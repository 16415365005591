import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-selectable-address',
  templateUrl: './ion-selectable-address.component.html',
  styleUrls: ['./ion-selectable-address.component.scss'],
})
export class IonSelectableAddressComponent implements OnInit {

  @Input() selectItems = [];
  @Input() placeholder = '';
  @Input() style = '';
  @Input() margin = '';
  @Input() selected:any = '';
  @Input() multiple = false;
  @Input() disabled :boolean;
  @Output() valueSelected = new EventEmitter<string>();
  @Input() type = '';


  name = ''
  value = ''

  constructor() {

  }
  ngOnInit() {
  }


  onChange(event: { component: IonSelectableAddressComponent; value: any }) {
    this.selected = event.value;
    this.valueSelected.emit(event.value)

  }

}

import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { readCookie } from '../helpers/cookie.helper';

@Injectable({
    providedIn: 'root'
})

export class MustBeUserGuard implements CanActivate {
    constructor(private router: Router) { }
    canActivate(): boolean {
        const token = readCookie('token');
        if (!token) {
            this.router.navigateByUrl('login');
        }
        //const authorize = this.checkRole(route.data.guards);
        //if (!authorize) {
            //return false;
            // Return 403
            // this.router.navigateByUrl('login');
        //}
        return true;
    }

    private checkRole(arrayRole: Array<any>): boolean {
        const roleUser = readCookie('role');
        if (!roleUser) return false;
        if (arrayRole.includes(roleUser)) {
            return true;
        }
        return false;
    }
}
import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-modal-reset-history',
  templateUrl: './modal-reset-history.component.html',
  styleUrls: ['./modal-reset-history.component.scss'],
})
export class ModalResetHistoryComponent implements OnInit {
  name: string;
  serviceName : string;

  constructor(private modalCtrl: ModalController,) { }

  ngOnInit() {}
  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  confirm() {

    return this.modalCtrl.dismiss(this.serviceName, 'confirm');
  }
}

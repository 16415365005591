import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import * as types from "../types";
import { Request } from "./request.service";

//import { GlobalFunc } from '../base/base';
@Injectable()
export class ChangesHistoryService {
  constructor(
    private request: Request,
    private router: Router,
    private store: Store<any>
  ) //private globalFunc: GlobalFunc
  {}

  getChangesHistory(userId) {
    return new Promise((resolve, reject) => {
      this.request
        .get("/api/v1/survey-history-call-logs?userId=" + userId)
        .then((res) => {
          this.store.dispatch({
            type: types.LIST_CHANGES_HISTORY,
            payload: res.data,
          });
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  getChangesHistoryDetail(_id) {
    return new Promise((resolve, reject) => {
      this.request
        .get("/api/v1/survey-history-call-logs/" + _id)
        .then((res) => {
          this.store.dispatch({
            type: types.LIST_CHANGES_HISTORY_DETAIL,
            payload: res.data,
          });
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

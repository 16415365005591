import { Injectable, isDevMode } from "@angular/core";
import { Router } from "@angular/router";
import { AlertController, NavController } from "@ionic/angular";
import { readCookie } from "../helpers/cookie.helper";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Store } from "@ngrx/store";

import * as types from "../types";

@Injectable({
  providedIn: "root",
})
export class GlobalFunc {
  constructor(
    private router: Router,
    private alertController: AlertController,
    private navCtrl: NavController,
    // private service: CustomerService,
    private store: Store<any>,
    private http: HttpClient
  ) {}
  getDeviceType() {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    }
    if (
      /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "mobile";
    }
    return "desktop";
  }
  errLkUser = "";
  SERVER_URL = "https://prod-api-tego018.bcms.tech";
  async presentAlert(message) {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      message: message,
      buttons: [
        {
          text: "KHÔNG",
          role: "cancel",
          cssClass: "css-cancel",
          handler: () => {},
        },
        {
          text: "ĐỒNG Ý",
          handler: () => {
            this.router.navigateByUrl("home");
          },
        },
      ],
    });

    await alert.present();
  }

  async successAlert(message, link) {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      message: message,
      buttons: [
        {
          text: "ĐỒNG Ý",
          cssClass: "css-success",
          handler: () => {
            if (link != "" && link) {
              this.router.navigateByUrl("login");
            }
          },
        },
      ],
    });

    await alert.present();
  }

  async commonAlert(message, link = "") {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      message: message,
      buttons: [
        {
          text: "ĐỒNG Ý",
          cssClass: "css-success",
          handler: () => {
            if (link) {
              this.router.navigateByUrl(link);
            }
          },
        },
      ],
    });

    await alert.present();
  }

  onSubmitLienket() {}

  async lienketKH(): Promise<any> {
    let message =
      "" +
      '<p class="mBT10"><img src="assets/images/account/icon_group+.png" /></p>' +
      '<p class="mBT10">Nhập <strong>Số điện thoại</strong> của <strong>Khách hàng</strong> bạn muốn liên kết</p>' +
      '<span class="mBT10">Khuyến khích khách hàng <strong>cập nhật số điện thoại và xác minh OTP</strong> trên <strong>ứng dụng Khách hàng</strong></span>' +
      '<span class="error" id="errLkUser">Số điện thoại không tồn tại</span>';
    const alert = await this.alertController.create({
      cssClass: "lienketKH-alert",
      message: message,
      inputs: [
        {
          name: "phone",
          type: "text",
          placeholder: "Số điện thoại",
        },
      ],
      buttons: [
        {
          text: "QUAY LẠI",
          role: "cancel",
          cssClass: "css-cancel",
          handler: () => {},
        },
        {
          text: "YÊU CẦU LIÊN KẾT",
          handler: (alertData) => {
            if (alertData.phone == "") {
              this.errLkUser = "Số điện thoại không được rỗng";
              document.getElementById("errLkUser").innerText = this.errLkUser;
              document.getElementById("errLkUser").style.opacity = "1";
              return false;
            } else {
              this.lkUsers(alertData.phone, null)
                .then((res) => {
                  this.store.dispatch({
                    type: types.SEND_LINK_PHONE,
                    payload: alertData.phone,
                  });
                  this.errLkUser = "";
                })
                .catch((error) => {
                  this.errLkUser = error.messsage;
                  this.successAlert(this.errLkUser, null);
                });
            }

            //this.successAlert("Khách hàng <strong>NGUYỄN VĂN MINH A</strong> đồng ý liên kết với bạn");
          },
        },
      ],
    });

    return alert.present();
  }

  onLkUser() {}

  lkUsers(id, payload) {
    return new Promise((resolve, reject) => {
      this.post("/api/v1/supporter/send-link/" + id, payload)
        .then((res) => {
          //this.store.dispatch({ type: types.VALID_LK_USERS, payload: res.data });
          //this.router.navigateByUrl('customer');
          resolve(res.data);
        })
        .catch((error) => {
          this.store.dispatch({
            type: types.VALID_LK_USERS,
            payload: error,
          });
          reject(error);
        });
    });
  }

  post(subUrl: string, body) {
    let headers = new HttpHeaders();
    const token = readCookie("token");
    if (token) {
      headers = headers.append("Authorization", token);
    }
    const response = this.http
      .post(this.SERVER_URL + subUrl, body, { headers })
      .toPromise();
    return this.processResponse(response);
  }

  processResponse(response: Promise<any>) {
    return response
      .then((res) => res)
      .catch((error) => {
        if (error.error) {
          throw { messsage: error.error.message };
        }
      });
  }

  goBack() {
    this.navCtrl.back();
  }

  goToPage(str) {
    this.router.navigateByUrl(str);
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-ionic-selectable',
  templateUrl: './ionic-selectable.component.html',
  styleUrls: ['./ionic-selectable.component.scss'],
})

export class IonicSelectableComponent implements OnInit{

  @Input() selectItem:any = [];
  @Input() placeholder = '';
  @Input() style = '';
  @Input() margin = '';
  @Input() selected:any = '';
  @Input() multiple = false;
  @Input() disabled :boolean;
  @Output() valueSelected = new EventEmitter<string>();

  name = ''
  value = ''

  constructor() {

  }
  ngOnInit() {
  }


  onChange(event: { component: IonicSelectableComponent; value: any }) {
    this.selected = event.value;
    this.valueSelected.emit(event.value)

  }
}

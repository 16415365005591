import { CustomerService } from 'src/app/services/customer.service';
import { Component, Input, OnInit } from '@angular/core';
import { GlobalFunc } from '../../../base/base';
import { AlertController } from '@ionic/angular';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import {AuthApiService} from '../../../services/auth.service';
@Component({
  selector: 'app-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.scss'],
})
export class GeneralInformationComponent implements OnInit {
  customerId = this.route.snapshot.paramMap.get('id');
  payloadForUpdate = {};
  isOpenModalMenu = false;
  missingFieldArr = [];
  styleKPElog = '#f3f7fc';
  type = 'KC';
  fakeUser = {};
  cgXetNghiemKhangDinh = '';
  ketQuaXNSangLoc = '';
  cgPrep = '';
  // "CLINK", "Healthy Market",
  fakeUser1 = {
    projectName: ['LADDERS',  'Quỹ Toàn Cầu', 'KHÁC'],
    CBO: ['CL01', 'HCM03'],
    CBOHCDC: [
      'HCM-HS',

    ],
    appoachOfficerCode: ['P01', 'G01', 'P03'],
    projectProvince: [
      'Thành phố Hồ Chí Minh',
      'Tỉnh Đồng Nai',
      'Thành phố Hà Nội',
      // 'Thành phố Cần Thơ',
      // 'Thành phố Đà Nẵng',
      // 'Thành phố Hải Phòng',
      // 'Tỉnh An Giang',
      // 'Tỉnh Bà Rịa - Vũng Tàu',
      // 'Tỉnh Bắc Giang',
      // 'Tỉnh Bắc Kạn',
      // 'Tỉnh Bạc Liêu',
      // 'Tỉnh Bắc Ninh',
      // 'Tỉnh Bến Tre',
      // 'Tỉnh Bình Định',
      // 'Tỉnh Bình Dương',
      // 'Tỉnh Bình Phước',
      // 'Tỉnh Bình Thuận',
      // 'Tỉnh Cà Mau',
      // 'Tỉnh Cao Bằng',
      // 'Tỉnh Đắk Lắk',
      // 'Tỉnh Đắk Nông',
      // 'Tỉnh Điện Biên',
      // 'Tỉnh Đồng Tháp',
      // 'Tỉnh Gia Lai',
      // 'Tỉnh Hà Giang',
      // 'Tỉnh Hà Nam',
      // 'Tỉnh Hà Tĩnh',
      // 'Tỉnh Hải Dương',
      // 'Tỉnh Hậu Giang',
      // 'Tỉnh Hoà Bình',
      // 'Tỉnh Hưng Yên',
      // 'Tỉnh Khánh Hòa',
      // 'Tỉnh Kiên Giang',
      // 'Tỉnh Kon Tum',
      // 'Tỉnh Lai Châu',
      // 'Tỉnh Lâm Đồng',
      // 'Tỉnh Lạng Sơn',
      // 'Tỉnh Lào Cai',
      // 'Tỉnh Long An',
      // 'Tỉnh Nam Định',
      // 'Tỉnh Nghệ An',
      // 'Tỉnh Ninh Bình',
      // 'Tỉnh Ninh Thuận',
      // 'Tỉnh Phú Thọ',
      // 'Tỉnh Phú Yên',
      // 'Tỉnh Quảng Bình',
      // 'Tỉnh Quảng Nam',
      // 'Tỉnh Quảng Ngãi',
      // 'Tỉnh Quảng Ninh',
      // 'Tỉnh Quảng Trị',
      // 'Tỉnh Sóc Trăng',
      // 'Tỉnh Sơn La',
      // 'Tỉnh Tây Ninh',
      // 'Tỉnh Thái Bình',
      // 'Tỉnh Thái Nguyên',
      // 'Tỉnh Thanh Hóa',
      // 'Tỉnh Thừa Thiên Huế',
      // 'Tỉnh Tiền Giang',
      // 'Tỉnh Trà Vinh',
      // 'Tỉnh Tuyên Quang',
      // 'Tỉnh Vĩnh Long',
      // 'Tỉnh Vĩnh Phúc',
      // 'Tỉnh Yên Bái'
    ]

  };
  fakeUser2 = {
    projectName: [ 'Quỹ Toàn Cầu', 'KHÁC'],
    CBO: ['CL01', 'HCM03'],
    CBOHCDC: [
      'HCM-101',
      'HCM-102',
      'HCM-103',
      'HCM-104',
      'HCM-105',
      'HCM-106',
      'HCM-107',
      'HCM-108',
    ],
    appoachOfficerCode: ['P01', 'G01', 'G03'],
    projectProvince: [
      'Thành phố Hồ Chí Minh',
      'Tỉnh Đồng Nai',
      'Thành phố Hà Nội',
      // 'Thành phố Cần Thơ',
      // 'Thành phố Đà Nẵng',
      // 'Thành phố Hải Phòng',
      // 'Tỉnh An Giang',
      // 'Tỉnh Bà Rịa - Vũng Tàu',
      // 'Tỉnh Bắc Giang',
      // 'Tỉnh Bắc Kạn',
      // 'Tỉnh Bạc Liêu',
      // 'Tỉnh Bắc Ninh',
      // 'Tỉnh Bến Tre',
      // 'Tỉnh Bình Định',
      // 'Tỉnh Bình Dương',
      // 'Tỉnh Bình Phước',
      // 'Tỉnh Bình Thuận',
      // 'Tỉnh Cà Mau',
      // 'Tỉnh Cao Bằng',
      // 'Tỉnh Đắk Lắk',
      // 'Tỉnh Đắk Nông',
      // 'Tỉnh Điện Biên',
      // 'Tỉnh Đồng Tháp',
      // 'Tỉnh Gia Lai',
      // 'Tỉnh Hà Giang',
      // 'Tỉnh Hà Nam',
      // 'Tỉnh Hà Tĩnh',
      // 'Tỉnh Hải Dương',
      // 'Tỉnh Hậu Giang',
      // 'Tỉnh Hoà Bình',
      // 'Tỉnh Hưng Yên',
      // 'Tỉnh Khánh Hòa',
      // 'Tỉnh Kiên Giang',
      // 'Tỉnh Kon Tum',
      // 'Tỉnh Lai Châu',
      // 'Tỉnh Lâm Đồng',
      // 'Tỉnh Lạng Sơn',
      // 'Tỉnh Lào Cai',
      // 'Tỉnh Long An',
      // 'Tỉnh Nam Định',
      // 'Tỉnh Nghệ An',
      // 'Tỉnh Ninh Bình',
      // 'Tỉnh Ninh Thuận',
      // 'Tỉnh Phú Thọ',
      // 'Tỉnh Phú Yên',
      // 'Tỉnh Quảng Bình',
      // 'Tỉnh Quảng Nam',
      // 'Tỉnh Quảng Ngãi',
      // 'Tỉnh Quảng Ninh',
      // 'Tỉnh Quảng Trị',
      // 'Tỉnh Sóc Trăng',
      // 'Tỉnh Sơn La',
      // 'Tỉnh Tây Ninh',
      // 'Tỉnh Thái Bình',
      // 'Tỉnh Thái Nguyên',
      // 'Tỉnh Thanh Hóa',
      // 'Tỉnh Thừa Thiên Huế',
      // 'Tỉnh Tiền Giang',
      // 'Tỉnh Trà Vinh',
      // 'Tỉnh Tuyên Quang',
      // 'Tỉnh Vĩnh Long',
      // 'Tỉnh Vĩnh Phúc',
      // 'Tỉnh Yên Bái'
    ]
  };
  fakeUser3 = {
    projectName: [ 'Quỹ Toàn Cầu', 'KHÁC'],
    CBO: ['CL01', 'HCM03'],
    CBOHCDC: [
      'HCM-101',
      'HCM-102',
      'HCM-103',
      'HCM-104',
      'HCM-105',
      'HCM-106',
      'HCM-107',
      'HCM-108',
    ],
    appoachOfficerCode: ['P01', 'G01', 'P03'],
    projectProvince: [
      'Thành phố Hồ Chí Minh',
      'Tỉnh Đồng Nai',
      'Thành phố Hà Nội',
      // 'Thành phố Cần Thơ',
      // 'Thành phố Đà Nẵng',
      // 'Thành phố Hải Phòng',
      // 'Tỉnh An Giang',
      // 'Tỉnh Bà Rịa - Vũng Tàu',
      // 'Tỉnh Bắc Giang',
      // 'Tỉnh Bắc Kạn',
      // 'Tỉnh Bạc Liêu',
      // 'Tỉnh Bắc Ninh',
      // 'Tỉnh Bến Tre',
      // 'Tỉnh Bình Định',
      // 'Tỉnh Bình Dương',
      // 'Tỉnh Bình Phước',
      // 'Tỉnh Bình Thuận',
      // 'Tỉnh Cà Mau',
      // 'Tỉnh Cao Bằng',
      // 'Tỉnh Đắk Lắk',
      // 'Tỉnh Đắk Nông',
      // 'Tỉnh Điện Biên',
      // 'Tỉnh Đồng Tháp',
      // 'Tỉnh Gia Lai',
      // 'Tỉnh Hà Giang',
      // 'Tỉnh Hà Nam',
      // 'Tỉnh Hà Tĩnh',
      // 'Tỉnh Hải Dương',
      // 'Tỉnh Hậu Giang',
      // 'Tỉnh Hoà Bình',
      // 'Tỉnh Hưng Yên',
      // 'Tỉnh Khánh Hòa',
      // 'Tỉnh Kiên Giang',
      // 'Tỉnh Kon Tum',
      // 'Tỉnh Lai Châu',
      // 'Tỉnh Lâm Đồng',
      // 'Tỉnh Lạng Sơn',
      // 'Tỉnh Lào Cai',
      // 'Tỉnh Long An',
      // 'Tỉnh Nam Định',
      // 'Tỉnh Nghệ An',
      // 'Tỉnh Ninh Bình',
      // 'Tỉnh Ninh Thuận',
      // 'Tỉnh Phú Thọ',
      // 'Tỉnh Phú Yên',
      // 'Tỉnh Quảng Bình',
      // 'Tỉnh Quảng Nam',
      // 'Tỉnh Quảng Ngãi',
      // 'Tỉnh Quảng Ninh',
      // 'Tỉnh Quảng Trị',
      // 'Tỉnh Sóc Trăng',
      // 'Tỉnh Sơn La',
      // 'Tỉnh Tây Ninh',
      // 'Tỉnh Thái Bình',
      // 'Tỉnh Thái Nguyên',
      // 'Tỉnh Thanh Hóa',
      // 'Tỉnh Thừa Thiên Huế',
      // 'Tỉnh Tiền Giang',
      // 'Tỉnh Trà Vinh',
      // 'Tỉnh Tuyên Quang',
      // 'Tỉnh Vĩnh Long',
      // 'Tỉnh Vĩnh Phúc',
      // 'Tỉnh Yên Bái'
    ]
  };
  loading = false;
  tenDuAn = '';
  tenDuAnKhac = '';
  tinhDuAn = '';
  maCBOTheoHCDC = '';
  maTCVTheoHCDC = '';
  maCBO = '';
  maTCV = '';
  maXetNghiemVienTheoDuAn = '';
  maXNVienTheoHCDC = '';
  tenTCV = '';
  xnSangLocTaiCD = '';
  chuyenGuiARV = '';
  isEmpty = false ;
  isLoadingSubmit = false;

  constructor(
    private global: GlobalFunc,
    public atrCtrl: AlertController,
    private route: ActivatedRoute,
    private location: Location,
    private customerService: CustomerService,
    private store: Store<any>,
    private  service: AuthApiService,
  ) {}
  ionViewWillEnter(){
    this.fakeUser = this.fakeUser1;
    if (this.customerId)
    {
      this.getDetailSupport();

    }
    this.getTcvInfo();

  }
  ngOnInit() {
    this.fakeUser = this.fakeUser1;
    if (this.customerId)
    {
      this.getDetailSupport();

    }
    this.getTcvInfo();
  }
  getDetailSupport() {
    this.getTcvInfo();
    this.loading = true;
    this.customerService.getDetailUsersSendKPElog(this.customerId).then((res:any) => {
        this.loading = false;
        this.tenDuAn = this.fakeUser1.projectName.includes(res?.ttChung?.tenDuAn ) ? res?.ttChung?.tenDuAn : 'KHÁC';
        this.tenDuAnKhac = !this.fakeUser1.projectName.includes(res?.ttChung?.tenDuAn ) ? res?.ttChung?.tenDuAn : '';
        this.tinhDuAn = res?.ttChung?.tinhDuAn ? res?.ttChung?.tinhDuAn : '';
        this.maCBO = res?.ttChung?.maCBO ? res?.ttChung?.maCBO : '';
        this.maCBOTheoHCDC = res?.ttChung?.maCBOTheoHCDC ? res?.ttChung?.maCBOTheoHCDC : '';
        this.cgXetNghiemKhangDinh = res?.ttDichVu?.xetNghiemSangLocHIV[res?.ttDichVu?.xetNghiemSangLocHIV.length-1]?.cgXetNghiemKhangDinh;
        this.ketQuaXNSangLoc = res?.ttDichVu?.xetNghiemSangLocHIV[res?.ttDichVu?.xetNghiemSangLocHIV.length-1]?.ketQuaXNSangLoc;
        this.cgPrep = res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length-1]?.cgPrep;
        if (this.tenDuAn === 'LADDERS'){
          this.maTCVTheoHCDC = 'P' + this.maTCV.slice(-2) ;
        }
        else if (this.tenDuAn === 'Quỹ Toàn Cầu'){
          this.maTCVTheoHCDC = 'G' + this.maTCV.slice(-2) ;
        }

    });


  }
  goToPage(str) {
    this.global.goToPage(str);
  }
  getTcvInfo() {
    this.service.getTcvInfo().then(res => {
      this.store.select('auth').subscribe(s => {
        if (s.tcvInfo.supporter){
          this.tenTCV = s.tcvInfo.supporter.ten;
          this.maTCV = s.tcvInfo.supporter.tcv.maTCV.replace(/\s/g, '').slice(-2);
        }
      });
    });
  }
 async handleSaveAll() {
    const alertConfirm = this.atrCtrl.create({
      message: 'Thông tin vừa được lưu vào bộ nhớ tạm thời, bạn có thể tiếp tục cập nhật hoặc quay lại sau!',
    });
    (await alertConfirm).present();
  }
  async onComplete() {
    this.isLoadingSubmit = true;
    this.isEmpty = true;
    const payload = {
      userId: this.customerId ? this.customerId : '',
      maCBOTheoHCDC: this.maCBOTheoHCDC,
      maTCVTheoHCDC: this.maTCVTheoHCDC,
      tenDuAn: this.tenDuAn,
      tinhDuAn: this.tinhDuAn,
      maCBO: this.maCBO,
      maTCV: this.maTCV,
      tenTCV: this.tenTCV,
          ...(this.tenDuAn === 'KHÁC' && {tenDuAn: this.tenDuAnKhac})
    };
    if (!this.customerId) { delete payload.userId; }
    let clonePayload = JSON.parse(JSON.stringify(payload));
    if(this.tinhDuAn !=='Thành phố Hồ Chí Minh'){
      delete payload.maCBOTheoHCDC
      delete payload.maTCVTheoHCDC
    }
    if (Object.values(payload).includes('')) {
      this.isLoadingSubmit = false;
      const alertConfirm = this.atrCtrl.create({
        cssClass: 'my-custom-class',
        message: 'Vui lòng nhập đầy đủ thông tin hợp lệ!',
      });
      (await alertConfirm).dismiss();

      (await alertConfirm).present();
    }
    else {
      if (localStorage.getItem('isNew') === '1') {
        this.customerService
            .updateInformationKpelog(payload)
            .then(async (res: any) => {
              if (res) {
                this.isLoadingSubmit = false;
                const alertConfirm = this.atrCtrl.create({
                  message: 'Thông tin vừa được lưu vào hệ thống!',
                  cssClass: 'my-custom-class-success',
                });
                setTimeout(async () => {
                  (await alertConfirm).dismiss();
                  if (this.customerId) {
                    this.goToPage('tabs/list-survey/basic-information/' + this.customerId);
                  } else {
                    this.goToPage('tabs/list-survey/basic-information/' + res.global_id);
                  }
                }, 1000);
                (await alertConfirm).present();
              }
            })
            .catch();
      } else if (localStorage.getItem('isNew') === '0' ) {
        this.payloadForUpdate = payload;
        this.customerService
            .updateInformationKpelog(this.payloadForUpdate)
            .then(async (res: any) => {
              this.isLoadingSubmit = false;
              setTimeout(() => {
                this.customerService
                    .checkMissingData(this.customerId)
                    .then(async (res: any) => {
                      if (res) {
                        res.map(value => {
                              if (value.reason) {
                                this.missingFieldArr = value.reason;
                              } else {
                                this.missingFieldArr = [];
                              }
                            }
                        );
                      }
                    })
                    .catch();
                setTimeout(async () => {
                  if (((this.cgXetNghiemKhangDinh === 'Có' && this.ketQuaXNSangLoc === 'Có phản ứng') || this.cgPrep === 'Có') && this.tinhDuAn ==='Thành phố Hồ Chí Minh' ) {
                    this.isOpenModalMenu = !this.isOpenModalMenu;
                  } else {
                    const alertConfirm = this.atrCtrl.create({
                      message: 'Thông tin vừa được lưu vào hệ thống!',
                      cssClass: 'my-custom-class-success',
                    });
                    setTimeout(async () => {
                      (await alertConfirm).dismiss();
                      if (this.customerId) {
                        this.goToPage('tabs/list-survey/basic-information/' + this.customerId);
                      }
                    }, 1000);
                    (await alertConfirm).present();
                  }

                }, 500);
              }, 1000);
            })
            .catch();

      }
    }


  }
  goBack(){
    if (this.customerId){
      this.goToPage('tabs/customer-detail-kpelog/' + this.customerId);
    }else{
      this.goToPage('customer');
      localStorage.setItem('isNew', '0');
    }
  }
  handleChangeTenDuAn(e){

    this.tenDuAn = e.target.value;
    this.customerService
          .getListCBO(this.tenDuAn)
          .then(async (res: any) => {
            if (res) {
              this.maCBOTheoHCDC = res.maCBOTheoHCDC;
              this.maCBO = res.maCBOTheoDuAn;
            }
          });
    if (this.tenDuAn === 'LADDERS'){
      this.maTCVTheoHCDC = 'P' + this.maTCV ;
    }
    else if (this.tenDuAn === 'Quỹ Toàn Cầu'){
      this.maTCVTheoHCDC = 'G' + this.maTCV;
    }
  }

  handleChangeTinhDuAn(e){
    this.tinhDuAn = e.target.value;
    this.customerService
        .getListCBO(this.tenDuAn)
        .then(async (res: any) => {
          if (res) {
            this.maCBOTheoHCDC = res.maCBOTheoHCDC;
            this.maCBO = res.maCBOTheoDuAn;
          }
        });
  }
  handleChangeCBO(e){
    this.maCBOTheoHCDC = e.target.value;
  }
  handleChangeTCV(e){
    this.maTCVTheoHCDC = e.target.value;
  }
  handleChangeType(e){
    this.type = e.target.value;
  }
  async closeModalMenu() {
    this.isOpenModalMenu = false;

    const alertConfirm = this.atrCtrl.create({
      message: 'Thông tin vừa được lưu vào hệ thống!',
      cssClass: 'my-custom-class-success',
    });
    setTimeout(async () => {
      (await alertConfirm).dismiss();
      if (this.customerId) {
        this.goToPage('tabs/list-survey/basic-information/' + this.customerId);
      }
    }, 1000);
    (await alertConfirm).present();
  }

}

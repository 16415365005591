import { Component } from '@angular/core';

@Component({
  selector: 'app-screening',
  templateUrl: 'screening.component.html',
  styleUrls: ['screening.component.scss']
})
export class ScreeningComponent {

  constructor() {}

}

import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import * as types from "../types";
import { Request } from "./request.service";

//import { GlobalFunc } from '../base/base';
@Injectable()
export class NotificationService {
  constructor(
    private request: Request,
    private router: Router,
    private store: Store<any> //private globalFunc: GlobalFunc
  ) { }

  getListNotification() {
    return new Promise((resolve, reject) => {
      this.request
        .get("/api/v1/supporter/all-thong-bao-from-chis/")
        .then((res) => {
          this.store.dispatch({
            type: types.LIST_NOTIFICATIONS,
            payload: res.data,
          });
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  updateReadNotification(_id, payload) {
    return new Promise((resolve, reject) => {
      this.request
        .post("/api/v1/supporter/detail-result-from-chis/" + _id, payload)
        .then((res) => {
          this.store.dispatch({
            type: types.LIST_NOTIFICATIONS,
            payload: res.data,
          });
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  getListNotificationByType(type = '') {
    return new Promise((resolve, reject) => {
      this.request
        .get("/api/v1/supporter/get-result-from-chis-by-type?type=" + type)
        .then((res) => {
          this.store.dispatch({
            type: types.LIST_NOTIFICATIONS,
            payload: { record: res.data },
          });
          console.log("getListNotificationByType", res);

          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
  getListNotificationCBO(status = '', page = 1, limit = 100) {
    return new Promise((resolve, reject) => {
      this.request
        .get(`/api/v1/schedule/supporter/booking-assign?status=${status}&page=${page}&limit=${limit}`)
        .then((res) => {
          this.store.dispatch({
            type: types.LIST_NOTIFICATIONS_CBO,
            payload: { record: res.data },
          });
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
  updateStatusBookings(payload: object) {
    return new Promise((resolve, reject) => {
      this.request
        .put(`/api/v1/schedule/admin/status-booking`, payload)
        .then((res) => {
          console.log("Update Status Success", res);
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
  getCBODetails(id = '') {
    return new Promise((resolve, reject) => {
      this.request
        .get(`/api/v1/schedule/supporter/booking-assign/${id}`)
        .then((res) => {
          // this.store.dispatch({
          //   type: types.LIST_NOTIFICATIONS_CBO,
          //   payload: { record: res.data },
          // });
          console.log("getDetailsCBO", res);

          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
  getNewBooking(){
    return new Promise((resolve, reject) => {
      this.request
        .get(`/api/v1/schedule/supporter/check-new-booking`)
        .then((res) => {
          console.log("CheckNewBookings", res);
          console.log(res.data, 'res.data')
            this.store.dispatch({
            type: types.NEW_BOOKINGS,
            payload: res.data,
          });
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
  readBookings(id = ''){
    return new Promise((resolve, reject) => {
      this.request
        .put(`/api/v1/schedule/supporter/booking/${id}/read`, '')
        .then((res) => {
          console.log("ReadBookings", res);
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
    readNotification(_id) {
        return new Promise((resolve, reject) => {
            this.request
                .post("/api/v1/supporter/read-thong-bao/" + _id,'')
                .then((res) => {
                    this.store.dispatch({
                        type: types.LIST_NOTIFICATIONS,
                        payload: res.data,
                    });
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    checkCBO() {
        return new Promise((resolve, reject) => {
            this.request
                .get('/api/v1/supporter/check-supporter-belong-to-new-features')
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }

}

import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { GlobalFunc } from "../base/base";
import * as types from "../types";
import { Request } from "./request.service";

@Injectable()
export class ServicesService {
  constructor(
    private request: Request,
    private router: Router,
    private store: Store<any>,
    private globalFunc: GlobalFunc
  ) {}

  getDetailService(dichVuId, customerId) {
    return new Promise((resolve, reject) => {
      this.request
        .get(
          "/api/v1/supporter/get-danh-sach-goi-cau-hoi?dichVuId=" +
            dichVuId +
            "&customerId=" +
            customerId
        )
        .then((res) => {
          this.store.dispatch({ type: types.LIST_SERVICE, payload: res.data });
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getHistoryService(goiCauHoiId, customerId) {
    return new Promise((resolve, reject) => {
      this.request
        .get(
          "/api/v1/supporter/get-danh-sach-lich-su?goiCauHoiId=" +
            goiCauHoiId +
            "&customerId=" +
            customerId
        )
        .then((res) => {
          this.store.dispatch({ type: types.LIST_HISTORY, payload: res.data });
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getQuestService(questId, baoLuuId) {
    let url = `/api/v1/supporter/get-cau-hoi-dichVu/${questId}`;
    if (baoLuuId && baoLuuId.length) {
      url += `?goiCauHoiBaoLuuId=${baoLuuId}`;
    }

    return this.request.get(url).then((res) => {
      this.store.dispatch({
        type: types.LIST_QUEST_SERVICE,
        payload: res.data,
      });
      return res.data;
    });
  }
  postApplyService(payload) {
    const url = `/api/v1/supporter/nop-bai-dich-vu`;
    return this.request.post(url, payload);
  }
  deleteService(id) {
    const url = "/api/v1/supporter/xoa-bai-dich-vu/" + id;
    return new Promise((resolve, reject) => {
      this.request
        .delete(url)
        .then((res) => {
          return resolve(res);
        })
        .catch((error) => {
          this.store.dispatch({
            type: types.VALID_LK_USERS,
            payload: error.message,
          });
          return reject(error);
        });
    });
  }
}

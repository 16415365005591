import authReducer from "./auth.reducer";
import changesHistoryReducer from "./changes-history.reducer";
import customerReducer from "./customer.reducer";
import servicesReducer from "./services.reducer";
import surveyReducer from "./survey.reducer";
import notificationReducer from "./notification.reducer";

export default {
  auth: authReducer,
  customer: customerReducer,
  services: servicesReducer,
  changesHistory: changesHistoryReducer,
  notification: notificationReducer,
  survey: surveyReducer,
};

import Swal from 'sweetalert2';

/**
 * This is function show error message
 * @param message
 */
export const errorMessage = (message: string) => {
    Swal.fire({
        // icon: 'error',
        title: '',
        text: message,
        confirmButtonText: 'Thử lại',
        customClass: {
            popup: 'modal-error',
            header: 'header-error',
            content: 'content-error',
            actions: 'error-actions',
            icon: 'error-icon',
        }
    })
}

/**
 * This is function show success message
 * @param title
 * @param message
 */
export const successMessage = (title: string, message: string) => {
    Swal.fire({
        imageUrl: '../../../assets/images/image-success.svg',
        title: 'Đổi mật khẩu thành công',
        text: 'Đổi mật khẩu thành công vui lòng đăng nhập để sử dụng dịch vụ',
        confirmButtonText: 'Đóng',
        customClass: {
            popup: 'modal-success',
            header: 'modal-header',
            content: 'modal-content',
            actions: 'modal-actions'
        }
    })
}

export const swalConfirm = ({
  title = "",
  message = "",
  onConfirm,
  onCancel,
}) => {
  Swal.fire({
    title: `${title}`,
    text: `${message}`,
    showCancelButton: true,
    confirmButtonText: "Có",
    cancelButtonText: "Không",
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirm && onConfirm();
    } else {
      onCancel && onCancel();
    }
  });
};
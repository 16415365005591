import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";
import {CustomerService} from "../../../services/customer.service";
import * as moment from "moment/moment";

@Component({
  selector: 'app-history-violence',
  templateUrl: './history-violence.component.html',
  styleUrls: ['./history-violence.component.scss'],
})
export class HistoryViolenceComponent  {

  name: string;
  isShow  = [100];
  isShow1 = false;
  isShow2 = false;
  isShow3 = false;
  customerId;
  historyViolenceArr = [];
  ngayKhachDongYKhaiBaoBTBC = null;
  ngayCungCapDV = null;
  constructor(private modalCtrl: ModalController,
              private customerService: CustomerService,) {}
  fakeData = ['a','b', 'c']
  ngOnInit() {

    this.customerService.getDetailUsersSendKPElog(this.customerId).then((res:any) =>{
      this.ngayKhachDongYKhaiBaoBTBC = moment(res.ttDichVu.ngayKhachDongYKhaiBaoBTBC).format('DD/MM/YYYY')
      this.ngayCungCapDV = moment(res.ttDichVu.ngayCungCapDV).format('DD/MM/YYYY')
      this.historyViolenceArr = res.ttDichVu.tuVanVaHoTroUngPhoNguyCoBaoHanh
    })
  }
  formatDate(date){
    return moment(date).format('DD-MM-YYYY')
  }
  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  confirm() {
    return this.modalCtrl.dismiss(this.name, 'confirm');
  }
  showHistory(i){
    if(i === 0){
      this.isShow.push(1);
    }
    else if (i === 1){
      this.isShow.push(2);

    }
    else{
      this.isShow.push(3);
    }

  }

}

import { Injectable, isDevMode } from "@angular/core";
import { Request } from "./request.service";
import { LoadingService } from "./loading.service";
import { Router } from "@angular/router";
import {
  Register,
  Login,
  EditProfile,
  CheckOtp,
  CreatePassword,
  UpdateInfo,
  SendOtp,
} from "../interfaces/auth.interface";
import { setCookie, removeCookie } from "../helpers/cookie.helper";
import { errorMessage } from "../helpers/swal.helper";
import { Store } from "@ngrx/store";
import * as types from "../types";
import { GlobalFunc } from "../base/base";

@Injectable()
export class AuthApiService {
  forgotPass = false;
  constructor(
    private request: Request,
    private router: Router,
    private store: Store<any>,
    public loadingService: LoadingService,
    private globalFunc: GlobalFunc
  ) { }

  /**
   * This is function login user with phone
   * @param payload
   */
  login(payload: Login) {
    this.loadingService.loadingPresent();
    return new Promise((resolve, reject) => {
      this.request
        .post('/api/v1/auth/supporter-login', payload)
        .then((res) => {
          this.loadingService.loadingDismiss();
          const { access_token } = res.data;
          if (access_token) {
            this.store.dispatch({
                type: types.VALID_PASS,
                payload: '',
            });
            setCookie('token', access_token, 7);
                    this.router.navigateByUrl('customer');

        } else {
            this.router.navigateByUrl('login');
        }
        })
        .catch((error) => {
          this.loadingService.loadingDismiss();
          if (error.messsage) {
            this.store.dispatch({
              type: types.VALID_PASS,
              payload: error.messsage,
            });
          }
        });
    });
  }

  checkAuthPIN() {
    return new Promise((resolve, reject) => {
      this.request.get("/api/v1/supporter/check-update-pin").then((res) => resolve(res.data)).catch()
    })
  }

  /**
   * This is function remove token and logout user if token is invalid
   */
  logoutUser() {
    removeCookie("token");
    // this.router.navigateByUrl("login")
    const { origin } = location;
    location.replace(origin + "/login");
  }

  /**
   * This is function resend OTP
   * @param payload
   */
  sendOtpFotgotPass(payload: SendOtp) {
    return new Promise((resolve, reject) => {
      this.request
        .post("/api/v1/supporter/public/forgot-pass", payload)
        .then((res: any) => {
          this.store.dispatch({ type: types.VALID_USER, payload: "" });
          return resolve(res.data);
        })
        .catch((error) => {
          this.store.dispatch({
            type: types.VALID_USER,
            payload: error.messsage,
          });
          reject(error);
        });
    });
  }

  /**
   * This is function active OTP
   * @param payload
   */
  checkOtp(payload: CheckOtp) {
    return new Promise((resolve, reject) => {
      this.request
        .post("/api/v1/supporter/public/check-otp", payload)
        .then((res) => {
          this.store.dispatch({
            type: types.USER_ID,
            payload: res.data,
          });
          this.store.dispatch({
            type: types.VALID_OTP,
            payload: true,
          });
          this.router.navigateByUrl("create-password");
        })
        .catch((error) => {
          console.log(error);
          this.store.dispatch({
            type: types.VALID_OTP,
            payload: false,
          });
        });
    });
  }

  /**
   * This is function active OTP
   * @param payload
   */
  sendSmsOtp(payload: SendOtp) {
    this.request
      .post("/api/v1/supporter/public/send-sms-otp", payload)
      .then((res) => { })
      .catch((error) => { });
  }

  /**
   * This is function change password user info by token
   * @param payload
   */
  updatePassword(payload: any) {
    this.request
      .put("/api/v1/supporter/public/update-password", payload)
      .then((res) => {
        this.globalFunc.successAlert(
          "Tạo thành công mật khẩu mới. Vui lòng đăng nhập bằng mật khẩu mới.",
          "login"
        );
      })
      .catch((error) => {
        if (error.messsage) {
          errorMessage(error.messsage);
        }
      });
  }

  /**
   * This is function check user with phone
   * @param payload
   */
  customerCheckPhone(payload: Register) {
    this.request
      .post("/api/v1/auth/customer-check-phone", payload)
      .then((res) => {
        if (res.data._id) {
          this.router.navigateByUrl("login-password");
          localStorage.setItem("phone", res.data.soDienThoai);
          localStorage.setItem("id", res.data._id);
        } else {
          this.router.navigateByUrl("register-otp");
          localStorage.setItem("id", res.data);
          this.store.dispatch({
            type: types.USER_ID,
            payload: res.data,
          });
        }
      })
      .catch((error) => { });
  }

  /**
   * This is function customer create password
   * @param payload
   */
  customerCreatePassword(payload: CreatePassword) {
    this.request
      .put("/api/v1/auth/customer-update-password", payload)
      .then((res) => {
        const { access_token } = res.data;
        if (access_token) {
          setCookie("token", access_token, 7);
        } else {
          this.router.navigateByUrl("login");
        }
        if (this.forgotPass) {
          this.forgotPass = false;
          this.globalFunc.successAlert(
            "Tạo thành công mật khẩu mới. Vui lòng đăng nhập bằng mật khẩu mới.",
            "login"
          );
        } else {
          this.router.navigateByUrl("register-info");
        }
      })
      .catch((error) => { });
  }

  /**
   * This is function customer create password
   * @param payload
   */
  customerUpdateInfo(payload: UpdateInfo, login: Login) {
    this.request
      .post("/api/v1/user/update-information", payload)
      .then((res) => {
        this.login(login);
      })
      .catch((error) => { });
  }

  getTcvInfo() {
    return new Promise((resolve, reject) => {
      this.request
        .get("/api/v1/supporter/information")
        .then((res) => {
          this.store.dispatch({
            type: types.TCV_INFO,
            payload: res.data,
          });
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  countUserLink(payload) {
    return new Promise((resolve, reject) => {
      this.request
        .post("/api/v1/supporter/count-user-linked", payload)
        .then((res) => {
          this.store.dispatch({
            type: types.COUNT_CUSTOMER,
            payload: res.data,
          });
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * This is function register user with phone
   * @param payload
   */
  register(payload: Register) {
    this.request
      .post("/api/v1/auth/customer-register-by-phone", payload)
      .then((res) => {
        this.router.navigateByUrl("register-otp");
        this.store.dispatch({ type: types.USER_ID, payload: res.data });
      })
      .catch((error) => {
        this.store.dispatch({ type: types.USER_ID, payload: error });
      });
  }

  /**
   * This is function get user info by token
   * @param token
   */
  getUserInfo() {
    this.request
      .get("/api/v1/user/get-information")
      .then((res) => {
        localStorage.setItem("userInfo", JSON.stringify(res.data));
      })
      .catch((error) => {
        this.logoutUser();
      });
  }

  /**
   * This is function update user info by token
   * @param payload
   */
  updateUserInfo(payload: EditProfile) {
    this.request
      .put("/secure/student/user/update-profile", payload)
      .then((res) => {
        this.router.navigateByUrl("home");
      })
      .catch((error) => {
        if (error.messsage) {
          errorMessage(error.messsage);
        }
      });
  }
}

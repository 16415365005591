import { Component } from "@angular/core";

import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { Store } from "@ngrx/store";
import { AuthApiService } from "./services/auth.service";

// import { Socket } from 'ngx-socket-io';

@Component({
    selector: "app-root",
    templateUrl: "app.component.html",
    styleUrls: ["app.component.scss"],
})
export class AppComponent {
    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        // private socket: Socket,
        private service: AuthApiService,
        private store: Store<any>
    ) {
        // this.service.getTcvInfo();
        // this.store.select('auth').subscribe(s => {
        //   if (s.tcvInfo?.supporter?._id) {
        //     this.socket.emit("connect-socket", s.tcvInfo.supporter._id);
        //   }
        // });
        //this.initializeApp();
        this.handleSplashScreen();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
    }
    // hide #splash-screen when app is ready
    async handleSplashScreen(): Promise<void> {
        try {
            // wait for App to finish loading
            await this.platform.ready();
        } catch (error) {
            console.error("Platform initialization bug");
        }

        // Any operation that shoud be performed BEFORE showing user UI,
        // in a real App that may be cookie based authentication check e.g.
        // await this.authProvider.authenticate(...)

        // fade out and remove the #splash-screen
        const splash = document.getElementById("splash-screen");

        setTimeout(() => {
            splash.style.opacity = "0";
            splash.remove();
        }, 1000);
    }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as types from '../types';
import { Request } from './request.service';
import { calculateProcess, filterQuestionBatchs, handleFindInactiveBatches } from './utils/useSurvey';
import {Observable} from "rxjs";
import {HttpParams} from '@angular/common/http';


//import { GlobalFunc } from '../base/base'; 
@Injectable()
export class CustomerService {
    constructor(
        private request: Request,
        private router: Router,
        private store: Store<any>,
        //private globalFunc: GlobalFunc
    ) { }

    progressBarValue = 35;

    getListUsers() {
        return new Promise((resolve, reject) => {
            this.request
                .get('/api/v1/supporter/list-user')
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data });
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    getListCBO(tenDuAn) {
        return new Promise((resolve, reject) => {
            this.request
                .get('/api/v1/supporter/get-ma-cbo-theo-du-an?tenDuAn='+tenDuAn)
                .then((res) => {
                    // this.store.dispatch({ type: types.LIST_USERS, payload: res.data });
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }

    getListUsersLinked() {
        return new Promise((resolve, reject) => {
            this.request
                .get('/api/v1/supporter/list-user-linked')
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data });
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }

    getListUsersNotLinked() {
        return new Promise((resolve, reject) => {
            this.request
                .get('/api/v1/supporter/list-user-not-linked')
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data });
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }

    getListUsersSendKPElog(isSend=null,status=null,haveNotification=null) {
        let url = '/api/v1/supporter/list-user-kpelog/filter?'
        if(isSend !== null ) {
            url = url + `&isSend=${isSend}`
        }
        if(status !== null) {
            url = url + `&status=${status}`
        }
        if(haveNotification) {
            url = url + `&haveNotification=${haveNotification}`
        }
        return new Promise((resolve, reject) => {

            this.request
                .get(url)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data });
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }

    getListUsersUnlinking() {
        return new Promise((resolve, reject) => {
            this.request
                .get('/api/v1/supporter/list-user-unlinking')
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data });
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }

    getCustomerInfo(id) {
        return new Promise((resolve, reject) => {
            this.request
                .get('/api/v1/supporter/customer/thong-tin-chi-tiet/' + id)
                .then((res) => {
                    this.store.dispatch({ type: types.CUSTOMER_INFO, payload: res.data });
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }

    getDetailSupport(id) {
        return new Promise((resolve, reject) => {
            this.request
                .get('/api/v1/supporter/customer/thong-tin/' + id)
                .then((res) => {
                    this.store.dispatch({ type: types.DETAIL_USERS, payload: res.data });
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }

    getDetailUsersSendKPElog(userId) {
        return new Promise((resolve, reject) => {
            this.request
                .get(`/api/v1/supporter/list-user-send-to-datalake-detail?userId=${userId}`)
                .then((res) => {
                    this.store.dispatch({ type: types.DETAIL_USERS_KPELOG, payload: res.data });
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }

    searchUsers(key) {
        return new Promise((resolve, reject) => {
            this.request
                .get('/api/v1/supporter/search?key=' + key)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data });
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }

    updateUsers(payload, id) {

        return new Promise((resolve, reject) => {
            this.request
                .post('/api/v1/supporter/customer/update/' + id, payload)
                .then((res) => {

                    //this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    addNewUsers(payload) {

        return new Promise((resolve, reject) => {
            this.request
                .post('/api/v1/supporter/create-customer/', payload)
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    lkUsers(id, payload) {
        return new Promise((resolve, reject) => {
            this.request
                .post('/api/v1/supporter/send-link/' + id, payload)
                .then((res) => {

                    this.store.dispatch({ type: types.VALID_LK_USERS, payload: res.data });
                    this.router.navigateByUrl('customer');
                    resolve(res.data)
                })
                .catch((error) => {
                    this.store.dispatch({ type: types.VALID_LK_USERS, payload: error.message });
                    reject(error)
                });
        })
    }
    customerFilter(payload) {
        const url = '/api/v1/supporter/filter'
        return new Promise((resolve, reject) => {
            this.request
                .post(url, payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data })
                    return resolve(res)
                })
                .catch((error) => {
                    this.store.dispatch({ type: types.VALID_LK_USERS, payload: error.message });
                    return reject(error)
                });
        })
    }

    getQuestionProcess(id) {
        return new Promise((resolve, reject) => {
            this.request
                .get('/api/v1/survey-set-of-question/get-process?userId=' + id)
                .then(({ data }) => {
                    const { array, needRemove } = handleFindInactiveBatches(data.boCauHoi);
                    // boCauHoi.filter(i => !i.isActive && i.hasOwnProperty("isActive")).map(i => i.tenBoCauHoi)
                    // console.log("boCauHoi", boCauHoi);

                    // console.log("boCauHoi", boCauHoi.filter(i => {
                    //     if (typeof i?.isActive === 'boolean' && !i?.isActive) return false
                    //     return true
                    // }));

                    this.progressBarValue = calculateProcess(array)

                    resolve(array)
                })
                .catch((error) => (reject(error)));
        })
    }

    getUserSurveyInfo(id) {
        return new Promise((resolve, reject) => {
            this.request
                .get('/api/v1/info-surveyuser?userId=' + id)
                .then(({ data }) => {
                    resolve(data)
                })
                .catch((error) => (reject(error)));
        })
    }

    calStrokeBigProcess(totalPercent) {
        const value = 440 - (220 * totalPercent * 2) / 100
        return `stroke-dashoffset: ${value};`
    }
    calStrokeBigProcessOld() {
        const value = 440 - (220 * this.progressBarValue * 2) / 100
        return `stroke-dashoffset: ${value};`
    }

    calBatchProcessIPhone(id, { lichSuTraLoi, soCauHoi }) {
        const element = document.getElementById('batchProcess-' + id)

        if (element) {
            if (!lichSuTraLoi) element.style.background = `conic-gradient(#7c67f7 0deg, #c6c6c8 0deg)`
            else {
                const percent = lichSuTraLoi.daTraLoi * 100 / soCauHoi
                const value = percent * 360 / 100
                element.style.background = `conic-gradient(rgb(106 159 253) ${value}deg, #c6c6c8 ${value}deg)`
            }
        }
    }

    calStrokeBigProcessIPhone() {
        const element = document.getElementById('allBatchProcess')
        const value = this.progressBarValue * 360 / 100
        if (element) element.style.background = `conic-gradient(rgb(106 159 253) ${value}deg, #c6c6c8 ${value}deg)`

        console.log("navigator", navigator.userAgent);

    }

    calBatchProcess(percentEachSection) {


            const percent = 100
            const value = 440 - (220 * percent) / 100

            return `stroke-dashoffset: ${value};`

    }
    calBatchProcessOld({ lichSuTraLoi, soCauHoi }) {
        if (!lichSuTraLoi) return `stroke-dashoffset: calc(440 - (440 * 0) / 100);`
        else {
            const percent = lichSuTraLoi.daTraLoi * 100 / soCauHoi
            const value = 440 - (220 * percent) / 100

            return `stroke-dashoffset: ${value};`
        }
    }

    handleBatchStyle(item) {
        if (typeof item.isActive === 'undefined') return 'question-box'
        return 'question-box ' + (!item?.isActive ? ' no-drop opacity-5 ' : '')
    }
    handleSubmitKpelogInformation(globalId){
        return new Promise((resolve, reject) => {
            this.request
                .post('/api/v1/event/submit-thong-tin-demo' ,{"globalId":globalId})
                .then((res) => {

                    //this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    isIphone() {
        return false
    }

    updateInformationKpelog(payload){
        return new Promise((resolve, reject) => {
            this.request
                .put('/api/v1/supporter/update-tt-chung-kh' , payload)
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    updateInformationBasicKpelog(payload){
        return new Promise((resolve, reject) => {
            this.request
                .put('/api/v1/supporter/update-tt-co-ban-kh' , payload)
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    updateInformationAdvanceKpelog(payload){
        return new Promise((resolve, reject) => {
            this.request
                .put('/api/v1/supporter/update-tt-nang-cao-kh' , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    updateInformationServiceKpelog(payload){
        return new Promise((resolve, reject) => {
            this.request
                .put('/api/v1/supporter/update-tt-dich-vu-kh' , payload)
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    checkMissingData(id) {
        return new Promise((resolve, reject) => {
            this.request
                .get('/api/v1/event/check-missing-data?userId=' + id)
                .then((res) => {
                    this.store.dispatch({ type: types.DETAIL_USERS, payload: res.data });
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    checkPhoneDuplicate(phoneNumber) {
        return new Promise((resolve, reject) => {
            this.request
                .get('/api/v1/supporter/check-phone-duplicate?phone=' + phoneNumber)
                .then((res) => {
                    this.store.dispatch({ type: types.DETAIL_USERS, payload: res.data });
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    getTCVByMaTCV(maTCV) {
        return new Promise((resolve, reject) => {
            this.request
                .get('/api/v1/supporter/get-tcv-by-matcv?maTCV=' + maTCV)
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    getTenXetNghiemVien(maXNV) {
        return new Promise((resolve, reject) => {
            this.request
                .get('/api/v1/supporter/xet-nghiem-vien?maXetNghiemVien=' + maXNV)
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    saveHIVTest(payload) {
        return new Promise((resolve, reject) => {
            this.request
                .post('/api/v1/supporter/save-xet-nghiem-sang-loc' , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    saveHistoryPrep(payload) {
        return new Promise((resolve, reject) => {
            this.request
                .post('/api/v1/supporter/save-lich-su-prep' , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    saveHistoryPep(payload) {
        return new Promise((resolve, reject) => {
            this.request
                .post('/api/v1/supporter/save-lich-su-pep' , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    saveHistoryArv(payload) {
        return new Promise((resolve, reject) => {
            this.request
                .post('/api/v1/supporter/save-lich-su-dieu-tri-arv' , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    saveComplianceHistoryArv(payload) {
        return new Promise((resolve, reject) => {
            this.request
                .post('/api/v1/supporter/save-lich-su-tuan-thu-arv' , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    saveHistoryViolence(payload) {
        return new Promise((resolve, reject) => {
            this.request
                .post('/api/v1/supporter/save-lich-su-nguy-co-bao-hanh' , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    saveHistorySexHealth(payload) {
        return new Promise((resolve, reject) => {
            this.request
                .post('/api/v1/supporter/save-lich-su-sktd-skss' , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }

    saveHistoryChangeHabit(payload) {
        return new Promise((resolve, reject) => {
            this.request
                .post('/api/v1/supporter/save-lich-su-thay-doi-hanh-vi' , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    saveHistoryMethodReduce(payload) {
        return new Promise((resolve, reject) => {
            this.request
                .post('/api/v1/supporter/save-lich-su-bien-phap-giam-hai' , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    saveHistoryShareHIV(payload) {
        return new Promise((resolve, reject) => {
            this.request
                .post('/api/v1/supporter/save-lich-su-chia-se-hiv' , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    saveHistorySexualIllness(payload) {
        return new Promise((resolve, reject) => {
            this.request
                .post('/api/v1/supporter/save-lich-su-benh-tinh-duc' , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    saveHistoryOtherSend(payload) {
        return new Promise((resolve, reject) => {
            this.request
                .post('/api/v1/supporter/save-lich-su-chuyen-gui-khac' , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    saveHistoryRiskFactor(payload) {
        return new Promise((resolve, reject) => {
            this.request
                .post('/api/v1/supporter/save-lich-su-yeu-to-nguy-co' , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    saveHistoryBTBC(payload) {
        return new Promise((resolve, reject) => {
            this.request
                .post('/api/v1/supporter/save-lich-su-ban-tinh-ban-chich' , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    saveHistoryTest(payload) {
        return new Promise((resolve, reject) => {
            this.request
                .post('/api/v1/supporter/save-lich-su-tien-su-xet-nghiem-hiv' , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    saveHistoryTreatmentArv(payload) {
        return new Promise((resolve, reject) => {
            this.request
                .post('/api/v1/supporter/save-lich-su-tinh-trang-dieu-tri-arv' , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    saveHistoryPrepAdvanced(payload) {
        return new Promise((resolve, reject) => {
            this.request
                .post('/api/v1/supporter/save-lich-su-tinh-trang-prep' , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }

    saveHistoryFavoriteService(payload) {
        return new Promise((resolve, reject) => {
            this.request
                .post('/api/v1/supporter/save-lich-su-dich-vu-yeu-thich' , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    updateHistoryHIVTest(_id, payload) {
        return new Promise((resolve, reject) => {
            this.request
                .put('/api/v1/supporter/save-xet-nghiem-sang-loc/'+ _id , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    updateHistoryPrep(_id, payload) {
        return new Promise((resolve, reject) => {
            this.request
                .put('/api/v1/supporter/save-lich-su-prep/'+ _id , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    updateHistoryPep(_id, payload) {
        return new Promise((resolve, reject) => {
            this.request
                .put('/api/v1/supporter/save-lich-su-pep/'+ _id , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    updateHistoryTreatmentArv(_id, payload) {
        return new Promise((resolve, reject) => {
            this.request
                .put('/api/v1/supporter/save-lich-su-dieu-tri-arv/'+ _id , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    updateHistoryComplianceArv(_id, payload) {
        return new Promise((resolve, reject) => {
            this.request
                .put('/api/v1/supporter/save-lich-su-tuan-thu-arv/'+ _id , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    updateHistoryViolence(_id, payload) {
        return new Promise((resolve, reject) => {
            this.request
                .put('/api/v1/supporter/save-lich-su-nguy-co-bao-hanh/'+ _id , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    updateHistorySexHealth(_id, payload) {
        return new Promise((resolve, reject) => {
            this.request
                .put('/api/v1/supporter/save-lich-su-sktd-skss/'+ _id , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    updateHistoryChangeHabit(_id, payload) {
        return new Promise((resolve, reject) => {
            this.request
                .put('/api/v1/supporter/save-lich-su-thay-doi-hanh-vi/'+ _id , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    updateHistoryReduceMethod(_id, payload) {
        return new Promise((resolve, reject) => {
            this.request
                .put('/api/v1/supporter/save-lich-su-bien-phap-giam-hai/'+ _id , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    updateHistoryShareHIV(_id, payload) {
        return new Promise((resolve, reject) => {
            this.request
                .put('/api/v1/supporter/save-lich-su-chia-se-hiv/'+ _id , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    updateHistorySexualIllness(_id, payload) {
        return new Promise((resolve, reject) => {
            this.request
                .put('/api/v1/supporter/save-lich-su-benh-tinh-duc/'+ _id , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    updateHistoryOtherMethod(_id, payload) {
        return new Promise((resolve, reject) => {
            this.request
                .put('/api/v1/supporter/save-lich-su-chuyen-gui-khac/'+ _id , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    updateHistoryRiskFactor(_id, payload) {
        return new Promise((resolve, reject) => {
            this.request
                .put('/api/v1/supporter/save-lich-su-yeu-to-nguy-co/'+ _id , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    updateHistoryBTBC(_id, payload) {
        return new Promise((resolve, reject) => {
            this.request
                .put('/api/v1/supporter/save-lich-su-ban-tinh-ban-chich/'+ _id , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    updateHistoryFavoriteService(_id, payload) {
        return new Promise((resolve, reject) => {
            this.request
                .put('/api/v1/supporter/save-lich-su-dich-vu-yeu-thich/'+ _id , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    updateHistoryTestHiv(_id, payload) {
        return new Promise((resolve, reject) => {
            this.request
                .put('/api/v1/supporter/save-lich-su-tien-su-xet-nghiem-hiv/'+ _id , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    editHistoryTreatmentArv(_id, payload) {
        return new Promise((resolve, reject) => {
            this.request
                .put('/api/v1/supporter/save-lich-su-tinh-trang-dieu-tri-arv/'+ _id , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    editHistoryPrepAdvanced(_id, payload) {
        return new Promise((resolve, reject) => {
            this.request
                .put('/api/v1/supporter/save-lich-su-tinh-trang-prep/'+ _id , payload)
                .then((res) => {
                    this.store.dispatch({ type: types.LIST_USERS, payload: res.data});
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    uploadFile(file) {
        const formData = new FormData()
        formData.append('file', file)
        return new Promise((resolve, reject) => {
            this.request
                .post('/api/v1/upload', formData)
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    checkCBO() {
        return new Promise((resolve, reject) => {
            this.request
                .get('/api/v1/supporter/check-supporter-belong-to-new-features')
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    validateSttTiepCan(tenDuAn, maCBO, sttTiepCan, userId) {
        return new Promise((resolve, reject) => {
            this.request
                .get(`/api/v1/supporter/validate-stt-tiep-can?userId=${userId}&tenDuAn=${tenDuAn}&maCBO=${maCBO}&sttTiepCan=${sttTiepCan}`)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    validateSttHCDC(tenDuAn, maCBO, sttHCDC, userId) {
        return new Promise((resolve, reject) => {
            this.request
                .get(`/api/v1/supporter/validate-stt-hcdc?userId=${userId}&tenDuAn=${tenDuAn}&maCBO=${maCBO}&soTTHCDC=${sttHCDC}`)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    validateSttXNSL(tenDuAn, maCBO, sttXNSL, userId) {
        return new Promise((resolve, reject) => {
            this.request
                .get(`/api/v1/supporter/validate-stt-xnsl?userId=${userId}&tenDuAn=${tenDuAn}&maCBO=${maCBO}&soTTXNSL=${sttXNSL}`)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}


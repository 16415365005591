import { Component, OnInit } from "@angular/core";
import { GlobalFunc } from "../../../base/base";
import { AlertController, ModalController } from "@ionic/angular";
import { ActivatedRoute } from "@angular/router";
import { CustomerService } from "src/app/services/customer.service";
import { Store } from "@ngrx/store";
import * as moment from "moment";
import { SurveyHistoryComponent } from "../../../components/survey-history/survey-history/survey-history.component";
import { HistoryPrepComponent } from "../../../components/survey-history/history-prep/history-prep.component";
import { HistoryPepComponent } from "../../../components/survey-history/history-pep/history-pep.component";
import { HistoryTreatmentArvComponent } from "../../../components/survey-history/history-treatment-arv/history-treatment-arv.component";
import { HistoryComplianceArvComponent } from "../../../components/survey-history/history-compliance-arv/history-compliance-arv.component";
import { HistoryViolenceComponent } from "../../../components/survey-history/history-violence/history-violence.component";
import { HistorySexHealthComponent } from "../../../components/survey-history/history-sex-health/history-sex-health.component";
import { HistoryMethodReduceComponent } from "../../../components/survey-history/history-method-reduce/history-method-reduce.component";
import { HistoryShareHivComponent } from "../../../components/survey-history/history-share-hiv/history-share-hiv.component";
import { SexualIllnessComponent } from "../../../components/survey-history/sexual-illness/sexual-illness.component";
import { HistoryOtherSendComponent } from "../../../components/survey-history/history-other-send/history-other-send.component";
import { HistoryChangeHabbitComponent } from "../../../components/survey-history/history-change-habbit/history-change-habbit.component";
import { ModalResetHistoryComponent } from "../../../components/survey-history/modal-reset-history/modal-reset-history.component";

@Component({
  selector: "app-service-information",
  templateUrl: "./service-information.component.html",
  styleUrls: ["./service-information.component.scss"],
})
export class ServiceInformationComponent implements OnInit {
  constructor(
    private global: GlobalFunc,
    public atrCtrl: AlertController,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private store: Store<any>,
    private modalCtrl: ModalController
  ) {}
  message =
    "This modal example uses the modalController to present and dismiss modals.";

  customerId = this.route.snapshot.paramMap.get("id");

  isOpenModalMenu = false;
  payloadForUpdate = {};
  missingFieldArr = [];
  fakeUser = {};
  today = moment(new Date()).format("YYYY-MM-DD");
  sendDay = new Date().getTime();
  fakeUser1 = {
    kindOfTest: [
      "CBO làm xét nghiệm cho khách hàng",
      "Khách hàng tự xét nghiệm, có hỗ trợ",
      "Khách hàng tự xét nghiệm, không hỗ trợ",
    ],
    biologicalName: [
      "Determine",
      "Alere Combo",
      "OraQuick",
      "INSTI",
      "SD Bioline",
      "HIV/Syphilis Duo",
      "Mylan",
      "Khác",
    ],
    resultHIV: [
      "Có phản ứng",
      "Không phản ứng",
      "Không theo dõi được",
      "Không xác định",
    ],
    resultAntigen: [
      "Kháng nguyên +",
      "Kháng thể +",
      "Kháng nguyên + và kháng thể +",
    ],
    selfTest: [
      "Nhận trực tiếp từ CBO",
      "Nhận thông qua BTBC",
      "Nhận qua dịch vụ giao hàng",
    ],
    selfTestOrder: ["Đặt qua D.Health", "Đặt qua kênh khác"],
    kindTest: [
      "Xét nghiệm khẳng định huyết thanh",
      "Xét nghiệm khẳng định PCR",
      "Xét nghiệm sàng lọc",
    ],
    bloodUnit: [
      "HCM-63",
      "HCM-66",
      "HCM-68",
      "HCM-69",
      "HCM-71",
      "HCM-78",
      "HCM-81",
      "HCM-83",
      "HCM-BTX",
      "HCM-SGP",
      "HCM-61",
      "HCM-62",
      "HCM-64",
      "HCM-65",
      "HCM-67",
      "HCM-70",
      "HCM-72",
      "HCM-73",
      "HCM-22",
      "HCM-75",
      "HCM-76",
      "HCM-77",
      "HCM-79",
      "HCM-80",
      "HCM-82",
      "HCM-84",
      "HCM-21",
      "79499",
      "79414",
      "QĐ-01",
      "79428",
      "79400",
      "79993",
      "79013",
      "79030",
      "79024",
      "79040",
      "HCM-26",
      "PASTEUR",
      "79048",
      "HCM-98",
      "HCM-11",
      "HCM-46",
      "HCM-15",
      "HCM-28",
      "HCM-04-01",
      "HCM-06",
      "HCM-27",
      "HCM-05",
      "HCM-85",
      "HCM-04-03",
      "HCM-21-02",
      "HCM-25",
      "HCM-20",
      "79075",
      "79405",
      "79408",
      "79022",
      "79034",
      "79011",
      "HCDC",
      "HCM-23",
      "HCM-24",
      "HCM-29",
      "HCM-87",
      "HCM-89",
      "HCM-79-11",
      "HCM-79-12",
      "HCM-76-12",
      "HCM-76-13",
      "HCM-64-19",
      "HCM-64-20",
      "HCM-67-11",
      "HCM-67-12",
      "HCM-66-15",
      "HCM-78-13",
      "HCM-78-14",
      "HCM-75-16",
      "HCM-75-17",
      "HCM-63-15",
      "HCM-63-16",
      "HCM-61-11",
      "HCM-61-12",
      "HCM-62-10",
      "HCM-62-11",
      "KHÁC",
    ],
    HIVUnit: ["HCM25", "HCM26", "HCM27", "HCM61", "HCM62", "HCM63", "Khác"],

    treatmentARVFirstMonth: [
      "Không quên liều",
      "Có quên liều",
      "Ngưng uống thuốc",
    ],
    treatmentARVSecondMonth: [
      "Không quên liều",
      "Có quên liều",
      "Ngưng uống thuốc",
    ],
    treatmentARVThirdMonth: [
      "Không quên liều",
      "Có quên liều",
      "Ngưng uống thuốc",
    ],
    treatmentHIV: ["Không quên liều", "Có quên liều", "Ngưng uống thuốc"],
    treatmentPrepFirstMonth: [
      "Không quên liều",
      "Có quên liều",
      "Ngưng uống thuốc",
    ],
    treatmentPrepSecondMonth: [
      "Không quên liều",
      "Có quên liều",
      "Ngưng uống thuốc",
    ],
    treatmentPrepThirdMonth: [
      "Không quên liều",
      "Có quên liều",
      "Ngưng uống thuốc",
    ],
    hivInPublic: ["Có", "Không"],
    dayRecieveSelftest: ["22/12/2022"],
    arvStatus: ["Chưa điều trị", "Đang điều trị", "Tự ngưng thuốc"],
    prepStatus: ["Chưa điều trị", "Đang điều trị", "Tự ngưng thuốc"],
    deliveryFacility: [
      {
        name: "TTYT Quận 1",
        value: "HCM-61",
      },
      {
        name: "TTYT Tp.Thủ Đức – KV1",
        value: "HCM-62",
      },
      {
        name: "TTYT Quận 3",
        value: "HCM-63",
      },
      {
        name: "TTYT Quận 4",
        value: "HCM-64",
      },
      {
        name: "TTYT Quận 5",
        value: "HCM-65",
      },
      {
        name: "TTYT Quận 6",
        value: "HCM-66",
      },
      {
        name: "TTYT Quận 7",
        value: "HCM-67",
      },
      {
        name: "TTYT Quận 8",
        value: "HCM-68",
      },
      {
        name: "TTYT Tp.Thủ Đức – KV2",
        value: "HCM-69",
      },
      {
        name: "TTYT Quận 10",
        value: "HCM-70",
      },
      {
        name: "TTYT Quận 11",
        value: "HCM-71",
      },
      {
        name: "TTYT Quận 12",
        value: "HCM-72",
      },
      {
        name: "TTYT Quận Phú Nhuận",
        value: "HCM-73",
      },
      {
        name: "TTYT Quận Bình Thạnh",
        value: "HCM-22",
      },
      {
        name: "TTYT Quận Tân Bình",
        value: "HCM-75",
      },
      {
        name: "TTYT Quận Tân Phú",
        value: "HCM-76",
      },
      {
        name: "TTYT Quận Gò Vấp",
        value: "HCM-77",
      },
      {
        name: "TTYT Tp.Thủ Đức – KV3",
        value: "HCM-78",
      },
      {
        name: "TTYT Quận Bình Tân",
        value: "HCM-79",
      },
      {
        name: "TTYT Huyện Bình Chánh",
        value: "HCM-80",
      },
      {
        name: "TTYT Huyện Hóc Môn",
        value: "HCM-81",
      },
      {
        name: "TTYT Huyện Củ Chi",
        value: "HCM-82",
      },
      {
        name: "TTYT Huyện Nhà Bè",
        value: "HCM-83",
      },
      {
        name: "TTYT Huyện Cần Giờ",
        value: "HCM-84",
      },
      {
        name: "BV Da Liễu",
        value: "HCM-15",
      },
      {
        name: "BV Nhân Ái",
        value: "HCM-46",
      },
      {
        name: "BV Bệnh Nhiệt Đới",
        value: "HCM-11",
      },
      {
        name: "BV Phạm Ngọc Thạch",
        value: "79499",
      },
      {
        name: "BV Tp.Thủ Đức",
        value: "HCM-98",
      },
      {
        name: "BV Từ Dũ",
        value: "79414",
      },
      {
        name: "BV Hùng Vương",
        value: "79400",
      },
      {
        name: "BV Nguyễn Tri Phương",
        value: "79013",
      },
      {
        name: "BV Nhân dân Gia Định",
        value: "79030",
      },
      {
        name: "Viện Pasteur Tp.HCM",
        value: "PASTEUR",
      },
      {
        name: "BV Chợ Rẫy",
        value: "79048",
      },
      {
        name: "BV Lê Văn Việt (BV Quận 9 cũ)",
        value: "79022",
      },
      {
        name: "BV 175",
        value: "79034",
      },
      {
        name: "Trung tâm CTXH Ánh Dương",
        value: "HCM-21",
      },
      {
        name: "Phòng khám Mai Khôi",
        value: "HCM-85",
      },
      {
        name: "Phòng khám Galant",
        value: "HCM-26",
      },
      {
        name: "Phòng khám Thành Danh",
        value: "HCM-27",
      },
      {
        name: "Phòng khám Pride Health",
        value: "HCM-07",
      },
      {
        name: "Phòng khám Alo Health",
        value: "HCM-28",
      },
      {
        name: "Phòng khám Alo Care",
        value: "HCM-25",
      },
      {
        name: "Phòng khám Nhà Mình",
        value: "HCM-20",
      },
      {
        name: "Cty TNHH PTCĐ Hoàng Thiên Phúc",
        value: "HCM-05",
      },
      {
        name: "Trung tâm CARMAH",
        value: "HCM-06",
      },
      {
        name: "Hội phòng chống AIDS",
        value: "HCM-04-01",
      },
      {
        name: "DNXH Bầu Trời Xanh",
        value: "HCM-BTX",
      },
      {
        name: "DNXH Sài Gòn Pride",
        value: "HCM-SGP",
      },
      {
        name: "Phòng khám Đa Khoa Quốc Tế An Hảo",
        value: "HCM-23",
      },
      {
        name: "Phòng khám TESTSGN",
        value: "HCM-29",
      },
      {
        name: "BV Nhân dân 115",
        value: "79024",
      },
      {
        name: "BV ĐKKV Củ Chi",
        value: "79040",
      },
      {
        name: "Trung tâm Xét Nghiệm Diag",
        value: "HCM-DIAG",
      },
      {
        name: "Phòng khám MCARE",
        value: "HCM-87",
      },
      {
        name: "Khác",
        value: "1",
      },
    ],
    sendAnotherService: ["Điều trị nPEP", "Theo dõi nhiễm HIV cấp", "Khác"],
    reasonForget: [
      "Quên",
      "Tác dụng phụ",
      "Sợ bị kỳ thị",
      "Không thuận tiện",
      "Tiêu tốn nhiều tiền",
      "Hết thuốc và tự dừng thuốc",
      "Hết thuốc nhưng chưa được nhận thêm/không mua được thuốc",
      "Không còn cần thiết/Hết hành vi nguy cơ ",
      "Khác",
    ],
  };
  coSoDongNai = [
    {
      name: "BV Da Liễu Đồng Nai",
      value: "2",
    },
    {
      name: "BVĐK Đồng Nai",
      value: "3",
    },
    {
      name: "BVĐK Long Khánh",
      value: "45",
    },
    {
      name: "BVĐK Thống Nhất",
      value: "12",
    },
    {
      name: "CDC Đồng Nai",
      value: "22",
    },
    {
      name: "TTYT Biên Hòa",
      value: "11",
    },
    {
      name: "TTYT Long Khánh",
      value: "44",
    },
    {
      name: "TTYT Long Thành",
      value: "32",
    },
    {
      name: "TTYT Xuân Lộc",
      value: "46",
    },
    {
      name: "PK Alo Care Đồng Nai",
      value: "02",
    },
    {
      name: "PK Glink Đồng Nai",
      value: "87",
    },
    {
      name: "TYT Hóa An",
      value: "95",
    },
    {
      name: "TYT Lộc An",
      value: "29",
    },
    {
      name: "TYT Long Bình Tân",
      value: "91",
    },
    {
      name: "TYT Long Thành",
      value: "100",
    },
    {
      name: "PXN GNET",
      value: "127",
    },
    {
      name: "Khác",
      value: "1",
    },
  ];
  coSoHaNoi = [
    {
      name: "BV 198",
      value: "100",
    },
    {
      name: "BV Ba Vì",
      value: "21",
    },
    {
      name: "BV Bạch Mai",
      value: "22",
    },
    {
      name: "BV Bệnh Nhiệt Đới Trung Ương",
      value: "011",
    },
    {
      name: "BV Da Liễu Hà Nội",
      value: "655",
    },
    {
      name: "BV Đại học Y Hà Nội",
      value: "89",
    },
    {
      name: "BV Đống Đa",
      value: "26",
    },
    {
      name: "BV Hà Đông",
      value: "97",
    },
    {
      name: "BV Phổi Hà Nội",
      value: "53",
    },
    {
      name: "BV Vân Đình",
      value: "232",
    },
    {
      name: "CDC Hà Nội",
      value: "123",
    },
    {
      name: "TTYT Ba Vì",
      value: "1111",
    },
    {
      name: "TTYT Đống Đa",
      value: "111",
    },
    {
      name: "TTYT Hà Đông",
      value: "9788",
    },
    {
      name: "TTYT Hai Bà Trưng",
      value: "554",
    },
    {
      name: "TTYT Hoàng Mai",
      value: "332",
    },
    {
      name: "TTYT Long Biên",
      value: "333",
    },
    {
      name: "TTYT Nam Từ Liêm",
      value: "212",
    },
    {
      name: "TTYT Tây Hồ",
      value: "222",
    },
    {
      name: "TTYT Thanh Xuân",
      value: "22223",
    },
    {
      name: "PK Biển Việt",
      value: "88",
    },
    {
      name: "PK Glink Hà Nội",
      value: "9886",
    },
    {
      name: "PK Hải Đăng",
      value: "324",
    },
    {
      name: "PK Medlatec",
      value: "645",
    },
    {
      name: "PK Minh Ngọc",
      value: "755",
    },
    {
      name: "PK SHP",
      value: "4334",
    },
    {
      name: "Khác",
      value: "1",
    },
  ];
  styleKPElog = "#f3f7fc";
  isShowHIVInPublic = false;
  isShowAntiARV = false;
  isShowAntiARV2 = false;
  isShowComplianceARV = false;
  isShowPrep = false;
  isShowPep = false;
  isShowPepTest = false;
  dieuTriARV = "";
  dieuTriPrep = "";
  hinhThucXNSangLoc = "";
  ketQuaXNKhangNguyenKhangThe = "";
  ketQuaXNSangLoc = "";
  ngayChuyenGui = null;
  ngayNhanSP = null;
  tenSinhPham = "";
  tenSinhPhamKhac = "";
  xnSangLocTaiCD = "";
  coSoLamXNKhangDinh = "";
  coSoLamXNKhangDinhKhac = "";
  loading = false;
  ngayThayDoiHvi = "";
  ngayThayDoiHviCoHai = "";
  ngayTvanTtrangHIV = "";
  ngayKhachHangDongY = "";
  ngayXetNghiemHIV = "";
  ngayCBOBietKqua = "";
  ngayTraKquaHIV = "";
  ngayBdauDtri = "";
  ngayBdauDtriPrep = "";
  ngayBdauDtriNpep = "";
  ngayTvanSucKhoeTinhDuc = "";
  tuVanThayDoiHanhVi = "";
  _idChangeHabit = "";
  ngayTuVanThayDoiHanhVi = null;
  tuVanHanhViCoHaiChoSK = "";
  _idReduceMethod = "";
  ngayTuVanHanhViCoHaiChoSK = null;
  tuVanChiaSeTTHIV = "";
  _idShareHIV = "";
  ngayTuVanChiaSeTTHIV = null;
  tuVanBTBC = "";
  _idViolence = "";
  khachDongYKhaiBaoBTBC = "";
  ngayKhachDongYKhaiBaoBTBC = null;
  soBTBCKhaiBao = "";
  soBanTinhBanChichThuongXuyen = "";
  soBanTinhBanChichBatChot = "";
  ngayXNSangLocTaiCD = "";
  soTTXNSangLoc = "";
  maXNSangLoc = "";
  _idHIVTest = "";
  hinhThucNhanSP = "";
  hinhThucDatSP = "";
  soSPNhanVeChoBT = 1;
  soSPNhanVeChoBTBC = 0;
  soSPNhanVeChoBanNguoiThan = 0;
  cgXetNghiemKhangDinh = "";
  ngayCBOCoKQ = null;
  ngayTraKQXNKD = null;
  ketQuaXNKD = "";
  loaiXetNghiem = "";
  donViLayMau = "";
  donViLayMauKhac = "";
  maXNKD = "";
  chuyenGuiARV = "";
  _idTreatmentArv = "";
  coSoCgDieuTriARV = "";
  coSoCgDieuTriARVKhac = "";
  liDoKHTuChoi = "";
  ngayBDDieuTri = null;
  ngayCgDieuTriARV = null;
  donViDieuTriHIV = "";
  donViDieuTriHIVKhac = "";
  maSoDieuTriHIV = "";
  dieuTriArvQuaBHYT = "";
  ttDieuTriARV = "";
  ttDieuTriHIV1 = "";
  ttDieuTriHIV2 = "";
  ttDieuTriHIV3 = "";
  kqXNTLVR = "";
  _idComplianceArv = "";
  ngayXNTLVR = null;
  ketQuaCD4 = "";
  ngayXNCD4 = null;
  duTCPrep = "";
  dongYPrepSauTuVan = "";
  _idPrep = "";
  cgPrep = "";
  coSoCgDieuTriPrep = "";
  coSoCgDieuTriPrepKhac = "";
  lyDoKhongPrep = "";
  ngayBDPrep = null;
  ngayCgDieuTriPrep = null;
  donViPrep = "";
  donViPrepKhac = "";
  ttPrep1 = "";
  ttPrep2 = "";
  ttPrep3 = "";
  ttPrep = "";
  cgPEP = "";
  dongYPepSauTuVan = "";
  lyDoKhongPEP = "";
  coSoCgDieuTriPEP = "";
  coSoCgDieuTriPEPKhac = "";
  ngayBatDatnPEP = null;
  ngayCgDieuTriPep = null;
  coSonPEP = "";
  coSonPEPKhac = "";
  maDieuTrinPEP = "";
  dieuTriPEP = "";
  _idPep = "";
  cgDichVuKhac = "";
  _idOtherMethod = "";
  cgKhac = "";
  tuVanSKTD = "";
  _idSexHealth = "";
  ngayTuVanSKTD = null;
  kqXNGiangMai = "";
  _idSexualIllness = "";
  ngayXNGiangMai = null;
  ngayBDTiepCan = null;
  isValidateNgaycg: boolean;
  ngayBDTiepCanPlus1Month = null;
  isGreaterThan1Month: boolean;
  maXetNghiemVien = "";
  maXetNghiemVienTheoHCDC = "";
  tenXetNghiemVien = "";
  tinhDuAn = "";
  maCBO = "";
  maSoDieuTriPrep = "";
  provideService = "";
  ngayCungCapDV = null;
  isShowSexualHealth = false;
  isShowHelpViolence = false;
  isShowChangeHabit = false;
  isShowBadHabit = false;
  isShowShareHIVStatus = false;
  isShowOtherService = false;
  soLieuDaQuenHIV1 = "";
  soNgayNgungHIV1 = "";
  lyDoQuenHIV1 = "";
  khacHIV1 = "";
  soLieuDaQuenHIV2 = "";
  soNgayNgungHIV2 = "";
  lyDoQuenHIV2 = "";
  khacHIV2 = "";
  soLieuDaQuenHIV3 = "";
  soNgayNgungHIV3 = "";
  lyDoQuenHIV3 = "";
  khacHIV3 = "";
  soLieuDaQuenPrep1 = "";
  soNgayNgungPrep1 = "";
  lyDoQuenPrep1 = "";
  khacPrep1 = "";
  soLieuDaQuenPrep2 = "";
  soNgayNgungPrep2 = "";
  lyDoQuenPrep2 = "";
  khacPrep2 = "";
  soLieuDaQuenPrep3 = "";
  soNgayNgungPrep3 = "";
  lyDoQuenPrep3 = "";
  khacPrep3 = "";
  isEmpty = false;
  maTCV = "";
  maTCVTheoHCDC = "";
  tenTCV = "";
  tenDuAn = "";
  tienSuXetNghiemHIV = "";
  tinhTrangDieuTriARV = "";
  cgDieuTriARV = "";
  tinhTrangPrep = "";
  tinhTrangNhiemHIV = "";
  countXNSL = 0;
  countPrep = 0;
  countPep = 0;
  countDieuTriArv = 0;
  countTTArv = 0;
  countBaoHanh = 0;
  countSKTD = 0;
  countThayDoiHvi = 0;
  countBPGiamHai = 0;
  countChiaSeHiv = 0;
  countBenhTd = 0;
  countCgKhac = 0;
  isSendHIVResult: boolean;
  isResultHIV: boolean;
  isSendResultPrep: boolean;
  isCompliancePrep: boolean;
  isTreatmentPrepStatus: boolean;
  isTreatmentPep: boolean;
  isResultARV: boolean;
  isTreatmentARVStatus: boolean;
  isTLVR: boolean;
  isCD4: boolean;
  validateXNSL = false;
  validatePrep = false;
  validatePep = false;
  validateTreatmentArv = false;
  validateViolence = false;
  validateSexualIllness = false;
  isCreateHivTest = false;
  isCreateNewPrep = false;
  isCreateNewPep = false;
  isCreateTreatmentArv = false;
  isCreateComplianceArv = false;
  isCreateNewViolence = false;
  isCreateNewSexHealth = false;
  isCreateNewChangeHabit = false;
  isCreateNewMethodReduce = false;
  isCreateNewShareHIV = false;
  isCreateNewSexIllness = false;
  isCreateNewOtherMethod = false;
  ngayTaoXnsl = null;
  ngayTaoCgXnKhangDinh = null;
  ngayTaoKqXnKhangDinh = null;
  ngayTaoDieuTriArv = null;
  ngayTaoKqArv = null;
  ngayTaoTinhTrangArv = null;
  ngayTaoPrep = null;
  ngayTaoKqDieuTriPrep = null;
  ngayTaoTuanThuPrep = null;
  ngayTaoTinhTrangPrep = null;
  ngayTaoPep = null;
  ngayTaoKqPep = null;
  ngayTaoTuanThuArv = null;
  ngayTaoKqTlvr = null;
  ngayTaoKqCD4 = null;
  canUpdateXnsl = true;
  canUpdateCgXnKhangDinh = true;
  canUpdateKqXnKhangDinh = true;
  canUpdatePrep = true;
  canUpdateKqDieuTriPrep = true;
  canUpdateTuanThuPrep = true;
  canUpdateTinhTrangPrep = true;
  canUpdatePep = true;
  canUpdateKqPep = true;
  canUpdateDieuTriArv = true;
  canUpdateKqArv = true;
  canUpdateTinhTrangArv = true;
  canUpdateTuanThuArv = true;
  canUpdateKqTlvr = true;
  canUpdateKqCD4 = true;
  maHCDC = "";
  soTTTheoHCDC = "";
  maCBOTheoHCDC = "";
  updateRelatedHistory = false;
  updateRelateHistoryPrep = false;
  updateRelateHistoryTreatmentArv = false;
  updateRelateHistoryPep = false;
  updateRelateHistoryComplianceArv = false;
  updateRelateHistoryViolence = false;
  updateRelateHistorySexHealth = false;
  updateRelateHistoryChangeHabit = false;
  updateRelateHistoryReduceMethod = false;
  updateRelateHistoryShareHiv = false;
  updateRelateHistorySexIllness = false;
  updateRelateHistoryOtherMethod = false;
  ngayCgAfterToday = false;
  ngayTraXNKDAfterToday = false;
  ngayCgPrepAfterToday = false;
  ngayXNAfterNgayCg = false;
  ngayBDTCAfterNgayXNSL = false;
  ngayTraXNKDAfterNgayXNSL = false;
  ngayCgPrepAfterNgayXNSL = false;
  ngayCgPepAfterNgayXNSL = false;
  ngayCgArvAfterNgayXNSL = false;
  ngayDieuTriPrepAfterNgayXNSL = false;
  ngayDieuTriPrepAfterToday = false;
  ngayDieuTriPepAfterNgayXNSL = false;
  ngayDieuTriPepAfterToday = false;
  ngayDieuTriHIVAfterNgayXNSL = false;
  ngayDieuTriHIVAfterToday = false;
  ngayCgArvAfterToday = false;
  ngayCgPepAfterToday = false;
  isLoadingSubmit = false;
  isLoadingHivTest = false;
  isLoadingViolence = false;
  isLoadingSexHealth = false;
  isLoadingChangeHabit = false;
  isLoadingReduceMethod = false;
  isLoadingSexIlness = false;
  isLoadingShareHiv = false;
  isLoadingOtherSend = false;
  isLoadingPrep = false;
  isLoadingPep = false;
  isLoadingTreatmentArv = false;
  isLoadingComplianceArv = false;
  valueUploadFile = "";
  valueUploadFilePrep = "";
  ngayXNAfterToday = false;
  isSubmitHIVTest = false;
  isSubmitPrep = false;
  dateCreateBasicInfo = null;
  isBasicInfoCreateSameDay = false;
  invalidNumberOfMedicine = false;
  async openModal() {
    const modal = await this.modalCtrl.create({
      component: SurveyHistoryComponent,
      cssClass: "history-test",
      componentProps: {
        customerId: this.customerId,
      },
    });
    await modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === "confirm") {
      this.message = `Hello, ${data}!`;
    }
  }
  async openModalHistoryPrep() {
    const modal1 = await this.modalCtrl.create({
      component: HistoryPrepComponent,
      cssClass: "history-test",
      componentProps: {
        customerId: this.customerId,
      },
    });
    await modal1.present();

    const { data, role } = await modal1.onWillDismiss();

    if (role === "confirm") {
      this.message = `Hello, ${data}!`;
    }
  }
  async openModalHistoryPep() {
    const modal2 = await this.modalCtrl.create({
      component: HistoryPepComponent,
      cssClass: "history-test",
      componentProps: {
        customerId: this.customerId,
      },
    });
    await modal2.present();

    const { data, role } = await modal2.onWillDismiss();

    if (role === "confirm") {
      this.message = `Hello, ${data}!`;
    }
  }
  async openModalHistoryTreatmentARV() {
    const modal3 = await this.modalCtrl.create({
      component: HistoryTreatmentArvComponent,
      cssClass: "history-test",
      componentProps: {
        customerId: this.customerId,
      },
    });
    await modal3.present();

    const { data, role } = await modal3.onWillDismiss();

    if (role === "confirm") {
      this.message = `Hello, ${data}!`;
    }
  }
  async openModalHistoryComplianceARV() {
    const modal4 = await this.modalCtrl.create({
      component: HistoryComplianceArvComponent,
      cssClass: "history-test",
      componentProps: {
        customerId: this.customerId,
      },
    });
    await modal4.present();

    const { data, role } = await modal4.onWillDismiss();

    if (role === "confirm") {
      this.message = `Hello, ${data}!`;
    }
  }
  async openModalHistoryViolence() {
    const modal5 = await this.modalCtrl.create({
      component: HistoryViolenceComponent,
      cssClass: "history-test",
      componentProps: {
        customerId: this.customerId,
      },
    });
    await modal5.present();

    const { data, role } = await modal5.onWillDismiss();

    if (role === "confirm") {
      this.message = `Hello, ${data}!`;
    }
  }
  async openModalHistorySexHealth() {
    const modal6 = await this.modalCtrl.create({
      component: HistorySexHealthComponent,
      cssClass: "history-test",
      componentProps: {
        customerId: this.customerId,
      },
    });
    await modal6.present();

    const { data, role } = await modal6.onWillDismiss();

    if (role === "confirm") {
      this.message = `Hello, ${data}!`;
    }
  }
  async openModalHistoryReduceMethod() {
    const modal7 = await this.modalCtrl.create({
      component: HistoryMethodReduceComponent,
      cssClass: "history-test",
      componentProps: {
        customerId: this.customerId,
      },
    });
    await modal7.present();

    const { data, role } = await modal7.onWillDismiss();

    if (role === "confirm") {
      this.message = `Hello, ${data}!`;
    }
  }
  async openModalHistoryShareHIV() {
    const modal8 = await this.modalCtrl.create({
      component: HistoryShareHivComponent,
      cssClass: "history-test",
      componentProps: {
        customerId: this.customerId,
      },
    });
    await modal8.present();

    const { data, role } = await modal8.onWillDismiss();

    if (role === "confirm") {
      this.message = `Hello, ${data}!`;
    }
  }
  async openModalHistorySexualIllness() {
    const modal9 = await this.modalCtrl.create({
      component: SexualIllnessComponent,
      cssClass: "history-test",
      componentProps: {
        customerId: this.customerId,
      },
    });
    await modal9.present();

    const { data, role } = await modal9.onWillDismiss();

    if (role === "confirm") {
      this.message = `Hello, ${data}!`;
    }
  }
  async openModalHistoryOtherSend() {
    const modal10 = await this.modalCtrl.create({
      component: HistoryOtherSendComponent,
      cssClass: "history-test",
      componentProps: {
        customerId: this.customerId,
      },
    });
    await modal10.present();

    const { data, role } = await modal10.onWillDismiss();

    if (role === "confirm") {
      this.message = `Hello, ${data}!`;
    }
  }
  async openModalHistoryChangeHabit() {
    const modal11 = await this.modalCtrl.create({
      component: HistoryChangeHabbitComponent,
      cssClass: "history-test",
      componentProps: {
        customerId: this.customerId,
      },
    });
    await modal11.present();

    const { data, role } = await modal11.onWillDismiss();

    if (role === "confirm") {
      this.message = `Hello, ${data}!`;
    }
  }
  async openModalResetHistory() {
    const modal12 = await this.modalCtrl.create({
      component: ModalResetHistoryComponent,
      cssClass: "reset-history",
    });
    await modal12.present();

    const { role } = await modal12.onWillDismiss();

    if (role === "confirm") {
      this.isCreateHivTest = true;
      this.xnSangLocTaiCD = "";
      this.maXNSangLoc = "";
      this.hinhThucXNSangLoc = "";
      this.ketQuaXNKhangNguyenKhangThe = "";
      this.ketQuaXNSangLoc = "";
      this.ngayChuyenGui = null;
      this.ngayNhanSP = null;
      this.tenSinhPham = "";
      this.tenSinhPhamKhac = "";
      this.coSoLamXNKhangDinh = "";
      this.coSoLamXNKhangDinhKhac = "";
      this.ngayXNSangLocTaiCD = null;
      this.soTTXNSangLoc = "";
      this.hinhThucNhanSP = "";
      this.soSPNhanVeChoBT = null;
      this.soSPNhanVeChoBTBC = 0;
      this.soSPNhanVeChoBanNguoiThan = 0;
      this.cgXetNghiemKhangDinh = "";
      this.ngayCBOBietKqua = null;
      this.ngayTraKQXNKD = null;
      this.ketQuaXNKD = "";
      this.loaiXetNghiem = "";
      this.donViLayMau = "";
      this.maXNKD = "";
      this.maXetNghiemVienTheoHCDC = "";
      this.tenXetNghiemVien = "";
      this.updateRelatedHistory = true;
      this.valueUploadFile = "";
      this.soTTTheoHCDC = "";
      this.maHCDC = "";
      this.canUpdateXnsl = true;
      this.canUpdateKqXnKhangDinh = true;
      this.canUpdateCgXnKhangDinh = true;
    }
  }
  async openModalResetPrep() {
    const modal13 = await this.modalCtrl.create({
      component: ModalResetHistoryComponent,
      cssClass: "reset-history",
    });
    await modal13.present();

    const { role } = await modal13.onWillDismiss();

    if (role === "confirm") {
      this.isCreateNewPrep = true;
      this.duTCPrep = "";
      this.dongYPrepSauTuVan = "";
      this.cgPrep = "";
      this.coSoCgDieuTriPrep = "";
      this.lyDoKhongPrep = "";
      this.ngayBDPrep = null;
      this.ngayCgDieuTriPrep = null;
      this.donViPrep = "";
      this.ttPrep1 = "";
      this.ttPrep2 = "";
      this.ttPrep3 = "";
      this.ttPrep = "";
      this.dieuTriPrep = "";
      this.maSoDieuTriPrep = "";
      this.updateRelateHistoryPrep = true;
      this.valueUploadFilePrep = "";
      this.canUpdateTinhTrangPrep = true;
      this.canUpdateKqDieuTriPrep = true;
      this.canUpdatePrep = true;
      this.canUpdateTuanThuPrep = true;
    }
  }
  async openModalResetPep() {
    const modal14 = await this.modalCtrl.create({
      component: ModalResetHistoryComponent,
      cssClass: "reset-history",
    });
    await modal14.present();

    const { role } = await modal14.onWillDismiss();

    if (role === "confirm") {
      this.isCreateNewPep = true;
      this.dieuTriPEP = "";
      this.cgPEP = "";
      this.dongYPepSauTuVan = "";
      this.coSoCgDieuTriPEP = "";
      this.lyDoKhongPEP = "";
      this.ngayBatDatnPEP = null;
      this.ngayCgDieuTriPep = null;
      this.coSonPEP = "";
      this.maDieuTrinPEP = "";
      this.updateRelateHistoryPep = true;
      this.canUpdateKqPep = true;
      this.canUpdatePep = true;
    }
  }
  async openModalResetTreamentARV() {
    const modal15 = await this.modalCtrl.create({
      component: ModalResetHistoryComponent,
      cssClass: "reset-history",
    });
    await modal15.present();

    const { role } = await modal15.onWillDismiss();

    if (role === "confirm") {
      this.isCreateTreatmentArv = true;
      this.chuyenGuiARV = "";
      this.coSoCgDieuTriARV = "";
      this.liDoKHTuChoi = "";
      this.ngayBDDieuTri = null;
      this.ngayCgDieuTriARV = null;
      this.dieuTriARV = "";
      this.donViDieuTriHIV = "";
      this.maSoDieuTriHIV = "";
      this.dieuTriArvQuaBHYT = "";
      this.ttDieuTriARV = "";
      this.updateRelateHistoryTreatmentArv = true;
      this.canUpdateTinhTrangArv = true;
      this.canUpdateKqArv = true;
      this.canUpdateDieuTriArv = true;
    }
  }
  async openModalResetComplianceARV() {
    const modal16 = await this.modalCtrl.create({
      component: ModalResetHistoryComponent,
      cssClass: "reset-history",
    });
    await modal16.present();

    const { role } = await modal16.onWillDismiss();

    if (role === "confirm") {
      this.isCreateComplianceArv = true;
      this.ttDieuTriHIV1 = "";
      this.ttDieuTriHIV2 = "";
      this.ttDieuTriHIV3 = "";
      this.soLieuDaQuenHIV1 = "";
      this.soNgayNgungHIV1 = "";
      this.ttDieuTriHIV1 = "";
      this.khacHIV1 = "";
      this.soLieuDaQuenHIV2 = "";
      this.soNgayNgungHIV2 = "";
      this.ttDieuTriHIV2 = "";
      this.khacHIV2 = "";
      this.soLieuDaQuenHIV3 = "";
      this.soNgayNgungHIV3 = "";
      this.ttDieuTriHIV3 = "";
      this.khacHIV3 = "";
      this.kqXNTLVR = "";
      this.ngayXNTLVR = null;
      this.ketQuaCD4 = "";
      this.ngayXNCD4 = null;
      this.updateRelateHistoryComplianceArv = true;
      this.canUpdateKqTlvr = true;
      this.canUpdateKqCD4 = true;
      this.canUpdateTuanThuArv = true;
    }
  }
  async openModalResetViolence() {
    const modal17 = await this.modalCtrl.create({
      component: ModalResetHistoryComponent,
      cssClass: "reset-history",
    });
    await modal17.present();

    const { role } = await modal17.onWillDismiss();

    if (role === "confirm") {
      this.isCreateNewViolence = true;
      this.tuVanBTBC = "";
      this.khachDongYKhaiBaoBTBC = "";
      this.ngayKhachDongYKhaiBaoBTBC = null;
      this.soBTBCKhaiBao = "";
      this.soBanTinhBanChichThuongXuyen = "";
      this.soBanTinhBanChichBatChot = "";
      this.ngayCungCapDV = null;
      this.updateRelateHistoryViolence = true;
    }
  }
  async openModalResetSexHealth() {
    const modal18 = await this.modalCtrl.create({
      component: ModalResetHistoryComponent,
      cssClass: "reset-history",
    });
    await modal18.present();

    const { role } = await modal18.onWillDismiss();

    if (role === "confirm") {
      this.isCreateNewSexHealth = true;
      this.tuVanSKTD = "";
      this.ngayTuVanSKTD = null;
      this.updateRelateHistorySexHealth = true;
    }
  }
  async openModalResetChangeHabit() {
    const modal19 = await this.modalCtrl.create({
      component: ModalResetHistoryComponent,
      cssClass: "reset-history",
    });
    await modal19.present();

    const { role } = await modal19.onWillDismiss();

    if (role === "confirm") {
      this.isCreateNewChangeHabit = true;
      this.tuVanThayDoiHanhVi = "";
      this.ngayTuVanThayDoiHanhVi = null;
      this.updateRelateHistoryChangeHabit = true;
    }
  }
  async openModalResetMethodReduce() {
    const modal20 = await this.modalCtrl.create({
      component: ModalResetHistoryComponent,
      cssClass: "reset-history",
    });
    await modal20.present();

    const { role } = await modal20.onWillDismiss();

    if (role === "confirm") {
      this.isCreateNewMethodReduce = true;
      this.tuVanHanhViCoHaiChoSK = "";
      this.ngayTuVanHanhViCoHaiChoSK = null;
      this.updateRelateHistoryReduceMethod = true;
    }
  }
  async openModalResetShareHIV() {
    const modal21 = await this.modalCtrl.create({
      component: ModalResetHistoryComponent,
      cssClass: "reset-history",
    });
    await modal21.present();

    const { role } = await modal21.onWillDismiss();

    if (role === "confirm") {
      this.isCreateNewShareHIV = true;
      this.tuVanChiaSeTTHIV = "";
      this.ngayTuVanChiaSeTTHIV = null;
      this.updateRelateHistoryShareHiv = true;
    }
  }
  async openModalResetSexIllness() {
    const modal22 = await this.modalCtrl.create({
      component: ModalResetHistoryComponent,
      cssClass: "reset-history",
    });
    await modal22.present();

    const { role } = await modal22.onWillDismiss();

    if (role === "confirm") {
      this.isCreateNewSexIllness = true;
      this.kqXNGiangMai = "";
      this.ngayXNGiangMai = null;
      this.updateRelateHistorySexIllness = true;
    }
  }
  async openModalResetOtherMethod() {
    const modal23 = await this.modalCtrl.create({
      component: ModalResetHistoryComponent,
      cssClass: "reset-history",
    });
    await modal23.present();

    const { role } = await modal23.onWillDismiss();

    if (role === "confirm") {
      this.isCreateNewOtherMethod = true;
      this.cgDichVuKhac = "";
      this.cgKhac = "";
      this.updateRelateHistoryOtherMethod = true;
    }
  }

  ionViewWillEnter() {
    if (this.tinhDuAn === "Tỉnh Đồng Nai") {
      this.fakeUser1.deliveryFacility = this.coSoDongNai;
    }
    this.fakeUser = this.fakeUser1;
    this.getDetailSupport();
    this.isValidateNgaycg = false;
  }

  ngOnInit() {
    this.fakeUser = this.fakeUser1;
    localStorage.setItem("isNew", "0");

    this.getDetailSupport();

    this.isValidateNgaycg = false;

    this.customerService
      .getDetailUsersSendKPElog(this.customerId)
      .then((res: any) => {
        this.countXNSL = res.ttDichVu.xetNghiemSangLocHIV.length;
        this.countPrep =
          res.ttDichVu.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length;
        this.countPep =
          res.ttDichVu.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP.length;
        this.countDieuTriArv = res.ttDichVu.hoTroDangKiDieuTriARV.length;
        this.countTTArv = res.ttDichVu.hoTroTuanThuDieuTriARV.length;
        this.countBaoHanh = res.ttDichVu.tuVanVaHoTroUngPhoNguyCoBaoHanh.length;
        this.countSKTD =
          res.ttDichVu.tuVanSucKhoeTinhDucVaSucKhoeSinhSan.length;
        this.countThayDoiHvi = res.ttDichVu.tuVanThayDoiHanhVi.length;
        this.countBPGiamHai = res.ttDichVu.tuVanCanBienPhapGiamHai.length;
        this.countChiaSeHiv = res.ttDichVu.tuVanChiaSeTinhTrangHIV.length;
        this.countBenhTd =
          res.ttDichVu.tuVanVaPhatHienSomCacBenhLayQuaDuongTinhDuc.length;
        this.countCgKhac = res.ttDichVu.chuyenGuiCacDichVuDuPhongKhac.length;
        this.loading = false;
        this.maCBO = res?.ttChung.maCBO ? res?.ttChung?.maCBO : "";
        this.tinhDuAn = res?.ttChung?.tinhDuAn ? res?.ttChung?.tinhDuAn : "";
        if (this.tinhDuAn === "Thành phố Hồ Chí Minh") {
          this.maXetNghiemVien = res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.maXetNghiemVien
            ? res?.ttDichVu?.xetNghiemSangLocHIV[
                res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
              ]?.maXetNghiemVien
            : "LTHCM" + res?.ttChung?.maCBO;
        } else if (this.tinhDuAn === "Tỉnh Đồng Nai") {
          this.maXetNghiemVien = res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.maXetNghiemVien
            ? res?.ttDichVu?.xetNghiemSangLocHIV[
                res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
              ]?.maXetNghiemVien
            : "LTDNA" + res?.ttChung?.maCBO;
        } else if (this.tinhDuAn === "Thành phố Hà Nội") {
          this.maXetNghiemVien = res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.maXetNghiemVien
            ? res?.ttDichVu?.xetNghiemSangLocHIV[
                res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
              ]?.maXetNghiemVien
            : "LTHAN" + res?.ttChung?.maCBO;
        }
        this.maTCV = res?.ttChung?.maTCV;
        this.maTCVTheoHCDC = res?.ttChung?.maTCVTheoHCDC;
        this.tenTCV = res?.ttChung?.tenTCV;
        this.tenDuAn = res?.ttChung?.tenDuAn;
        this.tinhTrangNhiemHIV =
          res?.ttNangCao?.tienSuXetNghiemHIV[
            res?.ttNangCao?.tienSuXetNghiemHIV.length - 1
          ]?.tinhTrangNhiemHIV;
        this.tienSuXetNghiemHIV =
          res?.ttNangCao?.tienSuXetNghiemHIV[
            res?.ttNangCao?.tienSuXetNghiemHIV.length - 1
          ]?.tienSuXetNghiemHIV;
        this.tinhTrangDieuTriARV =
          res?.ttNangCao?.tinhTrangDieuTriARV[
            res?.ttNangCao?.tinhTrangDieuTriARV.length - 1
          ]?.tinhTrangDieuTriARV;
        this.cgDieuTriARV =
          res?.ttNangCao?.tinhTrangDieuTriARV[
            res?.ttNangCao?.tinhTrangDieuTriARV.length - 1
          ]?.cgDieuTriARV;
        this.tinhTrangPrep =
          res?.ttNangCao?.tinhTrangPrep[
            res?.ttNangCao?.tinhTrangPrep.length - 1
          ]?.tinhTrangPrep;
        if (this.tinhDuAn === "Tỉnh Đồng Nai") {
          this.fakeUser1.deliveryFacility = this.coSoDongNai;
        } else if (this.tinhDuAn === "Thành phố Hà Nội") {
          this.fakeUser1.deliveryFacility = this.coSoHaNoi;
        }
      });
  }

  getDetailSupport() {
    this.isValidateNgaycg = false;
    this.loading = true;
    this.customerService
      .getDetailUsersSendKPElog(this.customerId)
      .then((res: any) => {
        this.loading = false;
        this.ngayBDTiepCan = res?.ttCoBan?.ngayBDTiepCan
          ? moment(res?.ttCoBan?.ngayBDTiepCan).format("YYYY-MM-DD")
          : "";
        this.dieuTriARV =
          res?.ttDichVu?.hoTroDangKiDieuTriARV[
            res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
          ]?.dieuTriARV;
        if (this.dieuTriARV) {
          this.isResultARV = true;
        }
        this.dieuTriPrep =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.dieuTriPrep;
        if (this.dieuTriPrep) {
          this.isSendResultPrep = true;
        }
        if (
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.tenSinhPham?.value === "HIV/Syphilys Duo"
        ) {
          this.tenSinhPham = "HIV/Syphilis Duo";
        } else {
          this.tenSinhPham = res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.tenSinhPham?.value
            ? res?.ttDichVu?.xetNghiemSangLocHIV[
                res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
              ]?.tenSinhPham?.value
            : "";
        }

        this.tenSinhPhamKhac = res?.ttDichVu?.xetNghiemSangLocHIV[
          res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
        ]?.tenSinhPham?.khac
          ? res?.ttDichVu?.xetNghiemSangLocHIV[
              res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
            ]?.tenSinhPham?.khac
          : "";
        this.coSoLamXNKhangDinh = res?.ttDichVu?.xetNghiemSangLocHIV[
          res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
        ]?.coSoLamXNKhangDinh?.value?.name
          ? res?.ttDichVu?.xetNghiemSangLocHIV[
              res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
            ]?.coSoLamXNKhangDinh?.value
          : "";
        this.coSoLamXNKhangDinhKhac = res?.ttDichVu?.xetNghiemSangLocHIV[
          res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
        ]?.coSoLamXNKhangDinh?.khac
          ? res?.ttDichVu?.xetNghiemSangLocHIV[
              res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
            ]?.coSoLamXNKhangDinh?.khac
          : "";
        this.dieuTriPEP =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP.length - 1
          ]?.dieuTriPEP;
        if (this.dieuTriPEP) {
          this.isTreatmentPep = true;
        }
        this._idPep =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP.length - 1
          ]?._id;
        this.tuVanThayDoiHanhVi =
          res?.ttDichVu?.tuVanThayDoiHanhVi[
            res?.ttDichVu?.tuVanThayDoiHanhVi.length - 1
          ]?.tuVanThayDoiHanhVi;
        this._idChangeHabit =
          res?.ttDichVu?.tuVanThayDoiHanhVi[
            res?.ttDichVu?.tuVanThayDoiHanhVi.length - 1
          ]?._id;
        this.ngayTuVanThayDoiHanhVi = this.formatGetDate(
          res?.ttDichVu?.tuVanThayDoiHanhVi[
            res?.ttDichVu?.tuVanThayDoiHanhVi.length - 1
          ]?.ngayTuVanThayDoiHanhVi
        );

        this.tuVanHanhViCoHaiChoSK =
          res?.ttDichVu?.tuVanCanBienPhapGiamHai[
            res?.ttDichVu?.tuVanCanBienPhapGiamHai.length - 1
          ]?.tuVanHanhViCoHaiChoSK;
        this._idReduceMethod =
          res?.ttDichVu?.tuVanCanBienPhapGiamHai[
            res?.ttDichVu?.tuVanCanBienPhapGiamHai.length - 1
          ]?._id;
        this.ngayTuVanHanhViCoHaiChoSK = this.formatGetDate(
          res?.ttDichVu?.tuVanCanBienPhapGiamHai[
            res?.ttDichVu?.tuVanCanBienPhapGiamHai.length - 1
          ]?.ngayTuVanHanhViCoHaiChoSK
        );
        this.tuVanChiaSeTTHIV =
          res?.ttDichVu?.tuVanChiaSeTinhTrangHIV[
            res?.ttDichVu?.tuVanChiaSeTinhTrangHIV.length - 1
          ]?.tuVanChiaSeTTHIV;
        this._idShareHIV =
          res?.ttDichVu?.tuVanChiaSeTinhTrangHIV[
            res?.ttDichVu?.tuVanChiaSeTinhTrangHIV.length - 1
          ]?._id;
        this.ngayTuVanChiaSeTTHIV = this.formatGetDate(
          res?.ttDichVu?.tuVanChiaSeTinhTrangHIV[
            res?.ttDichVu?.tuVanChiaSeTinhTrangHIV.length - 1
          ]?.ngayTuVanChiaSeTTHIV
        );

        this.tuVanBTBC =
          res?.ttDichVu?.tuVanVaHoTroUngPhoNguyCoBaoHanh[
            res?.ttDichVu?.tuVanVaHoTroUngPhoNguyCoBaoHanh.length - 1
          ]?.tuVanBTBC;
        this._idViolence =
          res?.ttDichVu?.tuVanVaHoTroUngPhoNguyCoBaoHanh[
            res?.ttDichVu?.tuVanVaHoTroUngPhoNguyCoBaoHanh.length - 1
          ]?._id;
        this.khachDongYKhaiBaoBTBC =
          res?.ttDichVu?.tuVanVaHoTroUngPhoNguyCoBaoHanh[
            res?.ttDichVu?.tuVanVaHoTroUngPhoNguyCoBaoHanh.length - 1
          ]?.khachDongYKhaiBaoBTBC;
        this.ngayKhachDongYKhaiBaoBTBC = this.formatGetDate(
          res?.ttDichVu?.tuVanVaHoTroUngPhoNguyCoBaoHanh[
            res?.ttDichVu?.tuVanVaHoTroUngPhoNguyCoBaoHanh.length - 1
          ]?.ngayKhachDongYKhaiBaoBTBC
        );
        this.soBTBCKhaiBao =
          res?.ttDichVu?.tuVanVaHoTroUngPhoNguyCoBaoHanh[
            res?.ttDichVu?.tuVanVaHoTroUngPhoNguyCoBaoHanh.length - 1
          ]?.soBTBCKhaiBao;
        this.soBanTinhBanChichThuongXuyen =
          res?.ttDichVu?.tuVanVaHoTroUngPhoNguyCoBaoHanh[
            res?.ttDichVu?.tuVanVaHoTroUngPhoNguyCoBaoHanh.length - 1
          ]?.soBanTinhBanChichThuongXuyen;
        this.soBanTinhBanChichBatChot =
          res?.ttDichVu?.tuVanVaHoTroUngPhoNguyCoBaoHanh[
            res?.ttDichVu?.tuVanVaHoTroUngPhoNguyCoBaoHanh.length - 1
          ]?.soBanTinhBanChichBatChot;
        this.xnSangLocTaiCD =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.xnSangLocTaiCD;
        this.ngayXNSangLocTaiCD = this.formatGetDateNeedAuto(
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.ngayXNSangLocTaiCD
        );
        this.soTTXNSangLoc = res?.ttDichVu?.xetNghiemSangLocHIV[
          res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
        ]?.soTTXNSangLoc
          ? res?.ttDichVu?.xetNghiemSangLocHIV[
              res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
            ]?.soTTXNSangLoc
          : "";
        this.maXNSangLoc =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.maXNSangLoc;
        this._idHIVTest =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?._id;
        this.hinhThucXNSangLoc =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.hinhThucXNSangLoc;
        this.ketQuaXNSangLoc =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.ketQuaXNSangLoc;
        this.soTTTheoHCDC =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.soTTTheoHCDC;
        this.ketQuaXNKhangNguyenKhangThe =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.ketQuaXNKhangNguyenKhangThe;
        this.hinhThucNhanSP =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.hinhThucNhanSP;
        this.hinhThucDatSP =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.hinhThucDatSP;
        this.ngayNhanSP = this.formatGetDate(
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.ngayNhanSP
        );
        this.valueUploadFile =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.phieuDongYXNKD;
        this.soSPNhanVeChoBT = res?.ttDichVu?.xetNghiemSangLocHIV[
          res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
        ]?.soSPNhanVeChoBT
          ? res?.ttDichVu?.xetNghiemSangLocHIV[
              res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
            ]?.soSPNhanVeChoBT
          : 1;
        this.soSPNhanVeChoBTBC = res?.ttDichVu?.xetNghiemSangLocHIV[
          res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
        ]?.soSPNhanVeChoBTBC
          ? res?.ttDichVu?.xetNghiemSangLocHIV[
              res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
            ]?.soSPNhanVeChoBTBC
          : 0;
        this.soSPNhanVeChoBanNguoiThan = res?.ttDichVu?.xetNghiemSangLocHIV[
          res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
        ]?.soSPNhanVeChoBanNguoiThan
          ? res?.ttDichVu?.xetNghiemSangLocHIV[
              res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
            ]?.soSPNhanVeChoBanNguoiThan
          : 0;
        this.cgXetNghiemKhangDinh =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.cgXetNghiemKhangDinh;
        if (this.cgXetNghiemKhangDinh) {
          this.isSendHIVResult = true;
        }
        this.ngayChuyenGui = this.formatGetDateNeedAuto(
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.ngayChuyenGui
        );
        this.ngayCBOCoKQ = this.formatGetDate(
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.ngayCBOBietKqua
        );

        this.ngayTraKQXNKD = this.formatGetDateNeedAuto(
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.ngayTraKQXNKD
        );

        this.ketQuaXNKD =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.ketQuaXNKD;
        this.loaiXetNghiem =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.loaiXetNghiem;
        this.donViLayMau = res?.ttDichVu?.xetNghiemSangLocHIV[
          res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
        ]?.donViLayMau?.value
          ? res?.ttDichVu?.xetNghiemSangLocHIV[
              res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
            ]?.donViLayMau?.value
          : "";
        this.donViLayMauKhac = res?.ttDichVu?.xetNghiemSangLocHIV[
          res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
        ]?.donViLayMau?.khac
          ? res?.ttDichVu?.xetNghiemSangLocHIV[
              res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
            ]?.donViLayMau?.khac
          : "";
        this.maXNKD =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.maXNKD;
        if (
          this.ngayCBOCoKQ ||
          this.ngayTraKQXNKD ||
          this.ketQuaXNKD ||
          this.loaiXetNghiem ||
          this.donViLayMau ||
          this.maXNKD
        ) {
          this.isResultHIV = true;
        }

        this.chuyenGuiARV =
          res?.ttDichVu?.hoTroDangKiDieuTriARV[
            res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
          ]?.chuyenGuiARV;
        this._idTreatmentArv =
          res?.ttDichVu?.hoTroDangKiDieuTriARV[
            res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
          ]?._id;
        this.liDoKHTuChoi =
          res?.ttDichVu?.hoTroDangKiDieuTriARV[
            res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
          ]?.liDoKHTuChoi;
        this.ngayBDDieuTri = this.formatGetDateNeedAuto(
          res?.ttDichVu?.hoTroDangKiDieuTriARV[
            res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
          ]?.ngayBDDieuTri
        );

        this.ngayCgDieuTriARV = this.formatGetDateNeedAuto(
          res?.ttDichVu?.hoTroDangKiDieuTriARV[
            res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
          ]?.ngayCgDieuTriARV
        );

        this.donViDieuTriHIV = res?.ttDichVu?.hoTroDangKiDieuTriARV[
          res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
        ]?.donViDieuTriHIV?.value
          ? res?.ttDichVu?.hoTroDangKiDieuTriARV[
              res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
            ]?.donViDieuTriHIV?.value
          : "";
        this.donViDieuTriHIVKhac = res?.ttDichVu?.hoTroDangKiDieuTriARV[
          res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
        ]?.donViDieuTriHIV?.khac
          ? res?.ttDichVu?.hoTroDangKiDieuTriARV[
              res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
            ]?.donViDieuTriHIV?.khac
          : "";
        this.coSoCgDieuTriARV = res?.ttDichVu?.hoTroDangKiDieuTriARV[
          res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
        ]?.coSoCgDieuTriARV?.value
          ? res?.ttDichVu?.hoTroDangKiDieuTriARV[
              res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
            ]?.coSoCgDieuTriARV?.value
          : "";
        this.coSoCgDieuTriARVKhac = res?.ttDichVu?.hoTroDangKiDieuTriARV[
          res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
        ]?.coSoCgDieuTriARV?.khac
          ? res?.ttDichVu?.hoTroDangKiDieuTriARV[
              res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
            ]?.coSoCgDieuTriARV?.khac
          : "";
        this.maSoDieuTriHIV =
          res?.ttDichVu?.hoTroDangKiDieuTriARV[
            res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
          ]?.maSoDieuTriHIV;
        this.dieuTriArvQuaBHYT =
          res?.ttDichVu?.hoTroDangKiDieuTriARV[
            res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
          ]?.dieuTriArvQuaBHYT;
        this.ttDieuTriARV =
          res?.ttDichVu?.hoTroDangKiDieuTriARV[
            res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
          ]?.ttDieuTriARV;
        if (this.ttDieuTriARV) {
          this.isTreatmentARVStatus = true;
        }
        this.ttDieuTriHIV1 = res?.ttDichVu?.hoTroTuanThuDieuTriARV[
          res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
        ]?.ttDieuTriHIV1?.value
          ? res?.ttDichVu?.hoTroTuanThuDieuTriARV[
              res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
            ]?.ttDieuTriHIV1?.value
          : "";
        this.ttDieuTriHIV2 = res?.ttDichVu?.hoTroTuanThuDieuTriARV[
          res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
        ]?.ttDieuTriHIV2?.value
          ? res?.ttDichVu?.hoTroTuanThuDieuTriARV[
              res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
            ]?.ttDieuTriHIV2?.value
          : "";
        this.ttDieuTriHIV3 = res?.ttDichVu?.hoTroTuanThuDieuTriARV[
          res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
        ]?.ttDieuTriHIV3?.value
          ? res?.ttDichVu?.hoTroTuanThuDieuTriARV[
              res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
            ]?.ttDieuTriHIV3?.value
          : "";
        this.soLieuDaQuenHIV1 =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ttDieuTriHIV1?.soLieuDaQuen;
        this.soNgayNgungHIV1 =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ttDieuTriHIV1?.soNgayNgung;
        this.lyDoQuenHIV1 =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ttDieuTriHIV1?.lyDoQuen;
        this.khacHIV1 =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ttDieuTriHIV1?.khac;
        this.soLieuDaQuenHIV2 =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ttDieuTriHIV2?.soLieuDaQuen;
        this.soNgayNgungHIV2 =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ttDieuTriHIV2?.soNgayNgung;
        this.lyDoQuenHIV2 =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ttDieuTriHIV2?.lyDoQuen;
        this.khacHIV2 =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ttDieuTriHIV2?.khac;
        this.soLieuDaQuenHIV3 =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ttDieuTriHIV3?.soLieuDaQuen;
        this.soNgayNgungHIV3 =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ttDieuTriHIV3?.soNgayNgung;
        this.lyDoQuenHIV3 =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ttDieuTriHIV3?.lyDoQuen;
        this.khacHIV3 =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ttDieuTriHIV3?.khac;
        this.kqXNTLVR =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.kqXNTLVR;
        this._idComplianceArv =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?._id;
        this.ngayXNTLVR = this.formatGetDate(
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ngayXNTLVR
        );
        if (this.kqXNTLVR || this.ngayXNTLVR) {
          this.isTLVR = true;
        }
        this.ketQuaCD4 =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ketQuaCD4;
        this.ngayXNCD4 = this.formatGetDate(
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ngayXNCD4
        );

        if (this.ketQuaCD4 || this.ngayXNCD4) {
          this.isCD4 = true;
        }
        this.duTCPrep =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.duTCPrep;
        this.dongYPrepSauTuVan =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.dongYPrepSauTuVan;
        this._idPrep =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?._id;
        this.cgPrep =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.cgPrep;
        this.coSoCgDieuTriPrep = res?.ttDichVu
          ?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
        ]?.coSoCgDieuTriPrep?.value
          ? res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
              res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
            ]?.coSoCgDieuTriPrep?.value
          : "";
        this.coSoCgDieuTriPrepKhac = res?.ttDichVu
          ?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
        ]?.coSoCgDieuTriPrep?.khac
          ? res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
              res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
            ]?.coSoCgDieuTriPrep?.khac
          : "";
        this.valueUploadFilePrep =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.phieuDongYPrep;
        this.lyDoKhongPrep =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.lyDoKhongPrep;
        this.ngayBDPrep = this.formatGetDateNeedAuto(
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ngayBDPrep
        );

        this.ngayCgDieuTriPrep = this.formatGetDateNeedAuto(
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ngayCgDieuTriPrep
        );

        this.donViPrep = res?.ttDichVu
          ?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
        ]?.donViPrep?.value
          ? res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
              res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
            ]?.donViPrep?.value
          : "";
        this.donViPrepKhac = res?.ttDichVu
          ?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
        ]?.donViPrep?.khac
          ? res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
              res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
            ]?.donViPrep?.khac
          : "";
        this.ttPrep1 = res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
        ]?.ttPrep1?.value
          ? res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
              res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
            ]?.ttPrep1?.value
          : "";
        this.soLieuDaQuenPrep1 =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ttPrep1?.soLieuDaQuen;
        this.soNgayNgungPrep1 =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ttPrep1?.soNgayNgung;
        this.lyDoQuenPrep1 =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ttPrep1?.lyDoQuen;
        this.khacPrep1 =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ttPrep1?.khac;
        this.soLieuDaQuenPrep2 =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ttPrep2?.soLieuDaQuenPrep2;
        this.soNgayNgungPrep2 =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ttPrep2?.soNgayNgung;
        this.lyDoQuenPrep2 =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ttPrep2?.lyDoQuen;
        this.khacPrep2 =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ttPrep2?.khac;
        this.soLieuDaQuenPrep3 =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ttPrep3?.soLieuDaQuen;
        this.soNgayNgungPrep3 =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ttPrep3?.soNgayNgung;
        this.lyDoQuenPrep3 =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ttPrep3?.lyDoQuen;
        this.khacPrep3 =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ttPrep3?.khac;
        this.ttPrep2 = res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
        ]?.ttPrep2?.value
          ? res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
              res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
            ]?.ttPrep2?.value
          : "";
        this.ttPrep3 = res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
        ]?.ttPrep3?.value
          ? res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
              res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
            ]?.ttPrep3?.value
          : "";
        if (this.ttPrep1 || this.ttPrep2 || this.ttPrep3) {
          this.isCompliancePrep = true;
        }
        this.ttPrep =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ttPrep;
        if (this.ttPrep) {
          this.isTreatmentPrepStatus = true;
        }
        this.cgPEP =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP.length - 1
          ]?.cgPEP;
        this.dongYPepSauTuVan =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP.length - 1
          ]?.dongYPepSauTuVan;
        this.coSoCgDieuTriPEP = res?.ttDichVu
          ?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP[
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP.length - 1
        ]?.coSoCgDieuTriPEP?.value
          ? res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP[
              res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP.length - 1
            ]?.coSoCgDieuTriPEP?.value
          : "";
        this.coSoCgDieuTriPEPKhac =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP.length - 1
          ]?.coSoCgDieuTriPEP?.khac;
        this.lyDoKhongPEP =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP.length - 1
          ]?.lyDoKhongPEP;
        this.ngayBatDatnPEP = this.formatGetDateNeedAuto(
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP.length - 1
          ]?.ngayBatDatnPEP
        );
        this.ngayCgDieuTriPep = this.formatGetDateNeedAuto(
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP.length - 1
          ]?.ngayCgDieuTriPep
        );

        this.coSonPEP = res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP[
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP.length - 1
        ]?.coSonPEP?.value
          ? res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP[
              res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP.length - 1
            ]?.coSonPEP?.value
          : "";
        this.coSonPEPKhac =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP.length - 1
          ]?.coSonPEP?.khac;

        this.maDieuTrinPEP =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP.length - 1
          ]?.maDieuTrinPEP;
        this.cgDichVuKhac =
          res?.ttDichVu?.chuyenGuiCacDichVuDuPhongKhac[
            res?.ttDichVu?.chuyenGuiCacDichVuDuPhongKhac.length - 1
          ]?.cgDichVuKhac;
        this._idOtherMethod =
          res?.ttDichVu?.chuyenGuiCacDichVuDuPhongKhac[
            res?.ttDichVu?.chuyenGuiCacDichVuDuPhongKhac.length - 1
          ]?._id;
        this.cgKhac =
          res?.ttDichVu?.chuyenGuiCacDichVuDuPhongKhac[
            res?.ttDichVu?.chuyenGuiCacDichVuDuPhongKhac.length - 1
          ]?.cgKhac;
        this.tuVanSKTD =
          res?.ttDichVu?.tuVanSucKhoeTinhDucVaSucKhoeSinhSan[
            res?.ttDichVu?.tuVanSucKhoeTinhDucVaSucKhoeSinhSan.length - 1
          ]?.tuVanSKTD;
        this.maSoDieuTriPrep =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.maSoDieuTriPrep;
        this._idSexHealth =
          res?.ttDichVu?.tuVanSucKhoeTinhDucVaSucKhoeSinhSan[
            res?.ttDichVu?.tuVanSucKhoeTinhDucVaSucKhoeSinhSan.length - 1
          ]?._id;
        this.ngayTuVanSKTD = this.formatGetDate(
          res?.ttDichVu?.tuVanSucKhoeTinhDucVaSucKhoeSinhSan[
            res?.ttDichVu?.tuVanSucKhoeTinhDucVaSucKhoeSinhSan.length - 1
          ]?.ngayTuVanSKTD
        );

        this.kqXNGiangMai =
          res?.ttDichVu?.tuVanVaPhatHienSomCacBenhLayQuaDuongTinhDuc[
            res?.ttDichVu?.tuVanVaPhatHienSomCacBenhLayQuaDuongTinhDuc.length -
              1
          ]?.kqXNGiangMai;
        this._idSexualIllness =
          res?.ttDichVu?.tuVanVaPhatHienSomCacBenhLayQuaDuongTinhDuc[
            res?.ttDichVu?.tuVanVaPhatHienSomCacBenhLayQuaDuongTinhDuc.length -
              1
          ]?._id;
        this.ngayXNGiangMai =
          this.tenSinhPham === "HIV/Syphilis Duo"
            ? this.ngayXNSangLocTaiCD
            : this.formatGetDate(
                res?.ttDichVu?.tuVanVaPhatHienSomCacBenhLayQuaDuongTinhDuc[
                  res?.ttDichVu?.tuVanVaPhatHienSomCacBenhLayQuaDuongTinhDuc
                    .length - 1
                ]?.ngayXNGiangMai
              );

        this.maXetNghiemVienTheoHCDC =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.maXetNghiemVienTheoHCDC;
        this.tenXetNghiemVien =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.tenXetNghiemVien;
        this.ngayCungCapDV =
          res?.ttDichVu?.tuVanVaHoTroUngPhoNguyCoBaoHanh[
            res?.ttDichVu?.tuVanVaHoTroUngPhoNguyCoBaoHanh.length - 1
          ]?.ngayCungCapDV;
        this.ngayTaoXnsl =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.ngayTaoXnsl;
        this.ngayTaoCgXnKhangDinh =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.ngayTaoCgXnKhangDinh;
        this.ngayTaoKqXnKhangDinh =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.ngayTaoKqXnKhangDinh;
        this.ngayTaoDieuTriArv =
          res?.ttDichVu?.hoTroDangKiDieuTriARV[
            res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
          ]?.ngayTaoDieuTriArv;
        this.ngayTaoKqArv =
          res?.ttDichVu?.hoTroDangKiDieuTriARV[
            res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
          ]?.ngayTaoKqArv;
        this.ngayTaoTinhTrangArv =
          res?.ttDichVu?.hoTroDangKiDieuTriARV[
            res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
          ]?.ngayTaoTinhTrangArv;
        this.ngayTaoPrep =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ngayTaoPrep;
        this.ngayTaoKqDieuTriPrep =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ngayTaoKqDieuTriPrep;
        this.ngayTaoTuanThuPrep =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ngayTaoTuanThuPrep;
        this.ngayTaoPep =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP.length - 1
          ]?.ngayTaoPep;
        this.ngayTaoKqPep =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP.length - 1
          ]?.ngayTaoKqPep;
        this.ngayTaoTuanThuArv =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ngayTaoTuanThuArv;
        this.ngayTaoKqTlvr =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ngayTaoKqTlvr;
        this.ngayTaoKqCD4 =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ngayTaoKqCD4;
        this.dateCreateBasicInfo = res?.ttCoBan?.createdAt
          ? res?.ttCoBan?.createdAt
          : null;

        this.isBasicInfoCreateSameDay = moment(this.today)
          .startOf("d")
          .isSame(moment(this.dateCreateBasicInfo).startOf("d"));
        if (this.today > this.checkDay10EveryMonth(this.ngayTaoXnsl)) {
          // this.canUpdateXnsl = false;
          this.canUpdateXnsl = true;
        }
        if (this.today > this.checkDay10EveryMonth(this.ngayTaoCgXnKhangDinh)) {
          // this.canUpdateCgXnKhangDinh = false;
          this.canUpdateCgXnKhangDinh = true;
        }
        if (this.today > this.checkDay10EveryMonth(this.ngayTaoKqXnKhangDinh)) {
          // this.canUpdateKqXnKhangDinh = false;
          this.canUpdateKqXnKhangDinh = true;
        }
        if (this.today > this.checkDay10EveryMonth(this.ngayTaoPrep)) {
          // this.canUpdatePrep = false;
          this.canUpdatePrep = true;
        }
        if (this.today > this.checkDay10EveryMonth(this.ngayTaoKqDieuTriPrep)) {
          // this.canUpdateKqDieuTriPrep = false;
          this.canUpdateKqDieuTriPrep = true;
        }
        if (this.today > this.checkDay10EveryMonth(this.ngayTaoTuanThuPrep)) {
          // this.canUpdateTuanThuPrep = false;
          this.canUpdateTuanThuPrep = true;
        }
        if (this.today > this.checkDay10EveryMonth(this.ngayTaoTinhTrangPrep)) {
          // this.canUpdateTinhTrangPrep = false;
          this.canUpdateTinhTrangPrep = true;
        }
        if (this.today > this.checkDay10EveryMonth(this.ngayTaoPep)) {
          // this.canUpdatePep = false;
          this.canUpdatePep = true;
        }
        if (this.today > this.checkDay10EveryMonth(this.ngayTaoKqPep)) {
          // this.canUpdateKqPep = false;
          this.canUpdateKqPep = true;
        }
        if (this.today > this.checkDay10EveryMonth(this.ngayTaoDieuTriArv)) {
          // this.canUpdateDieuTriArv = false;
          this.canUpdateDieuTriArv = true;
        }
        if (this.today > this.checkDay10EveryMonth(this.ngayTaoKqArv)) {
          // this.canUpdateKqArv = false;
          this.canUpdateKqArv = true;
        }
        if (this.today > this.checkDay10EveryMonth(this.ngayTaoTinhTrangArv)) {
          // this.canUpdateTinhTrangArv = false;
          this.canUpdateTinhTrangArv = true;
        }
        if (this.today > this.checkDay10EveryMonth(this.ngayTaoTuanThuArv)) {
          // this.canUpdateTuanThuArv = false;
          this.canUpdateTuanThuArv = true;
        }
        if (this.today > this.checkDay10EveryMonth(this.ngayTaoKqTlvr)) {
          // this.canUpdateKqTlvr = false;
          this.canUpdateKqTlvr = true;
        }
        if (this.today > this.checkDay10EveryMonth(this.ngayTaoKqCD4)) {
          // this.canUpdateKqCD4 = false;
          this.canUpdateKqCD4 = true;
        }
        this.maCBOTheoHCDC = res?.ttChung?.maCBOTheoHCDC;

        this.maHCDC = res?.ttDichVu?.xetNghiemSangLocHIV[
          res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
        ]?.maHCDC.includes("undefined")
          ? ""
          : res?.ttDichVu?.xetNghiemSangLocHIV[
              res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
            ]?.maHCDC;
        if (this.cgXetNghiemKhangDinh && this.ketQuaXNSangLoc) {
          this.isSubmitHIVTest = true;
        }
        if (this.cgPrep && this.duTCPrep === "Có") {
          this.isSubmitPrep = true;
        }
      });
  }

  goToPage(str) {
    this.global.goToPage(str);
  }

  handleDropdownHIVInPublic() {
    this.isShowHIVInPublic = !this.isShowHIVInPublic;
  }

  handleDieuTriPep(e) {
    this.dieuTriPEP = e.target.value;
    if (this.dieuTriPEP === "Đã điều trị") {
      this.cgDichVuKhac = "Điều trị nPEP";
      this.ngayBatDatnPEP = this.ngayXNSangLocTaiCD;
    } else if (this.dieuTriPEP === "Chưa điều trị") {
      this.ngayBatDatnPEP = null;
      this.coSonPEP = "";
      this.maDieuTrinPEP = "";
    }
  }

  handleDropdownAntiARV() {
    this.isShowAntiARV = !this.isShowAntiARV;
  }

  handleDropdownAntiARV2() {
    this.isShowAntiARV2 = !this.isShowAntiARV2;
  }

  handleDropdownComplianceARV() {
    this.isShowComplianceARV = !this.isShowComplianceARV;
  }

  handleDropdownPrep() {
    this.isShowPrep = !this.isShowPrep;
  }

  handleDropdownPep() {
    this.isShowPep = !this.isShowPep;
  }

  handleDropdownPepTest() {
    this.isShowPepTest = !this.isShowPepTest;
  }

  handleDropdownHelpViolence() {
    this.isShowHelpViolence = !this.isShowHelpViolence;
  }

  handleDropdownSexualHealth() {
    this.isShowSexualHealth = !this.isShowSexualHealth;
  }

  handleDropdownChangeHabit() {
    this.isShowChangeHabit = !this.isShowChangeHabit;
  }

  handleDropdownBadHabit() {
    this.isShowBadHabit = !this.isShowBadHabit;
  }

  handleDropdownShareHIVStatus() {
    this.isShowShareHIVStatus = !this.isShowShareHIVStatus;
  }

  handleDropdownOtherService() {
    this.isShowOtherService = !this.isShowOtherService;
  }

  async handleSaveAll() {
    const alertConfirm = this.atrCtrl.create({
      message:
        "Thông tin vừa được lưu vào bộ nhớ tạm thời, bạn có thể tiếp tục cập nhật hoặc quay lại sau!",
    });
    (await alertConfirm).present();
  }

  async onComplete() {
    this.isEmpty = true;
    this.isLoadingSubmit = true;

    if (localStorage.getItem("isNew") === "1") {
    } else if (localStorage.getItem("isNew") === "0") {
      if (
        (this.xnSangLocTaiCD === "Có" &&
          this.tenSinhPham === "HIV/Syphilis Duo" &&
          !this.kqXNGiangMai) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.tenSinhPham === "HIV/Syphilis Duo" &&
          !this.ngayXNGiangMai)
      ) {
        this.validateSexualIllness = true;
        this.isLoadingSubmit = false;
        this.isLoadingHivTest = false;
        const alertConfirm = this.atrCtrl.create({
          cssClass: "my-custom-class",
          message: "Vui lòng nhập đầy đủ thông tin hợp lệ!",
        });
        (await alertConfirm).dismiss();

        (await alertConfirm).present();
      } else {
        this.customerService
          .updateInformationServiceKpelog({ userId: this.customerId })
          .then(async (res: any) => {
            this.isLoadingSubmit = false;
            this.customerService
              .checkMissingData(this.customerId)
              .then(async (res: any) => {
                if (res) {
                  res.map((value) => {
                    if (value.reason) {
                      this.missingFieldArr = value.reason;
                    } else {
                      this.missingFieldArr = [];
                    }
                  });
                }
                setTimeout(async () => {
                  if (
                    ((this.cgXetNghiemKhangDinh === "Có" &&
                      this.ketQuaXNSangLoc === "Có phản ứng" &&
                      this.isSubmitHIVTest) ||
                      (this.cgPrep === "Có" && this.isSubmitPrep)) &&
                    this.tinhDuAn === "Thành phố Hồ Chí Minh"
                  ) {
                    this.isOpenModalMenu = !this.isOpenModalMenu;
                  } else {
                    const alertConfirm = this.atrCtrl.create({
                      message: "Thông tin vừa được lưu vào hệ thống!",
                      cssClass: "my-custom-class-success",
                    });
                    setTimeout(async () => {
                      (await alertConfirm).dismiss();
                      if (this.customerId) {
                        this.goToPage("tabs/customer");
                      }
                    }, 1000);
                    (await alertConfirm).present();
                  }
                }, 500);
              })
              .catch();
          })
          .catch();
      }
    }

    this.openTabHasError();
  }

  async onCompleteHIVTest() {
    this.isLoadingHivTest = true;
    if (
      this.hinhThucXNSangLoc !== "CBO làm xét nghiệm cho khách hàng" &&
      this.xnSangLocTaiCD === "Có"
    ) {
      this.soSPNhanVeChoBT = 1;
      this.soSPNhanVeChoBanNguoiThan = 0;
      this.soSPNhanVeChoBTBC = 0;
    } else {
      this.soSPNhanVeChoBT = null;
      this.soSPNhanVeChoBanNguoiThan = null;
      this.soSPNhanVeChoBTBC = null;
      this.hinhThucDatSP = "";
      this.hinhThucNhanSP = "";
      this.ngayNhanSP = null;
    }
    const payload = {
      userId: this.customerId,
      maXNSangLoc: this.maXNSangLoc,
      hinhThucXNSangLoc: this.hinhThucXNSangLoc,
      ketQuaXNKhangNguyenKhangThe: this.ketQuaXNKhangNguyenKhangThe,
      ketQuaXNSangLoc: this.ketQuaXNSangLoc,
      ngayChuyenGui: new Date(this.ngayChuyenGui).getTime(),
      ngayNhanSP: new Date(this.ngayNhanSP).getTime(),
      tenSinhPham: {
        value: this.tenSinhPham,
        khac: this.tenSinhPhamKhac,
      },
      xnSangLocTaiCD: this.xnSangLocTaiCD,
      coSoLamXNKhangDinh: {
        value: this.coSoLamXNKhangDinh,
        khac: this.coSoLamXNKhangDinhKhac,
      },
      ngayXNSangLocTaiCD: new Date(this.ngayXNSangLocTaiCD).getTime(),
      soTTXNSangLoc: this.soTTXNSangLoc,
      hinhThucNhanSP: this.hinhThucNhanSP,
      hinhThucDatSP: this.hinhThucDatSP,
      soSPNhanVeChoBT: this.soSPNhanVeChoBT,
      soSPNhanVeChoBTBC: this.soSPNhanVeChoBTBC,
      soSPNhanVeChoBanNguoiThan: this.soSPNhanVeChoBanNguoiThan,
      cgXetNghiemKhangDinh: this.cgXetNghiemKhangDinh,
      ngayCBOBietKqua: new Date(this.ngayCBOCoKQ).getTime(),
      ngayTraKQXNKD: new Date(this.ngayTraKQXNKD).getTime(),
      ketQuaXNKD: this.ketQuaXNKD,
      loaiXetNghiem: this.loaiXetNghiem,
      donViLayMau: {
        value: this.donViLayMau,
        khac: this.donViLayMauKhac,
      },
      maXNKD: this.maXNKD,
      maXetNghiemVienTheoHCDC: this.maXetNghiemVienTheoHCDC,
      maXetNghiemVien: this.maXetNghiemVien,
      tenXetNghiemVien: this.tenXetNghiemVien,
      ngayTaoXnsl: this.sendDay,
      ngayTaoCgXnKhangDinh: null,
      ngayTaoKqXnKhangDinh: null,
      maHCDC: this.maHCDC,
      soTTTheoHCDC: this.soTTTheoHCDC,
      phieuDongYXNKD: this.valueUploadFile,
    };
    if (!this.ngayChuyenGui) {
      delete payload.ngayChuyenGui;
    }
    if (!this.ngayNhanSP) {
      delete payload.ngayNhanSP;
    }
    if (payload.cgXetNghiemKhangDinh) {
      payload.ngayTaoCgXnKhangDinh = this.sendDay;
    } else {
      delete payload.ngayTaoCgXnKhangDinh;
    }
    if (
      payload.ngayCBOBietKqua ||
      payload.ngayTraKQXNKD ||
      payload.ketQuaXNKD ||
      payload.loaiXetNghiem ||
      payload.donViLayMau ||
      payload.maXNKD
    ) {
      payload.ngayTaoKqXnKhangDinh = this.sendDay;
    } else {
      delete payload.ngayTaoKqXnKhangDinh;
    }
    if (this.tenSinhPham === "HIV/Syphilis Duo") {
      payload.tenSinhPham.value = "HIV/Syphilys Duo";
    }
    const clonePayload = JSON.parse(JSON.stringify(payload));
    delete clonePayload.userId;
    delete clonePayload.maXetNghiemVien;
    delete clonePayload.ngayTaoKqXnKhangDinh;
    delete clonePayload.ngayTaoXnsl;
    delete clonePayload.maHCDC;
    delete clonePayload.maXetNghiemVienTheoHCDC;
    delete clonePayload.ngayCBOBietKqua;
    delete clonePayload.soTTTheoHCDC;
    delete clonePayload.hinhThucDatSP;
    delete clonePayload.ngayXNSangLocTaiCD;
    delete clonePayload.ngayChuyenGui;
    delete clonePayload.ngayTraKQXNKD;
    if (this.tinhDuAn !== "Thành phố Hồ Chí Minh") {
      payload.maHCDC = "";
      payload.maXetNghiemVienTheoHCDC = "";
      payload.soTTTheoHCDC = "";
    }
    const isEmpty = (element, index, array) => {
      return (
        element === null ||
        element === "" ||
        element === 0 ||
        (element.value === "" && element.khac === "") ||
        element.value === "" ||
        element.value?.name === ""
      );
    };
    if (payload.xnSangLocTaiCD === "Không") {
      payload.maXNSangLoc = "";
      payload.hinhThucXNSangLoc = "";
      payload.ketQuaXNKhangNguyenKhangThe = "";
      payload.ketQuaXNSangLoc = "";
      payload.ngayChuyenGui = null;
      payload.ngayNhanSP = null;
      payload.tenSinhPham.value = "";
      payload.tenSinhPham.khac = "";
      payload.coSoLamXNKhangDinh.value = "";
      payload.coSoLamXNKhangDinh.khac = "";
      payload.ngayXNSangLocTaiCD = null;
      payload.soTTXNSangLoc = "";
      payload.hinhThucNhanSP = "";
      payload.hinhThucDatSP = "";
      payload.soSPNhanVeChoBT = null;
      payload.soSPNhanVeChoBTBC = null;
      payload.soSPNhanVeChoBanNguoiThan = null;
      payload.cgXetNghiemKhangDinh = "";
      payload.ngayCBOBietKqua = null;
      payload.ngayTraKQXNKD = null;
      payload.ketQuaXNKD = "";
      payload.loaiXetNghiem = "";
      payload.donViLayMau.value = "";
      payload.maXNKD = "";
      payload.maXetNghiemVienTheoHCDC = "";
      payload.maXetNghiemVien = "";
      payload.tenXetNghiemVien = "";
      payload.soTTTheoHCDC = "";
      payload.maHCDC = "";
      this.isSubmitHIVTest = false;
    }
    if (Object.values(clonePayload).every(isEmpty)) {
      this.isLoadingHivTest = false;
    } else {
      this.invalidNumberOfMedicine =
        this.soSPNhanVeChoBT +
          this.soSPNhanVeChoBTBC +
          this.soSPNhanVeChoBanNguoiThan >
        3;
      if (
        (this.xnSangLocTaiCD === "Có" && !this.ngayXNSangLocTaiCD) ||
        (this.xnSangLocTaiCD === "Có" && this.ngayXNAfterNgayCg) ||
        (this.xnSangLocTaiCD === "Có" && !this.maXetNghiemVien) ||
        (this.xnSangLocTaiCD === "Có" && !this.ngayXNSangLocTaiCD) ||
        (this.xnSangLocTaiCD === "Có" && this.ngayBDTCAfterNgayXNSL) ||
        (this.xnSangLocTaiCD === "Có" && !this.tenSinhPham) ||
        (this.xnSangLocTaiCD === "Có" && !this.soTTXNSangLoc) ||
        (this.xnSangLocTaiCD === "Có" && !this.maXNSangLoc) ||
        (this.xnSangLocTaiCD === "Có" && this.ngayXNAfterToday) ||
        (this.xnSangLocTaiCD === "Có" &&
          !this.maXetNghiemVienTheoHCDC &&
          this.tinhDuAn === "Thành phố Hồ Chí Minh") ||
        (this.xnSangLocTaiCD === "Có" && !this.hinhThucXNSangLoc) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.hinhThucXNSangLoc === "CBO làm xét nghiệm cho khách hàng" &&
          !this.tenSinhPham) ||
        (this.xnSangLocTaiCD === "Có" && !this.ketQuaXNSangLoc) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.ketQuaXNSangLoc === "Có phản ứng" &&
          !this.ketQuaXNKhangNguyenKhangThe) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.hinhThucXNSangLoc !== "CBO làm xét nghiệm cho khách hàng" &&
          !this.hinhThucNhanSP) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.hinhThucXNSangLoc !== "CBO làm xét nghiệm cho khách hàng" &&
          !this.hinhThucDatSP) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.hinhThucXNSangLoc !== "CBO làm xét nghiệm cho khách hàng" &&
          this.hinhThucNhanSP !== "Nhận thông qua BTBC" &&
          !this.ngayNhanSP) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.hinhThucXNSangLoc !== "CBO làm xét nghiệm cho khách hàng" &&
          this.hinhThucNhanSP !== "Nhận thông qua BTBC" &&
          !this.soSPNhanVeChoBTBC &&
          this.soSPNhanVeChoBTBC !== 0) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.hinhThucXNSangLoc !== "CBO làm xét nghiệm cho khách hàng" &&
          this.hinhThucNhanSP !== "Nhận thông qua BTBC" &&
          this.soSPNhanVeChoBTBC >= 3 &&
          this.soSPNhanVeChoBTBC !== 0) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.hinhThucXNSangLoc !== "CBO làm xét nghiệm cho khách hàng" &&
          this.hinhThucNhanSP !== "Nhận thông qua BTBC" &&
          !this.soSPNhanVeChoBanNguoiThan &&
          this.soSPNhanVeChoBanNguoiThan !== 0) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.hinhThucXNSangLoc !== "CBO làm xét nghiệm cho khách hàng" &&
          this.hinhThucNhanSP !== "Nhận thông qua BTBC" &&
          !this.soSPNhanVeChoBT &&
          this.soSPNhanVeChoBT !== 0) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.hinhThucXNSangLoc !== "CBO làm xét nghiệm cho khách hàng" &&
          this.hinhThucNhanSP !== "Nhận thông qua BTBC" &&
          this.invalidNumberOfMedicine &&
          this.soSPNhanVeChoBT !== 0) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.ketQuaXNSangLoc === "Có phản ứng" &&
          !this.soTTTheoHCDC &&
          this.tinhDuAn === "Thành phố Hồ Chí Minh") ||
        (this.xnSangLocTaiCD === "Có" &&
          this.soTTTheoHCDC?.length !== 5 &&
          this.tinhDuAn === "Thành phố Hồ Chí Minh") ||
        (this.xnSangLocTaiCD === "Có" &&
          !this.maHCDC &&
          this.tinhDuAn === "Thành phố Hồ Chí Minh") ||
        (this.xnSangLocTaiCD === "Có" &&
          this.cgXetNghiemKhangDinh === "Có" &&
          this.ketQuaXNSangLoc === "Có phản ứng" &&
          !this.ngayChuyenGui) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.cgXetNghiemKhangDinh === "Có" &&
          this.ketQuaXNSangLoc === "Có phản ứng" &&
          this.ngayCgAfterToday) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.cgXetNghiemKhangDinh === "Có" &&
          this.ketQuaXNSangLoc === "Có phản ứng" &&
          this.ngayTraXNKDAfterToday) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.cgXetNghiemKhangDinh === "Có" &&
          this.ketQuaXNSangLoc === "Có phản ứng" &&
          this.ngayTraXNKDAfterNgayXNSL) ||
        (this.xnSangLocTaiCD === "Có" && this.soTTXNSangLoc.length < 4) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.cgXetNghiemKhangDinh === "Có" &&
          this.ketQuaXNSangLoc === "Có phản ứng" &&
          !this.coSoLamXNKhangDinh) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.cgXetNghiemKhangDinh === "Có" &&
          this.ketQuaXNSangLoc === "Có phản ứng" &&
          this.tinhDuAn === "Thành phố Hồ Chí Minh" &&
          !this.valueUploadFile)
      ) {
        this.validateXNSL = true;
        this.isLoadingHivTest = false;
        const alertConfirm = this.atrCtrl.create({
          cssClass: "my-custom-class",
          message: "Vui lòng nhập đầy đủ thông tin hợp lệ!",
        });
        (await alertConfirm).dismiss();

        (await alertConfirm).present();
      } else {
        this.customerService
          .validateSttHCDC(
            this.tenDuAn,
            this.maCBO,
            this.soTTTheoHCDC,
            this.customerId
          )
          .then(async (res: any) => {
            if (
              !res &&
              this.tinhDuAn === "Thành phố Hồ Chí Minh" &&
              this.xnSangLocTaiCD === "Có"
            ) {
              this.isLoadingHivTest = false;
              const alertConfirm = this.atrCtrl.create({
                cssClass: "my-custom-class",
                message: "Số thứ tự theo Mã HCDC đã được sử dụng!",
              });
              (await alertConfirm).dismiss();

              (await alertConfirm).present();
            } else {
              this.customerService
                .validateSttXNSL(
                  this.tenDuAn,
                  this.maCBO,
                  this.soTTXNSangLoc,
                  this.customerId
                )
                .then(async (res: any) => {
                  if (res) {
                    this.customerService
                      .saveHIVTest(payload)
                      .then(async (res) => {
                        this.isLoadingHivTest = false;
                        this.isSubmitHIVTest = true;
                        const alertConfirm = this.atrCtrl.create({
                          message: "Thông tin vừa được lưu vào hệ thống!",
                          cssClass: "my-custom-class-success",
                        });
                        (await alertConfirm).dismiss();
                        (await alertConfirm).present();
                        this.isCreateHivTest = false;
                        this.customerService
                          .getDetailUsersSendKPElog(this.customerId)
                          .then((res: any) => {
                            this.countXNSL =
                              res.ttDichVu.xetNghiemSangLocHIV.length;
                            this._idHIVTest =
                              res?.ttDichVu?.xetNghiemSangLocHIV[
                                res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
                              ]?._id;
                            this.updateRelatedHistory = false;
                          });
                      })
                      .catch(() => (this.isLoadingHivTest = false));
                  } else {
                    this.isLoadingHivTest = false;
                    const alertConfirm = this.atrCtrl.create({
                      cssClass: "my-custom-class",
                      message: "Số thứ tự xét nghiệm sàng lọc đã được sử dụng!",
                    });
                    (await alertConfirm).dismiss();

                    (await alertConfirm).present();
                  }
                });
            }
          });
      }
    }
  }
  async updateHistoryHIVTest() {
    this.isLoadingHivTest = true;
    if (this.maHCDC.includes("KC-KC")) {
      this.maHCDC = this.maHCDC.slice(3);
    }
    if (
      this.hinhThucXNSangLoc !== "CBO làm xét nghiệm cho khách hàng" &&
      this.xnSangLocTaiCD === "Có"
    ) {
      this.soSPNhanVeChoBT = 1;
      this.soSPNhanVeChoBanNguoiThan = 0;
      this.soSPNhanVeChoBTBC = 0;
    } else {
      this.soSPNhanVeChoBT = null;
      this.soSPNhanVeChoBanNguoiThan = null;
      this.soSPNhanVeChoBTBC = null;
      this.hinhThucDatSP = "";
      this.hinhThucNhanSP = "";
      this.ngayNhanSP = null;
    }
    const payload = {
      userId: this.customerId,
      maXNSangLoc: this.maXNSangLoc,
      hinhThucXNSangLoc: this.hinhThucXNSangLoc,
      ketQuaXNKhangNguyenKhangThe: this.ketQuaXNKhangNguyenKhangThe,
      ketQuaXNSangLoc: this.ketQuaXNSangLoc,
      ngayChuyenGui: new Date(this.ngayChuyenGui).getTime(),
      ngayNhanSP: new Date(this.ngayNhanSP).getTime(),
      tenSinhPham: {
        value: this.tenSinhPham,
        khac: this.tenSinhPhamKhac,
      },
      xnSangLocTaiCD: this.xnSangLocTaiCD,
      coSoLamXNKhangDinh: {
        value: this.coSoLamXNKhangDinh,
        khac: this.coSoLamXNKhangDinhKhac,
      },
      ngayXNSangLocTaiCD: new Date(this.ngayXNSangLocTaiCD).getTime(),
      soTTXNSangLoc: this.soTTXNSangLoc,
      hinhThucNhanSP: this.hinhThucNhanSP,
      hinhThucDatSP: this.hinhThucDatSP,
      soSPNhanVeChoBT: this.soSPNhanVeChoBT,
      soSPNhanVeChoBTBC: this.soSPNhanVeChoBTBC,
      soSPNhanVeChoBanNguoiThan: this.soSPNhanVeChoBanNguoiThan,
      cgXetNghiemKhangDinh: this.cgXetNghiemKhangDinh,
      ngayCBOBietKqua: new Date(this.ngayCBOCoKQ).getTime(),
      ngayTraKQXNKD: new Date(this.ngayTraKQXNKD).getTime(),
      ketQuaXNKD: this.ketQuaXNKD,
      loaiXetNghiem: this.loaiXetNghiem,
      donViLayMau: {
        value: this.donViLayMau,
        khac: this.donViLayMauKhac,
      },
      maXNKD: this.maXNKD,
      maXetNghiemVienTheoHCDC: this.maXetNghiemVienTheoHCDC,
      maXetNghiemVien: this.maXetNghiemVien,
      tenXetNghiemVien: this.tenXetNghiemVien,
      ngayTaoXnsl: this.sendDay,
      ngayTaoCgXnKhangDinh: null,
      ngayTaoKqXnKhangDinh: null,
      maHCDC: this.maHCDC,
      soTTTheoHCDC: this.soTTTheoHCDC,
      phieuDongYXNKD: this.valueUploadFile,
    };
    if (payload.cgXetNghiemKhangDinh) {
      payload.ngayTaoCgXnKhangDinh = this.sendDay;
    } else {
      delete payload.ngayTaoCgXnKhangDinh;
    }
    if (
      payload.ngayCBOBietKqua ||
      payload.ngayTraKQXNKD ||
      payload.ketQuaXNKD ||
      payload.loaiXetNghiem ||
      payload.donViLayMau ||
      payload.maXNKD
    ) {
      payload.ngayTaoKqXnKhangDinh = this.sendDay;
    } else {
      delete payload.ngayTaoKqXnKhangDinh;
    }
    const clonePayload = JSON.parse(JSON.stringify(payload));
    delete clonePayload.userId;
    delete clonePayload.maXetNghiemVien;
    delete clonePayload.ngayTaoKqXnKhangDinh;
    delete clonePayload.ngayTaoXnsl;
    delete clonePayload.maHCDC;
    delete clonePayload.maXetNghiemVienTheoHCDC;
    delete clonePayload.ngayCBOBietKqua;
    delete clonePayload.soTTTheoHCDC;
    delete clonePayload.ngayXNSangLocTaiCD;
    delete clonePayload.ngayChuyenGui;
    delete clonePayload.ngayTraKQXNKD;
    if (this.tinhDuAn !== "Thành phố Hồ Chí Minh") {
      payload.maHCDC = "";
      payload.maXetNghiemVienTheoHCDC = "";
      payload.soTTTheoHCDC = "";
    }
    if (payload.xnSangLocTaiCD === "Không") {
      payload.maXNSangLoc = "";
      payload.hinhThucXNSangLoc = "";
      payload.ketQuaXNKhangNguyenKhangThe = "";
      payload.ketQuaXNSangLoc = "";
      payload.ngayChuyenGui = null;
      payload.ngayNhanSP = null;
      payload.tenSinhPham.value = "";
      payload.tenSinhPham.khac = "";
      payload.coSoLamXNKhangDinh.value = "";
      payload.coSoLamXNKhangDinh.khac = "";
      payload.phieuDongYXNKD = "";
      payload.ngayXNSangLocTaiCD = null;
      payload.soTTXNSangLoc = "";
      payload.hinhThucNhanSP = "";
      payload.hinhThucDatSP = "";
      payload.soSPNhanVeChoBT = null;
      payload.soSPNhanVeChoBTBC = null;
      payload.soSPNhanVeChoBanNguoiThan = null;
      payload.cgXetNghiemKhangDinh = "";
      payload.ngayCBOBietKqua = null;
      payload.ngayTraKQXNKD = null;
      payload.ketQuaXNKD = "";
      payload.loaiXetNghiem = "";
      payload.donViLayMau.value = "";
      payload.maXNKD = "";
      payload.maXetNghiemVienTheoHCDC = "";
      payload.maXetNghiemVien = "";
      payload.tenXetNghiemVien = "";
      payload.soTTTheoHCDC = "";
      payload.maHCDC = "";
      payload.donViLayMau = { value: "", khac: "" };
      this.isSubmitHIVTest = false;
    }
    if (this.tenSinhPham === "HIV/Syphilis Duo") {
      payload.tenSinhPham.value = "HIV/Syphilys Duo";
    }
    const isEmpty = (element) => {
      return (
        element === null ||
        element === "" ||
        element === 0 ||
        (element.value === "" && element.khac === "") ||
        element.value === "" ||
        element.value?.name === ""
      );
    };
    if (Object.values(clonePayload).every(isEmpty)) {
      this.isLoadingHivTest = false;
    } else {
      this.invalidNumberOfMedicine =
        this.soSPNhanVeChoBT +
          this.soSPNhanVeChoBTBC +
          this.soSPNhanVeChoBanNguoiThan >
        3;
      if (
        (this.xnSangLocTaiCD === "Có" && !this.ngayXNSangLocTaiCD) ||
        (this.xnSangLocTaiCD === "Có" && this.ngayXNAfterNgayCg) ||
        (this.xnSangLocTaiCD === "Có" && !this.maXetNghiemVien) ||
        (this.xnSangLocTaiCD === "Có" && !this.ngayXNSangLocTaiCD) ||
        (this.xnSangLocTaiCD === "Có" && this.ngayBDTCAfterNgayXNSL) ||
        (this.xnSangLocTaiCD === "Có" && !this.tenSinhPham) ||
        (this.xnSangLocTaiCD === "Có" && !this.soTTXNSangLoc) ||
        (this.xnSangLocTaiCD === "Có" && !this.maXNSangLoc) ||
        (this.xnSangLocTaiCD === "Có" && this.ngayXNAfterToday) ||
        (this.xnSangLocTaiCD === "Có" &&
          !this.maXetNghiemVienTheoHCDC &&
          this.tinhDuAn === "Thành phố Hồ Chí Minh") ||
        (this.xnSangLocTaiCD === "Có" && !this.hinhThucXNSangLoc) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.hinhThucXNSangLoc === "CBO làm xét nghiệm cho khách hàng" &&
          !this.tenSinhPham) ||
        (this.xnSangLocTaiCD === "Có" && !this.ketQuaXNSangLoc) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.ketQuaXNSangLoc === "Có phản ứng" &&
          !this.ketQuaXNKhangNguyenKhangThe) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.hinhThucXNSangLoc !== "CBO làm xét nghiệm cho khách hàng" &&
          !this.hinhThucNhanSP) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.hinhThucXNSangLoc !== "CBO làm xét nghiệm cho khách hàng" &&
          !this.hinhThucDatSP) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.hinhThucXNSangLoc !== "CBO làm xét nghiệm cho khách hàng" &&
          this.hinhThucNhanSP !== "Nhận thông qua BTBC" &&
          !this.ngayNhanSP) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.hinhThucXNSangLoc !== "CBO làm xét nghiệm cho khách hàng" &&
          this.hinhThucNhanSP !== "Nhận thông qua BTBC" &&
          !this.soSPNhanVeChoBTBC &&
          this.soSPNhanVeChoBTBC !== 0) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.hinhThucXNSangLoc !== "CBO làm xét nghiệm cho khách hàng" &&
          this.hinhThucNhanSP !== "Nhận thông qua BTBC" &&
          this.soSPNhanVeChoBTBC >= 3 &&
          this.soSPNhanVeChoBTBC !== 0) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.hinhThucXNSangLoc !== "CBO làm xét nghiệm cho khách hàng" &&
          this.hinhThucNhanSP !== "Nhận thông qua BTBC" &&
          !this.soSPNhanVeChoBanNguoiThan &&
          this.soSPNhanVeChoBanNguoiThan !== 0) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.hinhThucXNSangLoc !== "CBO làm xét nghiệm cho khách hàng" &&
          this.hinhThucNhanSP !== "Nhận thông qua BTBC" &&
          !this.soSPNhanVeChoBT &&
          this.soSPNhanVeChoBT !== 0) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.hinhThucXNSangLoc !== "CBO làm xét nghiệm cho khách hàng" &&
          this.hinhThucNhanSP !== "Nhận thông qua BTBC" &&
          this.invalidNumberOfMedicine &&
          this.soSPNhanVeChoBT !== 0) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.tinhDuAn === "Thành phố Hồ Chí Minh" &&
          !this.soTTTheoHCDC) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.soTTTheoHCDC?.length !== 5 &&
          this.tinhDuAn === "Thành phố Hồ Chí Minh") ||
        (this.xnSangLocTaiCD === "Có" &&
          !this.maHCDC &&
          this.tinhDuAn === "Thành phố Hồ Chí Minh") ||
        (this.xnSangLocTaiCD === "Có" &&
          this.cgXetNghiemKhangDinh === "Có" &&
          this.ketQuaXNSangLoc === "Có phản ứng" &&
          !this.ngayChuyenGui) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.cgXetNghiemKhangDinh === "Có" &&
          this.ketQuaXNSangLoc === "Có phản ứng" &&
          this.ngayCgAfterToday) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.cgXetNghiemKhangDinh === "Có" &&
          this.ketQuaXNSangLoc === "Có phản ứng" &&
          this.ngayTraXNKDAfterToday) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.cgXetNghiemKhangDinh === "Có" &&
          this.ketQuaXNSangLoc === "Có phản ứng" &&
          this.ngayTraXNKDAfterNgayXNSL) ||
        (this.xnSangLocTaiCD === "Có" && this.soTTXNSangLoc.length < 4) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.cgXetNghiemKhangDinh === "Có" &&
          this.ketQuaXNSangLoc === "Có phản ứng" &&
          !this.coSoLamXNKhangDinh) ||
        (this.xnSangLocTaiCD === "Có" &&
          this.cgXetNghiemKhangDinh === "Có" &&
          this.ketQuaXNSangLoc === "Có phản ứng" &&
          this.tinhDuAn === "Thành phố Hồ Chí Minh" &&
          !this.valueUploadFile)
      ) {
        this.validateXNSL = true;
        this.isLoadingHivTest = false;
        const alertConfirm = this.atrCtrl.create({
          cssClass: "my-custom-class",
          message: "Vui lòng nhập đầy đủ thông tin hợp lệ!",
        });
        (await alertConfirm).dismiss();

        (await alertConfirm).present();
      } else {
        const form_data = new FormData();

        for (const key in payload) {
          form_data.append(key, payload[key]);
        }
        this.customerService
          .validateSttHCDC(
            this.tenDuAn,
            this.maCBO,
            this.soTTTheoHCDC,
            this.customerId
          )
          .then(async (res: any) => {
            if (
              !res &&
              this.tinhDuAn === "Thành phố Hồ Chí Minh" &&
              this.xnSangLocTaiCD === "Có"
            ) {
              this.isLoadingHivTest = false;
              const alertConfirm = this.atrCtrl.create({
                cssClass: "my-custom-class",
                message: "Số thứ tự theo Mã HCDC đã được sử dụng!",
              });
              (await alertConfirm).dismiss();

              (await alertConfirm).present();
            } else {
              this.customerService
                .validateSttXNSL(
                  this.tenDuAn,
                  this.maCBO,
                  this.soTTXNSangLoc,
                  this.customerId
                )
                .then(async (res: any) => {
                  if (res) {
                    this.customerService
                      .updateHistoryHIVTest(this._idHIVTest, payload)
                      .then(async (res) => {
                        this.isLoadingHivTest = false;
                        this.isSubmitHIVTest = true;
                        const alertConfirm = this.atrCtrl.create({
                          message: "Thông tin vừa được lưu vào hệ thống!",
                          cssClass: "my-custom-class-success",
                        });
                        (await alertConfirm).dismiss();
                        (await alertConfirm).present();
                      })
                      .catch(() => (this.isLoadingHivTest = false));
                  } else {
                    this.isLoadingHivTest = false;
                    const alertConfirm = this.atrCtrl.create({
                      cssClass: "my-custom-class",
                      message: "Số thứ tự xét nghiệm sàng lọc đã được sử dụng!",
                    });
                    (await alertConfirm).dismiss();

                    (await alertConfirm).present();
                  }
                });
            }
          });
      }
    }
  }

  async onCompleteHistoryPrep() {
    this.isLoadingPrep = true;
    const payload = {
      userId: this.customerId,
      duTCPrep: this.duTCPrep,
      dongYPrepSauTuVan: this.dongYPrepSauTuVan,
      cgPrep: this.cgPrep,
      coSoCgDieuTriPrep: {
        value: this.coSoCgDieuTriPrep,
        khac: this.coSoCgDieuTriPrepKhac,
      },
      lyDoKhongPrep: this.lyDoKhongPrep,
      ngayBDPrep: new Date(this.ngayBDPrep).getTime(),
      ngayCgDieuTriPrep: new Date(this.ngayCgDieuTriPrep).getTime(),
      donViPrep: {
        value: this.donViPrep,
        khac: this.donViPrepKhac,
      },
      ttPrep1: {
        value: this.ttPrep1,
        soLieuDaQuen: this.soLieuDaQuenPrep1,
        soNgayNgung: this.soNgayNgungPrep1,
        lyDoQuen: this.lyDoQuenPrep1,
        khac: this.khacPrep1,
      },
      ttPrep2: {
        value: this.ttPrep2,
        soLieuDaQuen: this.soLieuDaQuenPrep2,
        soNgayNgung: this.soNgayNgungPrep2,
        lyDoQuen: this.lyDoQuenPrep2,
        khac: this.khacPrep2,
      },
      ttPrep3: {
        value: this.ttPrep3,
        soLieuDaQuen: this.soLieuDaQuenPrep3,
        soNgayNgung: this.soNgayNgungPrep3,
        lyDoQuen: this.lyDoQuenPrep3,
        khac: this.khacPrep3,
      },
      ttPrep: this.ttPrep,
      dieuTriPrep: this.dieuTriPrep,
      maSoDieuTriPrep: this.maSoDieuTriPrep,
      ngayTaoPrep: this.sendDay,
      ngayTaoKqDieuTriPrep: null,
      ngayTaoTuanThuPrep: null,
      ngayTaoTinhTrangPrep: null,
      phieuDongYPrep: this.valueUploadFilePrep,
    };
    const clonePayload = JSON.parse(JSON.stringify(payload));
    delete clonePayload.userId;
    delete clonePayload.ngayTaoPrep;
    delete clonePayload.ngayTaoKqDieuTriPrep;
    delete clonePayload.ngayTaoTuanThuPrep;
    delete clonePayload.ngayTaoTinhTrangPrep;

    const isEmpty = (element) => {
      return (
        element === "" ||
        element === 0 ||
        (element?.value === "" && element?.khac === "") ||
        element?.value === "" ||
        element?.value?.name === ""
      );
    };

    if (payload.dieuTriPrep) {
      payload.ngayTaoKqDieuTriPrep = this.sendDay;
    } else {
      delete payload.ngayTaoKqDieuTriPrep;
    }
    if (
      payload.ttPrep1.value ||
      payload.ttPrep2.value ||
      payload.ttPrep3.value
    ) {
      payload.ngayTaoTuanThuPrep = this.sendDay;
    } else {
      delete payload.ngayTaoTuanThuPrep;
    }
    if (payload.ttPrep) {
      payload.ngayTaoTinhTrangPrep = this.sendDay;
    } else {
      delete payload.ngayTaoTinhTrangPrep;
    }
    if (payload.duTCPrep === "Không") {
      this.isSubmitPrep = false;
    }
    delete clonePayload.ngayCgDieuTriPrep;
    delete clonePayload.ngayBDPrep;
    if (Object.values(clonePayload).every(isEmpty)) {
      this.isLoadingPrep = false;
    } else {
      if (
        !this.dongYPrepSauTuVan ||
        (this.duTCPrep === "Có" && !this.cgPrep) ||
        (this.cgPrep === "Không" && !this.lyDoKhongPrep) ||
        (this.duTCPrep === "Có" &&
          this.cgPrep === "Có" &&
          !this.ngayCgDieuTriPrep) ||
        (this.duTCPrep === "Có" &&
          this.cgPrep === "Có" &&
          !this.coSoCgDieuTriPrep) ||
        (this.duTCPrep === "Có" &&
          this.cgPrep === "Có" &&
          this.tinhDuAn === "Thành phố Hồ Chí Minh" &&
          !this.valueUploadFilePrep) ||
        (this.duTCPrep === "Có" &&
          this.cgPrep === "Có" &&
          this.ngayCgPrepAfterToday) ||
        (this.duTCPrep === "Có" &&
          this.cgPrep === "Có" &&
          this.ngayCgPrepAfterNgayXNSL) ||
        (this.duTCPrep === "Có" &&
          this.dieuTriPrep === "Đã điều trị" &&
          !this.ngayBDPrep) ||
        (this.duTCPrep === "Có" &&
          this.dieuTriPrep === "Đã điều trị" &&
          !this.donViPrep) ||
        (this.duTCPrep === "Có" &&
          this.dieuTriPrep === "Đã điều trị" &&
          !this.maSoDieuTriPrep) ||
        (this.duTCPrep === "Có" &&
          this.dieuTriPrep === "Đã điều trị" &&
          this.cgPrep === "Có" &&
          this.ngayDieuTriPrepAfterNgayXNSL) ||
        (this.duTCPrep === "Có" &&
          this.dieuTriPrep === "Đã điều trị" &&
          this.cgPrep === "Có" &&
          this.ngayDieuTriPrepAfterToday)
      ) {
        this.validatePrep = true;
        this.isLoadingPrep = false;
        const alertConfirm = this.atrCtrl.create({
          cssClass: "my-custom-class",
          message: "Vui lòng nhập đầy đủ thông tin hợp lệ!",
        });
        (await alertConfirm).dismiss();

        (await alertConfirm).present();
      } else {
        this.customerService
          .saveHistoryPrep(payload)
          .then(async (res) => {
            this.isLoadingPrep = false;
            this.isSubmitPrep = true;
            const alertConfirm = this.atrCtrl.create({
              message: "Thông tin vừa được lưu vào hệ thống!",
              cssClass: "my-custom-class-success",
            });
            (await alertConfirm).dismiss();
            (await alertConfirm).present();
            this.isCreateNewPrep = false;
            this.customerService
              .getDetailUsersSendKPElog(this.customerId)
              .then((res: any) => {
                this.countPrep =
                  res.ttDichVu.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length;
                this._idPrep =
                  res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
                    res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep
                      .length - 1
                  ]?._id;
                this.updateRelateHistoryPrep = false;
              });
          })
          .catch(() => (this.isLoadingPrep = false));
      }
    }
  }
  async updateHistoryPrep() {
    this.isLoadingPrep = true;
    const payload = {
      userId: this.customerId,
      duTCPrep: this.duTCPrep,
      dongYPrepSauTuVan: this.dongYPrepSauTuVan,
      cgPrep: this.cgPrep,
      coSoCgDieuTriPrep: {
        value: this.coSoCgDieuTriPrep,
        khac: this.coSoCgDieuTriPrepKhac,
      },
      lyDoKhongPrep: this.lyDoKhongPrep,
      ngayBDPrep: new Date(this.ngayBDPrep).getTime(),
      ngayCgDieuTriPrep: new Date(this.ngayCgDieuTriPrep).getTime(),
      donViPrep: {
        value: this.donViPrep,
        khac: this.donViPrepKhac,
      },
      ttPrep1: {
        value: this.ttPrep1,
        soLieuDaQuen: this.soLieuDaQuenPrep1,
        soNgayNgung: this.soNgayNgungPrep1,
        lyDoQuen: this.lyDoQuenPrep1,
        khac: this.khacPrep1,
      },
      ttPrep2: {
        value: this.ttPrep2,
        soLieuDaQuen: this.soLieuDaQuenPrep2,
        soNgayNgung: this.soNgayNgungPrep2,
        lyDoQuen: this.lyDoQuenPrep2,
        khac: this.khacPrep2,
      },
      ttPrep3: {
        value: this.ttPrep3,
        soLieuDaQuen: this.soLieuDaQuenPrep3,
        soNgayNgung: this.soNgayNgungPrep3,
        lyDoQuen: this.lyDoQuenPrep3,
        khac: this.khacPrep3,
      },
      ttPrep: this.ttPrep,
      dieuTriPrep: this.dieuTriPrep,
      maSoDieuTriPrep: this.maSoDieuTriPrep,
      ngayTaoPrep: this.sendDay,
      ngayTaoKqDieuTriPrep: null,
      ngayTaoTuanThuPrep: null,
      ngayTaoTinhTrangPrep: null,
      phieuDongYPrep: this.valueUploadFilePrep,
    };
    if (payload.dieuTriPrep) {
      payload.ngayTaoKqDieuTriPrep = this.sendDay;
    } else {
      delete payload.ngayTaoKqDieuTriPrep;
    }
    if (
      payload.ttPrep1.value ||
      payload.ttPrep2.value ||
      payload.ttPrep3.value
    ) {
      payload.ngayTaoTuanThuPrep = this.sendDay;
    } else {
      delete payload.ngayTaoTuanThuPrep;
    }
    if (payload.ttPrep) {
      payload.ngayTaoTinhTrangPrep = this.sendDay;
    } else {
      delete payload.ngayTaoTinhTrangPrep;
    }
    if (payload.duTCPrep === "Không") {
      this.isSubmitPrep = false;
    }

    if (
      !this.dongYPrepSauTuVan ||
      (this.duTCPrep === "Có" && !this.cgPrep) ||
      (this.cgPrep === "Không" && !this.lyDoKhongPrep) ||
      (this.duTCPrep === "Có" &&
        this.cgPrep === "Có" &&
        !this.ngayCgDieuTriPrep) ||
      (this.duTCPrep === "Có" &&
        this.cgPrep === "Có" &&
        !this.coSoCgDieuTriPrep) ||
      (this.duTCPrep === "Có" &&
        this.cgPrep === "Có" &&
        this.ngayCgPrepAfterToday) ||
      (this.duTCPrep === "Có" &&
        this.cgPrep === "Có" &&
        this.ngayCgPrepAfterNgayXNSL) ||
      (this.duTCPrep === "Có" &&
        this.dieuTriPrep === "Đã điều trị" &&
        !this.ngayBDPrep) ||
      (this.duTCPrep === "Có" &&
        this.dieuTriPrep === "Đã điều trị" &&
        !this.donViPrep) ||
      (this.duTCPrep === "Có" &&
        this.dieuTriPrep === "Đã điều trị" &&
        !this.maSoDieuTriPrep) ||
      (this.duTCPrep === "Có" &&
        this.dieuTriPrep === "Đã điều trị" &&
        this.cgPrep === "Có" &&
        this.ngayDieuTriPrepAfterNgayXNSL) ||
      (this.duTCPrep === "Có" &&
        this.dieuTriPrep === "Đã điều trị" &&
        this.cgPrep === "Có" &&
        this.ngayDieuTriPrepAfterToday) ||
      (this.duTCPrep === "Có" &&
        this.cgPrep === "Có" &&
        this.tinhDuAn === "Thành phố Hồ Chí Minh" &&
        !this.valueUploadFilePrep)
    ) {
      this.validatePrep = true;
      this.isLoadingPrep = false;
      const alertConfirm = this.atrCtrl.create({
        cssClass: "my-custom-class",
        message: "Vui lòng nhập đầy đủ thông tin hợp lệ!",
      });
      (await alertConfirm).dismiss();

      (await alertConfirm).present();
    } else {
      this.customerService
        .updateHistoryPrep(this._idPrep, payload)
        .then(async (res) => {
          this.isLoadingPrep = false;
          this.isSubmitPrep = true;
          const alertConfirm = this.atrCtrl.create({
            message: "Thông tin vừa được lưu vào hệ thống!",
            cssClass: "my-custom-class-success",
          });
          (await alertConfirm).dismiss();
          (await alertConfirm).present();
        })
        .catch();
    }
  }
  formatGetDate(date: string) {
    if (date) {
      return moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
    } else {
      return null;
    }
  }
  formatGetDateNeedAuto(date: string) {
    if (date) {
      return moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
    } else {
      if (this.isBasicInfoCreateSameDay) {
        return this.ngayBDTiepCan;
      } else {
        return null;
      }
    }
  }
  async onCompleteHistoryPep() {
    this.isLoadingPep = true;
    const payload = {
      userId: this.customerId,
      dieuTriPEP: this.dieuTriPEP,
      cgPEP: this.cgPEP,
      dongYPepSauTuVan: this.dongYPepSauTuVan,
      coSoCgDieuTriPEP: {
        value: this.coSoCgDieuTriPEP,
        khac: this.coSoCgDieuTriPEPKhac,
      },
      lyDoKhongPEP: this.lyDoKhongPEP,
      ngayBatDatnPEP: new Date(this.ngayBatDatnPEP).getTime(),
      ngayCgDieuTriPep: new Date(this.ngayCgDieuTriPep).getTime(),
      coSonPEP: {
        value: this.coSonPEP,
        khac: this.coSonPEPKhac,
      },
      maDieuTrinPEP: this.maDieuTrinPEP,
      ngayTaoPep: this.sendDay,
      ngayTaoKqPep: null,
    };
    const clonePayload = JSON.parse(JSON.stringify(payload));
    delete clonePayload.userId;
    delete clonePayload.ngayTaoPep;
    delete clonePayload.ngayTaoKqPep;
    delete clonePayload.ngayCgDieuTriPep;
    delete clonePayload.ngayBatDatnPEP;
    const isEmpty = (element) => {
      return (
        element === "" ||
        element === 0 ||
        (element?.value === "" && element?.khac === "") ||
        element?.value === "" ||
        element?.value?.name === ""
      );
    };
    if (payload.dieuTriPEP) {
      payload.ngayTaoKqPep = this.sendDay;
    } else {
      delete payload.ngayTaoKqPep;
    }
    if (Object.values(clonePayload).every(isEmpty)) {
      this.isLoadingPep = false;
    } else {
      if (
        !this.dongYPepSauTuVan ||
        (this.cgPEP === "Có" && !this.ngayCgDieuTriPep) ||
        (this.cgPEP === "Có" &&
          this.dieuTriPEP === "Đã điều trị" &&
          !this.ngayBatDatnPEP) ||
        (this.dieuTriPEP === "Đã điều trị" &&
          this.cgPEP === "Có" &&
          !this.coSonPEP) ||
        (this.dieuTriPEP === "Đã điều trị" &&
          this.cgPEP === "Có" &&
          !this.maDieuTrinPEP) ||
        (this.cgPEP === "Có" && this.ngayCgPepAfterNgayXNSL) ||
        (this.cgPEP === "Có" && this.ngayCgPepAfterToday) ||
        (this.cgPEP === "Có" &&
          this.dieuTriPEP === "Đã điều trị" &&
          this.ngayDieuTriPepAfterNgayXNSL) ||
        (this.cgPEP === "Có" &&
          this.dieuTriPEP === "Đã điều trị" &&
          this.ngayDieuTriPepAfterToday)
      ) {
        this.validatePep = true;
        this.isLoadingPep = false;
        const alertConfirm = this.atrCtrl.create({
          cssClass: "my-custom-class",
          message: "Vui lòng nhập đầy đủ thông tin hợp lệ!",
        });
        (await alertConfirm).dismiss();

        (await alertConfirm).present();
      } else {
        this.customerService
          .saveHistoryPep(payload)
          .then(async (res) => {
            this.isLoadingPep = false;
            const alertConfirm = this.atrCtrl.create({
              message: "Thông tin vừa được lưu vào hệ thống!",
              cssClass: "my-custom-class-success",
            });
            (await alertConfirm).dismiss();
            (await alertConfirm).present();
            this.isCreateNewPep = false;
            this.customerService
              .getDetailUsersSendKPElog(this.customerId)
              .then((res: any) => {
                this.countPep =
                  res.ttDichVu.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP.length;
                this._idPep =
                  res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP[
                    res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP
                      .length - 1
                  ]?._id;
                this.updateRelateHistoryPep = false;
              });
          })
          .catch();
      }
    }
  }
  async updateHistoyPep() {
    this.isLoadingPep = true;
    const payload = {
      userId: this.customerId,
      dieuTriPEP: this.dieuTriPEP,
      cgPEP: this.cgPEP,
      dongYPepSauTuVan: this.dongYPepSauTuVan,
      coSoCgDieuTriPEP: {
        value: this.coSoCgDieuTriPEP,
        khac: this.coSoCgDieuTriPEPKhac,
      },
      lyDoKhongPEP: this.lyDoKhongPEP,
      ngayBatDatnPEP: new Date(this.ngayBatDatnPEP).getTime(),
      ngayCgDieuTriPep: new Date(this.ngayCgDieuTriPep).getTime(),
      coSonPEP: {
        value: this.coSonPEP,
        khac: this.coSonPEPKhac,
      },
      maDieuTrinPEP: this.maDieuTrinPEP,
      ngayTaoPep: this.sendDay,
      ngayTaoKqPep: null,
    };
    if (payload.dieuTriPEP) {
      payload.ngayTaoKqPep = this.sendDay;
    } else {
      delete payload.ngayTaoKqPep;
    }
    if (
      !this.dongYPepSauTuVan ||
      (this.cgPEP === "Có" && !this.ngayCgDieuTriPep) ||
      (this.cgPEP === "Có" &&
        this.dieuTriPEP === "Đã điều trị" &&
        !this.ngayBatDatnPEP) ||
      (this.dieuTriPEP === "Đã điều trị" &&
        this.cgPEP === "Có" &&
        !this.coSonPEP) ||
      (this.dieuTriPEP === "Đã điều trị" &&
        this.cgPEP === "Có" &&
        !this.maDieuTrinPEP) ||
      (this.cgPEP === "Có" && this.ngayCgPepAfterNgayXNSL) ||
      (this.cgPEP === "Có" && this.ngayCgPepAfterToday) ||
      (this.cgPEP === "Có" &&
        this.dieuTriPEP === "Đã điều trị" &&
        this.ngayDieuTriPepAfterNgayXNSL) ||
      (this.cgPEP === "Có" &&
        this.dieuTriPEP === "Đã điều trị" &&
        this.ngayDieuTriPepAfterToday)
    ) {
      this.validatePep = true;
      this.isLoadingPep = false;
      const alertConfirm = this.atrCtrl.create({
        cssClass: "my-custom-class",
        message: "Vui lòng nhập đầy đủ thông tin hợp lệ!",
      });
      (await alertConfirm).dismiss();

      (await alertConfirm).present();
    } else {
      this.customerService
        .updateHistoryPep(this._idPep, payload)
        .then(async (res) => {
          this.isLoadingPep = false;
          const alertConfirm = this.atrCtrl.create({
            message: "Thông tin vừa được lưu vào hệ thống!",
            cssClass: "my-custom-class-success",
          });
          (await alertConfirm).dismiss();
          (await alertConfirm).present();
        })
        .catch();
    }
  }
  async onCompleteTreatmentArv() {
    this.isLoadingTreatmentArv = true;
    const payload = {
      userId: this.customerId,
      chuyenGuiARV: this.chuyenGuiARV,
      coSoCgDieuTriARV: {
        value: this.coSoCgDieuTriARV,
        khac: this.coSoCgDieuTriARVKhac,
      },
      liDoKHTuChoi: this.liDoKHTuChoi,
      ngayBDDieuTri: new Date(this.ngayBDDieuTri).getTime(),
      ngayCgDieuTriARV: new Date(this.ngayCgDieuTriARV).getTime(),
      dieuTriARV: this.dieuTriARV,
      donViDieuTriHIV: {
        value: this.donViDieuTriHIV,
        khac: this.donViDieuTriHIVKhac,
      },
      maSoDieuTriHIV: this.maSoDieuTriHIV,
      dieuTriArvQuaBHYT: this.dieuTriArvQuaBHYT,
      ttDieuTriARV: this.ttDieuTriARV,
      ngayTaoDieuTriArv: this.sendDay,
      ngayTaoTinhTrangArv: null,
      ngayTaoKqArv: null,
    };
    const clonePayload = JSON.parse(JSON.stringify(payload));
    delete clonePayload.userId;
    delete clonePayload.ngayTaoKqArv;
    delete clonePayload.ngayTaoTinhTrangArv;
    delete clonePayload.ngayTaoTinhTrangArv;
    const objectEmpty = (element) => {
      return element === "";
    };
    const isEmpty = (element) => {
      return (
        element === "" ||
        element === 0 ||
        Object.values(element).every(objectEmpty)
      );
    };
    if (payload.dieuTriARV) {
      payload.ngayTaoKqArv = this.sendDay;
    } else {
      delete payload.ngayTaoKqArv;
    }
    if (payload.ttDieuTriARV) {
      payload.ngayTaoTinhTrangArv = this.sendDay;
    } else {
      delete payload.ngayTaoTinhTrangArv;
    }
    if (Object.values(clonePayload).every(isEmpty)) {
      this.isLoadingTreatmentArv = false;
    } else {
      if (
        (this.chuyenGuiARV === "Chưa đồng ý chuyển gửi" &&
          !this.liDoKHTuChoi) ||
        (this.chuyenGuiARV === "Đồng ý chuyển gửi" && !this.ngayCgDieuTriARV) ||
        (this.dieuTriARV === "Có" && !this.ngayBDDieuTri) ||
        (this.dieuTriARV === "Có" && !this.donViDieuTriHIV) ||
        (this.dieuTriARV === "Có" && !this.maSoDieuTriHIV) ||
        (this.chuyenGuiARV === "Đồng ý chuyển gửi" &&
          this.ngayCgArvAfterNgayXNSL) ||
        (this.chuyenGuiARV === "Đồng ý chuyển gửi" &&
          this.ngayCgArvAfterToday) ||
        (this.dieuTriARV === "Có" && this.ngayDieuTriHIVAfterNgayXNSL) ||
        (this.dieuTriARV === "Có" && this.ngayDieuTriHIVAfterToday)
      ) {
        this.validateTreatmentArv = true;
        this.isLoadingTreatmentArv = false;
        const alertConfirm = this.atrCtrl.create({
          cssClass: "my-custom-class",
          message: "Vui lòng nhập đầy đủ thông tin hợp lệ!",
        });
        (await alertConfirm).dismiss();

        (await alertConfirm).present();
      } else {
        this.customerService
          .saveHistoryArv(payload)
          .then(async (res) => {
            this.isLoadingTreatmentArv = false;
            const alertConfirm = this.atrCtrl.create({
              message: "Thông tin vừa được lưu vào hệ thống!",
              cssClass: "my-custom-class-success",
            });
            (await alertConfirm).dismiss();
            (await alertConfirm).present();
            this.isCreateTreatmentArv = false;
            this.customerService
              .getDetailUsersSendKPElog(this.customerId)
              .then((res: any) => {
                this.countDieuTriArv =
                  res.ttDichVu.hoTroDangKiDieuTriARV.length;
                this._idTreatmentArv =
                  res?.ttDichVu?.hoTroDangKiDieuTriARV[
                    res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
                  ]?._id;
                this.updateRelateHistoryTreatmentArv = false;
              });
          })
          .catch();
      }
    }
  }
  async updateHistoryTreatmentArv() {
    this.isLoadingTreatmentArv = true;
    const payload = {
      userId: this.customerId,
      chuyenGuiARV: this.chuyenGuiARV,
      coSoCgDieuTriARV: {
        value: this.coSoCgDieuTriARV,
        khac: this.coSoCgDieuTriARVKhac,
      },
      liDoKHTuChoi: this.liDoKHTuChoi,
      ngayBDDieuTri: new Date(this.ngayBDDieuTri).getTime(),
      ngayCgDieuTriARV: new Date(this.ngayCgDieuTriARV).getTime(),
      dieuTriARV: this.dieuTriARV,
      donViDieuTriHIV: {
        value: this.donViDieuTriHIV,
        khac: this.donViDieuTriHIVKhac,
      },
      maSoDieuTriHIV: this.maSoDieuTriHIV,
      dieuTriArvQuaBHYT: this.dieuTriArvQuaBHYT,
      ttDieuTriARV: this.ttDieuTriARV,
      ngayTaoDieuTriArv: this.sendDay,
      ngayTaoTinhTrangArv: null,
      ngayTaoKqArv: null,
    };
    if (payload.dieuTriARV) {
      payload.ngayTaoKqArv = this.sendDay;
    } else {
      delete payload.ngayTaoKqArv;
    }
    if (payload.ttDieuTriARV) {
      payload.ngayTaoTinhTrangArv = this.sendDay;
    } else {
      delete payload.ngayTaoTinhTrangArv;
    }
    if (
      (this.chuyenGuiARV === "Chưa đồng ý chuyển gửi" && !this.liDoKHTuChoi) ||
      (this.chuyenGuiARV === "Đồng ý chuyển gửi" && !this.ngayCgDieuTriARV) ||
      (this.dieuTriARV === "Có" && !this.ngayBDDieuTri) ||
      (this.dieuTriARV === "Có" && !this.donViDieuTriHIV) ||
      (this.dieuTriARV === "Có" && !this.maSoDieuTriHIV) ||
      (this.chuyenGuiARV === "Đồng ý chuyển gửi" &&
        this.ngayCgArvAfterNgayXNSL) ||
      (this.chuyenGuiARV === "Đồng ý chuyển gửi" && this.ngayCgArvAfterToday) ||
      (this.dieuTriARV === "Có" && this.ngayDieuTriHIVAfterNgayXNSL) ||
      (this.dieuTriARV === "Có" && this.ngayDieuTriHIVAfterToday)
    ) {
      this.validateTreatmentArv = true;
      this.isLoadingTreatmentArv = false;
      const alertConfirm = this.atrCtrl.create({
        cssClass: "my-custom-class",
        message: "Vui lòng nhập đầy đủ thông tin hợp lệ!",
      });
      (await alertConfirm).dismiss();

      (await alertConfirm).present();
    } else {
      this.customerService
        .updateHistoryTreatmentArv(this._idTreatmentArv, payload)
        .then(async (res) => {
          this.isLoadingTreatmentArv = false;
          const alertConfirm = this.atrCtrl.create({
            message: "Thông tin vừa được lưu vào hệ thống!",
            cssClass: "my-custom-class-success",
          });
          (await alertConfirm).dismiss();
          (await alertConfirm).present();
        })
        .catch();
    }
  }
  onCompleteComplianceArv() {
    this.isLoadingComplianceArv = true;
    const payload = {
      userId: this.customerId,
      ttDieuTriHIV1: {
        value: this.ttDieuTriHIV1,
        soLieuDaQuen: this.soLieuDaQuenHIV1,
        soNgayNgung: this.soNgayNgungHIV1,
        lyDoQuen: this.lyDoQuenHIV1,
        khac: this.khacHIV1,
      },
      ttDieuTriHIV2: {
        value: this.ttDieuTriHIV2,
        soLieuDaQuen: this.soLieuDaQuenHIV2,
        soNgayNgung: this.soNgayNgungHIV2,
        lyDoQuen: this.lyDoQuenHIV2,
        khac: this.khacHIV2,
      },
      ttDieuTriHIV3: {
        value: this.ttDieuTriHIV3,
        soLieuDaQuen: this.soLieuDaQuenHIV3,
        soNgayNgung: this.soNgayNgungHIV3,
        lyDoQuen: this.lyDoQuenHIV3,
        khac: this.khacHIV3,
      },
      kqXNTLVR: this.kqXNTLVR,
      ngayXNTLVR: new Date(this.ngayXNTLVR).getTime(),
      ketQuaCD4: this.ketQuaCD4,
      ngayXNCD4: new Date(this.ngayXNCD4).getTime(),
      ngayTaoTuanThuArv: this.sendDay,
      ngayTaoKqCD4: null,
      ngayTaoKqTlvr: null,
    };
    const clonePayload = JSON.parse(JSON.stringify(payload));
    delete clonePayload.userId;
    delete clonePayload.ngayTaoTuanThuArv;
    delete clonePayload.ngayTaoKqCD4;
    delete clonePayload.ngayTaoKqTlvr;
    const objectEmpty = (element) => {
      return element === "";
    };
    const isEmpty = (element: any) => {
      return (
        element === "" ||
        element === 0 ||
        (element.value === "" &&
          element.soLieuDaQuen === "" &&
          element.soNgayNgung === "" &&
          element.lyDoQuen === "" &&
          element.khac === "") ||
        element?.value === ""
      );
    };
    if (payload.kqXNTLVR || payload.ngayXNTLVR) {
      payload.ngayTaoKqTlvr = this.sendDay;
    } else {
      delete payload.ngayTaoKqTlvr;
    }
    if (payload.ketQuaCD4 || payload.ngayXNCD4) {
      payload.ngayTaoKqCD4 = this.sendDay;
    } else {
      delete payload.ngayTaoKqCD4;
    }
    if (Object.values(clonePayload).every(isEmpty)) {
      this.isLoadingComplianceArv = false;
    } else {
      this.customerService
        .saveComplianceHistoryArv(payload)
        .then(async (res) => {
          this.isLoadingComplianceArv = false;
          const alertConfirm = this.atrCtrl.create({
            message: "Thông tin vừa được lưu vào hệ thống!",
            cssClass: "my-custom-class-success",
          });
          (await alertConfirm).dismiss();
          (await alertConfirm).present();
          this.isCreateComplianceArv = false;
          this.customerService
            .getDetailUsersSendKPElog(this.customerId)
            .then((res: any) => {
              this.countTTArv = res.ttDichVu.hoTroTuanThuDieuTriARV.length;
              this._idComplianceArv =
                res?.ttDichVu?.hoTroTuanThuDieuTriARV[
                  res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
                ]?._id;
              this.updateRelateHistoryComplianceArv = false;
            });
        })
        .catch(() => (this.isLoadingComplianceArv = false));
    }
  }
  updateHistoryComplianceArv() {
    this.isLoadingComplianceArv = true;
    const payload = {
      userId: this.customerId,
      ttDieuTriHIV1: {
        value: this.ttDieuTriHIV1,
        soLieuDaQuen: this.soLieuDaQuenHIV1,
        soNgayNgung: this.soNgayNgungHIV1,
        lyDoQuen: this.lyDoQuenHIV1,
        khac: this.khacHIV1,
      },
      ttDieuTriHIV2: {
        value: this.ttDieuTriHIV2,
        soLieuDaQuen: this.soLieuDaQuenHIV2,
        soNgayNgung: this.soNgayNgungHIV2,
        lyDoQuen: this.lyDoQuenHIV2,
        khac: this.khacHIV2,
      },
      ttDieuTriHIV3: {
        value: this.ttDieuTriHIV3,
        soLieuDaQuen: this.soLieuDaQuenHIV3,
        soNgayNgung: this.soNgayNgungHIV3,
        lyDoQuen: this.lyDoQuenHIV3,
        khac: this.khacHIV3,
      },
      kqXNTLVR: this.kqXNTLVR,
      ngayXNTLVR: new Date(this.ngayXNTLVR).getTime(),
      ketQuaCD4: this.ketQuaCD4,
      ngayXNCD4: new Date(this.ngayXNCD4).getTime(),
      ngayTaoTuanThuArv: this.sendDay,
      ngayTaoKqCD4: null,
      ngayTaoKqTlvr: null,
    };
    if (payload.kqXNTLVR || payload.ngayXNTLVR) {
      payload.ngayTaoKqTlvr = this.sendDay;
    } else {
      delete payload.ngayTaoKqTlvr;
    }
    if (payload.ketQuaCD4 || payload.ngayXNCD4) {
      payload.ngayTaoKqCD4 = this.sendDay;
    } else {
      delete payload.ngayTaoKqCD4;
    }
    this.customerService
      .updateHistoryComplianceArv(this._idComplianceArv, payload)
      .then(async (res) => {
        this.isLoadingComplianceArv = false;
        const alertConfirm = this.atrCtrl.create({
          message: "Thông tin vừa được lưu vào hệ thống!",
          cssClass: "my-custom-class-success",
        });
        (await alertConfirm).dismiss();
        (await alertConfirm).present();
      })
      .catch();
  }
  async onCompleteHistoryViolance() {
    this.isLoadingViolence = true;
    const payload = {
      userId: this.customerId,
      tuVanBTBC: this.tuVanBTBC,
      khachDongYKhaiBaoBTBC: this.khachDongYKhaiBaoBTBC,
      ngayKhachDongYKhaiBaoBTBC: new Date(
        this.ngayKhachDongYKhaiBaoBTBC
      ).getTime(),
      soBTBCKhaiBao: this.soBTBCKhaiBao,
      soBanTinhBanChichThuongXuyen: this.soBanTinhBanChichThuongXuyen,
      soBanTinhBanChichBatChot: this.soBanTinhBanChichBatChot,
      ngayCungCapDV: new Date(this.ngayCungCapDV).getTime(),
    };
    const clonePayload = JSON.parse(JSON.stringify(payload));
    delete clonePayload.userId;
    const isEmpty = (element: any) => {
      return element === "" || element === 0 || element === null;
    };

    if (Object.values(clonePayload).every(isEmpty)) {
      this.isLoadingViolence = false;
    } else {
      if (
        (this.tuVanBTBC === "Có" && !this.khachDongYKhaiBaoBTBC) ||
        (this.tuVanBTBC === "Có" && !this.ngayKhachDongYKhaiBaoBTBC) ||
        (this.tuVanBTBC === "Có" &&
          this.khachDongYKhaiBaoBTBC === "Có" &&
          this.soBanTinhBanChichBatChot > this.soBTBCKhaiBao)
      ) {
        this.validateViolence = true;
        this.isLoadingViolence = false;
        const alertConfirm = this.atrCtrl.create({
          cssClass: "my-custom-class",
          message: "Vui lòng nhập đầy đủ thông tin hợp lệ!",
        });
        (await alertConfirm).dismiss();

        (await alertConfirm).present();
      } else {
        this.customerService
          .saveHistoryViolence(payload)
          .then(async (res) => {
            this.isLoadingViolence = false;
            const alertConfirm = this.atrCtrl.create({
              message: "Thông tin vừa được lưu vào hệ thống!",
              cssClass: "my-custom-class-success",
            });
            (await alertConfirm).dismiss();
            (await alertConfirm).present();
            this.isCreateNewViolence = false;
            this.customerService
              .getDetailUsersSendKPElog(this.customerId)
              .then((res: any) => {
                this.countBaoHanh =
                  res.ttDichVu.tuVanVaHoTroUngPhoNguyCoBaoHanh.length;
                this._idViolence =
                  res?.ttDichVu?.tuVanVaHoTroUngPhoNguyCoBaoHanh[
                    res?.ttDichVu?.tuVanVaHoTroUngPhoNguyCoBaoHanh.length - 1
                  ]?._id;
                this.updateRelateHistoryViolence = false;
              });
          })
          .catch(() => (this.isLoadingViolence = false));
      }
    }
  }
  async updateHistoryViolence() {
    this.isLoadingViolence = true;
    const payload = {
      userId: this.customerId,
      tuVanBTBC: this.tuVanBTBC,
      khachDongYKhaiBaoBTBC: this.khachDongYKhaiBaoBTBC,
      ngayKhachDongYKhaiBaoBTBC: new Date(
        this.ngayKhachDongYKhaiBaoBTBC
      ).getTime(),
      soBTBCKhaiBao: this.soBTBCKhaiBao,
      soBanTinhBanChichThuongXuyen: this.soBanTinhBanChichThuongXuyen,
      soBanTinhBanChichBatChot: this.soBanTinhBanChichBatChot,
      ngayCungCapDV: new Date(this.ngayCungCapDV).getTime(),
    };
    if (payload.khachDongYKhaiBaoBTBC === "Không") {
      payload.ngayKhachDongYKhaiBaoBTBC = null;
      payload.soBTBCKhaiBao = null;
      payload.soBanTinhBanChichThuongXuyen = null;
      payload.soBanTinhBanChichBatChot = null;
      payload.ngayCungCapDV = null;
    }
    if (
      (this.tuVanBTBC === "Có" && !this.khachDongYKhaiBaoBTBC) ||
      (this.tuVanBTBC === "Có" &&
        this.khachDongYKhaiBaoBTBC === "Có" &&
        !this.ngayKhachDongYKhaiBaoBTBC) ||
      (this.tuVanBTBC === "Có" &&
        this.khachDongYKhaiBaoBTBC === "Có" &&
        this.soBanTinhBanChichBatChot > this.soBTBCKhaiBao)
    ) {
      this.validateViolence = true;
      this.isLoadingViolence = false;
      const alertConfirm = this.atrCtrl.create({
        cssClass: "my-custom-class",
        message: "Vui lòng nhập đầy đủ thông tin hợp lệ!",
      });
      (await alertConfirm).dismiss();

      (await alertConfirm).present();
    } else {
      this.customerService
        .updateHistoryViolence(this._idViolence, payload)
        .then(async (res) => {
          this.isLoadingViolence = false;
          const alertConfirm = this.atrCtrl.create({
            message: "Thông tin vừa được lưu vào hệ thống!",
            cssClass: "my-custom-class-success",
          });
          (await alertConfirm).dismiss();
          (await alertConfirm).present();
        })
        .catch();
    }
  }
  onCompleteChangeHabbit() {
    this.isLoadingChangeHabit = true;
    const payload = {
      userId: this.customerId,
      tuVanThayDoiHanhVi: this.tuVanThayDoiHanhVi,
      ngayTuVanThayDoiHanhVi: new Date(this.ngayTuVanThayDoiHanhVi).getTime(),
    };
    const clonePayload = JSON.parse(JSON.stringify(payload));
    delete clonePayload.userId;
    const isEmpty = (element: any) => {
      return element === "" || element === 0;
    };

    if (Object.values(clonePayload).every(isEmpty)) {
      this.isLoadingChangeHabit = false;
    } else {
      this.customerService
        .saveHistoryChangeHabit(payload)
        .then(async (res) => {
          this.isLoadingChangeHabit = false;
          this.isLoadingChangeHabit = false;
          const alertConfirm = this.atrCtrl.create({
            message: "Thông tin vừa được lưu vào hệ thống!",
            cssClass: "my-custom-class-success",
          });
          (await alertConfirm).dismiss();
          (await alertConfirm).present();
          this.isCreateNewChangeHabit = false;
          this.customerService
            .getDetailUsersSendKPElog(this.customerId)
            .then((res: any) => {
              this.countThayDoiHvi = res.ttDichVu.tuVanThayDoiHanhVi.length;
              this._idChangeHabit =
                res?.ttDichVu?.tuVanThayDoiHanhVi[
                  res?.ttDichVu?.tuVanThayDoiHanhVi.length - 1
                ]?._id;
              this.updateRelateHistoryChangeHabit = false;
            });
        })
        .catch(() => (this.isLoadingChangeHabit = false));
    }
  }
  updateHistoryChangeHabit() {
    this.isLoadingChangeHabit = true;
    const payload = {
      userId: this.customerId,
      tuVanThayDoiHanhVi: this.tuVanThayDoiHanhVi,
      ngayTuVanThayDoiHanhVi: new Date(this.ngayTuVanThayDoiHanhVi).getTime(),
    };
    this.customerService
      .updateHistoryChangeHabit(this._idChangeHabit, payload)
      .then(async (res) => {
        this.isLoadingChangeHabit = false;
        const alertConfirm = this.atrCtrl.create({
          message: "Thông tin vừa được lưu vào hệ thống!",
          cssClass: "my-custom-class-success",
        });
        (await alertConfirm).dismiss();
        (await alertConfirm).present();
      })
      .catch();
  }
  onCompleteReduceMethod() {
    this.isLoadingReduceMethod = true;
    const payload = {
      userId: this.customerId,
      tuVanHanhViCoHaiChoSK: this.tuVanHanhViCoHaiChoSK,
      ngayTuVanHanhViCoHaiChoSK: new Date(
        this.ngayTuVanHanhViCoHaiChoSK
      ).getTime(),
    };
    const clonePayload = JSON.parse(JSON.stringify(payload));
    delete clonePayload.userId;
    const isEmpty = (element: any) => {
      return element === "" || element === 0;
    };

    if (Object.values(clonePayload).every(isEmpty)) {
      this.isLoadingReduceMethod = false;
    } else {
      this.customerService
        .saveHistoryMethodReduce(payload)
        .then(async (res) => {
          this.isLoadingReduceMethod = false;
          const alertConfirm = this.atrCtrl.create({
            message: "Thông tin vừa được lưu vào hệ thống!",
            cssClass: "my-custom-class-success",
          });
          (await alertConfirm).dismiss();
          (await alertConfirm).present();
          this.isCreateNewMethodReduce = false;
          this.customerService
            .getDetailUsersSendKPElog(this.customerId)
            .then((res: any) => {
              this.countBPGiamHai = res.ttDichVu.tuVanCanBienPhapGiamHai.length;
              this._idReduceMethod =
                res?.ttDichVu?.tuVanCanBienPhapGiamHai[
                  res?.ttDichVu?.tuVanCanBienPhapGiamHai.length - 1
                ]?._id;
              this.updateRelateHistoryReduceMethod = false;
            });
        })
        .catch(() => (this.isLoadingReduceMethod = false));
    }
  }
  updateReduceMethod() {
    this.isLoadingReduceMethod = true;
    const payload = {
      userId: this.customerId,
      tuVanHanhViCoHaiChoSK: this.tuVanHanhViCoHaiChoSK,
      ngayTuVanHanhViCoHaiChoSK: new Date(
        this.ngayTuVanHanhViCoHaiChoSK
      ).getTime(),
    };
    if (payload.tuVanHanhViCoHaiChoSK === "Không") {
      payload.ngayTuVanHanhViCoHaiChoSK = null;
    }
    this.customerService
      .updateHistoryReduceMethod(this._idReduceMethod, payload)
      .then(async (res) => {
        this.isLoadingReduceMethod = false;
        const alertConfirm = this.atrCtrl.create({
          message: "Thông tin vừa được lưu vào hệ thống!",
          cssClass: "my-custom-class-success",
        });
        (await alertConfirm).dismiss();
        (await alertConfirm).present();
      })
      .catch();
  }
  onCompleteShareHIV() {
    this.isLoadingShareHiv = true;
    const payload = {
      userId: this.customerId,
      tuVanChiaSeTTHIV: this.tuVanChiaSeTTHIV,
      ngayTuVanChiaSeTTHIV: new Date(this.ngayTuVanChiaSeTTHIV).getTime(),
    };
    const clonePayload = JSON.parse(JSON.stringify(payload));
    delete clonePayload.userId;
    const isEmpty = (element: any) => {
      return element === "" || element === 0;
    };

    if (Object.values(clonePayload).every(isEmpty)) {
      this.isLoadingShareHiv = false;
    } else {
      this.customerService
        .saveHistoryShareHIV(payload)
        .then(async (res) => {
          this.isLoadingShareHiv = false;
          const alertConfirm = this.atrCtrl.create({
            message: "Thông tin vừa được lưu vào hệ thống!",
            cssClass: "my-custom-class-success",
          });
          (await alertConfirm).dismiss();
          (await alertConfirm).present();
          this.isCreateNewShareHIV = false;
          this.customerService
            .getDetailUsersSendKPElog(this.customerId)
            .then((res: any) => {
              this.countChiaSeHiv = res.ttDichVu.tuVanChiaSeTinhTrangHIV.length;
              this._idShareHIV =
                res?.ttDichVu?.tuVanChiaSeTinhTrangHIV[
                  res?.ttDichVu?.tuVanChiaSeTinhTrangHIV.length - 1
                ]?._id;
              this.updateRelateHistoryShareHiv = false;
            });
        })
        .catch(() => (this.isLoadingShareHiv = false));
    }
  }
  updateHistoryShareHIV() {
    this.isLoadingShareHiv = true;
    const payload = {
      userId: this.customerId,
      tuVanChiaSeTTHIV: this.tuVanChiaSeTTHIV,
      ngayTuVanChiaSeTTHIV: new Date(this.ngayTuVanChiaSeTTHIV).getTime(),
    };
    if (payload.tuVanChiaSeTTHIV === "Không") {
      payload.ngayTuVanChiaSeTTHIV = null;
    }
    this.customerService
      .updateHistoryShareHIV(this._idShareHIV, payload)
      .then(async (res) => {
        this.isLoadingShareHiv = false;
        const alertConfirm = this.atrCtrl.create({
          message: "Thông tin vừa được lưu vào hệ thống!",
          cssClass: "my-custom-class-success",
        });
        (await alertConfirm).dismiss();
        (await alertConfirm).present();
      })
      .catch();
  }
  async onCompleteSexIllness() {
    this.isLoadingSexIlness = true;
    const payload = {
      userId: this.customerId,
      kqXNGiangMai: this.kqXNGiangMai,
      ngayXNGiangMai: new Date(this.ngayXNGiangMai).getTime(),
    };
    const clonePayload = JSON.parse(JSON.stringify(payload));
    delete clonePayload.userId;
    const isEmpty = (element: any) => {
      return element === "" || element === 0;
    };

    if (Object.values(clonePayload).every(isEmpty)) {
      this.isLoadingSexIlness = false;
    } else {
      if (
        (this.tenSinhPham === "HIV/Syphilis Duo" && !this.kqXNGiangMai) ||
        (this.tenSinhPham === "HIV/Syphilis Duo" && !this.ngayXNGiangMai)
      ) {
        this.validateSexualIllness = true;
        this.isLoadingSexIlness = false;
        const alertConfirm = this.atrCtrl.create({
          cssClass: "my-custom-class",
          message: "Vui lòng nhập đầy đủ thông tin hợp lệ!",
        });
        (await alertConfirm).dismiss();

        (await alertConfirm).present();
      } else {
        if (this.tenSinhPham === "HIV/Syphilis Duo") {
          this.customerService
            .saveHistorySexualIllness(payload)
            .then(async (res) => {
              this.isLoadingSexIlness = false;
              const alertConfirm = this.atrCtrl.create({
                message: "Thông tin vừa được lưu vào hệ thống!",
                cssClass: "my-custom-class-success",
              });
              (await alertConfirm).dismiss();
              (await alertConfirm).present();
              this.isCreateNewSexIllness = false;
              this.customerService
                .getDetailUsersSendKPElog(this.customerId)
                .then((res: any) => {
                  this.countBenhTd =
                    res.ttDichVu.tuVanVaPhatHienSomCacBenhLayQuaDuongTinhDuc.length;
                  this._idSexualIllness =
                    res?.ttDichVu?.tuVanVaPhatHienSomCacBenhLayQuaDuongTinhDuc[
                      res?.ttDichVu?.tuVanVaPhatHienSomCacBenhLayQuaDuongTinhDuc
                        .length - 1
                    ]?._id;
                  this.updateRelateHistorySexIllness = false;
                });
            })
            .catch(() => (this.isLoadingSexIlness = false));
        }
      }
    }
  }
  async updateHistorySexualIllness() {
    this.isLoadingSexIlness = true;
    const payload = {
      userId: this.customerId,
      kqXNGiangMai: this.kqXNGiangMai,
      ngayXNGiangMai: new Date(this.ngayXNGiangMai).getTime(),
    };
    if (
      (this.tenSinhPham === "HIV/Syphilis Duo" && !this.kqXNGiangMai) ||
      (this.tenSinhPham === "HIV/Syphilis Duo" && !this.ngayXNGiangMai)
    ) {
      this.validateSexualIllness = true;
      this.isLoadingSexIlness = false;
      const alertConfirm = this.atrCtrl.create({
        cssClass: "my-custom-class",
        message: "Vui lòng nhập đầy đủ thông tin hợp lệ!",
      });
      (await alertConfirm).dismiss();

      (await alertConfirm).present();
    } else {
      this.customerService
        .updateHistorySexualIllness(this._idSexualIllness, payload)
        .then(async (res) => {
          this.isLoadingSexIlness = false;
          const alertConfirm = this.atrCtrl.create({
            message: "Thông tin vừa được lưu vào hệ thống!",
            cssClass: "my-custom-class-success",
          });
          (await alertConfirm).dismiss();
          (await alertConfirm).present();
        })
        .catch();
    }
  }
  onCompleteOtherMethod() {
    this.isLoadingOtherSend = true;
    const payload = {
      userId: this.customerId,
      cgDichVuKhac: this.cgDichVuKhac,
      cgKhac: this.cgKhac,
    };
    const clonePayload = JSON.parse(JSON.stringify(payload));
    delete clonePayload.userId;
    if (Object.values(clonePayload).every((value) => value === "")) {
      this.isLoadingOtherSend = false;
    } else {
      this.customerService
        .saveHistoryOtherSend(payload)
        .then(async (res) => {
          this.isLoadingOtherSend = false;
          const alertConfirm = this.atrCtrl.create({
            message: "Thông tin vừa được lưu vào hệ thống!",
            cssClass: "my-custom-class-success",
          });
          (await alertConfirm).dismiss();
          (await alertConfirm).present();
          this.isCreateNewOtherMethod = false;
          this.customerService
            .getDetailUsersSendKPElog(this.customerId)
            .then((res: any) => {
              this.countCgKhac =
                res.ttDichVu.chuyenGuiCacDichVuDuPhongKhac.length;
              this._idOtherMethod =
                res?.ttDichVu?.chuyenGuiCacDichVuDuPhongKhac[
                  res?.ttDichVu?.chuyenGuiCacDichVuDuPhongKhac.length - 1
                ]?._id;
              this.updateRelateHistoryOtherMethod = false;
            });
        })
        .catch(() => (this.isLoadingOtherSend = false));
    }
  }
  updateHistoryOtherMethod() {
    this.isLoadingOtherSend = true;
    const payload = {
      userId: this.customerId,
      cgDichVuKhac: this.cgDichVuKhac,
      cgKhac: this.cgKhac,
    };
    this.customerService
      .updateHistoryOtherMethod(this._idOtherMethod, payload)
      .then(async (res) => {
        this.isLoadingOtherSend = false;
        const alertConfirm = this.atrCtrl.create({
          message: "Thông tin vừa được lưu vào hệ thống!",
          cssClass: "my-custom-class-success",
        });
        (await alertConfirm).dismiss();
        (await alertConfirm).present();
      })
      .catch();
  }
  onCompleteSexHealth() {
    this.isLoadingSexHealth = true;
    const payload = {
      userId: this.customerId,
      tuVanSKTD: this.tuVanSKTD,
      ngayTuVanSKTD: new Date(this.ngayTuVanSKTD).getTime(),
    };
    const clonePayload = JSON.parse(JSON.stringify(payload));
    delete clonePayload.userId;
    const isEmpty = (element: any) => {
      return element === "" || element === 0;
    };

    if (Object.values(clonePayload).every(isEmpty)) {
      this.isLoadingSexHealth = false;
    } else {
      this.customerService
        .saveHistorySexHealth(payload)
        .then(async (res) => {
          this.isLoadingSexHealth = false;
          const alertConfirm = this.atrCtrl.create({
            message: "Thông tin vừa được lưu vào hệ thống!",
            cssClass: "my-custom-class-success",
          });
          (await alertConfirm).dismiss();
          (await alertConfirm).present();
          this.isCreateNewSexHealth = false;
          this.customerService
            .getDetailUsersSendKPElog(this.customerId)
            .then((res: any) => {
              this.countSKTD =
                res.ttDichVu.tuVanSucKhoeTinhDucVaSucKhoeSinhSan.length;
              this._idSexHealth =
                res?.ttDichVu?.tuVanSucKhoeTinhDucVaSucKhoeSinhSan[
                  res?.ttDichVu?.tuVanSucKhoeTinhDucVaSucKhoeSinhSan.length - 1
                ]?._id;
              this.updateRelateHistorySexHealth = false;
            });
        })
        .catch(() => (this.isLoadingSexHealth = false));
    }
  }
  updateHistorySexHealth() {
    this.isLoadingSexHealth = true;
    const payload = {
      userId: this.customerId,
      tuVanSKTD: this.tuVanSKTD,
      ngayTuVanSKTD: new Date(this.ngayTuVanSKTD).getTime(),
    };
    const clonePayload = JSON.parse(JSON.stringify(payload));
    delete clonePayload.userId;
    const isEmpty = (element: any) => {
      return element === "" || element === 0;
    };

    if (Object.values(clonePayload).every(isEmpty)) {
    } else {
      this.customerService
        .updateHistorySexHealth(this._idSexHealth, payload)
        .then(async (res) => {
          this.isLoadingSexHealth = false;
          const alertConfirm = this.atrCtrl.create({
            message: "Thông tin vừa được lưu vào hệ thống!",
            cssClass: "my-custom-class-success",
          });
          (await alertConfirm).dismiss();
          (await alertConfirm).present();
        })
        .catch();
    }
  }
  goBack() {
    if (localStorage.getItem("isNew") === "1") {
      this.goToPage("customer");
    } else if (localStorage.getItem("isNew") === "0") {
      this.goToPage("tabs/customer-detail-kpelog/" + this.customerId);
    }
    localStorage.setItem("isNew", "0");
  }

  handleChangedieuTriARV(e) {
    this.ttDieuTriARV = e.target.value;
  }
  handleHIVTestDate(e) {
    this.ngayXNSangLocTaiCD = e.target.value;
    this.ngayXNGiangMai = this.ngayXNSangLocTaiCD;
    this.ngayXNAfterToday =
      moment(this.ngayXNSangLocTaiCD).startOf("d") >
      moment(this.today).startOf("d");
    this.ngayBDTCAfterNgayXNSL =
      moment(this.ngayBDTiepCan).startOf("d") >
      moment(this.ngayXNSangLocTaiCD).startOf("d");
    if (!this.isBasicInfoCreateSameDay) {
      this.ngayChuyenGui = this.ngayXNSangLocTaiCD;
      this.ngayTraKQXNKD = this.ngayXNSangLocTaiCD;
    }
    if (this.cgXetNghiemKhangDinh === "Có") {
      this.ngayXNAfterNgayCg =
        moment(this.ngayXNSangLocTaiCD).startOf("d") >
        moment(this.ngayChuyenGui).startOf("d");
    }
  }
  handleChangeCgXetNghiemKhangDinh(e) {
    this.cgXetNghiemKhangDinh = e.target.value;
    if (this.cgXetNghiemKhangDinh === "Không") {
      this.coSoLamXNKhangDinh = "";
      this.ngayChuyenGui = "";
      this.ngayCBOCoKQ = null;
      this.ngayTraKQXNKD = null;
      this.ketQuaXNKD = "";
      this.loaiXetNghiem = "";
      this.donViLayMau = "";
      this.valueUploadFile = "";
      this.maXNKD = "";
    } else {
      this.ngayChuyenGui = this.ngayXNSangLocTaiCD;
    }
  }
  handleChangeNgayTraXNKD(e) {
    this.ngayTraKQXNKD = e.target.value;
    this.ngayTraXNKDAfterNgayXNSL =
      moment(this.ngayXNSangLocTaiCD).startOf("d") >
      moment(this.ngayTraKQXNKD).startOf("d");
    this.ngayTraXNKDAfterToday =
      moment(this.ngayTraKQXNKD).startOf("d") > moment(this.today).startOf("d");
  }
  handleChangeNgayCgPrep(e) {
    this.ngayCgDieuTriPrep = e.target.value;
    this.ngayCgPrepAfterNgayXNSL =
      moment(this.ngayXNSangLocTaiCD).startOf("d") >
      moment(this.ngayCgDieuTriPrep).startOf("d");
    this.ngayCgPrepAfterToday =
      moment(this.ngayCgDieuTriPrep).startOf("d") >
      moment(this.today).startOf("d");
  }
  handleChangeNgayCgPep(e) {
    this.ngayCgDieuTriPep = e.target.value;
    this.ngayCgPepAfterNgayXNSL =
      moment(this.ngayXNSangLocTaiCD).startOf("d") >
      moment(this.ngayCgDieuTriPep).startOf("d");
    this.ngayCgPepAfterToday =
      moment(this.ngayCgDieuTriPep).startOf("d") >
      moment(this.today).startOf("d");
  }
  handleChangeNgayCgArv(e) {
    this.ngayCgDieuTriARV = e.target.value;
    this.ngayCgArvAfterNgayXNSL =
      moment(this.ngayXNSangLocTaiCD).startOf("d") >
      moment(this.ngayCgDieuTriARV).startOf("d");
    this.ngayCgArvAfterToday =
      moment(this.ngayCgDieuTriARV).startOf("d") >
      moment(this.today).startOf("d");
  }
  handleChangeNgayBDPrep(e) {
    this.ngayBDPrep = e.target.value;
    this.ngayDieuTriPrepAfterNgayXNSL =
      moment(this.ngayXNSangLocTaiCD).startOf("d") >
      moment(this.ngayBDPrep).startOf("d");
    this.ngayDieuTriPrepAfterToday =
      moment(this.ngayBDPrep).startOf("d") > moment(this.today).startOf("d");
  }
  handleChangeNgayDieuTriPep(e) {
    this.ngayBatDatnPEP = e.target.value;
    this.ngayDieuTriPepAfterNgayXNSL =
      moment(this.ngayXNSangLocTaiCD).startOf("d") >
      moment(this.ngayBatDatnPEP).startOf("d");
    this.ngayDieuTriPepAfterToday =
      moment(this.ngayBatDatnPEP).startOf("d") >
      moment(this.today).startOf("d");
  }
  handleChangeDieuTriHIV(e) {
    this.ngayBDDieuTri = e.target.value;
    this.ngayDieuTriHIVAfterNgayXNSL =
      moment(this.ngayXNSangLocTaiCD).startOf("d") >
      moment(this.ngayBDDieuTri).startOf("d");
    this.ngayDieuTriHIVAfterToday =
      moment(this.ngayBDDieuTri).startOf("d") > moment(this.today).startOf("d");
  }

  handleChangehinhThucXNSangLoc(e) {
    this.hinhThucXNSangLoc = e.target.value;
    if (this.hinhThucXNSangLoc !== "CBO làm xét nghiệm cho khách hàng") {
      this.soSPNhanVeChoBT = 1;
      this.soSPNhanVeChoBanNguoiThan = 0;
      this.soSPNhanVeChoBTBC = 0;
      this.tenSinhPham = "";
    } else {
      this.soSPNhanVeChoBT = null;
      this.soSPNhanVeChoBanNguoiThan = null;
      this.soSPNhanVeChoBTBC = null;
      this.hinhThucDatSP = "";
      this.hinhThucNhanSP = "";
      this.ngayNhanSP = null;
    }
  }

  handleChangeketQuaXNKhangNguyenKhangThe(e) {
    this.ketQuaXNKhangNguyenKhangThe = e.target.value;
  }

  handleChangeketQuaXNSangLoc(e) {
    this.ketQuaXNSangLoc = e.target.value;
    if (this.ketQuaXNSangLoc !== "Có phản ứng") {
      this.hinhThucDatSP = "";
      this.hinhThucNhanSP = "";
      this.ngayNhanSP = "";
      this.soSPNhanVeChoBT = null;
      this.soSPNhanVeChoBTBC = null;
      this.soSPNhanVeChoBanNguoiThan = null;
      this.ketQuaXNKhangNguyenKhangThe = "";
      this.cgXetNghiemKhangDinh = "";
      this.valueUploadFile = "";
      this.coSoLamXNKhangDinh = "";
      this.ngayChuyenGui = "";
      this.ngayCBOCoKQ = null;
      this.ngayTraKQXNKD = null;
      this.ketQuaXNKD = "";
      this.loaiXetNghiem = "";
      this.donViLayMau = "";
      this.maXNKD = "";
    }
  }

  handleChangecoSoLamXNKhangDinh(e) {
    this.coSoLamXNKhangDinh = e;
  }
  handleChangeDonViLayMau(e) {
    this.donViLayMau = e;
  }
  handleChangeDonViPrep(e) {
    this.coSoCgDieuTriPrep = e;
  }
  handleChangeDonViDieuTriPrep(e) {
    this.donViPrep = e;
  }
  handleChangeDonViPep(e) {
    this.coSoCgDieuTriPEP = e;
  }
  handleChangeDonViDieuTriPep(e) {
    this.coSonPEP = e;
  }
  handleChangeDonViARV(e) {
    this.coSoCgDieuTriARV = e;
  }
  handleChangeDonViDieuTriARV(e) {
    this.donViDieuTriHIV = e;
  }

  handleCheckSendResult() {
    if (!this.isSendHIVResult) {
      this.cgXetNghiemKhangDinh = "";
      this.coSoLamXNKhangDinh = "";
      this.ngayChuyenGui = null;
      this.ngayXNAfterNgayCg =
        moment(this.ngayXNSangLocTaiCD).startOf("d") >
        moment(this.ngayChuyenGui).startOf("d");
      this.isResultHIV = false;
      this.ngayCBOCoKQ = null;
      this.ngayTraKQXNKD = null;
      this.ketQuaXNKD = "";
      this.loaiXetNghiem = "";
      this.donViLayMau = "";
      this.maXNKD = "";
      this.valueUploadFile = "";
    }
  }
  handleCheckResultHIV() {
    if (!this.isResultHIV) {
      this.ngayCBOCoKQ = null;
      this.ngayTraKQXNKD = null;
      this.ketQuaXNKD = "";
      this.loaiXetNghiem = "";
      this.donViLayMau = "";
      this.maXNKD = "";
    } else {
      this.ngayTraKQXNKD = this.ngayXNSangLocTaiCD;
    }
  }
  handleCheckResultPrep() {
    if (!this.isSendResultPrep) {
      this.dieuTriPrep = "";
      this.ngayBDPrep = null;
      this.donViPrep = "";
      this.maSoDieuTriPrep = "";
      this.isCompliancePrep = false;
      this.isTreatmentPrepStatus = false;
      this.ttPrep1 = "";
      this.ttPrep2 = "";
      this.ttPrep3 = "";
      this.soLieuDaQuenPrep1 = "";
      this.soNgayNgungPrep1 = "";
      this.lyDoQuenPrep1 = "";
      this.khacPrep1 = "";
      this.soLieuDaQuenPrep2 = "";
      this.soNgayNgungPrep2 = "";
      this.lyDoQuenPrep2 = "";
      this.khacPrep2 = "";
      this.soLieuDaQuenPrep3 = "";
      this.soNgayNgungPrep3 = "";
      this.lyDoQuenPrep3 = "";
      this.khacPrep3 = "";
      this.ttPrep = "";
    }
  }
  handleCheckCompliancePrep() {
    if (!this.isCompliancePrep) {
      this.ttPrep1 = "";
      this.ttPrep2 = "";
      this.ttPrep3 = "";
      this.soLieuDaQuenPrep1 = "";
      this.soNgayNgungPrep1 = "";
      this.lyDoQuenPrep1 = "";
      this.khacPrep1 = "";
      this.soLieuDaQuenPrep2 = "";
      this.soNgayNgungPrep2 = "";
      this.lyDoQuenPrep2 = "";
      this.khacPrep2 = "";
      this.soLieuDaQuenPrep3 = "";
      this.soNgayNgungPrep3 = "";
      this.lyDoQuenPrep3 = "";
      this.khacPrep3 = "";
    }
  }
  handleCheckTreatmentPrepStatus() {
    if (!this.isTreatmentPrepStatus) {
      this.ttPrep = "";
    }
  }
  handleCheckTreatmentPep() {
    if (!this.isTreatmentPep) {
      this.dieuTriPEP = "";
      this.ngayBatDatnPEP = "";
      this.coSonPEP = "";
      this.maDieuTrinPEP = "";
    }
  }
  handleCheckResultARV() {
    if (!this.isResultARV) {
      this.dieuTriARV = "";
      this.ngayBDDieuTri = null;
      this.donViDieuTriHIV = "";
      this.maSoDieuTriHIV = "";
      this.dieuTriArvQuaBHYT = "";
      this.isTreatmentARVStatus = false;
      this.ttDieuTriARV = "";
    }
  }
  handleCheckTLVR() {
    if (!this.isTLVR) {
      this.kqXNTLVR = "";
      this.ngayXNTLVR = null;
    }
  }
  handleCheckCD4() {
    if (!this.isCD4) {
      this.ketQuaCD4 = "";
      this.ngayXNCD4 = null;
    }
  }

  handleCheckTreatmentARVStatus() {
    if (!this.isTreatmentARVStatus) {
      this.ttDieuTriARV = "";
    }
  }
  handleChangetenSinhPham(e) {
    this.tenSinhPham = e.target.value;
    if (this.tenSinhPham === "HIV/Syphilis Duo") {
      this.ngayXNGiangMai = this.ngayXNSangLocTaiCD;
    }
  }

  handleChangexnSangLocTaiCD(e) {
    this.xnSangLocTaiCD = e.target.value;
  }
  handleChangeHinhThucNhanSP(e) {
    this.hinhThucNhanSP = e.target.value;
    if (this.hinhThucNhanSP === "Nhận thông qua BTBC") {
      this.ngayNhanSP = null;
      this.soSPNhanVeChoBT = null;
      this.soSPNhanVeChoBTBC = null;
      this.soSPNhanVeChoBanNguoiThan = null;
    } else {
      this.soSPNhanVeChoBT = 1;
      this.soSPNhanVeChoBTBC = 0;
      this.soSPNhanVeChoBanNguoiThan = 0;
    }
  }
  handleChangeSoSpBTBC(e) {
    if (!e.target.value && e.target.value !== 0) {
      this.soSPNhanVeChoBTBC = null;
    } else {
      this.soSPNhanVeChoBTBC = Number(e.target.value);
    }
    this.invalidNumberOfMedicine =
      1 + this.soSPNhanVeChoBTBC + this.soSPNhanVeChoBanNguoiThan > 3;
  }
  handleChangeSoSpBBNT(e) {
    if (!e.target.value && e.target.value !== 0) {
      this.soSPNhanVeChoBanNguoiThan = null;
    } else {
      this.soSPNhanVeChoBanNguoiThan = Number(e.target.value);
    }
    this.invalidNumberOfMedicine =
      1 + this.soSPNhanVeChoBTBC + this.soSPNhanVeChoBanNguoiThan > 3;
  }
  handleChangeKQXNKD(e) {
    this.ketQuaXNKD = e.target.value;
  }
  handleChangeDuTCPrep(e) {
    this.duTCPrep = e.target.value;
    if (this.duTCPrep === "Không") {
      this.cgPrep = "";
      this.coSoCgDieuTriPrep = "";
      this.valueUploadFilePrep = "";
      this.ngayCgDieuTriPrep = null;
      this.dieuTriPrep = "";
      this.ngayBDPrep = null;
      this.donViPrep = "";
      this.maSoDieuTriPrep = "";
      this.ttPrep1 = "";
      this.soLieuDaQuenPrep1 = "";
      this.soNgayNgungPrep1 = "";
      this.lyDoQuenPrep1 = "";
      this.khacPrep1 = "";
      this.ttPrep2 = "";
      this.soLieuDaQuenPrep2 = "";
      this.soNgayNgungPrep2 = "";
      this.lyDoQuenPrep2 = "";
      this.khacPrep2 = "";
      this.ttPrep3 = "";
      this.soLieuDaQuenPrep3 = "";
      this.soNgayNgungPrep3 = "";
      this.lyDoQuenPrep3 = "";
      this.khacPrep3 = "";
      this.ttPrep = "";
    }
  }
  handleChangeCGPrep(e) {
    this.cgPrep = e.target.value;
    if (this.cgPrep === "Có") {
      this.ngayCgDieuTriPrep = this.ngayXNSangLocTaiCD;
    }
  }
  handleChangeCgPep(e) {
    this.cgPEP = e.target.value;
    if (this.cgPEP === "Không") {
      this.coSoCgDieuTriPEP = "";
      this.ngayCgDieuTriPep = null;
      this.dieuTriPEP = "";
      this.ngayBatDatnPEP = null;
      this.coSonPEP = "";
      this.maDieuTrinPEP = "";
    } else {
      this.ngayCgDieuTriPep = this.ngayXNSangLocTaiCD;
    }
  }
  handleChangeCgArv(e) {
    this.chuyenGuiARV = e.target.value;
    if (this.chuyenGuiARV !== "Đồng ý chuyển gửi") {
      this.dieuTriARV = "";
      this.coSoCgDieuTriARV = "";
      this.ngayCgDieuTriARV = null;
      this.ngayBDDieuTri = null;
      this.donViDieuTriHIV = "";
      this.maSoDieuTriHIV = "";
      this.dieuTriArvQuaBHYT = "";
      this.ttDieuTriARV = "";
    } else {
      this.ngayCgDieuTriARV = this.ngayXNSangLocTaiCD;
    }
  }
  handleChangeTTPrep1(e) {
    this.ttPrep1 = e.target.value;
    if (this.ttPrep1 === "Không quên liều") {
      this.soLieuDaQuenPrep1 = "";
      this.soNgayNgungPrep1 = "";
      this.lyDoQuenPrep1 = "";
      this.khacPrep1 = "";
    }
    if (this.ttPrep1 !== "Ngưng uống thuốc") {
      this.soNgayNgungPrep1 = "";
    }
    if (this.ttPrep1 !== "Có quên liều") {
      this.soLieuDaQuenPrep1 = "";
    }
  }
  handleChangeLyDoQuenPrep1(e) {
    this.lyDoQuenPrep1 = e.target.value;
    if (this.lyDoQuenPrep1 !== "Khác") {
      this.khacPrep1 = "";
    }
  }
  handleChangeTTPrep2(e) {
    this.ttPrep2 = e.target.value;
    if (this.ttPrep2 === "Không quên liều") {
      this.soLieuDaQuenPrep2 = "";
      this.soNgayNgungPrep2 = "";
      this.lyDoQuenPrep2 = "";
      this.khacPrep2 = "";
    }
    if (this.ttPrep2 !== "Ngưng uống thuốc") {
      this.soNgayNgungPrep2 = "";
    }
    if (this.ttPrep2 !== "Có quên liều") {
      this.soLieuDaQuenPrep2 = "";
    }
  }
  handleChangeLyDoQuenPrep2(e) {
    this.lyDoQuenPrep2 = e.target.value;
    if (this.lyDoQuenPrep2 !== "Khác") {
      this.khacPrep2 = "";
    }
  }
  handleChangeTTPrep3(e) {
    this.ttPrep3 = e.target.value;
    if (this.ttPrep3 === "Không quên liều") {
      this.soLieuDaQuenPrep3 = "";
      this.soNgayNgungPrep3 = "";
      this.lyDoQuenPrep3 = "";
      this.khacPrep3 = "";
    }
    if (this.ttPrep3 !== "Ngưng uống thuốc") {
      this.soNgayNgungPrep2 = "";
    }
    if (this.ttPrep3 !== "Có quên liều") {
      this.soLieuDaQuenPrep3 = "";
    }
  }
  handleChangeLyDoQuenPrep3(e) {
    this.lyDoQuenPrep3 = e.target.value;
    if (this.lyDoQuenPrep3 !== "Khác") {
      this.khacPrep3 = "";
    }
  }
  handleChangeTTArv3(e) {
    this.ttDieuTriHIV3 = e.target.value;
    if (this.ttDieuTriHIV3 === "Không quên liều") {
      this.soLieuDaQuenHIV3 = "";
      this.soNgayNgungHIV3 = "";
      this.lyDoQuenHIV3 = "";
      this.khacHIV3 = "";
    }
    if (this.ttDieuTriHIV3 !== "Ngưng uống thuốc") {
      this.soNgayNgungHIV3 = "";
    }
    if (this.ttDieuTriHIV3 !== "Có quên liều") {
      this.soLieuDaQuenHIV3 = "";
    }
  }
  handleChangeLyDoQuenARV3(e) {
    this.lyDoQuenHIV3 = e.target.value;
    if (this.lyDoQuenHIV3 !== "Khác") {
      this.khacHIV3 = "";
    }
  }
  handleChangeTTArv2(e) {
    this.ttDieuTriHIV2 = e.target.value;
    if (this.ttDieuTriHIV2 === "Không quên liều") {
      this.soLieuDaQuenHIV2 = "";
      this.soNgayNgungHIV2 = "";
      this.lyDoQuenHIV2 = "";
      this.khacHIV2 = "";
    }
    if (this.ttDieuTriHIV2 !== "Ngưng uống thuốc") {
      this.soNgayNgungHIV1 = "";
    }
    if (this.ttDieuTriHIV2 !== "Có quên liều") {
      this.soLieuDaQuenHIV2 = "";
    }
  }
  handleChangeLyDoQuenARV2(e) {
    this.lyDoQuenHIV2 = e.target.value;
    if (this.lyDoQuenHIV2 !== "Khác") {
      this.khacHIV2 = "";
    }
  }
  handleChangeTTArv1(e) {
    this.ttDieuTriHIV1 = e.target.value;
    if (this.ttDieuTriHIV1 === "Không quên liều") {
      this.soLieuDaQuenHIV1 = "";
      this.soNgayNgungHIV1 = "";
      this.lyDoQuenHIV1 = "";
      this.khacHIV1 = "";
    }
    if (this.ttDieuTriHIV1 !== "Ngưng uống thuốc") {
      this.soNgayNgungHIV1 = "";
    }
    if (this.ttDieuTriHIV1 !== "Có quên liều") {
      this.soLieuDaQuenHIV1 = "";
    }
  }
  handleChangeLyDoQuenARV1(e) {
    this.lyDoQuenHIV1 = e.target.value;
    if (this.lyDoQuenHIV1 !== "Khác") {
      this.khacHIV1 = "";
    }
  }
  handleChangeDieuTriARV(e) {
    this.dieuTriARV = e.target.value;
    if (this.dieuTriARV === "Không") {
      this.ngayBDDieuTri = null;
      this.donViDieuTriHIV = "";
      this.maSoDieuTriHIV = "";
      this.dieuTriArvQuaBHYT = "";
      this.ttDieuTriARV = "";
    } else {
      this.ngayBDDieuTri = this.ngayXNSangLocTaiCD;
    }
  }
  handleChangeDieuTriPrep(e) {
    this.dieuTriPrep = e.target.value;
    if (this.dieuTriPrep === "Chưa điều trị") {
      this.ngayBDPrep = null;
      this.donViPrep = "";
      this.maSoDieuTriPrep = "";
      this.ttPrep = "";
      this.ttPrep1 = "";
      this.soLieuDaQuenPrep1 = "";
      this.soNgayNgungPrep1 = "";
      this.lyDoQuenPrep1 = "";
      this.khacPrep1 = "";
      this.ttPrep2 = "";
      this.soLieuDaQuenPrep2 = "";
      this.soNgayNgungPrep2 = "";
      this.lyDoQuenPrep2 = "";
      this.khacPrep2 = "";
      this.ttPrep3 = "";
      this.soLieuDaQuenPrep3 = "";
      this.soNgayNgungPrep3 = "";
      this.lyDoQuenPrep3 = "";
      this.khacPrep3 = "";
    } else {
      this.ngayBDPrep = this.ngayXNSangLocTaiCD;
    }
  }

  clickOut() {
    let soTTXNSangLoc = this.soTTXNSangLoc;
    if (soTTXNSangLoc) {
      this.maXNSangLoc = this.maXetNghiemVien + soTTXNSangLoc;
      soTTXNSangLoc = "";
    } else if (!this.soTTXNSangLoc) {
      this.maXNSangLoc = this.maXetNghiemVien;
    }
  }

  clickOutTestCode() {
    const maXetNghiemVien = this.maXetNghiemVien;
    let tenXNVTheoMaTCV = "";
    let maXNVTheoHCDC = "";
    this.maXNSangLoc =
      maXetNghiemVien + (this.soTTXNSangLoc ? this.soTTXNSangLoc : "");
    this.customerService
      .getTCVByMaTCV(this.maCBO + this.maXetNghiemVien.slice(-2))
      .then((res: any) => {
        tenXNVTheoMaTCV = res?.ten;
      })
      .catch();
    this.customerService
      .getTenXetNghiemVien(maXetNghiemVien)
      .then((res: any) => {
        this.tenXetNghiemVien = res?.tenXNV;
        maXNVTheoHCDC = res?.maXNVTheoHCDC;
        this.maXetNghiemVienTheoHCDC = res?.maXNVTheoHCDC;
      });
    if (this.maXetNghiemVien.slice(-2) === this.maTCV) {
      this.maXetNghiemVienTheoHCDC = this.maTCVTheoHCDC;
      this.tenXetNghiemVien = this.tenTCV;
    } else {
      this.tenXetNghiemVien = tenXNVTheoMaTCV;
      this.maXetNghiemVienTheoHCDC = maXNVTheoHCDC;
    }
  }

  clearDatetime(value) {
    if (value === "ngayChuyenGui") {
      this.ngayChuyenGui = null;
    } else if (value === "ngayThayDoiHvi") {
      this.ngayThayDoiHvi = null;
    } else if (value === "ngayThayDoiHviCoHai") {
      this.ngayThayDoiHviCoHai = null;
    } else if (value === "ngayTvanTtrangHIV") {
      this.ngayTvanTtrangHIV = null;
    } else if (value === "ngayKhachHangDongY") {
      this.ngayKhachHangDongY = null;
    } else if (value === "ngayXetNghiemHIV") {
      this.ngayXetNghiemHIV = null;
    } else if (value === "ngayNhanSP") {
      this.ngayNhanSP = null;
    } else if (value === "ngayCBOBietKqua") {
      this.ngayCBOCoKQ = null;
    } else if (value === "ngayTraKquaHIV") {
      this.ngayTraKquaHIV = null;
    } else if (value === "ngayBdauDtri") {
      this.ngayBdauDtri = null;
    } else if (value === "ngayBdauDtriPrep") {
      this.ngayBdauDtriPrep = null;
    } else if (value === "ngayBdauDtriNpep") {
      this.ngayBdauDtriNpep = null;
    } else if (value === "ngayTvanSucKhoeTinhDuc") {
      this.ngayTvanSucKhoeTinhDuc = null;
    } else if (value === "ngayXNGiangMai") {
      this.ngayXNGiangMai = null;
    } else if (value === "ngayCgDieuTriPep") {
      this.ngayCgDieuTriPep = null;
    } else if (value === "ngayCgDieuTriARV") {
      this.ngayCgDieuTriARV = null;
    } else if (value === "ngayCgDieuTriPep") {
      this.ngayCgDieuTriPrep = null;
    }
  }

  async closeModalMenu() {
    this.isOpenModalMenu = false;
    this.missingFieldArr = [];
    const alertConfirm = this.atrCtrl.create({
      message: "Thông tin vừa được lưu vào hệ thống!",
      cssClass: "my-custom-class-success",
    });

    setTimeout(async () => {
      (await alertConfirm).dismiss();
      this.goToPage("tabs/customer");
    }, 1000);
    (await alertConfirm).present();
  }

  openTabHasError() {
    if (
      (this.xnSangLocTaiCD === "Có" && !this.ngayXNSangLocTaiCD) ||
      (this.xnSangLocTaiCD === "Có" && !this.maXetNghiemVien) ||
      (this.xnSangLocTaiCD === "Có" && !this.soTTXNSangLoc) ||
      (this.xnSangLocTaiCD === "Có" && !this.maXNSangLoc) ||
      (this.xnSangLocTaiCD === "Có" && !this.maXetNghiemVienTheoHCDC) ||
      (this.xnSangLocTaiCD === "Có" && !this.hinhThucXNSangLoc) ||
      (this.xnSangLocTaiCD === "Có" && !this.tenSinhPham) ||
      (this.xnSangLocTaiCD === "Có" && !this.ketQuaXNSangLoc) ||
      (this.xnSangLocTaiCD === "Có" &&
        this.ketQuaXNSangLoc === "Có phản ứng" &&
        !this.ketQuaXNKhangNguyenKhangThe) ||
      (this.xnSangLocTaiCD === "Có" &&
        this.hinhThucXNSangLoc !== "CBO làm xét nghiệm cho khách hàng" &&
        !this.hinhThucNhanSP) ||
      (this.xnSangLocTaiCD === "Có" &&
        this.hinhThucXNSangLoc !== "CBO làm xét nghiệm cho khách hàng" &&
        !this.hinhThucDatSP) ||
      (this.xnSangLocTaiCD === "Có" &&
        this.hinhThucXNSangLoc !== "CBO làm xét nghiệm cho khách hàng" &&
        !this.ngayNhanSP) ||
      (this.xnSangLocTaiCD === "Có" &&
        this.hinhThucXNSangLoc !== "CBO làm xét nghiệm cho khách hàng" &&
        !this.soSPNhanVeChoBT)
    ) {
      this.isShowHIVInPublic = true;
    }
    if (
      (this.duTCPrep === "Có" && !this.cgPrep) ||
      (this.cgPrep === "Không" && !this.lyDoKhongPrep) ||
      (this.duTCPrep === "Có" &&
        this.cgPrep === "Có" &&
        !this.ngayCgDieuTriPrep) ||
      (this.duTCPrep === "Có" &&
        this.cgPrep === "Có" &&
        !this.coSoCgDieuTriPrep) ||
      (this.duTCPrep === "Có" && this.cgPrep === "Có" && !this.dieuTriPrep) ||
      (this.duTCPrep === "Có" &&
        this.dieuTriPrep === "Đã điều trị" &&
        !this.ngayBDPrep) ||
      (this.duTCPrep === "Có" &&
        this.dieuTriPrep === "Đã điều trị" &&
        !this.donViPrep) ||
      (this.duTCPrep === "Có" &&
        this.dieuTriPrep === "Đã điều trị" &&
        !this.maSoDieuTriPrep)
    ) {
      this.isShowPrep = true;
    }
    if (
      (this.cgPEP === "Có" && !this.ngayCgDieuTriPep) ||
      (this.cgPEP === "Có" && !this.ngayBatDatnPEP) ||
      (this.cgPEP === "Có" && !this.coSonPEP) ||
      (this.cgPEP === "Có" && !this.maDieuTrinPEP)
    ) {
      this.isShowPep = true;
    }
    if (
      (this.chuyenGuiARV === "Chưa đồng ý chuyển gửi" && !this.liDoKHTuChoi) ||
      (this.chuyenGuiARV === "Đồng ý chuyển gửi" && !this.ngayCgDieuTriARV) ||
      (this.dieuTriARV === "Có" && !this.ngayBDDieuTri) ||
      (this.dieuTriARV === "Có" && !this.donViDieuTriHIV) ||
      (this.dieuTriARV === "Có" && !this.maSoDieuTriHIV)
    ) {
      this.isShowAntiARV = true;
    }
    if (
      (this.tuVanBTBC === "Có" && !this.khachDongYKhaiBaoBTBC) ||
      (this.tuVanBTBC === "Có" && !this.ngayKhachDongYKhaiBaoBTBC) ||
      (this.tuVanBTBC === "Có" &&
        this.khachDongYKhaiBaoBTBC === "Có" &&
        this.soBanTinhBanChichBatChot > this.soBTBCKhaiBao)
    ) {
      this.isShowHelpViolence = true;
    }
    if (
      (this.tenSinhPham === "HIV/Syphilis Duo" && !this.kqXNGiangMai) ||
      (this.tenSinhPham === "HIV/Syphilis Duo" && !this.ngayXNGiangMai)
    ) {
      this.isShowPepTest = true;
    }
  }
  checkDay10EveryMonth(date) {
    if (date) {
      return moment(date).add(1, "M").set("date", 10).format("YYYY-MM-DD");
    } else if (!date) {
      return "3000-01-01";
    }
  }
  clickOutSttTheoHCDC(e) {
    let soTTTheoHCDC = e.target.value;
    const maXetNghiemVienTheoHCDC = this.maXetNghiemVienTheoHCDC
      ? this.maXetNghiemVienTheoHCDC
      : "";
    if (soTTTheoHCDC) {
      this.maHCDC =
        "KC-" +
        this.maCBOTheoHCDC +
        "-" +
        maXetNghiemVienTheoHCDC +
        "-" +
        soTTTheoHCDC;
      soTTTheoHCDC = "";
    } else {
      this.maHCDC =
        "KC-" + this.maCBOTheoHCDC + "-" + maXetNghiemVienTheoHCDC + "-";
    }
  }
  clickOutMaXNVTheoHCDC(e) {
    let maXNVTheoHCDC = e.target.value;
    if (maXNVTheoHCDC) {
      this.maHCDC =
        "KC-" +
        this.maCBOTheoHCDC +
        "-" +
        maXNVTheoHCDC +
        "-" +
        this.soTTTheoHCDC;
      maXNVTheoHCDC = "";
    } else {
      this.maHCDC = "KC-" + this.maCBOTheoHCDC + "-" + "-" + this.soTTTheoHCDC;
    }
  }
  openUpdateRelatedHistory() {
    this.updateRelatedHistory = false;
    this.isCreateHivTest = false;
    this.customerService
      .getDetailUsersSendKPElog(this.customerId)
      .then((res: any) => {
        this.xnSangLocTaiCD =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.xnSangLocTaiCD;
        this.ngayXNSangLocTaiCD = this.formatGetDate(
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.ngayXNSangLocTaiCD
        );
        this.soTTXNSangLoc =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.soTTXNSangLoc;
        this.maXNSangLoc =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.maXNSangLoc;
        this.soTTTheoHCDC =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.soTTTheoHCDC;
        this.maXetNghiemVien =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.maXetNghiemVien;
        this.maHCDC = res?.ttDichVu?.xetNghiemSangLocHIV[
          res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
        ]?.maHCDC.includes("undefined")
          ? ""
          : res?.ttDichVu?.xetNghiemSangLocHIV[
              res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
            ]?.maHCDC;
        this.tenXetNghiemVien =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.tenXetNghiemVien;
        this.hinhThucXNSangLoc =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.hinhThucXNSangLoc;
        this.tenSinhPham =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.tenSinhPham?.value;
        this.tenSinhPhamKhac =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.tenSinhPham?.khac;
        this.ketQuaXNSangLoc =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.ketQuaXNSangLoc;
        this.ketQuaXNKhangNguyenKhangThe =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.ketQuaXNKhangNguyenKhangThe;
        this.hinhThucNhanSP =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.hinhThucNhanSP;
        this.ngayNhanSP =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.ngayNhanSP;
        this.soSPNhanVeChoBT = res?.ttDichVu?.xetNghiemSangLocHIV[
          res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
        ]?.soSPNhanVeChoBT
          ? res?.ttDichVu?.xetNghiemSangLocHIV[
              res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
            ]?.soSPNhanVeChoBT
          : 1;
        this.soSPNhanVeChoBTBC = res?.ttDichVu?.xetNghiemSangLocHIV[
          res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
        ]?.soSPNhanVeChoBTBC
          ? res?.ttDichVu?.xetNghiemSangLocHIV[
              res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
            ]?.soSPNhanVeChoBTBC
          : 0;
        this.soSPNhanVeChoBanNguoiThan = res?.ttDichVu?.xetNghiemSangLocHIV[
          res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
        ]?.soSPNhanVeChoBanNguoiThan
          ? res?.ttDichVu?.xetNghiemSangLocHIV[
              res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
            ]?.soSPNhanVeChoBanNguoiThan
          : 0;
        this.cgXetNghiemKhangDinh =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.cgXetNghiemKhangDinh;
        this.coSoLamXNKhangDinh = res?.ttDichVu?.xetNghiemSangLocHIV[
          res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
        ]?.coSoLamXNKhangDinh?.value
          ? res?.ttDichVu?.xetNghiemSangLocHIV[
              res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
            ]?.coSoLamXNKhangDinh?.value
          : "";
        this.coSoLamXNKhangDinhKhac =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.coSoLamXNKhangDinh?.khac;
        this.ngayChuyenGui = this.formatGetDate(
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.ngayChuyenGui
        );
        this.ngayCBOCoKQ = this.formatGetDate(
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.ngayCBOBietKqua
        );
        this.ngayTraKQXNKD = this.formatGetDate(
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.ngayTraKQXNKD
        );
        this.ketQuaXNKD =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.ketQuaXNKD;
        this.loaiXetNghiem =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.loaiXetNghiem;
        this.donViLayMau = res?.ttDichVu?.xetNghiemSangLocHIV[
          res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
        ]?.donViLayMau?.value
          ? res?.ttDichVu?.xetNghiemSangLocHIV[
              res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
            ]?.donViLayMau?.value
          : "";
        this.donViLayMauKhac =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.donViLayMauKhac;
        this.maXNKD =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.maXNKD;
        this.maXetNghiemVienTheoHCDC =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.maXetNghiemVienTheoHCDC;
        this.hinhThucDatSP =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.hinhThucDatSP;
        this.valueUploadFile =
          res?.ttDichVu?.xetNghiemSangLocHIV[
            res?.ttDichVu?.xetNghiemSangLocHIV.length - 1
          ]?.phieuDongYXNKD;
      })
      .catch();
    if (this.today > this.checkDay10EveryMonth(this.ngayTaoXnsl)) {
      // this.canUpdateXnsl = false;
      this.canUpdateXnsl = true;
    }
    if (this.today > this.checkDay10EveryMonth(this.ngayTaoKqXnKhangDinh)) {
      // this.canUpdateKqXnKhangDinh = false;
      this.canUpdateKqXnKhangDinh = true;
    }
    if (this.today > this.checkDay10EveryMonth(this.ngayTaoCgXnKhangDinh)) {
      // this.canUpdateCgXnKhangDinh = false;
      this.canUpdateCgXnKhangDinh = true;
    }
  }
  updateRelatedHistoryPrep() {
    this.updateRelateHistoryPrep = false;
    this.isCreateNewPrep = false;
    this.customerService
      .getDetailUsersSendKPElog(this.customerId)
      .then((res: any) => {
        this.duTCPrep =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.duTCPrep;
        this.dongYPrepSauTuVan =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.dongYPrepSauTuVan;
        this.cgPrep =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.cgPrep;
        this.lyDoKhongPrep =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.lyDoKhongPrep;
        this.coSoCgDieuTriPrep = res?.ttDichVu
          ?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
        ]?.coSoCgDieuTriPrep?.value
          ? res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
              res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
            ]?.coSoCgDieuTriPrep?.value
          : "";
        this.coSoCgDieuTriPrepKhac =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.coSoCgDieuTriPrepKhac?.khac;
        this.ngayCgDieuTriPrep = this.formatGetDate(
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ngayCgDieuTriPrep
        );
        this.dieuTriPrep =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.dieuTriPrep;
        this.ngayBDPrep = this.formatGetDate(
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ngayBDPrep
        );
        this.donViPrep = res?.ttDichVu
          ?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
        ]?.donViPrep?.value
          ? res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
              res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
            ]?.donViPrep?.value
          : "";
        this.donViPrepKhac =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.donViPrepKhac?.khac;
        this.maSoDieuTriPrep =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.maSoDieuTriPrep;
        this.ttPrep1 =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ttPrep1?.value;
        this.soLieuDaQuenPrep1 =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ttPrep1?.soLieuDaQuen;
        this.soNgayNgungPrep1 =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ttPrep1?.soNgayNgung;
        this.lyDoQuenPrep1 =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ttPrep1?.lyDoQuen;
        this.khacPrep1 =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ttPrep1?.khac;
        this.soLieuDaQuenPrep2 =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ttPrep2?.soLieuDaQuenPrep2;
        this.soNgayNgungPrep2 =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ttPrep2?.soNgayNgung;
        this.lyDoQuenPrep2 =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ttPrep2?.lyDoQuen;
        this.khacPrep2 =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ttPrep2?.khac;
        this.soLieuDaQuenPrep3 =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ttPrep3?.soLieuDaQuen;
        this.soNgayNgungPrep3 =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ttPrep3?.soNgayNgung;
        this.lyDoQuenPrep3 =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ttPrep3?.lyDoQuen;
        this.khacPrep3 =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ttPrep3?.khac;
        this.ttPrep2 =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ttPrep2?.value;
        this.ttPrep3 =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ttPrep3?.value;
        this.ttPrep =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.ttPrep;
        this.valueUploadFilePrep =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length - 1
          ]?.phieuDongYPrep;
      })
      .catch();
    if (this.today > this.checkDay10EveryMonth(this.ngayTaoTinhTrangPrep)) {
      // this.canUpdateTinhTrangPrep = false;
      this.canUpdateTinhTrangPrep = true;
    }
    if (this.today > this.checkDay10EveryMonth(this.ngayTaoKqDieuTriPrep)) {
      // this.canUpdateKqDieuTriPrep = false;
      this.canUpdateKqDieuTriPrep = true;
    }
    if (this.today > this.checkDay10EveryMonth(this.ngayTaoPrep)) {
      // this.canUpdatePrep = false;
      this.canUpdatePrep = true;
    }
    if (this.today > this.checkDay10EveryMonth(this.ngayTaoTuanThuPrep)) {
      // this.canUpdateTuanThuPrep = false;
      this.canUpdateTuanThuPrep = true;
    }
  }
  updateRelatedHistoryTreatmentArv() {
    this.updateRelateHistoryTreatmentArv = false;
    this.isCreateTreatmentArv = false;
    this.customerService
      .getDetailUsersSendKPElog(this.customerId)
      .then((res: any) => {
        this.chuyenGuiARV =
          res?.ttDichVu?.hoTroDangKiDieuTriARV[
            res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
          ]?.chuyenGuiARV;
        this.liDoKHTuChoi =
          res?.ttDichVu?.hoTroDangKiDieuTriARV[
            res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
          ]?.liDoKHTuChoi;
        this.coSoCgDieuTriARV = res?.ttDichVu?.hoTroDangKiDieuTriARV[
          res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
        ]?.coSoCgDieuTriARV?.value
          ? res?.ttDichVu?.hoTroDangKiDieuTriARV[
              res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
            ]?.coSoCgDieuTriARV?.value
          : "";
        this.coSoCgDieuTriARVKhac =
          res?.ttDichVu?.hoTroDangKiDieuTriARV[
            res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
          ]?.coSoCgDieuTriARV?.khac;
        this.ngayCgDieuTriARV = this.formatGetDate(
          res?.ttDichVu?.hoTroDangKiDieuTriARV[
            res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
          ]?.ngayCgDieuTriARV
        );
        this.dieuTriARV =
          res?.ttDichVu?.hoTroDangKiDieuTriARV[
            res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
          ]?.dieuTriARV;
        this.ngayBDDieuTri = this.formatGetDate(
          res?.ttDichVu?.hoTroDangKiDieuTriARV[
            res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
          ]?.ngayBDDieuTri
        );
        this.donViDieuTriHIV = res?.ttDichVu?.hoTroDangKiDieuTriARV[
          res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
        ]?.donViDieuTriHIV?.value
          ? res?.ttDichVu?.hoTroDangKiDieuTriARV[
              res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
            ]?.donViDieuTriHIV?.value
          : "";
        this.donViDieuTriHIVKhac =
          res?.ttDichVu?.hoTroDangKiDieuTriARV[
            res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
          ]?.donViDieuTriHIV?.khac;
        this.maSoDieuTriHIV =
          res?.ttDichVu?.hoTroDangKiDieuTriARV[
            res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
          ]?.maSoDieuTriHIV;
        this.dieuTriArvQuaBHYT =
          res?.ttDichVu?.hoTroDangKiDieuTriARV[
            res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
          ]?.dieuTriArvQuaBHYT;
        this.ttDieuTriARV =
          res?.ttDichVu?.hoTroDangKiDieuTriARV[
            res?.ttDichVu?.hoTroDangKiDieuTriARV.length - 1
          ]?.ttDieuTriARV;
      })
      .catch();
    if (this.today > this.checkDay10EveryMonth(this.ngayTaoTinhTrangArv)) {
      // this.canUpdateTinhTrangArv = false;
      this.canUpdateTinhTrangArv = true;
    }
    if (this.today > this.checkDay10EveryMonth(this.ngayTaoKqArv)) {
      // this.canUpdateKqArv = false;
      this.canUpdateKqArv = true;
    }
    if (this.today > this.checkDay10EveryMonth(this.ngayTaoDieuTriArv)) {
      // this.canUpdateDieuTriArv = false;
      this.canUpdateDieuTriArv = true;
    }
  }
  updateRelatedHistoryPep() {
    this.updateRelateHistoryPep = false;
    this.isCreateNewPep = false;
    this.customerService
      .getDetailUsersSendKPElog(this.customerId)
      .then((res: any) => {
        this.cgPEP =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP.length - 1
          ]?.cgPEP;
        this.dongYPepSauTuVan =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP.length - 1
          ]?.dongYPepSauTuVan;
        this.coSoCgDieuTriPEP = res?.ttDichVu
          ?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP[
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP.length - 1
        ]?.coSoCgDieuTriPEP?.value
          ? res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP[
              res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP.length - 1
            ]?.coSoCgDieuTriPEP?.value
          : "";
        this.coSoCgDieuTriPEPKhac =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP.length - 1
          ]?.coSoCgDieuTriPEP?.khac;
        this.lyDoKhongPEP =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP.length - 1
          ]?.lyDoKhongPEP;
        this.ngayBatDatnPEP = this.formatGetDate(
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP.length - 1
          ]?.ngayBatDatnPEP
        );
        this.ngayCgDieuTriPep = this.formatGetDate(
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP.length - 1
          ]?.ngayCgDieuTriPep
        );
        this.coSonPEP =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP.length - 1
          ]?.coSonPEP?.value?.name;
        this.coSonPEPKhac =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP.length - 1
          ]?.coSonPEP?.khac;
        this.dieuTriPEP =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP.length - 1
          ]?.dieuTriPEP;
        this.maDieuTrinPEP =
          res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP[
            res?.ttDichVu?.tuVanVaHoTroDuPhongHIVSauPhoiNhiemPEP.length - 1
          ]?.maDieuTrinPEP;
      })
      .catch();
    if (this.today > this.checkDay10EveryMonth(this.ngayTaoKqPep)) {
      // this.canUpdateKqPep = false;
      this.canUpdateKqPep = true;
    }
    if (this.today > this.checkDay10EveryMonth(this.ngayTaoPep)) {
      // this.canUpdatePep = false;
      this.canUpdatePep = true;
    }
  }
  updateRelatedHistoryComplianceArv() {
    this.updateRelateHistoryComplianceArv = false;
    this.isCreateComplianceArv = false;
    this.customerService
      .getDetailUsersSendKPElog(this.customerId)
      .then((res: any) => {
        this.ttDieuTriHIV1 =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ttDieuTriHIV1?.value;
        this.ttDieuTriHIV2 =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ttDieuTriHIV2?.value;
        this.ttDieuTriHIV3 =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ttDieuTriHIV3?.value;
        this.soLieuDaQuenHIV1 =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ttDieuTriHIV1?.soLieuDaQuen;
        this.soNgayNgungHIV1 =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ttDieuTriHIV1?.soNgayNgung;
        this.lyDoQuenHIV1 =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ttDieuTriHIV1?.lyDoQuen;
        this.khacHIV1 =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ttDieuTriHIV1?.khac;
        this.soLieuDaQuenHIV2 =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ttDieuTriHIV2?.soLieuDaQuen;
        this.soNgayNgungHIV2 =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ttDieuTriHIV2?.soNgayNgung;
        this.lyDoQuenHIV2 =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ttDieuTriHIV2?.lyDoQuen;
        this.khacHIV2 =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ttDieuTriHIV2?.khac;
        this.soLieuDaQuenHIV3 =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ttDieuTriHIV3?.soLieuDaQuen;
        this.soNgayNgungHIV3 =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ttDieuTriHIV3?.soNgayNgung;
        this.lyDoQuenHIV3 =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ttDieuTriHIV3?.lyDoQuen;
        this.khacHIV3 =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ttDieuTriHIV3?.khac;
        this.kqXNTLVR =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.kqXNTLVR;
        this.ngayXNTLVR = this.formatGetDate(
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ngayXNTLVR
        );
        this.ketQuaCD4 =
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ketQuaCD4;
        this.ngayXNCD4 = this.formatGetDate(
          res?.ttDichVu?.hoTroTuanThuDieuTriARV[
            res?.ttDichVu?.hoTroTuanThuDieuTriARV.length - 1
          ]?.ngayXNCD4
        );
      })
      .catch();
    if (this.today > this.checkDay10EveryMonth(this.ngayTaoKqTlvr)) {
      // this.canUpdateKqTlvr = false;
      this.canUpdateKqTlvr = true;
    }
    if (this.today > this.checkDay10EveryMonth(this.ngayTaoKqCD4)) {
      // this.canUpdateKqCD4 = false;
      this.canUpdateKqCD4 = true;
    }
    if (this.today > this.checkDay10EveryMonth(this.ngayTaoTuanThuArv)) {
      // this.canUpdateTuanThuArv = false;
      this.canUpdateTuanThuArv = true;
    }
  }
  updateRelatedHistoryViolence() {
    this.updateRelateHistoryViolence = false;
    this.isCreateNewViolence = false;
    this.customerService
      .getDetailUsersSendKPElog(this.customerId)
      .then((res: any) => {
        this.tuVanBTBC =
          res?.ttDichVu?.tuVanVaHoTroUngPhoNguyCoBaoHanh[
            res?.ttDichVu?.tuVanVaHoTroUngPhoNguyCoBaoHanh.length - 1
          ]?.tuVanBTBC;
        this.khachDongYKhaiBaoBTBC =
          res?.ttDichVu?.tuVanVaHoTroUngPhoNguyCoBaoHanh[
            res?.ttDichVu?.tuVanVaHoTroUngPhoNguyCoBaoHanh.length - 1
          ]?.khachDongYKhaiBaoBTBC;
        this.ngayKhachDongYKhaiBaoBTBC = this.formatGetDate(
          res?.ttDichVu?.tuVanVaHoTroUngPhoNguyCoBaoHanh[
            res?.ttDichVu?.tuVanVaHoTroUngPhoNguyCoBaoHanh.length - 1
          ]?.ngayKhachDongYKhaiBaoBTBC
        );
        this.soBTBCKhaiBao =
          res?.ttDichVu?.tuVanVaHoTroUngPhoNguyCoBaoHanh[
            res?.ttDichVu?.tuVanVaHoTroUngPhoNguyCoBaoHanh.length - 1
          ]?.soBTBCKhaiBao;
        this.soBanTinhBanChichThuongXuyen =
          res?.ttDichVu?.tuVanVaHoTroUngPhoNguyCoBaoHanh[
            res?.ttDichVu?.tuVanVaHoTroUngPhoNguyCoBaoHanh.length - 1
          ]?.soBanTinhBanChichThuongXuyen;
        this.soBanTinhBanChichBatChot =
          res?.ttDichVu?.tuVanVaHoTroUngPhoNguyCoBaoHanh[
            res?.ttDichVu?.tuVanVaHoTroUngPhoNguyCoBaoHanh.length - 1
          ]?.soBanTinhBanChichBatChot;
      })
      .catch();
  }
  updateRelatedHistorySexHealth() {
    this.updateRelateHistorySexHealth = false;
    this.isCreateNewSexHealth = false;
    this.customerService
      .getDetailUsersSendKPElog(this.customerId)
      .then((res: any) => {
        this.tuVanSKTD =
          res?.ttDichVu?.tuVanSucKhoeTinhDucVaSucKhoeSinhSan[
            res?.ttDichVu?.tuVanSucKhoeTinhDucVaSucKhoeSinhSan.length - 1
          ]?.tuVanSKTD;
        this.ngayTuVanSKTD = this.formatGetDate(
          res?.ttDichVu?.tuVanSucKhoeTinhDucVaSucKhoeSinhSan[
            res?.ttDichVu?.tuVanSucKhoeTinhDucVaSucKhoeSinhSan.length - 1
          ]?.ngayTuVanSKTD
        );
      })
      .catch();
  }
  updateRelatedHistoryChangeHabit() {
    this.updateRelateHistoryChangeHabit = false;
    this.isCreateNewChangeHabit = false;
    this.customerService
      .getDetailUsersSendKPElog(this.customerId)
      .then((res: any) => {
        this.tuVanThayDoiHanhVi =
          res?.ttDichVu?.tuVanThayDoiHanhVi[
            res?.ttDichVu?.tuVanThayDoiHanhVi.length - 1
          ]?.tuVanThayDoiHanhVi;
        this.ngayTuVanThayDoiHanhVi = this.formatGetDate(
          res?.ttDichVu?.tuVanThayDoiHanhVi[
            res?.ttDichVu?.tuVanThayDoiHanhVi.length - 1
          ]?.ngayTuVanThayDoiHanhVi
        );
      })
      .catch();
  }
  updateRelatedHistoryReduceMethod() {
    this.updateRelateHistoryReduceMethod = false;
    this.isCreateNewMethodReduce = false;
    this.customerService
      .getDetailUsersSendKPElog(this.customerId)
      .then((res: any) => {
        this.tuVanHanhViCoHaiChoSK =
          res?.ttDichVu?.tuVanCanBienPhapGiamHai[
            res?.ttDichVu?.tuVanCanBienPhapGiamHai.length - 1
          ]?.tuVanHanhViCoHaiChoSK;
        this.ngayTuVanHanhViCoHaiChoSK = this.formatGetDate(
          res?.ttDichVu?.tuVanCanBienPhapGiamHai[
            res?.ttDichVu?.tuVanCanBienPhapGiamHai.length - 1
          ]?.ngayTuVanHanhViCoHaiChoSK
        );
      })
      .catch();
  }
  updateRelatedHistoryShareHiv() {
    this.updateRelateHistoryShareHiv = false;
    this.isCreateNewShareHIV = false;
    this.customerService
      .getDetailUsersSendKPElog(this.customerId)
      .then((res: any) => {
        this.tuVanChiaSeTTHIV =
          res?.ttDichVu?.tuVanChiaSeTinhTrangHIV[
            res?.ttDichVu?.tuVanChiaSeTinhTrangHIV.length - 1
          ]?.tuVanChiaSeTTHIV;
        this.ngayTuVanChiaSeTTHIV = this.formatGetDate(
          res?.ttDichVu?.tuVanChiaSeTinhTrangHIV[
            res?.ttDichVu?.tuVanChiaSeTinhTrangHIV.length - 1
          ]?.ngayTuVanChiaSeTTHIV
        );
      })
      .catch();
  }
  updateRelatedHistorySexIllness() {
    this.updateRelateHistorySexIllness = false;
    this.isCreateNewSexIllness = false;
    this.customerService
      .getDetailUsersSendKPElog(this.customerId)
      .then((res: any) => {
        this.kqXNGiangMai =
          res?.ttDichVu?.tuVanVaPhatHienSomCacBenhLayQuaDuongTinhDuc[
            res?.ttDichVu?.tuVanVaPhatHienSomCacBenhLayQuaDuongTinhDuc.length -
              1
          ]?.kqXNGiangMai;
        this.ngayXNGiangMai = this.formatGetDate(
          res?.ttDichVu?.tuVanVaPhatHienSomCacBenhLayQuaDuongTinhDuc[
            res?.ttDichVu?.tuVanVaPhatHienSomCacBenhLayQuaDuongTinhDuc.length -
              1
          ]?.ngayXNGiangMai
        );
      })
      .catch();
  }
  updateRelatedHistoryOtherMethod() {
    this.updateRelateHistoryOtherMethod = false;
    this.isCreateNewOtherMethod = false;
    this.customerService
      .getDetailUsersSendKPElog(this.customerId)
      .then((res: any) => {
        this.cgDichVuKhac =
          res?.ttDichVu?.chuyenGuiCacDichVuDuPhongKhac[
            res?.ttDichVu?.chuyenGuiCacDichVuDuPhongKhac.length - 1
          ]?.cgDichVuKhac;
        this.cgKhac =
          res?.ttDichVu?.chuyenGuiCacDichVuDuPhongKhac[
            res?.ttDichVu?.chuyenGuiCacDichVuDuPhongKhac.length - 1
          ]?.cgKhac;
      })
      .catch();
  }
  async onPasteNgayXN() {
    const datePaste = await navigator.clipboard.readText();
    const datePasteFormated = moment(datePaste, "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );
    const checkDate =
      moment(datePaste, "DD-MM-YYYY").isValid() ||
      moment(datePaste, "DD/MM/YYYY").isValid() ||
      moment(datePaste, "D-M-YYYY").isValid() ||
      moment(datePaste, "D/M/YYYY").isValid() ||
      moment(datePaste, "D-M-YY").isValid() ||
      moment(datePaste, "D/M/YY").isValid();
    if (checkDate) {
      this.ngayXNSangLocTaiCD = datePasteFormated;
    }
  }
  async onPasteNgayNhanSP() {
    const datePaste = await navigator.clipboard.readText();
    const datePasteFormated = moment(datePaste, "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );
    const checkDate =
      moment(datePaste, "DD-MM-YYYY").isValid() ||
      moment(datePaste, "DD/MM/YYYY").isValid() ||
      moment(datePaste, "D-M-YYYY").isValid() ||
      moment(datePaste, "D/M/YYYY").isValid() ||
      moment(datePaste, "D-M-YY").isValid() ||
      moment(datePaste, "D/M/YY").isValid();
    if (checkDate) {
      this.ngayNhanSP = datePasteFormated;
    }
  }
  async onPasteNgayCg() {
    const datePaste = await navigator.clipboard.readText();
    const datePasteFormated = moment(datePaste, "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );
    const checkDate =
      moment(datePaste, "DD-MM-YYYY").isValid() ||
      moment(datePaste, "DD/MM/YYYY").isValid() ||
      moment(datePaste, "D-M-YYYY").isValid() ||
      moment(datePaste, "D/M/YYYY").isValid() ||
      moment(datePaste, "D-M-YY").isValid() ||
      moment(datePaste, "D/M/YY").isValid();
    if (checkDate) {
      this.ngayChuyenGui = datePasteFormated;
    }
  }
  async onPasteNgayCBOBietKQ() {
    const datePaste = await navigator.clipboard.readText();
    const datePasteFormated = moment(datePaste, "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );
    const checkDate =
      moment(datePaste, "DD-MM-YYYY").isValid() ||
      moment(datePaste, "DD/MM/YYYY").isValid() ||
      moment(datePaste, "D-M-YYYY").isValid() ||
      moment(datePaste, "D/M/YYYY").isValid() ||
      moment(datePaste, "D-M-YY").isValid() ||
      moment(datePaste, "D/M/YY").isValid();
    if (checkDate) {
      this.ngayCBOCoKQ = datePasteFormated;
    }
  }
  async onPasteNgayTraKQXNKD() {
    const datePaste = await navigator.clipboard.readText();
    const datePasteFormated = moment(datePaste, "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );
    const checkDate =
      moment(datePaste, "DD-MM-YYYY").isValid() ||
      moment(datePaste, "DD/MM/YYYY").isValid() ||
      moment(datePaste, "D-M-YYYY").isValid() ||
      moment(datePaste, "D/M/YYYY").isValid() ||
      moment(datePaste, "D-M-YY").isValid() ||
      moment(datePaste, "D/M/YY").isValid();
    if (checkDate) {
      this.ngayTraKQXNKD = datePasteFormated;
    }
  }
  async onPasteNgayCgDieuTriPrep() {
    const datePaste = await navigator.clipboard.readText();
    const datePasteFormated = moment(datePaste, "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );
    const checkDate =
      moment(datePaste, "DD-MM-YYYY").isValid() ||
      moment(datePaste, "DD/MM/YYYY").isValid() ||
      moment(datePaste, "D-M-YYYY").isValid() ||
      moment(datePaste, "D/M/YYYY").isValid() ||
      moment(datePaste, "D-M-YY").isValid() ||
      moment(datePaste, "D/M/YY").isValid();
    if (checkDate) {
      this.ngayCgDieuTriPrep = datePasteFormated;
    }
  }
  async onPasteNgayDieuTriPrep() {
    const datePaste = await navigator.clipboard.readText();
    const datePasteFormated = moment(datePaste, "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );
    const checkDate =
      moment(datePaste, "DD-MM-YYYY").isValid() ||
      moment(datePaste, "DD/MM/YYYY").isValid() ||
      moment(datePaste, "D-M-YYYY").isValid() ||
      moment(datePaste, "D/M/YYYY").isValid() ||
      moment(datePaste, "D-M-YY").isValid() ||
      moment(datePaste, "D/M/YY").isValid();
    if (checkDate) {
      this.ngayBDPrep = datePasteFormated;
    }
  }
  async onPasteNgayCgDieuTriPep() {
    const datePaste = await navigator.clipboard.readText();
    const datePasteFormated = moment(datePaste, "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );
    const checkDate =
      moment(datePaste, "DD-MM-YYYY").isValid() ||
      moment(datePaste, "DD/MM/YYYY").isValid() ||
      moment(datePaste, "D-M-YYYY").isValid() ||
      moment(datePaste, "D/M/YYYY").isValid() ||
      moment(datePaste, "D-M-YY").isValid() ||
      moment(datePaste, "D/M/YY").isValid();
    if (checkDate) {
      this.ngayCgDieuTriPep = datePasteFormated;
    }
  }
  async onPasteNgayDieuTriPep() {
    const datePaste = await navigator.clipboard.readText();
    const datePasteFormated = moment(datePaste, "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );
    const checkDate =
      moment(datePaste, "DD-MM-YYYY").isValid() ||
      moment(datePaste, "DD/MM/YYYY").isValid() ||
      moment(datePaste, "D-M-YYYY").isValid() ||
      moment(datePaste, "D/M/YYYY").isValid() ||
      moment(datePaste, "D-M-YY").isValid() ||
      moment(datePaste, "D/M/YY").isValid();
    if (checkDate) {
      this.ngayBatDatnPEP = datePasteFormated;
    }
  }
  async onPasteNgayCgDieuTriARV() {
    const datePaste = await navigator.clipboard.readText();
    const datePasteFormated = moment(datePaste, "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );
    const checkDate =
      moment(datePaste, "DD-MM-YYYY").isValid() ||
      moment(datePaste, "DD/MM/YYYY").isValid() ||
      moment(datePaste, "D-M-YYYY").isValid() ||
      moment(datePaste, "D/M/YYYY").isValid() ||
      moment(datePaste, "D-M-YY").isValid() ||
      moment(datePaste, "D/M/YY").isValid();
    if (checkDate) {
      this.ngayCgDieuTriARV = datePasteFormated;
    }
  }
  async onPasteNgayDieuTriHIV() {
    const datePaste = await navigator.clipboard.readText();
    const datePasteFormated = moment(datePaste, "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );
    const checkDate =
      moment(datePaste, "DD-MM-YYYY").isValid() ||
      moment(datePaste, "DD/MM/YYYY").isValid() ||
      moment(datePaste, "D-M-YYYY").isValid() ||
      moment(datePaste, "D/M/YYYY").isValid() ||
      moment(datePaste, "D-M-YY").isValid() ||
      moment(datePaste, "D/M/YY").isValid();
    if (checkDate) {
      this.ngayBDDieuTri = datePasteFormated;
    }
  }
  async onPasteNgayTLVR() {
    const datePaste = await navigator.clipboard.readText();
    const datePasteFormated = moment(datePaste, "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );
    const checkDate =
      moment(datePaste, "DD-MM-YYYY").isValid() ||
      moment(datePaste, "DD/MM/YYYY").isValid() ||
      moment(datePaste, "D-M-YYYY").isValid() ||
      moment(datePaste, "D/M/YYYY").isValid() ||
      moment(datePaste, "D-M-YY").isValid() ||
      moment(datePaste, "D/M/YY").isValid();
    if (checkDate) {
      this.ngayXNTLVR = datePasteFormated;
    }
  }
  async onPasteNgayCD4() {
    const datePaste = await navigator.clipboard.readText();
    const datePasteFormated = moment(datePaste, "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );
    const checkDate =
      moment(datePaste, "DD-MM-YYYY").isValid() ||
      moment(datePaste, "DD/MM/YYYY").isValid() ||
      moment(datePaste, "D-M-YYYY").isValid() ||
      moment(datePaste, "D/M/YYYY").isValid() ||
      moment(datePaste, "D-M-YY").isValid() ||
      moment(datePaste, "D/M/YY").isValid();
    if (checkDate) {
      this.ngayXNCD4 = datePasteFormated;
    }
  }
  async onPasteNgayKhaiBaoBTBC() {
    const datePaste = await navigator.clipboard.readText();
    const datePasteFormated = moment(datePaste, "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );
    const checkDate =
      moment(datePaste, "DD-MM-YYYY").isValid() ||
      moment(datePaste, "DD/MM/YYYY").isValid() ||
      moment(datePaste, "D-M-YYYY").isValid() ||
      moment(datePaste, "D/M/YYYY").isValid() ||
      moment(datePaste, "D-M-YY").isValid() ||
      moment(datePaste, "D/M/YY").isValid();
    if (checkDate) {
      this.ngayKhachDongYKhaiBaoBTBC = datePasteFormated;
    }
  }
  async onPasteNgayCCDichVu() {
    const datePaste = await navigator.clipboard.readText();
    const datePasteFormated = moment(datePaste, "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );
    const checkDate =
      moment(datePaste, "DD-MM-YYYY").isValid() ||
      moment(datePaste, "DD/MM/YYYY").isValid() ||
      moment(datePaste, "D-M-YYYY").isValid() ||
      moment(datePaste, "D/M/YYYY").isValid() ||
      moment(datePaste, "D-M-YY").isValid() ||
      moment(datePaste, "D/M/YY").isValid();
    if (checkDate) {
      this.ngayCungCapDV = datePasteFormated;
    }
  }
  async onPasteNgayTuVanSKTD() {
    const datePaste = await navigator.clipboard.readText();
    const datePasteFormated = moment(datePaste, "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );
    const checkDate =
      moment(datePaste, "DD-MM-YYYY").isValid() ||
      moment(datePaste, "DD/MM/YYYY").isValid() ||
      moment(datePaste, "D-M-YYYY").isValid() ||
      moment(datePaste, "D/M/YYYY").isValid() ||
      moment(datePaste, "D-M-YY").isValid() ||
      moment(datePaste, "D/M/YY").isValid();
    if (checkDate) {
      this.ngayTuVanSKTD = datePasteFormated;
    }
  }

  async onPasteNgayThayDoiHvi() {
    const datePaste = await navigator.clipboard.readText();
    const datePasteFormated = moment(datePaste, "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );
    const checkDate =
      moment(datePaste, "DD-MM-YYYY").isValid() ||
      moment(datePaste, "DD/MM/YYYY").isValid() ||
      moment(datePaste, "D-M-YYYY").isValid() ||
      moment(datePaste, "D/M/YYYY").isValid() ||
      moment(datePaste, "D-M-YY").isValid() ||
      moment(datePaste, "D/M/YY").isValid();
    if (checkDate) {
      this.ngayTuVanThayDoiHanhVi = datePasteFormated;
    }
  }
  async onPasteNgayBPGiamHai() {
    const datePaste = await navigator.clipboard.readText();
    const datePasteFormated = moment(datePaste, "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );
    const checkDate =
      moment(datePaste, "DD-MM-YYYY").isValid() ||
      moment(datePaste, "DD/MM/YYYY").isValid() ||
      moment(datePaste, "D-M-YYYY").isValid() ||
      moment(datePaste, "D/M/YYYY").isValid() ||
      moment(datePaste, "D-M-YY").isValid() ||
      moment(datePaste, "D/M/YY").isValid();
    if (checkDate) {
      this.ngayTuVanHanhViCoHaiChoSK = datePasteFormated;
    }
  }
  async onPasteNgayChiaSe() {
    const datePaste = await navigator.clipboard.readText();
    const datePasteFormated = moment(datePaste, "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );
    const checkDate =
      moment(datePaste, "DD-MM-YYYY").isValid() ||
      moment(datePaste, "DD/MM/YYYY").isValid() ||
      moment(datePaste, "D-M-YYYY").isValid() ||
      moment(datePaste, "D/M/YYYY").isValid() ||
      moment(datePaste, "D-M-YY").isValid() ||
      moment(datePaste, "D/M/YY").isValid();
    if (checkDate) {
      this.ngayTuVanChiaSeTTHIV = datePasteFormated;
    }
  }
  async onPasteNgayXNGiangMai() {
    const datePaste = await navigator.clipboard.readText();
    const datePasteFormated = moment(datePaste, "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );
    const checkDate =
      moment(datePaste, "DD-MM-YYYY").isValid() ||
      moment(datePaste, "DD/MM/YYYY").isValid() ||
      moment(datePaste, "D-M-YYYY").isValid() ||
      moment(datePaste, "D/M/YYYY").isValid() ||
      moment(datePaste, "D-M-YY").isValid() ||
      moment(datePaste, "D/M/YY").isValid();
    if (checkDate) {
      this.ngayXNGiangMai = datePasteFormated;
    }
  }
  handleNgayCg(e) {
    this.ngayChuyenGui = e.target.value;
    this.ngayCgAfterToday =
      moment(this.ngayChuyenGui).startOf("d") > moment(this.today).startOf("d");
    if (this.cgXetNghiemKhangDinh === "Có") {
      this.ngayXNAfterNgayCg =
        moment(this.ngayXNSangLocTaiCD).startOf("d") >
        moment(this.ngayChuyenGui).startOf("d");
    }
  }
  async handleUploadFile(e) {
    const fileFormData = e.target.files[0];
    const idxDot = fileFormData.name.lastIndexOf(".") + 1;
    const extFile = fileFormData.name
      .substr(idxDot, fileFormData.name.length)
      .toLowerCase();
    if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
      if (fileFormData.size > 3145728) {
        const alertConfirm = this.atrCtrl.create({
          cssClass: "my-custom-class",
          message: "Dung lượng tập tin không được quá 3Mb!",
        });
        (await alertConfirm).dismiss();

        (await alertConfirm).present();
      } else {
        this.customerService.uploadFile(fileFormData).then((res: any) => {
          this.valueUploadFile = res;
        });
      }
    } else {
      const alertConfirm = this.atrCtrl.create({
        cssClass: "my-custom-class",
        message: "Tệp phải là jpg/jpeg/png!",
      });
      (await alertConfirm).dismiss();

      (await alertConfirm).present();
    }
  }
  async handleUploadFilePrep(e) {
    const fileFormData = e.target.files[0];
    const idxDot = fileFormData.name.lastIndexOf(".") + 1;
    const extFile = fileFormData.name
      .substr(idxDot, fileFormData.name.length)
      .toLowerCase();
    if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
      if (fileFormData.size > 3145728) {
        const alertConfirm = this.atrCtrl.create({
          cssClass: "my-custom-class",
          message: "Dung lượng tập tin không được quá 3Mb!",
        });
        (await alertConfirm).dismiss();

        (await alertConfirm).present();
      } else {
        this.customerService.uploadFile(fileFormData).then((res: any) => {
          this.valueUploadFilePrep = res;
        });
      }
    } else {
      const alertConfirm = this.atrCtrl.create({
        cssClass: "my-custom-class",
        message: "Tệp phải là jpg/jpeg/png!",
      });
      (await alertConfirm).dismiss();

      (await alertConfirm).present();
    }
  }
}

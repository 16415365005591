import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";
import * as moment from "moment/moment";
import {CustomerService} from "../../../services/customer.service";

@Component({
  selector: 'app-history-compliance-arv',
  templateUrl: './history-compliance-arv.component.html',
  styleUrls: ['./history-compliance-arv.component.scss'],
})
export class HistoryComplianceArvComponent  {

  name: string;
  isShow  = [100];
  isShow1 = false;
  isShow2 = false;
  isShow3 = false;
  customerId;
  historyComplianceARVArr = [];

  ngayXNCD4 = null;
  ngayXNTLVR = null;
  constructor(private modalCtrl: ModalController,
              private customerService: CustomerService,) {}
  fakeData = ['a','b', 'c']
  ngOnInit() {

    this.customerService.getDetailUsersSendKPElog(this.customerId).then((res:any) =>{
      this.ngayXNCD4 = moment(res.ttDichVu.hoTroTuanThuDieuTriARV.ngayXNCD4).format('DD/MM/YYYY')
      this.ngayXNTLVR = moment(res.ttDichVu.hoTroTuanThuDieuTriARV.ngayXNTLVR).format('DD/MM/YYYY')
      this.historyComplianceARVArr = res.ttDichVu.hoTroTuanThuDieuTriARV;
    })
  }
  formatDate(date){
    return moment(date).format('DD-MM-YYYY')
  }
  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  confirm() {
    return this.modalCtrl.dismiss(this.name, 'confirm');
  }
  showHistory(i){
    if(i === 0){
      this.isShow.push(1);
    }
    else if (i === 1){
      this.isShow.push(2);

    }
    else{
      this.isShow.push(3);
    }

  }

}

import * as types from '../types';

const initialState = {
    form: {},
    survey_list: null,
    survey_batchs: null,
    stt: []
};

export default function surveyReducer(state = initialState, action) {
    switch (action.type) {
        case types.SURVEY_SAVE_DATA:
            return {
                ...state,
                form: action.payload
            };
        case types.SURVEY_GET_LIST:
            return {
                ...state,
                survey_list: action.payload
                // survey_list: mockQuestions
            };
        case types.SURVEY_SET_BATCH:
            return {
                ...state,
                survey_batchs: action.payload
            };
        case types.SURVEY_SET_BATCH:
            return {
                ...state,
                survey_batchs: action.payload
            };
        case types.SURVEY_UPDATE_STT: {
            // console.log("SURVEY_UPDATE_STT", action.payload);
            
            return {
                ...state,
                stt: action.payload
            };
        }
        case types.SURVEY_UPDATE_HAVE_CHANGE:
            return {
                ...state,
                stt: action.payload
            };
        default:
            return state;
    }
}

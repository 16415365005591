import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss'],
})
export class UploadImageComponent implements OnInit {

  constructor() { }

  @Input() value = '';
  @Input() placeholder = '';
  @Input() name = '';
  @Input() disabled = false;
  @Input() disabledPrep = false;

  ngOnInit() {
  }

}

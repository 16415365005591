export default [
    {
        "name": "Thành phố Hà Nội",
        "code": 1,
        "codename": "thanh_pho_ha_noi",
        "division_type": "thành phố trung ương",
        "phone_code": 24,
        "districts": [
            {
                "name": "Quận Ba Đình",
                "code": 1,
                "codename": "quan_ba_dinh",
                "division_type": "quận",
                "short_codename": "ba_dinh",
                "wards": [
                    {
                        "name": "Phường Phúc Xá",
                        "code": 1,
                        "codename": "phuong_phuc_xa",
                        "division_type": "phường",
                        "short_codename": "phuc_xa"
                    },
                    {
                        "name": "Phường Trúc Bạch",
                        "code": 4,
                        "codename": "phuong_truc_bach",
                        "division_type": "phường",
                        "short_codename": "truc_bach"
                    },
                    {
                        "name": "Phường Vĩnh Phúc",
                        "code": 6,
                        "codename": "phuong_vinh_phuc",
                        "division_type": "phường",
                        "short_codename": "vinh_phuc"
                    },
                    {
                        "name": "Phường Cống Vị",
                        "code": 7,
                        "codename": "phuong_cong_vi",
                        "division_type": "phường",
                        "short_codename": "cong_vi"
                    },
                    {
                        "name": "Phường Liễu Giai",
                        "code": 8,
                        "codename": "phuong_lieu_giai",
                        "division_type": "phường",
                        "short_codename": "lieu_giai"
                    },
                    {
                        "name": "Phường Nguyễn Trung Trực",
                        "code": 10,
                        "codename": "phuong_nguyen_trung_truc",
                        "division_type": "phường",
                        "short_codename": "nguyen_trung_truc"
                    },
                    {
                        "name": "Phường Quán Thánh",
                        "code": 13,
                        "codename": "phuong_quan_thanh",
                        "division_type": "phường",
                        "short_codename": "quan_thanh"
                    },
                    {
                        "name": "Phường Ngọc Hà",
                        "code": 16,
                        "codename": "phuong_ngoc_ha",
                        "division_type": "phường",
                        "short_codename": "ngoc_ha"
                    },
                    {
                        "name": "Phường Điện Biên",
                        "code": 19,
                        "codename": "phuong_dien_bien",
                        "division_type": "phường",
                        "short_codename": "dien_bien"
                    },
                    {
                        "name": "Phường Đội Cấn",
                        "code": 22,
                        "codename": "phuong_doi_can",
                        "division_type": "phường",
                        "short_codename": "doi_can"
                    },
                    {
                        "name": "Phường Ngọc Khánh",
                        "code": 25,
                        "codename": "phuong_ngoc_khanh",
                        "division_type": "phường",
                        "short_codename": "ngoc_khanh"
                    },
                    {
                        "name": "Phường Kim Mã",
                        "code": 28,
                        "codename": "phuong_kim_ma",
                        "division_type": "phường",
                        "short_codename": "kim_ma"
                    },
                    {
                        "name": "Phường Giảng Võ",
                        "code": 31,
                        "codename": "phuong_giang_vo",
                        "division_type": "phường",
                        "short_codename": "giang_vo"
                    },
                    {
                        "name": "Phường Thành Công",
                        "code": 34,
                        "codename": "phuong_thanh_cong",
                        "division_type": "phường",
                        "short_codename": "thanh_cong"
                    }
                ]
            },
            {
                "name": "Quận Hoàn Kiếm",
                "code": 2,
                "codename": "quan_hoan_kiem",
                "division_type": "quận",
                "short_codename": "hoan_kiem",
                "wards": [
                    {
                        "name": "Phường Phúc Tân",
                        "code": 37,
                        "codename": "phuong_phuc_tan",
                        "division_type": "phường",
                        "short_codename": "phuc_tan"
                    },
                    {
                        "name": "Phường Đồng Xuân",
                        "code": 40,
                        "codename": "phuong_dong_xuan",
                        "division_type": "phường",
                        "short_codename": "dong_xuan"
                    },
                    {
                        "name": "Phường Hàng Mã",
                        "code": 43,
                        "codename": "phuong_hang_ma",
                        "division_type": "phường",
                        "short_codename": "hang_ma"
                    },
                    {
                        "name": "Phường Hàng Buồm",
                        "code": 46,
                        "codename": "phuong_hang_buom",
                        "division_type": "phường",
                        "short_codename": "hang_buom"
                    },
                    {
                        "name": "Phường Hàng Đào",
                        "code": 49,
                        "codename": "phuong_hang_dao",
                        "division_type": "phường",
                        "short_codename": "hang_dao"
                    },
                    {
                        "name": "Phường Hàng Bồ",
                        "code": 52,
                        "codename": "phuong_hang_bo",
                        "division_type": "phường",
                        "short_codename": "hang_bo"
                    },
                    {
                        "name": "Phường Cửa Đông",
                        "code": 55,
                        "codename": "phuong_cua_dong",
                        "division_type": "phường",
                        "short_codename": "cua_dong"
                    },
                    {
                        "name": "Phường Lý Thái Tổ",
                        "code": 58,
                        "codename": "phuong_ly_thai_to",
                        "division_type": "phường",
                        "short_codename": "ly_thai_to"
                    },
                    {
                        "name": "Phường Hàng Bạc",
                        "code": 61,
                        "codename": "phuong_hang_bac",
                        "division_type": "phường",
                        "short_codename": "hang_bac"
                    },
                    {
                        "name": "Phường Hàng Gai",
                        "code": 64,
                        "codename": "phuong_hang_gai",
                        "division_type": "phường",
                        "short_codename": "hang_gai"
                    },
                    {
                        "name": "Phường Chương Dương",
                        "code": 67,
                        "codename": "phuong_chuong_duong",
                        "division_type": "phường",
                        "short_codename": "chuong_duong"
                    },
                    {
                        "name": "Phường Hàng Trống",
                        "code": 70,
                        "codename": "phuong_hang_trong",
                        "division_type": "phường",
                        "short_codename": "hang_trong"
                    },
                    {
                        "name": "Phường Cửa Nam",
                        "code": 73,
                        "codename": "phuong_cua_nam",
                        "division_type": "phường",
                        "short_codename": "cua_nam"
                    },
                    {
                        "name": "Phường Hàng Bông",
                        "code": 76,
                        "codename": "phuong_hang_bong",
                        "division_type": "phường",
                        "short_codename": "hang_bong"
                    },
                    {
                        "name": "Phường Tràng Tiền",
                        "code": 79,
                        "codename": "phuong_trang_tien",
                        "division_type": "phường",
                        "short_codename": "trang_tien"
                    },
                    {
                        "name": "Phường Trần Hưng Đạo",
                        "code": 82,
                        "codename": "phuong_tran_hung_dao",
                        "division_type": "phường",
                        "short_codename": "tran_hung_dao"
                    },
                    {
                        "name": "Phường Phan Chu Trinh",
                        "code": 85,
                        "codename": "phuong_phan_chu_trinh",
                        "division_type": "phường",
                        "short_codename": "phan_chu_trinh"
                    },
                    {
                        "name": "Phường Hàng Bài",
                        "code": 88,
                        "codename": "phuong_hang_bai",
                        "division_type": "phường",
                        "short_codename": "hang_bai"
                    }
                ]
            },
            {
                "name": "Quận Tây Hồ",
                "code": 3,
                "codename": "quan_tay_ho",
                "division_type": "quận",
                "short_codename": "tay_ho",
                "wards": [
                    {
                        "name": "Phường Phú Thượng",
                        "code": 91,
                        "codename": "phuong_phu_thuong",
                        "division_type": "phường",
                        "short_codename": "phu_thuong"
                    },
                    {
                        "name": "Phường Nhật Tân",
                        "code": 94,
                        "codename": "phuong_nhat_tan",
                        "division_type": "phường",
                        "short_codename": "nhat_tan"
                    },
                    {
                        "name": "Phường Tứ Liên",
                        "code": 97,
                        "codename": "phuong_tu_lien",
                        "division_type": "phường",
                        "short_codename": "tu_lien"
                    },
                    {
                        "name": "Phường Quảng An",
                        "code": 100,
                        "codename": "phuong_quang_an",
                        "division_type": "phường",
                        "short_codename": "quang_an"
                    },
                    {
                        "name": "Phường Xuân La",
                        "code": 103,
                        "codename": "phuong_xuan_la",
                        "division_type": "phường",
                        "short_codename": "xuan_la"
                    },
                    {
                        "name": "Phường Yên Phụ",
                        "code": 106,
                        "codename": "phuong_yen_phu",
                        "division_type": "phường",
                        "short_codename": "yen_phu"
                    },
                    {
                        "name": "Phường Bưởi",
                        "code": 109,
                        "codename": "phuong_buoi",
                        "division_type": "phường",
                        "short_codename": "buoi"
                    },
                    {
                        "name": "Phường Thụy Khuê",
                        "code": 112,
                        "codename": "phuong_thuy_khue",
                        "division_type": "phường",
                        "short_codename": "thuy_khue"
                    }
                ]
            },
            {
                "name": "Quận Long Biên",
                "code": 4,
                "codename": "quan_long_bien",
                "division_type": "quận",
                "short_codename": "long_bien",
                "wards": [
                    {
                        "name": "Phường Thượng Thanh",
                        "code": 115,
                        "codename": "phuong_thuong_thanh",
                        "division_type": "phường",
                        "short_codename": "thuong_thanh"
                    },
                    {
                        "name": "Phường Ngọc Thụy",
                        "code": 118,
                        "codename": "phuong_ngoc_thuy",
                        "division_type": "phường",
                        "short_codename": "ngoc_thuy"
                    },
                    {
                        "name": "Phường Giang Biên",
                        "code": 121,
                        "codename": "phuong_giang_bien",
                        "division_type": "phường",
                        "short_codename": "giang_bien"
                    },
                    {
                        "name": "Phường Đức Giang",
                        "code": 124,
                        "codename": "phuong_duc_giang",
                        "division_type": "phường",
                        "short_codename": "duc_giang"
                    },
                    {
                        "name": "Phường Việt Hưng",
                        "code": 127,
                        "codename": "phuong_viet_hung",
                        "division_type": "phường",
                        "short_codename": "viet_hung"
                    },
                    {
                        "name": "Phường Gia Thụy",
                        "code": 130,
                        "codename": "phuong_gia_thuy",
                        "division_type": "phường",
                        "short_codename": "gia_thuy"
                    },
                    {
                        "name": "Phường Ngọc Lâm",
                        "code": 133,
                        "codename": "phuong_ngoc_lam",
                        "division_type": "phường",
                        "short_codename": "ngoc_lam"
                    },
                    {
                        "name": "Phường Phúc Lợi",
                        "code": 136,
                        "codename": "phuong_phuc_loi",
                        "division_type": "phường",
                        "short_codename": "phuc_loi"
                    },
                    {
                        "name": "Phường Bồ Đề",
                        "code": 139,
                        "codename": "phuong_bo_de",
                        "division_type": "phường",
                        "short_codename": "bo_de"
                    },
                    {
                        "name": "Phường Sài Đồng",
                        "code": 142,
                        "codename": "phuong_sai_dong",
                        "division_type": "phường",
                        "short_codename": "sai_dong"
                    },
                    {
                        "name": "Phường Long Biên",
                        "code": 145,
                        "codename": "phuong_long_bien",
                        "division_type": "phường",
                        "short_codename": "long_bien"
                    },
                    {
                        "name": "Phường Thạch Bàn",
                        "code": 148,
                        "codename": "phuong_thach_ban",
                        "division_type": "phường",
                        "short_codename": "thach_ban"
                    },
                    {
                        "name": "Phường Phúc Đồng",
                        "code": 151,
                        "codename": "phuong_phuc_dong",
                        "division_type": "phường",
                        "short_codename": "phuc_dong"
                    },
                    {
                        "name": "Phường Cự Khối",
                        "code": 154,
                        "codename": "phuong_cu_khoi",
                        "division_type": "phường",
                        "short_codename": "cu_khoi"
                    }
                ]
            },
            {
                "name": "Quận Cầu Giấy",
                "code": 5,
                "codename": "quan_cau_giay",
                "division_type": "quận",
                "short_codename": "cau_giay",
                "wards": [
                    {
                        "name": "Phường Nghĩa Đô",
                        "code": 157,
                        "codename": "phuong_nghia_do",
                        "division_type": "phường",
                        "short_codename": "nghia_do"
                    },
                    {
                        "name": "Phường Nghĩa Tân",
                        "code": 160,
                        "codename": "phuong_nghia_tan",
                        "division_type": "phường",
                        "short_codename": "nghia_tan"
                    },
                    {
                        "name": "Phường Mai Dịch",
                        "code": 163,
                        "codename": "phuong_mai_dich",
                        "division_type": "phường",
                        "short_codename": "mai_dich"
                    },
                    {
                        "name": "Phường Dịch Vọng",
                        "code": 166,
                        "codename": "phuong_dich_vong",
                        "division_type": "phường",
                        "short_codename": "dich_vong"
                    },
                    {
                        "name": "Phường Dịch Vọng Hậu",
                        "code": 167,
                        "codename": "phuong_dich_vong_hau",
                        "division_type": "phường",
                        "short_codename": "dich_vong_hau"
                    },
                    {
                        "name": "Phường Quan Hoa",
                        "code": 169,
                        "codename": "phuong_quan_hoa",
                        "division_type": "phường",
                        "short_codename": "quan_hoa"
                    },
                    {
                        "name": "Phường Yên Hoà",
                        "code": 172,
                        "codename": "phuong_yen_hoa",
                        "division_type": "phường",
                        "short_codename": "yen_hoa"
                    },
                    {
                        "name": "Phường Trung Hoà",
                        "code": 175,
                        "codename": "phuong_trung_hoa",
                        "division_type": "phường",
                        "short_codename": "trung_hoa"
                    }
                ]
            },
            {
                "name": "Quận Đống Đa",
                "code": 6,
                "codename": "quan_dong_da",
                "division_type": "quận",
                "short_codename": "dong_da",
                "wards": [
                    {
                        "name": "Phường Cát Linh",
                        "code": 178,
                        "codename": "phuong_cat_linh",
                        "division_type": "phường",
                        "short_codename": "cat_linh"
                    },
                    {
                        "name": "Phường Văn Miếu",
                        "code": 181,
                        "codename": "phuong_van_mieu",
                        "division_type": "phường",
                        "short_codename": "van_mieu"
                    },
                    {
                        "name": "Phường Quốc Tử Giám",
                        "code": 184,
                        "codename": "phuong_quoc_tu_giam",
                        "division_type": "phường",
                        "short_codename": "quoc_tu_giam"
                    },
                    {
                        "name": "Phường Láng Thượng",
                        "code": 187,
                        "codename": "phuong_lang_thuong",
                        "division_type": "phường",
                        "short_codename": "lang_thuong"
                    },
                    {
                        "name": "Phường Ô Chợ Dừa",
                        "code": 190,
                        "codename": "phuong_o_cho_dua",
                        "division_type": "phường",
                        "short_codename": "o_cho_dua"
                    },
                    {
                        "name": "Phường Văn Chương",
                        "code": 193,
                        "codename": "phuong_van_chuong",
                        "division_type": "phường",
                        "short_codename": "van_chuong"
                    },
                    {
                        "name": "Phường Hàng Bột",
                        "code": 196,
                        "codename": "phuong_hang_bot",
                        "division_type": "phường",
                        "short_codename": "hang_bot"
                    },
                    {
                        "name": "Phường Láng Hạ",
                        "code": 199,
                        "codename": "phuong_lang_ha",
                        "division_type": "phường",
                        "short_codename": "lang_ha"
                    },
                    {
                        "name": "Phường Khâm Thiên",
                        "code": 202,
                        "codename": "phuong_kham_thien",
                        "division_type": "phường",
                        "short_codename": "kham_thien"
                    },
                    {
                        "name": "Phường Thổ Quan",
                        "code": 205,
                        "codename": "phuong_tho_quan",
                        "division_type": "phường",
                        "short_codename": "tho_quan"
                    },
                    {
                        "name": "Phường Nam Đồng",
                        "code": 208,
                        "codename": "phuong_nam_dong",
                        "division_type": "phường",
                        "short_codename": "nam_dong"
                    },
                    {
                        "name": "Phường Trung Phụng",
                        "code": 211,
                        "codename": "phuong_trung_phung",
                        "division_type": "phường",
                        "short_codename": "trung_phung"
                    },
                    {
                        "name": "Phường Quang Trung",
                        "code": 214,
                        "codename": "phuong_quang_trung",
                        "division_type": "phường",
                        "short_codename": "quang_trung"
                    },
                    {
                        "name": "Phường Trung Liệt",
                        "code": 217,
                        "codename": "phuong_trung_liet",
                        "division_type": "phường",
                        "short_codename": "trung_liet"
                    },
                    {
                        "name": "Phường Phương Liên",
                        "code": 220,
                        "codename": "phuong_phuong_lien",
                        "division_type": "phường",
                        "short_codename": "phuong_lien"
                    },
                    {
                        "name": "Phường Thịnh Quang",
                        "code": 223,
                        "codename": "phuong_thinh_quang",
                        "division_type": "phường",
                        "short_codename": "thinh_quang"
                    },
                    {
                        "name": "Phường Trung Tự",
                        "code": 226,
                        "codename": "phuong_trung_tu",
                        "division_type": "phường",
                        "short_codename": "trung_tu"
                    },
                    {
                        "name": "Phường Kim Liên",
                        "code": 229,
                        "codename": "phuong_kim_lien",
                        "division_type": "phường",
                        "short_codename": "kim_lien"
                    },
                    {
                        "name": "Phường Phương Mai",
                        "code": 232,
                        "codename": "phuong_phuong_mai",
                        "division_type": "phường",
                        "short_codename": "phuong_mai"
                    },
                    {
                        "name": "Phường Ngã Tư Sở",
                        "code": 235,
                        "codename": "phuong_nga_tu_so",
                        "division_type": "phường",
                        "short_codename": "nga_tu_so"
                    },
                    {
                        "name": "Phường Khương Thượng",
                        "code": 238,
                        "codename": "phuong_khuong_thuong",
                        "division_type": "phường",
                        "short_codename": "khuong_thuong"
                    }
                ]
            },
            {
                "name": "Quận Hai Bà Trưng",
                "code": 7,
                "codename": "quan_hai_ba_trung",
                "division_type": "quận",
                "short_codename": "hai_ba_trung",
                "wards": [
                    {
                        "name": "Phường Nguyễn Du",
                        "code": 241,
                        "codename": "phuong_nguyen_du",
                        "division_type": "phường",
                        "short_codename": "nguyen_du"
                    },
                    {
                        "name": "Phường Bạch Đằng",
                        "code": 244,
                        "codename": "phuong_bach_dang",
                        "division_type": "phường",
                        "short_codename": "bach_dang"
                    },
                    {
                        "name": "Phường Phạm Đình Hổ",
                        "code": 247,
                        "codename": "phuong_pham_dinh_ho",
                        "division_type": "phường",
                        "short_codename": "pham_dinh_ho"
                    },
                    {
                        "name": "Phường Lê Đại Hành",
                        "code": 256,
                        "codename": "phuong_le_dai_hanh",
                        "division_type": "phường",
                        "short_codename": "le_dai_hanh"
                    },
                    {
                        "name": "Phường Đồng Nhân",
                        "code": 259,
                        "codename": "phuong_dong_nhan",
                        "division_type": "phường",
                        "short_codename": "dong_nhan"
                    },
                    {
                        "name": "Phường Phố Huế",
                        "code": 262,
                        "codename": "phuong_pho_hue",
                        "division_type": "phường",
                        "short_codename": "pho_hue"
                    },
                    {
                        "name": "Phường Đống Mác",
                        "code": 265,
                        "codename": "phuong_dong_mac",
                        "division_type": "phường",
                        "short_codename": "dong_mac"
                    },
                    {
                        "name": "Phường Thanh Lương",
                        "code": 268,
                        "codename": "phuong_thanh_luong",
                        "division_type": "phường",
                        "short_codename": "thanh_luong"
                    },
                    {
                        "name": "Phường Thanh Nhàn",
                        "code": 271,
                        "codename": "phuong_thanh_nhan",
                        "division_type": "phường",
                        "short_codename": "thanh_nhan"
                    },
                    {
                        "name": "Phường Cầu Dền",
                        "code": 274,
                        "codename": "phuong_cau_den",
                        "division_type": "phường",
                        "short_codename": "cau_den"
                    },
                    {
                        "name": "Phường Bách Khoa",
                        "code": 277,
                        "codename": "phuong_bach_khoa",
                        "division_type": "phường",
                        "short_codename": "bach_khoa"
                    },
                    {
                        "name": "Phường Đồng Tâm",
                        "code": 280,
                        "codename": "phuong_dong_tam",
                        "division_type": "phường",
                        "short_codename": "dong_tam"
                    },
                    {
                        "name": "Phường Vĩnh Tuy",
                        "code": 283,
                        "codename": "phuong_vinh_tuy",
                        "division_type": "phường",
                        "short_codename": "vinh_tuy"
                    },
                    {
                        "name": "Phường Bạch Mai",
                        "code": 286,
                        "codename": "phuong_bach_mai",
                        "division_type": "phường",
                        "short_codename": "bach_mai"
                    },
                    {
                        "name": "Phường Quỳnh Mai",
                        "code": 289,
                        "codename": "phuong_quynh_mai",
                        "division_type": "phường",
                        "short_codename": "quynh_mai"
                    },
                    {
                        "name": "Phường Quỳnh Lôi",
                        "code": 292,
                        "codename": "phuong_quynh_loi",
                        "division_type": "phường",
                        "short_codename": "quynh_loi"
                    },
                    {
                        "name": "Phường Minh Khai",
                        "code": 295,
                        "codename": "phuong_minh_khai",
                        "division_type": "phường",
                        "short_codename": "minh_khai"
                    },
                    {
                        "name": "Phường Trương Định",
                        "code": 298,
                        "codename": "phuong_truong_dinh",
                        "division_type": "phường",
                        "short_codename": "truong_dinh"
                    }
                ]
            },
            {
                "name": "Quận Hoàng Mai",
                "code": 8,
                "codename": "quan_hoang_mai",
                "division_type": "quận",
                "short_codename": "hoang_mai",
                "wards": [
                    {
                        "name": "Phường Thanh Trì",
                        "code": 301,
                        "codename": "phuong_thanh_tri",
                        "division_type": "phường",
                        "short_codename": "thanh_tri"
                    },
                    {
                        "name": "Phường Vĩnh Hưng",
                        "code": 304,
                        "codename": "phuong_vinh_hung",
                        "division_type": "phường",
                        "short_codename": "vinh_hung"
                    },
                    {
                        "name": "Phường Định Công",
                        "code": 307,
                        "codename": "phuong_dinh_cong",
                        "division_type": "phường",
                        "short_codename": "dinh_cong"
                    },
                    {
                        "name": "Phường Mai Động",
                        "code": 310,
                        "codename": "phuong_mai_dong",
                        "division_type": "phường",
                        "short_codename": "mai_dong"
                    },
                    {
                        "name": "Phường Tương Mai",
                        "code": 313,
                        "codename": "phuong_tuong_mai",
                        "division_type": "phường",
                        "short_codename": "tuong_mai"
                    },
                    {
                        "name": "Phường Đại Kim",
                        "code": 316,
                        "codename": "phuong_dai_kim",
                        "division_type": "phường",
                        "short_codename": "dai_kim"
                    },
                    {
                        "name": "Phường Tân Mai",
                        "code": 319,
                        "codename": "phuong_tan_mai",
                        "division_type": "phường",
                        "short_codename": "tan_mai"
                    },
                    {
                        "name": "Phường Hoàng Văn Thụ",
                        "code": 322,
                        "codename": "phuong_hoang_van_thu",
                        "division_type": "phường",
                        "short_codename": "hoang_van_thu"
                    },
                    {
                        "name": "Phường Giáp Bát",
                        "code": 325,
                        "codename": "phuong_giap_bat",
                        "division_type": "phường",
                        "short_codename": "giap_bat"
                    },
                    {
                        "name": "Phường Lĩnh Nam",
                        "code": 328,
                        "codename": "phuong_linh_nam",
                        "division_type": "phường",
                        "short_codename": "linh_nam"
                    },
                    {
                        "name": "Phường Thịnh Liệt",
                        "code": 331,
                        "codename": "phuong_thinh_liet",
                        "division_type": "phường",
                        "short_codename": "thinh_liet"
                    },
                    {
                        "name": "Phường Trần Phú",
                        "code": 334,
                        "codename": "phuong_tran_phu",
                        "division_type": "phường",
                        "short_codename": "tran_phu"
                    },
                    {
                        "name": "Phường Hoàng Liệt",
                        "code": 337,
                        "codename": "phuong_hoang_liet",
                        "division_type": "phường",
                        "short_codename": "hoang_liet"
                    },
                    {
                        "name": "Phường Yên Sở",
                        "code": 340,
                        "codename": "phuong_yen_so",
                        "division_type": "phường",
                        "short_codename": "yen_so"
                    }
                ]
            },
            {
                "name": "Quận Thanh Xuân",
                "code": 9,
                "codename": "quan_thanh_xuan",
                "division_type": "quận",
                "short_codename": "thanh_xuan",
                "wards": [
                    {
                        "name": "Phường Nhân Chính",
                        "code": 343,
                        "codename": "phuong_nhan_chinh",
                        "division_type": "phường",
                        "short_codename": "nhan_chinh"
                    },
                    {
                        "name": "Phường Thượng Đình",
                        "code": 346,
                        "codename": "phuong_thuong_dinh",
                        "division_type": "phường",
                        "short_codename": "thuong_dinh"
                    },
                    {
                        "name": "Phường Khương Trung",
                        "code": 349,
                        "codename": "phuong_khuong_trung",
                        "division_type": "phường",
                        "short_codename": "khuong_trung"
                    },
                    {
                        "name": "Phường Khương Mai",
                        "code": 352,
                        "codename": "phuong_khuong_mai",
                        "division_type": "phường",
                        "short_codename": "khuong_mai"
                    },
                    {
                        "name": "Phường Thanh Xuân Trung",
                        "code": 355,
                        "codename": "phuong_thanh_xuan_trung",
                        "division_type": "phường",
                        "short_codename": "thanh_xuan_trung"
                    },
                    {
                        "name": "Phường Phương Liệt",
                        "code": 358,
                        "codename": "phuong_phuong_liet",
                        "division_type": "phường",
                        "short_codename": "phuong_liet"
                    },
                    {
                        "name": "Phường Hạ Đình",
                        "code": 361,
                        "codename": "phuong_ha_dinh",
                        "division_type": "phường",
                        "short_codename": "ha_dinh"
                    },
                    {
                        "name": "Phường Khương Đình",
                        "code": 364,
                        "codename": "phuong_khuong_dinh",
                        "division_type": "phường",
                        "short_codename": "khuong_dinh"
                    },
                    {
                        "name": "Phường Thanh Xuân Bắc",
                        "code": 367,
                        "codename": "phuong_thanh_xuan_bac",
                        "division_type": "phường",
                        "short_codename": "thanh_xuan_bac"
                    },
                    {
                        "name": "Phường Thanh Xuân Nam",
                        "code": 370,
                        "codename": "phuong_thanh_xuan_nam",
                        "division_type": "phường",
                        "short_codename": "thanh_xuan_nam"
                    },
                    {
                        "name": "Phường Kim Giang",
                        "code": 373,
                        "codename": "phuong_kim_giang",
                        "division_type": "phường",
                        "short_codename": "kim_giang"
                    }
                ]
            },
            {
                "name": "Huyện Sóc Sơn",
                "code": 16,
                "codename": "huyen_soc_son",
                "division_type": "huyện",
                "short_codename": "soc_son",
                "wards": [
                    {
                        "name": "Thị trấn Sóc Sơn",
                        "code": 376,
                        "codename": "thi_tran_soc_son",
                        "division_type": "thị trấn",
                        "short_codename": "soc_son"
                    },
                    {
                        "name": "Xã Bắc Sơn",
                        "code": 379,
                        "codename": "xa_bac_son",
                        "division_type": "xã",
                        "short_codename": "bac_son"
                    },
                    {
                        "name": "Xã Minh Trí",
                        "code": 382,
                        "codename": "xa_minh_tri",
                        "division_type": "xã",
                        "short_codename": "minh_tri"
                    },
                    {
                        "name": "Xã Hồng Kỳ",
                        "code": 385,
                        "codename": "xa_hong_ky",
                        "division_type": "xã",
                        "short_codename": "hong_ky"
                    },
                    {
                        "name": "Xã Nam Sơn",
                        "code": 388,
                        "codename": "xa_nam_son",
                        "division_type": "xã",
                        "short_codename": "nam_son"
                    },
                    {
                        "name": "Xã Trung Giã",
                        "code": 391,
                        "codename": "xa_trung_gia",
                        "division_type": "xã",
                        "short_codename": "trung_gia"
                    },
                    {
                        "name": "Xã Tân Hưng",
                        "code": 394,
                        "codename": "xa_tan_hung",
                        "division_type": "xã",
                        "short_codename": "tan_hung"
                    },
                    {
                        "name": "Xã Minh Phú",
                        "code": 397,
                        "codename": "xa_minh_phu",
                        "division_type": "xã",
                        "short_codename": "minh_phu"
                    },
                    {
                        "name": "Xã Phù Linh",
                        "code": 400,
                        "codename": "xa_phu_linh",
                        "division_type": "xã",
                        "short_codename": "phu_linh"
                    },
                    {
                        "name": "Xã Bắc Phú",
                        "code": 403,
                        "codename": "xa_bac_phu",
                        "division_type": "xã",
                        "short_codename": "bac_phu"
                    },
                    {
                        "name": "Xã Tân Minh",
                        "code": 406,
                        "codename": "xa_tan_minh",
                        "division_type": "xã",
                        "short_codename": "tan_minh"
                    },
                    {
                        "name": "Xã Quang Tiến",
                        "code": 409,
                        "codename": "xa_quang_tien",
                        "division_type": "xã",
                        "short_codename": "quang_tien"
                    },
                    {
                        "name": "Xã Hiền Ninh",
                        "code": 412,
                        "codename": "xa_hien_ninh",
                        "division_type": "xã",
                        "short_codename": "hien_ninh"
                    },
                    {
                        "name": "Xã Tân Dân",
                        "code": 415,
                        "codename": "xa_tan_dan",
                        "division_type": "xã",
                        "short_codename": "tan_dan"
                    },
                    {
                        "name": "Xã Tiên Dược",
                        "code": 418,
                        "codename": "xa_tien_duoc",
                        "division_type": "xã",
                        "short_codename": "tien_duoc"
                    },
                    {
                        "name": "Xã Việt Long",
                        "code": 421,
                        "codename": "xa_viet_long",
                        "division_type": "xã",
                        "short_codename": "viet_long"
                    },
                    {
                        "name": "Xã Xuân Giang",
                        "code": 424,
                        "codename": "xa_xuan_giang",
                        "division_type": "xã",
                        "short_codename": "xuan_giang"
                    },
                    {
                        "name": "Xã Mai Đình",
                        "code": 427,
                        "codename": "xa_mai_dinh",
                        "division_type": "xã",
                        "short_codename": "mai_dinh"
                    },
                    {
                        "name": "Xã Đức Hoà",
                        "code": 430,
                        "codename": "xa_duc_hoa",
                        "division_type": "xã",
                        "short_codename": "duc_hoa"
                    },
                    {
                        "name": "Xã Thanh Xuân",
                        "code": 433,
                        "codename": "xa_thanh_xuan",
                        "division_type": "xã",
                        "short_codename": "thanh_xuan"
                    },
                    {
                        "name": "Xã Đông Xuân",
                        "code": 436,
                        "codename": "xa_dong_xuan",
                        "division_type": "xã",
                        "short_codename": "dong_xuan"
                    },
                    {
                        "name": "Xã Kim Lũ",
                        "code": 439,
                        "codename": "xa_kim_lu",
                        "division_type": "xã",
                        "short_codename": "kim_lu"
                    },
                    {
                        "name": "Xã Phú Cường",
                        "code": 442,
                        "codename": "xa_phu_cuong",
                        "division_type": "xã",
                        "short_codename": "phu_cuong"
                    },
                    {
                        "name": "Xã Phú Minh",
                        "code": 445,
                        "codename": "xa_phu_minh",
                        "division_type": "xã",
                        "short_codename": "phu_minh"
                    },
                    {
                        "name": "Xã Phù Lỗ",
                        "code": 448,
                        "codename": "xa_phu_lo",
                        "division_type": "xã",
                        "short_codename": "phu_lo"
                    },
                    {
                        "name": "Xã Xuân Thu",
                        "code": 451,
                        "codename": "xa_xuan_thu",
                        "division_type": "xã",
                        "short_codename": "xuan_thu"
                    }
                ]
            },
            {
                "name": "Huyện Đông Anh",
                "code": 17,
                "codename": "huyen_dong_anh",
                "division_type": "huyện",
                "short_codename": "dong_anh",
                "wards": [
                    {
                        "name": "Thị trấn Đông Anh",
                        "code": 454,
                        "codename": "thi_tran_dong_anh",
                        "division_type": "thị trấn",
                        "short_codename": "dong_anh"
                    },
                    {
                        "name": "Xã Xuân Nộn",
                        "code": 457,
                        "codename": "xa_xuan_non",
                        "division_type": "xã",
                        "short_codename": "xuan_non"
                    },
                    {
                        "name": "Xã Thuỵ Lâm",
                        "code": 460,
                        "codename": "xa_thuy_lam",
                        "division_type": "xã",
                        "short_codename": "thuy_lam"
                    },
                    {
                        "name": "Xã Bắc Hồng",
                        "code": 463,
                        "codename": "xa_bac_hong",
                        "division_type": "xã",
                        "short_codename": "bac_hong"
                    },
                    {
                        "name": "Xã Nguyên Khê",
                        "code": 466,
                        "codename": "xa_nguyen_khe",
                        "division_type": "xã",
                        "short_codename": "nguyen_khe"
                    },
                    {
                        "name": "Xã Nam Hồng",
                        "code": 469,
                        "codename": "xa_nam_hong",
                        "division_type": "xã",
                        "short_codename": "nam_hong"
                    },
                    {
                        "name": "Xã Tiên Dương",
                        "code": 472,
                        "codename": "xa_tien_duong",
                        "division_type": "xã",
                        "short_codename": "tien_duong"
                    },
                    {
                        "name": "Xã Vân Hà",
                        "code": 475,
                        "codename": "xa_van_ha",
                        "division_type": "xã",
                        "short_codename": "van_ha"
                    },
                    {
                        "name": "Xã Uy Nỗ",
                        "code": 478,
                        "codename": "xa_uy_no",
                        "division_type": "xã",
                        "short_codename": "uy_no"
                    },
                    {
                        "name": "Xã Vân Nội",
                        "code": 481,
                        "codename": "xa_van_noi",
                        "division_type": "xã",
                        "short_codename": "van_noi"
                    },
                    {
                        "name": "Xã Liên Hà",
                        "code": 484,
                        "codename": "xa_lien_ha",
                        "division_type": "xã",
                        "short_codename": "lien_ha"
                    },
                    {
                        "name": "Xã Việt Hùng",
                        "code": 487,
                        "codename": "xa_viet_hung",
                        "division_type": "xã",
                        "short_codename": "viet_hung"
                    },
                    {
                        "name": "Xã Kim Nỗ",
                        "code": 490,
                        "codename": "xa_kim_no",
                        "division_type": "xã",
                        "short_codename": "kim_no"
                    },
                    {
                        "name": "Xã Kim Chung",
                        "code": 493,
                        "codename": "xa_kim_chung",
                        "division_type": "xã",
                        "short_codename": "kim_chung"
                    },
                    {
                        "name": "Xã Dục Tú",
                        "code": 496,
                        "codename": "xa_duc_tu",
                        "division_type": "xã",
                        "short_codename": "duc_tu"
                    },
                    {
                        "name": "Xã Đại Mạch",
                        "code": 499,
                        "codename": "xa_dai_mach",
                        "division_type": "xã",
                        "short_codename": "dai_mach"
                    },
                    {
                        "name": "Xã Vĩnh Ngọc",
                        "code": 502,
                        "codename": "xa_vinh_ngoc",
                        "division_type": "xã",
                        "short_codename": "vinh_ngoc"
                    },
                    {
                        "name": "Xã Cổ Loa",
                        "code": 505,
                        "codename": "xa_co_loa",
                        "division_type": "xã",
                        "short_codename": "co_loa"
                    },
                    {
                        "name": "Xã Hải Bối",
                        "code": 508,
                        "codename": "xa_hai_boi",
                        "division_type": "xã",
                        "short_codename": "hai_boi"
                    },
                    {
                        "name": "Xã Xuân Canh",
                        "code": 511,
                        "codename": "xa_xuan_canh",
                        "division_type": "xã",
                        "short_codename": "xuan_canh"
                    },
                    {
                        "name": "Xã Võng La",
                        "code": 514,
                        "codename": "xa_vong_la",
                        "division_type": "xã",
                        "short_codename": "vong_la"
                    },
                    {
                        "name": "Xã Tàm Xá",
                        "code": 517,
                        "codename": "xa_tam_xa",
                        "division_type": "xã",
                        "short_codename": "tam_xa"
                    },
                    {
                        "name": "Xã Mai Lâm",
                        "code": 520,
                        "codename": "xa_mai_lam",
                        "division_type": "xã",
                        "short_codename": "mai_lam"
                    },
                    {
                        "name": "Xã Đông Hội",
                        "code": 523,
                        "codename": "xa_dong_hoi",
                        "division_type": "xã",
                        "short_codename": "dong_hoi"
                    }
                ]
            },
            {
                "name": "Huyện Gia Lâm",
                "code": 18,
                "codename": "huyen_gia_lam",
                "division_type": "huyện",
                "short_codename": "gia_lam",
                "wards": [
                    {
                        "name": "Thị trấn Yên Viên",
                        "code": 526,
                        "codename": "thi_tran_yen_vien",
                        "division_type": "thị trấn",
                        "short_codename": "yen_vien"
                    },
                    {
                        "name": "Xã Yên Thường",
                        "code": 529,
                        "codename": "xa_yen_thuong",
                        "division_type": "xã",
                        "short_codename": "yen_thuong"
                    },
                    {
                        "name": "Xã Yên Viên",
                        "code": 532,
                        "codename": "xa_yen_vien",
                        "division_type": "xã",
                        "short_codename": "xa_yen_vien"
                    },
                    {
                        "name": "Xã Ninh Hiệp",
                        "code": 535,
                        "codename": "xa_ninh_hiep",
                        "division_type": "xã",
                        "short_codename": "ninh_hiep"
                    },
                    {
                        "name": "Xã Đình Xuyên",
                        "code": 538,
                        "codename": "xa_dinh_xuyen",
                        "division_type": "xã",
                        "short_codename": "dinh_xuyen"
                    },
                    {
                        "name": "Xã Dương Hà",
                        "code": 541,
                        "codename": "xa_duong_ha",
                        "division_type": "xã",
                        "short_codename": "duong_ha"
                    },
                    {
                        "name": "Xã Phù Đổng",
                        "code": 544,
                        "codename": "xa_phu_dong",
                        "division_type": "xã",
                        "short_codename": "phu_dong"
                    },
                    {
                        "name": "Xã Trung Mầu",
                        "code": 547,
                        "codename": "xa_trung_mau",
                        "division_type": "xã",
                        "short_codename": "trung_mau"
                    },
                    {
                        "name": "Xã Lệ Chi",
                        "code": 550,
                        "codename": "xa_le_chi",
                        "division_type": "xã",
                        "short_codename": "le_chi"
                    },
                    {
                        "name": "Xã Cổ Bi",
                        "code": 553,
                        "codename": "xa_co_bi",
                        "division_type": "xã",
                        "short_codename": "co_bi"
                    },
                    {
                        "name": "Xã Đặng Xá",
                        "code": 556,
                        "codename": "xa_dang_xa",
                        "division_type": "xã",
                        "short_codename": "dang_xa"
                    },
                    {
                        "name": "Xã Phú Thị",
                        "code": 559,
                        "codename": "xa_phu_thi",
                        "division_type": "xã",
                        "short_codename": "phu_thi"
                    },
                    {
                        "name": "Xã Kim Sơn",
                        "code": 562,
                        "codename": "xa_kim_son",
                        "division_type": "xã",
                        "short_codename": "kim_son"
                    },
                    {
                        "name": "Thị trấn Trâu Quỳ",
                        "code": 565,
                        "codename": "thi_tran_trau_quy",
                        "division_type": "thị trấn",
                        "short_codename": "trau_quy"
                    },
                    {
                        "name": "Xã Dương Quang",
                        "code": 568,
                        "codename": "xa_duong_quang",
                        "division_type": "xã",
                        "short_codename": "duong_quang"
                    },
                    {
                        "name": "Xã Dương Xá",
                        "code": 571,
                        "codename": "xa_duong_xa",
                        "division_type": "xã",
                        "short_codename": "duong_xa"
                    },
                    {
                        "name": "Xã Đông Dư",
                        "code": 574,
                        "codename": "xa_dong_du",
                        "division_type": "xã",
                        "short_codename": "dong_du"
                    },
                    {
                        "name": "Xã Đa Tốn",
                        "code": 577,
                        "codename": "xa_da_ton",
                        "division_type": "xã",
                        "short_codename": "da_ton"
                    },
                    {
                        "name": "Xã Kiêu Kỵ",
                        "code": 580,
                        "codename": "xa_kieu_ky",
                        "division_type": "xã",
                        "short_codename": "kieu_ky"
                    },
                    {
                        "name": "Xã Bát Tràng",
                        "code": 583,
                        "codename": "xa_bat_trang",
                        "division_type": "xã",
                        "short_codename": "bat_trang"
                    },
                    {
                        "name": "Xã Kim Lan",
                        "code": 586,
                        "codename": "xa_kim_lan",
                        "division_type": "xã",
                        "short_codename": "kim_lan"
                    },
                    {
                        "name": "Xã Văn Đức",
                        "code": 589,
                        "codename": "xa_van_duc",
                        "division_type": "xã",
                        "short_codename": "van_duc"
                    }
                ]
            },
            {
                "name": "Quận Nam Từ Liêm",
                "code": 19,
                "codename": "quan_nam_tu_liem",
                "division_type": "quận",
                "short_codename": "nam_tu_liem",
                "wards": [
                    {
                        "name": "Phường Cầu Diễn",
                        "code": 592,
                        "codename": "phuong_cau_dien",
                        "division_type": "phường",
                        "short_codename": "cau_dien"
                    },
                    {
                        "name": "Phường Xuân Phương",
                        "code": 622,
                        "codename": "phuong_xuan_phuong",
                        "division_type": "phường",
                        "short_codename": "xuan_phuong"
                    },
                    {
                        "name": "Phường Phương Canh",
                        "code": 623,
                        "codename": "phuong_phuong_canh",
                        "division_type": "phường",
                        "short_codename": "phuong_canh"
                    },
                    {
                        "name": "Phường Mỹ Đình 1",
                        "code": 625,
                        "codename": "phuong_my_dinh_1",
                        "division_type": "phường",
                        "short_codename": "my_dinh_1"
                    },
                    {
                        "name": "Phường Mỹ Đình 2",
                        "code": 626,
                        "codename": "phuong_my_dinh_2",
                        "division_type": "phường",
                        "short_codename": "my_dinh_2"
                    },
                    {
                        "name": "Phường Tây Mỗ",
                        "code": 628,
                        "codename": "phuong_tay_mo",
                        "division_type": "phường",
                        "short_codename": "tay_mo"
                    },
                    {
                        "name": "Phường Mễ Trì",
                        "code": 631,
                        "codename": "phuong_me_tri",
                        "division_type": "phường",
                        "short_codename": "me_tri"
                    },
                    {
                        "name": "Phường Phú Đô",
                        "code": 632,
                        "codename": "phuong_phu_do",
                        "division_type": "phường",
                        "short_codename": "phu_do"
                    },
                    {
                        "name": "Phường Đại Mỗ",
                        "code": 634,
                        "codename": "phuong_dai_mo",
                        "division_type": "phường",
                        "short_codename": "dai_mo"
                    },
                    {
                        "name": "Phường Trung Văn",
                        "code": 637,
                        "codename": "phuong_trung_van",
                        "division_type": "phường",
                        "short_codename": "trung_van"
                    }
                ]
            },
            {
                "name": "Huyện Thanh Trì",
                "code": 20,
                "codename": "huyen_thanh_tri",
                "division_type": "huyện",
                "short_codename": "thanh_tri",
                "wards": [
                    {
                        "name": "Thị trấn Văn Điển",
                        "code": 640,
                        "codename": "thi_tran_van_dien",
                        "division_type": "thị trấn",
                        "short_codename": "van_dien"
                    },
                    {
                        "name": "Xã Tân Triều",
                        "code": 643,
                        "codename": "xa_tan_trieu",
                        "division_type": "xã",
                        "short_codename": "tan_trieu"
                    },
                    {
                        "name": "Xã Thanh Liệt",
                        "code": 646,
                        "codename": "xa_thanh_liet",
                        "division_type": "xã",
                        "short_codename": "thanh_liet"
                    },
                    {
                        "name": "Xã Tả Thanh Oai",
                        "code": 649,
                        "codename": "xa_ta_thanh_oai",
                        "division_type": "xã",
                        "short_codename": "ta_thanh_oai"
                    },
                    {
                        "name": "Xã Hữu Hoà",
                        "code": 652,
                        "codename": "xa_huu_hoa",
                        "division_type": "xã",
                        "short_codename": "huu_hoa"
                    },
                    {
                        "name": "Xã Tam Hiệp",
                        "code": 655,
                        "codename": "xa_tam_hiep",
                        "division_type": "xã",
                        "short_codename": "tam_hiep"
                    },
                    {
                        "name": "Xã Tứ Hiệp",
                        "code": 658,
                        "codename": "xa_tu_hiep",
                        "division_type": "xã",
                        "short_codename": "tu_hiep"
                    },
                    {
                        "name": "Xã Yên Mỹ",
                        "code": 661,
                        "codename": "xa_yen_my",
                        "division_type": "xã",
                        "short_codename": "yen_my"
                    },
                    {
                        "name": "Xã Vĩnh Quỳnh",
                        "code": 664,
                        "codename": "xa_vinh_quynh",
                        "division_type": "xã",
                        "short_codename": "vinh_quynh"
                    },
                    {
                        "name": "Xã Ngũ Hiệp",
                        "code": 667,
                        "codename": "xa_ngu_hiep",
                        "division_type": "xã",
                        "short_codename": "ngu_hiep"
                    },
                    {
                        "name": "Xã Duyên Hà",
                        "code": 670,
                        "codename": "xa_duyen_ha",
                        "division_type": "xã",
                        "short_codename": "duyen_ha"
                    },
                    {
                        "name": "Xã Ngọc Hồi",
                        "code": 673,
                        "codename": "xa_ngoc_hoi",
                        "division_type": "xã",
                        "short_codename": "ngoc_hoi"
                    },
                    {
                        "name": "Xã Vạn Phúc",
                        "code": 676,
                        "codename": "xa_van_phuc",
                        "division_type": "xã",
                        "short_codename": "van_phuc"
                    },
                    {
                        "name": "Xã Đại áng",
                        "code": 679,
                        "codename": "xa_dai_ang",
                        "division_type": "xã",
                        "short_codename": "dai_ang"
                    },
                    {
                        "name": "Xã Liên Ninh",
                        "code": 682,
                        "codename": "xa_lien_ninh",
                        "division_type": "xã",
                        "short_codename": "lien_ninh"
                    },
                    {
                        "name": "Xã Đông Mỹ",
                        "code": 685,
                        "codename": "xa_dong_my",
                        "division_type": "xã",
                        "short_codename": "dong_my"
                    }
                ]
            },
            {
                "name": "Quận Bắc Từ Liêm",
                "code": 21,
                "codename": "quan_bac_tu_liem",
                "division_type": "quận",
                "short_codename": "bac_tu_liem",
                "wards": [
                    {
                        "name": "Phường Thượng Cát",
                        "code": 595,
                        "codename": "phuong_thuong_cat",
                        "division_type": "phường",
                        "short_codename": "thuong_cat"
                    },
                    {
                        "name": "Phường Liên Mạc",
                        "code": 598,
                        "codename": "phuong_lien_mac",
                        "division_type": "phường",
                        "short_codename": "lien_mac"
                    },
                    {
                        "name": "Phường Đông Ngạc",
                        "code": 601,
                        "codename": "phuong_dong_ngac",
                        "division_type": "phường",
                        "short_codename": "dong_ngac"
                    },
                    {
                        "name": "Phường Đức Thắng",
                        "code": 602,
                        "codename": "phuong_duc_thang",
                        "division_type": "phường",
                        "short_codename": "duc_thang"
                    },
                    {
                        "name": "Phường Thụy Phương",
                        "code": 604,
                        "codename": "phuong_thuy_phuong",
                        "division_type": "phường",
                        "short_codename": "thuy_phuong"
                    },
                    {
                        "name": "Phường Tây Tựu",
                        "code": 607,
                        "codename": "phuong_tay_tuu",
                        "division_type": "phường",
                        "short_codename": "tay_tuu"
                    },
                    {
                        "name": "Phường Xuân Đỉnh",
                        "code": 610,
                        "codename": "phuong_xuan_dinh",
                        "division_type": "phường",
                        "short_codename": "xuan_dinh"
                    },
                    {
                        "name": "Phường Xuân Tảo",
                        "code": 611,
                        "codename": "phuong_xuan_tao",
                        "division_type": "phường",
                        "short_codename": "xuan_tao"
                    },
                    {
                        "name": "Phường Minh Khai",
                        "code": 613,
                        "codename": "phuong_minh_khai",
                        "division_type": "phường",
                        "short_codename": "minh_khai"
                    },
                    {
                        "name": "Phường Cổ Nhuế 1",
                        "code": 616,
                        "codename": "phuong_co_nhue_1",
                        "division_type": "phường",
                        "short_codename": "co_nhue_1"
                    },
                    {
                        "name": "Phường Cổ Nhuế 2",
                        "code": 617,
                        "codename": "phuong_co_nhue_2",
                        "division_type": "phường",
                        "short_codename": "co_nhue_2"
                    },
                    {
                        "name": "Phường Phú Diễn",
                        "code": 619,
                        "codename": "phuong_phu_dien",
                        "division_type": "phường",
                        "short_codename": "phu_dien"
                    },
                    {
                        "name": "Phường Phúc Diễn",
                        "code": 620,
                        "codename": "phuong_phuc_dien",
                        "division_type": "phường",
                        "short_codename": "phuc_dien"
                    }
                ]
            },
            {
                "name": "Huyện Mê Linh",
                "code": 250,
                "codename": "huyen_me_linh",
                "division_type": "huyện",
                "short_codename": "me_linh",
                "wards": [
                    {
                        "name": "Thị trấn Chi Đông",
                        "code": 8973,
                        "codename": "thi_tran_chi_dong",
                        "division_type": "thị trấn",
                        "short_codename": "chi_dong"
                    },
                    {
                        "name": "Xã Đại Thịnh",
                        "code": 8974,
                        "codename": "xa_dai_thinh",
                        "division_type": "xã",
                        "short_codename": "dai_thinh"
                    },
                    {
                        "name": "Xã Kim Hoa",
                        "code": 8977,
                        "codename": "xa_kim_hoa",
                        "division_type": "xã",
                        "short_codename": "kim_hoa"
                    },
                    {
                        "name": "Xã Thạch Đà",
                        "code": 8980,
                        "codename": "xa_thach_da",
                        "division_type": "xã",
                        "short_codename": "thach_da"
                    },
                    {
                        "name": "Xã Tiến Thắng",
                        "code": 8983,
                        "codename": "xa_tien_thang",
                        "division_type": "xã",
                        "short_codename": "tien_thang"
                    },
                    {
                        "name": "Xã Tự Lập",
                        "code": 8986,
                        "codename": "xa_tu_lap",
                        "division_type": "xã",
                        "short_codename": "tu_lap"
                    },
                    {
                        "name": "Thị trấn Quang Minh",
                        "code": 8989,
                        "codename": "thi_tran_quang_minh",
                        "division_type": "thị trấn",
                        "short_codename": "quang_minh"
                    },
                    {
                        "name": "Xã Thanh Lâm",
                        "code": 8992,
                        "codename": "xa_thanh_lam",
                        "division_type": "xã",
                        "short_codename": "thanh_lam"
                    },
                    {
                        "name": "Xã Tam Đồng",
                        "code": 8995,
                        "codename": "xa_tam_dong",
                        "division_type": "xã",
                        "short_codename": "tam_dong"
                    },
                    {
                        "name": "Xã Liên Mạc",
                        "code": 8998,
                        "codename": "xa_lien_mac",
                        "division_type": "xã",
                        "short_codename": "lien_mac"
                    },
                    {
                        "name": "Xã Vạn Yên",
                        "code": 9001,
                        "codename": "xa_van_yen",
                        "division_type": "xã",
                        "short_codename": "van_yen"
                    },
                    {
                        "name": "Xã Chu Phan",
                        "code": 9004,
                        "codename": "xa_chu_phan",
                        "division_type": "xã",
                        "short_codename": "chu_phan"
                    },
                    {
                        "name": "Xã Tiến Thịnh",
                        "code": 9007,
                        "codename": "xa_tien_thinh",
                        "division_type": "xã",
                        "short_codename": "tien_thinh"
                    },
                    {
                        "name": "Xã Mê Linh",
                        "code": 9010,
                        "codename": "xa_me_linh",
                        "division_type": "xã",
                        "short_codename": "me_linh"
                    },
                    {
                        "name": "Xã Văn Khê",
                        "code": 9013,
                        "codename": "xa_van_khe",
                        "division_type": "xã",
                        "short_codename": "van_khe"
                    },
                    {
                        "name": "Xã Hoàng Kim",
                        "code": 9016,
                        "codename": "xa_hoang_kim",
                        "division_type": "xã",
                        "short_codename": "hoang_kim"
                    },
                    {
                        "name": "Xã Tiền Phong",
                        "code": 9019,
                        "codename": "xa_tien_phong",
                        "division_type": "xã",
                        "short_codename": "tien_phong"
                    },
                    {
                        "name": "Xã Tráng Việt",
                        "code": 9022,
                        "codename": "xa_trang_viet",
                        "division_type": "xã",
                        "short_codename": "trang_viet"
                    }
                ]
            },
            {
                "name": "Quận Hà Đông",
                "code": 268,
                "codename": "quan_ha_dong",
                "division_type": "quận",
                "short_codename": "ha_dong",
                "wards": [
                    {
                        "name": "Phường Nguyễn Trãi",
                        "code": 9538,
                        "codename": "phuong_nguyen_trai",
                        "division_type": "phường",
                        "short_codename": "nguyen_trai"
                    },
                    {
                        "name": "Phường Mộ Lao",
                        "code": 9541,
                        "codename": "phuong_mo_lao",
                        "division_type": "phường",
                        "short_codename": "mo_lao"
                    },
                    {
                        "name": "Phường Văn Quán",
                        "code": 9542,
                        "codename": "phuong_van_quan",
                        "division_type": "phường",
                        "short_codename": "van_quan"
                    },
                    {
                        "name": "Phường Vạn Phúc",
                        "code": 9544,
                        "codename": "phuong_van_phuc",
                        "division_type": "phường",
                        "short_codename": "van_phuc"
                    },
                    {
                        "name": "Phường Yết Kiêu",
                        "code": 9547,
                        "codename": "phuong_yet_kieu",
                        "division_type": "phường",
                        "short_codename": "yet_kieu"
                    },
                    {
                        "name": "Phường Quang Trung",
                        "code": 9550,
                        "codename": "phuong_quang_trung",
                        "division_type": "phường",
                        "short_codename": "quang_trung"
                    },
                    {
                        "name": "Phường La Khê",
                        "code": 9551,
                        "codename": "phuong_la_khe",
                        "division_type": "phường",
                        "short_codename": "la_khe"
                    },
                    {
                        "name": "Phường Phú La",
                        "code": 9552,
                        "codename": "phuong_phu_la",
                        "division_type": "phường",
                        "short_codename": "phu_la"
                    },
                    {
                        "name": "Phường Phúc La",
                        "code": 9553,
                        "codename": "phuong_phuc_la",
                        "division_type": "phường",
                        "short_codename": "phuc_la"
                    },
                    {
                        "name": "Phường Hà Cầu",
                        "code": 9556,
                        "codename": "phuong_ha_cau",
                        "division_type": "phường",
                        "short_codename": "ha_cau"
                    },
                    {
                        "name": "Phường Yên Nghĩa",
                        "code": 9562,
                        "codename": "phuong_yen_nghia",
                        "division_type": "phường",
                        "short_codename": "yen_nghia"
                    },
                    {
                        "name": "Phường Kiến Hưng",
                        "code": 9565,
                        "codename": "phuong_kien_hung",
                        "division_type": "phường",
                        "short_codename": "kien_hung"
                    },
                    {
                        "name": "Phường Phú Lãm",
                        "code": 9568,
                        "codename": "phuong_phu_lam",
                        "division_type": "phường",
                        "short_codename": "phu_lam"
                    },
                    {
                        "name": "Phường Phú Lương",
                        "code": 9571,
                        "codename": "phuong_phu_luong",
                        "division_type": "phường",
                        "short_codename": "phu_luong"
                    },
                    {
                        "name": "Phường Dương Nội",
                        "code": 9886,
                        "codename": "phuong_duong_noi",
                        "division_type": "phường",
                        "short_codename": "duong_noi"
                    },
                    {
                        "name": "Phường Đồng Mai",
                        "code": 10117,
                        "codename": "phuong_dong_mai",
                        "division_type": "phường",
                        "short_codename": "dong_mai"
                    },
                    {
                        "name": "Phường Biên Giang",
                        "code": 10123,
                        "codename": "phuong_bien_giang",
                        "division_type": "phường",
                        "short_codename": "bien_giang"
                    }
                ]
            },
            {
                "name": "Thị xã Sơn Tây",
                "code": 269,
                "codename": "thi_xa_son_tay",
                "division_type": "thị xã",
                "short_codename": "son_tay",
                "wards": [
                    {
                        "name": "Phường Lê Lợi",
                        "code": 9574,
                        "codename": "phuong_le_loi",
                        "division_type": "phường",
                        "short_codename": "le_loi"
                    },
                    {
                        "name": "Phường Phú Thịnh",
                        "code": 9577,
                        "codename": "phuong_phu_thinh",
                        "division_type": "phường",
                        "short_codename": "phu_thinh"
                    },
                    {
                        "name": "Phường Ngô Quyền",
                        "code": 9580,
                        "codename": "phuong_ngo_quyen",
                        "division_type": "phường",
                        "short_codename": "ngo_quyen"
                    },
                    {
                        "name": "Phường Quang Trung",
                        "code": 9583,
                        "codename": "phuong_quang_trung",
                        "division_type": "phường",
                        "short_codename": "quang_trung"
                    },
                    {
                        "name": "Phường Sơn Lộc",
                        "code": 9586,
                        "codename": "phuong_son_loc",
                        "division_type": "phường",
                        "short_codename": "son_loc"
                    },
                    {
                        "name": "Phường Xuân Khanh",
                        "code": 9589,
                        "codename": "phuong_xuan_khanh",
                        "division_type": "phường",
                        "short_codename": "xuan_khanh"
                    },
                    {
                        "name": "Xã Đường Lâm",
                        "code": 9592,
                        "codename": "xa_duong_lam",
                        "division_type": "xã",
                        "short_codename": "duong_lam"
                    },
                    {
                        "name": "Phường Viên Sơn",
                        "code": 9595,
                        "codename": "phuong_vien_son",
                        "division_type": "phường",
                        "short_codename": "vien_son"
                    },
                    {
                        "name": "Xã Xuân Sơn",
                        "code": 9598,
                        "codename": "xa_xuan_son",
                        "division_type": "xã",
                        "short_codename": "xuan_son"
                    },
                    {
                        "name": "Phường Trung Hưng",
                        "code": 9601,
                        "codename": "phuong_trung_hung",
                        "division_type": "phường",
                        "short_codename": "trung_hung"
                    },
                    {
                        "name": "Xã Thanh Mỹ",
                        "code": 9604,
                        "codename": "xa_thanh_my",
                        "division_type": "xã",
                        "short_codename": "thanh_my"
                    },
                    {
                        "name": "Phường Trung Sơn Trầm",
                        "code": 9607,
                        "codename": "phuong_trung_son_tram",
                        "division_type": "phường",
                        "short_codename": "trung_son_tram"
                    },
                    {
                        "name": "Xã Kim Sơn",
                        "code": 9610,
                        "codename": "xa_kim_son",
                        "division_type": "xã",
                        "short_codename": "kim_son"
                    },
                    {
                        "name": "Xã Sơn Đông",
                        "code": 9613,
                        "codename": "xa_son_dong",
                        "division_type": "xã",
                        "short_codename": "son_dong"
                    },
                    {
                        "name": "Xã Cổ Đông",
                        "code": 9616,
                        "codename": "xa_co_dong",
                        "division_type": "xã",
                        "short_codename": "co_dong"
                    }
                ]
            },
            {
                "name": "Huyện Ba Vì",
                "code": 271,
                "codename": "huyen_ba_vi",
                "division_type": "huyện",
                "short_codename": "ba_vi",
                "wards": [
                    {
                        "name": "Thị trấn Tây Đằng",
                        "code": 9619,
                        "codename": "thi_tran_tay_dang",
                        "division_type": "thị trấn",
                        "short_codename": "tay_dang"
                    },
                    {
                        "name": "Xã Phú Cường",
                        "code": 9625,
                        "codename": "xa_phu_cuong",
                        "division_type": "xã",
                        "short_codename": "phu_cuong"
                    },
                    {
                        "name": "Xã Cổ Đô",
                        "code": 9628,
                        "codename": "xa_co_do",
                        "division_type": "xã",
                        "short_codename": "co_do"
                    },
                    {
                        "name": "Xã Tản Hồng",
                        "code": 9631,
                        "codename": "xa_tan_hong",
                        "division_type": "xã",
                        "short_codename": "tan_hong"
                    },
                    {
                        "name": "Xã Vạn Thắng",
                        "code": 9634,
                        "codename": "xa_van_thang",
                        "division_type": "xã",
                        "short_codename": "van_thang"
                    },
                    {
                        "name": "Xã Châu Sơn",
                        "code": 9637,
                        "codename": "xa_chau_son",
                        "division_type": "xã",
                        "short_codename": "chau_son"
                    },
                    {
                        "name": "Xã Phong Vân",
                        "code": 9640,
                        "codename": "xa_phong_van",
                        "division_type": "xã",
                        "short_codename": "phong_van"
                    },
                    {
                        "name": "Xã Phú Đông",
                        "code": 9643,
                        "codename": "xa_phu_dong",
                        "division_type": "xã",
                        "short_codename": "phu_dong"
                    },
                    {
                        "name": "Xã Phú Phương",
                        "code": 9646,
                        "codename": "xa_phu_phuong",
                        "division_type": "xã",
                        "short_codename": "phu_phuong"
                    },
                    {
                        "name": "Xã Phú Châu",
                        "code": 9649,
                        "codename": "xa_phu_chau",
                        "division_type": "xã",
                        "short_codename": "phu_chau"
                    },
                    {
                        "name": "Xã Thái Hòa",
                        "code": 9652,
                        "codename": "xa_thai_hoa",
                        "division_type": "xã",
                        "short_codename": "thai_hoa"
                    },
                    {
                        "name": "Xã Đồng Thái",
                        "code": 9655,
                        "codename": "xa_dong_thai",
                        "division_type": "xã",
                        "short_codename": "dong_thai"
                    },
                    {
                        "name": "Xã Phú Sơn",
                        "code": 9658,
                        "codename": "xa_phu_son",
                        "division_type": "xã",
                        "short_codename": "phu_son"
                    },
                    {
                        "name": "Xã Minh Châu",
                        "code": 9661,
                        "codename": "xa_minh_chau",
                        "division_type": "xã",
                        "short_codename": "minh_chau"
                    },
                    {
                        "name": "Xã Vật Lại",
                        "code": 9664,
                        "codename": "xa_vat_lai",
                        "division_type": "xã",
                        "short_codename": "vat_lai"
                    },
                    {
                        "name": "Xã Chu Minh",
                        "code": 9667,
                        "codename": "xa_chu_minh",
                        "division_type": "xã",
                        "short_codename": "chu_minh"
                    },
                    {
                        "name": "Xã Tòng Bạt",
                        "code": 9670,
                        "codename": "xa_tong_bat",
                        "division_type": "xã",
                        "short_codename": "tong_bat"
                    },
                    {
                        "name": "Xã Cẩm Lĩnh",
                        "code": 9673,
                        "codename": "xa_cam_linh",
                        "division_type": "xã",
                        "short_codename": "cam_linh"
                    },
                    {
                        "name": "Xã Sơn Đà",
                        "code": 9676,
                        "codename": "xa_son_da",
                        "division_type": "xã",
                        "short_codename": "son_da"
                    },
                    {
                        "name": "Xã Đông Quang",
                        "code": 9679,
                        "codename": "xa_dong_quang",
                        "division_type": "xã",
                        "short_codename": "dong_quang"
                    },
                    {
                        "name": "Xã Tiên Phong",
                        "code": 9682,
                        "codename": "xa_tien_phong",
                        "division_type": "xã",
                        "short_codename": "tien_phong"
                    },
                    {
                        "name": "Xã Thụy An",
                        "code": 9685,
                        "codename": "xa_thuy_an",
                        "division_type": "xã",
                        "short_codename": "thuy_an"
                    },
                    {
                        "name": "Xã Cam Thượng",
                        "code": 9688,
                        "codename": "xa_cam_thuong",
                        "division_type": "xã",
                        "short_codename": "cam_thuong"
                    },
                    {
                        "name": "Xã Thuần Mỹ",
                        "code": 9691,
                        "codename": "xa_thuan_my",
                        "division_type": "xã",
                        "short_codename": "thuan_my"
                    },
                    {
                        "name": "Xã Tản Lĩnh",
                        "code": 9694,
                        "codename": "xa_tan_linh",
                        "division_type": "xã",
                        "short_codename": "tan_linh"
                    },
                    {
                        "name": "Xã Ba Trại",
                        "code": 9697,
                        "codename": "xa_ba_trai",
                        "division_type": "xã",
                        "short_codename": "ba_trai"
                    },
                    {
                        "name": "Xã Minh Quang",
                        "code": 9700,
                        "codename": "xa_minh_quang",
                        "division_type": "xã",
                        "short_codename": "minh_quang"
                    },
                    {
                        "name": "Xã Ba Vì",
                        "code": 9703,
                        "codename": "xa_ba_vi",
                        "division_type": "xã",
                        "short_codename": "ba_vi"
                    },
                    {
                        "name": "Xã Vân Hòa",
                        "code": 9706,
                        "codename": "xa_van_hoa",
                        "division_type": "xã",
                        "short_codename": "van_hoa"
                    },
                    {
                        "name": "Xã Yên Bài",
                        "code": 9709,
                        "codename": "xa_yen_bai",
                        "division_type": "xã",
                        "short_codename": "yen_bai"
                    },
                    {
                        "name": "Xã Khánh Thượng",
                        "code": 9712,
                        "codename": "xa_khanh_thuong",
                        "division_type": "xã",
                        "short_codename": "khanh_thuong"
                    }
                ]
            },
            {
                "name": "Huyện Phúc Thọ",
                "code": 272,
                "codename": "huyen_phuc_tho",
                "division_type": "huyện",
                "short_codename": "phuc_tho",
                "wards": [
                    {
                        "name": "Thị trấn Phúc Thọ",
                        "code": 9715,
                        "codename": "thi_tran_phuc_tho",
                        "division_type": "thị trấn",
                        "short_codename": "phuc_tho"
                    },
                    {
                        "name": "Xã Vân Hà",
                        "code": 9718,
                        "codename": "xa_van_ha",
                        "division_type": "xã",
                        "short_codename": "van_ha"
                    },
                    {
                        "name": "Xã Vân Phúc",
                        "code": 9721,
                        "codename": "xa_van_phuc",
                        "division_type": "xã",
                        "short_codename": "van_phuc"
                    },
                    {
                        "name": "Xã Vân Nam",
                        "code": 9724,
                        "codename": "xa_van_nam",
                        "division_type": "xã",
                        "short_codename": "van_nam"
                    },
                    {
                        "name": "Xã Xuân Đình",
                        "code": 9727,
                        "codename": "xa_xuan_dinh",
                        "division_type": "xã",
                        "short_codename": "xuan_dinh"
                    },
                    {
                        "name": "Xã Sen Phương",
                        "code": 9733,
                        "codename": "xa_sen_phuong",
                        "division_type": "xã",
                        "short_codename": "sen_phuong"
                    },
                    {
                        "name": "Xã Võng Xuyên",
                        "code": 9739,
                        "codename": "xa_vong_xuyen",
                        "division_type": "xã",
                        "short_codename": "vong_xuyen"
                    },
                    {
                        "name": "Xã Thọ Lộc",
                        "code": 9742,
                        "codename": "xa_tho_loc",
                        "division_type": "xã",
                        "short_codename": "tho_loc"
                    },
                    {
                        "name": "Xã Long Xuyên",
                        "code": 9745,
                        "codename": "xa_long_xuyen",
                        "division_type": "xã",
                        "short_codename": "long_xuyen"
                    },
                    {
                        "name": "Xã Thượng Cốc",
                        "code": 9748,
                        "codename": "xa_thuong_coc",
                        "division_type": "xã",
                        "short_codename": "thuong_coc"
                    },
                    {
                        "name": "Xã Hát Môn",
                        "code": 9751,
                        "codename": "xa_hat_mon",
                        "division_type": "xã",
                        "short_codename": "hat_mon"
                    },
                    {
                        "name": "Xã Tích Giang",
                        "code": 9754,
                        "codename": "xa_tich_giang",
                        "division_type": "xã",
                        "short_codename": "tich_giang"
                    },
                    {
                        "name": "Xã Thanh Đa",
                        "code": 9757,
                        "codename": "xa_thanh_da",
                        "division_type": "xã",
                        "short_codename": "thanh_da"
                    },
                    {
                        "name": "Xã Trạch Mỹ Lộc",
                        "code": 9760,
                        "codename": "xa_trach_my_loc",
                        "division_type": "xã",
                        "short_codename": "trach_my_loc"
                    },
                    {
                        "name": "Xã Phúc Hòa",
                        "code": 9763,
                        "codename": "xa_phuc_hoa",
                        "division_type": "xã",
                        "short_codename": "phuc_hoa"
                    },
                    {
                        "name": "Xã Ngọc Tảo",
                        "code": 9766,
                        "codename": "xa_ngoc_tao",
                        "division_type": "xã",
                        "short_codename": "ngoc_tao"
                    },
                    {
                        "name": "Xã Phụng Thượng",
                        "code": 9769,
                        "codename": "xa_phung_thuong",
                        "division_type": "xã",
                        "short_codename": "phung_thuong"
                    },
                    {
                        "name": "Xã Tam Thuấn",
                        "code": 9772,
                        "codename": "xa_tam_thuan",
                        "division_type": "xã",
                        "short_codename": "tam_thuan"
                    },
                    {
                        "name": "Xã Tam Hiệp",
                        "code": 9775,
                        "codename": "xa_tam_hiep",
                        "division_type": "xã",
                        "short_codename": "tam_hiep"
                    },
                    {
                        "name": "Xã Hiệp Thuận",
                        "code": 9778,
                        "codename": "xa_hiep_thuan",
                        "division_type": "xã",
                        "short_codename": "hiep_thuan"
                    },
                    {
                        "name": "Xã Liên Hiệp",
                        "code": 9781,
                        "codename": "xa_lien_hiep",
                        "division_type": "xã",
                        "short_codename": "lien_hiep"
                    }
                ]
            },
            {
                "name": "Huyện Đan Phượng",
                "code": 273,
                "codename": "huyen_dan_phuong",
                "division_type": "huyện",
                "short_codename": "dan_phuong",
                "wards": [
                    {
                        "name": "Thị trấn Phùng",
                        "code": 9784,
                        "codename": "thi_tran_phung",
                        "division_type": "thị trấn",
                        "short_codename": "phung"
                    },
                    {
                        "name": "Xã Trung Châu",
                        "code": 9787,
                        "codename": "xa_trung_chau",
                        "division_type": "xã",
                        "short_codename": "trung_chau"
                    },
                    {
                        "name": "Xã Thọ An",
                        "code": 9790,
                        "codename": "xa_tho_an",
                        "division_type": "xã",
                        "short_codename": "tho_an"
                    },
                    {
                        "name": "Xã Thọ Xuân",
                        "code": 9793,
                        "codename": "xa_tho_xuan",
                        "division_type": "xã",
                        "short_codename": "tho_xuan"
                    },
                    {
                        "name": "Xã Hồng Hà",
                        "code": 9796,
                        "codename": "xa_hong_ha",
                        "division_type": "xã",
                        "short_codename": "hong_ha"
                    },
                    {
                        "name": "Xã Liên Hồng",
                        "code": 9799,
                        "codename": "xa_lien_hong",
                        "division_type": "xã",
                        "short_codename": "lien_hong"
                    },
                    {
                        "name": "Xã Liên Hà",
                        "code": 9802,
                        "codename": "xa_lien_ha",
                        "division_type": "xã",
                        "short_codename": "lien_ha"
                    },
                    {
                        "name": "Xã Hạ Mỗ",
                        "code": 9805,
                        "codename": "xa_ha_mo",
                        "division_type": "xã",
                        "short_codename": "ha_mo"
                    },
                    {
                        "name": "Xã Liên Trung",
                        "code": 9808,
                        "codename": "xa_lien_trung",
                        "division_type": "xã",
                        "short_codename": "lien_trung"
                    },
                    {
                        "name": "Xã Phương Đình",
                        "code": 9811,
                        "codename": "xa_phuong_dinh",
                        "division_type": "xã",
                        "short_codename": "phuong_dinh"
                    },
                    {
                        "name": "Xã Thượng Mỗ",
                        "code": 9814,
                        "codename": "xa_thuong_mo",
                        "division_type": "xã",
                        "short_codename": "thuong_mo"
                    },
                    {
                        "name": "Xã Tân Hội",
                        "code": 9817,
                        "codename": "xa_tan_hoi",
                        "division_type": "xã",
                        "short_codename": "tan_hoi"
                    },
                    {
                        "name": "Xã Tân Lập",
                        "code": 9820,
                        "codename": "xa_tan_lap",
                        "division_type": "xã",
                        "short_codename": "tan_lap"
                    },
                    {
                        "name": "Xã Đan Phượng",
                        "code": 9823,
                        "codename": "xa_dan_phuong",
                        "division_type": "xã",
                        "short_codename": "dan_phuong"
                    },
                    {
                        "name": "Xã Đồng Tháp",
                        "code": 9826,
                        "codename": "xa_dong_thap",
                        "division_type": "xã",
                        "short_codename": "dong_thap"
                    },
                    {
                        "name": "Xã Song Phượng",
                        "code": 9829,
                        "codename": "xa_song_phuong",
                        "division_type": "xã",
                        "short_codename": "song_phuong"
                    }
                ]
            },
            {
                "name": "Huyện Hoài Đức",
                "code": 274,
                "codename": "huyen_hoai_duc",
                "division_type": "huyện",
                "short_codename": "hoai_duc",
                "wards": [
                    {
                        "name": "Thị trấn Trạm Trôi",
                        "code": 9832,
                        "codename": "thi_tran_tram_troi",
                        "division_type": "thị trấn",
                        "short_codename": "tram_troi"
                    },
                    {
                        "name": "Xã Đức Thượng",
                        "code": 9835,
                        "codename": "xa_duc_thuong",
                        "division_type": "xã",
                        "short_codename": "duc_thuong"
                    },
                    {
                        "name": "Xã Minh Khai",
                        "code": 9838,
                        "codename": "xa_minh_khai",
                        "division_type": "xã",
                        "short_codename": "minh_khai"
                    },
                    {
                        "name": "Xã Dương Liễu",
                        "code": 9841,
                        "codename": "xa_duong_lieu",
                        "division_type": "xã",
                        "short_codename": "duong_lieu"
                    },
                    {
                        "name": "Xã Di Trạch",
                        "code": 9844,
                        "codename": "xa_di_trach",
                        "division_type": "xã",
                        "short_codename": "di_trach"
                    },
                    {
                        "name": "Xã Đức Giang",
                        "code": 9847,
                        "codename": "xa_duc_giang",
                        "division_type": "xã",
                        "short_codename": "duc_giang"
                    },
                    {
                        "name": "Xã Cát Quế",
                        "code": 9850,
                        "codename": "xa_cat_que",
                        "division_type": "xã",
                        "short_codename": "cat_que"
                    },
                    {
                        "name": "Xã Kim Chung",
                        "code": 9853,
                        "codename": "xa_kim_chung",
                        "division_type": "xã",
                        "short_codename": "kim_chung"
                    },
                    {
                        "name": "Xã Yên Sở",
                        "code": 9856,
                        "codename": "xa_yen_so",
                        "division_type": "xã",
                        "short_codename": "yen_so"
                    },
                    {
                        "name": "Xã Sơn Đồng",
                        "code": 9859,
                        "codename": "xa_son_dong",
                        "division_type": "xã",
                        "short_codename": "son_dong"
                    },
                    {
                        "name": "Xã Vân Canh",
                        "code": 9862,
                        "codename": "xa_van_canh",
                        "division_type": "xã",
                        "short_codename": "van_canh"
                    },
                    {
                        "name": "Xã Đắc Sở",
                        "code": 9865,
                        "codename": "xa_dac_so",
                        "division_type": "xã",
                        "short_codename": "dac_so"
                    },
                    {
                        "name": "Xã Lại Yên",
                        "code": 9868,
                        "codename": "xa_lai_yen",
                        "division_type": "xã",
                        "short_codename": "lai_yen"
                    },
                    {
                        "name": "Xã Tiền Yên",
                        "code": 9871,
                        "codename": "xa_tien_yen",
                        "division_type": "xã",
                        "short_codename": "tien_yen"
                    },
                    {
                        "name": "Xã Song Phương",
                        "code": 9874,
                        "codename": "xa_song_phuong",
                        "division_type": "xã",
                        "short_codename": "song_phuong"
                    },
                    {
                        "name": "Xã An Khánh",
                        "code": 9877,
                        "codename": "xa_an_khanh",
                        "division_type": "xã",
                        "short_codename": "an_khanh"
                    },
                    {
                        "name": "Xã An Thượng",
                        "code": 9880,
                        "codename": "xa_an_thuong",
                        "division_type": "xã",
                        "short_codename": "an_thuong"
                    },
                    {
                        "name": "Xã Vân Côn",
                        "code": 9883,
                        "codename": "xa_van_con",
                        "division_type": "xã",
                        "short_codename": "van_con"
                    },
                    {
                        "name": "Xã La Phù",
                        "code": 9889,
                        "codename": "xa_la_phu",
                        "division_type": "xã",
                        "short_codename": "la_phu"
                    },
                    {
                        "name": "Xã Đông La",
                        "code": 9892,
                        "codename": "xa_dong_la",
                        "division_type": "xã",
                        "short_codename": "dong_la"
                    }
                ]
            },
            {
                "name": "Huyện Quốc Oai",
                "code": 275,
                "codename": "huyen_quoc_oai",
                "division_type": "huyện",
                "short_codename": "quoc_oai",
                "wards": [
                    {
                        "name": "Xã Đông Xuân",
                        "code": 4939,
                        "codename": "xa_dong_xuan",
                        "division_type": "xã",
                        "short_codename": "dong_xuan"
                    },
                    {
                        "name": "Thị trấn Quốc Oai",
                        "code": 9895,
                        "codename": "thi_tran_quoc_oai",
                        "division_type": "thị trấn",
                        "short_codename": "quoc_oai"
                    },
                    {
                        "name": "Xã Sài Sơn",
                        "code": 9898,
                        "codename": "xa_sai_son",
                        "division_type": "xã",
                        "short_codename": "sai_son"
                    },
                    {
                        "name": "Xã Phượng Cách",
                        "code": 9901,
                        "codename": "xa_phuong_cach",
                        "division_type": "xã",
                        "short_codename": "phuong_cach"
                    },
                    {
                        "name": "Xã Yên Sơn",
                        "code": 9904,
                        "codename": "xa_yen_son",
                        "division_type": "xã",
                        "short_codename": "yen_son"
                    },
                    {
                        "name": "Xã Ngọc Liệp",
                        "code": 9907,
                        "codename": "xa_ngoc_liep",
                        "division_type": "xã",
                        "short_codename": "ngoc_liep"
                    },
                    {
                        "name": "Xã Ngọc Mỹ",
                        "code": 9910,
                        "codename": "xa_ngoc_my",
                        "division_type": "xã",
                        "short_codename": "ngoc_my"
                    },
                    {
                        "name": "Xã Liệp Tuyết",
                        "code": 9913,
                        "codename": "xa_liep_tuyet",
                        "division_type": "xã",
                        "short_codename": "liep_tuyet"
                    },
                    {
                        "name": "Xã Thạch Thán",
                        "code": 9916,
                        "codename": "xa_thach_than",
                        "division_type": "xã",
                        "short_codename": "thach_than"
                    },
                    {
                        "name": "Xã Đồng Quang",
                        "code": 9919,
                        "codename": "xa_dong_quang",
                        "division_type": "xã",
                        "short_codename": "dong_quang"
                    },
                    {
                        "name": "Xã Phú Cát",
                        "code": 9922,
                        "codename": "xa_phu_cat",
                        "division_type": "xã",
                        "short_codename": "phu_cat"
                    },
                    {
                        "name": "Xã Tuyết Nghĩa",
                        "code": 9925,
                        "codename": "xa_tuyet_nghia",
                        "division_type": "xã",
                        "short_codename": "tuyet_nghia"
                    },
                    {
                        "name": "Xã Nghĩa Hương",
                        "code": 9928,
                        "codename": "xa_nghia_huong",
                        "division_type": "xã",
                        "short_codename": "nghia_huong"
                    },
                    {
                        "name": "Xã Cộng Hòa",
                        "code": 9931,
                        "codename": "xa_cong_hoa",
                        "division_type": "xã",
                        "short_codename": "cong_hoa"
                    },
                    {
                        "name": "Xã Tân Phú",
                        "code": 9934,
                        "codename": "xa_tan_phu",
                        "division_type": "xã",
                        "short_codename": "tan_phu"
                    },
                    {
                        "name": "Xã Đại Thành",
                        "code": 9937,
                        "codename": "xa_dai_thanh",
                        "division_type": "xã",
                        "short_codename": "dai_thanh"
                    },
                    {
                        "name": "Xã Phú Mãn",
                        "code": 9940,
                        "codename": "xa_phu_man",
                        "division_type": "xã",
                        "short_codename": "phu_man"
                    },
                    {
                        "name": "Xã Cấn Hữu",
                        "code": 9943,
                        "codename": "xa_can_huu",
                        "division_type": "xã",
                        "short_codename": "can_huu"
                    },
                    {
                        "name": "Xã Tân Hòa",
                        "code": 9946,
                        "codename": "xa_tan_hoa",
                        "division_type": "xã",
                        "short_codename": "tan_hoa"
                    },
                    {
                        "name": "Xã Hòa Thạch",
                        "code": 9949,
                        "codename": "xa_hoa_thach",
                        "division_type": "xã",
                        "short_codename": "hoa_thach"
                    },
                    {
                        "name": "Xã Đông Yên",
                        "code": 9952,
                        "codename": "xa_dong_yen",
                        "division_type": "xã",
                        "short_codename": "dong_yen"
                    }
                ]
            },
            {
                "name": "Huyện Thạch Thất",
                "code": 276,
                "codename": "huyen_thach_that",
                "division_type": "huyện",
                "short_codename": "thach_that",
                "wards": [
                    {
                        "name": "Xã Yên Trung",
                        "code": 4927,
                        "codename": "xa_yen_trung",
                        "division_type": "xã",
                        "short_codename": "yen_trung"
                    },
                    {
                        "name": "Xã Yên Bình",
                        "code": 4930,
                        "codename": "xa_yen_binh",
                        "division_type": "xã",
                        "short_codename": "yen_binh"
                    },
                    {
                        "name": "Xã Tiến Xuân",
                        "code": 4936,
                        "codename": "xa_tien_xuan",
                        "division_type": "xã",
                        "short_codename": "tien_xuan"
                    },
                    {
                        "name": "Thị trấn Liên Quan",
                        "code": 9955,
                        "codename": "thi_tran_lien_quan",
                        "division_type": "thị trấn",
                        "short_codename": "lien_quan"
                    },
                    {
                        "name": "Xã Đại Đồng",
                        "code": 9958,
                        "codename": "xa_dai_dong",
                        "division_type": "xã",
                        "short_codename": "dai_dong"
                    },
                    {
                        "name": "Xã Cẩm Yên",
                        "code": 9961,
                        "codename": "xa_cam_yen",
                        "division_type": "xã",
                        "short_codename": "cam_yen"
                    },
                    {
                        "name": "Xã Lại Thượng",
                        "code": 9964,
                        "codename": "xa_lai_thuong",
                        "division_type": "xã",
                        "short_codename": "lai_thuong"
                    },
                    {
                        "name": "Xã Phú Kim",
                        "code": 9967,
                        "codename": "xa_phu_kim",
                        "division_type": "xã",
                        "short_codename": "phu_kim"
                    },
                    {
                        "name": "Xã Hương Ngải",
                        "code": 9970,
                        "codename": "xa_huong_ngai",
                        "division_type": "xã",
                        "short_codename": "huong_ngai"
                    },
                    {
                        "name": "Xã Canh Nậu",
                        "code": 9973,
                        "codename": "xa_canh_nau",
                        "division_type": "xã",
                        "short_codename": "canh_nau"
                    },
                    {
                        "name": "Xã Kim Quan",
                        "code": 9976,
                        "codename": "xa_kim_quan",
                        "division_type": "xã",
                        "short_codename": "kim_quan"
                    },
                    {
                        "name": "Xã Dị Nậu",
                        "code": 9979,
                        "codename": "xa_di_nau",
                        "division_type": "xã",
                        "short_codename": "di_nau"
                    },
                    {
                        "name": "Xã Bình Yên",
                        "code": 9982,
                        "codename": "xa_binh_yen",
                        "division_type": "xã",
                        "short_codename": "binh_yen"
                    },
                    {
                        "name": "Xã Chàng Sơn",
                        "code": 9985,
                        "codename": "xa_chang_son",
                        "division_type": "xã",
                        "short_codename": "chang_son"
                    },
                    {
                        "name": "Xã Thạch Hoà",
                        "code": 9988,
                        "codename": "xa_thach_hoa",
                        "division_type": "xã",
                        "short_codename": "thach_hoa"
                    },
                    {
                        "name": "Xã Cần Kiệm",
                        "code": 9991,
                        "codename": "xa_can_kiem",
                        "division_type": "xã",
                        "short_codename": "can_kiem"
                    },
                    {
                        "name": "Xã Hữu Bằng",
                        "code": 9994,
                        "codename": "xa_huu_bang",
                        "division_type": "xã",
                        "short_codename": "huu_bang"
                    },
                    {
                        "name": "Xã Phùng Xá",
                        "code": 9997,
                        "codename": "xa_phung_xa",
                        "division_type": "xã",
                        "short_codename": "phung_xa"
                    },
                    {
                        "name": "Xã Tân Xã",
                        "code": 10000,
                        "codename": "xa_tan_xa",
                        "division_type": "xã",
                        "short_codename": "tan_xa"
                    },
                    {
                        "name": "Xã Thạch Xá",
                        "code": 10003,
                        "codename": "xa_thach_xa",
                        "division_type": "xã",
                        "short_codename": "thach_xa"
                    },
                    {
                        "name": "Xã Bình Phú",
                        "code": 10006,
                        "codename": "xa_binh_phu",
                        "division_type": "xã",
                        "short_codename": "binh_phu"
                    },
                    {
                        "name": "Xã Hạ Bằng",
                        "code": 10009,
                        "codename": "xa_ha_bang",
                        "division_type": "xã",
                        "short_codename": "ha_bang"
                    },
                    {
                        "name": "Xã Đồng Trúc",
                        "code": 10012,
                        "codename": "xa_dong_truc",
                        "division_type": "xã",
                        "short_codename": "dong_truc"
                    }
                ]
            },
            {
                "name": "Huyện Chương Mỹ",
                "code": 277,
                "codename": "huyen_chuong_my",
                "division_type": "huyện",
                "short_codename": "chuong_my",
                "wards": [
                    {
                        "name": "Thị trấn Chúc Sơn",
                        "code": 10015,
                        "codename": "thi_tran_chuc_son",
                        "division_type": "thị trấn",
                        "short_codename": "chuc_son"
                    },
                    {
                        "name": "Thị trấn Xuân Mai",
                        "code": 10018,
                        "codename": "thi_tran_xuan_mai",
                        "division_type": "thị trấn",
                        "short_codename": "xuan_mai"
                    },
                    {
                        "name": "Xã Phụng Châu",
                        "code": 10021,
                        "codename": "xa_phung_chau",
                        "division_type": "xã",
                        "short_codename": "phung_chau"
                    },
                    {
                        "name": "Xã Tiên Phương",
                        "code": 10024,
                        "codename": "xa_tien_phuong",
                        "division_type": "xã",
                        "short_codename": "tien_phuong"
                    },
                    {
                        "name": "Xã Đông Sơn",
                        "code": 10027,
                        "codename": "xa_dong_son",
                        "division_type": "xã",
                        "short_codename": "dong_son"
                    },
                    {
                        "name": "Xã Đông Phương Yên",
                        "code": 10030,
                        "codename": "xa_dong_phuong_yen",
                        "division_type": "xã",
                        "short_codename": "dong_phuong_yen"
                    },
                    {
                        "name": "Xã Phú Nghĩa",
                        "code": 10033,
                        "codename": "xa_phu_nghia",
                        "division_type": "xã",
                        "short_codename": "phu_nghia"
                    },
                    {
                        "name": "Xã Trường Yên",
                        "code": 10039,
                        "codename": "xa_truong_yen",
                        "division_type": "xã",
                        "short_codename": "truong_yen"
                    },
                    {
                        "name": "Xã Ngọc Hòa",
                        "code": 10042,
                        "codename": "xa_ngoc_hoa",
                        "division_type": "xã",
                        "short_codename": "ngoc_hoa"
                    },
                    {
                        "name": "Xã Thủy Xuân Tiên",
                        "code": 10045,
                        "codename": "xa_thuy_xuan_tien",
                        "division_type": "xã",
                        "short_codename": "thuy_xuan_tien"
                    },
                    {
                        "name": "Xã Thanh Bình",
                        "code": 10048,
                        "codename": "xa_thanh_binh",
                        "division_type": "xã",
                        "short_codename": "thanh_binh"
                    },
                    {
                        "name": "Xã Trung Hòa",
                        "code": 10051,
                        "codename": "xa_trung_hoa",
                        "division_type": "xã",
                        "short_codename": "trung_hoa"
                    },
                    {
                        "name": "Xã Đại Yên",
                        "code": 10054,
                        "codename": "xa_dai_yen",
                        "division_type": "xã",
                        "short_codename": "dai_yen"
                    },
                    {
                        "name": "Xã Thụy Hương",
                        "code": 10057,
                        "codename": "xa_thuy_huong",
                        "division_type": "xã",
                        "short_codename": "thuy_huong"
                    },
                    {
                        "name": "Xã Tốt Động",
                        "code": 10060,
                        "codename": "xa_tot_dong",
                        "division_type": "xã",
                        "short_codename": "tot_dong"
                    },
                    {
                        "name": "Xã Lam Điền",
                        "code": 10063,
                        "codename": "xa_lam_dien",
                        "division_type": "xã",
                        "short_codename": "lam_dien"
                    },
                    {
                        "name": "Xã Tân Tiến",
                        "code": 10066,
                        "codename": "xa_tan_tien",
                        "division_type": "xã",
                        "short_codename": "tan_tien"
                    },
                    {
                        "name": "Xã Nam Phương Tiến",
                        "code": 10069,
                        "codename": "xa_nam_phuong_tien",
                        "division_type": "xã",
                        "short_codename": "nam_phuong_tien"
                    },
                    {
                        "name": "Xã Hợp Đồng",
                        "code": 10072,
                        "codename": "xa_hop_dong",
                        "division_type": "xã",
                        "short_codename": "hop_dong"
                    },
                    {
                        "name": "Xã Hoàng Văn Thụ",
                        "code": 10075,
                        "codename": "xa_hoang_van_thu",
                        "division_type": "xã",
                        "short_codename": "hoang_van_thu"
                    },
                    {
                        "name": "Xã Hoàng Diệu",
                        "code": 10078,
                        "codename": "xa_hoang_dieu",
                        "division_type": "xã",
                        "short_codename": "hoang_dieu"
                    },
                    {
                        "name": "Xã Hữu Văn",
                        "code": 10081,
                        "codename": "xa_huu_van",
                        "division_type": "xã",
                        "short_codename": "huu_van"
                    },
                    {
                        "name": "Xã Quảng Bị",
                        "code": 10084,
                        "codename": "xa_quang_bi",
                        "division_type": "xã",
                        "short_codename": "quang_bi"
                    },
                    {
                        "name": "Xã Mỹ Lương",
                        "code": 10087,
                        "codename": "xa_my_luong",
                        "division_type": "xã",
                        "short_codename": "my_luong"
                    },
                    {
                        "name": "Xã Thượng Vực",
                        "code": 10090,
                        "codename": "xa_thuong_vuc",
                        "division_type": "xã",
                        "short_codename": "thuong_vuc"
                    },
                    {
                        "name": "Xã Hồng Phong",
                        "code": 10093,
                        "codename": "xa_hong_phong",
                        "division_type": "xã",
                        "short_codename": "hong_phong"
                    },
                    {
                        "name": "Xã Đồng Phú",
                        "code": 10096,
                        "codename": "xa_dong_phu",
                        "division_type": "xã",
                        "short_codename": "dong_phu"
                    },
                    {
                        "name": "Xã Trần Phú",
                        "code": 10099,
                        "codename": "xa_tran_phu",
                        "division_type": "xã",
                        "short_codename": "tran_phu"
                    },
                    {
                        "name": "Xã Văn Võ",
                        "code": 10102,
                        "codename": "xa_van_vo",
                        "division_type": "xã",
                        "short_codename": "van_vo"
                    },
                    {
                        "name": "Xã Đồng Lạc",
                        "code": 10105,
                        "codename": "xa_dong_lac",
                        "division_type": "xã",
                        "short_codename": "dong_lac"
                    },
                    {
                        "name": "Xã Hòa Chính",
                        "code": 10108,
                        "codename": "xa_hoa_chinh",
                        "division_type": "xã",
                        "short_codename": "hoa_chinh"
                    },
                    {
                        "name": "Xã Phú Nam An",
                        "code": 10111,
                        "codename": "xa_phu_nam_an",
                        "division_type": "xã",
                        "short_codename": "phu_nam_an"
                    }
                ]
            },
            {
                "name": "Huyện Thanh Oai",
                "code": 278,
                "codename": "huyen_thanh_oai",
                "division_type": "huyện",
                "short_codename": "thanh_oai",
                "wards": [
                    {
                        "name": "Thị trấn Kim Bài",
                        "code": 10114,
                        "codename": "thi_tran_kim_bai",
                        "division_type": "thị trấn",
                        "short_codename": "kim_bai"
                    },
                    {
                        "name": "Xã Cự Khê",
                        "code": 10120,
                        "codename": "xa_cu_khe",
                        "division_type": "xã",
                        "short_codename": "cu_khe"
                    },
                    {
                        "name": "Xã Bích Hòa",
                        "code": 10126,
                        "codename": "xa_bich_hoa",
                        "division_type": "xã",
                        "short_codename": "bich_hoa"
                    },
                    {
                        "name": "Xã Mỹ Hưng",
                        "code": 10129,
                        "codename": "xa_my_hung",
                        "division_type": "xã",
                        "short_codename": "my_hung"
                    },
                    {
                        "name": "Xã Cao Viên",
                        "code": 10132,
                        "codename": "xa_cao_vien",
                        "division_type": "xã",
                        "short_codename": "cao_vien"
                    },
                    {
                        "name": "Xã Bình Minh",
                        "code": 10135,
                        "codename": "xa_binh_minh",
                        "division_type": "xã",
                        "short_codename": "binh_minh"
                    },
                    {
                        "name": "Xã Tam Hưng",
                        "code": 10138,
                        "codename": "xa_tam_hung",
                        "division_type": "xã",
                        "short_codename": "tam_hung"
                    },
                    {
                        "name": "Xã Thanh Cao",
                        "code": 10141,
                        "codename": "xa_thanh_cao",
                        "division_type": "xã",
                        "short_codename": "thanh_cao"
                    },
                    {
                        "name": "Xã Thanh Thùy",
                        "code": 10144,
                        "codename": "xa_thanh_thuy",
                        "division_type": "xã",
                        "short_codename": "thanh_thuy"
                    },
                    {
                        "name": "Xã Thanh Mai",
                        "code": 10147,
                        "codename": "xa_thanh_mai",
                        "division_type": "xã",
                        "short_codename": "thanh_mai"
                    },
                    {
                        "name": "Xã Thanh Văn",
                        "code": 10150,
                        "codename": "xa_thanh_van",
                        "division_type": "xã",
                        "short_codename": "thanh_van"
                    },
                    {
                        "name": "Xã Đỗ Động",
                        "code": 10153,
                        "codename": "xa_do_dong",
                        "division_type": "xã",
                        "short_codename": "do_dong"
                    },
                    {
                        "name": "Xã Kim An",
                        "code": 10156,
                        "codename": "xa_kim_an",
                        "division_type": "xã",
                        "short_codename": "kim_an"
                    },
                    {
                        "name": "Xã Kim Thư",
                        "code": 10159,
                        "codename": "xa_kim_thu",
                        "division_type": "xã",
                        "short_codename": "kim_thu"
                    },
                    {
                        "name": "Xã Phương Trung",
                        "code": 10162,
                        "codename": "xa_phuong_trung",
                        "division_type": "xã",
                        "short_codename": "phuong_trung"
                    },
                    {
                        "name": "Xã Tân Ước",
                        "code": 10165,
                        "codename": "xa_tan_uoc",
                        "division_type": "xã",
                        "short_codename": "tan_uoc"
                    },
                    {
                        "name": "Xã Dân Hòa",
                        "code": 10168,
                        "codename": "xa_dan_hoa",
                        "division_type": "xã",
                        "short_codename": "dan_hoa"
                    },
                    {
                        "name": "Xã Liên Châu",
                        "code": 10171,
                        "codename": "xa_lien_chau",
                        "division_type": "xã",
                        "short_codename": "lien_chau"
                    },
                    {
                        "name": "Xã Cao Dương",
                        "code": 10174,
                        "codename": "xa_cao_duong",
                        "division_type": "xã",
                        "short_codename": "cao_duong"
                    },
                    {
                        "name": "Xã Xuân Dương",
                        "code": 10177,
                        "codename": "xa_xuan_duong",
                        "division_type": "xã",
                        "short_codename": "xuan_duong"
                    },
                    {
                        "name": "Xã Hồng Dương",
                        "code": 10180,
                        "codename": "xa_hong_duong",
                        "division_type": "xã",
                        "short_codename": "hong_duong"
                    }
                ]
            },
            {
                "name": "Huyện Thường Tín",
                "code": 279,
                "codename": "huyen_thuong_tin",
                "division_type": "huyện",
                "short_codename": "thuong_tin",
                "wards": [
                    {
                        "name": "Thị trấn Thường Tín",
                        "code": 10183,
                        "codename": "thi_tran_thuong_tin",
                        "division_type": "thị trấn",
                        "short_codename": "thuong_tin"
                    },
                    {
                        "name": "Xã Ninh Sở",
                        "code": 10186,
                        "codename": "xa_ninh_so",
                        "division_type": "xã",
                        "short_codename": "ninh_so"
                    },
                    {
                        "name": "Xã Nhị Khê",
                        "code": 10189,
                        "codename": "xa_nhi_khe",
                        "division_type": "xã",
                        "short_codename": "nhi_khe"
                    },
                    {
                        "name": "Xã Duyên Thái",
                        "code": 10192,
                        "codename": "xa_duyen_thai",
                        "division_type": "xã",
                        "short_codename": "duyen_thai"
                    },
                    {
                        "name": "Xã Khánh Hà",
                        "code": 10195,
                        "codename": "xa_khanh_ha",
                        "division_type": "xã",
                        "short_codename": "khanh_ha"
                    },
                    {
                        "name": "Xã Hòa Bình",
                        "code": 10198,
                        "codename": "xa_hoa_binh",
                        "division_type": "xã",
                        "short_codename": "hoa_binh"
                    },
                    {
                        "name": "Xã Văn Bình",
                        "code": 10201,
                        "codename": "xa_van_binh",
                        "division_type": "xã",
                        "short_codename": "van_binh"
                    },
                    {
                        "name": "Xã Hiền Giang",
                        "code": 10204,
                        "codename": "xa_hien_giang",
                        "division_type": "xã",
                        "short_codename": "hien_giang"
                    },
                    {
                        "name": "Xã Hồng Vân",
                        "code": 10207,
                        "codename": "xa_hong_van",
                        "division_type": "xã",
                        "short_codename": "hong_van"
                    },
                    {
                        "name": "Xã Vân Tảo",
                        "code": 10210,
                        "codename": "xa_van_tao",
                        "division_type": "xã",
                        "short_codename": "van_tao"
                    },
                    {
                        "name": "Xã Liên Phương",
                        "code": 10213,
                        "codename": "xa_lien_phuong",
                        "division_type": "xã",
                        "short_codename": "lien_phuong"
                    },
                    {
                        "name": "Xã Văn Phú",
                        "code": 10216,
                        "codename": "xa_van_phu",
                        "division_type": "xã",
                        "short_codename": "van_phu"
                    },
                    {
                        "name": "Xã Tự Nhiên",
                        "code": 10219,
                        "codename": "xa_tu_nhien",
                        "division_type": "xã",
                        "short_codename": "tu_nhien"
                    },
                    {
                        "name": "Xã Tiền Phong",
                        "code": 10222,
                        "codename": "xa_tien_phong",
                        "division_type": "xã",
                        "short_codename": "tien_phong"
                    },
                    {
                        "name": "Xã Hà Hồi",
                        "code": 10225,
                        "codename": "xa_ha_hoi",
                        "division_type": "xã",
                        "short_codename": "ha_hoi"
                    },
                    {
                        "name": "Xã Thư Phú",
                        "code": 10228,
                        "codename": "xa_thu_phu",
                        "division_type": "xã",
                        "short_codename": "thu_phu"
                    },
                    {
                        "name": "Xã Nguyễn Trãi",
                        "code": 10231,
                        "codename": "xa_nguyen_trai",
                        "division_type": "xã",
                        "short_codename": "nguyen_trai"
                    },
                    {
                        "name": "Xã Quất Động",
                        "code": 10234,
                        "codename": "xa_quat_dong",
                        "division_type": "xã",
                        "short_codename": "quat_dong"
                    },
                    {
                        "name": "Xã Chương Dương",
                        "code": 10237,
                        "codename": "xa_chuong_duong",
                        "division_type": "xã",
                        "short_codename": "chuong_duong"
                    },
                    {
                        "name": "Xã Tân Minh",
                        "code": 10240,
                        "codename": "xa_tan_minh",
                        "division_type": "xã",
                        "short_codename": "tan_minh"
                    },
                    {
                        "name": "Xã Lê Lợi",
                        "code": 10243,
                        "codename": "xa_le_loi",
                        "division_type": "xã",
                        "short_codename": "le_loi"
                    },
                    {
                        "name": "Xã Thắng Lợi",
                        "code": 10246,
                        "codename": "xa_thang_loi",
                        "division_type": "xã",
                        "short_codename": "thang_loi"
                    },
                    {
                        "name": "Xã Dũng Tiến",
                        "code": 10249,
                        "codename": "xa_dung_tien",
                        "division_type": "xã",
                        "short_codename": "dung_tien"
                    },
                    {
                        "name": "Xã Thống Nhất",
                        "code": 10252,
                        "codename": "xa_thong_nhat",
                        "division_type": "xã",
                        "short_codename": "thong_nhat"
                    },
                    {
                        "name": "Xã Nghiêm Xuyên",
                        "code": 10255,
                        "codename": "xa_nghiem_xuyen",
                        "division_type": "xã",
                        "short_codename": "nghiem_xuyen"
                    },
                    {
                        "name": "Xã Tô Hiệu",
                        "code": 10258,
                        "codename": "xa_to_hieu",
                        "division_type": "xã",
                        "short_codename": "to_hieu"
                    },
                    {
                        "name": "Xã Văn Tự",
                        "code": 10261,
                        "codename": "xa_van_tu",
                        "division_type": "xã",
                        "short_codename": "van_tu"
                    },
                    {
                        "name": "Xã Vạn Điểm",
                        "code": 10264,
                        "codename": "xa_van_diem",
                        "division_type": "xã",
                        "short_codename": "van_diem"
                    },
                    {
                        "name": "Xã Minh Cường",
                        "code": 10267,
                        "codename": "xa_minh_cuong",
                        "division_type": "xã",
                        "short_codename": "minh_cuong"
                    }
                ]
            },
            {
                "name": "Huyện Phú Xuyên",
                "code": 280,
                "codename": "huyen_phu_xuyen",
                "division_type": "huyện",
                "short_codename": "phu_xuyen",
                "wards": [
                    {
                        "name": "Thị trấn Phú Minh",
                        "code": 10270,
                        "codename": "thi_tran_phu_minh",
                        "division_type": "thị trấn",
                        "short_codename": "phu_minh"
                    },
                    {
                        "name": "Thị trấn Phú Xuyên",
                        "code": 10273,
                        "codename": "thi_tran_phu_xuyen",
                        "division_type": "thị trấn",
                        "short_codename": "phu_xuyen"
                    },
                    {
                        "name": "Xã Hồng Minh",
                        "code": 10276,
                        "codename": "xa_hong_minh",
                        "division_type": "xã",
                        "short_codename": "hong_minh"
                    },
                    {
                        "name": "Xã Phượng Dực",
                        "code": 10279,
                        "codename": "xa_phuong_duc",
                        "division_type": "xã",
                        "short_codename": "phuong_duc"
                    },
                    {
                        "name": "Xã Nam Tiến",
                        "code": 10282,
                        "codename": "xa_nam_tien",
                        "division_type": "xã",
                        "short_codename": "nam_tien"
                    },
                    {
                        "name": "Xã Tri Trung",
                        "code": 10288,
                        "codename": "xa_tri_trung",
                        "division_type": "xã",
                        "short_codename": "tri_trung"
                    },
                    {
                        "name": "Xã Đại Thắng",
                        "code": 10291,
                        "codename": "xa_dai_thang",
                        "division_type": "xã",
                        "short_codename": "dai_thang"
                    },
                    {
                        "name": "Xã Phú Túc",
                        "code": 10294,
                        "codename": "xa_phu_tuc",
                        "division_type": "xã",
                        "short_codename": "phu_tuc"
                    },
                    {
                        "name": "Xã Văn Hoàng",
                        "code": 10297,
                        "codename": "xa_van_hoang",
                        "division_type": "xã",
                        "short_codename": "van_hoang"
                    },
                    {
                        "name": "Xã Hồng Thái",
                        "code": 10300,
                        "codename": "xa_hong_thai",
                        "division_type": "xã",
                        "short_codename": "hong_thai"
                    },
                    {
                        "name": "Xã Hoàng Long",
                        "code": 10303,
                        "codename": "xa_hoang_long",
                        "division_type": "xã",
                        "short_codename": "hoang_long"
                    },
                    {
                        "name": "Xã Quang Trung",
                        "code": 10306,
                        "codename": "xa_quang_trung",
                        "division_type": "xã",
                        "short_codename": "quang_trung"
                    },
                    {
                        "name": "Xã Nam Phong",
                        "code": 10309,
                        "codename": "xa_nam_phong",
                        "division_type": "xã",
                        "short_codename": "nam_phong"
                    },
                    {
                        "name": "Xã Nam Triều",
                        "code": 10312,
                        "codename": "xa_nam_trieu",
                        "division_type": "xã",
                        "short_codename": "nam_trieu"
                    },
                    {
                        "name": "Xã Tân Dân",
                        "code": 10315,
                        "codename": "xa_tan_dan",
                        "division_type": "xã",
                        "short_codename": "tan_dan"
                    },
                    {
                        "name": "Xã Sơn Hà",
                        "code": 10318,
                        "codename": "xa_son_ha",
                        "division_type": "xã",
                        "short_codename": "son_ha"
                    },
                    {
                        "name": "Xã Chuyên Mỹ",
                        "code": 10321,
                        "codename": "xa_chuyen_my",
                        "division_type": "xã",
                        "short_codename": "chuyen_my"
                    },
                    {
                        "name": "Xã Khai Thái",
                        "code": 10324,
                        "codename": "xa_khai_thai",
                        "division_type": "xã",
                        "short_codename": "khai_thai"
                    },
                    {
                        "name": "Xã Phúc Tiến",
                        "code": 10327,
                        "codename": "xa_phuc_tien",
                        "division_type": "xã",
                        "short_codename": "phuc_tien"
                    },
                    {
                        "name": "Xã Vân Từ",
                        "code": 10330,
                        "codename": "xa_van_tu",
                        "division_type": "xã",
                        "short_codename": "van_tu"
                    },
                    {
                        "name": "Xã Tri Thủy",
                        "code": 10333,
                        "codename": "xa_tri_thuy",
                        "division_type": "xã",
                        "short_codename": "tri_thuy"
                    },
                    {
                        "name": "Xã Đại Xuyên",
                        "code": 10336,
                        "codename": "xa_dai_xuyen",
                        "division_type": "xã",
                        "short_codename": "dai_xuyen"
                    },
                    {
                        "name": "Xã Phú Yên",
                        "code": 10339,
                        "codename": "xa_phu_yen",
                        "division_type": "xã",
                        "short_codename": "phu_yen"
                    },
                    {
                        "name": "Xã Bạch Hạ",
                        "code": 10342,
                        "codename": "xa_bach_ha",
                        "division_type": "xã",
                        "short_codename": "bach_ha"
                    },
                    {
                        "name": "Xã Quang Lãng",
                        "code": 10345,
                        "codename": "xa_quang_lang",
                        "division_type": "xã",
                        "short_codename": "quang_lang"
                    },
                    {
                        "name": "Xã Châu Can",
                        "code": 10348,
                        "codename": "xa_chau_can",
                        "division_type": "xã",
                        "short_codename": "chau_can"
                    },
                    {
                        "name": "Xã Minh Tân",
                        "code": 10351,
                        "codename": "xa_minh_tan",
                        "division_type": "xã",
                        "short_codename": "minh_tan"
                    }
                ]
            },
            {
                "name": "Huyện Ứng Hòa",
                "code": 281,
                "codename": "huyen_ung_hoa",
                "division_type": "huyện",
                "short_codename": "ung_hoa",
                "wards": [
                    {
                        "name": "Thị trấn Vân Đình",
                        "code": 10354,
                        "codename": "thi_tran_van_dinh",
                        "division_type": "thị trấn",
                        "short_codename": "van_dinh"
                    },
                    {
                        "name": "Xã Viên An",
                        "code": 10357,
                        "codename": "xa_vien_an",
                        "division_type": "xã",
                        "short_codename": "vien_an"
                    },
                    {
                        "name": "Xã Viên Nội",
                        "code": 10360,
                        "codename": "xa_vien_noi",
                        "division_type": "xã",
                        "short_codename": "vien_noi"
                    },
                    {
                        "name": "Xã Hoa Sơn",
                        "code": 10363,
                        "codename": "xa_hoa_son",
                        "division_type": "xã",
                        "short_codename": "hoa_son"
                    },
                    {
                        "name": "Xã Quảng Phú Cầu",
                        "code": 10366,
                        "codename": "xa_quang_phu_cau",
                        "division_type": "xã",
                        "short_codename": "quang_phu_cau"
                    },
                    {
                        "name": "Xã Trường Thịnh",
                        "code": 10369,
                        "codename": "xa_truong_thinh",
                        "division_type": "xã",
                        "short_codename": "truong_thinh"
                    },
                    {
                        "name": "Xã Cao Thành",
                        "code": 10372,
                        "codename": "xa_cao_thanh",
                        "division_type": "xã",
                        "short_codename": "cao_thanh"
                    },
                    {
                        "name": "Xã Liên Bạt",
                        "code": 10375,
                        "codename": "xa_lien_bat",
                        "division_type": "xã",
                        "short_codename": "lien_bat"
                    },
                    {
                        "name": "Xã Sơn Công",
                        "code": 10378,
                        "codename": "xa_son_cong",
                        "division_type": "xã",
                        "short_codename": "son_cong"
                    },
                    {
                        "name": "Xã Đồng Tiến",
                        "code": 10381,
                        "codename": "xa_dong_tien",
                        "division_type": "xã",
                        "short_codename": "dong_tien"
                    },
                    {
                        "name": "Xã Phương Tú",
                        "code": 10384,
                        "codename": "xa_phuong_tu",
                        "division_type": "xã",
                        "short_codename": "phuong_tu"
                    },
                    {
                        "name": "Xã Trung Tú",
                        "code": 10387,
                        "codename": "xa_trung_tu",
                        "division_type": "xã",
                        "short_codename": "trung_tu"
                    },
                    {
                        "name": "Xã Đồng Tân",
                        "code": 10390,
                        "codename": "xa_dong_tan",
                        "division_type": "xã",
                        "short_codename": "dong_tan"
                    },
                    {
                        "name": "Xã Tảo Dương Văn",
                        "code": 10393,
                        "codename": "xa_tao_duong_van",
                        "division_type": "xã",
                        "short_codename": "tao_duong_van"
                    },
                    {
                        "name": "Xã Vạn Thái",
                        "code": 10396,
                        "codename": "xa_van_thai",
                        "division_type": "xã",
                        "short_codename": "van_thai"
                    },
                    {
                        "name": "Xã Minh Đức",
                        "code": 10399,
                        "codename": "xa_minh_duc",
                        "division_type": "xã",
                        "short_codename": "minh_duc"
                    },
                    {
                        "name": "Xã Hòa Lâm",
                        "code": 10402,
                        "codename": "xa_hoa_lam",
                        "division_type": "xã",
                        "short_codename": "hoa_lam"
                    },
                    {
                        "name": "Xã Hòa Xá",
                        "code": 10405,
                        "codename": "xa_hoa_xa",
                        "division_type": "xã",
                        "short_codename": "hoa_xa"
                    },
                    {
                        "name": "Xã Trầm Lộng",
                        "code": 10408,
                        "codename": "xa_tram_long",
                        "division_type": "xã",
                        "short_codename": "tram_long"
                    },
                    {
                        "name": "Xã Kim Đường",
                        "code": 10411,
                        "codename": "xa_kim_duong",
                        "division_type": "xã",
                        "short_codename": "kim_duong"
                    },
                    {
                        "name": "Xã Hòa Nam",
                        "code": 10414,
                        "codename": "xa_hoa_nam",
                        "division_type": "xã",
                        "short_codename": "hoa_nam"
                    },
                    {
                        "name": "Xã Hòa Phú",
                        "code": 10417,
                        "codename": "xa_hoa_phu",
                        "division_type": "xã",
                        "short_codename": "hoa_phu"
                    },
                    {
                        "name": "Xã Đội Bình",
                        "code": 10420,
                        "codename": "xa_doi_binh",
                        "division_type": "xã",
                        "short_codename": "doi_binh"
                    },
                    {
                        "name": "Xã Đại Hùng",
                        "code": 10423,
                        "codename": "xa_dai_hung",
                        "division_type": "xã",
                        "short_codename": "dai_hung"
                    },
                    {
                        "name": "Xã Đông Lỗ",
                        "code": 10426,
                        "codename": "xa_dong_lo",
                        "division_type": "xã",
                        "short_codename": "dong_lo"
                    },
                    {
                        "name": "Xã Phù Lưu",
                        "code": 10429,
                        "codename": "xa_phu_luu",
                        "division_type": "xã",
                        "short_codename": "phu_luu"
                    },
                    {
                        "name": "Xã Đại Cường",
                        "code": 10432,
                        "codename": "xa_dai_cuong",
                        "division_type": "xã",
                        "short_codename": "dai_cuong"
                    },
                    {
                        "name": "Xã Lưu Hoàng",
                        "code": 10435,
                        "codename": "xa_luu_hoang",
                        "division_type": "xã",
                        "short_codename": "luu_hoang"
                    },
                    {
                        "name": "Xã Hồng Quang",
                        "code": 10438,
                        "codename": "xa_hong_quang",
                        "division_type": "xã",
                        "short_codename": "hong_quang"
                    }
                ]
            },
            {
                "name": "Huyện Mỹ Đức",
                "code": 282,
                "codename": "huyen_my_duc",
                "division_type": "huyện",
                "short_codename": "my_duc",
                "wards": [
                    {
                        "name": "Thị trấn Đại Nghĩa",
                        "code": 10441,
                        "codename": "thi_tran_dai_nghia",
                        "division_type": "thị trấn",
                        "short_codename": "dai_nghia"
                    },
                    {
                        "name": "Xã Đồng Tâm",
                        "code": 10444,
                        "codename": "xa_dong_tam",
                        "division_type": "xã",
                        "short_codename": "dong_tam"
                    },
                    {
                        "name": "Xã Thượng Lâm",
                        "code": 10447,
                        "codename": "xa_thuong_lam",
                        "division_type": "xã",
                        "short_codename": "thuong_lam"
                    },
                    {
                        "name": "Xã Tuy Lai",
                        "code": 10450,
                        "codename": "xa_tuy_lai",
                        "division_type": "xã",
                        "short_codename": "tuy_lai"
                    },
                    {
                        "name": "Xã Phúc Lâm",
                        "code": 10453,
                        "codename": "xa_phuc_lam",
                        "division_type": "xã",
                        "short_codename": "phuc_lam"
                    },
                    {
                        "name": "Xã Mỹ Thành",
                        "code": 10456,
                        "codename": "xa_my_thanh",
                        "division_type": "xã",
                        "short_codename": "my_thanh"
                    },
                    {
                        "name": "Xã Bột Xuyên",
                        "code": 10459,
                        "codename": "xa_bot_xuyen",
                        "division_type": "xã",
                        "short_codename": "bot_xuyen"
                    },
                    {
                        "name": "Xã An Mỹ",
                        "code": 10462,
                        "codename": "xa_an_my",
                        "division_type": "xã",
                        "short_codename": "an_my"
                    },
                    {
                        "name": "Xã Hồng Sơn",
                        "code": 10465,
                        "codename": "xa_hong_son",
                        "division_type": "xã",
                        "short_codename": "hong_son"
                    },
                    {
                        "name": "Xã Lê Thanh",
                        "code": 10468,
                        "codename": "xa_le_thanh",
                        "division_type": "xã",
                        "short_codename": "le_thanh"
                    },
                    {
                        "name": "Xã Xuy Xá",
                        "code": 10471,
                        "codename": "xa_xuy_xa",
                        "division_type": "xã",
                        "short_codename": "xuy_xa"
                    },
                    {
                        "name": "Xã Phùng Xá",
                        "code": 10474,
                        "codename": "xa_phung_xa",
                        "division_type": "xã",
                        "short_codename": "phung_xa"
                    },
                    {
                        "name": "Xã Phù Lưu Tế",
                        "code": 10477,
                        "codename": "xa_phu_luu_te",
                        "division_type": "xã",
                        "short_codename": "phu_luu_te"
                    },
                    {
                        "name": "Xã Đại Hưng",
                        "code": 10480,
                        "codename": "xa_dai_hung",
                        "division_type": "xã",
                        "short_codename": "dai_hung"
                    },
                    {
                        "name": "Xã Vạn Kim",
                        "code": 10483,
                        "codename": "xa_van_kim",
                        "division_type": "xã",
                        "short_codename": "van_kim"
                    },
                    {
                        "name": "Xã Đốc Tín",
                        "code": 10486,
                        "codename": "xa_doc_tin",
                        "division_type": "xã",
                        "short_codename": "doc_tin"
                    },
                    {
                        "name": "Xã Hương Sơn",
                        "code": 10489,
                        "codename": "xa_huong_son",
                        "division_type": "xã",
                        "short_codename": "huong_son"
                    },
                    {
                        "name": "Xã Hùng Tiến",
                        "code": 10492,
                        "codename": "xa_hung_tien",
                        "division_type": "xã",
                        "short_codename": "hung_tien"
                    },
                    {
                        "name": "Xã An Tiến",
                        "code": 10495,
                        "codename": "xa_an_tien",
                        "division_type": "xã",
                        "short_codename": "an_tien"
                    },
                    {
                        "name": "Xã Hợp Tiến",
                        "code": 10498,
                        "codename": "xa_hop_tien",
                        "division_type": "xã",
                        "short_codename": "hop_tien"
                    },
                    {
                        "name": "Xã Hợp Thanh",
                        "code": 10501,
                        "codename": "xa_hop_thanh",
                        "division_type": "xã",
                        "short_codename": "hop_thanh"
                    },
                    {
                        "name": "Xã An Phú",
                        "code": 10504,
                        "codename": "xa_an_phu",
                        "division_type": "xã",
                        "short_codename": "an_phu"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Hà Giang",
        "code": 2,
        "codename": "tinh_ha_giang",
        "division_type": "tỉnh",
        "phone_code": 219,
        "districts": [
            {
                "name": "Thành phố Hà Giang",
                "code": 24,
                "codename": "thanh_pho_ha_giang",
                "division_type": "thành phố",
                "short_codename": "ha_giang",
                "wards": [
                    {
                        "name": "Phường Quang Trung",
                        "code": 688,
                        "codename": "phuong_quang_trung",
                        "division_type": "phường",
                        "short_codename": "quang_trung"
                    },
                    {
                        "name": "Phường Trần Phú",
                        "code": 691,
                        "codename": "phuong_tran_phu",
                        "division_type": "phường",
                        "short_codename": "tran_phu"
                    },
                    {
                        "name": "Phường Ngọc Hà",
                        "code": 692,
                        "codename": "phuong_ngoc_ha",
                        "division_type": "phường",
                        "short_codename": "ngoc_ha"
                    },
                    {
                        "name": "Phường Nguyễn Trãi",
                        "code": 694,
                        "codename": "phuong_nguyen_trai",
                        "division_type": "phường",
                        "short_codename": "nguyen_trai"
                    },
                    {
                        "name": "Phường Minh Khai",
                        "code": 697,
                        "codename": "phuong_minh_khai",
                        "division_type": "phường",
                        "short_codename": "minh_khai"
                    },
                    {
                        "name": "Xã Ngọc Đường",
                        "code": 700,
                        "codename": "xa_ngoc_duong",
                        "division_type": "xã",
                        "short_codename": "ngoc_duong"
                    },
                    {
                        "name": "Xã Phương Độ",
                        "code": 946,
                        "codename": "xa_phuong_do",
                        "division_type": "xã",
                        "short_codename": "phuong_do"
                    },
                    {
                        "name": "Xã Phương Thiện",
                        "code": 949,
                        "codename": "xa_phuong_thien",
                        "division_type": "xã",
                        "short_codename": "phuong_thien"
                    }
                ]
            },
            {
                "name": "Huyện Đồng Văn",
                "code": 26,
                "codename": "huyen_dong_van",
                "division_type": "huyện",
                "short_codename": "dong_van",
                "wards": [
                    {
                        "name": "Thị trấn Phó Bảng",
                        "code": 712,
                        "codename": "thi_tran_pho_bang",
                        "division_type": "thị trấn",
                        "short_codename": "pho_bang"
                    },
                    {
                        "name": "Xã Lũng Cú",
                        "code": 715,
                        "codename": "xa_lung_cu",
                        "division_type": "xã",
                        "short_codename": "lung_cu"
                    },
                    {
                        "name": "Xã Má Lé",
                        "code": 718,
                        "codename": "xa_ma_le",
                        "division_type": "xã",
                        "short_codename": "ma_le"
                    },
                    {
                        "name": "Thị trấn Đồng Văn",
                        "code": 721,
                        "codename": "thi_tran_dong_van",
                        "division_type": "thị trấn",
                        "short_codename": "dong_van"
                    },
                    {
                        "name": "Xã Lũng Táo",
                        "code": 724,
                        "codename": "xa_lung_tao",
                        "division_type": "xã",
                        "short_codename": "lung_tao"
                    },
                    {
                        "name": "Xã Phố Là",
                        "code": 727,
                        "codename": "xa_pho_la",
                        "division_type": "xã",
                        "short_codename": "pho_la"
                    },
                    {
                        "name": "Xã Thài Phìn Tủng",
                        "code": 730,
                        "codename": "xa_thai_phin_tung",
                        "division_type": "xã",
                        "short_codename": "thai_phin_tung"
                    },
                    {
                        "name": "Xã Sủng Là",
                        "code": 733,
                        "codename": "xa_sung_la",
                        "division_type": "xã",
                        "short_codename": "sung_la"
                    },
                    {
                        "name": "Xã Xà Phìn",
                        "code": 736,
                        "codename": "xa_xa_phin",
                        "division_type": "xã",
                        "short_codename": "xa_phin"
                    },
                    {
                        "name": "Xã Tả Phìn",
                        "code": 739,
                        "codename": "xa_ta_phin",
                        "division_type": "xã",
                        "short_codename": "ta_phin"
                    },
                    {
                        "name": "Xã Tả Lủng",
                        "code": 742,
                        "codename": "xa_ta_lung",
                        "division_type": "xã",
                        "short_codename": "ta_lung"
                    },
                    {
                        "name": "Xã Phố Cáo",
                        "code": 745,
                        "codename": "xa_pho_cao",
                        "division_type": "xã",
                        "short_codename": "pho_cao"
                    },
                    {
                        "name": "Xã Sính Lủng",
                        "code": 748,
                        "codename": "xa_sinh_lung",
                        "division_type": "xã",
                        "short_codename": "sinh_lung"
                    },
                    {
                        "name": "Xã Sảng Tủng",
                        "code": 751,
                        "codename": "xa_sang_tung",
                        "division_type": "xã",
                        "short_codename": "sang_tung"
                    },
                    {
                        "name": "Xã Lũng Thầu",
                        "code": 754,
                        "codename": "xa_lung_thau",
                        "division_type": "xã",
                        "short_codename": "lung_thau"
                    },
                    {
                        "name": "Xã Hố Quáng Phìn",
                        "code": 757,
                        "codename": "xa_ho_quang_phin",
                        "division_type": "xã",
                        "short_codename": "ho_quang_phin"
                    },
                    {
                        "name": "Xã Vần Chải",
                        "code": 760,
                        "codename": "xa_van_chai",
                        "division_type": "xã",
                        "short_codename": "van_chai"
                    },
                    {
                        "name": "Xã Lũng Phìn",
                        "code": 763,
                        "codename": "xa_lung_phin",
                        "division_type": "xã",
                        "short_codename": "lung_phin"
                    },
                    {
                        "name": "Xã Sủng Trái",
                        "code": 766,
                        "codename": "xa_sung_trai",
                        "division_type": "xã",
                        "short_codename": "sung_trai"
                    }
                ]
            },
            {
                "name": "Huyện Mèo Vạc",
                "code": 27,
                "codename": "huyen_meo_vac",
                "division_type": "huyện",
                "short_codename": "meo_vac",
                "wards": [
                    {
                        "name": "Thị trấn Mèo Vạc",
                        "code": 769,
                        "codename": "thi_tran_meo_vac",
                        "division_type": "thị trấn",
                        "short_codename": "meo_vac"
                    },
                    {
                        "name": "Xã Thượng Phùng",
                        "code": 772,
                        "codename": "xa_thuong_phung",
                        "division_type": "xã",
                        "short_codename": "thuong_phung"
                    },
                    {
                        "name": "Xã Pải Lủng",
                        "code": 775,
                        "codename": "xa_pai_lung",
                        "division_type": "xã",
                        "short_codename": "pai_lung"
                    },
                    {
                        "name": "Xã Xín Cái",
                        "code": 778,
                        "codename": "xa_xin_cai",
                        "division_type": "xã",
                        "short_codename": "xin_cai"
                    },
                    {
                        "name": "Xã Pả Vi",
                        "code": 781,
                        "codename": "xa_pa_vi",
                        "division_type": "xã",
                        "short_codename": "pa_vi"
                    },
                    {
                        "name": "Xã Giàng Chu Phìn",
                        "code": 784,
                        "codename": "xa_giang_chu_phin",
                        "division_type": "xã",
                        "short_codename": "giang_chu_phin"
                    },
                    {
                        "name": "Xã Sủng Trà",
                        "code": 787,
                        "codename": "xa_sung_tra",
                        "division_type": "xã",
                        "short_codename": "sung_tra"
                    },
                    {
                        "name": "Xã Sủng Máng",
                        "code": 790,
                        "codename": "xa_sung_mang",
                        "division_type": "xã",
                        "short_codename": "sung_mang"
                    },
                    {
                        "name": "Xã Sơn Vĩ",
                        "code": 793,
                        "codename": "xa_son_vi",
                        "division_type": "xã",
                        "short_codename": "son_vi"
                    },
                    {
                        "name": "Xã Tả Lủng",
                        "code": 796,
                        "codename": "xa_ta_lung",
                        "division_type": "xã",
                        "short_codename": "ta_lung"
                    },
                    {
                        "name": "Xã Cán Chu Phìn",
                        "code": 799,
                        "codename": "xa_can_chu_phin",
                        "division_type": "xã",
                        "short_codename": "can_chu_phin"
                    },
                    {
                        "name": "Xã Lũng Pù",
                        "code": 802,
                        "codename": "xa_lung_pu",
                        "division_type": "xã",
                        "short_codename": "lung_pu"
                    },
                    {
                        "name": "Xã Lũng Chinh",
                        "code": 805,
                        "codename": "xa_lung_chinh",
                        "division_type": "xã",
                        "short_codename": "lung_chinh"
                    },
                    {
                        "name": "Xã Tát Ngà",
                        "code": 808,
                        "codename": "xa_tat_nga",
                        "division_type": "xã",
                        "short_codename": "tat_nga"
                    },
                    {
                        "name": "Xã Nậm Ban",
                        "code": 811,
                        "codename": "xa_nam_ban",
                        "division_type": "xã",
                        "short_codename": "nam_ban"
                    },
                    {
                        "name": "Xã Khâu Vai",
                        "code": 814,
                        "codename": "xa_khau_vai",
                        "division_type": "xã",
                        "short_codename": "khau_vai"
                    },
                    {
                        "name": "Xã Niêm Tòng",
                        "code": 815,
                        "codename": "xa_niem_tong",
                        "division_type": "xã",
                        "short_codename": "niem_tong"
                    },
                    {
                        "name": "Xã Niêm Sơn",
                        "code": 817,
                        "codename": "xa_niem_son",
                        "division_type": "xã",
                        "short_codename": "niem_son"
                    }
                ]
            },
            {
                "name": "Huyện Yên Minh",
                "code": 28,
                "codename": "huyen_yen_minh",
                "division_type": "huyện",
                "short_codename": "yen_minh",
                "wards": [
                    {
                        "name": "Thị trấn Yên Minh",
                        "code": 820,
                        "codename": "thi_tran_yen_minh",
                        "division_type": "thị trấn",
                        "short_codename": "yen_minh"
                    },
                    {
                        "name": "Xã Thắng Mố",
                        "code": 823,
                        "codename": "xa_thang_mo",
                        "division_type": "xã",
                        "short_codename": "thang_mo"
                    },
                    {
                        "name": "Xã Phú Lũng",
                        "code": 826,
                        "codename": "xa_phu_lung",
                        "division_type": "xã",
                        "short_codename": "phu_lung"
                    },
                    {
                        "name": "Xã Sủng Tráng",
                        "code": 829,
                        "codename": "xa_sung_trang",
                        "division_type": "xã",
                        "short_codename": "sung_trang"
                    },
                    {
                        "name": "Xã Bạch Đích",
                        "code": 832,
                        "codename": "xa_bach_dich",
                        "division_type": "xã",
                        "short_codename": "bach_dich"
                    },
                    {
                        "name": "Xã Na Khê",
                        "code": 835,
                        "codename": "xa_na_khe",
                        "division_type": "xã",
                        "short_codename": "na_khe"
                    },
                    {
                        "name": "Xã Sủng Thài",
                        "code": 838,
                        "codename": "xa_sung_thai",
                        "division_type": "xã",
                        "short_codename": "sung_thai"
                    },
                    {
                        "name": "Xã Hữu Vinh",
                        "code": 841,
                        "codename": "xa_huu_vinh",
                        "division_type": "xã",
                        "short_codename": "huu_vinh"
                    },
                    {
                        "name": "Xã Lao Và Chải",
                        "code": 844,
                        "codename": "xa_lao_va_chai",
                        "division_type": "xã",
                        "short_codename": "lao_va_chai"
                    },
                    {
                        "name": "Xã Mậu Duệ",
                        "code": 847,
                        "codename": "xa_mau_due",
                        "division_type": "xã",
                        "short_codename": "mau_due"
                    },
                    {
                        "name": "Xã Đông Minh",
                        "code": 850,
                        "codename": "xa_dong_minh",
                        "division_type": "xã",
                        "short_codename": "dong_minh"
                    },
                    {
                        "name": "Xã Mậu Long",
                        "code": 853,
                        "codename": "xa_mau_long",
                        "division_type": "xã",
                        "short_codename": "mau_long"
                    },
                    {
                        "name": "Xã Ngam La",
                        "code": 856,
                        "codename": "xa_ngam_la",
                        "division_type": "xã",
                        "short_codename": "ngam_la"
                    },
                    {
                        "name": "Xã Ngọc Long",
                        "code": 859,
                        "codename": "xa_ngoc_long",
                        "division_type": "xã",
                        "short_codename": "ngoc_long"
                    },
                    {
                        "name": "Xã Đường Thượng",
                        "code": 862,
                        "codename": "xa_duong_thuong",
                        "division_type": "xã",
                        "short_codename": "duong_thuong"
                    },
                    {
                        "name": "Xã Lũng Hồ",
                        "code": 865,
                        "codename": "xa_lung_ho",
                        "division_type": "xã",
                        "short_codename": "lung_ho"
                    },
                    {
                        "name": "Xã Du Tiến",
                        "code": 868,
                        "codename": "xa_du_tien",
                        "division_type": "xã",
                        "short_codename": "du_tien"
                    },
                    {
                        "name": "Xã Du Già",
                        "code": 871,
                        "codename": "xa_du_gia",
                        "division_type": "xã",
                        "short_codename": "du_gia"
                    }
                ]
            },
            {
                "name": "Huyện Quản Bạ",
                "code": 29,
                "codename": "huyen_quan_ba",
                "division_type": "huyện",
                "short_codename": "quan_ba",
                "wards": [
                    {
                        "name": "Thị trấn Tam Sơn",
                        "code": 874,
                        "codename": "thi_tran_tam_son",
                        "division_type": "thị trấn",
                        "short_codename": "tam_son"
                    },
                    {
                        "name": "Xã Bát Đại Sơn",
                        "code": 877,
                        "codename": "xa_bat_dai_son",
                        "division_type": "xã",
                        "short_codename": "bat_dai_son"
                    },
                    {
                        "name": "Xã Nghĩa Thuận",
                        "code": 880,
                        "codename": "xa_nghia_thuan",
                        "division_type": "xã",
                        "short_codename": "nghia_thuan"
                    },
                    {
                        "name": "Xã Cán Tỷ",
                        "code": 883,
                        "codename": "xa_can_ty",
                        "division_type": "xã",
                        "short_codename": "can_ty"
                    },
                    {
                        "name": "Xã Cao Mã Pờ",
                        "code": 886,
                        "codename": "xa_cao_ma_po",
                        "division_type": "xã",
                        "short_codename": "cao_ma_po"
                    },
                    {
                        "name": "Xã Thanh Vân",
                        "code": 889,
                        "codename": "xa_thanh_van",
                        "division_type": "xã",
                        "short_codename": "thanh_van"
                    },
                    {
                        "name": "Xã Tùng Vài",
                        "code": 892,
                        "codename": "xa_tung_vai",
                        "division_type": "xã",
                        "short_codename": "tung_vai"
                    },
                    {
                        "name": "Xã Đông Hà",
                        "code": 895,
                        "codename": "xa_dong_ha",
                        "division_type": "xã",
                        "short_codename": "dong_ha"
                    },
                    {
                        "name": "Xã Quản Bạ",
                        "code": 898,
                        "codename": "xa_quan_ba",
                        "division_type": "xã",
                        "short_codename": "quan_ba"
                    },
                    {
                        "name": "Xã Lùng Tám",
                        "code": 901,
                        "codename": "xa_lung_tam",
                        "division_type": "xã",
                        "short_codename": "lung_tam"
                    },
                    {
                        "name": "Xã Quyết Tiến",
                        "code": 904,
                        "codename": "xa_quyet_tien",
                        "division_type": "xã",
                        "short_codename": "quyet_tien"
                    },
                    {
                        "name": "Xã Tả Ván",
                        "code": 907,
                        "codename": "xa_ta_van",
                        "division_type": "xã",
                        "short_codename": "ta_van"
                    },
                    {
                        "name": "Xã Thái An",
                        "code": 910,
                        "codename": "xa_thai_an",
                        "division_type": "xã",
                        "short_codename": "thai_an"
                    }
                ]
            },
            {
                "name": "Huyện Vị Xuyên",
                "code": 30,
                "codename": "huyen_vi_xuyen",
                "division_type": "huyện",
                "short_codename": "vi_xuyen",
                "wards": [
                    {
                        "name": "Xã Kim Thạch",
                        "code": 703,
                        "codename": "xa_kim_thach",
                        "division_type": "xã",
                        "short_codename": "kim_thach"
                    },
                    {
                        "name": "Xã Phú Linh",
                        "code": 706,
                        "codename": "xa_phu_linh",
                        "division_type": "xã",
                        "short_codename": "phu_linh"
                    },
                    {
                        "name": "Xã Kim Linh",
                        "code": 709,
                        "codename": "xa_kim_linh",
                        "division_type": "xã",
                        "short_codename": "kim_linh"
                    },
                    {
                        "name": "Thị trấn Vị Xuyên",
                        "code": 913,
                        "codename": "thi_tran_vi_xuyen",
                        "division_type": "thị trấn",
                        "short_codename": "vi_xuyen"
                    },
                    {
                        "name": "Thị trấn Nông Trường Việt Lâm",
                        "code": 916,
                        "codename": "thi_tran_nong_truong_viet_lam",
                        "division_type": "thị trấn",
                        "short_codename": "nong_truong_viet_lam"
                    },
                    {
                        "name": "Xã Minh Tân",
                        "code": 919,
                        "codename": "xa_minh_tan",
                        "division_type": "xã",
                        "short_codename": "minh_tan"
                    },
                    {
                        "name": "Xã Thuận Hoà",
                        "code": 922,
                        "codename": "xa_thuan_hoa",
                        "division_type": "xã",
                        "short_codename": "thuan_hoa"
                    },
                    {
                        "name": "Xã Tùng Bá",
                        "code": 925,
                        "codename": "xa_tung_ba",
                        "division_type": "xã",
                        "short_codename": "tung_ba"
                    },
                    {
                        "name": "Xã Thanh Thủy",
                        "code": 928,
                        "codename": "xa_thanh_thuy",
                        "division_type": "xã",
                        "short_codename": "thanh_thuy"
                    },
                    {
                        "name": "Xã Thanh Đức",
                        "code": 931,
                        "codename": "xa_thanh_duc",
                        "division_type": "xã",
                        "short_codename": "thanh_duc"
                    },
                    {
                        "name": "Xã Phong Quang",
                        "code": 934,
                        "codename": "xa_phong_quang",
                        "division_type": "xã",
                        "short_codename": "phong_quang"
                    },
                    {
                        "name": "Xã Xín Chải",
                        "code": 937,
                        "codename": "xa_xin_chai",
                        "division_type": "xã",
                        "short_codename": "xin_chai"
                    },
                    {
                        "name": "Xã Phương Tiến",
                        "code": 940,
                        "codename": "xa_phuong_tien",
                        "division_type": "xã",
                        "short_codename": "phuong_tien"
                    },
                    {
                        "name": "Xã Lao Chải",
                        "code": 943,
                        "codename": "xa_lao_chai",
                        "division_type": "xã",
                        "short_codename": "lao_chai"
                    },
                    {
                        "name": "Xã Cao Bồ",
                        "code": 952,
                        "codename": "xa_cao_bo",
                        "division_type": "xã",
                        "short_codename": "cao_bo"
                    },
                    {
                        "name": "Xã Đạo Đức",
                        "code": 955,
                        "codename": "xa_dao_duc",
                        "division_type": "xã",
                        "short_codename": "dao_duc"
                    },
                    {
                        "name": "Xã Thượng Sơn",
                        "code": 958,
                        "codename": "xa_thuong_son",
                        "division_type": "xã",
                        "short_codename": "thuong_son"
                    },
                    {
                        "name": "Xã Linh Hồ",
                        "code": 961,
                        "codename": "xa_linh_ho",
                        "division_type": "xã",
                        "short_codename": "linh_ho"
                    },
                    {
                        "name": "Xã Quảng Ngần",
                        "code": 964,
                        "codename": "xa_quang_ngan",
                        "division_type": "xã",
                        "short_codename": "quang_ngan"
                    },
                    {
                        "name": "Xã Việt Lâm",
                        "code": 967,
                        "codename": "xa_viet_lam",
                        "division_type": "xã",
                        "short_codename": "viet_lam"
                    },
                    {
                        "name": "Xã Ngọc Linh",
                        "code": 970,
                        "codename": "xa_ngoc_linh",
                        "division_type": "xã",
                        "short_codename": "ngoc_linh"
                    },
                    {
                        "name": "Xã Ngọc Minh",
                        "code": 973,
                        "codename": "xa_ngoc_minh",
                        "division_type": "xã",
                        "short_codename": "ngoc_minh"
                    },
                    {
                        "name": "Xã Bạch Ngọc",
                        "code": 976,
                        "codename": "xa_bach_ngoc",
                        "division_type": "xã",
                        "short_codename": "bach_ngoc"
                    },
                    {
                        "name": "Xã Trung Thành",
                        "code": 979,
                        "codename": "xa_trung_thanh",
                        "division_type": "xã",
                        "short_codename": "trung_thanh"
                    }
                ]
            },
            {
                "name": "Huyện Bắc Mê",
                "code": 31,
                "codename": "huyen_bac_me",
                "division_type": "huyện",
                "short_codename": "bac_me",
                "wards": [
                    {
                        "name": "Xã Minh Sơn",
                        "code": 982,
                        "codename": "xa_minh_son",
                        "division_type": "xã",
                        "short_codename": "minh_son"
                    },
                    {
                        "name": "Xã Giáp Trung",
                        "code": 985,
                        "codename": "xa_giap_trung",
                        "division_type": "xã",
                        "short_codename": "giap_trung"
                    },
                    {
                        "name": "Xã Yên Định",
                        "code": 988,
                        "codename": "xa_yen_dinh",
                        "division_type": "xã",
                        "short_codename": "yen_dinh"
                    },
                    {
                        "name": "Thị trấn Yên Phú",
                        "code": 991,
                        "codename": "thi_tran_yen_phu",
                        "division_type": "thị trấn",
                        "short_codename": "yen_phu"
                    },
                    {
                        "name": "Xã Minh Ngọc",
                        "code": 994,
                        "codename": "xa_minh_ngoc",
                        "division_type": "xã",
                        "short_codename": "minh_ngoc"
                    },
                    {
                        "name": "Xã Yên Phong",
                        "code": 997,
                        "codename": "xa_yen_phong",
                        "division_type": "xã",
                        "short_codename": "yen_phong"
                    },
                    {
                        "name": "Xã Lạc Nông",
                        "code": 1000,
                        "codename": "xa_lac_nong",
                        "division_type": "xã",
                        "short_codename": "lac_nong"
                    },
                    {
                        "name": "Xã Phú Nam",
                        "code": 1003,
                        "codename": "xa_phu_nam",
                        "division_type": "xã",
                        "short_codename": "phu_nam"
                    },
                    {
                        "name": "Xã Yên Cường",
                        "code": 1006,
                        "codename": "xa_yen_cuong",
                        "division_type": "xã",
                        "short_codename": "yen_cuong"
                    },
                    {
                        "name": "Xã Thượng Tân",
                        "code": 1009,
                        "codename": "xa_thuong_tan",
                        "division_type": "xã",
                        "short_codename": "thuong_tan"
                    },
                    {
                        "name": "Xã Đường Âm",
                        "code": 1012,
                        "codename": "xa_duong_am",
                        "division_type": "xã",
                        "short_codename": "duong_am"
                    },
                    {
                        "name": "Xã Đường Hồng",
                        "code": 1015,
                        "codename": "xa_duong_hong",
                        "division_type": "xã",
                        "short_codename": "duong_hong"
                    },
                    {
                        "name": "Xã Phiêng Luông",
                        "code": 1018,
                        "codename": "xa_phieng_luong",
                        "division_type": "xã",
                        "short_codename": "phieng_luong"
                    }
                ]
            },
            {
                "name": "Huyện Hoàng Su Phì",
                "code": 32,
                "codename": "huyen_hoang_su_phi",
                "division_type": "huyện",
                "short_codename": "hoang_su_phi",
                "wards": [
                    {
                        "name": "Thị trấn Vinh Quang",
                        "code": 1021,
                        "codename": "thi_tran_vinh_quang",
                        "division_type": "thị trấn",
                        "short_codename": "vinh_quang"
                    },
                    {
                        "name": "Xã Bản Máy",
                        "code": 1024,
                        "codename": "xa_ban_may",
                        "division_type": "xã",
                        "short_codename": "ban_may"
                    },
                    {
                        "name": "Xã Thàng Tín",
                        "code": 1027,
                        "codename": "xa_thang_tin",
                        "division_type": "xã",
                        "short_codename": "thang_tin"
                    },
                    {
                        "name": "Xã Thèn Chu Phìn",
                        "code": 1030,
                        "codename": "xa_then_chu_phin",
                        "division_type": "xã",
                        "short_codename": "then_chu_phin"
                    },
                    {
                        "name": "Xã Pố Lồ",
                        "code": 1033,
                        "codename": "xa_po_lo",
                        "division_type": "xã",
                        "short_codename": "po_lo"
                    },
                    {
                        "name": "Xã Bản Phùng",
                        "code": 1036,
                        "codename": "xa_ban_phung",
                        "division_type": "xã",
                        "short_codename": "ban_phung"
                    },
                    {
                        "name": "Xã Túng Sán",
                        "code": 1039,
                        "codename": "xa_tung_san",
                        "division_type": "xã",
                        "short_codename": "tung_san"
                    },
                    {
                        "name": "Xã Chiến Phố",
                        "code": 1042,
                        "codename": "xa_chien_pho",
                        "division_type": "xã",
                        "short_codename": "chien_pho"
                    },
                    {
                        "name": "Xã Đản Ván",
                        "code": 1045,
                        "codename": "xa_dan_van",
                        "division_type": "xã",
                        "short_codename": "dan_van"
                    },
                    {
                        "name": "Xã Tụ Nhân",
                        "code": 1048,
                        "codename": "xa_tu_nhan",
                        "division_type": "xã",
                        "short_codename": "tu_nhan"
                    },
                    {
                        "name": "Xã Tân Tiến",
                        "code": 1051,
                        "codename": "xa_tan_tien",
                        "division_type": "xã",
                        "short_codename": "tan_tien"
                    },
                    {
                        "name": "Xã Nàng Đôn",
                        "code": 1054,
                        "codename": "xa_nang_don",
                        "division_type": "xã",
                        "short_codename": "nang_don"
                    },
                    {
                        "name": "Xã Pờ Ly Ngài",
                        "code": 1057,
                        "codename": "xa_po_ly_ngai",
                        "division_type": "xã",
                        "short_codename": "po_ly_ngai"
                    },
                    {
                        "name": "Xã Sán Xả Hồ",
                        "code": 1060,
                        "codename": "xa_san_xa_ho",
                        "division_type": "xã",
                        "short_codename": "san_xa_ho"
                    },
                    {
                        "name": "Xã Bản Luốc",
                        "code": 1063,
                        "codename": "xa_ban_luoc",
                        "division_type": "xã",
                        "short_codename": "ban_luoc"
                    },
                    {
                        "name": "Xã Ngàm Đăng Vài",
                        "code": 1066,
                        "codename": "xa_ngam_dang_vai",
                        "division_type": "xã",
                        "short_codename": "ngam_dang_vai"
                    },
                    {
                        "name": "Xã Bản Nhùng",
                        "code": 1069,
                        "codename": "xa_ban_nhung",
                        "division_type": "xã",
                        "short_codename": "ban_nhung"
                    },
                    {
                        "name": "Xã Tả Sử Choóng",
                        "code": 1072,
                        "codename": "xa_ta_su_choong",
                        "division_type": "xã",
                        "short_codename": "ta_su_choong"
                    },
                    {
                        "name": "Xã Nậm Dịch",
                        "code": 1075,
                        "codename": "xa_nam_dich",
                        "division_type": "xã",
                        "short_codename": "nam_dich"
                    },
                    {
                        "name": "Xã Hồ Thầu",
                        "code": 1081,
                        "codename": "xa_ho_thau",
                        "division_type": "xã",
                        "short_codename": "ho_thau"
                    },
                    {
                        "name": "Xã Nam Sơn",
                        "code": 1084,
                        "codename": "xa_nam_son",
                        "division_type": "xã",
                        "short_codename": "nam_son"
                    },
                    {
                        "name": "Xã Nậm Tỵ",
                        "code": 1087,
                        "codename": "xa_nam_ty",
                        "division_type": "xã",
                        "short_codename": "nam_ty"
                    },
                    {
                        "name": "Xã Thông Nguyên",
                        "code": 1090,
                        "codename": "xa_thong_nguyen",
                        "division_type": "xã",
                        "short_codename": "thong_nguyen"
                    },
                    {
                        "name": "Xã Nậm Khòa",
                        "code": 1093,
                        "codename": "xa_nam_khoa",
                        "division_type": "xã",
                        "short_codename": "nam_khoa"
                    }
                ]
            },
            {
                "name": "Huyện Xín Mần",
                "code": 33,
                "codename": "huyen_xin_man",
                "division_type": "huyện",
                "short_codename": "xin_man",
                "wards": [
                    {
                        "name": "Thị trấn Cốc Pài",
                        "code": 1096,
                        "codename": "thi_tran_coc_pai",
                        "division_type": "thị trấn",
                        "short_codename": "coc_pai"
                    },
                    {
                        "name": "Xã Nàn Xỉn",
                        "code": 1099,
                        "codename": "xa_nan_xin",
                        "division_type": "xã",
                        "short_codename": "nan_xin"
                    },
                    {
                        "name": "Xã Bản Díu",
                        "code": 1102,
                        "codename": "xa_ban_diu",
                        "division_type": "xã",
                        "short_codename": "ban_diu"
                    },
                    {
                        "name": "Xã Chí Cà",
                        "code": 1105,
                        "codename": "xa_chi_ca",
                        "division_type": "xã",
                        "short_codename": "chi_ca"
                    },
                    {
                        "name": "Xã Xín Mần",
                        "code": 1108,
                        "codename": "xa_xin_man",
                        "division_type": "xã",
                        "short_codename": "xin_man"
                    },
                    {
                        "name": "Xã Thèn Phàng",
                        "code": 1114,
                        "codename": "xa_then_phang",
                        "division_type": "xã",
                        "short_codename": "then_phang"
                    },
                    {
                        "name": "Xã Trung Thịnh",
                        "code": 1117,
                        "codename": "xa_trung_thinh",
                        "division_type": "xã",
                        "short_codename": "trung_thinh"
                    },
                    {
                        "name": "Xã Pà Vầy Sủ",
                        "code": 1120,
                        "codename": "xa_pa_vay_su",
                        "division_type": "xã",
                        "short_codename": "pa_vay_su"
                    },
                    {
                        "name": "Xã Cốc Rế",
                        "code": 1123,
                        "codename": "xa_coc_re",
                        "division_type": "xã",
                        "short_codename": "coc_re"
                    },
                    {
                        "name": "Xã Thu Tà",
                        "code": 1126,
                        "codename": "xa_thu_ta",
                        "division_type": "xã",
                        "short_codename": "thu_ta"
                    },
                    {
                        "name": "Xã Nàn Ma",
                        "code": 1129,
                        "codename": "xa_nan_ma",
                        "division_type": "xã",
                        "short_codename": "nan_ma"
                    },
                    {
                        "name": "Xã Tả Nhìu",
                        "code": 1132,
                        "codename": "xa_ta_nhiu",
                        "division_type": "xã",
                        "short_codename": "ta_nhiu"
                    },
                    {
                        "name": "Xã Bản Ngò",
                        "code": 1135,
                        "codename": "xa_ban_ngo",
                        "division_type": "xã",
                        "short_codename": "ban_ngo"
                    },
                    {
                        "name": "Xã Chế Là",
                        "code": 1138,
                        "codename": "xa_che_la",
                        "division_type": "xã",
                        "short_codename": "che_la"
                    },
                    {
                        "name": "Xã Nấm Dẩn",
                        "code": 1141,
                        "codename": "xa_nam_dan",
                        "division_type": "xã",
                        "short_codename": "nam_dan"
                    },
                    {
                        "name": "Xã Quảng Nguyên",
                        "code": 1144,
                        "codename": "xa_quang_nguyen",
                        "division_type": "xã",
                        "short_codename": "quang_nguyen"
                    },
                    {
                        "name": "Xã Nà Chì",
                        "code": 1147,
                        "codename": "xa_na_chi",
                        "division_type": "xã",
                        "short_codename": "na_chi"
                    },
                    {
                        "name": "Xã Khuôn Lùng",
                        "code": 1150,
                        "codename": "xa_khuon_lung",
                        "division_type": "xã",
                        "short_codename": "khuon_lung"
                    }
                ]
            },
            {
                "name": "Huyện Bắc Quang",
                "code": 34,
                "codename": "huyen_bac_quang",
                "division_type": "huyện",
                "short_codename": "bac_quang",
                "wards": [
                    {
                        "name": "Thị trấn Việt Quang",
                        "code": 1153,
                        "codename": "thi_tran_viet_quang",
                        "division_type": "thị trấn",
                        "short_codename": "viet_quang"
                    },
                    {
                        "name": "Thị trấn Vĩnh Tuy",
                        "code": 1156,
                        "codename": "thi_tran_vinh_tuy",
                        "division_type": "thị trấn",
                        "short_codename": "vinh_tuy"
                    },
                    {
                        "name": "Xã Tân Lập",
                        "code": 1159,
                        "codename": "xa_tan_lap",
                        "division_type": "xã",
                        "short_codename": "tan_lap"
                    },
                    {
                        "name": "Xã Tân Thành",
                        "code": 1162,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Xã Đồng Tiến",
                        "code": 1165,
                        "codename": "xa_dong_tien",
                        "division_type": "xã",
                        "short_codename": "dong_tien"
                    },
                    {
                        "name": "Xã Đồng Tâm",
                        "code": 1168,
                        "codename": "xa_dong_tam",
                        "division_type": "xã",
                        "short_codename": "dong_tam"
                    },
                    {
                        "name": "Xã Tân Quang",
                        "code": 1171,
                        "codename": "xa_tan_quang",
                        "division_type": "xã",
                        "short_codename": "tan_quang"
                    },
                    {
                        "name": "Xã Thượng Bình",
                        "code": 1174,
                        "codename": "xa_thuong_binh",
                        "division_type": "xã",
                        "short_codename": "thuong_binh"
                    },
                    {
                        "name": "Xã Hữu Sản",
                        "code": 1177,
                        "codename": "xa_huu_san",
                        "division_type": "xã",
                        "short_codename": "huu_san"
                    },
                    {
                        "name": "Xã Kim Ngọc",
                        "code": 1180,
                        "codename": "xa_kim_ngoc",
                        "division_type": "xã",
                        "short_codename": "kim_ngoc"
                    },
                    {
                        "name": "Xã Việt Vinh",
                        "code": 1183,
                        "codename": "xa_viet_vinh",
                        "division_type": "xã",
                        "short_codename": "viet_vinh"
                    },
                    {
                        "name": "Xã Bằng Hành",
                        "code": 1186,
                        "codename": "xa_bang_hanh",
                        "division_type": "xã",
                        "short_codename": "bang_hanh"
                    },
                    {
                        "name": "Xã Quang Minh",
                        "code": 1189,
                        "codename": "xa_quang_minh",
                        "division_type": "xã",
                        "short_codename": "quang_minh"
                    },
                    {
                        "name": "Xã Liên Hiệp",
                        "code": 1192,
                        "codename": "xa_lien_hiep",
                        "division_type": "xã",
                        "short_codename": "lien_hiep"
                    },
                    {
                        "name": "Xã Vô Điếm",
                        "code": 1195,
                        "codename": "xa_vo_diem",
                        "division_type": "xã",
                        "short_codename": "vo_diem"
                    },
                    {
                        "name": "Xã Việt Hồng",
                        "code": 1198,
                        "codename": "xa_viet_hong",
                        "division_type": "xã",
                        "short_codename": "viet_hong"
                    },
                    {
                        "name": "Xã Hùng An",
                        "code": 1201,
                        "codename": "xa_hung_an",
                        "division_type": "xã",
                        "short_codename": "hung_an"
                    },
                    {
                        "name": "Xã Đức Xuân",
                        "code": 1204,
                        "codename": "xa_duc_xuan",
                        "division_type": "xã",
                        "short_codename": "duc_xuan"
                    },
                    {
                        "name": "Xã Tiên Kiều",
                        "code": 1207,
                        "codename": "xa_tien_kieu",
                        "division_type": "xã",
                        "short_codename": "tien_kieu"
                    },
                    {
                        "name": "Xã Vĩnh Hảo",
                        "code": 1210,
                        "codename": "xa_vinh_hao",
                        "division_type": "xã",
                        "short_codename": "vinh_hao"
                    },
                    {
                        "name": "Xã Vĩnh Phúc",
                        "code": 1213,
                        "codename": "xa_vinh_phuc",
                        "division_type": "xã",
                        "short_codename": "vinh_phuc"
                    },
                    {
                        "name": "Xã Đồng Yên",
                        "code": 1216,
                        "codename": "xa_dong_yen",
                        "division_type": "xã",
                        "short_codename": "dong_yen"
                    },
                    {
                        "name": "Xã Đông Thành",
                        "code": 1219,
                        "codename": "xa_dong_thanh",
                        "division_type": "xã",
                        "short_codename": "dong_thanh"
                    }
                ]
            },
            {
                "name": "Huyện Quang Bình",
                "code": 35,
                "codename": "huyen_quang_binh",
                "division_type": "huyện",
                "short_codename": "quang_binh",
                "wards": [
                    {
                        "name": "Xã Xuân Minh",
                        "code": 1222,
                        "codename": "xa_xuan_minh",
                        "division_type": "xã",
                        "short_codename": "xuan_minh"
                    },
                    {
                        "name": "Xã Tiên Nguyên",
                        "code": 1225,
                        "codename": "xa_tien_nguyen",
                        "division_type": "xã",
                        "short_codename": "tien_nguyen"
                    },
                    {
                        "name": "Xã Tân Nam",
                        "code": 1228,
                        "codename": "xa_tan_nam",
                        "division_type": "xã",
                        "short_codename": "tan_nam"
                    },
                    {
                        "name": "Xã Bản Rịa",
                        "code": 1231,
                        "codename": "xa_ban_ria",
                        "division_type": "xã",
                        "short_codename": "ban_ria"
                    },
                    {
                        "name": "Xã Yên Thành",
                        "code": 1234,
                        "codename": "xa_yen_thanh",
                        "division_type": "xã",
                        "short_codename": "yen_thanh"
                    },
                    {
                        "name": "Thị trấn Yên Bình",
                        "code": 1237,
                        "codename": "thi_tran_yen_binh",
                        "division_type": "thị trấn",
                        "short_codename": "yen_binh"
                    },
                    {
                        "name": "Xã Tân Trịnh",
                        "code": 1240,
                        "codename": "xa_tan_trinh",
                        "division_type": "xã",
                        "short_codename": "tan_trinh"
                    },
                    {
                        "name": "Xã Tân Bắc",
                        "code": 1243,
                        "codename": "xa_tan_bac",
                        "division_type": "xã",
                        "short_codename": "tan_bac"
                    },
                    {
                        "name": "Xã Bằng Lang",
                        "code": 1246,
                        "codename": "xa_bang_lang",
                        "division_type": "xã",
                        "short_codename": "bang_lang"
                    },
                    {
                        "name": "Xã Yên Hà",
                        "code": 1249,
                        "codename": "xa_yen_ha",
                        "division_type": "xã",
                        "short_codename": "yen_ha"
                    },
                    {
                        "name": "Xã Hương Sơn",
                        "code": 1252,
                        "codename": "xa_huong_son",
                        "division_type": "xã",
                        "short_codename": "huong_son"
                    },
                    {
                        "name": "Xã Xuân Giang",
                        "code": 1255,
                        "codename": "xa_xuan_giang",
                        "division_type": "xã",
                        "short_codename": "xuan_giang"
                    },
                    {
                        "name": "Xã Nà Khương",
                        "code": 1258,
                        "codename": "xa_na_khuong",
                        "division_type": "xã",
                        "short_codename": "na_khuong"
                    },
                    {
                        "name": "Xã Tiên Yên",
                        "code": 1261,
                        "codename": "xa_tien_yen",
                        "division_type": "xã",
                        "short_codename": "tien_yen"
                    },
                    {
                        "name": "Xã Vĩ Thượng",
                        "code": 1264,
                        "codename": "xa_vi_thuong",
                        "division_type": "xã",
                        "short_codename": "vi_thuong"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Cao Bằng",
        "code": 4,
        "codename": "tinh_cao_bang",
        "division_type": "tỉnh",
        "phone_code": 206,
        "districts": [
            {
                "name": "Thành phố Cao Bằng",
                "code": 40,
                "codename": "thanh_pho_cao_bang",
                "division_type": "thành phố",
                "short_codename": "cao_bang",
                "wards": [
                    {
                        "name": "Phường Sông Hiến",
                        "code": 1267,
                        "codename": "phuong_song_hien",
                        "division_type": "phường",
                        "short_codename": "song_hien"
                    },
                    {
                        "name": "Phường Sông Bằng",
                        "code": 1270,
                        "codename": "phuong_song_bang",
                        "division_type": "phường",
                        "short_codename": "song_bang"
                    },
                    {
                        "name": "Phường Hợp Giang",
                        "code": 1273,
                        "codename": "phuong_hop_giang",
                        "division_type": "phường",
                        "short_codename": "hop_giang"
                    },
                    {
                        "name": "Phường Tân Giang",
                        "code": 1276,
                        "codename": "phuong_tan_giang",
                        "division_type": "phường",
                        "short_codename": "tan_giang"
                    },
                    {
                        "name": "Phường Ngọc Xuân",
                        "code": 1279,
                        "codename": "phuong_ngoc_xuan",
                        "division_type": "phường",
                        "short_codename": "ngoc_xuan"
                    },
                    {
                        "name": "Phường Đề Thám",
                        "code": 1282,
                        "codename": "phuong_de_tham",
                        "division_type": "phường",
                        "short_codename": "de_tham"
                    },
                    {
                        "name": "Phường Hoà Chung",
                        "code": 1285,
                        "codename": "phuong_hoa_chung",
                        "division_type": "phường",
                        "short_codename": "hoa_chung"
                    },
                    {
                        "name": "Phường Duyệt Trung",
                        "code": 1288,
                        "codename": "phuong_duyet_trung",
                        "division_type": "phường",
                        "short_codename": "duyet_trung"
                    },
                    {
                        "name": "Xã Vĩnh Quang",
                        "code": 1693,
                        "codename": "xa_vinh_quang",
                        "division_type": "xã",
                        "short_codename": "vinh_quang"
                    },
                    {
                        "name": "Xã Hưng Đạo",
                        "code": 1705,
                        "codename": "xa_hung_dao",
                        "division_type": "xã",
                        "short_codename": "hung_dao"
                    },
                    {
                        "name": "Xã Chu Trinh",
                        "code": 1720,
                        "codename": "xa_chu_trinh",
                        "division_type": "xã",
                        "short_codename": "chu_trinh"
                    }
                ]
            },
            {
                "name": "Huyện Bảo Lâm",
                "code": 42,
                "codename": "huyen_bao_lam",
                "division_type": "huyện",
                "short_codename": "bao_lam",
                "wards": [
                    {
                        "name": "Thị trấn Pác Miầu",
                        "code": 1290,
                        "codename": "thi_tran_pac_miau",
                        "division_type": "thị trấn",
                        "short_codename": "pac_miau"
                    },
                    {
                        "name": "Xã Đức Hạnh",
                        "code": 1291,
                        "codename": "xa_duc_hanh",
                        "division_type": "xã",
                        "short_codename": "duc_hanh"
                    },
                    {
                        "name": "Xã Lý Bôn",
                        "code": 1294,
                        "codename": "xa_ly_bon",
                        "division_type": "xã",
                        "short_codename": "ly_bon"
                    },
                    {
                        "name": "Xã Nam Cao",
                        "code": 1296,
                        "codename": "xa_nam_cao",
                        "division_type": "xã",
                        "short_codename": "nam_cao"
                    },
                    {
                        "name": "Xã Nam Quang",
                        "code": 1297,
                        "codename": "xa_nam_quang",
                        "division_type": "xã",
                        "short_codename": "nam_quang"
                    },
                    {
                        "name": "Xã Vĩnh Quang",
                        "code": 1300,
                        "codename": "xa_vinh_quang",
                        "division_type": "xã",
                        "short_codename": "vinh_quang"
                    },
                    {
                        "name": "Xã Quảng Lâm",
                        "code": 1303,
                        "codename": "xa_quang_lam",
                        "division_type": "xã",
                        "short_codename": "quang_lam"
                    },
                    {
                        "name": "Xã Thạch Lâm",
                        "code": 1304,
                        "codename": "xa_thach_lam",
                        "division_type": "xã",
                        "short_codename": "thach_lam"
                    },
                    {
                        "name": "Xã Vĩnh Phong",
                        "code": 1309,
                        "codename": "xa_vinh_phong",
                        "division_type": "xã",
                        "short_codename": "vinh_phong"
                    },
                    {
                        "name": "Xã Mông Ân",
                        "code": 1312,
                        "codename": "xa_mong_an",
                        "division_type": "xã",
                        "short_codename": "mong_an"
                    },
                    {
                        "name": "Xã Thái Học",
                        "code": 1315,
                        "codename": "xa_thai_hoc",
                        "division_type": "xã",
                        "short_codename": "thai_hoc"
                    },
                    {
                        "name": "Xã Thái Sơn",
                        "code": 1316,
                        "codename": "xa_thai_son",
                        "division_type": "xã",
                        "short_codename": "thai_son"
                    },
                    {
                        "name": "Xã Yên Thổ",
                        "code": 1318,
                        "codename": "xa_yen_tho",
                        "division_type": "xã",
                        "short_codename": "yen_tho"
                    }
                ]
            },
            {
                "name": "Huyện Bảo Lạc",
                "code": 43,
                "codename": "huyen_bao_lac",
                "division_type": "huyện",
                "short_codename": "bao_lac",
                "wards": [
                    {
                        "name": "Thị trấn Bảo Lạc",
                        "code": 1321,
                        "codename": "thi_tran_bao_lac",
                        "division_type": "thị trấn",
                        "short_codename": "bao_lac"
                    },
                    {
                        "name": "Xã Cốc Pàng",
                        "code": 1324,
                        "codename": "xa_coc_pang",
                        "division_type": "xã",
                        "short_codename": "coc_pang"
                    },
                    {
                        "name": "Xã Thượng Hà",
                        "code": 1327,
                        "codename": "xa_thuong_ha",
                        "division_type": "xã",
                        "short_codename": "thuong_ha"
                    },
                    {
                        "name": "Xã Cô Ba",
                        "code": 1330,
                        "codename": "xa_co_ba",
                        "division_type": "xã",
                        "short_codename": "co_ba"
                    },
                    {
                        "name": "Xã Bảo Toàn",
                        "code": 1333,
                        "codename": "xa_bao_toan",
                        "division_type": "xã",
                        "short_codename": "bao_toan"
                    },
                    {
                        "name": "Xã Khánh Xuân",
                        "code": 1336,
                        "codename": "xa_khanh_xuan",
                        "division_type": "xã",
                        "short_codename": "khanh_xuan"
                    },
                    {
                        "name": "Xã Xuân Trường",
                        "code": 1339,
                        "codename": "xa_xuan_truong",
                        "division_type": "xã",
                        "short_codename": "xuan_truong"
                    },
                    {
                        "name": "Xã Hồng Trị",
                        "code": 1342,
                        "codename": "xa_hong_tri",
                        "division_type": "xã",
                        "short_codename": "hong_tri"
                    },
                    {
                        "name": "Xã Kim Cúc",
                        "code": 1343,
                        "codename": "xa_kim_cuc",
                        "division_type": "xã",
                        "short_codename": "kim_cuc"
                    },
                    {
                        "name": "Xã Phan Thanh",
                        "code": 1345,
                        "codename": "xa_phan_thanh",
                        "division_type": "xã",
                        "short_codename": "phan_thanh"
                    },
                    {
                        "name": "Xã Hồng An",
                        "code": 1348,
                        "codename": "xa_hong_an",
                        "division_type": "xã",
                        "short_codename": "hong_an"
                    },
                    {
                        "name": "Xã Hưng Đạo",
                        "code": 1351,
                        "codename": "xa_hung_dao",
                        "division_type": "xã",
                        "short_codename": "hung_dao"
                    },
                    {
                        "name": "Xã Hưng Thịnh",
                        "code": 1352,
                        "codename": "xa_hung_thinh",
                        "division_type": "xã",
                        "short_codename": "hung_thinh"
                    },
                    {
                        "name": "Xã Huy Giáp",
                        "code": 1354,
                        "codename": "xa_huy_giap",
                        "division_type": "xã",
                        "short_codename": "huy_giap"
                    },
                    {
                        "name": "Xã Đình Phùng",
                        "code": 1357,
                        "codename": "xa_dinh_phung",
                        "division_type": "xã",
                        "short_codename": "dinh_phung"
                    },
                    {
                        "name": "Xã Sơn Lập",
                        "code": 1359,
                        "codename": "xa_son_lap",
                        "division_type": "xã",
                        "short_codename": "son_lap"
                    },
                    {
                        "name": "Xã Sơn Lộ",
                        "code": 1360,
                        "codename": "xa_son_lo",
                        "division_type": "xã",
                        "short_codename": "son_lo"
                    }
                ]
            },
            {
                "name": "Huyện Hà Quảng",
                "code": 45,
                "codename": "huyen_ha_quang",
                "division_type": "huyện",
                "short_codename": "ha_quang",
                "wards": [
                    {
                        "name": "Thị trấn Thông Nông",
                        "code": 1363,
                        "codename": "thi_tran_thong_nong",
                        "division_type": "thị trấn",
                        "short_codename": "thong_nong"
                    },
                    {
                        "name": "Xã Cần Yên",
                        "code": 1366,
                        "codename": "xa_can_yen",
                        "division_type": "xã",
                        "short_codename": "can_yen"
                    },
                    {
                        "name": "Xã Cần Nông",
                        "code": 1367,
                        "codename": "xa_can_nong",
                        "division_type": "xã",
                        "short_codename": "can_nong"
                    },
                    {
                        "name": "Xã Lương Thông",
                        "code": 1372,
                        "codename": "xa_luong_thong",
                        "division_type": "xã",
                        "short_codename": "luong_thong"
                    },
                    {
                        "name": "Xã Đa Thông",
                        "code": 1375,
                        "codename": "xa_da_thong",
                        "division_type": "xã",
                        "short_codename": "da_thong"
                    },
                    {
                        "name": "Xã Ngọc Động",
                        "code": 1378,
                        "codename": "xa_ngoc_dong",
                        "division_type": "xã",
                        "short_codename": "ngoc_dong"
                    },
                    {
                        "name": "Xã Yên Sơn",
                        "code": 1381,
                        "codename": "xa_yen_son",
                        "division_type": "xã",
                        "short_codename": "yen_son"
                    },
                    {
                        "name": "Xã Lương Can",
                        "code": 1384,
                        "codename": "xa_luong_can",
                        "division_type": "xã",
                        "short_codename": "luong_can"
                    },
                    {
                        "name": "Xã Thanh Long",
                        "code": 1387,
                        "codename": "xa_thanh_long",
                        "division_type": "xã",
                        "short_codename": "thanh_long"
                    },
                    {
                        "name": "Thị trấn Xuân Hòa",
                        "code": 1392,
                        "codename": "thi_tran_xuan_hoa",
                        "division_type": "thị trấn",
                        "short_codename": "xuan_hoa"
                    },
                    {
                        "name": "Xã Lũng Nặm",
                        "code": 1393,
                        "codename": "xa_lung_nam",
                        "division_type": "xã",
                        "short_codename": "lung_nam"
                    },
                    {
                        "name": "Xã Trường Hà",
                        "code": 1399,
                        "codename": "xa_truong_ha",
                        "division_type": "xã",
                        "short_codename": "truong_ha"
                    },
                    {
                        "name": "Xã Cải Viên",
                        "code": 1402,
                        "codename": "xa_cai_vien",
                        "division_type": "xã",
                        "short_codename": "cai_vien"
                    },
                    {
                        "name": "Xã Nội Thôn",
                        "code": 1411,
                        "codename": "xa_noi_thon",
                        "division_type": "xã",
                        "short_codename": "noi_thon"
                    },
                    {
                        "name": "Xã Tổng Cọt",
                        "code": 1414,
                        "codename": "xa_tong_cot",
                        "division_type": "xã",
                        "short_codename": "tong_cot"
                    },
                    {
                        "name": "Xã Sóc Hà",
                        "code": 1417,
                        "codename": "xa_soc_ha",
                        "division_type": "xã",
                        "short_codename": "soc_ha"
                    },
                    {
                        "name": "Xã Thượng Thôn",
                        "code": 1420,
                        "codename": "xa_thuong_thon",
                        "division_type": "xã",
                        "short_codename": "thuong_thon"
                    },
                    {
                        "name": "Xã Hồng Sỹ",
                        "code": 1429,
                        "codename": "xa_hong_sy",
                        "division_type": "xã",
                        "short_codename": "hong_sy"
                    },
                    {
                        "name": "Xã Quý Quân",
                        "code": 1432,
                        "codename": "xa_quy_quan",
                        "division_type": "xã",
                        "short_codename": "quy_quan"
                    },
                    {
                        "name": "Xã Mã Ba",
                        "code": 1435,
                        "codename": "xa_ma_ba",
                        "division_type": "xã",
                        "short_codename": "ma_ba"
                    },
                    {
                        "name": "Xã Ngọc Đào",
                        "code": 1438,
                        "codename": "xa_ngoc_dao",
                        "division_type": "xã",
                        "short_codename": "ngoc_dao"
                    }
                ]
            },
            {
                "name": "Huyện Trùng Khánh",
                "code": 47,
                "codename": "huyen_trung_khanh",
                "division_type": "huyện",
                "short_codename": "trung_khanh",
                "wards": [
                    {
                        "name": "Thị trấn Trà Lĩnh",
                        "code": 1447,
                        "codename": "thi_tran_tra_linh",
                        "division_type": "thị trấn",
                        "short_codename": "tra_linh"
                    },
                    {
                        "name": "Xã Tri Phương",
                        "code": 1453,
                        "codename": "xa_tri_phuong",
                        "division_type": "xã",
                        "short_codename": "tri_phuong"
                    },
                    {
                        "name": "Xã Quang Hán",
                        "code": 1456,
                        "codename": "xa_quang_han",
                        "division_type": "xã",
                        "short_codename": "quang_han"
                    },
                    {
                        "name": "Xã Xuân Nội",
                        "code": 1462,
                        "codename": "xa_xuan_noi",
                        "division_type": "xã",
                        "short_codename": "xuan_noi"
                    },
                    {
                        "name": "Xã Quang Trung",
                        "code": 1465,
                        "codename": "xa_quang_trung",
                        "division_type": "xã",
                        "short_codename": "quang_trung"
                    },
                    {
                        "name": "Xã Quang Vinh",
                        "code": 1468,
                        "codename": "xa_quang_vinh",
                        "division_type": "xã",
                        "short_codename": "quang_vinh"
                    },
                    {
                        "name": "Xã Cao Chương",
                        "code": 1471,
                        "codename": "xa_cao_chuong",
                        "division_type": "xã",
                        "short_codename": "cao_chuong"
                    },
                    {
                        "name": "Thị trấn Trùng Khánh",
                        "code": 1477,
                        "codename": "thi_tran_trung_khanh",
                        "division_type": "thị trấn",
                        "short_codename": "trung_khanh"
                    },
                    {
                        "name": "Xã Ngọc Khê",
                        "code": 1480,
                        "codename": "xa_ngoc_khe",
                        "division_type": "xã",
                        "short_codename": "ngoc_khe"
                    },
                    {
                        "name": "Xã Ngọc Côn",
                        "code": 1481,
                        "codename": "xa_ngoc_con",
                        "division_type": "xã",
                        "short_codename": "ngoc_con"
                    },
                    {
                        "name": "Xã Phong Nậm",
                        "code": 1483,
                        "codename": "xa_phong_nam",
                        "division_type": "xã",
                        "short_codename": "phong_nam"
                    },
                    {
                        "name": "Xã Đình Phong",
                        "code": 1489,
                        "codename": "xa_dinh_phong",
                        "division_type": "xã",
                        "short_codename": "dinh_phong"
                    },
                    {
                        "name": "Xã Đàm Thuỷ",
                        "code": 1495,
                        "codename": "xa_dam_thuy",
                        "division_type": "xã",
                        "short_codename": "dam_thuy"
                    },
                    {
                        "name": "Xã Khâm Thành",
                        "code": 1498,
                        "codename": "xa_kham_thanh",
                        "division_type": "xã",
                        "short_codename": "kham_thanh"
                    },
                    {
                        "name": "Xã Chí Viễn",
                        "code": 1501,
                        "codename": "xa_chi_vien",
                        "division_type": "xã",
                        "short_codename": "chi_vien"
                    },
                    {
                        "name": "Xã Lăng Hiếu",
                        "code": 1504,
                        "codename": "xa_lang_hieu",
                        "division_type": "xã",
                        "short_codename": "lang_hieu"
                    },
                    {
                        "name": "Xã Phong Châu",
                        "code": 1507,
                        "codename": "xa_phong_chau",
                        "division_type": "xã",
                        "short_codename": "phong_chau"
                    },
                    {
                        "name": "Xã Trung Phúc",
                        "code": 1516,
                        "codename": "xa_trung_phuc",
                        "division_type": "xã",
                        "short_codename": "trung_phuc"
                    },
                    {
                        "name": "Xã Cao Thăng",
                        "code": 1519,
                        "codename": "xa_cao_thang",
                        "division_type": "xã",
                        "short_codename": "cao_thang"
                    },
                    {
                        "name": "Xã Đức Hồng",
                        "code": 1522,
                        "codename": "xa_duc_hong",
                        "division_type": "xã",
                        "short_codename": "duc_hong"
                    },
                    {
                        "name": "Xã Đoài Dương",
                        "code": 1525,
                        "codename": "xa_doai_duong",
                        "division_type": "xã",
                        "short_codename": "doai_duong"
                    }
                ]
            },
            {
                "name": "Huyện Hạ Lang",
                "code": 48,
                "codename": "huyen_ha_lang",
                "division_type": "huyện",
                "short_codename": "ha_lang",
                "wards": [
                    {
                        "name": "Xã Minh Long",
                        "code": 1534,
                        "codename": "xa_minh_long",
                        "division_type": "xã",
                        "short_codename": "minh_long"
                    },
                    {
                        "name": "Xã Lý Quốc",
                        "code": 1537,
                        "codename": "xa_ly_quoc",
                        "division_type": "xã",
                        "short_codename": "ly_quoc"
                    },
                    {
                        "name": "Xã Thắng Lợi",
                        "code": 1540,
                        "codename": "xa_thang_loi",
                        "division_type": "xã",
                        "short_codename": "thang_loi"
                    },
                    {
                        "name": "Xã Đồng Loan",
                        "code": 1543,
                        "codename": "xa_dong_loan",
                        "division_type": "xã",
                        "short_codename": "dong_loan"
                    },
                    {
                        "name": "Xã Đức Quang",
                        "code": 1546,
                        "codename": "xa_duc_quang",
                        "division_type": "xã",
                        "short_codename": "duc_quang"
                    },
                    {
                        "name": "Xã Kim Loan",
                        "code": 1549,
                        "codename": "xa_kim_loan",
                        "division_type": "xã",
                        "short_codename": "kim_loan"
                    },
                    {
                        "name": "Xã Quang Long",
                        "code": 1552,
                        "codename": "xa_quang_long",
                        "division_type": "xã",
                        "short_codename": "quang_long"
                    },
                    {
                        "name": "Xã An Lạc",
                        "code": 1555,
                        "codename": "xa_an_lac",
                        "division_type": "xã",
                        "short_codename": "an_lac"
                    },
                    {
                        "name": "Thị trấn Thanh Nhật",
                        "code": 1558,
                        "codename": "thi_tran_thanh_nhat",
                        "division_type": "thị trấn",
                        "short_codename": "thanh_nhat"
                    },
                    {
                        "name": "Xã Vinh Quý",
                        "code": 1561,
                        "codename": "xa_vinh_quy",
                        "division_type": "xã",
                        "short_codename": "vinh_quy"
                    },
                    {
                        "name": "Xã Thống Nhất",
                        "code": 1564,
                        "codename": "xa_thong_nhat",
                        "division_type": "xã",
                        "short_codename": "thong_nhat"
                    },
                    {
                        "name": "Xã Cô Ngân",
                        "code": 1567,
                        "codename": "xa_co_ngan",
                        "division_type": "xã",
                        "short_codename": "co_ngan"
                    },
                    {
                        "name": "Xã Thị Hoa",
                        "code": 1573,
                        "codename": "xa_thi_hoa",
                        "division_type": "xã",
                        "short_codename": "thi_hoa"
                    }
                ]
            },
            {
                "name": "Huyện Quảng Hòa",
                "code": 49,
                "codename": "huyen_quang_hoa",
                "division_type": "huyện",
                "short_codename": "quang_hoa",
                "wards": [
                    {
                        "name": "Xã Quốc Toản",
                        "code": 1474,
                        "codename": "xa_quoc_toan",
                        "division_type": "xã",
                        "short_codename": "quoc_toan"
                    },
                    {
                        "name": "Thị trấn Quảng Uyên",
                        "code": 1576,
                        "codename": "thi_tran_quang_uyen",
                        "division_type": "thị trấn",
                        "short_codename": "quang_uyen"
                    },
                    {
                        "name": "Xã Phi Hải",
                        "code": 1579,
                        "codename": "xa_phi_hai",
                        "division_type": "xã",
                        "short_codename": "phi_hai"
                    },
                    {
                        "name": "Xã Quảng Hưng",
                        "code": 1582,
                        "codename": "xa_quang_hung",
                        "division_type": "xã",
                        "short_codename": "quang_hung"
                    },
                    {
                        "name": "Xã Độc Lập",
                        "code": 1594,
                        "codename": "xa_doc_lap",
                        "division_type": "xã",
                        "short_codename": "doc_lap"
                    },
                    {
                        "name": "Xã Cai Bộ",
                        "code": 1597,
                        "codename": "xa_cai_bo",
                        "division_type": "xã",
                        "short_codename": "cai_bo"
                    },
                    {
                        "name": "Xã Phúc Sen",
                        "code": 1603,
                        "codename": "xa_phuc_sen",
                        "division_type": "xã",
                        "short_codename": "phuc_sen"
                    },
                    {
                        "name": "Xã Chí Thảo",
                        "code": 1606,
                        "codename": "xa_chi_thao",
                        "division_type": "xã",
                        "short_codename": "chi_thao"
                    },
                    {
                        "name": "Xã Tự Do",
                        "code": 1609,
                        "codename": "xa_tu_do",
                        "division_type": "xã",
                        "short_codename": "tu_do"
                    },
                    {
                        "name": "Xã Hồng Quang",
                        "code": 1615,
                        "codename": "xa_hong_quang",
                        "division_type": "xã",
                        "short_codename": "hong_quang"
                    },
                    {
                        "name": "Xã Ngọc Động",
                        "code": 1618,
                        "codename": "xa_ngoc_dong",
                        "division_type": "xã",
                        "short_codename": "ngoc_dong"
                    },
                    {
                        "name": "Xã Hạnh Phúc",
                        "code": 1624,
                        "codename": "xa_hanh_phuc",
                        "division_type": "xã",
                        "short_codename": "hanh_phuc"
                    },
                    {
                        "name": "Thị trấn Tà Lùng",
                        "code": 1627,
                        "codename": "thi_tran_ta_lung",
                        "division_type": "thị trấn",
                        "short_codename": "ta_lung"
                    },
                    {
                        "name": "Xã Bế Văn Đàn",
                        "code": 1630,
                        "codename": "xa_be_van_dan",
                        "division_type": "xã",
                        "short_codename": "be_van_dan"
                    },
                    {
                        "name": "Xã Cách Linh",
                        "code": 1636,
                        "codename": "xa_cach_linh",
                        "division_type": "xã",
                        "short_codename": "cach_linh"
                    },
                    {
                        "name": "Xã Đại Sơn",
                        "code": 1639,
                        "codename": "xa_dai_son",
                        "division_type": "xã",
                        "short_codename": "dai_son"
                    },
                    {
                        "name": "Xã Tiên Thành",
                        "code": 1645,
                        "codename": "xa_tien_thanh",
                        "division_type": "xã",
                        "short_codename": "tien_thanh"
                    },
                    {
                        "name": "Thị trấn Hoà Thuận",
                        "code": 1648,
                        "codename": "thi_tran_hoa_thuan",
                        "division_type": "thị trấn",
                        "short_codename": "hoa_thuan"
                    },
                    {
                        "name": "Xã Mỹ Hưng",
                        "code": 1651,
                        "codename": "xa_my_hung",
                        "division_type": "xã",
                        "short_codename": "my_hung"
                    }
                ]
            },
            {
                "name": "Huyện Hoà An",
                "code": 51,
                "codename": "huyen_hoa_an",
                "division_type": "huyện",
                "short_codename": "hoa_an",
                "wards": [
                    {
                        "name": "Thị trấn Nước Hai",
                        "code": 1654,
                        "codename": "thi_tran_nuoc_hai",
                        "division_type": "thị trấn",
                        "short_codename": "nuoc_hai"
                    },
                    {
                        "name": "Xã Dân Chủ",
                        "code": 1657,
                        "codename": "xa_dan_chu",
                        "division_type": "xã",
                        "short_codename": "dan_chu"
                    },
                    {
                        "name": "Xã Nam Tuấn",
                        "code": 1660,
                        "codename": "xa_nam_tuan",
                        "division_type": "xã",
                        "short_codename": "nam_tuan"
                    },
                    {
                        "name": "Xã Đại Tiến",
                        "code": 1666,
                        "codename": "xa_dai_tien",
                        "division_type": "xã",
                        "short_codename": "dai_tien"
                    },
                    {
                        "name": "Xã Đức Long",
                        "code": 1669,
                        "codename": "xa_duc_long",
                        "division_type": "xã",
                        "short_codename": "duc_long"
                    },
                    {
                        "name": "Xã Ngũ Lão",
                        "code": 1672,
                        "codename": "xa_ngu_lao",
                        "division_type": "xã",
                        "short_codename": "ngu_lao"
                    },
                    {
                        "name": "Xã Trương Lương",
                        "code": 1675,
                        "codename": "xa_truong_luong",
                        "division_type": "xã",
                        "short_codename": "truong_luong"
                    },
                    {
                        "name": "Xã Hồng Việt",
                        "code": 1687,
                        "codename": "xa_hong_viet",
                        "division_type": "xã",
                        "short_codename": "hong_viet"
                    },
                    {
                        "name": "Xã Hoàng Tung",
                        "code": 1696,
                        "codename": "xa_hoang_tung",
                        "division_type": "xã",
                        "short_codename": "hoang_tung"
                    },
                    {
                        "name": "Xã Nguyễn Huệ",
                        "code": 1699,
                        "codename": "xa_nguyen_hue",
                        "division_type": "xã",
                        "short_codename": "nguyen_hue"
                    },
                    {
                        "name": "Xã Quang Trung",
                        "code": 1702,
                        "codename": "xa_quang_trung",
                        "division_type": "xã",
                        "short_codename": "quang_trung"
                    },
                    {
                        "name": "Xã Bạch Đằng",
                        "code": 1708,
                        "codename": "xa_bach_dang",
                        "division_type": "xã",
                        "short_codename": "bach_dang"
                    },
                    {
                        "name": "Xã Bình Dương",
                        "code": 1711,
                        "codename": "xa_binh_duong",
                        "division_type": "xã",
                        "short_codename": "binh_duong"
                    },
                    {
                        "name": "Xã Lê Chung",
                        "code": 1714,
                        "codename": "xa_le_chung",
                        "division_type": "xã",
                        "short_codename": "le_chung"
                    },
                    {
                        "name": "Xã Hồng Nam",
                        "code": 1723,
                        "codename": "xa_hong_nam",
                        "division_type": "xã",
                        "short_codename": "hong_nam"
                    }
                ]
            },
            {
                "name": "Huyện Nguyên Bình",
                "code": 52,
                "codename": "huyen_nguyen_binh",
                "division_type": "huyện",
                "short_codename": "nguyen_binh",
                "wards": [
                    {
                        "name": "Thị trấn Nguyên Bình",
                        "code": 1726,
                        "codename": "thi_tran_nguyen_binh",
                        "division_type": "thị trấn",
                        "short_codename": "nguyen_binh"
                    },
                    {
                        "name": "Thị trấn Tĩnh Túc",
                        "code": 1729,
                        "codename": "thi_tran_tinh_tuc",
                        "division_type": "thị trấn",
                        "short_codename": "tinh_tuc"
                    },
                    {
                        "name": "Xã Yên Lạc",
                        "code": 1732,
                        "codename": "xa_yen_lac",
                        "division_type": "xã",
                        "short_codename": "yen_lac"
                    },
                    {
                        "name": "Xã Triệu Nguyên",
                        "code": 1735,
                        "codename": "xa_trieu_nguyen",
                        "division_type": "xã",
                        "short_codename": "trieu_nguyen"
                    },
                    {
                        "name": "Xã Ca Thành",
                        "code": 1738,
                        "codename": "xa_ca_thanh",
                        "division_type": "xã",
                        "short_codename": "ca_thanh"
                    },
                    {
                        "name": "Xã Vũ Nông",
                        "code": 1744,
                        "codename": "xa_vu_nong",
                        "division_type": "xã",
                        "short_codename": "vu_nong"
                    },
                    {
                        "name": "Xã Minh Tâm",
                        "code": 1747,
                        "codename": "xa_minh_tam",
                        "division_type": "xã",
                        "short_codename": "minh_tam"
                    },
                    {
                        "name": "Xã Thể Dục",
                        "code": 1750,
                        "codename": "xa_the_duc",
                        "division_type": "xã",
                        "short_codename": "the_duc"
                    },
                    {
                        "name": "Xã Mai Long",
                        "code": 1756,
                        "codename": "xa_mai_long",
                        "division_type": "xã",
                        "short_codename": "mai_long"
                    },
                    {
                        "name": "Xã Vũ Minh",
                        "code": 1762,
                        "codename": "xa_vu_minh",
                        "division_type": "xã",
                        "short_codename": "vu_minh"
                    },
                    {
                        "name": "Xã Hoa Thám",
                        "code": 1765,
                        "codename": "xa_hoa_tham",
                        "division_type": "xã",
                        "short_codename": "hoa_tham"
                    },
                    {
                        "name": "Xã Phan Thanh",
                        "code": 1768,
                        "codename": "xa_phan_thanh",
                        "division_type": "xã",
                        "short_codename": "phan_thanh"
                    },
                    {
                        "name": "Xã Quang Thành",
                        "code": 1771,
                        "codename": "xa_quang_thanh",
                        "division_type": "xã",
                        "short_codename": "quang_thanh"
                    },
                    {
                        "name": "Xã Tam Kim",
                        "code": 1774,
                        "codename": "xa_tam_kim",
                        "division_type": "xã",
                        "short_codename": "tam_kim"
                    },
                    {
                        "name": "Xã Thành Công",
                        "code": 1777,
                        "codename": "xa_thanh_cong",
                        "division_type": "xã",
                        "short_codename": "thanh_cong"
                    },
                    {
                        "name": "Xã Thịnh Vượng",
                        "code": 1780,
                        "codename": "xa_thinh_vuong",
                        "division_type": "xã",
                        "short_codename": "thinh_vuong"
                    },
                    {
                        "name": "Xã Hưng Đạo",
                        "code": 1783,
                        "codename": "xa_hung_dao",
                        "division_type": "xã",
                        "short_codename": "hung_dao"
                    }
                ]
            },
            {
                "name": "Huyện Thạch An",
                "code": 53,
                "codename": "huyen_thach_an",
                "division_type": "huyện",
                "short_codename": "thach_an",
                "wards": [
                    {
                        "name": "Thị trấn Đông Khê",
                        "code": 1786,
                        "codename": "thi_tran_dong_khe",
                        "division_type": "thị trấn",
                        "short_codename": "dong_khe"
                    },
                    {
                        "name": "Xã Canh Tân",
                        "code": 1789,
                        "codename": "xa_canh_tan",
                        "division_type": "xã",
                        "short_codename": "canh_tan"
                    },
                    {
                        "name": "Xã Kim Đồng",
                        "code": 1792,
                        "codename": "xa_kim_dong",
                        "division_type": "xã",
                        "short_codename": "kim_dong"
                    },
                    {
                        "name": "Xã Minh Khai",
                        "code": 1795,
                        "codename": "xa_minh_khai",
                        "division_type": "xã",
                        "short_codename": "minh_khai"
                    },
                    {
                        "name": "Xã Đức Thông",
                        "code": 1801,
                        "codename": "xa_duc_thong",
                        "division_type": "xã",
                        "short_codename": "duc_thong"
                    },
                    {
                        "name": "Xã Thái Cường",
                        "code": 1804,
                        "codename": "xa_thai_cuong",
                        "division_type": "xã",
                        "short_codename": "thai_cuong"
                    },
                    {
                        "name": "Xã Vân Trình",
                        "code": 1807,
                        "codename": "xa_van_trinh",
                        "division_type": "xã",
                        "short_codename": "van_trinh"
                    },
                    {
                        "name": "Xã Thụy Hùng",
                        "code": 1810,
                        "codename": "xa_thuy_hung",
                        "division_type": "xã",
                        "short_codename": "thuy_hung"
                    },
                    {
                        "name": "Xã Quang Trọng",
                        "code": 1813,
                        "codename": "xa_quang_trong",
                        "division_type": "xã",
                        "short_codename": "quang_trong"
                    },
                    {
                        "name": "Xã Trọng Con",
                        "code": 1816,
                        "codename": "xa_trong_con",
                        "division_type": "xã",
                        "short_codename": "trong_con"
                    },
                    {
                        "name": "Xã Lê Lai",
                        "code": 1819,
                        "codename": "xa_le_lai",
                        "division_type": "xã",
                        "short_codename": "le_lai"
                    },
                    {
                        "name": "Xã Đức Long",
                        "code": 1822,
                        "codename": "xa_duc_long",
                        "division_type": "xã",
                        "short_codename": "duc_long"
                    },
                    {
                        "name": "Xã Lê Lợi",
                        "code": 1828,
                        "codename": "xa_le_loi",
                        "division_type": "xã",
                        "short_codename": "le_loi"
                    },
                    {
                        "name": "Xã Đức Xuân",
                        "code": 1831,
                        "codename": "xa_duc_xuan",
                        "division_type": "xã",
                        "short_codename": "duc_xuan"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Bắc Kạn",
        "code": 6,
        "codename": "tinh_bac_kan",
        "division_type": "tỉnh",
        "phone_code": 209,
        "districts": [
            {
                "name": "Thành Phố Bắc Kạn",
                "code": 58,
                "codename": "thanh_pho_bac_kan",
                "division_type": "thành phố",
                "short_codename": "bac_kan",
                "wards": [
                    {
                        "name": "Phường Nguyễn Thị Minh Khai",
                        "code": 1834,
                        "codename": "phuong_nguyen_thi_minh_khai",
                        "division_type": "phường",
                        "short_codename": "nguyen_thi_minh_khai"
                    },
                    {
                        "name": "Phường Sông Cầu",
                        "code": 1837,
                        "codename": "phuong_song_cau",
                        "division_type": "phường",
                        "short_codename": "song_cau"
                    },
                    {
                        "name": "Phường Đức Xuân",
                        "code": 1840,
                        "codename": "phuong_duc_xuan",
                        "division_type": "phường",
                        "short_codename": "duc_xuan"
                    },
                    {
                        "name": "Phường Phùng Chí Kiên",
                        "code": 1843,
                        "codename": "phuong_phung_chi_kien",
                        "division_type": "phường",
                        "short_codename": "phung_chi_kien"
                    },
                    {
                        "name": "Phường Huyền Tụng",
                        "code": 1846,
                        "codename": "phuong_huyen_tung",
                        "division_type": "phường",
                        "short_codename": "huyen_tung"
                    },
                    {
                        "name": "Xã Dương Quang",
                        "code": 1849,
                        "codename": "xa_duong_quang",
                        "division_type": "xã",
                        "short_codename": "duong_quang"
                    },
                    {
                        "name": "Xã Nông Thượng",
                        "code": 1852,
                        "codename": "xa_nong_thuong",
                        "division_type": "xã",
                        "short_codename": "nong_thuong"
                    },
                    {
                        "name": "Phường Xuất Hóa",
                        "code": 1855,
                        "codename": "phuong_xuat_hoa",
                        "division_type": "phường",
                        "short_codename": "xuat_hoa"
                    }
                ]
            },
            {
                "name": "Huyện Pác Nặm",
                "code": 60,
                "codename": "huyen_pac_nam",
                "division_type": "huyện",
                "short_codename": "pac_nam",
                "wards": [
                    {
                        "name": "Xã Bằng Thành",
                        "code": 1858,
                        "codename": "xa_bang_thanh",
                        "division_type": "xã",
                        "short_codename": "bang_thanh"
                    },
                    {
                        "name": "Xã Nhạn Môn",
                        "code": 1861,
                        "codename": "xa_nhan_mon",
                        "division_type": "xã",
                        "short_codename": "nhan_mon"
                    },
                    {
                        "name": "Xã Bộc Bố",
                        "code": 1864,
                        "codename": "xa_boc_bo",
                        "division_type": "xã",
                        "short_codename": "boc_bo"
                    },
                    {
                        "name": "Xã Công Bằng",
                        "code": 1867,
                        "codename": "xa_cong_bang",
                        "division_type": "xã",
                        "short_codename": "cong_bang"
                    },
                    {
                        "name": "Xã Giáo Hiệu",
                        "code": 1870,
                        "codename": "xa_giao_hieu",
                        "division_type": "xã",
                        "short_codename": "giao_hieu"
                    },
                    {
                        "name": "Xã Xuân La",
                        "code": 1873,
                        "codename": "xa_xuan_la",
                        "division_type": "xã",
                        "short_codename": "xuan_la"
                    },
                    {
                        "name": "Xã An Thắng",
                        "code": 1876,
                        "codename": "xa_an_thang",
                        "division_type": "xã",
                        "short_codename": "an_thang"
                    },
                    {
                        "name": "Xã Cổ Linh",
                        "code": 1879,
                        "codename": "xa_co_linh",
                        "division_type": "xã",
                        "short_codename": "co_linh"
                    },
                    {
                        "name": "Xã Nghiên Loan",
                        "code": 1882,
                        "codename": "xa_nghien_loan",
                        "division_type": "xã",
                        "short_codename": "nghien_loan"
                    },
                    {
                        "name": "Xã Cao Tân",
                        "code": 1885,
                        "codename": "xa_cao_tan",
                        "division_type": "xã",
                        "short_codename": "cao_tan"
                    }
                ]
            },
            {
                "name": "Huyện Ba Bể",
                "code": 61,
                "codename": "huyen_ba_be",
                "division_type": "huyện",
                "short_codename": "ba_be",
                "wards": [
                    {
                        "name": "Thị trấn Chợ Rã",
                        "code": 1888,
                        "codename": "thi_tran_cho_ra",
                        "division_type": "thị trấn",
                        "short_codename": "cho_ra"
                    },
                    {
                        "name": "Xã Bành Trạch",
                        "code": 1891,
                        "codename": "xa_banh_trach",
                        "division_type": "xã",
                        "short_codename": "banh_trach"
                    },
                    {
                        "name": "Xã Phúc Lộc",
                        "code": 1894,
                        "codename": "xa_phuc_loc",
                        "division_type": "xã",
                        "short_codename": "phuc_loc"
                    },
                    {
                        "name": "Xã Hà Hiệu",
                        "code": 1897,
                        "codename": "xa_ha_hieu",
                        "division_type": "xã",
                        "short_codename": "ha_hieu"
                    },
                    {
                        "name": "Xã Cao Thượng",
                        "code": 1900,
                        "codename": "xa_cao_thuong",
                        "division_type": "xã",
                        "short_codename": "cao_thuong"
                    },
                    {
                        "name": "Xã Khang Ninh",
                        "code": 1906,
                        "codename": "xa_khang_ninh",
                        "division_type": "xã",
                        "short_codename": "khang_ninh"
                    },
                    {
                        "name": "Xã Nam Mẫu",
                        "code": 1909,
                        "codename": "xa_nam_mau",
                        "division_type": "xã",
                        "short_codename": "nam_mau"
                    },
                    {
                        "name": "Xã Thượng Giáo",
                        "code": 1912,
                        "codename": "xa_thuong_giao",
                        "division_type": "xã",
                        "short_codename": "thuong_giao"
                    },
                    {
                        "name": "Xã Địa Linh",
                        "code": 1915,
                        "codename": "xa_dia_linh",
                        "division_type": "xã",
                        "short_codename": "dia_linh"
                    },
                    {
                        "name": "Xã Yến Dương",
                        "code": 1918,
                        "codename": "xa_yen_duong",
                        "division_type": "xã",
                        "short_codename": "yen_duong"
                    },
                    {
                        "name": "Xã Chu Hương",
                        "code": 1921,
                        "codename": "xa_chu_huong",
                        "division_type": "xã",
                        "short_codename": "chu_huong"
                    },
                    {
                        "name": "Xã Quảng Khê",
                        "code": 1924,
                        "codename": "xa_quang_khe",
                        "division_type": "xã",
                        "short_codename": "quang_khe"
                    },
                    {
                        "name": "Xã Mỹ Phương",
                        "code": 1927,
                        "codename": "xa_my_phuong",
                        "division_type": "xã",
                        "short_codename": "my_phuong"
                    },
                    {
                        "name": "Xã Hoàng Trĩ",
                        "code": 1930,
                        "codename": "xa_hoang_tri",
                        "division_type": "xã",
                        "short_codename": "hoang_tri"
                    },
                    {
                        "name": "Xã Đồng Phúc",
                        "code": 1933,
                        "codename": "xa_dong_phuc",
                        "division_type": "xã",
                        "short_codename": "dong_phuc"
                    }
                ]
            },
            {
                "name": "Huyện Ngân Sơn",
                "code": 62,
                "codename": "huyen_ngan_son",
                "division_type": "huyện",
                "short_codename": "ngan_son",
                "wards": [
                    {
                        "name": "Thị trấn Nà Phặc",
                        "code": 1936,
                        "codename": "thi_tran_na_phac",
                        "division_type": "thị trấn",
                        "short_codename": "na_phac"
                    },
                    {
                        "name": "Xã Thượng Ân",
                        "code": 1939,
                        "codename": "xa_thuong_an",
                        "division_type": "xã",
                        "short_codename": "thuong_an"
                    },
                    {
                        "name": "Xã Bằng Vân",
                        "code": 1942,
                        "codename": "xa_bang_van",
                        "division_type": "xã",
                        "short_codename": "bang_van"
                    },
                    {
                        "name": "Xã Cốc Đán",
                        "code": 1945,
                        "codename": "xa_coc_dan",
                        "division_type": "xã",
                        "short_codename": "coc_dan"
                    },
                    {
                        "name": "Xã Trung Hoà",
                        "code": 1948,
                        "codename": "xa_trung_hoa",
                        "division_type": "xã",
                        "short_codename": "trung_hoa"
                    },
                    {
                        "name": "Xã Đức Vân",
                        "code": 1951,
                        "codename": "xa_duc_van",
                        "division_type": "xã",
                        "short_codename": "duc_van"
                    },
                    {
                        "name": "Xã Vân Tùng",
                        "code": 1954,
                        "codename": "xa_van_tung",
                        "division_type": "xã",
                        "short_codename": "van_tung"
                    },
                    {
                        "name": "Xã Thượng Quan",
                        "code": 1957,
                        "codename": "xa_thuong_quan",
                        "division_type": "xã",
                        "short_codename": "thuong_quan"
                    },
                    {
                        "name": "Xã Hiệp Lực",
                        "code": 1960,
                        "codename": "xa_hiep_luc",
                        "division_type": "xã",
                        "short_codename": "hiep_luc"
                    },
                    {
                        "name": "Xã Thuần Mang",
                        "code": 1963,
                        "codename": "xa_thuan_mang",
                        "division_type": "xã",
                        "short_codename": "thuan_mang"
                    }
                ]
            },
            {
                "name": "Huyện Bạch Thông",
                "code": 63,
                "codename": "huyen_bach_thong",
                "division_type": "huyện",
                "short_codename": "bach_thong",
                "wards": [
                    {
                        "name": "Thị trấn Phủ Thông",
                        "code": 1969,
                        "codename": "thi_tran_phu_thong",
                        "division_type": "thị trấn",
                        "short_codename": "phu_thong"
                    },
                    {
                        "name": "Xã Vi Hương",
                        "code": 1975,
                        "codename": "xa_vi_huong",
                        "division_type": "xã",
                        "short_codename": "vi_huong"
                    },
                    {
                        "name": "Xã Sĩ Bình",
                        "code": 1978,
                        "codename": "xa_si_binh",
                        "division_type": "xã",
                        "short_codename": "si_binh"
                    },
                    {
                        "name": "Xã Vũ Muộn",
                        "code": 1981,
                        "codename": "xa_vu_muon",
                        "division_type": "xã",
                        "short_codename": "vu_muon"
                    },
                    {
                        "name": "Xã Đôn Phong",
                        "code": 1984,
                        "codename": "xa_don_phong",
                        "division_type": "xã",
                        "short_codename": "don_phong"
                    },
                    {
                        "name": "Xã Lục Bình",
                        "code": 1990,
                        "codename": "xa_luc_binh",
                        "division_type": "xã",
                        "short_codename": "luc_binh"
                    },
                    {
                        "name": "Xã Tân Tú",
                        "code": 1993,
                        "codename": "xa_tan_tu",
                        "division_type": "xã",
                        "short_codename": "tan_tu"
                    },
                    {
                        "name": "Xã Nguyên Phúc",
                        "code": 1999,
                        "codename": "xa_nguyen_phuc",
                        "division_type": "xã",
                        "short_codename": "nguyen_phuc"
                    },
                    {
                        "name": "Xã Cao Sơn",
                        "code": 2002,
                        "codename": "xa_cao_son",
                        "division_type": "xã",
                        "short_codename": "cao_son"
                    },
                    {
                        "name": "Xã Quân Hà",
                        "code": 2005,
                        "codename": "xa_quan_ha",
                        "division_type": "xã",
                        "short_codename": "quan_ha"
                    },
                    {
                        "name": "Xã Cẩm Giàng",
                        "code": 2008,
                        "codename": "xa_cam_giang",
                        "division_type": "xã",
                        "short_codename": "cam_giang"
                    },
                    {
                        "name": "Xã Mỹ Thanh",
                        "code": 2011,
                        "codename": "xa_my_thanh",
                        "division_type": "xã",
                        "short_codename": "my_thanh"
                    },
                    {
                        "name": "Xã Dương Phong",
                        "code": 2014,
                        "codename": "xa_duong_phong",
                        "division_type": "xã",
                        "short_codename": "duong_phong"
                    },
                    {
                        "name": "Xã Quang Thuận",
                        "code": 2017,
                        "codename": "xa_quang_thuan",
                        "division_type": "xã",
                        "short_codename": "quang_thuan"
                    }
                ]
            },
            {
                "name": "Huyện Chợ Đồn",
                "code": 64,
                "codename": "huyen_cho_don",
                "division_type": "huyện",
                "short_codename": "cho_don",
                "wards": [
                    {
                        "name": "Thị trấn Bằng Lũng",
                        "code": 2020,
                        "codename": "thi_tran_bang_lung",
                        "division_type": "thị trấn",
                        "short_codename": "bang_lung"
                    },
                    {
                        "name": "Xã Xuân Lạc",
                        "code": 2023,
                        "codename": "xa_xuan_lac",
                        "division_type": "xã",
                        "short_codename": "xuan_lac"
                    },
                    {
                        "name": "Xã Nam Cường",
                        "code": 2026,
                        "codename": "xa_nam_cuong",
                        "division_type": "xã",
                        "short_codename": "nam_cuong"
                    },
                    {
                        "name": "Xã Đồng Lạc",
                        "code": 2029,
                        "codename": "xa_dong_lac",
                        "division_type": "xã",
                        "short_codename": "dong_lac"
                    },
                    {
                        "name": "Xã Tân Lập",
                        "code": 2032,
                        "codename": "xa_tan_lap",
                        "division_type": "xã",
                        "short_codename": "tan_lap"
                    },
                    {
                        "name": "Xã Bản Thi",
                        "code": 2035,
                        "codename": "xa_ban_thi",
                        "division_type": "xã",
                        "short_codename": "ban_thi"
                    },
                    {
                        "name": "Xã Quảng Bạch",
                        "code": 2038,
                        "codename": "xa_quang_bach",
                        "division_type": "xã",
                        "short_codename": "quang_bach"
                    },
                    {
                        "name": "Xã Bằng Phúc",
                        "code": 2041,
                        "codename": "xa_bang_phuc",
                        "division_type": "xã",
                        "short_codename": "bang_phuc"
                    },
                    {
                        "name": "Xã Yên Thịnh",
                        "code": 2044,
                        "codename": "xa_yen_thinh",
                        "division_type": "xã",
                        "short_codename": "yen_thinh"
                    },
                    {
                        "name": "Xã Yên Thượng",
                        "code": 2047,
                        "codename": "xa_yen_thuong",
                        "division_type": "xã",
                        "short_codename": "yen_thuong"
                    },
                    {
                        "name": "Xã Phương Viên",
                        "code": 2050,
                        "codename": "xa_phuong_vien",
                        "division_type": "xã",
                        "short_codename": "phuong_vien"
                    },
                    {
                        "name": "Xã Ngọc Phái",
                        "code": 2053,
                        "codename": "xa_ngoc_phai",
                        "division_type": "xã",
                        "short_codename": "ngoc_phai"
                    },
                    {
                        "name": "Xã Đồng Thắng",
                        "code": 2059,
                        "codename": "xa_dong_thang",
                        "division_type": "xã",
                        "short_codename": "dong_thang"
                    },
                    {
                        "name": "Xã Lương Bằng",
                        "code": 2062,
                        "codename": "xa_luong_bang",
                        "division_type": "xã",
                        "short_codename": "luong_bang"
                    },
                    {
                        "name": "Xã Bằng Lãng",
                        "code": 2065,
                        "codename": "xa_bang_lang",
                        "division_type": "xã",
                        "short_codename": "bang_lang"
                    },
                    {
                        "name": "Xã Đại Sảo",
                        "code": 2068,
                        "codename": "xa_dai_sao",
                        "division_type": "xã",
                        "short_codename": "dai_sao"
                    },
                    {
                        "name": "Xã Nghĩa Tá",
                        "code": 2071,
                        "codename": "xa_nghia_ta",
                        "division_type": "xã",
                        "short_codename": "nghia_ta"
                    },
                    {
                        "name": "Xã Yên Mỹ",
                        "code": 2077,
                        "codename": "xa_yen_my",
                        "division_type": "xã",
                        "short_codename": "yen_my"
                    },
                    {
                        "name": "Xã Bình Trung",
                        "code": 2080,
                        "codename": "xa_binh_trung",
                        "division_type": "xã",
                        "short_codename": "binh_trung"
                    },
                    {
                        "name": "Xã Yên Phong",
                        "code": 2083,
                        "codename": "xa_yen_phong",
                        "division_type": "xã",
                        "short_codename": "yen_phong"
                    }
                ]
            },
            {
                "name": "Huyện Chợ Mới",
                "code": 65,
                "codename": "huyen_cho_moi",
                "division_type": "huyện",
                "short_codename": "cho_moi",
                "wards": [
                    {
                        "name": "Thị trấn Đồng Tâm",
                        "code": 2086,
                        "codename": "thi_tran_dong_tam",
                        "division_type": "thị trấn",
                        "short_codename": "dong_tam"
                    },
                    {
                        "name": "Xã Tân Sơn",
                        "code": 2089,
                        "codename": "xa_tan_son",
                        "division_type": "xã",
                        "short_codename": "tan_son"
                    },
                    {
                        "name": "Xã Thanh Vận",
                        "code": 2092,
                        "codename": "xa_thanh_van",
                        "division_type": "xã",
                        "short_codename": "thanh_van"
                    },
                    {
                        "name": "Xã Mai Lạp",
                        "code": 2095,
                        "codename": "xa_mai_lap",
                        "division_type": "xã",
                        "short_codename": "mai_lap"
                    },
                    {
                        "name": "Xã Hoà Mục",
                        "code": 2098,
                        "codename": "xa_hoa_muc",
                        "division_type": "xã",
                        "short_codename": "hoa_muc"
                    },
                    {
                        "name": "Xã Thanh Mai",
                        "code": 2101,
                        "codename": "xa_thanh_mai",
                        "division_type": "xã",
                        "short_codename": "thanh_mai"
                    },
                    {
                        "name": "Xã Cao Kỳ",
                        "code": 2104,
                        "codename": "xa_cao_ky",
                        "division_type": "xã",
                        "short_codename": "cao_ky"
                    },
                    {
                        "name": "Xã Nông Hạ",
                        "code": 2107,
                        "codename": "xa_nong_ha",
                        "division_type": "xã",
                        "short_codename": "nong_ha"
                    },
                    {
                        "name": "Xã Yên Cư",
                        "code": 2110,
                        "codename": "xa_yen_cu",
                        "division_type": "xã",
                        "short_codename": "yen_cu"
                    },
                    {
                        "name": "Xã Thanh Thịnh",
                        "code": 2113,
                        "codename": "xa_thanh_thinh",
                        "division_type": "xã",
                        "short_codename": "thanh_thinh"
                    },
                    {
                        "name": "Xã Yên Hân",
                        "code": 2116,
                        "codename": "xa_yen_han",
                        "division_type": "xã",
                        "short_codename": "yen_han"
                    },
                    {
                        "name": "Xã Như Cố",
                        "code": 2122,
                        "codename": "xa_nhu_co",
                        "division_type": "xã",
                        "short_codename": "nhu_co"
                    },
                    {
                        "name": "Xã Bình Văn",
                        "code": 2125,
                        "codename": "xa_binh_van",
                        "division_type": "xã",
                        "short_codename": "binh_van"
                    },
                    {
                        "name": "Xã Quảng Chu",
                        "code": 2131,
                        "codename": "xa_quang_chu",
                        "division_type": "xã",
                        "short_codename": "quang_chu"
                    }
                ]
            },
            {
                "name": "Huyện Na Rì",
                "code": 66,
                "codename": "huyen_na_ri",
                "division_type": "huyện",
                "short_codename": "na_ri",
                "wards": [
                    {
                        "name": "Xã Văn Vũ",
                        "code": 2137,
                        "codename": "xa_van_vu",
                        "division_type": "xã",
                        "short_codename": "van_vu"
                    },
                    {
                        "name": "Xã Văn Lang",
                        "code": 2140,
                        "codename": "xa_van_lang",
                        "division_type": "xã",
                        "short_codename": "van_lang"
                    },
                    {
                        "name": "Xã Lương Thượng",
                        "code": 2143,
                        "codename": "xa_luong_thuong",
                        "division_type": "xã",
                        "short_codename": "luong_thuong"
                    },
                    {
                        "name": "Xã Kim Hỷ",
                        "code": 2146,
                        "codename": "xa_kim_hy",
                        "division_type": "xã",
                        "short_codename": "kim_hy"
                    },
                    {
                        "name": "Xã Cường Lợi",
                        "code": 2152,
                        "codename": "xa_cuong_loi",
                        "division_type": "xã",
                        "short_codename": "cuong_loi"
                    },
                    {
                        "name": "Thị trấn Yến Lạc",
                        "code": 2155,
                        "codename": "thi_tran_yen_lac",
                        "division_type": "thị trấn",
                        "short_codename": "yen_lac"
                    },
                    {
                        "name": "Xã Kim Lư",
                        "code": 2158,
                        "codename": "xa_kim_lu",
                        "division_type": "xã",
                        "short_codename": "kim_lu"
                    },
                    {
                        "name": "Xã Sơn Thành",
                        "code": 2161,
                        "codename": "xa_son_thanh",
                        "division_type": "xã",
                        "short_codename": "son_thanh"
                    },
                    {
                        "name": "Xã Văn Minh",
                        "code": 2170,
                        "codename": "xa_van_minh",
                        "division_type": "xã",
                        "short_codename": "van_minh"
                    },
                    {
                        "name": "Xã Côn Minh",
                        "code": 2173,
                        "codename": "xa_con_minh",
                        "division_type": "xã",
                        "short_codename": "con_minh"
                    },
                    {
                        "name": "Xã Cư Lễ",
                        "code": 2176,
                        "codename": "xa_cu_le",
                        "division_type": "xã",
                        "short_codename": "cu_le"
                    },
                    {
                        "name": "Xã Trần Phú",
                        "code": 2179,
                        "codename": "xa_tran_phu",
                        "division_type": "xã",
                        "short_codename": "tran_phu"
                    },
                    {
                        "name": "Xã Quang Phong",
                        "code": 2185,
                        "codename": "xa_quang_phong",
                        "division_type": "xã",
                        "short_codename": "quang_phong"
                    },
                    {
                        "name": "Xã Dương Sơn",
                        "code": 2188,
                        "codename": "xa_duong_son",
                        "division_type": "xã",
                        "short_codename": "duong_son"
                    },
                    {
                        "name": "Xã Xuân Dương",
                        "code": 2191,
                        "codename": "xa_xuan_duong",
                        "division_type": "xã",
                        "short_codename": "xuan_duong"
                    },
                    {
                        "name": "Xã Đổng Xá",
                        "code": 2194,
                        "codename": "xa_dong_xa",
                        "division_type": "xã",
                        "short_codename": "dong_xa"
                    },
                    {
                        "name": "Xã Liêm Thuỷ",
                        "code": 2197,
                        "codename": "xa_liem_thuy",
                        "division_type": "xã",
                        "short_codename": "liem_thuy"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Tuyên Quang",
        "code": 8,
        "codename": "tinh_tuyen_quang",
        "division_type": "tỉnh",
        "phone_code": 207,
        "districts": [
            {
                "name": "Thành phố Tuyên Quang",
                "code": 70,
                "codename": "thanh_pho_tuyen_quang",
                "division_type": "thành phố",
                "short_codename": "tuyen_quang",
                "wards": [
                    {
                        "name": "Phường Phan Thiết",
                        "code": 2200,
                        "codename": "phuong_phan_thiet",
                        "division_type": "phường",
                        "short_codename": "phan_thiet"
                    },
                    {
                        "name": "Phường Minh Xuân",
                        "code": 2203,
                        "codename": "phuong_minh_xuan",
                        "division_type": "phường",
                        "short_codename": "minh_xuan"
                    },
                    {
                        "name": "Phường Tân Quang",
                        "code": 2206,
                        "codename": "phuong_tan_quang",
                        "division_type": "phường",
                        "short_codename": "tan_quang"
                    },
                    {
                        "name": "Xã Tràng Đà",
                        "code": 2209,
                        "codename": "xa_trang_da",
                        "division_type": "xã",
                        "short_codename": "trang_da"
                    },
                    {
                        "name": "Phường Nông Tiến",
                        "code": 2212,
                        "codename": "phuong_nong_tien",
                        "division_type": "phường",
                        "short_codename": "nong_tien"
                    },
                    {
                        "name": "Phường Ỷ La",
                        "code": 2215,
                        "codename": "phuong_y_la",
                        "division_type": "phường",
                        "short_codename": "y_la"
                    },
                    {
                        "name": "Phường Tân Hà",
                        "code": 2216,
                        "codename": "phuong_tan_ha",
                        "division_type": "phường",
                        "short_codename": "tan_ha"
                    },
                    {
                        "name": "Phường Hưng Thành",
                        "code": 2218,
                        "codename": "phuong_hung_thanh",
                        "division_type": "phường",
                        "short_codename": "hung_thanh"
                    },
                    {
                        "name": "Xã Kim Phú",
                        "code": 2497,
                        "codename": "xa_kim_phu",
                        "division_type": "xã",
                        "short_codename": "kim_phu"
                    },
                    {
                        "name": "Xã An Khang",
                        "code": 2503,
                        "codename": "xa_an_khang",
                        "division_type": "xã",
                        "short_codename": "an_khang"
                    },
                    {
                        "name": "Phường Mỹ Lâm",
                        "code": 2509,
                        "codename": "phuong_my_lam",
                        "division_type": "phường",
                        "short_codename": "my_lam"
                    },
                    {
                        "name": "Phường An Tường",
                        "code": 2512,
                        "codename": "phuong_an_tuong",
                        "division_type": "phường",
                        "short_codename": "an_tuong"
                    },
                    {
                        "name": "Xã Lưỡng Vượng",
                        "code": 2515,
                        "codename": "xa_luong_vuong",
                        "division_type": "xã",
                        "short_codename": "luong_vuong"
                    },
                    {
                        "name": "Xã Thái Long",
                        "code": 2521,
                        "codename": "xa_thai_long",
                        "division_type": "xã",
                        "short_codename": "thai_long"
                    },
                    {
                        "name": "Phường Đội Cấn",
                        "code": 2524,
                        "codename": "phuong_doi_can",
                        "division_type": "phường",
                        "short_codename": "doi_can"
                    }
                ]
            },
            {
                "name": "Huyện Lâm Bình",
                "code": 71,
                "codename": "huyen_lam_binh",
                "division_type": "huyện",
                "short_codename": "lam_binh",
                "wards": [
                    {
                        "name": "Xã Phúc Yên",
                        "code": 2233,
                        "codename": "xa_phuc_yen",
                        "division_type": "xã",
                        "short_codename": "phuc_yen"
                    },
                    {
                        "name": "Xã Xuân Lập",
                        "code": 2242,
                        "codename": "xa_xuan_lap",
                        "division_type": "xã",
                        "short_codename": "xuan_lap"
                    },
                    {
                        "name": "Xã Khuôn Hà",
                        "code": 2251,
                        "codename": "xa_khuon_ha",
                        "division_type": "xã",
                        "short_codename": "khuon_ha"
                    },
                    {
                        "name": "Xã Lăng Can",
                        "code": 2266,
                        "codename": "xa_lang_can",
                        "division_type": "xã",
                        "short_codename": "lang_can"
                    },
                    {
                        "name": "Xã Thượng Lâm",
                        "code": 2269,
                        "codename": "xa_thuong_lam",
                        "division_type": "xã",
                        "short_codename": "thuong_lam"
                    },
                    {
                        "name": "Xã Bình An",
                        "code": 2290,
                        "codename": "xa_binh_an",
                        "division_type": "xã",
                        "short_codename": "binh_an"
                    },
                    {
                        "name": "Xã Hồng Quang",
                        "code": 2293,
                        "codename": "xa_hong_quang",
                        "division_type": "xã",
                        "short_codename": "hong_quang"
                    },
                    {
                        "name": "Xã Thổ Bình",
                        "code": 2296,
                        "codename": "xa_tho_binh",
                        "division_type": "xã",
                        "short_codename": "tho_binh"
                    }
                ]
            },
            {
                "name": "Huyện Na Hang",
                "code": 72,
                "codename": "huyen_na_hang",
                "division_type": "huyện",
                "short_codename": "na_hang",
                "wards": [
                    {
                        "name": "Thị trấn Na Hang",
                        "code": 2221,
                        "codename": "thi_tran_na_hang",
                        "division_type": "thị trấn",
                        "short_codename": "na_hang"
                    },
                    {
                        "name": "Xã Sinh Long",
                        "code": 2227,
                        "codename": "xa_sinh_long",
                        "division_type": "xã",
                        "short_codename": "sinh_long"
                    },
                    {
                        "name": "Xã Thượng Giáp",
                        "code": 2230,
                        "codename": "xa_thuong_giap",
                        "division_type": "xã",
                        "short_codename": "thuong_giap"
                    },
                    {
                        "name": "Xã Thượng Nông",
                        "code": 2239,
                        "codename": "xa_thuong_nong",
                        "division_type": "xã",
                        "short_codename": "thuong_nong"
                    },
                    {
                        "name": "Xã Côn Lôn",
                        "code": 2245,
                        "codename": "xa_con_lon",
                        "division_type": "xã",
                        "short_codename": "con_lon"
                    },
                    {
                        "name": "Xã Yên Hoa",
                        "code": 2248,
                        "codename": "xa_yen_hoa",
                        "division_type": "xã",
                        "short_codename": "yen_hoa"
                    },
                    {
                        "name": "Xã Hồng Thái",
                        "code": 2254,
                        "codename": "xa_hong_thai",
                        "division_type": "xã",
                        "short_codename": "hong_thai"
                    },
                    {
                        "name": "Xã Đà Vị",
                        "code": 2260,
                        "codename": "xa_da_vi",
                        "division_type": "xã",
                        "short_codename": "da_vi"
                    },
                    {
                        "name": "Xã Khau Tinh",
                        "code": 2263,
                        "codename": "xa_khau_tinh",
                        "division_type": "xã",
                        "short_codename": "khau_tinh"
                    },
                    {
                        "name": "Xã Sơn Phú",
                        "code": 2275,
                        "codename": "xa_son_phu",
                        "division_type": "xã",
                        "short_codename": "son_phu"
                    },
                    {
                        "name": "Xã Năng Khả",
                        "code": 2281,
                        "codename": "xa_nang_kha",
                        "division_type": "xã",
                        "short_codename": "nang_kha"
                    },
                    {
                        "name": "Xã Thanh Tương",
                        "code": 2284,
                        "codename": "xa_thanh_tuong",
                        "division_type": "xã",
                        "short_codename": "thanh_tuong"
                    }
                ]
            },
            {
                "name": "Huyện Chiêm Hóa",
                "code": 73,
                "codename": "huyen_chiem_hoa",
                "division_type": "huyện",
                "short_codename": "chiem_hoa",
                "wards": [
                    {
                        "name": "Thị trấn Vĩnh Lộc",
                        "code": 2287,
                        "codename": "thi_tran_vinh_loc",
                        "division_type": "thị trấn",
                        "short_codename": "vinh_loc"
                    },
                    {
                        "name": "Xã Phúc Sơn",
                        "code": 2299,
                        "codename": "xa_phuc_son",
                        "division_type": "xã",
                        "short_codename": "phuc_son"
                    },
                    {
                        "name": "Xã Minh Quang",
                        "code": 2302,
                        "codename": "xa_minh_quang",
                        "division_type": "xã",
                        "short_codename": "minh_quang"
                    },
                    {
                        "name": "Xã Trung Hà",
                        "code": 2305,
                        "codename": "xa_trung_ha",
                        "division_type": "xã",
                        "short_codename": "trung_ha"
                    },
                    {
                        "name": "Xã Tân Mỹ",
                        "code": 2308,
                        "codename": "xa_tan_my",
                        "division_type": "xã",
                        "short_codename": "tan_my"
                    },
                    {
                        "name": "Xã Hà Lang",
                        "code": 2311,
                        "codename": "xa_ha_lang",
                        "division_type": "xã",
                        "short_codename": "ha_lang"
                    },
                    {
                        "name": "Xã Hùng Mỹ",
                        "code": 2314,
                        "codename": "xa_hung_my",
                        "division_type": "xã",
                        "short_codename": "hung_my"
                    },
                    {
                        "name": "Xã Yên Lập",
                        "code": 2317,
                        "codename": "xa_yen_lap",
                        "division_type": "xã",
                        "short_codename": "yen_lap"
                    },
                    {
                        "name": "Xã Tân An",
                        "code": 2320,
                        "codename": "xa_tan_an",
                        "division_type": "xã",
                        "short_codename": "tan_an"
                    },
                    {
                        "name": "Xã Bình Phú",
                        "code": 2323,
                        "codename": "xa_binh_phu",
                        "division_type": "xã",
                        "short_codename": "binh_phu"
                    },
                    {
                        "name": "Xã Xuân Quang",
                        "code": 2326,
                        "codename": "xa_xuan_quang",
                        "division_type": "xã",
                        "short_codename": "xuan_quang"
                    },
                    {
                        "name": "Xã Ngọc Hội",
                        "code": 2329,
                        "codename": "xa_ngoc_hoi",
                        "division_type": "xã",
                        "short_codename": "ngoc_hoi"
                    },
                    {
                        "name": "Xã Phú Bình",
                        "code": 2332,
                        "codename": "xa_phu_binh",
                        "division_type": "xã",
                        "short_codename": "phu_binh"
                    },
                    {
                        "name": "Xã Hòa Phú",
                        "code": 2335,
                        "codename": "xa_hoa_phu",
                        "division_type": "xã",
                        "short_codename": "hoa_phu"
                    },
                    {
                        "name": "Xã Phúc Thịnh",
                        "code": 2338,
                        "codename": "xa_phuc_thinh",
                        "division_type": "xã",
                        "short_codename": "phuc_thinh"
                    },
                    {
                        "name": "Xã Kiên Đài",
                        "code": 2341,
                        "codename": "xa_kien_dai",
                        "division_type": "xã",
                        "short_codename": "kien_dai"
                    },
                    {
                        "name": "Xã Tân Thịnh",
                        "code": 2344,
                        "codename": "xa_tan_thinh",
                        "division_type": "xã",
                        "short_codename": "tan_thinh"
                    },
                    {
                        "name": "Xã Trung Hòa",
                        "code": 2347,
                        "codename": "xa_trung_hoa",
                        "division_type": "xã",
                        "short_codename": "trung_hoa"
                    },
                    {
                        "name": "Xã Kim Bình",
                        "code": 2350,
                        "codename": "xa_kim_binh",
                        "division_type": "xã",
                        "short_codename": "kim_binh"
                    },
                    {
                        "name": "Xã Hòa An",
                        "code": 2353,
                        "codename": "xa_hoa_an",
                        "division_type": "xã",
                        "short_codename": "hoa_an"
                    },
                    {
                        "name": "Xã Vinh Quang",
                        "code": 2356,
                        "codename": "xa_vinh_quang",
                        "division_type": "xã",
                        "short_codename": "vinh_quang"
                    },
                    {
                        "name": "Xã Tri Phú",
                        "code": 2359,
                        "codename": "xa_tri_phu",
                        "division_type": "xã",
                        "short_codename": "tri_phu"
                    },
                    {
                        "name": "Xã Nhân Lý",
                        "code": 2362,
                        "codename": "xa_nhan_ly",
                        "division_type": "xã",
                        "short_codename": "nhan_ly"
                    },
                    {
                        "name": "Xã Yên Nguyên",
                        "code": 2365,
                        "codename": "xa_yen_nguyen",
                        "division_type": "xã",
                        "short_codename": "yen_nguyen"
                    },
                    {
                        "name": "Xã Linh Phú",
                        "code": 2368,
                        "codename": "xa_linh_phu",
                        "division_type": "xã",
                        "short_codename": "linh_phu"
                    },
                    {
                        "name": "Xã Bình Nhân",
                        "code": 2371,
                        "codename": "xa_binh_nhan",
                        "division_type": "xã",
                        "short_codename": "binh_nhan"
                    }
                ]
            },
            {
                "name": "Huyện Hàm Yên",
                "code": 74,
                "codename": "huyen_ham_yen",
                "division_type": "huyện",
                "short_codename": "ham_yen",
                "wards": [
                    {
                        "name": "Thị trấn Tân Yên",
                        "code": 2374,
                        "codename": "thi_tran_tan_yen",
                        "division_type": "thị trấn",
                        "short_codename": "tan_yen"
                    },
                    {
                        "name": "Xã Yên Thuận",
                        "code": 2377,
                        "codename": "xa_yen_thuan",
                        "division_type": "xã",
                        "short_codename": "yen_thuan"
                    },
                    {
                        "name": "Xã Bạch Xa",
                        "code": 2380,
                        "codename": "xa_bach_xa",
                        "division_type": "xã",
                        "short_codename": "bach_xa"
                    },
                    {
                        "name": "Xã Minh Khương",
                        "code": 2383,
                        "codename": "xa_minh_khuong",
                        "division_type": "xã",
                        "short_codename": "minh_khuong"
                    },
                    {
                        "name": "Xã Yên Lâm",
                        "code": 2386,
                        "codename": "xa_yen_lam",
                        "division_type": "xã",
                        "short_codename": "yen_lam"
                    },
                    {
                        "name": "Xã Minh Dân",
                        "code": 2389,
                        "codename": "xa_minh_dan",
                        "division_type": "xã",
                        "short_codename": "minh_dan"
                    },
                    {
                        "name": "Xã Phù Lưu",
                        "code": 2392,
                        "codename": "xa_phu_luu",
                        "division_type": "xã",
                        "short_codename": "phu_luu"
                    },
                    {
                        "name": "Xã Minh Hương",
                        "code": 2395,
                        "codename": "xa_minh_huong",
                        "division_type": "xã",
                        "short_codename": "minh_huong"
                    },
                    {
                        "name": "Xã Yên Phú",
                        "code": 2398,
                        "codename": "xa_yen_phu",
                        "division_type": "xã",
                        "short_codename": "yen_phu"
                    },
                    {
                        "name": "Xã Tân Thành",
                        "code": 2401,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Xã Bình Xa",
                        "code": 2404,
                        "codename": "xa_binh_xa",
                        "division_type": "xã",
                        "short_codename": "binh_xa"
                    },
                    {
                        "name": "Xã Thái Sơn",
                        "code": 2407,
                        "codename": "xa_thai_son",
                        "division_type": "xã",
                        "short_codename": "thai_son"
                    },
                    {
                        "name": "Xã Nhân Mục",
                        "code": 2410,
                        "codename": "xa_nhan_muc",
                        "division_type": "xã",
                        "short_codename": "nhan_muc"
                    },
                    {
                        "name": "Xã Thành Long",
                        "code": 2413,
                        "codename": "xa_thanh_long",
                        "division_type": "xã",
                        "short_codename": "thanh_long"
                    },
                    {
                        "name": "Xã Bằng Cốc",
                        "code": 2416,
                        "codename": "xa_bang_coc",
                        "division_type": "xã",
                        "short_codename": "bang_coc"
                    },
                    {
                        "name": "Xã Thái Hòa",
                        "code": 2419,
                        "codename": "xa_thai_hoa",
                        "division_type": "xã",
                        "short_codename": "thai_hoa"
                    },
                    {
                        "name": "Xã Đức Ninh",
                        "code": 2422,
                        "codename": "xa_duc_ninh",
                        "division_type": "xã",
                        "short_codename": "duc_ninh"
                    },
                    {
                        "name": "Xã Hùng Đức",
                        "code": 2425,
                        "codename": "xa_hung_duc",
                        "division_type": "xã",
                        "short_codename": "hung_duc"
                    }
                ]
            },
            {
                "name": "Huyện Yên Sơn",
                "code": 75,
                "codename": "huyen_yen_son",
                "division_type": "huyện",
                "short_codename": "yen_son",
                "wards": [
                    {
                        "name": "Xã Quí Quân",
                        "code": 2431,
                        "codename": "xa_qui_quan",
                        "division_type": "xã",
                        "short_codename": "qui_quan"
                    },
                    {
                        "name": "Xã Lực Hành",
                        "code": 2434,
                        "codename": "xa_luc_hanh",
                        "division_type": "xã",
                        "short_codename": "luc_hanh"
                    },
                    {
                        "name": "Xã Kiến Thiết",
                        "code": 2437,
                        "codename": "xa_kien_thiet",
                        "division_type": "xã",
                        "short_codename": "kien_thiet"
                    },
                    {
                        "name": "Xã Trung Minh",
                        "code": 2440,
                        "codename": "xa_trung_minh",
                        "division_type": "xã",
                        "short_codename": "trung_minh"
                    },
                    {
                        "name": "Xã Chiêu Yên",
                        "code": 2443,
                        "codename": "xa_chieu_yen",
                        "division_type": "xã",
                        "short_codename": "chieu_yen"
                    },
                    {
                        "name": "Xã Trung Trực",
                        "code": 2446,
                        "codename": "xa_trung_truc",
                        "division_type": "xã",
                        "short_codename": "trung_truc"
                    },
                    {
                        "name": "Xã Xuân Vân",
                        "code": 2449,
                        "codename": "xa_xuan_van",
                        "division_type": "xã",
                        "short_codename": "xuan_van"
                    },
                    {
                        "name": "Xã Phúc Ninh",
                        "code": 2452,
                        "codename": "xa_phuc_ninh",
                        "division_type": "xã",
                        "short_codename": "phuc_ninh"
                    },
                    {
                        "name": "Xã Hùng Lợi",
                        "code": 2455,
                        "codename": "xa_hung_loi",
                        "division_type": "xã",
                        "short_codename": "hung_loi"
                    },
                    {
                        "name": "Xã Trung Sơn",
                        "code": 2458,
                        "codename": "xa_trung_son",
                        "division_type": "xã",
                        "short_codename": "trung_son"
                    },
                    {
                        "name": "Xã Tân Tiến",
                        "code": 2461,
                        "codename": "xa_tan_tien",
                        "division_type": "xã",
                        "short_codename": "tan_tien"
                    },
                    {
                        "name": "Xã Tứ Quận",
                        "code": 2464,
                        "codename": "xa_tu_quan",
                        "division_type": "xã",
                        "short_codename": "tu_quan"
                    },
                    {
                        "name": "Xã Đạo Viện",
                        "code": 2467,
                        "codename": "xa_dao_vien",
                        "division_type": "xã",
                        "short_codename": "dao_vien"
                    },
                    {
                        "name": "Xã Tân Long",
                        "code": 2470,
                        "codename": "xa_tan_long",
                        "division_type": "xã",
                        "short_codename": "tan_long"
                    },
                    {
                        "name": "Xã Thắng Quân",
                        "code": 2473,
                        "codename": "xa_thang_quan",
                        "division_type": "xã",
                        "short_codename": "thang_quan"
                    },
                    {
                        "name": "Xã Kim Quan",
                        "code": 2476,
                        "codename": "xa_kim_quan",
                        "division_type": "xã",
                        "short_codename": "kim_quan"
                    },
                    {
                        "name": "Xã Lang Quán",
                        "code": 2479,
                        "codename": "xa_lang_quan",
                        "division_type": "xã",
                        "short_codename": "lang_quan"
                    },
                    {
                        "name": "Xã Phú Thịnh",
                        "code": 2482,
                        "codename": "xa_phu_thinh",
                        "division_type": "xã",
                        "short_codename": "phu_thinh"
                    },
                    {
                        "name": "Xã Công Đa",
                        "code": 2485,
                        "codename": "xa_cong_da",
                        "division_type": "xã",
                        "short_codename": "cong_da"
                    },
                    {
                        "name": "Xã Trung Môn",
                        "code": 2488,
                        "codename": "xa_trung_mon",
                        "division_type": "xã",
                        "short_codename": "trung_mon"
                    },
                    {
                        "name": "Xã Chân Sơn",
                        "code": 2491,
                        "codename": "xa_chan_son",
                        "division_type": "xã",
                        "short_codename": "chan_son"
                    },
                    {
                        "name": "Xã Thái Bình",
                        "code": 2494,
                        "codename": "xa_thai_binh",
                        "division_type": "xã",
                        "short_codename": "thai_binh"
                    },
                    {
                        "name": "Xã Tiến Bộ",
                        "code": 2500,
                        "codename": "xa_tien_bo",
                        "division_type": "xã",
                        "short_codename": "tien_bo"
                    },
                    {
                        "name": "Xã Mỹ Bằng",
                        "code": 2506,
                        "codename": "xa_my_bang",
                        "division_type": "xã",
                        "short_codename": "my_bang"
                    },
                    {
                        "name": "Xã Hoàng Khai",
                        "code": 2518,
                        "codename": "xa_hoang_khai",
                        "division_type": "xã",
                        "short_codename": "hoang_khai"
                    },
                    {
                        "name": "Xã Nhữ Hán",
                        "code": 2527,
                        "codename": "xa_nhu_han",
                        "division_type": "xã",
                        "short_codename": "nhu_han"
                    },
                    {
                        "name": "Xã Nhữ Khê",
                        "code": 2530,
                        "codename": "xa_nhu_khe",
                        "division_type": "xã",
                        "short_codename": "nhu_khe"
                    },
                    {
                        "name": "Xã Đội Bình",
                        "code": 2533,
                        "codename": "xa_doi_binh",
                        "division_type": "xã",
                        "short_codename": "doi_binh"
                    }
                ]
            },
            {
                "name": "Huyện Sơn Dương",
                "code": 76,
                "codename": "huyen_son_duong",
                "division_type": "huyện",
                "short_codename": "son_duong",
                "wards": [
                    {
                        "name": "Thị trấn Sơn Dương",
                        "code": 2536,
                        "codename": "thi_tran_son_duong",
                        "division_type": "thị trấn",
                        "short_codename": "son_duong"
                    },
                    {
                        "name": "Xã Trung Yên",
                        "code": 2539,
                        "codename": "xa_trung_yen",
                        "division_type": "xã",
                        "short_codename": "trung_yen"
                    },
                    {
                        "name": "Xã Minh Thanh",
                        "code": 2542,
                        "codename": "xa_minh_thanh",
                        "division_type": "xã",
                        "short_codename": "minh_thanh"
                    },
                    {
                        "name": "Xã Tân Trào",
                        "code": 2545,
                        "codename": "xa_tan_trao",
                        "division_type": "xã",
                        "short_codename": "tan_trao"
                    },
                    {
                        "name": "Xã Vĩnh Lợi",
                        "code": 2548,
                        "codename": "xa_vinh_loi",
                        "division_type": "xã",
                        "short_codename": "vinh_loi"
                    },
                    {
                        "name": "Xã Thượng Ấm",
                        "code": 2551,
                        "codename": "xa_thuong_am",
                        "division_type": "xã",
                        "short_codename": "thuong_am"
                    },
                    {
                        "name": "Xã Bình Yên",
                        "code": 2554,
                        "codename": "xa_binh_yen",
                        "division_type": "xã",
                        "short_codename": "binh_yen"
                    },
                    {
                        "name": "Xã Lương Thiện",
                        "code": 2557,
                        "codename": "xa_luong_thien",
                        "division_type": "xã",
                        "short_codename": "luong_thien"
                    },
                    {
                        "name": "Xã Tú Thịnh",
                        "code": 2560,
                        "codename": "xa_tu_thinh",
                        "division_type": "xã",
                        "short_codename": "tu_thinh"
                    },
                    {
                        "name": "Xã Cấp Tiến",
                        "code": 2563,
                        "codename": "xa_cap_tien",
                        "division_type": "xã",
                        "short_codename": "cap_tien"
                    },
                    {
                        "name": "Xã Hợp Thành",
                        "code": 2566,
                        "codename": "xa_hop_thanh",
                        "division_type": "xã",
                        "short_codename": "hop_thanh"
                    },
                    {
                        "name": "Xã Phúc Ứng",
                        "code": 2569,
                        "codename": "xa_phuc_ung",
                        "division_type": "xã",
                        "short_codename": "phuc_ung"
                    },
                    {
                        "name": "Xã Đông Thọ",
                        "code": 2572,
                        "codename": "xa_dong_tho",
                        "division_type": "xã",
                        "short_codename": "dong_tho"
                    },
                    {
                        "name": "Xã Kháng Nhật",
                        "code": 2575,
                        "codename": "xa_khang_nhat",
                        "division_type": "xã",
                        "short_codename": "khang_nhat"
                    },
                    {
                        "name": "Xã Hợp Hòa",
                        "code": 2578,
                        "codename": "xa_hop_hoa",
                        "division_type": "xã",
                        "short_codename": "hop_hoa"
                    },
                    {
                        "name": "Xã Quyết Thắng",
                        "code": 2584,
                        "codename": "xa_quyet_thang",
                        "division_type": "xã",
                        "short_codename": "quyet_thang"
                    },
                    {
                        "name": "Xã Đồng Quý",
                        "code": 2587,
                        "codename": "xa_dong_quy",
                        "division_type": "xã",
                        "short_codename": "dong_quy"
                    },
                    {
                        "name": "Xã Tân Thanh",
                        "code": 2590,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Xã Vân Sơn",
                        "code": 2593,
                        "codename": "xa_van_son",
                        "division_type": "xã",
                        "short_codename": "van_son"
                    },
                    {
                        "name": "Xã Văn Phú",
                        "code": 2596,
                        "codename": "xa_van_phu",
                        "division_type": "xã",
                        "short_codename": "van_phu"
                    },
                    {
                        "name": "Xã Chi Thiết",
                        "code": 2599,
                        "codename": "xa_chi_thiet",
                        "division_type": "xã",
                        "short_codename": "chi_thiet"
                    },
                    {
                        "name": "Xã Đông Lợi",
                        "code": 2602,
                        "codename": "xa_dong_loi",
                        "division_type": "xã",
                        "short_codename": "dong_loi"
                    },
                    {
                        "name": "Xã Thiện Kế",
                        "code": 2605,
                        "codename": "xa_thien_ke",
                        "division_type": "xã",
                        "short_codename": "thien_ke"
                    },
                    {
                        "name": "Xã Hồng Lạc",
                        "code": 2608,
                        "codename": "xa_hong_lac",
                        "division_type": "xã",
                        "short_codename": "hong_lac"
                    },
                    {
                        "name": "Xã Phú Lương",
                        "code": 2611,
                        "codename": "xa_phu_luong",
                        "division_type": "xã",
                        "short_codename": "phu_luong"
                    },
                    {
                        "name": "Xã Ninh Lai",
                        "code": 2614,
                        "codename": "xa_ninh_lai",
                        "division_type": "xã",
                        "short_codename": "ninh_lai"
                    },
                    {
                        "name": "Xã Đại Phú",
                        "code": 2617,
                        "codename": "xa_dai_phu",
                        "division_type": "xã",
                        "short_codename": "dai_phu"
                    },
                    {
                        "name": "Xã Sơn Nam",
                        "code": 2620,
                        "codename": "xa_son_nam",
                        "division_type": "xã",
                        "short_codename": "son_nam"
                    },
                    {
                        "name": "Xã Hào Phú",
                        "code": 2623,
                        "codename": "xa_hao_phu",
                        "division_type": "xã",
                        "short_codename": "hao_phu"
                    },
                    {
                        "name": "Xã Tam Đa",
                        "code": 2626,
                        "codename": "xa_tam_da",
                        "division_type": "xã",
                        "short_codename": "tam_da"
                    },
                    {
                        "name": "Xã Trường Sinh",
                        "code": 2632,
                        "codename": "xa_truong_sinh",
                        "division_type": "xã",
                        "short_codename": "truong_sinh"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Lào Cai",
        "code": 10,
        "codename": "tinh_lao_cai",
        "division_type": "tỉnh",
        "phone_code": 214,
        "districts": [
            {
                "name": "Thành phố Lào Cai",
                "code": 80,
                "codename": "thanh_pho_lao_cai",
                "division_type": "thành phố",
                "short_codename": "lao_cai",
                "wards": [
                    {
                        "name": "Phường Duyên Hải",
                        "code": 2635,
                        "codename": "phuong_duyen_hai",
                        "division_type": "phường",
                        "short_codename": "duyen_hai"
                    },
                    {
                        "name": "Phường Lào Cai",
                        "code": 2641,
                        "codename": "phuong_lao_cai",
                        "division_type": "phường",
                        "short_codename": "lao_cai"
                    },
                    {
                        "name": "Phường Cốc Lếu",
                        "code": 2644,
                        "codename": "phuong_coc_leu",
                        "division_type": "phường",
                        "short_codename": "coc_leu"
                    },
                    {
                        "name": "Phường Kim Tân",
                        "code": 2647,
                        "codename": "phuong_kim_tan",
                        "division_type": "phường",
                        "short_codename": "kim_tan"
                    },
                    {
                        "name": "Phường Bắc Lệnh",
                        "code": 2650,
                        "codename": "phuong_bac_lenh",
                        "division_type": "phường",
                        "short_codename": "bac_lenh"
                    },
                    {
                        "name": "Phường Pom Hán",
                        "code": 2653,
                        "codename": "phuong_pom_han",
                        "division_type": "phường",
                        "short_codename": "pom_han"
                    },
                    {
                        "name": "Phường Xuân Tăng",
                        "code": 2656,
                        "codename": "phuong_xuan_tang",
                        "division_type": "phường",
                        "short_codename": "xuan_tang"
                    },
                    {
                        "name": "Phường Bình Minh",
                        "code": 2658,
                        "codename": "phuong_binh_minh",
                        "division_type": "phường",
                        "short_codename": "binh_minh"
                    },
                    {
                        "name": "Xã Thống Nhất",
                        "code": 2659,
                        "codename": "xa_thong_nhat",
                        "division_type": "xã",
                        "short_codename": "thong_nhat"
                    },
                    {
                        "name": "Xã Đồng Tuyển",
                        "code": 2662,
                        "codename": "xa_dong_tuyen",
                        "division_type": "xã",
                        "short_codename": "dong_tuyen"
                    },
                    {
                        "name": "Xã Vạn Hoà",
                        "code": 2665,
                        "codename": "xa_van_hoa",
                        "division_type": "xã",
                        "short_codename": "van_hoa"
                    },
                    {
                        "name": "Phường Bắc Cường",
                        "code": 2668,
                        "codename": "phuong_bac_cuong",
                        "division_type": "phường",
                        "short_codename": "bac_cuong"
                    },
                    {
                        "name": "Phường Nam Cường",
                        "code": 2671,
                        "codename": "phuong_nam_cuong",
                        "division_type": "phường",
                        "short_codename": "nam_cuong"
                    },
                    {
                        "name": "Xã Cam Đường",
                        "code": 2674,
                        "codename": "xa_cam_duong",
                        "division_type": "xã",
                        "short_codename": "cam_duong"
                    },
                    {
                        "name": "Xã Tả Phời",
                        "code": 2677,
                        "codename": "xa_ta_phoi",
                        "division_type": "xã",
                        "short_codename": "ta_phoi"
                    },
                    {
                        "name": "Xã Hợp Thành",
                        "code": 2680,
                        "codename": "xa_hop_thanh",
                        "division_type": "xã",
                        "short_codename": "hop_thanh"
                    },
                    {
                        "name": "Xã Cốc San",
                        "code": 2746,
                        "codename": "xa_coc_san",
                        "division_type": "xã",
                        "short_codename": "coc_san"
                    }
                ]
            },
            {
                "name": "Huyện Bát Xát",
                "code": 82,
                "codename": "huyen_bat_xat",
                "division_type": "huyện",
                "short_codename": "bat_xat",
                "wards": [
                    {
                        "name": "Thị trấn Bát Xát",
                        "code": 2683,
                        "codename": "thi_tran_bat_xat",
                        "division_type": "thị trấn",
                        "short_codename": "bat_xat"
                    },
                    {
                        "name": "Xã A Mú Sung",
                        "code": 2686,
                        "codename": "xa_a_mu_sung",
                        "division_type": "xã",
                        "short_codename": "a_mu_sung"
                    },
                    {
                        "name": "Xã Nậm Chạc",
                        "code": 2689,
                        "codename": "xa_nam_chac",
                        "division_type": "xã",
                        "short_codename": "nam_chac"
                    },
                    {
                        "name": "Xã A Lù",
                        "code": 2692,
                        "codename": "xa_a_lu",
                        "division_type": "xã",
                        "short_codename": "a_lu"
                    },
                    {
                        "name": "Xã Trịnh Tường",
                        "code": 2695,
                        "codename": "xa_trinh_tuong",
                        "division_type": "xã",
                        "short_codename": "trinh_tuong"
                    },
                    {
                        "name": "Xã Y Tý",
                        "code": 2701,
                        "codename": "xa_y_ty",
                        "division_type": "xã",
                        "short_codename": "y_ty"
                    },
                    {
                        "name": "Xã Cốc Mỳ",
                        "code": 2704,
                        "codename": "xa_coc_my",
                        "division_type": "xã",
                        "short_codename": "coc_my"
                    },
                    {
                        "name": "Xã Dền Sáng",
                        "code": 2707,
                        "codename": "xa_den_sang",
                        "division_type": "xã",
                        "short_codename": "den_sang"
                    },
                    {
                        "name": "Xã Bản Vược",
                        "code": 2710,
                        "codename": "xa_ban_vuoc",
                        "division_type": "xã",
                        "short_codename": "ban_vuoc"
                    },
                    {
                        "name": "Xã Sàng Ma Sáo",
                        "code": 2713,
                        "codename": "xa_sang_ma_sao",
                        "division_type": "xã",
                        "short_codename": "sang_ma_sao"
                    },
                    {
                        "name": "Xã Bản Qua",
                        "code": 2716,
                        "codename": "xa_ban_qua",
                        "division_type": "xã",
                        "short_codename": "ban_qua"
                    },
                    {
                        "name": "Xã Mường Vi",
                        "code": 2719,
                        "codename": "xa_muong_vi",
                        "division_type": "xã",
                        "short_codename": "muong_vi"
                    },
                    {
                        "name": "Xã Dền Thàng",
                        "code": 2722,
                        "codename": "xa_den_thang",
                        "division_type": "xã",
                        "short_codename": "den_thang"
                    },
                    {
                        "name": "Xã Bản Xèo",
                        "code": 2725,
                        "codename": "xa_ban_xeo",
                        "division_type": "xã",
                        "short_codename": "ban_xeo"
                    },
                    {
                        "name": "Xã Mường Hum",
                        "code": 2728,
                        "codename": "xa_muong_hum",
                        "division_type": "xã",
                        "short_codename": "muong_hum"
                    },
                    {
                        "name": "Xã Trung Lèng Hồ",
                        "code": 2731,
                        "codename": "xa_trung_leng_ho",
                        "division_type": "xã",
                        "short_codename": "trung_leng_ho"
                    },
                    {
                        "name": "Xã Quang Kim",
                        "code": 2734,
                        "codename": "xa_quang_kim",
                        "division_type": "xã",
                        "short_codename": "quang_kim"
                    },
                    {
                        "name": "Xã Pa Cheo",
                        "code": 2737,
                        "codename": "xa_pa_cheo",
                        "division_type": "xã",
                        "short_codename": "pa_cheo"
                    },
                    {
                        "name": "Xã Nậm Pung",
                        "code": 2740,
                        "codename": "xa_nam_pung",
                        "division_type": "xã",
                        "short_codename": "nam_pung"
                    },
                    {
                        "name": "Xã Phìn Ngan",
                        "code": 2743,
                        "codename": "xa_phin_ngan",
                        "division_type": "xã",
                        "short_codename": "phin_ngan"
                    },
                    {
                        "name": "Xã Tòng Sành",
                        "code": 2749,
                        "codename": "xa_tong_sanh",
                        "division_type": "xã",
                        "short_codename": "tong_sanh"
                    }
                ]
            },
            {
                "name": "Huyện Mường Khương",
                "code": 83,
                "codename": "huyen_muong_khuong",
                "division_type": "huyện",
                "short_codename": "muong_khuong",
                "wards": [
                    {
                        "name": "Xã Pha Long",
                        "code": 2752,
                        "codename": "xa_pha_long",
                        "division_type": "xã",
                        "short_codename": "pha_long"
                    },
                    {
                        "name": "Xã Tả Ngải Chồ",
                        "code": 2755,
                        "codename": "xa_ta_ngai_cho",
                        "division_type": "xã",
                        "short_codename": "ta_ngai_cho"
                    },
                    {
                        "name": "Xã Tung Chung Phố",
                        "code": 2758,
                        "codename": "xa_tung_chung_pho",
                        "division_type": "xã",
                        "short_codename": "tung_chung_pho"
                    },
                    {
                        "name": "Thị trấn Mường Khương",
                        "code": 2761,
                        "codename": "thi_tran_muong_khuong",
                        "division_type": "thị trấn",
                        "short_codename": "muong_khuong"
                    },
                    {
                        "name": "Xã Dìn Chin",
                        "code": 2764,
                        "codename": "xa_din_chin",
                        "division_type": "xã",
                        "short_codename": "din_chin"
                    },
                    {
                        "name": "Xã Tả Gia Khâu",
                        "code": 2767,
                        "codename": "xa_ta_gia_khau",
                        "division_type": "xã",
                        "short_codename": "ta_gia_khau"
                    },
                    {
                        "name": "Xã Nậm Chảy",
                        "code": 2770,
                        "codename": "xa_nam_chay",
                        "division_type": "xã",
                        "short_codename": "nam_chay"
                    },
                    {
                        "name": "Xã Nấm Lư",
                        "code": 2773,
                        "codename": "xa_nam_lu",
                        "division_type": "xã",
                        "short_codename": "nam_lu"
                    },
                    {
                        "name": "Xã Lùng Khấu Nhin",
                        "code": 2776,
                        "codename": "xa_lung_khau_nhin",
                        "division_type": "xã",
                        "short_codename": "lung_khau_nhin"
                    },
                    {
                        "name": "Xã Thanh Bình",
                        "code": 2779,
                        "codename": "xa_thanh_binh",
                        "division_type": "xã",
                        "short_codename": "thanh_binh"
                    },
                    {
                        "name": "Xã Cao Sơn",
                        "code": 2782,
                        "codename": "xa_cao_son",
                        "division_type": "xã",
                        "short_codename": "cao_son"
                    },
                    {
                        "name": "Xã Lùng Vai",
                        "code": 2785,
                        "codename": "xa_lung_vai",
                        "division_type": "xã",
                        "short_codename": "lung_vai"
                    },
                    {
                        "name": "Xã Bản Lầu",
                        "code": 2788,
                        "codename": "xa_ban_lau",
                        "division_type": "xã",
                        "short_codename": "ban_lau"
                    },
                    {
                        "name": "Xã La Pan Tẩn",
                        "code": 2791,
                        "codename": "xa_la_pan_tan",
                        "division_type": "xã",
                        "short_codename": "la_pan_tan"
                    },
                    {
                        "name": "Xã Tả Thàng",
                        "code": 2794,
                        "codename": "xa_ta_thang",
                        "division_type": "xã",
                        "short_codename": "ta_thang"
                    },
                    {
                        "name": "Xã Bản Sen",
                        "code": 2797,
                        "codename": "xa_ban_sen",
                        "division_type": "xã",
                        "short_codename": "ban_sen"
                    }
                ]
            },
            {
                "name": "Huyện Si Ma Cai",
                "code": 84,
                "codename": "huyen_si_ma_cai",
                "division_type": "huyện",
                "short_codename": "si_ma_cai",
                "wards": [
                    {
                        "name": "Xã Nàn Sán",
                        "code": 2800,
                        "codename": "xa_nan_san",
                        "division_type": "xã",
                        "short_codename": "nan_san"
                    },
                    {
                        "name": "Xã Thào Chư Phìn",
                        "code": 2803,
                        "codename": "xa_thao_chu_phin",
                        "division_type": "xã",
                        "short_codename": "thao_chu_phin"
                    },
                    {
                        "name": "Xã Bản Mế",
                        "code": 2806,
                        "codename": "xa_ban_me",
                        "division_type": "xã",
                        "short_codename": "ban_me"
                    },
                    {
                        "name": "Thị trấn Si Ma Cai",
                        "code": 2809,
                        "codename": "thi_tran_si_ma_cai",
                        "division_type": "thị trấn",
                        "short_codename": "si_ma_cai"
                    },
                    {
                        "name": "Xã Sán Chải",
                        "code": 2812,
                        "codename": "xa_san_chai",
                        "division_type": "xã",
                        "short_codename": "san_chai"
                    },
                    {
                        "name": "Xã Lùng Thẩn",
                        "code": 2818,
                        "codename": "xa_lung_than",
                        "division_type": "xã",
                        "short_codename": "lung_than"
                    },
                    {
                        "name": "Xã Cán Cấu",
                        "code": 2821,
                        "codename": "xa_can_cau",
                        "division_type": "xã",
                        "short_codename": "can_cau"
                    },
                    {
                        "name": "Xã Sín Chéng",
                        "code": 2824,
                        "codename": "xa_sin_cheng",
                        "division_type": "xã",
                        "short_codename": "sin_cheng"
                    },
                    {
                        "name": "Xã Quan Hồ Thẩn",
                        "code": 2827,
                        "codename": "xa_quan_ho_than",
                        "division_type": "xã",
                        "short_codename": "quan_ho_than"
                    },
                    {
                        "name": "Xã Nàn Xín",
                        "code": 2836,
                        "codename": "xa_nan_xin",
                        "division_type": "xã",
                        "short_codename": "nan_xin"
                    }
                ]
            },
            {
                "name": "Huyện Bắc Hà",
                "code": 85,
                "codename": "huyen_bac_ha",
                "division_type": "huyện",
                "short_codename": "bac_ha",
                "wards": [
                    {
                        "name": "Thị trấn Bắc Hà",
                        "code": 2839,
                        "codename": "thi_tran_bac_ha",
                        "division_type": "thị trấn",
                        "short_codename": "bac_ha"
                    },
                    {
                        "name": "Xã Lùng Cải",
                        "code": 2842,
                        "codename": "xa_lung_cai",
                        "division_type": "xã",
                        "short_codename": "lung_cai"
                    },
                    {
                        "name": "Xã Lùng Phình",
                        "code": 2848,
                        "codename": "xa_lung_phinh",
                        "division_type": "xã",
                        "short_codename": "lung_phinh"
                    },
                    {
                        "name": "Xã Tả Van Chư",
                        "code": 2851,
                        "codename": "xa_ta_van_chu",
                        "division_type": "xã",
                        "short_codename": "ta_van_chu"
                    },
                    {
                        "name": "Xã Tả Củ Tỷ",
                        "code": 2854,
                        "codename": "xa_ta_cu_ty",
                        "division_type": "xã",
                        "short_codename": "ta_cu_ty"
                    },
                    {
                        "name": "Xã Thải Giàng Phố",
                        "code": 2857,
                        "codename": "xa_thai_giang_pho",
                        "division_type": "xã",
                        "short_codename": "thai_giang_pho"
                    },
                    {
                        "name": "Xã Hoàng Thu Phố",
                        "code": 2863,
                        "codename": "xa_hoang_thu_pho",
                        "division_type": "xã",
                        "short_codename": "hoang_thu_pho"
                    },
                    {
                        "name": "Xã Bản Phố",
                        "code": 2866,
                        "codename": "xa_ban_pho",
                        "division_type": "xã",
                        "short_codename": "ban_pho"
                    },
                    {
                        "name": "Xã Bản Liền",
                        "code": 2869,
                        "codename": "xa_ban_lien",
                        "division_type": "xã",
                        "short_codename": "ban_lien"
                    },
                    {
                        "name": "Xã Tà Chải",
                        "code": 2872,
                        "codename": "xa_ta_chai",
                        "division_type": "xã",
                        "short_codename": "ta_chai"
                    },
                    {
                        "name": "Xã Na Hối",
                        "code": 2875,
                        "codename": "xa_na_hoi",
                        "division_type": "xã",
                        "short_codename": "na_hoi"
                    },
                    {
                        "name": "Xã Cốc Ly",
                        "code": 2878,
                        "codename": "xa_coc_ly",
                        "division_type": "xã",
                        "short_codename": "coc_ly"
                    },
                    {
                        "name": "Xã Nậm Mòn",
                        "code": 2881,
                        "codename": "xa_nam_mon",
                        "division_type": "xã",
                        "short_codename": "nam_mon"
                    },
                    {
                        "name": "Xã Nậm Đét",
                        "code": 2884,
                        "codename": "xa_nam_det",
                        "division_type": "xã",
                        "short_codename": "nam_det"
                    },
                    {
                        "name": "Xã Nậm Khánh",
                        "code": 2887,
                        "codename": "xa_nam_khanh",
                        "division_type": "xã",
                        "short_codename": "nam_khanh"
                    },
                    {
                        "name": "Xã Bảo Nhai",
                        "code": 2890,
                        "codename": "xa_bao_nhai",
                        "division_type": "xã",
                        "short_codename": "bao_nhai"
                    },
                    {
                        "name": "Xã Nậm Lúc",
                        "code": 2893,
                        "codename": "xa_nam_luc",
                        "division_type": "xã",
                        "short_codename": "nam_luc"
                    },
                    {
                        "name": "Xã Cốc Lầu",
                        "code": 2896,
                        "codename": "xa_coc_lau",
                        "division_type": "xã",
                        "short_codename": "coc_lau"
                    },
                    {
                        "name": "Xã Bản Cái",
                        "code": 2899,
                        "codename": "xa_ban_cai",
                        "division_type": "xã",
                        "short_codename": "ban_cai"
                    }
                ]
            },
            {
                "name": "Huyện Bảo Thắng",
                "code": 86,
                "codename": "huyen_bao_thang",
                "division_type": "huyện",
                "short_codename": "bao_thang",
                "wards": [
                    {
                        "name": "Thị trấn N.T Phong Hải",
                        "code": 2902,
                        "codename": "thi_tran_n_t_phong_hai",
                        "division_type": "thị trấn",
                        "short_codename": "n_t_phong_hai"
                    },
                    {
                        "name": "Thị trấn Phố Lu",
                        "code": 2905,
                        "codename": "thi_tran_pho_lu",
                        "division_type": "thị trấn",
                        "short_codename": "pho_lu"
                    },
                    {
                        "name": "Thị trấn Tằng Loỏng",
                        "code": 2908,
                        "codename": "thi_tran_tang_loong",
                        "division_type": "thị trấn",
                        "short_codename": "tang_loong"
                    },
                    {
                        "name": "Xã Bản Phiệt",
                        "code": 2911,
                        "codename": "xa_ban_phiet",
                        "division_type": "xã",
                        "short_codename": "ban_phiet"
                    },
                    {
                        "name": "Xã Bản Cầm",
                        "code": 2914,
                        "codename": "xa_ban_cam",
                        "division_type": "xã",
                        "short_codename": "ban_cam"
                    },
                    {
                        "name": "Xã Thái Niên",
                        "code": 2917,
                        "codename": "xa_thai_nien",
                        "division_type": "xã",
                        "short_codename": "thai_nien"
                    },
                    {
                        "name": "Xã Phong Niên",
                        "code": 2920,
                        "codename": "xa_phong_nien",
                        "division_type": "xã",
                        "short_codename": "phong_nien"
                    },
                    {
                        "name": "Xã Gia Phú",
                        "code": 2923,
                        "codename": "xa_gia_phu",
                        "division_type": "xã",
                        "short_codename": "gia_phu"
                    },
                    {
                        "name": "Xã Xuân Quang",
                        "code": 2926,
                        "codename": "xa_xuan_quang",
                        "division_type": "xã",
                        "short_codename": "xuan_quang"
                    },
                    {
                        "name": "Xã Sơn Hải",
                        "code": 2929,
                        "codename": "xa_son_hai",
                        "division_type": "xã",
                        "short_codename": "son_hai"
                    },
                    {
                        "name": "Xã Xuân Giao",
                        "code": 2932,
                        "codename": "xa_xuan_giao",
                        "division_type": "xã",
                        "short_codename": "xuan_giao"
                    },
                    {
                        "name": "Xã Trì Quang",
                        "code": 2935,
                        "codename": "xa_tri_quang",
                        "division_type": "xã",
                        "short_codename": "tri_quang"
                    },
                    {
                        "name": "Xã Sơn Hà",
                        "code": 2938,
                        "codename": "xa_son_ha",
                        "division_type": "xã",
                        "short_codename": "son_ha"
                    },
                    {
                        "name": "Xã Phú Nhuận",
                        "code": 2944,
                        "codename": "xa_phu_nhuan",
                        "division_type": "xã",
                        "short_codename": "phu_nhuan"
                    }
                ]
            },
            {
                "name": "Huyện Bảo Yên",
                "code": 87,
                "codename": "huyen_bao_yen",
                "division_type": "huyện",
                "short_codename": "bao_yen",
                "wards": [
                    {
                        "name": "Thị trấn Phố Ràng",
                        "code": 2947,
                        "codename": "thi_tran_pho_rang",
                        "division_type": "thị trấn",
                        "short_codename": "pho_rang"
                    },
                    {
                        "name": "Xã Tân Tiến",
                        "code": 2950,
                        "codename": "xa_tan_tien",
                        "division_type": "xã",
                        "short_codename": "tan_tien"
                    },
                    {
                        "name": "Xã Nghĩa Đô",
                        "code": 2953,
                        "codename": "xa_nghia_do",
                        "division_type": "xã",
                        "short_codename": "nghia_do"
                    },
                    {
                        "name": "Xã Vĩnh Yên",
                        "code": 2956,
                        "codename": "xa_vinh_yen",
                        "division_type": "xã",
                        "short_codename": "vinh_yen"
                    },
                    {
                        "name": "Xã Điện Quan",
                        "code": 2959,
                        "codename": "xa_dien_quan",
                        "division_type": "xã",
                        "short_codename": "dien_quan"
                    },
                    {
                        "name": "Xã Xuân Hoà",
                        "code": 2962,
                        "codename": "xa_xuan_hoa",
                        "division_type": "xã",
                        "short_codename": "xuan_hoa"
                    },
                    {
                        "name": "Xã Tân Dương",
                        "code": 2965,
                        "codename": "xa_tan_duong",
                        "division_type": "xã",
                        "short_codename": "tan_duong"
                    },
                    {
                        "name": "Xã Thượng Hà",
                        "code": 2968,
                        "codename": "xa_thuong_ha",
                        "division_type": "xã",
                        "short_codename": "thuong_ha"
                    },
                    {
                        "name": "Xã Kim Sơn",
                        "code": 2971,
                        "codename": "xa_kim_son",
                        "division_type": "xã",
                        "short_codename": "kim_son"
                    },
                    {
                        "name": "Xã Cam Cọn",
                        "code": 2974,
                        "codename": "xa_cam_con",
                        "division_type": "xã",
                        "short_codename": "cam_con"
                    },
                    {
                        "name": "Xã Minh Tân",
                        "code": 2977,
                        "codename": "xa_minh_tan",
                        "division_type": "xã",
                        "short_codename": "minh_tan"
                    },
                    {
                        "name": "Xã Xuân Thượng",
                        "code": 2980,
                        "codename": "xa_xuan_thuong",
                        "division_type": "xã",
                        "short_codename": "xuan_thuong"
                    },
                    {
                        "name": "Xã Việt Tiến",
                        "code": 2983,
                        "codename": "xa_viet_tien",
                        "division_type": "xã",
                        "short_codename": "viet_tien"
                    },
                    {
                        "name": "Xã Yên Sơn",
                        "code": 2986,
                        "codename": "xa_yen_son",
                        "division_type": "xã",
                        "short_codename": "yen_son"
                    },
                    {
                        "name": "Xã Bảo Hà",
                        "code": 2989,
                        "codename": "xa_bao_ha",
                        "division_type": "xã",
                        "short_codename": "bao_ha"
                    },
                    {
                        "name": "Xã Lương Sơn",
                        "code": 2992,
                        "codename": "xa_luong_son",
                        "division_type": "xã",
                        "short_codename": "luong_son"
                    },
                    {
                        "name": "Xã Phúc Khánh",
                        "code": 2998,
                        "codename": "xa_phuc_khanh",
                        "division_type": "xã",
                        "short_codename": "phuc_khanh"
                    }
                ]
            },
            {
                "name": "Thị xã Sa Pa",
                "code": 88,
                "codename": "thi_xa_sa_pa",
                "division_type": "thị xã",
                "short_codename": "sa_pa",
                "wards": [
                    {
                        "name": "Phường Sa Pa",
                        "code": 3001,
                        "codename": "phuong_sa_pa",
                        "division_type": "phường",
                        "short_codename": "sa_pa"
                    },
                    {
                        "name": "Phường Sa Pả",
                        "code": 3002,
                        "codename": "phuong_sa_pa",
                        "division_type": "phường",
                        "short_codename": "sa_pa"
                    },
                    {
                        "name": "Phường Ô Quý Hồ",
                        "code": 3003,
                        "codename": "phuong_o_quy_ho",
                        "division_type": "phường",
                        "short_codename": "o_quy_ho"
                    },
                    {
                        "name": "Xã Ngũ Chỉ Sơn",
                        "code": 3004,
                        "codename": "xa_ngu_chi_son",
                        "division_type": "xã",
                        "short_codename": "ngu_chi_son"
                    },
                    {
                        "name": "Phường Phan Si Păng",
                        "code": 3006,
                        "codename": "phuong_phan_si_pang",
                        "division_type": "phường",
                        "short_codename": "phan_si_pang"
                    },
                    {
                        "name": "Xã Trung Chải",
                        "code": 3010,
                        "codename": "xa_trung_chai",
                        "division_type": "xã",
                        "short_codename": "trung_chai"
                    },
                    {
                        "name": "Xã Tả Phìn",
                        "code": 3013,
                        "codename": "xa_ta_phin",
                        "division_type": "xã",
                        "short_codename": "ta_phin"
                    },
                    {
                        "name": "Phường Hàm Rồng",
                        "code": 3016,
                        "codename": "phuong_ham_rong",
                        "division_type": "phường",
                        "short_codename": "ham_rong"
                    },
                    {
                        "name": "Xã Hoàng Liên",
                        "code": 3019,
                        "codename": "xa_hoang_lien",
                        "division_type": "xã",
                        "short_codename": "hoang_lien"
                    },
                    {
                        "name": "Xã Thanh Bình",
                        "code": 3022,
                        "codename": "xa_thanh_binh",
                        "division_type": "xã",
                        "short_codename": "thanh_binh"
                    },
                    {
                        "name": "Phường Cầu Mây",
                        "code": 3028,
                        "codename": "phuong_cau_may",
                        "division_type": "phường",
                        "short_codename": "cau_may"
                    },
                    {
                        "name": "Xã Mường Hoa",
                        "code": 3037,
                        "codename": "xa_muong_hoa",
                        "division_type": "xã",
                        "short_codename": "muong_hoa"
                    },
                    {
                        "name": "Xã Tả Van",
                        "code": 3040,
                        "codename": "xa_ta_van",
                        "division_type": "xã",
                        "short_codename": "ta_van"
                    },
                    {
                        "name": "Xã Mường Bo",
                        "code": 3043,
                        "codename": "xa_muong_bo",
                        "division_type": "xã",
                        "short_codename": "muong_bo"
                    },
                    {
                        "name": "Xã Bản Hồ",
                        "code": 3046,
                        "codename": "xa_ban_ho",
                        "division_type": "xã",
                        "short_codename": "ban_ho"
                    },
                    {
                        "name": "Xã Liên Minh",
                        "code": 3052,
                        "codename": "xa_lien_minh",
                        "division_type": "xã",
                        "short_codename": "lien_minh"
                    }
                ]
            },
            {
                "name": "Huyện Văn Bàn",
                "code": 89,
                "codename": "huyen_van_ban",
                "division_type": "huyện",
                "short_codename": "van_ban",
                "wards": [
                    {
                        "name": "Thị trấn Khánh Yên",
                        "code": 3055,
                        "codename": "thi_tran_khanh_yen",
                        "division_type": "thị trấn",
                        "short_codename": "khanh_yen"
                    },
                    {
                        "name": "Xã Võ Lao",
                        "code": 3061,
                        "codename": "xa_vo_lao",
                        "division_type": "xã",
                        "short_codename": "vo_lao"
                    },
                    {
                        "name": "Xã Sơn Thuỷ",
                        "code": 3064,
                        "codename": "xa_son_thuy",
                        "division_type": "xã",
                        "short_codename": "son_thuy"
                    },
                    {
                        "name": "Xã Nậm Mả",
                        "code": 3067,
                        "codename": "xa_nam_ma",
                        "division_type": "xã",
                        "short_codename": "nam_ma"
                    },
                    {
                        "name": "Xã Tân Thượng",
                        "code": 3070,
                        "codename": "xa_tan_thuong",
                        "division_type": "xã",
                        "short_codename": "tan_thuong"
                    },
                    {
                        "name": "Xã Nậm Rạng",
                        "code": 3073,
                        "codename": "xa_nam_rang",
                        "division_type": "xã",
                        "short_codename": "nam_rang"
                    },
                    {
                        "name": "Xã Nậm Chầy",
                        "code": 3076,
                        "codename": "xa_nam_chay",
                        "division_type": "xã",
                        "short_codename": "nam_chay"
                    },
                    {
                        "name": "Xã Tân An",
                        "code": 3079,
                        "codename": "xa_tan_an",
                        "division_type": "xã",
                        "short_codename": "tan_an"
                    },
                    {
                        "name": "Xã Khánh Yên Thượng",
                        "code": 3082,
                        "codename": "xa_khanh_yen_thuong",
                        "division_type": "xã",
                        "short_codename": "khanh_yen_thuong"
                    },
                    {
                        "name": "Xã Nậm Xé",
                        "code": 3085,
                        "codename": "xa_nam_xe",
                        "division_type": "xã",
                        "short_codename": "nam_xe"
                    },
                    {
                        "name": "Xã Dần Thàng",
                        "code": 3088,
                        "codename": "xa_dan_thang",
                        "division_type": "xã",
                        "short_codename": "dan_thang"
                    },
                    {
                        "name": "Xã Chiềng Ken",
                        "code": 3091,
                        "codename": "xa_chieng_ken",
                        "division_type": "xã",
                        "short_codename": "chieng_ken"
                    },
                    {
                        "name": "Xã Làng Giàng",
                        "code": 3094,
                        "codename": "xa_lang_giang",
                        "division_type": "xã",
                        "short_codename": "lang_giang"
                    },
                    {
                        "name": "Xã Hoà Mạc",
                        "code": 3097,
                        "codename": "xa_hoa_mac",
                        "division_type": "xã",
                        "short_codename": "hoa_mac"
                    },
                    {
                        "name": "Xã Khánh Yên Trung",
                        "code": 3100,
                        "codename": "xa_khanh_yen_trung",
                        "division_type": "xã",
                        "short_codename": "khanh_yen_trung"
                    },
                    {
                        "name": "Xã Khánh Yên Hạ",
                        "code": 3103,
                        "codename": "xa_khanh_yen_ha",
                        "division_type": "xã",
                        "short_codename": "khanh_yen_ha"
                    },
                    {
                        "name": "Xã Dương Quỳ",
                        "code": 3106,
                        "codename": "xa_duong_quy",
                        "division_type": "xã",
                        "short_codename": "duong_quy"
                    },
                    {
                        "name": "Xã Nậm Tha",
                        "code": 3109,
                        "codename": "xa_nam_tha",
                        "division_type": "xã",
                        "short_codename": "nam_tha"
                    },
                    {
                        "name": "Xã Minh Lương",
                        "code": 3112,
                        "codename": "xa_minh_luong",
                        "division_type": "xã",
                        "short_codename": "minh_luong"
                    },
                    {
                        "name": "Xã Thẩm Dương",
                        "code": 3115,
                        "codename": "xa_tham_duong",
                        "division_type": "xã",
                        "short_codename": "tham_duong"
                    },
                    {
                        "name": "Xã Liêm Phú",
                        "code": 3118,
                        "codename": "xa_liem_phu",
                        "division_type": "xã",
                        "short_codename": "liem_phu"
                    },
                    {
                        "name": "Xã Nậm Xây",
                        "code": 3121,
                        "codename": "xa_nam_xay",
                        "division_type": "xã",
                        "short_codename": "nam_xay"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Điện Biên",
        "code": 11,
        "codename": "tinh_dien_bien",
        "division_type": "tỉnh",
        "phone_code": 215,
        "districts": [
            {
                "name": "Thành phố Điện Biên Phủ",
                "code": 94,
                "codename": "thanh_pho_dien_bien_phu",
                "division_type": "thành phố",
                "short_codename": "dien_bien_phu",
                "wards": [
                    {
                        "name": "Phường Noong Bua",
                        "code": 3124,
                        "codename": "phuong_noong_bua",
                        "division_type": "phường",
                        "short_codename": "noong_bua"
                    },
                    {
                        "name": "Phường Him Lam",
                        "code": 3127,
                        "codename": "phuong_him_lam",
                        "division_type": "phường",
                        "short_codename": "him_lam"
                    },
                    {
                        "name": "Phường Thanh Bình",
                        "code": 3130,
                        "codename": "phuong_thanh_binh",
                        "division_type": "phường",
                        "short_codename": "thanh_binh"
                    },
                    {
                        "name": "Phường Tân Thanh",
                        "code": 3133,
                        "codename": "phuong_tan_thanh",
                        "division_type": "phường",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Phường Mường Thanh",
                        "code": 3136,
                        "codename": "phuong_muong_thanh",
                        "division_type": "phường",
                        "short_codename": "muong_thanh"
                    },
                    {
                        "name": "Phường Nam Thanh",
                        "code": 3139,
                        "codename": "phuong_nam_thanh",
                        "division_type": "phường",
                        "short_codename": "nam_thanh"
                    },
                    {
                        "name": "Phường Thanh Trường",
                        "code": 3142,
                        "codename": "phuong_thanh_truong",
                        "division_type": "phường",
                        "short_codename": "thanh_truong"
                    },
                    {
                        "name": "Xã Thanh Minh",
                        "code": 3145,
                        "codename": "xa_thanh_minh",
                        "division_type": "xã",
                        "short_codename": "thanh_minh"
                    },
                    {
                        "name": "Xã Nà Tấu",
                        "code": 3316,
                        "codename": "xa_na_tau",
                        "division_type": "xã",
                        "short_codename": "na_tau"
                    },
                    {
                        "name": "Xã Nà Nhạn",
                        "code": 3317,
                        "codename": "xa_na_nhan",
                        "division_type": "xã",
                        "short_codename": "na_nhan"
                    },
                    {
                        "name": "Xã Mường Phăng",
                        "code": 3325,
                        "codename": "xa_muong_phang",
                        "division_type": "xã",
                        "short_codename": "muong_phang"
                    },
                    {
                        "name": "Xã Pá Khoang",
                        "code": 3326,
                        "codename": "xa_pa_khoang",
                        "division_type": "xã",
                        "short_codename": "pa_khoang"
                    }
                ]
            },
            {
                "name": "Thị Xã Mường Lay",
                "code": 95,
                "codename": "thi_xa_muong_lay",
                "division_type": "thị xã",
                "short_codename": "muong_lay",
                "wards": [
                    {
                        "name": "Phường Sông Đà",
                        "code": 3148,
                        "codename": "phuong_song_da",
                        "division_type": "phường",
                        "short_codename": "song_da"
                    },
                    {
                        "name": "Phường Na Lay",
                        "code": 3151,
                        "codename": "phuong_na_lay",
                        "division_type": "phường",
                        "short_codename": "na_lay"
                    },
                    {
                        "name": "Xã Lay Nưa",
                        "code": 3184,
                        "codename": "xa_lay_nua",
                        "division_type": "xã",
                        "short_codename": "lay_nua"
                    }
                ]
            },
            {
                "name": "Huyện Mường Nhé",
                "code": 96,
                "codename": "huyen_muong_nhe",
                "division_type": "huyện",
                "short_codename": "muong_nhe",
                "wards": [
                    {
                        "name": "Xã Sín Thầu",
                        "code": 3154,
                        "codename": "xa_sin_thau",
                        "division_type": "xã",
                        "short_codename": "sin_thau"
                    },
                    {
                        "name": "Xã Sen Thượng",
                        "code": 3155,
                        "codename": "xa_sen_thuong",
                        "division_type": "xã",
                        "short_codename": "sen_thuong"
                    },
                    {
                        "name": "Xã Chung Chải",
                        "code": 3157,
                        "codename": "xa_chung_chai",
                        "division_type": "xã",
                        "short_codename": "chung_chai"
                    },
                    {
                        "name": "Xã Leng Su Sìn",
                        "code": 3158,
                        "codename": "xa_leng_su_sin",
                        "division_type": "xã",
                        "short_codename": "leng_su_sin"
                    },
                    {
                        "name": "Xã Pá Mỳ",
                        "code": 3159,
                        "codename": "xa_pa_my",
                        "division_type": "xã",
                        "short_codename": "pa_my"
                    },
                    {
                        "name": "Xã Mường Nhé",
                        "code": 3160,
                        "codename": "xa_muong_nhe",
                        "division_type": "xã",
                        "short_codename": "muong_nhe"
                    },
                    {
                        "name": "Xã Nậm Vì",
                        "code": 3161,
                        "codename": "xa_nam_vi",
                        "division_type": "xã",
                        "short_codename": "nam_vi"
                    },
                    {
                        "name": "Xã Nậm Kè",
                        "code": 3162,
                        "codename": "xa_nam_ke",
                        "division_type": "xã",
                        "short_codename": "nam_ke"
                    },
                    {
                        "name": "Xã Mường Toong",
                        "code": 3163,
                        "codename": "xa_muong_toong",
                        "division_type": "xã",
                        "short_codename": "muong_toong"
                    },
                    {
                        "name": "Xã Quảng Lâm",
                        "code": 3164,
                        "codename": "xa_quang_lam",
                        "division_type": "xã",
                        "short_codename": "quang_lam"
                    },
                    {
                        "name": "Xã Huổi Lếnh",
                        "code": 3177,
                        "codename": "xa_huoi_lenh",
                        "division_type": "xã",
                        "short_codename": "huoi_lenh"
                    }
                ]
            },
            {
                "name": "Huyện Mường Chà",
                "code": 97,
                "codename": "huyen_muong_cha",
                "division_type": "huyện",
                "short_codename": "muong_cha",
                "wards": [
                    {
                        "name": "Thị Trấn Mường Chà",
                        "code": 3172,
                        "codename": "thi_tran_muong_cha",
                        "division_type": "thị trấn",
                        "short_codename": "muong_cha"
                    },
                    {
                        "name": "Xã Xá Tổng",
                        "code": 3178,
                        "codename": "xa_xa_tong",
                        "division_type": "xã",
                        "short_codename": "xa_tong"
                    },
                    {
                        "name": "Xã Mường Tùng",
                        "code": 3181,
                        "codename": "xa_muong_tung",
                        "division_type": "xã",
                        "short_codename": "muong_tung"
                    },
                    {
                        "name": "Xã Hừa Ngài",
                        "code": 3190,
                        "codename": "xa_hua_ngai",
                        "division_type": "xã",
                        "short_codename": "hua_ngai"
                    },
                    {
                        "name": "Xã Huổi Mí",
                        "code": 3191,
                        "codename": "xa_huoi_mi",
                        "division_type": "xã",
                        "short_codename": "huoi_mi"
                    },
                    {
                        "name": "Xã Pa Ham",
                        "code": 3193,
                        "codename": "xa_pa_ham",
                        "division_type": "xã",
                        "short_codename": "pa_ham"
                    },
                    {
                        "name": "Xã Nậm Nèn",
                        "code": 3194,
                        "codename": "xa_nam_nen",
                        "division_type": "xã",
                        "short_codename": "nam_nen"
                    },
                    {
                        "name": "Xã Huổi Lèng",
                        "code": 3196,
                        "codename": "xa_huoi_leng",
                        "division_type": "xã",
                        "short_codename": "huoi_leng"
                    },
                    {
                        "name": "Xã Sa Lông",
                        "code": 3197,
                        "codename": "xa_sa_long",
                        "division_type": "xã",
                        "short_codename": "sa_long"
                    },
                    {
                        "name": "Xã Ma Thì Hồ",
                        "code": 3200,
                        "codename": "xa_ma_thi_ho",
                        "division_type": "xã",
                        "short_codename": "ma_thi_ho"
                    },
                    {
                        "name": "Xã Na Sang",
                        "code": 3201,
                        "codename": "xa_na_sang",
                        "division_type": "xã",
                        "short_codename": "na_sang"
                    },
                    {
                        "name": "Xã Mường Mươn",
                        "code": 3202,
                        "codename": "xa_muong_muon",
                        "division_type": "xã",
                        "short_codename": "muong_muon"
                    }
                ]
            },
            {
                "name": "Huyện Tủa Chùa",
                "code": 98,
                "codename": "huyen_tua_chua",
                "division_type": "huyện",
                "short_codename": "tua_chua",
                "wards": [
                    {
                        "name": "Thị trấn Tủa Chùa",
                        "code": 3217,
                        "codename": "thi_tran_tua_chua",
                        "division_type": "thị trấn",
                        "short_codename": "tua_chua"
                    },
                    {
                        "name": "Xã Huổi Só",
                        "code": 3220,
                        "codename": "xa_huoi_so",
                        "division_type": "xã",
                        "short_codename": "huoi_so"
                    },
                    {
                        "name": "Xã Xín Chải",
                        "code": 3223,
                        "codename": "xa_xin_chai",
                        "division_type": "xã",
                        "short_codename": "xin_chai"
                    },
                    {
                        "name": "Xã Tả Sìn Thàng",
                        "code": 3226,
                        "codename": "xa_ta_sin_thang",
                        "division_type": "xã",
                        "short_codename": "ta_sin_thang"
                    },
                    {
                        "name": "Xã Lao Xả Phình",
                        "code": 3229,
                        "codename": "xa_lao_xa_phinh",
                        "division_type": "xã",
                        "short_codename": "lao_xa_phinh"
                    },
                    {
                        "name": "Xã Tả Phìn",
                        "code": 3232,
                        "codename": "xa_ta_phin",
                        "division_type": "xã",
                        "short_codename": "ta_phin"
                    },
                    {
                        "name": "Xã Tủa Thàng",
                        "code": 3235,
                        "codename": "xa_tua_thang",
                        "division_type": "xã",
                        "short_codename": "tua_thang"
                    },
                    {
                        "name": "Xã Trung Thu",
                        "code": 3238,
                        "codename": "xa_trung_thu",
                        "division_type": "xã",
                        "short_codename": "trung_thu"
                    },
                    {
                        "name": "Xã Sính Phình",
                        "code": 3241,
                        "codename": "xa_sinh_phinh",
                        "division_type": "xã",
                        "short_codename": "sinh_phinh"
                    },
                    {
                        "name": "Xã Sáng Nhè",
                        "code": 3244,
                        "codename": "xa_sang_nhe",
                        "division_type": "xã",
                        "short_codename": "sang_nhe"
                    },
                    {
                        "name": "Xã Mường Đun",
                        "code": 3247,
                        "codename": "xa_muong_dun",
                        "division_type": "xã",
                        "short_codename": "muong_dun"
                    },
                    {
                        "name": "Xã Mường Báng",
                        "code": 3250,
                        "codename": "xa_muong_bang",
                        "division_type": "xã",
                        "short_codename": "muong_bang"
                    }
                ]
            },
            {
                "name": "Huyện Tuần Giáo",
                "code": 99,
                "codename": "huyen_tuan_giao",
                "division_type": "huyện",
                "short_codename": "tuan_giao",
                "wards": [
                    {
                        "name": "Thị trấn Tuần Giáo",
                        "code": 3253,
                        "codename": "thi_tran_tuan_giao",
                        "division_type": "thị trấn",
                        "short_codename": "tuan_giao"
                    },
                    {
                        "name": "Xã Phình Sáng",
                        "code": 3259,
                        "codename": "xa_phinh_sang",
                        "division_type": "xã",
                        "short_codename": "phinh_sang"
                    },
                    {
                        "name": "Xã Rạng Đông",
                        "code": 3260,
                        "codename": "xa_rang_dong",
                        "division_type": "xã",
                        "short_codename": "rang_dong"
                    },
                    {
                        "name": "Xã Mùn Chung",
                        "code": 3262,
                        "codename": "xa_mun_chung",
                        "division_type": "xã",
                        "short_codename": "mun_chung"
                    },
                    {
                        "name": "Xã Nà Tòng",
                        "code": 3263,
                        "codename": "xa_na_tong",
                        "division_type": "xã",
                        "short_codename": "na_tong"
                    },
                    {
                        "name": "Xã Ta Ma",
                        "code": 3265,
                        "codename": "xa_ta_ma",
                        "division_type": "xã",
                        "short_codename": "ta_ma"
                    },
                    {
                        "name": "Xã Mường Mùn",
                        "code": 3268,
                        "codename": "xa_muong_mun",
                        "division_type": "xã",
                        "short_codename": "muong_mun"
                    },
                    {
                        "name": "Xã Pú Xi",
                        "code": 3269,
                        "codename": "xa_pu_xi",
                        "division_type": "xã",
                        "short_codename": "pu_xi"
                    },
                    {
                        "name": "Xã Pú Nhung",
                        "code": 3271,
                        "codename": "xa_pu_nhung",
                        "division_type": "xã",
                        "short_codename": "pu_nhung"
                    },
                    {
                        "name": "Xã Quài Nưa",
                        "code": 3274,
                        "codename": "xa_quai_nua",
                        "division_type": "xã",
                        "short_codename": "quai_nua"
                    },
                    {
                        "name": "Xã Mường Thín",
                        "code": 3277,
                        "codename": "xa_muong_thin",
                        "division_type": "xã",
                        "short_codename": "muong_thin"
                    },
                    {
                        "name": "Xã Tỏa Tình",
                        "code": 3280,
                        "codename": "xa_toa_tinh",
                        "division_type": "xã",
                        "short_codename": "toa_tinh"
                    },
                    {
                        "name": "Xã Nà Sáy",
                        "code": 3283,
                        "codename": "xa_na_say",
                        "division_type": "xã",
                        "short_codename": "na_say"
                    },
                    {
                        "name": "Xã Mường Khong",
                        "code": 3284,
                        "codename": "xa_muong_khong",
                        "division_type": "xã",
                        "short_codename": "muong_khong"
                    },
                    {
                        "name": "Xã Quài Cang",
                        "code": 3289,
                        "codename": "xa_quai_cang",
                        "division_type": "xã",
                        "short_codename": "quai_cang"
                    },
                    {
                        "name": "Xã Quài Tở",
                        "code": 3295,
                        "codename": "xa_quai_to",
                        "division_type": "xã",
                        "short_codename": "quai_to"
                    },
                    {
                        "name": "Xã Chiềng Sinh",
                        "code": 3298,
                        "codename": "xa_chieng_sinh",
                        "division_type": "xã",
                        "short_codename": "chieng_sinh"
                    },
                    {
                        "name": "Xã Chiềng Đông",
                        "code": 3299,
                        "codename": "xa_chieng_dong",
                        "division_type": "xã",
                        "short_codename": "chieng_dong"
                    },
                    {
                        "name": "Xã Tênh Phông",
                        "code": 3304,
                        "codename": "xa_tenh_phong",
                        "division_type": "xã",
                        "short_codename": "tenh_phong"
                    }
                ]
            },
            {
                "name": "Huyện Điện Biên",
                "code": 100,
                "codename": "huyen_dien_bien",
                "division_type": "huyện",
                "short_codename": "dien_bien",
                "wards": [
                    {
                        "name": "Xã Mường Pồn",
                        "code": 3319,
                        "codename": "xa_muong_pon",
                        "division_type": "xã",
                        "short_codename": "muong_pon"
                    },
                    {
                        "name": "Xã Thanh Nưa",
                        "code": 3322,
                        "codename": "xa_thanh_nua",
                        "division_type": "xã",
                        "short_codename": "thanh_nua"
                    },
                    {
                        "name": "Xã Hua Thanh",
                        "code": 3323,
                        "codename": "xa_hua_thanh",
                        "division_type": "xã",
                        "short_codename": "hua_thanh"
                    },
                    {
                        "name": "Xã Thanh Luông",
                        "code": 3328,
                        "codename": "xa_thanh_luong",
                        "division_type": "xã",
                        "short_codename": "thanh_luong"
                    },
                    {
                        "name": "Xã Thanh Hưng",
                        "code": 3331,
                        "codename": "xa_thanh_hung",
                        "division_type": "xã",
                        "short_codename": "thanh_hung"
                    },
                    {
                        "name": "Xã Thanh Xương",
                        "code": 3334,
                        "codename": "xa_thanh_xuong",
                        "division_type": "xã",
                        "short_codename": "thanh_xuong"
                    },
                    {
                        "name": "Xã Thanh Chăn",
                        "code": 3337,
                        "codename": "xa_thanh_chan",
                        "division_type": "xã",
                        "short_codename": "thanh_chan"
                    },
                    {
                        "name": "Xã Pa Thơm",
                        "code": 3340,
                        "codename": "xa_pa_thom",
                        "division_type": "xã",
                        "short_codename": "pa_thom"
                    },
                    {
                        "name": "Xã Thanh An",
                        "code": 3343,
                        "codename": "xa_thanh_an",
                        "division_type": "xã",
                        "short_codename": "thanh_an"
                    },
                    {
                        "name": "Xã Thanh Yên",
                        "code": 3346,
                        "codename": "xa_thanh_yen",
                        "division_type": "xã",
                        "short_codename": "thanh_yen"
                    },
                    {
                        "name": "Xã Noong Luống",
                        "code": 3349,
                        "codename": "xa_noong_luong",
                        "division_type": "xã",
                        "short_codename": "noong_luong"
                    },
                    {
                        "name": "Xã Noọng Hẹt",
                        "code": 3352,
                        "codename": "xa_noong_het",
                        "division_type": "xã",
                        "short_codename": "noong_het"
                    },
                    {
                        "name": "Xã Sam Mứn",
                        "code": 3355,
                        "codename": "xa_sam_mun",
                        "division_type": "xã",
                        "short_codename": "sam_mun"
                    },
                    {
                        "name": "Xã Pom Lót",
                        "code": 3356,
                        "codename": "xa_pom_lot",
                        "division_type": "xã",
                        "short_codename": "pom_lot"
                    },
                    {
                        "name": "Xã Núa Ngam",
                        "code": 3358,
                        "codename": "xa_nua_ngam",
                        "division_type": "xã",
                        "short_codename": "nua_ngam"
                    },
                    {
                        "name": "Xã Hẹ Muông",
                        "code": 3359,
                        "codename": "xa_he_muong",
                        "division_type": "xã",
                        "short_codename": "he_muong"
                    },
                    {
                        "name": "Xã Na Ư",
                        "code": 3361,
                        "codename": "xa_na_u",
                        "division_type": "xã",
                        "short_codename": "na_u"
                    },
                    {
                        "name": "Xã Mường Nhà",
                        "code": 3364,
                        "codename": "xa_muong_nha",
                        "division_type": "xã",
                        "short_codename": "muong_nha"
                    },
                    {
                        "name": "Xã Na Tông",
                        "code": 3365,
                        "codename": "xa_na_tong",
                        "division_type": "xã",
                        "short_codename": "na_tong"
                    },
                    {
                        "name": "Xã Mường Lói",
                        "code": 3367,
                        "codename": "xa_muong_loi",
                        "division_type": "xã",
                        "short_codename": "muong_loi"
                    },
                    {
                        "name": "Xã Phu Luông",
                        "code": 3368,
                        "codename": "xa_phu_luong",
                        "division_type": "xã",
                        "short_codename": "phu_luong"
                    }
                ]
            },
            {
                "name": "Huyện Điện Biên Đông",
                "code": 101,
                "codename": "huyen_dien_bien_dong",
                "division_type": "huyện",
                "short_codename": "dien_bien_dong",
                "wards": [
                    {
                        "name": "Thị trấn Điện Biên Đông",
                        "code": 3203,
                        "codename": "thi_tran_dien_bien_dong",
                        "division_type": "thị trấn",
                        "short_codename": "dien_bien_dong"
                    },
                    {
                        "name": "Xã Na Son",
                        "code": 3205,
                        "codename": "xa_na_son",
                        "division_type": "xã",
                        "short_codename": "na_son"
                    },
                    {
                        "name": "Xã Phì Nhừ",
                        "code": 3208,
                        "codename": "xa_phi_nhu",
                        "division_type": "xã",
                        "short_codename": "phi_nhu"
                    },
                    {
                        "name": "Xã Chiềng Sơ",
                        "code": 3211,
                        "codename": "xa_chieng_so",
                        "division_type": "xã",
                        "short_codename": "chieng_so"
                    },
                    {
                        "name": "Xã Mường Luân",
                        "code": 3214,
                        "codename": "xa_muong_luan",
                        "division_type": "xã",
                        "short_codename": "muong_luan"
                    },
                    {
                        "name": "Xã Pú Nhi",
                        "code": 3370,
                        "codename": "xa_pu_nhi",
                        "division_type": "xã",
                        "short_codename": "pu_nhi"
                    },
                    {
                        "name": "Xã Nong U",
                        "code": 3371,
                        "codename": "xa_nong_u",
                        "division_type": "xã",
                        "short_codename": "nong_u"
                    },
                    {
                        "name": "Xã Xa Dung",
                        "code": 3373,
                        "codename": "xa_xa_dung",
                        "division_type": "xã",
                        "short_codename": "xa_dung"
                    },
                    {
                        "name": "Xã Keo Lôm",
                        "code": 3376,
                        "codename": "xa_keo_lom",
                        "division_type": "xã",
                        "short_codename": "keo_lom"
                    },
                    {
                        "name": "Xã Luân Giới",
                        "code": 3379,
                        "codename": "xa_luan_gioi",
                        "division_type": "xã",
                        "short_codename": "luan_gioi"
                    },
                    {
                        "name": "Xã Phình Giàng",
                        "code": 3382,
                        "codename": "xa_phinh_giang",
                        "division_type": "xã",
                        "short_codename": "phinh_giang"
                    },
                    {
                        "name": "Xã Pú Hồng",
                        "code": 3383,
                        "codename": "xa_pu_hong",
                        "division_type": "xã",
                        "short_codename": "pu_hong"
                    },
                    {
                        "name": "Xã Tìa Dình",
                        "code": 3384,
                        "codename": "xa_tia_dinh",
                        "division_type": "xã",
                        "short_codename": "tia_dinh"
                    },
                    {
                        "name": "Xã Háng Lìa",
                        "code": 3385,
                        "codename": "xa_hang_lia",
                        "division_type": "xã",
                        "short_codename": "hang_lia"
                    }
                ]
            },
            {
                "name": "Huyện Mường Ảng",
                "code": 102,
                "codename": "huyen_muong_ang",
                "division_type": "huyện",
                "short_codename": "muong_ang",
                "wards": [
                    {
                        "name": "Thị trấn Mường Ảng",
                        "code": 3256,
                        "codename": "thi_tran_muong_ang",
                        "division_type": "thị trấn",
                        "short_codename": "muong_ang"
                    },
                    {
                        "name": "Xã Mường Đăng",
                        "code": 3286,
                        "codename": "xa_muong_dang",
                        "division_type": "xã",
                        "short_codename": "muong_dang"
                    },
                    {
                        "name": "Xã Ngối Cáy",
                        "code": 3287,
                        "codename": "xa_ngoi_cay",
                        "division_type": "xã",
                        "short_codename": "ngoi_cay"
                    },
                    {
                        "name": "Xã Ẳng Tở",
                        "code": 3292,
                        "codename": "xa_ang_to",
                        "division_type": "xã",
                        "short_codename": "ang_to"
                    },
                    {
                        "name": "Xã Búng Lao",
                        "code": 3301,
                        "codename": "xa_bung_lao",
                        "division_type": "xã",
                        "short_codename": "bung_lao"
                    },
                    {
                        "name": "Xã Xuân Lao",
                        "code": 3302,
                        "codename": "xa_xuan_lao",
                        "division_type": "xã",
                        "short_codename": "xuan_lao"
                    },
                    {
                        "name": "Xã Ẳng Nưa",
                        "code": 3307,
                        "codename": "xa_ang_nua",
                        "division_type": "xã",
                        "short_codename": "ang_nua"
                    },
                    {
                        "name": "Xã Ẳng Cang",
                        "code": 3310,
                        "codename": "xa_ang_cang",
                        "division_type": "xã",
                        "short_codename": "ang_cang"
                    },
                    {
                        "name": "Xã Nặm Lịch",
                        "code": 3312,
                        "codename": "xa_nam_lich",
                        "division_type": "xã",
                        "short_codename": "nam_lich"
                    },
                    {
                        "name": "Xã Mường Lạn",
                        "code": 3313,
                        "codename": "xa_muong_lan",
                        "division_type": "xã",
                        "short_codename": "muong_lan"
                    }
                ]
            },
            {
                "name": "Huyện Nậm Pồ",
                "code": 103,
                "codename": "huyen_nam_po",
                "division_type": "huyện",
                "short_codename": "nam_po",
                "wards": [
                    {
                        "name": "Xã Nậm Tin",
                        "code": 3156,
                        "codename": "xa_nam_tin",
                        "division_type": "xã",
                        "short_codename": "nam_tin"
                    },
                    {
                        "name": "Xã Pa Tần",
                        "code": 3165,
                        "codename": "xa_pa_tan",
                        "division_type": "xã",
                        "short_codename": "pa_tan"
                    },
                    {
                        "name": "Xã Chà Cang",
                        "code": 3166,
                        "codename": "xa_cha_cang",
                        "division_type": "xã",
                        "short_codename": "cha_cang"
                    },
                    {
                        "name": "Xã Na Cô Sa",
                        "code": 3167,
                        "codename": "xa_na_co_sa",
                        "division_type": "xã",
                        "short_codename": "na_co_sa"
                    },
                    {
                        "name": "Xã Nà Khoa",
                        "code": 3168,
                        "codename": "xa_na_khoa",
                        "division_type": "xã",
                        "short_codename": "na_khoa"
                    },
                    {
                        "name": "Xã Nà Hỳ",
                        "code": 3169,
                        "codename": "xa_na_hy",
                        "division_type": "xã",
                        "short_codename": "na_hy"
                    },
                    {
                        "name": "Xã Nà Bủng",
                        "code": 3170,
                        "codename": "xa_na_bung",
                        "division_type": "xã",
                        "short_codename": "na_bung"
                    },
                    {
                        "name": "Xã Nậm Nhừ",
                        "code": 3171,
                        "codename": "xa_nam_nhu",
                        "division_type": "xã",
                        "short_codename": "nam_nhu"
                    },
                    {
                        "name": "Xã Nậm Chua",
                        "code": 3173,
                        "codename": "xa_nam_chua",
                        "division_type": "xã",
                        "short_codename": "nam_chua"
                    },
                    {
                        "name": "Xã Nậm Khăn",
                        "code": 3174,
                        "codename": "xa_nam_khan",
                        "division_type": "xã",
                        "short_codename": "nam_khan"
                    },
                    {
                        "name": "Xã Chà Tở",
                        "code": 3175,
                        "codename": "xa_cha_to",
                        "division_type": "xã",
                        "short_codename": "cha_to"
                    },
                    {
                        "name": "Xã Vàng Đán",
                        "code": 3176,
                        "codename": "xa_vang_dan",
                        "division_type": "xã",
                        "short_codename": "vang_dan"
                    },
                    {
                        "name": "Xã Chà Nưa",
                        "code": 3187,
                        "codename": "xa_cha_nua",
                        "division_type": "xã",
                        "short_codename": "cha_nua"
                    },
                    {
                        "name": "Xã Phìn Hồ",
                        "code": 3198,
                        "codename": "xa_phin_ho",
                        "division_type": "xã",
                        "short_codename": "phin_ho"
                    },
                    {
                        "name": "Xã Si Pa Phìn",
                        "code": 3199,
                        "codename": "xa_si_pa_phin",
                        "division_type": "xã",
                        "short_codename": "si_pa_phin"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Lai Châu",
        "code": 12,
        "codename": "tinh_lai_chau",
        "division_type": "tỉnh",
        "phone_code": 213,
        "districts": [
            {
                "name": "Thành phố Lai Châu",
                "code": 105,
                "codename": "thanh_pho_lai_chau",
                "division_type": "thành phố",
                "short_codename": "lai_chau",
                "wards": [
                    {
                        "name": "Phường Quyết Thắng",
                        "code": 3386,
                        "codename": "phuong_quyet_thang",
                        "division_type": "phường",
                        "short_codename": "quyet_thang"
                    },
                    {
                        "name": "Phường Tân Phong",
                        "code": 3387,
                        "codename": "phuong_tan_phong",
                        "division_type": "phường",
                        "short_codename": "tan_phong"
                    },
                    {
                        "name": "Phường Quyết Tiến",
                        "code": 3388,
                        "codename": "phuong_quyet_tien",
                        "division_type": "phường",
                        "short_codename": "quyet_tien"
                    },
                    {
                        "name": "Phường Đoàn Kết",
                        "code": 3389,
                        "codename": "phuong_doan_ket",
                        "division_type": "phường",
                        "short_codename": "doan_ket"
                    },
                    {
                        "name": "Xã Sùng Phài",
                        "code": 3403,
                        "codename": "xa_sung_phai",
                        "division_type": "xã",
                        "short_codename": "sung_phai"
                    },
                    {
                        "name": "Phường Đông Phong",
                        "code": 3408,
                        "codename": "phuong_dong_phong",
                        "division_type": "phường",
                        "short_codename": "dong_phong"
                    },
                    {
                        "name": "Xã San Thàng",
                        "code": 3409,
                        "codename": "xa_san_thang",
                        "division_type": "xã",
                        "short_codename": "san_thang"
                    }
                ]
            },
            {
                "name": "Huyện Tam Đường",
                "code": 106,
                "codename": "huyen_tam_duong",
                "division_type": "huyện",
                "short_codename": "tam_duong",
                "wards": [
                    {
                        "name": "Thị trấn Tam Đường",
                        "code": 3390,
                        "codename": "thi_tran_tam_duong",
                        "division_type": "thị trấn",
                        "short_codename": "tam_duong"
                    },
                    {
                        "name": "Xã Thèn Sin",
                        "code": 3394,
                        "codename": "xa_then_sin",
                        "division_type": "xã",
                        "short_codename": "then_sin"
                    },
                    {
                        "name": "Xã Tả Lèng",
                        "code": 3400,
                        "codename": "xa_ta_leng",
                        "division_type": "xã",
                        "short_codename": "ta_leng"
                    },
                    {
                        "name": "Xã Giang Ma",
                        "code": 3405,
                        "codename": "xa_giang_ma",
                        "division_type": "xã",
                        "short_codename": "giang_ma"
                    },
                    {
                        "name": "Xã Hồ Thầu",
                        "code": 3406,
                        "codename": "xa_ho_thau",
                        "division_type": "xã",
                        "short_codename": "ho_thau"
                    },
                    {
                        "name": "Xã Bình Lư",
                        "code": 3412,
                        "codename": "xa_binh_lu",
                        "division_type": "xã",
                        "short_codename": "binh_lu"
                    },
                    {
                        "name": "Xã Sơn Bình",
                        "code": 3413,
                        "codename": "xa_son_binh",
                        "division_type": "xã",
                        "short_codename": "son_binh"
                    },
                    {
                        "name": "Xã Nùng Nàng",
                        "code": 3415,
                        "codename": "xa_nung_nang",
                        "division_type": "xã",
                        "short_codename": "nung_nang"
                    },
                    {
                        "name": "Xã Bản Giang",
                        "code": 3418,
                        "codename": "xa_ban_giang",
                        "division_type": "xã",
                        "short_codename": "ban_giang"
                    },
                    {
                        "name": "Xã Bản Hon",
                        "code": 3421,
                        "codename": "xa_ban_hon",
                        "division_type": "xã",
                        "short_codename": "ban_hon"
                    },
                    {
                        "name": "Xã Bản Bo",
                        "code": 3424,
                        "codename": "xa_ban_bo",
                        "division_type": "xã",
                        "short_codename": "ban_bo"
                    },
                    {
                        "name": "Xã Nà Tăm",
                        "code": 3427,
                        "codename": "xa_na_tam",
                        "division_type": "xã",
                        "short_codename": "na_tam"
                    },
                    {
                        "name": "Xã Khun Há",
                        "code": 3430,
                        "codename": "xa_khun_ha",
                        "division_type": "xã",
                        "short_codename": "khun_ha"
                    }
                ]
            },
            {
                "name": "Huyện Mường Tè",
                "code": 107,
                "codename": "huyen_muong_te",
                "division_type": "huyện",
                "short_codename": "muong_te",
                "wards": [
                    {
                        "name": "Thị trấn Mường Tè",
                        "code": 3433,
                        "codename": "thi_tran_muong_te",
                        "division_type": "thị trấn",
                        "short_codename": "muong_te"
                    },
                    {
                        "name": "Xã Thu Lũm",
                        "code": 3436,
                        "codename": "xa_thu_lum",
                        "division_type": "xã",
                        "short_codename": "thu_lum"
                    },
                    {
                        "name": "Xã Ka Lăng",
                        "code": 3439,
                        "codename": "xa_ka_lang",
                        "division_type": "xã",
                        "short_codename": "ka_lang"
                    },
                    {
                        "name": "Xã Tá Bạ",
                        "code": 3440,
                        "codename": "xa_ta_ba",
                        "division_type": "xã",
                        "short_codename": "ta_ba"
                    },
                    {
                        "name": "Xã Pa ủ",
                        "code": 3442,
                        "codename": "xa_pa_u",
                        "division_type": "xã",
                        "short_codename": "pa_u"
                    },
                    {
                        "name": "Xã Mường Tè",
                        "code": 3445,
                        "codename": "xa_muong_te",
                        "division_type": "xã",
                        "short_codename": "xa_muong_te"
                    },
                    {
                        "name": "Xã Pa Vệ Sử",
                        "code": 3448,
                        "codename": "xa_pa_ve_su",
                        "division_type": "xã",
                        "short_codename": "pa_ve_su"
                    },
                    {
                        "name": "Xã Mù Cả",
                        "code": 3451,
                        "codename": "xa_mu_ca",
                        "division_type": "xã",
                        "short_codename": "mu_ca"
                    },
                    {
                        "name": "Xã Bum Tở",
                        "code": 3454,
                        "codename": "xa_bum_to",
                        "division_type": "xã",
                        "short_codename": "bum_to"
                    },
                    {
                        "name": "Xã Nậm Khao",
                        "code": 3457,
                        "codename": "xa_nam_khao",
                        "division_type": "xã",
                        "short_codename": "nam_khao"
                    },
                    {
                        "name": "Xã Tà Tổng",
                        "code": 3463,
                        "codename": "xa_ta_tong",
                        "division_type": "xã",
                        "short_codename": "ta_tong"
                    },
                    {
                        "name": "Xã Bum Nưa",
                        "code": 3466,
                        "codename": "xa_bum_nua",
                        "division_type": "xã",
                        "short_codename": "bum_nua"
                    },
                    {
                        "name": "Xã Vàng San",
                        "code": 3467,
                        "codename": "xa_vang_san",
                        "division_type": "xã",
                        "short_codename": "vang_san"
                    },
                    {
                        "name": "Xã Kan Hồ",
                        "code": 3469,
                        "codename": "xa_kan_ho",
                        "division_type": "xã",
                        "short_codename": "kan_ho"
                    }
                ]
            },
            {
                "name": "Huyện Sìn Hồ",
                "code": 108,
                "codename": "huyen_sin_ho",
                "division_type": "huyện",
                "short_codename": "sin_ho",
                "wards": [
                    {
                        "name": "Thị trấn Sìn Hồ",
                        "code": 3478,
                        "codename": "thi_tran_sin_ho",
                        "division_type": "thị trấn",
                        "short_codename": "sin_ho"
                    },
                    {
                        "name": "Xã Chăn Nưa",
                        "code": 3487,
                        "codename": "xa_chan_nua",
                        "division_type": "xã",
                        "short_codename": "chan_nua"
                    },
                    {
                        "name": "Xã Pa Tần",
                        "code": 3493,
                        "codename": "xa_pa_tan",
                        "division_type": "xã",
                        "short_codename": "pa_tan"
                    },
                    {
                        "name": "Xã Phìn Hồ",
                        "code": 3496,
                        "codename": "xa_phin_ho",
                        "division_type": "xã",
                        "short_codename": "phin_ho"
                    },
                    {
                        "name": "Xã Hồng Thu",
                        "code": 3499,
                        "codename": "xa_hong_thu",
                        "division_type": "xã",
                        "short_codename": "hong_thu"
                    },
                    {
                        "name": "Xã Phăng Sô Lin",
                        "code": 3505,
                        "codename": "xa_phang_so_lin",
                        "division_type": "xã",
                        "short_codename": "phang_so_lin"
                    },
                    {
                        "name": "Xã Ma Quai",
                        "code": 3508,
                        "codename": "xa_ma_quai",
                        "division_type": "xã",
                        "short_codename": "ma_quai"
                    },
                    {
                        "name": "Xã Lùng Thàng",
                        "code": 3509,
                        "codename": "xa_lung_thang",
                        "division_type": "xã",
                        "short_codename": "lung_thang"
                    },
                    {
                        "name": "Xã Tả Phìn",
                        "code": 3511,
                        "codename": "xa_ta_phin",
                        "division_type": "xã",
                        "short_codename": "ta_phin"
                    },
                    {
                        "name": "Xã Sà Dề Phìn",
                        "code": 3514,
                        "codename": "xa_sa_de_phin",
                        "division_type": "xã",
                        "short_codename": "sa_de_phin"
                    },
                    {
                        "name": "Xã Nậm Tăm",
                        "code": 3517,
                        "codename": "xa_nam_tam",
                        "division_type": "xã",
                        "short_codename": "nam_tam"
                    },
                    {
                        "name": "Xã Tả Ngảo",
                        "code": 3520,
                        "codename": "xa_ta_ngao",
                        "division_type": "xã",
                        "short_codename": "ta_ngao"
                    },
                    {
                        "name": "Xã Pu Sam Cáp",
                        "code": 3523,
                        "codename": "xa_pu_sam_cap",
                        "division_type": "xã",
                        "short_codename": "pu_sam_cap"
                    },
                    {
                        "name": "Xã Nậm Cha",
                        "code": 3526,
                        "codename": "xa_nam_cha",
                        "division_type": "xã",
                        "short_codename": "nam_cha"
                    },
                    {
                        "name": "Xã Pa Khoá",
                        "code": 3527,
                        "codename": "xa_pa_khoa",
                        "division_type": "xã",
                        "short_codename": "pa_khoa"
                    },
                    {
                        "name": "Xã Làng Mô",
                        "code": 3529,
                        "codename": "xa_lang_mo",
                        "division_type": "xã",
                        "short_codename": "lang_mo"
                    },
                    {
                        "name": "Xã Noong Hẻo",
                        "code": 3532,
                        "codename": "xa_noong_heo",
                        "division_type": "xã",
                        "short_codename": "noong_heo"
                    },
                    {
                        "name": "Xã Nậm Mạ",
                        "code": 3535,
                        "codename": "xa_nam_ma",
                        "division_type": "xã",
                        "short_codename": "nam_ma"
                    },
                    {
                        "name": "Xã Căn Co",
                        "code": 3538,
                        "codename": "xa_can_co",
                        "division_type": "xã",
                        "short_codename": "can_co"
                    },
                    {
                        "name": "Xã Tủa Sín Chải",
                        "code": 3541,
                        "codename": "xa_tua_sin_chai",
                        "division_type": "xã",
                        "short_codename": "tua_sin_chai"
                    },
                    {
                        "name": "Xã Nậm Cuổi",
                        "code": 3544,
                        "codename": "xa_nam_cuoi",
                        "division_type": "xã",
                        "short_codename": "nam_cuoi"
                    },
                    {
                        "name": "Xã Nậm Hăn",
                        "code": 3547,
                        "codename": "xa_nam_han",
                        "division_type": "xã",
                        "short_codename": "nam_han"
                    }
                ]
            },
            {
                "name": "Huyện Phong Thổ",
                "code": 109,
                "codename": "huyen_phong_tho",
                "division_type": "huyện",
                "short_codename": "phong_tho",
                "wards": [
                    {
                        "name": "Xã Lả Nhì Thàng",
                        "code": 3391,
                        "codename": "xa_la_nhi_thang",
                        "division_type": "xã",
                        "short_codename": "la_nhi_thang"
                    },
                    {
                        "name": "Xã Huổi Luông",
                        "code": 3490,
                        "codename": "xa_huoi_luong",
                        "division_type": "xã",
                        "short_codename": "huoi_luong"
                    },
                    {
                        "name": "Thị trấn Phong Thổ",
                        "code": 3549,
                        "codename": "thi_tran_phong_tho",
                        "division_type": "thị trấn",
                        "short_codename": "phong_tho"
                    },
                    {
                        "name": "Xã Sì Lở Lầu",
                        "code": 3550,
                        "codename": "xa_si_lo_lau",
                        "division_type": "xã",
                        "short_codename": "si_lo_lau"
                    },
                    {
                        "name": "Xã Mồ Sì San",
                        "code": 3553,
                        "codename": "xa_mo_si_san",
                        "division_type": "xã",
                        "short_codename": "mo_si_san"
                    },
                    {
                        "name": "Xã Pa Vây Sử",
                        "code": 3559,
                        "codename": "xa_pa_vay_su",
                        "division_type": "xã",
                        "short_codename": "pa_vay_su"
                    },
                    {
                        "name": "Xã Vàng Ma Chải",
                        "code": 3562,
                        "codename": "xa_vang_ma_chai",
                        "division_type": "xã",
                        "short_codename": "vang_ma_chai"
                    },
                    {
                        "name": "Xã Tông Qua Lìn",
                        "code": 3565,
                        "codename": "xa_tong_qua_lin",
                        "division_type": "xã",
                        "short_codename": "tong_qua_lin"
                    },
                    {
                        "name": "Xã Mù Sang",
                        "code": 3568,
                        "codename": "xa_mu_sang",
                        "division_type": "xã",
                        "short_codename": "mu_sang"
                    },
                    {
                        "name": "Xã Dào San",
                        "code": 3571,
                        "codename": "xa_dao_san",
                        "division_type": "xã",
                        "short_codename": "dao_san"
                    },
                    {
                        "name": "Xã Ma Ly Pho",
                        "code": 3574,
                        "codename": "xa_ma_ly_pho",
                        "division_type": "xã",
                        "short_codename": "ma_ly_pho"
                    },
                    {
                        "name": "Xã Bản Lang",
                        "code": 3577,
                        "codename": "xa_ban_lang",
                        "division_type": "xã",
                        "short_codename": "ban_lang"
                    },
                    {
                        "name": "Xã Hoang Thèn",
                        "code": 3580,
                        "codename": "xa_hoang_then",
                        "division_type": "xã",
                        "short_codename": "hoang_then"
                    },
                    {
                        "name": "Xã Khổng Lào",
                        "code": 3583,
                        "codename": "xa_khong_lao",
                        "division_type": "xã",
                        "short_codename": "khong_lao"
                    },
                    {
                        "name": "Xã Nậm Xe",
                        "code": 3586,
                        "codename": "xa_nam_xe",
                        "division_type": "xã",
                        "short_codename": "nam_xe"
                    },
                    {
                        "name": "Xã Mường So",
                        "code": 3589,
                        "codename": "xa_muong_so",
                        "division_type": "xã",
                        "short_codename": "muong_so"
                    },
                    {
                        "name": "Xã Sin Suối Hồ",
                        "code": 3592,
                        "codename": "xa_sin_suoi_ho",
                        "division_type": "xã",
                        "short_codename": "sin_suoi_ho"
                    }
                ]
            },
            {
                "name": "Huyện Than Uyên",
                "code": 110,
                "codename": "huyen_than_uyen",
                "division_type": "huyện",
                "short_codename": "than_uyen",
                "wards": [
                    {
                        "name": "Thị trấn Than Uyên",
                        "code": 3595,
                        "codename": "thi_tran_than_uyen",
                        "division_type": "thị trấn",
                        "short_codename": "than_uyen"
                    },
                    {
                        "name": "Xã Phúc Than",
                        "code": 3618,
                        "codename": "xa_phuc_than",
                        "division_type": "xã",
                        "short_codename": "phuc_than"
                    },
                    {
                        "name": "Xã Mường Than",
                        "code": 3619,
                        "codename": "xa_muong_than",
                        "division_type": "xã",
                        "short_codename": "muong_than"
                    },
                    {
                        "name": "Xã Mường Mít",
                        "code": 3625,
                        "codename": "xa_muong_mit",
                        "division_type": "xã",
                        "short_codename": "muong_mit"
                    },
                    {
                        "name": "Xã Pha Mu",
                        "code": 3628,
                        "codename": "xa_pha_mu",
                        "division_type": "xã",
                        "short_codename": "pha_mu"
                    },
                    {
                        "name": "Xã Mường Cang",
                        "code": 3631,
                        "codename": "xa_muong_cang",
                        "division_type": "xã",
                        "short_codename": "muong_cang"
                    },
                    {
                        "name": "Xã Hua Nà",
                        "code": 3632,
                        "codename": "xa_hua_na",
                        "division_type": "xã",
                        "short_codename": "hua_na"
                    },
                    {
                        "name": "Xã Tà Hừa",
                        "code": 3634,
                        "codename": "xa_ta_hua",
                        "division_type": "xã",
                        "short_codename": "ta_hua"
                    },
                    {
                        "name": "Xã Mường Kim",
                        "code": 3637,
                        "codename": "xa_muong_kim",
                        "division_type": "xã",
                        "short_codename": "muong_kim"
                    },
                    {
                        "name": "Xã Tà Mung",
                        "code": 3638,
                        "codename": "xa_ta_mung",
                        "division_type": "xã",
                        "short_codename": "ta_mung"
                    },
                    {
                        "name": "Xã Tà Gia",
                        "code": 3640,
                        "codename": "xa_ta_gia",
                        "division_type": "xã",
                        "short_codename": "ta_gia"
                    },
                    {
                        "name": "Xã Khoen On",
                        "code": 3643,
                        "codename": "xa_khoen_on",
                        "division_type": "xã",
                        "short_codename": "khoen_on"
                    }
                ]
            },
            {
                "name": "Huyện Tân Uyên",
                "code": 111,
                "codename": "huyen_tan_uyen",
                "division_type": "huyện",
                "short_codename": "tan_uyen",
                "wards": [
                    {
                        "name": "Thị trấn Tân Uyên",
                        "code": 3598,
                        "codename": "thi_tran_tan_uyen",
                        "division_type": "thị trấn",
                        "short_codename": "tan_uyen"
                    },
                    {
                        "name": "Xã Mường Khoa",
                        "code": 3601,
                        "codename": "xa_muong_khoa",
                        "division_type": "xã",
                        "short_codename": "muong_khoa"
                    },
                    {
                        "name": "Xã Phúc Khoa",
                        "code": 3602,
                        "codename": "xa_phuc_khoa",
                        "division_type": "xã",
                        "short_codename": "phuc_khoa"
                    },
                    {
                        "name": "Xã Thân Thuộc",
                        "code": 3604,
                        "codename": "xa_than_thuoc",
                        "division_type": "xã",
                        "short_codename": "than_thuoc"
                    },
                    {
                        "name": "Xã Trung Đồng",
                        "code": 3605,
                        "codename": "xa_trung_dong",
                        "division_type": "xã",
                        "short_codename": "trung_dong"
                    },
                    {
                        "name": "Xã Hố Mít",
                        "code": 3607,
                        "codename": "xa_ho_mit",
                        "division_type": "xã",
                        "short_codename": "ho_mit"
                    },
                    {
                        "name": "Xã Nậm Cần",
                        "code": 3610,
                        "codename": "xa_nam_can",
                        "division_type": "xã",
                        "short_codename": "nam_can"
                    },
                    {
                        "name": "Xã Nậm Sỏ",
                        "code": 3613,
                        "codename": "xa_nam_so",
                        "division_type": "xã",
                        "short_codename": "nam_so"
                    },
                    {
                        "name": "Xã Pắc Ta",
                        "code": 3616,
                        "codename": "xa_pac_ta",
                        "division_type": "xã",
                        "short_codename": "pac_ta"
                    },
                    {
                        "name": "Xã Tà Mít",
                        "code": 3622,
                        "codename": "xa_ta_mit",
                        "division_type": "xã",
                        "short_codename": "ta_mit"
                    }
                ]
            },
            {
                "name": "Huyện Nậm Nhùn",
                "code": 112,
                "codename": "huyen_nam_nhun",
                "division_type": "huyện",
                "short_codename": "nam_nhun",
                "wards": [
                    {
                        "name": "Thị trấn Nậm Nhùn",
                        "code": 3434,
                        "codename": "thi_tran_nam_nhun",
                        "division_type": "thị trấn",
                        "short_codename": "nam_nhun"
                    },
                    {
                        "name": "Xã Hua Bun",
                        "code": 3460,
                        "codename": "xa_hua_bun",
                        "division_type": "xã",
                        "short_codename": "hua_bun"
                    },
                    {
                        "name": "Xã Mường Mô",
                        "code": 3472,
                        "codename": "xa_muong_mo",
                        "division_type": "xã",
                        "short_codename": "muong_mo"
                    },
                    {
                        "name": "Xã Nậm Chà",
                        "code": 3473,
                        "codename": "xa_nam_cha",
                        "division_type": "xã",
                        "short_codename": "nam_cha"
                    },
                    {
                        "name": "Xã Nậm Manh",
                        "code": 3474,
                        "codename": "xa_nam_manh",
                        "division_type": "xã",
                        "short_codename": "nam_manh"
                    },
                    {
                        "name": "Xã Nậm Hàng",
                        "code": 3475,
                        "codename": "xa_nam_hang",
                        "division_type": "xã",
                        "short_codename": "nam_hang"
                    },
                    {
                        "name": "Xã Lê Lợi",
                        "code": 3481,
                        "codename": "xa_le_loi",
                        "division_type": "xã",
                        "short_codename": "le_loi"
                    },
                    {
                        "name": "Xã Pú Đao",
                        "code": 3484,
                        "codename": "xa_pu_dao",
                        "division_type": "xã",
                        "short_codename": "pu_dao"
                    },
                    {
                        "name": "Xã Nậm Pì",
                        "code": 3488,
                        "codename": "xa_nam_pi",
                        "division_type": "xã",
                        "short_codename": "nam_pi"
                    },
                    {
                        "name": "Xã Nậm Ban",
                        "code": 3502,
                        "codename": "xa_nam_ban",
                        "division_type": "xã",
                        "short_codename": "nam_ban"
                    },
                    {
                        "name": "Xã Trung Chải",
                        "code": 3503,
                        "codename": "xa_trung_chai",
                        "division_type": "xã",
                        "short_codename": "trung_chai"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Sơn La",
        "code": 14,
        "codename": "tinh_son_la",
        "division_type": "tỉnh",
        "phone_code": 212,
        "districts": [
            {
                "name": "Thành phố Sơn La",
                "code": 116,
                "codename": "thanh_pho_son_la",
                "division_type": "thành phố",
                "short_codename": "son_la",
                "wards": [
                    {
                        "name": "Phường Chiềng Lề",
                        "code": 3646,
                        "codename": "phuong_chieng_le",
                        "division_type": "phường",
                        "short_codename": "chieng_le"
                    },
                    {
                        "name": "Phường Tô Hiệu",
                        "code": 3649,
                        "codename": "phuong_to_hieu",
                        "division_type": "phường",
                        "short_codename": "to_hieu"
                    },
                    {
                        "name": "Phường Quyết Thắng",
                        "code": 3652,
                        "codename": "phuong_quyet_thang",
                        "division_type": "phường",
                        "short_codename": "quyet_thang"
                    },
                    {
                        "name": "Phường Quyết Tâm",
                        "code": 3655,
                        "codename": "phuong_quyet_tam",
                        "division_type": "phường",
                        "short_codename": "quyet_tam"
                    },
                    {
                        "name": "Xã Chiềng Cọ",
                        "code": 3658,
                        "codename": "xa_chieng_co",
                        "division_type": "xã",
                        "short_codename": "chieng_co"
                    },
                    {
                        "name": "Xã Chiềng Đen",
                        "code": 3661,
                        "codename": "xa_chieng_den",
                        "division_type": "xã",
                        "short_codename": "chieng_den"
                    },
                    {
                        "name": "Xã Chiềng Xôm",
                        "code": 3664,
                        "codename": "xa_chieng_xom",
                        "division_type": "xã",
                        "short_codename": "chieng_xom"
                    },
                    {
                        "name": "Phường Chiềng An",
                        "code": 3667,
                        "codename": "phuong_chieng_an",
                        "division_type": "phường",
                        "short_codename": "chieng_an"
                    },
                    {
                        "name": "Phường Chiềng Cơi",
                        "code": 3670,
                        "codename": "phuong_chieng_coi",
                        "division_type": "phường",
                        "short_codename": "chieng_coi"
                    },
                    {
                        "name": "Xã Chiềng Ngần",
                        "code": 3673,
                        "codename": "xa_chieng_ngan",
                        "division_type": "xã",
                        "short_codename": "chieng_ngan"
                    },
                    {
                        "name": "Xã Hua La",
                        "code": 3676,
                        "codename": "xa_hua_la",
                        "division_type": "xã",
                        "short_codename": "hua_la"
                    },
                    {
                        "name": "Phường Chiềng Sinh",
                        "code": 3679,
                        "codename": "phuong_chieng_sinh",
                        "division_type": "phường",
                        "short_codename": "chieng_sinh"
                    }
                ]
            },
            {
                "name": "Huyện Quỳnh Nhai",
                "code": 118,
                "codename": "huyen_quynh_nhai",
                "division_type": "huyện",
                "short_codename": "quynh_nhai",
                "wards": [
                    {
                        "name": "Xã Mường Chiên",
                        "code": 3682,
                        "codename": "xa_muong_chien",
                        "division_type": "xã",
                        "short_codename": "muong_chien"
                    },
                    {
                        "name": "Xã Cà Nàng",
                        "code": 3685,
                        "codename": "xa_ca_nang",
                        "division_type": "xã",
                        "short_codename": "ca_nang"
                    },
                    {
                        "name": "Xã Chiềng Khay",
                        "code": 3688,
                        "codename": "xa_chieng_khay",
                        "division_type": "xã",
                        "short_codename": "chieng_khay"
                    },
                    {
                        "name": "Xã Mường Giôn",
                        "code": 3694,
                        "codename": "xa_muong_gion",
                        "division_type": "xã",
                        "short_codename": "muong_gion"
                    },
                    {
                        "name": "Xã Pá Ma Pha Khinh",
                        "code": 3697,
                        "codename": "xa_pa_ma_pha_khinh",
                        "division_type": "xã",
                        "short_codename": "pa_ma_pha_khinh"
                    },
                    {
                        "name": "Xã Chiềng Ơn",
                        "code": 3700,
                        "codename": "xa_chieng_on",
                        "division_type": "xã",
                        "short_codename": "chieng_on"
                    },
                    {
                        "name": "Xã Mường Giàng",
                        "code": 3703,
                        "codename": "xa_muong_giang",
                        "division_type": "xã",
                        "short_codename": "muong_giang"
                    },
                    {
                        "name": "Xã Chiềng Bằng",
                        "code": 3706,
                        "codename": "xa_chieng_bang",
                        "division_type": "xã",
                        "short_codename": "chieng_bang"
                    },
                    {
                        "name": "Xã Mường Sại",
                        "code": 3709,
                        "codename": "xa_muong_sai",
                        "division_type": "xã",
                        "short_codename": "muong_sai"
                    },
                    {
                        "name": "Xã Nậm ét",
                        "code": 3712,
                        "codename": "xa_nam_et",
                        "division_type": "xã",
                        "short_codename": "nam_et"
                    },
                    {
                        "name": "Xã Chiềng Khoang",
                        "code": 3718,
                        "codename": "xa_chieng_khoang",
                        "division_type": "xã",
                        "short_codename": "chieng_khoang"
                    }
                ]
            },
            {
                "name": "Huyện Thuận Châu",
                "code": 119,
                "codename": "huyen_thuan_chau",
                "division_type": "huyện",
                "short_codename": "thuan_chau",
                "wards": [
                    {
                        "name": "Thị trấn Thuận Châu",
                        "code": 3721,
                        "codename": "thi_tran_thuan_chau",
                        "division_type": "thị trấn",
                        "short_codename": "thuan_chau"
                    },
                    {
                        "name": "Xã Phổng Lái",
                        "code": 3724,
                        "codename": "xa_phong_lai",
                        "division_type": "xã",
                        "short_codename": "phong_lai"
                    },
                    {
                        "name": "Xã Mường é",
                        "code": 3727,
                        "codename": "xa_muong_e",
                        "division_type": "xã",
                        "short_codename": "muong_e"
                    },
                    {
                        "name": "Xã Chiềng Pha",
                        "code": 3730,
                        "codename": "xa_chieng_pha",
                        "division_type": "xã",
                        "short_codename": "chieng_pha"
                    },
                    {
                        "name": "Xã Chiềng La",
                        "code": 3733,
                        "codename": "xa_chieng_la",
                        "division_type": "xã",
                        "short_codename": "chieng_la"
                    },
                    {
                        "name": "Xã Chiềng Ngàm",
                        "code": 3736,
                        "codename": "xa_chieng_ngam",
                        "division_type": "xã",
                        "short_codename": "chieng_ngam"
                    },
                    {
                        "name": "Xã Liệp Tè",
                        "code": 3739,
                        "codename": "xa_liep_te",
                        "division_type": "xã",
                        "short_codename": "liep_te"
                    },
                    {
                        "name": "Xã é Tòng",
                        "code": 3742,
                        "codename": "xa_e_tong",
                        "division_type": "xã",
                        "short_codename": "e_tong"
                    },
                    {
                        "name": "Xã Phổng Lập",
                        "code": 3745,
                        "codename": "xa_phong_lap",
                        "division_type": "xã",
                        "short_codename": "phong_lap"
                    },
                    {
                        "name": "Xã Phổng Lăng",
                        "code": 3748,
                        "codename": "xa_phong_lang",
                        "division_type": "xã",
                        "short_codename": "phong_lang"
                    },
                    {
                        "name": "Xã Chiềng Ly",
                        "code": 3751,
                        "codename": "xa_chieng_ly",
                        "division_type": "xã",
                        "short_codename": "chieng_ly"
                    },
                    {
                        "name": "Xã Noong Lay",
                        "code": 3754,
                        "codename": "xa_noong_lay",
                        "division_type": "xã",
                        "short_codename": "noong_lay"
                    },
                    {
                        "name": "Xã Mường Khiêng",
                        "code": 3757,
                        "codename": "xa_muong_khieng",
                        "division_type": "xã",
                        "short_codename": "muong_khieng"
                    },
                    {
                        "name": "Xã Mường Bám",
                        "code": 3760,
                        "codename": "xa_muong_bam",
                        "division_type": "xã",
                        "short_codename": "muong_bam"
                    },
                    {
                        "name": "Xã Long Hẹ",
                        "code": 3763,
                        "codename": "xa_long_he",
                        "division_type": "xã",
                        "short_codename": "long_he"
                    },
                    {
                        "name": "Xã Chiềng Bôm",
                        "code": 3766,
                        "codename": "xa_chieng_bom",
                        "division_type": "xã",
                        "short_codename": "chieng_bom"
                    },
                    {
                        "name": "Xã Thôm Mòn",
                        "code": 3769,
                        "codename": "xa_thom_mon",
                        "division_type": "xã",
                        "short_codename": "thom_mon"
                    },
                    {
                        "name": "Xã Tông Lạnh",
                        "code": 3772,
                        "codename": "xa_tong_lanh",
                        "division_type": "xã",
                        "short_codename": "tong_lanh"
                    },
                    {
                        "name": "Xã Tông Cọ",
                        "code": 3775,
                        "codename": "xa_tong_co",
                        "division_type": "xã",
                        "short_codename": "tong_co"
                    },
                    {
                        "name": "Xã Bó Mười",
                        "code": 3778,
                        "codename": "xa_bo_muoi",
                        "division_type": "xã",
                        "short_codename": "bo_muoi"
                    },
                    {
                        "name": "Xã Co Mạ",
                        "code": 3781,
                        "codename": "xa_co_ma",
                        "division_type": "xã",
                        "short_codename": "co_ma"
                    },
                    {
                        "name": "Xã Púng Tra",
                        "code": 3784,
                        "codename": "xa_pung_tra",
                        "division_type": "xã",
                        "short_codename": "pung_tra"
                    },
                    {
                        "name": "Xã Chiềng Pấc",
                        "code": 3787,
                        "codename": "xa_chieng_pac",
                        "division_type": "xã",
                        "short_codename": "chieng_pac"
                    },
                    {
                        "name": "Xã Nậm Lầu",
                        "code": 3790,
                        "codename": "xa_nam_lau",
                        "division_type": "xã",
                        "short_codename": "nam_lau"
                    },
                    {
                        "name": "Xã Bon Phặng",
                        "code": 3793,
                        "codename": "xa_bon_phang",
                        "division_type": "xã",
                        "short_codename": "bon_phang"
                    },
                    {
                        "name": "Xã Co Tòng",
                        "code": 3796,
                        "codename": "xa_co_tong",
                        "division_type": "xã",
                        "short_codename": "co_tong"
                    },
                    {
                        "name": "Xã Muổi Nọi",
                        "code": 3799,
                        "codename": "xa_muoi_noi",
                        "division_type": "xã",
                        "short_codename": "muoi_noi"
                    },
                    {
                        "name": "Xã Pá Lông",
                        "code": 3802,
                        "codename": "xa_pa_long",
                        "division_type": "xã",
                        "short_codename": "pa_long"
                    },
                    {
                        "name": "Xã Bản Lầm",
                        "code": 3805,
                        "codename": "xa_ban_lam",
                        "division_type": "xã",
                        "short_codename": "ban_lam"
                    }
                ]
            },
            {
                "name": "Huyện Mường La",
                "code": 120,
                "codename": "huyen_muong_la",
                "division_type": "huyện",
                "short_codename": "muong_la",
                "wards": [
                    {
                        "name": "Thị trấn Ít Ong",
                        "code": 3808,
                        "codename": "thi_tran_it_ong",
                        "division_type": "thị trấn",
                        "short_codename": "it_ong"
                    },
                    {
                        "name": "Xã Nậm Giôn",
                        "code": 3811,
                        "codename": "xa_nam_gion",
                        "division_type": "xã",
                        "short_codename": "nam_gion"
                    },
                    {
                        "name": "Xã Chiềng Lao",
                        "code": 3814,
                        "codename": "xa_chieng_lao",
                        "division_type": "xã",
                        "short_codename": "chieng_lao"
                    },
                    {
                        "name": "Xã Hua Trai",
                        "code": 3817,
                        "codename": "xa_hua_trai",
                        "division_type": "xã",
                        "short_codename": "hua_trai"
                    },
                    {
                        "name": "Xã Ngọc Chiến",
                        "code": 3820,
                        "codename": "xa_ngoc_chien",
                        "division_type": "xã",
                        "short_codename": "ngoc_chien"
                    },
                    {
                        "name": "Xã Mường Trai",
                        "code": 3823,
                        "codename": "xa_muong_trai",
                        "division_type": "xã",
                        "short_codename": "muong_trai"
                    },
                    {
                        "name": "Xã Nậm Păm",
                        "code": 3826,
                        "codename": "xa_nam_pam",
                        "division_type": "xã",
                        "short_codename": "nam_pam"
                    },
                    {
                        "name": "Xã Chiềng Muôn",
                        "code": 3829,
                        "codename": "xa_chieng_muon",
                        "division_type": "xã",
                        "short_codename": "chieng_muon"
                    },
                    {
                        "name": "Xã Chiềng Ân",
                        "code": 3832,
                        "codename": "xa_chieng_an",
                        "division_type": "xã",
                        "short_codename": "chieng_an"
                    },
                    {
                        "name": "Xã Pi Toong",
                        "code": 3835,
                        "codename": "xa_pi_toong",
                        "division_type": "xã",
                        "short_codename": "pi_toong"
                    },
                    {
                        "name": "Xã Chiềng Công",
                        "code": 3838,
                        "codename": "xa_chieng_cong",
                        "division_type": "xã",
                        "short_codename": "chieng_cong"
                    },
                    {
                        "name": "Xã Tạ Bú",
                        "code": 3841,
                        "codename": "xa_ta_bu",
                        "division_type": "xã",
                        "short_codename": "ta_bu"
                    },
                    {
                        "name": "Xã Chiềng San",
                        "code": 3844,
                        "codename": "xa_chieng_san",
                        "division_type": "xã",
                        "short_codename": "chieng_san"
                    },
                    {
                        "name": "Xã Mường Bú",
                        "code": 3847,
                        "codename": "xa_muong_bu",
                        "division_type": "xã",
                        "short_codename": "muong_bu"
                    },
                    {
                        "name": "Xã Chiềng Hoa",
                        "code": 3850,
                        "codename": "xa_chieng_hoa",
                        "division_type": "xã",
                        "short_codename": "chieng_hoa"
                    },
                    {
                        "name": "Xã Mường Chùm",
                        "code": 3853,
                        "codename": "xa_muong_chum",
                        "division_type": "xã",
                        "short_codename": "muong_chum"
                    }
                ]
            },
            {
                "name": "Huyện Bắc Yên",
                "code": 121,
                "codename": "huyen_bac_yen",
                "division_type": "huyện",
                "short_codename": "bac_yen",
                "wards": [
                    {
                        "name": "Thị trấn Bắc Yên",
                        "code": 3856,
                        "codename": "thi_tran_bac_yen",
                        "division_type": "thị trấn",
                        "short_codename": "bac_yen"
                    },
                    {
                        "name": "Xã Phiêng Ban",
                        "code": 3859,
                        "codename": "xa_phieng_ban",
                        "division_type": "xã",
                        "short_codename": "phieng_ban"
                    },
                    {
                        "name": "Xã Hang Chú",
                        "code": 3862,
                        "codename": "xa_hang_chu",
                        "division_type": "xã",
                        "short_codename": "hang_chu"
                    },
                    {
                        "name": "Xã Xím Vàng",
                        "code": 3865,
                        "codename": "xa_xim_vang",
                        "division_type": "xã",
                        "short_codename": "xim_vang"
                    },
                    {
                        "name": "Xã Tà Xùa",
                        "code": 3868,
                        "codename": "xa_ta_xua",
                        "division_type": "xã",
                        "short_codename": "ta_xua"
                    },
                    {
                        "name": "Xã Háng Đồng",
                        "code": 3869,
                        "codename": "xa_hang_dong",
                        "division_type": "xã",
                        "short_codename": "hang_dong"
                    },
                    {
                        "name": "Xã Pắc Ngà",
                        "code": 3871,
                        "codename": "xa_pac_nga",
                        "division_type": "xã",
                        "short_codename": "pac_nga"
                    },
                    {
                        "name": "Xã Làng Chếu",
                        "code": 3874,
                        "codename": "xa_lang_cheu",
                        "division_type": "xã",
                        "short_codename": "lang_cheu"
                    },
                    {
                        "name": "Xã Chim Vàn",
                        "code": 3877,
                        "codename": "xa_chim_van",
                        "division_type": "xã",
                        "short_codename": "chim_van"
                    },
                    {
                        "name": "Xã Mường Khoa",
                        "code": 3880,
                        "codename": "xa_muong_khoa",
                        "division_type": "xã",
                        "short_codename": "muong_khoa"
                    },
                    {
                        "name": "Xã Song Pe",
                        "code": 3883,
                        "codename": "xa_song_pe",
                        "division_type": "xã",
                        "short_codename": "song_pe"
                    },
                    {
                        "name": "Xã Hồng Ngài",
                        "code": 3886,
                        "codename": "xa_hong_ngai",
                        "division_type": "xã",
                        "short_codename": "hong_ngai"
                    },
                    {
                        "name": "Xã Tạ Khoa",
                        "code": 3889,
                        "codename": "xa_ta_khoa",
                        "division_type": "xã",
                        "short_codename": "ta_khoa"
                    },
                    {
                        "name": "Xã Hua Nhàn",
                        "code": 3890,
                        "codename": "xa_hua_nhan",
                        "division_type": "xã",
                        "short_codename": "hua_nhan"
                    },
                    {
                        "name": "Xã Phiêng Côn",
                        "code": 3892,
                        "codename": "xa_phieng_con",
                        "division_type": "xã",
                        "short_codename": "phieng_con"
                    },
                    {
                        "name": "Xã Chiềng Sại",
                        "code": 3895,
                        "codename": "xa_chieng_sai",
                        "division_type": "xã",
                        "short_codename": "chieng_sai"
                    }
                ]
            },
            {
                "name": "Huyện Phù Yên",
                "code": 122,
                "codename": "huyen_phu_yen",
                "division_type": "huyện",
                "short_codename": "phu_yen",
                "wards": [
                    {
                        "name": "Thị trấn Phù Yên",
                        "code": 3898,
                        "codename": "thi_tran_phu_yen",
                        "division_type": "thị trấn",
                        "short_codename": "phu_yen"
                    },
                    {
                        "name": "Xã Suối Tọ",
                        "code": 3901,
                        "codename": "xa_suoi_to",
                        "division_type": "xã",
                        "short_codename": "suoi_to"
                    },
                    {
                        "name": "Xã Mường Thải",
                        "code": 3904,
                        "codename": "xa_muong_thai",
                        "division_type": "xã",
                        "short_codename": "muong_thai"
                    },
                    {
                        "name": "Xã Mường Cơi",
                        "code": 3907,
                        "codename": "xa_muong_coi",
                        "division_type": "xã",
                        "short_codename": "muong_coi"
                    },
                    {
                        "name": "Xã Quang Huy",
                        "code": 3910,
                        "codename": "xa_quang_huy",
                        "division_type": "xã",
                        "short_codename": "quang_huy"
                    },
                    {
                        "name": "Xã Huy Bắc",
                        "code": 3913,
                        "codename": "xa_huy_bac",
                        "division_type": "xã",
                        "short_codename": "huy_bac"
                    },
                    {
                        "name": "Xã Huy Thượng",
                        "code": 3916,
                        "codename": "xa_huy_thuong",
                        "division_type": "xã",
                        "short_codename": "huy_thuong"
                    },
                    {
                        "name": "Xã Tân Lang",
                        "code": 3919,
                        "codename": "xa_tan_lang",
                        "division_type": "xã",
                        "short_codename": "tan_lang"
                    },
                    {
                        "name": "Xã Gia Phù",
                        "code": 3922,
                        "codename": "xa_gia_phu",
                        "division_type": "xã",
                        "short_codename": "gia_phu"
                    },
                    {
                        "name": "Xã Tường Phù",
                        "code": 3925,
                        "codename": "xa_tuong_phu",
                        "division_type": "xã",
                        "short_codename": "tuong_phu"
                    },
                    {
                        "name": "Xã Huy Hạ",
                        "code": 3928,
                        "codename": "xa_huy_ha",
                        "division_type": "xã",
                        "short_codename": "huy_ha"
                    },
                    {
                        "name": "Xã Huy Tân",
                        "code": 3931,
                        "codename": "xa_huy_tan",
                        "division_type": "xã",
                        "short_codename": "huy_tan"
                    },
                    {
                        "name": "Xã Mường Lang",
                        "code": 3934,
                        "codename": "xa_muong_lang",
                        "division_type": "xã",
                        "short_codename": "muong_lang"
                    },
                    {
                        "name": "Xã Suối Bau",
                        "code": 3937,
                        "codename": "xa_suoi_bau",
                        "division_type": "xã",
                        "short_codename": "suoi_bau"
                    },
                    {
                        "name": "Xã Huy Tường",
                        "code": 3940,
                        "codename": "xa_huy_tuong",
                        "division_type": "xã",
                        "short_codename": "huy_tuong"
                    },
                    {
                        "name": "Xã Mường Do",
                        "code": 3943,
                        "codename": "xa_muong_do",
                        "division_type": "xã",
                        "short_codename": "muong_do"
                    },
                    {
                        "name": "Xã Sập Xa",
                        "code": 3946,
                        "codename": "xa_sap_xa",
                        "division_type": "xã",
                        "short_codename": "sap_xa"
                    },
                    {
                        "name": "Xã Tường Thượng",
                        "code": 3949,
                        "codename": "xa_tuong_thuong",
                        "division_type": "xã",
                        "short_codename": "tuong_thuong"
                    },
                    {
                        "name": "Xã Tường Tiến",
                        "code": 3952,
                        "codename": "xa_tuong_tien",
                        "division_type": "xã",
                        "short_codename": "tuong_tien"
                    },
                    {
                        "name": "Xã Tường Phong",
                        "code": 3955,
                        "codename": "xa_tuong_phong",
                        "division_type": "xã",
                        "short_codename": "tuong_phong"
                    },
                    {
                        "name": "Xã Tường Hạ",
                        "code": 3958,
                        "codename": "xa_tuong_ha",
                        "division_type": "xã",
                        "short_codename": "tuong_ha"
                    },
                    {
                        "name": "Xã Kim Bon",
                        "code": 3961,
                        "codename": "xa_kim_bon",
                        "division_type": "xã",
                        "short_codename": "kim_bon"
                    },
                    {
                        "name": "Xã Mường Bang",
                        "code": 3964,
                        "codename": "xa_muong_bang",
                        "division_type": "xã",
                        "short_codename": "muong_bang"
                    },
                    {
                        "name": "Xã Đá Đỏ",
                        "code": 3967,
                        "codename": "xa_da_do",
                        "division_type": "xã",
                        "short_codename": "da_do"
                    },
                    {
                        "name": "Xã Tân Phong",
                        "code": 3970,
                        "codename": "xa_tan_phong",
                        "division_type": "xã",
                        "short_codename": "tan_phong"
                    },
                    {
                        "name": "Xã Nam Phong",
                        "code": 3973,
                        "codename": "xa_nam_phong",
                        "division_type": "xã",
                        "short_codename": "nam_phong"
                    },
                    {
                        "name": "Xã Bắc Phong",
                        "code": 3976,
                        "codename": "xa_bac_phong",
                        "division_type": "xã",
                        "short_codename": "bac_phong"
                    }
                ]
            },
            {
                "name": "Huyện Mộc Châu",
                "code": 123,
                "codename": "huyen_moc_chau",
                "division_type": "huyện",
                "short_codename": "moc_chau",
                "wards": [
                    {
                        "name": "Thị trấn Mộc Châu",
                        "code": 3979,
                        "codename": "thi_tran_moc_chau",
                        "division_type": "thị trấn",
                        "short_codename": "moc_chau"
                    },
                    {
                        "name": "Thị trấn NT Mộc Châu",
                        "code": 3982,
                        "codename": "thi_tran_nt_moc_chau",
                        "division_type": "thị trấn",
                        "short_codename": "nt_moc_chau"
                    },
                    {
                        "name": "Xã Chiềng Sơn",
                        "code": 3985,
                        "codename": "xa_chieng_son",
                        "division_type": "xã",
                        "short_codename": "chieng_son"
                    },
                    {
                        "name": "Xã Tân Hợp",
                        "code": 3988,
                        "codename": "xa_tan_hop",
                        "division_type": "xã",
                        "short_codename": "tan_hop"
                    },
                    {
                        "name": "Xã Qui Hướng",
                        "code": 3991,
                        "codename": "xa_qui_huong",
                        "division_type": "xã",
                        "short_codename": "qui_huong"
                    },
                    {
                        "name": "Xã Tân Lập",
                        "code": 3997,
                        "codename": "xa_tan_lap",
                        "division_type": "xã",
                        "short_codename": "tan_lap"
                    },
                    {
                        "name": "Xã Nà Mường",
                        "code": 4000,
                        "codename": "xa_na_muong",
                        "division_type": "xã",
                        "short_codename": "na_muong"
                    },
                    {
                        "name": "Xã Tà Lai",
                        "code": 4003,
                        "codename": "xa_ta_lai",
                        "division_type": "xã",
                        "short_codename": "ta_lai"
                    },
                    {
                        "name": "Xã Chiềng Hắc",
                        "code": 4012,
                        "codename": "xa_chieng_hac",
                        "division_type": "xã",
                        "short_codename": "chieng_hac"
                    },
                    {
                        "name": "Xã Hua Păng",
                        "code": 4015,
                        "codename": "xa_hua_pang",
                        "division_type": "xã",
                        "short_codename": "hua_pang"
                    },
                    {
                        "name": "Xã Chiềng Khừa",
                        "code": 4024,
                        "codename": "xa_chieng_khua",
                        "division_type": "xã",
                        "short_codename": "chieng_khua"
                    },
                    {
                        "name": "Xã Mường Sang",
                        "code": 4027,
                        "codename": "xa_muong_sang",
                        "division_type": "xã",
                        "short_codename": "muong_sang"
                    },
                    {
                        "name": "Xã Đông Sang",
                        "code": 4030,
                        "codename": "xa_dong_sang",
                        "division_type": "xã",
                        "short_codename": "dong_sang"
                    },
                    {
                        "name": "Xã Phiêng Luông",
                        "code": 4033,
                        "codename": "xa_phieng_luong",
                        "division_type": "xã",
                        "short_codename": "phieng_luong"
                    },
                    {
                        "name": "Xã Lóng Sập",
                        "code": 4045,
                        "codename": "xa_long_sap",
                        "division_type": "xã",
                        "short_codename": "long_sap"
                    }
                ]
            },
            {
                "name": "Huyện Yên Châu",
                "code": 124,
                "codename": "huyen_yen_chau",
                "division_type": "huyện",
                "short_codename": "yen_chau",
                "wards": [
                    {
                        "name": "Thị trấn Yên Châu",
                        "code": 4060,
                        "codename": "thi_tran_yen_chau",
                        "division_type": "thị trấn",
                        "short_codename": "yen_chau"
                    },
                    {
                        "name": "Xã Chiềng Đông",
                        "code": 4063,
                        "codename": "xa_chieng_dong",
                        "division_type": "xã",
                        "short_codename": "chieng_dong"
                    },
                    {
                        "name": "Xã Sập Vạt",
                        "code": 4066,
                        "codename": "xa_sap_vat",
                        "division_type": "xã",
                        "short_codename": "sap_vat"
                    },
                    {
                        "name": "Xã Chiềng Sàng",
                        "code": 4069,
                        "codename": "xa_chieng_sang",
                        "division_type": "xã",
                        "short_codename": "chieng_sang"
                    },
                    {
                        "name": "Xã Chiềng Pằn",
                        "code": 4072,
                        "codename": "xa_chieng_pan",
                        "division_type": "xã",
                        "short_codename": "chieng_pan"
                    },
                    {
                        "name": "Xã Viêng Lán",
                        "code": 4075,
                        "codename": "xa_vieng_lan",
                        "division_type": "xã",
                        "short_codename": "vieng_lan"
                    },
                    {
                        "name": "Xã Chiềng Hặc",
                        "code": 4078,
                        "codename": "xa_chieng_hac",
                        "division_type": "xã",
                        "short_codename": "chieng_hac"
                    },
                    {
                        "name": "Xã Mường Lựm",
                        "code": 4081,
                        "codename": "xa_muong_lum",
                        "division_type": "xã",
                        "short_codename": "muong_lum"
                    },
                    {
                        "name": "Xã Chiềng On",
                        "code": 4084,
                        "codename": "xa_chieng_on",
                        "division_type": "xã",
                        "short_codename": "chieng_on"
                    },
                    {
                        "name": "Xã Yên Sơn",
                        "code": 4087,
                        "codename": "xa_yen_son",
                        "division_type": "xã",
                        "short_codename": "yen_son"
                    },
                    {
                        "name": "Xã Chiềng Khoi",
                        "code": 4090,
                        "codename": "xa_chieng_khoi",
                        "division_type": "xã",
                        "short_codename": "chieng_khoi"
                    },
                    {
                        "name": "Xã Tú Nang",
                        "code": 4093,
                        "codename": "xa_tu_nang",
                        "division_type": "xã",
                        "short_codename": "tu_nang"
                    },
                    {
                        "name": "Xã Lóng Phiêng",
                        "code": 4096,
                        "codename": "xa_long_phieng",
                        "division_type": "xã",
                        "short_codename": "long_phieng"
                    },
                    {
                        "name": "Xã Phiêng Khoài",
                        "code": 4099,
                        "codename": "xa_phieng_khoai",
                        "division_type": "xã",
                        "short_codename": "phieng_khoai"
                    },
                    {
                        "name": "Xã Chiềng Tương",
                        "code": 4102,
                        "codename": "xa_chieng_tuong",
                        "division_type": "xã",
                        "short_codename": "chieng_tuong"
                    }
                ]
            },
            {
                "name": "Huyện Mai Sơn",
                "code": 125,
                "codename": "huyen_mai_son",
                "division_type": "huyện",
                "short_codename": "mai_son",
                "wards": [
                    {
                        "name": "Thị trấn Hát Lót",
                        "code": 4105,
                        "codename": "thi_tran_hat_lot",
                        "division_type": "thị trấn",
                        "short_codename": "hat_lot"
                    },
                    {
                        "name": "Xã Chiềng Sung",
                        "code": 4108,
                        "codename": "xa_chieng_sung",
                        "division_type": "xã",
                        "short_codename": "chieng_sung"
                    },
                    {
                        "name": "Xã Mường Bằng",
                        "code": 4111,
                        "codename": "xa_muong_bang",
                        "division_type": "xã",
                        "short_codename": "muong_bang"
                    },
                    {
                        "name": "Xã Chiềng Chăn",
                        "code": 4114,
                        "codename": "xa_chieng_chan",
                        "division_type": "xã",
                        "short_codename": "chieng_chan"
                    },
                    {
                        "name": "Xã Mương Chanh",
                        "code": 4117,
                        "codename": "xa_muong_chanh",
                        "division_type": "xã",
                        "short_codename": "muong_chanh"
                    },
                    {
                        "name": "Xã Chiềng Ban",
                        "code": 4120,
                        "codename": "xa_chieng_ban",
                        "division_type": "xã",
                        "short_codename": "chieng_ban"
                    },
                    {
                        "name": "Xã Chiềng Mung",
                        "code": 4123,
                        "codename": "xa_chieng_mung",
                        "division_type": "xã",
                        "short_codename": "chieng_mung"
                    },
                    {
                        "name": "Xã Mường Bon",
                        "code": 4126,
                        "codename": "xa_muong_bon",
                        "division_type": "xã",
                        "short_codename": "muong_bon"
                    },
                    {
                        "name": "Xã Chiềng Chung",
                        "code": 4129,
                        "codename": "xa_chieng_chung",
                        "division_type": "xã",
                        "short_codename": "chieng_chung"
                    },
                    {
                        "name": "Xã Chiềng Mai",
                        "code": 4132,
                        "codename": "xa_chieng_mai",
                        "division_type": "xã",
                        "short_codename": "chieng_mai"
                    },
                    {
                        "name": "Xã Hát Lót",
                        "code": 4135,
                        "codename": "xa_hat_lot",
                        "division_type": "xã",
                        "short_codename": "xa_hat_lot"
                    },
                    {
                        "name": "Xã Nà Pó",
                        "code": 4136,
                        "codename": "xa_na_po",
                        "division_type": "xã",
                        "short_codename": "na_po"
                    },
                    {
                        "name": "Xã Cò Nòi",
                        "code": 4138,
                        "codename": "xa_co_noi",
                        "division_type": "xã",
                        "short_codename": "co_noi"
                    },
                    {
                        "name": "Xã Chiềng Nơi",
                        "code": 4141,
                        "codename": "xa_chieng_noi",
                        "division_type": "xã",
                        "short_codename": "chieng_noi"
                    },
                    {
                        "name": "Xã Phiêng Cằm",
                        "code": 4144,
                        "codename": "xa_phieng_cam",
                        "division_type": "xã",
                        "short_codename": "phieng_cam"
                    },
                    {
                        "name": "Xã Chiềng Dong",
                        "code": 4147,
                        "codename": "xa_chieng_dong",
                        "division_type": "xã",
                        "short_codename": "chieng_dong"
                    },
                    {
                        "name": "Xã Chiềng Kheo",
                        "code": 4150,
                        "codename": "xa_chieng_kheo",
                        "division_type": "xã",
                        "short_codename": "chieng_kheo"
                    },
                    {
                        "name": "Xã Chiềng Ve",
                        "code": 4153,
                        "codename": "xa_chieng_ve",
                        "division_type": "xã",
                        "short_codename": "chieng_ve"
                    },
                    {
                        "name": "Xã Chiềng Lương",
                        "code": 4156,
                        "codename": "xa_chieng_luong",
                        "division_type": "xã",
                        "short_codename": "chieng_luong"
                    },
                    {
                        "name": "Xã Phiêng Pằn",
                        "code": 4159,
                        "codename": "xa_phieng_pan",
                        "division_type": "xã",
                        "short_codename": "phieng_pan"
                    },
                    {
                        "name": "Xã Nà Ơt",
                        "code": 4162,
                        "codename": "xa_na_ot",
                        "division_type": "xã",
                        "short_codename": "na_ot"
                    },
                    {
                        "name": "Xã Tà Hộc",
                        "code": 4165,
                        "codename": "xa_ta_hoc",
                        "division_type": "xã",
                        "short_codename": "ta_hoc"
                    }
                ]
            },
            {
                "name": "Huyện Sông Mã",
                "code": 126,
                "codename": "huyen_song_ma",
                "division_type": "huyện",
                "short_codename": "song_ma",
                "wards": [
                    {
                        "name": "Thị trấn Sông Mã",
                        "code": 4168,
                        "codename": "thi_tran_song_ma",
                        "division_type": "thị trấn",
                        "short_codename": "song_ma"
                    },
                    {
                        "name": "Xã Bó Sinh",
                        "code": 4171,
                        "codename": "xa_bo_sinh",
                        "division_type": "xã",
                        "short_codename": "bo_sinh"
                    },
                    {
                        "name": "Xã Pú Pẩu",
                        "code": 4174,
                        "codename": "xa_pu_pau",
                        "division_type": "xã",
                        "short_codename": "pu_pau"
                    },
                    {
                        "name": "Xã Chiềng Phung",
                        "code": 4177,
                        "codename": "xa_chieng_phung",
                        "division_type": "xã",
                        "short_codename": "chieng_phung"
                    },
                    {
                        "name": "Xã Chiềng En",
                        "code": 4180,
                        "codename": "xa_chieng_en",
                        "division_type": "xã",
                        "short_codename": "chieng_en"
                    },
                    {
                        "name": "Xã Mường Lầm",
                        "code": 4183,
                        "codename": "xa_muong_lam",
                        "division_type": "xã",
                        "short_codename": "muong_lam"
                    },
                    {
                        "name": "Xã Nậm Ty",
                        "code": 4186,
                        "codename": "xa_nam_ty",
                        "division_type": "xã",
                        "short_codename": "nam_ty"
                    },
                    {
                        "name": "Xã Đứa Mòn",
                        "code": 4189,
                        "codename": "xa_dua_mon",
                        "division_type": "xã",
                        "short_codename": "dua_mon"
                    },
                    {
                        "name": "Xã Yên Hưng",
                        "code": 4192,
                        "codename": "xa_yen_hung",
                        "division_type": "xã",
                        "short_codename": "yen_hung"
                    },
                    {
                        "name": "Xã Chiềng Sơ",
                        "code": 4195,
                        "codename": "xa_chieng_so",
                        "division_type": "xã",
                        "short_codename": "chieng_so"
                    },
                    {
                        "name": "Xã Nà Nghịu",
                        "code": 4198,
                        "codename": "xa_na_nghiu",
                        "division_type": "xã",
                        "short_codename": "na_nghiu"
                    },
                    {
                        "name": "Xã Nậm Mằn",
                        "code": 4201,
                        "codename": "xa_nam_man",
                        "division_type": "xã",
                        "short_codename": "nam_man"
                    },
                    {
                        "name": "Xã Chiềng Khoong",
                        "code": 4204,
                        "codename": "xa_chieng_khoong",
                        "division_type": "xã",
                        "short_codename": "chieng_khoong"
                    },
                    {
                        "name": "Xã Chiềng Cang",
                        "code": 4207,
                        "codename": "xa_chieng_cang",
                        "division_type": "xã",
                        "short_codename": "chieng_cang"
                    },
                    {
                        "name": "Xã Huổi Một",
                        "code": 4210,
                        "codename": "xa_huoi_mot",
                        "division_type": "xã",
                        "short_codename": "huoi_mot"
                    },
                    {
                        "name": "Xã Mường Sai",
                        "code": 4213,
                        "codename": "xa_muong_sai",
                        "division_type": "xã",
                        "short_codename": "muong_sai"
                    },
                    {
                        "name": "Xã Mường Cai",
                        "code": 4216,
                        "codename": "xa_muong_cai",
                        "division_type": "xã",
                        "short_codename": "muong_cai"
                    },
                    {
                        "name": "Xã Mường Hung",
                        "code": 4219,
                        "codename": "xa_muong_hung",
                        "division_type": "xã",
                        "short_codename": "muong_hung"
                    },
                    {
                        "name": "Xã Chiềng Khương",
                        "code": 4222,
                        "codename": "xa_chieng_khuong",
                        "division_type": "xã",
                        "short_codename": "chieng_khuong"
                    }
                ]
            },
            {
                "name": "Huyện Sốp Cộp",
                "code": 127,
                "codename": "huyen_sop_cop",
                "division_type": "huyện",
                "short_codename": "sop_cop",
                "wards": [
                    {
                        "name": "Xã Sam Kha",
                        "code": 4225,
                        "codename": "xa_sam_kha",
                        "division_type": "xã",
                        "short_codename": "sam_kha"
                    },
                    {
                        "name": "Xã Púng Bánh",
                        "code": 4228,
                        "codename": "xa_pung_banh",
                        "division_type": "xã",
                        "short_codename": "pung_banh"
                    },
                    {
                        "name": "Xã Sốp Cộp",
                        "code": 4231,
                        "codename": "xa_sop_cop",
                        "division_type": "xã",
                        "short_codename": "sop_cop"
                    },
                    {
                        "name": "Xã Dồm Cang",
                        "code": 4234,
                        "codename": "xa_dom_cang",
                        "division_type": "xã",
                        "short_codename": "dom_cang"
                    },
                    {
                        "name": "Xã Nậm Lạnh",
                        "code": 4237,
                        "codename": "xa_nam_lanh",
                        "division_type": "xã",
                        "short_codename": "nam_lanh"
                    },
                    {
                        "name": "Xã Mường Lèo",
                        "code": 4240,
                        "codename": "xa_muong_leo",
                        "division_type": "xã",
                        "short_codename": "muong_leo"
                    },
                    {
                        "name": "Xã Mường Và",
                        "code": 4243,
                        "codename": "xa_muong_va",
                        "division_type": "xã",
                        "short_codename": "muong_va"
                    },
                    {
                        "name": "Xã Mường Lạn",
                        "code": 4246,
                        "codename": "xa_muong_lan",
                        "division_type": "xã",
                        "short_codename": "muong_lan"
                    }
                ]
            },
            {
                "name": "Huyện Vân Hồ",
                "code": 128,
                "codename": "huyen_van_ho",
                "division_type": "huyện",
                "short_codename": "van_ho",
                "wards": [
                    {
                        "name": "Xã Suối Bàng",
                        "code": 3994,
                        "codename": "xa_suoi_bang",
                        "division_type": "xã",
                        "short_codename": "suoi_bang"
                    },
                    {
                        "name": "Xã Song Khủa",
                        "code": 4006,
                        "codename": "xa_song_khua",
                        "division_type": "xã",
                        "short_codename": "song_khua"
                    },
                    {
                        "name": "Xã Liên Hoà",
                        "code": 4009,
                        "codename": "xa_lien_hoa",
                        "division_type": "xã",
                        "short_codename": "lien_hoa"
                    },
                    {
                        "name": "Xã Tô Múa",
                        "code": 4018,
                        "codename": "xa_to_mua",
                        "division_type": "xã",
                        "short_codename": "to_mua"
                    },
                    {
                        "name": "Xã Mường Tè",
                        "code": 4021,
                        "codename": "xa_muong_te",
                        "division_type": "xã",
                        "short_codename": "muong_te"
                    },
                    {
                        "name": "Xã Chiềng Khoa",
                        "code": 4036,
                        "codename": "xa_chieng_khoa",
                        "division_type": "xã",
                        "short_codename": "chieng_khoa"
                    },
                    {
                        "name": "Xã Mường Men",
                        "code": 4039,
                        "codename": "xa_muong_men",
                        "division_type": "xã",
                        "short_codename": "muong_men"
                    },
                    {
                        "name": "Xã Quang Minh",
                        "code": 4042,
                        "codename": "xa_quang_minh",
                        "division_type": "xã",
                        "short_codename": "quang_minh"
                    },
                    {
                        "name": "Xã Vân Hồ",
                        "code": 4048,
                        "codename": "xa_van_ho",
                        "division_type": "xã",
                        "short_codename": "van_ho"
                    },
                    {
                        "name": "Xã Lóng Luông",
                        "code": 4051,
                        "codename": "xa_long_luong",
                        "division_type": "xã",
                        "short_codename": "long_luong"
                    },
                    {
                        "name": "Xã Chiềng Yên",
                        "code": 4054,
                        "codename": "xa_chieng_yen",
                        "division_type": "xã",
                        "short_codename": "chieng_yen"
                    },
                    {
                        "name": "Xã Chiềng Xuân",
                        "code": 4056,
                        "codename": "xa_chieng_xuan",
                        "division_type": "xã",
                        "short_codename": "chieng_xuan"
                    },
                    {
                        "name": "Xã Xuân Nha",
                        "code": 4057,
                        "codename": "xa_xuan_nha",
                        "division_type": "xã",
                        "short_codename": "xuan_nha"
                    },
                    {
                        "name": "Xã Tân Xuân",
                        "code": 4058,
                        "codename": "xa_tan_xuan",
                        "division_type": "xã",
                        "short_codename": "tan_xuan"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Yên Bái",
        "code": 15,
        "codename": "tinh_yen_bai",
        "division_type": "tỉnh",
        "phone_code": 216,
        "districts": [
            {
                "name": "Thành phố Yên Bái",
                "code": 132,
                "codename": "thanh_pho_yen_bai",
                "division_type": "thành phố",
                "short_codename": "yen_bai",
                "wards": [
                    {
                        "name": "Phường Yên Thịnh",
                        "code": 4249,
                        "codename": "phuong_yen_thinh",
                        "division_type": "phường",
                        "short_codename": "yen_thinh"
                    },
                    {
                        "name": "Phường Yên Ninh",
                        "code": 4252,
                        "codename": "phuong_yen_ninh",
                        "division_type": "phường",
                        "short_codename": "yen_ninh"
                    },
                    {
                        "name": "Phường Minh Tân",
                        "code": 4255,
                        "codename": "phuong_minh_tan",
                        "division_type": "phường",
                        "short_codename": "minh_tan"
                    },
                    {
                        "name": "Phường Nguyễn Thái Học",
                        "code": 4258,
                        "codename": "phuong_nguyen_thai_hoc",
                        "division_type": "phường",
                        "short_codename": "nguyen_thai_hoc"
                    },
                    {
                        "name": "Phường Đồng Tâm",
                        "code": 4261,
                        "codename": "phuong_dong_tam",
                        "division_type": "phường",
                        "short_codename": "dong_tam"
                    },
                    {
                        "name": "Phường Nguyễn Phúc",
                        "code": 4264,
                        "codename": "phuong_nguyen_phuc",
                        "division_type": "phường",
                        "short_codename": "nguyen_phuc"
                    },
                    {
                        "name": "Phường Hồng Hà",
                        "code": 4267,
                        "codename": "phuong_hong_ha",
                        "division_type": "phường",
                        "short_codename": "hong_ha"
                    },
                    {
                        "name": "Xã Minh Bảo",
                        "code": 4270,
                        "codename": "xa_minh_bao",
                        "division_type": "xã",
                        "short_codename": "minh_bao"
                    },
                    {
                        "name": "Phường Nam Cường",
                        "code": 4273,
                        "codename": "phuong_nam_cuong",
                        "division_type": "phường",
                        "short_codename": "nam_cuong"
                    },
                    {
                        "name": "Xã Tuy Lộc",
                        "code": 4276,
                        "codename": "xa_tuy_loc",
                        "division_type": "xã",
                        "short_codename": "tuy_loc"
                    },
                    {
                        "name": "Xã Tân Thịnh",
                        "code": 4279,
                        "codename": "xa_tan_thinh",
                        "division_type": "xã",
                        "short_codename": "tan_thinh"
                    },
                    {
                        "name": "Xã Âu Lâu",
                        "code": 4540,
                        "codename": "xa_au_lau",
                        "division_type": "xã",
                        "short_codename": "au_lau"
                    },
                    {
                        "name": "Xã Giới Phiên",
                        "code": 4543,
                        "codename": "xa_gioi_phien",
                        "division_type": "xã",
                        "short_codename": "gioi_phien"
                    },
                    {
                        "name": "Phường Hợp Minh",
                        "code": 4546,
                        "codename": "phuong_hop_minh",
                        "division_type": "phường",
                        "short_codename": "hop_minh"
                    },
                    {
                        "name": "Xã Văn Phú",
                        "code": 4558,
                        "codename": "xa_van_phu",
                        "division_type": "xã",
                        "short_codename": "van_phu"
                    }
                ]
            },
            {
                "name": "Thị xã Nghĩa Lộ",
                "code": 133,
                "codename": "thi_xa_nghia_lo",
                "division_type": "thị xã",
                "short_codename": "nghia_lo",
                "wards": [
                    {
                        "name": "Phường Pú Trạng",
                        "code": 4282,
                        "codename": "phuong_pu_trang",
                        "division_type": "phường",
                        "short_codename": "pu_trang"
                    },
                    {
                        "name": "Phường Trung Tâm",
                        "code": 4285,
                        "codename": "phuong_trung_tam",
                        "division_type": "phường",
                        "short_codename": "trung_tam"
                    },
                    {
                        "name": "Phường Tân An",
                        "code": 4288,
                        "codename": "phuong_tan_an",
                        "division_type": "phường",
                        "short_codename": "tan_an"
                    },
                    {
                        "name": "Phường Cầu Thia",
                        "code": 4291,
                        "codename": "phuong_cau_thia",
                        "division_type": "phường",
                        "short_codename": "cau_thia"
                    },
                    {
                        "name": "Xã Nghĩa Lợi",
                        "code": 4294,
                        "codename": "xa_nghia_loi",
                        "division_type": "xã",
                        "short_codename": "nghia_loi"
                    },
                    {
                        "name": "Xã Nghĩa Phúc",
                        "code": 4297,
                        "codename": "xa_nghia_phuc",
                        "division_type": "xã",
                        "short_codename": "nghia_phuc"
                    },
                    {
                        "name": "Xã Nghĩa An",
                        "code": 4300,
                        "codename": "xa_nghia_an",
                        "division_type": "xã",
                        "short_codename": "nghia_an"
                    },
                    {
                        "name": "Xã Nghĩa Lộ",
                        "code": 4624,
                        "codename": "xa_nghia_lo",
                        "division_type": "xã",
                        "short_codename": "nghia_lo"
                    },
                    {
                        "name": "Xã Sơn A",
                        "code": 4660,
                        "codename": "xa_son_a",
                        "division_type": "xã",
                        "short_codename": "son_a"
                    },
                    {
                        "name": "Xã Phù Nham",
                        "code": 4663,
                        "codename": "xa_phu_nham",
                        "division_type": "xã",
                        "short_codename": "phu_nham"
                    },
                    {
                        "name": "Xã Thanh Lương",
                        "code": 4675,
                        "codename": "xa_thanh_luong",
                        "division_type": "xã",
                        "short_codename": "thanh_luong"
                    },
                    {
                        "name": "Xã Hạnh Sơn",
                        "code": 4678,
                        "codename": "xa_hanh_son",
                        "division_type": "xã",
                        "short_codename": "hanh_son"
                    },
                    {
                        "name": "Xã Phúc Sơn",
                        "code": 4681,
                        "codename": "xa_phuc_son",
                        "division_type": "xã",
                        "short_codename": "phuc_son"
                    },
                    {
                        "name": "Xã Thạch Lương",
                        "code": 4684,
                        "codename": "xa_thach_luong",
                        "division_type": "xã",
                        "short_codename": "thach_luong"
                    }
                ]
            },
            {
                "name": "Huyện Lục Yên",
                "code": 135,
                "codename": "huyen_luc_yen",
                "division_type": "huyện",
                "short_codename": "luc_yen",
                "wards": [
                    {
                        "name": "Thị trấn Yên Thế",
                        "code": 4303,
                        "codename": "thi_tran_yen_the",
                        "division_type": "thị trấn",
                        "short_codename": "yen_the"
                    },
                    {
                        "name": "Xã Tân Phượng",
                        "code": 4306,
                        "codename": "xa_tan_phuong",
                        "division_type": "xã",
                        "short_codename": "tan_phuong"
                    },
                    {
                        "name": "Xã Lâm Thượng",
                        "code": 4309,
                        "codename": "xa_lam_thuong",
                        "division_type": "xã",
                        "short_codename": "lam_thuong"
                    },
                    {
                        "name": "Xã Khánh Thiện",
                        "code": 4312,
                        "codename": "xa_khanh_thien",
                        "division_type": "xã",
                        "short_codename": "khanh_thien"
                    },
                    {
                        "name": "Xã Minh Chuẩn",
                        "code": 4315,
                        "codename": "xa_minh_chuan",
                        "division_type": "xã",
                        "short_codename": "minh_chuan"
                    },
                    {
                        "name": "Xã Mai Sơn",
                        "code": 4318,
                        "codename": "xa_mai_son",
                        "division_type": "xã",
                        "short_codename": "mai_son"
                    },
                    {
                        "name": "Xã Khai Trung",
                        "code": 4321,
                        "codename": "xa_khai_trung",
                        "division_type": "xã",
                        "short_codename": "khai_trung"
                    },
                    {
                        "name": "Xã Mường Lai",
                        "code": 4324,
                        "codename": "xa_muong_lai",
                        "division_type": "xã",
                        "short_codename": "muong_lai"
                    },
                    {
                        "name": "Xã An Lạc",
                        "code": 4327,
                        "codename": "xa_an_lac",
                        "division_type": "xã",
                        "short_codename": "an_lac"
                    },
                    {
                        "name": "Xã Minh Xuân",
                        "code": 4330,
                        "codename": "xa_minh_xuan",
                        "division_type": "xã",
                        "short_codename": "minh_xuan"
                    },
                    {
                        "name": "Xã Tô Mậu",
                        "code": 4333,
                        "codename": "xa_to_mau",
                        "division_type": "xã",
                        "short_codename": "to_mau"
                    },
                    {
                        "name": "Xã Tân Lĩnh",
                        "code": 4336,
                        "codename": "xa_tan_linh",
                        "division_type": "xã",
                        "short_codename": "tan_linh"
                    },
                    {
                        "name": "Xã Yên Thắng",
                        "code": 4339,
                        "codename": "xa_yen_thang",
                        "division_type": "xã",
                        "short_codename": "yen_thang"
                    },
                    {
                        "name": "Xã Khánh Hoà",
                        "code": 4342,
                        "codename": "xa_khanh_hoa",
                        "division_type": "xã",
                        "short_codename": "khanh_hoa"
                    },
                    {
                        "name": "Xã Vĩnh Lạc",
                        "code": 4345,
                        "codename": "xa_vinh_lac",
                        "division_type": "xã",
                        "short_codename": "vinh_lac"
                    },
                    {
                        "name": "Xã Liễu Đô",
                        "code": 4348,
                        "codename": "xa_lieu_do",
                        "division_type": "xã",
                        "short_codename": "lieu_do"
                    },
                    {
                        "name": "Xã Động Quan",
                        "code": 4351,
                        "codename": "xa_dong_quan",
                        "division_type": "xã",
                        "short_codename": "dong_quan"
                    },
                    {
                        "name": "Xã Tân Lập",
                        "code": 4354,
                        "codename": "xa_tan_lap",
                        "division_type": "xã",
                        "short_codename": "tan_lap"
                    },
                    {
                        "name": "Xã Minh Tiến",
                        "code": 4357,
                        "codename": "xa_minh_tien",
                        "division_type": "xã",
                        "short_codename": "minh_tien"
                    },
                    {
                        "name": "Xã Trúc Lâu",
                        "code": 4360,
                        "codename": "xa_truc_lau",
                        "division_type": "xã",
                        "short_codename": "truc_lau"
                    },
                    {
                        "name": "Xã Phúc Lợi",
                        "code": 4363,
                        "codename": "xa_phuc_loi",
                        "division_type": "xã",
                        "short_codename": "phuc_loi"
                    },
                    {
                        "name": "Xã Phan Thanh",
                        "code": 4366,
                        "codename": "xa_phan_thanh",
                        "division_type": "xã",
                        "short_codename": "phan_thanh"
                    },
                    {
                        "name": "Xã An Phú",
                        "code": 4369,
                        "codename": "xa_an_phu",
                        "division_type": "xã",
                        "short_codename": "an_phu"
                    },
                    {
                        "name": "Xã Trung Tâm",
                        "code": 4372,
                        "codename": "xa_trung_tam",
                        "division_type": "xã",
                        "short_codename": "trung_tam"
                    }
                ]
            },
            {
                "name": "Huyện Văn Yên",
                "code": 136,
                "codename": "huyen_van_yen",
                "division_type": "huyện",
                "short_codename": "van_yen",
                "wards": [
                    {
                        "name": "Thị trấn Mậu A",
                        "code": 4375,
                        "codename": "thi_tran_mau_a",
                        "division_type": "thị trấn",
                        "short_codename": "mau_a"
                    },
                    {
                        "name": "Xã Lang Thíp",
                        "code": 4378,
                        "codename": "xa_lang_thip",
                        "division_type": "xã",
                        "short_codename": "lang_thip"
                    },
                    {
                        "name": "Xã Lâm Giang",
                        "code": 4381,
                        "codename": "xa_lam_giang",
                        "division_type": "xã",
                        "short_codename": "lam_giang"
                    },
                    {
                        "name": "Xã Châu Quế Thượng",
                        "code": 4384,
                        "codename": "xa_chau_que_thuong",
                        "division_type": "xã",
                        "short_codename": "chau_que_thuong"
                    },
                    {
                        "name": "Xã Châu Quế Hạ",
                        "code": 4387,
                        "codename": "xa_chau_que_ha",
                        "division_type": "xã",
                        "short_codename": "chau_que_ha"
                    },
                    {
                        "name": "Xã An Bình",
                        "code": 4390,
                        "codename": "xa_an_binh",
                        "division_type": "xã",
                        "short_codename": "an_binh"
                    },
                    {
                        "name": "Xã Quang Minh",
                        "code": 4393,
                        "codename": "xa_quang_minh",
                        "division_type": "xã",
                        "short_codename": "quang_minh"
                    },
                    {
                        "name": "Xã Đông An",
                        "code": 4396,
                        "codename": "xa_dong_an",
                        "division_type": "xã",
                        "short_codename": "dong_an"
                    },
                    {
                        "name": "Xã Đông Cuông",
                        "code": 4399,
                        "codename": "xa_dong_cuong",
                        "division_type": "xã",
                        "short_codename": "dong_cuong"
                    },
                    {
                        "name": "Xã Phong Dụ Hạ",
                        "code": 4402,
                        "codename": "xa_phong_du_ha",
                        "division_type": "xã",
                        "short_codename": "phong_du_ha"
                    },
                    {
                        "name": "Xã Mậu Đông",
                        "code": 4405,
                        "codename": "xa_mau_dong",
                        "division_type": "xã",
                        "short_codename": "mau_dong"
                    },
                    {
                        "name": "Xã Ngòi A",
                        "code": 4408,
                        "codename": "xa_ngoi_a",
                        "division_type": "xã",
                        "short_codename": "ngoi_a"
                    },
                    {
                        "name": "Xã Xuân Tầm",
                        "code": 4411,
                        "codename": "xa_xuan_tam",
                        "division_type": "xã",
                        "short_codename": "xuan_tam"
                    },
                    {
                        "name": "Xã Tân Hợp",
                        "code": 4414,
                        "codename": "xa_tan_hop",
                        "division_type": "xã",
                        "short_codename": "tan_hop"
                    },
                    {
                        "name": "Xã An Thịnh",
                        "code": 4417,
                        "codename": "xa_an_thinh",
                        "division_type": "xã",
                        "short_codename": "an_thinh"
                    },
                    {
                        "name": "Xã Yên Thái",
                        "code": 4420,
                        "codename": "xa_yen_thai",
                        "division_type": "xã",
                        "short_codename": "yen_thai"
                    },
                    {
                        "name": "Xã Phong Dụ Thượng",
                        "code": 4423,
                        "codename": "xa_phong_du_thuong",
                        "division_type": "xã",
                        "short_codename": "phong_du_thuong"
                    },
                    {
                        "name": "Xã Yên Hợp",
                        "code": 4426,
                        "codename": "xa_yen_hop",
                        "division_type": "xã",
                        "short_codename": "yen_hop"
                    },
                    {
                        "name": "Xã Đại Sơn",
                        "code": 4429,
                        "codename": "xa_dai_son",
                        "division_type": "xã",
                        "short_codename": "dai_son"
                    },
                    {
                        "name": "Xã Đại Phác",
                        "code": 4435,
                        "codename": "xa_dai_phac",
                        "division_type": "xã",
                        "short_codename": "dai_phac"
                    },
                    {
                        "name": "Xã Yên Phú",
                        "code": 4438,
                        "codename": "xa_yen_phu",
                        "division_type": "xã",
                        "short_codename": "yen_phu"
                    },
                    {
                        "name": "Xã Xuân Ái",
                        "code": 4441,
                        "codename": "xa_xuan_ai",
                        "division_type": "xã",
                        "short_codename": "xuan_ai"
                    },
                    {
                        "name": "Xã Viễn Sơn",
                        "code": 4447,
                        "codename": "xa_vien_son",
                        "division_type": "xã",
                        "short_codename": "vien_son"
                    },
                    {
                        "name": "Xã Mỏ Vàng",
                        "code": 4450,
                        "codename": "xa_mo_vang",
                        "division_type": "xã",
                        "short_codename": "mo_vang"
                    },
                    {
                        "name": "Xã Nà Hẩu",
                        "code": 4453,
                        "codename": "xa_na_hau",
                        "division_type": "xã",
                        "short_codename": "na_hau"
                    }
                ]
            },
            {
                "name": "Huyện Mù Căng Chải",
                "code": 137,
                "codename": "huyen_mu_cang_chai",
                "division_type": "huyện",
                "short_codename": "mu_cang_chai",
                "wards": [
                    {
                        "name": "Thị trấn Mù Căng Chải",
                        "code": 4456,
                        "codename": "thi_tran_mu_cang_chai",
                        "division_type": "thị trấn",
                        "short_codename": "mu_cang_chai"
                    },
                    {
                        "name": "Xã Hồ Bốn",
                        "code": 4459,
                        "codename": "xa_ho_bon",
                        "division_type": "xã",
                        "short_codename": "ho_bon"
                    },
                    {
                        "name": "Xã Nậm Có",
                        "code": 4462,
                        "codename": "xa_nam_co",
                        "division_type": "xã",
                        "short_codename": "nam_co"
                    },
                    {
                        "name": "Xã Khao Mang",
                        "code": 4465,
                        "codename": "xa_khao_mang",
                        "division_type": "xã",
                        "short_codename": "khao_mang"
                    },
                    {
                        "name": "Xã Mồ Dề",
                        "code": 4468,
                        "codename": "xa_mo_de",
                        "division_type": "xã",
                        "short_codename": "mo_de"
                    },
                    {
                        "name": "Xã Chế Cu Nha",
                        "code": 4471,
                        "codename": "xa_che_cu_nha",
                        "division_type": "xã",
                        "short_codename": "che_cu_nha"
                    },
                    {
                        "name": "Xã Lao Chải",
                        "code": 4474,
                        "codename": "xa_lao_chai",
                        "division_type": "xã",
                        "short_codename": "lao_chai"
                    },
                    {
                        "name": "Xã Kim Nọi",
                        "code": 4477,
                        "codename": "xa_kim_noi",
                        "division_type": "xã",
                        "short_codename": "kim_noi"
                    },
                    {
                        "name": "Xã Cao Phạ",
                        "code": 4480,
                        "codename": "xa_cao_pha",
                        "division_type": "xã",
                        "short_codename": "cao_pha"
                    },
                    {
                        "name": "Xã La Pán Tẩn",
                        "code": 4483,
                        "codename": "xa_la_pan_tan",
                        "division_type": "xã",
                        "short_codename": "la_pan_tan"
                    },
                    {
                        "name": "Xã Dế Su Phình",
                        "code": 4486,
                        "codename": "xa_de_su_phinh",
                        "division_type": "xã",
                        "short_codename": "de_su_phinh"
                    },
                    {
                        "name": "Xã Chế Tạo",
                        "code": 4489,
                        "codename": "xa_che_tao",
                        "division_type": "xã",
                        "short_codename": "che_tao"
                    },
                    {
                        "name": "Xã Púng Luông",
                        "code": 4492,
                        "codename": "xa_pung_luong",
                        "division_type": "xã",
                        "short_codename": "pung_luong"
                    },
                    {
                        "name": "Xã Nậm Khắt",
                        "code": 4495,
                        "codename": "xa_nam_khat",
                        "division_type": "xã",
                        "short_codename": "nam_khat"
                    }
                ]
            },
            {
                "name": "Huyện Trấn Yên",
                "code": 138,
                "codename": "huyen_tran_yen",
                "division_type": "huyện",
                "short_codename": "tran_yen",
                "wards": [
                    {
                        "name": "Thị trấn Cổ Phúc",
                        "code": 4498,
                        "codename": "thi_tran_co_phuc",
                        "division_type": "thị trấn",
                        "short_codename": "co_phuc"
                    },
                    {
                        "name": "Xã Tân Đồng",
                        "code": 4501,
                        "codename": "xa_tan_dong",
                        "division_type": "xã",
                        "short_codename": "tan_dong"
                    },
                    {
                        "name": "Xã Báo Đáp",
                        "code": 4504,
                        "codename": "xa_bao_dap",
                        "division_type": "xã",
                        "short_codename": "bao_dap"
                    },
                    {
                        "name": "Xã Đào Thịnh",
                        "code": 4507,
                        "codename": "xa_dao_thinh",
                        "division_type": "xã",
                        "short_codename": "dao_thinh"
                    },
                    {
                        "name": "Xã Việt Thành",
                        "code": 4510,
                        "codename": "xa_viet_thanh",
                        "division_type": "xã",
                        "short_codename": "viet_thanh"
                    },
                    {
                        "name": "Xã Hòa Cuông",
                        "code": 4513,
                        "codename": "xa_hoa_cuong",
                        "division_type": "xã",
                        "short_codename": "hoa_cuong"
                    },
                    {
                        "name": "Xã Minh Quán",
                        "code": 4516,
                        "codename": "xa_minh_quan",
                        "division_type": "xã",
                        "short_codename": "xa_minh_quan"
                    },
                    {
                        "name": "Xã Quy Mông",
                        "code": 4519,
                        "codename": "xa_quy_mong",
                        "division_type": "xã",
                        "short_codename": "quy_mong"
                    },
                    {
                        "name": "Xã Cường Thịnh",
                        "code": 4522,
                        "codename": "xa_cuong_thinh",
                        "division_type": "xã",
                        "short_codename": "cuong_thinh"
                    },
                    {
                        "name": "Xã Kiên Thành",
                        "code": 4525,
                        "codename": "xa_kien_thanh",
                        "division_type": "xã",
                        "short_codename": "kien_thanh"
                    },
                    {
                        "name": "Xã Nga Quán",
                        "code": 4528,
                        "codename": "xa_nga_quan",
                        "division_type": "xã",
                        "short_codename": "nga_quan"
                    },
                    {
                        "name": "Xã Y Can",
                        "code": 4531,
                        "codename": "xa_y_can",
                        "division_type": "xã",
                        "short_codename": "y_can"
                    },
                    {
                        "name": "Xã Lương Thịnh",
                        "code": 4537,
                        "codename": "xa_luong_thinh",
                        "division_type": "xã",
                        "short_codename": "luong_thinh"
                    },
                    {
                        "name": "Xã Bảo Hưng",
                        "code": 4561,
                        "codename": "xa_bao_hung",
                        "division_type": "xã",
                        "short_codename": "bao_hung"
                    },
                    {
                        "name": "Xã Việt Cường",
                        "code": 4564,
                        "codename": "xa_viet_cuong",
                        "division_type": "xã",
                        "short_codename": "viet_cuong"
                    },
                    {
                        "name": "Xã Minh Quân",
                        "code": 4567,
                        "codename": "xa_minh_quan",
                        "division_type": "xã",
                        "short_codename": "xa_minh_quan"
                    },
                    {
                        "name": "Xã Hồng Ca",
                        "code": 4570,
                        "codename": "xa_hong_ca",
                        "division_type": "xã",
                        "short_codename": "hong_ca"
                    },
                    {
                        "name": "Xã Hưng Thịnh",
                        "code": 4573,
                        "codename": "xa_hung_thinh",
                        "division_type": "xã",
                        "short_codename": "hung_thinh"
                    },
                    {
                        "name": "Xã Hưng Khánh",
                        "code": 4576,
                        "codename": "xa_hung_khanh",
                        "division_type": "xã",
                        "short_codename": "hung_khanh"
                    },
                    {
                        "name": "Xã Việt Hồng",
                        "code": 4579,
                        "codename": "xa_viet_hong",
                        "division_type": "xã",
                        "short_codename": "viet_hong"
                    },
                    {
                        "name": "Xã Vân Hội",
                        "code": 4582,
                        "codename": "xa_van_hoi",
                        "division_type": "xã",
                        "short_codename": "van_hoi"
                    }
                ]
            },
            {
                "name": "Huyện Trạm Tấu",
                "code": 139,
                "codename": "huyen_tram_tau",
                "division_type": "huyện",
                "short_codename": "tram_tau",
                "wards": [
                    {
                        "name": "Thị trấn Trạm Tấu",
                        "code": 4585,
                        "codename": "thi_tran_tram_tau",
                        "division_type": "thị trấn",
                        "short_codename": "tram_tau"
                    },
                    {
                        "name": "Xã Túc Đán",
                        "code": 4588,
                        "codename": "xa_tuc_dan",
                        "division_type": "xã",
                        "short_codename": "tuc_dan"
                    },
                    {
                        "name": "Xã Pá Lau",
                        "code": 4591,
                        "codename": "xa_pa_lau",
                        "division_type": "xã",
                        "short_codename": "pa_lau"
                    },
                    {
                        "name": "Xã Xà Hồ",
                        "code": 4594,
                        "codename": "xa_xa_ho",
                        "division_type": "xã",
                        "short_codename": "xa_ho"
                    },
                    {
                        "name": "Xã Phình Hồ",
                        "code": 4597,
                        "codename": "xa_phinh_ho",
                        "division_type": "xã",
                        "short_codename": "phinh_ho"
                    },
                    {
                        "name": "Xã Trạm Tấu",
                        "code": 4600,
                        "codename": "xa_tram_tau",
                        "division_type": "xã",
                        "short_codename": "xa_tram_tau"
                    },
                    {
                        "name": "Xã Tà Si Láng",
                        "code": 4603,
                        "codename": "xa_ta_si_lang",
                        "division_type": "xã",
                        "short_codename": "ta_si_lang"
                    },
                    {
                        "name": "Xã Pá Hu",
                        "code": 4606,
                        "codename": "xa_pa_hu",
                        "division_type": "xã",
                        "short_codename": "pa_hu"
                    },
                    {
                        "name": "Xã Làng Nhì",
                        "code": 4609,
                        "codename": "xa_lang_nhi",
                        "division_type": "xã",
                        "short_codename": "lang_nhi"
                    },
                    {
                        "name": "Xã Bản Công",
                        "code": 4612,
                        "codename": "xa_ban_cong",
                        "division_type": "xã",
                        "short_codename": "ban_cong"
                    },
                    {
                        "name": "Xã Bản Mù",
                        "code": 4615,
                        "codename": "xa_ban_mu",
                        "division_type": "xã",
                        "short_codename": "ban_mu"
                    },
                    {
                        "name": "Xã Hát Lìu",
                        "code": 4618,
                        "codename": "xa_hat_liu",
                        "division_type": "xã",
                        "short_codename": "hat_liu"
                    }
                ]
            },
            {
                "name": "Huyện Văn Chấn",
                "code": 140,
                "codename": "huyen_van_chan",
                "division_type": "huyện",
                "short_codename": "van_chan",
                "wards": [
                    {
                        "name": "Thị trấn NT Liên Sơn",
                        "code": 4621,
                        "codename": "thi_tran_nt_lien_son",
                        "division_type": "thị trấn",
                        "short_codename": "nt_lien_son"
                    },
                    {
                        "name": "Thị trấn NT Trần Phú",
                        "code": 4627,
                        "codename": "thi_tran_nt_tran_phu",
                        "division_type": "thị trấn",
                        "short_codename": "nt_tran_phu"
                    },
                    {
                        "name": "Xã Tú Lệ",
                        "code": 4630,
                        "codename": "xa_tu_le",
                        "division_type": "xã",
                        "short_codename": "tu_le"
                    },
                    {
                        "name": "Xã Nậm Búng",
                        "code": 4633,
                        "codename": "xa_nam_bung",
                        "division_type": "xã",
                        "short_codename": "nam_bung"
                    },
                    {
                        "name": "Xã Gia Hội",
                        "code": 4636,
                        "codename": "xa_gia_hoi",
                        "division_type": "xã",
                        "short_codename": "gia_hoi"
                    },
                    {
                        "name": "Xã Sùng Đô",
                        "code": 4639,
                        "codename": "xa_sung_do",
                        "division_type": "xã",
                        "short_codename": "sung_do"
                    },
                    {
                        "name": "Xã Nậm Mười",
                        "code": 4642,
                        "codename": "xa_nam_muoi",
                        "division_type": "xã",
                        "short_codename": "nam_muoi"
                    },
                    {
                        "name": "Xã An Lương",
                        "code": 4645,
                        "codename": "xa_an_luong",
                        "division_type": "xã",
                        "short_codename": "an_luong"
                    },
                    {
                        "name": "Xã Nậm Lành",
                        "code": 4648,
                        "codename": "xa_nam_lanh",
                        "division_type": "xã",
                        "short_codename": "nam_lanh"
                    },
                    {
                        "name": "Xã Sơn Lương",
                        "code": 4651,
                        "codename": "xa_son_luong",
                        "division_type": "xã",
                        "short_codename": "son_luong"
                    },
                    {
                        "name": "Xã Suối Quyền",
                        "code": 4654,
                        "codename": "xa_suoi_quyen",
                        "division_type": "xã",
                        "short_codename": "suoi_quyen"
                    },
                    {
                        "name": "Xã Suối Giàng",
                        "code": 4657,
                        "codename": "xa_suoi_giang",
                        "division_type": "xã",
                        "short_codename": "suoi_giang"
                    },
                    {
                        "name": "Xã Nghĩa Sơn",
                        "code": 4666,
                        "codename": "xa_nghia_son",
                        "division_type": "xã",
                        "short_codename": "nghia_son"
                    },
                    {
                        "name": "Xã Suối Bu",
                        "code": 4669,
                        "codename": "xa_suoi_bu",
                        "division_type": "xã",
                        "short_codename": "suoi_bu"
                    },
                    {
                        "name": "Thị trấn Sơn Thịnh",
                        "code": 4672,
                        "codename": "thi_tran_son_thinh",
                        "division_type": "thị trấn",
                        "short_codename": "son_thinh"
                    },
                    {
                        "name": "Xã Đại Lịch",
                        "code": 4687,
                        "codename": "xa_dai_lich",
                        "division_type": "xã",
                        "short_codename": "dai_lich"
                    },
                    {
                        "name": "Xã Đồng Khê",
                        "code": 4690,
                        "codename": "xa_dong_khe",
                        "division_type": "xã",
                        "short_codename": "dong_khe"
                    },
                    {
                        "name": "Xã Cát Thịnh",
                        "code": 4693,
                        "codename": "xa_cat_thinh",
                        "division_type": "xã",
                        "short_codename": "cat_thinh"
                    },
                    {
                        "name": "Xã Tân Thịnh",
                        "code": 4696,
                        "codename": "xa_tan_thinh",
                        "division_type": "xã",
                        "short_codename": "tan_thinh"
                    },
                    {
                        "name": "Xã Chấn Thịnh",
                        "code": 4699,
                        "codename": "xa_chan_thinh",
                        "division_type": "xã",
                        "short_codename": "chan_thinh"
                    },
                    {
                        "name": "Xã Bình Thuận",
                        "code": 4702,
                        "codename": "xa_binh_thuan",
                        "division_type": "xã",
                        "short_codename": "binh_thuan"
                    },
                    {
                        "name": "Xã Thượng Bằng La",
                        "code": 4705,
                        "codename": "xa_thuong_bang_la",
                        "division_type": "xã",
                        "short_codename": "thuong_bang_la"
                    },
                    {
                        "name": "Xã Minh An",
                        "code": 4708,
                        "codename": "xa_minh_an",
                        "division_type": "xã",
                        "short_codename": "minh_an"
                    },
                    {
                        "name": "Xã Nghĩa Tâm",
                        "code": 4711,
                        "codename": "xa_nghia_tam",
                        "division_type": "xã",
                        "short_codename": "nghia_tam"
                    }
                ]
            },
            {
                "name": "Huyện Yên Bình",
                "code": 141,
                "codename": "huyen_yen_binh",
                "division_type": "huyện",
                "short_codename": "yen_binh",
                "wards": [
                    {
                        "name": "Thị trấn Yên Bình",
                        "code": 4714,
                        "codename": "thi_tran_yen_binh",
                        "division_type": "thị trấn",
                        "short_codename": "yen_binh"
                    },
                    {
                        "name": "Thị trấn Thác Bà",
                        "code": 4717,
                        "codename": "thi_tran_thac_ba",
                        "division_type": "thị trấn",
                        "short_codename": "thac_ba"
                    },
                    {
                        "name": "Xã Xuân Long",
                        "code": 4720,
                        "codename": "xa_xuan_long",
                        "division_type": "xã",
                        "short_codename": "xuan_long"
                    },
                    {
                        "name": "Xã Cảm Nhân",
                        "code": 4726,
                        "codename": "xa_cam_nhan",
                        "division_type": "xã",
                        "short_codename": "cam_nhan"
                    },
                    {
                        "name": "Xã Ngọc Chấn",
                        "code": 4729,
                        "codename": "xa_ngoc_chan",
                        "division_type": "xã",
                        "short_codename": "ngoc_chan"
                    },
                    {
                        "name": "Xã Tân Nguyên",
                        "code": 4732,
                        "codename": "xa_tan_nguyen",
                        "division_type": "xã",
                        "short_codename": "tan_nguyen"
                    },
                    {
                        "name": "Xã Phúc Ninh",
                        "code": 4735,
                        "codename": "xa_phuc_ninh",
                        "division_type": "xã",
                        "short_codename": "phuc_ninh"
                    },
                    {
                        "name": "Xã Bảo Ái",
                        "code": 4738,
                        "codename": "xa_bao_ai",
                        "division_type": "xã",
                        "short_codename": "bao_ai"
                    },
                    {
                        "name": "Xã Mỹ Gia",
                        "code": 4741,
                        "codename": "xa_my_gia",
                        "division_type": "xã",
                        "short_codename": "my_gia"
                    },
                    {
                        "name": "Xã Xuân Lai",
                        "code": 4744,
                        "codename": "xa_xuan_lai",
                        "division_type": "xã",
                        "short_codename": "xuan_lai"
                    },
                    {
                        "name": "Xã Mông Sơn",
                        "code": 4747,
                        "codename": "xa_mong_son",
                        "division_type": "xã",
                        "short_codename": "mong_son"
                    },
                    {
                        "name": "Xã Cảm Ân",
                        "code": 4750,
                        "codename": "xa_cam_an",
                        "division_type": "xã",
                        "short_codename": "cam_an"
                    },
                    {
                        "name": "Xã Yên Thành",
                        "code": 4753,
                        "codename": "xa_yen_thanh",
                        "division_type": "xã",
                        "short_codename": "yen_thanh"
                    },
                    {
                        "name": "Xã Tân Hương",
                        "code": 4756,
                        "codename": "xa_tan_huong",
                        "division_type": "xã",
                        "short_codename": "tan_huong"
                    },
                    {
                        "name": "Xã Phúc An",
                        "code": 4759,
                        "codename": "xa_phuc_an",
                        "division_type": "xã",
                        "short_codename": "phuc_an"
                    },
                    {
                        "name": "Xã Bạch Hà",
                        "code": 4762,
                        "codename": "xa_bach_ha",
                        "division_type": "xã",
                        "short_codename": "bach_ha"
                    },
                    {
                        "name": "Xã Vũ Linh",
                        "code": 4765,
                        "codename": "xa_vu_linh",
                        "division_type": "xã",
                        "short_codename": "vu_linh"
                    },
                    {
                        "name": "Xã Đại Đồng",
                        "code": 4768,
                        "codename": "xa_dai_dong",
                        "division_type": "xã",
                        "short_codename": "dai_dong"
                    },
                    {
                        "name": "Xã Vĩnh Kiên",
                        "code": 4771,
                        "codename": "xa_vinh_kien",
                        "division_type": "xã",
                        "short_codename": "vinh_kien"
                    },
                    {
                        "name": "Xã Yên Bình",
                        "code": 4774,
                        "codename": "xa_yen_binh",
                        "division_type": "xã",
                        "short_codename": "xa_yen_binh"
                    },
                    {
                        "name": "Xã Thịnh Hưng",
                        "code": 4777,
                        "codename": "xa_thinh_hung",
                        "division_type": "xã",
                        "short_codename": "thinh_hung"
                    },
                    {
                        "name": "Xã Hán Đà",
                        "code": 4780,
                        "codename": "xa_han_da",
                        "division_type": "xã",
                        "short_codename": "han_da"
                    },
                    {
                        "name": "Xã Phú Thịnh",
                        "code": 4783,
                        "codename": "xa_phu_thinh",
                        "division_type": "xã",
                        "short_codename": "phu_thinh"
                    },
                    {
                        "name": "Xã Đại Minh",
                        "code": 4786,
                        "codename": "xa_dai_minh",
                        "division_type": "xã",
                        "short_codename": "dai_minh"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Hoà Bình",
        "code": 17,
        "codename": "tinh_hoa_binh",
        "division_type": "tỉnh",
        "phone_code": 218,
        "districts": [
            {
                "name": "Thành phố Hòa Bình",
                "code": 148,
                "codename": "thanh_pho_hoa_binh",
                "division_type": "thành phố",
                "short_codename": "hoa_binh",
                "wards": [
                    {
                        "name": "Phường Thái Bình",
                        "code": 4789,
                        "codename": "phuong_thai_binh",
                        "division_type": "phường",
                        "short_codename": "thai_binh"
                    },
                    {
                        "name": "Phường Tân Hòa",
                        "code": 4792,
                        "codename": "phuong_tan_hoa",
                        "division_type": "phường",
                        "short_codename": "tan_hoa"
                    },
                    {
                        "name": "Phường Thịnh Lang",
                        "code": 4795,
                        "codename": "phuong_thinh_lang",
                        "division_type": "phường",
                        "short_codename": "thinh_lang"
                    },
                    {
                        "name": "Phường Hữu Nghị",
                        "code": 4798,
                        "codename": "phuong_huu_nghi",
                        "division_type": "phường",
                        "short_codename": "huu_nghi"
                    },
                    {
                        "name": "Phường Tân Thịnh",
                        "code": 4801,
                        "codename": "phuong_tan_thinh",
                        "division_type": "phường",
                        "short_codename": "tan_thinh"
                    },
                    {
                        "name": "Phường Đồng Tiến",
                        "code": 4804,
                        "codename": "phuong_dong_tien",
                        "division_type": "phường",
                        "short_codename": "dong_tien"
                    },
                    {
                        "name": "Phường Phương Lâm",
                        "code": 4807,
                        "codename": "phuong_phuong_lam",
                        "division_type": "phường",
                        "short_codename": "phuong_lam"
                    },
                    {
                        "name": "Xã Yên Mông",
                        "code": 4813,
                        "codename": "xa_yen_mong",
                        "division_type": "xã",
                        "short_codename": "yen_mong"
                    },
                    {
                        "name": "Phường Quỳnh Lâm",
                        "code": 4816,
                        "codename": "phuong_quynh_lam",
                        "division_type": "phường",
                        "short_codename": "quynh_lam"
                    },
                    {
                        "name": "Phường Dân Chủ",
                        "code": 4819,
                        "codename": "phuong_dan_chu",
                        "division_type": "phường",
                        "short_codename": "dan_chu"
                    },
                    {
                        "name": "Xã Hòa Bình",
                        "code": 4825,
                        "codename": "xa_hoa_binh",
                        "division_type": "xã",
                        "short_codename": "hoa_binh"
                    },
                    {
                        "name": "Phường Thống Nhất",
                        "code": 4828,
                        "codename": "phuong_thong_nhat",
                        "division_type": "phường",
                        "short_codename": "thong_nhat"
                    },
                    {
                        "name": "Phường Kỳ Sơn",
                        "code": 4894,
                        "codename": "phuong_ky_son",
                        "division_type": "phường",
                        "short_codename": "ky_son"
                    },
                    {
                        "name": "Xã Thịnh Minh",
                        "code": 4897,
                        "codename": "xa_thinh_minh",
                        "division_type": "xã",
                        "short_codename": "thinh_minh"
                    },
                    {
                        "name": "Xã Hợp Thành",
                        "code": 4903,
                        "codename": "xa_hop_thanh",
                        "division_type": "xã",
                        "short_codename": "hop_thanh"
                    },
                    {
                        "name": "Xã Quang Tiến",
                        "code": 4906,
                        "codename": "xa_quang_tien",
                        "division_type": "xã",
                        "short_codename": "quang_tien"
                    },
                    {
                        "name": "Xã Mông Hóa",
                        "code": 4912,
                        "codename": "xa_mong_hoa",
                        "division_type": "xã",
                        "short_codename": "mong_hoa"
                    },
                    {
                        "name": "Phường Trung Minh",
                        "code": 4918,
                        "codename": "phuong_trung_minh",
                        "division_type": "phường",
                        "short_codename": "trung_minh"
                    },
                    {
                        "name": "Xã Độc Lập",
                        "code": 4921,
                        "codename": "xa_doc_lap",
                        "division_type": "xã",
                        "short_codename": "doc_lap"
                    }
                ]
            },
            {
                "name": "Huyện Đà Bắc",
                "code": 150,
                "codename": "huyen_da_bac",
                "division_type": "huyện",
                "short_codename": "da_bac",
                "wards": [
                    {
                        "name": "Thị trấn Đà Bắc",
                        "code": 4831,
                        "codename": "thi_tran_da_bac",
                        "division_type": "thị trấn",
                        "short_codename": "da_bac"
                    },
                    {
                        "name": "Xã Nánh Nghê",
                        "code": 4834,
                        "codename": "xa_nanh_nghe",
                        "division_type": "xã",
                        "short_codename": "nanh_nghe"
                    },
                    {
                        "name": "Xã Giáp Đắt",
                        "code": 4840,
                        "codename": "xa_giap_dat",
                        "division_type": "xã",
                        "short_codename": "giap_dat"
                    },
                    {
                        "name": "Xã Mường Chiềng",
                        "code": 4846,
                        "codename": "xa_muong_chieng",
                        "division_type": "xã",
                        "short_codename": "muong_chieng"
                    },
                    {
                        "name": "Xã Tân Pheo",
                        "code": 4849,
                        "codename": "xa_tan_pheo",
                        "division_type": "xã",
                        "short_codename": "tan_pheo"
                    },
                    {
                        "name": "Xã Đồng Chum",
                        "code": 4852,
                        "codename": "xa_dong_chum",
                        "division_type": "xã",
                        "short_codename": "dong_chum"
                    },
                    {
                        "name": "Xã Tân Minh",
                        "code": 4855,
                        "codename": "xa_tan_minh",
                        "division_type": "xã",
                        "short_codename": "tan_minh"
                    },
                    {
                        "name": "Xã Đoàn Kết",
                        "code": 4858,
                        "codename": "xa_doan_ket",
                        "division_type": "xã",
                        "short_codename": "doan_ket"
                    },
                    {
                        "name": "Xã Đồng Ruộng",
                        "code": 4861,
                        "codename": "xa_dong_ruong",
                        "division_type": "xã",
                        "short_codename": "dong_ruong"
                    },
                    {
                        "name": "Xã Tú Lý",
                        "code": 4867,
                        "codename": "xa_tu_ly",
                        "division_type": "xã",
                        "short_codename": "tu_ly"
                    },
                    {
                        "name": "Xã Trung Thành",
                        "code": 4870,
                        "codename": "xa_trung_thanh",
                        "division_type": "xã",
                        "short_codename": "trung_thanh"
                    },
                    {
                        "name": "Xã Yên Hòa",
                        "code": 4873,
                        "codename": "xa_yen_hoa",
                        "division_type": "xã",
                        "short_codename": "yen_hoa"
                    },
                    {
                        "name": "Xã Cao Sơn",
                        "code": 4876,
                        "codename": "xa_cao_son",
                        "division_type": "xã",
                        "short_codename": "cao_son"
                    },
                    {
                        "name": "Xã Toàn Sơn",
                        "code": 4879,
                        "codename": "xa_toan_son",
                        "division_type": "xã",
                        "short_codename": "toan_son"
                    },
                    {
                        "name": "Xã Hiền Lương",
                        "code": 4885,
                        "codename": "xa_hien_luong",
                        "division_type": "xã",
                        "short_codename": "hien_luong"
                    },
                    {
                        "name": "Xã Tiền Phong",
                        "code": 4888,
                        "codename": "xa_tien_phong",
                        "division_type": "xã",
                        "short_codename": "tien_phong"
                    },
                    {
                        "name": "Xã Vầy Nưa",
                        "code": 4891,
                        "codename": "xa_vay_nua",
                        "division_type": "xã",
                        "short_codename": "vay_nua"
                    }
                ]
            },
            {
                "name": "Huyện Lương Sơn",
                "code": 152,
                "codename": "huyen_luong_son",
                "division_type": "huyện",
                "short_codename": "luong_son",
                "wards": [
                    {
                        "name": "Thị trấn Lương Sơn",
                        "code": 4924,
                        "codename": "thi_tran_luong_son",
                        "division_type": "thị trấn",
                        "short_codename": "luong_son"
                    },
                    {
                        "name": "Xã Lâm Sơn",
                        "code": 4942,
                        "codename": "xa_lam_son",
                        "division_type": "xã",
                        "short_codename": "lam_son"
                    },
                    {
                        "name": "Xã Hòa Sơn",
                        "code": 4945,
                        "codename": "xa_hoa_son",
                        "division_type": "xã",
                        "short_codename": "hoa_son"
                    },
                    {
                        "name": "Xã Tân Vinh",
                        "code": 4951,
                        "codename": "xa_tan_vinh",
                        "division_type": "xã",
                        "short_codename": "tan_vinh"
                    },
                    {
                        "name": "Xã Nhuận Trạch",
                        "code": 4954,
                        "codename": "xa_nhuan_trach",
                        "division_type": "xã",
                        "short_codename": "nhuan_trach"
                    },
                    {
                        "name": "Xã Cao Sơn",
                        "code": 4957,
                        "codename": "xa_cao_son",
                        "division_type": "xã",
                        "short_codename": "cao_son"
                    },
                    {
                        "name": "Xã Cư Yên",
                        "code": 4960,
                        "codename": "xa_cu_yen",
                        "division_type": "xã",
                        "short_codename": "cu_yen"
                    },
                    {
                        "name": "Xã Liên Sơn",
                        "code": 4969,
                        "codename": "xa_lien_son",
                        "division_type": "xã",
                        "short_codename": "lien_son"
                    },
                    {
                        "name": "Xã Cao Dương",
                        "code": 5008,
                        "codename": "xa_cao_duong",
                        "division_type": "xã",
                        "short_codename": "cao_duong"
                    },
                    {
                        "name": "Xã Thanh Sơn",
                        "code": 5041,
                        "codename": "xa_thanh_son",
                        "division_type": "xã",
                        "short_codename": "thanh_son"
                    },
                    {
                        "name": "Xã Thanh Cao",
                        "code": 5047,
                        "codename": "xa_thanh_cao",
                        "division_type": "xã",
                        "short_codename": "thanh_cao"
                    }
                ]
            },
            {
                "name": "Huyện Kim Bôi",
                "code": 153,
                "codename": "huyen_kim_boi",
                "division_type": "huyện",
                "short_codename": "kim_boi",
                "wards": [
                    {
                        "name": "Thị trấn Bo",
                        "code": 4978,
                        "codename": "thi_tran_bo",
                        "division_type": "thị trấn",
                        "short_codename": "bo"
                    },
                    {
                        "name": "Xã Đú Sáng",
                        "code": 4984,
                        "codename": "xa_du_sang",
                        "division_type": "xã",
                        "short_codename": "du_sang"
                    },
                    {
                        "name": "Xã Hùng Sơn",
                        "code": 4987,
                        "codename": "xa_hung_son",
                        "division_type": "xã",
                        "short_codename": "hung_son"
                    },
                    {
                        "name": "Xã Bình Sơn",
                        "code": 4990,
                        "codename": "xa_binh_son",
                        "division_type": "xã",
                        "short_codename": "binh_son"
                    },
                    {
                        "name": "Xã Tú Sơn",
                        "code": 4999,
                        "codename": "xa_tu_son",
                        "division_type": "xã",
                        "short_codename": "tu_son"
                    },
                    {
                        "name": "Xã Vĩnh Tiến",
                        "code": 5005,
                        "codename": "xa_vinh_tien",
                        "division_type": "xã",
                        "short_codename": "vinh_tien"
                    },
                    {
                        "name": "Xã Đông Bắc",
                        "code": 5014,
                        "codename": "xa_dong_bac",
                        "division_type": "xã",
                        "short_codename": "dong_bac"
                    },
                    {
                        "name": "Xã Xuân Thủy",
                        "code": 5017,
                        "codename": "xa_xuan_thuy",
                        "division_type": "xã",
                        "short_codename": "xuan_thuy"
                    },
                    {
                        "name": "Xã Vĩnh Đồng",
                        "code": 5026,
                        "codename": "xa_vinh_dong",
                        "division_type": "xã",
                        "short_codename": "vinh_dong"
                    },
                    {
                        "name": "Xã Kim Lập",
                        "code": 5035,
                        "codename": "xa_kim_lap",
                        "division_type": "xã",
                        "short_codename": "kim_lap"
                    },
                    {
                        "name": "Xã Hợp Tiến",
                        "code": 5038,
                        "codename": "xa_hop_tien",
                        "division_type": "xã",
                        "short_codename": "hop_tien"
                    },
                    {
                        "name": "Xã Kim Bôi",
                        "code": 5065,
                        "codename": "xa_kim_boi",
                        "division_type": "xã",
                        "short_codename": "kim_boi"
                    },
                    {
                        "name": "Xã Nam Thượng",
                        "code": 5068,
                        "codename": "xa_nam_thuong",
                        "division_type": "xã",
                        "short_codename": "nam_thuong"
                    },
                    {
                        "name": "Xã Cuối Hạ",
                        "code": 5077,
                        "codename": "xa_cuoi_ha",
                        "division_type": "xã",
                        "short_codename": "cuoi_ha"
                    },
                    {
                        "name": "Xã Sào Báy",
                        "code": 5080,
                        "codename": "xa_sao_bay",
                        "division_type": "xã",
                        "short_codename": "sao_bay"
                    },
                    {
                        "name": "Xã Mi Hòa",
                        "code": 5083,
                        "codename": "xa_mi_hoa",
                        "division_type": "xã",
                        "short_codename": "mi_hoa"
                    },
                    {
                        "name": "Xã Nuông Dăm",
                        "code": 5086,
                        "codename": "xa_nuong_dam",
                        "division_type": "xã",
                        "short_codename": "nuong_dam"
                    }
                ]
            },
            {
                "name": "Huyện Cao Phong",
                "code": 154,
                "codename": "huyen_cao_phong",
                "division_type": "huyện",
                "short_codename": "cao_phong",
                "wards": [
                    {
                        "name": "Thị trấn Cao Phong",
                        "code": 5089,
                        "codename": "thi_tran_cao_phong",
                        "division_type": "thị trấn",
                        "short_codename": "cao_phong"
                    },
                    {
                        "name": "Xã Bình Thanh",
                        "code": 5092,
                        "codename": "xa_binh_thanh",
                        "division_type": "xã",
                        "short_codename": "binh_thanh"
                    },
                    {
                        "name": "Xã Thung Nai",
                        "code": 5095,
                        "codename": "xa_thung_nai",
                        "division_type": "xã",
                        "short_codename": "thung_nai"
                    },
                    {
                        "name": "Xã Bắc Phong",
                        "code": 5098,
                        "codename": "xa_bac_phong",
                        "division_type": "xã",
                        "short_codename": "bac_phong"
                    },
                    {
                        "name": "Xã Thu Phong",
                        "code": 5101,
                        "codename": "xa_thu_phong",
                        "division_type": "xã",
                        "short_codename": "thu_phong"
                    },
                    {
                        "name": "Xã Hợp Phong",
                        "code": 5104,
                        "codename": "xa_hop_phong",
                        "division_type": "xã",
                        "short_codename": "hop_phong"
                    },
                    {
                        "name": "Xã Tây Phong",
                        "code": 5110,
                        "codename": "xa_tay_phong",
                        "division_type": "xã",
                        "short_codename": "tay_phong"
                    },
                    {
                        "name": "Xã Dũng Phong",
                        "code": 5116,
                        "codename": "xa_dung_phong",
                        "division_type": "xã",
                        "short_codename": "dung_phong"
                    },
                    {
                        "name": "Xã Nam Phong",
                        "code": 5119,
                        "codename": "xa_nam_phong",
                        "division_type": "xã",
                        "short_codename": "nam_phong"
                    },
                    {
                        "name": "Xã Thạch Yên",
                        "code": 5125,
                        "codename": "xa_thach_yen",
                        "division_type": "xã",
                        "short_codename": "thach_yen"
                    }
                ]
            },
            {
                "name": "Huyện Tân Lạc",
                "code": 155,
                "codename": "huyen_tan_lac",
                "division_type": "huyện",
                "short_codename": "tan_lac",
                "wards": [
                    {
                        "name": "Thị trấn Mãn Đức",
                        "code": 5128,
                        "codename": "thi_tran_man_duc",
                        "division_type": "thị trấn",
                        "short_codename": "man_duc"
                    },
                    {
                        "name": "Xã Suối Hoa",
                        "code": 5134,
                        "codename": "xa_suoi_hoa",
                        "division_type": "xã",
                        "short_codename": "suoi_hoa"
                    },
                    {
                        "name": "Xã Phú Vinh",
                        "code": 5137,
                        "codename": "xa_phu_vinh",
                        "division_type": "xã",
                        "short_codename": "phu_vinh"
                    },
                    {
                        "name": "Xã Phú Cường",
                        "code": 5140,
                        "codename": "xa_phu_cuong",
                        "division_type": "xã",
                        "short_codename": "phu_cuong"
                    },
                    {
                        "name": "Xã Mỹ Hòa",
                        "code": 5143,
                        "codename": "xa_my_hoa",
                        "division_type": "xã",
                        "short_codename": "my_hoa"
                    },
                    {
                        "name": "Xã Quyết Chiến",
                        "code": 5152,
                        "codename": "xa_quyet_chien",
                        "division_type": "xã",
                        "short_codename": "quyet_chien"
                    },
                    {
                        "name": "Xã Phong Phú",
                        "code": 5158,
                        "codename": "xa_phong_phu",
                        "division_type": "xã",
                        "short_codename": "phong_phu"
                    },
                    {
                        "name": "Xã Tử Nê",
                        "code": 5164,
                        "codename": "xa_tu_ne",
                        "division_type": "xã",
                        "short_codename": "tu_ne"
                    },
                    {
                        "name": "Xã Thanh Hối",
                        "code": 5167,
                        "codename": "xa_thanh_hoi",
                        "division_type": "xã",
                        "short_codename": "thanh_hoi"
                    },
                    {
                        "name": "Xã Ngọc Mỹ",
                        "code": 5170,
                        "codename": "xa_ngoc_my",
                        "division_type": "xã",
                        "short_codename": "ngoc_my"
                    },
                    {
                        "name": "Xã Đông Lai",
                        "code": 5173,
                        "codename": "xa_dong_lai",
                        "division_type": "xã",
                        "short_codename": "dong_lai"
                    },
                    {
                        "name": "Xã Vân Sơn",
                        "code": 5176,
                        "codename": "xa_van_son",
                        "division_type": "xã",
                        "short_codename": "van_son"
                    },
                    {
                        "name": "Xã Nhân Mỹ",
                        "code": 5182,
                        "codename": "xa_nhan_my",
                        "division_type": "xã",
                        "short_codename": "nhan_my"
                    },
                    {
                        "name": "Xã Lỗ Sơn",
                        "code": 5191,
                        "codename": "xa_lo_son",
                        "division_type": "xã",
                        "short_codename": "lo_son"
                    },
                    {
                        "name": "Xã Ngổ Luông",
                        "code": 5194,
                        "codename": "xa_ngo_luong",
                        "division_type": "xã",
                        "short_codename": "ngo_luong"
                    },
                    {
                        "name": "Xã Gia Mô",
                        "code": 5197,
                        "codename": "xa_gia_mo",
                        "division_type": "xã",
                        "short_codename": "gia_mo"
                    }
                ]
            },
            {
                "name": "Huyện Mai Châu",
                "code": 156,
                "codename": "huyen_mai_chau",
                "division_type": "huyện",
                "short_codename": "mai_chau",
                "wards": [
                    {
                        "name": "Xã Tân Thành",
                        "code": 4882,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Thị trấn Mai Châu",
                        "code": 5200,
                        "codename": "thi_tran_mai_chau",
                        "division_type": "thị trấn",
                        "short_codename": "mai_chau"
                    },
                    {
                        "name": "Xã Sơn Thủy",
                        "code": 5206,
                        "codename": "xa_son_thuy",
                        "division_type": "xã",
                        "short_codename": "son_thuy"
                    },
                    {
                        "name": "Xã Pà Cò",
                        "code": 5209,
                        "codename": "xa_pa_co",
                        "division_type": "xã",
                        "short_codename": "pa_co"
                    },
                    {
                        "name": "Xã Hang Kia",
                        "code": 5212,
                        "codename": "xa_hang_kia",
                        "division_type": "xã",
                        "short_codename": "hang_kia"
                    },
                    {
                        "name": "Xã Đồng Tân",
                        "code": 5221,
                        "codename": "xa_dong_tan",
                        "division_type": "xã",
                        "short_codename": "dong_tan"
                    },
                    {
                        "name": "Xã Cun Pheo",
                        "code": 5224,
                        "codename": "xa_cun_pheo",
                        "division_type": "xã",
                        "short_codename": "cun_pheo"
                    },
                    {
                        "name": "Xã Bao La",
                        "code": 5227,
                        "codename": "xa_bao_la",
                        "division_type": "xã",
                        "short_codename": "bao_la"
                    },
                    {
                        "name": "Xã Tòng Đậu",
                        "code": 5233,
                        "codename": "xa_tong_dau",
                        "division_type": "xã",
                        "short_codename": "tong_dau"
                    },
                    {
                        "name": "Xã Nà Phòn",
                        "code": 5242,
                        "codename": "xa_na_phon",
                        "division_type": "xã",
                        "short_codename": "na_phon"
                    },
                    {
                        "name": "Xã Săm Khóe",
                        "code": 5245,
                        "codename": "xa_sam_khoe",
                        "division_type": "xã",
                        "short_codename": "sam_khoe"
                    },
                    {
                        "name": "Xã Chiềng Châu",
                        "code": 5248,
                        "codename": "xa_chieng_chau",
                        "division_type": "xã",
                        "short_codename": "chieng_chau"
                    },
                    {
                        "name": "Xã Mai Hạ",
                        "code": 5251,
                        "codename": "xa_mai_ha",
                        "division_type": "xã",
                        "short_codename": "mai_ha"
                    },
                    {
                        "name": "Xã Thành Sơn",
                        "code": 5254,
                        "codename": "xa_thanh_son",
                        "division_type": "xã",
                        "short_codename": "thanh_son"
                    },
                    {
                        "name": "Xã Mai Hịch",
                        "code": 5257,
                        "codename": "xa_mai_hich",
                        "division_type": "xã",
                        "short_codename": "mai_hich"
                    },
                    {
                        "name": "Xã Vạn Mai",
                        "code": 5263,
                        "codename": "xa_van_mai",
                        "division_type": "xã",
                        "short_codename": "van_mai"
                    }
                ]
            },
            {
                "name": "Huyện Lạc Sơn",
                "code": 157,
                "codename": "huyen_lac_son",
                "division_type": "huyện",
                "short_codename": "lac_son",
                "wards": [
                    {
                        "name": "Thị trấn Vụ Bản",
                        "code": 5266,
                        "codename": "thi_tran_vu_ban",
                        "division_type": "thị trấn",
                        "short_codename": "vu_ban"
                    },
                    {
                        "name": "Xã Quý Hòa",
                        "code": 5269,
                        "codename": "xa_quy_hoa",
                        "division_type": "xã",
                        "short_codename": "quy_hoa"
                    },
                    {
                        "name": "Xã Miền Đồi",
                        "code": 5272,
                        "codename": "xa_mien_doi",
                        "division_type": "xã",
                        "short_codename": "mien_doi"
                    },
                    {
                        "name": "Xã Mỹ Thành",
                        "code": 5275,
                        "codename": "xa_my_thanh",
                        "division_type": "xã",
                        "short_codename": "my_thanh"
                    },
                    {
                        "name": "Xã Tuân Đạo",
                        "code": 5278,
                        "codename": "xa_tuan_dao",
                        "division_type": "xã",
                        "short_codename": "tuan_dao"
                    },
                    {
                        "name": "Xã Văn Nghĩa",
                        "code": 5281,
                        "codename": "xa_van_nghia",
                        "division_type": "xã",
                        "short_codename": "van_nghia"
                    },
                    {
                        "name": "Xã Văn Sơn",
                        "code": 5284,
                        "codename": "xa_van_son",
                        "division_type": "xã",
                        "short_codename": "van_son"
                    },
                    {
                        "name": "Xã Tân Lập",
                        "code": 5287,
                        "codename": "xa_tan_lap",
                        "division_type": "xã",
                        "short_codename": "tan_lap"
                    },
                    {
                        "name": "Xã Nhân Nghĩa",
                        "code": 5290,
                        "codename": "xa_nhan_nghia",
                        "division_type": "xã",
                        "short_codename": "nhan_nghia"
                    },
                    {
                        "name": "Xã Thượng Cốc",
                        "code": 5293,
                        "codename": "xa_thuong_coc",
                        "division_type": "xã",
                        "short_codename": "thuong_coc"
                    },
                    {
                        "name": "Xã Quyết Thắng",
                        "code": 5299,
                        "codename": "xa_quyet_thang",
                        "division_type": "xã",
                        "short_codename": "quyet_thang"
                    },
                    {
                        "name": "Xã Xuất Hóa",
                        "code": 5302,
                        "codename": "xa_xuat_hoa",
                        "division_type": "xã",
                        "short_codename": "xuat_hoa"
                    },
                    {
                        "name": "Xã Yên Phú",
                        "code": 5305,
                        "codename": "xa_yen_phu",
                        "division_type": "xã",
                        "short_codename": "yen_phu"
                    },
                    {
                        "name": "Xã Bình Hẻm",
                        "code": 5308,
                        "codename": "xa_binh_hem",
                        "division_type": "xã",
                        "short_codename": "binh_hem"
                    },
                    {
                        "name": "Xã Định Cư",
                        "code": 5320,
                        "codename": "xa_dinh_cu",
                        "division_type": "xã",
                        "short_codename": "dinh_cu"
                    },
                    {
                        "name": "Xã Chí Đạo",
                        "code": 5323,
                        "codename": "xa_chi_dao",
                        "division_type": "xã",
                        "short_codename": "chi_dao"
                    },
                    {
                        "name": "Xã Ngọc Sơn",
                        "code": 5329,
                        "codename": "xa_ngoc_son",
                        "division_type": "xã",
                        "short_codename": "ngoc_son"
                    },
                    {
                        "name": "Xã Hương Nhượng",
                        "code": 5332,
                        "codename": "xa_huong_nhuong",
                        "division_type": "xã",
                        "short_codename": "huong_nhuong"
                    },
                    {
                        "name": "Xã Vũ Bình",
                        "code": 5335,
                        "codename": "xa_vu_binh",
                        "division_type": "xã",
                        "short_codename": "vu_binh"
                    },
                    {
                        "name": "Xã Tự Do",
                        "code": 5338,
                        "codename": "xa_tu_do",
                        "division_type": "xã",
                        "short_codename": "tu_do"
                    },
                    {
                        "name": "Xã Yên Nghiệp",
                        "code": 5341,
                        "codename": "xa_yen_nghiep",
                        "division_type": "xã",
                        "short_codename": "yen_nghiep"
                    },
                    {
                        "name": "Xã Tân Mỹ",
                        "code": 5344,
                        "codename": "xa_tan_my",
                        "division_type": "xã",
                        "short_codename": "tan_my"
                    },
                    {
                        "name": "Xã Ân Nghĩa",
                        "code": 5347,
                        "codename": "xa_an_nghia",
                        "division_type": "xã",
                        "short_codename": "an_nghia"
                    },
                    {
                        "name": "Xã Ngọc Lâu",
                        "code": 5350,
                        "codename": "xa_ngoc_lau",
                        "division_type": "xã",
                        "short_codename": "ngoc_lau"
                    }
                ]
            },
            {
                "name": "Huyện Yên Thủy",
                "code": 158,
                "codename": "huyen_yen_thuy",
                "division_type": "huyện",
                "short_codename": "yen_thuy",
                "wards": [
                    {
                        "name": "Thị trấn Hàng Trạm",
                        "code": 5353,
                        "codename": "thi_tran_hang_tram",
                        "division_type": "thị trấn",
                        "short_codename": "hang_tram"
                    },
                    {
                        "name": "Xã Lạc Sỹ",
                        "code": 5356,
                        "codename": "xa_lac_sy",
                        "division_type": "xã",
                        "short_codename": "lac_sy"
                    },
                    {
                        "name": "Xã Lạc Lương",
                        "code": 5362,
                        "codename": "xa_lac_luong",
                        "division_type": "xã",
                        "short_codename": "lac_luong"
                    },
                    {
                        "name": "Xã Bảo Hiệu",
                        "code": 5365,
                        "codename": "xa_bao_hieu",
                        "division_type": "xã",
                        "short_codename": "bao_hieu"
                    },
                    {
                        "name": "Xã Đa Phúc",
                        "code": 5368,
                        "codename": "xa_da_phuc",
                        "division_type": "xã",
                        "short_codename": "da_phuc"
                    },
                    {
                        "name": "Xã Hữu Lợi",
                        "code": 5371,
                        "codename": "xa_huu_loi",
                        "division_type": "xã",
                        "short_codename": "huu_loi"
                    },
                    {
                        "name": "Xã Lạc Thịnh",
                        "code": 5374,
                        "codename": "xa_lac_thinh",
                        "division_type": "xã",
                        "short_codename": "lac_thinh"
                    },
                    {
                        "name": "Xã Đoàn Kết",
                        "code": 5380,
                        "codename": "xa_doan_ket",
                        "division_type": "xã",
                        "short_codename": "doan_ket"
                    },
                    {
                        "name": "Xã Phú Lai",
                        "code": 5383,
                        "codename": "xa_phu_lai",
                        "division_type": "xã",
                        "short_codename": "phu_lai"
                    },
                    {
                        "name": "Xã Yên Trị",
                        "code": 5386,
                        "codename": "xa_yen_tri",
                        "division_type": "xã",
                        "short_codename": "yen_tri"
                    },
                    {
                        "name": "Xã Ngọc Lương",
                        "code": 5389,
                        "codename": "xa_ngoc_luong",
                        "division_type": "xã",
                        "short_codename": "ngoc_luong"
                    }
                ]
            },
            {
                "name": "Huyện Lạc Thủy",
                "code": 159,
                "codename": "huyen_lac_thuy",
                "division_type": "huyện",
                "short_codename": "lac_thuy",
                "wards": [
                    {
                        "name": "Thị trấn Ba Hàng Đồi",
                        "code": 4981,
                        "codename": "thi_tran_ba_hang_doi",
                        "division_type": "thị trấn",
                        "short_codename": "ba_hang_doi"
                    },
                    {
                        "name": "Thị trấn Chi Nê",
                        "code": 5392,
                        "codename": "thi_tran_chi_ne",
                        "division_type": "thị trấn",
                        "short_codename": "chi_ne"
                    },
                    {
                        "name": "Xã Phú Nghĩa",
                        "code": 5395,
                        "codename": "xa_phu_nghia",
                        "division_type": "xã",
                        "short_codename": "phu_nghia"
                    },
                    {
                        "name": "Xã Phú Thành",
                        "code": 5398,
                        "codename": "xa_phu_thanh",
                        "division_type": "xã",
                        "short_codename": "phu_thanh"
                    },
                    {
                        "name": "Xã Hưng Thi",
                        "code": 5404,
                        "codename": "xa_hung_thi",
                        "division_type": "xã",
                        "short_codename": "hung_thi"
                    },
                    {
                        "name": "Xã Khoan Dụ",
                        "code": 5413,
                        "codename": "xa_khoan_du",
                        "division_type": "xã",
                        "short_codename": "khoan_du"
                    },
                    {
                        "name": "Xã Đồng Tâm",
                        "code": 5419,
                        "codename": "xa_dong_tam",
                        "division_type": "xã",
                        "short_codename": "dong_tam"
                    },
                    {
                        "name": "Xã Yên Bồng",
                        "code": 5422,
                        "codename": "xa_yen_bong",
                        "division_type": "xã",
                        "short_codename": "yen_bong"
                    },
                    {
                        "name": "Xã Thống Nhất",
                        "code": 5425,
                        "codename": "xa_thong_nhat",
                        "division_type": "xã",
                        "short_codename": "thong_nhat"
                    },
                    {
                        "name": "Xã An Bình",
                        "code": 5428,
                        "codename": "xa_an_binh",
                        "division_type": "xã",
                        "short_codename": "an_binh"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Thái Nguyên",
        "code": 19,
        "codename": "tinh_thai_nguyen",
        "division_type": "tỉnh",
        "phone_code": 208,
        "districts": [
            {
                "name": "Thành phố Thái Nguyên",
                "code": 164,
                "codename": "thanh_pho_thai_nguyen",
                "division_type": "thành phố",
                "short_codename": "thai_nguyen",
                "wards": [
                    {
                        "name": "Phường Quán Triều",
                        "code": 5431,
                        "codename": "phuong_quan_trieu",
                        "division_type": "phường",
                        "short_codename": "quan_trieu"
                    },
                    {
                        "name": "Phường Quang Vinh",
                        "code": 5434,
                        "codename": "phuong_quang_vinh",
                        "division_type": "phường",
                        "short_codename": "quang_vinh"
                    },
                    {
                        "name": "Phường Túc Duyên",
                        "code": 5437,
                        "codename": "phuong_tuc_duyen",
                        "division_type": "phường",
                        "short_codename": "tuc_duyen"
                    },
                    {
                        "name": "Phường Hoàng Văn Thụ",
                        "code": 5440,
                        "codename": "phuong_hoang_van_thu",
                        "division_type": "phường",
                        "short_codename": "hoang_van_thu"
                    },
                    {
                        "name": "Phường Trưng Vương",
                        "code": 5443,
                        "codename": "phuong_trung_vuong",
                        "division_type": "phường",
                        "short_codename": "trung_vuong"
                    },
                    {
                        "name": "Phường Quang Trung",
                        "code": 5446,
                        "codename": "phuong_quang_trung",
                        "division_type": "phường",
                        "short_codename": "quang_trung"
                    },
                    {
                        "name": "Phường Phan Đình Phùng",
                        "code": 5449,
                        "codename": "phuong_phan_dinh_phung",
                        "division_type": "phường",
                        "short_codename": "phan_dinh_phung"
                    },
                    {
                        "name": "Phường Tân Thịnh",
                        "code": 5452,
                        "codename": "phuong_tan_thinh",
                        "division_type": "phường",
                        "short_codename": "tan_thinh"
                    },
                    {
                        "name": "Phường Thịnh Đán",
                        "code": 5455,
                        "codename": "phuong_thinh_dan",
                        "division_type": "phường",
                        "short_codename": "thinh_dan"
                    },
                    {
                        "name": "Phường Đồng Quang",
                        "code": 5458,
                        "codename": "phuong_dong_quang",
                        "division_type": "phường",
                        "short_codename": "dong_quang"
                    },
                    {
                        "name": "Phường Gia Sàng",
                        "code": 5461,
                        "codename": "phuong_gia_sang",
                        "division_type": "phường",
                        "short_codename": "gia_sang"
                    },
                    {
                        "name": "Phường Tân Lập",
                        "code": 5464,
                        "codename": "phuong_tan_lap",
                        "division_type": "phường",
                        "short_codename": "tan_lap"
                    },
                    {
                        "name": "Phường Cam Giá",
                        "code": 5467,
                        "codename": "phuong_cam_gia",
                        "division_type": "phường",
                        "short_codename": "cam_gia"
                    },
                    {
                        "name": "Phường Phú Xá",
                        "code": 5470,
                        "codename": "phuong_phu_xa",
                        "division_type": "phường",
                        "short_codename": "phu_xa"
                    },
                    {
                        "name": "Phường Hương Sơn",
                        "code": 5473,
                        "codename": "phuong_huong_son",
                        "division_type": "phường",
                        "short_codename": "huong_son"
                    },
                    {
                        "name": "Phường Trung Thành",
                        "code": 5476,
                        "codename": "phuong_trung_thanh",
                        "division_type": "phường",
                        "short_codename": "trung_thanh"
                    },
                    {
                        "name": "Phường Tân Thành",
                        "code": 5479,
                        "codename": "phuong_tan_thanh",
                        "division_type": "phường",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Phường Tân Long",
                        "code": 5482,
                        "codename": "phuong_tan_long",
                        "division_type": "phường",
                        "short_codename": "tan_long"
                    },
                    {
                        "name": "Xã Phúc Hà",
                        "code": 5485,
                        "codename": "xa_phuc_ha",
                        "division_type": "xã",
                        "short_codename": "phuc_ha"
                    },
                    {
                        "name": "Xã Phúc Xuân",
                        "code": 5488,
                        "codename": "xa_phuc_xuan",
                        "division_type": "xã",
                        "short_codename": "phuc_xuan"
                    },
                    {
                        "name": "Xã Quyết Thắng",
                        "code": 5491,
                        "codename": "xa_quyet_thang",
                        "division_type": "xã",
                        "short_codename": "quyet_thang"
                    },
                    {
                        "name": "Xã Phúc Trìu",
                        "code": 5494,
                        "codename": "xa_phuc_triu",
                        "division_type": "xã",
                        "short_codename": "phuc_triu"
                    },
                    {
                        "name": "Xã Thịnh Đức",
                        "code": 5497,
                        "codename": "xa_thinh_duc",
                        "division_type": "xã",
                        "short_codename": "thinh_duc"
                    },
                    {
                        "name": "Phường Tích Lương",
                        "code": 5500,
                        "codename": "phuong_tich_luong",
                        "division_type": "phường",
                        "short_codename": "tich_luong"
                    },
                    {
                        "name": "Xã Tân Cương",
                        "code": 5503,
                        "codename": "xa_tan_cuong",
                        "division_type": "xã",
                        "short_codename": "tan_cuong"
                    },
                    {
                        "name": "Xã Sơn Cẩm",
                        "code": 5653,
                        "codename": "xa_son_cam",
                        "division_type": "xã",
                        "short_codename": "son_cam"
                    },
                    {
                        "name": "Phường Chùa Hang",
                        "code": 5659,
                        "codename": "phuong_chua_hang",
                        "division_type": "phường",
                        "short_codename": "chua_hang"
                    },
                    {
                        "name": "Xã Cao Ngạn",
                        "code": 5695,
                        "codename": "xa_cao_ngan",
                        "division_type": "xã",
                        "short_codename": "cao_ngan"
                    },
                    {
                        "name": "Xã Linh Sơn",
                        "code": 5701,
                        "codename": "xa_linh_son",
                        "division_type": "xã",
                        "short_codename": "linh_son"
                    },
                    {
                        "name": "Phường Đồng Bẩm",
                        "code": 5710,
                        "codename": "phuong_dong_bam",
                        "division_type": "phường",
                        "short_codename": "dong_bam"
                    },
                    {
                        "name": "Xã Huống Thượng",
                        "code": 5713,
                        "codename": "xa_huong_thuong",
                        "division_type": "xã",
                        "short_codename": "huong_thuong"
                    },
                    {
                        "name": "Xã Đồng Liên",
                        "code": 5914,
                        "codename": "xa_dong_lien",
                        "division_type": "xã",
                        "short_codename": "dong_lien"
                    }
                ]
            },
            {
                "name": "Thành phố Sông Công",
                "code": 165,
                "codename": "thanh_pho_song_cong",
                "division_type": "thành phố",
                "short_codename": "song_cong",
                "wards": [
                    {
                        "name": "Phường Lương Sơn",
                        "code": 5506,
                        "codename": "phuong_luong_son",
                        "division_type": "phường",
                        "short_codename": "luong_son"
                    },
                    {
                        "name": "Phường Châu Sơn",
                        "code": 5509,
                        "codename": "phuong_chau_son",
                        "division_type": "phường",
                        "short_codename": "chau_son"
                    },
                    {
                        "name": "Phường Mỏ Chè",
                        "code": 5512,
                        "codename": "phuong_mo_che",
                        "division_type": "phường",
                        "short_codename": "mo_che"
                    },
                    {
                        "name": "Phường Cải Đan",
                        "code": 5515,
                        "codename": "phuong_cai_dan",
                        "division_type": "phường",
                        "short_codename": "cai_dan"
                    },
                    {
                        "name": "Phường Thắng Lợi",
                        "code": 5518,
                        "codename": "phuong_thang_loi",
                        "division_type": "phường",
                        "short_codename": "thang_loi"
                    },
                    {
                        "name": "Phường Phố Cò",
                        "code": 5521,
                        "codename": "phuong_pho_co",
                        "division_type": "phường",
                        "short_codename": "pho_co"
                    },
                    {
                        "name": "Xã Tân Quang",
                        "code": 5527,
                        "codename": "xa_tan_quang",
                        "division_type": "xã",
                        "short_codename": "tan_quang"
                    },
                    {
                        "name": "Phường Bách Quang",
                        "code": 5528,
                        "codename": "phuong_bach_quang",
                        "division_type": "phường",
                        "short_codename": "bach_quang"
                    },
                    {
                        "name": "Xã Bình Sơn",
                        "code": 5530,
                        "codename": "xa_binh_son",
                        "division_type": "xã",
                        "short_codename": "binh_son"
                    },
                    {
                        "name": "Xã Bá Xuyên",
                        "code": 5533,
                        "codename": "xa_ba_xuyen",
                        "division_type": "xã",
                        "short_codename": "ba_xuyen"
                    }
                ]
            },
            {
                "name": "Huyện Định Hóa",
                "code": 167,
                "codename": "huyen_dinh_hoa",
                "division_type": "huyện",
                "short_codename": "dinh_hoa",
                "wards": [
                    {
                        "name": "Thị trấn Chợ Chu",
                        "code": 5536,
                        "codename": "thi_tran_cho_chu",
                        "division_type": "thị trấn",
                        "short_codename": "cho_chu"
                    },
                    {
                        "name": "Xã Linh Thông",
                        "code": 5539,
                        "codename": "xa_linh_thong",
                        "division_type": "xã",
                        "short_codename": "linh_thong"
                    },
                    {
                        "name": "Xã Lam Vỹ",
                        "code": 5542,
                        "codename": "xa_lam_vy",
                        "division_type": "xã",
                        "short_codename": "lam_vy"
                    },
                    {
                        "name": "Xã Quy Kỳ",
                        "code": 5545,
                        "codename": "xa_quy_ky",
                        "division_type": "xã",
                        "short_codename": "quy_ky"
                    },
                    {
                        "name": "Xã Tân Thịnh",
                        "code": 5548,
                        "codename": "xa_tan_thinh",
                        "division_type": "xã",
                        "short_codename": "tan_thinh"
                    },
                    {
                        "name": "Xã Kim Phượng",
                        "code": 5551,
                        "codename": "xa_kim_phuong",
                        "division_type": "xã",
                        "short_codename": "kim_phuong"
                    },
                    {
                        "name": "Xã Bảo Linh",
                        "code": 5554,
                        "codename": "xa_bao_linh",
                        "division_type": "xã",
                        "short_codename": "bao_linh"
                    },
                    {
                        "name": "Xã Phúc Chu",
                        "code": 5560,
                        "codename": "xa_phuc_chu",
                        "division_type": "xã",
                        "short_codename": "phuc_chu"
                    },
                    {
                        "name": "Xã Tân Dương",
                        "code": 5563,
                        "codename": "xa_tan_duong",
                        "division_type": "xã",
                        "short_codename": "tan_duong"
                    },
                    {
                        "name": "Xã Phượng Tiến",
                        "code": 5566,
                        "codename": "xa_phuong_tien",
                        "division_type": "xã",
                        "short_codename": "phuong_tien"
                    },
                    {
                        "name": "Xã Bảo Cường",
                        "code": 5569,
                        "codename": "xa_bao_cuong",
                        "division_type": "xã",
                        "short_codename": "bao_cuong"
                    },
                    {
                        "name": "Xã Đồng Thịnh",
                        "code": 5572,
                        "codename": "xa_dong_thinh",
                        "division_type": "xã",
                        "short_codename": "dong_thinh"
                    },
                    {
                        "name": "Xã Định Biên",
                        "code": 5575,
                        "codename": "xa_dinh_bien",
                        "division_type": "xã",
                        "short_codename": "dinh_bien"
                    },
                    {
                        "name": "Xã Thanh Định",
                        "code": 5578,
                        "codename": "xa_thanh_dinh",
                        "division_type": "xã",
                        "short_codename": "thanh_dinh"
                    },
                    {
                        "name": "Xã Trung Hội",
                        "code": 5581,
                        "codename": "xa_trung_hoi",
                        "division_type": "xã",
                        "short_codename": "trung_hoi"
                    },
                    {
                        "name": "Xã Trung Lương",
                        "code": 5584,
                        "codename": "xa_trung_luong",
                        "division_type": "xã",
                        "short_codename": "trung_luong"
                    },
                    {
                        "name": "Xã Bình Yên",
                        "code": 5587,
                        "codename": "xa_binh_yen",
                        "division_type": "xã",
                        "short_codename": "binh_yen"
                    },
                    {
                        "name": "Xã Điềm Mặc",
                        "code": 5590,
                        "codename": "xa_diem_mac",
                        "division_type": "xã",
                        "short_codename": "diem_mac"
                    },
                    {
                        "name": "Xã Phú Tiến",
                        "code": 5593,
                        "codename": "xa_phu_tien",
                        "division_type": "xã",
                        "short_codename": "phu_tien"
                    },
                    {
                        "name": "Xã Bộc Nhiêu",
                        "code": 5596,
                        "codename": "xa_boc_nhieu",
                        "division_type": "xã",
                        "short_codename": "boc_nhieu"
                    },
                    {
                        "name": "Xã Sơn Phú",
                        "code": 5599,
                        "codename": "xa_son_phu",
                        "division_type": "xã",
                        "short_codename": "son_phu"
                    },
                    {
                        "name": "Xã Phú Đình",
                        "code": 5602,
                        "codename": "xa_phu_dinh",
                        "division_type": "xã",
                        "short_codename": "phu_dinh"
                    },
                    {
                        "name": "Xã Bình Thành",
                        "code": 5605,
                        "codename": "xa_binh_thanh",
                        "division_type": "xã",
                        "short_codename": "binh_thanh"
                    }
                ]
            },
            {
                "name": "Huyện Phú Lương",
                "code": 168,
                "codename": "huyen_phu_luong",
                "division_type": "huyện",
                "short_codename": "phu_luong",
                "wards": [
                    {
                        "name": "Thị trấn Giang Tiên",
                        "code": 5608,
                        "codename": "thi_tran_giang_tien",
                        "division_type": "thị trấn",
                        "short_codename": "giang_tien"
                    },
                    {
                        "name": "Thị trấn Đu",
                        "code": 5611,
                        "codename": "thi_tran_du",
                        "division_type": "thị trấn",
                        "short_codename": "du"
                    },
                    {
                        "name": "Xã Yên Ninh",
                        "code": 5614,
                        "codename": "xa_yen_ninh",
                        "division_type": "xã",
                        "short_codename": "yen_ninh"
                    },
                    {
                        "name": "Xã Yên Trạch",
                        "code": 5617,
                        "codename": "xa_yen_trach",
                        "division_type": "xã",
                        "short_codename": "yen_trach"
                    },
                    {
                        "name": "Xã Yên Đổ",
                        "code": 5620,
                        "codename": "xa_yen_do",
                        "division_type": "xã",
                        "short_codename": "yen_do"
                    },
                    {
                        "name": "Xã Yên Lạc",
                        "code": 5623,
                        "codename": "xa_yen_lac",
                        "division_type": "xã",
                        "short_codename": "yen_lac"
                    },
                    {
                        "name": "Xã Ôn Lương",
                        "code": 5626,
                        "codename": "xa_on_luong",
                        "division_type": "xã",
                        "short_codename": "on_luong"
                    },
                    {
                        "name": "Xã Động Đạt",
                        "code": 5629,
                        "codename": "xa_dong_dat",
                        "division_type": "xã",
                        "short_codename": "dong_dat"
                    },
                    {
                        "name": "Xã Phủ Lý",
                        "code": 5632,
                        "codename": "xa_phu_ly",
                        "division_type": "xã",
                        "short_codename": "phu_ly"
                    },
                    {
                        "name": "Xã Phú Đô",
                        "code": 5635,
                        "codename": "xa_phu_do",
                        "division_type": "xã",
                        "short_codename": "phu_do"
                    },
                    {
                        "name": "Xã Hợp Thành",
                        "code": 5638,
                        "codename": "xa_hop_thanh",
                        "division_type": "xã",
                        "short_codename": "hop_thanh"
                    },
                    {
                        "name": "Xã Tức Tranh",
                        "code": 5641,
                        "codename": "xa_tuc_tranh",
                        "division_type": "xã",
                        "short_codename": "tuc_tranh"
                    },
                    {
                        "name": "Xã Phấn Mễ",
                        "code": 5644,
                        "codename": "xa_phan_me",
                        "division_type": "xã",
                        "short_codename": "phan_me"
                    },
                    {
                        "name": "Xã Vô Tranh",
                        "code": 5647,
                        "codename": "xa_vo_tranh",
                        "division_type": "xã",
                        "short_codename": "vo_tranh"
                    },
                    {
                        "name": "Xã Cổ Lũng",
                        "code": 5650,
                        "codename": "xa_co_lung",
                        "division_type": "xã",
                        "short_codename": "co_lung"
                    }
                ]
            },
            {
                "name": "Huyện Đồng Hỷ",
                "code": 169,
                "codename": "huyen_dong_hy",
                "division_type": "huyện",
                "short_codename": "dong_hy",
                "wards": [
                    {
                        "name": "Thị trấn Sông Cầu",
                        "code": 5656,
                        "codename": "thi_tran_song_cau",
                        "division_type": "thị trấn",
                        "short_codename": "song_cau"
                    },
                    {
                        "name": "Thị trấn Trại Cau",
                        "code": 5662,
                        "codename": "thi_tran_trai_cau",
                        "division_type": "thị trấn",
                        "short_codename": "trai_cau"
                    },
                    {
                        "name": "Xã Văn Lăng",
                        "code": 5665,
                        "codename": "xa_van_lang",
                        "division_type": "xã",
                        "short_codename": "van_lang"
                    },
                    {
                        "name": "Xã Tân Long",
                        "code": 5668,
                        "codename": "xa_tan_long",
                        "division_type": "xã",
                        "short_codename": "tan_long"
                    },
                    {
                        "name": "Xã Hòa Bình",
                        "code": 5671,
                        "codename": "xa_hoa_binh",
                        "division_type": "xã",
                        "short_codename": "hoa_binh"
                    },
                    {
                        "name": "Xã Quang Sơn",
                        "code": 5674,
                        "codename": "xa_quang_son",
                        "division_type": "xã",
                        "short_codename": "quang_son"
                    },
                    {
                        "name": "Xã Minh Lập",
                        "code": 5677,
                        "codename": "xa_minh_lap",
                        "division_type": "xã",
                        "short_codename": "minh_lap"
                    },
                    {
                        "name": "Xã Văn Hán",
                        "code": 5680,
                        "codename": "xa_van_han",
                        "division_type": "xã",
                        "short_codename": "van_han"
                    },
                    {
                        "name": "Xã Hóa Trung",
                        "code": 5683,
                        "codename": "xa_hoa_trung",
                        "division_type": "xã",
                        "short_codename": "hoa_trung"
                    },
                    {
                        "name": "Xã Khe Mo",
                        "code": 5686,
                        "codename": "xa_khe_mo",
                        "division_type": "xã",
                        "short_codename": "khe_mo"
                    },
                    {
                        "name": "Xã Cây Thị",
                        "code": 5689,
                        "codename": "xa_cay_thi",
                        "division_type": "xã",
                        "short_codename": "cay_thi"
                    },
                    {
                        "name": "Xã Hóa Thượng",
                        "code": 5692,
                        "codename": "xa_hoa_thuong",
                        "division_type": "xã",
                        "short_codename": "hoa_thuong"
                    },
                    {
                        "name": "Xã Hợp Tiến",
                        "code": 5698,
                        "codename": "xa_hop_tien",
                        "division_type": "xã",
                        "short_codename": "hop_tien"
                    },
                    {
                        "name": "Xã Tân Lợi",
                        "code": 5704,
                        "codename": "xa_tan_loi",
                        "division_type": "xã",
                        "short_codename": "tan_loi"
                    },
                    {
                        "name": "Xã Nam Hòa",
                        "code": 5707,
                        "codename": "xa_nam_hoa",
                        "division_type": "xã",
                        "short_codename": "nam_hoa"
                    }
                ]
            },
            {
                "name": "Huyện Võ Nhai",
                "code": 170,
                "codename": "huyen_vo_nhai",
                "division_type": "huyện",
                "short_codename": "vo_nhai",
                "wards": [
                    {
                        "name": "Thị trấn Đình Cả",
                        "code": 5716,
                        "codename": "thi_tran_dinh_ca",
                        "division_type": "thị trấn",
                        "short_codename": "dinh_ca"
                    },
                    {
                        "name": "Xã Sảng Mộc",
                        "code": 5719,
                        "codename": "xa_sang_moc",
                        "division_type": "xã",
                        "short_codename": "sang_moc"
                    },
                    {
                        "name": "Xã Nghinh Tường",
                        "code": 5722,
                        "codename": "xa_nghinh_tuong",
                        "division_type": "xã",
                        "short_codename": "nghinh_tuong"
                    },
                    {
                        "name": "Xã Thần Xa",
                        "code": 5725,
                        "codename": "xa_than_xa",
                        "division_type": "xã",
                        "short_codename": "than_xa"
                    },
                    {
                        "name": "Xã Vũ Chấn",
                        "code": 5728,
                        "codename": "xa_vu_chan",
                        "division_type": "xã",
                        "short_codename": "vu_chan"
                    },
                    {
                        "name": "Xã Thượng Nung",
                        "code": 5731,
                        "codename": "xa_thuong_nung",
                        "division_type": "xã",
                        "short_codename": "thuong_nung"
                    },
                    {
                        "name": "Xã Phú Thượng",
                        "code": 5734,
                        "codename": "xa_phu_thuong",
                        "division_type": "xã",
                        "short_codename": "phu_thuong"
                    },
                    {
                        "name": "Xã Cúc Đường",
                        "code": 5737,
                        "codename": "xa_cuc_duong",
                        "division_type": "xã",
                        "short_codename": "cuc_duong"
                    },
                    {
                        "name": "Xã La Hiên",
                        "code": 5740,
                        "codename": "xa_la_hien",
                        "division_type": "xã",
                        "short_codename": "la_hien"
                    },
                    {
                        "name": "Xã Lâu Thượng",
                        "code": 5743,
                        "codename": "xa_lau_thuong",
                        "division_type": "xã",
                        "short_codename": "lau_thuong"
                    },
                    {
                        "name": "Xã Tràng Xá",
                        "code": 5746,
                        "codename": "xa_trang_xa",
                        "division_type": "xã",
                        "short_codename": "trang_xa"
                    },
                    {
                        "name": "Xã Phương Giao",
                        "code": 5749,
                        "codename": "xa_phuong_giao",
                        "division_type": "xã",
                        "short_codename": "phuong_giao"
                    },
                    {
                        "name": "Xã Liên Minh",
                        "code": 5752,
                        "codename": "xa_lien_minh",
                        "division_type": "xã",
                        "short_codename": "lien_minh"
                    },
                    {
                        "name": "Xã Dân Tiến",
                        "code": 5755,
                        "codename": "xa_dan_tien",
                        "division_type": "xã",
                        "short_codename": "dan_tien"
                    },
                    {
                        "name": "Xã Bình Long",
                        "code": 5758,
                        "codename": "xa_binh_long",
                        "division_type": "xã",
                        "short_codename": "binh_long"
                    }
                ]
            },
            {
                "name": "Huyện Đại Từ",
                "code": 171,
                "codename": "huyen_dai_tu",
                "division_type": "huyện",
                "short_codename": "dai_tu",
                "wards": [
                    {
                        "name": "Thị trấn Hùng Sơn",
                        "code": 5761,
                        "codename": "thi_tran_hung_son",
                        "division_type": "thị trấn",
                        "short_codename": "hung_son"
                    },
                    {
                        "name": "Thị trấn Quân Chu",
                        "code": 5764,
                        "codename": "thi_tran_quan_chu",
                        "division_type": "thị trấn",
                        "short_codename": "quan_chu"
                    },
                    {
                        "name": "Xã Phúc Lương",
                        "code": 5767,
                        "codename": "xa_phuc_luong",
                        "division_type": "xã",
                        "short_codename": "phuc_luong"
                    },
                    {
                        "name": "Xã Minh Tiến",
                        "code": 5770,
                        "codename": "xa_minh_tien",
                        "division_type": "xã",
                        "short_codename": "minh_tien"
                    },
                    {
                        "name": "Xã Yên Lãng",
                        "code": 5773,
                        "codename": "xa_yen_lang",
                        "division_type": "xã",
                        "short_codename": "yen_lang"
                    },
                    {
                        "name": "Xã Đức Lương",
                        "code": 5776,
                        "codename": "xa_duc_luong",
                        "division_type": "xã",
                        "short_codename": "duc_luong"
                    },
                    {
                        "name": "Xã Phú Cường",
                        "code": 5779,
                        "codename": "xa_phu_cuong",
                        "division_type": "xã",
                        "short_codename": "phu_cuong"
                    },
                    {
                        "name": "Xã Na Mao",
                        "code": 5782,
                        "codename": "xa_na_mao",
                        "division_type": "xã",
                        "short_codename": "na_mao"
                    },
                    {
                        "name": "Xã Phú Lạc",
                        "code": 5785,
                        "codename": "xa_phu_lac",
                        "division_type": "xã",
                        "short_codename": "phu_lac"
                    },
                    {
                        "name": "Xã Tân Linh",
                        "code": 5788,
                        "codename": "xa_tan_linh",
                        "division_type": "xã",
                        "short_codename": "tan_linh"
                    },
                    {
                        "name": "Xã Phú Thịnh",
                        "code": 5791,
                        "codename": "xa_phu_thinh",
                        "division_type": "xã",
                        "short_codename": "phu_thinh"
                    },
                    {
                        "name": "Xã Phục Linh",
                        "code": 5794,
                        "codename": "xa_phuc_linh",
                        "division_type": "xã",
                        "short_codename": "phuc_linh"
                    },
                    {
                        "name": "Xã Phú Xuyên",
                        "code": 5797,
                        "codename": "xa_phu_xuyen",
                        "division_type": "xã",
                        "short_codename": "phu_xuyen"
                    },
                    {
                        "name": "Xã Bản Ngoại",
                        "code": 5800,
                        "codename": "xa_ban_ngoai",
                        "division_type": "xã",
                        "short_codename": "ban_ngoai"
                    },
                    {
                        "name": "Xã Tiên Hội",
                        "code": 5803,
                        "codename": "xa_tien_hoi",
                        "division_type": "xã",
                        "short_codename": "tien_hoi"
                    },
                    {
                        "name": "Xã Cù Vân",
                        "code": 5809,
                        "codename": "xa_cu_van",
                        "division_type": "xã",
                        "short_codename": "cu_van"
                    },
                    {
                        "name": "Xã Hà Thượng",
                        "code": 5812,
                        "codename": "xa_ha_thuong",
                        "division_type": "xã",
                        "short_codename": "ha_thuong"
                    },
                    {
                        "name": "Xã La Bằng",
                        "code": 5815,
                        "codename": "xa_la_bang",
                        "division_type": "xã",
                        "short_codename": "la_bang"
                    },
                    {
                        "name": "Xã Hoàng Nông",
                        "code": 5818,
                        "codename": "xa_hoang_nong",
                        "division_type": "xã",
                        "short_codename": "hoang_nong"
                    },
                    {
                        "name": "Xã Khôi Kỳ",
                        "code": 5821,
                        "codename": "xa_khoi_ky",
                        "division_type": "xã",
                        "short_codename": "khoi_ky"
                    },
                    {
                        "name": "Xã An Khánh",
                        "code": 5824,
                        "codename": "xa_an_khanh",
                        "division_type": "xã",
                        "short_codename": "an_khanh"
                    },
                    {
                        "name": "Xã Tân Thái",
                        "code": 5827,
                        "codename": "xa_tan_thai",
                        "division_type": "xã",
                        "short_codename": "tan_thai"
                    },
                    {
                        "name": "Xã Bình Thuận",
                        "code": 5830,
                        "codename": "xa_binh_thuan",
                        "division_type": "xã",
                        "short_codename": "binh_thuan"
                    },
                    {
                        "name": "Xã Lục Ba",
                        "code": 5833,
                        "codename": "xa_luc_ba",
                        "division_type": "xã",
                        "short_codename": "luc_ba"
                    },
                    {
                        "name": "Xã Mỹ Yên",
                        "code": 5836,
                        "codename": "xa_my_yen",
                        "division_type": "xã",
                        "short_codename": "my_yen"
                    },
                    {
                        "name": "Xã Vạn Thọ",
                        "code": 5839,
                        "codename": "xa_van_tho",
                        "division_type": "xã",
                        "short_codename": "van_tho"
                    },
                    {
                        "name": "Xã Văn Yên",
                        "code": 5842,
                        "codename": "xa_van_yen",
                        "division_type": "xã",
                        "short_codename": "van_yen"
                    },
                    {
                        "name": "Xã Ký Phú",
                        "code": 5845,
                        "codename": "xa_ky_phu",
                        "division_type": "xã",
                        "short_codename": "ky_phu"
                    },
                    {
                        "name": "Xã Cát Nê",
                        "code": 5848,
                        "codename": "xa_cat_ne",
                        "division_type": "xã",
                        "short_codename": "cat_ne"
                    },
                    {
                        "name": "Xã Quân Chu",
                        "code": 5851,
                        "codename": "xa_quan_chu",
                        "division_type": "xã",
                        "short_codename": "xa_quan_chu"
                    }
                ]
            },
            {
                "name": "Thị xã Phổ Yên",
                "code": 172,
                "codename": "thi_xa_pho_yen",
                "division_type": "thị xã",
                "short_codename": "pho_yen",
                "wards": [
                    {
                        "name": "Phường Bãi Bông",
                        "code": 5854,
                        "codename": "phuong_bai_bong",
                        "division_type": "phường",
                        "short_codename": "bai_bong"
                    },
                    {
                        "name": "Phường Bắc Sơn",
                        "code": 5857,
                        "codename": "phuong_bac_son",
                        "division_type": "phường",
                        "short_codename": "bac_son"
                    },
                    {
                        "name": "Phường Ba Hàng",
                        "code": 5860,
                        "codename": "phuong_ba_hang",
                        "division_type": "phường",
                        "short_codename": "ba_hang"
                    },
                    {
                        "name": "Xã Phúc Tân",
                        "code": 5863,
                        "codename": "xa_phuc_tan",
                        "division_type": "xã",
                        "short_codename": "phuc_tan"
                    },
                    {
                        "name": "Xã Phúc Thuận",
                        "code": 5866,
                        "codename": "xa_phuc_thuan",
                        "division_type": "xã",
                        "short_codename": "phuc_thuan"
                    },
                    {
                        "name": "Xã Hồng Tiến",
                        "code": 5869,
                        "codename": "xa_hong_tien",
                        "division_type": "xã",
                        "short_codename": "hong_tien"
                    },
                    {
                        "name": "Xã Minh Đức",
                        "code": 5872,
                        "codename": "xa_minh_duc",
                        "division_type": "xã",
                        "short_codename": "minh_duc"
                    },
                    {
                        "name": "Xã Đắc Sơn",
                        "code": 5875,
                        "codename": "xa_dac_son",
                        "division_type": "xã",
                        "short_codename": "dac_son"
                    },
                    {
                        "name": "Phường Đồng Tiến",
                        "code": 5878,
                        "codename": "phuong_dong_tien",
                        "division_type": "phường",
                        "short_codename": "dong_tien"
                    },
                    {
                        "name": "Xã Thành Công",
                        "code": 5881,
                        "codename": "xa_thanh_cong",
                        "division_type": "xã",
                        "short_codename": "thanh_cong"
                    },
                    {
                        "name": "Xã Tiên Phong",
                        "code": 5884,
                        "codename": "xa_tien_phong",
                        "division_type": "xã",
                        "short_codename": "tien_phong"
                    },
                    {
                        "name": "Xã Vạn Phái",
                        "code": 5887,
                        "codename": "xa_van_phai",
                        "division_type": "xã",
                        "short_codename": "van_phai"
                    },
                    {
                        "name": "Xã Nam Tiến",
                        "code": 5890,
                        "codename": "xa_nam_tien",
                        "division_type": "xã",
                        "short_codename": "nam_tien"
                    },
                    {
                        "name": "Xã Tân Hương",
                        "code": 5893,
                        "codename": "xa_tan_huong",
                        "division_type": "xã",
                        "short_codename": "tan_huong"
                    },
                    {
                        "name": "Xã Đông Cao",
                        "code": 5896,
                        "codename": "xa_dong_cao",
                        "division_type": "xã",
                        "short_codename": "dong_cao"
                    },
                    {
                        "name": "Xã Trung Thành",
                        "code": 5899,
                        "codename": "xa_trung_thanh",
                        "division_type": "xã",
                        "short_codename": "trung_thanh"
                    },
                    {
                        "name": "Xã Tân Phú",
                        "code": 5902,
                        "codename": "xa_tan_phu",
                        "division_type": "xã",
                        "short_codename": "tan_phu"
                    },
                    {
                        "name": "Xã Thuận Thành",
                        "code": 5905,
                        "codename": "xa_thuan_thanh",
                        "division_type": "xã",
                        "short_codename": "thuan_thanh"
                    }
                ]
            },
            {
                "name": "Huyện Phú Bình",
                "code": 173,
                "codename": "huyen_phu_binh",
                "division_type": "huyện",
                "short_codename": "phu_binh",
                "wards": [
                    {
                        "name": "Thị trấn Hương Sơn",
                        "code": 5908,
                        "codename": "thi_tran_huong_son",
                        "division_type": "thị trấn",
                        "short_codename": "huong_son"
                    },
                    {
                        "name": "Xã Bàn Đạt",
                        "code": 5911,
                        "codename": "xa_ban_dat",
                        "division_type": "xã",
                        "short_codename": "ban_dat"
                    },
                    {
                        "name": "Xã Tân Khánh",
                        "code": 5917,
                        "codename": "xa_tan_khanh",
                        "division_type": "xã",
                        "short_codename": "tan_khanh"
                    },
                    {
                        "name": "Xã Tân Kim",
                        "code": 5920,
                        "codename": "xa_tan_kim",
                        "division_type": "xã",
                        "short_codename": "tan_kim"
                    },
                    {
                        "name": "Xã Tân Thành",
                        "code": 5923,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Xã Đào Xá",
                        "code": 5926,
                        "codename": "xa_dao_xa",
                        "division_type": "xã",
                        "short_codename": "dao_xa"
                    },
                    {
                        "name": "Xã Bảo Lý",
                        "code": 5929,
                        "codename": "xa_bao_ly",
                        "division_type": "xã",
                        "short_codename": "bao_ly"
                    },
                    {
                        "name": "Xã Thượng Đình",
                        "code": 5932,
                        "codename": "xa_thuong_dinh",
                        "division_type": "xã",
                        "short_codename": "thuong_dinh"
                    },
                    {
                        "name": "Xã Tân Hòa",
                        "code": 5935,
                        "codename": "xa_tan_hoa",
                        "division_type": "xã",
                        "short_codename": "tan_hoa"
                    },
                    {
                        "name": "Xã Nhã Lộng",
                        "code": 5938,
                        "codename": "xa_nha_long",
                        "division_type": "xã",
                        "short_codename": "nha_long"
                    },
                    {
                        "name": "Xã Điềm Thụy",
                        "code": 5941,
                        "codename": "xa_diem_thuy",
                        "division_type": "xã",
                        "short_codename": "diem_thuy"
                    },
                    {
                        "name": "Xã Xuân Phương",
                        "code": 5944,
                        "codename": "xa_xuan_phuong",
                        "division_type": "xã",
                        "short_codename": "xuan_phuong"
                    },
                    {
                        "name": "Xã Tân Đức",
                        "code": 5947,
                        "codename": "xa_tan_duc",
                        "division_type": "xã",
                        "short_codename": "tan_duc"
                    },
                    {
                        "name": "Xã Úc Kỳ",
                        "code": 5950,
                        "codename": "xa_uc_ky",
                        "division_type": "xã",
                        "short_codename": "uc_ky"
                    },
                    {
                        "name": "Xã Lương Phú",
                        "code": 5953,
                        "codename": "xa_luong_phu",
                        "division_type": "xã",
                        "short_codename": "luong_phu"
                    },
                    {
                        "name": "Xã Nga My",
                        "code": 5956,
                        "codename": "xa_nga_my",
                        "division_type": "xã",
                        "short_codename": "nga_my"
                    },
                    {
                        "name": "Xã Kha Sơn",
                        "code": 5959,
                        "codename": "xa_kha_son",
                        "division_type": "xã",
                        "short_codename": "kha_son"
                    },
                    {
                        "name": "Xã Thanh Ninh",
                        "code": 5962,
                        "codename": "xa_thanh_ninh",
                        "division_type": "xã",
                        "short_codename": "thanh_ninh"
                    },
                    {
                        "name": "Xã Dương Thành",
                        "code": 5965,
                        "codename": "xa_duong_thanh",
                        "division_type": "xã",
                        "short_codename": "duong_thanh"
                    },
                    {
                        "name": "Xã Hà Châu",
                        "code": 5968,
                        "codename": "xa_ha_chau",
                        "division_type": "xã",
                        "short_codename": "ha_chau"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Lạng Sơn",
        "code": 20,
        "codename": "tinh_lang_son",
        "division_type": "tỉnh",
        "phone_code": 205,
        "districts": [
            {
                "name": "Thành phố Lạng Sơn",
                "code": 178,
                "codename": "thanh_pho_lang_son",
                "division_type": "thành phố",
                "short_codename": "lang_son",
                "wards": [
                    {
                        "name": "Phường Hoàng Văn Thụ",
                        "code": 5971,
                        "codename": "phuong_hoang_van_thu",
                        "division_type": "phường",
                        "short_codename": "hoang_van_thu"
                    },
                    {
                        "name": "Phường Tam Thanh",
                        "code": 5974,
                        "codename": "phuong_tam_thanh",
                        "division_type": "phường",
                        "short_codename": "tam_thanh"
                    },
                    {
                        "name": "Phường Vĩnh Trại",
                        "code": 5977,
                        "codename": "phuong_vinh_trai",
                        "division_type": "phường",
                        "short_codename": "vinh_trai"
                    },
                    {
                        "name": "Phường Đông Kinh",
                        "code": 5980,
                        "codename": "phuong_dong_kinh",
                        "division_type": "phường",
                        "short_codename": "dong_kinh"
                    },
                    {
                        "name": "Phường Chi Lăng",
                        "code": 5983,
                        "codename": "phuong_chi_lang",
                        "division_type": "phường",
                        "short_codename": "chi_lang"
                    },
                    {
                        "name": "Xã Hoàng Đồng",
                        "code": 5986,
                        "codename": "xa_hoang_dong",
                        "division_type": "xã",
                        "short_codename": "hoang_dong"
                    },
                    {
                        "name": "Xã Quảng Lạc",
                        "code": 5989,
                        "codename": "xa_quang_lac",
                        "division_type": "xã",
                        "short_codename": "quang_lac"
                    },
                    {
                        "name": "Xã Mai Pha",
                        "code": 5992,
                        "codename": "xa_mai_pha",
                        "division_type": "xã",
                        "short_codename": "mai_pha"
                    }
                ]
            },
            {
                "name": "Huyện Tràng Định",
                "code": 180,
                "codename": "huyen_trang_dinh",
                "division_type": "huyện",
                "short_codename": "trang_dinh",
                "wards": [
                    {
                        "name": "Thị trấn Thất Khê",
                        "code": 5995,
                        "codename": "thi_tran_that_khe",
                        "division_type": "thị trấn",
                        "short_codename": "that_khe"
                    },
                    {
                        "name": "Xã Khánh Long",
                        "code": 5998,
                        "codename": "xa_khanh_long",
                        "division_type": "xã",
                        "short_codename": "khanh_long"
                    },
                    {
                        "name": "Xã Đoàn Kết",
                        "code": 6001,
                        "codename": "xa_doan_ket",
                        "division_type": "xã",
                        "short_codename": "doan_ket"
                    },
                    {
                        "name": "Xã Quốc Khánh",
                        "code": 6004,
                        "codename": "xa_quoc_khanh",
                        "division_type": "xã",
                        "short_codename": "quoc_khanh"
                    },
                    {
                        "name": "Xã Vĩnh Tiến",
                        "code": 6007,
                        "codename": "xa_vinh_tien",
                        "division_type": "xã",
                        "short_codename": "vinh_tien"
                    },
                    {
                        "name": "Xã Cao Minh",
                        "code": 6010,
                        "codename": "xa_cao_minh",
                        "division_type": "xã",
                        "short_codename": "cao_minh"
                    },
                    {
                        "name": "Xã Chí Minh",
                        "code": 6013,
                        "codename": "xa_chi_minh",
                        "division_type": "xã",
                        "short_codename": "chi_minh"
                    },
                    {
                        "name": "Xã Tri Phương",
                        "code": 6016,
                        "codename": "xa_tri_phuong",
                        "division_type": "xã",
                        "short_codename": "tri_phuong"
                    },
                    {
                        "name": "Xã Tân Tiến",
                        "code": 6019,
                        "codename": "xa_tan_tien",
                        "division_type": "xã",
                        "short_codename": "tan_tien"
                    },
                    {
                        "name": "Xã Tân Yên",
                        "code": 6022,
                        "codename": "xa_tan_yen",
                        "division_type": "xã",
                        "short_codename": "tan_yen"
                    },
                    {
                        "name": "Xã Đội Cấn",
                        "code": 6025,
                        "codename": "xa_doi_can",
                        "division_type": "xã",
                        "short_codename": "doi_can"
                    },
                    {
                        "name": "Xã Tân Minh",
                        "code": 6028,
                        "codename": "xa_tan_minh",
                        "division_type": "xã",
                        "short_codename": "tan_minh"
                    },
                    {
                        "name": "Xã Kim Đồng",
                        "code": 6031,
                        "codename": "xa_kim_dong",
                        "division_type": "xã",
                        "short_codename": "kim_dong"
                    },
                    {
                        "name": "Xã Chi Lăng",
                        "code": 6034,
                        "codename": "xa_chi_lang",
                        "division_type": "xã",
                        "short_codename": "chi_lang"
                    },
                    {
                        "name": "Xã Trung Thành",
                        "code": 6037,
                        "codename": "xa_trung_thanh",
                        "division_type": "xã",
                        "short_codename": "trung_thanh"
                    },
                    {
                        "name": "Xã Đại Đồng",
                        "code": 6040,
                        "codename": "xa_dai_dong",
                        "division_type": "xã",
                        "short_codename": "dai_dong"
                    },
                    {
                        "name": "Xã Đào Viên",
                        "code": 6043,
                        "codename": "xa_dao_vien",
                        "division_type": "xã",
                        "short_codename": "dao_vien"
                    },
                    {
                        "name": "Xã Đề Thám",
                        "code": 6046,
                        "codename": "xa_de_tham",
                        "division_type": "xã",
                        "short_codename": "de_tham"
                    },
                    {
                        "name": "Xã Kháng Chiến",
                        "code": 6049,
                        "codename": "xa_khang_chien",
                        "division_type": "xã",
                        "short_codename": "khang_chien"
                    },
                    {
                        "name": "Xã Hùng Sơn",
                        "code": 6055,
                        "codename": "xa_hung_son",
                        "division_type": "xã",
                        "short_codename": "hung_son"
                    },
                    {
                        "name": "Xã Quốc Việt",
                        "code": 6058,
                        "codename": "xa_quoc_viet",
                        "division_type": "xã",
                        "short_codename": "quoc_viet"
                    },
                    {
                        "name": "Xã Hùng Việt",
                        "code": 6061,
                        "codename": "xa_hung_viet",
                        "division_type": "xã",
                        "short_codename": "hung_viet"
                    }
                ]
            },
            {
                "name": "Huyện Bình Gia",
                "code": 181,
                "codename": "huyen_binh_gia",
                "division_type": "huyện",
                "short_codename": "binh_gia",
                "wards": [
                    {
                        "name": "Xã Hưng Đạo",
                        "code": 6067,
                        "codename": "xa_hung_dao",
                        "division_type": "xã",
                        "short_codename": "hung_dao"
                    },
                    {
                        "name": "Xã Vĩnh Yên",
                        "code": 6070,
                        "codename": "xa_vinh_yen",
                        "division_type": "xã",
                        "short_codename": "vinh_yen"
                    },
                    {
                        "name": "Xã Hoa Thám",
                        "code": 6073,
                        "codename": "xa_hoa_tham",
                        "division_type": "xã",
                        "short_codename": "hoa_tham"
                    },
                    {
                        "name": "Xã Quý Hòa",
                        "code": 6076,
                        "codename": "xa_quy_hoa",
                        "division_type": "xã",
                        "short_codename": "quy_hoa"
                    },
                    {
                        "name": "Xã Hồng Phong",
                        "code": 6079,
                        "codename": "xa_hong_phong",
                        "division_type": "xã",
                        "short_codename": "hong_phong"
                    },
                    {
                        "name": "Xã Yên Lỗ",
                        "code": 6082,
                        "codename": "xa_yen_lo",
                        "division_type": "xã",
                        "short_codename": "yen_lo"
                    },
                    {
                        "name": "Xã Thiện Hòa",
                        "code": 6085,
                        "codename": "xa_thien_hoa",
                        "division_type": "xã",
                        "short_codename": "thien_hoa"
                    },
                    {
                        "name": "Xã Quang Trung",
                        "code": 6088,
                        "codename": "xa_quang_trung",
                        "division_type": "xã",
                        "short_codename": "quang_trung"
                    },
                    {
                        "name": "Xã Thiện Thuật",
                        "code": 6091,
                        "codename": "xa_thien_thuat",
                        "division_type": "xã",
                        "short_codename": "thien_thuat"
                    },
                    {
                        "name": "Xã Minh Khai",
                        "code": 6094,
                        "codename": "xa_minh_khai",
                        "division_type": "xã",
                        "short_codename": "minh_khai"
                    },
                    {
                        "name": "Xã Thiện Long",
                        "code": 6097,
                        "codename": "xa_thien_long",
                        "division_type": "xã",
                        "short_codename": "thien_long"
                    },
                    {
                        "name": "Xã Hoàng Văn Thụ",
                        "code": 6100,
                        "codename": "xa_hoang_van_thu",
                        "division_type": "xã",
                        "short_codename": "hoang_van_thu"
                    },
                    {
                        "name": "Xã Hòa Bình",
                        "code": 6103,
                        "codename": "xa_hoa_binh",
                        "division_type": "xã",
                        "short_codename": "hoa_binh"
                    },
                    {
                        "name": "Xã Mông Ân",
                        "code": 6106,
                        "codename": "xa_mong_an",
                        "division_type": "xã",
                        "short_codename": "mong_an"
                    },
                    {
                        "name": "Xã Tân Hòa",
                        "code": 6109,
                        "codename": "xa_tan_hoa",
                        "division_type": "xã",
                        "short_codename": "tan_hoa"
                    },
                    {
                        "name": "Thị trấn Bình Gia",
                        "code": 6112,
                        "codename": "thi_tran_binh_gia",
                        "division_type": "thị trấn",
                        "short_codename": "binh_gia"
                    },
                    {
                        "name": "Xã Hồng Thái",
                        "code": 6115,
                        "codename": "xa_hong_thai",
                        "division_type": "xã",
                        "short_codename": "hong_thai"
                    },
                    {
                        "name": "Xã Bình La",
                        "code": 6118,
                        "codename": "xa_binh_la",
                        "division_type": "xã",
                        "short_codename": "binh_la"
                    },
                    {
                        "name": "Xã Tân Văn",
                        "code": 6121,
                        "codename": "xa_tan_van",
                        "division_type": "xã",
                        "short_codename": "tan_van"
                    }
                ]
            },
            {
                "name": "Huyện Văn Lãng",
                "code": 182,
                "codename": "huyen_van_lang",
                "division_type": "huyện",
                "short_codename": "van_lang",
                "wards": [
                    {
                        "name": "Thị trấn Na Sầm",
                        "code": 6124,
                        "codename": "thi_tran_na_sam",
                        "division_type": "thị trấn",
                        "short_codename": "na_sam"
                    },
                    {
                        "name": "Xã Trùng Khánh",
                        "code": 6127,
                        "codename": "xa_trung_khanh",
                        "division_type": "xã",
                        "short_codename": "trung_khanh"
                    },
                    {
                        "name": "Xã Bắc La",
                        "code": 6133,
                        "codename": "xa_bac_la",
                        "division_type": "xã",
                        "short_codename": "bac_la"
                    },
                    {
                        "name": "Xã Thụy Hùng",
                        "code": 6136,
                        "codename": "xa_thuy_hung",
                        "division_type": "xã",
                        "short_codename": "thuy_hung"
                    },
                    {
                        "name": "Xã Bắc Hùng",
                        "code": 6139,
                        "codename": "xa_bac_hung",
                        "division_type": "xã",
                        "short_codename": "bac_hung"
                    },
                    {
                        "name": "Xã Tân Tác",
                        "code": 6142,
                        "codename": "xa_tan_tac",
                        "division_type": "xã",
                        "short_codename": "tan_tac"
                    },
                    {
                        "name": "Xã Thanh Long",
                        "code": 6148,
                        "codename": "xa_thanh_long",
                        "division_type": "xã",
                        "short_codename": "thanh_long"
                    },
                    {
                        "name": "Xã Hội Hoan",
                        "code": 6151,
                        "codename": "xa_hoi_hoan",
                        "division_type": "xã",
                        "short_codename": "hoi_hoan"
                    },
                    {
                        "name": "Xã Bắc Việt",
                        "code": 6154,
                        "codename": "xa_bac_viet",
                        "division_type": "xã",
                        "short_codename": "bac_viet"
                    },
                    {
                        "name": "Xã Hoàng Việt",
                        "code": 6157,
                        "codename": "xa_hoang_viet",
                        "division_type": "xã",
                        "short_codename": "hoang_viet"
                    },
                    {
                        "name": "Xã Gia Miễn",
                        "code": 6160,
                        "codename": "xa_gia_mien",
                        "division_type": "xã",
                        "short_codename": "gia_mien"
                    },
                    {
                        "name": "Xã Thành Hòa",
                        "code": 6163,
                        "codename": "xa_thanh_hoa",
                        "division_type": "xã",
                        "short_codename": "thanh_hoa"
                    },
                    {
                        "name": "Xã Tân Thanh",
                        "code": 6166,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Xã Tân Mỹ",
                        "code": 6172,
                        "codename": "xa_tan_my",
                        "division_type": "xã",
                        "short_codename": "tan_my"
                    },
                    {
                        "name": "Xã Hồng Thái",
                        "code": 6175,
                        "codename": "xa_hong_thai",
                        "division_type": "xã",
                        "short_codename": "hong_thai"
                    },
                    {
                        "name": "Xã Hoàng Văn Thụ",
                        "code": 6178,
                        "codename": "xa_hoang_van_thu",
                        "division_type": "xã",
                        "short_codename": "hoang_van_thu"
                    },
                    {
                        "name": "Xã Nhạc Kỳ",
                        "code": 6181,
                        "codename": "xa_nhac_ky",
                        "division_type": "xã",
                        "short_codename": "nhac_ky"
                    }
                ]
            },
            {
                "name": "Huyện Cao Lộc",
                "code": 183,
                "codename": "huyen_cao_loc",
                "division_type": "huyện",
                "short_codename": "cao_loc",
                "wards": [
                    {
                        "name": "Thị trấn Đồng Đăng",
                        "code": 6184,
                        "codename": "thi_tran_dong_dang",
                        "division_type": "thị trấn",
                        "short_codename": "dong_dang"
                    },
                    {
                        "name": "Thị trấn Cao Lộc",
                        "code": 6187,
                        "codename": "thi_tran_cao_loc",
                        "division_type": "thị trấn",
                        "short_codename": "cao_loc"
                    },
                    {
                        "name": "Xã Bảo Lâm",
                        "code": 6190,
                        "codename": "xa_bao_lam",
                        "division_type": "xã",
                        "short_codename": "bao_lam"
                    },
                    {
                        "name": "Xã Thanh Lòa",
                        "code": 6193,
                        "codename": "xa_thanh_loa",
                        "division_type": "xã",
                        "short_codename": "thanh_loa"
                    },
                    {
                        "name": "Xã Cao Lâu",
                        "code": 6196,
                        "codename": "xa_cao_lau",
                        "division_type": "xã",
                        "short_codename": "cao_lau"
                    },
                    {
                        "name": "Xã Thạch Đạn",
                        "code": 6199,
                        "codename": "xa_thach_dan",
                        "division_type": "xã",
                        "short_codename": "thach_dan"
                    },
                    {
                        "name": "Xã Xuất Lễ",
                        "code": 6202,
                        "codename": "xa_xuat_le",
                        "division_type": "xã",
                        "short_codename": "xuat_le"
                    },
                    {
                        "name": "Xã Hồng Phong",
                        "code": 6205,
                        "codename": "xa_hong_phong",
                        "division_type": "xã",
                        "short_codename": "hong_phong"
                    },
                    {
                        "name": "Xã Thụy Hùng",
                        "code": 6208,
                        "codename": "xa_thuy_hung",
                        "division_type": "xã",
                        "short_codename": "thuy_hung"
                    },
                    {
                        "name": "Xã Lộc Yên",
                        "code": 6211,
                        "codename": "xa_loc_yen",
                        "division_type": "xã",
                        "short_codename": "loc_yen"
                    },
                    {
                        "name": "Xã Phú Xá",
                        "code": 6214,
                        "codename": "xa_phu_xa",
                        "division_type": "xã",
                        "short_codename": "phu_xa"
                    },
                    {
                        "name": "Xã Bình Trung",
                        "code": 6217,
                        "codename": "xa_binh_trung",
                        "division_type": "xã",
                        "short_codename": "binh_trung"
                    },
                    {
                        "name": "Xã Hải Yến",
                        "code": 6220,
                        "codename": "xa_hai_yen",
                        "division_type": "xã",
                        "short_codename": "hai_yen"
                    },
                    {
                        "name": "Xã Hòa Cư",
                        "code": 6223,
                        "codename": "xa_hoa_cu",
                        "division_type": "xã",
                        "short_codename": "hoa_cu"
                    },
                    {
                        "name": "Xã Hợp Thành",
                        "code": 6226,
                        "codename": "xa_hop_thanh",
                        "division_type": "xã",
                        "short_codename": "hop_thanh"
                    },
                    {
                        "name": "Xã Công Sơn",
                        "code": 6232,
                        "codename": "xa_cong_son",
                        "division_type": "xã",
                        "short_codename": "cong_son"
                    },
                    {
                        "name": "Xã Gia Cát",
                        "code": 6235,
                        "codename": "xa_gia_cat",
                        "division_type": "xã",
                        "short_codename": "gia_cat"
                    },
                    {
                        "name": "Xã Mẫu Sơn",
                        "code": 6238,
                        "codename": "xa_mau_son",
                        "division_type": "xã",
                        "short_codename": "mau_son"
                    },
                    {
                        "name": "Xã Xuân Long",
                        "code": 6241,
                        "codename": "xa_xuan_long",
                        "division_type": "xã",
                        "short_codename": "xuan_long"
                    },
                    {
                        "name": "Xã Tân Liên",
                        "code": 6244,
                        "codename": "xa_tan_lien",
                        "division_type": "xã",
                        "short_codename": "tan_lien"
                    },
                    {
                        "name": "Xã Yên Trạch",
                        "code": 6247,
                        "codename": "xa_yen_trach",
                        "division_type": "xã",
                        "short_codename": "yen_trach"
                    },
                    {
                        "name": "Xã Tân Thành",
                        "code": 6250,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    }
                ]
            },
            {
                "name": "Huyện Văn Quan",
                "code": 184,
                "codename": "huyen_van_quan",
                "division_type": "huyện",
                "short_codename": "van_quan",
                "wards": [
                    {
                        "name": "Thị trấn Văn Quan",
                        "code": 6253,
                        "codename": "thi_tran_van_quan",
                        "division_type": "thị trấn",
                        "short_codename": "van_quan"
                    },
                    {
                        "name": "Xã Trấn Ninh",
                        "code": 6256,
                        "codename": "xa_tran_ninh",
                        "division_type": "xã",
                        "short_codename": "tran_ninh"
                    },
                    {
                        "name": "Xã Liên Hội",
                        "code": 6268,
                        "codename": "xa_lien_hoi",
                        "division_type": "xã",
                        "short_codename": "lien_hoi"
                    },
                    {
                        "name": "Xã Hòa Bình",
                        "code": 6274,
                        "codename": "xa_hoa_binh",
                        "division_type": "xã",
                        "short_codename": "hoa_binh"
                    },
                    {
                        "name": "Xã Tú Xuyên",
                        "code": 6277,
                        "codename": "xa_tu_xuyen",
                        "division_type": "xã",
                        "short_codename": "tu_xuyen"
                    },
                    {
                        "name": "Xã Điềm He",
                        "code": 6280,
                        "codename": "xa_diem_he",
                        "division_type": "xã",
                        "short_codename": "diem_he"
                    },
                    {
                        "name": "Xã An Sơn",
                        "code": 6283,
                        "codename": "xa_an_son",
                        "division_type": "xã",
                        "short_codename": "an_son"
                    },
                    {
                        "name": "Xã Khánh Khê",
                        "code": 6286,
                        "codename": "xa_khanh_khe",
                        "division_type": "xã",
                        "short_codename": "khanh_khe"
                    },
                    {
                        "name": "Xã Lương Năng",
                        "code": 6292,
                        "codename": "xa_luong_nang",
                        "division_type": "xã",
                        "short_codename": "luong_nang"
                    },
                    {
                        "name": "Xã Đồng Giáp",
                        "code": 6295,
                        "codename": "xa_dong_giap",
                        "division_type": "xã",
                        "short_codename": "dong_giap"
                    },
                    {
                        "name": "Xã Bình Phúc",
                        "code": 6298,
                        "codename": "xa_binh_phuc",
                        "division_type": "xã",
                        "short_codename": "binh_phuc"
                    },
                    {
                        "name": "Xã Tràng Các",
                        "code": 6301,
                        "codename": "xa_trang_cac",
                        "division_type": "xã",
                        "short_codename": "trang_cac"
                    },
                    {
                        "name": "Xã Tân Đoàn",
                        "code": 6307,
                        "codename": "xa_tan_doan",
                        "division_type": "xã",
                        "short_codename": "tan_doan"
                    },
                    {
                        "name": "Xã Tri Lễ",
                        "code": 6313,
                        "codename": "xa_tri_le",
                        "division_type": "xã",
                        "short_codename": "tri_le"
                    },
                    {
                        "name": "Xã Tràng Phái",
                        "code": 6316,
                        "codename": "xa_trang_phai",
                        "division_type": "xã",
                        "short_codename": "trang_phai"
                    },
                    {
                        "name": "Xã Yên Phúc",
                        "code": 6319,
                        "codename": "xa_yen_phuc",
                        "division_type": "xã",
                        "short_codename": "yen_phuc"
                    },
                    {
                        "name": "Xã Hữu Lễ",
                        "code": 6322,
                        "codename": "xa_huu_le",
                        "division_type": "xã",
                        "short_codename": "huu_le"
                    }
                ]
            },
            {
                "name": "Huyện Bắc Sơn",
                "code": 185,
                "codename": "huyen_bac_son",
                "division_type": "huyện",
                "short_codename": "bac_son",
                "wards": [
                    {
                        "name": "Thị trấn Bắc Sơn",
                        "code": 6325,
                        "codename": "thi_tran_bac_son",
                        "division_type": "thị trấn",
                        "short_codename": "bac_son"
                    },
                    {
                        "name": "Xã Long Đống",
                        "code": 6328,
                        "codename": "xa_long_dong",
                        "division_type": "xã",
                        "short_codename": "long_dong"
                    },
                    {
                        "name": "Xã Vạn Thủy",
                        "code": 6331,
                        "codename": "xa_van_thuy",
                        "division_type": "xã",
                        "short_codename": "van_thuy"
                    },
                    {
                        "name": "Xã Đồng ý",
                        "code": 6337,
                        "codename": "xa_dong_y",
                        "division_type": "xã",
                        "short_codename": "dong_y"
                    },
                    {
                        "name": "Xã Tân Tri",
                        "code": 6340,
                        "codename": "xa_tan_tri",
                        "division_type": "xã",
                        "short_codename": "tan_tri"
                    },
                    {
                        "name": "Xã Bắc Quỳnh",
                        "code": 6343,
                        "codename": "xa_bac_quynh",
                        "division_type": "xã",
                        "short_codename": "bac_quynh"
                    },
                    {
                        "name": "Xã Hưng Vũ",
                        "code": 6349,
                        "codename": "xa_hung_vu",
                        "division_type": "xã",
                        "short_codename": "hung_vu"
                    },
                    {
                        "name": "Xã Tân Lập",
                        "code": 6352,
                        "codename": "xa_tan_lap",
                        "division_type": "xã",
                        "short_codename": "tan_lap"
                    },
                    {
                        "name": "Xã Vũ Sơn",
                        "code": 6355,
                        "codename": "xa_vu_son",
                        "division_type": "xã",
                        "short_codename": "vu_son"
                    },
                    {
                        "name": "Xã Chiêu Vũ",
                        "code": 6358,
                        "codename": "xa_chieu_vu",
                        "division_type": "xã",
                        "short_codename": "chieu_vu"
                    },
                    {
                        "name": "Xã Tân Hương",
                        "code": 6361,
                        "codename": "xa_tan_huong",
                        "division_type": "xã",
                        "short_codename": "tan_huong"
                    },
                    {
                        "name": "Xã Chiến Thắng",
                        "code": 6364,
                        "codename": "xa_chien_thang",
                        "division_type": "xã",
                        "short_codename": "chien_thang"
                    },
                    {
                        "name": "Xã Vũ Lăng",
                        "code": 6367,
                        "codename": "xa_vu_lang",
                        "division_type": "xã",
                        "short_codename": "vu_lang"
                    },
                    {
                        "name": "Xã Trấn Yên",
                        "code": 6370,
                        "codename": "xa_tran_yen",
                        "division_type": "xã",
                        "short_codename": "tran_yen"
                    },
                    {
                        "name": "Xã Vũ Lễ",
                        "code": 6373,
                        "codename": "xa_vu_le",
                        "division_type": "xã",
                        "short_codename": "vu_le"
                    },
                    {
                        "name": "Xã Nhất Hòa",
                        "code": 6376,
                        "codename": "xa_nhat_hoa",
                        "division_type": "xã",
                        "short_codename": "nhat_hoa"
                    },
                    {
                        "name": "Xã Tân Thành",
                        "code": 6379,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Xã Nhất Tiến",
                        "code": 6382,
                        "codename": "xa_nhat_tien",
                        "division_type": "xã",
                        "short_codename": "nhat_tien"
                    }
                ]
            },
            {
                "name": "Huyện Hữu Lũng",
                "code": 186,
                "codename": "huyen_huu_lung",
                "division_type": "huyện",
                "short_codename": "huu_lung",
                "wards": [
                    {
                        "name": "Thị trấn Hữu Lũng",
                        "code": 6385,
                        "codename": "thi_tran_huu_lung",
                        "division_type": "thị trấn",
                        "short_codename": "huu_lung"
                    },
                    {
                        "name": "Xã Hữu Liên",
                        "code": 6388,
                        "codename": "xa_huu_lien",
                        "division_type": "xã",
                        "short_codename": "huu_lien"
                    },
                    {
                        "name": "Xã Yên Bình",
                        "code": 6391,
                        "codename": "xa_yen_binh",
                        "division_type": "xã",
                        "short_codename": "yen_binh"
                    },
                    {
                        "name": "Xã Quyết Thắng",
                        "code": 6394,
                        "codename": "xa_quyet_thang",
                        "division_type": "xã",
                        "short_codename": "quyet_thang"
                    },
                    {
                        "name": "Xã Hòa Bình",
                        "code": 6397,
                        "codename": "xa_hoa_binh",
                        "division_type": "xã",
                        "short_codename": "hoa_binh"
                    },
                    {
                        "name": "Xã Yên Thịnh",
                        "code": 6400,
                        "codename": "xa_yen_thinh",
                        "division_type": "xã",
                        "short_codename": "yen_thinh"
                    },
                    {
                        "name": "Xã Yên Sơn",
                        "code": 6403,
                        "codename": "xa_yen_son",
                        "division_type": "xã",
                        "short_codename": "yen_son"
                    },
                    {
                        "name": "Xã Thiện Tân",
                        "code": 6406,
                        "codename": "xa_thien_tan",
                        "division_type": "xã",
                        "short_codename": "thien_tan"
                    },
                    {
                        "name": "Xã Yên Vượng",
                        "code": 6412,
                        "codename": "xa_yen_vuong",
                        "division_type": "xã",
                        "short_codename": "yen_vuong"
                    },
                    {
                        "name": "Xã Minh Tiến",
                        "code": 6415,
                        "codename": "xa_minh_tien",
                        "division_type": "xã",
                        "short_codename": "minh_tien"
                    },
                    {
                        "name": "Xã Nhật Tiến",
                        "code": 6418,
                        "codename": "xa_nhat_tien",
                        "division_type": "xã",
                        "short_codename": "nhat_tien"
                    },
                    {
                        "name": "Xã Thanh Sơn",
                        "code": 6421,
                        "codename": "xa_thanh_son",
                        "division_type": "xã",
                        "short_codename": "thanh_son"
                    },
                    {
                        "name": "Xã Đồng Tân",
                        "code": 6424,
                        "codename": "xa_dong_tan",
                        "division_type": "xã",
                        "short_codename": "dong_tan"
                    },
                    {
                        "name": "Xã Cai Kinh",
                        "code": 6427,
                        "codename": "xa_cai_kinh",
                        "division_type": "xã",
                        "short_codename": "cai_kinh"
                    },
                    {
                        "name": "Xã Hòa Lạc",
                        "code": 6430,
                        "codename": "xa_hoa_lac",
                        "division_type": "xã",
                        "short_codename": "hoa_lac"
                    },
                    {
                        "name": "Xã Vân Nham",
                        "code": 6433,
                        "codename": "xa_van_nham",
                        "division_type": "xã",
                        "short_codename": "van_nham"
                    },
                    {
                        "name": "Xã Đồng Tiến",
                        "code": 6436,
                        "codename": "xa_dong_tien",
                        "division_type": "xã",
                        "short_codename": "dong_tien"
                    },
                    {
                        "name": "Xã Tân Thành",
                        "code": 6442,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Xã Hòa Sơn",
                        "code": 6445,
                        "codename": "xa_hoa_son",
                        "division_type": "xã",
                        "short_codename": "hoa_son"
                    },
                    {
                        "name": "Xã Minh Sơn",
                        "code": 6448,
                        "codename": "xa_minh_son",
                        "division_type": "xã",
                        "short_codename": "minh_son"
                    },
                    {
                        "name": "Xã Hồ Sơn",
                        "code": 6451,
                        "codename": "xa_ho_son",
                        "division_type": "xã",
                        "short_codename": "ho_son"
                    },
                    {
                        "name": "Xã Sơn Hà",
                        "code": 6454,
                        "codename": "xa_son_ha",
                        "division_type": "xã",
                        "short_codename": "son_ha"
                    },
                    {
                        "name": "Xã Minh Hòa",
                        "code": 6457,
                        "codename": "xa_minh_hoa",
                        "division_type": "xã",
                        "short_codename": "minh_hoa"
                    },
                    {
                        "name": "Xã Hòa Thắng",
                        "code": 6460,
                        "codename": "xa_hoa_thang",
                        "division_type": "xã",
                        "short_codename": "hoa_thang"
                    }
                ]
            },
            {
                "name": "Huyện Chi Lăng",
                "code": 187,
                "codename": "huyen_chi_lang",
                "division_type": "huyện",
                "short_codename": "chi_lang",
                "wards": [
                    {
                        "name": "Thị trấn Đồng Mỏ",
                        "code": 6463,
                        "codename": "thi_tran_dong_mo",
                        "division_type": "thị trấn",
                        "short_codename": "dong_mo"
                    },
                    {
                        "name": "Thị trấn Chi Lăng",
                        "code": 6466,
                        "codename": "thi_tran_chi_lang",
                        "division_type": "thị trấn",
                        "short_codename": "chi_lang"
                    },
                    {
                        "name": "Xã Vân An",
                        "code": 6469,
                        "codename": "xa_van_an",
                        "division_type": "xã",
                        "short_codename": "van_an"
                    },
                    {
                        "name": "Xã Vân Thủy",
                        "code": 6472,
                        "codename": "xa_van_thuy",
                        "division_type": "xã",
                        "short_codename": "van_thuy"
                    },
                    {
                        "name": "Xã Gia Lộc",
                        "code": 6475,
                        "codename": "xa_gia_loc",
                        "division_type": "xã",
                        "short_codename": "gia_loc"
                    },
                    {
                        "name": "Xã Bắc Thủy",
                        "code": 6478,
                        "codename": "xa_bac_thuy",
                        "division_type": "xã",
                        "short_codename": "bac_thuy"
                    },
                    {
                        "name": "Xã Chiến Thắng",
                        "code": 6481,
                        "codename": "xa_chien_thang",
                        "division_type": "xã",
                        "short_codename": "chien_thang"
                    },
                    {
                        "name": "Xã Mai Sao",
                        "code": 6484,
                        "codename": "xa_mai_sao",
                        "division_type": "xã",
                        "short_codename": "mai_sao"
                    },
                    {
                        "name": "Xã Bằng Hữu",
                        "code": 6487,
                        "codename": "xa_bang_huu",
                        "division_type": "xã",
                        "short_codename": "bang_huu"
                    },
                    {
                        "name": "Xã Thượng Cường",
                        "code": 6490,
                        "codename": "xa_thuong_cuong",
                        "division_type": "xã",
                        "short_codename": "thuong_cuong"
                    },
                    {
                        "name": "Xã Bằng Mạc",
                        "code": 6493,
                        "codename": "xa_bang_mac",
                        "division_type": "xã",
                        "short_codename": "bang_mac"
                    },
                    {
                        "name": "Xã Nhân Lý",
                        "code": 6496,
                        "codename": "xa_nhan_ly",
                        "division_type": "xã",
                        "short_codename": "nhan_ly"
                    },
                    {
                        "name": "Xã Lâm Sơn",
                        "code": 6499,
                        "codename": "xa_lam_son",
                        "division_type": "xã",
                        "short_codename": "lam_son"
                    },
                    {
                        "name": "Xã Liên Sơn",
                        "code": 6502,
                        "codename": "xa_lien_son",
                        "division_type": "xã",
                        "short_codename": "lien_son"
                    },
                    {
                        "name": "Xã Vạn Linh",
                        "code": 6505,
                        "codename": "xa_van_linh",
                        "division_type": "xã",
                        "short_codename": "van_linh"
                    },
                    {
                        "name": "Xã Hòa Bình",
                        "code": 6508,
                        "codename": "xa_hoa_binh",
                        "division_type": "xã",
                        "short_codename": "hoa_binh"
                    },
                    {
                        "name": "Xã Hữu Kiên",
                        "code": 6514,
                        "codename": "xa_huu_kien",
                        "division_type": "xã",
                        "short_codename": "huu_kien"
                    },
                    {
                        "name": "Xã Quan Sơn",
                        "code": 6517,
                        "codename": "xa_quan_son",
                        "division_type": "xã",
                        "short_codename": "quan_son"
                    },
                    {
                        "name": "Xã Y Tịch",
                        "code": 6520,
                        "codename": "xa_y_tich",
                        "division_type": "xã",
                        "short_codename": "y_tich"
                    },
                    {
                        "name": "Xã Chi Lăng",
                        "code": 6523,
                        "codename": "xa_chi_lang",
                        "division_type": "xã",
                        "short_codename": "xa_chi_lang"
                    }
                ]
            },
            {
                "name": "Huyện Lộc Bình",
                "code": 188,
                "codename": "huyen_loc_binh",
                "division_type": "huyện",
                "short_codename": "loc_binh",
                "wards": [
                    {
                        "name": "Thị trấn Na Dương",
                        "code": 6526,
                        "codename": "thi_tran_na_duong",
                        "division_type": "thị trấn",
                        "short_codename": "na_duong"
                    },
                    {
                        "name": "Thị trấn Lộc Bình",
                        "code": 6529,
                        "codename": "thi_tran_loc_binh",
                        "division_type": "thị trấn",
                        "short_codename": "loc_binh"
                    },
                    {
                        "name": "Xã Mẫu Sơn",
                        "code": 6532,
                        "codename": "xa_mau_son",
                        "division_type": "xã",
                        "short_codename": "mau_son"
                    },
                    {
                        "name": "Xã Yên Khoái",
                        "code": 6541,
                        "codename": "xa_yen_khoai",
                        "division_type": "xã",
                        "short_codename": "yen_khoai"
                    },
                    {
                        "name": "Xã Khánh Xuân",
                        "code": 6544,
                        "codename": "xa_khanh_xuan",
                        "division_type": "xã",
                        "short_codename": "khanh_xuan"
                    },
                    {
                        "name": "Xã Tú Mịch",
                        "code": 6547,
                        "codename": "xa_tu_mich",
                        "division_type": "xã",
                        "short_codename": "tu_mich"
                    },
                    {
                        "name": "Xã Hữu Khánh",
                        "code": 6550,
                        "codename": "xa_huu_khanh",
                        "division_type": "xã",
                        "short_codename": "huu_khanh"
                    },
                    {
                        "name": "Xã Đồng Bục",
                        "code": 6553,
                        "codename": "xa_dong_buc",
                        "division_type": "xã",
                        "short_codename": "dong_buc"
                    },
                    {
                        "name": "Xã Tam Gia",
                        "code": 6559,
                        "codename": "xa_tam_gia",
                        "division_type": "xã",
                        "short_codename": "tam_gia"
                    },
                    {
                        "name": "Xã Tú Đoạn",
                        "code": 6562,
                        "codename": "xa_tu_doan",
                        "division_type": "xã",
                        "short_codename": "tu_doan"
                    },
                    {
                        "name": "Xã Khuất Xá",
                        "code": 6565,
                        "codename": "xa_khuat_xa",
                        "division_type": "xã",
                        "short_codename": "khuat_xa"
                    },
                    {
                        "name": "Xã Tĩnh Bắc",
                        "code": 6574,
                        "codename": "xa_tinh_bac",
                        "division_type": "xã",
                        "short_codename": "tinh_bac"
                    },
                    {
                        "name": "Xã Thống Nhất",
                        "code": 6577,
                        "codename": "xa_thong_nhat",
                        "division_type": "xã",
                        "short_codename": "thong_nhat"
                    },
                    {
                        "name": "Xã Sàn Viên",
                        "code": 6589,
                        "codename": "xa_san_vien",
                        "division_type": "xã",
                        "short_codename": "san_vien"
                    },
                    {
                        "name": "Xã Đông Quan",
                        "code": 6592,
                        "codename": "xa_dong_quan",
                        "division_type": "xã",
                        "short_codename": "dong_quan"
                    },
                    {
                        "name": "Xã Minh Hiệp",
                        "code": 6595,
                        "codename": "xa_minh_hiep",
                        "division_type": "xã",
                        "short_codename": "minh_hiep"
                    },
                    {
                        "name": "Xã Hữu Lân",
                        "code": 6598,
                        "codename": "xa_huu_lan",
                        "division_type": "xã",
                        "short_codename": "huu_lan"
                    },
                    {
                        "name": "Xã Lợi Bác",
                        "code": 6601,
                        "codename": "xa_loi_bac",
                        "division_type": "xã",
                        "short_codename": "loi_bac"
                    },
                    {
                        "name": "Xã Nam Quan",
                        "code": 6604,
                        "codename": "xa_nam_quan",
                        "division_type": "xã",
                        "short_codename": "nam_quan"
                    },
                    {
                        "name": "Xã Xuân Dương",
                        "code": 6607,
                        "codename": "xa_xuan_duong",
                        "division_type": "xã",
                        "short_codename": "xuan_duong"
                    },
                    {
                        "name": "Xã Ái Quốc",
                        "code": 6610,
                        "codename": "xa_ai_quoc",
                        "division_type": "xã",
                        "short_codename": "ai_quoc"
                    }
                ]
            },
            {
                "name": "Huyện Đình Lập",
                "code": 189,
                "codename": "huyen_dinh_lap",
                "division_type": "huyện",
                "short_codename": "dinh_lap",
                "wards": [
                    {
                        "name": "Thị trấn Đình Lập",
                        "code": 6613,
                        "codename": "thi_tran_dinh_lap",
                        "division_type": "thị trấn",
                        "short_codename": "dinh_lap"
                    },
                    {
                        "name": "Thị trấn NT Thái Bình",
                        "code": 6616,
                        "codename": "thi_tran_nt_thai_binh",
                        "division_type": "thị trấn",
                        "short_codename": "nt_thai_binh"
                    },
                    {
                        "name": "Xã Bắc Xa",
                        "code": 6619,
                        "codename": "xa_bac_xa",
                        "division_type": "xã",
                        "short_codename": "bac_xa"
                    },
                    {
                        "name": "Xã Bính Xá",
                        "code": 6622,
                        "codename": "xa_binh_xa",
                        "division_type": "xã",
                        "short_codename": "binh_xa"
                    },
                    {
                        "name": "Xã Kiên Mộc",
                        "code": 6625,
                        "codename": "xa_kien_moc",
                        "division_type": "xã",
                        "short_codename": "kien_moc"
                    },
                    {
                        "name": "Xã Đình Lập",
                        "code": 6628,
                        "codename": "xa_dinh_lap",
                        "division_type": "xã",
                        "short_codename": "xa_dinh_lap"
                    },
                    {
                        "name": "Xã Thái Bình",
                        "code": 6631,
                        "codename": "xa_thai_binh",
                        "division_type": "xã",
                        "short_codename": "thai_binh"
                    },
                    {
                        "name": "Xã Cường Lợi",
                        "code": 6634,
                        "codename": "xa_cuong_loi",
                        "division_type": "xã",
                        "short_codename": "cuong_loi"
                    },
                    {
                        "name": "Xã Châu Sơn",
                        "code": 6637,
                        "codename": "xa_chau_son",
                        "division_type": "xã",
                        "short_codename": "chau_son"
                    },
                    {
                        "name": "Xã Lâm Ca",
                        "code": 6640,
                        "codename": "xa_lam_ca",
                        "division_type": "xã",
                        "short_codename": "lam_ca"
                    },
                    {
                        "name": "Xã Đồng Thắng",
                        "code": 6643,
                        "codename": "xa_dong_thang",
                        "division_type": "xã",
                        "short_codename": "dong_thang"
                    },
                    {
                        "name": "Xã Bắc Lãng",
                        "code": 6646,
                        "codename": "xa_bac_lang",
                        "division_type": "xã",
                        "short_codename": "bac_lang"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Quảng Ninh",
        "code": 22,
        "codename": "tinh_quang_ninh",
        "division_type": "tỉnh",
        "phone_code": 203,
        "districts": [
            {
                "name": "Thành phố Hạ Long",
                "code": 193,
                "codename": "thanh_pho_ha_long",
                "division_type": "thành phố",
                "short_codename": "ha_long",
                "wards": [
                    {
                        "name": "Phường Hà Khánh",
                        "code": 6649,
                        "codename": "phuong_ha_khanh",
                        "division_type": "phường",
                        "short_codename": "ha_khanh"
                    },
                    {
                        "name": "Phường Hà Phong",
                        "code": 6652,
                        "codename": "phuong_ha_phong",
                        "division_type": "phường",
                        "short_codename": "ha_phong"
                    },
                    {
                        "name": "Phường Hà Khẩu",
                        "code": 6655,
                        "codename": "phuong_ha_khau",
                        "division_type": "phường",
                        "short_codename": "ha_khau"
                    },
                    {
                        "name": "Phường Cao Xanh",
                        "code": 6658,
                        "codename": "phuong_cao_xanh",
                        "division_type": "phường",
                        "short_codename": "cao_xanh"
                    },
                    {
                        "name": "Phường Giếng Đáy",
                        "code": 6661,
                        "codename": "phuong_gieng_day",
                        "division_type": "phường",
                        "short_codename": "gieng_day"
                    },
                    {
                        "name": "Phường Hà Tu",
                        "code": 6664,
                        "codename": "phuong_ha_tu",
                        "division_type": "phường",
                        "short_codename": "ha_tu"
                    },
                    {
                        "name": "Phường Hà Trung",
                        "code": 6667,
                        "codename": "phuong_ha_trung",
                        "division_type": "phường",
                        "short_codename": "ha_trung"
                    },
                    {
                        "name": "Phường Hà Lầm",
                        "code": 6670,
                        "codename": "phuong_ha_lam",
                        "division_type": "phường",
                        "short_codename": "ha_lam"
                    },
                    {
                        "name": "Phường Bãi Cháy",
                        "code": 6673,
                        "codename": "phuong_bai_chay",
                        "division_type": "phường",
                        "short_codename": "bai_chay"
                    },
                    {
                        "name": "Phường Cao Thắng",
                        "code": 6676,
                        "codename": "phuong_cao_thang",
                        "division_type": "phường",
                        "short_codename": "cao_thang"
                    },
                    {
                        "name": "Phường Hùng Thắng",
                        "code": 6679,
                        "codename": "phuong_hung_thang",
                        "division_type": "phường",
                        "short_codename": "hung_thang"
                    },
                    {
                        "name": "Phường Yết Kiêu",
                        "code": 6682,
                        "codename": "phuong_yet_kieu",
                        "division_type": "phường",
                        "short_codename": "yet_kieu"
                    },
                    {
                        "name": "Phường Trần Hưng Đạo",
                        "code": 6685,
                        "codename": "phuong_tran_hung_dao",
                        "division_type": "phường",
                        "short_codename": "tran_hung_dao"
                    },
                    {
                        "name": "Phường Hồng Hải",
                        "code": 6688,
                        "codename": "phuong_hong_hai",
                        "division_type": "phường",
                        "short_codename": "hong_hai"
                    },
                    {
                        "name": "Phường Hồng Gai",
                        "code": 6691,
                        "codename": "phuong_hong_gai",
                        "division_type": "phường",
                        "short_codename": "hong_gai"
                    },
                    {
                        "name": "Phường Bạch Đằng",
                        "code": 6694,
                        "codename": "phuong_bach_dang",
                        "division_type": "phường",
                        "short_codename": "bach_dang"
                    },
                    {
                        "name": "Phường Hồng Hà",
                        "code": 6697,
                        "codename": "phuong_hong_ha",
                        "division_type": "phường",
                        "short_codename": "hong_ha"
                    },
                    {
                        "name": "Phường Tuần Châu",
                        "code": 6700,
                        "codename": "phuong_tuan_chau",
                        "division_type": "phường",
                        "short_codename": "tuan_chau"
                    },
                    {
                        "name": "Phường Việt Hưng",
                        "code": 6703,
                        "codename": "phuong_viet_hung",
                        "division_type": "phường",
                        "short_codename": "viet_hung"
                    },
                    {
                        "name": "Phường Đại Yên",
                        "code": 6706,
                        "codename": "phuong_dai_yen",
                        "division_type": "phường",
                        "short_codename": "dai_yen"
                    },
                    {
                        "name": "Phường Hoành Bồ",
                        "code": 7030,
                        "codename": "phuong_hoanh_bo",
                        "division_type": "phường",
                        "short_codename": "hoanh_bo"
                    },
                    {
                        "name": "Xã Kỳ Thượng",
                        "code": 7033,
                        "codename": "xa_ky_thuong",
                        "division_type": "xã",
                        "short_codename": "ky_thuong"
                    },
                    {
                        "name": "Xã Đồng Sơn",
                        "code": 7036,
                        "codename": "xa_dong_son",
                        "division_type": "xã",
                        "short_codename": "dong_son"
                    },
                    {
                        "name": "Xã Tân Dân",
                        "code": 7039,
                        "codename": "xa_tan_dan",
                        "division_type": "xã",
                        "short_codename": "tan_dan"
                    },
                    {
                        "name": "Xã Đồng Lâm",
                        "code": 7042,
                        "codename": "xa_dong_lam",
                        "division_type": "xã",
                        "short_codename": "dong_lam"
                    },
                    {
                        "name": "Xã Hòa Bình",
                        "code": 7045,
                        "codename": "xa_hoa_binh",
                        "division_type": "xã",
                        "short_codename": "hoa_binh"
                    },
                    {
                        "name": "Xã Vũ Oai",
                        "code": 7048,
                        "codename": "xa_vu_oai",
                        "division_type": "xã",
                        "short_codename": "vu_oai"
                    },
                    {
                        "name": "Xã Dân Chủ",
                        "code": 7051,
                        "codename": "xa_dan_chu",
                        "division_type": "xã",
                        "short_codename": "dan_chu"
                    },
                    {
                        "name": "Xã Quảng La",
                        "code": 7054,
                        "codename": "xa_quang_la",
                        "division_type": "xã",
                        "short_codename": "quang_la"
                    },
                    {
                        "name": "Xã Bằng Cả",
                        "code": 7057,
                        "codename": "xa_bang_ca",
                        "division_type": "xã",
                        "short_codename": "bang_ca"
                    },
                    {
                        "name": "Xã Thống Nhất",
                        "code": 7060,
                        "codename": "xa_thong_nhat",
                        "division_type": "xã",
                        "short_codename": "thong_nhat"
                    },
                    {
                        "name": "Xã Sơn Dương",
                        "code": 7063,
                        "codename": "xa_son_duong",
                        "division_type": "xã",
                        "short_codename": "son_duong"
                    },
                    {
                        "name": "Xã Lê Lợi",
                        "code": 7066,
                        "codename": "xa_le_loi",
                        "division_type": "xã",
                        "short_codename": "le_loi"
                    }
                ]
            },
            {
                "name": "Thành phố Móng Cái",
                "code": 194,
                "codename": "thanh_pho_mong_cai",
                "division_type": "thành phố",
                "short_codename": "mong_cai",
                "wards": [
                    {
                        "name": "Phường Ka Long",
                        "code": 6709,
                        "codename": "phuong_ka_long",
                        "division_type": "phường",
                        "short_codename": "ka_long"
                    },
                    {
                        "name": "Phường Trần Phú",
                        "code": 6712,
                        "codename": "phuong_tran_phu",
                        "division_type": "phường",
                        "short_codename": "tran_phu"
                    },
                    {
                        "name": "Phường Ninh Dương",
                        "code": 6715,
                        "codename": "phuong_ninh_duong",
                        "division_type": "phường",
                        "short_codename": "ninh_duong"
                    },
                    {
                        "name": "Phường Hoà Lạc",
                        "code": 6718,
                        "codename": "phuong_hoa_lac",
                        "division_type": "phường",
                        "short_codename": "hoa_lac"
                    },
                    {
                        "name": "Phường Trà Cổ",
                        "code": 6721,
                        "codename": "phuong_tra_co",
                        "division_type": "phường",
                        "short_codename": "tra_co"
                    },
                    {
                        "name": "Xã Hải Sơn",
                        "code": 6724,
                        "codename": "xa_hai_son",
                        "division_type": "xã",
                        "short_codename": "hai_son"
                    },
                    {
                        "name": "Xã Bắc Sơn",
                        "code": 6727,
                        "codename": "xa_bac_son",
                        "division_type": "xã",
                        "short_codename": "bac_son"
                    },
                    {
                        "name": "Xã Hải Đông",
                        "code": 6730,
                        "codename": "xa_hai_dong",
                        "division_type": "xã",
                        "short_codename": "hai_dong"
                    },
                    {
                        "name": "Xã Hải Tiến",
                        "code": 6733,
                        "codename": "xa_hai_tien",
                        "division_type": "xã",
                        "short_codename": "hai_tien"
                    },
                    {
                        "name": "Phường Hải Yên",
                        "code": 6736,
                        "codename": "phuong_hai_yen",
                        "division_type": "phường",
                        "short_codename": "hai_yen"
                    },
                    {
                        "name": "Xã Quảng Nghĩa",
                        "code": 6739,
                        "codename": "xa_quang_nghia",
                        "division_type": "xã",
                        "short_codename": "quang_nghia"
                    },
                    {
                        "name": "Phường Hải Hoà",
                        "code": 6742,
                        "codename": "phuong_hai_hoa",
                        "division_type": "phường",
                        "short_codename": "hai_hoa"
                    },
                    {
                        "name": "Xã Hải Xuân",
                        "code": 6745,
                        "codename": "xa_hai_xuan",
                        "division_type": "xã",
                        "short_codename": "hai_xuan"
                    },
                    {
                        "name": "Xã Vạn Ninh",
                        "code": 6748,
                        "codename": "xa_van_ninh",
                        "division_type": "xã",
                        "short_codename": "van_ninh"
                    },
                    {
                        "name": "Phường Bình Ngọc",
                        "code": 6751,
                        "codename": "phuong_binh_ngoc",
                        "division_type": "phường",
                        "short_codename": "binh_ngoc"
                    },
                    {
                        "name": "Xã Vĩnh Trung",
                        "code": 6754,
                        "codename": "xa_vinh_trung",
                        "division_type": "xã",
                        "short_codename": "vinh_trung"
                    },
                    {
                        "name": "Xã Vĩnh Thực",
                        "code": 6757,
                        "codename": "xa_vinh_thuc",
                        "division_type": "xã",
                        "short_codename": "vinh_thuc"
                    }
                ]
            },
            {
                "name": "Thành phố Cẩm Phả",
                "code": 195,
                "codename": "thanh_pho_cam_pha",
                "division_type": "thành phố",
                "short_codename": "cam_pha",
                "wards": [
                    {
                        "name": "Phường Mông Dương",
                        "code": 6760,
                        "codename": "phuong_mong_duong",
                        "division_type": "phường",
                        "short_codename": "mong_duong"
                    },
                    {
                        "name": "Phường Cửa Ông",
                        "code": 6763,
                        "codename": "phuong_cua_ong",
                        "division_type": "phường",
                        "short_codename": "cua_ong"
                    },
                    {
                        "name": "Phường Cẩm Sơn",
                        "code": 6766,
                        "codename": "phuong_cam_son",
                        "division_type": "phường",
                        "short_codename": "cam_son"
                    },
                    {
                        "name": "Phường Cẩm Đông",
                        "code": 6769,
                        "codename": "phuong_cam_dong",
                        "division_type": "phường",
                        "short_codename": "cam_dong"
                    },
                    {
                        "name": "Phường Cẩm Phú",
                        "code": 6772,
                        "codename": "phuong_cam_phu",
                        "division_type": "phường",
                        "short_codename": "cam_phu"
                    },
                    {
                        "name": "Phường Cẩm Tây",
                        "code": 6775,
                        "codename": "phuong_cam_tay",
                        "division_type": "phường",
                        "short_codename": "cam_tay"
                    },
                    {
                        "name": "Phường Quang Hanh",
                        "code": 6778,
                        "codename": "phuong_quang_hanh",
                        "division_type": "phường",
                        "short_codename": "quang_hanh"
                    },
                    {
                        "name": "Phường Cẩm Thịnh",
                        "code": 6781,
                        "codename": "phuong_cam_thinh",
                        "division_type": "phường",
                        "short_codename": "cam_thinh"
                    },
                    {
                        "name": "Phường Cẩm Thủy",
                        "code": 6784,
                        "codename": "phuong_cam_thuy",
                        "division_type": "phường",
                        "short_codename": "cam_thuy"
                    },
                    {
                        "name": "Phường Cẩm Thạch",
                        "code": 6787,
                        "codename": "phuong_cam_thach",
                        "division_type": "phường",
                        "short_codename": "cam_thach"
                    },
                    {
                        "name": "Phường Cẩm Thành",
                        "code": 6790,
                        "codename": "phuong_cam_thanh",
                        "division_type": "phường",
                        "short_codename": "cam_thanh"
                    },
                    {
                        "name": "Phường Cẩm Trung",
                        "code": 6793,
                        "codename": "phuong_cam_trung",
                        "division_type": "phường",
                        "short_codename": "cam_trung"
                    },
                    {
                        "name": "Phường Cẩm Bình",
                        "code": 6796,
                        "codename": "phuong_cam_binh",
                        "division_type": "phường",
                        "short_codename": "cam_binh"
                    },
                    {
                        "name": "Xã Cộng Hòa",
                        "code": 6799,
                        "codename": "xa_cong_hoa",
                        "division_type": "xã",
                        "short_codename": "cong_hoa"
                    },
                    {
                        "name": "Xã Cẩm Hải",
                        "code": 6802,
                        "codename": "xa_cam_hai",
                        "division_type": "xã",
                        "short_codename": "cam_hai"
                    },
                    {
                        "name": "Xã Dương Huy",
                        "code": 6805,
                        "codename": "xa_duong_huy",
                        "division_type": "xã",
                        "short_codename": "duong_huy"
                    }
                ]
            },
            {
                "name": "Thành phố Uông Bí",
                "code": 196,
                "codename": "thanh_pho_uong_bi",
                "division_type": "thành phố",
                "short_codename": "uong_bi",
                "wards": [
                    {
                        "name": "Phường Vàng Danh",
                        "code": 6808,
                        "codename": "phuong_vang_danh",
                        "division_type": "phường",
                        "short_codename": "vang_danh"
                    },
                    {
                        "name": "Phường Thanh Sơn",
                        "code": 6811,
                        "codename": "phuong_thanh_son",
                        "division_type": "phường",
                        "short_codename": "thanh_son"
                    },
                    {
                        "name": "Phường Bắc Sơn",
                        "code": 6814,
                        "codename": "phuong_bac_son",
                        "division_type": "phường",
                        "short_codename": "bac_son"
                    },
                    {
                        "name": "Phường Quang Trung",
                        "code": 6817,
                        "codename": "phuong_quang_trung",
                        "division_type": "phường",
                        "short_codename": "quang_trung"
                    },
                    {
                        "name": "Phường Trưng Vương",
                        "code": 6820,
                        "codename": "phuong_trung_vuong",
                        "division_type": "phường",
                        "short_codename": "trung_vuong"
                    },
                    {
                        "name": "Phường Nam Khê",
                        "code": 6823,
                        "codename": "phuong_nam_khe",
                        "division_type": "phường",
                        "short_codename": "nam_khe"
                    },
                    {
                        "name": "Phường Yên Thanh",
                        "code": 6826,
                        "codename": "phuong_yen_thanh",
                        "division_type": "phường",
                        "short_codename": "yen_thanh"
                    },
                    {
                        "name": "Xã Thượng Yên Công",
                        "code": 6829,
                        "codename": "xa_thuong_yen_cong",
                        "division_type": "xã",
                        "short_codename": "thuong_yen_cong"
                    },
                    {
                        "name": "Phường Phương Đông",
                        "code": 6832,
                        "codename": "phuong_phuong_dong",
                        "division_type": "phường",
                        "short_codename": "phuong_dong"
                    },
                    {
                        "name": "Phường Phương Nam",
                        "code": 6835,
                        "codename": "phuong_phuong_nam",
                        "division_type": "phường",
                        "short_codename": "phuong_nam"
                    }
                ]
            },
            {
                "name": "Huyện Bình Liêu",
                "code": 198,
                "codename": "huyen_binh_lieu",
                "division_type": "huyện",
                "short_codename": "binh_lieu",
                "wards": [
                    {
                        "name": "Thị trấn Bình Liêu",
                        "code": 6838,
                        "codename": "thi_tran_binh_lieu",
                        "division_type": "thị trấn",
                        "short_codename": "binh_lieu"
                    },
                    {
                        "name": "Xã Hoành Mô",
                        "code": 6841,
                        "codename": "xa_hoanh_mo",
                        "division_type": "xã",
                        "short_codename": "hoanh_mo"
                    },
                    {
                        "name": "Xã Đồng Tâm",
                        "code": 6844,
                        "codename": "xa_dong_tam",
                        "division_type": "xã",
                        "short_codename": "dong_tam"
                    },
                    {
                        "name": "Xã Đồng Văn",
                        "code": 6847,
                        "codename": "xa_dong_van",
                        "division_type": "xã",
                        "short_codename": "dong_van"
                    },
                    {
                        "name": "Xã Vô Ngại",
                        "code": 6853,
                        "codename": "xa_vo_ngai",
                        "division_type": "xã",
                        "short_codename": "vo_ngai"
                    },
                    {
                        "name": "Xã Lục Hồn",
                        "code": 6856,
                        "codename": "xa_luc_hon",
                        "division_type": "xã",
                        "short_codename": "luc_hon"
                    },
                    {
                        "name": "Xã Húc Động",
                        "code": 6859,
                        "codename": "xa_huc_dong",
                        "division_type": "xã",
                        "short_codename": "huc_dong"
                    }
                ]
            },
            {
                "name": "Huyện Tiên Yên",
                "code": 199,
                "codename": "huyen_tien_yen",
                "division_type": "huyện",
                "short_codename": "tien_yen",
                "wards": [
                    {
                        "name": "Thị trấn Tiên Yên",
                        "code": 6862,
                        "codename": "thi_tran_tien_yen",
                        "division_type": "thị trấn",
                        "short_codename": "tien_yen"
                    },
                    {
                        "name": "Xã Hà Lâu",
                        "code": 6865,
                        "codename": "xa_ha_lau",
                        "division_type": "xã",
                        "short_codename": "ha_lau"
                    },
                    {
                        "name": "Xã Đại Dực",
                        "code": 6868,
                        "codename": "xa_dai_duc",
                        "division_type": "xã",
                        "short_codename": "dai_duc"
                    },
                    {
                        "name": "Xã Phong Dụ",
                        "code": 6871,
                        "codename": "xa_phong_du",
                        "division_type": "xã",
                        "short_codename": "phong_du"
                    },
                    {
                        "name": "Xã Điền Xá",
                        "code": 6874,
                        "codename": "xa_dien_xa",
                        "division_type": "xã",
                        "short_codename": "dien_xa"
                    },
                    {
                        "name": "Xã Đông Ngũ",
                        "code": 6877,
                        "codename": "xa_dong_ngu",
                        "division_type": "xã",
                        "short_codename": "dong_ngu"
                    },
                    {
                        "name": "Xã Yên Than",
                        "code": 6880,
                        "codename": "xa_yen_than",
                        "division_type": "xã",
                        "short_codename": "yen_than"
                    },
                    {
                        "name": "Xã Đông Hải",
                        "code": 6883,
                        "codename": "xa_dong_hai",
                        "division_type": "xã",
                        "short_codename": "dong_hai"
                    },
                    {
                        "name": "Xã Hải Lạng",
                        "code": 6886,
                        "codename": "xa_hai_lang",
                        "division_type": "xã",
                        "short_codename": "hai_lang"
                    },
                    {
                        "name": "Xã Tiên Lãng",
                        "code": 6889,
                        "codename": "xa_tien_lang",
                        "division_type": "xã",
                        "short_codename": "tien_lang"
                    },
                    {
                        "name": "Xã Đồng Rui",
                        "code": 6892,
                        "codename": "xa_dong_rui",
                        "division_type": "xã",
                        "short_codename": "dong_rui"
                    }
                ]
            },
            {
                "name": "Huyện Đầm Hà",
                "code": 200,
                "codename": "huyen_dam_ha",
                "division_type": "huyện",
                "short_codename": "dam_ha",
                "wards": [
                    {
                        "name": "Thị trấn Đầm Hà",
                        "code": 6895,
                        "codename": "thi_tran_dam_ha",
                        "division_type": "thị trấn",
                        "short_codename": "dam_ha"
                    },
                    {
                        "name": "Xã Quảng Lâm",
                        "code": 6898,
                        "codename": "xa_quang_lam",
                        "division_type": "xã",
                        "short_codename": "quang_lam"
                    },
                    {
                        "name": "Xã Quảng An",
                        "code": 6901,
                        "codename": "xa_quang_an",
                        "division_type": "xã",
                        "short_codename": "quang_an"
                    },
                    {
                        "name": "Xã Tân Bình",
                        "code": 6904,
                        "codename": "xa_tan_binh",
                        "division_type": "xã",
                        "short_codename": "tan_binh"
                    },
                    {
                        "name": "Xã Dực Yên",
                        "code": 6910,
                        "codename": "xa_duc_yen",
                        "division_type": "xã",
                        "short_codename": "duc_yen"
                    },
                    {
                        "name": "Xã Quảng Tân",
                        "code": 6913,
                        "codename": "xa_quang_tan",
                        "division_type": "xã",
                        "short_codename": "quang_tan"
                    },
                    {
                        "name": "Xã Đầm Hà",
                        "code": 6916,
                        "codename": "xa_dam_ha",
                        "division_type": "xã",
                        "short_codename": "xa_dam_ha"
                    },
                    {
                        "name": "Xã Tân Lập",
                        "code": 6917,
                        "codename": "xa_tan_lap",
                        "division_type": "xã",
                        "short_codename": "tan_lap"
                    },
                    {
                        "name": "Xã Đại Bình",
                        "code": 6919,
                        "codename": "xa_dai_binh",
                        "division_type": "xã",
                        "short_codename": "dai_binh"
                    }
                ]
            },
            {
                "name": "Huyện Hải Hà",
                "code": 201,
                "codename": "huyen_hai_ha",
                "division_type": "huyện",
                "short_codename": "hai_ha",
                "wards": [
                    {
                        "name": "Thị trấn Quảng Hà",
                        "code": 6922,
                        "codename": "thi_tran_quang_ha",
                        "division_type": "thị trấn",
                        "short_codename": "quang_ha"
                    },
                    {
                        "name": "Xã Quảng Đức",
                        "code": 6925,
                        "codename": "xa_quang_duc",
                        "division_type": "xã",
                        "short_codename": "quang_duc"
                    },
                    {
                        "name": "Xã Quảng Sơn",
                        "code": 6928,
                        "codename": "xa_quang_son",
                        "division_type": "xã",
                        "short_codename": "quang_son"
                    },
                    {
                        "name": "Xã Quảng Thành",
                        "code": 6931,
                        "codename": "xa_quang_thanh",
                        "division_type": "xã",
                        "short_codename": "quang_thanh"
                    },
                    {
                        "name": "Xã Quảng Thịnh",
                        "code": 6937,
                        "codename": "xa_quang_thinh",
                        "division_type": "xã",
                        "short_codename": "quang_thinh"
                    },
                    {
                        "name": "Xã Quảng Minh",
                        "code": 6940,
                        "codename": "xa_quang_minh",
                        "division_type": "xã",
                        "short_codename": "quang_minh"
                    },
                    {
                        "name": "Xã Quảng Chính",
                        "code": 6943,
                        "codename": "xa_quang_chinh",
                        "division_type": "xã",
                        "short_codename": "quang_chinh"
                    },
                    {
                        "name": "Xã Quảng Long",
                        "code": 6946,
                        "codename": "xa_quang_long",
                        "division_type": "xã",
                        "short_codename": "quang_long"
                    },
                    {
                        "name": "Xã Đường Hoa",
                        "code": 6949,
                        "codename": "xa_duong_hoa",
                        "division_type": "xã",
                        "short_codename": "duong_hoa"
                    },
                    {
                        "name": "Xã Quảng Phong",
                        "code": 6952,
                        "codename": "xa_quang_phong",
                        "division_type": "xã",
                        "short_codename": "quang_phong"
                    },
                    {
                        "name": "Xã Cái Chiên",
                        "code": 6967,
                        "codename": "xa_cai_chien",
                        "division_type": "xã",
                        "short_codename": "cai_chien"
                    }
                ]
            },
            {
                "name": "Huyện Ba Chẽ",
                "code": 202,
                "codename": "huyen_ba_che",
                "division_type": "huyện",
                "short_codename": "ba_che",
                "wards": [
                    {
                        "name": "Thị trấn Ba Chẽ",
                        "code": 6970,
                        "codename": "thi_tran_ba_che",
                        "division_type": "thị trấn",
                        "short_codename": "ba_che"
                    },
                    {
                        "name": "Xã Thanh Sơn",
                        "code": 6973,
                        "codename": "xa_thanh_son",
                        "division_type": "xã",
                        "short_codename": "thanh_son"
                    },
                    {
                        "name": "Xã Thanh Lâm",
                        "code": 6976,
                        "codename": "xa_thanh_lam",
                        "division_type": "xã",
                        "short_codename": "thanh_lam"
                    },
                    {
                        "name": "Xã Đạp Thanh",
                        "code": 6979,
                        "codename": "xa_dap_thanh",
                        "division_type": "xã",
                        "short_codename": "dap_thanh"
                    },
                    {
                        "name": "Xã Nam Sơn",
                        "code": 6982,
                        "codename": "xa_nam_son",
                        "division_type": "xã",
                        "short_codename": "nam_son"
                    },
                    {
                        "name": "Xã Lương Mông",
                        "code": 6985,
                        "codename": "xa_luong_mong",
                        "division_type": "xã",
                        "short_codename": "luong_mong"
                    },
                    {
                        "name": "Xã Đồn Đạc",
                        "code": 6988,
                        "codename": "xa_don_dac",
                        "division_type": "xã",
                        "short_codename": "don_dac"
                    },
                    {
                        "name": "Xã Minh Cầm",
                        "code": 6991,
                        "codename": "xa_minh_cam",
                        "division_type": "xã",
                        "short_codename": "minh_cam"
                    }
                ]
            },
            {
                "name": "Huyện Vân Đồn",
                "code": 203,
                "codename": "huyen_van_don",
                "division_type": "huyện",
                "short_codename": "van_don",
                "wards": [
                    {
                        "name": "Thị trấn Cái Rồng",
                        "code": 6994,
                        "codename": "thi_tran_cai_rong",
                        "division_type": "thị trấn",
                        "short_codename": "cai_rong"
                    },
                    {
                        "name": "Xã Đài Xuyên",
                        "code": 6997,
                        "codename": "xa_dai_xuyen",
                        "division_type": "xã",
                        "short_codename": "dai_xuyen"
                    },
                    {
                        "name": "Xã Bình Dân",
                        "code": 7000,
                        "codename": "xa_binh_dan",
                        "division_type": "xã",
                        "short_codename": "binh_dan"
                    },
                    {
                        "name": "Xã Vạn Yên",
                        "code": 7003,
                        "codename": "xa_van_yen",
                        "division_type": "xã",
                        "short_codename": "van_yen"
                    },
                    {
                        "name": "Xã Minh Châu",
                        "code": 7006,
                        "codename": "xa_minh_chau",
                        "division_type": "xã",
                        "short_codename": "minh_chau"
                    },
                    {
                        "name": "Xã Đoàn Kết",
                        "code": 7009,
                        "codename": "xa_doan_ket",
                        "division_type": "xã",
                        "short_codename": "doan_ket"
                    },
                    {
                        "name": "Xã Hạ Long",
                        "code": 7012,
                        "codename": "xa_ha_long",
                        "division_type": "xã",
                        "short_codename": "ha_long"
                    },
                    {
                        "name": "Xã Đông Xá",
                        "code": 7015,
                        "codename": "xa_dong_xa",
                        "division_type": "xã",
                        "short_codename": "dong_xa"
                    },
                    {
                        "name": "Xã Bản Sen",
                        "code": 7018,
                        "codename": "xa_ban_sen",
                        "division_type": "xã",
                        "short_codename": "ban_sen"
                    },
                    {
                        "name": "Xã Thắng Lợi",
                        "code": 7021,
                        "codename": "xa_thang_loi",
                        "division_type": "xã",
                        "short_codename": "thang_loi"
                    },
                    {
                        "name": "Xã Quan Lạn",
                        "code": 7024,
                        "codename": "xa_quan_lan",
                        "division_type": "xã",
                        "short_codename": "quan_lan"
                    },
                    {
                        "name": "Xã Ngọc Vừng",
                        "code": 7027,
                        "codename": "xa_ngoc_vung",
                        "division_type": "xã",
                        "short_codename": "ngoc_vung"
                    }
                ]
            },
            {
                "name": "Thị xã Đông Triều",
                "code": 205,
                "codename": "thi_xa_dong_trieu",
                "division_type": "thị xã",
                "short_codename": "dong_trieu",
                "wards": [
                    {
                        "name": "Phường Mạo Khê",
                        "code": 7069,
                        "codename": "phuong_mao_khe",
                        "division_type": "phường",
                        "short_codename": "mao_khe"
                    },
                    {
                        "name": "Phường Đông Triều",
                        "code": 7072,
                        "codename": "phuong_dong_trieu",
                        "division_type": "phường",
                        "short_codename": "dong_trieu"
                    },
                    {
                        "name": "Xã An Sinh",
                        "code": 7075,
                        "codename": "xa_an_sinh",
                        "division_type": "xã",
                        "short_codename": "an_sinh"
                    },
                    {
                        "name": "Xã Tràng Lương",
                        "code": 7078,
                        "codename": "xa_trang_luong",
                        "division_type": "xã",
                        "short_codename": "trang_luong"
                    },
                    {
                        "name": "Xã Bình Khê",
                        "code": 7081,
                        "codename": "xa_binh_khe",
                        "division_type": "xã",
                        "short_codename": "binh_khe"
                    },
                    {
                        "name": "Xã Việt Dân",
                        "code": 7084,
                        "codename": "xa_viet_dan",
                        "division_type": "xã",
                        "short_codename": "viet_dan"
                    },
                    {
                        "name": "Xã Tân Việt",
                        "code": 7087,
                        "codename": "xa_tan_viet",
                        "division_type": "xã",
                        "short_codename": "tan_viet"
                    },
                    {
                        "name": "Xã Bình Dương",
                        "code": 7090,
                        "codename": "xa_binh_duong",
                        "division_type": "xã",
                        "short_codename": "binh_duong"
                    },
                    {
                        "name": "Phường Đức Chính",
                        "code": 7093,
                        "codename": "phuong_duc_chinh",
                        "division_type": "phường",
                        "short_codename": "duc_chinh"
                    },
                    {
                        "name": "Phường Tràng An",
                        "code": 7096,
                        "codename": "phuong_trang_an",
                        "division_type": "phường",
                        "short_codename": "trang_an"
                    },
                    {
                        "name": "Xã Nguyễn Huệ",
                        "code": 7099,
                        "codename": "xa_nguyen_hue",
                        "division_type": "xã",
                        "short_codename": "nguyen_hue"
                    },
                    {
                        "name": "Xã Thủy An",
                        "code": 7102,
                        "codename": "xa_thuy_an",
                        "division_type": "xã",
                        "short_codename": "thuy_an"
                    },
                    {
                        "name": "Phường Xuân Sơn",
                        "code": 7105,
                        "codename": "phuong_xuan_son",
                        "division_type": "phường",
                        "short_codename": "xuan_son"
                    },
                    {
                        "name": "Xã Hồng Thái Tây",
                        "code": 7108,
                        "codename": "xa_hong_thai_tay",
                        "division_type": "xã",
                        "short_codename": "hong_thai_tay"
                    },
                    {
                        "name": "Xã Hồng Thái Đông",
                        "code": 7111,
                        "codename": "xa_hong_thai_dong",
                        "division_type": "xã",
                        "short_codename": "hong_thai_dong"
                    },
                    {
                        "name": "Phường Hoàng Quế",
                        "code": 7114,
                        "codename": "phuong_hoang_que",
                        "division_type": "phường",
                        "short_codename": "hoang_que"
                    },
                    {
                        "name": "Phường Yên Thọ",
                        "code": 7117,
                        "codename": "phuong_yen_tho",
                        "division_type": "phường",
                        "short_codename": "yen_tho"
                    },
                    {
                        "name": "Phường Hồng Phong",
                        "code": 7120,
                        "codename": "phuong_hong_phong",
                        "division_type": "phường",
                        "short_codename": "hong_phong"
                    },
                    {
                        "name": "Phường Kim Sơn",
                        "code": 7123,
                        "codename": "phuong_kim_son",
                        "division_type": "phường",
                        "short_codename": "kim_son"
                    },
                    {
                        "name": "Phường Hưng Đạo",
                        "code": 7126,
                        "codename": "phuong_hung_dao",
                        "division_type": "phường",
                        "short_codename": "hung_dao"
                    },
                    {
                        "name": "Xã Yên Đức",
                        "code": 7129,
                        "codename": "xa_yen_duc",
                        "division_type": "xã",
                        "short_codename": "yen_duc"
                    }
                ]
            },
            {
                "name": "Thị xã Quảng Yên",
                "code": 206,
                "codename": "thi_xa_quang_yen",
                "division_type": "thị xã",
                "short_codename": "quang_yen",
                "wards": [
                    {
                        "name": "Phường Quảng Yên",
                        "code": 7132,
                        "codename": "phuong_quang_yen",
                        "division_type": "phường",
                        "short_codename": "quang_yen"
                    },
                    {
                        "name": "Phường Đông Mai",
                        "code": 7135,
                        "codename": "phuong_dong_mai",
                        "division_type": "phường",
                        "short_codename": "dong_mai"
                    },
                    {
                        "name": "Phường Minh Thành",
                        "code": 7138,
                        "codename": "phuong_minh_thanh",
                        "division_type": "phường",
                        "short_codename": "minh_thanh"
                    },
                    {
                        "name": "Xã Sông Khoai",
                        "code": 7144,
                        "codename": "xa_song_khoai",
                        "division_type": "xã",
                        "short_codename": "song_khoai"
                    },
                    {
                        "name": "Xã Hiệp Hòa",
                        "code": 7147,
                        "codename": "xa_hiep_hoa",
                        "division_type": "xã",
                        "short_codename": "hiep_hoa"
                    },
                    {
                        "name": "Phường Cộng Hòa",
                        "code": 7150,
                        "codename": "phuong_cong_hoa",
                        "division_type": "phường",
                        "short_codename": "cong_hoa"
                    },
                    {
                        "name": "Xã Tiền An",
                        "code": 7153,
                        "codename": "xa_tien_an",
                        "division_type": "xã",
                        "short_codename": "tien_an"
                    },
                    {
                        "name": "Xã Hoàng Tân",
                        "code": 7156,
                        "codename": "xa_hoang_tan",
                        "division_type": "xã",
                        "short_codename": "hoang_tan"
                    },
                    {
                        "name": "Phường Tân An",
                        "code": 7159,
                        "codename": "phuong_tan_an",
                        "division_type": "phường",
                        "short_codename": "tan_an"
                    },
                    {
                        "name": "Phường Yên Giang",
                        "code": 7162,
                        "codename": "phuong_yen_giang",
                        "division_type": "phường",
                        "short_codename": "yen_giang"
                    },
                    {
                        "name": "Phường Nam Hoà",
                        "code": 7165,
                        "codename": "phuong_nam_hoa",
                        "division_type": "phường",
                        "short_codename": "nam_hoa"
                    },
                    {
                        "name": "Phường Hà An",
                        "code": 7168,
                        "codename": "phuong_ha_an",
                        "division_type": "phường",
                        "short_codename": "ha_an"
                    },
                    {
                        "name": "Xã Cẩm La",
                        "code": 7171,
                        "codename": "xa_cam_la",
                        "division_type": "xã",
                        "short_codename": "cam_la"
                    },
                    {
                        "name": "Phường Phong Hải",
                        "code": 7174,
                        "codename": "phuong_phong_hai",
                        "division_type": "phường",
                        "short_codename": "phong_hai"
                    },
                    {
                        "name": "Phường Yên Hải",
                        "code": 7177,
                        "codename": "phuong_yen_hai",
                        "division_type": "phường",
                        "short_codename": "yen_hai"
                    },
                    {
                        "name": "Xã Liên Hòa",
                        "code": 7180,
                        "codename": "xa_lien_hoa",
                        "division_type": "xã",
                        "short_codename": "lien_hoa"
                    },
                    {
                        "name": "Phường Phong Cốc",
                        "code": 7183,
                        "codename": "phuong_phong_coc",
                        "division_type": "phường",
                        "short_codename": "phong_coc"
                    },
                    {
                        "name": "Xã Liên Vị",
                        "code": 7186,
                        "codename": "xa_lien_vi",
                        "division_type": "xã",
                        "short_codename": "lien_vi"
                    },
                    {
                        "name": "Xã Tiền Phong",
                        "code": 7189,
                        "codename": "xa_tien_phong",
                        "division_type": "xã",
                        "short_codename": "tien_phong"
                    }
                ]
            },
            {
                "name": "Huyện Cô Tô",
                "code": 207,
                "codename": "huyen_co_to",
                "division_type": "huyện",
                "short_codename": "co_to",
                "wards": [
                    {
                        "name": "Thị trấn Cô Tô",
                        "code": 7192,
                        "codename": "thi_tran_co_to",
                        "division_type": "thị trấn",
                        "short_codename": "co_to"
                    },
                    {
                        "name": "Xã Đồng Tiến",
                        "code": 7195,
                        "codename": "xa_dong_tien",
                        "division_type": "xã",
                        "short_codename": "dong_tien"
                    },
                    {
                        "name": "Xã Thanh Lân",
                        "code": 7198,
                        "codename": "xa_thanh_lan",
                        "division_type": "xã",
                        "short_codename": "thanh_lan"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Bắc Giang",
        "code": 24,
        "codename": "tinh_bac_giang",
        "division_type": "tỉnh",
        "phone_code": 204,
        "districts": [
            {
                "name": "Thành phố Bắc Giang",
                "code": 213,
                "codename": "thanh_pho_bac_giang",
                "division_type": "thành phố",
                "short_codename": "bac_giang",
                "wards": [
                    {
                        "name": "Phường Thọ Xương",
                        "code": 7201,
                        "codename": "phuong_tho_xuong",
                        "division_type": "phường",
                        "short_codename": "tho_xuong"
                    },
                    {
                        "name": "Phường Trần Nguyên Hãn",
                        "code": 7204,
                        "codename": "phuong_tran_nguyen_han",
                        "division_type": "phường",
                        "short_codename": "tran_nguyen_han"
                    },
                    {
                        "name": "Phường Ngô Quyền",
                        "code": 7207,
                        "codename": "phuong_ngo_quyen",
                        "division_type": "phường",
                        "short_codename": "ngo_quyen"
                    },
                    {
                        "name": "Phường Hoàng Văn Thụ",
                        "code": 7210,
                        "codename": "phuong_hoang_van_thu",
                        "division_type": "phường",
                        "short_codename": "hoang_van_thu"
                    },
                    {
                        "name": "Phường Trần Phú",
                        "code": 7213,
                        "codename": "phuong_tran_phu",
                        "division_type": "phường",
                        "short_codename": "tran_phu"
                    },
                    {
                        "name": "Phường Mỹ Độ",
                        "code": 7216,
                        "codename": "phuong_my_do",
                        "division_type": "phường",
                        "short_codename": "my_do"
                    },
                    {
                        "name": "Phường Lê Lợi",
                        "code": 7219,
                        "codename": "phuong_le_loi",
                        "division_type": "phường",
                        "short_codename": "le_loi"
                    },
                    {
                        "name": "Xã Song Mai",
                        "code": 7222,
                        "codename": "xa_song_mai",
                        "division_type": "xã",
                        "short_codename": "song_mai"
                    },
                    {
                        "name": "Phường Xương Giang",
                        "code": 7225,
                        "codename": "phuong_xuong_giang",
                        "division_type": "phường",
                        "short_codename": "xuong_giang"
                    },
                    {
                        "name": "Phường Đa Mai",
                        "code": 7228,
                        "codename": "phuong_da_mai",
                        "division_type": "phường",
                        "short_codename": "da_mai"
                    },
                    {
                        "name": "Phường Dĩnh Kế",
                        "code": 7231,
                        "codename": "phuong_dinh_ke",
                        "division_type": "phường",
                        "short_codename": "dinh_ke"
                    },
                    {
                        "name": "Xã Dĩnh Trì",
                        "code": 7441,
                        "codename": "xa_dinh_tri",
                        "division_type": "xã",
                        "short_codename": "dinh_tri"
                    },
                    {
                        "name": "Xã Tân Mỹ",
                        "code": 7687,
                        "codename": "xa_tan_my",
                        "division_type": "xã",
                        "short_codename": "tan_my"
                    },
                    {
                        "name": "Xã Đồng Sơn",
                        "code": 7696,
                        "codename": "xa_dong_son",
                        "division_type": "xã",
                        "short_codename": "dong_son"
                    },
                    {
                        "name": "Xã Tân Tiến",
                        "code": 7699,
                        "codename": "xa_tan_tien",
                        "division_type": "xã",
                        "short_codename": "tan_tien"
                    },
                    {
                        "name": "Xã Song Khê",
                        "code": 7705,
                        "codename": "xa_song_khe",
                        "division_type": "xã",
                        "short_codename": "song_khe"
                    }
                ]
            },
            {
                "name": "Huyện Yên Thế",
                "code": 215,
                "codename": "huyen_yen_the",
                "division_type": "huyện",
                "short_codename": "yen_the",
                "wards": [
                    {
                        "name": "Xã Đồng Tiến",
                        "code": 7243,
                        "codename": "xa_dong_tien",
                        "division_type": "xã",
                        "short_codename": "dong_tien"
                    },
                    {
                        "name": "Xã Canh Nậu",
                        "code": 7246,
                        "codename": "xa_canh_nau",
                        "division_type": "xã",
                        "short_codename": "canh_nau"
                    },
                    {
                        "name": "Xã Xuân Lương",
                        "code": 7249,
                        "codename": "xa_xuan_luong",
                        "division_type": "xã",
                        "short_codename": "xuan_luong"
                    },
                    {
                        "name": "Xã Tam Tiến",
                        "code": 7252,
                        "codename": "xa_tam_tien",
                        "division_type": "xã",
                        "short_codename": "tam_tien"
                    },
                    {
                        "name": "Xã Đồng Vương",
                        "code": 7255,
                        "codename": "xa_dong_vuong",
                        "division_type": "xã",
                        "short_codename": "dong_vuong"
                    },
                    {
                        "name": "Xã Đồng Hưu",
                        "code": 7258,
                        "codename": "xa_dong_huu",
                        "division_type": "xã",
                        "short_codename": "dong_huu"
                    },
                    {
                        "name": "Xã Đồng Tâm",
                        "code": 7260,
                        "codename": "xa_dong_tam",
                        "division_type": "xã",
                        "short_codename": "dong_tam"
                    },
                    {
                        "name": "Xã Tam Hiệp",
                        "code": 7261,
                        "codename": "xa_tam_hiep",
                        "division_type": "xã",
                        "short_codename": "tam_hiep"
                    },
                    {
                        "name": "Xã Tiến Thắng",
                        "code": 7264,
                        "codename": "xa_tien_thang",
                        "division_type": "xã",
                        "short_codename": "tien_thang"
                    },
                    {
                        "name": "Xã Hồng Kỳ",
                        "code": 7267,
                        "codename": "xa_hong_ky",
                        "division_type": "xã",
                        "short_codename": "hong_ky"
                    },
                    {
                        "name": "Xã Đồng Lạc",
                        "code": 7270,
                        "codename": "xa_dong_lac",
                        "division_type": "xã",
                        "short_codename": "dong_lac"
                    },
                    {
                        "name": "Xã Đông Sơn",
                        "code": 7273,
                        "codename": "xa_dong_son",
                        "division_type": "xã",
                        "short_codename": "dong_son"
                    },
                    {
                        "name": "Xã Tân Hiệp",
                        "code": 7276,
                        "codename": "xa_tan_hiep",
                        "division_type": "xã",
                        "short_codename": "tan_hiep"
                    },
                    {
                        "name": "Xã Hương Vĩ",
                        "code": 7279,
                        "codename": "xa_huong_vi",
                        "division_type": "xã",
                        "short_codename": "huong_vi"
                    },
                    {
                        "name": "Xã Đồng Kỳ",
                        "code": 7282,
                        "codename": "xa_dong_ky",
                        "division_type": "xã",
                        "short_codename": "dong_ky"
                    },
                    {
                        "name": "Xã An Thượng",
                        "code": 7285,
                        "codename": "xa_an_thuong",
                        "division_type": "xã",
                        "short_codename": "an_thuong"
                    },
                    {
                        "name": "Thị trấn Phồn Xương",
                        "code": 7288,
                        "codename": "thi_tran_phon_xuong",
                        "division_type": "thị trấn",
                        "short_codename": "phon_xuong"
                    },
                    {
                        "name": "Xã Tân Sỏi",
                        "code": 7291,
                        "codename": "xa_tan_soi",
                        "division_type": "xã",
                        "short_codename": "tan_soi"
                    },
                    {
                        "name": "Thị trấn Bố Hạ",
                        "code": 7294,
                        "codename": "thi_tran_bo_ha",
                        "division_type": "thị trấn",
                        "short_codename": "bo_ha"
                    }
                ]
            },
            {
                "name": "Huyện Tân Yên",
                "code": 216,
                "codename": "huyen_tan_yen",
                "division_type": "huyện",
                "short_codename": "tan_yen",
                "wards": [
                    {
                        "name": "Xã Lan Giới",
                        "code": 7303,
                        "codename": "xa_lan_gioi",
                        "division_type": "xã",
                        "short_codename": "lan_gioi"
                    },
                    {
                        "name": "Thị trấn Nhã Nam",
                        "code": 7306,
                        "codename": "thi_tran_nha_nam",
                        "division_type": "thị trấn",
                        "short_codename": "nha_nam"
                    },
                    {
                        "name": "Xã Tân Trung",
                        "code": 7309,
                        "codename": "xa_tan_trung",
                        "division_type": "xã",
                        "short_codename": "tan_trung"
                    },
                    {
                        "name": "Xã Đại Hóa",
                        "code": 7312,
                        "codename": "xa_dai_hoa",
                        "division_type": "xã",
                        "short_codename": "dai_hoa"
                    },
                    {
                        "name": "Xã Quang Tiến",
                        "code": 7315,
                        "codename": "xa_quang_tien",
                        "division_type": "xã",
                        "short_codename": "quang_tien"
                    },
                    {
                        "name": "Xã Phúc Sơn",
                        "code": 7318,
                        "codename": "xa_phuc_son",
                        "division_type": "xã",
                        "short_codename": "phuc_son"
                    },
                    {
                        "name": "Xã An Dương",
                        "code": 7321,
                        "codename": "xa_an_duong",
                        "division_type": "xã",
                        "short_codename": "an_duong"
                    },
                    {
                        "name": "Xã Phúc Hòa",
                        "code": 7324,
                        "codename": "xa_phuc_hoa",
                        "division_type": "xã",
                        "short_codename": "phuc_hoa"
                    },
                    {
                        "name": "Xã Liên Sơn",
                        "code": 7327,
                        "codename": "xa_lien_son",
                        "division_type": "xã",
                        "short_codename": "lien_son"
                    },
                    {
                        "name": "Xã Hợp Đức",
                        "code": 7330,
                        "codename": "xa_hop_duc",
                        "division_type": "xã",
                        "short_codename": "hop_duc"
                    },
                    {
                        "name": "Xã Lam Cốt",
                        "code": 7333,
                        "codename": "xa_lam_cot",
                        "division_type": "xã",
                        "short_codename": "lam_cot"
                    },
                    {
                        "name": "Xã Cao Xá",
                        "code": 7336,
                        "codename": "xa_cao_xa",
                        "division_type": "xã",
                        "short_codename": "cao_xa"
                    },
                    {
                        "name": "Thị trấn Cao Thượng",
                        "code": 7339,
                        "codename": "thi_tran_cao_thuong",
                        "division_type": "thị trấn",
                        "short_codename": "cao_thuong"
                    },
                    {
                        "name": "Xã Việt Ngọc",
                        "code": 7342,
                        "codename": "xa_viet_ngoc",
                        "division_type": "xã",
                        "short_codename": "viet_ngoc"
                    },
                    {
                        "name": "Xã Song Vân",
                        "code": 7345,
                        "codename": "xa_song_van",
                        "division_type": "xã",
                        "short_codename": "song_van"
                    },
                    {
                        "name": "Xã Ngọc Châu",
                        "code": 7348,
                        "codename": "xa_ngoc_chau",
                        "division_type": "xã",
                        "short_codename": "ngoc_chau"
                    },
                    {
                        "name": "Xã Ngọc Vân",
                        "code": 7351,
                        "codename": "xa_ngoc_van",
                        "division_type": "xã",
                        "short_codename": "ngoc_van"
                    },
                    {
                        "name": "Xã Việt Lập",
                        "code": 7354,
                        "codename": "xa_viet_lap",
                        "division_type": "xã",
                        "short_codename": "viet_lap"
                    },
                    {
                        "name": "Xã Liên Chung",
                        "code": 7357,
                        "codename": "xa_lien_chung",
                        "division_type": "xã",
                        "short_codename": "lien_chung"
                    },
                    {
                        "name": "Xã Ngọc Thiện",
                        "code": 7360,
                        "codename": "xa_ngoc_thien",
                        "division_type": "xã",
                        "short_codename": "ngoc_thien"
                    },
                    {
                        "name": "Xã Ngọc Lý",
                        "code": 7363,
                        "codename": "xa_ngoc_ly",
                        "division_type": "xã",
                        "short_codename": "ngoc_ly"
                    },
                    {
                        "name": "Xã Quế Nham",
                        "code": 7366,
                        "codename": "xa_que_nham",
                        "division_type": "xã",
                        "short_codename": "que_nham"
                    }
                ]
            },
            {
                "name": "Huyện Lạng Giang",
                "code": 217,
                "codename": "huyen_lang_giang",
                "division_type": "huyện",
                "short_codename": "lang_giang",
                "wards": [
                    {
                        "name": "Thị trấn Vôi",
                        "code": 7375,
                        "codename": "thi_tran_voi",
                        "division_type": "thị trấn",
                        "short_codename": "voi"
                    },
                    {
                        "name": "Xã Nghĩa Hòa",
                        "code": 7378,
                        "codename": "xa_nghia_hoa",
                        "division_type": "xã",
                        "short_codename": "nghia_hoa"
                    },
                    {
                        "name": "Xã Nghĩa Hưng",
                        "code": 7381,
                        "codename": "xa_nghia_hung",
                        "division_type": "xã",
                        "short_codename": "nghia_hung"
                    },
                    {
                        "name": "Xã Quang Thịnh",
                        "code": 7384,
                        "codename": "xa_quang_thinh",
                        "division_type": "xã",
                        "short_codename": "quang_thinh"
                    },
                    {
                        "name": "Xã Hương Sơn",
                        "code": 7387,
                        "codename": "xa_huong_son",
                        "division_type": "xã",
                        "short_codename": "huong_son"
                    },
                    {
                        "name": "Xã Đào Mỹ",
                        "code": 7390,
                        "codename": "xa_dao_my",
                        "division_type": "xã",
                        "short_codename": "dao_my"
                    },
                    {
                        "name": "Xã Tiên Lục",
                        "code": 7393,
                        "codename": "xa_tien_luc",
                        "division_type": "xã",
                        "short_codename": "tien_luc"
                    },
                    {
                        "name": "Xã An Hà",
                        "code": 7396,
                        "codename": "xa_an_ha",
                        "division_type": "xã",
                        "short_codename": "an_ha"
                    },
                    {
                        "name": "Thị trấn Kép",
                        "code": 7399,
                        "codename": "thi_tran_kep",
                        "division_type": "thị trấn",
                        "short_codename": "kep"
                    },
                    {
                        "name": "Xã Mỹ Hà",
                        "code": 7402,
                        "codename": "xa_my_ha",
                        "division_type": "xã",
                        "short_codename": "my_ha"
                    },
                    {
                        "name": "Xã Hương Lạc",
                        "code": 7405,
                        "codename": "xa_huong_lac",
                        "division_type": "xã",
                        "short_codename": "huong_lac"
                    },
                    {
                        "name": "Xã Dương Đức",
                        "code": 7408,
                        "codename": "xa_duong_duc",
                        "division_type": "xã",
                        "short_codename": "duong_duc"
                    },
                    {
                        "name": "Xã Tân Thanh",
                        "code": 7411,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Xã Yên Mỹ",
                        "code": 7414,
                        "codename": "xa_yen_my",
                        "division_type": "xã",
                        "short_codename": "yen_my"
                    },
                    {
                        "name": "Xã Tân Hưng",
                        "code": 7417,
                        "codename": "xa_tan_hung",
                        "division_type": "xã",
                        "short_codename": "tan_hung"
                    },
                    {
                        "name": "Xã Mỹ Thái",
                        "code": 7420,
                        "codename": "xa_my_thai",
                        "division_type": "xã",
                        "short_codename": "my_thai"
                    },
                    {
                        "name": "Xã Xương Lâm",
                        "code": 7426,
                        "codename": "xa_xuong_lam",
                        "division_type": "xã",
                        "short_codename": "xuong_lam"
                    },
                    {
                        "name": "Xã Xuân Hương",
                        "code": 7429,
                        "codename": "xa_xuan_huong",
                        "division_type": "xã",
                        "short_codename": "xuan_huong"
                    },
                    {
                        "name": "Xã Tân Dĩnh",
                        "code": 7432,
                        "codename": "xa_tan_dinh",
                        "division_type": "xã",
                        "short_codename": "tan_dinh"
                    },
                    {
                        "name": "Xã Đại Lâm",
                        "code": 7435,
                        "codename": "xa_dai_lam",
                        "division_type": "xã",
                        "short_codename": "dai_lam"
                    },
                    {
                        "name": "Xã Thái Đào",
                        "code": 7438,
                        "codename": "xa_thai_dao",
                        "division_type": "xã",
                        "short_codename": "thai_dao"
                    }
                ]
            },
            {
                "name": "Huyện Lục Nam",
                "code": 218,
                "codename": "huyen_luc_nam",
                "division_type": "huyện",
                "short_codename": "luc_nam",
                "wards": [
                    {
                        "name": "Thị trấn Đồi Ngô",
                        "code": 7444,
                        "codename": "thi_tran_doi_ngo",
                        "division_type": "thị trấn",
                        "short_codename": "doi_ngo"
                    },
                    {
                        "name": "Xã Đông Hưng",
                        "code": 7450,
                        "codename": "xa_dong_hung",
                        "division_type": "xã",
                        "short_codename": "dong_hung"
                    },
                    {
                        "name": "Xã Đông Phú",
                        "code": 7453,
                        "codename": "xa_dong_phu",
                        "division_type": "xã",
                        "short_codename": "dong_phu"
                    },
                    {
                        "name": "Xã Tam Dị",
                        "code": 7456,
                        "codename": "xa_tam_di",
                        "division_type": "xã",
                        "short_codename": "tam_di"
                    },
                    {
                        "name": "Xã Bảo Sơn",
                        "code": 7459,
                        "codename": "xa_bao_son",
                        "division_type": "xã",
                        "short_codename": "bao_son"
                    },
                    {
                        "name": "Xã Bảo Đài",
                        "code": 7462,
                        "codename": "xa_bao_dai",
                        "division_type": "xã",
                        "short_codename": "bao_dai"
                    },
                    {
                        "name": "Xã Thanh Lâm",
                        "code": 7465,
                        "codename": "xa_thanh_lam",
                        "division_type": "xã",
                        "short_codename": "thanh_lam"
                    },
                    {
                        "name": "Xã Tiên Nha",
                        "code": 7468,
                        "codename": "xa_tien_nha",
                        "division_type": "xã",
                        "short_codename": "tien_nha"
                    },
                    {
                        "name": "Xã Trường Giang",
                        "code": 7471,
                        "codename": "xa_truong_giang",
                        "division_type": "xã",
                        "short_codename": "truong_giang"
                    },
                    {
                        "name": "Xã Phương Sơn",
                        "code": 7477,
                        "codename": "xa_phuong_son",
                        "division_type": "xã",
                        "short_codename": "phuong_son"
                    },
                    {
                        "name": "Xã Chu Điện",
                        "code": 7480,
                        "codename": "xa_chu_dien",
                        "division_type": "xã",
                        "short_codename": "chu_dien"
                    },
                    {
                        "name": "Xã Cương Sơn",
                        "code": 7483,
                        "codename": "xa_cuong_son",
                        "division_type": "xã",
                        "short_codename": "cuong_son"
                    },
                    {
                        "name": "Xã Nghĩa Phương",
                        "code": 7486,
                        "codename": "xa_nghia_phuong",
                        "division_type": "xã",
                        "short_codename": "nghia_phuong"
                    },
                    {
                        "name": "Xã Vô Tranh",
                        "code": 7489,
                        "codename": "xa_vo_tranh",
                        "division_type": "xã",
                        "short_codename": "vo_tranh"
                    },
                    {
                        "name": "Xã Bình Sơn",
                        "code": 7492,
                        "codename": "xa_binh_son",
                        "division_type": "xã",
                        "short_codename": "binh_son"
                    },
                    {
                        "name": "Xã Lan Mẫu",
                        "code": 7495,
                        "codename": "xa_lan_mau",
                        "division_type": "xã",
                        "short_codename": "lan_mau"
                    },
                    {
                        "name": "Xã Yên Sơn",
                        "code": 7498,
                        "codename": "xa_yen_son",
                        "division_type": "xã",
                        "short_codename": "yen_son"
                    },
                    {
                        "name": "Xã Khám Lạng",
                        "code": 7501,
                        "codename": "xa_kham_lang",
                        "division_type": "xã",
                        "short_codename": "kham_lang"
                    },
                    {
                        "name": "Xã Huyền Sơn",
                        "code": 7504,
                        "codename": "xa_huyen_son",
                        "division_type": "xã",
                        "short_codename": "huyen_son"
                    },
                    {
                        "name": "Xã Trường Sơn",
                        "code": 7507,
                        "codename": "xa_truong_son",
                        "division_type": "xã",
                        "short_codename": "truong_son"
                    },
                    {
                        "name": "Xã Lục Sơn",
                        "code": 7510,
                        "codename": "xa_luc_son",
                        "division_type": "xã",
                        "short_codename": "luc_son"
                    },
                    {
                        "name": "Xã Bắc Lũng",
                        "code": 7513,
                        "codename": "xa_bac_lung",
                        "division_type": "xã",
                        "short_codename": "bac_lung"
                    },
                    {
                        "name": "Xã Vũ Xá",
                        "code": 7516,
                        "codename": "xa_vu_xa",
                        "division_type": "xã",
                        "short_codename": "vu_xa"
                    },
                    {
                        "name": "Xã Cẩm Lý",
                        "code": 7519,
                        "codename": "xa_cam_ly",
                        "division_type": "xã",
                        "short_codename": "cam_ly"
                    },
                    {
                        "name": "Xã Đan Hội",
                        "code": 7522,
                        "codename": "xa_dan_hoi",
                        "division_type": "xã",
                        "short_codename": "dan_hoi"
                    }
                ]
            },
            {
                "name": "Huyện Lục Ngạn",
                "code": 219,
                "codename": "huyen_luc_ngan",
                "division_type": "huyện",
                "short_codename": "luc_ngan",
                "wards": [
                    {
                        "name": "Thị trấn Chũ",
                        "code": 7525,
                        "codename": "thi_tran_chu",
                        "division_type": "thị trấn",
                        "short_codename": "chu"
                    },
                    {
                        "name": "Xã Cấm Sơn",
                        "code": 7528,
                        "codename": "xa_cam_son",
                        "division_type": "xã",
                        "short_codename": "cam_son"
                    },
                    {
                        "name": "Xã Tân Sơn",
                        "code": 7531,
                        "codename": "xa_tan_son",
                        "division_type": "xã",
                        "short_codename": "tan_son"
                    },
                    {
                        "name": "Xã Phong Minh",
                        "code": 7534,
                        "codename": "xa_phong_minh",
                        "division_type": "xã",
                        "short_codename": "phong_minh"
                    },
                    {
                        "name": "Xã Phong Vân",
                        "code": 7537,
                        "codename": "xa_phong_van",
                        "division_type": "xã",
                        "short_codename": "phong_van"
                    },
                    {
                        "name": "Xã Xa Lý",
                        "code": 7540,
                        "codename": "xa_xa_ly",
                        "division_type": "xã",
                        "short_codename": "xa_ly"
                    },
                    {
                        "name": "Xã Hộ Đáp",
                        "code": 7543,
                        "codename": "xa_ho_dap",
                        "division_type": "xã",
                        "short_codename": "ho_dap"
                    },
                    {
                        "name": "Xã Sơn Hải",
                        "code": 7546,
                        "codename": "xa_son_hai",
                        "division_type": "xã",
                        "short_codename": "son_hai"
                    },
                    {
                        "name": "Xã Thanh Hải",
                        "code": 7549,
                        "codename": "xa_thanh_hai",
                        "division_type": "xã",
                        "short_codename": "thanh_hai"
                    },
                    {
                        "name": "Xã Kiên Lao",
                        "code": 7552,
                        "codename": "xa_kien_lao",
                        "division_type": "xã",
                        "short_codename": "kien_lao"
                    },
                    {
                        "name": "Xã Biên Sơn",
                        "code": 7555,
                        "codename": "xa_bien_son",
                        "division_type": "xã",
                        "short_codename": "bien_son"
                    },
                    {
                        "name": "Xã Kiên Thành",
                        "code": 7558,
                        "codename": "xa_kien_thanh",
                        "division_type": "xã",
                        "short_codename": "kien_thanh"
                    },
                    {
                        "name": "Xã Hồng Giang",
                        "code": 7561,
                        "codename": "xa_hong_giang",
                        "division_type": "xã",
                        "short_codename": "hong_giang"
                    },
                    {
                        "name": "Xã Kim Sơn",
                        "code": 7564,
                        "codename": "xa_kim_son",
                        "division_type": "xã",
                        "short_codename": "kim_son"
                    },
                    {
                        "name": "Xã Tân Hoa",
                        "code": 7567,
                        "codename": "xa_tan_hoa",
                        "division_type": "xã",
                        "short_codename": "tan_hoa"
                    },
                    {
                        "name": "Xã Giáp Sơn",
                        "code": 7570,
                        "codename": "xa_giap_son",
                        "division_type": "xã",
                        "short_codename": "giap_son"
                    },
                    {
                        "name": "Xã Biển Động",
                        "code": 7573,
                        "codename": "xa_bien_dong",
                        "division_type": "xã",
                        "short_codename": "bien_dong"
                    },
                    {
                        "name": "Xã Quý Sơn",
                        "code": 7576,
                        "codename": "xa_quy_son",
                        "division_type": "xã",
                        "short_codename": "quy_son"
                    },
                    {
                        "name": "Xã Trù Hựu",
                        "code": 7579,
                        "codename": "xa_tru_huu",
                        "division_type": "xã",
                        "short_codename": "tru_huu"
                    },
                    {
                        "name": "Xã Phì Điền",
                        "code": 7582,
                        "codename": "xa_phi_dien",
                        "division_type": "xã",
                        "short_codename": "phi_dien"
                    },
                    {
                        "name": "Xã Tân Quang",
                        "code": 7588,
                        "codename": "xa_tan_quang",
                        "division_type": "xã",
                        "short_codename": "tan_quang"
                    },
                    {
                        "name": "Xã Đồng Cốc",
                        "code": 7591,
                        "codename": "xa_dong_coc",
                        "division_type": "xã",
                        "short_codename": "dong_coc"
                    },
                    {
                        "name": "Xã Tân Lập",
                        "code": 7594,
                        "codename": "xa_tan_lap",
                        "division_type": "xã",
                        "short_codename": "tan_lap"
                    },
                    {
                        "name": "Xã Phú Nhuận",
                        "code": 7597,
                        "codename": "xa_phu_nhuan",
                        "division_type": "xã",
                        "short_codename": "phu_nhuan"
                    },
                    {
                        "name": "Xã Mỹ An",
                        "code": 7600,
                        "codename": "xa_my_an",
                        "division_type": "xã",
                        "short_codename": "my_an"
                    },
                    {
                        "name": "Xã Nam Dương",
                        "code": 7603,
                        "codename": "xa_nam_duong",
                        "division_type": "xã",
                        "short_codename": "nam_duong"
                    },
                    {
                        "name": "Xã Tân Mộc",
                        "code": 7606,
                        "codename": "xa_tan_moc",
                        "division_type": "xã",
                        "short_codename": "tan_moc"
                    },
                    {
                        "name": "Xã Đèo Gia",
                        "code": 7609,
                        "codename": "xa_deo_gia",
                        "division_type": "xã",
                        "short_codename": "deo_gia"
                    },
                    {
                        "name": "Xã Phượng Sơn",
                        "code": 7612,
                        "codename": "xa_phuong_son",
                        "division_type": "xã",
                        "short_codename": "phuong_son"
                    }
                ]
            },
            {
                "name": "Huyện Sơn Động",
                "code": 220,
                "codename": "huyen_son_dong",
                "division_type": "huyện",
                "short_codename": "son_dong",
                "wards": [
                    {
                        "name": "Thị trấn An Châu",
                        "code": 7615,
                        "codename": "thi_tran_an_chau",
                        "division_type": "thị trấn",
                        "short_codename": "an_chau"
                    },
                    {
                        "name": "Thị trấn Tây Yên Tử",
                        "code": 7616,
                        "codename": "thi_tran_tay_yen_tu",
                        "division_type": "thị trấn",
                        "short_codename": "tay_yen_tu"
                    },
                    {
                        "name": "Xã Vân Sơn",
                        "code": 7621,
                        "codename": "xa_van_son",
                        "division_type": "xã",
                        "short_codename": "van_son"
                    },
                    {
                        "name": "Xã Hữu Sản",
                        "code": 7624,
                        "codename": "xa_huu_san",
                        "division_type": "xã",
                        "short_codename": "huu_san"
                    },
                    {
                        "name": "Xã Đại Sơn",
                        "code": 7627,
                        "codename": "xa_dai_son",
                        "division_type": "xã",
                        "short_codename": "dai_son"
                    },
                    {
                        "name": "Xã Phúc Sơn",
                        "code": 7630,
                        "codename": "xa_phuc_son",
                        "division_type": "xã",
                        "short_codename": "phuc_son"
                    },
                    {
                        "name": "Xã Giáo Liêm",
                        "code": 7636,
                        "codename": "xa_giao_liem",
                        "division_type": "xã",
                        "short_codename": "giao_liem"
                    },
                    {
                        "name": "Xã Cẩm Đàn",
                        "code": 7642,
                        "codename": "xa_cam_dan",
                        "division_type": "xã",
                        "short_codename": "cam_dan"
                    },
                    {
                        "name": "Xã An Lạc",
                        "code": 7645,
                        "codename": "xa_an_lac",
                        "division_type": "xã",
                        "short_codename": "an_lac"
                    },
                    {
                        "name": "Xã Vĩnh An",
                        "code": 7648,
                        "codename": "xa_vinh_an",
                        "division_type": "xã",
                        "short_codename": "vinh_an"
                    },
                    {
                        "name": "Xã Yên Định",
                        "code": 7651,
                        "codename": "xa_yen_dinh",
                        "division_type": "xã",
                        "short_codename": "yen_dinh"
                    },
                    {
                        "name": "Xã Lệ Viễn",
                        "code": 7654,
                        "codename": "xa_le_vien",
                        "division_type": "xã",
                        "short_codename": "le_vien"
                    },
                    {
                        "name": "Xã An Bá",
                        "code": 7660,
                        "codename": "xa_an_ba",
                        "division_type": "xã",
                        "short_codename": "an_ba"
                    },
                    {
                        "name": "Xã Tuấn Đạo",
                        "code": 7663,
                        "codename": "xa_tuan_dao",
                        "division_type": "xã",
                        "short_codename": "tuan_dao"
                    },
                    {
                        "name": "Xã Dương Hưu",
                        "code": 7666,
                        "codename": "xa_duong_huu",
                        "division_type": "xã",
                        "short_codename": "duong_huu"
                    },
                    {
                        "name": "Xã Long Sơn",
                        "code": 7672,
                        "codename": "xa_long_son",
                        "division_type": "xã",
                        "short_codename": "long_son"
                    },
                    {
                        "name": "Xã Thanh Luận",
                        "code": 7678,
                        "codename": "xa_thanh_luan",
                        "division_type": "xã",
                        "short_codename": "thanh_luan"
                    }
                ]
            },
            {
                "name": "Huyện Yên Dũng",
                "code": 221,
                "codename": "huyen_yen_dung",
                "division_type": "huyện",
                "short_codename": "yen_dung",
                "wards": [
                    {
                        "name": "Thị trấn Nham Biền",
                        "code": 7681,
                        "codename": "thi_tran_nham_bien",
                        "division_type": "thị trấn",
                        "short_codename": "nham_bien"
                    },
                    {
                        "name": "Thị trấn Tân An",
                        "code": 7682,
                        "codename": "thi_tran_tan_an",
                        "division_type": "thị trấn",
                        "short_codename": "tan_an"
                    },
                    {
                        "name": "Xã Lão Hộ",
                        "code": 7684,
                        "codename": "xa_lao_ho",
                        "division_type": "xã",
                        "short_codename": "lao_ho"
                    },
                    {
                        "name": "Xã Hương Gián",
                        "code": 7690,
                        "codename": "xa_huong_gian",
                        "division_type": "xã",
                        "short_codename": "huong_gian"
                    },
                    {
                        "name": "Xã Quỳnh Sơn",
                        "code": 7702,
                        "codename": "xa_quynh_son",
                        "division_type": "xã",
                        "short_codename": "quynh_son"
                    },
                    {
                        "name": "Xã Nội Hoàng",
                        "code": 7708,
                        "codename": "xa_noi_hoang",
                        "division_type": "xã",
                        "short_codename": "noi_hoang"
                    },
                    {
                        "name": "Xã Tiền Phong",
                        "code": 7711,
                        "codename": "xa_tien_phong",
                        "division_type": "xã",
                        "short_codename": "tien_phong"
                    },
                    {
                        "name": "Xã Xuân Phú",
                        "code": 7714,
                        "codename": "xa_xuan_phu",
                        "division_type": "xã",
                        "short_codename": "xuan_phu"
                    },
                    {
                        "name": "Xã Tân Liễu",
                        "code": 7717,
                        "codename": "xa_tan_lieu",
                        "division_type": "xã",
                        "short_codename": "tan_lieu"
                    },
                    {
                        "name": "Xã Trí Yên",
                        "code": 7720,
                        "codename": "xa_tri_yen",
                        "division_type": "xã",
                        "short_codename": "tri_yen"
                    },
                    {
                        "name": "Xã Lãng Sơn",
                        "code": 7723,
                        "codename": "xa_lang_son",
                        "division_type": "xã",
                        "short_codename": "lang_son"
                    },
                    {
                        "name": "Xã Yên Lư",
                        "code": 7726,
                        "codename": "xa_yen_lu",
                        "division_type": "xã",
                        "short_codename": "yen_lu"
                    },
                    {
                        "name": "Xã Tiến Dũng",
                        "code": 7729,
                        "codename": "xa_tien_dung",
                        "division_type": "xã",
                        "short_codename": "tien_dung"
                    },
                    {
                        "name": "Xã Đức Giang",
                        "code": 7735,
                        "codename": "xa_duc_giang",
                        "division_type": "xã",
                        "short_codename": "duc_giang"
                    },
                    {
                        "name": "Xã Cảnh Thụy",
                        "code": 7738,
                        "codename": "xa_canh_thuy",
                        "division_type": "xã",
                        "short_codename": "canh_thuy"
                    },
                    {
                        "name": "Xã Tư Mại",
                        "code": 7741,
                        "codename": "xa_tu_mai",
                        "division_type": "xã",
                        "short_codename": "tu_mai"
                    },
                    {
                        "name": "Xã Đồng Việt",
                        "code": 7747,
                        "codename": "xa_dong_viet",
                        "division_type": "xã",
                        "short_codename": "dong_viet"
                    },
                    {
                        "name": "Xã Đồng Phúc",
                        "code": 7750,
                        "codename": "xa_dong_phuc",
                        "division_type": "xã",
                        "short_codename": "dong_phuc"
                    }
                ]
            },
            {
                "name": "Huyện Việt Yên",
                "code": 222,
                "codename": "huyen_viet_yen",
                "division_type": "huyện",
                "short_codename": "viet_yen",
                "wards": [
                    {
                        "name": "Xã Thượng Lan",
                        "code": 7759,
                        "codename": "xa_thuong_lan",
                        "division_type": "xã",
                        "short_codename": "thuong_lan"
                    },
                    {
                        "name": "Xã Việt Tiến",
                        "code": 7762,
                        "codename": "xa_viet_tien",
                        "division_type": "xã",
                        "short_codename": "viet_tien"
                    },
                    {
                        "name": "Xã Nghĩa Trung",
                        "code": 7765,
                        "codename": "xa_nghia_trung",
                        "division_type": "xã",
                        "short_codename": "nghia_trung"
                    },
                    {
                        "name": "Xã Minh Đức",
                        "code": 7768,
                        "codename": "xa_minh_duc",
                        "division_type": "xã",
                        "short_codename": "minh_duc"
                    },
                    {
                        "name": "Xã Hương Mai",
                        "code": 7771,
                        "codename": "xa_huong_mai",
                        "division_type": "xã",
                        "short_codename": "huong_mai"
                    },
                    {
                        "name": "Xã Tự Lạn",
                        "code": 7774,
                        "codename": "xa_tu_lan",
                        "division_type": "xã",
                        "short_codename": "tu_lan"
                    },
                    {
                        "name": "Thị trấn Bích Động",
                        "code": 7777,
                        "codename": "thi_tran_bich_dong",
                        "division_type": "thị trấn",
                        "short_codename": "bich_dong"
                    },
                    {
                        "name": "Xã Trung Sơn",
                        "code": 7780,
                        "codename": "xa_trung_son",
                        "division_type": "xã",
                        "short_codename": "trung_son"
                    },
                    {
                        "name": "Xã Hồng Thái",
                        "code": 7783,
                        "codename": "xa_hong_thai",
                        "division_type": "xã",
                        "short_codename": "hong_thai"
                    },
                    {
                        "name": "Xã Tiên Sơn",
                        "code": 7786,
                        "codename": "xa_tien_son",
                        "division_type": "xã",
                        "short_codename": "tien_son"
                    },
                    {
                        "name": "Xã Tăng Tiến",
                        "code": 7789,
                        "codename": "xa_tang_tien",
                        "division_type": "xã",
                        "short_codename": "tang_tien"
                    },
                    {
                        "name": "Xã Quảng Minh",
                        "code": 7792,
                        "codename": "xa_quang_minh",
                        "division_type": "xã",
                        "short_codename": "quang_minh"
                    },
                    {
                        "name": "Thị trấn Nếnh",
                        "code": 7795,
                        "codename": "thi_tran_nenh",
                        "division_type": "thị trấn",
                        "short_codename": "nenh"
                    },
                    {
                        "name": "Xã Ninh Sơn",
                        "code": 7798,
                        "codename": "xa_ninh_son",
                        "division_type": "xã",
                        "short_codename": "ninh_son"
                    },
                    {
                        "name": "Xã Vân Trung",
                        "code": 7801,
                        "codename": "xa_van_trung",
                        "division_type": "xã",
                        "short_codename": "van_trung"
                    },
                    {
                        "name": "Xã Vân Hà",
                        "code": 7804,
                        "codename": "xa_van_ha",
                        "division_type": "xã",
                        "short_codename": "van_ha"
                    },
                    {
                        "name": "Xã Quang Châu",
                        "code": 7807,
                        "codename": "xa_quang_chau",
                        "division_type": "xã",
                        "short_codename": "quang_chau"
                    }
                ]
            },
            {
                "name": "Huyện Hiệp Hòa",
                "code": 223,
                "codename": "huyen_hiep_hoa",
                "division_type": "huyện",
                "short_codename": "hiep_hoa",
                "wards": [
                    {
                        "name": "Xã Đồng Tân",
                        "code": 7813,
                        "codename": "xa_dong_tan",
                        "division_type": "xã",
                        "short_codename": "dong_tan"
                    },
                    {
                        "name": "Xã Thanh Vân",
                        "code": 7816,
                        "codename": "xa_thanh_van",
                        "division_type": "xã",
                        "short_codename": "thanh_van"
                    },
                    {
                        "name": "Xã Hoàng Lương",
                        "code": 7819,
                        "codename": "xa_hoang_luong",
                        "division_type": "xã",
                        "short_codename": "hoang_luong"
                    },
                    {
                        "name": "Xã Hoàng Vân",
                        "code": 7822,
                        "codename": "xa_hoang_van",
                        "division_type": "xã",
                        "short_codename": "hoang_van"
                    },
                    {
                        "name": "Xã Hoàng Thanh",
                        "code": 7825,
                        "codename": "xa_hoang_thanh",
                        "division_type": "xã",
                        "short_codename": "hoang_thanh"
                    },
                    {
                        "name": "Xã Hoàng An",
                        "code": 7828,
                        "codename": "xa_hoang_an",
                        "division_type": "xã",
                        "short_codename": "hoang_an"
                    },
                    {
                        "name": "Xã Ngọc Sơn",
                        "code": 7831,
                        "codename": "xa_ngoc_son",
                        "division_type": "xã",
                        "short_codename": "ngoc_son"
                    },
                    {
                        "name": "Xã Thái Sơn",
                        "code": 7834,
                        "codename": "xa_thai_son",
                        "division_type": "xã",
                        "short_codename": "thai_son"
                    },
                    {
                        "name": "Xã Hòa Sơn",
                        "code": 7837,
                        "codename": "xa_hoa_son",
                        "division_type": "xã",
                        "short_codename": "hoa_son"
                    },
                    {
                        "name": "Thị trấn Thắng",
                        "code": 7840,
                        "codename": "thi_tran_thang",
                        "division_type": "thị trấn",
                        "short_codename": "thang"
                    },
                    {
                        "name": "Xã Quang Minh",
                        "code": 7843,
                        "codename": "xa_quang_minh",
                        "division_type": "xã",
                        "short_codename": "quang_minh"
                    },
                    {
                        "name": "Xã Lương Phong",
                        "code": 7846,
                        "codename": "xa_luong_phong",
                        "division_type": "xã",
                        "short_codename": "luong_phong"
                    },
                    {
                        "name": "Xã Hùng Sơn",
                        "code": 7849,
                        "codename": "xa_hung_son",
                        "division_type": "xã",
                        "short_codename": "hung_son"
                    },
                    {
                        "name": "Xã Đại Thành",
                        "code": 7852,
                        "codename": "xa_dai_thanh",
                        "division_type": "xã",
                        "short_codename": "dai_thanh"
                    },
                    {
                        "name": "Xã Thường Thắng",
                        "code": 7855,
                        "codename": "xa_thuong_thang",
                        "division_type": "xã",
                        "short_codename": "thuong_thang"
                    },
                    {
                        "name": "Xã Hợp Thịnh",
                        "code": 7858,
                        "codename": "xa_hop_thinh",
                        "division_type": "xã",
                        "short_codename": "hop_thinh"
                    },
                    {
                        "name": "Xã Danh Thắng",
                        "code": 7861,
                        "codename": "xa_danh_thang",
                        "division_type": "xã",
                        "short_codename": "danh_thang"
                    },
                    {
                        "name": "Xã Mai Trung",
                        "code": 7864,
                        "codename": "xa_mai_trung",
                        "division_type": "xã",
                        "short_codename": "mai_trung"
                    },
                    {
                        "name": "Xã Đoan Bái",
                        "code": 7867,
                        "codename": "xa_doan_bai",
                        "division_type": "xã",
                        "short_codename": "doan_bai"
                    },
                    {
                        "name": "Xã Bắc Lý",
                        "code": 7870,
                        "codename": "xa_bac_ly",
                        "division_type": "xã",
                        "short_codename": "bac_ly"
                    },
                    {
                        "name": "Xã Xuân Cẩm",
                        "code": 7873,
                        "codename": "xa_xuan_cam",
                        "division_type": "xã",
                        "short_codename": "xuan_cam"
                    },
                    {
                        "name": "Xã Hương Lâm",
                        "code": 7876,
                        "codename": "xa_huong_lam",
                        "division_type": "xã",
                        "short_codename": "huong_lam"
                    },
                    {
                        "name": "Xã Đông Lỗ",
                        "code": 7879,
                        "codename": "xa_dong_lo",
                        "division_type": "xã",
                        "short_codename": "dong_lo"
                    },
                    {
                        "name": "Xã Châu Minh",
                        "code": 7882,
                        "codename": "xa_chau_minh",
                        "division_type": "xã",
                        "short_codename": "chau_minh"
                    },
                    {
                        "name": "Xã Mai Đình",
                        "code": 7885,
                        "codename": "xa_mai_dinh",
                        "division_type": "xã",
                        "short_codename": "mai_dinh"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Phú Thọ",
        "code": 25,
        "codename": "tinh_phu_tho",
        "division_type": "tỉnh",
        "phone_code": 210,
        "districts": [
            {
                "name": "Thành phố Việt Trì",
                "code": 227,
                "codename": "thanh_pho_viet_tri",
                "division_type": "thành phố",
                "short_codename": "viet_tri",
                "wards": [
                    {
                        "name": "Phường Dữu Lâu",
                        "code": 7888,
                        "codename": "phuong_duu_lau",
                        "division_type": "phường",
                        "short_codename": "duu_lau"
                    },
                    {
                        "name": "Phường Vân Cơ",
                        "code": 7891,
                        "codename": "phuong_van_co",
                        "division_type": "phường",
                        "short_codename": "van_co"
                    },
                    {
                        "name": "Phường Nông Trang",
                        "code": 7894,
                        "codename": "phuong_nong_trang",
                        "division_type": "phường",
                        "short_codename": "nong_trang"
                    },
                    {
                        "name": "Phường Tân Dân",
                        "code": 7897,
                        "codename": "phuong_tan_dan",
                        "division_type": "phường",
                        "short_codename": "tan_dan"
                    },
                    {
                        "name": "Phường Gia Cẩm",
                        "code": 7900,
                        "codename": "phuong_gia_cam",
                        "division_type": "phường",
                        "short_codename": "gia_cam"
                    },
                    {
                        "name": "Phường Tiên Cát",
                        "code": 7903,
                        "codename": "phuong_tien_cat",
                        "division_type": "phường",
                        "short_codename": "tien_cat"
                    },
                    {
                        "name": "Phường Thọ Sơn",
                        "code": 7906,
                        "codename": "phuong_tho_son",
                        "division_type": "phường",
                        "short_codename": "tho_son"
                    },
                    {
                        "name": "Phường Thanh Miếu",
                        "code": 7909,
                        "codename": "phuong_thanh_mieu",
                        "division_type": "phường",
                        "short_codename": "thanh_mieu"
                    },
                    {
                        "name": "Phường Bạch Hạc",
                        "code": 7912,
                        "codename": "phuong_bach_hac",
                        "division_type": "phường",
                        "short_codename": "bach_hac"
                    },
                    {
                        "name": "Phường Bến Gót",
                        "code": 7915,
                        "codename": "phuong_ben_got",
                        "division_type": "phường",
                        "short_codename": "ben_got"
                    },
                    {
                        "name": "Phường Vân Phú",
                        "code": 7918,
                        "codename": "phuong_van_phu",
                        "division_type": "phường",
                        "short_codename": "van_phu"
                    },
                    {
                        "name": "Xã Phượng Lâu",
                        "code": 7921,
                        "codename": "xa_phuong_lau",
                        "division_type": "xã",
                        "short_codename": "phuong_lau"
                    },
                    {
                        "name": "Xã Thụy Vân",
                        "code": 7924,
                        "codename": "xa_thuy_van",
                        "division_type": "xã",
                        "short_codename": "thuy_van"
                    },
                    {
                        "name": "Phường Minh Phương",
                        "code": 7927,
                        "codename": "phuong_minh_phuong",
                        "division_type": "phường",
                        "short_codename": "minh_phuong"
                    },
                    {
                        "name": "Xã Trưng Vương",
                        "code": 7930,
                        "codename": "xa_trung_vuong",
                        "division_type": "xã",
                        "short_codename": "trung_vuong"
                    },
                    {
                        "name": "Phường Minh Nông",
                        "code": 7933,
                        "codename": "phuong_minh_nong",
                        "division_type": "phường",
                        "short_codename": "minh_nong"
                    },
                    {
                        "name": "Xã Sông Lô",
                        "code": 7936,
                        "codename": "xa_song_lo",
                        "division_type": "xã",
                        "short_codename": "song_lo"
                    },
                    {
                        "name": "Xã Kim Đức",
                        "code": 8281,
                        "codename": "xa_kim_duc",
                        "division_type": "xã",
                        "short_codename": "kim_duc"
                    },
                    {
                        "name": "Xã Hùng Lô",
                        "code": 8287,
                        "codename": "xa_hung_lo",
                        "division_type": "xã",
                        "short_codename": "hung_lo"
                    },
                    {
                        "name": "Xã Hy Cương",
                        "code": 8503,
                        "codename": "xa_hy_cuong",
                        "division_type": "xã",
                        "short_codename": "hy_cuong"
                    },
                    {
                        "name": "Xã Chu Hóa",
                        "code": 8506,
                        "codename": "xa_chu_hoa",
                        "division_type": "xã",
                        "short_codename": "chu_hoa"
                    },
                    {
                        "name": "Xã Thanh Đình",
                        "code": 8515,
                        "codename": "xa_thanh_dinh",
                        "division_type": "xã",
                        "short_codename": "thanh_dinh"
                    }
                ]
            },
            {
                "name": "Thị xã Phú Thọ",
                "code": 228,
                "codename": "thi_xa_phu_tho",
                "division_type": "thị xã",
                "short_codename": "phu_tho",
                "wards": [
                    {
                        "name": "Phường Hùng Vương",
                        "code": 7942,
                        "codename": "phuong_hung_vuong",
                        "division_type": "phường",
                        "short_codename": "hung_vuong"
                    },
                    {
                        "name": "Phường Phong Châu",
                        "code": 7945,
                        "codename": "phuong_phong_chau",
                        "division_type": "phường",
                        "short_codename": "phong_chau"
                    },
                    {
                        "name": "Phường Âu Cơ",
                        "code": 7948,
                        "codename": "phuong_au_co",
                        "division_type": "phường",
                        "short_codename": "au_co"
                    },
                    {
                        "name": "Xã Hà Lộc",
                        "code": 7951,
                        "codename": "xa_ha_loc",
                        "division_type": "xã",
                        "short_codename": "ha_loc"
                    },
                    {
                        "name": "Xã Phú Hộ",
                        "code": 7954,
                        "codename": "xa_phu_ho",
                        "division_type": "xã",
                        "short_codename": "phu_ho"
                    },
                    {
                        "name": "Xã Văn Lung",
                        "code": 7957,
                        "codename": "xa_van_lung",
                        "division_type": "xã",
                        "short_codename": "van_lung"
                    },
                    {
                        "name": "Xã Thanh Minh",
                        "code": 7960,
                        "codename": "xa_thanh_minh",
                        "division_type": "xã",
                        "short_codename": "thanh_minh"
                    },
                    {
                        "name": "Xã Hà Thạch",
                        "code": 7963,
                        "codename": "xa_ha_thach",
                        "division_type": "xã",
                        "short_codename": "ha_thach"
                    },
                    {
                        "name": "Phường Thanh Vinh",
                        "code": 7966,
                        "codename": "phuong_thanh_vinh",
                        "division_type": "phường",
                        "short_codename": "thanh_vinh"
                    }
                ]
            },
            {
                "name": "Huyện Đoan Hùng",
                "code": 230,
                "codename": "huyen_doan_hung",
                "division_type": "huyện",
                "short_codename": "doan_hung",
                "wards": [
                    {
                        "name": "Thị trấn Đoan Hùng",
                        "code": 7969,
                        "codename": "thi_tran_doan_hung",
                        "division_type": "thị trấn",
                        "short_codename": "doan_hung"
                    },
                    {
                        "name": "Xã Hùng Xuyên",
                        "code": 7975,
                        "codename": "xa_hung_xuyen",
                        "division_type": "xã",
                        "short_codename": "hung_xuyen"
                    },
                    {
                        "name": "Xã Bằng Luân",
                        "code": 7981,
                        "codename": "xa_bang_luan",
                        "division_type": "xã",
                        "short_codename": "bang_luan"
                    },
                    {
                        "name": "Xã Vân Du",
                        "code": 7984,
                        "codename": "xa_van_du",
                        "division_type": "xã",
                        "short_codename": "van_du"
                    },
                    {
                        "name": "Xã Phú Lâm",
                        "code": 7987,
                        "codename": "xa_phu_lam",
                        "division_type": "xã",
                        "short_codename": "phu_lam"
                    },
                    {
                        "name": "Xã Minh Lương",
                        "code": 7993,
                        "codename": "xa_minh_luong",
                        "division_type": "xã",
                        "short_codename": "minh_luong"
                    },
                    {
                        "name": "Xã Bằng Doãn",
                        "code": 7996,
                        "codename": "xa_bang_doan",
                        "division_type": "xã",
                        "short_codename": "bang_doan"
                    },
                    {
                        "name": "Xã Chí Đám",
                        "code": 7999,
                        "codename": "xa_chi_dam",
                        "division_type": "xã",
                        "short_codename": "chi_dam"
                    },
                    {
                        "name": "Xã Phúc Lai",
                        "code": 8005,
                        "codename": "xa_phuc_lai",
                        "division_type": "xã",
                        "short_codename": "phuc_lai"
                    },
                    {
                        "name": "Xã Ngọc Quan",
                        "code": 8008,
                        "codename": "xa_ngoc_quan",
                        "division_type": "xã",
                        "short_codename": "ngoc_quan"
                    },
                    {
                        "name": "Xã Hợp Nhất",
                        "code": 8014,
                        "codename": "xa_hop_nhat",
                        "division_type": "xã",
                        "short_codename": "hop_nhat"
                    },
                    {
                        "name": "Xã Sóc Đăng",
                        "code": 8017,
                        "codename": "xa_soc_dang",
                        "division_type": "xã",
                        "short_codename": "soc_dang"
                    },
                    {
                        "name": "Xã Tây Cốc",
                        "code": 8023,
                        "codename": "xa_tay_coc",
                        "division_type": "xã",
                        "short_codename": "tay_coc"
                    },
                    {
                        "name": "Xã Yên Kiện",
                        "code": 8026,
                        "codename": "xa_yen_kien",
                        "division_type": "xã",
                        "short_codename": "yen_kien"
                    },
                    {
                        "name": "Xã Hùng Long",
                        "code": 8029,
                        "codename": "xa_hung_long",
                        "division_type": "xã",
                        "short_codename": "hung_long"
                    },
                    {
                        "name": "Xã Vụ Quang",
                        "code": 8032,
                        "codename": "xa_vu_quang",
                        "division_type": "xã",
                        "short_codename": "vu_quang"
                    },
                    {
                        "name": "Xã Vân Đồn",
                        "code": 8035,
                        "codename": "xa_van_don",
                        "division_type": "xã",
                        "short_codename": "van_don"
                    },
                    {
                        "name": "Xã Tiêu Sơn",
                        "code": 8038,
                        "codename": "xa_tieu_son",
                        "division_type": "xã",
                        "short_codename": "tieu_son"
                    },
                    {
                        "name": "Xã Minh Tiến",
                        "code": 8041,
                        "codename": "xa_minh_tien",
                        "division_type": "xã",
                        "short_codename": "minh_tien"
                    },
                    {
                        "name": "Xã Minh Phú",
                        "code": 8044,
                        "codename": "xa_minh_phu",
                        "division_type": "xã",
                        "short_codename": "minh_phu"
                    },
                    {
                        "name": "Xã Chân Mộng",
                        "code": 8047,
                        "codename": "xa_chan_mong",
                        "division_type": "xã",
                        "short_codename": "chan_mong"
                    },
                    {
                        "name": "Xã Ca Đình",
                        "code": 8050,
                        "codename": "xa_ca_dinh",
                        "division_type": "xã",
                        "short_codename": "ca_dinh"
                    }
                ]
            },
            {
                "name": "Huyện Hạ Hoà",
                "code": 231,
                "codename": "huyen_ha_hoa",
                "division_type": "huyện",
                "short_codename": "ha_hoa",
                "wards": [
                    {
                        "name": "Thị trấn Hạ Hoà",
                        "code": 8053,
                        "codename": "thi_tran_ha_hoa",
                        "division_type": "thị trấn",
                        "short_codename": "ha_hoa"
                    },
                    {
                        "name": "Xã Đại Phạm",
                        "code": 8056,
                        "codename": "xa_dai_pham",
                        "division_type": "xã",
                        "short_codename": "dai_pham"
                    },
                    {
                        "name": "Xã Đan Thượng",
                        "code": 8062,
                        "codename": "xa_dan_thuong",
                        "division_type": "xã",
                        "short_codename": "dan_thuong"
                    },
                    {
                        "name": "Xã Hà Lương",
                        "code": 8065,
                        "codename": "xa_ha_luong",
                        "division_type": "xã",
                        "short_codename": "ha_luong"
                    },
                    {
                        "name": "Xã Tứ Hiệp",
                        "code": 8071,
                        "codename": "xa_tu_hiep",
                        "division_type": "xã",
                        "short_codename": "tu_hiep"
                    },
                    {
                        "name": "Xã Hiền Lương",
                        "code": 8080,
                        "codename": "xa_hien_luong",
                        "division_type": "xã",
                        "short_codename": "hien_luong"
                    },
                    {
                        "name": "Xã Phương Viên",
                        "code": 8089,
                        "codename": "xa_phuong_vien",
                        "division_type": "xã",
                        "short_codename": "phuong_vien"
                    },
                    {
                        "name": "Xã Gia Điền",
                        "code": 8092,
                        "codename": "xa_gia_dien",
                        "division_type": "xã",
                        "short_codename": "gia_dien"
                    },
                    {
                        "name": "Xã Ấm Hạ",
                        "code": 8095,
                        "codename": "xa_am_ha",
                        "division_type": "xã",
                        "short_codename": "am_ha"
                    },
                    {
                        "name": "Xã Hương Xạ",
                        "code": 8104,
                        "codename": "xa_huong_xa",
                        "division_type": "xã",
                        "short_codename": "huong_xa"
                    },
                    {
                        "name": "Xã Xuân Áng",
                        "code": 8110,
                        "codename": "xa_xuan_ang",
                        "division_type": "xã",
                        "short_codename": "xuan_ang"
                    },
                    {
                        "name": "Xã Yên Kỳ",
                        "code": 8113,
                        "codename": "xa_yen_ky",
                        "division_type": "xã",
                        "short_codename": "yen_ky"
                    },
                    {
                        "name": "Xã Minh Hạc",
                        "code": 8119,
                        "codename": "xa_minh_hac",
                        "division_type": "xã",
                        "short_codename": "minh_hac"
                    },
                    {
                        "name": "Xã Lang Sơn",
                        "code": 8122,
                        "codename": "xa_lang_son",
                        "division_type": "xã",
                        "short_codename": "lang_son"
                    },
                    {
                        "name": "Xã Bằng Giã",
                        "code": 8125,
                        "codename": "xa_bang_gia",
                        "division_type": "xã",
                        "short_codename": "bang_gia"
                    },
                    {
                        "name": "Xã Yên Luật",
                        "code": 8128,
                        "codename": "xa_yen_luat",
                        "division_type": "xã",
                        "short_codename": "yen_luat"
                    },
                    {
                        "name": "Xã Vô Tranh",
                        "code": 8131,
                        "codename": "xa_vo_tranh",
                        "division_type": "xã",
                        "short_codename": "vo_tranh"
                    },
                    {
                        "name": "Xã Văn Lang",
                        "code": 8134,
                        "codename": "xa_van_lang",
                        "division_type": "xã",
                        "short_codename": "van_lang"
                    },
                    {
                        "name": "Xã Minh Côi",
                        "code": 8140,
                        "codename": "xa_minh_coi",
                        "division_type": "xã",
                        "short_codename": "minh_coi"
                    },
                    {
                        "name": "Xã Vĩnh Chân",
                        "code": 8143,
                        "codename": "xa_vinh_chan",
                        "division_type": "xã",
                        "short_codename": "vinh_chan"
                    }
                ]
            },
            {
                "name": "Huyện Thanh Ba",
                "code": 232,
                "codename": "huyen_thanh_ba",
                "division_type": "huyện",
                "short_codename": "thanh_ba",
                "wards": [
                    {
                        "name": "Thị trấn Thanh Ba",
                        "code": 8152,
                        "codename": "thi_tran_thanh_ba",
                        "division_type": "thị trấn",
                        "short_codename": "thanh_ba"
                    },
                    {
                        "name": "Xã Vân Lĩnh",
                        "code": 8156,
                        "codename": "xa_van_linh",
                        "division_type": "xã",
                        "short_codename": "van_linh"
                    },
                    {
                        "name": "Xã Đông Lĩnh",
                        "code": 8158,
                        "codename": "xa_dong_linh",
                        "division_type": "xã",
                        "short_codename": "dong_linh"
                    },
                    {
                        "name": "Xã Đại An",
                        "code": 8161,
                        "codename": "xa_dai_an",
                        "division_type": "xã",
                        "short_codename": "dai_an"
                    },
                    {
                        "name": "Xã Hanh Cù",
                        "code": 8164,
                        "codename": "xa_hanh_cu",
                        "division_type": "xã",
                        "short_codename": "hanh_cu"
                    },
                    {
                        "name": "Xã Đồng Xuân",
                        "code": 8170,
                        "codename": "xa_dong_xuan",
                        "division_type": "xã",
                        "short_codename": "dong_xuan"
                    },
                    {
                        "name": "Xã Quảng Yên",
                        "code": 8173,
                        "codename": "xa_quang_yen",
                        "division_type": "xã",
                        "short_codename": "quang_yen"
                    },
                    {
                        "name": "Xã Ninh Dân",
                        "code": 8179,
                        "codename": "xa_ninh_dan",
                        "division_type": "xã",
                        "short_codename": "ninh_dan"
                    },
                    {
                        "name": "Xã Võ Lao",
                        "code": 8194,
                        "codename": "xa_vo_lao",
                        "division_type": "xã",
                        "short_codename": "vo_lao"
                    },
                    {
                        "name": "Xã Khải Xuân",
                        "code": 8197,
                        "codename": "xa_khai_xuan",
                        "division_type": "xã",
                        "short_codename": "khai_xuan"
                    },
                    {
                        "name": "Xã Mạn Lạn",
                        "code": 8200,
                        "codename": "xa_man_lan",
                        "division_type": "xã",
                        "short_codename": "man_lan"
                    },
                    {
                        "name": "Xã Hoàng Cương",
                        "code": 8203,
                        "codename": "xa_hoang_cuong",
                        "division_type": "xã",
                        "short_codename": "hoang_cuong"
                    },
                    {
                        "name": "Xã Chí Tiên",
                        "code": 8206,
                        "codename": "xa_chi_tien",
                        "division_type": "xã",
                        "short_codename": "chi_tien"
                    },
                    {
                        "name": "Xã Đông Thành",
                        "code": 8209,
                        "codename": "xa_dong_thanh",
                        "division_type": "xã",
                        "short_codename": "dong_thanh"
                    },
                    {
                        "name": "Xã Sơn Cương",
                        "code": 8215,
                        "codename": "xa_son_cuong",
                        "division_type": "xã",
                        "short_codename": "son_cuong"
                    },
                    {
                        "name": "Xã Thanh Hà",
                        "code": 8218,
                        "codename": "xa_thanh_ha",
                        "division_type": "xã",
                        "short_codename": "thanh_ha"
                    },
                    {
                        "name": "Xã Đỗ Sơn",
                        "code": 8221,
                        "codename": "xa_do_son",
                        "division_type": "xã",
                        "short_codename": "do_son"
                    },
                    {
                        "name": "Xã Đỗ Xuyên",
                        "code": 8224,
                        "codename": "xa_do_xuyen",
                        "division_type": "xã",
                        "short_codename": "do_xuyen"
                    },
                    {
                        "name": "Xã Lương Lỗ",
                        "code": 8227,
                        "codename": "xa_luong_lo",
                        "division_type": "xã",
                        "short_codename": "luong_lo"
                    }
                ]
            },
            {
                "name": "Huyện Phù Ninh",
                "code": 233,
                "codename": "huyen_phu_ninh",
                "division_type": "huyện",
                "short_codename": "phu_ninh",
                "wards": [
                    {
                        "name": "Thị trấn Phong Châu",
                        "code": 8230,
                        "codename": "thi_tran_phong_chau",
                        "division_type": "thị trấn",
                        "short_codename": "phong_chau"
                    },
                    {
                        "name": "Xã Phú Mỹ",
                        "code": 8233,
                        "codename": "xa_phu_my",
                        "division_type": "xã",
                        "short_codename": "phu_my"
                    },
                    {
                        "name": "Xã Lệ Mỹ",
                        "code": 8234,
                        "codename": "xa_le_my",
                        "division_type": "xã",
                        "short_codename": "le_my"
                    },
                    {
                        "name": "Xã Liên Hoa",
                        "code": 8236,
                        "codename": "xa_lien_hoa",
                        "division_type": "xã",
                        "short_codename": "lien_hoa"
                    },
                    {
                        "name": "Xã Trạm Thản",
                        "code": 8239,
                        "codename": "xa_tram_than",
                        "division_type": "xã",
                        "short_codename": "tram_than"
                    },
                    {
                        "name": "Xã Trị Quận",
                        "code": 8242,
                        "codename": "xa_tri_quan",
                        "division_type": "xã",
                        "short_codename": "tri_quan"
                    },
                    {
                        "name": "Xã Trung Giáp",
                        "code": 8245,
                        "codename": "xa_trung_giap",
                        "division_type": "xã",
                        "short_codename": "trung_giap"
                    },
                    {
                        "name": "Xã Tiên Phú",
                        "code": 8248,
                        "codename": "xa_tien_phu",
                        "division_type": "xã",
                        "short_codename": "tien_phu"
                    },
                    {
                        "name": "Xã Hạ Giáp",
                        "code": 8251,
                        "codename": "xa_ha_giap",
                        "division_type": "xã",
                        "short_codename": "ha_giap"
                    },
                    {
                        "name": "Xã Bảo Thanh",
                        "code": 8254,
                        "codename": "xa_bao_thanh",
                        "division_type": "xã",
                        "short_codename": "bao_thanh"
                    },
                    {
                        "name": "Xã Phú Lộc",
                        "code": 8257,
                        "codename": "xa_phu_loc",
                        "division_type": "xã",
                        "short_codename": "phu_loc"
                    },
                    {
                        "name": "Xã Gia Thanh",
                        "code": 8260,
                        "codename": "xa_gia_thanh",
                        "division_type": "xã",
                        "short_codename": "gia_thanh"
                    },
                    {
                        "name": "Xã Tiên Du",
                        "code": 8263,
                        "codename": "xa_tien_du",
                        "division_type": "xã",
                        "short_codename": "tien_du"
                    },
                    {
                        "name": "Xã Phú Nham",
                        "code": 8266,
                        "codename": "xa_phu_nham",
                        "division_type": "xã",
                        "short_codename": "phu_nham"
                    },
                    {
                        "name": "Xã An Đạo",
                        "code": 8272,
                        "codename": "xa_an_dao",
                        "division_type": "xã",
                        "short_codename": "an_dao"
                    },
                    {
                        "name": "Xã Bình Phú",
                        "code": 8275,
                        "codename": "xa_binh_phu",
                        "division_type": "xã",
                        "short_codename": "binh_phu"
                    },
                    {
                        "name": "Xã Phù Ninh",
                        "code": 8278,
                        "codename": "xa_phu_ninh",
                        "division_type": "xã",
                        "short_codename": "phu_ninh"
                    }
                ]
            },
            {
                "name": "Huyện Yên Lập",
                "code": 234,
                "codename": "huyen_yen_lap",
                "division_type": "huyện",
                "short_codename": "yen_lap",
                "wards": [
                    {
                        "name": "Thị trấn Yên Lập",
                        "code": 8290,
                        "codename": "thi_tran_yen_lap",
                        "division_type": "thị trấn",
                        "short_codename": "yen_lap"
                    },
                    {
                        "name": "Xã Mỹ Lung",
                        "code": 8293,
                        "codename": "xa_my_lung",
                        "division_type": "xã",
                        "short_codename": "my_lung"
                    },
                    {
                        "name": "Xã Mỹ Lương",
                        "code": 8296,
                        "codename": "xa_my_luong",
                        "division_type": "xã",
                        "short_codename": "my_luong"
                    },
                    {
                        "name": "Xã Lương Sơn",
                        "code": 8299,
                        "codename": "xa_luong_son",
                        "division_type": "xã",
                        "short_codename": "luong_son"
                    },
                    {
                        "name": "Xã Xuân An",
                        "code": 8302,
                        "codename": "xa_xuan_an",
                        "division_type": "xã",
                        "short_codename": "xuan_an"
                    },
                    {
                        "name": "Xã Xuân Viên",
                        "code": 8305,
                        "codename": "xa_xuan_vien",
                        "division_type": "xã",
                        "short_codename": "xuan_vien"
                    },
                    {
                        "name": "Xã Xuân Thủy",
                        "code": 8308,
                        "codename": "xa_xuan_thuy",
                        "division_type": "xã",
                        "short_codename": "xuan_thuy"
                    },
                    {
                        "name": "Xã Trung Sơn",
                        "code": 8311,
                        "codename": "xa_trung_son",
                        "division_type": "xã",
                        "short_codename": "trung_son"
                    },
                    {
                        "name": "Xã Hưng Long",
                        "code": 8314,
                        "codename": "xa_hung_long",
                        "division_type": "xã",
                        "short_codename": "hung_long"
                    },
                    {
                        "name": "Xã Nga Hoàng",
                        "code": 8317,
                        "codename": "xa_nga_hoang",
                        "division_type": "xã",
                        "short_codename": "nga_hoang"
                    },
                    {
                        "name": "Xã Đồng Lạc",
                        "code": 8320,
                        "codename": "xa_dong_lac",
                        "division_type": "xã",
                        "short_codename": "dong_lac"
                    },
                    {
                        "name": "Xã Thượng Long",
                        "code": 8323,
                        "codename": "xa_thuong_long",
                        "division_type": "xã",
                        "short_codename": "thuong_long"
                    },
                    {
                        "name": "Xã Đồng Thịnh",
                        "code": 8326,
                        "codename": "xa_dong_thinh",
                        "division_type": "xã",
                        "short_codename": "dong_thinh"
                    },
                    {
                        "name": "Xã Phúc Khánh",
                        "code": 8329,
                        "codename": "xa_phuc_khanh",
                        "division_type": "xã",
                        "short_codename": "phuc_khanh"
                    },
                    {
                        "name": "Xã Minh Hòa",
                        "code": 8332,
                        "codename": "xa_minh_hoa",
                        "division_type": "xã",
                        "short_codename": "minh_hoa"
                    },
                    {
                        "name": "Xã Ngọc Lập",
                        "code": 8335,
                        "codename": "xa_ngoc_lap",
                        "division_type": "xã",
                        "short_codename": "ngoc_lap"
                    },
                    {
                        "name": "Xã Ngọc Đồng",
                        "code": 8338,
                        "codename": "xa_ngoc_dong",
                        "division_type": "xã",
                        "short_codename": "ngoc_dong"
                    }
                ]
            },
            {
                "name": "Huyện Cẩm Khê",
                "code": 235,
                "codename": "huyen_cam_khe",
                "division_type": "huyện",
                "short_codename": "cam_khe",
                "wards": [
                    {
                        "name": "Thị trấn Cẩm Khê",
                        "code": 8341,
                        "codename": "thi_tran_cam_khe",
                        "division_type": "thị trấn",
                        "short_codename": "cam_khe"
                    },
                    {
                        "name": "Xã Tiên Lương",
                        "code": 8344,
                        "codename": "xa_tien_luong",
                        "division_type": "xã",
                        "short_codename": "tien_luong"
                    },
                    {
                        "name": "Xã Tuy Lộc",
                        "code": 8347,
                        "codename": "xa_tuy_loc",
                        "division_type": "xã",
                        "short_codename": "tuy_loc"
                    },
                    {
                        "name": "Xã Ngô Xá",
                        "code": 8350,
                        "codename": "xa_ngo_xa",
                        "division_type": "xã",
                        "short_codename": "ngo_xa"
                    },
                    {
                        "name": "Xã Minh Tân",
                        "code": 8353,
                        "codename": "xa_minh_tan",
                        "division_type": "xã",
                        "short_codename": "minh_tan"
                    },
                    {
                        "name": "Xã Phượng Vĩ",
                        "code": 8356,
                        "codename": "xa_phuong_vi",
                        "division_type": "xã",
                        "short_codename": "phuong_vi"
                    },
                    {
                        "name": "Xã Thụy Liễu",
                        "code": 8362,
                        "codename": "xa_thuy_lieu",
                        "division_type": "xã",
                        "short_codename": "thuy_lieu"
                    },
                    {
                        "name": "Xã Tùng Khê",
                        "code": 8374,
                        "codename": "xa_tung_khe",
                        "division_type": "xã",
                        "short_codename": "tung_khe"
                    },
                    {
                        "name": "Xã Tam Sơn",
                        "code": 8377,
                        "codename": "xa_tam_son",
                        "division_type": "xã",
                        "short_codename": "tam_son"
                    },
                    {
                        "name": "Xã Văn Bán",
                        "code": 8380,
                        "codename": "xa_van_ban",
                        "division_type": "xã",
                        "short_codename": "van_ban"
                    },
                    {
                        "name": "Xã Cấp Dẫn",
                        "code": 8383,
                        "codename": "xa_cap_dan",
                        "division_type": "xã",
                        "short_codename": "cap_dan"
                    },
                    {
                        "name": "Xã Xương Thịnh",
                        "code": 8389,
                        "codename": "xa_xuong_thinh",
                        "division_type": "xã",
                        "short_codename": "xuong_thinh"
                    },
                    {
                        "name": "Xã Phú Khê",
                        "code": 8392,
                        "codename": "xa_phu_khe",
                        "division_type": "xã",
                        "short_codename": "phu_khe"
                    },
                    {
                        "name": "Xã Sơn Tình",
                        "code": 8395,
                        "codename": "xa_son_tinh",
                        "division_type": "xã",
                        "short_codename": "son_tinh"
                    },
                    {
                        "name": "Xã Yên Tập",
                        "code": 8398,
                        "codename": "xa_yen_tap",
                        "division_type": "xã",
                        "short_codename": "yen_tap"
                    },
                    {
                        "name": "Xã Hương Lung",
                        "code": 8401,
                        "codename": "xa_huong_lung",
                        "division_type": "xã",
                        "short_codename": "huong_lung"
                    },
                    {
                        "name": "Xã Tạ Xá",
                        "code": 8404,
                        "codename": "xa_ta_xa",
                        "division_type": "xã",
                        "short_codename": "ta_xa"
                    },
                    {
                        "name": "Xã Phú Lạc",
                        "code": 8407,
                        "codename": "xa_phu_lac",
                        "division_type": "xã",
                        "short_codename": "phu_lac"
                    },
                    {
                        "name": "Xã Chương Xá",
                        "code": 8413,
                        "codename": "xa_chuong_xa",
                        "division_type": "xã",
                        "short_codename": "chuong_xa"
                    },
                    {
                        "name": "Xã Hùng Việt",
                        "code": 8416,
                        "codename": "xa_hung_viet",
                        "division_type": "xã",
                        "short_codename": "hung_viet"
                    },
                    {
                        "name": "Xã Văn Khúc",
                        "code": 8419,
                        "codename": "xa_van_khuc",
                        "division_type": "xã",
                        "short_codename": "van_khuc"
                    },
                    {
                        "name": "Xã Yên Dưỡng",
                        "code": 8422,
                        "codename": "xa_yen_duong",
                        "division_type": "xã",
                        "short_codename": "yen_duong"
                    },
                    {
                        "name": "Xã Điêu Lương",
                        "code": 8428,
                        "codename": "xa_dieu_luong",
                        "division_type": "xã",
                        "short_codename": "dieu_luong"
                    },
                    {
                        "name": "Xã Đồng Lương",
                        "code": 8431,
                        "codename": "xa_dong_luong",
                        "division_type": "xã",
                        "short_codename": "dong_luong"
                    }
                ]
            },
            {
                "name": "Huyện Tam Nông",
                "code": 236,
                "codename": "huyen_tam_nong",
                "division_type": "huyện",
                "short_codename": "tam_nong",
                "wards": [
                    {
                        "name": "Thị trấn Hưng Hoá",
                        "code": 8434,
                        "codename": "thi_tran_hung_hoa",
                        "division_type": "thị trấn",
                        "short_codename": "hung_hoa"
                    },
                    {
                        "name": "Xã Hiền Quan",
                        "code": 8440,
                        "codename": "xa_hien_quan",
                        "division_type": "xã",
                        "short_codename": "hien_quan"
                    },
                    {
                        "name": "Xã Bắc Sơn",
                        "code": 8443,
                        "codename": "xa_bac_son",
                        "division_type": "xã",
                        "short_codename": "bac_son"
                    },
                    {
                        "name": "Xã Thanh Uyên",
                        "code": 8446,
                        "codename": "xa_thanh_uyen",
                        "division_type": "xã",
                        "short_codename": "thanh_uyen"
                    },
                    {
                        "name": "Xã Lam Sơn",
                        "code": 8461,
                        "codename": "xa_lam_son",
                        "division_type": "xã",
                        "short_codename": "lam_son"
                    },
                    {
                        "name": "Xã Vạn Xuân",
                        "code": 8467,
                        "codename": "xa_van_xuan",
                        "division_type": "xã",
                        "short_codename": "van_xuan"
                    },
                    {
                        "name": "Xã Quang Húc",
                        "code": 8470,
                        "codename": "xa_quang_huc",
                        "division_type": "xã",
                        "short_codename": "quang_huc"
                    },
                    {
                        "name": "Xã Hương Nộn",
                        "code": 8473,
                        "codename": "xa_huong_non",
                        "division_type": "xã",
                        "short_codename": "huong_non"
                    },
                    {
                        "name": "Xã Tề Lễ",
                        "code": 8476,
                        "codename": "xa_te_le",
                        "division_type": "xã",
                        "short_codename": "te_le"
                    },
                    {
                        "name": "Xã Thọ Văn",
                        "code": 8479,
                        "codename": "xa_tho_van",
                        "division_type": "xã",
                        "short_codename": "tho_van"
                    },
                    {
                        "name": "Xã Dị Nậu",
                        "code": 8482,
                        "codename": "xa_di_nau",
                        "division_type": "xã",
                        "short_codename": "di_nau"
                    },
                    {
                        "name": "Xã Dân Quyền",
                        "code": 8491,
                        "codename": "xa_dan_quyen",
                        "division_type": "xã",
                        "short_codename": "dan_quyen"
                    }
                ]
            },
            {
                "name": "Huyện Lâm Thao",
                "code": 237,
                "codename": "huyen_lam_thao",
                "division_type": "huyện",
                "short_codename": "lam_thao",
                "wards": [
                    {
                        "name": "Thị trấn Lâm Thao",
                        "code": 8494,
                        "codename": "thi_tran_lam_thao",
                        "division_type": "thị trấn",
                        "short_codename": "lam_thao"
                    },
                    {
                        "name": "Xã Tiên Kiên",
                        "code": 8497,
                        "codename": "xa_tien_kien",
                        "division_type": "xã",
                        "short_codename": "tien_kien"
                    },
                    {
                        "name": "Thị trấn Hùng Sơn",
                        "code": 8498,
                        "codename": "thi_tran_hung_son",
                        "division_type": "thị trấn",
                        "short_codename": "hung_son"
                    },
                    {
                        "name": "Xã Xuân Lũng",
                        "code": 8500,
                        "codename": "xa_xuan_lung",
                        "division_type": "xã",
                        "short_codename": "xuan_lung"
                    },
                    {
                        "name": "Xã Xuân Huy",
                        "code": 8509,
                        "codename": "xa_xuan_huy",
                        "division_type": "xã",
                        "short_codename": "xuan_huy"
                    },
                    {
                        "name": "Xã Thạch Sơn",
                        "code": 8512,
                        "codename": "xa_thach_son",
                        "division_type": "xã",
                        "short_codename": "thach_son"
                    },
                    {
                        "name": "Xã Sơn Vi",
                        "code": 8518,
                        "codename": "xa_son_vi",
                        "division_type": "xã",
                        "short_codename": "son_vi"
                    },
                    {
                        "name": "Xã Phùng Nguyên",
                        "code": 8521,
                        "codename": "xa_phung_nguyen",
                        "division_type": "xã",
                        "short_codename": "phung_nguyen"
                    },
                    {
                        "name": "Xã Cao Xá",
                        "code": 8527,
                        "codename": "xa_cao_xa",
                        "division_type": "xã",
                        "short_codename": "cao_xa"
                    },
                    {
                        "name": "Xã Vĩnh Lại",
                        "code": 8533,
                        "codename": "xa_vinh_lai",
                        "division_type": "xã",
                        "short_codename": "vinh_lai"
                    },
                    {
                        "name": "Xã Tứ Xã",
                        "code": 8536,
                        "codename": "xa_tu_xa",
                        "division_type": "xã",
                        "short_codename": "tu_xa"
                    },
                    {
                        "name": "Xã Bản Nguyên",
                        "code": 8539,
                        "codename": "xa_ban_nguyen",
                        "division_type": "xã",
                        "short_codename": "ban_nguyen"
                    }
                ]
            },
            {
                "name": "Huyện Thanh Sơn",
                "code": 238,
                "codename": "huyen_thanh_son",
                "division_type": "huyện",
                "short_codename": "thanh_son",
                "wards": [
                    {
                        "name": "Thị trấn Thanh Sơn",
                        "code": 8542,
                        "codename": "thi_tran_thanh_son",
                        "division_type": "thị trấn",
                        "short_codename": "thanh_son"
                    },
                    {
                        "name": "Xã Sơn Hùng",
                        "code": 8563,
                        "codename": "xa_son_hung",
                        "division_type": "xã",
                        "short_codename": "son_hung"
                    },
                    {
                        "name": "Xã Địch Quả",
                        "code": 8572,
                        "codename": "xa_dich_qua",
                        "division_type": "xã",
                        "short_codename": "dich_qua"
                    },
                    {
                        "name": "Xã Giáp Lai",
                        "code": 8575,
                        "codename": "xa_giap_lai",
                        "division_type": "xã",
                        "short_codename": "giap_lai"
                    },
                    {
                        "name": "Xã Thục Luyện",
                        "code": 8581,
                        "codename": "xa_thuc_luyen",
                        "division_type": "xã",
                        "short_codename": "thuc_luyen"
                    },
                    {
                        "name": "Xã Võ Miếu",
                        "code": 8584,
                        "codename": "xa_vo_mieu",
                        "division_type": "xã",
                        "short_codename": "vo_mieu"
                    },
                    {
                        "name": "Xã Thạch Khoán",
                        "code": 8587,
                        "codename": "xa_thach_khoan",
                        "division_type": "xã",
                        "short_codename": "thach_khoan"
                    },
                    {
                        "name": "Xã Cự Thắng",
                        "code": 8602,
                        "codename": "xa_cu_thang",
                        "division_type": "xã",
                        "short_codename": "cu_thang"
                    },
                    {
                        "name": "Xã Tất Thắng",
                        "code": 8605,
                        "codename": "xa_tat_thang",
                        "division_type": "xã",
                        "short_codename": "tat_thang"
                    },
                    {
                        "name": "Xã Văn Miếu",
                        "code": 8611,
                        "codename": "xa_van_mieu",
                        "division_type": "xã",
                        "short_codename": "van_mieu"
                    },
                    {
                        "name": "Xã Cự Đồng",
                        "code": 8614,
                        "codename": "xa_cu_dong",
                        "division_type": "xã",
                        "short_codename": "cu_dong"
                    },
                    {
                        "name": "Xã Thắng Sơn",
                        "code": 8623,
                        "codename": "xa_thang_son",
                        "division_type": "xã",
                        "short_codename": "thang_son"
                    },
                    {
                        "name": "Xã Tân Minh",
                        "code": 8629,
                        "codename": "xa_tan_minh",
                        "division_type": "xã",
                        "short_codename": "tan_minh"
                    },
                    {
                        "name": "Xã Hương Cần",
                        "code": 8632,
                        "codename": "xa_huong_can",
                        "division_type": "xã",
                        "short_codename": "huong_can"
                    },
                    {
                        "name": "Xã Khả Cửu",
                        "code": 8635,
                        "codename": "xa_kha_cuu",
                        "division_type": "xã",
                        "short_codename": "kha_cuu"
                    },
                    {
                        "name": "Xã Đông Cửu",
                        "code": 8638,
                        "codename": "xa_dong_cuu",
                        "division_type": "xã",
                        "short_codename": "dong_cuu"
                    },
                    {
                        "name": "Xã Tân Lập",
                        "code": 8641,
                        "codename": "xa_tan_lap",
                        "division_type": "xã",
                        "short_codename": "tan_lap"
                    },
                    {
                        "name": "Xã Yên Lãng",
                        "code": 8644,
                        "codename": "xa_yen_lang",
                        "division_type": "xã",
                        "short_codename": "yen_lang"
                    },
                    {
                        "name": "Xã Yên Lương",
                        "code": 8647,
                        "codename": "xa_yen_luong",
                        "division_type": "xã",
                        "short_codename": "yen_luong"
                    },
                    {
                        "name": "Xã Thượng Cửu",
                        "code": 8650,
                        "codename": "xa_thuong_cuu",
                        "division_type": "xã",
                        "short_codename": "thuong_cuu"
                    },
                    {
                        "name": "Xã Lương Nha",
                        "code": 8653,
                        "codename": "xa_luong_nha",
                        "division_type": "xã",
                        "short_codename": "luong_nha"
                    },
                    {
                        "name": "Xã Yên Sơn",
                        "code": 8656,
                        "codename": "xa_yen_son",
                        "division_type": "xã",
                        "short_codename": "yen_son"
                    },
                    {
                        "name": "Xã Tinh Nhuệ",
                        "code": 8659,
                        "codename": "xa_tinh_nhue",
                        "division_type": "xã",
                        "short_codename": "tinh_nhue"
                    }
                ]
            },
            {
                "name": "Huyện Thanh Thuỷ",
                "code": 239,
                "codename": "huyen_thanh_thuy",
                "division_type": "huyện",
                "short_codename": "thanh_thuy",
                "wards": [
                    {
                        "name": "Xã Đào Xá",
                        "code": 8662,
                        "codename": "xa_dao_xa",
                        "division_type": "xã",
                        "short_codename": "dao_xa"
                    },
                    {
                        "name": "Xã Thạch Đồng",
                        "code": 8665,
                        "codename": "xa_thach_dong",
                        "division_type": "xã",
                        "short_codename": "thach_dong"
                    },
                    {
                        "name": "Xã Xuân Lộc",
                        "code": 8668,
                        "codename": "xa_xuan_loc",
                        "division_type": "xã",
                        "short_codename": "xuan_loc"
                    },
                    {
                        "name": "Xã Tân Phương",
                        "code": 8671,
                        "codename": "xa_tan_phuong",
                        "division_type": "xã",
                        "short_codename": "tan_phuong"
                    },
                    {
                        "name": "Thị trấn Thanh Thủy",
                        "code": 8674,
                        "codename": "thi_tran_thanh_thuy",
                        "division_type": "thị trấn",
                        "short_codename": "thanh_thuy"
                    },
                    {
                        "name": "Xã Sơn Thủy",
                        "code": 8677,
                        "codename": "xa_son_thuy",
                        "division_type": "xã",
                        "short_codename": "son_thuy"
                    },
                    {
                        "name": "Xã Bảo Yên",
                        "code": 8680,
                        "codename": "xa_bao_yen",
                        "division_type": "xã",
                        "short_codename": "bao_yen"
                    },
                    {
                        "name": "Xã Đoan Hạ",
                        "code": 8683,
                        "codename": "xa_doan_ha",
                        "division_type": "xã",
                        "short_codename": "doan_ha"
                    },
                    {
                        "name": "Xã Đồng Trung",
                        "code": 8686,
                        "codename": "xa_dong_trung",
                        "division_type": "xã",
                        "short_codename": "dong_trung"
                    },
                    {
                        "name": "Xã Hoàng Xá",
                        "code": 8689,
                        "codename": "xa_hoang_xa",
                        "division_type": "xã",
                        "short_codename": "hoang_xa"
                    },
                    {
                        "name": "Xã Tu Vũ",
                        "code": 8701,
                        "codename": "xa_tu_vu",
                        "division_type": "xã",
                        "short_codename": "tu_vu"
                    }
                ]
            },
            {
                "name": "Huyện Tân Sơn",
                "code": 240,
                "codename": "huyen_tan_son",
                "division_type": "huyện",
                "short_codename": "tan_son",
                "wards": [
                    {
                        "name": "Xã Thu Cúc",
                        "code": 8545,
                        "codename": "xa_thu_cuc",
                        "division_type": "xã",
                        "short_codename": "thu_cuc"
                    },
                    {
                        "name": "Xã Thạch Kiệt",
                        "code": 8548,
                        "codename": "xa_thach_kiet",
                        "division_type": "xã",
                        "short_codename": "thach_kiet"
                    },
                    {
                        "name": "Xã Thu Ngạc",
                        "code": 8551,
                        "codename": "xa_thu_ngac",
                        "division_type": "xã",
                        "short_codename": "thu_ngac"
                    },
                    {
                        "name": "Xã Kiệt Sơn",
                        "code": 8554,
                        "codename": "xa_kiet_son",
                        "division_type": "xã",
                        "short_codename": "kiet_son"
                    },
                    {
                        "name": "Xã Đồng Sơn",
                        "code": 8557,
                        "codename": "xa_dong_son",
                        "division_type": "xã",
                        "short_codename": "dong_son"
                    },
                    {
                        "name": "Xã Lai Đồng",
                        "code": 8560,
                        "codename": "xa_lai_dong",
                        "division_type": "xã",
                        "short_codename": "lai_dong"
                    },
                    {
                        "name": "Xã Tân Phú",
                        "code": 8566,
                        "codename": "xa_tan_phu",
                        "division_type": "xã",
                        "short_codename": "tan_phu"
                    },
                    {
                        "name": "Xã Mỹ Thuận",
                        "code": 8569,
                        "codename": "xa_my_thuan",
                        "division_type": "xã",
                        "short_codename": "my_thuan"
                    },
                    {
                        "name": "Xã Tân Sơn",
                        "code": 8578,
                        "codename": "xa_tan_son",
                        "division_type": "xã",
                        "short_codename": "tan_son"
                    },
                    {
                        "name": "Xã Xuân Đài",
                        "code": 8590,
                        "codename": "xa_xuan_dai",
                        "division_type": "xã",
                        "short_codename": "xuan_dai"
                    },
                    {
                        "name": "Xã Minh Đài",
                        "code": 8593,
                        "codename": "xa_minh_dai",
                        "division_type": "xã",
                        "short_codename": "minh_dai"
                    },
                    {
                        "name": "Xã Văn Luông",
                        "code": 8596,
                        "codename": "xa_van_luong",
                        "division_type": "xã",
                        "short_codename": "van_luong"
                    },
                    {
                        "name": "Xã Xuân Sơn",
                        "code": 8599,
                        "codename": "xa_xuan_son",
                        "division_type": "xã",
                        "short_codename": "xuan_son"
                    },
                    {
                        "name": "Xã Long Cốc",
                        "code": 8608,
                        "codename": "xa_long_coc",
                        "division_type": "xã",
                        "short_codename": "long_coc"
                    },
                    {
                        "name": "Xã Kim Thượng",
                        "code": 8617,
                        "codename": "xa_kim_thuong",
                        "division_type": "xã",
                        "short_codename": "kim_thuong"
                    },
                    {
                        "name": "Xã Tam Thanh",
                        "code": 8620,
                        "codename": "xa_tam_thanh",
                        "division_type": "xã",
                        "short_codename": "tam_thanh"
                    },
                    {
                        "name": "Xã Vinh Tiền",
                        "code": 8626,
                        "codename": "xa_vinh_tien",
                        "division_type": "xã",
                        "short_codename": "vinh_tien"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Vĩnh Phúc",
        "code": 26,
        "codename": "tinh_vinh_phuc",
        "division_type": "tỉnh",
        "phone_code": 211,
        "districts": [
            {
                "name": "Thành phố Vĩnh Yên",
                "code": 243,
                "codename": "thanh_pho_vinh_yen",
                "division_type": "thành phố",
                "short_codename": "vinh_yen",
                "wards": [
                    {
                        "name": "Phường Tích Sơn",
                        "code": 8707,
                        "codename": "phuong_tich_son",
                        "division_type": "phường",
                        "short_codename": "tich_son"
                    },
                    {
                        "name": "Phường Liên Bảo",
                        "code": 8710,
                        "codename": "phuong_lien_bao",
                        "division_type": "phường",
                        "short_codename": "lien_bao"
                    },
                    {
                        "name": "Phường Hội Hợp",
                        "code": 8713,
                        "codename": "phuong_hoi_hop",
                        "division_type": "phường",
                        "short_codename": "hoi_hop"
                    },
                    {
                        "name": "Phường Đống Đa",
                        "code": 8716,
                        "codename": "phuong_dong_da",
                        "division_type": "phường",
                        "short_codename": "dong_da"
                    },
                    {
                        "name": "Phường Ngô Quyền",
                        "code": 8719,
                        "codename": "phuong_ngo_quyen",
                        "division_type": "phường",
                        "short_codename": "ngo_quyen"
                    },
                    {
                        "name": "Phường Đồng Tâm",
                        "code": 8722,
                        "codename": "phuong_dong_tam",
                        "division_type": "phường",
                        "short_codename": "dong_tam"
                    },
                    {
                        "name": "Xã Định Trung",
                        "code": 8725,
                        "codename": "xa_dinh_trung",
                        "division_type": "xã",
                        "short_codename": "dinh_trung"
                    },
                    {
                        "name": "Phường Khai Quang",
                        "code": 8728,
                        "codename": "phuong_khai_quang",
                        "division_type": "phường",
                        "short_codename": "khai_quang"
                    },
                    {
                        "name": "Xã Thanh Trù",
                        "code": 8731,
                        "codename": "xa_thanh_tru",
                        "division_type": "xã",
                        "short_codename": "thanh_tru"
                    }
                ]
            },
            {
                "name": "Thành phố Phúc Yên",
                "code": 244,
                "codename": "thanh_pho_phuc_yen",
                "division_type": "thành phố",
                "short_codename": "phuc_yen",
                "wards": [
                    {
                        "name": "Phường Trưng Trắc",
                        "code": 8734,
                        "codename": "phuong_trung_trac",
                        "division_type": "phường",
                        "short_codename": "trung_trac"
                    },
                    {
                        "name": "Phường Hùng Vương",
                        "code": 8737,
                        "codename": "phuong_hung_vuong",
                        "division_type": "phường",
                        "short_codename": "hung_vuong"
                    },
                    {
                        "name": "Phường Trưng Nhị",
                        "code": 8740,
                        "codename": "phuong_trung_nhi",
                        "division_type": "phường",
                        "short_codename": "trung_nhi"
                    },
                    {
                        "name": "Phường Phúc Thắng",
                        "code": 8743,
                        "codename": "phuong_phuc_thang",
                        "division_type": "phường",
                        "short_codename": "phuc_thang"
                    },
                    {
                        "name": "Phường Xuân Hoà",
                        "code": 8746,
                        "codename": "phuong_xuan_hoa",
                        "division_type": "phường",
                        "short_codename": "xuan_hoa"
                    },
                    {
                        "name": "Phường Đồng Xuân",
                        "code": 8747,
                        "codename": "phuong_dong_xuan",
                        "division_type": "phường",
                        "short_codename": "dong_xuan"
                    },
                    {
                        "name": "Xã Ngọc Thanh",
                        "code": 8749,
                        "codename": "xa_ngoc_thanh",
                        "division_type": "xã",
                        "short_codename": "ngoc_thanh"
                    },
                    {
                        "name": "Xã Cao Minh",
                        "code": 8752,
                        "codename": "xa_cao_minh",
                        "division_type": "xã",
                        "short_codename": "cao_minh"
                    },
                    {
                        "name": "Phường Nam Viêm",
                        "code": 8755,
                        "codename": "phuong_nam_viem",
                        "division_type": "phường",
                        "short_codename": "nam_viem"
                    },
                    {
                        "name": "Phường Tiền Châu",
                        "code": 8758,
                        "codename": "phuong_tien_chau",
                        "division_type": "phường",
                        "short_codename": "tien_chau"
                    }
                ]
            },
            {
                "name": "Huyện Lập Thạch",
                "code": 246,
                "codename": "huyen_lap_thach",
                "division_type": "huyện",
                "short_codename": "lap_thach",
                "wards": [
                    {
                        "name": "Thị trấn Lập Thạch",
                        "code": 8761,
                        "codename": "thi_tran_lap_thach",
                        "division_type": "thị trấn",
                        "short_codename": "lap_thach"
                    },
                    {
                        "name": "Xã Quang Sơn",
                        "code": 8764,
                        "codename": "xa_quang_son",
                        "division_type": "xã",
                        "short_codename": "quang_son"
                    },
                    {
                        "name": "Xã Ngọc Mỹ",
                        "code": 8767,
                        "codename": "xa_ngoc_my",
                        "division_type": "xã",
                        "short_codename": "ngoc_my"
                    },
                    {
                        "name": "Xã Hợp Lý",
                        "code": 8770,
                        "codename": "xa_hop_ly",
                        "division_type": "xã",
                        "short_codename": "hop_ly"
                    },
                    {
                        "name": "Xã Bắc Bình",
                        "code": 8785,
                        "codename": "xa_bac_binh",
                        "division_type": "xã",
                        "short_codename": "bac_binh"
                    },
                    {
                        "name": "Xã Thái Hòa",
                        "code": 8788,
                        "codename": "xa_thai_hoa",
                        "division_type": "xã",
                        "short_codename": "thai_hoa"
                    },
                    {
                        "name": "Thị trấn Hoa Sơn",
                        "code": 8789,
                        "codename": "thi_tran_hoa_son",
                        "division_type": "thị trấn",
                        "short_codename": "hoa_son"
                    },
                    {
                        "name": "Xã Liễn Sơn",
                        "code": 8791,
                        "codename": "xa_lien_son",
                        "division_type": "xã",
                        "short_codename": "lien_son"
                    },
                    {
                        "name": "Xã Xuân Hòa",
                        "code": 8794,
                        "codename": "xa_xuan_hoa",
                        "division_type": "xã",
                        "short_codename": "xuan_hoa"
                    },
                    {
                        "name": "Xã Vân Trục",
                        "code": 8797,
                        "codename": "xa_van_truc",
                        "division_type": "xã",
                        "short_codename": "van_truc"
                    },
                    {
                        "name": "Xã Liên Hòa",
                        "code": 8812,
                        "codename": "xa_lien_hoa",
                        "division_type": "xã",
                        "short_codename": "lien_hoa"
                    },
                    {
                        "name": "Xã Tử Du",
                        "code": 8815,
                        "codename": "xa_tu_du",
                        "division_type": "xã",
                        "short_codename": "tu_du"
                    },
                    {
                        "name": "Xã Bàn Giản",
                        "code": 8833,
                        "codename": "xa_ban_gian",
                        "division_type": "xã",
                        "short_codename": "ban_gian"
                    },
                    {
                        "name": "Xã Xuân Lôi",
                        "code": 8836,
                        "codename": "xa_xuan_loi",
                        "division_type": "xã",
                        "short_codename": "xuan_loi"
                    },
                    {
                        "name": "Xã Đồng Ích",
                        "code": 8839,
                        "codename": "xa_dong_ich",
                        "division_type": "xã",
                        "short_codename": "dong_ich"
                    },
                    {
                        "name": "Xã Tiên Lữ",
                        "code": 8842,
                        "codename": "xa_tien_lu",
                        "division_type": "xã",
                        "short_codename": "tien_lu"
                    },
                    {
                        "name": "Xã Văn Quán",
                        "code": 8845,
                        "codename": "xa_van_quan",
                        "division_type": "xã",
                        "short_codename": "van_quan"
                    },
                    {
                        "name": "Xã Đình Chu",
                        "code": 8857,
                        "codename": "xa_dinh_chu",
                        "division_type": "xã",
                        "short_codename": "dinh_chu"
                    },
                    {
                        "name": "Xã Triệu Đề",
                        "code": 8863,
                        "codename": "xa_trieu_de",
                        "division_type": "xã",
                        "short_codename": "trieu_de"
                    },
                    {
                        "name": "Xã Sơn Đông",
                        "code": 8866,
                        "codename": "xa_son_dong",
                        "division_type": "xã",
                        "short_codename": "son_dong"
                    }
                ]
            },
            {
                "name": "Huyện Tam Dương",
                "code": 247,
                "codename": "huyen_tam_duong",
                "division_type": "huyện",
                "short_codename": "tam_duong",
                "wards": [
                    {
                        "name": "Thị trấn Hợp Hòa",
                        "code": 8869,
                        "codename": "thi_tran_hop_hoa",
                        "division_type": "thị trấn",
                        "short_codename": "hop_hoa"
                    },
                    {
                        "name": "Xã Hoàng Hoa",
                        "code": 8872,
                        "codename": "xa_hoang_hoa",
                        "division_type": "xã",
                        "short_codename": "hoang_hoa"
                    },
                    {
                        "name": "Xã Đồng Tĩnh",
                        "code": 8875,
                        "codename": "xa_dong_tinh",
                        "division_type": "xã",
                        "short_codename": "dong_tinh"
                    },
                    {
                        "name": "Xã Kim Long",
                        "code": 8878,
                        "codename": "xa_kim_long",
                        "division_type": "xã",
                        "short_codename": "kim_long"
                    },
                    {
                        "name": "Xã Hướng Đạo",
                        "code": 8881,
                        "codename": "xa_huong_dao",
                        "division_type": "xã",
                        "short_codename": "huong_dao"
                    },
                    {
                        "name": "Xã Đạo Tú",
                        "code": 8884,
                        "codename": "xa_dao_tu",
                        "division_type": "xã",
                        "short_codename": "dao_tu"
                    },
                    {
                        "name": "Xã An Hòa",
                        "code": 8887,
                        "codename": "xa_an_hoa",
                        "division_type": "xã",
                        "short_codename": "an_hoa"
                    },
                    {
                        "name": "Xã Thanh Vân",
                        "code": 8890,
                        "codename": "xa_thanh_van",
                        "division_type": "xã",
                        "short_codename": "thanh_van"
                    },
                    {
                        "name": "Xã Duy Phiên",
                        "code": 8893,
                        "codename": "xa_duy_phien",
                        "division_type": "xã",
                        "short_codename": "duy_phien"
                    },
                    {
                        "name": "Xã Hoàng Đan",
                        "code": 8896,
                        "codename": "xa_hoang_dan",
                        "division_type": "xã",
                        "short_codename": "hoang_dan"
                    },
                    {
                        "name": "Xã Hoàng Lâu",
                        "code": 8899,
                        "codename": "xa_hoang_lau",
                        "division_type": "xã",
                        "short_codename": "hoang_lau"
                    },
                    {
                        "name": "Xã Vân Hội",
                        "code": 8902,
                        "codename": "xa_van_hoi",
                        "division_type": "xã",
                        "short_codename": "van_hoi"
                    },
                    {
                        "name": "Xã Hợp Thịnh",
                        "code": 8905,
                        "codename": "xa_hop_thinh",
                        "division_type": "xã",
                        "short_codename": "hop_thinh"
                    }
                ]
            },
            {
                "name": "Huyện Tam Đảo",
                "code": 248,
                "codename": "huyen_tam_dao",
                "division_type": "huyện",
                "short_codename": "tam_dao",
                "wards": [
                    {
                        "name": "Thị trấn Tam Đảo",
                        "code": 8908,
                        "codename": "thi_tran_tam_dao",
                        "division_type": "thị trấn",
                        "short_codename": "tam_dao"
                    },
                    {
                        "name": "Thị trấn Hợp Châu",
                        "code": 8911,
                        "codename": "thi_tran_hop_chau",
                        "division_type": "thị trấn",
                        "short_codename": "hop_chau"
                    },
                    {
                        "name": "Xã Đạo Trù",
                        "code": 8914,
                        "codename": "xa_dao_tru",
                        "division_type": "xã",
                        "short_codename": "dao_tru"
                    },
                    {
                        "name": "Xã Yên Dương",
                        "code": 8917,
                        "codename": "xa_yen_duong",
                        "division_type": "xã",
                        "short_codename": "yen_duong"
                    },
                    {
                        "name": "Xã Bồ Lý",
                        "code": 8920,
                        "codename": "xa_bo_ly",
                        "division_type": "xã",
                        "short_codename": "bo_ly"
                    },
                    {
                        "name": "Thị trấn Đại Đình",
                        "code": 8923,
                        "codename": "thi_tran_dai_dinh",
                        "division_type": "thị trấn",
                        "short_codename": "dai_dinh"
                    },
                    {
                        "name": "Xã Tam Quan",
                        "code": 8926,
                        "codename": "xa_tam_quan",
                        "division_type": "xã",
                        "short_codename": "tam_quan"
                    },
                    {
                        "name": "Xã Hồ Sơn",
                        "code": 8929,
                        "codename": "xa_ho_son",
                        "division_type": "xã",
                        "short_codename": "ho_son"
                    },
                    {
                        "name": "Xã Minh Quang",
                        "code": 8932,
                        "codename": "xa_minh_quang",
                        "division_type": "xã",
                        "short_codename": "minh_quang"
                    }
                ]
            },
            {
                "name": "Huyện Bình Xuyên",
                "code": 249,
                "codename": "huyen_binh_xuyen",
                "division_type": "huyện",
                "short_codename": "binh_xuyen",
                "wards": [
                    {
                        "name": "Thị trấn Hương Canh",
                        "code": 8935,
                        "codename": "thi_tran_huong_canh",
                        "division_type": "thị trấn",
                        "short_codename": "huong_canh"
                    },
                    {
                        "name": "Thị trấn Gia Khánh",
                        "code": 8936,
                        "codename": "thi_tran_gia_khanh",
                        "division_type": "thị trấn",
                        "short_codename": "gia_khanh"
                    },
                    {
                        "name": "Xã Trung Mỹ",
                        "code": 8938,
                        "codename": "xa_trung_my",
                        "division_type": "xã",
                        "short_codename": "trung_my"
                    },
                    {
                        "name": "Thị trấn Bá Hiến",
                        "code": 8944,
                        "codename": "thi_tran_ba_hien",
                        "division_type": "thị trấn",
                        "short_codename": "ba_hien"
                    },
                    {
                        "name": "Xã Thiện Kế",
                        "code": 8947,
                        "codename": "xa_thien_ke",
                        "division_type": "xã",
                        "short_codename": "thien_ke"
                    },
                    {
                        "name": "Xã Hương Sơn",
                        "code": 8950,
                        "codename": "xa_huong_son",
                        "division_type": "xã",
                        "short_codename": "huong_son"
                    },
                    {
                        "name": "Xã Tam Hợp",
                        "code": 8953,
                        "codename": "xa_tam_hop",
                        "division_type": "xã",
                        "short_codename": "tam_hop"
                    },
                    {
                        "name": "Xã Quất Lưu",
                        "code": 8956,
                        "codename": "xa_quat_luu",
                        "division_type": "xã",
                        "short_codename": "quat_luu"
                    },
                    {
                        "name": "Xã Sơn Lôi",
                        "code": 8959,
                        "codename": "xa_son_loi",
                        "division_type": "xã",
                        "short_codename": "son_loi"
                    },
                    {
                        "name": "Thị trấn Đạo Đức",
                        "code": 8962,
                        "codename": "thi_tran_dao_duc",
                        "division_type": "thị trấn",
                        "short_codename": "dao_duc"
                    },
                    {
                        "name": "Xã Tân Phong",
                        "code": 8965,
                        "codename": "xa_tan_phong",
                        "division_type": "xã",
                        "short_codename": "tan_phong"
                    },
                    {
                        "name": "Thị trấn Thanh Lãng",
                        "code": 8968,
                        "codename": "thi_tran_thanh_lang",
                        "division_type": "thị trấn",
                        "short_codename": "thanh_lang"
                    },
                    {
                        "name": "Xã Phú Xuân",
                        "code": 8971,
                        "codename": "xa_phu_xuan",
                        "division_type": "xã",
                        "short_codename": "phu_xuan"
                    }
                ]
            },
            {
                "name": "Huyện Yên Lạc",
                "code": 251,
                "codename": "huyen_yen_lac",
                "division_type": "huyện",
                "short_codename": "yen_lac",
                "wards": [
                    {
                        "name": "Thị trấn Yên Lạc",
                        "code": 9025,
                        "codename": "thi_tran_yen_lac",
                        "division_type": "thị trấn",
                        "short_codename": "yen_lac"
                    },
                    {
                        "name": "Xã Đồng Cương",
                        "code": 9028,
                        "codename": "xa_dong_cuong",
                        "division_type": "xã",
                        "short_codename": "dong_cuong"
                    },
                    {
                        "name": "Xã Đồng Văn",
                        "code": 9031,
                        "codename": "xa_dong_van",
                        "division_type": "xã",
                        "short_codename": "dong_van"
                    },
                    {
                        "name": "Xã Bình Định",
                        "code": 9034,
                        "codename": "xa_binh_dinh",
                        "division_type": "xã",
                        "short_codename": "binh_dinh"
                    },
                    {
                        "name": "Xã Trung Nguyên",
                        "code": 9037,
                        "codename": "xa_trung_nguyen",
                        "division_type": "xã",
                        "short_codename": "trung_nguyen"
                    },
                    {
                        "name": "Xã Tề Lỗ",
                        "code": 9040,
                        "codename": "xa_te_lo",
                        "division_type": "xã",
                        "short_codename": "te_lo"
                    },
                    {
                        "name": "Xã Tam Hồng",
                        "code": 9043,
                        "codename": "xa_tam_hong",
                        "division_type": "xã",
                        "short_codename": "tam_hong"
                    },
                    {
                        "name": "Xã Yên Đồng",
                        "code": 9046,
                        "codename": "xa_yen_dong",
                        "division_type": "xã",
                        "short_codename": "yen_dong"
                    },
                    {
                        "name": "Xã Văn Tiến",
                        "code": 9049,
                        "codename": "xa_van_tien",
                        "division_type": "xã",
                        "short_codename": "van_tien"
                    },
                    {
                        "name": "Xã Nguyệt Đức",
                        "code": 9052,
                        "codename": "xa_nguyet_duc",
                        "division_type": "xã",
                        "short_codename": "nguyet_duc"
                    },
                    {
                        "name": "Xã Yên Phương",
                        "code": 9055,
                        "codename": "xa_yen_phuong",
                        "division_type": "xã",
                        "short_codename": "yen_phuong"
                    },
                    {
                        "name": "Xã Hồng Phương",
                        "code": 9058,
                        "codename": "xa_hong_phuong",
                        "division_type": "xã",
                        "short_codename": "hong_phuong"
                    },
                    {
                        "name": "Xã Trung Kiên",
                        "code": 9061,
                        "codename": "xa_trung_kien",
                        "division_type": "xã",
                        "short_codename": "trung_kien"
                    },
                    {
                        "name": "Xã Liên Châu",
                        "code": 9064,
                        "codename": "xa_lien_chau",
                        "division_type": "xã",
                        "short_codename": "lien_chau"
                    },
                    {
                        "name": "Xã Đại Tự",
                        "code": 9067,
                        "codename": "xa_dai_tu",
                        "division_type": "xã",
                        "short_codename": "dai_tu"
                    },
                    {
                        "name": "Xã Hồng Châu",
                        "code": 9070,
                        "codename": "xa_hong_chau",
                        "division_type": "xã",
                        "short_codename": "hong_chau"
                    },
                    {
                        "name": "Xã Trung Hà",
                        "code": 9073,
                        "codename": "xa_trung_ha",
                        "division_type": "xã",
                        "short_codename": "trung_ha"
                    }
                ]
            },
            {
                "name": "Huyện Vĩnh Tường",
                "code": 252,
                "codename": "huyen_vinh_tuong",
                "division_type": "huyện",
                "short_codename": "vinh_tuong",
                "wards": [
                    {
                        "name": "Thị trấn Vĩnh Tường",
                        "code": 9076,
                        "codename": "thi_tran_vinh_tuong",
                        "division_type": "thị trấn",
                        "short_codename": "vinh_tuong"
                    },
                    {
                        "name": "Xã Kim Xá",
                        "code": 9079,
                        "codename": "xa_kim_xa",
                        "division_type": "xã",
                        "short_codename": "kim_xa"
                    },
                    {
                        "name": "Xã Yên Bình",
                        "code": 9082,
                        "codename": "xa_yen_binh",
                        "division_type": "xã",
                        "short_codename": "yen_binh"
                    },
                    {
                        "name": "Xã Chấn Hưng",
                        "code": 9085,
                        "codename": "xa_chan_hung",
                        "division_type": "xã",
                        "short_codename": "chan_hung"
                    },
                    {
                        "name": "Xã Nghĩa Hưng",
                        "code": 9088,
                        "codename": "xa_nghia_hung",
                        "division_type": "xã",
                        "short_codename": "nghia_hung"
                    },
                    {
                        "name": "Xã Yên Lập",
                        "code": 9091,
                        "codename": "xa_yen_lap",
                        "division_type": "xã",
                        "short_codename": "yen_lap"
                    },
                    {
                        "name": "Xã Việt Xuân",
                        "code": 9094,
                        "codename": "xa_viet_xuan",
                        "division_type": "xã",
                        "short_codename": "viet_xuan"
                    },
                    {
                        "name": "Xã Bồ Sao",
                        "code": 9097,
                        "codename": "xa_bo_sao",
                        "division_type": "xã",
                        "short_codename": "bo_sao"
                    },
                    {
                        "name": "Xã Đại Đồng",
                        "code": 9100,
                        "codename": "xa_dai_dong",
                        "division_type": "xã",
                        "short_codename": "dai_dong"
                    },
                    {
                        "name": "Xã Tân Tiến",
                        "code": 9103,
                        "codename": "xa_tan_tien",
                        "division_type": "xã",
                        "short_codename": "tan_tien"
                    },
                    {
                        "name": "Xã Lũng Hoà",
                        "code": 9106,
                        "codename": "xa_lung_hoa",
                        "division_type": "xã",
                        "short_codename": "lung_hoa"
                    },
                    {
                        "name": "Xã Cao Đại",
                        "code": 9109,
                        "codename": "xa_cao_dai",
                        "division_type": "xã",
                        "short_codename": "cao_dai"
                    },
                    {
                        "name": "Thị Trấn Thổ Tang",
                        "code": 9112,
                        "codename": "thi_tran_tho_tang",
                        "division_type": "thị trấn",
                        "short_codename": "tho_tang"
                    },
                    {
                        "name": "Xã Vĩnh Sơn",
                        "code": 9115,
                        "codename": "xa_vinh_son",
                        "division_type": "xã",
                        "short_codename": "vinh_son"
                    },
                    {
                        "name": "Xã Bình Dương",
                        "code": 9118,
                        "codename": "xa_binh_duong",
                        "division_type": "xã",
                        "short_codename": "binh_duong"
                    },
                    {
                        "name": "Xã Tân Phú",
                        "code": 9124,
                        "codename": "xa_tan_phu",
                        "division_type": "xã",
                        "short_codename": "tan_phu"
                    },
                    {
                        "name": "Xã Thượng Trưng",
                        "code": 9127,
                        "codename": "xa_thuong_trung",
                        "division_type": "xã",
                        "short_codename": "thuong_trung"
                    },
                    {
                        "name": "Xã Vũ Di",
                        "code": 9130,
                        "codename": "xa_vu_di",
                        "division_type": "xã",
                        "short_codename": "vu_di"
                    },
                    {
                        "name": "Xã Lý Nhân",
                        "code": 9133,
                        "codename": "xa_ly_nhan",
                        "division_type": "xã",
                        "short_codename": "ly_nhan"
                    },
                    {
                        "name": "Xã Tuân Chính",
                        "code": 9136,
                        "codename": "xa_tuan_chinh",
                        "division_type": "xã",
                        "short_codename": "tuan_chinh"
                    },
                    {
                        "name": "Xã Vân Xuân",
                        "code": 9139,
                        "codename": "xa_van_xuan",
                        "division_type": "xã",
                        "short_codename": "van_xuan"
                    },
                    {
                        "name": "Xã Tam Phúc",
                        "code": 9142,
                        "codename": "xa_tam_phuc",
                        "division_type": "xã",
                        "short_codename": "tam_phuc"
                    },
                    {
                        "name": "Thị trấn Tứ Trưng",
                        "code": 9145,
                        "codename": "thi_tran_tu_trung",
                        "division_type": "thị trấn",
                        "short_codename": "tu_trung"
                    },
                    {
                        "name": "Xã Ngũ Kiên",
                        "code": 9148,
                        "codename": "xa_ngu_kien",
                        "division_type": "xã",
                        "short_codename": "ngu_kien"
                    },
                    {
                        "name": "Xã An Tường",
                        "code": 9151,
                        "codename": "xa_an_tuong",
                        "division_type": "xã",
                        "short_codename": "an_tuong"
                    },
                    {
                        "name": "Xã Vĩnh Thịnh",
                        "code": 9154,
                        "codename": "xa_vinh_thinh",
                        "division_type": "xã",
                        "short_codename": "vinh_thinh"
                    },
                    {
                        "name": "Xã Phú Đa",
                        "code": 9157,
                        "codename": "xa_phu_da",
                        "division_type": "xã",
                        "short_codename": "phu_da"
                    },
                    {
                        "name": "Xã Vĩnh Ninh",
                        "code": 9160,
                        "codename": "xa_vinh_ninh",
                        "division_type": "xã",
                        "short_codename": "vinh_ninh"
                    }
                ]
            },
            {
                "name": "Huyện Sông Lô",
                "code": 253,
                "codename": "huyen_song_lo",
                "division_type": "huyện",
                "short_codename": "song_lo",
                "wards": [
                    {
                        "name": "Xã Lãng Công",
                        "code": 8773,
                        "codename": "xa_lang_cong",
                        "division_type": "xã",
                        "short_codename": "lang_cong"
                    },
                    {
                        "name": "Xã Quang Yên",
                        "code": 8776,
                        "codename": "xa_quang_yen",
                        "division_type": "xã",
                        "short_codename": "quang_yen"
                    },
                    {
                        "name": "Xã Bạch Lưu",
                        "code": 8779,
                        "codename": "xa_bach_luu",
                        "division_type": "xã",
                        "short_codename": "bach_luu"
                    },
                    {
                        "name": "Xã Hải Lựu",
                        "code": 8782,
                        "codename": "xa_hai_luu",
                        "division_type": "xã",
                        "short_codename": "hai_luu"
                    },
                    {
                        "name": "Xã Đồng Quế",
                        "code": 8800,
                        "codename": "xa_dong_que",
                        "division_type": "xã",
                        "short_codename": "dong_que"
                    },
                    {
                        "name": "Xã Nhân Đạo",
                        "code": 8803,
                        "codename": "xa_nhan_dao",
                        "division_type": "xã",
                        "short_codename": "nhan_dao"
                    },
                    {
                        "name": "Xã Đôn Nhân",
                        "code": 8806,
                        "codename": "xa_don_nhan",
                        "division_type": "xã",
                        "short_codename": "don_nhan"
                    },
                    {
                        "name": "Xã Phương Khoan",
                        "code": 8809,
                        "codename": "xa_phuong_khoan",
                        "division_type": "xã",
                        "short_codename": "phuong_khoan"
                    },
                    {
                        "name": "Xã Tân Lập",
                        "code": 8818,
                        "codename": "xa_tan_lap",
                        "division_type": "xã",
                        "short_codename": "tan_lap"
                    },
                    {
                        "name": "Xã Nhạo Sơn",
                        "code": 8821,
                        "codename": "xa_nhao_son",
                        "division_type": "xã",
                        "short_codename": "nhao_son"
                    },
                    {
                        "name": "Thị trấn Tam Sơn",
                        "code": 8824,
                        "codename": "thi_tran_tam_son",
                        "division_type": "thị trấn",
                        "short_codename": "tam_son"
                    },
                    {
                        "name": "Xã Như Thụy",
                        "code": 8827,
                        "codename": "xa_nhu_thuy",
                        "division_type": "xã",
                        "short_codename": "nhu_thuy"
                    },
                    {
                        "name": "Xã Yên Thạch",
                        "code": 8830,
                        "codename": "xa_yen_thach",
                        "division_type": "xã",
                        "short_codename": "yen_thach"
                    },
                    {
                        "name": "Xã Đồng Thịnh",
                        "code": 8848,
                        "codename": "xa_dong_thinh",
                        "division_type": "xã",
                        "short_codename": "dong_thinh"
                    },
                    {
                        "name": "Xã Tứ Yên",
                        "code": 8851,
                        "codename": "xa_tu_yen",
                        "division_type": "xã",
                        "short_codename": "tu_yen"
                    },
                    {
                        "name": "Xã Đức Bác",
                        "code": 8854,
                        "codename": "xa_duc_bac",
                        "division_type": "xã",
                        "short_codename": "duc_bac"
                    },
                    {
                        "name": "Xã Cao Phong",
                        "code": 8860,
                        "codename": "xa_cao_phong",
                        "division_type": "xã",
                        "short_codename": "cao_phong"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Bắc Ninh",
        "code": 27,
        "codename": "tinh_bac_ninh",
        "division_type": "tỉnh",
        "phone_code": 222,
        "districts": [
            {
                "name": "Thành phố Bắc Ninh",
                "code": 256,
                "codename": "thanh_pho_bac_ninh",
                "division_type": "thành phố",
                "short_codename": "bac_ninh",
                "wards": [
                    {
                        "name": "Phường Vũ Ninh",
                        "code": 9163,
                        "codename": "phuong_vu_ninh",
                        "division_type": "phường",
                        "short_codename": "vu_ninh"
                    },
                    {
                        "name": "Phường Đáp Cầu",
                        "code": 9166,
                        "codename": "phuong_dap_cau",
                        "division_type": "phường",
                        "short_codename": "dap_cau"
                    },
                    {
                        "name": "Phường Thị Cầu",
                        "code": 9169,
                        "codename": "phuong_thi_cau",
                        "division_type": "phường",
                        "short_codename": "thi_cau"
                    },
                    {
                        "name": "Phường Kinh Bắc",
                        "code": 9172,
                        "codename": "phuong_kinh_bac",
                        "division_type": "phường",
                        "short_codename": "kinh_bac"
                    },
                    {
                        "name": "Phường Vệ An",
                        "code": 9175,
                        "codename": "phuong_ve_an",
                        "division_type": "phường",
                        "short_codename": "ve_an"
                    },
                    {
                        "name": "Phường Tiền An",
                        "code": 9178,
                        "codename": "phuong_tien_an",
                        "division_type": "phường",
                        "short_codename": "tien_an"
                    },
                    {
                        "name": "Phường Đại Phúc",
                        "code": 9181,
                        "codename": "phuong_dai_phuc",
                        "division_type": "phường",
                        "short_codename": "dai_phuc"
                    },
                    {
                        "name": "Phường Ninh Xá",
                        "code": 9184,
                        "codename": "phuong_ninh_xa",
                        "division_type": "phường",
                        "short_codename": "ninh_xa"
                    },
                    {
                        "name": "Phường Suối Hoa",
                        "code": 9187,
                        "codename": "phuong_suoi_hoa",
                        "division_type": "phường",
                        "short_codename": "suoi_hoa"
                    },
                    {
                        "name": "Phường Võ Cường",
                        "code": 9190,
                        "codename": "phuong_vo_cuong",
                        "division_type": "phường",
                        "short_codename": "vo_cuong"
                    },
                    {
                        "name": "Phường Hòa Long",
                        "code": 9214,
                        "codename": "phuong_hoa_long",
                        "division_type": "phường",
                        "short_codename": "hoa_long"
                    },
                    {
                        "name": "Phường Vạn An",
                        "code": 9226,
                        "codename": "phuong_van_an",
                        "division_type": "phường",
                        "short_codename": "van_an"
                    },
                    {
                        "name": "Phường Khúc Xuyên",
                        "code": 9235,
                        "codename": "phuong_khuc_xuyen",
                        "division_type": "phường",
                        "short_codename": "khuc_xuyen"
                    },
                    {
                        "name": "Phường Phong Khê",
                        "code": 9244,
                        "codename": "phuong_phong_khe",
                        "division_type": "phường",
                        "short_codename": "phong_khe"
                    },
                    {
                        "name": "Phường Kim Chân",
                        "code": 9256,
                        "codename": "phuong_kim_chan",
                        "division_type": "phường",
                        "short_codename": "kim_chan"
                    },
                    {
                        "name": "Phường Vân Dương",
                        "code": 9271,
                        "codename": "phuong_van_duong",
                        "division_type": "phường",
                        "short_codename": "van_duong"
                    },
                    {
                        "name": "Phường Nam Sơn",
                        "code": 9286,
                        "codename": "phuong_nam_son",
                        "division_type": "phường",
                        "short_codename": "nam_son"
                    },
                    {
                        "name": "Phường Khắc Niệm",
                        "code": 9325,
                        "codename": "phuong_khac_niem",
                        "division_type": "phường",
                        "short_codename": "khac_niem"
                    },
                    {
                        "name": "Phường Hạp Lĩnh",
                        "code": 9331,
                        "codename": "phuong_hap_linh",
                        "division_type": "phường",
                        "short_codename": "hap_linh"
                    }
                ]
            },
            {
                "name": "Huyện Yên Phong",
                "code": 258,
                "codename": "huyen_yen_phong",
                "division_type": "huyện",
                "short_codename": "yen_phong",
                "wards": [
                    {
                        "name": "Thị trấn Chờ",
                        "code": 9193,
                        "codename": "thi_tran_cho",
                        "division_type": "thị trấn",
                        "short_codename": "cho"
                    },
                    {
                        "name": "Xã Dũng Liệt",
                        "code": 9196,
                        "codename": "xa_dung_liet",
                        "division_type": "xã",
                        "short_codename": "dung_liet"
                    },
                    {
                        "name": "Xã Tam Đa",
                        "code": 9199,
                        "codename": "xa_tam_da",
                        "division_type": "xã",
                        "short_codename": "tam_da"
                    },
                    {
                        "name": "Xã Tam Giang",
                        "code": 9202,
                        "codename": "xa_tam_giang",
                        "division_type": "xã",
                        "short_codename": "tam_giang"
                    },
                    {
                        "name": "Xã Yên Trung",
                        "code": 9205,
                        "codename": "xa_yen_trung",
                        "division_type": "xã",
                        "short_codename": "yen_trung"
                    },
                    {
                        "name": "Xã Thụy Hòa",
                        "code": 9208,
                        "codename": "xa_thuy_hoa",
                        "division_type": "xã",
                        "short_codename": "thuy_hoa"
                    },
                    {
                        "name": "Xã Hòa Tiến",
                        "code": 9211,
                        "codename": "xa_hoa_tien",
                        "division_type": "xã",
                        "short_codename": "hoa_tien"
                    },
                    {
                        "name": "Xã Đông Tiến",
                        "code": 9217,
                        "codename": "xa_dong_tien",
                        "division_type": "xã",
                        "short_codename": "dong_tien"
                    },
                    {
                        "name": "Xã Yên Phụ",
                        "code": 9220,
                        "codename": "xa_yen_phu",
                        "division_type": "xã",
                        "short_codename": "yen_phu"
                    },
                    {
                        "name": "Xã Trung Nghĩa",
                        "code": 9223,
                        "codename": "xa_trung_nghia",
                        "division_type": "xã",
                        "short_codename": "trung_nghia"
                    },
                    {
                        "name": "Xã Đông Phong",
                        "code": 9229,
                        "codename": "xa_dong_phong",
                        "division_type": "xã",
                        "short_codename": "dong_phong"
                    },
                    {
                        "name": "Xã Long Châu",
                        "code": 9232,
                        "codename": "xa_long_chau",
                        "division_type": "xã",
                        "short_codename": "long_chau"
                    },
                    {
                        "name": "Xã Văn Môn",
                        "code": 9238,
                        "codename": "xa_van_mon",
                        "division_type": "xã",
                        "short_codename": "van_mon"
                    },
                    {
                        "name": "Xã Đông Thọ",
                        "code": 9241,
                        "codename": "xa_dong_tho",
                        "division_type": "xã",
                        "short_codename": "dong_tho"
                    }
                ]
            },
            {
                "name": "Huyện Quế Võ",
                "code": 259,
                "codename": "huyen_que_vo",
                "division_type": "huyện",
                "short_codename": "que_vo",
                "wards": [
                    {
                        "name": "Thị trấn Phố Mới",
                        "code": 9247,
                        "codename": "thi_tran_pho_moi",
                        "division_type": "thị trấn",
                        "short_codename": "pho_moi"
                    },
                    {
                        "name": "Xã Việt Thống",
                        "code": 9250,
                        "codename": "xa_viet_thong",
                        "division_type": "xã",
                        "short_codename": "viet_thong"
                    },
                    {
                        "name": "Xã Đại Xuân",
                        "code": 9253,
                        "codename": "xa_dai_xuan",
                        "division_type": "xã",
                        "short_codename": "dai_xuan"
                    },
                    {
                        "name": "Xã Nhân Hòa",
                        "code": 9259,
                        "codename": "xa_nhan_hoa",
                        "division_type": "xã",
                        "short_codename": "nhan_hoa"
                    },
                    {
                        "name": "Xã Bằng An",
                        "code": 9262,
                        "codename": "xa_bang_an",
                        "division_type": "xã",
                        "short_codename": "bang_an"
                    },
                    {
                        "name": "Xã Phương Liễu",
                        "code": 9265,
                        "codename": "xa_phuong_lieu",
                        "division_type": "xã",
                        "short_codename": "phuong_lieu"
                    },
                    {
                        "name": "Xã Quế Tân",
                        "code": 9268,
                        "codename": "xa_que_tan",
                        "division_type": "xã",
                        "short_codename": "que_tan"
                    },
                    {
                        "name": "Xã Phù Lương",
                        "code": 9274,
                        "codename": "xa_phu_luong",
                        "division_type": "xã",
                        "short_codename": "phu_luong"
                    },
                    {
                        "name": "Xã Phù Lãng",
                        "code": 9277,
                        "codename": "xa_phu_lang",
                        "division_type": "xã",
                        "short_codename": "phu_lang"
                    },
                    {
                        "name": "Xã Phượng Mao",
                        "code": 9280,
                        "codename": "xa_phuong_mao",
                        "division_type": "xã",
                        "short_codename": "phuong_mao"
                    },
                    {
                        "name": "Xã Việt Hùng",
                        "code": 9283,
                        "codename": "xa_viet_hung",
                        "division_type": "xã",
                        "short_codename": "viet_hung"
                    },
                    {
                        "name": "Xã Ngọc Xá",
                        "code": 9289,
                        "codename": "xa_ngoc_xa",
                        "division_type": "xã",
                        "short_codename": "ngoc_xa"
                    },
                    {
                        "name": "Xã Châu Phong",
                        "code": 9292,
                        "codename": "xa_chau_phong",
                        "division_type": "xã",
                        "short_codename": "chau_phong"
                    },
                    {
                        "name": "Xã Bồng Lai",
                        "code": 9295,
                        "codename": "xa_bong_lai",
                        "division_type": "xã",
                        "short_codename": "bong_lai"
                    },
                    {
                        "name": "Xã Cách Bi",
                        "code": 9298,
                        "codename": "xa_cach_bi",
                        "division_type": "xã",
                        "short_codename": "cach_bi"
                    },
                    {
                        "name": "Xã Đào Viên",
                        "code": 9301,
                        "codename": "xa_dao_vien",
                        "division_type": "xã",
                        "short_codename": "dao_vien"
                    },
                    {
                        "name": "Xã Yên Giả",
                        "code": 9304,
                        "codename": "xa_yen_gia",
                        "division_type": "xã",
                        "short_codename": "yen_gia"
                    },
                    {
                        "name": "Xã Mộ Đạo",
                        "code": 9307,
                        "codename": "xa_mo_dao",
                        "division_type": "xã",
                        "short_codename": "mo_dao"
                    },
                    {
                        "name": "Xã Đức Long",
                        "code": 9310,
                        "codename": "xa_duc_long",
                        "division_type": "xã",
                        "short_codename": "duc_long"
                    },
                    {
                        "name": "Xã Chi Lăng",
                        "code": 9313,
                        "codename": "xa_chi_lang",
                        "division_type": "xã",
                        "short_codename": "chi_lang"
                    },
                    {
                        "name": "Xã Hán Quảng",
                        "code": 9316,
                        "codename": "xa_han_quang",
                        "division_type": "xã",
                        "short_codename": "han_quang"
                    }
                ]
            },
            {
                "name": "Huyện Tiên Du",
                "code": 260,
                "codename": "huyen_tien_du",
                "division_type": "huyện",
                "short_codename": "tien_du",
                "wards": [
                    {
                        "name": "Thị trấn Lim",
                        "code": 9319,
                        "codename": "thi_tran_lim",
                        "division_type": "thị trấn",
                        "short_codename": "lim"
                    },
                    {
                        "name": "Xã Phú Lâm",
                        "code": 9322,
                        "codename": "xa_phu_lam",
                        "division_type": "xã",
                        "short_codename": "phu_lam"
                    },
                    {
                        "name": "Xã Nội Duệ",
                        "code": 9328,
                        "codename": "xa_noi_due",
                        "division_type": "xã",
                        "short_codename": "noi_due"
                    },
                    {
                        "name": "Xã Liên Bão",
                        "code": 9334,
                        "codename": "xa_lien_bao",
                        "division_type": "xã",
                        "short_codename": "lien_bao"
                    },
                    {
                        "name": "Xã Hiên Vân",
                        "code": 9337,
                        "codename": "xa_hien_van",
                        "division_type": "xã",
                        "short_codename": "hien_van"
                    },
                    {
                        "name": "Xã Hoàn Sơn",
                        "code": 9340,
                        "codename": "xa_hoan_son",
                        "division_type": "xã",
                        "short_codename": "hoan_son"
                    },
                    {
                        "name": "Xã Lạc Vệ",
                        "code": 9343,
                        "codename": "xa_lac_ve",
                        "division_type": "xã",
                        "short_codename": "lac_ve"
                    },
                    {
                        "name": "Xã Việt Đoàn",
                        "code": 9346,
                        "codename": "xa_viet_doan",
                        "division_type": "xã",
                        "short_codename": "viet_doan"
                    },
                    {
                        "name": "Xã Phật Tích",
                        "code": 9349,
                        "codename": "xa_phat_tich",
                        "division_type": "xã",
                        "short_codename": "phat_tich"
                    },
                    {
                        "name": "Xã Tân Chi",
                        "code": 9352,
                        "codename": "xa_tan_chi",
                        "division_type": "xã",
                        "short_codename": "tan_chi"
                    },
                    {
                        "name": "Xã Đại Đồng",
                        "code": 9355,
                        "codename": "xa_dai_dong",
                        "division_type": "xã",
                        "short_codename": "dai_dong"
                    },
                    {
                        "name": "Xã Tri Phương",
                        "code": 9358,
                        "codename": "xa_tri_phuong",
                        "division_type": "xã",
                        "short_codename": "tri_phuong"
                    },
                    {
                        "name": "Xã Minh Đạo",
                        "code": 9361,
                        "codename": "xa_minh_dao",
                        "division_type": "xã",
                        "short_codename": "minh_dao"
                    },
                    {
                        "name": "Xã Cảnh Hưng",
                        "code": 9364,
                        "codename": "xa_canh_hung",
                        "division_type": "xã",
                        "short_codename": "canh_hung"
                    }
                ]
            },
            {
                "name": "Thị xã Từ Sơn",
                "code": 261,
                "codename": "thi_xa_tu_son",
                "division_type": "thị xã",
                "short_codename": "tu_son",
                "wards": [
                    {
                        "name": "Phường Đông Ngàn",
                        "code": 9367,
                        "codename": "phuong_dong_ngan",
                        "division_type": "phường",
                        "short_codename": "dong_ngan"
                    },
                    {
                        "name": "Phường Tam Sơn",
                        "code": 9370,
                        "codename": "phuong_tam_son",
                        "division_type": "phường",
                        "short_codename": "tam_son"
                    },
                    {
                        "name": "Phường Hương Mạc",
                        "code": 9373,
                        "codename": "phuong_huong_mac",
                        "division_type": "phường",
                        "short_codename": "huong_mac"
                    },
                    {
                        "name": "Phường Tương Giang",
                        "code": 9376,
                        "codename": "phuong_tuong_giang",
                        "division_type": "phường",
                        "short_codename": "tuong_giang"
                    },
                    {
                        "name": "Phường Phù Khê",
                        "code": 9379,
                        "codename": "phuong_phu_khe",
                        "division_type": "phường",
                        "short_codename": "phu_khe"
                    },
                    {
                        "name": "Phường Đồng Kỵ",
                        "code": 9382,
                        "codename": "phuong_dong_ky",
                        "division_type": "phường",
                        "short_codename": "dong_ky"
                    },
                    {
                        "name": "Phường Trang Hạ",
                        "code": 9383,
                        "codename": "phuong_trang_ha",
                        "division_type": "phường",
                        "short_codename": "trang_ha"
                    },
                    {
                        "name": "Phường Đồng Nguyên",
                        "code": 9385,
                        "codename": "phuong_dong_nguyen",
                        "division_type": "phường",
                        "short_codename": "dong_nguyen"
                    },
                    {
                        "name": "Phường Châu Khê",
                        "code": 9388,
                        "codename": "phuong_chau_khe",
                        "division_type": "phường",
                        "short_codename": "chau_khe"
                    },
                    {
                        "name": "Phường Tân Hồng",
                        "code": 9391,
                        "codename": "phuong_tan_hong",
                        "division_type": "phường",
                        "short_codename": "tan_hong"
                    },
                    {
                        "name": "Phường Đình Bảng",
                        "code": 9394,
                        "codename": "phuong_dinh_bang",
                        "division_type": "phường",
                        "short_codename": "dinh_bang"
                    },
                    {
                        "name": "Phường Phù Chẩn",
                        "code": 9397,
                        "codename": "phuong_phu_chan",
                        "division_type": "phường",
                        "short_codename": "phu_chan"
                    }
                ]
            },
            {
                "name": "Huyện Thuận Thành",
                "code": 262,
                "codename": "huyen_thuan_thanh",
                "division_type": "huyện",
                "short_codename": "thuan_thanh",
                "wards": [
                    {
                        "name": "Thị trấn Hồ",
                        "code": 9400,
                        "codename": "thi_tran_ho",
                        "division_type": "thị trấn",
                        "short_codename": "ho"
                    },
                    {
                        "name": "Xã Hoài Thượng",
                        "code": 9403,
                        "codename": "xa_hoai_thuong",
                        "division_type": "xã",
                        "short_codename": "hoai_thuong"
                    },
                    {
                        "name": "Xã Đại Đồng Thành",
                        "code": 9406,
                        "codename": "xa_dai_dong_thanh",
                        "division_type": "xã",
                        "short_codename": "dai_dong_thanh"
                    },
                    {
                        "name": "Xã Mão Điền",
                        "code": 9409,
                        "codename": "xa_mao_dien",
                        "division_type": "xã",
                        "short_codename": "mao_dien"
                    },
                    {
                        "name": "Xã Song Hồ",
                        "code": 9412,
                        "codename": "xa_song_ho",
                        "division_type": "xã",
                        "short_codename": "song_ho"
                    },
                    {
                        "name": "Xã Đình Tổ",
                        "code": 9415,
                        "codename": "xa_dinh_to",
                        "division_type": "xã",
                        "short_codename": "dinh_to"
                    },
                    {
                        "name": "Xã An Bình",
                        "code": 9418,
                        "codename": "xa_an_binh",
                        "division_type": "xã",
                        "short_codename": "an_binh"
                    },
                    {
                        "name": "Xã Trí Quả",
                        "code": 9421,
                        "codename": "xa_tri_qua",
                        "division_type": "xã",
                        "short_codename": "tri_qua"
                    },
                    {
                        "name": "Xã Gia Đông",
                        "code": 9424,
                        "codename": "xa_gia_dong",
                        "division_type": "xã",
                        "short_codename": "gia_dong"
                    },
                    {
                        "name": "Xã Thanh Khương",
                        "code": 9427,
                        "codename": "xa_thanh_khuong",
                        "division_type": "xã",
                        "short_codename": "thanh_khuong"
                    },
                    {
                        "name": "Xã Trạm Lộ",
                        "code": 9430,
                        "codename": "xa_tram_lo",
                        "division_type": "xã",
                        "short_codename": "tram_lo"
                    },
                    {
                        "name": "Xã Xuân Lâm",
                        "code": 9433,
                        "codename": "xa_xuan_lam",
                        "division_type": "xã",
                        "short_codename": "xuan_lam"
                    },
                    {
                        "name": "Xã Hà Mãn",
                        "code": 9436,
                        "codename": "xa_ha_man",
                        "division_type": "xã",
                        "short_codename": "ha_man"
                    },
                    {
                        "name": "Xã Ngũ Thái",
                        "code": 9439,
                        "codename": "xa_ngu_thai",
                        "division_type": "xã",
                        "short_codename": "ngu_thai"
                    },
                    {
                        "name": "Xã Nguyệt Đức",
                        "code": 9442,
                        "codename": "xa_nguyet_duc",
                        "division_type": "xã",
                        "short_codename": "nguyet_duc"
                    },
                    {
                        "name": "Xã Ninh Xá",
                        "code": 9445,
                        "codename": "xa_ninh_xa",
                        "division_type": "xã",
                        "short_codename": "ninh_xa"
                    },
                    {
                        "name": "Xã Nghĩa Đạo",
                        "code": 9448,
                        "codename": "xa_nghia_dao",
                        "division_type": "xã",
                        "short_codename": "nghia_dao"
                    },
                    {
                        "name": "Xã Song Liễu",
                        "code": 9451,
                        "codename": "xa_song_lieu",
                        "division_type": "xã",
                        "short_codename": "song_lieu"
                    }
                ]
            },
            {
                "name": "Huyện Gia Bình",
                "code": 263,
                "codename": "huyen_gia_binh",
                "division_type": "huyện",
                "short_codename": "gia_binh",
                "wards": [
                    {
                        "name": "Thị trấn Gia Bình",
                        "code": 9454,
                        "codename": "thi_tran_gia_binh",
                        "division_type": "thị trấn",
                        "short_codename": "gia_binh"
                    },
                    {
                        "name": "Xã Vạn Ninh",
                        "code": 9457,
                        "codename": "xa_van_ninh",
                        "division_type": "xã",
                        "short_codename": "van_ninh"
                    },
                    {
                        "name": "Xã Thái Bảo",
                        "code": 9460,
                        "codename": "xa_thai_bao",
                        "division_type": "xã",
                        "short_codename": "thai_bao"
                    },
                    {
                        "name": "Xã Giang Sơn",
                        "code": 9463,
                        "codename": "xa_giang_son",
                        "division_type": "xã",
                        "short_codename": "giang_son"
                    },
                    {
                        "name": "Xã Cao Đức",
                        "code": 9466,
                        "codename": "xa_cao_duc",
                        "division_type": "xã",
                        "short_codename": "cao_duc"
                    },
                    {
                        "name": "Xã Đại Lai",
                        "code": 9469,
                        "codename": "xa_dai_lai",
                        "division_type": "xã",
                        "short_codename": "dai_lai"
                    },
                    {
                        "name": "Xã Song Giang",
                        "code": 9472,
                        "codename": "xa_song_giang",
                        "division_type": "xã",
                        "short_codename": "song_giang"
                    },
                    {
                        "name": "Xã Bình Dương",
                        "code": 9475,
                        "codename": "xa_binh_duong",
                        "division_type": "xã",
                        "short_codename": "binh_duong"
                    },
                    {
                        "name": "Xã Lãng Ngâm",
                        "code": 9478,
                        "codename": "xa_lang_ngam",
                        "division_type": "xã",
                        "short_codename": "lang_ngam"
                    },
                    {
                        "name": "Xã Nhân Thắng",
                        "code": 9481,
                        "codename": "xa_nhan_thang",
                        "division_type": "xã",
                        "short_codename": "nhan_thang"
                    },
                    {
                        "name": "Xã Xuân Lai",
                        "code": 9484,
                        "codename": "xa_xuan_lai",
                        "division_type": "xã",
                        "short_codename": "xuan_lai"
                    },
                    {
                        "name": "Xã Đông Cứu",
                        "code": 9487,
                        "codename": "xa_dong_cuu",
                        "division_type": "xã",
                        "short_codename": "dong_cuu"
                    },
                    {
                        "name": "Xã Đại Bái",
                        "code": 9490,
                        "codename": "xa_dai_bai",
                        "division_type": "xã",
                        "short_codename": "dai_bai"
                    },
                    {
                        "name": "Xã Quỳnh Phú",
                        "code": 9493,
                        "codename": "xa_quynh_phu",
                        "division_type": "xã",
                        "short_codename": "quynh_phu"
                    }
                ]
            },
            {
                "name": "Huyện Lương Tài",
                "code": 264,
                "codename": "huyen_luong_tai",
                "division_type": "huyện",
                "short_codename": "luong_tai",
                "wards": [
                    {
                        "name": "Thị trấn Thứa",
                        "code": 9496,
                        "codename": "thi_tran_thua",
                        "division_type": "thị trấn",
                        "short_codename": "thua"
                    },
                    {
                        "name": "Xã An Thịnh",
                        "code": 9499,
                        "codename": "xa_an_thinh",
                        "division_type": "xã",
                        "short_codename": "an_thinh"
                    },
                    {
                        "name": "Xã Trung Kênh",
                        "code": 9502,
                        "codename": "xa_trung_kenh",
                        "division_type": "xã",
                        "short_codename": "trung_kenh"
                    },
                    {
                        "name": "Xã Phú Hòa",
                        "code": 9505,
                        "codename": "xa_phu_hoa",
                        "division_type": "xã",
                        "short_codename": "phu_hoa"
                    },
                    {
                        "name": "Xã Mỹ Hương",
                        "code": 9508,
                        "codename": "xa_my_huong",
                        "division_type": "xã",
                        "short_codename": "my_huong"
                    },
                    {
                        "name": "Xã Tân Lãng",
                        "code": 9511,
                        "codename": "xa_tan_lang",
                        "division_type": "xã",
                        "short_codename": "tan_lang"
                    },
                    {
                        "name": "Xã Quảng Phú",
                        "code": 9514,
                        "codename": "xa_quang_phu",
                        "division_type": "xã",
                        "short_codename": "quang_phu"
                    },
                    {
                        "name": "Xã Trừng Xá",
                        "code": 9517,
                        "codename": "xa_trung_xa",
                        "division_type": "xã",
                        "short_codename": "trung_xa"
                    },
                    {
                        "name": "Xã Lai Hạ",
                        "code": 9520,
                        "codename": "xa_lai_ha",
                        "division_type": "xã",
                        "short_codename": "lai_ha"
                    },
                    {
                        "name": "Xã Trung Chính",
                        "code": 9523,
                        "codename": "xa_trung_chinh",
                        "division_type": "xã",
                        "short_codename": "trung_chinh"
                    },
                    {
                        "name": "Xã Minh Tân",
                        "code": 9526,
                        "codename": "xa_minh_tan",
                        "division_type": "xã",
                        "short_codename": "minh_tan"
                    },
                    {
                        "name": "Xã Bình Định",
                        "code": 9529,
                        "codename": "xa_binh_dinh",
                        "division_type": "xã",
                        "short_codename": "binh_dinh"
                    },
                    {
                        "name": "Xã Phú Lương",
                        "code": 9532,
                        "codename": "xa_phu_luong",
                        "division_type": "xã",
                        "short_codename": "phu_luong"
                    },
                    {
                        "name": "Xã Lâm Thao",
                        "code": 9535,
                        "codename": "xa_lam_thao",
                        "division_type": "xã",
                        "short_codename": "lam_thao"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Hải Dương",
        "code": 30,
        "codename": "tinh_hai_duong",
        "division_type": "tỉnh",
        "phone_code": 220,
        "districts": [
            {
                "name": "Thành phố Hải Dương",
                "code": 288,
                "codename": "thanh_pho_hai_duong",
                "division_type": "thành phố",
                "short_codename": "hai_duong",
                "wards": [
                    {
                        "name": "Phường Cẩm Thượng",
                        "code": 10507,
                        "codename": "phuong_cam_thuong",
                        "division_type": "phường",
                        "short_codename": "cam_thuong"
                    },
                    {
                        "name": "Phường Bình Hàn",
                        "code": 10510,
                        "codename": "phuong_binh_han",
                        "division_type": "phường",
                        "short_codename": "binh_han"
                    },
                    {
                        "name": "Phường Ngọc Châu",
                        "code": 10513,
                        "codename": "phuong_ngoc_chau",
                        "division_type": "phường",
                        "short_codename": "ngoc_chau"
                    },
                    {
                        "name": "Phường Nhị Châu",
                        "code": 10514,
                        "codename": "phuong_nhi_chau",
                        "division_type": "phường",
                        "short_codename": "nhi_chau"
                    },
                    {
                        "name": "Phường Quang Trung",
                        "code": 10516,
                        "codename": "phuong_quang_trung",
                        "division_type": "phường",
                        "short_codename": "quang_trung"
                    },
                    {
                        "name": "Phường Nguyễn Trãi",
                        "code": 10519,
                        "codename": "phuong_nguyen_trai",
                        "division_type": "phường",
                        "short_codename": "nguyen_trai"
                    },
                    {
                        "name": "Phường Phạm Ngũ Lão",
                        "code": 10522,
                        "codename": "phuong_pham_ngu_lao",
                        "division_type": "phường",
                        "short_codename": "pham_ngu_lao"
                    },
                    {
                        "name": "Phường Trần Hưng Đạo",
                        "code": 10525,
                        "codename": "phuong_tran_hung_dao",
                        "division_type": "phường",
                        "short_codename": "tran_hung_dao"
                    },
                    {
                        "name": "Phường Trần Phú",
                        "code": 10528,
                        "codename": "phuong_tran_phu",
                        "division_type": "phường",
                        "short_codename": "tran_phu"
                    },
                    {
                        "name": "Phường Thanh Bình",
                        "code": 10531,
                        "codename": "phuong_thanh_binh",
                        "division_type": "phường",
                        "short_codename": "thanh_binh"
                    },
                    {
                        "name": "Phường Tân Bình",
                        "code": 10532,
                        "codename": "phuong_tan_binh",
                        "division_type": "phường",
                        "short_codename": "tan_binh"
                    },
                    {
                        "name": "Phường Lê Thanh Nghị",
                        "code": 10534,
                        "codename": "phuong_le_thanh_nghi",
                        "division_type": "phường",
                        "short_codename": "le_thanh_nghi"
                    },
                    {
                        "name": "Phường Hải Tân",
                        "code": 10537,
                        "codename": "phuong_hai_tan",
                        "division_type": "phường",
                        "short_codename": "hai_tan"
                    },
                    {
                        "name": "Phường Tứ Minh",
                        "code": 10540,
                        "codename": "phuong_tu_minh",
                        "division_type": "phường",
                        "short_codename": "tu_minh"
                    },
                    {
                        "name": "Phường Việt Hoà",
                        "code": 10543,
                        "codename": "phuong_viet_hoa",
                        "division_type": "phường",
                        "short_codename": "viet_hoa"
                    },
                    {
                        "name": "Phường Ái Quốc",
                        "code": 10660,
                        "codename": "phuong_ai_quoc",
                        "division_type": "phường",
                        "short_codename": "ai_quoc"
                    },
                    {
                        "name": "Xã An Thượng",
                        "code": 10663,
                        "codename": "xa_an_thuong",
                        "division_type": "xã",
                        "short_codename": "an_thuong"
                    },
                    {
                        "name": "Phường Nam Đồng",
                        "code": 10672,
                        "codename": "phuong_nam_dong",
                        "division_type": "phường",
                        "short_codename": "nam_dong"
                    },
                    {
                        "name": "Xã Quyết Thắng",
                        "code": 10822,
                        "codename": "xa_quyet_thang",
                        "division_type": "xã",
                        "short_codename": "quyet_thang"
                    },
                    {
                        "name": "Xã Tiền Tiến",
                        "code": 10837,
                        "codename": "xa_tien_tien",
                        "division_type": "xã",
                        "short_codename": "tien_tien"
                    },
                    {
                        "name": "Phường Thạch Khôi",
                        "code": 11002,
                        "codename": "phuong_thach_khoi",
                        "division_type": "phường",
                        "short_codename": "thach_khoi"
                    },
                    {
                        "name": "Xã Liên Hồng",
                        "code": 11005,
                        "codename": "xa_lien_hong",
                        "division_type": "xã",
                        "short_codename": "lien_hong"
                    },
                    {
                        "name": "Phường Tân Hưng",
                        "code": 11011,
                        "codename": "phuong_tan_hung",
                        "division_type": "phường",
                        "short_codename": "tan_hung"
                    },
                    {
                        "name": "Xã Gia Xuyên",
                        "code": 11017,
                        "codename": "xa_gia_xuyen",
                        "division_type": "xã",
                        "short_codename": "gia_xuyen"
                    },
                    {
                        "name": "Xã Ngọc Sơn",
                        "code": 11077,
                        "codename": "xa_ngoc_son",
                        "division_type": "xã",
                        "short_codename": "ngoc_son"
                    }
                ]
            },
            {
                "name": "Thành phố Chí Linh",
                "code": 290,
                "codename": "thanh_pho_chi_linh",
                "division_type": "thành phố",
                "short_codename": "chi_linh",
                "wards": [
                    {
                        "name": "Phường Phả Lại",
                        "code": 10546,
                        "codename": "phuong_pha_lai",
                        "division_type": "phường",
                        "short_codename": "pha_lai"
                    },
                    {
                        "name": "Phường Sao Đỏ",
                        "code": 10549,
                        "codename": "phuong_sao_do",
                        "division_type": "phường",
                        "short_codename": "sao_do"
                    },
                    {
                        "name": "Phường Bến Tắm",
                        "code": 10552,
                        "codename": "phuong_ben_tam",
                        "division_type": "phường",
                        "short_codename": "ben_tam"
                    },
                    {
                        "name": "Xã Hoàng Hoa Thám",
                        "code": 10555,
                        "codename": "xa_hoang_hoa_tham",
                        "division_type": "xã",
                        "short_codename": "hoang_hoa_tham"
                    },
                    {
                        "name": "Xã Bắc An",
                        "code": 10558,
                        "codename": "xa_bac_an",
                        "division_type": "xã",
                        "short_codename": "bac_an"
                    },
                    {
                        "name": "Xã Hưng Đạo",
                        "code": 10561,
                        "codename": "xa_hung_dao",
                        "division_type": "xã",
                        "short_codename": "hung_dao"
                    },
                    {
                        "name": "Xã Lê Lợi",
                        "code": 10564,
                        "codename": "xa_le_loi",
                        "division_type": "xã",
                        "short_codename": "le_loi"
                    },
                    {
                        "name": "Phường Hoàng Tiến",
                        "code": 10567,
                        "codename": "phuong_hoang_tien",
                        "division_type": "phường",
                        "short_codename": "hoang_tien"
                    },
                    {
                        "name": "Phường Cộng Hoà",
                        "code": 10570,
                        "codename": "phuong_cong_hoa",
                        "division_type": "phường",
                        "short_codename": "cong_hoa"
                    },
                    {
                        "name": "Phường Hoàng Tân",
                        "code": 10573,
                        "codename": "phuong_hoang_tan",
                        "division_type": "phường",
                        "short_codename": "hoang_tan"
                    },
                    {
                        "name": "Phường Cổ Thành",
                        "code": 10576,
                        "codename": "phuong_co_thanh",
                        "division_type": "phường",
                        "short_codename": "co_thanh"
                    },
                    {
                        "name": "Phường Văn An",
                        "code": 10579,
                        "codename": "phuong_van_an",
                        "division_type": "phường",
                        "short_codename": "van_an"
                    },
                    {
                        "name": "Phường Chí Minh",
                        "code": 10582,
                        "codename": "phuong_chi_minh",
                        "division_type": "phường",
                        "short_codename": "chi_minh"
                    },
                    {
                        "name": "Phường Văn Đức",
                        "code": 10585,
                        "codename": "phuong_van_duc",
                        "division_type": "phường",
                        "short_codename": "van_duc"
                    },
                    {
                        "name": "Phường Thái Học",
                        "code": 10588,
                        "codename": "phuong_thai_hoc",
                        "division_type": "phường",
                        "short_codename": "thai_hoc"
                    },
                    {
                        "name": "Xã Nhân Huệ",
                        "code": 10591,
                        "codename": "xa_nhan_hue",
                        "division_type": "xã",
                        "short_codename": "nhan_hue"
                    },
                    {
                        "name": "Phường An Lạc",
                        "code": 10594,
                        "codename": "phuong_an_lac",
                        "division_type": "phường",
                        "short_codename": "an_lac"
                    },
                    {
                        "name": "Phường Đồng Lạc",
                        "code": 10600,
                        "codename": "phuong_dong_lac",
                        "division_type": "phường",
                        "short_codename": "dong_lac"
                    },
                    {
                        "name": "Phường Tân Dân",
                        "code": 10603,
                        "codename": "phuong_tan_dan",
                        "division_type": "phường",
                        "short_codename": "tan_dan"
                    }
                ]
            },
            {
                "name": "Huyện Nam Sách",
                "code": 291,
                "codename": "huyen_nam_sach",
                "division_type": "huyện",
                "short_codename": "nam_sach",
                "wards": [
                    {
                        "name": "Thị trấn Nam Sách",
                        "code": 10606,
                        "codename": "thi_tran_nam_sach",
                        "division_type": "thị trấn",
                        "short_codename": "nam_sach"
                    },
                    {
                        "name": "Xã Nam Hưng",
                        "code": 10609,
                        "codename": "xa_nam_hung",
                        "division_type": "xã",
                        "short_codename": "nam_hung"
                    },
                    {
                        "name": "Xã Nam Tân",
                        "code": 10612,
                        "codename": "xa_nam_tan",
                        "division_type": "xã",
                        "short_codename": "nam_tan"
                    },
                    {
                        "name": "Xã Hợp Tiến",
                        "code": 10615,
                        "codename": "xa_hop_tien",
                        "division_type": "xã",
                        "short_codename": "hop_tien"
                    },
                    {
                        "name": "Xã Hiệp Cát",
                        "code": 10618,
                        "codename": "xa_hiep_cat",
                        "division_type": "xã",
                        "short_codename": "hiep_cat"
                    },
                    {
                        "name": "Xã Thanh Quang",
                        "code": 10621,
                        "codename": "xa_thanh_quang",
                        "division_type": "xã",
                        "short_codename": "thanh_quang"
                    },
                    {
                        "name": "Xã Quốc Tuấn",
                        "code": 10624,
                        "codename": "xa_quoc_tuan",
                        "division_type": "xã",
                        "short_codename": "quoc_tuan"
                    },
                    {
                        "name": "Xã Nam Chính",
                        "code": 10627,
                        "codename": "xa_nam_chinh",
                        "division_type": "xã",
                        "short_codename": "nam_chinh"
                    },
                    {
                        "name": "Xã An Bình",
                        "code": 10630,
                        "codename": "xa_an_binh",
                        "division_type": "xã",
                        "short_codename": "an_binh"
                    },
                    {
                        "name": "Xã Nam Trung",
                        "code": 10633,
                        "codename": "xa_nam_trung",
                        "division_type": "xã",
                        "short_codename": "nam_trung"
                    },
                    {
                        "name": "Xã An Sơn",
                        "code": 10636,
                        "codename": "xa_an_son",
                        "division_type": "xã",
                        "short_codename": "an_son"
                    },
                    {
                        "name": "Xã Cộng Hòa",
                        "code": 10639,
                        "codename": "xa_cong_hoa",
                        "division_type": "xã",
                        "short_codename": "cong_hoa"
                    },
                    {
                        "name": "Xã Thái Tân",
                        "code": 10642,
                        "codename": "xa_thai_tan",
                        "division_type": "xã",
                        "short_codename": "thai_tan"
                    },
                    {
                        "name": "Xã An Lâm",
                        "code": 10645,
                        "codename": "xa_an_lam",
                        "division_type": "xã",
                        "short_codename": "an_lam"
                    },
                    {
                        "name": "Xã Phú Điền",
                        "code": 10648,
                        "codename": "xa_phu_dien",
                        "division_type": "xã",
                        "short_codename": "phu_dien"
                    },
                    {
                        "name": "Xã Nam Hồng",
                        "code": 10651,
                        "codename": "xa_nam_hong",
                        "division_type": "xã",
                        "short_codename": "nam_hong"
                    },
                    {
                        "name": "Xã Hồng Phong",
                        "code": 10654,
                        "codename": "xa_hong_phong",
                        "division_type": "xã",
                        "short_codename": "hong_phong"
                    },
                    {
                        "name": "Xã Đồng Lạc",
                        "code": 10657,
                        "codename": "xa_dong_lac",
                        "division_type": "xã",
                        "short_codename": "dong_lac"
                    },
                    {
                        "name": "Xã Minh Tân",
                        "code": 10666,
                        "codename": "xa_minh_tan",
                        "division_type": "xã",
                        "short_codename": "minh_tan"
                    }
                ]
            },
            {
                "name": "Thị xã Kinh Môn",
                "code": 292,
                "codename": "thi_xa_kinh_mon",
                "division_type": "thị xã",
                "short_codename": "kinh_mon",
                "wards": [
                    {
                        "name": "Phường An Lưu",
                        "code": 10675,
                        "codename": "phuong_an_luu",
                        "division_type": "phường",
                        "short_codename": "an_luu"
                    },
                    {
                        "name": "Xã Bạch Đằng",
                        "code": 10678,
                        "codename": "xa_bach_dang",
                        "division_type": "xã",
                        "short_codename": "bach_dang"
                    },
                    {
                        "name": "Phường Thất Hùng",
                        "code": 10681,
                        "codename": "phuong_that_hung",
                        "division_type": "phường",
                        "short_codename": "that_hung"
                    },
                    {
                        "name": "Xã Lê Ninh",
                        "code": 10684,
                        "codename": "xa_le_ninh",
                        "division_type": "xã",
                        "short_codename": "le_ninh"
                    },
                    {
                        "name": "Xã Hoành Sơn",
                        "code": 10687,
                        "codename": "xa_hoanh_son",
                        "division_type": "xã",
                        "short_codename": "hoanh_son"
                    },
                    {
                        "name": "Phường Phạm Thái",
                        "code": 10693,
                        "codename": "phuong_pham_thai",
                        "division_type": "phường",
                        "short_codename": "pham_thai"
                    },
                    {
                        "name": "Phường Duy Tân",
                        "code": 10696,
                        "codename": "phuong_duy_tan",
                        "division_type": "phường",
                        "short_codename": "duy_tan"
                    },
                    {
                        "name": "Phường Tân Dân",
                        "code": 10699,
                        "codename": "phuong_tan_dan",
                        "division_type": "phường",
                        "short_codename": "tan_dan"
                    },
                    {
                        "name": "Phường Minh Tân",
                        "code": 10702,
                        "codename": "phuong_minh_tan",
                        "division_type": "phường",
                        "short_codename": "minh_tan"
                    },
                    {
                        "name": "Xã Quang Thành",
                        "code": 10705,
                        "codename": "xa_quang_thanh",
                        "division_type": "xã",
                        "short_codename": "quang_thanh"
                    },
                    {
                        "name": "Xã Hiệp Hòa",
                        "code": 10708,
                        "codename": "xa_hiep_hoa",
                        "division_type": "xã",
                        "short_codename": "hiep_hoa"
                    },
                    {
                        "name": "Phường Phú Thứ",
                        "code": 10714,
                        "codename": "phuong_phu_thu",
                        "division_type": "phường",
                        "short_codename": "phu_thu"
                    },
                    {
                        "name": "Xã Thăng Long",
                        "code": 10717,
                        "codename": "xa_thang_long",
                        "division_type": "xã",
                        "short_codename": "thang_long"
                    },
                    {
                        "name": "Xã Lạc Long",
                        "code": 10720,
                        "codename": "xa_lac_long",
                        "division_type": "xã",
                        "short_codename": "lac_long"
                    },
                    {
                        "name": "Phường An Sinh",
                        "code": 10723,
                        "codename": "phuong_an_sinh",
                        "division_type": "phường",
                        "short_codename": "an_sinh"
                    },
                    {
                        "name": "Phường Hiệp Sơn",
                        "code": 10726,
                        "codename": "phuong_hiep_son",
                        "division_type": "phường",
                        "short_codename": "hiep_son"
                    },
                    {
                        "name": "Xã Thượng Quận",
                        "code": 10729,
                        "codename": "xa_thuong_quan",
                        "division_type": "xã",
                        "short_codename": "thuong_quan"
                    },
                    {
                        "name": "Phường An Phụ",
                        "code": 10732,
                        "codename": "phuong_an_phu",
                        "division_type": "phường",
                        "short_codename": "an_phu"
                    },
                    {
                        "name": "Phường Hiệp An",
                        "code": 10735,
                        "codename": "phuong_hiep_an",
                        "division_type": "phường",
                        "short_codename": "hiep_an"
                    },
                    {
                        "name": "Phường Long Xuyên",
                        "code": 10738,
                        "codename": "phuong_long_xuyen",
                        "division_type": "phường",
                        "short_codename": "long_xuyen"
                    },
                    {
                        "name": "Phường Thái Thịnh",
                        "code": 10741,
                        "codename": "phuong_thai_thinh",
                        "division_type": "phường",
                        "short_codename": "thai_thinh"
                    },
                    {
                        "name": "Phường Hiến Thành",
                        "code": 10744,
                        "codename": "phuong_hien_thanh",
                        "division_type": "phường",
                        "short_codename": "hien_thanh"
                    },
                    {
                        "name": "Xã Minh Hòa",
                        "code": 10747,
                        "codename": "xa_minh_hoa",
                        "division_type": "xã",
                        "short_codename": "minh_hoa"
                    }
                ]
            },
            {
                "name": "Huyện Kim Thành",
                "code": 293,
                "codename": "huyen_kim_thanh",
                "division_type": "huyện",
                "short_codename": "kim_thanh",
                "wards": [
                    {
                        "name": "Thị trấn Phú Thái",
                        "code": 10750,
                        "codename": "thi_tran_phu_thai",
                        "division_type": "thị trấn",
                        "short_codename": "phu_thai"
                    },
                    {
                        "name": "Xã Lai Vu",
                        "code": 10753,
                        "codename": "xa_lai_vu",
                        "division_type": "xã",
                        "short_codename": "lai_vu"
                    },
                    {
                        "name": "Xã Cộng Hòa",
                        "code": 10756,
                        "codename": "xa_cong_hoa",
                        "division_type": "xã",
                        "short_codename": "cong_hoa"
                    },
                    {
                        "name": "Xã Thượng Vũ",
                        "code": 10759,
                        "codename": "xa_thuong_vu",
                        "division_type": "xã",
                        "short_codename": "thuong_vu"
                    },
                    {
                        "name": "Xã Cổ Dũng",
                        "code": 10762,
                        "codename": "xa_co_dung",
                        "division_type": "xã",
                        "short_codename": "co_dung"
                    },
                    {
                        "name": "Xã Tuấn Việt",
                        "code": 10768,
                        "codename": "xa_tuan_viet",
                        "division_type": "xã",
                        "short_codename": "tuan_viet"
                    },
                    {
                        "name": "Xã Kim Xuyên",
                        "code": 10771,
                        "codename": "xa_kim_xuyen",
                        "division_type": "xã",
                        "short_codename": "kim_xuyen"
                    },
                    {
                        "name": "Xã Phúc Thành A",
                        "code": 10774,
                        "codename": "xa_phuc_thanh_a",
                        "division_type": "xã",
                        "short_codename": "phuc_thanh_a"
                    },
                    {
                        "name": "Xã Ngũ Phúc",
                        "code": 10777,
                        "codename": "xa_ngu_phuc",
                        "division_type": "xã",
                        "short_codename": "ngu_phuc"
                    },
                    {
                        "name": "Xã Kim Anh",
                        "code": 10780,
                        "codename": "xa_kim_anh",
                        "division_type": "xã",
                        "short_codename": "kim_anh"
                    },
                    {
                        "name": "Xã Kim Liên",
                        "code": 10783,
                        "codename": "xa_kim_lien",
                        "division_type": "xã",
                        "short_codename": "kim_lien"
                    },
                    {
                        "name": "Xã Kim Tân",
                        "code": 10786,
                        "codename": "xa_kim_tan",
                        "division_type": "xã",
                        "short_codename": "kim_tan"
                    },
                    {
                        "name": "Xã Kim Đính",
                        "code": 10792,
                        "codename": "xa_kim_dinh",
                        "division_type": "xã",
                        "short_codename": "kim_dinh"
                    },
                    {
                        "name": "Xã Bình Dân",
                        "code": 10798,
                        "codename": "xa_binh_dan",
                        "division_type": "xã",
                        "short_codename": "binh_dan"
                    },
                    {
                        "name": "Xã Tam Kỳ",
                        "code": 10801,
                        "codename": "xa_tam_ky",
                        "division_type": "xã",
                        "short_codename": "tam_ky"
                    },
                    {
                        "name": "Xã Đồng Cẩm",
                        "code": 10804,
                        "codename": "xa_dong_cam",
                        "division_type": "xã",
                        "short_codename": "dong_cam"
                    },
                    {
                        "name": "Xã Liên Hòa",
                        "code": 10807,
                        "codename": "xa_lien_hoa",
                        "division_type": "xã",
                        "short_codename": "lien_hoa"
                    },
                    {
                        "name": "Xã Đại Đức",
                        "code": 10810,
                        "codename": "xa_dai_duc",
                        "division_type": "xã",
                        "short_codename": "dai_duc"
                    }
                ]
            },
            {
                "name": "Huyện Thanh Hà",
                "code": 294,
                "codename": "huyen_thanh_ha",
                "division_type": "huyện",
                "short_codename": "thanh_ha",
                "wards": [
                    {
                        "name": "Thị trấn Thanh Hà",
                        "code": 10813,
                        "codename": "thi_tran_thanh_ha",
                        "division_type": "thị trấn",
                        "short_codename": "thanh_ha"
                    },
                    {
                        "name": "Xã Hồng Lạc",
                        "code": 10816,
                        "codename": "xa_hong_lac",
                        "division_type": "xã",
                        "short_codename": "hong_lac"
                    },
                    {
                        "name": "Xã Việt Hồng",
                        "code": 10819,
                        "codename": "xa_viet_hong",
                        "division_type": "xã",
                        "short_codename": "viet_hong"
                    },
                    {
                        "name": "Xã Tân Việt",
                        "code": 10825,
                        "codename": "xa_tan_viet",
                        "division_type": "xã",
                        "short_codename": "tan_viet"
                    },
                    {
                        "name": "Xã Cẩm Chế",
                        "code": 10828,
                        "codename": "xa_cam_che",
                        "division_type": "xã",
                        "short_codename": "cam_che"
                    },
                    {
                        "name": "Xã Thanh An",
                        "code": 10831,
                        "codename": "xa_thanh_an",
                        "division_type": "xã",
                        "short_codename": "thanh_an"
                    },
                    {
                        "name": "Xã Thanh Lang",
                        "code": 10834,
                        "codename": "xa_thanh_lang",
                        "division_type": "xã",
                        "short_codename": "thanh_lang"
                    },
                    {
                        "name": "Xã Tân An",
                        "code": 10840,
                        "codename": "xa_tan_an",
                        "division_type": "xã",
                        "short_codename": "tan_an"
                    },
                    {
                        "name": "Xã Liên Mạc",
                        "code": 10843,
                        "codename": "xa_lien_mac",
                        "division_type": "xã",
                        "short_codename": "lien_mac"
                    },
                    {
                        "name": "Xã Thanh Hải",
                        "code": 10846,
                        "codename": "xa_thanh_hai",
                        "division_type": "xã",
                        "short_codename": "thanh_hai"
                    },
                    {
                        "name": "Xã Thanh Khê",
                        "code": 10849,
                        "codename": "xa_thanh_khe",
                        "division_type": "xã",
                        "short_codename": "thanh_khe"
                    },
                    {
                        "name": "Xã Thanh Xá",
                        "code": 10852,
                        "codename": "xa_thanh_xa",
                        "division_type": "xã",
                        "short_codename": "thanh_xa"
                    },
                    {
                        "name": "Xã Thanh Xuân",
                        "code": 10855,
                        "codename": "xa_thanh_xuan",
                        "division_type": "xã",
                        "short_codename": "thanh_xuan"
                    },
                    {
                        "name": "Xã Thanh Thủy",
                        "code": 10861,
                        "codename": "xa_thanh_thuy",
                        "division_type": "xã",
                        "short_codename": "thanh_thuy"
                    },
                    {
                        "name": "Xã An Phượng",
                        "code": 10864,
                        "codename": "xa_an_phuong",
                        "division_type": "xã",
                        "short_codename": "an_phuong"
                    },
                    {
                        "name": "Xã Thanh Sơn",
                        "code": 10867,
                        "codename": "xa_thanh_son",
                        "division_type": "xã",
                        "short_codename": "thanh_son"
                    },
                    {
                        "name": "Xã Thanh Quang",
                        "code": 10876,
                        "codename": "xa_thanh_quang",
                        "division_type": "xã",
                        "short_codename": "thanh_quang"
                    },
                    {
                        "name": "Xã Thanh Hồng",
                        "code": 10879,
                        "codename": "xa_thanh_hong",
                        "division_type": "xã",
                        "short_codename": "thanh_hong"
                    },
                    {
                        "name": "Xã Thanh Cường",
                        "code": 10882,
                        "codename": "xa_thanh_cuong",
                        "division_type": "xã",
                        "short_codename": "thanh_cuong"
                    },
                    {
                        "name": "Xã Vĩnh Lập",
                        "code": 10885,
                        "codename": "xa_vinh_lap",
                        "division_type": "xã",
                        "short_codename": "vinh_lap"
                    }
                ]
            },
            {
                "name": "Huyện Cẩm Giàng",
                "code": 295,
                "codename": "huyen_cam_giang",
                "division_type": "huyện",
                "short_codename": "cam_giang",
                "wards": [
                    {
                        "name": "Thị trấn Cẩm Giang",
                        "code": 10888,
                        "codename": "thi_tran_cam_giang",
                        "division_type": "thị trấn",
                        "short_codename": "cam_giang"
                    },
                    {
                        "name": "Thị trấn Lai Cách",
                        "code": 10891,
                        "codename": "thi_tran_lai_cach",
                        "division_type": "thị trấn",
                        "short_codename": "lai_cach"
                    },
                    {
                        "name": "Xã Cẩm Hưng",
                        "code": 10894,
                        "codename": "xa_cam_hung",
                        "division_type": "xã",
                        "short_codename": "cam_hung"
                    },
                    {
                        "name": "Xã Cẩm Hoàng",
                        "code": 10897,
                        "codename": "xa_cam_hoang",
                        "division_type": "xã",
                        "short_codename": "cam_hoang"
                    },
                    {
                        "name": "Xã Cẩm Văn",
                        "code": 10900,
                        "codename": "xa_cam_van",
                        "division_type": "xã",
                        "short_codename": "cam_van"
                    },
                    {
                        "name": "Xã Ngọc Liên",
                        "code": 10903,
                        "codename": "xa_ngoc_lien",
                        "division_type": "xã",
                        "short_codename": "ngoc_lien"
                    },
                    {
                        "name": "Xã Thạch Lỗi",
                        "code": 10906,
                        "codename": "xa_thach_loi",
                        "division_type": "xã",
                        "short_codename": "thach_loi"
                    },
                    {
                        "name": "Xã Cẩm Vũ",
                        "code": 10909,
                        "codename": "xa_cam_vu",
                        "division_type": "xã",
                        "short_codename": "cam_vu"
                    },
                    {
                        "name": "Xã Đức Chính",
                        "code": 10912,
                        "codename": "xa_duc_chinh",
                        "division_type": "xã",
                        "short_codename": "duc_chinh"
                    },
                    {
                        "name": "Xã Định Sơn",
                        "code": 10918,
                        "codename": "xa_dinh_son",
                        "division_type": "xã",
                        "short_codename": "dinh_son"
                    },
                    {
                        "name": "Xã Lương Điền",
                        "code": 10924,
                        "codename": "xa_luong_dien",
                        "division_type": "xã",
                        "short_codename": "luong_dien"
                    },
                    {
                        "name": "Xã Cao An",
                        "code": 10927,
                        "codename": "xa_cao_an",
                        "division_type": "xã",
                        "short_codename": "cao_an"
                    },
                    {
                        "name": "Xã Tân Trường",
                        "code": 10930,
                        "codename": "xa_tan_truong",
                        "division_type": "xã",
                        "short_codename": "tan_truong"
                    },
                    {
                        "name": "Xã Cẩm Phúc",
                        "code": 10933,
                        "codename": "xa_cam_phuc",
                        "division_type": "xã",
                        "short_codename": "cam_phuc"
                    },
                    {
                        "name": "Xã Cẩm Điền",
                        "code": 10936,
                        "codename": "xa_cam_dien",
                        "division_type": "xã",
                        "short_codename": "cam_dien"
                    },
                    {
                        "name": "Xã Cẩm Đông",
                        "code": 10939,
                        "codename": "xa_cam_dong",
                        "division_type": "xã",
                        "short_codename": "cam_dong"
                    },
                    {
                        "name": "Xã Cẩm Đoài",
                        "code": 10942,
                        "codename": "xa_cam_doai",
                        "division_type": "xã",
                        "short_codename": "cam_doai"
                    }
                ]
            },
            {
                "name": "Huyện Bình Giang",
                "code": 296,
                "codename": "huyen_binh_giang",
                "division_type": "huyện",
                "short_codename": "binh_giang",
                "wards": [
                    {
                        "name": "Thị trấn Kẻ Sặt",
                        "code": 10945,
                        "codename": "thi_tran_ke_sat",
                        "division_type": "thị trấn",
                        "short_codename": "ke_sat"
                    },
                    {
                        "name": "Xã Vĩnh Hưng",
                        "code": 10951,
                        "codename": "xa_vinh_hung",
                        "division_type": "xã",
                        "short_codename": "vinh_hung"
                    },
                    {
                        "name": "Xã Hùng Thắng",
                        "code": 10954,
                        "codename": "xa_hung_thang",
                        "division_type": "xã",
                        "short_codename": "hung_thang"
                    },
                    {
                        "name": "Xã Vĩnh Hồng",
                        "code": 10960,
                        "codename": "xa_vinh_hong",
                        "division_type": "xã",
                        "short_codename": "vinh_hong"
                    },
                    {
                        "name": "Xã Long Xuyên",
                        "code": 10963,
                        "codename": "xa_long_xuyen",
                        "division_type": "xã",
                        "short_codename": "long_xuyen"
                    },
                    {
                        "name": "Xã Tân Việt",
                        "code": 10966,
                        "codename": "xa_tan_viet",
                        "division_type": "xã",
                        "short_codename": "tan_viet"
                    },
                    {
                        "name": "Xã Thúc Kháng",
                        "code": 10969,
                        "codename": "xa_thuc_khang",
                        "division_type": "xã",
                        "short_codename": "thuc_khang"
                    },
                    {
                        "name": "Xã Tân Hồng",
                        "code": 10972,
                        "codename": "xa_tan_hong",
                        "division_type": "xã",
                        "short_codename": "tan_hong"
                    },
                    {
                        "name": "Xã Bình Minh",
                        "code": 10975,
                        "codename": "xa_binh_minh",
                        "division_type": "xã",
                        "short_codename": "binh_minh"
                    },
                    {
                        "name": "Xã Hồng Khê",
                        "code": 10978,
                        "codename": "xa_hong_khe",
                        "division_type": "xã",
                        "short_codename": "hong_khe"
                    },
                    {
                        "name": "Xã Thái Học",
                        "code": 10981,
                        "codename": "xa_thai_hoc",
                        "division_type": "xã",
                        "short_codename": "thai_hoc"
                    },
                    {
                        "name": "Xã Cổ Bì",
                        "code": 10984,
                        "codename": "xa_co_bi",
                        "division_type": "xã",
                        "short_codename": "co_bi"
                    },
                    {
                        "name": "Xã Nhân Quyền",
                        "code": 10987,
                        "codename": "xa_nhan_quyen",
                        "division_type": "xã",
                        "short_codename": "nhan_quyen"
                    },
                    {
                        "name": "Xã Thái Dương",
                        "code": 10990,
                        "codename": "xa_thai_duong",
                        "division_type": "xã",
                        "short_codename": "thai_duong"
                    },
                    {
                        "name": "Xã Thái Hòa",
                        "code": 10993,
                        "codename": "xa_thai_hoa",
                        "division_type": "xã",
                        "short_codename": "thai_hoa"
                    },
                    {
                        "name": "Xã Bình Xuyên",
                        "code": 10996,
                        "codename": "xa_binh_xuyen",
                        "division_type": "xã",
                        "short_codename": "binh_xuyen"
                    }
                ]
            },
            {
                "name": "Huyện Gia Lộc",
                "code": 297,
                "codename": "huyen_gia_loc",
                "division_type": "huyện",
                "short_codename": "gia_loc",
                "wards": [
                    {
                        "name": "Thị trấn Gia Lộc",
                        "code": 10999,
                        "codename": "thi_tran_gia_loc",
                        "division_type": "thị trấn",
                        "short_codename": "gia_loc"
                    },
                    {
                        "name": "Xã Thống Nhất",
                        "code": 11008,
                        "codename": "xa_thong_nhat",
                        "division_type": "xã",
                        "short_codename": "thong_nhat"
                    },
                    {
                        "name": "Xã Yết Kiêu",
                        "code": 11020,
                        "codename": "xa_yet_kieu",
                        "division_type": "xã",
                        "short_codename": "yet_kieu"
                    },
                    {
                        "name": "Xã Gia Tân",
                        "code": 11029,
                        "codename": "xa_gia_tan",
                        "division_type": "xã",
                        "short_codename": "gia_tan"
                    },
                    {
                        "name": "Xã Tân Tiến",
                        "code": 11032,
                        "codename": "xa_tan_tien",
                        "division_type": "xã",
                        "short_codename": "tan_tien"
                    },
                    {
                        "name": "Xã Gia Khánh",
                        "code": 11035,
                        "codename": "xa_gia_khanh",
                        "division_type": "xã",
                        "short_codename": "gia_khanh"
                    },
                    {
                        "name": "Xã Gia Lương",
                        "code": 11038,
                        "codename": "xa_gia_luong",
                        "division_type": "xã",
                        "short_codename": "gia_luong"
                    },
                    {
                        "name": "Xã Lê Lợi",
                        "code": 11041,
                        "codename": "xa_le_loi",
                        "division_type": "xã",
                        "short_codename": "le_loi"
                    },
                    {
                        "name": "Xã Toàn Thắng",
                        "code": 11044,
                        "codename": "xa_toan_thang",
                        "division_type": "xã",
                        "short_codename": "toan_thang"
                    },
                    {
                        "name": "Xã Hoàng Diệu",
                        "code": 11047,
                        "codename": "xa_hoang_dieu",
                        "division_type": "xã",
                        "short_codename": "hoang_dieu"
                    },
                    {
                        "name": "Xã Hồng Hưng",
                        "code": 11050,
                        "codename": "xa_hong_hung",
                        "division_type": "xã",
                        "short_codename": "hong_hung"
                    },
                    {
                        "name": "Xã Phạm Trấn",
                        "code": 11053,
                        "codename": "xa_pham_tran",
                        "division_type": "xã",
                        "short_codename": "pham_tran"
                    },
                    {
                        "name": "Xã Đoàn Thượng",
                        "code": 11056,
                        "codename": "xa_doan_thuong",
                        "division_type": "xã",
                        "short_codename": "doan_thuong"
                    },
                    {
                        "name": "Xã Thống Kênh",
                        "code": 11059,
                        "codename": "xa_thong_kenh",
                        "division_type": "xã",
                        "short_codename": "thong_kenh"
                    },
                    {
                        "name": "Xã Quang Minh",
                        "code": 11062,
                        "codename": "xa_quang_minh",
                        "division_type": "xã",
                        "short_codename": "quang_minh"
                    },
                    {
                        "name": "Xã Đồng Quang",
                        "code": 11065,
                        "codename": "xa_dong_quang",
                        "division_type": "xã",
                        "short_codename": "dong_quang"
                    },
                    {
                        "name": "Xã Nhật Tân",
                        "code": 11068,
                        "codename": "xa_nhat_tan",
                        "division_type": "xã",
                        "short_codename": "nhat_tan"
                    },
                    {
                        "name": "Xã Đức Xương",
                        "code": 11071,
                        "codename": "xa_duc_xuong",
                        "division_type": "xã",
                        "short_codename": "duc_xuong"
                    }
                ]
            },
            {
                "name": "Huyện Tứ Kỳ",
                "code": 298,
                "codename": "huyen_tu_ky",
                "division_type": "huyện",
                "short_codename": "tu_ky",
                "wards": [
                    {
                        "name": "Thị trấn Tứ Kỳ",
                        "code": 11074,
                        "codename": "thi_tran_tu_ky",
                        "division_type": "thị trấn",
                        "short_codename": "tu_ky"
                    },
                    {
                        "name": "Xã Đại Sơn",
                        "code": 11083,
                        "codename": "xa_dai_son",
                        "division_type": "xã",
                        "short_codename": "dai_son"
                    },
                    {
                        "name": "Xã Hưng Đạo",
                        "code": 11086,
                        "codename": "xa_hung_dao",
                        "division_type": "xã",
                        "short_codename": "hung_dao"
                    },
                    {
                        "name": "Xã Ngọc Kỳ",
                        "code": 11089,
                        "codename": "xa_ngoc_ky",
                        "division_type": "xã",
                        "short_codename": "ngoc_ky"
                    },
                    {
                        "name": "Xã Bình Lăng",
                        "code": 11092,
                        "codename": "xa_binh_lang",
                        "division_type": "xã",
                        "short_codename": "binh_lang"
                    },
                    {
                        "name": "Xã Chí Minh",
                        "code": 11095,
                        "codename": "xa_chi_minh",
                        "division_type": "xã",
                        "short_codename": "chi_minh"
                    },
                    {
                        "name": "Xã Tái Sơn",
                        "code": 11098,
                        "codename": "xa_tai_son",
                        "division_type": "xã",
                        "short_codename": "tai_son"
                    },
                    {
                        "name": "Xã Quang Phục",
                        "code": 11101,
                        "codename": "xa_quang_phuc",
                        "division_type": "xã",
                        "short_codename": "quang_phuc"
                    },
                    {
                        "name": "Xã Dân Chủ",
                        "code": 11110,
                        "codename": "xa_dan_chu",
                        "division_type": "xã",
                        "short_codename": "dan_chu"
                    },
                    {
                        "name": "Xã Tân Kỳ",
                        "code": 11113,
                        "codename": "xa_tan_ky",
                        "division_type": "xã",
                        "short_codename": "tan_ky"
                    },
                    {
                        "name": "Xã Quang Khải",
                        "code": 11116,
                        "codename": "xa_quang_khai",
                        "division_type": "xã",
                        "short_codename": "quang_khai"
                    },
                    {
                        "name": "Xã Đại Hợp",
                        "code": 11119,
                        "codename": "xa_dai_hop",
                        "division_type": "xã",
                        "short_codename": "dai_hop"
                    },
                    {
                        "name": "Xã Quảng Nghiệp",
                        "code": 11122,
                        "codename": "xa_quang_nghiep",
                        "division_type": "xã",
                        "short_codename": "quang_nghiep"
                    },
                    {
                        "name": "Xã An Thanh",
                        "code": 11125,
                        "codename": "xa_an_thanh",
                        "division_type": "xã",
                        "short_codename": "an_thanh"
                    },
                    {
                        "name": "Xã Minh Đức",
                        "code": 11128,
                        "codename": "xa_minh_duc",
                        "division_type": "xã",
                        "short_codename": "minh_duc"
                    },
                    {
                        "name": "Xã Văn Tố",
                        "code": 11131,
                        "codename": "xa_van_to",
                        "division_type": "xã",
                        "short_codename": "van_to"
                    },
                    {
                        "name": "Xã Quang Trung",
                        "code": 11134,
                        "codename": "xa_quang_trung",
                        "division_type": "xã",
                        "short_codename": "quang_trung"
                    },
                    {
                        "name": "Xã Phượng Kỳ",
                        "code": 11137,
                        "codename": "xa_phuong_ky",
                        "division_type": "xã",
                        "short_codename": "phuong_ky"
                    },
                    {
                        "name": "Xã Cộng Lạc",
                        "code": 11140,
                        "codename": "xa_cong_lac",
                        "division_type": "xã",
                        "short_codename": "cong_lac"
                    },
                    {
                        "name": "Xã Tiên Động",
                        "code": 11143,
                        "codename": "xa_tien_dong",
                        "division_type": "xã",
                        "short_codename": "tien_dong"
                    },
                    {
                        "name": "Xã Nguyên Giáp",
                        "code": 11146,
                        "codename": "xa_nguyen_giap",
                        "division_type": "xã",
                        "short_codename": "nguyen_giap"
                    },
                    {
                        "name": "Xã Hà Kỳ",
                        "code": 11149,
                        "codename": "xa_ha_ky",
                        "division_type": "xã",
                        "short_codename": "ha_ky"
                    },
                    {
                        "name": "Xã Hà Thanh",
                        "code": 11152,
                        "codename": "xa_ha_thanh",
                        "division_type": "xã",
                        "short_codename": "ha_thanh"
                    }
                ]
            },
            {
                "name": "Huyện Ninh Giang",
                "code": 299,
                "codename": "huyen_ninh_giang",
                "division_type": "huyện",
                "short_codename": "ninh_giang",
                "wards": [
                    {
                        "name": "Thị trấn Ninh Giang",
                        "code": 11155,
                        "codename": "thi_tran_ninh_giang",
                        "division_type": "thị trấn",
                        "short_codename": "ninh_giang"
                    },
                    {
                        "name": "Xã Ứng Hoè",
                        "code": 11161,
                        "codename": "xa_ung_hoe",
                        "division_type": "xã",
                        "short_codename": "ung_hoe"
                    },
                    {
                        "name": "Xã Nghĩa An",
                        "code": 11164,
                        "codename": "xa_nghia_an",
                        "division_type": "xã",
                        "short_codename": "nghia_an"
                    },
                    {
                        "name": "Xã Hồng Đức",
                        "code": 11167,
                        "codename": "xa_hong_duc",
                        "division_type": "xã",
                        "short_codename": "hong_duc"
                    },
                    {
                        "name": "Xã An Đức",
                        "code": 11173,
                        "codename": "xa_an_duc",
                        "division_type": "xã",
                        "short_codename": "an_duc"
                    },
                    {
                        "name": "Xã Vạn Phúc",
                        "code": 11176,
                        "codename": "xa_van_phuc",
                        "division_type": "xã",
                        "short_codename": "van_phuc"
                    },
                    {
                        "name": "Xã Tân Hương",
                        "code": 11179,
                        "codename": "xa_tan_huong",
                        "division_type": "xã",
                        "short_codename": "tan_huong"
                    },
                    {
                        "name": "Xã Vĩnh Hòa",
                        "code": 11185,
                        "codename": "xa_vinh_hoa",
                        "division_type": "xã",
                        "short_codename": "vinh_hoa"
                    },
                    {
                        "name": "Xã Đông Xuyên",
                        "code": 11188,
                        "codename": "xa_dong_xuyen",
                        "division_type": "xã",
                        "short_codename": "dong_xuyen"
                    },
                    {
                        "name": "Xã Tân Phong",
                        "code": 11197,
                        "codename": "xa_tan_phong",
                        "division_type": "xã",
                        "short_codename": "tan_phong"
                    },
                    {
                        "name": "Xã Ninh Hải",
                        "code": 11200,
                        "codename": "xa_ninh_hai",
                        "division_type": "xã",
                        "short_codename": "ninh_hai"
                    },
                    {
                        "name": "Xã Đồng Tâm",
                        "code": 11203,
                        "codename": "xa_dong_tam",
                        "division_type": "xã",
                        "short_codename": "dong_tam"
                    },
                    {
                        "name": "Xã Tân Quang",
                        "code": 11206,
                        "codename": "xa_tan_quang",
                        "division_type": "xã",
                        "short_codename": "tan_quang"
                    },
                    {
                        "name": "Xã Kiến Quốc",
                        "code": 11209,
                        "codename": "xa_kien_quoc",
                        "division_type": "xã",
                        "short_codename": "kien_quoc"
                    },
                    {
                        "name": "Xã Hồng Dụ",
                        "code": 11215,
                        "codename": "xa_hong_du",
                        "division_type": "xã",
                        "short_codename": "hong_du"
                    },
                    {
                        "name": "Xã Văn Hội",
                        "code": 11218,
                        "codename": "xa_van_hoi",
                        "division_type": "xã",
                        "short_codename": "van_hoi"
                    },
                    {
                        "name": "Xã Hồng Phong",
                        "code": 11224,
                        "codename": "xa_hong_phong",
                        "division_type": "xã",
                        "short_codename": "hong_phong"
                    },
                    {
                        "name": "Xã Hiệp Lực",
                        "code": 11227,
                        "codename": "xa_hiep_luc",
                        "division_type": "xã",
                        "short_codename": "hiep_luc"
                    },
                    {
                        "name": "Xã Hồng Phúc",
                        "code": 11230,
                        "codename": "xa_hong_phuc",
                        "division_type": "xã",
                        "short_codename": "hong_phuc"
                    },
                    {
                        "name": "Xã Hưng Long",
                        "code": 11233,
                        "codename": "xa_hung_long",
                        "division_type": "xã",
                        "short_codename": "hung_long"
                    }
                ]
            },
            {
                "name": "Huyện Thanh Miện",
                "code": 300,
                "codename": "huyen_thanh_mien",
                "division_type": "huyện",
                "short_codename": "thanh_mien",
                "wards": [
                    {
                        "name": "Thị trấn Thanh Miện",
                        "code": 11239,
                        "codename": "thi_tran_thanh_mien",
                        "division_type": "thị trấn",
                        "short_codename": "thanh_mien"
                    },
                    {
                        "name": "Xã Thanh Tùng",
                        "code": 11242,
                        "codename": "xa_thanh_tung",
                        "division_type": "xã",
                        "short_codename": "thanh_tung"
                    },
                    {
                        "name": "Xã Phạm Kha",
                        "code": 11245,
                        "codename": "xa_pham_kha",
                        "division_type": "xã",
                        "short_codename": "pham_kha"
                    },
                    {
                        "name": "Xã Ngô Quyền",
                        "code": 11248,
                        "codename": "xa_ngo_quyen",
                        "division_type": "xã",
                        "short_codename": "ngo_quyen"
                    },
                    {
                        "name": "Xã Đoàn Tùng",
                        "code": 11251,
                        "codename": "xa_doan_tung",
                        "division_type": "xã",
                        "short_codename": "doan_tung"
                    },
                    {
                        "name": "Xã Hồng Quang",
                        "code": 11254,
                        "codename": "xa_hong_quang",
                        "division_type": "xã",
                        "short_codename": "hong_quang"
                    },
                    {
                        "name": "Xã Tân Trào",
                        "code": 11257,
                        "codename": "xa_tan_trao",
                        "division_type": "xã",
                        "short_codename": "tan_trao"
                    },
                    {
                        "name": "Xã Lam Sơn",
                        "code": 11260,
                        "codename": "xa_lam_son",
                        "division_type": "xã",
                        "short_codename": "lam_son"
                    },
                    {
                        "name": "Xã Đoàn Kết",
                        "code": 11263,
                        "codename": "xa_doan_ket",
                        "division_type": "xã",
                        "short_codename": "doan_ket"
                    },
                    {
                        "name": "Xã Lê Hồng",
                        "code": 11266,
                        "codename": "xa_le_hong",
                        "division_type": "xã",
                        "short_codename": "le_hong"
                    },
                    {
                        "name": "Xã Tứ Cường",
                        "code": 11269,
                        "codename": "xa_tu_cuong",
                        "division_type": "xã",
                        "short_codename": "tu_cuong"
                    },
                    {
                        "name": "Xã Ngũ Hùng",
                        "code": 11275,
                        "codename": "xa_ngu_hung",
                        "division_type": "xã",
                        "short_codename": "ngu_hung"
                    },
                    {
                        "name": "Xã Cao Thắng",
                        "code": 11278,
                        "codename": "xa_cao_thang",
                        "division_type": "xã",
                        "short_codename": "cao_thang"
                    },
                    {
                        "name": "Xã Chi Lăng Bắc",
                        "code": 11281,
                        "codename": "xa_chi_lang_bac",
                        "division_type": "xã",
                        "short_codename": "chi_lang_bac"
                    },
                    {
                        "name": "Xã Chi Lăng Nam",
                        "code": 11284,
                        "codename": "xa_chi_lang_nam",
                        "division_type": "xã",
                        "short_codename": "chi_lang_nam"
                    },
                    {
                        "name": "Xã Thanh Giang",
                        "code": 11287,
                        "codename": "xa_thanh_giang",
                        "division_type": "xã",
                        "short_codename": "thanh_giang"
                    },
                    {
                        "name": "Xã Hồng Phong",
                        "code": 11293,
                        "codename": "xa_hong_phong",
                        "division_type": "xã",
                        "short_codename": "hong_phong"
                    }
                ]
            }
        ]
    },
    {
        "name": "Thành phố Hải Phòng",
        "code": 31,
        "codename": "thanh_pho_hai_phong",
        "division_type": "thành phố trung ương",
        "phone_code": 225,
        "districts": [
            {
                "name": "Quận Hồng Bàng",
                "code": 303,
                "codename": "quan_hong_bang",
                "division_type": "quận",
                "short_codename": "hong_bang",
                "wards": [
                    {
                        "name": "Phường Quán Toan",
                        "code": 11296,
                        "codename": "phuong_quan_toan",
                        "division_type": "phường",
                        "short_codename": "quan_toan"
                    },
                    {
                        "name": "Phường Hùng Vương",
                        "code": 11299,
                        "codename": "phuong_hung_vuong",
                        "division_type": "phường",
                        "short_codename": "hung_vuong"
                    },
                    {
                        "name": "Phường Sở Dầu",
                        "code": 11302,
                        "codename": "phuong_so_dau",
                        "division_type": "phường",
                        "short_codename": "so_dau"
                    },
                    {
                        "name": "Phường Thượng Lý",
                        "code": 11305,
                        "codename": "phuong_thuong_ly",
                        "division_type": "phường",
                        "short_codename": "thuong_ly"
                    },
                    {
                        "name": "Phường Hạ Lý",
                        "code": 11308,
                        "codename": "phuong_ha_ly",
                        "division_type": "phường",
                        "short_codename": "ha_ly"
                    },
                    {
                        "name": "Phường Minh Khai",
                        "code": 11311,
                        "codename": "phuong_minh_khai",
                        "division_type": "phường",
                        "short_codename": "minh_khai"
                    },
                    {
                        "name": "Phường Trại Chuối",
                        "code": 11314,
                        "codename": "phuong_trai_chuoi",
                        "division_type": "phường",
                        "short_codename": "trai_chuoi"
                    },
                    {
                        "name": "Phường Hoàng Văn Thụ",
                        "code": 11320,
                        "codename": "phuong_hoang_van_thu",
                        "division_type": "phường",
                        "short_codename": "hoang_van_thu"
                    },
                    {
                        "name": "Phường Phan Bội Châu",
                        "code": 11323,
                        "codename": "phuong_phan_boi_chau",
                        "division_type": "phường",
                        "short_codename": "phan_boi_chau"
                    }
                ]
            },
            {
                "name": "Quận Ngô Quyền",
                "code": 304,
                "codename": "quan_ngo_quyen",
                "division_type": "quận",
                "short_codename": "ngo_quyen",
                "wards": [
                    {
                        "name": "Phường Máy Chai",
                        "code": 11329,
                        "codename": "phuong_may_chai",
                        "division_type": "phường",
                        "short_codename": "may_chai"
                    },
                    {
                        "name": "Phường Máy Tơ",
                        "code": 11332,
                        "codename": "phuong_may_to",
                        "division_type": "phường",
                        "short_codename": "may_to"
                    },
                    {
                        "name": "Phường Vạn Mỹ",
                        "code": 11335,
                        "codename": "phuong_van_my",
                        "division_type": "phường",
                        "short_codename": "van_my"
                    },
                    {
                        "name": "Phường Cầu Tre",
                        "code": 11338,
                        "codename": "phuong_cau_tre",
                        "division_type": "phường",
                        "short_codename": "cau_tre"
                    },
                    {
                        "name": "Phường Lạc Viên",
                        "code": 11341,
                        "codename": "phuong_lac_vien",
                        "division_type": "phường",
                        "short_codename": "lac_vien"
                    },
                    {
                        "name": "Phường Gia Viên",
                        "code": 11347,
                        "codename": "phuong_gia_vien",
                        "division_type": "phường",
                        "short_codename": "gia_vien"
                    },
                    {
                        "name": "Phường Đông Khê",
                        "code": 11350,
                        "codename": "phuong_dong_khe",
                        "division_type": "phường",
                        "short_codename": "dong_khe"
                    },
                    {
                        "name": "Phường Cầu Đất",
                        "code": 11353,
                        "codename": "phuong_cau_dat",
                        "division_type": "phường",
                        "short_codename": "cau_dat"
                    },
                    {
                        "name": "Phường Lê Lợi",
                        "code": 11356,
                        "codename": "phuong_le_loi",
                        "division_type": "phường",
                        "short_codename": "le_loi"
                    },
                    {
                        "name": "Phường Đằng Giang",
                        "code": 11359,
                        "codename": "phuong_dang_giang",
                        "division_type": "phường",
                        "short_codename": "dang_giang"
                    },
                    {
                        "name": "Phường Lạch Tray",
                        "code": 11362,
                        "codename": "phuong_lach_tray",
                        "division_type": "phường",
                        "short_codename": "lach_tray"
                    },
                    {
                        "name": "Phường Đổng Quốc Bình",
                        "code": 11365,
                        "codename": "phuong_dong_quoc_binh",
                        "division_type": "phường",
                        "short_codename": "dong_quoc_binh"
                    }
                ]
            },
            {
                "name": "Quận Lê Chân",
                "code": 305,
                "codename": "quan_le_chan",
                "division_type": "quận",
                "short_codename": "le_chan",
                "wards": [
                    {
                        "name": "Phường Cát Dài",
                        "code": 11368,
                        "codename": "phuong_cat_dai",
                        "division_type": "phường",
                        "short_codename": "cat_dai"
                    },
                    {
                        "name": "Phường An Biên",
                        "code": 11371,
                        "codename": "phuong_an_bien",
                        "division_type": "phường",
                        "short_codename": "an_bien"
                    },
                    {
                        "name": "Phường Lam Sơn",
                        "code": 11374,
                        "codename": "phuong_lam_son",
                        "division_type": "phường",
                        "short_codename": "lam_son"
                    },
                    {
                        "name": "Phường An Dương",
                        "code": 11377,
                        "codename": "phuong_an_duong",
                        "division_type": "phường",
                        "short_codename": "an_duong"
                    },
                    {
                        "name": "Phường Trần Nguyên Hãn",
                        "code": 11380,
                        "codename": "phuong_tran_nguyen_han",
                        "division_type": "phường",
                        "short_codename": "tran_nguyen_han"
                    },
                    {
                        "name": "Phường Hồ Nam",
                        "code": 11383,
                        "codename": "phuong_ho_nam",
                        "division_type": "phường",
                        "short_codename": "ho_nam"
                    },
                    {
                        "name": "Phường Trại Cau",
                        "code": 11386,
                        "codename": "phuong_trai_cau",
                        "division_type": "phường",
                        "short_codename": "trai_cau"
                    },
                    {
                        "name": "Phường Dư Hàng",
                        "code": 11389,
                        "codename": "phuong_du_hang",
                        "division_type": "phường",
                        "short_codename": "du_hang"
                    },
                    {
                        "name": "Phường Hàng Kênh",
                        "code": 11392,
                        "codename": "phuong_hang_kenh",
                        "division_type": "phường",
                        "short_codename": "hang_kenh"
                    },
                    {
                        "name": "Phường Đông Hải",
                        "code": 11395,
                        "codename": "phuong_dong_hai",
                        "division_type": "phường",
                        "short_codename": "dong_hai"
                    },
                    {
                        "name": "Phường Niệm Nghĩa",
                        "code": 11398,
                        "codename": "phuong_niem_nghia",
                        "division_type": "phường",
                        "short_codename": "niem_nghia"
                    },
                    {
                        "name": "Phường Nghĩa Xá",
                        "code": 11401,
                        "codename": "phuong_nghia_xa",
                        "division_type": "phường",
                        "short_codename": "nghia_xa"
                    },
                    {
                        "name": "Phường Dư Hàng Kênh",
                        "code": 11404,
                        "codename": "phuong_du_hang_kenh",
                        "division_type": "phường",
                        "short_codename": "du_hang_kenh"
                    },
                    {
                        "name": "Phường Kênh Dương",
                        "code": 11405,
                        "codename": "phuong_kenh_duong",
                        "division_type": "phường",
                        "short_codename": "kenh_duong"
                    },
                    {
                        "name": "Phường Vĩnh Niệm",
                        "code": 11407,
                        "codename": "phuong_vinh_niem",
                        "division_type": "phường",
                        "short_codename": "vinh_niem"
                    }
                ]
            },
            {
                "name": "Quận Hải An",
                "code": 306,
                "codename": "quan_hai_an",
                "division_type": "quận",
                "short_codename": "hai_an",
                "wards": [
                    {
                        "name": "Phường Đông Hải 1",
                        "code": 11410,
                        "codename": "phuong_dong_hai_1",
                        "division_type": "phường",
                        "short_codename": "dong_hai_1"
                    },
                    {
                        "name": "Phường Đông Hải 2",
                        "code": 11411,
                        "codename": "phuong_dong_hai_2",
                        "division_type": "phường",
                        "short_codename": "dong_hai_2"
                    },
                    {
                        "name": "Phường Đằng Lâm",
                        "code": 11413,
                        "codename": "phuong_dang_lam",
                        "division_type": "phường",
                        "short_codename": "dang_lam"
                    },
                    {
                        "name": "Phường Thành Tô",
                        "code": 11414,
                        "codename": "phuong_thanh_to",
                        "division_type": "phường",
                        "short_codename": "thanh_to"
                    },
                    {
                        "name": "Phường Đằng Hải",
                        "code": 11416,
                        "codename": "phuong_dang_hai",
                        "division_type": "phường",
                        "short_codename": "dang_hai"
                    },
                    {
                        "name": "Phường Nam Hải",
                        "code": 11419,
                        "codename": "phuong_nam_hai",
                        "division_type": "phường",
                        "short_codename": "nam_hai"
                    },
                    {
                        "name": "Phường Cát Bi",
                        "code": 11422,
                        "codename": "phuong_cat_bi",
                        "division_type": "phường",
                        "short_codename": "cat_bi"
                    },
                    {
                        "name": "Phường Tràng Cát",
                        "code": 11425,
                        "codename": "phuong_trang_cat",
                        "division_type": "phường",
                        "short_codename": "trang_cat"
                    }
                ]
            },
            {
                "name": "Quận Kiến An",
                "code": 307,
                "codename": "quan_kien_an",
                "division_type": "quận",
                "short_codename": "kien_an",
                "wards": [
                    {
                        "name": "Phường Quán Trữ",
                        "code": 11428,
                        "codename": "phuong_quan_tru",
                        "division_type": "phường",
                        "short_codename": "quan_tru"
                    },
                    {
                        "name": "Phường Lãm Hà",
                        "code": 11429,
                        "codename": "phuong_lam_ha",
                        "division_type": "phường",
                        "short_codename": "lam_ha"
                    },
                    {
                        "name": "Phường Đồng Hoà",
                        "code": 11431,
                        "codename": "phuong_dong_hoa",
                        "division_type": "phường",
                        "short_codename": "dong_hoa"
                    },
                    {
                        "name": "Phường Bắc Sơn",
                        "code": 11434,
                        "codename": "phuong_bac_son",
                        "division_type": "phường",
                        "short_codename": "bac_son"
                    },
                    {
                        "name": "Phường Nam Sơn",
                        "code": 11437,
                        "codename": "phuong_nam_son",
                        "division_type": "phường",
                        "short_codename": "nam_son"
                    },
                    {
                        "name": "Phường Ngọc Sơn",
                        "code": 11440,
                        "codename": "phuong_ngoc_son",
                        "division_type": "phường",
                        "short_codename": "ngoc_son"
                    },
                    {
                        "name": "Phường Trần Thành Ngọ",
                        "code": 11443,
                        "codename": "phuong_tran_thanh_ngo",
                        "division_type": "phường",
                        "short_codename": "tran_thanh_ngo"
                    },
                    {
                        "name": "Phường Văn Đẩu",
                        "code": 11446,
                        "codename": "phuong_van_dau",
                        "division_type": "phường",
                        "short_codename": "van_dau"
                    },
                    {
                        "name": "Phường Phù Liễn",
                        "code": 11449,
                        "codename": "phuong_phu_lien",
                        "division_type": "phường",
                        "short_codename": "phu_lien"
                    },
                    {
                        "name": "Phường Tràng Minh",
                        "code": 11452,
                        "codename": "phuong_trang_minh",
                        "division_type": "phường",
                        "short_codename": "trang_minh"
                    }
                ]
            },
            {
                "name": "Quận Đồ Sơn",
                "code": 308,
                "codename": "quan_do_son",
                "division_type": "quận",
                "short_codename": "do_son",
                "wards": [
                    {
                        "name": "Phường Ngọc Xuyên",
                        "code": 11455,
                        "codename": "phuong_ngoc_xuyen",
                        "division_type": "phường",
                        "short_codename": "ngoc_xuyen"
                    },
                    {
                        "name": "Phường Hải Sơn",
                        "code": 11458,
                        "codename": "phuong_hai_son",
                        "division_type": "phường",
                        "short_codename": "hai_son"
                    },
                    {
                        "name": "Phường Vạn Hương",
                        "code": 11461,
                        "codename": "phuong_van_huong",
                        "division_type": "phường",
                        "short_codename": "van_huong"
                    },
                    {
                        "name": "Phường Minh Đức",
                        "code": 11465,
                        "codename": "phuong_minh_duc",
                        "division_type": "phường",
                        "short_codename": "minh_duc"
                    },
                    {
                        "name": "Phường Bàng La",
                        "code": 11467,
                        "codename": "phuong_bang_la",
                        "division_type": "phường",
                        "short_codename": "bang_la"
                    },
                    {
                        "name": "Phường Hợp Đức",
                        "code": 11737,
                        "codename": "phuong_hop_duc",
                        "division_type": "phường",
                        "short_codename": "hop_duc"
                    }
                ]
            },
            {
                "name": "Quận Dương Kinh",
                "code": 309,
                "codename": "quan_duong_kinh",
                "division_type": "quận",
                "short_codename": "duong_kinh",
                "wards": [
                    {
                        "name": "Phường Đa Phúc",
                        "code": 11683,
                        "codename": "phuong_da_phuc",
                        "division_type": "phường",
                        "short_codename": "da_phuc"
                    },
                    {
                        "name": "Phường Hưng Đạo",
                        "code": 11686,
                        "codename": "phuong_hung_dao",
                        "division_type": "phường",
                        "short_codename": "hung_dao"
                    },
                    {
                        "name": "Phường Anh Dũng",
                        "code": 11689,
                        "codename": "phuong_anh_dung",
                        "division_type": "phường",
                        "short_codename": "anh_dung"
                    },
                    {
                        "name": "Phường Hải Thành",
                        "code": 11692,
                        "codename": "phuong_hai_thanh",
                        "division_type": "phường",
                        "short_codename": "hai_thanh"
                    },
                    {
                        "name": "Phường Hoà Nghĩa",
                        "code": 11707,
                        "codename": "phuong_hoa_nghia",
                        "division_type": "phường",
                        "short_codename": "hoa_nghia"
                    },
                    {
                        "name": "Phường Tân Thành",
                        "code": 11740,
                        "codename": "phuong_tan_thanh",
                        "division_type": "phường",
                        "short_codename": "tan_thanh"
                    }
                ]
            },
            {
                "name": "Huyện Thuỷ Nguyên",
                "code": 311,
                "codename": "huyen_thuy_nguyen",
                "division_type": "huyện",
                "short_codename": "thuy_nguyen",
                "wards": [
                    {
                        "name": "Thị trấn Núi Đèo",
                        "code": 11470,
                        "codename": "thi_tran_nui_deo",
                        "division_type": "thị trấn",
                        "short_codename": "nui_deo"
                    },
                    {
                        "name": "Thị trấn Minh Đức",
                        "code": 11473,
                        "codename": "thi_tran_minh_duc",
                        "division_type": "thị trấn",
                        "short_codename": "minh_duc"
                    },
                    {
                        "name": "Xã Lại Xuân",
                        "code": 11476,
                        "codename": "xa_lai_xuan",
                        "division_type": "xã",
                        "short_codename": "lai_xuan"
                    },
                    {
                        "name": "Xã An Sơn",
                        "code": 11479,
                        "codename": "xa_an_son",
                        "division_type": "xã",
                        "short_codename": "an_son"
                    },
                    {
                        "name": "Xã Kỳ Sơn",
                        "code": 11482,
                        "codename": "xa_ky_son",
                        "division_type": "xã",
                        "short_codename": "ky_son"
                    },
                    {
                        "name": "Xã Liên Khê",
                        "code": 11485,
                        "codename": "xa_lien_khe",
                        "division_type": "xã",
                        "short_codename": "lien_khe"
                    },
                    {
                        "name": "Xã Lưu Kiếm",
                        "code": 11488,
                        "codename": "xa_luu_kiem",
                        "division_type": "xã",
                        "short_codename": "luu_kiem"
                    },
                    {
                        "name": "Xã Lưu Kỳ",
                        "code": 11491,
                        "codename": "xa_luu_ky",
                        "division_type": "xã",
                        "short_codename": "luu_ky"
                    },
                    {
                        "name": "Xã Gia Minh",
                        "code": 11494,
                        "codename": "xa_gia_minh",
                        "division_type": "xã",
                        "short_codename": "gia_minh"
                    },
                    {
                        "name": "Xã Gia Đức",
                        "code": 11497,
                        "codename": "xa_gia_duc",
                        "division_type": "xã",
                        "short_codename": "gia_duc"
                    },
                    {
                        "name": "Xã Minh Tân",
                        "code": 11500,
                        "codename": "xa_minh_tan",
                        "division_type": "xã",
                        "short_codename": "minh_tan"
                    },
                    {
                        "name": "Xã Phù Ninh",
                        "code": 11503,
                        "codename": "xa_phu_ninh",
                        "division_type": "xã",
                        "short_codename": "phu_ninh"
                    },
                    {
                        "name": "Xã Quảng Thanh",
                        "code": 11506,
                        "codename": "xa_quang_thanh",
                        "division_type": "xã",
                        "short_codename": "quang_thanh"
                    },
                    {
                        "name": "Xã Chính Mỹ",
                        "code": 11509,
                        "codename": "xa_chinh_my",
                        "division_type": "xã",
                        "short_codename": "chinh_my"
                    },
                    {
                        "name": "Xã Kênh Giang",
                        "code": 11512,
                        "codename": "xa_kenh_giang",
                        "division_type": "xã",
                        "short_codename": "kenh_giang"
                    },
                    {
                        "name": "Xã Hợp Thành",
                        "code": 11515,
                        "codename": "xa_hop_thanh",
                        "division_type": "xã",
                        "short_codename": "hop_thanh"
                    },
                    {
                        "name": "Xã Cao Nhân",
                        "code": 11518,
                        "codename": "xa_cao_nhan",
                        "division_type": "xã",
                        "short_codename": "cao_nhan"
                    },
                    {
                        "name": "Xã Mỹ Đồng",
                        "code": 11521,
                        "codename": "xa_my_dong",
                        "division_type": "xã",
                        "short_codename": "my_dong"
                    },
                    {
                        "name": "Xã Đông Sơn",
                        "code": 11524,
                        "codename": "xa_dong_son",
                        "division_type": "xã",
                        "short_codename": "dong_son"
                    },
                    {
                        "name": "Xã Hoà Bình",
                        "code": 11527,
                        "codename": "xa_hoa_binh",
                        "division_type": "xã",
                        "short_codename": "hoa_binh"
                    },
                    {
                        "name": "Xã Trung Hà",
                        "code": 11530,
                        "codename": "xa_trung_ha",
                        "division_type": "xã",
                        "short_codename": "trung_ha"
                    },
                    {
                        "name": "Xã An Lư",
                        "code": 11533,
                        "codename": "xa_an_lu",
                        "division_type": "xã",
                        "short_codename": "an_lu"
                    },
                    {
                        "name": "Xã Thuỷ Triều",
                        "code": 11536,
                        "codename": "xa_thuy_trieu",
                        "division_type": "xã",
                        "short_codename": "thuy_trieu"
                    },
                    {
                        "name": "Xã Ngũ Lão",
                        "code": 11539,
                        "codename": "xa_ngu_lao",
                        "division_type": "xã",
                        "short_codename": "ngu_lao"
                    },
                    {
                        "name": "Xã Phục Lễ",
                        "code": 11542,
                        "codename": "xa_phuc_le",
                        "division_type": "xã",
                        "short_codename": "phuc_le"
                    },
                    {
                        "name": "Xã Tam Hưng",
                        "code": 11545,
                        "codename": "xa_tam_hung",
                        "division_type": "xã",
                        "short_codename": "tam_hung"
                    },
                    {
                        "name": "Xã Phả Lễ",
                        "code": 11548,
                        "codename": "xa_pha_le",
                        "division_type": "xã",
                        "short_codename": "pha_le"
                    },
                    {
                        "name": "Xã Lập Lễ",
                        "code": 11551,
                        "codename": "xa_lap_le",
                        "division_type": "xã",
                        "short_codename": "lap_le"
                    },
                    {
                        "name": "Xã Kiền Bái",
                        "code": 11554,
                        "codename": "xa_kien_bai",
                        "division_type": "xã",
                        "short_codename": "kien_bai"
                    },
                    {
                        "name": "Xã Thiên Hương",
                        "code": 11557,
                        "codename": "xa_thien_huong",
                        "division_type": "xã",
                        "short_codename": "thien_huong"
                    },
                    {
                        "name": "Xã Thuỷ Sơn",
                        "code": 11560,
                        "codename": "xa_thuy_son",
                        "division_type": "xã",
                        "short_codename": "thuy_son"
                    },
                    {
                        "name": "Xã Thuỷ Đường",
                        "code": 11563,
                        "codename": "xa_thuy_duong",
                        "division_type": "xã",
                        "short_codename": "thuy_duong"
                    },
                    {
                        "name": "Xã Hoàng Động",
                        "code": 11566,
                        "codename": "xa_hoang_dong",
                        "division_type": "xã",
                        "short_codename": "hoang_dong"
                    },
                    {
                        "name": "Xã Lâm Động",
                        "code": 11569,
                        "codename": "xa_lam_dong",
                        "division_type": "xã",
                        "short_codename": "lam_dong"
                    },
                    {
                        "name": "Xã Hoa Động",
                        "code": 11572,
                        "codename": "xa_hoa_dong",
                        "division_type": "xã",
                        "short_codename": "hoa_dong"
                    },
                    {
                        "name": "Xã Tân Dương",
                        "code": 11575,
                        "codename": "xa_tan_duong",
                        "division_type": "xã",
                        "short_codename": "tan_duong"
                    },
                    {
                        "name": "Xã Dương Quan",
                        "code": 11578,
                        "codename": "xa_duong_quan",
                        "division_type": "xã",
                        "short_codename": "duong_quan"
                    }
                ]
            },
            {
                "name": "Huyện An Dương",
                "code": 312,
                "codename": "huyen_an_duong",
                "division_type": "huyện",
                "short_codename": "an_duong",
                "wards": [
                    {
                        "name": "Thị trấn An Dương",
                        "code": 11581,
                        "codename": "thi_tran_an_duong",
                        "division_type": "thị trấn",
                        "short_codename": "an_duong"
                    },
                    {
                        "name": "Xã Lê Thiện",
                        "code": 11584,
                        "codename": "xa_le_thien",
                        "division_type": "xã",
                        "short_codename": "le_thien"
                    },
                    {
                        "name": "Xã Đại Bản",
                        "code": 11587,
                        "codename": "xa_dai_ban",
                        "division_type": "xã",
                        "short_codename": "dai_ban"
                    },
                    {
                        "name": "Xã An Hoà",
                        "code": 11590,
                        "codename": "xa_an_hoa",
                        "division_type": "xã",
                        "short_codename": "an_hoa"
                    },
                    {
                        "name": "Xã Hồng Phong",
                        "code": 11593,
                        "codename": "xa_hong_phong",
                        "division_type": "xã",
                        "short_codename": "hong_phong"
                    },
                    {
                        "name": "Xã Tân Tiến",
                        "code": 11596,
                        "codename": "xa_tan_tien",
                        "division_type": "xã",
                        "short_codename": "tan_tien"
                    },
                    {
                        "name": "Xã An Hưng",
                        "code": 11599,
                        "codename": "xa_an_hung",
                        "division_type": "xã",
                        "short_codename": "an_hung"
                    },
                    {
                        "name": "Xã An Hồng",
                        "code": 11602,
                        "codename": "xa_an_hong",
                        "division_type": "xã",
                        "short_codename": "an_hong"
                    },
                    {
                        "name": "Xã Bắc Sơn",
                        "code": 11605,
                        "codename": "xa_bac_son",
                        "division_type": "xã",
                        "short_codename": "bac_son"
                    },
                    {
                        "name": "Xã Nam Sơn",
                        "code": 11608,
                        "codename": "xa_nam_son",
                        "division_type": "xã",
                        "short_codename": "nam_son"
                    },
                    {
                        "name": "Xã Lê Lợi",
                        "code": 11611,
                        "codename": "xa_le_loi",
                        "division_type": "xã",
                        "short_codename": "le_loi"
                    },
                    {
                        "name": "Xã Đặng Cương",
                        "code": 11614,
                        "codename": "xa_dang_cuong",
                        "division_type": "xã",
                        "short_codename": "dang_cuong"
                    },
                    {
                        "name": "Xã Đồng Thái",
                        "code": 11617,
                        "codename": "xa_dong_thai",
                        "division_type": "xã",
                        "short_codename": "dong_thai"
                    },
                    {
                        "name": "Xã Quốc Tuấn",
                        "code": 11620,
                        "codename": "xa_quoc_tuan",
                        "division_type": "xã",
                        "short_codename": "quoc_tuan"
                    },
                    {
                        "name": "Xã An Đồng",
                        "code": 11623,
                        "codename": "xa_an_dong",
                        "division_type": "xã",
                        "short_codename": "an_dong"
                    },
                    {
                        "name": "Xã Hồng Thái",
                        "code": 11626,
                        "codename": "xa_hong_thai",
                        "division_type": "xã",
                        "short_codename": "hong_thai"
                    }
                ]
            },
            {
                "name": "Huyện An Lão",
                "code": 313,
                "codename": "huyen_an_lao",
                "division_type": "huyện",
                "short_codename": "an_lao",
                "wards": [
                    {
                        "name": "Thị trấn An Lão",
                        "code": 11629,
                        "codename": "thi_tran_an_lao",
                        "division_type": "thị trấn",
                        "short_codename": "an_lao"
                    },
                    {
                        "name": "Xã Bát Trang",
                        "code": 11632,
                        "codename": "xa_bat_trang",
                        "division_type": "xã",
                        "short_codename": "bat_trang"
                    },
                    {
                        "name": "Xã Trường Thọ",
                        "code": 11635,
                        "codename": "xa_truong_tho",
                        "division_type": "xã",
                        "short_codename": "truong_tho"
                    },
                    {
                        "name": "Xã Trường Thành",
                        "code": 11638,
                        "codename": "xa_truong_thanh",
                        "division_type": "xã",
                        "short_codename": "truong_thanh"
                    },
                    {
                        "name": "Xã An Tiến",
                        "code": 11641,
                        "codename": "xa_an_tien",
                        "division_type": "xã",
                        "short_codename": "an_tien"
                    },
                    {
                        "name": "Xã Quang Hưng",
                        "code": 11644,
                        "codename": "xa_quang_hung",
                        "division_type": "xã",
                        "short_codename": "quang_hung"
                    },
                    {
                        "name": "Xã Quang Trung",
                        "code": 11647,
                        "codename": "xa_quang_trung",
                        "division_type": "xã",
                        "short_codename": "quang_trung"
                    },
                    {
                        "name": "Xã Quốc Tuấn",
                        "code": 11650,
                        "codename": "xa_quoc_tuan",
                        "division_type": "xã",
                        "short_codename": "quoc_tuan"
                    },
                    {
                        "name": "Xã An Thắng",
                        "code": 11653,
                        "codename": "xa_an_thang",
                        "division_type": "xã",
                        "short_codename": "an_thang"
                    },
                    {
                        "name": "Thị trấn Trường Sơn",
                        "code": 11656,
                        "codename": "thi_tran_truong_son",
                        "division_type": "thị trấn",
                        "short_codename": "truong_son"
                    },
                    {
                        "name": "Xã Tân Dân",
                        "code": 11659,
                        "codename": "xa_tan_dan",
                        "division_type": "xã",
                        "short_codename": "tan_dan"
                    },
                    {
                        "name": "Xã Thái Sơn",
                        "code": 11662,
                        "codename": "xa_thai_son",
                        "division_type": "xã",
                        "short_codename": "thai_son"
                    },
                    {
                        "name": "Xã Tân Viên",
                        "code": 11665,
                        "codename": "xa_tan_vien",
                        "division_type": "xã",
                        "short_codename": "tan_vien"
                    },
                    {
                        "name": "Xã Mỹ Đức",
                        "code": 11668,
                        "codename": "xa_my_duc",
                        "division_type": "xã",
                        "short_codename": "my_duc"
                    },
                    {
                        "name": "Xã Chiến Thắng",
                        "code": 11671,
                        "codename": "xa_chien_thang",
                        "division_type": "xã",
                        "short_codename": "chien_thang"
                    },
                    {
                        "name": "Xã An Thọ",
                        "code": 11674,
                        "codename": "xa_an_tho",
                        "division_type": "xã",
                        "short_codename": "an_tho"
                    },
                    {
                        "name": "Xã An Thái",
                        "code": 11677,
                        "codename": "xa_an_thai",
                        "division_type": "xã",
                        "short_codename": "an_thai"
                    }
                ]
            },
            {
                "name": "Huyện Kiến Thuỵ",
                "code": 314,
                "codename": "huyen_kien_thuy",
                "division_type": "huyện",
                "short_codename": "kien_thuy",
                "wards": [
                    {
                        "name": "Thị trấn Núi Đối",
                        "code": 11680,
                        "codename": "thi_tran_nui_doi",
                        "division_type": "thị trấn",
                        "short_codename": "nui_doi"
                    },
                    {
                        "name": "Xã Đông Phương",
                        "code": 11695,
                        "codename": "xa_dong_phuong",
                        "division_type": "xã",
                        "short_codename": "dong_phuong"
                    },
                    {
                        "name": "Xã Thuận Thiên",
                        "code": 11698,
                        "codename": "xa_thuan_thien",
                        "division_type": "xã",
                        "short_codename": "thuan_thien"
                    },
                    {
                        "name": "Xã Hữu Bằng",
                        "code": 11701,
                        "codename": "xa_huu_bang",
                        "division_type": "xã",
                        "short_codename": "huu_bang"
                    },
                    {
                        "name": "Xã Đại Đồng",
                        "code": 11704,
                        "codename": "xa_dai_dong",
                        "division_type": "xã",
                        "short_codename": "dai_dong"
                    },
                    {
                        "name": "Xã Ngũ Phúc",
                        "code": 11710,
                        "codename": "xa_ngu_phuc",
                        "division_type": "xã",
                        "short_codename": "ngu_phuc"
                    },
                    {
                        "name": "Xã Kiến Quốc",
                        "code": 11713,
                        "codename": "xa_kien_quoc",
                        "division_type": "xã",
                        "short_codename": "kien_quoc"
                    },
                    {
                        "name": "Xã Du Lễ",
                        "code": 11716,
                        "codename": "xa_du_le",
                        "division_type": "xã",
                        "short_codename": "du_le"
                    },
                    {
                        "name": "Xã Thuỵ Hương",
                        "code": 11719,
                        "codename": "xa_thuy_huong",
                        "division_type": "xã",
                        "short_codename": "thuy_huong"
                    },
                    {
                        "name": "Xã Thanh Sơn",
                        "code": 11722,
                        "codename": "xa_thanh_son",
                        "division_type": "xã",
                        "short_codename": "thanh_son"
                    },
                    {
                        "name": "Xã Minh Tân",
                        "code": 11725,
                        "codename": "xa_minh_tan",
                        "division_type": "xã",
                        "short_codename": "minh_tan"
                    },
                    {
                        "name": "Xã Đại Hà",
                        "code": 11728,
                        "codename": "xa_dai_ha",
                        "division_type": "xã",
                        "short_codename": "dai_ha"
                    },
                    {
                        "name": "Xã Ngũ Đoan",
                        "code": 11731,
                        "codename": "xa_ngu_doan",
                        "division_type": "xã",
                        "short_codename": "ngu_doan"
                    },
                    {
                        "name": "Xã Tân Phong",
                        "code": 11734,
                        "codename": "xa_tan_phong",
                        "division_type": "xã",
                        "short_codename": "tan_phong"
                    },
                    {
                        "name": "Xã Tân Trào",
                        "code": 11743,
                        "codename": "xa_tan_trao",
                        "division_type": "xã",
                        "short_codename": "tan_trao"
                    },
                    {
                        "name": "Xã Đoàn Xá",
                        "code": 11746,
                        "codename": "xa_doan_xa",
                        "division_type": "xã",
                        "short_codename": "doan_xa"
                    },
                    {
                        "name": "Xã Tú Sơn",
                        "code": 11749,
                        "codename": "xa_tu_son",
                        "division_type": "xã",
                        "short_codename": "tu_son"
                    },
                    {
                        "name": "Xã Đại Hợp",
                        "code": 11752,
                        "codename": "xa_dai_hop",
                        "division_type": "xã",
                        "short_codename": "dai_hop"
                    }
                ]
            },
            {
                "name": "Huyện Tiên Lãng",
                "code": 315,
                "codename": "huyen_tien_lang",
                "division_type": "huyện",
                "short_codename": "tien_lang",
                "wards": [
                    {
                        "name": "Thị trấn Tiên Lãng",
                        "code": 11755,
                        "codename": "thi_tran_tien_lang",
                        "division_type": "thị trấn",
                        "short_codename": "tien_lang"
                    },
                    {
                        "name": "Xã Đại Thắng",
                        "code": 11758,
                        "codename": "xa_dai_thang",
                        "division_type": "xã",
                        "short_codename": "dai_thang"
                    },
                    {
                        "name": "Xã Tiên Cường",
                        "code": 11761,
                        "codename": "xa_tien_cuong",
                        "division_type": "xã",
                        "short_codename": "tien_cuong"
                    },
                    {
                        "name": "Xã Tự Cường",
                        "code": 11764,
                        "codename": "xa_tu_cuong",
                        "division_type": "xã",
                        "short_codename": "tu_cuong"
                    },
                    {
                        "name": "Xã Quyết Tiến",
                        "code": 11770,
                        "codename": "xa_quyet_tien",
                        "division_type": "xã",
                        "short_codename": "quyet_tien"
                    },
                    {
                        "name": "Xã Khởi Nghĩa",
                        "code": 11773,
                        "codename": "xa_khoi_nghia",
                        "division_type": "xã",
                        "short_codename": "khoi_nghia"
                    },
                    {
                        "name": "Xã Tiên Thanh",
                        "code": 11776,
                        "codename": "xa_tien_thanh",
                        "division_type": "xã",
                        "short_codename": "tien_thanh"
                    },
                    {
                        "name": "Xã Cấp Tiến",
                        "code": 11779,
                        "codename": "xa_cap_tien",
                        "division_type": "xã",
                        "short_codename": "cap_tien"
                    },
                    {
                        "name": "Xã Kiến Thiết",
                        "code": 11782,
                        "codename": "xa_kien_thiet",
                        "division_type": "xã",
                        "short_codename": "kien_thiet"
                    },
                    {
                        "name": "Xã Đoàn Lập",
                        "code": 11785,
                        "codename": "xa_doan_lap",
                        "division_type": "xã",
                        "short_codename": "doan_lap"
                    },
                    {
                        "name": "Xã Bạch Đằng",
                        "code": 11788,
                        "codename": "xa_bach_dang",
                        "division_type": "xã",
                        "short_codename": "bach_dang"
                    },
                    {
                        "name": "Xã Quang Phục",
                        "code": 11791,
                        "codename": "xa_quang_phuc",
                        "division_type": "xã",
                        "short_codename": "quang_phuc"
                    },
                    {
                        "name": "Xã Toàn Thắng",
                        "code": 11794,
                        "codename": "xa_toan_thang",
                        "division_type": "xã",
                        "short_codename": "toan_thang"
                    },
                    {
                        "name": "Xã Tiên Thắng",
                        "code": 11797,
                        "codename": "xa_tien_thang",
                        "division_type": "xã",
                        "short_codename": "tien_thang"
                    },
                    {
                        "name": "Xã Tiên Minh",
                        "code": 11800,
                        "codename": "xa_tien_minh",
                        "division_type": "xã",
                        "short_codename": "tien_minh"
                    },
                    {
                        "name": "Xã Bắc Hưng",
                        "code": 11803,
                        "codename": "xa_bac_hung",
                        "division_type": "xã",
                        "short_codename": "bac_hung"
                    },
                    {
                        "name": "Xã Nam Hưng",
                        "code": 11806,
                        "codename": "xa_nam_hung",
                        "division_type": "xã",
                        "short_codename": "nam_hung"
                    },
                    {
                        "name": "Xã Hùng Thắng",
                        "code": 11809,
                        "codename": "xa_hung_thang",
                        "division_type": "xã",
                        "short_codename": "hung_thang"
                    },
                    {
                        "name": "Xã Tây Hưng",
                        "code": 11812,
                        "codename": "xa_tay_hung",
                        "division_type": "xã",
                        "short_codename": "tay_hung"
                    },
                    {
                        "name": "Xã Đông Hưng",
                        "code": 11815,
                        "codename": "xa_dong_hung",
                        "division_type": "xã",
                        "short_codename": "dong_hung"
                    },
                    {
                        "name": "Xã Vinh Quang",
                        "code": 11821,
                        "codename": "xa_vinh_quang",
                        "division_type": "xã",
                        "short_codename": "vinh_quang"
                    }
                ]
            },
            {
                "name": "Huyện Vĩnh Bảo",
                "code": 316,
                "codename": "huyen_vinh_bao",
                "division_type": "huyện",
                "short_codename": "vinh_bao",
                "wards": [
                    {
                        "name": "Thị trấn Vĩnh Bảo",
                        "code": 11824,
                        "codename": "thi_tran_vinh_bao",
                        "division_type": "thị trấn",
                        "short_codename": "vinh_bao"
                    },
                    {
                        "name": "Xã Dũng Tiến",
                        "code": 11827,
                        "codename": "xa_dung_tien",
                        "division_type": "xã",
                        "short_codename": "dung_tien"
                    },
                    {
                        "name": "Xã Giang Biên",
                        "code": 11830,
                        "codename": "xa_giang_bien",
                        "division_type": "xã",
                        "short_codename": "giang_bien"
                    },
                    {
                        "name": "Xã Thắng Thuỷ",
                        "code": 11833,
                        "codename": "xa_thang_thuy",
                        "division_type": "xã",
                        "short_codename": "thang_thuy"
                    },
                    {
                        "name": "Xã Trung Lập",
                        "code": 11836,
                        "codename": "xa_trung_lap",
                        "division_type": "xã",
                        "short_codename": "trung_lap"
                    },
                    {
                        "name": "Xã Việt Tiến",
                        "code": 11839,
                        "codename": "xa_viet_tien",
                        "division_type": "xã",
                        "short_codename": "viet_tien"
                    },
                    {
                        "name": "Xã Vĩnh An",
                        "code": 11842,
                        "codename": "xa_vinh_an",
                        "division_type": "xã",
                        "short_codename": "vinh_an"
                    },
                    {
                        "name": "Xã Vĩnh Long",
                        "code": 11845,
                        "codename": "xa_vinh_long",
                        "division_type": "xã",
                        "short_codename": "vinh_long"
                    },
                    {
                        "name": "Xã Hiệp Hoà",
                        "code": 11848,
                        "codename": "xa_hiep_hoa",
                        "division_type": "xã",
                        "short_codename": "hiep_hoa"
                    },
                    {
                        "name": "Xã Hùng Tiến",
                        "code": 11851,
                        "codename": "xa_hung_tien",
                        "division_type": "xã",
                        "short_codename": "hung_tien"
                    },
                    {
                        "name": "Xã An Hoà",
                        "code": 11854,
                        "codename": "xa_an_hoa",
                        "division_type": "xã",
                        "short_codename": "an_hoa"
                    },
                    {
                        "name": "Xã Tân Hưng",
                        "code": 11857,
                        "codename": "xa_tan_hung",
                        "division_type": "xã",
                        "short_codename": "tan_hung"
                    },
                    {
                        "name": "Xã Tân Liên",
                        "code": 11860,
                        "codename": "xa_tan_lien",
                        "division_type": "xã",
                        "short_codename": "tan_lien"
                    },
                    {
                        "name": "Xã Nhân Hoà",
                        "code": 11863,
                        "codename": "xa_nhan_hoa",
                        "division_type": "xã",
                        "short_codename": "nhan_hoa"
                    },
                    {
                        "name": "Xã Tam Đa",
                        "code": 11866,
                        "codename": "xa_tam_da",
                        "division_type": "xã",
                        "short_codename": "tam_da"
                    },
                    {
                        "name": "Xã Hưng Nhân",
                        "code": 11869,
                        "codename": "xa_hung_nhan",
                        "division_type": "xã",
                        "short_codename": "hung_nhan"
                    },
                    {
                        "name": "Xã Vinh Quang",
                        "code": 11872,
                        "codename": "xa_vinh_quang",
                        "division_type": "xã",
                        "short_codename": "vinh_quang"
                    },
                    {
                        "name": "Xã Đồng Minh",
                        "code": 11875,
                        "codename": "xa_dong_minh",
                        "division_type": "xã",
                        "short_codename": "dong_minh"
                    },
                    {
                        "name": "Xã Thanh Lương",
                        "code": 11878,
                        "codename": "xa_thanh_luong",
                        "division_type": "xã",
                        "short_codename": "thanh_luong"
                    },
                    {
                        "name": "Xã Liên Am",
                        "code": 11881,
                        "codename": "xa_lien_am",
                        "division_type": "xã",
                        "short_codename": "lien_am"
                    },
                    {
                        "name": "Xã Lý Học",
                        "code": 11884,
                        "codename": "xa_ly_hoc",
                        "division_type": "xã",
                        "short_codename": "ly_hoc"
                    },
                    {
                        "name": "Xã Tam Cường",
                        "code": 11887,
                        "codename": "xa_tam_cuong",
                        "division_type": "xã",
                        "short_codename": "tam_cuong"
                    },
                    {
                        "name": "Xã Hoà Bình",
                        "code": 11890,
                        "codename": "xa_hoa_binh",
                        "division_type": "xã",
                        "short_codename": "hoa_binh"
                    },
                    {
                        "name": "Xã Tiền Phong",
                        "code": 11893,
                        "codename": "xa_tien_phong",
                        "division_type": "xã",
                        "short_codename": "tien_phong"
                    },
                    {
                        "name": "Xã Vĩnh Phong",
                        "code": 11896,
                        "codename": "xa_vinh_phong",
                        "division_type": "xã",
                        "short_codename": "vinh_phong"
                    },
                    {
                        "name": "Xã Cộng Hiền",
                        "code": 11899,
                        "codename": "xa_cong_hien",
                        "division_type": "xã",
                        "short_codename": "cong_hien"
                    },
                    {
                        "name": "Xã Cao Minh",
                        "code": 11902,
                        "codename": "xa_cao_minh",
                        "division_type": "xã",
                        "short_codename": "cao_minh"
                    },
                    {
                        "name": "Xã Cổ Am",
                        "code": 11905,
                        "codename": "xa_co_am",
                        "division_type": "xã",
                        "short_codename": "co_am"
                    },
                    {
                        "name": "Xã Vĩnh Tiến",
                        "code": 11908,
                        "codename": "xa_vinh_tien",
                        "division_type": "xã",
                        "short_codename": "vinh_tien"
                    },
                    {
                        "name": "Xã Trấn Dương",
                        "code": 11911,
                        "codename": "xa_tran_duong",
                        "division_type": "xã",
                        "short_codename": "tran_duong"
                    }
                ]
            },
            {
                "name": "Huyện Cát Hải",
                "code": 317,
                "codename": "huyen_cat_hai",
                "division_type": "huyện",
                "short_codename": "cat_hai",
                "wards": [
                    {
                        "name": "Thị trấn Cát Bà",
                        "code": 11914,
                        "codename": "thi_tran_cat_ba",
                        "division_type": "thị trấn",
                        "short_codename": "cat_ba"
                    },
                    {
                        "name": "Thị trấn Cát Hải",
                        "code": 11917,
                        "codename": "thi_tran_cat_hai",
                        "division_type": "thị trấn",
                        "short_codename": "cat_hai"
                    },
                    {
                        "name": "Xã Nghĩa Lộ",
                        "code": 11920,
                        "codename": "xa_nghia_lo",
                        "division_type": "xã",
                        "short_codename": "nghia_lo"
                    },
                    {
                        "name": "Xã Đồng Bài",
                        "code": 11923,
                        "codename": "xa_dong_bai",
                        "division_type": "xã",
                        "short_codename": "dong_bai"
                    },
                    {
                        "name": "Xã Hoàng Châu",
                        "code": 11926,
                        "codename": "xa_hoang_chau",
                        "division_type": "xã",
                        "short_codename": "hoang_chau"
                    },
                    {
                        "name": "Xã Văn Phong",
                        "code": 11929,
                        "codename": "xa_van_phong",
                        "division_type": "xã",
                        "short_codename": "van_phong"
                    },
                    {
                        "name": "Xã Phù Long",
                        "code": 11932,
                        "codename": "xa_phu_long",
                        "division_type": "xã",
                        "short_codename": "phu_long"
                    },
                    {
                        "name": "Xã Gia Luận",
                        "code": 11935,
                        "codename": "xa_gia_luan",
                        "division_type": "xã",
                        "short_codename": "gia_luan"
                    },
                    {
                        "name": "Xã Hiền Hào",
                        "code": 11938,
                        "codename": "xa_hien_hao",
                        "division_type": "xã",
                        "short_codename": "hien_hao"
                    },
                    {
                        "name": "Xã Trân Châu",
                        "code": 11941,
                        "codename": "xa_tran_chau",
                        "division_type": "xã",
                        "short_codename": "tran_chau"
                    },
                    {
                        "name": "Xã Việt Hải",
                        "code": 11944,
                        "codename": "xa_viet_hai",
                        "division_type": "xã",
                        "short_codename": "viet_hai"
                    },
                    {
                        "name": "Xã Xuân Đám",
                        "code": 11947,
                        "codename": "xa_xuan_dam",
                        "division_type": "xã",
                        "short_codename": "xuan_dam"
                    }
                ]
            },
            {
                "name": "Huyện Bạch Long Vĩ",
                "code": 318,
                "codename": "huyen_bach_long_vi",
                "division_type": "huyện",
                "short_codename": "bach_long_vi",
                "wards": []
            }
        ]
    },
    {
        "name": "Tỉnh Hưng Yên",
        "code": 33,
        "codename": "tinh_hung_yen",
        "division_type": "tỉnh",
        "phone_code": 221,
        "districts": [
            {
                "name": "Thành phố Hưng Yên",
                "code": 323,
                "codename": "thanh_pho_hung_yen",
                "division_type": "thành phố",
                "short_codename": "hung_yen",
                "wards": [
                    {
                        "name": "Phường Lam Sơn",
                        "code": 11950,
                        "codename": "phuong_lam_son",
                        "division_type": "phường",
                        "short_codename": "lam_son"
                    },
                    {
                        "name": "Phường Hiến Nam",
                        "code": 11953,
                        "codename": "phuong_hien_nam",
                        "division_type": "phường",
                        "short_codename": "hien_nam"
                    },
                    {
                        "name": "Phường An Tảo",
                        "code": 11956,
                        "codename": "phuong_an_tao",
                        "division_type": "phường",
                        "short_codename": "an_tao"
                    },
                    {
                        "name": "Phường Lê Lợi",
                        "code": 11959,
                        "codename": "phuong_le_loi",
                        "division_type": "phường",
                        "short_codename": "le_loi"
                    },
                    {
                        "name": "Phường Minh Khai",
                        "code": 11962,
                        "codename": "phuong_minh_khai",
                        "division_type": "phường",
                        "short_codename": "minh_khai"
                    },
                    {
                        "name": "Phường Quang Trung",
                        "code": 11965,
                        "codename": "phuong_quang_trung",
                        "division_type": "phường",
                        "short_codename": "quang_trung"
                    },
                    {
                        "name": "Phường Hồng Châu",
                        "code": 11968,
                        "codename": "phuong_hong_chau",
                        "division_type": "phường",
                        "short_codename": "hong_chau"
                    },
                    {
                        "name": "Xã Trung Nghĩa",
                        "code": 11971,
                        "codename": "xa_trung_nghia",
                        "division_type": "xã",
                        "short_codename": "trung_nghia"
                    },
                    {
                        "name": "Xã Liên Phương",
                        "code": 11974,
                        "codename": "xa_lien_phuong",
                        "division_type": "xã",
                        "short_codename": "lien_phuong"
                    },
                    {
                        "name": "Xã Hồng Nam",
                        "code": 11977,
                        "codename": "xa_hong_nam",
                        "division_type": "xã",
                        "short_codename": "hong_nam"
                    },
                    {
                        "name": "Xã Quảng Châu",
                        "code": 11980,
                        "codename": "xa_quang_chau",
                        "division_type": "xã",
                        "short_codename": "quang_chau"
                    },
                    {
                        "name": "Xã Bảo Khê",
                        "code": 11983,
                        "codename": "xa_bao_khe",
                        "division_type": "xã",
                        "short_codename": "bao_khe"
                    },
                    {
                        "name": "Xã Phú Cường",
                        "code": 12331,
                        "codename": "xa_phu_cuong",
                        "division_type": "xã",
                        "short_codename": "phu_cuong"
                    },
                    {
                        "name": "Xã Hùng Cường",
                        "code": 12334,
                        "codename": "xa_hung_cuong",
                        "division_type": "xã",
                        "short_codename": "hung_cuong"
                    },
                    {
                        "name": "Xã Phương Chiểu",
                        "code": 12382,
                        "codename": "xa_phuong_chieu",
                        "division_type": "xã",
                        "short_codename": "phuong_chieu"
                    },
                    {
                        "name": "Xã Tân Hưng",
                        "code": 12385,
                        "codename": "xa_tan_hung",
                        "division_type": "xã",
                        "short_codename": "tan_hung"
                    },
                    {
                        "name": "Xã Hoàng Hanh",
                        "code": 12388,
                        "codename": "xa_hoang_hanh",
                        "division_type": "xã",
                        "short_codename": "hoang_hanh"
                    }
                ]
            },
            {
                "name": "Huyện Văn Lâm",
                "code": 325,
                "codename": "huyen_van_lam",
                "division_type": "huyện",
                "short_codename": "van_lam",
                "wards": [
                    {
                        "name": "Thị trấn Như Quỳnh",
                        "code": 11986,
                        "codename": "thi_tran_nhu_quynh",
                        "division_type": "thị trấn",
                        "short_codename": "nhu_quynh"
                    },
                    {
                        "name": "Xã Lạc Đạo",
                        "code": 11989,
                        "codename": "xa_lac_dao",
                        "division_type": "xã",
                        "short_codename": "lac_dao"
                    },
                    {
                        "name": "Xã Chỉ Đạo",
                        "code": 11992,
                        "codename": "xa_chi_dao",
                        "division_type": "xã",
                        "short_codename": "chi_dao"
                    },
                    {
                        "name": "Xã Đại Đồng",
                        "code": 11995,
                        "codename": "xa_dai_dong",
                        "division_type": "xã",
                        "short_codename": "dai_dong"
                    },
                    {
                        "name": "Xã Việt Hưng",
                        "code": 11998,
                        "codename": "xa_viet_hung",
                        "division_type": "xã",
                        "short_codename": "viet_hung"
                    },
                    {
                        "name": "Xã Tân Quang",
                        "code": 12001,
                        "codename": "xa_tan_quang",
                        "division_type": "xã",
                        "short_codename": "tan_quang"
                    },
                    {
                        "name": "Xã Đình Dù",
                        "code": 12004,
                        "codename": "xa_dinh_du",
                        "division_type": "xã",
                        "short_codename": "dinh_du"
                    },
                    {
                        "name": "Xã Minh Hải",
                        "code": 12007,
                        "codename": "xa_minh_hai",
                        "division_type": "xã",
                        "short_codename": "minh_hai"
                    },
                    {
                        "name": "Xã Lương Tài",
                        "code": 12010,
                        "codename": "xa_luong_tai",
                        "division_type": "xã",
                        "short_codename": "luong_tai"
                    },
                    {
                        "name": "Xã Trưng Trắc",
                        "code": 12013,
                        "codename": "xa_trung_trac",
                        "division_type": "xã",
                        "short_codename": "trung_trac"
                    },
                    {
                        "name": "Xã Lạc Hồng",
                        "code": 12016,
                        "codename": "xa_lac_hong",
                        "division_type": "xã",
                        "short_codename": "lac_hong"
                    }
                ]
            },
            {
                "name": "Huyện Văn Giang",
                "code": 326,
                "codename": "huyen_van_giang",
                "division_type": "huyện",
                "short_codename": "van_giang",
                "wards": [
                    {
                        "name": "Thị trấn Văn Giang",
                        "code": 12019,
                        "codename": "thi_tran_van_giang",
                        "division_type": "thị trấn",
                        "short_codename": "van_giang"
                    },
                    {
                        "name": "Xã Xuân Quan",
                        "code": 12022,
                        "codename": "xa_xuan_quan",
                        "division_type": "xã",
                        "short_codename": "xuan_quan"
                    },
                    {
                        "name": "Xã Cửu Cao",
                        "code": 12025,
                        "codename": "xa_cuu_cao",
                        "division_type": "xã",
                        "short_codename": "cuu_cao"
                    },
                    {
                        "name": "Xã Phụng Công",
                        "code": 12028,
                        "codename": "xa_phung_cong",
                        "division_type": "xã",
                        "short_codename": "phung_cong"
                    },
                    {
                        "name": "Xã Nghĩa Trụ",
                        "code": 12031,
                        "codename": "xa_nghia_tru",
                        "division_type": "xã",
                        "short_codename": "nghia_tru"
                    },
                    {
                        "name": "Xã Long Hưng",
                        "code": 12034,
                        "codename": "xa_long_hung",
                        "division_type": "xã",
                        "short_codename": "long_hung"
                    },
                    {
                        "name": "Xã Vĩnh Khúc",
                        "code": 12037,
                        "codename": "xa_vinh_khuc",
                        "division_type": "xã",
                        "short_codename": "vinh_khuc"
                    },
                    {
                        "name": "Xã Liên Nghĩa",
                        "code": 12040,
                        "codename": "xa_lien_nghia",
                        "division_type": "xã",
                        "short_codename": "lien_nghia"
                    },
                    {
                        "name": "Xã Tân Tiến",
                        "code": 12043,
                        "codename": "xa_tan_tien",
                        "division_type": "xã",
                        "short_codename": "tan_tien"
                    },
                    {
                        "name": "Xã Thắng Lợi",
                        "code": 12046,
                        "codename": "xa_thang_loi",
                        "division_type": "xã",
                        "short_codename": "thang_loi"
                    },
                    {
                        "name": "Xã Mễ Sở",
                        "code": 12049,
                        "codename": "xa_me_so",
                        "division_type": "xã",
                        "short_codename": "me_so"
                    }
                ]
            },
            {
                "name": "Huyện Yên Mỹ",
                "code": 327,
                "codename": "huyen_yen_my",
                "division_type": "huyện",
                "short_codename": "yen_my",
                "wards": [
                    {
                        "name": "Thị trấn Yên Mỹ",
                        "code": 12052,
                        "codename": "thi_tran_yen_my",
                        "division_type": "thị trấn",
                        "short_codename": "yen_my"
                    },
                    {
                        "name": "Xã Giai Phạm",
                        "code": 12055,
                        "codename": "xa_giai_pham",
                        "division_type": "xã",
                        "short_codename": "giai_pham"
                    },
                    {
                        "name": "Xã Nghĩa Hiệp",
                        "code": 12058,
                        "codename": "xa_nghia_hiep",
                        "division_type": "xã",
                        "short_codename": "nghia_hiep"
                    },
                    {
                        "name": "Xã Đồng Than",
                        "code": 12061,
                        "codename": "xa_dong_than",
                        "division_type": "xã",
                        "short_codename": "dong_than"
                    },
                    {
                        "name": "Xã Ngọc Long",
                        "code": 12064,
                        "codename": "xa_ngoc_long",
                        "division_type": "xã",
                        "short_codename": "ngoc_long"
                    },
                    {
                        "name": "Xã Liêu Xá",
                        "code": 12067,
                        "codename": "xa_lieu_xa",
                        "division_type": "xã",
                        "short_codename": "lieu_xa"
                    },
                    {
                        "name": "Xã Hoàn Long",
                        "code": 12070,
                        "codename": "xa_hoan_long",
                        "division_type": "xã",
                        "short_codename": "hoan_long"
                    },
                    {
                        "name": "Xã Tân Lập",
                        "code": 12073,
                        "codename": "xa_tan_lap",
                        "division_type": "xã",
                        "short_codename": "tan_lap"
                    },
                    {
                        "name": "Xã Thanh Long",
                        "code": 12076,
                        "codename": "xa_thanh_long",
                        "division_type": "xã",
                        "short_codename": "thanh_long"
                    },
                    {
                        "name": "Xã Yên Phú",
                        "code": 12079,
                        "codename": "xa_yen_phu",
                        "division_type": "xã",
                        "short_codename": "yen_phu"
                    },
                    {
                        "name": "Xã Việt Cường",
                        "code": 12082,
                        "codename": "xa_viet_cuong",
                        "division_type": "xã",
                        "short_codename": "viet_cuong"
                    },
                    {
                        "name": "Xã Trung Hòa",
                        "code": 12085,
                        "codename": "xa_trung_hoa",
                        "division_type": "xã",
                        "short_codename": "trung_hoa"
                    },
                    {
                        "name": "Xã Yên Hòa",
                        "code": 12088,
                        "codename": "xa_yen_hoa",
                        "division_type": "xã",
                        "short_codename": "yen_hoa"
                    },
                    {
                        "name": "Xã Minh Châu",
                        "code": 12091,
                        "codename": "xa_minh_chau",
                        "division_type": "xã",
                        "short_codename": "minh_chau"
                    },
                    {
                        "name": "Xã Trung Hưng",
                        "code": 12094,
                        "codename": "xa_trung_hung",
                        "division_type": "xã",
                        "short_codename": "trung_hung"
                    },
                    {
                        "name": "Xã Lý Thường Kiệt",
                        "code": 12097,
                        "codename": "xa_ly_thuong_kiet",
                        "division_type": "xã",
                        "short_codename": "ly_thuong_kiet"
                    },
                    {
                        "name": "Xã Tân Việt",
                        "code": 12100,
                        "codename": "xa_tan_viet",
                        "division_type": "xã",
                        "short_codename": "tan_viet"
                    }
                ]
            },
            {
                "name": "Thị xã Mỹ Hào",
                "code": 328,
                "codename": "thi_xa_my_hao",
                "division_type": "thị xã",
                "short_codename": "my_hao",
                "wards": [
                    {
                        "name": "Phường Bần Yên Nhân",
                        "code": 12103,
                        "codename": "phuong_ban_yen_nhan",
                        "division_type": "phường",
                        "short_codename": "ban_yen_nhan"
                    },
                    {
                        "name": "Phường Phan Đình Phùng",
                        "code": 12106,
                        "codename": "phuong_phan_dinh_phung",
                        "division_type": "phường",
                        "short_codename": "phan_dinh_phung"
                    },
                    {
                        "name": "Xã Cẩm Xá",
                        "code": 12109,
                        "codename": "xa_cam_xa",
                        "division_type": "xã",
                        "short_codename": "cam_xa"
                    },
                    {
                        "name": "Xã Dương Quang",
                        "code": 12112,
                        "codename": "xa_duong_quang",
                        "division_type": "xã",
                        "short_codename": "duong_quang"
                    },
                    {
                        "name": "Xã Hòa Phong",
                        "code": 12115,
                        "codename": "xa_hoa_phong",
                        "division_type": "xã",
                        "short_codename": "hoa_phong"
                    },
                    {
                        "name": "Phường Nhân Hòa",
                        "code": 12118,
                        "codename": "phuong_nhan_hoa",
                        "division_type": "phường",
                        "short_codename": "nhan_hoa"
                    },
                    {
                        "name": "Phường Dị Sử",
                        "code": 12121,
                        "codename": "phuong_di_su",
                        "division_type": "phường",
                        "short_codename": "di_su"
                    },
                    {
                        "name": "Phường Bạch Sam",
                        "code": 12124,
                        "codename": "phuong_bach_sam",
                        "division_type": "phường",
                        "short_codename": "bach_sam"
                    },
                    {
                        "name": "Phường Minh Đức",
                        "code": 12127,
                        "codename": "phuong_minh_duc",
                        "division_type": "phường",
                        "short_codename": "minh_duc"
                    },
                    {
                        "name": "Phường Phùng Chí Kiên",
                        "code": 12130,
                        "codename": "phuong_phung_chi_kien",
                        "division_type": "phường",
                        "short_codename": "phung_chi_kien"
                    },
                    {
                        "name": "Xã Xuân Dục",
                        "code": 12133,
                        "codename": "xa_xuan_duc",
                        "division_type": "xã",
                        "short_codename": "xuan_duc"
                    },
                    {
                        "name": "Xã Ngọc Lâm",
                        "code": 12136,
                        "codename": "xa_ngoc_lam",
                        "division_type": "xã",
                        "short_codename": "ngoc_lam"
                    },
                    {
                        "name": "Xã Hưng Long",
                        "code": 12139,
                        "codename": "xa_hung_long",
                        "division_type": "xã",
                        "short_codename": "hung_long"
                    }
                ]
            },
            {
                "name": "Huyện Ân Thi",
                "code": 329,
                "codename": "huyen_an_thi",
                "division_type": "huyện",
                "short_codename": "an_thi",
                "wards": [
                    {
                        "name": "Thị trấn Ân Thi",
                        "code": 12142,
                        "codename": "thi_tran_an_thi",
                        "division_type": "thị trấn",
                        "short_codename": "an_thi"
                    },
                    {
                        "name": "Xã Phù Ủng",
                        "code": 12145,
                        "codename": "xa_phu_ung",
                        "division_type": "xã",
                        "short_codename": "phu_ung"
                    },
                    {
                        "name": "Xã Bắc Sơn",
                        "code": 12148,
                        "codename": "xa_bac_son",
                        "division_type": "xã",
                        "short_codename": "bac_son"
                    },
                    {
                        "name": "Xã Bãi Sậy",
                        "code": 12151,
                        "codename": "xa_bai_say",
                        "division_type": "xã",
                        "short_codename": "bai_say"
                    },
                    {
                        "name": "Xã Đào Dương",
                        "code": 12154,
                        "codename": "xa_dao_duong",
                        "division_type": "xã",
                        "short_codename": "dao_duong"
                    },
                    {
                        "name": "Xã Tân Phúc",
                        "code": 12157,
                        "codename": "xa_tan_phuc",
                        "division_type": "xã",
                        "short_codename": "tan_phuc"
                    },
                    {
                        "name": "Xã Vân Du",
                        "code": 12160,
                        "codename": "xa_van_du",
                        "division_type": "xã",
                        "short_codename": "van_du"
                    },
                    {
                        "name": "Xã Quang Vinh",
                        "code": 12163,
                        "codename": "xa_quang_vinh",
                        "division_type": "xã",
                        "short_codename": "quang_vinh"
                    },
                    {
                        "name": "Xã Xuân Trúc",
                        "code": 12166,
                        "codename": "xa_xuan_truc",
                        "division_type": "xã",
                        "short_codename": "xuan_truc"
                    },
                    {
                        "name": "Xã Hoàng Hoa Thám",
                        "code": 12169,
                        "codename": "xa_hoang_hoa_tham",
                        "division_type": "xã",
                        "short_codename": "hoang_hoa_tham"
                    },
                    {
                        "name": "Xã Quảng Lãng",
                        "code": 12172,
                        "codename": "xa_quang_lang",
                        "division_type": "xã",
                        "short_codename": "quang_lang"
                    },
                    {
                        "name": "Xã Văn Nhuệ",
                        "code": 12175,
                        "codename": "xa_van_nhue",
                        "division_type": "xã",
                        "short_codename": "van_nhue"
                    },
                    {
                        "name": "Xã Đặng Lễ",
                        "code": 12178,
                        "codename": "xa_dang_le",
                        "division_type": "xã",
                        "short_codename": "dang_le"
                    },
                    {
                        "name": "Xã Cẩm Ninh",
                        "code": 12181,
                        "codename": "xa_cam_ninh",
                        "division_type": "xã",
                        "short_codename": "cam_ninh"
                    },
                    {
                        "name": "Xã Nguyễn Trãi",
                        "code": 12184,
                        "codename": "xa_nguyen_trai",
                        "division_type": "xã",
                        "short_codename": "nguyen_trai"
                    },
                    {
                        "name": "Xã Đa Lộc",
                        "code": 12187,
                        "codename": "xa_da_loc",
                        "division_type": "xã",
                        "short_codename": "da_loc"
                    },
                    {
                        "name": "Xã Hồ Tùng Mậu",
                        "code": 12190,
                        "codename": "xa_ho_tung_mau",
                        "division_type": "xã",
                        "short_codename": "ho_tung_mau"
                    },
                    {
                        "name": "Xã Tiền Phong",
                        "code": 12193,
                        "codename": "xa_tien_phong",
                        "division_type": "xã",
                        "short_codename": "tien_phong"
                    },
                    {
                        "name": "Xã Hồng Vân",
                        "code": 12196,
                        "codename": "xa_hong_van",
                        "division_type": "xã",
                        "short_codename": "hong_van"
                    },
                    {
                        "name": "Xã Hồng Quang",
                        "code": 12199,
                        "codename": "xa_hong_quang",
                        "division_type": "xã",
                        "short_codename": "hong_quang"
                    },
                    {
                        "name": "Xã Hạ Lễ",
                        "code": 12202,
                        "codename": "xa_ha_le",
                        "division_type": "xã",
                        "short_codename": "ha_le"
                    }
                ]
            },
            {
                "name": "Huyện Khoái Châu",
                "code": 330,
                "codename": "huyen_khoai_chau",
                "division_type": "huyện",
                "short_codename": "khoai_chau",
                "wards": [
                    {
                        "name": "Thị trấn Khoái Châu",
                        "code": 12205,
                        "codename": "thi_tran_khoai_chau",
                        "division_type": "thị trấn",
                        "short_codename": "khoai_chau"
                    },
                    {
                        "name": "Xã Đông Tảo",
                        "code": 12208,
                        "codename": "xa_dong_tao",
                        "division_type": "xã",
                        "short_codename": "dong_tao"
                    },
                    {
                        "name": "Xã Bình Minh",
                        "code": 12211,
                        "codename": "xa_binh_minh",
                        "division_type": "xã",
                        "short_codename": "binh_minh"
                    },
                    {
                        "name": "Xã Dạ Trạch",
                        "code": 12214,
                        "codename": "xa_da_trach",
                        "division_type": "xã",
                        "short_codename": "da_trach"
                    },
                    {
                        "name": "Xã Hàm Tử",
                        "code": 12217,
                        "codename": "xa_ham_tu",
                        "division_type": "xã",
                        "short_codename": "ham_tu"
                    },
                    {
                        "name": "Xã Ông Đình",
                        "code": 12220,
                        "codename": "xa_ong_dinh",
                        "division_type": "xã",
                        "short_codename": "ong_dinh"
                    },
                    {
                        "name": "Xã Tân Dân",
                        "code": 12223,
                        "codename": "xa_tan_dan",
                        "division_type": "xã",
                        "short_codename": "tan_dan"
                    },
                    {
                        "name": "Xã Tứ Dân",
                        "code": 12226,
                        "codename": "xa_tu_dan",
                        "division_type": "xã",
                        "short_codename": "tu_dan"
                    },
                    {
                        "name": "Xã An Vĩ",
                        "code": 12229,
                        "codename": "xa_an_vi",
                        "division_type": "xã",
                        "short_codename": "an_vi"
                    },
                    {
                        "name": "Xã Đông Kết",
                        "code": 12232,
                        "codename": "xa_dong_ket",
                        "division_type": "xã",
                        "short_codename": "dong_ket"
                    },
                    {
                        "name": "Xã Bình Kiều",
                        "code": 12235,
                        "codename": "xa_binh_kieu",
                        "division_type": "xã",
                        "short_codename": "binh_kieu"
                    },
                    {
                        "name": "Xã Dân Tiến",
                        "code": 12238,
                        "codename": "xa_dan_tien",
                        "division_type": "xã",
                        "short_codename": "dan_tien"
                    },
                    {
                        "name": "Xã Đồng Tiến",
                        "code": 12241,
                        "codename": "xa_dong_tien",
                        "division_type": "xã",
                        "short_codename": "dong_tien"
                    },
                    {
                        "name": "Xã Hồng Tiến",
                        "code": 12244,
                        "codename": "xa_hong_tien",
                        "division_type": "xã",
                        "short_codename": "hong_tien"
                    },
                    {
                        "name": "Xã Tân Châu",
                        "code": 12247,
                        "codename": "xa_tan_chau",
                        "division_type": "xã",
                        "short_codename": "tan_chau"
                    },
                    {
                        "name": "Xã Liên Khê",
                        "code": 12250,
                        "codename": "xa_lien_khe",
                        "division_type": "xã",
                        "short_codename": "lien_khe"
                    },
                    {
                        "name": "Xã Phùng Hưng",
                        "code": 12253,
                        "codename": "xa_phung_hung",
                        "division_type": "xã",
                        "short_codename": "phung_hung"
                    },
                    {
                        "name": "Xã Việt Hòa",
                        "code": 12256,
                        "codename": "xa_viet_hoa",
                        "division_type": "xã",
                        "short_codename": "viet_hoa"
                    },
                    {
                        "name": "Xã Đông Ninh",
                        "code": 12259,
                        "codename": "xa_dong_ninh",
                        "division_type": "xã",
                        "short_codename": "dong_ninh"
                    },
                    {
                        "name": "Xã Đại Tập",
                        "code": 12262,
                        "codename": "xa_dai_tap",
                        "division_type": "xã",
                        "short_codename": "dai_tap"
                    },
                    {
                        "name": "Xã Chí Tân",
                        "code": 12265,
                        "codename": "xa_chi_tan",
                        "division_type": "xã",
                        "short_codename": "chi_tan"
                    },
                    {
                        "name": "Xã Đại Hưng",
                        "code": 12268,
                        "codename": "xa_dai_hung",
                        "division_type": "xã",
                        "short_codename": "dai_hung"
                    },
                    {
                        "name": "Xã Thuần Hưng",
                        "code": 12271,
                        "codename": "xa_thuan_hung",
                        "division_type": "xã",
                        "short_codename": "thuan_hung"
                    },
                    {
                        "name": "Xã Thành Công",
                        "code": 12274,
                        "codename": "xa_thanh_cong",
                        "division_type": "xã",
                        "short_codename": "thanh_cong"
                    },
                    {
                        "name": "Xã Nhuế Dương",
                        "code": 12277,
                        "codename": "xa_nhue_duong",
                        "division_type": "xã",
                        "short_codename": "nhue_duong"
                    }
                ]
            },
            {
                "name": "Huyện Kim Động",
                "code": 331,
                "codename": "huyen_kim_dong",
                "division_type": "huyện",
                "short_codename": "kim_dong",
                "wards": [
                    {
                        "name": "Thị trấn Lương Bằng",
                        "code": 12280,
                        "codename": "thi_tran_luong_bang",
                        "division_type": "thị trấn",
                        "short_codename": "luong_bang"
                    },
                    {
                        "name": "Xã Nghĩa Dân",
                        "code": 12283,
                        "codename": "xa_nghia_dan",
                        "division_type": "xã",
                        "short_codename": "nghia_dan"
                    },
                    {
                        "name": "Xã Toàn Thắng",
                        "code": 12286,
                        "codename": "xa_toan_thang",
                        "division_type": "xã",
                        "short_codename": "toan_thang"
                    },
                    {
                        "name": "Xã Vĩnh Xá",
                        "code": 12289,
                        "codename": "xa_vinh_xa",
                        "division_type": "xã",
                        "short_codename": "vinh_xa"
                    },
                    {
                        "name": "Xã Phạm Ngũ Lão",
                        "code": 12292,
                        "codename": "xa_pham_ngu_lao",
                        "division_type": "xã",
                        "short_codename": "pham_ngu_lao"
                    },
                    {
                        "name": "Xã Thọ Vinh",
                        "code": 12295,
                        "codename": "xa_tho_vinh",
                        "division_type": "xã",
                        "short_codename": "tho_vinh"
                    },
                    {
                        "name": "Xã Đồng Thanh",
                        "code": 12298,
                        "codename": "xa_dong_thanh",
                        "division_type": "xã",
                        "short_codename": "dong_thanh"
                    },
                    {
                        "name": "Xã Song Mai",
                        "code": 12301,
                        "codename": "xa_song_mai",
                        "division_type": "xã",
                        "short_codename": "song_mai"
                    },
                    {
                        "name": "Xã Chính Nghĩa",
                        "code": 12304,
                        "codename": "xa_chinh_nghia",
                        "division_type": "xã",
                        "short_codename": "chinh_nghia"
                    },
                    {
                        "name": "Xã Nhân La",
                        "code": 12307,
                        "codename": "xa_nhan_la",
                        "division_type": "xã",
                        "short_codename": "nhan_la"
                    },
                    {
                        "name": "Xã Phú Thịnh",
                        "code": 12310,
                        "codename": "xa_phu_thinh",
                        "division_type": "xã",
                        "short_codename": "phu_thinh"
                    },
                    {
                        "name": "Xã Mai Động",
                        "code": 12313,
                        "codename": "xa_mai_dong",
                        "division_type": "xã",
                        "short_codename": "mai_dong"
                    },
                    {
                        "name": "Xã Đức Hợp",
                        "code": 12316,
                        "codename": "xa_duc_hop",
                        "division_type": "xã",
                        "short_codename": "duc_hop"
                    },
                    {
                        "name": "Xã Hùng An",
                        "code": 12319,
                        "codename": "xa_hung_an",
                        "division_type": "xã",
                        "short_codename": "hung_an"
                    },
                    {
                        "name": "Xã Ngọc Thanh",
                        "code": 12322,
                        "codename": "xa_ngoc_thanh",
                        "division_type": "xã",
                        "short_codename": "ngoc_thanh"
                    },
                    {
                        "name": "Xã Vũ Xá",
                        "code": 12325,
                        "codename": "xa_vu_xa",
                        "division_type": "xã",
                        "short_codename": "vu_xa"
                    },
                    {
                        "name": "Xã Hiệp Cường",
                        "code": 12328,
                        "codename": "xa_hiep_cuong",
                        "division_type": "xã",
                        "short_codename": "hiep_cuong"
                    }
                ]
            },
            {
                "name": "Huyện Tiên Lữ",
                "code": 332,
                "codename": "huyen_tien_lu",
                "division_type": "huyện",
                "short_codename": "tien_lu",
                "wards": [
                    {
                        "name": "Thị trấn Vương",
                        "code": 12337,
                        "codename": "thi_tran_vuong",
                        "division_type": "thị trấn",
                        "short_codename": "vuong"
                    },
                    {
                        "name": "Xã Hưng Đạo",
                        "code": 12340,
                        "codename": "xa_hung_dao",
                        "division_type": "xã",
                        "short_codename": "hung_dao"
                    },
                    {
                        "name": "Xã Ngô Quyền",
                        "code": 12343,
                        "codename": "xa_ngo_quyen",
                        "division_type": "xã",
                        "short_codename": "ngo_quyen"
                    },
                    {
                        "name": "Xã Nhật Tân",
                        "code": 12346,
                        "codename": "xa_nhat_tan",
                        "division_type": "xã",
                        "short_codename": "nhat_tan"
                    },
                    {
                        "name": "Xã Dị Chế",
                        "code": 12349,
                        "codename": "xa_di_che",
                        "division_type": "xã",
                        "short_codename": "di_che"
                    },
                    {
                        "name": "Xã Lệ Xá",
                        "code": 12352,
                        "codename": "xa_le_xa",
                        "division_type": "xã",
                        "short_codename": "le_xa"
                    },
                    {
                        "name": "Xã An Viên",
                        "code": 12355,
                        "codename": "xa_an_vien",
                        "division_type": "xã",
                        "short_codename": "an_vien"
                    },
                    {
                        "name": "Xã Đức Thắng",
                        "code": 12358,
                        "codename": "xa_duc_thang",
                        "division_type": "xã",
                        "short_codename": "duc_thang"
                    },
                    {
                        "name": "Xã Trung Dũng",
                        "code": 12361,
                        "codename": "xa_trung_dung",
                        "division_type": "xã",
                        "short_codename": "trung_dung"
                    },
                    {
                        "name": "Xã Hải Triều",
                        "code": 12364,
                        "codename": "xa_hai_trieu",
                        "division_type": "xã",
                        "short_codename": "hai_trieu"
                    },
                    {
                        "name": "Xã Thủ Sỹ",
                        "code": 12367,
                        "codename": "xa_thu_sy",
                        "division_type": "xã",
                        "short_codename": "thu_sy"
                    },
                    {
                        "name": "Xã Thiện Phiến",
                        "code": 12370,
                        "codename": "xa_thien_phien",
                        "division_type": "xã",
                        "short_codename": "thien_phien"
                    },
                    {
                        "name": "Xã Thụy Lôi",
                        "code": 12373,
                        "codename": "xa_thuy_loi",
                        "division_type": "xã",
                        "short_codename": "thuy_loi"
                    },
                    {
                        "name": "Xã Cương Chính",
                        "code": 12376,
                        "codename": "xa_cuong_chinh",
                        "division_type": "xã",
                        "short_codename": "cuong_chinh"
                    },
                    {
                        "name": "Xã Minh Phượng",
                        "code": 12379,
                        "codename": "xa_minh_phuong",
                        "division_type": "xã",
                        "short_codename": "minh_phuong"
                    }
                ]
            },
            {
                "name": "Huyện Phù Cừ",
                "code": 333,
                "codename": "huyen_phu_cu",
                "division_type": "huyện",
                "short_codename": "phu_cu",
                "wards": [
                    {
                        "name": "Thị trấn Trần Cao",
                        "code": 12391,
                        "codename": "thi_tran_tran_cao",
                        "division_type": "thị trấn",
                        "short_codename": "tran_cao"
                    },
                    {
                        "name": "Xã Minh Tân",
                        "code": 12394,
                        "codename": "xa_minh_tan",
                        "division_type": "xã",
                        "short_codename": "minh_tan"
                    },
                    {
                        "name": "Xã Phan Sào Nam",
                        "code": 12397,
                        "codename": "xa_phan_sao_nam",
                        "division_type": "xã",
                        "short_codename": "phan_sao_nam"
                    },
                    {
                        "name": "Xã Quang Hưng",
                        "code": 12400,
                        "codename": "xa_quang_hung",
                        "division_type": "xã",
                        "short_codename": "quang_hung"
                    },
                    {
                        "name": "Xã Minh Hoàng",
                        "code": 12403,
                        "codename": "xa_minh_hoang",
                        "division_type": "xã",
                        "short_codename": "minh_hoang"
                    },
                    {
                        "name": "Xã Đoàn Đào",
                        "code": 12406,
                        "codename": "xa_doan_dao",
                        "division_type": "xã",
                        "short_codename": "doan_dao"
                    },
                    {
                        "name": "Xã Tống Phan",
                        "code": 12409,
                        "codename": "xa_tong_phan",
                        "division_type": "xã",
                        "short_codename": "tong_phan"
                    },
                    {
                        "name": "Xã Đình Cao",
                        "code": 12412,
                        "codename": "xa_dinh_cao",
                        "division_type": "xã",
                        "short_codename": "dinh_cao"
                    },
                    {
                        "name": "Xã Nhật Quang",
                        "code": 12415,
                        "codename": "xa_nhat_quang",
                        "division_type": "xã",
                        "short_codename": "nhat_quang"
                    },
                    {
                        "name": "Xã Tiền Tiến",
                        "code": 12418,
                        "codename": "xa_tien_tien",
                        "division_type": "xã",
                        "short_codename": "tien_tien"
                    },
                    {
                        "name": "Xã Tam Đa",
                        "code": 12421,
                        "codename": "xa_tam_da",
                        "division_type": "xã",
                        "short_codename": "tam_da"
                    },
                    {
                        "name": "Xã Minh Tiến",
                        "code": 12424,
                        "codename": "xa_minh_tien",
                        "division_type": "xã",
                        "short_codename": "minh_tien"
                    },
                    {
                        "name": "Xã Nguyên Hòa",
                        "code": 12427,
                        "codename": "xa_nguyen_hoa",
                        "division_type": "xã",
                        "short_codename": "nguyen_hoa"
                    },
                    {
                        "name": "Xã Tống Trân",
                        "code": 12430,
                        "codename": "xa_tong_tran",
                        "division_type": "xã",
                        "short_codename": "tong_tran"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Thái Bình",
        "code": 34,
        "codename": "tinh_thai_binh",
        "division_type": "tỉnh",
        "phone_code": 227,
        "districts": [
            {
                "name": "Thành phố Thái Bình",
                "code": 336,
                "codename": "thanh_pho_thai_binh",
                "division_type": "thành phố",
                "short_codename": "thai_binh",
                "wards": [
                    {
                        "name": "Phường Lê Hồng Phong",
                        "code": 12433,
                        "codename": "phuong_le_hong_phong",
                        "division_type": "phường",
                        "short_codename": "le_hong_phong"
                    },
                    {
                        "name": "Phường Bồ Xuyên",
                        "code": 12436,
                        "codename": "phuong_bo_xuyen",
                        "division_type": "phường",
                        "short_codename": "bo_xuyen"
                    },
                    {
                        "name": "Phường Đề Thám",
                        "code": 12439,
                        "codename": "phuong_de_tham",
                        "division_type": "phường",
                        "short_codename": "de_tham"
                    },
                    {
                        "name": "Phường Kỳ Bá",
                        "code": 12442,
                        "codename": "phuong_ky_ba",
                        "division_type": "phường",
                        "short_codename": "ky_ba"
                    },
                    {
                        "name": "Phường Quang Trung",
                        "code": 12445,
                        "codename": "phuong_quang_trung",
                        "division_type": "phường",
                        "short_codename": "quang_trung"
                    },
                    {
                        "name": "Phường Phú Khánh",
                        "code": 12448,
                        "codename": "phuong_phu_khanh",
                        "division_type": "phường",
                        "short_codename": "phu_khanh"
                    },
                    {
                        "name": "Phường Tiền Phong",
                        "code": 12451,
                        "codename": "phuong_tien_phong",
                        "division_type": "phường",
                        "short_codename": "tien_phong"
                    },
                    {
                        "name": "Phường Trần Hưng Đạo",
                        "code": 12452,
                        "codename": "phuong_tran_hung_dao",
                        "division_type": "phường",
                        "short_codename": "tran_hung_dao"
                    },
                    {
                        "name": "Phường Trần Lãm",
                        "code": 12454,
                        "codename": "phuong_tran_lam",
                        "division_type": "phường",
                        "short_codename": "tran_lam"
                    },
                    {
                        "name": "Xã Đông Hòa",
                        "code": 12457,
                        "codename": "xa_dong_hoa",
                        "division_type": "xã",
                        "short_codename": "dong_hoa"
                    },
                    {
                        "name": "Phường Hoàng Diệu",
                        "code": 12460,
                        "codename": "phuong_hoang_dieu",
                        "division_type": "phường",
                        "short_codename": "hoang_dieu"
                    },
                    {
                        "name": "Xã Phú Xuân",
                        "code": 12463,
                        "codename": "xa_phu_xuan",
                        "division_type": "xã",
                        "short_codename": "phu_xuan"
                    },
                    {
                        "name": "Xã Vũ Phúc",
                        "code": 12466,
                        "codename": "xa_vu_phuc",
                        "division_type": "xã",
                        "short_codename": "vu_phuc"
                    },
                    {
                        "name": "Xã Vũ Chính",
                        "code": 12469,
                        "codename": "xa_vu_chinh",
                        "division_type": "xã",
                        "short_codename": "vu_chinh"
                    },
                    {
                        "name": "Xã Đông Mỹ",
                        "code": 12817,
                        "codename": "xa_dong_my",
                        "division_type": "xã",
                        "short_codename": "dong_my"
                    },
                    {
                        "name": "Xã Đông Thọ",
                        "code": 12820,
                        "codename": "xa_dong_tho",
                        "division_type": "xã",
                        "short_codename": "dong_tho"
                    },
                    {
                        "name": "Xã Vũ Đông",
                        "code": 13084,
                        "codename": "xa_vu_dong",
                        "division_type": "xã",
                        "short_codename": "vu_dong"
                    },
                    {
                        "name": "Xã Vũ Lạc",
                        "code": 13108,
                        "codename": "xa_vu_lac",
                        "division_type": "xã",
                        "short_codename": "vu_lac"
                    },
                    {
                        "name": "Xã Tân Bình",
                        "code": 13225,
                        "codename": "xa_tan_binh",
                        "division_type": "xã",
                        "short_codename": "tan_binh"
                    }
                ]
            },
            {
                "name": "Huyện Quỳnh Phụ",
                "code": 338,
                "codename": "huyen_quynh_phu",
                "division_type": "huyện",
                "short_codename": "quynh_phu",
                "wards": [
                    {
                        "name": "Thị trấn Quỳnh Côi",
                        "code": 12472,
                        "codename": "thi_tran_quynh_coi",
                        "division_type": "thị trấn",
                        "short_codename": "quynh_coi"
                    },
                    {
                        "name": "Xã An Khê",
                        "code": 12475,
                        "codename": "xa_an_khe",
                        "division_type": "xã",
                        "short_codename": "an_khe"
                    },
                    {
                        "name": "Xã An Đồng",
                        "code": 12478,
                        "codename": "xa_an_dong",
                        "division_type": "xã",
                        "short_codename": "an_dong"
                    },
                    {
                        "name": "Xã Quỳnh Hoa",
                        "code": 12481,
                        "codename": "xa_quynh_hoa",
                        "division_type": "xã",
                        "short_codename": "quynh_hoa"
                    },
                    {
                        "name": "Xã Quỳnh Lâm",
                        "code": 12484,
                        "codename": "xa_quynh_lam",
                        "division_type": "xã",
                        "short_codename": "quynh_lam"
                    },
                    {
                        "name": "Xã Quỳnh Thọ",
                        "code": 12487,
                        "codename": "xa_quynh_tho",
                        "division_type": "xã",
                        "short_codename": "quynh_tho"
                    },
                    {
                        "name": "Xã An Hiệp",
                        "code": 12490,
                        "codename": "xa_an_hiep",
                        "division_type": "xã",
                        "short_codename": "an_hiep"
                    },
                    {
                        "name": "Xã Quỳnh Hoàng",
                        "code": 12493,
                        "codename": "xa_quynh_hoang",
                        "division_type": "xã",
                        "short_codename": "quynh_hoang"
                    },
                    {
                        "name": "Xã Quỳnh Giao",
                        "code": 12496,
                        "codename": "xa_quynh_giao",
                        "division_type": "xã",
                        "short_codename": "quynh_giao"
                    },
                    {
                        "name": "Xã An Thái",
                        "code": 12499,
                        "codename": "xa_an_thai",
                        "division_type": "xã",
                        "short_codename": "an_thai"
                    },
                    {
                        "name": "Xã An Cầu",
                        "code": 12502,
                        "codename": "xa_an_cau",
                        "division_type": "xã",
                        "short_codename": "an_cau"
                    },
                    {
                        "name": "Xã Quỳnh Hồng",
                        "code": 12505,
                        "codename": "xa_quynh_hong",
                        "division_type": "xã",
                        "short_codename": "quynh_hong"
                    },
                    {
                        "name": "Xã Quỳnh Khê",
                        "code": 12508,
                        "codename": "xa_quynh_khe",
                        "division_type": "xã",
                        "short_codename": "quynh_khe"
                    },
                    {
                        "name": "Xã Quỳnh Minh",
                        "code": 12511,
                        "codename": "xa_quynh_minh",
                        "division_type": "xã",
                        "short_codename": "quynh_minh"
                    },
                    {
                        "name": "Xã An Ninh",
                        "code": 12514,
                        "codename": "xa_an_ninh",
                        "division_type": "xã",
                        "short_codename": "an_ninh"
                    },
                    {
                        "name": "Xã Quỳnh Ngọc",
                        "code": 12517,
                        "codename": "xa_quynh_ngoc",
                        "division_type": "xã",
                        "short_codename": "quynh_ngoc"
                    },
                    {
                        "name": "Xã Quỳnh Hải",
                        "code": 12520,
                        "codename": "xa_quynh_hai",
                        "division_type": "xã",
                        "short_codename": "quynh_hai"
                    },
                    {
                        "name": "Thị trấn An Bài",
                        "code": 12523,
                        "codename": "thi_tran_an_bai",
                        "division_type": "thị trấn",
                        "short_codename": "an_bai"
                    },
                    {
                        "name": "Xã An Ấp",
                        "code": 12526,
                        "codename": "xa_an_ap",
                        "division_type": "xã",
                        "short_codename": "an_ap"
                    },
                    {
                        "name": "Xã Quỳnh Hội",
                        "code": 12529,
                        "codename": "xa_quynh_hoi",
                        "division_type": "xã",
                        "short_codename": "quynh_hoi"
                    },
                    {
                        "name": "Xã Châu Sơn",
                        "code": 12532,
                        "codename": "xa_chau_son",
                        "division_type": "xã",
                        "short_codename": "chau_son"
                    },
                    {
                        "name": "Xã Quỳnh Mỹ",
                        "code": 12535,
                        "codename": "xa_quynh_my",
                        "division_type": "xã",
                        "short_codename": "quynh_my"
                    },
                    {
                        "name": "Xã An Quí",
                        "code": 12538,
                        "codename": "xa_an_qui",
                        "division_type": "xã",
                        "short_codename": "an_qui"
                    },
                    {
                        "name": "Xã An Thanh",
                        "code": 12541,
                        "codename": "xa_an_thanh",
                        "division_type": "xã",
                        "short_codename": "an_thanh"
                    },
                    {
                        "name": "Xã An Vũ",
                        "code": 12547,
                        "codename": "xa_an_vu",
                        "division_type": "xã",
                        "short_codename": "an_vu"
                    },
                    {
                        "name": "Xã An Lễ",
                        "code": 12550,
                        "codename": "xa_an_le",
                        "division_type": "xã",
                        "short_codename": "an_le"
                    },
                    {
                        "name": "Xã Quỳnh Hưng",
                        "code": 12553,
                        "codename": "xa_quynh_hung",
                        "division_type": "xã",
                        "short_codename": "quynh_hung"
                    },
                    {
                        "name": "Xã Quỳnh Bảo",
                        "code": 12556,
                        "codename": "xa_quynh_bao",
                        "division_type": "xã",
                        "short_codename": "quynh_bao"
                    },
                    {
                        "name": "Xã An Mỹ",
                        "code": 12559,
                        "codename": "xa_an_my",
                        "division_type": "xã",
                        "short_codename": "an_my"
                    },
                    {
                        "name": "Xã Quỳnh Nguyên",
                        "code": 12562,
                        "codename": "xa_quynh_nguyen",
                        "division_type": "xã",
                        "short_codename": "quynh_nguyen"
                    },
                    {
                        "name": "Xã An Vinh",
                        "code": 12565,
                        "codename": "xa_an_vinh",
                        "division_type": "xã",
                        "short_codename": "an_vinh"
                    },
                    {
                        "name": "Xã Quỳnh Xá",
                        "code": 12568,
                        "codename": "xa_quynh_xa",
                        "division_type": "xã",
                        "short_codename": "quynh_xa"
                    },
                    {
                        "name": "Xã An Dục",
                        "code": 12571,
                        "codename": "xa_an_duc",
                        "division_type": "xã",
                        "short_codename": "an_duc"
                    },
                    {
                        "name": "Xã Đông Hải",
                        "code": 12574,
                        "codename": "xa_dong_hai",
                        "division_type": "xã",
                        "short_codename": "dong_hai"
                    },
                    {
                        "name": "Xã Quỳnh Trang",
                        "code": 12577,
                        "codename": "xa_quynh_trang",
                        "division_type": "xã",
                        "short_codename": "quynh_trang"
                    },
                    {
                        "name": "Xã An Tràng",
                        "code": 12580,
                        "codename": "xa_an_trang",
                        "division_type": "xã",
                        "short_codename": "an_trang"
                    },
                    {
                        "name": "Xã Đồng Tiến",
                        "code": 12583,
                        "codename": "xa_dong_tien",
                        "division_type": "xã",
                        "short_codename": "dong_tien"
                    }
                ]
            },
            {
                "name": "Huyện Hưng Hà",
                "code": 339,
                "codename": "huyen_hung_ha",
                "division_type": "huyện",
                "short_codename": "hung_ha",
                "wards": [
                    {
                        "name": "Thị trấn Hưng Hà",
                        "code": 12586,
                        "codename": "thi_tran_hung_ha",
                        "division_type": "thị trấn",
                        "short_codename": "hung_ha"
                    },
                    {
                        "name": "Xã Điệp Nông",
                        "code": 12589,
                        "codename": "xa_diep_nong",
                        "division_type": "xã",
                        "short_codename": "diep_nong"
                    },
                    {
                        "name": "Xã Tân Lễ",
                        "code": 12592,
                        "codename": "xa_tan_le",
                        "division_type": "xã",
                        "short_codename": "tan_le"
                    },
                    {
                        "name": "Xã Cộng Hòa",
                        "code": 12595,
                        "codename": "xa_cong_hoa",
                        "division_type": "xã",
                        "short_codename": "cong_hoa"
                    },
                    {
                        "name": "Xã Dân Chủ",
                        "code": 12598,
                        "codename": "xa_dan_chu",
                        "division_type": "xã",
                        "short_codename": "dan_chu"
                    },
                    {
                        "name": "Xã Canh Tân",
                        "code": 12601,
                        "codename": "xa_canh_tan",
                        "division_type": "xã",
                        "short_codename": "canh_tan"
                    },
                    {
                        "name": "Xã Hòa Tiến",
                        "code": 12604,
                        "codename": "xa_hoa_tien",
                        "division_type": "xã",
                        "short_codename": "hoa_tien"
                    },
                    {
                        "name": "Xã Hùng Dũng",
                        "code": 12607,
                        "codename": "xa_hung_dung",
                        "division_type": "xã",
                        "short_codename": "hung_dung"
                    },
                    {
                        "name": "Xã Tân Tiến",
                        "code": 12610,
                        "codename": "xa_tan_tien",
                        "division_type": "xã",
                        "short_codename": "tan_tien"
                    },
                    {
                        "name": "Thị trấn Hưng Nhân",
                        "code": 12613,
                        "codename": "thi_tran_hung_nhan",
                        "division_type": "thị trấn",
                        "short_codename": "hung_nhan"
                    },
                    {
                        "name": "Xã Đoan Hùng",
                        "code": 12616,
                        "codename": "xa_doan_hung",
                        "division_type": "xã",
                        "short_codename": "doan_hung"
                    },
                    {
                        "name": "Xã Duyên Hải",
                        "code": 12619,
                        "codename": "xa_duyen_hai",
                        "division_type": "xã",
                        "short_codename": "duyen_hai"
                    },
                    {
                        "name": "Xã Tân Hòa",
                        "code": 12622,
                        "codename": "xa_tan_hoa",
                        "division_type": "xã",
                        "short_codename": "tan_hoa"
                    },
                    {
                        "name": "Xã Văn Cẩm",
                        "code": 12625,
                        "codename": "xa_van_cam",
                        "division_type": "xã",
                        "short_codename": "van_cam"
                    },
                    {
                        "name": "Xã Bắc Sơn",
                        "code": 12628,
                        "codename": "xa_bac_son",
                        "division_type": "xã",
                        "short_codename": "bac_son"
                    },
                    {
                        "name": "Xã Đông Đô",
                        "code": 12631,
                        "codename": "xa_dong_do",
                        "division_type": "xã",
                        "short_codename": "dong_do"
                    },
                    {
                        "name": "Xã Phúc Khánh",
                        "code": 12634,
                        "codename": "xa_phuc_khanh",
                        "division_type": "xã",
                        "short_codename": "phuc_khanh"
                    },
                    {
                        "name": "Xã Liên Hiệp",
                        "code": 12637,
                        "codename": "xa_lien_hiep",
                        "division_type": "xã",
                        "short_codename": "lien_hiep"
                    },
                    {
                        "name": "Xã Tây Đô",
                        "code": 12640,
                        "codename": "xa_tay_do",
                        "division_type": "xã",
                        "short_codename": "tay_do"
                    },
                    {
                        "name": "Xã Thống Nhất",
                        "code": 12643,
                        "codename": "xa_thong_nhat",
                        "division_type": "xã",
                        "short_codename": "thong_nhat"
                    },
                    {
                        "name": "Xã Tiến Đức",
                        "code": 12646,
                        "codename": "xa_tien_duc",
                        "division_type": "xã",
                        "short_codename": "tien_duc"
                    },
                    {
                        "name": "Xã Thái Hưng",
                        "code": 12649,
                        "codename": "xa_thai_hung",
                        "division_type": "xã",
                        "short_codename": "thai_hung"
                    },
                    {
                        "name": "Xã Thái Phương",
                        "code": 12652,
                        "codename": "xa_thai_phuong",
                        "division_type": "xã",
                        "short_codename": "thai_phuong"
                    },
                    {
                        "name": "Xã Hòa Bình",
                        "code": 12655,
                        "codename": "xa_hoa_binh",
                        "division_type": "xã",
                        "short_codename": "hoa_binh"
                    },
                    {
                        "name": "Xã Chi Lăng",
                        "code": 12656,
                        "codename": "xa_chi_lang",
                        "division_type": "xã",
                        "short_codename": "chi_lang"
                    },
                    {
                        "name": "Xã Minh Khai",
                        "code": 12658,
                        "codename": "xa_minh_khai",
                        "division_type": "xã",
                        "short_codename": "minh_khai"
                    },
                    {
                        "name": "Xã Hồng An",
                        "code": 12661,
                        "codename": "xa_hong_an",
                        "division_type": "xã",
                        "short_codename": "hong_an"
                    },
                    {
                        "name": "Xã Kim Chung",
                        "code": 12664,
                        "codename": "xa_kim_chung",
                        "division_type": "xã",
                        "short_codename": "kim_chung"
                    },
                    {
                        "name": "Xã Hồng Lĩnh",
                        "code": 12667,
                        "codename": "xa_hong_linh",
                        "division_type": "xã",
                        "short_codename": "hong_linh"
                    },
                    {
                        "name": "Xã Minh Tân",
                        "code": 12670,
                        "codename": "xa_minh_tan",
                        "division_type": "xã",
                        "short_codename": "minh_tan"
                    },
                    {
                        "name": "Xã Văn Lang",
                        "code": 12673,
                        "codename": "xa_van_lang",
                        "division_type": "xã",
                        "short_codename": "van_lang"
                    },
                    {
                        "name": "Xã Độc Lập",
                        "code": 12676,
                        "codename": "xa_doc_lap",
                        "division_type": "xã",
                        "short_codename": "doc_lap"
                    },
                    {
                        "name": "Xã Chí Hòa",
                        "code": 12679,
                        "codename": "xa_chi_hoa",
                        "division_type": "xã",
                        "short_codename": "chi_hoa"
                    },
                    {
                        "name": "Xã Minh Hòa",
                        "code": 12682,
                        "codename": "xa_minh_hoa",
                        "division_type": "xã",
                        "short_codename": "minh_hoa"
                    },
                    {
                        "name": "Xã Hồng Minh",
                        "code": 12685,
                        "codename": "xa_hong_minh",
                        "division_type": "xã",
                        "short_codename": "hong_minh"
                    }
                ]
            },
            {
                "name": "Huyện Đông Hưng",
                "code": 340,
                "codename": "huyen_dong_hung",
                "division_type": "huyện",
                "short_codename": "dong_hung",
                "wards": [
                    {
                        "name": "Thị trấn Đông Hưng",
                        "code": 12688,
                        "codename": "thi_tran_dong_hung",
                        "division_type": "thị trấn",
                        "short_codename": "dong_hung"
                    },
                    {
                        "name": "Xã Đô Lương",
                        "code": 12691,
                        "codename": "xa_do_luong",
                        "division_type": "xã",
                        "short_codename": "do_luong"
                    },
                    {
                        "name": "Xã Đông Phương",
                        "code": 12694,
                        "codename": "xa_dong_phuong",
                        "division_type": "xã",
                        "short_codename": "dong_phuong"
                    },
                    {
                        "name": "Xã Liên Giang",
                        "code": 12697,
                        "codename": "xa_lien_giang",
                        "division_type": "xã",
                        "short_codename": "lien_giang"
                    },
                    {
                        "name": "Xã An Châu",
                        "code": 12700,
                        "codename": "xa_an_chau",
                        "division_type": "xã",
                        "short_codename": "an_chau"
                    },
                    {
                        "name": "Xã Đông Sơn",
                        "code": 12703,
                        "codename": "xa_dong_son",
                        "division_type": "xã",
                        "short_codename": "dong_son"
                    },
                    {
                        "name": "Xã Đông Cường",
                        "code": 12706,
                        "codename": "xa_dong_cuong",
                        "division_type": "xã",
                        "short_codename": "dong_cuong"
                    },
                    {
                        "name": "Xã Phú Lương",
                        "code": 12709,
                        "codename": "xa_phu_luong",
                        "division_type": "xã",
                        "short_codename": "phu_luong"
                    },
                    {
                        "name": "Xã Mê Linh",
                        "code": 12712,
                        "codename": "xa_me_linh",
                        "division_type": "xã",
                        "short_codename": "me_linh"
                    },
                    {
                        "name": "Xã Lô Giang",
                        "code": 12715,
                        "codename": "xa_lo_giang",
                        "division_type": "xã",
                        "short_codename": "lo_giang"
                    },
                    {
                        "name": "Xã Đông La",
                        "code": 12718,
                        "codename": "xa_dong_la",
                        "division_type": "xã",
                        "short_codename": "dong_la"
                    },
                    {
                        "name": "Xã Minh Tân",
                        "code": 12721,
                        "codename": "xa_minh_tan",
                        "division_type": "xã",
                        "short_codename": "minh_tan"
                    },
                    {
                        "name": "Xã Đông Xá",
                        "code": 12724,
                        "codename": "xa_dong_xa",
                        "division_type": "xã",
                        "short_codename": "dong_xa"
                    },
                    {
                        "name": "Xã Chương Dương",
                        "code": 12727,
                        "codename": "xa_chuong_duong",
                        "division_type": "xã",
                        "short_codename": "chuong_duong"
                    },
                    {
                        "name": "Xã Nguyên Xá",
                        "code": 12730,
                        "codename": "xa_nguyen_xa",
                        "division_type": "xã",
                        "short_codename": "nguyen_xa"
                    },
                    {
                        "name": "Xã Phong Châu",
                        "code": 12733,
                        "codename": "xa_phong_chau",
                        "division_type": "xã",
                        "short_codename": "phong_chau"
                    },
                    {
                        "name": "Xã Hợp Tiến",
                        "code": 12736,
                        "codename": "xa_hop_tien",
                        "division_type": "xã",
                        "short_codename": "hop_tien"
                    },
                    {
                        "name": "Xã Hồng Việt",
                        "code": 12739,
                        "codename": "xa_hong_viet",
                        "division_type": "xã",
                        "short_codename": "hong_viet"
                    },
                    {
                        "name": "Xã Hà Giang",
                        "code": 12745,
                        "codename": "xa_ha_giang",
                        "division_type": "xã",
                        "short_codename": "ha_giang"
                    },
                    {
                        "name": "Xã Đông Kinh",
                        "code": 12748,
                        "codename": "xa_dong_kinh",
                        "division_type": "xã",
                        "short_codename": "dong_kinh"
                    },
                    {
                        "name": "Xã Đông Hợp",
                        "code": 12751,
                        "codename": "xa_dong_hop",
                        "division_type": "xã",
                        "short_codename": "dong_hop"
                    },
                    {
                        "name": "Xã Thăng Long",
                        "code": 12754,
                        "codename": "xa_thang_long",
                        "division_type": "xã",
                        "short_codename": "thang_long"
                    },
                    {
                        "name": "Xã Đông Các",
                        "code": 12757,
                        "codename": "xa_dong_cac",
                        "division_type": "xã",
                        "short_codename": "dong_cac"
                    },
                    {
                        "name": "Xã Phú Châu",
                        "code": 12760,
                        "codename": "xa_phu_chau",
                        "division_type": "xã",
                        "short_codename": "phu_chau"
                    },
                    {
                        "name": "Xã Liên Hoa",
                        "code": 12763,
                        "codename": "xa_lien_hoa",
                        "division_type": "xã",
                        "short_codename": "lien_hoa"
                    },
                    {
                        "name": "Xã Đông Tân",
                        "code": 12769,
                        "codename": "xa_dong_tan",
                        "division_type": "xã",
                        "short_codename": "dong_tan"
                    },
                    {
                        "name": "Xã Đông Vinh",
                        "code": 12772,
                        "codename": "xa_dong_vinh",
                        "division_type": "xã",
                        "short_codename": "dong_vinh"
                    },
                    {
                        "name": "Xã Đông Động",
                        "code": 12775,
                        "codename": "xa_dong_dong",
                        "division_type": "xã",
                        "short_codename": "dong_dong"
                    },
                    {
                        "name": "Xã Hồng Bạch",
                        "code": 12778,
                        "codename": "xa_hong_bach",
                        "division_type": "xã",
                        "short_codename": "hong_bach"
                    },
                    {
                        "name": "Xã Trọng Quan",
                        "code": 12784,
                        "codename": "xa_trong_quan",
                        "division_type": "xã",
                        "short_codename": "trong_quan"
                    },
                    {
                        "name": "Xã Hồng Giang",
                        "code": 12790,
                        "codename": "xa_hong_giang",
                        "division_type": "xã",
                        "short_codename": "hong_giang"
                    },
                    {
                        "name": "Xã Đông Quan",
                        "code": 12793,
                        "codename": "xa_dong_quan",
                        "division_type": "xã",
                        "short_codename": "dong_quan"
                    },
                    {
                        "name": "Xã Đông Quang",
                        "code": 12796,
                        "codename": "xa_dong_quang",
                        "division_type": "xã",
                        "short_codename": "dong_quang"
                    },
                    {
                        "name": "Xã Đông Xuân",
                        "code": 12799,
                        "codename": "xa_dong_xuan",
                        "division_type": "xã",
                        "short_codename": "dong_xuan"
                    },
                    {
                        "name": "Xã Đông Á",
                        "code": 12802,
                        "codename": "xa_dong_a",
                        "division_type": "xã",
                        "short_codename": "dong_a"
                    },
                    {
                        "name": "Xã Đông Hoàng",
                        "code": 12808,
                        "codename": "xa_dong_hoang",
                        "division_type": "xã",
                        "short_codename": "dong_hoang"
                    },
                    {
                        "name": "Xã Đông Dương",
                        "code": 12811,
                        "codename": "xa_dong_duong",
                        "division_type": "xã",
                        "short_codename": "dong_duong"
                    },
                    {
                        "name": "Xã Minh Phú",
                        "code": 12823,
                        "codename": "xa_minh_phu",
                        "division_type": "xã",
                        "short_codename": "minh_phu"
                    }
                ]
            },
            {
                "name": "Huyện Thái Thụy",
                "code": 341,
                "codename": "huyen_thai_thuy",
                "division_type": "huyện",
                "short_codename": "thai_thuy",
                "wards": [
                    {
                        "name": "Thị trấn Diêm Điền",
                        "code": 12826,
                        "codename": "thi_tran_diem_dien",
                        "division_type": "thị trấn",
                        "short_codename": "diem_dien"
                    },
                    {
                        "name": "Xã Thụy Trường",
                        "code": 12832,
                        "codename": "xa_thuy_truong",
                        "division_type": "xã",
                        "short_codename": "thuy_truong"
                    },
                    {
                        "name": "Xã Hồng Dũng",
                        "code": 12841,
                        "codename": "xa_hong_dung",
                        "division_type": "xã",
                        "short_codename": "hong_dung"
                    },
                    {
                        "name": "Xã Thụy Quỳnh",
                        "code": 12844,
                        "codename": "xa_thuy_quynh",
                        "division_type": "xã",
                        "short_codename": "thuy_quynh"
                    },
                    {
                        "name": "Xã An Tân",
                        "code": 12847,
                        "codename": "xa_an_tan",
                        "division_type": "xã",
                        "short_codename": "an_tan"
                    },
                    {
                        "name": "Xã Thụy Ninh",
                        "code": 12850,
                        "codename": "xa_thuy_ninh",
                        "division_type": "xã",
                        "short_codename": "thuy_ninh"
                    },
                    {
                        "name": "Xã Thụy Hưng",
                        "code": 12853,
                        "codename": "xa_thuy_hung",
                        "division_type": "xã",
                        "short_codename": "thuy_hung"
                    },
                    {
                        "name": "Xã Thụy Việt",
                        "code": 12856,
                        "codename": "xa_thuy_viet",
                        "division_type": "xã",
                        "short_codename": "thuy_viet"
                    },
                    {
                        "name": "Xã Thụy Văn",
                        "code": 12859,
                        "codename": "xa_thuy_van",
                        "division_type": "xã",
                        "short_codename": "thuy_van"
                    },
                    {
                        "name": "Xã Thụy Xuân",
                        "code": 12862,
                        "codename": "xa_thuy_xuan",
                        "division_type": "xã",
                        "short_codename": "thuy_xuan"
                    },
                    {
                        "name": "Xã Dương Phúc",
                        "code": 12865,
                        "codename": "xa_duong_phuc",
                        "division_type": "xã",
                        "short_codename": "duong_phuc"
                    },
                    {
                        "name": "Xã Thụy Trình",
                        "code": 12868,
                        "codename": "xa_thuy_trinh",
                        "division_type": "xã",
                        "short_codename": "thuy_trinh"
                    },
                    {
                        "name": "Xã Thụy Bình",
                        "code": 12871,
                        "codename": "xa_thuy_binh",
                        "division_type": "xã",
                        "short_codename": "thuy_binh"
                    },
                    {
                        "name": "Xã Thụy Chính",
                        "code": 12874,
                        "codename": "xa_thuy_chinh",
                        "division_type": "xã",
                        "short_codename": "thuy_chinh"
                    },
                    {
                        "name": "Xã Thụy Dân",
                        "code": 12877,
                        "codename": "xa_thuy_dan",
                        "division_type": "xã",
                        "short_codename": "thuy_dan"
                    },
                    {
                        "name": "Xã Thụy Hải",
                        "code": 12880,
                        "codename": "xa_thuy_hai",
                        "division_type": "xã",
                        "short_codename": "thuy_hai"
                    },
                    {
                        "name": "Xã Thụy Liên",
                        "code": 12889,
                        "codename": "xa_thuy_lien",
                        "division_type": "xã",
                        "short_codename": "thuy_lien"
                    },
                    {
                        "name": "Xã Thụy Duyên",
                        "code": 12892,
                        "codename": "xa_thuy_duyen",
                        "division_type": "xã",
                        "short_codename": "thuy_duyen"
                    },
                    {
                        "name": "Xã Thụy Thanh",
                        "code": 12898,
                        "codename": "xa_thuy_thanh",
                        "division_type": "xã",
                        "short_codename": "thuy_thanh"
                    },
                    {
                        "name": "Xã Thụy Sơn",
                        "code": 12901,
                        "codename": "xa_thuy_son",
                        "division_type": "xã",
                        "short_codename": "thuy_son"
                    },
                    {
                        "name": "Xã Thụy Phong",
                        "code": 12904,
                        "codename": "xa_thuy_phong",
                        "division_type": "xã",
                        "short_codename": "thuy_phong"
                    },
                    {
                        "name": "Xã Thái Thượng",
                        "code": 12907,
                        "codename": "xa_thai_thuong",
                        "division_type": "xã",
                        "short_codename": "thai_thuong"
                    },
                    {
                        "name": "Xã Thái Nguyên",
                        "code": 12910,
                        "codename": "xa_thai_nguyen",
                        "division_type": "xã",
                        "short_codename": "thai_nguyen"
                    },
                    {
                        "name": "Xã Dương Hồng Thủy",
                        "code": 12916,
                        "codename": "xa_duong_hong_thuy",
                        "division_type": "xã",
                        "short_codename": "duong_hong_thuy"
                    },
                    {
                        "name": "Xã Thái Giang",
                        "code": 12919,
                        "codename": "xa_thai_giang",
                        "division_type": "xã",
                        "short_codename": "thai_giang"
                    },
                    {
                        "name": "Xã Hòa An",
                        "code": 12922,
                        "codename": "xa_hoa_an",
                        "division_type": "xã",
                        "short_codename": "hoa_an"
                    },
                    {
                        "name": "Xã Sơn Hà",
                        "code": 12925,
                        "codename": "xa_son_ha",
                        "division_type": "xã",
                        "short_codename": "son_ha"
                    },
                    {
                        "name": "Xã Thái Phúc",
                        "code": 12934,
                        "codename": "xa_thai_phuc",
                        "division_type": "xã",
                        "short_codename": "thai_phuc"
                    },
                    {
                        "name": "Xã Thái Hưng",
                        "code": 12937,
                        "codename": "xa_thai_hung",
                        "division_type": "xã",
                        "short_codename": "thai_hung"
                    },
                    {
                        "name": "Xã Thái Đô",
                        "code": 12940,
                        "codename": "xa_thai_do",
                        "division_type": "xã",
                        "short_codename": "thai_do"
                    },
                    {
                        "name": "Xã Thái Xuyên",
                        "code": 12943,
                        "codename": "xa_thai_xuyen",
                        "division_type": "xã",
                        "short_codename": "thai_xuyen"
                    },
                    {
                        "name": "Xã Mỹ Lộc",
                        "code": 12949,
                        "codename": "xa_my_loc",
                        "division_type": "xã",
                        "short_codename": "my_loc"
                    },
                    {
                        "name": "Xã Tân Học",
                        "code": 12958,
                        "codename": "xa_tan_hoc",
                        "division_type": "xã",
                        "short_codename": "tan_hoc"
                    },
                    {
                        "name": "Xã Thái Thịnh",
                        "code": 12961,
                        "codename": "xa_thai_thinh",
                        "division_type": "xã",
                        "short_codename": "thai_thinh"
                    },
                    {
                        "name": "Xã Thuần Thành",
                        "code": 12964,
                        "codename": "xa_thuan_thanh",
                        "division_type": "xã",
                        "short_codename": "thuan_thanh"
                    },
                    {
                        "name": "Xã Thái Thọ",
                        "code": 12967,
                        "codename": "xa_thai_tho",
                        "division_type": "xã",
                        "short_codename": "thai_tho"
                    }
                ]
            },
            {
                "name": "Huyện Tiền Hải",
                "code": 342,
                "codename": "huyen_tien_hai",
                "division_type": "huyện",
                "short_codename": "tien_hai",
                "wards": [
                    {
                        "name": "Thị trấn Tiền Hải",
                        "code": 12970,
                        "codename": "thi_tran_tien_hai",
                        "division_type": "thị trấn",
                        "short_codename": "tien_hai"
                    },
                    {
                        "name": "Xã Đông Trà",
                        "code": 12976,
                        "codename": "xa_dong_tra",
                        "division_type": "xã",
                        "short_codename": "dong_tra"
                    },
                    {
                        "name": "Xã Đông Long",
                        "code": 12979,
                        "codename": "xa_dong_long",
                        "division_type": "xã",
                        "short_codename": "dong_long"
                    },
                    {
                        "name": "Xã Đông Quí",
                        "code": 12982,
                        "codename": "xa_dong_qui",
                        "division_type": "xã",
                        "short_codename": "dong_qui"
                    },
                    {
                        "name": "Xã Vũ Lăng",
                        "code": 12985,
                        "codename": "xa_vu_lang",
                        "division_type": "xã",
                        "short_codename": "vu_lang"
                    },
                    {
                        "name": "Xã Đông Xuyên",
                        "code": 12988,
                        "codename": "xa_dong_xuyen",
                        "division_type": "xã",
                        "short_codename": "dong_xuyen"
                    },
                    {
                        "name": "Xã Tây Lương",
                        "code": 12991,
                        "codename": "xa_tay_luong",
                        "division_type": "xã",
                        "short_codename": "tay_luong"
                    },
                    {
                        "name": "Xã Tây Ninh",
                        "code": 12994,
                        "codename": "xa_tay_ninh",
                        "division_type": "xã",
                        "short_codename": "tay_ninh"
                    },
                    {
                        "name": "Xã Đông Trung",
                        "code": 12997,
                        "codename": "xa_dong_trung",
                        "division_type": "xã",
                        "short_codename": "dong_trung"
                    },
                    {
                        "name": "Xã Đông Hoàng",
                        "code": 13000,
                        "codename": "xa_dong_hoang",
                        "division_type": "xã",
                        "short_codename": "dong_hoang"
                    },
                    {
                        "name": "Xã Đông Minh",
                        "code": 13003,
                        "codename": "xa_dong_minh",
                        "division_type": "xã",
                        "short_codename": "dong_minh"
                    },
                    {
                        "name": "Xã Đông Phong",
                        "code": 13009,
                        "codename": "xa_dong_phong",
                        "division_type": "xã",
                        "short_codename": "dong_phong"
                    },
                    {
                        "name": "Xã An Ninh",
                        "code": 13012,
                        "codename": "xa_an_ninh",
                        "division_type": "xã",
                        "short_codename": "an_ninh"
                    },
                    {
                        "name": "Xã Đông Cơ",
                        "code": 13018,
                        "codename": "xa_dong_co",
                        "division_type": "xã",
                        "short_codename": "dong_co"
                    },
                    {
                        "name": "Xã Tây Giang",
                        "code": 13021,
                        "codename": "xa_tay_giang",
                        "division_type": "xã",
                        "short_codename": "tay_giang"
                    },
                    {
                        "name": "Xã Đông Lâm",
                        "code": 13024,
                        "codename": "xa_dong_lam",
                        "division_type": "xã",
                        "short_codename": "dong_lam"
                    },
                    {
                        "name": "Xã Phương Công",
                        "code": 13027,
                        "codename": "xa_phuong_cong",
                        "division_type": "xã",
                        "short_codename": "phuong_cong"
                    },
                    {
                        "name": "Xã Tây Phong",
                        "code": 13030,
                        "codename": "xa_tay_phong",
                        "division_type": "xã",
                        "short_codename": "tay_phong"
                    },
                    {
                        "name": "Xã Tây Tiến",
                        "code": 13033,
                        "codename": "xa_tay_tien",
                        "division_type": "xã",
                        "short_codename": "tay_tien"
                    },
                    {
                        "name": "Xã Nam Cường",
                        "code": 13036,
                        "codename": "xa_nam_cuong",
                        "division_type": "xã",
                        "short_codename": "nam_cuong"
                    },
                    {
                        "name": "Xã Vân Trường",
                        "code": 13039,
                        "codename": "xa_van_truong",
                        "division_type": "xã",
                        "short_codename": "van_truong"
                    },
                    {
                        "name": "Xã Nam Thắng",
                        "code": 13042,
                        "codename": "xa_nam_thang",
                        "division_type": "xã",
                        "short_codename": "nam_thang"
                    },
                    {
                        "name": "Xã Nam Chính",
                        "code": 13045,
                        "codename": "xa_nam_chinh",
                        "division_type": "xã",
                        "short_codename": "nam_chinh"
                    },
                    {
                        "name": "Xã Bắc Hải",
                        "code": 13048,
                        "codename": "xa_bac_hai",
                        "division_type": "xã",
                        "short_codename": "bac_hai"
                    },
                    {
                        "name": "Xã Nam Thịnh",
                        "code": 13051,
                        "codename": "xa_nam_thinh",
                        "division_type": "xã",
                        "short_codename": "nam_thinh"
                    },
                    {
                        "name": "Xã Nam Hà",
                        "code": 13054,
                        "codename": "xa_nam_ha",
                        "division_type": "xã",
                        "short_codename": "nam_ha"
                    },
                    {
                        "name": "Xã Nam Thanh",
                        "code": 13057,
                        "codename": "xa_nam_thanh",
                        "division_type": "xã",
                        "short_codename": "nam_thanh"
                    },
                    {
                        "name": "Xã Nam Trung",
                        "code": 13060,
                        "codename": "xa_nam_trung",
                        "division_type": "xã",
                        "short_codename": "nam_trung"
                    },
                    {
                        "name": "Xã Nam Hồng",
                        "code": 13063,
                        "codename": "xa_nam_hong",
                        "division_type": "xã",
                        "short_codename": "nam_hong"
                    },
                    {
                        "name": "Xã Nam Hưng",
                        "code": 13066,
                        "codename": "xa_nam_hung",
                        "division_type": "xã",
                        "short_codename": "nam_hung"
                    },
                    {
                        "name": "Xã Nam Hải",
                        "code": 13069,
                        "codename": "xa_nam_hai",
                        "division_type": "xã",
                        "short_codename": "nam_hai"
                    },
                    {
                        "name": "Xã Nam Phú",
                        "code": 13072,
                        "codename": "xa_nam_phu",
                        "division_type": "xã",
                        "short_codename": "nam_phu"
                    }
                ]
            },
            {
                "name": "Huyện Kiến Xương",
                "code": 343,
                "codename": "huyen_kien_xuong",
                "division_type": "huyện",
                "short_codename": "kien_xuong",
                "wards": [
                    {
                        "name": "Thị trấn Kiến Xương",
                        "code": 13075,
                        "codename": "thi_tran_kien_xuong",
                        "division_type": "thị trấn",
                        "short_codename": "kien_xuong"
                    },
                    {
                        "name": "Xã Trà Giang",
                        "code": 13078,
                        "codename": "xa_tra_giang",
                        "division_type": "xã",
                        "short_codename": "tra_giang"
                    },
                    {
                        "name": "Xã Quốc Tuấn",
                        "code": 13081,
                        "codename": "xa_quoc_tuan",
                        "division_type": "xã",
                        "short_codename": "quoc_tuan"
                    },
                    {
                        "name": "Xã An Bình",
                        "code": 13087,
                        "codename": "xa_an_binh",
                        "division_type": "xã",
                        "short_codename": "an_binh"
                    },
                    {
                        "name": "Xã Tây Sơn",
                        "code": 13090,
                        "codename": "xa_tay_son",
                        "division_type": "xã",
                        "short_codename": "tay_son"
                    },
                    {
                        "name": "Xã Hồng Thái",
                        "code": 13093,
                        "codename": "xa_hong_thai",
                        "division_type": "xã",
                        "short_codename": "hong_thai"
                    },
                    {
                        "name": "Xã Bình Nguyên",
                        "code": 13096,
                        "codename": "xa_binh_nguyen",
                        "division_type": "xã",
                        "short_codename": "binh_nguyen"
                    },
                    {
                        "name": "Xã Lê Lợi",
                        "code": 13102,
                        "codename": "xa_le_loi",
                        "division_type": "xã",
                        "short_codename": "le_loi"
                    },
                    {
                        "name": "Xã Vũ Lễ",
                        "code": 13111,
                        "codename": "xa_vu_le",
                        "division_type": "xã",
                        "short_codename": "vu_le"
                    },
                    {
                        "name": "Xã Thanh Tân",
                        "code": 13114,
                        "codename": "xa_thanh_tan",
                        "division_type": "xã",
                        "short_codename": "thanh_tan"
                    },
                    {
                        "name": "Xã Thượng Hiền",
                        "code": 13117,
                        "codename": "xa_thuong_hien",
                        "division_type": "xã",
                        "short_codename": "thuong_hien"
                    },
                    {
                        "name": "Xã Nam Cao",
                        "code": 13120,
                        "codename": "xa_nam_cao",
                        "division_type": "xã",
                        "short_codename": "nam_cao"
                    },
                    {
                        "name": "Xã Đình Phùng",
                        "code": 13123,
                        "codename": "xa_dinh_phung",
                        "division_type": "xã",
                        "short_codename": "dinh_phung"
                    },
                    {
                        "name": "Xã Vũ Ninh",
                        "code": 13126,
                        "codename": "xa_vu_ninh",
                        "division_type": "xã",
                        "short_codename": "vu_ninh"
                    },
                    {
                        "name": "Xã Vũ An",
                        "code": 13129,
                        "codename": "xa_vu_an",
                        "division_type": "xã",
                        "short_codename": "vu_an"
                    },
                    {
                        "name": "Xã Quang Lịch",
                        "code": 13132,
                        "codename": "xa_quang_lich",
                        "division_type": "xã",
                        "short_codename": "quang_lich"
                    },
                    {
                        "name": "Xã Hòa Bình",
                        "code": 13135,
                        "codename": "xa_hoa_binh",
                        "division_type": "xã",
                        "short_codename": "hoa_binh"
                    },
                    {
                        "name": "Xã Bình Minh",
                        "code": 13138,
                        "codename": "xa_binh_minh",
                        "division_type": "xã",
                        "short_codename": "binh_minh"
                    },
                    {
                        "name": "Xã Vũ Quí",
                        "code": 13141,
                        "codename": "xa_vu_qui",
                        "division_type": "xã",
                        "short_codename": "vu_qui"
                    },
                    {
                        "name": "Xã Quang Bình",
                        "code": 13144,
                        "codename": "xa_quang_binh",
                        "division_type": "xã",
                        "short_codename": "quang_binh"
                    },
                    {
                        "name": "Xã Vũ Trung",
                        "code": 13150,
                        "codename": "xa_vu_trung",
                        "division_type": "xã",
                        "short_codename": "vu_trung"
                    },
                    {
                        "name": "Xã Vũ Thắng",
                        "code": 13153,
                        "codename": "xa_vu_thang",
                        "division_type": "xã",
                        "short_codename": "vu_thang"
                    },
                    {
                        "name": "Xã Vũ Công",
                        "code": 13156,
                        "codename": "xa_vu_cong",
                        "division_type": "xã",
                        "short_codename": "vu_cong"
                    },
                    {
                        "name": "Xã Vũ Hòa",
                        "code": 13159,
                        "codename": "xa_vu_hoa",
                        "division_type": "xã",
                        "short_codename": "vu_hoa"
                    },
                    {
                        "name": "Xã Quang Minh",
                        "code": 13162,
                        "codename": "xa_quang_minh",
                        "division_type": "xã",
                        "short_codename": "quang_minh"
                    },
                    {
                        "name": "Xã Quang Trung",
                        "code": 13165,
                        "codename": "xa_quang_trung",
                        "division_type": "xã",
                        "short_codename": "quang_trung"
                    },
                    {
                        "name": "Xã Minh Quang",
                        "code": 13171,
                        "codename": "xa_minh_quang",
                        "division_type": "xã",
                        "short_codename": "minh_quang"
                    },
                    {
                        "name": "Xã Vũ Bình",
                        "code": 13174,
                        "codename": "xa_vu_binh",
                        "division_type": "xã",
                        "short_codename": "vu_binh"
                    },
                    {
                        "name": "Xã Minh Tân",
                        "code": 13177,
                        "codename": "xa_minh_tan",
                        "division_type": "xã",
                        "short_codename": "minh_tan"
                    },
                    {
                        "name": "Xã Nam Bình",
                        "code": 13180,
                        "codename": "xa_nam_binh",
                        "division_type": "xã",
                        "short_codename": "nam_binh"
                    },
                    {
                        "name": "Xã Bình Thanh",
                        "code": 13183,
                        "codename": "xa_binh_thanh",
                        "division_type": "xã",
                        "short_codename": "binh_thanh"
                    },
                    {
                        "name": "Xã Bình Định",
                        "code": 13186,
                        "codename": "xa_binh_dinh",
                        "division_type": "xã",
                        "short_codename": "binh_dinh"
                    },
                    {
                        "name": "Xã Hồng Tiến",
                        "code": 13189,
                        "codename": "xa_hong_tien",
                        "division_type": "xã",
                        "short_codename": "hong_tien"
                    }
                ]
            },
            {
                "name": "Huyện Vũ Thư",
                "code": 344,
                "codename": "huyen_vu_thu",
                "division_type": "huyện",
                "short_codename": "vu_thu",
                "wards": [
                    {
                        "name": "Thị trấn Vũ Thư",
                        "code": 13192,
                        "codename": "thi_tran_vu_thu",
                        "division_type": "thị trấn",
                        "short_codename": "vu_thu"
                    },
                    {
                        "name": "Xã Hồng Lý",
                        "code": 13195,
                        "codename": "xa_hong_ly",
                        "division_type": "xã",
                        "short_codename": "hong_ly"
                    },
                    {
                        "name": "Xã Đồng Thanh",
                        "code": 13198,
                        "codename": "xa_dong_thanh",
                        "division_type": "xã",
                        "short_codename": "dong_thanh"
                    },
                    {
                        "name": "Xã Xuân Hòa",
                        "code": 13201,
                        "codename": "xa_xuan_hoa",
                        "division_type": "xã",
                        "short_codename": "xuan_hoa"
                    },
                    {
                        "name": "Xã Hiệp Hòa",
                        "code": 13204,
                        "codename": "xa_hiep_hoa",
                        "division_type": "xã",
                        "short_codename": "hiep_hoa"
                    },
                    {
                        "name": "Xã Phúc Thành",
                        "code": 13207,
                        "codename": "xa_phuc_thanh",
                        "division_type": "xã",
                        "short_codename": "phuc_thanh"
                    },
                    {
                        "name": "Xã Tân Phong",
                        "code": 13210,
                        "codename": "xa_tan_phong",
                        "division_type": "xã",
                        "short_codename": "tan_phong"
                    },
                    {
                        "name": "Xã Song Lãng",
                        "code": 13213,
                        "codename": "xa_song_lang",
                        "division_type": "xã",
                        "short_codename": "song_lang"
                    },
                    {
                        "name": "Xã Tân Hòa",
                        "code": 13216,
                        "codename": "xa_tan_hoa",
                        "division_type": "xã",
                        "short_codename": "tan_hoa"
                    },
                    {
                        "name": "Xã Việt Hùng",
                        "code": 13219,
                        "codename": "xa_viet_hung",
                        "division_type": "xã",
                        "short_codename": "viet_hung"
                    },
                    {
                        "name": "Xã Minh Lãng",
                        "code": 13222,
                        "codename": "xa_minh_lang",
                        "division_type": "xã",
                        "short_codename": "minh_lang"
                    },
                    {
                        "name": "Xã Minh Khai",
                        "code": 13228,
                        "codename": "xa_minh_khai",
                        "division_type": "xã",
                        "short_codename": "minh_khai"
                    },
                    {
                        "name": "Xã Dũng Nghĩa",
                        "code": 13231,
                        "codename": "xa_dung_nghia",
                        "division_type": "xã",
                        "short_codename": "dung_nghia"
                    },
                    {
                        "name": "Xã Minh Quang",
                        "code": 13234,
                        "codename": "xa_minh_quang",
                        "division_type": "xã",
                        "short_codename": "minh_quang"
                    },
                    {
                        "name": "Xã Tam Quang",
                        "code": 13237,
                        "codename": "xa_tam_quang",
                        "division_type": "xã",
                        "short_codename": "tam_quang"
                    },
                    {
                        "name": "Xã Tân Lập",
                        "code": 13240,
                        "codename": "xa_tan_lap",
                        "division_type": "xã",
                        "short_codename": "tan_lap"
                    },
                    {
                        "name": "Xã Bách Thuận",
                        "code": 13243,
                        "codename": "xa_bach_thuan",
                        "division_type": "xã",
                        "short_codename": "bach_thuan"
                    },
                    {
                        "name": "Xã Tự Tân",
                        "code": 13246,
                        "codename": "xa_tu_tan",
                        "division_type": "xã",
                        "short_codename": "tu_tan"
                    },
                    {
                        "name": "Xã Song An",
                        "code": 13249,
                        "codename": "xa_song_an",
                        "division_type": "xã",
                        "short_codename": "song_an"
                    },
                    {
                        "name": "Xã Trung An",
                        "code": 13252,
                        "codename": "xa_trung_an",
                        "division_type": "xã",
                        "short_codename": "trung_an"
                    },
                    {
                        "name": "Xã Vũ Hội",
                        "code": 13255,
                        "codename": "xa_vu_hoi",
                        "division_type": "xã",
                        "short_codename": "vu_hoi"
                    },
                    {
                        "name": "Xã Hòa Bình",
                        "code": 13258,
                        "codename": "xa_hoa_binh",
                        "division_type": "xã",
                        "short_codename": "hoa_binh"
                    },
                    {
                        "name": "Xã Nguyên Xá",
                        "code": 13261,
                        "codename": "xa_nguyen_xa",
                        "division_type": "xã",
                        "short_codename": "nguyen_xa"
                    },
                    {
                        "name": "Xã Việt Thuận",
                        "code": 13264,
                        "codename": "xa_viet_thuan",
                        "division_type": "xã",
                        "short_codename": "viet_thuan"
                    },
                    {
                        "name": "Xã Vũ Vinh",
                        "code": 13267,
                        "codename": "xa_vu_vinh",
                        "division_type": "xã",
                        "short_codename": "vu_vinh"
                    },
                    {
                        "name": "Xã Vũ Đoài",
                        "code": 13270,
                        "codename": "xa_vu_doai",
                        "division_type": "xã",
                        "short_codename": "vu_doai"
                    },
                    {
                        "name": "Xã Vũ Tiến",
                        "code": 13273,
                        "codename": "xa_vu_tien",
                        "division_type": "xã",
                        "short_codename": "vu_tien"
                    },
                    {
                        "name": "Xã Vũ Vân",
                        "code": 13276,
                        "codename": "xa_vu_van",
                        "division_type": "xã",
                        "short_codename": "vu_van"
                    },
                    {
                        "name": "Xã Duy Nhất",
                        "code": 13279,
                        "codename": "xa_duy_nhat",
                        "division_type": "xã",
                        "short_codename": "duy_nhat"
                    },
                    {
                        "name": "Xã Hồng Phong",
                        "code": 13282,
                        "codename": "xa_hong_phong",
                        "division_type": "xã",
                        "short_codename": "hong_phong"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Hà Nam",
        "code": 35,
        "codename": "tinh_ha_nam",
        "division_type": "tỉnh",
        "phone_code": 226,
        "districts": [
            {
                "name": "Thành phố Phủ Lý",
                "code": 347,
                "codename": "thanh_pho_phu_ly",
                "division_type": "thành phố",
                "short_codename": "phu_ly",
                "wards": [
                    {
                        "name": "Phường Quang Trung",
                        "code": 13285,
                        "codename": "phuong_quang_trung",
                        "division_type": "phường",
                        "short_codename": "quang_trung"
                    },
                    {
                        "name": "Phường Lương Khánh Thiện",
                        "code": 13288,
                        "codename": "phuong_luong_khanh_thien",
                        "division_type": "phường",
                        "short_codename": "luong_khanh_thien"
                    },
                    {
                        "name": "Phường Lê Hồng Phong",
                        "code": 13291,
                        "codename": "phuong_le_hong_phong",
                        "division_type": "phường",
                        "short_codename": "le_hong_phong"
                    },
                    {
                        "name": "Phường Minh Khai",
                        "code": 13294,
                        "codename": "phuong_minh_khai",
                        "division_type": "phường",
                        "short_codename": "minh_khai"
                    },
                    {
                        "name": "Phường Hai Bà Trưng",
                        "code": 13297,
                        "codename": "phuong_hai_ba_trung",
                        "division_type": "phường",
                        "short_codename": "hai_ba_trung"
                    },
                    {
                        "name": "Phường Trần Hưng Đạo",
                        "code": 13300,
                        "codename": "phuong_tran_hung_dao",
                        "division_type": "phường",
                        "short_codename": "tran_hung_dao"
                    },
                    {
                        "name": "Phường Lam Hạ",
                        "code": 13303,
                        "codename": "phuong_lam_ha",
                        "division_type": "phường",
                        "short_codename": "lam_ha"
                    },
                    {
                        "name": "Xã Phù Vân",
                        "code": 13306,
                        "codename": "xa_phu_van",
                        "division_type": "xã",
                        "short_codename": "phu_van"
                    },
                    {
                        "name": "Phường Liêm Chính",
                        "code": 13309,
                        "codename": "phuong_liem_chinh",
                        "division_type": "phường",
                        "short_codename": "liem_chinh"
                    },
                    {
                        "name": "Xã Liêm Chung",
                        "code": 13312,
                        "codename": "xa_liem_chung",
                        "division_type": "xã",
                        "short_codename": "liem_chung"
                    },
                    {
                        "name": "Phường Thanh Châu",
                        "code": 13315,
                        "codename": "phuong_thanh_chau",
                        "division_type": "phường",
                        "short_codename": "thanh_chau"
                    },
                    {
                        "name": "Phường Châu Sơn",
                        "code": 13318,
                        "codename": "phuong_chau_son",
                        "division_type": "phường",
                        "short_codename": "chau_son"
                    },
                    {
                        "name": "Xã Tiên Tân",
                        "code": 13366,
                        "codename": "xa_tien_tan",
                        "division_type": "xã",
                        "short_codename": "tien_tan"
                    },
                    {
                        "name": "Xã Tiên Hiệp",
                        "code": 13372,
                        "codename": "xa_tien_hiep",
                        "division_type": "xã",
                        "short_codename": "tien_hiep"
                    },
                    {
                        "name": "Xã Tiên Hải",
                        "code": 13381,
                        "codename": "xa_tien_hai",
                        "division_type": "xã",
                        "short_codename": "tien_hai"
                    },
                    {
                        "name": "Xã Kim Bình",
                        "code": 13426,
                        "codename": "xa_kim_binh",
                        "division_type": "xã",
                        "short_codename": "kim_binh"
                    },
                    {
                        "name": "Xã Liêm Tuyền",
                        "code": 13444,
                        "codename": "xa_liem_tuyen",
                        "division_type": "xã",
                        "short_codename": "liem_tuyen"
                    },
                    {
                        "name": "Xã Liêm Tiết",
                        "code": 13447,
                        "codename": "xa_liem_tiet",
                        "division_type": "xã",
                        "short_codename": "liem_tiet"
                    },
                    {
                        "name": "Phường Thanh Tuyền",
                        "code": 13459,
                        "codename": "phuong_thanh_tuyen",
                        "division_type": "phường",
                        "short_codename": "thanh_tuyen"
                    },
                    {
                        "name": "Xã Đinh Xá",
                        "code": 13507,
                        "codename": "xa_dinh_xa",
                        "division_type": "xã",
                        "short_codename": "dinh_xa"
                    },
                    {
                        "name": "Xã Trịnh Xá",
                        "code": 13513,
                        "codename": "xa_trinh_xa",
                        "division_type": "xã",
                        "short_codename": "trinh_xa"
                    }
                ]
            },
            {
                "name": "Thị xã Duy Tiên",
                "code": 349,
                "codename": "thi_xa_duy_tien",
                "division_type": "thị xã",
                "short_codename": "duy_tien",
                "wards": [
                    {
                        "name": "Phường Đồng Văn",
                        "code": 13321,
                        "codename": "phuong_dong_van",
                        "division_type": "phường",
                        "short_codename": "dong_van"
                    },
                    {
                        "name": "Phường Hòa Mạc",
                        "code": 13324,
                        "codename": "phuong_hoa_mac",
                        "division_type": "phường",
                        "short_codename": "hoa_mac"
                    },
                    {
                        "name": "Xã Mộc Bắc",
                        "code": 13327,
                        "codename": "xa_moc_bac",
                        "division_type": "xã",
                        "short_codename": "moc_bac"
                    },
                    {
                        "name": "Phường Châu Giang",
                        "code": 13330,
                        "codename": "phuong_chau_giang",
                        "division_type": "phường",
                        "short_codename": "chau_giang"
                    },
                    {
                        "name": "Phường Bạch Thượng",
                        "code": 13333,
                        "codename": "phuong_bach_thuong",
                        "division_type": "phường",
                        "short_codename": "bach_thuong"
                    },
                    {
                        "name": "Phường Duy Minh",
                        "code": 13336,
                        "codename": "phuong_duy_minh",
                        "division_type": "phường",
                        "short_codename": "duy_minh"
                    },
                    {
                        "name": "Xã Mộc Nam",
                        "code": 13339,
                        "codename": "xa_moc_nam",
                        "division_type": "xã",
                        "short_codename": "moc_nam"
                    },
                    {
                        "name": "Phường Duy Hải",
                        "code": 13342,
                        "codename": "phuong_duy_hai",
                        "division_type": "phường",
                        "short_codename": "duy_hai"
                    },
                    {
                        "name": "Xã Chuyên Ngoại",
                        "code": 13345,
                        "codename": "xa_chuyen_ngoai",
                        "division_type": "xã",
                        "short_codename": "chuyen_ngoai"
                    },
                    {
                        "name": "Phường Yên Bắc",
                        "code": 13348,
                        "codename": "phuong_yen_bac",
                        "division_type": "phường",
                        "short_codename": "yen_bac"
                    },
                    {
                        "name": "Xã Trác Văn",
                        "code": 13351,
                        "codename": "xa_trac_van",
                        "division_type": "xã",
                        "short_codename": "trac_van"
                    },
                    {
                        "name": "Phường Tiên Nội",
                        "code": 13354,
                        "codename": "phuong_tien_noi",
                        "division_type": "phường",
                        "short_codename": "tien_noi"
                    },
                    {
                        "name": "Phường Hoàng Đông",
                        "code": 13357,
                        "codename": "phuong_hoang_dong",
                        "division_type": "phường",
                        "short_codename": "hoang_dong"
                    },
                    {
                        "name": "Xã Yên Nam",
                        "code": 13360,
                        "codename": "xa_yen_nam",
                        "division_type": "xã",
                        "short_codename": "yen_nam"
                    },
                    {
                        "name": "Xã Tiên Ngoại",
                        "code": 13363,
                        "codename": "xa_tien_ngoai",
                        "division_type": "xã",
                        "short_codename": "tien_ngoai"
                    },
                    {
                        "name": "Xã Tiên Sơn",
                        "code": 13369,
                        "codename": "xa_tien_son",
                        "division_type": "xã",
                        "short_codename": "tien_son"
                    }
                ]
            },
            {
                "name": "Huyện Kim Bảng",
                "code": 350,
                "codename": "huyen_kim_bang",
                "division_type": "huyện",
                "short_codename": "kim_bang",
                "wards": [
                    {
                        "name": "Thị trấn Quế",
                        "code": 13384,
                        "codename": "thi_tran_que",
                        "division_type": "thị trấn",
                        "short_codename": "que"
                    },
                    {
                        "name": "Xã Nguyễn Úy",
                        "code": 13387,
                        "codename": "xa_nguyen_uy",
                        "division_type": "xã",
                        "short_codename": "nguyen_uy"
                    },
                    {
                        "name": "Xã Đại Cương",
                        "code": 13390,
                        "codename": "xa_dai_cuong",
                        "division_type": "xã",
                        "short_codename": "dai_cuong"
                    },
                    {
                        "name": "Xã Lê Hồ",
                        "code": 13393,
                        "codename": "xa_le_ho",
                        "division_type": "xã",
                        "short_codename": "le_ho"
                    },
                    {
                        "name": "Xã Tượng Lĩnh",
                        "code": 13396,
                        "codename": "xa_tuong_linh",
                        "division_type": "xã",
                        "short_codename": "tuong_linh"
                    },
                    {
                        "name": "Xã Nhật Tựu",
                        "code": 13399,
                        "codename": "xa_nhat_tuu",
                        "division_type": "xã",
                        "short_codename": "nhat_tuu"
                    },
                    {
                        "name": "Xã Nhật Tân",
                        "code": 13402,
                        "codename": "xa_nhat_tan",
                        "division_type": "xã",
                        "short_codename": "nhat_tan"
                    },
                    {
                        "name": "Xã Đồng Hóa",
                        "code": 13405,
                        "codename": "xa_dong_hoa",
                        "division_type": "xã",
                        "short_codename": "dong_hoa"
                    },
                    {
                        "name": "Xã Hoàng Tây",
                        "code": 13408,
                        "codename": "xa_hoang_tay",
                        "division_type": "xã",
                        "short_codename": "hoang_tay"
                    },
                    {
                        "name": "Xã Tân Sơn",
                        "code": 13411,
                        "codename": "xa_tan_son",
                        "division_type": "xã",
                        "short_codename": "tan_son"
                    },
                    {
                        "name": "Xã Thụy Lôi",
                        "code": 13414,
                        "codename": "xa_thuy_loi",
                        "division_type": "xã",
                        "short_codename": "thuy_loi"
                    },
                    {
                        "name": "Xã Văn Xá",
                        "code": 13417,
                        "codename": "xa_van_xa",
                        "division_type": "xã",
                        "short_codename": "van_xa"
                    },
                    {
                        "name": "Xã Khả Phong",
                        "code": 13420,
                        "codename": "xa_kha_phong",
                        "division_type": "xã",
                        "short_codename": "kha_phong"
                    },
                    {
                        "name": "Xã Ngọc Sơn",
                        "code": 13423,
                        "codename": "xa_ngoc_son",
                        "division_type": "xã",
                        "short_codename": "ngoc_son"
                    },
                    {
                        "name": "Thị trấn Ba Sao",
                        "code": 13429,
                        "codename": "thi_tran_ba_sao",
                        "division_type": "thị trấn",
                        "short_codename": "ba_sao"
                    },
                    {
                        "name": "Xã Liên Sơn",
                        "code": 13432,
                        "codename": "xa_lien_son",
                        "division_type": "xã",
                        "short_codename": "lien_son"
                    },
                    {
                        "name": "Xã Thi Sơn",
                        "code": 13435,
                        "codename": "xa_thi_son",
                        "division_type": "xã",
                        "short_codename": "thi_son"
                    },
                    {
                        "name": "Xã Thanh Sơn",
                        "code": 13438,
                        "codename": "xa_thanh_son",
                        "division_type": "xã",
                        "short_codename": "thanh_son"
                    }
                ]
            },
            {
                "name": "Huyện Thanh Liêm",
                "code": 351,
                "codename": "huyen_thanh_liem",
                "division_type": "huyện",
                "short_codename": "thanh_liem",
                "wards": [
                    {
                        "name": "Thị trấn Kiện Khê",
                        "code": 13441,
                        "codename": "thi_tran_kien_khe",
                        "division_type": "thị trấn",
                        "short_codename": "kien_khe"
                    },
                    {
                        "name": "Xã Liêm Phong",
                        "code": 13450,
                        "codename": "xa_liem_phong",
                        "division_type": "xã",
                        "short_codename": "liem_phong"
                    },
                    {
                        "name": "Xã Thanh Hà",
                        "code": 13453,
                        "codename": "xa_thanh_ha",
                        "division_type": "xã",
                        "short_codename": "thanh_ha"
                    },
                    {
                        "name": "Xã Liêm Cần",
                        "code": 13456,
                        "codename": "xa_liem_can",
                        "division_type": "xã",
                        "short_codename": "liem_can"
                    },
                    {
                        "name": "Xã Liêm Thuận",
                        "code": 13465,
                        "codename": "xa_liem_thuan",
                        "division_type": "xã",
                        "short_codename": "liem_thuan"
                    },
                    {
                        "name": "Xã Thanh Thủy",
                        "code": 13468,
                        "codename": "xa_thanh_thuy",
                        "division_type": "xã",
                        "short_codename": "thanh_thuy"
                    },
                    {
                        "name": "Xã Thanh Phong",
                        "code": 13471,
                        "codename": "xa_thanh_phong",
                        "division_type": "xã",
                        "short_codename": "thanh_phong"
                    },
                    {
                        "name": "Thị trấn Tân Thanh",
                        "code": 13474,
                        "codename": "thi_tran_tan_thanh",
                        "division_type": "thị trấn",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Xã Thanh Tân",
                        "code": 13477,
                        "codename": "xa_thanh_tan",
                        "division_type": "xã",
                        "short_codename": "thanh_tan"
                    },
                    {
                        "name": "Xã Liêm Túc",
                        "code": 13480,
                        "codename": "xa_liem_tuc",
                        "division_type": "xã",
                        "short_codename": "liem_tuc"
                    },
                    {
                        "name": "Xã Liêm Sơn",
                        "code": 13483,
                        "codename": "xa_liem_son",
                        "division_type": "xã",
                        "short_codename": "liem_son"
                    },
                    {
                        "name": "Xã Thanh Hương",
                        "code": 13486,
                        "codename": "xa_thanh_huong",
                        "division_type": "xã",
                        "short_codename": "thanh_huong"
                    },
                    {
                        "name": "Xã Thanh Nghị",
                        "code": 13489,
                        "codename": "xa_thanh_nghi",
                        "division_type": "xã",
                        "short_codename": "thanh_nghi"
                    },
                    {
                        "name": "Xã Thanh Tâm",
                        "code": 13492,
                        "codename": "xa_thanh_tam",
                        "division_type": "xã",
                        "short_codename": "thanh_tam"
                    },
                    {
                        "name": "Xã Thanh Nguyên",
                        "code": 13495,
                        "codename": "xa_thanh_nguyen",
                        "division_type": "xã",
                        "short_codename": "thanh_nguyen"
                    },
                    {
                        "name": "Xã Thanh Hải",
                        "code": 13498,
                        "codename": "xa_thanh_hai",
                        "division_type": "xã",
                        "short_codename": "thanh_hai"
                    }
                ]
            },
            {
                "name": "Huyện Bình Lục",
                "code": 352,
                "codename": "huyen_binh_luc",
                "division_type": "huyện",
                "short_codename": "binh_luc",
                "wards": [
                    {
                        "name": "Thị trấn Bình Mỹ",
                        "code": 13501,
                        "codename": "thi_tran_binh_my",
                        "division_type": "thị trấn",
                        "short_codename": "binh_my"
                    },
                    {
                        "name": "Xã Bình Nghĩa",
                        "code": 13504,
                        "codename": "xa_binh_nghia",
                        "division_type": "xã",
                        "short_codename": "binh_nghia"
                    },
                    {
                        "name": "Xã Tràng An",
                        "code": 13510,
                        "codename": "xa_trang_an",
                        "division_type": "xã",
                        "short_codename": "trang_an"
                    },
                    {
                        "name": "Xã Đồng Du",
                        "code": 13516,
                        "codename": "xa_dong_du",
                        "division_type": "xã",
                        "short_codename": "dong_du"
                    },
                    {
                        "name": "Xã Ngọc Lũ",
                        "code": 13519,
                        "codename": "xa_ngoc_lu",
                        "division_type": "xã",
                        "short_codename": "ngoc_lu"
                    },
                    {
                        "name": "Xã Hưng Công",
                        "code": 13522,
                        "codename": "xa_hung_cong",
                        "division_type": "xã",
                        "short_codename": "hung_cong"
                    },
                    {
                        "name": "Xã Đồn Xá",
                        "code": 13525,
                        "codename": "xa_don_xa",
                        "division_type": "xã",
                        "short_codename": "don_xa"
                    },
                    {
                        "name": "Xã An Ninh",
                        "code": 13528,
                        "codename": "xa_an_ninh",
                        "division_type": "xã",
                        "short_codename": "an_ninh"
                    },
                    {
                        "name": "Xã Bồ Đề",
                        "code": 13531,
                        "codename": "xa_bo_de",
                        "division_type": "xã",
                        "short_codename": "bo_de"
                    },
                    {
                        "name": "Xã Bối Cầu",
                        "code": 13534,
                        "codename": "xa_boi_cau",
                        "division_type": "xã",
                        "short_codename": "boi_cau"
                    },
                    {
                        "name": "Xã An Nội",
                        "code": 13540,
                        "codename": "xa_an_noi",
                        "division_type": "xã",
                        "short_codename": "an_noi"
                    },
                    {
                        "name": "Xã Vũ Bản",
                        "code": 13543,
                        "codename": "xa_vu_ban",
                        "division_type": "xã",
                        "short_codename": "vu_ban"
                    },
                    {
                        "name": "Xã Trung Lương",
                        "code": 13546,
                        "codename": "xa_trung_luong",
                        "division_type": "xã",
                        "short_codename": "trung_luong"
                    },
                    {
                        "name": "Xã An Đổ",
                        "code": 13552,
                        "codename": "xa_an_do",
                        "division_type": "xã",
                        "short_codename": "an_do"
                    },
                    {
                        "name": "Xã La Sơn",
                        "code": 13555,
                        "codename": "xa_la_son",
                        "division_type": "xã",
                        "short_codename": "la_son"
                    },
                    {
                        "name": "Xã Tiêu Động",
                        "code": 13558,
                        "codename": "xa_tieu_dong",
                        "division_type": "xã",
                        "short_codename": "tieu_dong"
                    },
                    {
                        "name": "Xã An Lão",
                        "code": 13561,
                        "codename": "xa_an_lao",
                        "division_type": "xã",
                        "short_codename": "an_lao"
                    }
                ]
            },
            {
                "name": "Huyện Lý Nhân",
                "code": 353,
                "codename": "huyen_ly_nhan",
                "division_type": "huyện",
                "short_codename": "ly_nhan",
                "wards": [
                    {
                        "name": "Xã Hợp Lý",
                        "code": 13567,
                        "codename": "xa_hop_ly",
                        "division_type": "xã",
                        "short_codename": "hop_ly"
                    },
                    {
                        "name": "Xã Nguyên Lý",
                        "code": 13570,
                        "codename": "xa_nguyen_ly",
                        "division_type": "xã",
                        "short_codename": "nguyen_ly"
                    },
                    {
                        "name": "Xã Chính Lý",
                        "code": 13573,
                        "codename": "xa_chinh_ly",
                        "division_type": "xã",
                        "short_codename": "chinh_ly"
                    },
                    {
                        "name": "Xã Chân Lý",
                        "code": 13576,
                        "codename": "xa_chan_ly",
                        "division_type": "xã",
                        "short_codename": "chan_ly"
                    },
                    {
                        "name": "Xã Đạo Lý",
                        "code": 13579,
                        "codename": "xa_dao_ly",
                        "division_type": "xã",
                        "short_codename": "dao_ly"
                    },
                    {
                        "name": "Xã Công Lý",
                        "code": 13582,
                        "codename": "xa_cong_ly",
                        "division_type": "xã",
                        "short_codename": "cong_ly"
                    },
                    {
                        "name": "Xã Văn Lý",
                        "code": 13585,
                        "codename": "xa_van_ly",
                        "division_type": "xã",
                        "short_codename": "van_ly"
                    },
                    {
                        "name": "Xã Bắc Lý",
                        "code": 13588,
                        "codename": "xa_bac_ly",
                        "division_type": "xã",
                        "short_codename": "bac_ly"
                    },
                    {
                        "name": "Xã Đức Lý",
                        "code": 13591,
                        "codename": "xa_duc_ly",
                        "division_type": "xã",
                        "short_codename": "duc_ly"
                    },
                    {
                        "name": "Xã Trần Hưng Đạo",
                        "code": 13594,
                        "codename": "xa_tran_hung_dao",
                        "division_type": "xã",
                        "short_codename": "tran_hung_dao"
                    },
                    {
                        "name": "Thị trấn Vĩnh Trụ",
                        "code": 13597,
                        "codename": "thi_tran_vinh_tru",
                        "division_type": "thị trấn",
                        "short_codename": "vinh_tru"
                    },
                    {
                        "name": "Xã Nhân Thịnh",
                        "code": 13600,
                        "codename": "xa_nhan_thinh",
                        "division_type": "xã",
                        "short_codename": "nhan_thinh"
                    },
                    {
                        "name": "Xã Nhân Khang",
                        "code": 13606,
                        "codename": "xa_nhan_khang",
                        "division_type": "xã",
                        "short_codename": "nhan_khang"
                    },
                    {
                        "name": "Xã Nhân Mỹ",
                        "code": 13609,
                        "codename": "xa_nhan_my",
                        "division_type": "xã",
                        "short_codename": "nhan_my"
                    },
                    {
                        "name": "Xã Nhân Nghĩa",
                        "code": 13612,
                        "codename": "xa_nhan_nghia",
                        "division_type": "xã",
                        "short_codename": "nhan_nghia"
                    },
                    {
                        "name": "Xã Nhân Chính",
                        "code": 13615,
                        "codename": "xa_nhan_chinh",
                        "division_type": "xã",
                        "short_codename": "nhan_chinh"
                    },
                    {
                        "name": "Xã Nhân Bình",
                        "code": 13618,
                        "codename": "xa_nhan_binh",
                        "division_type": "xã",
                        "short_codename": "nhan_binh"
                    },
                    {
                        "name": "Xã Phú Phúc",
                        "code": 13621,
                        "codename": "xa_phu_phuc",
                        "division_type": "xã",
                        "short_codename": "phu_phuc"
                    },
                    {
                        "name": "Xã Xuân Khê",
                        "code": 13624,
                        "codename": "xa_xuan_khe",
                        "division_type": "xã",
                        "short_codename": "xuan_khe"
                    },
                    {
                        "name": "Xã Tiến Thắng",
                        "code": 13627,
                        "codename": "xa_tien_thang",
                        "division_type": "xã",
                        "short_codename": "tien_thang"
                    },
                    {
                        "name": "Xã Hòa Hậu",
                        "code": 13630,
                        "codename": "xa_hoa_hau",
                        "division_type": "xã",
                        "short_codename": "hoa_hau"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Nam Định",
        "code": 36,
        "codename": "tinh_nam_dinh",
        "division_type": "tỉnh",
        "phone_code": 228,
        "districts": [
            {
                "name": "Thành phố Nam Định",
                "code": 356,
                "codename": "thanh_pho_nam_dinh",
                "division_type": "thành phố",
                "short_codename": "nam_dinh",
                "wards": [
                    {
                        "name": "Phường Hạ Long",
                        "code": 13633,
                        "codename": "phuong_ha_long",
                        "division_type": "phường",
                        "short_codename": "ha_long"
                    },
                    {
                        "name": "Phường Trần Tế Xương",
                        "code": 13636,
                        "codename": "phuong_tran_te_xuong",
                        "division_type": "phường",
                        "short_codename": "tran_te_xuong"
                    },
                    {
                        "name": "Phường Vị Hoàng",
                        "code": 13639,
                        "codename": "phuong_vi_hoang",
                        "division_type": "phường",
                        "short_codename": "vi_hoang"
                    },
                    {
                        "name": "Phường Vị Xuyên",
                        "code": 13642,
                        "codename": "phuong_vi_xuyen",
                        "division_type": "phường",
                        "short_codename": "vi_xuyen"
                    },
                    {
                        "name": "Phường Quang Trung",
                        "code": 13645,
                        "codename": "phuong_quang_trung",
                        "division_type": "phường",
                        "short_codename": "quang_trung"
                    },
                    {
                        "name": "Phường Cửa Bắc",
                        "code": 13648,
                        "codename": "phuong_cua_bac",
                        "division_type": "phường",
                        "short_codename": "cua_bac"
                    },
                    {
                        "name": "Phường Nguyễn Du",
                        "code": 13651,
                        "codename": "phuong_nguyen_du",
                        "division_type": "phường",
                        "short_codename": "nguyen_du"
                    },
                    {
                        "name": "Phường Bà Triệu",
                        "code": 13654,
                        "codename": "phuong_ba_trieu",
                        "division_type": "phường",
                        "short_codename": "ba_trieu"
                    },
                    {
                        "name": "Phường Trường Thi",
                        "code": 13657,
                        "codename": "phuong_truong_thi",
                        "division_type": "phường",
                        "short_codename": "truong_thi"
                    },
                    {
                        "name": "Phường Phan Đình Phùng",
                        "code": 13660,
                        "codename": "phuong_phan_dinh_phung",
                        "division_type": "phường",
                        "short_codename": "phan_dinh_phung"
                    },
                    {
                        "name": "Phường Ngô Quyền",
                        "code": 13663,
                        "codename": "phuong_ngo_quyen",
                        "division_type": "phường",
                        "short_codename": "ngo_quyen"
                    },
                    {
                        "name": "Phường Trần Hưng Đạo",
                        "code": 13666,
                        "codename": "phuong_tran_hung_dao",
                        "division_type": "phường",
                        "short_codename": "tran_hung_dao"
                    },
                    {
                        "name": "Phường Trần Đăng Ninh",
                        "code": 13669,
                        "codename": "phuong_tran_dang_ninh",
                        "division_type": "phường",
                        "short_codename": "tran_dang_ninh"
                    },
                    {
                        "name": "Phường Năng Tĩnh",
                        "code": 13672,
                        "codename": "phuong_nang_tinh",
                        "division_type": "phường",
                        "short_codename": "nang_tinh"
                    },
                    {
                        "name": "Phường Văn Miếu",
                        "code": 13675,
                        "codename": "phuong_van_mieu",
                        "division_type": "phường",
                        "short_codename": "van_mieu"
                    },
                    {
                        "name": "Phường Trần Quang Khải",
                        "code": 13678,
                        "codename": "phuong_tran_quang_khai",
                        "division_type": "phường",
                        "short_codename": "tran_quang_khai"
                    },
                    {
                        "name": "Phường Thống Nhất",
                        "code": 13681,
                        "codename": "phuong_thong_nhat",
                        "division_type": "phường",
                        "short_codename": "thong_nhat"
                    },
                    {
                        "name": "Phường Lộc Hạ",
                        "code": 13684,
                        "codename": "phuong_loc_ha",
                        "division_type": "phường",
                        "short_codename": "loc_ha"
                    },
                    {
                        "name": "Phường Lộc Vượng",
                        "code": 13687,
                        "codename": "phuong_loc_vuong",
                        "division_type": "phường",
                        "short_codename": "loc_vuong"
                    },
                    {
                        "name": "Phường Cửa Nam",
                        "code": 13690,
                        "codename": "phuong_cua_nam",
                        "division_type": "phường",
                        "short_codename": "cua_nam"
                    },
                    {
                        "name": "Phường Lộc Hòa",
                        "code": 13693,
                        "codename": "phuong_loc_hoa",
                        "division_type": "phường",
                        "short_codename": "loc_hoa"
                    },
                    {
                        "name": "Xã Nam Phong",
                        "code": 13696,
                        "codename": "xa_nam_phong",
                        "division_type": "xã",
                        "short_codename": "nam_phong"
                    },
                    {
                        "name": "Phường Mỹ Xá",
                        "code": 13699,
                        "codename": "phuong_my_xa",
                        "division_type": "phường",
                        "short_codename": "my_xa"
                    },
                    {
                        "name": "Xã Lộc An",
                        "code": 13702,
                        "codename": "xa_loc_an",
                        "division_type": "xã",
                        "short_codename": "loc_an"
                    },
                    {
                        "name": "Xã Nam Vân",
                        "code": 13705,
                        "codename": "xa_nam_van",
                        "division_type": "xã",
                        "short_codename": "nam_van"
                    }
                ]
            },
            {
                "name": "Huyện Mỹ Lộc",
                "code": 358,
                "codename": "huyen_my_loc",
                "division_type": "huyện",
                "short_codename": "my_loc",
                "wards": [
                    {
                        "name": "Thị trấn Mỹ Lộc",
                        "code": 13708,
                        "codename": "thi_tran_my_loc",
                        "division_type": "thị trấn",
                        "short_codename": "my_loc"
                    },
                    {
                        "name": "Xã Mỹ Hà",
                        "code": 13711,
                        "codename": "xa_my_ha",
                        "division_type": "xã",
                        "short_codename": "my_ha"
                    },
                    {
                        "name": "Xã Mỹ Tiến",
                        "code": 13714,
                        "codename": "xa_my_tien",
                        "division_type": "xã",
                        "short_codename": "my_tien"
                    },
                    {
                        "name": "Xã Mỹ Thắng",
                        "code": 13717,
                        "codename": "xa_my_thang",
                        "division_type": "xã",
                        "short_codename": "my_thang"
                    },
                    {
                        "name": "Xã Mỹ Trung",
                        "code": 13720,
                        "codename": "xa_my_trung",
                        "division_type": "xã",
                        "short_codename": "my_trung"
                    },
                    {
                        "name": "Xã Mỹ Tân",
                        "code": 13723,
                        "codename": "xa_my_tan",
                        "division_type": "xã",
                        "short_codename": "my_tan"
                    },
                    {
                        "name": "Xã Mỹ Phúc",
                        "code": 13726,
                        "codename": "xa_my_phuc",
                        "division_type": "xã",
                        "short_codename": "my_phuc"
                    },
                    {
                        "name": "Xã Mỹ Hưng",
                        "code": 13729,
                        "codename": "xa_my_hung",
                        "division_type": "xã",
                        "short_codename": "my_hung"
                    },
                    {
                        "name": "Xã Mỹ Thuận",
                        "code": 13732,
                        "codename": "xa_my_thuan",
                        "division_type": "xã",
                        "short_codename": "my_thuan"
                    },
                    {
                        "name": "Xã Mỹ Thịnh",
                        "code": 13735,
                        "codename": "xa_my_thinh",
                        "division_type": "xã",
                        "short_codename": "my_thinh"
                    },
                    {
                        "name": "Xã Mỹ Thành",
                        "code": 13738,
                        "codename": "xa_my_thanh",
                        "division_type": "xã",
                        "short_codename": "my_thanh"
                    }
                ]
            },
            {
                "name": "Huyện Vụ Bản",
                "code": 359,
                "codename": "huyen_vu_ban",
                "division_type": "huyện",
                "short_codename": "vu_ban",
                "wards": [
                    {
                        "name": "Thị trấn Gôi",
                        "code": 13741,
                        "codename": "thi_tran_goi",
                        "division_type": "thị trấn",
                        "short_codename": "goi"
                    },
                    {
                        "name": "Xã Minh Thuận",
                        "code": 13744,
                        "codename": "xa_minh_thuan",
                        "division_type": "xã",
                        "short_codename": "minh_thuan"
                    },
                    {
                        "name": "Xã Hiển Khánh",
                        "code": 13747,
                        "codename": "xa_hien_khanh",
                        "division_type": "xã",
                        "short_codename": "hien_khanh"
                    },
                    {
                        "name": "Xã Tân Khánh",
                        "code": 13750,
                        "codename": "xa_tan_khanh",
                        "division_type": "xã",
                        "short_codename": "tan_khanh"
                    },
                    {
                        "name": "Xã Hợp Hưng",
                        "code": 13753,
                        "codename": "xa_hop_hung",
                        "division_type": "xã",
                        "short_codename": "hop_hung"
                    },
                    {
                        "name": "Xã Đại An",
                        "code": 13756,
                        "codename": "xa_dai_an",
                        "division_type": "xã",
                        "short_codename": "dai_an"
                    },
                    {
                        "name": "Xã Tân Thành",
                        "code": 13759,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Xã Cộng Hòa",
                        "code": 13762,
                        "codename": "xa_cong_hoa",
                        "division_type": "xã",
                        "short_codename": "cong_hoa"
                    },
                    {
                        "name": "Xã Trung Thành",
                        "code": 13765,
                        "codename": "xa_trung_thanh",
                        "division_type": "xã",
                        "short_codename": "trung_thanh"
                    },
                    {
                        "name": "Xã Quang Trung",
                        "code": 13768,
                        "codename": "xa_quang_trung",
                        "division_type": "xã",
                        "short_codename": "quang_trung"
                    },
                    {
                        "name": "Xã Minh Tân",
                        "code": 13771,
                        "codename": "xa_minh_tan",
                        "division_type": "xã",
                        "short_codename": "minh_tan"
                    },
                    {
                        "name": "Xã Liên Bảo",
                        "code": 13774,
                        "codename": "xa_lien_bao",
                        "division_type": "xã",
                        "short_codename": "lien_bao"
                    },
                    {
                        "name": "Xã Thành Lợi",
                        "code": 13777,
                        "codename": "xa_thanh_loi",
                        "division_type": "xã",
                        "short_codename": "thanh_loi"
                    },
                    {
                        "name": "Xã Kim Thái",
                        "code": 13780,
                        "codename": "xa_kim_thai",
                        "division_type": "xã",
                        "short_codename": "kim_thai"
                    },
                    {
                        "name": "Xã Liên Minh",
                        "code": 13783,
                        "codename": "xa_lien_minh",
                        "division_type": "xã",
                        "short_codename": "lien_minh"
                    },
                    {
                        "name": "Xã Đại Thắng",
                        "code": 13786,
                        "codename": "xa_dai_thang",
                        "division_type": "xã",
                        "short_codename": "dai_thang"
                    },
                    {
                        "name": "Xã Tam Thanh",
                        "code": 13789,
                        "codename": "xa_tam_thanh",
                        "division_type": "xã",
                        "short_codename": "tam_thanh"
                    },
                    {
                        "name": "Xã Vĩnh Hào",
                        "code": 13792,
                        "codename": "xa_vinh_hao",
                        "division_type": "xã",
                        "short_codename": "vinh_hao"
                    }
                ]
            },
            {
                "name": "Huyện Ý Yên",
                "code": 360,
                "codename": "huyen_y_yen",
                "division_type": "huyện",
                "short_codename": "y_yen",
                "wards": [
                    {
                        "name": "Thị trấn Lâm",
                        "code": 13795,
                        "codename": "thi_tran_lam",
                        "division_type": "thị trấn",
                        "short_codename": "lam"
                    },
                    {
                        "name": "Xã Yên Trung",
                        "code": 13798,
                        "codename": "xa_yen_trung",
                        "division_type": "xã",
                        "short_codename": "yen_trung"
                    },
                    {
                        "name": "Xã Yên Thành",
                        "code": 13801,
                        "codename": "xa_yen_thanh",
                        "division_type": "xã",
                        "short_codename": "yen_thanh"
                    },
                    {
                        "name": "Xã Yên Tân",
                        "code": 13804,
                        "codename": "xa_yen_tan",
                        "division_type": "xã",
                        "short_codename": "yen_tan"
                    },
                    {
                        "name": "Xã Yên Lợi",
                        "code": 13807,
                        "codename": "xa_yen_loi",
                        "division_type": "xã",
                        "short_codename": "yen_loi"
                    },
                    {
                        "name": "Xã Yên Thọ",
                        "code": 13810,
                        "codename": "xa_yen_tho",
                        "division_type": "xã",
                        "short_codename": "yen_tho"
                    },
                    {
                        "name": "Xã Yên Nghĩa",
                        "code": 13813,
                        "codename": "xa_yen_nghia",
                        "division_type": "xã",
                        "short_codename": "yen_nghia"
                    },
                    {
                        "name": "Xã Yên Minh",
                        "code": 13816,
                        "codename": "xa_yen_minh",
                        "division_type": "xã",
                        "short_codename": "yen_minh"
                    },
                    {
                        "name": "Xã Yên Phương",
                        "code": 13819,
                        "codename": "xa_yen_phuong",
                        "division_type": "xã",
                        "short_codename": "yen_phuong"
                    },
                    {
                        "name": "Xã Yên Chính",
                        "code": 13822,
                        "codename": "xa_yen_chinh",
                        "division_type": "xã",
                        "short_codename": "yen_chinh"
                    },
                    {
                        "name": "Xã Yên Bình",
                        "code": 13825,
                        "codename": "xa_yen_binh",
                        "division_type": "xã",
                        "short_codename": "yen_binh"
                    },
                    {
                        "name": "Xã Yên Phú",
                        "code": 13828,
                        "codename": "xa_yen_phu",
                        "division_type": "xã",
                        "short_codename": "yen_phu"
                    },
                    {
                        "name": "Xã Yên Mỹ",
                        "code": 13831,
                        "codename": "xa_yen_my",
                        "division_type": "xã",
                        "short_codename": "yen_my"
                    },
                    {
                        "name": "Xã Yên Dương",
                        "code": 13834,
                        "codename": "xa_yen_duong",
                        "division_type": "xã",
                        "short_codename": "yen_duong"
                    },
                    {
                        "name": "Xã Yên Hưng",
                        "code": 13840,
                        "codename": "xa_yen_hung",
                        "division_type": "xã",
                        "short_codename": "yen_hung"
                    },
                    {
                        "name": "Xã Yên Khánh",
                        "code": 13843,
                        "codename": "xa_yen_khanh",
                        "division_type": "xã",
                        "short_codename": "yen_khanh"
                    },
                    {
                        "name": "Xã Yên Phong",
                        "code": 13846,
                        "codename": "xa_yen_phong",
                        "division_type": "xã",
                        "short_codename": "yen_phong"
                    },
                    {
                        "name": "Xã Yên Ninh",
                        "code": 13849,
                        "codename": "xa_yen_ninh",
                        "division_type": "xã",
                        "short_codename": "yen_ninh"
                    },
                    {
                        "name": "Xã Yên Lương",
                        "code": 13852,
                        "codename": "xa_yen_luong",
                        "division_type": "xã",
                        "short_codename": "yen_luong"
                    },
                    {
                        "name": "Xã Yên Hồng",
                        "code": 13855,
                        "codename": "xa_yen_hong",
                        "division_type": "xã",
                        "short_codename": "yen_hong"
                    },
                    {
                        "name": "Xã Yên Quang",
                        "code": 13858,
                        "codename": "xa_yen_quang",
                        "division_type": "xã",
                        "short_codename": "yen_quang"
                    },
                    {
                        "name": "Xã Yên Tiến",
                        "code": 13861,
                        "codename": "xa_yen_tien",
                        "division_type": "xã",
                        "short_codename": "yen_tien"
                    },
                    {
                        "name": "Xã Yên Thắng",
                        "code": 13864,
                        "codename": "xa_yen_thang",
                        "division_type": "xã",
                        "short_codename": "yen_thang"
                    },
                    {
                        "name": "Xã Yên Phúc",
                        "code": 13867,
                        "codename": "xa_yen_phuc",
                        "division_type": "xã",
                        "short_codename": "yen_phuc"
                    },
                    {
                        "name": "Xã Yên Cường",
                        "code": 13870,
                        "codename": "xa_yen_cuong",
                        "division_type": "xã",
                        "short_codename": "yen_cuong"
                    },
                    {
                        "name": "Xã Yên Lộc",
                        "code": 13873,
                        "codename": "xa_yen_loc",
                        "division_type": "xã",
                        "short_codename": "yen_loc"
                    },
                    {
                        "name": "Xã Yên Bằng",
                        "code": 13876,
                        "codename": "xa_yen_bang",
                        "division_type": "xã",
                        "short_codename": "yen_bang"
                    },
                    {
                        "name": "Xã Yên Đồng",
                        "code": 13879,
                        "codename": "xa_yen_dong",
                        "division_type": "xã",
                        "short_codename": "yen_dong"
                    },
                    {
                        "name": "Xã Yên Khang",
                        "code": 13882,
                        "codename": "xa_yen_khang",
                        "division_type": "xã",
                        "short_codename": "yen_khang"
                    },
                    {
                        "name": "Xã Yên Nhân",
                        "code": 13885,
                        "codename": "xa_yen_nhan",
                        "division_type": "xã",
                        "short_codename": "yen_nhan"
                    },
                    {
                        "name": "Xã Yên Trị",
                        "code": 13888,
                        "codename": "xa_yen_tri",
                        "division_type": "xã",
                        "short_codename": "yen_tri"
                    }
                ]
            },
            {
                "name": "Huyện Nghĩa Hưng",
                "code": 361,
                "codename": "huyen_nghia_hung",
                "division_type": "huyện",
                "short_codename": "nghia_hung",
                "wards": [
                    {
                        "name": "Thị trấn Liễu Đề",
                        "code": 13891,
                        "codename": "thi_tran_lieu_de",
                        "division_type": "thị trấn",
                        "short_codename": "lieu_de"
                    },
                    {
                        "name": "Thị trấn Rạng Đông",
                        "code": 13894,
                        "codename": "thi_tran_rang_dong",
                        "division_type": "thị trấn",
                        "short_codename": "rang_dong"
                    },
                    {
                        "name": "Xã Nghĩa Đồng",
                        "code": 13897,
                        "codename": "xa_nghia_dong",
                        "division_type": "xã",
                        "short_codename": "nghia_dong"
                    },
                    {
                        "name": "Xã Nghĩa Thịnh",
                        "code": 13900,
                        "codename": "xa_nghia_thinh",
                        "division_type": "xã",
                        "short_codename": "nghia_thinh"
                    },
                    {
                        "name": "Xã Nghĩa Minh",
                        "code": 13903,
                        "codename": "xa_nghia_minh",
                        "division_type": "xã",
                        "short_codename": "nghia_minh"
                    },
                    {
                        "name": "Xã Nghĩa Thái",
                        "code": 13906,
                        "codename": "xa_nghia_thai",
                        "division_type": "xã",
                        "short_codename": "nghia_thai"
                    },
                    {
                        "name": "Xã Hoàng Nam",
                        "code": 13909,
                        "codename": "xa_hoang_nam",
                        "division_type": "xã",
                        "short_codename": "hoang_nam"
                    },
                    {
                        "name": "Xã Nghĩa Châu",
                        "code": 13912,
                        "codename": "xa_nghia_chau",
                        "division_type": "xã",
                        "short_codename": "nghia_chau"
                    },
                    {
                        "name": "Xã Nghĩa Trung",
                        "code": 13915,
                        "codename": "xa_nghia_trung",
                        "division_type": "xã",
                        "short_codename": "nghia_trung"
                    },
                    {
                        "name": "Xã Nghĩa Sơn",
                        "code": 13918,
                        "codename": "xa_nghia_son",
                        "division_type": "xã",
                        "short_codename": "nghia_son"
                    },
                    {
                        "name": "Xã Nghĩa Lạc",
                        "code": 13921,
                        "codename": "xa_nghia_lac",
                        "division_type": "xã",
                        "short_codename": "nghia_lac"
                    },
                    {
                        "name": "Xã Nghĩa Hồng",
                        "code": 13924,
                        "codename": "xa_nghia_hong",
                        "division_type": "xã",
                        "short_codename": "nghia_hong"
                    },
                    {
                        "name": "Xã Nghĩa Phong",
                        "code": 13927,
                        "codename": "xa_nghia_phong",
                        "division_type": "xã",
                        "short_codename": "nghia_phong"
                    },
                    {
                        "name": "Xã Nghĩa Phú",
                        "code": 13930,
                        "codename": "xa_nghia_phu",
                        "division_type": "xã",
                        "short_codename": "nghia_phu"
                    },
                    {
                        "name": "Xã Nghĩa Bình",
                        "code": 13933,
                        "codename": "xa_nghia_binh",
                        "division_type": "xã",
                        "short_codename": "nghia_binh"
                    },
                    {
                        "name": "Thị trấn Quỹ Nhất",
                        "code": 13936,
                        "codename": "thi_tran_quy_nhat",
                        "division_type": "thị trấn",
                        "short_codename": "quy_nhat"
                    },
                    {
                        "name": "Xã Nghĩa Tân",
                        "code": 13939,
                        "codename": "xa_nghia_tan",
                        "division_type": "xã",
                        "short_codename": "nghia_tan"
                    },
                    {
                        "name": "Xã Nghĩa Hùng",
                        "code": 13942,
                        "codename": "xa_nghia_hung",
                        "division_type": "xã",
                        "short_codename": "nghia_hung"
                    },
                    {
                        "name": "Xã Nghĩa Lâm",
                        "code": 13945,
                        "codename": "xa_nghia_lam",
                        "division_type": "xã",
                        "short_codename": "nghia_lam"
                    },
                    {
                        "name": "Xã Nghĩa Thành",
                        "code": 13948,
                        "codename": "xa_nghia_thanh",
                        "division_type": "xã",
                        "short_codename": "nghia_thanh"
                    },
                    {
                        "name": "Xã Phúc Thắng",
                        "code": 13951,
                        "codename": "xa_phuc_thang",
                        "division_type": "xã",
                        "short_codename": "phuc_thang"
                    },
                    {
                        "name": "Xã Nghĩa Lợi",
                        "code": 13954,
                        "codename": "xa_nghia_loi",
                        "division_type": "xã",
                        "short_codename": "nghia_loi"
                    },
                    {
                        "name": "Xã Nghĩa Hải",
                        "code": 13957,
                        "codename": "xa_nghia_hai",
                        "division_type": "xã",
                        "short_codename": "nghia_hai"
                    },
                    {
                        "name": "Xã Nam Điền",
                        "code": 13963,
                        "codename": "xa_nam_dien",
                        "division_type": "xã",
                        "short_codename": "nam_dien"
                    }
                ]
            },
            {
                "name": "Huyện Nam Trực",
                "code": 362,
                "codename": "huyen_nam_truc",
                "division_type": "huyện",
                "short_codename": "nam_truc",
                "wards": [
                    {
                        "name": "Thị trấn Nam Giang",
                        "code": 13966,
                        "codename": "thi_tran_nam_giang",
                        "division_type": "thị trấn",
                        "short_codename": "nam_giang"
                    },
                    {
                        "name": "Xã Nam Mỹ",
                        "code": 13969,
                        "codename": "xa_nam_my",
                        "division_type": "xã",
                        "short_codename": "nam_my"
                    },
                    {
                        "name": "Xã Điền Xá",
                        "code": 13972,
                        "codename": "xa_dien_xa",
                        "division_type": "xã",
                        "short_codename": "dien_xa"
                    },
                    {
                        "name": "Xã Nghĩa An",
                        "code": 13975,
                        "codename": "xa_nghia_an",
                        "division_type": "xã",
                        "short_codename": "nghia_an"
                    },
                    {
                        "name": "Xã Nam Thắng",
                        "code": 13978,
                        "codename": "xa_nam_thang",
                        "division_type": "xã",
                        "short_codename": "nam_thang"
                    },
                    {
                        "name": "Xã Nam Toàn",
                        "code": 13981,
                        "codename": "xa_nam_toan",
                        "division_type": "xã",
                        "short_codename": "nam_toan"
                    },
                    {
                        "name": "Xã Hồng Quang",
                        "code": 13984,
                        "codename": "xa_hong_quang",
                        "division_type": "xã",
                        "short_codename": "hong_quang"
                    },
                    {
                        "name": "Xã Tân Thịnh",
                        "code": 13987,
                        "codename": "xa_tan_thinh",
                        "division_type": "xã",
                        "short_codename": "tan_thinh"
                    },
                    {
                        "name": "Xã Nam Cường",
                        "code": 13990,
                        "codename": "xa_nam_cuong",
                        "division_type": "xã",
                        "short_codename": "nam_cuong"
                    },
                    {
                        "name": "Xã Nam Hồng",
                        "code": 13993,
                        "codename": "xa_nam_hong",
                        "division_type": "xã",
                        "short_codename": "nam_hong"
                    },
                    {
                        "name": "Xã Nam Hùng",
                        "code": 13996,
                        "codename": "xa_nam_hung",
                        "division_type": "xã",
                        "short_codename": "nam_hung"
                    },
                    {
                        "name": "Xã Nam Hoa",
                        "code": 13999,
                        "codename": "xa_nam_hoa",
                        "division_type": "xã",
                        "short_codename": "nam_hoa"
                    },
                    {
                        "name": "Xã Nam Dương",
                        "code": 14002,
                        "codename": "xa_nam_duong",
                        "division_type": "xã",
                        "short_codename": "nam_duong"
                    },
                    {
                        "name": "Xã Nam Thanh",
                        "code": 14005,
                        "codename": "xa_nam_thanh",
                        "division_type": "xã",
                        "short_codename": "nam_thanh"
                    },
                    {
                        "name": "Xã Nam Lợi",
                        "code": 14008,
                        "codename": "xa_nam_loi",
                        "division_type": "xã",
                        "short_codename": "nam_loi"
                    },
                    {
                        "name": "Xã Bình Minh",
                        "code": 14011,
                        "codename": "xa_binh_minh",
                        "division_type": "xã",
                        "short_codename": "binh_minh"
                    },
                    {
                        "name": "Xã Đồng Sơn",
                        "code": 14014,
                        "codename": "xa_dong_son",
                        "division_type": "xã",
                        "short_codename": "dong_son"
                    },
                    {
                        "name": "Xã Nam Tiến",
                        "code": 14017,
                        "codename": "xa_nam_tien",
                        "division_type": "xã",
                        "short_codename": "nam_tien"
                    },
                    {
                        "name": "Xã Nam Hải",
                        "code": 14020,
                        "codename": "xa_nam_hai",
                        "division_type": "xã",
                        "short_codename": "nam_hai"
                    },
                    {
                        "name": "Xã Nam Thái",
                        "code": 14023,
                        "codename": "xa_nam_thai",
                        "division_type": "xã",
                        "short_codename": "nam_thai"
                    }
                ]
            },
            {
                "name": "Huyện Trực Ninh",
                "code": 363,
                "codename": "huyen_truc_ninh",
                "division_type": "huyện",
                "short_codename": "truc_ninh",
                "wards": [
                    {
                        "name": "Thị trấn Cổ Lễ",
                        "code": 14026,
                        "codename": "thi_tran_co_le",
                        "division_type": "thị trấn",
                        "short_codename": "co_le"
                    },
                    {
                        "name": "Xã Phương Định",
                        "code": 14029,
                        "codename": "xa_phuong_dinh",
                        "division_type": "xã",
                        "short_codename": "phuong_dinh"
                    },
                    {
                        "name": "Xã Trực Chính",
                        "code": 14032,
                        "codename": "xa_truc_chinh",
                        "division_type": "xã",
                        "short_codename": "truc_chinh"
                    },
                    {
                        "name": "Xã Trung Đông",
                        "code": 14035,
                        "codename": "xa_trung_dong",
                        "division_type": "xã",
                        "short_codename": "trung_dong"
                    },
                    {
                        "name": "Xã Liêm Hải",
                        "code": 14038,
                        "codename": "xa_liem_hai",
                        "division_type": "xã",
                        "short_codename": "liem_hai"
                    },
                    {
                        "name": "Xã Trực Tuấn",
                        "code": 14041,
                        "codename": "xa_truc_tuan",
                        "division_type": "xã",
                        "short_codename": "truc_tuan"
                    },
                    {
                        "name": "Xã Việt Hùng",
                        "code": 14044,
                        "codename": "xa_viet_hung",
                        "division_type": "xã",
                        "short_codename": "viet_hung"
                    },
                    {
                        "name": "Xã Trực Đạo",
                        "code": 14047,
                        "codename": "xa_truc_dao",
                        "division_type": "xã",
                        "short_codename": "truc_dao"
                    },
                    {
                        "name": "Xã Trực Hưng",
                        "code": 14050,
                        "codename": "xa_truc_hung",
                        "division_type": "xã",
                        "short_codename": "xa_truc_hung"
                    },
                    {
                        "name": "Xã Trực Nội",
                        "code": 14053,
                        "codename": "xa_truc_noi",
                        "division_type": "xã",
                        "short_codename": "truc_noi"
                    },
                    {
                        "name": "Thị trấn Cát Thành",
                        "code": 14056,
                        "codename": "thi_tran_cat_thanh",
                        "division_type": "thị trấn",
                        "short_codename": "cat_thanh"
                    },
                    {
                        "name": "Xã Trực Thanh",
                        "code": 14059,
                        "codename": "xa_truc_thanh",
                        "division_type": "xã",
                        "short_codename": "truc_thanh"
                    },
                    {
                        "name": "Xã Trực Khang",
                        "code": 14062,
                        "codename": "xa_truc_khang",
                        "division_type": "xã",
                        "short_codename": "truc_khang"
                    },
                    {
                        "name": "Xã Trực Thuận",
                        "code": 14065,
                        "codename": "xa_truc_thuan",
                        "division_type": "xã",
                        "short_codename": "truc_thuan"
                    },
                    {
                        "name": "Xã Trực Mỹ",
                        "code": 14068,
                        "codename": "xa_truc_my",
                        "division_type": "xã",
                        "short_codename": "truc_my"
                    },
                    {
                        "name": "Xã Trực Đại",
                        "code": 14071,
                        "codename": "xa_truc_dai",
                        "division_type": "xã",
                        "short_codename": "truc_dai"
                    },
                    {
                        "name": "Xã Trực Cường",
                        "code": 14074,
                        "codename": "xa_truc_cuong",
                        "division_type": "xã",
                        "short_codename": "truc_cuong"
                    },
                    {
                        "name": "Thị trấn Ninh Cường",
                        "code": 14077,
                        "codename": "thi_tran_ninh_cuong",
                        "division_type": "thị trấn",
                        "short_codename": "ninh_cuong"
                    },
                    {
                        "name": "Xã Trực Thái",
                        "code": 14080,
                        "codename": "xa_truc_thai",
                        "division_type": "xã",
                        "short_codename": "truc_thai"
                    },
                    {
                        "name": "Xã Trực Hùng",
                        "code": 14083,
                        "codename": "xa_truc_hung",
                        "division_type": "xã",
                        "short_codename": "xa_truc_hung"
                    },
                    {
                        "name": "Xã Trực Thắng",
                        "code": 14086,
                        "codename": "xa_truc_thang",
                        "division_type": "xã",
                        "short_codename": "truc_thang"
                    }
                ]
            },
            {
                "name": "Huyện Xuân Trường",
                "code": 364,
                "codename": "huyen_xuan_truong",
                "division_type": "huyện",
                "short_codename": "xuan_truong",
                "wards": [
                    {
                        "name": "Thị trấn Xuân Trường",
                        "code": 14089,
                        "codename": "thi_tran_xuan_truong",
                        "division_type": "thị trấn",
                        "short_codename": "xuan_truong"
                    },
                    {
                        "name": "Xã Xuân Châu",
                        "code": 14092,
                        "codename": "xa_xuan_chau",
                        "division_type": "xã",
                        "short_codename": "xuan_chau"
                    },
                    {
                        "name": "Xã Xuân Hồng",
                        "code": 14095,
                        "codename": "xa_xuan_hong",
                        "division_type": "xã",
                        "short_codename": "xuan_hong"
                    },
                    {
                        "name": "Xã Xuân Thành",
                        "code": 14098,
                        "codename": "xa_xuan_thanh",
                        "division_type": "xã",
                        "short_codename": "xuan_thanh"
                    },
                    {
                        "name": "Xã Xuân Thượng",
                        "code": 14101,
                        "codename": "xa_xuan_thuong",
                        "division_type": "xã",
                        "short_codename": "xuan_thuong"
                    },
                    {
                        "name": "Xã Xuân Phong",
                        "code": 14104,
                        "codename": "xa_xuan_phong",
                        "division_type": "xã",
                        "short_codename": "xuan_phong"
                    },
                    {
                        "name": "Xã Xuân Đài",
                        "code": 14107,
                        "codename": "xa_xuan_dai",
                        "division_type": "xã",
                        "short_codename": "xuan_dai"
                    },
                    {
                        "name": "Xã Xuân Tân",
                        "code": 14110,
                        "codename": "xa_xuan_tan",
                        "division_type": "xã",
                        "short_codename": "xuan_tan"
                    },
                    {
                        "name": "Xã Xuân Thủy",
                        "code": 14113,
                        "codename": "xa_xuan_thuy",
                        "division_type": "xã",
                        "short_codename": "xuan_thuy"
                    },
                    {
                        "name": "Xã Xuân Ngọc",
                        "code": 14116,
                        "codename": "xa_xuan_ngoc",
                        "division_type": "xã",
                        "short_codename": "xuan_ngoc"
                    },
                    {
                        "name": "Xã Xuân Bắc",
                        "code": 14119,
                        "codename": "xa_xuan_bac",
                        "division_type": "xã",
                        "short_codename": "xuan_bac"
                    },
                    {
                        "name": "Xã Xuân Phương",
                        "code": 14122,
                        "codename": "xa_xuan_phuong",
                        "division_type": "xã",
                        "short_codename": "xuan_phuong"
                    },
                    {
                        "name": "Xã Thọ Nghiệp",
                        "code": 14125,
                        "codename": "xa_tho_nghiep",
                        "division_type": "xã",
                        "short_codename": "tho_nghiep"
                    },
                    {
                        "name": "Xã Xuân Phú",
                        "code": 14128,
                        "codename": "xa_xuan_phu",
                        "division_type": "xã",
                        "short_codename": "xuan_phu"
                    },
                    {
                        "name": "Xã Xuân Trung",
                        "code": 14131,
                        "codename": "xa_xuan_trung",
                        "division_type": "xã",
                        "short_codename": "xuan_trung"
                    },
                    {
                        "name": "Xã Xuân Vinh",
                        "code": 14134,
                        "codename": "xa_xuan_vinh",
                        "division_type": "xã",
                        "short_codename": "xuan_vinh"
                    },
                    {
                        "name": "Xã Xuân Kiên",
                        "code": 14137,
                        "codename": "xa_xuan_kien",
                        "division_type": "xã",
                        "short_codename": "xuan_kien"
                    },
                    {
                        "name": "Xã Xuân Tiến",
                        "code": 14140,
                        "codename": "xa_xuan_tien",
                        "division_type": "xã",
                        "short_codename": "xuan_tien"
                    },
                    {
                        "name": "Xã Xuân Ninh",
                        "code": 14143,
                        "codename": "xa_xuan_ninh",
                        "division_type": "xã",
                        "short_codename": "xuan_ninh"
                    },
                    {
                        "name": "Xã Xuân Hòa",
                        "code": 14146,
                        "codename": "xa_xuan_hoa",
                        "division_type": "xã",
                        "short_codename": "xuan_hoa"
                    }
                ]
            },
            {
                "name": "Huyện Giao Thủy",
                "code": 365,
                "codename": "huyen_giao_thuy",
                "division_type": "huyện",
                "short_codename": "giao_thuy",
                "wards": [
                    {
                        "name": "Thị trấn Ngô Đồng",
                        "code": 14149,
                        "codename": "thi_tran_ngo_dong",
                        "division_type": "thị trấn",
                        "short_codename": "ngo_dong"
                    },
                    {
                        "name": "Thị trấn Quất Lâm",
                        "code": 14152,
                        "codename": "thi_tran_quat_lam",
                        "division_type": "thị trấn",
                        "short_codename": "quat_lam"
                    },
                    {
                        "name": "Xã Giao Hương",
                        "code": 14155,
                        "codename": "xa_giao_huong",
                        "division_type": "xã",
                        "short_codename": "giao_huong"
                    },
                    {
                        "name": "Xã Hồng Thuận",
                        "code": 14158,
                        "codename": "xa_hong_thuan",
                        "division_type": "xã",
                        "short_codename": "hong_thuan"
                    },
                    {
                        "name": "Xã Giao Thiện",
                        "code": 14161,
                        "codename": "xa_giao_thien",
                        "division_type": "xã",
                        "short_codename": "giao_thien"
                    },
                    {
                        "name": "Xã Giao Thanh",
                        "code": 14164,
                        "codename": "xa_giao_thanh",
                        "division_type": "xã",
                        "short_codename": "giao_thanh"
                    },
                    {
                        "name": "Xã Hoành Sơn",
                        "code": 14167,
                        "codename": "xa_hoanh_son",
                        "division_type": "xã",
                        "short_codename": "hoanh_son"
                    },
                    {
                        "name": "Xã Bình Hòa",
                        "code": 14170,
                        "codename": "xa_binh_hoa",
                        "division_type": "xã",
                        "short_codename": "binh_hoa"
                    },
                    {
                        "name": "Xã Giao Tiến",
                        "code": 14173,
                        "codename": "xa_giao_tien",
                        "division_type": "xã",
                        "short_codename": "giao_tien"
                    },
                    {
                        "name": "Xã Giao Hà",
                        "code": 14176,
                        "codename": "xa_giao_ha",
                        "division_type": "xã",
                        "short_codename": "giao_ha"
                    },
                    {
                        "name": "Xã Giao Nhân",
                        "code": 14179,
                        "codename": "xa_giao_nhan",
                        "division_type": "xã",
                        "short_codename": "giao_nhan"
                    },
                    {
                        "name": "Xã Giao An",
                        "code": 14182,
                        "codename": "xa_giao_an",
                        "division_type": "xã",
                        "short_codename": "giao_an"
                    },
                    {
                        "name": "Xã Giao Lạc",
                        "code": 14185,
                        "codename": "xa_giao_lac",
                        "division_type": "xã",
                        "short_codename": "giao_lac"
                    },
                    {
                        "name": "Xã Giao Châu",
                        "code": 14188,
                        "codename": "xa_giao_chau",
                        "division_type": "xã",
                        "short_codename": "giao_chau"
                    },
                    {
                        "name": "Xã Giao Tân",
                        "code": 14191,
                        "codename": "xa_giao_tan",
                        "division_type": "xã",
                        "short_codename": "giao_tan"
                    },
                    {
                        "name": "Xã Giao Yến",
                        "code": 14194,
                        "codename": "xa_giao_yen",
                        "division_type": "xã",
                        "short_codename": "giao_yen"
                    },
                    {
                        "name": "Xã Giao Xuân",
                        "code": 14197,
                        "codename": "xa_giao_xuan",
                        "division_type": "xã",
                        "short_codename": "giao_xuan"
                    },
                    {
                        "name": "Xã Giao Thịnh",
                        "code": 14200,
                        "codename": "xa_giao_thinh",
                        "division_type": "xã",
                        "short_codename": "giao_thinh"
                    },
                    {
                        "name": "Xã Giao Hải",
                        "code": 14203,
                        "codename": "xa_giao_hai",
                        "division_type": "xã",
                        "short_codename": "giao_hai"
                    },
                    {
                        "name": "Xã Bạch Long",
                        "code": 14206,
                        "codename": "xa_bach_long",
                        "division_type": "xã",
                        "short_codename": "bach_long"
                    },
                    {
                        "name": "Xã Giao Long",
                        "code": 14209,
                        "codename": "xa_giao_long",
                        "division_type": "xã",
                        "short_codename": "giao_long"
                    },
                    {
                        "name": "Xã Giao Phong",
                        "code": 14212,
                        "codename": "xa_giao_phong",
                        "division_type": "xã",
                        "short_codename": "giao_phong"
                    }
                ]
            },
            {
                "name": "Huyện Hải Hậu",
                "code": 366,
                "codename": "huyen_hai_hau",
                "division_type": "huyện",
                "short_codename": "hai_hau",
                "wards": [
                    {
                        "name": "Thị trấn Yên Định",
                        "code": 14215,
                        "codename": "thi_tran_yen_dinh",
                        "division_type": "thị trấn",
                        "short_codename": "yen_dinh"
                    },
                    {
                        "name": "Thị trấn Cồn",
                        "code": 14218,
                        "codename": "thi_tran_con",
                        "division_type": "thị trấn",
                        "short_codename": "con"
                    },
                    {
                        "name": "Thị trấn Thịnh Long",
                        "code": 14221,
                        "codename": "thi_tran_thinh_long",
                        "division_type": "thị trấn",
                        "short_codename": "thinh_long"
                    },
                    {
                        "name": "Xã Hải Nam",
                        "code": 14224,
                        "codename": "xa_hai_nam",
                        "division_type": "xã",
                        "short_codename": "hai_nam"
                    },
                    {
                        "name": "Xã Hải Trung",
                        "code": 14227,
                        "codename": "xa_hai_trung",
                        "division_type": "xã",
                        "short_codename": "hai_trung"
                    },
                    {
                        "name": "Xã Hải Vân",
                        "code": 14230,
                        "codename": "xa_hai_van",
                        "division_type": "xã",
                        "short_codename": "hai_van"
                    },
                    {
                        "name": "Xã Hải Minh",
                        "code": 14233,
                        "codename": "xa_hai_minh",
                        "division_type": "xã",
                        "short_codename": "hai_minh"
                    },
                    {
                        "name": "Xã Hải Anh",
                        "code": 14236,
                        "codename": "xa_hai_anh",
                        "division_type": "xã",
                        "short_codename": "hai_anh"
                    },
                    {
                        "name": "Xã Hải Hưng",
                        "code": 14239,
                        "codename": "xa_hai_hung",
                        "division_type": "xã",
                        "short_codename": "hai_hung"
                    },
                    {
                        "name": "Xã Hải Bắc",
                        "code": 14242,
                        "codename": "xa_hai_bac",
                        "division_type": "xã",
                        "short_codename": "hai_bac"
                    },
                    {
                        "name": "Xã Hải Phúc",
                        "code": 14245,
                        "codename": "xa_hai_phuc",
                        "division_type": "xã",
                        "short_codename": "hai_phuc"
                    },
                    {
                        "name": "Xã Hải Thanh",
                        "code": 14248,
                        "codename": "xa_hai_thanh",
                        "division_type": "xã",
                        "short_codename": "hai_thanh"
                    },
                    {
                        "name": "Xã Hải Hà",
                        "code": 14251,
                        "codename": "xa_hai_ha",
                        "division_type": "xã",
                        "short_codename": "hai_ha"
                    },
                    {
                        "name": "Xã Hải Long",
                        "code": 14254,
                        "codename": "xa_hai_long",
                        "division_type": "xã",
                        "short_codename": "hai_long"
                    },
                    {
                        "name": "Xã Hải Phương",
                        "code": 14257,
                        "codename": "xa_hai_phuong",
                        "division_type": "xã",
                        "short_codename": "hai_phuong"
                    },
                    {
                        "name": "Xã Hải Đường",
                        "code": 14260,
                        "codename": "xa_hai_duong",
                        "division_type": "xã",
                        "short_codename": "hai_duong"
                    },
                    {
                        "name": "Xã Hải Lộc",
                        "code": 14263,
                        "codename": "xa_hai_loc",
                        "division_type": "xã",
                        "short_codename": "hai_loc"
                    },
                    {
                        "name": "Xã Hải Quang",
                        "code": 14266,
                        "codename": "xa_hai_quang",
                        "division_type": "xã",
                        "short_codename": "hai_quang"
                    },
                    {
                        "name": "Xã Hải Đông",
                        "code": 14269,
                        "codename": "xa_hai_dong",
                        "division_type": "xã",
                        "short_codename": "hai_dong"
                    },
                    {
                        "name": "Xã Hải Sơn",
                        "code": 14272,
                        "codename": "xa_hai_son",
                        "division_type": "xã",
                        "short_codename": "hai_son"
                    },
                    {
                        "name": "Xã Hải Tân",
                        "code": 14275,
                        "codename": "xa_hai_tan",
                        "division_type": "xã",
                        "short_codename": "hai_tan"
                    },
                    {
                        "name": "Xã Hải Phong",
                        "code": 14281,
                        "codename": "xa_hai_phong",
                        "division_type": "xã",
                        "short_codename": "hai_phong"
                    },
                    {
                        "name": "Xã Hải An",
                        "code": 14284,
                        "codename": "xa_hai_an",
                        "division_type": "xã",
                        "short_codename": "hai_an"
                    },
                    {
                        "name": "Xã Hải Tây",
                        "code": 14287,
                        "codename": "xa_hai_tay",
                        "division_type": "xã",
                        "short_codename": "hai_tay"
                    },
                    {
                        "name": "Xã Hải Lý",
                        "code": 14290,
                        "codename": "xa_hai_ly",
                        "division_type": "xã",
                        "short_codename": "hai_ly"
                    },
                    {
                        "name": "Xã Hải Phú",
                        "code": 14293,
                        "codename": "xa_hai_phu",
                        "division_type": "xã",
                        "short_codename": "hai_phu"
                    },
                    {
                        "name": "Xã Hải Giang",
                        "code": 14296,
                        "codename": "xa_hai_giang",
                        "division_type": "xã",
                        "short_codename": "hai_giang"
                    },
                    {
                        "name": "Xã Hải Cường",
                        "code": 14299,
                        "codename": "xa_hai_cuong",
                        "division_type": "xã",
                        "short_codename": "hai_cuong"
                    },
                    {
                        "name": "Xã Hải Ninh",
                        "code": 14302,
                        "codename": "xa_hai_ninh",
                        "division_type": "xã",
                        "short_codename": "hai_ninh"
                    },
                    {
                        "name": "Xã Hải Chính",
                        "code": 14305,
                        "codename": "xa_hai_chinh",
                        "division_type": "xã",
                        "short_codename": "hai_chinh"
                    },
                    {
                        "name": "Xã Hải Xuân",
                        "code": 14308,
                        "codename": "xa_hai_xuan",
                        "division_type": "xã",
                        "short_codename": "hai_xuan"
                    },
                    {
                        "name": "Xã Hải Châu",
                        "code": 14311,
                        "codename": "xa_hai_chau",
                        "division_type": "xã",
                        "short_codename": "hai_chau"
                    },
                    {
                        "name": "Xã Hải Triều",
                        "code": 14314,
                        "codename": "xa_hai_trieu",
                        "division_type": "xã",
                        "short_codename": "hai_trieu"
                    },
                    {
                        "name": "Xã Hải Hòa",
                        "code": 14317,
                        "codename": "xa_hai_hoa",
                        "division_type": "xã",
                        "short_codename": "hai_hoa"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Ninh Bình",
        "code": 37,
        "codename": "tinh_ninh_binh",
        "division_type": "tỉnh",
        "phone_code": 229,
        "districts": [
            {
                "name": "Thành phố Ninh Bình",
                "code": 369,
                "codename": "thanh_pho_ninh_binh",
                "division_type": "thành phố",
                "short_codename": "ninh_binh",
                "wards": [
                    {
                        "name": "Phường Đông Thành",
                        "code": 14320,
                        "codename": "phuong_dong_thanh",
                        "division_type": "phường",
                        "short_codename": "dong_thanh"
                    },
                    {
                        "name": "Phường Tân Thành",
                        "code": 14323,
                        "codename": "phuong_tan_thanh",
                        "division_type": "phường",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Phường Thanh Bình",
                        "code": 14326,
                        "codename": "phuong_thanh_binh",
                        "division_type": "phường",
                        "short_codename": "thanh_binh"
                    },
                    {
                        "name": "Phường Vân Giang",
                        "code": 14329,
                        "codename": "phuong_van_giang",
                        "division_type": "phường",
                        "short_codename": "van_giang"
                    },
                    {
                        "name": "Phường Bích Đào",
                        "code": 14332,
                        "codename": "phuong_bich_dao",
                        "division_type": "phường",
                        "short_codename": "bich_dao"
                    },
                    {
                        "name": "Phường Phúc Thành",
                        "code": 14335,
                        "codename": "phuong_phuc_thanh",
                        "division_type": "phường",
                        "short_codename": "phuc_thanh"
                    },
                    {
                        "name": "Phường Nam Bình",
                        "code": 14338,
                        "codename": "phuong_nam_binh",
                        "division_type": "phường",
                        "short_codename": "nam_binh"
                    },
                    {
                        "name": "Phường Nam Thành",
                        "code": 14341,
                        "codename": "phuong_nam_thanh",
                        "division_type": "phường",
                        "short_codename": "nam_thanh"
                    },
                    {
                        "name": "Phường Ninh Khánh",
                        "code": 14344,
                        "codename": "phuong_ninh_khanh",
                        "division_type": "phường",
                        "short_codename": "ninh_khanh"
                    },
                    {
                        "name": "Xã Ninh Nhất",
                        "code": 14347,
                        "codename": "xa_ninh_nhat",
                        "division_type": "xã",
                        "short_codename": "ninh_nhat"
                    },
                    {
                        "name": "Xã Ninh Tiến",
                        "code": 14350,
                        "codename": "xa_ninh_tien",
                        "division_type": "xã",
                        "short_codename": "ninh_tien"
                    },
                    {
                        "name": "Xã Ninh Phúc",
                        "code": 14353,
                        "codename": "xa_ninh_phuc",
                        "division_type": "xã",
                        "short_codename": "ninh_phuc"
                    },
                    {
                        "name": "Phường Ninh Sơn",
                        "code": 14356,
                        "codename": "phuong_ninh_son",
                        "division_type": "phường",
                        "short_codename": "ninh_son"
                    },
                    {
                        "name": "Phường Ninh Phong",
                        "code": 14359,
                        "codename": "phuong_ninh_phong",
                        "division_type": "phường",
                        "short_codename": "ninh_phong"
                    }
                ]
            },
            {
                "name": "Thành phố Tam Điệp",
                "code": 370,
                "codename": "thanh_pho_tam_diep",
                "division_type": "thành phố",
                "short_codename": "tam_diep",
                "wards": [
                    {
                        "name": "Phường Bắc Sơn",
                        "code": 14362,
                        "codename": "phuong_bac_son",
                        "division_type": "phường",
                        "short_codename": "bac_son"
                    },
                    {
                        "name": "Phường Trung Sơn",
                        "code": 14365,
                        "codename": "phuong_trung_son",
                        "division_type": "phường",
                        "short_codename": "trung_son"
                    },
                    {
                        "name": "Phường Nam Sơn",
                        "code": 14368,
                        "codename": "phuong_nam_son",
                        "division_type": "phường",
                        "short_codename": "nam_son"
                    },
                    {
                        "name": "Phường Tây Sơn",
                        "code": 14369,
                        "codename": "phuong_tay_son",
                        "division_type": "phường",
                        "short_codename": "tay_son"
                    },
                    {
                        "name": "Xã Yên Sơn",
                        "code": 14371,
                        "codename": "xa_yen_son",
                        "division_type": "xã",
                        "short_codename": "yen_son"
                    },
                    {
                        "name": "Phường Yên Bình",
                        "code": 14374,
                        "codename": "phuong_yen_binh",
                        "division_type": "phường",
                        "short_codename": "yen_binh"
                    },
                    {
                        "name": "Phường Tân Bình",
                        "code": 14375,
                        "codename": "phuong_tan_binh",
                        "division_type": "phường",
                        "short_codename": "tan_binh"
                    },
                    {
                        "name": "Xã Quang Sơn",
                        "code": 14377,
                        "codename": "xa_quang_son",
                        "division_type": "xã",
                        "short_codename": "quang_son"
                    },
                    {
                        "name": "Xã Đông Sơn",
                        "code": 14380,
                        "codename": "xa_dong_son",
                        "division_type": "xã",
                        "short_codename": "dong_son"
                    }
                ]
            },
            {
                "name": "Huyện Nho Quan",
                "code": 372,
                "codename": "huyen_nho_quan",
                "division_type": "huyện",
                "short_codename": "nho_quan",
                "wards": [
                    {
                        "name": "Thị trấn Nho Quan",
                        "code": 14383,
                        "codename": "thi_tran_nho_quan",
                        "division_type": "thị trấn",
                        "short_codename": "nho_quan"
                    },
                    {
                        "name": "Xã Xích Thổ",
                        "code": 14386,
                        "codename": "xa_xich_tho",
                        "division_type": "xã",
                        "short_codename": "xich_tho"
                    },
                    {
                        "name": "Xã Gia Lâm",
                        "code": 14389,
                        "codename": "xa_gia_lam",
                        "division_type": "xã",
                        "short_codename": "gia_lam"
                    },
                    {
                        "name": "Xã Gia Sơn",
                        "code": 14392,
                        "codename": "xa_gia_son",
                        "division_type": "xã",
                        "short_codename": "gia_son"
                    },
                    {
                        "name": "Xã Thạch Bình",
                        "code": 14395,
                        "codename": "xa_thach_binh",
                        "division_type": "xã",
                        "short_codename": "thach_binh"
                    },
                    {
                        "name": "Xã Gia Thủy",
                        "code": 14398,
                        "codename": "xa_gia_thuy",
                        "division_type": "xã",
                        "short_codename": "gia_thuy"
                    },
                    {
                        "name": "Xã Gia Tường",
                        "code": 14401,
                        "codename": "xa_gia_tuong",
                        "division_type": "xã",
                        "short_codename": "gia_tuong"
                    },
                    {
                        "name": "Xã Cúc Phương",
                        "code": 14404,
                        "codename": "xa_cuc_phuong",
                        "division_type": "xã",
                        "short_codename": "cuc_phuong"
                    },
                    {
                        "name": "Xã Phú Sơn",
                        "code": 14407,
                        "codename": "xa_phu_son",
                        "division_type": "xã",
                        "short_codename": "phu_son"
                    },
                    {
                        "name": "Xã Đức Long",
                        "code": 14410,
                        "codename": "xa_duc_long",
                        "division_type": "xã",
                        "short_codename": "duc_long"
                    },
                    {
                        "name": "Xã Lạc Vân",
                        "code": 14413,
                        "codename": "xa_lac_van",
                        "division_type": "xã",
                        "short_codename": "lac_van"
                    },
                    {
                        "name": "Xã Đồng Phong",
                        "code": 14416,
                        "codename": "xa_dong_phong",
                        "division_type": "xã",
                        "short_codename": "dong_phong"
                    },
                    {
                        "name": "Xã Yên Quang",
                        "code": 14419,
                        "codename": "xa_yen_quang",
                        "division_type": "xã",
                        "short_codename": "yen_quang"
                    },
                    {
                        "name": "Xã Lạng Phong",
                        "code": 14422,
                        "codename": "xa_lang_phong",
                        "division_type": "xã",
                        "short_codename": "lang_phong"
                    },
                    {
                        "name": "Xã Thượng Hòa",
                        "code": 14425,
                        "codename": "xa_thuong_hoa",
                        "division_type": "xã",
                        "short_codename": "thuong_hoa"
                    },
                    {
                        "name": "Xã Văn Phong",
                        "code": 14428,
                        "codename": "xa_van_phong",
                        "division_type": "xã",
                        "short_codename": "van_phong"
                    },
                    {
                        "name": "Xã Văn Phương",
                        "code": 14431,
                        "codename": "xa_van_phuong",
                        "division_type": "xã",
                        "short_codename": "van_phuong"
                    },
                    {
                        "name": "Xã Thanh Lạc",
                        "code": 14434,
                        "codename": "xa_thanh_lac",
                        "division_type": "xã",
                        "short_codename": "thanh_lac"
                    },
                    {
                        "name": "Xã Sơn Lai",
                        "code": 14437,
                        "codename": "xa_son_lai",
                        "division_type": "xã",
                        "short_codename": "son_lai"
                    },
                    {
                        "name": "Xã Sơn Thành",
                        "code": 14440,
                        "codename": "xa_son_thanh",
                        "division_type": "xã",
                        "short_codename": "son_thanh"
                    },
                    {
                        "name": "Xã Văn Phú",
                        "code": 14443,
                        "codename": "xa_van_phu",
                        "division_type": "xã",
                        "short_codename": "van_phu"
                    },
                    {
                        "name": "Xã Phú Lộc",
                        "code": 14446,
                        "codename": "xa_phu_loc",
                        "division_type": "xã",
                        "short_codename": "phu_loc"
                    },
                    {
                        "name": "Xã Kỳ Phú",
                        "code": 14449,
                        "codename": "xa_ky_phu",
                        "division_type": "xã",
                        "short_codename": "ky_phu"
                    },
                    {
                        "name": "Xã Quỳnh Lưu",
                        "code": 14452,
                        "codename": "xa_quynh_luu",
                        "division_type": "xã",
                        "short_codename": "quynh_luu"
                    },
                    {
                        "name": "Xã Sơn Hà",
                        "code": 14455,
                        "codename": "xa_son_ha",
                        "division_type": "xã",
                        "short_codename": "son_ha"
                    },
                    {
                        "name": "Xã Phú Long",
                        "code": 14458,
                        "codename": "xa_phu_long",
                        "division_type": "xã",
                        "short_codename": "phu_long"
                    },
                    {
                        "name": "Xã Quảng Lạc",
                        "code": 14461,
                        "codename": "xa_quang_lac",
                        "division_type": "xã",
                        "short_codename": "quang_lac"
                    }
                ]
            },
            {
                "name": "Huyện Gia Viễn",
                "code": 373,
                "codename": "huyen_gia_vien",
                "division_type": "huyện",
                "short_codename": "gia_vien",
                "wards": [
                    {
                        "name": "Thị trấn Me",
                        "code": 14464,
                        "codename": "thi_tran_me",
                        "division_type": "thị trấn",
                        "short_codename": "me"
                    },
                    {
                        "name": "Xã Gia Hòa",
                        "code": 14467,
                        "codename": "xa_gia_hoa",
                        "division_type": "xã",
                        "short_codename": "gia_hoa"
                    },
                    {
                        "name": "Xã Gia Hưng",
                        "code": 14470,
                        "codename": "xa_gia_hung",
                        "division_type": "xã",
                        "short_codename": "gia_hung"
                    },
                    {
                        "name": "Xã Liên Sơn",
                        "code": 14473,
                        "codename": "xa_lien_son",
                        "division_type": "xã",
                        "short_codename": "lien_son"
                    },
                    {
                        "name": "Xã Gia Thanh",
                        "code": 14476,
                        "codename": "xa_gia_thanh",
                        "division_type": "xã",
                        "short_codename": "gia_thanh"
                    },
                    {
                        "name": "Xã Gia Vân",
                        "code": 14479,
                        "codename": "xa_gia_van",
                        "division_type": "xã",
                        "short_codename": "gia_van"
                    },
                    {
                        "name": "Xã Gia Phú",
                        "code": 14482,
                        "codename": "xa_gia_phu",
                        "division_type": "xã",
                        "short_codename": "gia_phu"
                    },
                    {
                        "name": "Xã Gia Xuân",
                        "code": 14485,
                        "codename": "xa_gia_xuan",
                        "division_type": "xã",
                        "short_codename": "gia_xuan"
                    },
                    {
                        "name": "Xã Gia Lập",
                        "code": 14488,
                        "codename": "xa_gia_lap",
                        "division_type": "xã",
                        "short_codename": "gia_lap"
                    },
                    {
                        "name": "Xã Gia Vượng",
                        "code": 14491,
                        "codename": "xa_gia_vuong",
                        "division_type": "xã",
                        "short_codename": "gia_vuong"
                    },
                    {
                        "name": "Xã Gia Trấn",
                        "code": 14494,
                        "codename": "xa_gia_tran",
                        "division_type": "xã",
                        "short_codename": "gia_tran"
                    },
                    {
                        "name": "Xã Gia Thịnh",
                        "code": 14497,
                        "codename": "xa_gia_thinh",
                        "division_type": "xã",
                        "short_codename": "gia_thinh"
                    },
                    {
                        "name": "Xã Gia Phương",
                        "code": 14500,
                        "codename": "xa_gia_phuong",
                        "division_type": "xã",
                        "short_codename": "gia_phuong"
                    },
                    {
                        "name": "Xã Gia Tân",
                        "code": 14503,
                        "codename": "xa_gia_tan",
                        "division_type": "xã",
                        "short_codename": "gia_tan"
                    },
                    {
                        "name": "Xã Gia Thắng",
                        "code": 14506,
                        "codename": "xa_gia_thang",
                        "division_type": "xã",
                        "short_codename": "gia_thang"
                    },
                    {
                        "name": "Xã Gia Trung",
                        "code": 14509,
                        "codename": "xa_gia_trung",
                        "division_type": "xã",
                        "short_codename": "gia_trung"
                    },
                    {
                        "name": "Xã Gia Minh",
                        "code": 14512,
                        "codename": "xa_gia_minh",
                        "division_type": "xã",
                        "short_codename": "gia_minh"
                    },
                    {
                        "name": "Xã Gia Lạc",
                        "code": 14515,
                        "codename": "xa_gia_lac",
                        "division_type": "xã",
                        "short_codename": "gia_lac"
                    },
                    {
                        "name": "Xã Gia Tiến",
                        "code": 14518,
                        "codename": "xa_gia_tien",
                        "division_type": "xã",
                        "short_codename": "gia_tien"
                    },
                    {
                        "name": "Xã Gia Sinh",
                        "code": 14521,
                        "codename": "xa_gia_sinh",
                        "division_type": "xã",
                        "short_codename": "gia_sinh"
                    },
                    {
                        "name": "Xã Gia Phong",
                        "code": 14524,
                        "codename": "xa_gia_phong",
                        "division_type": "xã",
                        "short_codename": "gia_phong"
                    }
                ]
            },
            {
                "name": "Huyện Hoa Lư",
                "code": 374,
                "codename": "huyen_hoa_lu",
                "division_type": "huyện",
                "short_codename": "hoa_lu",
                "wards": [
                    {
                        "name": "Thị trấn Thiên Tôn",
                        "code": 14527,
                        "codename": "thi_tran_thien_ton",
                        "division_type": "thị trấn",
                        "short_codename": "thien_ton"
                    },
                    {
                        "name": "Xã Ninh Giang",
                        "code": 14530,
                        "codename": "xa_ninh_giang",
                        "division_type": "xã",
                        "short_codename": "ninh_giang"
                    },
                    {
                        "name": "Xã Trường Yên",
                        "code": 14533,
                        "codename": "xa_truong_yen",
                        "division_type": "xã",
                        "short_codename": "truong_yen"
                    },
                    {
                        "name": "Xã Ninh Khang",
                        "code": 14536,
                        "codename": "xa_ninh_khang",
                        "division_type": "xã",
                        "short_codename": "ninh_khang"
                    },
                    {
                        "name": "Xã Ninh Mỹ",
                        "code": 14539,
                        "codename": "xa_ninh_my",
                        "division_type": "xã",
                        "short_codename": "ninh_my"
                    },
                    {
                        "name": "Xã Ninh Hòa",
                        "code": 14542,
                        "codename": "xa_ninh_hoa",
                        "division_type": "xã",
                        "short_codename": "ninh_hoa"
                    },
                    {
                        "name": "Xã Ninh Xuân",
                        "code": 14545,
                        "codename": "xa_ninh_xuan",
                        "division_type": "xã",
                        "short_codename": "ninh_xuan"
                    },
                    {
                        "name": "Xã Ninh Hải",
                        "code": 14548,
                        "codename": "xa_ninh_hai",
                        "division_type": "xã",
                        "short_codename": "ninh_hai"
                    },
                    {
                        "name": "Xã Ninh Thắng",
                        "code": 14551,
                        "codename": "xa_ninh_thang",
                        "division_type": "xã",
                        "short_codename": "ninh_thang"
                    },
                    {
                        "name": "Xã Ninh Vân",
                        "code": 14554,
                        "codename": "xa_ninh_van",
                        "division_type": "xã",
                        "short_codename": "ninh_van"
                    },
                    {
                        "name": "Xã Ninh An",
                        "code": 14557,
                        "codename": "xa_ninh_an",
                        "division_type": "xã",
                        "short_codename": "ninh_an"
                    }
                ]
            },
            {
                "name": "Huyện Yên Khánh",
                "code": 375,
                "codename": "huyen_yen_khanh",
                "division_type": "huyện",
                "short_codename": "yen_khanh",
                "wards": [
                    {
                        "name": "Thị trấn Yên Ninh",
                        "code": 14560,
                        "codename": "thi_tran_yen_ninh",
                        "division_type": "thị trấn",
                        "short_codename": "yen_ninh"
                    },
                    {
                        "name": "Xã Khánh Tiên",
                        "code": 14563,
                        "codename": "xa_khanh_tien",
                        "division_type": "xã",
                        "short_codename": "khanh_tien"
                    },
                    {
                        "name": "Xã Khánh Phú",
                        "code": 14566,
                        "codename": "xa_khanh_phu",
                        "division_type": "xã",
                        "short_codename": "khanh_phu"
                    },
                    {
                        "name": "Xã Khánh Hòa",
                        "code": 14569,
                        "codename": "xa_khanh_hoa",
                        "division_type": "xã",
                        "short_codename": "khanh_hoa"
                    },
                    {
                        "name": "Xã Khánh Lợi",
                        "code": 14572,
                        "codename": "xa_khanh_loi",
                        "division_type": "xã",
                        "short_codename": "khanh_loi"
                    },
                    {
                        "name": "Xã Khánh An",
                        "code": 14575,
                        "codename": "xa_khanh_an",
                        "division_type": "xã",
                        "short_codename": "khanh_an"
                    },
                    {
                        "name": "Xã Khánh Cường",
                        "code": 14578,
                        "codename": "xa_khanh_cuong",
                        "division_type": "xã",
                        "short_codename": "khanh_cuong"
                    },
                    {
                        "name": "Xã Khánh Cư",
                        "code": 14581,
                        "codename": "xa_khanh_cu",
                        "division_type": "xã",
                        "short_codename": "khanh_cu"
                    },
                    {
                        "name": "Xã Khánh Thiện",
                        "code": 14584,
                        "codename": "xa_khanh_thien",
                        "division_type": "xã",
                        "short_codename": "khanh_thien"
                    },
                    {
                        "name": "Xã Khánh Hải",
                        "code": 14587,
                        "codename": "xa_khanh_hai",
                        "division_type": "xã",
                        "short_codename": "khanh_hai"
                    },
                    {
                        "name": "Xã Khánh Trung",
                        "code": 14590,
                        "codename": "xa_khanh_trung",
                        "division_type": "xã",
                        "short_codename": "khanh_trung"
                    },
                    {
                        "name": "Xã Khánh Mậu",
                        "code": 14593,
                        "codename": "xa_khanh_mau",
                        "division_type": "xã",
                        "short_codename": "khanh_mau"
                    },
                    {
                        "name": "Xã Khánh Vân",
                        "code": 14596,
                        "codename": "xa_khanh_van",
                        "division_type": "xã",
                        "short_codename": "khanh_van"
                    },
                    {
                        "name": "Xã Khánh Hội",
                        "code": 14599,
                        "codename": "xa_khanh_hoi",
                        "division_type": "xã",
                        "short_codename": "khanh_hoi"
                    },
                    {
                        "name": "Xã Khánh Công",
                        "code": 14602,
                        "codename": "xa_khanh_cong",
                        "division_type": "xã",
                        "short_codename": "khanh_cong"
                    },
                    {
                        "name": "Xã Khánh Thành",
                        "code": 14608,
                        "codename": "xa_khanh_thanh",
                        "division_type": "xã",
                        "short_codename": "khanh_thanh"
                    },
                    {
                        "name": "Xã Khánh Nhạc",
                        "code": 14611,
                        "codename": "xa_khanh_nhac",
                        "division_type": "xã",
                        "short_codename": "khanh_nhac"
                    },
                    {
                        "name": "Xã Khánh Thủy",
                        "code": 14614,
                        "codename": "xa_khanh_thuy",
                        "division_type": "xã",
                        "short_codename": "khanh_thuy"
                    },
                    {
                        "name": "Xã Khánh Hồng",
                        "code": 14617,
                        "codename": "xa_khanh_hong",
                        "division_type": "xã",
                        "short_codename": "khanh_hong"
                    }
                ]
            },
            {
                "name": "Huyện Kim Sơn",
                "code": 376,
                "codename": "huyen_kim_son",
                "division_type": "huyện",
                "short_codename": "kim_son",
                "wards": [
                    {
                        "name": "Thị trấn Phát Diệm",
                        "code": 14620,
                        "codename": "thi_tran_phat_diem",
                        "division_type": "thị trấn",
                        "short_codename": "phat_diem"
                    },
                    {
                        "name": "Thị trấn Bình Minh",
                        "code": 14623,
                        "codename": "thi_tran_binh_minh",
                        "division_type": "thị trấn",
                        "short_codename": "binh_minh"
                    },
                    {
                        "name": "Xã Hồi Ninh",
                        "code": 14629,
                        "codename": "xa_hoi_ninh",
                        "division_type": "xã",
                        "short_codename": "hoi_ninh"
                    },
                    {
                        "name": "Xã Xuân Chính",
                        "code": 14632,
                        "codename": "xa_xuan_chinh",
                        "division_type": "xã",
                        "short_codename": "xuan_chinh"
                    },
                    {
                        "name": "Xã Kim Định",
                        "code": 14635,
                        "codename": "xa_kim_dinh",
                        "division_type": "xã",
                        "short_codename": "kim_dinh"
                    },
                    {
                        "name": "Xã Ân Hòa",
                        "code": 14638,
                        "codename": "xa_an_hoa",
                        "division_type": "xã",
                        "short_codename": "an_hoa"
                    },
                    {
                        "name": "Xã Hùng Tiến",
                        "code": 14641,
                        "codename": "xa_hung_tien",
                        "division_type": "xã",
                        "short_codename": "hung_tien"
                    },
                    {
                        "name": "Xã Quang Thiện",
                        "code": 14647,
                        "codename": "xa_quang_thien",
                        "division_type": "xã",
                        "short_codename": "quang_thien"
                    },
                    {
                        "name": "Xã Như Hòa",
                        "code": 14650,
                        "codename": "xa_nhu_hoa",
                        "division_type": "xã",
                        "short_codename": "nhu_hoa"
                    },
                    {
                        "name": "Xã Chất Bình",
                        "code": 14653,
                        "codename": "xa_chat_binh",
                        "division_type": "xã",
                        "short_codename": "chat_binh"
                    },
                    {
                        "name": "Xã Đồng Hướng",
                        "code": 14656,
                        "codename": "xa_dong_huong",
                        "division_type": "xã",
                        "short_codename": "dong_huong"
                    },
                    {
                        "name": "Xã Kim Chính",
                        "code": 14659,
                        "codename": "xa_kim_chinh",
                        "division_type": "xã",
                        "short_codename": "kim_chinh"
                    },
                    {
                        "name": "Xã Thượng Kiệm",
                        "code": 14662,
                        "codename": "xa_thuong_kiem",
                        "division_type": "xã",
                        "short_codename": "thuong_kiem"
                    },
                    {
                        "name": "Xã Lưu Phương",
                        "code": 14665,
                        "codename": "xa_luu_phuong",
                        "division_type": "xã",
                        "short_codename": "luu_phuong"
                    },
                    {
                        "name": "Xã Tân Thành",
                        "code": 14668,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Xã Yên Lộc",
                        "code": 14671,
                        "codename": "xa_yen_loc",
                        "division_type": "xã",
                        "short_codename": "yen_loc"
                    },
                    {
                        "name": "Xã Lai Thành",
                        "code": 14674,
                        "codename": "xa_lai_thanh",
                        "division_type": "xã",
                        "short_codename": "lai_thanh"
                    },
                    {
                        "name": "Xã Định Hóa",
                        "code": 14677,
                        "codename": "xa_dinh_hoa",
                        "division_type": "xã",
                        "short_codename": "dinh_hoa"
                    },
                    {
                        "name": "Xã Văn Hải",
                        "code": 14680,
                        "codename": "xa_van_hai",
                        "division_type": "xã",
                        "short_codename": "van_hai"
                    },
                    {
                        "name": "Xã Kim Tân",
                        "code": 14683,
                        "codename": "xa_kim_tan",
                        "division_type": "xã",
                        "short_codename": "kim_tan"
                    },
                    {
                        "name": "Xã Kim Mỹ",
                        "code": 14686,
                        "codename": "xa_kim_my",
                        "division_type": "xã",
                        "short_codename": "kim_my"
                    },
                    {
                        "name": "Xã Cồn Thoi",
                        "code": 14689,
                        "codename": "xa_con_thoi",
                        "division_type": "xã",
                        "short_codename": "con_thoi"
                    },
                    {
                        "name": "Xã Kim Hải",
                        "code": 14692,
                        "codename": "xa_kim_hai",
                        "division_type": "xã",
                        "short_codename": "kim_hai"
                    },
                    {
                        "name": "Xã Kim Trung",
                        "code": 14695,
                        "codename": "xa_kim_trung",
                        "division_type": "xã",
                        "short_codename": "kim_trung"
                    },
                    {
                        "name": "Xã Kim Đông",
                        "code": 14698,
                        "codename": "xa_kim_dong",
                        "division_type": "xã",
                        "short_codename": "kim_dong"
                    }
                ]
            },
            {
                "name": "Huyện Yên Mô",
                "code": 377,
                "codename": "huyen_yen_mo",
                "division_type": "huyện",
                "short_codename": "yen_mo",
                "wards": [
                    {
                        "name": "Thị trấn Yên Thịnh",
                        "code": 14701,
                        "codename": "thi_tran_yen_thinh",
                        "division_type": "thị trấn",
                        "short_codename": "yen_thinh"
                    },
                    {
                        "name": "Xã Khánh Thượng",
                        "code": 14704,
                        "codename": "xa_khanh_thuong",
                        "division_type": "xã",
                        "short_codename": "khanh_thuong"
                    },
                    {
                        "name": "Xã Khánh Dương",
                        "code": 14707,
                        "codename": "xa_khanh_duong",
                        "division_type": "xã",
                        "short_codename": "khanh_duong"
                    },
                    {
                        "name": "Xã Mai Sơn",
                        "code": 14710,
                        "codename": "xa_mai_son",
                        "division_type": "xã",
                        "short_codename": "mai_son"
                    },
                    {
                        "name": "Xã Khánh Thịnh",
                        "code": 14713,
                        "codename": "xa_khanh_thinh",
                        "division_type": "xã",
                        "short_codename": "khanh_thinh"
                    },
                    {
                        "name": "Xã Yên Phong",
                        "code": 14719,
                        "codename": "xa_yen_phong",
                        "division_type": "xã",
                        "short_codename": "yen_phong"
                    },
                    {
                        "name": "Xã Yên Hòa",
                        "code": 14722,
                        "codename": "xa_yen_hoa",
                        "division_type": "xã",
                        "short_codename": "yen_hoa"
                    },
                    {
                        "name": "Xã Yên Thắng",
                        "code": 14725,
                        "codename": "xa_yen_thang",
                        "division_type": "xã",
                        "short_codename": "yen_thang"
                    },
                    {
                        "name": "Xã Yên Từ",
                        "code": 14728,
                        "codename": "xa_yen_tu",
                        "division_type": "xã",
                        "short_codename": "yen_tu"
                    },
                    {
                        "name": "Xã Yên Hưng",
                        "code": 14731,
                        "codename": "xa_yen_hung",
                        "division_type": "xã",
                        "short_codename": "yen_hung"
                    },
                    {
                        "name": "Xã Yên Thành",
                        "code": 14734,
                        "codename": "xa_yen_thanh",
                        "division_type": "xã",
                        "short_codename": "yen_thanh"
                    },
                    {
                        "name": "Xã Yên Nhân",
                        "code": 14737,
                        "codename": "xa_yen_nhan",
                        "division_type": "xã",
                        "short_codename": "yen_nhan"
                    },
                    {
                        "name": "Xã Yên Mỹ",
                        "code": 14740,
                        "codename": "xa_yen_my",
                        "division_type": "xã",
                        "short_codename": "yen_my"
                    },
                    {
                        "name": "Xã Yên Mạc",
                        "code": 14743,
                        "codename": "xa_yen_mac",
                        "division_type": "xã",
                        "short_codename": "yen_mac"
                    },
                    {
                        "name": "Xã Yên Đồng",
                        "code": 14746,
                        "codename": "xa_yen_dong",
                        "division_type": "xã",
                        "short_codename": "yen_dong"
                    },
                    {
                        "name": "Xã Yên Thái",
                        "code": 14749,
                        "codename": "xa_yen_thai",
                        "division_type": "xã",
                        "short_codename": "yen_thai"
                    },
                    {
                        "name": "Xã Yên Lâm",
                        "code": 14752,
                        "codename": "xa_yen_lam",
                        "division_type": "xã",
                        "short_codename": "yen_lam"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Thanh Hóa",
        "code": 38,
        "codename": "tinh_thanh_hoa",
        "division_type": "tỉnh",
        "phone_code": 237,
        "districts": [
            {
                "name": "Thành phố Thanh Hóa",
                "code": 380,
                "codename": "thanh_pho_thanh_hoa",
                "division_type": "thành phố",
                "short_codename": "thanh_hoa",
                "wards": [
                    {
                        "name": "Phường Hàm Rồng",
                        "code": 14755,
                        "codename": "phuong_ham_rong",
                        "division_type": "phường",
                        "short_codename": "ham_rong"
                    },
                    {
                        "name": "Phường Đông Thọ",
                        "code": 14758,
                        "codename": "phuong_dong_tho",
                        "division_type": "phường",
                        "short_codename": "dong_tho"
                    },
                    {
                        "name": "Phường Nam Ngạn",
                        "code": 14761,
                        "codename": "phuong_nam_ngan",
                        "division_type": "phường",
                        "short_codename": "nam_ngan"
                    },
                    {
                        "name": "Phường Trường Thi",
                        "code": 14764,
                        "codename": "phuong_truong_thi",
                        "division_type": "phường",
                        "short_codename": "truong_thi"
                    },
                    {
                        "name": "Phường Điện Biên",
                        "code": 14767,
                        "codename": "phuong_dien_bien",
                        "division_type": "phường",
                        "short_codename": "dien_bien"
                    },
                    {
                        "name": "Phường Phú Sơn",
                        "code": 14770,
                        "codename": "phuong_phu_son",
                        "division_type": "phường",
                        "short_codename": "phu_son"
                    },
                    {
                        "name": "Phường Lam Sơn",
                        "code": 14773,
                        "codename": "phuong_lam_son",
                        "division_type": "phường",
                        "short_codename": "lam_son"
                    },
                    {
                        "name": "Phường Ba Đình",
                        "code": 14776,
                        "codename": "phuong_ba_dinh",
                        "division_type": "phường",
                        "short_codename": "ba_dinh"
                    },
                    {
                        "name": "Phường Ngọc Trạo",
                        "code": 14779,
                        "codename": "phuong_ngoc_trao",
                        "division_type": "phường",
                        "short_codename": "ngoc_trao"
                    },
                    {
                        "name": "Phường Đông Vệ",
                        "code": 14782,
                        "codename": "phuong_dong_ve",
                        "division_type": "phường",
                        "short_codename": "dong_ve"
                    },
                    {
                        "name": "Phường Đông Sơn",
                        "code": 14785,
                        "codename": "phuong_dong_son",
                        "division_type": "phường",
                        "short_codename": "dong_son"
                    },
                    {
                        "name": "Phường Tân Sơn",
                        "code": 14788,
                        "codename": "phuong_tan_son",
                        "division_type": "phường",
                        "short_codename": "tan_son"
                    },
                    {
                        "name": "Phường Đông Cương",
                        "code": 14791,
                        "codename": "phuong_dong_cuong",
                        "division_type": "phường",
                        "short_codename": "dong_cuong"
                    },
                    {
                        "name": "Phường Đông Hương",
                        "code": 14794,
                        "codename": "phuong_dong_huong",
                        "division_type": "phường",
                        "short_codename": "dong_huong"
                    },
                    {
                        "name": "Phường Đông Hải",
                        "code": 14797,
                        "codename": "phuong_dong_hai",
                        "division_type": "phường",
                        "short_codename": "dong_hai"
                    },
                    {
                        "name": "Phường Quảng Hưng",
                        "code": 14800,
                        "codename": "phuong_quang_hung",
                        "division_type": "phường",
                        "short_codename": "quang_hung"
                    },
                    {
                        "name": "Phường Quảng Thắng",
                        "code": 14803,
                        "codename": "phuong_quang_thang",
                        "division_type": "phường",
                        "short_codename": "quang_thang"
                    },
                    {
                        "name": "Phường Quảng Thành",
                        "code": 14806,
                        "codename": "phuong_quang_thanh",
                        "division_type": "phường",
                        "short_codename": "quang_thanh"
                    },
                    {
                        "name": "Xã Thiệu Vân",
                        "code": 15850,
                        "codename": "xa_thieu_van",
                        "division_type": "xã",
                        "short_codename": "thieu_van"
                    },
                    {
                        "name": "Phường Thiệu Khánh",
                        "code": 15856,
                        "codename": "phuong_thieu_khanh",
                        "division_type": "phường",
                        "short_codename": "thieu_khanh"
                    },
                    {
                        "name": "Phường Thiệu Dương",
                        "code": 15859,
                        "codename": "phuong_thieu_duong",
                        "division_type": "phường",
                        "short_codename": "thieu_duong"
                    },
                    {
                        "name": "Phường Tào Xuyên",
                        "code": 15913,
                        "codename": "phuong_tao_xuyen",
                        "division_type": "phường",
                        "short_codename": "tao_xuyen"
                    },
                    {
                        "name": "Phường Long Anh",
                        "code": 15922,
                        "codename": "phuong_long_anh",
                        "division_type": "phường",
                        "short_codename": "long_anh"
                    },
                    {
                        "name": "Xã Hoằng Quang",
                        "code": 15925,
                        "codename": "xa_hoang_quang",
                        "division_type": "xã",
                        "short_codename": "hoang_quang"
                    },
                    {
                        "name": "Xã Hoằng Đại",
                        "code": 15970,
                        "codename": "xa_hoang_dai",
                        "division_type": "xã",
                        "short_codename": "hoang_dai"
                    },
                    {
                        "name": "Phường Đông Lĩnh",
                        "code": 16396,
                        "codename": "phuong_dong_linh",
                        "division_type": "phường",
                        "short_codename": "dong_linh"
                    },
                    {
                        "name": "Xã Đông Vinh",
                        "code": 16429,
                        "codename": "xa_dong_vinh",
                        "division_type": "xã",
                        "short_codename": "dong_vinh"
                    },
                    {
                        "name": "Phường Đông Tân",
                        "code": 16432,
                        "codename": "phuong_dong_tan",
                        "division_type": "phường",
                        "short_codename": "dong_tan"
                    },
                    {
                        "name": "Phường An Hưng",
                        "code": 16435,
                        "codename": "phuong_an_hung",
                        "division_type": "phường",
                        "short_codename": "an_hung"
                    },
                    {
                        "name": "Phường Quảng Thịnh",
                        "code": 16441,
                        "codename": "phuong_quang_thinh",
                        "division_type": "phường",
                        "short_codename": "quang_thinh"
                    },
                    {
                        "name": "Phường Quảng Đông",
                        "code": 16459,
                        "codename": "phuong_quang_dong",
                        "division_type": "phường",
                        "short_codename": "quang_dong"
                    },
                    {
                        "name": "Phường Quảng Cát",
                        "code": 16507,
                        "codename": "phuong_quang_cat",
                        "division_type": "phường",
                        "short_codename": "quang_cat"
                    },
                    {
                        "name": "Phường Quảng Phú",
                        "code": 16522,
                        "codename": "phuong_quang_phu",
                        "division_type": "phường",
                        "short_codename": "quang_phu"
                    },
                    {
                        "name": "Phường Quảng Tâm",
                        "code": 16525,
                        "codename": "phuong_quang_tam",
                        "division_type": "phường",
                        "short_codename": "quang_tam"
                    }
                ]
            },
            {
                "name": "Thị xã Bỉm Sơn",
                "code": 381,
                "codename": "thi_xa_bim_son",
                "division_type": "thị xã",
                "short_codename": "bim_son",
                "wards": [
                    {
                        "name": "Phường Bắc Sơn",
                        "code": 14809,
                        "codename": "phuong_bac_son",
                        "division_type": "phường",
                        "short_codename": "bac_son"
                    },
                    {
                        "name": "Phường Ba Đình",
                        "code": 14812,
                        "codename": "phuong_ba_dinh",
                        "division_type": "phường",
                        "short_codename": "ba_dinh"
                    },
                    {
                        "name": "Phường Lam Sơn",
                        "code": 14815,
                        "codename": "phuong_lam_son",
                        "division_type": "phường",
                        "short_codename": "lam_son"
                    },
                    {
                        "name": "Phường Ngọc Trạo",
                        "code": 14818,
                        "codename": "phuong_ngoc_trao",
                        "division_type": "phường",
                        "short_codename": "ngoc_trao"
                    },
                    {
                        "name": "Phường Đông Sơn",
                        "code": 14821,
                        "codename": "phuong_dong_son",
                        "division_type": "phường",
                        "short_codename": "dong_son"
                    },
                    {
                        "name": "Phường Phú Sơn",
                        "code": 14823,
                        "codename": "phuong_phu_son",
                        "division_type": "phường",
                        "short_codename": "phu_son"
                    },
                    {
                        "name": "Xã Quang Trung",
                        "code": 14824,
                        "codename": "xa_quang_trung",
                        "division_type": "xã",
                        "short_codename": "quang_trung"
                    }
                ]
            },
            {
                "name": "Thành phố Sầm Sơn",
                "code": 382,
                "codename": "thanh_pho_sam_son",
                "division_type": "thành phố",
                "short_codename": "sam_son",
                "wards": [
                    {
                        "name": "Phường Trung Sơn",
                        "code": 14830,
                        "codename": "phuong_trung_son",
                        "division_type": "phường",
                        "short_codename": "trung_son"
                    },
                    {
                        "name": "Phường Bắc Sơn",
                        "code": 14833,
                        "codename": "phuong_bac_son",
                        "division_type": "phường",
                        "short_codename": "bac_son"
                    },
                    {
                        "name": "Phường Trường Sơn",
                        "code": 14836,
                        "codename": "phuong_truong_son",
                        "division_type": "phường",
                        "short_codename": "truong_son"
                    },
                    {
                        "name": "Phường Quảng Cư",
                        "code": 14839,
                        "codename": "phuong_quang_cu",
                        "division_type": "phường",
                        "short_codename": "quang_cu"
                    },
                    {
                        "name": "Phường Quảng Tiến",
                        "code": 14842,
                        "codename": "phuong_quang_tien",
                        "division_type": "phường",
                        "short_codename": "quang_tien"
                    },
                    {
                        "name": "Xã Quảng Minh",
                        "code": 16513,
                        "codename": "xa_quang_minh",
                        "division_type": "xã",
                        "short_codename": "quang_minh"
                    },
                    {
                        "name": "Xã Quảng Hùng",
                        "code": 16516,
                        "codename": "xa_quang_hung",
                        "division_type": "xã",
                        "short_codename": "quang_hung"
                    },
                    {
                        "name": "Phường Quảng Thọ",
                        "code": 16528,
                        "codename": "phuong_quang_tho",
                        "division_type": "phường",
                        "short_codename": "quang_tho"
                    },
                    {
                        "name": "Phường Quảng Châu",
                        "code": 16531,
                        "codename": "phuong_quang_chau",
                        "division_type": "phường",
                        "short_codename": "quang_chau"
                    },
                    {
                        "name": "Phường Quảng Vinh",
                        "code": 16534,
                        "codename": "phuong_quang_vinh",
                        "division_type": "phường",
                        "short_codename": "quang_vinh"
                    },
                    {
                        "name": "Xã Quảng Đại",
                        "code": 16537,
                        "codename": "xa_quang_dai",
                        "division_type": "xã",
                        "short_codename": "quang_dai"
                    }
                ]
            },
            {
                "name": "Huyện Mường Lát",
                "code": 384,
                "codename": "huyen_muong_lat",
                "division_type": "huyện",
                "short_codename": "muong_lat",
                "wards": [
                    {
                        "name": "Thị trấn Mường Lát",
                        "code": 14845,
                        "codename": "thi_tran_muong_lat",
                        "division_type": "thị trấn",
                        "short_codename": "muong_lat"
                    },
                    {
                        "name": "Xã Tam Chung",
                        "code": 14848,
                        "codename": "xa_tam_chung",
                        "division_type": "xã",
                        "short_codename": "tam_chung"
                    },
                    {
                        "name": "Xã Mường Lý",
                        "code": 14854,
                        "codename": "xa_muong_ly",
                        "division_type": "xã",
                        "short_codename": "muong_ly"
                    },
                    {
                        "name": "Xã Trung Lý",
                        "code": 14857,
                        "codename": "xa_trung_ly",
                        "division_type": "xã",
                        "short_codename": "trung_ly"
                    },
                    {
                        "name": "Xã Quang Chiểu",
                        "code": 14860,
                        "codename": "xa_quang_chieu",
                        "division_type": "xã",
                        "short_codename": "quang_chieu"
                    },
                    {
                        "name": "Xã Pù Nhi",
                        "code": 14863,
                        "codename": "xa_pu_nhi",
                        "division_type": "xã",
                        "short_codename": "pu_nhi"
                    },
                    {
                        "name": "Xã Nhi Sơn",
                        "code": 14864,
                        "codename": "xa_nhi_son",
                        "division_type": "xã",
                        "short_codename": "nhi_son"
                    },
                    {
                        "name": "Xã Mường Chanh",
                        "code": 14866,
                        "codename": "xa_muong_chanh",
                        "division_type": "xã",
                        "short_codename": "muong_chanh"
                    }
                ]
            },
            {
                "name": "Huyện Quan Hóa",
                "code": 385,
                "codename": "huyen_quan_hoa",
                "division_type": "huyện",
                "short_codename": "quan_hoa",
                "wards": [
                    {
                        "name": "Thị trấn Hồi Xuân",
                        "code": 14869,
                        "codename": "thi_tran_hoi_xuan",
                        "division_type": "thị trấn",
                        "short_codename": "hoi_xuan"
                    },
                    {
                        "name": "Xã Thành Sơn",
                        "code": 14872,
                        "codename": "xa_thanh_son",
                        "division_type": "xã",
                        "short_codename": "thanh_son"
                    },
                    {
                        "name": "Xã Trung Sơn",
                        "code": 14875,
                        "codename": "xa_trung_son",
                        "division_type": "xã",
                        "short_codename": "trung_son"
                    },
                    {
                        "name": "Xã Phú Thanh",
                        "code": 14878,
                        "codename": "xa_phu_thanh",
                        "division_type": "xã",
                        "short_codename": "phu_thanh"
                    },
                    {
                        "name": "Xã Trung Thành",
                        "code": 14881,
                        "codename": "xa_trung_thanh",
                        "division_type": "xã",
                        "short_codename": "trung_thanh"
                    },
                    {
                        "name": "Xã Phú Lệ",
                        "code": 14884,
                        "codename": "xa_phu_le",
                        "division_type": "xã",
                        "short_codename": "phu_le"
                    },
                    {
                        "name": "Xã Phú Sơn",
                        "code": 14887,
                        "codename": "xa_phu_son",
                        "division_type": "xã",
                        "short_codename": "phu_son"
                    },
                    {
                        "name": "Xã Phú Xuân",
                        "code": 14890,
                        "codename": "xa_phu_xuan",
                        "division_type": "xã",
                        "short_codename": "phu_xuan"
                    },
                    {
                        "name": "Xã Hiền Chung",
                        "code": 14896,
                        "codename": "xa_hien_chung",
                        "division_type": "xã",
                        "short_codename": "hien_chung"
                    },
                    {
                        "name": "Xã Hiền Kiệt",
                        "code": 14899,
                        "codename": "xa_hien_kiet",
                        "division_type": "xã",
                        "short_codename": "hien_kiet"
                    },
                    {
                        "name": "Xã Nam Tiến",
                        "code": 14902,
                        "codename": "xa_nam_tien",
                        "division_type": "xã",
                        "short_codename": "nam_tien"
                    },
                    {
                        "name": "Xã Thiên Phủ",
                        "code": 14908,
                        "codename": "xa_thien_phu",
                        "division_type": "xã",
                        "short_codename": "thien_phu"
                    },
                    {
                        "name": "Xã Phú Nghiêm",
                        "code": 14911,
                        "codename": "xa_phu_nghiem",
                        "division_type": "xã",
                        "short_codename": "phu_nghiem"
                    },
                    {
                        "name": "Xã Nam Xuân",
                        "code": 14914,
                        "codename": "xa_nam_xuan",
                        "division_type": "xã",
                        "short_codename": "nam_xuan"
                    },
                    {
                        "name": "Xã Nam Động",
                        "code": 14917,
                        "codename": "xa_nam_dong",
                        "division_type": "xã",
                        "short_codename": "nam_dong"
                    }
                ]
            },
            {
                "name": "Huyện Bá Thước",
                "code": 386,
                "codename": "huyen_ba_thuoc",
                "division_type": "huyện",
                "short_codename": "ba_thuoc",
                "wards": [
                    {
                        "name": "Thị trấn Cành Nàng",
                        "code": 14923,
                        "codename": "thi_tran_canh_nang",
                        "division_type": "thị trấn",
                        "short_codename": "canh_nang"
                    },
                    {
                        "name": "Xã Điền Thượng",
                        "code": 14926,
                        "codename": "xa_dien_thuong",
                        "division_type": "xã",
                        "short_codename": "dien_thuong"
                    },
                    {
                        "name": "Xã Điền Hạ",
                        "code": 14929,
                        "codename": "xa_dien_ha",
                        "division_type": "xã",
                        "short_codename": "dien_ha"
                    },
                    {
                        "name": "Xã Điền Quang",
                        "code": 14932,
                        "codename": "xa_dien_quang",
                        "division_type": "xã",
                        "short_codename": "dien_quang"
                    },
                    {
                        "name": "Xã Điền Trung",
                        "code": 14935,
                        "codename": "xa_dien_trung",
                        "division_type": "xã",
                        "short_codename": "dien_trung"
                    },
                    {
                        "name": "Xã Thành Sơn",
                        "code": 14938,
                        "codename": "xa_thanh_son",
                        "division_type": "xã",
                        "short_codename": "thanh_son"
                    },
                    {
                        "name": "Xã Lương Ngoại",
                        "code": 14941,
                        "codename": "xa_luong_ngoai",
                        "division_type": "xã",
                        "short_codename": "luong_ngoai"
                    },
                    {
                        "name": "Xã Ái Thượng",
                        "code": 14944,
                        "codename": "xa_ai_thuong",
                        "division_type": "xã",
                        "short_codename": "ai_thuong"
                    },
                    {
                        "name": "Xã Lương Nội",
                        "code": 14947,
                        "codename": "xa_luong_noi",
                        "division_type": "xã",
                        "short_codename": "luong_noi"
                    },
                    {
                        "name": "Xã Điền Lư",
                        "code": 14950,
                        "codename": "xa_dien_lu",
                        "division_type": "xã",
                        "short_codename": "dien_lu"
                    },
                    {
                        "name": "Xã Lương Trung",
                        "code": 14953,
                        "codename": "xa_luong_trung",
                        "division_type": "xã",
                        "short_codename": "luong_trung"
                    },
                    {
                        "name": "Xã Lũng Niêm",
                        "code": 14956,
                        "codename": "xa_lung_niem",
                        "division_type": "xã",
                        "short_codename": "lung_niem"
                    },
                    {
                        "name": "Xã Lũng Cao",
                        "code": 14959,
                        "codename": "xa_lung_cao",
                        "division_type": "xã",
                        "short_codename": "lung_cao"
                    },
                    {
                        "name": "Xã Hạ Trung",
                        "code": 14962,
                        "codename": "xa_ha_trung",
                        "division_type": "xã",
                        "short_codename": "ha_trung"
                    },
                    {
                        "name": "Xã Cổ Lũng",
                        "code": 14965,
                        "codename": "xa_co_lung",
                        "division_type": "xã",
                        "short_codename": "co_lung"
                    },
                    {
                        "name": "Xã Thành Lâm",
                        "code": 14968,
                        "codename": "xa_thanh_lam",
                        "division_type": "xã",
                        "short_codename": "thanh_lam"
                    },
                    {
                        "name": "Xã Ban Công",
                        "code": 14971,
                        "codename": "xa_ban_cong",
                        "division_type": "xã",
                        "short_codename": "ban_cong"
                    },
                    {
                        "name": "Xã Kỳ Tân",
                        "code": 14974,
                        "codename": "xa_ky_tan",
                        "division_type": "xã",
                        "short_codename": "ky_tan"
                    },
                    {
                        "name": "Xã Văn Nho",
                        "code": 14977,
                        "codename": "xa_van_nho",
                        "division_type": "xã",
                        "short_codename": "van_nho"
                    },
                    {
                        "name": "Xã Thiết Ống",
                        "code": 14980,
                        "codename": "xa_thiet_ong",
                        "division_type": "xã",
                        "short_codename": "thiet_ong"
                    },
                    {
                        "name": "Xã Thiết Kế",
                        "code": 14986,
                        "codename": "xa_thiet_ke",
                        "division_type": "xã",
                        "short_codename": "thiet_ke"
                    }
                ]
            },
            {
                "name": "Huyện Quan Sơn",
                "code": 387,
                "codename": "huyen_quan_son",
                "division_type": "huyện",
                "short_codename": "quan_son",
                "wards": [
                    {
                        "name": "Xã Trung Xuân",
                        "code": 14995,
                        "codename": "xa_trung_xuan",
                        "division_type": "xã",
                        "short_codename": "trung_xuan"
                    },
                    {
                        "name": "Xã Trung Thượng",
                        "code": 14998,
                        "codename": "xa_trung_thuong",
                        "division_type": "xã",
                        "short_codename": "trung_thuong"
                    },
                    {
                        "name": "Xã Trung Tiến",
                        "code": 14999,
                        "codename": "xa_trung_tien",
                        "division_type": "xã",
                        "short_codename": "trung_tien"
                    },
                    {
                        "name": "Xã Trung Hạ",
                        "code": 15001,
                        "codename": "xa_trung_ha",
                        "division_type": "xã",
                        "short_codename": "trung_ha"
                    },
                    {
                        "name": "Xã Sơn Hà",
                        "code": 15004,
                        "codename": "xa_son_ha",
                        "division_type": "xã",
                        "short_codename": "son_ha"
                    },
                    {
                        "name": "Xã Tam Thanh",
                        "code": 15007,
                        "codename": "xa_tam_thanh",
                        "division_type": "xã",
                        "short_codename": "tam_thanh"
                    },
                    {
                        "name": "Xã Sơn Thủy",
                        "code": 15010,
                        "codename": "xa_son_thuy",
                        "division_type": "xã",
                        "short_codename": "son_thuy"
                    },
                    {
                        "name": "Xã Na Mèo",
                        "code": 15013,
                        "codename": "xa_na_meo",
                        "division_type": "xã",
                        "short_codename": "na_meo"
                    },
                    {
                        "name": "Thị trấn Sơn Lư",
                        "code": 15016,
                        "codename": "thi_tran_son_lu",
                        "division_type": "thị trấn",
                        "short_codename": "son_lu"
                    },
                    {
                        "name": "Xã Tam Lư",
                        "code": 15019,
                        "codename": "xa_tam_lu",
                        "division_type": "xã",
                        "short_codename": "tam_lu"
                    },
                    {
                        "name": "Xã Sơn Điện",
                        "code": 15022,
                        "codename": "xa_son_dien",
                        "division_type": "xã",
                        "short_codename": "son_dien"
                    },
                    {
                        "name": "Xã Mường Mìn",
                        "code": 15025,
                        "codename": "xa_muong_min",
                        "division_type": "xã",
                        "short_codename": "muong_min"
                    }
                ]
            },
            {
                "name": "Huyện Lang Chánh",
                "code": 388,
                "codename": "huyen_lang_chanh",
                "division_type": "huyện",
                "short_codename": "lang_chanh",
                "wards": [
                    {
                        "name": "Xã Yên Khương",
                        "code": 15031,
                        "codename": "xa_yen_khuong",
                        "division_type": "xã",
                        "short_codename": "yen_khuong"
                    },
                    {
                        "name": "Xã Yên Thắng",
                        "code": 15034,
                        "codename": "xa_yen_thang",
                        "division_type": "xã",
                        "short_codename": "yen_thang"
                    },
                    {
                        "name": "Xã Trí Nang",
                        "code": 15037,
                        "codename": "xa_tri_nang",
                        "division_type": "xã",
                        "short_codename": "tri_nang"
                    },
                    {
                        "name": "Xã Giao An",
                        "code": 15040,
                        "codename": "xa_giao_an",
                        "division_type": "xã",
                        "short_codename": "giao_an"
                    },
                    {
                        "name": "Xã Giao Thiện",
                        "code": 15043,
                        "codename": "xa_giao_thien",
                        "division_type": "xã",
                        "short_codename": "giao_thien"
                    },
                    {
                        "name": "Xã Tân Phúc",
                        "code": 15046,
                        "codename": "xa_tan_phuc",
                        "division_type": "xã",
                        "short_codename": "tan_phuc"
                    },
                    {
                        "name": "Xã Tam Văn",
                        "code": 15049,
                        "codename": "xa_tam_van",
                        "division_type": "xã",
                        "short_codename": "tam_van"
                    },
                    {
                        "name": "Xã Lâm Phú",
                        "code": 15052,
                        "codename": "xa_lam_phu",
                        "division_type": "xã",
                        "short_codename": "lam_phu"
                    },
                    {
                        "name": "Thị trấn Lang Chánh",
                        "code": 15055,
                        "codename": "thi_tran_lang_chanh",
                        "division_type": "thị trấn",
                        "short_codename": "lang_chanh"
                    },
                    {
                        "name": "Xã Đồng Lương",
                        "code": 15058,
                        "codename": "xa_dong_luong",
                        "division_type": "xã",
                        "short_codename": "dong_luong"
                    }
                ]
            },
            {
                "name": "Huyện Ngọc Lặc",
                "code": 389,
                "codename": "huyen_ngoc_lac",
                "division_type": "huyện",
                "short_codename": "ngoc_lac",
                "wards": [
                    {
                        "name": "Thị Trấn Ngọc Lặc",
                        "code": 15061,
                        "codename": "thi_tran_ngoc_lac",
                        "division_type": "thị trấn",
                        "short_codename": "ngoc_lac"
                    },
                    {
                        "name": "Xã Lam Sơn",
                        "code": 15064,
                        "codename": "xa_lam_son",
                        "division_type": "xã",
                        "short_codename": "lam_son"
                    },
                    {
                        "name": "Xã Mỹ Tân",
                        "code": 15067,
                        "codename": "xa_my_tan",
                        "division_type": "xã",
                        "short_codename": "my_tan"
                    },
                    {
                        "name": "Xã Thúy Sơn",
                        "code": 15070,
                        "codename": "xa_thuy_son",
                        "division_type": "xã",
                        "short_codename": "thuy_son"
                    },
                    {
                        "name": "Xã Thạch Lập",
                        "code": 15073,
                        "codename": "xa_thach_lap",
                        "division_type": "xã",
                        "short_codename": "thach_lap"
                    },
                    {
                        "name": "Xã Vân Âm",
                        "code": 15076,
                        "codename": "xa_van_am",
                        "division_type": "xã",
                        "short_codename": "van_am"
                    },
                    {
                        "name": "Xã Cao Ngọc",
                        "code": 15079,
                        "codename": "xa_cao_ngoc",
                        "division_type": "xã",
                        "short_codename": "cao_ngoc"
                    },
                    {
                        "name": "Xã Quang Trung",
                        "code": 15085,
                        "codename": "xa_quang_trung",
                        "division_type": "xã",
                        "short_codename": "quang_trung"
                    },
                    {
                        "name": "Xã Đồng Thịnh",
                        "code": 15088,
                        "codename": "xa_dong_thinh",
                        "division_type": "xã",
                        "short_codename": "dong_thinh"
                    },
                    {
                        "name": "Xã Ngọc Liên",
                        "code": 15091,
                        "codename": "xa_ngoc_lien",
                        "division_type": "xã",
                        "short_codename": "ngoc_lien"
                    },
                    {
                        "name": "Xã Ngọc Sơn",
                        "code": 15094,
                        "codename": "xa_ngoc_son",
                        "division_type": "xã",
                        "short_codename": "ngoc_son"
                    },
                    {
                        "name": "Xã Lộc Thịnh",
                        "code": 15097,
                        "codename": "xa_loc_thinh",
                        "division_type": "xã",
                        "short_codename": "loc_thinh"
                    },
                    {
                        "name": "Xã Cao Thịnh",
                        "code": 15100,
                        "codename": "xa_cao_thinh",
                        "division_type": "xã",
                        "short_codename": "cao_thinh"
                    },
                    {
                        "name": "Xã Ngọc Trung",
                        "code": 15103,
                        "codename": "xa_ngoc_trung",
                        "division_type": "xã",
                        "short_codename": "ngoc_trung"
                    },
                    {
                        "name": "Xã Phùng Giáo",
                        "code": 15106,
                        "codename": "xa_phung_giao",
                        "division_type": "xã",
                        "short_codename": "phung_giao"
                    },
                    {
                        "name": "Xã Phùng Minh",
                        "code": 15109,
                        "codename": "xa_phung_minh",
                        "division_type": "xã",
                        "short_codename": "phung_minh"
                    },
                    {
                        "name": "Xã Phúc Thịnh",
                        "code": 15112,
                        "codename": "xa_phuc_thinh",
                        "division_type": "xã",
                        "short_codename": "phuc_thinh"
                    },
                    {
                        "name": "Xã Nguyệt Ấn",
                        "code": 15115,
                        "codename": "xa_nguyet_an",
                        "division_type": "xã",
                        "short_codename": "nguyet_an"
                    },
                    {
                        "name": "Xã Kiên Thọ",
                        "code": 15118,
                        "codename": "xa_kien_tho",
                        "division_type": "xã",
                        "short_codename": "kien_tho"
                    },
                    {
                        "name": "Xã Minh Tiến",
                        "code": 15121,
                        "codename": "xa_minh_tien",
                        "division_type": "xã",
                        "short_codename": "minh_tien"
                    },
                    {
                        "name": "Xã Minh Sơn",
                        "code": 15124,
                        "codename": "xa_minh_son",
                        "division_type": "xã",
                        "short_codename": "minh_son"
                    }
                ]
            },
            {
                "name": "Huyện Cẩm Thủy",
                "code": 390,
                "codename": "huyen_cam_thuy",
                "division_type": "huyện",
                "short_codename": "cam_thuy",
                "wards": [
                    {
                        "name": "Thị trấn Phong Sơn",
                        "code": 15127,
                        "codename": "thi_tran_phong_son",
                        "division_type": "thị trấn",
                        "short_codename": "phong_son"
                    },
                    {
                        "name": "Xã Cẩm Thành",
                        "code": 15133,
                        "codename": "xa_cam_thanh",
                        "division_type": "xã",
                        "short_codename": "cam_thanh"
                    },
                    {
                        "name": "Xã Cẩm Quý",
                        "code": 15136,
                        "codename": "xa_cam_quy",
                        "division_type": "xã",
                        "short_codename": "cam_quy"
                    },
                    {
                        "name": "Xã Cẩm Lương",
                        "code": 15139,
                        "codename": "xa_cam_luong",
                        "division_type": "xã",
                        "short_codename": "cam_luong"
                    },
                    {
                        "name": "Xã Cẩm Thạch",
                        "code": 15142,
                        "codename": "xa_cam_thach",
                        "division_type": "xã",
                        "short_codename": "cam_thach"
                    },
                    {
                        "name": "Xã Cẩm Liên",
                        "code": 15145,
                        "codename": "xa_cam_lien",
                        "division_type": "xã",
                        "short_codename": "cam_lien"
                    },
                    {
                        "name": "Xã Cẩm Giang",
                        "code": 15148,
                        "codename": "xa_cam_giang",
                        "division_type": "xã",
                        "short_codename": "cam_giang"
                    },
                    {
                        "name": "Xã Cẩm Bình",
                        "code": 15151,
                        "codename": "xa_cam_binh",
                        "division_type": "xã",
                        "short_codename": "cam_binh"
                    },
                    {
                        "name": "Xã Cẩm Tú",
                        "code": 15154,
                        "codename": "xa_cam_tu",
                        "division_type": "xã",
                        "short_codename": "cam_tu"
                    },
                    {
                        "name": "Xã Cẩm Châu",
                        "code": 15160,
                        "codename": "xa_cam_chau",
                        "division_type": "xã",
                        "short_codename": "cam_chau"
                    },
                    {
                        "name": "Xã Cẩm Tâm",
                        "code": 15163,
                        "codename": "xa_cam_tam",
                        "division_type": "xã",
                        "short_codename": "cam_tam"
                    },
                    {
                        "name": "Xã Cẩm Ngọc",
                        "code": 15169,
                        "codename": "xa_cam_ngoc",
                        "division_type": "xã",
                        "short_codename": "cam_ngoc"
                    },
                    {
                        "name": "Xã Cẩm Long",
                        "code": 15172,
                        "codename": "xa_cam_long",
                        "division_type": "xã",
                        "short_codename": "cam_long"
                    },
                    {
                        "name": "Xã Cẩm Yên",
                        "code": 15175,
                        "codename": "xa_cam_yen",
                        "division_type": "xã",
                        "short_codename": "cam_yen"
                    },
                    {
                        "name": "Xã Cẩm Tân",
                        "code": 15178,
                        "codename": "xa_cam_tan",
                        "division_type": "xã",
                        "short_codename": "cam_tan"
                    },
                    {
                        "name": "Xã Cẩm Phú",
                        "code": 15181,
                        "codename": "xa_cam_phu",
                        "division_type": "xã",
                        "short_codename": "cam_phu"
                    },
                    {
                        "name": "Xã Cẩm Vân",
                        "code": 15184,
                        "codename": "xa_cam_van",
                        "division_type": "xã",
                        "short_codename": "cam_van"
                    }
                ]
            },
            {
                "name": "Huyện Thạch Thành",
                "code": 391,
                "codename": "huyen_thach_thanh",
                "division_type": "huyện",
                "short_codename": "thach_thanh",
                "wards": [
                    {
                        "name": "Thị trấn Kim Tân",
                        "code": 15187,
                        "codename": "thi_tran_kim_tan",
                        "division_type": "thị trấn",
                        "short_codename": "kim_tan"
                    },
                    {
                        "name": "Thị trấn Vân Du",
                        "code": 15190,
                        "codename": "thi_tran_van_du",
                        "division_type": "thị trấn",
                        "short_codename": "van_du"
                    },
                    {
                        "name": "Xã Thạch Lâm",
                        "code": 15196,
                        "codename": "xa_thach_lam",
                        "division_type": "xã",
                        "short_codename": "thach_lam"
                    },
                    {
                        "name": "Xã Thạch Quảng",
                        "code": 15199,
                        "codename": "xa_thach_quang",
                        "division_type": "xã",
                        "short_codename": "thach_quang"
                    },
                    {
                        "name": "Xã Thạch Tượng",
                        "code": 15202,
                        "codename": "xa_thach_tuong",
                        "division_type": "xã",
                        "short_codename": "thach_tuong"
                    },
                    {
                        "name": "Xã Thạch Cẩm",
                        "code": 15205,
                        "codename": "xa_thach_cam",
                        "division_type": "xã",
                        "short_codename": "thach_cam"
                    },
                    {
                        "name": "Xã Thạch Sơn",
                        "code": 15208,
                        "codename": "xa_thach_son",
                        "division_type": "xã",
                        "short_codename": "thach_son"
                    },
                    {
                        "name": "Xã Thạch Bình",
                        "code": 15211,
                        "codename": "xa_thach_binh",
                        "division_type": "xã",
                        "short_codename": "thach_binh"
                    },
                    {
                        "name": "Xã Thạch Định",
                        "code": 15214,
                        "codename": "xa_thach_dinh",
                        "division_type": "xã",
                        "short_codename": "thach_dinh"
                    },
                    {
                        "name": "Xã Thạch Đồng",
                        "code": 15217,
                        "codename": "xa_thach_dong",
                        "division_type": "xã",
                        "short_codename": "thach_dong"
                    },
                    {
                        "name": "Xã Thạch Long",
                        "code": 15220,
                        "codename": "xa_thach_long",
                        "division_type": "xã",
                        "short_codename": "thach_long"
                    },
                    {
                        "name": "Xã Thành Mỹ",
                        "code": 15223,
                        "codename": "xa_thanh_my",
                        "division_type": "xã",
                        "short_codename": "thanh_my"
                    },
                    {
                        "name": "Xã Thành Yên",
                        "code": 15226,
                        "codename": "xa_thanh_yen",
                        "division_type": "xã",
                        "short_codename": "thanh_yen"
                    },
                    {
                        "name": "Xã Thành Vinh",
                        "code": 15229,
                        "codename": "xa_thanh_vinh",
                        "division_type": "xã",
                        "short_codename": "thanh_vinh"
                    },
                    {
                        "name": "Xã Thành Minh",
                        "code": 15232,
                        "codename": "xa_thanh_minh",
                        "division_type": "xã",
                        "short_codename": "thanh_minh"
                    },
                    {
                        "name": "Xã Thành Công",
                        "code": 15235,
                        "codename": "xa_thanh_cong",
                        "division_type": "xã",
                        "short_codename": "thanh_cong"
                    },
                    {
                        "name": "Xã Thành Tân",
                        "code": 15238,
                        "codename": "xa_thanh_tan",
                        "division_type": "xã",
                        "short_codename": "thanh_tan"
                    },
                    {
                        "name": "Xã Thành Trực",
                        "code": 15241,
                        "codename": "xa_thanh_truc",
                        "division_type": "xã",
                        "short_codename": "thanh_truc"
                    },
                    {
                        "name": "Xã Thành Tâm",
                        "code": 15247,
                        "codename": "xa_thanh_tam",
                        "division_type": "xã",
                        "short_codename": "thanh_tam"
                    },
                    {
                        "name": "Xã Thành An",
                        "code": 15250,
                        "codename": "xa_thanh_an",
                        "division_type": "xã",
                        "short_codename": "thanh_an"
                    },
                    {
                        "name": "Xã Thành Thọ",
                        "code": 15253,
                        "codename": "xa_thanh_tho",
                        "division_type": "xã",
                        "short_codename": "thanh_tho"
                    },
                    {
                        "name": "Xã Thành Tiến",
                        "code": 15256,
                        "codename": "xa_thanh_tien",
                        "division_type": "xã",
                        "short_codename": "thanh_tien"
                    },
                    {
                        "name": "Xã Thành Long",
                        "code": 15259,
                        "codename": "xa_thanh_long",
                        "division_type": "xã",
                        "short_codename": "thanh_long"
                    },
                    {
                        "name": "Xã Thành Hưng",
                        "code": 15265,
                        "codename": "xa_thanh_hung",
                        "division_type": "xã",
                        "short_codename": "thanh_hung"
                    },
                    {
                        "name": "Xã Ngọc Trạo",
                        "code": 15268,
                        "codename": "xa_ngoc_trao",
                        "division_type": "xã",
                        "short_codename": "ngoc_trao"
                    }
                ]
            },
            {
                "name": "Huyện Hà Trung",
                "code": 392,
                "codename": "huyen_ha_trung",
                "division_type": "huyện",
                "short_codename": "ha_trung",
                "wards": [
                    {
                        "name": "Thị trấn Hà Trung",
                        "code": 15271,
                        "codename": "thi_tran_ha_trung",
                        "division_type": "thị trấn",
                        "short_codename": "ha_trung"
                    },
                    {
                        "name": "Xã Hà Long",
                        "code": 15274,
                        "codename": "xa_ha_long",
                        "division_type": "xã",
                        "short_codename": "ha_long"
                    },
                    {
                        "name": "Xã Hà Vinh",
                        "code": 15277,
                        "codename": "xa_ha_vinh",
                        "division_type": "xã",
                        "short_codename": "ha_vinh"
                    },
                    {
                        "name": "Xã Hà Bắc",
                        "code": 15280,
                        "codename": "xa_ha_bac",
                        "division_type": "xã",
                        "short_codename": "ha_bac"
                    },
                    {
                        "name": "Xã Hoạt Giang",
                        "code": 15283,
                        "codename": "xa_hoat_giang",
                        "division_type": "xã",
                        "short_codename": "hoat_giang"
                    },
                    {
                        "name": "Xã Yên Dương",
                        "code": 15286,
                        "codename": "xa_yen_duong",
                        "division_type": "xã",
                        "short_codename": "yen_duong"
                    },
                    {
                        "name": "Xã Hà Giang",
                        "code": 15292,
                        "codename": "xa_ha_giang",
                        "division_type": "xã",
                        "short_codename": "ha_giang"
                    },
                    {
                        "name": "Xã Lĩnh Toại",
                        "code": 15298,
                        "codename": "xa_linh_toai",
                        "division_type": "xã",
                        "short_codename": "linh_toai"
                    },
                    {
                        "name": "Xã Hà Ngọc",
                        "code": 15304,
                        "codename": "xa_ha_ngoc",
                        "division_type": "xã",
                        "short_codename": "ha_ngoc"
                    },
                    {
                        "name": "Xã Yến Sơn",
                        "code": 15307,
                        "codename": "xa_yen_son",
                        "division_type": "xã",
                        "short_codename": "yen_son"
                    },
                    {
                        "name": "Xã Hà Sơn",
                        "code": 15313,
                        "codename": "xa_ha_son",
                        "division_type": "xã",
                        "short_codename": "ha_son"
                    },
                    {
                        "name": "Xã Hà Lĩnh",
                        "code": 15316,
                        "codename": "xa_ha_linh",
                        "division_type": "xã",
                        "short_codename": "ha_linh"
                    },
                    {
                        "name": "Xã Hà Đông",
                        "code": 15319,
                        "codename": "xa_ha_dong",
                        "division_type": "xã",
                        "short_codename": "ha_dong"
                    },
                    {
                        "name": "Xã Hà Tân",
                        "code": 15322,
                        "codename": "xa_ha_tan",
                        "division_type": "xã",
                        "short_codename": "ha_tan"
                    },
                    {
                        "name": "Xã Hà Tiến",
                        "code": 15325,
                        "codename": "xa_ha_tien",
                        "division_type": "xã",
                        "short_codename": "ha_tien"
                    },
                    {
                        "name": "Xã Hà Bình",
                        "code": 15328,
                        "codename": "xa_ha_binh",
                        "division_type": "xã",
                        "short_codename": "ha_binh"
                    },
                    {
                        "name": "Xã Hà Lai",
                        "code": 15331,
                        "codename": "xa_ha_lai",
                        "division_type": "xã",
                        "short_codename": "ha_lai"
                    },
                    {
                        "name": "Xã Hà Châu",
                        "code": 15334,
                        "codename": "xa_ha_chau",
                        "division_type": "xã",
                        "short_codename": "ha_chau"
                    },
                    {
                        "name": "Xã Hà Thái",
                        "code": 15340,
                        "codename": "xa_ha_thai",
                        "division_type": "xã",
                        "short_codename": "ha_thai"
                    },
                    {
                        "name": "Xã Hà Hải",
                        "code": 15343,
                        "codename": "xa_ha_hai",
                        "division_type": "xã",
                        "short_codename": "ha_hai"
                    }
                ]
            },
            {
                "name": "Huyện Vĩnh Lộc",
                "code": 393,
                "codename": "huyen_vinh_loc",
                "division_type": "huyện",
                "short_codename": "vinh_loc",
                "wards": [
                    {
                        "name": "Thị trấn Vĩnh Lộc",
                        "code": 15349,
                        "codename": "thi_tran_vinh_loc",
                        "division_type": "thị trấn",
                        "short_codename": "vinh_loc"
                    },
                    {
                        "name": "Xã Vĩnh Quang",
                        "code": 15352,
                        "codename": "xa_vinh_quang",
                        "division_type": "xã",
                        "short_codename": "vinh_quang"
                    },
                    {
                        "name": "Xã Vĩnh Yên",
                        "code": 15355,
                        "codename": "xa_vinh_yen",
                        "division_type": "xã",
                        "short_codename": "vinh_yen"
                    },
                    {
                        "name": "Xã Vĩnh Tiến",
                        "code": 15358,
                        "codename": "xa_vinh_tien",
                        "division_type": "xã",
                        "short_codename": "vinh_tien"
                    },
                    {
                        "name": "Xã Vĩnh Long",
                        "code": 15361,
                        "codename": "xa_vinh_long",
                        "division_type": "xã",
                        "short_codename": "vinh_long"
                    },
                    {
                        "name": "Xã Vĩnh Phúc",
                        "code": 15364,
                        "codename": "xa_vinh_phuc",
                        "division_type": "xã",
                        "short_codename": "vinh_phuc"
                    },
                    {
                        "name": "Xã Vĩnh Hưng",
                        "code": 15367,
                        "codename": "xa_vinh_hung",
                        "division_type": "xã",
                        "short_codename": "xa_vinh_hung"
                    },
                    {
                        "name": "Xã Vĩnh Hòa",
                        "code": 15376,
                        "codename": "xa_vinh_hoa",
                        "division_type": "xã",
                        "short_codename": "vinh_hoa"
                    },
                    {
                        "name": "Xã Vĩnh Hùng",
                        "code": 15379,
                        "codename": "xa_vinh_hung",
                        "division_type": "xã",
                        "short_codename": "xa_vinh_hung"
                    },
                    {
                        "name": "Xã Minh Tân",
                        "code": 15382,
                        "codename": "xa_minh_tan",
                        "division_type": "xã",
                        "short_codename": "minh_tan"
                    },
                    {
                        "name": "Xã Ninh Khang",
                        "code": 15385,
                        "codename": "xa_ninh_khang",
                        "division_type": "xã",
                        "short_codename": "ninh_khang"
                    },
                    {
                        "name": "Xã Vĩnh Thịnh",
                        "code": 15388,
                        "codename": "xa_vinh_thinh",
                        "division_type": "xã",
                        "short_codename": "vinh_thinh"
                    },
                    {
                        "name": "Xã Vĩnh An",
                        "code": 15391,
                        "codename": "xa_vinh_an",
                        "division_type": "xã",
                        "short_codename": "vinh_an"
                    }
                ]
            },
            {
                "name": "Huyện Yên Định",
                "code": 394,
                "codename": "huyen_yen_dinh",
                "division_type": "huyện",
                "short_codename": "yen_dinh",
                "wards": [
                    {
                        "name": "Thị trấn Thống Nhất",
                        "code": 15397,
                        "codename": "thi_tran_thong_nhat",
                        "division_type": "thị trấn",
                        "short_codename": "thong_nhat"
                    },
                    {
                        "name": "Xã Yên Lâm",
                        "code": 15403,
                        "codename": "xa_yen_lam",
                        "division_type": "xã",
                        "short_codename": "yen_lam"
                    },
                    {
                        "name": "Xã Yên Tâm",
                        "code": 15406,
                        "codename": "xa_yen_tam",
                        "division_type": "xã",
                        "short_codename": "yen_tam"
                    },
                    {
                        "name": "Xã Yên Phú",
                        "code": 15409,
                        "codename": "xa_yen_phu",
                        "division_type": "xã",
                        "short_codename": "yen_phu"
                    },
                    {
                        "name": "Xã Quí Lộc",
                        "code": 15412,
                        "codename": "xa_qui_loc",
                        "division_type": "xã",
                        "short_codename": "qui_loc"
                    },
                    {
                        "name": "Xã Yên Thọ",
                        "code": 15415,
                        "codename": "xa_yen_tho",
                        "division_type": "xã",
                        "short_codename": "yen_tho"
                    },
                    {
                        "name": "Xã Yên Trung",
                        "code": 15418,
                        "codename": "xa_yen_trung",
                        "division_type": "xã",
                        "short_codename": "yen_trung"
                    },
                    {
                        "name": "Xã Yên Trường",
                        "code": 15421,
                        "codename": "xa_yen_truong",
                        "division_type": "xã",
                        "short_codename": "yen_truong"
                    },
                    {
                        "name": "Xã Yên Phong",
                        "code": 15427,
                        "codename": "xa_yen_phong",
                        "division_type": "xã",
                        "short_codename": "yen_phong"
                    },
                    {
                        "name": "Xã Yên Thái",
                        "code": 15430,
                        "codename": "xa_yen_thai",
                        "division_type": "xã",
                        "short_codename": "yen_thai"
                    },
                    {
                        "name": "Xã Yên Hùng",
                        "code": 15433,
                        "codename": "xa_yen_hung",
                        "division_type": "xã",
                        "short_codename": "yen_hung"
                    },
                    {
                        "name": "Xã Yên Thịnh",
                        "code": 15436,
                        "codename": "xa_yen_thinh",
                        "division_type": "xã",
                        "short_codename": "yen_thinh"
                    },
                    {
                        "name": "Xã Yên Ninh",
                        "code": 15439,
                        "codename": "xa_yen_ninh",
                        "division_type": "xã",
                        "short_codename": "yen_ninh"
                    },
                    {
                        "name": "Xã Yên Lạc",
                        "code": 15442,
                        "codename": "xa_yen_lac",
                        "division_type": "xã",
                        "short_codename": "yen_lac"
                    },
                    {
                        "name": "Xã Định Tăng",
                        "code": 15445,
                        "codename": "xa_dinh_tang",
                        "division_type": "xã",
                        "short_codename": "dinh_tang"
                    },
                    {
                        "name": "Xã Định Hòa",
                        "code": 15448,
                        "codename": "xa_dinh_hoa",
                        "division_type": "xã",
                        "short_codename": "dinh_hoa"
                    },
                    {
                        "name": "Xã Định Thành",
                        "code": 15451,
                        "codename": "xa_dinh_thanh",
                        "division_type": "xã",
                        "short_codename": "dinh_thanh"
                    },
                    {
                        "name": "Xã Định Công",
                        "code": 15454,
                        "codename": "xa_dinh_cong",
                        "division_type": "xã",
                        "short_codename": "dinh_cong"
                    },
                    {
                        "name": "Xã Định Tân",
                        "code": 15457,
                        "codename": "xa_dinh_tan",
                        "division_type": "xã",
                        "short_codename": "dinh_tan"
                    },
                    {
                        "name": "Xã Định Tiến",
                        "code": 15460,
                        "codename": "xa_dinh_tien",
                        "division_type": "xã",
                        "short_codename": "dinh_tien"
                    },
                    {
                        "name": "Xã Định Long",
                        "code": 15463,
                        "codename": "xa_dinh_long",
                        "division_type": "xã",
                        "short_codename": "dinh_long"
                    },
                    {
                        "name": "Xã Định Liên",
                        "code": 15466,
                        "codename": "xa_dinh_lien",
                        "division_type": "xã",
                        "short_codename": "dinh_lien"
                    },
                    {
                        "name": "Thị trấn Quán Lào",
                        "code": 15469,
                        "codename": "thi_tran_quan_lao",
                        "division_type": "thị trấn",
                        "short_codename": "quan_lao"
                    },
                    {
                        "name": "Xã Định Hưng",
                        "code": 15472,
                        "codename": "xa_dinh_hung",
                        "division_type": "xã",
                        "short_codename": "dinh_hung"
                    },
                    {
                        "name": "Xã Định Hải",
                        "code": 15475,
                        "codename": "xa_dinh_hai",
                        "division_type": "xã",
                        "short_codename": "dinh_hai"
                    },
                    {
                        "name": "Xã Định Bình",
                        "code": 15478,
                        "codename": "xa_dinh_binh",
                        "division_type": "xã",
                        "short_codename": "dinh_binh"
                    }
                ]
            },
            {
                "name": "Huyện Thọ Xuân",
                "code": 395,
                "codename": "huyen_tho_xuan",
                "division_type": "huyện",
                "short_codename": "tho_xuan",
                "wards": [
                    {
                        "name": "Xã Xuân Hồng",
                        "code": 15493,
                        "codename": "xa_xuan_hong",
                        "division_type": "xã",
                        "short_codename": "xuan_hong"
                    },
                    {
                        "name": "Thị trấn Thọ Xuân",
                        "code": 15499,
                        "codename": "thi_tran_tho_xuan",
                        "division_type": "thị trấn",
                        "short_codename": "tho_xuan"
                    },
                    {
                        "name": "Xã Bắc Lương",
                        "code": 15502,
                        "codename": "xa_bac_luong",
                        "division_type": "xã",
                        "short_codename": "bac_luong"
                    },
                    {
                        "name": "Xã Nam Giang",
                        "code": 15505,
                        "codename": "xa_nam_giang",
                        "division_type": "xã",
                        "short_codename": "nam_giang"
                    },
                    {
                        "name": "Xã Xuân Phong",
                        "code": 15508,
                        "codename": "xa_xuan_phong",
                        "division_type": "xã",
                        "short_codename": "xuan_phong"
                    },
                    {
                        "name": "Xã Thọ Lộc",
                        "code": 15511,
                        "codename": "xa_tho_loc",
                        "division_type": "xã",
                        "short_codename": "tho_loc"
                    },
                    {
                        "name": "Xã Xuân Trường",
                        "code": 15514,
                        "codename": "xa_xuan_truong",
                        "division_type": "xã",
                        "short_codename": "xuan_truong"
                    },
                    {
                        "name": "Xã Xuân Hòa",
                        "code": 15517,
                        "codename": "xa_xuan_hoa",
                        "division_type": "xã",
                        "short_codename": "xuan_hoa"
                    },
                    {
                        "name": "Xã Thọ Hải",
                        "code": 15520,
                        "codename": "xa_tho_hai",
                        "division_type": "xã",
                        "short_codename": "tho_hai"
                    },
                    {
                        "name": "Xã Tây Hồ",
                        "code": 15523,
                        "codename": "xa_tay_ho",
                        "division_type": "xã",
                        "short_codename": "tay_ho"
                    },
                    {
                        "name": "Xã Xuân Giang",
                        "code": 15526,
                        "codename": "xa_xuan_giang",
                        "division_type": "xã",
                        "short_codename": "xuan_giang"
                    },
                    {
                        "name": "Xã Xuân Sinh",
                        "code": 15532,
                        "codename": "xa_xuan_sinh",
                        "division_type": "xã",
                        "short_codename": "xuan_sinh"
                    },
                    {
                        "name": "Xã Xuân Hưng",
                        "code": 15535,
                        "codename": "xa_xuan_hung",
                        "division_type": "xã",
                        "short_codename": "xuan_hung"
                    },
                    {
                        "name": "Xã Thọ Diên",
                        "code": 15538,
                        "codename": "xa_tho_dien",
                        "division_type": "xã",
                        "short_codename": "tho_dien"
                    },
                    {
                        "name": "Xã Thọ Lâm",
                        "code": 15541,
                        "codename": "xa_tho_lam",
                        "division_type": "xã",
                        "short_codename": "tho_lam"
                    },
                    {
                        "name": "Xã Thọ Xương",
                        "code": 15544,
                        "codename": "xa_tho_xuong",
                        "division_type": "xã",
                        "short_codename": "tho_xuong"
                    },
                    {
                        "name": "Xã Xuân Bái",
                        "code": 15547,
                        "codename": "xa_xuan_bai",
                        "division_type": "xã",
                        "short_codename": "xuan_bai"
                    },
                    {
                        "name": "Xã Xuân Phú",
                        "code": 15550,
                        "codename": "xa_xuan_phu",
                        "division_type": "xã",
                        "short_codename": "xuan_phu"
                    },
                    {
                        "name": "Thị trấn Sao Vàng",
                        "code": 15553,
                        "codename": "thi_tran_sao_vang",
                        "division_type": "thị trấn",
                        "short_codename": "sao_vang"
                    },
                    {
                        "name": "Thị trấn Lam Sơn",
                        "code": 15556,
                        "codename": "thi_tran_lam_son",
                        "division_type": "thị trấn",
                        "short_codename": "lam_son"
                    },
                    {
                        "name": "Xã Xuân Thiên",
                        "code": 15559,
                        "codename": "xa_xuan_thien",
                        "division_type": "xã",
                        "short_codename": "xuan_thien"
                    },
                    {
                        "name": "Xã Thuận Minh",
                        "code": 15565,
                        "codename": "xa_thuan_minh",
                        "division_type": "xã",
                        "short_codename": "thuan_minh"
                    },
                    {
                        "name": "Xã Thọ Lập",
                        "code": 15568,
                        "codename": "xa_tho_lap",
                        "division_type": "xã",
                        "short_codename": "tho_lap"
                    },
                    {
                        "name": "Xã Quảng Phú",
                        "code": 15571,
                        "codename": "xa_quang_phu",
                        "division_type": "xã",
                        "short_codename": "quang_phu"
                    },
                    {
                        "name": "Xã Xuân Tín",
                        "code": 15574,
                        "codename": "xa_xuan_tin",
                        "division_type": "xã",
                        "short_codename": "xuan_tin"
                    },
                    {
                        "name": "Xã Phú Xuân",
                        "code": 15577,
                        "codename": "xa_phu_xuan",
                        "division_type": "xã",
                        "short_codename": "phu_xuan"
                    },
                    {
                        "name": "Xã Xuân Lai",
                        "code": 15583,
                        "codename": "xa_xuan_lai",
                        "division_type": "xã",
                        "short_codename": "xuan_lai"
                    },
                    {
                        "name": "Xã Xuân Lập",
                        "code": 15586,
                        "codename": "xa_xuan_lap",
                        "division_type": "xã",
                        "short_codename": "xuan_lap"
                    },
                    {
                        "name": "Xã Xuân Minh",
                        "code": 15592,
                        "codename": "xa_xuan_minh",
                        "division_type": "xã",
                        "short_codename": "xuan_minh"
                    },
                    {
                        "name": "Xã Trường Xuân",
                        "code": 15598,
                        "codename": "xa_truong_xuan",
                        "division_type": "xã",
                        "short_codename": "truong_xuan"
                    }
                ]
            },
            {
                "name": "Huyện Thường Xuân",
                "code": 396,
                "codename": "huyen_thuong_xuan",
                "division_type": "huyện",
                "short_codename": "thuong_xuan",
                "wards": [
                    {
                        "name": "Xã Bát Mọt",
                        "code": 15607,
                        "codename": "xa_bat_mot",
                        "division_type": "xã",
                        "short_codename": "bat_mot"
                    },
                    {
                        "name": "Xã Yên Nhân",
                        "code": 15610,
                        "codename": "xa_yen_nhan",
                        "division_type": "xã",
                        "short_codename": "yen_nhan"
                    },
                    {
                        "name": "Xã Xuân Lẹ",
                        "code": 15619,
                        "codename": "xa_xuan_le",
                        "division_type": "xã",
                        "short_codename": "xuan_le"
                    },
                    {
                        "name": "Xã Vạn Xuân",
                        "code": 15622,
                        "codename": "xa_van_xuan",
                        "division_type": "xã",
                        "short_codename": "van_xuan"
                    },
                    {
                        "name": "Xã Lương Sơn",
                        "code": 15628,
                        "codename": "xa_luong_son",
                        "division_type": "xã",
                        "short_codename": "luong_son"
                    },
                    {
                        "name": "Xã Xuân Cao",
                        "code": 15631,
                        "codename": "xa_xuan_cao",
                        "division_type": "xã",
                        "short_codename": "xuan_cao"
                    },
                    {
                        "name": "Xã Luận Thành",
                        "code": 15634,
                        "codename": "xa_luan_thanh",
                        "division_type": "xã",
                        "short_codename": "luan_thanh"
                    },
                    {
                        "name": "Xã Luận Khê",
                        "code": 15637,
                        "codename": "xa_luan_khe",
                        "division_type": "xã",
                        "short_codename": "luan_khe"
                    },
                    {
                        "name": "Xã Xuân Thắng",
                        "code": 15640,
                        "codename": "xa_xuan_thang",
                        "division_type": "xã",
                        "short_codename": "xuan_thang"
                    },
                    {
                        "name": "Xã Xuân Lộc",
                        "code": 15643,
                        "codename": "xa_xuan_loc",
                        "division_type": "xã",
                        "short_codename": "xuan_loc"
                    },
                    {
                        "name": "Thị trấn Thường Xuân",
                        "code": 15646,
                        "codename": "thi_tran_thuong_xuan",
                        "division_type": "thị trấn",
                        "short_codename": "thuong_xuan"
                    },
                    {
                        "name": "Xã Xuân Dương",
                        "code": 15649,
                        "codename": "xa_xuan_duong",
                        "division_type": "xã",
                        "short_codename": "xuan_duong"
                    },
                    {
                        "name": "Xã Thọ Thanh",
                        "code": 15652,
                        "codename": "xa_tho_thanh",
                        "division_type": "xã",
                        "short_codename": "tho_thanh"
                    },
                    {
                        "name": "Xã Ngọc Phụng",
                        "code": 15655,
                        "codename": "xa_ngoc_phung",
                        "division_type": "xã",
                        "short_codename": "ngoc_phung"
                    },
                    {
                        "name": "Xã Xuân Chinh",
                        "code": 15658,
                        "codename": "xa_xuan_chinh",
                        "division_type": "xã",
                        "short_codename": "xuan_chinh"
                    },
                    {
                        "name": "Xã Tân Thành",
                        "code": 15661,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    }
                ]
            },
            {
                "name": "Huyện Triệu Sơn",
                "code": 397,
                "codename": "huyen_trieu_son",
                "division_type": "huyện",
                "short_codename": "trieu_son",
                "wards": [
                    {
                        "name": "Thị trấn Triệu Sơn",
                        "code": 15664,
                        "codename": "thi_tran_trieu_son",
                        "division_type": "thị trấn",
                        "short_codename": "trieu_son"
                    },
                    {
                        "name": "Xã Thọ Sơn",
                        "code": 15667,
                        "codename": "xa_tho_son",
                        "division_type": "xã",
                        "short_codename": "tho_son"
                    },
                    {
                        "name": "Xã Thọ Bình",
                        "code": 15670,
                        "codename": "xa_tho_binh",
                        "division_type": "xã",
                        "short_codename": "tho_binh"
                    },
                    {
                        "name": "Xã Thọ Tiến",
                        "code": 15673,
                        "codename": "xa_tho_tien",
                        "division_type": "xã",
                        "short_codename": "tho_tien"
                    },
                    {
                        "name": "Xã Hợp Lý",
                        "code": 15676,
                        "codename": "xa_hop_ly",
                        "division_type": "xã",
                        "short_codename": "hop_ly"
                    },
                    {
                        "name": "Xã Hợp Tiến",
                        "code": 15679,
                        "codename": "xa_hop_tien",
                        "division_type": "xã",
                        "short_codename": "hop_tien"
                    },
                    {
                        "name": "Xã Hợp Thành",
                        "code": 15682,
                        "codename": "xa_hop_thanh",
                        "division_type": "xã",
                        "short_codename": "hop_thanh"
                    },
                    {
                        "name": "Xã Triệu Thành",
                        "code": 15685,
                        "codename": "xa_trieu_thanh",
                        "division_type": "xã",
                        "short_codename": "trieu_thanh"
                    },
                    {
                        "name": "Xã Hợp Thắng",
                        "code": 15688,
                        "codename": "xa_hop_thang",
                        "division_type": "xã",
                        "short_codename": "hop_thang"
                    },
                    {
                        "name": "Xã Minh Sơn",
                        "code": 15691,
                        "codename": "xa_minh_son",
                        "division_type": "xã",
                        "short_codename": "minh_son"
                    },
                    {
                        "name": "Xã Dân Lực",
                        "code": 15700,
                        "codename": "xa_dan_luc",
                        "division_type": "xã",
                        "short_codename": "dan_luc"
                    },
                    {
                        "name": "Xã Dân Lý",
                        "code": 15703,
                        "codename": "xa_dan_ly",
                        "division_type": "xã",
                        "short_codename": "dan_ly"
                    },
                    {
                        "name": "Xã Dân Quyền",
                        "code": 15706,
                        "codename": "xa_dan_quyen",
                        "division_type": "xã",
                        "short_codename": "dan_quyen"
                    },
                    {
                        "name": "Xã An Nông",
                        "code": 15709,
                        "codename": "xa_an_nong",
                        "division_type": "xã",
                        "short_codename": "an_nong"
                    },
                    {
                        "name": "Xã Văn Sơn",
                        "code": 15712,
                        "codename": "xa_van_son",
                        "division_type": "xã",
                        "short_codename": "van_son"
                    },
                    {
                        "name": "Xã Thái Hòa",
                        "code": 15715,
                        "codename": "xa_thai_hoa",
                        "division_type": "xã",
                        "short_codename": "thai_hoa"
                    },
                    {
                        "name": "Thị trấn Nưa",
                        "code": 15718,
                        "codename": "thi_tran_nua",
                        "division_type": "thị trấn",
                        "short_codename": "nua"
                    },
                    {
                        "name": "Xã Đồng Lợi",
                        "code": 15721,
                        "codename": "xa_dong_loi",
                        "division_type": "xã",
                        "short_codename": "dong_loi"
                    },
                    {
                        "name": "Xã Đồng Tiến",
                        "code": 15724,
                        "codename": "xa_dong_tien",
                        "division_type": "xã",
                        "short_codename": "dong_tien"
                    },
                    {
                        "name": "Xã Đồng Thắng",
                        "code": 15727,
                        "codename": "xa_dong_thang",
                        "division_type": "xã",
                        "short_codename": "dong_thang"
                    },
                    {
                        "name": "Xã Tiến Nông",
                        "code": 15730,
                        "codename": "xa_tien_nong",
                        "division_type": "xã",
                        "short_codename": "tien_nong"
                    },
                    {
                        "name": "Xã Khuyến Nông",
                        "code": 15733,
                        "codename": "xa_khuyen_nong",
                        "division_type": "xã",
                        "short_codename": "khuyen_nong"
                    },
                    {
                        "name": "Xã Xuân Thịnh",
                        "code": 15736,
                        "codename": "xa_xuan_thinh",
                        "division_type": "xã",
                        "short_codename": "xuan_thinh"
                    },
                    {
                        "name": "Xã Xuân Lộc",
                        "code": 15739,
                        "codename": "xa_xuan_loc",
                        "division_type": "xã",
                        "short_codename": "xuan_loc"
                    },
                    {
                        "name": "Xã Thọ Dân",
                        "code": 15742,
                        "codename": "xa_tho_dan",
                        "division_type": "xã",
                        "short_codename": "tho_dan"
                    },
                    {
                        "name": "Xã Xuân Thọ",
                        "code": 15745,
                        "codename": "xa_xuan_tho",
                        "division_type": "xã",
                        "short_codename": "xuan_tho"
                    },
                    {
                        "name": "Xã Thọ Tân",
                        "code": 15748,
                        "codename": "xa_tho_tan",
                        "division_type": "xã",
                        "short_codename": "tho_tan"
                    },
                    {
                        "name": "Xã Thọ Ngọc",
                        "code": 15751,
                        "codename": "xa_tho_ngoc",
                        "division_type": "xã",
                        "short_codename": "tho_ngoc"
                    },
                    {
                        "name": "Xã Thọ Cường",
                        "code": 15754,
                        "codename": "xa_tho_cuong",
                        "division_type": "xã",
                        "short_codename": "tho_cuong"
                    },
                    {
                        "name": "Xã Thọ Phú",
                        "code": 15757,
                        "codename": "xa_tho_phu",
                        "division_type": "xã",
                        "short_codename": "tho_phu"
                    },
                    {
                        "name": "Xã Thọ Vực",
                        "code": 15760,
                        "codename": "xa_tho_vuc",
                        "division_type": "xã",
                        "short_codename": "tho_vuc"
                    },
                    {
                        "name": "Xã Thọ Thế",
                        "code": 15763,
                        "codename": "xa_tho_the",
                        "division_type": "xã",
                        "short_codename": "tho_the"
                    },
                    {
                        "name": "Xã Nông Trường",
                        "code": 15766,
                        "codename": "xa_nong_truong",
                        "division_type": "xã",
                        "short_codename": "nong_truong"
                    },
                    {
                        "name": "Xã Bình Sơn",
                        "code": 15769,
                        "codename": "xa_binh_son",
                        "division_type": "xã",
                        "short_codename": "binh_son"
                    }
                ]
            },
            {
                "name": "Huyện Thiệu Hóa",
                "code": 398,
                "codename": "huyen_thieu_hoa",
                "division_type": "huyện",
                "short_codename": "thieu_hoa",
                "wards": [
                    {
                        "name": "Thị trấn Thiệu Hóa",
                        "code": 15772,
                        "codename": "thi_tran_thieu_hoa",
                        "division_type": "thị trấn",
                        "short_codename": "thieu_hoa"
                    },
                    {
                        "name": "Xã Thiệu Ngọc",
                        "code": 15775,
                        "codename": "xa_thieu_ngoc",
                        "division_type": "xã",
                        "short_codename": "thieu_ngoc"
                    },
                    {
                        "name": "Xã Thiệu Vũ",
                        "code": 15778,
                        "codename": "xa_thieu_vu",
                        "division_type": "xã",
                        "short_codename": "thieu_vu"
                    },
                    {
                        "name": "Xã Thiệu Phúc",
                        "code": 15781,
                        "codename": "xa_thieu_phuc",
                        "division_type": "xã",
                        "short_codename": "thieu_phuc"
                    },
                    {
                        "name": "Xã Thiệu Tiến",
                        "code": 15784,
                        "codename": "xa_thieu_tien",
                        "division_type": "xã",
                        "short_codename": "thieu_tien"
                    },
                    {
                        "name": "Xã Thiệu Công",
                        "code": 15787,
                        "codename": "xa_thieu_cong",
                        "division_type": "xã",
                        "short_codename": "thieu_cong"
                    },
                    {
                        "name": "Xã Thiệu Phú",
                        "code": 15790,
                        "codename": "xa_thieu_phu",
                        "division_type": "xã",
                        "short_codename": "thieu_phu"
                    },
                    {
                        "name": "Xã Thiệu Long",
                        "code": 15793,
                        "codename": "xa_thieu_long",
                        "division_type": "xã",
                        "short_codename": "thieu_long"
                    },
                    {
                        "name": "Xã Thiệu Giang",
                        "code": 15796,
                        "codename": "xa_thieu_giang",
                        "division_type": "xã",
                        "short_codename": "thieu_giang"
                    },
                    {
                        "name": "Xã Thiệu Duy",
                        "code": 15799,
                        "codename": "xa_thieu_duy",
                        "division_type": "xã",
                        "short_codename": "thieu_duy"
                    },
                    {
                        "name": "Xã Thiệu Nguyên",
                        "code": 15802,
                        "codename": "xa_thieu_nguyen",
                        "division_type": "xã",
                        "short_codename": "thieu_nguyen"
                    },
                    {
                        "name": "Xã Thiệu Hợp",
                        "code": 15805,
                        "codename": "xa_thieu_hop",
                        "division_type": "xã",
                        "short_codename": "thieu_hop"
                    },
                    {
                        "name": "Xã Thiệu Thịnh",
                        "code": 15808,
                        "codename": "xa_thieu_thinh",
                        "division_type": "xã",
                        "short_codename": "thieu_thinh"
                    },
                    {
                        "name": "Xã Thiệu Quang",
                        "code": 15811,
                        "codename": "xa_thieu_quang",
                        "division_type": "xã",
                        "short_codename": "thieu_quang"
                    },
                    {
                        "name": "Xã Thiệu Thành",
                        "code": 15814,
                        "codename": "xa_thieu_thanh",
                        "division_type": "xã",
                        "short_codename": "thieu_thanh"
                    },
                    {
                        "name": "Xã Thiệu Toán",
                        "code": 15817,
                        "codename": "xa_thieu_toan",
                        "division_type": "xã",
                        "short_codename": "thieu_toan"
                    },
                    {
                        "name": "Xã Thiệu Chính",
                        "code": 15820,
                        "codename": "xa_thieu_chinh",
                        "division_type": "xã",
                        "short_codename": "thieu_chinh"
                    },
                    {
                        "name": "Xã Thiệu Hòa",
                        "code": 15823,
                        "codename": "xa_thieu_hoa",
                        "division_type": "xã",
                        "short_codename": "xa_thieu_hoa"
                    },
                    {
                        "name": "Xã Minh Tâm",
                        "code": 15829,
                        "codename": "xa_minh_tam",
                        "division_type": "xã",
                        "short_codename": "minh_tam"
                    },
                    {
                        "name": "Xã Thiệu Viên",
                        "code": 15832,
                        "codename": "xa_thieu_vien",
                        "division_type": "xã",
                        "short_codename": "thieu_vien"
                    },
                    {
                        "name": "Xã Thiệu Lý",
                        "code": 15835,
                        "codename": "xa_thieu_ly",
                        "division_type": "xã",
                        "short_codename": "thieu_ly"
                    },
                    {
                        "name": "Xã Thiệu Vận",
                        "code": 15838,
                        "codename": "xa_thieu_van",
                        "division_type": "xã",
                        "short_codename": "thieu_van"
                    },
                    {
                        "name": "Xã Thiệu Trung",
                        "code": 15841,
                        "codename": "xa_thieu_trung",
                        "division_type": "xã",
                        "short_codename": "thieu_trung"
                    },
                    {
                        "name": "Xã Tân Châu",
                        "code": 15847,
                        "codename": "xa_tan_chau",
                        "division_type": "xã",
                        "short_codename": "tan_chau"
                    },
                    {
                        "name": "Xã Thiệu Giao",
                        "code": 15853,
                        "codename": "xa_thieu_giao",
                        "division_type": "xã",
                        "short_codename": "thieu_giao"
                    }
                ]
            },
            {
                "name": "Huyện Hoằng Hóa",
                "code": 399,
                "codename": "huyen_hoang_hoa",
                "division_type": "huyện",
                "short_codename": "hoang_hoa",
                "wards": [
                    {
                        "name": "Thị trấn Bút Sơn",
                        "code": 15865,
                        "codename": "thi_tran_but_son",
                        "division_type": "thị trấn",
                        "short_codename": "but_son"
                    },
                    {
                        "name": "Xã Hoằng Giang",
                        "code": 15871,
                        "codename": "xa_hoang_giang",
                        "division_type": "xã",
                        "short_codename": "hoang_giang"
                    },
                    {
                        "name": "Xã Hoằng Xuân",
                        "code": 15877,
                        "codename": "xa_hoang_xuan",
                        "division_type": "xã",
                        "short_codename": "hoang_xuan"
                    },
                    {
                        "name": "Xã Hoằng Phượng",
                        "code": 15880,
                        "codename": "xa_hoang_phuong",
                        "division_type": "xã",
                        "short_codename": "hoang_phuong"
                    },
                    {
                        "name": "Xã Hoằng Phú",
                        "code": 15883,
                        "codename": "xa_hoang_phu",
                        "division_type": "xã",
                        "short_codename": "xa_hoang_phu"
                    },
                    {
                        "name": "Xã Hoằng Quỳ",
                        "code": 15886,
                        "codename": "xa_hoang_quy",
                        "division_type": "xã",
                        "short_codename": "xa_hoang_quy"
                    },
                    {
                        "name": "Xã Hoằng Kim",
                        "code": 15889,
                        "codename": "xa_hoang_kim",
                        "division_type": "xã",
                        "short_codename": "hoang_kim"
                    },
                    {
                        "name": "Xã Hoằng Trung",
                        "code": 15892,
                        "codename": "xa_hoang_trung",
                        "division_type": "xã",
                        "short_codename": "hoang_trung"
                    },
                    {
                        "name": "Xã Hoằng Trinh",
                        "code": 15895,
                        "codename": "xa_hoang_trinh",
                        "division_type": "xã",
                        "short_codename": "hoang_trinh"
                    },
                    {
                        "name": "Xã Hoằng Sơn",
                        "code": 15901,
                        "codename": "xa_hoang_son",
                        "division_type": "xã",
                        "short_codename": "hoang_son"
                    },
                    {
                        "name": "Xã Hoằng Cát",
                        "code": 15907,
                        "codename": "xa_hoang_cat",
                        "division_type": "xã",
                        "short_codename": "hoang_cat"
                    },
                    {
                        "name": "Xã Hoằng Xuyên",
                        "code": 15910,
                        "codename": "xa_hoang_xuyen",
                        "division_type": "xã",
                        "short_codename": "hoang_xuyen"
                    },
                    {
                        "name": "Xã Hoằng Quý",
                        "code": 15916,
                        "codename": "xa_hoang_quy",
                        "division_type": "xã",
                        "short_codename": "xa_hoang_quy"
                    },
                    {
                        "name": "Xã Hoằng Hợp",
                        "code": 15919,
                        "codename": "xa_hoang_hop",
                        "division_type": "xã",
                        "short_codename": "hoang_hop"
                    },
                    {
                        "name": "Xã Hoằng Đức",
                        "code": 15928,
                        "codename": "xa_hoang_duc",
                        "division_type": "xã",
                        "short_codename": "hoang_duc"
                    },
                    {
                        "name": "Xã Hoằng Hà",
                        "code": 15937,
                        "codename": "xa_hoang_ha",
                        "division_type": "xã",
                        "short_codename": "hoang_ha"
                    },
                    {
                        "name": "Xã Hoằng Đạt",
                        "code": 15940,
                        "codename": "xa_hoang_dat",
                        "division_type": "xã",
                        "short_codename": "hoang_dat"
                    },
                    {
                        "name": "Xã Hoằng Đạo",
                        "code": 15946,
                        "codename": "xa_hoang_dao",
                        "division_type": "xã",
                        "short_codename": "hoang_dao"
                    },
                    {
                        "name": "Xã Hoằng Thắng",
                        "code": 15949,
                        "codename": "xa_hoang_thang",
                        "division_type": "xã",
                        "short_codename": "hoang_thang"
                    },
                    {
                        "name": "Xã Hoằng Đồng",
                        "code": 15952,
                        "codename": "xa_hoang_dong",
                        "division_type": "xã",
                        "short_codename": "xa_hoang_dong"
                    },
                    {
                        "name": "Xã Hoằng Thái",
                        "code": 15955,
                        "codename": "xa_hoang_thai",
                        "division_type": "xã",
                        "short_codename": "hoang_thai"
                    },
                    {
                        "name": "Xã Hoằng Thịnh",
                        "code": 15958,
                        "codename": "xa_hoang_thinh",
                        "division_type": "xã",
                        "short_codename": "hoang_thinh"
                    },
                    {
                        "name": "Xã Hoằng Thành",
                        "code": 15961,
                        "codename": "xa_hoang_thanh",
                        "division_type": "xã",
                        "short_codename": "xa_hoang_thanh"
                    },
                    {
                        "name": "Xã Hoằng Lộc",
                        "code": 15964,
                        "codename": "xa_hoang_loc",
                        "division_type": "xã",
                        "short_codename": "hoang_loc"
                    },
                    {
                        "name": "Xã Hoằng Trạch",
                        "code": 15967,
                        "codename": "xa_hoang_trach",
                        "division_type": "xã",
                        "short_codename": "hoang_trach"
                    },
                    {
                        "name": "Xã Hoằng Phong",
                        "code": 15973,
                        "codename": "xa_hoang_phong",
                        "division_type": "xã",
                        "short_codename": "hoang_phong"
                    },
                    {
                        "name": "Xã Hoằng Lưu",
                        "code": 15976,
                        "codename": "xa_hoang_luu",
                        "division_type": "xã",
                        "short_codename": "hoang_luu"
                    },
                    {
                        "name": "Xã Hoằng Châu",
                        "code": 15979,
                        "codename": "xa_hoang_chau",
                        "division_type": "xã",
                        "short_codename": "hoang_chau"
                    },
                    {
                        "name": "Xã Hoằng Tân",
                        "code": 15982,
                        "codename": "xa_hoang_tan",
                        "division_type": "xã",
                        "short_codename": "hoang_tan"
                    },
                    {
                        "name": "Xã Hoằng Yến",
                        "code": 15985,
                        "codename": "xa_hoang_yen",
                        "division_type": "xã",
                        "short_codename": "hoang_yen"
                    },
                    {
                        "name": "Xã Hoằng Tiến",
                        "code": 15988,
                        "codename": "xa_hoang_tien",
                        "division_type": "xã",
                        "short_codename": "hoang_tien"
                    },
                    {
                        "name": "Xã Hoằng Hải",
                        "code": 15991,
                        "codename": "xa_hoang_hai",
                        "division_type": "xã",
                        "short_codename": "hoang_hai"
                    },
                    {
                        "name": "Xã Hoằng Ngọc",
                        "code": 15994,
                        "codename": "xa_hoang_ngoc",
                        "division_type": "xã",
                        "short_codename": "hoang_ngoc"
                    },
                    {
                        "name": "Xã Hoằng Đông",
                        "code": 15997,
                        "codename": "xa_hoang_dong",
                        "division_type": "xã",
                        "short_codename": "xa_hoang_dong"
                    },
                    {
                        "name": "Xã Hoằng Thanh",
                        "code": 16000,
                        "codename": "xa_hoang_thanh",
                        "division_type": "xã",
                        "short_codename": "xa_hoang_thanh"
                    },
                    {
                        "name": "Xã Hoằng Phụ",
                        "code": 16003,
                        "codename": "xa_hoang_phu",
                        "division_type": "xã",
                        "short_codename": "xa_hoang_phu"
                    },
                    {
                        "name": "Xã Hoằng Trường",
                        "code": 16006,
                        "codename": "xa_hoang_truong",
                        "division_type": "xã",
                        "short_codename": "hoang_truong"
                    }
                ]
            },
            {
                "name": "Huyện Hậu Lộc",
                "code": 400,
                "codename": "huyen_hau_loc",
                "division_type": "huyện",
                "short_codename": "hau_loc",
                "wards": [
                    {
                        "name": "Thị trấn Hậu Lộc",
                        "code": 16012,
                        "codename": "thi_tran_hau_loc",
                        "division_type": "thị trấn",
                        "short_codename": "hau_loc"
                    },
                    {
                        "name": "Xã Đồng Lộc",
                        "code": 16015,
                        "codename": "xa_dong_loc",
                        "division_type": "xã",
                        "short_codename": "dong_loc"
                    },
                    {
                        "name": "Xã Đại Lộc",
                        "code": 16018,
                        "codename": "xa_dai_loc",
                        "division_type": "xã",
                        "short_codename": "dai_loc"
                    },
                    {
                        "name": "Xã Triệu Lộc",
                        "code": 16021,
                        "codename": "xa_trieu_loc",
                        "division_type": "xã",
                        "short_codename": "trieu_loc"
                    },
                    {
                        "name": "Xã Tiến Lộc",
                        "code": 16027,
                        "codename": "xa_tien_loc",
                        "division_type": "xã",
                        "short_codename": "tien_loc"
                    },
                    {
                        "name": "Xã Lộc Sơn",
                        "code": 16030,
                        "codename": "xa_loc_son",
                        "division_type": "xã",
                        "short_codename": "loc_son"
                    },
                    {
                        "name": "Xã Cầu Lộc",
                        "code": 16033,
                        "codename": "xa_cau_loc",
                        "division_type": "xã",
                        "short_codename": "cau_loc"
                    },
                    {
                        "name": "Xã Thành Lộc",
                        "code": 16036,
                        "codename": "xa_thanh_loc",
                        "division_type": "xã",
                        "short_codename": "thanh_loc"
                    },
                    {
                        "name": "Xã Tuy Lộc",
                        "code": 16039,
                        "codename": "xa_tuy_loc",
                        "division_type": "xã",
                        "short_codename": "tuy_loc"
                    },
                    {
                        "name": "Xã Phong Lộc",
                        "code": 16042,
                        "codename": "xa_phong_loc",
                        "division_type": "xã",
                        "short_codename": "phong_loc"
                    },
                    {
                        "name": "Xã Mỹ Lộc",
                        "code": 16045,
                        "codename": "xa_my_loc",
                        "division_type": "xã",
                        "short_codename": "my_loc"
                    },
                    {
                        "name": "Xã Thuần Lộc",
                        "code": 16048,
                        "codename": "xa_thuan_loc",
                        "division_type": "xã",
                        "short_codename": "thuan_loc"
                    },
                    {
                        "name": "Xã Xuân Lộc",
                        "code": 16057,
                        "codename": "xa_xuan_loc",
                        "division_type": "xã",
                        "short_codename": "xuan_loc"
                    },
                    {
                        "name": "Xã Hoa Lộc",
                        "code": 16063,
                        "codename": "xa_hoa_loc",
                        "division_type": "xã",
                        "short_codename": "xa_hoa_loc"
                    },
                    {
                        "name": "Xã Liên Lộc",
                        "code": 16066,
                        "codename": "xa_lien_loc",
                        "division_type": "xã",
                        "short_codename": "lien_loc"
                    },
                    {
                        "name": "Xã Quang Lộc",
                        "code": 16069,
                        "codename": "xa_quang_loc",
                        "division_type": "xã",
                        "short_codename": "quang_loc"
                    },
                    {
                        "name": "Xã Phú Lộc",
                        "code": 16072,
                        "codename": "xa_phu_loc",
                        "division_type": "xã",
                        "short_codename": "phu_loc"
                    },
                    {
                        "name": "Xã Hòa Lộc",
                        "code": 16075,
                        "codename": "xa_hoa_loc",
                        "division_type": "xã",
                        "short_codename": "xa_hoa_loc"
                    },
                    {
                        "name": "Xã Minh Lộc",
                        "code": 16078,
                        "codename": "xa_minh_loc",
                        "division_type": "xã",
                        "short_codename": "minh_loc"
                    },
                    {
                        "name": "Xã Hưng Lộc",
                        "code": 16081,
                        "codename": "xa_hung_loc",
                        "division_type": "xã",
                        "short_codename": "hung_loc"
                    },
                    {
                        "name": "Xã Hải Lộc",
                        "code": 16084,
                        "codename": "xa_hai_loc",
                        "division_type": "xã",
                        "short_codename": "hai_loc"
                    },
                    {
                        "name": "Xã Đa Lộc",
                        "code": 16087,
                        "codename": "xa_da_loc",
                        "division_type": "xã",
                        "short_codename": "da_loc"
                    },
                    {
                        "name": "Xã Ngư Lộc",
                        "code": 16090,
                        "codename": "xa_ngu_loc",
                        "division_type": "xã",
                        "short_codename": "ngu_loc"
                    }
                ]
            },
            {
                "name": "Huyện Nga Sơn",
                "code": 401,
                "codename": "huyen_nga_son",
                "division_type": "huyện",
                "short_codename": "nga_son",
                "wards": [
                    {
                        "name": "Thị trấn Nga Sơn",
                        "code": 16093,
                        "codename": "thi_tran_nga_son",
                        "division_type": "thị trấn",
                        "short_codename": "nga_son"
                    },
                    {
                        "name": "Xã Ba Đình",
                        "code": 16096,
                        "codename": "xa_ba_dinh",
                        "division_type": "xã",
                        "short_codename": "ba_dinh"
                    },
                    {
                        "name": "Xã Nga Vịnh",
                        "code": 16099,
                        "codename": "xa_nga_vinh",
                        "division_type": "xã",
                        "short_codename": "nga_vinh"
                    },
                    {
                        "name": "Xã Nga Văn",
                        "code": 16102,
                        "codename": "xa_nga_van",
                        "division_type": "xã",
                        "short_codename": "nga_van"
                    },
                    {
                        "name": "Xã Nga Thiện",
                        "code": 16105,
                        "codename": "xa_nga_thien",
                        "division_type": "xã",
                        "short_codename": "nga_thien"
                    },
                    {
                        "name": "Xã Nga Tiến",
                        "code": 16108,
                        "codename": "xa_nga_tien",
                        "division_type": "xã",
                        "short_codename": "nga_tien"
                    },
                    {
                        "name": "Xã Nga Phượng",
                        "code": 16114,
                        "codename": "xa_nga_phuong",
                        "division_type": "xã",
                        "short_codename": "nga_phuong"
                    },
                    {
                        "name": "Xã Nga Trung",
                        "code": 16117,
                        "codename": "xa_nga_trung",
                        "division_type": "xã",
                        "short_codename": "nga_trung"
                    },
                    {
                        "name": "Xã Nga Bạch",
                        "code": 16120,
                        "codename": "xa_nga_bach",
                        "division_type": "xã",
                        "short_codename": "nga_bach"
                    },
                    {
                        "name": "Xã Nga Thanh",
                        "code": 16123,
                        "codename": "xa_nga_thanh",
                        "division_type": "xã",
                        "short_codename": "xa_nga_thanh"
                    },
                    {
                        "name": "Xã Nga Yên",
                        "code": 16132,
                        "codename": "xa_nga_yen",
                        "division_type": "xã",
                        "short_codename": "nga_yen"
                    },
                    {
                        "name": "Xã Nga Giáp",
                        "code": 16135,
                        "codename": "xa_nga_giap",
                        "division_type": "xã",
                        "short_codename": "nga_giap"
                    },
                    {
                        "name": "Xã Nga Hải",
                        "code": 16138,
                        "codename": "xa_nga_hai",
                        "division_type": "xã",
                        "short_codename": "nga_hai"
                    },
                    {
                        "name": "Xã Nga Thành",
                        "code": 16141,
                        "codename": "xa_nga_thanh",
                        "division_type": "xã",
                        "short_codename": "xa_nga_thanh"
                    },
                    {
                        "name": "Xã Nga An",
                        "code": 16144,
                        "codename": "xa_nga_an",
                        "division_type": "xã",
                        "short_codename": "nga_an"
                    },
                    {
                        "name": "Xã Nga Phú",
                        "code": 16147,
                        "codename": "xa_nga_phu",
                        "division_type": "xã",
                        "short_codename": "nga_phu"
                    },
                    {
                        "name": "Xã Nga Điền",
                        "code": 16150,
                        "codename": "xa_nga_dien",
                        "division_type": "xã",
                        "short_codename": "nga_dien"
                    },
                    {
                        "name": "Xã Nga Tân",
                        "code": 16153,
                        "codename": "xa_nga_tan",
                        "division_type": "xã",
                        "short_codename": "nga_tan"
                    },
                    {
                        "name": "Xã Nga Thủy",
                        "code": 16156,
                        "codename": "xa_nga_thuy",
                        "division_type": "xã",
                        "short_codename": "nga_thuy"
                    },
                    {
                        "name": "Xã Nga Liên",
                        "code": 16159,
                        "codename": "xa_nga_lien",
                        "division_type": "xã",
                        "short_codename": "nga_lien"
                    },
                    {
                        "name": "Xã Nga Thái",
                        "code": 16162,
                        "codename": "xa_nga_thai",
                        "division_type": "xã",
                        "short_codename": "nga_thai"
                    },
                    {
                        "name": "Xã Nga Thạch",
                        "code": 16165,
                        "codename": "xa_nga_thach",
                        "division_type": "xã",
                        "short_codename": "nga_thach"
                    },
                    {
                        "name": "Xã Nga Thắng",
                        "code": 16168,
                        "codename": "xa_nga_thang",
                        "division_type": "xã",
                        "short_codename": "nga_thang"
                    },
                    {
                        "name": "Xã Nga Trường",
                        "code": 16171,
                        "codename": "xa_nga_truong",
                        "division_type": "xã",
                        "short_codename": "nga_truong"
                    }
                ]
            },
            {
                "name": "Huyện Như Xuân",
                "code": 402,
                "codename": "huyen_nhu_xuan",
                "division_type": "huyện",
                "short_codename": "nhu_xuan",
                "wards": [
                    {
                        "name": "Thị trấn Yên Cát",
                        "code": 16174,
                        "codename": "thi_tran_yen_cat",
                        "division_type": "thị trấn",
                        "short_codename": "yen_cat"
                    },
                    {
                        "name": "Xã Bãi Trành",
                        "code": 16177,
                        "codename": "xa_bai_tranh",
                        "division_type": "xã",
                        "short_codename": "bai_tranh"
                    },
                    {
                        "name": "Xã Xuân Hòa",
                        "code": 16180,
                        "codename": "xa_xuan_hoa",
                        "division_type": "xã",
                        "short_codename": "xuan_hoa"
                    },
                    {
                        "name": "Xã Xuân Bình",
                        "code": 16183,
                        "codename": "xa_xuan_binh",
                        "division_type": "xã",
                        "short_codename": "xuan_binh"
                    },
                    {
                        "name": "Xã Hóa Quỳ",
                        "code": 16186,
                        "codename": "xa_hoa_quy",
                        "division_type": "xã",
                        "short_codename": "hoa_quy"
                    },
                    {
                        "name": "Xã Cát Vân",
                        "code": 16195,
                        "codename": "xa_cat_van",
                        "division_type": "xã",
                        "short_codename": "cat_van"
                    },
                    {
                        "name": "Xã Cát Tân",
                        "code": 16198,
                        "codename": "xa_cat_tan",
                        "division_type": "xã",
                        "short_codename": "cat_tan"
                    },
                    {
                        "name": "Xã Tân Bình",
                        "code": 16201,
                        "codename": "xa_tan_binh",
                        "division_type": "xã",
                        "short_codename": "tan_binh"
                    },
                    {
                        "name": "Xã Bình Lương",
                        "code": 16204,
                        "codename": "xa_binh_luong",
                        "division_type": "xã",
                        "short_codename": "binh_luong"
                    },
                    {
                        "name": "Xã Thanh Quân",
                        "code": 16207,
                        "codename": "xa_thanh_quan",
                        "division_type": "xã",
                        "short_codename": "thanh_quan"
                    },
                    {
                        "name": "Xã Thanh Xuân",
                        "code": 16210,
                        "codename": "xa_thanh_xuan",
                        "division_type": "xã",
                        "short_codename": "thanh_xuan"
                    },
                    {
                        "name": "Xã Thanh Hòa",
                        "code": 16213,
                        "codename": "xa_thanh_hoa",
                        "division_type": "xã",
                        "short_codename": "thanh_hoa"
                    },
                    {
                        "name": "Xã Thanh Phong",
                        "code": 16216,
                        "codename": "xa_thanh_phong",
                        "division_type": "xã",
                        "short_codename": "thanh_phong"
                    },
                    {
                        "name": "Xã Thanh Lâm",
                        "code": 16219,
                        "codename": "xa_thanh_lam",
                        "division_type": "xã",
                        "short_codename": "thanh_lam"
                    },
                    {
                        "name": "Xã Thanh Sơn",
                        "code": 16222,
                        "codename": "xa_thanh_son",
                        "division_type": "xã",
                        "short_codename": "thanh_son"
                    },
                    {
                        "name": "Xã Thượng Ninh",
                        "code": 16225,
                        "codename": "xa_thuong_ninh",
                        "division_type": "xã",
                        "short_codename": "thuong_ninh"
                    }
                ]
            },
            {
                "name": "Huyện Như Thanh",
                "code": 403,
                "codename": "huyen_nhu_thanh",
                "division_type": "huyện",
                "short_codename": "nhu_thanh",
                "wards": [
                    {
                        "name": "Thị trấn Bến Sung",
                        "code": 16228,
                        "codename": "thi_tran_ben_sung",
                        "division_type": "thị trấn",
                        "short_codename": "ben_sung"
                    },
                    {
                        "name": "Xã Cán Khê",
                        "code": 16231,
                        "codename": "xa_can_khe",
                        "division_type": "xã",
                        "short_codename": "can_khe"
                    },
                    {
                        "name": "Xã Xuân Du",
                        "code": 16234,
                        "codename": "xa_xuan_du",
                        "division_type": "xã",
                        "short_codename": "xuan_du"
                    },
                    {
                        "name": "Xã Phượng Nghi",
                        "code": 16240,
                        "codename": "xa_phuong_nghi",
                        "division_type": "xã",
                        "short_codename": "phuong_nghi"
                    },
                    {
                        "name": "Xã Mậu Lâm",
                        "code": 16243,
                        "codename": "xa_mau_lam",
                        "division_type": "xã",
                        "short_codename": "mau_lam"
                    },
                    {
                        "name": "Xã Xuân Khang",
                        "code": 16246,
                        "codename": "xa_xuan_khang",
                        "division_type": "xã",
                        "short_codename": "xuan_khang"
                    },
                    {
                        "name": "Xã Phú Nhuận",
                        "code": 16249,
                        "codename": "xa_phu_nhuan",
                        "division_type": "xã",
                        "short_codename": "phu_nhuan"
                    },
                    {
                        "name": "Xã Hải Long",
                        "code": 16252,
                        "codename": "xa_hai_long",
                        "division_type": "xã",
                        "short_codename": "hai_long"
                    },
                    {
                        "name": "Xã Xuân Thái",
                        "code": 16258,
                        "codename": "xa_xuan_thai",
                        "division_type": "xã",
                        "short_codename": "xuan_thai"
                    },
                    {
                        "name": "Xã Xuân Phúc",
                        "code": 16261,
                        "codename": "xa_xuan_phuc",
                        "division_type": "xã",
                        "short_codename": "xuan_phuc"
                    },
                    {
                        "name": "Xã Yên Thọ",
                        "code": 16264,
                        "codename": "xa_yen_tho",
                        "division_type": "xã",
                        "short_codename": "yen_tho"
                    },
                    {
                        "name": "Xã Yên Lạc",
                        "code": 16267,
                        "codename": "xa_yen_lac",
                        "division_type": "xã",
                        "short_codename": "yen_lac"
                    },
                    {
                        "name": "Xã Thanh Tân",
                        "code": 16273,
                        "codename": "xa_thanh_tan",
                        "division_type": "xã",
                        "short_codename": "thanh_tan"
                    },
                    {
                        "name": "Xã Thanh Kỳ",
                        "code": 16276,
                        "codename": "xa_thanh_ky",
                        "division_type": "xã",
                        "short_codename": "thanh_ky"
                    }
                ]
            },
            {
                "name": "Huyện Nông Cống",
                "code": 404,
                "codename": "huyen_nong_cong",
                "division_type": "huyện",
                "short_codename": "nong_cong",
                "wards": [
                    {
                        "name": "Thị trấn Nông Cống",
                        "code": 16279,
                        "codename": "thi_tran_nong_cong",
                        "division_type": "thị trấn",
                        "short_codename": "nong_cong"
                    },
                    {
                        "name": "Xã Tân Phúc",
                        "code": 16282,
                        "codename": "xa_tan_phuc",
                        "division_type": "xã",
                        "short_codename": "tan_phuc"
                    },
                    {
                        "name": "Xã Tân Thọ",
                        "code": 16285,
                        "codename": "xa_tan_tho",
                        "division_type": "xã",
                        "short_codename": "tan_tho"
                    },
                    {
                        "name": "Xã Hoàng Sơn",
                        "code": 16288,
                        "codename": "xa_hoang_son",
                        "division_type": "xã",
                        "short_codename": "hoang_son"
                    },
                    {
                        "name": "Xã Tân Khang",
                        "code": 16291,
                        "codename": "xa_tan_khang",
                        "division_type": "xã",
                        "short_codename": "tan_khang"
                    },
                    {
                        "name": "Xã Hoàng Giang",
                        "code": 16294,
                        "codename": "xa_hoang_giang",
                        "division_type": "xã",
                        "short_codename": "hoang_giang"
                    },
                    {
                        "name": "Xã Trung Chính",
                        "code": 16297,
                        "codename": "xa_trung_chinh",
                        "division_type": "xã",
                        "short_codename": "trung_chinh"
                    },
                    {
                        "name": "Xã Trung Thành",
                        "code": 16303,
                        "codename": "xa_trung_thanh",
                        "division_type": "xã",
                        "short_codename": "trung_thanh"
                    },
                    {
                        "name": "Xã Tế Thắng",
                        "code": 16309,
                        "codename": "xa_te_thang",
                        "division_type": "xã",
                        "short_codename": "te_thang"
                    },
                    {
                        "name": "Xã Tế Lợi",
                        "code": 16315,
                        "codename": "xa_te_loi",
                        "division_type": "xã",
                        "short_codename": "te_loi"
                    },
                    {
                        "name": "Xã Tế Nông",
                        "code": 16318,
                        "codename": "xa_te_nong",
                        "division_type": "xã",
                        "short_codename": "te_nong"
                    },
                    {
                        "name": "Xã Minh Nghĩa",
                        "code": 16321,
                        "codename": "xa_minh_nghia",
                        "division_type": "xã",
                        "short_codename": "minh_nghia"
                    },
                    {
                        "name": "Xã Minh Khôi",
                        "code": 16324,
                        "codename": "xa_minh_khoi",
                        "division_type": "xã",
                        "short_codename": "minh_khoi"
                    },
                    {
                        "name": "Xã Vạn Hòa",
                        "code": 16327,
                        "codename": "xa_van_hoa",
                        "division_type": "xã",
                        "short_codename": "van_hoa"
                    },
                    {
                        "name": "Xã Trường Trung",
                        "code": 16330,
                        "codename": "xa_truong_trung",
                        "division_type": "xã",
                        "short_codename": "truong_trung"
                    },
                    {
                        "name": "Xã Vạn Thắng",
                        "code": 16333,
                        "codename": "xa_van_thang",
                        "division_type": "xã",
                        "short_codename": "van_thang"
                    },
                    {
                        "name": "Xã Trường Giang",
                        "code": 16336,
                        "codename": "xa_truong_giang",
                        "division_type": "xã",
                        "short_codename": "truong_giang"
                    },
                    {
                        "name": "Xã Vạn Thiện",
                        "code": 16339,
                        "codename": "xa_van_thien",
                        "division_type": "xã",
                        "short_codename": "van_thien"
                    },
                    {
                        "name": "Xã Thăng Long",
                        "code": 16342,
                        "codename": "xa_thang_long",
                        "division_type": "xã",
                        "short_codename": "thang_long"
                    },
                    {
                        "name": "Xã Trường Minh",
                        "code": 16345,
                        "codename": "xa_truong_minh",
                        "division_type": "xã",
                        "short_codename": "truong_minh"
                    },
                    {
                        "name": "Xã Trường Sơn",
                        "code": 16348,
                        "codename": "xa_truong_son",
                        "division_type": "xã",
                        "short_codename": "truong_son"
                    },
                    {
                        "name": "Xã Thăng Bình",
                        "code": 16351,
                        "codename": "xa_thang_binh",
                        "division_type": "xã",
                        "short_codename": "thang_binh"
                    },
                    {
                        "name": "Xã Công Liêm",
                        "code": 16354,
                        "codename": "xa_cong_liem",
                        "division_type": "xã",
                        "short_codename": "cong_liem"
                    },
                    {
                        "name": "Xã Tượng Văn",
                        "code": 16357,
                        "codename": "xa_tuong_van",
                        "division_type": "xã",
                        "short_codename": "tuong_van"
                    },
                    {
                        "name": "Xã Thăng Thọ",
                        "code": 16360,
                        "codename": "xa_thang_tho",
                        "division_type": "xã",
                        "short_codename": "thang_tho"
                    },
                    {
                        "name": "Xã Tượng Lĩnh",
                        "code": 16363,
                        "codename": "xa_tuong_linh",
                        "division_type": "xã",
                        "short_codename": "tuong_linh"
                    },
                    {
                        "name": "Xã Tượng Sơn",
                        "code": 16366,
                        "codename": "xa_tuong_son",
                        "division_type": "xã",
                        "short_codename": "tuong_son"
                    },
                    {
                        "name": "Xã Công Chính",
                        "code": 16369,
                        "codename": "xa_cong_chinh",
                        "division_type": "xã",
                        "short_codename": "cong_chinh"
                    },
                    {
                        "name": "Xã Yên Mỹ",
                        "code": 16375,
                        "codename": "xa_yen_my",
                        "division_type": "xã",
                        "short_codename": "yen_my"
                    }
                ]
            },
            {
                "name": "Huyện Đông Sơn",
                "code": 405,
                "codename": "huyen_dong_son",
                "division_type": "huyện",
                "short_codename": "dong_son",
                "wards": [
                    {
                        "name": "Thị trấn Rừng Thông",
                        "code": 16378,
                        "codename": "thi_tran_rung_thong",
                        "division_type": "thị trấn",
                        "short_codename": "rung_thong"
                    },
                    {
                        "name": "Xã Đông Hoàng",
                        "code": 16381,
                        "codename": "xa_dong_hoang",
                        "division_type": "xã",
                        "short_codename": "dong_hoang"
                    },
                    {
                        "name": "Xã Đông Ninh",
                        "code": 16384,
                        "codename": "xa_dong_ninh",
                        "division_type": "xã",
                        "short_codename": "dong_ninh"
                    },
                    {
                        "name": "Xã Đông Hòa",
                        "code": 16390,
                        "codename": "xa_dong_hoa",
                        "division_type": "xã",
                        "short_codename": "dong_hoa"
                    },
                    {
                        "name": "Xã Đông Yên",
                        "code": 16393,
                        "codename": "xa_dong_yen",
                        "division_type": "xã",
                        "short_codename": "dong_yen"
                    },
                    {
                        "name": "Xã Đông Minh",
                        "code": 16399,
                        "codename": "xa_dong_minh",
                        "division_type": "xã",
                        "short_codename": "dong_minh"
                    },
                    {
                        "name": "Xã Đông Thanh",
                        "code": 16402,
                        "codename": "xa_dong_thanh",
                        "division_type": "xã",
                        "short_codename": "dong_thanh"
                    },
                    {
                        "name": "Xã Đông Tiến",
                        "code": 16405,
                        "codename": "xa_dong_tien",
                        "division_type": "xã",
                        "short_codename": "dong_tien"
                    },
                    {
                        "name": "Xã Đông Khê",
                        "code": 16408,
                        "codename": "xa_dong_khe",
                        "division_type": "xã",
                        "short_codename": "dong_khe"
                    },
                    {
                        "name": "Xã Đông Thịnh",
                        "code": 16414,
                        "codename": "xa_dong_thinh",
                        "division_type": "xã",
                        "short_codename": "dong_thinh"
                    },
                    {
                        "name": "Xã Đông Văn",
                        "code": 16417,
                        "codename": "xa_dong_van",
                        "division_type": "xã",
                        "short_codename": "dong_van"
                    },
                    {
                        "name": "Xã Đông Phú",
                        "code": 16420,
                        "codename": "xa_dong_phu",
                        "division_type": "xã",
                        "short_codename": "dong_phu"
                    },
                    {
                        "name": "Xã Đông Nam",
                        "code": 16423,
                        "codename": "xa_dong_nam",
                        "division_type": "xã",
                        "short_codename": "dong_nam"
                    },
                    {
                        "name": "Xã Đông Quang",
                        "code": 16426,
                        "codename": "xa_dong_quang",
                        "division_type": "xã",
                        "short_codename": "dong_quang"
                    }
                ]
            },
            {
                "name": "Huyện Quảng Xương",
                "code": 406,
                "codename": "huyen_quang_xuong",
                "division_type": "huyện",
                "short_codename": "quang_xuong",
                "wards": [
                    {
                        "name": "Thị trấn Tân Phong",
                        "code": 16438,
                        "codename": "thi_tran_tan_phong",
                        "division_type": "thị trấn",
                        "short_codename": "tan_phong"
                    },
                    {
                        "name": "Xã Quảng Trạch",
                        "code": 16447,
                        "codename": "xa_quang_trach",
                        "division_type": "xã",
                        "short_codename": "quang_trach"
                    },
                    {
                        "name": "Xã Quảng Đức",
                        "code": 16453,
                        "codename": "xa_quang_duc",
                        "division_type": "xã",
                        "short_codename": "quang_duc"
                    },
                    {
                        "name": "Xã Quảng Định",
                        "code": 16456,
                        "codename": "xa_quang_dinh",
                        "division_type": "xã",
                        "short_codename": "quang_dinh"
                    },
                    {
                        "name": "Xã Quảng Nhân",
                        "code": 16462,
                        "codename": "xa_quang_nhan",
                        "division_type": "xã",
                        "short_codename": "quang_nhan"
                    },
                    {
                        "name": "Xã Quảng Ninh",
                        "code": 16465,
                        "codename": "xa_quang_ninh",
                        "division_type": "xã",
                        "short_codename": "quang_ninh"
                    },
                    {
                        "name": "Xã Quảng Bình",
                        "code": 16468,
                        "codename": "xa_quang_binh",
                        "division_type": "xã",
                        "short_codename": "quang_binh"
                    },
                    {
                        "name": "Xã Quảng Hợp",
                        "code": 16471,
                        "codename": "xa_quang_hop",
                        "division_type": "xã",
                        "short_codename": "quang_hop"
                    },
                    {
                        "name": "Xã Quảng Văn",
                        "code": 16474,
                        "codename": "xa_quang_van",
                        "division_type": "xã",
                        "short_codename": "quang_van"
                    },
                    {
                        "name": "Xã Quảng Long",
                        "code": 16477,
                        "codename": "xa_quang_long",
                        "division_type": "xã",
                        "short_codename": "quang_long"
                    },
                    {
                        "name": "Xã Quảng Yên",
                        "code": 16480,
                        "codename": "xa_quang_yen",
                        "division_type": "xã",
                        "short_codename": "quang_yen"
                    },
                    {
                        "name": "Xã Quảng Hòa",
                        "code": 16483,
                        "codename": "xa_quang_hoa",
                        "division_type": "xã",
                        "short_codename": "quang_hoa"
                    },
                    {
                        "name": "Xã Quảng Khê",
                        "code": 16489,
                        "codename": "xa_quang_khe",
                        "division_type": "xã",
                        "short_codename": "quang_khe"
                    },
                    {
                        "name": "Xã Quảng Trung",
                        "code": 16492,
                        "codename": "xa_quang_trung",
                        "division_type": "xã",
                        "short_codename": "quang_trung"
                    },
                    {
                        "name": "Xã Quảng Chính",
                        "code": 16495,
                        "codename": "xa_quang_chinh",
                        "division_type": "xã",
                        "short_codename": "quang_chinh"
                    },
                    {
                        "name": "Xã Quảng Ngọc",
                        "code": 16498,
                        "codename": "xa_quang_ngoc",
                        "division_type": "xã",
                        "short_codename": "quang_ngoc"
                    },
                    {
                        "name": "Xã Quảng Trường",
                        "code": 16501,
                        "codename": "xa_quang_truong",
                        "division_type": "xã",
                        "short_codename": "quang_truong"
                    },
                    {
                        "name": "Xã Quảng Phúc",
                        "code": 16510,
                        "codename": "xa_quang_phuc",
                        "division_type": "xã",
                        "short_codename": "quang_phuc"
                    },
                    {
                        "name": "Xã Quảng Giao",
                        "code": 16519,
                        "codename": "xa_quang_giao",
                        "division_type": "xã",
                        "short_codename": "quang_giao"
                    },
                    {
                        "name": "Xã Quảng Hải",
                        "code": 16540,
                        "codename": "xa_quang_hai",
                        "division_type": "xã",
                        "short_codename": "quang_hai"
                    },
                    {
                        "name": "Xã Quảng Lưu",
                        "code": 16543,
                        "codename": "xa_quang_luu",
                        "division_type": "xã",
                        "short_codename": "quang_luu"
                    },
                    {
                        "name": "Xã Quảng Lộc",
                        "code": 16546,
                        "codename": "xa_quang_loc",
                        "division_type": "xã",
                        "short_codename": "quang_loc"
                    },
                    {
                        "name": "Xã Tiên Trang",
                        "code": 16549,
                        "codename": "xa_tien_trang",
                        "division_type": "xã",
                        "short_codename": "tien_trang"
                    },
                    {
                        "name": "Xã Quảng Nham",
                        "code": 16552,
                        "codename": "xa_quang_nham",
                        "division_type": "xã",
                        "short_codename": "quang_nham"
                    },
                    {
                        "name": "Xã Quảng Thạch",
                        "code": 16555,
                        "codename": "xa_quang_thach",
                        "division_type": "xã",
                        "short_codename": "quang_thach"
                    },
                    {
                        "name": "Xã Quảng Thái",
                        "code": 16558,
                        "codename": "xa_quang_thai",
                        "division_type": "xã",
                        "short_codename": "quang_thai"
                    }
                ]
            },
            {
                "name": "Thị xã Nghi Sơn",
                "code": 407,
                "codename": "thi_xa_nghi_son",
                "division_type": "thị xã",
                "short_codename": "nghi_son",
                "wards": [
                    {
                        "name": "Phường Hải Hòa",
                        "code": 16561,
                        "codename": "phuong_hai_hoa",
                        "division_type": "phường",
                        "short_codename": "hai_hoa"
                    },
                    {
                        "name": "Phường Hải Châu",
                        "code": 16564,
                        "codename": "phuong_hai_chau",
                        "division_type": "phường",
                        "short_codename": "hai_chau"
                    },
                    {
                        "name": "Xã Thanh Thủy",
                        "code": 16567,
                        "codename": "xa_thanh_thuy",
                        "division_type": "xã",
                        "short_codename": "thanh_thuy"
                    },
                    {
                        "name": "Xã Thanh Sơn",
                        "code": 16570,
                        "codename": "xa_thanh_son",
                        "division_type": "xã",
                        "short_codename": "thanh_son"
                    },
                    {
                        "name": "Phường Hải Ninh",
                        "code": 16576,
                        "codename": "phuong_hai_ninh",
                        "division_type": "phường",
                        "short_codename": "hai_ninh"
                    },
                    {
                        "name": "Xã Anh Sơn",
                        "code": 16579,
                        "codename": "xa_anh_son",
                        "division_type": "xã",
                        "short_codename": "anh_son"
                    },
                    {
                        "name": "Xã Ngọc Lĩnh",
                        "code": 16582,
                        "codename": "xa_ngoc_linh",
                        "division_type": "xã",
                        "short_codename": "ngoc_linh"
                    },
                    {
                        "name": "Phường Hải An",
                        "code": 16585,
                        "codename": "phuong_hai_an",
                        "division_type": "phường",
                        "short_codename": "hai_an"
                    },
                    {
                        "name": "Xã Các Sơn",
                        "code": 16591,
                        "codename": "xa_cac_son",
                        "division_type": "xã",
                        "short_codename": "cac_son"
                    },
                    {
                        "name": "Phường Tân Dân",
                        "code": 16594,
                        "codename": "phuong_tan_dan",
                        "division_type": "phường",
                        "short_codename": "tan_dan"
                    },
                    {
                        "name": "Phường Hải Lĩnh",
                        "code": 16597,
                        "codename": "phuong_hai_linh",
                        "division_type": "phường",
                        "short_codename": "hai_linh"
                    },
                    {
                        "name": "Xã Định Hải",
                        "code": 16600,
                        "codename": "xa_dinh_hai",
                        "division_type": "xã",
                        "short_codename": "dinh_hai"
                    },
                    {
                        "name": "Xã Phú Sơn",
                        "code": 16603,
                        "codename": "xa_phu_son",
                        "division_type": "xã",
                        "short_codename": "phu_son"
                    },
                    {
                        "name": "Phường Ninh Hải",
                        "code": 16606,
                        "codename": "phuong_ninh_hai",
                        "division_type": "phường",
                        "short_codename": "ninh_hai"
                    },
                    {
                        "name": "Phường Nguyên Bình",
                        "code": 16609,
                        "codename": "phuong_nguyen_binh",
                        "division_type": "phường",
                        "short_codename": "nguyen_binh"
                    },
                    {
                        "name": "Xã Hải Nhân",
                        "code": 16612,
                        "codename": "xa_hai_nhan",
                        "division_type": "xã",
                        "short_codename": "hai_nhan"
                    },
                    {
                        "name": "Phường Bình Minh",
                        "code": 16618,
                        "codename": "phuong_binh_minh",
                        "division_type": "phường",
                        "short_codename": "binh_minh"
                    },
                    {
                        "name": "Phường Hải Thanh",
                        "code": 16621,
                        "codename": "phuong_hai_thanh",
                        "division_type": "phường",
                        "short_codename": "hai_thanh"
                    },
                    {
                        "name": "Xã Phú Lâm",
                        "code": 16624,
                        "codename": "xa_phu_lam",
                        "division_type": "xã",
                        "short_codename": "phu_lam"
                    },
                    {
                        "name": "Phường Xuân Lâm",
                        "code": 16627,
                        "codename": "phuong_xuan_lam",
                        "division_type": "phường",
                        "short_codename": "xuan_lam"
                    },
                    {
                        "name": "Phường Trúc Lâm",
                        "code": 16630,
                        "codename": "phuong_truc_lam",
                        "division_type": "phường",
                        "short_codename": "truc_lam"
                    },
                    {
                        "name": "Phường Hải Bình",
                        "code": 16633,
                        "codename": "phuong_hai_binh",
                        "division_type": "phường",
                        "short_codename": "hai_binh"
                    },
                    {
                        "name": "Xã Tân Trường",
                        "code": 16636,
                        "codename": "xa_tan_truong",
                        "division_type": "xã",
                        "short_codename": "tan_truong"
                    },
                    {
                        "name": "Xã Tùng Lâm",
                        "code": 16639,
                        "codename": "xa_tung_lam",
                        "division_type": "xã",
                        "short_codename": "tung_lam"
                    },
                    {
                        "name": "Phường Tĩnh Hải",
                        "code": 16642,
                        "codename": "phuong_tinh_hai",
                        "division_type": "phường",
                        "short_codename": "tinh_hai"
                    },
                    {
                        "name": "Phường Mai Lâm",
                        "code": 16645,
                        "codename": "phuong_mai_lam",
                        "division_type": "phường",
                        "short_codename": "mai_lam"
                    },
                    {
                        "name": "Xã Trường Lâm",
                        "code": 16648,
                        "codename": "xa_truong_lam",
                        "division_type": "xã",
                        "short_codename": "truong_lam"
                    },
                    {
                        "name": "Xã Hải Yến",
                        "code": 16651,
                        "codename": "xa_hai_yen",
                        "division_type": "xã",
                        "short_codename": "hai_yen"
                    },
                    {
                        "name": "Phường Hải Thượng",
                        "code": 16654,
                        "codename": "phuong_hai_thuong",
                        "division_type": "phường",
                        "short_codename": "hai_thuong"
                    },
                    {
                        "name": "Xã Nghi Sơn",
                        "code": 16657,
                        "codename": "xa_nghi_son",
                        "division_type": "xã",
                        "short_codename": "nghi_son"
                    },
                    {
                        "name": "Xã Hải Hà",
                        "code": 16660,
                        "codename": "xa_hai_ha",
                        "division_type": "xã",
                        "short_codename": "hai_ha"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Nghệ An",
        "code": 40,
        "codename": "tinh_nghe_an",
        "division_type": "tỉnh",
        "phone_code": 238,
        "districts": [
            {
                "name": "Thành phố Vinh",
                "code": 412,
                "codename": "thanh_pho_vinh",
                "division_type": "thành phố",
                "short_codename": "vinh",
                "wards": [
                    {
                        "name": "Phường Đông Vĩnh",
                        "code": 16663,
                        "codename": "phuong_dong_vinh",
                        "division_type": "phường",
                        "short_codename": "dong_vinh"
                    },
                    {
                        "name": "Phường Hà Huy Tập",
                        "code": 16666,
                        "codename": "phuong_ha_huy_tap",
                        "division_type": "phường",
                        "short_codename": "ha_huy_tap"
                    },
                    {
                        "name": "Phường Lê Lợi",
                        "code": 16669,
                        "codename": "phuong_le_loi",
                        "division_type": "phường",
                        "short_codename": "le_loi"
                    },
                    {
                        "name": "Phường Quán Bàu",
                        "code": 16670,
                        "codename": "phuong_quan_bau",
                        "division_type": "phường",
                        "short_codename": "quan_bau"
                    },
                    {
                        "name": "Phường Hưng Bình",
                        "code": 16672,
                        "codename": "phuong_hung_binh",
                        "division_type": "phường",
                        "short_codename": "hung_binh"
                    },
                    {
                        "name": "Phường Hưng Phúc",
                        "code": 16673,
                        "codename": "phuong_hung_phuc",
                        "division_type": "phường",
                        "short_codename": "hung_phuc"
                    },
                    {
                        "name": "Phường Hưng Dũng",
                        "code": 16675,
                        "codename": "phuong_hung_dung",
                        "division_type": "phường",
                        "short_codename": "hung_dung"
                    },
                    {
                        "name": "Phường Cửa Nam",
                        "code": 16678,
                        "codename": "phuong_cua_nam",
                        "division_type": "phường",
                        "short_codename": "cua_nam"
                    },
                    {
                        "name": "Phường Quang Trung",
                        "code": 16681,
                        "codename": "phuong_quang_trung",
                        "division_type": "phường",
                        "short_codename": "quang_trung"
                    },
                    {
                        "name": "Phường Đội Cung",
                        "code": 16684,
                        "codename": "phuong_doi_cung",
                        "division_type": "phường",
                        "short_codename": "doi_cung"
                    },
                    {
                        "name": "Phường Lê Mao",
                        "code": 16687,
                        "codename": "phuong_le_mao",
                        "division_type": "phường",
                        "short_codename": "le_mao"
                    },
                    {
                        "name": "Phường Trường Thi",
                        "code": 16690,
                        "codename": "phuong_truong_thi",
                        "division_type": "phường",
                        "short_codename": "truong_thi"
                    },
                    {
                        "name": "Phường Bến Thủy",
                        "code": 16693,
                        "codename": "phuong_ben_thuy",
                        "division_type": "phường",
                        "short_codename": "ben_thuy"
                    },
                    {
                        "name": "Phường Hồng Sơn",
                        "code": 16696,
                        "codename": "phuong_hong_son",
                        "division_type": "phường",
                        "short_codename": "hong_son"
                    },
                    {
                        "name": "Phường Trung Đô",
                        "code": 16699,
                        "codename": "phuong_trung_do",
                        "division_type": "phường",
                        "short_codename": "trung_do"
                    },
                    {
                        "name": "Xã Nghi Phú",
                        "code": 16702,
                        "codename": "xa_nghi_phu",
                        "division_type": "xã",
                        "short_codename": "nghi_phu"
                    },
                    {
                        "name": "Xã Hưng Đông",
                        "code": 16705,
                        "codename": "xa_hung_dong",
                        "division_type": "xã",
                        "short_codename": "hung_dong"
                    },
                    {
                        "name": "Xã Hưng Lộc",
                        "code": 16708,
                        "codename": "xa_hung_loc",
                        "division_type": "xã",
                        "short_codename": "hung_loc"
                    },
                    {
                        "name": "Xã Hưng Hòa",
                        "code": 16711,
                        "codename": "xa_hung_hoa",
                        "division_type": "xã",
                        "short_codename": "hung_hoa"
                    },
                    {
                        "name": "Phường Vinh Tân",
                        "code": 16714,
                        "codename": "phuong_vinh_tan",
                        "division_type": "phường",
                        "short_codename": "vinh_tan"
                    },
                    {
                        "name": "Xã Nghi Liên",
                        "code": 17908,
                        "codename": "xa_nghi_lien",
                        "division_type": "xã",
                        "short_codename": "nghi_lien"
                    },
                    {
                        "name": "Xã Nghi Ân",
                        "code": 17914,
                        "codename": "xa_nghi_an",
                        "division_type": "xã",
                        "short_codename": "nghi_an"
                    },
                    {
                        "name": "Xã Nghi Kim",
                        "code": 17920,
                        "codename": "xa_nghi_kim",
                        "division_type": "xã",
                        "short_codename": "nghi_kim"
                    },
                    {
                        "name": "Xã Nghi Đức",
                        "code": 17923,
                        "codename": "xa_nghi_duc",
                        "division_type": "xã",
                        "short_codename": "nghi_duc"
                    },
                    {
                        "name": "Xã Hưng Chính",
                        "code": 18013,
                        "codename": "xa_hung_chinh",
                        "division_type": "xã",
                        "short_codename": "hung_chinh"
                    }
                ]
            },
            {
                "name": "Thị xã Cửa Lò",
                "code": 413,
                "codename": "thi_xa_cua_lo",
                "division_type": "thị xã",
                "short_codename": "cua_lo",
                "wards": [
                    {
                        "name": "Phường Nghi Thuỷ",
                        "code": 16717,
                        "codename": "phuong_nghi_thuy",
                        "division_type": "phường",
                        "short_codename": "nghi_thuy"
                    },
                    {
                        "name": "Phường Nghi Tân",
                        "code": 16720,
                        "codename": "phuong_nghi_tan",
                        "division_type": "phường",
                        "short_codename": "nghi_tan"
                    },
                    {
                        "name": "Phường Thu Thuỷ",
                        "code": 16723,
                        "codename": "phuong_thu_thuy",
                        "division_type": "phường",
                        "short_codename": "thu_thuy"
                    },
                    {
                        "name": "Phường Nghi Hòa",
                        "code": 16726,
                        "codename": "phuong_nghi_hoa",
                        "division_type": "phường",
                        "short_codename": "nghi_hoa"
                    },
                    {
                        "name": "Phường Nghi Hải",
                        "code": 16729,
                        "codename": "phuong_nghi_hai",
                        "division_type": "phường",
                        "short_codename": "nghi_hai"
                    },
                    {
                        "name": "Phường Nghi Hương",
                        "code": 16732,
                        "codename": "phuong_nghi_huong",
                        "division_type": "phường",
                        "short_codename": "nghi_huong"
                    },
                    {
                        "name": "Phường Nghi Thu",
                        "code": 16735,
                        "codename": "phuong_nghi_thu",
                        "division_type": "phường",
                        "short_codename": "nghi_thu"
                    }
                ]
            },
            {
                "name": "Thị xã Thái Hoà",
                "code": 414,
                "codename": "thi_xa_thai_hoa",
                "division_type": "thị xã",
                "short_codename": "thai_hoa",
                "wards": [
                    {
                        "name": "Phường Hoà Hiếu",
                        "code": 16939,
                        "codename": "phuong_hoa_hieu",
                        "division_type": "phường",
                        "short_codename": "hoa_hieu"
                    },
                    {
                        "name": "Phường Quang Phong",
                        "code": 16993,
                        "codename": "phuong_quang_phong",
                        "division_type": "phường",
                        "short_codename": "quang_phong"
                    },
                    {
                        "name": "Phường Quang Tiến",
                        "code": 16994,
                        "codename": "phuong_quang_tien",
                        "division_type": "phường",
                        "short_codename": "quang_tien"
                    },
                    {
                        "name": "Phường Long Sơn",
                        "code": 17003,
                        "codename": "phuong_long_son",
                        "division_type": "phường",
                        "short_codename": "long_son"
                    },
                    {
                        "name": "Xã Nghĩa Tiến",
                        "code": 17005,
                        "codename": "xa_nghia_tien",
                        "division_type": "xã",
                        "short_codename": "nghia_tien"
                    },
                    {
                        "name": "Xã Nghĩa Mỹ",
                        "code": 17008,
                        "codename": "xa_nghia_my",
                        "division_type": "xã",
                        "short_codename": "nghia_my"
                    },
                    {
                        "name": "Xã Tây Hiếu",
                        "code": 17011,
                        "codename": "xa_tay_hieu",
                        "division_type": "xã",
                        "short_codename": "tay_hieu"
                    },
                    {
                        "name": "Xã Nghĩa Thuận",
                        "code": 17014,
                        "codename": "xa_nghia_thuan",
                        "division_type": "xã",
                        "short_codename": "nghia_thuan"
                    },
                    {
                        "name": "Xã Đông Hiếu",
                        "code": 17017,
                        "codename": "xa_dong_hieu",
                        "division_type": "xã",
                        "short_codename": "dong_hieu"
                    }
                ]
            },
            {
                "name": "Huyện Quế Phong",
                "code": 415,
                "codename": "huyen_que_phong",
                "division_type": "huyện",
                "short_codename": "que_phong",
                "wards": [
                    {
                        "name": "Thị trấn Kim Sơn",
                        "code": 16738,
                        "codename": "thi_tran_kim_son",
                        "division_type": "thị trấn",
                        "short_codename": "kim_son"
                    },
                    {
                        "name": "Xã Thông Thụ",
                        "code": 16741,
                        "codename": "xa_thong_thu",
                        "division_type": "xã",
                        "short_codename": "thong_thu"
                    },
                    {
                        "name": "Xã Đồng Văn",
                        "code": 16744,
                        "codename": "xa_dong_van",
                        "division_type": "xã",
                        "short_codename": "dong_van"
                    },
                    {
                        "name": "Xã Hạnh Dịch",
                        "code": 16747,
                        "codename": "xa_hanh_dich",
                        "division_type": "xã",
                        "short_codename": "hanh_dich"
                    },
                    {
                        "name": "Xã Tiền Phong",
                        "code": 16750,
                        "codename": "xa_tien_phong",
                        "division_type": "xã",
                        "short_codename": "tien_phong"
                    },
                    {
                        "name": "Xã Nậm Giải",
                        "code": 16753,
                        "codename": "xa_nam_giai",
                        "division_type": "xã",
                        "short_codename": "nam_giai"
                    },
                    {
                        "name": "Xã Tri Lễ",
                        "code": 16756,
                        "codename": "xa_tri_le",
                        "division_type": "xã",
                        "short_codename": "tri_le"
                    },
                    {
                        "name": "Xã Châu Kim",
                        "code": 16759,
                        "codename": "xa_chau_kim",
                        "division_type": "xã",
                        "short_codename": "chau_kim"
                    },
                    {
                        "name": "Xã Mường Nọc",
                        "code": 16763,
                        "codename": "xa_muong_noc",
                        "division_type": "xã",
                        "short_codename": "muong_noc"
                    },
                    {
                        "name": "Xã Châu Thôn",
                        "code": 16765,
                        "codename": "xa_chau_thon",
                        "division_type": "xã",
                        "short_codename": "chau_thon"
                    },
                    {
                        "name": "Xã Nậm Nhoóng",
                        "code": 16768,
                        "codename": "xa_nam_nhoong",
                        "division_type": "xã",
                        "short_codename": "nam_nhoong"
                    },
                    {
                        "name": "Xã Quang Phong",
                        "code": 16771,
                        "codename": "xa_quang_phong",
                        "division_type": "xã",
                        "short_codename": "quang_phong"
                    },
                    {
                        "name": "Xã Căm Muộn",
                        "code": 16774,
                        "codename": "xa_cam_muon",
                        "division_type": "xã",
                        "short_codename": "cam_muon"
                    }
                ]
            },
            {
                "name": "Huyện Quỳ Châu",
                "code": 416,
                "codename": "huyen_quy_chau",
                "division_type": "huyện",
                "short_codename": "quy_chau",
                "wards": [
                    {
                        "name": "Thị trấn Tân Lạc",
                        "code": 16777,
                        "codename": "thi_tran_tan_lac",
                        "division_type": "thị trấn",
                        "short_codename": "tan_lac"
                    },
                    {
                        "name": "Xã Châu Bính",
                        "code": 16780,
                        "codename": "xa_chau_binh",
                        "division_type": "xã",
                        "short_codename": "xa_chau_binh"
                    },
                    {
                        "name": "Xã Châu Thuận",
                        "code": 16783,
                        "codename": "xa_chau_thuan",
                        "division_type": "xã",
                        "short_codename": "chau_thuan"
                    },
                    {
                        "name": "Xã Châu Hội",
                        "code": 16786,
                        "codename": "xa_chau_hoi",
                        "division_type": "xã",
                        "short_codename": "chau_hoi"
                    },
                    {
                        "name": "Xã Châu Nga",
                        "code": 16789,
                        "codename": "xa_chau_nga",
                        "division_type": "xã",
                        "short_codename": "chau_nga"
                    },
                    {
                        "name": "Xã Châu Tiến",
                        "code": 16792,
                        "codename": "xa_chau_tien",
                        "division_type": "xã",
                        "short_codename": "chau_tien"
                    },
                    {
                        "name": "Xã Châu Hạnh",
                        "code": 16795,
                        "codename": "xa_chau_hanh",
                        "division_type": "xã",
                        "short_codename": "chau_hanh"
                    },
                    {
                        "name": "Xã Châu Thắng",
                        "code": 16798,
                        "codename": "xa_chau_thang",
                        "division_type": "xã",
                        "short_codename": "chau_thang"
                    },
                    {
                        "name": "Xã Châu Phong",
                        "code": 16801,
                        "codename": "xa_chau_phong",
                        "division_type": "xã",
                        "short_codename": "chau_phong"
                    },
                    {
                        "name": "Xã Châu Bình",
                        "code": 16804,
                        "codename": "xa_chau_binh",
                        "division_type": "xã",
                        "short_codename": "xa_chau_binh"
                    },
                    {
                        "name": "Xã Châu Hoàn",
                        "code": 16807,
                        "codename": "xa_chau_hoan",
                        "division_type": "xã",
                        "short_codename": "chau_hoan"
                    },
                    {
                        "name": "Xã Diên Lãm",
                        "code": 16810,
                        "codename": "xa_dien_lam",
                        "division_type": "xã",
                        "short_codename": "dien_lam"
                    }
                ]
            },
            {
                "name": "Huyện Kỳ Sơn",
                "code": 417,
                "codename": "huyen_ky_son",
                "division_type": "huyện",
                "short_codename": "ky_son",
                "wards": [
                    {
                        "name": "Thị trấn Mường Xén",
                        "code": 16813,
                        "codename": "thi_tran_muong_xen",
                        "division_type": "thị trấn",
                        "short_codename": "muong_xen"
                    },
                    {
                        "name": "Xã Mỹ Lý",
                        "code": 16816,
                        "codename": "xa_my_ly",
                        "division_type": "xã",
                        "short_codename": "my_ly"
                    },
                    {
                        "name": "Xã Bắc Lý",
                        "code": 16819,
                        "codename": "xa_bac_ly",
                        "division_type": "xã",
                        "short_codename": "bac_ly"
                    },
                    {
                        "name": "Xã Keng Đu",
                        "code": 16822,
                        "codename": "xa_keng_du",
                        "division_type": "xã",
                        "short_codename": "keng_du"
                    },
                    {
                        "name": "Xã Đoọc Mạy",
                        "code": 16825,
                        "codename": "xa_dooc_may",
                        "division_type": "xã",
                        "short_codename": "dooc_may"
                    },
                    {
                        "name": "Xã Huồi Tụ",
                        "code": 16828,
                        "codename": "xa_huoi_tu",
                        "division_type": "xã",
                        "short_codename": "huoi_tu"
                    },
                    {
                        "name": "Xã Mường Lống",
                        "code": 16831,
                        "codename": "xa_muong_long",
                        "division_type": "xã",
                        "short_codename": "muong_long"
                    },
                    {
                        "name": "Xã Na Loi",
                        "code": 16834,
                        "codename": "xa_na_loi",
                        "division_type": "xã",
                        "short_codename": "na_loi"
                    },
                    {
                        "name": "Xã Nậm Cắn",
                        "code": 16837,
                        "codename": "xa_nam_can",
                        "division_type": "xã",
                        "short_codename": "xa_nam_can"
                    },
                    {
                        "name": "Xã Bảo Nam",
                        "code": 16840,
                        "codename": "xa_bao_nam",
                        "division_type": "xã",
                        "short_codename": "bao_nam"
                    },
                    {
                        "name": "Xã Phà Đánh",
                        "code": 16843,
                        "codename": "xa_pha_danh",
                        "division_type": "xã",
                        "short_codename": "pha_danh"
                    },
                    {
                        "name": "Xã Bảo Thắng",
                        "code": 16846,
                        "codename": "xa_bao_thang",
                        "division_type": "xã",
                        "short_codename": "bao_thang"
                    },
                    {
                        "name": "Xã Hữu Lập",
                        "code": 16849,
                        "codename": "xa_huu_lap",
                        "division_type": "xã",
                        "short_codename": "huu_lap"
                    },
                    {
                        "name": "Xã Tà Cạ",
                        "code": 16852,
                        "codename": "xa_ta_ca",
                        "division_type": "xã",
                        "short_codename": "ta_ca"
                    },
                    {
                        "name": "Xã Chiêu Lưu",
                        "code": 16855,
                        "codename": "xa_chieu_luu",
                        "division_type": "xã",
                        "short_codename": "chieu_luu"
                    },
                    {
                        "name": "Xã Mường Típ",
                        "code": 16858,
                        "codename": "xa_muong_tip",
                        "division_type": "xã",
                        "short_codename": "muong_tip"
                    },
                    {
                        "name": "Xã Hữu Kiệm",
                        "code": 16861,
                        "codename": "xa_huu_kiem",
                        "division_type": "xã",
                        "short_codename": "huu_kiem"
                    },
                    {
                        "name": "Xã Tây Sơn",
                        "code": 16864,
                        "codename": "xa_tay_son",
                        "division_type": "xã",
                        "short_codename": "tay_son"
                    },
                    {
                        "name": "Xã Mường Ải",
                        "code": 16867,
                        "codename": "xa_muong_ai",
                        "division_type": "xã",
                        "short_codename": "muong_ai"
                    },
                    {
                        "name": "Xã Na Ngoi",
                        "code": 16870,
                        "codename": "xa_na_ngoi",
                        "division_type": "xã",
                        "short_codename": "na_ngoi"
                    },
                    {
                        "name": "Xã Nậm Càn",
                        "code": 16873,
                        "codename": "xa_nam_can",
                        "division_type": "xã",
                        "short_codename": "xa_nam_can"
                    }
                ]
            },
            {
                "name": "Huyện Tương Dương",
                "code": 418,
                "codename": "huyen_tuong_duong",
                "division_type": "huyện",
                "short_codename": "tuong_duong",
                "wards": [
                    {
                        "name": "Thị trấn Thạch Giám",
                        "code": 16876,
                        "codename": "thi_tran_thach_giam",
                        "division_type": "thị trấn",
                        "short_codename": "thach_giam"
                    },
                    {
                        "name": "Xã Mai Sơn",
                        "code": 16879,
                        "codename": "xa_mai_son",
                        "division_type": "xã",
                        "short_codename": "mai_son"
                    },
                    {
                        "name": "Xã Nhôn Mai",
                        "code": 16882,
                        "codename": "xa_nhon_mai",
                        "division_type": "xã",
                        "short_codename": "nhon_mai"
                    },
                    {
                        "name": "Xã Hữu Khuông",
                        "code": 16885,
                        "codename": "xa_huu_khuong",
                        "division_type": "xã",
                        "short_codename": "huu_khuong"
                    },
                    {
                        "name": "Xã Yên Tĩnh",
                        "code": 16900,
                        "codename": "xa_yen_tinh",
                        "division_type": "xã",
                        "short_codename": "yen_tinh"
                    },
                    {
                        "name": "Xã Nga My",
                        "code": 16903,
                        "codename": "xa_nga_my",
                        "division_type": "xã",
                        "short_codename": "nga_my"
                    },
                    {
                        "name": "Xã Xiêng My",
                        "code": 16904,
                        "codename": "xa_xieng_my",
                        "division_type": "xã",
                        "short_codename": "xieng_my"
                    },
                    {
                        "name": "Xã Lưỡng Minh",
                        "code": 16906,
                        "codename": "xa_luong_minh",
                        "division_type": "xã",
                        "short_codename": "luong_minh"
                    },
                    {
                        "name": "Xã Yên Hòa",
                        "code": 16909,
                        "codename": "xa_yen_hoa",
                        "division_type": "xã",
                        "short_codename": "yen_hoa"
                    },
                    {
                        "name": "Xã Yên Na",
                        "code": 16912,
                        "codename": "xa_yen_na",
                        "division_type": "xã",
                        "short_codename": "yen_na"
                    },
                    {
                        "name": "Xã Lưu Kiền",
                        "code": 16915,
                        "codename": "xa_luu_kien",
                        "division_type": "xã",
                        "short_codename": "luu_kien"
                    },
                    {
                        "name": "Xã Xá Lượng",
                        "code": 16921,
                        "codename": "xa_xa_luong",
                        "division_type": "xã",
                        "short_codename": "xa_luong"
                    },
                    {
                        "name": "Xã Tam Thái",
                        "code": 16924,
                        "codename": "xa_tam_thai",
                        "division_type": "xã",
                        "short_codename": "tam_thai"
                    },
                    {
                        "name": "Xã Tam Đình",
                        "code": 16927,
                        "codename": "xa_tam_dinh",
                        "division_type": "xã",
                        "short_codename": "tam_dinh"
                    },
                    {
                        "name": "Xã Yên Thắng",
                        "code": 16930,
                        "codename": "xa_yen_thang",
                        "division_type": "xã",
                        "short_codename": "yen_thang"
                    },
                    {
                        "name": "Xã Tam Quang",
                        "code": 16933,
                        "codename": "xa_tam_quang",
                        "division_type": "xã",
                        "short_codename": "tam_quang"
                    },
                    {
                        "name": "Xã Tam Hợp",
                        "code": 16936,
                        "codename": "xa_tam_hop",
                        "division_type": "xã",
                        "short_codename": "tam_hop"
                    }
                ]
            },
            {
                "name": "Huyện Nghĩa Đàn",
                "code": 419,
                "codename": "huyen_nghia_dan",
                "division_type": "huyện",
                "short_codename": "nghia_dan",
                "wards": [
                    {
                        "name": "Thị trấn Nghĩa Đàn",
                        "code": 16941,
                        "codename": "thi_tran_nghia_dan",
                        "division_type": "thị trấn",
                        "short_codename": "nghia_dan"
                    },
                    {
                        "name": "Xã Nghĩa Mai",
                        "code": 16942,
                        "codename": "xa_nghia_mai",
                        "division_type": "xã",
                        "short_codename": "nghia_mai"
                    },
                    {
                        "name": "Xã Nghĩa Yên",
                        "code": 16945,
                        "codename": "xa_nghia_yen",
                        "division_type": "xã",
                        "short_codename": "nghia_yen"
                    },
                    {
                        "name": "Xã Nghĩa Lạc",
                        "code": 16948,
                        "codename": "xa_nghia_lac",
                        "division_type": "xã",
                        "short_codename": "nghia_lac"
                    },
                    {
                        "name": "Xã Nghĩa Lâm",
                        "code": 16951,
                        "codename": "xa_nghia_lam",
                        "division_type": "xã",
                        "short_codename": "nghia_lam"
                    },
                    {
                        "name": "Xã Nghĩa Sơn",
                        "code": 16954,
                        "codename": "xa_nghia_son",
                        "division_type": "xã",
                        "short_codename": "nghia_son"
                    },
                    {
                        "name": "Xã Nghĩa Lợi",
                        "code": 16957,
                        "codename": "xa_nghia_loi",
                        "division_type": "xã",
                        "short_codename": "nghia_loi"
                    },
                    {
                        "name": "Xã Nghĩa Bình",
                        "code": 16960,
                        "codename": "xa_nghia_binh",
                        "division_type": "xã",
                        "short_codename": "nghia_binh"
                    },
                    {
                        "name": "Xã Nghĩa Thọ",
                        "code": 16963,
                        "codename": "xa_nghia_tho",
                        "division_type": "xã",
                        "short_codename": "nghia_tho"
                    },
                    {
                        "name": "Xã Nghĩa Minh",
                        "code": 16966,
                        "codename": "xa_nghia_minh",
                        "division_type": "xã",
                        "short_codename": "nghia_minh"
                    },
                    {
                        "name": "Xã Nghĩa Phú",
                        "code": 16969,
                        "codename": "xa_nghia_phu",
                        "division_type": "xã",
                        "short_codename": "nghia_phu"
                    },
                    {
                        "name": "Xã Nghĩa Hưng",
                        "code": 16972,
                        "codename": "xa_nghia_hung",
                        "division_type": "xã",
                        "short_codename": "nghia_hung"
                    },
                    {
                        "name": "Xã Nghĩa Hồng",
                        "code": 16975,
                        "codename": "xa_nghia_hong",
                        "division_type": "xã",
                        "short_codename": "nghia_hong"
                    },
                    {
                        "name": "Xã Nghĩa Thịnh",
                        "code": 16978,
                        "codename": "xa_nghia_thinh",
                        "division_type": "xã",
                        "short_codename": "nghia_thinh"
                    },
                    {
                        "name": "Xã Nghĩa Trung",
                        "code": 16981,
                        "codename": "xa_nghia_trung",
                        "division_type": "xã",
                        "short_codename": "nghia_trung"
                    },
                    {
                        "name": "Xã Nghĩa Hội",
                        "code": 16984,
                        "codename": "xa_nghia_hoi",
                        "division_type": "xã",
                        "short_codename": "nghia_hoi"
                    },
                    {
                        "name": "Xã Nghĩa Thành",
                        "code": 16987,
                        "codename": "xa_nghia_thanh",
                        "division_type": "xã",
                        "short_codename": "nghia_thanh"
                    },
                    {
                        "name": "Xã Nghĩa Hiếu",
                        "code": 16996,
                        "codename": "xa_nghia_hieu",
                        "division_type": "xã",
                        "short_codename": "nghia_hieu"
                    },
                    {
                        "name": "Xã Nghĩa Đức",
                        "code": 17020,
                        "codename": "xa_nghia_duc",
                        "division_type": "xã",
                        "short_codename": "nghia_duc"
                    },
                    {
                        "name": "Xã Nghĩa An",
                        "code": 17023,
                        "codename": "xa_nghia_an",
                        "division_type": "xã",
                        "short_codename": "nghia_an"
                    },
                    {
                        "name": "Xã Nghĩa Long",
                        "code": 17026,
                        "codename": "xa_nghia_long",
                        "division_type": "xã",
                        "short_codename": "nghia_long"
                    },
                    {
                        "name": "Xã Nghĩa Lộc",
                        "code": 17029,
                        "codename": "xa_nghia_loc",
                        "division_type": "xã",
                        "short_codename": "nghia_loc"
                    },
                    {
                        "name": "Xã Nghĩa Khánh",
                        "code": 17032,
                        "codename": "xa_nghia_khanh",
                        "division_type": "xã",
                        "short_codename": "nghia_khanh"
                    }
                ]
            },
            {
                "name": "Huyện Quỳ Hợp",
                "code": 420,
                "codename": "huyen_quy_hop",
                "division_type": "huyện",
                "short_codename": "quy_hop",
                "wards": [
                    {
                        "name": "Thị trấn Quỳ Hợp",
                        "code": 17035,
                        "codename": "thi_tran_quy_hop",
                        "division_type": "thị trấn",
                        "short_codename": "quy_hop"
                    },
                    {
                        "name": "Xã Yên Hợp",
                        "code": 17038,
                        "codename": "xa_yen_hop",
                        "division_type": "xã",
                        "short_codename": "yen_hop"
                    },
                    {
                        "name": "Xã Châu Tiến",
                        "code": 17041,
                        "codename": "xa_chau_tien",
                        "division_type": "xã",
                        "short_codename": "chau_tien"
                    },
                    {
                        "name": "Xã Châu Hồng",
                        "code": 17044,
                        "codename": "xa_chau_hong",
                        "division_type": "xã",
                        "short_codename": "chau_hong"
                    },
                    {
                        "name": "Xã Đồng Hợp",
                        "code": 17047,
                        "codename": "xa_dong_hop",
                        "division_type": "xã",
                        "short_codename": "dong_hop"
                    },
                    {
                        "name": "Xã Châu Thành",
                        "code": 17050,
                        "codename": "xa_chau_thanh",
                        "division_type": "xã",
                        "short_codename": "chau_thanh"
                    },
                    {
                        "name": "Xã Liên Hợp",
                        "code": 17053,
                        "codename": "xa_lien_hop",
                        "division_type": "xã",
                        "short_codename": "lien_hop"
                    },
                    {
                        "name": "Xã Châu Lộc",
                        "code": 17056,
                        "codename": "xa_chau_loc",
                        "division_type": "xã",
                        "short_codename": "chau_loc"
                    },
                    {
                        "name": "Xã Tam Hợp",
                        "code": 17059,
                        "codename": "xa_tam_hop",
                        "division_type": "xã",
                        "short_codename": "tam_hop"
                    },
                    {
                        "name": "Xã Châu Cường",
                        "code": 17062,
                        "codename": "xa_chau_cuong",
                        "division_type": "xã",
                        "short_codename": "chau_cuong"
                    },
                    {
                        "name": "Xã Châu Quang",
                        "code": 17065,
                        "codename": "xa_chau_quang",
                        "division_type": "xã",
                        "short_codename": "chau_quang"
                    },
                    {
                        "name": "Xã Thọ Hợp",
                        "code": 17068,
                        "codename": "xa_tho_hop",
                        "division_type": "xã",
                        "short_codename": "tho_hop"
                    },
                    {
                        "name": "Xã Minh Hợp",
                        "code": 17071,
                        "codename": "xa_minh_hop",
                        "division_type": "xã",
                        "short_codename": "minh_hop"
                    },
                    {
                        "name": "Xã Nghĩa Xuân",
                        "code": 17074,
                        "codename": "xa_nghia_xuan",
                        "division_type": "xã",
                        "short_codename": "nghia_xuan"
                    },
                    {
                        "name": "Xã Châu Thái",
                        "code": 17077,
                        "codename": "xa_chau_thai",
                        "division_type": "xã",
                        "short_codename": "chau_thai"
                    },
                    {
                        "name": "Xã Châu Đình",
                        "code": 17080,
                        "codename": "xa_chau_dinh",
                        "division_type": "xã",
                        "short_codename": "chau_dinh"
                    },
                    {
                        "name": "Xã Văn Lợi",
                        "code": 17083,
                        "codename": "xa_van_loi",
                        "division_type": "xã",
                        "short_codename": "van_loi"
                    },
                    {
                        "name": "Xã Nam Sơn",
                        "code": 17086,
                        "codename": "xa_nam_son",
                        "division_type": "xã",
                        "short_codename": "nam_son"
                    },
                    {
                        "name": "Xã Châu Lý",
                        "code": 17089,
                        "codename": "xa_chau_ly",
                        "division_type": "xã",
                        "short_codename": "chau_ly"
                    },
                    {
                        "name": "Xã Hạ Sơn",
                        "code": 17092,
                        "codename": "xa_ha_son",
                        "division_type": "xã",
                        "short_codename": "ha_son"
                    },
                    {
                        "name": "Xã Bắc Sơn",
                        "code": 17095,
                        "codename": "xa_bac_son",
                        "division_type": "xã",
                        "short_codename": "bac_son"
                    }
                ]
            },
            {
                "name": "Huyện Quỳnh Lưu",
                "code": 421,
                "codename": "huyen_quynh_luu",
                "division_type": "huyện",
                "short_codename": "quynh_luu",
                "wards": [
                    {
                        "name": "Thị trấn Cầu Giát",
                        "code": 17098,
                        "codename": "thi_tran_cau_giat",
                        "division_type": "thị trấn",
                        "short_codename": "cau_giat"
                    },
                    {
                        "name": "Xã Quỳnh Thắng",
                        "code": 17101,
                        "codename": "xa_quynh_thang",
                        "division_type": "xã",
                        "short_codename": "quynh_thang"
                    },
                    {
                        "name": "Xã Quỳnh Tân",
                        "code": 17119,
                        "codename": "xa_quynh_tan",
                        "division_type": "xã",
                        "short_codename": "quynh_tan"
                    },
                    {
                        "name": "Xã Quỳnh Châu",
                        "code": 17122,
                        "codename": "xa_quynh_chau",
                        "division_type": "xã",
                        "short_codename": "quynh_chau"
                    },
                    {
                        "name": "Xã Tân Sơn",
                        "code": 17140,
                        "codename": "xa_tan_son",
                        "division_type": "xã",
                        "short_codename": "tan_son"
                    },
                    {
                        "name": "Xã Quỳnh Văn",
                        "code": 17143,
                        "codename": "xa_quynh_van",
                        "division_type": "xã",
                        "short_codename": "quynh_van"
                    },
                    {
                        "name": "Xã Ngọc Sơn",
                        "code": 17146,
                        "codename": "xa_ngoc_son",
                        "division_type": "xã",
                        "short_codename": "ngoc_son"
                    },
                    {
                        "name": "Xã Quỳnh Tam",
                        "code": 17149,
                        "codename": "xa_quynh_tam",
                        "division_type": "xã",
                        "short_codename": "quynh_tam"
                    },
                    {
                        "name": "Xã Quỳnh Hoa",
                        "code": 17152,
                        "codename": "xa_quynh_hoa",
                        "division_type": "xã",
                        "short_codename": "quynh_hoa"
                    },
                    {
                        "name": "Xã Quỳnh Thạch",
                        "code": 17155,
                        "codename": "xa_quynh_thach",
                        "division_type": "xã",
                        "short_codename": "quynh_thach"
                    },
                    {
                        "name": "Xã Quỳnh Bảng",
                        "code": 17158,
                        "codename": "xa_quynh_bang",
                        "division_type": "xã",
                        "short_codename": "quynh_bang"
                    },
                    {
                        "name": "Xã Quỳnh Mỹ",
                        "code": 17161,
                        "codename": "xa_quynh_my",
                        "division_type": "xã",
                        "short_codename": "quynh_my"
                    },
                    {
                        "name": "Xã Quỳnh Thanh",
                        "code": 17164,
                        "codename": "xa_quynh_thanh",
                        "division_type": "xã",
                        "short_codename": "quynh_thanh"
                    },
                    {
                        "name": "Xã Quỳnh Hậu",
                        "code": 17167,
                        "codename": "xa_quynh_hau",
                        "division_type": "xã",
                        "short_codename": "quynh_hau"
                    },
                    {
                        "name": "Xã Quỳnh Lâm",
                        "code": 17170,
                        "codename": "xa_quynh_lam",
                        "division_type": "xã",
                        "short_codename": "quynh_lam"
                    },
                    {
                        "name": "Xã Quỳnh Đôi",
                        "code": 17173,
                        "codename": "xa_quynh_doi",
                        "division_type": "xã",
                        "short_codename": "quynh_doi"
                    },
                    {
                        "name": "Xã Quỳnh Lương",
                        "code": 17176,
                        "codename": "xa_quynh_luong",
                        "division_type": "xã",
                        "short_codename": "quynh_luong"
                    },
                    {
                        "name": "Xã Quỳnh Hồng",
                        "code": 17179,
                        "codename": "xa_quynh_hong",
                        "division_type": "xã",
                        "short_codename": "quynh_hong"
                    },
                    {
                        "name": "Xã Quỳnh Yên",
                        "code": 17182,
                        "codename": "xa_quynh_yen",
                        "division_type": "xã",
                        "short_codename": "quynh_yen"
                    },
                    {
                        "name": "Xã Quỳnh Bá",
                        "code": 17185,
                        "codename": "xa_quynh_ba",
                        "division_type": "xã",
                        "short_codename": "quynh_ba"
                    },
                    {
                        "name": "Xã Quỳnh Minh",
                        "code": 17188,
                        "codename": "xa_quynh_minh",
                        "division_type": "xã",
                        "short_codename": "quynh_minh"
                    },
                    {
                        "name": "Xã Quỳnh Diễn",
                        "code": 17191,
                        "codename": "xa_quynh_dien",
                        "division_type": "xã",
                        "short_codename": "quynh_dien"
                    },
                    {
                        "name": "Xã Quỳnh Hưng",
                        "code": 17194,
                        "codename": "xa_quynh_hung",
                        "division_type": "xã",
                        "short_codename": "quynh_hung"
                    },
                    {
                        "name": "Xã Quỳnh Giang",
                        "code": 17197,
                        "codename": "xa_quynh_giang",
                        "division_type": "xã",
                        "short_codename": "quynh_giang"
                    },
                    {
                        "name": "Xã Quỳnh Ngọc",
                        "code": 17200,
                        "codename": "xa_quynh_ngoc",
                        "division_type": "xã",
                        "short_codename": "quynh_ngoc"
                    },
                    {
                        "name": "Xã Quỳnh Nghĩa",
                        "code": 17203,
                        "codename": "xa_quynh_nghia",
                        "division_type": "xã",
                        "short_codename": "quynh_nghia"
                    },
                    {
                        "name": "Xã An Hòa",
                        "code": 17206,
                        "codename": "xa_an_hoa",
                        "division_type": "xã",
                        "short_codename": "an_hoa"
                    },
                    {
                        "name": "Xã Tiến Thủy",
                        "code": 17209,
                        "codename": "xa_tien_thuy",
                        "division_type": "xã",
                        "short_codename": "tien_thuy"
                    },
                    {
                        "name": "Xã Sơn Hải",
                        "code": 17212,
                        "codename": "xa_son_hai",
                        "division_type": "xã",
                        "short_codename": "son_hai"
                    },
                    {
                        "name": "Xã Quỳnh Thọ",
                        "code": 17215,
                        "codename": "xa_quynh_tho",
                        "division_type": "xã",
                        "short_codename": "quynh_tho"
                    },
                    {
                        "name": "Xã Quỳnh Thuận",
                        "code": 17218,
                        "codename": "xa_quynh_thuan",
                        "division_type": "xã",
                        "short_codename": "quynh_thuan"
                    },
                    {
                        "name": "Xã Quỳnh Long",
                        "code": 17221,
                        "codename": "xa_quynh_long",
                        "division_type": "xã",
                        "short_codename": "quynh_long"
                    },
                    {
                        "name": "Xã Tân Thắng",
                        "code": 17224,
                        "codename": "xa_tan_thang",
                        "division_type": "xã",
                        "short_codename": "tan_thang"
                    }
                ]
            },
            {
                "name": "Huyện Con Cuông",
                "code": 422,
                "codename": "huyen_con_cuong",
                "division_type": "huyện",
                "short_codename": "con_cuong",
                "wards": [
                    {
                        "name": "Thị trấn Con Cuông",
                        "code": 17227,
                        "codename": "thi_tran_con_cuong",
                        "division_type": "thị trấn",
                        "short_codename": "con_cuong"
                    },
                    {
                        "name": "Xã Bình Chuẩn",
                        "code": 17230,
                        "codename": "xa_binh_chuan",
                        "division_type": "xã",
                        "short_codename": "binh_chuan"
                    },
                    {
                        "name": "Xã Lạng Khê",
                        "code": 17233,
                        "codename": "xa_lang_khe",
                        "division_type": "xã",
                        "short_codename": "lang_khe"
                    },
                    {
                        "name": "Xã Cam Lâm",
                        "code": 17236,
                        "codename": "xa_cam_lam",
                        "division_type": "xã",
                        "short_codename": "cam_lam"
                    },
                    {
                        "name": "Xã Thạch Ngàn",
                        "code": 17239,
                        "codename": "xa_thach_ngan",
                        "division_type": "xã",
                        "short_codename": "thach_ngan"
                    },
                    {
                        "name": "Xã Đôn Phục",
                        "code": 17242,
                        "codename": "xa_don_phuc",
                        "division_type": "xã",
                        "short_codename": "don_phuc"
                    },
                    {
                        "name": "Xã Mậu Đức",
                        "code": 17245,
                        "codename": "xa_mau_duc",
                        "division_type": "xã",
                        "short_codename": "mau_duc"
                    },
                    {
                        "name": "Xã Châu Khê",
                        "code": 17248,
                        "codename": "xa_chau_khe",
                        "division_type": "xã",
                        "short_codename": "chau_khe"
                    },
                    {
                        "name": "Xã Chi Khê",
                        "code": 17251,
                        "codename": "xa_chi_khe",
                        "division_type": "xã",
                        "short_codename": "chi_khe"
                    },
                    {
                        "name": "Xã Bồng Khê",
                        "code": 17254,
                        "codename": "xa_bong_khe",
                        "division_type": "xã",
                        "short_codename": "bong_khe"
                    },
                    {
                        "name": "Xã Yên Khê",
                        "code": 17257,
                        "codename": "xa_yen_khe",
                        "division_type": "xã",
                        "short_codename": "yen_khe"
                    },
                    {
                        "name": "Xã Lục Dạ",
                        "code": 17260,
                        "codename": "xa_luc_da",
                        "division_type": "xã",
                        "short_codename": "luc_da"
                    },
                    {
                        "name": "Xã Môn Sơn",
                        "code": 17263,
                        "codename": "xa_mon_son",
                        "division_type": "xã",
                        "short_codename": "mon_son"
                    }
                ]
            },
            {
                "name": "Huyện Tân Kỳ",
                "code": 423,
                "codename": "huyen_tan_ky",
                "division_type": "huyện",
                "short_codename": "tan_ky",
                "wards": [
                    {
                        "name": "Thị trấn Tân Kỳ",
                        "code": 17266,
                        "codename": "thi_tran_tan_ky",
                        "division_type": "thị trấn",
                        "short_codename": "tan_ky"
                    },
                    {
                        "name": "Xã Tân Hợp",
                        "code": 17269,
                        "codename": "xa_tan_hop",
                        "division_type": "xã",
                        "short_codename": "tan_hop"
                    },
                    {
                        "name": "Xã Tân Phú",
                        "code": 17272,
                        "codename": "xa_tan_phu",
                        "division_type": "xã",
                        "short_codename": "tan_phu"
                    },
                    {
                        "name": "Xã Tân Xuân",
                        "code": 17275,
                        "codename": "xa_tan_xuan",
                        "division_type": "xã",
                        "short_codename": "tan_xuan"
                    },
                    {
                        "name": "Xã Giai Xuân",
                        "code": 17278,
                        "codename": "xa_giai_xuan",
                        "division_type": "xã",
                        "short_codename": "giai_xuan"
                    },
                    {
                        "name": "Xã Nghĩa Bình",
                        "code": 17281,
                        "codename": "xa_nghia_binh",
                        "division_type": "xã",
                        "short_codename": "nghia_binh"
                    },
                    {
                        "name": "Xã Nghĩa Đồng",
                        "code": 17284,
                        "codename": "xa_nghia_dong",
                        "division_type": "xã",
                        "short_codename": "nghia_dong"
                    },
                    {
                        "name": "Xã Đồng Văn",
                        "code": 17287,
                        "codename": "xa_dong_van",
                        "division_type": "xã",
                        "short_codename": "dong_van"
                    },
                    {
                        "name": "Xã Nghĩa Thái",
                        "code": 17290,
                        "codename": "xa_nghia_thai",
                        "division_type": "xã",
                        "short_codename": "nghia_thai"
                    },
                    {
                        "name": "Xã Nghĩa Hợp",
                        "code": 17293,
                        "codename": "xa_nghia_hop",
                        "division_type": "xã",
                        "short_codename": "nghia_hop"
                    },
                    {
                        "name": "Xã Nghĩa Hoàn",
                        "code": 17296,
                        "codename": "xa_nghia_hoan",
                        "division_type": "xã",
                        "short_codename": "nghia_hoan"
                    },
                    {
                        "name": "Xã Nghĩa Phúc",
                        "code": 17299,
                        "codename": "xa_nghia_phuc",
                        "division_type": "xã",
                        "short_codename": "nghia_phuc"
                    },
                    {
                        "name": "Xã Tiên Kỳ",
                        "code": 17302,
                        "codename": "xa_tien_ky",
                        "division_type": "xã",
                        "short_codename": "tien_ky"
                    },
                    {
                        "name": "Xã Tân An",
                        "code": 17305,
                        "codename": "xa_tan_an",
                        "division_type": "xã",
                        "short_codename": "tan_an"
                    },
                    {
                        "name": "Xã Nghĩa Dũng",
                        "code": 17308,
                        "codename": "xa_nghia_dung",
                        "division_type": "xã",
                        "short_codename": "nghia_dung"
                    },
                    {
                        "name": "Xã Tân Long",
                        "code": 17311,
                        "codename": "xa_tan_long",
                        "division_type": "xã",
                        "short_codename": "tan_long"
                    },
                    {
                        "name": "Xã Kỳ Sơn",
                        "code": 17314,
                        "codename": "xa_ky_son",
                        "division_type": "xã",
                        "short_codename": "ky_son"
                    },
                    {
                        "name": "Xã Hương Sơn",
                        "code": 17317,
                        "codename": "xa_huong_son",
                        "division_type": "xã",
                        "short_codename": "huong_son"
                    },
                    {
                        "name": "Xã Kỳ Tân",
                        "code": 17320,
                        "codename": "xa_ky_tan",
                        "division_type": "xã",
                        "short_codename": "ky_tan"
                    },
                    {
                        "name": "Xã Phú Sơn",
                        "code": 17323,
                        "codename": "xa_phu_son",
                        "division_type": "xã",
                        "short_codename": "phu_son"
                    },
                    {
                        "name": "Xã Tân Hương",
                        "code": 17325,
                        "codename": "xa_tan_huong",
                        "division_type": "xã",
                        "short_codename": "tan_huong"
                    },
                    {
                        "name": "Xã Nghĩa Hành",
                        "code": 17326,
                        "codename": "xa_nghia_hanh",
                        "division_type": "xã",
                        "short_codename": "nghia_hanh"
                    }
                ]
            },
            {
                "name": "Huyện Anh Sơn",
                "code": 424,
                "codename": "huyen_anh_son",
                "division_type": "huyện",
                "short_codename": "anh_son",
                "wards": [
                    {
                        "name": "Thị trấn Anh Sơn",
                        "code": 17329,
                        "codename": "thi_tran_anh_son",
                        "division_type": "thị trấn",
                        "short_codename": "anh_son"
                    },
                    {
                        "name": "Xã Thọ Sơn",
                        "code": 17332,
                        "codename": "xa_tho_son",
                        "division_type": "xã",
                        "short_codename": "tho_son"
                    },
                    {
                        "name": "Xã Thành Sơn",
                        "code": 17335,
                        "codename": "xa_thanh_son",
                        "division_type": "xã",
                        "short_codename": "thanh_son"
                    },
                    {
                        "name": "Xã Bình Sơn",
                        "code": 17338,
                        "codename": "xa_binh_son",
                        "division_type": "xã",
                        "short_codename": "binh_son"
                    },
                    {
                        "name": "Xã Tam Sơn",
                        "code": 17341,
                        "codename": "xa_tam_son",
                        "division_type": "xã",
                        "short_codename": "tam_son"
                    },
                    {
                        "name": "Xã Đỉnh Sơn",
                        "code": 17344,
                        "codename": "xa_dinh_son",
                        "division_type": "xã",
                        "short_codename": "dinh_son"
                    },
                    {
                        "name": "Xã Hùng Sơn",
                        "code": 17347,
                        "codename": "xa_hung_son",
                        "division_type": "xã",
                        "short_codename": "hung_son"
                    },
                    {
                        "name": "Xã Cẩm Sơn",
                        "code": 17350,
                        "codename": "xa_cam_son",
                        "division_type": "xã",
                        "short_codename": "cam_son"
                    },
                    {
                        "name": "Xã Đức Sơn",
                        "code": 17353,
                        "codename": "xa_duc_son",
                        "division_type": "xã",
                        "short_codename": "duc_son"
                    },
                    {
                        "name": "Xã Tường Sơn",
                        "code": 17356,
                        "codename": "xa_tuong_son",
                        "division_type": "xã",
                        "short_codename": "tuong_son"
                    },
                    {
                        "name": "Xã Hoa Sơn",
                        "code": 17357,
                        "codename": "xa_hoa_son",
                        "division_type": "xã",
                        "short_codename": "hoa_son"
                    },
                    {
                        "name": "Xã Tào Sơn",
                        "code": 17359,
                        "codename": "xa_tao_son",
                        "division_type": "xã",
                        "short_codename": "tao_son"
                    },
                    {
                        "name": "Xã Vĩnh Sơn",
                        "code": 17362,
                        "codename": "xa_vinh_son",
                        "division_type": "xã",
                        "short_codename": "vinh_son"
                    },
                    {
                        "name": "Xã Lạng Sơn",
                        "code": 17365,
                        "codename": "xa_lang_son",
                        "division_type": "xã",
                        "short_codename": "lang_son"
                    },
                    {
                        "name": "Xã Hội Sơn",
                        "code": 17368,
                        "codename": "xa_hoi_son",
                        "division_type": "xã",
                        "short_codename": "hoi_son"
                    },
                    {
                        "name": "Xã Thạch Sơn",
                        "code": 17371,
                        "codename": "xa_thach_son",
                        "division_type": "xã",
                        "short_codename": "thach_son"
                    },
                    {
                        "name": "Xã Phúc Sơn",
                        "code": 17374,
                        "codename": "xa_phuc_son",
                        "division_type": "xã",
                        "short_codename": "phuc_son"
                    },
                    {
                        "name": "Xã Long Sơn",
                        "code": 17377,
                        "codename": "xa_long_son",
                        "division_type": "xã",
                        "short_codename": "long_son"
                    },
                    {
                        "name": "Xã Khai Sơn",
                        "code": 17380,
                        "codename": "xa_khai_son",
                        "division_type": "xã",
                        "short_codename": "khai_son"
                    },
                    {
                        "name": "Xã Lĩnh Sơn",
                        "code": 17383,
                        "codename": "xa_linh_son",
                        "division_type": "xã",
                        "short_codename": "linh_son"
                    },
                    {
                        "name": "Xã Cao Sơn",
                        "code": 17386,
                        "codename": "xa_cao_son",
                        "division_type": "xã",
                        "short_codename": "cao_son"
                    }
                ]
            },
            {
                "name": "Huyện Diễn Châu",
                "code": 425,
                "codename": "huyen_dien_chau",
                "division_type": "huyện",
                "short_codename": "dien_chau",
                "wards": [
                    {
                        "name": "Thị trấn Diễn Châu",
                        "code": 17389,
                        "codename": "thi_tran_dien_chau",
                        "division_type": "thị trấn",
                        "short_codename": "dien_chau"
                    },
                    {
                        "name": "Xã Diễn Lâm",
                        "code": 17392,
                        "codename": "xa_dien_lam",
                        "division_type": "xã",
                        "short_codename": "dien_lam"
                    },
                    {
                        "name": "Xã Diễn Đoài",
                        "code": 17395,
                        "codename": "xa_dien_doai",
                        "division_type": "xã",
                        "short_codename": "dien_doai"
                    },
                    {
                        "name": "Xã Diễn Trường",
                        "code": 17398,
                        "codename": "xa_dien_truong",
                        "division_type": "xã",
                        "short_codename": "dien_truong"
                    },
                    {
                        "name": "Xã Diễn Yên",
                        "code": 17401,
                        "codename": "xa_dien_yen",
                        "division_type": "xã",
                        "short_codename": "dien_yen"
                    },
                    {
                        "name": "Xã Diễn Hoàng",
                        "code": 17404,
                        "codename": "xa_dien_hoang",
                        "division_type": "xã",
                        "short_codename": "dien_hoang"
                    },
                    {
                        "name": "Xã Diễn Hùng",
                        "code": 17407,
                        "codename": "xa_dien_hung",
                        "division_type": "xã",
                        "short_codename": "dien_hung"
                    },
                    {
                        "name": "Xã Diễn Mỹ",
                        "code": 17410,
                        "codename": "xa_dien_my",
                        "division_type": "xã",
                        "short_codename": "dien_my"
                    },
                    {
                        "name": "Xã Diễn Hồng",
                        "code": 17413,
                        "codename": "xa_dien_hong",
                        "division_type": "xã",
                        "short_codename": "dien_hong"
                    },
                    {
                        "name": "Xã Diễn Phong",
                        "code": 17416,
                        "codename": "xa_dien_phong",
                        "division_type": "xã",
                        "short_codename": "dien_phong"
                    },
                    {
                        "name": "Xã Diễn Hải",
                        "code": 17419,
                        "codename": "xa_dien_hai",
                        "division_type": "xã",
                        "short_codename": "dien_hai"
                    },
                    {
                        "name": "Xã Diễn Tháp",
                        "code": 17422,
                        "codename": "xa_dien_thap",
                        "division_type": "xã",
                        "short_codename": "dien_thap"
                    },
                    {
                        "name": "Xã Diễn Liên",
                        "code": 17425,
                        "codename": "xa_dien_lien",
                        "division_type": "xã",
                        "short_codename": "dien_lien"
                    },
                    {
                        "name": "Xã Diễn Vạn",
                        "code": 17428,
                        "codename": "xa_dien_van",
                        "division_type": "xã",
                        "short_codename": "dien_van"
                    },
                    {
                        "name": "Xã Diễn Kim",
                        "code": 17431,
                        "codename": "xa_dien_kim",
                        "division_type": "xã",
                        "short_codename": "dien_kim"
                    },
                    {
                        "name": "Xã Diễn Kỷ",
                        "code": 17434,
                        "codename": "xa_dien_ky",
                        "division_type": "xã",
                        "short_codename": "dien_ky"
                    },
                    {
                        "name": "Xã Diễn Xuân",
                        "code": 17437,
                        "codename": "xa_dien_xuan",
                        "division_type": "xã",
                        "short_codename": "dien_xuan"
                    },
                    {
                        "name": "Xã Diễn Thái",
                        "code": 17440,
                        "codename": "xa_dien_thai",
                        "division_type": "xã",
                        "short_codename": "dien_thai"
                    },
                    {
                        "name": "Xã Diễn Đồng",
                        "code": 17443,
                        "codename": "xa_dien_dong",
                        "division_type": "xã",
                        "short_codename": "dien_dong"
                    },
                    {
                        "name": "Xã Diễn Bích",
                        "code": 17446,
                        "codename": "xa_dien_bich",
                        "division_type": "xã",
                        "short_codename": "dien_bich"
                    },
                    {
                        "name": "Xã Diễn Hạnh",
                        "code": 17449,
                        "codename": "xa_dien_hanh",
                        "division_type": "xã",
                        "short_codename": "dien_hanh"
                    },
                    {
                        "name": "Xã Diễn Ngọc",
                        "code": 17452,
                        "codename": "xa_dien_ngoc",
                        "division_type": "xã",
                        "short_codename": "dien_ngoc"
                    },
                    {
                        "name": "Xã Diễn Quảng",
                        "code": 17455,
                        "codename": "xa_dien_quang",
                        "division_type": "xã",
                        "short_codename": "dien_quang"
                    },
                    {
                        "name": "Xã Diễn Nguyên",
                        "code": 17458,
                        "codename": "xa_dien_nguyen",
                        "division_type": "xã",
                        "short_codename": "dien_nguyen"
                    },
                    {
                        "name": "Xã Diễn Hoa",
                        "code": 17461,
                        "codename": "xa_dien_hoa",
                        "division_type": "xã",
                        "short_codename": "dien_hoa"
                    },
                    {
                        "name": "Xã Diễn Thành",
                        "code": 17464,
                        "codename": "xa_dien_thanh",
                        "division_type": "xã",
                        "short_codename": "dien_thanh"
                    },
                    {
                        "name": "Xã Diễn Phúc",
                        "code": 17467,
                        "codename": "xa_dien_phuc",
                        "division_type": "xã",
                        "short_codename": "dien_phuc"
                    },
                    {
                        "name": "Xã Diễn Cát",
                        "code": 17476,
                        "codename": "xa_dien_cat",
                        "division_type": "xã",
                        "short_codename": "dien_cat"
                    },
                    {
                        "name": "Xã Diễn Thịnh",
                        "code": 17479,
                        "codename": "xa_dien_thinh",
                        "division_type": "xã",
                        "short_codename": "dien_thinh"
                    },
                    {
                        "name": "Xã Diễn Tân",
                        "code": 17482,
                        "codename": "xa_dien_tan",
                        "division_type": "xã",
                        "short_codename": "dien_tan"
                    },
                    {
                        "name": "Xã Minh Châu",
                        "code": 17485,
                        "codename": "xa_minh_chau",
                        "division_type": "xã",
                        "short_codename": "minh_chau"
                    },
                    {
                        "name": "Xã Diễn Thọ",
                        "code": 17488,
                        "codename": "xa_dien_tho",
                        "division_type": "xã",
                        "short_codename": "dien_tho"
                    },
                    {
                        "name": "Xã Diễn Lợi",
                        "code": 17491,
                        "codename": "xa_dien_loi",
                        "division_type": "xã",
                        "short_codename": "dien_loi"
                    },
                    {
                        "name": "Xã Diễn Lộc",
                        "code": 17494,
                        "codename": "xa_dien_loc",
                        "division_type": "xã",
                        "short_codename": "dien_loc"
                    },
                    {
                        "name": "Xã Diễn Trung",
                        "code": 17497,
                        "codename": "xa_dien_trung",
                        "division_type": "xã",
                        "short_codename": "dien_trung"
                    },
                    {
                        "name": "Xã Diễn An",
                        "code": 17500,
                        "codename": "xa_dien_an",
                        "division_type": "xã",
                        "short_codename": "dien_an"
                    },
                    {
                        "name": "Xã Diễn Phú",
                        "code": 17503,
                        "codename": "xa_dien_phu",
                        "division_type": "xã",
                        "short_codename": "dien_phu"
                    }
                ]
            },
            {
                "name": "Huyện Yên Thành",
                "code": 426,
                "codename": "huyen_yen_thanh",
                "division_type": "huyện",
                "short_codename": "yen_thanh",
                "wards": [
                    {
                        "name": "Thị trấn Yên Thành",
                        "code": 17506,
                        "codename": "thi_tran_yen_thanh",
                        "division_type": "thị trấn",
                        "short_codename": "yen_thanh"
                    },
                    {
                        "name": "Xã Mã Thành",
                        "code": 17509,
                        "codename": "xa_ma_thanh",
                        "division_type": "xã",
                        "short_codename": "ma_thanh"
                    },
                    {
                        "name": "Xã Tiến Thành",
                        "code": 17510,
                        "codename": "xa_tien_thanh",
                        "division_type": "xã",
                        "short_codename": "tien_thanh"
                    },
                    {
                        "name": "Xã Lăng Thành",
                        "code": 17512,
                        "codename": "xa_lang_thanh",
                        "division_type": "xã",
                        "short_codename": "lang_thanh"
                    },
                    {
                        "name": "Xã Tân Thành",
                        "code": 17515,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Xã Đức Thành",
                        "code": 17518,
                        "codename": "xa_duc_thanh",
                        "division_type": "xã",
                        "short_codename": "duc_thanh"
                    },
                    {
                        "name": "Xã Kim Thành",
                        "code": 17521,
                        "codename": "xa_kim_thanh",
                        "division_type": "xã",
                        "short_codename": "kim_thanh"
                    },
                    {
                        "name": "Xã Hậu Thành",
                        "code": 17524,
                        "codename": "xa_hau_thanh",
                        "division_type": "xã",
                        "short_codename": "hau_thanh"
                    },
                    {
                        "name": "Xã Hùng Thành",
                        "code": 17525,
                        "codename": "xa_hung_thanh",
                        "division_type": "xã",
                        "short_codename": "hung_thanh"
                    },
                    {
                        "name": "Xã Đô Thành",
                        "code": 17527,
                        "codename": "xa_do_thanh",
                        "division_type": "xã",
                        "short_codename": "do_thanh"
                    },
                    {
                        "name": "Xã Thọ Thành",
                        "code": 17530,
                        "codename": "xa_tho_thanh",
                        "division_type": "xã",
                        "short_codename": "tho_thanh"
                    },
                    {
                        "name": "Xã Quang Thành",
                        "code": 17533,
                        "codename": "xa_quang_thanh",
                        "division_type": "xã",
                        "short_codename": "quang_thanh"
                    },
                    {
                        "name": "Xã Tây Thành",
                        "code": 17536,
                        "codename": "xa_tay_thanh",
                        "division_type": "xã",
                        "short_codename": "tay_thanh"
                    },
                    {
                        "name": "Xã Phúc Thành",
                        "code": 17539,
                        "codename": "xa_phuc_thanh",
                        "division_type": "xã",
                        "short_codename": "phuc_thanh"
                    },
                    {
                        "name": "Xã Hồng Thành",
                        "code": 17542,
                        "codename": "xa_hong_thanh",
                        "division_type": "xã",
                        "short_codename": "hong_thanh"
                    },
                    {
                        "name": "Xã Đồng Thành",
                        "code": 17545,
                        "codename": "xa_dong_thanh",
                        "division_type": "xã",
                        "short_codename": "dong_thanh"
                    },
                    {
                        "name": "Xã Phú Thành",
                        "code": 17548,
                        "codename": "xa_phu_thanh",
                        "division_type": "xã",
                        "short_codename": "phu_thanh"
                    },
                    {
                        "name": "Xã Hoa Thành",
                        "code": 17551,
                        "codename": "xa_hoa_thanh",
                        "division_type": "xã",
                        "short_codename": "hoa_thanh"
                    },
                    {
                        "name": "Xã Tăng Thành",
                        "code": 17554,
                        "codename": "xa_tang_thanh",
                        "division_type": "xã",
                        "short_codename": "tang_thanh"
                    },
                    {
                        "name": "Xã Văn Thành",
                        "code": 17557,
                        "codename": "xa_van_thanh",
                        "division_type": "xã",
                        "short_codename": "van_thanh"
                    },
                    {
                        "name": "Xã Thịnh Thành",
                        "code": 17560,
                        "codename": "xa_thinh_thanh",
                        "division_type": "xã",
                        "short_codename": "thinh_thanh"
                    },
                    {
                        "name": "Xã Hợp Thành",
                        "code": 17563,
                        "codename": "xa_hop_thanh",
                        "division_type": "xã",
                        "short_codename": "hop_thanh"
                    },
                    {
                        "name": "Xã Xuân Thành",
                        "code": 17566,
                        "codename": "xa_xuan_thanh",
                        "division_type": "xã",
                        "short_codename": "xuan_thanh"
                    },
                    {
                        "name": "Xã Bắc Thành",
                        "code": 17569,
                        "codename": "xa_bac_thanh",
                        "division_type": "xã",
                        "short_codename": "bac_thanh"
                    },
                    {
                        "name": "Xã Nhân Thành",
                        "code": 17572,
                        "codename": "xa_nhan_thanh",
                        "division_type": "xã",
                        "short_codename": "nhan_thanh"
                    },
                    {
                        "name": "Xã Trung Thành",
                        "code": 17575,
                        "codename": "xa_trung_thanh",
                        "division_type": "xã",
                        "short_codename": "trung_thanh"
                    },
                    {
                        "name": "Xã Long Thành",
                        "code": 17578,
                        "codename": "xa_long_thanh",
                        "division_type": "xã",
                        "short_codename": "long_thanh"
                    },
                    {
                        "name": "Xã Minh Thành",
                        "code": 17581,
                        "codename": "xa_minh_thanh",
                        "division_type": "xã",
                        "short_codename": "minh_thanh"
                    },
                    {
                        "name": "Xã Nam Thành",
                        "code": 17584,
                        "codename": "xa_nam_thanh",
                        "division_type": "xã",
                        "short_codename": "nam_thanh"
                    },
                    {
                        "name": "Xã Vĩnh Thành",
                        "code": 17587,
                        "codename": "xa_vinh_thanh",
                        "division_type": "xã",
                        "short_codename": "vinh_thanh"
                    },
                    {
                        "name": "Xã Lý Thành",
                        "code": 17590,
                        "codename": "xa_ly_thanh",
                        "division_type": "xã",
                        "short_codename": "ly_thanh"
                    },
                    {
                        "name": "Xã Khánh Thành",
                        "code": 17593,
                        "codename": "xa_khanh_thanh",
                        "division_type": "xã",
                        "short_codename": "khanh_thanh"
                    },
                    {
                        "name": "Xã Viên Thành",
                        "code": 17596,
                        "codename": "xa_vien_thanh",
                        "division_type": "xã",
                        "short_codename": "vien_thanh"
                    },
                    {
                        "name": "Xã Đại Thành",
                        "code": 17599,
                        "codename": "xa_dai_thanh",
                        "division_type": "xã",
                        "short_codename": "dai_thanh"
                    },
                    {
                        "name": "Xã Liên Thành",
                        "code": 17602,
                        "codename": "xa_lien_thanh",
                        "division_type": "xã",
                        "short_codename": "lien_thanh"
                    },
                    {
                        "name": "Xã Bảo Thành",
                        "code": 17605,
                        "codename": "xa_bao_thanh",
                        "division_type": "xã",
                        "short_codename": "bao_thanh"
                    },
                    {
                        "name": "Xã Mỹ Thành",
                        "code": 17608,
                        "codename": "xa_my_thanh",
                        "division_type": "xã",
                        "short_codename": "my_thanh"
                    },
                    {
                        "name": "Xã Công Thành",
                        "code": 17611,
                        "codename": "xa_cong_thanh",
                        "division_type": "xã",
                        "short_codename": "cong_thanh"
                    },
                    {
                        "name": "Xã Sơn Thành",
                        "code": 17614,
                        "codename": "xa_son_thanh",
                        "division_type": "xã",
                        "short_codename": "son_thanh"
                    }
                ]
            },
            {
                "name": "Huyện Đô Lương",
                "code": 427,
                "codename": "huyen_do_luong",
                "division_type": "huyện",
                "short_codename": "do_luong",
                "wards": [
                    {
                        "name": "Thị trấn Đô Lương",
                        "code": 17617,
                        "codename": "thi_tran_do_luong",
                        "division_type": "thị trấn",
                        "short_codename": "do_luong"
                    },
                    {
                        "name": "Xã Giang Sơn Đông",
                        "code": 17619,
                        "codename": "xa_giang_son_dong",
                        "division_type": "xã",
                        "short_codename": "giang_son_dong"
                    },
                    {
                        "name": "Xã Giang Sơn Tây",
                        "code": 17620,
                        "codename": "xa_giang_son_tay",
                        "division_type": "xã",
                        "short_codename": "giang_son_tay"
                    },
                    {
                        "name": "Xã Lam Sơn",
                        "code": 17623,
                        "codename": "xa_lam_son",
                        "division_type": "xã",
                        "short_codename": "lam_son"
                    },
                    {
                        "name": "Xã Bồi Sơn",
                        "code": 17626,
                        "codename": "xa_boi_son",
                        "division_type": "xã",
                        "short_codename": "boi_son"
                    },
                    {
                        "name": "Xã Hồng Sơn",
                        "code": 17629,
                        "codename": "xa_hong_son",
                        "division_type": "xã",
                        "short_codename": "hong_son"
                    },
                    {
                        "name": "Xã Bài Sơn",
                        "code": 17632,
                        "codename": "xa_bai_son",
                        "division_type": "xã",
                        "short_codename": "bai_son"
                    },
                    {
                        "name": "Xã Ngọc Sơn",
                        "code": 17635,
                        "codename": "xa_ngoc_son",
                        "division_type": "xã",
                        "short_codename": "ngoc_son"
                    },
                    {
                        "name": "Xã Bắc Sơn",
                        "code": 17638,
                        "codename": "xa_bac_son",
                        "division_type": "xã",
                        "short_codename": "bac_son"
                    },
                    {
                        "name": "Xã Tràng Sơn",
                        "code": 17641,
                        "codename": "xa_trang_son",
                        "division_type": "xã",
                        "short_codename": "trang_son"
                    },
                    {
                        "name": "Xã Thượng Sơn",
                        "code": 17644,
                        "codename": "xa_thuong_son",
                        "division_type": "xã",
                        "short_codename": "thuong_son"
                    },
                    {
                        "name": "Xã Hòa Sơn",
                        "code": 17647,
                        "codename": "xa_hoa_son",
                        "division_type": "xã",
                        "short_codename": "hoa_son"
                    },
                    {
                        "name": "Xã Đặng Sơn",
                        "code": 17650,
                        "codename": "xa_dang_son",
                        "division_type": "xã",
                        "short_codename": "dang_son"
                    },
                    {
                        "name": "Xã Đông Sơn",
                        "code": 17653,
                        "codename": "xa_dong_son",
                        "division_type": "xã",
                        "short_codename": "dong_son"
                    },
                    {
                        "name": "Xã Nam Sơn",
                        "code": 17656,
                        "codename": "xa_nam_son",
                        "division_type": "xã",
                        "short_codename": "nam_son"
                    },
                    {
                        "name": "Xã Lưu Sơn",
                        "code": 17659,
                        "codename": "xa_luu_son",
                        "division_type": "xã",
                        "short_codename": "luu_son"
                    },
                    {
                        "name": "Xã Yên Sơn",
                        "code": 17662,
                        "codename": "xa_yen_son",
                        "division_type": "xã",
                        "short_codename": "yen_son"
                    },
                    {
                        "name": "Xã Văn Sơn",
                        "code": 17665,
                        "codename": "xa_van_son",
                        "division_type": "xã",
                        "short_codename": "van_son"
                    },
                    {
                        "name": "Xã Đà Sơn",
                        "code": 17668,
                        "codename": "xa_da_son",
                        "division_type": "xã",
                        "short_codename": "da_son"
                    },
                    {
                        "name": "Xã Lạc Sơn",
                        "code": 17671,
                        "codename": "xa_lac_son",
                        "division_type": "xã",
                        "short_codename": "lac_son"
                    },
                    {
                        "name": "Xã Tân Sơn",
                        "code": 17674,
                        "codename": "xa_tan_son",
                        "division_type": "xã",
                        "short_codename": "tan_son"
                    },
                    {
                        "name": "Xã Thái Sơn",
                        "code": 17677,
                        "codename": "xa_thai_son",
                        "division_type": "xã",
                        "short_codename": "thai_son"
                    },
                    {
                        "name": "Xã Quang Sơn",
                        "code": 17680,
                        "codename": "xa_quang_son",
                        "division_type": "xã",
                        "short_codename": "quang_son"
                    },
                    {
                        "name": "Xã Thịnh Sơn",
                        "code": 17683,
                        "codename": "xa_thinh_son",
                        "division_type": "xã",
                        "short_codename": "thinh_son"
                    },
                    {
                        "name": "Xã Trung Sơn",
                        "code": 17686,
                        "codename": "xa_trung_son",
                        "division_type": "xã",
                        "short_codename": "trung_son"
                    },
                    {
                        "name": "Xã Xuân Sơn",
                        "code": 17689,
                        "codename": "xa_xuan_son",
                        "division_type": "xã",
                        "short_codename": "xuan_son"
                    },
                    {
                        "name": "Xã Minh Sơn",
                        "code": 17692,
                        "codename": "xa_minh_son",
                        "division_type": "xã",
                        "short_codename": "minh_son"
                    },
                    {
                        "name": "Xã Thuận Sơn",
                        "code": 17695,
                        "codename": "xa_thuan_son",
                        "division_type": "xã",
                        "short_codename": "thuan_son"
                    },
                    {
                        "name": "Xã Nhân Sơn",
                        "code": 17698,
                        "codename": "xa_nhan_son",
                        "division_type": "xã",
                        "short_codename": "nhan_son"
                    },
                    {
                        "name": "Xã Hiến Sơn",
                        "code": 17701,
                        "codename": "xa_hien_son",
                        "division_type": "xã",
                        "short_codename": "hien_son"
                    },
                    {
                        "name": "Xã Mỹ Sơn",
                        "code": 17704,
                        "codename": "xa_my_son",
                        "division_type": "xã",
                        "short_codename": "my_son"
                    },
                    {
                        "name": "Xã Trù Sơn",
                        "code": 17707,
                        "codename": "xa_tru_son",
                        "division_type": "xã",
                        "short_codename": "tru_son"
                    },
                    {
                        "name": "Xã Đại Sơn",
                        "code": 17710,
                        "codename": "xa_dai_son",
                        "division_type": "xã",
                        "short_codename": "dai_son"
                    }
                ]
            },
            {
                "name": "Huyện Thanh Chương",
                "code": 428,
                "codename": "huyen_thanh_chuong",
                "division_type": "huyện",
                "short_codename": "thanh_chuong",
                "wards": [
                    {
                        "name": "Thị trấn Thanh Chương",
                        "code": 17713,
                        "codename": "thi_tran_thanh_chuong",
                        "division_type": "thị trấn",
                        "short_codename": "thanh_chuong"
                    },
                    {
                        "name": "Xã Cát Văn",
                        "code": 17716,
                        "codename": "xa_cat_van",
                        "division_type": "xã",
                        "short_codename": "cat_van"
                    },
                    {
                        "name": "Xã Thanh Nho",
                        "code": 17719,
                        "codename": "xa_thanh_nho",
                        "division_type": "xã",
                        "short_codename": "thanh_nho"
                    },
                    {
                        "name": "Xã Hạnh Lâm",
                        "code": 17722,
                        "codename": "xa_hanh_lam",
                        "division_type": "xã",
                        "short_codename": "hanh_lam"
                    },
                    {
                        "name": "Xã Thanh Sơn",
                        "code": 17723,
                        "codename": "xa_thanh_son",
                        "division_type": "xã",
                        "short_codename": "thanh_son"
                    },
                    {
                        "name": "Xã Thanh Hòa",
                        "code": 17725,
                        "codename": "xa_thanh_hoa",
                        "division_type": "xã",
                        "short_codename": "thanh_hoa"
                    },
                    {
                        "name": "Xã Phong Thịnh",
                        "code": 17728,
                        "codename": "xa_phong_thinh",
                        "division_type": "xã",
                        "short_codename": "phong_thinh"
                    },
                    {
                        "name": "Xã Thanh Phong",
                        "code": 17731,
                        "codename": "xa_thanh_phong",
                        "division_type": "xã",
                        "short_codename": "thanh_phong"
                    },
                    {
                        "name": "Xã Thanh Mỹ",
                        "code": 17734,
                        "codename": "xa_thanh_my",
                        "division_type": "xã",
                        "short_codename": "thanh_my"
                    },
                    {
                        "name": "Xã Thanh Tiên",
                        "code": 17737,
                        "codename": "xa_thanh_tien",
                        "division_type": "xã",
                        "short_codename": "thanh_tien"
                    },
                    {
                        "name": "Xã Thanh Liên",
                        "code": 17743,
                        "codename": "xa_thanh_lien",
                        "division_type": "xã",
                        "short_codename": "thanh_lien"
                    },
                    {
                        "name": "Xã Đại Đồng",
                        "code": 17749,
                        "codename": "xa_dai_dong",
                        "division_type": "xã",
                        "short_codename": "dai_dong"
                    },
                    {
                        "name": "Xã Thanh Đồng",
                        "code": 17752,
                        "codename": "xa_thanh_dong",
                        "division_type": "xã",
                        "short_codename": "thanh_dong"
                    },
                    {
                        "name": "Xã Thanh Ngọc",
                        "code": 17755,
                        "codename": "xa_thanh_ngoc",
                        "division_type": "xã",
                        "short_codename": "thanh_ngoc"
                    },
                    {
                        "name": "Xã Thanh Hương",
                        "code": 17758,
                        "codename": "xa_thanh_huong",
                        "division_type": "xã",
                        "short_codename": "thanh_huong"
                    },
                    {
                        "name": "Xã Ngọc Lâm",
                        "code": 17759,
                        "codename": "xa_ngoc_lam",
                        "division_type": "xã",
                        "short_codename": "ngoc_lam"
                    },
                    {
                        "name": "Xã Thanh Lĩnh",
                        "code": 17761,
                        "codename": "xa_thanh_linh",
                        "division_type": "xã",
                        "short_codename": "thanh_linh"
                    },
                    {
                        "name": "Xã Đồng Văn",
                        "code": 17764,
                        "codename": "xa_dong_van",
                        "division_type": "xã",
                        "short_codename": "dong_van"
                    },
                    {
                        "name": "Xã Ngọc Sơn",
                        "code": 17767,
                        "codename": "xa_ngoc_son",
                        "division_type": "xã",
                        "short_codename": "ngoc_son"
                    },
                    {
                        "name": "Xã Thanh Thịnh",
                        "code": 17770,
                        "codename": "xa_thanh_thinh",
                        "division_type": "xã",
                        "short_codename": "thanh_thinh"
                    },
                    {
                        "name": "Xã Thanh An",
                        "code": 17773,
                        "codename": "xa_thanh_an",
                        "division_type": "xã",
                        "short_codename": "thanh_an"
                    },
                    {
                        "name": "Xã Thanh Chi",
                        "code": 17776,
                        "codename": "xa_thanh_chi",
                        "division_type": "xã",
                        "short_codename": "thanh_chi"
                    },
                    {
                        "name": "Xã Xuân Tường",
                        "code": 17779,
                        "codename": "xa_xuan_tuong",
                        "division_type": "xã",
                        "short_codename": "xuan_tuong"
                    },
                    {
                        "name": "Xã Thanh Dương",
                        "code": 17782,
                        "codename": "xa_thanh_duong",
                        "division_type": "xã",
                        "short_codename": "thanh_duong"
                    },
                    {
                        "name": "Xã Thanh Lương",
                        "code": 17785,
                        "codename": "xa_thanh_luong",
                        "division_type": "xã",
                        "short_codename": "thanh_luong"
                    },
                    {
                        "name": "Xã Thanh Khê",
                        "code": 17788,
                        "codename": "xa_thanh_khe",
                        "division_type": "xã",
                        "short_codename": "thanh_khe"
                    },
                    {
                        "name": "Xã Võ Liệt",
                        "code": 17791,
                        "codename": "xa_vo_liet",
                        "division_type": "xã",
                        "short_codename": "vo_liet"
                    },
                    {
                        "name": "Xã Thanh Long",
                        "code": 17794,
                        "codename": "xa_thanh_long",
                        "division_type": "xã",
                        "short_codename": "thanh_long"
                    },
                    {
                        "name": "Xã Thanh Thủy",
                        "code": 17797,
                        "codename": "xa_thanh_thuy",
                        "division_type": "xã",
                        "short_codename": "thanh_thuy"
                    },
                    {
                        "name": "Xã Thanh Khai",
                        "code": 17800,
                        "codename": "xa_thanh_khai",
                        "division_type": "xã",
                        "short_codename": "thanh_khai"
                    },
                    {
                        "name": "Xã Thanh Yên",
                        "code": 17803,
                        "codename": "xa_thanh_yen",
                        "division_type": "xã",
                        "short_codename": "thanh_yen"
                    },
                    {
                        "name": "Xã Thanh Hà",
                        "code": 17806,
                        "codename": "xa_thanh_ha",
                        "division_type": "xã",
                        "short_codename": "thanh_ha"
                    },
                    {
                        "name": "Xã Thanh Giang",
                        "code": 17809,
                        "codename": "xa_thanh_giang",
                        "division_type": "xã",
                        "short_codename": "thanh_giang"
                    },
                    {
                        "name": "Xã Thanh Tùng",
                        "code": 17812,
                        "codename": "xa_thanh_tung",
                        "division_type": "xã",
                        "short_codename": "thanh_tung"
                    },
                    {
                        "name": "Xã Thanh Lâm",
                        "code": 17815,
                        "codename": "xa_thanh_lam",
                        "division_type": "xã",
                        "short_codename": "thanh_lam"
                    },
                    {
                        "name": "Xã Thanh Mai",
                        "code": 17818,
                        "codename": "xa_thanh_mai",
                        "division_type": "xã",
                        "short_codename": "thanh_mai"
                    },
                    {
                        "name": "Xã Thanh Xuân",
                        "code": 17821,
                        "codename": "xa_thanh_xuan",
                        "division_type": "xã",
                        "short_codename": "thanh_xuan"
                    },
                    {
                        "name": "Xã Thanh Đức",
                        "code": 17824,
                        "codename": "xa_thanh_duc",
                        "division_type": "xã",
                        "short_codename": "thanh_duc"
                    }
                ]
            },
            {
                "name": "Huyện Nghi Lộc",
                "code": 429,
                "codename": "huyen_nghi_loc",
                "division_type": "huyện",
                "short_codename": "nghi_loc",
                "wards": [
                    {
                        "name": "Thị trấn Quán Hành",
                        "code": 17827,
                        "codename": "thi_tran_quan_hanh",
                        "division_type": "thị trấn",
                        "short_codename": "quan_hanh"
                    },
                    {
                        "name": "Xã Nghi Văn",
                        "code": 17830,
                        "codename": "xa_nghi_van",
                        "division_type": "xã",
                        "short_codename": "xa_nghi_van"
                    },
                    {
                        "name": "Xã Nghi Yên",
                        "code": 17833,
                        "codename": "xa_nghi_yen",
                        "division_type": "xã",
                        "short_codename": "nghi_yen"
                    },
                    {
                        "name": "Xã Nghi Tiến",
                        "code": 17836,
                        "codename": "xa_nghi_tien",
                        "division_type": "xã",
                        "short_codename": "nghi_tien"
                    },
                    {
                        "name": "Xã Nghi Hưng",
                        "code": 17839,
                        "codename": "xa_nghi_hung",
                        "division_type": "xã",
                        "short_codename": "nghi_hung"
                    },
                    {
                        "name": "Xã Nghi Đồng",
                        "code": 17842,
                        "codename": "xa_nghi_dong",
                        "division_type": "xã",
                        "short_codename": "nghi_dong"
                    },
                    {
                        "name": "Xã Nghi Thiết",
                        "code": 17845,
                        "codename": "xa_nghi_thiet",
                        "division_type": "xã",
                        "short_codename": "nghi_thiet"
                    },
                    {
                        "name": "Xã Nghi Lâm",
                        "code": 17848,
                        "codename": "xa_nghi_lam",
                        "division_type": "xã",
                        "short_codename": "nghi_lam"
                    },
                    {
                        "name": "Xã Nghi Quang",
                        "code": 17851,
                        "codename": "xa_nghi_quang",
                        "division_type": "xã",
                        "short_codename": "nghi_quang"
                    },
                    {
                        "name": "Xã Nghi Kiều",
                        "code": 17854,
                        "codename": "xa_nghi_kieu",
                        "division_type": "xã",
                        "short_codename": "nghi_kieu"
                    },
                    {
                        "name": "Xã Nghi Mỹ",
                        "code": 17857,
                        "codename": "xa_nghi_my",
                        "division_type": "xã",
                        "short_codename": "nghi_my"
                    },
                    {
                        "name": "Xã Nghi Phương",
                        "code": 17860,
                        "codename": "xa_nghi_phuong",
                        "division_type": "xã",
                        "short_codename": "nghi_phuong"
                    },
                    {
                        "name": "Xã Nghi Thuận",
                        "code": 17863,
                        "codename": "xa_nghi_thuan",
                        "division_type": "xã",
                        "short_codename": "nghi_thuan"
                    },
                    {
                        "name": "Xã Nghi Long",
                        "code": 17866,
                        "codename": "xa_nghi_long",
                        "division_type": "xã",
                        "short_codename": "nghi_long"
                    },
                    {
                        "name": "Xã Nghi Xá",
                        "code": 17869,
                        "codename": "xa_nghi_xa",
                        "division_type": "xã",
                        "short_codename": "nghi_xa"
                    },
                    {
                        "name": "Xã Nghi Hoa",
                        "code": 17875,
                        "codename": "xa_nghi_hoa",
                        "division_type": "xã",
                        "short_codename": "nghi_hoa"
                    },
                    {
                        "name": "Xã Khánh Hợp",
                        "code": 17878,
                        "codename": "xa_khanh_hop",
                        "division_type": "xã",
                        "short_codename": "khanh_hop"
                    },
                    {
                        "name": "Xã Nghi Thịnh",
                        "code": 17881,
                        "codename": "xa_nghi_thinh",
                        "division_type": "xã",
                        "short_codename": "nghi_thinh"
                    },
                    {
                        "name": "Xã Nghi Công Bắc",
                        "code": 17884,
                        "codename": "xa_nghi_cong_bac",
                        "division_type": "xã",
                        "short_codename": "nghi_cong_bac"
                    },
                    {
                        "name": "Xã Nghi Công Nam",
                        "code": 17887,
                        "codename": "xa_nghi_cong_nam",
                        "division_type": "xã",
                        "short_codename": "nghi_cong_nam"
                    },
                    {
                        "name": "Xã Nghi Thạch",
                        "code": 17890,
                        "codename": "xa_nghi_thach",
                        "division_type": "xã",
                        "short_codename": "nghi_thach"
                    },
                    {
                        "name": "Xã Nghi Trung",
                        "code": 17893,
                        "codename": "xa_nghi_trung",
                        "division_type": "xã",
                        "short_codename": "nghi_trung"
                    },
                    {
                        "name": "Xã Nghi Trường",
                        "code": 17896,
                        "codename": "xa_nghi_truong",
                        "division_type": "xã",
                        "short_codename": "nghi_truong"
                    },
                    {
                        "name": "Xã Nghi Diên",
                        "code": 17899,
                        "codename": "xa_nghi_dien",
                        "division_type": "xã",
                        "short_codename": "nghi_dien"
                    },
                    {
                        "name": "Xã Nghi Phong",
                        "code": 17902,
                        "codename": "xa_nghi_phong",
                        "division_type": "xã",
                        "short_codename": "nghi_phong"
                    },
                    {
                        "name": "Xã Nghi Xuân",
                        "code": 17905,
                        "codename": "xa_nghi_xuan",
                        "division_type": "xã",
                        "short_codename": "nghi_xuan"
                    },
                    {
                        "name": "Xã Nghi Vạn",
                        "code": 17911,
                        "codename": "xa_nghi_van",
                        "division_type": "xã",
                        "short_codename": "xa_nghi_van"
                    },
                    {
                        "name": "Xã Phúc Thọ",
                        "code": 17917,
                        "codename": "xa_phuc_tho",
                        "division_type": "xã",
                        "short_codename": "phuc_tho"
                    },
                    {
                        "name": "Xã Nghi Thái",
                        "code": 17926,
                        "codename": "xa_nghi_thai",
                        "division_type": "xã",
                        "short_codename": "nghi_thai"
                    }
                ]
            },
            {
                "name": "Huyện Nam Đàn",
                "code": 430,
                "codename": "huyen_nam_dan",
                "division_type": "huyện",
                "short_codename": "nam_dan",
                "wards": [
                    {
                        "name": "Xã Nam Hưng",
                        "code": 17932,
                        "codename": "xa_nam_hung",
                        "division_type": "xã",
                        "short_codename": "nam_hung"
                    },
                    {
                        "name": "Xã Nam Nghĩa",
                        "code": 17935,
                        "codename": "xa_nam_nghia",
                        "division_type": "xã",
                        "short_codename": "nam_nghia"
                    },
                    {
                        "name": "Xã Nam Thanh",
                        "code": 17938,
                        "codename": "xa_nam_thanh",
                        "division_type": "xã",
                        "short_codename": "nam_thanh"
                    },
                    {
                        "name": "Xã Nam Anh",
                        "code": 17941,
                        "codename": "xa_nam_anh",
                        "division_type": "xã",
                        "short_codename": "nam_anh"
                    },
                    {
                        "name": "Xã Nam Xuân",
                        "code": 17944,
                        "codename": "xa_nam_xuan",
                        "division_type": "xã",
                        "short_codename": "nam_xuan"
                    },
                    {
                        "name": "Xã Nam Thái",
                        "code": 17947,
                        "codename": "xa_nam_thai",
                        "division_type": "xã",
                        "short_codename": "nam_thai"
                    },
                    {
                        "name": "Thị trấn Nam Đàn",
                        "code": 17950,
                        "codename": "thi_tran_nam_dan",
                        "division_type": "thị trấn",
                        "short_codename": "nam_dan"
                    },
                    {
                        "name": "Xã Nam Lĩnh",
                        "code": 17953,
                        "codename": "xa_nam_linh",
                        "division_type": "xã",
                        "short_codename": "nam_linh"
                    },
                    {
                        "name": "Xã Nam Giang",
                        "code": 17956,
                        "codename": "xa_nam_giang",
                        "division_type": "xã",
                        "short_codename": "nam_giang"
                    },
                    {
                        "name": "Xã Xuân Hòa",
                        "code": 17959,
                        "codename": "xa_xuan_hoa",
                        "division_type": "xã",
                        "short_codename": "xuan_hoa"
                    },
                    {
                        "name": "Xã Hùng Tiến",
                        "code": 17962,
                        "codename": "xa_hung_tien",
                        "division_type": "xã",
                        "short_codename": "hung_tien"
                    },
                    {
                        "name": "Xã Thượng Tân Lộc",
                        "code": 17968,
                        "codename": "xa_thuong_tan_loc",
                        "division_type": "xã",
                        "short_codename": "thuong_tan_loc"
                    },
                    {
                        "name": "Xã Kim Liên",
                        "code": 17971,
                        "codename": "xa_kim_lien",
                        "division_type": "xã",
                        "short_codename": "kim_lien"
                    },
                    {
                        "name": "Xã Hồng Long",
                        "code": 17977,
                        "codename": "xa_hong_long",
                        "division_type": "xã",
                        "short_codename": "hong_long"
                    },
                    {
                        "name": "Xã Xuân Lâm",
                        "code": 17980,
                        "codename": "xa_xuan_lam",
                        "division_type": "xã",
                        "short_codename": "xuan_lam"
                    },
                    {
                        "name": "Xã Nam Cát",
                        "code": 17983,
                        "codename": "xa_nam_cat",
                        "division_type": "xã",
                        "short_codename": "nam_cat"
                    },
                    {
                        "name": "Xã Khánh Sơn",
                        "code": 17986,
                        "codename": "xa_khanh_son",
                        "division_type": "xã",
                        "short_codename": "khanh_son"
                    },
                    {
                        "name": "Xã Trung Phúc Cường",
                        "code": 17989,
                        "codename": "xa_trung_phuc_cuong",
                        "division_type": "xã",
                        "short_codename": "trung_phuc_cuong"
                    },
                    {
                        "name": "Xã Nam Kim",
                        "code": 17998,
                        "codename": "xa_nam_kim",
                        "division_type": "xã",
                        "short_codename": "nam_kim"
                    }
                ]
            },
            {
                "name": "Huyện Hưng Nguyên",
                "code": 431,
                "codename": "huyen_hung_nguyen",
                "division_type": "huyện",
                "short_codename": "hung_nguyen",
                "wards": [
                    {
                        "name": "Thị trấn Hưng Nguyên",
                        "code": 18001,
                        "codename": "thi_tran_hung_nguyen",
                        "division_type": "thị trấn",
                        "short_codename": "hung_nguyen"
                    },
                    {
                        "name": "Xã Hưng Trung",
                        "code": 18004,
                        "codename": "xa_hung_trung",
                        "division_type": "xã",
                        "short_codename": "hung_trung"
                    },
                    {
                        "name": "Xã Hưng Yên",
                        "code": 18007,
                        "codename": "xa_hung_yen",
                        "division_type": "xã",
                        "short_codename": "hung_yen"
                    },
                    {
                        "name": "Xã Hưng Yên Bắc",
                        "code": 18008,
                        "codename": "xa_hung_yen_bac",
                        "division_type": "xã",
                        "short_codename": "hung_yen_bac"
                    },
                    {
                        "name": "Xã Hưng Tây",
                        "code": 18010,
                        "codename": "xa_hung_tay",
                        "division_type": "xã",
                        "short_codename": "hung_tay"
                    },
                    {
                        "name": "Xã Hưng Đạo",
                        "code": 18016,
                        "codename": "xa_hung_dao",
                        "division_type": "xã",
                        "short_codename": "hung_dao"
                    },
                    {
                        "name": "Xã Hưng Mỹ",
                        "code": 18019,
                        "codename": "xa_hung_my",
                        "division_type": "xã",
                        "short_codename": "hung_my"
                    },
                    {
                        "name": "Xã Hưng Thịnh",
                        "code": 18022,
                        "codename": "xa_hung_thinh",
                        "division_type": "xã",
                        "short_codename": "hung_thinh"
                    },
                    {
                        "name": "Xã Hưng Lĩnh",
                        "code": 18025,
                        "codename": "xa_hung_linh",
                        "division_type": "xã",
                        "short_codename": "hung_linh"
                    },
                    {
                        "name": "Xã Hưng Thông",
                        "code": 18028,
                        "codename": "xa_hung_thong",
                        "division_type": "xã",
                        "short_codename": "hung_thong"
                    },
                    {
                        "name": "Xã Hưng Tân",
                        "code": 18031,
                        "codename": "xa_hung_tan",
                        "division_type": "xã",
                        "short_codename": "hung_tan"
                    },
                    {
                        "name": "Xã Hưng Lợi",
                        "code": 18034,
                        "codename": "xa_hung_loi",
                        "division_type": "xã",
                        "short_codename": "hung_loi"
                    },
                    {
                        "name": "Xã Hưng Nghĩa",
                        "code": 18037,
                        "codename": "xa_hung_nghia",
                        "division_type": "xã",
                        "short_codename": "hung_nghia"
                    },
                    {
                        "name": "Xã Hưng Phúc",
                        "code": 18040,
                        "codename": "xa_hung_phuc",
                        "division_type": "xã",
                        "short_codename": "hung_phuc"
                    },
                    {
                        "name": "Xã Long Xá",
                        "code": 18043,
                        "codename": "xa_long_xa",
                        "division_type": "xã",
                        "short_codename": "long_xa"
                    },
                    {
                        "name": "Xã Châu Nhân",
                        "code": 18052,
                        "codename": "xa_chau_nhan",
                        "division_type": "xã",
                        "short_codename": "chau_nhan"
                    },
                    {
                        "name": "Xã Xuân Lam",
                        "code": 18055,
                        "codename": "xa_xuan_lam",
                        "division_type": "xã",
                        "short_codename": "xuan_lam"
                    },
                    {
                        "name": "Xã Hưng Thành",
                        "code": 18064,
                        "codename": "xa_hung_thanh",
                        "division_type": "xã",
                        "short_codename": "hung_thanh"
                    }
                ]
            },
            {
                "name": "Thị xã Hoàng Mai",
                "code": 432,
                "codename": "thi_xa_hoang_mai",
                "division_type": "thị xã",
                "short_codename": "hoang_mai",
                "wards": [
                    {
                        "name": "Xã Quỳnh Vinh",
                        "code": 17104,
                        "codename": "xa_quynh_vinh",
                        "division_type": "xã",
                        "short_codename": "quynh_vinh"
                    },
                    {
                        "name": "Xã Quỳnh Lộc",
                        "code": 17107,
                        "codename": "xa_quynh_loc",
                        "division_type": "xã",
                        "short_codename": "quynh_loc"
                    },
                    {
                        "name": "Phường Quỳnh Thiện",
                        "code": 17110,
                        "codename": "phuong_quynh_thien",
                        "division_type": "phường",
                        "short_codename": "quynh_thien"
                    },
                    {
                        "name": "Xã Quỳnh Lập",
                        "code": 17113,
                        "codename": "xa_quynh_lap",
                        "division_type": "xã",
                        "short_codename": "quynh_lap"
                    },
                    {
                        "name": "Xã Quỳnh Trang",
                        "code": 17116,
                        "codename": "xa_quynh_trang",
                        "division_type": "xã",
                        "short_codename": "quynh_trang"
                    },
                    {
                        "name": "Phường Mai Hùng",
                        "code": 17125,
                        "codename": "phuong_mai_hung",
                        "division_type": "phường",
                        "short_codename": "mai_hung"
                    },
                    {
                        "name": "Phường Quỳnh Dị",
                        "code": 17128,
                        "codename": "phuong_quynh_di",
                        "division_type": "phường",
                        "short_codename": "quynh_di"
                    },
                    {
                        "name": "Phường Quỳnh Xuân",
                        "code": 17131,
                        "codename": "phuong_quynh_xuan",
                        "division_type": "phường",
                        "short_codename": "quynh_xuan"
                    },
                    {
                        "name": "Phường Quỳnh Phương",
                        "code": 17134,
                        "codename": "phuong_quynh_phuong",
                        "division_type": "phường",
                        "short_codename": "quynh_phuong"
                    },
                    {
                        "name": "Xã Quỳnh Liên",
                        "code": 17137,
                        "codename": "xa_quynh_lien",
                        "division_type": "xã",
                        "short_codename": "quynh_lien"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Hà Tĩnh",
        "code": 42,
        "codename": "tinh_ha_tinh",
        "division_type": "tỉnh",
        "phone_code": 239,
        "districts": [
            {
                "name": "Thành phố Hà Tĩnh",
                "code": 436,
                "codename": "thanh_pho_ha_tinh",
                "division_type": "thành phố",
                "short_codename": "ha_tinh",
                "wards": [
                    {
                        "name": "Phường Trần Phú",
                        "code": 18070,
                        "codename": "phuong_tran_phu",
                        "division_type": "phường",
                        "short_codename": "tran_phu"
                    },
                    {
                        "name": "Phường Nam Hà",
                        "code": 18073,
                        "codename": "phuong_nam_ha",
                        "division_type": "phường",
                        "short_codename": "nam_ha"
                    },
                    {
                        "name": "Phường Bắc Hà",
                        "code": 18076,
                        "codename": "phuong_bac_ha",
                        "division_type": "phường",
                        "short_codename": "bac_ha"
                    },
                    {
                        "name": "Phường Nguyễn Du",
                        "code": 18077,
                        "codename": "phuong_nguyen_du",
                        "division_type": "phường",
                        "short_codename": "nguyen_du"
                    },
                    {
                        "name": "Phường Tân Giang",
                        "code": 18079,
                        "codename": "phuong_tan_giang",
                        "division_type": "phường",
                        "short_codename": "tan_giang"
                    },
                    {
                        "name": "Phường Đại Nài",
                        "code": 18082,
                        "codename": "phuong_dai_nai",
                        "division_type": "phường",
                        "short_codename": "dai_nai"
                    },
                    {
                        "name": "Phường Hà Huy Tập",
                        "code": 18085,
                        "codename": "phuong_ha_huy_tap",
                        "division_type": "phường",
                        "short_codename": "ha_huy_tap"
                    },
                    {
                        "name": "Xã Thạch Trung",
                        "code": 18088,
                        "codename": "xa_thach_trung",
                        "division_type": "xã",
                        "short_codename": "thach_trung"
                    },
                    {
                        "name": "Phường Thạch Quý",
                        "code": 18091,
                        "codename": "phuong_thach_quy",
                        "division_type": "phường",
                        "short_codename": "thach_quy"
                    },
                    {
                        "name": "Phường Thạch Linh",
                        "code": 18094,
                        "codename": "phuong_thach_linh",
                        "division_type": "phường",
                        "short_codename": "thach_linh"
                    },
                    {
                        "name": "Phường Văn Yên",
                        "code": 18097,
                        "codename": "phuong_van_yen",
                        "division_type": "phường",
                        "short_codename": "van_yen"
                    },
                    {
                        "name": "Xã Thạch Hạ",
                        "code": 18100,
                        "codename": "xa_thach_ha",
                        "division_type": "xã",
                        "short_codename": "thach_ha"
                    },
                    {
                        "name": "Xã Đồng Môn",
                        "code": 18103,
                        "codename": "xa_dong_mon",
                        "division_type": "xã",
                        "short_codename": "dong_mon"
                    },
                    {
                        "name": "Xã Thạch Hưng",
                        "code": 18109,
                        "codename": "xa_thach_hung",
                        "division_type": "xã",
                        "short_codename": "thach_hung"
                    },
                    {
                        "name": "Xã Thạch Bình",
                        "code": 18112,
                        "codename": "xa_thach_binh",
                        "division_type": "xã",
                        "short_codename": "thach_binh"
                    }
                ]
            },
            {
                "name": "Thị xã Hồng Lĩnh",
                "code": 437,
                "codename": "thi_xa_hong_linh",
                "division_type": "thị xã",
                "short_codename": "hong_linh",
                "wards": [
                    {
                        "name": "Phường Bắc Hồng",
                        "code": 18115,
                        "codename": "phuong_bac_hong",
                        "division_type": "phường",
                        "short_codename": "bac_hong"
                    },
                    {
                        "name": "Phường Nam Hồng",
                        "code": 18118,
                        "codename": "phuong_nam_hong",
                        "division_type": "phường",
                        "short_codename": "nam_hong"
                    },
                    {
                        "name": "Phường Trung Lương",
                        "code": 18121,
                        "codename": "phuong_trung_luong",
                        "division_type": "phường",
                        "short_codename": "trung_luong"
                    },
                    {
                        "name": "Phường Đức Thuận",
                        "code": 18124,
                        "codename": "phuong_duc_thuan",
                        "division_type": "phường",
                        "short_codename": "duc_thuan"
                    },
                    {
                        "name": "Phường Đậu Liêu",
                        "code": 18127,
                        "codename": "phuong_dau_lieu",
                        "division_type": "phường",
                        "short_codename": "dau_lieu"
                    },
                    {
                        "name": "Xã Thuận Lộc",
                        "code": 18130,
                        "codename": "xa_thuan_loc",
                        "division_type": "xã",
                        "short_codename": "thuan_loc"
                    }
                ]
            },
            {
                "name": "Huyện Hương Sơn",
                "code": 439,
                "codename": "huyen_huong_son",
                "division_type": "huyện",
                "short_codename": "huong_son",
                "wards": [
                    {
                        "name": "Thị trấn Phố Châu",
                        "code": 18133,
                        "codename": "thi_tran_pho_chau",
                        "division_type": "thị trấn",
                        "short_codename": "pho_chau"
                    },
                    {
                        "name": "Thị trấn Tây Sơn",
                        "code": 18136,
                        "codename": "thi_tran_tay_son",
                        "division_type": "thị trấn",
                        "short_codename": "tay_son"
                    },
                    {
                        "name": "Xã Sơn Hồng",
                        "code": 18139,
                        "codename": "xa_son_hong",
                        "division_type": "xã",
                        "short_codename": "son_hong"
                    },
                    {
                        "name": "Xã Sơn Tiến",
                        "code": 18142,
                        "codename": "xa_son_tien",
                        "division_type": "xã",
                        "short_codename": "son_tien"
                    },
                    {
                        "name": "Xã Sơn Lâm",
                        "code": 18145,
                        "codename": "xa_son_lam",
                        "division_type": "xã",
                        "short_codename": "son_lam"
                    },
                    {
                        "name": "Xã Sơn Lễ",
                        "code": 18148,
                        "codename": "xa_son_le",
                        "division_type": "xã",
                        "short_codename": "son_le"
                    },
                    {
                        "name": "Xã Sơn Giang",
                        "code": 18157,
                        "codename": "xa_son_giang",
                        "division_type": "xã",
                        "short_codename": "son_giang"
                    },
                    {
                        "name": "Xã Sơn Lĩnh",
                        "code": 18160,
                        "codename": "xa_son_linh",
                        "division_type": "xã",
                        "short_codename": "son_linh"
                    },
                    {
                        "name": "Xã An Hòa Thịnh",
                        "code": 18163,
                        "codename": "xa_an_hoa_thinh",
                        "division_type": "xã",
                        "short_codename": "an_hoa_thinh"
                    },
                    {
                        "name": "Xã Sơn Tây",
                        "code": 18172,
                        "codename": "xa_son_tay",
                        "division_type": "xã",
                        "short_codename": "son_tay"
                    },
                    {
                        "name": "Xã Sơn Ninh",
                        "code": 18175,
                        "codename": "xa_son_ninh",
                        "division_type": "xã",
                        "short_codename": "son_ninh"
                    },
                    {
                        "name": "Xã Sơn Châu",
                        "code": 18178,
                        "codename": "xa_son_chau",
                        "division_type": "xã",
                        "short_codename": "son_chau"
                    },
                    {
                        "name": "Xã Tân Mỹ Hà",
                        "code": 18181,
                        "codename": "xa_tan_my_ha",
                        "division_type": "xã",
                        "short_codename": "tan_my_ha"
                    },
                    {
                        "name": "Xã Quang Diệm",
                        "code": 18184,
                        "codename": "xa_quang_diem",
                        "division_type": "xã",
                        "short_codename": "quang_diem"
                    },
                    {
                        "name": "Xã Sơn Trung",
                        "code": 18187,
                        "codename": "xa_son_trung",
                        "division_type": "xã",
                        "short_codename": "son_trung"
                    },
                    {
                        "name": "Xã Sơn Bằng",
                        "code": 18190,
                        "codename": "xa_son_bang",
                        "division_type": "xã",
                        "short_codename": "son_bang"
                    },
                    {
                        "name": "Xã Sơn Bình",
                        "code": 18193,
                        "codename": "xa_son_binh",
                        "division_type": "xã",
                        "short_codename": "son_binh"
                    },
                    {
                        "name": "Xã Sơn Kim 1",
                        "code": 18196,
                        "codename": "xa_son_kim_1",
                        "division_type": "xã",
                        "short_codename": "son_kim_1"
                    },
                    {
                        "name": "Xã Sơn Kim 2",
                        "code": 18199,
                        "codename": "xa_son_kim_2",
                        "division_type": "xã",
                        "short_codename": "son_kim_2"
                    },
                    {
                        "name": "Xã Sơn Trà",
                        "code": 18202,
                        "codename": "xa_son_tra",
                        "division_type": "xã",
                        "short_codename": "son_tra"
                    },
                    {
                        "name": "Xã Sơn Long",
                        "code": 18205,
                        "codename": "xa_son_long",
                        "division_type": "xã",
                        "short_codename": "son_long"
                    },
                    {
                        "name": "Xã Kim Hoa",
                        "code": 18211,
                        "codename": "xa_kim_hoa",
                        "division_type": "xã",
                        "short_codename": "kim_hoa"
                    },
                    {
                        "name": "Xã Sơn Hàm",
                        "code": 18214,
                        "codename": "xa_son_ham",
                        "division_type": "xã",
                        "short_codename": "son_ham"
                    },
                    {
                        "name": "Xã Sơn Phú",
                        "code": 18217,
                        "codename": "xa_son_phu",
                        "division_type": "xã",
                        "short_codename": "son_phu"
                    },
                    {
                        "name": "Xã Sơn Trường",
                        "code": 18223,
                        "codename": "xa_son_truong",
                        "division_type": "xã",
                        "short_codename": "son_truong"
                    }
                ]
            },
            {
                "name": "Huyện Đức Thọ",
                "code": 440,
                "codename": "huyen_duc_tho",
                "division_type": "huyện",
                "short_codename": "duc_tho",
                "wards": [
                    {
                        "name": "Thị trấn Đức Thọ",
                        "code": 18229,
                        "codename": "thi_tran_duc_tho",
                        "division_type": "thị trấn",
                        "short_codename": "duc_tho"
                    },
                    {
                        "name": "Xã Quang Vĩnh",
                        "code": 18235,
                        "codename": "xa_quang_vinh",
                        "division_type": "xã",
                        "short_codename": "quang_vinh"
                    },
                    {
                        "name": "Xã Tùng Châu",
                        "code": 18241,
                        "codename": "xa_tung_chau",
                        "division_type": "xã",
                        "short_codename": "tung_chau"
                    },
                    {
                        "name": "Xã Trường Sơn",
                        "code": 18244,
                        "codename": "xa_truong_son",
                        "division_type": "xã",
                        "short_codename": "truong_son"
                    },
                    {
                        "name": "Xã Liên Minh",
                        "code": 18247,
                        "codename": "xa_lien_minh",
                        "division_type": "xã",
                        "short_codename": "lien_minh"
                    },
                    {
                        "name": "Xã Yên Hồ",
                        "code": 18253,
                        "codename": "xa_yen_ho",
                        "division_type": "xã",
                        "short_codename": "yen_ho"
                    },
                    {
                        "name": "Xã Tùng Ảnh",
                        "code": 18259,
                        "codename": "xa_tung_anh",
                        "division_type": "xã",
                        "short_codename": "tung_anh"
                    },
                    {
                        "name": "Xã Bùi La Nhân",
                        "code": 18262,
                        "codename": "xa_bui_la_nhan",
                        "division_type": "xã",
                        "short_codename": "bui_la_nhan"
                    },
                    {
                        "name": "Xã Thanh Bình Thịnh",
                        "code": 18274,
                        "codename": "xa_thanh_binh_thinh",
                        "division_type": "xã",
                        "short_codename": "thanh_binh_thinh"
                    },
                    {
                        "name": "Xã Lâm Trung Thủy",
                        "code": 18277,
                        "codename": "xa_lam_trung_thuy",
                        "division_type": "xã",
                        "short_codename": "lam_trung_thuy"
                    },
                    {
                        "name": "Xã Hòa Lạc",
                        "code": 18280,
                        "codename": "xa_hoa_lac",
                        "division_type": "xã",
                        "short_codename": "hoa_lac"
                    },
                    {
                        "name": "Xã Tân Dân",
                        "code": 18283,
                        "codename": "xa_tan_dan",
                        "division_type": "xã",
                        "short_codename": "tan_dan"
                    },
                    {
                        "name": "Xã An Dũng",
                        "code": 18298,
                        "codename": "xa_an_dung",
                        "division_type": "xã",
                        "short_codename": "an_dung"
                    },
                    {
                        "name": "Xã Đức Đồng",
                        "code": 18304,
                        "codename": "xa_duc_dong",
                        "division_type": "xã",
                        "short_codename": "duc_dong"
                    },
                    {
                        "name": "Xã Đức Lạng",
                        "code": 18307,
                        "codename": "xa_duc_lang",
                        "division_type": "xã",
                        "short_codename": "duc_lang"
                    },
                    {
                        "name": "Xã Tân Hương",
                        "code": 18310,
                        "codename": "xa_tan_huong",
                        "division_type": "xã",
                        "short_codename": "tan_huong"
                    }
                ]
            },
            {
                "name": "Huyện Vũ Quang",
                "code": 441,
                "codename": "huyen_vu_quang",
                "division_type": "huyện",
                "short_codename": "vu_quang",
                "wards": [
                    {
                        "name": "Thị trấn Vũ Quang",
                        "code": 18313,
                        "codename": "thi_tran_vu_quang",
                        "division_type": "thị trấn",
                        "short_codename": "vu_quang"
                    },
                    {
                        "name": "Xã Ân Phú",
                        "code": 18316,
                        "codename": "xa_an_phu",
                        "division_type": "xã",
                        "short_codename": "an_phu"
                    },
                    {
                        "name": "Xã Đức Giang",
                        "code": 18319,
                        "codename": "xa_duc_giang",
                        "division_type": "xã",
                        "short_codename": "duc_giang"
                    },
                    {
                        "name": "Xã Đức Lĩnh",
                        "code": 18322,
                        "codename": "xa_duc_linh",
                        "division_type": "xã",
                        "short_codename": "duc_linh"
                    },
                    {
                        "name": "Xã Thọ Điền",
                        "code": 18325,
                        "codename": "xa_tho_dien",
                        "division_type": "xã",
                        "short_codename": "tho_dien"
                    },
                    {
                        "name": "Xã Đức Hương",
                        "code": 18328,
                        "codename": "xa_duc_huong",
                        "division_type": "xã",
                        "short_codename": "duc_huong"
                    },
                    {
                        "name": "Xã Đức Bồng",
                        "code": 18331,
                        "codename": "xa_duc_bong",
                        "division_type": "xã",
                        "short_codename": "duc_bong"
                    },
                    {
                        "name": "Xã Đức Liên",
                        "code": 18334,
                        "codename": "xa_duc_lien",
                        "division_type": "xã",
                        "short_codename": "duc_lien"
                    },
                    {
                        "name": "Xã Hương Minh",
                        "code": 18340,
                        "codename": "xa_huong_minh",
                        "division_type": "xã",
                        "short_codename": "huong_minh"
                    },
                    {
                        "name": "Xã Quang Thọ",
                        "code": 18343,
                        "codename": "xa_quang_tho",
                        "division_type": "xã",
                        "short_codename": "quang_tho"
                    }
                ]
            },
            {
                "name": "Huyện Nghi Xuân",
                "code": 442,
                "codename": "huyen_nghi_xuan",
                "division_type": "huyện",
                "short_codename": "nghi_xuan",
                "wards": [
                    {
                        "name": "Thị trấn Xuân An",
                        "code": 18352,
                        "codename": "thi_tran_xuan_an",
                        "division_type": "thị trấn",
                        "short_codename": "xuan_an"
                    },
                    {
                        "name": "Xã Xuân Hội",
                        "code": 18355,
                        "codename": "xa_xuan_hoi",
                        "division_type": "xã",
                        "short_codename": "xuan_hoi"
                    },
                    {
                        "name": "Xã Đan Trường",
                        "code": 18358,
                        "codename": "xa_dan_truong",
                        "division_type": "xã",
                        "short_codename": "dan_truong"
                    },
                    {
                        "name": "Xã Xuân Phổ",
                        "code": 18364,
                        "codename": "xa_xuan_pho",
                        "division_type": "xã",
                        "short_codename": "xuan_pho"
                    },
                    {
                        "name": "Xã Xuân Hải",
                        "code": 18367,
                        "codename": "xa_xuan_hai",
                        "division_type": "xã",
                        "short_codename": "xuan_hai"
                    },
                    {
                        "name": "Xã Xuân Giang",
                        "code": 18370,
                        "codename": "xa_xuan_giang",
                        "division_type": "xã",
                        "short_codename": "xuan_giang"
                    },
                    {
                        "name": "Thị trấn Tiên Điền",
                        "code": 18373,
                        "codename": "thi_tran_tien_dien",
                        "division_type": "thị trấn",
                        "short_codename": "tien_dien"
                    },
                    {
                        "name": "Xã Xuân Yên",
                        "code": 18376,
                        "codename": "xa_xuan_yen",
                        "division_type": "xã",
                        "short_codename": "xuan_yen"
                    },
                    {
                        "name": "Xã Xuân Mỹ",
                        "code": 18379,
                        "codename": "xa_xuan_my",
                        "division_type": "xã",
                        "short_codename": "xuan_my"
                    },
                    {
                        "name": "Xã Xuân Thành",
                        "code": 18382,
                        "codename": "xa_xuan_thanh",
                        "division_type": "xã",
                        "short_codename": "xuan_thanh"
                    },
                    {
                        "name": "Xã Xuân Viên",
                        "code": 18385,
                        "codename": "xa_xuan_vien",
                        "division_type": "xã",
                        "short_codename": "xuan_vien"
                    },
                    {
                        "name": "Xã Xuân Hồng",
                        "code": 18388,
                        "codename": "xa_xuan_hong",
                        "division_type": "xã",
                        "short_codename": "xuan_hong"
                    },
                    {
                        "name": "Xã Cỗ Đạm",
                        "code": 18391,
                        "codename": "xa_co_dam",
                        "division_type": "xã",
                        "short_codename": "co_dam"
                    },
                    {
                        "name": "Xã Xuân Liên",
                        "code": 18394,
                        "codename": "xa_xuan_lien",
                        "division_type": "xã",
                        "short_codename": "xuan_lien"
                    },
                    {
                        "name": "Xã Xuân Lĩnh",
                        "code": 18397,
                        "codename": "xa_xuan_linh",
                        "division_type": "xã",
                        "short_codename": "xuan_linh"
                    },
                    {
                        "name": "Xã Xuân Lam",
                        "code": 18400,
                        "codename": "xa_xuan_lam",
                        "division_type": "xã",
                        "short_codename": "xuan_lam"
                    },
                    {
                        "name": "Xã Cương Gián",
                        "code": 18403,
                        "codename": "xa_cuong_gian",
                        "division_type": "xã",
                        "short_codename": "cuong_gian"
                    }
                ]
            },
            {
                "name": "Huyện Can Lộc",
                "code": 443,
                "codename": "huyen_can_loc",
                "division_type": "huyện",
                "short_codename": "can_loc",
                "wards": [
                    {
                        "name": "Thị trấn Nghèn",
                        "code": 18406,
                        "codename": "thi_tran_nghen",
                        "division_type": "thị trấn",
                        "short_codename": "nghen"
                    },
                    {
                        "name": "Xã Thiên Lộc",
                        "code": 18415,
                        "codename": "xa_thien_loc",
                        "division_type": "xã",
                        "short_codename": "thien_loc"
                    },
                    {
                        "name": "Xã Thuần Thiện",
                        "code": 18418,
                        "codename": "xa_thuan_thien",
                        "division_type": "xã",
                        "short_codename": "thuan_thien"
                    },
                    {
                        "name": "Xã Vượng Lộc",
                        "code": 18427,
                        "codename": "xa_vuong_loc",
                        "division_type": "xã",
                        "short_codename": "vuong_loc"
                    },
                    {
                        "name": "Xã Thanh Lộc",
                        "code": 18433,
                        "codename": "xa_thanh_loc",
                        "division_type": "xã",
                        "short_codename": "thanh_loc"
                    },
                    {
                        "name": "Xã Kim Song Trường",
                        "code": 18436,
                        "codename": "xa_kim_song_truong",
                        "division_type": "xã",
                        "short_codename": "kim_song_truong"
                    },
                    {
                        "name": "Xã Thường Nga",
                        "code": 18439,
                        "codename": "xa_thuong_nga",
                        "division_type": "xã",
                        "short_codename": "thuong_nga"
                    },
                    {
                        "name": "Xã Tùng Lộc",
                        "code": 18445,
                        "codename": "xa_tung_loc",
                        "division_type": "xã",
                        "short_codename": "tung_loc"
                    },
                    {
                        "name": "Xã Phú Lộc",
                        "code": 18454,
                        "codename": "xa_phu_loc",
                        "division_type": "xã",
                        "short_codename": "phu_loc"
                    },
                    {
                        "name": "Xã Gia Hanh",
                        "code": 18463,
                        "codename": "xa_gia_hanh",
                        "division_type": "xã",
                        "short_codename": "gia_hanh"
                    },
                    {
                        "name": "Xã Khánh Vĩnh Yên",
                        "code": 18466,
                        "codename": "xa_khanh_vinh_yen",
                        "division_type": "xã",
                        "short_codename": "khanh_vinh_yen"
                    },
                    {
                        "name": "Xã Trung Lộc",
                        "code": 18472,
                        "codename": "xa_trung_loc",
                        "division_type": "xã",
                        "short_codename": "trung_loc"
                    },
                    {
                        "name": "Xã Xuân Lộc",
                        "code": 18475,
                        "codename": "xa_xuan_loc",
                        "division_type": "xã",
                        "short_codename": "xuan_loc"
                    },
                    {
                        "name": "Xã Thượng Lộc",
                        "code": 18478,
                        "codename": "xa_thuong_loc",
                        "division_type": "xã",
                        "short_codename": "thuong_loc"
                    },
                    {
                        "name": "Xã Quang Lộc",
                        "code": 18481,
                        "codename": "xa_quang_loc",
                        "division_type": "xã",
                        "short_codename": "quang_loc"
                    },
                    {
                        "name": "Thị trấn Đồng Lộc",
                        "code": 18484,
                        "codename": "thi_tran_dong_loc",
                        "division_type": "thị trấn",
                        "short_codename": "dong_loc"
                    },
                    {
                        "name": "Xã Mỹ Lộc",
                        "code": 18487,
                        "codename": "xa_my_loc",
                        "division_type": "xã",
                        "short_codename": "my_loc"
                    },
                    {
                        "name": "Xã Sơn Lộc",
                        "code": 18490,
                        "codename": "xa_son_loc",
                        "division_type": "xã",
                        "short_codename": "son_loc"
                    }
                ]
            },
            {
                "name": "Huyện Hương Khê",
                "code": 444,
                "codename": "huyen_huong_khe",
                "division_type": "huyện",
                "short_codename": "huong_khe",
                "wards": [
                    {
                        "name": "Thị trấn Hương Khê",
                        "code": 18496,
                        "codename": "thi_tran_huong_khe",
                        "division_type": "thị trấn",
                        "short_codename": "huong_khe"
                    },
                    {
                        "name": "Xã Điền Mỹ",
                        "code": 18499,
                        "codename": "xa_dien_my",
                        "division_type": "xã",
                        "short_codename": "dien_my"
                    },
                    {
                        "name": "Xã Hà Linh",
                        "code": 18502,
                        "codename": "xa_ha_linh",
                        "division_type": "xã",
                        "short_codename": "ha_linh"
                    },
                    {
                        "name": "Xã Hương Thủy",
                        "code": 18505,
                        "codename": "xa_huong_thuy",
                        "division_type": "xã",
                        "short_codename": "huong_thuy"
                    },
                    {
                        "name": "Xã Hòa Hải",
                        "code": 18508,
                        "codename": "xa_hoa_hai",
                        "division_type": "xã",
                        "short_codename": "hoa_hai"
                    },
                    {
                        "name": "Xã Phúc Đồng",
                        "code": 18514,
                        "codename": "xa_phuc_dong",
                        "division_type": "xã",
                        "short_codename": "phuc_dong"
                    },
                    {
                        "name": "Xã Hương Giang",
                        "code": 18517,
                        "codename": "xa_huong_giang",
                        "division_type": "xã",
                        "short_codename": "huong_giang"
                    },
                    {
                        "name": "Xã Lộc Yên",
                        "code": 18520,
                        "codename": "xa_loc_yen",
                        "division_type": "xã",
                        "short_codename": "loc_yen"
                    },
                    {
                        "name": "Xã Hương Bình",
                        "code": 18523,
                        "codename": "xa_huong_binh",
                        "division_type": "xã",
                        "short_codename": "huong_binh"
                    },
                    {
                        "name": "Xã Hương Long",
                        "code": 18526,
                        "codename": "xa_huong_long",
                        "division_type": "xã",
                        "short_codename": "huong_long"
                    },
                    {
                        "name": "Xã Phú Gia",
                        "code": 18529,
                        "codename": "xa_phu_gia",
                        "division_type": "xã",
                        "short_codename": "phu_gia"
                    },
                    {
                        "name": "Xã Gia Phố",
                        "code": 18532,
                        "codename": "xa_gia_pho",
                        "division_type": "xã",
                        "short_codename": "gia_pho"
                    },
                    {
                        "name": "Xã Phú Phong",
                        "code": 18535,
                        "codename": "xa_phu_phong",
                        "division_type": "xã",
                        "short_codename": "phu_phong"
                    },
                    {
                        "name": "Xã Hương Đô",
                        "code": 18538,
                        "codename": "xa_huong_do",
                        "division_type": "xã",
                        "short_codename": "huong_do"
                    },
                    {
                        "name": "Xã Hương Vĩnh",
                        "code": 18541,
                        "codename": "xa_huong_vinh",
                        "division_type": "xã",
                        "short_codename": "huong_vinh"
                    },
                    {
                        "name": "Xã Hương Xuân",
                        "code": 18544,
                        "codename": "xa_huong_xuan",
                        "division_type": "xã",
                        "short_codename": "huong_xuan"
                    },
                    {
                        "name": "Xã Phúc Trạch",
                        "code": 18547,
                        "codename": "xa_phuc_trach",
                        "division_type": "xã",
                        "short_codename": "phuc_trach"
                    },
                    {
                        "name": "Xã Hương Trà",
                        "code": 18550,
                        "codename": "xa_huong_tra",
                        "division_type": "xã",
                        "short_codename": "huong_tra"
                    },
                    {
                        "name": "Xã Hương Trạch",
                        "code": 18553,
                        "codename": "xa_huong_trach",
                        "division_type": "xã",
                        "short_codename": "huong_trach"
                    },
                    {
                        "name": "Xã Hương Lâm",
                        "code": 18556,
                        "codename": "xa_huong_lam",
                        "division_type": "xã",
                        "short_codename": "huong_lam"
                    },
                    {
                        "name": "Xã Hương Liên",
                        "code": 18559,
                        "codename": "xa_huong_lien",
                        "division_type": "xã",
                        "short_codename": "huong_lien"
                    }
                ]
            },
            {
                "name": "Huyện Thạch Hà",
                "code": 445,
                "codename": "huyen_thach_ha",
                "division_type": "huyện",
                "short_codename": "thach_ha",
                "wards": [
                    {
                        "name": "Thị trấn Thạch Hà",
                        "code": 18562,
                        "codename": "thi_tran_thach_ha",
                        "division_type": "thị trấn",
                        "short_codename": "thach_ha"
                    },
                    {
                        "name": "Xã Ngọc Sơn",
                        "code": 18565,
                        "codename": "xa_ngoc_son",
                        "division_type": "xã",
                        "short_codename": "ngoc_son"
                    },
                    {
                        "name": "Xã Thạch Hải",
                        "code": 18571,
                        "codename": "xa_thach_hai",
                        "division_type": "xã",
                        "short_codename": "thach_hai"
                    },
                    {
                        "name": "Xã Thạch Kênh",
                        "code": 18586,
                        "codename": "xa_thach_kenh",
                        "division_type": "xã",
                        "short_codename": "thach_kenh"
                    },
                    {
                        "name": "Xã Thạch Sơn",
                        "code": 18589,
                        "codename": "xa_thach_son",
                        "division_type": "xã",
                        "short_codename": "thach_son"
                    },
                    {
                        "name": "Xã Thạch Liên",
                        "code": 18592,
                        "codename": "xa_thach_lien",
                        "division_type": "xã",
                        "short_codename": "thach_lien"
                    },
                    {
                        "name": "Xã Đỉnh Bàn",
                        "code": 18595,
                        "codename": "xa_dinh_ban",
                        "division_type": "xã",
                        "short_codename": "dinh_ban"
                    },
                    {
                        "name": "Xã Việt Tiến",
                        "code": 18601,
                        "codename": "xa_viet_tien",
                        "division_type": "xã",
                        "short_codename": "viet_tien"
                    },
                    {
                        "name": "Xã Thạch Khê",
                        "code": 18604,
                        "codename": "xa_thach_khe",
                        "division_type": "xã",
                        "short_codename": "thach_khe"
                    },
                    {
                        "name": "Xã Thạch Long",
                        "code": 18607,
                        "codename": "xa_thach_long",
                        "division_type": "xã",
                        "short_codename": "thach_long"
                    },
                    {
                        "name": "Xã Thạch Trị",
                        "code": 18619,
                        "codename": "xa_thach_tri",
                        "division_type": "xã",
                        "short_codename": "thach_tri"
                    },
                    {
                        "name": "Xã Thạch Lạc",
                        "code": 18622,
                        "codename": "xa_thach_lac",
                        "division_type": "xã",
                        "short_codename": "thach_lac"
                    },
                    {
                        "name": "Xã Thạch Ngọc",
                        "code": 18625,
                        "codename": "xa_thach_ngoc",
                        "division_type": "xã",
                        "short_codename": "thach_ngoc"
                    },
                    {
                        "name": "Xã Tượng Sơn",
                        "code": 18628,
                        "codename": "xa_tuong_son",
                        "division_type": "xã",
                        "short_codename": "tuong_son"
                    },
                    {
                        "name": "Xã Thạch Văn",
                        "code": 18631,
                        "codename": "xa_thach_van",
                        "division_type": "xã",
                        "short_codename": "thach_van"
                    },
                    {
                        "name": "Xã Lưu Vĩnh Sơn",
                        "code": 18634,
                        "codename": "xa_luu_vinh_son",
                        "division_type": "xã",
                        "short_codename": "luu_vinh_son"
                    },
                    {
                        "name": "Xã Thạch Thắng",
                        "code": 18637,
                        "codename": "xa_thach_thang",
                        "division_type": "xã",
                        "short_codename": "thach_thang"
                    },
                    {
                        "name": "Xã Thạch Đài",
                        "code": 18643,
                        "codename": "xa_thach_dai",
                        "division_type": "xã",
                        "short_codename": "thach_dai"
                    },
                    {
                        "name": "Xã Thạch Hội",
                        "code": 18649,
                        "codename": "xa_thach_hoi",
                        "division_type": "xã",
                        "short_codename": "thach_hoi"
                    },
                    {
                        "name": "Xã Tân Lâm Hương",
                        "code": 18652,
                        "codename": "xa_tan_lam_huong",
                        "division_type": "xã",
                        "short_codename": "tan_lam_huong"
                    },
                    {
                        "name": "Xã Thạch Xuân",
                        "code": 18658,
                        "codename": "xa_thach_xuan",
                        "division_type": "xã",
                        "short_codename": "thach_xuan"
                    },
                    {
                        "name": "Xã Nam Điền",
                        "code": 18667,
                        "codename": "xa_nam_dien",
                        "division_type": "xã",
                        "short_codename": "nam_dien"
                    }
                ]
            },
            {
                "name": "Huyện Cẩm Xuyên",
                "code": 446,
                "codename": "huyen_cam_xuyen",
                "division_type": "huyện",
                "short_codename": "cam_xuyen",
                "wards": [
                    {
                        "name": "Thị trấn Cẩm Xuyên",
                        "code": 18673,
                        "codename": "thi_tran_cam_xuyen",
                        "division_type": "thị trấn",
                        "short_codename": "cam_xuyen"
                    },
                    {
                        "name": "Thị trấn Thiên Cầm",
                        "code": 18676,
                        "codename": "thi_tran_thien_cam",
                        "division_type": "thị trấn",
                        "short_codename": "thien_cam"
                    },
                    {
                        "name": "Xã Yên Hòa",
                        "code": 18679,
                        "codename": "xa_yen_hoa",
                        "division_type": "xã",
                        "short_codename": "yen_hoa"
                    },
                    {
                        "name": "Xã Cẩm Dương",
                        "code": 18682,
                        "codename": "xa_cam_duong",
                        "division_type": "xã",
                        "short_codename": "cam_duong"
                    },
                    {
                        "name": "Xã Cẩm Bình",
                        "code": 18685,
                        "codename": "xa_cam_binh",
                        "division_type": "xã",
                        "short_codename": "cam_binh"
                    },
                    {
                        "name": "Xã Cẩm Vĩnh",
                        "code": 18691,
                        "codename": "xa_cam_vinh",
                        "division_type": "xã",
                        "short_codename": "cam_vinh"
                    },
                    {
                        "name": "Xã Cẩm Thành",
                        "code": 18694,
                        "codename": "xa_cam_thanh",
                        "division_type": "xã",
                        "short_codename": "cam_thanh"
                    },
                    {
                        "name": "Xã Cẩm Quang",
                        "code": 18697,
                        "codename": "xa_cam_quang",
                        "division_type": "xã",
                        "short_codename": "cam_quang"
                    },
                    {
                        "name": "Xã Cẩm Thạch",
                        "code": 18706,
                        "codename": "xa_cam_thach",
                        "division_type": "xã",
                        "short_codename": "cam_thach"
                    },
                    {
                        "name": "Xã Cẩm Nhượng",
                        "code": 18709,
                        "codename": "xa_cam_nhuong",
                        "division_type": "xã",
                        "short_codename": "cam_nhuong"
                    },
                    {
                        "name": "Xã Nam Phúc Thăng",
                        "code": 18712,
                        "codename": "xa_nam_phuc_thang",
                        "division_type": "xã",
                        "short_codename": "nam_phuc_thang"
                    },
                    {
                        "name": "Xã Cẩm Duệ",
                        "code": 18715,
                        "codename": "xa_cam_due",
                        "division_type": "xã",
                        "short_codename": "cam_due"
                    },
                    {
                        "name": "Xã Cẩm Lĩnh",
                        "code": 18721,
                        "codename": "xa_cam_linh",
                        "division_type": "xã",
                        "short_codename": "cam_linh"
                    },
                    {
                        "name": "Xã Cẩm Quan",
                        "code": 18724,
                        "codename": "xa_cam_quan",
                        "division_type": "xã",
                        "short_codename": "cam_quan"
                    },
                    {
                        "name": "Xã Cẩm Hà",
                        "code": 18727,
                        "codename": "xa_cam_ha",
                        "division_type": "xã",
                        "short_codename": "cam_ha"
                    },
                    {
                        "name": "Xã Cẩm Lộc",
                        "code": 18730,
                        "codename": "xa_cam_loc",
                        "division_type": "xã",
                        "short_codename": "cam_loc"
                    },
                    {
                        "name": "Xã Cẩm Hưng",
                        "code": 18733,
                        "codename": "xa_cam_hung",
                        "division_type": "xã",
                        "short_codename": "cam_hung"
                    },
                    {
                        "name": "Xã Cẩm Thịnh",
                        "code": 18736,
                        "codename": "xa_cam_thinh",
                        "division_type": "xã",
                        "short_codename": "cam_thinh"
                    },
                    {
                        "name": "Xã Cẩm Mỹ",
                        "code": 18739,
                        "codename": "xa_cam_my",
                        "division_type": "xã",
                        "short_codename": "cam_my"
                    },
                    {
                        "name": "Xã Cẩm Trung",
                        "code": 18742,
                        "codename": "xa_cam_trung",
                        "division_type": "xã",
                        "short_codename": "cam_trung"
                    },
                    {
                        "name": "Xã Cẩm Sơn",
                        "code": 18745,
                        "codename": "xa_cam_son",
                        "division_type": "xã",
                        "short_codename": "cam_son"
                    },
                    {
                        "name": "Xã Cẩm Lạc",
                        "code": 18748,
                        "codename": "xa_cam_lac",
                        "division_type": "xã",
                        "short_codename": "cam_lac"
                    },
                    {
                        "name": "Xã Cẩm Minh",
                        "code": 18751,
                        "codename": "xa_cam_minh",
                        "division_type": "xã",
                        "short_codename": "cam_minh"
                    }
                ]
            },
            {
                "name": "Huyện Kỳ Anh",
                "code": 447,
                "codename": "huyen_ky_anh",
                "division_type": "huyện",
                "short_codename": "huyen_ky_anh",
                "wards": [
                    {
                        "name": "Xã Kỳ Xuân",
                        "code": 18757,
                        "codename": "xa_ky_xuan",
                        "division_type": "xã",
                        "short_codename": "ky_xuan"
                    },
                    {
                        "name": "Xã Kỳ Bắc",
                        "code": 18760,
                        "codename": "xa_ky_bac",
                        "division_type": "xã",
                        "short_codename": "ky_bac"
                    },
                    {
                        "name": "Xã Kỳ Phú",
                        "code": 18763,
                        "codename": "xa_ky_phu",
                        "division_type": "xã",
                        "short_codename": "ky_phu"
                    },
                    {
                        "name": "Xã Kỳ Phong",
                        "code": 18766,
                        "codename": "xa_ky_phong",
                        "division_type": "xã",
                        "short_codename": "ky_phong"
                    },
                    {
                        "name": "Xã Kỳ Tiến",
                        "code": 18769,
                        "codename": "xa_ky_tien",
                        "division_type": "xã",
                        "short_codename": "ky_tien"
                    },
                    {
                        "name": "Xã Kỳ Giang",
                        "code": 18772,
                        "codename": "xa_ky_giang",
                        "division_type": "xã",
                        "short_codename": "ky_giang"
                    },
                    {
                        "name": "Xã Kỳ Đồng",
                        "code": 18775,
                        "codename": "xa_ky_dong",
                        "division_type": "xã",
                        "short_codename": "ky_dong"
                    },
                    {
                        "name": "Xã Kỳ Khang",
                        "code": 18778,
                        "codename": "xa_ky_khang",
                        "division_type": "xã",
                        "short_codename": "ky_khang"
                    },
                    {
                        "name": "Xã Kỳ Văn",
                        "code": 18784,
                        "codename": "xa_ky_van",
                        "division_type": "xã",
                        "short_codename": "ky_van"
                    },
                    {
                        "name": "Xã Kỳ Trung",
                        "code": 18787,
                        "codename": "xa_ky_trung",
                        "division_type": "xã",
                        "short_codename": "ky_trung"
                    },
                    {
                        "name": "Xã Kỳ Thọ",
                        "code": 18790,
                        "codename": "xa_ky_tho",
                        "division_type": "xã",
                        "short_codename": "ky_tho"
                    },
                    {
                        "name": "Xã Kỳ Tây",
                        "code": 18793,
                        "codename": "xa_ky_tay",
                        "division_type": "xã",
                        "short_codename": "ky_tay"
                    },
                    {
                        "name": "Xã Kỳ Thượng",
                        "code": 18799,
                        "codename": "xa_ky_thuong",
                        "division_type": "xã",
                        "short_codename": "ky_thuong"
                    },
                    {
                        "name": "Xã Kỳ Hải",
                        "code": 18802,
                        "codename": "xa_ky_hai",
                        "division_type": "xã",
                        "short_codename": "ky_hai"
                    },
                    {
                        "name": "Xã Kỳ Thư",
                        "code": 18805,
                        "codename": "xa_ky_thu",
                        "division_type": "xã",
                        "short_codename": "ky_thu"
                    },
                    {
                        "name": "Xã Kỳ Châu",
                        "code": 18811,
                        "codename": "xa_ky_chau",
                        "division_type": "xã",
                        "short_codename": "ky_chau"
                    },
                    {
                        "name": "Xã Kỳ Tân",
                        "code": 18814,
                        "codename": "xa_ky_tan",
                        "division_type": "xã",
                        "short_codename": "ky_tan"
                    },
                    {
                        "name": "Xã Lâm Hợp",
                        "code": 18838,
                        "codename": "xa_lam_hop",
                        "division_type": "xã",
                        "short_codename": "lam_hop"
                    },
                    {
                        "name": "Xã Kỳ Sơn",
                        "code": 18844,
                        "codename": "xa_ky_son",
                        "division_type": "xã",
                        "short_codename": "ky_son"
                    },
                    {
                        "name": "Xã Kỳ Lạc",
                        "code": 18850,
                        "codename": "xa_ky_lac",
                        "division_type": "xã",
                        "short_codename": "ky_lac"
                    }
                ]
            },
            {
                "name": "Huyện Lộc Hà",
                "code": 448,
                "codename": "huyen_loc_ha",
                "division_type": "huyện",
                "short_codename": "loc_ha",
                "wards": [
                    {
                        "name": "Xã Tân Lộc",
                        "code": 18409,
                        "codename": "xa_tan_loc",
                        "division_type": "xã",
                        "short_codename": "tan_loc"
                    },
                    {
                        "name": "Xã Hồng Lộc",
                        "code": 18412,
                        "codename": "xa_hong_loc",
                        "division_type": "xã",
                        "short_codename": "hong_loc"
                    },
                    {
                        "name": "Xã Thịnh Lộc",
                        "code": 18421,
                        "codename": "xa_thinh_loc",
                        "division_type": "xã",
                        "short_codename": "thinh_loc"
                    },
                    {
                        "name": "Xã Bình An",
                        "code": 18430,
                        "codename": "xa_binh_an",
                        "division_type": "xã",
                        "short_codename": "binh_an"
                    },
                    {
                        "name": "Xã Ích Hậu",
                        "code": 18457,
                        "codename": "xa_ich_hau",
                        "division_type": "xã",
                        "short_codename": "ich_hau"
                    },
                    {
                        "name": "Xã Phù Lưu",
                        "code": 18493,
                        "codename": "xa_phu_luu",
                        "division_type": "xã",
                        "short_codename": "phu_luu"
                    },
                    {
                        "name": "Thị trấn Lộc Hà",
                        "code": 18568,
                        "codename": "thi_tran_loc_ha",
                        "division_type": "thị trấn",
                        "short_codename": "loc_ha"
                    },
                    {
                        "name": "Xã Thạch Mỹ",
                        "code": 18577,
                        "codename": "xa_thach_my",
                        "division_type": "xã",
                        "short_codename": "thach_my"
                    },
                    {
                        "name": "Xã Thạch Kim",
                        "code": 18580,
                        "codename": "xa_thach_kim",
                        "division_type": "xã",
                        "short_codename": "thach_kim"
                    },
                    {
                        "name": "Xã Thạch Châu",
                        "code": 18583,
                        "codename": "xa_thach_chau",
                        "division_type": "xã",
                        "short_codename": "thach_chau"
                    },
                    {
                        "name": "Xã Hộ Độ",
                        "code": 18598,
                        "codename": "xa_ho_do",
                        "division_type": "xã",
                        "short_codename": "ho_do"
                    },
                    {
                        "name": "Xã Mai Phụ",
                        "code": 18670,
                        "codename": "xa_mai_phu",
                        "division_type": "xã",
                        "short_codename": "mai_phu"
                    }
                ]
            },
            {
                "name": "Thị xã Kỳ Anh",
                "code": 449,
                "codename": "thi_xa_ky_anh",
                "division_type": "thị xã",
                "short_codename": "ky_anh",
                "wards": [
                    {
                        "name": "Phường Hưng Trí",
                        "code": 18754,
                        "codename": "phuong_hung_tri",
                        "division_type": "phường",
                        "short_codename": "hung_tri"
                    },
                    {
                        "name": "Xã Kỳ Ninh",
                        "code": 18781,
                        "codename": "xa_ky_ninh",
                        "division_type": "xã",
                        "short_codename": "ky_ninh"
                    },
                    {
                        "name": "Xã Kỳ Lợi",
                        "code": 18796,
                        "codename": "xa_ky_loi",
                        "division_type": "xã",
                        "short_codename": "ky_loi"
                    },
                    {
                        "name": "Xã Kỳ Hà",
                        "code": 18808,
                        "codename": "xa_ky_ha",
                        "division_type": "xã",
                        "short_codename": "ky_ha"
                    },
                    {
                        "name": "Phường Kỳ Trinh",
                        "code": 18820,
                        "codename": "phuong_ky_trinh",
                        "division_type": "phường",
                        "short_codename": "ky_trinh"
                    },
                    {
                        "name": "Phường Kỳ Thịnh",
                        "code": 18823,
                        "codename": "phuong_ky_thinh",
                        "division_type": "phường",
                        "short_codename": "ky_thinh"
                    },
                    {
                        "name": "Xã Kỳ Hoa",
                        "code": 18829,
                        "codename": "xa_ky_hoa",
                        "division_type": "xã",
                        "short_codename": "ky_hoa"
                    },
                    {
                        "name": "Phường Kỳ Phương",
                        "code": 18832,
                        "codename": "phuong_ky_phuong",
                        "division_type": "phường",
                        "short_codename": "ky_phuong"
                    },
                    {
                        "name": "Phường Kỳ Long",
                        "code": 18835,
                        "codename": "phuong_ky_long",
                        "division_type": "phường",
                        "short_codename": "ky_long"
                    },
                    {
                        "name": "Phường Kỳ Liên",
                        "code": 18841,
                        "codename": "phuong_ky_lien",
                        "division_type": "phường",
                        "short_codename": "ky_lien"
                    },
                    {
                        "name": "Xã Kỳ Nam",
                        "code": 18847,
                        "codename": "xa_ky_nam",
                        "division_type": "xã",
                        "short_codename": "ky_nam"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Quảng Bình",
        "code": 44,
        "codename": "tinh_quang_binh",
        "division_type": "tỉnh",
        "phone_code": 232,
        "districts": [
            {
                "name": "Thành Phố Đồng Hới",
                "code": 450,
                "codename": "thanh_pho_dong_hoi",
                "division_type": "thành phố",
                "short_codename": "dong_hoi",
                "wards": [
                    {
                        "name": "Phường Hải Thành",
                        "code": 18853,
                        "codename": "phuong_hai_thanh",
                        "division_type": "phường",
                        "short_codename": "hai_thanh"
                    },
                    {
                        "name": "Phường Đồng Phú",
                        "code": 18856,
                        "codename": "phuong_dong_phu",
                        "division_type": "phường",
                        "short_codename": "dong_phu"
                    },
                    {
                        "name": "Phường Bắc Lý",
                        "code": 18859,
                        "codename": "phuong_bac_ly",
                        "division_type": "phường",
                        "short_codename": "bac_ly"
                    },
                    {
                        "name": "Phường Nam Lý",
                        "code": 18865,
                        "codename": "phuong_nam_ly",
                        "division_type": "phường",
                        "short_codename": "nam_ly"
                    },
                    {
                        "name": "Phường Đồng Hải",
                        "code": 18868,
                        "codename": "phuong_dong_hai",
                        "division_type": "phường",
                        "short_codename": "dong_hai"
                    },
                    {
                        "name": "Phường Đồng Sơn",
                        "code": 18871,
                        "codename": "phuong_dong_son",
                        "division_type": "phường",
                        "short_codename": "dong_son"
                    },
                    {
                        "name": "Phường Phú Hải",
                        "code": 18874,
                        "codename": "phuong_phu_hai",
                        "division_type": "phường",
                        "short_codename": "phu_hai"
                    },
                    {
                        "name": "Phường Bắc Nghĩa",
                        "code": 18877,
                        "codename": "phuong_bac_nghia",
                        "division_type": "phường",
                        "short_codename": "bac_nghia"
                    },
                    {
                        "name": "Phường Đức Ninh Đông",
                        "code": 18880,
                        "codename": "phuong_duc_ninh_dong",
                        "division_type": "phường",
                        "short_codename": "duc_ninh_dong"
                    },
                    {
                        "name": "Xã Quang Phú",
                        "code": 18883,
                        "codename": "xa_quang_phu",
                        "division_type": "xã",
                        "short_codename": "quang_phu"
                    },
                    {
                        "name": "Xã Lộc Ninh",
                        "code": 18886,
                        "codename": "xa_loc_ninh",
                        "division_type": "xã",
                        "short_codename": "loc_ninh"
                    },
                    {
                        "name": "Xã Bảo Ninh",
                        "code": 18889,
                        "codename": "xa_bao_ninh",
                        "division_type": "xã",
                        "short_codename": "bao_ninh"
                    },
                    {
                        "name": "Xã Nghĩa Ninh",
                        "code": 18892,
                        "codename": "xa_nghia_ninh",
                        "division_type": "xã",
                        "short_codename": "nghia_ninh"
                    },
                    {
                        "name": "Xã Thuận Đức",
                        "code": 18895,
                        "codename": "xa_thuan_duc",
                        "division_type": "xã",
                        "short_codename": "thuan_duc"
                    },
                    {
                        "name": "Xã Đức Ninh",
                        "code": 18898,
                        "codename": "xa_duc_ninh",
                        "division_type": "xã",
                        "short_codename": "duc_ninh"
                    }
                ]
            },
            {
                "name": "Huyện Minh Hóa",
                "code": 452,
                "codename": "huyen_minh_hoa",
                "division_type": "huyện",
                "short_codename": "minh_hoa",
                "wards": [
                    {
                        "name": "Thị trấn Quy Đạt",
                        "code": 18901,
                        "codename": "thi_tran_quy_dat",
                        "division_type": "thị trấn",
                        "short_codename": "quy_dat"
                    },
                    {
                        "name": "Xã Dân Hóa",
                        "code": 18904,
                        "codename": "xa_dan_hoa",
                        "division_type": "xã",
                        "short_codename": "dan_hoa"
                    },
                    {
                        "name": "Xã Trọng Hóa",
                        "code": 18907,
                        "codename": "xa_trong_hoa",
                        "division_type": "xã",
                        "short_codename": "trong_hoa"
                    },
                    {
                        "name": "Xã Hóa Phúc",
                        "code": 18910,
                        "codename": "xa_hoa_phuc",
                        "division_type": "xã",
                        "short_codename": "hoa_phuc"
                    },
                    {
                        "name": "Xã Hồng Hóa",
                        "code": 18913,
                        "codename": "xa_hong_hoa",
                        "division_type": "xã",
                        "short_codename": "hong_hoa"
                    },
                    {
                        "name": "Xã Hóa Thanh",
                        "code": 18916,
                        "codename": "xa_hoa_thanh",
                        "division_type": "xã",
                        "short_codename": "hoa_thanh"
                    },
                    {
                        "name": "Xã Hóa Tiến",
                        "code": 18919,
                        "codename": "xa_hoa_tien",
                        "division_type": "xã",
                        "short_codename": "hoa_tien"
                    },
                    {
                        "name": "Xã Hóa Hợp",
                        "code": 18922,
                        "codename": "xa_hoa_hop",
                        "division_type": "xã",
                        "short_codename": "hoa_hop"
                    },
                    {
                        "name": "Xã Xuân Hóa",
                        "code": 18925,
                        "codename": "xa_xuan_hoa",
                        "division_type": "xã",
                        "short_codename": "xuan_hoa"
                    },
                    {
                        "name": "Xã Yên Hóa",
                        "code": 18928,
                        "codename": "xa_yen_hoa",
                        "division_type": "xã",
                        "short_codename": "yen_hoa"
                    },
                    {
                        "name": "Xã Minh Hóa",
                        "code": 18931,
                        "codename": "xa_minh_hoa",
                        "division_type": "xã",
                        "short_codename": "minh_hoa"
                    },
                    {
                        "name": "Xã Tân Hóa",
                        "code": 18934,
                        "codename": "xa_tan_hoa",
                        "division_type": "xã",
                        "short_codename": "tan_hoa"
                    },
                    {
                        "name": "Xã Hóa Sơn",
                        "code": 18937,
                        "codename": "xa_hoa_son",
                        "division_type": "xã",
                        "short_codename": "hoa_son"
                    },
                    {
                        "name": "Xã Trung Hóa",
                        "code": 18943,
                        "codename": "xa_trung_hoa",
                        "division_type": "xã",
                        "short_codename": "trung_hoa"
                    },
                    {
                        "name": "Xã Thượng Hóa",
                        "code": 18946,
                        "codename": "xa_thuong_hoa",
                        "division_type": "xã",
                        "short_codename": "thuong_hoa"
                    }
                ]
            },
            {
                "name": "Huyện Tuyên Hóa",
                "code": 453,
                "codename": "huyen_tuyen_hoa",
                "division_type": "huyện",
                "short_codename": "tuyen_hoa",
                "wards": [
                    {
                        "name": "Thị trấn Đồng Lê",
                        "code": 18949,
                        "codename": "thi_tran_dong_le",
                        "division_type": "thị trấn",
                        "short_codename": "dong_le"
                    },
                    {
                        "name": "Xã Hương Hóa",
                        "code": 18952,
                        "codename": "xa_huong_hoa",
                        "division_type": "xã",
                        "short_codename": "huong_hoa"
                    },
                    {
                        "name": "Xã Kim Hóa",
                        "code": 18955,
                        "codename": "xa_kim_hoa",
                        "division_type": "xã",
                        "short_codename": "kim_hoa"
                    },
                    {
                        "name": "Xã Thanh Hóa",
                        "code": 18958,
                        "codename": "xa_thanh_hoa",
                        "division_type": "xã",
                        "short_codename": "thanh_hoa"
                    },
                    {
                        "name": "Xã Thanh Thạch",
                        "code": 18961,
                        "codename": "xa_thanh_thach",
                        "division_type": "xã",
                        "short_codename": "thanh_thach"
                    },
                    {
                        "name": "Xã Thuận Hóa",
                        "code": 18964,
                        "codename": "xa_thuan_hoa",
                        "division_type": "xã",
                        "short_codename": "thuan_hoa"
                    },
                    {
                        "name": "Xã Lâm Hóa",
                        "code": 18967,
                        "codename": "xa_lam_hoa",
                        "division_type": "xã",
                        "short_codename": "lam_hoa"
                    },
                    {
                        "name": "Xã Lê Hóa",
                        "code": 18970,
                        "codename": "xa_le_hoa",
                        "division_type": "xã",
                        "short_codename": "le_hoa"
                    },
                    {
                        "name": "Xã Sơn Hóa",
                        "code": 18973,
                        "codename": "xa_son_hoa",
                        "division_type": "xã",
                        "short_codename": "son_hoa"
                    },
                    {
                        "name": "Xã Đồng Hóa",
                        "code": 18976,
                        "codename": "xa_dong_hoa",
                        "division_type": "xã",
                        "short_codename": "dong_hoa"
                    },
                    {
                        "name": "Xã Ngư Hóa",
                        "code": 18979,
                        "codename": "xa_ngu_hoa",
                        "division_type": "xã",
                        "short_codename": "ngu_hoa"
                    },
                    {
                        "name": "Xã Thạch Hóa",
                        "code": 18985,
                        "codename": "xa_thach_hoa",
                        "division_type": "xã",
                        "short_codename": "thach_hoa"
                    },
                    {
                        "name": "Xã Đức Hóa",
                        "code": 18988,
                        "codename": "xa_duc_hoa",
                        "division_type": "xã",
                        "short_codename": "duc_hoa"
                    },
                    {
                        "name": "Xã Phong Hóa",
                        "code": 18991,
                        "codename": "xa_phong_hoa",
                        "division_type": "xã",
                        "short_codename": "phong_hoa"
                    },
                    {
                        "name": "Xã Mai Hóa",
                        "code": 18994,
                        "codename": "xa_mai_hoa",
                        "division_type": "xã",
                        "short_codename": "mai_hoa"
                    },
                    {
                        "name": "Xã Tiến Hóa",
                        "code": 18997,
                        "codename": "xa_tien_hoa",
                        "division_type": "xã",
                        "short_codename": "tien_hoa"
                    },
                    {
                        "name": "Xã Châu Hóa",
                        "code": 19000,
                        "codename": "xa_chau_hoa",
                        "division_type": "xã",
                        "short_codename": "chau_hoa"
                    },
                    {
                        "name": "Xã Cao Quảng",
                        "code": 19003,
                        "codename": "xa_cao_quang",
                        "division_type": "xã",
                        "short_codename": "cao_quang"
                    },
                    {
                        "name": "Xã Văn Hóa",
                        "code": 19006,
                        "codename": "xa_van_hoa",
                        "division_type": "xã",
                        "short_codename": "van_hoa"
                    }
                ]
            },
            {
                "name": "Huyện Quảng Trạch",
                "code": 454,
                "codename": "huyen_quang_trach",
                "division_type": "huyện",
                "short_codename": "quang_trach",
                "wards": [
                    {
                        "name": "Xã Quảng Hợp",
                        "code": 19012,
                        "codename": "xa_quang_hop",
                        "division_type": "xã",
                        "short_codename": "quang_hop"
                    },
                    {
                        "name": "Xã Quảng Kim",
                        "code": 19015,
                        "codename": "xa_quang_kim",
                        "division_type": "xã",
                        "short_codename": "quang_kim"
                    },
                    {
                        "name": "Xã Quảng Đông",
                        "code": 19018,
                        "codename": "xa_quang_dong",
                        "division_type": "xã",
                        "short_codename": "quang_dong"
                    },
                    {
                        "name": "Xã Quảng Phú",
                        "code": 19021,
                        "codename": "xa_quang_phu",
                        "division_type": "xã",
                        "short_codename": "quang_phu"
                    },
                    {
                        "name": "Xã Quảng Châu",
                        "code": 19024,
                        "codename": "xa_quang_chau",
                        "division_type": "xã",
                        "short_codename": "quang_chau"
                    },
                    {
                        "name": "Xã Quảng Thạch",
                        "code": 19027,
                        "codename": "xa_quang_thach",
                        "division_type": "xã",
                        "short_codename": "quang_thach"
                    },
                    {
                        "name": "Xã Quảng Lưu",
                        "code": 19030,
                        "codename": "xa_quang_luu",
                        "division_type": "xã",
                        "short_codename": "quang_luu"
                    },
                    {
                        "name": "Xã Quảng Tùng",
                        "code": 19033,
                        "codename": "xa_quang_tung",
                        "division_type": "xã",
                        "short_codename": "quang_tung"
                    },
                    {
                        "name": "Xã Cảnh Dương",
                        "code": 19036,
                        "codename": "xa_canh_duong",
                        "division_type": "xã",
                        "short_codename": "canh_duong"
                    },
                    {
                        "name": "Xã Quảng Tiến",
                        "code": 19039,
                        "codename": "xa_quang_tien",
                        "division_type": "xã",
                        "short_codename": "quang_tien"
                    },
                    {
                        "name": "Xã Quảng Hưng",
                        "code": 19042,
                        "codename": "xa_quang_hung",
                        "division_type": "xã",
                        "short_codename": "quang_hung"
                    },
                    {
                        "name": "Xã Quảng Xuân",
                        "code": 19045,
                        "codename": "xa_quang_xuan",
                        "division_type": "xã",
                        "short_codename": "quang_xuan"
                    },
                    {
                        "name": "Xã Cảnh Hóa",
                        "code": 19048,
                        "codename": "xa_canh_hoa",
                        "division_type": "xã",
                        "short_codename": "canh_hoa"
                    },
                    {
                        "name": "Xã Liên Trường",
                        "code": 19051,
                        "codename": "xa_lien_truong",
                        "division_type": "xã",
                        "short_codename": "lien_truong"
                    },
                    {
                        "name": "Xã Quảng Phương",
                        "code": 19057,
                        "codename": "xa_quang_phuong",
                        "division_type": "xã",
                        "short_codename": "quang_phuong"
                    },
                    {
                        "name": "Xã Phù Hóa",
                        "code": 19063,
                        "codename": "xa_phu_hoa",
                        "division_type": "xã",
                        "short_codename": "phu_hoa"
                    },
                    {
                        "name": "Xã Quảng Thanh",
                        "code": 19072,
                        "codename": "xa_quang_thanh",
                        "division_type": "xã",
                        "short_codename": "quang_thanh"
                    }
                ]
            },
            {
                "name": "Huyện Bố Trạch",
                "code": 455,
                "codename": "huyen_bo_trach",
                "division_type": "huyện",
                "short_codename": "bo_trach",
                "wards": [
                    {
                        "name": "Thị trấn Hoàn Lão",
                        "code": 19111,
                        "codename": "thi_tran_hoan_lao",
                        "division_type": "thị trấn",
                        "short_codename": "hoan_lao"
                    },
                    {
                        "name": "Thị trấn NT Việt Trung",
                        "code": 19114,
                        "codename": "thi_tran_nt_viet_trung",
                        "division_type": "thị trấn",
                        "short_codename": "nt_viet_trung"
                    },
                    {
                        "name": "Xã Xuân Trạch",
                        "code": 19117,
                        "codename": "xa_xuan_trach",
                        "division_type": "xã",
                        "short_codename": "xuan_trach"
                    },
                    {
                        "name": "Xã Mỹ Trạch",
                        "code": 19120,
                        "codename": "xa_my_trach",
                        "division_type": "xã",
                        "short_codename": "my_trach"
                    },
                    {
                        "name": "Xã Hạ Trạch",
                        "code": 19123,
                        "codename": "xa_ha_trach",
                        "division_type": "xã",
                        "short_codename": "ha_trach"
                    },
                    {
                        "name": "Xã Bắc Trạch",
                        "code": 19126,
                        "codename": "xa_bac_trach",
                        "division_type": "xã",
                        "short_codename": "bac_trach"
                    },
                    {
                        "name": "Xã Lâm Trạch",
                        "code": 19129,
                        "codename": "xa_lam_trach",
                        "division_type": "xã",
                        "short_codename": "lam_trach"
                    },
                    {
                        "name": "Xã Thanh Trạch",
                        "code": 19132,
                        "codename": "xa_thanh_trach",
                        "division_type": "xã",
                        "short_codename": "thanh_trach"
                    },
                    {
                        "name": "Xã Liên Trạch",
                        "code": 19135,
                        "codename": "xa_lien_trach",
                        "division_type": "xã",
                        "short_codename": "lien_trach"
                    },
                    {
                        "name": "Xã Phúc Trạch",
                        "code": 19138,
                        "codename": "xa_phuc_trach",
                        "division_type": "xã",
                        "short_codename": "phuc_trach"
                    },
                    {
                        "name": "Xã Cự Nẫm",
                        "code": 19141,
                        "codename": "xa_cu_nam",
                        "division_type": "xã",
                        "short_codename": "cu_nam"
                    },
                    {
                        "name": "Xã Hải Phú",
                        "code": 19144,
                        "codename": "xa_hai_phu",
                        "division_type": "xã",
                        "short_codename": "hai_phu"
                    },
                    {
                        "name": "Xã Thượng Trạch",
                        "code": 19147,
                        "codename": "xa_thuong_trach",
                        "division_type": "xã",
                        "short_codename": "thuong_trach"
                    },
                    {
                        "name": "Xã Sơn Lộc",
                        "code": 19150,
                        "codename": "xa_son_loc",
                        "division_type": "xã",
                        "short_codename": "son_loc"
                    },
                    {
                        "name": "Xã Hưng Trạch",
                        "code": 19156,
                        "codename": "xa_hung_trach",
                        "division_type": "xã",
                        "short_codename": "hung_trach"
                    },
                    {
                        "name": "Xã Đồng Trạch",
                        "code": 19159,
                        "codename": "xa_dong_trach",
                        "division_type": "xã",
                        "short_codename": "dong_trach"
                    },
                    {
                        "name": "Xã Đức Trạch",
                        "code": 19162,
                        "codename": "xa_duc_trach",
                        "division_type": "xã",
                        "short_codename": "duc_trach"
                    },
                    {
                        "name": "Thị trấn Phong Nha",
                        "code": 19165,
                        "codename": "thi_tran_phong_nha",
                        "division_type": "thị trấn",
                        "short_codename": "phong_nha"
                    },
                    {
                        "name": "Xã Vạn Trạch",
                        "code": 19168,
                        "codename": "xa_van_trach",
                        "division_type": "xã",
                        "short_codename": "van_trach"
                    },
                    {
                        "name": "Xã Phú Định",
                        "code": 19174,
                        "codename": "xa_phu_dinh",
                        "division_type": "xã",
                        "short_codename": "phu_dinh"
                    },
                    {
                        "name": "Xã Trung Trạch",
                        "code": 19177,
                        "codename": "xa_trung_trach",
                        "division_type": "xã",
                        "short_codename": "trung_trach"
                    },
                    {
                        "name": "Xã Tây Trạch",
                        "code": 19180,
                        "codename": "xa_tay_trach",
                        "division_type": "xã",
                        "short_codename": "tay_trach"
                    },
                    {
                        "name": "Xã Hòa Trạch",
                        "code": 19183,
                        "codename": "xa_hoa_trach",
                        "division_type": "xã",
                        "short_codename": "hoa_trach"
                    },
                    {
                        "name": "Xã Đại Trạch",
                        "code": 19186,
                        "codename": "xa_dai_trach",
                        "division_type": "xã",
                        "short_codename": "dai_trach"
                    },
                    {
                        "name": "Xã Nhân Trạch",
                        "code": 19189,
                        "codename": "xa_nhan_trach",
                        "division_type": "xã",
                        "short_codename": "nhan_trach"
                    },
                    {
                        "name": "Xã Tân Trạch",
                        "code": 19192,
                        "codename": "xa_tan_trach",
                        "division_type": "xã",
                        "short_codename": "tan_trach"
                    },
                    {
                        "name": "Xã Nam Trạch",
                        "code": 19195,
                        "codename": "xa_nam_trach",
                        "division_type": "xã",
                        "short_codename": "nam_trach"
                    },
                    {
                        "name": "Xã Lý Trạch",
                        "code": 19198,
                        "codename": "xa_ly_trach",
                        "division_type": "xã",
                        "short_codename": "ly_trach"
                    }
                ]
            },
            {
                "name": "Huyện Quảng Ninh",
                "code": 456,
                "codename": "huyen_quang_ninh",
                "division_type": "huyện",
                "short_codename": "quang_ninh",
                "wards": [
                    {
                        "name": "Thị trấn Quán Hàu",
                        "code": 19201,
                        "codename": "thi_tran_quan_hau",
                        "division_type": "thị trấn",
                        "short_codename": "quan_hau"
                    },
                    {
                        "name": "Xã Trường Sơn",
                        "code": 19204,
                        "codename": "xa_truong_son",
                        "division_type": "xã",
                        "short_codename": "truong_son"
                    },
                    {
                        "name": "Xã Lương Ninh",
                        "code": 19207,
                        "codename": "xa_luong_ninh",
                        "division_type": "xã",
                        "short_codename": "luong_ninh"
                    },
                    {
                        "name": "Xã Vĩnh Ninh",
                        "code": 19210,
                        "codename": "xa_vinh_ninh",
                        "division_type": "xã",
                        "short_codename": "vinh_ninh"
                    },
                    {
                        "name": "Xã Võ Ninh",
                        "code": 19213,
                        "codename": "xa_vo_ninh",
                        "division_type": "xã",
                        "short_codename": "vo_ninh"
                    },
                    {
                        "name": "Xã Hải Ninh",
                        "code": 19216,
                        "codename": "xa_hai_ninh",
                        "division_type": "xã",
                        "short_codename": "hai_ninh"
                    },
                    {
                        "name": "Xã Hàm Ninh",
                        "code": 19219,
                        "codename": "xa_ham_ninh",
                        "division_type": "xã",
                        "short_codename": "ham_ninh"
                    },
                    {
                        "name": "Xã Duy Ninh",
                        "code": 19222,
                        "codename": "xa_duy_ninh",
                        "division_type": "xã",
                        "short_codename": "duy_ninh"
                    },
                    {
                        "name": "Xã Gia Ninh",
                        "code": 19225,
                        "codename": "xa_gia_ninh",
                        "division_type": "xã",
                        "short_codename": "gia_ninh"
                    },
                    {
                        "name": "Xã Trường Xuân",
                        "code": 19228,
                        "codename": "xa_truong_xuan",
                        "division_type": "xã",
                        "short_codename": "truong_xuan"
                    },
                    {
                        "name": "Xã Hiền Ninh",
                        "code": 19231,
                        "codename": "xa_hien_ninh",
                        "division_type": "xã",
                        "short_codename": "hien_ninh"
                    },
                    {
                        "name": "Xã Tân Ninh",
                        "code": 19234,
                        "codename": "xa_tan_ninh",
                        "division_type": "xã",
                        "short_codename": "tan_ninh"
                    },
                    {
                        "name": "Xã Xuân Ninh",
                        "code": 19237,
                        "codename": "xa_xuan_ninh",
                        "division_type": "xã",
                        "short_codename": "xuan_ninh"
                    },
                    {
                        "name": "Xã An Ninh",
                        "code": 19240,
                        "codename": "xa_an_ninh",
                        "division_type": "xã",
                        "short_codename": "an_ninh"
                    },
                    {
                        "name": "Xã Vạn Ninh",
                        "code": 19243,
                        "codename": "xa_van_ninh",
                        "division_type": "xã",
                        "short_codename": "van_ninh"
                    }
                ]
            },
            {
                "name": "Huyện Lệ Thủy",
                "code": 457,
                "codename": "huyen_le_thuy",
                "division_type": "huyện",
                "short_codename": "le_thuy",
                "wards": [
                    {
                        "name": "Thị trấn NT Lệ Ninh",
                        "code": 19246,
                        "codename": "thi_tran_nt_le_ninh",
                        "division_type": "thị trấn",
                        "short_codename": "nt_le_ninh"
                    },
                    {
                        "name": "Thị trấn Kiến Giang",
                        "code": 19249,
                        "codename": "thi_tran_kien_giang",
                        "division_type": "thị trấn",
                        "short_codename": "kien_giang"
                    },
                    {
                        "name": "Xã Hồng Thủy",
                        "code": 19252,
                        "codename": "xa_hong_thuy",
                        "division_type": "xã",
                        "short_codename": "hong_thuy"
                    },
                    {
                        "name": "Xã Ngư Thủy Bắc",
                        "code": 19255,
                        "codename": "xa_ngu_thuy_bac",
                        "division_type": "xã",
                        "short_codename": "ngu_thuy_bac"
                    },
                    {
                        "name": "Xã Hoa Thủy",
                        "code": 19258,
                        "codename": "xa_hoa_thuy",
                        "division_type": "xã",
                        "short_codename": "hoa_thuy"
                    },
                    {
                        "name": "Xã Thanh Thủy",
                        "code": 19261,
                        "codename": "xa_thanh_thuy",
                        "division_type": "xã",
                        "short_codename": "thanh_thuy"
                    },
                    {
                        "name": "Xã An Thủy",
                        "code": 19264,
                        "codename": "xa_an_thuy",
                        "division_type": "xã",
                        "short_codename": "an_thuy"
                    },
                    {
                        "name": "Xã Phong Thủy",
                        "code": 19267,
                        "codename": "xa_phong_thuy",
                        "division_type": "xã",
                        "short_codename": "phong_thuy"
                    },
                    {
                        "name": "Xã Cam Thủy",
                        "code": 19270,
                        "codename": "xa_cam_thuy",
                        "division_type": "xã",
                        "short_codename": "cam_thuy"
                    },
                    {
                        "name": "Xã Ngân Thủy",
                        "code": 19273,
                        "codename": "xa_ngan_thuy",
                        "division_type": "xã",
                        "short_codename": "ngan_thuy"
                    },
                    {
                        "name": "Xã Sơn Thủy",
                        "code": 19276,
                        "codename": "xa_son_thuy",
                        "division_type": "xã",
                        "short_codename": "son_thuy"
                    },
                    {
                        "name": "Xã Lộc Thủy",
                        "code": 19279,
                        "codename": "xa_loc_thuy",
                        "division_type": "xã",
                        "short_codename": "loc_thuy"
                    },
                    {
                        "name": "Xã Liên Thủy",
                        "code": 19285,
                        "codename": "xa_lien_thuy",
                        "division_type": "xã",
                        "short_codename": "lien_thuy"
                    },
                    {
                        "name": "Xã Hưng Thủy",
                        "code": 19288,
                        "codename": "xa_hung_thuy",
                        "division_type": "xã",
                        "short_codename": "hung_thuy"
                    },
                    {
                        "name": "Xã Dương Thủy",
                        "code": 19291,
                        "codename": "xa_duong_thuy",
                        "division_type": "xã",
                        "short_codename": "duong_thuy"
                    },
                    {
                        "name": "Xã Tân Thủy",
                        "code": 19294,
                        "codename": "xa_tan_thuy",
                        "division_type": "xã",
                        "short_codename": "tan_thuy"
                    },
                    {
                        "name": "Xã Phú Thủy",
                        "code": 19297,
                        "codename": "xa_phu_thuy",
                        "division_type": "xã",
                        "short_codename": "phu_thuy"
                    },
                    {
                        "name": "Xã Xuân Thủy",
                        "code": 19300,
                        "codename": "xa_xuan_thuy",
                        "division_type": "xã",
                        "short_codename": "xuan_thuy"
                    },
                    {
                        "name": "Xã Mỹ Thủy",
                        "code": 19303,
                        "codename": "xa_my_thuy",
                        "division_type": "xã",
                        "short_codename": "my_thuy"
                    },
                    {
                        "name": "Xã Ngư Thủy",
                        "code": 19306,
                        "codename": "xa_ngu_thuy",
                        "division_type": "xã",
                        "short_codename": "ngu_thuy"
                    },
                    {
                        "name": "Xã Mai Thủy",
                        "code": 19309,
                        "codename": "xa_mai_thuy",
                        "division_type": "xã",
                        "short_codename": "mai_thuy"
                    },
                    {
                        "name": "Xã Sen Thủy",
                        "code": 19312,
                        "codename": "xa_sen_thuy",
                        "division_type": "xã",
                        "short_codename": "sen_thuy"
                    },
                    {
                        "name": "Xã Thái Thủy",
                        "code": 19315,
                        "codename": "xa_thai_thuy",
                        "division_type": "xã",
                        "short_codename": "thai_thuy"
                    },
                    {
                        "name": "Xã Kim Thủy",
                        "code": 19318,
                        "codename": "xa_kim_thuy",
                        "division_type": "xã",
                        "short_codename": "kim_thuy"
                    },
                    {
                        "name": "Xã Trường Thủy",
                        "code": 19321,
                        "codename": "xa_truong_thuy",
                        "division_type": "xã",
                        "short_codename": "truong_thuy"
                    },
                    {
                        "name": "Xã Lâm Thủy",
                        "code": 19327,
                        "codename": "xa_lam_thuy",
                        "division_type": "xã",
                        "short_codename": "lam_thuy"
                    }
                ]
            },
            {
                "name": "Thị xã Ba Đồn",
                "code": 458,
                "codename": "thi_xa_ba_don",
                "division_type": "thị xã",
                "short_codename": "ba_don",
                "wards": [
                    {
                        "name": "Phường Ba Đồn",
                        "code": 19009,
                        "codename": "phuong_ba_don",
                        "division_type": "phường",
                        "short_codename": "ba_don"
                    },
                    {
                        "name": "Phường Quảng Long",
                        "code": 19060,
                        "codename": "phuong_quang_long",
                        "division_type": "phường",
                        "short_codename": "quang_long"
                    },
                    {
                        "name": "Phường Quảng Thọ",
                        "code": 19066,
                        "codename": "phuong_quang_tho",
                        "division_type": "phường",
                        "short_codename": "quang_tho"
                    },
                    {
                        "name": "Xã Quảng Tiên",
                        "code": 19069,
                        "codename": "xa_quang_tien",
                        "division_type": "xã",
                        "short_codename": "quang_tien"
                    },
                    {
                        "name": "Xã Quảng Trung",
                        "code": 19075,
                        "codename": "xa_quang_trung",
                        "division_type": "xã",
                        "short_codename": "quang_trung"
                    },
                    {
                        "name": "Phường Quảng Phong",
                        "code": 19078,
                        "codename": "phuong_quang_phong",
                        "division_type": "phường",
                        "short_codename": "quang_phong"
                    },
                    {
                        "name": "Phường Quảng Thuận",
                        "code": 19081,
                        "codename": "phuong_quang_thuan",
                        "division_type": "phường",
                        "short_codename": "quang_thuan"
                    },
                    {
                        "name": "Xã Quảng Tân",
                        "code": 19084,
                        "codename": "xa_quang_tan",
                        "division_type": "xã",
                        "short_codename": "quang_tan"
                    },
                    {
                        "name": "Xã Quảng Hải",
                        "code": 19087,
                        "codename": "xa_quang_hai",
                        "division_type": "xã",
                        "short_codename": "quang_hai"
                    },
                    {
                        "name": "Xã Quảng Sơn",
                        "code": 19090,
                        "codename": "xa_quang_son",
                        "division_type": "xã",
                        "short_codename": "quang_son"
                    },
                    {
                        "name": "Xã Quảng Lộc",
                        "code": 19093,
                        "codename": "xa_quang_loc",
                        "division_type": "xã",
                        "short_codename": "quang_loc"
                    },
                    {
                        "name": "Xã Quảng Thủy",
                        "code": 19096,
                        "codename": "xa_quang_thuy",
                        "division_type": "xã",
                        "short_codename": "quang_thuy"
                    },
                    {
                        "name": "Xã Quảng Văn",
                        "code": 19099,
                        "codename": "xa_quang_van",
                        "division_type": "xã",
                        "short_codename": "quang_van"
                    },
                    {
                        "name": "Phường Quảng Phúc",
                        "code": 19102,
                        "codename": "phuong_quang_phuc",
                        "division_type": "phường",
                        "short_codename": "quang_phuc"
                    },
                    {
                        "name": "Xã Quảng Hòa",
                        "code": 19105,
                        "codename": "xa_quang_hoa",
                        "division_type": "xã",
                        "short_codename": "quang_hoa"
                    },
                    {
                        "name": "Xã Quảng Minh",
                        "code": 19108,
                        "codename": "xa_quang_minh",
                        "division_type": "xã",
                        "short_codename": "quang_minh"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Quảng Trị",
        "code": 45,
        "codename": "tinh_quang_tri",
        "division_type": "tỉnh",
        "phone_code": 233,
        "districts": [
            {
                "name": "Thành phố Đông Hà",
                "code": 461,
                "codename": "thanh_pho_dong_ha",
                "division_type": "thành phố",
                "short_codename": "dong_ha",
                "wards": [
                    {
                        "name": "Phường Đông Giang",
                        "code": 19330,
                        "codename": "phuong_dong_giang",
                        "division_type": "phường",
                        "short_codename": "dong_giang"
                    },
                    {
                        "name": "Phường 1",
                        "code": 19333,
                        "codename": "phuong_1",
                        "division_type": "phường",
                        "short_codename": "phuong_1"
                    },
                    {
                        "name": "Phường Đông Lễ",
                        "code": 19336,
                        "codename": "phuong_dong_le",
                        "division_type": "phường",
                        "short_codename": "dong_le"
                    },
                    {
                        "name": "Phường Đông Thanh",
                        "code": 19339,
                        "codename": "phuong_dong_thanh",
                        "division_type": "phường",
                        "short_codename": "dong_thanh"
                    },
                    {
                        "name": "Phường 2",
                        "code": 19342,
                        "codename": "phuong_2",
                        "division_type": "phường",
                        "short_codename": "phuong_2"
                    },
                    {
                        "name": "Phường 4",
                        "code": 19345,
                        "codename": "phuong_4",
                        "division_type": "phường",
                        "short_codename": "phuong_4"
                    },
                    {
                        "name": "Phường 5",
                        "code": 19348,
                        "codename": "phuong_5",
                        "division_type": "phường",
                        "short_codename": "phuong_5"
                    },
                    {
                        "name": "Phường Đông Lương",
                        "code": 19351,
                        "codename": "phuong_dong_luong",
                        "division_type": "phường",
                        "short_codename": "dong_luong"
                    },
                    {
                        "name": "Phường 3",
                        "code": 19354,
                        "codename": "phuong_3",
                        "division_type": "phường",
                        "short_codename": "phuong_3"
                    }
                ]
            },
            {
                "name": "Thị xã Quảng Trị",
                "code": 462,
                "codename": "thi_xa_quang_tri",
                "division_type": "thị xã",
                "short_codename": "quang_tri",
                "wards": [
                    {
                        "name": "Phường 1",
                        "code": 19357,
                        "codename": "phuong_1",
                        "division_type": "phường",
                        "short_codename": "phuong_1"
                    },
                    {
                        "name": "Phường An Đôn",
                        "code": 19358,
                        "codename": "phuong_an_don",
                        "division_type": "phường",
                        "short_codename": "an_don"
                    },
                    {
                        "name": "Phường 2",
                        "code": 19360,
                        "codename": "phuong_2",
                        "division_type": "phường",
                        "short_codename": "phuong_2"
                    },
                    {
                        "name": "Phường 3",
                        "code": 19361,
                        "codename": "phuong_3",
                        "division_type": "phường",
                        "short_codename": "phuong_3"
                    },
                    {
                        "name": "Xã Hải Lệ",
                        "code": 19705,
                        "codename": "xa_hai_le",
                        "division_type": "xã",
                        "short_codename": "hai_le"
                    }
                ]
            },
            {
                "name": "Huyện Vĩnh Linh",
                "code": 464,
                "codename": "huyen_vinh_linh",
                "division_type": "huyện",
                "short_codename": "vinh_linh",
                "wards": [
                    {
                        "name": "Thị trấn Hồ Xá",
                        "code": 19363,
                        "codename": "thi_tran_ho_xa",
                        "division_type": "thị trấn",
                        "short_codename": "ho_xa"
                    },
                    {
                        "name": "Thị trấn Bến Quan",
                        "code": 19366,
                        "codename": "thi_tran_ben_quan",
                        "division_type": "thị trấn",
                        "short_codename": "ben_quan"
                    },
                    {
                        "name": "Xã Vĩnh Thái",
                        "code": 19369,
                        "codename": "xa_vinh_thai",
                        "division_type": "xã",
                        "short_codename": "vinh_thai"
                    },
                    {
                        "name": "Xã Vĩnh Tú",
                        "code": 19372,
                        "codename": "xa_vinh_tu",
                        "division_type": "xã",
                        "short_codename": "vinh_tu"
                    },
                    {
                        "name": "Xã Vĩnh Chấp",
                        "code": 19375,
                        "codename": "xa_vinh_chap",
                        "division_type": "xã",
                        "short_codename": "vinh_chap"
                    },
                    {
                        "name": "Xã Trung Nam",
                        "code": 19378,
                        "codename": "xa_trung_nam",
                        "division_type": "xã",
                        "short_codename": "trung_nam"
                    },
                    {
                        "name": "Xã Kim Thạch",
                        "code": 19384,
                        "codename": "xa_kim_thach",
                        "division_type": "xã",
                        "short_codename": "kim_thach"
                    },
                    {
                        "name": "Xã Vĩnh Long",
                        "code": 19387,
                        "codename": "xa_vinh_long",
                        "division_type": "xã",
                        "short_codename": "vinh_long"
                    },
                    {
                        "name": "Xã Vĩnh Khê",
                        "code": 19393,
                        "codename": "xa_vinh_khe",
                        "division_type": "xã",
                        "short_codename": "vinh_khe"
                    },
                    {
                        "name": "Xã Vĩnh Hòa",
                        "code": 19396,
                        "codename": "xa_vinh_hoa",
                        "division_type": "xã",
                        "short_codename": "vinh_hoa"
                    },
                    {
                        "name": "Xã Vĩnh Thủy",
                        "code": 19402,
                        "codename": "xa_vinh_thuy",
                        "division_type": "xã",
                        "short_codename": "vinh_thuy"
                    },
                    {
                        "name": "Xã Vĩnh Lâm",
                        "code": 19405,
                        "codename": "xa_vinh_lam",
                        "division_type": "xã",
                        "short_codename": "vinh_lam"
                    },
                    {
                        "name": "Xã Hiền Thành",
                        "code": 19408,
                        "codename": "xa_hien_thanh",
                        "division_type": "xã",
                        "short_codename": "hien_thanh"
                    },
                    {
                        "name": "Thị trấn Cửa Tùng",
                        "code": 19414,
                        "codename": "thi_tran_cua_tung",
                        "division_type": "thị trấn",
                        "short_codename": "cua_tung"
                    },
                    {
                        "name": "Xã Vĩnh Hà",
                        "code": 19417,
                        "codename": "xa_vinh_ha",
                        "division_type": "xã",
                        "short_codename": "vinh_ha"
                    },
                    {
                        "name": "Xã Vĩnh Sơn",
                        "code": 19420,
                        "codename": "xa_vinh_son",
                        "division_type": "xã",
                        "short_codename": "vinh_son"
                    },
                    {
                        "name": "Xã Vĩnh Giang",
                        "code": 19423,
                        "codename": "xa_vinh_giang",
                        "division_type": "xã",
                        "short_codename": "vinh_giang"
                    },
                    {
                        "name": "Xã Vĩnh Ô",
                        "code": 19426,
                        "codename": "xa_vinh_o",
                        "division_type": "xã",
                        "short_codename": "vinh_o"
                    }
                ]
            },
            {
                "name": "Huyện Hướng Hóa",
                "code": 465,
                "codename": "huyen_huong_hoa",
                "division_type": "huyện",
                "short_codename": "huong_hoa",
                "wards": [
                    {
                        "name": "Thị trấn Khe Sanh",
                        "code": 19429,
                        "codename": "thi_tran_khe_sanh",
                        "division_type": "thị trấn",
                        "short_codename": "khe_sanh"
                    },
                    {
                        "name": "Thị trấn Lao Bảo",
                        "code": 19432,
                        "codename": "thi_tran_lao_bao",
                        "division_type": "thị trấn",
                        "short_codename": "lao_bao"
                    },
                    {
                        "name": "Xã Hướng Lập",
                        "code": 19435,
                        "codename": "xa_huong_lap",
                        "division_type": "xã",
                        "short_codename": "huong_lap"
                    },
                    {
                        "name": "Xã Hướng Việt",
                        "code": 19438,
                        "codename": "xa_huong_viet",
                        "division_type": "xã",
                        "short_codename": "huong_viet"
                    },
                    {
                        "name": "Xã Hướng Phùng",
                        "code": 19441,
                        "codename": "xa_huong_phung",
                        "division_type": "xã",
                        "short_codename": "huong_phung"
                    },
                    {
                        "name": "Xã Hướng Sơn",
                        "code": 19444,
                        "codename": "xa_huong_son",
                        "division_type": "xã",
                        "short_codename": "huong_son"
                    },
                    {
                        "name": "Xã Hướng Linh",
                        "code": 19447,
                        "codename": "xa_huong_linh",
                        "division_type": "xã",
                        "short_codename": "huong_linh"
                    },
                    {
                        "name": "Xã Tân Hợp",
                        "code": 19450,
                        "codename": "xa_tan_hop",
                        "division_type": "xã",
                        "short_codename": "tan_hop"
                    },
                    {
                        "name": "Xã Hướng Tân",
                        "code": 19453,
                        "codename": "xa_huong_tan",
                        "division_type": "xã",
                        "short_codename": "huong_tan"
                    },
                    {
                        "name": "Xã Tân Thành",
                        "code": 19456,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Xã Tân Long",
                        "code": 19459,
                        "codename": "xa_tan_long",
                        "division_type": "xã",
                        "short_codename": "tan_long"
                    },
                    {
                        "name": "Xã Tân Lập",
                        "code": 19462,
                        "codename": "xa_tan_lap",
                        "division_type": "xã",
                        "short_codename": "tan_lap"
                    },
                    {
                        "name": "Xã Tân Liên",
                        "code": 19465,
                        "codename": "xa_tan_lien",
                        "division_type": "xã",
                        "short_codename": "tan_lien"
                    },
                    {
                        "name": "Xã Húc",
                        "code": 19468,
                        "codename": "xa_huc",
                        "division_type": "xã",
                        "short_codename": "huc"
                    },
                    {
                        "name": "Xã Thuận",
                        "code": 19471,
                        "codename": "xa_thuan",
                        "division_type": "xã",
                        "short_codename": "thuan"
                    },
                    {
                        "name": "Xã Hướng Lộc",
                        "code": 19474,
                        "codename": "xa_huong_loc",
                        "division_type": "xã",
                        "short_codename": "huong_loc"
                    },
                    {
                        "name": "Xã Ba Tầng",
                        "code": 19477,
                        "codename": "xa_ba_tang",
                        "division_type": "xã",
                        "short_codename": "ba_tang"
                    },
                    {
                        "name": "Xã Thanh",
                        "code": 19480,
                        "codename": "xa_thanh",
                        "division_type": "xã",
                        "short_codename": "thanh"
                    },
                    {
                        "name": "Xã A Dơi",
                        "code": 19483,
                        "codename": "xa_a_doi",
                        "division_type": "xã",
                        "short_codename": "a_doi"
                    },
                    {
                        "name": "Xã Lìa",
                        "code": 19489,
                        "codename": "xa_lia",
                        "division_type": "xã",
                        "short_codename": "lia"
                    },
                    {
                        "name": "Xã Xy",
                        "code": 19492,
                        "codename": "xa_xy",
                        "division_type": "xã",
                        "short_codename": "xy"
                    }
                ]
            },
            {
                "name": "Huyện Gio Linh",
                "code": 466,
                "codename": "huyen_gio_linh",
                "division_type": "huyện",
                "short_codename": "gio_linh",
                "wards": [
                    {
                        "name": "Thị trấn Gio Linh",
                        "code": 19495,
                        "codename": "thi_tran_gio_linh",
                        "division_type": "thị trấn",
                        "short_codename": "gio_linh"
                    },
                    {
                        "name": "Thị trấn Cửa Việt",
                        "code": 19496,
                        "codename": "thi_tran_cua_viet",
                        "division_type": "thị trấn",
                        "short_codename": "cua_viet"
                    },
                    {
                        "name": "Xã Trung Giang",
                        "code": 19498,
                        "codename": "xa_trung_giang",
                        "division_type": "xã",
                        "short_codename": "trung_giang"
                    },
                    {
                        "name": "Xã Trung Hải",
                        "code": 19501,
                        "codename": "xa_trung_hai",
                        "division_type": "xã",
                        "short_codename": "trung_hai"
                    },
                    {
                        "name": "Xã Trung Sơn",
                        "code": 19504,
                        "codename": "xa_trung_son",
                        "division_type": "xã",
                        "short_codename": "trung_son"
                    },
                    {
                        "name": "Xã Phong Bình",
                        "code": 19507,
                        "codename": "xa_phong_binh",
                        "division_type": "xã",
                        "short_codename": "phong_binh"
                    },
                    {
                        "name": "Xã Gio Mỹ",
                        "code": 19510,
                        "codename": "xa_gio_my",
                        "division_type": "xã",
                        "short_codename": "gio_my"
                    },
                    {
                        "name": "Xã Gio Hải",
                        "code": 19519,
                        "codename": "xa_gio_hai",
                        "division_type": "xã",
                        "short_codename": "gio_hai"
                    },
                    {
                        "name": "Xã Gio An",
                        "code": 19522,
                        "codename": "xa_gio_an",
                        "division_type": "xã",
                        "short_codename": "gio_an"
                    },
                    {
                        "name": "Xã Gio Châu",
                        "code": 19525,
                        "codename": "xa_gio_chau",
                        "division_type": "xã",
                        "short_codename": "gio_chau"
                    },
                    {
                        "name": "Xã Gio Việt",
                        "code": 19531,
                        "codename": "xa_gio_viet",
                        "division_type": "xã",
                        "short_codename": "gio_viet"
                    },
                    {
                        "name": "Xã Linh Trường",
                        "code": 19534,
                        "codename": "xa_linh_truong",
                        "division_type": "xã",
                        "short_codename": "linh_truong"
                    },
                    {
                        "name": "Xã Gio Sơn",
                        "code": 19537,
                        "codename": "xa_gio_son",
                        "division_type": "xã",
                        "short_codename": "gio_son"
                    },
                    {
                        "name": "Xã Gio Mai",
                        "code": 19543,
                        "codename": "xa_gio_mai",
                        "division_type": "xã",
                        "short_codename": "gio_mai"
                    },
                    {
                        "name": "Xã Hải Thái",
                        "code": 19546,
                        "codename": "xa_hai_thai",
                        "division_type": "xã",
                        "short_codename": "hai_thai"
                    },
                    {
                        "name": "Xã Linh Hải",
                        "code": 19549,
                        "codename": "xa_linh_hai",
                        "division_type": "xã",
                        "short_codename": "linh_hai"
                    },
                    {
                        "name": "Xã Gio Quang",
                        "code": 19552,
                        "codename": "xa_gio_quang",
                        "division_type": "xã",
                        "short_codename": "gio_quang"
                    }
                ]
            },
            {
                "name": "Huyện Đa Krông",
                "code": 467,
                "codename": "huyen_da_krong",
                "division_type": "huyện",
                "short_codename": "da_krong",
                "wards": [
                    {
                        "name": "Thị trấn Krông Klang",
                        "code": 19555,
                        "codename": "thi_tran_krong_klang",
                        "division_type": "thị trấn",
                        "short_codename": "krong_klang"
                    },
                    {
                        "name": "Xã Mò Ó",
                        "code": 19558,
                        "codename": "xa_mo_o",
                        "division_type": "xã",
                        "short_codename": "mo_o"
                    },
                    {
                        "name": "Xã Hướng Hiệp",
                        "code": 19561,
                        "codename": "xa_huong_hiep",
                        "division_type": "xã",
                        "short_codename": "huong_hiep"
                    },
                    {
                        "name": "Xã Đa Krông",
                        "code": 19564,
                        "codename": "xa_da_krong",
                        "division_type": "xã",
                        "short_codename": "da_krong"
                    },
                    {
                        "name": "Xã Triệu Nguyên",
                        "code": 19567,
                        "codename": "xa_trieu_nguyen",
                        "division_type": "xã",
                        "short_codename": "trieu_nguyen"
                    },
                    {
                        "name": "Xã Ba Lòng",
                        "code": 19570,
                        "codename": "xa_ba_long",
                        "division_type": "xã",
                        "short_codename": "ba_long"
                    },
                    {
                        "name": "Xã Ba Nang",
                        "code": 19576,
                        "codename": "xa_ba_nang",
                        "division_type": "xã",
                        "short_codename": "ba_nang"
                    },
                    {
                        "name": "Xã Tà Long",
                        "code": 19579,
                        "codename": "xa_ta_long",
                        "division_type": "xã",
                        "short_codename": "ta_long"
                    },
                    {
                        "name": "Xã Húc Nghì",
                        "code": 19582,
                        "codename": "xa_huc_nghi",
                        "division_type": "xã",
                        "short_codename": "huc_nghi"
                    },
                    {
                        "name": "Xã A Vao",
                        "code": 19585,
                        "codename": "xa_a_vao",
                        "division_type": "xã",
                        "short_codename": "a_vao"
                    },
                    {
                        "name": "Xã Tà Rụt",
                        "code": 19588,
                        "codename": "xa_ta_rut",
                        "division_type": "xã",
                        "short_codename": "ta_rut"
                    },
                    {
                        "name": "Xã A Bung",
                        "code": 19591,
                        "codename": "xa_a_bung",
                        "division_type": "xã",
                        "short_codename": "a_bung"
                    },
                    {
                        "name": "Xã A Ngo",
                        "code": 19594,
                        "codename": "xa_a_ngo",
                        "division_type": "xã",
                        "short_codename": "a_ngo"
                    }
                ]
            },
            {
                "name": "Huyện Cam Lộ",
                "code": 468,
                "codename": "huyen_cam_lo",
                "division_type": "huyện",
                "short_codename": "cam_lo",
                "wards": [
                    {
                        "name": "Thị trấn Cam Lộ",
                        "code": 19597,
                        "codename": "thi_tran_cam_lo",
                        "division_type": "thị trấn",
                        "short_codename": "cam_lo"
                    },
                    {
                        "name": "Xã Cam Tuyền",
                        "code": 19600,
                        "codename": "xa_cam_tuyen",
                        "division_type": "xã",
                        "short_codename": "cam_tuyen"
                    },
                    {
                        "name": "Xã Thanh An",
                        "code": 19603,
                        "codename": "xa_thanh_an",
                        "division_type": "xã",
                        "short_codename": "thanh_an"
                    },
                    {
                        "name": "Xã Cam Thủy",
                        "code": 19606,
                        "codename": "xa_cam_thuy",
                        "division_type": "xã",
                        "short_codename": "cam_thuy"
                    },
                    {
                        "name": "Xã Cam Thành",
                        "code": 19612,
                        "codename": "xa_cam_thanh",
                        "division_type": "xã",
                        "short_codename": "cam_thanh"
                    },
                    {
                        "name": "Xã Cam Hiếu",
                        "code": 19615,
                        "codename": "xa_cam_hieu",
                        "division_type": "xã",
                        "short_codename": "cam_hieu"
                    },
                    {
                        "name": "Xã Cam Chính",
                        "code": 19618,
                        "codename": "xa_cam_chinh",
                        "division_type": "xã",
                        "short_codename": "cam_chinh"
                    },
                    {
                        "name": "Xã Cam Nghĩa",
                        "code": 19621,
                        "codename": "xa_cam_nghia",
                        "division_type": "xã",
                        "short_codename": "cam_nghia"
                    }
                ]
            },
            {
                "name": "Huyện Triệu Phong",
                "code": 469,
                "codename": "huyen_trieu_phong",
                "division_type": "huyện",
                "short_codename": "trieu_phong",
                "wards": [
                    {
                        "name": "Thị Trấn Ái Tử",
                        "code": 19624,
                        "codename": "thi_tran_ai_tu",
                        "division_type": "thị trấn",
                        "short_codename": "ai_tu"
                    },
                    {
                        "name": "Xã Triệu An",
                        "code": 19627,
                        "codename": "xa_trieu_an",
                        "division_type": "xã",
                        "short_codename": "trieu_an"
                    },
                    {
                        "name": "Xã Triệu Vân",
                        "code": 19630,
                        "codename": "xa_trieu_van",
                        "division_type": "xã",
                        "short_codename": "trieu_van"
                    },
                    {
                        "name": "Xã Triệu Phước",
                        "code": 19633,
                        "codename": "xa_trieu_phuoc",
                        "division_type": "xã",
                        "short_codename": "trieu_phuoc"
                    },
                    {
                        "name": "Xã Triệu Độ",
                        "code": 19636,
                        "codename": "xa_trieu_do",
                        "division_type": "xã",
                        "short_codename": "trieu_do"
                    },
                    {
                        "name": "Xã Triệu Trạch",
                        "code": 19639,
                        "codename": "xa_trieu_trach",
                        "division_type": "xã",
                        "short_codename": "trieu_trach"
                    },
                    {
                        "name": "Xã Triệu Thuận",
                        "code": 19642,
                        "codename": "xa_trieu_thuan",
                        "division_type": "xã",
                        "short_codename": "trieu_thuan"
                    },
                    {
                        "name": "Xã Triệu Đại",
                        "code": 19645,
                        "codename": "xa_trieu_dai",
                        "division_type": "xã",
                        "short_codename": "trieu_dai"
                    },
                    {
                        "name": "Xã Triệu Hòa",
                        "code": 19648,
                        "codename": "xa_trieu_hoa",
                        "division_type": "xã",
                        "short_codename": "trieu_hoa"
                    },
                    {
                        "name": "Xã Triệu Lăng",
                        "code": 19651,
                        "codename": "xa_trieu_lang",
                        "division_type": "xã",
                        "short_codename": "trieu_lang"
                    },
                    {
                        "name": "Xã Triệu Sơn",
                        "code": 19654,
                        "codename": "xa_trieu_son",
                        "division_type": "xã",
                        "short_codename": "trieu_son"
                    },
                    {
                        "name": "Xã Triệu Long",
                        "code": 19657,
                        "codename": "xa_trieu_long",
                        "division_type": "xã",
                        "short_codename": "trieu_long"
                    },
                    {
                        "name": "Xã Triệu Tài",
                        "code": 19660,
                        "codename": "xa_trieu_tai",
                        "division_type": "xã",
                        "short_codename": "trieu_tai"
                    },
                    {
                        "name": "Xã Triệu Trung",
                        "code": 19666,
                        "codename": "xa_trieu_trung",
                        "division_type": "xã",
                        "short_codename": "trieu_trung"
                    },
                    {
                        "name": "Xã Triệu Ái",
                        "code": 19669,
                        "codename": "xa_trieu_ai",
                        "division_type": "xã",
                        "short_codename": "trieu_ai"
                    },
                    {
                        "name": "Xã Triệu Thượng",
                        "code": 19672,
                        "codename": "xa_trieu_thuong",
                        "division_type": "xã",
                        "short_codename": "trieu_thuong"
                    },
                    {
                        "name": "Xã Triệu Giang",
                        "code": 19675,
                        "codename": "xa_trieu_giang",
                        "division_type": "xã",
                        "short_codename": "trieu_giang"
                    },
                    {
                        "name": "Xã Triệu Thành",
                        "code": 19678,
                        "codename": "xa_trieu_thanh",
                        "division_type": "xã",
                        "short_codename": "trieu_thanh"
                    }
                ]
            },
            {
                "name": "Huyện Hải Lăng",
                "code": 470,
                "codename": "huyen_hai_lang",
                "division_type": "huyện",
                "short_codename": "hai_lang",
                "wards": [
                    {
                        "name": "Thị trấn Diên Sanh",
                        "code": 19681,
                        "codename": "thi_tran_dien_sanh",
                        "division_type": "thị trấn",
                        "short_codename": "dien_sanh"
                    },
                    {
                        "name": "Xã Hải An",
                        "code": 19684,
                        "codename": "xa_hai_an",
                        "division_type": "xã",
                        "short_codename": "hai_an"
                    },
                    {
                        "name": "Xã Hải Ba",
                        "code": 19687,
                        "codename": "xa_hai_ba",
                        "division_type": "xã",
                        "short_codename": "hai_ba"
                    },
                    {
                        "name": "Xã Hải Quy",
                        "code": 19693,
                        "codename": "xa_hai_quy",
                        "division_type": "xã",
                        "short_codename": "hai_quy"
                    },
                    {
                        "name": "Xã Hải Quế",
                        "code": 19696,
                        "codename": "xa_hai_que",
                        "division_type": "xã",
                        "short_codename": "hai_que"
                    },
                    {
                        "name": "Xã Hải Hưng",
                        "code": 19699,
                        "codename": "xa_hai_hung",
                        "division_type": "xã",
                        "short_codename": "hai_hung"
                    },
                    {
                        "name": "Xã Hải Phú",
                        "code": 19702,
                        "codename": "xa_hai_phu",
                        "division_type": "xã",
                        "short_codename": "hai_phu"
                    },
                    {
                        "name": "Xã Hải Thượng",
                        "code": 19708,
                        "codename": "xa_hai_thuong",
                        "division_type": "xã",
                        "short_codename": "hai_thuong"
                    },
                    {
                        "name": "Xã Hải Dương",
                        "code": 19711,
                        "codename": "xa_hai_duong",
                        "division_type": "xã",
                        "short_codename": "hai_duong"
                    },
                    {
                        "name": "Xã Hải Định",
                        "code": 19714,
                        "codename": "xa_hai_dinh",
                        "division_type": "xã",
                        "short_codename": "hai_dinh"
                    },
                    {
                        "name": "Xã Hải Lâm",
                        "code": 19717,
                        "codename": "xa_hai_lam",
                        "division_type": "xã",
                        "short_codename": "hai_lam"
                    },
                    {
                        "name": "Xã Hải Phong",
                        "code": 19726,
                        "codename": "xa_hai_phong",
                        "division_type": "xã",
                        "short_codename": "hai_phong"
                    },
                    {
                        "name": "Xã Hải Trường",
                        "code": 19729,
                        "codename": "xa_hai_truong",
                        "division_type": "xã",
                        "short_codename": "hai_truong"
                    },
                    {
                        "name": "Xã Hải Sơn",
                        "code": 19735,
                        "codename": "xa_hai_son",
                        "division_type": "xã",
                        "short_codename": "hai_son"
                    },
                    {
                        "name": "Xã Hải Chánh",
                        "code": 19738,
                        "codename": "xa_hai_chanh",
                        "division_type": "xã",
                        "short_codename": "hai_chanh"
                    },
                    {
                        "name": "Xã Hải Khê",
                        "code": 19741,
                        "codename": "xa_hai_khe",
                        "division_type": "xã",
                        "short_codename": "hai_khe"
                    }
                ]
            },
            {
                "name": "Huyện Cồn Cỏ",
                "code": 471,
                "codename": "huyen_con_co",
                "division_type": "huyện",
                "short_codename": "con_co",
                "wards": []
            }
        ]
    },
    {
        "name": "Tỉnh Thừa Thiên Huế",
        "code": 46,
        "codename": "tinh_thua_thien_hue",
        "division_type": "tỉnh",
        "phone_code": 234,
        "districts": [
            {
                "name": "Thành phố Huế",
                "code": 474,
                "codename": "thanh_pho_hue",
                "division_type": "thành phố",
                "short_codename": "hue",
                "wards": [
                    {
                        "name": "Phường Phú Thuận",
                        "code": 19744,
                        "codename": "phuong_phu_thuan",
                        "division_type": "phường",
                        "short_codename": "phu_thuan"
                    },
                    {
                        "name": "Phường Phú Bình",
                        "code": 19747,
                        "codename": "phuong_phu_binh",
                        "division_type": "phường",
                        "short_codename": "phu_binh"
                    },
                    {
                        "name": "Phường Tây Lộc",
                        "code": 19750,
                        "codename": "phuong_tay_loc",
                        "division_type": "phường",
                        "short_codename": "tay_loc"
                    },
                    {
                        "name": "Phường Thuận Lộc",
                        "code": 19753,
                        "codename": "phuong_thuan_loc",
                        "division_type": "phường",
                        "short_codename": "thuan_loc"
                    },
                    {
                        "name": "Phường Phú Hiệp",
                        "code": 19756,
                        "codename": "phuong_phu_hiep",
                        "division_type": "phường",
                        "short_codename": "phu_hiep"
                    },
                    {
                        "name": "Phường Phú Hậu",
                        "code": 19759,
                        "codename": "phuong_phu_hau",
                        "division_type": "phường",
                        "short_codename": "phu_hau"
                    },
                    {
                        "name": "Phường Thuận Hòa",
                        "code": 19762,
                        "codename": "phuong_thuan_hoa",
                        "division_type": "phường",
                        "short_codename": "thuan_hoa"
                    },
                    {
                        "name": "Phường Thuận Thành",
                        "code": 19765,
                        "codename": "phuong_thuan_thanh",
                        "division_type": "phường",
                        "short_codename": "thuan_thanh"
                    },
                    {
                        "name": "Phường Phú Hòa",
                        "code": 19768,
                        "codename": "phuong_phu_hoa",
                        "division_type": "phường",
                        "short_codename": "phu_hoa"
                    },
                    {
                        "name": "Phường Phú Cát",
                        "code": 19771,
                        "codename": "phuong_phu_cat",
                        "division_type": "phường",
                        "short_codename": "phu_cat"
                    },
                    {
                        "name": "Phường Kim Long",
                        "code": 19774,
                        "codename": "phuong_kim_long",
                        "division_type": "phường",
                        "short_codename": "kim_long"
                    },
                    {
                        "name": "Phường Vỹ Dạ",
                        "code": 19777,
                        "codename": "phuong_vy_da",
                        "division_type": "phường",
                        "short_codename": "vy_da"
                    },
                    {
                        "name": "Phường Phường Đúc",
                        "code": 19780,
                        "codename": "phuong_phuong_duc",
                        "division_type": "phường",
                        "short_codename": "phuong_duc"
                    },
                    {
                        "name": "Phường Vĩnh Ninh",
                        "code": 19783,
                        "codename": "phuong_vinh_ninh",
                        "division_type": "phường",
                        "short_codename": "vinh_ninh"
                    },
                    {
                        "name": "Phường Phú Hội",
                        "code": 19786,
                        "codename": "phuong_phu_hoi",
                        "division_type": "phường",
                        "short_codename": "phu_hoi"
                    },
                    {
                        "name": "Phường Phú Nhuận",
                        "code": 19789,
                        "codename": "phuong_phu_nhuan",
                        "division_type": "phường",
                        "short_codename": "phu_nhuan"
                    },
                    {
                        "name": "Phường Xuân Phú",
                        "code": 19792,
                        "codename": "phuong_xuan_phu",
                        "division_type": "phường",
                        "short_codename": "xuan_phu"
                    },
                    {
                        "name": "Phường Trường An",
                        "code": 19795,
                        "codename": "phuong_truong_an",
                        "division_type": "phường",
                        "short_codename": "truong_an"
                    },
                    {
                        "name": "Phường Phước Vĩnh",
                        "code": 19798,
                        "codename": "phuong_phuoc_vinh",
                        "division_type": "phường",
                        "short_codename": "phuoc_vinh"
                    },
                    {
                        "name": "Phường An Cựu",
                        "code": 19801,
                        "codename": "phuong_an_cuu",
                        "division_type": "phường",
                        "short_codename": "an_cuu"
                    },
                    {
                        "name": "Phường An Hòa",
                        "code": 19803,
                        "codename": "phuong_an_hoa",
                        "division_type": "phường",
                        "short_codename": "an_hoa"
                    },
                    {
                        "name": "Phường Hương Sơ",
                        "code": 19804,
                        "codename": "phuong_huong_so",
                        "division_type": "phường",
                        "short_codename": "huong_so"
                    },
                    {
                        "name": "Phường Thuỷ Biều",
                        "code": 19807,
                        "codename": "phuong_thuy_bieu",
                        "division_type": "phường",
                        "short_codename": "thuy_bieu"
                    },
                    {
                        "name": "Phường Hương Long",
                        "code": 19810,
                        "codename": "phuong_huong_long",
                        "division_type": "phường",
                        "short_codename": "huong_long"
                    },
                    {
                        "name": "Phường Thuỷ Xuân",
                        "code": 19813,
                        "codename": "phuong_thuy_xuan",
                        "division_type": "phường",
                        "short_codename": "thuy_xuan"
                    },
                    {
                        "name": "Phường An Đông",
                        "code": 19815,
                        "codename": "phuong_an_dong",
                        "division_type": "phường",
                        "short_codename": "an_dong"
                    },
                    {
                        "name": "Phường An Tây",
                        "code": 19816,
                        "codename": "phuong_an_tay",
                        "division_type": "phường",
                        "short_codename": "an_tay"
                    }
                ]
            },
            {
                "name": "Huyện Phong Điền",
                "code": 476,
                "codename": "huyen_phong_dien",
                "division_type": "huyện",
                "short_codename": "phong_dien",
                "wards": [
                    {
                        "name": "Thị trấn Phong Điền",
                        "code": 19819,
                        "codename": "thi_tran_phong_dien",
                        "division_type": "thị trấn",
                        "short_codename": "phong_dien"
                    },
                    {
                        "name": "Xã Điền Hương",
                        "code": 19822,
                        "codename": "xa_dien_huong",
                        "division_type": "xã",
                        "short_codename": "dien_huong"
                    },
                    {
                        "name": "Xã Điền Môn",
                        "code": 19825,
                        "codename": "xa_dien_mon",
                        "division_type": "xã",
                        "short_codename": "dien_mon"
                    },
                    {
                        "name": "Xã Điền Lộc",
                        "code": 19828,
                        "codename": "xa_dien_loc",
                        "division_type": "xã",
                        "short_codename": "dien_loc"
                    },
                    {
                        "name": "Xã Phong Bình",
                        "code": 19831,
                        "codename": "xa_phong_binh",
                        "division_type": "xã",
                        "short_codename": "phong_binh"
                    },
                    {
                        "name": "Xã Điền Hòa",
                        "code": 19834,
                        "codename": "xa_dien_hoa",
                        "division_type": "xã",
                        "short_codename": "dien_hoa"
                    },
                    {
                        "name": "Xã Phong Chương",
                        "code": 19837,
                        "codename": "xa_phong_chuong",
                        "division_type": "xã",
                        "short_codename": "phong_chuong"
                    },
                    {
                        "name": "Xã Phong Hải",
                        "code": 19840,
                        "codename": "xa_phong_hai",
                        "division_type": "xã",
                        "short_codename": "phong_hai"
                    },
                    {
                        "name": "Xã Điền Hải",
                        "code": 19843,
                        "codename": "xa_dien_hai",
                        "division_type": "xã",
                        "short_codename": "dien_hai"
                    },
                    {
                        "name": "Xã Phong Hòa",
                        "code": 19846,
                        "codename": "xa_phong_hoa",
                        "division_type": "xã",
                        "short_codename": "phong_hoa"
                    },
                    {
                        "name": "Xã Phong Thu",
                        "code": 19849,
                        "codename": "xa_phong_thu",
                        "division_type": "xã",
                        "short_codename": "phong_thu"
                    },
                    {
                        "name": "Xã Phong Hiền",
                        "code": 19852,
                        "codename": "xa_phong_hien",
                        "division_type": "xã",
                        "short_codename": "phong_hien"
                    },
                    {
                        "name": "Xã Phong Mỹ",
                        "code": 19855,
                        "codename": "xa_phong_my",
                        "division_type": "xã",
                        "short_codename": "phong_my"
                    },
                    {
                        "name": "Xã Phong An",
                        "code": 19858,
                        "codename": "xa_phong_an",
                        "division_type": "xã",
                        "short_codename": "phong_an"
                    },
                    {
                        "name": "Xã Phong Xuân",
                        "code": 19861,
                        "codename": "xa_phong_xuan",
                        "division_type": "xã",
                        "short_codename": "phong_xuan"
                    },
                    {
                        "name": "Xã Phong Sơn",
                        "code": 19864,
                        "codename": "xa_phong_son",
                        "division_type": "xã",
                        "short_codename": "phong_son"
                    }
                ]
            },
            {
                "name": "Huyện Quảng Điền",
                "code": 477,
                "codename": "huyen_quang_dien",
                "division_type": "huyện",
                "short_codename": "quang_dien",
                "wards": [
                    {
                        "name": "Thị trấn Sịa",
                        "code": 19867,
                        "codename": "thi_tran_sia",
                        "division_type": "thị trấn",
                        "short_codename": "sia"
                    },
                    {
                        "name": "Xã Quảng Thái",
                        "code": 19870,
                        "codename": "xa_quang_thai",
                        "division_type": "xã",
                        "short_codename": "quang_thai"
                    },
                    {
                        "name": "Xã Quảng Ngạn",
                        "code": 19873,
                        "codename": "xa_quang_ngan",
                        "division_type": "xã",
                        "short_codename": "quang_ngan"
                    },
                    {
                        "name": "Xã Quảng Lợi",
                        "code": 19876,
                        "codename": "xa_quang_loi",
                        "division_type": "xã",
                        "short_codename": "quang_loi"
                    },
                    {
                        "name": "Xã Quảng Công",
                        "code": 19879,
                        "codename": "xa_quang_cong",
                        "division_type": "xã",
                        "short_codename": "quang_cong"
                    },
                    {
                        "name": "Xã Quảng Phước",
                        "code": 19882,
                        "codename": "xa_quang_phuoc",
                        "division_type": "xã",
                        "short_codename": "quang_phuoc"
                    },
                    {
                        "name": "Xã Quảng Vinh",
                        "code": 19885,
                        "codename": "xa_quang_vinh",
                        "division_type": "xã",
                        "short_codename": "quang_vinh"
                    },
                    {
                        "name": "Xã Quảng An",
                        "code": 19888,
                        "codename": "xa_quang_an",
                        "division_type": "xã",
                        "short_codename": "quang_an"
                    },
                    {
                        "name": "Xã Quảng Thành",
                        "code": 19891,
                        "codename": "xa_quang_thanh",
                        "division_type": "xã",
                        "short_codename": "quang_thanh"
                    },
                    {
                        "name": "Xã Quảng Thọ",
                        "code": 19894,
                        "codename": "xa_quang_tho",
                        "division_type": "xã",
                        "short_codename": "quang_tho"
                    },
                    {
                        "name": "Xã Quảng Phú",
                        "code": 19897,
                        "codename": "xa_quang_phu",
                        "division_type": "xã",
                        "short_codename": "quang_phu"
                    }
                ]
            },
            {
                "name": "Huyện Phú Vang",
                "code": 478,
                "codename": "huyen_phu_vang",
                "division_type": "huyện",
                "short_codename": "phu_vang",
                "wards": [
                    {
                        "name": "Thị trấn Thuận An",
                        "code": 19900,
                        "codename": "thi_tran_thuan_an",
                        "division_type": "thị trấn",
                        "short_codename": "thuan_an"
                    },
                    {
                        "name": "Xã Phú Thuận",
                        "code": 19903,
                        "codename": "xa_phu_thuan",
                        "division_type": "xã",
                        "short_codename": "phu_thuan"
                    },
                    {
                        "name": "Xã Phú Dương",
                        "code": 19906,
                        "codename": "xa_phu_duong",
                        "division_type": "xã",
                        "short_codename": "phu_duong"
                    },
                    {
                        "name": "Xã Phú Mậu",
                        "code": 19909,
                        "codename": "xa_phu_mau",
                        "division_type": "xã",
                        "short_codename": "phu_mau"
                    },
                    {
                        "name": "Xã Phú An",
                        "code": 19912,
                        "codename": "xa_phu_an",
                        "division_type": "xã",
                        "short_codename": "phu_an"
                    },
                    {
                        "name": "Xã Phú Hải",
                        "code": 19915,
                        "codename": "xa_phu_hai",
                        "division_type": "xã",
                        "short_codename": "phu_hai"
                    },
                    {
                        "name": "Xã Phú Xuân",
                        "code": 19918,
                        "codename": "xa_phu_xuan",
                        "division_type": "xã",
                        "short_codename": "phu_xuan"
                    },
                    {
                        "name": "Xã Phú Diên",
                        "code": 19921,
                        "codename": "xa_phu_dien",
                        "division_type": "xã",
                        "short_codename": "phu_dien"
                    },
                    {
                        "name": "Xã Phú Thanh",
                        "code": 19924,
                        "codename": "xa_phu_thanh",
                        "division_type": "xã",
                        "short_codename": "phu_thanh"
                    },
                    {
                        "name": "Xã Phú Mỹ",
                        "code": 19927,
                        "codename": "xa_phu_my",
                        "division_type": "xã",
                        "short_codename": "phu_my"
                    },
                    {
                        "name": "Xã Phú Thượng",
                        "code": 19930,
                        "codename": "xa_phu_thuong",
                        "division_type": "xã",
                        "short_codename": "phu_thuong"
                    },
                    {
                        "name": "Xã Phú Hồ",
                        "code": 19933,
                        "codename": "xa_phu_ho",
                        "division_type": "xã",
                        "short_codename": "phu_ho"
                    },
                    {
                        "name": "Xã Vinh Xuân",
                        "code": 19936,
                        "codename": "xa_vinh_xuan",
                        "division_type": "xã",
                        "short_codename": "vinh_xuan"
                    },
                    {
                        "name": "Xã Phú Lương",
                        "code": 19939,
                        "codename": "xa_phu_luong",
                        "division_type": "xã",
                        "short_codename": "phu_luong"
                    },
                    {
                        "name": "Thị trấn Phú Đa",
                        "code": 19942,
                        "codename": "thi_tran_phu_da",
                        "division_type": "thị trấn",
                        "short_codename": "phu_da"
                    },
                    {
                        "name": "Xã Vinh Thanh",
                        "code": 19945,
                        "codename": "xa_vinh_thanh",
                        "division_type": "xã",
                        "short_codename": "vinh_thanh"
                    },
                    {
                        "name": "Xã Vinh An",
                        "code": 19948,
                        "codename": "xa_vinh_an",
                        "division_type": "xã",
                        "short_codename": "vinh_an"
                    },
                    {
                        "name": "Xã Phú Gia",
                        "code": 19954,
                        "codename": "xa_phu_gia",
                        "division_type": "xã",
                        "short_codename": "phu_gia"
                    },
                    {
                        "name": "Xã Vinh Hà",
                        "code": 19957,
                        "codename": "xa_vinh_ha",
                        "division_type": "xã",
                        "short_codename": "vinh_ha"
                    }
                ]
            },
            {
                "name": "Thị xã Hương Thủy",
                "code": 479,
                "codename": "thi_xa_huong_thuy",
                "division_type": "thị xã",
                "short_codename": "huong_thuy",
                "wards": [
                    {
                        "name": "Phường Phú Bài",
                        "code": 19960,
                        "codename": "phuong_phu_bai",
                        "division_type": "phường",
                        "short_codename": "phu_bai"
                    },
                    {
                        "name": "Xã Thủy Vân",
                        "code": 19963,
                        "codename": "xa_thuy_van",
                        "division_type": "xã",
                        "short_codename": "thuy_van"
                    },
                    {
                        "name": "Xã Thủy Thanh",
                        "code": 19966,
                        "codename": "xa_thuy_thanh",
                        "division_type": "xã",
                        "short_codename": "thuy_thanh"
                    },
                    {
                        "name": "Phường Thủy Dương",
                        "code": 19969,
                        "codename": "phuong_thuy_duong",
                        "division_type": "phường",
                        "short_codename": "thuy_duong"
                    },
                    {
                        "name": "Phường Thủy Phương",
                        "code": 19972,
                        "codename": "phuong_thuy_phuong",
                        "division_type": "phường",
                        "short_codename": "thuy_phuong"
                    },
                    {
                        "name": "Phường Thủy Châu",
                        "code": 19975,
                        "codename": "phuong_thuy_chau",
                        "division_type": "phường",
                        "short_codename": "thuy_chau"
                    },
                    {
                        "name": "Phường Thủy Lương",
                        "code": 19978,
                        "codename": "phuong_thuy_luong",
                        "division_type": "phường",
                        "short_codename": "thuy_luong"
                    },
                    {
                        "name": "Xã Thủy Bằng",
                        "code": 19981,
                        "codename": "xa_thuy_bang",
                        "division_type": "xã",
                        "short_codename": "thuy_bang"
                    },
                    {
                        "name": "Xã Thủy Tân",
                        "code": 19984,
                        "codename": "xa_thuy_tan",
                        "division_type": "xã",
                        "short_codename": "thuy_tan"
                    },
                    {
                        "name": "Xã Thủy Phù",
                        "code": 19987,
                        "codename": "xa_thuy_phu",
                        "division_type": "xã",
                        "short_codename": "thuy_phu"
                    },
                    {
                        "name": "Xã Phú Sơn",
                        "code": 19990,
                        "codename": "xa_phu_son",
                        "division_type": "xã",
                        "short_codename": "phu_son"
                    },
                    {
                        "name": "Xã Dương Hòa",
                        "code": 19993,
                        "codename": "xa_duong_hoa",
                        "division_type": "xã",
                        "short_codename": "duong_hoa"
                    }
                ]
            },
            {
                "name": "Thị xã Hương Trà",
                "code": 480,
                "codename": "thi_xa_huong_tra",
                "division_type": "thị xã",
                "short_codename": "huong_tra",
                "wards": [
                    {
                        "name": "Phường Tứ Hạ",
                        "code": 19996,
                        "codename": "phuong_tu_ha",
                        "division_type": "phường",
                        "short_codename": "tu_ha"
                    },
                    {
                        "name": "Xã Hải Dương",
                        "code": 19999,
                        "codename": "xa_hai_duong",
                        "division_type": "xã",
                        "short_codename": "hai_duong"
                    },
                    {
                        "name": "Xã Hương Phong",
                        "code": 20002,
                        "codename": "xa_huong_phong",
                        "division_type": "xã",
                        "short_codename": "huong_phong"
                    },
                    {
                        "name": "Xã Hương Toàn",
                        "code": 20005,
                        "codename": "xa_huong_toan",
                        "division_type": "xã",
                        "short_codename": "huong_toan"
                    },
                    {
                        "name": "Phường Hương Vân",
                        "code": 20008,
                        "codename": "phuong_huong_van",
                        "division_type": "phường",
                        "short_codename": "huong_van"
                    },
                    {
                        "name": "Phường Hương Văn",
                        "code": 20011,
                        "codename": "phuong_huong_van",
                        "division_type": "phường",
                        "short_codename": "huong_van"
                    },
                    {
                        "name": "Xã Hương Vinh",
                        "code": 20014,
                        "codename": "xa_huong_vinh",
                        "division_type": "xã",
                        "short_codename": "huong_vinh"
                    },
                    {
                        "name": "Phường Hương Xuân",
                        "code": 20017,
                        "codename": "phuong_huong_xuan",
                        "division_type": "phường",
                        "short_codename": "huong_xuan"
                    },
                    {
                        "name": "Phường Hương Chữ",
                        "code": 20020,
                        "codename": "phuong_huong_chu",
                        "division_type": "phường",
                        "short_codename": "huong_chu"
                    },
                    {
                        "name": "Phường Hương An",
                        "code": 20023,
                        "codename": "phuong_huong_an",
                        "division_type": "phường",
                        "short_codename": "huong_an"
                    },
                    {
                        "name": "Xã Hương Bình",
                        "code": 20026,
                        "codename": "xa_huong_binh",
                        "division_type": "xã",
                        "short_codename": "huong_binh"
                    },
                    {
                        "name": "Phường Hương Hồ",
                        "code": 20029,
                        "codename": "phuong_huong_ho",
                        "division_type": "phường",
                        "short_codename": "huong_ho"
                    },
                    {
                        "name": "Xã Hương Thọ",
                        "code": 20032,
                        "codename": "xa_huong_tho",
                        "division_type": "xã",
                        "short_codename": "huong_tho"
                    },
                    {
                        "name": "Xã Bình Tiến",
                        "code": 20035,
                        "codename": "xa_binh_tien",
                        "division_type": "xã",
                        "short_codename": "binh_tien"
                    },
                    {
                        "name": "Xã Bình Thành",
                        "code": 20041,
                        "codename": "xa_binh_thanh",
                        "division_type": "xã",
                        "short_codename": "binh_thanh"
                    }
                ]
            },
            {
                "name": "Huyện A Lưới",
                "code": 481,
                "codename": "huyen_a_luoi",
                "division_type": "huyện",
                "short_codename": "a_luoi",
                "wards": [
                    {
                        "name": "Thị trấn A Lưới",
                        "code": 20044,
                        "codename": "thi_tran_a_luoi",
                        "division_type": "thị trấn",
                        "short_codename": "a_luoi"
                    },
                    {
                        "name": "Xã Hồng Vân",
                        "code": 20047,
                        "codename": "xa_hong_van",
                        "division_type": "xã",
                        "short_codename": "hong_van"
                    },
                    {
                        "name": "Xã Hồng Hạ",
                        "code": 20050,
                        "codename": "xa_hong_ha",
                        "division_type": "xã",
                        "short_codename": "hong_ha"
                    },
                    {
                        "name": "Xã Hồng Kim",
                        "code": 20053,
                        "codename": "xa_hong_kim",
                        "division_type": "xã",
                        "short_codename": "hong_kim"
                    },
                    {
                        "name": "Xã Trung Sơn",
                        "code": 20056,
                        "codename": "xa_trung_son",
                        "division_type": "xã",
                        "short_codename": "trung_son"
                    },
                    {
                        "name": "Xã Hương Nguyên",
                        "code": 20059,
                        "codename": "xa_huong_nguyen",
                        "division_type": "xã",
                        "short_codename": "huong_nguyen"
                    },
                    {
                        "name": "Xã Hồng Bắc",
                        "code": 20065,
                        "codename": "xa_hong_bac",
                        "division_type": "xã",
                        "short_codename": "hong_bac"
                    },
                    {
                        "name": "Xã A Ngo",
                        "code": 20068,
                        "codename": "xa_a_ngo",
                        "division_type": "xã",
                        "short_codename": "a_ngo"
                    },
                    {
                        "name": "Xã Sơn Thủy",
                        "code": 20071,
                        "codename": "xa_son_thuy",
                        "division_type": "xã",
                        "short_codename": "son_thuy"
                    },
                    {
                        "name": "Xã Phú Vinh",
                        "code": 20074,
                        "codename": "xa_phu_vinh",
                        "division_type": "xã",
                        "short_codename": "phu_vinh"
                    },
                    {
                        "name": "Xã Hương Phong",
                        "code": 20080,
                        "codename": "xa_huong_phong",
                        "division_type": "xã",
                        "short_codename": "huong_phong"
                    },
                    {
                        "name": "Xã Quảng Nhâm",
                        "code": 20083,
                        "codename": "xa_quang_nham",
                        "division_type": "xã",
                        "short_codename": "quang_nham"
                    },
                    {
                        "name": "Xã Hồng Thượng",
                        "code": 20086,
                        "codename": "xa_hong_thuong",
                        "division_type": "xã",
                        "short_codename": "hong_thuong"
                    },
                    {
                        "name": "Xã Hồng Thái",
                        "code": 20089,
                        "codename": "xa_hong_thai",
                        "division_type": "xã",
                        "short_codename": "hong_thai"
                    },
                    {
                        "name": "Xã A Roàng",
                        "code": 20095,
                        "codename": "xa_a_roang",
                        "division_type": "xã",
                        "short_codename": "a_roang"
                    },
                    {
                        "name": "Xã Đông Sơn",
                        "code": 20098,
                        "codename": "xa_dong_son",
                        "division_type": "xã",
                        "short_codename": "dong_son"
                    },
                    {
                        "name": "Xã Lâm Đớt",
                        "code": 20101,
                        "codename": "xa_lam_dot",
                        "division_type": "xã",
                        "short_codename": "lam_dot"
                    },
                    {
                        "name": "Xã Hồng Thủy",
                        "code": 20104,
                        "codename": "xa_hong_thuy",
                        "division_type": "xã",
                        "short_codename": "hong_thuy"
                    }
                ]
            },
            {
                "name": "Huyện Phú Lộc",
                "code": 482,
                "codename": "huyen_phu_loc",
                "division_type": "huyện",
                "short_codename": "phu_loc",
                "wards": [
                    {
                        "name": "Thị trấn Phú Lộc",
                        "code": 20107,
                        "codename": "thi_tran_phu_loc",
                        "division_type": "thị trấn",
                        "short_codename": "phu_loc"
                    },
                    {
                        "name": "Thị trấn Lăng Cô",
                        "code": 20110,
                        "codename": "thi_tran_lang_co",
                        "division_type": "thị trấn",
                        "short_codename": "lang_co"
                    },
                    {
                        "name": "Xã Vinh Mỹ",
                        "code": 20113,
                        "codename": "xa_vinh_my",
                        "division_type": "xã",
                        "short_codename": "vinh_my"
                    },
                    {
                        "name": "Xã Vinh Hưng",
                        "code": 20116,
                        "codename": "xa_vinh_hung",
                        "division_type": "xã",
                        "short_codename": "vinh_hung"
                    },
                    {
                        "name": "Xã Giang Hải",
                        "code": 20122,
                        "codename": "xa_giang_hai",
                        "division_type": "xã",
                        "short_codename": "giang_hai"
                    },
                    {
                        "name": "Xã Vinh Hiền",
                        "code": 20125,
                        "codename": "xa_vinh_hien",
                        "division_type": "xã",
                        "short_codename": "vinh_hien"
                    },
                    {
                        "name": "Xã Lộc Bổn",
                        "code": 20128,
                        "codename": "xa_loc_bon",
                        "division_type": "xã",
                        "short_codename": "loc_bon"
                    },
                    {
                        "name": "Xã Lộc Sơn",
                        "code": 20131,
                        "codename": "xa_loc_son",
                        "division_type": "xã",
                        "short_codename": "loc_son"
                    },
                    {
                        "name": "Xã Lộc Bình",
                        "code": 20134,
                        "codename": "xa_loc_binh",
                        "division_type": "xã",
                        "short_codename": "loc_binh"
                    },
                    {
                        "name": "Xã Lộc Vĩnh",
                        "code": 20137,
                        "codename": "xa_loc_vinh",
                        "division_type": "xã",
                        "short_codename": "loc_vinh"
                    },
                    {
                        "name": "Xã Lộc An",
                        "code": 20140,
                        "codename": "xa_loc_an",
                        "division_type": "xã",
                        "short_codename": "loc_an"
                    },
                    {
                        "name": "Xã Lộc Điền",
                        "code": 20143,
                        "codename": "xa_loc_dien",
                        "division_type": "xã",
                        "short_codename": "loc_dien"
                    },
                    {
                        "name": "Xã Lộc Thủy",
                        "code": 20146,
                        "codename": "xa_loc_thuy",
                        "division_type": "xã",
                        "short_codename": "loc_thuy"
                    },
                    {
                        "name": "Xã Lộc Trì",
                        "code": 20149,
                        "codename": "xa_loc_tri",
                        "division_type": "xã",
                        "short_codename": "loc_tri"
                    },
                    {
                        "name": "Xã Lộc Tiến",
                        "code": 20152,
                        "codename": "xa_loc_tien",
                        "division_type": "xã",
                        "short_codename": "loc_tien"
                    },
                    {
                        "name": "Xã Lộc Hòa",
                        "code": 20155,
                        "codename": "xa_loc_hoa",
                        "division_type": "xã",
                        "short_codename": "loc_hoa"
                    },
                    {
                        "name": "Xã Xuân Lộc",
                        "code": 20158,
                        "codename": "xa_xuan_loc",
                        "division_type": "xã",
                        "short_codename": "xuan_loc"
                    }
                ]
            },
            {
                "name": "Huyện Nam Đông",
                "code": 483,
                "codename": "huyen_nam_dong",
                "division_type": "huyện",
                "short_codename": "nam_dong",
                "wards": [
                    {
                        "name": "Thị trấn Khe Tre",
                        "code": 20161,
                        "codename": "thi_tran_khe_tre",
                        "division_type": "thị trấn",
                        "short_codename": "khe_tre"
                    },
                    {
                        "name": "Xã Hương Phú",
                        "code": 20164,
                        "codename": "xa_huong_phu",
                        "division_type": "xã",
                        "short_codename": "huong_phu"
                    },
                    {
                        "name": "Xã Hương Sơn",
                        "code": 20167,
                        "codename": "xa_huong_son",
                        "division_type": "xã",
                        "short_codename": "huong_son"
                    },
                    {
                        "name": "Xã Hương Lộc",
                        "code": 20170,
                        "codename": "xa_huong_loc",
                        "division_type": "xã",
                        "short_codename": "huong_loc"
                    },
                    {
                        "name": "Xã Thượng Quảng",
                        "code": 20173,
                        "codename": "xa_thuong_quang",
                        "division_type": "xã",
                        "short_codename": "thuong_quang"
                    },
                    {
                        "name": "Xã Hương Xuân",
                        "code": 20179,
                        "codename": "xa_huong_xuan",
                        "division_type": "xã",
                        "short_codename": "huong_xuan"
                    },
                    {
                        "name": "Xã Hương Hữu",
                        "code": 20182,
                        "codename": "xa_huong_huu",
                        "division_type": "xã",
                        "short_codename": "huong_huu"
                    },
                    {
                        "name": "Xã Thượng Lộ",
                        "code": 20185,
                        "codename": "xa_thuong_lo",
                        "division_type": "xã",
                        "short_codename": "thuong_lo"
                    },
                    {
                        "name": "Xã Thượng Long",
                        "code": 20188,
                        "codename": "xa_thuong_long",
                        "division_type": "xã",
                        "short_codename": "thuong_long"
                    },
                    {
                        "name": "Xã Thượng Nhật",
                        "code": 20191,
                        "codename": "xa_thuong_nhat",
                        "division_type": "xã",
                        "short_codename": "thuong_nhat"
                    }
                ]
            }
        ]
    },
    {
        "name": "Thành phố Đà Nẵng",
        "code": 48,
        "codename": "thanh_pho_da_nang",
        "division_type": "thành phố trung ương",
        "phone_code": 236,
        "districts": [
            {
                "name": "Quận Liên Chiểu",
                "code": 490,
                "codename": "quan_lien_chieu",
                "division_type": "quận",
                "short_codename": "lien_chieu",
                "wards": [
                    {
                        "name": "Phường Hòa Hiệp Bắc",
                        "code": 20194,
                        "codename": "phuong_hoa_hiep_bac",
                        "division_type": "phường",
                        "short_codename": "hoa_hiep_bac"
                    },
                    {
                        "name": "Phường Hòa Hiệp Nam",
                        "code": 20195,
                        "codename": "phuong_hoa_hiep_nam",
                        "division_type": "phường",
                        "short_codename": "hoa_hiep_nam"
                    },
                    {
                        "name": "Phường Hòa Khánh Bắc",
                        "code": 20197,
                        "codename": "phuong_hoa_khanh_bac",
                        "division_type": "phường",
                        "short_codename": "hoa_khanh_bac"
                    },
                    {
                        "name": "Phường Hòa Khánh Nam",
                        "code": 20198,
                        "codename": "phuong_hoa_khanh_nam",
                        "division_type": "phường",
                        "short_codename": "hoa_khanh_nam"
                    },
                    {
                        "name": "Phường Hòa Minh",
                        "code": 20200,
                        "codename": "phuong_hoa_minh",
                        "division_type": "phường",
                        "short_codename": "hoa_minh"
                    }
                ]
            },
            {
                "name": "Quận Thanh Khê",
                "code": 491,
                "codename": "quan_thanh_khe",
                "division_type": "quận",
                "short_codename": "thanh_khe",
                "wards": [
                    {
                        "name": "Phường Tam Thuận",
                        "code": 20203,
                        "codename": "phuong_tam_thuan",
                        "division_type": "phường",
                        "short_codename": "tam_thuan"
                    },
                    {
                        "name": "Phường Thanh Khê Tây",
                        "code": 20206,
                        "codename": "phuong_thanh_khe_tay",
                        "division_type": "phường",
                        "short_codename": "thanh_khe_tay"
                    },
                    {
                        "name": "Phường Thanh Khê Đông",
                        "code": 20207,
                        "codename": "phuong_thanh_khe_dong",
                        "division_type": "phường",
                        "short_codename": "thanh_khe_dong"
                    },
                    {
                        "name": "Phường Xuân Hà",
                        "code": 20209,
                        "codename": "phuong_xuan_ha",
                        "division_type": "phường",
                        "short_codename": "xuan_ha"
                    },
                    {
                        "name": "Phường Tân Chính",
                        "code": 20212,
                        "codename": "phuong_tan_chinh",
                        "division_type": "phường",
                        "short_codename": "tan_chinh"
                    },
                    {
                        "name": "Phường Chính Gián",
                        "code": 20215,
                        "codename": "phuong_chinh_gian",
                        "division_type": "phường",
                        "short_codename": "chinh_gian"
                    },
                    {
                        "name": "Phường Vĩnh Trung",
                        "code": 20218,
                        "codename": "phuong_vinh_trung",
                        "division_type": "phường",
                        "short_codename": "vinh_trung"
                    },
                    {
                        "name": "Phường Thạc Gián",
                        "code": 20221,
                        "codename": "phuong_thac_gian",
                        "division_type": "phường",
                        "short_codename": "thac_gian"
                    },
                    {
                        "name": "Phường An Khê",
                        "code": 20224,
                        "codename": "phuong_an_khe",
                        "division_type": "phường",
                        "short_codename": "an_khe"
                    },
                    {
                        "name": "Phường Hòa Khê",
                        "code": 20225,
                        "codename": "phuong_hoa_khe",
                        "division_type": "phường",
                        "short_codename": "hoa_khe"
                    }
                ]
            },
            {
                "name": "Quận Hải Châu",
                "code": 492,
                "codename": "quan_hai_chau",
                "division_type": "quận",
                "short_codename": "hai_chau",
                "wards": [
                    {
                        "name": "Phường Thanh Bình",
                        "code": 20227,
                        "codename": "phuong_thanh_binh",
                        "division_type": "phường",
                        "short_codename": "thanh_binh"
                    },
                    {
                        "name": "Phường Thuận Phước",
                        "code": 20230,
                        "codename": "phuong_thuan_phuoc",
                        "division_type": "phường",
                        "short_codename": "thuan_phuoc"
                    },
                    {
                        "name": "Phường Thạch Thang",
                        "code": 20233,
                        "codename": "phuong_thach_thang",
                        "division_type": "phường",
                        "short_codename": "thach_thang"
                    },
                    {
                        "name": "Phường Hải Châu I",
                        "code": 20236,
                        "codename": "phuong_hai_chau_i",
                        "division_type": "phường",
                        "short_codename": "hai_chau_i"
                    },
                    {
                        "name": "Phường Hải Châu II",
                        "code": 20239,
                        "codename": "phuong_hai_chau_ii",
                        "division_type": "phường",
                        "short_codename": "hai_chau_ii"
                    },
                    {
                        "name": "Phường Phước Ninh",
                        "code": 20242,
                        "codename": "phuong_phuoc_ninh",
                        "division_type": "phường",
                        "short_codename": "phuoc_ninh"
                    },
                    {
                        "name": "Phường Hòa Thuận Tây",
                        "code": 20245,
                        "codename": "phuong_hoa_thuan_tay",
                        "division_type": "phường",
                        "short_codename": "hoa_thuan_tay"
                    },
                    {
                        "name": "Phường Hòa Thuận Đông",
                        "code": 20246,
                        "codename": "phuong_hoa_thuan_dong",
                        "division_type": "phường",
                        "short_codename": "hoa_thuan_dong"
                    },
                    {
                        "name": "Phường Nam Dương",
                        "code": 20248,
                        "codename": "phuong_nam_duong",
                        "division_type": "phường",
                        "short_codename": "nam_duong"
                    },
                    {
                        "name": "Phường Bình Hiên",
                        "code": 20251,
                        "codename": "phuong_binh_hien",
                        "division_type": "phường",
                        "short_codename": "binh_hien"
                    },
                    {
                        "name": "Phường Bình Thuận",
                        "code": 20254,
                        "codename": "phuong_binh_thuan",
                        "division_type": "phường",
                        "short_codename": "binh_thuan"
                    },
                    {
                        "name": "Phường Hòa Cường Bắc",
                        "code": 20257,
                        "codename": "phuong_hoa_cuong_bac",
                        "division_type": "phường",
                        "short_codename": "hoa_cuong_bac"
                    },
                    {
                        "name": "Phường Hòa Cường Nam",
                        "code": 20258,
                        "codename": "phuong_hoa_cuong_nam",
                        "division_type": "phường",
                        "short_codename": "hoa_cuong_nam"
                    }
                ]
            },
            {
                "name": "Quận Sơn Trà",
                "code": 493,
                "codename": "quan_son_tra",
                "division_type": "quận",
                "short_codename": "son_tra",
                "wards": [
                    {
                        "name": "Phường Thọ Quang",
                        "code": 20263,
                        "codename": "phuong_tho_quang",
                        "division_type": "phường",
                        "short_codename": "tho_quang"
                    },
                    {
                        "name": "Phường Nại Hiên Đông",
                        "code": 20266,
                        "codename": "phuong_nai_hien_dong",
                        "division_type": "phường",
                        "short_codename": "nai_hien_dong"
                    },
                    {
                        "name": "Phường Mân Thái",
                        "code": 20269,
                        "codename": "phuong_man_thai",
                        "division_type": "phường",
                        "short_codename": "man_thai"
                    },
                    {
                        "name": "Phường An Hải Bắc",
                        "code": 20272,
                        "codename": "phuong_an_hai_bac",
                        "division_type": "phường",
                        "short_codename": "an_hai_bac"
                    },
                    {
                        "name": "Phường Phước Mỹ",
                        "code": 20275,
                        "codename": "phuong_phuoc_my",
                        "division_type": "phường",
                        "short_codename": "phuoc_my"
                    },
                    {
                        "name": "Phường An Hải Tây",
                        "code": 20278,
                        "codename": "phuong_an_hai_tay",
                        "division_type": "phường",
                        "short_codename": "an_hai_tay"
                    },
                    {
                        "name": "Phường An Hải Đông",
                        "code": 20281,
                        "codename": "phuong_an_hai_dong",
                        "division_type": "phường",
                        "short_codename": "an_hai_dong"
                    }
                ]
            },
            {
                "name": "Quận Ngũ Hành Sơn",
                "code": 494,
                "codename": "quan_ngu_hanh_son",
                "division_type": "quận",
                "short_codename": "ngu_hanh_son",
                "wards": [
                    {
                        "name": "Phường Mỹ An",
                        "code": 20284,
                        "codename": "phuong_my_an",
                        "division_type": "phường",
                        "short_codename": "my_an"
                    },
                    {
                        "name": "Phường Khuê Mỹ",
                        "code": 20285,
                        "codename": "phuong_khue_my",
                        "division_type": "phường",
                        "short_codename": "khue_my"
                    },
                    {
                        "name": "Phường Hoà Quý",
                        "code": 20287,
                        "codename": "phuong_hoa_quy",
                        "division_type": "phường",
                        "short_codename": "hoa_quy"
                    },
                    {
                        "name": "Phường Hoà Hải",
                        "code": 20290,
                        "codename": "phuong_hoa_hai",
                        "division_type": "phường",
                        "short_codename": "hoa_hai"
                    }
                ]
            },
            {
                "name": "Quận Cẩm Lệ",
                "code": 495,
                "codename": "quan_cam_le",
                "division_type": "quận",
                "short_codename": "cam_le",
                "wards": [
                    {
                        "name": "Phường Khuê Trung",
                        "code": 20260,
                        "codename": "phuong_khue_trung",
                        "division_type": "phường",
                        "short_codename": "khue_trung"
                    },
                    {
                        "name": "Phường Hòa Phát",
                        "code": 20305,
                        "codename": "phuong_hoa_phat",
                        "division_type": "phường",
                        "short_codename": "hoa_phat"
                    },
                    {
                        "name": "Phường Hòa An",
                        "code": 20306,
                        "codename": "phuong_hoa_an",
                        "division_type": "phường",
                        "short_codename": "hoa_an"
                    },
                    {
                        "name": "Phường Hòa Thọ Tây",
                        "code": 20311,
                        "codename": "phuong_hoa_tho_tay",
                        "division_type": "phường",
                        "short_codename": "hoa_tho_tay"
                    },
                    {
                        "name": "Phường Hòa Thọ Đông",
                        "code": 20312,
                        "codename": "phuong_hoa_tho_dong",
                        "division_type": "phường",
                        "short_codename": "hoa_tho_dong"
                    },
                    {
                        "name": "Phường Hòa Xuân",
                        "code": 20314,
                        "codename": "phuong_hoa_xuan",
                        "division_type": "phường",
                        "short_codename": "hoa_xuan"
                    }
                ]
            },
            {
                "name": "Huyện Hòa Vang",
                "code": 497,
                "codename": "huyen_hoa_vang",
                "division_type": "huyện",
                "short_codename": "hoa_vang",
                "wards": [
                    {
                        "name": "Xã Hòa Bắc",
                        "code": 20293,
                        "codename": "xa_hoa_bac",
                        "division_type": "xã",
                        "short_codename": "hoa_bac"
                    },
                    {
                        "name": "Xã Hòa Liên",
                        "code": 20296,
                        "codename": "xa_hoa_lien",
                        "division_type": "xã",
                        "short_codename": "hoa_lien"
                    },
                    {
                        "name": "Xã Hòa Ninh",
                        "code": 20299,
                        "codename": "xa_hoa_ninh",
                        "division_type": "xã",
                        "short_codename": "hoa_ninh"
                    },
                    {
                        "name": "Xã Hòa Sơn",
                        "code": 20302,
                        "codename": "xa_hoa_son",
                        "division_type": "xã",
                        "short_codename": "hoa_son"
                    },
                    {
                        "name": "Xã Hòa Nhơn",
                        "code": 20308,
                        "codename": "xa_hoa_nhon",
                        "division_type": "xã",
                        "short_codename": "hoa_nhon"
                    },
                    {
                        "name": "Xã Hòa Phú",
                        "code": 20317,
                        "codename": "xa_hoa_phu",
                        "division_type": "xã",
                        "short_codename": "hoa_phu"
                    },
                    {
                        "name": "Xã Hòa Phong",
                        "code": 20320,
                        "codename": "xa_hoa_phong",
                        "division_type": "xã",
                        "short_codename": "hoa_phong"
                    },
                    {
                        "name": "Xã Hòa Châu",
                        "code": 20323,
                        "codename": "xa_hoa_chau",
                        "division_type": "xã",
                        "short_codename": "hoa_chau"
                    },
                    {
                        "name": "Xã Hòa Tiến",
                        "code": 20326,
                        "codename": "xa_hoa_tien",
                        "division_type": "xã",
                        "short_codename": "hoa_tien"
                    },
                    {
                        "name": "Xã Hòa Phước",
                        "code": 20329,
                        "codename": "xa_hoa_phuoc",
                        "division_type": "xã",
                        "short_codename": "hoa_phuoc"
                    },
                    {
                        "name": "Xã Hòa Khương",
                        "code": 20332,
                        "codename": "xa_hoa_khuong",
                        "division_type": "xã",
                        "short_codename": "hoa_khuong"
                    }
                ]
            },
            {
                "name": "Huyện Hoàng Sa",
                "code": 498,
                "codename": "huyen_hoang_sa",
                "division_type": "huyện",
                "short_codename": "hoang_sa",
                "wards": []
            }
        ]
    },
    {
        "name": "Tỉnh Quảng Nam",
        "code": 49,
        "codename": "tinh_quang_nam",
        "division_type": "tỉnh",
        "phone_code": 235,
        "districts": [
            {
                "name": "Thành phố Tam Kỳ",
                "code": 502,
                "codename": "thanh_pho_tam_ky",
                "division_type": "thành phố",
                "short_codename": "tam_ky",
                "wards": [
                    {
                        "name": "Phường Tân Thạnh",
                        "code": 20335,
                        "codename": "phuong_tan_thanh",
                        "division_type": "phường",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Phường Phước Hòa",
                        "code": 20338,
                        "codename": "phuong_phuoc_hoa",
                        "division_type": "phường",
                        "short_codename": "phuoc_hoa"
                    },
                    {
                        "name": "Phường An Mỹ",
                        "code": 20341,
                        "codename": "phuong_an_my",
                        "division_type": "phường",
                        "short_codename": "an_my"
                    },
                    {
                        "name": "Phường Hòa Hương",
                        "code": 20344,
                        "codename": "phuong_hoa_huong",
                        "division_type": "phường",
                        "short_codename": "hoa_huong"
                    },
                    {
                        "name": "Phường An Xuân",
                        "code": 20347,
                        "codename": "phuong_an_xuan",
                        "division_type": "phường",
                        "short_codename": "an_xuan"
                    },
                    {
                        "name": "Phường An Sơn",
                        "code": 20350,
                        "codename": "phuong_an_son",
                        "division_type": "phường",
                        "short_codename": "an_son"
                    },
                    {
                        "name": "Phường Trường Xuân",
                        "code": 20353,
                        "codename": "phuong_truong_xuan",
                        "division_type": "phường",
                        "short_codename": "truong_xuan"
                    },
                    {
                        "name": "Phường An Phú",
                        "code": 20356,
                        "codename": "phuong_an_phu",
                        "division_type": "phường",
                        "short_codename": "an_phu"
                    },
                    {
                        "name": "Xã Tam Thanh",
                        "code": 20359,
                        "codename": "xa_tam_thanh",
                        "division_type": "xã",
                        "short_codename": "tam_thanh"
                    },
                    {
                        "name": "Xã Tam Thăng",
                        "code": 20362,
                        "codename": "xa_tam_thang",
                        "division_type": "xã",
                        "short_codename": "tam_thang"
                    },
                    {
                        "name": "Xã Tam Phú",
                        "code": 20371,
                        "codename": "xa_tam_phu",
                        "division_type": "xã",
                        "short_codename": "tam_phu"
                    },
                    {
                        "name": "Phường Hoà Thuận",
                        "code": 20375,
                        "codename": "phuong_hoa_thuan",
                        "division_type": "phường",
                        "short_codename": "hoa_thuan"
                    },
                    {
                        "name": "Xã Tam Ngọc",
                        "code": 20389,
                        "codename": "xa_tam_ngoc",
                        "division_type": "xã",
                        "short_codename": "tam_ngoc"
                    }
                ]
            },
            {
                "name": "Thành phố Hội An",
                "code": 503,
                "codename": "thanh_pho_hoi_an",
                "division_type": "thành phố",
                "short_codename": "hoi_an",
                "wards": [
                    {
                        "name": "Phường Minh An",
                        "code": 20398,
                        "codename": "phuong_minh_an",
                        "division_type": "phường",
                        "short_codename": "minh_an"
                    },
                    {
                        "name": "Phường Tân An",
                        "code": 20401,
                        "codename": "phuong_tan_an",
                        "division_type": "phường",
                        "short_codename": "tan_an"
                    },
                    {
                        "name": "Phường Cẩm Phô",
                        "code": 20404,
                        "codename": "phuong_cam_pho",
                        "division_type": "phường",
                        "short_codename": "cam_pho"
                    },
                    {
                        "name": "Phường Thanh Hà",
                        "code": 20407,
                        "codename": "phuong_thanh_ha",
                        "division_type": "phường",
                        "short_codename": "thanh_ha"
                    },
                    {
                        "name": "Phường Sơn Phong",
                        "code": 20410,
                        "codename": "phuong_son_phong",
                        "division_type": "phường",
                        "short_codename": "son_phong"
                    },
                    {
                        "name": "Phường Cẩm Châu",
                        "code": 20413,
                        "codename": "phuong_cam_chau",
                        "division_type": "phường",
                        "short_codename": "cam_chau"
                    },
                    {
                        "name": "Phường Cửa Đại",
                        "code": 20416,
                        "codename": "phuong_cua_dai",
                        "division_type": "phường",
                        "short_codename": "cua_dai"
                    },
                    {
                        "name": "Phường Cẩm An",
                        "code": 20419,
                        "codename": "phuong_cam_an",
                        "division_type": "phường",
                        "short_codename": "cam_an"
                    },
                    {
                        "name": "Xã Cẩm Hà",
                        "code": 20422,
                        "codename": "xa_cam_ha",
                        "division_type": "xã",
                        "short_codename": "cam_ha"
                    },
                    {
                        "name": "Xã Cẩm Kim",
                        "code": 20425,
                        "codename": "xa_cam_kim",
                        "division_type": "xã",
                        "short_codename": "cam_kim"
                    },
                    {
                        "name": "Phường Cẩm Nam",
                        "code": 20428,
                        "codename": "phuong_cam_nam",
                        "division_type": "phường",
                        "short_codename": "cam_nam"
                    },
                    {
                        "name": "Xã Cẩm Thanh",
                        "code": 20431,
                        "codename": "xa_cam_thanh",
                        "division_type": "xã",
                        "short_codename": "cam_thanh"
                    },
                    {
                        "name": "Xã Tân Hiệp",
                        "code": 20434,
                        "codename": "xa_tan_hiep",
                        "division_type": "xã",
                        "short_codename": "tan_hiep"
                    }
                ]
            },
            {
                "name": "Huyện Tây Giang",
                "code": 504,
                "codename": "huyen_tay_giang",
                "division_type": "huyện",
                "short_codename": "tay_giang",
                "wards": [
                    {
                        "name": "Xã Ch'ơm",
                        "code": 20437,
                        "codename": "xa_chom",
                        "division_type": "xã",
                        "short_codename": "chom"
                    },
                    {
                        "name": "Xã Ga Ri",
                        "code": 20440,
                        "codename": "xa_ga_ri",
                        "division_type": "xã",
                        "short_codename": "ga_ri"
                    },
                    {
                        "name": "Xã A Xan",
                        "code": 20443,
                        "codename": "xa_a_xan",
                        "division_type": "xã",
                        "short_codename": "a_xan"
                    },
                    {
                        "name": "Xã Tr'Hy",
                        "code": 20446,
                        "codename": "xa_trhy",
                        "division_type": "xã",
                        "short_codename": "trhy"
                    },
                    {
                        "name": "Xã Lăng",
                        "code": 20449,
                        "codename": "xa_lang",
                        "division_type": "xã",
                        "short_codename": "lang"
                    },
                    {
                        "name": "Xã A Nông",
                        "code": 20452,
                        "codename": "xa_a_nong",
                        "division_type": "xã",
                        "short_codename": "a_nong"
                    },
                    {
                        "name": "Xã A Tiêng",
                        "code": 20455,
                        "codename": "xa_a_tieng",
                        "division_type": "xã",
                        "short_codename": "a_tieng"
                    },
                    {
                        "name": "Xã Bha Lê",
                        "code": 20458,
                        "codename": "xa_bha_le",
                        "division_type": "xã",
                        "short_codename": "bha_le"
                    },
                    {
                        "name": "Xã A Vương",
                        "code": 20461,
                        "codename": "xa_a_vuong",
                        "division_type": "xã",
                        "short_codename": "a_vuong"
                    },
                    {
                        "name": "Xã Dang",
                        "code": 20464,
                        "codename": "xa_dang",
                        "division_type": "xã",
                        "short_codename": "dang"
                    }
                ]
            },
            {
                "name": "Huyện Đông Giang",
                "code": 505,
                "codename": "huyen_dong_giang",
                "division_type": "huyện",
                "short_codename": "dong_giang",
                "wards": [
                    {
                        "name": "Thị trấn P Rao",
                        "code": 20467,
                        "codename": "thi_tran_p_rao",
                        "division_type": "thị trấn",
                        "short_codename": "p_rao"
                    },
                    {
                        "name": "Xã Tà Lu",
                        "code": 20470,
                        "codename": "xa_ta_lu",
                        "division_type": "xã",
                        "short_codename": "ta_lu"
                    },
                    {
                        "name": "Xã Sông Kôn",
                        "code": 20473,
                        "codename": "xa_song_kon",
                        "division_type": "xã",
                        "short_codename": "song_kon"
                    },
                    {
                        "name": "Xã Jơ Ngây",
                        "code": 20476,
                        "codename": "xa_jo_ngay",
                        "division_type": "xã",
                        "short_codename": "jo_ngay"
                    },
                    {
                        "name": "Xã A Ting",
                        "code": 20479,
                        "codename": "xa_a_ting",
                        "division_type": "xã",
                        "short_codename": "a_ting"
                    },
                    {
                        "name": "Xã Tư",
                        "code": 20482,
                        "codename": "xa_tu",
                        "division_type": "xã",
                        "short_codename": "tu"
                    },
                    {
                        "name": "Xã Ba",
                        "code": 20485,
                        "codename": "xa_ba",
                        "division_type": "xã",
                        "short_codename": "ba"
                    },
                    {
                        "name": "Xã A Rooi",
                        "code": 20488,
                        "codename": "xa_a_rooi",
                        "division_type": "xã",
                        "short_codename": "a_rooi"
                    },
                    {
                        "name": "Xã Za Hung",
                        "code": 20491,
                        "codename": "xa_za_hung",
                        "division_type": "xã",
                        "short_codename": "za_hung"
                    },
                    {
                        "name": "Xã Mà Cooi",
                        "code": 20494,
                        "codename": "xa_ma_cooi",
                        "division_type": "xã",
                        "short_codename": "ma_cooi"
                    },
                    {
                        "name": "Xã Ka Dăng",
                        "code": 20497,
                        "codename": "xa_ka_dang",
                        "division_type": "xã",
                        "short_codename": "ka_dang"
                    }
                ]
            },
            {
                "name": "Huyện Đại Lộc",
                "code": 506,
                "codename": "huyen_dai_loc",
                "division_type": "huyện",
                "short_codename": "dai_loc",
                "wards": [
                    {
                        "name": "Thị Trấn Ái Nghĩa",
                        "code": 20500,
                        "codename": "thi_tran_ai_nghia",
                        "division_type": "thị trấn",
                        "short_codename": "ai_nghia"
                    },
                    {
                        "name": "Xã Đại Sơn",
                        "code": 20503,
                        "codename": "xa_dai_son",
                        "division_type": "xã",
                        "short_codename": "dai_son"
                    },
                    {
                        "name": "Xã Đại Lãnh",
                        "code": 20506,
                        "codename": "xa_dai_lanh",
                        "division_type": "xã",
                        "short_codename": "dai_lanh"
                    },
                    {
                        "name": "Xã Đại Hưng",
                        "code": 20509,
                        "codename": "xa_dai_hung",
                        "division_type": "xã",
                        "short_codename": "dai_hung"
                    },
                    {
                        "name": "Xã Đại Hồng",
                        "code": 20512,
                        "codename": "xa_dai_hong",
                        "division_type": "xã",
                        "short_codename": "dai_hong"
                    },
                    {
                        "name": "Xã Đại Đồng",
                        "code": 20515,
                        "codename": "xa_dai_dong",
                        "division_type": "xã",
                        "short_codename": "dai_dong"
                    },
                    {
                        "name": "Xã Đại Quang",
                        "code": 20518,
                        "codename": "xa_dai_quang",
                        "division_type": "xã",
                        "short_codename": "dai_quang"
                    },
                    {
                        "name": "Xã Đại Nghĩa",
                        "code": 20521,
                        "codename": "xa_dai_nghia",
                        "division_type": "xã",
                        "short_codename": "dai_nghia"
                    },
                    {
                        "name": "Xã Đại Hiệp",
                        "code": 20524,
                        "codename": "xa_dai_hiep",
                        "division_type": "xã",
                        "short_codename": "dai_hiep"
                    },
                    {
                        "name": "Xã Đại Thạnh",
                        "code": 20527,
                        "codename": "xa_dai_thanh",
                        "division_type": "xã",
                        "short_codename": "dai_thanh"
                    },
                    {
                        "name": "Xã Đại Chánh",
                        "code": 20530,
                        "codename": "xa_dai_chanh",
                        "division_type": "xã",
                        "short_codename": "dai_chanh"
                    },
                    {
                        "name": "Xã Đại Tân",
                        "code": 20533,
                        "codename": "xa_dai_tan",
                        "division_type": "xã",
                        "short_codename": "dai_tan"
                    },
                    {
                        "name": "Xã Đại Phong",
                        "code": 20536,
                        "codename": "xa_dai_phong",
                        "division_type": "xã",
                        "short_codename": "dai_phong"
                    },
                    {
                        "name": "Xã Đại Minh",
                        "code": 20539,
                        "codename": "xa_dai_minh",
                        "division_type": "xã",
                        "short_codename": "dai_minh"
                    },
                    {
                        "name": "Xã Đại Thắng",
                        "code": 20542,
                        "codename": "xa_dai_thang",
                        "division_type": "xã",
                        "short_codename": "dai_thang"
                    },
                    {
                        "name": "Xã Đại Cường",
                        "code": 20545,
                        "codename": "xa_dai_cuong",
                        "division_type": "xã",
                        "short_codename": "dai_cuong"
                    },
                    {
                        "name": "Xã Đại An",
                        "code": 20547,
                        "codename": "xa_dai_an",
                        "division_type": "xã",
                        "short_codename": "dai_an"
                    },
                    {
                        "name": "Xã Đại Hòa",
                        "code": 20548,
                        "codename": "xa_dai_hoa",
                        "division_type": "xã",
                        "short_codename": "dai_hoa"
                    }
                ]
            },
            {
                "name": "Thị xã Điện Bàn",
                "code": 507,
                "codename": "thi_xa_dien_ban",
                "division_type": "thị xã",
                "short_codename": "dien_ban",
                "wards": [
                    {
                        "name": "Phường Vĩnh Điện",
                        "code": 20551,
                        "codename": "phuong_vinh_dien",
                        "division_type": "phường",
                        "short_codename": "vinh_dien"
                    },
                    {
                        "name": "Xã Điện Tiến",
                        "code": 20554,
                        "codename": "xa_dien_tien",
                        "division_type": "xã",
                        "short_codename": "dien_tien"
                    },
                    {
                        "name": "Xã Điện Hòa",
                        "code": 20557,
                        "codename": "xa_dien_hoa",
                        "division_type": "xã",
                        "short_codename": "dien_hoa"
                    },
                    {
                        "name": "Xã Điện Thắng Bắc",
                        "code": 20560,
                        "codename": "xa_dien_thang_bac",
                        "division_type": "xã",
                        "short_codename": "dien_thang_bac"
                    },
                    {
                        "name": "Xã Điện Thắng Trung",
                        "code": 20561,
                        "codename": "xa_dien_thang_trung",
                        "division_type": "xã",
                        "short_codename": "dien_thang_trung"
                    },
                    {
                        "name": "Xã Điện Thắng Nam",
                        "code": 20562,
                        "codename": "xa_dien_thang_nam",
                        "division_type": "xã",
                        "short_codename": "dien_thang_nam"
                    },
                    {
                        "name": "Phường Điện Ngọc",
                        "code": 20563,
                        "codename": "phuong_dien_ngoc",
                        "division_type": "phường",
                        "short_codename": "dien_ngoc"
                    },
                    {
                        "name": "Xã Điện Hồng",
                        "code": 20566,
                        "codename": "xa_dien_hong",
                        "division_type": "xã",
                        "short_codename": "dien_hong"
                    },
                    {
                        "name": "Xã Điện Thọ",
                        "code": 20569,
                        "codename": "xa_dien_tho",
                        "division_type": "xã",
                        "short_codename": "dien_tho"
                    },
                    {
                        "name": "Xã Điện Phước",
                        "code": 20572,
                        "codename": "xa_dien_phuoc",
                        "division_type": "xã",
                        "short_codename": "dien_phuoc"
                    },
                    {
                        "name": "Phường Điện An",
                        "code": 20575,
                        "codename": "phuong_dien_an",
                        "division_type": "phường",
                        "short_codename": "dien_an"
                    },
                    {
                        "name": "Phường Điện Nam Bắc",
                        "code": 20578,
                        "codename": "phuong_dien_nam_bac",
                        "division_type": "phường",
                        "short_codename": "dien_nam_bac"
                    },
                    {
                        "name": "Phường Điện Nam Trung",
                        "code": 20579,
                        "codename": "phuong_dien_nam_trung",
                        "division_type": "phường",
                        "short_codename": "dien_nam_trung"
                    },
                    {
                        "name": "Phường Điện Nam Đông",
                        "code": 20580,
                        "codename": "phuong_dien_nam_dong",
                        "division_type": "phường",
                        "short_codename": "dien_nam_dong"
                    },
                    {
                        "name": "Phường Điện Dương",
                        "code": 20581,
                        "codename": "phuong_dien_duong",
                        "division_type": "phường",
                        "short_codename": "dien_duong"
                    },
                    {
                        "name": "Xã Điện Quang",
                        "code": 20584,
                        "codename": "xa_dien_quang",
                        "division_type": "xã",
                        "short_codename": "dien_quang"
                    },
                    {
                        "name": "Xã Điện Trung",
                        "code": 20587,
                        "codename": "xa_dien_trung",
                        "division_type": "xã",
                        "short_codename": "dien_trung"
                    },
                    {
                        "name": "Xã Điện Phong",
                        "code": 20590,
                        "codename": "xa_dien_phong",
                        "division_type": "xã",
                        "short_codename": "dien_phong"
                    },
                    {
                        "name": "Xã Điện Minh",
                        "code": 20593,
                        "codename": "xa_dien_minh",
                        "division_type": "xã",
                        "short_codename": "dien_minh"
                    },
                    {
                        "name": "Xã Điện Phương",
                        "code": 20596,
                        "codename": "xa_dien_phuong",
                        "division_type": "xã",
                        "short_codename": "dien_phuong"
                    }
                ]
            },
            {
                "name": "Huyện Duy Xuyên",
                "code": 508,
                "codename": "huyen_duy_xuyen",
                "division_type": "huyện",
                "short_codename": "duy_xuyen",
                "wards": [
                    {
                        "name": "Thị trấn Nam Phước",
                        "code": 20599,
                        "codename": "thi_tran_nam_phuoc",
                        "division_type": "thị trấn",
                        "short_codename": "nam_phuoc"
                    },
                    {
                        "name": "Xã Duy Thu",
                        "code": 20602,
                        "codename": "xa_duy_thu",
                        "division_type": "xã",
                        "short_codename": "duy_thu"
                    },
                    {
                        "name": "Xã Duy Phú",
                        "code": 20605,
                        "codename": "xa_duy_phu",
                        "division_type": "xã",
                        "short_codename": "duy_phu"
                    },
                    {
                        "name": "Xã Duy Tân",
                        "code": 20608,
                        "codename": "xa_duy_tan",
                        "division_type": "xã",
                        "short_codename": "duy_tan"
                    },
                    {
                        "name": "Xã Duy Hòa",
                        "code": 20611,
                        "codename": "xa_duy_hoa",
                        "division_type": "xã",
                        "short_codename": "duy_hoa"
                    },
                    {
                        "name": "Xã Duy Châu",
                        "code": 20614,
                        "codename": "xa_duy_chau",
                        "division_type": "xã",
                        "short_codename": "duy_chau"
                    },
                    {
                        "name": "Xã Duy Trinh",
                        "code": 20617,
                        "codename": "xa_duy_trinh",
                        "division_type": "xã",
                        "short_codename": "duy_trinh"
                    },
                    {
                        "name": "Xã Duy Sơn",
                        "code": 20620,
                        "codename": "xa_duy_son",
                        "division_type": "xã",
                        "short_codename": "duy_son"
                    },
                    {
                        "name": "Xã Duy Trung",
                        "code": 20623,
                        "codename": "xa_duy_trung",
                        "division_type": "xã",
                        "short_codename": "duy_trung"
                    },
                    {
                        "name": "Xã Duy Phước",
                        "code": 20626,
                        "codename": "xa_duy_phuoc",
                        "division_type": "xã",
                        "short_codename": "duy_phuoc"
                    },
                    {
                        "name": "Xã Duy Thành",
                        "code": 20629,
                        "codename": "xa_duy_thanh",
                        "division_type": "xã",
                        "short_codename": "duy_thanh"
                    },
                    {
                        "name": "Xã Duy Vinh",
                        "code": 20632,
                        "codename": "xa_duy_vinh",
                        "division_type": "xã",
                        "short_codename": "duy_vinh"
                    },
                    {
                        "name": "Xã Duy Nghĩa",
                        "code": 20635,
                        "codename": "xa_duy_nghia",
                        "division_type": "xã",
                        "short_codename": "duy_nghia"
                    },
                    {
                        "name": "Xã Duy Hải",
                        "code": 20638,
                        "codename": "xa_duy_hai",
                        "division_type": "xã",
                        "short_codename": "duy_hai"
                    }
                ]
            },
            {
                "name": "Huyện Quế Sơn",
                "code": 509,
                "codename": "huyen_que_son",
                "division_type": "huyện",
                "short_codename": "que_son",
                "wards": [
                    {
                        "name": "Thị trấn Đông Phú",
                        "code": 20641,
                        "codename": "thi_tran_dong_phu",
                        "division_type": "thị trấn",
                        "short_codename": "dong_phu"
                    },
                    {
                        "name": "Xã Quế Xuân 1",
                        "code": 20644,
                        "codename": "xa_que_xuan_1",
                        "division_type": "xã",
                        "short_codename": "que_xuan_1"
                    },
                    {
                        "name": "Xã Quế Xuân 2",
                        "code": 20647,
                        "codename": "xa_que_xuan_2",
                        "division_type": "xã",
                        "short_codename": "que_xuan_2"
                    },
                    {
                        "name": "Xã Quế Phú",
                        "code": 20650,
                        "codename": "xa_que_phu",
                        "division_type": "xã",
                        "short_codename": "que_phu"
                    },
                    {
                        "name": "Thị trấn Hương An",
                        "code": 20651,
                        "codename": "thi_tran_huong_an",
                        "division_type": "thị trấn",
                        "short_codename": "huong_an"
                    },
                    {
                        "name": "Xã Quế Hiệp",
                        "code": 20659,
                        "codename": "xa_que_hiep",
                        "division_type": "xã",
                        "short_codename": "que_hiep"
                    },
                    {
                        "name": "Xã Quế Thuận",
                        "code": 20662,
                        "codename": "xa_que_thuan",
                        "division_type": "xã",
                        "short_codename": "que_thuan"
                    },
                    {
                        "name": "Xã Quế Mỹ",
                        "code": 20665,
                        "codename": "xa_que_my",
                        "division_type": "xã",
                        "short_codename": "que_my"
                    },
                    {
                        "name": "Xã Quế Long",
                        "code": 20677,
                        "codename": "xa_que_long",
                        "division_type": "xã",
                        "short_codename": "que_long"
                    },
                    {
                        "name": "Xã Quế Châu",
                        "code": 20680,
                        "codename": "xa_que_chau",
                        "division_type": "xã",
                        "short_codename": "que_chau"
                    },
                    {
                        "name": "Xã Quế Phong",
                        "code": 20683,
                        "codename": "xa_que_phong",
                        "division_type": "xã",
                        "short_codename": "que_phong"
                    },
                    {
                        "name": "Xã Quế An",
                        "code": 20686,
                        "codename": "xa_que_an",
                        "division_type": "xã",
                        "short_codename": "que_an"
                    },
                    {
                        "name": "Xã Quế Minh",
                        "code": 20689,
                        "codename": "xa_que_minh",
                        "division_type": "xã",
                        "short_codename": "que_minh"
                    }
                ]
            },
            {
                "name": "Huyện Nam Giang",
                "code": 510,
                "codename": "huyen_nam_giang",
                "division_type": "huyện",
                "short_codename": "nam_giang",
                "wards": [
                    {
                        "name": "Thị trấn Thạnh Mỹ",
                        "code": 20695,
                        "codename": "thi_tran_thanh_my",
                        "division_type": "thị trấn",
                        "short_codename": "thanh_my"
                    },
                    {
                        "name": "Xã Laêê",
                        "code": 20698,
                        "codename": "xa_laee",
                        "division_type": "xã",
                        "short_codename": "laee"
                    },
                    {
                        "name": "Xã Chơ Chun",
                        "code": 20699,
                        "codename": "xa_cho_chun",
                        "division_type": "xã",
                        "short_codename": "cho_chun"
                    },
                    {
                        "name": "Xã Zuôich",
                        "code": 20701,
                        "codename": "xa_zuoich",
                        "division_type": "xã",
                        "short_codename": "zuoich"
                    },
                    {
                        "name": "Xã Tà Pơơ",
                        "code": 20702,
                        "codename": "xa_ta_poo",
                        "division_type": "xã",
                        "short_codename": "ta_poo"
                    },
                    {
                        "name": "Xã La Dêê",
                        "code": 20704,
                        "codename": "xa_la_dee",
                        "division_type": "xã",
                        "short_codename": "la_dee"
                    },
                    {
                        "name": "Xã Đắc Tôi",
                        "code": 20705,
                        "codename": "xa_dac_toi",
                        "division_type": "xã",
                        "short_codename": "dac_toi"
                    },
                    {
                        "name": "Xã Chà Vàl",
                        "code": 20707,
                        "codename": "xa_cha_val",
                        "division_type": "xã",
                        "short_codename": "cha_val"
                    },
                    {
                        "name": "Xã Tà Bhinh",
                        "code": 20710,
                        "codename": "xa_ta_bhinh",
                        "division_type": "xã",
                        "short_codename": "ta_bhinh"
                    },
                    {
                        "name": "Xã Cà Dy",
                        "code": 20713,
                        "codename": "xa_ca_dy",
                        "division_type": "xã",
                        "short_codename": "ca_dy"
                    },
                    {
                        "name": "Xã Đắc Pre",
                        "code": 20716,
                        "codename": "xa_dac_pre",
                        "division_type": "xã",
                        "short_codename": "dac_pre"
                    },
                    {
                        "name": "Xã Đắc Pring",
                        "code": 20719,
                        "codename": "xa_dac_pring",
                        "division_type": "xã",
                        "short_codename": "dac_pring"
                    }
                ]
            },
            {
                "name": "Huyện Phước Sơn",
                "code": 511,
                "codename": "huyen_phuoc_son",
                "division_type": "huyện",
                "short_codename": "phuoc_son",
                "wards": [
                    {
                        "name": "Thị trấn Khâm Đức",
                        "code": 20722,
                        "codename": "thi_tran_kham_duc",
                        "division_type": "thị trấn",
                        "short_codename": "kham_duc"
                    },
                    {
                        "name": "Xã Phước Xuân",
                        "code": 20725,
                        "codename": "xa_phuoc_xuan",
                        "division_type": "xã",
                        "short_codename": "phuoc_xuan"
                    },
                    {
                        "name": "Xã Phước Hiệp",
                        "code": 20728,
                        "codename": "xa_phuoc_hiep",
                        "division_type": "xã",
                        "short_codename": "phuoc_hiep"
                    },
                    {
                        "name": "Xã Phước Hoà",
                        "code": 20729,
                        "codename": "xa_phuoc_hoa",
                        "division_type": "xã",
                        "short_codename": "phuoc_hoa"
                    },
                    {
                        "name": "Xã Phước Đức",
                        "code": 20731,
                        "codename": "xa_phuoc_duc",
                        "division_type": "xã",
                        "short_codename": "phuoc_duc"
                    },
                    {
                        "name": "Xã Phước Năng",
                        "code": 20734,
                        "codename": "xa_phuoc_nang",
                        "division_type": "xã",
                        "short_codename": "phuoc_nang"
                    },
                    {
                        "name": "Xã Phước Mỹ",
                        "code": 20737,
                        "codename": "xa_phuoc_my",
                        "division_type": "xã",
                        "short_codename": "phuoc_my"
                    },
                    {
                        "name": "Xã Phước Chánh",
                        "code": 20740,
                        "codename": "xa_phuoc_chanh",
                        "division_type": "xã",
                        "short_codename": "phuoc_chanh"
                    },
                    {
                        "name": "Xã Phước Công",
                        "code": 20743,
                        "codename": "xa_phuoc_cong",
                        "division_type": "xã",
                        "short_codename": "phuoc_cong"
                    },
                    {
                        "name": "Xã Phước Kim",
                        "code": 20746,
                        "codename": "xa_phuoc_kim",
                        "division_type": "xã",
                        "short_codename": "phuoc_kim"
                    },
                    {
                        "name": "Xã Phước Lộc",
                        "code": 20749,
                        "codename": "xa_phuoc_loc",
                        "division_type": "xã",
                        "short_codename": "phuoc_loc"
                    },
                    {
                        "name": "Xã Phước Thành",
                        "code": 20752,
                        "codename": "xa_phuoc_thanh",
                        "division_type": "xã",
                        "short_codename": "phuoc_thanh"
                    }
                ]
            },
            {
                "name": "Huyện Hiệp Đức",
                "code": 512,
                "codename": "huyen_hiep_duc",
                "division_type": "huyện",
                "short_codename": "hiep_duc",
                "wards": [
                    {
                        "name": "Xã Hiệp Hòa",
                        "code": 20758,
                        "codename": "xa_hiep_hoa",
                        "division_type": "xã",
                        "short_codename": "hiep_hoa"
                    },
                    {
                        "name": "Xã Hiệp Thuận",
                        "code": 20761,
                        "codename": "xa_hiep_thuan",
                        "division_type": "xã",
                        "short_codename": "hiep_thuan"
                    },
                    {
                        "name": "Xã Quế Thọ",
                        "code": 20764,
                        "codename": "xa_que_tho",
                        "division_type": "xã",
                        "short_codename": "que_tho"
                    },
                    {
                        "name": "Xã Bình Lâm",
                        "code": 20767,
                        "codename": "xa_binh_lam",
                        "division_type": "xã",
                        "short_codename": "binh_lam"
                    },
                    {
                        "name": "Xã Sông Trà",
                        "code": 20770,
                        "codename": "xa_song_tra",
                        "division_type": "xã",
                        "short_codename": "song_tra"
                    },
                    {
                        "name": "Xã Phước Trà",
                        "code": 20773,
                        "codename": "xa_phuoc_tra",
                        "division_type": "xã",
                        "short_codename": "phuoc_tra"
                    },
                    {
                        "name": "Xã Phước Gia",
                        "code": 20776,
                        "codename": "xa_phuoc_gia",
                        "division_type": "xã",
                        "short_codename": "phuoc_gia"
                    },
                    {
                        "name": "Thị trấn Tân Bình",
                        "code": 20779,
                        "codename": "thi_tran_tan_binh",
                        "division_type": "thị trấn",
                        "short_codename": "tan_binh"
                    },
                    {
                        "name": "Xã Quế Lưu",
                        "code": 20782,
                        "codename": "xa_que_luu",
                        "division_type": "xã",
                        "short_codename": "que_luu"
                    },
                    {
                        "name": "Xã Thăng Phước",
                        "code": 20785,
                        "codename": "xa_thang_phuoc",
                        "division_type": "xã",
                        "short_codename": "thang_phuoc"
                    },
                    {
                        "name": "Xã Bình Sơn",
                        "code": 20788,
                        "codename": "xa_binh_son",
                        "division_type": "xã",
                        "short_codename": "binh_son"
                    }
                ]
            },
            {
                "name": "Huyện Thăng Bình",
                "code": 513,
                "codename": "huyen_thang_binh",
                "division_type": "huyện",
                "short_codename": "thang_binh",
                "wards": [
                    {
                        "name": "Thị trấn Hà Lam",
                        "code": 20791,
                        "codename": "thi_tran_ha_lam",
                        "division_type": "thị trấn",
                        "short_codename": "ha_lam"
                    },
                    {
                        "name": "Xã Bình Dương",
                        "code": 20794,
                        "codename": "xa_binh_duong",
                        "division_type": "xã",
                        "short_codename": "binh_duong"
                    },
                    {
                        "name": "Xã Bình Giang",
                        "code": 20797,
                        "codename": "xa_binh_giang",
                        "division_type": "xã",
                        "short_codename": "binh_giang"
                    },
                    {
                        "name": "Xã Bình Nguyên",
                        "code": 20800,
                        "codename": "xa_binh_nguyen",
                        "division_type": "xã",
                        "short_codename": "binh_nguyen"
                    },
                    {
                        "name": "Xã Bình Phục",
                        "code": 20803,
                        "codename": "xa_binh_phuc",
                        "division_type": "xã",
                        "short_codename": "binh_phuc"
                    },
                    {
                        "name": "Xã Bình Triều",
                        "code": 20806,
                        "codename": "xa_binh_trieu",
                        "division_type": "xã",
                        "short_codename": "binh_trieu"
                    },
                    {
                        "name": "Xã Bình Đào",
                        "code": 20809,
                        "codename": "xa_binh_dao",
                        "division_type": "xã",
                        "short_codename": "binh_dao"
                    },
                    {
                        "name": "Xã Bình Minh",
                        "code": 20812,
                        "codename": "xa_binh_minh",
                        "division_type": "xã",
                        "short_codename": "binh_minh"
                    },
                    {
                        "name": "Xã Bình Lãnh",
                        "code": 20815,
                        "codename": "xa_binh_lanh",
                        "division_type": "xã",
                        "short_codename": "binh_lanh"
                    },
                    {
                        "name": "Xã Bình Trị",
                        "code": 20818,
                        "codename": "xa_binh_tri",
                        "division_type": "xã",
                        "short_codename": "binh_tri"
                    },
                    {
                        "name": "Xã Bình Định Bắc",
                        "code": 20821,
                        "codename": "xa_binh_dinh_bac",
                        "division_type": "xã",
                        "short_codename": "binh_dinh_bac"
                    },
                    {
                        "name": "Xã Bình Định Nam",
                        "code": 20822,
                        "codename": "xa_binh_dinh_nam",
                        "division_type": "xã",
                        "short_codename": "binh_dinh_nam"
                    },
                    {
                        "name": "Xã Bình Quý",
                        "code": 20824,
                        "codename": "xa_binh_quy",
                        "division_type": "xã",
                        "short_codename": "binh_quy"
                    },
                    {
                        "name": "Xã Bình Phú",
                        "code": 20827,
                        "codename": "xa_binh_phu",
                        "division_type": "xã",
                        "short_codename": "binh_phu"
                    },
                    {
                        "name": "Xã Bình Chánh",
                        "code": 20830,
                        "codename": "xa_binh_chanh",
                        "division_type": "xã",
                        "short_codename": "binh_chanh"
                    },
                    {
                        "name": "Xã Bình Tú",
                        "code": 20833,
                        "codename": "xa_binh_tu",
                        "division_type": "xã",
                        "short_codename": "binh_tu"
                    },
                    {
                        "name": "Xã Bình Sa",
                        "code": 20836,
                        "codename": "xa_binh_sa",
                        "division_type": "xã",
                        "short_codename": "binh_sa"
                    },
                    {
                        "name": "Xã Bình Hải",
                        "code": 20839,
                        "codename": "xa_binh_hai",
                        "division_type": "xã",
                        "short_codename": "binh_hai"
                    },
                    {
                        "name": "Xã Bình Quế",
                        "code": 20842,
                        "codename": "xa_binh_que",
                        "division_type": "xã",
                        "short_codename": "binh_que"
                    },
                    {
                        "name": "Xã Bình An",
                        "code": 20845,
                        "codename": "xa_binh_an",
                        "division_type": "xã",
                        "short_codename": "binh_an"
                    },
                    {
                        "name": "Xã Bình Trung",
                        "code": 20848,
                        "codename": "xa_binh_trung",
                        "division_type": "xã",
                        "short_codename": "binh_trung"
                    },
                    {
                        "name": "Xã Bình Nam",
                        "code": 20851,
                        "codename": "xa_binh_nam",
                        "division_type": "xã",
                        "short_codename": "binh_nam"
                    }
                ]
            },
            {
                "name": "Huyện Tiên Phước",
                "code": 514,
                "codename": "huyen_tien_phuoc",
                "division_type": "huyện",
                "short_codename": "tien_phuoc",
                "wards": [
                    {
                        "name": "Thị trấn Tiên Kỳ",
                        "code": 20854,
                        "codename": "thi_tran_tien_ky",
                        "division_type": "thị trấn",
                        "short_codename": "tien_ky"
                    },
                    {
                        "name": "Xã Tiên Sơn",
                        "code": 20857,
                        "codename": "xa_tien_son",
                        "division_type": "xã",
                        "short_codename": "tien_son"
                    },
                    {
                        "name": "Xã Tiên Hà",
                        "code": 20860,
                        "codename": "xa_tien_ha",
                        "division_type": "xã",
                        "short_codename": "tien_ha"
                    },
                    {
                        "name": "Xã Tiên Cẩm",
                        "code": 20863,
                        "codename": "xa_tien_cam",
                        "division_type": "xã",
                        "short_codename": "tien_cam"
                    },
                    {
                        "name": "Xã Tiên Châu",
                        "code": 20866,
                        "codename": "xa_tien_chau",
                        "division_type": "xã",
                        "short_codename": "tien_chau"
                    },
                    {
                        "name": "Xã Tiên Lãnh",
                        "code": 20869,
                        "codename": "xa_tien_lanh",
                        "division_type": "xã",
                        "short_codename": "tien_lanh"
                    },
                    {
                        "name": "Xã Tiên Ngọc",
                        "code": 20872,
                        "codename": "xa_tien_ngoc",
                        "division_type": "xã",
                        "short_codename": "tien_ngoc"
                    },
                    {
                        "name": "Xã Tiên Hiệp",
                        "code": 20875,
                        "codename": "xa_tien_hiep",
                        "division_type": "xã",
                        "short_codename": "tien_hiep"
                    },
                    {
                        "name": "Xã Tiên Cảnh",
                        "code": 20878,
                        "codename": "xa_tien_canh",
                        "division_type": "xã",
                        "short_codename": "tien_canh"
                    },
                    {
                        "name": "Xã Tiên Mỹ",
                        "code": 20881,
                        "codename": "xa_tien_my",
                        "division_type": "xã",
                        "short_codename": "tien_my"
                    },
                    {
                        "name": "Xã Tiên Phong",
                        "code": 20884,
                        "codename": "xa_tien_phong",
                        "division_type": "xã",
                        "short_codename": "tien_phong"
                    },
                    {
                        "name": "Xã Tiên Thọ",
                        "code": 20887,
                        "codename": "xa_tien_tho",
                        "division_type": "xã",
                        "short_codename": "tien_tho"
                    },
                    {
                        "name": "Xã Tiên An",
                        "code": 20890,
                        "codename": "xa_tien_an",
                        "division_type": "xã",
                        "short_codename": "tien_an"
                    },
                    {
                        "name": "Xã Tiên Lộc",
                        "code": 20893,
                        "codename": "xa_tien_loc",
                        "division_type": "xã",
                        "short_codename": "tien_loc"
                    },
                    {
                        "name": "Xã Tiên Lập",
                        "code": 20896,
                        "codename": "xa_tien_lap",
                        "division_type": "xã",
                        "short_codename": "tien_lap"
                    }
                ]
            },
            {
                "name": "Huyện Bắc Trà My",
                "code": 515,
                "codename": "huyen_bac_tra_my",
                "division_type": "huyện",
                "short_codename": "bac_tra_my",
                "wards": [
                    {
                        "name": "Thị trấn Trà My",
                        "code": 20899,
                        "codename": "thi_tran_tra_my",
                        "division_type": "thị trấn",
                        "short_codename": "tra_my"
                    },
                    {
                        "name": "Xã Trà Sơn",
                        "code": 20900,
                        "codename": "xa_tra_son",
                        "division_type": "xã",
                        "short_codename": "tra_son"
                    },
                    {
                        "name": "Xã Trà Kót",
                        "code": 20902,
                        "codename": "xa_tra_kot",
                        "division_type": "xã",
                        "short_codename": "tra_kot"
                    },
                    {
                        "name": "Xã Trà Nú",
                        "code": 20905,
                        "codename": "xa_tra_nu",
                        "division_type": "xã",
                        "short_codename": "tra_nu"
                    },
                    {
                        "name": "Xã Trà Đông",
                        "code": 20908,
                        "codename": "xa_tra_dong",
                        "division_type": "xã",
                        "short_codename": "tra_dong"
                    },
                    {
                        "name": "Xã Trà Dương",
                        "code": 20911,
                        "codename": "xa_tra_duong",
                        "division_type": "xã",
                        "short_codename": "tra_duong"
                    },
                    {
                        "name": "Xã Trà Giang",
                        "code": 20914,
                        "codename": "xa_tra_giang",
                        "division_type": "xã",
                        "short_codename": "tra_giang"
                    },
                    {
                        "name": "Xã Trà Bui",
                        "code": 20917,
                        "codename": "xa_tra_bui",
                        "division_type": "xã",
                        "short_codename": "tra_bui"
                    },
                    {
                        "name": "Xã Trà Đốc",
                        "code": 20920,
                        "codename": "xa_tra_doc",
                        "division_type": "xã",
                        "short_codename": "tra_doc"
                    },
                    {
                        "name": "Xã Trà Tân",
                        "code": 20923,
                        "codename": "xa_tra_tan",
                        "division_type": "xã",
                        "short_codename": "tra_tan"
                    },
                    {
                        "name": "Xã Trà Giác",
                        "code": 20926,
                        "codename": "xa_tra_giac",
                        "division_type": "xã",
                        "short_codename": "tra_giac"
                    },
                    {
                        "name": "Xã Trà Giáp",
                        "code": 20929,
                        "codename": "xa_tra_giap",
                        "division_type": "xã",
                        "short_codename": "tra_giap"
                    },
                    {
                        "name": "Xã Trà Ka",
                        "code": 20932,
                        "codename": "xa_tra_ka",
                        "division_type": "xã",
                        "short_codename": "tra_ka"
                    }
                ]
            },
            {
                "name": "Huyện Nam Trà My",
                "code": 516,
                "codename": "huyen_nam_tra_my",
                "division_type": "huyện",
                "short_codename": "nam_tra_my",
                "wards": [
                    {
                        "name": "Xã Trà Leng",
                        "code": 20935,
                        "codename": "xa_tra_leng",
                        "division_type": "xã",
                        "short_codename": "tra_leng"
                    },
                    {
                        "name": "Xã Trà Dơn",
                        "code": 20938,
                        "codename": "xa_tra_don",
                        "division_type": "xã",
                        "short_codename": "xa_tra_don"
                    },
                    {
                        "name": "Xã Trà Tập",
                        "code": 20941,
                        "codename": "xa_tra_tap",
                        "division_type": "xã",
                        "short_codename": "tra_tap"
                    },
                    {
                        "name": "Xã Trà Mai",
                        "code": 20944,
                        "codename": "xa_tra_mai",
                        "division_type": "xã",
                        "short_codename": "tra_mai"
                    },
                    {
                        "name": "Xã Trà Cang",
                        "code": 20947,
                        "codename": "xa_tra_cang",
                        "division_type": "xã",
                        "short_codename": "tra_cang"
                    },
                    {
                        "name": "Xã Trà Linh",
                        "code": 20950,
                        "codename": "xa_tra_linh",
                        "division_type": "xã",
                        "short_codename": "tra_linh"
                    },
                    {
                        "name": "Xã Trà Nam",
                        "code": 20953,
                        "codename": "xa_tra_nam",
                        "division_type": "xã",
                        "short_codename": "tra_nam"
                    },
                    {
                        "name": "Xã Trà Don",
                        "code": 20956,
                        "codename": "xa_tra_don",
                        "division_type": "xã",
                        "short_codename": "xa_tra_don"
                    },
                    {
                        "name": "Xã Trà Vân",
                        "code": 20959,
                        "codename": "xa_tra_van",
                        "division_type": "xã",
                        "short_codename": "tra_van"
                    },
                    {
                        "name": "Xã Trà Vinh",
                        "code": 20962,
                        "codename": "xa_tra_vinh",
                        "division_type": "xã",
                        "short_codename": "tra_vinh"
                    }
                ]
            },
            {
                "name": "Huyện Núi Thành",
                "code": 517,
                "codename": "huyen_nui_thanh",
                "division_type": "huyện",
                "short_codename": "nui_thanh",
                "wards": [
                    {
                        "name": "Thị trấn Núi Thành",
                        "code": 20965,
                        "codename": "thi_tran_nui_thanh",
                        "division_type": "thị trấn",
                        "short_codename": "nui_thanh"
                    },
                    {
                        "name": "Xã Tam Xuân I",
                        "code": 20968,
                        "codename": "xa_tam_xuan_i",
                        "division_type": "xã",
                        "short_codename": "tam_xuan_i"
                    },
                    {
                        "name": "Xã Tam Xuân II",
                        "code": 20971,
                        "codename": "xa_tam_xuan_ii",
                        "division_type": "xã",
                        "short_codename": "tam_xuan_ii"
                    },
                    {
                        "name": "Xã Tam Tiến",
                        "code": 20974,
                        "codename": "xa_tam_tien",
                        "division_type": "xã",
                        "short_codename": "tam_tien"
                    },
                    {
                        "name": "Xã Tam Sơn",
                        "code": 20977,
                        "codename": "xa_tam_son",
                        "division_type": "xã",
                        "short_codename": "tam_son"
                    },
                    {
                        "name": "Xã Tam Thạnh",
                        "code": 20980,
                        "codename": "xa_tam_thanh",
                        "division_type": "xã",
                        "short_codename": "tam_thanh"
                    },
                    {
                        "name": "Xã Tam Anh Bắc",
                        "code": 20983,
                        "codename": "xa_tam_anh_bac",
                        "division_type": "xã",
                        "short_codename": "tam_anh_bac"
                    },
                    {
                        "name": "Xã Tam Anh Nam",
                        "code": 20984,
                        "codename": "xa_tam_anh_nam",
                        "division_type": "xã",
                        "short_codename": "tam_anh_nam"
                    },
                    {
                        "name": "Xã Tam Hòa",
                        "code": 20986,
                        "codename": "xa_tam_hoa",
                        "division_type": "xã",
                        "short_codename": "tam_hoa"
                    },
                    {
                        "name": "Xã Tam Hiệp",
                        "code": 20989,
                        "codename": "xa_tam_hiep",
                        "division_type": "xã",
                        "short_codename": "tam_hiep"
                    },
                    {
                        "name": "Xã Tam Hải",
                        "code": 20992,
                        "codename": "xa_tam_hai",
                        "division_type": "xã",
                        "short_codename": "tam_hai"
                    },
                    {
                        "name": "Xã Tam Giang",
                        "code": 20995,
                        "codename": "xa_tam_giang",
                        "division_type": "xã",
                        "short_codename": "tam_giang"
                    },
                    {
                        "name": "Xã Tam Quang",
                        "code": 20998,
                        "codename": "xa_tam_quang",
                        "division_type": "xã",
                        "short_codename": "tam_quang"
                    },
                    {
                        "name": "Xã Tam Nghĩa",
                        "code": 21001,
                        "codename": "xa_tam_nghia",
                        "division_type": "xã",
                        "short_codename": "tam_nghia"
                    },
                    {
                        "name": "Xã Tam Mỹ Tây",
                        "code": 21004,
                        "codename": "xa_tam_my_tay",
                        "division_type": "xã",
                        "short_codename": "tam_my_tay"
                    },
                    {
                        "name": "Xã Tam Mỹ Đông",
                        "code": 21005,
                        "codename": "xa_tam_my_dong",
                        "division_type": "xã",
                        "short_codename": "tam_my_dong"
                    },
                    {
                        "name": "Xã Tam Trà",
                        "code": 21007,
                        "codename": "xa_tam_tra",
                        "division_type": "xã",
                        "short_codename": "tam_tra"
                    }
                ]
            },
            {
                "name": "Huyện Phú Ninh",
                "code": 518,
                "codename": "huyen_phu_ninh",
                "division_type": "huyện",
                "short_codename": "phu_ninh",
                "wards": [
                    {
                        "name": "Thị trấn Phú Thịnh",
                        "code": 20364,
                        "codename": "thi_tran_phu_thinh",
                        "division_type": "thị trấn",
                        "short_codename": "phu_thinh"
                    },
                    {
                        "name": "Xã Tam Thành",
                        "code": 20365,
                        "codename": "xa_tam_thanh",
                        "division_type": "xã",
                        "short_codename": "tam_thanh"
                    },
                    {
                        "name": "Xã Tam An",
                        "code": 20368,
                        "codename": "xa_tam_an",
                        "division_type": "xã",
                        "short_codename": "tam_an"
                    },
                    {
                        "name": "Xã Tam Đàn",
                        "code": 20374,
                        "codename": "xa_tam_dan",
                        "division_type": "xã",
                        "short_codename": "xa_tam_dan"
                    },
                    {
                        "name": "Xã Tam Lộc",
                        "code": 20377,
                        "codename": "xa_tam_loc",
                        "division_type": "xã",
                        "short_codename": "tam_loc"
                    },
                    {
                        "name": "Xã Tam Phước",
                        "code": 20380,
                        "codename": "xa_tam_phuoc",
                        "division_type": "xã",
                        "short_codename": "tam_phuoc"
                    },
                    {
                        "name": "Xã Tam Vinh",
                        "code": 20383,
                        "codename": "xa_tam_vinh",
                        "division_type": "xã",
                        "short_codename": "tam_vinh"
                    },
                    {
                        "name": "Xã Tam Thái",
                        "code": 20386,
                        "codename": "xa_tam_thai",
                        "division_type": "xã",
                        "short_codename": "tam_thai"
                    },
                    {
                        "name": "Xã Tam Đại",
                        "code": 20387,
                        "codename": "xa_tam_dai",
                        "division_type": "xã",
                        "short_codename": "tam_dai"
                    },
                    {
                        "name": "Xã Tam Dân",
                        "code": 20392,
                        "codename": "xa_tam_dan",
                        "division_type": "xã",
                        "short_codename": "xa_tam_dan"
                    },
                    {
                        "name": "Xã Tam Lãnh",
                        "code": 20395,
                        "codename": "xa_tam_lanh",
                        "division_type": "xã",
                        "short_codename": "tam_lanh"
                    }
                ]
            },
            {
                "name": "Huyện Nông Sơn",
                "code": 519,
                "codename": "huyen_nong_son",
                "division_type": "huyện",
                "short_codename": "nong_son",
                "wards": [
                    {
                        "name": "Xã Quế Trung",
                        "code": 20656,
                        "codename": "xa_que_trung",
                        "division_type": "xã",
                        "short_codename": "que_trung"
                    },
                    {
                        "name": "Xã Ninh Phước",
                        "code": 20668,
                        "codename": "xa_ninh_phuoc",
                        "division_type": "xã",
                        "short_codename": "ninh_phuoc"
                    },
                    {
                        "name": "Xã Phước Ninh",
                        "code": 20669,
                        "codename": "xa_phuoc_ninh",
                        "division_type": "xã",
                        "short_codename": "phuoc_ninh"
                    },
                    {
                        "name": "Xã Quế Lộc",
                        "code": 20671,
                        "codename": "xa_que_loc",
                        "division_type": "xã",
                        "short_codename": "que_loc"
                    },
                    {
                        "name": "Xã Sơn Viên",
                        "code": 20672,
                        "codename": "xa_son_vien",
                        "division_type": "xã",
                        "short_codename": "son_vien"
                    },
                    {
                        "name": "Xã Quế Lâm",
                        "code": 20692,
                        "codename": "xa_que_lam",
                        "division_type": "xã",
                        "short_codename": "que_lam"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Quảng Ngãi",
        "code": 51,
        "codename": "tinh_quang_ngai",
        "division_type": "tỉnh",
        "phone_code": 255,
        "districts": [
            {
                "name": "Thành phố Quảng Ngãi",
                "code": 522,
                "codename": "thanh_pho_quang_ngai",
                "division_type": "thành phố",
                "short_codename": "quang_ngai",
                "wards": [
                    {
                        "name": "Phường Lê Hồng Phong",
                        "code": 21010,
                        "codename": "phuong_le_hong_phong",
                        "division_type": "phường",
                        "short_codename": "le_hong_phong"
                    },
                    {
                        "name": "Phường Trần Phú",
                        "code": 21013,
                        "codename": "phuong_tran_phu",
                        "division_type": "phường",
                        "short_codename": "tran_phu"
                    },
                    {
                        "name": "Phường Quảng Phú",
                        "code": 21016,
                        "codename": "phuong_quang_phu",
                        "division_type": "phường",
                        "short_codename": "quang_phu"
                    },
                    {
                        "name": "Phường Nghĩa Chánh",
                        "code": 21019,
                        "codename": "phuong_nghia_chanh",
                        "division_type": "phường",
                        "short_codename": "nghia_chanh"
                    },
                    {
                        "name": "Phường Trần Hưng Đạo",
                        "code": 21022,
                        "codename": "phuong_tran_hung_dao",
                        "division_type": "phường",
                        "short_codename": "tran_hung_dao"
                    },
                    {
                        "name": "Phường Nguyễn Nghiêm",
                        "code": 21025,
                        "codename": "phuong_nguyen_nghiem",
                        "division_type": "phường",
                        "short_codename": "nguyen_nghiem"
                    },
                    {
                        "name": "Phường Nghĩa Lộ",
                        "code": 21028,
                        "codename": "phuong_nghia_lo",
                        "division_type": "phường",
                        "short_codename": "nghia_lo"
                    },
                    {
                        "name": "Phường Chánh Lộ",
                        "code": 21031,
                        "codename": "phuong_chanh_lo",
                        "division_type": "phường",
                        "short_codename": "chanh_lo"
                    },
                    {
                        "name": "Xã Nghĩa Dũng",
                        "code": 21034,
                        "codename": "xa_nghia_dung",
                        "division_type": "xã",
                        "short_codename": "nghia_dung"
                    },
                    {
                        "name": "Xã Nghĩa Dõng",
                        "code": 21037,
                        "codename": "xa_nghia_dong",
                        "division_type": "xã",
                        "short_codename": "nghia_dong"
                    },
                    {
                        "name": "Phường Trương Quang Trọng",
                        "code": 21172,
                        "codename": "phuong_truong_quang_trong",
                        "division_type": "phường",
                        "short_codename": "truong_quang_trong"
                    },
                    {
                        "name": "Xã Tịnh Hòa",
                        "code": 21187,
                        "codename": "xa_tinh_hoa",
                        "division_type": "xã",
                        "short_codename": "tinh_hoa"
                    },
                    {
                        "name": "Xã Tịnh Kỳ",
                        "code": 21190,
                        "codename": "xa_tinh_ky",
                        "division_type": "xã",
                        "short_codename": "tinh_ky"
                    },
                    {
                        "name": "Xã Tịnh Thiện",
                        "code": 21199,
                        "codename": "xa_tinh_thien",
                        "division_type": "xã",
                        "short_codename": "tinh_thien"
                    },
                    {
                        "name": "Xã Tịnh Ấn Đông",
                        "code": 21202,
                        "codename": "xa_tinh_an_dong",
                        "division_type": "xã",
                        "short_codename": "tinh_an_dong"
                    },
                    {
                        "name": "Xã Tịnh Châu",
                        "code": 21208,
                        "codename": "xa_tinh_chau",
                        "division_type": "xã",
                        "short_codename": "tinh_chau"
                    },
                    {
                        "name": "Xã Tịnh Khê",
                        "code": 21211,
                        "codename": "xa_tinh_khe",
                        "division_type": "xã",
                        "short_codename": "tinh_khe"
                    },
                    {
                        "name": "Xã Tịnh Long",
                        "code": 21214,
                        "codename": "xa_tinh_long",
                        "division_type": "xã",
                        "short_codename": "tinh_long"
                    },
                    {
                        "name": "Xã Tịnh Ấn Tây",
                        "code": 21223,
                        "codename": "xa_tinh_an_tay",
                        "division_type": "xã",
                        "short_codename": "tinh_an_tay"
                    },
                    {
                        "name": "Xã Tịnh An",
                        "code": 21232,
                        "codename": "xa_tinh_an",
                        "division_type": "xã",
                        "short_codename": "tinh_an"
                    },
                    {
                        "name": "Xã Nghĩa Phú",
                        "code": 21253,
                        "codename": "xa_nghia_phu",
                        "division_type": "xã",
                        "short_codename": "nghia_phu"
                    },
                    {
                        "name": "Xã Nghĩa Hà",
                        "code": 21256,
                        "codename": "xa_nghia_ha",
                        "division_type": "xã",
                        "short_codename": "nghia_ha"
                    },
                    {
                        "name": "Xã Nghĩa An",
                        "code": 21262,
                        "codename": "xa_nghia_an",
                        "division_type": "xã",
                        "short_codename": "nghia_an"
                    }
                ]
            },
            {
                "name": "Huyện Bình Sơn",
                "code": 524,
                "codename": "huyen_binh_son",
                "division_type": "huyện",
                "short_codename": "binh_son",
                "wards": [
                    {
                        "name": "Thị Trấn Châu Ổ",
                        "code": 21040,
                        "codename": "thi_tran_chau_o",
                        "division_type": "thị trấn",
                        "short_codename": "chau_o"
                    },
                    {
                        "name": "Xã Bình Thuận",
                        "code": 21043,
                        "codename": "xa_binh_thuan",
                        "division_type": "xã",
                        "short_codename": "binh_thuan"
                    },
                    {
                        "name": "Xã Bình Thạnh",
                        "code": 21046,
                        "codename": "xa_binh_thanh",
                        "division_type": "xã",
                        "short_codename": "xa_binh_thanh"
                    },
                    {
                        "name": "Xã Bình Đông",
                        "code": 21049,
                        "codename": "xa_binh_dong",
                        "division_type": "xã",
                        "short_codename": "binh_dong"
                    },
                    {
                        "name": "Xã Bình Chánh",
                        "code": 21052,
                        "codename": "xa_binh_chanh",
                        "division_type": "xã",
                        "short_codename": "binh_chanh"
                    },
                    {
                        "name": "Xã Bình Nguyên",
                        "code": 21055,
                        "codename": "xa_binh_nguyen",
                        "division_type": "xã",
                        "short_codename": "binh_nguyen"
                    },
                    {
                        "name": "Xã Bình Khương",
                        "code": 21058,
                        "codename": "xa_binh_khuong",
                        "division_type": "xã",
                        "short_codename": "binh_khuong"
                    },
                    {
                        "name": "Xã Bình Trị",
                        "code": 21061,
                        "codename": "xa_binh_tri",
                        "division_type": "xã",
                        "short_codename": "binh_tri"
                    },
                    {
                        "name": "Xã Bình An",
                        "code": 21064,
                        "codename": "xa_binh_an",
                        "division_type": "xã",
                        "short_codename": "binh_an"
                    },
                    {
                        "name": "Xã Bình Hải",
                        "code": 21067,
                        "codename": "xa_binh_hai",
                        "division_type": "xã",
                        "short_codename": "binh_hai"
                    },
                    {
                        "name": "Xã Bình Dương",
                        "code": 21070,
                        "codename": "xa_binh_duong",
                        "division_type": "xã",
                        "short_codename": "binh_duong"
                    },
                    {
                        "name": "Xã Bình Phước",
                        "code": 21073,
                        "codename": "xa_binh_phuoc",
                        "division_type": "xã",
                        "short_codename": "binh_phuoc"
                    },
                    {
                        "name": "Xã Bình Hòa",
                        "code": 21079,
                        "codename": "xa_binh_hoa",
                        "division_type": "xã",
                        "short_codename": "binh_hoa"
                    },
                    {
                        "name": "Xã Bình Trung",
                        "code": 21082,
                        "codename": "xa_binh_trung",
                        "division_type": "xã",
                        "short_codename": "binh_trung"
                    },
                    {
                        "name": "Xã Bình Minh",
                        "code": 21085,
                        "codename": "xa_binh_minh",
                        "division_type": "xã",
                        "short_codename": "binh_minh"
                    },
                    {
                        "name": "Xã Bình Long",
                        "code": 21088,
                        "codename": "xa_binh_long",
                        "division_type": "xã",
                        "short_codename": "binh_long"
                    },
                    {
                        "name": "Xã Bình Thanh",
                        "code": 21091,
                        "codename": "xa_binh_thanh",
                        "division_type": "xã",
                        "short_codename": "xa_binh_thanh"
                    },
                    {
                        "name": "Xã Bình Chương",
                        "code": 21100,
                        "codename": "xa_binh_chuong",
                        "division_type": "xã",
                        "short_codename": "binh_chuong"
                    },
                    {
                        "name": "Xã Bình Hiệp",
                        "code": 21103,
                        "codename": "xa_binh_hiep",
                        "division_type": "xã",
                        "short_codename": "binh_hiep"
                    },
                    {
                        "name": "Xã Bình Mỹ",
                        "code": 21106,
                        "codename": "xa_binh_my",
                        "division_type": "xã",
                        "short_codename": "binh_my"
                    },
                    {
                        "name": "Xã Bình Tân Phú",
                        "code": 21109,
                        "codename": "xa_binh_tan_phu",
                        "division_type": "xã",
                        "short_codename": "binh_tan_phu"
                    },
                    {
                        "name": "Xã Bình Châu",
                        "code": 21112,
                        "codename": "xa_binh_chau",
                        "division_type": "xã",
                        "short_codename": "binh_chau"
                    }
                ]
            },
            {
                "name": "Huyện Trà Bồng",
                "code": 525,
                "codename": "huyen_tra_bong",
                "division_type": "huyện",
                "short_codename": "tra_bong",
                "wards": [
                    {
                        "name": "Thị trấn Trà Xuân",
                        "code": 21115,
                        "codename": "thi_tran_tra_xuan",
                        "division_type": "thị trấn",
                        "short_codename": "tra_xuan"
                    },
                    {
                        "name": "Xã Trà Giang",
                        "code": 21118,
                        "codename": "xa_tra_giang",
                        "division_type": "xã",
                        "short_codename": "tra_giang"
                    },
                    {
                        "name": "Xã Trà Thủy",
                        "code": 21121,
                        "codename": "xa_tra_thuy",
                        "division_type": "xã",
                        "short_codename": "tra_thuy"
                    },
                    {
                        "name": "Xã Trà Hiệp",
                        "code": 21124,
                        "codename": "xa_tra_hiep",
                        "division_type": "xã",
                        "short_codename": "tra_hiep"
                    },
                    {
                        "name": "Xã Trà Bình",
                        "code": 21127,
                        "codename": "xa_tra_binh",
                        "division_type": "xã",
                        "short_codename": "tra_binh"
                    },
                    {
                        "name": "Xã Trà Phú",
                        "code": 21130,
                        "codename": "xa_tra_phu",
                        "division_type": "xã",
                        "short_codename": "tra_phu"
                    },
                    {
                        "name": "Xã Trà Lâm",
                        "code": 21133,
                        "codename": "xa_tra_lam",
                        "division_type": "xã",
                        "short_codename": "tra_lam"
                    },
                    {
                        "name": "Xã Trà Tân",
                        "code": 21136,
                        "codename": "xa_tra_tan",
                        "division_type": "xã",
                        "short_codename": "tra_tan"
                    },
                    {
                        "name": "Xã Trà Sơn",
                        "code": 21139,
                        "codename": "xa_tra_son",
                        "division_type": "xã",
                        "short_codename": "tra_son"
                    },
                    {
                        "name": "Xã Trà Bùi",
                        "code": 21142,
                        "codename": "xa_tra_bui",
                        "division_type": "xã",
                        "short_codename": "tra_bui"
                    },
                    {
                        "name": "Xã Trà Thanh",
                        "code": 21145,
                        "codename": "xa_tra_thanh",
                        "division_type": "xã",
                        "short_codename": "tra_thanh"
                    },
                    {
                        "name": "Xã Sơn Trà",
                        "code": 21148,
                        "codename": "xa_son_tra",
                        "division_type": "xã",
                        "short_codename": "son_tra"
                    },
                    {
                        "name": "Xã Trà Phong",
                        "code": 21154,
                        "codename": "xa_tra_phong",
                        "division_type": "xã",
                        "short_codename": "tra_phong"
                    },
                    {
                        "name": "Xã Hương Trà",
                        "code": 21157,
                        "codename": "xa_huong_tra",
                        "division_type": "xã",
                        "short_codename": "huong_tra"
                    },
                    {
                        "name": "Xã Trà Xinh",
                        "code": 21163,
                        "codename": "xa_tra_xinh",
                        "division_type": "xã",
                        "short_codename": "tra_xinh"
                    },
                    {
                        "name": "Xã Trà Tây",
                        "code": 21166,
                        "codename": "xa_tra_tay",
                        "division_type": "xã",
                        "short_codename": "tra_tay"
                    }
                ]
            },
            {
                "name": "Huyện Sơn Tịnh",
                "code": 527,
                "codename": "huyen_son_tinh",
                "division_type": "huyện",
                "short_codename": "son_tinh",
                "wards": [
                    {
                        "name": "Xã Tịnh Thọ",
                        "code": 21175,
                        "codename": "xa_tinh_tho",
                        "division_type": "xã",
                        "short_codename": "tinh_tho"
                    },
                    {
                        "name": "Xã Tịnh Trà",
                        "code": 21178,
                        "codename": "xa_tinh_tra",
                        "division_type": "xã",
                        "short_codename": "tinh_tra"
                    },
                    {
                        "name": "Xã Tịnh Phong",
                        "code": 21181,
                        "codename": "xa_tinh_phong",
                        "division_type": "xã",
                        "short_codename": "tinh_phong"
                    },
                    {
                        "name": "Xã Tịnh Hiệp",
                        "code": 21184,
                        "codename": "xa_tinh_hiep",
                        "division_type": "xã",
                        "short_codename": "tinh_hiep"
                    },
                    {
                        "name": "Xã Tịnh Bình",
                        "code": 21193,
                        "codename": "xa_tinh_binh",
                        "division_type": "xã",
                        "short_codename": "tinh_binh"
                    },
                    {
                        "name": "Xã Tịnh Đông",
                        "code": 21196,
                        "codename": "xa_tinh_dong",
                        "division_type": "xã",
                        "short_codename": "tinh_dong"
                    },
                    {
                        "name": "Xã Tịnh Bắc",
                        "code": 21205,
                        "codename": "xa_tinh_bac",
                        "division_type": "xã",
                        "short_codename": "tinh_bac"
                    },
                    {
                        "name": "Xã Tịnh Sơn",
                        "code": 21217,
                        "codename": "xa_tinh_son",
                        "division_type": "xã",
                        "short_codename": "tinh_son"
                    },
                    {
                        "name": "Xã Tịnh Hà",
                        "code": 21220,
                        "codename": "xa_tinh_ha",
                        "division_type": "xã",
                        "short_codename": "tinh_ha"
                    },
                    {
                        "name": "Xã Tịnh Giang",
                        "code": 21226,
                        "codename": "xa_tinh_giang",
                        "division_type": "xã",
                        "short_codename": "tinh_giang"
                    },
                    {
                        "name": "Xã Tịnh Minh",
                        "code": 21229,
                        "codename": "xa_tinh_minh",
                        "division_type": "xã",
                        "short_codename": "tinh_minh"
                    }
                ]
            },
            {
                "name": "Huyện Tư Nghĩa",
                "code": 528,
                "codename": "huyen_tu_nghia",
                "division_type": "huyện",
                "short_codename": "tu_nghia",
                "wards": [
                    {
                        "name": "Thị trấn La Hà",
                        "code": 21235,
                        "codename": "thi_tran_la_ha",
                        "division_type": "thị trấn",
                        "short_codename": "la_ha"
                    },
                    {
                        "name": "Thị trấn Sông Vệ",
                        "code": 21238,
                        "codename": "thi_tran_song_ve",
                        "division_type": "thị trấn",
                        "short_codename": "song_ve"
                    },
                    {
                        "name": "Xã Nghĩa Lâm",
                        "code": 21241,
                        "codename": "xa_nghia_lam",
                        "division_type": "xã",
                        "short_codename": "nghia_lam"
                    },
                    {
                        "name": "Xã Nghĩa Thắng",
                        "code": 21244,
                        "codename": "xa_nghia_thang",
                        "division_type": "xã",
                        "short_codename": "nghia_thang"
                    },
                    {
                        "name": "Xã Nghĩa Thuận",
                        "code": 21247,
                        "codename": "xa_nghia_thuan",
                        "division_type": "xã",
                        "short_codename": "nghia_thuan"
                    },
                    {
                        "name": "Xã Nghĩa Kỳ",
                        "code": 21250,
                        "codename": "xa_nghia_ky",
                        "division_type": "xã",
                        "short_codename": "nghia_ky"
                    },
                    {
                        "name": "Xã Nghĩa Sơn",
                        "code": 21259,
                        "codename": "xa_nghia_son",
                        "division_type": "xã",
                        "short_codename": "nghia_son"
                    },
                    {
                        "name": "Xã Nghĩa Hòa",
                        "code": 21268,
                        "codename": "xa_nghia_hoa",
                        "division_type": "xã",
                        "short_codename": "nghia_hoa"
                    },
                    {
                        "name": "Xã Nghĩa Điền",
                        "code": 21271,
                        "codename": "xa_nghia_dien",
                        "division_type": "xã",
                        "short_codename": "nghia_dien"
                    },
                    {
                        "name": "Xã Nghĩa Thương",
                        "code": 21274,
                        "codename": "xa_nghia_thuong",
                        "division_type": "xã",
                        "short_codename": "nghia_thuong"
                    },
                    {
                        "name": "Xã Nghĩa Trung",
                        "code": 21277,
                        "codename": "xa_nghia_trung",
                        "division_type": "xã",
                        "short_codename": "nghia_trung"
                    },
                    {
                        "name": "Xã Nghĩa Hiệp",
                        "code": 21280,
                        "codename": "xa_nghia_hiep",
                        "division_type": "xã",
                        "short_codename": "nghia_hiep"
                    },
                    {
                        "name": "Xã Nghĩa Phương",
                        "code": 21283,
                        "codename": "xa_nghia_phuong",
                        "division_type": "xã",
                        "short_codename": "nghia_phuong"
                    },
                    {
                        "name": "Xã Nghĩa Mỹ",
                        "code": 21286,
                        "codename": "xa_nghia_my",
                        "division_type": "xã",
                        "short_codename": "nghia_my"
                    }
                ]
            },
            {
                "name": "Huyện Sơn Hà",
                "code": 529,
                "codename": "huyen_son_ha",
                "division_type": "huyện",
                "short_codename": "son_ha",
                "wards": [
                    {
                        "name": "Thị trấn Di Lăng",
                        "code": 21289,
                        "codename": "thi_tran_di_lang",
                        "division_type": "thị trấn",
                        "short_codename": "di_lang"
                    },
                    {
                        "name": "Xã Sơn Hạ",
                        "code": 21292,
                        "codename": "xa_son_ha",
                        "division_type": "xã",
                        "short_codename": "son_ha"
                    },
                    {
                        "name": "Xã Sơn Thành",
                        "code": 21295,
                        "codename": "xa_son_thanh",
                        "division_type": "xã",
                        "short_codename": "son_thanh"
                    },
                    {
                        "name": "Xã Sơn Nham",
                        "code": 21298,
                        "codename": "xa_son_nham",
                        "division_type": "xã",
                        "short_codename": "son_nham"
                    },
                    {
                        "name": "Xã Sơn Bao",
                        "code": 21301,
                        "codename": "xa_son_bao",
                        "division_type": "xã",
                        "short_codename": "son_bao"
                    },
                    {
                        "name": "Xã Sơn Linh",
                        "code": 21304,
                        "codename": "xa_son_linh",
                        "division_type": "xã",
                        "short_codename": "son_linh"
                    },
                    {
                        "name": "Xã Sơn Giang",
                        "code": 21307,
                        "codename": "xa_son_giang",
                        "division_type": "xã",
                        "short_codename": "son_giang"
                    },
                    {
                        "name": "Xã Sơn Trung",
                        "code": 21310,
                        "codename": "xa_son_trung",
                        "division_type": "xã",
                        "short_codename": "son_trung"
                    },
                    {
                        "name": "Xã Sơn Thượng",
                        "code": 21313,
                        "codename": "xa_son_thuong",
                        "division_type": "xã",
                        "short_codename": "son_thuong"
                    },
                    {
                        "name": "Xã Sơn Cao",
                        "code": 21316,
                        "codename": "xa_son_cao",
                        "division_type": "xã",
                        "short_codename": "son_cao"
                    },
                    {
                        "name": "Xã Sơn Hải",
                        "code": 21319,
                        "codename": "xa_son_hai",
                        "division_type": "xã",
                        "short_codename": "son_hai"
                    },
                    {
                        "name": "Xã Sơn Thủy",
                        "code": 21322,
                        "codename": "xa_son_thuy",
                        "division_type": "xã",
                        "short_codename": "son_thuy"
                    },
                    {
                        "name": "Xã Sơn Kỳ",
                        "code": 21325,
                        "codename": "xa_son_ky",
                        "division_type": "xã",
                        "short_codename": "son_ky"
                    },
                    {
                        "name": "Xã Sơn Ba",
                        "code": 21328,
                        "codename": "xa_son_ba",
                        "division_type": "xã",
                        "short_codename": "son_ba"
                    }
                ]
            },
            {
                "name": "Huyện Sơn Tây",
                "code": 530,
                "codename": "huyen_son_tay",
                "division_type": "huyện",
                "short_codename": "son_tay",
                "wards": [
                    {
                        "name": "Xã Sơn Bua",
                        "code": 21331,
                        "codename": "xa_son_bua",
                        "division_type": "xã",
                        "short_codename": "son_bua"
                    },
                    {
                        "name": "Xã Sơn Mùa",
                        "code": 21334,
                        "codename": "xa_son_mua",
                        "division_type": "xã",
                        "short_codename": "son_mua"
                    },
                    {
                        "name": "Xã Sơn Liên",
                        "code": 21335,
                        "codename": "xa_son_lien",
                        "division_type": "xã",
                        "short_codename": "son_lien"
                    },
                    {
                        "name": "Xã Sơn Tân",
                        "code": 21337,
                        "codename": "xa_son_tan",
                        "division_type": "xã",
                        "short_codename": "son_tan"
                    },
                    {
                        "name": "Xã Sơn Màu",
                        "code": 21338,
                        "codename": "xa_son_mau",
                        "division_type": "xã",
                        "short_codename": "son_mau"
                    },
                    {
                        "name": "Xã Sơn Dung",
                        "code": 21340,
                        "codename": "xa_son_dung",
                        "division_type": "xã",
                        "short_codename": "son_dung"
                    },
                    {
                        "name": "Xã Sơn Long",
                        "code": 21341,
                        "codename": "xa_son_long",
                        "division_type": "xã",
                        "short_codename": "son_long"
                    },
                    {
                        "name": "Xã Sơn Tinh",
                        "code": 21343,
                        "codename": "xa_son_tinh",
                        "division_type": "xã",
                        "short_codename": "son_tinh"
                    },
                    {
                        "name": "Xã Sơn Lập",
                        "code": 21346,
                        "codename": "xa_son_lap",
                        "division_type": "xã",
                        "short_codename": "son_lap"
                    }
                ]
            },
            {
                "name": "Huyện Minh Long",
                "code": 531,
                "codename": "huyen_minh_long",
                "division_type": "huyện",
                "short_codename": "minh_long",
                "wards": [
                    {
                        "name": "Xã Long Sơn",
                        "code": 21349,
                        "codename": "xa_long_son",
                        "division_type": "xã",
                        "short_codename": "long_son"
                    },
                    {
                        "name": "Xã Long Mai",
                        "code": 21352,
                        "codename": "xa_long_mai",
                        "division_type": "xã",
                        "short_codename": "long_mai"
                    },
                    {
                        "name": "Xã Thanh An",
                        "code": 21355,
                        "codename": "xa_thanh_an",
                        "division_type": "xã",
                        "short_codename": "thanh_an"
                    },
                    {
                        "name": "Xã Long Môn",
                        "code": 21358,
                        "codename": "xa_long_mon",
                        "division_type": "xã",
                        "short_codename": "long_mon"
                    },
                    {
                        "name": "Xã Long Hiệp",
                        "code": 21361,
                        "codename": "xa_long_hiep",
                        "division_type": "xã",
                        "short_codename": "long_hiep"
                    }
                ]
            },
            {
                "name": "Huyện Nghĩa Hành",
                "code": 532,
                "codename": "huyen_nghia_hanh",
                "division_type": "huyện",
                "short_codename": "nghia_hanh",
                "wards": [
                    {
                        "name": "Thị trấn Chợ Chùa",
                        "code": 21364,
                        "codename": "thi_tran_cho_chua",
                        "division_type": "thị trấn",
                        "short_codename": "cho_chua"
                    },
                    {
                        "name": "Xã Hành Thuận",
                        "code": 21367,
                        "codename": "xa_hanh_thuan",
                        "division_type": "xã",
                        "short_codename": "hanh_thuan"
                    },
                    {
                        "name": "Xã Hành Dũng",
                        "code": 21370,
                        "codename": "xa_hanh_dung",
                        "division_type": "xã",
                        "short_codename": "hanh_dung"
                    },
                    {
                        "name": "Xã Hành Trung",
                        "code": 21373,
                        "codename": "xa_hanh_trung",
                        "division_type": "xã",
                        "short_codename": "hanh_trung"
                    },
                    {
                        "name": "Xã Hành Nhân",
                        "code": 21376,
                        "codename": "xa_hanh_nhan",
                        "division_type": "xã",
                        "short_codename": "hanh_nhan"
                    },
                    {
                        "name": "Xã Hành Đức",
                        "code": 21379,
                        "codename": "xa_hanh_duc",
                        "division_type": "xã",
                        "short_codename": "hanh_duc"
                    },
                    {
                        "name": "Xã Hành Minh",
                        "code": 21382,
                        "codename": "xa_hanh_minh",
                        "division_type": "xã",
                        "short_codename": "hanh_minh"
                    },
                    {
                        "name": "Xã Hành Phước",
                        "code": 21385,
                        "codename": "xa_hanh_phuoc",
                        "division_type": "xã",
                        "short_codename": "hanh_phuoc"
                    },
                    {
                        "name": "Xã Hành Thiện",
                        "code": 21388,
                        "codename": "xa_hanh_thien",
                        "division_type": "xã",
                        "short_codename": "hanh_thien"
                    },
                    {
                        "name": "Xã Hành Thịnh",
                        "code": 21391,
                        "codename": "xa_hanh_thinh",
                        "division_type": "xã",
                        "short_codename": "hanh_thinh"
                    },
                    {
                        "name": "Xã Hành Tín Tây",
                        "code": 21394,
                        "codename": "xa_hanh_tin_tay",
                        "division_type": "xã",
                        "short_codename": "hanh_tin_tay"
                    },
                    {
                        "name": "Xã Hành Tín Đông",
                        "code": 21397,
                        "codename": "xa_hanh_tin_dong",
                        "division_type": "xã",
                        "short_codename": "hanh_tin_dong"
                    }
                ]
            },
            {
                "name": "Huyện Mộ Đức",
                "code": 533,
                "codename": "huyen_mo_duc",
                "division_type": "huyện",
                "short_codename": "mo_duc",
                "wards": [
                    {
                        "name": "Thị trấn Mộ Đức",
                        "code": 21400,
                        "codename": "thi_tran_mo_duc",
                        "division_type": "thị trấn",
                        "short_codename": "mo_duc"
                    },
                    {
                        "name": "Xã Đức Lợi",
                        "code": 21403,
                        "codename": "xa_duc_loi",
                        "division_type": "xã",
                        "short_codename": "duc_loi"
                    },
                    {
                        "name": "Xã Đức Thắng",
                        "code": 21406,
                        "codename": "xa_duc_thang",
                        "division_type": "xã",
                        "short_codename": "duc_thang"
                    },
                    {
                        "name": "Xã Đức Nhuận",
                        "code": 21409,
                        "codename": "xa_duc_nhuan",
                        "division_type": "xã",
                        "short_codename": "duc_nhuan"
                    },
                    {
                        "name": "Xã Đức Chánh",
                        "code": 21412,
                        "codename": "xa_duc_chanh",
                        "division_type": "xã",
                        "short_codename": "duc_chanh"
                    },
                    {
                        "name": "Xã Đức Hiệp",
                        "code": 21415,
                        "codename": "xa_duc_hiep",
                        "division_type": "xã",
                        "short_codename": "duc_hiep"
                    },
                    {
                        "name": "Xã Đức Minh",
                        "code": 21418,
                        "codename": "xa_duc_minh",
                        "division_type": "xã",
                        "short_codename": "duc_minh"
                    },
                    {
                        "name": "Xã Đức Thạnh",
                        "code": 21421,
                        "codename": "xa_duc_thanh",
                        "division_type": "xã",
                        "short_codename": "duc_thanh"
                    },
                    {
                        "name": "Xã Đức Hòa",
                        "code": 21424,
                        "codename": "xa_duc_hoa",
                        "division_type": "xã",
                        "short_codename": "duc_hoa"
                    },
                    {
                        "name": "Xã Đức Tân",
                        "code": 21427,
                        "codename": "xa_duc_tan",
                        "division_type": "xã",
                        "short_codename": "duc_tan"
                    },
                    {
                        "name": "Xã Đức Phú",
                        "code": 21430,
                        "codename": "xa_duc_phu",
                        "division_type": "xã",
                        "short_codename": "duc_phu"
                    },
                    {
                        "name": "Xã Đức Phong",
                        "code": 21433,
                        "codename": "xa_duc_phong",
                        "division_type": "xã",
                        "short_codename": "duc_phong"
                    },
                    {
                        "name": "Xã Đức Lân",
                        "code": 21436,
                        "codename": "xa_duc_lan",
                        "division_type": "xã",
                        "short_codename": "duc_lan"
                    }
                ]
            },
            {
                "name": "Thị xã Đức Phổ",
                "code": 534,
                "codename": "thi_xa_duc_pho",
                "division_type": "thị xã",
                "short_codename": "duc_pho",
                "wards": [
                    {
                        "name": "Phường Nguyễn Nghiêm",
                        "code": 21439,
                        "codename": "phuong_nguyen_nghiem",
                        "division_type": "phường",
                        "short_codename": "nguyen_nghiem"
                    },
                    {
                        "name": "Xã Phổ An",
                        "code": 21442,
                        "codename": "xa_pho_an",
                        "division_type": "xã",
                        "short_codename": "pho_an"
                    },
                    {
                        "name": "Xã Phổ Phong",
                        "code": 21445,
                        "codename": "xa_pho_phong",
                        "division_type": "xã",
                        "short_codename": "pho_phong"
                    },
                    {
                        "name": "Xã Phổ Thuận",
                        "code": 21448,
                        "codename": "xa_pho_thuan",
                        "division_type": "xã",
                        "short_codename": "pho_thuan"
                    },
                    {
                        "name": "Phường Phổ Văn",
                        "code": 21451,
                        "codename": "phuong_pho_van",
                        "division_type": "phường",
                        "short_codename": "pho_van"
                    },
                    {
                        "name": "Phường Phổ Quang",
                        "code": 21454,
                        "codename": "phuong_pho_quang",
                        "division_type": "phường",
                        "short_codename": "pho_quang"
                    },
                    {
                        "name": "Xã Phổ Nhơn",
                        "code": 21457,
                        "codename": "xa_pho_nhon",
                        "division_type": "xã",
                        "short_codename": "pho_nhon"
                    },
                    {
                        "name": "Phường Phổ Ninh",
                        "code": 21460,
                        "codename": "phuong_pho_ninh",
                        "division_type": "phường",
                        "short_codename": "pho_ninh"
                    },
                    {
                        "name": "Phường Phổ Minh",
                        "code": 21463,
                        "codename": "phuong_pho_minh",
                        "division_type": "phường",
                        "short_codename": "pho_minh"
                    },
                    {
                        "name": "Phường Phổ Vinh",
                        "code": 21466,
                        "codename": "phuong_pho_vinh",
                        "division_type": "phường",
                        "short_codename": "pho_vinh"
                    },
                    {
                        "name": "Phường Phổ Hòa",
                        "code": 21469,
                        "codename": "phuong_pho_hoa",
                        "division_type": "phường",
                        "short_codename": "pho_hoa"
                    },
                    {
                        "name": "Xã Phổ Cường",
                        "code": 21472,
                        "codename": "xa_pho_cuong",
                        "division_type": "xã",
                        "short_codename": "pho_cuong"
                    },
                    {
                        "name": "Xã Phổ Khánh",
                        "code": 21475,
                        "codename": "xa_pho_khanh",
                        "division_type": "xã",
                        "short_codename": "pho_khanh"
                    },
                    {
                        "name": "Phường Phổ Thạnh",
                        "code": 21478,
                        "codename": "phuong_pho_thanh",
                        "division_type": "phường",
                        "short_codename": "pho_thanh"
                    },
                    {
                        "name": "Xã Phổ Châu",
                        "code": 21481,
                        "codename": "xa_pho_chau",
                        "division_type": "xã",
                        "short_codename": "pho_chau"
                    }
                ]
            },
            {
                "name": "Huyện Ba Tơ",
                "code": 535,
                "codename": "huyen_ba_to",
                "division_type": "huyện",
                "short_codename": "ba_to",
                "wards": [
                    {
                        "name": "Thị trấn Ba Tơ",
                        "code": 21484,
                        "codename": "thi_tran_ba_to",
                        "division_type": "thị trấn",
                        "short_codename": "ba_to"
                    },
                    {
                        "name": "Xã Ba Điền",
                        "code": 21487,
                        "codename": "xa_ba_dien",
                        "division_type": "xã",
                        "short_codename": "ba_dien"
                    },
                    {
                        "name": "Xã Ba Vinh",
                        "code": 21490,
                        "codename": "xa_ba_vinh",
                        "division_type": "xã",
                        "short_codename": "ba_vinh"
                    },
                    {
                        "name": "Xã Ba Thành",
                        "code": 21493,
                        "codename": "xa_ba_thanh",
                        "division_type": "xã",
                        "short_codename": "ba_thanh"
                    },
                    {
                        "name": "Xã Ba Động",
                        "code": 21496,
                        "codename": "xa_ba_dong",
                        "division_type": "xã",
                        "short_codename": "ba_dong"
                    },
                    {
                        "name": "Xã Ba Dinh",
                        "code": 21499,
                        "codename": "xa_ba_dinh",
                        "division_type": "xã",
                        "short_codename": "ba_dinh"
                    },
                    {
                        "name": "Xã Ba Giang",
                        "code": 21500,
                        "codename": "xa_ba_giang",
                        "division_type": "xã",
                        "short_codename": "ba_giang"
                    },
                    {
                        "name": "Xã Ba Liên",
                        "code": 21502,
                        "codename": "xa_ba_lien",
                        "division_type": "xã",
                        "short_codename": "ba_lien"
                    },
                    {
                        "name": "Xã Ba Ngạc",
                        "code": 21505,
                        "codename": "xa_ba_ngac",
                        "division_type": "xã",
                        "short_codename": "ba_ngac"
                    },
                    {
                        "name": "Xã Ba Khâm",
                        "code": 21508,
                        "codename": "xa_ba_kham",
                        "division_type": "xã",
                        "short_codename": "ba_kham"
                    },
                    {
                        "name": "Xã Ba Cung",
                        "code": 21511,
                        "codename": "xa_ba_cung",
                        "division_type": "xã",
                        "short_codename": "ba_cung"
                    },
                    {
                        "name": "Xã Ba Tiêu",
                        "code": 21517,
                        "codename": "xa_ba_tieu",
                        "division_type": "xã",
                        "short_codename": "ba_tieu"
                    },
                    {
                        "name": "Xã Ba Trang",
                        "code": 21520,
                        "codename": "xa_ba_trang",
                        "division_type": "xã",
                        "short_codename": "ba_trang"
                    },
                    {
                        "name": "Xã Ba Tô",
                        "code": 21523,
                        "codename": "xa_ba_to",
                        "division_type": "xã",
                        "short_codename": "xa_ba_to"
                    },
                    {
                        "name": "Xã Ba Bích",
                        "code": 21526,
                        "codename": "xa_ba_bich",
                        "division_type": "xã",
                        "short_codename": "ba_bich"
                    },
                    {
                        "name": "Xã Ba Vì",
                        "code": 21529,
                        "codename": "xa_ba_vi",
                        "division_type": "xã",
                        "short_codename": "ba_vi"
                    },
                    {
                        "name": "Xã Ba Lế",
                        "code": 21532,
                        "codename": "xa_ba_le",
                        "division_type": "xã",
                        "short_codename": "ba_le"
                    },
                    {
                        "name": "Xã Ba Nam",
                        "code": 21535,
                        "codename": "xa_ba_nam",
                        "division_type": "xã",
                        "short_codename": "ba_nam"
                    },
                    {
                        "name": "Xã Ba Xa",
                        "code": 21538,
                        "codename": "xa_ba_xa",
                        "division_type": "xã",
                        "short_codename": "ba_xa"
                    }
                ]
            },
            {
                "name": "Huyện Lý Sơn",
                "code": 536,
                "codename": "huyen_ly_son",
                "division_type": "huyện",
                "short_codename": "ly_son",
                "wards": []
            }
        ]
    },
    {
        "name": "Tỉnh Bình Định",
        "code": 52,
        "codename": "tinh_binh_dinh",
        "division_type": "tỉnh",
        "phone_code": 256,
        "districts": [
            {
                "name": "Thành phố Quy Nhơn",
                "code": 540,
                "codename": "thanh_pho_quy_nhon",
                "division_type": "thành phố",
                "short_codename": "quy_nhon",
                "wards": [
                    {
                        "name": "Phường Nhơn Bình",
                        "code": 21550,
                        "codename": "phuong_nhon_binh",
                        "division_type": "phường",
                        "short_codename": "nhon_binh"
                    },
                    {
                        "name": "Phường Nhơn Phú",
                        "code": 21553,
                        "codename": "phuong_nhon_phu",
                        "division_type": "phường",
                        "short_codename": "nhon_phu"
                    },
                    {
                        "name": "Phường Đống Đa",
                        "code": 21556,
                        "codename": "phuong_dong_da",
                        "division_type": "phường",
                        "short_codename": "dong_da"
                    },
                    {
                        "name": "Phường Trần Quang Diệu",
                        "code": 21559,
                        "codename": "phuong_tran_quang_dieu",
                        "division_type": "phường",
                        "short_codename": "tran_quang_dieu"
                    },
                    {
                        "name": "Phường Hải Cảng",
                        "code": 21562,
                        "codename": "phuong_hai_cang",
                        "division_type": "phường",
                        "short_codename": "hai_cang"
                    },
                    {
                        "name": "Phường Quang Trung",
                        "code": 21565,
                        "codename": "phuong_quang_trung",
                        "division_type": "phường",
                        "short_codename": "quang_trung"
                    },
                    {
                        "name": "Phường Thị Nại",
                        "code": 21568,
                        "codename": "phuong_thi_nai",
                        "division_type": "phường",
                        "short_codename": "thi_nai"
                    },
                    {
                        "name": "Phường Lê Hồng Phong",
                        "code": 21571,
                        "codename": "phuong_le_hong_phong",
                        "division_type": "phường",
                        "short_codename": "le_hong_phong"
                    },
                    {
                        "name": "Phường Trần Hưng Đạo",
                        "code": 21574,
                        "codename": "phuong_tran_hung_dao",
                        "division_type": "phường",
                        "short_codename": "tran_hung_dao"
                    },
                    {
                        "name": "Phường Ngô Mây",
                        "code": 21577,
                        "codename": "phuong_ngo_may",
                        "division_type": "phường",
                        "short_codename": "ngo_may"
                    },
                    {
                        "name": "Phường Lý Thường Kiệt",
                        "code": 21580,
                        "codename": "phuong_ly_thuong_kiet",
                        "division_type": "phường",
                        "short_codename": "ly_thuong_kiet"
                    },
                    {
                        "name": "Phường Lê Lợi",
                        "code": 21583,
                        "codename": "phuong_le_loi",
                        "division_type": "phường",
                        "short_codename": "le_loi"
                    },
                    {
                        "name": "Phường Trần Phú",
                        "code": 21586,
                        "codename": "phuong_tran_phu",
                        "division_type": "phường",
                        "short_codename": "tran_phu"
                    },
                    {
                        "name": "Phường Bùi Thị Xuân",
                        "code": 21589,
                        "codename": "phuong_bui_thi_xuan",
                        "division_type": "phường",
                        "short_codename": "bui_thi_xuan"
                    },
                    {
                        "name": "Phường Nguyễn Văn Cừ",
                        "code": 21592,
                        "codename": "phuong_nguyen_van_cu",
                        "division_type": "phường",
                        "short_codename": "nguyen_van_cu"
                    },
                    {
                        "name": "Phường Ghềnh Ráng",
                        "code": 21595,
                        "codename": "phuong_ghenh_rang",
                        "division_type": "phường",
                        "short_codename": "ghenh_rang"
                    },
                    {
                        "name": "Xã Nhơn Lý",
                        "code": 21598,
                        "codename": "xa_nhon_ly",
                        "division_type": "xã",
                        "short_codename": "nhon_ly"
                    },
                    {
                        "name": "Xã Nhơn Hội",
                        "code": 21601,
                        "codename": "xa_nhon_hoi",
                        "division_type": "xã",
                        "short_codename": "nhon_hoi"
                    },
                    {
                        "name": "Xã Nhơn Hải",
                        "code": 21604,
                        "codename": "xa_nhon_hai",
                        "division_type": "xã",
                        "short_codename": "nhon_hai"
                    },
                    {
                        "name": "Xã Nhơn Châu",
                        "code": 21607,
                        "codename": "xa_nhon_chau",
                        "division_type": "xã",
                        "short_codename": "nhon_chau"
                    },
                    {
                        "name": "Xã Phước Mỹ",
                        "code": 21991,
                        "codename": "xa_phuoc_my",
                        "division_type": "xã",
                        "short_codename": "phuoc_my"
                    }
                ]
            },
            {
                "name": "Huyện An Lão",
                "code": 542,
                "codename": "huyen_an_lao",
                "division_type": "huyện",
                "short_codename": "an_lao",
                "wards": [
                    {
                        "name": "Thị trấn An Lão",
                        "code": 21609,
                        "codename": "thi_tran_an_lao",
                        "division_type": "thị trấn",
                        "short_codename": "an_lao"
                    },
                    {
                        "name": "Xã An Hưng",
                        "code": 21610,
                        "codename": "xa_an_hung",
                        "division_type": "xã",
                        "short_codename": "an_hung"
                    },
                    {
                        "name": "Xã An Trung",
                        "code": 21613,
                        "codename": "xa_an_trung",
                        "division_type": "xã",
                        "short_codename": "an_trung"
                    },
                    {
                        "name": "Xã An Dũng",
                        "code": 21616,
                        "codename": "xa_an_dung",
                        "division_type": "xã",
                        "short_codename": "an_dung"
                    },
                    {
                        "name": "Xã An Vinh",
                        "code": 21619,
                        "codename": "xa_an_vinh",
                        "division_type": "xã",
                        "short_codename": "an_vinh"
                    },
                    {
                        "name": "Xã An Toàn",
                        "code": 21622,
                        "codename": "xa_an_toan",
                        "division_type": "xã",
                        "short_codename": "an_toan"
                    },
                    {
                        "name": "Xã An Tân",
                        "code": 21625,
                        "codename": "xa_an_tan",
                        "division_type": "xã",
                        "short_codename": "an_tan"
                    },
                    {
                        "name": "Xã An Hòa",
                        "code": 21628,
                        "codename": "xa_an_hoa",
                        "division_type": "xã",
                        "short_codename": "an_hoa"
                    },
                    {
                        "name": "Xã An Quang",
                        "code": 21631,
                        "codename": "xa_an_quang",
                        "division_type": "xã",
                        "short_codename": "an_quang"
                    },
                    {
                        "name": "Xã An Nghĩa",
                        "code": 21634,
                        "codename": "xa_an_nghia",
                        "division_type": "xã",
                        "short_codename": "an_nghia"
                    }
                ]
            },
            {
                "name": "Thị xã Hoài Nhơn",
                "code": 543,
                "codename": "thi_xa_hoai_nhon",
                "division_type": "thị xã",
                "short_codename": "hoai_nhon",
                "wards": [
                    {
                        "name": "Phường Tam Quan",
                        "code": 21637,
                        "codename": "phuong_tam_quan",
                        "division_type": "phường",
                        "short_codename": "tam_quan"
                    },
                    {
                        "name": "Phường Bồng Sơn",
                        "code": 21640,
                        "codename": "phuong_bong_son",
                        "division_type": "phường",
                        "short_codename": "bong_son"
                    },
                    {
                        "name": "Xã Hoài Sơn",
                        "code": 21643,
                        "codename": "xa_hoai_son",
                        "division_type": "xã",
                        "short_codename": "hoai_son"
                    },
                    {
                        "name": "Xã Hoài Châu Bắc",
                        "code": 21646,
                        "codename": "xa_hoai_chau_bac",
                        "division_type": "xã",
                        "short_codename": "hoai_chau_bac"
                    },
                    {
                        "name": "Xã Hoài Châu",
                        "code": 21649,
                        "codename": "xa_hoai_chau",
                        "division_type": "xã",
                        "short_codename": "hoai_chau"
                    },
                    {
                        "name": "Xã Hoài Phú",
                        "code": 21652,
                        "codename": "xa_hoai_phu",
                        "division_type": "xã",
                        "short_codename": "hoai_phu"
                    },
                    {
                        "name": "Phường Tam Quan Bắc",
                        "code": 21655,
                        "codename": "phuong_tam_quan_bac",
                        "division_type": "phường",
                        "short_codename": "tam_quan_bac"
                    },
                    {
                        "name": "Phường Tam Quan Nam",
                        "code": 21658,
                        "codename": "phuong_tam_quan_nam",
                        "division_type": "phường",
                        "short_codename": "tam_quan_nam"
                    },
                    {
                        "name": "Phường Hoài Hảo",
                        "code": 21661,
                        "codename": "phuong_hoai_hao",
                        "division_type": "phường",
                        "short_codename": "hoai_hao"
                    },
                    {
                        "name": "Phường Hoài Thanh Tây",
                        "code": 21664,
                        "codename": "phuong_hoai_thanh_tay",
                        "division_type": "phường",
                        "short_codename": "hoai_thanh_tay"
                    },
                    {
                        "name": "Phường Hoài Thanh",
                        "code": 21667,
                        "codename": "phuong_hoai_thanh",
                        "division_type": "phường",
                        "short_codename": "hoai_thanh"
                    },
                    {
                        "name": "Phường Hoài Hương",
                        "code": 21670,
                        "codename": "phuong_hoai_huong",
                        "division_type": "phường",
                        "short_codename": "hoai_huong"
                    },
                    {
                        "name": "Phường Hoài Tân",
                        "code": 21673,
                        "codename": "phuong_hoai_tan",
                        "division_type": "phường",
                        "short_codename": "hoai_tan"
                    },
                    {
                        "name": "Xã Hoài Hải",
                        "code": 21676,
                        "codename": "xa_hoai_hai",
                        "division_type": "xã",
                        "short_codename": "hoai_hai"
                    },
                    {
                        "name": "Phường Hoài Xuân",
                        "code": 21679,
                        "codename": "phuong_hoai_xuan",
                        "division_type": "phường",
                        "short_codename": "hoai_xuan"
                    },
                    {
                        "name": "Xã Hoài Mỹ",
                        "code": 21682,
                        "codename": "xa_hoai_my",
                        "division_type": "xã",
                        "short_codename": "hoai_my"
                    },
                    {
                        "name": "Phường Hoài Đức",
                        "code": 21685,
                        "codename": "phuong_hoai_duc",
                        "division_type": "phường",
                        "short_codename": "hoai_duc"
                    }
                ]
            },
            {
                "name": "Huyện Hoài Ân",
                "code": 544,
                "codename": "huyen_hoai_an",
                "division_type": "huyện",
                "short_codename": "hoai_an",
                "wards": [
                    {
                        "name": "Thị trấn Tăng Bạt Hổ",
                        "code": 21688,
                        "codename": "thi_tran_tang_bat_ho",
                        "division_type": "thị trấn",
                        "short_codename": "tang_bat_ho"
                    },
                    {
                        "name": "Xã Ân Hảo Tây",
                        "code": 21690,
                        "codename": "xa_an_hao_tay",
                        "division_type": "xã",
                        "short_codename": "an_hao_tay"
                    },
                    {
                        "name": "Xã Ân Hảo Đông",
                        "code": 21691,
                        "codename": "xa_an_hao_dong",
                        "division_type": "xã",
                        "short_codename": "an_hao_dong"
                    },
                    {
                        "name": "Xã Ân Sơn",
                        "code": 21694,
                        "codename": "xa_an_son",
                        "division_type": "xã",
                        "short_codename": "an_son"
                    },
                    {
                        "name": "Xã Ân Mỹ",
                        "code": 21697,
                        "codename": "xa_an_my",
                        "division_type": "xã",
                        "short_codename": "an_my"
                    },
                    {
                        "name": "Xã Đak Mang",
                        "code": 21700,
                        "codename": "xa_dak_mang",
                        "division_type": "xã",
                        "short_codename": "dak_mang"
                    },
                    {
                        "name": "Xã Ân Tín",
                        "code": 21703,
                        "codename": "xa_an_tin",
                        "division_type": "xã",
                        "short_codename": "an_tin"
                    },
                    {
                        "name": "Xã Ân Thạnh",
                        "code": 21706,
                        "codename": "xa_an_thanh",
                        "division_type": "xã",
                        "short_codename": "an_thanh"
                    },
                    {
                        "name": "Xã Ân Phong",
                        "code": 21709,
                        "codename": "xa_an_phong",
                        "division_type": "xã",
                        "short_codename": "an_phong"
                    },
                    {
                        "name": "Xã Ân Đức",
                        "code": 21712,
                        "codename": "xa_an_duc",
                        "division_type": "xã",
                        "short_codename": "an_duc"
                    },
                    {
                        "name": "Xã Ân Hữu",
                        "code": 21715,
                        "codename": "xa_an_huu",
                        "division_type": "xã",
                        "short_codename": "an_huu"
                    },
                    {
                        "name": "Xã Bok Tới",
                        "code": 21718,
                        "codename": "xa_bok_toi",
                        "division_type": "xã",
                        "short_codename": "bok_toi"
                    },
                    {
                        "name": "Xã Ân Tường Tây",
                        "code": 21721,
                        "codename": "xa_an_tuong_tay",
                        "division_type": "xã",
                        "short_codename": "an_tuong_tay"
                    },
                    {
                        "name": "Xã Ân Tường Đông",
                        "code": 21724,
                        "codename": "xa_an_tuong_dong",
                        "division_type": "xã",
                        "short_codename": "an_tuong_dong"
                    },
                    {
                        "name": "Xã Ân Nghĩa",
                        "code": 21727,
                        "codename": "xa_an_nghia",
                        "division_type": "xã",
                        "short_codename": "an_nghia"
                    }
                ]
            },
            {
                "name": "Huyện Phù Mỹ",
                "code": 545,
                "codename": "huyen_phu_my",
                "division_type": "huyện",
                "short_codename": "phu_my",
                "wards": [
                    {
                        "name": "Thị trấn Phù Mỹ",
                        "code": 21730,
                        "codename": "thi_tran_phu_my",
                        "division_type": "thị trấn",
                        "short_codename": "phu_my"
                    },
                    {
                        "name": "Thị trấn Bình Dương",
                        "code": 21733,
                        "codename": "thi_tran_binh_duong",
                        "division_type": "thị trấn",
                        "short_codename": "binh_duong"
                    },
                    {
                        "name": "Xã Mỹ Đức",
                        "code": 21736,
                        "codename": "xa_my_duc",
                        "division_type": "xã",
                        "short_codename": "my_duc"
                    },
                    {
                        "name": "Xã Mỹ Châu",
                        "code": 21739,
                        "codename": "xa_my_chau",
                        "division_type": "xã",
                        "short_codename": "my_chau"
                    },
                    {
                        "name": "Xã Mỹ Thắng",
                        "code": 21742,
                        "codename": "xa_my_thang",
                        "division_type": "xã",
                        "short_codename": "my_thang"
                    },
                    {
                        "name": "Xã Mỹ Lộc",
                        "code": 21745,
                        "codename": "xa_my_loc",
                        "division_type": "xã",
                        "short_codename": "my_loc"
                    },
                    {
                        "name": "Xã Mỹ Lợi",
                        "code": 21748,
                        "codename": "xa_my_loi",
                        "division_type": "xã",
                        "short_codename": "my_loi"
                    },
                    {
                        "name": "Xã Mỹ An",
                        "code": 21751,
                        "codename": "xa_my_an",
                        "division_type": "xã",
                        "short_codename": "my_an"
                    },
                    {
                        "name": "Xã Mỹ Phong",
                        "code": 21754,
                        "codename": "xa_my_phong",
                        "division_type": "xã",
                        "short_codename": "my_phong"
                    },
                    {
                        "name": "Xã Mỹ Trinh",
                        "code": 21757,
                        "codename": "xa_my_trinh",
                        "division_type": "xã",
                        "short_codename": "my_trinh"
                    },
                    {
                        "name": "Xã Mỹ Thọ",
                        "code": 21760,
                        "codename": "xa_my_tho",
                        "division_type": "xã",
                        "short_codename": "my_tho"
                    },
                    {
                        "name": "Xã Mỹ Hòa",
                        "code": 21763,
                        "codename": "xa_my_hoa",
                        "division_type": "xã",
                        "short_codename": "my_hoa"
                    },
                    {
                        "name": "Xã Mỹ Thành",
                        "code": 21766,
                        "codename": "xa_my_thanh",
                        "division_type": "xã",
                        "short_codename": "my_thanh"
                    },
                    {
                        "name": "Xã Mỹ Chánh",
                        "code": 21769,
                        "codename": "xa_my_chanh",
                        "division_type": "xã",
                        "short_codename": "my_chanh"
                    },
                    {
                        "name": "Xã Mỹ Quang",
                        "code": 21772,
                        "codename": "xa_my_quang",
                        "division_type": "xã",
                        "short_codename": "my_quang"
                    },
                    {
                        "name": "Xã Mỹ Hiệp",
                        "code": 21775,
                        "codename": "xa_my_hiep",
                        "division_type": "xã",
                        "short_codename": "my_hiep"
                    },
                    {
                        "name": "Xã Mỹ Tài",
                        "code": 21778,
                        "codename": "xa_my_tai",
                        "division_type": "xã",
                        "short_codename": "my_tai"
                    },
                    {
                        "name": "Xã Mỹ Cát",
                        "code": 21781,
                        "codename": "xa_my_cat",
                        "division_type": "xã",
                        "short_codename": "my_cat"
                    },
                    {
                        "name": "Xã Mỹ Chánh Tây",
                        "code": 21784,
                        "codename": "xa_my_chanh_tay",
                        "division_type": "xã",
                        "short_codename": "my_chanh_tay"
                    }
                ]
            },
            {
                "name": "Huyện Vĩnh Thạnh",
                "code": 546,
                "codename": "huyen_vinh_thanh",
                "division_type": "huyện",
                "short_codename": "vinh_thanh",
                "wards": [
                    {
                        "name": "Thị trấn Vĩnh Thạnh",
                        "code": 21786,
                        "codename": "thi_tran_vinh_thanh",
                        "division_type": "thị trấn",
                        "short_codename": "vinh_thanh"
                    },
                    {
                        "name": "Xã Vĩnh Sơn",
                        "code": 21787,
                        "codename": "xa_vinh_son",
                        "division_type": "xã",
                        "short_codename": "vinh_son"
                    },
                    {
                        "name": "Xã Vĩnh Kim",
                        "code": 21790,
                        "codename": "xa_vinh_kim",
                        "division_type": "xã",
                        "short_codename": "vinh_kim"
                    },
                    {
                        "name": "Xã Vĩnh Hiệp",
                        "code": 21796,
                        "codename": "xa_vinh_hiep",
                        "division_type": "xã",
                        "short_codename": "vinh_hiep"
                    },
                    {
                        "name": "Xã Vĩnh Hảo",
                        "code": 21799,
                        "codename": "xa_vinh_hao",
                        "division_type": "xã",
                        "short_codename": "vinh_hao"
                    },
                    {
                        "name": "Xã Vĩnh Hòa",
                        "code": 21801,
                        "codename": "xa_vinh_hoa",
                        "division_type": "xã",
                        "short_codename": "vinh_hoa"
                    },
                    {
                        "name": "Xã Vĩnh Thịnh",
                        "code": 21802,
                        "codename": "xa_vinh_thinh",
                        "division_type": "xã",
                        "short_codename": "vinh_thinh"
                    },
                    {
                        "name": "Xã Vĩnh Thuận",
                        "code": 21804,
                        "codename": "xa_vinh_thuan",
                        "division_type": "xã",
                        "short_codename": "vinh_thuan"
                    },
                    {
                        "name": "Xã Vĩnh Quang",
                        "code": 21805,
                        "codename": "xa_vinh_quang",
                        "division_type": "xã",
                        "short_codename": "vinh_quang"
                    }
                ]
            },
            {
                "name": "Huyện Tây Sơn",
                "code": 547,
                "codename": "huyen_tay_son",
                "division_type": "huyện",
                "short_codename": "tay_son",
                "wards": [
                    {
                        "name": "Thị trấn Phú Phong",
                        "code": 21808,
                        "codename": "thi_tran_phu_phong",
                        "division_type": "thị trấn",
                        "short_codename": "phu_phong"
                    },
                    {
                        "name": "Xã Bình Tân",
                        "code": 21811,
                        "codename": "xa_binh_tan",
                        "division_type": "xã",
                        "short_codename": "binh_tan"
                    },
                    {
                        "name": "Xã Tây Thuận",
                        "code": 21814,
                        "codename": "xa_tay_thuan",
                        "division_type": "xã",
                        "short_codename": "tay_thuan"
                    },
                    {
                        "name": "Xã Bình Thuận",
                        "code": 21817,
                        "codename": "xa_binh_thuan",
                        "division_type": "xã",
                        "short_codename": "binh_thuan"
                    },
                    {
                        "name": "Xã Tây Giang",
                        "code": 21820,
                        "codename": "xa_tay_giang",
                        "division_type": "xã",
                        "short_codename": "tay_giang"
                    },
                    {
                        "name": "Xã Bình Thành",
                        "code": 21823,
                        "codename": "xa_binh_thanh",
                        "division_type": "xã",
                        "short_codename": "binh_thanh"
                    },
                    {
                        "name": "Xã Tây An",
                        "code": 21826,
                        "codename": "xa_tay_an",
                        "division_type": "xã",
                        "short_codename": "tay_an"
                    },
                    {
                        "name": "Xã Bình Hòa",
                        "code": 21829,
                        "codename": "xa_binh_hoa",
                        "division_type": "xã",
                        "short_codename": "binh_hoa"
                    },
                    {
                        "name": "Xã Tây Bình",
                        "code": 21832,
                        "codename": "xa_tay_binh",
                        "division_type": "xã",
                        "short_codename": "tay_binh"
                    },
                    {
                        "name": "Xã Bình Tường",
                        "code": 21835,
                        "codename": "xa_binh_tuong",
                        "division_type": "xã",
                        "short_codename": "binh_tuong"
                    },
                    {
                        "name": "Xã Tây Vinh",
                        "code": 21838,
                        "codename": "xa_tay_vinh",
                        "division_type": "xã",
                        "short_codename": "tay_vinh"
                    },
                    {
                        "name": "Xã Vĩnh An",
                        "code": 21841,
                        "codename": "xa_vinh_an",
                        "division_type": "xã",
                        "short_codename": "vinh_an"
                    },
                    {
                        "name": "Xã Tây Xuân",
                        "code": 21844,
                        "codename": "xa_tay_xuan",
                        "division_type": "xã",
                        "short_codename": "tay_xuan"
                    },
                    {
                        "name": "Xã Bình Nghi",
                        "code": 21847,
                        "codename": "xa_binh_nghi",
                        "division_type": "xã",
                        "short_codename": "binh_nghi"
                    },
                    {
                        "name": "Xã Tây Phú",
                        "code": 21850,
                        "codename": "xa_tay_phu",
                        "division_type": "xã",
                        "short_codename": "tay_phu"
                    }
                ]
            },
            {
                "name": "Huyện Phù Cát",
                "code": 548,
                "codename": "huyen_phu_cat",
                "division_type": "huyện",
                "short_codename": "phu_cat",
                "wards": [
                    {
                        "name": "Thị trấn Ngô Mây",
                        "code": 21853,
                        "codename": "thi_tran_ngo_may",
                        "division_type": "thị trấn",
                        "short_codename": "ngo_may"
                    },
                    {
                        "name": "Xã Cát Sơn",
                        "code": 21856,
                        "codename": "xa_cat_son",
                        "division_type": "xã",
                        "short_codename": "cat_son"
                    },
                    {
                        "name": "Xã Cát Minh",
                        "code": 21859,
                        "codename": "xa_cat_minh",
                        "division_type": "xã",
                        "short_codename": "cat_minh"
                    },
                    {
                        "name": "Xã Cát Khánh",
                        "code": 21862,
                        "codename": "xa_cat_khanh",
                        "division_type": "xã",
                        "short_codename": "cat_khanh"
                    },
                    {
                        "name": "Xã Cát Tài",
                        "code": 21865,
                        "codename": "xa_cat_tai",
                        "division_type": "xã",
                        "short_codename": "cat_tai"
                    },
                    {
                        "name": "Xã Cát Lâm",
                        "code": 21868,
                        "codename": "xa_cat_lam",
                        "division_type": "xã",
                        "short_codename": "cat_lam"
                    },
                    {
                        "name": "Xã Cát Hanh",
                        "code": 21871,
                        "codename": "xa_cat_hanh",
                        "division_type": "xã",
                        "short_codename": "cat_hanh"
                    },
                    {
                        "name": "Xã Cát Thành",
                        "code": 21874,
                        "codename": "xa_cat_thanh",
                        "division_type": "xã",
                        "short_codename": "cat_thanh"
                    },
                    {
                        "name": "Xã Cát Trinh",
                        "code": 21877,
                        "codename": "xa_cat_trinh",
                        "division_type": "xã",
                        "short_codename": "cat_trinh"
                    },
                    {
                        "name": "Xã Cát Hải",
                        "code": 21880,
                        "codename": "xa_cat_hai",
                        "division_type": "xã",
                        "short_codename": "cat_hai"
                    },
                    {
                        "name": "Xã Cát Hiệp",
                        "code": 21883,
                        "codename": "xa_cat_hiep",
                        "division_type": "xã",
                        "short_codename": "cat_hiep"
                    },
                    {
                        "name": "Xã Cát Nhơn",
                        "code": 21886,
                        "codename": "xa_cat_nhon",
                        "division_type": "xã",
                        "short_codename": "cat_nhon"
                    },
                    {
                        "name": "Xã Cát Hưng",
                        "code": 21889,
                        "codename": "xa_cat_hung",
                        "division_type": "xã",
                        "short_codename": "cat_hung"
                    },
                    {
                        "name": "Xã Cát Tường",
                        "code": 21892,
                        "codename": "xa_cat_tuong",
                        "division_type": "xã",
                        "short_codename": "cat_tuong"
                    },
                    {
                        "name": "Xã Cát Tân",
                        "code": 21895,
                        "codename": "xa_cat_tan",
                        "division_type": "xã",
                        "short_codename": "cat_tan"
                    },
                    {
                        "name": "Thị trấn Cát Tiến",
                        "code": 21898,
                        "codename": "thi_tran_cat_tien",
                        "division_type": "thị trấn",
                        "short_codename": "cat_tien"
                    },
                    {
                        "name": "Xã Cát Thắng",
                        "code": 21901,
                        "codename": "xa_cat_thang",
                        "division_type": "xã",
                        "short_codename": "cat_thang"
                    },
                    {
                        "name": "Xã Cát Chánh",
                        "code": 21904,
                        "codename": "xa_cat_chanh",
                        "division_type": "xã",
                        "short_codename": "cat_chanh"
                    }
                ]
            },
            {
                "name": "Thị xã An Nhơn",
                "code": 549,
                "codename": "thi_xa_an_nhon",
                "division_type": "thị xã",
                "short_codename": "an_nhon",
                "wards": [
                    {
                        "name": "Phường Bình Định",
                        "code": 21907,
                        "codename": "phuong_binh_dinh",
                        "division_type": "phường",
                        "short_codename": "binh_dinh"
                    },
                    {
                        "name": "Phường Đập Đá",
                        "code": 21910,
                        "codename": "phuong_dap_da",
                        "division_type": "phường",
                        "short_codename": "dap_da"
                    },
                    {
                        "name": "Xã Nhơn Mỹ",
                        "code": 21913,
                        "codename": "xa_nhon_my",
                        "division_type": "xã",
                        "short_codename": "nhon_my"
                    },
                    {
                        "name": "Phường Nhơn Thành",
                        "code": 21916,
                        "codename": "phuong_nhon_thanh",
                        "division_type": "phường",
                        "short_codename": "nhon_thanh"
                    },
                    {
                        "name": "Xã Nhơn Hạnh",
                        "code": 21919,
                        "codename": "xa_nhon_hanh",
                        "division_type": "xã",
                        "short_codename": "nhon_hanh"
                    },
                    {
                        "name": "Xã Nhơn Hậu",
                        "code": 21922,
                        "codename": "xa_nhon_hau",
                        "division_type": "xã",
                        "short_codename": "nhon_hau"
                    },
                    {
                        "name": "Xã Nhơn Phong",
                        "code": 21925,
                        "codename": "xa_nhon_phong",
                        "division_type": "xã",
                        "short_codename": "nhon_phong"
                    },
                    {
                        "name": "Xã Nhơn An",
                        "code": 21928,
                        "codename": "xa_nhon_an",
                        "division_type": "xã",
                        "short_codename": "nhon_an"
                    },
                    {
                        "name": "Xã Nhơn Phúc",
                        "code": 21931,
                        "codename": "xa_nhon_phuc",
                        "division_type": "xã",
                        "short_codename": "nhon_phuc"
                    },
                    {
                        "name": "Phường Nhơn Hưng",
                        "code": 21934,
                        "codename": "phuong_nhon_hung",
                        "division_type": "phường",
                        "short_codename": "nhon_hung"
                    },
                    {
                        "name": "Xã Nhơn Khánh",
                        "code": 21937,
                        "codename": "xa_nhon_khanh",
                        "division_type": "xã",
                        "short_codename": "nhon_khanh"
                    },
                    {
                        "name": "Xã Nhơn Lộc",
                        "code": 21940,
                        "codename": "xa_nhon_loc",
                        "division_type": "xã",
                        "short_codename": "nhon_loc"
                    },
                    {
                        "name": "Phường Nhơn Hoà",
                        "code": 21943,
                        "codename": "phuong_nhon_hoa",
                        "division_type": "phường",
                        "short_codename": "nhon_hoa"
                    },
                    {
                        "name": "Xã Nhơn Tân",
                        "code": 21946,
                        "codename": "xa_nhon_tan",
                        "division_type": "xã",
                        "short_codename": "nhon_tan"
                    },
                    {
                        "name": "Xã Nhơn Thọ",
                        "code": 21949,
                        "codename": "xa_nhon_tho",
                        "division_type": "xã",
                        "short_codename": "nhon_tho"
                    }
                ]
            },
            {
                "name": "Huyện Tuy Phước",
                "code": 550,
                "codename": "huyen_tuy_phuoc",
                "division_type": "huyện",
                "short_codename": "tuy_phuoc",
                "wards": [
                    {
                        "name": "Thị trấn Tuy Phước",
                        "code": 21952,
                        "codename": "thi_tran_tuy_phuoc",
                        "division_type": "thị trấn",
                        "short_codename": "tuy_phuoc"
                    },
                    {
                        "name": "Thị trấn Diêu Trì",
                        "code": 21955,
                        "codename": "thi_tran_dieu_tri",
                        "division_type": "thị trấn",
                        "short_codename": "dieu_tri"
                    },
                    {
                        "name": "Xã Phước Thắng",
                        "code": 21958,
                        "codename": "xa_phuoc_thang",
                        "division_type": "xã",
                        "short_codename": "phuoc_thang"
                    },
                    {
                        "name": "Xã Phước Hưng",
                        "code": 21961,
                        "codename": "xa_phuoc_hung",
                        "division_type": "xã",
                        "short_codename": "phuoc_hung"
                    },
                    {
                        "name": "Xã Phước Quang",
                        "code": 21964,
                        "codename": "xa_phuoc_quang",
                        "division_type": "xã",
                        "short_codename": "phuoc_quang"
                    },
                    {
                        "name": "Xã Phước Hòa",
                        "code": 21967,
                        "codename": "xa_phuoc_hoa",
                        "division_type": "xã",
                        "short_codename": "phuoc_hoa"
                    },
                    {
                        "name": "Xã Phước Sơn",
                        "code": 21970,
                        "codename": "xa_phuoc_son",
                        "division_type": "xã",
                        "short_codename": "phuoc_son"
                    },
                    {
                        "name": "Xã Phước Hiệp",
                        "code": 21973,
                        "codename": "xa_phuoc_hiep",
                        "division_type": "xã",
                        "short_codename": "phuoc_hiep"
                    },
                    {
                        "name": "Xã Phước Lộc",
                        "code": 21976,
                        "codename": "xa_phuoc_loc",
                        "division_type": "xã",
                        "short_codename": "phuoc_loc"
                    },
                    {
                        "name": "Xã Phước Nghĩa",
                        "code": 21979,
                        "codename": "xa_phuoc_nghia",
                        "division_type": "xã",
                        "short_codename": "phuoc_nghia"
                    },
                    {
                        "name": "Xã Phước Thuận",
                        "code": 21982,
                        "codename": "xa_phuoc_thuan",
                        "division_type": "xã",
                        "short_codename": "phuoc_thuan"
                    },
                    {
                        "name": "Xã Phước An",
                        "code": 21985,
                        "codename": "xa_phuoc_an",
                        "division_type": "xã",
                        "short_codename": "phuoc_an"
                    },
                    {
                        "name": "Xã Phước Thành",
                        "code": 21988,
                        "codename": "xa_phuoc_thanh",
                        "division_type": "xã",
                        "short_codename": "phuoc_thanh"
                    }
                ]
            },
            {
                "name": "Huyện Vân Canh",
                "code": 551,
                "codename": "huyen_van_canh",
                "division_type": "huyện",
                "short_codename": "van_canh",
                "wards": [
                    {
                        "name": "Thị trấn Vân Canh",
                        "code": 21994,
                        "codename": "thi_tran_van_canh",
                        "division_type": "thị trấn",
                        "short_codename": "van_canh"
                    },
                    {
                        "name": "Xã Canh Liên",
                        "code": 21997,
                        "codename": "xa_canh_lien",
                        "division_type": "xã",
                        "short_codename": "canh_lien"
                    },
                    {
                        "name": "Xã Canh Hiệp",
                        "code": 22000,
                        "codename": "xa_canh_hiep",
                        "division_type": "xã",
                        "short_codename": "canh_hiep"
                    },
                    {
                        "name": "Xã Canh Vinh",
                        "code": 22003,
                        "codename": "xa_canh_vinh",
                        "division_type": "xã",
                        "short_codename": "canh_vinh"
                    },
                    {
                        "name": "Xã Canh Hiển",
                        "code": 22006,
                        "codename": "xa_canh_hien",
                        "division_type": "xã",
                        "short_codename": "canh_hien"
                    },
                    {
                        "name": "Xã Canh Thuận",
                        "code": 22009,
                        "codename": "xa_canh_thuan",
                        "division_type": "xã",
                        "short_codename": "canh_thuan"
                    },
                    {
                        "name": "Xã Canh Hòa",
                        "code": 22012,
                        "codename": "xa_canh_hoa",
                        "division_type": "xã",
                        "short_codename": "canh_hoa"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Phú Yên",
        "code": 54,
        "codename": "tinh_phu_yen",
        "division_type": "tỉnh",
        "phone_code": 257,
        "districts": [
            {
                "name": "Thành phố Tuy Hoà",
                "code": 555,
                "codename": "thanh_pho_tuy_hoa",
                "division_type": "thành phố",
                "short_codename": "tuy_hoa",
                "wards": [
                    {
                        "name": "Phường 1",
                        "code": 22015,
                        "codename": "phuong_1",
                        "division_type": "phường",
                        "short_codename": "phuong_1"
                    },
                    {
                        "name": "Phường 8",
                        "code": 22018,
                        "codename": "phuong_8",
                        "division_type": "phường",
                        "short_codename": "phuong_8"
                    },
                    {
                        "name": "Phường 2",
                        "code": 22021,
                        "codename": "phuong_2",
                        "division_type": "phường",
                        "short_codename": "phuong_2"
                    },
                    {
                        "name": "Phường 9",
                        "code": 22024,
                        "codename": "phuong_9",
                        "division_type": "phường",
                        "short_codename": "phuong_9"
                    },
                    {
                        "name": "Phường 3",
                        "code": 22027,
                        "codename": "phuong_3",
                        "division_type": "phường",
                        "short_codename": "phuong_3"
                    },
                    {
                        "name": "Phường 4",
                        "code": 22030,
                        "codename": "phuong_4",
                        "division_type": "phường",
                        "short_codename": "phuong_4"
                    },
                    {
                        "name": "Phường 5",
                        "code": 22033,
                        "codename": "phuong_5",
                        "division_type": "phường",
                        "short_codename": "phuong_5"
                    },
                    {
                        "name": "Phường 7",
                        "code": 22036,
                        "codename": "phuong_7",
                        "division_type": "phường",
                        "short_codename": "phuong_7"
                    },
                    {
                        "name": "Phường 6",
                        "code": 22039,
                        "codename": "phuong_6",
                        "division_type": "phường",
                        "short_codename": "phuong_6"
                    },
                    {
                        "name": "Phường Phú Thạnh",
                        "code": 22040,
                        "codename": "phuong_phu_thanh",
                        "division_type": "phường",
                        "short_codename": "phu_thanh"
                    },
                    {
                        "name": "Phường Phú Đông",
                        "code": 22041,
                        "codename": "phuong_phu_dong",
                        "division_type": "phường",
                        "short_codename": "phu_dong"
                    },
                    {
                        "name": "Xã Hòa Kiến",
                        "code": 22042,
                        "codename": "xa_hoa_kien",
                        "division_type": "xã",
                        "short_codename": "hoa_kien"
                    },
                    {
                        "name": "Xã Bình Kiến",
                        "code": 22045,
                        "codename": "xa_binh_kien",
                        "division_type": "xã",
                        "short_codename": "binh_kien"
                    },
                    {
                        "name": "Xã Bình Ngọc",
                        "code": 22048,
                        "codename": "xa_binh_ngoc",
                        "division_type": "xã",
                        "short_codename": "binh_ngoc"
                    },
                    {
                        "name": "Xã An Phú",
                        "code": 22162,
                        "codename": "xa_an_phu",
                        "division_type": "xã",
                        "short_codename": "an_phu"
                    },
                    {
                        "name": "Phường Phú Lâm",
                        "code": 22240,
                        "codename": "phuong_phu_lam",
                        "division_type": "phường",
                        "short_codename": "phu_lam"
                    }
                ]
            },
            {
                "name": "Thị xã Sông Cầu",
                "code": 557,
                "codename": "thi_xa_song_cau",
                "division_type": "thị xã",
                "short_codename": "song_cau",
                "wards": [
                    {
                        "name": "Phường Xuân Phú",
                        "code": 22051,
                        "codename": "phuong_xuan_phu",
                        "division_type": "phường",
                        "short_codename": "xuan_phu"
                    },
                    {
                        "name": "Xã Xuân Lâm",
                        "code": 22052,
                        "codename": "xa_xuan_lam",
                        "division_type": "xã",
                        "short_codename": "xuan_lam"
                    },
                    {
                        "name": "Phường Xuân Thành",
                        "code": 22053,
                        "codename": "phuong_xuan_thanh",
                        "division_type": "phường",
                        "short_codename": "xuan_thanh"
                    },
                    {
                        "name": "Xã Xuân Hải",
                        "code": 22054,
                        "codename": "xa_xuan_hai",
                        "division_type": "xã",
                        "short_codename": "xuan_hai"
                    },
                    {
                        "name": "Xã Xuân Lộc",
                        "code": 22057,
                        "codename": "xa_xuan_loc",
                        "division_type": "xã",
                        "short_codename": "xuan_loc"
                    },
                    {
                        "name": "Xã Xuân Bình",
                        "code": 22060,
                        "codename": "xa_xuan_binh",
                        "division_type": "xã",
                        "short_codename": "xuan_binh"
                    },
                    {
                        "name": "Xã Xuân Cảnh",
                        "code": 22066,
                        "codename": "xa_xuan_canh",
                        "division_type": "xã",
                        "short_codename": "xuan_canh"
                    },
                    {
                        "name": "Xã Xuân Thịnh",
                        "code": 22069,
                        "codename": "xa_xuan_thinh",
                        "division_type": "xã",
                        "short_codename": "xuan_thinh"
                    },
                    {
                        "name": "Xã Xuân Phương",
                        "code": 22072,
                        "codename": "xa_xuan_phuong",
                        "division_type": "xã",
                        "short_codename": "xuan_phuong"
                    },
                    {
                        "name": "Phường Xuân Yên",
                        "code": 22073,
                        "codename": "phuong_xuan_yen",
                        "division_type": "phường",
                        "short_codename": "xuan_yen"
                    },
                    {
                        "name": "Xã Xuân Thọ 1",
                        "code": 22075,
                        "codename": "xa_xuan_tho_1",
                        "division_type": "xã",
                        "short_codename": "xuan_tho_1"
                    },
                    {
                        "name": "Phường Xuân Đài",
                        "code": 22076,
                        "codename": "phuong_xuan_dai",
                        "division_type": "phường",
                        "short_codename": "xuan_dai"
                    },
                    {
                        "name": "Xã Xuân Thọ 2",
                        "code": 22078,
                        "codename": "xa_xuan_tho_2",
                        "division_type": "xã",
                        "short_codename": "xuan_tho_2"
                    }
                ]
            },
            {
                "name": "Huyện Đồng Xuân",
                "code": 558,
                "codename": "huyen_dong_xuan",
                "division_type": "huyện",
                "short_codename": "dong_xuan",
                "wards": [
                    {
                        "name": "Thị trấn La Hai",
                        "code": 22081,
                        "codename": "thi_tran_la_hai",
                        "division_type": "thị trấn",
                        "short_codename": "la_hai"
                    },
                    {
                        "name": "Xã Đa Lộc",
                        "code": 22084,
                        "codename": "xa_da_loc",
                        "division_type": "xã",
                        "short_codename": "da_loc"
                    },
                    {
                        "name": "Xã Phú Mỡ",
                        "code": 22087,
                        "codename": "xa_phu_mo",
                        "division_type": "xã",
                        "short_codename": "phu_mo"
                    },
                    {
                        "name": "Xã Xuân Lãnh",
                        "code": 22090,
                        "codename": "xa_xuan_lanh",
                        "division_type": "xã",
                        "short_codename": "xuan_lanh"
                    },
                    {
                        "name": "Xã Xuân Long",
                        "code": 22093,
                        "codename": "xa_xuan_long",
                        "division_type": "xã",
                        "short_codename": "xuan_long"
                    },
                    {
                        "name": "Xã Xuân Quang 1",
                        "code": 22096,
                        "codename": "xa_xuan_quang_1",
                        "division_type": "xã",
                        "short_codename": "xuan_quang_1"
                    },
                    {
                        "name": "Xã Xuân Sơn Bắc",
                        "code": 22099,
                        "codename": "xa_xuan_son_bac",
                        "division_type": "xã",
                        "short_codename": "xuan_son_bac"
                    },
                    {
                        "name": "Xã Xuân Quang 2",
                        "code": 22102,
                        "codename": "xa_xuan_quang_2",
                        "division_type": "xã",
                        "short_codename": "xuan_quang_2"
                    },
                    {
                        "name": "Xã Xuân Sơn Nam",
                        "code": 22105,
                        "codename": "xa_xuan_son_nam",
                        "division_type": "xã",
                        "short_codename": "xuan_son_nam"
                    },
                    {
                        "name": "Xã Xuân Quang 3",
                        "code": 22108,
                        "codename": "xa_xuan_quang_3",
                        "division_type": "xã",
                        "short_codename": "xuan_quang_3"
                    },
                    {
                        "name": "Xã Xuân Phước",
                        "code": 22111,
                        "codename": "xa_xuan_phuoc",
                        "division_type": "xã",
                        "short_codename": "xuan_phuoc"
                    }
                ]
            },
            {
                "name": "Huyện Tuy An",
                "code": 559,
                "codename": "huyen_tuy_an",
                "division_type": "huyện",
                "short_codename": "tuy_an",
                "wards": [
                    {
                        "name": "Thị trấn Chí Thạnh",
                        "code": 22114,
                        "codename": "thi_tran_chi_thanh",
                        "division_type": "thị trấn",
                        "short_codename": "chi_thanh"
                    },
                    {
                        "name": "Xã An Dân",
                        "code": 22117,
                        "codename": "xa_an_dan",
                        "division_type": "xã",
                        "short_codename": "an_dan"
                    },
                    {
                        "name": "Xã An Ninh Tây",
                        "code": 22120,
                        "codename": "xa_an_ninh_tay",
                        "division_type": "xã",
                        "short_codename": "an_ninh_tay"
                    },
                    {
                        "name": "Xã An Ninh Đông",
                        "code": 22123,
                        "codename": "xa_an_ninh_dong",
                        "division_type": "xã",
                        "short_codename": "an_ninh_dong"
                    },
                    {
                        "name": "Xã An Thạch",
                        "code": 22126,
                        "codename": "xa_an_thach",
                        "division_type": "xã",
                        "short_codename": "an_thach"
                    },
                    {
                        "name": "Xã An Định",
                        "code": 22129,
                        "codename": "xa_an_dinh",
                        "division_type": "xã",
                        "short_codename": "an_dinh"
                    },
                    {
                        "name": "Xã An Nghiệp",
                        "code": 22132,
                        "codename": "xa_an_nghiep",
                        "division_type": "xã",
                        "short_codename": "an_nghiep"
                    },
                    {
                        "name": "Xã An Cư",
                        "code": 22138,
                        "codename": "xa_an_cu",
                        "division_type": "xã",
                        "short_codename": "an_cu"
                    },
                    {
                        "name": "Xã An Xuân",
                        "code": 22141,
                        "codename": "xa_an_xuan",
                        "division_type": "xã",
                        "short_codename": "an_xuan"
                    },
                    {
                        "name": "Xã An Lĩnh",
                        "code": 22144,
                        "codename": "xa_an_linh",
                        "division_type": "xã",
                        "short_codename": "an_linh"
                    },
                    {
                        "name": "Xã An Hòa Hải",
                        "code": 22147,
                        "codename": "xa_an_hoa_hai",
                        "division_type": "xã",
                        "short_codename": "an_hoa_hai"
                    },
                    {
                        "name": "Xã An Hiệp",
                        "code": 22150,
                        "codename": "xa_an_hiep",
                        "division_type": "xã",
                        "short_codename": "an_hiep"
                    },
                    {
                        "name": "Xã An Mỹ",
                        "code": 22153,
                        "codename": "xa_an_my",
                        "division_type": "xã",
                        "short_codename": "an_my"
                    },
                    {
                        "name": "Xã An Chấn",
                        "code": 22156,
                        "codename": "xa_an_chan",
                        "division_type": "xã",
                        "short_codename": "an_chan"
                    },
                    {
                        "name": "Xã An Thọ",
                        "code": 22159,
                        "codename": "xa_an_tho",
                        "division_type": "xã",
                        "short_codename": "an_tho"
                    }
                ]
            },
            {
                "name": "Huyện Sơn Hòa",
                "code": 560,
                "codename": "huyen_son_hoa",
                "division_type": "huyện",
                "short_codename": "son_hoa",
                "wards": [
                    {
                        "name": "Thị trấn Củng Sơn",
                        "code": 22165,
                        "codename": "thi_tran_cung_son",
                        "division_type": "thị trấn",
                        "short_codename": "cung_son"
                    },
                    {
                        "name": "Xã Phước Tân",
                        "code": 22168,
                        "codename": "xa_phuoc_tan",
                        "division_type": "xã",
                        "short_codename": "phuoc_tan"
                    },
                    {
                        "name": "Xã Sơn Hội",
                        "code": 22171,
                        "codename": "xa_son_hoi",
                        "division_type": "xã",
                        "short_codename": "son_hoi"
                    },
                    {
                        "name": "Xã Sơn Định",
                        "code": 22174,
                        "codename": "xa_son_dinh",
                        "division_type": "xã",
                        "short_codename": "son_dinh"
                    },
                    {
                        "name": "Xã Sơn Long",
                        "code": 22177,
                        "codename": "xa_son_long",
                        "division_type": "xã",
                        "short_codename": "son_long"
                    },
                    {
                        "name": "Xã Cà Lúi",
                        "code": 22180,
                        "codename": "xa_ca_lui",
                        "division_type": "xã",
                        "short_codename": "ca_lui"
                    },
                    {
                        "name": "Xã Sơn Phước",
                        "code": 22183,
                        "codename": "xa_son_phuoc",
                        "division_type": "xã",
                        "short_codename": "son_phuoc"
                    },
                    {
                        "name": "Xã Sơn Xuân",
                        "code": 22186,
                        "codename": "xa_son_xuan",
                        "division_type": "xã",
                        "short_codename": "son_xuan"
                    },
                    {
                        "name": "Xã Sơn Nguyên",
                        "code": 22189,
                        "codename": "xa_son_nguyen",
                        "division_type": "xã",
                        "short_codename": "son_nguyen"
                    },
                    {
                        "name": "Xã Eachà Rang",
                        "code": 22192,
                        "codename": "xa_eacha_rang",
                        "division_type": "xã",
                        "short_codename": "eacha_rang"
                    },
                    {
                        "name": "Xã Krông Pa",
                        "code": 22195,
                        "codename": "xa_krong_pa",
                        "division_type": "xã",
                        "short_codename": "krong_pa"
                    },
                    {
                        "name": "Xã Suối Bạc",
                        "code": 22198,
                        "codename": "xa_suoi_bac",
                        "division_type": "xã",
                        "short_codename": "suoi_bac"
                    },
                    {
                        "name": "Xã Sơn Hà",
                        "code": 22201,
                        "codename": "xa_son_ha",
                        "division_type": "xã",
                        "short_codename": "son_ha"
                    },
                    {
                        "name": "Xã Suối Trai",
                        "code": 22204,
                        "codename": "xa_suoi_trai",
                        "division_type": "xã",
                        "short_codename": "suoi_trai"
                    }
                ]
            },
            {
                "name": "Huyện Sông Hinh",
                "code": 561,
                "codename": "huyen_song_hinh",
                "division_type": "huyện",
                "short_codename": "song_hinh",
                "wards": [
                    {
                        "name": "Thị trấn Hai Riêng",
                        "code": 22207,
                        "codename": "thi_tran_hai_rieng",
                        "division_type": "thị trấn",
                        "short_codename": "hai_rieng"
                    },
                    {
                        "name": "Xã Ea Lâm",
                        "code": 22210,
                        "codename": "xa_ea_lam",
                        "division_type": "xã",
                        "short_codename": "ea_lam"
                    },
                    {
                        "name": "Xã Đức Bình Tây",
                        "code": 22213,
                        "codename": "xa_duc_binh_tay",
                        "division_type": "xã",
                        "short_codename": "duc_binh_tay"
                    },
                    {
                        "name": "Xã Ea Bá",
                        "code": 22216,
                        "codename": "xa_ea_ba",
                        "division_type": "xã",
                        "short_codename": "ea_ba"
                    },
                    {
                        "name": "Xã Sơn Giang",
                        "code": 22219,
                        "codename": "xa_son_giang",
                        "division_type": "xã",
                        "short_codename": "son_giang"
                    },
                    {
                        "name": "Xã Đức Bình Đông",
                        "code": 22222,
                        "codename": "xa_duc_binh_dong",
                        "division_type": "xã",
                        "short_codename": "duc_binh_dong"
                    },
                    {
                        "name": "Xã EaBar",
                        "code": 22225,
                        "codename": "xa_eabar",
                        "division_type": "xã",
                        "short_codename": "eabar"
                    },
                    {
                        "name": "Xã EaBia",
                        "code": 22228,
                        "codename": "xa_eabia",
                        "division_type": "xã",
                        "short_codename": "eabia"
                    },
                    {
                        "name": "Xã EaTrol",
                        "code": 22231,
                        "codename": "xa_eatrol",
                        "division_type": "xã",
                        "short_codename": "eatrol"
                    },
                    {
                        "name": "Xã Sông Hinh",
                        "code": 22234,
                        "codename": "xa_song_hinh",
                        "division_type": "xã",
                        "short_codename": "song_hinh"
                    },
                    {
                        "name": "Xã Ealy",
                        "code": 22237,
                        "codename": "xa_ealy",
                        "division_type": "xã",
                        "short_codename": "ealy"
                    }
                ]
            },
            {
                "name": "Huyện Tây Hoà",
                "code": 562,
                "codename": "huyen_tay_hoa",
                "division_type": "huyện",
                "short_codename": "tay_hoa",
                "wards": [
                    {
                        "name": "Xã Sơn Thành Tây",
                        "code": 22249,
                        "codename": "xa_son_thanh_tay",
                        "division_type": "xã",
                        "short_codename": "son_thanh_tay"
                    },
                    {
                        "name": "Xã Sơn Thành Đông",
                        "code": 22250,
                        "codename": "xa_son_thanh_dong",
                        "division_type": "xã",
                        "short_codename": "son_thanh_dong"
                    },
                    {
                        "name": "Xã Hòa Bình 1",
                        "code": 22252,
                        "codename": "xa_hoa_binh_1",
                        "division_type": "xã",
                        "short_codename": "hoa_binh_1"
                    },
                    {
                        "name": "Thị trấn Phú Thứ",
                        "code": 22255,
                        "codename": "thi_tran_phu_thu",
                        "division_type": "thị trấn",
                        "short_codename": "phu_thu"
                    },
                    {
                        "name": "Xã Hòa Phong",
                        "code": 22264,
                        "codename": "xa_hoa_phong",
                        "division_type": "xã",
                        "short_codename": "hoa_phong"
                    },
                    {
                        "name": "Xã Hòa Phú",
                        "code": 22270,
                        "codename": "xa_hoa_phu",
                        "division_type": "xã",
                        "short_codename": "hoa_phu"
                    },
                    {
                        "name": "Xã Hòa Tân Tây",
                        "code": 22273,
                        "codename": "xa_hoa_tan_tay",
                        "division_type": "xã",
                        "short_codename": "hoa_tan_tay"
                    },
                    {
                        "name": "Xã Hòa Đồng",
                        "code": 22276,
                        "codename": "xa_hoa_dong",
                        "division_type": "xã",
                        "short_codename": "hoa_dong"
                    },
                    {
                        "name": "Xã Hòa Mỹ Đông",
                        "code": 22285,
                        "codename": "xa_hoa_my_dong",
                        "division_type": "xã",
                        "short_codename": "hoa_my_dong"
                    },
                    {
                        "name": "Xã Hòa Mỹ Tây",
                        "code": 22288,
                        "codename": "xa_hoa_my_tay",
                        "division_type": "xã",
                        "short_codename": "hoa_my_tay"
                    },
                    {
                        "name": "Xã Hòa Thịnh",
                        "code": 22294,
                        "codename": "xa_hoa_thinh",
                        "division_type": "xã",
                        "short_codename": "hoa_thinh"
                    }
                ]
            },
            {
                "name": "Huyện Phú Hoà",
                "code": 563,
                "codename": "huyen_phu_hoa",
                "division_type": "huyện",
                "short_codename": "phu_hoa",
                "wards": [
                    {
                        "name": "Xã Hòa Quang Bắc",
                        "code": 22303,
                        "codename": "xa_hoa_quang_bac",
                        "division_type": "xã",
                        "short_codename": "hoa_quang_bac"
                    },
                    {
                        "name": "Xã Hòa Quang Nam",
                        "code": 22306,
                        "codename": "xa_hoa_quang_nam",
                        "division_type": "xã",
                        "short_codename": "hoa_quang_nam"
                    },
                    {
                        "name": "Xã Hòa Hội",
                        "code": 22309,
                        "codename": "xa_hoa_hoi",
                        "division_type": "xã",
                        "short_codename": "hoa_hoi"
                    },
                    {
                        "name": "Xã Hòa Trị",
                        "code": 22312,
                        "codename": "xa_hoa_tri",
                        "division_type": "xã",
                        "short_codename": "hoa_tri"
                    },
                    {
                        "name": "Xã Hòa An",
                        "code": 22315,
                        "codename": "xa_hoa_an",
                        "division_type": "xã",
                        "short_codename": "hoa_an"
                    },
                    {
                        "name": "Xã Hòa Định Đông",
                        "code": 22318,
                        "codename": "xa_hoa_dinh_dong",
                        "division_type": "xã",
                        "short_codename": "hoa_dinh_dong"
                    },
                    {
                        "name": "Thị Trấn Phú Hoà",
                        "code": 22319,
                        "codename": "thi_tran_phu_hoa",
                        "division_type": "thị trấn",
                        "short_codename": "phu_hoa"
                    },
                    {
                        "name": "Xã Hòa Định Tây",
                        "code": 22321,
                        "codename": "xa_hoa_dinh_tay",
                        "division_type": "xã",
                        "short_codename": "hoa_dinh_tay"
                    },
                    {
                        "name": "Xã Hòa Thắng",
                        "code": 22324,
                        "codename": "xa_hoa_thang",
                        "division_type": "xã",
                        "short_codename": "hoa_thang"
                    }
                ]
            },
            {
                "name": "Thị xã Đông Hòa",
                "code": 564,
                "codename": "thi_xa_dong_hoa",
                "division_type": "thị xã",
                "short_codename": "dong_hoa",
                "wards": [
                    {
                        "name": "Xã Hòa Thành",
                        "code": 22243,
                        "codename": "xa_hoa_thanh",
                        "division_type": "xã",
                        "short_codename": "hoa_thanh"
                    },
                    {
                        "name": "Phường Hòa Hiệp Bắc",
                        "code": 22246,
                        "codename": "phuong_hoa_hiep_bac",
                        "division_type": "phường",
                        "short_codename": "hoa_hiep_bac"
                    },
                    {
                        "name": "Phường Hoà Vinh",
                        "code": 22258,
                        "codename": "phuong_hoa_vinh",
                        "division_type": "phường",
                        "short_codename": "hoa_vinh"
                    },
                    {
                        "name": "Phường Hoà Hiệp Trung",
                        "code": 22261,
                        "codename": "phuong_hoa_hiep_trung",
                        "division_type": "phường",
                        "short_codename": "hoa_hiep_trung"
                    },
                    {
                        "name": "Xã Hòa Tân Đông",
                        "code": 22267,
                        "codename": "xa_hoa_tan_dong",
                        "division_type": "xã",
                        "short_codename": "hoa_tan_dong"
                    },
                    {
                        "name": "Phường Hòa Xuân Tây",
                        "code": 22279,
                        "codename": "phuong_hoa_xuan_tay",
                        "division_type": "phường",
                        "short_codename": "hoa_xuan_tay"
                    },
                    {
                        "name": "Phường Hòa Hiệp Nam",
                        "code": 22282,
                        "codename": "phuong_hoa_hiep_nam",
                        "division_type": "phường",
                        "short_codename": "hoa_hiep_nam"
                    },
                    {
                        "name": "Xã Hòa Xuân Đông",
                        "code": 22291,
                        "codename": "xa_hoa_xuan_dong",
                        "division_type": "xã",
                        "short_codename": "hoa_xuan_dong"
                    },
                    {
                        "name": "Xã Hòa Tâm",
                        "code": 22297,
                        "codename": "xa_hoa_tam",
                        "division_type": "xã",
                        "short_codename": "hoa_tam"
                    },
                    {
                        "name": "Xã Hòa Xuân Nam",
                        "code": 22300,
                        "codename": "xa_hoa_xuan_nam",
                        "division_type": "xã",
                        "short_codename": "hoa_xuan_nam"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Khánh Hòa",
        "code": 56,
        "codename": "tinh_khanh_hoa",
        "division_type": "tỉnh",
        "phone_code": 258,
        "districts": [
            {
                "name": "Thành phố Nha Trang",
                "code": 568,
                "codename": "thanh_pho_nha_trang",
                "division_type": "thành phố",
                "short_codename": "nha_trang",
                "wards": [
                    {
                        "name": "Phường Vĩnh Hòa",
                        "code": 22327,
                        "codename": "phuong_vinh_hoa",
                        "division_type": "phường",
                        "short_codename": "vinh_hoa"
                    },
                    {
                        "name": "Phường Vĩnh Hải",
                        "code": 22330,
                        "codename": "phuong_vinh_hai",
                        "division_type": "phường",
                        "short_codename": "vinh_hai"
                    },
                    {
                        "name": "Phường Vĩnh Phước",
                        "code": 22333,
                        "codename": "phuong_vinh_phuoc",
                        "division_type": "phường",
                        "short_codename": "vinh_phuoc"
                    },
                    {
                        "name": "Phường Ngọc Hiệp",
                        "code": 22336,
                        "codename": "phuong_ngoc_hiep",
                        "division_type": "phường",
                        "short_codename": "ngoc_hiep"
                    },
                    {
                        "name": "Phường Vĩnh Thọ",
                        "code": 22339,
                        "codename": "phuong_vinh_tho",
                        "division_type": "phường",
                        "short_codename": "vinh_tho"
                    },
                    {
                        "name": "Phường Xương Huân",
                        "code": 22342,
                        "codename": "phuong_xuong_huan",
                        "division_type": "phường",
                        "short_codename": "xuong_huan"
                    },
                    {
                        "name": "Phường Vạn Thắng",
                        "code": 22345,
                        "codename": "phuong_van_thang",
                        "division_type": "phường",
                        "short_codename": "van_thang"
                    },
                    {
                        "name": "Phường Vạn Thạnh",
                        "code": 22348,
                        "codename": "phuong_van_thanh",
                        "division_type": "phường",
                        "short_codename": "van_thanh"
                    },
                    {
                        "name": "Phường Phương Sài",
                        "code": 22351,
                        "codename": "phuong_phuong_sai",
                        "division_type": "phường",
                        "short_codename": "phuong_sai"
                    },
                    {
                        "name": "Phường Phương Sơn",
                        "code": 22354,
                        "codename": "phuong_phuong_son",
                        "division_type": "phường",
                        "short_codename": "phuong_son"
                    },
                    {
                        "name": "Phường Phước Hải",
                        "code": 22357,
                        "codename": "phuong_phuoc_hai",
                        "division_type": "phường",
                        "short_codename": "phuoc_hai"
                    },
                    {
                        "name": "Phường Phước Tân",
                        "code": 22360,
                        "codename": "phuong_phuoc_tan",
                        "division_type": "phường",
                        "short_codename": "phuoc_tan"
                    },
                    {
                        "name": "Phường Lộc Thọ",
                        "code": 22363,
                        "codename": "phuong_loc_tho",
                        "division_type": "phường",
                        "short_codename": "loc_tho"
                    },
                    {
                        "name": "Phường Phước Tiến",
                        "code": 22366,
                        "codename": "phuong_phuoc_tien",
                        "division_type": "phường",
                        "short_codename": "phuoc_tien"
                    },
                    {
                        "name": "Phường Tân Lập",
                        "code": 22369,
                        "codename": "phuong_tan_lap",
                        "division_type": "phường",
                        "short_codename": "tan_lap"
                    },
                    {
                        "name": "Phường Phước Hòa",
                        "code": 22372,
                        "codename": "phuong_phuoc_hoa",
                        "division_type": "phường",
                        "short_codename": "phuoc_hoa"
                    },
                    {
                        "name": "Phường Vĩnh Nguyên",
                        "code": 22375,
                        "codename": "phuong_vinh_nguyen",
                        "division_type": "phường",
                        "short_codename": "vinh_nguyen"
                    },
                    {
                        "name": "Phường Phước Long",
                        "code": 22378,
                        "codename": "phuong_phuoc_long",
                        "division_type": "phường",
                        "short_codename": "phuoc_long"
                    },
                    {
                        "name": "Phường Vĩnh Trường",
                        "code": 22381,
                        "codename": "phuong_vinh_truong",
                        "division_type": "phường",
                        "short_codename": "vinh_truong"
                    },
                    {
                        "name": "Xã Vĩnh Lương",
                        "code": 22384,
                        "codename": "xa_vinh_luong",
                        "division_type": "xã",
                        "short_codename": "vinh_luong"
                    },
                    {
                        "name": "Xã Vĩnh Phương",
                        "code": 22387,
                        "codename": "xa_vinh_phuong",
                        "division_type": "xã",
                        "short_codename": "vinh_phuong"
                    },
                    {
                        "name": "Xã Vĩnh Ngọc",
                        "code": 22390,
                        "codename": "xa_vinh_ngoc",
                        "division_type": "xã",
                        "short_codename": "vinh_ngoc"
                    },
                    {
                        "name": "Xã Vĩnh Thạnh",
                        "code": 22393,
                        "codename": "xa_vinh_thanh",
                        "division_type": "xã",
                        "short_codename": "vinh_thanh"
                    },
                    {
                        "name": "Xã Vĩnh Trung",
                        "code": 22396,
                        "codename": "xa_vinh_trung",
                        "division_type": "xã",
                        "short_codename": "vinh_trung"
                    },
                    {
                        "name": "Xã Vĩnh Hiệp",
                        "code": 22399,
                        "codename": "xa_vinh_hiep",
                        "division_type": "xã",
                        "short_codename": "vinh_hiep"
                    },
                    {
                        "name": "Xã Vĩnh Thái",
                        "code": 22402,
                        "codename": "xa_vinh_thai",
                        "division_type": "xã",
                        "short_codename": "vinh_thai"
                    },
                    {
                        "name": "Xã Phước Đồng",
                        "code": 22405,
                        "codename": "xa_phuoc_dong",
                        "division_type": "xã",
                        "short_codename": "phuoc_dong"
                    }
                ]
            },
            {
                "name": "Thành phố Cam Ranh",
                "code": 569,
                "codename": "thanh_pho_cam_ranh",
                "division_type": "thành phố",
                "short_codename": "cam_ranh",
                "wards": [
                    {
                        "name": "Phường Cam Nghĩa",
                        "code": 22408,
                        "codename": "phuong_cam_nghia",
                        "division_type": "phường",
                        "short_codename": "cam_nghia"
                    },
                    {
                        "name": "Phường Cam Phúc Bắc",
                        "code": 22411,
                        "codename": "phuong_cam_phuc_bac",
                        "division_type": "phường",
                        "short_codename": "cam_phuc_bac"
                    },
                    {
                        "name": "Phường Cam Phúc Nam",
                        "code": 22414,
                        "codename": "phuong_cam_phuc_nam",
                        "division_type": "phường",
                        "short_codename": "cam_phuc_nam"
                    },
                    {
                        "name": "Phường Cam Lộc",
                        "code": 22417,
                        "codename": "phuong_cam_loc",
                        "division_type": "phường",
                        "short_codename": "cam_loc"
                    },
                    {
                        "name": "Phường Cam Phú",
                        "code": 22420,
                        "codename": "phuong_cam_phu",
                        "division_type": "phường",
                        "short_codename": "cam_phu"
                    },
                    {
                        "name": "Phường Ba Ngòi",
                        "code": 22423,
                        "codename": "phuong_ba_ngoi",
                        "division_type": "phường",
                        "short_codename": "ba_ngoi"
                    },
                    {
                        "name": "Phường Cam Thuận",
                        "code": 22426,
                        "codename": "phuong_cam_thuan",
                        "division_type": "phường",
                        "short_codename": "cam_thuan"
                    },
                    {
                        "name": "Phường Cam Lợi",
                        "code": 22429,
                        "codename": "phuong_cam_loi",
                        "division_type": "phường",
                        "short_codename": "cam_loi"
                    },
                    {
                        "name": "Phường Cam Linh",
                        "code": 22432,
                        "codename": "phuong_cam_linh",
                        "division_type": "phường",
                        "short_codename": "cam_linh"
                    },
                    {
                        "name": "Xã Cam Thành Nam",
                        "code": 22468,
                        "codename": "xa_cam_thanh_nam",
                        "division_type": "xã",
                        "short_codename": "cam_thanh_nam"
                    },
                    {
                        "name": "Xã Cam Phước Đông",
                        "code": 22474,
                        "codename": "xa_cam_phuoc_dong",
                        "division_type": "xã",
                        "short_codename": "cam_phuoc_dong"
                    },
                    {
                        "name": "Xã Cam Thịnh Tây",
                        "code": 22477,
                        "codename": "xa_cam_thinh_tay",
                        "division_type": "xã",
                        "short_codename": "cam_thinh_tay"
                    },
                    {
                        "name": "Xã Cam Thịnh Đông",
                        "code": 22480,
                        "codename": "xa_cam_thinh_dong",
                        "division_type": "xã",
                        "short_codename": "cam_thinh_dong"
                    },
                    {
                        "name": "Xã Cam Lập",
                        "code": 22483,
                        "codename": "xa_cam_lap",
                        "division_type": "xã",
                        "short_codename": "cam_lap"
                    },
                    {
                        "name": "Xã Cam Bình",
                        "code": 22486,
                        "codename": "xa_cam_binh",
                        "division_type": "xã",
                        "short_codename": "cam_binh"
                    }
                ]
            },
            {
                "name": "Huyện Cam Lâm",
                "code": 570,
                "codename": "huyen_cam_lam",
                "division_type": "huyện",
                "short_codename": "cam_lam",
                "wards": [
                    {
                        "name": "Xã Cam Tân",
                        "code": 22435,
                        "codename": "xa_cam_tan",
                        "division_type": "xã",
                        "short_codename": "cam_tan"
                    },
                    {
                        "name": "Xã Cam Hòa",
                        "code": 22438,
                        "codename": "xa_cam_hoa",
                        "division_type": "xã",
                        "short_codename": "cam_hoa"
                    },
                    {
                        "name": "Xã Cam Hải Đông",
                        "code": 22441,
                        "codename": "xa_cam_hai_dong",
                        "division_type": "xã",
                        "short_codename": "cam_hai_dong"
                    },
                    {
                        "name": "Xã Cam Hải Tây",
                        "code": 22444,
                        "codename": "xa_cam_hai_tay",
                        "division_type": "xã",
                        "short_codename": "cam_hai_tay"
                    },
                    {
                        "name": "Xã Sơn Tân",
                        "code": 22447,
                        "codename": "xa_son_tan",
                        "division_type": "xã",
                        "short_codename": "son_tan"
                    },
                    {
                        "name": "Xã Cam Hiệp Bắc",
                        "code": 22450,
                        "codename": "xa_cam_hiep_bac",
                        "division_type": "xã",
                        "short_codename": "cam_hiep_bac"
                    },
                    {
                        "name": "Thị trấn Cam Đức",
                        "code": 22453,
                        "codename": "thi_tran_cam_duc",
                        "division_type": "thị trấn",
                        "short_codename": "cam_duc"
                    },
                    {
                        "name": "Xã Cam Hiệp Nam",
                        "code": 22456,
                        "codename": "xa_cam_hiep_nam",
                        "division_type": "xã",
                        "short_codename": "cam_hiep_nam"
                    },
                    {
                        "name": "Xã Cam Phước Tây",
                        "code": 22459,
                        "codename": "xa_cam_phuoc_tay",
                        "division_type": "xã",
                        "short_codename": "cam_phuoc_tay"
                    },
                    {
                        "name": "Xã Cam Thành Bắc",
                        "code": 22462,
                        "codename": "xa_cam_thanh_bac",
                        "division_type": "xã",
                        "short_codename": "cam_thanh_bac"
                    },
                    {
                        "name": "Xã Cam An Bắc",
                        "code": 22465,
                        "codename": "xa_cam_an_bac",
                        "division_type": "xã",
                        "short_codename": "cam_an_bac"
                    },
                    {
                        "name": "Xã Cam An Nam",
                        "code": 22471,
                        "codename": "xa_cam_an_nam",
                        "division_type": "xã",
                        "short_codename": "cam_an_nam"
                    },
                    {
                        "name": "Xã Suối Cát",
                        "code": 22708,
                        "codename": "xa_suoi_cat",
                        "division_type": "xã",
                        "short_codename": "suoi_cat"
                    },
                    {
                        "name": "Xã Suối Tân",
                        "code": 22711,
                        "codename": "xa_suoi_tan",
                        "division_type": "xã",
                        "short_codename": "suoi_tan"
                    }
                ]
            },
            {
                "name": "Huyện Vạn Ninh",
                "code": 571,
                "codename": "huyen_van_ninh",
                "division_type": "huyện",
                "short_codename": "van_ninh",
                "wards": [
                    {
                        "name": "Thị trấn Vạn Giã",
                        "code": 22489,
                        "codename": "thi_tran_van_gia",
                        "division_type": "thị trấn",
                        "short_codename": "van_gia"
                    },
                    {
                        "name": "Xã Đại Lãnh",
                        "code": 22492,
                        "codename": "xa_dai_lanh",
                        "division_type": "xã",
                        "short_codename": "dai_lanh"
                    },
                    {
                        "name": "Xã Vạn Phước",
                        "code": 22495,
                        "codename": "xa_van_phuoc",
                        "division_type": "xã",
                        "short_codename": "van_phuoc"
                    },
                    {
                        "name": "Xã Vạn Long",
                        "code": 22498,
                        "codename": "xa_van_long",
                        "division_type": "xã",
                        "short_codename": "van_long"
                    },
                    {
                        "name": "Xã Vạn Bình",
                        "code": 22501,
                        "codename": "xa_van_binh",
                        "division_type": "xã",
                        "short_codename": "van_binh"
                    },
                    {
                        "name": "Xã Vạn Thọ",
                        "code": 22504,
                        "codename": "xa_van_tho",
                        "division_type": "xã",
                        "short_codename": "van_tho"
                    },
                    {
                        "name": "Xã Vạn Khánh",
                        "code": 22507,
                        "codename": "xa_van_khanh",
                        "division_type": "xã",
                        "short_codename": "van_khanh"
                    },
                    {
                        "name": "Xã Vạn Phú",
                        "code": 22510,
                        "codename": "xa_van_phu",
                        "division_type": "xã",
                        "short_codename": "van_phu"
                    },
                    {
                        "name": "Xã Vạn Lương",
                        "code": 22513,
                        "codename": "xa_van_luong",
                        "division_type": "xã",
                        "short_codename": "van_luong"
                    },
                    {
                        "name": "Xã Vạn Thắng",
                        "code": 22516,
                        "codename": "xa_van_thang",
                        "division_type": "xã",
                        "short_codename": "van_thang"
                    },
                    {
                        "name": "Xã Vạn Thạnh",
                        "code": 22519,
                        "codename": "xa_van_thanh",
                        "division_type": "xã",
                        "short_codename": "van_thanh"
                    },
                    {
                        "name": "Xã Xuân Sơn",
                        "code": 22522,
                        "codename": "xa_xuan_son",
                        "division_type": "xã",
                        "short_codename": "xuan_son"
                    },
                    {
                        "name": "Xã Vạn Hưng",
                        "code": 22525,
                        "codename": "xa_van_hung",
                        "division_type": "xã",
                        "short_codename": "van_hung"
                    }
                ]
            },
            {
                "name": "Thị xã Ninh Hòa",
                "code": 572,
                "codename": "thi_xa_ninh_hoa",
                "division_type": "thị xã",
                "short_codename": "ninh_hoa",
                "wards": [
                    {
                        "name": "Phường Ninh Hiệp",
                        "code": 22528,
                        "codename": "phuong_ninh_hiep",
                        "division_type": "phường",
                        "short_codename": "ninh_hiep"
                    },
                    {
                        "name": "Xã Ninh Sơn",
                        "code": 22531,
                        "codename": "xa_ninh_son",
                        "division_type": "xã",
                        "short_codename": "ninh_son"
                    },
                    {
                        "name": "Xã Ninh Tây",
                        "code": 22534,
                        "codename": "xa_ninh_tay",
                        "division_type": "xã",
                        "short_codename": "ninh_tay"
                    },
                    {
                        "name": "Xã Ninh Thượng",
                        "code": 22537,
                        "codename": "xa_ninh_thuong",
                        "division_type": "xã",
                        "short_codename": "ninh_thuong"
                    },
                    {
                        "name": "Xã Ninh An",
                        "code": 22540,
                        "codename": "xa_ninh_an",
                        "division_type": "xã",
                        "short_codename": "ninh_an"
                    },
                    {
                        "name": "Phường Ninh Hải",
                        "code": 22543,
                        "codename": "phuong_ninh_hai",
                        "division_type": "phường",
                        "short_codename": "ninh_hai"
                    },
                    {
                        "name": "Xã Ninh Thọ",
                        "code": 22546,
                        "codename": "xa_ninh_tho",
                        "division_type": "xã",
                        "short_codename": "ninh_tho"
                    },
                    {
                        "name": "Xã Ninh Trung",
                        "code": 22549,
                        "codename": "xa_ninh_trung",
                        "division_type": "xã",
                        "short_codename": "ninh_trung"
                    },
                    {
                        "name": "Xã Ninh Sim",
                        "code": 22552,
                        "codename": "xa_ninh_sim",
                        "division_type": "xã",
                        "short_codename": "ninh_sim"
                    },
                    {
                        "name": "Xã Ninh Xuân",
                        "code": 22555,
                        "codename": "xa_ninh_xuan",
                        "division_type": "xã",
                        "short_codename": "ninh_xuan"
                    },
                    {
                        "name": "Xã Ninh Thân",
                        "code": 22558,
                        "codename": "xa_ninh_than",
                        "division_type": "xã",
                        "short_codename": "ninh_than"
                    },
                    {
                        "name": "Phường Ninh Diêm",
                        "code": 22561,
                        "codename": "phuong_ninh_diem",
                        "division_type": "phường",
                        "short_codename": "ninh_diem"
                    },
                    {
                        "name": "Xã Ninh Đông",
                        "code": 22564,
                        "codename": "xa_ninh_dong",
                        "division_type": "xã",
                        "short_codename": "ninh_dong"
                    },
                    {
                        "name": "Phường Ninh Thủy",
                        "code": 22567,
                        "codename": "phuong_ninh_thuy",
                        "division_type": "phường",
                        "short_codename": "ninh_thuy"
                    },
                    {
                        "name": "Phường Ninh Đa",
                        "code": 22570,
                        "codename": "phuong_ninh_da",
                        "division_type": "phường",
                        "short_codename": "ninh_da"
                    },
                    {
                        "name": "Xã Ninh Phụng",
                        "code": 22573,
                        "codename": "xa_ninh_phung",
                        "division_type": "xã",
                        "short_codename": "ninh_phung"
                    },
                    {
                        "name": "Xã Ninh Bình",
                        "code": 22576,
                        "codename": "xa_ninh_binh",
                        "division_type": "xã",
                        "short_codename": "ninh_binh"
                    },
                    {
                        "name": "Xã Ninh Phước",
                        "code": 22579,
                        "codename": "xa_ninh_phuoc",
                        "division_type": "xã",
                        "short_codename": "ninh_phuoc"
                    },
                    {
                        "name": "Xã Ninh Phú",
                        "code": 22582,
                        "codename": "xa_ninh_phu",
                        "division_type": "xã",
                        "short_codename": "ninh_phu"
                    },
                    {
                        "name": "Xã Ninh Tân",
                        "code": 22585,
                        "codename": "xa_ninh_tan",
                        "division_type": "xã",
                        "short_codename": "ninh_tan"
                    },
                    {
                        "name": "Xã Ninh Quang",
                        "code": 22588,
                        "codename": "xa_ninh_quang",
                        "division_type": "xã",
                        "short_codename": "ninh_quang"
                    },
                    {
                        "name": "Phường Ninh Giang",
                        "code": 22591,
                        "codename": "phuong_ninh_giang",
                        "division_type": "phường",
                        "short_codename": "ninh_giang"
                    },
                    {
                        "name": "Phường Ninh Hà",
                        "code": 22594,
                        "codename": "phuong_ninh_ha",
                        "division_type": "phường",
                        "short_codename": "ninh_ha"
                    },
                    {
                        "name": "Xã Ninh Hưng",
                        "code": 22597,
                        "codename": "xa_ninh_hung",
                        "division_type": "xã",
                        "short_codename": "ninh_hung"
                    },
                    {
                        "name": "Xã Ninh Lộc",
                        "code": 22600,
                        "codename": "xa_ninh_loc",
                        "division_type": "xã",
                        "short_codename": "ninh_loc"
                    },
                    {
                        "name": "Xã Ninh Ích",
                        "code": 22603,
                        "codename": "xa_ninh_ich",
                        "division_type": "xã",
                        "short_codename": "ninh_ich"
                    },
                    {
                        "name": "Xã Ninh Vân",
                        "code": 22606,
                        "codename": "xa_ninh_van",
                        "division_type": "xã",
                        "short_codename": "ninh_van"
                    }
                ]
            },
            {
                "name": "Huyện Khánh Vĩnh",
                "code": 573,
                "codename": "huyen_khanh_vinh",
                "division_type": "huyện",
                "short_codename": "khanh_vinh",
                "wards": [
                    {
                        "name": "Thị trấn Khánh Vĩnh",
                        "code": 22609,
                        "codename": "thi_tran_khanh_vinh",
                        "division_type": "thị trấn",
                        "short_codename": "khanh_vinh"
                    },
                    {
                        "name": "Xã Khánh Hiệp",
                        "code": 22612,
                        "codename": "xa_khanh_hiep",
                        "division_type": "xã",
                        "short_codename": "khanh_hiep"
                    },
                    {
                        "name": "Xã Khánh Bình",
                        "code": 22615,
                        "codename": "xa_khanh_binh",
                        "division_type": "xã",
                        "short_codename": "khanh_binh"
                    },
                    {
                        "name": "Xã Khánh Trung",
                        "code": 22618,
                        "codename": "xa_khanh_trung",
                        "division_type": "xã",
                        "short_codename": "khanh_trung"
                    },
                    {
                        "name": "Xã Khánh Đông",
                        "code": 22621,
                        "codename": "xa_khanh_dong",
                        "division_type": "xã",
                        "short_codename": "khanh_dong"
                    },
                    {
                        "name": "Xã Khánh Thượng",
                        "code": 22624,
                        "codename": "xa_khanh_thuong",
                        "division_type": "xã",
                        "short_codename": "khanh_thuong"
                    },
                    {
                        "name": "Xã Khánh Nam",
                        "code": 22627,
                        "codename": "xa_khanh_nam",
                        "division_type": "xã",
                        "short_codename": "khanh_nam"
                    },
                    {
                        "name": "Xã Sông Cầu",
                        "code": 22630,
                        "codename": "xa_song_cau",
                        "division_type": "xã",
                        "short_codename": "song_cau"
                    },
                    {
                        "name": "Xã Giang Ly",
                        "code": 22633,
                        "codename": "xa_giang_ly",
                        "division_type": "xã",
                        "short_codename": "giang_ly"
                    },
                    {
                        "name": "Xã Cầu Bà",
                        "code": 22636,
                        "codename": "xa_cau_ba",
                        "division_type": "xã",
                        "short_codename": "cau_ba"
                    },
                    {
                        "name": "Xã Liên Sang",
                        "code": 22639,
                        "codename": "xa_lien_sang",
                        "division_type": "xã",
                        "short_codename": "lien_sang"
                    },
                    {
                        "name": "Xã Khánh Thành",
                        "code": 22642,
                        "codename": "xa_khanh_thanh",
                        "division_type": "xã",
                        "short_codename": "khanh_thanh"
                    },
                    {
                        "name": "Xã Khánh Phú",
                        "code": 22645,
                        "codename": "xa_khanh_phu",
                        "division_type": "xã",
                        "short_codename": "khanh_phu"
                    },
                    {
                        "name": "Xã Sơn Thái",
                        "code": 22648,
                        "codename": "xa_son_thai",
                        "division_type": "xã",
                        "short_codename": "son_thai"
                    }
                ]
            },
            {
                "name": "Huyện Diên Khánh",
                "code": 574,
                "codename": "huyen_dien_khanh",
                "division_type": "huyện",
                "short_codename": "dien_khanh",
                "wards": [
                    {
                        "name": "Thị trấn Diên Khánh",
                        "code": 22651,
                        "codename": "thi_tran_dien_khanh",
                        "division_type": "thị trấn",
                        "short_codename": "dien_khanh"
                    },
                    {
                        "name": "Xã Diên Lâm",
                        "code": 22654,
                        "codename": "xa_dien_lam",
                        "division_type": "xã",
                        "short_codename": "dien_lam"
                    },
                    {
                        "name": "Xã Diên Điền",
                        "code": 22657,
                        "codename": "xa_dien_dien",
                        "division_type": "xã",
                        "short_codename": "dien_dien"
                    },
                    {
                        "name": "Xã Diên Xuân",
                        "code": 22660,
                        "codename": "xa_dien_xuan",
                        "division_type": "xã",
                        "short_codename": "dien_xuan"
                    },
                    {
                        "name": "Xã Diên Sơn",
                        "code": 22663,
                        "codename": "xa_dien_son",
                        "division_type": "xã",
                        "short_codename": "dien_son"
                    },
                    {
                        "name": "Xã Diên Đồng",
                        "code": 22666,
                        "codename": "xa_dien_dong",
                        "division_type": "xã",
                        "short_codename": "dien_dong"
                    },
                    {
                        "name": "Xã Diên Phú",
                        "code": 22669,
                        "codename": "xa_dien_phu",
                        "division_type": "xã",
                        "short_codename": "dien_phu"
                    },
                    {
                        "name": "Xã Diên Thọ",
                        "code": 22672,
                        "codename": "xa_dien_tho",
                        "division_type": "xã",
                        "short_codename": "dien_tho"
                    },
                    {
                        "name": "Xã Diên Phước",
                        "code": 22675,
                        "codename": "xa_dien_phuoc",
                        "division_type": "xã",
                        "short_codename": "dien_phuoc"
                    },
                    {
                        "name": "Xã Diên Lạc",
                        "code": 22678,
                        "codename": "xa_dien_lac",
                        "division_type": "xã",
                        "short_codename": "dien_lac"
                    },
                    {
                        "name": "Xã Diên Tân",
                        "code": 22681,
                        "codename": "xa_dien_tan",
                        "division_type": "xã",
                        "short_codename": "dien_tan"
                    },
                    {
                        "name": "Xã Diên Hòa",
                        "code": 22684,
                        "codename": "xa_dien_hoa",
                        "division_type": "xã",
                        "short_codename": "dien_hoa"
                    },
                    {
                        "name": "Xã Diên Thạnh",
                        "code": 22687,
                        "codename": "xa_dien_thanh",
                        "division_type": "xã",
                        "short_codename": "dien_thanh"
                    },
                    {
                        "name": "Xã Diên Toàn",
                        "code": 22690,
                        "codename": "xa_dien_toan",
                        "division_type": "xã",
                        "short_codename": "dien_toan"
                    },
                    {
                        "name": "Xã Diên An",
                        "code": 22693,
                        "codename": "xa_dien_an",
                        "division_type": "xã",
                        "short_codename": "dien_an"
                    },
                    {
                        "name": "Xã Bình Lộc",
                        "code": 22696,
                        "codename": "xa_binh_loc",
                        "division_type": "xã",
                        "short_codename": "binh_loc"
                    },
                    {
                        "name": "Xã Suối Hiệp",
                        "code": 22702,
                        "codename": "xa_suoi_hiep",
                        "division_type": "xã",
                        "short_codename": "suoi_hiep"
                    },
                    {
                        "name": "Xã Suối Tiên",
                        "code": 22705,
                        "codename": "xa_suoi_tien",
                        "division_type": "xã",
                        "short_codename": "suoi_tien"
                    }
                ]
            },
            {
                "name": "Huyện Khánh Sơn",
                "code": 575,
                "codename": "huyen_khanh_son",
                "division_type": "huyện",
                "short_codename": "khanh_son",
                "wards": [
                    {
                        "name": "Thị trấn Tô Hạp",
                        "code": 22714,
                        "codename": "thi_tran_to_hap",
                        "division_type": "thị trấn",
                        "short_codename": "to_hap"
                    },
                    {
                        "name": "Xã Thành Sơn",
                        "code": 22717,
                        "codename": "xa_thanh_son",
                        "division_type": "xã",
                        "short_codename": "thanh_son"
                    },
                    {
                        "name": "Xã Sơn Lâm",
                        "code": 22720,
                        "codename": "xa_son_lam",
                        "division_type": "xã",
                        "short_codename": "son_lam"
                    },
                    {
                        "name": "Xã Sơn Hiệp",
                        "code": 22723,
                        "codename": "xa_son_hiep",
                        "division_type": "xã",
                        "short_codename": "son_hiep"
                    },
                    {
                        "name": "Xã Sơn Bình",
                        "code": 22726,
                        "codename": "xa_son_binh",
                        "division_type": "xã",
                        "short_codename": "son_binh"
                    },
                    {
                        "name": "Xã Sơn Trung",
                        "code": 22729,
                        "codename": "xa_son_trung",
                        "division_type": "xã",
                        "short_codename": "son_trung"
                    },
                    {
                        "name": "Xã Ba Cụm Bắc",
                        "code": 22732,
                        "codename": "xa_ba_cum_bac",
                        "division_type": "xã",
                        "short_codename": "ba_cum_bac"
                    },
                    {
                        "name": "Xã Ba Cụm Nam",
                        "code": 22735,
                        "codename": "xa_ba_cum_nam",
                        "division_type": "xã",
                        "short_codename": "ba_cum_nam"
                    }
                ]
            },
            {
                "name": "Huyện Trường Sa",
                "code": 576,
                "codename": "huyen_truong_sa",
                "division_type": "huyện",
                "short_codename": "truong_sa",
                "wards": [
                    {
                        "name": "Thị trấn Trường Sa",
                        "code": 22736,
                        "codename": "thi_tran_truong_sa",
                        "division_type": "thị trấn",
                        "short_codename": "truong_sa"
                    },
                    {
                        "name": "Xã Song Tử Tây",
                        "code": 22737,
                        "codename": "xa_song_tu_tay",
                        "division_type": "xã",
                        "short_codename": "song_tu_tay"
                    },
                    {
                        "name": "Xã Sinh Tồn",
                        "code": 22739,
                        "codename": "xa_sinh_ton",
                        "division_type": "xã",
                        "short_codename": "sinh_ton"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Ninh Thuận",
        "code": 58,
        "codename": "tinh_ninh_thuan",
        "division_type": "tỉnh",
        "phone_code": 259,
        "districts": [
            {
                "name": "Thành phố Phan Rang-Tháp Chàm",
                "code": 582,
                "codename": "thanh_pho_phan_rang_thap_cham",
                "division_type": "thành phố",
                "short_codename": "phan_rang_thap_cham",
                "wards": [
                    {
                        "name": "Phường Đô Vinh",
                        "code": 22738,
                        "codename": "phuong_do_vinh",
                        "division_type": "phường",
                        "short_codename": "do_vinh"
                    },
                    {
                        "name": "Phường Phước Mỹ",
                        "code": 22741,
                        "codename": "phuong_phuoc_my",
                        "division_type": "phường",
                        "short_codename": "phuoc_my"
                    },
                    {
                        "name": "Phường Bảo An",
                        "code": 22744,
                        "codename": "phuong_bao_an",
                        "division_type": "phường",
                        "short_codename": "bao_an"
                    },
                    {
                        "name": "Phường Phủ Hà",
                        "code": 22747,
                        "codename": "phuong_phu_ha",
                        "division_type": "phường",
                        "short_codename": "phu_ha"
                    },
                    {
                        "name": "Phường Thanh Sơn",
                        "code": 22750,
                        "codename": "phuong_thanh_son",
                        "division_type": "phường",
                        "short_codename": "thanh_son"
                    },
                    {
                        "name": "Phường Mỹ Hương",
                        "code": 22753,
                        "codename": "phuong_my_huong",
                        "division_type": "phường",
                        "short_codename": "my_huong"
                    },
                    {
                        "name": "Phường Tấn Tài",
                        "code": 22756,
                        "codename": "phuong_tan_tai",
                        "division_type": "phường",
                        "short_codename": "tan_tai"
                    },
                    {
                        "name": "Phường Kinh Dinh",
                        "code": 22759,
                        "codename": "phuong_kinh_dinh",
                        "division_type": "phường",
                        "short_codename": "kinh_dinh"
                    },
                    {
                        "name": "Phường Đạo Long",
                        "code": 22762,
                        "codename": "phuong_dao_long",
                        "division_type": "phường",
                        "short_codename": "dao_long"
                    },
                    {
                        "name": "Phường Đài Sơn",
                        "code": 22765,
                        "codename": "phuong_dai_son",
                        "division_type": "phường",
                        "short_codename": "dai_son"
                    },
                    {
                        "name": "Phường Đông Hải",
                        "code": 22768,
                        "codename": "phuong_dong_hai",
                        "division_type": "phường",
                        "short_codename": "dong_hai"
                    },
                    {
                        "name": "Phường Mỹ Đông",
                        "code": 22771,
                        "codename": "phuong_my_dong",
                        "division_type": "phường",
                        "short_codename": "my_dong"
                    },
                    {
                        "name": "Xã Thành Hải",
                        "code": 22774,
                        "codename": "xa_thanh_hai",
                        "division_type": "xã",
                        "short_codename": "thanh_hai"
                    },
                    {
                        "name": "Phường Văn Hải",
                        "code": 22777,
                        "codename": "phuong_van_hai",
                        "division_type": "phường",
                        "short_codename": "van_hai"
                    },
                    {
                        "name": "Phường Mỹ Bình",
                        "code": 22779,
                        "codename": "phuong_my_binh",
                        "division_type": "phường",
                        "short_codename": "my_binh"
                    },
                    {
                        "name": "Phường Mỹ Hải",
                        "code": 22780,
                        "codename": "phuong_my_hai",
                        "division_type": "phường",
                        "short_codename": "my_hai"
                    }
                ]
            },
            {
                "name": "Huyện Bác Ái",
                "code": 584,
                "codename": "huyen_bac_ai",
                "division_type": "huyện",
                "short_codename": "bac_ai",
                "wards": [
                    {
                        "name": "Xã Phước Bình",
                        "code": 22783,
                        "codename": "xa_phuoc_binh",
                        "division_type": "xã",
                        "short_codename": "phuoc_binh"
                    },
                    {
                        "name": "Xã Phước Hòa",
                        "code": 22786,
                        "codename": "xa_phuoc_hoa",
                        "division_type": "xã",
                        "short_codename": "phuoc_hoa"
                    },
                    {
                        "name": "Xã Phước Tân",
                        "code": 22789,
                        "codename": "xa_phuoc_tan",
                        "division_type": "xã",
                        "short_codename": "phuoc_tan"
                    },
                    {
                        "name": "Xã Phước Tiến",
                        "code": 22792,
                        "codename": "xa_phuoc_tien",
                        "division_type": "xã",
                        "short_codename": "phuoc_tien"
                    },
                    {
                        "name": "Xã Phước Thắng",
                        "code": 22795,
                        "codename": "xa_phuoc_thang",
                        "division_type": "xã",
                        "short_codename": "phuoc_thang"
                    },
                    {
                        "name": "Xã Phước Thành",
                        "code": 22798,
                        "codename": "xa_phuoc_thanh",
                        "division_type": "xã",
                        "short_codename": "phuoc_thanh"
                    },
                    {
                        "name": "Xã Phước Đại",
                        "code": 22801,
                        "codename": "xa_phuoc_dai",
                        "division_type": "xã",
                        "short_codename": "phuoc_dai"
                    },
                    {
                        "name": "Xã Phước Chính",
                        "code": 22804,
                        "codename": "xa_phuoc_chinh",
                        "division_type": "xã",
                        "short_codename": "phuoc_chinh"
                    },
                    {
                        "name": "Xã Phước Trung",
                        "code": 22807,
                        "codename": "xa_phuoc_trung",
                        "division_type": "xã",
                        "short_codename": "phuoc_trung"
                    }
                ]
            },
            {
                "name": "Huyện Ninh Sơn",
                "code": 585,
                "codename": "huyen_ninh_son",
                "division_type": "huyện",
                "short_codename": "ninh_son",
                "wards": [
                    {
                        "name": "Thị trấn Tân Sơn",
                        "code": 22810,
                        "codename": "thi_tran_tan_son",
                        "division_type": "thị trấn",
                        "short_codename": "tan_son"
                    },
                    {
                        "name": "Xã Lâm Sơn",
                        "code": 22813,
                        "codename": "xa_lam_son",
                        "division_type": "xã",
                        "short_codename": "lam_son"
                    },
                    {
                        "name": "Xã Lương Sơn",
                        "code": 22816,
                        "codename": "xa_luong_son",
                        "division_type": "xã",
                        "short_codename": "luong_son"
                    },
                    {
                        "name": "Xã Quảng Sơn",
                        "code": 22819,
                        "codename": "xa_quang_son",
                        "division_type": "xã",
                        "short_codename": "quang_son"
                    },
                    {
                        "name": "Xã Mỹ Sơn",
                        "code": 22822,
                        "codename": "xa_my_son",
                        "division_type": "xã",
                        "short_codename": "my_son"
                    },
                    {
                        "name": "Xã Hòa Sơn",
                        "code": 22825,
                        "codename": "xa_hoa_son",
                        "division_type": "xã",
                        "short_codename": "hoa_son"
                    },
                    {
                        "name": "Xã Ma Nới",
                        "code": 22828,
                        "codename": "xa_ma_noi",
                        "division_type": "xã",
                        "short_codename": "ma_noi"
                    },
                    {
                        "name": "Xã Nhơn Sơn",
                        "code": 22831,
                        "codename": "xa_nhon_son",
                        "division_type": "xã",
                        "short_codename": "nhon_son"
                    }
                ]
            },
            {
                "name": "Huyện Ninh Hải",
                "code": 586,
                "codename": "huyen_ninh_hai",
                "division_type": "huyện",
                "short_codename": "ninh_hai",
                "wards": [
                    {
                        "name": "Thị trấn Khánh Hải",
                        "code": 22834,
                        "codename": "thi_tran_khanh_hai",
                        "division_type": "thị trấn",
                        "short_codename": "khanh_hai"
                    },
                    {
                        "name": "Xã Vĩnh Hải",
                        "code": 22846,
                        "codename": "xa_vinh_hai",
                        "division_type": "xã",
                        "short_codename": "vinh_hai"
                    },
                    {
                        "name": "Xã Phương Hải",
                        "code": 22852,
                        "codename": "xa_phuong_hai",
                        "division_type": "xã",
                        "short_codename": "phuong_hai"
                    },
                    {
                        "name": "Xã Tân Hải",
                        "code": 22855,
                        "codename": "xa_tan_hai",
                        "division_type": "xã",
                        "short_codename": "tan_hai"
                    },
                    {
                        "name": "Xã Xuân Hải",
                        "code": 22858,
                        "codename": "xa_xuan_hai",
                        "division_type": "xã",
                        "short_codename": "xuan_hai"
                    },
                    {
                        "name": "Xã Hộ Hải",
                        "code": 22861,
                        "codename": "xa_ho_hai",
                        "division_type": "xã",
                        "short_codename": "ho_hai"
                    },
                    {
                        "name": "Xã Tri Hải",
                        "code": 22864,
                        "codename": "xa_tri_hai",
                        "division_type": "xã",
                        "short_codename": "tri_hai"
                    },
                    {
                        "name": "Xã Nhơn Hải",
                        "code": 22867,
                        "codename": "xa_nhon_hai",
                        "division_type": "xã",
                        "short_codename": "nhon_hai"
                    },
                    {
                        "name": "Xã Thanh Hải",
                        "code": 22868,
                        "codename": "xa_thanh_hai",
                        "division_type": "xã",
                        "short_codename": "thanh_hai"
                    }
                ]
            },
            {
                "name": "Huyện Ninh Phước",
                "code": 587,
                "codename": "huyen_ninh_phuoc",
                "division_type": "huyện",
                "short_codename": "ninh_phuoc",
                "wards": [
                    {
                        "name": "Thị trấn Phước Dân",
                        "code": 22870,
                        "codename": "thi_tran_phuoc_dan",
                        "division_type": "thị trấn",
                        "short_codename": "phuoc_dan"
                    },
                    {
                        "name": "Xã Phước Sơn",
                        "code": 22873,
                        "codename": "xa_phuoc_son",
                        "division_type": "xã",
                        "short_codename": "phuoc_son"
                    },
                    {
                        "name": "Xã Phước Thái",
                        "code": 22876,
                        "codename": "xa_phuoc_thai",
                        "division_type": "xã",
                        "short_codename": "phuoc_thai"
                    },
                    {
                        "name": "Xã Phước Hậu",
                        "code": 22879,
                        "codename": "xa_phuoc_hau",
                        "division_type": "xã",
                        "short_codename": "phuoc_hau"
                    },
                    {
                        "name": "Xã Phước Thuận",
                        "code": 22882,
                        "codename": "xa_phuoc_thuan",
                        "division_type": "xã",
                        "short_codename": "phuoc_thuan"
                    },
                    {
                        "name": "Xã An Hải",
                        "code": 22888,
                        "codename": "xa_an_hai",
                        "division_type": "xã",
                        "short_codename": "an_hai"
                    },
                    {
                        "name": "Xã Phước Hữu",
                        "code": 22891,
                        "codename": "xa_phuoc_huu",
                        "division_type": "xã",
                        "short_codename": "phuoc_huu"
                    },
                    {
                        "name": "Xã Phước Hải",
                        "code": 22894,
                        "codename": "xa_phuoc_hai",
                        "division_type": "xã",
                        "short_codename": "phuoc_hai"
                    },
                    {
                        "name": "Xã Phước Vinh",
                        "code": 22912,
                        "codename": "xa_phuoc_vinh",
                        "division_type": "xã",
                        "short_codename": "phuoc_vinh"
                    }
                ]
            },
            {
                "name": "Huyện Thuận Bắc",
                "code": 588,
                "codename": "huyen_thuan_bac",
                "division_type": "huyện",
                "short_codename": "thuan_bac",
                "wards": [
                    {
                        "name": "Xã Phước Chiến",
                        "code": 22837,
                        "codename": "xa_phuoc_chien",
                        "division_type": "xã",
                        "short_codename": "phuoc_chien"
                    },
                    {
                        "name": "Xã Công Hải",
                        "code": 22840,
                        "codename": "xa_cong_hai",
                        "division_type": "xã",
                        "short_codename": "cong_hai"
                    },
                    {
                        "name": "Xã Phước Kháng",
                        "code": 22843,
                        "codename": "xa_phuoc_khang",
                        "division_type": "xã",
                        "short_codename": "phuoc_khang"
                    },
                    {
                        "name": "Xã Lợi Hải",
                        "code": 22849,
                        "codename": "xa_loi_hai",
                        "division_type": "xã",
                        "short_codename": "loi_hai"
                    },
                    {
                        "name": "Xã Bắc Sơn",
                        "code": 22853,
                        "codename": "xa_bac_son",
                        "division_type": "xã",
                        "short_codename": "bac_son"
                    },
                    {
                        "name": "Xã Bắc Phong",
                        "code": 22856,
                        "codename": "xa_bac_phong",
                        "division_type": "xã",
                        "short_codename": "bac_phong"
                    }
                ]
            },
            {
                "name": "Huyện Thuận Nam",
                "code": 589,
                "codename": "huyen_thuan_nam",
                "division_type": "huyện",
                "short_codename": "thuan_nam",
                "wards": [
                    {
                        "name": "Xã Phước Hà",
                        "code": 22885,
                        "codename": "xa_phuoc_ha",
                        "division_type": "xã",
                        "short_codename": "phuoc_ha"
                    },
                    {
                        "name": "Xã Phước Nam",
                        "code": 22897,
                        "codename": "xa_phuoc_nam",
                        "division_type": "xã",
                        "short_codename": "phuoc_nam"
                    },
                    {
                        "name": "Xã Phước Ninh",
                        "code": 22898,
                        "codename": "xa_phuoc_ninh",
                        "division_type": "xã",
                        "short_codename": "phuoc_ninh"
                    },
                    {
                        "name": "Xã Nhị Hà",
                        "code": 22900,
                        "codename": "xa_nhi_ha",
                        "division_type": "xã",
                        "short_codename": "nhi_ha"
                    },
                    {
                        "name": "Xã Phước Dinh",
                        "code": 22903,
                        "codename": "xa_phuoc_dinh",
                        "division_type": "xã",
                        "short_codename": "phuoc_dinh"
                    },
                    {
                        "name": "Xã Phước Minh",
                        "code": 22906,
                        "codename": "xa_phuoc_minh",
                        "division_type": "xã",
                        "short_codename": "phuoc_minh"
                    },
                    {
                        "name": "Xã Phước Diêm",
                        "code": 22909,
                        "codename": "xa_phuoc_diem",
                        "division_type": "xã",
                        "short_codename": "phuoc_diem"
                    },
                    {
                        "name": "Xã Cà Ná",
                        "code": 22910,
                        "codename": "xa_ca_na",
                        "division_type": "xã",
                        "short_codename": "ca_na"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Bình Thuận",
        "code": 60,
        "codename": "tinh_binh_thuan",
        "division_type": "tỉnh",
        "phone_code": 252,
        "districts": [
            {
                "name": "Thành phố Phan Thiết",
                "code": 593,
                "codename": "thanh_pho_phan_thiet",
                "division_type": "thành phố",
                "short_codename": "phan_thiet",
                "wards": [
                    {
                        "name": "Phường Mũi Né",
                        "code": 22915,
                        "codename": "phuong_mui_ne",
                        "division_type": "phường",
                        "short_codename": "mui_ne"
                    },
                    {
                        "name": "Phường Hàm Tiến",
                        "code": 22918,
                        "codename": "phuong_ham_tien",
                        "division_type": "phường",
                        "short_codename": "ham_tien"
                    },
                    {
                        "name": "Phường Phú Hài",
                        "code": 22921,
                        "codename": "phuong_phu_hai",
                        "division_type": "phường",
                        "short_codename": "phu_hai"
                    },
                    {
                        "name": "Phường Phú Thủy",
                        "code": 22924,
                        "codename": "phuong_phu_thuy",
                        "division_type": "phường",
                        "short_codename": "phu_thuy"
                    },
                    {
                        "name": "Phường Phú Tài",
                        "code": 22927,
                        "codename": "phuong_phu_tai",
                        "division_type": "phường",
                        "short_codename": "phu_tai"
                    },
                    {
                        "name": "Phường Phú Trinh",
                        "code": 22930,
                        "codename": "phuong_phu_trinh",
                        "division_type": "phường",
                        "short_codename": "phu_trinh"
                    },
                    {
                        "name": "Phường Xuân An",
                        "code": 22933,
                        "codename": "phuong_xuan_an",
                        "division_type": "phường",
                        "short_codename": "xuan_an"
                    },
                    {
                        "name": "Phường Thanh Hải",
                        "code": 22936,
                        "codename": "phuong_thanh_hai",
                        "division_type": "phường",
                        "short_codename": "thanh_hai"
                    },
                    {
                        "name": "Phường Bình Hưng",
                        "code": 22939,
                        "codename": "phuong_binh_hung",
                        "division_type": "phường",
                        "short_codename": "binh_hung"
                    },
                    {
                        "name": "Phường Đức Nghĩa",
                        "code": 22942,
                        "codename": "phuong_duc_nghia",
                        "division_type": "phường",
                        "short_codename": "duc_nghia"
                    },
                    {
                        "name": "Phường Lạc Đạo",
                        "code": 22945,
                        "codename": "phuong_lac_dao",
                        "division_type": "phường",
                        "short_codename": "lac_dao"
                    },
                    {
                        "name": "Phường Đức Thắng",
                        "code": 22948,
                        "codename": "phuong_duc_thang",
                        "division_type": "phường",
                        "short_codename": "duc_thang"
                    },
                    {
                        "name": "Phường Hưng Long",
                        "code": 22951,
                        "codename": "phuong_hung_long",
                        "division_type": "phường",
                        "short_codename": "hung_long"
                    },
                    {
                        "name": "Phường Đức Long",
                        "code": 22954,
                        "codename": "phuong_duc_long",
                        "division_type": "phường",
                        "short_codename": "duc_long"
                    },
                    {
                        "name": "Xã Thiện Nghiệp",
                        "code": 22957,
                        "codename": "xa_thien_nghiep",
                        "division_type": "xã",
                        "short_codename": "thien_nghiep"
                    },
                    {
                        "name": "Xã Phong Nẫm",
                        "code": 22960,
                        "codename": "xa_phong_nam",
                        "division_type": "xã",
                        "short_codename": "phong_nam"
                    },
                    {
                        "name": "Xã Tiến Lợi",
                        "code": 22963,
                        "codename": "xa_tien_loi",
                        "division_type": "xã",
                        "short_codename": "tien_loi"
                    },
                    {
                        "name": "Xã Tiến Thành",
                        "code": 22966,
                        "codename": "xa_tien_thanh",
                        "division_type": "xã",
                        "short_codename": "tien_thanh"
                    }
                ]
            },
            {
                "name": "Thị xã La Gi",
                "code": 594,
                "codename": "thi_xa_la_gi",
                "division_type": "thị xã",
                "short_codename": "la_gi",
                "wards": [
                    {
                        "name": "Phường Phước Hội",
                        "code": 23231,
                        "codename": "phuong_phuoc_hoi",
                        "division_type": "phường",
                        "short_codename": "phuoc_hoi"
                    },
                    {
                        "name": "Phường Phước Lộc",
                        "code": 23232,
                        "codename": "phuong_phuoc_loc",
                        "division_type": "phường",
                        "short_codename": "phuoc_loc"
                    },
                    {
                        "name": "Phường Tân Thiện",
                        "code": 23234,
                        "codename": "phuong_tan_thien",
                        "division_type": "phường",
                        "short_codename": "tan_thien"
                    },
                    {
                        "name": "Phường Tân An",
                        "code": 23235,
                        "codename": "phuong_tan_an",
                        "division_type": "phường",
                        "short_codename": "tan_an"
                    },
                    {
                        "name": "Phường Bình Tân",
                        "code": 23237,
                        "codename": "phuong_binh_tan",
                        "division_type": "phường",
                        "short_codename": "binh_tan"
                    },
                    {
                        "name": "Xã Tân Hải",
                        "code": 23245,
                        "codename": "xa_tan_hai",
                        "division_type": "xã",
                        "short_codename": "tan_hai"
                    },
                    {
                        "name": "Xã Tân Tiến",
                        "code": 23246,
                        "codename": "xa_tan_tien",
                        "division_type": "xã",
                        "short_codename": "tan_tien"
                    },
                    {
                        "name": "Xã Tân Bình",
                        "code": 23248,
                        "codename": "xa_tan_binh",
                        "division_type": "xã",
                        "short_codename": "tan_binh"
                    },
                    {
                        "name": "Xã Tân Phước",
                        "code": 23268,
                        "codename": "xa_tan_phuoc",
                        "division_type": "xã",
                        "short_codename": "tan_phuoc"
                    }
                ]
            },
            {
                "name": "Huyện Tuy Phong",
                "code": 595,
                "codename": "huyen_tuy_phong",
                "division_type": "huyện",
                "short_codename": "tuy_phong",
                "wards": [
                    {
                        "name": "Thị trấn Liên Hương",
                        "code": 22969,
                        "codename": "thi_tran_lien_huong",
                        "division_type": "thị trấn",
                        "short_codename": "lien_huong"
                    },
                    {
                        "name": "Thị trấn Phan Rí Cửa",
                        "code": 22972,
                        "codename": "thi_tran_phan_ri_cua",
                        "division_type": "thị trấn",
                        "short_codename": "phan_ri_cua"
                    },
                    {
                        "name": "Xã Phan Dũng",
                        "code": 22975,
                        "codename": "xa_phan_dung",
                        "division_type": "xã",
                        "short_codename": "phan_dung"
                    },
                    {
                        "name": "Xã Phong Phú",
                        "code": 22978,
                        "codename": "xa_phong_phu",
                        "division_type": "xã",
                        "short_codename": "phong_phu"
                    },
                    {
                        "name": "Xã Vĩnh Hảo",
                        "code": 22981,
                        "codename": "xa_vinh_hao",
                        "division_type": "xã",
                        "short_codename": "vinh_hao"
                    },
                    {
                        "name": "Xã Vĩnh Tân",
                        "code": 22984,
                        "codename": "xa_vinh_tan",
                        "division_type": "xã",
                        "short_codename": "vinh_tan"
                    },
                    {
                        "name": "Xã Phú Lạc",
                        "code": 22987,
                        "codename": "xa_phu_lac",
                        "division_type": "xã",
                        "short_codename": "phu_lac"
                    },
                    {
                        "name": "Xã Phước Thể",
                        "code": 22990,
                        "codename": "xa_phuoc_the",
                        "division_type": "xã",
                        "short_codename": "phuoc_the"
                    },
                    {
                        "name": "Xã Hòa Minh",
                        "code": 22993,
                        "codename": "xa_hoa_minh",
                        "division_type": "xã",
                        "short_codename": "hoa_minh"
                    },
                    {
                        "name": "Xã Chí Công",
                        "code": 22996,
                        "codename": "xa_chi_cong",
                        "division_type": "xã",
                        "short_codename": "chi_cong"
                    },
                    {
                        "name": "Xã Bình Thạnh",
                        "code": 22999,
                        "codename": "xa_binh_thanh",
                        "division_type": "xã",
                        "short_codename": "binh_thanh"
                    }
                ]
            },
            {
                "name": "Huyện Bắc Bình",
                "code": 596,
                "codename": "huyen_bac_binh",
                "division_type": "huyện",
                "short_codename": "bac_binh",
                "wards": [
                    {
                        "name": "Thị trấn Chợ Lầu",
                        "code": 23005,
                        "codename": "thi_tran_cho_lau",
                        "division_type": "thị trấn",
                        "short_codename": "cho_lau"
                    },
                    {
                        "name": "Xã Phan Sơn",
                        "code": 23008,
                        "codename": "xa_phan_son",
                        "division_type": "xã",
                        "short_codename": "phan_son"
                    },
                    {
                        "name": "Xã Phan Lâm",
                        "code": 23011,
                        "codename": "xa_phan_lam",
                        "division_type": "xã",
                        "short_codename": "phan_lam"
                    },
                    {
                        "name": "Xã Bình An",
                        "code": 23014,
                        "codename": "xa_binh_an",
                        "division_type": "xã",
                        "short_codename": "binh_an"
                    },
                    {
                        "name": "Xã Phan Điền",
                        "code": 23017,
                        "codename": "xa_phan_dien",
                        "division_type": "xã",
                        "short_codename": "phan_dien"
                    },
                    {
                        "name": "Xã Hải Ninh",
                        "code": 23020,
                        "codename": "xa_hai_ninh",
                        "division_type": "xã",
                        "short_codename": "hai_ninh"
                    },
                    {
                        "name": "Xã Sông Lũy",
                        "code": 23023,
                        "codename": "xa_song_luy",
                        "division_type": "xã",
                        "short_codename": "song_luy"
                    },
                    {
                        "name": "Xã Phan Tiến",
                        "code": 23026,
                        "codename": "xa_phan_tien",
                        "division_type": "xã",
                        "short_codename": "phan_tien"
                    },
                    {
                        "name": "Xã Sông Bình",
                        "code": 23029,
                        "codename": "xa_song_binh",
                        "division_type": "xã",
                        "short_codename": "song_binh"
                    },
                    {
                        "name": "Thị trấn Lương Sơn",
                        "code": 23032,
                        "codename": "thi_tran_luong_son",
                        "division_type": "thị trấn",
                        "short_codename": "luong_son"
                    },
                    {
                        "name": "Xã Phan Hòa",
                        "code": 23035,
                        "codename": "xa_phan_hoa",
                        "division_type": "xã",
                        "short_codename": "phan_hoa"
                    },
                    {
                        "name": "Xã Phan Thanh",
                        "code": 23038,
                        "codename": "xa_phan_thanh",
                        "division_type": "xã",
                        "short_codename": "phan_thanh"
                    },
                    {
                        "name": "Xã Hồng Thái",
                        "code": 23041,
                        "codename": "xa_hong_thai",
                        "division_type": "xã",
                        "short_codename": "hong_thai"
                    },
                    {
                        "name": "Xã Phan Hiệp",
                        "code": 23044,
                        "codename": "xa_phan_hiep",
                        "division_type": "xã",
                        "short_codename": "phan_hiep"
                    },
                    {
                        "name": "Xã Bình Tân",
                        "code": 23047,
                        "codename": "xa_binh_tan",
                        "division_type": "xã",
                        "short_codename": "binh_tan"
                    },
                    {
                        "name": "Xã Phan Rí Thành",
                        "code": 23050,
                        "codename": "xa_phan_ri_thanh",
                        "division_type": "xã",
                        "short_codename": "phan_ri_thanh"
                    },
                    {
                        "name": "Xã Hòa Thắng",
                        "code": 23053,
                        "codename": "xa_hoa_thang",
                        "division_type": "xã",
                        "short_codename": "hoa_thang"
                    },
                    {
                        "name": "Xã Hồng Phong",
                        "code": 23056,
                        "codename": "xa_hong_phong",
                        "division_type": "xã",
                        "short_codename": "hong_phong"
                    }
                ]
            },
            {
                "name": "Huyện Hàm Thuận Bắc",
                "code": 597,
                "codename": "huyen_ham_thuan_bac",
                "division_type": "huyện",
                "short_codename": "ham_thuan_bac",
                "wards": [
                    {
                        "name": "Thị trấn Ma Lâm",
                        "code": 23059,
                        "codename": "thi_tran_ma_lam",
                        "division_type": "thị trấn",
                        "short_codename": "ma_lam"
                    },
                    {
                        "name": "Thị trấn Phú Long",
                        "code": 23062,
                        "codename": "thi_tran_phu_long",
                        "division_type": "thị trấn",
                        "short_codename": "phu_long"
                    },
                    {
                        "name": "Xã La Dạ",
                        "code": 23065,
                        "codename": "xa_la_da",
                        "division_type": "xã",
                        "short_codename": "la_da"
                    },
                    {
                        "name": "Xã Đông Tiến",
                        "code": 23068,
                        "codename": "xa_dong_tien",
                        "division_type": "xã",
                        "short_codename": "dong_tien"
                    },
                    {
                        "name": "Xã Thuận Hòa",
                        "code": 23071,
                        "codename": "xa_thuan_hoa",
                        "division_type": "xã",
                        "short_codename": "thuan_hoa"
                    },
                    {
                        "name": "Xã Đông Giang",
                        "code": 23074,
                        "codename": "xa_dong_giang",
                        "division_type": "xã",
                        "short_codename": "dong_giang"
                    },
                    {
                        "name": "Xã Hàm Phú",
                        "code": 23077,
                        "codename": "xa_ham_phu",
                        "division_type": "xã",
                        "short_codename": "ham_phu"
                    },
                    {
                        "name": "Xã Hồng Liêm",
                        "code": 23080,
                        "codename": "xa_hong_liem",
                        "division_type": "xã",
                        "short_codename": "hong_liem"
                    },
                    {
                        "name": "Xã Thuận Minh",
                        "code": 23083,
                        "codename": "xa_thuan_minh",
                        "division_type": "xã",
                        "short_codename": "thuan_minh"
                    },
                    {
                        "name": "Xã Hồng Sơn",
                        "code": 23086,
                        "codename": "xa_hong_son",
                        "division_type": "xã",
                        "short_codename": "hong_son"
                    },
                    {
                        "name": "Xã Hàm Trí",
                        "code": 23089,
                        "codename": "xa_ham_tri",
                        "division_type": "xã",
                        "short_codename": "ham_tri"
                    },
                    {
                        "name": "Xã Hàm Đức",
                        "code": 23092,
                        "codename": "xa_ham_duc",
                        "division_type": "xã",
                        "short_codename": "ham_duc"
                    },
                    {
                        "name": "Xã Hàm Liêm",
                        "code": 23095,
                        "codename": "xa_ham_liem",
                        "division_type": "xã",
                        "short_codename": "ham_liem"
                    },
                    {
                        "name": "Xã Hàm Chính",
                        "code": 23098,
                        "codename": "xa_ham_chinh",
                        "division_type": "xã",
                        "short_codename": "ham_chinh"
                    },
                    {
                        "name": "Xã Hàm Hiệp",
                        "code": 23101,
                        "codename": "xa_ham_hiep",
                        "division_type": "xã",
                        "short_codename": "ham_hiep"
                    },
                    {
                        "name": "Xã Hàm Thắng",
                        "code": 23104,
                        "codename": "xa_ham_thang",
                        "division_type": "xã",
                        "short_codename": "ham_thang"
                    },
                    {
                        "name": "Xã Đa Mi",
                        "code": 23107,
                        "codename": "xa_da_mi",
                        "division_type": "xã",
                        "short_codename": "da_mi"
                    }
                ]
            },
            {
                "name": "Huyện Hàm Thuận Nam",
                "code": 598,
                "codename": "huyen_ham_thuan_nam",
                "division_type": "huyện",
                "short_codename": "ham_thuan_nam",
                "wards": [
                    {
                        "name": "Thị trấn Thuận Nam",
                        "code": 23110,
                        "codename": "thi_tran_thuan_nam",
                        "division_type": "thị trấn",
                        "short_codename": "thuan_nam"
                    },
                    {
                        "name": "Xã Mỹ Thạnh",
                        "code": 23113,
                        "codename": "xa_my_thanh",
                        "division_type": "xã",
                        "short_codename": "my_thanh"
                    },
                    {
                        "name": "Xã Hàm Cần",
                        "code": 23116,
                        "codename": "xa_ham_can",
                        "division_type": "xã",
                        "short_codename": "ham_can"
                    },
                    {
                        "name": "Xã Mương Mán",
                        "code": 23119,
                        "codename": "xa_muong_man",
                        "division_type": "xã",
                        "short_codename": "muong_man"
                    },
                    {
                        "name": "Xã Hàm Thạnh",
                        "code": 23122,
                        "codename": "xa_ham_thanh",
                        "division_type": "xã",
                        "short_codename": "ham_thanh"
                    },
                    {
                        "name": "Xã Hàm Kiệm",
                        "code": 23125,
                        "codename": "xa_ham_kiem",
                        "division_type": "xã",
                        "short_codename": "ham_kiem"
                    },
                    {
                        "name": "Xã Hàm Cường",
                        "code": 23128,
                        "codename": "xa_ham_cuong",
                        "division_type": "xã",
                        "short_codename": "ham_cuong"
                    },
                    {
                        "name": "Xã Hàm Mỹ",
                        "code": 23131,
                        "codename": "xa_ham_my",
                        "division_type": "xã",
                        "short_codename": "ham_my"
                    },
                    {
                        "name": "Xã Tân Lập",
                        "code": 23134,
                        "codename": "xa_tan_lap",
                        "division_type": "xã",
                        "short_codename": "tan_lap"
                    },
                    {
                        "name": "Xã Hàm Minh",
                        "code": 23137,
                        "codename": "xa_ham_minh",
                        "division_type": "xã",
                        "short_codename": "ham_minh"
                    },
                    {
                        "name": "Xã Thuận Quí",
                        "code": 23140,
                        "codename": "xa_thuan_qui",
                        "division_type": "xã",
                        "short_codename": "thuan_qui"
                    },
                    {
                        "name": "Xã Tân Thuận",
                        "code": 23143,
                        "codename": "xa_tan_thuan",
                        "division_type": "xã",
                        "short_codename": "tan_thuan"
                    },
                    {
                        "name": "Xã Tân Thành",
                        "code": 23146,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    }
                ]
            },
            {
                "name": "Huyện Tánh Linh",
                "code": 599,
                "codename": "huyen_tanh_linh",
                "division_type": "huyện",
                "short_codename": "tanh_linh",
                "wards": [
                    {
                        "name": "Thị trấn Lạc Tánh",
                        "code": 23149,
                        "codename": "thi_tran_lac_tanh",
                        "division_type": "thị trấn",
                        "short_codename": "lac_tanh"
                    },
                    {
                        "name": "Xã Bắc Ruộng",
                        "code": 23152,
                        "codename": "xa_bac_ruong",
                        "division_type": "xã",
                        "short_codename": "bac_ruong"
                    },
                    {
                        "name": "Xã Nghị Đức",
                        "code": 23158,
                        "codename": "xa_nghi_duc",
                        "division_type": "xã",
                        "short_codename": "nghi_duc"
                    },
                    {
                        "name": "Xã La Ngâu",
                        "code": 23161,
                        "codename": "xa_la_ngau",
                        "division_type": "xã",
                        "short_codename": "la_ngau"
                    },
                    {
                        "name": "Xã Huy Khiêm",
                        "code": 23164,
                        "codename": "xa_huy_khiem",
                        "division_type": "xã",
                        "short_codename": "huy_khiem"
                    },
                    {
                        "name": "Xã Măng Tố",
                        "code": 23167,
                        "codename": "xa_mang_to",
                        "division_type": "xã",
                        "short_codename": "mang_to"
                    },
                    {
                        "name": "Xã Đức Phú",
                        "code": 23170,
                        "codename": "xa_duc_phu",
                        "division_type": "xã",
                        "short_codename": "duc_phu"
                    },
                    {
                        "name": "Xã Đồng Kho",
                        "code": 23173,
                        "codename": "xa_dong_kho",
                        "division_type": "xã",
                        "short_codename": "dong_kho"
                    },
                    {
                        "name": "Xã Gia An",
                        "code": 23176,
                        "codename": "xa_gia_an",
                        "division_type": "xã",
                        "short_codename": "gia_an"
                    },
                    {
                        "name": "Xã Đức Bình",
                        "code": 23179,
                        "codename": "xa_duc_binh",
                        "division_type": "xã",
                        "short_codename": "duc_binh"
                    },
                    {
                        "name": "Xã Gia Huynh",
                        "code": 23182,
                        "codename": "xa_gia_huynh",
                        "division_type": "xã",
                        "short_codename": "gia_huynh"
                    },
                    {
                        "name": "Xã Đức Thuận",
                        "code": 23185,
                        "codename": "xa_duc_thuan",
                        "division_type": "xã",
                        "short_codename": "duc_thuan"
                    },
                    {
                        "name": "Xã Suối Kiết",
                        "code": 23188,
                        "codename": "xa_suoi_kiet",
                        "division_type": "xã",
                        "short_codename": "suoi_kiet"
                    }
                ]
            },
            {
                "name": "Huyện Đức Linh",
                "code": 600,
                "codename": "huyen_duc_linh",
                "division_type": "huyện",
                "short_codename": "duc_linh",
                "wards": [
                    {
                        "name": "Thị trấn Võ Xu",
                        "code": 23191,
                        "codename": "thi_tran_vo_xu",
                        "division_type": "thị trấn",
                        "short_codename": "vo_xu"
                    },
                    {
                        "name": "Thị trấn Đức Tài",
                        "code": 23194,
                        "codename": "thi_tran_duc_tai",
                        "division_type": "thị trấn",
                        "short_codename": "duc_tai"
                    },
                    {
                        "name": "Xã Đa Kai",
                        "code": 23197,
                        "codename": "xa_da_kai",
                        "division_type": "xã",
                        "short_codename": "da_kai"
                    },
                    {
                        "name": "Xã Sùng Nhơn",
                        "code": 23200,
                        "codename": "xa_sung_nhon",
                        "division_type": "xã",
                        "short_codename": "sung_nhon"
                    },
                    {
                        "name": "Xã Mê Pu",
                        "code": 23203,
                        "codename": "xa_me_pu",
                        "division_type": "xã",
                        "short_codename": "me_pu"
                    },
                    {
                        "name": "Xã Nam Chính",
                        "code": 23206,
                        "codename": "xa_nam_chinh",
                        "division_type": "xã",
                        "short_codename": "nam_chinh"
                    },
                    {
                        "name": "Xã Đức Hạnh",
                        "code": 23212,
                        "codename": "xa_duc_hanh",
                        "division_type": "xã",
                        "short_codename": "duc_hanh"
                    },
                    {
                        "name": "Xã Đức Tín",
                        "code": 23215,
                        "codename": "xa_duc_tin",
                        "division_type": "xã",
                        "short_codename": "duc_tin"
                    },
                    {
                        "name": "Xã Vũ Hoà",
                        "code": 23218,
                        "codename": "xa_vu_hoa",
                        "division_type": "xã",
                        "short_codename": "vu_hoa"
                    },
                    {
                        "name": "Xã Tân Hà",
                        "code": 23221,
                        "codename": "xa_tan_ha",
                        "division_type": "xã",
                        "short_codename": "tan_ha"
                    },
                    {
                        "name": "Xã Đông Hà",
                        "code": 23224,
                        "codename": "xa_dong_ha",
                        "division_type": "xã",
                        "short_codename": "dong_ha"
                    },
                    {
                        "name": "Xã Trà Tân",
                        "code": 23227,
                        "codename": "xa_tra_tan",
                        "division_type": "xã",
                        "short_codename": "tra_tan"
                    }
                ]
            },
            {
                "name": "Huyện Hàm Tân",
                "code": 601,
                "codename": "huyen_ham_tan",
                "division_type": "huyện",
                "short_codename": "ham_tan",
                "wards": [
                    {
                        "name": "Thị trấn Tân Minh",
                        "code": 23230,
                        "codename": "thi_tran_tan_minh",
                        "division_type": "thị trấn",
                        "short_codename": "tan_minh"
                    },
                    {
                        "name": "Thị trấn Tân Nghĩa",
                        "code": 23236,
                        "codename": "thi_tran_tan_nghia",
                        "division_type": "thị trấn",
                        "short_codename": "tan_nghia"
                    },
                    {
                        "name": "Xã Sông Phan",
                        "code": 23239,
                        "codename": "xa_song_phan",
                        "division_type": "xã",
                        "short_codename": "song_phan"
                    },
                    {
                        "name": "Xã Tân Phúc",
                        "code": 23242,
                        "codename": "xa_tan_phuc",
                        "division_type": "xã",
                        "short_codename": "tan_phuc"
                    },
                    {
                        "name": "Xã Tân Đức",
                        "code": 23251,
                        "codename": "xa_tan_duc",
                        "division_type": "xã",
                        "short_codename": "tan_duc"
                    },
                    {
                        "name": "Xã Tân Thắng",
                        "code": 23254,
                        "codename": "xa_tan_thang",
                        "division_type": "xã",
                        "short_codename": "tan_thang"
                    },
                    {
                        "name": "Xã Thắng Hải",
                        "code": 23255,
                        "codename": "xa_thang_hai",
                        "division_type": "xã",
                        "short_codename": "thang_hai"
                    },
                    {
                        "name": "Xã Tân Hà",
                        "code": 23257,
                        "codename": "xa_tan_ha",
                        "division_type": "xã",
                        "short_codename": "tan_ha"
                    },
                    {
                        "name": "Xã Tân Xuân",
                        "code": 23260,
                        "codename": "xa_tan_xuan",
                        "division_type": "xã",
                        "short_codename": "tan_xuan"
                    },
                    {
                        "name": "Xã Sơn Mỹ",
                        "code": 23266,
                        "codename": "xa_son_my",
                        "division_type": "xã",
                        "short_codename": "son_my"
                    }
                ]
            },
            {
                "name": "Huyện Phú Quí",
                "code": 602,
                "codename": "huyen_phu_qui",
                "division_type": "huyện",
                "short_codename": "phu_qui",
                "wards": [
                    {
                        "name": "Xã Ngũ Phụng",
                        "code": 23272,
                        "codename": "xa_ngu_phung",
                        "division_type": "xã",
                        "short_codename": "ngu_phung"
                    },
                    {
                        "name": "Xã Long Hải",
                        "code": 23275,
                        "codename": "xa_long_hai",
                        "division_type": "xã",
                        "short_codename": "long_hai"
                    },
                    {
                        "name": "Xã Tam Thanh",
                        "code": 23278,
                        "codename": "xa_tam_thanh",
                        "division_type": "xã",
                        "short_codename": "tam_thanh"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Kon Tum",
        "code": 62,
        "codename": "tinh_kon_tum",
        "division_type": "tỉnh",
        "phone_code": 260,
        "districts": [
            {
                "name": "Thành phố Kon Tum",
                "code": 608,
                "codename": "thanh_pho_kon_tum",
                "division_type": "thành phố",
                "short_codename": "kon_tum",
                "wards": [
                    {
                        "name": "Phường Quang Trung",
                        "code": 23281,
                        "codename": "phuong_quang_trung",
                        "division_type": "phường",
                        "short_codename": "quang_trung"
                    },
                    {
                        "name": "Phường Duy Tân",
                        "code": 23284,
                        "codename": "phuong_duy_tan",
                        "division_type": "phường",
                        "short_codename": "duy_tan"
                    },
                    {
                        "name": "Phường Quyết Thắng",
                        "code": 23287,
                        "codename": "phuong_quyet_thang",
                        "division_type": "phường",
                        "short_codename": "quyet_thang"
                    },
                    {
                        "name": "Phường Trường Chinh",
                        "code": 23290,
                        "codename": "phuong_truong_chinh",
                        "division_type": "phường",
                        "short_codename": "truong_chinh"
                    },
                    {
                        "name": "Phường Thắng Lợi",
                        "code": 23293,
                        "codename": "phuong_thang_loi",
                        "division_type": "phường",
                        "short_codename": "thang_loi"
                    },
                    {
                        "name": "Phường Ngô Mây",
                        "code": 23296,
                        "codename": "phuong_ngo_may",
                        "division_type": "phường",
                        "short_codename": "ngo_may"
                    },
                    {
                        "name": "Phường Thống Nhất",
                        "code": 23299,
                        "codename": "phuong_thong_nhat",
                        "division_type": "phường",
                        "short_codename": "thong_nhat"
                    },
                    {
                        "name": "Phường Lê Lợi",
                        "code": 23302,
                        "codename": "phuong_le_loi",
                        "division_type": "phường",
                        "short_codename": "le_loi"
                    },
                    {
                        "name": "Phường Nguyễn Trãi",
                        "code": 23305,
                        "codename": "phuong_nguyen_trai",
                        "division_type": "phường",
                        "short_codename": "nguyen_trai"
                    },
                    {
                        "name": "Phường Trần Hưng Đạo",
                        "code": 23308,
                        "codename": "phuong_tran_hung_dao",
                        "division_type": "phường",
                        "short_codename": "tran_hung_dao"
                    },
                    {
                        "name": "Xã Đắk Cấm",
                        "code": 23311,
                        "codename": "xa_dak_cam",
                        "division_type": "xã",
                        "short_codename": "dak_cam"
                    },
                    {
                        "name": "Xã Kroong",
                        "code": 23314,
                        "codename": "xa_kroong",
                        "division_type": "xã",
                        "short_codename": "kroong"
                    },
                    {
                        "name": "Xã Ngọk Bay",
                        "code": 23317,
                        "codename": "xa_ngok_bay",
                        "division_type": "xã",
                        "short_codename": "ngok_bay"
                    },
                    {
                        "name": "Xã Vinh Quang",
                        "code": 23320,
                        "codename": "xa_vinh_quang",
                        "division_type": "xã",
                        "short_codename": "vinh_quang"
                    },
                    {
                        "name": "Xã Đắk Blà",
                        "code": 23323,
                        "codename": "xa_dak_bla",
                        "division_type": "xã",
                        "short_codename": "dak_bla"
                    },
                    {
                        "name": "Xã Ia Chim",
                        "code": 23326,
                        "codename": "xa_ia_chim",
                        "division_type": "xã",
                        "short_codename": "ia_chim"
                    },
                    {
                        "name": "Xã Đăk Năng",
                        "code": 23327,
                        "codename": "xa_dak_nang",
                        "division_type": "xã",
                        "short_codename": "dak_nang"
                    },
                    {
                        "name": "Xã Đoàn Kết",
                        "code": 23329,
                        "codename": "xa_doan_ket",
                        "division_type": "xã",
                        "short_codename": "doan_ket"
                    },
                    {
                        "name": "Xã Chư Hreng",
                        "code": 23332,
                        "codename": "xa_chu_hreng",
                        "division_type": "xã",
                        "short_codename": "chu_hreng"
                    },
                    {
                        "name": "Xã Đắk Rơ Wa",
                        "code": 23335,
                        "codename": "xa_dak_ro_wa",
                        "division_type": "xã",
                        "short_codename": "dak_ro_wa"
                    },
                    {
                        "name": "Xã Hòa Bình",
                        "code": 23338,
                        "codename": "xa_hoa_binh",
                        "division_type": "xã",
                        "short_codename": "hoa_binh"
                    }
                ]
            },
            {
                "name": "Huyện Đắk Glei",
                "code": 610,
                "codename": "huyen_dak_glei",
                "division_type": "huyện",
                "short_codename": "dak_glei",
                "wards": [
                    {
                        "name": "Thị trấn Đắk Glei",
                        "code": 23341,
                        "codename": "thi_tran_dak_glei",
                        "division_type": "thị trấn",
                        "short_codename": "dak_glei"
                    },
                    {
                        "name": "Xã Đắk Blô",
                        "code": 23344,
                        "codename": "xa_dak_blo",
                        "division_type": "xã",
                        "short_codename": "dak_blo"
                    },
                    {
                        "name": "Xã Đắk Man",
                        "code": 23347,
                        "codename": "xa_dak_man",
                        "division_type": "xã",
                        "short_codename": "dak_man"
                    },
                    {
                        "name": "Xã Đắk Nhoong",
                        "code": 23350,
                        "codename": "xa_dak_nhoong",
                        "division_type": "xã",
                        "short_codename": "dak_nhoong"
                    },
                    {
                        "name": "Xã Đắk Pék",
                        "code": 23353,
                        "codename": "xa_dak_pek",
                        "division_type": "xã",
                        "short_codename": "dak_pek"
                    },
                    {
                        "name": "Xã Đắk Choong",
                        "code": 23356,
                        "codename": "xa_dak_choong",
                        "division_type": "xã",
                        "short_codename": "dak_choong"
                    },
                    {
                        "name": "Xã Xốp",
                        "code": 23359,
                        "codename": "xa_xop",
                        "division_type": "xã",
                        "short_codename": "xop"
                    },
                    {
                        "name": "Xã Mường Hoong",
                        "code": 23362,
                        "codename": "xa_muong_hoong",
                        "division_type": "xã",
                        "short_codename": "muong_hoong"
                    },
                    {
                        "name": "Xã Ngọc Linh",
                        "code": 23365,
                        "codename": "xa_ngoc_linh",
                        "division_type": "xã",
                        "short_codename": "ngoc_linh"
                    },
                    {
                        "name": "Xã Đắk Long",
                        "code": 23368,
                        "codename": "xa_dak_long",
                        "division_type": "xã",
                        "short_codename": "dak_long"
                    },
                    {
                        "name": "Xã Đắk KRoong",
                        "code": 23371,
                        "codename": "xa_dak_kroong",
                        "division_type": "xã",
                        "short_codename": "dak_kroong"
                    },
                    {
                        "name": "Xã Đắk Môn",
                        "code": 23374,
                        "codename": "xa_dak_mon",
                        "division_type": "xã",
                        "short_codename": "dak_mon"
                    }
                ]
            },
            {
                "name": "Huyện Ngọc Hồi",
                "code": 611,
                "codename": "huyen_ngoc_hoi",
                "division_type": "huyện",
                "short_codename": "ngoc_hoi",
                "wards": [
                    {
                        "name": "Thị trấn Plei Kần",
                        "code": 23377,
                        "codename": "thi_tran_plei_kan",
                        "division_type": "thị trấn",
                        "short_codename": "plei_kan"
                    },
                    {
                        "name": "Xã Đắk Ang",
                        "code": 23380,
                        "codename": "xa_dak_ang",
                        "division_type": "xã",
                        "short_codename": "dak_ang"
                    },
                    {
                        "name": "Xã Đắk Dục",
                        "code": 23383,
                        "codename": "xa_dak_duc",
                        "division_type": "xã",
                        "short_codename": "dak_duc"
                    },
                    {
                        "name": "Xã Đắk Nông",
                        "code": 23386,
                        "codename": "xa_dak_nong",
                        "division_type": "xã",
                        "short_codename": "dak_nong"
                    },
                    {
                        "name": "Xã Đắk Xú",
                        "code": 23389,
                        "codename": "xa_dak_xu",
                        "division_type": "xã",
                        "short_codename": "dak_xu"
                    },
                    {
                        "name": "Xã Đắk Kan",
                        "code": 23392,
                        "codename": "xa_dak_kan",
                        "division_type": "xã",
                        "short_codename": "dak_kan"
                    },
                    {
                        "name": "Xã Bờ Y",
                        "code": 23395,
                        "codename": "xa_bo_y",
                        "division_type": "xã",
                        "short_codename": "bo_y"
                    },
                    {
                        "name": "Xã Sa Loong",
                        "code": 23398,
                        "codename": "xa_sa_loong",
                        "division_type": "xã",
                        "short_codename": "sa_loong"
                    }
                ]
            },
            {
                "name": "Huyện Đắk Tô",
                "code": 612,
                "codename": "huyen_dak_to",
                "division_type": "huyện",
                "short_codename": "dak_to",
                "wards": [
                    {
                        "name": "Thị trấn Đắk Tô",
                        "code": 23401,
                        "codename": "thi_tran_dak_to",
                        "division_type": "thị trấn",
                        "short_codename": "dak_to"
                    },
                    {
                        "name": "Xã Đắk Rơ Nga",
                        "code": 23427,
                        "codename": "xa_dak_ro_nga",
                        "division_type": "xã",
                        "short_codename": "dak_ro_nga"
                    },
                    {
                        "name": "Xã Ngọk Tụ",
                        "code": 23428,
                        "codename": "xa_ngok_tu",
                        "division_type": "xã",
                        "short_codename": "ngok_tu"
                    },
                    {
                        "name": "Xã Đắk Trăm",
                        "code": 23430,
                        "codename": "xa_dak_tram",
                        "division_type": "xã",
                        "short_codename": "dak_tram"
                    },
                    {
                        "name": "Xã Văn Lem",
                        "code": 23431,
                        "codename": "xa_van_lem",
                        "division_type": "xã",
                        "short_codename": "van_lem"
                    },
                    {
                        "name": "Xã Kon Đào",
                        "code": 23434,
                        "codename": "xa_kon_dao",
                        "division_type": "xã",
                        "short_codename": "kon_dao"
                    },
                    {
                        "name": "Xã Tân Cảnh",
                        "code": 23437,
                        "codename": "xa_tan_canh",
                        "division_type": "xã",
                        "short_codename": "tan_canh"
                    },
                    {
                        "name": "Xã Diên Bình",
                        "code": 23440,
                        "codename": "xa_dien_binh",
                        "division_type": "xã",
                        "short_codename": "dien_binh"
                    },
                    {
                        "name": "Xã Pô Kô",
                        "code": 23443,
                        "codename": "xa_po_ko",
                        "division_type": "xã",
                        "short_codename": "po_ko"
                    }
                ]
            },
            {
                "name": "Huyện Kon Plông",
                "code": 613,
                "codename": "huyen_kon_plong",
                "division_type": "huyện",
                "short_codename": "kon_plong",
                "wards": [
                    {
                        "name": "Xã Đắk Nên",
                        "code": 23452,
                        "codename": "xa_dak_nen",
                        "division_type": "xã",
                        "short_codename": "dak_nen"
                    },
                    {
                        "name": "Xã Đắk Ring",
                        "code": 23455,
                        "codename": "xa_dak_ring",
                        "division_type": "xã",
                        "short_codename": "dak_ring"
                    },
                    {
                        "name": "Xã Măng Buk",
                        "code": 23458,
                        "codename": "xa_mang_buk",
                        "division_type": "xã",
                        "short_codename": "mang_buk"
                    },
                    {
                        "name": "Xã Đắk Tăng",
                        "code": 23461,
                        "codename": "xa_dak_tang",
                        "division_type": "xã",
                        "short_codename": "dak_tang"
                    },
                    {
                        "name": "Xã Ngok Tem",
                        "code": 23464,
                        "codename": "xa_ngok_tem",
                        "division_type": "xã",
                        "short_codename": "ngok_tem"
                    },
                    {
                        "name": "Xã Pờ Ê",
                        "code": 23467,
                        "codename": "xa_po_e",
                        "division_type": "xã",
                        "short_codename": "po_e"
                    },
                    {
                        "name": "Xã Măng Cành",
                        "code": 23470,
                        "codename": "xa_mang_canh",
                        "division_type": "xã",
                        "short_codename": "mang_canh"
                    },
                    {
                        "name": "Thị trấn Măng Đen",
                        "code": 23473,
                        "codename": "thi_tran_mang_den",
                        "division_type": "thị trấn",
                        "short_codename": "mang_den"
                    },
                    {
                        "name": "Xã Hiếu",
                        "code": 23476,
                        "codename": "xa_hieu",
                        "division_type": "xã",
                        "short_codename": "hieu"
                    }
                ]
            },
            {
                "name": "Huyện Kon Rẫy",
                "code": 614,
                "codename": "huyen_kon_ray",
                "division_type": "huyện",
                "short_codename": "kon_ray",
                "wards": [
                    {
                        "name": "Thị trấn Đắk Rve",
                        "code": 23479,
                        "codename": "thi_tran_dak_rve",
                        "division_type": "thị trấn",
                        "short_codename": "dak_rve"
                    },
                    {
                        "name": "Xã Đắk Kôi",
                        "code": 23482,
                        "codename": "xa_dak_koi",
                        "division_type": "xã",
                        "short_codename": "dak_koi"
                    },
                    {
                        "name": "Xã Đắk Tơ Lung",
                        "code": 23485,
                        "codename": "xa_dak_to_lung",
                        "division_type": "xã",
                        "short_codename": "dak_to_lung"
                    },
                    {
                        "name": "Xã Đắk Ruồng",
                        "code": 23488,
                        "codename": "xa_dak_ruong",
                        "division_type": "xã",
                        "short_codename": "dak_ruong"
                    },
                    {
                        "name": "Xã Đắk Pne",
                        "code": 23491,
                        "codename": "xa_dak_pne",
                        "division_type": "xã",
                        "short_codename": "dak_pne"
                    },
                    {
                        "name": "Xã Đắk Tờ Re",
                        "code": 23494,
                        "codename": "xa_dak_to_re",
                        "division_type": "xã",
                        "short_codename": "dak_to_re"
                    },
                    {
                        "name": "Xã Tân Lập",
                        "code": 23497,
                        "codename": "xa_tan_lap",
                        "division_type": "xã",
                        "short_codename": "tan_lap"
                    }
                ]
            },
            {
                "name": "Huyện Đắk Hà",
                "code": 615,
                "codename": "huyen_dak_ha",
                "division_type": "huyện",
                "short_codename": "dak_ha",
                "wards": [
                    {
                        "name": "Thị trấn Đắk Hà",
                        "code": 23500,
                        "codename": "thi_tran_dak_ha",
                        "division_type": "thị trấn",
                        "short_codename": "dak_ha"
                    },
                    {
                        "name": "Xã Đắk PXi",
                        "code": 23503,
                        "codename": "xa_dak_pxi",
                        "division_type": "xã",
                        "short_codename": "dak_pxi"
                    },
                    {
                        "name": "Xã Đăk Long",
                        "code": 23504,
                        "codename": "xa_dak_long",
                        "division_type": "xã",
                        "short_codename": "dak_long"
                    },
                    {
                        "name": "Xã Đắk HRing",
                        "code": 23506,
                        "codename": "xa_dak_hring",
                        "division_type": "xã",
                        "short_codename": "dak_hring"
                    },
                    {
                        "name": "Xã Đắk Ui",
                        "code": 23509,
                        "codename": "xa_dak_ui",
                        "division_type": "xã",
                        "short_codename": "dak_ui"
                    },
                    {
                        "name": "Xã Đăk Ngọk",
                        "code": 23510,
                        "codename": "xa_dak_ngok",
                        "division_type": "xã",
                        "short_codename": "dak_ngok"
                    },
                    {
                        "name": "Xã Đắk Mar",
                        "code": 23512,
                        "codename": "xa_dak_mar",
                        "division_type": "xã",
                        "short_codename": "dak_mar"
                    },
                    {
                        "name": "Xã Ngok Wang",
                        "code": 23515,
                        "codename": "xa_ngok_wang",
                        "division_type": "xã",
                        "short_codename": "ngok_wang"
                    },
                    {
                        "name": "Xã Ngok Réo",
                        "code": 23518,
                        "codename": "xa_ngok_reo",
                        "division_type": "xã",
                        "short_codename": "ngok_reo"
                    },
                    {
                        "name": "Xã Hà Mòn",
                        "code": 23521,
                        "codename": "xa_ha_mon",
                        "division_type": "xã",
                        "short_codename": "ha_mon"
                    },
                    {
                        "name": "Xã Đắk La",
                        "code": 23524,
                        "codename": "xa_dak_la",
                        "division_type": "xã",
                        "short_codename": "dak_la"
                    }
                ]
            },
            {
                "name": "Huyện Sa Thầy",
                "code": 616,
                "codename": "huyen_sa_thay",
                "division_type": "huyện",
                "short_codename": "sa_thay",
                "wards": [
                    {
                        "name": "Thị trấn Sa Thầy",
                        "code": 23527,
                        "codename": "thi_tran_sa_thay",
                        "division_type": "thị trấn",
                        "short_codename": "sa_thay"
                    },
                    {
                        "name": "Xã Rơ Kơi",
                        "code": 23530,
                        "codename": "xa_ro_koi",
                        "division_type": "xã",
                        "short_codename": "ro_koi"
                    },
                    {
                        "name": "Xã Sa Nhơn",
                        "code": 23533,
                        "codename": "xa_sa_nhon",
                        "division_type": "xã",
                        "short_codename": "sa_nhon"
                    },
                    {
                        "name": "Xã Hơ Moong",
                        "code": 23534,
                        "codename": "xa_ho_moong",
                        "division_type": "xã",
                        "short_codename": "ho_moong"
                    },
                    {
                        "name": "Xã Mô Rai",
                        "code": 23536,
                        "codename": "xa_mo_rai",
                        "division_type": "xã",
                        "short_codename": "mo_rai"
                    },
                    {
                        "name": "Xã Sa Sơn",
                        "code": 23539,
                        "codename": "xa_sa_son",
                        "division_type": "xã",
                        "short_codename": "sa_son"
                    },
                    {
                        "name": "Xã Sa Nghĩa",
                        "code": 23542,
                        "codename": "xa_sa_nghia",
                        "division_type": "xã",
                        "short_codename": "sa_nghia"
                    },
                    {
                        "name": "Xã Sa Bình",
                        "code": 23545,
                        "codename": "xa_sa_binh",
                        "division_type": "xã",
                        "short_codename": "sa_binh"
                    },
                    {
                        "name": "Xã Ya Xiêr",
                        "code": 23548,
                        "codename": "xa_ya_xier",
                        "division_type": "xã",
                        "short_codename": "ya_xier"
                    },
                    {
                        "name": "Xã Ya Tăng",
                        "code": 23551,
                        "codename": "xa_ya_tang",
                        "division_type": "xã",
                        "short_codename": "ya_tang"
                    },
                    {
                        "name": "Xã Ya ly",
                        "code": 23554,
                        "codename": "xa_ya_ly",
                        "division_type": "xã",
                        "short_codename": "ya_ly"
                    }
                ]
            },
            {
                "name": "Huyện Tu Mơ Rông",
                "code": 617,
                "codename": "huyen_tu_mo_rong",
                "division_type": "huyện",
                "short_codename": "tu_mo_rong",
                "wards": [
                    {
                        "name": "Xã Ngọc Lây",
                        "code": 23404,
                        "codename": "xa_ngoc_lay",
                        "division_type": "xã",
                        "short_codename": "ngoc_lay"
                    },
                    {
                        "name": "Xã Đắk Na",
                        "code": 23407,
                        "codename": "xa_dak_na",
                        "division_type": "xã",
                        "short_codename": "dak_na"
                    },
                    {
                        "name": "Xã Măng Ri",
                        "code": 23410,
                        "codename": "xa_mang_ri",
                        "division_type": "xã",
                        "short_codename": "mang_ri"
                    },
                    {
                        "name": "Xã Ngọc Yêu",
                        "code": 23413,
                        "codename": "xa_ngoc_yeu",
                        "division_type": "xã",
                        "short_codename": "ngoc_yeu"
                    },
                    {
                        "name": "Xã Đắk Sao",
                        "code": 23416,
                        "codename": "xa_dak_sao",
                        "division_type": "xã",
                        "short_codename": "dak_sao"
                    },
                    {
                        "name": "Xã Đắk Rơ Ông",
                        "code": 23417,
                        "codename": "xa_dak_ro_ong",
                        "division_type": "xã",
                        "short_codename": "dak_ro_ong"
                    },
                    {
                        "name": "Xã Đắk Tờ Kan",
                        "code": 23419,
                        "codename": "xa_dak_to_kan",
                        "division_type": "xã",
                        "short_codename": "dak_to_kan"
                    },
                    {
                        "name": "Xã Tu Mơ Rông",
                        "code": 23422,
                        "codename": "xa_tu_mo_rong",
                        "division_type": "xã",
                        "short_codename": "tu_mo_rong"
                    },
                    {
                        "name": "Xã Đắk Hà",
                        "code": 23425,
                        "codename": "xa_dak_ha",
                        "division_type": "xã",
                        "short_codename": "dak_ha"
                    },
                    {
                        "name": "Xã Tê Xăng",
                        "code": 23446,
                        "codename": "xa_te_xang",
                        "division_type": "xã",
                        "short_codename": "te_xang"
                    },
                    {
                        "name": "Xã Văn Xuôi",
                        "code": 23449,
                        "codename": "xa_van_xuoi",
                        "division_type": "xã",
                        "short_codename": "van_xuoi"
                    }
                ]
            },
            {
                "name": "Huyện Ia H' Drai",
                "code": 618,
                "codename": "huyen_ia_h_drai",
                "division_type": "huyện",
                "short_codename": "ia_h_drai",
                "wards": [
                    {
                        "name": "Xã Ia Đal",
                        "code": 23535,
                        "codename": "xa_ia_dal",
                        "division_type": "xã",
                        "short_codename": "ia_dal"
                    },
                    {
                        "name": "Xã Ia Dom",
                        "code": 23537,
                        "codename": "xa_ia_dom",
                        "division_type": "xã",
                        "short_codename": "ia_dom"
                    },
                    {
                        "name": "Xã Ia Tơi",
                        "code": 23538,
                        "codename": "xa_ia_toi",
                        "division_type": "xã",
                        "short_codename": "ia_toi"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Gia Lai",
        "code": 64,
        "codename": "tinh_gia_lai",
        "division_type": "tỉnh",
        "phone_code": 269,
        "districts": [
            {
                "name": "Thành phố Pleiku",
                "code": 622,
                "codename": "thanh_pho_pleiku",
                "division_type": "thành phố",
                "short_codename": "pleiku",
                "wards": [
                    {
                        "name": "Phường Yên Đỗ",
                        "code": 23557,
                        "codename": "phuong_yen_do",
                        "division_type": "phường",
                        "short_codename": "yen_do"
                    },
                    {
                        "name": "Phường Diên Hồng",
                        "code": 23560,
                        "codename": "phuong_dien_hong",
                        "division_type": "phường",
                        "short_codename": "dien_hong"
                    },
                    {
                        "name": "Phường Ia Kring",
                        "code": 23563,
                        "codename": "phuong_ia_kring",
                        "division_type": "phường",
                        "short_codename": "ia_kring"
                    },
                    {
                        "name": "Phường Hội Thương",
                        "code": 23566,
                        "codename": "phuong_hoi_thuong",
                        "division_type": "phường",
                        "short_codename": "hoi_thuong"
                    },
                    {
                        "name": "Phường Hội Phú",
                        "code": 23569,
                        "codename": "phuong_hoi_phu",
                        "division_type": "phường",
                        "short_codename": "hoi_phu"
                    },
                    {
                        "name": "Phường Phù Đổng",
                        "code": 23570,
                        "codename": "phuong_phu_dong",
                        "division_type": "phường",
                        "short_codename": "phu_dong"
                    },
                    {
                        "name": "Phường Hoa Lư",
                        "code": 23572,
                        "codename": "phuong_hoa_lu",
                        "division_type": "phường",
                        "short_codename": "hoa_lu"
                    },
                    {
                        "name": "Phường Tây Sơn",
                        "code": 23575,
                        "codename": "phuong_tay_son",
                        "division_type": "phường",
                        "short_codename": "tay_son"
                    },
                    {
                        "name": "Phường Thống Nhất",
                        "code": 23578,
                        "codename": "phuong_thong_nhat",
                        "division_type": "phường",
                        "short_codename": "thong_nhat"
                    },
                    {
                        "name": "Phường Đống Đa",
                        "code": 23579,
                        "codename": "phuong_dong_da",
                        "division_type": "phường",
                        "short_codename": "dong_da"
                    },
                    {
                        "name": "Phường Trà Bá",
                        "code": 23581,
                        "codename": "phuong_tra_ba",
                        "division_type": "phường",
                        "short_codename": "tra_ba"
                    },
                    {
                        "name": "Phường Thắng Lợi",
                        "code": 23582,
                        "codename": "phuong_thang_loi",
                        "division_type": "phường",
                        "short_codename": "thang_loi"
                    },
                    {
                        "name": "Phường Yên Thế",
                        "code": 23584,
                        "codename": "phuong_yen_the",
                        "division_type": "phường",
                        "short_codename": "yen_the"
                    },
                    {
                        "name": "Phường Chi Lăng",
                        "code": 23586,
                        "codename": "phuong_chi_lang",
                        "division_type": "phường",
                        "short_codename": "chi_lang"
                    },
                    {
                        "name": "Xã Biển Hồ",
                        "code": 23590,
                        "codename": "xa_bien_ho",
                        "division_type": "xã",
                        "short_codename": "bien_ho"
                    },
                    {
                        "name": "Xã Tân Sơn",
                        "code": 23593,
                        "codename": "xa_tan_son",
                        "division_type": "xã",
                        "short_codename": "tan_son"
                    },
                    {
                        "name": "Xã Trà Đa",
                        "code": 23596,
                        "codename": "xa_tra_da",
                        "division_type": "xã",
                        "short_codename": "tra_da"
                    },
                    {
                        "name": "Xã Chư Á",
                        "code": 23599,
                        "codename": "xa_chu_a",
                        "division_type": "xã",
                        "short_codename": "chu_a"
                    },
                    {
                        "name": "Xã An Phú",
                        "code": 23602,
                        "codename": "xa_an_phu",
                        "division_type": "xã",
                        "short_codename": "an_phu"
                    },
                    {
                        "name": "Xã Diên Phú",
                        "code": 23605,
                        "codename": "xa_dien_phu",
                        "division_type": "xã",
                        "short_codename": "dien_phu"
                    },
                    {
                        "name": "Xã Ia Kênh",
                        "code": 23608,
                        "codename": "xa_ia_kenh",
                        "division_type": "xã",
                        "short_codename": "ia_kenh"
                    },
                    {
                        "name": "Xã Gào",
                        "code": 23611,
                        "codename": "xa_gao",
                        "division_type": "xã",
                        "short_codename": "gao"
                    }
                ]
            },
            {
                "name": "Thị xã An Khê",
                "code": 623,
                "codename": "thi_xa_an_khe",
                "division_type": "thị xã",
                "short_codename": "an_khe",
                "wards": [
                    {
                        "name": "Phường An Bình",
                        "code": 23614,
                        "codename": "phuong_an_binh",
                        "division_type": "phường",
                        "short_codename": "an_binh"
                    },
                    {
                        "name": "Phường Tây Sơn",
                        "code": 23617,
                        "codename": "phuong_tay_son",
                        "division_type": "phường",
                        "short_codename": "tay_son"
                    },
                    {
                        "name": "Phường An Phú",
                        "code": 23620,
                        "codename": "phuong_an_phu",
                        "division_type": "phường",
                        "short_codename": "an_phu"
                    },
                    {
                        "name": "Phường An Tân",
                        "code": 23623,
                        "codename": "phuong_an_tan",
                        "division_type": "phường",
                        "short_codename": "an_tan"
                    },
                    {
                        "name": "Xã Tú An",
                        "code": 23626,
                        "codename": "xa_tu_an",
                        "division_type": "xã",
                        "short_codename": "tu_an"
                    },
                    {
                        "name": "Xã Xuân An",
                        "code": 23627,
                        "codename": "xa_xuan_an",
                        "division_type": "xã",
                        "short_codename": "xuan_an"
                    },
                    {
                        "name": "Xã Cửu An",
                        "code": 23629,
                        "codename": "xa_cuu_an",
                        "division_type": "xã",
                        "short_codename": "cuu_an"
                    },
                    {
                        "name": "Phường An Phước",
                        "code": 23630,
                        "codename": "phuong_an_phuoc",
                        "division_type": "phường",
                        "short_codename": "an_phuoc"
                    },
                    {
                        "name": "Xã Song An",
                        "code": 23632,
                        "codename": "xa_song_an",
                        "division_type": "xã",
                        "short_codename": "song_an"
                    },
                    {
                        "name": "Phường Ngô Mây",
                        "code": 23633,
                        "codename": "phuong_ngo_may",
                        "division_type": "phường",
                        "short_codename": "ngo_may"
                    },
                    {
                        "name": "Xã Thành An",
                        "code": 23635,
                        "codename": "xa_thanh_an",
                        "division_type": "xã",
                        "short_codename": "thanh_an"
                    }
                ]
            },
            {
                "name": "Thị xã Ayun Pa",
                "code": 624,
                "codename": "thi_xa_ayun_pa",
                "division_type": "thị xã",
                "short_codename": "ayun_pa",
                "wards": [
                    {
                        "name": "Phường Cheo Reo",
                        "code": 24041,
                        "codename": "phuong_cheo_reo",
                        "division_type": "phường",
                        "short_codename": "cheo_reo"
                    },
                    {
                        "name": "Phường Hòa Bình",
                        "code": 24042,
                        "codename": "phuong_hoa_binh",
                        "division_type": "phường",
                        "short_codename": "hoa_binh"
                    },
                    {
                        "name": "Phường Đoàn Kết",
                        "code": 24044,
                        "codename": "phuong_doan_ket",
                        "division_type": "phường",
                        "short_codename": "doan_ket"
                    },
                    {
                        "name": "Phường Sông Bờ",
                        "code": 24045,
                        "codename": "phuong_song_bo",
                        "division_type": "phường",
                        "short_codename": "song_bo"
                    },
                    {
                        "name": "Xã Ia RBol",
                        "code": 24064,
                        "codename": "xa_ia_rbol",
                        "division_type": "xã",
                        "short_codename": "ia_rbol"
                    },
                    {
                        "name": "Xã Chư Băh",
                        "code": 24065,
                        "codename": "xa_chu_bah",
                        "division_type": "xã",
                        "short_codename": "chu_bah"
                    },
                    {
                        "name": "Xã Ia RTô",
                        "code": 24070,
                        "codename": "xa_ia_rto",
                        "division_type": "xã",
                        "short_codename": "ia_rto"
                    },
                    {
                        "name": "Xã Ia Sao",
                        "code": 24073,
                        "codename": "xa_ia_sao",
                        "division_type": "xã",
                        "short_codename": "ia_sao"
                    }
                ]
            },
            {
                "name": "Huyện KBang",
                "code": 625,
                "codename": "huyen_kbang",
                "division_type": "huyện",
                "short_codename": "kbang",
                "wards": [
                    {
                        "name": "Thị trấn KBang",
                        "code": 23638,
                        "codename": "thi_tran_kbang",
                        "division_type": "thị trấn",
                        "short_codename": "kbang"
                    },
                    {
                        "name": "Xã Kon Pne",
                        "code": 23641,
                        "codename": "xa_kon_pne",
                        "division_type": "xã",
                        "short_codename": "kon_pne"
                    },
                    {
                        "name": "Xã Đăk Roong",
                        "code": 23644,
                        "codename": "xa_dak_roong",
                        "division_type": "xã",
                        "short_codename": "dak_roong"
                    },
                    {
                        "name": "Xã Sơn Lang",
                        "code": 23647,
                        "codename": "xa_son_lang",
                        "division_type": "xã",
                        "short_codename": "son_lang"
                    },
                    {
                        "name": "Xã KRong",
                        "code": 23650,
                        "codename": "xa_krong",
                        "division_type": "xã",
                        "short_codename": "krong"
                    },
                    {
                        "name": "Xã Sơ Pai",
                        "code": 23653,
                        "codename": "xa_so_pai",
                        "division_type": "xã",
                        "short_codename": "so_pai"
                    },
                    {
                        "name": "Xã Lơ Ku",
                        "code": 23656,
                        "codename": "xa_lo_ku",
                        "division_type": "xã",
                        "short_codename": "lo_ku"
                    },
                    {
                        "name": "Xã Đông",
                        "code": 23659,
                        "codename": "xa_dong",
                        "division_type": "xã",
                        "short_codename": "dong"
                    },
                    {
                        "name": "Xã Đak SMar",
                        "code": 23660,
                        "codename": "xa_dak_smar",
                        "division_type": "xã",
                        "short_codename": "dak_smar"
                    },
                    {
                        "name": "Xã Nghĩa An",
                        "code": 23662,
                        "codename": "xa_nghia_an",
                        "division_type": "xã",
                        "short_codename": "nghia_an"
                    },
                    {
                        "name": "Xã Tơ Tung",
                        "code": 23665,
                        "codename": "xa_to_tung",
                        "division_type": "xã",
                        "short_codename": "to_tung"
                    },
                    {
                        "name": "Xã Kông Lơng Khơng",
                        "code": 23668,
                        "codename": "xa_kong_long_khong",
                        "division_type": "xã",
                        "short_codename": "kong_long_khong"
                    },
                    {
                        "name": "Xã Kông Pla",
                        "code": 23671,
                        "codename": "xa_kong_pla",
                        "division_type": "xã",
                        "short_codename": "kong_pla"
                    },
                    {
                        "name": "Xã Đăk HLơ",
                        "code": 23674,
                        "codename": "xa_dak_hlo",
                        "division_type": "xã",
                        "short_codename": "dak_hlo"
                    }
                ]
            },
            {
                "name": "Huyện Đăk Đoa",
                "code": 626,
                "codename": "huyen_dak_doa",
                "division_type": "huyện",
                "short_codename": "dak_doa",
                "wards": [
                    {
                        "name": "Thị trấn Đăk Đoa",
                        "code": 23677,
                        "codename": "thi_tran_dak_doa",
                        "division_type": "thị trấn",
                        "short_codename": "dak_doa"
                    },
                    {
                        "name": "Xã Hà Đông",
                        "code": 23680,
                        "codename": "xa_ha_dong",
                        "division_type": "xã",
                        "short_codename": "ha_dong"
                    },
                    {
                        "name": "Xã Đăk Sơmei",
                        "code": 23683,
                        "codename": "xa_dak_somei",
                        "division_type": "xã",
                        "short_codename": "dak_somei"
                    },
                    {
                        "name": "Xã Đăk Krong",
                        "code": 23684,
                        "codename": "xa_dak_krong",
                        "division_type": "xã",
                        "short_codename": "dak_krong"
                    },
                    {
                        "name": "Xã Hải Yang",
                        "code": 23686,
                        "codename": "xa_hai_yang",
                        "division_type": "xã",
                        "short_codename": "hai_yang"
                    },
                    {
                        "name": "Xã Kon Gang",
                        "code": 23689,
                        "codename": "xa_kon_gang",
                        "division_type": "xã",
                        "short_codename": "kon_gang"
                    },
                    {
                        "name": "Xã Hà Bầu",
                        "code": 23692,
                        "codename": "xa_ha_bau",
                        "division_type": "xã",
                        "short_codename": "ha_bau"
                    },
                    {
                        "name": "Xã Nam Yang",
                        "code": 23695,
                        "codename": "xa_nam_yang",
                        "division_type": "xã",
                        "short_codename": "nam_yang"
                    },
                    {
                        "name": "Xã K' Dang",
                        "code": 23698,
                        "codename": "xa_k_dang",
                        "division_type": "xã",
                        "short_codename": "k_dang"
                    },
                    {
                        "name": "Xã H' Neng",
                        "code": 23701,
                        "codename": "xa_h_neng",
                        "division_type": "xã",
                        "short_codename": "h_neng"
                    },
                    {
                        "name": "Xã Tân Bình",
                        "code": 23704,
                        "codename": "xa_tan_binh",
                        "division_type": "xã",
                        "short_codename": "tan_binh"
                    },
                    {
                        "name": "Xã Glar",
                        "code": 23707,
                        "codename": "xa_glar",
                        "division_type": "xã",
                        "short_codename": "glar"
                    },
                    {
                        "name": "Xã A Dơk",
                        "code": 23710,
                        "codename": "xa_a_dok",
                        "division_type": "xã",
                        "short_codename": "a_dok"
                    },
                    {
                        "name": "Xã Trang",
                        "code": 23713,
                        "codename": "xa_trang",
                        "division_type": "xã",
                        "short_codename": "trang"
                    },
                    {
                        "name": "Xã HNol",
                        "code": 23714,
                        "codename": "xa_hnol",
                        "division_type": "xã",
                        "short_codename": "hnol"
                    },
                    {
                        "name": "Xã Ia Pết",
                        "code": 23716,
                        "codename": "xa_ia_pet",
                        "division_type": "xã",
                        "short_codename": "ia_pet"
                    },
                    {
                        "name": "Xã Ia Băng",
                        "code": 23719,
                        "codename": "xa_ia_bang",
                        "division_type": "xã",
                        "short_codename": "ia_bang"
                    }
                ]
            },
            {
                "name": "Huyện Chư Păh",
                "code": 627,
                "codename": "huyen_chu_pah",
                "division_type": "huyện",
                "short_codename": "chu_pah",
                "wards": [
                    {
                        "name": "Thị trấn Phú Hòa",
                        "code": 23722,
                        "codename": "thi_tran_phu_hoa",
                        "division_type": "thị trấn",
                        "short_codename": "phu_hoa"
                    },
                    {
                        "name": "Xã Hà Tây",
                        "code": 23725,
                        "codename": "xa_ha_tay",
                        "division_type": "xã",
                        "short_codename": "ha_tay"
                    },
                    {
                        "name": "Xã Ia Khươl",
                        "code": 23728,
                        "codename": "xa_ia_khuol",
                        "division_type": "xã",
                        "short_codename": "ia_khuol"
                    },
                    {
                        "name": "Xã Ia Phí",
                        "code": 23731,
                        "codename": "xa_ia_phi",
                        "division_type": "xã",
                        "short_codename": "ia_phi"
                    },
                    {
                        "name": "Thị trấn Ia Ly",
                        "code": 23734,
                        "codename": "thi_tran_ia_ly",
                        "division_type": "thị trấn",
                        "short_codename": "ia_ly"
                    },
                    {
                        "name": "Xã Ia Mơ Nông",
                        "code": 23737,
                        "codename": "xa_ia_mo_nong",
                        "division_type": "xã",
                        "short_codename": "ia_mo_nong"
                    },
                    {
                        "name": "Xã Ia Kreng",
                        "code": 23738,
                        "codename": "xa_ia_kreng",
                        "division_type": "xã",
                        "short_codename": "ia_kreng"
                    },
                    {
                        "name": "Xã Đăk Tơ Ver",
                        "code": 23740,
                        "codename": "xa_dak_to_ver",
                        "division_type": "xã",
                        "short_codename": "dak_to_ver"
                    },
                    {
                        "name": "Xã Hòa Phú",
                        "code": 23743,
                        "codename": "xa_hoa_phu",
                        "division_type": "xã",
                        "short_codename": "hoa_phu"
                    },
                    {
                        "name": "Xã Chư Đăng Ya",
                        "code": 23746,
                        "codename": "xa_chu_dang_ya",
                        "division_type": "xã",
                        "short_codename": "chu_dang_ya"
                    },
                    {
                        "name": "Xã Ia Ka",
                        "code": 23749,
                        "codename": "xa_ia_ka",
                        "division_type": "xã",
                        "short_codename": "ia_ka"
                    },
                    {
                        "name": "Xã Ia Nhin",
                        "code": 23752,
                        "codename": "xa_ia_nhin",
                        "division_type": "xã",
                        "short_codename": "ia_nhin"
                    },
                    {
                        "name": "Xã Nghĩa Hòa",
                        "code": 23755,
                        "codename": "xa_nghia_hoa",
                        "division_type": "xã",
                        "short_codename": "nghia_hoa"
                    },
                    {
                        "name": "Xã Nghĩa Hưng",
                        "code": 23761,
                        "codename": "xa_nghia_hung",
                        "division_type": "xã",
                        "short_codename": "nghia_hung"
                    }
                ]
            },
            {
                "name": "Huyện Ia Grai",
                "code": 628,
                "codename": "huyen_ia_grai",
                "division_type": "huyện",
                "short_codename": "ia_grai",
                "wards": [
                    {
                        "name": "Thị trấn Ia Kha",
                        "code": 23764,
                        "codename": "thi_tran_ia_kha",
                        "division_type": "thị trấn",
                        "short_codename": "ia_kha"
                    },
                    {
                        "name": "Xã Ia Sao",
                        "code": 23767,
                        "codename": "xa_ia_sao",
                        "division_type": "xã",
                        "short_codename": "ia_sao"
                    },
                    {
                        "name": "Xã Ia Yok",
                        "code": 23768,
                        "codename": "xa_ia_yok",
                        "division_type": "xã",
                        "short_codename": "ia_yok"
                    },
                    {
                        "name": "Xã Ia Hrung",
                        "code": 23770,
                        "codename": "xa_ia_hrung",
                        "division_type": "xã",
                        "short_codename": "ia_hrung"
                    },
                    {
                        "name": "Xã Ia Bă",
                        "code": 23771,
                        "codename": "xa_ia_ba",
                        "division_type": "xã",
                        "short_codename": "ia_ba"
                    },
                    {
                        "name": "Xã Ia Khai",
                        "code": 23773,
                        "codename": "xa_ia_khai",
                        "division_type": "xã",
                        "short_codename": "ia_khai"
                    },
                    {
                        "name": "Xã Ia KRai",
                        "code": 23776,
                        "codename": "xa_ia_krai",
                        "division_type": "xã",
                        "short_codename": "ia_krai"
                    },
                    {
                        "name": "Xã Ia Grăng",
                        "code": 23778,
                        "codename": "xa_ia_grang",
                        "division_type": "xã",
                        "short_codename": "ia_grang"
                    },
                    {
                        "name": "Xã Ia Tô",
                        "code": 23779,
                        "codename": "xa_ia_to",
                        "division_type": "xã",
                        "short_codename": "ia_to"
                    },
                    {
                        "name": "Xã Ia O",
                        "code": 23782,
                        "codename": "xa_ia_o",
                        "division_type": "xã",
                        "short_codename": "ia_o"
                    },
                    {
                        "name": "Xã Ia Dêr",
                        "code": 23785,
                        "codename": "xa_ia_der",
                        "division_type": "xã",
                        "short_codename": "ia_der"
                    },
                    {
                        "name": "Xã Ia Chia",
                        "code": 23788,
                        "codename": "xa_ia_chia",
                        "division_type": "xã",
                        "short_codename": "ia_chia"
                    },
                    {
                        "name": "Xã Ia Pếch",
                        "code": 23791,
                        "codename": "xa_ia_pech",
                        "division_type": "xã",
                        "short_codename": "ia_pech"
                    }
                ]
            },
            {
                "name": "Huyện Mang Yang",
                "code": 629,
                "codename": "huyen_mang_yang",
                "division_type": "huyện",
                "short_codename": "mang_yang",
                "wards": [
                    {
                        "name": "Thị trấn Kon Dơng",
                        "code": 23794,
                        "codename": "thi_tran_kon_dong",
                        "division_type": "thị trấn",
                        "short_codename": "kon_dong"
                    },
                    {
                        "name": "Xã Ayun",
                        "code": 23797,
                        "codename": "xa_ayun",
                        "division_type": "xã",
                        "short_codename": "ayun"
                    },
                    {
                        "name": "Xã Đak Jơ Ta",
                        "code": 23798,
                        "codename": "xa_dak_jo_ta",
                        "division_type": "xã",
                        "short_codename": "dak_jo_ta"
                    },
                    {
                        "name": "Xã Đak Ta Ley",
                        "code": 23799,
                        "codename": "xa_dak_ta_ley",
                        "division_type": "xã",
                        "short_codename": "dak_ta_ley"
                    },
                    {
                        "name": "Xã Hra",
                        "code": 23800,
                        "codename": "xa_hra",
                        "division_type": "xã",
                        "short_codename": "hra"
                    },
                    {
                        "name": "Xã Đăk Yă",
                        "code": 23803,
                        "codename": "xa_dak_ya",
                        "division_type": "xã",
                        "short_codename": "dak_ya"
                    },
                    {
                        "name": "Xã Đăk Djrăng",
                        "code": 23806,
                        "codename": "xa_dak_djrang",
                        "division_type": "xã",
                        "short_codename": "dak_djrang"
                    },
                    {
                        "name": "Xã Lơ Pang",
                        "code": 23809,
                        "codename": "xa_lo_pang",
                        "division_type": "xã",
                        "short_codename": "lo_pang"
                    },
                    {
                        "name": "Xã Kon Thụp",
                        "code": 23812,
                        "codename": "xa_kon_thup",
                        "division_type": "xã",
                        "short_codename": "kon_thup"
                    },
                    {
                        "name": "Xã Đê Ar",
                        "code": 23815,
                        "codename": "xa_de_ar",
                        "division_type": "xã",
                        "short_codename": "de_ar"
                    },
                    {
                        "name": "Xã Kon Chiêng",
                        "code": 23818,
                        "codename": "xa_kon_chieng",
                        "division_type": "xã",
                        "short_codename": "kon_chieng"
                    },
                    {
                        "name": "Xã Đăk Trôi",
                        "code": 23821,
                        "codename": "xa_dak_troi",
                        "division_type": "xã",
                        "short_codename": "dak_troi"
                    }
                ]
            },
            {
                "name": "Huyện Kông Chro",
                "code": 630,
                "codename": "huyen_kong_chro",
                "division_type": "huyện",
                "short_codename": "kong_chro",
                "wards": [
                    {
                        "name": "Thị trấn Kông Chro",
                        "code": 23824,
                        "codename": "thi_tran_kong_chro",
                        "division_type": "thị trấn",
                        "short_codename": "kong_chro"
                    },
                    {
                        "name": "Xã Chư Krêy",
                        "code": 23827,
                        "codename": "xa_chu_krey",
                        "division_type": "xã",
                        "short_codename": "chu_krey"
                    },
                    {
                        "name": "Xã An Trung",
                        "code": 23830,
                        "codename": "xa_an_trung",
                        "division_type": "xã",
                        "short_codename": "an_trung"
                    },
                    {
                        "name": "Xã Kông Yang",
                        "code": 23833,
                        "codename": "xa_kong_yang",
                        "division_type": "xã",
                        "short_codename": "kong_yang"
                    },
                    {
                        "name": "Xã Đăk Tơ Pang",
                        "code": 23836,
                        "codename": "xa_dak_to_pang",
                        "division_type": "xã",
                        "short_codename": "dak_to_pang"
                    },
                    {
                        "name": "Xã SRó",
                        "code": 23839,
                        "codename": "xa_sro",
                        "division_type": "xã",
                        "short_codename": "sro"
                    },
                    {
                        "name": "Xã Đắk Kơ Ning",
                        "code": 23840,
                        "codename": "xa_dak_ko_ning",
                        "division_type": "xã",
                        "short_codename": "dak_ko_ning"
                    },
                    {
                        "name": "Xã Đăk Song",
                        "code": 23842,
                        "codename": "xa_dak_song",
                        "division_type": "xã",
                        "short_codename": "dak_song"
                    },
                    {
                        "name": "Xã Đăk Pling",
                        "code": 23843,
                        "codename": "xa_dak_pling",
                        "division_type": "xã",
                        "short_codename": "dak_pling"
                    },
                    {
                        "name": "Xã Yang Trung",
                        "code": 23845,
                        "codename": "xa_yang_trung",
                        "division_type": "xã",
                        "short_codename": "yang_trung"
                    },
                    {
                        "name": "Xã Đăk Pơ Pho",
                        "code": 23846,
                        "codename": "xa_dak_po_pho",
                        "division_type": "xã",
                        "short_codename": "dak_po_pho"
                    },
                    {
                        "name": "Xã Ya Ma",
                        "code": 23848,
                        "codename": "xa_ya_ma",
                        "division_type": "xã",
                        "short_codename": "ya_ma"
                    },
                    {
                        "name": "Xã Chơ Long",
                        "code": 23851,
                        "codename": "xa_cho_long",
                        "division_type": "xã",
                        "short_codename": "cho_long"
                    },
                    {
                        "name": "Xã Yang Nam",
                        "code": 23854,
                        "codename": "xa_yang_nam",
                        "division_type": "xã",
                        "short_codename": "yang_nam"
                    }
                ]
            },
            {
                "name": "Huyện Đức Cơ",
                "code": 631,
                "codename": "huyen_duc_co",
                "division_type": "huyện",
                "short_codename": "duc_co",
                "wards": [
                    {
                        "name": "Thị trấn Chư Ty",
                        "code": 23857,
                        "codename": "thi_tran_chu_ty",
                        "division_type": "thị trấn",
                        "short_codename": "chu_ty"
                    },
                    {
                        "name": "Xã Ia Dơk",
                        "code": 23860,
                        "codename": "xa_ia_dok",
                        "division_type": "xã",
                        "short_codename": "ia_dok"
                    },
                    {
                        "name": "Xã Ia Krêl",
                        "code": 23863,
                        "codename": "xa_ia_krel",
                        "division_type": "xã",
                        "short_codename": "ia_krel"
                    },
                    {
                        "name": "Xã Ia Din",
                        "code": 23866,
                        "codename": "xa_ia_din",
                        "division_type": "xã",
                        "short_codename": "ia_din"
                    },
                    {
                        "name": "Xã Ia Kla",
                        "code": 23869,
                        "codename": "xa_ia_kla",
                        "division_type": "xã",
                        "short_codename": "ia_kla"
                    },
                    {
                        "name": "Xã Ia Dom",
                        "code": 23872,
                        "codename": "xa_ia_dom",
                        "division_type": "xã",
                        "short_codename": "ia_dom"
                    },
                    {
                        "name": "Xã Ia Lang",
                        "code": 23875,
                        "codename": "xa_ia_lang",
                        "division_type": "xã",
                        "short_codename": "ia_lang"
                    },
                    {
                        "name": "Xã Ia Kriêng",
                        "code": 23878,
                        "codename": "xa_ia_krieng",
                        "division_type": "xã",
                        "short_codename": "ia_krieng"
                    },
                    {
                        "name": "Xã Ia Pnôn",
                        "code": 23881,
                        "codename": "xa_ia_pnon",
                        "division_type": "xã",
                        "short_codename": "ia_pnon"
                    },
                    {
                        "name": "Xã Ia Nan",
                        "code": 23884,
                        "codename": "xa_ia_nan",
                        "division_type": "xã",
                        "short_codename": "ia_nan"
                    }
                ]
            },
            {
                "name": "Huyện Chư Prông",
                "code": 632,
                "codename": "huyen_chu_prong",
                "division_type": "huyện",
                "short_codename": "chu_prong",
                "wards": [
                    {
                        "name": "Thị trấn Chư Prông",
                        "code": 23887,
                        "codename": "thi_tran_chu_prong",
                        "division_type": "thị trấn",
                        "short_codename": "chu_prong"
                    },
                    {
                        "name": "Xã Ia Kly",
                        "code": 23888,
                        "codename": "xa_ia_kly",
                        "division_type": "xã",
                        "short_codename": "ia_kly"
                    },
                    {
                        "name": "Xã Bình Giáo",
                        "code": 23890,
                        "codename": "xa_binh_giao",
                        "division_type": "xã",
                        "short_codename": "binh_giao"
                    },
                    {
                        "name": "Xã Ia Drăng",
                        "code": 23893,
                        "codename": "xa_ia_drang",
                        "division_type": "xã",
                        "short_codename": "ia_drang"
                    },
                    {
                        "name": "Xã Thăng Hưng",
                        "code": 23896,
                        "codename": "xa_thang_hung",
                        "division_type": "xã",
                        "short_codename": "thang_hung"
                    },
                    {
                        "name": "Xã Bàu Cạn",
                        "code": 23899,
                        "codename": "xa_bau_can",
                        "division_type": "xã",
                        "short_codename": "bau_can"
                    },
                    {
                        "name": "Xã Ia Phìn",
                        "code": 23902,
                        "codename": "xa_ia_phin",
                        "division_type": "xã",
                        "short_codename": "ia_phin"
                    },
                    {
                        "name": "Xã Ia Băng",
                        "code": 23905,
                        "codename": "xa_ia_bang",
                        "division_type": "xã",
                        "short_codename": "xa_ia_bang"
                    },
                    {
                        "name": "Xã Ia Tôr",
                        "code": 23908,
                        "codename": "xa_ia_tor",
                        "division_type": "xã",
                        "short_codename": "ia_tor"
                    },
                    {
                        "name": "Xã Ia Boòng",
                        "code": 23911,
                        "codename": "xa_ia_boong",
                        "division_type": "xã",
                        "short_codename": "ia_boong"
                    },
                    {
                        "name": "Xã Ia O",
                        "code": 23914,
                        "codename": "xa_ia_o",
                        "division_type": "xã",
                        "short_codename": "ia_o"
                    },
                    {
                        "name": "Xã Ia Púch",
                        "code": 23917,
                        "codename": "xa_ia_puch",
                        "division_type": "xã",
                        "short_codename": "ia_puch"
                    },
                    {
                        "name": "Xã Ia Me",
                        "code": 23920,
                        "codename": "xa_ia_me",
                        "division_type": "xã",
                        "short_codename": "ia_me"
                    },
                    {
                        "name": "Xã Ia Vê",
                        "code": 23923,
                        "codename": "xa_ia_ve",
                        "division_type": "xã",
                        "short_codename": "ia_ve"
                    },
                    {
                        "name": "Xã Ia Bang",
                        "code": 23924,
                        "codename": "xa_ia_bang",
                        "division_type": "xã",
                        "short_codename": "xa_ia_bang"
                    },
                    {
                        "name": "Xã Ia Pia",
                        "code": 23926,
                        "codename": "xa_ia_pia",
                        "division_type": "xã",
                        "short_codename": "ia_pia"
                    },
                    {
                        "name": "Xã Ia Ga",
                        "code": 23929,
                        "codename": "xa_ia_ga",
                        "division_type": "xã",
                        "short_codename": "ia_ga"
                    },
                    {
                        "name": "Xã Ia Lâu",
                        "code": 23932,
                        "codename": "xa_ia_lau",
                        "division_type": "xã",
                        "short_codename": "ia_lau"
                    },
                    {
                        "name": "Xã Ia Piơr",
                        "code": 23935,
                        "codename": "xa_ia_pior",
                        "division_type": "xã",
                        "short_codename": "ia_pior"
                    },
                    {
                        "name": "Xã Ia Mơ",
                        "code": 23938,
                        "codename": "xa_ia_mo",
                        "division_type": "xã",
                        "short_codename": "ia_mo"
                    }
                ]
            },
            {
                "name": "Huyện Chư Sê",
                "code": 633,
                "codename": "huyen_chu_se",
                "division_type": "huyện",
                "short_codename": "chu_se",
                "wards": [
                    {
                        "name": "Thị trấn Chư Sê",
                        "code": 23941,
                        "codename": "thi_tran_chu_se",
                        "division_type": "thị trấn",
                        "short_codename": "chu_se"
                    },
                    {
                        "name": "Xã Ia Tiêm",
                        "code": 23944,
                        "codename": "xa_ia_tiem",
                        "division_type": "xã",
                        "short_codename": "ia_tiem"
                    },
                    {
                        "name": "Xã Chư Pơng",
                        "code": 23945,
                        "codename": "xa_chu_pong",
                        "division_type": "xã",
                        "short_codename": "chu_pong"
                    },
                    {
                        "name": "Xã Bar Măih",
                        "code": 23946,
                        "codename": "xa_bar_maih",
                        "division_type": "xã",
                        "short_codename": "bar_maih"
                    },
                    {
                        "name": "Xã Bờ Ngoong",
                        "code": 23947,
                        "codename": "xa_bo_ngoong",
                        "division_type": "xã",
                        "short_codename": "bo_ngoong"
                    },
                    {
                        "name": "Xã Ia Glai",
                        "code": 23950,
                        "codename": "xa_ia_glai",
                        "division_type": "xã",
                        "short_codename": "ia_glai"
                    },
                    {
                        "name": "Xã AL Bá",
                        "code": 23953,
                        "codename": "xa_al_ba",
                        "division_type": "xã",
                        "short_codename": "al_ba"
                    },
                    {
                        "name": "Xã Kông HTok",
                        "code": 23954,
                        "codename": "xa_kong_htok",
                        "division_type": "xã",
                        "short_codename": "kong_htok"
                    },
                    {
                        "name": "Xã AYun",
                        "code": 23956,
                        "codename": "xa_ayun",
                        "division_type": "xã",
                        "short_codename": "ayun"
                    },
                    {
                        "name": "Xã Ia HLốp",
                        "code": 23959,
                        "codename": "xa_ia_hlop",
                        "division_type": "xã",
                        "short_codename": "ia_hlop"
                    },
                    {
                        "name": "Xã Ia Blang",
                        "code": 23962,
                        "codename": "xa_ia_blang",
                        "division_type": "xã",
                        "short_codename": "ia_blang"
                    },
                    {
                        "name": "Xã Dun",
                        "code": 23965,
                        "codename": "xa_dun",
                        "division_type": "xã",
                        "short_codename": "dun"
                    },
                    {
                        "name": "Xã Ia Pal",
                        "code": 23966,
                        "codename": "xa_ia_pal",
                        "division_type": "xã",
                        "short_codename": "ia_pal"
                    },
                    {
                        "name": "Xã H Bông",
                        "code": 23968,
                        "codename": "xa_h_bong",
                        "division_type": "xã",
                        "short_codename": "h_bong"
                    },
                    {
                        "name": "Xã Ia Ko",
                        "code": 23977,
                        "codename": "xa_ia_ko",
                        "division_type": "xã",
                        "short_codename": "ia_ko"
                    }
                ]
            },
            {
                "name": "Huyện Đăk Pơ",
                "code": 634,
                "codename": "huyen_dak_po",
                "division_type": "huyện",
                "short_codename": "dak_po",
                "wards": [
                    {
                        "name": "Xã Hà Tam",
                        "code": 23989,
                        "codename": "xa_ha_tam",
                        "division_type": "xã",
                        "short_codename": "ha_tam"
                    },
                    {
                        "name": "Xã An Thành",
                        "code": 23992,
                        "codename": "xa_an_thanh",
                        "division_type": "xã",
                        "short_codename": "an_thanh"
                    },
                    {
                        "name": "Thị trấn Đak Pơ",
                        "code": 23995,
                        "codename": "thi_tran_dak_po",
                        "division_type": "thị trấn",
                        "short_codename": "dak_po"
                    },
                    {
                        "name": "Xã Yang Bắc",
                        "code": 23998,
                        "codename": "xa_yang_bac",
                        "division_type": "xã",
                        "short_codename": "yang_bac"
                    },
                    {
                        "name": "Xã Cư An",
                        "code": 24001,
                        "codename": "xa_cu_an",
                        "division_type": "xã",
                        "short_codename": "cu_an"
                    },
                    {
                        "name": "Xã Tân An",
                        "code": 24004,
                        "codename": "xa_tan_an",
                        "division_type": "xã",
                        "short_codename": "tan_an"
                    },
                    {
                        "name": "Xã Phú An",
                        "code": 24007,
                        "codename": "xa_phu_an",
                        "division_type": "xã",
                        "short_codename": "phu_an"
                    },
                    {
                        "name": "Xã Ya Hội",
                        "code": 24010,
                        "codename": "xa_ya_hoi",
                        "division_type": "xã",
                        "short_codename": "ya_hoi"
                    }
                ]
            },
            {
                "name": "Huyện Ia Pa",
                "code": 635,
                "codename": "huyen_ia_pa",
                "division_type": "huyện",
                "short_codename": "ia_pa",
                "wards": [
                    {
                        "name": "Xã Pờ Tó",
                        "code": 24013,
                        "codename": "xa_po_to",
                        "division_type": "xã",
                        "short_codename": "po_to"
                    },
                    {
                        "name": "Xã Chư Răng",
                        "code": 24016,
                        "codename": "xa_chu_rang",
                        "division_type": "xã",
                        "short_codename": "chu_rang"
                    },
                    {
                        "name": "Xã Ia KDăm",
                        "code": 24019,
                        "codename": "xa_ia_kdam",
                        "division_type": "xã",
                        "short_codename": "ia_kdam"
                    },
                    {
                        "name": "Xã Kim Tân",
                        "code": 24022,
                        "codename": "xa_kim_tan",
                        "division_type": "xã",
                        "short_codename": "kim_tan"
                    },
                    {
                        "name": "Xã Chư Mố",
                        "code": 24025,
                        "codename": "xa_chu_mo",
                        "division_type": "xã",
                        "short_codename": "chu_mo"
                    },
                    {
                        "name": "Xã Ia Tul",
                        "code": 24028,
                        "codename": "xa_ia_tul",
                        "division_type": "xã",
                        "short_codename": "ia_tul"
                    },
                    {
                        "name": "Xã Ia Ma Rơn",
                        "code": 24031,
                        "codename": "xa_ia_ma_ron",
                        "division_type": "xã",
                        "short_codename": "ia_ma_ron"
                    },
                    {
                        "name": "Xã Ia Broăi",
                        "code": 24034,
                        "codename": "xa_ia_broai",
                        "division_type": "xã",
                        "short_codename": "ia_broai"
                    },
                    {
                        "name": "Xã Ia Trok",
                        "code": 24037,
                        "codename": "xa_ia_trok",
                        "division_type": "xã",
                        "short_codename": "ia_trok"
                    }
                ]
            },
            {
                "name": "Huyện Krông Pa",
                "code": 637,
                "codename": "huyen_krong_pa",
                "division_type": "huyện",
                "short_codename": "krong_pa",
                "wards": [
                    {
                        "name": "Thị trấn Phú Túc",
                        "code": 24076,
                        "codename": "thi_tran_phu_tuc",
                        "division_type": "thị trấn",
                        "short_codename": "phu_tuc"
                    },
                    {
                        "name": "Xã Ia RSai",
                        "code": 24079,
                        "codename": "xa_ia_rsai",
                        "division_type": "xã",
                        "short_codename": "ia_rsai"
                    },
                    {
                        "name": "Xã Ia RSươm",
                        "code": 24082,
                        "codename": "xa_ia_rsuom",
                        "division_type": "xã",
                        "short_codename": "ia_rsuom"
                    },
                    {
                        "name": "Xã Chư Gu",
                        "code": 24085,
                        "codename": "xa_chu_gu",
                        "division_type": "xã",
                        "short_codename": "chu_gu"
                    },
                    {
                        "name": "Xã Đất Bằng",
                        "code": 24088,
                        "codename": "xa_dat_bang",
                        "division_type": "xã",
                        "short_codename": "dat_bang"
                    },
                    {
                        "name": "Xã Ia Mláh",
                        "code": 24091,
                        "codename": "xa_ia_mlah",
                        "division_type": "xã",
                        "short_codename": "ia_mlah"
                    },
                    {
                        "name": "Xã Chư Drăng",
                        "code": 24094,
                        "codename": "xa_chu_drang",
                        "division_type": "xã",
                        "short_codename": "chu_drang"
                    },
                    {
                        "name": "Xã Phú Cần",
                        "code": 24097,
                        "codename": "xa_phu_can",
                        "division_type": "xã",
                        "short_codename": "phu_can"
                    },
                    {
                        "name": "Xã Ia HDreh",
                        "code": 24100,
                        "codename": "xa_ia_hdreh",
                        "division_type": "xã",
                        "short_codename": "ia_hdreh"
                    },
                    {
                        "name": "Xã Ia RMok",
                        "code": 24103,
                        "codename": "xa_ia_rmok",
                        "division_type": "xã",
                        "short_codename": "ia_rmok"
                    },
                    {
                        "name": "Xã Chư Ngọc",
                        "code": 24106,
                        "codename": "xa_chu_ngoc",
                        "division_type": "xã",
                        "short_codename": "chu_ngoc"
                    },
                    {
                        "name": "Xã Uar",
                        "code": 24109,
                        "codename": "xa_uar",
                        "division_type": "xã",
                        "short_codename": "uar"
                    },
                    {
                        "name": "Xã Chư Rcăm",
                        "code": 24112,
                        "codename": "xa_chu_rcam",
                        "division_type": "xã",
                        "short_codename": "chu_rcam"
                    },
                    {
                        "name": "Xã Krông Năng",
                        "code": 24115,
                        "codename": "xa_krong_nang",
                        "division_type": "xã",
                        "short_codename": "krong_nang"
                    }
                ]
            },
            {
                "name": "Huyện Phú Thiện",
                "code": 638,
                "codename": "huyen_phu_thien",
                "division_type": "huyện",
                "short_codename": "phu_thien",
                "wards": [
                    {
                        "name": "Thị trấn Phú Thiện",
                        "code": 24043,
                        "codename": "thi_tran_phu_thien",
                        "division_type": "thị trấn",
                        "short_codename": "phu_thien"
                    },
                    {
                        "name": "Xã Chư A Thai",
                        "code": 24046,
                        "codename": "xa_chu_a_thai",
                        "division_type": "xã",
                        "short_codename": "chu_a_thai"
                    },
                    {
                        "name": "Xã Ayun Hạ",
                        "code": 24048,
                        "codename": "xa_ayun_ha",
                        "division_type": "xã",
                        "short_codename": "ayun_ha"
                    },
                    {
                        "name": "Xã Ia Ake",
                        "code": 24049,
                        "codename": "xa_ia_ake",
                        "division_type": "xã",
                        "short_codename": "ia_ake"
                    },
                    {
                        "name": "Xã Ia Sol",
                        "code": 24052,
                        "codename": "xa_ia_sol",
                        "division_type": "xã",
                        "short_codename": "ia_sol"
                    },
                    {
                        "name": "Xã Ia Piar",
                        "code": 24055,
                        "codename": "xa_ia_piar",
                        "division_type": "xã",
                        "short_codename": "ia_piar"
                    },
                    {
                        "name": "Xã Ia Peng",
                        "code": 24058,
                        "codename": "xa_ia_peng",
                        "division_type": "xã",
                        "short_codename": "ia_peng"
                    },
                    {
                        "name": "Xã Chrôh Pơnan",
                        "code": 24060,
                        "codename": "xa_chroh_ponan",
                        "division_type": "xã",
                        "short_codename": "chroh_ponan"
                    },
                    {
                        "name": "Xã Ia Hiao",
                        "code": 24061,
                        "codename": "xa_ia_hiao",
                        "division_type": "xã",
                        "short_codename": "ia_hiao"
                    },
                    {
                        "name": "Xã Ia Yeng",
                        "code": 24067,
                        "codename": "xa_ia_yeng",
                        "division_type": "xã",
                        "short_codename": "ia_yeng"
                    }
                ]
            },
            {
                "name": "Huyện Chư Pưh",
                "code": 639,
                "codename": "huyen_chu_puh",
                "division_type": "huyện",
                "short_codename": "chu_puh",
                "wards": [
                    {
                        "name": "Thị trấn Nhơn Hoà",
                        "code": 23942,
                        "codename": "thi_tran_nhon_hoa",
                        "division_type": "thị trấn",
                        "short_codename": "nhon_hoa"
                    },
                    {
                        "name": "Xã Ia Hrú",
                        "code": 23971,
                        "codename": "xa_ia_hru",
                        "division_type": "xã",
                        "short_codename": "ia_hru"
                    },
                    {
                        "name": "Xã Ia Rong",
                        "code": 23972,
                        "codename": "xa_ia_rong",
                        "division_type": "xã",
                        "short_codename": "ia_rong"
                    },
                    {
                        "name": "Xã Ia Dreng",
                        "code": 23974,
                        "codename": "xa_ia_dreng",
                        "division_type": "xã",
                        "short_codename": "ia_dreng"
                    },
                    {
                        "name": "Xã Ia Hla",
                        "code": 23978,
                        "codename": "xa_ia_hla",
                        "division_type": "xã",
                        "short_codename": "ia_hla"
                    },
                    {
                        "name": "Xã Chư Don",
                        "code": 23980,
                        "codename": "xa_chu_don",
                        "division_type": "xã",
                        "short_codename": "chu_don"
                    },
                    {
                        "name": "Xã Ia Phang",
                        "code": 23983,
                        "codename": "xa_ia_phang",
                        "division_type": "xã",
                        "short_codename": "ia_phang"
                    },
                    {
                        "name": "Xã Ia Le",
                        "code": 23986,
                        "codename": "xa_ia_le",
                        "division_type": "xã",
                        "short_codename": "ia_le"
                    },
                    {
                        "name": "Xã Ia BLứ",
                        "code": 23987,
                        "codename": "xa_ia_blu",
                        "division_type": "xã",
                        "short_codename": "ia_blu"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Đắk Lắk",
        "code": 66,
        "codename": "tinh_dak_lak",
        "division_type": "tỉnh",
        "phone_code": 262,
        "districts": [
            {
                "name": "Thành phố Buôn Ma Thuột",
                "code": 643,
                "codename": "thanh_pho_buon_ma_thuot",
                "division_type": "thành phố",
                "short_codename": "buon_ma_thuot",
                "wards": [
                    {
                        "name": "Phường Tân Lập",
                        "code": 24118,
                        "codename": "phuong_tan_lap",
                        "division_type": "phường",
                        "short_codename": "tan_lap"
                    },
                    {
                        "name": "Phường Tân Hòa",
                        "code": 24121,
                        "codename": "phuong_tan_hoa",
                        "division_type": "phường",
                        "short_codename": "tan_hoa"
                    },
                    {
                        "name": "Phường Tân An",
                        "code": 24124,
                        "codename": "phuong_tan_an",
                        "division_type": "phường",
                        "short_codename": "tan_an"
                    },
                    {
                        "name": "Phường Thống Nhất",
                        "code": 24127,
                        "codename": "phuong_thong_nhat",
                        "division_type": "phường",
                        "short_codename": "thong_nhat"
                    },
                    {
                        "name": "Phường Thành Nhất",
                        "code": 24130,
                        "codename": "phuong_thanh_nhat",
                        "division_type": "phường",
                        "short_codename": "thanh_nhat"
                    },
                    {
                        "name": "Phường Thắng Lợi",
                        "code": 24133,
                        "codename": "phuong_thang_loi",
                        "division_type": "phường",
                        "short_codename": "thang_loi"
                    },
                    {
                        "name": "Phường Tân Lợi",
                        "code": 24136,
                        "codename": "phuong_tan_loi",
                        "division_type": "phường",
                        "short_codename": "tan_loi"
                    },
                    {
                        "name": "Phường Thành Công",
                        "code": 24139,
                        "codename": "phuong_thanh_cong",
                        "division_type": "phường",
                        "short_codename": "thanh_cong"
                    },
                    {
                        "name": "Phường Tân Thành",
                        "code": 24142,
                        "codename": "phuong_tan_thanh",
                        "division_type": "phường",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Phường Tân Tiến",
                        "code": 24145,
                        "codename": "phuong_tan_tien",
                        "division_type": "phường",
                        "short_codename": "tan_tien"
                    },
                    {
                        "name": "Phường Tự An",
                        "code": 24148,
                        "codename": "phuong_tu_an",
                        "division_type": "phường",
                        "short_codename": "tu_an"
                    },
                    {
                        "name": "Phường Ea Tam",
                        "code": 24151,
                        "codename": "phuong_ea_tam",
                        "division_type": "phường",
                        "short_codename": "ea_tam"
                    },
                    {
                        "name": "Phường Khánh Xuân",
                        "code": 24154,
                        "codename": "phuong_khanh_xuan",
                        "division_type": "phường",
                        "short_codename": "khanh_xuan"
                    },
                    {
                        "name": "Xã Hòa Thuận",
                        "code": 24157,
                        "codename": "xa_hoa_thuan",
                        "division_type": "xã",
                        "short_codename": "hoa_thuan"
                    },
                    {
                        "name": "Xã Cư ÊBur",
                        "code": 24160,
                        "codename": "xa_cu_ebur",
                        "division_type": "xã",
                        "short_codename": "cu_ebur"
                    },
                    {
                        "name": "Xã Ea Tu",
                        "code": 24163,
                        "codename": "xa_ea_tu",
                        "division_type": "xã",
                        "short_codename": "ea_tu"
                    },
                    {
                        "name": "Xã Hòa Thắng",
                        "code": 24166,
                        "codename": "xa_hoa_thang",
                        "division_type": "xã",
                        "short_codename": "hoa_thang"
                    },
                    {
                        "name": "Xã Ea Kao",
                        "code": 24169,
                        "codename": "xa_ea_kao",
                        "division_type": "xã",
                        "short_codename": "ea_kao"
                    },
                    {
                        "name": "Xã Hòa Phú",
                        "code": 24172,
                        "codename": "xa_hoa_phu",
                        "division_type": "xã",
                        "short_codename": "hoa_phu"
                    },
                    {
                        "name": "Xã Hòa Khánh",
                        "code": 24175,
                        "codename": "xa_hoa_khanh",
                        "division_type": "xã",
                        "short_codename": "hoa_khanh"
                    },
                    {
                        "name": "Xã Hòa Xuân",
                        "code": 24178,
                        "codename": "xa_hoa_xuan",
                        "division_type": "xã",
                        "short_codename": "hoa_xuan"
                    }
                ]
            },
            {
                "name": "Thị Xã Buôn Hồ",
                "code": 644,
                "codename": "thi_xa_buon_ho",
                "division_type": "thị xã",
                "short_codename": "buon_ho",
                "wards": [
                    {
                        "name": "Phường An Lạc",
                        "code": 24305,
                        "codename": "phuong_an_lac",
                        "division_type": "phường",
                        "short_codename": "an_lac"
                    },
                    {
                        "name": "Phường An Bình",
                        "code": 24308,
                        "codename": "phuong_an_binh",
                        "division_type": "phường",
                        "short_codename": "an_binh"
                    },
                    {
                        "name": "Phường Thiện An",
                        "code": 24311,
                        "codename": "phuong_thien_an",
                        "division_type": "phường",
                        "short_codename": "thien_an"
                    },
                    {
                        "name": "Phường Đạt Hiếu",
                        "code": 24318,
                        "codename": "phuong_dat_hieu",
                        "division_type": "phường",
                        "short_codename": "dat_hieu"
                    },
                    {
                        "name": "Phường Đoàn Kết",
                        "code": 24322,
                        "codename": "phuong_doan_ket",
                        "division_type": "phường",
                        "short_codename": "doan_ket"
                    },
                    {
                        "name": "Xã Ea Blang",
                        "code": 24325,
                        "codename": "xa_ea_blang",
                        "division_type": "xã",
                        "short_codename": "ea_blang"
                    },
                    {
                        "name": "Xã Ea Drông",
                        "code": 24328,
                        "codename": "xa_ea_drong",
                        "division_type": "xã",
                        "short_codename": "ea_drong"
                    },
                    {
                        "name": "Phường Thống Nhất",
                        "code": 24331,
                        "codename": "phuong_thong_nhat",
                        "division_type": "phường",
                        "short_codename": "thong_nhat"
                    },
                    {
                        "name": "Phường Bình Tân",
                        "code": 24332,
                        "codename": "phuong_binh_tan",
                        "division_type": "phường",
                        "short_codename": "binh_tan"
                    },
                    {
                        "name": "Xã Ea Siên",
                        "code": 24334,
                        "codename": "xa_ea_sien",
                        "division_type": "xã",
                        "short_codename": "ea_sien"
                    },
                    {
                        "name": "Xã Bình Thuận",
                        "code": 24337,
                        "codename": "xa_binh_thuan",
                        "division_type": "xã",
                        "short_codename": "binh_thuan"
                    },
                    {
                        "name": "Xã Cư Bao",
                        "code": 24340,
                        "codename": "xa_cu_bao",
                        "division_type": "xã",
                        "short_codename": "cu_bao"
                    }
                ]
            },
            {
                "name": "Huyện Ea H'leo",
                "code": 645,
                "codename": "huyen_ea_hleo",
                "division_type": "huyện",
                "short_codename": "ea_hleo",
                "wards": [
                    {
                        "name": "Thị trấn Ea Drăng",
                        "code": 24181,
                        "codename": "thi_tran_ea_drang",
                        "division_type": "thị trấn",
                        "short_codename": "ea_drang"
                    },
                    {
                        "name": "Xã Ea H'leo",
                        "code": 24184,
                        "codename": "xa_ea_hleo",
                        "division_type": "xã",
                        "short_codename": "ea_hleo"
                    },
                    {
                        "name": "Xã Ea Sol",
                        "code": 24187,
                        "codename": "xa_ea_sol",
                        "division_type": "xã",
                        "short_codename": "ea_sol"
                    },
                    {
                        "name": "Xã Ea Ral",
                        "code": 24190,
                        "codename": "xa_ea_ral",
                        "division_type": "xã",
                        "short_codename": "ea_ral"
                    },
                    {
                        "name": "Xã Ea Wy",
                        "code": 24193,
                        "codename": "xa_ea_wy",
                        "division_type": "xã",
                        "short_codename": "ea_wy"
                    },
                    {
                        "name": "Xã Cư A Mung",
                        "code": 24194,
                        "codename": "xa_cu_a_mung",
                        "division_type": "xã",
                        "short_codename": "cu_a_mung"
                    },
                    {
                        "name": "Xã Cư Mốt",
                        "code": 24196,
                        "codename": "xa_cu_mot",
                        "division_type": "xã",
                        "short_codename": "cu_mot"
                    },
                    {
                        "name": "Xã Ea Hiao",
                        "code": 24199,
                        "codename": "xa_ea_hiao",
                        "division_type": "xã",
                        "short_codename": "ea_hiao"
                    },
                    {
                        "name": "Xã Ea Khal",
                        "code": 24202,
                        "codename": "xa_ea_khal",
                        "division_type": "xã",
                        "short_codename": "ea_khal"
                    },
                    {
                        "name": "Xã Dliê Yang",
                        "code": 24205,
                        "codename": "xa_dlie_yang",
                        "division_type": "xã",
                        "short_codename": "dlie_yang"
                    },
                    {
                        "name": "Xã Ea Tir",
                        "code": 24207,
                        "codename": "xa_ea_tir",
                        "division_type": "xã",
                        "short_codename": "ea_tir"
                    },
                    {
                        "name": "Xã Ea Nam",
                        "code": 24208,
                        "codename": "xa_ea_nam",
                        "division_type": "xã",
                        "short_codename": "ea_nam"
                    }
                ]
            },
            {
                "name": "Huyện Ea Súp",
                "code": 646,
                "codename": "huyen_ea_sup",
                "division_type": "huyện",
                "short_codename": "ea_sup",
                "wards": [
                    {
                        "name": "Thị trấn Ea Súp",
                        "code": 24211,
                        "codename": "thi_tran_ea_sup",
                        "division_type": "thị trấn",
                        "short_codename": "ea_sup"
                    },
                    {
                        "name": "Xã Ia Lốp",
                        "code": 24214,
                        "codename": "xa_ia_lop",
                        "division_type": "xã",
                        "short_codename": "ia_lop"
                    },
                    {
                        "name": "Xã Ia JLơi",
                        "code": 24215,
                        "codename": "xa_ia_jloi",
                        "division_type": "xã",
                        "short_codename": "ia_jloi"
                    },
                    {
                        "name": "Xã Ea Rốk",
                        "code": 24217,
                        "codename": "xa_ea_rok",
                        "division_type": "xã",
                        "short_codename": "ea_rok"
                    },
                    {
                        "name": "Xã Ya Tờ Mốt",
                        "code": 24220,
                        "codename": "xa_ya_to_mot",
                        "division_type": "xã",
                        "short_codename": "ya_to_mot"
                    },
                    {
                        "name": "Xã Ia RVê",
                        "code": 24221,
                        "codename": "xa_ia_rve",
                        "division_type": "xã",
                        "short_codename": "ia_rve"
                    },
                    {
                        "name": "Xã Ea Lê",
                        "code": 24223,
                        "codename": "xa_ea_le",
                        "division_type": "xã",
                        "short_codename": "ea_le"
                    },
                    {
                        "name": "Xã Cư KBang",
                        "code": 24226,
                        "codename": "xa_cu_kbang",
                        "division_type": "xã",
                        "short_codename": "cu_kbang"
                    },
                    {
                        "name": "Xã Ea Bung",
                        "code": 24229,
                        "codename": "xa_ea_bung",
                        "division_type": "xã",
                        "short_codename": "ea_bung"
                    },
                    {
                        "name": "Xã Cư M'Lan",
                        "code": 24232,
                        "codename": "xa_cu_mlan",
                        "division_type": "xã",
                        "short_codename": "cu_mlan"
                    }
                ]
            },
            {
                "name": "Huyện Buôn Đôn",
                "code": 647,
                "codename": "huyen_buon_don",
                "division_type": "huyện",
                "short_codename": "buon_don",
                "wards": [
                    {
                        "name": "Xã Krông Na",
                        "code": 24235,
                        "codename": "xa_krong_na",
                        "division_type": "xã",
                        "short_codename": "krong_na"
                    },
                    {
                        "name": "Xã Ea Huar",
                        "code": 24238,
                        "codename": "xa_ea_huar",
                        "division_type": "xã",
                        "short_codename": "ea_huar"
                    },
                    {
                        "name": "Xã Ea Wer",
                        "code": 24241,
                        "codename": "xa_ea_wer",
                        "division_type": "xã",
                        "short_codename": "ea_wer"
                    },
                    {
                        "name": "Xã Tân Hoà",
                        "code": 24244,
                        "codename": "xa_tan_hoa",
                        "division_type": "xã",
                        "short_codename": "tan_hoa"
                    },
                    {
                        "name": "Xã Cuôr KNia",
                        "code": 24247,
                        "codename": "xa_cuor_knia",
                        "division_type": "xã",
                        "short_codename": "cuor_knia"
                    },
                    {
                        "name": "Xã Ea Bar",
                        "code": 24250,
                        "codename": "xa_ea_bar",
                        "division_type": "xã",
                        "short_codename": "ea_bar"
                    },
                    {
                        "name": "Xã Ea Nuôl",
                        "code": 24253,
                        "codename": "xa_ea_nuol",
                        "division_type": "xã",
                        "short_codename": "ea_nuol"
                    }
                ]
            },
            {
                "name": "Huyện Cư M'gar",
                "code": 648,
                "codename": "huyen_cu_mgar",
                "division_type": "huyện",
                "short_codename": "cu_mgar",
                "wards": [
                    {
                        "name": "Thị trấn Ea Pốk",
                        "code": 24256,
                        "codename": "thi_tran_ea_pok",
                        "division_type": "thị trấn",
                        "short_codename": "ea_pok"
                    },
                    {
                        "name": "Thị trấn Quảng Phú",
                        "code": 24259,
                        "codename": "thi_tran_quang_phu",
                        "division_type": "thị trấn",
                        "short_codename": "quang_phu"
                    },
                    {
                        "name": "Xã Quảng Tiến",
                        "code": 24262,
                        "codename": "xa_quang_tien",
                        "division_type": "xã",
                        "short_codename": "quang_tien"
                    },
                    {
                        "name": "Xã Ea Kuêh",
                        "code": 24264,
                        "codename": "xa_ea_kueh",
                        "division_type": "xã",
                        "short_codename": "ea_kueh"
                    },
                    {
                        "name": "Xã Ea Kiết",
                        "code": 24265,
                        "codename": "xa_ea_kiet",
                        "division_type": "xã",
                        "short_codename": "ea_kiet"
                    },
                    {
                        "name": "Xã Ea Tar",
                        "code": 24268,
                        "codename": "xa_ea_tar",
                        "division_type": "xã",
                        "short_codename": "ea_tar"
                    },
                    {
                        "name": "Xã Cư Dliê M'nông",
                        "code": 24271,
                        "codename": "xa_cu_dlie_mnong",
                        "division_type": "xã",
                        "short_codename": "cu_dlie_mnong"
                    },
                    {
                        "name": "Xã Ea H'đinh",
                        "code": 24274,
                        "codename": "xa_ea_hdinh",
                        "division_type": "xã",
                        "short_codename": "ea_hdinh"
                    },
                    {
                        "name": "Xã Ea Tul",
                        "code": 24277,
                        "codename": "xa_ea_tul",
                        "division_type": "xã",
                        "short_codename": "ea_tul"
                    },
                    {
                        "name": "Xã Ea KPam",
                        "code": 24280,
                        "codename": "xa_ea_kpam",
                        "division_type": "xã",
                        "short_codename": "ea_kpam"
                    },
                    {
                        "name": "Xã Ea M'DRóh",
                        "code": 24283,
                        "codename": "xa_ea_mdroh",
                        "division_type": "xã",
                        "short_codename": "ea_mdroh"
                    },
                    {
                        "name": "Xã Quảng Hiệp",
                        "code": 24286,
                        "codename": "xa_quang_hiep",
                        "division_type": "xã",
                        "short_codename": "quang_hiep"
                    },
                    {
                        "name": "Xã Cư M'gar",
                        "code": 24289,
                        "codename": "xa_cu_mgar",
                        "division_type": "xã",
                        "short_codename": "cu_mgar"
                    },
                    {
                        "name": "Xã Ea D'Rơng",
                        "code": 24292,
                        "codename": "xa_ea_drong",
                        "division_type": "xã",
                        "short_codename": "ea_drong"
                    },
                    {
                        "name": "Xã Ea M'nang",
                        "code": 24295,
                        "codename": "xa_ea_mnang",
                        "division_type": "xã",
                        "short_codename": "ea_mnang"
                    },
                    {
                        "name": "Xã Cư Suê",
                        "code": 24298,
                        "codename": "xa_cu_sue",
                        "division_type": "xã",
                        "short_codename": "cu_sue"
                    },
                    {
                        "name": "Xã Cuor Đăng",
                        "code": 24301,
                        "codename": "xa_cuor_dang",
                        "division_type": "xã",
                        "short_codename": "cuor_dang"
                    }
                ]
            },
            {
                "name": "Huyện Krông Búk",
                "code": 649,
                "codename": "huyen_krong_buk",
                "division_type": "huyện",
                "short_codename": "krong_buk",
                "wards": [
                    {
                        "name": "Xã Cư Né",
                        "code": 24307,
                        "codename": "xa_cu_ne",
                        "division_type": "xã",
                        "short_codename": "cu_ne"
                    },
                    {
                        "name": "Xã Chư KBô",
                        "code": 24310,
                        "codename": "xa_chu_kbo",
                        "division_type": "xã",
                        "short_codename": "chu_kbo"
                    },
                    {
                        "name": "Xã Cư Pơng",
                        "code": 24313,
                        "codename": "xa_cu_pong",
                        "division_type": "xã",
                        "short_codename": "cu_pong"
                    },
                    {
                        "name": "Xã Ea Sin",
                        "code": 24314,
                        "codename": "xa_ea_sin",
                        "division_type": "xã",
                        "short_codename": "ea_sin"
                    },
                    {
                        "name": "Xã Pơng Drang",
                        "code": 24316,
                        "codename": "xa_pong_drang",
                        "division_type": "xã",
                        "short_codename": "pong_drang"
                    },
                    {
                        "name": "Xã Tân Lập",
                        "code": 24317,
                        "codename": "xa_tan_lap",
                        "division_type": "xã",
                        "short_codename": "tan_lap"
                    },
                    {
                        "name": "Xã Ea Ngai",
                        "code": 24319,
                        "codename": "xa_ea_ngai",
                        "division_type": "xã",
                        "short_codename": "ea_ngai"
                    }
                ]
            },
            {
                "name": "Huyện Krông Năng",
                "code": 650,
                "codename": "huyen_krong_nang",
                "division_type": "huyện",
                "short_codename": "krong_nang",
                "wards": [
                    {
                        "name": "Thị trấn Krông Năng",
                        "code": 24343,
                        "codename": "thi_tran_krong_nang",
                        "division_type": "thị trấn",
                        "short_codename": "krong_nang"
                    },
                    {
                        "name": "Xã ĐLiê Ya",
                        "code": 24346,
                        "codename": "xa_dlie_ya",
                        "division_type": "xã",
                        "short_codename": "dlie_ya"
                    },
                    {
                        "name": "Xã Ea Tóh",
                        "code": 24349,
                        "codename": "xa_ea_toh",
                        "division_type": "xã",
                        "short_codename": "ea_toh"
                    },
                    {
                        "name": "Xã Ea Tam",
                        "code": 24352,
                        "codename": "xa_ea_tam",
                        "division_type": "xã",
                        "short_codename": "ea_tam"
                    },
                    {
                        "name": "Xã Phú Lộc",
                        "code": 24355,
                        "codename": "xa_phu_loc",
                        "division_type": "xã",
                        "short_codename": "phu_loc"
                    },
                    {
                        "name": "Xã Tam Giang",
                        "code": 24358,
                        "codename": "xa_tam_giang",
                        "division_type": "xã",
                        "short_codename": "tam_giang"
                    },
                    {
                        "name": "Xã Ea Puk",
                        "code": 24359,
                        "codename": "xa_ea_puk",
                        "division_type": "xã",
                        "short_codename": "ea_puk"
                    },
                    {
                        "name": "Xã Ea Dăh",
                        "code": 24360,
                        "codename": "xa_ea_dah",
                        "division_type": "xã",
                        "short_codename": "ea_dah"
                    },
                    {
                        "name": "Xã Ea Hồ",
                        "code": 24361,
                        "codename": "xa_ea_ho",
                        "division_type": "xã",
                        "short_codename": "ea_ho"
                    },
                    {
                        "name": "Xã Phú Xuân",
                        "code": 24364,
                        "codename": "xa_phu_xuan",
                        "division_type": "xã",
                        "short_codename": "phu_xuan"
                    },
                    {
                        "name": "Xã Cư Klông",
                        "code": 24367,
                        "codename": "xa_cu_klong",
                        "division_type": "xã",
                        "short_codename": "cu_klong"
                    },
                    {
                        "name": "Xã Ea Tân",
                        "code": 24370,
                        "codename": "xa_ea_tan",
                        "division_type": "xã",
                        "short_codename": "ea_tan"
                    }
                ]
            },
            {
                "name": "Huyện Ea Kar",
                "code": 651,
                "codename": "huyen_ea_kar",
                "division_type": "huyện",
                "short_codename": "ea_kar",
                "wards": [
                    {
                        "name": "Thị trấn Ea Kar",
                        "code": 24373,
                        "codename": "thi_tran_ea_kar",
                        "division_type": "thị trấn",
                        "short_codename": "ea_kar"
                    },
                    {
                        "name": "Thị trấn Ea Knốp",
                        "code": 24376,
                        "codename": "thi_tran_ea_knop",
                        "division_type": "thị trấn",
                        "short_codename": "ea_knop"
                    },
                    {
                        "name": "Xã Ea Sô",
                        "code": 24379,
                        "codename": "xa_ea_so",
                        "division_type": "xã",
                        "short_codename": "ea_so"
                    },
                    {
                        "name": "Xã Ea Sar",
                        "code": 24380,
                        "codename": "xa_ea_sar",
                        "division_type": "xã",
                        "short_codename": "ea_sar"
                    },
                    {
                        "name": "Xã Xuân Phú",
                        "code": 24382,
                        "codename": "xa_xuan_phu",
                        "division_type": "xã",
                        "short_codename": "xuan_phu"
                    },
                    {
                        "name": "Xã Cư Huê",
                        "code": 24385,
                        "codename": "xa_cu_hue",
                        "division_type": "xã",
                        "short_codename": "cu_hue"
                    },
                    {
                        "name": "Xã Ea Tih",
                        "code": 24388,
                        "codename": "xa_ea_tih",
                        "division_type": "xã",
                        "short_codename": "ea_tih"
                    },
                    {
                        "name": "Xã Ea Đar",
                        "code": 24391,
                        "codename": "xa_ea_dar",
                        "division_type": "xã",
                        "short_codename": "ea_dar"
                    },
                    {
                        "name": "Xã Ea Kmút",
                        "code": 24394,
                        "codename": "xa_ea_kmut",
                        "division_type": "xã",
                        "short_codename": "ea_kmut"
                    },
                    {
                        "name": "Xã Cư Ni",
                        "code": 24397,
                        "codename": "xa_cu_ni",
                        "division_type": "xã",
                        "short_codename": "cu_ni"
                    },
                    {
                        "name": "Xã Ea Păl",
                        "code": 24400,
                        "codename": "xa_ea_pal",
                        "division_type": "xã",
                        "short_codename": "ea_pal"
                    },
                    {
                        "name": "Xã Cư Prông",
                        "code": 24401,
                        "codename": "xa_cu_prong",
                        "division_type": "xã",
                        "short_codename": "cu_prong"
                    },
                    {
                        "name": "Xã Ea Ô",
                        "code": 24403,
                        "codename": "xa_ea_o",
                        "division_type": "xã",
                        "short_codename": "ea_o"
                    },
                    {
                        "name": "Xã Cư ELang",
                        "code": 24404,
                        "codename": "xa_cu_elang",
                        "division_type": "xã",
                        "short_codename": "cu_elang"
                    },
                    {
                        "name": "Xã Cư Bông",
                        "code": 24406,
                        "codename": "xa_cu_bong",
                        "division_type": "xã",
                        "short_codename": "cu_bong"
                    },
                    {
                        "name": "Xã Cư Jang",
                        "code": 24409,
                        "codename": "xa_cu_jang",
                        "division_type": "xã",
                        "short_codename": "cu_jang"
                    }
                ]
            },
            {
                "name": "Huyện M'Đrắk",
                "code": 652,
                "codename": "huyen_mdrak",
                "division_type": "huyện",
                "short_codename": "mdrak",
                "wards": [
                    {
                        "name": "Thị trấn M'Đrắk",
                        "code": 24412,
                        "codename": "thi_tran_mdrak",
                        "division_type": "thị trấn",
                        "short_codename": "mdrak"
                    },
                    {
                        "name": "Xã Cư Prao",
                        "code": 24415,
                        "codename": "xa_cu_prao",
                        "division_type": "xã",
                        "short_codename": "cu_prao"
                    },
                    {
                        "name": "Xã Ea Pil",
                        "code": 24418,
                        "codename": "xa_ea_pil",
                        "division_type": "xã",
                        "short_codename": "ea_pil"
                    },
                    {
                        "name": "Xã Ea Lai",
                        "code": 24421,
                        "codename": "xa_ea_lai",
                        "division_type": "xã",
                        "short_codename": "ea_lai"
                    },
                    {
                        "name": "Xã Ea H'MLay",
                        "code": 24424,
                        "codename": "xa_ea_hmlay",
                        "division_type": "xã",
                        "short_codename": "ea_hmlay"
                    },
                    {
                        "name": "Xã Krông Jing",
                        "code": 24427,
                        "codename": "xa_krong_jing",
                        "division_type": "xã",
                        "short_codename": "krong_jing"
                    },
                    {
                        "name": "Xã Ea M' Doal",
                        "code": 24430,
                        "codename": "xa_ea_m_doal",
                        "division_type": "xã",
                        "short_codename": "ea_m_doal"
                    },
                    {
                        "name": "Xã Ea Riêng",
                        "code": 24433,
                        "codename": "xa_ea_rieng",
                        "division_type": "xã",
                        "short_codename": "ea_rieng"
                    },
                    {
                        "name": "Xã Cư M'ta",
                        "code": 24436,
                        "codename": "xa_cu_mta",
                        "division_type": "xã",
                        "short_codename": "cu_mta"
                    },
                    {
                        "name": "Xã Cư K Róa",
                        "code": 24439,
                        "codename": "xa_cu_k_roa",
                        "division_type": "xã",
                        "short_codename": "cu_k_roa"
                    },
                    {
                        "name": "Xã Krông Á",
                        "code": 24442,
                        "codename": "xa_krong_a",
                        "division_type": "xã",
                        "short_codename": "krong_a"
                    },
                    {
                        "name": "Xã Cư San",
                        "code": 24444,
                        "codename": "xa_cu_san",
                        "division_type": "xã",
                        "short_codename": "cu_san"
                    },
                    {
                        "name": "Xã Ea Trang",
                        "code": 24445,
                        "codename": "xa_ea_trang",
                        "division_type": "xã",
                        "short_codename": "ea_trang"
                    }
                ]
            },
            {
                "name": "Huyện Krông Bông",
                "code": 653,
                "codename": "huyen_krong_bong",
                "division_type": "huyện",
                "short_codename": "krong_bong",
                "wards": [
                    {
                        "name": "Thị trấn Krông Kmar",
                        "code": 24448,
                        "codename": "thi_tran_krong_kmar",
                        "division_type": "thị trấn",
                        "short_codename": "krong_kmar"
                    },
                    {
                        "name": "Xã Dang Kang",
                        "code": 24451,
                        "codename": "xa_dang_kang",
                        "division_type": "xã",
                        "short_codename": "dang_kang"
                    },
                    {
                        "name": "Xã Cư KTy",
                        "code": 24454,
                        "codename": "xa_cu_kty",
                        "division_type": "xã",
                        "short_codename": "cu_kty"
                    },
                    {
                        "name": "Xã Hòa Thành",
                        "code": 24457,
                        "codename": "xa_hoa_thanh",
                        "division_type": "xã",
                        "short_codename": "hoa_thanh"
                    },
                    {
                        "name": "Xã Hòa Tân",
                        "code": 24460,
                        "codename": "xa_hoa_tan",
                        "division_type": "xã",
                        "short_codename": "hoa_tan"
                    },
                    {
                        "name": "Xã Hòa Phong",
                        "code": 24463,
                        "codename": "xa_hoa_phong",
                        "division_type": "xã",
                        "short_codename": "hoa_phong"
                    },
                    {
                        "name": "Xã Hòa Lễ",
                        "code": 24466,
                        "codename": "xa_hoa_le",
                        "division_type": "xã",
                        "short_codename": "hoa_le"
                    },
                    {
                        "name": "Xã Yang Reh",
                        "code": 24469,
                        "codename": "xa_yang_reh",
                        "division_type": "xã",
                        "short_codename": "yang_reh"
                    },
                    {
                        "name": "Xã Ea Trul",
                        "code": 24472,
                        "codename": "xa_ea_trul",
                        "division_type": "xã",
                        "short_codename": "ea_trul"
                    },
                    {
                        "name": "Xã Khuê Ngọc Điền",
                        "code": 24475,
                        "codename": "xa_khue_ngoc_dien",
                        "division_type": "xã",
                        "short_codename": "khue_ngoc_dien"
                    },
                    {
                        "name": "Xã Cư Pui",
                        "code": 24478,
                        "codename": "xa_cu_pui",
                        "division_type": "xã",
                        "short_codename": "cu_pui"
                    },
                    {
                        "name": "Xã Hòa Sơn",
                        "code": 24481,
                        "codename": "xa_hoa_son",
                        "division_type": "xã",
                        "short_codename": "hoa_son"
                    },
                    {
                        "name": "Xã Cư Drăm",
                        "code": 24484,
                        "codename": "xa_cu_dram",
                        "division_type": "xã",
                        "short_codename": "cu_dram"
                    },
                    {
                        "name": "Xã Yang Mao",
                        "code": 24487,
                        "codename": "xa_yang_mao",
                        "division_type": "xã",
                        "short_codename": "yang_mao"
                    }
                ]
            },
            {
                "name": "Huyện Krông Pắc",
                "code": 654,
                "codename": "huyen_krong_pac",
                "division_type": "huyện",
                "short_codename": "krong_pac",
                "wards": [
                    {
                        "name": "Thị trấn Phước An",
                        "code": 24490,
                        "codename": "thi_tran_phuoc_an",
                        "division_type": "thị trấn",
                        "short_codename": "phuoc_an"
                    },
                    {
                        "name": "Xã KRông Búk",
                        "code": 24493,
                        "codename": "xa_krong_buk",
                        "division_type": "xã",
                        "short_codename": "krong_buk"
                    },
                    {
                        "name": "Xã Ea Kly",
                        "code": 24496,
                        "codename": "xa_ea_kly",
                        "division_type": "xã",
                        "short_codename": "ea_kly"
                    },
                    {
                        "name": "Xã Ea Kênh",
                        "code": 24499,
                        "codename": "xa_ea_kenh",
                        "division_type": "xã",
                        "short_codename": "ea_kenh"
                    },
                    {
                        "name": "Xã Ea Phê",
                        "code": 24502,
                        "codename": "xa_ea_phe",
                        "division_type": "xã",
                        "short_codename": "ea_phe"
                    },
                    {
                        "name": "Xã Ea KNuec",
                        "code": 24505,
                        "codename": "xa_ea_knuec",
                        "division_type": "xã",
                        "short_codename": "ea_knuec"
                    },
                    {
                        "name": "Xã Ea Yông",
                        "code": 24508,
                        "codename": "xa_ea_yong",
                        "division_type": "xã",
                        "short_codename": "ea_yong"
                    },
                    {
                        "name": "Xã Hòa An",
                        "code": 24511,
                        "codename": "xa_hoa_an",
                        "division_type": "xã",
                        "short_codename": "hoa_an"
                    },
                    {
                        "name": "Xã Ea Kuăng",
                        "code": 24514,
                        "codename": "xa_ea_kuang",
                        "division_type": "xã",
                        "short_codename": "ea_kuang"
                    },
                    {
                        "name": "Xã Hòa Đông",
                        "code": 24517,
                        "codename": "xa_hoa_dong",
                        "division_type": "xã",
                        "short_codename": "hoa_dong"
                    },
                    {
                        "name": "Xã Ea Hiu",
                        "code": 24520,
                        "codename": "xa_ea_hiu",
                        "division_type": "xã",
                        "short_codename": "ea_hiu"
                    },
                    {
                        "name": "Xã Hòa Tiến",
                        "code": 24523,
                        "codename": "xa_hoa_tien",
                        "division_type": "xã",
                        "short_codename": "hoa_tien"
                    },
                    {
                        "name": "Xã Tân Tiến",
                        "code": 24526,
                        "codename": "xa_tan_tien",
                        "division_type": "xã",
                        "short_codename": "tan_tien"
                    },
                    {
                        "name": "Xã Vụ Bổn",
                        "code": 24529,
                        "codename": "xa_vu_bon",
                        "division_type": "xã",
                        "short_codename": "vu_bon"
                    },
                    {
                        "name": "Xã Ea Uy",
                        "code": 24532,
                        "codename": "xa_ea_uy",
                        "division_type": "xã",
                        "short_codename": "ea_uy"
                    },
                    {
                        "name": "Xã Ea Yiêng",
                        "code": 24535,
                        "codename": "xa_ea_yieng",
                        "division_type": "xã",
                        "short_codename": "ea_yieng"
                    }
                ]
            },
            {
                "name": "Huyện Krông A Na",
                "code": 655,
                "codename": "huyen_krong_a_na",
                "division_type": "huyện",
                "short_codename": "krong_a_na",
                "wards": [
                    {
                        "name": "Thị trấn Buôn Trấp",
                        "code": 24538,
                        "codename": "thi_tran_buon_trap",
                        "division_type": "thị trấn",
                        "short_codename": "buon_trap"
                    },
                    {
                        "name": "Xã Dray Sáp",
                        "code": 24556,
                        "codename": "xa_dray_sap",
                        "division_type": "xã",
                        "short_codename": "dray_sap"
                    },
                    {
                        "name": "Xã Ea Na",
                        "code": 24559,
                        "codename": "xa_ea_na",
                        "division_type": "xã",
                        "short_codename": "ea_na"
                    },
                    {
                        "name": "Xã Ea Bông",
                        "code": 24565,
                        "codename": "xa_ea_bong",
                        "division_type": "xã",
                        "short_codename": "ea_bong"
                    },
                    {
                        "name": "Xã Băng A Drênh",
                        "code": 24568,
                        "codename": "xa_bang_a_drenh",
                        "division_type": "xã",
                        "short_codename": "bang_a_drenh"
                    },
                    {
                        "name": "Xã Dur KMăl",
                        "code": 24571,
                        "codename": "xa_dur_kmal",
                        "division_type": "xã",
                        "short_codename": "dur_kmal"
                    },
                    {
                        "name": "Xã Bình Hòa",
                        "code": 24574,
                        "codename": "xa_binh_hoa",
                        "division_type": "xã",
                        "short_codename": "binh_hoa"
                    },
                    {
                        "name": "Xã Quảng Điền",
                        "code": 24577,
                        "codename": "xa_quang_dien",
                        "division_type": "xã",
                        "short_codename": "quang_dien"
                    }
                ]
            },
            {
                "name": "Huyện Lắk",
                "code": 656,
                "codename": "huyen_lak",
                "division_type": "huyện",
                "short_codename": "lak",
                "wards": [
                    {
                        "name": "Thị trấn Liên Sơn",
                        "code": 24580,
                        "codename": "thi_tran_lien_son",
                        "division_type": "thị trấn",
                        "short_codename": "lien_son"
                    },
                    {
                        "name": "Xã Yang Tao",
                        "code": 24583,
                        "codename": "xa_yang_tao",
                        "division_type": "xã",
                        "short_codename": "yang_tao"
                    },
                    {
                        "name": "Xã Bông Krang",
                        "code": 24586,
                        "codename": "xa_bong_krang",
                        "division_type": "xã",
                        "short_codename": "bong_krang"
                    },
                    {
                        "name": "Xã Đắk Liêng",
                        "code": 24589,
                        "codename": "xa_dak_lieng",
                        "division_type": "xã",
                        "short_codename": "dak_lieng"
                    },
                    {
                        "name": "Xã Buôn Triết",
                        "code": 24592,
                        "codename": "xa_buon_triet",
                        "division_type": "xã",
                        "short_codename": "buon_triet"
                    },
                    {
                        "name": "Xã Buôn Tría",
                        "code": 24595,
                        "codename": "xa_buon_tria",
                        "division_type": "xã",
                        "short_codename": "buon_tria"
                    },
                    {
                        "name": "Xã Đắk Phơi",
                        "code": 24598,
                        "codename": "xa_dak_phoi",
                        "division_type": "xã",
                        "short_codename": "dak_phoi"
                    },
                    {
                        "name": "Xã Đắk Nuê",
                        "code": 24601,
                        "codename": "xa_dak_nue",
                        "division_type": "xã",
                        "short_codename": "dak_nue"
                    },
                    {
                        "name": "Xã Krông Nô",
                        "code": 24604,
                        "codename": "xa_krong_no",
                        "division_type": "xã",
                        "short_codename": "krong_no"
                    },
                    {
                        "name": "Xã Nam Ka",
                        "code": 24607,
                        "codename": "xa_nam_ka",
                        "division_type": "xã",
                        "short_codename": "nam_ka"
                    },
                    {
                        "name": "Xã Ea R'Bin",
                        "code": 24610,
                        "codename": "xa_ea_rbin",
                        "division_type": "xã",
                        "short_codename": "ea_rbin"
                    }
                ]
            },
            {
                "name": "Huyện Cư Kuin",
                "code": 657,
                "codename": "huyen_cu_kuin",
                "division_type": "huyện",
                "short_codename": "cu_kuin",
                "wards": [
                    {
                        "name": "Xã Ea Ning",
                        "code": 24540,
                        "codename": "xa_ea_ning",
                        "division_type": "xã",
                        "short_codename": "ea_ning"
                    },
                    {
                        "name": "Xã Cư Ê Wi",
                        "code": 24541,
                        "codename": "xa_cu_e_wi",
                        "division_type": "xã",
                        "short_codename": "cu_e_wi"
                    },
                    {
                        "name": "Xã Ea Ktur",
                        "code": 24544,
                        "codename": "xa_ea_ktur",
                        "division_type": "xã",
                        "short_codename": "ea_ktur"
                    },
                    {
                        "name": "Xã Ea Tiêu",
                        "code": 24547,
                        "codename": "xa_ea_tieu",
                        "division_type": "xã",
                        "short_codename": "ea_tieu"
                    },
                    {
                        "name": "Xã Ea BHốk",
                        "code": 24550,
                        "codename": "xa_ea_bhok",
                        "division_type": "xã",
                        "short_codename": "ea_bhok"
                    },
                    {
                        "name": "Xã Ea Hu",
                        "code": 24553,
                        "codename": "xa_ea_hu",
                        "division_type": "xã",
                        "short_codename": "ea_hu"
                    },
                    {
                        "name": "Xã Dray Bhăng",
                        "code": 24561,
                        "codename": "xa_dray_bhang",
                        "division_type": "xã",
                        "short_codename": "dray_bhang"
                    },
                    {
                        "name": "Xã Hòa Hiệp",
                        "code": 24562,
                        "codename": "xa_hoa_hiep",
                        "division_type": "xã",
                        "short_codename": "hoa_hiep"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Đắk Nông",
        "code": 67,
        "codename": "tinh_dak_nong",
        "division_type": "tỉnh",
        "phone_code": 261,
        "districts": [
            {
                "name": "Thành phố Gia Nghĩa",
                "code": 660,
                "codename": "thanh_pho_gia_nghia",
                "division_type": "thành phố",
                "short_codename": "gia_nghia",
                "wards": [
                    {
                        "name": "Phường Nghĩa Đức",
                        "code": 24611,
                        "codename": "phuong_nghia_duc",
                        "division_type": "phường",
                        "short_codename": "nghia_duc"
                    },
                    {
                        "name": "Phường Nghĩa Thành",
                        "code": 24612,
                        "codename": "phuong_nghia_thanh",
                        "division_type": "phường",
                        "short_codename": "nghia_thanh"
                    },
                    {
                        "name": "Phường Nghĩa Phú",
                        "code": 24614,
                        "codename": "phuong_nghia_phu",
                        "division_type": "phường",
                        "short_codename": "nghia_phu"
                    },
                    {
                        "name": "Phường Nghĩa Tân",
                        "code": 24615,
                        "codename": "phuong_nghia_tan",
                        "division_type": "phường",
                        "short_codename": "nghia_tan"
                    },
                    {
                        "name": "Phường Nghĩa Trung",
                        "code": 24617,
                        "codename": "phuong_nghia_trung",
                        "division_type": "phường",
                        "short_codename": "nghia_trung"
                    },
                    {
                        "name": "Xã Đăk R'Moan",
                        "code": 24618,
                        "codename": "xa_dak_rmoan",
                        "division_type": "xã",
                        "short_codename": "dak_rmoan"
                    },
                    {
                        "name": "Phường Quảng Thành",
                        "code": 24619,
                        "codename": "phuong_quang_thanh",
                        "division_type": "phường",
                        "short_codename": "quang_thanh"
                    },
                    {
                        "name": "Xã Đắk Nia",
                        "code": 24628,
                        "codename": "xa_dak_nia",
                        "division_type": "xã",
                        "short_codename": "dak_nia"
                    }
                ]
            },
            {
                "name": "Huyện Đăk Glong",
                "code": 661,
                "codename": "huyen_dak_glong",
                "division_type": "huyện",
                "short_codename": "dak_glong",
                "wards": [
                    {
                        "name": "Xã Quảng Sơn",
                        "code": 24616,
                        "codename": "xa_quang_son",
                        "division_type": "xã",
                        "short_codename": "quang_son"
                    },
                    {
                        "name": "Xã Quảng Hoà",
                        "code": 24620,
                        "codename": "xa_quang_hoa",
                        "division_type": "xã",
                        "short_codename": "quang_hoa"
                    },
                    {
                        "name": "Xã Đắk Ha",
                        "code": 24622,
                        "codename": "xa_dak_ha",
                        "division_type": "xã",
                        "short_codename": "dak_ha"
                    },
                    {
                        "name": "Xã Đắk R'Măng",
                        "code": 24625,
                        "codename": "xa_dak_rmang",
                        "division_type": "xã",
                        "short_codename": "dak_rmang"
                    },
                    {
                        "name": "Xã Quảng Khê",
                        "code": 24631,
                        "codename": "xa_quang_khe",
                        "division_type": "xã",
                        "short_codename": "quang_khe"
                    },
                    {
                        "name": "Xã Đắk Plao",
                        "code": 24634,
                        "codename": "xa_dak_plao",
                        "division_type": "xã",
                        "short_codename": "dak_plao"
                    },
                    {
                        "name": "Xã Đắk Som",
                        "code": 24637,
                        "codename": "xa_dak_som",
                        "division_type": "xã",
                        "short_codename": "dak_som"
                    }
                ]
            },
            {
                "name": "Huyện Cư Jút",
                "code": 662,
                "codename": "huyen_cu_jut",
                "division_type": "huyện",
                "short_codename": "cu_jut",
                "wards": [
                    {
                        "name": "Thị trấn Ea T'Ling",
                        "code": 24640,
                        "codename": "thi_tran_ea_tling",
                        "division_type": "thị trấn",
                        "short_codename": "ea_tling"
                    },
                    {
                        "name": "Xã Đắk Wil",
                        "code": 24643,
                        "codename": "xa_dak_wil",
                        "division_type": "xã",
                        "short_codename": "dak_wil"
                    },
                    {
                        "name": "Xã Ea Pô",
                        "code": 24646,
                        "codename": "xa_ea_po",
                        "division_type": "xã",
                        "short_codename": "ea_po"
                    },
                    {
                        "name": "Xã Nam Dong",
                        "code": 24649,
                        "codename": "xa_nam_dong",
                        "division_type": "xã",
                        "short_codename": "nam_dong"
                    },
                    {
                        "name": "Xã Đắk DRông",
                        "code": 24652,
                        "codename": "xa_dak_drong",
                        "division_type": "xã",
                        "short_codename": "dak_drong"
                    },
                    {
                        "name": "Xã Tâm Thắng",
                        "code": 24655,
                        "codename": "xa_tam_thang",
                        "division_type": "xã",
                        "short_codename": "tam_thang"
                    },
                    {
                        "name": "Xã Cư Knia",
                        "code": 24658,
                        "codename": "xa_cu_knia",
                        "division_type": "xã",
                        "short_codename": "cu_knia"
                    },
                    {
                        "name": "Xã Trúc Sơn",
                        "code": 24661,
                        "codename": "xa_truc_son",
                        "division_type": "xã",
                        "short_codename": "truc_son"
                    }
                ]
            },
            {
                "name": "Huyện Đắk Mil",
                "code": 663,
                "codename": "huyen_dak_mil",
                "division_type": "huyện",
                "short_codename": "dak_mil",
                "wards": [
                    {
                        "name": "Thị trấn Đắk Mil",
                        "code": 24664,
                        "codename": "thi_tran_dak_mil",
                        "division_type": "thị trấn",
                        "short_codename": "dak_mil"
                    },
                    {
                        "name": "Xã Đắk Lao",
                        "code": 24667,
                        "codename": "xa_dak_lao",
                        "division_type": "xã",
                        "short_codename": "dak_lao"
                    },
                    {
                        "name": "Xã Đắk R'La",
                        "code": 24670,
                        "codename": "xa_dak_rla",
                        "division_type": "xã",
                        "short_codename": "dak_rla"
                    },
                    {
                        "name": "Xã Đắk Gằn",
                        "code": 24673,
                        "codename": "xa_dak_gan",
                        "division_type": "xã",
                        "short_codename": "dak_gan"
                    },
                    {
                        "name": "Xã Đức Mạnh",
                        "code": 24676,
                        "codename": "xa_duc_manh",
                        "division_type": "xã",
                        "short_codename": "duc_manh"
                    },
                    {
                        "name": "Xã Đắk N'Drót",
                        "code": 24677,
                        "codename": "xa_dak_ndrot",
                        "division_type": "xã",
                        "short_codename": "dak_ndrot"
                    },
                    {
                        "name": "Xã Long Sơn",
                        "code": 24678,
                        "codename": "xa_long_son",
                        "division_type": "xã",
                        "short_codename": "long_son"
                    },
                    {
                        "name": "Xã Đắk Sắk",
                        "code": 24679,
                        "codename": "xa_dak_sak",
                        "division_type": "xã",
                        "short_codename": "dak_sak"
                    },
                    {
                        "name": "Xã Thuận An",
                        "code": 24682,
                        "codename": "xa_thuan_an",
                        "division_type": "xã",
                        "short_codename": "thuan_an"
                    },
                    {
                        "name": "Xã Đức Minh",
                        "code": 24685,
                        "codename": "xa_duc_minh",
                        "division_type": "xã",
                        "short_codename": "duc_minh"
                    }
                ]
            },
            {
                "name": "Huyện Krông Nô",
                "code": 664,
                "codename": "huyen_krong_no",
                "division_type": "huyện",
                "short_codename": "krong_no",
                "wards": [
                    {
                        "name": "Thị trấn Đắk Mâm",
                        "code": 24688,
                        "codename": "thi_tran_dak_mam",
                        "division_type": "thị trấn",
                        "short_codename": "dak_mam"
                    },
                    {
                        "name": "Xã Đắk Sôr",
                        "code": 24691,
                        "codename": "xa_dak_sor",
                        "division_type": "xã",
                        "short_codename": "dak_sor"
                    },
                    {
                        "name": "Xã Nam Xuân",
                        "code": 24692,
                        "codename": "xa_nam_xuan",
                        "division_type": "xã",
                        "short_codename": "nam_xuan"
                    },
                    {
                        "name": "Xã Buôn Choah",
                        "code": 24694,
                        "codename": "xa_buon_choah",
                        "division_type": "xã",
                        "short_codename": "buon_choah"
                    },
                    {
                        "name": "Xã Nam Đà",
                        "code": 24697,
                        "codename": "xa_nam_da",
                        "division_type": "xã",
                        "short_codename": "nam_da"
                    },
                    {
                        "name": "Xã Tân Thành",
                        "code": 24699,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Xã Đắk Drô",
                        "code": 24700,
                        "codename": "xa_dak_dro",
                        "division_type": "xã",
                        "short_codename": "dak_dro"
                    },
                    {
                        "name": "Xã Nâm Nung",
                        "code": 24703,
                        "codename": "xa_nam_nung",
                        "division_type": "xã",
                        "short_codename": "nam_nung"
                    },
                    {
                        "name": "Xã Đức Xuyên",
                        "code": 24706,
                        "codename": "xa_duc_xuyen",
                        "division_type": "xã",
                        "short_codename": "duc_xuyen"
                    },
                    {
                        "name": "Xã Đắk Nang",
                        "code": 24709,
                        "codename": "xa_dak_nang",
                        "division_type": "xã",
                        "short_codename": "dak_nang"
                    },
                    {
                        "name": "Xã Quảng Phú",
                        "code": 24712,
                        "codename": "xa_quang_phu",
                        "division_type": "xã",
                        "short_codename": "quang_phu"
                    },
                    {
                        "name": "Xã Nâm N'Đir",
                        "code": 24715,
                        "codename": "xa_nam_ndir",
                        "division_type": "xã",
                        "short_codename": "nam_ndir"
                    }
                ]
            },
            {
                "name": "Huyện Đắk Song",
                "code": 665,
                "codename": "huyen_dak_song",
                "division_type": "huyện",
                "short_codename": "dak_song",
                "wards": [
                    {
                        "name": "Thị trấn Đức An",
                        "code": 24717,
                        "codename": "thi_tran_duc_an",
                        "division_type": "thị trấn",
                        "short_codename": "duc_an"
                    },
                    {
                        "name": "Xã Đắk Môl",
                        "code": 24718,
                        "codename": "xa_dak_mol",
                        "division_type": "xã",
                        "short_codename": "dak_mol"
                    },
                    {
                        "name": "Xã Đắk Hòa",
                        "code": 24719,
                        "codename": "xa_dak_hoa",
                        "division_type": "xã",
                        "short_codename": "dak_hoa"
                    },
                    {
                        "name": "Xã Nam Bình",
                        "code": 24721,
                        "codename": "xa_nam_binh",
                        "division_type": "xã",
                        "short_codename": "nam_binh"
                    },
                    {
                        "name": "Xã Thuận Hà",
                        "code": 24722,
                        "codename": "xa_thuan_ha",
                        "division_type": "xã",
                        "short_codename": "thuan_ha"
                    },
                    {
                        "name": "Xã Thuận Hạnh",
                        "code": 24724,
                        "codename": "xa_thuan_hanh",
                        "division_type": "xã",
                        "short_codename": "thuan_hanh"
                    },
                    {
                        "name": "Xã Đắk N'Dung",
                        "code": 24727,
                        "codename": "xa_dak_ndung",
                        "division_type": "xã",
                        "short_codename": "dak_ndung"
                    },
                    {
                        "name": "Xã Nâm N'Jang",
                        "code": 24728,
                        "codename": "xa_nam_njang",
                        "division_type": "xã",
                        "short_codename": "nam_njang"
                    },
                    {
                        "name": "Xã Trường Xuân",
                        "code": 24730,
                        "codename": "xa_truong_xuan",
                        "division_type": "xã",
                        "short_codename": "truong_xuan"
                    }
                ]
            },
            {
                "name": "Huyện Đắk R'Lấp",
                "code": 666,
                "codename": "huyen_dak_rlap",
                "division_type": "huyện",
                "short_codename": "dak_rlap",
                "wards": [
                    {
                        "name": "Thị trấn Kiến Đức",
                        "code": 24733,
                        "codename": "thi_tran_kien_duc",
                        "division_type": "thị trấn",
                        "short_codename": "kien_duc"
                    },
                    {
                        "name": "Xã Quảng Tín",
                        "code": 24745,
                        "codename": "xa_quang_tin",
                        "division_type": "xã",
                        "short_codename": "quang_tin"
                    },
                    {
                        "name": "Xã Đắk Wer",
                        "code": 24750,
                        "codename": "xa_dak_wer",
                        "division_type": "xã",
                        "short_codename": "dak_wer"
                    },
                    {
                        "name": "Xã Nhân Cơ",
                        "code": 24751,
                        "codename": "xa_nhan_co",
                        "division_type": "xã",
                        "short_codename": "nhan_co"
                    },
                    {
                        "name": "Xã Kiến Thành",
                        "code": 24754,
                        "codename": "xa_kien_thanh",
                        "division_type": "xã",
                        "short_codename": "kien_thanh"
                    },
                    {
                        "name": "Xã Nghĩa Thắng",
                        "code": 24756,
                        "codename": "xa_nghia_thang",
                        "division_type": "xã",
                        "short_codename": "nghia_thang"
                    },
                    {
                        "name": "Xã Đạo Nghĩa",
                        "code": 24757,
                        "codename": "xa_dao_nghia",
                        "division_type": "xã",
                        "short_codename": "dao_nghia"
                    },
                    {
                        "name": "Xã Đắk Sin",
                        "code": 24760,
                        "codename": "xa_dak_sin",
                        "division_type": "xã",
                        "short_codename": "dak_sin"
                    },
                    {
                        "name": "Xã Hưng Bình",
                        "code": 24761,
                        "codename": "xa_hung_binh",
                        "division_type": "xã",
                        "short_codename": "hung_binh"
                    },
                    {
                        "name": "Xã Đắk Ru",
                        "code": 24763,
                        "codename": "xa_dak_ru",
                        "division_type": "xã",
                        "short_codename": "dak_ru"
                    },
                    {
                        "name": "Xã Nhân Đạo",
                        "code": 24766,
                        "codename": "xa_nhan_dao",
                        "division_type": "xã",
                        "short_codename": "nhan_dao"
                    }
                ]
            },
            {
                "name": "Huyện Tuy Đức",
                "code": 667,
                "codename": "huyen_tuy_duc",
                "division_type": "huyện",
                "short_codename": "tuy_duc",
                "wards": [
                    {
                        "name": "Xã Quảng Trực",
                        "code": 24736,
                        "codename": "xa_quang_truc",
                        "division_type": "xã",
                        "short_codename": "quang_truc"
                    },
                    {
                        "name": "Xã Đắk Búk So",
                        "code": 24739,
                        "codename": "xa_dak_buk_so",
                        "division_type": "xã",
                        "short_codename": "dak_buk_so"
                    },
                    {
                        "name": "Xã Quảng Tâm",
                        "code": 24740,
                        "codename": "xa_quang_tam",
                        "division_type": "xã",
                        "short_codename": "quang_tam"
                    },
                    {
                        "name": "Xã Đắk R'Tíh",
                        "code": 24742,
                        "codename": "xa_dak_rtih",
                        "division_type": "xã",
                        "short_codename": "dak_rtih"
                    },
                    {
                        "name": "Xã Đắk Ngo",
                        "code": 24746,
                        "codename": "xa_dak_ngo",
                        "division_type": "xã",
                        "short_codename": "dak_ngo"
                    },
                    {
                        "name": "Xã Quảng Tân",
                        "code": 24748,
                        "codename": "xa_quang_tan",
                        "division_type": "xã",
                        "short_codename": "quang_tan"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Lâm Đồng",
        "code": 68,
        "codename": "tinh_lam_dong",
        "division_type": "tỉnh",
        "phone_code": 263,
        "districts": [
            {
                "name": "Thành phố Đà Lạt",
                "code": 672,
                "codename": "thanh_pho_da_lat",
                "division_type": "thành phố",
                "short_codename": "da_lat",
                "wards": [
                    {
                        "name": "Phường 7",
                        "code": 24769,
                        "codename": "phuong_7",
                        "division_type": "phường",
                        "short_codename": "phuong_7"
                    },
                    {
                        "name": "Phường 8",
                        "code": 24772,
                        "codename": "phuong_8",
                        "division_type": "phường",
                        "short_codename": "phuong_8"
                    },
                    {
                        "name": "Phường 12",
                        "code": 24775,
                        "codename": "phuong_12",
                        "division_type": "phường",
                        "short_codename": "phuong_12"
                    },
                    {
                        "name": "Phường 9",
                        "code": 24778,
                        "codename": "phuong_9",
                        "division_type": "phường",
                        "short_codename": "phuong_9"
                    },
                    {
                        "name": "Phường 2",
                        "code": 24781,
                        "codename": "phuong_2",
                        "division_type": "phường",
                        "short_codename": "phuong_2"
                    },
                    {
                        "name": "Phường 1",
                        "code": 24784,
                        "codename": "phuong_1",
                        "division_type": "phường",
                        "short_codename": "phuong_1"
                    },
                    {
                        "name": "Phường 6",
                        "code": 24787,
                        "codename": "phuong_6",
                        "division_type": "phường",
                        "short_codename": "phuong_6"
                    },
                    {
                        "name": "Phường 5",
                        "code": 24790,
                        "codename": "phuong_5",
                        "division_type": "phường",
                        "short_codename": "phuong_5"
                    },
                    {
                        "name": "Phường 4",
                        "code": 24793,
                        "codename": "phuong_4",
                        "division_type": "phường",
                        "short_codename": "phuong_4"
                    },
                    {
                        "name": "Phường 10",
                        "code": 24796,
                        "codename": "phuong_10",
                        "division_type": "phường",
                        "short_codename": "phuong_10"
                    },
                    {
                        "name": "Phường 11",
                        "code": 24799,
                        "codename": "phuong_11",
                        "division_type": "phường",
                        "short_codename": "phuong_11"
                    },
                    {
                        "name": "Phường 3",
                        "code": 24802,
                        "codename": "phuong_3",
                        "division_type": "phường",
                        "short_codename": "phuong_3"
                    },
                    {
                        "name": "Xã Xuân Thọ",
                        "code": 24805,
                        "codename": "xa_xuan_tho",
                        "division_type": "xã",
                        "short_codename": "xuan_tho"
                    },
                    {
                        "name": "Xã Tà Nung",
                        "code": 24808,
                        "codename": "xa_ta_nung",
                        "division_type": "xã",
                        "short_codename": "ta_nung"
                    },
                    {
                        "name": "Xã Trạm Hành",
                        "code": 24810,
                        "codename": "xa_tram_hanh",
                        "division_type": "xã",
                        "short_codename": "tram_hanh"
                    },
                    {
                        "name": "Xã Xuân Trường",
                        "code": 24811,
                        "codename": "xa_xuan_truong",
                        "division_type": "xã",
                        "short_codename": "xuan_truong"
                    }
                ]
            },
            {
                "name": "Thành phố Bảo Lộc",
                "code": 673,
                "codename": "thanh_pho_bao_loc",
                "division_type": "thành phố",
                "short_codename": "bao_loc",
                "wards": [
                    {
                        "name": "Phường Lộc Phát",
                        "code": 24814,
                        "codename": "phuong_loc_phat",
                        "division_type": "phường",
                        "short_codename": "loc_phat"
                    },
                    {
                        "name": "Phường Lộc Tiến",
                        "code": 24817,
                        "codename": "phuong_loc_tien",
                        "division_type": "phường",
                        "short_codename": "loc_tien"
                    },
                    {
                        "name": "Phường 2",
                        "code": 24820,
                        "codename": "phuong_2",
                        "division_type": "phường",
                        "short_codename": "phuong_2"
                    },
                    {
                        "name": "Phường 1",
                        "code": 24823,
                        "codename": "phuong_1",
                        "division_type": "phường",
                        "short_codename": "phuong_1"
                    },
                    {
                        "name": "Phường B'lao",
                        "code": 24826,
                        "codename": "phuong_blao",
                        "division_type": "phường",
                        "short_codename": "blao"
                    },
                    {
                        "name": "Phường Lộc Sơn",
                        "code": 24829,
                        "codename": "phuong_loc_son",
                        "division_type": "phường",
                        "short_codename": "loc_son"
                    },
                    {
                        "name": "Xã Đạm Bri",
                        "code": 24832,
                        "codename": "xa_dam_bri",
                        "division_type": "xã",
                        "short_codename": "dam_bri"
                    },
                    {
                        "name": "Xã Lộc Thanh",
                        "code": 24835,
                        "codename": "xa_loc_thanh",
                        "division_type": "xã",
                        "short_codename": "loc_thanh"
                    },
                    {
                        "name": "Xã Lộc Nga",
                        "code": 24838,
                        "codename": "xa_loc_nga",
                        "division_type": "xã",
                        "short_codename": "loc_nga"
                    },
                    {
                        "name": "Xã Lộc Châu",
                        "code": 24841,
                        "codename": "xa_loc_chau",
                        "division_type": "xã",
                        "short_codename": "loc_chau"
                    },
                    {
                        "name": "Xã Đại Lào",
                        "code": 24844,
                        "codename": "xa_dai_lao",
                        "division_type": "xã",
                        "short_codename": "dai_lao"
                    }
                ]
            },
            {
                "name": "Huyện Đam Rông",
                "code": 674,
                "codename": "huyen_dam_rong",
                "division_type": "huyện",
                "short_codename": "dam_rong",
                "wards": [
                    {
                        "name": "Xã Đạ Tông",
                        "code": 24853,
                        "codename": "xa_da_tong",
                        "division_type": "xã",
                        "short_codename": "da_tong"
                    },
                    {
                        "name": "Xã Đạ Long",
                        "code": 24856,
                        "codename": "xa_da_long",
                        "division_type": "xã",
                        "short_codename": "da_long"
                    },
                    {
                        "name": "Xã Đạ M' Rong",
                        "code": 24859,
                        "codename": "xa_da_m_rong",
                        "division_type": "xã",
                        "short_codename": "da_m_rong"
                    },
                    {
                        "name": "Xã Liêng Srônh",
                        "code": 24874,
                        "codename": "xa_lieng_sronh",
                        "division_type": "xã",
                        "short_codename": "lieng_sronh"
                    },
                    {
                        "name": "Xã Đạ Rsal",
                        "code": 24875,
                        "codename": "xa_da_rsal",
                        "division_type": "xã",
                        "short_codename": "da_rsal"
                    },
                    {
                        "name": "Xã Rô Men",
                        "code": 24877,
                        "codename": "xa_ro_men",
                        "division_type": "xã",
                        "short_codename": "ro_men"
                    },
                    {
                        "name": "Xã Phi Liêng",
                        "code": 24886,
                        "codename": "xa_phi_lieng",
                        "division_type": "xã",
                        "short_codename": "phi_lieng"
                    },
                    {
                        "name": "Xã Đạ K' Nàng",
                        "code": 24889,
                        "codename": "xa_da_k_nang",
                        "division_type": "xã",
                        "short_codename": "da_k_nang"
                    }
                ]
            },
            {
                "name": "Huyện Lạc Dương",
                "code": 675,
                "codename": "huyen_lac_duong",
                "division_type": "huyện",
                "short_codename": "lac_duong",
                "wards": [
                    {
                        "name": "Thị trấn Lạc Dương",
                        "code": 24846,
                        "codename": "thi_tran_lac_duong",
                        "division_type": "thị trấn",
                        "short_codename": "lac_duong"
                    },
                    {
                        "name": "Xã Đạ Chais",
                        "code": 24847,
                        "codename": "xa_da_chais",
                        "division_type": "xã",
                        "short_codename": "da_chais"
                    },
                    {
                        "name": "Xã Đạ Nhim",
                        "code": 24848,
                        "codename": "xa_da_nhim",
                        "division_type": "xã",
                        "short_codename": "da_nhim"
                    },
                    {
                        "name": "Xã Đưng KNớ",
                        "code": 24850,
                        "codename": "xa_dung_kno",
                        "division_type": "xã",
                        "short_codename": "dung_kno"
                    },
                    {
                        "name": "Xã Lát",
                        "code": 24862,
                        "codename": "xa_lat",
                        "division_type": "xã",
                        "short_codename": "lat"
                    },
                    {
                        "name": "Xã Đạ Sar",
                        "code": 24865,
                        "codename": "xa_da_sar",
                        "division_type": "xã",
                        "short_codename": "da_sar"
                    }
                ]
            },
            {
                "name": "Huyện Lâm Hà",
                "code": 676,
                "codename": "huyen_lam_ha",
                "division_type": "huyện",
                "short_codename": "lam_ha",
                "wards": [
                    {
                        "name": "Thị trấn Nam Ban",
                        "code": 24868,
                        "codename": "thi_tran_nam_ban",
                        "division_type": "thị trấn",
                        "short_codename": "nam_ban"
                    },
                    {
                        "name": "Thị trấn Đinh Văn",
                        "code": 24871,
                        "codename": "thi_tran_dinh_van",
                        "division_type": "thị trấn",
                        "short_codename": "dinh_van"
                    },
                    {
                        "name": "Xã Phú Sơn",
                        "code": 24880,
                        "codename": "xa_phu_son",
                        "division_type": "xã",
                        "short_codename": "phu_son"
                    },
                    {
                        "name": "Xã Phi Tô",
                        "code": 24883,
                        "codename": "xa_phi_to",
                        "division_type": "xã",
                        "short_codename": "phi_to"
                    },
                    {
                        "name": "Xã Mê Linh",
                        "code": 24892,
                        "codename": "xa_me_linh",
                        "division_type": "xã",
                        "short_codename": "me_linh"
                    },
                    {
                        "name": "Xã Đạ Đờn",
                        "code": 24895,
                        "codename": "xa_da_don",
                        "division_type": "xã",
                        "short_codename": "da_don"
                    },
                    {
                        "name": "Xã Phúc Thọ",
                        "code": 24898,
                        "codename": "xa_phuc_tho",
                        "division_type": "xã",
                        "short_codename": "phuc_tho"
                    },
                    {
                        "name": "Xã Đông Thanh",
                        "code": 24901,
                        "codename": "xa_dong_thanh",
                        "division_type": "xã",
                        "short_codename": "dong_thanh"
                    },
                    {
                        "name": "Xã Gia Lâm",
                        "code": 24904,
                        "codename": "xa_gia_lam",
                        "division_type": "xã",
                        "short_codename": "gia_lam"
                    },
                    {
                        "name": "Xã Tân Thanh",
                        "code": 24907,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Xã Tân Văn",
                        "code": 24910,
                        "codename": "xa_tan_van",
                        "division_type": "xã",
                        "short_codename": "tan_van"
                    },
                    {
                        "name": "Xã Hoài Đức",
                        "code": 24913,
                        "codename": "xa_hoai_duc",
                        "division_type": "xã",
                        "short_codename": "hoai_duc"
                    },
                    {
                        "name": "Xã Tân Hà",
                        "code": 24916,
                        "codename": "xa_tan_ha",
                        "division_type": "xã",
                        "short_codename": "tan_ha"
                    },
                    {
                        "name": "Xã Liên Hà",
                        "code": 24919,
                        "codename": "xa_lien_ha",
                        "division_type": "xã",
                        "short_codename": "lien_ha"
                    },
                    {
                        "name": "Xã Đan Phượng",
                        "code": 24922,
                        "codename": "xa_dan_phuong",
                        "division_type": "xã",
                        "short_codename": "dan_phuong"
                    },
                    {
                        "name": "Xã Nam Hà",
                        "code": 24925,
                        "codename": "xa_nam_ha",
                        "division_type": "xã",
                        "short_codename": "nam_ha"
                    }
                ]
            },
            {
                "name": "Huyện Đơn Dương",
                "code": 677,
                "codename": "huyen_don_duong",
                "division_type": "huyện",
                "short_codename": "don_duong",
                "wards": [
                    {
                        "name": "Thị trấn D'Ran",
                        "code": 24928,
                        "codename": "thi_tran_dran",
                        "division_type": "thị trấn",
                        "short_codename": "dran"
                    },
                    {
                        "name": "Thị trấn Thạnh Mỹ",
                        "code": 24931,
                        "codename": "thi_tran_thanh_my",
                        "division_type": "thị trấn",
                        "short_codename": "thanh_my"
                    },
                    {
                        "name": "Xã Lạc Xuân",
                        "code": 24934,
                        "codename": "xa_lac_xuan",
                        "division_type": "xã",
                        "short_codename": "lac_xuan"
                    },
                    {
                        "name": "Xã Đạ Ròn",
                        "code": 24937,
                        "codename": "xa_da_ron",
                        "division_type": "xã",
                        "short_codename": "da_ron"
                    },
                    {
                        "name": "Xã Lạc Lâm",
                        "code": 24940,
                        "codename": "xa_lac_lam",
                        "division_type": "xã",
                        "short_codename": "lac_lam"
                    },
                    {
                        "name": "Xã Ka Đô",
                        "code": 24943,
                        "codename": "xa_ka_do",
                        "division_type": "xã",
                        "short_codename": "ka_do"
                    },
                    {
                        "name": "Xã Quảng Lập",
                        "code": 24946,
                        "codename": "xa_quang_lap",
                        "division_type": "xã",
                        "short_codename": "quang_lap"
                    },
                    {
                        "name": "Xã Ka Đơn",
                        "code": 24949,
                        "codename": "xa_ka_don",
                        "division_type": "xã",
                        "short_codename": "ka_don"
                    },
                    {
                        "name": "Xã Tu Tra",
                        "code": 24952,
                        "codename": "xa_tu_tra",
                        "division_type": "xã",
                        "short_codename": "tu_tra"
                    },
                    {
                        "name": "Xã Pró",
                        "code": 24955,
                        "codename": "xa_pro",
                        "division_type": "xã",
                        "short_codename": "pro"
                    }
                ]
            },
            {
                "name": "Huyện Đức Trọng",
                "code": 678,
                "codename": "huyen_duc_trong",
                "division_type": "huyện",
                "short_codename": "duc_trong",
                "wards": [
                    {
                        "name": "Thị trấn Liên Nghĩa",
                        "code": 24958,
                        "codename": "thi_tran_lien_nghia",
                        "division_type": "thị trấn",
                        "short_codename": "lien_nghia"
                    },
                    {
                        "name": "Xã Hiệp An",
                        "code": 24961,
                        "codename": "xa_hiep_an",
                        "division_type": "xã",
                        "short_codename": "hiep_an"
                    },
                    {
                        "name": "Xã Liên Hiệp",
                        "code": 24964,
                        "codename": "xa_lien_hiep",
                        "division_type": "xã",
                        "short_codename": "lien_hiep"
                    },
                    {
                        "name": "Xã Hiệp Thạnh",
                        "code": 24967,
                        "codename": "xa_hiep_thanh",
                        "division_type": "xã",
                        "short_codename": "hiep_thanh"
                    },
                    {
                        "name": "Xã Bình Thạnh",
                        "code": 24970,
                        "codename": "xa_binh_thanh",
                        "division_type": "xã",
                        "short_codename": "binh_thanh"
                    },
                    {
                        "name": "Xã N'Thol Hạ",
                        "code": 24973,
                        "codename": "xa_nthol_ha",
                        "division_type": "xã",
                        "short_codename": "nthol_ha"
                    },
                    {
                        "name": "Xã Tân Hội",
                        "code": 24976,
                        "codename": "xa_tan_hoi",
                        "division_type": "xã",
                        "short_codename": "tan_hoi"
                    },
                    {
                        "name": "Xã Tân Thành",
                        "code": 24979,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Xã Phú Hội",
                        "code": 24982,
                        "codename": "xa_phu_hoi",
                        "division_type": "xã",
                        "short_codename": "phu_hoi"
                    },
                    {
                        "name": "Xã Ninh Gia",
                        "code": 24985,
                        "codename": "xa_ninh_gia",
                        "division_type": "xã",
                        "short_codename": "ninh_gia"
                    },
                    {
                        "name": "Xã Tà Năng",
                        "code": 24988,
                        "codename": "xa_ta_nang",
                        "division_type": "xã",
                        "short_codename": "ta_nang"
                    },
                    {
                        "name": "Xã Đa Quyn",
                        "code": 24989,
                        "codename": "xa_da_quyn",
                        "division_type": "xã",
                        "short_codename": "da_quyn"
                    },
                    {
                        "name": "Xã Tà Hine",
                        "code": 24991,
                        "codename": "xa_ta_hine",
                        "division_type": "xã",
                        "short_codename": "ta_hine"
                    },
                    {
                        "name": "Xã Đà Loan",
                        "code": 24994,
                        "codename": "xa_da_loan",
                        "division_type": "xã",
                        "short_codename": "da_loan"
                    },
                    {
                        "name": "Xã Ninh Loan",
                        "code": 24997,
                        "codename": "xa_ninh_loan",
                        "division_type": "xã",
                        "short_codename": "ninh_loan"
                    }
                ]
            },
            {
                "name": "Huyện Di Linh",
                "code": 679,
                "codename": "huyen_di_linh",
                "division_type": "huyện",
                "short_codename": "di_linh",
                "wards": [
                    {
                        "name": "Thị trấn Di Linh",
                        "code": 25000,
                        "codename": "thi_tran_di_linh",
                        "division_type": "thị trấn",
                        "short_codename": "di_linh"
                    },
                    {
                        "name": "Xã Đinh Trang Thượng",
                        "code": 25003,
                        "codename": "xa_dinh_trang_thuong",
                        "division_type": "xã",
                        "short_codename": "dinh_trang_thuong"
                    },
                    {
                        "name": "Xã Tân Thượng",
                        "code": 25006,
                        "codename": "xa_tan_thuong",
                        "division_type": "xã",
                        "short_codename": "tan_thuong"
                    },
                    {
                        "name": "Xã Tân Lâm",
                        "code": 25007,
                        "codename": "xa_tan_lam",
                        "division_type": "xã",
                        "short_codename": "tan_lam"
                    },
                    {
                        "name": "Xã Tân Châu",
                        "code": 25009,
                        "codename": "xa_tan_chau",
                        "division_type": "xã",
                        "short_codename": "tan_chau"
                    },
                    {
                        "name": "Xã Tân Nghĩa",
                        "code": 25012,
                        "codename": "xa_tan_nghia",
                        "division_type": "xã",
                        "short_codename": "tan_nghia"
                    },
                    {
                        "name": "Xã Gia Hiệp",
                        "code": 25015,
                        "codename": "xa_gia_hiep",
                        "division_type": "xã",
                        "short_codename": "gia_hiep"
                    },
                    {
                        "name": "Xã Đinh Lạc",
                        "code": 25018,
                        "codename": "xa_dinh_lac",
                        "division_type": "xã",
                        "short_codename": "dinh_lac"
                    },
                    {
                        "name": "Xã Tam Bố",
                        "code": 25021,
                        "codename": "xa_tam_bo",
                        "division_type": "xã",
                        "short_codename": "tam_bo"
                    },
                    {
                        "name": "Xã Đinh Trang Hòa",
                        "code": 25024,
                        "codename": "xa_dinh_trang_hoa",
                        "division_type": "xã",
                        "short_codename": "dinh_trang_hoa"
                    },
                    {
                        "name": "Xã Liên Đầm",
                        "code": 25027,
                        "codename": "xa_lien_dam",
                        "division_type": "xã",
                        "short_codename": "lien_dam"
                    },
                    {
                        "name": "Xã Gung Ré",
                        "code": 25030,
                        "codename": "xa_gung_re",
                        "division_type": "xã",
                        "short_codename": "gung_re"
                    },
                    {
                        "name": "Xã Bảo Thuận",
                        "code": 25033,
                        "codename": "xa_bao_thuan",
                        "division_type": "xã",
                        "short_codename": "bao_thuan"
                    },
                    {
                        "name": "Xã Hòa Ninh",
                        "code": 25036,
                        "codename": "xa_hoa_ninh",
                        "division_type": "xã",
                        "short_codename": "hoa_ninh"
                    },
                    {
                        "name": "Xã Hòa Trung",
                        "code": 25039,
                        "codename": "xa_hoa_trung",
                        "division_type": "xã",
                        "short_codename": "hoa_trung"
                    },
                    {
                        "name": "Xã Hòa Nam",
                        "code": 25042,
                        "codename": "xa_hoa_nam",
                        "division_type": "xã",
                        "short_codename": "hoa_nam"
                    },
                    {
                        "name": "Xã Hòa Bắc",
                        "code": 25045,
                        "codename": "xa_hoa_bac",
                        "division_type": "xã",
                        "short_codename": "hoa_bac"
                    },
                    {
                        "name": "Xã Sơn Điền",
                        "code": 25048,
                        "codename": "xa_son_dien",
                        "division_type": "xã",
                        "short_codename": "son_dien"
                    },
                    {
                        "name": "Xã Gia Bắc",
                        "code": 25051,
                        "codename": "xa_gia_bac",
                        "division_type": "xã",
                        "short_codename": "gia_bac"
                    }
                ]
            },
            {
                "name": "Huyện Bảo Lâm",
                "code": 680,
                "codename": "huyen_bao_lam",
                "division_type": "huyện",
                "short_codename": "bao_lam",
                "wards": [
                    {
                        "name": "Thị trấn Lộc Thắng",
                        "code": 25054,
                        "codename": "thi_tran_loc_thang",
                        "division_type": "thị trấn",
                        "short_codename": "loc_thang"
                    },
                    {
                        "name": "Xã Lộc Bảo",
                        "code": 25057,
                        "codename": "xa_loc_bao",
                        "division_type": "xã",
                        "short_codename": "loc_bao"
                    },
                    {
                        "name": "Xã Lộc Lâm",
                        "code": 25060,
                        "codename": "xa_loc_lam",
                        "division_type": "xã",
                        "short_codename": "loc_lam"
                    },
                    {
                        "name": "Xã Lộc Phú",
                        "code": 25063,
                        "codename": "xa_loc_phu",
                        "division_type": "xã",
                        "short_codename": "loc_phu"
                    },
                    {
                        "name": "Xã Lộc Bắc",
                        "code": 25066,
                        "codename": "xa_loc_bac",
                        "division_type": "xã",
                        "short_codename": "loc_bac"
                    },
                    {
                        "name": "Xã B' Lá",
                        "code": 25069,
                        "codename": "xa_b_la",
                        "division_type": "xã",
                        "short_codename": "b_la"
                    },
                    {
                        "name": "Xã Lộc Ngãi",
                        "code": 25072,
                        "codename": "xa_loc_ngai",
                        "division_type": "xã",
                        "short_codename": "loc_ngai"
                    },
                    {
                        "name": "Xã Lộc Quảng",
                        "code": 25075,
                        "codename": "xa_loc_quang",
                        "division_type": "xã",
                        "short_codename": "loc_quang"
                    },
                    {
                        "name": "Xã Lộc Tân",
                        "code": 25078,
                        "codename": "xa_loc_tan",
                        "division_type": "xã",
                        "short_codename": "loc_tan"
                    },
                    {
                        "name": "Xã Lộc Đức",
                        "code": 25081,
                        "codename": "xa_loc_duc",
                        "division_type": "xã",
                        "short_codename": "loc_duc"
                    },
                    {
                        "name": "Xã Lộc An",
                        "code": 25084,
                        "codename": "xa_loc_an",
                        "division_type": "xã",
                        "short_codename": "loc_an"
                    },
                    {
                        "name": "Xã Tân Lạc",
                        "code": 25087,
                        "codename": "xa_tan_lac",
                        "division_type": "xã",
                        "short_codename": "tan_lac"
                    },
                    {
                        "name": "Xã Lộc Thành",
                        "code": 25090,
                        "codename": "xa_loc_thanh",
                        "division_type": "xã",
                        "short_codename": "loc_thanh"
                    },
                    {
                        "name": "Xã Lộc Nam",
                        "code": 25093,
                        "codename": "xa_loc_nam",
                        "division_type": "xã",
                        "short_codename": "loc_nam"
                    }
                ]
            },
            {
                "name": "Huyện Đạ Huoai",
                "code": 681,
                "codename": "huyen_da_huoai",
                "division_type": "huyện",
                "short_codename": "da_huoai",
                "wards": [
                    {
                        "name": "Thị trấn Đạ M'ri",
                        "code": 25096,
                        "codename": "thi_tran_da_mri",
                        "division_type": "thị trấn",
                        "short_codename": "da_mri"
                    },
                    {
                        "name": "Thị trấn Ma Đa Guôi",
                        "code": 25099,
                        "codename": "thi_tran_ma_da_guoi",
                        "division_type": "thị trấn",
                        "short_codename": "ma_da_guoi"
                    },
                    {
                        "name": "Xã Hà Lâm",
                        "code": 25105,
                        "codename": "xa_ha_lam",
                        "division_type": "xã",
                        "short_codename": "ha_lam"
                    },
                    {
                        "name": "Xã Đạ Tồn",
                        "code": 25108,
                        "codename": "xa_da_ton",
                        "division_type": "xã",
                        "short_codename": "da_ton"
                    },
                    {
                        "name": "Xã Đạ Oai",
                        "code": 25111,
                        "codename": "xa_da_oai",
                        "division_type": "xã",
                        "short_codename": "da_oai"
                    },
                    {
                        "name": "Xã Đạ Ploa",
                        "code": 25114,
                        "codename": "xa_da_ploa",
                        "division_type": "xã",
                        "short_codename": "da_ploa"
                    },
                    {
                        "name": "Xã Ma Đa Guôi",
                        "code": 25117,
                        "codename": "xa_ma_da_guoi",
                        "division_type": "xã",
                        "short_codename": "xa_ma_da_guoi"
                    },
                    {
                        "name": "Xã Đoàn Kết",
                        "code": 25120,
                        "codename": "xa_doan_ket",
                        "division_type": "xã",
                        "short_codename": "doan_ket"
                    },
                    {
                        "name": "Xã Phước Lộc",
                        "code": 25123,
                        "codename": "xa_phuoc_loc",
                        "division_type": "xã",
                        "short_codename": "phuoc_loc"
                    }
                ]
            },
            {
                "name": "Huyện Đạ Tẻh",
                "code": 682,
                "codename": "huyen_da_teh",
                "division_type": "huyện",
                "short_codename": "da_teh",
                "wards": [
                    {
                        "name": "Thị trấn Đạ Tẻh",
                        "code": 25126,
                        "codename": "thi_tran_da_teh",
                        "division_type": "thị trấn",
                        "short_codename": "da_teh"
                    },
                    {
                        "name": "Xã An Nhơn",
                        "code": 25129,
                        "codename": "xa_an_nhon",
                        "division_type": "xã",
                        "short_codename": "an_nhon"
                    },
                    {
                        "name": "Xã Quốc Oai",
                        "code": 25132,
                        "codename": "xa_quoc_oai",
                        "division_type": "xã",
                        "short_codename": "quoc_oai"
                    },
                    {
                        "name": "Xã Mỹ Đức",
                        "code": 25135,
                        "codename": "xa_my_duc",
                        "division_type": "xã",
                        "short_codename": "my_duc"
                    },
                    {
                        "name": "Xã Quảng Trị",
                        "code": 25138,
                        "codename": "xa_quang_tri",
                        "division_type": "xã",
                        "short_codename": "quang_tri"
                    },
                    {
                        "name": "Xã Đạ Lây",
                        "code": 25141,
                        "codename": "xa_da_lay",
                        "division_type": "xã",
                        "short_codename": "da_lay"
                    },
                    {
                        "name": "Xã Triệu Hải",
                        "code": 25147,
                        "codename": "xa_trieu_hai",
                        "division_type": "xã",
                        "short_codename": "trieu_hai"
                    },
                    {
                        "name": "Xã Đạ Kho",
                        "code": 25153,
                        "codename": "xa_da_kho",
                        "division_type": "xã",
                        "short_codename": "da_kho"
                    },
                    {
                        "name": "Xã Đạ Pal",
                        "code": 25156,
                        "codename": "xa_da_pal",
                        "division_type": "xã",
                        "short_codename": "da_pal"
                    }
                ]
            },
            {
                "name": "Huyện Cát Tiên",
                "code": 683,
                "codename": "huyen_cat_tien",
                "division_type": "huyện",
                "short_codename": "cat_tien",
                "wards": [
                    {
                        "name": "Thị trấn Cát Tiên",
                        "code": 25159,
                        "codename": "thi_tran_cat_tien",
                        "division_type": "thị trấn",
                        "short_codename": "cat_tien"
                    },
                    {
                        "name": "Xã Tiên Hoàng",
                        "code": 25162,
                        "codename": "xa_tien_hoang",
                        "division_type": "xã",
                        "short_codename": "tien_hoang"
                    },
                    {
                        "name": "Xã Phước Cát 2",
                        "code": 25165,
                        "codename": "xa_phuoc_cat_2",
                        "division_type": "xã",
                        "short_codename": "phuoc_cat_2"
                    },
                    {
                        "name": "Xã Gia Viễn",
                        "code": 25168,
                        "codename": "xa_gia_vien",
                        "division_type": "xã",
                        "short_codename": "gia_vien"
                    },
                    {
                        "name": "Xã Nam Ninh",
                        "code": 25171,
                        "codename": "xa_nam_ninh",
                        "division_type": "xã",
                        "short_codename": "nam_ninh"
                    },
                    {
                        "name": "Thị trấn Phước Cát",
                        "code": 25180,
                        "codename": "thi_tran_phuoc_cat",
                        "division_type": "thị trấn",
                        "short_codename": "phuoc_cat"
                    },
                    {
                        "name": "Xã Đức Phổ",
                        "code": 25183,
                        "codename": "xa_duc_pho",
                        "division_type": "xã",
                        "short_codename": "duc_pho"
                    },
                    {
                        "name": "Xã Quảng Ngãi",
                        "code": 25189,
                        "codename": "xa_quang_ngai",
                        "division_type": "xã",
                        "short_codename": "quang_ngai"
                    },
                    {
                        "name": "Xã Đồng Nai Thượng",
                        "code": 25192,
                        "codename": "xa_dong_nai_thuong",
                        "division_type": "xã",
                        "short_codename": "dong_nai_thuong"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Bình Phước",
        "code": 70,
        "codename": "tinh_binh_phuoc",
        "division_type": "tỉnh",
        "phone_code": 271,
        "districts": [
            {
                "name": "Thị xã Phước Long",
                "code": 688,
                "codename": "thi_xa_phuoc_long",
                "division_type": "thị xã",
                "short_codename": "phuoc_long",
                "wards": [
                    {
                        "name": "Phường Thác Mơ",
                        "code": 25216,
                        "codename": "phuong_thac_mo",
                        "division_type": "phường",
                        "short_codename": "thac_mo"
                    },
                    {
                        "name": "Phường Long Thủy",
                        "code": 25217,
                        "codename": "phuong_long_thuy",
                        "division_type": "phường",
                        "short_codename": "long_thuy"
                    },
                    {
                        "name": "Phường Phước Bình",
                        "code": 25219,
                        "codename": "phuong_phuoc_binh",
                        "division_type": "phường",
                        "short_codename": "phuoc_binh"
                    },
                    {
                        "name": "Phường Long Phước",
                        "code": 25220,
                        "codename": "phuong_long_phuoc",
                        "division_type": "phường",
                        "short_codename": "long_phuoc"
                    },
                    {
                        "name": "Phường Sơn Giang",
                        "code": 25237,
                        "codename": "phuong_son_giang",
                        "division_type": "phường",
                        "short_codename": "son_giang"
                    },
                    {
                        "name": "Xã Long Giang",
                        "code": 25245,
                        "codename": "xa_long_giang",
                        "division_type": "xã",
                        "short_codename": "long_giang"
                    },
                    {
                        "name": "Xã Phước Tín",
                        "code": 25249,
                        "codename": "xa_phuoc_tin",
                        "division_type": "xã",
                        "short_codename": "phuoc_tin"
                    }
                ]
            },
            {
                "name": "Thành phố Đồng Xoài",
                "code": 689,
                "codename": "thanh_pho_dong_xoai",
                "division_type": "thành phố",
                "short_codename": "dong_xoai",
                "wards": [
                    {
                        "name": "Phường Tân Phú",
                        "code": 25195,
                        "codename": "phuong_tan_phu",
                        "division_type": "phường",
                        "short_codename": "tan_phu"
                    },
                    {
                        "name": "Phường Tân Đồng",
                        "code": 25198,
                        "codename": "phuong_tan_dong",
                        "division_type": "phường",
                        "short_codename": "tan_dong"
                    },
                    {
                        "name": "Phường Tân Bình",
                        "code": 25201,
                        "codename": "phuong_tan_binh",
                        "division_type": "phường",
                        "short_codename": "tan_binh"
                    },
                    {
                        "name": "Phường Tân Xuân",
                        "code": 25204,
                        "codename": "phuong_tan_xuan",
                        "division_type": "phường",
                        "short_codename": "tan_xuan"
                    },
                    {
                        "name": "Phường Tân Thiện",
                        "code": 25205,
                        "codename": "phuong_tan_thien",
                        "division_type": "phường",
                        "short_codename": "tan_thien"
                    },
                    {
                        "name": "Xã Tân Thành",
                        "code": 25207,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Phường Tiến Thành",
                        "code": 25210,
                        "codename": "phuong_tien_thanh",
                        "division_type": "phường",
                        "short_codename": "tien_thanh"
                    },
                    {
                        "name": "Xã Tiến Hưng",
                        "code": 25213,
                        "codename": "xa_tien_hung",
                        "division_type": "xã",
                        "short_codename": "tien_hung"
                    }
                ]
            },
            {
                "name": "Thị xã Bình Long",
                "code": 690,
                "codename": "thi_xa_binh_long",
                "division_type": "thị xã",
                "short_codename": "binh_long",
                "wards": [
                    {
                        "name": "Phường Hưng Chiến",
                        "code": 25320,
                        "codename": "phuong_hung_chien",
                        "division_type": "phường",
                        "short_codename": "hung_chien"
                    },
                    {
                        "name": "Phường An Lộc",
                        "code": 25324,
                        "codename": "phuong_an_loc",
                        "division_type": "phường",
                        "short_codename": "an_loc"
                    },
                    {
                        "name": "Phường Phú Thịnh",
                        "code": 25325,
                        "codename": "phuong_phu_thinh",
                        "division_type": "phường",
                        "short_codename": "phu_thinh"
                    },
                    {
                        "name": "Phường Phú Đức",
                        "code": 25326,
                        "codename": "phuong_phu_duc",
                        "division_type": "phường",
                        "short_codename": "phu_duc"
                    },
                    {
                        "name": "Xã Thanh Lương",
                        "code": 25333,
                        "codename": "xa_thanh_luong",
                        "division_type": "xã",
                        "short_codename": "thanh_luong"
                    },
                    {
                        "name": "Xã Thanh Phú",
                        "code": 25336,
                        "codename": "xa_thanh_phu",
                        "division_type": "xã",
                        "short_codename": "thanh_phu"
                    }
                ]
            },
            {
                "name": "Huyện Bù Gia Mập",
                "code": 691,
                "codename": "huyen_bu_gia_map",
                "division_type": "huyện",
                "short_codename": "bu_gia_map",
                "wards": [
                    {
                        "name": "Xã Bù Gia Mập",
                        "code": 25222,
                        "codename": "xa_bu_gia_map",
                        "division_type": "xã",
                        "short_codename": "bu_gia_map"
                    },
                    {
                        "name": "Xã Đak Ơ",
                        "code": 25225,
                        "codename": "xa_dak_o",
                        "division_type": "xã",
                        "short_codename": "dak_o"
                    },
                    {
                        "name": "Xã Đức Hạnh",
                        "code": 25228,
                        "codename": "xa_duc_hanh",
                        "division_type": "xã",
                        "short_codename": "duc_hanh"
                    },
                    {
                        "name": "Xã Phú Văn",
                        "code": 25229,
                        "codename": "xa_phu_van",
                        "division_type": "xã",
                        "short_codename": "phu_van"
                    },
                    {
                        "name": "Xã Đa Kia",
                        "code": 25231,
                        "codename": "xa_da_kia",
                        "division_type": "xã",
                        "short_codename": "da_kia"
                    },
                    {
                        "name": "Xã Phước Minh",
                        "code": 25232,
                        "codename": "xa_phuoc_minh",
                        "division_type": "xã",
                        "short_codename": "phuoc_minh"
                    },
                    {
                        "name": "Xã Bình Thắng",
                        "code": 25234,
                        "codename": "xa_binh_thang",
                        "division_type": "xã",
                        "short_codename": "binh_thang"
                    },
                    {
                        "name": "Xã Phú Nghĩa",
                        "code": 25267,
                        "codename": "xa_phu_nghia",
                        "division_type": "xã",
                        "short_codename": "phu_nghia"
                    }
                ]
            },
            {
                "name": "Huyện Lộc Ninh",
                "code": 692,
                "codename": "huyen_loc_ninh",
                "division_type": "huyện",
                "short_codename": "loc_ninh",
                "wards": [
                    {
                        "name": "Thị trấn Lộc Ninh",
                        "code": 25270,
                        "codename": "thi_tran_loc_ninh",
                        "division_type": "thị trấn",
                        "short_codename": "loc_ninh"
                    },
                    {
                        "name": "Xã Lộc Hòa",
                        "code": 25273,
                        "codename": "xa_loc_hoa",
                        "division_type": "xã",
                        "short_codename": "loc_hoa"
                    },
                    {
                        "name": "Xã Lộc An",
                        "code": 25276,
                        "codename": "xa_loc_an",
                        "division_type": "xã",
                        "short_codename": "loc_an"
                    },
                    {
                        "name": "Xã Lộc Tấn",
                        "code": 25279,
                        "codename": "xa_loc_tan",
                        "division_type": "xã",
                        "short_codename": "loc_tan"
                    },
                    {
                        "name": "Xã Lộc Thạnh",
                        "code": 25280,
                        "codename": "xa_loc_thanh",
                        "division_type": "xã",
                        "short_codename": "xa_loc_thanh"
                    },
                    {
                        "name": "Xã Lộc Hiệp",
                        "code": 25282,
                        "codename": "xa_loc_hiep",
                        "division_type": "xã",
                        "short_codename": "loc_hiep"
                    },
                    {
                        "name": "Xã Lộc Thiện",
                        "code": 25285,
                        "codename": "xa_loc_thien",
                        "division_type": "xã",
                        "short_codename": "loc_thien"
                    },
                    {
                        "name": "Xã Lộc Thuận",
                        "code": 25288,
                        "codename": "xa_loc_thuan",
                        "division_type": "xã",
                        "short_codename": "loc_thuan"
                    },
                    {
                        "name": "Xã Lộc Quang",
                        "code": 25291,
                        "codename": "xa_loc_quang",
                        "division_type": "xã",
                        "short_codename": "loc_quang"
                    },
                    {
                        "name": "Xã Lộc Phú",
                        "code": 25292,
                        "codename": "xa_loc_phu",
                        "division_type": "xã",
                        "short_codename": "loc_phu"
                    },
                    {
                        "name": "Xã Lộc Thành",
                        "code": 25294,
                        "codename": "xa_loc_thanh",
                        "division_type": "xã",
                        "short_codename": "xa_loc_thanh"
                    },
                    {
                        "name": "Xã Lộc Thái",
                        "code": 25297,
                        "codename": "xa_loc_thai",
                        "division_type": "xã",
                        "short_codename": "loc_thai"
                    },
                    {
                        "name": "Xã Lộc Điền",
                        "code": 25300,
                        "codename": "xa_loc_dien",
                        "division_type": "xã",
                        "short_codename": "loc_dien"
                    },
                    {
                        "name": "Xã Lộc Hưng",
                        "code": 25303,
                        "codename": "xa_loc_hung",
                        "division_type": "xã",
                        "short_codename": "loc_hung"
                    },
                    {
                        "name": "Xã Lộc Thịnh",
                        "code": 25305,
                        "codename": "xa_loc_thinh",
                        "division_type": "xã",
                        "short_codename": "loc_thinh"
                    },
                    {
                        "name": "Xã Lộc Khánh",
                        "code": 25306,
                        "codename": "xa_loc_khanh",
                        "division_type": "xã",
                        "short_codename": "loc_khanh"
                    }
                ]
            },
            {
                "name": "Huyện Bù Đốp",
                "code": 693,
                "codename": "huyen_bu_dop",
                "division_type": "huyện",
                "short_codename": "bu_dop",
                "wards": [
                    {
                        "name": "Thị trấn Thanh Bình",
                        "code": 25308,
                        "codename": "thi_tran_thanh_binh",
                        "division_type": "thị trấn",
                        "short_codename": "thanh_binh"
                    },
                    {
                        "name": "Xã Hưng Phước",
                        "code": 25309,
                        "codename": "xa_hung_phuoc",
                        "division_type": "xã",
                        "short_codename": "hung_phuoc"
                    },
                    {
                        "name": "Xã Phước Thiện",
                        "code": 25310,
                        "codename": "xa_phuoc_thien",
                        "division_type": "xã",
                        "short_codename": "phuoc_thien"
                    },
                    {
                        "name": "Xã Thiện Hưng",
                        "code": 25312,
                        "codename": "xa_thien_hung",
                        "division_type": "xã",
                        "short_codename": "thien_hung"
                    },
                    {
                        "name": "Xã Thanh Hòa",
                        "code": 25315,
                        "codename": "xa_thanh_hoa",
                        "division_type": "xã",
                        "short_codename": "thanh_hoa"
                    },
                    {
                        "name": "Xã Tân Thành",
                        "code": 25318,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Xã Tân Tiến",
                        "code": 25321,
                        "codename": "xa_tan_tien",
                        "division_type": "xã",
                        "short_codename": "tan_tien"
                    }
                ]
            },
            {
                "name": "Huyện Hớn Quản",
                "code": 694,
                "codename": "huyen_hon_quan",
                "division_type": "huyện",
                "short_codename": "hon_quan",
                "wards": [
                    {
                        "name": "Xã Thanh An",
                        "code": 25327,
                        "codename": "xa_thanh_an",
                        "division_type": "xã",
                        "short_codename": "thanh_an"
                    },
                    {
                        "name": "Xã An Khương",
                        "code": 25330,
                        "codename": "xa_an_khuong",
                        "division_type": "xã",
                        "short_codename": "an_khuong"
                    },
                    {
                        "name": "Xã An Phú",
                        "code": 25339,
                        "codename": "xa_an_phu",
                        "division_type": "xã",
                        "short_codename": "an_phu"
                    },
                    {
                        "name": "Xã Tân Lợi",
                        "code": 25342,
                        "codename": "xa_tan_loi",
                        "division_type": "xã",
                        "short_codename": "tan_loi"
                    },
                    {
                        "name": "Xã Tân Hưng",
                        "code": 25345,
                        "codename": "xa_tan_hung",
                        "division_type": "xã",
                        "short_codename": "tan_hung"
                    },
                    {
                        "name": "Xã Minh Đức",
                        "code": 25348,
                        "codename": "xa_minh_duc",
                        "division_type": "xã",
                        "short_codename": "minh_duc"
                    },
                    {
                        "name": "Xã Minh Tâm",
                        "code": 25349,
                        "codename": "xa_minh_tam",
                        "division_type": "xã",
                        "short_codename": "minh_tam"
                    },
                    {
                        "name": "Xã Phước An",
                        "code": 25351,
                        "codename": "xa_phuoc_an",
                        "division_type": "xã",
                        "short_codename": "phuoc_an"
                    },
                    {
                        "name": "Xã Thanh Bình",
                        "code": 25354,
                        "codename": "xa_thanh_binh",
                        "division_type": "xã",
                        "short_codename": "thanh_binh"
                    },
                    {
                        "name": "Thị trấn Tân Khai",
                        "code": 25357,
                        "codename": "thi_tran_tan_khai",
                        "division_type": "thị trấn",
                        "short_codename": "tan_khai"
                    },
                    {
                        "name": "Xã Đồng Nơ",
                        "code": 25360,
                        "codename": "xa_dong_no",
                        "division_type": "xã",
                        "short_codename": "dong_no"
                    },
                    {
                        "name": "Xã Tân Hiệp",
                        "code": 25361,
                        "codename": "xa_tan_hiep",
                        "division_type": "xã",
                        "short_codename": "tan_hiep"
                    },
                    {
                        "name": "Xã Tân Quan",
                        "code": 25438,
                        "codename": "xa_tan_quan",
                        "division_type": "xã",
                        "short_codename": "tan_quan"
                    }
                ]
            },
            {
                "name": "Huyện Đồng Phú",
                "code": 695,
                "codename": "huyen_dong_phu",
                "division_type": "huyện",
                "short_codename": "dong_phu",
                "wards": [
                    {
                        "name": "Thị trấn Tân Phú",
                        "code": 25363,
                        "codename": "thi_tran_tan_phu",
                        "division_type": "thị trấn",
                        "short_codename": "tan_phu"
                    },
                    {
                        "name": "Xã Thuận Lợi",
                        "code": 25366,
                        "codename": "xa_thuan_loi",
                        "division_type": "xã",
                        "short_codename": "thuan_loi"
                    },
                    {
                        "name": "Xã Đồng Tâm",
                        "code": 25369,
                        "codename": "xa_dong_tam",
                        "division_type": "xã",
                        "short_codename": "dong_tam"
                    },
                    {
                        "name": "Xã Tân Phước",
                        "code": 25372,
                        "codename": "xa_tan_phuoc",
                        "division_type": "xã",
                        "short_codename": "tan_phuoc"
                    },
                    {
                        "name": "Xã Tân Hưng",
                        "code": 25375,
                        "codename": "xa_tan_hung",
                        "division_type": "xã",
                        "short_codename": "tan_hung"
                    },
                    {
                        "name": "Xã Tân Lợi",
                        "code": 25378,
                        "codename": "xa_tan_loi",
                        "division_type": "xã",
                        "short_codename": "tan_loi"
                    },
                    {
                        "name": "Xã Tân Lập",
                        "code": 25381,
                        "codename": "xa_tan_lap",
                        "division_type": "xã",
                        "short_codename": "tan_lap"
                    },
                    {
                        "name": "Xã Tân Hòa",
                        "code": 25384,
                        "codename": "xa_tan_hoa",
                        "division_type": "xã",
                        "short_codename": "tan_hoa"
                    },
                    {
                        "name": "Xã Thuận Phú",
                        "code": 25387,
                        "codename": "xa_thuan_phu",
                        "division_type": "xã",
                        "short_codename": "thuan_phu"
                    },
                    {
                        "name": "Xã Đồng Tiến",
                        "code": 25390,
                        "codename": "xa_dong_tien",
                        "division_type": "xã",
                        "short_codename": "dong_tien"
                    },
                    {
                        "name": "Xã Tân Tiến",
                        "code": 25393,
                        "codename": "xa_tan_tien",
                        "division_type": "xã",
                        "short_codename": "tan_tien"
                    }
                ]
            },
            {
                "name": "Huyện Bù Đăng",
                "code": 696,
                "codename": "huyen_bu_dang",
                "division_type": "huyện",
                "short_codename": "bu_dang",
                "wards": [
                    {
                        "name": "Thị trấn Đức Phong",
                        "code": 25396,
                        "codename": "thi_tran_duc_phong",
                        "division_type": "thị trấn",
                        "short_codename": "duc_phong"
                    },
                    {
                        "name": "Xã Đường 10",
                        "code": 25398,
                        "codename": "xa_duong_10",
                        "division_type": "xã",
                        "short_codename": "duong_10"
                    },
                    {
                        "name": "Xã Đak Nhau",
                        "code": 25399,
                        "codename": "xa_dak_nhau",
                        "division_type": "xã",
                        "short_codename": "dak_nhau"
                    },
                    {
                        "name": "Xã Phú Sơn",
                        "code": 25400,
                        "codename": "xa_phu_son",
                        "division_type": "xã",
                        "short_codename": "phu_son"
                    },
                    {
                        "name": "Xã Thọ Sơn",
                        "code": 25402,
                        "codename": "xa_tho_son",
                        "division_type": "xã",
                        "short_codename": "tho_son"
                    },
                    {
                        "name": "Xã Bình Minh",
                        "code": 25404,
                        "codename": "xa_binh_minh",
                        "division_type": "xã",
                        "short_codename": "binh_minh"
                    },
                    {
                        "name": "Xã Bom Bo",
                        "code": 25405,
                        "codename": "xa_bom_bo",
                        "division_type": "xã",
                        "short_codename": "bom_bo"
                    },
                    {
                        "name": "Xã Minh Hưng",
                        "code": 25408,
                        "codename": "xa_minh_hung",
                        "division_type": "xã",
                        "short_codename": "minh_hung"
                    },
                    {
                        "name": "Xã Đoàn Kết",
                        "code": 25411,
                        "codename": "xa_doan_ket",
                        "division_type": "xã",
                        "short_codename": "doan_ket"
                    },
                    {
                        "name": "Xã Đồng Nai",
                        "code": 25414,
                        "codename": "xa_dong_nai",
                        "division_type": "xã",
                        "short_codename": "dong_nai"
                    },
                    {
                        "name": "Xã Đức Liễu",
                        "code": 25417,
                        "codename": "xa_duc_lieu",
                        "division_type": "xã",
                        "short_codename": "duc_lieu"
                    },
                    {
                        "name": "Xã Thống Nhất",
                        "code": 25420,
                        "codename": "xa_thong_nhat",
                        "division_type": "xã",
                        "short_codename": "thong_nhat"
                    },
                    {
                        "name": "Xã Nghĩa Trung",
                        "code": 25423,
                        "codename": "xa_nghia_trung",
                        "division_type": "xã",
                        "short_codename": "nghia_trung"
                    },
                    {
                        "name": "Xã Nghĩa Bình",
                        "code": 25424,
                        "codename": "xa_nghia_binh",
                        "division_type": "xã",
                        "short_codename": "nghia_binh"
                    },
                    {
                        "name": "Xã Đăng Hà",
                        "code": 25426,
                        "codename": "xa_dang_ha",
                        "division_type": "xã",
                        "short_codename": "dang_ha"
                    },
                    {
                        "name": "Xã Phước Sơn",
                        "code": 25429,
                        "codename": "xa_phuoc_son",
                        "division_type": "xã",
                        "short_codename": "phuoc_son"
                    }
                ]
            },
            {
                "name": "Huyện Chơn Thành",
                "code": 697,
                "codename": "huyen_chon_thanh",
                "division_type": "huyện",
                "short_codename": "chon_thanh",
                "wards": [
                    {
                        "name": "Thị trấn Chơn Thành",
                        "code": 25432,
                        "codename": "thi_tran_chon_thanh",
                        "division_type": "thị trấn",
                        "short_codename": "chon_thanh"
                    },
                    {
                        "name": "Xã Thành Tâm",
                        "code": 25433,
                        "codename": "xa_thanh_tam",
                        "division_type": "xã",
                        "short_codename": "thanh_tam"
                    },
                    {
                        "name": "Xã Minh Lập",
                        "code": 25435,
                        "codename": "xa_minh_lap",
                        "division_type": "xã",
                        "short_codename": "minh_lap"
                    },
                    {
                        "name": "Xã Quang Minh",
                        "code": 25439,
                        "codename": "xa_quang_minh",
                        "division_type": "xã",
                        "short_codename": "quang_minh"
                    },
                    {
                        "name": "Xã Minh Hưng",
                        "code": 25441,
                        "codename": "xa_minh_hung",
                        "division_type": "xã",
                        "short_codename": "minh_hung"
                    },
                    {
                        "name": "Xã Minh Long",
                        "code": 25444,
                        "codename": "xa_minh_long",
                        "division_type": "xã",
                        "short_codename": "minh_long"
                    },
                    {
                        "name": "Xã Minh Thành",
                        "code": 25447,
                        "codename": "xa_minh_thanh",
                        "division_type": "xã",
                        "short_codename": "minh_thanh"
                    },
                    {
                        "name": "Xã Nha Bích",
                        "code": 25450,
                        "codename": "xa_nha_bich",
                        "division_type": "xã",
                        "short_codename": "nha_bich"
                    },
                    {
                        "name": "Xã Minh Thắng",
                        "code": 25453,
                        "codename": "xa_minh_thang",
                        "division_type": "xã",
                        "short_codename": "minh_thang"
                    }
                ]
            },
            {
                "name": "Huyện Phú Riềng",
                "code": 698,
                "codename": "huyen_phu_rieng",
                "division_type": "huyện",
                "short_codename": "phu_rieng",
                "wards": [
                    {
                        "name": "Xã Long Bình",
                        "code": 25240,
                        "codename": "xa_long_binh",
                        "division_type": "xã",
                        "short_codename": "long_binh"
                    },
                    {
                        "name": "Xã Bình Tân",
                        "code": 25243,
                        "codename": "xa_binh_tan",
                        "division_type": "xã",
                        "short_codename": "binh_tan"
                    },
                    {
                        "name": "Xã Bình Sơn",
                        "code": 25244,
                        "codename": "xa_binh_son",
                        "division_type": "xã",
                        "short_codename": "binh_son"
                    },
                    {
                        "name": "Xã Long Hưng",
                        "code": 25246,
                        "codename": "xa_long_hung",
                        "division_type": "xã",
                        "short_codename": "long_hung"
                    },
                    {
                        "name": "Xã Phước Tân",
                        "code": 25250,
                        "codename": "xa_phuoc_tan",
                        "division_type": "xã",
                        "short_codename": "phuoc_tan"
                    },
                    {
                        "name": "Xã Bù Nho",
                        "code": 25252,
                        "codename": "xa_bu_nho",
                        "division_type": "xã",
                        "short_codename": "bu_nho"
                    },
                    {
                        "name": "Xã Long Hà",
                        "code": 25255,
                        "codename": "xa_long_ha",
                        "division_type": "xã",
                        "short_codename": "long_ha"
                    },
                    {
                        "name": "Xã Long Tân",
                        "code": 25258,
                        "codename": "xa_long_tan",
                        "division_type": "xã",
                        "short_codename": "long_tan"
                    },
                    {
                        "name": "Xã Phú Trung",
                        "code": 25261,
                        "codename": "xa_phu_trung",
                        "division_type": "xã",
                        "short_codename": "phu_trung"
                    },
                    {
                        "name": "Xã Phú Riềng",
                        "code": 25264,
                        "codename": "xa_phu_rieng",
                        "division_type": "xã",
                        "short_codename": "phu_rieng"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Tây Ninh",
        "code": 72,
        "codename": "tinh_tay_ninh",
        "division_type": "tỉnh",
        "phone_code": 276,
        "districts": [
            {
                "name": "Thành phố Tây Ninh",
                "code": 703,
                "codename": "thanh_pho_tay_ninh",
                "division_type": "thành phố",
                "short_codename": "tay_ninh",
                "wards": [
                    {
                        "name": "Phường 1",
                        "code": 25456,
                        "codename": "phuong_1",
                        "division_type": "phường",
                        "short_codename": "phuong_1"
                    },
                    {
                        "name": "Phường 3",
                        "code": 25459,
                        "codename": "phuong_3",
                        "division_type": "phường",
                        "short_codename": "phuong_3"
                    },
                    {
                        "name": "Phường 4",
                        "code": 25462,
                        "codename": "phuong_4",
                        "division_type": "phường",
                        "short_codename": "phuong_4"
                    },
                    {
                        "name": "Phường Hiệp Ninh",
                        "code": 25465,
                        "codename": "phuong_hiep_ninh",
                        "division_type": "phường",
                        "short_codename": "hiep_ninh"
                    },
                    {
                        "name": "Phường 2",
                        "code": 25468,
                        "codename": "phuong_2",
                        "division_type": "phường",
                        "short_codename": "phuong_2"
                    },
                    {
                        "name": "Xã Thạnh Tân",
                        "code": 25471,
                        "codename": "xa_thanh_tan",
                        "division_type": "xã",
                        "short_codename": "thanh_tan"
                    },
                    {
                        "name": "Xã Tân Bình",
                        "code": 25474,
                        "codename": "xa_tan_binh",
                        "division_type": "xã",
                        "short_codename": "tan_binh"
                    },
                    {
                        "name": "Xã Bình Minh",
                        "code": 25477,
                        "codename": "xa_binh_minh",
                        "division_type": "xã",
                        "short_codename": "binh_minh"
                    },
                    {
                        "name": "Phường Ninh Sơn",
                        "code": 25480,
                        "codename": "phuong_ninh_son",
                        "division_type": "phường",
                        "short_codename": "ninh_son"
                    },
                    {
                        "name": "Phường Ninh Thạnh",
                        "code": 25483,
                        "codename": "phuong_ninh_thanh",
                        "division_type": "phường",
                        "short_codename": "ninh_thanh"
                    }
                ]
            },
            {
                "name": "Huyện Tân Biên",
                "code": 705,
                "codename": "huyen_tan_bien",
                "division_type": "huyện",
                "short_codename": "tan_bien",
                "wards": [
                    {
                        "name": "Thị trấn Tân Biên",
                        "code": 25486,
                        "codename": "thi_tran_tan_bien",
                        "division_type": "thị trấn",
                        "short_codename": "tan_bien"
                    },
                    {
                        "name": "Xã Tân Lập",
                        "code": 25489,
                        "codename": "xa_tan_lap",
                        "division_type": "xã",
                        "short_codename": "tan_lap"
                    },
                    {
                        "name": "Xã Thạnh Bắc",
                        "code": 25492,
                        "codename": "xa_thanh_bac",
                        "division_type": "xã",
                        "short_codename": "thanh_bac"
                    },
                    {
                        "name": "Xã Tân Bình",
                        "code": 25495,
                        "codename": "xa_tan_binh",
                        "division_type": "xã",
                        "short_codename": "tan_binh"
                    },
                    {
                        "name": "Xã Thạnh Bình",
                        "code": 25498,
                        "codename": "xa_thanh_binh",
                        "division_type": "xã",
                        "short_codename": "thanh_binh"
                    },
                    {
                        "name": "Xã Thạnh Tây",
                        "code": 25501,
                        "codename": "xa_thanh_tay",
                        "division_type": "xã",
                        "short_codename": "thanh_tay"
                    },
                    {
                        "name": "Xã Hòa Hiệp",
                        "code": 25504,
                        "codename": "xa_hoa_hiep",
                        "division_type": "xã",
                        "short_codename": "hoa_hiep"
                    },
                    {
                        "name": "Xã Tân Phong",
                        "code": 25507,
                        "codename": "xa_tan_phong",
                        "division_type": "xã",
                        "short_codename": "tan_phong"
                    },
                    {
                        "name": "Xã Mỏ Công",
                        "code": 25510,
                        "codename": "xa_mo_cong",
                        "division_type": "xã",
                        "short_codename": "mo_cong"
                    },
                    {
                        "name": "Xã Trà Vong",
                        "code": 25513,
                        "codename": "xa_tra_vong",
                        "division_type": "xã",
                        "short_codename": "tra_vong"
                    }
                ]
            },
            {
                "name": "Huyện Tân Châu",
                "code": 706,
                "codename": "huyen_tan_chau",
                "division_type": "huyện",
                "short_codename": "tan_chau",
                "wards": [
                    {
                        "name": "Thị trấn Tân Châu",
                        "code": 25516,
                        "codename": "thi_tran_tan_chau",
                        "division_type": "thị trấn",
                        "short_codename": "tan_chau"
                    },
                    {
                        "name": "Xã Tân Hà",
                        "code": 25519,
                        "codename": "xa_tan_ha",
                        "division_type": "xã",
                        "short_codename": "tan_ha"
                    },
                    {
                        "name": "Xã Tân Đông",
                        "code": 25522,
                        "codename": "xa_tan_dong",
                        "division_type": "xã",
                        "short_codename": "tan_dong"
                    },
                    {
                        "name": "Xã Tân Hội",
                        "code": 25525,
                        "codename": "xa_tan_hoi",
                        "division_type": "xã",
                        "short_codename": "tan_hoi"
                    },
                    {
                        "name": "Xã Tân Hòa",
                        "code": 25528,
                        "codename": "xa_tan_hoa",
                        "division_type": "xã",
                        "short_codename": "tan_hoa"
                    },
                    {
                        "name": "Xã Suối Ngô",
                        "code": 25531,
                        "codename": "xa_suoi_ngo",
                        "division_type": "xã",
                        "short_codename": "suoi_ngo"
                    },
                    {
                        "name": "Xã Suối Dây",
                        "code": 25534,
                        "codename": "xa_suoi_day",
                        "division_type": "xã",
                        "short_codename": "suoi_day"
                    },
                    {
                        "name": "Xã Tân Hiệp",
                        "code": 25537,
                        "codename": "xa_tan_hiep",
                        "division_type": "xã",
                        "short_codename": "tan_hiep"
                    },
                    {
                        "name": "Xã Thạnh Đông",
                        "code": 25540,
                        "codename": "xa_thanh_dong",
                        "division_type": "xã",
                        "short_codename": "thanh_dong"
                    },
                    {
                        "name": "Xã Tân Thành",
                        "code": 25543,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Xã Tân Phú",
                        "code": 25546,
                        "codename": "xa_tan_phu",
                        "division_type": "xã",
                        "short_codename": "tan_phu"
                    },
                    {
                        "name": "Xã Tân Hưng",
                        "code": 25549,
                        "codename": "xa_tan_hung",
                        "division_type": "xã",
                        "short_codename": "tan_hung"
                    }
                ]
            },
            {
                "name": "Huyện Dương Minh Châu",
                "code": 707,
                "codename": "huyen_duong_minh_chau",
                "division_type": "huyện",
                "short_codename": "duong_minh_chau",
                "wards": [
                    {
                        "name": "Thị trấn Dương Minh Châu",
                        "code": 25552,
                        "codename": "thi_tran_duong_minh_chau",
                        "division_type": "thị trấn",
                        "short_codename": "duong_minh_chau"
                    },
                    {
                        "name": "Xã Suối Đá",
                        "code": 25555,
                        "codename": "xa_suoi_da",
                        "division_type": "xã",
                        "short_codename": "suoi_da"
                    },
                    {
                        "name": "Xã Phan",
                        "code": 25558,
                        "codename": "xa_phan",
                        "division_type": "xã",
                        "short_codename": "phan"
                    },
                    {
                        "name": "Xã Phước Ninh",
                        "code": 25561,
                        "codename": "xa_phuoc_ninh",
                        "division_type": "xã",
                        "short_codename": "phuoc_ninh"
                    },
                    {
                        "name": "Xã Phước Minh",
                        "code": 25564,
                        "codename": "xa_phuoc_minh",
                        "division_type": "xã",
                        "short_codename": "phuoc_minh"
                    },
                    {
                        "name": "Xã Bàu Năng",
                        "code": 25567,
                        "codename": "xa_bau_nang",
                        "division_type": "xã",
                        "short_codename": "bau_nang"
                    },
                    {
                        "name": "Xã Chà Là",
                        "code": 25570,
                        "codename": "xa_cha_la",
                        "division_type": "xã",
                        "short_codename": "cha_la"
                    },
                    {
                        "name": "Xã Cầu Khởi",
                        "code": 25573,
                        "codename": "xa_cau_khoi",
                        "division_type": "xã",
                        "short_codename": "cau_khoi"
                    },
                    {
                        "name": "Xã Bến Củi",
                        "code": 25576,
                        "codename": "xa_ben_cui",
                        "division_type": "xã",
                        "short_codename": "ben_cui"
                    },
                    {
                        "name": "Xã Lộc Ninh",
                        "code": 25579,
                        "codename": "xa_loc_ninh",
                        "division_type": "xã",
                        "short_codename": "loc_ninh"
                    },
                    {
                        "name": "Xã Truông Mít",
                        "code": 25582,
                        "codename": "xa_truong_mit",
                        "division_type": "xã",
                        "short_codename": "truong_mit"
                    }
                ]
            },
            {
                "name": "Huyện Châu Thành",
                "code": 708,
                "codename": "huyen_chau_thanh",
                "division_type": "huyện",
                "short_codename": "chau_thanh",
                "wards": [
                    {
                        "name": "Thị trấn Châu Thành",
                        "code": 25585,
                        "codename": "thi_tran_chau_thanh",
                        "division_type": "thị trấn",
                        "short_codename": "chau_thanh"
                    },
                    {
                        "name": "Xã Hảo Đước",
                        "code": 25588,
                        "codename": "xa_hao_duoc",
                        "division_type": "xã",
                        "short_codename": "hao_duoc"
                    },
                    {
                        "name": "Xã Phước Vinh",
                        "code": 25591,
                        "codename": "xa_phuoc_vinh",
                        "division_type": "xã",
                        "short_codename": "phuoc_vinh"
                    },
                    {
                        "name": "Xã Đồng Khởi",
                        "code": 25594,
                        "codename": "xa_dong_khoi",
                        "division_type": "xã",
                        "short_codename": "dong_khoi"
                    },
                    {
                        "name": "Xã Thái Bình",
                        "code": 25597,
                        "codename": "xa_thai_binh",
                        "division_type": "xã",
                        "short_codename": "thai_binh"
                    },
                    {
                        "name": "Xã An Cơ",
                        "code": 25600,
                        "codename": "xa_an_co",
                        "division_type": "xã",
                        "short_codename": "an_co"
                    },
                    {
                        "name": "Xã Biên Giới",
                        "code": 25603,
                        "codename": "xa_bien_gioi",
                        "division_type": "xã",
                        "short_codename": "bien_gioi"
                    },
                    {
                        "name": "Xã Hòa Thạnh",
                        "code": 25606,
                        "codename": "xa_hoa_thanh",
                        "division_type": "xã",
                        "short_codename": "hoa_thanh"
                    },
                    {
                        "name": "Xã Trí Bình",
                        "code": 25609,
                        "codename": "xa_tri_binh",
                        "division_type": "xã",
                        "short_codename": "tri_binh"
                    },
                    {
                        "name": "Xã Hòa Hội",
                        "code": 25612,
                        "codename": "xa_hoa_hoi",
                        "division_type": "xã",
                        "short_codename": "hoa_hoi"
                    },
                    {
                        "name": "Xã An Bình",
                        "code": 25615,
                        "codename": "xa_an_binh",
                        "division_type": "xã",
                        "short_codename": "an_binh"
                    },
                    {
                        "name": "Xã Thanh Điền",
                        "code": 25618,
                        "codename": "xa_thanh_dien",
                        "division_type": "xã",
                        "short_codename": "thanh_dien"
                    },
                    {
                        "name": "Xã Thành Long",
                        "code": 25621,
                        "codename": "xa_thanh_long",
                        "division_type": "xã",
                        "short_codename": "thanh_long"
                    },
                    {
                        "name": "Xã Ninh Điền",
                        "code": 25624,
                        "codename": "xa_ninh_dien",
                        "division_type": "xã",
                        "short_codename": "ninh_dien"
                    },
                    {
                        "name": "Xã Long Vĩnh",
                        "code": 25627,
                        "codename": "xa_long_vinh",
                        "division_type": "xã",
                        "short_codename": "long_vinh"
                    }
                ]
            },
            {
                "name": "Thị xã Hòa Thành",
                "code": 709,
                "codename": "thi_xa_hoa_thanh",
                "division_type": "thị xã",
                "short_codename": "hoa_thanh",
                "wards": [
                    {
                        "name": "Phường Long Hoa",
                        "code": 25630,
                        "codename": "phuong_long_hoa",
                        "division_type": "phường",
                        "short_codename": "long_hoa"
                    },
                    {
                        "name": "Phường Hiệp Tân",
                        "code": 25633,
                        "codename": "phuong_hiep_tan",
                        "division_type": "phường",
                        "short_codename": "hiep_tan"
                    },
                    {
                        "name": "Phường Long Thành Bắc",
                        "code": 25636,
                        "codename": "phuong_long_thanh_bac",
                        "division_type": "phường",
                        "short_codename": "long_thanh_bac"
                    },
                    {
                        "name": "Xã Trường Hòa",
                        "code": 25639,
                        "codename": "xa_truong_hoa",
                        "division_type": "xã",
                        "short_codename": "truong_hoa"
                    },
                    {
                        "name": "Xã Trường Đông",
                        "code": 25642,
                        "codename": "xa_truong_dong",
                        "division_type": "xã",
                        "short_codename": "truong_dong"
                    },
                    {
                        "name": "Phường Long Thành Trung",
                        "code": 25645,
                        "codename": "phuong_long_thanh_trung",
                        "division_type": "phường",
                        "short_codename": "long_thanh_trung"
                    },
                    {
                        "name": "Xã Trường Tây",
                        "code": 25648,
                        "codename": "xa_truong_tay",
                        "division_type": "xã",
                        "short_codename": "truong_tay"
                    },
                    {
                        "name": "Xã Long Thành Nam",
                        "code": 25651,
                        "codename": "xa_long_thanh_nam",
                        "division_type": "xã",
                        "short_codename": "long_thanh_nam"
                    }
                ]
            },
            {
                "name": "Huyện Gò Dầu",
                "code": 710,
                "codename": "huyen_go_dau",
                "division_type": "huyện",
                "short_codename": "go_dau",
                "wards": [
                    {
                        "name": "Thị trấn Gò Dầu",
                        "code": 25654,
                        "codename": "thi_tran_go_dau",
                        "division_type": "thị trấn",
                        "short_codename": "go_dau"
                    },
                    {
                        "name": "Xã Thạnh Đức",
                        "code": 25657,
                        "codename": "xa_thanh_duc",
                        "division_type": "xã",
                        "short_codename": "thanh_duc"
                    },
                    {
                        "name": "Xã Cẩm Giang",
                        "code": 25660,
                        "codename": "xa_cam_giang",
                        "division_type": "xã",
                        "short_codename": "cam_giang"
                    },
                    {
                        "name": "Xã Hiệp Thạnh",
                        "code": 25663,
                        "codename": "xa_hiep_thanh",
                        "division_type": "xã",
                        "short_codename": "hiep_thanh"
                    },
                    {
                        "name": "Xã Bàu Đồn",
                        "code": 25666,
                        "codename": "xa_bau_don",
                        "division_type": "xã",
                        "short_codename": "bau_don"
                    },
                    {
                        "name": "Xã Phước Thạnh",
                        "code": 25669,
                        "codename": "xa_phuoc_thanh",
                        "division_type": "xã",
                        "short_codename": "phuoc_thanh"
                    },
                    {
                        "name": "Xã Phước Đông",
                        "code": 25672,
                        "codename": "xa_phuoc_dong",
                        "division_type": "xã",
                        "short_codename": "phuoc_dong"
                    },
                    {
                        "name": "Xã Phước Trạch",
                        "code": 25675,
                        "codename": "xa_phuoc_trach",
                        "division_type": "xã",
                        "short_codename": "phuoc_trach"
                    },
                    {
                        "name": "Xã Thanh Phước",
                        "code": 25678,
                        "codename": "xa_thanh_phuoc",
                        "division_type": "xã",
                        "short_codename": "thanh_phuoc"
                    }
                ]
            },
            {
                "name": "Huyện Bến Cầu",
                "code": 711,
                "codename": "huyen_ben_cau",
                "division_type": "huyện",
                "short_codename": "ben_cau",
                "wards": [
                    {
                        "name": "Thị trấn Bến Cầu",
                        "code": 25681,
                        "codename": "thi_tran_ben_cau",
                        "division_type": "thị trấn",
                        "short_codename": "ben_cau"
                    },
                    {
                        "name": "Xã Long Chữ",
                        "code": 25684,
                        "codename": "xa_long_chu",
                        "division_type": "xã",
                        "short_codename": "long_chu"
                    },
                    {
                        "name": "Xã Long Phước",
                        "code": 25687,
                        "codename": "xa_long_phuoc",
                        "division_type": "xã",
                        "short_codename": "long_phuoc"
                    },
                    {
                        "name": "Xã Long Giang",
                        "code": 25690,
                        "codename": "xa_long_giang",
                        "division_type": "xã",
                        "short_codename": "long_giang"
                    },
                    {
                        "name": "Xã Tiên Thuận",
                        "code": 25693,
                        "codename": "xa_tien_thuan",
                        "division_type": "xã",
                        "short_codename": "tien_thuan"
                    },
                    {
                        "name": "Xã Long Khánh",
                        "code": 25696,
                        "codename": "xa_long_khanh",
                        "division_type": "xã",
                        "short_codename": "long_khanh"
                    },
                    {
                        "name": "Xã Lợi Thuận",
                        "code": 25699,
                        "codename": "xa_loi_thuan",
                        "division_type": "xã",
                        "short_codename": "loi_thuan"
                    },
                    {
                        "name": "Xã Long Thuận",
                        "code": 25702,
                        "codename": "xa_long_thuan",
                        "division_type": "xã",
                        "short_codename": "long_thuan"
                    },
                    {
                        "name": "Xã An Thạnh",
                        "code": 25705,
                        "codename": "xa_an_thanh",
                        "division_type": "xã",
                        "short_codename": "an_thanh"
                    }
                ]
            },
            {
                "name": "Thị xã Trảng Bàng",
                "code": 712,
                "codename": "thi_xa_trang_bang",
                "division_type": "thị xã",
                "short_codename": "trang_bang",
                "wards": [
                    {
                        "name": "Phường Trảng Bàng",
                        "code": 25708,
                        "codename": "phuong_trang_bang",
                        "division_type": "phường",
                        "short_codename": "trang_bang"
                    },
                    {
                        "name": "Xã Đôn Thuận",
                        "code": 25711,
                        "codename": "xa_don_thuan",
                        "division_type": "xã",
                        "short_codename": "don_thuan"
                    },
                    {
                        "name": "Xã Hưng Thuận",
                        "code": 25714,
                        "codename": "xa_hung_thuan",
                        "division_type": "xã",
                        "short_codename": "hung_thuan"
                    },
                    {
                        "name": "Phường Lộc Hưng",
                        "code": 25717,
                        "codename": "phuong_loc_hung",
                        "division_type": "phường",
                        "short_codename": "loc_hung"
                    },
                    {
                        "name": "Phường Gia Lộc",
                        "code": 25720,
                        "codename": "phuong_gia_loc",
                        "division_type": "phường",
                        "short_codename": "gia_loc"
                    },
                    {
                        "name": "Phường Gia Bình",
                        "code": 25723,
                        "codename": "phuong_gia_binh",
                        "division_type": "phường",
                        "short_codename": "gia_binh"
                    },
                    {
                        "name": "Xã Phước Bình",
                        "code": 25729,
                        "codename": "xa_phuoc_binh",
                        "division_type": "xã",
                        "short_codename": "phuoc_binh"
                    },
                    {
                        "name": "Phường An Tịnh",
                        "code": 25732,
                        "codename": "phuong_an_tinh",
                        "division_type": "phường",
                        "short_codename": "an_tinh"
                    },
                    {
                        "name": "Phường An Hòa",
                        "code": 25735,
                        "codename": "phuong_an_hoa",
                        "division_type": "phường",
                        "short_codename": "an_hoa"
                    },
                    {
                        "name": "Xã Phước Chỉ",
                        "code": 25738,
                        "codename": "xa_phuoc_chi",
                        "division_type": "xã",
                        "short_codename": "phuoc_chi"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Bình Dương",
        "code": 74,
        "codename": "tinh_binh_duong",
        "division_type": "tỉnh",
        "phone_code": 274,
        "districts": [
            {
                "name": "Thành phố Thủ Dầu Một",
                "code": 718,
                "codename": "thanh_pho_thu_dau_mot",
                "division_type": "thành phố",
                "short_codename": "thu_dau_mot",
                "wards": [
                    {
                        "name": "Phường Hiệp Thành",
                        "code": 25741,
                        "codename": "phuong_hiep_thanh",
                        "division_type": "phường",
                        "short_codename": "hiep_thanh"
                    },
                    {
                        "name": "Phường Phú Lợi",
                        "code": 25744,
                        "codename": "phuong_phu_loi",
                        "division_type": "phường",
                        "short_codename": "phu_loi"
                    },
                    {
                        "name": "Phường Phú Cường",
                        "code": 25747,
                        "codename": "phuong_phu_cuong",
                        "division_type": "phường",
                        "short_codename": "phu_cuong"
                    },
                    {
                        "name": "Phường Phú Hòa",
                        "code": 25750,
                        "codename": "phuong_phu_hoa",
                        "division_type": "phường",
                        "short_codename": "phu_hoa"
                    },
                    {
                        "name": "Phường Phú Thọ",
                        "code": 25753,
                        "codename": "phuong_phu_tho",
                        "division_type": "phường",
                        "short_codename": "phu_tho"
                    },
                    {
                        "name": "Phường Chánh Nghĩa",
                        "code": 25756,
                        "codename": "phuong_chanh_nghia",
                        "division_type": "phường",
                        "short_codename": "chanh_nghia"
                    },
                    {
                        "name": "Phường Định Hoà",
                        "code": 25759,
                        "codename": "phuong_dinh_hoa",
                        "division_type": "phường",
                        "short_codename": "dinh_hoa"
                    },
                    {
                        "name": "Phường Hoà Phú",
                        "code": 25760,
                        "codename": "phuong_hoa_phu",
                        "division_type": "phường",
                        "short_codename": "hoa_phu"
                    },
                    {
                        "name": "Phường Phú Mỹ",
                        "code": 25762,
                        "codename": "phuong_phu_my",
                        "division_type": "phường",
                        "short_codename": "phu_my"
                    },
                    {
                        "name": "Phường Phú Tân",
                        "code": 25763,
                        "codename": "phuong_phu_tan",
                        "division_type": "phường",
                        "short_codename": "phu_tan"
                    },
                    {
                        "name": "Phường Tân An",
                        "code": 25765,
                        "codename": "phuong_tan_an",
                        "division_type": "phường",
                        "short_codename": "tan_an"
                    },
                    {
                        "name": "Phường Hiệp An",
                        "code": 25768,
                        "codename": "phuong_hiep_an",
                        "division_type": "phường",
                        "short_codename": "hiep_an"
                    },
                    {
                        "name": "Phường Tương Bình Hiệp",
                        "code": 25771,
                        "codename": "phuong_tuong_binh_hiep",
                        "division_type": "phường",
                        "short_codename": "tuong_binh_hiep"
                    },
                    {
                        "name": "Phường Chánh Mỹ",
                        "code": 25774,
                        "codename": "phuong_chanh_my",
                        "division_type": "phường",
                        "short_codename": "chanh_my"
                    }
                ]
            },
            {
                "name": "Huyện Bàu Bàng",
                "code": 719,
                "codename": "huyen_bau_bang",
                "division_type": "huyện",
                "short_codename": "bau_bang",
                "wards": [
                    {
                        "name": "Xã Trừ Văn Thố",
                        "code": 25816,
                        "codename": "xa_tru_van_tho",
                        "division_type": "xã",
                        "short_codename": "tru_van_tho"
                    },
                    {
                        "name": "Xã Cây Trường II",
                        "code": 25819,
                        "codename": "xa_cay_truong_ii",
                        "division_type": "xã",
                        "short_codename": "cay_truong_ii"
                    },
                    {
                        "name": "Thị trấn Lai Uyên",
                        "code": 25822,
                        "codename": "thi_tran_lai_uyen",
                        "division_type": "thị trấn",
                        "short_codename": "lai_uyen"
                    },
                    {
                        "name": "Xã Tân Hưng",
                        "code": 25825,
                        "codename": "xa_tan_hung",
                        "division_type": "xã",
                        "short_codename": "tan_hung"
                    },
                    {
                        "name": "Xã Long Nguyên",
                        "code": 25828,
                        "codename": "xa_long_nguyen",
                        "division_type": "xã",
                        "short_codename": "long_nguyen"
                    },
                    {
                        "name": "Xã Hưng Hòa",
                        "code": 25831,
                        "codename": "xa_hung_hoa",
                        "division_type": "xã",
                        "short_codename": "hung_hoa"
                    },
                    {
                        "name": "Xã Lai Hưng",
                        "code": 25834,
                        "codename": "xa_lai_hung",
                        "division_type": "xã",
                        "short_codename": "lai_hung"
                    }
                ]
            },
            {
                "name": "Huyện Dầu Tiếng",
                "code": 720,
                "codename": "huyen_dau_tieng",
                "division_type": "huyện",
                "short_codename": "dau_tieng",
                "wards": [
                    {
                        "name": "Thị trấn Dầu Tiếng",
                        "code": 25777,
                        "codename": "thi_tran_dau_tieng",
                        "division_type": "thị trấn",
                        "short_codename": "dau_tieng"
                    },
                    {
                        "name": "Xã Minh Hoà",
                        "code": 25780,
                        "codename": "xa_minh_hoa",
                        "division_type": "xã",
                        "short_codename": "minh_hoa"
                    },
                    {
                        "name": "Xã Minh Thạnh",
                        "code": 25783,
                        "codename": "xa_minh_thanh",
                        "division_type": "xã",
                        "short_codename": "minh_thanh"
                    },
                    {
                        "name": "Xã Minh Tân",
                        "code": 25786,
                        "codename": "xa_minh_tan",
                        "division_type": "xã",
                        "short_codename": "minh_tan"
                    },
                    {
                        "name": "Xã Định An",
                        "code": 25789,
                        "codename": "xa_dinh_an",
                        "division_type": "xã",
                        "short_codename": "dinh_an"
                    },
                    {
                        "name": "Xã Long Hoà",
                        "code": 25792,
                        "codename": "xa_long_hoa",
                        "division_type": "xã",
                        "short_codename": "long_hoa"
                    },
                    {
                        "name": "Xã Định Thành",
                        "code": 25795,
                        "codename": "xa_dinh_thanh",
                        "division_type": "xã",
                        "short_codename": "dinh_thanh"
                    },
                    {
                        "name": "Xã Định Hiệp",
                        "code": 25798,
                        "codename": "xa_dinh_hiep",
                        "division_type": "xã",
                        "short_codename": "dinh_hiep"
                    },
                    {
                        "name": "Xã An Lập",
                        "code": 25801,
                        "codename": "xa_an_lap",
                        "division_type": "xã",
                        "short_codename": "an_lap"
                    },
                    {
                        "name": "Xã Long Tân",
                        "code": 25804,
                        "codename": "xa_long_tan",
                        "division_type": "xã",
                        "short_codename": "long_tan"
                    },
                    {
                        "name": "Xã Thanh An",
                        "code": 25807,
                        "codename": "xa_thanh_an",
                        "division_type": "xã",
                        "short_codename": "thanh_an"
                    },
                    {
                        "name": "Xã Thanh Tuyền",
                        "code": 25810,
                        "codename": "xa_thanh_tuyen",
                        "division_type": "xã",
                        "short_codename": "thanh_tuyen"
                    }
                ]
            },
            {
                "name": "Thị xã Bến Cát",
                "code": 721,
                "codename": "thi_xa_ben_cat",
                "division_type": "thị xã",
                "short_codename": "ben_cat",
                "wards": [
                    {
                        "name": "Phường Mỹ Phước",
                        "code": 25813,
                        "codename": "phuong_my_phuoc",
                        "division_type": "phường",
                        "short_codename": "my_phuoc"
                    },
                    {
                        "name": "Phường Chánh Phú Hòa",
                        "code": 25837,
                        "codename": "phuong_chanh_phu_hoa",
                        "division_type": "phường",
                        "short_codename": "chanh_phu_hoa"
                    },
                    {
                        "name": "Xã An Điền",
                        "code": 25840,
                        "codename": "xa_an_dien",
                        "division_type": "xã",
                        "short_codename": "an_dien"
                    },
                    {
                        "name": "Xã An Tây",
                        "code": 25843,
                        "codename": "xa_an_tay",
                        "division_type": "xã",
                        "short_codename": "an_tay"
                    },
                    {
                        "name": "Phường Thới Hòa",
                        "code": 25846,
                        "codename": "phuong_thoi_hoa",
                        "division_type": "phường",
                        "short_codename": "thoi_hoa"
                    },
                    {
                        "name": "Phường Hòa Lợi",
                        "code": 25849,
                        "codename": "phuong_hoa_loi",
                        "division_type": "phường",
                        "short_codename": "hoa_loi"
                    },
                    {
                        "name": "Phường Tân Định",
                        "code": 25852,
                        "codename": "phuong_tan_dinh",
                        "division_type": "phường",
                        "short_codename": "tan_dinh"
                    },
                    {
                        "name": "Xã Phú An",
                        "code": 25855,
                        "codename": "xa_phu_an",
                        "division_type": "xã",
                        "short_codename": "phu_an"
                    }
                ]
            },
            {
                "name": "Huyện Phú Giáo",
                "code": 722,
                "codename": "huyen_phu_giao",
                "division_type": "huyện",
                "short_codename": "phu_giao",
                "wards": [
                    {
                        "name": "Thị trấn Phước Vĩnh",
                        "code": 25858,
                        "codename": "thi_tran_phuoc_vinh",
                        "division_type": "thị trấn",
                        "short_codename": "phuoc_vinh"
                    },
                    {
                        "name": "Xã An Linh",
                        "code": 25861,
                        "codename": "xa_an_linh",
                        "division_type": "xã",
                        "short_codename": "an_linh"
                    },
                    {
                        "name": "Xã Phước Sang",
                        "code": 25864,
                        "codename": "xa_phuoc_sang",
                        "division_type": "xã",
                        "short_codename": "phuoc_sang"
                    },
                    {
                        "name": "Xã An Thái",
                        "code": 25865,
                        "codename": "xa_an_thai",
                        "division_type": "xã",
                        "short_codename": "an_thai"
                    },
                    {
                        "name": "Xã An Long",
                        "code": 25867,
                        "codename": "xa_an_long",
                        "division_type": "xã",
                        "short_codename": "an_long"
                    },
                    {
                        "name": "Xã An Bình",
                        "code": 25870,
                        "codename": "xa_an_binh",
                        "division_type": "xã",
                        "short_codename": "an_binh"
                    },
                    {
                        "name": "Xã Tân Hiệp",
                        "code": 25873,
                        "codename": "xa_tan_hiep",
                        "division_type": "xã",
                        "short_codename": "tan_hiep"
                    },
                    {
                        "name": "Xã Tam Lập",
                        "code": 25876,
                        "codename": "xa_tam_lap",
                        "division_type": "xã",
                        "short_codename": "tam_lap"
                    },
                    {
                        "name": "Xã Tân Long",
                        "code": 25879,
                        "codename": "xa_tan_long",
                        "division_type": "xã",
                        "short_codename": "tan_long"
                    },
                    {
                        "name": "Xã Vĩnh Hoà",
                        "code": 25882,
                        "codename": "xa_vinh_hoa",
                        "division_type": "xã",
                        "short_codename": "vinh_hoa"
                    },
                    {
                        "name": "Xã Phước Hoà",
                        "code": 25885,
                        "codename": "xa_phuoc_hoa",
                        "division_type": "xã",
                        "short_codename": "phuoc_hoa"
                    }
                ]
            },
            {
                "name": "Thị xã Tân Uyên",
                "code": 723,
                "codename": "thi_xa_tan_uyen",
                "division_type": "thị xã",
                "short_codename": "tan_uyen",
                "wards": [
                    {
                        "name": "Phường Uyên Hưng",
                        "code": 25888,
                        "codename": "phuong_uyen_hung",
                        "division_type": "phường",
                        "short_codename": "uyen_hung"
                    },
                    {
                        "name": "Phường Tân Phước Khánh",
                        "code": 25891,
                        "codename": "phuong_tan_phuoc_khanh",
                        "division_type": "phường",
                        "short_codename": "tan_phuoc_khanh"
                    },
                    {
                        "name": "Phường Vĩnh Tân",
                        "code": 25912,
                        "codename": "phuong_vinh_tan",
                        "division_type": "phường",
                        "short_codename": "vinh_tan"
                    },
                    {
                        "name": "Phường Hội Nghĩa",
                        "code": 25915,
                        "codename": "phuong_hoi_nghia",
                        "division_type": "phường",
                        "short_codename": "hoi_nghia"
                    },
                    {
                        "name": "Phường Tân Hiệp",
                        "code": 25920,
                        "codename": "phuong_tan_hiep",
                        "division_type": "phường",
                        "short_codename": "tan_hiep"
                    },
                    {
                        "name": "Phường Khánh Bình",
                        "code": 25921,
                        "codename": "phuong_khanh_binh",
                        "division_type": "phường",
                        "short_codename": "khanh_binh"
                    },
                    {
                        "name": "Phường Phú Chánh",
                        "code": 25924,
                        "codename": "phuong_phu_chanh",
                        "division_type": "phường",
                        "short_codename": "phu_chanh"
                    },
                    {
                        "name": "Xã Bạch Đằng",
                        "code": 25930,
                        "codename": "xa_bach_dang",
                        "division_type": "xã",
                        "short_codename": "bach_dang"
                    },
                    {
                        "name": "Phường Tân Vĩnh Hiệp",
                        "code": 25933,
                        "codename": "phuong_tan_vinh_hiep",
                        "division_type": "phường",
                        "short_codename": "tan_vinh_hiep"
                    },
                    {
                        "name": "Phường Thạnh Phước",
                        "code": 25936,
                        "codename": "phuong_thanh_phuoc",
                        "division_type": "phường",
                        "short_codename": "thanh_phuoc"
                    },
                    {
                        "name": "Xã Thạnh Hội",
                        "code": 25937,
                        "codename": "xa_thanh_hoi",
                        "division_type": "xã",
                        "short_codename": "thanh_hoi"
                    },
                    {
                        "name": "Phường Thái Hòa",
                        "code": 25939,
                        "codename": "phuong_thai_hoa",
                        "division_type": "phường",
                        "short_codename": "thai_hoa"
                    }
                ]
            },
            {
                "name": "Thành phố Dĩ An",
                "code": 724,
                "codename": "thanh_pho_di_an",
                "division_type": "thành phố",
                "short_codename": "di_an",
                "wards": [
                    {
                        "name": "Phường Dĩ An",
                        "code": 25942,
                        "codename": "phuong_di_an",
                        "division_type": "phường",
                        "short_codename": "di_an"
                    },
                    {
                        "name": "Phường Tân Bình",
                        "code": 25945,
                        "codename": "phuong_tan_binh",
                        "division_type": "phường",
                        "short_codename": "tan_binh"
                    },
                    {
                        "name": "Phường Tân Đông Hiệp",
                        "code": 25948,
                        "codename": "phuong_tan_dong_hiep",
                        "division_type": "phường",
                        "short_codename": "tan_dong_hiep"
                    },
                    {
                        "name": "Phường Bình An",
                        "code": 25951,
                        "codename": "phuong_binh_an",
                        "division_type": "phường",
                        "short_codename": "binh_an"
                    },
                    {
                        "name": "Phường Bình Thắng",
                        "code": 25954,
                        "codename": "phuong_binh_thang",
                        "division_type": "phường",
                        "short_codename": "binh_thang"
                    },
                    {
                        "name": "Phường Đông Hòa",
                        "code": 25957,
                        "codename": "phuong_dong_hoa",
                        "division_type": "phường",
                        "short_codename": "dong_hoa"
                    },
                    {
                        "name": "Phường An Bình",
                        "code": 25960,
                        "codename": "phuong_an_binh",
                        "division_type": "phường",
                        "short_codename": "an_binh"
                    }
                ]
            },
            {
                "name": "Thành phố Thuận An",
                "code": 725,
                "codename": "thanh_pho_thuan_an",
                "division_type": "thành phố",
                "short_codename": "thuan_an",
                "wards": [
                    {
                        "name": "Phường An Thạnh",
                        "code": 25963,
                        "codename": "phuong_an_thanh",
                        "division_type": "phường",
                        "short_codename": "an_thanh"
                    },
                    {
                        "name": "Phường Lái Thiêu",
                        "code": 25966,
                        "codename": "phuong_lai_thieu",
                        "division_type": "phường",
                        "short_codename": "lai_thieu"
                    },
                    {
                        "name": "Phường Bình Chuẩn",
                        "code": 25969,
                        "codename": "phuong_binh_chuan",
                        "division_type": "phường",
                        "short_codename": "binh_chuan"
                    },
                    {
                        "name": "Phường Thuận Giao",
                        "code": 25972,
                        "codename": "phuong_thuan_giao",
                        "division_type": "phường",
                        "short_codename": "thuan_giao"
                    },
                    {
                        "name": "Phường An Phú",
                        "code": 25975,
                        "codename": "phuong_an_phu",
                        "division_type": "phường",
                        "short_codename": "an_phu"
                    },
                    {
                        "name": "Phường Hưng Định",
                        "code": 25978,
                        "codename": "phuong_hung_dinh",
                        "division_type": "phường",
                        "short_codename": "hung_dinh"
                    },
                    {
                        "name": "Xã An Sơn",
                        "code": 25981,
                        "codename": "xa_an_son",
                        "division_type": "xã",
                        "short_codename": "an_son"
                    },
                    {
                        "name": "Phường Bình Nhâm",
                        "code": 25984,
                        "codename": "phuong_binh_nham",
                        "division_type": "phường",
                        "short_codename": "binh_nham"
                    },
                    {
                        "name": "Phường Bình Hòa",
                        "code": 25987,
                        "codename": "phuong_binh_hoa",
                        "division_type": "phường",
                        "short_codename": "binh_hoa"
                    },
                    {
                        "name": "Phường Vĩnh Phú",
                        "code": 25990,
                        "codename": "phuong_vinh_phu",
                        "division_type": "phường",
                        "short_codename": "vinh_phu"
                    }
                ]
            },
            {
                "name": "Huyện Bắc Tân Uyên",
                "code": 726,
                "codename": "huyen_bac_tan_uyen",
                "division_type": "huyện",
                "short_codename": "bac_tan_uyen",
                "wards": [
                    {
                        "name": "Xã Tân Định",
                        "code": 25894,
                        "codename": "xa_tan_dinh",
                        "division_type": "xã",
                        "short_codename": "tan_dinh"
                    },
                    {
                        "name": "Xã Bình Mỹ",
                        "code": 25897,
                        "codename": "xa_binh_my",
                        "division_type": "xã",
                        "short_codename": "binh_my"
                    },
                    {
                        "name": "Thị trấn Tân Bình",
                        "code": 25900,
                        "codename": "thi_tran_tan_binh",
                        "division_type": "thị trấn",
                        "short_codename": "tan_binh"
                    },
                    {
                        "name": "Xã Tân Lập",
                        "code": 25903,
                        "codename": "xa_tan_lap",
                        "division_type": "xã",
                        "short_codename": "tan_lap"
                    },
                    {
                        "name": "Thị trấn Tân Thành",
                        "code": 25906,
                        "codename": "thi_tran_tan_thanh",
                        "division_type": "thị trấn",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Xã Đất Cuốc",
                        "code": 25907,
                        "codename": "xa_dat_cuoc",
                        "division_type": "xã",
                        "short_codename": "dat_cuoc"
                    },
                    {
                        "name": "Xã Hiếu Liêm",
                        "code": 25908,
                        "codename": "xa_hieu_liem",
                        "division_type": "xã",
                        "short_codename": "hieu_liem"
                    },
                    {
                        "name": "Xã Lạc An",
                        "code": 25909,
                        "codename": "xa_lac_an",
                        "division_type": "xã",
                        "short_codename": "lac_an"
                    },
                    {
                        "name": "Xã Tân Mỹ",
                        "code": 25918,
                        "codename": "xa_tan_my",
                        "division_type": "xã",
                        "short_codename": "tan_my"
                    },
                    {
                        "name": "Xã Thường Tân",
                        "code": 25927,
                        "codename": "xa_thuong_tan",
                        "division_type": "xã",
                        "short_codename": "thuong_tan"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Đồng Nai",
        "code": 75,
        "codename": "tinh_dong_nai",
        "division_type": "tỉnh",
        "phone_code": 251,
        "districts": [
            {
                "name": "Thành phố Biên Hòa",
                "code": 731,
                "codename": "thanh_pho_bien_hoa",
                "division_type": "thành phố",
                "short_codename": "bien_hoa",
                "wards": [
                    {
                        "name": "Phường Trảng Dài",
                        "code": 25993,
                        "codename": "phuong_trang_dai",
                        "division_type": "phường",
                        "short_codename": "trang_dai"
                    },
                    {
                        "name": "Phường Tân Phong",
                        "code": 25996,
                        "codename": "phuong_tan_phong",
                        "division_type": "phường",
                        "short_codename": "tan_phong"
                    },
                    {
                        "name": "Phường Tân Biên",
                        "code": 25999,
                        "codename": "phuong_tan_bien",
                        "division_type": "phường",
                        "short_codename": "tan_bien"
                    },
                    {
                        "name": "Phường Hố Nai",
                        "code": 26002,
                        "codename": "phuong_ho_nai",
                        "division_type": "phường",
                        "short_codename": "ho_nai"
                    },
                    {
                        "name": "Phường Tân Hòa",
                        "code": 26005,
                        "codename": "phuong_tan_hoa",
                        "division_type": "phường",
                        "short_codename": "tan_hoa"
                    },
                    {
                        "name": "Phường Tân Hiệp",
                        "code": 26008,
                        "codename": "phuong_tan_hiep",
                        "division_type": "phường",
                        "short_codename": "tan_hiep"
                    },
                    {
                        "name": "Phường Bửu Long",
                        "code": 26011,
                        "codename": "phuong_buu_long",
                        "division_type": "phường",
                        "short_codename": "buu_long"
                    },
                    {
                        "name": "Phường Tân Tiến",
                        "code": 26014,
                        "codename": "phuong_tan_tien",
                        "division_type": "phường",
                        "short_codename": "tan_tien"
                    },
                    {
                        "name": "Phường Tam Hiệp",
                        "code": 26017,
                        "codename": "phuong_tam_hiep",
                        "division_type": "phường",
                        "short_codename": "tam_hiep"
                    },
                    {
                        "name": "Phường Long Bình",
                        "code": 26020,
                        "codename": "phuong_long_binh",
                        "division_type": "phường",
                        "short_codename": "long_binh"
                    },
                    {
                        "name": "Phường Quang Vinh",
                        "code": 26023,
                        "codename": "phuong_quang_vinh",
                        "division_type": "phường",
                        "short_codename": "quang_vinh"
                    },
                    {
                        "name": "Phường Tân Mai",
                        "code": 26026,
                        "codename": "phuong_tan_mai",
                        "division_type": "phường",
                        "short_codename": "tan_mai"
                    },
                    {
                        "name": "Phường Thống Nhất",
                        "code": 26029,
                        "codename": "phuong_thong_nhat",
                        "division_type": "phường",
                        "short_codename": "thong_nhat"
                    },
                    {
                        "name": "Phường Trung Dũng",
                        "code": 26032,
                        "codename": "phuong_trung_dung",
                        "division_type": "phường",
                        "short_codename": "trung_dung"
                    },
                    {
                        "name": "Phường Tam Hòa",
                        "code": 26035,
                        "codename": "phuong_tam_hoa",
                        "division_type": "phường",
                        "short_codename": "tam_hoa"
                    },
                    {
                        "name": "Phường Hòa Bình",
                        "code": 26038,
                        "codename": "phuong_hoa_binh",
                        "division_type": "phường",
                        "short_codename": "hoa_binh"
                    },
                    {
                        "name": "Phường Quyết Thắng",
                        "code": 26041,
                        "codename": "phuong_quyet_thang",
                        "division_type": "phường",
                        "short_codename": "quyet_thang"
                    },
                    {
                        "name": "Phường Thanh Bình",
                        "code": 26044,
                        "codename": "phuong_thanh_binh",
                        "division_type": "phường",
                        "short_codename": "thanh_binh"
                    },
                    {
                        "name": "Phường Bình Đa",
                        "code": 26047,
                        "codename": "phuong_binh_da",
                        "division_type": "phường",
                        "short_codename": "binh_da"
                    },
                    {
                        "name": "Phường An Bình",
                        "code": 26050,
                        "codename": "phuong_an_binh",
                        "division_type": "phường",
                        "short_codename": "an_binh"
                    },
                    {
                        "name": "Phường Bửu Hòa",
                        "code": 26053,
                        "codename": "phuong_buu_hoa",
                        "division_type": "phường",
                        "short_codename": "buu_hoa"
                    },
                    {
                        "name": "Phường Long Bình Tân",
                        "code": 26056,
                        "codename": "phuong_long_binh_tan",
                        "division_type": "phường",
                        "short_codename": "long_binh_tan"
                    },
                    {
                        "name": "Phường Tân Vạn",
                        "code": 26059,
                        "codename": "phuong_tan_van",
                        "division_type": "phường",
                        "short_codename": "tan_van"
                    },
                    {
                        "name": "Phường Tân Hạnh",
                        "code": 26062,
                        "codename": "phuong_tan_hanh",
                        "division_type": "phường",
                        "short_codename": "tan_hanh"
                    },
                    {
                        "name": "Phường Hiệp Hòa",
                        "code": 26065,
                        "codename": "phuong_hiep_hoa",
                        "division_type": "phường",
                        "short_codename": "hiep_hoa"
                    },
                    {
                        "name": "Phường Hóa An",
                        "code": 26068,
                        "codename": "phuong_hoa_an",
                        "division_type": "phường",
                        "short_codename": "hoa_an"
                    },
                    {
                        "name": "Phường An Hòa",
                        "code": 26371,
                        "codename": "phuong_an_hoa",
                        "division_type": "phường",
                        "short_codename": "an_hoa"
                    },
                    {
                        "name": "Phường Tam Phước",
                        "code": 26374,
                        "codename": "phuong_tam_phuoc",
                        "division_type": "phường",
                        "short_codename": "tam_phuoc"
                    },
                    {
                        "name": "Phường Phước Tân",
                        "code": 26377,
                        "codename": "phuong_phuoc_tan",
                        "division_type": "phường",
                        "short_codename": "phuoc_tan"
                    },
                    {
                        "name": "Xã Long Hưng",
                        "code": 26380,
                        "codename": "xa_long_hung",
                        "division_type": "xã",
                        "short_codename": "long_hung"
                    }
                ]
            },
            {
                "name": "Thành phố Long Khánh",
                "code": 732,
                "codename": "thanh_pho_long_khanh",
                "division_type": "thành phố",
                "short_codename": "long_khanh",
                "wards": [
                    {
                        "name": "Phường Xuân Trung",
                        "code": 26071,
                        "codename": "phuong_xuan_trung",
                        "division_type": "phường",
                        "short_codename": "xuan_trung"
                    },
                    {
                        "name": "Phường Xuân Thanh",
                        "code": 26074,
                        "codename": "phuong_xuan_thanh",
                        "division_type": "phường",
                        "short_codename": "xuan_thanh"
                    },
                    {
                        "name": "Phường Xuân Bình",
                        "code": 26077,
                        "codename": "phuong_xuan_binh",
                        "division_type": "phường",
                        "short_codename": "xuan_binh"
                    },
                    {
                        "name": "Phường Xuân An",
                        "code": 26080,
                        "codename": "phuong_xuan_an",
                        "division_type": "phường",
                        "short_codename": "xuan_an"
                    },
                    {
                        "name": "Phường Xuân Hoà",
                        "code": 26083,
                        "codename": "phuong_xuan_hoa",
                        "division_type": "phường",
                        "short_codename": "xuan_hoa"
                    },
                    {
                        "name": "Phường Phú Bình",
                        "code": 26086,
                        "codename": "phuong_phu_binh",
                        "division_type": "phường",
                        "short_codename": "phu_binh"
                    },
                    {
                        "name": "Xã Bình Lộc",
                        "code": 26089,
                        "codename": "xa_binh_loc",
                        "division_type": "xã",
                        "short_codename": "binh_loc"
                    },
                    {
                        "name": "Xã Bảo Quang",
                        "code": 26092,
                        "codename": "xa_bao_quang",
                        "division_type": "xã",
                        "short_codename": "bao_quang"
                    },
                    {
                        "name": "Phường Suối Tre",
                        "code": 26095,
                        "codename": "phuong_suoi_tre",
                        "division_type": "phường",
                        "short_codename": "suoi_tre"
                    },
                    {
                        "name": "Phường Bảo Vinh",
                        "code": 26098,
                        "codename": "phuong_bao_vinh",
                        "division_type": "phường",
                        "short_codename": "bao_vinh"
                    },
                    {
                        "name": "Phường Xuân Lập",
                        "code": 26101,
                        "codename": "phuong_xuan_lap",
                        "division_type": "phường",
                        "short_codename": "xuan_lap"
                    },
                    {
                        "name": "Phường Bàu Sen",
                        "code": 26104,
                        "codename": "phuong_bau_sen",
                        "division_type": "phường",
                        "short_codename": "bau_sen"
                    },
                    {
                        "name": "Xã Bàu Trâm",
                        "code": 26107,
                        "codename": "xa_bau_tram",
                        "division_type": "xã",
                        "short_codename": "bau_tram"
                    },
                    {
                        "name": "Phường Xuân Tân",
                        "code": 26110,
                        "codename": "phuong_xuan_tan",
                        "division_type": "phường",
                        "short_codename": "xuan_tan"
                    },
                    {
                        "name": "Xã Hàng Gòn",
                        "code": 26113,
                        "codename": "xa_hang_gon",
                        "division_type": "xã",
                        "short_codename": "hang_gon"
                    }
                ]
            },
            {
                "name": "Huyện Tân Phú",
                "code": 734,
                "codename": "huyen_tan_phu",
                "division_type": "huyện",
                "short_codename": "tan_phu",
                "wards": [
                    {
                        "name": "Thị trấn Tân Phú",
                        "code": 26116,
                        "codename": "thi_tran_tan_phu",
                        "division_type": "thị trấn",
                        "short_codename": "tan_phu"
                    },
                    {
                        "name": "Xã Dak Lua",
                        "code": 26119,
                        "codename": "xa_dak_lua",
                        "division_type": "xã",
                        "short_codename": "dak_lua"
                    },
                    {
                        "name": "Xã Nam Cát Tiên",
                        "code": 26122,
                        "codename": "xa_nam_cat_tien",
                        "division_type": "xã",
                        "short_codename": "nam_cat_tien"
                    },
                    {
                        "name": "Xã Phú An",
                        "code": 26125,
                        "codename": "xa_phu_an",
                        "division_type": "xã",
                        "short_codename": "phu_an"
                    },
                    {
                        "name": "Xã Núi Tượng",
                        "code": 26128,
                        "codename": "xa_nui_tuong",
                        "division_type": "xã",
                        "short_codename": "nui_tuong"
                    },
                    {
                        "name": "Xã Tà Lài",
                        "code": 26131,
                        "codename": "xa_ta_lai",
                        "division_type": "xã",
                        "short_codename": "ta_lai"
                    },
                    {
                        "name": "Xã Phú Lập",
                        "code": 26134,
                        "codename": "xa_phu_lap",
                        "division_type": "xã",
                        "short_codename": "phu_lap"
                    },
                    {
                        "name": "Xã Phú Sơn",
                        "code": 26137,
                        "codename": "xa_phu_son",
                        "division_type": "xã",
                        "short_codename": "phu_son"
                    },
                    {
                        "name": "Xã Phú Thịnh",
                        "code": 26140,
                        "codename": "xa_phu_thinh",
                        "division_type": "xã",
                        "short_codename": "phu_thinh"
                    },
                    {
                        "name": "Xã Thanh Sơn",
                        "code": 26143,
                        "codename": "xa_thanh_son",
                        "division_type": "xã",
                        "short_codename": "thanh_son"
                    },
                    {
                        "name": "Xã Phú Trung",
                        "code": 26146,
                        "codename": "xa_phu_trung",
                        "division_type": "xã",
                        "short_codename": "phu_trung"
                    },
                    {
                        "name": "Xã Phú Xuân",
                        "code": 26149,
                        "codename": "xa_phu_xuan",
                        "division_type": "xã",
                        "short_codename": "phu_xuan"
                    },
                    {
                        "name": "Xã Phú Lộc",
                        "code": 26152,
                        "codename": "xa_phu_loc",
                        "division_type": "xã",
                        "short_codename": "phu_loc"
                    },
                    {
                        "name": "Xã Phú Lâm",
                        "code": 26155,
                        "codename": "xa_phu_lam",
                        "division_type": "xã",
                        "short_codename": "phu_lam"
                    },
                    {
                        "name": "Xã Phú Bình",
                        "code": 26158,
                        "codename": "xa_phu_binh",
                        "division_type": "xã",
                        "short_codename": "phu_binh"
                    },
                    {
                        "name": "Xã Phú Thanh",
                        "code": 26161,
                        "codename": "xa_phu_thanh",
                        "division_type": "xã",
                        "short_codename": "phu_thanh"
                    },
                    {
                        "name": "Xã Trà Cổ",
                        "code": 26164,
                        "codename": "xa_tra_co",
                        "division_type": "xã",
                        "short_codename": "tra_co"
                    },
                    {
                        "name": "Xã Phú Điền",
                        "code": 26167,
                        "codename": "xa_phu_dien",
                        "division_type": "xã",
                        "short_codename": "phu_dien"
                    }
                ]
            },
            {
                "name": "Huyện Vĩnh Cửu",
                "code": 735,
                "codename": "huyen_vinh_cuu",
                "division_type": "huyện",
                "short_codename": "vinh_cuu",
                "wards": [
                    {
                        "name": "Thị trấn Vĩnh An",
                        "code": 26170,
                        "codename": "thi_tran_vinh_an",
                        "division_type": "thị trấn",
                        "short_codename": "vinh_an"
                    },
                    {
                        "name": "Xã Phú Lý",
                        "code": 26173,
                        "codename": "xa_phu_ly",
                        "division_type": "xã",
                        "short_codename": "phu_ly"
                    },
                    {
                        "name": "Xã Trị An",
                        "code": 26176,
                        "codename": "xa_tri_an",
                        "division_type": "xã",
                        "short_codename": "tri_an"
                    },
                    {
                        "name": "Xã Tân An",
                        "code": 26179,
                        "codename": "xa_tan_an",
                        "division_type": "xã",
                        "short_codename": "tan_an"
                    },
                    {
                        "name": "Xã Vĩnh Tân",
                        "code": 26182,
                        "codename": "xa_vinh_tan",
                        "division_type": "xã",
                        "short_codename": "vinh_tan"
                    },
                    {
                        "name": "Xã Bình Lợi",
                        "code": 26185,
                        "codename": "xa_binh_loi",
                        "division_type": "xã",
                        "short_codename": "binh_loi"
                    },
                    {
                        "name": "Xã Thạnh Phú",
                        "code": 26188,
                        "codename": "xa_thanh_phu",
                        "division_type": "xã",
                        "short_codename": "thanh_phu"
                    },
                    {
                        "name": "Xã Thiện Tân",
                        "code": 26191,
                        "codename": "xa_thien_tan",
                        "division_type": "xã",
                        "short_codename": "thien_tan"
                    },
                    {
                        "name": "Xã Tân Bình",
                        "code": 26194,
                        "codename": "xa_tan_binh",
                        "division_type": "xã",
                        "short_codename": "tan_binh"
                    },
                    {
                        "name": "Xã Bình Hòa",
                        "code": 26197,
                        "codename": "xa_binh_hoa",
                        "division_type": "xã",
                        "short_codename": "binh_hoa"
                    },
                    {
                        "name": "Xã Mã Đà",
                        "code": 26200,
                        "codename": "xa_ma_da",
                        "division_type": "xã",
                        "short_codename": "ma_da"
                    },
                    {
                        "name": "Xã Hiếu Liêm",
                        "code": 26203,
                        "codename": "xa_hieu_liem",
                        "division_type": "xã",
                        "short_codename": "hieu_liem"
                    }
                ]
            },
            {
                "name": "Huyện Định Quán",
                "code": 736,
                "codename": "huyen_dinh_quan",
                "division_type": "huyện",
                "short_codename": "dinh_quan",
                "wards": [
                    {
                        "name": "Thị trấn Định Quán",
                        "code": 26206,
                        "codename": "thi_tran_dinh_quan",
                        "division_type": "thị trấn",
                        "short_codename": "dinh_quan"
                    },
                    {
                        "name": "Xã Thanh Sơn",
                        "code": 26209,
                        "codename": "xa_thanh_son",
                        "division_type": "xã",
                        "short_codename": "thanh_son"
                    },
                    {
                        "name": "Xã Phú Tân",
                        "code": 26212,
                        "codename": "xa_phu_tan",
                        "division_type": "xã",
                        "short_codename": "phu_tan"
                    },
                    {
                        "name": "Xã Phú Vinh",
                        "code": 26215,
                        "codename": "xa_phu_vinh",
                        "division_type": "xã",
                        "short_codename": "phu_vinh"
                    },
                    {
                        "name": "Xã Phú Lợi",
                        "code": 26218,
                        "codename": "xa_phu_loi",
                        "division_type": "xã",
                        "short_codename": "phu_loi"
                    },
                    {
                        "name": "Xã Phú Hòa",
                        "code": 26221,
                        "codename": "xa_phu_hoa",
                        "division_type": "xã",
                        "short_codename": "phu_hoa"
                    },
                    {
                        "name": "Xã Ngọc Định",
                        "code": 26224,
                        "codename": "xa_ngoc_dinh",
                        "division_type": "xã",
                        "short_codename": "ngoc_dinh"
                    },
                    {
                        "name": "Xã La Ngà",
                        "code": 26227,
                        "codename": "xa_la_nga",
                        "division_type": "xã",
                        "short_codename": "la_nga"
                    },
                    {
                        "name": "Xã Gia Canh",
                        "code": 26230,
                        "codename": "xa_gia_canh",
                        "division_type": "xã",
                        "short_codename": "gia_canh"
                    },
                    {
                        "name": "Xã Phú Ngọc",
                        "code": 26233,
                        "codename": "xa_phu_ngoc",
                        "division_type": "xã",
                        "short_codename": "phu_ngoc"
                    },
                    {
                        "name": "Xã Phú Cường",
                        "code": 26236,
                        "codename": "xa_phu_cuong",
                        "division_type": "xã",
                        "short_codename": "phu_cuong"
                    },
                    {
                        "name": "Xã Túc Trưng",
                        "code": 26239,
                        "codename": "xa_tuc_trung",
                        "division_type": "xã",
                        "short_codename": "tuc_trung"
                    },
                    {
                        "name": "Xã Phú Túc",
                        "code": 26242,
                        "codename": "xa_phu_tuc",
                        "division_type": "xã",
                        "short_codename": "phu_tuc"
                    },
                    {
                        "name": "Xã Suối Nho",
                        "code": 26245,
                        "codename": "xa_suoi_nho",
                        "division_type": "xã",
                        "short_codename": "suoi_nho"
                    }
                ]
            },
            {
                "name": "Huyện Trảng Bom",
                "code": 737,
                "codename": "huyen_trang_bom",
                "division_type": "huyện",
                "short_codename": "trang_bom",
                "wards": [
                    {
                        "name": "Thị trấn Trảng Bom",
                        "code": 26248,
                        "codename": "thi_tran_trang_bom",
                        "division_type": "thị trấn",
                        "short_codename": "trang_bom"
                    },
                    {
                        "name": "Xã Thanh Bình",
                        "code": 26251,
                        "codename": "xa_thanh_binh",
                        "division_type": "xã",
                        "short_codename": "thanh_binh"
                    },
                    {
                        "name": "Xã Cây Gáo",
                        "code": 26254,
                        "codename": "xa_cay_gao",
                        "division_type": "xã",
                        "short_codename": "cay_gao"
                    },
                    {
                        "name": "Xã Bàu Hàm",
                        "code": 26257,
                        "codename": "xa_bau_ham",
                        "division_type": "xã",
                        "short_codename": "bau_ham"
                    },
                    {
                        "name": "Xã Sông Thao",
                        "code": 26260,
                        "codename": "xa_song_thao",
                        "division_type": "xã",
                        "short_codename": "song_thao"
                    },
                    {
                        "name": "Xã Sông Trầu",
                        "code": 26263,
                        "codename": "xa_song_trau",
                        "division_type": "xã",
                        "short_codename": "song_trau"
                    },
                    {
                        "name": "Xã Đông Hoà",
                        "code": 26266,
                        "codename": "xa_dong_hoa",
                        "division_type": "xã",
                        "short_codename": "dong_hoa"
                    },
                    {
                        "name": "Xã Bắc Sơn",
                        "code": 26269,
                        "codename": "xa_bac_son",
                        "division_type": "xã",
                        "short_codename": "bac_son"
                    },
                    {
                        "name": "Xã Hố Nai 3",
                        "code": 26272,
                        "codename": "xa_ho_nai_3",
                        "division_type": "xã",
                        "short_codename": "ho_nai_3"
                    },
                    {
                        "name": "Xã Tây Hoà",
                        "code": 26275,
                        "codename": "xa_tay_hoa",
                        "division_type": "xã",
                        "short_codename": "tay_hoa"
                    },
                    {
                        "name": "Xã Bình Minh",
                        "code": 26278,
                        "codename": "xa_binh_minh",
                        "division_type": "xã",
                        "short_codename": "binh_minh"
                    },
                    {
                        "name": "Xã Trung Hoà",
                        "code": 26281,
                        "codename": "xa_trung_hoa",
                        "division_type": "xã",
                        "short_codename": "trung_hoa"
                    },
                    {
                        "name": "Xã Đồi 61",
                        "code": 26284,
                        "codename": "xa_doi_61",
                        "division_type": "xã",
                        "short_codename": "doi_61"
                    },
                    {
                        "name": "Xã Hưng Thịnh",
                        "code": 26287,
                        "codename": "xa_hung_thinh",
                        "division_type": "xã",
                        "short_codename": "hung_thinh"
                    },
                    {
                        "name": "Xã Quảng Tiến",
                        "code": 26290,
                        "codename": "xa_quang_tien",
                        "division_type": "xã",
                        "short_codename": "quang_tien"
                    },
                    {
                        "name": "Xã Giang Điền",
                        "code": 26293,
                        "codename": "xa_giang_dien",
                        "division_type": "xã",
                        "short_codename": "giang_dien"
                    },
                    {
                        "name": "Xã An Viễn",
                        "code": 26296,
                        "codename": "xa_an_vien",
                        "division_type": "xã",
                        "short_codename": "an_vien"
                    }
                ]
            },
            {
                "name": "Huyện Thống Nhất",
                "code": 738,
                "codename": "huyen_thong_nhat",
                "division_type": "huyện",
                "short_codename": "thong_nhat",
                "wards": [
                    {
                        "name": "Xã Gia Tân 1",
                        "code": 26299,
                        "codename": "xa_gia_tan_1",
                        "division_type": "xã",
                        "short_codename": "gia_tan_1"
                    },
                    {
                        "name": "Xã Gia Tân 2",
                        "code": 26302,
                        "codename": "xa_gia_tan_2",
                        "division_type": "xã",
                        "short_codename": "gia_tan_2"
                    },
                    {
                        "name": "Xã Gia Tân 3",
                        "code": 26305,
                        "codename": "xa_gia_tan_3",
                        "division_type": "xã",
                        "short_codename": "gia_tan_3"
                    },
                    {
                        "name": "Xã Gia Kiệm",
                        "code": 26308,
                        "codename": "xa_gia_kiem",
                        "division_type": "xã",
                        "short_codename": "gia_kiem"
                    },
                    {
                        "name": "Xã Quang Trung",
                        "code": 26311,
                        "codename": "xa_quang_trung",
                        "division_type": "xã",
                        "short_codename": "quang_trung"
                    },
                    {
                        "name": "Xã Bàu Hàm 2",
                        "code": 26314,
                        "codename": "xa_bau_ham_2",
                        "division_type": "xã",
                        "short_codename": "bau_ham_2"
                    },
                    {
                        "name": "Xã Hưng Lộc",
                        "code": 26317,
                        "codename": "xa_hung_loc",
                        "division_type": "xã",
                        "short_codename": "hung_loc"
                    },
                    {
                        "name": "Xã Lộ 25",
                        "code": 26320,
                        "codename": "xa_lo_25",
                        "division_type": "xã",
                        "short_codename": "lo_25"
                    },
                    {
                        "name": "Xã Xuân Thiện",
                        "code": 26323,
                        "codename": "xa_xuan_thien",
                        "division_type": "xã",
                        "short_codename": "xuan_thien"
                    },
                    {
                        "name": "Thị trấn Dầu Giây",
                        "code": 26326,
                        "codename": "thi_tran_dau_giay",
                        "division_type": "thị trấn",
                        "short_codename": "dau_giay"
                    }
                ]
            },
            {
                "name": "Huyện Cẩm Mỹ",
                "code": 739,
                "codename": "huyen_cam_my",
                "division_type": "huyện",
                "short_codename": "cam_my",
                "wards": [
                    {
                        "name": "Xã Sông Nhạn",
                        "code": 26329,
                        "codename": "xa_song_nhan",
                        "division_type": "xã",
                        "short_codename": "song_nhan"
                    },
                    {
                        "name": "Xã Xuân Quế",
                        "code": 26332,
                        "codename": "xa_xuan_que",
                        "division_type": "xã",
                        "short_codename": "xuan_que"
                    },
                    {
                        "name": "Xã Nhân Nghĩa",
                        "code": 26335,
                        "codename": "xa_nhan_nghia",
                        "division_type": "xã",
                        "short_codename": "nhan_nghia"
                    },
                    {
                        "name": "Xã Xuân Đường",
                        "code": 26338,
                        "codename": "xa_xuan_duong",
                        "division_type": "xã",
                        "short_codename": "xuan_duong"
                    },
                    {
                        "name": "Xã Long Giao",
                        "code": 26341,
                        "codename": "xa_long_giao",
                        "division_type": "xã",
                        "short_codename": "long_giao"
                    },
                    {
                        "name": "Xã Xuân Mỹ",
                        "code": 26344,
                        "codename": "xa_xuan_my",
                        "division_type": "xã",
                        "short_codename": "xuan_my"
                    },
                    {
                        "name": "Xã Thừa Đức",
                        "code": 26347,
                        "codename": "xa_thua_duc",
                        "division_type": "xã",
                        "short_codename": "thua_duc"
                    },
                    {
                        "name": "Xã Bảo Bình",
                        "code": 26350,
                        "codename": "xa_bao_binh",
                        "division_type": "xã",
                        "short_codename": "bao_binh"
                    },
                    {
                        "name": "Xã Xuân Bảo",
                        "code": 26353,
                        "codename": "xa_xuan_bao",
                        "division_type": "xã",
                        "short_codename": "xuan_bao"
                    },
                    {
                        "name": "Xã Xuân Tây",
                        "code": 26356,
                        "codename": "xa_xuan_tay",
                        "division_type": "xã",
                        "short_codename": "xuan_tay"
                    },
                    {
                        "name": "Xã Xuân Đông",
                        "code": 26359,
                        "codename": "xa_xuan_dong",
                        "division_type": "xã",
                        "short_codename": "xuan_dong"
                    },
                    {
                        "name": "Xã Sông Ray",
                        "code": 26362,
                        "codename": "xa_song_ray",
                        "division_type": "xã",
                        "short_codename": "song_ray"
                    },
                    {
                        "name": "Xã Lâm San",
                        "code": 26365,
                        "codename": "xa_lam_san",
                        "division_type": "xã",
                        "short_codename": "lam_san"
                    }
                ]
            },
            {
                "name": "Huyện Long Thành",
                "code": 740,
                "codename": "huyen_long_thanh",
                "division_type": "huyện",
                "short_codename": "long_thanh",
                "wards": [
                    {
                        "name": "Thị trấn Long Thành",
                        "code": 26368,
                        "codename": "thi_tran_long_thanh",
                        "division_type": "thị trấn",
                        "short_codename": "long_thanh"
                    },
                    {
                        "name": "Xã An Phước",
                        "code": 26383,
                        "codename": "xa_an_phuoc",
                        "division_type": "xã",
                        "short_codename": "an_phuoc"
                    },
                    {
                        "name": "Xã Bình An",
                        "code": 26386,
                        "codename": "xa_binh_an",
                        "division_type": "xã",
                        "short_codename": "binh_an"
                    },
                    {
                        "name": "Xã Long Đức",
                        "code": 26389,
                        "codename": "xa_long_duc",
                        "division_type": "xã",
                        "short_codename": "long_duc"
                    },
                    {
                        "name": "Xã Lộc An",
                        "code": 26392,
                        "codename": "xa_loc_an",
                        "division_type": "xã",
                        "short_codename": "loc_an"
                    },
                    {
                        "name": "Xã Bình Sơn",
                        "code": 26395,
                        "codename": "xa_binh_son",
                        "division_type": "xã",
                        "short_codename": "binh_son"
                    },
                    {
                        "name": "Xã Tam An",
                        "code": 26398,
                        "codename": "xa_tam_an",
                        "division_type": "xã",
                        "short_codename": "tam_an"
                    },
                    {
                        "name": "Xã Cẩm Đường",
                        "code": 26401,
                        "codename": "xa_cam_duong",
                        "division_type": "xã",
                        "short_codename": "cam_duong"
                    },
                    {
                        "name": "Xã Long An",
                        "code": 26404,
                        "codename": "xa_long_an",
                        "division_type": "xã",
                        "short_codename": "long_an"
                    },
                    {
                        "name": "Xã Bàu Cạn",
                        "code": 26410,
                        "codename": "xa_bau_can",
                        "division_type": "xã",
                        "short_codename": "bau_can"
                    },
                    {
                        "name": "Xã Long Phước",
                        "code": 26413,
                        "codename": "xa_long_phuoc",
                        "division_type": "xã",
                        "short_codename": "long_phuoc"
                    },
                    {
                        "name": "Xã Phước Bình",
                        "code": 26416,
                        "codename": "xa_phuoc_binh",
                        "division_type": "xã",
                        "short_codename": "phuoc_binh"
                    },
                    {
                        "name": "Xã Tân Hiệp",
                        "code": 26419,
                        "codename": "xa_tan_hiep",
                        "division_type": "xã",
                        "short_codename": "tan_hiep"
                    },
                    {
                        "name": "Xã Phước Thái",
                        "code": 26422,
                        "codename": "xa_phuoc_thai",
                        "division_type": "xã",
                        "short_codename": "phuoc_thai"
                    }
                ]
            },
            {
                "name": "Huyện Xuân Lộc",
                "code": 741,
                "codename": "huyen_xuan_loc",
                "division_type": "huyện",
                "short_codename": "xuan_loc",
                "wards": [
                    {
                        "name": "Thị trấn Gia Ray",
                        "code": 26425,
                        "codename": "thi_tran_gia_ray",
                        "division_type": "thị trấn",
                        "short_codename": "gia_ray"
                    },
                    {
                        "name": "Xã Xuân Bắc",
                        "code": 26428,
                        "codename": "xa_xuan_bac",
                        "division_type": "xã",
                        "short_codename": "xuan_bac"
                    },
                    {
                        "name": "Xã Suối Cao",
                        "code": 26431,
                        "codename": "xa_suoi_cao",
                        "division_type": "xã",
                        "short_codename": "suoi_cao"
                    },
                    {
                        "name": "Xã Xuân Thành",
                        "code": 26434,
                        "codename": "xa_xuan_thanh",
                        "division_type": "xã",
                        "short_codename": "xuan_thanh"
                    },
                    {
                        "name": "Xã Xuân Thọ",
                        "code": 26437,
                        "codename": "xa_xuan_tho",
                        "division_type": "xã",
                        "short_codename": "xuan_tho"
                    },
                    {
                        "name": "Xã Xuân Trường",
                        "code": 26440,
                        "codename": "xa_xuan_truong",
                        "division_type": "xã",
                        "short_codename": "xuan_truong"
                    },
                    {
                        "name": "Xã Xuân Hòa",
                        "code": 26443,
                        "codename": "xa_xuan_hoa",
                        "division_type": "xã",
                        "short_codename": "xuan_hoa"
                    },
                    {
                        "name": "Xã Xuân Hưng",
                        "code": 26446,
                        "codename": "xa_xuan_hung",
                        "division_type": "xã",
                        "short_codename": "xuan_hung"
                    },
                    {
                        "name": "Xã Xuân Tâm",
                        "code": 26449,
                        "codename": "xa_xuan_tam",
                        "division_type": "xã",
                        "short_codename": "xuan_tam"
                    },
                    {
                        "name": "Xã Suối Cát",
                        "code": 26452,
                        "codename": "xa_suoi_cat",
                        "division_type": "xã",
                        "short_codename": "suoi_cat"
                    },
                    {
                        "name": "Xã Xuân Hiệp",
                        "code": 26455,
                        "codename": "xa_xuan_hiep",
                        "division_type": "xã",
                        "short_codename": "xuan_hiep"
                    },
                    {
                        "name": "Xã Xuân Phú",
                        "code": 26458,
                        "codename": "xa_xuan_phu",
                        "division_type": "xã",
                        "short_codename": "xuan_phu"
                    },
                    {
                        "name": "Xã Xuân Định",
                        "code": 26461,
                        "codename": "xa_xuan_dinh",
                        "division_type": "xã",
                        "short_codename": "xuan_dinh"
                    },
                    {
                        "name": "Xã Bảo Hoà",
                        "code": 26464,
                        "codename": "xa_bao_hoa",
                        "division_type": "xã",
                        "short_codename": "bao_hoa"
                    },
                    {
                        "name": "Xã Lang Minh",
                        "code": 26467,
                        "codename": "xa_lang_minh",
                        "division_type": "xã",
                        "short_codename": "lang_minh"
                    }
                ]
            },
            {
                "name": "Huyện Nhơn Trạch",
                "code": 742,
                "codename": "huyen_nhon_trach",
                "division_type": "huyện",
                "short_codename": "nhon_trach",
                "wards": [
                    {
                        "name": "Xã Phước Thiền",
                        "code": 26470,
                        "codename": "xa_phuoc_thien",
                        "division_type": "xã",
                        "short_codename": "phuoc_thien"
                    },
                    {
                        "name": "Xã Long Tân",
                        "code": 26473,
                        "codename": "xa_long_tan",
                        "division_type": "xã",
                        "short_codename": "long_tan"
                    },
                    {
                        "name": "Xã Đại Phước",
                        "code": 26476,
                        "codename": "xa_dai_phuoc",
                        "division_type": "xã",
                        "short_codename": "dai_phuoc"
                    },
                    {
                        "name": "Thị trấn Hiệp Phước",
                        "code": 26479,
                        "codename": "thi_tran_hiep_phuoc",
                        "division_type": "thị trấn",
                        "short_codename": "hiep_phuoc"
                    },
                    {
                        "name": "Xã Phú Hữu",
                        "code": 26482,
                        "codename": "xa_phu_huu",
                        "division_type": "xã",
                        "short_codename": "phu_huu"
                    },
                    {
                        "name": "Xã Phú Hội",
                        "code": 26485,
                        "codename": "xa_phu_hoi",
                        "division_type": "xã",
                        "short_codename": "phu_hoi"
                    },
                    {
                        "name": "Xã Phú Thạnh",
                        "code": 26488,
                        "codename": "xa_phu_thanh",
                        "division_type": "xã",
                        "short_codename": "phu_thanh"
                    },
                    {
                        "name": "Xã Phú Đông",
                        "code": 26491,
                        "codename": "xa_phu_dong",
                        "division_type": "xã",
                        "short_codename": "phu_dong"
                    },
                    {
                        "name": "Xã Long Thọ",
                        "code": 26494,
                        "codename": "xa_long_tho",
                        "division_type": "xã",
                        "short_codename": "long_tho"
                    },
                    {
                        "name": "Xã Vĩnh Thanh",
                        "code": 26497,
                        "codename": "xa_vinh_thanh",
                        "division_type": "xã",
                        "short_codename": "vinh_thanh"
                    },
                    {
                        "name": "Xã Phước Khánh",
                        "code": 26500,
                        "codename": "xa_phuoc_khanh",
                        "division_type": "xã",
                        "short_codename": "phuoc_khanh"
                    },
                    {
                        "name": "Xã Phước An",
                        "code": 26503,
                        "codename": "xa_phuoc_an",
                        "division_type": "xã",
                        "short_codename": "phuoc_an"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Bà Rịa - Vũng Tàu",
        "code": 77,
        "codename": "tinh_ba_ria_vung_tau",
        "division_type": "tỉnh",
        "phone_code": 254,
        "districts": [
            {
                "name": "Thành phố Vũng Tàu",
                "code": 747,
                "codename": "thanh_pho_vung_tau",
                "division_type": "thành phố",
                "short_codename": "vung_tau",
                "wards": [
                    {
                        "name": "Phường 1",
                        "code": 26506,
                        "codename": "phuong_1",
                        "division_type": "phường",
                        "short_codename": "phuong_1"
                    },
                    {
                        "name": "Phường Thắng Tam",
                        "code": 26508,
                        "codename": "phuong_thang_tam",
                        "division_type": "phường",
                        "short_codename": "thang_tam"
                    },
                    {
                        "name": "Phường 2",
                        "code": 26509,
                        "codename": "phuong_2",
                        "division_type": "phường",
                        "short_codename": "phuong_2"
                    },
                    {
                        "name": "Phường 3",
                        "code": 26512,
                        "codename": "phuong_3",
                        "division_type": "phường",
                        "short_codename": "phuong_3"
                    },
                    {
                        "name": "Phường 4",
                        "code": 26515,
                        "codename": "phuong_4",
                        "division_type": "phường",
                        "short_codename": "phuong_4"
                    },
                    {
                        "name": "Phường 5",
                        "code": 26518,
                        "codename": "phuong_5",
                        "division_type": "phường",
                        "short_codename": "phuong_5"
                    },
                    {
                        "name": "Phường Thắng Nhì",
                        "code": 26521,
                        "codename": "phuong_thang_nhi",
                        "division_type": "phường",
                        "short_codename": "thang_nhi"
                    },
                    {
                        "name": "Phường 7",
                        "code": 26524,
                        "codename": "phuong_7",
                        "division_type": "phường",
                        "short_codename": "phuong_7"
                    },
                    {
                        "name": "Phường Nguyễn An Ninh",
                        "code": 26526,
                        "codename": "phuong_nguyen_an_ninh",
                        "division_type": "phường",
                        "short_codename": "nguyen_an_ninh"
                    },
                    {
                        "name": "Phường 8",
                        "code": 26527,
                        "codename": "phuong_8",
                        "division_type": "phường",
                        "short_codename": "phuong_8"
                    },
                    {
                        "name": "Phường 9",
                        "code": 26530,
                        "codename": "phuong_9",
                        "division_type": "phường",
                        "short_codename": "phuong_9"
                    },
                    {
                        "name": "Phường Thắng Nhất",
                        "code": 26533,
                        "codename": "phuong_thang_nhat",
                        "division_type": "phường",
                        "short_codename": "thang_nhat"
                    },
                    {
                        "name": "Phường Rạch Dừa",
                        "code": 26535,
                        "codename": "phuong_rach_dua",
                        "division_type": "phường",
                        "short_codename": "rach_dua"
                    },
                    {
                        "name": "Phường 10",
                        "code": 26536,
                        "codename": "phuong_10",
                        "division_type": "phường",
                        "short_codename": "phuong_10"
                    },
                    {
                        "name": "Phường 11",
                        "code": 26539,
                        "codename": "phuong_11",
                        "division_type": "phường",
                        "short_codename": "phuong_11"
                    },
                    {
                        "name": "Phường 12",
                        "code": 26542,
                        "codename": "phuong_12",
                        "division_type": "phường",
                        "short_codename": "phuong_12"
                    },
                    {
                        "name": "Xã Long Sơn",
                        "code": 26545,
                        "codename": "xa_long_son",
                        "division_type": "xã",
                        "short_codename": "long_son"
                    }
                ]
            },
            {
                "name": "Thành phố Bà Rịa",
                "code": 748,
                "codename": "thanh_pho_ba_ria",
                "division_type": "thành phố",
                "short_codename": "ba_ria",
                "wards": [
                    {
                        "name": "Phường Phước Hưng",
                        "code": 26548,
                        "codename": "phuong_phuoc_hung",
                        "division_type": "phường",
                        "short_codename": "phuoc_hung"
                    },
                    {
                        "name": "Phường Phước Hiệp",
                        "code": 26551,
                        "codename": "phuong_phuoc_hiep",
                        "division_type": "phường",
                        "short_codename": "phuoc_hiep"
                    },
                    {
                        "name": "Phường Phước Nguyên",
                        "code": 26554,
                        "codename": "phuong_phuoc_nguyen",
                        "division_type": "phường",
                        "short_codename": "phuoc_nguyen"
                    },
                    {
                        "name": "Phường Long Toàn",
                        "code": 26557,
                        "codename": "phuong_long_toan",
                        "division_type": "phường",
                        "short_codename": "long_toan"
                    },
                    {
                        "name": "Phường Long Tâm",
                        "code": 26558,
                        "codename": "phuong_long_tam",
                        "division_type": "phường",
                        "short_codename": "long_tam"
                    },
                    {
                        "name": "Phường Phước Trung",
                        "code": 26560,
                        "codename": "phuong_phuoc_trung",
                        "division_type": "phường",
                        "short_codename": "phuoc_trung"
                    },
                    {
                        "name": "Phường Long Hương",
                        "code": 26563,
                        "codename": "phuong_long_huong",
                        "division_type": "phường",
                        "short_codename": "long_huong"
                    },
                    {
                        "name": "Phường Kim Dinh",
                        "code": 26566,
                        "codename": "phuong_kim_dinh",
                        "division_type": "phường",
                        "short_codename": "kim_dinh"
                    },
                    {
                        "name": "Xã Tân Hưng",
                        "code": 26567,
                        "codename": "xa_tan_hung",
                        "division_type": "xã",
                        "short_codename": "tan_hung"
                    },
                    {
                        "name": "Xã Long Phước",
                        "code": 26569,
                        "codename": "xa_long_phuoc",
                        "division_type": "xã",
                        "short_codename": "long_phuoc"
                    },
                    {
                        "name": "Xã Hoà Long",
                        "code": 26572,
                        "codename": "xa_hoa_long",
                        "division_type": "xã",
                        "short_codename": "hoa_long"
                    }
                ]
            },
            {
                "name": "Huyện Châu Đức",
                "code": 750,
                "codename": "huyen_chau_duc",
                "division_type": "huyện",
                "short_codename": "chau_duc",
                "wards": [
                    {
                        "name": "Xã Bàu Chinh",
                        "code": 26574,
                        "codename": "xa_bau_chinh",
                        "division_type": "xã",
                        "short_codename": "bau_chinh"
                    },
                    {
                        "name": "Thị trấn Ngãi Giao",
                        "code": 26575,
                        "codename": "thi_tran_ngai_giao",
                        "division_type": "thị trấn",
                        "short_codename": "ngai_giao"
                    },
                    {
                        "name": "Xã Bình Ba",
                        "code": 26578,
                        "codename": "xa_binh_ba",
                        "division_type": "xã",
                        "short_codename": "binh_ba"
                    },
                    {
                        "name": "Xã Suối Nghệ",
                        "code": 26581,
                        "codename": "xa_suoi_nghe",
                        "division_type": "xã",
                        "short_codename": "suoi_nghe"
                    },
                    {
                        "name": "Xã Xuân Sơn",
                        "code": 26584,
                        "codename": "xa_xuan_son",
                        "division_type": "xã",
                        "short_codename": "xuan_son"
                    },
                    {
                        "name": "Xã Sơn Bình",
                        "code": 26587,
                        "codename": "xa_son_binh",
                        "division_type": "xã",
                        "short_codename": "son_binh"
                    },
                    {
                        "name": "Xã Bình Giã",
                        "code": 26590,
                        "codename": "xa_binh_gia",
                        "division_type": "xã",
                        "short_codename": "binh_gia"
                    },
                    {
                        "name": "Xã Bình Trung",
                        "code": 26593,
                        "codename": "xa_binh_trung",
                        "division_type": "xã",
                        "short_codename": "binh_trung"
                    },
                    {
                        "name": "Xã Xà Bang",
                        "code": 26596,
                        "codename": "xa_xa_bang",
                        "division_type": "xã",
                        "short_codename": "xa_bang"
                    },
                    {
                        "name": "Xã Cù Bị",
                        "code": 26599,
                        "codename": "xa_cu_bi",
                        "division_type": "xã",
                        "short_codename": "cu_bi"
                    },
                    {
                        "name": "Xã Láng Lớn",
                        "code": 26602,
                        "codename": "xa_lang_lon",
                        "division_type": "xã",
                        "short_codename": "lang_lon"
                    },
                    {
                        "name": "Xã Quảng Thành",
                        "code": 26605,
                        "codename": "xa_quang_thanh",
                        "division_type": "xã",
                        "short_codename": "quang_thanh"
                    },
                    {
                        "name": "Xã Kim Long",
                        "code": 26608,
                        "codename": "xa_kim_long",
                        "division_type": "xã",
                        "short_codename": "kim_long"
                    },
                    {
                        "name": "Xã Suối Rao",
                        "code": 26611,
                        "codename": "xa_suoi_rao",
                        "division_type": "xã",
                        "short_codename": "suoi_rao"
                    },
                    {
                        "name": "Xã Đá Bạc",
                        "code": 26614,
                        "codename": "xa_da_bac",
                        "division_type": "xã",
                        "short_codename": "da_bac"
                    },
                    {
                        "name": "Xã Nghĩa Thành",
                        "code": 26617,
                        "codename": "xa_nghia_thanh",
                        "division_type": "xã",
                        "short_codename": "nghia_thanh"
                    }
                ]
            },
            {
                "name": "Huyện Xuyên Mộc",
                "code": 751,
                "codename": "huyen_xuyen_moc",
                "division_type": "huyện",
                "short_codename": "xuyen_moc",
                "wards": [
                    {
                        "name": "Thị trấn Phước Bửu",
                        "code": 26620,
                        "codename": "thi_tran_phuoc_buu",
                        "division_type": "thị trấn",
                        "short_codename": "phuoc_buu"
                    },
                    {
                        "name": "Xã Phước Thuận",
                        "code": 26623,
                        "codename": "xa_phuoc_thuan",
                        "division_type": "xã",
                        "short_codename": "phuoc_thuan"
                    },
                    {
                        "name": "Xã Phước Tân",
                        "code": 26626,
                        "codename": "xa_phuoc_tan",
                        "division_type": "xã",
                        "short_codename": "phuoc_tan"
                    },
                    {
                        "name": "Xã Xuyên Mộc",
                        "code": 26629,
                        "codename": "xa_xuyen_moc",
                        "division_type": "xã",
                        "short_codename": "xuyen_moc"
                    },
                    {
                        "name": "Xã Bông Trang",
                        "code": 26632,
                        "codename": "xa_bong_trang",
                        "division_type": "xã",
                        "short_codename": "bong_trang"
                    },
                    {
                        "name": "Xã Tân Lâm",
                        "code": 26635,
                        "codename": "xa_tan_lam",
                        "division_type": "xã",
                        "short_codename": "tan_lam"
                    },
                    {
                        "name": "Xã Bàu Lâm",
                        "code": 26638,
                        "codename": "xa_bau_lam",
                        "division_type": "xã",
                        "short_codename": "bau_lam"
                    },
                    {
                        "name": "Xã Hòa Bình",
                        "code": 26641,
                        "codename": "xa_hoa_binh",
                        "division_type": "xã",
                        "short_codename": "hoa_binh"
                    },
                    {
                        "name": "Xã Hòa Hưng",
                        "code": 26644,
                        "codename": "xa_hoa_hung",
                        "division_type": "xã",
                        "short_codename": "hoa_hung"
                    },
                    {
                        "name": "Xã Hòa Hiệp",
                        "code": 26647,
                        "codename": "xa_hoa_hiep",
                        "division_type": "xã",
                        "short_codename": "hoa_hiep"
                    },
                    {
                        "name": "Xã Hòa Hội",
                        "code": 26650,
                        "codename": "xa_hoa_hoi",
                        "division_type": "xã",
                        "short_codename": "hoa_hoi"
                    },
                    {
                        "name": "Xã Bưng Riềng",
                        "code": 26653,
                        "codename": "xa_bung_rieng",
                        "division_type": "xã",
                        "short_codename": "bung_rieng"
                    },
                    {
                        "name": "Xã Bình Châu",
                        "code": 26656,
                        "codename": "xa_binh_chau",
                        "division_type": "xã",
                        "short_codename": "binh_chau"
                    }
                ]
            },
            {
                "name": "Huyện Long Điền",
                "code": 752,
                "codename": "huyen_long_dien",
                "division_type": "huyện",
                "short_codename": "long_dien",
                "wards": [
                    {
                        "name": "Thị trấn Long Điền",
                        "code": 26659,
                        "codename": "thi_tran_long_dien",
                        "division_type": "thị trấn",
                        "short_codename": "long_dien"
                    },
                    {
                        "name": "Thị trấn Long Hải",
                        "code": 26662,
                        "codename": "thi_tran_long_hai",
                        "division_type": "thị trấn",
                        "short_codename": "long_hai"
                    },
                    {
                        "name": "Xã An Ngãi",
                        "code": 26665,
                        "codename": "xa_an_ngai",
                        "division_type": "xã",
                        "short_codename": "an_ngai"
                    },
                    {
                        "name": "Xã Tam Phước",
                        "code": 26668,
                        "codename": "xa_tam_phuoc",
                        "division_type": "xã",
                        "short_codename": "tam_phuoc"
                    },
                    {
                        "name": "Xã An Nhứt",
                        "code": 26671,
                        "codename": "xa_an_nhut",
                        "division_type": "xã",
                        "short_codename": "an_nhut"
                    },
                    {
                        "name": "Xã Phước Tỉnh",
                        "code": 26674,
                        "codename": "xa_phuoc_tinh",
                        "division_type": "xã",
                        "short_codename": "phuoc_tinh"
                    },
                    {
                        "name": "Xã Phước Hưng",
                        "code": 26677,
                        "codename": "xa_phuoc_hung",
                        "division_type": "xã",
                        "short_codename": "phuoc_hung"
                    }
                ]
            },
            {
                "name": "Huyện Đất Đỏ",
                "code": 753,
                "codename": "huyen_dat_do",
                "division_type": "huyện",
                "short_codename": "dat_do",
                "wards": [
                    {
                        "name": "Thị trấn Đất Đỏ",
                        "code": 26680,
                        "codename": "thi_tran_dat_do",
                        "division_type": "thị trấn",
                        "short_codename": "dat_do"
                    },
                    {
                        "name": "Xã Phước Long Thọ",
                        "code": 26683,
                        "codename": "xa_phuoc_long_tho",
                        "division_type": "xã",
                        "short_codename": "phuoc_long_tho"
                    },
                    {
                        "name": "Xã Phước Hội",
                        "code": 26686,
                        "codename": "xa_phuoc_hoi",
                        "division_type": "xã",
                        "short_codename": "phuoc_hoi"
                    },
                    {
                        "name": "Xã Long Mỹ",
                        "code": 26689,
                        "codename": "xa_long_my",
                        "division_type": "xã",
                        "short_codename": "long_my"
                    },
                    {
                        "name": "Thị trấn Phước Hải",
                        "code": 26692,
                        "codename": "thi_tran_phuoc_hai",
                        "division_type": "thị trấn",
                        "short_codename": "phuoc_hai"
                    },
                    {
                        "name": "Xã Long Tân",
                        "code": 26695,
                        "codename": "xa_long_tan",
                        "division_type": "xã",
                        "short_codename": "long_tan"
                    },
                    {
                        "name": "Xã Láng Dài",
                        "code": 26698,
                        "codename": "xa_lang_dai",
                        "division_type": "xã",
                        "short_codename": "lang_dai"
                    },
                    {
                        "name": "Xã Lộc An",
                        "code": 26701,
                        "codename": "xa_loc_an",
                        "division_type": "xã",
                        "short_codename": "loc_an"
                    }
                ]
            },
            {
                "name": "Thị xã Phú Mỹ",
                "code": 754,
                "codename": "thi_xa_phu_my",
                "division_type": "thị xã",
                "short_codename": "phu_my",
                "wards": [
                    {
                        "name": "Phường Phú Mỹ",
                        "code": 26704,
                        "codename": "phuong_phu_my",
                        "division_type": "phường",
                        "short_codename": "phu_my"
                    },
                    {
                        "name": "Xã Tân Hoà",
                        "code": 26707,
                        "codename": "xa_tan_hoa",
                        "division_type": "xã",
                        "short_codename": "tan_hoa"
                    },
                    {
                        "name": "Xã Tân Hải",
                        "code": 26710,
                        "codename": "xa_tan_hai",
                        "division_type": "xã",
                        "short_codename": "tan_hai"
                    },
                    {
                        "name": "Phường Phước Hoà",
                        "code": 26713,
                        "codename": "phuong_phuoc_hoa",
                        "division_type": "phường",
                        "short_codename": "phuoc_hoa"
                    },
                    {
                        "name": "Phường Tân Phước",
                        "code": 26716,
                        "codename": "phuong_tan_phuoc",
                        "division_type": "phường",
                        "short_codename": "tan_phuoc"
                    },
                    {
                        "name": "Phường Mỹ Xuân",
                        "code": 26719,
                        "codename": "phuong_my_xuan",
                        "division_type": "phường",
                        "short_codename": "my_xuan"
                    },
                    {
                        "name": "Xã Sông Xoài",
                        "code": 26722,
                        "codename": "xa_song_xoai",
                        "division_type": "xã",
                        "short_codename": "song_xoai"
                    },
                    {
                        "name": "Phường Hắc Dịch",
                        "code": 26725,
                        "codename": "phuong_hac_dich",
                        "division_type": "phường",
                        "short_codename": "hac_dich"
                    },
                    {
                        "name": "Xã Châu Pha",
                        "code": 26728,
                        "codename": "xa_chau_pha",
                        "division_type": "xã",
                        "short_codename": "chau_pha"
                    },
                    {
                        "name": "Xã Tóc Tiên",
                        "code": 26731,
                        "codename": "xa_toc_tien",
                        "division_type": "xã",
                        "short_codename": "toc_tien"
                    }
                ]
            },
            {
                "name": "Huyện Côn Đảo",
                "code": 755,
                "codename": "huyen_con_dao",
                "division_type": "huyện",
                "short_codename": "con_dao",
                "wards": []
            }
        ]
    },
    {
        "name": "Thành phố Hồ Chí Minh",
        "code": 79,
        "codename": "thanh_pho_ho_chi_minh",
        "division_type": "thành phố trung ương",
        "phone_code": 28,
        "districts": [
            {
                "name": "Quận 1",
                "code": 760,
                "codename": "quan_1",
                "division_type": "quận",
                "short_codename": "quan_1",
                "wards": [
                    {
                        "name": "Phường Tân Định",
                        "code": 26734,
                        "codename": "phuong_tan_dinh",
                        "division_type": "phường",
                        "short_codename": "tan_dinh"
                    },
                    {
                        "name": "Phường Đa Kao",
                        "code": 26737,
                        "codename": "phuong_da_kao",
                        "division_type": "phường",
                        "short_codename": "da_kao"
                    },
                    {
                        "name": "Phường Bến Nghé",
                        "code": 26740,
                        "codename": "phuong_ben_nghe",
                        "division_type": "phường",
                        "short_codename": "ben_nghe"
                    },
                    {
                        "name": "Phường Bến Thành",
                        "code": 26743,
                        "codename": "phuong_ben_thanh",
                        "division_type": "phường",
                        "short_codename": "ben_thanh"
                    },
                    {
                        "name": "Phường Nguyễn Thái Bình",
                        "code": 26746,
                        "codename": "phuong_nguyen_thai_binh",
                        "division_type": "phường",
                        "short_codename": "nguyen_thai_binh"
                    },
                    {
                        "name": "Phường Phạm Ngũ Lão",
                        "code": 26749,
                        "codename": "phuong_pham_ngu_lao",
                        "division_type": "phường",
                        "short_codename": "pham_ngu_lao"
                    },
                    {
                        "name": "Phường Cầu Ông Lãnh",
                        "code": 26752,
                        "codename": "phuong_cau_ong_lanh",
                        "division_type": "phường",
                        "short_codename": "cau_ong_lanh"
                    },
                    {
                        "name": "Phường Cô Giang",
                        "code": 26755,
                        "codename": "phuong_co_giang",
                        "division_type": "phường",
                        "short_codename": "co_giang"
                    },
                    {
                        "name": "Phường Nguyễn Cư Trinh",
                        "code": 26758,
                        "codename": "phuong_nguyen_cu_trinh",
                        "division_type": "phường",
                        "short_codename": "nguyen_cu_trinh"
                    },
                    {
                        "name": "Phường Cầu Kho",
                        "code": 26761,
                        "codename": "phuong_cau_kho",
                        "division_type": "phường",
                        "short_codename": "cau_kho"
                    }
                ]
            },
            {
                "name": "Quận 12",
                "code": 761,
                "codename": "quan_12",
                "division_type": "quận",
                "short_codename": "quan_12",
                "wards": [
                    {
                        "name": "Phường Thạnh Xuân",
                        "code": 26764,
                        "codename": "phuong_thanh_xuan",
                        "division_type": "phường",
                        "short_codename": "thanh_xuan"
                    },
                    {
                        "name": "Phường Thạnh Lộc",
                        "code": 26767,
                        "codename": "phuong_thanh_loc",
                        "division_type": "phường",
                        "short_codename": "thanh_loc"
                    },
                    {
                        "name": "Phường Hiệp Thành",
                        "code": 26770,
                        "codename": "phuong_hiep_thanh",
                        "division_type": "phường",
                        "short_codename": "hiep_thanh"
                    },
                    {
                        "name": "Phường Thới An",
                        "code": 26773,
                        "codename": "phuong_thoi_an",
                        "division_type": "phường",
                        "short_codename": "thoi_an"
                    },
                    {
                        "name": "Phường Tân Chánh Hiệp",
                        "code": 26776,
                        "codename": "phuong_tan_chanh_hiep",
                        "division_type": "phường",
                        "short_codename": "tan_chanh_hiep"
                    },
                    {
                        "name": "Phường An Phú Đông",
                        "code": 26779,
                        "codename": "phuong_an_phu_dong",
                        "division_type": "phường",
                        "short_codename": "an_phu_dong"
                    },
                    {
                        "name": "Phường Tân Thới Hiệp",
                        "code": 26782,
                        "codename": "phuong_tan_thoi_hiep",
                        "division_type": "phường",
                        "short_codename": "tan_thoi_hiep"
                    },
                    {
                        "name": "Phường Trung Mỹ Tây",
                        "code": 26785,
                        "codename": "phuong_trung_my_tay",
                        "division_type": "phường",
                        "short_codename": "trung_my_tay"
                    },
                    {
                        "name": "Phường Tân Hưng Thuận",
                        "code": 26787,
                        "codename": "phuong_tan_hung_thuan",
                        "division_type": "phường",
                        "short_codename": "tan_hung_thuan"
                    },
                    {
                        "name": "Phường Đông Hưng Thuận",
                        "code": 26788,
                        "codename": "phuong_dong_hung_thuan",
                        "division_type": "phường",
                        "short_codename": "dong_hung_thuan"
                    },
                    {
                        "name": "Phường Tân Thới Nhất",
                        "code": 26791,
                        "codename": "phuong_tan_thoi_nhat",
                        "division_type": "phường",
                        "short_codename": "tan_thoi_nhat"
                    }
                ]
            },
            {
                "name": "Quận Gò Vấp",
                "code": 764,
                "codename": "quan_go_vap",
                "division_type": "quận",
                "short_codename": "go_vap",
                "wards": [
                    {
                        "name": "Phường 15",
                        "code": 26869,
                        "codename": "phuong_15",
                        "division_type": "phường",
                        "short_codename": "phuong_15"
                    },
                    {
                        "name": "Phường 13",
                        "code": 26872,
                        "codename": "phuong_13",
                        "division_type": "phường",
                        "short_codename": "phuong_13"
                    },
                    {
                        "name": "Phường 17",
                        "code": 26875,
                        "codename": "phuong_17",
                        "division_type": "phường",
                        "short_codename": "phuong_17"
                    },
                    {
                        "name": "Phường 6",
                        "code": 26876,
                        "codename": "phuong_6",
                        "division_type": "phường",
                        "short_codename": "phuong_6"
                    },
                    {
                        "name": "Phường 16",
                        "code": 26878,
                        "codename": "phuong_16",
                        "division_type": "phường",
                        "short_codename": "phuong_16"
                    },
                    {
                        "name": "Phường 12",
                        "code": 26881,
                        "codename": "phuong_12",
                        "division_type": "phường",
                        "short_codename": "phuong_12"
                    },
                    {
                        "name": "Phường 14",
                        "code": 26882,
                        "codename": "phuong_14",
                        "division_type": "phường",
                        "short_codename": "phuong_14"
                    },
                    {
                        "name": "Phường 10",
                        "code": 26884,
                        "codename": "phuong_10",
                        "division_type": "phường",
                        "short_codename": "phuong_10"
                    },
                    {
                        "name": "Phường 05",
                        "code": 26887,
                        "codename": "phuong_05",
                        "division_type": "phường",
                        "short_codename": "phuong_05"
                    },
                    {
                        "name": "Phường 07",
                        "code": 26890,
                        "codename": "phuong_07",
                        "division_type": "phường",
                        "short_codename": "phuong_07"
                    },
                    {
                        "name": "Phường 04",
                        "code": 26893,
                        "codename": "phuong_04",
                        "division_type": "phường",
                        "short_codename": "phuong_04"
                    },
                    {
                        "name": "Phường 01",
                        "code": 26896,
                        "codename": "phuong_01",
                        "division_type": "phường",
                        "short_codename": "phuong_01"
                    },
                    {
                        "name": "Phường 9",
                        "code": 26897,
                        "codename": "phuong_9",
                        "division_type": "phường",
                        "short_codename": "phuong_9"
                    },
                    {
                        "name": "Phường 8",
                        "code": 26898,
                        "codename": "phuong_8",
                        "division_type": "phường",
                        "short_codename": "phuong_8"
                    },
                    {
                        "name": "Phường 11",
                        "code": 26899,
                        "codename": "phuong_11",
                        "division_type": "phường",
                        "short_codename": "phuong_11"
                    },
                    {
                        "name": "Phường 03",
                        "code": 26902,
                        "codename": "phuong_03",
                        "division_type": "phường",
                        "short_codename": "phuong_03"
                    }
                ]
            },
            {
                "name": "Quận Bình Thạnh",
                "code": 765,
                "codename": "quan_binh_thanh",
                "division_type": "quận",
                "short_codename": "binh_thanh",
                "wards": [
                    {
                        "name": "Phường 13",
                        "code": 26905,
                        "codename": "phuong_13",
                        "division_type": "phường",
                        "short_codename": "phuong_13"
                    },
                    {
                        "name": "Phường 11",
                        "code": 26908,
                        "codename": "phuong_11",
                        "division_type": "phường",
                        "short_codename": "phuong_11"
                    },
                    {
                        "name": "Phường 27",
                        "code": 26911,
                        "codename": "phuong_27",
                        "division_type": "phường",
                        "short_codename": "phuong_27"
                    },
                    {
                        "name": "Phường 26",
                        "code": 26914,
                        "codename": "phuong_26",
                        "division_type": "phường",
                        "short_codename": "phuong_26"
                    },
                    {
                        "name": "Phường 12",
                        "code": 26917,
                        "codename": "phuong_12",
                        "division_type": "phường",
                        "short_codename": "phuong_12"
                    },
                    {
                        "name": "Phường 25",
                        "code": 26920,
                        "codename": "phuong_25",
                        "division_type": "phường",
                        "short_codename": "phuong_25"
                    },
                    {
                        "name": "Phường 05",
                        "code": 26923,
                        "codename": "phuong_05",
                        "division_type": "phường",
                        "short_codename": "phuong_05"
                    },
                    {
                        "name": "Phường 07",
                        "code": 26926,
                        "codename": "phuong_07",
                        "division_type": "phường",
                        "short_codename": "phuong_07"
                    },
                    {
                        "name": "Phường 24",
                        "code": 26929,
                        "codename": "phuong_24",
                        "division_type": "phường",
                        "short_codename": "phuong_24"
                    },
                    {
                        "name": "Phường 06",
                        "code": 26932,
                        "codename": "phuong_06",
                        "division_type": "phường",
                        "short_codename": "phuong_06"
                    },
                    {
                        "name": "Phường 14",
                        "code": 26935,
                        "codename": "phuong_14",
                        "division_type": "phường",
                        "short_codename": "phuong_14"
                    },
                    {
                        "name": "Phường 15",
                        "code": 26938,
                        "codename": "phuong_15",
                        "division_type": "phường",
                        "short_codename": "phuong_15"
                    },
                    {
                        "name": "Phường 02",
                        "code": 26941,
                        "codename": "phuong_02",
                        "division_type": "phường",
                        "short_codename": "phuong_02"
                    },
                    {
                        "name": "Phường 01",
                        "code": 26944,
                        "codename": "phuong_01",
                        "division_type": "phường",
                        "short_codename": "phuong_01"
                    },
                    {
                        "name": "Phường 03",
                        "code": 26947,
                        "codename": "phuong_03",
                        "division_type": "phường",
                        "short_codename": "phuong_03"
                    },
                    {
                        "name": "Phường 17",
                        "code": 26950,
                        "codename": "phuong_17",
                        "division_type": "phường",
                        "short_codename": "phuong_17"
                    },
                    {
                        "name": "Phường 21",
                        "code": 26953,
                        "codename": "phuong_21",
                        "division_type": "phường",
                        "short_codename": "phuong_21"
                    },
                    {
                        "name": "Phường 22",
                        "code": 26956,
                        "codename": "phuong_22",
                        "division_type": "phường",
                        "short_codename": "phuong_22"
                    },
                    {
                        "name": "Phường 19",
                        "code": 26959,
                        "codename": "phuong_19",
                        "division_type": "phường",
                        "short_codename": "phuong_19"
                    },
                    {
                        "name": "Phường 28",
                        "code": 26962,
                        "codename": "phuong_28",
                        "division_type": "phường",
                        "short_codename": "phuong_28"
                    }
                ]
            },
            {
                "name": "Quận Tân Bình",
                "code": 766,
                "codename": "quan_tan_binh",
                "division_type": "quận",
                "short_codename": "tan_binh",
                "wards": [
                    {
                        "name": "Phường 02",
                        "code": 26965,
                        "codename": "phuong_02",
                        "division_type": "phường",
                        "short_codename": "phuong_02"
                    },
                    {
                        "name": "Phường 04",
                        "code": 26968,
                        "codename": "phuong_04",
                        "division_type": "phường",
                        "short_codename": "phuong_04"
                    },
                    {
                        "name": "Phường 12",
                        "code": 26971,
                        "codename": "phuong_12",
                        "division_type": "phường",
                        "short_codename": "phuong_12"
                    },
                    {
                        "name": "Phường 13",
                        "code": 26974,
                        "codename": "phuong_13",
                        "division_type": "phường",
                        "short_codename": "phuong_13"
                    },
                    {
                        "name": "Phường 01",
                        "code": 26977,
                        "codename": "phuong_01",
                        "division_type": "phường",
                        "short_codename": "phuong_01"
                    },
                    {
                        "name": "Phường 03",
                        "code": 26980,
                        "codename": "phuong_03",
                        "division_type": "phường",
                        "short_codename": "phuong_03"
                    },
                    {
                        "name": "Phường 11",
                        "code": 26983,
                        "codename": "phuong_11",
                        "division_type": "phường",
                        "short_codename": "phuong_11"
                    },
                    {
                        "name": "Phường 07",
                        "code": 26986,
                        "codename": "phuong_07",
                        "division_type": "phường",
                        "short_codename": "phuong_07"
                    },
                    {
                        "name": "Phường 05",
                        "code": 26989,
                        "codename": "phuong_05",
                        "division_type": "phường",
                        "short_codename": "phuong_05"
                    },
                    {
                        "name": "Phường 10",
                        "code": 26992,
                        "codename": "phuong_10",
                        "division_type": "phường",
                        "short_codename": "phuong_10"
                    },
                    {
                        "name": "Phường 06",
                        "code": 26995,
                        "codename": "phuong_06",
                        "division_type": "phường",
                        "short_codename": "phuong_06"
                    },
                    {
                        "name": "Phường 08",
                        "code": 26998,
                        "codename": "phuong_08",
                        "division_type": "phường",
                        "short_codename": "phuong_08"
                    },
                    {
                        "name": "Phường 09",
                        "code": 27001,
                        "codename": "phuong_09",
                        "division_type": "phường",
                        "short_codename": "phuong_09"
                    },
                    {
                        "name": "Phường 14",
                        "code": 27004,
                        "codename": "phuong_14",
                        "division_type": "phường",
                        "short_codename": "phuong_14"
                    },
                    {
                        "name": "Phường 15",
                        "code": 27007,
                        "codename": "phuong_15",
                        "division_type": "phường",
                        "short_codename": "phuong_15"
                    }
                ]
            },
            {
                "name": "Quận Tân Phú",
                "code": 767,
                "codename": "quan_tan_phu",
                "division_type": "quận",
                "short_codename": "tan_phu",
                "wards": [
                    {
                        "name": "Phường Tân Sơn Nhì",
                        "code": 27010,
                        "codename": "phuong_tan_son_nhi",
                        "division_type": "phường",
                        "short_codename": "tan_son_nhi"
                    },
                    {
                        "name": "Phường Tây Thạnh",
                        "code": 27013,
                        "codename": "phuong_tay_thanh",
                        "division_type": "phường",
                        "short_codename": "tay_thanh"
                    },
                    {
                        "name": "Phường Sơn Kỳ",
                        "code": 27016,
                        "codename": "phuong_son_ky",
                        "division_type": "phường",
                        "short_codename": "son_ky"
                    },
                    {
                        "name": "Phường Tân Quý",
                        "code": 27019,
                        "codename": "phuong_tan_quy",
                        "division_type": "phường",
                        "short_codename": "tan_quy"
                    },
                    {
                        "name": "Phường Tân Thành",
                        "code": 27022,
                        "codename": "phuong_tan_thanh",
                        "division_type": "phường",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Phường Phú Thọ Hòa",
                        "code": 27025,
                        "codename": "phuong_phu_tho_hoa",
                        "division_type": "phường",
                        "short_codename": "phu_tho_hoa"
                    },
                    {
                        "name": "Phường Phú Thạnh",
                        "code": 27028,
                        "codename": "phuong_phu_thanh",
                        "division_type": "phường",
                        "short_codename": "phu_thanh"
                    },
                    {
                        "name": "Phường Phú Trung",
                        "code": 27031,
                        "codename": "phuong_phu_trung",
                        "division_type": "phường",
                        "short_codename": "phu_trung"
                    },
                    {
                        "name": "Phường Hòa Thạnh",
                        "code": 27034,
                        "codename": "phuong_hoa_thanh",
                        "division_type": "phường",
                        "short_codename": "hoa_thanh"
                    },
                    {
                        "name": "Phường Hiệp Tân",
                        "code": 27037,
                        "codename": "phuong_hiep_tan",
                        "division_type": "phường",
                        "short_codename": "hiep_tan"
                    },
                    {
                        "name": "Phường Tân Thới Hòa",
                        "code": 27040,
                        "codename": "phuong_tan_thoi_hoa",
                        "division_type": "phường",
                        "short_codename": "tan_thoi_hoa"
                    }
                ]
            },
            {
                "name": "Quận Phú Nhuận",
                "code": 768,
                "codename": "quan_phu_nhuan",
                "division_type": "quận",
                "short_codename": "phu_nhuan",
                "wards": [
                    {
                        "name": "Phường 04",
                        "code": 27043,
                        "codename": "phuong_04",
                        "division_type": "phường",
                        "short_codename": "phuong_04"
                    },
                    {
                        "name": "Phường 05",
                        "code": 27046,
                        "codename": "phuong_05",
                        "division_type": "phường",
                        "short_codename": "phuong_05"
                    },
                    {
                        "name": "Phường 09",
                        "code": 27049,
                        "codename": "phuong_09",
                        "division_type": "phường",
                        "short_codename": "phuong_09"
                    },
                    {
                        "name": "Phường 07",
                        "code": 27052,
                        "codename": "phuong_07",
                        "division_type": "phường",
                        "short_codename": "phuong_07"
                    },
                    {
                        "name": "Phường 03",
                        "code": 27055,
                        "codename": "phuong_03",
                        "division_type": "phường",
                        "short_codename": "phuong_03"
                    },
                    {
                        "name": "Phường 01",
                        "code": 27058,
                        "codename": "phuong_01",
                        "division_type": "phường",
                        "short_codename": "phuong_01"
                    },
                    {
                        "name": "Phường 02",
                        "code": 27061,
                        "codename": "phuong_02",
                        "division_type": "phường",
                        "short_codename": "phuong_02"
                    },
                    {
                        "name": "Phường 08",
                        "code": 27064,
                        "codename": "phuong_08",
                        "division_type": "phường",
                        "short_codename": "phuong_08"
                    },
                    {
                        "name": "Phường 15",
                        "code": 27067,
                        "codename": "phuong_15",
                        "division_type": "phường",
                        "short_codename": "phuong_15"
                    },
                    {
                        "name": "Phường 10",
                        "code": 27070,
                        "codename": "phuong_10",
                        "division_type": "phường",
                        "short_codename": "phuong_10"
                    },
                    {
                        "name": "Phường 11",
                        "code": 27073,
                        "codename": "phuong_11",
                        "division_type": "phường",
                        "short_codename": "phuong_11"
                    },
                    {
                        "name": "Phường 17",
                        "code": 27076,
                        "codename": "phuong_17",
                        "division_type": "phường",
                        "short_codename": "phuong_17"
                    },
                    {
                        "name": "Phường 13",
                        "code": 27085,
                        "codename": "phuong_13",
                        "division_type": "phường",
                        "short_codename": "phuong_13"
                    }
                ]
            },
            {
                "name": "Thành phố Thủ Đức",
                "code": 769,
                "codename": "thanh_pho_thu_duc",
                "division_type": "thành phố",
                "short_codename": "thu_duc",
                "wards": [
                    {
                        "name": "Phường Linh Xuân",
                        "code": 26794,
                        "codename": "phuong_linh_xuan",
                        "division_type": "phường",
                        "short_codename": "linh_xuan"
                    },
                    {
                        "name": "Phường Bình Chiểu",
                        "code": 26797,
                        "codename": "phuong_binh_chieu",
                        "division_type": "phường",
                        "short_codename": "binh_chieu"
                    },
                    {
                        "name": "Phường Linh Trung",
                        "code": 26800,
                        "codename": "phuong_linh_trung",
                        "division_type": "phường",
                        "short_codename": "linh_trung"
                    },
                    {
                        "name": "Phường Tam Bình",
                        "code": 26803,
                        "codename": "phuong_tam_binh",
                        "division_type": "phường",
                        "short_codename": "tam_binh"
                    },
                    {
                        "name": "Phường Tam Phú",
                        "code": 26806,
                        "codename": "phuong_tam_phu",
                        "division_type": "phường",
                        "short_codename": "tam_phu"
                    },
                    {
                        "name": "Phường Hiệp Bình Phước",
                        "code": 26809,
                        "codename": "phuong_hiep_binh_phuoc",
                        "division_type": "phường",
                        "short_codename": "hiep_binh_phuoc"
                    },
                    {
                        "name": "Phường Hiệp Bình Chánh",
                        "code": 26812,
                        "codename": "phuong_hiep_binh_chanh",
                        "division_type": "phường",
                        "short_codename": "hiep_binh_chanh"
                    },
                    {
                        "name": "Phường Linh Chiểu",
                        "code": 26815,
                        "codename": "phuong_linh_chieu",
                        "division_type": "phường",
                        "short_codename": "linh_chieu"
                    },
                    {
                        "name": "Phường Linh Tây",
                        "code": 26818,
                        "codename": "phuong_linh_tay",
                        "division_type": "phường",
                        "short_codename": "linh_tay"
                    },
                    {
                        "name": "Phường Linh Đông",
                        "code": 26821,
                        "codename": "phuong_linh_dong",
                        "division_type": "phường",
                        "short_codename": "linh_dong"
                    },
                    {
                        "name": "Phường Bình Thọ",
                        "code": 26824,
                        "codename": "phuong_binh_tho",
                        "division_type": "phường",
                        "short_codename": "binh_tho"
                    },
                    {
                        "name": "Phường Trường Thọ",
                        "code": 26827,
                        "codename": "phuong_truong_tho",
                        "division_type": "phường",
                        "short_codename": "truong_tho"
                    },
                    {
                        "name": "Phường Long Bình",
                        "code": 26830,
                        "codename": "phuong_long_binh",
                        "division_type": "phường",
                        "short_codename": "long_binh"
                    },
                    {
                        "name": "Phường Long Thạnh Mỹ",
                        "code": 26833,
                        "codename": "phuong_long_thanh_my",
                        "division_type": "phường",
                        "short_codename": "long_thanh_my"
                    },
                    {
                        "name": "Phường Tân Phú",
                        "code": 26836,
                        "codename": "phuong_tan_phu",
                        "division_type": "phường",
                        "short_codename": "tan_phu"
                    },
                    {
                        "name": "Phường Hiệp Phú",
                        "code": 26839,
                        "codename": "phuong_hiep_phu",
                        "division_type": "phường",
                        "short_codename": "hiep_phu"
                    },
                    {
                        "name": "Phường Tăng Nhơn Phú A",
                        "code": 26842,
                        "codename": "phuong_tang_nhon_phu_a",
                        "division_type": "phường",
                        "short_codename": "tang_nhon_phu_a"
                    },
                    {
                        "name": "Phường Tăng Nhơn Phú B",
                        "code": 26845,
                        "codename": "phuong_tang_nhon_phu_b",
                        "division_type": "phường",
                        "short_codename": "tang_nhon_phu_b"
                    },
                    {
                        "name": "Phường Phước Long B",
                        "code": 26848,
                        "codename": "phuong_phuoc_long_b",
                        "division_type": "phường",
                        "short_codename": "phuoc_long_b"
                    },
                    {
                        "name": "Phường Phước Long A",
                        "code": 26851,
                        "codename": "phuong_phuoc_long_a",
                        "division_type": "phường",
                        "short_codename": "phuoc_long_a"
                    },
                    {
                        "name": "Phường Trường Thạnh",
                        "code": 26854,
                        "codename": "phuong_truong_thanh",
                        "division_type": "phường",
                        "short_codename": "truong_thanh"
                    },
                    {
                        "name": "Phường Long Phước",
                        "code": 26857,
                        "codename": "phuong_long_phuoc",
                        "division_type": "phường",
                        "short_codename": "long_phuoc"
                    },
                    {
                        "name": "Phường Long Trường",
                        "code": 26860,
                        "codename": "phuong_long_truong",
                        "division_type": "phường",
                        "short_codename": "long_truong"
                    },
                    {
                        "name": "Phường Phước Bình",
                        "code": 26863,
                        "codename": "phuong_phuoc_binh",
                        "division_type": "phường",
                        "short_codename": "phuoc_binh"
                    },
                    {
                        "name": "Phường Phú Hữu",
                        "code": 26866,
                        "codename": "phuong_phu_huu",
                        "division_type": "phường",
                        "short_codename": "phu_huu"
                    },
                    {
                        "name": "Phường Thảo Điền",
                        "code": 27088,
                        "codename": "phuong_thao_dien",
                        "division_type": "phường",
                        "short_codename": "thao_dien"
                    },
                    {
                        "name": "Phường An Phú",
                        "code": 27091,
                        "codename": "phuong_an_phu",
                        "division_type": "phường",
                        "short_codename": "an_phu"
                    },
                    {
                        "name": "Phường An Khánh",
                        "code": 27094,
                        "codename": "phuong_an_khanh",
                        "division_type": "phường",
                        "short_codename": "an_khanh"
                    },
                    {
                        "name": "Phường Bình Trưng Đông",
                        "code": 27097,
                        "codename": "phuong_binh_trung_dong",
                        "division_type": "phường",
                        "short_codename": "binh_trung_dong"
                    },
                    {
                        "name": "Phường Bình Trưng Tây",
                        "code": 27100,
                        "codename": "phuong_binh_trung_tay",
                        "division_type": "phường",
                        "short_codename": "binh_trung_tay"
                    },
                    {
                        "name": "Phường Cát Lái",
                        "code": 27109,
                        "codename": "phuong_cat_lai",
                        "division_type": "phường",
                        "short_codename": "cat_lai"
                    },
                    {
                        "name": "Phường Thạnh Mỹ Lợi",
                        "code": 27112,
                        "codename": "phuong_thanh_my_loi",
                        "division_type": "phường",
                        "short_codename": "thanh_my_loi"
                    },
                    {
                        "name": "Phường An Lợi Đông",
                        "code": 27115,
                        "codename": "phuong_an_loi_dong",
                        "division_type": "phường",
                        "short_codename": "an_loi_dong"
                    },
                    {
                        "name": "Phường Thủ Thiêm",
                        "code": 27118,
                        "codename": "phuong_thu_thiem",
                        "division_type": "phường",
                        "short_codename": "thu_thiem"
                    }
                ]
            },
            {
                "name": "Quận 3",
                "code": 770,
                "codename": "quan_3",
                "division_type": "quận",
                "short_codename": "quan_3",
                "wards": [
                    {
                        "name": "Phường 14",
                        "code": 27127,
                        "codename": "phuong_14",
                        "division_type": "phường",
                        "short_codename": "phuong_14"
                    },
                    {
                        "name": "Phường 12",
                        "code": 27130,
                        "codename": "phuong_12",
                        "division_type": "phường",
                        "short_codename": "phuong_12"
                    },
                    {
                        "name": "Phường 11",
                        "code": 27133,
                        "codename": "phuong_11",
                        "division_type": "phường",
                        "short_codename": "phuong_11"
                    },
                    {
                        "name": "Phường 13",
                        "code": 27136,
                        "codename": "phuong_13",
                        "division_type": "phường",
                        "short_codename": "phuong_13"
                    },
                    {
                        "name": "Phường Võ Thị Sáu",
                        "code": 27139,
                        "codename": "phuong_vo_thi_sau",
                        "division_type": "phường",
                        "short_codename": "vo_thi_sau"
                    },
                    {
                        "name": "Phường 09",
                        "code": 27142,
                        "codename": "phuong_09",
                        "division_type": "phường",
                        "short_codename": "phuong_09"
                    },
                    {
                        "name": "Phường 10",
                        "code": 27145,
                        "codename": "phuong_10",
                        "division_type": "phường",
                        "short_codename": "phuong_10"
                    },
                    {
                        "name": "Phường 04",
                        "code": 27148,
                        "codename": "phuong_04",
                        "division_type": "phường",
                        "short_codename": "phuong_04"
                    },
                    {
                        "name": "Phường 05",
                        "code": 27151,
                        "codename": "phuong_05",
                        "division_type": "phường",
                        "short_codename": "phuong_05"
                    },
                    {
                        "name": "Phường 03",
                        "code": 27154,
                        "codename": "phuong_03",
                        "division_type": "phường",
                        "short_codename": "phuong_03"
                    },
                    {
                        "name": "Phường 02",
                        "code": 27157,
                        "codename": "phuong_02",
                        "division_type": "phường",
                        "short_codename": "phuong_02"
                    },
                    {
                        "name": "Phường 01",
                        "code": 27160,
                        "codename": "phuong_01",
                        "division_type": "phường",
                        "short_codename": "phuong_01"
                    }
                ]
            },
            {
                "name": "Quận 10",
                "code": 771,
                "codename": "quan_10",
                "division_type": "quận",
                "short_codename": "quan_10",
                "wards": [
                    {
                        "name": "Phường 15",
                        "code": 27163,
                        "codename": "phuong_15",
                        "division_type": "phường",
                        "short_codename": "phuong_15"
                    },
                    {
                        "name": "Phường 13",
                        "code": 27166,
                        "codename": "phuong_13",
                        "division_type": "phường",
                        "short_codename": "phuong_13"
                    },
                    {
                        "name": "Phường 14",
                        "code": 27169,
                        "codename": "phuong_14",
                        "division_type": "phường",
                        "short_codename": "phuong_14"
                    },
                    {
                        "name": "Phường 12",
                        "code": 27172,
                        "codename": "phuong_12",
                        "division_type": "phường",
                        "short_codename": "phuong_12"
                    },
                    {
                        "name": "Phường 11",
                        "code": 27175,
                        "codename": "phuong_11",
                        "division_type": "phường",
                        "short_codename": "phuong_11"
                    },
                    {
                        "name": "Phường 10",
                        "code": 27178,
                        "codename": "phuong_10",
                        "division_type": "phường",
                        "short_codename": "phuong_10"
                    },
                    {
                        "name": "Phường 09",
                        "code": 27181,
                        "codename": "phuong_09",
                        "division_type": "phường",
                        "short_codename": "phuong_09"
                    },
                    {
                        "name": "Phường 01",
                        "code": 27184,
                        "codename": "phuong_01",
                        "division_type": "phường",
                        "short_codename": "phuong_01"
                    },
                    {
                        "name": "Phường 08",
                        "code": 27187,
                        "codename": "phuong_08",
                        "division_type": "phường",
                        "short_codename": "phuong_08"
                    },
                    {
                        "name": "Phường 02",
                        "code": 27190,
                        "codename": "phuong_02",
                        "division_type": "phường",
                        "short_codename": "phuong_02"
                    },
                    {
                        "name": "Phường 04",
                        "code": 27193,
                        "codename": "phuong_04",
                        "division_type": "phường",
                        "short_codename": "phuong_04"
                    },
                    {
                        "name": "Phường 07",
                        "code": 27196,
                        "codename": "phuong_07",
                        "division_type": "phường",
                        "short_codename": "phuong_07"
                    },
                    {
                        "name": "Phường 05",
                        "code": 27199,
                        "codename": "phuong_05",
                        "division_type": "phường",
                        "short_codename": "phuong_05"
                    },
                    {
                        "name": "Phường 06",
                        "code": 27202,
                        "codename": "phuong_06",
                        "division_type": "phường",
                        "short_codename": "phuong_06"
                    }
                ]
            },
            {
                "name": "Quận 11",
                "code": 772,
                "codename": "quan_11",
                "division_type": "quận",
                "short_codename": "quan_11",
                "wards": [
                    {
                        "name": "Phường 15",
                        "code": 27208,
                        "codename": "phuong_15",
                        "division_type": "phường",
                        "short_codename": "phuong_15"
                    },
                    {
                        "name": "Phường 05",
                        "code": 27211,
                        "codename": "phuong_05",
                        "division_type": "phường",
                        "short_codename": "phuong_05"
                    },
                    {
                        "name": "Phường 14",
                        "code": 27214,
                        "codename": "phuong_14",
                        "division_type": "phường",
                        "short_codename": "phuong_14"
                    },
                    {
                        "name": "Phường 11",
                        "code": 27217,
                        "codename": "phuong_11",
                        "division_type": "phường",
                        "short_codename": "phuong_11"
                    },
                    {
                        "name": "Phường 03",
                        "code": 27220,
                        "codename": "phuong_03",
                        "division_type": "phường",
                        "short_codename": "phuong_03"
                    },
                    {
                        "name": "Phường 10",
                        "code": 27223,
                        "codename": "phuong_10",
                        "division_type": "phường",
                        "short_codename": "phuong_10"
                    },
                    {
                        "name": "Phường 13",
                        "code": 27226,
                        "codename": "phuong_13",
                        "division_type": "phường",
                        "short_codename": "phuong_13"
                    },
                    {
                        "name": "Phường 08",
                        "code": 27229,
                        "codename": "phuong_08",
                        "division_type": "phường",
                        "short_codename": "phuong_08"
                    },
                    {
                        "name": "Phường 09",
                        "code": 27232,
                        "codename": "phuong_09",
                        "division_type": "phường",
                        "short_codename": "phuong_09"
                    },
                    {
                        "name": "Phường 12",
                        "code": 27235,
                        "codename": "phuong_12",
                        "division_type": "phường",
                        "short_codename": "phuong_12"
                    },
                    {
                        "name": "Phường 07",
                        "code": 27238,
                        "codename": "phuong_07",
                        "division_type": "phường",
                        "short_codename": "phuong_07"
                    },
                    {
                        "name": "Phường 06",
                        "code": 27241,
                        "codename": "phuong_06",
                        "division_type": "phường",
                        "short_codename": "phuong_06"
                    },
                    {
                        "name": "Phường 04",
                        "code": 27244,
                        "codename": "phuong_04",
                        "division_type": "phường",
                        "short_codename": "phuong_04"
                    },
                    {
                        "name": "Phường 01",
                        "code": 27247,
                        "codename": "phuong_01",
                        "division_type": "phường",
                        "short_codename": "phuong_01"
                    },
                    {
                        "name": "Phường 02",
                        "code": 27250,
                        "codename": "phuong_02",
                        "division_type": "phường",
                        "short_codename": "phuong_02"
                    },
                    {
                        "name": "Phường 16",
                        "code": 27253,
                        "codename": "phuong_16",
                        "division_type": "phường",
                        "short_codename": "phuong_16"
                    }
                ]
            },
            {
                "name": "Quận 4",
                "code": 773,
                "codename": "quan_4",
                "division_type": "quận",
                "short_codename": "quan_4",
                "wards": [
                    {
                        "name": "Phường 13",
                        "code": 27259,
                        "codename": "phuong_13",
                        "division_type": "phường",
                        "short_codename": "phuong_13"
                    },
                    {
                        "name": "Phường 09",
                        "code": 27262,
                        "codename": "phuong_09",
                        "division_type": "phường",
                        "short_codename": "phuong_09"
                    },
                    {
                        "name": "Phường 06",
                        "code": 27265,
                        "codename": "phuong_06",
                        "division_type": "phường",
                        "short_codename": "phuong_06"
                    },
                    {
                        "name": "Phường 08",
                        "code": 27268,
                        "codename": "phuong_08",
                        "division_type": "phường",
                        "short_codename": "phuong_08"
                    },
                    {
                        "name": "Phường 10",
                        "code": 27271,
                        "codename": "phuong_10",
                        "division_type": "phường",
                        "short_codename": "phuong_10"
                    },
                    {
                        "name": "Phường 18",
                        "code": 27277,
                        "codename": "phuong_18",
                        "division_type": "phường",
                        "short_codename": "phuong_18"
                    },
                    {
                        "name": "Phường 14",
                        "code": 27280,
                        "codename": "phuong_14",
                        "division_type": "phường",
                        "short_codename": "phuong_14"
                    },
                    {
                        "name": "Phường 04",
                        "code": 27283,
                        "codename": "phuong_04",
                        "division_type": "phường",
                        "short_codename": "phuong_04"
                    },
                    {
                        "name": "Phường 03",
                        "code": 27286,
                        "codename": "phuong_03",
                        "division_type": "phường",
                        "short_codename": "phuong_03"
                    },
                    {
                        "name": "Phường 16",
                        "code": 27289,
                        "codename": "phuong_16",
                        "division_type": "phường",
                        "short_codename": "phuong_16"
                    },
                    {
                        "name": "Phường 02",
                        "code": 27292,
                        "codename": "phuong_02",
                        "division_type": "phường",
                        "short_codename": "phuong_02"
                    },
                    {
                        "name": "Phường 15",
                        "code": 27295,
                        "codename": "phuong_15",
                        "division_type": "phường",
                        "short_codename": "phuong_15"
                    },
                    {
                        "name": "Phường 01",
                        "code": 27298,
                        "codename": "phuong_01",
                        "division_type": "phường",
                        "short_codename": "phuong_01"
                    }
                ]
            },
            {
                "name": "Quận 5",
                "code": 774,
                "codename": "quan_5",
                "division_type": "quận",
                "short_codename": "quan_5",
                "wards": [
                    {
                        "name": "Phường 04",
                        "code": 27301,
                        "codename": "phuong_04",
                        "division_type": "phường",
                        "short_codename": "phuong_04"
                    },
                    {
                        "name": "Phường 09",
                        "code": 27304,
                        "codename": "phuong_09",
                        "division_type": "phường",
                        "short_codename": "phuong_09"
                    },
                    {
                        "name": "Phường 03",
                        "code": 27307,
                        "codename": "phuong_03",
                        "division_type": "phường",
                        "short_codename": "phuong_03"
                    },
                    {
                        "name": "Phường 12",
                        "code": 27310,
                        "codename": "phuong_12",
                        "division_type": "phường",
                        "short_codename": "phuong_12"
                    },
                    {
                        "name": "Phường 02",
                        "code": 27313,
                        "codename": "phuong_02",
                        "division_type": "phường",
                        "short_codename": "phuong_02"
                    },
                    {
                        "name": "Phường 08",
                        "code": 27316,
                        "codename": "phuong_08",
                        "division_type": "phường",
                        "short_codename": "phuong_08"
                    },
                    {
                        "name": "Phường 07",
                        "code": 27322,
                        "codename": "phuong_07",
                        "division_type": "phường",
                        "short_codename": "phuong_07"
                    },
                    {
                        "name": "Phường 01",
                        "code": 27325,
                        "codename": "phuong_01",
                        "division_type": "phường",
                        "short_codename": "phuong_01"
                    },
                    {
                        "name": "Phường 11",
                        "code": 27328,
                        "codename": "phuong_11",
                        "division_type": "phường",
                        "short_codename": "phuong_11"
                    },
                    {
                        "name": "Phường 14",
                        "code": 27331,
                        "codename": "phuong_14",
                        "division_type": "phường",
                        "short_codename": "phuong_14"
                    },
                    {
                        "name": "Phường 05",
                        "code": 27334,
                        "codename": "phuong_05",
                        "division_type": "phường",
                        "short_codename": "phuong_05"
                    },
                    {
                        "name": "Phường 06",
                        "code": 27337,
                        "codename": "phuong_06",
                        "division_type": "phường",
                        "short_codename": "phuong_06"
                    },
                    {
                        "name": "Phường 10",
                        "code": 27340,
                        "codename": "phuong_10",
                        "division_type": "phường",
                        "short_codename": "phuong_10"
                    },
                    {
                        "name": "Phường 13",
                        "code": 27343,
                        "codename": "phuong_13",
                        "division_type": "phường",
                        "short_codename": "phuong_13"
                    }
                ]
            },
            {
                "name": "Quận 6",
                "code": 775,
                "codename": "quan_6",
                "division_type": "quận",
                "short_codename": "quan_6",
                "wards": [
                    {
                        "name": "Phường 14",
                        "code": 27346,
                        "codename": "phuong_14",
                        "division_type": "phường",
                        "short_codename": "phuong_14"
                    },
                    {
                        "name": "Phường 13",
                        "code": 27349,
                        "codename": "phuong_13",
                        "division_type": "phường",
                        "short_codename": "phuong_13"
                    },
                    {
                        "name": "Phường 09",
                        "code": 27352,
                        "codename": "phuong_09",
                        "division_type": "phường",
                        "short_codename": "phuong_09"
                    },
                    {
                        "name": "Phường 06",
                        "code": 27355,
                        "codename": "phuong_06",
                        "division_type": "phường",
                        "short_codename": "phuong_06"
                    },
                    {
                        "name": "Phường 12",
                        "code": 27358,
                        "codename": "phuong_12",
                        "division_type": "phường",
                        "short_codename": "phuong_12"
                    },
                    {
                        "name": "Phường 05",
                        "code": 27361,
                        "codename": "phuong_05",
                        "division_type": "phường",
                        "short_codename": "phuong_05"
                    },
                    {
                        "name": "Phường 11",
                        "code": 27364,
                        "codename": "phuong_11",
                        "division_type": "phường",
                        "short_codename": "phuong_11"
                    },
                    {
                        "name": "Phường 02",
                        "code": 27367,
                        "codename": "phuong_02",
                        "division_type": "phường",
                        "short_codename": "phuong_02"
                    },
                    {
                        "name": "Phường 01",
                        "code": 27370,
                        "codename": "phuong_01",
                        "division_type": "phường",
                        "short_codename": "phuong_01"
                    },
                    {
                        "name": "Phường 04",
                        "code": 27373,
                        "codename": "phuong_04",
                        "division_type": "phường",
                        "short_codename": "phuong_04"
                    },
                    {
                        "name": "Phường 08",
                        "code": 27376,
                        "codename": "phuong_08",
                        "division_type": "phường",
                        "short_codename": "phuong_08"
                    },
                    {
                        "name": "Phường 03",
                        "code": 27379,
                        "codename": "phuong_03",
                        "division_type": "phường",
                        "short_codename": "phuong_03"
                    },
                    {
                        "name": "Phường 07",
                        "code": 27382,
                        "codename": "phuong_07",
                        "division_type": "phường",
                        "short_codename": "phuong_07"
                    },
                    {
                        "name": "Phường 10",
                        "code": 27385,
                        "codename": "phuong_10",
                        "division_type": "phường",
                        "short_codename": "phuong_10"
                    }
                ]
            },
            {
                "name": "Quận 8",
                "code": 776,
                "codename": "quan_8",
                "division_type": "quận",
                "short_codename": "quan_8",
                "wards": [
                    {
                        "name": "Phường 08",
                        "code": 27388,
                        "codename": "phuong_08",
                        "division_type": "phường",
                        "short_codename": "phuong_08"
                    },
                    {
                        "name": "Phường 02",
                        "code": 27391,
                        "codename": "phuong_02",
                        "division_type": "phường",
                        "short_codename": "phuong_02"
                    },
                    {
                        "name": "Phường 01",
                        "code": 27394,
                        "codename": "phuong_01",
                        "division_type": "phường",
                        "short_codename": "phuong_01"
                    },
                    {
                        "name": "Phường 03",
                        "code": 27397,
                        "codename": "phuong_03",
                        "division_type": "phường",
                        "short_codename": "phuong_03"
                    },
                    {
                        "name": "Phường 11",
                        "code": 27400,
                        "codename": "phuong_11",
                        "division_type": "phường",
                        "short_codename": "phuong_11"
                    },
                    {
                        "name": "Phường 09",
                        "code": 27403,
                        "codename": "phuong_09",
                        "division_type": "phường",
                        "short_codename": "phuong_09"
                    },
                    {
                        "name": "Phường 10",
                        "code": 27406,
                        "codename": "phuong_10",
                        "division_type": "phường",
                        "short_codename": "phuong_10"
                    },
                    {
                        "name": "Phường 04",
                        "code": 27409,
                        "codename": "phuong_04",
                        "division_type": "phường",
                        "short_codename": "phuong_04"
                    },
                    {
                        "name": "Phường 13",
                        "code": 27412,
                        "codename": "phuong_13",
                        "division_type": "phường",
                        "short_codename": "phuong_13"
                    },
                    {
                        "name": "Phường 12",
                        "code": 27415,
                        "codename": "phuong_12",
                        "division_type": "phường",
                        "short_codename": "phuong_12"
                    },
                    {
                        "name": "Phường 05",
                        "code": 27418,
                        "codename": "phuong_05",
                        "division_type": "phường",
                        "short_codename": "phuong_05"
                    },
                    {
                        "name": "Phường 14",
                        "code": 27421,
                        "codename": "phuong_14",
                        "division_type": "phường",
                        "short_codename": "phuong_14"
                    },
                    {
                        "name": "Phường 06",
                        "code": 27424,
                        "codename": "phuong_06",
                        "division_type": "phường",
                        "short_codename": "phuong_06"
                    },
                    {
                        "name": "Phường 15",
                        "code": 27427,
                        "codename": "phuong_15",
                        "division_type": "phường",
                        "short_codename": "phuong_15"
                    },
                    {
                        "name": "Phường 16",
                        "code": 27430,
                        "codename": "phuong_16",
                        "division_type": "phường",
                        "short_codename": "phuong_16"
                    },
                    {
                        "name": "Phường 07",
                        "code": 27433,
                        "codename": "phuong_07",
                        "division_type": "phường",
                        "short_codename": "phuong_07"
                    }
                ]
            },
            {
                "name": "Quận Bình Tân",
                "code": 777,
                "codename": "quan_binh_tan",
                "division_type": "quận",
                "short_codename": "binh_tan",
                "wards": [
                    {
                        "name": "Phường Bình Hưng Hòa",
                        "code": 27436,
                        "codename": "phuong_binh_hung_hoa",
                        "division_type": "phường",
                        "short_codename": "binh_hung_hoa"
                    },
                    {
                        "name": "Phường Bình Hưng Hoà A",
                        "code": 27439,
                        "codename": "phuong_binh_hung_hoa_a",
                        "division_type": "phường",
                        "short_codename": "binh_hung_hoa_a"
                    },
                    {
                        "name": "Phường Bình Hưng Hoà B",
                        "code": 27442,
                        "codename": "phuong_binh_hung_hoa_b",
                        "division_type": "phường",
                        "short_codename": "binh_hung_hoa_b"
                    },
                    {
                        "name": "Phường Bình Trị Đông",
                        "code": 27445,
                        "codename": "phuong_binh_tri_dong",
                        "division_type": "phường",
                        "short_codename": "binh_tri_dong"
                    },
                    {
                        "name": "Phường Bình Trị Đông A",
                        "code": 27448,
                        "codename": "phuong_binh_tri_dong_a",
                        "division_type": "phường",
                        "short_codename": "binh_tri_dong_a"
                    },
                    {
                        "name": "Phường Bình Trị Đông B",
                        "code": 27451,
                        "codename": "phuong_binh_tri_dong_b",
                        "division_type": "phường",
                        "short_codename": "binh_tri_dong_b"
                    },
                    {
                        "name": "Phường Tân Tạo",
                        "code": 27454,
                        "codename": "phuong_tan_tao",
                        "division_type": "phường",
                        "short_codename": "tan_tao"
                    },
                    {
                        "name": "Phường Tân Tạo A",
                        "code": 27457,
                        "codename": "phuong_tan_tao_a",
                        "division_type": "phường",
                        "short_codename": "tan_tao_a"
                    },
                    {
                        "name": "Phường An Lạc",
                        "code": 27460,
                        "codename": "phuong_an_lac",
                        "division_type": "phường",
                        "short_codename": "an_lac"
                    },
                    {
                        "name": "Phường An Lạc A",
                        "code": 27463,
                        "codename": "phuong_an_lac_a",
                        "division_type": "phường",
                        "short_codename": "an_lac_a"
                    }
                ]
            },
            {
                "name": "Quận 7",
                "code": 778,
                "codename": "quan_7",
                "division_type": "quận",
                "short_codename": "quan_7",
                "wards": [
                    {
                        "name": "Phường Tân Thuận Đông",
                        "code": 27466,
                        "codename": "phuong_tan_thuan_dong",
                        "division_type": "phường",
                        "short_codename": "tan_thuan_dong"
                    },
                    {
                        "name": "Phường Tân Thuận Tây",
                        "code": 27469,
                        "codename": "phuong_tan_thuan_tay",
                        "division_type": "phường",
                        "short_codename": "tan_thuan_tay"
                    },
                    {
                        "name": "Phường Tân Kiểng",
                        "code": 27472,
                        "codename": "phuong_tan_kieng",
                        "division_type": "phường",
                        "short_codename": "tan_kieng"
                    },
                    {
                        "name": "Phường Tân Hưng",
                        "code": 27475,
                        "codename": "phuong_tan_hung",
                        "division_type": "phường",
                        "short_codename": "tan_hung"
                    },
                    {
                        "name": "Phường Bình Thuận",
                        "code": 27478,
                        "codename": "phuong_binh_thuan",
                        "division_type": "phường",
                        "short_codename": "binh_thuan"
                    },
                    {
                        "name": "Phường Tân Quy",
                        "code": 27481,
                        "codename": "phuong_tan_quy",
                        "division_type": "phường",
                        "short_codename": "tan_quy"
                    },
                    {
                        "name": "Phường Phú Thuận",
                        "code": 27484,
                        "codename": "phuong_phu_thuan",
                        "division_type": "phường",
                        "short_codename": "phu_thuan"
                    },
                    {
                        "name": "Phường Tân Phú",
                        "code": 27487,
                        "codename": "phuong_tan_phu",
                        "division_type": "phường",
                        "short_codename": "tan_phu"
                    },
                    {
                        "name": "Phường Tân Phong",
                        "code": 27490,
                        "codename": "phuong_tan_phong",
                        "division_type": "phường",
                        "short_codename": "tan_phong"
                    },
                    {
                        "name": "Phường Phú Mỹ",
                        "code": 27493,
                        "codename": "phuong_phu_my",
                        "division_type": "phường",
                        "short_codename": "phu_my"
                    }
                ]
            },
            {
                "name": "Huyện Củ Chi",
                "code": 783,
                "codename": "huyen_cu_chi",
                "division_type": "huyện",
                "short_codename": "cu_chi",
                "wards": [
                    {
                        "name": "Thị trấn Củ Chi",
                        "code": 27496,
                        "codename": "thi_tran_cu_chi",
                        "division_type": "thị trấn",
                        "short_codename": "cu_chi"
                    },
                    {
                        "name": "Xã Phú Mỹ Hưng",
                        "code": 27499,
                        "codename": "xa_phu_my_hung",
                        "division_type": "xã",
                        "short_codename": "phu_my_hung"
                    },
                    {
                        "name": "Xã An Phú",
                        "code": 27502,
                        "codename": "xa_an_phu",
                        "division_type": "xã",
                        "short_codename": "an_phu"
                    },
                    {
                        "name": "Xã Trung Lập Thượng",
                        "code": 27505,
                        "codename": "xa_trung_lap_thuong",
                        "division_type": "xã",
                        "short_codename": "trung_lap_thuong"
                    },
                    {
                        "name": "Xã An Nhơn Tây",
                        "code": 27508,
                        "codename": "xa_an_nhon_tay",
                        "division_type": "xã",
                        "short_codename": "an_nhon_tay"
                    },
                    {
                        "name": "Xã Nhuận Đức",
                        "code": 27511,
                        "codename": "xa_nhuan_duc",
                        "division_type": "xã",
                        "short_codename": "nhuan_duc"
                    },
                    {
                        "name": "Xã Phạm Văn Cội",
                        "code": 27514,
                        "codename": "xa_pham_van_coi",
                        "division_type": "xã",
                        "short_codename": "pham_van_coi"
                    },
                    {
                        "name": "Xã Phú Hòa Đông",
                        "code": 27517,
                        "codename": "xa_phu_hoa_dong",
                        "division_type": "xã",
                        "short_codename": "phu_hoa_dong"
                    },
                    {
                        "name": "Xã Trung Lập Hạ",
                        "code": 27520,
                        "codename": "xa_trung_lap_ha",
                        "division_type": "xã",
                        "short_codename": "trung_lap_ha"
                    },
                    {
                        "name": "Xã Trung An",
                        "code": 27523,
                        "codename": "xa_trung_an",
                        "division_type": "xã",
                        "short_codename": "trung_an"
                    },
                    {
                        "name": "Xã Phước Thạnh",
                        "code": 27526,
                        "codename": "xa_phuoc_thanh",
                        "division_type": "xã",
                        "short_codename": "phuoc_thanh"
                    },
                    {
                        "name": "Xã Phước Hiệp",
                        "code": 27529,
                        "codename": "xa_phuoc_hiep",
                        "division_type": "xã",
                        "short_codename": "phuoc_hiep"
                    },
                    {
                        "name": "Xã Tân An Hội",
                        "code": 27532,
                        "codename": "xa_tan_an_hoi",
                        "division_type": "xã",
                        "short_codename": "tan_an_hoi"
                    },
                    {
                        "name": "Xã Phước Vĩnh An",
                        "code": 27535,
                        "codename": "xa_phuoc_vinh_an",
                        "division_type": "xã",
                        "short_codename": "phuoc_vinh_an"
                    },
                    {
                        "name": "Xã Thái Mỹ",
                        "code": 27538,
                        "codename": "xa_thai_my",
                        "division_type": "xã",
                        "short_codename": "thai_my"
                    },
                    {
                        "name": "Xã Tân Thạnh Tây",
                        "code": 27541,
                        "codename": "xa_tan_thanh_tay",
                        "division_type": "xã",
                        "short_codename": "tan_thanh_tay"
                    },
                    {
                        "name": "Xã Hòa Phú",
                        "code": 27544,
                        "codename": "xa_hoa_phu",
                        "division_type": "xã",
                        "short_codename": "hoa_phu"
                    },
                    {
                        "name": "Xã Tân Thạnh Đông",
                        "code": 27547,
                        "codename": "xa_tan_thanh_dong",
                        "division_type": "xã",
                        "short_codename": "tan_thanh_dong"
                    },
                    {
                        "name": "Xã Bình Mỹ",
                        "code": 27550,
                        "codename": "xa_binh_my",
                        "division_type": "xã",
                        "short_codename": "binh_my"
                    },
                    {
                        "name": "Xã Tân Phú Trung",
                        "code": 27553,
                        "codename": "xa_tan_phu_trung",
                        "division_type": "xã",
                        "short_codename": "tan_phu_trung"
                    },
                    {
                        "name": "Xã Tân Thông Hội",
                        "code": 27556,
                        "codename": "xa_tan_thong_hoi",
                        "division_type": "xã",
                        "short_codename": "tan_thong_hoi"
                    }
                ]
            },
            {
                "name": "Huyện Hóc Môn",
                "code": 784,
                "codename": "huyen_hoc_mon",
                "division_type": "huyện",
                "short_codename": "hoc_mon",
                "wards": [
                    {
                        "name": "Thị trấn Hóc Môn",
                        "code": 27559,
                        "codename": "thi_tran_hoc_mon",
                        "division_type": "thị trấn",
                        "short_codename": "hoc_mon"
                    },
                    {
                        "name": "Xã Tân Hiệp",
                        "code": 27562,
                        "codename": "xa_tan_hiep",
                        "division_type": "xã",
                        "short_codename": "tan_hiep"
                    },
                    {
                        "name": "Xã Nhị Bình",
                        "code": 27565,
                        "codename": "xa_nhi_binh",
                        "division_type": "xã",
                        "short_codename": "nhi_binh"
                    },
                    {
                        "name": "Xã Đông Thạnh",
                        "code": 27568,
                        "codename": "xa_dong_thanh",
                        "division_type": "xã",
                        "short_codename": "dong_thanh"
                    },
                    {
                        "name": "Xã Tân Thới Nhì",
                        "code": 27571,
                        "codename": "xa_tan_thoi_nhi",
                        "division_type": "xã",
                        "short_codename": "tan_thoi_nhi"
                    },
                    {
                        "name": "Xã Thới Tam Thôn",
                        "code": 27574,
                        "codename": "xa_thoi_tam_thon",
                        "division_type": "xã",
                        "short_codename": "thoi_tam_thon"
                    },
                    {
                        "name": "Xã Xuân Thới Sơn",
                        "code": 27577,
                        "codename": "xa_xuan_thoi_son",
                        "division_type": "xã",
                        "short_codename": "xuan_thoi_son"
                    },
                    {
                        "name": "Xã Tân Xuân",
                        "code": 27580,
                        "codename": "xa_tan_xuan",
                        "division_type": "xã",
                        "short_codename": "tan_xuan"
                    },
                    {
                        "name": "Xã Xuân Thới Đông",
                        "code": 27583,
                        "codename": "xa_xuan_thoi_dong",
                        "division_type": "xã",
                        "short_codename": "xuan_thoi_dong"
                    },
                    {
                        "name": "Xã Trung Chánh",
                        "code": 27586,
                        "codename": "xa_trung_chanh",
                        "division_type": "xã",
                        "short_codename": "trung_chanh"
                    },
                    {
                        "name": "Xã Xuân Thới Thượng",
                        "code": 27589,
                        "codename": "xa_xuan_thoi_thuong",
                        "division_type": "xã",
                        "short_codename": "xuan_thoi_thuong"
                    },
                    {
                        "name": "Xã Bà Điểm",
                        "code": 27592,
                        "codename": "xa_ba_diem",
                        "division_type": "xã",
                        "short_codename": "ba_diem"
                    }
                ]
            },
            {
                "name": "Huyện Bình Chánh",
                "code": 785,
                "codename": "huyen_binh_chanh",
                "division_type": "huyện",
                "short_codename": "binh_chanh",
                "wards": [
                    {
                        "name": "Thị trấn Tân Túc",
                        "code": 27595,
                        "codename": "thi_tran_tan_tuc",
                        "division_type": "thị trấn",
                        "short_codename": "tan_tuc"
                    },
                    {
                        "name": "Xã Phạm Văn Hai",
                        "code": 27598,
                        "codename": "xa_pham_van_hai",
                        "division_type": "xã",
                        "short_codename": "pham_van_hai"
                    },
                    {
                        "name": "Xã Vĩnh Lộc A",
                        "code": 27601,
                        "codename": "xa_vinh_loc_a",
                        "division_type": "xã",
                        "short_codename": "vinh_loc_a"
                    },
                    {
                        "name": "Xã Vĩnh Lộc B",
                        "code": 27604,
                        "codename": "xa_vinh_loc_b",
                        "division_type": "xã",
                        "short_codename": "vinh_loc_b"
                    },
                    {
                        "name": "Xã Bình Lợi",
                        "code": 27607,
                        "codename": "xa_binh_loi",
                        "division_type": "xã",
                        "short_codename": "binh_loi"
                    },
                    {
                        "name": "Xã Lê Minh Xuân",
                        "code": 27610,
                        "codename": "xa_le_minh_xuan",
                        "division_type": "xã",
                        "short_codename": "le_minh_xuan"
                    },
                    {
                        "name": "Xã Tân Nhựt",
                        "code": 27613,
                        "codename": "xa_tan_nhut",
                        "division_type": "xã",
                        "short_codename": "tan_nhut"
                    },
                    {
                        "name": "Xã Tân Kiên",
                        "code": 27616,
                        "codename": "xa_tan_kien",
                        "division_type": "xã",
                        "short_codename": "tan_kien"
                    },
                    {
                        "name": "Xã Bình Hưng",
                        "code": 27619,
                        "codename": "xa_binh_hung",
                        "division_type": "xã",
                        "short_codename": "binh_hung"
                    },
                    {
                        "name": "Xã Phong Phú",
                        "code": 27622,
                        "codename": "xa_phong_phu",
                        "division_type": "xã",
                        "short_codename": "phong_phu"
                    },
                    {
                        "name": "Xã An Phú Tây",
                        "code": 27625,
                        "codename": "xa_an_phu_tay",
                        "division_type": "xã",
                        "short_codename": "an_phu_tay"
                    },
                    {
                        "name": "Xã Hưng Long",
                        "code": 27628,
                        "codename": "xa_hung_long",
                        "division_type": "xã",
                        "short_codename": "hung_long"
                    },
                    {
                        "name": "Xã Đa Phước",
                        "code": 27631,
                        "codename": "xa_da_phuoc",
                        "division_type": "xã",
                        "short_codename": "da_phuoc"
                    },
                    {
                        "name": "Xã Tân Quý Tây",
                        "code": 27634,
                        "codename": "xa_tan_quy_tay",
                        "division_type": "xã",
                        "short_codename": "tan_quy_tay"
                    },
                    {
                        "name": "Xã Bình Chánh",
                        "code": 27637,
                        "codename": "xa_binh_chanh",
                        "division_type": "xã",
                        "short_codename": "binh_chanh"
                    },
                    {
                        "name": "Xã Quy Đức",
                        "code": 27640,
                        "codename": "xa_quy_duc",
                        "division_type": "xã",
                        "short_codename": "quy_duc"
                    }
                ]
            },
            {
                "name": "Huyện Nhà Bè",
                "code": 786,
                "codename": "huyen_nha_be",
                "division_type": "huyện",
                "short_codename": "nha_be",
                "wards": [
                    {
                        "name": "Thị trấn Nhà Bè",
                        "code": 27643,
                        "codename": "thi_tran_nha_be",
                        "division_type": "thị trấn",
                        "short_codename": "nha_be"
                    },
                    {
                        "name": "Xã Phước Kiển",
                        "code": 27646,
                        "codename": "xa_phuoc_kien",
                        "division_type": "xã",
                        "short_codename": "phuoc_kien"
                    },
                    {
                        "name": "Xã Phước Lộc",
                        "code": 27649,
                        "codename": "xa_phuoc_loc",
                        "division_type": "xã",
                        "short_codename": "phuoc_loc"
                    },
                    {
                        "name": "Xã Nhơn Đức",
                        "code": 27652,
                        "codename": "xa_nhon_duc",
                        "division_type": "xã",
                        "short_codename": "nhon_duc"
                    },
                    {
                        "name": "Xã Phú Xuân",
                        "code": 27655,
                        "codename": "xa_phu_xuan",
                        "division_type": "xã",
                        "short_codename": "phu_xuan"
                    },
                    {
                        "name": "Xã Long Thới",
                        "code": 27658,
                        "codename": "xa_long_thoi",
                        "division_type": "xã",
                        "short_codename": "long_thoi"
                    },
                    {
                        "name": "Xã Hiệp Phước",
                        "code": 27661,
                        "codename": "xa_hiep_phuoc",
                        "division_type": "xã",
                        "short_codename": "hiep_phuoc"
                    }
                ]
            },
            {
                "name": "Huyện Cần Giờ",
                "code": 787,
                "codename": "huyen_can_gio",
                "division_type": "huyện",
                "short_codename": "can_gio",
                "wards": [
                    {
                        "name": "Thị trấn Cần Thạnh",
                        "code": 27664,
                        "codename": "thi_tran_can_thanh",
                        "division_type": "thị trấn",
                        "short_codename": "can_thanh"
                    },
                    {
                        "name": "Xã Bình Khánh",
                        "code": 27667,
                        "codename": "xa_binh_khanh",
                        "division_type": "xã",
                        "short_codename": "binh_khanh"
                    },
                    {
                        "name": "Xã Tam Thôn Hiệp",
                        "code": 27670,
                        "codename": "xa_tam_thon_hiep",
                        "division_type": "xã",
                        "short_codename": "tam_thon_hiep"
                    },
                    {
                        "name": "Xã An Thới Đông",
                        "code": 27673,
                        "codename": "xa_an_thoi_dong",
                        "division_type": "xã",
                        "short_codename": "an_thoi_dong"
                    },
                    {
                        "name": "Xã Thạnh An",
                        "code": 27676,
                        "codename": "xa_thanh_an",
                        "division_type": "xã",
                        "short_codename": "thanh_an"
                    },
                    {
                        "name": "Xã Long Hòa",
                        "code": 27679,
                        "codename": "xa_long_hoa",
                        "division_type": "xã",
                        "short_codename": "long_hoa"
                    },
                    {
                        "name": "Xã Lý Nhơn",
                        "code": 27682,
                        "codename": "xa_ly_nhon",
                        "division_type": "xã",
                        "short_codename": "ly_nhon"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Long An",
        "code": 80,
        "codename": "tinh_long_an",
        "division_type": "tỉnh",
        "phone_code": 272,
        "districts": [
            {
                "name": "Thành phố Tân An",
                "code": 794,
                "codename": "thanh_pho_tan_an",
                "division_type": "thành phố",
                "short_codename": "tan_an",
                "wards": [
                    {
                        "name": "Phường 5",
                        "code": 27685,
                        "codename": "phuong_5",
                        "division_type": "phường",
                        "short_codename": "phuong_5"
                    },
                    {
                        "name": "Phường 2",
                        "code": 27688,
                        "codename": "phuong_2",
                        "division_type": "phường",
                        "short_codename": "phuong_2"
                    },
                    {
                        "name": "Phường 4",
                        "code": 27691,
                        "codename": "phuong_4",
                        "division_type": "phường",
                        "short_codename": "phuong_4"
                    },
                    {
                        "name": "Phường Tân Khánh",
                        "code": 27692,
                        "codename": "phuong_tan_khanh",
                        "division_type": "phường",
                        "short_codename": "tan_khanh"
                    },
                    {
                        "name": "Phường 1",
                        "code": 27694,
                        "codename": "phuong_1",
                        "division_type": "phường",
                        "short_codename": "phuong_1"
                    },
                    {
                        "name": "Phường 3",
                        "code": 27697,
                        "codename": "phuong_3",
                        "division_type": "phường",
                        "short_codename": "phuong_3"
                    },
                    {
                        "name": "Phường 7",
                        "code": 27698,
                        "codename": "phuong_7",
                        "division_type": "phường",
                        "short_codename": "phuong_7"
                    },
                    {
                        "name": "Phường 6",
                        "code": 27700,
                        "codename": "phuong_6",
                        "division_type": "phường",
                        "short_codename": "phuong_6"
                    },
                    {
                        "name": "Xã Hướng Thọ Phú",
                        "code": 27703,
                        "codename": "xa_huong_tho_phu",
                        "division_type": "xã",
                        "short_codename": "huong_tho_phu"
                    },
                    {
                        "name": "Xã Nhơn Thạnh Trung",
                        "code": 27706,
                        "codename": "xa_nhon_thanh_trung",
                        "division_type": "xã",
                        "short_codename": "nhon_thanh_trung"
                    },
                    {
                        "name": "Xã Lợi Bình Nhơn",
                        "code": 27709,
                        "codename": "xa_loi_binh_nhon",
                        "division_type": "xã",
                        "short_codename": "loi_binh_nhon"
                    },
                    {
                        "name": "Xã Bình Tâm",
                        "code": 27712,
                        "codename": "xa_binh_tam",
                        "division_type": "xã",
                        "short_codename": "binh_tam"
                    },
                    {
                        "name": "Phường Khánh Hậu",
                        "code": 27715,
                        "codename": "phuong_khanh_hau",
                        "division_type": "phường",
                        "short_codename": "khanh_hau"
                    },
                    {
                        "name": "Xã An Vĩnh Ngãi",
                        "code": 27718,
                        "codename": "xa_an_vinh_ngai",
                        "division_type": "xã",
                        "short_codename": "an_vinh_ngai"
                    }
                ]
            },
            {
                "name": "Thị xã Kiến Tường",
                "code": 795,
                "codename": "thi_xa_kien_tuong",
                "division_type": "thị xã",
                "short_codename": "kien_tuong",
                "wards": [
                    {
                        "name": "Phường 1",
                        "code": 27787,
                        "codename": "phuong_1",
                        "division_type": "phường",
                        "short_codename": "phuong_1"
                    },
                    {
                        "name": "Phường 2",
                        "code": 27788,
                        "codename": "phuong_2",
                        "division_type": "phường",
                        "short_codename": "phuong_2"
                    },
                    {
                        "name": "Xã Thạnh Trị",
                        "code": 27790,
                        "codename": "xa_thanh_tri",
                        "division_type": "xã",
                        "short_codename": "thanh_tri"
                    },
                    {
                        "name": "Xã Bình Hiệp",
                        "code": 27793,
                        "codename": "xa_binh_hiep",
                        "division_type": "xã",
                        "short_codename": "binh_hiep"
                    },
                    {
                        "name": "Xã Bình Tân",
                        "code": 27799,
                        "codename": "xa_binh_tan",
                        "division_type": "xã",
                        "short_codename": "binh_tan"
                    },
                    {
                        "name": "Xã Tuyên Thạnh",
                        "code": 27805,
                        "codename": "xa_tuyen_thanh",
                        "division_type": "xã",
                        "short_codename": "tuyen_thanh"
                    },
                    {
                        "name": "Phường 3",
                        "code": 27806,
                        "codename": "phuong_3",
                        "division_type": "phường",
                        "short_codename": "phuong_3"
                    },
                    {
                        "name": "Xã Thạnh Hưng",
                        "code": 27817,
                        "codename": "xa_thanh_hung",
                        "division_type": "xã",
                        "short_codename": "thanh_hung"
                    }
                ]
            },
            {
                "name": "Huyện Tân Hưng",
                "code": 796,
                "codename": "huyen_tan_hung",
                "division_type": "huyện",
                "short_codename": "tan_hung",
                "wards": [
                    {
                        "name": "Thị trấn Tân Hưng",
                        "code": 27721,
                        "codename": "thi_tran_tan_hung",
                        "division_type": "thị trấn",
                        "short_codename": "tan_hung"
                    },
                    {
                        "name": "Xã Hưng Hà",
                        "code": 27724,
                        "codename": "xa_hung_ha",
                        "division_type": "xã",
                        "short_codename": "hung_ha"
                    },
                    {
                        "name": "Xã Hưng Điền B",
                        "code": 27727,
                        "codename": "xa_hung_dien_b",
                        "division_type": "xã",
                        "short_codename": "hung_dien_b"
                    },
                    {
                        "name": "Xã Hưng Điền",
                        "code": 27730,
                        "codename": "xa_hung_dien",
                        "division_type": "xã",
                        "short_codename": "hung_dien"
                    },
                    {
                        "name": "Xã Thạnh Hưng",
                        "code": 27733,
                        "codename": "xa_thanh_hung",
                        "division_type": "xã",
                        "short_codename": "thanh_hung"
                    },
                    {
                        "name": "Xã Hưng Thạnh",
                        "code": 27736,
                        "codename": "xa_hung_thanh",
                        "division_type": "xã",
                        "short_codename": "hung_thanh"
                    },
                    {
                        "name": "Xã Vĩnh Thạnh",
                        "code": 27739,
                        "codename": "xa_vinh_thanh",
                        "division_type": "xã",
                        "short_codename": "vinh_thanh"
                    },
                    {
                        "name": "Xã Vĩnh Châu B",
                        "code": 27742,
                        "codename": "xa_vinh_chau_b",
                        "division_type": "xã",
                        "short_codename": "vinh_chau_b"
                    },
                    {
                        "name": "Xã Vĩnh Lợi",
                        "code": 27745,
                        "codename": "xa_vinh_loi",
                        "division_type": "xã",
                        "short_codename": "vinh_loi"
                    },
                    {
                        "name": "Xã Vĩnh Đại",
                        "code": 27748,
                        "codename": "xa_vinh_dai",
                        "division_type": "xã",
                        "short_codename": "vinh_dai"
                    },
                    {
                        "name": "Xã Vĩnh Châu A",
                        "code": 27751,
                        "codename": "xa_vinh_chau_a",
                        "division_type": "xã",
                        "short_codename": "vinh_chau_a"
                    },
                    {
                        "name": "Xã Vĩnh Bửu",
                        "code": 27754,
                        "codename": "xa_vinh_buu",
                        "division_type": "xã",
                        "short_codename": "vinh_buu"
                    }
                ]
            },
            {
                "name": "Huyện Vĩnh Hưng",
                "code": 797,
                "codename": "huyen_vinh_hung",
                "division_type": "huyện",
                "short_codename": "vinh_hung",
                "wards": [
                    {
                        "name": "Thị trấn Vĩnh Hưng",
                        "code": 27757,
                        "codename": "thi_tran_vinh_hung",
                        "division_type": "thị trấn",
                        "short_codename": "vinh_hung"
                    },
                    {
                        "name": "Xã Hưng Điền A",
                        "code": 27760,
                        "codename": "xa_hung_dien_a",
                        "division_type": "xã",
                        "short_codename": "hung_dien_a"
                    },
                    {
                        "name": "Xã Khánh Hưng",
                        "code": 27763,
                        "codename": "xa_khanh_hung",
                        "division_type": "xã",
                        "short_codename": "khanh_hung"
                    },
                    {
                        "name": "Xã Thái Trị",
                        "code": 27766,
                        "codename": "xa_thai_tri",
                        "division_type": "xã",
                        "short_codename": "thai_tri"
                    },
                    {
                        "name": "Xã Vĩnh Trị",
                        "code": 27769,
                        "codename": "xa_vinh_tri",
                        "division_type": "xã",
                        "short_codename": "vinh_tri"
                    },
                    {
                        "name": "Xã Thái Bình Trung",
                        "code": 27772,
                        "codename": "xa_thai_binh_trung",
                        "division_type": "xã",
                        "short_codename": "thai_binh_trung"
                    },
                    {
                        "name": "Xã Vĩnh Bình",
                        "code": 27775,
                        "codename": "xa_vinh_binh",
                        "division_type": "xã",
                        "short_codename": "vinh_binh"
                    },
                    {
                        "name": "Xã Vĩnh Thuận",
                        "code": 27778,
                        "codename": "xa_vinh_thuan",
                        "division_type": "xã",
                        "short_codename": "vinh_thuan"
                    },
                    {
                        "name": "Xã Tuyên Bình",
                        "code": 27781,
                        "codename": "xa_tuyen_binh",
                        "division_type": "xã",
                        "short_codename": "tuyen_binh"
                    },
                    {
                        "name": "Xã Tuyên Bình Tây",
                        "code": 27784,
                        "codename": "xa_tuyen_binh_tay",
                        "division_type": "xã",
                        "short_codename": "tuyen_binh_tay"
                    }
                ]
            },
            {
                "name": "Huyện Mộc Hóa",
                "code": 798,
                "codename": "huyen_moc_hoa",
                "division_type": "huyện",
                "short_codename": "moc_hoa",
                "wards": [
                    {
                        "name": "Xã Bình Hòa Tây",
                        "code": 27796,
                        "codename": "xa_binh_hoa_tay",
                        "division_type": "xã",
                        "short_codename": "binh_hoa_tay"
                    },
                    {
                        "name": "Xã Bình Thạnh",
                        "code": 27802,
                        "codename": "xa_binh_thanh",
                        "division_type": "xã",
                        "short_codename": "binh_thanh"
                    },
                    {
                        "name": "Xã Bình Hòa Trung",
                        "code": 27808,
                        "codename": "xa_binh_hoa_trung",
                        "division_type": "xã",
                        "short_codename": "binh_hoa_trung"
                    },
                    {
                        "name": "Xã Bình Hòa Đông",
                        "code": 27811,
                        "codename": "xa_binh_hoa_dong",
                        "division_type": "xã",
                        "short_codename": "binh_hoa_dong"
                    },
                    {
                        "name": "Thị trấn Bình Phong Thạnh",
                        "code": 27814,
                        "codename": "thi_tran_binh_phong_thanh",
                        "division_type": "thị trấn",
                        "short_codename": "binh_phong_thanh"
                    },
                    {
                        "name": "Xã Tân Lập",
                        "code": 27820,
                        "codename": "xa_tan_lap",
                        "division_type": "xã",
                        "short_codename": "tan_lap"
                    },
                    {
                        "name": "Xã Tân Thành",
                        "code": 27823,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    }
                ]
            },
            {
                "name": "Huyện Tân Thạnh",
                "code": 799,
                "codename": "huyen_tan_thanh",
                "division_type": "huyện",
                "short_codename": "tan_thanh",
                "wards": [
                    {
                        "name": "Thị trấn Tân Thạnh",
                        "code": 27826,
                        "codename": "thi_tran_tan_thanh",
                        "division_type": "thị trấn",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Xã Bắc Hòa",
                        "code": 27829,
                        "codename": "xa_bac_hoa",
                        "division_type": "xã",
                        "short_codename": "bac_hoa"
                    },
                    {
                        "name": "Xã Hậu Thạnh Tây",
                        "code": 27832,
                        "codename": "xa_hau_thanh_tay",
                        "division_type": "xã",
                        "short_codename": "hau_thanh_tay"
                    },
                    {
                        "name": "Xã Nhơn Hòa Lập",
                        "code": 27835,
                        "codename": "xa_nhon_hoa_lap",
                        "division_type": "xã",
                        "short_codename": "nhon_hoa_lap"
                    },
                    {
                        "name": "Xã Tân Lập",
                        "code": 27838,
                        "codename": "xa_tan_lap",
                        "division_type": "xã",
                        "short_codename": "tan_lap"
                    },
                    {
                        "name": "Xã Hậu Thạnh Đông",
                        "code": 27841,
                        "codename": "xa_hau_thanh_dong",
                        "division_type": "xã",
                        "short_codename": "hau_thanh_dong"
                    },
                    {
                        "name": "Xã Nhơn Hoà",
                        "code": 27844,
                        "codename": "xa_nhon_hoa",
                        "division_type": "xã",
                        "short_codename": "nhon_hoa"
                    },
                    {
                        "name": "Xã Kiến Bình",
                        "code": 27847,
                        "codename": "xa_kien_binh",
                        "division_type": "xã",
                        "short_codename": "kien_binh"
                    },
                    {
                        "name": "Xã Tân Thành",
                        "code": 27850,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "xa_tan_thanh"
                    },
                    {
                        "name": "Xã Tân Bình",
                        "code": 27853,
                        "codename": "xa_tan_binh",
                        "division_type": "xã",
                        "short_codename": "tan_binh"
                    },
                    {
                        "name": "Xã Tân Ninh",
                        "code": 27856,
                        "codename": "xa_tan_ninh",
                        "division_type": "xã",
                        "short_codename": "tan_ninh"
                    },
                    {
                        "name": "Xã Nhơn Ninh",
                        "code": 27859,
                        "codename": "xa_nhon_ninh",
                        "division_type": "xã",
                        "short_codename": "nhon_ninh"
                    },
                    {
                        "name": "Xã Tân Hòa",
                        "code": 27862,
                        "codename": "xa_tan_hoa",
                        "division_type": "xã",
                        "short_codename": "tan_hoa"
                    }
                ]
            },
            {
                "name": "Huyện Thạnh Hóa",
                "code": 800,
                "codename": "huyen_thanh_hoa",
                "division_type": "huyện",
                "short_codename": "thanh_hoa",
                "wards": [
                    {
                        "name": "Thị trấn Thạnh Hóa",
                        "code": 27865,
                        "codename": "thi_tran_thanh_hoa",
                        "division_type": "thị trấn",
                        "short_codename": "thanh_hoa"
                    },
                    {
                        "name": "Xã Tân Hiệp",
                        "code": 27868,
                        "codename": "xa_tan_hiep",
                        "division_type": "xã",
                        "short_codename": "tan_hiep"
                    },
                    {
                        "name": "Xã Thuận Bình",
                        "code": 27871,
                        "codename": "xa_thuan_binh",
                        "division_type": "xã",
                        "short_codename": "thuan_binh"
                    },
                    {
                        "name": "Xã Thạnh Phước",
                        "code": 27874,
                        "codename": "xa_thanh_phuoc",
                        "division_type": "xã",
                        "short_codename": "thanh_phuoc"
                    },
                    {
                        "name": "Xã Thạnh Phú",
                        "code": 27877,
                        "codename": "xa_thanh_phu",
                        "division_type": "xã",
                        "short_codename": "thanh_phu"
                    },
                    {
                        "name": "Xã Thuận Nghĩa Hòa",
                        "code": 27880,
                        "codename": "xa_thuan_nghia_hoa",
                        "division_type": "xã",
                        "short_codename": "thuan_nghia_hoa"
                    },
                    {
                        "name": "Xã Thủy Đông",
                        "code": 27883,
                        "codename": "xa_thuy_dong",
                        "division_type": "xã",
                        "short_codename": "thuy_dong"
                    },
                    {
                        "name": "Xã Thủy Tây",
                        "code": 27886,
                        "codename": "xa_thuy_tay",
                        "division_type": "xã",
                        "short_codename": "thuy_tay"
                    },
                    {
                        "name": "Xã Tân Tây",
                        "code": 27889,
                        "codename": "xa_tan_tay",
                        "division_type": "xã",
                        "short_codename": "tan_tay"
                    },
                    {
                        "name": "Xã Tân Đông",
                        "code": 27892,
                        "codename": "xa_tan_dong",
                        "division_type": "xã",
                        "short_codename": "tan_dong"
                    },
                    {
                        "name": "Xã Thạnh An",
                        "code": 27895,
                        "codename": "xa_thanh_an",
                        "division_type": "xã",
                        "short_codename": "thanh_an"
                    }
                ]
            },
            {
                "name": "Huyện Đức Huệ",
                "code": 801,
                "codename": "huyen_duc_hue",
                "division_type": "huyện",
                "short_codename": "duc_hue",
                "wards": [
                    {
                        "name": "Thị trấn Đông Thành",
                        "code": 27898,
                        "codename": "thi_tran_dong_thanh",
                        "division_type": "thị trấn",
                        "short_codename": "dong_thanh"
                    },
                    {
                        "name": "Xã Mỹ Quý Đông",
                        "code": 27901,
                        "codename": "xa_my_quy_dong",
                        "division_type": "xã",
                        "short_codename": "my_quy_dong"
                    },
                    {
                        "name": "Xã Mỹ Thạnh Bắc",
                        "code": 27904,
                        "codename": "xa_my_thanh_bac",
                        "division_type": "xã",
                        "short_codename": "my_thanh_bac"
                    },
                    {
                        "name": "Xã Mỹ Quý Tây",
                        "code": 27907,
                        "codename": "xa_my_quy_tay",
                        "division_type": "xã",
                        "short_codename": "my_quy_tay"
                    },
                    {
                        "name": "Xã Mỹ Thạnh Tây",
                        "code": 27910,
                        "codename": "xa_my_thanh_tay",
                        "division_type": "xã",
                        "short_codename": "my_thanh_tay"
                    },
                    {
                        "name": "Xã Mỹ Thạnh Đông",
                        "code": 27913,
                        "codename": "xa_my_thanh_dong",
                        "division_type": "xã",
                        "short_codename": "my_thanh_dong"
                    },
                    {
                        "name": "Xã Bình Thành",
                        "code": 27916,
                        "codename": "xa_binh_thanh",
                        "division_type": "xã",
                        "short_codename": "binh_thanh"
                    },
                    {
                        "name": "Xã Bình Hòa Bắc",
                        "code": 27919,
                        "codename": "xa_binh_hoa_bac",
                        "division_type": "xã",
                        "short_codename": "binh_hoa_bac"
                    },
                    {
                        "name": "Xã Bình Hòa Hưng",
                        "code": 27922,
                        "codename": "xa_binh_hoa_hung",
                        "division_type": "xã",
                        "short_codename": "binh_hoa_hung"
                    },
                    {
                        "name": "Xã Bình Hòa Nam",
                        "code": 27925,
                        "codename": "xa_binh_hoa_nam",
                        "division_type": "xã",
                        "short_codename": "binh_hoa_nam"
                    },
                    {
                        "name": "Xã Mỹ Bình",
                        "code": 27928,
                        "codename": "xa_my_binh",
                        "division_type": "xã",
                        "short_codename": "my_binh"
                    }
                ]
            },
            {
                "name": "Huyện Đức Hòa",
                "code": 802,
                "codename": "huyen_duc_hoa",
                "division_type": "huyện",
                "short_codename": "duc_hoa",
                "wards": [
                    {
                        "name": "Thị trấn Hậu Nghĩa",
                        "code": 27931,
                        "codename": "thi_tran_hau_nghia",
                        "division_type": "thị trấn",
                        "short_codename": "hau_nghia"
                    },
                    {
                        "name": "Thị trấn Hiệp Hòa",
                        "code": 27934,
                        "codename": "thi_tran_hiep_hoa",
                        "division_type": "thị trấn",
                        "short_codename": "hiep_hoa"
                    },
                    {
                        "name": "Thị trấn Đức Hòa",
                        "code": 27937,
                        "codename": "thi_tran_duc_hoa",
                        "division_type": "thị trấn",
                        "short_codename": "duc_hoa"
                    },
                    {
                        "name": "Xã Lộc Giang",
                        "code": 27940,
                        "codename": "xa_loc_giang",
                        "division_type": "xã",
                        "short_codename": "loc_giang"
                    },
                    {
                        "name": "Xã An Ninh Đông",
                        "code": 27943,
                        "codename": "xa_an_ninh_dong",
                        "division_type": "xã",
                        "short_codename": "an_ninh_dong"
                    },
                    {
                        "name": "Xã An Ninh Tây",
                        "code": 27946,
                        "codename": "xa_an_ninh_tay",
                        "division_type": "xã",
                        "short_codename": "an_ninh_tay"
                    },
                    {
                        "name": "Xã Tân Mỹ",
                        "code": 27949,
                        "codename": "xa_tan_my",
                        "division_type": "xã",
                        "short_codename": "tan_my"
                    },
                    {
                        "name": "Xã Hiệp Hòa",
                        "code": 27952,
                        "codename": "xa_hiep_hoa",
                        "division_type": "xã",
                        "short_codename": "xa_hiep_hoa"
                    },
                    {
                        "name": "Xã Đức Lập Thượng",
                        "code": 27955,
                        "codename": "xa_duc_lap_thuong",
                        "division_type": "xã",
                        "short_codename": "duc_lap_thuong"
                    },
                    {
                        "name": "Xã Đức Lập Hạ",
                        "code": 27958,
                        "codename": "xa_duc_lap_ha",
                        "division_type": "xã",
                        "short_codename": "duc_lap_ha"
                    },
                    {
                        "name": "Xã Tân Phú",
                        "code": 27961,
                        "codename": "xa_tan_phu",
                        "division_type": "xã",
                        "short_codename": "tan_phu"
                    },
                    {
                        "name": "Xã Mỹ Hạnh Bắc",
                        "code": 27964,
                        "codename": "xa_my_hanh_bac",
                        "division_type": "xã",
                        "short_codename": "my_hanh_bac"
                    },
                    {
                        "name": "Xã Đức Hòa Thượng",
                        "code": 27967,
                        "codename": "xa_duc_hoa_thuong",
                        "division_type": "xã",
                        "short_codename": "duc_hoa_thuong"
                    },
                    {
                        "name": "Xã Hòa Khánh Tây",
                        "code": 27970,
                        "codename": "xa_hoa_khanh_tay",
                        "division_type": "xã",
                        "short_codename": "hoa_khanh_tay"
                    },
                    {
                        "name": "Xã Hòa Khánh Đông",
                        "code": 27973,
                        "codename": "xa_hoa_khanh_dong",
                        "division_type": "xã",
                        "short_codename": "hoa_khanh_dong"
                    },
                    {
                        "name": "Xã Mỹ Hạnh Nam",
                        "code": 27976,
                        "codename": "xa_my_hanh_nam",
                        "division_type": "xã",
                        "short_codename": "my_hanh_nam"
                    },
                    {
                        "name": "Xã Hòa Khánh Nam",
                        "code": 27979,
                        "codename": "xa_hoa_khanh_nam",
                        "division_type": "xã",
                        "short_codename": "hoa_khanh_nam"
                    },
                    {
                        "name": "Xã Đức Hòa Đông",
                        "code": 27982,
                        "codename": "xa_duc_hoa_dong",
                        "division_type": "xã",
                        "short_codename": "duc_hoa_dong"
                    },
                    {
                        "name": "Xã Đức Hòa Hạ",
                        "code": 27985,
                        "codename": "xa_duc_hoa_ha",
                        "division_type": "xã",
                        "short_codename": "duc_hoa_ha"
                    },
                    {
                        "name": "Xã Hựu Thạnh",
                        "code": 27988,
                        "codename": "xa_huu_thanh",
                        "division_type": "xã",
                        "short_codename": "huu_thanh"
                    }
                ]
            },
            {
                "name": "Huyện Bến Lức",
                "code": 803,
                "codename": "huyen_ben_luc",
                "division_type": "huyện",
                "short_codename": "ben_luc",
                "wards": [
                    {
                        "name": "Thị trấn Bến Lức",
                        "code": 27991,
                        "codename": "thi_tran_ben_luc",
                        "division_type": "thị trấn",
                        "short_codename": "ben_luc"
                    },
                    {
                        "name": "Xã Thạnh Lợi",
                        "code": 27994,
                        "codename": "xa_thanh_loi",
                        "division_type": "xã",
                        "short_codename": "thanh_loi"
                    },
                    {
                        "name": "Xã Lương Bình",
                        "code": 27997,
                        "codename": "xa_luong_binh",
                        "division_type": "xã",
                        "short_codename": "luong_binh"
                    },
                    {
                        "name": "Xã Thạnh Hòa",
                        "code": 28000,
                        "codename": "xa_thanh_hoa",
                        "division_type": "xã",
                        "short_codename": "thanh_hoa"
                    },
                    {
                        "name": "Xã Lương Hòa",
                        "code": 28003,
                        "codename": "xa_luong_hoa",
                        "division_type": "xã",
                        "short_codename": "luong_hoa"
                    },
                    {
                        "name": "Xã Tân Hòa",
                        "code": 28006,
                        "codename": "xa_tan_hoa",
                        "division_type": "xã",
                        "short_codename": "tan_hoa"
                    },
                    {
                        "name": "Xã Tân Bửu",
                        "code": 28009,
                        "codename": "xa_tan_buu",
                        "division_type": "xã",
                        "short_codename": "tan_buu"
                    },
                    {
                        "name": "Xã An Thạnh",
                        "code": 28012,
                        "codename": "xa_an_thanh",
                        "division_type": "xã",
                        "short_codename": "an_thanh"
                    },
                    {
                        "name": "Xã Bình Đức",
                        "code": 28015,
                        "codename": "xa_binh_duc",
                        "division_type": "xã",
                        "short_codename": "binh_duc"
                    },
                    {
                        "name": "Xã Mỹ Yên",
                        "code": 28018,
                        "codename": "xa_my_yen",
                        "division_type": "xã",
                        "short_codename": "my_yen"
                    },
                    {
                        "name": "Xã Thanh Phú",
                        "code": 28021,
                        "codename": "xa_thanh_phu",
                        "division_type": "xã",
                        "short_codename": "thanh_phu"
                    },
                    {
                        "name": "Xã Long Hiệp",
                        "code": 28024,
                        "codename": "xa_long_hiep",
                        "division_type": "xã",
                        "short_codename": "long_hiep"
                    },
                    {
                        "name": "Xã Thạnh Đức",
                        "code": 28027,
                        "codename": "xa_thanh_duc",
                        "division_type": "xã",
                        "short_codename": "thanh_duc"
                    },
                    {
                        "name": "Xã Phước Lợi",
                        "code": 28030,
                        "codename": "xa_phuoc_loi",
                        "division_type": "xã",
                        "short_codename": "phuoc_loi"
                    },
                    {
                        "name": "Xã Nhựt Chánh",
                        "code": 28033,
                        "codename": "xa_nhut_chanh",
                        "division_type": "xã",
                        "short_codename": "nhut_chanh"
                    }
                ]
            },
            {
                "name": "Huyện Thủ Thừa",
                "code": 804,
                "codename": "huyen_thu_thua",
                "division_type": "huyện",
                "short_codename": "thu_thua",
                "wards": [
                    {
                        "name": "Thị trấn Thủ Thừa",
                        "code": 28036,
                        "codename": "thi_tran_thu_thua",
                        "division_type": "thị trấn",
                        "short_codename": "thu_thua"
                    },
                    {
                        "name": "Xã Long Thạnh",
                        "code": 28039,
                        "codename": "xa_long_thanh",
                        "division_type": "xã",
                        "short_codename": "long_thanh"
                    },
                    {
                        "name": "Xã Tân Thành",
                        "code": 28042,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Xã Long Thuận",
                        "code": 28045,
                        "codename": "xa_long_thuan",
                        "division_type": "xã",
                        "short_codename": "long_thuan"
                    },
                    {
                        "name": "Xã Mỹ Lạc",
                        "code": 28048,
                        "codename": "xa_my_lac",
                        "division_type": "xã",
                        "short_codename": "my_lac"
                    },
                    {
                        "name": "Xã Mỹ Thạnh",
                        "code": 28051,
                        "codename": "xa_my_thanh",
                        "division_type": "xã",
                        "short_codename": "my_thanh"
                    },
                    {
                        "name": "Xã Bình An",
                        "code": 28054,
                        "codename": "xa_binh_an",
                        "division_type": "xã",
                        "short_codename": "binh_an"
                    },
                    {
                        "name": "Xã Nhị Thành",
                        "code": 28057,
                        "codename": "xa_nhi_thanh",
                        "division_type": "xã",
                        "short_codename": "nhi_thanh"
                    },
                    {
                        "name": "Xã Mỹ An",
                        "code": 28060,
                        "codename": "xa_my_an",
                        "division_type": "xã",
                        "short_codename": "my_an"
                    },
                    {
                        "name": "Xã Bình Thạnh",
                        "code": 28063,
                        "codename": "xa_binh_thanh",
                        "division_type": "xã",
                        "short_codename": "binh_thanh"
                    },
                    {
                        "name": "Xã Mỹ Phú",
                        "code": 28066,
                        "codename": "xa_my_phu",
                        "division_type": "xã",
                        "short_codename": "my_phu"
                    },
                    {
                        "name": "Xã Tân Long",
                        "code": 28072,
                        "codename": "xa_tan_long",
                        "division_type": "xã",
                        "short_codename": "tan_long"
                    }
                ]
            },
            {
                "name": "Huyện Tân Trụ",
                "code": 805,
                "codename": "huyen_tan_tru",
                "division_type": "huyện",
                "short_codename": "tan_tru",
                "wards": [
                    {
                        "name": "Thị trấn Tân Trụ",
                        "code": 28075,
                        "codename": "thi_tran_tan_tru",
                        "division_type": "thị trấn",
                        "short_codename": "tan_tru"
                    },
                    {
                        "name": "Xã Tân Bình",
                        "code": 28078,
                        "codename": "xa_tan_binh",
                        "division_type": "xã",
                        "short_codename": "tan_binh"
                    },
                    {
                        "name": "Xã Quê Mỹ Thạnh",
                        "code": 28084,
                        "codename": "xa_que_my_thanh",
                        "division_type": "xã",
                        "short_codename": "que_my_thanh"
                    },
                    {
                        "name": "Xã Lạc Tấn",
                        "code": 28087,
                        "codename": "xa_lac_tan",
                        "division_type": "xã",
                        "short_codename": "lac_tan"
                    },
                    {
                        "name": "Xã Bình Trinh Đông",
                        "code": 28090,
                        "codename": "xa_binh_trinh_dong",
                        "division_type": "xã",
                        "short_codename": "binh_trinh_dong"
                    },
                    {
                        "name": "Xã Tân Phước Tây",
                        "code": 28093,
                        "codename": "xa_tan_phuoc_tay",
                        "division_type": "xã",
                        "short_codename": "tan_phuoc_tay"
                    },
                    {
                        "name": "Xã Bình Lãng",
                        "code": 28096,
                        "codename": "xa_binh_lang",
                        "division_type": "xã",
                        "short_codename": "binh_lang"
                    },
                    {
                        "name": "Xã Bình Tịnh",
                        "code": 28099,
                        "codename": "xa_binh_tinh",
                        "division_type": "xã",
                        "short_codename": "binh_tinh"
                    },
                    {
                        "name": "Xã Đức Tân",
                        "code": 28102,
                        "codename": "xa_duc_tan",
                        "division_type": "xã",
                        "short_codename": "duc_tan"
                    },
                    {
                        "name": "Xã Nhựt Ninh",
                        "code": 28105,
                        "codename": "xa_nhut_ninh",
                        "division_type": "xã",
                        "short_codename": "nhut_ninh"
                    }
                ]
            },
            {
                "name": "Huyện Cần Đước",
                "code": 806,
                "codename": "huyen_can_duoc",
                "division_type": "huyện",
                "short_codename": "can_duoc",
                "wards": [
                    {
                        "name": "Thị trấn Cần Đước",
                        "code": 28108,
                        "codename": "thi_tran_can_duoc",
                        "division_type": "thị trấn",
                        "short_codename": "can_duoc"
                    },
                    {
                        "name": "Xã Long Trạch",
                        "code": 28111,
                        "codename": "xa_long_trach",
                        "division_type": "xã",
                        "short_codename": "long_trach"
                    },
                    {
                        "name": "Xã Long Khê",
                        "code": 28114,
                        "codename": "xa_long_khe",
                        "division_type": "xã",
                        "short_codename": "long_khe"
                    },
                    {
                        "name": "Xã Long Định",
                        "code": 28117,
                        "codename": "xa_long_dinh",
                        "division_type": "xã",
                        "short_codename": "long_dinh"
                    },
                    {
                        "name": "Xã Phước Vân",
                        "code": 28120,
                        "codename": "xa_phuoc_van",
                        "division_type": "xã",
                        "short_codename": "phuoc_van"
                    },
                    {
                        "name": "Xã Long Hòa",
                        "code": 28123,
                        "codename": "xa_long_hoa",
                        "division_type": "xã",
                        "short_codename": "long_hoa"
                    },
                    {
                        "name": "Xã Long Cang",
                        "code": 28126,
                        "codename": "xa_long_cang",
                        "division_type": "xã",
                        "short_codename": "long_cang"
                    },
                    {
                        "name": "Xã Long Sơn",
                        "code": 28129,
                        "codename": "xa_long_son",
                        "division_type": "xã",
                        "short_codename": "long_son"
                    },
                    {
                        "name": "Xã Tân Trạch",
                        "code": 28132,
                        "codename": "xa_tan_trach",
                        "division_type": "xã",
                        "short_codename": "tan_trach"
                    },
                    {
                        "name": "Xã Mỹ Lệ",
                        "code": 28135,
                        "codename": "xa_my_le",
                        "division_type": "xã",
                        "short_codename": "my_le"
                    },
                    {
                        "name": "Xã Tân Lân",
                        "code": 28138,
                        "codename": "xa_tan_lan",
                        "division_type": "xã",
                        "short_codename": "tan_lan"
                    },
                    {
                        "name": "Xã Phước Tuy",
                        "code": 28141,
                        "codename": "xa_phuoc_tuy",
                        "division_type": "xã",
                        "short_codename": "phuoc_tuy"
                    },
                    {
                        "name": "Xã Long Hựu Đông",
                        "code": 28144,
                        "codename": "xa_long_huu_dong",
                        "division_type": "xã",
                        "short_codename": "long_huu_dong"
                    },
                    {
                        "name": "Xã Tân Ân",
                        "code": 28147,
                        "codename": "xa_tan_an",
                        "division_type": "xã",
                        "short_codename": "tan_an"
                    },
                    {
                        "name": "Xã Phước Đông",
                        "code": 28150,
                        "codename": "xa_phuoc_dong",
                        "division_type": "xã",
                        "short_codename": "phuoc_dong"
                    },
                    {
                        "name": "Xã Long Hựu Tây",
                        "code": 28153,
                        "codename": "xa_long_huu_tay",
                        "division_type": "xã",
                        "short_codename": "long_huu_tay"
                    },
                    {
                        "name": "Xã Tân Chánh",
                        "code": 28156,
                        "codename": "xa_tan_chanh",
                        "division_type": "xã",
                        "short_codename": "tan_chanh"
                    }
                ]
            },
            {
                "name": "Huyện Cần Giuộc",
                "code": 807,
                "codename": "huyen_can_giuoc",
                "division_type": "huyện",
                "short_codename": "can_giuoc",
                "wards": [
                    {
                        "name": "Thị trấn Cần Giuộc",
                        "code": 28159,
                        "codename": "thi_tran_can_giuoc",
                        "division_type": "thị trấn",
                        "short_codename": "can_giuoc"
                    },
                    {
                        "name": "Xã Phước Lý",
                        "code": 28162,
                        "codename": "xa_phuoc_ly",
                        "division_type": "xã",
                        "short_codename": "phuoc_ly"
                    },
                    {
                        "name": "Xã Long Thượng",
                        "code": 28165,
                        "codename": "xa_long_thuong",
                        "division_type": "xã",
                        "short_codename": "long_thuong"
                    },
                    {
                        "name": "Xã Long Hậu",
                        "code": 28168,
                        "codename": "xa_long_hau",
                        "division_type": "xã",
                        "short_codename": "long_hau"
                    },
                    {
                        "name": "Xã Phước Hậu",
                        "code": 28174,
                        "codename": "xa_phuoc_hau",
                        "division_type": "xã",
                        "short_codename": "phuoc_hau"
                    },
                    {
                        "name": "Xã Mỹ Lộc",
                        "code": 28177,
                        "codename": "xa_my_loc",
                        "division_type": "xã",
                        "short_codename": "my_loc"
                    },
                    {
                        "name": "Xã Phước Lại",
                        "code": 28180,
                        "codename": "xa_phuoc_lai",
                        "division_type": "xã",
                        "short_codename": "phuoc_lai"
                    },
                    {
                        "name": "Xã Phước Lâm",
                        "code": 28183,
                        "codename": "xa_phuoc_lam",
                        "division_type": "xã",
                        "short_codename": "phuoc_lam"
                    },
                    {
                        "name": "Xã Thuận Thành",
                        "code": 28189,
                        "codename": "xa_thuan_thanh",
                        "division_type": "xã",
                        "short_codename": "thuan_thanh"
                    },
                    {
                        "name": "Xã Phước Vĩnh Tây",
                        "code": 28192,
                        "codename": "xa_phuoc_vinh_tay",
                        "division_type": "xã",
                        "short_codename": "phuoc_vinh_tay"
                    },
                    {
                        "name": "Xã Phước Vĩnh Đông",
                        "code": 28195,
                        "codename": "xa_phuoc_vinh_dong",
                        "division_type": "xã",
                        "short_codename": "phuoc_vinh_dong"
                    },
                    {
                        "name": "Xã Long An",
                        "code": 28198,
                        "codename": "xa_long_an",
                        "division_type": "xã",
                        "short_codename": "long_an"
                    },
                    {
                        "name": "Xã Long Phụng",
                        "code": 28201,
                        "codename": "xa_long_phung",
                        "division_type": "xã",
                        "short_codename": "long_phung"
                    },
                    {
                        "name": "Xã Đông Thạnh",
                        "code": 28204,
                        "codename": "xa_dong_thanh",
                        "division_type": "xã",
                        "short_codename": "dong_thanh"
                    },
                    {
                        "name": "Xã Tân Tập",
                        "code": 28207,
                        "codename": "xa_tan_tap",
                        "division_type": "xã",
                        "short_codename": "tan_tap"
                    }
                ]
            },
            {
                "name": "Huyện Châu Thành",
                "code": 808,
                "codename": "huyen_chau_thanh",
                "division_type": "huyện",
                "short_codename": "chau_thanh",
                "wards": [
                    {
                        "name": "Thị trấn Tầm Vu",
                        "code": 28210,
                        "codename": "thi_tran_tam_vu",
                        "division_type": "thị trấn",
                        "short_codename": "tam_vu"
                    },
                    {
                        "name": "Xã Bình Quới",
                        "code": 28213,
                        "codename": "xa_binh_quoi",
                        "division_type": "xã",
                        "short_codename": "binh_quoi"
                    },
                    {
                        "name": "Xã Hòa Phú",
                        "code": 28216,
                        "codename": "xa_hoa_phu",
                        "division_type": "xã",
                        "short_codename": "hoa_phu"
                    },
                    {
                        "name": "Xã Phú Ngãi Trị",
                        "code": 28219,
                        "codename": "xa_phu_ngai_tri",
                        "division_type": "xã",
                        "short_codename": "phu_ngai_tri"
                    },
                    {
                        "name": "Xã Vĩnh Công",
                        "code": 28222,
                        "codename": "xa_vinh_cong",
                        "division_type": "xã",
                        "short_codename": "vinh_cong"
                    },
                    {
                        "name": "Xã Thuận Mỹ",
                        "code": 28225,
                        "codename": "xa_thuan_my",
                        "division_type": "xã",
                        "short_codename": "thuan_my"
                    },
                    {
                        "name": "Xã Hiệp Thạnh",
                        "code": 28228,
                        "codename": "xa_hiep_thanh",
                        "division_type": "xã",
                        "short_codename": "hiep_thanh"
                    },
                    {
                        "name": "Xã Phước Tân Hưng",
                        "code": 28231,
                        "codename": "xa_phuoc_tan_hung",
                        "division_type": "xã",
                        "short_codename": "phuoc_tan_hung"
                    },
                    {
                        "name": "Xã Thanh Phú Long",
                        "code": 28234,
                        "codename": "xa_thanh_phu_long",
                        "division_type": "xã",
                        "short_codename": "thanh_phu_long"
                    },
                    {
                        "name": "Xã Dương Xuân Hội",
                        "code": 28237,
                        "codename": "xa_duong_xuan_hoi",
                        "division_type": "xã",
                        "short_codename": "duong_xuan_hoi"
                    },
                    {
                        "name": "Xã An Lục Long",
                        "code": 28240,
                        "codename": "xa_an_luc_long",
                        "division_type": "xã",
                        "short_codename": "an_luc_long"
                    },
                    {
                        "name": "Xã Long Trì",
                        "code": 28243,
                        "codename": "xa_long_tri",
                        "division_type": "xã",
                        "short_codename": "long_tri"
                    },
                    {
                        "name": "Xã Thanh Vĩnh Đông",
                        "code": 28246,
                        "codename": "xa_thanh_vinh_dong",
                        "division_type": "xã",
                        "short_codename": "thanh_vinh_dong"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Tiền Giang",
        "code": 82,
        "codename": "tinh_tien_giang",
        "division_type": "tỉnh",
        "phone_code": 273,
        "districts": [
            {
                "name": "Thành phố Mỹ Tho",
                "code": 815,
                "codename": "thanh_pho_my_tho",
                "division_type": "thành phố",
                "short_codename": "my_tho",
                "wards": [
                    {
                        "name": "Phường 5",
                        "code": 28249,
                        "codename": "phuong_5",
                        "division_type": "phường",
                        "short_codename": "phuong_5"
                    },
                    {
                        "name": "Phường 4",
                        "code": 28252,
                        "codename": "phuong_4",
                        "division_type": "phường",
                        "short_codename": "phuong_4"
                    },
                    {
                        "name": "Phường 7",
                        "code": 28255,
                        "codename": "phuong_7",
                        "division_type": "phường",
                        "short_codename": "phuong_7"
                    },
                    {
                        "name": "Phường 3",
                        "code": 28258,
                        "codename": "phuong_3",
                        "division_type": "phường",
                        "short_codename": "phuong_3"
                    },
                    {
                        "name": "Phường 1",
                        "code": 28261,
                        "codename": "phuong_1",
                        "division_type": "phường",
                        "short_codename": "phuong_1"
                    },
                    {
                        "name": "Phường 2",
                        "code": 28264,
                        "codename": "phuong_2",
                        "division_type": "phường",
                        "short_codename": "phuong_2"
                    },
                    {
                        "name": "Phường 8",
                        "code": 28267,
                        "codename": "phuong_8",
                        "division_type": "phường",
                        "short_codename": "phuong_8"
                    },
                    {
                        "name": "Phường 6",
                        "code": 28270,
                        "codename": "phuong_6",
                        "division_type": "phường",
                        "short_codename": "phuong_6"
                    },
                    {
                        "name": "Phường 9",
                        "code": 28273,
                        "codename": "phuong_9",
                        "division_type": "phường",
                        "short_codename": "phuong_9"
                    },
                    {
                        "name": "Phường 10",
                        "code": 28276,
                        "codename": "phuong_10",
                        "division_type": "phường",
                        "short_codename": "phuong_10"
                    },
                    {
                        "name": "Phường Tân Long",
                        "code": 28279,
                        "codename": "phuong_tan_long",
                        "division_type": "phường",
                        "short_codename": "tan_long"
                    },
                    {
                        "name": "Xã Đạo Thạnh",
                        "code": 28282,
                        "codename": "xa_dao_thanh",
                        "division_type": "xã",
                        "short_codename": "dao_thanh"
                    },
                    {
                        "name": "Xã Trung An",
                        "code": 28285,
                        "codename": "xa_trung_an",
                        "division_type": "xã",
                        "short_codename": "trung_an"
                    },
                    {
                        "name": "Xã Mỹ Phong",
                        "code": 28288,
                        "codename": "xa_my_phong",
                        "division_type": "xã",
                        "short_codename": "my_phong"
                    },
                    {
                        "name": "Xã Tân Mỹ Chánh",
                        "code": 28291,
                        "codename": "xa_tan_my_chanh",
                        "division_type": "xã",
                        "short_codename": "tan_my_chanh"
                    },
                    {
                        "name": "Xã Phước Thạnh",
                        "code": 28567,
                        "codename": "xa_phuoc_thanh",
                        "division_type": "xã",
                        "short_codename": "phuoc_thanh"
                    },
                    {
                        "name": "Xã Thới Sơn",
                        "code": 28591,
                        "codename": "xa_thoi_son",
                        "division_type": "xã",
                        "short_codename": "thoi_son"
                    }
                ]
            },
            {
                "name": "Thị xã Gò Công",
                "code": 816,
                "codename": "thi_xa_go_cong",
                "division_type": "thị xã",
                "short_codename": "go_cong",
                "wards": [
                    {
                        "name": "Phường 3",
                        "code": 28294,
                        "codename": "phuong_3",
                        "division_type": "phường",
                        "short_codename": "phuong_3"
                    },
                    {
                        "name": "Phường 2",
                        "code": 28297,
                        "codename": "phuong_2",
                        "division_type": "phường",
                        "short_codename": "phuong_2"
                    },
                    {
                        "name": "Phường 4",
                        "code": 28300,
                        "codename": "phuong_4",
                        "division_type": "phường",
                        "short_codename": "phuong_4"
                    },
                    {
                        "name": "Phường 1",
                        "code": 28303,
                        "codename": "phuong_1",
                        "division_type": "phường",
                        "short_codename": "phuong_1"
                    },
                    {
                        "name": "Phường 5",
                        "code": 28306,
                        "codename": "phuong_5",
                        "division_type": "phường",
                        "short_codename": "phuong_5"
                    },
                    {
                        "name": "Xã Long Hưng",
                        "code": 28309,
                        "codename": "xa_long_hung",
                        "division_type": "xã",
                        "short_codename": "long_hung"
                    },
                    {
                        "name": "Xã Long Thuận",
                        "code": 28312,
                        "codename": "xa_long_thuan",
                        "division_type": "xã",
                        "short_codename": "long_thuan"
                    },
                    {
                        "name": "Xã Long Chánh",
                        "code": 28315,
                        "codename": "xa_long_chanh",
                        "division_type": "xã",
                        "short_codename": "long_chanh"
                    },
                    {
                        "name": "Xã Long Hòa",
                        "code": 28318,
                        "codename": "xa_long_hoa",
                        "division_type": "xã",
                        "short_codename": "long_hoa"
                    },
                    {
                        "name": "Xã Bình Đông",
                        "code": 28708,
                        "codename": "xa_binh_dong",
                        "division_type": "xã",
                        "short_codename": "binh_dong"
                    },
                    {
                        "name": "Xã Bình Xuân",
                        "code": 28717,
                        "codename": "xa_binh_xuan",
                        "division_type": "xã",
                        "short_codename": "binh_xuan"
                    },
                    {
                        "name": "Xã Tân Trung",
                        "code": 28729,
                        "codename": "xa_tan_trung",
                        "division_type": "xã",
                        "short_codename": "tan_trung"
                    }
                ]
            },
            {
                "name": "Thị xã Cai Lậy",
                "code": 817,
                "codename": "thi_xa_cai_lay",
                "division_type": "thị xã",
                "short_codename": "cai_lay",
                "wards": [
                    {
                        "name": "Phường 1",
                        "code": 28435,
                        "codename": "phuong_1",
                        "division_type": "phường",
                        "short_codename": "phuong_1"
                    },
                    {
                        "name": "Phường 2",
                        "code": 28436,
                        "codename": "phuong_2",
                        "division_type": "phường",
                        "short_codename": "phuong_2"
                    },
                    {
                        "name": "Phường 3",
                        "code": 28437,
                        "codename": "phuong_3",
                        "division_type": "phường",
                        "short_codename": "phuong_3"
                    },
                    {
                        "name": "Phường 4",
                        "code": 28439,
                        "codename": "phuong_4",
                        "division_type": "phường",
                        "short_codename": "phuong_4"
                    },
                    {
                        "name": "Phường 5",
                        "code": 28440,
                        "codename": "phuong_5",
                        "division_type": "phường",
                        "short_codename": "phuong_5"
                    },
                    {
                        "name": "Xã Mỹ Phước Tây",
                        "code": 28447,
                        "codename": "xa_my_phuoc_tay",
                        "division_type": "xã",
                        "short_codename": "my_phuoc_tay"
                    },
                    {
                        "name": "Xã Mỹ Hạnh Đông",
                        "code": 28450,
                        "codename": "xa_my_hanh_dong",
                        "division_type": "xã",
                        "short_codename": "my_hanh_dong"
                    },
                    {
                        "name": "Xã Mỹ Hạnh Trung",
                        "code": 28453,
                        "codename": "xa_my_hanh_trung",
                        "division_type": "xã",
                        "short_codename": "my_hanh_trung"
                    },
                    {
                        "name": "Xã Tân Phú",
                        "code": 28459,
                        "codename": "xa_tan_phu",
                        "division_type": "xã",
                        "short_codename": "tan_phu"
                    },
                    {
                        "name": "Xã Tân Bình",
                        "code": 28462,
                        "codename": "xa_tan_binh",
                        "division_type": "xã",
                        "short_codename": "tan_binh"
                    },
                    {
                        "name": "Xã Tân Hội",
                        "code": 28468,
                        "codename": "xa_tan_hoi",
                        "division_type": "xã",
                        "short_codename": "tan_hoi"
                    },
                    {
                        "name": "Phường Nhị Mỹ",
                        "code": 28474,
                        "codename": "phuong_nhi_my",
                        "division_type": "phường",
                        "short_codename": "nhi_my"
                    },
                    {
                        "name": "Xã Nhị Quý",
                        "code": 28477,
                        "codename": "xa_nhi_quy",
                        "division_type": "xã",
                        "short_codename": "nhi_quy"
                    },
                    {
                        "name": "Xã Thanh Hòa",
                        "code": 28480,
                        "codename": "xa_thanh_hoa",
                        "division_type": "xã",
                        "short_codename": "thanh_hoa"
                    },
                    {
                        "name": "Xã Phú Quý",
                        "code": 28483,
                        "codename": "xa_phu_quy",
                        "division_type": "xã",
                        "short_codename": "phu_quy"
                    },
                    {
                        "name": "Xã Long Khánh",
                        "code": 28486,
                        "codename": "xa_long_khanh",
                        "division_type": "xã",
                        "short_codename": "long_khanh"
                    }
                ]
            },
            {
                "name": "Huyện Tân Phước",
                "code": 818,
                "codename": "huyen_tan_phuoc",
                "division_type": "huyện",
                "short_codename": "tan_phuoc",
                "wards": [
                    {
                        "name": "Thị trấn Mỹ Phước",
                        "code": 28321,
                        "codename": "thi_tran_my_phuoc",
                        "division_type": "thị trấn",
                        "short_codename": "my_phuoc"
                    },
                    {
                        "name": "Xã Tân Hòa Đông",
                        "code": 28324,
                        "codename": "xa_tan_hoa_dong",
                        "division_type": "xã",
                        "short_codename": "tan_hoa_dong"
                    },
                    {
                        "name": "Xã Thạnh Tân",
                        "code": 28327,
                        "codename": "xa_thanh_tan",
                        "division_type": "xã",
                        "short_codename": "thanh_tan"
                    },
                    {
                        "name": "Xã Thạnh Mỹ",
                        "code": 28330,
                        "codename": "xa_thanh_my",
                        "division_type": "xã",
                        "short_codename": "thanh_my"
                    },
                    {
                        "name": "Xã Thạnh Hoà",
                        "code": 28333,
                        "codename": "xa_thanh_hoa",
                        "division_type": "xã",
                        "short_codename": "thanh_hoa"
                    },
                    {
                        "name": "Xã Phú Mỹ",
                        "code": 28336,
                        "codename": "xa_phu_my",
                        "division_type": "xã",
                        "short_codename": "phu_my"
                    },
                    {
                        "name": "Xã Tân Hòa Thành",
                        "code": 28339,
                        "codename": "xa_tan_hoa_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_hoa_thanh"
                    },
                    {
                        "name": "Xã Hưng Thạnh",
                        "code": 28342,
                        "codename": "xa_hung_thanh",
                        "division_type": "xã",
                        "short_codename": "hung_thanh"
                    },
                    {
                        "name": "Xã Tân Lập 1",
                        "code": 28345,
                        "codename": "xa_tan_lap_1",
                        "division_type": "xã",
                        "short_codename": "tan_lap_1"
                    },
                    {
                        "name": "Xã Tân Hòa Tây",
                        "code": 28348,
                        "codename": "xa_tan_hoa_tay",
                        "division_type": "xã",
                        "short_codename": "tan_hoa_tay"
                    },
                    {
                        "name": "Xã Tân Lập 2",
                        "code": 28354,
                        "codename": "xa_tan_lap_2",
                        "division_type": "xã",
                        "short_codename": "tan_lap_2"
                    },
                    {
                        "name": "Xã Phước Lập",
                        "code": 28357,
                        "codename": "xa_phuoc_lap",
                        "division_type": "xã",
                        "short_codename": "phuoc_lap"
                    }
                ]
            },
            {
                "name": "Huyện Cái Bè",
                "code": 819,
                "codename": "huyen_cai_be",
                "division_type": "huyện",
                "short_codename": "cai_be",
                "wards": [
                    {
                        "name": "Thị trấn Cái Bè",
                        "code": 28360,
                        "codename": "thi_tran_cai_be",
                        "division_type": "thị trấn",
                        "short_codename": "cai_be"
                    },
                    {
                        "name": "Xã Hậu Mỹ Bắc B",
                        "code": 28363,
                        "codename": "xa_hau_my_bac_b",
                        "division_type": "xã",
                        "short_codename": "hau_my_bac_b"
                    },
                    {
                        "name": "Xã Hậu Mỹ Bắc A",
                        "code": 28366,
                        "codename": "xa_hau_my_bac_a",
                        "division_type": "xã",
                        "short_codename": "hau_my_bac_a"
                    },
                    {
                        "name": "Xã Mỹ Trung",
                        "code": 28369,
                        "codename": "xa_my_trung",
                        "division_type": "xã",
                        "short_codename": "my_trung"
                    },
                    {
                        "name": "Xã Hậu Mỹ Trinh",
                        "code": 28372,
                        "codename": "xa_hau_my_trinh",
                        "division_type": "xã",
                        "short_codename": "hau_my_trinh"
                    },
                    {
                        "name": "Xã Hậu Mỹ Phú",
                        "code": 28375,
                        "codename": "xa_hau_my_phu",
                        "division_type": "xã",
                        "short_codename": "hau_my_phu"
                    },
                    {
                        "name": "Xã Mỹ Tân",
                        "code": 28378,
                        "codename": "xa_my_tan",
                        "division_type": "xã",
                        "short_codename": "my_tan"
                    },
                    {
                        "name": "Xã Mỹ Lợi B",
                        "code": 28381,
                        "codename": "xa_my_loi_b",
                        "division_type": "xã",
                        "short_codename": "my_loi_b"
                    },
                    {
                        "name": "Xã Thiện Trung",
                        "code": 28384,
                        "codename": "xa_thien_trung",
                        "division_type": "xã",
                        "short_codename": "thien_trung"
                    },
                    {
                        "name": "Xã Mỹ Hội",
                        "code": 28387,
                        "codename": "xa_my_hoi",
                        "division_type": "xã",
                        "short_codename": "my_hoi"
                    },
                    {
                        "name": "Xã An Cư",
                        "code": 28390,
                        "codename": "xa_an_cu",
                        "division_type": "xã",
                        "short_codename": "an_cu"
                    },
                    {
                        "name": "Xã Hậu Thành",
                        "code": 28393,
                        "codename": "xa_hau_thanh",
                        "division_type": "xã",
                        "short_codename": "hau_thanh"
                    },
                    {
                        "name": "Xã Mỹ Lợi A",
                        "code": 28396,
                        "codename": "xa_my_loi_a",
                        "division_type": "xã",
                        "short_codename": "my_loi_a"
                    },
                    {
                        "name": "Xã Hòa Khánh",
                        "code": 28399,
                        "codename": "xa_hoa_khanh",
                        "division_type": "xã",
                        "short_codename": "hoa_khanh"
                    },
                    {
                        "name": "Xã Thiện Trí",
                        "code": 28402,
                        "codename": "xa_thien_tri",
                        "division_type": "xã",
                        "short_codename": "thien_tri"
                    },
                    {
                        "name": "Xã Mỹ Đức Đông",
                        "code": 28405,
                        "codename": "xa_my_duc_dong",
                        "division_type": "xã",
                        "short_codename": "my_duc_dong"
                    },
                    {
                        "name": "Xã Mỹ Đức Tây",
                        "code": 28408,
                        "codename": "xa_my_duc_tay",
                        "division_type": "xã",
                        "short_codename": "my_duc_tay"
                    },
                    {
                        "name": "Xã Đông Hòa Hiệp",
                        "code": 28411,
                        "codename": "xa_dong_hoa_hiep",
                        "division_type": "xã",
                        "short_codename": "dong_hoa_hiep"
                    },
                    {
                        "name": "Xã An Thái Đông",
                        "code": 28414,
                        "codename": "xa_an_thai_dong",
                        "division_type": "xã",
                        "short_codename": "an_thai_dong"
                    },
                    {
                        "name": "Xã Tân Hưng",
                        "code": 28417,
                        "codename": "xa_tan_hung",
                        "division_type": "xã",
                        "short_codename": "tan_hung"
                    },
                    {
                        "name": "Xã Mỹ Lương",
                        "code": 28420,
                        "codename": "xa_my_luong",
                        "division_type": "xã",
                        "short_codename": "my_luong"
                    },
                    {
                        "name": "Xã Tân Thanh",
                        "code": 28423,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Xã An Thái Trung",
                        "code": 28426,
                        "codename": "xa_an_thai_trung",
                        "division_type": "xã",
                        "short_codename": "an_thai_trung"
                    },
                    {
                        "name": "Xã An Hữu",
                        "code": 28429,
                        "codename": "xa_an_huu",
                        "division_type": "xã",
                        "short_codename": "an_huu"
                    },
                    {
                        "name": "Xã Hòa Hưng",
                        "code": 28432,
                        "codename": "xa_hoa_hung",
                        "division_type": "xã",
                        "short_codename": "hoa_hung"
                    }
                ]
            },
            {
                "name": "Huyện Cai Lậy",
                "code": 820,
                "codename": "huyen_cai_lay",
                "division_type": "huyện",
                "short_codename": "huyen_cai_lay",
                "wards": [
                    {
                        "name": "Xã Thạnh Lộc",
                        "code": 28438,
                        "codename": "xa_thanh_loc",
                        "division_type": "xã",
                        "short_codename": "thanh_loc"
                    },
                    {
                        "name": "Xã Mỹ Thành Bắc",
                        "code": 28441,
                        "codename": "xa_my_thanh_bac",
                        "division_type": "xã",
                        "short_codename": "my_thanh_bac"
                    },
                    {
                        "name": "Xã Phú Cường",
                        "code": 28444,
                        "codename": "xa_phu_cuong",
                        "division_type": "xã",
                        "short_codename": "phu_cuong"
                    },
                    {
                        "name": "Xã Mỹ Thành Nam",
                        "code": 28456,
                        "codename": "xa_my_thanh_nam",
                        "division_type": "xã",
                        "short_codename": "my_thanh_nam"
                    },
                    {
                        "name": "Xã Phú Nhuận",
                        "code": 28465,
                        "codename": "xa_phu_nhuan",
                        "division_type": "xã",
                        "short_codename": "phu_nhuan"
                    },
                    {
                        "name": "Xã Bình Phú",
                        "code": 28471,
                        "codename": "xa_binh_phu",
                        "division_type": "xã",
                        "short_codename": "binh_phu"
                    },
                    {
                        "name": "Xã Cẩm Sơn",
                        "code": 28489,
                        "codename": "xa_cam_son",
                        "division_type": "xã",
                        "short_codename": "cam_son"
                    },
                    {
                        "name": "Xã Phú An",
                        "code": 28492,
                        "codename": "xa_phu_an",
                        "division_type": "xã",
                        "short_codename": "phu_an"
                    },
                    {
                        "name": "Xã Mỹ Long",
                        "code": 28495,
                        "codename": "xa_my_long",
                        "division_type": "xã",
                        "short_codename": "my_long"
                    },
                    {
                        "name": "Xã Long Tiên",
                        "code": 28498,
                        "codename": "xa_long_tien",
                        "division_type": "xã",
                        "short_codename": "long_tien"
                    },
                    {
                        "name": "Xã Hiệp Đức",
                        "code": 28501,
                        "codename": "xa_hiep_duc",
                        "division_type": "xã",
                        "short_codename": "hiep_duc"
                    },
                    {
                        "name": "Xã Long Trung",
                        "code": 28504,
                        "codename": "xa_long_trung",
                        "division_type": "xã",
                        "short_codename": "long_trung"
                    },
                    {
                        "name": "Xã Hội Xuân",
                        "code": 28507,
                        "codename": "xa_hoi_xuan",
                        "division_type": "xã",
                        "short_codename": "hoi_xuan"
                    },
                    {
                        "name": "Xã Tân Phong",
                        "code": 28510,
                        "codename": "xa_tan_phong",
                        "division_type": "xã",
                        "short_codename": "tan_phong"
                    },
                    {
                        "name": "Xã Tam Bình",
                        "code": 28513,
                        "codename": "xa_tam_binh",
                        "division_type": "xã",
                        "short_codename": "tam_binh"
                    },
                    {
                        "name": "Xã Ngũ Hiệp",
                        "code": 28516,
                        "codename": "xa_ngu_hiep",
                        "division_type": "xã",
                        "short_codename": "ngu_hiep"
                    }
                ]
            },
            {
                "name": "Huyện Châu Thành",
                "code": 821,
                "codename": "huyen_chau_thanh",
                "division_type": "huyện",
                "short_codename": "chau_thanh",
                "wards": [
                    {
                        "name": "Thị trấn Tân Hiệp",
                        "code": 28519,
                        "codename": "thi_tran_tan_hiep",
                        "division_type": "thị trấn",
                        "short_codename": "tan_hiep"
                    },
                    {
                        "name": "Xã Tân Hội Đông",
                        "code": 28522,
                        "codename": "xa_tan_hoi_dong",
                        "division_type": "xã",
                        "short_codename": "tan_hoi_dong"
                    },
                    {
                        "name": "Xã Tân Hương",
                        "code": 28525,
                        "codename": "xa_tan_huong",
                        "division_type": "xã",
                        "short_codename": "tan_huong"
                    },
                    {
                        "name": "Xã Tân Lý Đông",
                        "code": 28528,
                        "codename": "xa_tan_ly_dong",
                        "division_type": "xã",
                        "short_codename": "tan_ly_dong"
                    },
                    {
                        "name": "Xã Tân Lý Tây",
                        "code": 28531,
                        "codename": "xa_tan_ly_tay",
                        "division_type": "xã",
                        "short_codename": "tan_ly_tay"
                    },
                    {
                        "name": "Xã Thân Cửu Nghĩa",
                        "code": 28534,
                        "codename": "xa_than_cuu_nghia",
                        "division_type": "xã",
                        "short_codename": "than_cuu_nghia"
                    },
                    {
                        "name": "Xã Tam Hiệp",
                        "code": 28537,
                        "codename": "xa_tam_hiep",
                        "division_type": "xã",
                        "short_codename": "tam_hiep"
                    },
                    {
                        "name": "Xã Điềm Hy",
                        "code": 28540,
                        "codename": "xa_diem_hy",
                        "division_type": "xã",
                        "short_codename": "diem_hy"
                    },
                    {
                        "name": "Xã Nhị Bình",
                        "code": 28543,
                        "codename": "xa_nhi_binh",
                        "division_type": "xã",
                        "short_codename": "nhi_binh"
                    },
                    {
                        "name": "Xã Dưỡng Điềm",
                        "code": 28546,
                        "codename": "xa_duong_diem",
                        "division_type": "xã",
                        "short_codename": "duong_diem"
                    },
                    {
                        "name": "Xã Đông Hòa",
                        "code": 28549,
                        "codename": "xa_dong_hoa",
                        "division_type": "xã",
                        "short_codename": "dong_hoa"
                    },
                    {
                        "name": "Xã Long Định",
                        "code": 28552,
                        "codename": "xa_long_dinh",
                        "division_type": "xã",
                        "short_codename": "long_dinh"
                    },
                    {
                        "name": "Xã Hữu Đạo",
                        "code": 28555,
                        "codename": "xa_huu_dao",
                        "division_type": "xã",
                        "short_codename": "huu_dao"
                    },
                    {
                        "name": "Xã Long An",
                        "code": 28558,
                        "codename": "xa_long_an",
                        "division_type": "xã",
                        "short_codename": "long_an"
                    },
                    {
                        "name": "Xã Long Hưng",
                        "code": 28561,
                        "codename": "xa_long_hung",
                        "division_type": "xã",
                        "short_codename": "long_hung"
                    },
                    {
                        "name": "Xã Bình Trưng",
                        "code": 28564,
                        "codename": "xa_binh_trung",
                        "division_type": "xã",
                        "short_codename": "binh_trung"
                    },
                    {
                        "name": "Xã Thạnh Phú",
                        "code": 28570,
                        "codename": "xa_thanh_phu",
                        "division_type": "xã",
                        "short_codename": "thanh_phu"
                    },
                    {
                        "name": "Xã Bàn Long",
                        "code": 28573,
                        "codename": "xa_ban_long",
                        "division_type": "xã",
                        "short_codename": "ban_long"
                    },
                    {
                        "name": "Xã Vĩnh Kim",
                        "code": 28576,
                        "codename": "xa_vinh_kim",
                        "division_type": "xã",
                        "short_codename": "vinh_kim"
                    },
                    {
                        "name": "Xã Bình Đức",
                        "code": 28579,
                        "codename": "xa_binh_duc",
                        "division_type": "xã",
                        "short_codename": "binh_duc"
                    },
                    {
                        "name": "Xã Song Thuận",
                        "code": 28582,
                        "codename": "xa_song_thuan",
                        "division_type": "xã",
                        "short_codename": "song_thuan"
                    },
                    {
                        "name": "Xã Kim Sơn",
                        "code": 28585,
                        "codename": "xa_kim_son",
                        "division_type": "xã",
                        "short_codename": "kim_son"
                    },
                    {
                        "name": "Xã Phú Phong",
                        "code": 28588,
                        "codename": "xa_phu_phong",
                        "division_type": "xã",
                        "short_codename": "phu_phong"
                    }
                ]
            },
            {
                "name": "Huyện Chợ Gạo",
                "code": 822,
                "codename": "huyen_cho_gao",
                "division_type": "huyện",
                "short_codename": "cho_gao",
                "wards": [
                    {
                        "name": "Thị trấn Chợ Gạo",
                        "code": 28594,
                        "codename": "thi_tran_cho_gao",
                        "division_type": "thị trấn",
                        "short_codename": "cho_gao"
                    },
                    {
                        "name": "Xã Trung Hòa",
                        "code": 28597,
                        "codename": "xa_trung_hoa",
                        "division_type": "xã",
                        "short_codename": "trung_hoa"
                    },
                    {
                        "name": "Xã Hòa Tịnh",
                        "code": 28600,
                        "codename": "xa_hoa_tinh",
                        "division_type": "xã",
                        "short_codename": "hoa_tinh"
                    },
                    {
                        "name": "Xã Mỹ Tịnh An",
                        "code": 28603,
                        "codename": "xa_my_tinh_an",
                        "division_type": "xã",
                        "short_codename": "my_tinh_an"
                    },
                    {
                        "name": "Xã Tân Bình Thạnh",
                        "code": 28606,
                        "codename": "xa_tan_binh_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_binh_thanh"
                    },
                    {
                        "name": "Xã Phú Kiết",
                        "code": 28609,
                        "codename": "xa_phu_kiet",
                        "division_type": "xã",
                        "short_codename": "phu_kiet"
                    },
                    {
                        "name": "Xã Lương Hòa Lạc",
                        "code": 28612,
                        "codename": "xa_luong_hoa_lac",
                        "division_type": "xã",
                        "short_codename": "luong_hoa_lac"
                    },
                    {
                        "name": "Xã Thanh Bình",
                        "code": 28615,
                        "codename": "xa_thanh_binh",
                        "division_type": "xã",
                        "short_codename": "thanh_binh"
                    },
                    {
                        "name": "Xã Quơn Long",
                        "code": 28618,
                        "codename": "xa_quon_long",
                        "division_type": "xã",
                        "short_codename": "quon_long"
                    },
                    {
                        "name": "Xã Bình Phục Nhứt",
                        "code": 28621,
                        "codename": "xa_binh_phuc_nhut",
                        "division_type": "xã",
                        "short_codename": "binh_phuc_nhut"
                    },
                    {
                        "name": "Xã Đăng Hưng Phước",
                        "code": 28624,
                        "codename": "xa_dang_hung_phuoc",
                        "division_type": "xã",
                        "short_codename": "dang_hung_phuoc"
                    },
                    {
                        "name": "Xã Tân Thuận Bình",
                        "code": 28627,
                        "codename": "xa_tan_thuan_binh",
                        "division_type": "xã",
                        "short_codename": "tan_thuan_binh"
                    },
                    {
                        "name": "Xã Song Bình",
                        "code": 28630,
                        "codename": "xa_song_binh",
                        "division_type": "xã",
                        "short_codename": "song_binh"
                    },
                    {
                        "name": "Xã Bình Phan",
                        "code": 28633,
                        "codename": "xa_binh_phan",
                        "division_type": "xã",
                        "short_codename": "binh_phan"
                    },
                    {
                        "name": "Xã Long Bình Điền",
                        "code": 28636,
                        "codename": "xa_long_binh_dien",
                        "division_type": "xã",
                        "short_codename": "long_binh_dien"
                    },
                    {
                        "name": "Xã An Thạnh Thủy",
                        "code": 28639,
                        "codename": "xa_an_thanh_thuy",
                        "division_type": "xã",
                        "short_codename": "an_thanh_thuy"
                    },
                    {
                        "name": "Xã Xuân Đông",
                        "code": 28642,
                        "codename": "xa_xuan_dong",
                        "division_type": "xã",
                        "short_codename": "xuan_dong"
                    },
                    {
                        "name": "Xã Hòa Định",
                        "code": 28645,
                        "codename": "xa_hoa_dinh",
                        "division_type": "xã",
                        "short_codename": "hoa_dinh"
                    },
                    {
                        "name": "Xã Bình Ninh",
                        "code": 28648,
                        "codename": "xa_binh_ninh",
                        "division_type": "xã",
                        "short_codename": "binh_ninh"
                    }
                ]
            },
            {
                "name": "Huyện Gò Công Tây",
                "code": 823,
                "codename": "huyen_go_cong_tay",
                "division_type": "huyện",
                "short_codename": "go_cong_tay",
                "wards": [
                    {
                        "name": "Thị trấn Vĩnh Bình",
                        "code": 28651,
                        "codename": "thi_tran_vinh_binh",
                        "division_type": "thị trấn",
                        "short_codename": "vinh_binh"
                    },
                    {
                        "name": "Xã Đồng Sơn",
                        "code": 28654,
                        "codename": "xa_dong_son",
                        "division_type": "xã",
                        "short_codename": "dong_son"
                    },
                    {
                        "name": "Xã Bình Phú",
                        "code": 28657,
                        "codename": "xa_binh_phu",
                        "division_type": "xã",
                        "short_codename": "binh_phu"
                    },
                    {
                        "name": "Xã Đồng Thạnh",
                        "code": 28660,
                        "codename": "xa_dong_thanh",
                        "division_type": "xã",
                        "short_codename": "dong_thanh"
                    },
                    {
                        "name": "Xã Thành Công",
                        "code": 28663,
                        "codename": "xa_thanh_cong",
                        "division_type": "xã",
                        "short_codename": "thanh_cong"
                    },
                    {
                        "name": "Xã Bình Nhì",
                        "code": 28666,
                        "codename": "xa_binh_nhi",
                        "division_type": "xã",
                        "short_codename": "binh_nhi"
                    },
                    {
                        "name": "Xã Yên Luông",
                        "code": 28669,
                        "codename": "xa_yen_luong",
                        "division_type": "xã",
                        "short_codename": "yen_luong"
                    },
                    {
                        "name": "Xã Thạnh Trị",
                        "code": 28672,
                        "codename": "xa_thanh_tri",
                        "division_type": "xã",
                        "short_codename": "thanh_tri"
                    },
                    {
                        "name": "Xã Thạnh Nhựt",
                        "code": 28675,
                        "codename": "xa_thanh_nhut",
                        "division_type": "xã",
                        "short_codename": "thanh_nhut"
                    },
                    {
                        "name": "Xã Long Vĩnh",
                        "code": 28678,
                        "codename": "xa_long_vinh",
                        "division_type": "xã",
                        "short_codename": "long_vinh"
                    },
                    {
                        "name": "Xã Bình Tân",
                        "code": 28681,
                        "codename": "xa_binh_tan",
                        "division_type": "xã",
                        "short_codename": "binh_tan"
                    },
                    {
                        "name": "Xã Vĩnh Hựu",
                        "code": 28684,
                        "codename": "xa_vinh_huu",
                        "division_type": "xã",
                        "short_codename": "vinh_huu"
                    },
                    {
                        "name": "Xã Long Bình",
                        "code": 28687,
                        "codename": "xa_long_binh",
                        "division_type": "xã",
                        "short_codename": "long_binh"
                    }
                ]
            },
            {
                "name": "Huyện Gò Công Đông",
                "code": 824,
                "codename": "huyen_go_cong_dong",
                "division_type": "huyện",
                "short_codename": "go_cong_dong",
                "wards": [
                    {
                        "name": "Thị trấn Tân Hòa",
                        "code": 28702,
                        "codename": "thi_tran_tan_hoa",
                        "division_type": "thị trấn",
                        "short_codename": "tan_hoa"
                    },
                    {
                        "name": "Xã Tăng Hoà",
                        "code": 28705,
                        "codename": "xa_tang_hoa",
                        "division_type": "xã",
                        "short_codename": "tang_hoa"
                    },
                    {
                        "name": "Xã Tân Phước",
                        "code": 28711,
                        "codename": "xa_tan_phuoc",
                        "division_type": "xã",
                        "short_codename": "tan_phuoc"
                    },
                    {
                        "name": "Xã Gia Thuận",
                        "code": 28714,
                        "codename": "xa_gia_thuan",
                        "division_type": "xã",
                        "short_codename": "gia_thuan"
                    },
                    {
                        "name": "Thị trấn Vàm Láng",
                        "code": 28720,
                        "codename": "thi_tran_vam_lang",
                        "division_type": "thị trấn",
                        "short_codename": "vam_lang"
                    },
                    {
                        "name": "Xã Tân Tây",
                        "code": 28723,
                        "codename": "xa_tan_tay",
                        "division_type": "xã",
                        "short_codename": "tan_tay"
                    },
                    {
                        "name": "Xã Kiểng Phước",
                        "code": 28726,
                        "codename": "xa_kieng_phuoc",
                        "division_type": "xã",
                        "short_codename": "kieng_phuoc"
                    },
                    {
                        "name": "Xã Tân Đông",
                        "code": 28732,
                        "codename": "xa_tan_dong",
                        "division_type": "xã",
                        "short_codename": "tan_dong"
                    },
                    {
                        "name": "Xã Bình Ân",
                        "code": 28735,
                        "codename": "xa_binh_an",
                        "division_type": "xã",
                        "short_codename": "binh_an"
                    },
                    {
                        "name": "Xã Tân Điền",
                        "code": 28738,
                        "codename": "xa_tan_dien",
                        "division_type": "xã",
                        "short_codename": "tan_dien"
                    },
                    {
                        "name": "Xã Bình Nghị",
                        "code": 28741,
                        "codename": "xa_binh_nghi",
                        "division_type": "xã",
                        "short_codename": "binh_nghi"
                    },
                    {
                        "name": "Xã Phước Trung",
                        "code": 28744,
                        "codename": "xa_phuoc_trung",
                        "division_type": "xã",
                        "short_codename": "phuoc_trung"
                    },
                    {
                        "name": "Xã Tân Thành",
                        "code": 28747,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    }
                ]
            },
            {
                "name": "Huyện Tân Phú Đông",
                "code": 825,
                "codename": "huyen_tan_phu_dong",
                "division_type": "huyện",
                "short_codename": "tan_phu_dong",
                "wards": [
                    {
                        "name": "Xã Tân Thới",
                        "code": 28690,
                        "codename": "xa_tan_thoi",
                        "division_type": "xã",
                        "short_codename": "tan_thoi"
                    },
                    {
                        "name": "Xã Tân Phú",
                        "code": 28693,
                        "codename": "xa_tan_phu",
                        "division_type": "xã",
                        "short_codename": "tan_phu"
                    },
                    {
                        "name": "Xã Phú Thạnh",
                        "code": 28696,
                        "codename": "xa_phu_thanh",
                        "division_type": "xã",
                        "short_codename": "phu_thanh"
                    },
                    {
                        "name": "Xã Tân Thạnh",
                        "code": 28699,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Xã Phú Đông",
                        "code": 28750,
                        "codename": "xa_phu_dong",
                        "division_type": "xã",
                        "short_codename": "phu_dong"
                    },
                    {
                        "name": "Xã Phú Tân",
                        "code": 28753,
                        "codename": "xa_phu_tan",
                        "division_type": "xã",
                        "short_codename": "phu_tan"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Bến Tre",
        "code": 83,
        "codename": "tinh_ben_tre",
        "division_type": "tỉnh",
        "phone_code": 275,
        "districts": [
            {
                "name": "Thành phố Bến Tre",
                "code": 829,
                "codename": "thanh_pho_ben_tre",
                "division_type": "thành phố",
                "short_codename": "ben_tre",
                "wards": [
                    {
                        "name": "Phường Phú Khương",
                        "code": 28756,
                        "codename": "phuong_phu_khuong",
                        "division_type": "phường",
                        "short_codename": "phu_khuong"
                    },
                    {
                        "name": "Phường Phú Tân",
                        "code": 28757,
                        "codename": "phuong_phu_tan",
                        "division_type": "phường",
                        "short_codename": "phu_tan"
                    },
                    {
                        "name": "Phường 8",
                        "code": 28759,
                        "codename": "phuong_8",
                        "division_type": "phường",
                        "short_codename": "phuong_8"
                    },
                    {
                        "name": "Phường 6",
                        "code": 28762,
                        "codename": "phuong_6",
                        "division_type": "phường",
                        "short_codename": "phuong_6"
                    },
                    {
                        "name": "Phường 4",
                        "code": 28765,
                        "codename": "phuong_4",
                        "division_type": "phường",
                        "short_codename": "phuong_4"
                    },
                    {
                        "name": "Phường 5",
                        "code": 28768,
                        "codename": "phuong_5",
                        "division_type": "phường",
                        "short_codename": "phuong_5"
                    },
                    {
                        "name": "Phường An Hội",
                        "code": 28777,
                        "codename": "phuong_an_hoi",
                        "division_type": "phường",
                        "short_codename": "an_hoi"
                    },
                    {
                        "name": "Phường 7",
                        "code": 28780,
                        "codename": "phuong_7",
                        "division_type": "phường",
                        "short_codename": "phuong_7"
                    },
                    {
                        "name": "Xã Sơn Đông",
                        "code": 28783,
                        "codename": "xa_son_dong",
                        "division_type": "xã",
                        "short_codename": "son_dong"
                    },
                    {
                        "name": "Xã Phú Hưng",
                        "code": 28786,
                        "codename": "xa_phu_hung",
                        "division_type": "xã",
                        "short_codename": "phu_hung"
                    },
                    {
                        "name": "Xã Bình Phú",
                        "code": 28789,
                        "codename": "xa_binh_phu",
                        "division_type": "xã",
                        "short_codename": "binh_phu"
                    },
                    {
                        "name": "Xã Mỹ Thạnh An",
                        "code": 28792,
                        "codename": "xa_my_thanh_an",
                        "division_type": "xã",
                        "short_codename": "my_thanh_an"
                    },
                    {
                        "name": "Xã Nhơn Thạnh",
                        "code": 28795,
                        "codename": "xa_nhon_thanh",
                        "division_type": "xã",
                        "short_codename": "nhon_thanh"
                    },
                    {
                        "name": "Xã Phú Nhuận",
                        "code": 28798,
                        "codename": "xa_phu_nhuan",
                        "division_type": "xã",
                        "short_codename": "phu_nhuan"
                    }
                ]
            },
            {
                "name": "Huyện Châu Thành",
                "code": 831,
                "codename": "huyen_chau_thanh",
                "division_type": "huyện",
                "short_codename": "chau_thanh",
                "wards": [
                    {
                        "name": "Thị trấn Châu Thành",
                        "code": 28801,
                        "codename": "thi_tran_chau_thanh",
                        "division_type": "thị trấn",
                        "short_codename": "chau_thanh"
                    },
                    {
                        "name": "Xã Tân Thạch",
                        "code": 28804,
                        "codename": "xa_tan_thach",
                        "division_type": "xã",
                        "short_codename": "tan_thach"
                    },
                    {
                        "name": "Xã Qưới Sơn",
                        "code": 28807,
                        "codename": "xa_quoi_son",
                        "division_type": "xã",
                        "short_codename": "quoi_son"
                    },
                    {
                        "name": "Xã An Khánh",
                        "code": 28810,
                        "codename": "xa_an_khanh",
                        "division_type": "xã",
                        "short_codename": "an_khanh"
                    },
                    {
                        "name": "Xã Giao Long",
                        "code": 28813,
                        "codename": "xa_giao_long",
                        "division_type": "xã",
                        "short_codename": "giao_long"
                    },
                    {
                        "name": "Xã Phú Túc",
                        "code": 28819,
                        "codename": "xa_phu_tuc",
                        "division_type": "xã",
                        "short_codename": "phu_tuc"
                    },
                    {
                        "name": "Xã Phú Đức",
                        "code": 28822,
                        "codename": "xa_phu_duc",
                        "division_type": "xã",
                        "short_codename": "phu_duc"
                    },
                    {
                        "name": "Xã Phú An Hòa",
                        "code": 28825,
                        "codename": "xa_phu_an_hoa",
                        "division_type": "xã",
                        "short_codename": "phu_an_hoa"
                    },
                    {
                        "name": "Xã An Phước",
                        "code": 28828,
                        "codename": "xa_an_phuoc",
                        "division_type": "xã",
                        "short_codename": "an_phuoc"
                    },
                    {
                        "name": "Xã Tam Phước",
                        "code": 28831,
                        "codename": "xa_tam_phuoc",
                        "division_type": "xã",
                        "short_codename": "tam_phuoc"
                    },
                    {
                        "name": "Xã Thành Triệu",
                        "code": 28834,
                        "codename": "xa_thanh_trieu",
                        "division_type": "xã",
                        "short_codename": "thanh_trieu"
                    },
                    {
                        "name": "Xã Tường Đa",
                        "code": 28837,
                        "codename": "xa_tuong_da",
                        "division_type": "xã",
                        "short_codename": "tuong_da"
                    },
                    {
                        "name": "Xã Tân Phú",
                        "code": 28840,
                        "codename": "xa_tan_phu",
                        "division_type": "xã",
                        "short_codename": "tan_phu"
                    },
                    {
                        "name": "Xã Quới Thành",
                        "code": 28843,
                        "codename": "xa_quoi_thanh",
                        "division_type": "xã",
                        "short_codename": "quoi_thanh"
                    },
                    {
                        "name": "Xã Phước Thạnh",
                        "code": 28846,
                        "codename": "xa_phuoc_thanh",
                        "division_type": "xã",
                        "short_codename": "phuoc_thanh"
                    },
                    {
                        "name": "Xã An Hóa",
                        "code": 28849,
                        "codename": "xa_an_hoa",
                        "division_type": "xã",
                        "short_codename": "an_hoa"
                    },
                    {
                        "name": "Xã Tiên Long",
                        "code": 28852,
                        "codename": "xa_tien_long",
                        "division_type": "xã",
                        "short_codename": "tien_long"
                    },
                    {
                        "name": "Xã An Hiệp",
                        "code": 28855,
                        "codename": "xa_an_hiep",
                        "division_type": "xã",
                        "short_codename": "an_hiep"
                    },
                    {
                        "name": "Xã Hữu Định",
                        "code": 28858,
                        "codename": "xa_huu_dinh",
                        "division_type": "xã",
                        "short_codename": "huu_dinh"
                    },
                    {
                        "name": "Xã Tiên Thủy",
                        "code": 28861,
                        "codename": "xa_tien_thuy",
                        "division_type": "xã",
                        "short_codename": "tien_thuy"
                    },
                    {
                        "name": "Xã Sơn Hòa",
                        "code": 28864,
                        "codename": "xa_son_hoa",
                        "division_type": "xã",
                        "short_codename": "son_hoa"
                    }
                ]
            },
            {
                "name": "Huyện Chợ Lách",
                "code": 832,
                "codename": "huyen_cho_lach",
                "division_type": "huyện",
                "short_codename": "cho_lach",
                "wards": [
                    {
                        "name": "Thị trấn Chợ Lách",
                        "code": 28870,
                        "codename": "thi_tran_cho_lach",
                        "division_type": "thị trấn",
                        "short_codename": "cho_lach"
                    },
                    {
                        "name": "Xã Phú Phụng",
                        "code": 28873,
                        "codename": "xa_phu_phung",
                        "division_type": "xã",
                        "short_codename": "phu_phung"
                    },
                    {
                        "name": "Xã Sơn Định",
                        "code": 28876,
                        "codename": "xa_son_dinh",
                        "division_type": "xã",
                        "short_codename": "son_dinh"
                    },
                    {
                        "name": "Xã Vĩnh Bình",
                        "code": 28879,
                        "codename": "xa_vinh_binh",
                        "division_type": "xã",
                        "short_codename": "vinh_binh"
                    },
                    {
                        "name": "Xã Hòa Nghĩa",
                        "code": 28882,
                        "codename": "xa_hoa_nghia",
                        "division_type": "xã",
                        "short_codename": "hoa_nghia"
                    },
                    {
                        "name": "Xã Long Thới",
                        "code": 28885,
                        "codename": "xa_long_thoi",
                        "division_type": "xã",
                        "short_codename": "long_thoi"
                    },
                    {
                        "name": "Xã Phú Sơn",
                        "code": 28888,
                        "codename": "xa_phu_son",
                        "division_type": "xã",
                        "short_codename": "phu_son"
                    },
                    {
                        "name": "Xã Tân Thiềng",
                        "code": 28891,
                        "codename": "xa_tan_thieng",
                        "division_type": "xã",
                        "short_codename": "tan_thieng"
                    },
                    {
                        "name": "Xã Vĩnh Thành",
                        "code": 28894,
                        "codename": "xa_vinh_thanh",
                        "division_type": "xã",
                        "short_codename": "vinh_thanh"
                    },
                    {
                        "name": "Xã Vĩnh Hòa",
                        "code": 28897,
                        "codename": "xa_vinh_hoa",
                        "division_type": "xã",
                        "short_codename": "vinh_hoa"
                    },
                    {
                        "name": "Xã Hưng Khánh Trung B",
                        "code": 28900,
                        "codename": "xa_hung_khanh_trung_b",
                        "division_type": "xã",
                        "short_codename": "hung_khanh_trung_b"
                    }
                ]
            },
            {
                "name": "Huyện Mỏ Cày Nam",
                "code": 833,
                "codename": "huyen_mo_cay_nam",
                "division_type": "huyện",
                "short_codename": "mo_cay_nam",
                "wards": [
                    {
                        "name": "Thị trấn Mỏ Cày",
                        "code": 28903,
                        "codename": "thi_tran_mo_cay",
                        "division_type": "thị trấn",
                        "short_codename": "mo_cay"
                    },
                    {
                        "name": "Xã Định Thủy",
                        "code": 28930,
                        "codename": "xa_dinh_thuy",
                        "division_type": "xã",
                        "short_codename": "dinh_thuy"
                    },
                    {
                        "name": "Xã Đa Phước Hội",
                        "code": 28939,
                        "codename": "xa_da_phuoc_hoi",
                        "division_type": "xã",
                        "short_codename": "da_phuoc_hoi"
                    },
                    {
                        "name": "Xã Tân Hội",
                        "code": 28940,
                        "codename": "xa_tan_hoi",
                        "division_type": "xã",
                        "short_codename": "tan_hoi"
                    },
                    {
                        "name": "Xã Phước Hiệp",
                        "code": 28942,
                        "codename": "xa_phuoc_hiep",
                        "division_type": "xã",
                        "short_codename": "phuoc_hiep"
                    },
                    {
                        "name": "Xã Bình Khánh",
                        "code": 28945,
                        "codename": "xa_binh_khanh",
                        "division_type": "xã",
                        "short_codename": "binh_khanh"
                    },
                    {
                        "name": "Xã An Thạnh",
                        "code": 28951,
                        "codename": "xa_an_thanh",
                        "division_type": "xã",
                        "short_codename": "an_thanh"
                    },
                    {
                        "name": "Xã An Định",
                        "code": 28957,
                        "codename": "xa_an_dinh",
                        "division_type": "xã",
                        "short_codename": "an_dinh"
                    },
                    {
                        "name": "Xã Thành Thới B",
                        "code": 28960,
                        "codename": "xa_thanh_thoi_b",
                        "division_type": "xã",
                        "short_codename": "thanh_thoi_b"
                    },
                    {
                        "name": "Xã Tân Trung",
                        "code": 28963,
                        "codename": "xa_tan_trung",
                        "division_type": "xã",
                        "short_codename": "tan_trung"
                    },
                    {
                        "name": "Xã An Thới",
                        "code": 28966,
                        "codename": "xa_an_thoi",
                        "division_type": "xã",
                        "short_codename": "an_thoi"
                    },
                    {
                        "name": "Xã Thành Thới A",
                        "code": 28969,
                        "codename": "xa_thanh_thoi_a",
                        "division_type": "xã",
                        "short_codename": "thanh_thoi_a"
                    },
                    {
                        "name": "Xã Minh Đức",
                        "code": 28972,
                        "codename": "xa_minh_duc",
                        "division_type": "xã",
                        "short_codename": "minh_duc"
                    },
                    {
                        "name": "Xã Ngãi Đăng",
                        "code": 28975,
                        "codename": "xa_ngai_dang",
                        "division_type": "xã",
                        "short_codename": "ngai_dang"
                    },
                    {
                        "name": "Xã Cẩm Sơn",
                        "code": 28978,
                        "codename": "xa_cam_son",
                        "division_type": "xã",
                        "short_codename": "cam_son"
                    },
                    {
                        "name": "Xã Hương Mỹ",
                        "code": 28981,
                        "codename": "xa_huong_my",
                        "division_type": "xã",
                        "short_codename": "huong_my"
                    }
                ]
            },
            {
                "name": "Huyện Giồng Trôm",
                "code": 834,
                "codename": "huyen_giong_trom",
                "division_type": "huyện",
                "short_codename": "giong_trom",
                "wards": [
                    {
                        "name": "Thị trấn Giồng Trôm",
                        "code": 28984,
                        "codename": "thi_tran_giong_trom",
                        "division_type": "thị trấn",
                        "short_codename": "giong_trom"
                    },
                    {
                        "name": "Xã Phong Nẫm",
                        "code": 28987,
                        "codename": "xa_phong_nam",
                        "division_type": "xã",
                        "short_codename": "phong_nam"
                    },
                    {
                        "name": "Xã Mỹ Thạnh",
                        "code": 28993,
                        "codename": "xa_my_thanh",
                        "division_type": "xã",
                        "short_codename": "my_thanh"
                    },
                    {
                        "name": "Xã Châu Hòa",
                        "code": 28996,
                        "codename": "xa_chau_hoa",
                        "division_type": "xã",
                        "short_codename": "chau_hoa"
                    },
                    {
                        "name": "Xã Lương Hòa",
                        "code": 28999,
                        "codename": "xa_luong_hoa",
                        "division_type": "xã",
                        "short_codename": "luong_hoa"
                    },
                    {
                        "name": "Xã Lương Quới",
                        "code": 29002,
                        "codename": "xa_luong_quoi",
                        "division_type": "xã",
                        "short_codename": "luong_quoi"
                    },
                    {
                        "name": "Xã Lương Phú",
                        "code": 29005,
                        "codename": "xa_luong_phu",
                        "division_type": "xã",
                        "short_codename": "luong_phu"
                    },
                    {
                        "name": "Xã Châu Bình",
                        "code": 29008,
                        "codename": "xa_chau_binh",
                        "division_type": "xã",
                        "short_codename": "chau_binh"
                    },
                    {
                        "name": "Xã Thuận Điền",
                        "code": 29011,
                        "codename": "xa_thuan_dien",
                        "division_type": "xã",
                        "short_codename": "thuan_dien"
                    },
                    {
                        "name": "Xã Sơn Phú",
                        "code": 29014,
                        "codename": "xa_son_phu",
                        "division_type": "xã",
                        "short_codename": "son_phu"
                    },
                    {
                        "name": "Xã Bình Hoà",
                        "code": 29017,
                        "codename": "xa_binh_hoa",
                        "division_type": "xã",
                        "short_codename": "binh_hoa"
                    },
                    {
                        "name": "Xã Phước Long",
                        "code": 29020,
                        "codename": "xa_phuoc_long",
                        "division_type": "xã",
                        "short_codename": "phuoc_long"
                    },
                    {
                        "name": "Xã Hưng Phong",
                        "code": 29023,
                        "codename": "xa_hung_phong",
                        "division_type": "xã",
                        "short_codename": "hung_phong"
                    },
                    {
                        "name": "Xã Long Mỹ",
                        "code": 29026,
                        "codename": "xa_long_my",
                        "division_type": "xã",
                        "short_codename": "long_my"
                    },
                    {
                        "name": "Xã Tân Hào",
                        "code": 29029,
                        "codename": "xa_tan_hao",
                        "division_type": "xã",
                        "short_codename": "tan_hao"
                    },
                    {
                        "name": "Xã Bình Thành",
                        "code": 29032,
                        "codename": "xa_binh_thanh",
                        "division_type": "xã",
                        "short_codename": "binh_thanh"
                    },
                    {
                        "name": "Xã Tân Thanh",
                        "code": 29035,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Xã Tân Lợi Thạnh",
                        "code": 29038,
                        "codename": "xa_tan_loi_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_loi_thanh"
                    },
                    {
                        "name": "Xã Thạnh Phú Đông",
                        "code": 29041,
                        "codename": "xa_thanh_phu_dong",
                        "division_type": "xã",
                        "short_codename": "thanh_phu_dong"
                    },
                    {
                        "name": "Xã Hưng Nhượng",
                        "code": 29044,
                        "codename": "xa_hung_nhuong",
                        "division_type": "xã",
                        "short_codename": "hung_nhuong"
                    },
                    {
                        "name": "Xã Hưng Lễ",
                        "code": 29047,
                        "codename": "xa_hung_le",
                        "division_type": "xã",
                        "short_codename": "hung_le"
                    }
                ]
            },
            {
                "name": "Huyện Bình Đại",
                "code": 835,
                "codename": "huyen_binh_dai",
                "division_type": "huyện",
                "short_codename": "binh_dai",
                "wards": [
                    {
                        "name": "Thị trấn Bình Đại",
                        "code": 29050,
                        "codename": "thi_tran_binh_dai",
                        "division_type": "thị trấn",
                        "short_codename": "binh_dai"
                    },
                    {
                        "name": "Xã Tam Hiệp",
                        "code": 29053,
                        "codename": "xa_tam_hiep",
                        "division_type": "xã",
                        "short_codename": "tam_hiep"
                    },
                    {
                        "name": "Xã Long Định",
                        "code": 29056,
                        "codename": "xa_long_dinh",
                        "division_type": "xã",
                        "short_codename": "long_dinh"
                    },
                    {
                        "name": "Xã Long Hòa",
                        "code": 29059,
                        "codename": "xa_long_hoa",
                        "division_type": "xã",
                        "short_codename": "long_hoa"
                    },
                    {
                        "name": "Xã Phú Thuận",
                        "code": 29062,
                        "codename": "xa_phu_thuan",
                        "division_type": "xã",
                        "short_codename": "phu_thuan"
                    },
                    {
                        "name": "Xã Vang Quới Tây",
                        "code": 29065,
                        "codename": "xa_vang_quoi_tay",
                        "division_type": "xã",
                        "short_codename": "vang_quoi_tay"
                    },
                    {
                        "name": "Xã Vang Quới Đông",
                        "code": 29068,
                        "codename": "xa_vang_quoi_dong",
                        "division_type": "xã",
                        "short_codename": "vang_quoi_dong"
                    },
                    {
                        "name": "Xã Châu Hưng",
                        "code": 29071,
                        "codename": "xa_chau_hung",
                        "division_type": "xã",
                        "short_codename": "chau_hung"
                    },
                    {
                        "name": "Xã Phú Vang",
                        "code": 29074,
                        "codename": "xa_phu_vang",
                        "division_type": "xã",
                        "short_codename": "phu_vang"
                    },
                    {
                        "name": "Xã Lộc Thuận",
                        "code": 29077,
                        "codename": "xa_loc_thuan",
                        "division_type": "xã",
                        "short_codename": "loc_thuan"
                    },
                    {
                        "name": "Xã Định Trung",
                        "code": 29080,
                        "codename": "xa_dinh_trung",
                        "division_type": "xã",
                        "short_codename": "dinh_trung"
                    },
                    {
                        "name": "Xã Thới Lai",
                        "code": 29083,
                        "codename": "xa_thoi_lai",
                        "division_type": "xã",
                        "short_codename": "thoi_lai"
                    },
                    {
                        "name": "Xã Bình Thới",
                        "code": 29086,
                        "codename": "xa_binh_thoi",
                        "division_type": "xã",
                        "short_codename": "binh_thoi"
                    },
                    {
                        "name": "Xã Phú Long",
                        "code": 29089,
                        "codename": "xa_phu_long",
                        "division_type": "xã",
                        "short_codename": "phu_long"
                    },
                    {
                        "name": "Xã Bình Thắng",
                        "code": 29092,
                        "codename": "xa_binh_thang",
                        "division_type": "xã",
                        "short_codename": "binh_thang"
                    },
                    {
                        "name": "Xã Thạnh Trị",
                        "code": 29095,
                        "codename": "xa_thanh_tri",
                        "division_type": "xã",
                        "short_codename": "thanh_tri"
                    },
                    {
                        "name": "Xã Đại Hòa Lộc",
                        "code": 29098,
                        "codename": "xa_dai_hoa_loc",
                        "division_type": "xã",
                        "short_codename": "dai_hoa_loc"
                    },
                    {
                        "name": "Xã Thừa Đức",
                        "code": 29101,
                        "codename": "xa_thua_duc",
                        "division_type": "xã",
                        "short_codename": "thua_duc"
                    },
                    {
                        "name": "Xã Thạnh Phước",
                        "code": 29104,
                        "codename": "xa_thanh_phuoc",
                        "division_type": "xã",
                        "short_codename": "thanh_phuoc"
                    },
                    {
                        "name": "Xã Thới Thuận",
                        "code": 29107,
                        "codename": "xa_thoi_thuan",
                        "division_type": "xã",
                        "short_codename": "thoi_thuan"
                    }
                ]
            },
            {
                "name": "Huyện Ba Tri",
                "code": 836,
                "codename": "huyen_ba_tri",
                "division_type": "huyện",
                "short_codename": "ba_tri",
                "wards": [
                    {
                        "name": "Thị trấn Ba Tri",
                        "code": 29110,
                        "codename": "thi_tran_ba_tri",
                        "division_type": "thị trấn",
                        "short_codename": "ba_tri"
                    },
                    {
                        "name": "Xã Tân Mỹ",
                        "code": 29113,
                        "codename": "xa_tan_my",
                        "division_type": "xã",
                        "short_codename": "tan_my"
                    },
                    {
                        "name": "Xã Mỹ Hòa",
                        "code": 29116,
                        "codename": "xa_my_hoa",
                        "division_type": "xã",
                        "short_codename": "my_hoa"
                    },
                    {
                        "name": "Xã Tân Xuân",
                        "code": 29119,
                        "codename": "xa_tan_xuan",
                        "division_type": "xã",
                        "short_codename": "tan_xuan"
                    },
                    {
                        "name": "Xã Mỹ Chánh",
                        "code": 29122,
                        "codename": "xa_my_chanh",
                        "division_type": "xã",
                        "short_codename": "my_chanh"
                    },
                    {
                        "name": "Xã Bảo Thạnh",
                        "code": 29125,
                        "codename": "xa_bao_thanh",
                        "division_type": "xã",
                        "short_codename": "bao_thanh"
                    },
                    {
                        "name": "Xã An Phú Trung",
                        "code": 29128,
                        "codename": "xa_an_phu_trung",
                        "division_type": "xã",
                        "short_codename": "an_phu_trung"
                    },
                    {
                        "name": "Xã Mỹ Thạnh",
                        "code": 29131,
                        "codename": "xa_my_thanh",
                        "division_type": "xã",
                        "short_codename": "my_thanh"
                    },
                    {
                        "name": "Xã Mỹ Nhơn",
                        "code": 29134,
                        "codename": "xa_my_nhon",
                        "division_type": "xã",
                        "short_codename": "my_nhon"
                    },
                    {
                        "name": "Xã Phước Ngãi",
                        "code": 29137,
                        "codename": "xa_phuoc_ngai",
                        "division_type": "xã",
                        "short_codename": "phuoc_ngai"
                    },
                    {
                        "name": "Xã An Ngãi Trung",
                        "code": 29143,
                        "codename": "xa_an_ngai_trung",
                        "division_type": "xã",
                        "short_codename": "an_ngai_trung"
                    },
                    {
                        "name": "Xã Phú Lễ",
                        "code": 29146,
                        "codename": "xa_phu_le",
                        "division_type": "xã",
                        "short_codename": "phu_le"
                    },
                    {
                        "name": "Xã An Bình Tây",
                        "code": 29149,
                        "codename": "xa_an_binh_tay",
                        "division_type": "xã",
                        "short_codename": "an_binh_tay"
                    },
                    {
                        "name": "Xã Bảo Thuận",
                        "code": 29152,
                        "codename": "xa_bao_thuan",
                        "division_type": "xã",
                        "short_codename": "bao_thuan"
                    },
                    {
                        "name": "Xã Tân Hưng",
                        "code": 29155,
                        "codename": "xa_tan_hung",
                        "division_type": "xã",
                        "short_codename": "tan_hung"
                    },
                    {
                        "name": "Xã An Ngãi Tây",
                        "code": 29158,
                        "codename": "xa_an_ngai_tay",
                        "division_type": "xã",
                        "short_codename": "an_ngai_tay"
                    },
                    {
                        "name": "Xã An Hiệp",
                        "code": 29161,
                        "codename": "xa_an_hiep",
                        "division_type": "xã",
                        "short_codename": "an_hiep"
                    },
                    {
                        "name": "Xã Vĩnh Hòa",
                        "code": 29164,
                        "codename": "xa_vinh_hoa",
                        "division_type": "xã",
                        "short_codename": "vinh_hoa"
                    },
                    {
                        "name": "Xã Tân Thủy",
                        "code": 29167,
                        "codename": "xa_tan_thuy",
                        "division_type": "xã",
                        "short_codename": "tan_thuy"
                    },
                    {
                        "name": "Xã Vĩnh An",
                        "code": 29170,
                        "codename": "xa_vinh_an",
                        "division_type": "xã",
                        "short_codename": "vinh_an"
                    },
                    {
                        "name": "Xã An Đức",
                        "code": 29173,
                        "codename": "xa_an_duc",
                        "division_type": "xã",
                        "short_codename": "an_duc"
                    },
                    {
                        "name": "Xã An Hòa Tây",
                        "code": 29176,
                        "codename": "xa_an_hoa_tay",
                        "division_type": "xã",
                        "short_codename": "an_hoa_tay"
                    },
                    {
                        "name": "Xã An Thủy",
                        "code": 29179,
                        "codename": "xa_an_thuy",
                        "division_type": "xã",
                        "short_codename": "an_thuy"
                    }
                ]
            },
            {
                "name": "Huyện Thạnh Phú",
                "code": 837,
                "codename": "huyen_thanh_phu",
                "division_type": "huyện",
                "short_codename": "thanh_phu",
                "wards": [
                    {
                        "name": "Thị trấn Thạnh Phú",
                        "code": 29182,
                        "codename": "thi_tran_thanh_phu",
                        "division_type": "thị trấn",
                        "short_codename": "thanh_phu"
                    },
                    {
                        "name": "Xã Phú Khánh",
                        "code": 29185,
                        "codename": "xa_phu_khanh",
                        "division_type": "xã",
                        "short_codename": "phu_khanh"
                    },
                    {
                        "name": "Xã Đại Điền",
                        "code": 29188,
                        "codename": "xa_dai_dien",
                        "division_type": "xã",
                        "short_codename": "dai_dien"
                    },
                    {
                        "name": "Xã Quới Điền",
                        "code": 29191,
                        "codename": "xa_quoi_dien",
                        "division_type": "xã",
                        "short_codename": "quoi_dien"
                    },
                    {
                        "name": "Xã Tân Phong",
                        "code": 29194,
                        "codename": "xa_tan_phong",
                        "division_type": "xã",
                        "short_codename": "tan_phong"
                    },
                    {
                        "name": "Xã Mỹ Hưng",
                        "code": 29197,
                        "codename": "xa_my_hung",
                        "division_type": "xã",
                        "short_codename": "my_hung"
                    },
                    {
                        "name": "Xã An Thạnh",
                        "code": 29200,
                        "codename": "xa_an_thanh",
                        "division_type": "xã",
                        "short_codename": "an_thanh"
                    },
                    {
                        "name": "Xã Thới Thạnh",
                        "code": 29203,
                        "codename": "xa_thoi_thanh",
                        "division_type": "xã",
                        "short_codename": "thoi_thanh"
                    },
                    {
                        "name": "Xã Hòa Lợi",
                        "code": 29206,
                        "codename": "xa_hoa_loi",
                        "division_type": "xã",
                        "short_codename": "hoa_loi"
                    },
                    {
                        "name": "Xã An Điền",
                        "code": 29209,
                        "codename": "xa_an_dien",
                        "division_type": "xã",
                        "short_codename": "an_dien"
                    },
                    {
                        "name": "Xã Bình Thạnh",
                        "code": 29212,
                        "codename": "xa_binh_thanh",
                        "division_type": "xã",
                        "short_codename": "binh_thanh"
                    },
                    {
                        "name": "Xã An Thuận",
                        "code": 29215,
                        "codename": "xa_an_thuan",
                        "division_type": "xã",
                        "short_codename": "an_thuan"
                    },
                    {
                        "name": "Xã An Quy",
                        "code": 29218,
                        "codename": "xa_an_quy",
                        "division_type": "xã",
                        "short_codename": "an_quy"
                    },
                    {
                        "name": "Xã Thạnh Hải",
                        "code": 29221,
                        "codename": "xa_thanh_hai",
                        "division_type": "xã",
                        "short_codename": "thanh_hai"
                    },
                    {
                        "name": "Xã An Nhơn",
                        "code": 29224,
                        "codename": "xa_an_nhon",
                        "division_type": "xã",
                        "short_codename": "an_nhon"
                    },
                    {
                        "name": "Xã Giao Thạnh",
                        "code": 29227,
                        "codename": "xa_giao_thanh",
                        "division_type": "xã",
                        "short_codename": "giao_thanh"
                    },
                    {
                        "name": "Xã Thạnh Phong",
                        "code": 29230,
                        "codename": "xa_thanh_phong",
                        "division_type": "xã",
                        "short_codename": "thanh_phong"
                    },
                    {
                        "name": "Xã Mỹ An",
                        "code": 29233,
                        "codename": "xa_my_an",
                        "division_type": "xã",
                        "short_codename": "my_an"
                    }
                ]
            },
            {
                "name": "Huyện Mỏ Cày Bắc",
                "code": 838,
                "codename": "huyen_mo_cay_bac",
                "division_type": "huyện",
                "short_codename": "mo_cay_bac",
                "wards": [
                    {
                        "name": "Xã Phú Mỹ",
                        "code": 28889,
                        "codename": "xa_phu_my",
                        "division_type": "xã",
                        "short_codename": "phu_my"
                    },
                    {
                        "name": "Xã Hưng Khánh Trung A",
                        "code": 28901,
                        "codename": "xa_hung_khanh_trung_a",
                        "division_type": "xã",
                        "short_codename": "hung_khanh_trung_a"
                    },
                    {
                        "name": "Xã Thanh Tân",
                        "code": 28906,
                        "codename": "xa_thanh_tan",
                        "division_type": "xã",
                        "short_codename": "thanh_tan"
                    },
                    {
                        "name": "Xã Thạnh Ngãi",
                        "code": 28909,
                        "codename": "xa_thanh_ngai",
                        "division_type": "xã",
                        "short_codename": "thanh_ngai"
                    },
                    {
                        "name": "Xã Tân Phú Tây",
                        "code": 28912,
                        "codename": "xa_tan_phu_tay",
                        "division_type": "xã",
                        "short_codename": "tan_phu_tay"
                    },
                    {
                        "name": "Xã Phước Mỹ Trung",
                        "code": 28915,
                        "codename": "xa_phuoc_my_trung",
                        "division_type": "xã",
                        "short_codename": "phuoc_my_trung"
                    },
                    {
                        "name": "Xã Tân Thành Bình",
                        "code": 28918,
                        "codename": "xa_tan_thanh_binh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh_binh"
                    },
                    {
                        "name": "Xã Thành An",
                        "code": 28921,
                        "codename": "xa_thanh_an",
                        "division_type": "xã",
                        "short_codename": "thanh_an"
                    },
                    {
                        "name": "Xã Hòa Lộc",
                        "code": 28924,
                        "codename": "xa_hoa_loc",
                        "division_type": "xã",
                        "short_codename": "hoa_loc"
                    },
                    {
                        "name": "Xã Tân Thanh Tây",
                        "code": 28927,
                        "codename": "xa_tan_thanh_tay",
                        "division_type": "xã",
                        "short_codename": "tan_thanh_tay"
                    },
                    {
                        "name": "Xã Tân Bình",
                        "code": 28933,
                        "codename": "xa_tan_binh",
                        "division_type": "xã",
                        "short_codename": "tan_binh"
                    },
                    {
                        "name": "Xã Nhuận Phú Tân",
                        "code": 28936,
                        "codename": "xa_nhuan_phu_tan",
                        "division_type": "xã",
                        "short_codename": "nhuan_phu_tan"
                    },
                    {
                        "name": "Xã Khánh Thạnh Tân",
                        "code": 28948,
                        "codename": "xa_khanh_thanh_tan",
                        "division_type": "xã",
                        "short_codename": "khanh_thanh_tan"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Trà Vinh",
        "code": 84,
        "codename": "tinh_tra_vinh",
        "division_type": "tỉnh",
        "phone_code": 294,
        "districts": [
            {
                "name": "Thành phố Trà Vinh",
                "code": 842,
                "codename": "thanh_pho_tra_vinh",
                "division_type": "thành phố",
                "short_codename": "tra_vinh",
                "wards": [
                    {
                        "name": "Phường 4",
                        "code": 29236,
                        "codename": "phuong_4",
                        "division_type": "phường",
                        "short_codename": "phuong_4"
                    },
                    {
                        "name": "Phường 1",
                        "code": 29239,
                        "codename": "phuong_1",
                        "division_type": "phường",
                        "short_codename": "phuong_1"
                    },
                    {
                        "name": "Phường 3",
                        "code": 29242,
                        "codename": "phuong_3",
                        "division_type": "phường",
                        "short_codename": "phuong_3"
                    },
                    {
                        "name": "Phường 2",
                        "code": 29245,
                        "codename": "phuong_2",
                        "division_type": "phường",
                        "short_codename": "phuong_2"
                    },
                    {
                        "name": "Phường 5",
                        "code": 29248,
                        "codename": "phuong_5",
                        "division_type": "phường",
                        "short_codename": "phuong_5"
                    },
                    {
                        "name": "Phường 6",
                        "code": 29251,
                        "codename": "phuong_6",
                        "division_type": "phường",
                        "short_codename": "phuong_6"
                    },
                    {
                        "name": "Phường 7",
                        "code": 29254,
                        "codename": "phuong_7",
                        "division_type": "phường",
                        "short_codename": "phuong_7"
                    },
                    {
                        "name": "Phường 8",
                        "code": 29257,
                        "codename": "phuong_8",
                        "division_type": "phường",
                        "short_codename": "phuong_8"
                    },
                    {
                        "name": "Phường 9",
                        "code": 29260,
                        "codename": "phuong_9",
                        "division_type": "phường",
                        "short_codename": "phuong_9"
                    },
                    {
                        "name": "Xã Long Đức",
                        "code": 29263,
                        "codename": "xa_long_duc",
                        "division_type": "xã",
                        "short_codename": "long_duc"
                    }
                ]
            },
            {
                "name": "Huyện Càng Long",
                "code": 844,
                "codename": "huyen_cang_long",
                "division_type": "huyện",
                "short_codename": "cang_long",
                "wards": [
                    {
                        "name": "Thị trấn Càng Long",
                        "code": 29266,
                        "codename": "thi_tran_cang_long",
                        "division_type": "thị trấn",
                        "short_codename": "cang_long"
                    },
                    {
                        "name": "Xã Mỹ Cẩm",
                        "code": 29269,
                        "codename": "xa_my_cam",
                        "division_type": "xã",
                        "short_codename": "my_cam"
                    },
                    {
                        "name": "Xã An Trường A",
                        "code": 29272,
                        "codename": "xa_an_truong_a",
                        "division_type": "xã",
                        "short_codename": "an_truong_a"
                    },
                    {
                        "name": "Xã An Trường",
                        "code": 29275,
                        "codename": "xa_an_truong",
                        "division_type": "xã",
                        "short_codename": "an_truong"
                    },
                    {
                        "name": "Xã Huyền Hội",
                        "code": 29278,
                        "codename": "xa_huyen_hoi",
                        "division_type": "xã",
                        "short_codename": "huyen_hoi"
                    },
                    {
                        "name": "Xã Tân An",
                        "code": 29281,
                        "codename": "xa_tan_an",
                        "division_type": "xã",
                        "short_codename": "tan_an"
                    },
                    {
                        "name": "Xã Tân Bình",
                        "code": 29284,
                        "codename": "xa_tan_binh",
                        "division_type": "xã",
                        "short_codename": "tan_binh"
                    },
                    {
                        "name": "Xã Bình Phú",
                        "code": 29287,
                        "codename": "xa_binh_phu",
                        "division_type": "xã",
                        "short_codename": "binh_phu"
                    },
                    {
                        "name": "Xã Phương Thạnh",
                        "code": 29290,
                        "codename": "xa_phuong_thanh",
                        "division_type": "xã",
                        "short_codename": "phuong_thanh"
                    },
                    {
                        "name": "Xã Đại Phúc",
                        "code": 29293,
                        "codename": "xa_dai_phuc",
                        "division_type": "xã",
                        "short_codename": "dai_phuc"
                    },
                    {
                        "name": "Xã Đại Phước",
                        "code": 29296,
                        "codename": "xa_dai_phuoc",
                        "division_type": "xã",
                        "short_codename": "dai_phuoc"
                    },
                    {
                        "name": "Xã Nhị Long Phú",
                        "code": 29299,
                        "codename": "xa_nhi_long_phu",
                        "division_type": "xã",
                        "short_codename": "nhi_long_phu"
                    },
                    {
                        "name": "Xã Nhị Long",
                        "code": 29302,
                        "codename": "xa_nhi_long",
                        "division_type": "xã",
                        "short_codename": "nhi_long"
                    },
                    {
                        "name": "Xã Đức Mỹ",
                        "code": 29305,
                        "codename": "xa_duc_my",
                        "division_type": "xã",
                        "short_codename": "duc_my"
                    }
                ]
            },
            {
                "name": "Huyện Cầu Kè",
                "code": 845,
                "codename": "huyen_cau_ke",
                "division_type": "huyện",
                "short_codename": "cau_ke",
                "wards": [
                    {
                        "name": "Thị trấn Cầu Kè",
                        "code": 29308,
                        "codename": "thi_tran_cau_ke",
                        "division_type": "thị trấn",
                        "short_codename": "cau_ke"
                    },
                    {
                        "name": "Xã Hòa Ân",
                        "code": 29311,
                        "codename": "xa_hoa_an",
                        "division_type": "xã",
                        "short_codename": "hoa_an"
                    },
                    {
                        "name": "Xã Châu Điền",
                        "code": 29314,
                        "codename": "xa_chau_dien",
                        "division_type": "xã",
                        "short_codename": "chau_dien"
                    },
                    {
                        "name": "Xã An Phú Tân",
                        "code": 29317,
                        "codename": "xa_an_phu_tan",
                        "division_type": "xã",
                        "short_codename": "an_phu_tan"
                    },
                    {
                        "name": "Xã Hoà Tân",
                        "code": 29320,
                        "codename": "xa_hoa_tan",
                        "division_type": "xã",
                        "short_codename": "hoa_tan"
                    },
                    {
                        "name": "Xã Ninh Thới",
                        "code": 29323,
                        "codename": "xa_ninh_thoi",
                        "division_type": "xã",
                        "short_codename": "ninh_thoi"
                    },
                    {
                        "name": "Xã Phong Phú",
                        "code": 29326,
                        "codename": "xa_phong_phu",
                        "division_type": "xã",
                        "short_codename": "phong_phu"
                    },
                    {
                        "name": "Xã Phong Thạnh",
                        "code": 29329,
                        "codename": "xa_phong_thanh",
                        "division_type": "xã",
                        "short_codename": "phong_thanh"
                    },
                    {
                        "name": "Xã Tam Ngãi",
                        "code": 29332,
                        "codename": "xa_tam_ngai",
                        "division_type": "xã",
                        "short_codename": "tam_ngai"
                    },
                    {
                        "name": "Xã Thông Hòa",
                        "code": 29335,
                        "codename": "xa_thong_hoa",
                        "division_type": "xã",
                        "short_codename": "thong_hoa"
                    },
                    {
                        "name": "Xã Thạnh Phú",
                        "code": 29338,
                        "codename": "xa_thanh_phu",
                        "division_type": "xã",
                        "short_codename": "thanh_phu"
                    }
                ]
            },
            {
                "name": "Huyện Tiểu Cần",
                "code": 846,
                "codename": "huyen_tieu_can",
                "division_type": "huyện",
                "short_codename": "tieu_can",
                "wards": [
                    {
                        "name": "Thị trấn Tiểu Cần",
                        "code": 29341,
                        "codename": "thi_tran_tieu_can",
                        "division_type": "thị trấn",
                        "short_codename": "tieu_can"
                    },
                    {
                        "name": "Thị trấn Cầu Quan",
                        "code": 29344,
                        "codename": "thi_tran_cau_quan",
                        "division_type": "thị trấn",
                        "short_codename": "cau_quan"
                    },
                    {
                        "name": "Xã Phú Cần",
                        "code": 29347,
                        "codename": "xa_phu_can",
                        "division_type": "xã",
                        "short_codename": "phu_can"
                    },
                    {
                        "name": "Xã Hiếu Tử",
                        "code": 29350,
                        "codename": "xa_hieu_tu",
                        "division_type": "xã",
                        "short_codename": "hieu_tu"
                    },
                    {
                        "name": "Xã Hiếu Trung",
                        "code": 29353,
                        "codename": "xa_hieu_trung",
                        "division_type": "xã",
                        "short_codename": "hieu_trung"
                    },
                    {
                        "name": "Xã Long Thới",
                        "code": 29356,
                        "codename": "xa_long_thoi",
                        "division_type": "xã",
                        "short_codename": "long_thoi"
                    },
                    {
                        "name": "Xã Hùng Hòa",
                        "code": 29359,
                        "codename": "xa_hung_hoa",
                        "division_type": "xã",
                        "short_codename": "hung_hoa"
                    },
                    {
                        "name": "Xã Tân Hùng",
                        "code": 29362,
                        "codename": "xa_tan_hung",
                        "division_type": "xã",
                        "short_codename": "tan_hung"
                    },
                    {
                        "name": "Xã Tập Ngãi",
                        "code": 29365,
                        "codename": "xa_tap_ngai",
                        "division_type": "xã",
                        "short_codename": "tap_ngai"
                    },
                    {
                        "name": "Xã Ngãi Hùng",
                        "code": 29368,
                        "codename": "xa_ngai_hung",
                        "division_type": "xã",
                        "short_codename": "ngai_hung"
                    },
                    {
                        "name": "Xã Tân Hòa",
                        "code": 29371,
                        "codename": "xa_tan_hoa",
                        "division_type": "xã",
                        "short_codename": "tan_hoa"
                    }
                ]
            },
            {
                "name": "Huyện Châu Thành",
                "code": 847,
                "codename": "huyen_chau_thanh",
                "division_type": "huyện",
                "short_codename": "chau_thanh",
                "wards": [
                    {
                        "name": "Thị trấn Châu Thành",
                        "code": 29374,
                        "codename": "thi_tran_chau_thanh",
                        "division_type": "thị trấn",
                        "short_codename": "chau_thanh"
                    },
                    {
                        "name": "Xã Đa Lộc",
                        "code": 29377,
                        "codename": "xa_da_loc",
                        "division_type": "xã",
                        "short_codename": "da_loc"
                    },
                    {
                        "name": "Xã Mỹ Chánh",
                        "code": 29380,
                        "codename": "xa_my_chanh",
                        "division_type": "xã",
                        "short_codename": "my_chanh"
                    },
                    {
                        "name": "Xã Thanh Mỹ",
                        "code": 29383,
                        "codename": "xa_thanh_my",
                        "division_type": "xã",
                        "short_codename": "thanh_my"
                    },
                    {
                        "name": "Xã Lương Hoà A",
                        "code": 29386,
                        "codename": "xa_luong_hoa_a",
                        "division_type": "xã",
                        "short_codename": "luong_hoa_a"
                    },
                    {
                        "name": "Xã Lương Hòa",
                        "code": 29389,
                        "codename": "xa_luong_hoa",
                        "division_type": "xã",
                        "short_codename": "luong_hoa"
                    },
                    {
                        "name": "Xã Song Lộc",
                        "code": 29392,
                        "codename": "xa_song_loc",
                        "division_type": "xã",
                        "short_codename": "song_loc"
                    },
                    {
                        "name": "Xã Nguyệt Hóa",
                        "code": 29395,
                        "codename": "xa_nguyet_hoa",
                        "division_type": "xã",
                        "short_codename": "nguyet_hoa"
                    },
                    {
                        "name": "Xã Hòa Thuận",
                        "code": 29398,
                        "codename": "xa_hoa_thuan",
                        "division_type": "xã",
                        "short_codename": "hoa_thuan"
                    },
                    {
                        "name": "Xã Hòa Lợi",
                        "code": 29401,
                        "codename": "xa_hoa_loi",
                        "division_type": "xã",
                        "short_codename": "hoa_loi"
                    },
                    {
                        "name": "Xã Phước Hảo",
                        "code": 29404,
                        "codename": "xa_phuoc_hao",
                        "division_type": "xã",
                        "short_codename": "phuoc_hao"
                    },
                    {
                        "name": "Xã Hưng Mỹ",
                        "code": 29407,
                        "codename": "xa_hung_my",
                        "division_type": "xã",
                        "short_codename": "hung_my"
                    },
                    {
                        "name": "Xã Hòa Minh",
                        "code": 29410,
                        "codename": "xa_hoa_minh",
                        "division_type": "xã",
                        "short_codename": "hoa_minh"
                    },
                    {
                        "name": "Xã Long Hòa",
                        "code": 29413,
                        "codename": "xa_long_hoa",
                        "division_type": "xã",
                        "short_codename": "long_hoa"
                    }
                ]
            },
            {
                "name": "Huyện Cầu Ngang",
                "code": 848,
                "codename": "huyen_cau_ngang",
                "division_type": "huyện",
                "short_codename": "cau_ngang",
                "wards": [
                    {
                        "name": "Thị trấn Cầu Ngang",
                        "code": 29416,
                        "codename": "thi_tran_cau_ngang",
                        "division_type": "thị trấn",
                        "short_codename": "cau_ngang"
                    },
                    {
                        "name": "Thị trấn Mỹ Long",
                        "code": 29419,
                        "codename": "thi_tran_my_long",
                        "division_type": "thị trấn",
                        "short_codename": "my_long"
                    },
                    {
                        "name": "Xã Mỹ Long Bắc",
                        "code": 29422,
                        "codename": "xa_my_long_bac",
                        "division_type": "xã",
                        "short_codename": "my_long_bac"
                    },
                    {
                        "name": "Xã Mỹ Long Nam",
                        "code": 29425,
                        "codename": "xa_my_long_nam",
                        "division_type": "xã",
                        "short_codename": "my_long_nam"
                    },
                    {
                        "name": "Xã Mỹ Hòa",
                        "code": 29428,
                        "codename": "xa_my_hoa",
                        "division_type": "xã",
                        "short_codename": "my_hoa"
                    },
                    {
                        "name": "Xã Vĩnh Kim",
                        "code": 29431,
                        "codename": "xa_vinh_kim",
                        "division_type": "xã",
                        "short_codename": "vinh_kim"
                    },
                    {
                        "name": "Xã Kim Hòa",
                        "code": 29434,
                        "codename": "xa_kim_hoa",
                        "division_type": "xã",
                        "short_codename": "kim_hoa"
                    },
                    {
                        "name": "Xã Hiệp Hòa",
                        "code": 29437,
                        "codename": "xa_hiep_hoa",
                        "division_type": "xã",
                        "short_codename": "hiep_hoa"
                    },
                    {
                        "name": "Xã Thuận Hòa",
                        "code": 29440,
                        "codename": "xa_thuan_hoa",
                        "division_type": "xã",
                        "short_codename": "thuan_hoa"
                    },
                    {
                        "name": "Xã Long Sơn",
                        "code": 29443,
                        "codename": "xa_long_son",
                        "division_type": "xã",
                        "short_codename": "long_son"
                    },
                    {
                        "name": "Xã Nhị Trường",
                        "code": 29446,
                        "codename": "xa_nhi_truong",
                        "division_type": "xã",
                        "short_codename": "nhi_truong"
                    },
                    {
                        "name": "Xã Trường Thọ",
                        "code": 29449,
                        "codename": "xa_truong_tho",
                        "division_type": "xã",
                        "short_codename": "truong_tho"
                    },
                    {
                        "name": "Xã Hiệp Mỹ Đông",
                        "code": 29452,
                        "codename": "xa_hiep_my_dong",
                        "division_type": "xã",
                        "short_codename": "hiep_my_dong"
                    },
                    {
                        "name": "Xã Hiệp Mỹ Tây",
                        "code": 29455,
                        "codename": "xa_hiep_my_tay",
                        "division_type": "xã",
                        "short_codename": "hiep_my_tay"
                    },
                    {
                        "name": "Xã Thạnh Hòa Sơn",
                        "code": 29458,
                        "codename": "xa_thanh_hoa_son",
                        "division_type": "xã",
                        "short_codename": "thanh_hoa_son"
                    }
                ]
            },
            {
                "name": "Huyện Trà Cú",
                "code": 849,
                "codename": "huyen_tra_cu",
                "division_type": "huyện",
                "short_codename": "tra_cu",
                "wards": [
                    {
                        "name": "Thị trấn Trà Cú",
                        "code": 29461,
                        "codename": "thi_tran_tra_cu",
                        "division_type": "thị trấn",
                        "short_codename": "tra_cu"
                    },
                    {
                        "name": "Thị trấn Định An",
                        "code": 29462,
                        "codename": "thi_tran_dinh_an",
                        "division_type": "thị trấn",
                        "short_codename": "dinh_an"
                    },
                    {
                        "name": "Xã Phước Hưng",
                        "code": 29464,
                        "codename": "xa_phuoc_hung",
                        "division_type": "xã",
                        "short_codename": "phuoc_hung"
                    },
                    {
                        "name": "Xã Tập Sơn",
                        "code": 29467,
                        "codename": "xa_tap_son",
                        "division_type": "xã",
                        "short_codename": "tap_son"
                    },
                    {
                        "name": "Xã Tân Sơn",
                        "code": 29470,
                        "codename": "xa_tan_son",
                        "division_type": "xã",
                        "short_codename": "tan_son"
                    },
                    {
                        "name": "Xã An Quảng Hữu",
                        "code": 29473,
                        "codename": "xa_an_quang_huu",
                        "division_type": "xã",
                        "short_codename": "an_quang_huu"
                    },
                    {
                        "name": "Xã Lưu Nghiệp Anh",
                        "code": 29476,
                        "codename": "xa_luu_nghiep_anh",
                        "division_type": "xã",
                        "short_codename": "luu_nghiep_anh"
                    },
                    {
                        "name": "Xã Ngãi Xuyên",
                        "code": 29479,
                        "codename": "xa_ngai_xuyen",
                        "division_type": "xã",
                        "short_codename": "ngai_xuyen"
                    },
                    {
                        "name": "Xã Kim Sơn",
                        "code": 29482,
                        "codename": "xa_kim_son",
                        "division_type": "xã",
                        "short_codename": "kim_son"
                    },
                    {
                        "name": "Xã Thanh Sơn",
                        "code": 29485,
                        "codename": "xa_thanh_son",
                        "division_type": "xã",
                        "short_codename": "thanh_son"
                    },
                    {
                        "name": "Xã Hàm Giang",
                        "code": 29488,
                        "codename": "xa_ham_giang",
                        "division_type": "xã",
                        "short_codename": "ham_giang"
                    },
                    {
                        "name": "Xã Hàm Tân",
                        "code": 29489,
                        "codename": "xa_ham_tan",
                        "division_type": "xã",
                        "short_codename": "ham_tan"
                    },
                    {
                        "name": "Xã Đại An",
                        "code": 29491,
                        "codename": "xa_dai_an",
                        "division_type": "xã",
                        "short_codename": "dai_an"
                    },
                    {
                        "name": "Xã Định An",
                        "code": 29494,
                        "codename": "xa_dinh_an",
                        "division_type": "xã",
                        "short_codename": "xa_dinh_an"
                    },
                    {
                        "name": "Xã Ngọc Biên",
                        "code": 29503,
                        "codename": "xa_ngoc_bien",
                        "division_type": "xã",
                        "short_codename": "ngoc_bien"
                    },
                    {
                        "name": "Xã Long Hiệp",
                        "code": 29506,
                        "codename": "xa_long_hiep",
                        "division_type": "xã",
                        "short_codename": "long_hiep"
                    },
                    {
                        "name": "Xã Tân Hiệp",
                        "code": 29509,
                        "codename": "xa_tan_hiep",
                        "division_type": "xã",
                        "short_codename": "tan_hiep"
                    }
                ]
            },
            {
                "name": "Huyện Duyên Hải",
                "code": 850,
                "codename": "huyen_duyen_hai",
                "division_type": "huyện",
                "short_codename": "huyen_duyen_hai",
                "wards": [
                    {
                        "name": "Xã Đôn Xuân",
                        "code": 29497,
                        "codename": "xa_don_xuan",
                        "division_type": "xã",
                        "short_codename": "don_xuan"
                    },
                    {
                        "name": "Xã Đôn Châu",
                        "code": 29500,
                        "codename": "xa_don_chau",
                        "division_type": "xã",
                        "short_codename": "don_chau"
                    },
                    {
                        "name": "Thị trấn Long Thành",
                        "code": 29513,
                        "codename": "thi_tran_long_thanh",
                        "division_type": "thị trấn",
                        "short_codename": "long_thanh"
                    },
                    {
                        "name": "Xã Long Khánh",
                        "code": 29521,
                        "codename": "xa_long_khanh",
                        "division_type": "xã",
                        "short_codename": "long_khanh"
                    },
                    {
                        "name": "Xã Ngũ Lạc",
                        "code": 29530,
                        "codename": "xa_ngu_lac",
                        "division_type": "xã",
                        "short_codename": "ngu_lac"
                    },
                    {
                        "name": "Xã Long Vĩnh",
                        "code": 29533,
                        "codename": "xa_long_vinh",
                        "division_type": "xã",
                        "short_codename": "long_vinh"
                    },
                    {
                        "name": "Xã Đông Hải",
                        "code": 29536,
                        "codename": "xa_dong_hai",
                        "division_type": "xã",
                        "short_codename": "dong_hai"
                    }
                ]
            },
            {
                "name": "Thị xã Duyên Hải",
                "code": 851,
                "codename": "thi_xa_duyen_hai",
                "division_type": "thị xã",
                "short_codename": "duyen_hai",
                "wards": [
                    {
                        "name": "Phường 1",
                        "code": 29512,
                        "codename": "phuong_1",
                        "division_type": "phường",
                        "short_codename": "phuong_1"
                    },
                    {
                        "name": "Xã Long Toàn",
                        "code": 29515,
                        "codename": "xa_long_toan",
                        "division_type": "xã",
                        "short_codename": "long_toan"
                    },
                    {
                        "name": "Phường 2",
                        "code": 29516,
                        "codename": "phuong_2",
                        "division_type": "phường",
                        "short_codename": "phuong_2"
                    },
                    {
                        "name": "Xã Long Hữu",
                        "code": 29518,
                        "codename": "xa_long_huu",
                        "division_type": "xã",
                        "short_codename": "long_huu"
                    },
                    {
                        "name": "Xã Dân Thành",
                        "code": 29524,
                        "codename": "xa_dan_thanh",
                        "division_type": "xã",
                        "short_codename": "dan_thanh"
                    },
                    {
                        "name": "Xã Trường Long Hòa",
                        "code": 29527,
                        "codename": "xa_truong_long_hoa",
                        "division_type": "xã",
                        "short_codename": "truong_long_hoa"
                    },
                    {
                        "name": "Xã Hiệp Thạnh",
                        "code": 29539,
                        "codename": "xa_hiep_thanh",
                        "division_type": "xã",
                        "short_codename": "hiep_thanh"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Vĩnh Long",
        "code": 86,
        "codename": "tinh_vinh_long",
        "division_type": "tỉnh",
        "phone_code": 270,
        "districts": [
            {
                "name": "Thành phố Vĩnh Long",
                "code": 855,
                "codename": "thanh_pho_vinh_long",
                "division_type": "thành phố",
                "short_codename": "vinh_long",
                "wards": [
                    {
                        "name": "Phường 9",
                        "code": 29542,
                        "codename": "phuong_9",
                        "division_type": "phường",
                        "short_codename": "phuong_9"
                    },
                    {
                        "name": "Phường 5",
                        "code": 29545,
                        "codename": "phuong_5",
                        "division_type": "phường",
                        "short_codename": "phuong_5"
                    },
                    {
                        "name": "Phường 1",
                        "code": 29548,
                        "codename": "phuong_1",
                        "division_type": "phường",
                        "short_codename": "phuong_1"
                    },
                    {
                        "name": "Phường 2",
                        "code": 29551,
                        "codename": "phuong_2",
                        "division_type": "phường",
                        "short_codename": "phuong_2"
                    },
                    {
                        "name": "Phường 4",
                        "code": 29554,
                        "codename": "phuong_4",
                        "division_type": "phường",
                        "short_codename": "phuong_4"
                    },
                    {
                        "name": "Phường 3",
                        "code": 29557,
                        "codename": "phuong_3",
                        "division_type": "phường",
                        "short_codename": "phuong_3"
                    },
                    {
                        "name": "Phường 8",
                        "code": 29560,
                        "codename": "phuong_8",
                        "division_type": "phường",
                        "short_codename": "phuong_8"
                    },
                    {
                        "name": "Phường Tân Ngãi",
                        "code": 29563,
                        "codename": "phuong_tan_ngai",
                        "division_type": "phường",
                        "short_codename": "tan_ngai"
                    },
                    {
                        "name": "Phường Tân Hòa",
                        "code": 29566,
                        "codename": "phuong_tan_hoa",
                        "division_type": "phường",
                        "short_codename": "tan_hoa"
                    },
                    {
                        "name": "Phường Tân Hội",
                        "code": 29569,
                        "codename": "phuong_tan_hoi",
                        "division_type": "phường",
                        "short_codename": "tan_hoi"
                    },
                    {
                        "name": "Phường Trường An",
                        "code": 29572,
                        "codename": "phuong_truong_an",
                        "division_type": "phường",
                        "short_codename": "truong_an"
                    }
                ]
            },
            {
                "name": "Huyện Long Hồ",
                "code": 857,
                "codename": "huyen_long_ho",
                "division_type": "huyện",
                "short_codename": "long_ho",
                "wards": [
                    {
                        "name": "Thị trấn Long Hồ",
                        "code": 29575,
                        "codename": "thi_tran_long_ho",
                        "division_type": "thị trấn",
                        "short_codename": "long_ho"
                    },
                    {
                        "name": "Xã Đồng Phú",
                        "code": 29578,
                        "codename": "xa_dong_phu",
                        "division_type": "xã",
                        "short_codename": "dong_phu"
                    },
                    {
                        "name": "Xã Bình Hòa Phước",
                        "code": 29581,
                        "codename": "xa_binh_hoa_phuoc",
                        "division_type": "xã",
                        "short_codename": "binh_hoa_phuoc"
                    },
                    {
                        "name": "Xã Hòa Ninh",
                        "code": 29584,
                        "codename": "xa_hoa_ninh",
                        "division_type": "xã",
                        "short_codename": "hoa_ninh"
                    },
                    {
                        "name": "Xã An Bình",
                        "code": 29587,
                        "codename": "xa_an_binh",
                        "division_type": "xã",
                        "short_codename": "an_binh"
                    },
                    {
                        "name": "Xã Thanh Đức",
                        "code": 29590,
                        "codename": "xa_thanh_duc",
                        "division_type": "xã",
                        "short_codename": "thanh_duc"
                    },
                    {
                        "name": "Xã Tân Hạnh",
                        "code": 29593,
                        "codename": "xa_tan_hanh",
                        "division_type": "xã",
                        "short_codename": "tan_hanh"
                    },
                    {
                        "name": "Xã Phước Hậu",
                        "code": 29596,
                        "codename": "xa_phuoc_hau",
                        "division_type": "xã",
                        "short_codename": "phuoc_hau"
                    },
                    {
                        "name": "Xã Long Phước",
                        "code": 29599,
                        "codename": "xa_long_phuoc",
                        "division_type": "xã",
                        "short_codename": "long_phuoc"
                    },
                    {
                        "name": "Xã Phú Đức",
                        "code": 29602,
                        "codename": "xa_phu_duc",
                        "division_type": "xã",
                        "short_codename": "phu_duc"
                    },
                    {
                        "name": "Xã Lộc Hòa",
                        "code": 29605,
                        "codename": "xa_loc_hoa",
                        "division_type": "xã",
                        "short_codename": "loc_hoa"
                    },
                    {
                        "name": "Xã Long An",
                        "code": 29608,
                        "codename": "xa_long_an",
                        "division_type": "xã",
                        "short_codename": "long_an"
                    },
                    {
                        "name": "Xã Phú Quới",
                        "code": 29611,
                        "codename": "xa_phu_quoi",
                        "division_type": "xã",
                        "short_codename": "phu_quoi"
                    },
                    {
                        "name": "Xã Thạnh Quới",
                        "code": 29614,
                        "codename": "xa_thanh_quoi",
                        "division_type": "xã",
                        "short_codename": "thanh_quoi"
                    },
                    {
                        "name": "Xã Hòa Phú",
                        "code": 29617,
                        "codename": "xa_hoa_phu",
                        "division_type": "xã",
                        "short_codename": "hoa_phu"
                    }
                ]
            },
            {
                "name": "Huyện Mang Thít",
                "code": 858,
                "codename": "huyen_mang_thit",
                "division_type": "huyện",
                "short_codename": "mang_thit",
                "wards": [
                    {
                        "name": "Xã Mỹ An",
                        "code": 29623,
                        "codename": "xa_my_an",
                        "division_type": "xã",
                        "short_codename": "my_an"
                    },
                    {
                        "name": "Xã Mỹ Phước",
                        "code": 29626,
                        "codename": "xa_my_phuoc",
                        "division_type": "xã",
                        "short_codename": "my_phuoc"
                    },
                    {
                        "name": "Xã An Phước",
                        "code": 29629,
                        "codename": "xa_an_phuoc",
                        "division_type": "xã",
                        "short_codename": "an_phuoc"
                    },
                    {
                        "name": "Xã Nhơn Phú",
                        "code": 29632,
                        "codename": "xa_nhon_phu",
                        "division_type": "xã",
                        "short_codename": "nhon_phu"
                    },
                    {
                        "name": "Xã Long Mỹ",
                        "code": 29635,
                        "codename": "xa_long_my",
                        "division_type": "xã",
                        "short_codename": "long_my"
                    },
                    {
                        "name": "Xã Hòa Tịnh",
                        "code": 29638,
                        "codename": "xa_hoa_tinh",
                        "division_type": "xã",
                        "short_codename": "hoa_tinh"
                    },
                    {
                        "name": "Thị trấn Cái Nhum",
                        "code": 29641,
                        "codename": "thi_tran_cai_nhum",
                        "division_type": "thị trấn",
                        "short_codename": "cai_nhum"
                    },
                    {
                        "name": "Xã Bình Phước",
                        "code": 29644,
                        "codename": "xa_binh_phuoc",
                        "division_type": "xã",
                        "short_codename": "binh_phuoc"
                    },
                    {
                        "name": "Xã Chánh An",
                        "code": 29647,
                        "codename": "xa_chanh_an",
                        "division_type": "xã",
                        "short_codename": "chanh_an"
                    },
                    {
                        "name": "Xã Tân An Hội",
                        "code": 29650,
                        "codename": "xa_tan_an_hoi",
                        "division_type": "xã",
                        "short_codename": "tan_an_hoi"
                    },
                    {
                        "name": "Xã Tân Long",
                        "code": 29653,
                        "codename": "xa_tan_long",
                        "division_type": "xã",
                        "short_codename": "tan_long"
                    },
                    {
                        "name": "Xã Tân Long Hội",
                        "code": 29656,
                        "codename": "xa_tan_long_hoi",
                        "division_type": "xã",
                        "short_codename": "tan_long_hoi"
                    }
                ]
            },
            {
                "name": "Huyện Vũng Liêm",
                "code": 859,
                "codename": "huyen_vung_liem",
                "division_type": "huyện",
                "short_codename": "vung_liem",
                "wards": [
                    {
                        "name": "Thị trấn Vũng Liêm",
                        "code": 29659,
                        "codename": "thi_tran_vung_liem",
                        "division_type": "thị trấn",
                        "short_codename": "vung_liem"
                    },
                    {
                        "name": "Xã Tân Quới Trung",
                        "code": 29662,
                        "codename": "xa_tan_quoi_trung",
                        "division_type": "xã",
                        "short_codename": "tan_quoi_trung"
                    },
                    {
                        "name": "Xã Quới Thiện",
                        "code": 29665,
                        "codename": "xa_quoi_thien",
                        "division_type": "xã",
                        "short_codename": "quoi_thien"
                    },
                    {
                        "name": "Xã Quới An",
                        "code": 29668,
                        "codename": "xa_quoi_an",
                        "division_type": "xã",
                        "short_codename": "quoi_an"
                    },
                    {
                        "name": "Xã Trung Chánh",
                        "code": 29671,
                        "codename": "xa_trung_chanh",
                        "division_type": "xã",
                        "short_codename": "trung_chanh"
                    },
                    {
                        "name": "Xã Tân An Luông",
                        "code": 29674,
                        "codename": "xa_tan_an_luong",
                        "division_type": "xã",
                        "short_codename": "tan_an_luong"
                    },
                    {
                        "name": "Xã Thanh Bình",
                        "code": 29677,
                        "codename": "xa_thanh_binh",
                        "division_type": "xã",
                        "short_codename": "thanh_binh"
                    },
                    {
                        "name": "Xã Trung Thành Tây",
                        "code": 29680,
                        "codename": "xa_trung_thanh_tay",
                        "division_type": "xã",
                        "short_codename": "trung_thanh_tay"
                    },
                    {
                        "name": "Xã Trung Hiệp",
                        "code": 29683,
                        "codename": "xa_trung_hiep",
                        "division_type": "xã",
                        "short_codename": "trung_hiep"
                    },
                    {
                        "name": "Xã Hiếu Phụng",
                        "code": 29686,
                        "codename": "xa_hieu_phung",
                        "division_type": "xã",
                        "short_codename": "hieu_phung"
                    },
                    {
                        "name": "Xã Trung Thành Đông",
                        "code": 29689,
                        "codename": "xa_trung_thanh_dong",
                        "division_type": "xã",
                        "short_codename": "trung_thanh_dong"
                    },
                    {
                        "name": "Xã Trung Thành",
                        "code": 29692,
                        "codename": "xa_trung_thanh",
                        "division_type": "xã",
                        "short_codename": "trung_thanh"
                    },
                    {
                        "name": "Xã Trung Hiếu",
                        "code": 29695,
                        "codename": "xa_trung_hieu",
                        "division_type": "xã",
                        "short_codename": "trung_hieu"
                    },
                    {
                        "name": "Xã Trung Ngãi",
                        "code": 29698,
                        "codename": "xa_trung_ngai",
                        "division_type": "xã",
                        "short_codename": "trung_ngai"
                    },
                    {
                        "name": "Xã Hiếu Thuận",
                        "code": 29701,
                        "codename": "xa_hieu_thuan",
                        "division_type": "xã",
                        "short_codename": "hieu_thuan"
                    },
                    {
                        "name": "Xã Trung Nghĩa",
                        "code": 29704,
                        "codename": "xa_trung_nghia",
                        "division_type": "xã",
                        "short_codename": "trung_nghia"
                    },
                    {
                        "name": "Xã Trung An",
                        "code": 29707,
                        "codename": "xa_trung_an",
                        "division_type": "xã",
                        "short_codename": "trung_an"
                    },
                    {
                        "name": "Xã Hiếu Nhơn",
                        "code": 29710,
                        "codename": "xa_hieu_nhon",
                        "division_type": "xã",
                        "short_codename": "hieu_nhon"
                    },
                    {
                        "name": "Xã Hiếu Thành",
                        "code": 29713,
                        "codename": "xa_hieu_thanh",
                        "division_type": "xã",
                        "short_codename": "hieu_thanh"
                    },
                    {
                        "name": "Xã Hiếu Nghĩa",
                        "code": 29716,
                        "codename": "xa_hieu_nghia",
                        "division_type": "xã",
                        "short_codename": "hieu_nghia"
                    }
                ]
            },
            {
                "name": "Huyện Tam Bình",
                "code": 860,
                "codename": "huyen_tam_binh",
                "division_type": "huyện",
                "short_codename": "tam_binh",
                "wards": [
                    {
                        "name": "Thị trấn Tam Bình",
                        "code": 29719,
                        "codename": "thi_tran_tam_binh",
                        "division_type": "thị trấn",
                        "short_codename": "tam_binh"
                    },
                    {
                        "name": "Xã Tân Lộc",
                        "code": 29722,
                        "codename": "xa_tan_loc",
                        "division_type": "xã",
                        "short_codename": "tan_loc"
                    },
                    {
                        "name": "Xã Phú Thịnh",
                        "code": 29725,
                        "codename": "xa_phu_thinh",
                        "division_type": "xã",
                        "short_codename": "phu_thinh"
                    },
                    {
                        "name": "Xã Hậu Lộc",
                        "code": 29728,
                        "codename": "xa_hau_loc",
                        "division_type": "xã",
                        "short_codename": "hau_loc"
                    },
                    {
                        "name": "Xã Hòa Thạnh",
                        "code": 29731,
                        "codename": "xa_hoa_thanh",
                        "division_type": "xã",
                        "short_codename": "hoa_thanh"
                    },
                    {
                        "name": "Xã Hoà Lộc",
                        "code": 29734,
                        "codename": "xa_hoa_loc",
                        "division_type": "xã",
                        "short_codename": "hoa_loc"
                    },
                    {
                        "name": "Xã Phú Lộc",
                        "code": 29737,
                        "codename": "xa_phu_loc",
                        "division_type": "xã",
                        "short_codename": "phu_loc"
                    },
                    {
                        "name": "Xã Song Phú",
                        "code": 29740,
                        "codename": "xa_song_phu",
                        "division_type": "xã",
                        "short_codename": "song_phu"
                    },
                    {
                        "name": "Xã Hòa Hiệp",
                        "code": 29743,
                        "codename": "xa_hoa_hiep",
                        "division_type": "xã",
                        "short_codename": "hoa_hiep"
                    },
                    {
                        "name": "Xã Mỹ Lộc",
                        "code": 29746,
                        "codename": "xa_my_loc",
                        "division_type": "xã",
                        "short_codename": "my_loc"
                    },
                    {
                        "name": "Xã Tân Phú",
                        "code": 29749,
                        "codename": "xa_tan_phu",
                        "division_type": "xã",
                        "short_codename": "tan_phu"
                    },
                    {
                        "name": "Xã Long Phú",
                        "code": 29752,
                        "codename": "xa_long_phu",
                        "division_type": "xã",
                        "short_codename": "long_phu"
                    },
                    {
                        "name": "Xã Mỹ Thạnh Trung",
                        "code": 29755,
                        "codename": "xa_my_thanh_trung",
                        "division_type": "xã",
                        "short_codename": "my_thanh_trung"
                    },
                    {
                        "name": "Xã Tường Lộc",
                        "code": 29758,
                        "codename": "xa_tuong_loc",
                        "division_type": "xã",
                        "short_codename": "tuong_loc"
                    },
                    {
                        "name": "Xã Loan Mỹ",
                        "code": 29761,
                        "codename": "xa_loan_my",
                        "division_type": "xã",
                        "short_codename": "loan_my"
                    },
                    {
                        "name": "Xã Ngãi Tứ",
                        "code": 29764,
                        "codename": "xa_ngai_tu",
                        "division_type": "xã",
                        "short_codename": "ngai_tu"
                    },
                    {
                        "name": "Xã Bình Ninh",
                        "code": 29767,
                        "codename": "xa_binh_ninh",
                        "division_type": "xã",
                        "short_codename": "binh_ninh"
                    }
                ]
            },
            {
                "name": "Thị xã Bình Minh",
                "code": 861,
                "codename": "thi_xa_binh_minh",
                "division_type": "thị xã",
                "short_codename": "binh_minh",
                "wards": [
                    {
                        "name": "Phường Cái Vồn",
                        "code": 29770,
                        "codename": "phuong_cai_von",
                        "division_type": "phường",
                        "short_codename": "cai_von"
                    },
                    {
                        "name": "Phường Thành Phước",
                        "code": 29771,
                        "codename": "phuong_thanh_phuoc",
                        "division_type": "phường",
                        "short_codename": "thanh_phuoc"
                    },
                    {
                        "name": "Xã Thuận An",
                        "code": 29806,
                        "codename": "xa_thuan_an",
                        "division_type": "xã",
                        "short_codename": "thuan_an"
                    },
                    {
                        "name": "Xã Đông Thạnh",
                        "code": 29809,
                        "codename": "xa_dong_thanh",
                        "division_type": "xã",
                        "short_codename": "xa_dong_thanh"
                    },
                    {
                        "name": "Xã Đông Bình",
                        "code": 29812,
                        "codename": "xa_dong_binh",
                        "division_type": "xã",
                        "short_codename": "dong_binh"
                    },
                    {
                        "name": "Phường Đông Thuận",
                        "code": 29813,
                        "codename": "phuong_dong_thuan",
                        "division_type": "phường",
                        "short_codename": "dong_thuan"
                    },
                    {
                        "name": "Xã Mỹ Hòa",
                        "code": 29815,
                        "codename": "xa_my_hoa",
                        "division_type": "xã",
                        "short_codename": "my_hoa"
                    },
                    {
                        "name": "Xã Đông Thành",
                        "code": 29818,
                        "codename": "xa_dong_thanh",
                        "division_type": "xã",
                        "short_codename": "xa_dong_thanh"
                    }
                ]
            },
            {
                "name": "Huyện Trà Ôn",
                "code": 862,
                "codename": "huyen_tra_on",
                "division_type": "huyện",
                "short_codename": "tra_on",
                "wards": [
                    {
                        "name": "Thị trấn Trà Ôn",
                        "code": 29821,
                        "codename": "thi_tran_tra_on",
                        "division_type": "thị trấn",
                        "short_codename": "tra_on"
                    },
                    {
                        "name": "Xã Xuân Hiệp",
                        "code": 29824,
                        "codename": "xa_xuan_hiep",
                        "division_type": "xã",
                        "short_codename": "xuan_hiep"
                    },
                    {
                        "name": "Xã Nhơn Bình",
                        "code": 29827,
                        "codename": "xa_nhon_binh",
                        "division_type": "xã",
                        "short_codename": "nhon_binh"
                    },
                    {
                        "name": "Xã Hòa Bình",
                        "code": 29830,
                        "codename": "xa_hoa_binh",
                        "division_type": "xã",
                        "short_codename": "hoa_binh"
                    },
                    {
                        "name": "Xã Thới Hòa",
                        "code": 29833,
                        "codename": "xa_thoi_hoa",
                        "division_type": "xã",
                        "short_codename": "thoi_hoa"
                    },
                    {
                        "name": "Xã Trà Côn",
                        "code": 29836,
                        "codename": "xa_tra_con",
                        "division_type": "xã",
                        "short_codename": "tra_con"
                    },
                    {
                        "name": "Xã Tân Mỹ",
                        "code": 29839,
                        "codename": "xa_tan_my",
                        "division_type": "xã",
                        "short_codename": "tan_my"
                    },
                    {
                        "name": "Xã Hựu Thành",
                        "code": 29842,
                        "codename": "xa_huu_thanh",
                        "division_type": "xã",
                        "short_codename": "huu_thanh"
                    },
                    {
                        "name": "Xã Vĩnh Xuân",
                        "code": 29845,
                        "codename": "xa_vinh_xuan",
                        "division_type": "xã",
                        "short_codename": "vinh_xuan"
                    },
                    {
                        "name": "Xã Thuận Thới",
                        "code": 29848,
                        "codename": "xa_thuan_thoi",
                        "division_type": "xã",
                        "short_codename": "thuan_thoi"
                    },
                    {
                        "name": "Xã Phú Thành",
                        "code": 29851,
                        "codename": "xa_phu_thanh",
                        "division_type": "xã",
                        "short_codename": "phu_thanh"
                    },
                    {
                        "name": "Xã Thiện Mỹ",
                        "code": 29854,
                        "codename": "xa_thien_my",
                        "division_type": "xã",
                        "short_codename": "thien_my"
                    },
                    {
                        "name": "Xã Lục Sỹ Thành",
                        "code": 29857,
                        "codename": "xa_luc_sy_thanh",
                        "division_type": "xã",
                        "short_codename": "luc_sy_thanh"
                    },
                    {
                        "name": "Xã Tích Thiện",
                        "code": 29860,
                        "codename": "xa_tich_thien",
                        "division_type": "xã",
                        "short_codename": "tich_thien"
                    }
                ]
            },
            {
                "name": "Huyện Bình Tân",
                "code": 863,
                "codename": "huyen_binh_tan",
                "division_type": "huyện",
                "short_codename": "binh_tan",
                "wards": [
                    {
                        "name": "Xã Tân Hưng",
                        "code": 29773,
                        "codename": "xa_tan_hung",
                        "division_type": "xã",
                        "short_codename": "tan_hung"
                    },
                    {
                        "name": "Xã Tân Thành",
                        "code": 29776,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Xã Thành Trung",
                        "code": 29779,
                        "codename": "xa_thanh_trung",
                        "division_type": "xã",
                        "short_codename": "thanh_trung"
                    },
                    {
                        "name": "Xã Tân An Thạnh",
                        "code": 29782,
                        "codename": "xa_tan_an_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_an_thanh"
                    },
                    {
                        "name": "Xã Tân Lược",
                        "code": 29785,
                        "codename": "xa_tan_luoc",
                        "division_type": "xã",
                        "short_codename": "tan_luoc"
                    },
                    {
                        "name": "Xã Nguyễn Văn Thảnh",
                        "code": 29788,
                        "codename": "xa_nguyen_van_thanh",
                        "division_type": "xã",
                        "short_codename": "nguyen_van_thanh"
                    },
                    {
                        "name": "Xã Thành Lợi",
                        "code": 29791,
                        "codename": "xa_thanh_loi",
                        "division_type": "xã",
                        "short_codename": "thanh_loi"
                    },
                    {
                        "name": "Xã Mỹ Thuận",
                        "code": 29794,
                        "codename": "xa_my_thuan",
                        "division_type": "xã",
                        "short_codename": "my_thuan"
                    },
                    {
                        "name": "Xã Tân Bình",
                        "code": 29797,
                        "codename": "xa_tan_binh",
                        "division_type": "xã",
                        "short_codename": "tan_binh"
                    },
                    {
                        "name": "Thị trấn Tân Quới",
                        "code": 29800,
                        "codename": "thi_tran_tan_quoi",
                        "division_type": "thị trấn",
                        "short_codename": "tan_quoi"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Đồng Tháp",
        "code": 87,
        "codename": "tinh_dong_thap",
        "division_type": "tỉnh",
        "phone_code": 277,
        "districts": [
            {
                "name": "Thành phố Cao Lãnh",
                "code": 866,
                "codename": "thanh_pho_cao_lanh",
                "division_type": "thành phố",
                "short_codename": "cao_lanh",
                "wards": [
                    {
                        "name": "Phường 11",
                        "code": 29863,
                        "codename": "phuong_11",
                        "division_type": "phường",
                        "short_codename": "phuong_11"
                    },
                    {
                        "name": "Phường 1",
                        "code": 29866,
                        "codename": "phuong_1",
                        "division_type": "phường",
                        "short_codename": "phuong_1"
                    },
                    {
                        "name": "Phường 2",
                        "code": 29869,
                        "codename": "phuong_2",
                        "division_type": "phường",
                        "short_codename": "phuong_2"
                    },
                    {
                        "name": "Phường 4",
                        "code": 29872,
                        "codename": "phuong_4",
                        "division_type": "phường",
                        "short_codename": "phuong_4"
                    },
                    {
                        "name": "Phường 3",
                        "code": 29875,
                        "codename": "phuong_3",
                        "division_type": "phường",
                        "short_codename": "phuong_3"
                    },
                    {
                        "name": "Phường 6",
                        "code": 29878,
                        "codename": "phuong_6",
                        "division_type": "phường",
                        "short_codename": "phuong_6"
                    },
                    {
                        "name": "Xã Mỹ Ngãi",
                        "code": 29881,
                        "codename": "xa_my_ngai",
                        "division_type": "xã",
                        "short_codename": "my_ngai"
                    },
                    {
                        "name": "Xã Mỹ Tân",
                        "code": 29884,
                        "codename": "xa_my_tan",
                        "division_type": "xã",
                        "short_codename": "my_tan"
                    },
                    {
                        "name": "Xã Mỹ Trà",
                        "code": 29887,
                        "codename": "xa_my_tra",
                        "division_type": "xã",
                        "short_codename": "my_tra"
                    },
                    {
                        "name": "Phường Mỹ Phú",
                        "code": 29888,
                        "codename": "phuong_my_phu",
                        "division_type": "phường",
                        "short_codename": "my_phu"
                    },
                    {
                        "name": "Xã Tân Thuận Tây",
                        "code": 29890,
                        "codename": "xa_tan_thuan_tay",
                        "division_type": "xã",
                        "short_codename": "tan_thuan_tay"
                    },
                    {
                        "name": "Phường Hoà Thuận",
                        "code": 29892,
                        "codename": "phuong_hoa_thuan",
                        "division_type": "phường",
                        "short_codename": "hoa_thuan"
                    },
                    {
                        "name": "Xã Hòa An",
                        "code": 29893,
                        "codename": "xa_hoa_an",
                        "division_type": "xã",
                        "short_codename": "hoa_an"
                    },
                    {
                        "name": "Xã Tân Thuận Đông",
                        "code": 29896,
                        "codename": "xa_tan_thuan_dong",
                        "division_type": "xã",
                        "short_codename": "tan_thuan_dong"
                    },
                    {
                        "name": "Xã Tịnh Thới",
                        "code": 29899,
                        "codename": "xa_tinh_thoi",
                        "division_type": "xã",
                        "short_codename": "tinh_thoi"
                    }
                ]
            },
            {
                "name": "Thành phố Sa Đéc",
                "code": 867,
                "codename": "thanh_pho_sa_dec",
                "division_type": "thành phố",
                "short_codename": "sa_dec",
                "wards": [
                    {
                        "name": "Phường 3",
                        "code": 29902,
                        "codename": "phuong_3",
                        "division_type": "phường",
                        "short_codename": "phuong_3"
                    },
                    {
                        "name": "Phường 1",
                        "code": 29905,
                        "codename": "phuong_1",
                        "division_type": "phường",
                        "short_codename": "phuong_1"
                    },
                    {
                        "name": "Phường 4",
                        "code": 29908,
                        "codename": "phuong_4",
                        "division_type": "phường",
                        "short_codename": "phuong_4"
                    },
                    {
                        "name": "Phường 2",
                        "code": 29911,
                        "codename": "phuong_2",
                        "division_type": "phường",
                        "short_codename": "phuong_2"
                    },
                    {
                        "name": "Xã Tân Khánh Đông",
                        "code": 29914,
                        "codename": "xa_tan_khanh_dong",
                        "division_type": "xã",
                        "short_codename": "tan_khanh_dong"
                    },
                    {
                        "name": "Phường Tân Quy Đông",
                        "code": 29917,
                        "codename": "phuong_tan_quy_dong",
                        "division_type": "phường",
                        "short_codename": "tan_quy_dong"
                    },
                    {
                        "name": "Phường An Hoà",
                        "code": 29919,
                        "codename": "phuong_an_hoa",
                        "division_type": "phường",
                        "short_codename": "an_hoa"
                    },
                    {
                        "name": "Xã Tân Quy Tây",
                        "code": 29920,
                        "codename": "xa_tan_quy_tay",
                        "division_type": "xã",
                        "short_codename": "tan_quy_tay"
                    },
                    {
                        "name": "Xã Tân Phú Đông",
                        "code": 29923,
                        "codename": "xa_tan_phu_dong",
                        "division_type": "xã",
                        "short_codename": "tan_phu_dong"
                    }
                ]
            },
            {
                "name": "Thành phố Hồng Ngự",
                "code": 868,
                "codename": "thanh_pho_hong_ngu",
                "division_type": "thành phố",
                "short_codename": "hong_ngu",
                "wards": [
                    {
                        "name": "Phường An Lộc",
                        "code": 29954,
                        "codename": "phuong_an_loc",
                        "division_type": "phường",
                        "short_codename": "an_loc"
                    },
                    {
                        "name": "Phường An Thạnh",
                        "code": 29955,
                        "codename": "phuong_an_thanh",
                        "division_type": "phường",
                        "short_codename": "an_thanh"
                    },
                    {
                        "name": "Xã Bình Thạnh",
                        "code": 29959,
                        "codename": "xa_binh_thanh",
                        "division_type": "xã",
                        "short_codename": "binh_thanh"
                    },
                    {
                        "name": "Xã Tân Hội",
                        "code": 29965,
                        "codename": "xa_tan_hoi",
                        "division_type": "xã",
                        "short_codename": "tan_hoi"
                    },
                    {
                        "name": "Phường An Lạc",
                        "code": 29978,
                        "codename": "phuong_an_lac",
                        "division_type": "phường",
                        "short_codename": "an_lac"
                    },
                    {
                        "name": "Phường An Bình B",
                        "code": 29986,
                        "codename": "phuong_an_binh_b",
                        "division_type": "phường",
                        "short_codename": "an_binh_b"
                    },
                    {
                        "name": "Phường An Bình A",
                        "code": 29989,
                        "codename": "phuong_an_binh_a",
                        "division_type": "phường",
                        "short_codename": "an_binh_a"
                    }
                ]
            },
            {
                "name": "Huyện Tân Hồng",
                "code": 869,
                "codename": "huyen_tan_hong",
                "division_type": "huyện",
                "short_codename": "tan_hong",
                "wards": [
                    {
                        "name": "Thị trấn Sa Rài",
                        "code": 29926,
                        "codename": "thi_tran_sa_rai",
                        "division_type": "thị trấn",
                        "short_codename": "sa_rai"
                    },
                    {
                        "name": "Xã Tân Hộ Cơ",
                        "code": 29929,
                        "codename": "xa_tan_ho_co",
                        "division_type": "xã",
                        "short_codename": "tan_ho_co"
                    },
                    {
                        "name": "Xã Thông Bình",
                        "code": 29932,
                        "codename": "xa_thong_binh",
                        "division_type": "xã",
                        "short_codename": "thong_binh"
                    },
                    {
                        "name": "Xã Bình Phú",
                        "code": 29935,
                        "codename": "xa_binh_phu",
                        "division_type": "xã",
                        "short_codename": "binh_phu"
                    },
                    {
                        "name": "Xã Tân Thành A",
                        "code": 29938,
                        "codename": "xa_tan_thanh_a",
                        "division_type": "xã",
                        "short_codename": "tan_thanh_a"
                    },
                    {
                        "name": "Xã Tân Thành B",
                        "code": 29941,
                        "codename": "xa_tan_thanh_b",
                        "division_type": "xã",
                        "short_codename": "tan_thanh_b"
                    },
                    {
                        "name": "Xã Tân Phước",
                        "code": 29944,
                        "codename": "xa_tan_phuoc",
                        "division_type": "xã",
                        "short_codename": "tan_phuoc"
                    },
                    {
                        "name": "Xã Tân Công Chí",
                        "code": 29947,
                        "codename": "xa_tan_cong_chi",
                        "division_type": "xã",
                        "short_codename": "tan_cong_chi"
                    },
                    {
                        "name": "Xã An Phước",
                        "code": 29950,
                        "codename": "xa_an_phuoc",
                        "division_type": "xã",
                        "short_codename": "an_phuoc"
                    }
                ]
            },
            {
                "name": "Huyện Hồng Ngự",
                "code": 870,
                "codename": "huyen_hong_ngu",
                "division_type": "huyện",
                "short_codename": "huyen_hong_ngu",
                "wards": [
                    {
                        "name": "Xã Thường Phước 1",
                        "code": 29956,
                        "codename": "xa_thuong_phuoc_1",
                        "division_type": "xã",
                        "short_codename": "thuong_phuoc_1"
                    },
                    {
                        "name": "Xã Thường Thới Hậu A",
                        "code": 29962,
                        "codename": "xa_thuong_thoi_hau_a",
                        "division_type": "xã",
                        "short_codename": "thuong_thoi_hau_a"
                    },
                    {
                        "name": "Thị trấn Thường Thới Tiền",
                        "code": 29971,
                        "codename": "thi_tran_thuong_thoi_tien",
                        "division_type": "thị trấn",
                        "short_codename": "thuong_thoi_tien"
                    },
                    {
                        "name": "Xã Thường Phước 2",
                        "code": 29974,
                        "codename": "xa_thuong_phuoc_2",
                        "division_type": "xã",
                        "short_codename": "thuong_phuoc_2"
                    },
                    {
                        "name": "Xã Thường Lạc",
                        "code": 29977,
                        "codename": "xa_thuong_lac",
                        "division_type": "xã",
                        "short_codename": "thuong_lac"
                    },
                    {
                        "name": "Xã Long Khánh A",
                        "code": 29980,
                        "codename": "xa_long_khanh_a",
                        "division_type": "xã",
                        "short_codename": "long_khanh_a"
                    },
                    {
                        "name": "Xã Long Khánh B",
                        "code": 29983,
                        "codename": "xa_long_khanh_b",
                        "division_type": "xã",
                        "short_codename": "long_khanh_b"
                    },
                    {
                        "name": "Xã Long Thuận",
                        "code": 29992,
                        "codename": "xa_long_thuan",
                        "division_type": "xã",
                        "short_codename": "long_thuan"
                    },
                    {
                        "name": "Xã Phú Thuận B",
                        "code": 29995,
                        "codename": "xa_phu_thuan_b",
                        "division_type": "xã",
                        "short_codename": "phu_thuan_b"
                    },
                    {
                        "name": "Xã Phú Thuận A",
                        "code": 29998,
                        "codename": "xa_phu_thuan_a",
                        "division_type": "xã",
                        "short_codename": "phu_thuan_a"
                    }
                ]
            },
            {
                "name": "Huyện Tam Nông",
                "code": 871,
                "codename": "huyen_tam_nong",
                "division_type": "huyện",
                "short_codename": "tam_nong",
                "wards": [
                    {
                        "name": "Thị trấn Tràm Chim",
                        "code": 30001,
                        "codename": "thi_tran_tram_chim",
                        "division_type": "thị trấn",
                        "short_codename": "tram_chim"
                    },
                    {
                        "name": "Xã Hoà Bình",
                        "code": 30004,
                        "codename": "xa_hoa_binh",
                        "division_type": "xã",
                        "short_codename": "hoa_binh"
                    },
                    {
                        "name": "Xã Tân Công Sính",
                        "code": 30007,
                        "codename": "xa_tan_cong_sinh",
                        "division_type": "xã",
                        "short_codename": "tan_cong_sinh"
                    },
                    {
                        "name": "Xã Phú Hiệp",
                        "code": 30010,
                        "codename": "xa_phu_hiep",
                        "division_type": "xã",
                        "short_codename": "phu_hiep"
                    },
                    {
                        "name": "Xã Phú Đức",
                        "code": 30013,
                        "codename": "xa_phu_duc",
                        "division_type": "xã",
                        "short_codename": "phu_duc"
                    },
                    {
                        "name": "Xã Phú Thành B",
                        "code": 30016,
                        "codename": "xa_phu_thanh_b",
                        "division_type": "xã",
                        "short_codename": "phu_thanh_b"
                    },
                    {
                        "name": "Xã An Hòa",
                        "code": 30019,
                        "codename": "xa_an_hoa",
                        "division_type": "xã",
                        "short_codename": "an_hoa"
                    },
                    {
                        "name": "Xã An Long",
                        "code": 30022,
                        "codename": "xa_an_long",
                        "division_type": "xã",
                        "short_codename": "an_long"
                    },
                    {
                        "name": "Xã Phú Cường",
                        "code": 30025,
                        "codename": "xa_phu_cuong",
                        "division_type": "xã",
                        "short_codename": "phu_cuong"
                    },
                    {
                        "name": "Xã Phú Ninh",
                        "code": 30028,
                        "codename": "xa_phu_ninh",
                        "division_type": "xã",
                        "short_codename": "phu_ninh"
                    },
                    {
                        "name": "Xã Phú Thọ",
                        "code": 30031,
                        "codename": "xa_phu_tho",
                        "division_type": "xã",
                        "short_codename": "phu_tho"
                    },
                    {
                        "name": "Xã Phú Thành A",
                        "code": 30034,
                        "codename": "xa_phu_thanh_a",
                        "division_type": "xã",
                        "short_codename": "phu_thanh_a"
                    }
                ]
            },
            {
                "name": "Huyện Tháp Mười",
                "code": 872,
                "codename": "huyen_thap_muoi",
                "division_type": "huyện",
                "short_codename": "thap_muoi",
                "wards": [
                    {
                        "name": "Thị trấn Mỹ An",
                        "code": 30037,
                        "codename": "thi_tran_my_an",
                        "division_type": "thị trấn",
                        "short_codename": "my_an"
                    },
                    {
                        "name": "Xã Thạnh Lợi",
                        "code": 30040,
                        "codename": "xa_thanh_loi",
                        "division_type": "xã",
                        "short_codename": "thanh_loi"
                    },
                    {
                        "name": "Xã Hưng Thạnh",
                        "code": 30043,
                        "codename": "xa_hung_thanh",
                        "division_type": "xã",
                        "short_codename": "hung_thanh"
                    },
                    {
                        "name": "Xã Trường Xuân",
                        "code": 30046,
                        "codename": "xa_truong_xuan",
                        "division_type": "xã",
                        "short_codename": "truong_xuan"
                    },
                    {
                        "name": "Xã Tân Kiều",
                        "code": 30049,
                        "codename": "xa_tan_kieu",
                        "division_type": "xã",
                        "short_codename": "tan_kieu"
                    },
                    {
                        "name": "Xã Mỹ Hòa",
                        "code": 30052,
                        "codename": "xa_my_hoa",
                        "division_type": "xã",
                        "short_codename": "my_hoa"
                    },
                    {
                        "name": "Xã Mỹ Quý",
                        "code": 30055,
                        "codename": "xa_my_quy",
                        "division_type": "xã",
                        "short_codename": "my_quy"
                    },
                    {
                        "name": "Xã Mỹ Đông",
                        "code": 30058,
                        "codename": "xa_my_dong",
                        "division_type": "xã",
                        "short_codename": "my_dong"
                    },
                    {
                        "name": "Xã Đốc Binh Kiều",
                        "code": 30061,
                        "codename": "xa_doc_binh_kieu",
                        "division_type": "xã",
                        "short_codename": "doc_binh_kieu"
                    },
                    {
                        "name": "Xã Mỹ An",
                        "code": 30064,
                        "codename": "xa_my_an",
                        "division_type": "xã",
                        "short_codename": "xa_my_an"
                    },
                    {
                        "name": "Xã Phú Điền",
                        "code": 30067,
                        "codename": "xa_phu_dien",
                        "division_type": "xã",
                        "short_codename": "phu_dien"
                    },
                    {
                        "name": "Xã Láng Biển",
                        "code": 30070,
                        "codename": "xa_lang_bien",
                        "division_type": "xã",
                        "short_codename": "lang_bien"
                    },
                    {
                        "name": "Xã Thanh Mỹ",
                        "code": 30073,
                        "codename": "xa_thanh_my",
                        "division_type": "xã",
                        "short_codename": "thanh_my"
                    }
                ]
            },
            {
                "name": "Huyện Cao Lãnh",
                "code": 873,
                "codename": "huyen_cao_lanh",
                "division_type": "huyện",
                "short_codename": "huyen_cao_lanh",
                "wards": [
                    {
                        "name": "Thị trấn Mỹ Thọ",
                        "code": 30076,
                        "codename": "thi_tran_my_tho",
                        "division_type": "thị trấn",
                        "short_codename": "my_tho"
                    },
                    {
                        "name": "Xã Gáo Giồng",
                        "code": 30079,
                        "codename": "xa_gao_giong",
                        "division_type": "xã",
                        "short_codename": "gao_giong"
                    },
                    {
                        "name": "Xã Phương Thịnh",
                        "code": 30082,
                        "codename": "xa_phuong_thinh",
                        "division_type": "xã",
                        "short_codename": "phuong_thinh"
                    },
                    {
                        "name": "Xã Ba Sao",
                        "code": 30085,
                        "codename": "xa_ba_sao",
                        "division_type": "xã",
                        "short_codename": "ba_sao"
                    },
                    {
                        "name": "Xã Phong Mỹ",
                        "code": 30088,
                        "codename": "xa_phong_my",
                        "division_type": "xã",
                        "short_codename": "phong_my"
                    },
                    {
                        "name": "Xã Tân Nghĩa",
                        "code": 30091,
                        "codename": "xa_tan_nghia",
                        "division_type": "xã",
                        "short_codename": "tan_nghia"
                    },
                    {
                        "name": "Xã Phương Trà",
                        "code": 30094,
                        "codename": "xa_phuong_tra",
                        "division_type": "xã",
                        "short_codename": "phuong_tra"
                    },
                    {
                        "name": "Xã Nhị Mỹ",
                        "code": 30097,
                        "codename": "xa_nhi_my",
                        "division_type": "xã",
                        "short_codename": "nhi_my"
                    },
                    {
                        "name": "Xã Mỹ Thọ",
                        "code": 30100,
                        "codename": "xa_my_tho",
                        "division_type": "xã",
                        "short_codename": "xa_my_tho"
                    },
                    {
                        "name": "Xã Tân Hội Trung",
                        "code": 30103,
                        "codename": "xa_tan_hoi_trung",
                        "division_type": "xã",
                        "short_codename": "tan_hoi_trung"
                    },
                    {
                        "name": "Xã An Bình",
                        "code": 30106,
                        "codename": "xa_an_binh",
                        "division_type": "xã",
                        "short_codename": "an_binh"
                    },
                    {
                        "name": "Xã Mỹ Hội",
                        "code": 30109,
                        "codename": "xa_my_hoi",
                        "division_type": "xã",
                        "short_codename": "my_hoi"
                    },
                    {
                        "name": "Xã Mỹ Hiệp",
                        "code": 30112,
                        "codename": "xa_my_hiep",
                        "division_type": "xã",
                        "short_codename": "my_hiep"
                    },
                    {
                        "name": "Xã Mỹ Long",
                        "code": 30115,
                        "codename": "xa_my_long",
                        "division_type": "xã",
                        "short_codename": "my_long"
                    },
                    {
                        "name": "Xã Bình Hàng Trung",
                        "code": 30118,
                        "codename": "xa_binh_hang_trung",
                        "division_type": "xã",
                        "short_codename": "binh_hang_trung"
                    },
                    {
                        "name": "Xã Mỹ Xương",
                        "code": 30121,
                        "codename": "xa_my_xuong",
                        "division_type": "xã",
                        "short_codename": "my_xuong"
                    },
                    {
                        "name": "Xã Bình Hàng Tây",
                        "code": 30124,
                        "codename": "xa_binh_hang_tay",
                        "division_type": "xã",
                        "short_codename": "binh_hang_tay"
                    },
                    {
                        "name": "Xã Bình Thạnh",
                        "code": 30127,
                        "codename": "xa_binh_thanh",
                        "division_type": "xã",
                        "short_codename": "binh_thanh"
                    }
                ]
            },
            {
                "name": "Huyện Thanh Bình",
                "code": 874,
                "codename": "huyen_thanh_binh",
                "division_type": "huyện",
                "short_codename": "thanh_binh",
                "wards": [
                    {
                        "name": "Thị trấn Thanh Bình",
                        "code": 30130,
                        "codename": "thi_tran_thanh_binh",
                        "division_type": "thị trấn",
                        "short_codename": "thanh_binh"
                    },
                    {
                        "name": "Xã Tân Quới",
                        "code": 30133,
                        "codename": "xa_tan_quoi",
                        "division_type": "xã",
                        "short_codename": "tan_quoi"
                    },
                    {
                        "name": "Xã Tân Hòa",
                        "code": 30136,
                        "codename": "xa_tan_hoa",
                        "division_type": "xã",
                        "short_codename": "tan_hoa"
                    },
                    {
                        "name": "Xã An Phong",
                        "code": 30139,
                        "codename": "xa_an_phong",
                        "division_type": "xã",
                        "short_codename": "an_phong"
                    },
                    {
                        "name": "Xã Phú Lợi",
                        "code": 30142,
                        "codename": "xa_phu_loi",
                        "division_type": "xã",
                        "short_codename": "phu_loi"
                    },
                    {
                        "name": "Xã Tân Mỹ",
                        "code": 30145,
                        "codename": "xa_tan_my",
                        "division_type": "xã",
                        "short_codename": "tan_my"
                    },
                    {
                        "name": "Xã Bình Tấn",
                        "code": 30148,
                        "codename": "xa_binh_tan",
                        "division_type": "xã",
                        "short_codename": "binh_tan"
                    },
                    {
                        "name": "Xã Tân Huề",
                        "code": 30151,
                        "codename": "xa_tan_hue",
                        "division_type": "xã",
                        "short_codename": "tan_hue"
                    },
                    {
                        "name": "Xã Tân Bình",
                        "code": 30154,
                        "codename": "xa_tan_binh",
                        "division_type": "xã",
                        "short_codename": "tan_binh"
                    },
                    {
                        "name": "Xã Tân Thạnh",
                        "code": 30157,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Xã Tân Phú",
                        "code": 30160,
                        "codename": "xa_tan_phu",
                        "division_type": "xã",
                        "short_codename": "tan_phu"
                    },
                    {
                        "name": "Xã Bình Thành",
                        "code": 30163,
                        "codename": "xa_binh_thanh",
                        "division_type": "xã",
                        "short_codename": "binh_thanh"
                    },
                    {
                        "name": "Xã Tân Long",
                        "code": 30166,
                        "codename": "xa_tan_long",
                        "division_type": "xã",
                        "short_codename": "tan_long"
                    }
                ]
            },
            {
                "name": "Huyện Lấp Vò",
                "code": 875,
                "codename": "huyen_lap_vo",
                "division_type": "huyện",
                "short_codename": "lap_vo",
                "wards": [
                    {
                        "name": "Thị trấn Lấp Vò",
                        "code": 30169,
                        "codename": "thi_tran_lap_vo",
                        "division_type": "thị trấn",
                        "short_codename": "lap_vo"
                    },
                    {
                        "name": "Xã Mỹ An Hưng A",
                        "code": 30172,
                        "codename": "xa_my_an_hung_a",
                        "division_type": "xã",
                        "short_codename": "my_an_hung_a"
                    },
                    {
                        "name": "Xã Tân Mỹ",
                        "code": 30175,
                        "codename": "xa_tan_my",
                        "division_type": "xã",
                        "short_codename": "tan_my"
                    },
                    {
                        "name": "Xã Mỹ An Hưng B",
                        "code": 30178,
                        "codename": "xa_my_an_hung_b",
                        "division_type": "xã",
                        "short_codename": "my_an_hung_b"
                    },
                    {
                        "name": "Xã Tân Khánh Trung",
                        "code": 30181,
                        "codename": "xa_tan_khanh_trung",
                        "division_type": "xã",
                        "short_codename": "tan_khanh_trung"
                    },
                    {
                        "name": "Xã Long Hưng A",
                        "code": 30184,
                        "codename": "xa_long_hung_a",
                        "division_type": "xã",
                        "short_codename": "long_hung_a"
                    },
                    {
                        "name": "Xã Vĩnh Thạnh",
                        "code": 30187,
                        "codename": "xa_vinh_thanh",
                        "division_type": "xã",
                        "short_codename": "vinh_thanh"
                    },
                    {
                        "name": "Xã Long Hưng B",
                        "code": 30190,
                        "codename": "xa_long_hung_b",
                        "division_type": "xã",
                        "short_codename": "long_hung_b"
                    },
                    {
                        "name": "Xã Bình Thành",
                        "code": 30193,
                        "codename": "xa_binh_thanh",
                        "division_type": "xã",
                        "short_codename": "binh_thanh"
                    },
                    {
                        "name": "Xã Định An",
                        "code": 30196,
                        "codename": "xa_dinh_an",
                        "division_type": "xã",
                        "short_codename": "dinh_an"
                    },
                    {
                        "name": "Xã Định Yên",
                        "code": 30199,
                        "codename": "xa_dinh_yen",
                        "division_type": "xã",
                        "short_codename": "dinh_yen"
                    },
                    {
                        "name": "Xã Hội An Đông",
                        "code": 30202,
                        "codename": "xa_hoi_an_dong",
                        "division_type": "xã",
                        "short_codename": "hoi_an_dong"
                    },
                    {
                        "name": "Xã Bình Thạnh Trung",
                        "code": 30205,
                        "codename": "xa_binh_thanh_trung",
                        "division_type": "xã",
                        "short_codename": "binh_thanh_trung"
                    }
                ]
            },
            {
                "name": "Huyện Lai Vung",
                "code": 876,
                "codename": "huyen_lai_vung",
                "division_type": "huyện",
                "short_codename": "lai_vung",
                "wards": [
                    {
                        "name": "Thị trấn Lai Vung",
                        "code": 30208,
                        "codename": "thi_tran_lai_vung",
                        "division_type": "thị trấn",
                        "short_codename": "lai_vung"
                    },
                    {
                        "name": "Xã Tân Dương",
                        "code": 30211,
                        "codename": "xa_tan_duong",
                        "division_type": "xã",
                        "short_codename": "tan_duong"
                    },
                    {
                        "name": "Xã Hòa Thành",
                        "code": 30214,
                        "codename": "xa_hoa_thanh",
                        "division_type": "xã",
                        "short_codename": "hoa_thanh"
                    },
                    {
                        "name": "Xã Long Hậu",
                        "code": 30217,
                        "codename": "xa_long_hau",
                        "division_type": "xã",
                        "short_codename": "long_hau"
                    },
                    {
                        "name": "Xã Tân Phước",
                        "code": 30220,
                        "codename": "xa_tan_phuoc",
                        "division_type": "xã",
                        "short_codename": "tan_phuoc"
                    },
                    {
                        "name": "Xã Hòa Long",
                        "code": 30223,
                        "codename": "xa_hoa_long",
                        "division_type": "xã",
                        "short_codename": "hoa_long"
                    },
                    {
                        "name": "Xã Tân Thành",
                        "code": 30226,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Xã Long Thắng",
                        "code": 30229,
                        "codename": "xa_long_thang",
                        "division_type": "xã",
                        "short_codename": "long_thang"
                    },
                    {
                        "name": "Xã Vĩnh Thới",
                        "code": 30232,
                        "codename": "xa_vinh_thoi",
                        "division_type": "xã",
                        "short_codename": "vinh_thoi"
                    },
                    {
                        "name": "Xã Tân Hòa",
                        "code": 30235,
                        "codename": "xa_tan_hoa",
                        "division_type": "xã",
                        "short_codename": "tan_hoa"
                    },
                    {
                        "name": "Xã Định Hòa",
                        "code": 30238,
                        "codename": "xa_dinh_hoa",
                        "division_type": "xã",
                        "short_codename": "dinh_hoa"
                    },
                    {
                        "name": "Xã Phong Hòa",
                        "code": 30241,
                        "codename": "xa_phong_hoa",
                        "division_type": "xã",
                        "short_codename": "phong_hoa"
                    }
                ]
            },
            {
                "name": "Huyện Châu Thành",
                "code": 877,
                "codename": "huyen_chau_thanh",
                "division_type": "huyện",
                "short_codename": "chau_thanh",
                "wards": [
                    {
                        "name": "Thị trấn Cái Tàu Hạ",
                        "code": 30244,
                        "codename": "thi_tran_cai_tau_ha",
                        "division_type": "thị trấn",
                        "short_codename": "cai_tau_ha"
                    },
                    {
                        "name": "Xã An Hiệp",
                        "code": 30247,
                        "codename": "xa_an_hiep",
                        "division_type": "xã",
                        "short_codename": "an_hiep"
                    },
                    {
                        "name": "Xã An Nhơn",
                        "code": 30250,
                        "codename": "xa_an_nhon",
                        "division_type": "xã",
                        "short_codename": "an_nhon"
                    },
                    {
                        "name": "Xã Tân Nhuận Đông",
                        "code": 30253,
                        "codename": "xa_tan_nhuan_dong",
                        "division_type": "xã",
                        "short_codename": "tan_nhuan_dong"
                    },
                    {
                        "name": "Xã Tân Bình",
                        "code": 30256,
                        "codename": "xa_tan_binh",
                        "division_type": "xã",
                        "short_codename": "tan_binh"
                    },
                    {
                        "name": "Xã Tân Phú Trung",
                        "code": 30259,
                        "codename": "xa_tan_phu_trung",
                        "division_type": "xã",
                        "short_codename": "tan_phu_trung"
                    },
                    {
                        "name": "Xã Phú Long",
                        "code": 30262,
                        "codename": "xa_phu_long",
                        "division_type": "xã",
                        "short_codename": "phu_long"
                    },
                    {
                        "name": "Xã An Phú Thuận",
                        "code": 30265,
                        "codename": "xa_an_phu_thuan",
                        "division_type": "xã",
                        "short_codename": "an_phu_thuan"
                    },
                    {
                        "name": "Xã Phú Hựu",
                        "code": 30268,
                        "codename": "xa_phu_huu",
                        "division_type": "xã",
                        "short_codename": "phu_huu"
                    },
                    {
                        "name": "Xã An Khánh",
                        "code": 30271,
                        "codename": "xa_an_khanh",
                        "division_type": "xã",
                        "short_codename": "an_khanh"
                    },
                    {
                        "name": "Xã Tân Phú",
                        "code": 30274,
                        "codename": "xa_tan_phu",
                        "division_type": "xã",
                        "short_codename": "tan_phu"
                    },
                    {
                        "name": "Xã Hòa Tân",
                        "code": 30277,
                        "codename": "xa_hoa_tan",
                        "division_type": "xã",
                        "short_codename": "hoa_tan"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh An Giang",
        "code": 89,
        "codename": "tinh_an_giang",
        "division_type": "tỉnh",
        "phone_code": 296,
        "districts": [
            {
                "name": "Thành phố Long Xuyên",
                "code": 883,
                "codename": "thanh_pho_long_xuyen",
                "division_type": "thành phố",
                "short_codename": "long_xuyen",
                "wards": [
                    {
                        "name": "Phường Mỹ Bình",
                        "code": 30280,
                        "codename": "phuong_my_binh",
                        "division_type": "phường",
                        "short_codename": "my_binh"
                    },
                    {
                        "name": "Phường Mỹ Long",
                        "code": 30283,
                        "codename": "phuong_my_long",
                        "division_type": "phường",
                        "short_codename": "my_long"
                    },
                    {
                        "name": "Phường Đông Xuyên",
                        "code": 30285,
                        "codename": "phuong_dong_xuyen",
                        "division_type": "phường",
                        "short_codename": "dong_xuyen"
                    },
                    {
                        "name": "Phường Mỹ Xuyên",
                        "code": 30286,
                        "codename": "phuong_my_xuyen",
                        "division_type": "phường",
                        "short_codename": "my_xuyen"
                    },
                    {
                        "name": "Phường Bình Đức",
                        "code": 30289,
                        "codename": "phuong_binh_duc",
                        "division_type": "phường",
                        "short_codename": "binh_duc"
                    },
                    {
                        "name": "Phường Bình Khánh",
                        "code": 30292,
                        "codename": "phuong_binh_khanh",
                        "division_type": "phường",
                        "short_codename": "binh_khanh"
                    },
                    {
                        "name": "Phường Mỹ Phước",
                        "code": 30295,
                        "codename": "phuong_my_phuoc",
                        "division_type": "phường",
                        "short_codename": "my_phuoc"
                    },
                    {
                        "name": "Phường Mỹ Quý",
                        "code": 30298,
                        "codename": "phuong_my_quy",
                        "division_type": "phường",
                        "short_codename": "my_quy"
                    },
                    {
                        "name": "Phường Mỹ Thới",
                        "code": 30301,
                        "codename": "phuong_my_thoi",
                        "division_type": "phường",
                        "short_codename": "my_thoi"
                    },
                    {
                        "name": "Phường Mỹ Thạnh",
                        "code": 30304,
                        "codename": "phuong_my_thanh",
                        "division_type": "phường",
                        "short_codename": "my_thanh"
                    },
                    {
                        "name": "Phường Mỹ Hòa",
                        "code": 30307,
                        "codename": "phuong_my_hoa",
                        "division_type": "phường",
                        "short_codename": "my_hoa"
                    },
                    {
                        "name": "Xã Mỹ Khánh",
                        "code": 30310,
                        "codename": "xa_my_khanh",
                        "division_type": "xã",
                        "short_codename": "my_khanh"
                    },
                    {
                        "name": "Xã Mỹ Hoà Hưng",
                        "code": 30313,
                        "codename": "xa_my_hoa_hung",
                        "division_type": "xã",
                        "short_codename": "my_hoa_hung"
                    }
                ]
            },
            {
                "name": "Thành phố Châu Đốc",
                "code": 884,
                "codename": "thanh_pho_chau_doc",
                "division_type": "thành phố",
                "short_codename": "chau_doc",
                "wards": [
                    {
                        "name": "Phường Châu Phú B",
                        "code": 30316,
                        "codename": "phuong_chau_phu_b",
                        "division_type": "phường",
                        "short_codename": "chau_phu_b"
                    },
                    {
                        "name": "Phường Châu Phú A",
                        "code": 30319,
                        "codename": "phuong_chau_phu_a",
                        "division_type": "phường",
                        "short_codename": "chau_phu_a"
                    },
                    {
                        "name": "Phường Vĩnh Mỹ",
                        "code": 30322,
                        "codename": "phuong_vinh_my",
                        "division_type": "phường",
                        "short_codename": "vinh_my"
                    },
                    {
                        "name": "Phường Núi Sam",
                        "code": 30325,
                        "codename": "phuong_nui_sam",
                        "division_type": "phường",
                        "short_codename": "nui_sam"
                    },
                    {
                        "name": "Phường Vĩnh Ngươn",
                        "code": 30328,
                        "codename": "phuong_vinh_nguon",
                        "division_type": "phường",
                        "short_codename": "vinh_nguon"
                    },
                    {
                        "name": "Xã Vĩnh Tế",
                        "code": 30331,
                        "codename": "xa_vinh_te",
                        "division_type": "xã",
                        "short_codename": "vinh_te"
                    },
                    {
                        "name": "Xã Vĩnh Châu",
                        "code": 30334,
                        "codename": "xa_vinh_chau",
                        "division_type": "xã",
                        "short_codename": "vinh_chau"
                    }
                ]
            },
            {
                "name": "Huyện An Phú",
                "code": 886,
                "codename": "huyen_an_phu",
                "division_type": "huyện",
                "short_codename": "an_phu",
                "wards": [
                    {
                        "name": "Thị trấn An Phú",
                        "code": 30337,
                        "codename": "thi_tran_an_phu",
                        "division_type": "thị trấn",
                        "short_codename": "an_phu"
                    },
                    {
                        "name": "Xã Khánh An",
                        "code": 30340,
                        "codename": "xa_khanh_an",
                        "division_type": "xã",
                        "short_codename": "khanh_an"
                    },
                    {
                        "name": "Thị Trấn Long Bình",
                        "code": 30341,
                        "codename": "thi_tran_long_binh",
                        "division_type": "thị trấn",
                        "short_codename": "long_binh"
                    },
                    {
                        "name": "Xã Khánh Bình",
                        "code": 30343,
                        "codename": "xa_khanh_binh",
                        "division_type": "xã",
                        "short_codename": "khanh_binh"
                    },
                    {
                        "name": "Xã Quốc Thái",
                        "code": 30346,
                        "codename": "xa_quoc_thai",
                        "division_type": "xã",
                        "short_codename": "quoc_thai"
                    },
                    {
                        "name": "Xã Nhơn Hội",
                        "code": 30349,
                        "codename": "xa_nhon_hoi",
                        "division_type": "xã",
                        "short_codename": "nhon_hoi"
                    },
                    {
                        "name": "Xã Phú Hữu",
                        "code": 30352,
                        "codename": "xa_phu_huu",
                        "division_type": "xã",
                        "short_codename": "phu_huu"
                    },
                    {
                        "name": "Xã Phú Hội",
                        "code": 30355,
                        "codename": "xa_phu_hoi",
                        "division_type": "xã",
                        "short_codename": "phu_hoi"
                    },
                    {
                        "name": "Xã Phước Hưng",
                        "code": 30358,
                        "codename": "xa_phuoc_hung",
                        "division_type": "xã",
                        "short_codename": "phuoc_hung"
                    },
                    {
                        "name": "Xã Vĩnh Lộc",
                        "code": 30361,
                        "codename": "xa_vinh_loc",
                        "division_type": "xã",
                        "short_codename": "vinh_loc"
                    },
                    {
                        "name": "Xã Vĩnh Hậu",
                        "code": 30364,
                        "codename": "xa_vinh_hau",
                        "division_type": "xã",
                        "short_codename": "vinh_hau"
                    },
                    {
                        "name": "Xã Vĩnh Trường",
                        "code": 30367,
                        "codename": "xa_vinh_truong",
                        "division_type": "xã",
                        "short_codename": "vinh_truong"
                    },
                    {
                        "name": "Xã Vĩnh Hội Đông",
                        "code": 30370,
                        "codename": "xa_vinh_hoi_dong",
                        "division_type": "xã",
                        "short_codename": "vinh_hoi_dong"
                    },
                    {
                        "name": "Xã Đa Phước",
                        "code": 30373,
                        "codename": "xa_da_phuoc",
                        "division_type": "xã",
                        "short_codename": "da_phuoc"
                    }
                ]
            },
            {
                "name": "Thị xã Tân Châu",
                "code": 887,
                "codename": "thi_xa_tan_chau",
                "division_type": "thị xã",
                "short_codename": "tan_chau",
                "wards": [
                    {
                        "name": "Phường Long Thạnh",
                        "code": 30376,
                        "codename": "phuong_long_thanh",
                        "division_type": "phường",
                        "short_codename": "long_thanh"
                    },
                    {
                        "name": "Phường Long Hưng",
                        "code": 30377,
                        "codename": "phuong_long_hung",
                        "division_type": "phường",
                        "short_codename": "long_hung"
                    },
                    {
                        "name": "Phường Long Châu",
                        "code": 30378,
                        "codename": "phuong_long_chau",
                        "division_type": "phường",
                        "short_codename": "long_chau"
                    },
                    {
                        "name": "Xã Phú Lộc",
                        "code": 30379,
                        "codename": "xa_phu_loc",
                        "division_type": "xã",
                        "short_codename": "phu_loc"
                    },
                    {
                        "name": "Xã Vĩnh Xương",
                        "code": 30382,
                        "codename": "xa_vinh_xuong",
                        "division_type": "xã",
                        "short_codename": "vinh_xuong"
                    },
                    {
                        "name": "Xã Vĩnh Hòa",
                        "code": 30385,
                        "codename": "xa_vinh_hoa",
                        "division_type": "xã",
                        "short_codename": "vinh_hoa"
                    },
                    {
                        "name": "Xã Tân Thạnh",
                        "code": 30387,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Xã Tân An",
                        "code": 30388,
                        "codename": "xa_tan_an",
                        "division_type": "xã",
                        "short_codename": "tan_an"
                    },
                    {
                        "name": "Xã Long An",
                        "code": 30391,
                        "codename": "xa_long_an",
                        "division_type": "xã",
                        "short_codename": "long_an"
                    },
                    {
                        "name": "Phường Long Phú",
                        "code": 30394,
                        "codename": "phuong_long_phu",
                        "division_type": "phường",
                        "short_codename": "long_phu"
                    },
                    {
                        "name": "Xã Châu Phong",
                        "code": 30397,
                        "codename": "xa_chau_phong",
                        "division_type": "xã",
                        "short_codename": "chau_phong"
                    },
                    {
                        "name": "Xã Phú Vĩnh",
                        "code": 30400,
                        "codename": "xa_phu_vinh",
                        "division_type": "xã",
                        "short_codename": "phu_vinh"
                    },
                    {
                        "name": "Xã Lê Chánh",
                        "code": 30403,
                        "codename": "xa_le_chanh",
                        "division_type": "xã",
                        "short_codename": "le_chanh"
                    },
                    {
                        "name": "Phường Long Sơn",
                        "code": 30412,
                        "codename": "phuong_long_son",
                        "division_type": "phường",
                        "short_codename": "long_son"
                    }
                ]
            },
            {
                "name": "Huyện Phú Tân",
                "code": 888,
                "codename": "huyen_phu_tan",
                "division_type": "huyện",
                "short_codename": "phu_tan",
                "wards": [
                    {
                        "name": "Thị trấn Phú Mỹ",
                        "code": 30406,
                        "codename": "thi_tran_phu_my",
                        "division_type": "thị trấn",
                        "short_codename": "phu_my"
                    },
                    {
                        "name": "Thị trấn Chợ Vàm",
                        "code": 30409,
                        "codename": "thi_tran_cho_vam",
                        "division_type": "thị trấn",
                        "short_codename": "cho_vam"
                    },
                    {
                        "name": "Xã Long Hoà",
                        "code": 30415,
                        "codename": "xa_long_hoa",
                        "division_type": "xã",
                        "short_codename": "long_hoa"
                    },
                    {
                        "name": "Xã Phú Long",
                        "code": 30418,
                        "codename": "xa_phu_long",
                        "division_type": "xã",
                        "short_codename": "phu_long"
                    },
                    {
                        "name": "Xã Phú Lâm",
                        "code": 30421,
                        "codename": "xa_phu_lam",
                        "division_type": "xã",
                        "short_codename": "phu_lam"
                    },
                    {
                        "name": "Xã Phú Hiệp",
                        "code": 30424,
                        "codename": "xa_phu_hiep",
                        "division_type": "xã",
                        "short_codename": "phu_hiep"
                    },
                    {
                        "name": "Xã Phú Thạnh",
                        "code": 30427,
                        "codename": "xa_phu_thanh",
                        "division_type": "xã",
                        "short_codename": "xa_phu_thanh"
                    },
                    {
                        "name": "Xã Hoà Lạc",
                        "code": 30430,
                        "codename": "xa_hoa_lac",
                        "division_type": "xã",
                        "short_codename": "hoa_lac"
                    },
                    {
                        "name": "Xã Phú Thành",
                        "code": 30433,
                        "codename": "xa_phu_thanh",
                        "division_type": "xã",
                        "short_codename": "xa_phu_thanh"
                    },
                    {
                        "name": "Xã Phú An",
                        "code": 30436,
                        "codename": "xa_phu_an",
                        "division_type": "xã",
                        "short_codename": "phu_an"
                    },
                    {
                        "name": "Xã Phú Xuân",
                        "code": 30439,
                        "codename": "xa_phu_xuan",
                        "division_type": "xã",
                        "short_codename": "phu_xuan"
                    },
                    {
                        "name": "Xã Hiệp Xương",
                        "code": 30442,
                        "codename": "xa_hiep_xuong",
                        "division_type": "xã",
                        "short_codename": "hiep_xuong"
                    },
                    {
                        "name": "Xã Phú Bình",
                        "code": 30445,
                        "codename": "xa_phu_binh",
                        "division_type": "xã",
                        "short_codename": "phu_binh"
                    },
                    {
                        "name": "Xã Phú Thọ",
                        "code": 30448,
                        "codename": "xa_phu_tho",
                        "division_type": "xã",
                        "short_codename": "phu_tho"
                    },
                    {
                        "name": "Xã Phú Hưng",
                        "code": 30451,
                        "codename": "xa_phu_hung",
                        "division_type": "xã",
                        "short_codename": "phu_hung"
                    },
                    {
                        "name": "Xã Bình Thạnh Đông",
                        "code": 30454,
                        "codename": "xa_binh_thanh_dong",
                        "division_type": "xã",
                        "short_codename": "binh_thanh_dong"
                    },
                    {
                        "name": "Xã Tân Hòa",
                        "code": 30457,
                        "codename": "xa_tan_hoa",
                        "division_type": "xã",
                        "short_codename": "tan_hoa"
                    },
                    {
                        "name": "Xã Tân Trung",
                        "code": 30460,
                        "codename": "xa_tan_trung",
                        "division_type": "xã",
                        "short_codename": "tan_trung"
                    }
                ]
            },
            {
                "name": "Huyện Châu Phú",
                "code": 889,
                "codename": "huyen_chau_phu",
                "division_type": "huyện",
                "short_codename": "chau_phu",
                "wards": [
                    {
                        "name": "Thị trấn Cái Dầu",
                        "code": 30463,
                        "codename": "thi_tran_cai_dau",
                        "division_type": "thị trấn",
                        "short_codename": "cai_dau"
                    },
                    {
                        "name": "Xã Khánh Hòa",
                        "code": 30466,
                        "codename": "xa_khanh_hoa",
                        "division_type": "xã",
                        "short_codename": "khanh_hoa"
                    },
                    {
                        "name": "Xã Mỹ Đức",
                        "code": 30469,
                        "codename": "xa_my_duc",
                        "division_type": "xã",
                        "short_codename": "my_duc"
                    },
                    {
                        "name": "Xã Mỹ Phú",
                        "code": 30472,
                        "codename": "xa_my_phu",
                        "division_type": "xã",
                        "short_codename": "my_phu"
                    },
                    {
                        "name": "Xã Ô Long Vỹ",
                        "code": 30475,
                        "codename": "xa_o_long_vy",
                        "division_type": "xã",
                        "short_codename": "o_long_vy"
                    },
                    {
                        "name": "Thị trấn Vĩnh Thạnh Trung",
                        "code": 30478,
                        "codename": "thi_tran_vinh_thanh_trung",
                        "division_type": "thị trấn",
                        "short_codename": "vinh_thanh_trung"
                    },
                    {
                        "name": "Xã Thạnh Mỹ Tây",
                        "code": 30481,
                        "codename": "xa_thanh_my_tay",
                        "division_type": "xã",
                        "short_codename": "thanh_my_tay"
                    },
                    {
                        "name": "Xã Bình Long",
                        "code": 30484,
                        "codename": "xa_binh_long",
                        "division_type": "xã",
                        "short_codename": "binh_long"
                    },
                    {
                        "name": "Xã Bình Mỹ",
                        "code": 30487,
                        "codename": "xa_binh_my",
                        "division_type": "xã",
                        "short_codename": "binh_my"
                    },
                    {
                        "name": "Xã Bình Thủy",
                        "code": 30490,
                        "codename": "xa_binh_thuy",
                        "division_type": "xã",
                        "short_codename": "binh_thuy"
                    },
                    {
                        "name": "Xã Đào Hữu Cảnh",
                        "code": 30493,
                        "codename": "xa_dao_huu_canh",
                        "division_type": "xã",
                        "short_codename": "dao_huu_canh"
                    },
                    {
                        "name": "Xã Bình Phú",
                        "code": 30496,
                        "codename": "xa_binh_phu",
                        "division_type": "xã",
                        "short_codename": "binh_phu"
                    },
                    {
                        "name": "Xã Bình Chánh",
                        "code": 30499,
                        "codename": "xa_binh_chanh",
                        "division_type": "xã",
                        "short_codename": "binh_chanh"
                    }
                ]
            },
            {
                "name": "Huyện Tịnh Biên",
                "code": 890,
                "codename": "huyen_tinh_bien",
                "division_type": "huyện",
                "short_codename": "tinh_bien",
                "wards": [
                    {
                        "name": "Thị trấn Nhà Bàng",
                        "code": 30502,
                        "codename": "thi_tran_nha_bang",
                        "division_type": "thị trấn",
                        "short_codename": "nha_bang"
                    },
                    {
                        "name": "Thị trấn Chi Lăng",
                        "code": 30505,
                        "codename": "thi_tran_chi_lang",
                        "division_type": "thị trấn",
                        "short_codename": "chi_lang"
                    },
                    {
                        "name": "Xã Núi Voi",
                        "code": 30508,
                        "codename": "xa_nui_voi",
                        "division_type": "xã",
                        "short_codename": "nui_voi"
                    },
                    {
                        "name": "Xã Nhơn Hưng",
                        "code": 30511,
                        "codename": "xa_nhon_hung",
                        "division_type": "xã",
                        "short_codename": "nhon_hung"
                    },
                    {
                        "name": "Xã An Phú",
                        "code": 30514,
                        "codename": "xa_an_phu",
                        "division_type": "xã",
                        "short_codename": "an_phu"
                    },
                    {
                        "name": "Xã Thới Sơn",
                        "code": 30517,
                        "codename": "xa_thoi_son",
                        "division_type": "xã",
                        "short_codename": "thoi_son"
                    },
                    {
                        "name": "Thị trấn Tịnh Biên",
                        "code": 30520,
                        "codename": "thi_tran_tinh_bien",
                        "division_type": "thị trấn",
                        "short_codename": "tinh_bien"
                    },
                    {
                        "name": "Xã Văn Giáo",
                        "code": 30523,
                        "codename": "xa_van_giao",
                        "division_type": "xã",
                        "short_codename": "van_giao"
                    },
                    {
                        "name": "Xã An Cư",
                        "code": 30526,
                        "codename": "xa_an_cu",
                        "division_type": "xã",
                        "short_codename": "an_cu"
                    },
                    {
                        "name": "Xã An Nông",
                        "code": 30529,
                        "codename": "xa_an_nong",
                        "division_type": "xã",
                        "short_codename": "an_nong"
                    },
                    {
                        "name": "Xã Vĩnh Trung",
                        "code": 30532,
                        "codename": "xa_vinh_trung",
                        "division_type": "xã",
                        "short_codename": "vinh_trung"
                    },
                    {
                        "name": "Xã Tân Lợi",
                        "code": 30535,
                        "codename": "xa_tan_loi",
                        "division_type": "xã",
                        "short_codename": "tan_loi"
                    },
                    {
                        "name": "Xã An Hảo",
                        "code": 30538,
                        "codename": "xa_an_hao",
                        "division_type": "xã",
                        "short_codename": "an_hao"
                    },
                    {
                        "name": "Xã Tân Lập",
                        "code": 30541,
                        "codename": "xa_tan_lap",
                        "division_type": "xã",
                        "short_codename": "tan_lap"
                    }
                ]
            },
            {
                "name": "Huyện Tri Tôn",
                "code": 891,
                "codename": "huyen_tri_ton",
                "division_type": "huyện",
                "short_codename": "tri_ton",
                "wards": [
                    {
                        "name": "Thị trấn Tri Tôn",
                        "code": 30544,
                        "codename": "thi_tran_tri_ton",
                        "division_type": "thị trấn",
                        "short_codename": "tri_ton"
                    },
                    {
                        "name": "Thị trấn Ba Chúc",
                        "code": 30547,
                        "codename": "thi_tran_ba_chuc",
                        "division_type": "thị trấn",
                        "short_codename": "ba_chuc"
                    },
                    {
                        "name": "Xã Lạc Quới",
                        "code": 30550,
                        "codename": "xa_lac_quoi",
                        "division_type": "xã",
                        "short_codename": "lac_quoi"
                    },
                    {
                        "name": "Xã Lê Trì",
                        "code": 30553,
                        "codename": "xa_le_tri",
                        "division_type": "xã",
                        "short_codename": "le_tri"
                    },
                    {
                        "name": "Xã Vĩnh Gia",
                        "code": 30556,
                        "codename": "xa_vinh_gia",
                        "division_type": "xã",
                        "short_codename": "vinh_gia"
                    },
                    {
                        "name": "Xã Vĩnh Phước",
                        "code": 30559,
                        "codename": "xa_vinh_phuoc",
                        "division_type": "xã",
                        "short_codename": "vinh_phuoc"
                    },
                    {
                        "name": "Xã Châu Lăng",
                        "code": 30562,
                        "codename": "xa_chau_lang",
                        "division_type": "xã",
                        "short_codename": "chau_lang"
                    },
                    {
                        "name": "Xã Lương Phi",
                        "code": 30565,
                        "codename": "xa_luong_phi",
                        "division_type": "xã",
                        "short_codename": "luong_phi"
                    },
                    {
                        "name": "Xã Lương An Trà",
                        "code": 30568,
                        "codename": "xa_luong_an_tra",
                        "division_type": "xã",
                        "short_codename": "luong_an_tra"
                    },
                    {
                        "name": "Xã Tà Đảnh",
                        "code": 30571,
                        "codename": "xa_ta_danh",
                        "division_type": "xã",
                        "short_codename": "ta_danh"
                    },
                    {
                        "name": "Xã Núi Tô",
                        "code": 30574,
                        "codename": "xa_nui_to",
                        "division_type": "xã",
                        "short_codename": "nui_to"
                    },
                    {
                        "name": "Xã An Tức",
                        "code": 30577,
                        "codename": "xa_an_tuc",
                        "division_type": "xã",
                        "short_codename": "an_tuc"
                    },
                    {
                        "name": "Thị trấn Cô Tô",
                        "code": 30580,
                        "codename": "thi_tran_co_to",
                        "division_type": "thị trấn",
                        "short_codename": "co_to"
                    },
                    {
                        "name": "Xã Tân Tuyến",
                        "code": 30583,
                        "codename": "xa_tan_tuyen",
                        "division_type": "xã",
                        "short_codename": "tan_tuyen"
                    },
                    {
                        "name": "Xã Ô Lâm",
                        "code": 30586,
                        "codename": "xa_o_lam",
                        "division_type": "xã",
                        "short_codename": "o_lam"
                    }
                ]
            },
            {
                "name": "Huyện Châu Thành",
                "code": 892,
                "codename": "huyen_chau_thanh",
                "division_type": "huyện",
                "short_codename": "chau_thanh",
                "wards": [
                    {
                        "name": "Thị trấn An Châu",
                        "code": 30589,
                        "codename": "thi_tran_an_chau",
                        "division_type": "thị trấn",
                        "short_codename": "an_chau"
                    },
                    {
                        "name": "Xã An Hòa",
                        "code": 30592,
                        "codename": "xa_an_hoa",
                        "division_type": "xã",
                        "short_codename": "an_hoa"
                    },
                    {
                        "name": "Xã Cần Đăng",
                        "code": 30595,
                        "codename": "xa_can_dang",
                        "division_type": "xã",
                        "short_codename": "can_dang"
                    },
                    {
                        "name": "Xã Vĩnh Hanh",
                        "code": 30598,
                        "codename": "xa_vinh_hanh",
                        "division_type": "xã",
                        "short_codename": "vinh_hanh"
                    },
                    {
                        "name": "Xã Bình Thạnh",
                        "code": 30601,
                        "codename": "xa_binh_thanh",
                        "division_type": "xã",
                        "short_codename": "binh_thanh"
                    },
                    {
                        "name": "Thị trấn Vĩnh Bình",
                        "code": 30604,
                        "codename": "thi_tran_vinh_binh",
                        "division_type": "thị trấn",
                        "short_codename": "vinh_binh"
                    },
                    {
                        "name": "Xã Bình Hòa",
                        "code": 30607,
                        "codename": "xa_binh_hoa",
                        "division_type": "xã",
                        "short_codename": "binh_hoa"
                    },
                    {
                        "name": "Xã Vĩnh An",
                        "code": 30610,
                        "codename": "xa_vinh_an",
                        "division_type": "xã",
                        "short_codename": "vinh_an"
                    },
                    {
                        "name": "Xã Hòa Bình Thạnh",
                        "code": 30613,
                        "codename": "xa_hoa_binh_thanh",
                        "division_type": "xã",
                        "short_codename": "hoa_binh_thanh"
                    },
                    {
                        "name": "Xã Vĩnh Lợi",
                        "code": 30616,
                        "codename": "xa_vinh_loi",
                        "division_type": "xã",
                        "short_codename": "vinh_loi"
                    },
                    {
                        "name": "Xã Vĩnh Nhuận",
                        "code": 30619,
                        "codename": "xa_vinh_nhuan",
                        "division_type": "xã",
                        "short_codename": "vinh_nhuan"
                    },
                    {
                        "name": "Xã Tân Phú",
                        "code": 30622,
                        "codename": "xa_tan_phu",
                        "division_type": "xã",
                        "short_codename": "tan_phu"
                    },
                    {
                        "name": "Xã Vĩnh Thành",
                        "code": 30625,
                        "codename": "xa_vinh_thanh",
                        "division_type": "xã",
                        "short_codename": "vinh_thanh"
                    }
                ]
            },
            {
                "name": "Huyện Chợ Mới",
                "code": 893,
                "codename": "huyen_cho_moi",
                "division_type": "huyện",
                "short_codename": "cho_moi",
                "wards": [
                    {
                        "name": "Thị trấn Chợ Mới",
                        "code": 30628,
                        "codename": "thi_tran_cho_moi",
                        "division_type": "thị trấn",
                        "short_codename": "cho_moi"
                    },
                    {
                        "name": "Thị trấn Mỹ Luông",
                        "code": 30631,
                        "codename": "thi_tran_my_luong",
                        "division_type": "thị trấn",
                        "short_codename": "my_luong"
                    },
                    {
                        "name": "Xã Kiến An",
                        "code": 30634,
                        "codename": "xa_kien_an",
                        "division_type": "xã",
                        "short_codename": "kien_an"
                    },
                    {
                        "name": "Xã Mỹ Hội Đông",
                        "code": 30637,
                        "codename": "xa_my_hoi_dong",
                        "division_type": "xã",
                        "short_codename": "my_hoi_dong"
                    },
                    {
                        "name": "Xã Long Điền A",
                        "code": 30640,
                        "codename": "xa_long_dien_a",
                        "division_type": "xã",
                        "short_codename": "long_dien_a"
                    },
                    {
                        "name": "Xã Tấn Mỹ",
                        "code": 30643,
                        "codename": "xa_tan_my",
                        "division_type": "xã",
                        "short_codename": "tan_my"
                    },
                    {
                        "name": "Xã Long Điền B",
                        "code": 30646,
                        "codename": "xa_long_dien_b",
                        "division_type": "xã",
                        "short_codename": "long_dien_b"
                    },
                    {
                        "name": "Xã Kiến Thành",
                        "code": 30649,
                        "codename": "xa_kien_thanh",
                        "division_type": "xã",
                        "short_codename": "kien_thanh"
                    },
                    {
                        "name": "Xã Mỹ Hiệp",
                        "code": 30652,
                        "codename": "xa_my_hiep",
                        "division_type": "xã",
                        "short_codename": "my_hiep"
                    },
                    {
                        "name": "Xã Mỹ An",
                        "code": 30655,
                        "codename": "xa_my_an",
                        "division_type": "xã",
                        "short_codename": "my_an"
                    },
                    {
                        "name": "Xã Nhơn Mỹ",
                        "code": 30658,
                        "codename": "xa_nhon_my",
                        "division_type": "xã",
                        "short_codename": "nhon_my"
                    },
                    {
                        "name": "Xã Long Giang",
                        "code": 30661,
                        "codename": "xa_long_giang",
                        "division_type": "xã",
                        "short_codename": "long_giang"
                    },
                    {
                        "name": "Xã Long Kiến",
                        "code": 30664,
                        "codename": "xa_long_kien",
                        "division_type": "xã",
                        "short_codename": "long_kien"
                    },
                    {
                        "name": "Xã Bình Phước Xuân",
                        "code": 30667,
                        "codename": "xa_binh_phuoc_xuan",
                        "division_type": "xã",
                        "short_codename": "binh_phuoc_xuan"
                    },
                    {
                        "name": "Xã An Thạnh Trung",
                        "code": 30670,
                        "codename": "xa_an_thanh_trung",
                        "division_type": "xã",
                        "short_codename": "an_thanh_trung"
                    },
                    {
                        "name": "Xã Hội An",
                        "code": 30673,
                        "codename": "xa_hoi_an",
                        "division_type": "xã",
                        "short_codename": "hoi_an"
                    },
                    {
                        "name": "Xã Hòa Bình",
                        "code": 30676,
                        "codename": "xa_hoa_binh",
                        "division_type": "xã",
                        "short_codename": "hoa_binh"
                    },
                    {
                        "name": "Xã Hòa An",
                        "code": 30679,
                        "codename": "xa_hoa_an",
                        "division_type": "xã",
                        "short_codename": "hoa_an"
                    }
                ]
            },
            {
                "name": "Huyện Thoại Sơn",
                "code": 894,
                "codename": "huyen_thoai_son",
                "division_type": "huyện",
                "short_codename": "thoai_son",
                "wards": [
                    {
                        "name": "Thị trấn Núi Sập",
                        "code": 30682,
                        "codename": "thi_tran_nui_sap",
                        "division_type": "thị trấn",
                        "short_codename": "nui_sap"
                    },
                    {
                        "name": "Thị trấn Phú Hoà",
                        "code": 30685,
                        "codename": "thi_tran_phu_hoa",
                        "division_type": "thị trấn",
                        "short_codename": "phu_hoa"
                    },
                    {
                        "name": "Thị Trấn Óc Eo",
                        "code": 30688,
                        "codename": "thi_tran_oc_eo",
                        "division_type": "thị trấn",
                        "short_codename": "oc_eo"
                    },
                    {
                        "name": "Xã Tây Phú",
                        "code": 30691,
                        "codename": "xa_tay_phu",
                        "division_type": "xã",
                        "short_codename": "tay_phu"
                    },
                    {
                        "name": "Xã An Bình",
                        "code": 30692,
                        "codename": "xa_an_binh",
                        "division_type": "xã",
                        "short_codename": "an_binh"
                    },
                    {
                        "name": "Xã Vĩnh Phú",
                        "code": 30694,
                        "codename": "xa_vinh_phu",
                        "division_type": "xã",
                        "short_codename": "vinh_phu"
                    },
                    {
                        "name": "Xã Vĩnh Trạch",
                        "code": 30697,
                        "codename": "xa_vinh_trach",
                        "division_type": "xã",
                        "short_codename": "vinh_trach"
                    },
                    {
                        "name": "Xã Phú Thuận",
                        "code": 30700,
                        "codename": "xa_phu_thuan",
                        "division_type": "xã",
                        "short_codename": "phu_thuan"
                    },
                    {
                        "name": "Xã Vĩnh Chánh",
                        "code": 30703,
                        "codename": "xa_vinh_chanh",
                        "division_type": "xã",
                        "short_codename": "vinh_chanh"
                    },
                    {
                        "name": "Xã Định Mỹ",
                        "code": 30706,
                        "codename": "xa_dinh_my",
                        "division_type": "xã",
                        "short_codename": "dinh_my"
                    },
                    {
                        "name": "Xã Định Thành",
                        "code": 30709,
                        "codename": "xa_dinh_thanh",
                        "division_type": "xã",
                        "short_codename": "dinh_thanh"
                    },
                    {
                        "name": "Xã Mỹ Phú Đông",
                        "code": 30712,
                        "codename": "xa_my_phu_dong",
                        "division_type": "xã",
                        "short_codename": "my_phu_dong"
                    },
                    {
                        "name": "Xã Vọng Đông",
                        "code": 30715,
                        "codename": "xa_vong_dong",
                        "division_type": "xã",
                        "short_codename": "vong_dong"
                    },
                    {
                        "name": "Xã Vĩnh Khánh",
                        "code": 30718,
                        "codename": "xa_vinh_khanh",
                        "division_type": "xã",
                        "short_codename": "vinh_khanh"
                    },
                    {
                        "name": "Xã Thoại Giang",
                        "code": 30721,
                        "codename": "xa_thoai_giang",
                        "division_type": "xã",
                        "short_codename": "thoai_giang"
                    },
                    {
                        "name": "Xã Bình Thành",
                        "code": 30724,
                        "codename": "xa_binh_thanh",
                        "division_type": "xã",
                        "short_codename": "binh_thanh"
                    },
                    {
                        "name": "Xã Vọng Thê",
                        "code": 30727,
                        "codename": "xa_vong_the",
                        "division_type": "xã",
                        "short_codename": "vong_the"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Kiên Giang",
        "code": 91,
        "codename": "tinh_kien_giang",
        "division_type": "tỉnh",
        "phone_code": 297,
        "districts": [
            {
                "name": "Thành phố Rạch Giá",
                "code": 899,
                "codename": "thanh_pho_rach_gia",
                "division_type": "thành phố",
                "short_codename": "rach_gia",
                "wards": [
                    {
                        "name": "Phường Vĩnh Thanh Vân",
                        "code": 30730,
                        "codename": "phuong_vinh_thanh_van",
                        "division_type": "phường",
                        "short_codename": "vinh_thanh_van"
                    },
                    {
                        "name": "Phường Vĩnh Thanh",
                        "code": 30733,
                        "codename": "phuong_vinh_thanh",
                        "division_type": "phường",
                        "short_codename": "vinh_thanh"
                    },
                    {
                        "name": "Phường Vĩnh Quang",
                        "code": 30736,
                        "codename": "phuong_vinh_quang",
                        "division_type": "phường",
                        "short_codename": "vinh_quang"
                    },
                    {
                        "name": "Phường Vĩnh Hiệp",
                        "code": 30739,
                        "codename": "phuong_vinh_hiep",
                        "division_type": "phường",
                        "short_codename": "vinh_hiep"
                    },
                    {
                        "name": "Phường Vĩnh Bảo",
                        "code": 30742,
                        "codename": "phuong_vinh_bao",
                        "division_type": "phường",
                        "short_codename": "vinh_bao"
                    },
                    {
                        "name": "Phường Vĩnh Lạc",
                        "code": 30745,
                        "codename": "phuong_vinh_lac",
                        "division_type": "phường",
                        "short_codename": "vinh_lac"
                    },
                    {
                        "name": "Phường An Hòa",
                        "code": 30748,
                        "codename": "phuong_an_hoa",
                        "division_type": "phường",
                        "short_codename": "an_hoa"
                    },
                    {
                        "name": "Phường An Bình",
                        "code": 30751,
                        "codename": "phuong_an_binh",
                        "division_type": "phường",
                        "short_codename": "an_binh"
                    },
                    {
                        "name": "Phường Rạch Sỏi",
                        "code": 30754,
                        "codename": "phuong_rach_soi",
                        "division_type": "phường",
                        "short_codename": "rach_soi"
                    },
                    {
                        "name": "Phường Vĩnh Lợi",
                        "code": 30757,
                        "codename": "phuong_vinh_loi",
                        "division_type": "phường",
                        "short_codename": "vinh_loi"
                    },
                    {
                        "name": "Phường Vĩnh Thông",
                        "code": 30760,
                        "codename": "phuong_vinh_thong",
                        "division_type": "phường",
                        "short_codename": "vinh_thong"
                    },
                    {
                        "name": "Xã Phi Thông",
                        "code": 30763,
                        "codename": "xa_phi_thong",
                        "division_type": "xã",
                        "short_codename": "phi_thong"
                    }
                ]
            },
            {
                "name": "Thành phố Hà Tiên",
                "code": 900,
                "codename": "thanh_pho_ha_tien",
                "division_type": "thành phố",
                "short_codename": "ha_tien",
                "wards": [
                    {
                        "name": "Phường Tô Châu",
                        "code": 30766,
                        "codename": "phuong_to_chau",
                        "division_type": "phường",
                        "short_codename": "to_chau"
                    },
                    {
                        "name": "Phường Đông Hồ",
                        "code": 30769,
                        "codename": "phuong_dong_ho",
                        "division_type": "phường",
                        "short_codename": "dong_ho"
                    },
                    {
                        "name": "Phường Bình San",
                        "code": 30772,
                        "codename": "phuong_binh_san",
                        "division_type": "phường",
                        "short_codename": "binh_san"
                    },
                    {
                        "name": "Phường Pháo Đài",
                        "code": 30775,
                        "codename": "phuong_phao_dai",
                        "division_type": "phường",
                        "short_codename": "phao_dai"
                    },
                    {
                        "name": "Phường Mỹ Đức",
                        "code": 30778,
                        "codename": "phuong_my_duc",
                        "division_type": "phường",
                        "short_codename": "my_duc"
                    },
                    {
                        "name": "Xã Tiên Hải",
                        "code": 30781,
                        "codename": "xa_tien_hai",
                        "division_type": "xã",
                        "short_codename": "tien_hai"
                    },
                    {
                        "name": "Xã Thuận Yên",
                        "code": 30784,
                        "codename": "xa_thuan_yen",
                        "division_type": "xã",
                        "short_codename": "thuan_yen"
                    }
                ]
            },
            {
                "name": "Huyện Kiên Lương",
                "code": 902,
                "codename": "huyen_kien_luong",
                "division_type": "huyện",
                "short_codename": "kien_luong",
                "wards": [
                    {
                        "name": "Thị trấn Kiên Lương",
                        "code": 30787,
                        "codename": "thi_tran_kien_luong",
                        "division_type": "thị trấn",
                        "short_codename": "kien_luong"
                    },
                    {
                        "name": "Xã Kiên Bình",
                        "code": 30790,
                        "codename": "xa_kien_binh",
                        "division_type": "xã",
                        "short_codename": "kien_binh"
                    },
                    {
                        "name": "Xã Hòa Điền",
                        "code": 30802,
                        "codename": "xa_hoa_dien",
                        "division_type": "xã",
                        "short_codename": "hoa_dien"
                    },
                    {
                        "name": "Xã Dương Hòa",
                        "code": 30805,
                        "codename": "xa_duong_hoa",
                        "division_type": "xã",
                        "short_codename": "duong_hoa"
                    },
                    {
                        "name": "Xã Bình An",
                        "code": 30808,
                        "codename": "xa_binh_an",
                        "division_type": "xã",
                        "short_codename": "binh_an"
                    },
                    {
                        "name": "Xã Bình Trị",
                        "code": 30809,
                        "codename": "xa_binh_tri",
                        "division_type": "xã",
                        "short_codename": "binh_tri"
                    },
                    {
                        "name": "Xã Sơn Hải",
                        "code": 30811,
                        "codename": "xa_son_hai",
                        "division_type": "xã",
                        "short_codename": "son_hai"
                    },
                    {
                        "name": "Xã Hòn Nghệ",
                        "code": 30814,
                        "codename": "xa_hon_nghe",
                        "division_type": "xã",
                        "short_codename": "hon_nghe"
                    }
                ]
            },
            {
                "name": "Huyện Hòn Đất",
                "code": 903,
                "codename": "huyen_hon_dat",
                "division_type": "huyện",
                "short_codename": "hon_dat",
                "wards": [
                    {
                        "name": "Thị trấn Hòn Đất",
                        "code": 30817,
                        "codename": "thi_tran_hon_dat",
                        "division_type": "thị trấn",
                        "short_codename": "hon_dat"
                    },
                    {
                        "name": "Thị trấn Sóc Sơn",
                        "code": 30820,
                        "codename": "thi_tran_soc_son",
                        "division_type": "thị trấn",
                        "short_codename": "soc_son"
                    },
                    {
                        "name": "Xã Bình Sơn",
                        "code": 30823,
                        "codename": "xa_binh_son",
                        "division_type": "xã",
                        "short_codename": "binh_son"
                    },
                    {
                        "name": "Xã Bình Giang",
                        "code": 30826,
                        "codename": "xa_binh_giang",
                        "division_type": "xã",
                        "short_codename": "binh_giang"
                    },
                    {
                        "name": "Xã Mỹ Thái",
                        "code": 30828,
                        "codename": "xa_my_thai",
                        "division_type": "xã",
                        "short_codename": "my_thai"
                    },
                    {
                        "name": "Xã Nam Thái Sơn",
                        "code": 30829,
                        "codename": "xa_nam_thai_son",
                        "division_type": "xã",
                        "short_codename": "nam_thai_son"
                    },
                    {
                        "name": "Xã Mỹ Hiệp Sơn",
                        "code": 30832,
                        "codename": "xa_my_hiep_son",
                        "division_type": "xã",
                        "short_codename": "my_hiep_son"
                    },
                    {
                        "name": "Xã Sơn Kiên",
                        "code": 30835,
                        "codename": "xa_son_kien",
                        "division_type": "xã",
                        "short_codename": "son_kien"
                    },
                    {
                        "name": "Xã Sơn Bình",
                        "code": 30836,
                        "codename": "xa_son_binh",
                        "division_type": "xã",
                        "short_codename": "son_binh"
                    },
                    {
                        "name": "Xã Mỹ Thuận",
                        "code": 30838,
                        "codename": "xa_my_thuan",
                        "division_type": "xã",
                        "short_codename": "my_thuan"
                    },
                    {
                        "name": "Xã Lình Huỳnh",
                        "code": 30840,
                        "codename": "xa_linh_huynh",
                        "division_type": "xã",
                        "short_codename": "linh_huynh"
                    },
                    {
                        "name": "Xã Thổ Sơn",
                        "code": 30841,
                        "codename": "xa_tho_son",
                        "division_type": "xã",
                        "short_codename": "tho_son"
                    },
                    {
                        "name": "Xã Mỹ Lâm",
                        "code": 30844,
                        "codename": "xa_my_lam",
                        "division_type": "xã",
                        "short_codename": "my_lam"
                    },
                    {
                        "name": "Xã Mỹ Phước",
                        "code": 30847,
                        "codename": "xa_my_phuoc",
                        "division_type": "xã",
                        "short_codename": "my_phuoc"
                    }
                ]
            },
            {
                "name": "Huyện Tân Hiệp",
                "code": 904,
                "codename": "huyen_tan_hiep",
                "division_type": "huyện",
                "short_codename": "tan_hiep",
                "wards": [
                    {
                        "name": "Thị trấn Tân Hiệp",
                        "code": 30850,
                        "codename": "thi_tran_tan_hiep",
                        "division_type": "thị trấn",
                        "short_codename": "tan_hiep"
                    },
                    {
                        "name": "Xã Tân Hội",
                        "code": 30853,
                        "codename": "xa_tan_hoi",
                        "division_type": "xã",
                        "short_codename": "tan_hoi"
                    },
                    {
                        "name": "Xã Tân Thành",
                        "code": 30856,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Xã Tân Hiệp B",
                        "code": 30859,
                        "codename": "xa_tan_hiep_b",
                        "division_type": "xã",
                        "short_codename": "tan_hiep_b"
                    },
                    {
                        "name": "Xã Tân Hoà",
                        "code": 30860,
                        "codename": "xa_tan_hoa",
                        "division_type": "xã",
                        "short_codename": "tan_hoa"
                    },
                    {
                        "name": "Xã Thạnh Đông B",
                        "code": 30862,
                        "codename": "xa_thanh_dong_b",
                        "division_type": "xã",
                        "short_codename": "thanh_dong_b"
                    },
                    {
                        "name": "Xã Thạnh Đông",
                        "code": 30865,
                        "codename": "xa_thanh_dong",
                        "division_type": "xã",
                        "short_codename": "thanh_dong"
                    },
                    {
                        "name": "Xã Tân Hiệp A",
                        "code": 30868,
                        "codename": "xa_tan_hiep_a",
                        "division_type": "xã",
                        "short_codename": "tan_hiep_a"
                    },
                    {
                        "name": "Xã Tân An",
                        "code": 30871,
                        "codename": "xa_tan_an",
                        "division_type": "xã",
                        "short_codename": "tan_an"
                    },
                    {
                        "name": "Xã Thạnh Đông A",
                        "code": 30874,
                        "codename": "xa_thanh_dong_a",
                        "division_type": "xã",
                        "short_codename": "thanh_dong_a"
                    },
                    {
                        "name": "Xã Thạnh Trị",
                        "code": 30877,
                        "codename": "xa_thanh_tri",
                        "division_type": "xã",
                        "short_codename": "thanh_tri"
                    }
                ]
            },
            {
                "name": "Huyện Châu Thành",
                "code": 905,
                "codename": "huyen_chau_thanh",
                "division_type": "huyện",
                "short_codename": "chau_thanh",
                "wards": [
                    {
                        "name": "Thị trấn Minh Lương",
                        "code": 30880,
                        "codename": "thi_tran_minh_luong",
                        "division_type": "thị trấn",
                        "short_codename": "minh_luong"
                    },
                    {
                        "name": "Xã Mong Thọ A",
                        "code": 30883,
                        "codename": "xa_mong_tho_a",
                        "division_type": "xã",
                        "short_codename": "mong_tho_a"
                    },
                    {
                        "name": "Xã Mong Thọ B",
                        "code": 30886,
                        "codename": "xa_mong_tho_b",
                        "division_type": "xã",
                        "short_codename": "mong_tho_b"
                    },
                    {
                        "name": "Xã Mong Thọ",
                        "code": 30887,
                        "codename": "xa_mong_tho",
                        "division_type": "xã",
                        "short_codename": "mong_tho"
                    },
                    {
                        "name": "Xã Giục Tượng",
                        "code": 30889,
                        "codename": "xa_giuc_tuong",
                        "division_type": "xã",
                        "short_codename": "giuc_tuong"
                    },
                    {
                        "name": "Xã Vĩnh Hòa Hiệp",
                        "code": 30892,
                        "codename": "xa_vinh_hoa_hiep",
                        "division_type": "xã",
                        "short_codename": "vinh_hoa_hiep"
                    },
                    {
                        "name": "Xã Vĩnh Hoà Phú",
                        "code": 30893,
                        "codename": "xa_vinh_hoa_phu",
                        "division_type": "xã",
                        "short_codename": "vinh_hoa_phu"
                    },
                    {
                        "name": "Xã Minh Hòa",
                        "code": 30895,
                        "codename": "xa_minh_hoa",
                        "division_type": "xã",
                        "short_codename": "minh_hoa"
                    },
                    {
                        "name": "Xã Bình An",
                        "code": 30898,
                        "codename": "xa_binh_an",
                        "division_type": "xã",
                        "short_codename": "binh_an"
                    },
                    {
                        "name": "Xã Thạnh Lộc",
                        "code": 30901,
                        "codename": "xa_thanh_loc",
                        "division_type": "xã",
                        "short_codename": "thanh_loc"
                    }
                ]
            },
            {
                "name": "Huyện Giồng Riềng",
                "code": 906,
                "codename": "huyen_giong_rieng",
                "division_type": "huyện",
                "short_codename": "giong_rieng",
                "wards": [
                    {
                        "name": "Thị Trấn Giồng Riềng",
                        "code": 30904,
                        "codename": "thi_tran_giong_rieng",
                        "division_type": "thị trấn",
                        "short_codename": "giong_rieng"
                    },
                    {
                        "name": "Xã Thạnh Hưng",
                        "code": 30907,
                        "codename": "xa_thanh_hung",
                        "division_type": "xã",
                        "short_codename": "thanh_hung"
                    },
                    {
                        "name": "Xã Thạnh Phước",
                        "code": 30910,
                        "codename": "xa_thanh_phuoc",
                        "division_type": "xã",
                        "short_codename": "thanh_phuoc"
                    },
                    {
                        "name": "Xã Thạnh Lộc",
                        "code": 30913,
                        "codename": "xa_thanh_loc",
                        "division_type": "xã",
                        "short_codename": "thanh_loc"
                    },
                    {
                        "name": "Xã Thạnh Hòa",
                        "code": 30916,
                        "codename": "xa_thanh_hoa",
                        "division_type": "xã",
                        "short_codename": "thanh_hoa"
                    },
                    {
                        "name": "Xã Thạnh Bình",
                        "code": 30917,
                        "codename": "xa_thanh_binh",
                        "division_type": "xã",
                        "short_codename": "thanh_binh"
                    },
                    {
                        "name": "Xã Bàn Thạch",
                        "code": 30919,
                        "codename": "xa_ban_thach",
                        "division_type": "xã",
                        "short_codename": "ban_thach"
                    },
                    {
                        "name": "Xã Bàn Tân Định",
                        "code": 30922,
                        "codename": "xa_ban_tan_dinh",
                        "division_type": "xã",
                        "short_codename": "ban_tan_dinh"
                    },
                    {
                        "name": "Xã Ngọc Thành",
                        "code": 30925,
                        "codename": "xa_ngoc_thanh",
                        "division_type": "xã",
                        "short_codename": "ngoc_thanh"
                    },
                    {
                        "name": "Xã Ngọc Chúc",
                        "code": 30928,
                        "codename": "xa_ngoc_chuc",
                        "division_type": "xã",
                        "short_codename": "ngoc_chuc"
                    },
                    {
                        "name": "Xã Ngọc Thuận",
                        "code": 30931,
                        "codename": "xa_ngoc_thuan",
                        "division_type": "xã",
                        "short_codename": "ngoc_thuan"
                    },
                    {
                        "name": "Xã Hòa Hưng",
                        "code": 30934,
                        "codename": "xa_hoa_hung",
                        "division_type": "xã",
                        "short_codename": "hoa_hung"
                    },
                    {
                        "name": "Xã Hoà Lợi",
                        "code": 30937,
                        "codename": "xa_hoa_loi",
                        "division_type": "xã",
                        "short_codename": "hoa_loi"
                    },
                    {
                        "name": "Xã Hoà An",
                        "code": 30940,
                        "codename": "xa_hoa_an",
                        "division_type": "xã",
                        "short_codename": "hoa_an"
                    },
                    {
                        "name": "Xã Long Thạnh",
                        "code": 30943,
                        "codename": "xa_long_thanh",
                        "division_type": "xã",
                        "short_codename": "long_thanh"
                    },
                    {
                        "name": "Xã Vĩnh Thạnh",
                        "code": 30946,
                        "codename": "xa_vinh_thanh",
                        "division_type": "xã",
                        "short_codename": "vinh_thanh"
                    },
                    {
                        "name": "Xã Vĩnh Phú",
                        "code": 30947,
                        "codename": "xa_vinh_phu",
                        "division_type": "xã",
                        "short_codename": "vinh_phu"
                    },
                    {
                        "name": "Xã Hòa Thuận",
                        "code": 30949,
                        "codename": "xa_hoa_thuan",
                        "division_type": "xã",
                        "short_codename": "hoa_thuan"
                    },
                    {
                        "name": "Xã Ngọc Hoà",
                        "code": 30950,
                        "codename": "xa_ngoc_hoa",
                        "division_type": "xã",
                        "short_codename": "ngoc_hoa"
                    }
                ]
            },
            {
                "name": "Huyện Gò Quao",
                "code": 907,
                "codename": "huyen_go_quao",
                "division_type": "huyện",
                "short_codename": "go_quao",
                "wards": [
                    {
                        "name": "Thị trấn Gò Quao",
                        "code": 30952,
                        "codename": "thi_tran_go_quao",
                        "division_type": "thị trấn",
                        "short_codename": "go_quao"
                    },
                    {
                        "name": "Xã Vĩnh Hòa Hưng Bắc",
                        "code": 30955,
                        "codename": "xa_vinh_hoa_hung_bac",
                        "division_type": "xã",
                        "short_codename": "vinh_hoa_hung_bac"
                    },
                    {
                        "name": "Xã Định Hòa",
                        "code": 30958,
                        "codename": "xa_dinh_hoa",
                        "division_type": "xã",
                        "short_codename": "dinh_hoa"
                    },
                    {
                        "name": "Xã Thới Quản",
                        "code": 30961,
                        "codename": "xa_thoi_quan",
                        "division_type": "xã",
                        "short_codename": "thoi_quan"
                    },
                    {
                        "name": "Xã Định An",
                        "code": 30964,
                        "codename": "xa_dinh_an",
                        "division_type": "xã",
                        "short_codename": "dinh_an"
                    },
                    {
                        "name": "Xã Thủy Liễu",
                        "code": 30967,
                        "codename": "xa_thuy_lieu",
                        "division_type": "xã",
                        "short_codename": "thuy_lieu"
                    },
                    {
                        "name": "Xã Vĩnh Hòa Hưng Nam",
                        "code": 30970,
                        "codename": "xa_vinh_hoa_hung_nam",
                        "division_type": "xã",
                        "short_codename": "vinh_hoa_hung_nam"
                    },
                    {
                        "name": "Xã Vĩnh Phước A",
                        "code": 30973,
                        "codename": "xa_vinh_phuoc_a",
                        "division_type": "xã",
                        "short_codename": "vinh_phuoc_a"
                    },
                    {
                        "name": "Xã Vĩnh Phước B",
                        "code": 30976,
                        "codename": "xa_vinh_phuoc_b",
                        "division_type": "xã",
                        "short_codename": "vinh_phuoc_b"
                    },
                    {
                        "name": "Xã Vĩnh Tuy",
                        "code": 30979,
                        "codename": "xa_vinh_tuy",
                        "division_type": "xã",
                        "short_codename": "vinh_tuy"
                    },
                    {
                        "name": "Xã Vĩnh Thắng",
                        "code": 30982,
                        "codename": "xa_vinh_thang",
                        "division_type": "xã",
                        "short_codename": "vinh_thang"
                    }
                ]
            },
            {
                "name": "Huyện An Biên",
                "code": 908,
                "codename": "huyen_an_bien",
                "division_type": "huyện",
                "short_codename": "an_bien",
                "wards": [
                    {
                        "name": "Thị trấn Thứ Ba",
                        "code": 30985,
                        "codename": "thi_tran_thu_ba",
                        "division_type": "thị trấn",
                        "short_codename": "thu_ba"
                    },
                    {
                        "name": "Xã Tây Yên",
                        "code": 30988,
                        "codename": "xa_tay_yen",
                        "division_type": "xã",
                        "short_codename": "tay_yen"
                    },
                    {
                        "name": "Xã Tây Yên A",
                        "code": 30991,
                        "codename": "xa_tay_yen_a",
                        "division_type": "xã",
                        "short_codename": "tay_yen_a"
                    },
                    {
                        "name": "Xã Nam Yên",
                        "code": 30994,
                        "codename": "xa_nam_yen",
                        "division_type": "xã",
                        "short_codename": "nam_yen"
                    },
                    {
                        "name": "Xã Hưng Yên",
                        "code": 30997,
                        "codename": "xa_hung_yen",
                        "division_type": "xã",
                        "short_codename": "hung_yen"
                    },
                    {
                        "name": "Xã Nam Thái",
                        "code": 31000,
                        "codename": "xa_nam_thai",
                        "division_type": "xã",
                        "short_codename": "nam_thai"
                    },
                    {
                        "name": "Xã Nam Thái A",
                        "code": 31003,
                        "codename": "xa_nam_thai_a",
                        "division_type": "xã",
                        "short_codename": "nam_thai_a"
                    },
                    {
                        "name": "Xã Đông Thái",
                        "code": 31006,
                        "codename": "xa_dong_thai",
                        "division_type": "xã",
                        "short_codename": "dong_thai"
                    },
                    {
                        "name": "Xã Đông Yên",
                        "code": 31009,
                        "codename": "xa_dong_yen",
                        "division_type": "xã",
                        "short_codename": "dong_yen"
                    }
                ]
            },
            {
                "name": "Huyện An Minh",
                "code": 909,
                "codename": "huyen_an_minh",
                "division_type": "huyện",
                "short_codename": "an_minh",
                "wards": [
                    {
                        "name": "Thị trấn Thứ Mười Một",
                        "code": 31018,
                        "codename": "thi_tran_thu_muoi_mot",
                        "division_type": "thị trấn",
                        "short_codename": "thu_muoi_mot"
                    },
                    {
                        "name": "Xã Thuận Hoà",
                        "code": 31021,
                        "codename": "xa_thuan_hoa",
                        "division_type": "xã",
                        "short_codename": "thuan_hoa"
                    },
                    {
                        "name": "Xã Đông Hòa",
                        "code": 31024,
                        "codename": "xa_dong_hoa",
                        "division_type": "xã",
                        "short_codename": "dong_hoa"
                    },
                    {
                        "name": "Xã Đông Thạnh",
                        "code": 31030,
                        "codename": "xa_dong_thanh",
                        "division_type": "xã",
                        "short_codename": "dong_thanh"
                    },
                    {
                        "name": "Xã Tân Thạnh",
                        "code": 31031,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Xã Đông Hưng",
                        "code": 31033,
                        "codename": "xa_dong_hung",
                        "division_type": "xã",
                        "short_codename": "dong_hung"
                    },
                    {
                        "name": "Xã Đông Hưng A",
                        "code": 31036,
                        "codename": "xa_dong_hung_a",
                        "division_type": "xã",
                        "short_codename": "dong_hung_a"
                    },
                    {
                        "name": "Xã Đông Hưng B",
                        "code": 31039,
                        "codename": "xa_dong_hung_b",
                        "division_type": "xã",
                        "short_codename": "dong_hung_b"
                    },
                    {
                        "name": "Xã Vân Khánh",
                        "code": 31042,
                        "codename": "xa_van_khanh",
                        "division_type": "xã",
                        "short_codename": "van_khanh"
                    },
                    {
                        "name": "Xã Vân Khánh Đông",
                        "code": 31045,
                        "codename": "xa_van_khanh_dong",
                        "division_type": "xã",
                        "short_codename": "van_khanh_dong"
                    },
                    {
                        "name": "Xã Vân Khánh Tây",
                        "code": 31048,
                        "codename": "xa_van_khanh_tay",
                        "division_type": "xã",
                        "short_codename": "van_khanh_tay"
                    }
                ]
            },
            {
                "name": "Huyện Vĩnh Thuận",
                "code": 910,
                "codename": "huyen_vinh_thuan",
                "division_type": "huyện",
                "short_codename": "vinh_thuan",
                "wards": [
                    {
                        "name": "Thị trấn Vĩnh Thuận",
                        "code": 31051,
                        "codename": "thi_tran_vinh_thuan",
                        "division_type": "thị trấn",
                        "short_codename": "vinh_thuan"
                    },
                    {
                        "name": "Xã Vĩnh Bình Bắc",
                        "code": 31060,
                        "codename": "xa_vinh_binh_bac",
                        "division_type": "xã",
                        "short_codename": "vinh_binh_bac"
                    },
                    {
                        "name": "Xã Vĩnh Bình Nam",
                        "code": 31063,
                        "codename": "xa_vinh_binh_nam",
                        "division_type": "xã",
                        "short_codename": "vinh_binh_nam"
                    },
                    {
                        "name": "Xã Bình Minh",
                        "code": 31064,
                        "codename": "xa_binh_minh",
                        "division_type": "xã",
                        "short_codename": "binh_minh"
                    },
                    {
                        "name": "Xã Vĩnh Thuận",
                        "code": 31069,
                        "codename": "xa_vinh_thuan",
                        "division_type": "xã",
                        "short_codename": "xa_vinh_thuan"
                    },
                    {
                        "name": "Xã Tân Thuận",
                        "code": 31072,
                        "codename": "xa_tan_thuan",
                        "division_type": "xã",
                        "short_codename": "tan_thuan"
                    },
                    {
                        "name": "Xã Phong Đông",
                        "code": 31074,
                        "codename": "xa_phong_dong",
                        "division_type": "xã",
                        "short_codename": "phong_dong"
                    },
                    {
                        "name": "Xã Vĩnh Phong",
                        "code": 31075,
                        "codename": "xa_vinh_phong",
                        "division_type": "xã",
                        "short_codename": "vinh_phong"
                    }
                ]
            },
            {
                "name": "Thành phố Phú Quốc",
                "code": 911,
                "codename": "thanh_pho_phu_quoc",
                "division_type": "thành phố",
                "short_codename": "phu_quoc",
                "wards": [
                    {
                        "name": "Phường Dương Đông",
                        "code": 31078,
                        "codename": "phuong_duong_dong",
                        "division_type": "phường",
                        "short_codename": "duong_dong"
                    },
                    {
                        "name": "Phường An Thới",
                        "code": 31081,
                        "codename": "phuong_an_thoi",
                        "division_type": "phường",
                        "short_codename": "an_thoi"
                    },
                    {
                        "name": "Xã Cửa Cạn",
                        "code": 31084,
                        "codename": "xa_cua_can",
                        "division_type": "xã",
                        "short_codename": "cua_can"
                    },
                    {
                        "name": "Xã Gành Dầu",
                        "code": 31087,
                        "codename": "xa_ganh_dau",
                        "division_type": "xã",
                        "short_codename": "ganh_dau"
                    },
                    {
                        "name": "Xã Cửa Dương",
                        "code": 31090,
                        "codename": "xa_cua_duong",
                        "division_type": "xã",
                        "short_codename": "cua_duong"
                    },
                    {
                        "name": "Xã Hàm Ninh",
                        "code": 31093,
                        "codename": "xa_ham_ninh",
                        "division_type": "xã",
                        "short_codename": "ham_ninh"
                    },
                    {
                        "name": "Xã Dương Tơ",
                        "code": 31096,
                        "codename": "xa_duong_to",
                        "division_type": "xã",
                        "short_codename": "duong_to"
                    },
                    {
                        "name": "Xã Bãi Thơm",
                        "code": 31102,
                        "codename": "xa_bai_thom",
                        "division_type": "xã",
                        "short_codename": "bai_thom"
                    },
                    {
                        "name": "Xã Thổ Châu",
                        "code": 31105,
                        "codename": "xa_tho_chau",
                        "division_type": "xã",
                        "short_codename": "tho_chau"
                    }
                ]
            },
            {
                "name": "Huyện Kiên Hải",
                "code": 912,
                "codename": "huyen_kien_hai",
                "division_type": "huyện",
                "short_codename": "kien_hai",
                "wards": [
                    {
                        "name": "Xã Hòn Tre",
                        "code": 31108,
                        "codename": "xa_hon_tre",
                        "division_type": "xã",
                        "short_codename": "hon_tre"
                    },
                    {
                        "name": "Xã Lại Sơn",
                        "code": 31111,
                        "codename": "xa_lai_son",
                        "division_type": "xã",
                        "short_codename": "lai_son"
                    },
                    {
                        "name": "Xã An Sơn",
                        "code": 31114,
                        "codename": "xa_an_son",
                        "division_type": "xã",
                        "short_codename": "an_son"
                    },
                    {
                        "name": "Xã Nam Du",
                        "code": 31115,
                        "codename": "xa_nam_du",
                        "division_type": "xã",
                        "short_codename": "nam_du"
                    }
                ]
            },
            {
                "name": "Huyện U Minh Thượng",
                "code": 913,
                "codename": "huyen_u_minh_thuong",
                "division_type": "huyện",
                "short_codename": "u_minh_thuong",
                "wards": [
                    {
                        "name": "Xã Thạnh Yên",
                        "code": 31012,
                        "codename": "xa_thanh_yen",
                        "division_type": "xã",
                        "short_codename": "thanh_yen"
                    },
                    {
                        "name": "Xã Thạnh Yên A",
                        "code": 31015,
                        "codename": "xa_thanh_yen_a",
                        "division_type": "xã",
                        "short_codename": "thanh_yen_a"
                    },
                    {
                        "name": "Xã An Minh Bắc",
                        "code": 31027,
                        "codename": "xa_an_minh_bac",
                        "division_type": "xã",
                        "short_codename": "an_minh_bac"
                    },
                    {
                        "name": "Xã Vĩnh Hòa",
                        "code": 31054,
                        "codename": "xa_vinh_hoa",
                        "division_type": "xã",
                        "short_codename": "vinh_hoa"
                    },
                    {
                        "name": "Xã Hoà Chánh",
                        "code": 31057,
                        "codename": "xa_hoa_chanh",
                        "division_type": "xã",
                        "short_codename": "hoa_chanh"
                    },
                    {
                        "name": "Xã Minh Thuận",
                        "code": 31066,
                        "codename": "xa_minh_thuan",
                        "division_type": "xã",
                        "short_codename": "minh_thuan"
                    }
                ]
            },
            {
                "name": "Huyện Giang Thành",
                "code": 914,
                "codename": "huyen_giang_thanh",
                "division_type": "huyện",
                "short_codename": "giang_thanh",
                "wards": [
                    {
                        "name": "Xã Vĩnh Phú",
                        "code": 30791,
                        "codename": "xa_vinh_phu",
                        "division_type": "xã",
                        "short_codename": "vinh_phu"
                    },
                    {
                        "name": "Xã Vĩnh Điều",
                        "code": 30793,
                        "codename": "xa_vinh_dieu",
                        "division_type": "xã",
                        "short_codename": "vinh_dieu"
                    },
                    {
                        "name": "Xã Tân Khánh Hòa",
                        "code": 30796,
                        "codename": "xa_tan_khanh_hoa",
                        "division_type": "xã",
                        "short_codename": "tan_khanh_hoa"
                    },
                    {
                        "name": "Xã Phú Lợi",
                        "code": 30797,
                        "codename": "xa_phu_loi",
                        "division_type": "xã",
                        "short_codename": "phu_loi"
                    },
                    {
                        "name": "Xã Phú Mỹ",
                        "code": 30799,
                        "codename": "xa_phu_my",
                        "division_type": "xã",
                        "short_codename": "phu_my"
                    }
                ]
            }
        ]
    },
    {
        "name": "Thành phố Cần Thơ",
        "code": 92,
        "codename": "thanh_pho_can_tho",
        "division_type": "thành phố trung ương",
        "phone_code": 292,
        "districts": [
            {
                "name": "Quận Ninh Kiều",
                "code": 916,
                "codename": "quan_ninh_kieu",
                "division_type": "quận",
                "short_codename": "ninh_kieu",
                "wards": [
                    {
                        "name": "Phường Cái Khế",
                        "code": 31117,
                        "codename": "phuong_cai_khe",
                        "division_type": "phường",
                        "short_codename": "cai_khe"
                    },
                    {
                        "name": "Phường An Hòa",
                        "code": 31120,
                        "codename": "phuong_an_hoa",
                        "division_type": "phường",
                        "short_codename": "an_hoa"
                    },
                    {
                        "name": "Phường Thới Bình",
                        "code": 31123,
                        "codename": "phuong_thoi_binh",
                        "division_type": "phường",
                        "short_codename": "thoi_binh"
                    },
                    {
                        "name": "Phường An Nghiệp",
                        "code": 31126,
                        "codename": "phuong_an_nghiep",
                        "division_type": "phường",
                        "short_codename": "an_nghiep"
                    },
                    {
                        "name": "Phường An Cư",
                        "code": 31129,
                        "codename": "phuong_an_cu",
                        "division_type": "phường",
                        "short_codename": "an_cu"
                    },
                    {
                        "name": "Phường Tân An",
                        "code": 31135,
                        "codename": "phuong_tan_an",
                        "division_type": "phường",
                        "short_codename": "tan_an"
                    },
                    {
                        "name": "Phường An Phú",
                        "code": 31141,
                        "codename": "phuong_an_phu",
                        "division_type": "phường",
                        "short_codename": "an_phu"
                    },
                    {
                        "name": "Phường Xuân Khánh",
                        "code": 31144,
                        "codename": "phuong_xuan_khanh",
                        "division_type": "phường",
                        "short_codename": "xuan_khanh"
                    },
                    {
                        "name": "Phường Hưng Lợi",
                        "code": 31147,
                        "codename": "phuong_hung_loi",
                        "division_type": "phường",
                        "short_codename": "hung_loi"
                    },
                    {
                        "name": "Phường An Khánh",
                        "code": 31149,
                        "codename": "phuong_an_khanh",
                        "division_type": "phường",
                        "short_codename": "an_khanh"
                    },
                    {
                        "name": "Phường An Bình",
                        "code": 31150,
                        "codename": "phuong_an_binh",
                        "division_type": "phường",
                        "short_codename": "an_binh"
                    }
                ]
            },
            {
                "name": "Quận Ô Môn",
                "code": 917,
                "codename": "quan_o_mon",
                "division_type": "quận",
                "short_codename": "o_mon",
                "wards": [
                    {
                        "name": "Phường Châu Văn Liêm",
                        "code": 31153,
                        "codename": "phuong_chau_van_liem",
                        "division_type": "phường",
                        "short_codename": "chau_van_liem"
                    },
                    {
                        "name": "Phường Thới Hòa",
                        "code": 31154,
                        "codename": "phuong_thoi_hoa",
                        "division_type": "phường",
                        "short_codename": "thoi_hoa"
                    },
                    {
                        "name": "Phường Thới Long",
                        "code": 31156,
                        "codename": "phuong_thoi_long",
                        "division_type": "phường",
                        "short_codename": "thoi_long"
                    },
                    {
                        "name": "Phường Long Hưng",
                        "code": 31157,
                        "codename": "phuong_long_hung",
                        "division_type": "phường",
                        "short_codename": "long_hung"
                    },
                    {
                        "name": "Phường Thới An",
                        "code": 31159,
                        "codename": "phuong_thoi_an",
                        "division_type": "phường",
                        "short_codename": "thoi_an"
                    },
                    {
                        "name": "Phường Phước Thới",
                        "code": 31162,
                        "codename": "phuong_phuoc_thoi",
                        "division_type": "phường",
                        "short_codename": "phuoc_thoi"
                    },
                    {
                        "name": "Phường Trường Lạc",
                        "code": 31165,
                        "codename": "phuong_truong_lac",
                        "division_type": "phường",
                        "short_codename": "truong_lac"
                    }
                ]
            },
            {
                "name": "Quận Bình Thuỷ",
                "code": 918,
                "codename": "quan_binh_thuy",
                "division_type": "quận",
                "short_codename": "binh_thuy",
                "wards": [
                    {
                        "name": "Phường Bình Thủy",
                        "code": 31168,
                        "codename": "phuong_binh_thuy",
                        "division_type": "phường",
                        "short_codename": "binh_thuy"
                    },
                    {
                        "name": "Phường Trà An",
                        "code": 31169,
                        "codename": "phuong_tra_an",
                        "division_type": "phường",
                        "short_codename": "tra_an"
                    },
                    {
                        "name": "Phường Trà Nóc",
                        "code": 31171,
                        "codename": "phuong_tra_noc",
                        "division_type": "phường",
                        "short_codename": "tra_noc"
                    },
                    {
                        "name": "Phường Thới An Đông",
                        "code": 31174,
                        "codename": "phuong_thoi_an_dong",
                        "division_type": "phường",
                        "short_codename": "thoi_an_dong"
                    },
                    {
                        "name": "Phường An Thới",
                        "code": 31177,
                        "codename": "phuong_an_thoi",
                        "division_type": "phường",
                        "short_codename": "an_thoi"
                    },
                    {
                        "name": "Phường Bùi Hữu Nghĩa",
                        "code": 31178,
                        "codename": "phuong_bui_huu_nghia",
                        "division_type": "phường",
                        "short_codename": "bui_huu_nghia"
                    },
                    {
                        "name": "Phường Long Hòa",
                        "code": 31180,
                        "codename": "phuong_long_hoa",
                        "division_type": "phường",
                        "short_codename": "long_hoa"
                    },
                    {
                        "name": "Phường Long Tuyền",
                        "code": 31183,
                        "codename": "phuong_long_tuyen",
                        "division_type": "phường",
                        "short_codename": "long_tuyen"
                    }
                ]
            },
            {
                "name": "Quận Cái Răng",
                "code": 919,
                "codename": "quan_cai_rang",
                "division_type": "quận",
                "short_codename": "cai_rang",
                "wards": [
                    {
                        "name": "Phường Lê Bình",
                        "code": 31186,
                        "codename": "phuong_le_binh",
                        "division_type": "phường",
                        "short_codename": "le_binh"
                    },
                    {
                        "name": "Phường Hưng Phú",
                        "code": 31189,
                        "codename": "phuong_hung_phu",
                        "division_type": "phường",
                        "short_codename": "hung_phu"
                    },
                    {
                        "name": "Phường Hưng Thạnh",
                        "code": 31192,
                        "codename": "phuong_hung_thanh",
                        "division_type": "phường",
                        "short_codename": "hung_thanh"
                    },
                    {
                        "name": "Phường Ba Láng",
                        "code": 31195,
                        "codename": "phuong_ba_lang",
                        "division_type": "phường",
                        "short_codename": "ba_lang"
                    },
                    {
                        "name": "Phường Thường Thạnh",
                        "code": 31198,
                        "codename": "phuong_thuong_thanh",
                        "division_type": "phường",
                        "short_codename": "thuong_thanh"
                    },
                    {
                        "name": "Phường Phú Thứ",
                        "code": 31201,
                        "codename": "phuong_phu_thu",
                        "division_type": "phường",
                        "short_codename": "phu_thu"
                    },
                    {
                        "name": "Phường Tân Phú",
                        "code": 31204,
                        "codename": "phuong_tan_phu",
                        "division_type": "phường",
                        "short_codename": "tan_phu"
                    }
                ]
            },
            {
                "name": "Quận Thốt Nốt",
                "code": 923,
                "codename": "quan_thot_not",
                "division_type": "quận",
                "short_codename": "thot_not",
                "wards": [
                    {
                        "name": "Phường Thốt Nốt",
                        "code": 31207,
                        "codename": "phuong_thot_not",
                        "division_type": "phường",
                        "short_codename": "thot_not"
                    },
                    {
                        "name": "Phường Thới Thuận",
                        "code": 31210,
                        "codename": "phuong_thoi_thuan",
                        "division_type": "phường",
                        "short_codename": "thoi_thuan"
                    },
                    {
                        "name": "Phường Thuận An",
                        "code": 31212,
                        "codename": "phuong_thuan_an",
                        "division_type": "phường",
                        "short_codename": "thuan_an"
                    },
                    {
                        "name": "Phường Tân Lộc",
                        "code": 31213,
                        "codename": "phuong_tan_loc",
                        "division_type": "phường",
                        "short_codename": "tan_loc"
                    },
                    {
                        "name": "Phường Trung Nhứt",
                        "code": 31216,
                        "codename": "phuong_trung_nhut",
                        "division_type": "phường",
                        "short_codename": "trung_nhut"
                    },
                    {
                        "name": "Phường Thạnh Hoà",
                        "code": 31217,
                        "codename": "phuong_thanh_hoa",
                        "division_type": "phường",
                        "short_codename": "thanh_hoa"
                    },
                    {
                        "name": "Phường Trung Kiên",
                        "code": 31219,
                        "codename": "phuong_trung_kien",
                        "division_type": "phường",
                        "short_codename": "trung_kien"
                    },
                    {
                        "name": "Phường Tân Hưng",
                        "code": 31227,
                        "codename": "phuong_tan_hung",
                        "division_type": "phường",
                        "short_codename": "tan_hung"
                    },
                    {
                        "name": "Phường Thuận Hưng",
                        "code": 31228,
                        "codename": "phuong_thuan_hung",
                        "division_type": "phường",
                        "short_codename": "thuan_hung"
                    }
                ]
            },
            {
                "name": "Huyện Vĩnh Thạnh",
                "code": 924,
                "codename": "huyen_vinh_thanh",
                "division_type": "huyện",
                "short_codename": "vinh_thanh",
                "wards": [
                    {
                        "name": "Xã Vĩnh Bình",
                        "code": 31211,
                        "codename": "xa_vinh_binh",
                        "division_type": "xã",
                        "short_codename": "vinh_binh"
                    },
                    {
                        "name": "Thị trấn Thanh An",
                        "code": 31231,
                        "codename": "thi_tran_thanh_an",
                        "division_type": "thị trấn",
                        "short_codename": "thanh_an"
                    },
                    {
                        "name": "Thị trấn Vĩnh Thạnh",
                        "code": 31232,
                        "codename": "thi_tran_vinh_thanh",
                        "division_type": "thị trấn",
                        "short_codename": "vinh_thanh"
                    },
                    {
                        "name": "Xã Thạnh Mỹ",
                        "code": 31234,
                        "codename": "xa_thanh_my",
                        "division_type": "xã",
                        "short_codename": "thanh_my"
                    },
                    {
                        "name": "Xã Vĩnh Trinh",
                        "code": 31237,
                        "codename": "xa_vinh_trinh",
                        "division_type": "xã",
                        "short_codename": "vinh_trinh"
                    },
                    {
                        "name": "Xã Thạnh An",
                        "code": 31240,
                        "codename": "xa_thanh_an",
                        "division_type": "xã",
                        "short_codename": "xa_thanh_an"
                    },
                    {
                        "name": "Xã Thạnh Tiến",
                        "code": 31241,
                        "codename": "xa_thanh_tien",
                        "division_type": "xã",
                        "short_codename": "thanh_tien"
                    },
                    {
                        "name": "Xã Thạnh Thắng",
                        "code": 31243,
                        "codename": "xa_thanh_thang",
                        "division_type": "xã",
                        "short_codename": "thanh_thang"
                    },
                    {
                        "name": "Xã Thạnh Lợi",
                        "code": 31244,
                        "codename": "xa_thanh_loi",
                        "division_type": "xã",
                        "short_codename": "thanh_loi"
                    },
                    {
                        "name": "Xã Thạnh Qưới",
                        "code": 31246,
                        "codename": "xa_thanh_quoi",
                        "division_type": "xã",
                        "short_codename": "thanh_quoi"
                    },
                    {
                        "name": "Xã Thạnh Lộc",
                        "code": 31252,
                        "codename": "xa_thanh_loc",
                        "division_type": "xã",
                        "short_codename": "thanh_loc"
                    }
                ]
            },
            {
                "name": "Huyện Cờ Đỏ",
                "code": 925,
                "codename": "huyen_co_do",
                "division_type": "huyện",
                "short_codename": "co_do",
                "wards": [
                    {
                        "name": "Xã Trung An",
                        "code": 31222,
                        "codename": "xa_trung_an",
                        "division_type": "xã",
                        "short_codename": "trung_an"
                    },
                    {
                        "name": "Xã Trung Thạnh",
                        "code": 31225,
                        "codename": "xa_trung_thanh",
                        "division_type": "xã",
                        "short_codename": "trung_thanh"
                    },
                    {
                        "name": "Xã Thạnh Phú",
                        "code": 31249,
                        "codename": "xa_thanh_phu",
                        "division_type": "xã",
                        "short_codename": "thanh_phu"
                    },
                    {
                        "name": "Xã Trung Hưng",
                        "code": 31255,
                        "codename": "xa_trung_hung",
                        "division_type": "xã",
                        "short_codename": "trung_hung"
                    },
                    {
                        "name": "Thị trấn Cờ Đỏ",
                        "code": 31261,
                        "codename": "thi_tran_co_do",
                        "division_type": "thị trấn",
                        "short_codename": "co_do"
                    },
                    {
                        "name": "Xã Thới Hưng",
                        "code": 31264,
                        "codename": "xa_thoi_hung",
                        "division_type": "xã",
                        "short_codename": "thoi_hung"
                    },
                    {
                        "name": "Xã Đông Hiệp",
                        "code": 31273,
                        "codename": "xa_dong_hiep",
                        "division_type": "xã",
                        "short_codename": "dong_hiep"
                    },
                    {
                        "name": "Xã Đông Thắng",
                        "code": 31274,
                        "codename": "xa_dong_thang",
                        "division_type": "xã",
                        "short_codename": "dong_thang"
                    },
                    {
                        "name": "Xã Thới Đông",
                        "code": 31276,
                        "codename": "xa_thoi_dong",
                        "division_type": "xã",
                        "short_codename": "thoi_dong"
                    },
                    {
                        "name": "Xã Thới Xuân",
                        "code": 31277,
                        "codename": "xa_thoi_xuan",
                        "division_type": "xã",
                        "short_codename": "thoi_xuan"
                    }
                ]
            },
            {
                "name": "Huyện Phong Điền",
                "code": 926,
                "codename": "huyen_phong_dien",
                "division_type": "huyện",
                "short_codename": "phong_dien",
                "wards": [
                    {
                        "name": "Thị trấn Phong Điền",
                        "code": 31299,
                        "codename": "thi_tran_phong_dien",
                        "division_type": "thị trấn",
                        "short_codename": "phong_dien"
                    },
                    {
                        "name": "Xã Nhơn Ái",
                        "code": 31300,
                        "codename": "xa_nhon_ai",
                        "division_type": "xã",
                        "short_codename": "nhon_ai"
                    },
                    {
                        "name": "Xã Giai Xuân",
                        "code": 31303,
                        "codename": "xa_giai_xuan",
                        "division_type": "xã",
                        "short_codename": "giai_xuan"
                    },
                    {
                        "name": "Xã Tân Thới",
                        "code": 31306,
                        "codename": "xa_tan_thoi",
                        "division_type": "xã",
                        "short_codename": "tan_thoi"
                    },
                    {
                        "name": "Xã Trường Long",
                        "code": 31309,
                        "codename": "xa_truong_long",
                        "division_type": "xã",
                        "short_codename": "truong_long"
                    },
                    {
                        "name": "Xã Mỹ Khánh",
                        "code": 31312,
                        "codename": "xa_my_khanh",
                        "division_type": "xã",
                        "short_codename": "my_khanh"
                    },
                    {
                        "name": "Xã Nhơn Nghĩa",
                        "code": 31315,
                        "codename": "xa_nhon_nghia",
                        "division_type": "xã",
                        "short_codename": "nhon_nghia"
                    }
                ]
            },
            {
                "name": "Huyện Thới Lai",
                "code": 927,
                "codename": "huyen_thoi_lai",
                "division_type": "huyện",
                "short_codename": "thoi_lai",
                "wards": [
                    {
                        "name": "Thị trấn Thới Lai",
                        "code": 31258,
                        "codename": "thi_tran_thoi_lai",
                        "division_type": "thị trấn",
                        "short_codename": "thoi_lai"
                    },
                    {
                        "name": "Xã Thới Thạnh",
                        "code": 31267,
                        "codename": "xa_thoi_thanh",
                        "division_type": "xã",
                        "short_codename": "thoi_thanh"
                    },
                    {
                        "name": "Xã Tân Thạnh",
                        "code": 31268,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Xã Xuân Thắng",
                        "code": 31270,
                        "codename": "xa_xuan_thang",
                        "division_type": "xã",
                        "short_codename": "xuan_thang"
                    },
                    {
                        "name": "Xã Đông Bình",
                        "code": 31279,
                        "codename": "xa_dong_binh",
                        "division_type": "xã",
                        "short_codename": "dong_binh"
                    },
                    {
                        "name": "Xã Đông Thuận",
                        "code": 31282,
                        "codename": "xa_dong_thuan",
                        "division_type": "xã",
                        "short_codename": "dong_thuan"
                    },
                    {
                        "name": "Xã Thới Tân",
                        "code": 31285,
                        "codename": "xa_thoi_tan",
                        "division_type": "xã",
                        "short_codename": "thoi_tan"
                    },
                    {
                        "name": "Xã Trường Thắng",
                        "code": 31286,
                        "codename": "xa_truong_thang",
                        "division_type": "xã",
                        "short_codename": "truong_thang"
                    },
                    {
                        "name": "Xã Định Môn",
                        "code": 31288,
                        "codename": "xa_dinh_mon",
                        "division_type": "xã",
                        "short_codename": "dinh_mon"
                    },
                    {
                        "name": "Xã Trường Thành",
                        "code": 31291,
                        "codename": "xa_truong_thanh",
                        "division_type": "xã",
                        "short_codename": "truong_thanh"
                    },
                    {
                        "name": "Xã Trường Xuân",
                        "code": 31294,
                        "codename": "xa_truong_xuan",
                        "division_type": "xã",
                        "short_codename": "truong_xuan"
                    },
                    {
                        "name": "Xã Trường Xuân A",
                        "code": 31297,
                        "codename": "xa_truong_xuan_a",
                        "division_type": "xã",
                        "short_codename": "truong_xuan_a"
                    },
                    {
                        "name": "Xã Trường Xuân B",
                        "code": 31298,
                        "codename": "xa_truong_xuan_b",
                        "division_type": "xã",
                        "short_codename": "truong_xuan_b"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Hậu Giang",
        "code": 93,
        "codename": "tinh_hau_giang",
        "division_type": "tỉnh",
        "phone_code": 293,
        "districts": [
            {
                "name": "Thành phố Vị Thanh",
                "code": 930,
                "codename": "thanh_pho_vi_thanh",
                "division_type": "thành phố",
                "short_codename": "vi_thanh",
                "wards": [
                    {
                        "name": "Phường I",
                        "code": 31318,
                        "codename": "phuong_i",
                        "division_type": "phường",
                        "short_codename": "i"
                    },
                    {
                        "name": "Phường III",
                        "code": 31321,
                        "codename": "phuong_iii",
                        "division_type": "phường",
                        "short_codename": "iii"
                    },
                    {
                        "name": "Phường IV",
                        "code": 31324,
                        "codename": "phuong_iv",
                        "division_type": "phường",
                        "short_codename": "iv"
                    },
                    {
                        "name": "Phường V",
                        "code": 31327,
                        "codename": "phuong_v",
                        "division_type": "phường",
                        "short_codename": "v"
                    },
                    {
                        "name": "Phường VII",
                        "code": 31330,
                        "codename": "phuong_vii",
                        "division_type": "phường",
                        "short_codename": "vii"
                    },
                    {
                        "name": "Xã Vị Tân",
                        "code": 31333,
                        "codename": "xa_vi_tan",
                        "division_type": "xã",
                        "short_codename": "vi_tan"
                    },
                    {
                        "name": "Xã Hoả Lựu",
                        "code": 31336,
                        "codename": "xa_hoa_luu",
                        "division_type": "xã",
                        "short_codename": "hoa_luu"
                    },
                    {
                        "name": "Xã Tân Tiến",
                        "code": 31338,
                        "codename": "xa_tan_tien",
                        "division_type": "xã",
                        "short_codename": "tan_tien"
                    },
                    {
                        "name": "Xã Hoả Tiến",
                        "code": 31339,
                        "codename": "xa_hoa_tien",
                        "division_type": "xã",
                        "short_codename": "hoa_tien"
                    }
                ]
            },
            {
                "name": "Thành phố Ngã Bảy",
                "code": 931,
                "codename": "thanh_pho_nga_bay",
                "division_type": "thành phố",
                "short_codename": "nga_bay",
                "wards": [
                    {
                        "name": "Phường Ngã Bảy",
                        "code": 31340,
                        "codename": "phuong_nga_bay",
                        "division_type": "phường",
                        "short_codename": "nga_bay"
                    },
                    {
                        "name": "Phường Lái Hiếu",
                        "code": 31341,
                        "codename": "phuong_lai_hieu",
                        "division_type": "phường",
                        "short_codename": "lai_hieu"
                    },
                    {
                        "name": "Phường Hiệp Thành",
                        "code": 31343,
                        "codename": "phuong_hiep_thanh",
                        "division_type": "phường",
                        "short_codename": "hiep_thanh"
                    },
                    {
                        "name": "Phường Hiệp Lợi",
                        "code": 31344,
                        "codename": "phuong_hiep_loi",
                        "division_type": "phường",
                        "short_codename": "hiep_loi"
                    },
                    {
                        "name": "Xã Đại Thành",
                        "code": 31411,
                        "codename": "xa_dai_thanh",
                        "division_type": "xã",
                        "short_codename": "dai_thanh"
                    },
                    {
                        "name": "Xã Tân Thành",
                        "code": 31414,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    }
                ]
            },
            {
                "name": "Huyện Châu Thành A",
                "code": 932,
                "codename": "huyen_chau_thanh_a",
                "division_type": "huyện",
                "short_codename": "chau_thanh_a",
                "wards": [
                    {
                        "name": "Thị trấn Một Ngàn",
                        "code": 31342,
                        "codename": "thi_tran_mot_ngan",
                        "division_type": "thị trấn",
                        "short_codename": "mot_ngan"
                    },
                    {
                        "name": "Xã Tân Hoà",
                        "code": 31345,
                        "codename": "xa_tan_hoa",
                        "division_type": "xã",
                        "short_codename": "tan_hoa"
                    },
                    {
                        "name": "Thị trấn Bảy Ngàn",
                        "code": 31346,
                        "codename": "thi_tran_bay_ngan",
                        "division_type": "thị trấn",
                        "short_codename": "bay_ngan"
                    },
                    {
                        "name": "Xã Trường Long Tây",
                        "code": 31348,
                        "codename": "xa_truong_long_tay",
                        "division_type": "xã",
                        "short_codename": "truong_long_tay"
                    },
                    {
                        "name": "Xã Trường Long A",
                        "code": 31351,
                        "codename": "xa_truong_long_a",
                        "division_type": "xã",
                        "short_codename": "truong_long_a"
                    },
                    {
                        "name": "Xã Nhơn Nghĩa A",
                        "code": 31357,
                        "codename": "xa_nhon_nghia_a",
                        "division_type": "xã",
                        "short_codename": "nhon_nghia_a"
                    },
                    {
                        "name": "Thị trấn Rạch Gòi",
                        "code": 31359,
                        "codename": "thi_tran_rach_goi",
                        "division_type": "thị trấn",
                        "short_codename": "rach_goi"
                    },
                    {
                        "name": "Xã Thạnh Xuân",
                        "code": 31360,
                        "codename": "xa_thanh_xuan",
                        "division_type": "xã",
                        "short_codename": "thanh_xuan"
                    },
                    {
                        "name": "Thị trấn Cái Tắc",
                        "code": 31362,
                        "codename": "thi_tran_cai_tac",
                        "division_type": "thị trấn",
                        "short_codename": "cai_tac"
                    },
                    {
                        "name": "Xã Tân Phú Thạnh",
                        "code": 31363,
                        "codename": "xa_tan_phu_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_phu_thanh"
                    }
                ]
            },
            {
                "name": "Huyện Châu Thành",
                "code": 933,
                "codename": "huyen_chau_thanh",
                "division_type": "huyện",
                "short_codename": "chau_thanh",
                "wards": [
                    {
                        "name": "Thị Trấn Ngã Sáu",
                        "code": 31366,
                        "codename": "thi_tran_nga_sau",
                        "division_type": "thị trấn",
                        "short_codename": "nga_sau"
                    },
                    {
                        "name": "Xã Đông Thạnh",
                        "code": 31369,
                        "codename": "xa_dong_thanh",
                        "division_type": "xã",
                        "short_codename": "dong_thanh"
                    },
                    {
                        "name": "Xã Đông Phú",
                        "code": 31375,
                        "codename": "xa_dong_phu",
                        "division_type": "xã",
                        "short_codename": "dong_phu"
                    },
                    {
                        "name": "Xã Phú Hữu",
                        "code": 31378,
                        "codename": "xa_phu_huu",
                        "division_type": "xã",
                        "short_codename": "phu_huu"
                    },
                    {
                        "name": "Xã Phú Tân",
                        "code": 31379,
                        "codename": "xa_phu_tan",
                        "division_type": "xã",
                        "short_codename": "phu_tan"
                    },
                    {
                        "name": "Thị trấn Mái Dầm",
                        "code": 31381,
                        "codename": "thi_tran_mai_dam",
                        "division_type": "thị trấn",
                        "short_codename": "mai_dam"
                    },
                    {
                        "name": "Xã Đông Phước",
                        "code": 31384,
                        "codename": "xa_dong_phuoc",
                        "division_type": "xã",
                        "short_codename": "dong_phuoc"
                    },
                    {
                        "name": "Xã Đông Phước A",
                        "code": 31387,
                        "codename": "xa_dong_phuoc_a",
                        "division_type": "xã",
                        "short_codename": "dong_phuoc_a"
                    }
                ]
            },
            {
                "name": "Huyện Phụng Hiệp",
                "code": 934,
                "codename": "huyen_phung_hiep",
                "division_type": "huyện",
                "short_codename": "phung_hiep",
                "wards": [
                    {
                        "name": "Thị trấn Kinh Cùng",
                        "code": 31393,
                        "codename": "thi_tran_kinh_cung",
                        "division_type": "thị trấn",
                        "short_codename": "kinh_cung"
                    },
                    {
                        "name": "Thị trấn Cây Dương",
                        "code": 31396,
                        "codename": "thi_tran_cay_duong",
                        "division_type": "thị trấn",
                        "short_codename": "cay_duong"
                    },
                    {
                        "name": "Xã Tân Bình",
                        "code": 31399,
                        "codename": "xa_tan_binh",
                        "division_type": "xã",
                        "short_codename": "tan_binh"
                    },
                    {
                        "name": "Xã Bình Thành",
                        "code": 31402,
                        "codename": "xa_binh_thanh",
                        "division_type": "xã",
                        "short_codename": "binh_thanh"
                    },
                    {
                        "name": "Xã Thạnh Hòa",
                        "code": 31405,
                        "codename": "xa_thanh_hoa",
                        "division_type": "xã",
                        "short_codename": "thanh_hoa"
                    },
                    {
                        "name": "Xã Long Thạnh",
                        "code": 31408,
                        "codename": "xa_long_thanh",
                        "division_type": "xã",
                        "short_codename": "long_thanh"
                    },
                    {
                        "name": "Xã Phụng Hiệp",
                        "code": 31417,
                        "codename": "xa_phung_hiep",
                        "division_type": "xã",
                        "short_codename": "phung_hiep"
                    },
                    {
                        "name": "Xã Hòa Mỹ",
                        "code": 31420,
                        "codename": "xa_hoa_my",
                        "division_type": "xã",
                        "short_codename": "hoa_my"
                    },
                    {
                        "name": "Xã Hòa An",
                        "code": 31423,
                        "codename": "xa_hoa_an",
                        "division_type": "xã",
                        "short_codename": "hoa_an"
                    },
                    {
                        "name": "Xã Phương Bình",
                        "code": 31426,
                        "codename": "xa_phuong_binh",
                        "division_type": "xã",
                        "short_codename": "phuong_binh"
                    },
                    {
                        "name": "Xã Hiệp Hưng",
                        "code": 31429,
                        "codename": "xa_hiep_hung",
                        "division_type": "xã",
                        "short_codename": "hiep_hung"
                    },
                    {
                        "name": "Xã Tân Phước Hưng",
                        "code": 31432,
                        "codename": "xa_tan_phuoc_hung",
                        "division_type": "xã",
                        "short_codename": "tan_phuoc_hung"
                    },
                    {
                        "name": "Thị trấn Búng Tàu",
                        "code": 31433,
                        "codename": "thi_tran_bung_tau",
                        "division_type": "thị trấn",
                        "short_codename": "bung_tau"
                    },
                    {
                        "name": "Xã Phương Phú",
                        "code": 31435,
                        "codename": "xa_phuong_phu",
                        "division_type": "xã",
                        "short_codename": "phuong_phu"
                    },
                    {
                        "name": "Xã Tân Long",
                        "code": 31438,
                        "codename": "xa_tan_long",
                        "division_type": "xã",
                        "short_codename": "tan_long"
                    }
                ]
            },
            {
                "name": "Huyện Vị Thuỷ",
                "code": 935,
                "codename": "huyen_vi_thuy",
                "division_type": "huyện",
                "short_codename": "vi_thuy",
                "wards": [
                    {
                        "name": "Thị trấn Nàng Mau",
                        "code": 31441,
                        "codename": "thi_tran_nang_mau",
                        "division_type": "thị trấn",
                        "short_codename": "nang_mau"
                    },
                    {
                        "name": "Xã Vị Trung",
                        "code": 31444,
                        "codename": "xa_vi_trung",
                        "division_type": "xã",
                        "short_codename": "vi_trung"
                    },
                    {
                        "name": "Xã Vị Thuỷ",
                        "code": 31447,
                        "codename": "xa_vi_thuy",
                        "division_type": "xã",
                        "short_codename": "vi_thuy"
                    },
                    {
                        "name": "Xã Vị Thắng",
                        "code": 31450,
                        "codename": "xa_vi_thang",
                        "division_type": "xã",
                        "short_codename": "vi_thang"
                    },
                    {
                        "name": "Xã Vĩnh Thuận Tây",
                        "code": 31453,
                        "codename": "xa_vinh_thuan_tay",
                        "division_type": "xã",
                        "short_codename": "vinh_thuan_tay"
                    },
                    {
                        "name": "Xã Vĩnh Trung",
                        "code": 31456,
                        "codename": "xa_vinh_trung",
                        "division_type": "xã",
                        "short_codename": "vinh_trung"
                    },
                    {
                        "name": "Xã Vĩnh Tường",
                        "code": 31459,
                        "codename": "xa_vinh_tuong",
                        "division_type": "xã",
                        "short_codename": "vinh_tuong"
                    },
                    {
                        "name": "Xã Vị Đông",
                        "code": 31462,
                        "codename": "xa_vi_dong",
                        "division_type": "xã",
                        "short_codename": "vi_dong"
                    },
                    {
                        "name": "Xã Vị Thanh",
                        "code": 31465,
                        "codename": "xa_vi_thanh",
                        "division_type": "xã",
                        "short_codename": "vi_thanh"
                    },
                    {
                        "name": "Xã Vị Bình",
                        "code": 31468,
                        "codename": "xa_vi_binh",
                        "division_type": "xã",
                        "short_codename": "vi_binh"
                    }
                ]
            },
            {
                "name": "Huyện Long Mỹ",
                "code": 936,
                "codename": "huyen_long_my",
                "division_type": "huyện",
                "short_codename": "huyen_long_my",
                "wards": [
                    {
                        "name": "Xã Thuận Hưng",
                        "code": 31483,
                        "codename": "xa_thuan_hung",
                        "division_type": "xã",
                        "short_codename": "thuan_hung"
                    },
                    {
                        "name": "Xã Thuận Hòa",
                        "code": 31484,
                        "codename": "xa_thuan_hoa",
                        "division_type": "xã",
                        "short_codename": "thuan_hoa"
                    },
                    {
                        "name": "Xã Vĩnh Thuận Đông",
                        "code": 31486,
                        "codename": "xa_vinh_thuan_dong",
                        "division_type": "xã",
                        "short_codename": "vinh_thuan_dong"
                    },
                    {
                        "name": "Thị trấn Vĩnh Viễn",
                        "code": 31489,
                        "codename": "thi_tran_vinh_vien",
                        "division_type": "thị trấn",
                        "short_codename": "vinh_vien"
                    },
                    {
                        "name": "Xã Vĩnh Viễn A",
                        "code": 31490,
                        "codename": "xa_vinh_vien_a",
                        "division_type": "xã",
                        "short_codename": "vinh_vien_a"
                    },
                    {
                        "name": "Xã Lương Tâm",
                        "code": 31492,
                        "codename": "xa_luong_tam",
                        "division_type": "xã",
                        "short_codename": "luong_tam"
                    },
                    {
                        "name": "Xã Lương Nghĩa",
                        "code": 31493,
                        "codename": "xa_luong_nghia",
                        "division_type": "xã",
                        "short_codename": "luong_nghia"
                    },
                    {
                        "name": "Xã Xà Phiên",
                        "code": 31495,
                        "codename": "xa_xa_phien",
                        "division_type": "xã",
                        "short_codename": "xa_phien"
                    }
                ]
            },
            {
                "name": "Thị xã Long Mỹ",
                "code": 937,
                "codename": "thi_xa_long_my",
                "division_type": "thị xã",
                "short_codename": "long_my",
                "wards": [
                    {
                        "name": "Phường Thuận An",
                        "code": 31471,
                        "codename": "phuong_thuan_an",
                        "division_type": "phường",
                        "short_codename": "thuan_an"
                    },
                    {
                        "name": "Phường Trà Lồng",
                        "code": 31472,
                        "codename": "phuong_tra_long",
                        "division_type": "phường",
                        "short_codename": "tra_long"
                    },
                    {
                        "name": "Phường Bình Thạnh",
                        "code": 31473,
                        "codename": "phuong_binh_thanh",
                        "division_type": "phường",
                        "short_codename": "binh_thanh"
                    },
                    {
                        "name": "Xã Long Bình",
                        "code": 31474,
                        "codename": "xa_long_binh",
                        "division_type": "xã",
                        "short_codename": "long_binh"
                    },
                    {
                        "name": "Phường Vĩnh Tường",
                        "code": 31475,
                        "codename": "phuong_vinh_tuong",
                        "division_type": "phường",
                        "short_codename": "vinh_tuong"
                    },
                    {
                        "name": "Xã Long Trị",
                        "code": 31477,
                        "codename": "xa_long_tri",
                        "division_type": "xã",
                        "short_codename": "long_tri"
                    },
                    {
                        "name": "Xã Long Trị A",
                        "code": 31478,
                        "codename": "xa_long_tri_a",
                        "division_type": "xã",
                        "short_codename": "long_tri_a"
                    },
                    {
                        "name": "Xã Long Phú",
                        "code": 31480,
                        "codename": "xa_long_phu",
                        "division_type": "xã",
                        "short_codename": "long_phu"
                    },
                    {
                        "name": "Xã Tân Phú",
                        "code": 31481,
                        "codename": "xa_tan_phu",
                        "division_type": "xã",
                        "short_codename": "tan_phu"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Sóc Trăng",
        "code": 94,
        "codename": "tinh_soc_trang",
        "division_type": "tỉnh",
        "phone_code": 299,
        "districts": [
            {
                "name": "Thành phố Sóc Trăng",
                "code": 941,
                "codename": "thanh_pho_soc_trang",
                "division_type": "thành phố",
                "short_codename": "soc_trang",
                "wards": [
                    {
                        "name": "Phường 5",
                        "code": 31498,
                        "codename": "phuong_5",
                        "division_type": "phường",
                        "short_codename": "phuong_5"
                    },
                    {
                        "name": "Phường 7",
                        "code": 31501,
                        "codename": "phuong_7",
                        "division_type": "phường",
                        "short_codename": "phuong_7"
                    },
                    {
                        "name": "Phường 8",
                        "code": 31504,
                        "codename": "phuong_8",
                        "division_type": "phường",
                        "short_codename": "phuong_8"
                    },
                    {
                        "name": "Phường 6",
                        "code": 31507,
                        "codename": "phuong_6",
                        "division_type": "phường",
                        "short_codename": "phuong_6"
                    },
                    {
                        "name": "Phường 2",
                        "code": 31510,
                        "codename": "phuong_2",
                        "division_type": "phường",
                        "short_codename": "phuong_2"
                    },
                    {
                        "name": "Phường 1",
                        "code": 31513,
                        "codename": "phuong_1",
                        "division_type": "phường",
                        "short_codename": "phuong_1"
                    },
                    {
                        "name": "Phường 4",
                        "code": 31516,
                        "codename": "phuong_4",
                        "division_type": "phường",
                        "short_codename": "phuong_4"
                    },
                    {
                        "name": "Phường 3",
                        "code": 31519,
                        "codename": "phuong_3",
                        "division_type": "phường",
                        "short_codename": "phuong_3"
                    },
                    {
                        "name": "Phường 9",
                        "code": 31522,
                        "codename": "phuong_9",
                        "division_type": "phường",
                        "short_codename": "phuong_9"
                    },
                    {
                        "name": "Phường 10",
                        "code": 31525,
                        "codename": "phuong_10",
                        "division_type": "phường",
                        "short_codename": "phuong_10"
                    }
                ]
            },
            {
                "name": "Huyện Châu Thành",
                "code": 942,
                "codename": "huyen_chau_thanh",
                "division_type": "huyện",
                "short_codename": "chau_thanh",
                "wards": [
                    {
                        "name": "Thị trấn Châu Thành",
                        "code": 31569,
                        "codename": "thi_tran_chau_thanh",
                        "division_type": "thị trấn",
                        "short_codename": "chau_thanh"
                    },
                    {
                        "name": "Xã Hồ Đắc Kiện",
                        "code": 31570,
                        "codename": "xa_ho_dac_kien",
                        "division_type": "xã",
                        "short_codename": "ho_dac_kien"
                    },
                    {
                        "name": "Xã Phú Tâm",
                        "code": 31573,
                        "codename": "xa_phu_tam",
                        "division_type": "xã",
                        "short_codename": "phu_tam"
                    },
                    {
                        "name": "Xã Thuận Hòa",
                        "code": 31576,
                        "codename": "xa_thuan_hoa",
                        "division_type": "xã",
                        "short_codename": "thuan_hoa"
                    },
                    {
                        "name": "Xã Phú Tân",
                        "code": 31582,
                        "codename": "xa_phu_tan",
                        "division_type": "xã",
                        "short_codename": "phu_tan"
                    },
                    {
                        "name": "Xã Thiện Mỹ",
                        "code": 31585,
                        "codename": "xa_thien_my",
                        "division_type": "xã",
                        "short_codename": "thien_my"
                    },
                    {
                        "name": "Xã An Hiệp",
                        "code": 31594,
                        "codename": "xa_an_hiep",
                        "division_type": "xã",
                        "short_codename": "an_hiep"
                    },
                    {
                        "name": "Xã An Ninh",
                        "code": 31600,
                        "codename": "xa_an_ninh",
                        "division_type": "xã",
                        "short_codename": "an_ninh"
                    }
                ]
            },
            {
                "name": "Huyện Kế Sách",
                "code": 943,
                "codename": "huyen_ke_sach",
                "division_type": "huyện",
                "short_codename": "ke_sach",
                "wards": [
                    {
                        "name": "Thị trấn Kế Sách",
                        "code": 31528,
                        "codename": "thi_tran_ke_sach",
                        "division_type": "thị trấn",
                        "short_codename": "ke_sach"
                    },
                    {
                        "name": "Thị trấn An Lạc Thôn",
                        "code": 31531,
                        "codename": "thi_tran_an_lac_thon",
                        "division_type": "thị trấn",
                        "short_codename": "an_lac_thon"
                    },
                    {
                        "name": "Xã Xuân Hòa",
                        "code": 31534,
                        "codename": "xa_xuan_hoa",
                        "division_type": "xã",
                        "short_codename": "xuan_hoa"
                    },
                    {
                        "name": "Xã Phong Nẫm",
                        "code": 31537,
                        "codename": "xa_phong_nam",
                        "division_type": "xã",
                        "short_codename": "phong_nam"
                    },
                    {
                        "name": "Xã An Lạc Tây",
                        "code": 31540,
                        "codename": "xa_an_lac_tay",
                        "division_type": "xã",
                        "short_codename": "an_lac_tay"
                    },
                    {
                        "name": "Xã Trinh Phú",
                        "code": 31543,
                        "codename": "xa_trinh_phu",
                        "division_type": "xã",
                        "short_codename": "trinh_phu"
                    },
                    {
                        "name": "Xã Ba Trinh",
                        "code": 31546,
                        "codename": "xa_ba_trinh",
                        "division_type": "xã",
                        "short_codename": "ba_trinh"
                    },
                    {
                        "name": "Xã Thới An Hội",
                        "code": 31549,
                        "codename": "xa_thoi_an_hoi",
                        "division_type": "xã",
                        "short_codename": "thoi_an_hoi"
                    },
                    {
                        "name": "Xã Nhơn Mỹ",
                        "code": 31552,
                        "codename": "xa_nhon_my",
                        "division_type": "xã",
                        "short_codename": "nhon_my"
                    },
                    {
                        "name": "Xã Kế Thành",
                        "code": 31555,
                        "codename": "xa_ke_thanh",
                        "division_type": "xã",
                        "short_codename": "ke_thanh"
                    },
                    {
                        "name": "Xã Kế An",
                        "code": 31558,
                        "codename": "xa_ke_an",
                        "division_type": "xã",
                        "short_codename": "ke_an"
                    },
                    {
                        "name": "Xã Đại Hải",
                        "code": 31561,
                        "codename": "xa_dai_hai",
                        "division_type": "xã",
                        "short_codename": "dai_hai"
                    },
                    {
                        "name": "Xã An Mỹ",
                        "code": 31564,
                        "codename": "xa_an_my",
                        "division_type": "xã",
                        "short_codename": "an_my"
                    }
                ]
            },
            {
                "name": "Huyện Mỹ Tú",
                "code": 944,
                "codename": "huyen_my_tu",
                "division_type": "huyện",
                "short_codename": "my_tu",
                "wards": [
                    {
                        "name": "Thị trấn Huỳnh Hữu Nghĩa",
                        "code": 31567,
                        "codename": "thi_tran_huynh_huu_nghia",
                        "division_type": "thị trấn",
                        "short_codename": "huynh_huu_nghia"
                    },
                    {
                        "name": "Xã Long Hưng",
                        "code": 31579,
                        "codename": "xa_long_hung",
                        "division_type": "xã",
                        "short_codename": "long_hung"
                    },
                    {
                        "name": "Xã Hưng Phú",
                        "code": 31588,
                        "codename": "xa_hung_phu",
                        "division_type": "xã",
                        "short_codename": "hung_phu"
                    },
                    {
                        "name": "Xã Mỹ Hương",
                        "code": 31591,
                        "codename": "xa_my_huong",
                        "division_type": "xã",
                        "short_codename": "my_huong"
                    },
                    {
                        "name": "Xã Mỹ Tú",
                        "code": 31597,
                        "codename": "xa_my_tu",
                        "division_type": "xã",
                        "short_codename": "my_tu"
                    },
                    {
                        "name": "Xã Mỹ Phước",
                        "code": 31603,
                        "codename": "xa_my_phuoc",
                        "division_type": "xã",
                        "short_codename": "my_phuoc"
                    },
                    {
                        "name": "Xã Thuận Hưng",
                        "code": 31606,
                        "codename": "xa_thuan_hung",
                        "division_type": "xã",
                        "short_codename": "thuan_hung"
                    },
                    {
                        "name": "Xã Mỹ Thuận",
                        "code": 31609,
                        "codename": "xa_my_thuan",
                        "division_type": "xã",
                        "short_codename": "my_thuan"
                    },
                    {
                        "name": "Xã Phú Mỹ",
                        "code": 31612,
                        "codename": "xa_phu_my",
                        "division_type": "xã",
                        "short_codename": "phu_my"
                    }
                ]
            },
            {
                "name": "Huyện Cù Lao Dung",
                "code": 945,
                "codename": "huyen_cu_lao_dung",
                "division_type": "huyện",
                "short_codename": "cu_lao_dung",
                "wards": [
                    {
                        "name": "Thị trấn Cù Lao Dung",
                        "code": 31615,
                        "codename": "thi_tran_cu_lao_dung",
                        "division_type": "thị trấn",
                        "short_codename": "cu_lao_dung"
                    },
                    {
                        "name": "Xã An Thạnh 1",
                        "code": 31618,
                        "codename": "xa_an_thanh_1",
                        "division_type": "xã",
                        "short_codename": "an_thanh_1"
                    },
                    {
                        "name": "Xã An Thạnh Tây",
                        "code": 31621,
                        "codename": "xa_an_thanh_tay",
                        "division_type": "xã",
                        "short_codename": "an_thanh_tay"
                    },
                    {
                        "name": "Xã An Thạnh Đông",
                        "code": 31624,
                        "codename": "xa_an_thanh_dong",
                        "division_type": "xã",
                        "short_codename": "an_thanh_dong"
                    },
                    {
                        "name": "Xã Đại Ân 1",
                        "code": 31627,
                        "codename": "xa_dai_an_1",
                        "division_type": "xã",
                        "short_codename": "dai_an_1"
                    },
                    {
                        "name": "Xã An Thạnh 2",
                        "code": 31630,
                        "codename": "xa_an_thanh_2",
                        "division_type": "xã",
                        "short_codename": "an_thanh_2"
                    },
                    {
                        "name": "Xã An Thạnh 3",
                        "code": 31633,
                        "codename": "xa_an_thanh_3",
                        "division_type": "xã",
                        "short_codename": "an_thanh_3"
                    },
                    {
                        "name": "Xã An Thạnh Nam",
                        "code": 31636,
                        "codename": "xa_an_thanh_nam",
                        "division_type": "xã",
                        "short_codename": "an_thanh_nam"
                    }
                ]
            },
            {
                "name": "Huyện Long Phú",
                "code": 946,
                "codename": "huyen_long_phu",
                "division_type": "huyện",
                "short_codename": "long_phu",
                "wards": [
                    {
                        "name": "Thị trấn Long Phú",
                        "code": 31639,
                        "codename": "thi_tran_long_phu",
                        "division_type": "thị trấn",
                        "short_codename": "long_phu"
                    },
                    {
                        "name": "Xã Song Phụng",
                        "code": 31642,
                        "codename": "xa_song_phung",
                        "division_type": "xã",
                        "short_codename": "song_phung"
                    },
                    {
                        "name": "Thị trấn Đại Ngãi",
                        "code": 31645,
                        "codename": "thi_tran_dai_ngai",
                        "division_type": "thị trấn",
                        "short_codename": "dai_ngai"
                    },
                    {
                        "name": "Xã Hậu Thạnh",
                        "code": 31648,
                        "codename": "xa_hau_thanh",
                        "division_type": "xã",
                        "short_codename": "hau_thanh"
                    },
                    {
                        "name": "Xã Long Đức",
                        "code": 31651,
                        "codename": "xa_long_duc",
                        "division_type": "xã",
                        "short_codename": "long_duc"
                    },
                    {
                        "name": "Xã Trường Khánh",
                        "code": 31654,
                        "codename": "xa_truong_khanh",
                        "division_type": "xã",
                        "short_codename": "truong_khanh"
                    },
                    {
                        "name": "Xã Phú Hữu",
                        "code": 31657,
                        "codename": "xa_phu_huu",
                        "division_type": "xã",
                        "short_codename": "phu_huu"
                    },
                    {
                        "name": "Xã Tân Hưng",
                        "code": 31660,
                        "codename": "xa_tan_hung",
                        "division_type": "xã",
                        "short_codename": "tan_hung"
                    },
                    {
                        "name": "Xã Châu Khánh",
                        "code": 31663,
                        "codename": "xa_chau_khanh",
                        "division_type": "xã",
                        "short_codename": "chau_khanh"
                    },
                    {
                        "name": "Xã Tân Thạnh",
                        "code": 31666,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Xã Long Phú",
                        "code": 31669,
                        "codename": "xa_long_phu",
                        "division_type": "xã",
                        "short_codename": "xa_long_phu"
                    }
                ]
            },
            {
                "name": "Huyện Mỹ Xuyên",
                "code": 947,
                "codename": "huyen_my_xuyen",
                "division_type": "huyện",
                "short_codename": "my_xuyen",
                "wards": [
                    {
                        "name": "Thị trấn Mỹ Xuyên",
                        "code": 31684,
                        "codename": "thi_tran_my_xuyen",
                        "division_type": "thị trấn",
                        "short_codename": "my_xuyen"
                    },
                    {
                        "name": "Xã Đại Tâm",
                        "code": 31690,
                        "codename": "xa_dai_tam",
                        "division_type": "xã",
                        "short_codename": "dai_tam"
                    },
                    {
                        "name": "Xã Tham Đôn",
                        "code": 31693,
                        "codename": "xa_tham_don",
                        "division_type": "xã",
                        "short_codename": "tham_don"
                    },
                    {
                        "name": "Xã Thạnh Phú",
                        "code": 31708,
                        "codename": "xa_thanh_phu",
                        "division_type": "xã",
                        "short_codename": "thanh_phu"
                    },
                    {
                        "name": "Xã Ngọc Đông",
                        "code": 31711,
                        "codename": "xa_ngoc_dong",
                        "division_type": "xã",
                        "short_codename": "ngoc_dong"
                    },
                    {
                        "name": "Xã Thạnh Quới",
                        "code": 31714,
                        "codename": "xa_thanh_quoi",
                        "division_type": "xã",
                        "short_codename": "thanh_quoi"
                    },
                    {
                        "name": "Xã Hòa Tú 1",
                        "code": 31717,
                        "codename": "xa_hoa_tu_1",
                        "division_type": "xã",
                        "short_codename": "hoa_tu_1"
                    },
                    {
                        "name": "Xã Gia Hòa 1",
                        "code": 31720,
                        "codename": "xa_gia_hoa_1",
                        "division_type": "xã",
                        "short_codename": "gia_hoa_1"
                    },
                    {
                        "name": "Xã Ngọc Tố",
                        "code": 31723,
                        "codename": "xa_ngoc_to",
                        "division_type": "xã",
                        "short_codename": "ngoc_to"
                    },
                    {
                        "name": "Xã Gia Hòa 2",
                        "code": 31726,
                        "codename": "xa_gia_hoa_2",
                        "division_type": "xã",
                        "short_codename": "gia_hoa_2"
                    },
                    {
                        "name": "Xã Hòa Tú II",
                        "code": 31729,
                        "codename": "xa_hoa_tu_ii",
                        "division_type": "xã",
                        "short_codename": "hoa_tu_ii"
                    }
                ]
            },
            {
                "name": "Thị xã Ngã Năm",
                "code": 948,
                "codename": "thi_xa_nga_nam",
                "division_type": "thị xã",
                "short_codename": "nga_nam",
                "wards": [
                    {
                        "name": "Phường 1",
                        "code": 31732,
                        "codename": "phuong_1",
                        "division_type": "phường",
                        "short_codename": "phuong_1"
                    },
                    {
                        "name": "Phường 2",
                        "code": 31735,
                        "codename": "phuong_2",
                        "division_type": "phường",
                        "short_codename": "phuong_2"
                    },
                    {
                        "name": "Xã Vĩnh Quới",
                        "code": 31738,
                        "codename": "xa_vinh_quoi",
                        "division_type": "xã",
                        "short_codename": "vinh_quoi"
                    },
                    {
                        "name": "Xã Tân Long",
                        "code": 31741,
                        "codename": "xa_tan_long",
                        "division_type": "xã",
                        "short_codename": "tan_long"
                    },
                    {
                        "name": "Xã Long Bình",
                        "code": 31744,
                        "codename": "xa_long_binh",
                        "division_type": "xã",
                        "short_codename": "long_binh"
                    },
                    {
                        "name": "Phường 3",
                        "code": 31747,
                        "codename": "phuong_3",
                        "division_type": "phường",
                        "short_codename": "phuong_3"
                    },
                    {
                        "name": "Xã Mỹ Bình",
                        "code": 31750,
                        "codename": "xa_my_binh",
                        "division_type": "xã",
                        "short_codename": "my_binh"
                    },
                    {
                        "name": "Xã Mỹ Quới",
                        "code": 31753,
                        "codename": "xa_my_quoi",
                        "division_type": "xã",
                        "short_codename": "my_quoi"
                    }
                ]
            },
            {
                "name": "Huyện Thạnh Trị",
                "code": 949,
                "codename": "huyen_thanh_tri",
                "division_type": "huyện",
                "short_codename": "thanh_tri",
                "wards": [
                    {
                        "name": "Thị trấn Phú Lộc",
                        "code": 31756,
                        "codename": "thi_tran_phu_loc",
                        "division_type": "thị trấn",
                        "short_codename": "phu_loc"
                    },
                    {
                        "name": "Thị trấn Hưng Lợi",
                        "code": 31757,
                        "codename": "thi_tran_hung_loi",
                        "division_type": "thị trấn",
                        "short_codename": "hung_loi"
                    },
                    {
                        "name": "Xã Lâm Tân",
                        "code": 31759,
                        "codename": "xa_lam_tan",
                        "division_type": "xã",
                        "short_codename": "lam_tan"
                    },
                    {
                        "name": "Xã Thạnh Tân",
                        "code": 31762,
                        "codename": "xa_thanh_tan",
                        "division_type": "xã",
                        "short_codename": "thanh_tan"
                    },
                    {
                        "name": "Xã Lâm Kiết",
                        "code": 31765,
                        "codename": "xa_lam_kiet",
                        "division_type": "xã",
                        "short_codename": "lam_kiet"
                    },
                    {
                        "name": "Xã Tuân Tức",
                        "code": 31768,
                        "codename": "xa_tuan_tuc",
                        "division_type": "xã",
                        "short_codename": "tuan_tuc"
                    },
                    {
                        "name": "Xã Vĩnh Thành",
                        "code": 31771,
                        "codename": "xa_vinh_thanh",
                        "division_type": "xã",
                        "short_codename": "vinh_thanh"
                    },
                    {
                        "name": "Xã Thạnh Trị",
                        "code": 31774,
                        "codename": "xa_thanh_tri",
                        "division_type": "xã",
                        "short_codename": "thanh_tri"
                    },
                    {
                        "name": "Xã Vĩnh Lợi",
                        "code": 31777,
                        "codename": "xa_vinh_loi",
                        "division_type": "xã",
                        "short_codename": "vinh_loi"
                    },
                    {
                        "name": "Xã Châu Hưng",
                        "code": 31780,
                        "codename": "xa_chau_hung",
                        "division_type": "xã",
                        "short_codename": "chau_hung"
                    }
                ]
            },
            {
                "name": "Thị xã Vĩnh Châu",
                "code": 950,
                "codename": "thi_xa_vinh_chau",
                "division_type": "thị xã",
                "short_codename": "vinh_chau",
                "wards": [
                    {
                        "name": "Phường 1",
                        "code": 31783,
                        "codename": "phuong_1",
                        "division_type": "phường",
                        "short_codename": "phuong_1"
                    },
                    {
                        "name": "Xã Hòa Đông",
                        "code": 31786,
                        "codename": "xa_hoa_dong",
                        "division_type": "xã",
                        "short_codename": "hoa_dong"
                    },
                    {
                        "name": "Phường Khánh Hòa",
                        "code": 31789,
                        "codename": "phuong_khanh_hoa",
                        "division_type": "phường",
                        "short_codename": "khanh_hoa"
                    },
                    {
                        "name": "Xã Vĩnh Hiệp",
                        "code": 31792,
                        "codename": "xa_vinh_hiep",
                        "division_type": "xã",
                        "short_codename": "vinh_hiep"
                    },
                    {
                        "name": "Xã Vĩnh Hải",
                        "code": 31795,
                        "codename": "xa_vinh_hai",
                        "division_type": "xã",
                        "short_codename": "vinh_hai"
                    },
                    {
                        "name": "Xã Lạc Hòa",
                        "code": 31798,
                        "codename": "xa_lac_hoa",
                        "division_type": "xã",
                        "short_codename": "lac_hoa"
                    },
                    {
                        "name": "Phường 2",
                        "code": 31801,
                        "codename": "phuong_2",
                        "division_type": "phường",
                        "short_codename": "phuong_2"
                    },
                    {
                        "name": "Phường Vĩnh Phước",
                        "code": 31804,
                        "codename": "phuong_vinh_phuoc",
                        "division_type": "phường",
                        "short_codename": "vinh_phuoc"
                    },
                    {
                        "name": "Xã Vĩnh Tân",
                        "code": 31807,
                        "codename": "xa_vinh_tan",
                        "division_type": "xã",
                        "short_codename": "vinh_tan"
                    },
                    {
                        "name": "Xã Lai Hòa",
                        "code": 31810,
                        "codename": "xa_lai_hoa",
                        "division_type": "xã",
                        "short_codename": "lai_hoa"
                    }
                ]
            },
            {
                "name": "Huyện Trần Đề",
                "code": 951,
                "codename": "huyen_tran_de",
                "division_type": "huyện",
                "short_codename": "tran_de",
                "wards": [
                    {
                        "name": "Xã Đại Ân 2",
                        "code": 31672,
                        "codename": "xa_dai_an_2",
                        "division_type": "xã",
                        "short_codename": "dai_an_2"
                    },
                    {
                        "name": "Thị trấn Trần Đề",
                        "code": 31673,
                        "codename": "thi_tran_tran_de",
                        "division_type": "thị trấn",
                        "short_codename": "tran_de"
                    },
                    {
                        "name": "Xã Liêu Tú",
                        "code": 31675,
                        "codename": "xa_lieu_tu",
                        "division_type": "xã",
                        "short_codename": "lieu_tu"
                    },
                    {
                        "name": "Xã Lịch Hội Thượng",
                        "code": 31678,
                        "codename": "xa_lich_hoi_thuong",
                        "division_type": "xã",
                        "short_codename": "xa_lich_hoi_thuong"
                    },
                    {
                        "name": "Thị trấn Lịch Hội Thượng",
                        "code": 31679,
                        "codename": "thi_tran_lich_hoi_thuong",
                        "division_type": "thị trấn",
                        "short_codename": "lich_hoi_thuong"
                    },
                    {
                        "name": "Xã Trung Bình",
                        "code": 31681,
                        "codename": "xa_trung_binh",
                        "division_type": "xã",
                        "short_codename": "trung_binh"
                    },
                    {
                        "name": "Xã Tài Văn",
                        "code": 31687,
                        "codename": "xa_tai_van",
                        "division_type": "xã",
                        "short_codename": "tai_van"
                    },
                    {
                        "name": "Xã Viên An",
                        "code": 31696,
                        "codename": "xa_vien_an",
                        "division_type": "xã",
                        "short_codename": "vien_an"
                    },
                    {
                        "name": "Xã Thạnh Thới An",
                        "code": 31699,
                        "codename": "xa_thanh_thoi_an",
                        "division_type": "xã",
                        "short_codename": "thanh_thoi_an"
                    },
                    {
                        "name": "Xã Thạnh Thới Thuận",
                        "code": 31702,
                        "codename": "xa_thanh_thoi_thuan",
                        "division_type": "xã",
                        "short_codename": "thanh_thoi_thuan"
                    },
                    {
                        "name": "Xã Viên Bình",
                        "code": 31705,
                        "codename": "xa_vien_binh",
                        "division_type": "xã",
                        "short_codename": "vien_binh"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Bạc Liêu",
        "code": 95,
        "codename": "tinh_bac_lieu",
        "division_type": "tỉnh",
        "phone_code": 291,
        "districts": [
            {
                "name": "Thành phố Bạc Liêu",
                "code": 954,
                "codename": "thanh_pho_bac_lieu",
                "division_type": "thành phố",
                "short_codename": "bac_lieu",
                "wards": [
                    {
                        "name": "Phường 2",
                        "code": 31813,
                        "codename": "phuong_2",
                        "division_type": "phường",
                        "short_codename": "phuong_2"
                    },
                    {
                        "name": "Phường 3",
                        "code": 31816,
                        "codename": "phuong_3",
                        "division_type": "phường",
                        "short_codename": "phuong_3"
                    },
                    {
                        "name": "Phường 5",
                        "code": 31819,
                        "codename": "phuong_5",
                        "division_type": "phường",
                        "short_codename": "phuong_5"
                    },
                    {
                        "name": "Phường 7",
                        "code": 31822,
                        "codename": "phuong_7",
                        "division_type": "phường",
                        "short_codename": "phuong_7"
                    },
                    {
                        "name": "Phường 1",
                        "code": 31825,
                        "codename": "phuong_1",
                        "division_type": "phường",
                        "short_codename": "phuong_1"
                    },
                    {
                        "name": "Phường 8",
                        "code": 31828,
                        "codename": "phuong_8",
                        "division_type": "phường",
                        "short_codename": "phuong_8"
                    },
                    {
                        "name": "Phường Nhà Mát",
                        "code": 31831,
                        "codename": "phuong_nha_mat",
                        "division_type": "phường",
                        "short_codename": "nha_mat"
                    },
                    {
                        "name": "Xã Vĩnh Trạch",
                        "code": 31834,
                        "codename": "xa_vinh_trach",
                        "division_type": "xã",
                        "short_codename": "vinh_trach"
                    },
                    {
                        "name": "Xã Vĩnh Trạch Đông",
                        "code": 31837,
                        "codename": "xa_vinh_trach_dong",
                        "division_type": "xã",
                        "short_codename": "vinh_trach_dong"
                    },
                    {
                        "name": "Xã Hiệp Thành",
                        "code": 31840,
                        "codename": "xa_hiep_thanh",
                        "division_type": "xã",
                        "short_codename": "hiep_thanh"
                    }
                ]
            },
            {
                "name": "Huyện Hồng Dân",
                "code": 956,
                "codename": "huyen_hong_dan",
                "division_type": "huyện",
                "short_codename": "hong_dan",
                "wards": [
                    {
                        "name": "Thị trấn Ngan Dừa",
                        "code": 31843,
                        "codename": "thi_tran_ngan_dua",
                        "division_type": "thị trấn",
                        "short_codename": "ngan_dua"
                    },
                    {
                        "name": "Xã Ninh Quới",
                        "code": 31846,
                        "codename": "xa_ninh_quoi",
                        "division_type": "xã",
                        "short_codename": "ninh_quoi"
                    },
                    {
                        "name": "Xã Ninh Quới A",
                        "code": 31849,
                        "codename": "xa_ninh_quoi_a",
                        "division_type": "xã",
                        "short_codename": "ninh_quoi_a"
                    },
                    {
                        "name": "Xã Ninh Hòa",
                        "code": 31852,
                        "codename": "xa_ninh_hoa",
                        "division_type": "xã",
                        "short_codename": "ninh_hoa"
                    },
                    {
                        "name": "Xã Lộc Ninh",
                        "code": 31855,
                        "codename": "xa_loc_ninh",
                        "division_type": "xã",
                        "short_codename": "loc_ninh"
                    },
                    {
                        "name": "Xã Vĩnh Lộc",
                        "code": 31858,
                        "codename": "xa_vinh_loc",
                        "division_type": "xã",
                        "short_codename": "vinh_loc"
                    },
                    {
                        "name": "Xã Vĩnh Lộc A",
                        "code": 31861,
                        "codename": "xa_vinh_loc_a",
                        "division_type": "xã",
                        "short_codename": "vinh_loc_a"
                    },
                    {
                        "name": "Xã Ninh Thạnh Lợi A",
                        "code": 31863,
                        "codename": "xa_ninh_thanh_loi_a",
                        "division_type": "xã",
                        "short_codename": "ninh_thanh_loi_a"
                    },
                    {
                        "name": "Xã Ninh Thạnh Lợi",
                        "code": 31864,
                        "codename": "xa_ninh_thanh_loi",
                        "division_type": "xã",
                        "short_codename": "ninh_thanh_loi"
                    }
                ]
            },
            {
                "name": "Huyện Phước Long",
                "code": 957,
                "codename": "huyen_phuoc_long",
                "division_type": "huyện",
                "short_codename": "phuoc_long",
                "wards": [
                    {
                        "name": "Thị trấn Phước Long",
                        "code": 31867,
                        "codename": "thi_tran_phuoc_long",
                        "division_type": "thị trấn",
                        "short_codename": "phuoc_long"
                    },
                    {
                        "name": "Xã Vĩnh Phú Đông",
                        "code": 31870,
                        "codename": "xa_vinh_phu_dong",
                        "division_type": "xã",
                        "short_codename": "vinh_phu_dong"
                    },
                    {
                        "name": "Xã Vĩnh Phú Tây",
                        "code": 31873,
                        "codename": "xa_vinh_phu_tay",
                        "division_type": "xã",
                        "short_codename": "vinh_phu_tay"
                    },
                    {
                        "name": "Xã Phước Long",
                        "code": 31876,
                        "codename": "xa_phuoc_long",
                        "division_type": "xã",
                        "short_codename": "xa_phuoc_long"
                    },
                    {
                        "name": "Xã Hưng Phú",
                        "code": 31879,
                        "codename": "xa_hung_phu",
                        "division_type": "xã",
                        "short_codename": "hung_phu"
                    },
                    {
                        "name": "Xã Vĩnh Thanh",
                        "code": 31882,
                        "codename": "xa_vinh_thanh",
                        "division_type": "xã",
                        "short_codename": "vinh_thanh"
                    },
                    {
                        "name": "Xã Phong Thạnh Tây A",
                        "code": 31885,
                        "codename": "xa_phong_thanh_tay_a",
                        "division_type": "xã",
                        "short_codename": "phong_thanh_tay_a"
                    },
                    {
                        "name": "Xã Phong Thạnh Tây B",
                        "code": 31888,
                        "codename": "xa_phong_thanh_tay_b",
                        "division_type": "xã",
                        "short_codename": "phong_thanh_tay_b"
                    }
                ]
            },
            {
                "name": "Huyện Vĩnh Lợi",
                "code": 958,
                "codename": "huyen_vinh_loi",
                "division_type": "huyện",
                "short_codename": "vinh_loi",
                "wards": [
                    {
                        "name": "Xã Vĩnh Hưng",
                        "code": 31894,
                        "codename": "xa_vinh_hung",
                        "division_type": "xã",
                        "short_codename": "vinh_hung"
                    },
                    {
                        "name": "Xã Vĩnh Hưng A",
                        "code": 31897,
                        "codename": "xa_vinh_hung_a",
                        "division_type": "xã",
                        "short_codename": "vinh_hung_a"
                    },
                    {
                        "name": "Thị trấn Châu Hưng",
                        "code": 31900,
                        "codename": "thi_tran_chau_hung",
                        "division_type": "thị trấn",
                        "short_codename": "chau_hung"
                    },
                    {
                        "name": "Xã Châu Hưng A",
                        "code": 31903,
                        "codename": "xa_chau_hung_a",
                        "division_type": "xã",
                        "short_codename": "chau_hung_a"
                    },
                    {
                        "name": "Xã Hưng Thành",
                        "code": 31906,
                        "codename": "xa_hung_thanh",
                        "division_type": "xã",
                        "short_codename": "hung_thanh"
                    },
                    {
                        "name": "Xã Hưng Hội",
                        "code": 31909,
                        "codename": "xa_hung_hoi",
                        "division_type": "xã",
                        "short_codename": "hung_hoi"
                    },
                    {
                        "name": "Xã Châu Thới",
                        "code": 31912,
                        "codename": "xa_chau_thoi",
                        "division_type": "xã",
                        "short_codename": "chau_thoi"
                    },
                    {
                        "name": "Xã Long Thạnh",
                        "code": 31921,
                        "codename": "xa_long_thanh",
                        "division_type": "xã",
                        "short_codename": "long_thanh"
                    }
                ]
            },
            {
                "name": "Thị xã Giá Rai",
                "code": 959,
                "codename": "thi_xa_gia_rai",
                "division_type": "thị xã",
                "short_codename": "gia_rai",
                "wards": [
                    {
                        "name": "Phường 1",
                        "code": 31942,
                        "codename": "phuong_1",
                        "division_type": "phường",
                        "short_codename": "phuong_1"
                    },
                    {
                        "name": "Phường Hộ Phòng",
                        "code": 31945,
                        "codename": "phuong_ho_phong",
                        "division_type": "phường",
                        "short_codename": "ho_phong"
                    },
                    {
                        "name": "Xã Phong Thạnh Đông",
                        "code": 31948,
                        "codename": "xa_phong_thanh_dong",
                        "division_type": "xã",
                        "short_codename": "phong_thanh_dong"
                    },
                    {
                        "name": "Phường Láng Tròn",
                        "code": 31951,
                        "codename": "phuong_lang_tron",
                        "division_type": "phường",
                        "short_codename": "lang_tron"
                    },
                    {
                        "name": "Xã Phong Tân",
                        "code": 31954,
                        "codename": "xa_phong_tan",
                        "division_type": "xã",
                        "short_codename": "phong_tan"
                    },
                    {
                        "name": "Xã Tân Phong",
                        "code": 31957,
                        "codename": "xa_tan_phong",
                        "division_type": "xã",
                        "short_codename": "tan_phong"
                    },
                    {
                        "name": "Xã Phong Thạnh",
                        "code": 31960,
                        "codename": "xa_phong_thanh",
                        "division_type": "xã",
                        "short_codename": "phong_thanh"
                    },
                    {
                        "name": "Xã Phong Thạnh A",
                        "code": 31963,
                        "codename": "xa_phong_thanh_a",
                        "division_type": "xã",
                        "short_codename": "phong_thanh_a"
                    },
                    {
                        "name": "Xã Phong Thạnh Tây",
                        "code": 31966,
                        "codename": "xa_phong_thanh_tay",
                        "division_type": "xã",
                        "short_codename": "phong_thanh_tay"
                    },
                    {
                        "name": "Xã Tân Thạnh",
                        "code": 31969,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "tan_thanh"
                    }
                ]
            },
            {
                "name": "Huyện Đông Hải",
                "code": 960,
                "codename": "huyen_dong_hai",
                "division_type": "huyện",
                "short_codename": "dong_hai",
                "wards": [
                    {
                        "name": "Thị trấn Gành Hào",
                        "code": 31972,
                        "codename": "thi_tran_ganh_hao",
                        "division_type": "thị trấn",
                        "short_codename": "ganh_hao"
                    },
                    {
                        "name": "Xã Long Điền Đông",
                        "code": 31975,
                        "codename": "xa_long_dien_dong",
                        "division_type": "xã",
                        "short_codename": "long_dien_dong"
                    },
                    {
                        "name": "Xã Long Điền Đông A",
                        "code": 31978,
                        "codename": "xa_long_dien_dong_a",
                        "division_type": "xã",
                        "short_codename": "long_dien_dong_a"
                    },
                    {
                        "name": "Xã Long Điền",
                        "code": 31981,
                        "codename": "xa_long_dien",
                        "division_type": "xã",
                        "short_codename": "long_dien"
                    },
                    {
                        "name": "Xã Long Điền Tây",
                        "code": 31984,
                        "codename": "xa_long_dien_tay",
                        "division_type": "xã",
                        "short_codename": "long_dien_tay"
                    },
                    {
                        "name": "Xã Điền Hải",
                        "code": 31985,
                        "codename": "xa_dien_hai",
                        "division_type": "xã",
                        "short_codename": "dien_hai"
                    },
                    {
                        "name": "Xã An Trạch",
                        "code": 31987,
                        "codename": "xa_an_trach",
                        "division_type": "xã",
                        "short_codename": "an_trach"
                    },
                    {
                        "name": "Xã An Trạch A",
                        "code": 31988,
                        "codename": "xa_an_trach_a",
                        "division_type": "xã",
                        "short_codename": "an_trach_a"
                    },
                    {
                        "name": "Xã An Phúc",
                        "code": 31990,
                        "codename": "xa_an_phuc",
                        "division_type": "xã",
                        "short_codename": "an_phuc"
                    },
                    {
                        "name": "Xã Định Thành",
                        "code": 31993,
                        "codename": "xa_dinh_thanh",
                        "division_type": "xã",
                        "short_codename": "dinh_thanh"
                    },
                    {
                        "name": "Xã Định Thành A",
                        "code": 31996,
                        "codename": "xa_dinh_thanh_a",
                        "division_type": "xã",
                        "short_codename": "dinh_thanh_a"
                    }
                ]
            },
            {
                "name": "Huyện Hoà Bình",
                "code": 961,
                "codename": "huyen_hoa_binh",
                "division_type": "huyện",
                "short_codename": "hoa_binh",
                "wards": [
                    {
                        "name": "Thị trấn Hòa Bình",
                        "code": 31891,
                        "codename": "thi_tran_hoa_binh",
                        "division_type": "thị trấn",
                        "short_codename": "hoa_binh"
                    },
                    {
                        "name": "Xã Minh Diệu",
                        "code": 31915,
                        "codename": "xa_minh_dieu",
                        "division_type": "xã",
                        "short_codename": "minh_dieu"
                    },
                    {
                        "name": "Xã Vĩnh Bình",
                        "code": 31918,
                        "codename": "xa_vinh_binh",
                        "division_type": "xã",
                        "short_codename": "vinh_binh"
                    },
                    {
                        "name": "Xã Vĩnh Mỹ B",
                        "code": 31924,
                        "codename": "xa_vinh_my_b",
                        "division_type": "xã",
                        "short_codename": "vinh_my_b"
                    },
                    {
                        "name": "Xã Vĩnh Hậu",
                        "code": 31927,
                        "codename": "xa_vinh_hau",
                        "division_type": "xã",
                        "short_codename": "vinh_hau"
                    },
                    {
                        "name": "Xã Vĩnh Hậu A",
                        "code": 31930,
                        "codename": "xa_vinh_hau_a",
                        "division_type": "xã",
                        "short_codename": "vinh_hau_a"
                    },
                    {
                        "name": "Xã Vĩnh Mỹ A",
                        "code": 31933,
                        "codename": "xa_vinh_my_a",
                        "division_type": "xã",
                        "short_codename": "vinh_my_a"
                    },
                    {
                        "name": "Xã Vĩnh Thịnh",
                        "code": 31936,
                        "codename": "xa_vinh_thinh",
                        "division_type": "xã",
                        "short_codename": "vinh_thinh"
                    }
                ]
            }
        ]
    },
    {
        "name": "Tỉnh Cà Mau",
        "code": 96,
        "codename": "tinh_ca_mau",
        "division_type": "tỉnh",
        "phone_code": 290,
        "districts": [
            {
                "name": "Thành phố Cà Mau",
                "code": 964,
                "codename": "thanh_pho_ca_mau",
                "division_type": "thành phố",
                "short_codename": "ca_mau",
                "wards": [
                    {
                        "name": "Phường 9",
                        "code": 31999,
                        "codename": "phuong_9",
                        "division_type": "phường",
                        "short_codename": "phuong_9"
                    },
                    {
                        "name": "Phường 4",
                        "code": 32002,
                        "codename": "phuong_4",
                        "division_type": "phường",
                        "short_codename": "phuong_4"
                    },
                    {
                        "name": "Phường 1",
                        "code": 32005,
                        "codename": "phuong_1",
                        "division_type": "phường",
                        "short_codename": "phuong_1"
                    },
                    {
                        "name": "Phường 5",
                        "code": 32008,
                        "codename": "phuong_5",
                        "division_type": "phường",
                        "short_codename": "phuong_5"
                    },
                    {
                        "name": "Phường 2",
                        "code": 32011,
                        "codename": "phuong_2",
                        "division_type": "phường",
                        "short_codename": "phuong_2"
                    },
                    {
                        "name": "Phường 8",
                        "code": 32014,
                        "codename": "phuong_8",
                        "division_type": "phường",
                        "short_codename": "phuong_8"
                    },
                    {
                        "name": "Phường 6",
                        "code": 32017,
                        "codename": "phuong_6",
                        "division_type": "phường",
                        "short_codename": "phuong_6"
                    },
                    {
                        "name": "Phường 7",
                        "code": 32020,
                        "codename": "phuong_7",
                        "division_type": "phường",
                        "short_codename": "phuong_7"
                    },
                    {
                        "name": "Phường Tân Xuyên",
                        "code": 32022,
                        "codename": "phuong_tan_xuyen",
                        "division_type": "phường",
                        "short_codename": "tan_xuyen"
                    },
                    {
                        "name": "Xã An Xuyên",
                        "code": 32023,
                        "codename": "xa_an_xuyen",
                        "division_type": "xã",
                        "short_codename": "an_xuyen"
                    },
                    {
                        "name": "Phường Tân Thành",
                        "code": 32025,
                        "codename": "phuong_tan_thanh",
                        "division_type": "phường",
                        "short_codename": "tan_thanh"
                    },
                    {
                        "name": "Xã Tân Thành",
                        "code": 32026,
                        "codename": "xa_tan_thanh",
                        "division_type": "xã",
                        "short_codename": "xa_tan_thanh"
                    },
                    {
                        "name": "Xã Tắc Vân",
                        "code": 32029,
                        "codename": "xa_tac_van",
                        "division_type": "xã",
                        "short_codename": "tac_van"
                    },
                    {
                        "name": "Xã Lý Văn Lâm",
                        "code": 32032,
                        "codename": "xa_ly_van_lam",
                        "division_type": "xã",
                        "short_codename": "ly_van_lam"
                    },
                    {
                        "name": "Xã Định Bình",
                        "code": 32035,
                        "codename": "xa_dinh_binh",
                        "division_type": "xã",
                        "short_codename": "dinh_binh"
                    },
                    {
                        "name": "Xã Hòa Thành",
                        "code": 32038,
                        "codename": "xa_hoa_thanh",
                        "division_type": "xã",
                        "short_codename": "hoa_thanh"
                    },
                    {
                        "name": "Xã Hòa Tân",
                        "code": 32041,
                        "codename": "xa_hoa_tan",
                        "division_type": "xã",
                        "short_codename": "hoa_tan"
                    }
                ]
            },
            {
                "name": "Huyện U Minh",
                "code": 966,
                "codename": "huyen_u_minh",
                "division_type": "huyện",
                "short_codename": "u_minh",
                "wards": [
                    {
                        "name": "Thị trấn U Minh",
                        "code": 32044,
                        "codename": "thi_tran_u_minh",
                        "division_type": "thị trấn",
                        "short_codename": "u_minh"
                    },
                    {
                        "name": "Xã Khánh Hòa",
                        "code": 32047,
                        "codename": "xa_khanh_hoa",
                        "division_type": "xã",
                        "short_codename": "khanh_hoa"
                    },
                    {
                        "name": "Xã Khánh Thuận",
                        "code": 32048,
                        "codename": "xa_khanh_thuan",
                        "division_type": "xã",
                        "short_codename": "khanh_thuan"
                    },
                    {
                        "name": "Xã Khánh Tiến",
                        "code": 32050,
                        "codename": "xa_khanh_tien",
                        "division_type": "xã",
                        "short_codename": "khanh_tien"
                    },
                    {
                        "name": "Xã Nguyễn Phích",
                        "code": 32053,
                        "codename": "xa_nguyen_phich",
                        "division_type": "xã",
                        "short_codename": "nguyen_phich"
                    },
                    {
                        "name": "Xã Khánh Lâm",
                        "code": 32056,
                        "codename": "xa_khanh_lam",
                        "division_type": "xã",
                        "short_codename": "khanh_lam"
                    },
                    {
                        "name": "Xã Khánh An",
                        "code": 32059,
                        "codename": "xa_khanh_an",
                        "division_type": "xã",
                        "short_codename": "khanh_an"
                    },
                    {
                        "name": "Xã Khánh Hội",
                        "code": 32062,
                        "codename": "xa_khanh_hoi",
                        "division_type": "xã",
                        "short_codename": "khanh_hoi"
                    }
                ]
            },
            {
                "name": "Huyện Thới Bình",
                "code": 967,
                "codename": "huyen_thoi_binh",
                "division_type": "huyện",
                "short_codename": "thoi_binh",
                "wards": [
                    {
                        "name": "Thị trấn Thới Bình",
                        "code": 32065,
                        "codename": "thi_tran_thoi_binh",
                        "division_type": "thị trấn",
                        "short_codename": "thoi_binh"
                    },
                    {
                        "name": "Xã Biển Bạch",
                        "code": 32068,
                        "codename": "xa_bien_bach",
                        "division_type": "xã",
                        "short_codename": "bien_bach"
                    },
                    {
                        "name": "Xã Tân Bằng",
                        "code": 32069,
                        "codename": "xa_tan_bang",
                        "division_type": "xã",
                        "short_codename": "tan_bang"
                    },
                    {
                        "name": "Xã Trí Phải",
                        "code": 32071,
                        "codename": "xa_tri_phai",
                        "division_type": "xã",
                        "short_codename": "tri_phai"
                    },
                    {
                        "name": "Xã Trí Lực",
                        "code": 32072,
                        "codename": "xa_tri_luc",
                        "division_type": "xã",
                        "short_codename": "tri_luc"
                    },
                    {
                        "name": "Xã Biển Bạch Đông",
                        "code": 32074,
                        "codename": "xa_bien_bach_dong",
                        "division_type": "xã",
                        "short_codename": "bien_bach_dong"
                    },
                    {
                        "name": "Xã Thới Bình",
                        "code": 32077,
                        "codename": "xa_thoi_binh",
                        "division_type": "xã",
                        "short_codename": "xa_thoi_binh"
                    },
                    {
                        "name": "Xã Tân Phú",
                        "code": 32080,
                        "codename": "xa_tan_phu",
                        "division_type": "xã",
                        "short_codename": "tan_phu"
                    },
                    {
                        "name": "Xã Tân Lộc Bắc",
                        "code": 32083,
                        "codename": "xa_tan_loc_bac",
                        "division_type": "xã",
                        "short_codename": "tan_loc_bac"
                    },
                    {
                        "name": "Xã Tân Lộc",
                        "code": 32086,
                        "codename": "xa_tan_loc",
                        "division_type": "xã",
                        "short_codename": "tan_loc"
                    },
                    {
                        "name": "Xã Tân Lộc Đông",
                        "code": 32089,
                        "codename": "xa_tan_loc_dong",
                        "division_type": "xã",
                        "short_codename": "tan_loc_dong"
                    },
                    {
                        "name": "Xã Hồ Thị Kỷ",
                        "code": 32092,
                        "codename": "xa_ho_thi_ky",
                        "division_type": "xã",
                        "short_codename": "ho_thi_ky"
                    }
                ]
            },
            {
                "name": "Huyện Trần Văn Thời",
                "code": 968,
                "codename": "huyen_tran_van_thoi",
                "division_type": "huyện",
                "short_codename": "tran_van_thoi",
                "wards": [
                    {
                        "name": "Thị trấn Trần Văn Thời",
                        "code": 32095,
                        "codename": "thi_tran_tran_van_thoi",
                        "division_type": "thị trấn",
                        "short_codename": "tran_van_thoi"
                    },
                    {
                        "name": "Thị trấn Sông Đốc",
                        "code": 32098,
                        "codename": "thi_tran_song_doc",
                        "division_type": "thị trấn",
                        "short_codename": "song_doc"
                    },
                    {
                        "name": "Xã Khánh Bình Tây Bắc",
                        "code": 32101,
                        "codename": "xa_khanh_binh_tay_bac",
                        "division_type": "xã",
                        "short_codename": "khanh_binh_tay_bac"
                    },
                    {
                        "name": "Xã Khánh Bình Tây",
                        "code": 32104,
                        "codename": "xa_khanh_binh_tay",
                        "division_type": "xã",
                        "short_codename": "khanh_binh_tay"
                    },
                    {
                        "name": "Xã Trần Hợi",
                        "code": 32107,
                        "codename": "xa_tran_hoi",
                        "division_type": "xã",
                        "short_codename": "tran_hoi"
                    },
                    {
                        "name": "Xã Khánh Lộc",
                        "code": 32108,
                        "codename": "xa_khanh_loc",
                        "division_type": "xã",
                        "short_codename": "khanh_loc"
                    },
                    {
                        "name": "Xã Khánh Bình",
                        "code": 32110,
                        "codename": "xa_khanh_binh",
                        "division_type": "xã",
                        "short_codename": "khanh_binh"
                    },
                    {
                        "name": "Xã Khánh Hưng",
                        "code": 32113,
                        "codename": "xa_khanh_hung",
                        "division_type": "xã",
                        "short_codename": "khanh_hung"
                    },
                    {
                        "name": "Xã Khánh Bình Đông",
                        "code": 32116,
                        "codename": "xa_khanh_binh_dong",
                        "division_type": "xã",
                        "short_codename": "khanh_binh_dong"
                    },
                    {
                        "name": "Xã Khánh Hải",
                        "code": 32119,
                        "codename": "xa_khanh_hai",
                        "division_type": "xã",
                        "short_codename": "khanh_hai"
                    },
                    {
                        "name": "Xã Lợi An",
                        "code": 32122,
                        "codename": "xa_loi_an",
                        "division_type": "xã",
                        "short_codename": "loi_an"
                    },
                    {
                        "name": "Xã Phong Điền",
                        "code": 32124,
                        "codename": "xa_phong_dien",
                        "division_type": "xã",
                        "short_codename": "phong_dien"
                    },
                    {
                        "name": "Xã Phong Lạc",
                        "code": 32125,
                        "codename": "xa_phong_lac",
                        "division_type": "xã",
                        "short_codename": "phong_lac"
                    }
                ]
            },
            {
                "name": "Huyện Cái Nước",
                "code": 969,
                "codename": "huyen_cai_nuoc",
                "division_type": "huyện",
                "short_codename": "cai_nuoc",
                "wards": [
                    {
                        "name": "Thị trấn Cái Nước",
                        "code": 32128,
                        "codename": "thi_tran_cai_nuoc",
                        "division_type": "thị trấn",
                        "short_codename": "cai_nuoc"
                    },
                    {
                        "name": "Xã Thạnh Phú",
                        "code": 32130,
                        "codename": "xa_thanh_phu",
                        "division_type": "xã",
                        "short_codename": "thanh_phu"
                    },
                    {
                        "name": "Xã Lương Thế Trân",
                        "code": 32131,
                        "codename": "xa_luong_the_tran",
                        "division_type": "xã",
                        "short_codename": "luong_the_tran"
                    },
                    {
                        "name": "Xã Phú Hưng",
                        "code": 32134,
                        "codename": "xa_phu_hung",
                        "division_type": "xã",
                        "short_codename": "phu_hung"
                    },
                    {
                        "name": "Xã Tân Hưng",
                        "code": 32137,
                        "codename": "xa_tan_hung",
                        "division_type": "xã",
                        "short_codename": "tan_hung"
                    },
                    {
                        "name": "Xã Hưng Mỹ",
                        "code": 32140,
                        "codename": "xa_hung_my",
                        "division_type": "xã",
                        "short_codename": "hung_my"
                    },
                    {
                        "name": "Xã Hoà Mỹ",
                        "code": 32141,
                        "codename": "xa_hoa_my",
                        "division_type": "xã",
                        "short_codename": "hoa_my"
                    },
                    {
                        "name": "Xã Đông Hưng",
                        "code": 32142,
                        "codename": "xa_dong_hung",
                        "division_type": "xã",
                        "short_codename": "dong_hung"
                    },
                    {
                        "name": "Xã Đông Thới",
                        "code": 32143,
                        "codename": "xa_dong_thoi",
                        "division_type": "xã",
                        "short_codename": "dong_thoi"
                    },
                    {
                        "name": "Xã Tân Hưng Đông",
                        "code": 32146,
                        "codename": "xa_tan_hung_dong",
                        "division_type": "xã",
                        "short_codename": "tan_hung_dong"
                    },
                    {
                        "name": "Xã Trần Thới",
                        "code": 32149,
                        "codename": "xa_tran_thoi",
                        "division_type": "xã",
                        "short_codename": "tran_thoi"
                    }
                ]
            },
            {
                "name": "Huyện Đầm Dơi",
                "code": 970,
                "codename": "huyen_dam_doi",
                "division_type": "huyện",
                "short_codename": "dam_doi",
                "wards": [
                    {
                        "name": "Thị trấn Đầm Dơi",
                        "code": 32152,
                        "codename": "thi_tran_dam_doi",
                        "division_type": "thị trấn",
                        "short_codename": "dam_doi"
                    },
                    {
                        "name": "Xã Tạ An Khương",
                        "code": 32155,
                        "codename": "xa_ta_an_khuong",
                        "division_type": "xã",
                        "short_codename": "ta_an_khuong"
                    },
                    {
                        "name": "Xã Tạ An Khương Đông",
                        "code": 32158,
                        "codename": "xa_ta_an_khuong_dong",
                        "division_type": "xã",
                        "short_codename": "ta_an_khuong_dong"
                    },
                    {
                        "name": "Xã Trần Phán",
                        "code": 32161,
                        "codename": "xa_tran_phan",
                        "division_type": "xã",
                        "short_codename": "tran_phan"
                    },
                    {
                        "name": "Xã Tân Trung",
                        "code": 32162,
                        "codename": "xa_tan_trung",
                        "division_type": "xã",
                        "short_codename": "tan_trung"
                    },
                    {
                        "name": "Xã Tân Đức",
                        "code": 32164,
                        "codename": "xa_tan_duc",
                        "division_type": "xã",
                        "short_codename": "tan_duc"
                    },
                    {
                        "name": "Xã Tân Thuận",
                        "code": 32167,
                        "codename": "xa_tan_thuan",
                        "division_type": "xã",
                        "short_codename": "tan_thuan"
                    },
                    {
                        "name": "Xã Tạ An Khương Nam",
                        "code": 32170,
                        "codename": "xa_ta_an_khuong_nam",
                        "division_type": "xã",
                        "short_codename": "ta_an_khuong_nam"
                    },
                    {
                        "name": "Xã Tân Duyệt",
                        "code": 32173,
                        "codename": "xa_tan_duyet",
                        "division_type": "xã",
                        "short_codename": "tan_duyet"
                    },
                    {
                        "name": "Xã Tân Dân",
                        "code": 32174,
                        "codename": "xa_tan_dan",
                        "division_type": "xã",
                        "short_codename": "tan_dan"
                    },
                    {
                        "name": "Xã Tân Tiến",
                        "code": 32176,
                        "codename": "xa_tan_tien",
                        "division_type": "xã",
                        "short_codename": "tan_tien"
                    },
                    {
                        "name": "Xã Quách Phẩm Bắc",
                        "code": 32179,
                        "codename": "xa_quach_pham_bac",
                        "division_type": "xã",
                        "short_codename": "quach_pham_bac"
                    },
                    {
                        "name": "Xã Quách Phẩm",
                        "code": 32182,
                        "codename": "xa_quach_pham",
                        "division_type": "xã",
                        "short_codename": "quach_pham"
                    },
                    {
                        "name": "Xã Thanh Tùng",
                        "code": 32185,
                        "codename": "xa_thanh_tung",
                        "division_type": "xã",
                        "short_codename": "thanh_tung"
                    },
                    {
                        "name": "Xã Ngọc Chánh",
                        "code": 32186,
                        "codename": "xa_ngoc_chanh",
                        "division_type": "xã",
                        "short_codename": "ngoc_chanh"
                    },
                    {
                        "name": "Xã Nguyễn Huân",
                        "code": 32188,
                        "codename": "xa_nguyen_huan",
                        "division_type": "xã",
                        "short_codename": "nguyen_huan"
                    }
                ]
            },
            {
                "name": "Huyện Năm Căn",
                "code": 971,
                "codename": "huyen_nam_can",
                "division_type": "huyện",
                "short_codename": "nam_can",
                "wards": [
                    {
                        "name": "Thị Trấn Năm Căn",
                        "code": 32191,
                        "codename": "thi_tran_nam_can",
                        "division_type": "thị trấn",
                        "short_codename": "nam_can"
                    },
                    {
                        "name": "Xã Hàm Rồng",
                        "code": 32194,
                        "codename": "xa_ham_rong",
                        "division_type": "xã",
                        "short_codename": "ham_rong"
                    },
                    {
                        "name": "Xã Hiệp Tùng",
                        "code": 32197,
                        "codename": "xa_hiep_tung",
                        "division_type": "xã",
                        "short_codename": "hiep_tung"
                    },
                    {
                        "name": "Xã Đất Mới",
                        "code": 32200,
                        "codename": "xa_dat_moi",
                        "division_type": "xã",
                        "short_codename": "dat_moi"
                    },
                    {
                        "name": "Xã Lâm Hải",
                        "code": 32201,
                        "codename": "xa_lam_hai",
                        "division_type": "xã",
                        "short_codename": "lam_hai"
                    },
                    {
                        "name": "Xã Hàng Vịnh",
                        "code": 32203,
                        "codename": "xa_hang_vinh",
                        "division_type": "xã",
                        "short_codename": "hang_vinh"
                    },
                    {
                        "name": "Xã Tam Giang",
                        "code": 32206,
                        "codename": "xa_tam_giang",
                        "division_type": "xã",
                        "short_codename": "tam_giang"
                    },
                    {
                        "name": "Xã Tam Giang Đông",
                        "code": 32209,
                        "codename": "xa_tam_giang_dong",
                        "division_type": "xã",
                        "short_codename": "tam_giang_dong"
                    }
                ]
            },
            {
                "name": "Huyện Phú Tân",
                "code": 972,
                "codename": "huyen_phu_tan",
                "division_type": "huyện",
                "short_codename": "phu_tan",
                "wards": [
                    {
                        "name": "Thị trấn Cái Đôi Vàm",
                        "code": 32212,
                        "codename": "thi_tran_cai_doi_vam",
                        "division_type": "thị trấn",
                        "short_codename": "cai_doi_vam"
                    },
                    {
                        "name": "Xã Phú Thuận",
                        "code": 32214,
                        "codename": "xa_phu_thuan",
                        "division_type": "xã",
                        "short_codename": "phu_thuan"
                    },
                    {
                        "name": "Xã Phú Mỹ",
                        "code": 32215,
                        "codename": "xa_phu_my",
                        "division_type": "xã",
                        "short_codename": "phu_my"
                    },
                    {
                        "name": "Xã Phú Tân",
                        "code": 32218,
                        "codename": "xa_phu_tan",
                        "division_type": "xã",
                        "short_codename": "phu_tan"
                    },
                    {
                        "name": "Xã Tân Hải",
                        "code": 32221,
                        "codename": "xa_tan_hai",
                        "division_type": "xã",
                        "short_codename": "tan_hai"
                    },
                    {
                        "name": "Xã Việt Thắng",
                        "code": 32224,
                        "codename": "xa_viet_thang",
                        "division_type": "xã",
                        "short_codename": "viet_thang"
                    },
                    {
                        "name": "Xã Tân Hưng Tây",
                        "code": 32227,
                        "codename": "xa_tan_hung_tay",
                        "division_type": "xã",
                        "short_codename": "tan_hung_tay"
                    },
                    {
                        "name": "Xã Rạch Chèo",
                        "code": 32228,
                        "codename": "xa_rach_cheo",
                        "division_type": "xã",
                        "short_codename": "rach_cheo"
                    },
                    {
                        "name": "Xã Nguyễn Việt Khái",
                        "code": 32230,
                        "codename": "xa_nguyen_viet_khai",
                        "division_type": "xã",
                        "short_codename": "nguyen_viet_khai"
                    }
                ]
            },
            {
                "name": "Huyện Ngọc Hiển",
                "code": 973,
                "codename": "huyen_ngoc_hien",
                "division_type": "huyện",
                "short_codename": "ngoc_hien",
                "wards": [
                    {
                        "name": "Xã Tam Giang Tây",
                        "code": 32233,
                        "codename": "xa_tam_giang_tay",
                        "division_type": "xã",
                        "short_codename": "tam_giang_tay"
                    },
                    {
                        "name": "Xã Tân Ân Tây",
                        "code": 32236,
                        "codename": "xa_tan_an_tay",
                        "division_type": "xã",
                        "short_codename": "tan_an_tay"
                    },
                    {
                        "name": "Xã Viên An Đông",
                        "code": 32239,
                        "codename": "xa_vien_an_dong",
                        "division_type": "xã",
                        "short_codename": "vien_an_dong"
                    },
                    {
                        "name": "Xã Viên An",
                        "code": 32242,
                        "codename": "xa_vien_an",
                        "division_type": "xã",
                        "short_codename": "vien_an"
                    },
                    {
                        "name": "Thị trấn Rạch Gốc",
                        "code": 32244,
                        "codename": "thi_tran_rach_goc",
                        "division_type": "thị trấn",
                        "short_codename": "rach_goc"
                    },
                    {
                        "name": "Xã Tân Ân",
                        "code": 32245,
                        "codename": "xa_tan_an",
                        "division_type": "xã",
                        "short_codename": "tan_an"
                    },
                    {
                        "name": "Xã Đất Mũi",
                        "code": 32248,
                        "codename": "xa_dat_mui",
                        "division_type": "xã",
                        "short_codename": "dat_mui"
                    }
                ]
            }
        ]
    }
]
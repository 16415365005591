import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit {
  @Input() selectItem:any = [];
  @Input() placeholder = '';
  @Input() style = '';
  @Input() margin = '';
  @Input() selected:any = '';
  @Input() multiple = false;
  @Input() disabled :boolean;
  fakeSelected={
    name: "Bệnh viện 30-4",
    value: "79011"
  }
  constructor() { }

  ngOnInit() {
  }

}

import * as types from "../types";

const initialState = {
  validLkUser: "",
  listUsers: null,
  listScreening: null,
  screeningDetail: null,
  customerInfo: null,
  isFilter: false,
  sendLinkPhone: null,
};

export default function customerReducer(state = initialState, action) {
  switch (action.type) {
    case types.LIST_USERS:
      return {
        ...state,
        listUsers: action.payload,
      };
    case types.DETAIL_USERS:
      return {
        ...state,
        detailUser: action.payload,
      };
    case types.DETAIL_USERS_KPELOG:
      return {
        ...state,
        detailUserKPELog: action.payload,
      };
    case types.CUSTOMER_INFO:
      return {
        ...state,
        customerInfo: action.payload,
      };
    case types.VALID_LK_USERS:
      return {
        ...state,
        validLkUser: action.payload,
      };
    case types.CUSTOMER_FILTER:
      return {
        ...state,
        isFilter: action.payload,
      };
    case types.SEND_LINK_PHONE:
      return {
        ...state,
        sendLinkPhone: action.payload,
      };

    default:
      return state;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { readCookie } from '../helpers/cookie.helper';
import { GlobalFunc } from "../base/base";

@Injectable()

export class Request {
    constructor(private http: HttpClient, private global: GlobalFunc) {
      
    }

    processResponse(response: Promise<any>) {
        return response
            .then(res => res)
            .catch(error => {
                if (error.error) {
                    throw { messsage: error.error.message,error };
                }
            });
    }

    get(subUrl: string) {
        let headers = new HttpHeaders();
        const token = readCookie('token');
        if (token) {
            headers = headers.append('Authorization', token);
        }
        const response = this.http.get(this.global.SERVER_URL + subUrl, { headers }).toPromise();
        return this.processResponse(response);
    }

    post(subUrl: string, body) {
        let headers = new HttpHeaders();
        const token = readCookie('token');
        if (token) {
            headers = headers.append('Authorization', token);
        }
        const response = this.http.post(this.global.SERVER_URL + subUrl, body, { headers }).toPromise();
        return this.processResponse(response);
    }

    put(subUrl: string, body) {
        let headers = new HttpHeaders();
        const token = readCookie('token');
        if (token) {
            headers = headers.append('Authorization', token);
        }
        const response = this.http.put(this.global.SERVER_URL + subUrl, body, { headers }).toPromise();
        return this.processResponse(response);
    }

    delete(subUrl: string) {
        let headers = new HttpHeaders();
        const token = readCookie('token');
        if (token) {
            headers = headers.append('Authorization', token);
        }
        const response = this.http.delete(this.global.SERVER_URL + subUrl, { headers }).toPromise();
        return this.processResponse(response);
    }
}

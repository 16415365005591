import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MustBeGuestGuard } from './guards/mustBeGuest.guard';
import { MustBeUserGuard } from './guards/mustBeUser.guard';


const routes: Routes = [
    {
        path: '',
        canActivate: [MustBeUserGuard],
        loadChildren: () =>
            import('./page/tabs/tabs.module').then((m) => m.TabsPageModule),
    },
    {
        path: 'customer',
        canActivate: [MustBeUserGuard],
        loadChildren: () =>
            import('./page/tabs/tabs.module').then((m) => m.TabsPageModule),
    },
    {
        path: 'changes-history/:id',
        canActivate: [MustBeUserGuard],
        loadChildren: () =>
            import('./page/tabs/tabs.module').then((m) => m.TabsPageModule),
    },
    {
        path: 'customer-info/:id',
        loadChildren: () =>
            import('./page/customer-info/customer-info.module').then(
                (m) => m.CustomerInfoPageModule
            ),
    },
    {
        path: 'login',
        loadChildren: () =>
            import('./page/login/login.module').then((m) => m.LoginPageModule),
    },
    {
        path: 'login-password',
        loadChildren: () =>
            import('./page/login-password/login-password.module').then(
                (m) => m.LoginPasswordPageModule
            ),
    },
    {
        path: 'register-otp',
        loadChildren: () =>
            import('./page/register-otp/register-otp.module').then(
                (m) => m.RegisterOtpPageModule
            ),
    },
    {
        path: 'create-password',
        loadChildren: () =>
            import('./page/create-password/create-password.module').then(
                (m) => m.CreatePasswordPageModule
            ),
    },
    {
        path: 'forgot-password',
        loadChildren: () =>
            import('./page/forgot-password/forgot-password.module').then(
                (m) => m.ForgotPasswordPageModule
            ),
    },
    {
        path: 'create-retype-password',
        loadChildren: () =>
            import(
                './page/create-retype-password/create-retype-password.module'
            ).then((m) => m.CreateRetypePasswordPageModule),
    },
    {
        path: 'register-info',
        loadChildren: () =>
            import('./page/register-info/register-info.module').then(
                (m) => m.RegisterInfoModule
            ),
    },

    {
        path: 'filter',
        canActivate: [MustBeUserGuard],
        loadChildren: () =>
            import('./page/filter/filter.module').then(
                (m) => m.FilterPageModule
            ),
    },
    {
        path: 'survey/:id/:userId',
        canActivate: [MustBeUserGuard],
        loadChildren: () =>
            import('./page/survey/survey.module').then(
                (m) => m.SurveyPageModule
            ),
    },
    {
        path: 'survey-instruct/:id/:userId',
        canActivate: [MustBeUserGuard],
        loadChildren: () =>
            import('./page/survey-instruction/survey-instruction.module').then(
                (m) => m.SurveyPageInstructionModule
            ),
    },

    {
        path: 'survey-information/:id/:userId',
        canActivate: [MustBeUserGuard],
        loadChildren: () =>
            import('./page/survey-information/survey-info.module').then(
                (m) => m.SurveyInformationPageModule
            ),
    },
  {
    path: 'modal',
    loadChildren: () => import('./components/modal/modal.module').then( m => m.ModalPageModule)
  },
  {
    path: 'customer-detail-kpelog',
    loadChildren: () => import('./page/customer-detail-kpelog/customer-detail-kpelog.module').then( m => m.CustomerDetailKpelogPageModule)
  },
];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    ],
    exports: [RouterModule],
    providers: [MustBeGuestGuard, MustBeUserGuard],
})
export class AppRoutingModule {}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {CustomerService} from "../../../services/customer.service";
import {ActivatedRoute} from "@angular/router";
import * as moment from "moment";

@Component({
  selector: 'app-survey-history',
  templateUrl: './survey-history.component.html',
  styleUrls: ['./survey-history.component.scss'],
})
export class SurveyHistoryComponent implements OnInit{
  name: string;
  isShow  = [100];
  isShow1 = false;
  isShow2 = false;
  isShow3 = false;
  customerId;
  testHivArr = [];
  ngayXNSangLocTaiCD = null;
  ngayNhanSP = null;
  ngayChuyenGui= null;
  ngayCBOBietKqua = null;
  ngayTraKQXNKD = null;
  dateChange = null;
  copyArr = [];
  constructor(private modalCtrl: ModalController,
              private customerService: CustomerService,
              private route: ActivatedRoute,) {}
  fakeData = ['a','b', 'c']

  ngOnInit() {

    this.customerService.getDetailUsersSendKPElog(this.customerId).then((res:any) =>{

      this.testHivArr = res.ttDichVu.xetNghiemSangLocHIV;
      console.log(this.testHivArr)
    })

  }
  formatDate(date){
    return moment(date).format('DD-MM-YYYY')
  }
  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  confirm() {
    return this.modalCtrl.dismiss(this.name, 'confirm');
  }
  showHistory(i){
    if(i === 0){
      this.isShow.push(1);
    }
    else if (i === 1){
      this.isShow.push(2);

    }
    else{
      this.isShow.push(3);
    }

  }
}

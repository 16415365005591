import * as types from '../types';

const initialState = {
    userInfo: null,
    existPhone: null,
    validPass: null,
    validUser: null
};

export default function authReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_AUTH:
            return {
                ...state,
                userInfo: action.payload
            };
        case types.REGISTER_AUTH:
            return {
                ...state,
                existPhone: action.payload
            };
        case types.USER_ID:
            return {
                ...state,
                user_id: action.payload
            };
        case types.USER_INFO:
            return {
                ...state,
                userInfo: action.payload
            };
        case types.VALID_OTP:
            return {
                ...state,
                validOtp: action.payload
              };
        case types.VALID_PASS:
            return {
                ...state,
                validPass: action.payload
            };
        case types.VALID_USER:
            return {
                ...state,
                validUser: action.payload
            };
        case types.OTP_CODE:
            return {
                ...state,
                otpCode: action.payload
            };
        case types.TCV_INFO:
            return {
                ...state,
                tcvInfo: action.payload
            };
        case types.COUNT_CUSTOMER:
            return {
                ...state,
                countCustomer: action.payload
            };
        default:
            return state;
    }
}

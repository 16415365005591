// import { LIST_NOTIFICATIONS_CBO } from './../types/index';
import * as types from "../types";

const initialState = {
  listNotification: null,
  isNew: false
};

export default function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case types.LIST_NOTIFICATIONS:
      return {
        ...state,
        listNotification: action.payload,
      };
    case types.LIST_NOTIFICATIONS_CBO:
      return {
        ...state,
        listCBO: action.payload,
      };
    case types.NEW_BOOKINGS:
      return {
        ...state,
        isNew: action.payload,
      };
    default:
      return state;
  }
}

import {Component, OnInit} from '@angular/core';
import {GlobalFunc} from '../../../base/base';
import {AlertController, ModalController} from '@ionic/angular';
import {ActivatedRoute} from '@angular/router';
import {CustomerService} from 'src/app/services/customer.service';
import {v4 as uuid} from 'uuid';
import {Store} from '@ngrx/store';
import * as moment from 'moment';
import {HistoryPrepComponent} from "../../../components/survey-history/history-prep/history-prep.component";
import {
    HistorryRiskFactorComponent
} from "../../../components/survey-history/historry-risk-factor/historry-risk-factor.component";
import {HistoryBtbcComponent} from "../../../components/survey-history/history-btbc/history-btbc.component";
import {
    HistoryServiceFavoriteComponent
} from "../../../components/survey-history/history-service-favorite/history-service-favorite.component";
import {HistoryTestHivComponent} from "../../../components/survey-history/history-test-hiv/history-test-hiv.component";
import {
    HistoryArvNangcaoComponent
} from "../../../components/survey-history/history-arv-nangcao/history-arv-nangcao.component";
import {
    ModalResetHistoryComponent
} from "../../../components/survey-history/modal-reset-history/modal-reset-history.component";
import {
    HistoryPrepNangcaoComponent
} from "../../../components/survey-history/history-prep-nangcao/history-prep-nangcao.component";


@Component({
    selector: 'app-advanced-information',
    templateUrl: './advanced-information.component.html',
    styleUrls: ['./advanced-information.component.scss'],
})
export class AdvancedInformationComponent implements OnInit {
    message = 'This modal example uses the modalController to present and dismiss modals.';
    customerId = this.route.snapshot.paramMap.get('id');
    styleKPElog = '#f3f7fc';
    payloadForUpdate = {};
    isOpenModalMenu = false;
    missingFieldArr = [];
    isShowNumberOfPartner = false;
    isShowTestHistory = false;
    gender = '';
    fakeUser = {};
    deliveryFacility = []
    fakeUser1 = {
        kindOfJob: [
            'Toàn thời gian',
            'Bán thời gian',
            'Thời vụ',
            'Tự kinh doanh',
            'Thất nghiệp, đang tìm việc làm',
            'Thất nghiệp, không tìm việc làm',
        ],
        place: [
            'Nhà của bản thân',
            'Nhà của cha mẹ',
            'Nhà của họ hàng',
            'Nhà của bạn tình',
            'Nhà thuê',
            'Vô gia cư',
        ],
        livingWith: [
            'Sống một mình',
            'Sống với cha mẹ/gia đình',
            'Sống với họ hàng',
            'Sống với bạn tình',
            'Sống với bạn bè/người khác',
        ],
        difficult: [
            'Khó khăn khi thay đổi thói quen/hoạt động hằng ngày',
            'Khó khăn khi học/làm điều mới',
            'Chưa từng uống thuốc hằng ngày',
            'Khó khăn trong việc thường xuyên xử dụng bao cao su cho quan hệ tình dục',
            'Khó khăn khi quan hệ tình dục không sử dụng chất kích thích',
            '' +
            'Không có người tâm sự và nói chuyện khi muốn vượt qua những khó khăn trên',
            'Mong có mạng lưới hỗ trợ (bạn bè/người thân)',
        ],
        harmfulBehavior: [
            'Có quan hệ tình dục đường âm đạo/hậu môn/miệng không dùng bao cao su',
            'Có quan hệ tình dục đường hậu môn với bạn tình nam không dùng bao cao su và chất bôi trơn',
            'Có dùng chung bơm kim tiêm',
            'Có dùng chung dụng cụ tiêm chích với người tiêm chích ma túy',
            'Có quan hệ tình dục không dùng bao cao su với người nhiễm HIV',
            'Có dùng chung bơm kim tiêm với người nhiễm HIV',
            'Có mắc (hoặc được điều trị) bệnh như lậu, giang mai, mụn cóc sinh dục/sùi mào gà, chlamydia, ...',
            '' +
            'Bạn tình bị mắc (hoặc được điều trị) các bệnh như lậu, giang mai, mụn cóc sinh dục/sùi mào gà, chlamydia, ...',
            'Không chắc chắn liệu bạn tình bị nhiễm HIV nhưng bạn không dùng bao cao su thường xuyên',
            'Bạn tình quan hệ tình dục với người khác và bạn không thường xuyên dùng bao cao su',
            'Có quan hệ tình dục với nhiều bạn tình cùng một lúc và có 1-2 người trong số đó không dùng bao cao su',
            'không biết liệu bạn tình có mắc nhiễm trùng/bệnh lây qua đường tình dục hay không và bạn không dùng bao cao su thường xuyên',
        ],
        riskViolence: [
            'Không cảm thấy an toàn trong mối quan hệ với bạn tình hiện tại',
            'Cảm thấy sợ bạn tình',
            'Cảm thấy quá suy sụp, thậm chí có ý định tự tử vì cách đối xử của bạn tình',
            '' + 'Bị bạn tình dùng bạo lực, tấn công, đấm, đá hoặc làm bạn bị thương',
            'Bị bạn tình chửi mắng, xúc phạm, làm tổn thương tinh thần',
            '' +
            'Có bạn tình/bạn chích cũ khiến cho bạn cảm thấy bất ổn đến tận bây giờ',
            'Bị bạn tình cưỡng bức tình dục',
            'Bạn tình hiện tại là người hay ghen, gia trưởng, nóng tính hoặc hung dữ',
            'Bạn tình nghiêm cấm bạn ra khỏi nhà, đi chơi với bạn bè, đi khám bệnh hoặc đi làm',
            'Bạn tình nghiện rượu hoặc ma túy',
            ' Bạn cố gắng chấm dứt mối quan hệ với bạn tình nhưng không có khả năng thực hiện',
        ],
        riskHIV: [
            'Có quan hệ tình dục không dùng bao cao su với người nhiễm HIV',
            ' Không biết chắc là bạn tình ruột có bị nhiễm HIV hay không, nhưng bạn đã không luôn luôn sử dụng bao cao su',
            '' +
            'Bạn tình ruột có quan hệ tình dục với người khác ngoài bạn, nhưng bạn đã không luôn luôn sử dụng bao cao su',
            'Có tiêm chích chung bơm kim tiêm với người nhiễm HIV',
            '' +
            ' Có từng sử dụng chung các thiết bị, dụng cụ tiêm chích với người tiêm chích ma tuý',
            'Có từng quan hệ tình dục với người khác để đổi lấy tiền/quà',
        ],
        riskHIVNeedPrep: ['Xét nghiệm HIV cần đây âm tính/”không có phản ứng” và vẫn có quan hệ tình dục',
            'Xét nghiệm HIV cần đây âm tính/”không có phản ứng” và vẫn có quan hệ tình dục',
            ' (Là nam giới) Có quan hệ tình dục đường hậu môn với nam giới khác không dùng bao cao su',
            ' (Là nam giới) Có quan hệ tình dục đường hậu môn với nam giới khác không dùng bao cao su',
            ' Thích quan hệ tình dục ngẫu hứng',
            ' Có bạn tình bị nhiễm HIV và tải lượng virus của người đó ở ngưỡng phát hiện hoặc bạn không biết tình trạng tải lượng virus của người đó',
            'Có nhiều bạn tình,  không biết tình trạng nhiễm HIV của họ, và không sử dụng bao cao su khi quan hệ tình dục'
        ],
        riskHIVNeedPep: [
            'Xét nghiệm HIV gần đây âm tính/không có phản ứng',
            ' Chưa điều trị PrEP',
            ' Trong vòng 72 giờ, có quan hệ tình dục (qua đường âm đạo/hậu môn/miệng) không bao cao su với bạn tình nhiễm HIV hoặc không rõ tình trạng HIV',
            ' (Trong vòng 72 giờ, có dùng chung kim tiêm hoặc dụng cụ tiêm chích khác ',
            ' Trong vòng 72 giờ, có tiếp xúc với máu hoặc dịch cơ thể của người nhiễm HIV hoặc người không rõ tình trạng HIV',

        ],
        difficultHIV: [
            'Có được cơ sở y tế chẩn đoán xác định tình trạng dương tính với HIV',
            'Chưa điều trị ARV',
            'Có lo lắng/ e ngại về tác dụng phụ của thuốc ARV',
            '' + 'Có lo lắng/ e ngại về việc chi trả cho điều trị ARV',
            'Có lo lắng/ e ngại về sự kỳ thị đối với bạn khi điều trị ARV',
            'Có lo lắng/ e ngại về thái độ của nhân viên y tế do tình trạng HIV, hoặc giới tính/xu hướng tình dục của mình',
            '' +
            'Có bất kỳ nỗi sợ hãi/ e ngại khác liên quan đến việc bắt đầu điều trị ARV',
        ],
        complianceARV: [
            'CHƯA bắt đầu điều trị ARV sau khi được xét nghiệm khẳng định dương tính tại cơ sở y tế',
            ' Quên uống thuốc ARV',
            'Gặp khó khăn trong việc uống thuốc ARV hàng ngày theo toa bác sĩ',
            '' + 'Gặp tác dụng phụ khiến cho bạn phải ngừng dùng thuốc ARV',
            'Lo lắng/để ý đến sự kỳ thị khi dùng thuốc ARV',
            'Gặp khó khăn khi mua/nhận thuốc ARV tại nhà thuốc hoặc cơ sở y tế',
            'Gặp khó khăn trong việc chi trả cho thuốc ARV',
            '' + 'Không có thẻ bảo hiểm y tế để hỗ trợ việc chi trả cho thuốc ARV',
            'Tải lượng virus KHÔNG giảm đi sau 3-6 tháng uống thuốc ARV đều',
        ],
        reproduction: [
            'Có quan hệ tình dục đường âm đạo/hậu môn/miệng không dùng bao cao su',
            'Có dịch tiết đường niệu bất thường (máu, mủ, ...)',
            '' +
            'Có dịch âm đạo bất thường (khí hư có màu, mùi hôi bất thường, ngứa ngáy khó chịu) hoặc ra máu âm đạo bất thường hoặc đau bụng kinh dữ dội khác thường',
            '' +
            '(Nữ) có bị đau bụng dưới, đặc biệt khi quan hệ tình dục không dùng bao cao su',
            '(Nam) Tinh hoàn có bị đau/khó chịu/đau khi chạm',
            '' + 'Có đi tiểu són/rắt/buốt',
            'Bộ phận sinh dục hoặc vùng hậu môn của bạn có mụn/vết loét/tổn thương khác',
            ' Muốn tránh thai và không biết nên dùng biện pháp nào',
            ' Đang nỗ lực để mang thai, nhưng lại khó thụ thai',
            '' +
            'Gia đình bạn có người bị ung thư, đặc biệt là ung thư tinh hoàn hoặc dương vật (nam giới) hoặc ung thư buồng trứng/tử cung/cổ tử cung (phụ nữ)',
        ],
        methodHIV: [
            'Tại CSYT công lập',
            'Tại CSYT tư nhân',
            'Tại Phòng khám cộng đồng (Alocare, Thành Danh/Glink, Galant...)',
            'Tại các tổ chức cộng đồng',
            'Tại nhà',
            'Tại một địa điểm riêng tư khác',
        ],
        kindOfHIV: [
            'Tại CSYT công lập',
            'Tại CSYT tư nhân',
            'Tại Phòng khám cộng đồng (Alocare, Thành Danh/Glink, Galant...)',
            'Tại các tổ chức cộng đồng',
            'Tại nhà',
            'Tại một địa điểm riêng tư khác',

        ],
        arvTreatment: [
            'Tại CSYT công lập',
            'Tại CSYT tư nhân',
            'Tại Phòng khám cộng đồng (Alocare, Thành Danh/Glink, Galant...)',
            'Tại các tổ chức cộng đồng',
            'Tại nhà',
            'Tại một địa điểm riêng tư khác',
        ],
        serviceHIV: [
            'Không cần tiết lộ thông tin cá nhân',
            'Số lần tái khám, nhận thuốc ít',
            'Thuốc được cấp phát theo nhu cầu (Phát nhiều tháng)',
            '' + 'Chấp nhận thanh toán qua bảo hiểm y tế',
            'Không bắt buộc thanh toán qua bảo hiểm y tế',
            'Giao thuốc đến tận nhà',
            'Thời gian chờ đợi ngắn',
            '' + 'Thái độ phục vụ tốt',
            'Được hỗ trợ mua bảo hiểm y tế',
            'Yêu cầu/ thủ tục hành chính tinh giản',
            'Chỉ kiểm tra sức khỏe khi có yêu cầu',
        ],
        hivTest: ['Chưa từng xét nghiệm', 'Có xét nghiệm'],
        hivStatus: ['Dương tính', 'Âm tính', 'Không xác định', 'Không biết'],
        arvStatus: ['Chưa điều trị', 'Đang điều trị', 'Tự ngưng thuốc'],
        arvBHYT: ['Có', 'Không'],
        curentVirus: [
            'Dưới ngưỡng phát hiện (Dưới 200 bản sao/ml)',
            'Trên ngưỡng phát hiện (Trên 200 bản sao/ml)',
            'Không biết',
        ],
        treatmentARVFirstMonth: [
            'Không quên liều',
            'Có quên liều',
            'Ngưng uống thuốc ',
        ],
        treatmentARVSecondMonth: [
            'Không quên liều',
            'Có quên liều',
            'Ngưng uống thuốc ',
            'Quên',
            'Tác dụng phụ',
            'Sợ bị kỳ thị',
            'Không thuận tiện',
            'Tiêu tốn nhiều tiền',
            'Hết thuốc và tự dừng thuốc',
            'Hết thuốc nhưng chưa được nhận thêm/không mua được thuốc',
            'Khác',
        ],
        treatmentARVThirdMonth: [
            'Không quên liều',
            'Có quên liều',
            'Ngưng uống thuốc ',
            'Quên',
            'Tác dụng phụ',
            'Sợ bị kỳ thị',
            'Không thuận tiện',
            'Tiêu tốn nhiều tiền',
            'Hết thuốc và tự dừng thuốc',
            'Hết thuốc nhưng chưa được nhận thêm/không mua được thuốc',
            'Khác',
        ],
        treatmentOldARV: ['Có', 'Không'],
        prepStatus: [
            'Chưa điều trị',
            'Đang điều trị',
            'Tự ngưng thuốc',
            'Ngưng thuốc theo chỉ định bác sĩ',
        ],
        treatmentPREPFirstMonth: [
            'Không quên liều',
            'Có quên liều',
            'Ngưng uống thuốc ',
            'Quên',
            'Tác dụng phụ',
            'Sợ bị kỳ thị',
            'Không thuận tiện',
            'Tiêu tốn nhiều tiền',
            'Hết thuốc và tự dừng thuốc',
            'Hết thuốc nhưng chưa được nhận thêm/không mua được thuốc',
            'Không còn cần thiết/Hết hành vi nguy cơ ',
            'Khác',
        ],
        treatmentPREPSecondMonth: [
            'Không quên liều',
            'Có quên liều',
            'Ngưng uống thuốc ',
            'Quên',
            'Tác dụng phụ',
            'Sợ bị kỳ thị',
            'Không thuận tiện',
            'Tiêu tốn nhiều tiền',
            'Hết thuốc và tự dừng thuốc',
            'Hết thuốc nhưng chưa được nhận thêm/không mua được thuốc',
            'Không còn cần thiết/Hết hành vi nguy cơ ',
            'Khác',
        ],
        treatmentPREPThirdMonth: [
            'Không quên liều',
            'Có quên liều',
            'Ngưng uống thuốc ',
            'Quên',
            'Tác dụng phụ',
            'Sợ bị kỳ thị',
            'Không thuận tiện',
            'Tiêu tốn nhiều tiền',
            'Hết thuốc và tự dừng thuốc',
            'Hết thuốc nhưng chưa được nhận thêm/không mua được thuốc',
            'Không còn cần thiết/Hết hành vi nguy cơ ',
            'Khác',
        ],
        risk6Month: [
            'Quan hệ tình dục không bao cao su qua âm đạo/hậu môn',
            'Quan hệ tình dục khi say xỉn',
            'Quan hệ tình dục khi sử dụng chất kích thích',
            'Quan hệ tình dục để đổi lấy tiền/ quà',
            'Quan hệ tình dục đồng giới nam',
            'Quan hệ với nhiều người (ko vì tiền & ma túy)',
            'Mắc các bệnh lây truyền qua đường tình dục (lậu, giang mai, sùi mào gà,…)',
            'Tiêm chích ma túy',
            'Dùng chung dụng cụ tiêm chích ma túy',
            'Xăm, cắt, xỏ lỗ, tiêm, truyền máu hoặc cấy ghép mô không đảm bảo vô khuẩn',
            'Dẫm đạp bơm kim tiêm hoặc các vật sắc nhọn dính máu của người khác',
            'Khác'
        ],
        dayTestHIV: '22/12/2022',
        reasonForget: ['Quên','Tác dụng phụ','Sợ bị kỳ thị','Không thuận tiện','Tiêu tốn nhiều tiền',
        'Hết thuốc và tự dừng thuốc','Hết thuốc nhưng chưa được nhận thêm/không mua được thuốc', 'Không còn cần thiết/Hết hành vi nguy cơ ',
        'Khác']
    };
    fakeUser2 = {
        kindOfJob: [
            'Toàn thời gian',
            'Bán thời gian',
            'Thời vụ',
            'Tự kinh doanh',
            'Thất nghiệp, đang tìm việc làm',
            'Thất nghiệp, không tìm việc làm',
        ],
        place: [
            'Nhà của bản thân',
            'Nhà của cha mẹ',
            'Nhà của họ hàng',
            'Nhà của bạn tình',
            'Nhà thuê',
            'Vô gia cư',
        ],
        livingWith: [
            'Sống một mình',
            'Sống với cha mẹ/gia đình',
            'Sống với họ hàng',
            'Sống với bạn tình',
            'Sống với bạn bè/người khác',
        ],
        difficult: [
            'Khó khăn khi thay đổi thói quen/hoạt động hằng ngày',
            'Khó khăn khi học/làm điều mới',
            'Chưa từng uống thuốc hằng ngày',
            'Khó khăn trong việc thường xuyên xử dụng bao cao su cho quan hệ tình dục',
            'Khó khăn khi quan hệ tình dục không sử dụng chất kích thích',
            '' +
            'Không có người tâm sự và nói chuyện khi muốn vượt qua những khó khăn trên',
            'Mong có mạng lưới hỗ trợ (bạn bè/người thân)',
        ],
        harmfulBehavior: [
            'Có quan hệ tình dục đường âm đạo/hậu môn/miệng không dùng bao cao su',
            'Có quan hệ tình dục đường hậu môn với bạn tình nam không dùng bao cao su và chất bôi trơn',
            'Có dùng chung bơm kim tiêm',
            'Có dùng chung dụng cụ tiêm chích với người tiêm chích ma túy',
            'Có quan hệ tình dục không dùng bao cao su với người nhiễm HIV',
            'Có dùng chung bơm kim tiêm với người nhiễm HIV',
            'Có mắc (hoặc được điều trị) bệnh như lậu, giang mai, mụn cóc sinh dục/sùi mào gà, chlamydia, ...',
            '' +
            'Bạn tình bị mắc (hoặc được điều trị) các bệnh như lậu, giang mai, mụn cóc sinh dục/sùi mào gà, chlamydia, ...',
            'Không chắc chắn liệu bạn tình bị nhiễm HIV nhưng bạn không dùng bao cao su thường xuyên',
            'Bạn tình quan hệ tình dục với người khác và bạn không thường xuyên dùng bao cao su',
            'Có quan hệ tình dục với nhiều bạn tình cùng một lúc và có 1-2 người trong số đó không dùng bao cao su',
            'không biết liệu bạn tình có mắc nhiễm trùng/bệnh lây qua đường tình dục hay không và bạn không dùng bao cao su thường xuyên',
        ],
        riskViolence: [
            'Không cảm thấy an toàn trong mối quan hệ với bạn tình hiện tại',
            'Cảm thấy sợ bạn tình',
            'Cảm thấy quá suy sụp, thậm chí có ý định tự tử vì cách đối xử của bạn tình',
            '' + 'Bị bạn tình dùng bạo lực, tấn công, đấm, đá hoặc làm bạn bị thương',
            'Bị bạn tình chửi mắng, xúc phạm, làm tổn thương tinh thần',
            '' +
            'Có bạn tình/bạn chích cũ khiến cho bạn cảm thấy bất ổn đến tận bây giờ',
            'Bị bạn tình cưỡng bức tình dục',
            'Bạn tình hiện tại là người hay ghen, gia trưởng, nóng tính hoặc hung dữ',
            'Bạn tình nghiêm cấm bạn ra khỏi nhà, đi chơi với bạn bè, đi khám bệnh hoặc đi làm',
            'Bạn tình nghiện rượu hoặc ma túy',
            ' Bạn cố gắng chấm dứt mối quan hệ với bạn tình nhưng không có khả năng thực hiện',
        ],
        riskHIV: [
            'Có quan hệ tình dục không dùng bao cao su với người nhiễm HIV',
            ' Không biết chắc là bạn tình ruột có bị nhiễm HIV hay không, nhưng bạn đã không luôn luôn sử dụng bao cao su',
            '' +
            'Bạn tình ruột có quan hệ tình dục với người khác ngoài bạn, nhưng bạn đã không luôn luôn sử dụng bao cao su',
            'Có tiêm chích chung bơm kim tiêm với người nhiễm HIV',
            '' +
            ' Có từng sử dụng chung các thiết bị, dụng cụ tiêm chích với người tiêm chích ma tuý',
            'Có từng quan hệ tình dục với người khác để đổi lấy tiền/quà',
        ],
        riskHIVNeedPrep: ['Xét nghiệm HIV cần đây âm tính/”không có phản ứng” và vẫn có quan hệ tình dục',
            'Xét nghiệm HIV cần đây âm tính/”không có phản ứng” và vẫn có quan hệ tình dục',
            ' (Là nam giới) Có quan hệ tình dục đường hậu môn với nam giới khác không dùng bao cao su',
            ' (Là nam giới) Có quan hệ tình dục đường hậu môn với nam giới khác không dùng bao cao su',
            ' Thích quan hệ tình dục ngẫu hứng',
            ' Có bạn tình bị nhiễm HIV và tải lượng virus của người đó ở ngưỡng phát hiện hoặc bạn không biết tình trạng tải lượng virus của người đó',
            'Có nhiều bạn tình,  không biết tình trạng nhiễm HIV của họ, và không sử dụng bao cao su khi quan hệ tình dục'
        ],
        riskHIVNeedPep: [
            'Xét nghiệm HIV gần đây âm tính/không có phản ứng',
            ' Chưa điều trị PrEP',
            ' Trong vòng 72 giờ, có quan hệ tình dục (qua đường âm đạo/hậu môn/miệng) không bao cao su với bạn tình nhiễm HIV hoặc không rõ tình trạng HIV',
            ' (Trong vòng 72 giờ, có dùng chung kim tiêm hoặc dụng cụ tiêm chích khác ',
            ' Thích quan hệ tình dục ngẫu hứng',
            ' Trong vòng 72 giờ, có tiếp xúc với máu hoặc dịch cơ thể của người nhiễm HIV hoặc người không rõ tình trạng HIV',
        ],
        difficultHIV: [
            'Có được cơ sở y tế chẩn đoán xác định tình trạng dương tính với HIV',
            'Chưa điều trị ARV',
            'Có lo lắng/ e ngại về tác dụng phụ của thuốc ARV',
            '' + 'Có lo lắng/ e ngại về việc chi trả cho điều trị ARV',
            'Có lo lắng/ e ngại về sự kỳ thị đối với bạn khi điều trị ARV',
            'Có lo lắng/ e ngại về thái độ của nhân viên y tế do tình trạng HIV, hoặc giới tính/xu hướng tình dục của mình',
            '' +
            'Có bất kỳ nỗi sợ hãi/ e ngại khác liên quan đến việc bắt đầu điều trị ARV',
        ],
        complianceARV: [
            'CHƯA bắt đầu điều trị ARV sau khi được xét nghiệm khẳng định dương tính tại cơ sở y tế',
            ' Quên uống thuốc ARV',
            'Gặp khó khăn trong việc uống thuốc ARV hàng ngày theo toa bác sĩ',
            '' + 'Gặp tác dụng phụ khiến cho bạn phải ngừng dùng thuốc ARV',
            'Lo lắng/để ý đến sự kỳ thị khi dùng thuốc ARV',
            'Gặp khó khăn khi mua/nhận thuốc ARV tại nhà thuốc hoặc cơ sở y tế',
            'Gặp khó khăn trong việc chi trả cho thuốc ARV',
            '' + 'Không có thẻ bảo hiểm y tế để hỗ trợ việc chi trả cho thuốc ARV',
            'Tải lượng virus KHÔNG giảm đi sau 3-6 tháng uống thuốc ARV đều',
        ],
        reproduction: [
            'Có quan hệ tình dục đường âm đạo/hậu môn/miệng không dùng bao cao su',
            'Có dịch tiết đường niệu bất thường (máu, mủ, ...)',
            '' +
            'Có dịch âm đạo bất thường (khí hư có màu, mùi hôi bất thường, ngứa ngáy khó chịu) hoặc ra máu âm đạo bất thường hoặc đau bụng kinh dữ dội khác thường',
            '' +
            '(Nữ) có bị đau bụng dưới, đặc biệt khi quan hệ tình dục không dùng bao cao su',
            '(Nam) Tinh hoàn có bị đau/khó chịu/đau khi chạm',
            '' + 'Có đi tiểu són/rắt/buốt',
            'Bộ phận sinh dục hoặc vùng hậu môn của bạn có mụn/vết loét/tổn thương khác',
            ' Muốn tránh thai và không biết nên dùng biện pháp nào',
            ' Đang nỗ lực để mang thai, nhưng lại khó thụ thai',
            '' +
            'Gia đình bạn có người bị ung thư, đặc biệt là ung thư tinh hoàn hoặc dương vật (nam giới) hoặc ung thư buồng trứng/tử cung/cổ tử cung (phụ nữ)',
        ],
        methodHIV: [
            'Tại CSYT công lập',
            'Tại CSYT tư nhân',
            'Tại Phòng khám cộng đồng (Alocare, Thành Danh/Glink, Galant...)',
            'Tại các tổ chức cộng đồng',
            'Tại nhà',
            'Tại một địa điểm riêng tư khác',
        ],
        kindOfHIV: [
            'Tại CSYT công lập',
            'Tại CSYT tư nhân',
            'Tại Phòng khám cộng đồng (Alocare, Thành Danh/Glink, Galant...)',
            'Tại các tổ chức cộng đồng',
            'Tại nhà',
            'Tại một địa điểm riêng tư khác',

        ],
        arvTreatment: [
            'Tại CSYT công lập',
            'Tại CSYT tư nhân',
            'Tại Phòng khám cộng đồng (Alocare, Thành Danh/Glink, Galant...)',
            'Tại các tổ chức cộng đồng',
            'Tại nhà',
            'Tại một địa điểm riêng tư khác',
        ],
        serviceHIV: [
            'Không cần tiết lộ thông tin cá nhân',
            'Số lần tái khám, nhận thuốc ít',
            'Thuốc được cấp phát theo nhu cầu (Phát nhiều tháng)',
            '' + 'Chấp nhận thanh toán qua bảo hiểm y tế',
            'Không bắt buộc thanh toán qua bảo hiểm y tế',
            'Giao thuốc đến tận nhà',
            'Thời gian chờ đợi ngắn',
            '' + 'Thái độ phục vụ tốt',
            'Được hỗ trợ mua bảo hiểm y tế',
            'Yêu cầu/ thủ tục hành chính tinh giản',
            'Chỉ kiểm tra sức khỏe khi có yêu cầu',
        ],
        hivTest: ['Chưa từng xét nghiệm', 'Có xét nghiệm'],
        hivStatus: ['Dương tính', 'Âm tính', 'Không xác định', 'Không biết'],
        arvStatus: ['Chưa điều trị', 'Đang điều trị', 'Tự ngưng thuốc'],
        arvBHYT: ['Có', 'Không'],
        curentVirus: [
            'Dưới ngưỡng phát hiện (Dưới 200 bản sao/ml)',
            'Trên ngưỡng phát hiện (Trên 200 bản sao/ml)',
            'Không biết',
        ],
        treatmentARVFirstMonth: [
            'Không quên liều',
            'Có quên liều',
            'Ngưng uống thuốc ',
        ],
        treatmentARVSecondMonth: [
            'Không quên liều',
            'Có quên liều',
            'Ngưng uống thuốc ',
            'Quên',
            'Tác dụng phụ',
            'Sợ bị kỳ thị',
            'Không thuận tiện',
            'Tiêu tốn nhiều tiền',
            'Hết thuốc và tự dừng thuốc',
            'Hết thuốc nhưng chưa được nhận thêm/không mua được thuốc',
            'Khác',
        ],
        treatmentARVThirdMonth: [
            'Không quên liều',
            'Có quên liều',
            'Ngưng uống thuốc ',
            'Quên',
            'Tác dụng phụ',
            'Sợ bị kỳ thị',
            'Không thuận tiện',
            'Tiêu tốn nhiều tiền',
            'Hết thuốc và tự dừng thuốc',
            'Hết thuốc nhưng chưa được nhận thêm/không mua được thuốc',
            'Khác',
        ],
        treatmentOldARV: ['Có', 'Không'],
        prepStatus: [
            'Chưa điều trị',
            'Đang điều trị',
            'Tự ngưng thuốc',
            'Ngưng thuốc theo chỉ định bác sĩ',
        ],
        treatmentPREPFirstMonth: [
            'Không quên liều',
            'Có quên liều',
            'Ngưng uống thuốc ',
            'Quên',
            'Tác dụng phụ',
            'Sợ bị kỳ thị',
            'Không thuận tiện',
            'Tiêu tốn nhiều tiền',
            'Hết thuốc và tự dừng thuốc',
            'Hết thuốc nhưng chưa được nhận thêm/không mua được thuốc',
            'Không còn cần thiết/Hết hành vi nguy cơ ',
            'Khác',
        ],
        treatmentPREPSecondMonth: [
            'Không quên liều',
            'Có quên liều',
            'Ngưng uống thuốc ',
            'Quên',
            'Tác dụng phụ',
            'Sợ bị kỳ thị',
            'Không thuận tiện',
            'Tiêu tốn nhiều tiền',
            'Hết thuốc và tự dừng thuốc',
            'Hết thuốc nhưng chưa được nhận thêm/không mua được thuốc',
            'Không còn cần thiết/Hết hành vi nguy cơ ',
            'Khác',
        ],
        treatmentPREPThirdMonth: [
            'Không quên liều',
            'Có quên liều',
            'Ngưng uống thuốc ',
            'Quên',
            'Tác dụng phụ',
            'Sợ bị kỳ thị',
            'Không thuận tiện',
            'Tiêu tốn nhiều tiền',
            'Hết thuốc và tự dừng thuốc',
            'Hết thuốc nhưng chưa được nhận thêm/không mua được thuốc',
            'Không còn cần thiết/Hết hành vi nguy cơ ',
            'Khác',
        ],
        risk6Month: [
            'Quan hệ tình dục không bao cao su qua âm đạo/hậu môn',
            'Quan hệ tình dục khi say xỉn',
            'Quan hệ tình dục khi sử dụng chất kích thích',
            'Quan hệ tình dục để đổi lấy tiền/ quà',
            'Quan hệ tình dục đồng giới nam',
            'Quan hệ với nhiều người (ko vì tiền & ma túy)',
            'Mắc các bệnh lây truyền qua đường tình dục (lậu, giang mai, sùi mào gà,…)',
            'Tiêm chích ma túy',
            'Dùng chung dụng cụ tiêm chích ma túy',
            'Xăm, cắt, xỏ lỗ, tiêm, truyền máu hoặc cấy ghép mô không đảm bảo vô khuẩn',
            'Dẫm đạp bơm kim tiêm hoặc các vật sắc nhọn dính máu của người khác',
            'Khác'
        ],
        dayTestHIV: '22/12/2022',
        reasonForget: ['Quên','Tác dụng phụ','Sợ bị kỳ thị','Không thuận tiện','Tiêu tốn nhiều tiền',
            'Hết thuốc và tự dừng thuốc','Hết thuốc nhưng chưa được nhận thêm/không mua được thuốc', 'Không còn cần thiết/Hết hành vi nguy cơ ',
            'Khác']
    };
    fakeUser3 = {
        kindOfJob: [
            'Toàn thời gian',
            'Bán thời gian',
            'Thời vụ',
            'Tự kinh doanh',
            'Thất nghiệp, đang tìm việc làm',
            'Thất nghiệp, không tìm việc làm',
        ],
        place: [
            'Nhà của bản thân',
            'Nhà của cha mẹ',
            'Nhà của họ hàng',
            'Nhà của bạn tình',
            'Nhà thuê',
            'Vô gia cư',
        ],
        livingWith: [
            'Sống một mình',
            'Sống với cha mẹ/gia đình',
            'Sống với họ hàng',
            'Sống với bạn tình',
            'Sống với bạn bè/người khác',
        ],
        difficult: [
            'Khó khăn khi thay đổi thói quen/hoạt động hằng ngày',
            'Khó khăn khi học/làm điều mới',
            'Chưa từng uống thuốc hằng ngày',
            'Khó khăn trong việc thường xuyên xử dụng bao cao su cho quan hệ tình dục',
            'Khó khăn khi quan hệ tình dục không sử dụng chất kích thích',
            '' +
            'Không có người tâm sự và nói chuyện khi muốn vượt qua những khó khăn trên',
            'Mong có mạng lưới hỗ trợ (bạn bè/người thân)',
        ],
        harmfulBehavior: [
            'Có quan hệ tình dục đường âm đạo/hậu môn/miệng không dùng bao cao su',
            'Có quan hệ tình dục đường hậu môn với bạn tình nam không dùng bao cao su và chất bôi trơn',
            'Có dùng chung bơm kim tiêm',
            'Có dùng chung dụng cụ tiêm chích với người tiêm chích ma túy',
            'Có quan hệ tình dục không dùng bao cao su với người nhiễm HIV',
            'Có dùng chung bơm kim tiêm với người nhiễm HIV',
            'Có mắc (hoặc được điều trị) bệnh như lậu, giang mai, mụn cóc sinh dục/sùi mào gà, chlamydia, ...',
            '' +
            'Bạn tình bị mắc (hoặc được điều trị) các bệnh như lậu, giang mai, mụn cóc sinh dục/sùi mào gà, chlamydia, ...',
            'Không chắc chắn liệu bạn tình bị nhiễm HIV nhưng bạn không dùng bao cao su thường xuyên',
            'Bạn tình quan hệ tình dục với người khác và bạn không thường xuyên dùng bao cao su',
            'Có quan hệ tình dục với nhiều bạn tình cùng một lúc và có 1-2 người trong số đó không dùng bao cao su',
            'không biết liệu bạn tình có mắc nhiễm trùng/bệnh lây qua đường tình dục hay không và bạn không dùng bao cao su thường xuyên',
        ],
        riskViolence: [
            'Không cảm thấy an toàn trong mối quan hệ với bạn tình hiện tại',
            'Cảm thấy sợ bạn tình',
            'Cảm thấy quá suy sụp, thậm chí có ý định tự tử vì cách đối xử của bạn tình',
            '' + 'Bị bạn tình dùng bạo lực, tấn công, đấm, đá hoặc làm bạn bị thương',
            'Bị bạn tình chửi mắng, xúc phạm, làm tổn thương tinh thần',
            '' +
            'Có bạn tình/bạn chích cũ khiến cho bạn cảm thấy bất ổn đến tận bây giờ',
            'Bị bạn tình cưỡng bức tình dục',
            'Bạn tình hiện tại là người hay ghen, gia trưởng, nóng tính hoặc hung dữ',
            'Bạn tình nghiêm cấm bạn ra khỏi nhà, đi chơi với bạn bè, đi khám bệnh hoặc đi làm',
            'Bạn tình nghiện rượu hoặc ma túy',
            ' Bạn cố gắng chấm dứt mối quan hệ với bạn tình nhưng không có khả năng thực hiện',
        ],
        riskHIV: [
            'Có quan hệ tình dục không dùng bao cao su với người nhiễm HIV',
            ' Không biết chắc là bạn tình ruột có bị nhiễm HIV hay không, nhưng bạn đã không luôn luôn sử dụng bao cao su',
            '' +
            'Bạn tình ruột có quan hệ tình dục với người khác ngoài bạn, nhưng bạn đã không luôn luôn sử dụng bao cao su',
            'Có tiêm chích chung bơm kim tiêm với người nhiễm HIV',
            '' +
            ' Có từng sử dụng chung các thiết bị, dụng cụ tiêm chích với người tiêm chích ma tuý',
            'Có từng quan hệ tình dục với người khác để đổi lấy tiền/quà',
        ],
        difficultHIV: [
            'Có được cơ sở y tế chẩn đoán xác định tình trạng dương tính với HIV',
            'Chưa điều trị ARV',
            'Có lo lắng/ e ngại về tác dụng phụ của thuốc ARV',
            '' + 'Có lo lắng/ e ngại về việc chi trả cho điều trị ARV',
            'Có lo lắng/ e ngại về sự kỳ thị đối với bạn khi điều trị ARV',
            'Có lo lắng/ e ngại về thái độ của nhân viên y tế do tình trạng HIV, hoặc giới tính/xu hướng tình dục của mình',
            '' +
            'Có bất kỳ nỗi sợ hãi/ e ngại khác liên quan đến việc bắt đầu điều trị ARV',
        ],
        complianceARV: [
            'CHƯA bắt đầu điều trị ARV sau khi được xét nghiệm khẳng định dương tính tại cơ sở y tế',
            ' Quên uống thuốc ARV',
            'Gặp khó khăn trong việc uống thuốc ARV hàng ngày theo toa bác sĩ',
            '' + 'Gặp tác dụng phụ khiến cho bạn phải ngừng dùng thuốc ARV',
            'Lo lắng/để ý đến sự kỳ thị khi dùng thuốc ARV',
            'Gặp khó khăn khi mua/nhận thuốc ARV tại nhà thuốc hoặc cơ sở y tế',
            'Gặp khó khăn trong việc chi trả cho thuốc ARV',
            '' + 'Không có thẻ bảo hiểm y tế để hỗ trợ việc chi trả cho thuốc ARV',
            'Tải lượng virus KHÔNG giảm đi sau 3-6 tháng uống thuốc ARV đều',
        ],
        reproduction: [
            'Có quan hệ tình dục đường âm đạo/hậu môn/miệng không dùng bao cao su',
            'Có dịch tiết đường niệu bất thường (máu, mủ, ...)',
            '' +
            'Có dịch âm đạo bất thường (khí hư có màu, mùi hôi bất thường, ngứa ngáy khó chịu) hoặc ra máu âm đạo bất thường hoặc đau bụng kinh dữ dội khác thường',
            '' +
            '(Nữ) có bị đau bụng dưới, đặc biệt khi quan hệ tình dục không dùng bao cao su',
            '(Nam) Tinh hoàn có bị đau/khó chịu/đau khi chạm',
            '' + 'Có đi tiểu són/rắt/buốt',
            'Bộ phận sinh dục hoặc vùng hậu môn của bạn có mụn/vết loét/tổn thương khác',
            ' Muốn tránh thai và không biết nên dùng biện pháp nào',
            ' Đang nỗ lực để mang thai, nhưng lại khó thụ thai',
            '' +
            'Gia đình bạn có người bị ung thư, đặc biệt là ung thư tinh hoàn hoặc dương vật (nam giới) hoặc ung thư buồng trứng/tử cung/cổ tử cung (phụ nữ)',
        ],
        methodHIV: [
            'Tại CSYT công lập',
            'Tại CSYT tư nhân',
            'Tại Phòng khám cộng đồng (Alocare, Thành Danh/Glink, Galant...)',
            'Tại các tổ chức cộng đồng',
            'Tại nhà',
            'Tại một địa điểm riêng tư khác',
        ],
        kindOfHIV: [
            'Tại CSYT công lập',
            'Tại CSYT tư nhân',
            'Tại Phòng khám cộng đồng (Alocare, Thành Danh/Glink, Galant...)',
            'Tại các tổ chức cộng đồng',
            'Tại nhà',
            'Tại một địa điểm riêng tư khác',

        ],
        arvTreatment: [
            'Tại CSYT công lập',
            'Tại CSYT tư nhân',
            'Tại Phòng khám cộng đồng (Alocare, Thành Danh/Glink, Galant...)',
            'Tại các tổ chức cộng đồng',
            'Tại nhà',
            'Tại một địa điểm riêng tư khác',
        ],
        serviceHIV: [
            'Không cần tiết lộ thông tin cá nhân',
            'Số lần tái khám, nhận thuốc ít',
            'Thuốc được cấp phát theo nhu cầu (Phát nhiều tháng)',
            '' + 'Chấp nhận thanh toán qua bảo hiểm y tế',
            'Không bắt buộc thanh toán qua bảo hiểm y tế',
            'Giao thuốc đến tận nhà',
            'Thời gian chờ đợi ngắn',
            '' + 'Thái độ phục vụ tốt',
            'Được hỗ trợ mua bảo hiểm y tế',
            'Yêu cầu/ thủ tục hành chính tinh giản',
            'Chỉ kiểm tra sức khỏe khi có yêu cầu',
        ],
        hivTest: ['Có xét nghiệm', 'Chưa từng xét nghiệm'],
        hivStatus: ['Dương tính', 'Âm tính', 'Không xác định', 'Không biết'],
        arvStatus: ['Chưa điều trị', 'Đang điều trị', 'Tự ngưng thuốc'],
        arvBHYT: ['Có', 'Không'],
        curentVirus: [
            'Dưới ngưỡng phát hiện (Dưới 200 bản sao/ml)',
            'Trên ngưỡng phát hiện (Trên 200 bản sao/ml)',
            'Không biết',
        ],
        treatmentARVFirstMonth: [
            'Không quên liều',
            'Có quên liều',
            'Ngưng uống thuốc ',
        ],
        treatmentARVSecondMonth: [
            'Không quên liều',
            'Có quên liều',
            'Ngưng uống thuốc ',
            'Quên',
            'Tác dụng phụ',
            'Sợ bị kỳ thị',
            'Không thuận tiện',
            'Tiêu tốn nhiều tiền',
            'Hết thuốc và tự dừng thuốc',
            'Hết thuốc nhưng chưa được nhận thêm/không mua được thuốc',
            'Khác',
        ],
        treatmentARVThirdMonth: [
            'Không quên liều',
            'Có quên liều',
            'Ngưng uống thuốc ',
            'Quên',
            'Tác dụng phụ',
            'Sợ bị kỳ thị',
            'Không thuận tiện',
            'Tiêu tốn nhiều tiền',
            'Hết thuốc và tự dừng thuốc',
            'Hết thuốc nhưng chưa được nhận thêm/không mua được thuốc',
            'Khác',
        ],
        treatmentOldARV: ['Không', 'Có'],
        prepStatus: [
            'Chưa điều trị',
            'Đang điều trị',
            'Tự ngưng thuốc',
            'Ngưng thuốc theo chỉ định bác sĩ',
        ],
        treatmentPREPFirstMonth: [
            'Không quên liều',
            'Có quên liều',
            'Ngưng uống thuốc ',
            'Quên',
            'Tác dụng phụ',
            'Sợ bị kỳ thị',
            'Không thuận tiện',
            'Tiêu tốn nhiều tiền',
            'Hết thuốc và tự dừng thuốc',
            'Hết thuốc nhưng chưa được nhận thêm/không mua được thuốc',
            'Không còn cần thiết/Hết hành vi nguy cơ ',
            'Khác',
        ],
        treatmentPREPSecondMonth: [
            'Không quên liều',
            'Có quên liều',
            'Ngưng uống thuốc ',
            'Quên',
            'Tác dụng phụ',
            'Sợ bị kỳ thị',
            'Không thuận tiện',
            'Tiêu tốn nhiều tiền',
            'Hết thuốc và tự dừng thuốc',
            'Hết thuốc nhưng chưa được nhận thêm/không mua được thuốc',
            'Không còn cần thiết/Hết hành vi nguy cơ ',
            'Khác',
        ],
        treatmentPREPThirdMonth: [
            'Không quên liều',
            'Có quên liều',
            'Ngưng uống thuốc ',
            'Quên',
            'Tác dụng phụ',
            'Sợ bị kỳ thị',
            'Không thuận tiện',
            'Tiêu tốn nhiều tiền',
            'Hết thuốc và tự dừng thuốc',
            'Hết thuốc nhưng chưa được nhận thêm/không mua được thuốc',
            'Không còn cần thiết/Hết hành vi nguy cơ ',
            'Khác',
        ],
        risk6Month: [
            'Quan hệ tình dục không bao cao su qua âm đạo/hậu môn',
            'Quan hệ tình dục khi say xỉn',
            'Quan hệ tình dục khi sử dụng chất kích thích',
            'Quan hệ tình dục để đổi lấy tiền/ quà',
            'Quan hệ tình dục đồng giới nam',
            'Quan hệ với nhiều người (ko vì tiền & ma túy)',
            'Mắc các bệnh lây truyền qua đường tình dục (lậu, giang mai, sùi mào gà,…)',
            'Tiêm chích ma túy',
            'Dùng chung dụng cụ tiêm chích ma túy',
            'Xăm, cắt, xỏ lỗ, tiêm, truyền máu hoặc cấy ghép mô không đảm bảo vô khuẩn',
            'Dẫm đạp bơm kim tiêm hoặc các vật sắc nhọn dính máu của người khác',
            'Khác'
        ],
        dayTestHIV: '05/01/2023',
        reasonForget: ['Quên','Tác dụng phụ','Sợ bị kỳ thị','Không thuận tiện','Tiêu tốn nhiều tiền',
            'Hết thuốc và tự dừng thuốc','Hết thuốc nhưng chưa được nhận thêm/không mua được thuốc', 'Không còn cần thiết/Hết hành vi nguy cơ ',
            'Khác']
    };

    isShow = false;
    isShowAlive = false;
    isShowRisk = false;
    isShowActivate = false;
    isShowHarmful = false;
    isShowViolence = false;
    isShowRiskHIV = false;
    isShowRiskHIVNeedPrep = false;
    isShowRiskHIVNeedPep = false;
    isShowDifficultHIV = false;
    isShowComplianceARV = false;
    isShowReproduction = false;
    isShowHIV = false;
    isShowLike = false;
    isShowHealthStatus = false;
    isShowTreatmentARV = false;
    isShowTreatmentPrep = false;
    isShowStatusPrep = false;
    nguyCoNhiemHIV = [];
    _idRiskFactor = '';
    tienSuXetNghiemHIV = '';
    _idTestHiv = '';
    tinhTrangNhiemHIV = '';
    ngayXNHIV = null;
    cgDieuTriARV = '';
    loaiHinhCongViec = '';
    noiLamViecHienTai = 'a';
    thuNhap = 0;
    songTrongNhaCuaAi = ''
    songVoiAi = '';
    thoiDiemBatDauSongTaiNoiOHienTai = null;
    parseThoiDiemBatDauSongTaiNoiOHienTai = null;
    duKienOChoDen = null;
    parseDuKienOChoDen = null;
    nguyCoNhiemHIVKhac = '';
    ketQuanTimHieuNguyCo = '';
    coKhoKhanVeThayDoiHanhViCanHoTro = '';
    khoKhanCuThe = '';
    coHanhViCoHaiChoSucKhoeCanHoTro = '';
    hanhViCoHaiCuThe = '';
    coNguyCoBaoHanh = '';
    nguyCoBaoHanhCuThe = '';
    coNguyCoLayNhiemHIV = '';
    nguyCoLayNhiemHIVCuThe = '';
    coKhoKhanDieuTriARV = '';
    khoKhanDieuTriARVCuThe = '';
    coKhoKhanTuanThuDieuTri = '';
    khoKhanTuanThuDieuTri = '';
    coKhoKhanPrep = 'a';
    khoKhanPrep = 'a';
    coKhoKhanSKTD = '';
    khoKhanSKTD = '';
    soBanTinhBanChich = null;
    soBanTinhBanChichThuongXuyen = null;
    soBanTinhBanChichBatChot = null;
    _idBTBC = '';
    _idFavoriteService = '';
    hinhThucXNHIVYeuThich = '';
    lyDoYeuThichXNHIV = '';
    hinhThucDieuTriHIVYeuThich = '';
    lyDoYeuThichDieuTriHIV = '';
    hinhThucPrepYeuThich = '';
    lyDoYeuThichPrep = '';
    dieuQuanTrongTiepCanDichVuDuPhongVaDieuTri = '';
    tinhTrangDieuTriARV = '';
    _idTreatmentArv = '';
    _idTreatPrep = '';
    dieuTriARVQuaBHYT = '';
    taiLuongVirus = '';
    tuanThuARV1 = '';
    tuanThuARV2 = '';
    tuanThuARV3 = '';
    soLieuDaQuenARV1  = '';
    soNgayNgungARV1  = '';
    lyDoQuenARV1  = '';
    khacARV1 = '';
    soLieuDaQuenARV2  = '';
    soNgayNgungARV2 = '';
    lyDoQuenARV2  = '';
    khacARV2 = '';
    soLieuDaQuenARV3  = '';
    soNgayNgungARV3  = '';
    lyDoQuenARV3  = '';
    khacARV3 = '';
    tinhTrangPrep = '';
    tuanThuPrep1 = '';
    tuanThuPrep2 = '';
    tuanThuPrep3 = '';
    soLieuDaQuenPrep1 = '';
    soNgayNgungPrep1 = '';
    lyDoQuenPrep1 = '';
    khacPrep1 = '';
    soLieuDaQuenPrep2 = '';
    soNgayNgungPrep2 = '';
    lyDoQuenPrep2 = '';
    khacPrep2 = '';
    soLieuDaQuenPrep3 = '';
    soNgayNgungPrep3 = '';
    lyDoQuenPrep3 = '';
    khacPrep3 = '';
    tinhTP:any = {};
    soNhaDuong = '';
    phuongXa:any = '';
    quanHuyen:any = '';
    valuePhuongXa = 0;
    valueQuanHuyen = 0;
    valueTinhTP = '';
    valuePhuongXaNoiSong = 0;
    valueQuanHuyenNoiSong = 0;
    valueTinhTPNoiSong = '';
    wards = [];
    cities = [];
    districts = [];
    wardsLiving = [];
    citiesLiving = [];
    districtsLiving = [];
    phuongXaNoiSong:any = {};
    quanHuyenNoiSong:any = {};
    tinhTPNoiSong:any = {};
    soNhaDuongNoiSong = '';
    location = [
        {
            lable: 'Thành phố Hồ Chí Minh',
            value: '79',
            type: 'Thành phố Trung ương',
            districts: [
                {
                    lable: 'Quận 1',
                    value: '760',
                    wards: [
                        {
                            lable: 'Phường Bến Nghé',
                            value: '26740',
                        },
                        {
                            lable: 'Phường Bến Thành',
                            value: '26743',
                        },
                        {
                            lable: 'Phường Cô Giang',
                            value: '26755',
                        },
                        {
                            lable: 'Phường Cầu Kho',
                            value: '26761',
                        },
                        {
                            lable: 'Phường Cầu Ông Lãnh',
                            value: '26752',
                        },
                        {
                            lable: 'Phường Nguyễn Cư Trinh',
                            value: '26758',
                        },
                        {
                            lable: 'Phường Nguyễn Thái Bình',
                            value: '26746',
                        },
                        {
                            lable: 'Phường Phạm Ngũ Lão',
                            value: '26749',
                        },
                        {
                            lable: 'Phường Tân Định',
                            value: '26734',
                        },
                        {
                            lable: 'Phường Đa Kao',
                            value: '26737',
                        },
                    ],
                },
                {
                    lable: 'Quận 3',
                    value: '770',
                    wards: [
                        {
                            lable: 'Phường 01',
                            value: '27160',
                        },
                        {
                            lable: 'Phường 02',
                            value: '27157',
                        },
                        {
                            lable: 'Phường 03',
                            value: '27154',
                        },
                        {
                            lable: 'Phường 04',
                            value: '27148',
                        },
                        {
                            lable: 'Phường 05',
                            value: '27151',
                        },
                        {
                            lable: 'Phường 09',
                            value: '27142',
                        },
                        {
                            lable: 'Phường 10',
                            value: '27145',
                        },
                        {
                            lable: 'Phường 11',
                            value: '27133',
                        },
                        {
                            lable: 'Phường 12',
                            value: '27130',
                        },
                        {
                            lable: 'Phường 13',
                            value: '27136',
                        },
                        {
                            lable: 'Phường 14',
                            value: '27127',
                        },
                        {
                            lable: 'Phường Võ Thị Sáu',
                            value: '27139',
                        },
                    ],
                },
                {
                    lable: 'Quận 4',
                    value: '773',
                    wards: [
                        {
                            lable: 'Phường 01',
                            value: '27298',
                        },
                        {
                            lable: 'Phường 02',
                            value: '27292',
                        },
                        {
                            lable: 'Phường 03',
                            value: '27286',
                        },
                        {
                            lable: 'Phường 04',
                            value: '27283',
                        },
                        {
                            lable: 'Phường 06',
                            value: '27265',
                        },
                        {
                            lable: 'Phường 08',
                            value: '27268',
                        },
                        {
                            lable: 'Phường 09',
                            value: '27262',
                        },
                        {
                            lable: 'Phường 10',
                            value: '27271',
                        },
                        {
                            lable: 'Phường 13',
                            value: '27259',
                        },
                        {
                            lable: 'Phường 14',
                            value: '27280',
                        },
                        {
                            lable: 'Phường 15',
                            value: '27295',
                        },
                        {
                            lable: 'Phường 16',
                            value: '27289',
                        },
                        {
                            lable: 'Phường 18',
                            value: '27277',
                        },
                    ],
                },
                {
                    lable: 'Quận 5',
                    value: '774',
                    wards: [
                        {
                            lable: 'Phường 01',
                            value: '27325',
                        },
                        {
                            lable: 'Phường 02',
                            value: '27313',
                        },
                        {
                            lable: 'Phường 03',
                            value: '27307',
                        },
                        {
                            lable: 'Phường 04',
                            value: '27301',
                        },
                        {
                            lable: 'Phường 05',
                            value: '27334',
                        },
                        {
                            lable: 'Phường 06',
                            value: '27337',
                        },
                        {
                            lable: 'Phường 07',
                            value: '27322',
                        },
                        {
                            lable: 'Phường 08',
                            value: '27316',
                        },
                        {
                            lable: 'Phường 09',
                            value: '27304',
                        },
                        {
                            lable: 'Phường 10',
                            value: '27340',
                        },
                        {
                            lable: 'Phường 11',
                            value: '27328',
                        },
                        {
                            lable: 'Phường 12',
                            value: '27310',
                        },
                        {
                            lable: 'Phường 13',
                            value: '27343',
                        },
                        {
                            lable: 'Phường 14',
                            value: '27331',
                        },
                    ],
                },
                {
                    lable: 'Quận 6',
                    value: '775',
                    wards: [
                        {
                            lable: 'Phường 01',
                            value: '27370',
                        },
                        {
                            lable: 'Phường 02',
                            value: '27367',
                        },
                        {
                            lable: 'Phường 03',
                            value: '27379',
                        },
                        {
                            lable: 'Phường 04',
                            value: '27373',
                        },
                        {
                            lable: 'Phường 05',
                            value: '27361',
                        },
                        {
                            lable: 'Phường 06',
                            value: '27355',
                        },
                        {
                            lable: 'Phường 07',
                            value: '27382',
                        },
                        {
                            lable: 'Phường 08',
                            value: '27376',
                        },
                        {
                            lable: 'Phường 09',
                            value: '27352',
                        },
                        {
                            lable: 'Phường 10',
                            value: '27385',
                        },
                        {
                            lable: 'Phường 11',
                            value: '27364',
                        },
                        {
                            lable: 'Phường 12',
                            value: '27358',
                        },
                        {
                            lable: 'Phường 13',
                            value: '27349',
                        },
                        {
                            lable: 'Phường 14',
                            value: '27346',
                        },
                    ],
                },
                {
                    lable: 'Quận 7',
                    value: '778',
                    wards: [
                        {
                            lable: 'Phường Bình Thuận',
                            value: '27478',
                        },
                        {
                            lable: 'Phường Phú Mỹ',
                            value: '27493',
                        },
                        {
                            lable: 'Phường Phú Thuận',
                            value: '27484',
                        },
                        {
                            lable: 'Phường Tân Hưng',
                            value: '27475',
                        },
                        {
                            lable: 'Phường Tân Kiểng',
                            value: '27472',
                        },
                        {
                            lable: 'Phường Tân Phong',
                            value: '27490',
                        },
                        {
                            lable: 'Phường Tân Phú',
                            value: '27487',
                        },
                        {
                            lable: 'Phường Tân Quy',
                            value: '27481',
                        },
                        {
                            lable: 'Phường Tân Thuận Tây',
                            value: '27469',
                        },
                        {
                            lable: 'Phường Tân Thuận Đông',
                            value: '27466',
                        },
                    ],
                },
                {
                    lable: 'Quận 8',
                    value: '776',
                    wards: [
                        {
                            lable: 'Phường 01',
                            value: '27394',
                        },
                        {
                            lable: 'Phường 02',
                            value: '27391',
                        },
                        {
                            lable: 'Phường 03',
                            value: '27397',
                        },
                        {
                            lable: 'Phường 04',
                            value: '27409',
                        },
                        {
                            lable: 'Phường 05',
                            value: '27418',
                        },
                        {
                            lable: 'Phường 06',
                            value: '27424',
                        },
                        {
                            lable: 'Phường 07',
                            value: '27433',
                        },
                        {
                            lable: 'Phường 08',
                            value: '27388',
                        },
                        {
                            lable: 'Phường 09',
                            value: '27403',
                        },
                        {
                            lable: 'Phường 10',
                            value: '27406',
                        },
                        {
                            lable: 'Phường 11',
                            value: '27400',
                        },
                        {
                            lable: 'Phường 12',
                            value: '27415',
                        },
                        {
                            lable: 'Phường 13',
                            value: '27412',
                        },
                        {
                            lable: 'Phường 14',
                            value: '27421',
                        },
                        {
                            lable: 'Phường 15',
                            value: '27427',
                        },
                        {
                            lable: 'Phường 16',
                            value: '27430',
                        },
                    ],
                },
                {
                    lable: 'Quận 10',
                    value: '771',
                    wards: [
                        {
                            lable: 'Phường 01',
                            value: '27184',
                        },
                        {
                            lable: 'Phường 02',
                            value: '27190',
                        },
                        {
                            lable: 'Phường 04',
                            value: '27193',
                        },
                        {
                            lable: 'Phường 05',
                            value: '27199',
                        },
                        {
                            lable: 'Phường 06',
                            value: '27202',
                        },
                        {
                            lable: 'Phường 07',
                            value: '27196',
                        },
                        {
                            lable: 'Phường 08',
                            value: '27187',
                        },
                        {
                            lable: 'Phường 09',
                            value: '27181',
                        },
                        {
                            lable: 'Phường 10',
                            value: '27178',
                        },
                        {
                            lable: 'Phường 11',
                            value: '27175',
                        },
                        {
                            lable: 'Phường 12',
                            value: '27172',
                        },
                        {
                            lable: 'Phường 13',
                            value: '27166',
                        },
                        {
                            lable: 'Phường 14',
                            value: '27169',
                        },
                        {
                            lable: 'Phường 15',
                            value: '27163',
                        },
                    ],
                },
                {
                    lable: 'Quận 11',
                    value: '772',
                    wards: [
                        {
                            lable: 'Phường 01',
                            value: '27247',
                        },
                        {
                            lable: 'Phường 02',
                            value: '27250',
                        },
                        {
                            lable: 'Phường 03',
                            value: '27220',
                        },
                        {
                            lable: 'Phường 04',
                            value: '27244',
                        },
                        {
                            lable: 'Phường 05',
                            value: '27211',
                        },
                        {
                            lable: 'Phường 06',
                            value: '27241',
                        },
                        {
                            lable: 'Phường 07',
                            value: '27238',
                        },
                        {
                            lable: 'Phường 08',
                            value: '27229',
                        },
                        {
                            lable: 'Phường 09',
                            value: '27232',
                        },
                        {
                            lable: 'Phường 10',
                            value: '27223',
                        },
                        {
                            lable: 'Phường 11',
                            value: '27217',
                        },
                        {
                            lable: 'Phường 12',
                            value: '27235',
                        },
                        {
                            lable: 'Phường 13',
                            value: '27226',
                        },
                        {
                            lable: 'Phường 14',
                            value: '27214',
                        },
                        {
                            lable: 'Phường 15',
                            value: '27208',
                        },
                        {
                            lable: 'Phường 16',
                            value: '27253',
                        },
                    ],
                },
                {
                    lable: 'Quận 12',
                    value: '761',
                    wards: [
                        {
                            lable: 'Phường An Phú Đông',
                            value: '26779',
                        },
                        {
                            lable: 'Phường Hiệp Thành',
                            value: '26770',
                        },
                        {
                            lable: 'Phường Thạnh Lộc',
                            value: '26767',
                        },
                        {
                            lable: 'Phường Thạnh Xuân',
                            value: '26764',
                        },
                        {
                            lable: 'Phường Thới An',
                            value: '26773',
                        },
                        {
                            lable: 'Phường Trung Mỹ Tây',
                            value: '26785',
                        },
                        {
                            lable: 'Phường Tân Chánh Hiệp',
                            value: '26776',
                        },
                        {
                            lable: 'Phường Tân Hưng Thuận',
                            value: '26787',
                        },
                        {
                            lable: 'Phường Tân Thới Hiệp',
                            value: '26782',
                        },
                        {
                            lable: 'Phường Tân Thới Nhất',
                            value: '26791',
                        },
                        {
                            lable: 'Phường Đông Hưng Thuận',
                            value: '26788',
                        },
                    ],
                },
                {
                    lable: 'Quận Bình Tân',
                    value: '777',
                    wards: [
                        {
                            lable: 'Phường  An Lạc',
                            value: '27460',
                        },
                        {
                            lable: 'Phường An Lạc A',
                            value: '27463',
                        },
                        {
                            lable: 'Phường Bình Hưng Hoà A',
                            value: '27439',
                        },
                        {
                            lable: 'Phường Bình Hưng Hoà B',
                            value: '27442',
                        },
                        {
                            lable: 'Phường Bình Hưng Hòa',
                            value: '27436',
                        },
                        {
                            lable: 'Phường Bình Trị Đông',
                            value: '27445',
                        },
                        {
                            lable: 'Phường Bình Trị Đông A',
                            value: '27448',
                        },
                        {
                            lable: 'Phường Bình Trị Đông B',
                            value: '27451',
                        },
                        {
                            lable: 'Phường Tân Tạo',
                            value: '27454',
                        },
                        {
                            lable: 'Phường Tân Tạo A',
                            value: '27457',
                        },
                    ],
                },
                {
                    lable: 'Quận Bình Thạnh',
                    value: '765',
                    wards: [
                        {
                            lable: 'Phường 01',
                            value: '26944',
                        },
                        {
                            lable: 'Phường 02',
                            value: '26941',
                        },
                        {
                            lable: 'Phường 03',
                            value: '26947',
                        },
                        {
                            lable: 'Phường 05',
                            value: '26923',
                        },
                        {
                            lable: 'Phường 06',
                            value: '26932',
                        },
                        {
                            lable: 'Phường 07',
                            value: '26926',
                        },
                        {
                            lable: 'Phường 11',
                            value: '26908',
                        },
                        {
                            lable: 'Phường 12',
                            value: '26917',
                        },
                        {
                            lable: 'Phường 13',
                            value: '26905',
                        },
                        {
                            lable: 'Phường 14',
                            value: '26935',
                        },
                        {
                            lable: 'Phường 15',
                            value: '26938',
                        },
                        {
                            lable: 'Phường 17',
                            value: '26950',
                        },
                        {
                            lable: 'Phường 19',
                            value: '26959',
                        },
                        {
                            lable: 'Phường 21',
                            value: '26953',
                        },
                        {
                            lable: 'Phường 22',
                            value: '26956',
                        },
                        {
                            lable: 'Phường 24',
                            value: '26929',
                        },
                        {
                            lable: 'Phường 25',
                            value: '26920',
                        },
                        {
                            lable: 'Phường 26',
                            value: '26914',
                        },
                        {
                            lable: 'Phường 27',
                            value: '26911',
                        },
                        {
                            lable: 'Phường 28',
                            value: '26962',
                        },
                    ],
                },
                {
                    lable: 'Quận Gò Vấp',
                    value: '764',
                    wards: [
                        {
                            lable: 'Phường 01',
                            value: '26896',
                        },
                        {
                            lable: 'Phường 03',
                            value: '26902',
                        },
                        {
                            lable: 'Phường 04',
                            value: '26893',
                        },
                        {
                            lable: 'Phường 05',
                            value: '26887',
                        },
                        {
                            lable: 'Phường 06',
                            value: '26876',
                        },
                        {
                            lable: 'Phường 07',
                            value: '26890',
                        },
                        {
                            lable: 'Phường 08',
                            value: '26898',
                        },
                        {
                            lable: 'Phường 09',
                            value: '26897',
                        },
                        {
                            lable: 'Phường 10',
                            value: '26884',
                        },
                        {
                            lable: 'Phường 11',
                            value: '26899',
                        },
                        {
                            lable: 'Phường 12',
                            value: '26881',
                        },
                        {
                            lable: 'Phường 13',
                            value: '26872',
                        },
                        {
                            lable: 'Phường 14',
                            value: '26882',
                        },
                        {
                            lable: 'Phường 15',
                            value: '26869',
                        },
                        {
                            lable: 'Phường 16',
                            value: '26878',
                        },
                        {
                            lable: 'Phường 17',
                            value: '26875',
                        },
                    ],
                },
                {
                    lable: 'Quận Phú Nhuận',
                    value: '768',
                    wards: [
                        {
                            lable: 'Phường 01',
                            value: '27058',
                        },
                        {
                            lable: 'Phường 02',
                            value: '27061',
                        },
                        {
                            lable: 'Phường 03',
                            value: '27055',
                        },
                        {
                            lable: 'Phường 04',
                            value: '27043',
                        },
                        {
                            lable: 'Phường 05',
                            value: '27046',
                        },
                        {
                            lable: 'Phường 07',
                            value: '27052',
                        },
                        {
                            lable: 'Phường 08',
                            value: '27064',
                        },
                        {
                            lable: 'Phường 09',
                            value: '27049',
                        },
                        {
                            lable: 'Phường 10',
                            value: '27070',
                        },
                        {
                            lable: 'Phường 11',
                            value: '27073',
                        },
                        {
                            lable: 'Phường 13',
                            value: '27085',
                        },
                        {
                            lable: 'Phường 15',
                            value: '27067',
                        },
                        {
                            lable: 'Phường 17',
                            value: '27076',
                        },
                    ],
                },
                {
                    lable: 'Quận Tân Bình',
                    value: '766',
                    wards: [
                        {
                            lable: 'Phường 01',
                            value: '26977',
                        },
                        {
                            lable: 'Phường 02',
                            value: '26965',
                        },
                        {
                            lable: 'Phường 03',
                            value: '26980',
                        },
                        {
                            lable: 'Phường 04',
                            value: '26968',
                        },
                        {
                            lable: 'Phường 05',
                            value: '26989',
                        },
                        {
                            lable: 'Phường 06',
                            value: '26995',
                        },
                        {
                            lable: 'Phường 07',
                            value: '26986',
                        },
                        {
                            lable: 'Phường 08',
                            value: '26998',
                        },
                        {
                            lable: 'Phường 09',
                            value: '27001',
                        },
                        {
                            lable: 'Phường 10',
                            value: '26992',
                        },
                        {
                            lable: 'Phường 11',
                            value: '26983',
                        },
                        {
                            lable: 'Phường 12',
                            value: '26971',
                        },
                        {
                            lable: 'Phường 13',
                            value: '26974',
                        },
                        {
                            lable: 'Phường 14',
                            value: '27004',
                        },
                        {
                            lable: 'Phường 15',
                            value: '27007',
                        },
                    ],
                },
                {
                    lable: 'Quận Tân Phú',
                    value: '767',
                    wards: [
                        {
                            lable: 'Phường Hiệp Tân',
                            value: '27037',
                        },
                        {
                            lable: 'Phường Hòa Thạnh',
                            value: '27034',
                        },
                        {
                            lable: 'Phường Phú Thạnh',
                            value: '27028',
                        },
                        {
                            lable: 'Phường Phú Thọ Hòa',
                            value: '27025',
                        },
                        {
                            lable: 'Phường Phú Trung',
                            value: '27031',
                        },
                        {
                            lable: 'Phường Sơn Kỳ',
                            value: '27016',
                        },
                        {
                            lable: 'Phường Tân Quý',
                            value: '27019',
                        },
                        {
                            lable: 'Phường Tân Sơn Nhì',
                            value: '27010',
                        },
                        {
                            lable: 'Phường Tân Thành',
                            value: '27022',
                        },
                        {
                            lable: 'Phường Tân Thới Hòa',
                            value: '27040',
                        },
                        {
                            lable: 'Phường Tây Thạnh',
                            value: '27013',
                        },
                    ],
                },
                {
                    lable: 'Thành phố Thủ Đức',
                    value: '769',
                    wards: [
                        {
                            lable: 'Phường An Khánh',
                            value: '27094',
                        },
                        {
                            lable: 'Phường An Lợi Đông',
                            value: '27115',
                        },
                        {
                            lable: 'Phường An Phú',
                            value: '27091',
                        },
                        {
                            lable: 'Phường Bình Chiểu',
                            value: '26797',
                        },
                        {
                            lable: 'Phường Bình Thọ',
                            value: '26824',
                        },
                        {
                            lable: 'Phường Bình Trưng Tây',
                            value: '27100',
                        },
                        {
                            lable: 'Phường Bình Trưng Đông',
                            value: '27097',
                        },
                        {
                            lable: 'Phường Cát Lái',
                            value: '27109',
                        },
                        {
                            lable: 'Phường Hiệp Bình Chánh',
                            value: '26812',
                        },
                        {
                            lable: 'Phường Hiệp Bình Phước',
                            value: '26809',
                        },
                        {
                            lable: 'Phường Hiệp Phú',
                            value: '26839',
                        },
                        {
                            lable: 'Phường Linh Chiểu',
                            value: '26815',
                        },
                        {
                            lable: 'Phường Linh Trung',
                            value: '26800',
                        },
                        {
                            lable: 'Phường Linh Tây',
                            value: '26818',
                        },
                        {
                            lable: 'Phường Linh Xuân',
                            value: '26794',
                        },
                        {
                            lable: 'Phường Linh Đông',
                            value: '26821',
                        },
                        {
                            lable: 'Phường Long Bình',
                            value: '26830',
                        },
                        {
                            lable: 'Phường Long Phước',
                            value: '26857',
                        },
                        {
                            lable: 'Phường Long Thạnh Mỹ',
                            value: '26833',
                        },
                        {
                            lable: 'Phường Long Trường',
                            value: '26860',
                        },
                        {
                            lable: 'Phường Phú Hữu',
                            value: '26866',
                        },
                        {
                            lable: 'Phường Phước Bình',
                            value: '26863',
                        },
                        {
                            lable: 'Phường Phước Long A',
                            value: '26851',
                        },
                        {
                            lable: 'Phường Phước Long B',
                            value: '26848',
                        },
                        {
                            lable: 'Phường Tam Bình',
                            value: '26803',
                        },
                        {
                            lable: 'Phường Tam Phú',
                            value: '26806',
                        },
                        {
                            lable: 'Phường Thạnh Mỹ Lợi',
                            value: '27112',
                        },
                        {
                            lable: 'Phường Thảo Điền',
                            value: '27088',
                        },
                        {
                            lable: 'Phường Thủ Thiêm',
                            value: '27118',
                        },
                        {
                            lable: 'Phường Trường Thạnh',
                            value: '26854',
                        },
                        {
                            lable: 'Phường Trường Thọ',
                            value: '26827',
                        },
                        {
                            lable: 'Phường Tân Phú',
                            value: '26836',
                        },
                        {
                            lable: 'Phường Tăng Nhơn Phú A',
                            value: '26842',
                        },
                        {
                            lable: 'Phường Tăng Nhơn Phú B',
                            value: '26845',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bình Chánh',
                    value: '785',
                    wards: [
                        {
                            lable: 'Thị trấn Tân Túc',
                            value: '27595',
                        },
                        {
                            lable: 'Xã An Phú Tây',
                            value: '27625',
                        },
                        {
                            lable: 'Xã Bình Chánh',
                            value: '27637',
                        },
                        {
                            lable: 'Xã Bình Hưng',
                            value: '27619',
                        },
                        {
                            lable: 'Xã Bình Lợi',
                            value: '27607',
                        },
                        {
                            lable: 'Xã Hưng Long',
                            value: '27628',
                        },
                        {
                            lable: 'Xã Lê Minh Xuân',
                            value: '27610',
                        },
                        {
                            lable: 'Xã Phong Phú',
                            value: '27622',
                        },
                        {
                            lable: 'Xã Phạm Văn Hai',
                            value: '27598',
                        },
                        {
                            lable: 'Xã Quy Đức',
                            value: '27640',
                        },
                        {
                            lable: 'Xã Tân Kiên',
                            value: '27616',
                        },
                        {
                            lable: 'Xã Tân Nhựt',
                            value: '27613',
                        },
                        {
                            lable: 'Xã Tân Quý Tây',
                            value: '27634',
                        },
                        {
                            lable: 'Xã Vĩnh Lộc A',
                            value: '27601',
                        },
                        {
                            lable: 'Xã Vĩnh Lộc B',
                            value: '27604',
                        },
                        {
                            lable: 'Xã Đa Phước',
                            value: '27631',
                        },
                    ],
                },
                {
                    lable: 'Huyện Củ Chi',
                    value: '783',
                    wards: [
                        {
                            lable: 'Thị trấn Củ Chi',
                            value: '27496',
                        },
                        {
                            lable: 'Xã An Nhơn Tây',
                            value: '27508',
                        },
                        {
                            lable: 'Xã An Phú',
                            value: '27502',
                        },
                        {
                            lable: 'Xã Bình Mỹ',
                            value: '27550',
                        },
                        {
                            lable: 'Xã Hòa Phú',
                            value: '27544',
                        },
                        {
                            lable: 'Xã Nhuận Đức',
                            value: '27511',
                        },
                        {
                            lable: 'Xã Phú Hòa Đông',
                            value: '27517',
                        },
                        {
                            lable: 'Xã Phú Mỹ Hưng',
                            value: '27499',
                        },
                        {
                            lable: 'Xã Phước Hiệp',
                            value: '27529',
                        },
                        {
                            lable: 'Xã Phước Thạnh',
                            value: '27526',
                        },
                        {
                            lable: 'Xã Phước Vĩnh An',
                            value: '27535',
                        },
                        {
                            lable: 'Xã Phạm Văn Cội',
                            value: '27514',
                        },
                        {
                            lable: 'Xã Thái Mỹ',
                            value: '27538',
                        },
                        {
                            lable: 'Xã Trung An',
                            value: '27523',
                        },
                        {
                            lable: 'Xã Trung Lập Hạ',
                            value: '27520',
                        },
                        {
                            lable: 'Xã Trung Lập Thượng',
                            value: '27505',
                        },
                        {
                            lable: 'Xã Tân An Hội',
                            value: '27532',
                        },
                        {
                            lable: 'Xã Tân Phú Trung',
                            value: '27553',
                        },
                        {
                            lable: 'Xã Tân Thông Hội',
                            value: '27556',
                        },
                        {
                            lable: 'Xã Tân Thạnh Tây',
                            value: '27541',
                        },
                        {
                            lable: 'Xã Tân Thạnh Đông',
                            value: '27547',
                        },
                    ],
                },
                {
                    lable: 'Huyện Cần Giờ',
                    value: '787',
                    wards: [
                        {
                            lable: 'Thị trấn Cần Thạnh',
                            value: '27664',
                        },
                        {
                            lable: 'Xã An Thới Đông',
                            value: '27673',
                        },
                        {
                            lable: 'Xã Bình Khánh',
                            value: '27667',
                        },
                        {
                            lable: 'Xã Long Hòa',
                            value: '27679',
                        },
                        {
                            lable: 'Xã Lý Nhơn',
                            value: '27682',
                        },
                        {
                            lable: 'Xã Tam Thôn Hiệp',
                            value: '27670',
                        },
                        {
                            lable: 'Xã Thạnh An',
                            value: '27676',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hóc Môn',
                    value: '784',
                    wards: [
                        {
                            lable: 'Thị trấn Hóc Môn',
                            value: '27559',
                        },
                        {
                            lable: 'Xã Bà Điểm',
                            value: '27592',
                        },
                        {
                            lable: 'Xã Nhị Bình',
                            value: '27565',
                        },
                        {
                            lable: 'Xã Thới Tam Thôn',
                            value: '27574',
                        },
                        {
                            lable: 'Xã Trung Chánh',
                            value: '27586',
                        },
                        {
                            lable: 'Xã Tân Hiệp',
                            value: '27562',
                        },
                        {
                            lable: 'Xã Tân Thới Nhì',
                            value: '27571',
                        },
                        {
                            lable: 'Xã Tân Xuân',
                            value: '27580',
                        },
                        {
                            lable: 'Xã Xuân Thới Sơn',
                            value: '27577',
                        },
                        {
                            lable: 'Xã Xuân Thới Thượng',
                            value: '27589',
                        },
                        {
                            lable: 'Xã Xuân Thới Đông',
                            value: '27583',
                        },
                        {
                            lable: 'Xã Đông Thạnh',
                            value: '27568',
                        },
                    ],
                },
                {
                    lable: 'Huyện Nhà Bè',
                    value: '786',
                    wards: [
                        {
                            lable: 'Thị trấn Nhà Bè',
                            value: '27643',
                        },
                        {
                            lable: 'Xã Hiệp Phước',
                            value: '27661',
                        },
                        {
                            lable: 'Xã Long Thới',
                            value: '27658',
                        },
                        {
                            lable: 'Xã Nhơn Đức',
                            value: '27652',
                        },
                        {
                            lable: 'Xã Phú Xuân',
                            value: '27655',
                        },
                        {
                            lable: 'Xã Phước Kiển',
                            value: '27646',
                        },
                        {
                            lable: 'Xã Phước Lộc',
                            value: '27649',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Thành phố Hà Nội',
            value: '01',
            type: 'Thành phố Trung ương',
            districts: [
                {
                    lable: 'Quận Ba Đình',
                    value: '001',
                    wards: [
                        {
                            lable: 'Phường Cống Vị',
                            value: '00007',
                        },
                        {
                            lable: 'Phường Giảng Võ',
                            value: '00031',
                        },
                        {
                            lable: 'Phường Kim Mã',
                            value: '00028',
                        },
                        {
                            lable: 'Phường Liễu Giai',
                            value: '00008',
                        },
                        {
                            lable: 'Phường Nguyễn Trung Trực',
                            value: '00010',
                        },
                        {
                            lable: 'Phường Ngọc Hà',
                            value: '00016',
                        },
                        {
                            lable: 'Phường Ngọc Khánh',
                            value: '00025',
                        },
                        {
                            lable: 'Phường Phúc Xá',
                            value: '00001',
                        },
                        {
                            lable: 'Phường Quán Thánh',
                            value: '00013',
                        },
                        {
                            lable: 'Phường Thành Công',
                            value: '00034',
                        },
                        {
                            lable: 'Phường Trúc Bạch',
                            value: '00004',
                        },
                        {
                            lable: 'Phường Vĩnh Phúc',
                            value: '00006',
                        },
                        {
                            lable: 'Phường Điện Biên',
                            value: '00019',
                        },
                        {
                            lable: 'Phường Đội Cấn',
                            value: '00022',
                        },
                    ],
                },
                {
                    lable: 'Quận Hoàn Kiếm',
                    value: '002',
                    wards: [
                        {
                            lable: 'Phường Chương Dương',
                            value: '00067',
                        },
                        {
                            lable: 'Phường Cửa Nam',
                            value: '00073',
                        },
                        {
                            lable: 'Phường Cửa Đông',
                            value: '00055',
                        },
                        {
                            lable: 'Phường Hàng Buồm',
                            value: '00046',
                        },
                        {
                            lable: 'Phường Hàng Bài',
                            value: '00088',
                        },
                        {
                            lable: 'Phường Hàng Bông',
                            value: '00076',
                        },
                        {
                            lable: 'Phường Hàng Bạc',
                            value: '00061',
                        },
                        {
                            lable: 'Phường Hàng Bồ',
                            value: '00052',
                        },
                        {
                            lable: 'Phường Hàng Gai',
                            value: '00064',
                        },
                        {
                            lable: 'Phường Hàng Mã',
                            value: '00043',
                        },
                        {
                            lable: 'Phường Hàng Trống',
                            value: '00070',
                        },
                        {
                            lable: 'Phường Hàng Đào',
                            value: '00049',
                        },
                        {
                            lable: 'Phường Lý Thái Tổ',
                            value: '00058',
                        },
                        {
                            lable: 'Phường Phan Chu Trinh',
                            value: '00085',
                        },
                        {
                            lable: 'Phường Phúc Tân',
                            value: '00037',
                        },
                        {
                            lable: 'Phường Tràng Tiền',
                            value: '00079',
                        },
                        {
                            lable: 'Phường Trần Hưng Đạo',
                            value: '00082',
                        },
                        {
                            lable: 'Phường Đồng Xuân',
                            value: '00040',
                        },
                    ],
                },
                {
                    lable: 'Quận Tây Hồ',
                    value: '003',
                    wards: [
                        {
                            lable: 'Phường Bưởi',
                            value: '00109',
                        },
                        {
                            lable: 'Phường Nhật Tân',
                            value: '00094',
                        },
                        {
                            lable: 'Phường Phú Thượng',
                            value: '00091',
                        },
                        {
                            lable: 'Phường Quảng An',
                            value: '00100',
                        },
                        {
                            lable: 'Phường Thụy Khuê',
                            value: '00112',
                        },
                        {
                            lable: 'Phường Tứ Liên',
                            value: '00097',
                        },
                        {
                            lable: 'Phường Xuân La',
                            value: '00103',
                        },
                        {
                            lable: 'Phường Yên Phụ',
                            value: '00106',
                        },
                    ],
                },
                {
                    lable: 'Quận Long Biên',
                    value: '004',
                    wards: [
                        {
                            lable: 'Phường Bồ Đề',
                            value: '00139',
                        },
                        {
                            lable: 'Phường Cự Khối',
                            value: '00154',
                        },
                        {
                            lable: 'Phường Gia Thụy',
                            value: '00130',
                        },
                        {
                            lable: 'Phường Giang Biên',
                            value: '00121',
                        },
                        {
                            lable: 'Phường Long Biên',
                            value: '00145',
                        },
                        {
                            lable: 'Phường Ngọc Lâm',
                            value: '00133',
                        },
                        {
                            lable: 'Phường Ngọc Thụy',
                            value: '00118',
                        },
                        {
                            lable: 'Phường Phúc Lợi',
                            value: '00136',
                        },
                        {
                            lable: 'Phường Phúc Đồng',
                            value: '00151',
                        },
                        {
                            lable: 'Phường Sài Đồng',
                            value: '00142',
                        },
                        {
                            lable: 'Phường Thượng Thanh',
                            value: '00115',
                        },
                        {
                            lable: 'Phường Thạch Bàn',
                            value: '00148',
                        },
                        {
                            lable: 'Phường Việt Hưng',
                            value: '00127',
                        },
                        {
                            lable: 'Phường Đức Giang',
                            value: '00124',
                        },
                    ],
                },
                {
                    lable: 'Quận Cầu Giấy',
                    value: '005',
                    wards: [
                        {
                            lable: 'Phường Dịch Vọng',
                            value: '00166',
                        },
                        {
                            lable: 'Phường Dịch Vọng Hậu',
                            value: '00167',
                        },
                        {
                            lable: 'Phường Mai Dịch',
                            value: '00163',
                        },
                        {
                            lable: 'Phường Nghĩa Tân',
                            value: '00160',
                        },
                        {
                            lable: 'Phường Nghĩa Đô',
                            value: '00157',
                        },
                        {
                            lable: 'Phường Quan Hoa',
                            value: '00169',
                        },
                        {
                            lable: 'Phường Trung Hoà',
                            value: '00175',
                        },
                        {
                            lable: 'Phường Yên Hoà',
                            value: '00172',
                        },
                    ],
                },
                {
                    lable: 'Quận Đống Đa',
                    value: '006',
                    wards: [
                        {
                            lable: 'Phường Cát Linh',
                            value: '00178',
                        },
                        {
                            lable: 'Phường Hàng Bột',
                            value: '00196',
                        },
                        {
                            lable: 'Phường Khâm Thiên',
                            value: '00202',
                        },
                        {
                            lable: 'Phường Khương Thượng',
                            value: '00238',
                        },
                        {
                            lable: 'Phường Kim Liên',
                            value: '00229',
                        },
                        {
                            lable: 'Phường Láng Hạ',
                            value: '00199',
                        },
                        {
                            lable: 'Phường Láng Thượng',
                            value: '00187',
                        },
                        {
                            lable: 'Phường Nam Đồng',
                            value: '00208',
                        },
                        {
                            lable: 'Phường Ngã Tư Sở',
                            value: '00235',
                        },
                        {
                            lable: 'Phường Phương Liên',
                            value: '00220',
                        },
                        {
                            lable: 'Phường Phương Mai',
                            value: '00232',
                        },
                        {
                            lable: 'Phường Quang Trung',
                            value: '00214',
                        },
                        {
                            lable: 'Phường Quốc Tử Giám',
                            value: '00184',
                        },
                        {
                            lable: 'Phường Thịnh Quang',
                            value: '00223',
                        },
                        {
                            lable: 'Phường Thổ Quan',
                            value: '00205',
                        },
                        {
                            lable: 'Phường Trung Liệt',
                            value: '00217',
                        },
                        {
                            lable: 'Phường Trung Phụng',
                            value: '00211',
                        },
                        {
                            lable: 'Phường Trung Tự',
                            value: '00226',
                        },
                        {
                            lable: 'Phường Văn Chương',
                            value: '00193',
                        },
                        {
                            lable: 'Phường Văn Miếu',
                            value: '00181',
                        },
                        {
                            lable: 'Phường Ô Chợ Dừa',
                            value: '00190',
                        },
                    ],
                },
                {
                    lable: 'Quận Hai Bà Trưng',
                    value: '007',
                    wards: [
                        {
                            lable: 'Phường Bách Khoa',
                            value: '00277',
                        },
                        {
                            lable: 'Phường Bạch Mai',
                            value: '00286',
                        },
                        {
                            lable: 'Phường Bạch Đằng',
                            value: '00244',
                        },
                        {
                            lable: 'Phường Cầu Dền',
                            value: '00274',
                        },
                        {
                            lable: 'Phường Lê Đại Hành',
                            value: '00256',
                        },
                        {
                            lable: 'Phường Minh Khai',
                            value: '00295',
                        },
                        {
                            lable: 'Phường Nguyễn Du',
                            value: '00241',
                        },
                        {
                            lable: 'Phường Phạm Đình Hổ',
                            value: '00247',
                        },
                        {
                            lable: 'Phường Phố Huế',
                            value: '00262',
                        },
                        {
                            lable: 'Phường Quỳnh Lôi',
                            value: '00292',
                        },
                        {
                            lable: 'Phường Quỳnh Mai',
                            value: '00289',
                        },
                        {
                            lable: 'Phường Thanh Lương',
                            value: '00268',
                        },
                        {
                            lable: 'Phường Thanh Nhàn',
                            value: '00271',
                        },
                        {
                            lable: 'Phường Trương Định',
                            value: '00298',
                        },
                        {
                            lable: 'Phường Vĩnh Tuy',
                            value: '00283',
                        },
                        {
                            lable: 'Phường Đống Mác',
                            value: '00265',
                        },
                        {
                            lable: 'Phường Đồng Nhân',
                            value: '00259',
                        },
                        {
                            lable: 'Phường Đồng Tâm',
                            value: '00280',
                        },
                    ],
                },
                {
                    lable: 'Quận Hoàng Mai',
                    value: '008',
                    wards: [
                        {
                            lable: 'Phường Giáp Bát',
                            value: '00325',
                        },
                        {
                            lable: 'Phường Hoàng Liệt',
                            value: '00337',
                        },
                        {
                            lable: 'Phường Hoàng Văn Thụ',
                            value: '00322',
                        },
                        {
                            lable: 'Phường Lĩnh Nam',
                            value: '00328',
                        },
                        {
                            lable: 'Phường Mai Động',
                            value: '00310',
                        },
                        {
                            lable: 'Phường Thanh Trì',
                            value: '00301',
                        },
                        {
                            lable: 'Phường Thịnh Liệt',
                            value: '00331',
                        },
                        {
                            lable: 'Phường Trần Phú',
                            value: '00334',
                        },
                        {
                            lable: 'Phường Tân Mai',
                            value: '00319',
                        },
                        {
                            lable: 'Phường Tương Mai',
                            value: '00313',
                        },
                        {
                            lable: 'Phường Vĩnh Hưng',
                            value: '00304',
                        },
                        {
                            lable: 'Phường Yên Sở',
                            value: '00340',
                        },
                        {
                            lable: 'Phường Đại Kim',
                            value: '00316',
                        },
                        {
                            lable: 'Phường Định Công',
                            value: '00307',
                        },
                    ],
                },
                {
                    lable: 'Quận Thanh Xuân',
                    value: '009',
                    wards: [
                        {
                            lable: 'Phường Hạ Đình',
                            value: '00361',
                        },
                        {
                            lable: 'Phường Khương Mai',
                            value: '00352',
                        },
                        {
                            lable: 'Phường Khương Trung',
                            value: '00349',
                        },
                        {
                            lable: 'Phường Khương Đình',
                            value: '00364',
                        },
                        {
                            lable: 'Phường Kim Giang',
                            value: '00373',
                        },
                        {
                            lable: 'Phường Nhân Chính',
                            value: '00343',
                        },
                        {
                            lable: 'Phường Phương Liệt',
                            value: '00358',
                        },
                        {
                            lable: 'Phường Thanh Xuân Bắc',
                            value: '00367',
                        },
                        {
                            lable: 'Phường Thanh Xuân Nam',
                            value: '00370',
                        },
                        {
                            lable: 'Phường Thanh Xuân Trung',
                            value: '00355',
                        },
                        {
                            lable: 'Phường Thượng Đình',
                            value: '00346',
                        },
                    ],
                },
                {
                    lable: 'Huyện Sóc Sơn',
                    value: '016',
                    wards: [
                        {
                            lable: 'Thị trấn Sóc Sơn',
                            value: '00376',
                        },
                        {
                            lable: 'Xã Bắc Phú',
                            value: '00403',
                        },
                        {
                            lable: 'Xã Bắc Sơn',
                            value: '00379',
                        },
                        {
                            lable: 'Xã Hiền Ninh',
                            value: '00412',
                        },
                        {
                            lable: 'Xã Hồng Kỳ',
                            value: '00385',
                        },
                        {
                            lable: 'Xã Kim Lũ',
                            value: '00439',
                        },
                        {
                            lable: 'Xã Mai Đình',
                            value: '00427',
                        },
                        {
                            lable: 'Xã Minh Phú',
                            value: '00397',
                        },
                        {
                            lable: 'Xã Minh Trí',
                            value: '00382',
                        },
                        {
                            lable: 'Xã Nam Sơn',
                            value: '00388',
                        },
                        {
                            lable: 'Xã Phù Linh',
                            value: '00400',
                        },
                        {
                            lable: 'Xã Phù Lỗ',
                            value: '00448',
                        },
                        {
                            lable: 'Xã Phú Cường',
                            value: '00442',
                        },
                        {
                            lable: 'Xã Phú Minh',
                            value: '00445',
                        },
                        {
                            lable: 'Xã Quang Tiến',
                            value: '00409',
                        },
                        {
                            lable: 'Xã Thanh Xuân',
                            value: '00433',
                        },
                        {
                            lable: 'Xã Tiên Dược',
                            value: '00418',
                        },
                        {
                            lable: 'Xã Trung Giã',
                            value: '00391',
                        },
                        {
                            lable: 'Xã Tân Dân',
                            value: '00415',
                        },
                        {
                            lable: 'Xã Tân Hưng',
                            value: '00394',
                        },
                        {
                            lable: 'Xã Tân Minh',
                            value: '00406',
                        },
                        {
                            lable: 'Xã Việt Long',
                            value: '00421',
                        },
                        {
                            lable: 'Xã Xuân Giang',
                            value: '00424',
                        },
                        {
                            lable: 'Xã Xuân Thu',
                            value: '00451',
                        },
                        {
                            lable: 'Xã Đông Xuân',
                            value: '00436',
                        },
                        {
                            lable: 'Xã Đức Hoà',
                            value: '00430',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đông Anh',
                    value: '017',
                    wards: [
                        {
                            lable: 'Thị trấn Đông Anh',
                            value: '00454',
                        },
                        {
                            lable: 'Xã Bắc Hồng',
                            value: '00463',
                        },
                        {
                            lable: 'Xã Cổ Loa',
                            value: '00505',
                        },
                        {
                            lable: 'Xã Dục Tú',
                            value: '00496',
                        },
                        {
                            lable: 'Xã Hải Bối',
                            value: '00508',
                        },
                        {
                            lable: 'Xã Kim Chung',
                            value: '00493',
                        },
                        {
                            lable: 'Xã Kim Nỗ',
                            value: '00490',
                        },
                        {
                            lable: 'Xã Liên Hà',
                            value: '00484',
                        },
                        {
                            lable: 'Xã Mai Lâm',
                            value: '00520',
                        },
                        {
                            lable: 'Xã Nam Hồng',
                            value: '00469',
                        },
                        {
                            lable: 'Xã Nguyên Khê',
                            value: '00466',
                        },
                        {
                            lable: 'Xã Thuỵ Lâm',
                            value: '00460',
                        },
                        {
                            lable: 'Xã Tiên Dương',
                            value: '00472',
                        },
                        {
                            lable: 'Xã Tàm Xá',
                            value: '00517',
                        },
                        {
                            lable: 'Xã Uy Nỗ',
                            value: '00478',
                        },
                        {
                            lable: 'Xã Việt Hùng',
                            value: '00487',
                        },
                        {
                            lable: 'Xã Vân Hà',
                            value: '00475',
                        },
                        {
                            lable: 'Xã Vân Nội',
                            value: '00481',
                        },
                        {
                            lable: 'Xã Võng La',
                            value: '00514',
                        },
                        {
                            lable: 'Xã Vĩnh Ngọc',
                            value: '00502',
                        },
                        {
                            lable: 'Xã Xuân Canh',
                            value: '00511',
                        },
                        {
                            lable: 'Xã Xuân Nộn',
                            value: '00457',
                        },
                        {
                            lable: 'Xã Đông Hội',
                            value: '00523',
                        },
                        {
                            lable: 'Xã Đại Mạch',
                            value: '00499',
                        },
                    ],
                },
                {
                    lable: 'Huyện Gia Lâm',
                    value: '018',
                    wards: [
                        {
                            lable: 'Thị trấn Trâu Quỳ',
                            value: '00565',
                        },
                        {
                            lable: 'Thị trấn Yên Viên',
                            value: '00526',
                        },
                        {
                            lable: 'Xã Bát Tràng',
                            value: '00583',
                        },
                        {
                            lable: 'Xã Cổ Bi',
                            value: '00553',
                        },
                        {
                            lable: 'Xã Dương Hà',
                            value: '00541',
                        },
                        {
                            lable: 'Xã Dương Quang',
                            value: '00568',
                        },
                        {
                            lable: 'Xã Dương Xá',
                            value: '00571',
                        },
                        {
                            lable: 'Xã Kim Lan',
                            value: '00586',
                        },
                        {
                            lable: 'Xã Kim Sơn',
                            value: '00562',
                        },
                        {
                            lable: 'Xã Kiêu Kỵ',
                            value: '00580',
                        },
                        {
                            lable: 'Xã Lệ Chi',
                            value: '00550',
                        },
                        {
                            lable: 'Xã Ninh Hiệp',
                            value: '00535',
                        },
                        {
                            lable: 'Xã Phù Đổng',
                            value: '00544',
                        },
                        {
                            lable: 'Xã Phú Thị',
                            value: '00559',
                        },
                        {
                            lable: 'Xã Trung Mầu',
                            value: '00547',
                        },
                        {
                            lable: 'Xã Văn Đức',
                            value: '00589',
                        },
                        {
                            lable: 'Xã Yên Thường',
                            value: '00529',
                        },
                        {
                            lable: 'Xã Yên Viên',
                            value: '00532',
                        },
                        {
                            lable: 'Xã Đa Tốn',
                            value: '00577',
                        },
                        {
                            lable: 'Xã Đình Xuyên',
                            value: '00538',
                        },
                        {
                            lable: 'Xã Đông Dư',
                            value: '00574',
                        },
                        {
                            lable: 'Xã Đặng Xá',
                            value: '00556',
                        },
                    ],
                },
                {
                    lable: 'Quận Nam Từ Liêm',
                    value: '019',
                    wards: [
                        {
                            lable: 'Phường Cầu Diễn',
                            value: '00592',
                        },
                        {
                            lable: 'Phường Mễ Trì',
                            value: '00631',
                        },
                        {
                            lable: 'Phường Mỹ Đình 1',
                            value: '00625',
                        },
                        {
                            lable: 'Phường Mỹ Đình 2',
                            value: '00626',
                        },
                        {
                            lable: 'Phường Phú Đô',
                            value: '00632',
                        },
                        {
                            lable: 'Phường Phương Canh',
                            value: '00623',
                        },
                        {
                            lable: 'Phường Trung Văn',
                            value: '00637',
                        },
                        {
                            lable: 'Phường Tây Mỗ',
                            value: '00628',
                        },
                        {
                            lable: 'Phường Xuân Phương',
                            value: '00622',
                        },
                        {
                            lable: 'Phường Đại Mỗ',
                            value: '00634',
                        },
                    ],
                },
                {
                    lable: 'Huyện Thanh Trì',
                    value: '020',
                    wards: [
                        {
                            lable: 'Thị trấn Văn Điển',
                            value: '00640',
                        },
                        {
                            lable: 'Xã Duyên Hà',
                            value: '00670',
                        },
                        {
                            lable: 'Xã Hữu Hoà',
                            value: '00652',
                        },
                        {
                            lable: 'Xã Liên Ninh',
                            value: '00682',
                        },
                        {
                            lable: 'Xã Ngũ Hiệp',
                            value: '00667',
                        },
                        {
                            lable: 'Xã Ngọc Hồi',
                            value: '00673',
                        },
                        {
                            lable: 'Xã Tam Hiệp',
                            value: '00655',
                        },
                        {
                            lable: 'Xã Thanh Liệt',
                            value: '00646',
                        },
                        {
                            lable: 'Xã Tân Triều',
                            value: '00643',
                        },
                        {
                            lable: 'Xã Tả Thanh Oai',
                            value: '00649',
                        },
                        {
                            lable: 'Xã Tứ Hiệp',
                            value: '00658',
                        },
                        {
                            lable: 'Xã Vĩnh Quỳnh',
                            value: '00664',
                        },
                        {
                            lable: 'Xã Vạn Phúc',
                            value: '00676',
                        },
                        {
                            lable: 'Xã Yên Mỹ',
                            value: '00661',
                        },
                        {
                            lable: 'Xã Đông Mỹ',
                            value: '00685',
                        },
                        {
                            lable: 'Xã Đại áng',
                            value: '00679',
                        },
                    ],
                },
                {
                    lable: 'Quận Bắc Từ Liêm',
                    value: '021',
                    wards: [
                        {
                            lable: 'Phường Cổ Nhuế 1',
                            value: '00616',
                        },
                        {
                            lable: 'Phường Cổ Nhuế 2',
                            value: '00617',
                        },
                        {
                            lable: 'Phường Liên Mạc',
                            value: '00598',
                        },
                        {
                            lable: 'Phường Minh Khai',
                            value: '00613',
                        },
                        {
                            lable: 'Phường Phú Diễn',
                            value: '00619',
                        },
                        {
                            lable: 'Phường Phúc Diễn',
                            value: '00620',
                        },
                        {
                            lable: 'Phường Thượng Cát',
                            value: '00595',
                        },
                        {
                            lable: 'Phường Thụy Phương',
                            value: '00604',
                        },
                        {
                            lable: 'Phường Tây Tựu',
                            value: '00607',
                        },
                        {
                            lable: 'Phường Xuân Tảo',
                            value: '00611',
                        },
                        {
                            lable: 'Phường Xuân Đỉnh',
                            value: '00610',
                        },
                        {
                            lable: 'Phường Đông Ngạc',
                            value: '00601',
                        },
                        {
                            lable: 'Phường Đức Thắng',
                            value: '00602',
                        },
                    ],
                },
                {
                    lable: 'Huyện Mê Linh',
                    value: '250',
                    wards: [
                        {
                            lable: 'Thị trấn Chi Đông',
                            value: '08973',
                        },
                        {
                            lable: 'Thị trấn Quang Minh',
                            value: '08989',
                        },
                        {
                            lable: 'Xã Chu Phan',
                            value: '09004',
                        },
                        {
                            lable: 'Xã Hoàng Kim',
                            value: '09016',
                        },
                        {
                            lable: 'Xã Kim Hoa',
                            value: '08977',
                        },
                        {
                            lable: 'Xã Liên Mạc',
                            value: '08998',
                        },
                        {
                            lable: 'Xã Mê Linh',
                            value: '09010',
                        },
                        {
                            lable: 'Xã Tam Đồng',
                            value: '08995',
                        },
                        {
                            lable: 'Xã Thanh Lâm',
                            value: '08992',
                        },
                        {
                            lable: 'Xã Thạch Đà',
                            value: '08980',
                        },
                        {
                            lable: 'Xã Tiến Thắng',
                            value: '08983',
                        },
                        {
                            lable: 'Xã Tiến Thịnh',
                            value: '09007',
                        },
                        {
                            lable: 'Xã Tiền Phong',
                            value: '09019',
                        },
                        {
                            lable: 'Xã Tráng Việt',
                            value: '09022',
                        },
                        {
                            lable: 'Xã Tự Lập',
                            value: '08986',
                        },
                        {
                            lable: 'Xã Văn Khê',
                            value: '09013',
                        },
                        {
                            lable: 'Xã Vạn Yên',
                            value: '09001',
                        },
                        {
                            lable: 'Xã Đại Thịnh',
                            value: '08974',
                        },
                    ],
                },
                {
                    lable: 'Quận Hà Đông',
                    value: '268',
                    wards: [
                        {
                            lable: 'Phường Biên Giang',
                            value: '10123',
                        },
                        {
                            lable: 'Phường Dương Nội',
                            value: '09886',
                        },
                        {
                            lable: 'Phường Hà Cầu',
                            value: '09556',
                        },
                        {
                            lable: 'Phường Kiến Hưng',
                            value: '09565',
                        },
                        {
                            lable: 'Phường La Khê',
                            value: '09551',
                        },
                        {
                            lable: 'Phường Mộ Lao',
                            value: '09541',
                        },
                        {
                            lable: 'Phường Nguyễn Trãi',
                            value: '09538',
                        },
                        {
                            lable: 'Phường Phú La',
                            value: '09552',
                        },
                        {
                            lable: 'Phường Phú Lãm',
                            value: '09568',
                        },
                        {
                            lable: 'Phường Phú Lương',
                            value: '09571',
                        },
                        {
                            lable: 'Phường Phúc La',
                            value: '09553',
                        },
                        {
                            lable: 'Phường Quang Trung',
                            value: '09550',
                        },
                        {
                            lable: 'Phường Văn Quán',
                            value: '09542',
                        },
                        {
                            lable: 'Phường Vạn Phúc',
                            value: '09544',
                        },
                        {
                            lable: 'Phường Yên Nghĩa',
                            value: '09562',
                        },
                        {
                            lable: 'Phường Yết Kiêu',
                            value: '09547',
                        },
                        {
                            lable: 'Phường Đồng Mai',
                            value: '10117',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Sơn Tây',
                    value: '269',
                    wards: [
                        {
                            lable: 'Phường Lê Lợi',
                            value: '09574',
                        },
                        {
                            lable: 'Phường Ngô Quyền',
                            value: '09580',
                        },
                        {
                            lable: 'Phường Phú Thịnh',
                            value: '09577',
                        },
                        {
                            lable: 'Phường Quang Trung',
                            value: '09583',
                        },
                        {
                            lable: 'Phường Sơn Lộc',
                            value: '09586',
                        },
                        {
                            lable: 'Phường Trung Hưng',
                            value: '09601',
                        },
                        {
                            lable: 'Phường Trung Sơn Trầm',
                            value: '09607',
                        },
                        {
                            lable: 'Phường Viên Sơn',
                            value: '09595',
                        },
                        {
                            lable: 'Phường Xuân Khanh',
                            value: '09589',
                        },
                        {
                            lable: 'Xã Cổ Đông',
                            value: '09616',
                        },
                        {
                            lable: 'Xã Kim Sơn',
                            value: '09610',
                        },
                        {
                            lable: 'Xã Sơn Đông',
                            value: '09613',
                        },
                        {
                            lable: 'Xã Thanh Mỹ',
                            value: '09604',
                        },
                        {
                            lable: 'Xã Xuân Sơn',
                            value: '09598',
                        },
                        {
                            lable: 'Xã Đường Lâm',
                            value: '09592',
                        },
                    ],
                },
                {
                    lable: 'Huyện Ba Vì',
                    value: '271',
                    wards: [
                        {
                            lable: 'Thị trấn Tây Đằng',
                            value: '09619',
                        },
                        {
                            lable: 'Xã Ba Trại',
                            value: '09697',
                        },
                        {
                            lable: 'Xã Ba Vì',
                            value: '09703',
                        },
                        {
                            lable: 'Xã Cam Thượng',
                            value: '09688',
                        },
                        {
                            lable: 'Xã Chu Minh',
                            value: '09667',
                        },
                        {
                            lable: 'Xã Châu Sơn',
                            value: '09637',
                        },
                        {
                            lable: 'Xã Cẩm Lĩnh',
                            value: '09673',
                        },
                        {
                            lable: 'Xã Cổ Đô',
                            value: '09628',
                        },
                        {
                            lable: 'Xã Khánh Thượng',
                            value: '09712',
                        },
                        {
                            lable: 'Xã Minh Châu',
                            value: '09661',
                        },
                        {
                            lable: 'Xã Minh Quang',
                            value: '09700',
                        },
                        {
                            lable: 'Xã Phong Vân',
                            value: '09640',
                        },
                        {
                            lable: 'Xã Phú Châu',
                            value: '09649',
                        },
                        {
                            lable: 'Xã Phú Cường',
                            value: '09625',
                        },
                        {
                            lable: 'Xã Phú Phương',
                            value: '09646',
                        },
                        {
                            lable: 'Xã Phú Sơn',
                            value: '09658',
                        },
                        {
                            lable: 'Xã Phú Đông',
                            value: '09643',
                        },
                        {
                            lable: 'Xã Sơn Đà',
                            value: '09676',
                        },
                        {
                            lable: 'Xã Thuần Mỹ',
                            value: '09691',
                        },
                        {
                            lable: 'Xã Thái Hòa',
                            value: '09652',
                        },
                        {
                            lable: 'Xã Thụy An',
                            value: '09685',
                        },
                        {
                            lable: 'Xã Tiên Phong',
                            value: '09682',
                        },
                        {
                            lable: 'Xã Tòng Bạt',
                            value: '09670',
                        },
                        {
                            lable: 'Xã Tản Hồng',
                            value: '09631',
                        },
                        {
                            lable: 'Xã Tản Lĩnh',
                            value: '09694',
                        },
                        {
                            lable: 'Xã Vân Hòa',
                            value: '09706',
                        },
                        {
                            lable: 'Xã Vạn Thắng',
                            value: '09634',
                        },
                        {
                            lable: 'Xã Vật Lại',
                            value: '09664',
                        },
                        {
                            lable: 'Xã Yên Bài',
                            value: '09709',
                        },
                        {
                            lable: 'Xã Đông Quang',
                            value: '09679',
                        },
                        {
                            lable: 'Xã Đồng Thái',
                            value: '09655',
                        },
                    ],
                },
                {
                    lable: 'Huyện Phúc Thọ',
                    value: '272',
                    wards: [
                        {
                            lable: 'Thị trấn Phúc Thọ',
                            value: '09715',
                        },
                        {
                            lable: 'Xã Hiệp Thuận',
                            value: '09778',
                        },
                        {
                            lable: 'Xã Hát Môn',
                            value: '09751',
                        },
                        {
                            lable: 'Xã Liên Hiệp',
                            value: '09781',
                        },
                        {
                            lable: 'Xã Long Xuyên',
                            value: '09745',
                        },
                        {
                            lable: 'Xã Ngọc Tảo',
                            value: '09766',
                        },
                        {
                            lable: 'Xã Phúc Hòa',
                            value: '09763',
                        },
                        {
                            lable: 'Xã Phụng Thượng',
                            value: '09769',
                        },
                        {
                            lable: 'Xã Sen Phương',
                            value: '09733',
                        },
                        {
                            lable: 'Xã Tam Hiệp',
                            value: '09775',
                        },
                        {
                            lable: 'Xã Tam Thuấn',
                            value: '09772',
                        },
                        {
                            lable: 'Xã Thanh Đa',
                            value: '09757',
                        },
                        {
                            lable: 'Xã Thượng Cốc',
                            value: '09748',
                        },
                        {
                            lable: 'Xã Thọ Lộc',
                            value: '09742',
                        },
                        {
                            lable: 'Xã Trạch Mỹ Lộc',
                            value: '09760',
                        },
                        {
                            lable: 'Xã Tích Giang',
                            value: '09754',
                        },
                        {
                            lable: 'Xã Vân Hà',
                            value: '09718',
                        },
                        {
                            lable: 'Xã Vân Nam',
                            value: '09724',
                        },
                        {
                            lable: 'Xã Vân Phúc',
                            value: '09721',
                        },
                        {
                            lable: 'Xã Võng Xuyên',
                            value: '09739',
                        },
                        {
                            lable: 'Xã Xuân Đình',
                            value: '09727',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đan Phượng',
                    value: '273',
                    wards: [
                        {
                            lable: 'Thị trấn Phùng',
                            value: '09784',
                        },
                        {
                            lable: 'Xã Hạ Mỗ',
                            value: '09805',
                        },
                        {
                            lable: 'Xã Hồng Hà',
                            value: '09796',
                        },
                        {
                            lable: 'Xã Liên Hà',
                            value: '09802',
                        },
                        {
                            lable: 'Xã Liên Hồng',
                            value: '09799',
                        },
                        {
                            lable: 'Xã Liên Trung',
                            value: '09808',
                        },
                        {
                            lable: 'Xã Phương Đình',
                            value: '09811',
                        },
                        {
                            lable: 'Xã Song Phượng',
                            value: '09829',
                        },
                        {
                            lable: 'Xã Thượng Mỗ',
                            value: '09814',
                        },
                        {
                            lable: 'Xã Thọ An',
                            value: '09790',
                        },
                        {
                            lable: 'Xã Thọ Xuân',
                            value: '09793',
                        },
                        {
                            lable: 'Xã Trung Châu',
                            value: '09787',
                        },
                        {
                            lable: 'Xã Tân Hội',
                            value: '09817',
                        },
                        {
                            lable: 'Xã Tân Lập',
                            value: '09820',
                        },
                        {
                            lable: 'Xã Đan Phượng',
                            value: '09823',
                        },
                        {
                            lable: 'Xã Đồng Tháp',
                            value: '09826',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hoài Đức',
                    value: '274',
                    wards: [
                        {
                            lable: 'Thị trấn Trạm Trôi',
                            value: '09832',
                        },
                        {
                            lable: 'Xã An Khánh',
                            value: '09877',
                        },
                        {
                            lable: 'Xã An Thượng',
                            value: '09880',
                        },
                        {
                            lable: 'Xã Cát Quế',
                            value: '09850',
                        },
                        {
                            lable: 'Xã Di Trạch',
                            value: '09844',
                        },
                        {
                            lable: 'Xã Dương Liễu',
                            value: '09841',
                        },
                        {
                            lable: 'Xã Kim Chung',
                            value: '09853',
                        },
                        {
                            lable: 'Xã La Phù',
                            value: '09889',
                        },
                        {
                            lable: 'Xã Lại Yên',
                            value: '09868',
                        },
                        {
                            lable: 'Xã Minh Khai',
                            value: '09838',
                        },
                        {
                            lable: 'Xã Song Phương',
                            value: '09874',
                        },
                        {
                            lable: 'Xã Sơn Đồng',
                            value: '09859',
                        },
                        {
                            lable: 'Xã Tiền Yên',
                            value: '09871',
                        },
                        {
                            lable: 'Xã Vân Canh',
                            value: '09862',
                        },
                        {
                            lable: 'Xã Vân Côn',
                            value: '09883',
                        },
                        {
                            lable: 'Xã Yên Sở',
                            value: '09856',
                        },
                        {
                            lable: 'Xã Đông La',
                            value: '09892',
                        },
                        {
                            lable: 'Xã Đắc Sở',
                            value: '09865',
                        },
                        {
                            lable: 'Xã Đức Giang',
                            value: '09847',
                        },
                        {
                            lable: 'Xã Đức Thượng',
                            value: '09835',
                        },
                    ],
                },
                {
                    lable: 'Huyện Quốc Oai',
                    value: '275',
                    wards: [
                        {
                            lable: 'Thị trấn Quốc Oai',
                            value: '09895',
                        },
                        {
                            lable: 'Xã Cấn Hữu',
                            value: '09943',
                        },
                        {
                            lable: 'Xã Cộng Hòa',
                            value: '09931',
                        },
                        {
                            lable: 'Xã Hòa Thạch',
                            value: '09949',
                        },
                        {
                            lable: 'Xã Liệp Tuyết',
                            value: '09913',
                        },
                        {
                            lable: 'Xã Nghĩa Hương',
                            value: '09928',
                        },
                        {
                            lable: 'Xã Ngọc Liệp',
                            value: '09907',
                        },
                        {
                            lable: 'Xã Ngọc Mỹ',
                            value: '09910',
                        },
                        {
                            lable: 'Xã Phú Cát',
                            value: '09922',
                        },
                        {
                            lable: 'Xã Phú Mãn',
                            value: '09940',
                        },
                        {
                            lable: 'Xã Phượng Cách',
                            value: '09901',
                        },
                        {
                            lable: 'Xã Sài Sơn',
                            value: '09898',
                        },
                        {
                            lable: 'Xã Thạch Thán',
                            value: '09916',
                        },
                        {
                            lable: 'Xã Tuyết Nghĩa',
                            value: '09925',
                        },
                        {
                            lable: 'Xã Tân Hòa',
                            value: '09946',
                        },
                        {
                            lable: 'Xã Tân Phú',
                            value: '09934',
                        },
                        {
                            lable: 'Xã Yên Sơn',
                            value: '09904',
                        },
                        {
                            lable: 'Xã Đông Xuân',
                            value: '04939',
                        },
                        {
                            lable: 'Xã Đông Yên',
                            value: '09952',
                        },
                        {
                            lable: 'Xã Đại Thành',
                            value: '09937',
                        },
                        {
                            lable: 'Xã Đồng Quang',
                            value: '09919',
                        },
                    ],
                },
                {
                    lable: 'Huyện Thạch Thất',
                    value: '276',
                    wards: [
                        {
                            lable: 'Thị trấn Liên Quan',
                            value: '09955',
                        },
                        {
                            lable: 'Xã Bình Phú',
                            value: '10006',
                        },
                        {
                            lable: 'Xã Bình Yên',
                            value: '09982',
                        },
                        {
                            lable: 'Xã Canh Nậu',
                            value: '09973',
                        },
                        {
                            lable: 'Xã Chàng Sơn',
                            value: '09985',
                        },
                        {
                            lable: 'Xã Cần Kiệm',
                            value: '09991',
                        },
                        {
                            lable: 'Xã Cẩm Yên',
                            value: '09961',
                        },
                        {
                            lable: 'Xã Dị Nậu',
                            value: '09979',
                        },
                        {
                            lable: 'Xã Hương Ngải',
                            value: '09970',
                        },
                        {
                            lable: 'Xã Hạ Bằng',
                            value: '10009',
                        },
                        {
                            lable: 'Xã Hữu Bằng',
                            value: '09994',
                        },
                        {
                            lable: 'Xã Kim Quan',
                            value: '09976',
                        },
                        {
                            lable: 'Xã Lại Thượng',
                            value: '09964',
                        },
                        {
                            lable: 'Xã Phùng Xá',
                            value: '09997',
                        },
                        {
                            lable: 'Xã Phú Kim',
                            value: '09967',
                        },
                        {
                            lable: 'Xã Thạch Hoà',
                            value: '09988',
                        },
                        {
                            lable: 'Xã Thạch Xá',
                            value: '10003',
                        },
                        {
                            lable: 'Xã Tiến Xuân',
                            value: '04936',
                        },
                        {
                            lable: 'Xã Tân Xã',
                            value: '10000',
                        },
                        {
                            lable: 'Xã Yên Bình',
                            value: '04930',
                        },
                        {
                            lable: 'Xã Yên Trung',
                            value: '04927',
                        },
                        {
                            lable: 'Xã Đại Đồng',
                            value: '09958',
                        },
                        {
                            lable: 'Xã Đồng Trúc',
                            value: '10012',
                        },
                    ],
                },
                {
                    lable: 'Huyện Chương Mỹ',
                    value: '277',
                    wards: [
                        {
                            lable: 'Thị trấn Chúc Sơn',
                            value: '10015',
                        },
                        {
                            lable: 'Thị trấn Xuân Mai',
                            value: '10018',
                        },
                        {
                            lable: 'Xã Hoàng Diệu',
                            value: '10078',
                        },
                        {
                            lable: 'Xã Hoàng Văn Thụ',
                            value: '10075',
                        },
                        {
                            lable: 'Xã Hòa Chính',
                            value: '10108',
                        },
                        {
                            lable: 'Xã Hồng Phong',
                            value: '10093',
                        },
                        {
                            lable: 'Xã Hợp Đồng',
                            value: '10072',
                        },
                        {
                            lable: 'Xã Hữu Văn',
                            value: '10081',
                        },
                        {
                            lable: 'Xã Lam Điền',
                            value: '10063',
                        },
                        {
                            lable: 'Xã Mỹ Lương',
                            value: '10087',
                        },
                        {
                            lable: 'Xã Nam Phương Tiến',
                            value: '10069',
                        },
                        {
                            lable: 'Xã Ngọc Hòa',
                            value: '10042',
                        },
                        {
                            lable: 'Xã Phú Nam An',
                            value: '10111',
                        },
                        {
                            lable: 'Xã Phú Nghĩa',
                            value: '10033',
                        },
                        {
                            lable: 'Xã Phụng Châu',
                            value: '10021',
                        },
                        {
                            lable: 'Xã Quảng Bị',
                            value: '10084',
                        },
                        {
                            lable: 'Xã Thanh Bình',
                            value: '10048',
                        },
                        {
                            lable: 'Xã Thượng Vực',
                            value: '10090',
                        },
                        {
                            lable: 'Xã Thụy Hương',
                            value: '10057',
                        },
                        {
                            lable: 'Xã Thủy Xuân Tiên',
                            value: '10045',
                        },
                        {
                            lable: 'Xã Tiên Phương',
                            value: '10024',
                        },
                        {
                            lable: 'Xã Trung Hòa',
                            value: '10051',
                        },
                        {
                            lable: 'Xã Trường Yên',
                            value: '10039',
                        },
                        {
                            lable: 'Xã Trần Phú',
                            value: '10099',
                        },
                        {
                            lable: 'Xã Tân Tiến',
                            value: '10066',
                        },
                        {
                            lable: 'Xã Tốt Động',
                            value: '10060',
                        },
                        {
                            lable: 'Xã Văn Võ',
                            value: '10102',
                        },
                        {
                            lable: 'Xã Đông Phương Yên',
                            value: '10030',
                        },
                        {
                            lable: 'Xã Đông Sơn',
                            value: '10027',
                        },
                        {
                            lable: 'Xã Đại Yên',
                            value: '10054',
                        },
                        {
                            lable: 'Xã Đồng Lạc',
                            value: '10105',
                        },
                        {
                            lable: 'Xã Đồng Phú',
                            value: '10096',
                        },
                    ],
                },
                {
                    lable: 'Huyện Thanh Oai',
                    value: '278',
                    wards: [
                        {
                            lable: 'Thị trấn Kim Bài',
                            value: '10114',
                        },
                        {
                            lable: 'Xã Bình Minh',
                            value: '10135',
                        },
                        {
                            lable: 'Xã Bích Hòa',
                            value: '10126',
                        },
                        {
                            lable: 'Xã Cao Dương',
                            value: '10174',
                        },
                        {
                            lable: 'Xã Cao Viên',
                            value: '10132',
                        },
                        {
                            lable: 'Xã Cự Khê',
                            value: '10120',
                        },
                        {
                            lable: 'Xã Dân Hòa',
                            value: '10168',
                        },
                        {
                            lable: 'Xã Hồng Dương',
                            value: '10180',
                        },
                        {
                            lable: 'Xã Kim An',
                            value: '10156',
                        },
                        {
                            lable: 'Xã Kim Thư',
                            value: '10159',
                        },
                        {
                            lable: 'Xã Liên Châu',
                            value: '10171',
                        },
                        {
                            lable: 'Xã Mỹ Hưng',
                            value: '10129',
                        },
                        {
                            lable: 'Xã Phương Trung',
                            value: '10162',
                        },
                        {
                            lable: 'Xã Tam Hưng',
                            value: '10138',
                        },
                        {
                            lable: 'Xã Thanh Cao',
                            value: '10141',
                        },
                        {
                            lable: 'Xã Thanh Mai',
                            value: '10147',
                        },
                        {
                            lable: 'Xã Thanh Thùy',
                            value: '10144',
                        },
                        {
                            lable: 'Xã Thanh Văn',
                            value: '10150',
                        },
                        {
                            lable: 'Xã Tân Ước',
                            value: '10165',
                        },
                        {
                            lable: 'Xã Xuân Dương',
                            value: '10177',
                        },
                        {
                            lable: 'Xã Đỗ Động',
                            value: '10153',
                        },
                    ],
                },
                {
                    lable: 'Huyện Thường Tín',
                    value: '279',
                    wards: [
                        {
                            lable: 'Thị trấn Thường Tín',
                            value: '10183',
                        },
                        {
                            lable: 'Xã Chương Dương',
                            value: '10237',
                        },
                        {
                            lable: 'Xã Duyên Thái',
                            value: '10192',
                        },
                        {
                            lable: 'Xã Dũng Tiến',
                            value: '10249',
                        },
                        {
                            lable: 'Xã Hiền Giang',
                            value: '10204',
                        },
                        {
                            lable: 'Xã Hà Hồi',
                            value: '10225',
                        },
                        {
                            lable: 'Xã Hòa Bình',
                            value: '10198',
                        },
                        {
                            lable: 'Xã Hồng Vân',
                            value: '10207',
                        },
                        {
                            lable: 'Xã Khánh Hà',
                            value: '10195',
                        },
                        {
                            lable: 'Xã Liên Phương',
                            value: '10213',
                        },
                        {
                            lable: 'Xã Lê Lợi',
                            value: '10243',
                        },
                        {
                            lable: 'Xã Minh Cường',
                            value: '10267',
                        },
                        {
                            lable: 'Xã Nghiêm Xuyên',
                            value: '10255',
                        },
                        {
                            lable: 'Xã Nguyễn Trãi',
                            value: '10231',
                        },
                        {
                            lable: 'Xã Nhị Khê',
                            value: '10189',
                        },
                        {
                            lable: 'Xã Ninh Sở',
                            value: '10186',
                        },
                        {
                            lable: 'Xã Quất Động',
                            value: '10234',
                        },
                        {
                            lable: 'Xã Thư Phú',
                            value: '10228',
                        },
                        {
                            lable: 'Xã Thắng Lợi',
                            value: '10246',
                        },
                        {
                            lable: 'Xã Thống Nhất',
                            value: '10252',
                        },
                        {
                            lable: 'Xã Tiền Phong',
                            value: '10222',
                        },
                        {
                            lable: 'Xã Tân Minh',
                            value: '10240',
                        },
                        {
                            lable: 'Xã Tô Hiệu',
                            value: '10258',
                        },
                        {
                            lable: 'Xã Tự Nhiên',
                            value: '10219',
                        },
                        {
                            lable: 'Xã Vân Tảo',
                            value: '10210',
                        },
                        {
                            lable: 'Xã Văn Bình',
                            value: '10201',
                        },
                        {
                            lable: 'Xã Văn Phú',
                            value: '10216',
                        },
                        {
                            lable: 'Xã Văn Tự',
                            value: '10261',
                        },
                        {
                            lable: 'Xã Vạn Điểm',
                            value: '10264',
                        },
                    ],
                },
                {
                    lable: 'Huyện Phú Xuyên',
                    value: '280',
                    wards: [
                        {
                            lable: 'Thị trấn Phú Minh',
                            value: '10270',
                        },
                        {
                            lable: 'Thị trấn Phú Xuyên',
                            value: '10273',
                        },
                        {
                            lable: 'Xã Bạch Hạ',
                            value: '10342',
                        },
                        {
                            lable: 'Xã Chuyên Mỹ',
                            value: '10321',
                        },
                        {
                            lable: 'Xã Châu Can',
                            value: '10348',
                        },
                        {
                            lable: 'Xã Hoàng Long',
                            value: '10303',
                        },
                        {
                            lable: 'Xã Hồng Minh',
                            value: '10276',
                        },
                        {
                            lable: 'Xã Hồng Thái',
                            value: '10300',
                        },
                        {
                            lable: 'Xã Khai Thái',
                            value: '10324',
                        },
                        {
                            lable: 'Xã Minh Tân',
                            value: '10351',
                        },
                        {
                            lable: 'Xã Nam Phong',
                            value: '10309',
                        },
                        {
                            lable: 'Xã Nam Tiến',
                            value: '10282',
                        },
                        {
                            lable: 'Xã Nam Triều',
                            value: '10312',
                        },
                        {
                            lable: 'Xã Phú Túc',
                            value: '10294',
                        },
                        {
                            lable: 'Xã Phú Yên',
                            value: '10339',
                        },
                        {
                            lable: 'Xã Phúc Tiến',
                            value: '10327',
                        },
                        {
                            lable: 'Xã Phượng Dực',
                            value: '10279',
                        },
                        {
                            lable: 'Xã Quang Lãng',
                            value: '10345',
                        },
                        {
                            lable: 'Xã Quang Trung',
                            value: '10306',
                        },
                        {
                            lable: 'Xã Sơn Hà',
                            value: '10318',
                        },
                        {
                            lable: 'Xã Tri Thủy',
                            value: '10333',
                        },
                        {
                            lable: 'Xã Tri Trung',
                            value: '10288',
                        },
                        {
                            lable: 'Xã Tân Dân',
                            value: '10315',
                        },
                        {
                            lable: 'Xã Vân Từ',
                            value: '10330',
                        },
                        {
                            lable: 'Xã Văn Hoàng',
                            value: '10297',
                        },
                        {
                            lable: 'Xã Đại Thắng',
                            value: '10291',
                        },
                        {
                            lable: 'Xã Đại Xuyên',
                            value: '10336',
                        },
                    ],
                },
                {
                    lable: 'Huyện Ứng Hòa',
                    value: '281',
                    wards: [
                        {
                            lable: 'Thị trấn Vân Đình',
                            value: '10354',
                        },
                        {
                            lable: 'Xã Cao Thành',
                            value: '10372',
                        },
                        {
                            lable: 'Xã Hoa Sơn',
                            value: '10363',
                        },
                        {
                            lable: 'Xã Hòa Lâm',
                            value: '10402',
                        },
                        {
                            lable: 'Xã Hòa Nam',
                            value: '10414',
                        },
                        {
                            lable: 'Xã Hòa Phú',
                            value: '10417',
                        },
                        {
                            lable: 'Xã Hòa Xá',
                            value: '10405',
                        },
                        {
                            lable: 'Xã Hồng Quang',
                            value: '10438',
                        },
                        {
                            lable: 'Xã Kim Đường',
                            value: '10411',
                        },
                        {
                            lable: 'Xã Liên Bạt',
                            value: '10375',
                        },
                        {
                            lable: 'Xã Lưu Hoàng',
                            value: '10435',
                        },
                        {
                            lable: 'Xã Minh Đức',
                            value: '10399',
                        },
                        {
                            lable: 'Xã Phù Lưu',
                            value: '10429',
                        },
                        {
                            lable: 'Xã Phương Tú',
                            value: '10384',
                        },
                        {
                            lable: 'Xã Quảng Phú Cầu',
                            value: '10366',
                        },
                        {
                            lable: 'Xã Sơn Công',
                            value: '10378',
                        },
                        {
                            lable: 'Xã Trung Tú',
                            value: '10387',
                        },
                        {
                            lable: 'Xã Trường Thịnh',
                            value: '10369',
                        },
                        {
                            lable: 'Xã Trầm Lộng',
                            value: '10408',
                        },
                        {
                            lable: 'Xã Tảo Dương Văn',
                            value: '10393',
                        },
                        {
                            lable: 'Xã Viên An',
                            value: '10357',
                        },
                        {
                            lable: 'Xã Viên Nội',
                            value: '10360',
                        },
                        {
                            lable: 'Xã Vạn Thái',
                            value: '10396',
                        },
                        {
                            lable: 'Xã Đông Lỗ',
                            value: '10426',
                        },
                        {
                            lable: 'Xã Đại Cường',
                            value: '10432',
                        },
                        {
                            lable: 'Xã Đại Hùng',
                            value: '10423',
                        },
                        {
                            lable: 'Xã Đồng Tiến',
                            value: '10381',
                        },
                        {
                            lable: 'Xã Đồng Tân',
                            value: '10390',
                        },
                        {
                            lable: 'Xã Đội Bình',
                            value: '10420',
                        },
                    ],
                },
                {
                    lable: 'Huyện Mỹ Đức',
                    value: '282',
                    wards: [
                        {
                            lable: 'Thị trấn Đại Nghĩa',
                            value: '10441',
                        },
                        {
                            lable: 'Xã An Mỹ',
                            value: '10462',
                        },
                        {
                            lable: 'Xã An Phú',
                            value: '10504',
                        },
                        {
                            lable: 'Xã An Tiến',
                            value: '10495',
                        },
                        {
                            lable: 'Xã Bột Xuyên',
                            value: '10459',
                        },
                        {
                            lable: 'Xã Hùng Tiến',
                            value: '10492',
                        },
                        {
                            lable: 'Xã Hương Sơn',
                            value: '10489',
                        },
                        {
                            lable: 'Xã Hồng Sơn',
                            value: '10465',
                        },
                        {
                            lable: 'Xã Hợp Thanh',
                            value: '10501',
                        },
                        {
                            lable: 'Xã Hợp Tiến',
                            value: '10498',
                        },
                        {
                            lable: 'Xã Lê Thanh',
                            value: '10468',
                        },
                        {
                            lable: 'Xã Mỹ Thành',
                            value: '10456',
                        },
                        {
                            lable: 'Xã Phù Lưu Tế',
                            value: '10477',
                        },
                        {
                            lable: 'Xã Phùng Xá',
                            value: '10474',
                        },
                        {
                            lable: 'Xã Phúc Lâm',
                            value: '10453',
                        },
                        {
                            lable: 'Xã Thượng Lâm',
                            value: '10447',
                        },
                        {
                            lable: 'Xã Tuy Lai',
                            value: '10450',
                        },
                        {
                            lable: 'Xã Vạn Kim',
                            value: '10483',
                        },
                        {
                            lable: 'Xã Xuy Xá',
                            value: '10471',
                        },
                        {
                            lable: 'Xã Đại Hưng',
                            value: '10480',
                        },
                        {
                            lable: 'Xã Đốc Tín',
                            value: '10486',
                        },
                        {
                            lable: 'Xã Đồng Tâm',
                            value: '10444',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Thành phố Cần Thơ',
            value: '92',
            type: 'Thành phố Trung ương',
            districts: [
                {
                    lable: 'Quận Ninh Kiều',
                    value: '916',
                    wards: [
                        {
                            lable: 'Phường An Bình',
                            value: '31150',
                        },
                        {
                            lable: 'Phường An Cư',
                            value: '31129',
                        },
                        {
                            lable: 'Phường An Hòa',
                            value: '31120',
                        },
                        {
                            lable: 'Phường An Khánh',
                            value: '31149',
                        },
                        {
                            lable: 'Phường An Nghiệp',
                            value: '31126',
                        },
                        {
                            lable: 'Phường An Phú',
                            value: '31141',
                        },
                        {
                            lable: 'Phường Cái Khế',
                            value: '31117',
                        },
                        {
                            lable: 'Phường Hưng Lợi',
                            value: '31147',
                        },
                        {
                            lable: 'Phường Thới Bình',
                            value: '31123',
                        },
                        {
                            lable: 'Phường Tân An',
                            value: '31135',
                        },
                        {
                            lable: 'Phường Xuân Khánh',
                            value: '31144',
                        },
                    ],
                },
                {
                    lable: 'Quận Ô Môn',
                    value: '917',
                    wards: [
                        {
                            lable: 'Phường Châu Văn Liêm',
                            value: '31153',
                        },
                        {
                            lable: 'Phường Long Hưng',
                            value: '31157',
                        },
                        {
                            lable: 'Phường Phước Thới',
                            value: '31162',
                        },
                        {
                            lable: 'Phường Thới An',
                            value: '31159',
                        },
                        {
                            lable: 'Phường Thới Hòa',
                            value: '31154',
                        },
                        {
                            lable: 'Phường Thới Long',
                            value: '31156',
                        },
                        {
                            lable: 'Phường Trường Lạc',
                            value: '31165',
                        },
                    ],
                },
                {
                    lable: 'Quận Bình Thuỷ',
                    value: '918',
                    wards: [
                        {
                            lable: 'Phường An Thới',
                            value: '31177',
                        },
                        {
                            lable: 'Phường Bình Thủy',
                            value: '31168',
                        },
                        {
                            lable: 'Phường Bùi Hữu Nghĩa',
                            value: '31178',
                        },
                        {
                            lable: 'Phường Long Hòa',
                            value: '31180',
                        },
                        {
                            lable: 'Phường Long Tuyền',
                            value: '31183',
                        },
                        {
                            lable: 'Phường Thới An Đông',
                            value: '31174',
                        },
                        {
                            lable: 'Phường Trà An',
                            value: '31169',
                        },
                        {
                            lable: 'Phường Trà Nóc',
                            value: '31171',
                        },
                    ],
                },
                {
                    lable: 'Quận Cái Răng',
                    value: '919',
                    wards: [
                        {
                            lable: 'Phường Ba Láng',
                            value: '31195',
                        },
                        {
                            lable: 'Phường Hưng Phú',
                            value: '31189',
                        },
                        {
                            lable: 'Phường Hưng Thạnh',
                            value: '31192',
                        },
                        {
                            lable: 'Phường Lê Bình',
                            value: '31186',
                        },
                        {
                            lable: 'Phường Phú Thứ',
                            value: '31201',
                        },
                        {
                            lable: 'Phường Thường Thạnh',
                            value: '31198',
                        },
                        {
                            lable: 'Phường Tân Phú',
                            value: '31204',
                        },
                    ],
                },
                {
                    lable: 'Quận Thốt Nốt',
                    value: '923',
                    wards: [
                        {
                            lable: 'Phường Thuận An',
                            value: '31212',
                        },
                        {
                            lable: 'Phường Thuận Hưng',
                            value: '31228',
                        },
                        {
                            lable: 'Phường Thạnh Hoà',
                            value: '31217',
                        },
                        {
                            lable: 'Phường Thốt Nốt',
                            value: '31207',
                        },
                        {
                            lable: 'Phường Thới Thuận',
                            value: '31210',
                        },
                        {
                            lable: 'Phường Trung Kiên',
                            value: '31219',
                        },
                        {
                            lable: 'Phường Trung Nhứt',
                            value: '31216',
                        },
                        {
                            lable: 'Phường Tân Hưng',
                            value: '31227',
                        },
                        {
                            lable: 'Phường Tân Lộc',
                            value: '31213',
                        },
                    ],
                },
                {
                    lable: 'Huyện Vĩnh Thạnh',
                    value: '924',
                    wards: [
                        {
                            lable: 'Thị trấn Thanh An',
                            value: '31231',
                        },
                        {
                            lable: 'Thị trấn Vĩnh Thạnh',
                            value: '31232',
                        },
                        {
                            lable: 'Xã Thạnh An',
                            value: '31240',
                        },
                        {
                            lable: 'Xã Thạnh Lộc',
                            value: '31252',
                        },
                        {
                            lable: 'Xã Thạnh Lợi',
                            value: '31244',
                        },
                        {
                            lable: 'Xã Thạnh Mỹ',
                            value: '31234',
                        },
                        {
                            lable: 'Xã Thạnh Qưới',
                            value: '31246',
                        },
                        {
                            lable: 'Xã Thạnh Thắng',
                            value: '31243',
                        },
                        {
                            lable: 'Xã Thạnh Tiến',
                            value: '31241',
                        },
                        {
                            lable: 'Xã Vĩnh Bình',
                            value: '31211',
                        },
                        {
                            lable: 'Xã Vĩnh Trinh',
                            value: '31237',
                        },
                    ],
                },
                {
                    lable: 'Huyện Cờ Đỏ',
                    value: '925',
                    wards: [
                        {
                            lable: 'Thị trấn Cờ Đỏ',
                            value: '31261',
                        },
                        {
                            lable: 'Xã Thạnh Phú',
                            value: '31249',
                        },
                        {
                            lable: 'Xã Thới Hưng',
                            value: '31264',
                        },
                        {
                            lable: 'Xã Thới Xuân',
                            value: '31277',
                        },
                        {
                            lable: 'Xã Thới Đông',
                            value: '31276',
                        },
                        {
                            lable: 'Xã Trung An',
                            value: '31222',
                        },
                        {
                            lable: 'Xã Trung Hưng',
                            value: '31255',
                        },
                        {
                            lable: 'Xã Trung Thạnh',
                            value: '31225',
                        },
                        {
                            lable: 'Xã Đông Hiệp',
                            value: '31273',
                        },
                        {
                            lable: 'Xã Đông Thắng',
                            value: '31274',
                        },
                    ],
                },
                {
                    lable: 'Huyện Phong Điền',
                    value: '926',
                    wards: [
                        {
                            lable: 'Thị trấn Phong Điền',
                            value: '31299',
                        },
                        {
                            lable: 'Xã Giai Xuân',
                            value: '31303',
                        },
                        {
                            lable: 'Xã Mỹ Khánh',
                            value: '31312',
                        },
                        {
                            lable: 'Xã Nhơn Nghĩa',
                            value: '31315',
                        },
                        {
                            lable: 'Xã Nhơn Ái',
                            value: '31300',
                        },
                        {
                            lable: 'Xã Trường Long',
                            value: '31309',
                        },
                        {
                            lable: 'Xã Tân Thới',
                            value: '31306',
                        },
                    ],
                },
                {
                    lable: 'Huyện Thới Lai',
                    value: '927',
                    wards: [
                        {
                            lable: 'Thị trấn Thới Lai',
                            value: '31258',
                        },
                        {
                            lable: 'Xã Thới Thạnh',
                            value: '31267',
                        },
                        {
                            lable: 'Xã Thới Tân',
                            value: '31285',
                        },
                        {
                            lable: 'Xã Trường Thành',
                            value: '31291',
                        },
                        {
                            lable: 'Xã Trường Thắng',
                            value: '31286',
                        },
                        {
                            lable: 'Xã Trường Xuân',
                            value: '31294',
                        },
                        {
                            lable: 'Xã Trường Xuân A',
                            value: '31297',
                        },
                        {
                            lable: 'Xã Trường Xuân B',
                            value: '31298',
                        },
                        {
                            lable: 'Xã Tân Thạnh',
                            value: '31268',
                        },
                        {
                            lable: 'Xã Xuân Thắng',
                            value: '31270',
                        },
                        {
                            lable: 'Xã Đông Bình',
                            value: '31279',
                        },
                        {
                            lable: 'Xã Đông Thuận',
                            value: '31282',
                        },
                        {
                            lable: 'Xã Định Môn',
                            value: '31288',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Thành phố Đà Nẵng',
            value: '48',
            type: 'Thành phố Trung ương',
            districts: [
                {
                    lable: 'Quận Liên Chiểu',
                    value: '490',
                    wards: [
                        {
                            lable: 'Phường Hòa Hiệp Bắc',
                            value: '20194',
                        },
                        {
                            lable: 'Phường Hòa Hiệp Nam',
                            value: '20195',
                        },
                        {
                            lable: 'Phường Hòa Khánh Bắc',
                            value: '20197',
                        },
                        {
                            lable: 'Phường Hòa Khánh Nam',
                            value: '20198',
                        },
                        {
                            lable: 'Phường Hòa Minh',
                            value: '20200',
                        },
                    ],
                },
                {
                    lable: 'Quận Thanh Khê',
                    value: '491',
                    wards: [
                        {
                            lable: 'Phường An Khê',
                            value: '20224',
                        },
                        {
                            lable: 'Phường Chính Gián',
                            value: '20215',
                        },
                        {
                            lable: 'Phường Hòa Khê',
                            value: '20225',
                        },
                        {
                            lable: 'Phường Tam Thuận',
                            value: '20203',
                        },
                        {
                            lable: 'Phường Thanh Khê Tây',
                            value: '20206',
                        },
                        {
                            lable: 'Phường Thanh Khê Đông',
                            value: '20207',
                        },
                        {
                            lable: 'Phường Thạc Gián',
                            value: '20221',
                        },
                        {
                            lable: 'Phường Tân Chính',
                            value: '20212',
                        },
                        {
                            lable: 'Phường Vĩnh Trung',
                            value: '20218',
                        },
                        {
                            lable: 'Phường Xuân Hà',
                            value: '20209',
                        },
                    ],
                },
                {
                    lable: 'Quận Hải Châu',
                    value: '492',
                    wards: [
                        {
                            lable: 'Phường Bình Hiên',
                            value: '20251',
                        },
                        {
                            lable: 'Phường Bình Thuận',
                            value: '20254',
                        },
                        {
                            lable: 'Phường Hòa Cường Bắc',
                            value: '20257',
                        },
                        {
                            lable: 'Phường Hòa Cường Nam',
                            value: '20258',
                        },
                        {
                            lable: 'Phường Hòa Thuận Tây',
                            value: '20245',
                        },
                        {
                            lable: 'Phường Hòa Thuận Đông',
                            value: '20246',
                        },
                        {
                            lable: 'Phường Hải Châu  I',
                            value: '20236',
                        },
                        {
                            lable: 'Phường Hải Châu II',
                            value: '20239',
                        },
                        {
                            lable: 'Phường Nam Dương',
                            value: '20248',
                        },
                        {
                            lable: 'Phường Phước Ninh',
                            value: '20242',
                        },
                        {
                            lable: 'Phường Thanh Bình',
                            value: '20227',
                        },
                        {
                            lable: 'Phường Thuận Phước',
                            value: '20230',
                        },
                        {
                            lable: 'Phường Thạch Thang',
                            value: '20233',
                        },
                    ],
                },
                {
                    lable: 'Quận Sơn Trà',
                    value: '493',
                    wards: [
                        {
                            lable: 'Phường An Hải Bắc',
                            value: '20272',
                        },
                        {
                            lable: 'Phường An Hải Tây',
                            value: '20278',
                        },
                        {
                            lable: 'Phường An Hải Đông',
                            value: '20281',
                        },
                        {
                            lable: 'Phường Mân Thái',
                            value: '20269',
                        },
                        {
                            lable: 'Phường Nại Hiên Đông',
                            value: '20266',
                        },
                        {
                            lable: 'Phường Phước Mỹ',
                            value: '20275',
                        },
                        {
                            lable: 'Phường Thọ Quang',
                            value: '20263',
                        },
                    ],
                },
                {
                    lable: 'Quận Ngũ Hành Sơn',
                    value: '494',
                    wards: [
                        {
                            lable: 'Phường Hoà Hải',
                            value: '20290',
                        },
                        {
                            lable: 'Phường Hoà Quý',
                            value: '20287',
                        },
                        {
                            lable: 'Phường Khuê Mỹ',
                            value: '20285',
                        },
                        {
                            lable: 'Phường Mỹ An',
                            value: '20284',
                        },
                    ],
                },
                {
                    lable: 'Quận Cẩm Lệ',
                    value: '495',
                    wards: [
                        {
                            lable: 'Phường Hòa An',
                            value: '20306',
                        },
                        {
                            lable: 'Phường Hòa Phát',
                            value: '20305',
                        },
                        {
                            lable: 'Phường Hòa Thọ Tây',
                            value: '20311',
                        },
                        {
                            lable: 'Phường Hòa Thọ Đông',
                            value: '20312',
                        },
                        {
                            lable: 'Phường Hòa Xuân',
                            value: '20314',
                        },
                        {
                            lable: 'Phường Khuê Trung',
                            value: '20260',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hòa Vang',
                    value: '497',
                    wards: [
                        {
                            lable: 'Xã Hòa Bắc',
                            value: '20293',
                        },
                        {
                            lable: 'Xã Hòa Châu',
                            value: '20323',
                        },
                        {
                            lable: 'Xã Hòa Khương',
                            value: '20332',
                        },
                        {
                            lable: 'Xã Hòa Liên',
                            value: '20296',
                        },
                        {
                            lable: 'Xã Hòa Nhơn',
                            value: '20308',
                        },
                        {
                            lable: 'Xã Hòa Ninh',
                            value: '20299',
                        },
                        {
                            lable: 'Xã Hòa Phong',
                            value: '20320',
                        },
                        {
                            lable: 'Xã Hòa Phú',
                            value: '20317',
                        },
                        {
                            lable: 'Xã Hòa Phước',
                            value: '20329',
                        },
                        {
                            lable: 'Xã Hòa Sơn',
                            value: '20302',
                        },
                        {
                            lable: 'Xã Hòa Tiến',
                            value: '20326',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hoàng Sa',
                    value: '498',
                    wards: [],
                },
            ],
        },
        {
            lable: 'Thành phố Hải Phòng',
            value: '31',
            type: 'Thành phố Trung ương',
            districts: [
                {
                    lable: 'Quận Hồng Bàng',
                    value: '303',
                    wards: [
                        {
                            lable: 'Phường Hoàng Văn Thụ',
                            value: '11320',
                        },
                        {
                            lable: 'Phường Hùng Vương',
                            value: '11299',
                        },
                        {
                            lable: 'Phường Hạ Lý',
                            value: '11308',
                        },
                        {
                            lable: 'Phường Minh Khai',
                            value: '11311',
                        },
                        {
                            lable: 'Phường Phan Bội Châu',
                            value: '11323',
                        },
                        {
                            lable: 'Phường Quán Toan',
                            value: '11296',
                        },
                        {
                            lable: 'Phường Sở Dầu',
                            value: '11302',
                        },
                        {
                            lable: 'Phường Thượng Lý',
                            value: '11305',
                        },
                        {
                            lable: 'Phường Trại Chuối',
                            value: '11314',
                        },
                    ],
                },
                {
                    lable: 'Quận Ngô Quyền',
                    value: '304',
                    wards: [
                        {
                            lable: 'Phường Cầu Tre',
                            value: '11338',
                        },
                        {
                            lable: 'Phường Cầu Đất',
                            value: '11353',
                        },
                        {
                            lable: 'Phường Gia Viên',
                            value: '11347',
                        },
                        {
                            lable: 'Phường Lê Lợi',
                            value: '11356',
                        },
                        {
                            lable: 'Phường Lạc Viên',
                            value: '11341',
                        },
                        {
                            lable: 'Phường Lạch Tray',
                            value: '11362',
                        },
                        {
                            lable: 'Phường Máy Chai',
                            value: '11329',
                        },
                        {
                            lable: 'Phường Máy Tơ',
                            value: '11332',
                        },
                        {
                            lable: 'Phường Vạn Mỹ',
                            value: '11335',
                        },
                        {
                            lable: 'Phường Đông Khê',
                            value: '11350',
                        },
                        {
                            lable: 'Phường Đằng Giang',
                            value: '11359',
                        },
                        {
                            lable: 'Phường Đổng Quốc Bình',
                            value: '11365',
                        },
                    ],
                },
                {
                    lable: 'Quận Lê Chân',
                    value: '305',
                    wards: [
                        {
                            lable: 'Phường An Biên',
                            value: '11371',
                        },
                        {
                            lable: 'Phường An Dương',
                            value: '11377',
                        },
                        {
                            lable: 'Phường Cát Dài',
                            value: '11368',
                        },
                        {
                            lable: 'Phường Dư Hàng',
                            value: '11389',
                        },
                        {
                            lable: 'Phường Dư Hàng Kênh',
                            value: '11404',
                        },
                        {
                            lable: 'Phường Hàng Kênh',
                            value: '11392',
                        },
                        {
                            lable: 'Phường Hồ Nam',
                            value: '11383',
                        },
                        {
                            lable: 'Phường Kênh Dương',
                            value: '11405',
                        },
                        {
                            lable: 'Phường Lam Sơn',
                            value: '11374',
                        },
                        {
                            lable: 'Phường Nghĩa Xá',
                            value: '11401',
                        },
                        {
                            lable: 'Phường Niệm Nghĩa',
                            value: '11398',
                        },
                        {
                            lable: 'Phường Trại Cau',
                            value: '11386',
                        },
                        {
                            lable: 'Phường Trần Nguyên Hãn',
                            value: '11380',
                        },
                        {
                            lable: 'Phường Vĩnh Niệm',
                            value: '11407',
                        },
                        {
                            lable: 'Phường Đông Hải',
                            value: '11395',
                        },
                    ],
                },
                {
                    lable: 'Quận Hải An',
                    value: '306',
                    wards: [
                        {
                            lable: 'Phường Cát Bi',
                            value: '11422',
                        },
                        {
                            lable: 'Phường Nam Hải',
                            value: '11419',
                        },
                        {
                            lable: 'Phường Thành Tô',
                            value: '11414',
                        },
                        {
                            lable: 'Phường Tràng Cát',
                            value: '11425',
                        },
                        {
                            lable: 'Phường Đông Hải 1',
                            value: '11410',
                        },
                        {
                            lable: 'Phường Đông Hải 2',
                            value: '11411',
                        },
                        {
                            lable: 'Phường Đằng Hải',
                            value: '11416',
                        },
                        {
                            lable: 'Phường Đằng Lâm',
                            value: '11413',
                        },
                    ],
                },
                {
                    lable: 'Quận Kiến An',
                    value: '307',
                    wards: [
                        {
                            lable: 'Phường Bắc Sơn',
                            value: '11434',
                        },
                        {
                            lable: 'Phường Lãm Hà',
                            value: '11429',
                        },
                        {
                            lable: 'Phường Nam Sơn',
                            value: '11437',
                        },
                        {
                            lable: 'Phường Ngọc Sơn',
                            value: '11440',
                        },
                        {
                            lable: 'Phường Phù Liễn',
                            value: '11449',
                        },
                        {
                            lable: 'Phường Quán Trữ',
                            value: '11428',
                        },
                        {
                            lable: 'Phường Tràng Minh',
                            value: '11452',
                        },
                        {
                            lable: 'Phường Trần Thành Ngọ',
                            value: '11443',
                        },
                        {
                            lable: 'Phường Văn Đẩu',
                            value: '11446',
                        },
                        {
                            lable: 'Phường Đồng Hoà',
                            value: '11431',
                        },
                    ],
                },
                {
                    lable: 'Quận Đồ Sơn',
                    value: '308',
                    wards: [
                        {
                            lable: 'Phường Bàng La',
                            value: '11467',
                        },
                        {
                            lable: 'Phường Hải Sơn',
                            value: '11458',
                        },
                        {
                            lable: 'Phường Hợp Đức',
                            value: '11737',
                        },
                        {
                            lable: 'Phường Minh Đức',
                            value: '11465',
                        },
                        {
                            lable: 'Phường Ngọc Xuyên',
                            value: '11455',
                        },
                        {
                            lable: 'Phường Vạn Hương',
                            value: '11461',
                        },
                    ],
                },
                {
                    lable: 'Quận Dương Kinh',
                    value: '309',
                    wards: [
                        {
                            lable: 'Phường Anh Dũng',
                            value: '11689',
                        },
                        {
                            lable: 'Phường Hoà Nghĩa',
                            value: '11707',
                        },
                        {
                            lable: 'Phường Hưng Đạo',
                            value: '11686',
                        },
                        {
                            lable: 'Phường Hải Thành',
                            value: '11692',
                        },
                        {
                            lable: 'Phường Tân Thành',
                            value: '11740',
                        },
                        {
                            lable: 'Phường Đa Phúc',
                            value: '11683',
                        },
                    ],
                },
                {
                    lable: 'Huyện Thuỷ Nguyên',
                    value: '311',
                    wards: [
                        {
                            lable: 'Thị trấn Minh Đức',
                            value: '11473',
                        },
                        {
                            lable: 'Thị trấn Núi Đèo',
                            value: '11470',
                        },
                        {
                            lable: 'Xã An Lư',
                            value: '11533',
                        },
                        {
                            lable: 'Xã An Sơn',
                            value: '11479',
                        },
                        {
                            lable: 'Xã Cao Nhân',
                            value: '11518',
                        },
                        {
                            lable: 'Xã Chính Mỹ',
                            value: '11509',
                        },
                        {
                            lable: 'Xã Dương Quan',
                            value: '11578',
                        },
                        {
                            lable: 'Xã Gia Minh',
                            value: '11494',
                        },
                        {
                            lable: 'Xã Gia Đức',
                            value: '11497',
                        },
                        {
                            lable: 'Xã Hoa Động',
                            value: '11572',
                        },
                        {
                            lable: 'Xã Hoà Bình',
                            value: '11527',
                        },
                        {
                            lable: 'Xã Hoàng Động',
                            value: '11566',
                        },
                        {
                            lable: 'Xã Hợp Thành',
                            value: '11515',
                        },
                        {
                            lable: 'Xã Kiền Bái',
                            value: '11554',
                        },
                        {
                            lable: 'Xã Kênh Giang',
                            value: '11512',
                        },
                        {
                            lable: 'Xã Kỳ Sơn',
                            value: '11482',
                        },
                        {
                            lable: 'Xã Liên Khê',
                            value: '11485',
                        },
                        {
                            lable: 'Xã Lâm Động',
                            value: '11569',
                        },
                        {
                            lable: 'Xã Lưu Kiếm',
                            value: '11488',
                        },
                        {
                            lable: 'Xã Lưu Kỳ',
                            value: '11491',
                        },
                        {
                            lable: 'Xã Lại Xuân',
                            value: '11476',
                        },
                        {
                            lable: 'Xã Lập Lễ',
                            value: '11551',
                        },
                        {
                            lable: 'Xã Minh Tân',
                            value: '11500',
                        },
                        {
                            lable: 'Xã Mỹ Đồng',
                            value: '11521',
                        },
                        {
                            lable: 'Xã Ngũ Lão',
                            value: '11539',
                        },
                        {
                            lable: 'Xã Phù Ninh',
                            value: '11503',
                        },
                        {
                            lable: 'Xã Phả Lễ',
                            value: '11548',
                        },
                        {
                            lable: 'Xã Phục Lễ',
                            value: '11542',
                        },
                        {
                            lable: 'Xã Quảng Thanh',
                            value: '11506',
                        },
                        {
                            lable: 'Xã Tam Hưng',
                            value: '11545',
                        },
                        {
                            lable: 'Xã Thiên Hương',
                            value: '11557',
                        },
                        {
                            lable: 'Xã Thuỷ Sơn',
                            value: '11560',
                        },
                        {
                            lable: 'Xã Thuỷ Triều',
                            value: '11536',
                        },
                        {
                            lable: 'Xã Thuỷ Đường',
                            value: '11563',
                        },
                        {
                            lable: 'Xã Trung Hà',
                            value: '11530',
                        },
                        {
                            lable: 'Xã Tân Dương',
                            value: '11575',
                        },
                        {
                            lable: 'Xã Đông Sơn',
                            value: '11524',
                        },
                    ],
                },
                {
                    lable: 'Huyện An Dương',
                    value: '312',
                    wards: [
                        {
                            lable: 'Thị trấn An Dương',
                            value: '11581',
                        },
                        {
                            lable: 'Xã An Hoà',
                            value: '11590',
                        },
                        {
                            lable: 'Xã An Hưng',
                            value: '11599',
                        },
                        {
                            lable: 'Xã An Hồng',
                            value: '11602',
                        },
                        {
                            lable: 'Xã An Đồng',
                            value: '11623',
                        },
                        {
                            lable: 'Xã Bắc Sơn',
                            value: '11605',
                        },
                        {
                            lable: 'Xã Hồng Phong',
                            value: '11593',
                        },
                        {
                            lable: 'Xã Hồng Thái',
                            value: '11626',
                        },
                        {
                            lable: 'Xã Lê Lợi',
                            value: '11611',
                        },
                        {
                            lable: 'Xã Lê Thiện',
                            value: '11584',
                        },
                        {
                            lable: 'Xã Nam Sơn',
                            value: '11608',
                        },
                        {
                            lable: 'Xã Quốc Tuấn',
                            value: '11620',
                        },
                        {
                            lable: 'Xã Tân Tiến',
                            value: '11596',
                        },
                        {
                            lable: 'Xã Đại Bản',
                            value: '11587',
                        },
                        {
                            lable: 'Xã Đặng Cương',
                            value: '11614',
                        },
                        {
                            lable: 'Xã Đồng Thái',
                            value: '11617',
                        },
                    ],
                },
                {
                    lable: 'Huyện An Lão',
                    value: '313',
                    wards: [
                        {
                            lable: 'Thị trấn An Lão',
                            value: '11629',
                        },
                        {
                            lable: 'Thị trấn Trường Sơn',
                            value: '11656',
                        },
                        {
                            lable: 'Xã An Thái',
                            value: '11677',
                        },
                        {
                            lable: 'Xã An Thắng',
                            value: '11653',
                        },
                        {
                            lable: 'Xã An Thọ',
                            value: '11674',
                        },
                        {
                            lable: 'Xã An Tiến',
                            value: '11641',
                        },
                        {
                            lable: 'Xã Bát Trang',
                            value: '11632',
                        },
                        {
                            lable: 'Xã Chiến Thắng',
                            value: '11671',
                        },
                        {
                            lable: 'Xã Mỹ Đức',
                            value: '11668',
                        },
                        {
                            lable: 'Xã Quang Hưng',
                            value: '11644',
                        },
                        {
                            lable: 'Xã Quang Trung',
                            value: '11647',
                        },
                        {
                            lable: 'Xã Quốc Tuấn',
                            value: '11650',
                        },
                        {
                            lable: 'Xã Thái Sơn',
                            value: '11662',
                        },
                        {
                            lable: 'Xã Trường Thành',
                            value: '11638',
                        },
                        {
                            lable: 'Xã Trường Thọ',
                            value: '11635',
                        },
                        {
                            lable: 'Xã Tân Dân',
                            value: '11659',
                        },
                        {
                            lable: 'Xã Tân Viên',
                            value: '11665',
                        },
                    ],
                },
                {
                    lable: 'Huyện Kiến Thuỵ',
                    value: '314',
                    wards: [
                        {
                            lable: 'Thị trấn Núi Đối',
                            value: '11680',
                        },
                        {
                            lable: 'Xã Du Lễ',
                            value: '11716',
                        },
                        {
                            lable: 'Xã Hữu Bằng',
                            value: '11701',
                        },
                        {
                            lable: 'Xã Kiến Quốc',
                            value: '11713',
                        },
                        {
                            lable: 'Xã Minh Tân',
                            value: '11725',
                        },
                        {
                            lable: 'Xã Ngũ Phúc',
                            value: '11710',
                        },
                        {
                            lable: 'Xã Ngũ Đoan',
                            value: '11731',
                        },
                        {
                            lable: 'Xã Thanh Sơn',
                            value: '11722',
                        },
                        {
                            lable: 'Xã Thuận Thiên',
                            value: '11698',
                        },
                        {
                            lable: 'Xã Thuỵ Hương',
                            value: '11719',
                        },
                        {
                            lable: 'Xã Tân Phong',
                            value: '11734',
                        },
                        {
                            lable: 'Xã Tân Trào',
                            value: '11743',
                        },
                        {
                            lable: 'Xã Tú Sơn',
                            value: '11749',
                        },
                        {
                            lable: 'Xã Đoàn Xá',
                            value: '11746',
                        },
                        {
                            lable: 'Xã Đông Phương',
                            value: '11695',
                        },
                        {
                            lable: 'Xã Đại Hà',
                            value: '11728',
                        },
                        {
                            lable: 'Xã Đại Hợp',
                            value: '11752',
                        },
                        {
                            lable: 'Xã Đại Đồng',
                            value: '11704',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tiên Lãng',
                    value: '315',
                    wards: [
                        {
                            lable: 'Thị trấn Tiên Lãng',
                            value: '11755',
                        },
                        {
                            lable: 'Xã Bạch Đằng',
                            value: '11788',
                        },
                        {
                            lable: 'Xã Bắc Hưng',
                            value: '11803',
                        },
                        {
                            lable: 'Xã Cấp Tiến',
                            value: '11779',
                        },
                        {
                            lable: 'Xã Hùng Thắng',
                            value: '11809',
                        },
                        {
                            lable: 'Xã Khởi Nghĩa',
                            value: '11773',
                        },
                        {
                            lable: 'Xã Kiến Thiết',
                            value: '11782',
                        },
                        {
                            lable: 'Xã Nam Hưng',
                            value: '11806',
                        },
                        {
                            lable: 'Xã Quang Phục',
                            value: '11791',
                        },
                        {
                            lable: 'Xã Quyết Tiến',
                            value: '11770',
                        },
                        {
                            lable: 'Xã Tiên Cường',
                            value: '11761',
                        },
                        {
                            lable: 'Xã Tiên Minh',
                            value: '11800',
                        },
                        {
                            lable: 'Xã Tiên Thanh',
                            value: '11776',
                        },
                        {
                            lable: 'Xã Tiên Thắng',
                            value: '11797',
                        },
                        {
                            lable: 'Xã Toàn Thắng',
                            value: '11794',
                        },
                        {
                            lable: 'Xã Tây Hưng',
                            value: '11812',
                        },
                        {
                            lable: 'Xã Tự Cường',
                            value: '11764',
                        },
                        {
                            lable: 'Xã Vinh Quang',
                            value: '11821',
                        },
                        {
                            lable: 'Xã Đoàn Lập',
                            value: '11785',
                        },
                        {
                            lable: 'Xã Đông Hưng',
                            value: '11815',
                        },
                        {
                            lable: 'Xã Đại Thắng',
                            value: '11758',
                        },
                    ],
                },
                {
                    lable: 'Huyện Vĩnh Bảo',
                    value: '316',
                    wards: [
                        {
                            lable: 'Thị trấn Vĩnh Bảo',
                            value: '11824',
                        },
                        {
                            lable: 'Xã An Hoà',
                            value: '11854',
                        },
                        {
                            lable: 'Xã Cao Minh',
                            value: '11902',
                        },
                        {
                            lable: 'Xã Cổ Am',
                            value: '11905',
                        },
                        {
                            lable: 'Xã Cộng Hiền',
                            value: '11899',
                        },
                        {
                            lable: 'Xã Dũng Tiến',
                            value: '11827',
                        },
                        {
                            lable: 'Xã Giang Biên',
                            value: '11830',
                        },
                        {
                            lable: 'Xã Hiệp Hoà',
                            value: '11848',
                        },
                        {
                            lable: 'Xã Hoà Bình',
                            value: '11890',
                        },
                        {
                            lable: 'Xã Hùng Tiến',
                            value: '11851',
                        },
                        {
                            lable: 'Xã Hưng Nhân',
                            value: '11869',
                        },
                        {
                            lable: 'Xã Liên Am',
                            value: '11881',
                        },
                        {
                            lable: 'Xã Lý Học',
                            value: '11884',
                        },
                        {
                            lable: 'Xã Nhân Hoà',
                            value: '11863',
                        },
                        {
                            lable: 'Xã Tam Cường',
                            value: '11887',
                        },
                        {
                            lable: 'Xã Tam Đa',
                            value: '11866',
                        },
                        {
                            lable: 'Xã Thanh Lương',
                            value: '11878',
                        },
                        {
                            lable: 'Xã Thắng Thuỷ',
                            value: '11833',
                        },
                        {
                            lable: 'Xã Tiền Phong',
                            value: '11893',
                        },
                        {
                            lable: 'Xã Trung Lập',
                            value: '11836',
                        },
                        {
                            lable: 'Xã Trấn Dương',
                            value: '11911',
                        },
                        {
                            lable: 'Xã Tân Hưng',
                            value: '11857',
                        },
                        {
                            lable: 'Xã Tân Liên',
                            value: '11860',
                        },
                        {
                            lable: 'Xã Vinh Quang',
                            value: '11872',
                        },
                        {
                            lable: 'Xã Việt Tiến',
                            value: '11839',
                        },
                        {
                            lable: 'Xã Vĩnh An',
                            value: '11842',
                        },
                        {
                            lable: 'Xã Vĩnh Long',
                            value: '11845',
                        },
                        {
                            lable: 'Xã Vĩnh Phong',
                            value: '11896',
                        },
                        {
                            lable: 'Xã Vĩnh Tiến',
                            value: '11908',
                        },
                        {
                            lable: 'Xã Đồng Minh',
                            value: '11875',
                        },
                    ],
                },
                {
                    lable: 'Huyện Cát Hải',
                    value: '317',
                    wards: [
                        {
                            lable: 'Thị trấn Cát Bà',
                            value: '11914',
                        },
                        {
                            lable: 'Thị trấn Cát Hải',
                            value: '11917',
                        },
                        {
                            lable: 'Xã Gia Luận',
                            value: '11935',
                        },
                        {
                            lable: 'Xã Hiền Hào',
                            value: '11938',
                        },
                        {
                            lable: 'Xã Hoàng Châu',
                            value: '11926',
                        },
                        {
                            lable: 'Xã Nghĩa Lộ',
                            value: '11920',
                        },
                        {
                            lable: 'Xã Phù Long',
                            value: '11932',
                        },
                        {
                            lable: 'Xã Trân Châu',
                            value: '11941',
                        },
                        {
                            lable: 'Xã Việt Hải',
                            value: '11944',
                        },
                        {
                            lable: 'Xã Văn Phong',
                            value: '11929',
                        },
                        {
                            lable: 'Xã Xuân Đám',
                            value: '11947',
                        },
                        {
                            lable: 'Xã Đồng Bài',
                            value: '11923',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bạch Long Vĩ',
                    value: '318',
                    wards: [],
                },
            ],
        },
        {
            lable: 'Tỉnh An Giang',
            value: '89',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Long Xuyên',
                    value: '883',
                    wards: [
                        {
                            lable: 'Phường Bình Khánh',
                            value: '30292',
                        },
                        {
                            lable: 'Phường Bình Đức',
                            value: '30289',
                        },
                        {
                            lable: 'Phường Mỹ Bình',
                            value: '30280',
                        },
                        {
                            lable: 'Phường Mỹ Hòa',
                            value: '30307',
                        },
                        {
                            lable: 'Phường Mỹ Long',
                            value: '30283',
                        },
                        {
                            lable: 'Phường Mỹ Phước',
                            value: '30295',
                        },
                        {
                            lable: 'Phường Mỹ Quý',
                            value: '30298',
                        },
                        {
                            lable: 'Phường Mỹ Thạnh',
                            value: '30304',
                        },
                        {
                            lable: 'Phường Mỹ Thới',
                            value: '30301',
                        },
                        {
                            lable: 'Phường Mỹ Xuyên',
                            value: '30286',
                        },
                        {
                            lable: 'Phường Đông Xuyên',
                            value: '30285',
                        },
                        {
                            lable: 'Xã Mỹ Hoà Hưng',
                            value: '30313',
                        },
                        {
                            lable: 'Xã Mỹ Khánh',
                            value: '30310',
                        },
                    ],
                },
                {
                    lable: 'Thành phố Châu Đốc',
                    value: '884',
                    wards: [
                        {
                            lable: 'Phường Châu Phú A',
                            value: '30319',
                        },
                        {
                            lable: 'Phường Châu Phú B',
                            value: '30316',
                        },
                        {
                            lable: 'Phường Núi Sam',
                            value: '30325',
                        },
                        {
                            lable: 'Phường Vĩnh Mỹ',
                            value: '30322',
                        },
                        {
                            lable: 'Phường Vĩnh Ngươn',
                            value: '30328',
                        },
                        {
                            lable: 'Xã Vĩnh Châu',
                            value: '30334',
                        },
                        {
                            lable: 'Xã Vĩnh Tế',
                            value: '30331',
                        },
                    ],
                },
                {
                    lable: 'Huyện An Phú',
                    value: '886',
                    wards: [
                        {
                            lable: 'Thị Trấn Long Bình',
                            value: '30341',
                        },
                        {
                            lable: 'Thị trấn An Phú',
                            value: '30337',
                        },
                        {
                            lable: 'Xã Khánh An',
                            value: '30340',
                        },
                        {
                            lable: 'Xã Khánh Bình',
                            value: '30343',
                        },
                        {
                            lable: 'Xã Nhơn Hội',
                            value: '30349',
                        },
                        {
                            lable: 'Xã Phú Hội',
                            value: '30355',
                        },
                        {
                            lable: 'Xã Phú Hữu',
                            value: '30352',
                        },
                        {
                            lable: 'Xã Phước Hưng',
                            value: '30358',
                        },
                        {
                            lable: 'Xã Quốc Thái',
                            value: '30346',
                        },
                        {
                            lable: 'Xã Vĩnh Hậu',
                            value: '30364',
                        },
                        {
                            lable: 'Xã Vĩnh Hội Đông',
                            value: '30370',
                        },
                        {
                            lable: 'Xã Vĩnh Lộc',
                            value: '30361',
                        },
                        {
                            lable: 'Xã Vĩnh Trường',
                            value: '30367',
                        },
                        {
                            lable: 'Xã Đa Phước',
                            value: '30373',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Tân Châu',
                    value: '887',
                    wards: [
                        {
                            lable: 'Phường Long Châu',
                            value: '30378',
                        },
                        {
                            lable: 'Phường Long Hưng',
                            value: '30377',
                        },
                        {
                            lable: 'Phường Long Phú',
                            value: '30394',
                        },
                        {
                            lable: 'Phường Long Sơn',
                            value: '30412',
                        },
                        {
                            lable: 'Phường Long Thạnh',
                            value: '30376',
                        },
                        {
                            lable: 'Xã Châu Phong',
                            value: '30397',
                        },
                        {
                            lable: 'Xã Long An',
                            value: '30391',
                        },
                        {
                            lable: 'Xã Lê Chánh',
                            value: '30403',
                        },
                        {
                            lable: 'Xã Phú Lộc',
                            value: '30379',
                        },
                        {
                            lable: 'Xã Phú Vĩnh',
                            value: '30400',
                        },
                        {
                            lable: 'Xã Tân An',
                            value: '30388',
                        },
                        {
                            lable: 'Xã Tân Thạnh',
                            value: '30387',
                        },
                        {
                            lable: 'Xã Vĩnh Hòa',
                            value: '30385',
                        },
                        {
                            lable: 'Xã Vĩnh Xương',
                            value: '30382',
                        },
                    ],
                },
                {
                    lable: 'Huyện Phú Tân',
                    value: '888',
                    wards: [
                        {
                            lable: 'Thị trấn Chợ Vàm',
                            value: '30409',
                        },
                        {
                            lable: 'Thị trấn Phú Mỹ',
                            value: '30406',
                        },
                        {
                            lable: 'Xã Bình Thạnh Đông',
                            value: '30454',
                        },
                        {
                            lable: 'Xã Hiệp Xương',
                            value: '30442',
                        },
                        {
                            lable: 'Xã Hoà Lạc',
                            value: '30430',
                        },
                        {
                            lable: 'Xã Long Hoà',
                            value: '30415',
                        },
                        {
                            lable: 'Xã Phú An',
                            value: '30436',
                        },
                        {
                            lable: 'Xã Phú Bình',
                            value: '30445',
                        },
                        {
                            lable: 'Xã Phú Hiệp',
                            value: '30424',
                        },
                        {
                            lable: 'Xã Phú Hưng',
                            value: '30451',
                        },
                        {
                            lable: 'Xã Phú Long',
                            value: '30418',
                        },
                        {
                            lable: 'Xã Phú Lâm',
                            value: '30421',
                        },
                        {
                            lable: 'Xã Phú Thành',
                            value: '30433',
                        },
                        {
                            lable: 'Xã Phú Thạnh',
                            value: '30427',
                        },
                        {
                            lable: 'Xã Phú Thọ',
                            value: '30448',
                        },
                        {
                            lable: 'Xã Phú Xuân',
                            value: '30439',
                        },
                        {
                            lable: 'Xã Tân Hòa',
                            value: '30457',
                        },
                        {
                            lable: 'Xã Tân Trung',
                            value: '30460',
                        },
                    ],
                },
                {
                    lable: 'Huyện Châu Phú',
                    value: '889',
                    wards: [
                        {
                            lable: 'Thị trấn Cái Dầu',
                            value: '30463',
                        },
                        {
                            lable: 'Thị trấn Vĩnh Thạnh Trung',
                            value: '30478',
                        },
                        {
                            lable: 'Xã Bình Chánh',
                            value: '30499',
                        },
                        {
                            lable: 'Xã Bình Long',
                            value: '30484',
                        },
                        {
                            lable: 'Xã Bình Mỹ',
                            value: '30487',
                        },
                        {
                            lable: 'Xã Bình Phú',
                            value: '30496',
                        },
                        {
                            lable: 'Xã Bình Thủy',
                            value: '30490',
                        },
                        {
                            lable: 'Xã Khánh Hòa',
                            value: '30466',
                        },
                        {
                            lable: 'Xã Mỹ Phú',
                            value: '30472',
                        },
                        {
                            lable: 'Xã Mỹ Đức',
                            value: '30469',
                        },
                        {
                            lable: 'Xã Thạnh Mỹ Tây',
                            value: '30481',
                        },
                        {
                            lable: 'Xã Ô Long Vỹ',
                            value: '30475',
                        },
                        {
                            lable: 'Xã Đào Hữu Cảnh',
                            value: '30493',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tịnh Biên',
                    value: '890',
                    wards: [
                        {
                            lable: 'Thị trấn Chi Lăng',
                            value: '30505',
                        },
                        {
                            lable: 'Thị trấn Nhà Bàng',
                            value: '30502',
                        },
                        {
                            lable: 'Thị trấn Tịnh Biên',
                            value: '30520',
                        },
                        {
                            lable: 'Xã An Cư',
                            value: '30526',
                        },
                        {
                            lable: 'Xã An Hảo',
                            value: '30538',
                        },
                        {
                            lable: 'Xã An Nông',
                            value: '30529',
                        },
                        {
                            lable: 'Xã An Phú',
                            value: '30514',
                        },
                        {
                            lable: 'Xã Nhơn Hưng',
                            value: '30511',
                        },
                        {
                            lable: 'Xã Núi Voi',
                            value: '30508',
                        },
                        {
                            lable: 'Xã Thới Sơn',
                            value: '30517',
                        },
                        {
                            lable: 'Xã Tân Lập',
                            value: '30541',
                        },
                        {
                            lable: 'Xã Tân Lợi',
                            value: '30535',
                        },
                        {
                            lable: 'Xã Văn Giáo',
                            value: '30523',
                        },
                        {
                            lable: 'Xã Vĩnh Trung',
                            value: '30532',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tri Tôn',
                    value: '891',
                    wards: [
                        {
                            lable: 'Thị trấn Ba Chúc',
                            value: '30547',
                        },
                        {
                            lable: 'Thị trấn Cô Tô',
                            value: '30580',
                        },
                        {
                            lable: 'Thị trấn Tri Tôn',
                            value: '30544',
                        },
                        {
                            lable: 'Xã An Tức',
                            value: '30577',
                        },
                        {
                            lable: 'Xã Châu Lăng',
                            value: '30562',
                        },
                        {
                            lable: 'Xã Lê Trì',
                            value: '30553',
                        },
                        {
                            lable: 'Xã Lương An Trà',
                            value: '30568',
                        },
                        {
                            lable: 'Xã Lương Phi',
                            value: '30565',
                        },
                        {
                            lable: 'Xã Lạc Quới',
                            value: '30550',
                        },
                        {
                            lable: 'Xã Núi Tô',
                            value: '30574',
                        },
                        {
                            lable: 'Xã Tà Đảnh',
                            value: '30571',
                        },
                        {
                            lable: 'Xã Tân Tuyến',
                            value: '30583',
                        },
                        {
                            lable: 'Xã Vĩnh Gia',
                            value: '30556',
                        },
                        {
                            lable: 'Xã Vĩnh Phước',
                            value: '30559',
                        },
                        {
                            lable: 'Xã Ô Lâm',
                            value: '30586',
                        },
                    ],
                },
                {
                    lable: 'Huyện Châu Thành',
                    value: '892',
                    wards: [
                        {
                            lable: 'Thị trấn An Châu',
                            value: '30589',
                        },
                        {
                            lable: 'Thị trấn Vĩnh Bình',
                            value: '30604',
                        },
                        {
                            lable: 'Xã An Hòa',
                            value: '30592',
                        },
                        {
                            lable: 'Xã Bình Hòa',
                            value: '30607',
                        },
                        {
                            lable: 'Xã Bình Thạnh',
                            value: '30601',
                        },
                        {
                            lable: 'Xã Cần Đăng',
                            value: '30595',
                        },
                        {
                            lable: 'Xã Hòa Bình Thạnh',
                            value: '30613',
                        },
                        {
                            lable: 'Xã Tân Phú',
                            value: '30622',
                        },
                        {
                            lable: 'Xã Vĩnh An',
                            value: '30610',
                        },
                        {
                            lable: 'Xã Vĩnh Hanh',
                            value: '30598',
                        },
                        {
                            lable: 'Xã Vĩnh Lợi',
                            value: '30616',
                        },
                        {
                            lable: 'Xã Vĩnh Nhuận',
                            value: '30619',
                        },
                        {
                            lable: 'Xã Vĩnh Thành',
                            value: '30625',
                        },
                    ],
                },
                {
                    lable: 'Huyện Chợ Mới',
                    value: '893',
                    wards: [
                        {
                            lable: 'Thị trấn Chợ Mới',
                            value: '30628',
                        },
                        {
                            lable: 'Thị trấn Mỹ Luông',
                            value: '30631',
                        },
                        {
                            lable: 'Xã An Thạnh Trung',
                            value: '30670',
                        },
                        {
                            lable: 'Xã Bình Phước Xuân',
                            value: '30667',
                        },
                        {
                            lable: 'Xã Hòa An',
                            value: '30679',
                        },
                        {
                            lable: 'Xã Hòa Bình',
                            value: '30676',
                        },
                        {
                            lable: 'Xã Hội An',
                            value: '30673',
                        },
                        {
                            lable: 'Xã Kiến An',
                            value: '30634',
                        },
                        {
                            lable: 'Xã Kiến Thành',
                            value: '30649',
                        },
                        {
                            lable: 'Xã Long Giang',
                            value: '30661',
                        },
                        {
                            lable: 'Xã Long Kiến',
                            value: '30664',
                        },
                        {
                            lable: 'Xã Long Điền A',
                            value: '30640',
                        },
                        {
                            lable: 'Xã Long Điền B',
                            value: '30646',
                        },
                        {
                            lable: 'Xã Mỹ An',
                            value: '30655',
                        },
                        {
                            lable: 'Xã Mỹ Hiệp',
                            value: '30652',
                        },
                        {
                            lable: 'Xã Mỹ Hội Đông',
                            value: '30637',
                        },
                        {
                            lable: 'Xã Nhơn Mỹ',
                            value: '30658',
                        },
                        {
                            lable: 'Xã Tấn Mỹ',
                            value: '30643',
                        },
                    ],
                },
                {
                    lable: 'Huyện Thoại Sơn',
                    value: '894',
                    wards: [
                        {
                            lable: 'Thị Trấn Óc Eo',
                            value: '30688',
                        },
                        {
                            lable: 'Thị trấn Núi Sập',
                            value: '30682',
                        },
                        {
                            lable: 'Thị trấn Phú Hoà',
                            value: '30685',
                        },
                        {
                            lable: 'Xã An Bình',
                            value: '30692',
                        },
                        {
                            lable: 'Xã Bình Thành',
                            value: '30724',
                        },
                        {
                            lable: 'Xã Mỹ Phú Đông',
                            value: '30712',
                        },
                        {
                            lable: 'Xã Phú Thuận',
                            value: '30700',
                        },
                        {
                            lable: 'Xã Thoại Giang',
                            value: '30721',
                        },
                        {
                            lable: 'Xã Tây Phú',
                            value: '30691',
                        },
                        {
                            lable: 'Xã Vĩnh Chánh',
                            value: '30703',
                        },
                        {
                            lable: 'Xã Vĩnh Khánh',
                            value: '30718',
                        },
                        {
                            lable: 'Xã Vĩnh Phú',
                            value: '30694',
                        },
                        {
                            lable: 'Xã Vĩnh Trạch',
                            value: '30697',
                        },
                        {
                            lable: 'Xã Vọng Thê',
                            value: '30727',
                        },
                        {
                            lable: 'Xã Vọng Đông',
                            value: '30715',
                        },
                        {
                            lable: 'Xã Định Mỹ',
                            value: '30706',
                        },
                        {
                            lable: 'Xã Định Thành',
                            value: '30709',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Bà Rịa - Vũng Tàu',
            value: '77',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Vũng Tàu',
                    value: '747',
                    wards: [
                        {
                            lable: 'Phường 1',
                            value: '26506',
                        },
                        {
                            lable: 'Phường 10',
                            value: '26536',
                        },
                        {
                            lable: 'Phường 11',
                            value: '26539',
                        },
                        {
                            lable: 'Phường 12',
                            value: '26542',
                        },
                        {
                            lable: 'Phường 2',
                            value: '26509',
                        },
                        {
                            lable: 'Phường 3',
                            value: '26512',
                        },
                        {
                            lable: 'Phường 4',
                            value: '26515',
                        },
                        {
                            lable: 'Phường 5',
                            value: '26518',
                        },
                        {
                            lable: 'Phường 7',
                            value: '26524',
                        },
                        {
                            lable: 'Phường 8',
                            value: '26527',
                        },
                        {
                            lable: 'Phường 9',
                            value: '26530',
                        },
                        {
                            lable: 'Phường Nguyễn An Ninh',
                            value: '26526',
                        },
                        {
                            lable: 'Phường Rạch Dừa',
                            value: '26535',
                        },
                        {
                            lable: 'Phường Thắng Nhì',
                            value: '26521',
                        },
                        {
                            lable: 'Phường Thắng Nhất',
                            value: '26533',
                        },
                        {
                            lable: 'Phường Thắng Tam',
                            value: '26508',
                        },
                        {
                            lable: 'Xã Long Sơn',
                            value: '26545',
                        },
                    ],
                },
                {
                    lable: 'Thành phố Bà Rịa',
                    value: '748',
                    wards: [
                        {
                            lable: 'Phường Kim Dinh',
                            value: '26566',
                        },
                        {
                            lable: 'Phường Long Hương',
                            value: '26563',
                        },
                        {
                            lable: 'Phường Long Toàn',
                            value: '26557',
                        },
                        {
                            lable: 'Phường Long Tâm',
                            value: '26558',
                        },
                        {
                            lable: 'Phường Phước Hiệp',
                            value: '26551',
                        },
                        {
                            lable: 'Phường Phước Hưng',
                            value: '26548',
                        },
                        {
                            lable: 'Phường Phước Nguyên',
                            value: '26554',
                        },
                        {
                            lable: 'Phường Phước Trung',
                            value: '26560',
                        },
                        {
                            lable: 'Xã Hoà Long',
                            value: '26572',
                        },
                        {
                            lable: 'Xã Long Phước',
                            value: '26569',
                        },
                        {
                            lable: 'Xã Tân Hưng',
                            value: '26567',
                        },
                    ],
                },
                {
                    lable: 'Huyện Châu Đức',
                    value: '750',
                    wards: [
                        {
                            lable: 'Thị trấn Ngãi Giao',
                            value: '26575',
                        },
                        {
                            lable: 'Xã Bàu Chinh',
                            value: '26574',
                        },
                        {
                            lable: 'Xã Bình Ba',
                            value: '26578',
                        },
                        {
                            lable: 'Xã Bình Giã',
                            value: '26590',
                        },
                        {
                            lable: 'Xã Bình Trung',
                            value: '26593',
                        },
                        {
                            lable: 'Xã Cù Bị',
                            value: '26599',
                        },
                        {
                            lable: 'Xã Kim Long',
                            value: '26608',
                        },
                        {
                            lable: 'Xã Láng Lớn',
                            value: '26602',
                        },
                        {
                            lable: 'Xã Nghĩa Thành',
                            value: '26617',
                        },
                        {
                            lable: 'Xã Quảng Thành',
                            value: '26605',
                        },
                        {
                            lable: 'Xã Suối Nghệ',
                            value: '26581',
                        },
                        {
                            lable: 'Xã Suối Rao',
                            value: '26611',
                        },
                        {
                            lable: 'Xã Sơn Bình',
                            value: '26587',
                        },
                        {
                            lable: 'Xã Xuân Sơn',
                            value: '26584',
                        },
                        {
                            lable: 'Xã Xà Bang',
                            value: '26596',
                        },
                        {
                            lable: 'Xã Đá Bạc',
                            value: '26614',
                        },
                    ],
                },
                {
                    lable: 'Huyện Xuyên Mộc',
                    value: '751',
                    wards: [
                        {
                            lable: 'Thị trấn Phước Bửu',
                            value: '26620',
                        },
                        {
                            lable: 'Xã Bàu Lâm',
                            value: '26638',
                        },
                        {
                            lable: 'Xã Bình Châu',
                            value: '26656',
                        },
                        {
                            lable: 'Xã Bông Trang',
                            value: '26632',
                        },
                        {
                            lable: 'Xã Bưng Riềng',
                            value: '26653',
                        },
                        {
                            lable: 'Xã Hòa Bình',
                            value: '26641',
                        },
                        {
                            lable: 'Xã Hòa Hiệp',
                            value: '26647',
                        },
                        {
                            lable: 'Xã Hòa Hưng',
                            value: '26644',
                        },
                        {
                            lable: 'Xã Hòa Hội',
                            value: '26650',
                        },
                        {
                            lable: 'Xã Phước Thuận',
                            value: '26623',
                        },
                        {
                            lable: 'Xã Phước Tân',
                            value: '26626',
                        },
                        {
                            lable: 'Xã Tân Lâm',
                            value: '26635',
                        },
                        {
                            lable: 'Xã Xuyên Mộc',
                            value: '26629',
                        },
                    ],
                },
                {
                    lable: 'Huyện Long Điền',
                    value: '752',
                    wards: [
                        {
                            lable: 'Thị trấn Long Hải',
                            value: '26662',
                        },
                        {
                            lable: 'Thị trấn Long Điền',
                            value: '26659',
                        },
                        {
                            lable: 'Xã An Ngãi',
                            value: '26665',
                        },
                        {
                            lable: 'Xã An Nhứt',
                            value: '26671',
                        },
                        {
                            lable: 'Xã Phước Hưng',
                            value: '26677',
                        },
                        {
                            lable: 'Xã Phước Tỉnh',
                            value: '26674',
                        },
                        {
                            lable: 'Xã Tam Phước',
                            value: '26668',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đất Đỏ',
                    value: '753',
                    wards: [
                        {
                            lable: 'Thị trấn Phước Hải',
                            value: '26692',
                        },
                        {
                            lable: 'Thị trấn Đất Đỏ',
                            value: '26680',
                        },
                        {
                            lable: 'Xã Long Mỹ',
                            value: '26689',
                        },
                        {
                            lable: 'Xã Long Tân',
                            value: '26695',
                        },
                        {
                            lable: 'Xã Láng Dài',
                            value: '26698',
                        },
                        {
                            lable: 'Xã Lộc An',
                            value: '26701',
                        },
                        {
                            lable: 'Xã Phước Hội',
                            value: '26686',
                        },
                        {
                            lable: 'Xã Phước Long Thọ',
                            value: '26683',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Phú Mỹ',
                    value: '754',
                    wards: [
                        {
                            lable: 'Phường Hắc Dịch',
                            value: '26725',
                        },
                        {
                            lable: 'Phường Mỹ Xuân',
                            value: '26719',
                        },
                        {
                            lable: 'Phường Phú Mỹ',
                            value: '26704',
                        },
                        {
                            lable: 'Phường Phước Hoà',
                            value: '26713',
                        },
                        {
                            lable: 'Phường Tân Phước',
                            value: '26716',
                        },
                        {
                            lable: 'Xã Châu Pha',
                            value: '26728',
                        },
                        {
                            lable: 'Xã Sông Xoài',
                            value: '26722',
                        },
                        {
                            lable: 'Xã Tân Hoà',
                            value: '26707',
                        },
                        {
                            lable: 'Xã Tân Hải',
                            value: '26710',
                        },
                        {
                            lable: 'Xã Tóc Tiên',
                            value: '26731',
                        },
                    ],
                },
                {
                    lable: 'Huyện Côn Đảo',
                    value: '755',
                    wards: [],
                },
            ],
        },
        {
            lable: 'Tỉnh Bắc Giang',
            value: '24',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Bắc Giang',
                    value: '213',
                    wards: [
                        {
                            lable: 'Phường Dĩnh Kế',
                            value: '07231',
                        },
                        {
                            lable: 'Phường Hoàng Văn Thụ',
                            value: '07210',
                        },
                        {
                            lable: 'Phường Lê Lợi',
                            value: '07219',
                        },
                        {
                            lable: 'Phường Mỹ Độ',
                            value: '07216',
                        },
                        {
                            lable: 'Phường Ngô Quyền',
                            value: '07207',
                        },
                        {
                            lable: 'Phường Thọ Xương',
                            value: '07201',
                        },
                        {
                            lable: 'Phường Trần Nguyên Hãn',
                            value: '07204',
                        },
                        {
                            lable: 'Phường Trần Phú',
                            value: '07213',
                        },
                        {
                            lable: 'Phường Xương Giang',
                            value: '07225',
                        },
                        {
                            lable: 'Phường Đa Mai',
                            value: '07228',
                        },
                        {
                            lable: 'Xã Dĩnh Trì',
                            value: '07441',
                        },
                        {
                            lable: 'Xã Song Khê',
                            value: '07705',
                        },
                        {
                            lable: 'Xã Song Mai',
                            value: '07222',
                        },
                        {
                            lable: 'Xã Tân Mỹ',
                            value: '07687',
                        },
                        {
                            lable: 'Xã Tân Tiến',
                            value: '07699',
                        },
                        {
                            lable: 'Xã Đồng Sơn',
                            value: '07696',
                        },
                    ],
                },
                {
                    lable: 'Huyện Yên Thế',
                    value: '215',
                    wards: [
                        {
                            lable: 'Thị trấn Bố Hạ',
                            value: '07294',
                        },
                        {
                            lable: 'Thị trấn Phồn Xương',
                            value: '07288',
                        },
                        {
                            lable: 'Xã An Thượng',
                            value: '07285',
                        },
                        {
                            lable: 'Xã Canh Nậu',
                            value: '07246',
                        },
                        {
                            lable: 'Xã Hương Vĩ',
                            value: '07279',
                        },
                        {
                            lable: 'Xã Hồng Kỳ',
                            value: '07267',
                        },
                        {
                            lable: 'Xã Tam Hiệp',
                            value: '07261',
                        },
                        {
                            lable: 'Xã Tam Tiến',
                            value: '07252',
                        },
                        {
                            lable: 'Xã Tiến Thắng',
                            value: '07264',
                        },
                        {
                            lable: 'Xã Tân Hiệp',
                            value: '07276',
                        },
                        {
                            lable: 'Xã Tân Sỏi',
                            value: '07291',
                        },
                        {
                            lable: 'Xã Xuân Lương',
                            value: '07249',
                        },
                        {
                            lable: 'Xã Đông Sơn',
                            value: '07273',
                        },
                        {
                            lable: 'Xã Đồng Hưu',
                            value: '07258',
                        },
                        {
                            lable: 'Xã Đồng Kỳ',
                            value: '07282',
                        },
                        {
                            lable: 'Xã Đồng Lạc',
                            value: '07270',
                        },
                        {
                            lable: 'Xã Đồng Tiến',
                            value: '07243',
                        },
                        {
                            lable: 'Xã Đồng Tâm',
                            value: '07260',
                        },
                        {
                            lable: 'Xã Đồng Vương',
                            value: '07255',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tân Yên',
                    value: '216',
                    wards: [
                        {
                            lable: 'Thị trấn Cao Thượng',
                            value: '07339',
                        },
                        {
                            lable: 'Thị trấn Nhã Nam',
                            value: '07306',
                        },
                        {
                            lable: 'Xã An Dương',
                            value: '07321',
                        },
                        {
                            lable: 'Xã Cao Xá',
                            value: '07336',
                        },
                        {
                            lable: 'Xã Hợp Đức',
                            value: '07330',
                        },
                        {
                            lable: 'Xã Lam Cốt',
                            value: '07333',
                        },
                        {
                            lable: 'Xã Lan Giới',
                            value: '07303',
                        },
                        {
                            lable: 'Xã Liên Chung',
                            value: '07357',
                        },
                        {
                            lable: 'Xã Liên Sơn',
                            value: '07327',
                        },
                        {
                            lable: 'Xã Ngọc Châu',
                            value: '07348',
                        },
                        {
                            lable: 'Xã Ngọc Lý',
                            value: '07363',
                        },
                        {
                            lable: 'Xã Ngọc Thiện',
                            value: '07360',
                        },
                        {
                            lable: 'Xã Ngọc Vân',
                            value: '07351',
                        },
                        {
                            lable: 'Xã Phúc Hòa',
                            value: '07324',
                        },
                        {
                            lable: 'Xã Phúc Sơn',
                            value: '07318',
                        },
                        {
                            lable: 'Xã Quang Tiến',
                            value: '07315',
                        },
                        {
                            lable: 'Xã Quế Nham',
                            value: '07366',
                        },
                        {
                            lable: 'Xã Song Vân',
                            value: '07345',
                        },
                        {
                            lable: 'Xã Tân Trung',
                            value: '07309',
                        },
                        {
                            lable: 'Xã Việt Lập',
                            value: '07354',
                        },
                        {
                            lable: 'Xã Việt Ngọc',
                            value: '07342',
                        },
                        {
                            lable: 'Xã Đại Hóa',
                            value: '07312',
                        },
                    ],
                },
                {
                    lable: 'Huyện Lạng Giang',
                    value: '217',
                    wards: [
                        {
                            lable: 'Thị trấn Kép',
                            value: '07399',
                        },
                        {
                            lable: 'Thị trấn Vôi',
                            value: '07375',
                        },
                        {
                            lable: 'Xã An Hà',
                            value: '07396',
                        },
                        {
                            lable: 'Xã Dương Đức',
                            value: '07408',
                        },
                        {
                            lable: 'Xã Hương Lạc',
                            value: '07405',
                        },
                        {
                            lable: 'Xã Hương Sơn',
                            value: '07387',
                        },
                        {
                            lable: 'Xã Mỹ Hà',
                            value: '07402',
                        },
                        {
                            lable: 'Xã Mỹ Thái',
                            value: '07420',
                        },
                        {
                            lable: 'Xã Nghĩa Hòa',
                            value: '07378',
                        },
                        {
                            lable: 'Xã Nghĩa Hưng',
                            value: '07381',
                        },
                        {
                            lable: 'Xã Quang Thịnh',
                            value: '07384',
                        },
                        {
                            lable: 'Xã Thái Đào',
                            value: '07438',
                        },
                        {
                            lable: 'Xã Tiên Lục',
                            value: '07393',
                        },
                        {
                            lable: 'Xã Tân Dĩnh',
                            value: '07432',
                        },
                        {
                            lable: 'Xã Tân Hưng',
                            value: '07417',
                        },
                        {
                            lable: 'Xã Tân Thanh',
                            value: '07411',
                        },
                        {
                            lable: 'Xã Xuân Hương',
                            value: '07429',
                        },
                        {
                            lable: 'Xã Xương Lâm',
                            value: '07426',
                        },
                        {
                            lable: 'Xã Yên Mỹ',
                            value: '07414',
                        },
                        {
                            lable: 'Xã Đào Mỹ',
                            value: '07390',
                        },
                        {
                            lable: 'Xã Đại Lâm',
                            value: '07435',
                        },
                    ],
                },
                {
                    lable: 'Huyện Lục Nam',
                    value: '218',
                    wards: [
                        {
                            lable: 'Thị trấn Đồi Ngô',
                            value: '07444',
                        },
                        {
                            lable: 'Xã Bình Sơn',
                            value: '07492',
                        },
                        {
                            lable: 'Xã Bảo Sơn',
                            value: '07459',
                        },
                        {
                            lable: 'Xã Bảo Đài',
                            value: '07462',
                        },
                        {
                            lable: 'Xã Bắc Lũng',
                            value: '07513',
                        },
                        {
                            lable: 'Xã Chu Điện',
                            value: '07480',
                        },
                        {
                            lable: 'Xã Cương Sơn',
                            value: '07483',
                        },
                        {
                            lable: 'Xã Cẩm Lý',
                            value: '07519',
                        },
                        {
                            lable: 'Xã Huyền Sơn',
                            value: '07504',
                        },
                        {
                            lable: 'Xã Khám Lạng',
                            value: '07501',
                        },
                        {
                            lable: 'Xã Lan Mẫu',
                            value: '07495',
                        },
                        {
                            lable: 'Xã Lục Sơn',
                            value: '07510',
                        },
                        {
                            lable: 'Xã Nghĩa Phương',
                            value: '07486',
                        },
                        {
                            lable: 'Xã Phương Sơn',
                            value: '07477',
                        },
                        {
                            lable: 'Xã Tam Dị',
                            value: '07456',
                        },
                        {
                            lable: 'Xã Thanh Lâm',
                            value: '07465',
                        },
                        {
                            lable: 'Xã Tiên Nha',
                            value: '07468',
                        },
                        {
                            lable: 'Xã Trường Giang',
                            value: '07471',
                        },
                        {
                            lable: 'Xã Trường Sơn',
                            value: '07507',
                        },
                        {
                            lable: 'Xã Vô Tranh',
                            value: '07489',
                        },
                        {
                            lable: 'Xã Vũ Xá',
                            value: '07516',
                        },
                        {
                            lable: 'Xã Yên Sơn',
                            value: '07498',
                        },
                        {
                            lable: 'Xã Đan Hội',
                            value: '07522',
                        },
                        {
                            lable: 'Xã Đông Hưng',
                            value: '07450',
                        },
                        {
                            lable: 'Xã Đông Phú',
                            value: '07453',
                        },
                    ],
                },
                {
                    lable: 'Huyện Lục Ngạn',
                    value: '219',
                    wards: [
                        {
                            lable: 'Thị trấn Chũ',
                            value: '07525',
                        },
                        {
                            lable: 'Xã Biên Sơn',
                            value: '07555',
                        },
                        {
                            lable: 'Xã Biển Động',
                            value: '07573',
                        },
                        {
                            lable: 'Xã Cấm Sơn',
                            value: '07528',
                        },
                        {
                            lable: 'Xã Giáp Sơn',
                            value: '07570',
                        },
                        {
                            lable: 'Xã Hồng Giang',
                            value: '07561',
                        },
                        {
                            lable: 'Xã Hộ Đáp',
                            value: '07543',
                        },
                        {
                            lable: 'Xã Kim Sơn',
                            value: '07564',
                        },
                        {
                            lable: 'Xã Kiên Lao',
                            value: '07552',
                        },
                        {
                            lable: 'Xã Kiên Thành',
                            value: '07558',
                        },
                        {
                            lable: 'Xã Mỹ An',
                            value: '07600',
                        },
                        {
                            lable: 'Xã Nam Dương',
                            value: '07603',
                        },
                        {
                            lable: 'Xã Phong Minh',
                            value: '07534',
                        },
                        {
                            lable: 'Xã Phong Vân',
                            value: '07537',
                        },
                        {
                            lable: 'Xã Phì Điền',
                            value: '07582',
                        },
                        {
                            lable: 'Xã Phú Nhuận',
                            value: '07597',
                        },
                        {
                            lable: 'Xã Phượng Sơn',
                            value: '07612',
                        },
                        {
                            lable: 'Xã Quý Sơn',
                            value: '07576',
                        },
                        {
                            lable: 'Xã Sơn Hải',
                            value: '07546',
                        },
                        {
                            lable: 'Xã Thanh Hải',
                            value: '07549',
                        },
                        {
                            lable: 'Xã Trù Hựu',
                            value: '07579',
                        },
                        {
                            lable: 'Xã Tân Hoa',
                            value: '07567',
                        },
                        {
                            lable: 'Xã Tân Lập',
                            value: '07594',
                        },
                        {
                            lable: 'Xã Tân Mộc',
                            value: '07606',
                        },
                        {
                            lable: 'Xã Tân Quang',
                            value: '07588',
                        },
                        {
                            lable: 'Xã Tân Sơn',
                            value: '07531',
                        },
                        {
                            lable: 'Xã Xa Lý',
                            value: '07540',
                        },
                        {
                            lable: 'Xã Đèo Gia',
                            value: '07609',
                        },
                        {
                            lable: 'Xã Đồng Cốc',
                            value: '07591',
                        },
                    ],
                },
                {
                    lable: 'Huyện Sơn Động',
                    value: '220',
                    wards: [
                        {
                            lable: 'Thị trấn An Châu',
                            value: '07615',
                        },
                        {
                            lable: 'Thị trấn Tây Yên Tử',
                            value: '07616',
                        },
                        {
                            lable: 'Xã An Bá',
                            value: '07660',
                        },
                        {
                            lable: 'Xã An Lạc',
                            value: '07645',
                        },
                        {
                            lable: 'Xã Cẩm Đàn',
                            value: '07642',
                        },
                        {
                            lable: 'Xã Dương Hưu',
                            value: '07666',
                        },
                        {
                            lable: 'Xã Giáo Liêm',
                            value: '07636',
                        },
                        {
                            lable: 'Xã Hữu Sản',
                            value: '07624',
                        },
                        {
                            lable: 'Xã Long Sơn',
                            value: '07672',
                        },
                        {
                            lable: 'Xã Lệ Viễn',
                            value: '07654',
                        },
                        {
                            lable: 'Xã Phúc Sơn',
                            value: '07630',
                        },
                        {
                            lable: 'Xã Thanh Luận',
                            value: '07678',
                        },
                        {
                            lable: 'Xã Tuấn Đạo',
                            value: '07663',
                        },
                        {
                            lable: 'Xã Vân Sơn',
                            value: '07621',
                        },
                        {
                            lable: 'Xã Vĩnh An',
                            value: '07648',
                        },
                        {
                            lable: 'Xã Yên Định',
                            value: '07651',
                        },
                        {
                            lable: 'Xã Đại Sơn',
                            value: '07627',
                        },
                    ],
                },
                {
                    lable: 'Huyện Yên Dũng',
                    value: '221',
                    wards: [
                        {
                            lable: 'Thị trấn Nham Biền',
                            value: '07681',
                        },
                        {
                            lable: 'Thị trấn Tân An',
                            value: '07682',
                        },
                        {
                            lable: 'Xã Cảnh Thụy',
                            value: '07738',
                        },
                        {
                            lable: 'Xã Hương Gián',
                            value: '07690',
                        },
                        {
                            lable: 'Xã Lãng Sơn',
                            value: '07723',
                        },
                        {
                            lable: 'Xã Lão Hộ',
                            value: '07684',
                        },
                        {
                            lable: 'Xã Nội Hoàng',
                            value: '07708',
                        },
                        {
                            lable: 'Xã Quỳnh Sơn',
                            value: '07702',
                        },
                        {
                            lable: 'Xã Tiến Dũng',
                            value: '07729',
                        },
                        {
                            lable: 'Xã Tiền Phong',
                            value: '07711',
                        },
                        {
                            lable: 'Xã Trí Yên',
                            value: '07720',
                        },
                        {
                            lable: 'Xã Tân Liễu',
                            value: '07717',
                        },
                        {
                            lable: 'Xã Tư Mại',
                            value: '07741',
                        },
                        {
                            lable: 'Xã Xuân Phú',
                            value: '07714',
                        },
                        {
                            lable: 'Xã Yên Lư',
                            value: '07726',
                        },
                        {
                            lable: 'Xã Đồng Phúc',
                            value: '07750',
                        },
                        {
                            lable: 'Xã Đồng Việt',
                            value: '07747',
                        },
                        {
                            lable: 'Xã Đức Giang',
                            value: '07735',
                        },
                    ],
                },
                {
                    lable: 'Huyện Việt Yên',
                    value: '222',
                    wards: [
                        {
                            lable: 'Thị trấn Bích Động',
                            value: '07777',
                        },
                        {
                            lable: 'Thị trấn Nếnh',
                            value: '07795',
                        },
                        {
                            lable: 'Xã Hương Mai',
                            value: '07771',
                        },
                        {
                            lable: 'Xã Hồng Thái',
                            value: '07783',
                        },
                        {
                            lable: 'Xã Minh Đức',
                            value: '07768',
                        },
                        {
                            lable: 'Xã Nghĩa Trung',
                            value: '07765',
                        },
                        {
                            lable: 'Xã Ninh Sơn',
                            value: '07798',
                        },
                        {
                            lable: 'Xã Quang Châu',
                            value: '07807',
                        },
                        {
                            lable: 'Xã Quảng Minh',
                            value: '07792',
                        },
                        {
                            lable: 'Xã Thượng Lan',
                            value: '07759',
                        },
                        {
                            lable: 'Xã Tiên Sơn',
                            value: '07786',
                        },
                        {
                            lable: 'Xã Trung Sơn',
                            value: '07780',
                        },
                        {
                            lable: 'Xã Tăng Tiến',
                            value: '07789',
                        },
                        {
                            lable: 'Xã Tự Lạn',
                            value: '07774',
                        },
                        {
                            lable: 'Xã Việt Tiến',
                            value: '07762',
                        },
                        {
                            lable: 'Xã Vân Hà',
                            value: '07804',
                        },
                        {
                            lable: 'Xã Vân Trung',
                            value: '07801',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hiệp Hòa',
                    value: '223',
                    wards: [
                        {
                            lable: 'Thị trấn Thắng',
                            value: '07840',
                        },
                        {
                            lable: 'Xã Bắc Lý',
                            value: '07870',
                        },
                        {
                            lable: 'Xã Châu Minh',
                            value: '07882',
                        },
                        {
                            lable: 'Xã Danh Thắng',
                            value: '07861',
                        },
                        {
                            lable: 'Xã Hoàng An',
                            value: '07828',
                        },
                        {
                            lable: 'Xã Hoàng Lương',
                            value: '07819',
                        },
                        {
                            lable: 'Xã Hoàng Thanh',
                            value: '07825',
                        },
                        {
                            lable: 'Xã Hoàng Vân',
                            value: '07822',
                        },
                        {
                            lable: 'Xã Hòa Sơn',
                            value: '07837',
                        },
                        {
                            lable: 'Xã Hùng Sơn',
                            value: '07849',
                        },
                        {
                            lable: 'Xã Hương Lâm',
                            value: '07876',
                        },
                        {
                            lable: 'Xã Hợp Thịnh',
                            value: '07858',
                        },
                        {
                            lable: 'Xã Lương Phong',
                            value: '07846',
                        },
                        {
                            lable: 'Xã Mai Trung',
                            value: '07864',
                        },
                        {
                            lable: 'Xã Mai Đình',
                            value: '07885',
                        },
                        {
                            lable: 'Xã Ngọc Sơn',
                            value: '07831',
                        },
                        {
                            lable: 'Xã Quang Minh',
                            value: '07843',
                        },
                        {
                            lable: 'Xã Thanh Vân',
                            value: '07816',
                        },
                        {
                            lable: 'Xã Thái Sơn',
                            value: '07834',
                        },
                        {
                            lable: 'Xã Thường Thắng',
                            value: '07855',
                        },
                        {
                            lable: 'Xã Xuân Cẩm',
                            value: '07873',
                        },
                        {
                            lable: 'Xã Đoan Bái',
                            value: '07867',
                        },
                        {
                            lable: 'Xã Đông Lỗ',
                            value: '07879',
                        },
                        {
                            lable: 'Xã Đại Thành',
                            value: '07852',
                        },
                        {
                            lable: 'Xã Đồng Tân',
                            value: '07813',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Bắc Kạn',
            value: '06',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành Phố Bắc Kạn',
                    value: '058',
                    wards: [
                        {
                            lable: 'Phường Huyền Tụng',
                            value: '01846',
                        },
                        {
                            lable: 'Phường Nguyễn Thị Minh Khai',
                            value: '01834',
                        },
                        {
                            lable: 'Phường Phùng Chí Kiên',
                            value: '01843',
                        },
                        {
                            lable: 'Phường Sông Cầu',
                            value: '01837',
                        },
                        {
                            lable: 'Phường Xuất Hóa',
                            value: '01855',
                        },
                        {
                            lable: 'Phường Đức Xuân',
                            value: '01840',
                        },
                        {
                            lable: 'Xã Dương Quang',
                            value: '01849',
                        },
                        {
                            lable: 'Xã Nông Thượng',
                            value: '01852',
                        },
                    ],
                },
                {
                    lable: 'Huyện Pác Nặm',
                    value: '060',
                    wards: [
                        {
                            lable: 'Xã An Thắng',
                            value: '01876',
                        },
                        {
                            lable: 'Xã Bằng Thành',
                            value: '01858',
                        },
                        {
                            lable: 'Xã Bộc Bố',
                            value: '01864',
                        },
                        {
                            lable: 'Xã Cao Tân',
                            value: '01885',
                        },
                        {
                            lable: 'Xã Công Bằng',
                            value: '01867',
                        },
                        {
                            lable: 'Xã Cổ Linh',
                            value: '01879',
                        },
                        {
                            lable: 'Xã Giáo Hiệu',
                            value: '01870',
                        },
                        {
                            lable: 'Xã Nghiên Loan',
                            value: '01882',
                        },
                        {
                            lable: 'Xã Nhạn Môn',
                            value: '01861',
                        },
                        {
                            lable: 'Xã Xuân La',
                            value: '01873',
                        },
                    ],
                },
                {
                    lable: 'Huyện Ba Bể',
                    value: '061',
                    wards: [
                        {
                            lable: 'Thị trấn Chợ Rã',
                            value: '01888',
                        },
                        {
                            lable: 'Xã Bành Trạch',
                            value: '01891',
                        },
                        {
                            lable: 'Xã Cao Thượng',
                            value: '01900',
                        },
                        {
                            lable: 'Xã Chu Hương',
                            value: '01921',
                        },
                        {
                            lable: 'Xã Hoàng Trĩ',
                            value: '01930',
                        },
                        {
                            lable: 'Xã Hà Hiệu',
                            value: '01897',
                        },
                        {
                            lable: 'Xã Khang Ninh',
                            value: '01906',
                        },
                        {
                            lable: 'Xã Mỹ Phương',
                            value: '01927',
                        },
                        {
                            lable: 'Xã Nam Mẫu',
                            value: '01909',
                        },
                        {
                            lable: 'Xã Phúc Lộc',
                            value: '01894',
                        },
                        {
                            lable: 'Xã Quảng Khê',
                            value: '01924',
                        },
                        {
                            lable: 'Xã Thượng Giáo',
                            value: '01912',
                        },
                        {
                            lable: 'Xã Yến Dương',
                            value: '01918',
                        },
                        {
                            lable: 'Xã Địa Linh',
                            value: '01915',
                        },
                        {
                            lable: 'Xã Đồng Phúc',
                            value: '01933',
                        },
                    ],
                },
                {
                    lable: 'Huyện Ngân Sơn',
                    value: '062',
                    wards: [
                        {
                            lable: 'Thị trấn Nà Phặc',
                            value: '01936',
                        },
                        {
                            lable: 'Xã Bằng Vân',
                            value: '01942',
                        },
                        {
                            lable: 'Xã Cốc Đán',
                            value: '01945',
                        },
                        {
                            lable: 'Xã Hiệp Lực',
                            value: '01960',
                        },
                        {
                            lable: 'Xã Thuần Mang',
                            value: '01963',
                        },
                        {
                            lable: 'Xã Thượng Quan',
                            value: '01957',
                        },
                        {
                            lable: 'Xã Thượng Ân',
                            value: '01939',
                        },
                        {
                            lable: 'Xã Trung Hoà',
                            value: '01948',
                        },
                        {
                            lable: 'Xã Vân Tùng',
                            value: '01954',
                        },
                        {
                            lable: 'Xã Đức Vân',
                            value: '01951',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bạch Thông',
                    value: '063',
                    wards: [
                        {
                            lable: 'Thị trấn Phủ Thông',
                            value: '01969',
                        },
                        {
                            lable: 'Xã Cao Sơn',
                            value: '02002',
                        },
                        {
                            lable: 'Xã Cẩm Giàng',
                            value: '02008',
                        },
                        {
                            lable: 'Xã Dương Phong',
                            value: '02014',
                        },
                        {
                            lable: 'Xã Lục Bình',
                            value: '01990',
                        },
                        {
                            lable: 'Xã Mỹ Thanh',
                            value: '02011',
                        },
                        {
                            lable: 'Xã Nguyên Phúc',
                            value: '01999',
                        },
                        {
                            lable: 'Xã Quang Thuận',
                            value: '02017',
                        },
                        {
                            lable: 'Xã Quân Hà',
                            value: '02005',
                        },
                        {
                            lable: 'Xã Sĩ Bình',
                            value: '01978',
                        },
                        {
                            lable: 'Xã Tân Tú',
                            value: '01993',
                        },
                        {
                            lable: 'Xã Vi Hương',
                            value: '01975',
                        },
                        {
                            lable: 'Xã Vũ Muộn',
                            value: '01981',
                        },
                        {
                            lable: 'Xã Đôn Phong',
                            value: '01984',
                        },
                    ],
                },
                {
                    lable: 'Huyện Chợ Đồn',
                    value: '064',
                    wards: [
                        {
                            lable: 'Thị trấn Bằng Lũng',
                            value: '02020',
                        },
                        {
                            lable: 'Xã Bình Trung',
                            value: '02080',
                        },
                        {
                            lable: 'Xã Bản Thi',
                            value: '02035',
                        },
                        {
                            lable: 'Xã Bằng Lãng',
                            value: '02065',
                        },
                        {
                            lable: 'Xã Bằng Phúc',
                            value: '02041',
                        },
                        {
                            lable: 'Xã Lương Bằng',
                            value: '02062',
                        },
                        {
                            lable: 'Xã Nam Cường',
                            value: '02026',
                        },
                        {
                            lable: 'Xã Nghĩa Tá',
                            value: '02071',
                        },
                        {
                            lable: 'Xã Ngọc Phái',
                            value: '02053',
                        },
                        {
                            lable: 'Xã Phương Viên',
                            value: '02050',
                        },
                        {
                            lable: 'Xã Quảng Bạch',
                            value: '02038',
                        },
                        {
                            lable: 'Xã Tân Lập',
                            value: '02032',
                        },
                        {
                            lable: 'Xã Xuân Lạc',
                            value: '02023',
                        },
                        {
                            lable: 'Xã Yên Mỹ',
                            value: '02077',
                        },
                        {
                            lable: 'Xã Yên Phong',
                            value: '02083',
                        },
                        {
                            lable: 'Xã Yên Thượng',
                            value: '02047',
                        },
                        {
                            lable: 'Xã Yên Thịnh',
                            value: '02044',
                        },
                        {
                            lable: 'Xã Đại Sảo',
                            value: '02068',
                        },
                        {
                            lable: 'Xã Đồng Lạc',
                            value: '02029',
                        },
                        {
                            lable: 'Xã Đồng Thắng',
                            value: '02059',
                        },
                    ],
                },
                {
                    lable: 'Huyện Chợ Mới',
                    value: '065',
                    wards: [
                        {
                            lable: 'Thị trấn Đồng Tâm',
                            value: '02086',
                        },
                        {
                            lable: 'Xã Bình Văn',
                            value: '02125',
                        },
                        {
                            lable: 'Xã Cao Kỳ',
                            value: '02104',
                        },
                        {
                            lable: 'Xã Hoà Mục',
                            value: '02098',
                        },
                        {
                            lable: 'Xã Mai Lạp',
                            value: '02095',
                        },
                        {
                            lable: 'Xã Như Cố',
                            value: '02122',
                        },
                        {
                            lable: 'Xã Nông Hạ',
                            value: '02107',
                        },
                        {
                            lable: 'Xã Quảng Chu',
                            value: '02131',
                        },
                        {
                            lable: 'Xã Thanh Mai',
                            value: '02101',
                        },
                        {
                            lable: 'Xã Thanh Thịnh',
                            value: '02113',
                        },
                        {
                            lable: 'Xã Thanh Vận',
                            value: '02092',
                        },
                        {
                            lable: 'Xã Tân Sơn',
                            value: '02089',
                        },
                        {
                            lable: 'Xã Yên Cư',
                            value: '02110',
                        },
                        {
                            lable: 'Xã Yên Hân',
                            value: '02116',
                        },
                    ],
                },
                {
                    lable: 'Huyện Na Rì',
                    value: '066',
                    wards: [
                        {
                            lable: 'Thị trấn Yến Lạc',
                            value: '02155',
                        },
                        {
                            lable: 'Xã Côn Minh',
                            value: '02173',
                        },
                        {
                            lable: 'Xã Cư Lễ',
                            value: '02176',
                        },
                        {
                            lable: 'Xã Cường Lợi',
                            value: '02152',
                        },
                        {
                            lable: 'Xã Dương Sơn',
                            value: '02188',
                        },
                        {
                            lable: 'Xã Kim Hỷ',
                            value: '02146',
                        },
                        {
                            lable: 'Xã Kim Lư',
                            value: '02158',
                        },
                        {
                            lable: 'Xã Liêm Thuỷ',
                            value: '02197',
                        },
                        {
                            lable: 'Xã Lương Thượng',
                            value: '02143',
                        },
                        {
                            lable: 'Xã Quang Phong',
                            value: '02185',
                        },
                        {
                            lable: 'Xã Sơn Thành',
                            value: '02161',
                        },
                        {
                            lable: 'Xã Trần Phú',
                            value: '02179',
                        },
                        {
                            lable: 'Xã Văn Lang',
                            value: '02140',
                        },
                        {
                            lable: 'Xã Văn Minh',
                            value: '02170',
                        },
                        {
                            lable: 'Xã Văn Vũ',
                            value: '02137',
                        },
                        {
                            lable: 'Xã Xuân Dương',
                            value: '02191',
                        },
                        {
                            lable: 'Xã Đổng Xá',
                            value: '02194',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Bạc Liêu',
            value: '95',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Bạc Liêu',
                    value: '954',
                    wards: [
                        {
                            lable: 'Phường 1',
                            value: '31825',
                        },
                        {
                            lable: 'Phường 2',
                            value: '31813',
                        },
                        {
                            lable: 'Phường 3',
                            value: '31816',
                        },
                        {
                            lable: 'Phường 5',
                            value: '31819',
                        },
                        {
                            lable: 'Phường 7',
                            value: '31822',
                        },
                        {
                            lable: 'Phường 8',
                            value: '31828',
                        },
                        {
                            lable: 'Phường Nhà Mát',
                            value: '31831',
                        },
                        {
                            lable: 'Xã Hiệp Thành',
                            value: '31840',
                        },
                        {
                            lable: 'Xã Vĩnh Trạch',
                            value: '31834',
                        },
                        {
                            lable: 'Xã Vĩnh Trạch Đông',
                            value: '31837',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hồng Dân',
                    value: '956',
                    wards: [
                        {
                            lable: 'Thị trấn Ngan Dừa',
                            value: '31843',
                        },
                        {
                            lable: 'Xã Lộc Ninh',
                            value: '31855',
                        },
                        {
                            lable: 'Xã Ninh Hòa',
                            value: '31852',
                        },
                        {
                            lable: 'Xã Ninh Quới',
                            value: '31846',
                        },
                        {
                            lable: 'Xã Ninh Quới A',
                            value: '31849',
                        },
                        {
                            lable: 'Xã Ninh Thạnh Lợi',
                            value: '31864',
                        },
                        {
                            lable: 'Xã Ninh Thạnh Lợi A',
                            value: '31863',
                        },
                        {
                            lable: 'Xã Vĩnh Lộc',
                            value: '31858',
                        },
                        {
                            lable: 'Xã Vĩnh Lộc A',
                            value: '31861',
                        },
                    ],
                },
                {
                    lable: 'Huyện Phước Long',
                    value: '957',
                    wards: [
                        {
                            lable: 'Thị trấn Phước Long',
                            value: '31867',
                        },
                        {
                            lable: 'Xã Hưng Phú',
                            value: '31879',
                        },
                        {
                            lable: 'Xã Phong Thạnh Tây A',
                            value: '31885',
                        },
                        {
                            lable: 'Xã Phong Thạnh Tây B',
                            value: '31888',
                        },
                        {
                            lable: 'Xã Phước Long',
                            value: '31876',
                        },
                        {
                            lable: 'Xã Vĩnh Phú Tây',
                            value: '31873',
                        },
                        {
                            lable: 'Xã Vĩnh Phú Đông',
                            value: '31870',
                        },
                        {
                            lable: 'Xã Vĩnh Thanh',
                            value: '31882',
                        },
                    ],
                },
                {
                    lable: 'Huyện Vĩnh Lợi',
                    value: '958',
                    wards: [
                        {
                            lable: 'Thị trấn Châu Hưng',
                            value: '31900',
                        },
                        {
                            lable: 'Xã Châu Hưng A',
                            value: '31903',
                        },
                        {
                            lable: 'Xã Châu Thới',
                            value: '31912',
                        },
                        {
                            lable: 'Xã Hưng Hội',
                            value: '31909',
                        },
                        {
                            lable: 'Xã Hưng Thành',
                            value: '31906',
                        },
                        {
                            lable: 'Xã Long Thạnh',
                            value: '31921',
                        },
                        {
                            lable: 'Xã Vĩnh Hưng',
                            value: '31894',
                        },
                        {
                            lable: 'Xã Vĩnh Hưng A',
                            value: '31897',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Giá Rai',
                    value: '959',
                    wards: [
                        {
                            lable: 'Phường 1',
                            value: '31942',
                        },
                        {
                            lable: 'Phường Hộ Phòng',
                            value: '31945',
                        },
                        {
                            lable: 'Phường Láng Tròn',
                            value: '31951',
                        },
                        {
                            lable: 'Xã Phong Thạnh',
                            value: '31960',
                        },
                        {
                            lable: 'Xã Phong Thạnh A',
                            value: '31963',
                        },
                        {
                            lable: 'Xã Phong Thạnh Tây',
                            value: '31966',
                        },
                        {
                            lable: 'Xã Phong Thạnh Đông',
                            value: '31948',
                        },
                        {
                            lable: 'Xã Phong Tân',
                            value: '31954',
                        },
                        {
                            lable: 'Xã Tân Phong',
                            value: '31957',
                        },
                        {
                            lable: 'Xã Tân Thạnh',
                            value: '31969',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đông Hải',
                    value: '960',
                    wards: [
                        {
                            lable: 'Thị trấn Gành Hào',
                            value: '31972',
                        },
                        {
                            lable: 'Xã An Phúc',
                            value: '31990',
                        },
                        {
                            lable: 'Xã An Trạch',
                            value: '31987',
                        },
                        {
                            lable: 'Xã An Trạch A',
                            value: '31988',
                        },
                        {
                            lable: 'Xã Long Điền',
                            value: '31981',
                        },
                        {
                            lable: 'Xã Long Điền Tây',
                            value: '31984',
                        },
                        {
                            lable: 'Xã Long Điền Đông',
                            value: '31975',
                        },
                        {
                            lable: 'Xã Long Điền Đông A',
                            value: '31978',
                        },
                        {
                            lable: 'Xã Điền Hải',
                            value: '31985',
                        },
                        {
                            lable: 'Xã Định Thành',
                            value: '31993',
                        },
                        {
                            lable: 'Xã Định Thành A',
                            value: '31996',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hoà Bình',
                    value: '961',
                    wards: [
                        {
                            lable: 'Thị trấn Hòa Bình',
                            value: '31891',
                        },
                        {
                            lable: 'Xã Minh Diệu',
                            value: '31915',
                        },
                        {
                            lable: 'Xã Vĩnh Bình',
                            value: '31918',
                        },
                        {
                            lable: 'Xã Vĩnh Hậu',
                            value: '31927',
                        },
                        {
                            lable: 'Xã Vĩnh Hậu A',
                            value: '31930',
                        },
                        {
                            lable: 'Xã Vĩnh Mỹ A',
                            value: '31933',
                        },
                        {
                            lable: 'Xã Vĩnh Mỹ B',
                            value: '31924',
                        },
                        {
                            lable: 'Xã Vĩnh Thịnh',
                            value: '31936',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Bắc Ninh',
            value: '27',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Bắc Ninh',
                    value: '256',
                    wards: [
                        {
                            lable: 'Phường Hòa Long',
                            value: '09214',
                        },
                        {
                            lable: 'Phường Hạp Lĩnh',
                            value: '09331',
                        },
                        {
                            lable: 'Phường Khúc Xuyên',
                            value: '09235',
                        },
                        {
                            lable: 'Phường Khắc Niệm',
                            value: '09325',
                        },
                        {
                            lable: 'Phường Kim Chân',
                            value: '09256',
                        },
                        {
                            lable: 'Phường Kinh Bắc',
                            value: '09172',
                        },
                        {
                            lable: 'Phường Nam Sơn',
                            value: '09286',
                        },
                        {
                            lable: 'Phường Ninh Xá',
                            value: '09184',
                        },
                        {
                            lable: 'Phường Phong Khê',
                            value: '09244',
                        },
                        {
                            lable: 'Phường Suối Hoa',
                            value: '09187',
                        },
                        {
                            lable: 'Phường Thị Cầu',
                            value: '09169',
                        },
                        {
                            lable: 'Phường Tiền An',
                            value: '09178',
                        },
                        {
                            lable: 'Phường Vân Dương',
                            value: '09271',
                        },
                        {
                            lable: 'Phường Võ Cường',
                            value: '09190',
                        },
                        {
                            lable: 'Phường Vũ Ninh',
                            value: '09163',
                        },
                        {
                            lable: 'Phường Vạn An',
                            value: '09226',
                        },
                        {
                            lable: 'Phường Vệ An',
                            value: '09175',
                        },
                        {
                            lable: 'Phường Đáp Cầu',
                            value: '09166',
                        },
                        {
                            lable: 'Phường Đại Phúc',
                            value: '09181',
                        },
                    ],
                },
                {
                    lable: 'Huyện Yên Phong',
                    value: '258',
                    wards: [
                        {
                            lable: 'Thị trấn Chờ',
                            value: '09193',
                        },
                        {
                            lable: 'Xã Dũng Liệt',
                            value: '09196',
                        },
                        {
                            lable: 'Xã Hòa Tiến',
                            value: '09211',
                        },
                        {
                            lable: 'Xã Long Châu',
                            value: '09232',
                        },
                        {
                            lable: 'Xã Tam Giang',
                            value: '09202',
                        },
                        {
                            lable: 'Xã Tam Đa',
                            value: '09199',
                        },
                        {
                            lable: 'Xã Thụy Hòa',
                            value: '09208',
                        },
                        {
                            lable: 'Xã Trung Nghĩa',
                            value: '09223',
                        },
                        {
                            lable: 'Xã Văn Môn',
                            value: '09238',
                        },
                        {
                            lable: 'Xã Yên Phụ',
                            value: '09220',
                        },
                        {
                            lable: 'Xã Yên Trung',
                            value: '09205',
                        },
                        {
                            lable: 'Xã Đông Phong',
                            value: '09229',
                        },
                        {
                            lable: 'Xã Đông Thọ',
                            value: '09241',
                        },
                        {
                            lable: 'Xã Đông Tiến',
                            value: '09217',
                        },
                    ],
                },
                {
                    lable: 'Huyện Quế Võ',
                    value: '259',
                    wards: [
                        {
                            lable: 'Thị trấn Phố Mới',
                            value: '09247',
                        },
                        {
                            lable: 'Xã Bằng An',
                            value: '09262',
                        },
                        {
                            lable: 'Xã Bồng Lai',
                            value: '09295',
                        },
                        {
                            lable: 'Xã Chi Lăng',
                            value: '09313',
                        },
                        {
                            lable: 'Xã Châu Phong',
                            value: '09292',
                        },
                        {
                            lable: 'Xã Cách Bi',
                            value: '09298',
                        },
                        {
                            lable: 'Xã Hán Quảng',
                            value: '09316',
                        },
                        {
                            lable: 'Xã Mộ Đạo',
                            value: '09307',
                        },
                        {
                            lable: 'Xã Ngọc Xá',
                            value: '09289',
                        },
                        {
                            lable: 'Xã Nhân Hòa',
                            value: '09259',
                        },
                        {
                            lable: 'Xã Phù Lãng',
                            value: '09277',
                        },
                        {
                            lable: 'Xã Phù Lương',
                            value: '09274',
                        },
                        {
                            lable: 'Xã Phương Liễu',
                            value: '09265',
                        },
                        {
                            lable: 'Xã Phượng Mao',
                            value: '09280',
                        },
                        {
                            lable: 'Xã Quế Tân',
                            value: '09268',
                        },
                        {
                            lable: 'Xã Việt Hùng',
                            value: '09283',
                        },
                        {
                            lable: 'Xã Việt Thống',
                            value: '09250',
                        },
                        {
                            lable: 'Xã Yên Giả',
                            value: '09304',
                        },
                        {
                            lable: 'Xã Đào Viên',
                            value: '09301',
                        },
                        {
                            lable: 'Xã Đại Xuân',
                            value: '09253',
                        },
                        {
                            lable: 'Xã Đức Long',
                            value: '09310',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tiên Du',
                    value: '260',
                    wards: [
                        {
                            lable: 'Thị trấn Lim',
                            value: '09319',
                        },
                        {
                            lable: 'Xã Cảnh Hưng',
                            value: '09364',
                        },
                        {
                            lable: 'Xã Hiên Vân',
                            value: '09337',
                        },
                        {
                            lable: 'Xã Hoàn Sơn',
                            value: '09340',
                        },
                        {
                            lable: 'Xã Liên Bão',
                            value: '09334',
                        },
                        {
                            lable: 'Xã Lạc Vệ',
                            value: '09343',
                        },
                        {
                            lable: 'Xã Minh Đạo',
                            value: '09361',
                        },
                        {
                            lable: 'Xã Nội Duệ',
                            value: '09328',
                        },
                        {
                            lable: 'Xã Phú Lâm',
                            value: '09322',
                        },
                        {
                            lable: 'Xã Phật Tích',
                            value: '09349',
                        },
                        {
                            lable: 'Xã Tri Phương',
                            value: '09358',
                        },
                        {
                            lable: 'Xã Tân Chi',
                            value: '09352',
                        },
                        {
                            lable: 'Xã Việt Đoàn',
                            value: '09346',
                        },
                        {
                            lable: 'Xã Đại Đồng',
                            value: '09355',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Từ Sơn',
                    value: '261',
                    wards: [
                        {
                            lable: 'Phường Châu Khê',
                            value: '09388',
                        },
                        {
                            lable: 'Phường Hương Mạc',
                            value: '09373',
                        },
                        {
                            lable: 'Phường Phù Chẩn',
                            value: '09397',
                        },
                        {
                            lable: 'Phường Phù Khê',
                            value: '09379',
                        },
                        {
                            lable: 'Phường Tam Sơn',
                            value: '09370',
                        },
                        {
                            lable: 'Phường Trang Hạ',
                            value: '09383',
                        },
                        {
                            lable: 'Phường Tân Hồng',
                            value: '09391',
                        },
                        {
                            lable: 'Phường Tương Giang',
                            value: '09376',
                        },
                        {
                            lable: 'Phường Đình Bảng',
                            value: '09394',
                        },
                        {
                            lable: 'Phường Đông Ngàn',
                            value: '09367',
                        },
                        {
                            lable: 'Phường Đồng Kỵ',
                            value: '09382',
                        },
                        {
                            lable: 'Phường Đồng Nguyên',
                            value: '09385',
                        },
                    ],
                },
                {
                    lable: 'Huyện Thuận Thành',
                    value: '262',
                    wards: [
                        {
                            lable: 'Thị trấn Hồ',
                            value: '09400',
                        },
                        {
                            lable: 'Xã An Bình',
                            value: '09418',
                        },
                        {
                            lable: 'Xã Gia Đông',
                            value: '09424',
                        },
                        {
                            lable: 'Xã Hoài Thượng',
                            value: '09403',
                        },
                        {
                            lable: 'Xã Hà Mãn',
                            value: '09436',
                        },
                        {
                            lable: 'Xã Mão Điền',
                            value: '09409',
                        },
                        {
                            lable: 'Xã Nghĩa Đạo',
                            value: '09448',
                        },
                        {
                            lable: 'Xã Nguyệt Đức',
                            value: '09442',
                        },
                        {
                            lable: 'Xã Ngũ Thái',
                            value: '09439',
                        },
                        {
                            lable: 'Xã Ninh Xá',
                            value: '09445',
                        },
                        {
                            lable: 'Xã Song Hồ',
                            value: '09412',
                        },
                        {
                            lable: 'Xã Song Liễu',
                            value: '09451',
                        },
                        {
                            lable: 'Xã Thanh Khương',
                            value: '09427',
                        },
                        {
                            lable: 'Xã Trí Quả',
                            value: '09421',
                        },
                        {
                            lable: 'Xã Trạm Lộ',
                            value: '09430',
                        },
                        {
                            lable: 'Xã Xuân Lâm',
                            value: '09433',
                        },
                        {
                            lable: 'Xã Đình Tổ',
                            value: '09415',
                        },
                        {
                            lable: 'Xã Đại Đồng Thành',
                            value: '09406',
                        },
                    ],
                },
                {
                    lable: 'Huyện Gia Bình',
                    value: '263',
                    wards: [
                        {
                            lable: 'Thị trấn Gia Bình',
                            value: '09454',
                        },
                        {
                            lable: 'Xã Bình Dương',
                            value: '09475',
                        },
                        {
                            lable: 'Xã Cao Đức',
                            value: '09466',
                        },
                        {
                            lable: 'Xã Giang Sơn',
                            value: '09463',
                        },
                        {
                            lable: 'Xã Lãng Ngâm',
                            value: '09478',
                        },
                        {
                            lable: 'Xã Nhân Thắng',
                            value: '09481',
                        },
                        {
                            lable: 'Xã Quỳnh Phú',
                            value: '09493',
                        },
                        {
                            lable: 'Xã Song Giang',
                            value: '09472',
                        },
                        {
                            lable: 'Xã Thái Bảo',
                            value: '09460',
                        },
                        {
                            lable: 'Xã Vạn Ninh',
                            value: '09457',
                        },
                        {
                            lable: 'Xã Xuân Lai',
                            value: '09484',
                        },
                        {
                            lable: 'Xã Đông Cứu',
                            value: '09487',
                        },
                        {
                            lable: 'Xã Đại Bái',
                            value: '09490',
                        },
                        {
                            lable: 'Xã Đại Lai',
                            value: '09469',
                        },
                    ],
                },
                {
                    lable: 'Huyện Lương Tài',
                    value: '264',
                    wards: [
                        {
                            lable: 'Thị trấn Thứa',
                            value: '09496',
                        },
                        {
                            lable: 'Xã An Thịnh',
                            value: '09499',
                        },
                        {
                            lable: 'Xã Bình Định',
                            value: '09529',
                        },
                        {
                            lable: 'Xã Lai Hạ',
                            value: '09520',
                        },
                        {
                            lable: 'Xã Lâm Thao',
                            value: '09535',
                        },
                        {
                            lable: 'Xã Minh Tân',
                            value: '09526',
                        },
                        {
                            lable: 'Xã Mỹ Hương',
                            value: '09508',
                        },
                        {
                            lable: 'Xã Phú Hòa',
                            value: '09505',
                        },
                        {
                            lable: 'Xã Phú Lương',
                            value: '09532',
                        },
                        {
                            lable: 'Xã Quảng Phú',
                            value: '09514',
                        },
                        {
                            lable: 'Xã Trung Chính',
                            value: '09523',
                        },
                        {
                            lable: 'Xã Trung Kênh',
                            value: '09502',
                        },
                        {
                            lable: 'Xã Trừng Xá',
                            value: '09517',
                        },
                        {
                            lable: 'Xã Tân Lãng',
                            value: '09511',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Bến Tre',
            value: '83',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Bến Tre',
                    value: '829',
                    wards: [
                        {
                            lable: 'Phường 4',
                            value: '28765',
                        },
                        {
                            lable: 'Phường 5',
                            value: '28768',
                        },
                        {
                            lable: 'Phường 6',
                            value: '28762',
                        },
                        {
                            lable: 'Phường 7',
                            value: '28780',
                        },
                        {
                            lable: 'Phường 8',
                            value: '28759',
                        },
                        {
                            lable: 'Phường An Hội',
                            value: '28777',
                        },
                        {
                            lable: 'Phường Phú Khương',
                            value: '28756',
                        },
                        {
                            lable: 'Phường Phú Tân',
                            value: '28757',
                        },
                        {
                            lable: 'Xã Bình Phú',
                            value: '28789',
                        },
                        {
                            lable: 'Xã Mỹ Thạnh An',
                            value: '28792',
                        },
                        {
                            lable: 'Xã Nhơn Thạnh',
                            value: '28795',
                        },
                        {
                            lable: 'Xã Phú Hưng',
                            value: '28786',
                        },
                        {
                            lable: 'Xã Phú Nhuận',
                            value: '28798',
                        },
                        {
                            lable: 'Xã Sơn Đông',
                            value: '28783',
                        },
                    ],
                },
                {
                    lable: 'Huyện Châu Thành',
                    value: '831',
                    wards: [
                        {
                            lable: 'Thị trấn Châu Thành',
                            value: '28801',
                        },
                        {
                            lable: 'Xã An Hiệp',
                            value: '28855',
                        },
                        {
                            lable: 'Xã An Hóa',
                            value: '28849',
                        },
                        {
                            lable: 'Xã An Khánh',
                            value: '28810',
                        },
                        {
                            lable: 'Xã An Phước',
                            value: '28828',
                        },
                        {
                            lable: 'Xã Giao Long',
                            value: '28813',
                        },
                        {
                            lable: 'Xã Hữu Định',
                            value: '28858',
                        },
                        {
                            lable: 'Xã Phú An Hòa',
                            value: '28825',
                        },
                        {
                            lable: 'Xã Phú Túc',
                            value: '28819',
                        },
                        {
                            lable: 'Xã Phú Đức',
                            value: '28822',
                        },
                        {
                            lable: 'Xã Phước Thạnh',
                            value: '28846',
                        },
                        {
                            lable: 'Xã Quới Thành',
                            value: '28843',
                        },
                        {
                            lable: 'Xã Qưới Sơn',
                            value: '28807',
                        },
                        {
                            lable: 'Xã Sơn Hòa',
                            value: '28864',
                        },
                        {
                            lable: 'Xã Tam Phước',
                            value: '28831',
                        },
                        {
                            lable: 'Xã Thành Triệu',
                            value: '28834',
                        },
                        {
                            lable: 'Xã Tiên Long',
                            value: '28852',
                        },
                        {
                            lable: 'Xã Tiên Thủy',
                            value: '28861',
                        },
                        {
                            lable: 'Xã Tân Phú',
                            value: '28840',
                        },
                        {
                            lable: 'Xã Tân Thạch',
                            value: '28804',
                        },
                        {
                            lable: 'Xã Tường Đa',
                            value: '28837',
                        },
                    ],
                },
                {
                    lable: 'Huyện Chợ Lách',
                    value: '832',
                    wards: [
                        {
                            lable: 'Thị trấn Chợ Lách',
                            value: '28870',
                        },
                        {
                            lable: 'Xã Hòa Nghĩa',
                            value: '28882',
                        },
                        {
                            lable: 'Xã Hưng Khánh Trung B',
                            value: '28900',
                        },
                        {
                            lable: 'Xã Long Thới',
                            value: '28885',
                        },
                        {
                            lable: 'Xã Phú Phụng',
                            value: '28873',
                        },
                        {
                            lable: 'Xã Phú Sơn',
                            value: '28888',
                        },
                        {
                            lable: 'Xã Sơn Định',
                            value: '28876',
                        },
                        {
                            lable: 'Xã Tân Thiềng',
                            value: '28891',
                        },
                        {
                            lable: 'Xã Vĩnh Bình',
                            value: '28879',
                        },
                        {
                            lable: 'Xã Vĩnh Hòa',
                            value: '28897',
                        },
                        {
                            lable: 'Xã Vĩnh Thành',
                            value: '28894',
                        },
                    ],
                },
                {
                    lable: 'Huyện Mỏ Cày Nam',
                    value: '833',
                    wards: [
                        {
                            lable: 'Thị trấn Mỏ Cày',
                            value: '28903',
                        },
                        {
                            lable: 'Xã An Thạnh',
                            value: '28951',
                        },
                        {
                            lable: 'Xã An Thới',
                            value: '28966',
                        },
                        {
                            lable: 'Xã An Định',
                            value: '28957',
                        },
                        {
                            lable: 'Xã Bình Khánh ',
                            value: '28945',
                        },
                        {
                            lable: 'Xã Cẩm Sơn',
                            value: '28978',
                        },
                        {
                            lable: 'Xã Hương Mỹ',
                            value: '28981',
                        },
                        {
                            lable: 'Xã Minh Đức',
                            value: '28972',
                        },
                        {
                            lable: 'Xã Ngãi Đăng',
                            value: '28975',
                        },
                        {
                            lable: 'Xã Phước Hiệp',
                            value: '28942',
                        },
                        {
                            lable: 'Xã Thành Thới A',
                            value: '28969',
                        },
                        {
                            lable: 'Xã Thành Thới B',
                            value: '28960',
                        },
                        {
                            lable: 'Xã Tân Hội',
                            value: '28940',
                        },
                        {
                            lable: 'Xã Tân Trung',
                            value: '28963',
                        },
                        {
                            lable: 'Xã Đa Phước Hội',
                            value: '28939',
                        },
                        {
                            lable: 'Xã Định Thủy',
                            value: '28930',
                        },
                    ],
                },
                {
                    lable: 'Huyện Giồng Trôm',
                    value: '834',
                    wards: [
                        {
                            lable: 'Thị trấn Giồng Trôm',
                            value: '28984',
                        },
                        {
                            lable: 'Xã Bình Hoà',
                            value: '29017',
                        },
                        {
                            lable: 'Xã Bình Thành',
                            value: '29032',
                        },
                        {
                            lable: 'Xã Châu Bình',
                            value: '29008',
                        },
                        {
                            lable: 'Xã Châu Hòa',
                            value: '28996',
                        },
                        {
                            lable: 'Xã Hưng Lễ',
                            value: '29047',
                        },
                        {
                            lable: 'Xã Hưng Nhượng',
                            value: '29044',
                        },
                        {
                            lable: 'Xã Hưng Phong',
                            value: '29023',
                        },
                        {
                            lable: 'Xã Long Mỹ',
                            value: '29026',
                        },
                        {
                            lable: 'Xã Lương Hòa',
                            value: '28999',
                        },
                        {
                            lable: 'Xã Lương Phú',
                            value: '29005',
                        },
                        {
                            lable: 'Xã Lương Quới',
                            value: '29002',
                        },
                        {
                            lable: 'Xã Mỹ Thạnh',
                            value: '28993',
                        },
                        {
                            lable: 'Xã Phong Nẫm',
                            value: '28987',
                        },
                        {
                            lable: 'Xã Phước Long',
                            value: '29020',
                        },
                        {
                            lable: 'Xã Sơn Phú',
                            value: '29014',
                        },
                        {
                            lable: 'Xã Thuận Điền',
                            value: '29011',
                        },
                        {
                            lable: 'Xã Thạnh Phú Đông',
                            value: '29041',
                        },
                        {
                            lable: 'Xã Tân Hào',
                            value: '29029',
                        },
                        {
                            lable: 'Xã Tân Lợi Thạnh',
                            value: '29038',
                        },
                        {
                            lable: 'Xã Tân Thanh',
                            value: '29035',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bình Đại',
                    value: '835',
                    wards: [
                        {
                            lable: 'Thị trấn Bình Đại',
                            value: '29050',
                        },
                        {
                            lable: 'Xã Bình Thắng',
                            value: '29092',
                        },
                        {
                            lable: 'Xã Bình Thới',
                            value: '29086',
                        },
                        {
                            lable: 'Xã Châu Hưng',
                            value: '29071',
                        },
                        {
                            lable: 'Xã Long Hòa',
                            value: '29059',
                        },
                        {
                            lable: 'Xã Long Định',
                            value: '29056',
                        },
                        {
                            lable: 'Xã Lộc Thuận',
                            value: '29077',
                        },
                        {
                            lable: 'Xã Phú Long',
                            value: '29089',
                        },
                        {
                            lable: 'Xã Phú Thuận',
                            value: '29062',
                        },
                        {
                            lable: 'Xã Phú Vang',
                            value: '29074',
                        },
                        {
                            lable: 'Xã Tam Hiệp',
                            value: '29053',
                        },
                        {
                            lable: 'Xã Thạnh Phước',
                            value: '29104',
                        },
                        {
                            lable: 'Xã Thạnh Trị',
                            value: '29095',
                        },
                        {
                            lable: 'Xã Thới Lai',
                            value: '29083',
                        },
                        {
                            lable: 'Xã Thới Thuận',
                            value: '29107',
                        },
                        {
                            lable: 'Xã Thừa Đức',
                            value: '29101',
                        },
                        {
                            lable: 'Xã Vang Quới Tây',
                            value: '29065',
                        },
                        {
                            lable: 'Xã Vang Quới Đông',
                            value: '29068',
                        },
                        {
                            lable: 'Xã Đại Hòa Lộc',
                            value: '29098',
                        },
                        {
                            lable: 'Xã Định Trung',
                            value: '29080',
                        },
                    ],
                },
                {
                    lable: 'Huyện Ba Tri',
                    value: '836',
                    wards: [
                        {
                            lable: 'Thị trấn Ba Tri',
                            value: '29110',
                        },
                        {
                            lable: 'Xã An Bình Tây',
                            value: '29149',
                        },
                        {
                            lable: 'Xã An Hiệp',
                            value: '29161',
                        },
                        {
                            lable: 'Xã An Hòa Tây',
                            value: '29176',
                        },
                        {
                            lable: 'Xã An Ngãi Trung',
                            value: '29143',
                        },
                        {
                            lable: 'Xã An Ngãi Tây',
                            value: '29158',
                        },
                        {
                            lable: 'Xã An Phú Trung',
                            value: '29128',
                        },
                        {
                            lable: 'Xã An Thủy',
                            value: '29179',
                        },
                        {
                            lable: 'Xã An Đức',
                            value: '29173',
                        },
                        {
                            lable: 'Xã Bảo Thuận',
                            value: '29152',
                        },
                        {
                            lable: 'Xã Bảo Thạnh',
                            value: '29125',
                        },
                        {
                            lable: 'Xã Mỹ Chánh',
                            value: '29122',
                        },
                        {
                            lable: 'Xã Mỹ Hòa',
                            value: '29116',
                        },
                        {
                            lable: 'Xã Mỹ Nhơn',
                            value: '29134',
                        },
                        {
                            lable: 'Xã Mỹ Thạnh',
                            value: '29131',
                        },
                        {
                            lable: 'Xã Phú Lễ',
                            value: '29146',
                        },
                        {
                            lable: 'Xã Phước Ngãi',
                            value: '29137',
                        },
                        {
                            lable: 'Xã Tân Hưng',
                            value: '29155',
                        },
                        {
                            lable: 'Xã Tân Mỹ',
                            value: '29113',
                        },
                        {
                            lable: 'Xã Tân Thủy',
                            value: '29167',
                        },
                        {
                            lable: 'Xã Tân Xuân',
                            value: '29119',
                        },
                        {
                            lable: 'Xã Vĩnh An',
                            value: '29170',
                        },
                        {
                            lable: 'Xã Vĩnh Hòa',
                            value: '29164',
                        },
                    ],
                },
                {
                    lable: 'Huyện Thạnh Phú',
                    value: '837',
                    wards: [
                        {
                            lable: 'Thị trấn Thạnh Phú',
                            value: '29182',
                        },
                        {
                            lable: 'Xã An Nhơn',
                            value: '29224',
                        },
                        {
                            lable: 'Xã An Quy',
                            value: '29218',
                        },
                        {
                            lable: 'Xã An Thuận',
                            value: '29215',
                        },
                        {
                            lable: 'Xã An Thạnh',
                            value: '29200',
                        },
                        {
                            lable: 'Xã An Điền',
                            value: '29209',
                        },
                        {
                            lable: 'Xã Bình Thạnh',
                            value: '29212',
                        },
                        {
                            lable: 'Xã Giao Thạnh',
                            value: '29227',
                        },
                        {
                            lable: 'Xã Hòa Lợi',
                            value: '29206',
                        },
                        {
                            lable: 'Xã Mỹ An',
                            value: '29233',
                        },
                        {
                            lable: 'Xã Mỹ Hưng',
                            value: '29197',
                        },
                        {
                            lable: 'Xã Phú Khánh',
                            value: '29185',
                        },
                        {
                            lable: 'Xã Quới Điền',
                            value: '29191',
                        },
                        {
                            lable: 'Xã Thạnh Hải',
                            value: '29221',
                        },
                        {
                            lable: 'Xã Thạnh Phong',
                            value: '29230',
                        },
                        {
                            lable: 'Xã Thới Thạnh',
                            value: '29203',
                        },
                        {
                            lable: 'Xã Tân Phong',
                            value: '29194',
                        },
                        {
                            lable: 'Xã Đại Điền',
                            value: '29188',
                        },
                    ],
                },
                {
                    lable: 'Huyện Mỏ Cày Bắc',
                    value: '838',
                    wards: [
                        {
                            lable: 'Xã Hòa Lộc',
                            value: '28924',
                        },
                        {
                            lable: 'Xã Hưng Khánh Trung A',
                            value: '28901',
                        },
                        {
                            lable: 'Xã Khánh Thạnh Tân',
                            value: '28948',
                        },
                        {
                            lable: 'Xã Nhuận Phú Tân',
                            value: '28936',
                        },
                        {
                            lable: 'Xã Phú Mỹ',
                            value: '28889',
                        },
                        {
                            lable: 'Xã Phước Mỹ Trung',
                            value: '28915',
                        },
                        {
                            lable: 'Xã Thanh Tân',
                            value: '28906',
                        },
                        {
                            lable: 'Xã Thành An',
                            value: '28921',
                        },
                        {
                            lable: 'Xã Thạnh Ngãi',
                            value: '28909',
                        },
                        {
                            lable: 'Xã Tân Bình',
                            value: '28933',
                        },
                        {
                            lable: 'Xã Tân Phú Tây',
                            value: '28912',
                        },
                        {
                            lable: 'Xã Tân Thanh Tây',
                            value: '28927',
                        },
                        {
                            lable: 'Xã Tân Thành Bình',
                            value: '28918',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Bình Định',
            value: '52',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Quy Nhơn',
                    value: '540',
                    wards: [
                        {
                            lable: 'Phường Bùi Thị Xuân',
                            value: '21589',
                        },
                        {
                            lable: 'Phường Ghềnh Ráng',
                            value: '21595',
                        },
                        {
                            lable: 'Phường Hải Cảng',
                            value: '21562',
                        },
                        {
                            lable: 'Phường Lê Hồng Phong',
                            value: '21571',
                        },
                        {
                            lable: 'Phường Lê Lợi',
                            value: '21583',
                        },
                        {
                            lable: 'Phường Lý Thường Kiệt',
                            value: '21580',
                        },
                        {
                            lable: 'Phường Nguyễn Văn Cừ',
                            value: '21592',
                        },
                        {
                            lable: 'Phường Ngô Mây',
                            value: '21577',
                        },
                        {
                            lable: 'Phường Nhơn Bình',
                            value: '21550',
                        },
                        {
                            lable: 'Phường Nhơn Phú',
                            value: '21553',
                        },
                        {
                            lable: 'Phường Quang Trung',
                            value: '21565',
                        },
                        {
                            lable: 'Phường Thị Nại',
                            value: '21568',
                        },
                        {
                            lable: 'Phường Trần Hưng Đạo',
                            value: '21574',
                        },
                        {
                            lable: 'Phường Trần Phú',
                            value: '21586',
                        },
                        {
                            lable: 'Phường Trần Quang Diệu',
                            value: '21559',
                        },
                        {
                            lable: 'Phường Đống Đa',
                            value: '21556',
                        },
                        {
                            lable: 'Xã Nhơn Châu',
                            value: '21607',
                        },
                        {
                            lable: 'Xã Nhơn Hải',
                            value: '21604',
                        },
                        {
                            lable: 'Xã Nhơn Hội',
                            value: '21601',
                        },
                        {
                            lable: 'Xã Nhơn Lý',
                            value: '21598',
                        },
                        {
                            lable: 'Xã Phước Mỹ',
                            value: '21991',
                        },
                    ],
                },
                {
                    lable: 'Huyện An Lão',
                    value: '542',
                    wards: [
                        {
                            lable: 'Thị trấn An Lão',
                            value: '21609',
                        },
                        {
                            lable: 'Xã An Dũng',
                            value: '21616',
                        },
                        {
                            lable: 'Xã An Hòa',
                            value: '21628',
                        },
                        {
                            lable: 'Xã An Hưng',
                            value: '21610',
                        },
                        {
                            lable: 'Xã An Nghĩa',
                            value: '21634',
                        },
                        {
                            lable: 'Xã An Quang',
                            value: '21631',
                        },
                        {
                            lable: 'Xã An Toàn',
                            value: '21622',
                        },
                        {
                            lable: 'Xã An Trung',
                            value: '21613',
                        },
                        {
                            lable: 'Xã An Tân',
                            value: '21625',
                        },
                        {
                            lable: 'Xã An Vinh',
                            value: '21619',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Hoài Nhơn',
                    value: '543',
                    wards: [
                        {
                            lable: 'Phường Bồng Sơn',
                            value: '21640',
                        },
                        {
                            lable: 'Phường Hoài Hương',
                            value: '21670',
                        },
                        {
                            lable: 'Phường Hoài Hảo',
                            value: '21661',
                        },
                        {
                            lable: 'Phường Hoài Thanh',
                            value: '21667',
                        },
                        {
                            lable: 'Phường Hoài Thanh Tây',
                            value: '21664',
                        },
                        {
                            lable: 'Phường Hoài Tân',
                            value: '21673',
                        },
                        {
                            lable: 'Phường Hoài Xuân',
                            value: '21679',
                        },
                        {
                            lable: 'Phường Hoài Đức',
                            value: '21685',
                        },
                        {
                            lable: 'Phường Tam Quan',
                            value: '21637',
                        },
                        {
                            lable: 'Phường Tam Quan Bắc',
                            value: '21655',
                        },
                        {
                            lable: 'Phường Tam Quan Nam',
                            value: '21658',
                        },
                        {
                            lable: 'Xã Hoài Châu',
                            value: '21649',
                        },
                        {
                            lable: 'Xã Hoài Châu Bắc',
                            value: '21646',
                        },
                        {
                            lable: 'Xã Hoài Hải',
                            value: '21676',
                        },
                        {
                            lable: 'Xã Hoài Mỹ',
                            value: '21682',
                        },
                        {
                            lable: 'Xã Hoài Phú',
                            value: '21652',
                        },
                        {
                            lable: 'Xã Hoài Sơn',
                            value: '21643',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hoài Ân',
                    value: '544',
                    wards: [
                        {
                            lable: 'Thị trấn Tăng Bạt Hổ',
                            value: '21688',
                        },
                        {
                            lable: 'Xã Bok Tới',
                            value: '21718',
                        },
                        {
                            lable: 'Xã Ân Hảo Tây',
                            value: '21690',
                        },
                        {
                            lable: 'Xã Ân Hảo Đông',
                            value: '21691',
                        },
                        {
                            lable: 'Xã Ân Hữu',
                            value: '21715',
                        },
                        {
                            lable: 'Xã Ân Mỹ',
                            value: '21697',
                        },
                        {
                            lable: 'Xã Ân Nghĩa',
                            value: '21727',
                        },
                        {
                            lable: 'Xã Ân Phong',
                            value: '21709',
                        },
                        {
                            lable: 'Xã Ân Sơn',
                            value: '21694',
                        },
                        {
                            lable: 'Xã Ân Thạnh',
                            value: '21706',
                        },
                        {
                            lable: 'Xã Ân Tín',
                            value: '21703',
                        },
                        {
                            lable: 'Xã Ân Tường Tây',
                            value: '21721',
                        },
                        {
                            lable: 'Xã Ân Tường Đông',
                            value: '21724',
                        },
                        {
                            lable: 'Xã Ân Đức',
                            value: '21712',
                        },
                        {
                            lable: 'Xã Đak Mang',
                            value: '21700',
                        },
                    ],
                },
                {
                    lable: 'Huyện Phù Mỹ',
                    value: '545',
                    wards: [
                        {
                            lable: 'Thị trấn Bình Dương',
                            value: '21733',
                        },
                        {
                            lable: 'Thị trấn Phù Mỹ',
                            value: '21730',
                        },
                        {
                            lable: 'Xã Mỹ An',
                            value: '21751',
                        },
                        {
                            lable: 'Xã Mỹ Chánh',
                            value: '21769',
                        },
                        {
                            lable: 'Xã Mỹ Chánh Tây',
                            value: '21784',
                        },
                        {
                            lable: 'Xã Mỹ Châu',
                            value: '21739',
                        },
                        {
                            lable: 'Xã Mỹ Cát',
                            value: '21781',
                        },
                        {
                            lable: 'Xã Mỹ Hiệp',
                            value: '21775',
                        },
                        {
                            lable: 'Xã Mỹ Hòa',
                            value: '21763',
                        },
                        {
                            lable: 'Xã Mỹ Lộc',
                            value: '21745',
                        },
                        {
                            lable: 'Xã Mỹ Lợi',
                            value: '21748',
                        },
                        {
                            lable: 'Xã Mỹ Phong',
                            value: '21754',
                        },
                        {
                            lable: 'Xã Mỹ Quang',
                            value: '21772',
                        },
                        {
                            lable: 'Xã Mỹ Thành',
                            value: '21766',
                        },
                        {
                            lable: 'Xã Mỹ Thắng',
                            value: '21742',
                        },
                        {
                            lable: 'Xã Mỹ Thọ',
                            value: '21760',
                        },
                        {
                            lable: 'Xã Mỹ Trinh',
                            value: '21757',
                        },
                        {
                            lable: 'Xã Mỹ Tài',
                            value: '21778',
                        },
                        {
                            lable: 'Xã Mỹ Đức',
                            value: '21736',
                        },
                    ],
                },
                {
                    lable: 'Huyện Vĩnh Thạnh',
                    value: '546',
                    wards: [
                        {
                            lable: 'Thị trấn Vĩnh Thạnh',
                            value: '21786',
                        },
                        {
                            lable: 'Xã Vĩnh Hiệp',
                            value: '21796',
                        },
                        {
                            lable: 'Xã Vĩnh Hòa',
                            value: '21801',
                        },
                        {
                            lable: 'Xã Vĩnh Hảo',
                            value: '21799',
                        },
                        {
                            lable: 'Xã Vĩnh Kim',
                            value: '21790',
                        },
                        {
                            lable: 'Xã Vĩnh Quang',
                            value: '21805',
                        },
                        {
                            lable: 'Xã Vĩnh Sơn',
                            value: '21787',
                        },
                        {
                            lable: 'Xã Vĩnh Thuận',
                            value: '21804',
                        },
                        {
                            lable: 'Xã Vĩnh Thịnh',
                            value: '21802',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tây Sơn',
                    value: '547',
                    wards: [
                        {
                            lable: 'Thị trấn Phú Phong',
                            value: '21808',
                        },
                        {
                            lable: 'Xã Bình Hòa',
                            value: '21829',
                        },
                        {
                            lable: 'Xã Bình Nghi',
                            value: '21847',
                        },
                        {
                            lable: 'Xã Bình Thuận',
                            value: '21817',
                        },
                        {
                            lable: 'Xã Bình Thành',
                            value: '21823',
                        },
                        {
                            lable: 'Xã Bình Tân',
                            value: '21811',
                        },
                        {
                            lable: 'Xã Bình Tường',
                            value: '21835',
                        },
                        {
                            lable: 'Xã Tây An',
                            value: '21826',
                        },
                        {
                            lable: 'Xã Tây Bình',
                            value: '21832',
                        },
                        {
                            lable: 'Xã Tây Giang',
                            value: '21820',
                        },
                        {
                            lable: 'Xã Tây Phú',
                            value: '21850',
                        },
                        {
                            lable: 'Xã Tây Thuận',
                            value: '21814',
                        },
                        {
                            lable: 'Xã Tây Vinh',
                            value: '21838',
                        },
                        {
                            lable: 'Xã Tây Xuân',
                            value: '21844',
                        },
                        {
                            lable: 'Xã Vĩnh An',
                            value: '21841',
                        },
                    ],
                },
                {
                    lable: 'Huyện Phù Cát',
                    value: '548',
                    wards: [
                        {
                            lable: 'Thị trấn Cát Tiến',
                            value: '21898',
                        },
                        {
                            lable: 'Thị trấn Ngô Mây',
                            value: '21853',
                        },
                        {
                            lable: 'Xã Cát Chánh',
                            value: '21904',
                        },
                        {
                            lable: 'Xã Cát Hanh',
                            value: '21871',
                        },
                        {
                            lable: 'Xã Cát Hiệp',
                            value: '21883',
                        },
                        {
                            lable: 'Xã Cát Hưng',
                            value: '21889',
                        },
                        {
                            lable: 'Xã Cát Hải',
                            value: '21880',
                        },
                        {
                            lable: 'Xã Cát Khánh',
                            value: '21862',
                        },
                        {
                            lable: 'Xã Cát Lâm',
                            value: '21868',
                        },
                        {
                            lable: 'Xã Cát Minh',
                            value: '21859',
                        },
                        {
                            lable: 'Xã Cát Nhơn',
                            value: '21886',
                        },
                        {
                            lable: 'Xã Cát Sơn',
                            value: '21856',
                        },
                        {
                            lable: 'Xã Cát Thành',
                            value: '21874',
                        },
                        {
                            lable: 'Xã Cát Thắng',
                            value: '21901',
                        },
                        {
                            lable: 'Xã Cát Trinh',
                            value: '21877',
                        },
                        {
                            lable: 'Xã Cát Tài',
                            value: '21865',
                        },
                        {
                            lable: 'Xã Cát Tân',
                            value: '21895',
                        },
                        {
                            lable: 'Xã Cát Tường',
                            value: '21892',
                        },
                    ],
                },
                {
                    lable: 'Thị xã An Nhơn',
                    value: '549',
                    wards: [
                        {
                            lable: 'Phường Bình Định',
                            value: '21907',
                        },
                        {
                            lable: 'Phường Nhơn Hoà',
                            value: '21943',
                        },
                        {
                            lable: 'Phường Nhơn Hưng',
                            value: '21934',
                        },
                        {
                            lable: 'Phường Nhơn Thành',
                            value: '21916',
                        },
                        {
                            lable: 'Phường Đập Đá',
                            value: '21910',
                        },
                        {
                            lable: 'Xã Nhơn An',
                            value: '21928',
                        },
                        {
                            lable: 'Xã Nhơn Hạnh',
                            value: '21919',
                        },
                        {
                            lable: 'Xã Nhơn Hậu',
                            value: '21922',
                        },
                        {
                            lable: 'Xã Nhơn Khánh',
                            value: '21937',
                        },
                        {
                            lable: 'Xã Nhơn Lộc',
                            value: '21940',
                        },
                        {
                            lable: 'Xã Nhơn Mỹ',
                            value: '21913',
                        },
                        {
                            lable: 'Xã Nhơn Phong',
                            value: '21925',
                        },
                        {
                            lable: 'Xã Nhơn Phúc',
                            value: '21931',
                        },
                        {
                            lable: 'Xã Nhơn Thọ',
                            value: '21949',
                        },
                        {
                            lable: 'Xã Nhơn Tân',
                            value: '21946',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tuy Phước',
                    value: '550',
                    wards: [
                        {
                            lable: 'Thị trấn Diêu Trì',
                            value: '21955',
                        },
                        {
                            lable: 'Thị trấn Tuy Phước',
                            value: '21952',
                        },
                        {
                            lable: 'Xã Phước An',
                            value: '21985',
                        },
                        {
                            lable: 'Xã Phước Hiệp',
                            value: '21973',
                        },
                        {
                            lable: 'Xã Phước Hòa',
                            value: '21967',
                        },
                        {
                            lable: 'Xã Phước Hưng',
                            value: '21961',
                        },
                        {
                            lable: 'Xã Phước Lộc',
                            value: '21976',
                        },
                        {
                            lable: 'Xã Phước Nghĩa',
                            value: '21979',
                        },
                        {
                            lable: 'Xã Phước Quang',
                            value: '21964',
                        },
                        {
                            lable: 'Xã Phước Sơn',
                            value: '21970',
                        },
                        {
                            lable: 'Xã Phước Thuận',
                            value: '21982',
                        },
                        {
                            lable: 'Xã Phước Thành',
                            value: '21988',
                        },
                        {
                            lable: 'Xã Phước Thắng',
                            value: '21958',
                        },
                    ],
                },
                {
                    lable: 'Huyện Vân Canh',
                    value: '551',
                    wards: [
                        {
                            lable: 'Thị trấn Vân Canh',
                            value: '21994',
                        },
                        {
                            lable: 'Xã Canh Hiển',
                            value: '22006',
                        },
                        {
                            lable: 'Xã Canh Hiệp',
                            value: '22000',
                        },
                        {
                            lable: 'Xã Canh Hòa',
                            value: '22012',
                        },
                        {
                            lable: 'Xã Canh Liên',
                            value: '21997',
                        },
                        {
                            lable: 'Xã Canh Thuận',
                            value: '22009',
                        },
                        {
                            lable: 'Xã Canh Vinh',
                            value: '22003',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Bình Dương',
            value: '74',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Thủ Dầu Một',
                    value: '718',
                    wards: [
                        {
                            lable: 'Phường Chánh Mỹ',
                            value: '25774',
                        },
                        {
                            lable: 'Phường Chánh Nghĩa',
                            value: '25756',
                        },
                        {
                            lable: 'Phường Hiệp An',
                            value: '25768',
                        },
                        {
                            lable: 'Phường Hiệp Thành',
                            value: '25741',
                        },
                        {
                            lable: 'Phường Hoà Phú',
                            value: '25760',
                        },
                        {
                            lable: 'Phường Phú Cường',
                            value: '25747',
                        },
                        {
                            lable: 'Phường Phú Hòa',
                            value: '25750',
                        },
                        {
                            lable: 'Phường Phú Lợi',
                            value: '25744',
                        },
                        {
                            lable: 'Phường Phú Mỹ',
                            value: '25762',
                        },
                        {
                            lable: 'Phường Phú Thọ',
                            value: '25753',
                        },
                        {
                            lable: 'Phường Phú Tân',
                            value: '25763',
                        },
                        {
                            lable: 'Phường Tân An',
                            value: '25765',
                        },
                        {
                            lable: 'Phường Tương Bình Hiệp',
                            value: '25771',
                        },
                        {
                            lable: 'Phường Định Hoà',
                            value: '25759',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bàu Bàng',
                    value: '719',
                    wards: [
                        {
                            lable: 'Thị trấn Lai Uyên',
                            value: '25822',
                        },
                        {
                            lable: 'Xã Cây Trường II',
                            value: '25819',
                        },
                        {
                            lable: 'Xã Hưng Hòa',
                            value: '25831',
                        },
                        {
                            lable: 'Xã Lai Hưng',
                            value: '25834',
                        },
                        {
                            lable: 'Xã Long Nguyên',
                            value: '25828',
                        },
                        {
                            lable: 'Xã Trừ Văn Thố',
                            value: '25816',
                        },
                        {
                            lable: 'Xã Tân Hưng',
                            value: '25825',
                        },
                    ],
                },
                {
                    lable: 'Huyện Dầu Tiếng',
                    value: '720',
                    wards: [
                        {
                            lable: 'Thị trấn Dầu Tiếng',
                            value: '25777',
                        },
                        {
                            lable: 'Xã An Lập',
                            value: '25801',
                        },
                        {
                            lable: 'Xã Long Hoà',
                            value: '25792',
                        },
                        {
                            lable: 'Xã Long Tân',
                            value: '25804',
                        },
                        {
                            lable: 'Xã Minh Hoà',
                            value: '25780',
                        },
                        {
                            lable: 'Xã Minh Thạnh',
                            value: '25783',
                        },
                        {
                            lable: 'Xã Minh Tân',
                            value: '25786',
                        },
                        {
                            lable: 'Xã Thanh An',
                            value: '25807',
                        },
                        {
                            lable: 'Xã Thanh Tuyền',
                            value: '25810',
                        },
                        {
                            lable: 'Xã Định An',
                            value: '25789',
                        },
                        {
                            lable: 'Xã Định Hiệp',
                            value: '25798',
                        },
                        {
                            lable: 'Xã Định Thành',
                            value: '25795',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Bến Cát',
                    value: '721',
                    wards: [
                        {
                            lable: 'Phường Chánh Phú Hòa',
                            value: '25837',
                        },
                        {
                            lable: 'Phường Hòa Lợi',
                            value: '25849',
                        },
                        {
                            lable: 'Phường Mỹ Phước',
                            value: '25813',
                        },
                        {
                            lable: 'Phường Thới Hòa',
                            value: '25846',
                        },
                        {
                            lable: 'Phường Tân Định',
                            value: '25852',
                        },
                        {
                            lable: 'Xã An Tây',
                            value: '25843',
                        },
                        {
                            lable: 'Xã An Điền',
                            value: '25840',
                        },
                        {
                            lable: 'Xã Phú An',
                            value: '25855',
                        },
                    ],
                },
                {
                    lable: 'Huyện Phú Giáo',
                    value: '722',
                    wards: [
                        {
                            lable: 'Thị trấn Phước Vĩnh',
                            value: '25858',
                        },
                        {
                            lable: 'Xã An Bình',
                            value: '25870',
                        },
                        {
                            lable: 'Xã An Linh',
                            value: '25861',
                        },
                        {
                            lable: 'Xã An Long',
                            value: '25867',
                        },
                        {
                            lable: 'Xã An Thái',
                            value: '25865',
                        },
                        {
                            lable: 'Xã Phước Hoà',
                            value: '25885',
                        },
                        {
                            lable: 'Xã Phước Sang',
                            value: '25864',
                        },
                        {
                            lable: 'Xã Tam Lập',
                            value: '25876',
                        },
                        {
                            lable: 'Xã Tân Hiệp',
                            value: '25873',
                        },
                        {
                            lable: 'Xã Tân Long',
                            value: '25879',
                        },
                        {
                            lable: 'Xã Vĩnh Hoà',
                            value: '25882',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Tân Uyên',
                    value: '723',
                    wards: [
                        {
                            lable: 'Phường Hội Nghĩa',
                            value: '25915',
                        },
                        {
                            lable: 'Phường Khánh Bình',
                            value: '25921',
                        },
                        {
                            lable: 'Phường Phú Chánh',
                            value: '25924',
                        },
                        {
                            lable: 'Phường Thái Hòa',
                            value: '25939',
                        },
                        {
                            lable: 'Phường Thạnh Phước',
                            value: '25936',
                        },
                        {
                            lable: 'Phường Tân Hiệp',
                            value: '25920',
                        },
                        {
                            lable: 'Phường Tân Phước Khánh',
                            value: '25891',
                        },
                        {
                            lable: 'Phường Tân Vĩnh Hiệp',
                            value: '25933',
                        },
                        {
                            lable: 'Phường Uyên Hưng',
                            value: '25888',
                        },
                        {
                            lable: 'Phường Vĩnh Tân',
                            value: '25912',
                        },
                        {
                            lable: 'Xã Bạch Đằng',
                            value: '25930',
                        },
                        {
                            lable: 'Xã Thạnh Hội',
                            value: '25937',
                        },
                    ],
                },
                {
                    lable: 'Thành phố Dĩ An',
                    value: '724',
                    wards: [
                        {
                            lable: 'Phường An Bình',
                            value: '25960',
                        },
                        {
                            lable: 'Phường Bình An',
                            value: '25951',
                        },
                        {
                            lable: 'Phường Bình Thắng',
                            value: '25954',
                        },
                        {
                            lable: 'Phường Dĩ An',
                            value: '25942',
                        },
                        {
                            lable: 'Phường Tân Bình',
                            value: '25945',
                        },
                        {
                            lable: 'Phường Tân Đông Hiệp',
                            value: '25948',
                        },
                        {
                            lable: 'Phường Đông Hòa',
                            value: '25957',
                        },
                    ],
                },
                {
                    lable: 'Thành phố Thuận An',
                    value: '725',
                    wards: [
                        {
                            lable: 'Phường An Phú',
                            value: '25975',
                        },
                        {
                            lable: 'Phường An Thạnh',
                            value: '25963',
                        },
                        {
                            lable: 'Phường Bình Chuẩn',
                            value: '25969',
                        },
                        {
                            lable: 'Phường Bình Hòa',
                            value: '25987',
                        },
                        {
                            lable: 'Phường Bình Nhâm',
                            value: '25984',
                        },
                        {
                            lable: 'Phường Hưng Định',
                            value: '25978',
                        },
                        {
                            lable: 'Phường Lái Thiêu',
                            value: '25966',
                        },
                        {
                            lable: 'Phường Thuận Giao',
                            value: '25972',
                        },
                        {
                            lable: 'Phường Vĩnh Phú',
                            value: '25990',
                        },
                        {
                            lable: 'Xã An Sơn',
                            value: '25981',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bắc Tân Uyên',
                    value: '726',
                    wards: [
                        {
                            lable: 'Thị trấn Tân Bình',
                            value: '25900',
                        },
                        {
                            lable: 'Thị trấn Tân Thành',
                            value: '25906',
                        },
                        {
                            lable: 'Xã Bình Mỹ',
                            value: '25897',
                        },
                        {
                            lable: 'Xã Hiếu Liêm',
                            value: '25908',
                        },
                        {
                            lable: 'Xã Lạc An',
                            value: '25909',
                        },
                        {
                            lable: 'Xã Thường Tân',
                            value: '25927',
                        },
                        {
                            lable: 'Xã Tân Lập',
                            value: '25903',
                        },
                        {
                            lable: 'Xã Tân Mỹ',
                            value: '25918',
                        },
                        {
                            lable: 'Xã Tân Định',
                            value: '25894',
                        },
                        {
                            lable: 'Xã Đất Cuốc',
                            value: '25907',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Bình Phước',
            value: '70',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thị xã Phước Long',
                    value: '688',
                    wards: [
                        {
                            lable: 'Phường Long Phước',
                            value: '25220',
                        },
                        {
                            lable: 'Phường Long Thủy',
                            value: '25217',
                        },
                        {
                            lable: 'Phường Phước Bình',
                            value: '25219',
                        },
                        {
                            lable: 'Phường Sơn Giang',
                            value: '25237',
                        },
                        {
                            lable: 'Phường Thác Mơ',
                            value: '25216',
                        },
                        {
                            lable: 'Xã Long Giang',
                            value: '25245',
                        },
                        {
                            lable: 'Xã Phước Tín',
                            value: '25249',
                        },
                    ],
                },
                {
                    lable: 'Thành phố Đồng Xoài',
                    value: '689',
                    wards: [
                        {
                            lable: 'Phường Tiến Thành',
                            value: '25210',
                        },
                        {
                            lable: 'Phường Tân Bình',
                            value: '25201',
                        },
                        {
                            lable: 'Phường Tân Phú',
                            value: '25195',
                        },
                        {
                            lable: 'Phường Tân Thiện',
                            value: '25205',
                        },
                        {
                            lable: 'Phường Tân Xuân',
                            value: '25204',
                        },
                        {
                            lable: 'Phường Tân Đồng',
                            value: '25198',
                        },
                        {
                            lable: 'Xã Tiến Hưng',
                            value: '25213',
                        },
                        {
                            lable: 'Xã Tân Thành',
                            value: '25207',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Bình Long',
                    value: '690',
                    wards: [
                        {
                            lable: 'Phường An Lộc',
                            value: '25324',
                        },
                        {
                            lable: 'Phường Hưng Chiến',
                            value: '25320',
                        },
                        {
                            lable: 'Phường Phú Thịnh',
                            value: '25325',
                        },
                        {
                            lable: 'Phường Phú Đức',
                            value: '25326',
                        },
                        {
                            lable: 'Xã Thanh Lương',
                            value: '25333',
                        },
                        {
                            lable: 'Xã Thanh Phú',
                            value: '25336',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bù Gia Mập',
                    value: '691',
                    wards: [
                        {
                            lable: 'Xã Bình Thắng',
                            value: '25234',
                        },
                        {
                            lable: 'Xã Bù Gia Mập',
                            value: '25222',
                        },
                        {
                            lable: 'Xã Phú Nghĩa',
                            value: '25267',
                        },
                        {
                            lable: 'Xã Phú Văn',
                            value: '25229',
                        },
                        {
                            lable: 'Xã Phước Minh',
                            value: '25232',
                        },
                        {
                            lable: 'Xã Đa Kia',
                            value: '25231',
                        },
                        {
                            lable: 'Xã Đak Ơ',
                            value: '25225',
                        },
                        {
                            lable: 'Xã Đức Hạnh',
                            value: '25228',
                        },
                    ],
                },
                {
                    lable: 'Huyện Lộc Ninh',
                    value: '692',
                    wards: [
                        {
                            lable: 'Thị trấn Lộc Ninh',
                            value: '25270',
                        },
                        {
                            lable: 'Xã Lộc An',
                            value: '25276',
                        },
                        {
                            lable: 'Xã Lộc Hiệp',
                            value: '25282',
                        },
                        {
                            lable: 'Xã Lộc Hòa',
                            value: '25273',
                        },
                        {
                            lable: 'Xã Lộc Hưng',
                            value: '25303',
                        },
                        {
                            lable: 'Xã Lộc Khánh',
                            value: '25306',
                        },
                        {
                            lable: 'Xã Lộc Phú',
                            value: '25292',
                        },
                        {
                            lable: 'Xã Lộc Quang',
                            value: '25291',
                        },
                        {
                            lable: 'Xã Lộc Thiện',
                            value: '25285',
                        },
                        {
                            lable: 'Xã Lộc Thuận',
                            value: '25288',
                        },
                        {
                            lable: 'Xã Lộc Thành',
                            value: '25294',
                        },
                        {
                            lable: 'Xã Lộc Thái',
                            value: '25297',
                        },
                        {
                            lable: 'Xã Lộc Thạnh',
                            value: '25280',
                        },
                        {
                            lable: 'Xã Lộc Thịnh',
                            value: '25305',
                        },
                        {
                            lable: 'Xã Lộc Tấn',
                            value: '25279',
                        },
                        {
                            lable: 'Xã Lộc Điền',
                            value: '25300',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bù Đốp',
                    value: '693',
                    wards: [
                        {
                            lable: 'Thị trấn Thanh Bình',
                            value: '25308',
                        },
                        {
                            lable: 'Xã Hưng Phước',
                            value: '25309',
                        },
                        {
                            lable: 'Xã Phước Thiện',
                            value: '25310',
                        },
                        {
                            lable: 'Xã Thanh Hòa',
                            value: '25315',
                        },
                        {
                            lable: 'Xã Thiện Hưng',
                            value: '25312',
                        },
                        {
                            lable: 'Xã Tân Thành',
                            value: '25318',
                        },
                        {
                            lable: 'Xã Tân Tiến',
                            value: '25321',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hớn Quản',
                    value: '694',
                    wards: [
                        {
                            lable: 'Thị trấn Tân Khai',
                            value: '25357',
                        },
                        {
                            lable: 'Xã An Khương',
                            value: '25330',
                        },
                        {
                            lable: 'Xã An Phú',
                            value: '25339',
                        },
                        {
                            lable: 'Xã Minh Tâm',
                            value: '25349',
                        },
                        {
                            lable: 'Xã Minh Đức',
                            value: '25348',
                        },
                        {
                            lable: 'Xã Phước An',
                            value: '25351',
                        },
                        {
                            lable: 'Xã Thanh An',
                            value: '25327',
                        },
                        {
                            lable: 'Xã Thanh Bình',
                            value: '25354',
                        },
                        {
                            lable: 'Xã Tân Hiệp',
                            value: '25361',
                        },
                        {
                            lable: 'Xã Tân Hưng',
                            value: '25345',
                        },
                        {
                            lable: 'Xã Tân Lợi',
                            value: '25342',
                        },
                        {
                            lable: 'Xã Tân Quan',
                            value: '25438',
                        },
                        {
                            lable: 'Xã Đồng Nơ',
                            value: '25360',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đồng Phú',
                    value: '695',
                    wards: [
                        {
                            lable: 'Thị trấn Tân Phú',
                            value: '25363',
                        },
                        {
                            lable: 'Xã Thuận Lợi',
                            value: '25366',
                        },
                        {
                            lable: 'Xã Thuận Phú',
                            value: '25387',
                        },
                        {
                            lable: 'Xã Tân Hòa',
                            value: '25384',
                        },
                        {
                            lable: 'Xã Tân Hưng',
                            value: '25375',
                        },
                        {
                            lable: 'Xã Tân Lập',
                            value: '25381',
                        },
                        {
                            lable: 'Xã Tân Lợi',
                            value: '25378',
                        },
                        {
                            lable: 'Xã Tân Phước',
                            value: '25372',
                        },
                        {
                            lable: 'Xã Tân Tiến',
                            value: '25393',
                        },
                        {
                            lable: 'Xã Đồng Tiến',
                            value: '25390',
                        },
                        {
                            lable: 'Xã Đồng Tâm',
                            value: '25369',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bù Đăng',
                    value: '696',
                    wards: [
                        {
                            lable: 'Thị trấn Đức Phong',
                            value: '25396',
                        },
                        {
                            lable: 'Xã Bom Bo',
                            value: '25405',
                        },
                        {
                            lable: 'Xã Bình Minh',
                            value: '25404',
                        },
                        {
                            lable: 'Xã Minh Hưng',
                            value: '25408',
                        },
                        {
                            lable: 'Xã Nghĩa Bình',
                            value: '25424',
                        },
                        {
                            lable: 'Xã Nghĩa Trung',
                            value: '25423',
                        },
                        {
                            lable: 'Xã Phú Sơn',
                            value: '25400',
                        },
                        {
                            lable: 'Xã Phước Sơn',
                            value: '25429',
                        },
                        {
                            lable: 'Xã Thọ Sơn',
                            value: '25402',
                        },
                        {
                            lable: 'Xã Thống Nhất',
                            value: '25420',
                        },
                        {
                            lable: 'Xã Đak Nhau',
                            value: '25399',
                        },
                        {
                            lable: 'Xã Đoàn Kết',
                            value: '25411',
                        },
                        {
                            lable: 'Xã Đăng Hà',
                            value: '25426',
                        },
                        {
                            lable: 'Xã Đường 10',
                            value: '25398',
                        },
                        {
                            lable: 'Xã Đồng Nai',
                            value: '25414',
                        },
                        {
                            lable: 'Xã Đức Liễu',
                            value: '25417',
                        },
                    ],
                },
                {
                    lable: 'Huyện Chơn Thành',
                    value: '697',
                    wards: [
                        {
                            lable: 'Thị trấn Chơn Thành',
                            value: '25432',
                        },
                        {
                            lable: 'Xã Minh Hưng',
                            value: '25441',
                        },
                        {
                            lable: 'Xã Minh Long',
                            value: '25444',
                        },
                        {
                            lable: 'Xã Minh Lập',
                            value: '25435',
                        },
                        {
                            lable: 'Xã Minh Thành',
                            value: '25447',
                        },
                        {
                            lable: 'Xã Minh Thắng',
                            value: '25453',
                        },
                        {
                            lable: 'Xã Nha Bích',
                            value: '25450',
                        },
                        {
                            lable: 'Xã Quang Minh',
                            value: '25439',
                        },
                        {
                            lable: 'Xã Thành Tâm',
                            value: '25433',
                        },
                    ],
                },
                {
                    lable: 'Huyện Phú Riềng',
                    value: '698',
                    wards: [
                        {
                            lable: 'Xã Bình Sơn',
                            value: '25244',
                        },
                        {
                            lable: 'Xã Bình Tân',
                            value: '25243',
                        },
                        {
                            lable: 'Xã Bù Nho',
                            value: '25252',
                        },
                        {
                            lable: 'Xã Long Bình',
                            value: '25240',
                        },
                        {
                            lable: 'Xã Long Hà',
                            value: '25255',
                        },
                        {
                            lable: 'Xã Long Hưng',
                            value: '25246',
                        },
                        {
                            lable: 'Xã Long Tân',
                            value: '25258',
                        },
                        {
                            lable: 'Xã Phú Riềng',
                            value: '25264',
                        },
                        {
                            lable: 'Xã Phú Trung',
                            value: '25261',
                        },
                        {
                            lable: 'Xã Phước Tân',
                            value: '25250',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Bình Thuận',
            value: '60',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Phan Thiết',
                    value: '593',
                    wards: [
                        {
                            lable: 'Phường Bình Hưng',
                            value: '22939',
                        },
                        {
                            lable: 'Phường Hàm Tiến',
                            value: '22918',
                        },
                        {
                            lable: 'Phường Hưng Long',
                            value: '22951',
                        },
                        {
                            lable: 'Phường Lạc Đạo',
                            value: '22945',
                        },
                        {
                            lable: 'Phường Mũi Né',
                            value: '22915',
                        },
                        {
                            lable: 'Phường Phú Hài',
                            value: '22921',
                        },
                        {
                            lable: 'Phường Phú Thủy',
                            value: '22924',
                        },
                        {
                            lable: 'Phường Phú Trinh',
                            value: '22930',
                        },
                        {
                            lable: 'Phường Phú Tài',
                            value: '22927',
                        },
                        {
                            lable: 'Phường Thanh Hải',
                            value: '22936',
                        },
                        {
                            lable: 'Phường Xuân An',
                            value: '22933',
                        },
                        {
                            lable: 'Phường Đức Long',
                            value: '22954',
                        },
                        {
                            lable: 'Phường Đức Nghĩa',
                            value: '22942',
                        },
                        {
                            lable: 'Phường Đức Thắng',
                            value: '22948',
                        },
                        {
                            lable: 'Xã Phong Nẫm',
                            value: '22960',
                        },
                        {
                            lable: 'Xã Thiện Nghiệp',
                            value: '22957',
                        },
                        {
                            lable: 'Xã Tiến Lợi',
                            value: '22963',
                        },
                        {
                            lable: 'Xã Tiến Thành',
                            value: '22966',
                        },
                    ],
                },
                {
                    lable: 'Thị xã La Gi',
                    value: '594',
                    wards: [
                        {
                            lable: 'Phường Bình Tân',
                            value: '23237',
                        },
                        {
                            lable: 'Phường Phước Hội',
                            value: '23231',
                        },
                        {
                            lable: 'Phường Phước Lộc',
                            value: '23232',
                        },
                        {
                            lable: 'Phường Tân An',
                            value: '23235',
                        },
                        {
                            lable: 'Phường Tân Thiện',
                            value: '23234',
                        },
                        {
                            lable: 'Xã Tân Bình',
                            value: '23248',
                        },
                        {
                            lable: 'Xã Tân Hải',
                            value: '23245',
                        },
                        {
                            lable: 'Xã Tân Phước',
                            value: '23268',
                        },
                        {
                            lable: 'Xã Tân Tiến',
                            value: '23246',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tuy Phong',
                    value: '595',
                    wards: [
                        {
                            lable: 'Thị trấn Liên Hương',
                            value: '22969',
                        },
                        {
                            lable: 'Thị trấn Phan Rí Cửa',
                            value: '22972',
                        },
                        {
                            lable: 'Xã Bình Thạnh',
                            value: '22999',
                        },
                        {
                            lable: 'Xã Chí Công',
                            value: '22996',
                        },
                        {
                            lable: 'Xã Hòa Minh',
                            value: '22993',
                        },
                        {
                            lable: 'Xã Phan Dũng',
                            value: '22975',
                        },
                        {
                            lable: 'Xã Phong Phú',
                            value: '22978',
                        },
                        {
                            lable: 'Xã Phú Lạc',
                            value: '22987',
                        },
                        {
                            lable: 'Xã Phước Thể',
                            value: '22990',
                        },
                        {
                            lable: 'Xã Vĩnh Hảo',
                            value: '22981',
                        },
                        {
                            lable: 'Xã Vĩnh Tân',
                            value: '22984',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bắc Bình',
                    value: '596',
                    wards: [
                        {
                            lable: 'Thị trấn Chợ Lầu',
                            value: '23005',
                        },
                        {
                            lable: 'Thị trấn Lương Sơn',
                            value: '23032',
                        },
                        {
                            lable: 'Xã Bình An',
                            value: '23014',
                        },
                        {
                            lable: 'Xã Bình Tân',
                            value: '23047',
                        },
                        {
                            lable: 'Xã Hòa Thắng',
                            value: '23053',
                        },
                        {
                            lable: 'Xã Hải Ninh',
                            value: '23020',
                        },
                        {
                            lable: 'Xã Hồng Phong',
                            value: '23056',
                        },
                        {
                            lable: 'Xã Hồng Thái',
                            value: '23041',
                        },
                        {
                            lable: 'Xã Phan Hiệp',
                            value: '23044',
                        },
                        {
                            lable: 'Xã Phan Hòa',
                            value: '23035',
                        },
                        {
                            lable: 'Xã Phan Lâm',
                            value: '23011',
                        },
                        {
                            lable: 'Xã Phan Rí Thành',
                            value: '23050',
                        },
                        {
                            lable: 'Xã Phan Sơn',
                            value: '23008',
                        },
                        {
                            lable: 'Xã Phan Thanh',
                            value: '23038',
                        },
                        {
                            lable: 'Xã Phan Tiến',
                            value: '23026',
                        },
                        {
                            lable: 'Xã Phan Điền',
                            value: '23017',
                        },
                        {
                            lable: 'Xã Sông Bình',
                            value: '23029',
                        },
                        {
                            lable: 'Xã Sông Lũy',
                            value: '23023',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hàm Thuận Bắc',
                    value: '597',
                    wards: [
                        {
                            lable: 'Thị trấn Ma Lâm',
                            value: '23059',
                        },
                        {
                            lable: 'Thị trấn Phú Long',
                            value: '23062',
                        },
                        {
                            lable: 'Xã Hàm Chính',
                            value: '23098',
                        },
                        {
                            lable: 'Xã Hàm Hiệp',
                            value: '23101',
                        },
                        {
                            lable: 'Xã Hàm Liêm',
                            value: '23095',
                        },
                        {
                            lable: 'Xã Hàm Phú',
                            value: '23077',
                        },
                        {
                            lable: 'Xã Hàm Thắng',
                            value: '23104',
                        },
                        {
                            lable: 'Xã Hàm Trí',
                            value: '23089',
                        },
                        {
                            lable: 'Xã Hàm Đức',
                            value: '23092',
                        },
                        {
                            lable: 'Xã Hồng Liêm',
                            value: '23080',
                        },
                        {
                            lable: 'Xã Hồng Sơn',
                            value: '23086',
                        },
                        {
                            lable: 'Xã La Dạ',
                            value: '23065',
                        },
                        {
                            lable: 'Xã Thuận Hòa',
                            value: '23071',
                        },
                        {
                            lable: 'Xã Thuận Minh',
                            value: '23083',
                        },
                        {
                            lable: 'Xã Đa Mi',
                            value: '23107',
                        },
                        {
                            lable: 'Xã Đông Giang',
                            value: '23074',
                        },
                        {
                            lable: 'Xã Đông Tiến',
                            value: '23068',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hàm Thuận Nam',
                    value: '598',
                    wards: [
                        {
                            lable: 'Thị trấn Thuận Nam',
                            value: '23110',
                        },
                        {
                            lable: 'Xã Hàm Cường',
                            value: '23128',
                        },
                        {
                            lable: 'Xã Hàm Cần',
                            value: '23116',
                        },
                        {
                            lable: 'Xã Hàm Kiệm',
                            value: '23125',
                        },
                        {
                            lable: 'Xã Hàm Minh',
                            value: '23137',
                        },
                        {
                            lable: 'Xã Hàm Mỹ',
                            value: '23131',
                        },
                        {
                            lable: 'Xã Hàm Thạnh',
                            value: '23122',
                        },
                        {
                            lable: 'Xã Mương Mán',
                            value: '23119',
                        },
                        {
                            lable: 'Xã Mỹ Thạnh',
                            value: '23113',
                        },
                        {
                            lable: 'Xã Thuận Quí',
                            value: '23140',
                        },
                        {
                            lable: 'Xã Tân Lập',
                            value: '23134',
                        },
                        {
                            lable: 'Xã Tân Thuận',
                            value: '23143',
                        },
                        {
                            lable: 'Xã Tân Thành',
                            value: '23146',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tánh Linh',
                    value: '599',
                    wards: [
                        {
                            lable: 'Thị trấn Lạc Tánh',
                            value: '23149',
                        },
                        {
                            lable: 'Xã Bắc Ruộng',
                            value: '23152',
                        },
                        {
                            lable: 'Xã Gia An',
                            value: '23176',
                        },
                        {
                            lable: 'Xã Gia Huynh',
                            value: '23182',
                        },
                        {
                            lable: 'Xã Huy Khiêm',
                            value: '23164',
                        },
                        {
                            lable: 'Xã La Ngâu',
                            value: '23161',
                        },
                        {
                            lable: 'Xã Măng Tố',
                            value: '23167',
                        },
                        {
                            lable: 'Xã Nghị Đức',
                            value: '23158',
                        },
                        {
                            lable: 'Xã Suối Kiết',
                            value: '23188',
                        },
                        {
                            lable: 'Xã Đồng Kho',
                            value: '23173',
                        },
                        {
                            lable: 'Xã Đức Bình',
                            value: '23179',
                        },
                        {
                            lable: 'Xã Đức Phú',
                            value: '23170',
                        },
                        {
                            lable: 'Xã Đức Thuận',
                            value: '23185',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đức Linh',
                    value: '600',
                    wards: [
                        {
                            lable: 'Thị trấn Võ Xu',
                            value: '23191',
                        },
                        {
                            lable: 'Thị trấn Đức Tài',
                            value: '23194',
                        },
                        {
                            lable: 'Xã Mê Pu',
                            value: '23203',
                        },
                        {
                            lable: 'Xã Nam Chính',
                            value: '23206',
                        },
                        {
                            lable: 'Xã Sùng Nhơn',
                            value: '23200',
                        },
                        {
                            lable: 'Xã Trà Tân',
                            value: '23227',
                        },
                        {
                            lable: 'Xã Tân Hà',
                            value: '23221',
                        },
                        {
                            lable: 'Xã Vũ Hoà',
                            value: '23218',
                        },
                        {
                            lable: 'Xã Đa Kai',
                            value: '23197',
                        },
                        {
                            lable: 'Xã Đông Hà',
                            value: '23224',
                        },
                        {
                            lable: 'Xã Đức Hạnh',
                            value: '23212',
                        },
                        {
                            lable: 'Xã Đức Tín',
                            value: '23215',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hàm Tân',
                    value: '601',
                    wards: [
                        {
                            lable: 'Thị trấn Tân Minh',
                            value: '23230',
                        },
                        {
                            lable: 'Thị trấn Tân Nghĩa',
                            value: '23236',
                        },
                        {
                            lable: 'Xã Sông Phan',
                            value: '23239',
                        },
                        {
                            lable: 'Xã Sơn Mỹ',
                            value: '23266',
                        },
                        {
                            lable: 'Xã Thắng Hải',
                            value: '23255',
                        },
                        {
                            lable: 'Xã Tân Hà',
                            value: '23257',
                        },
                        {
                            lable: 'Xã Tân Phúc',
                            value: '23242',
                        },
                        {
                            lable: 'Xã Tân Thắng',
                            value: '23254',
                        },
                        {
                            lable: 'Xã Tân Xuân',
                            value: '23260',
                        },
                        {
                            lable: 'Xã Tân Đức',
                            value: '23251',
                        },
                    ],
                },
                {
                    lable: 'Huyện Phú Quí',
                    value: '602',
                    wards: [
                        {
                            lable: 'Xã Long Hải',
                            value: '23275',
                        },
                        {
                            lable: 'Xã Ngũ Phụng',
                            value: '23272',
                        },
                        {
                            lable: 'Xã Tam Thanh',
                            value: '23278',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Cà Mau',
            value: '96',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Cà Mau',
                    value: '964',
                    wards: [
                        {
                            lable: 'Phường 1',
                            value: '32005',
                        },
                        {
                            lable: 'Phường 2',
                            value: '32011',
                        },
                        {
                            lable: 'Phường 4',
                            value: '32002',
                        },
                        {
                            lable: 'Phường 5',
                            value: '32008',
                        },
                        {
                            lable: 'Phường 6',
                            value: '32017',
                        },
                        {
                            lable: 'Phường 7',
                            value: '32020',
                        },
                        {
                            lable: 'Phường 8',
                            value: '32014',
                        },
                        {
                            lable: 'Phường 9',
                            value: '31999',
                        },
                        {
                            lable: 'Phường Tân Thành',
                            value: '32025',
                        },
                        {
                            lable: 'Phường Tân Xuyên',
                            value: '32022',
                        },
                        {
                            lable: 'Xã An Xuyên',
                            value: '32023',
                        },
                        {
                            lable: 'Xã Hòa Thành',
                            value: '32038',
                        },
                        {
                            lable: 'Xã Hòa Tân',
                            value: '32041',
                        },
                        {
                            lable: 'Xã Lý Văn Lâm',
                            value: '32032',
                        },
                        {
                            lable: 'Xã Tân Thành',
                            value: '32026',
                        },
                        {
                            lable: 'Xã Tắc Vân',
                            value: '32029',
                        },
                        {
                            lable: 'Xã Định Bình',
                            value: '32035',
                        },
                    ],
                },
                {
                    lable: 'Huyện U Minh',
                    value: '966',
                    wards: [
                        {
                            lable: 'Thị trấn U Minh',
                            value: '32044',
                        },
                        {
                            lable: 'Xã Khánh An',
                            value: '32059',
                        },
                        {
                            lable: 'Xã Khánh Hòa',
                            value: '32047',
                        },
                        {
                            lable: 'Xã Khánh Hội',
                            value: '32062',
                        },
                        {
                            lable: 'Xã Khánh Lâm',
                            value: '32056',
                        },
                        {
                            lable: 'Xã Khánh Thuận',
                            value: '32048',
                        },
                        {
                            lable: 'Xã Khánh Tiến',
                            value: '32050',
                        },
                        {
                            lable: 'Xã Nguyễn Phích',
                            value: '32053',
                        },
                    ],
                },
                {
                    lable: 'Huyện Thới Bình',
                    value: '967',
                    wards: [
                        {
                            lable: 'Thị trấn Thới Bình',
                            value: '32065',
                        },
                        {
                            lable: 'Xã Biển Bạch',
                            value: '32068',
                        },
                        {
                            lable: 'Xã Biển Bạch Đông',
                            value: '32074',
                        },
                        {
                            lable: 'Xã Hồ Thị Kỷ',
                            value: '32092',
                        },
                        {
                            lable: 'Xã Thới Bình',
                            value: '32077',
                        },
                        {
                            lable: 'Xã Trí Lực',
                            value: '32072',
                        },
                        {
                            lable: 'Xã Trí Phải',
                            value: '32071',
                        },
                        {
                            lable: 'Xã Tân Bằng',
                            value: '32069',
                        },
                        {
                            lable: 'Xã Tân Lộc',
                            value: '32086',
                        },
                        {
                            lable: 'Xã Tân Lộc Bắc',
                            value: '32083',
                        },
                        {
                            lable: 'Xã Tân Lộc Đông',
                            value: '32089',
                        },
                        {
                            lable: 'Xã Tân Phú',
                            value: '32080',
                        },
                    ],
                },
                {
                    lable: 'Huyện Trần Văn Thời',
                    value: '968',
                    wards: [
                        {
                            lable: 'Thị trấn Sông Đốc',
                            value: '32098',
                        },
                        {
                            lable: 'Thị trấn Trần Văn Thời',
                            value: '32095',
                        },
                        {
                            lable: 'Xã Khánh Bình',
                            value: '32110',
                        },
                        {
                            lable: 'Xã Khánh Bình Tây',
                            value: '32104',
                        },
                        {
                            lable: 'Xã Khánh Bình Tây Bắc',
                            value: '32101',
                        },
                        {
                            lable: 'Xã Khánh Bình Đông',
                            value: '32116',
                        },
                        {
                            lable: 'Xã Khánh Hưng',
                            value: '32113',
                        },
                        {
                            lable: 'Xã Khánh Hải',
                            value: '32119',
                        },
                        {
                            lable: 'Xã Khánh Lộc',
                            value: '32108',
                        },
                        {
                            lable: 'Xã Lợi An',
                            value: '32122',
                        },
                        {
                            lable: 'Xã Phong Lạc',
                            value: '32125',
                        },
                        {
                            lable: 'Xã Phong Điền',
                            value: '32124',
                        },
                        {
                            lable: 'Xã Trần Hợi',
                            value: '32107',
                        },
                    ],
                },
                {
                    lable: 'Huyện Cái Nước',
                    value: '969',
                    wards: [
                        {
                            lable: 'Thị trấn Cái Nước',
                            value: '32128',
                        },
                        {
                            lable: 'Xã Hoà Mỹ',
                            value: '32141',
                        },
                        {
                            lable: 'Xã Hưng Mỹ',
                            value: '32140',
                        },
                        {
                            lable: 'Xã Lương Thế Trân',
                            value: '32131',
                        },
                        {
                            lable: 'Xã Phú Hưng',
                            value: '32134',
                        },
                        {
                            lable: 'Xã Thạnh Phú',
                            value: '32130',
                        },
                        {
                            lable: 'Xã Trần Thới',
                            value: '32149',
                        },
                        {
                            lable: 'Xã Tân Hưng',
                            value: '32137',
                        },
                        {
                            lable: 'Xã Tân Hưng Đông',
                            value: '32146',
                        },
                        {
                            lable: 'Xã Đông Hưng',
                            value: '32142',
                        },
                        {
                            lable: 'Xã Đông Thới',
                            value: '32143',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đầm Dơi',
                    value: '970',
                    wards: [
                        {
                            lable: 'Thị trấn Đầm Dơi',
                            value: '32152',
                        },
                        {
                            lable: 'Xã Nguyễn Huân',
                            value: '32188',
                        },
                        {
                            lable: 'Xã Ngọc Chánh',
                            value: '32186',
                        },
                        {
                            lable: 'Xã Quách Phẩm',
                            value: '32182',
                        },
                        {
                            lable: 'Xã Quách Phẩm Bắc',
                            value: '32179',
                        },
                        {
                            lable: 'Xã Thanh Tùng',
                            value: '32185',
                        },
                        {
                            lable: 'Xã Trần Phán',
                            value: '32161',
                        },
                        {
                            lable: 'Xã Tân Duyệt',
                            value: '32173',
                        },
                        {
                            lable: 'Xã Tân Dân',
                            value: '32174',
                        },
                        {
                            lable: 'Xã Tân Thuận',
                            value: '32167',
                        },
                        {
                            lable: 'Xã Tân Tiến',
                            value: '32176',
                        },
                        {
                            lable: 'Xã Tân Trung',
                            value: '32162',
                        },
                        {
                            lable: 'Xã Tân Đức',
                            value: '32164',
                        },
                        {
                            lable: 'Xã Tạ An Khương',
                            value: '32155',
                        },
                        {
                            lable: 'Xã Tạ An Khương  Nam',
                            value: '32170',
                        },
                        {
                            lable: 'Xã Tạ An Khương  Đông',
                            value: '32158',
                        },
                    ],
                },
                {
                    lable: 'Huyện Năm Căn',
                    value: '971',
                    wards: [
                        {
                            lable: 'Thị Trấn Năm Căn',
                            value: '32191',
                        },
                        {
                            lable: 'Xã Hiệp Tùng',
                            value: '32197',
                        },
                        {
                            lable: 'Xã Hàm Rồng',
                            value: '32194',
                        },
                        {
                            lable: 'Xã Hàng Vịnh',
                            value: '32203',
                        },
                        {
                            lable: 'Xã Lâm Hải',
                            value: '32201',
                        },
                        {
                            lable: 'Xã Tam Giang',
                            value: '32206',
                        },
                        {
                            lable: 'Xã Tam Giang Đông',
                            value: '32209',
                        },
                        {
                            lable: 'Xã Đất Mới',
                            value: '32200',
                        },
                    ],
                },
                {
                    lable: 'Huyện Phú Tân',
                    value: '972',
                    wards: [
                        {
                            lable: 'Thị trấn Cái Đôi Vàm',
                            value: '32212',
                        },
                        {
                            lable: 'Xã Nguyễn Việt Khái',
                            value: '32230',
                        },
                        {
                            lable: 'Xã Phú Mỹ',
                            value: '32215',
                        },
                        {
                            lable: 'Xã Phú Thuận',
                            value: '32214',
                        },
                        {
                            lable: 'Xã Phú Tân',
                            value: '32218',
                        },
                        {
                            lable: 'Xã Rạch Chèo',
                            value: '32228',
                        },
                        {
                            lable: 'Xã Tân Hưng Tây',
                            value: '32227',
                        },
                        {
                            lable: 'Xã Tân Hải',
                            value: '32221',
                        },
                        {
                            lable: 'Xã Việt Thắng',
                            value: '32224',
                        },
                    ],
                },
                {
                    lable: 'Huyện Ngọc Hiển',
                    value: '973',
                    wards: [
                        {
                            lable: 'Thị trấn Rạch Gốc',
                            value: '32244',
                        },
                        {
                            lable: 'Xã Tam Giang Tây',
                            value: '32233',
                        },
                        {
                            lable: 'Xã Tân Ân',
                            value: '32245',
                        },
                        {
                            lable: 'Xã Tân Ân Tây',
                            value: '32236',
                        },
                        {
                            lable: 'Xã Viên An',
                            value: '32242',
                        },
                        {
                            lable: 'Xã Viên An Đông',
                            value: '32239',
                        },
                        {
                            lable: 'Xã Đất Mũi',
                            value: '32248',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Cao Bằng',
            value: '04',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Cao Bằng',
                    value: '040',
                    wards: [
                        {
                            lable: 'Phường Duyệt Trung',
                            value: '01288',
                        },
                        {
                            lable: 'Phường Hoà Chung',
                            value: '01285',
                        },
                        {
                            lable: 'Phường Hợp Giang',
                            value: '01273',
                        },
                        {
                            lable: 'Phường Ngọc Xuân',
                            value: '01279',
                        },
                        {
                            lable: 'Phường Sông Bằng',
                            value: '01270',
                        },
                        {
                            lable: 'Phường Sông Hiến',
                            value: '01267',
                        },
                        {
                            lable: 'Phường Tân Giang',
                            value: '01276',
                        },
                        {
                            lable: 'Phường Đề Thám',
                            value: '01282',
                        },
                        {
                            lable: 'Xã Chu Trinh',
                            value: '01720',
                        },
                        {
                            lable: 'Xã Hưng Đạo',
                            value: '01705',
                        },
                        {
                            lable: 'Xã Vĩnh Quang',
                            value: '01693',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bảo Lâm',
                    value: '042',
                    wards: [
                        {
                            lable: 'Thị trấn Pác Miầu',
                            value: '01290',
                        },
                        {
                            lable: 'Xã Lý Bôn',
                            value: '01294',
                        },
                        {
                            lable: 'Xã Mông Ân',
                            value: '01312',
                        },
                        {
                            lable: 'Xã Nam Cao',
                            value: '01296',
                        },
                        {
                            lable: 'Xã Nam Quang',
                            value: '01297',
                        },
                        {
                            lable: 'Xã Quảng Lâm',
                            value: '01303',
                        },
                        {
                            lable: 'Xã Thái Học',
                            value: '01315',
                        },
                        {
                            lable: 'Xã Thái Sơn',
                            value: '01316',
                        },
                        {
                            lable: 'Xã Thạch Lâm',
                            value: '01304',
                        },
                        {
                            lable: 'Xã Vĩnh Phong',
                            value: '01309',
                        },
                        {
                            lable: 'Xã Vĩnh Quang',
                            value: '01300',
                        },
                        {
                            lable: 'Xã Yên Thổ',
                            value: '01318',
                        },
                        {
                            lable: 'Xã Đức Hạnh',
                            value: '01291',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bảo Lạc',
                    value: '043',
                    wards: [
                        {
                            lable: 'Thị trấn Bảo Lạc',
                            value: '01321',
                        },
                        {
                            lable: 'Xã Bảo Toàn',
                            value: '01333',
                        },
                        {
                            lable: 'Xã Cô Ba',
                            value: '01330',
                        },
                        {
                            lable: 'Xã Cốc Pàng',
                            value: '01324',
                        },
                        {
                            lable: 'Xã Huy Giáp',
                            value: '01354',
                        },
                        {
                            lable: 'Xã Hưng Thịnh',
                            value: '01352',
                        },
                        {
                            lable: 'Xã Hưng Đạo',
                            value: '01351',
                        },
                        {
                            lable: 'Xã Hồng An',
                            value: '01348',
                        },
                        {
                            lable: 'Xã Hồng Trị',
                            value: '01342',
                        },
                        {
                            lable: 'Xã Khánh Xuân',
                            value: '01336',
                        },
                        {
                            lable: 'Xã Kim Cúc',
                            value: '01343',
                        },
                        {
                            lable: 'Xã Phan Thanh',
                            value: '01345',
                        },
                        {
                            lable: 'Xã Sơn Lập',
                            value: '01359',
                        },
                        {
                            lable: 'Xã Sơn Lộ',
                            value: '01360',
                        },
                        {
                            lable: 'Xã Thượng Hà',
                            value: '01327',
                        },
                        {
                            lable: 'Xã Xuân Trường',
                            value: '01339',
                        },
                        {
                            lable: 'Xã Đình Phùng',
                            value: '01357',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hà Quảng',
                    value: '045',
                    wards: [
                        {
                            lable: 'Thị trấn Thông Nông',
                            value: '01363',
                        },
                        {
                            lable: 'Thị trấn Xuân Hòa',
                            value: '01392',
                        },
                        {
                            lable: 'Xã Cải Viên',
                            value: '01402',
                        },
                        {
                            lable: 'Xã Cần Nông',
                            value: '01367',
                        },
                        {
                            lable: 'Xã Cần Yên',
                            value: '01366',
                        },
                        {
                            lable: 'Xã Hồng Sỹ',
                            value: '01429',
                        },
                        {
                            lable: 'Xã Lũng Nặm',
                            value: '01393',
                        },
                        {
                            lable: 'Xã Lương Can',
                            value: '01384',
                        },
                        {
                            lable: 'Xã Lương Thông',
                            value: '01372',
                        },
                        {
                            lable: 'Xã Mã Ba',
                            value: '01435',
                        },
                        {
                            lable: 'Xã Ngọc Đào',
                            value: '01438',
                        },
                        {
                            lable: 'Xã Ngọc Động',
                            value: '01378',
                        },
                        {
                            lable: 'Xã Nội Thôn',
                            value: '01411',
                        },
                        {
                            lable: 'Xã Quý Quân',
                            value: '01432',
                        },
                        {
                            lable: 'Xã Sóc Hà',
                            value: '01417',
                        },
                        {
                            lable: 'Xã Thanh Long',
                            value: '01387',
                        },
                        {
                            lable: 'Xã Thượng Thôn',
                            value: '01420',
                        },
                        {
                            lable: 'Xã Trường Hà',
                            value: '01399',
                        },
                        {
                            lable: 'Xã Tổng Cọt',
                            value: '01414',
                        },
                        {
                            lable: 'Xã Yên Sơn',
                            value: '01381',
                        },
                        {
                            lable: 'Xã Đa Thông',
                            value: '01375',
                        },
                    ],
                },
                {
                    lable: 'Huyện Trùng Khánh',
                    value: '047',
                    wards: [
                        {
                            lable: 'Thị trấn Trà Lĩnh',
                            value: '01447',
                        },
                        {
                            lable: 'Thị trấn Trùng Khánh',
                            value: '01477',
                        },
                        {
                            lable: 'Xã Cao Chương',
                            value: '01471',
                        },
                        {
                            lable: 'Xã Cao Thăng',
                            value: '01519',
                        },
                        {
                            lable: 'Xã Chí Viễn',
                            value: '01501',
                        },
                        {
                            lable: 'Xã Khâm Thành',
                            value: '01498',
                        },
                        {
                            lable: 'Xã Lăng Hiếu',
                            value: '01504',
                        },
                        {
                            lable: 'Xã Ngọc Côn',
                            value: '01481',
                        },
                        {
                            lable: 'Xã Ngọc Khê',
                            value: '01480',
                        },
                        {
                            lable: 'Xã Phong Châu',
                            value: '01507',
                        },
                        {
                            lable: 'Xã Phong Nậm',
                            value: '01483',
                        },
                        {
                            lable: 'Xã Quang Hán',
                            value: '01456',
                        },
                        {
                            lable: 'Xã Quang Trung',
                            value: '01465',
                        },
                        {
                            lable: 'Xã Quang Vinh',
                            value: '01468',
                        },
                        {
                            lable: 'Xã Tri Phương',
                            value: '01453',
                        },
                        {
                            lable: 'Xã Trung Phúc',
                            value: '01516',
                        },
                        {
                            lable: 'Xã Xuân Nội',
                            value: '01462',
                        },
                        {
                            lable: 'Xã Đoài Dương',
                            value: '01525',
                        },
                        {
                            lable: 'Xã Đàm Thuỷ',
                            value: '01495',
                        },
                        {
                            lable: 'Xã Đình Phong',
                            value: '01489',
                        },
                        {
                            lable: 'Xã Đức Hồng',
                            value: '01522',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hạ Lang',
                    value: '048',
                    wards: [
                        {
                            lable: 'Thị trấn Thanh Nhật',
                            value: '01558',
                        },
                        {
                            lable: 'Xã An Lạc',
                            value: '01555',
                        },
                        {
                            lable: 'Xã Cô Ngân',
                            value: '01567',
                        },
                        {
                            lable: 'Xã Kim Loan',
                            value: '01549',
                        },
                        {
                            lable: 'Xã Lý Quốc',
                            value: '01537',
                        },
                        {
                            lable: 'Xã Minh Long',
                            value: '01534',
                        },
                        {
                            lable: 'Xã Quang Long',
                            value: '01552',
                        },
                        {
                            lable: 'Xã Thắng Lợi',
                            value: '01540',
                        },
                        {
                            lable: 'Xã Thị Hoa',
                            value: '01573',
                        },
                        {
                            lable: 'Xã Thống Nhất',
                            value: '01564',
                        },
                        {
                            lable: 'Xã Vinh Quý',
                            value: '01561',
                        },
                        {
                            lable: 'Xã Đồng Loan',
                            value: '01543',
                        },
                        {
                            lable: 'Xã Đức Quang',
                            value: '01546',
                        },
                    ],
                },
                {
                    lable: 'Huyện Quảng Hòa',
                    value: '049',
                    wards: [
                        {
                            lable: 'Thị trấn Hoà Thuận',
                            value: '01648',
                        },
                        {
                            lable: 'Thị trấn Quảng Uyên',
                            value: '01576',
                        },
                        {
                            lable: 'Thị trấn Tà Lùng',
                            value: '01627',
                        },
                        {
                            lable: 'Xã Bế Văn Đàn',
                            value: '01630',
                        },
                        {
                            lable: 'Xã Cai Bộ',
                            value: '01597',
                        },
                        {
                            lable: 'Xã Chí Thảo',
                            value: '01606',
                        },
                        {
                            lable: 'Xã Cách Linh',
                            value: '01636',
                        },
                        {
                            lable: 'Xã Hạnh Phúc',
                            value: '01624',
                        },
                        {
                            lable: 'Xã Hồng Quang',
                            value: '01615',
                        },
                        {
                            lable: 'Xã Mỹ Hưng',
                            value: '01651',
                        },
                        {
                            lable: 'Xã Ngọc Động',
                            value: '01618',
                        },
                        {
                            lable: 'Xã Phi Hải',
                            value: '01579',
                        },
                        {
                            lable: 'Xã Phúc Sen',
                            value: '01603',
                        },
                        {
                            lable: 'Xã Quảng Hưng',
                            value: '01582',
                        },
                        {
                            lable: 'Xã Quốc Toản',
                            value: '01474',
                        },
                        {
                            lable: 'Xã Tiên Thành',
                            value: '01645',
                        },
                        {
                            lable: 'Xã Tự Do',
                            value: '01609',
                        },
                        {
                            lable: 'Xã Đại Sơn',
                            value: '01639',
                        },
                        {
                            lable: 'Xã Độc Lập',
                            value: '01594',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hoà An',
                    value: '051',
                    wards: [
                        {
                            lable: 'Thị trấn Nước Hai',
                            value: '01654',
                        },
                        {
                            lable: 'Xã Bình Dương',
                            value: '01711',
                        },
                        {
                            lable: 'Xã Bạch Đằng',
                            value: '01708',
                        },
                        {
                            lable: 'Xã Dân Chủ',
                            value: '01657',
                        },
                        {
                            lable: 'Xã Hoàng Tung',
                            value: '01696',
                        },
                        {
                            lable: 'Xã Hồng Nam',
                            value: '01723',
                        },
                        {
                            lable: 'Xã Hồng Việt',
                            value: '01687',
                        },
                        {
                            lable: 'Xã Lê Chung',
                            value: '01714',
                        },
                        {
                            lable: 'Xã Nam Tuấn',
                            value: '01660',
                        },
                        {
                            lable: 'Xã Nguyễn Huệ',
                            value: '01699',
                        },
                        {
                            lable: 'Xã Ngũ Lão',
                            value: '01672',
                        },
                        {
                            lable: 'Xã Quang Trung',
                            value: '01702',
                        },
                        {
                            lable: 'Xã Trương Lương',
                            value: '01675',
                        },
                        {
                            lable: 'Xã Đại Tiến',
                            value: '01666',
                        },
                        {
                            lable: 'Xã Đức Long',
                            value: '01669',
                        },
                    ],
                },
                {
                    lable: 'Huyện Nguyên Bình',
                    value: '052',
                    wards: [
                        {
                            lable: 'Thị trấn Nguyên Bình',
                            value: '01726',
                        },
                        {
                            lable: 'Thị trấn Tĩnh Túc',
                            value: '01729',
                        },
                        {
                            lable: 'Xã Ca Thành',
                            value: '01738',
                        },
                        {
                            lable: 'Xã Hoa Thám',
                            value: '01765',
                        },
                        {
                            lable: 'Xã Hưng Đạo',
                            value: '01783',
                        },
                        {
                            lable: 'Xã Mai Long',
                            value: '01756',
                        },
                        {
                            lable: 'Xã Minh Tâm',
                            value: '01747',
                        },
                        {
                            lable: 'Xã Phan Thanh',
                            value: '01768',
                        },
                        {
                            lable: 'Xã Quang Thành',
                            value: '01771',
                        },
                        {
                            lable: 'Xã Tam Kim',
                            value: '01774',
                        },
                        {
                            lable: 'Xã Thành Công',
                            value: '01777',
                        },
                        {
                            lable: 'Xã Thể Dục',
                            value: '01750',
                        },
                        {
                            lable: 'Xã Thịnh Vượng',
                            value: '01780',
                        },
                        {
                            lable: 'Xã Triệu Nguyên',
                            value: '01735',
                        },
                        {
                            lable: 'Xã Vũ Minh',
                            value: '01762',
                        },
                        {
                            lable: 'Xã Vũ Nông',
                            value: '01744',
                        },
                        {
                            lable: 'Xã Yên Lạc',
                            value: '01732',
                        },
                    ],
                },
                {
                    lable: 'Huyện Thạch An',
                    value: '053',
                    wards: [
                        {
                            lable: 'Thị trấn Đông Khê',
                            value: '01786',
                        },
                        {
                            lable: 'Xã Canh Tân',
                            value: '01789',
                        },
                        {
                            lable: 'Xã Kim Đồng',
                            value: '01792',
                        },
                        {
                            lable: 'Xã Lê Lai',
                            value: '01819',
                        },
                        {
                            lable: 'Xã Lê Lợi',
                            value: '01828',
                        },
                        {
                            lable: 'Xã Minh Khai',
                            value: '01795',
                        },
                        {
                            lable: 'Xã Quang Trọng',
                            value: '01813',
                        },
                        {
                            lable: 'Xã Thái Cường',
                            value: '01804',
                        },
                        {
                            lable: 'Xã Thụy Hùng',
                            value: '01810',
                        },
                        {
                            lable: 'Xã Trọng Con',
                            value: '01816',
                        },
                        {
                            lable: 'Xã Vân Trình',
                            value: '01807',
                        },
                        {
                            lable: 'Xã Đức Long',
                            value: '01822',
                        },
                        {
                            lable: 'Xã Đức Thông',
                            value: '01801',
                        },
                        {
                            lable: 'Xã Đức Xuân',
                            value: '01831',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Đắk Lắk',
            value: '66',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Buôn Ma Thuột',
                    value: '643',
                    wards: [
                        {
                            lable: 'Phường Ea Tam',
                            value: '24151',
                        },
                        {
                            lable: 'Phường Khánh Xuân',
                            value: '24154',
                        },
                        {
                            lable: 'Phường Thành Công',
                            value: '24139',
                        },
                        {
                            lable: 'Phường Thành Nhất',
                            value: '24130',
                        },
                        {
                            lable: 'Phường Thắng Lợi',
                            value: '24133',
                        },
                        {
                            lable: 'Phường Thống Nhất',
                            value: '24127',
                        },
                        {
                            lable: 'Phường Tân An',
                            value: '24124',
                        },
                        {
                            lable: 'Phường Tân Hòa',
                            value: '24121',
                        },
                        {
                            lable: 'Phường Tân Lập',
                            value: '24118',
                        },
                        {
                            lable: 'Phường Tân Lợi',
                            value: '24136',
                        },
                        {
                            lable: 'Phường Tân Thành',
                            value: '24142',
                        },
                        {
                            lable: 'Phường Tân Tiến',
                            value: '24145',
                        },
                        {
                            lable: 'Phường Tự An',
                            value: '24148',
                        },
                        {
                            lable: 'Xã Cư ÊBur',
                            value: '24160',
                        },
                        {
                            lable: 'Xã Ea Kao',
                            value: '24169',
                        },
                        {
                            lable: 'Xã Ea Tu',
                            value: '24163',
                        },
                        {
                            lable: 'Xã Hòa Khánh',
                            value: '24175',
                        },
                        {
                            lable: 'Xã Hòa Phú',
                            value: '24172',
                        },
                        {
                            lable: 'Xã Hòa Thuận',
                            value: '24157',
                        },
                        {
                            lable: 'Xã Hòa Thắng',
                            value: '24166',
                        },
                        {
                            lable: 'Xã Hòa Xuân',
                            value: '24178',
                        },
                    ],
                },
                {
                    lable: 'Thị Xã Buôn Hồ',
                    value: '644',
                    wards: [
                        {
                            lable: 'Phường An Bình',
                            value: '24308',
                        },
                        {
                            lable: 'Phường An Lạc',
                            value: '24305',
                        },
                        {
                            lable: 'Phường Bình Tân',
                            value: '24332',
                        },
                        {
                            lable: 'Phường Thiện An',
                            value: '24311',
                        },
                        {
                            lable: 'Phường Thống Nhất',
                            value: '24331',
                        },
                        {
                            lable: 'Phường Đoàn Kết',
                            value: '24322',
                        },
                        {
                            lable: 'Phường Đạt Hiếu',
                            value: '24318',
                        },
                        {
                            lable: 'Xã Bình Thuận',
                            value: '24337',
                        },
                        {
                            lable: 'Xã Cư Bao',
                            value: '24340',
                        },
                        {
                            lable: 'Xã Ea Blang',
                            value: '24325',
                        },
                        {
                            lable: 'Xã Ea Drông',
                            value: '24328',
                        },
                        {
                            lable: 'Xã Ea Siên',
                            value: '24334',
                        },
                    ],
                },
                {
                    lable: 'Huyện Ea H\'leo',
                    value: '645',
                    wards: [
                        {
                            lable: 'Thị trấn Ea Drăng',
                            value: '24181',
                        },
                        {
                            lable: 'Xã Cư A Mung',
                            value: '24194',
                        },
                        {
                            lable: 'Xã Cư Mốt',
                            value: '24196',
                        },
                        {
                            lable: 'Xã Dliê Yang',
                            value: '24205',
                        },
                        {
                            lable: 'Xã Ea H\'leo',
                            value: '24184',
                        },
                        {
                            lable: 'Xã Ea Hiao',
                            value: '24199',
                        },
                        {
                            lable: 'Xã Ea Khal',
                            value: '24202',
                        },
                        {
                            lable: 'Xã Ea Nam',
                            value: '24208',
                        },
                        {
                            lable: 'Xã Ea Ral',
                            value: '24190',
                        },
                        {
                            lable: 'Xã Ea Sol',
                            value: '24187',
                        },
                        {
                            lable: 'Xã Ea Tir',
                            value: '24207',
                        },
                        {
                            lable: 'Xã Ea Wy',
                            value: '24193',
                        },
                    ],
                },
                {
                    lable: 'Huyện Ea Súp',
                    value: '646',
                    wards: [
                        {
                            lable: 'Thị trấn Ea Súp',
                            value: '24211',
                        },
                        {
                            lable: 'Xã Cư KBang',
                            value: '24226',
                        },
                        {
                            lable: 'Xã Cư M\'Lan',
                            value: '24232',
                        },
                        {
                            lable: 'Xã Ea Bung',
                            value: '24229',
                        },
                        {
                            lable: 'Xã Ea Lê',
                            value: '24223',
                        },
                        {
                            lable: 'Xã Ea Rốk',
                            value: '24217',
                        },
                        {
                            lable: 'Xã Ia JLơi',
                            value: '24215',
                        },
                        {
                            lable: 'Xã Ia Lốp',
                            value: '24214',
                        },
                        {
                            lable: 'Xã Ia RVê',
                            value: '24221',
                        },
                        {
                            lable: 'Xã Ya Tờ Mốt',
                            value: '24220',
                        },
                    ],
                },
                {
                    lable: 'Huyện Buôn Đôn',
                    value: '647',
                    wards: [
                        {
                            lable: 'Xã Cuôr KNia',
                            value: '24247',
                        },
                        {
                            lable: 'Xã Ea Bar',
                            value: '24250',
                        },
                        {
                            lable: 'Xã Ea Huar',
                            value: '24238',
                        },
                        {
                            lable: 'Xã Ea Nuôl',
                            value: '24253',
                        },
                        {
                            lable: 'Xã Ea Wer',
                            value: '24241',
                        },
                        {
                            lable: 'Xã Krông Na',
                            value: '24235',
                        },
                        {
                            lable: 'Xã Tân Hoà',
                            value: '24244',
                        },
                    ],
                },
                {
                    lable: 'Huyện Cư M\'gar',
                    value: '648',
                    wards: [
                        {
                            lable: 'Thị trấn Ea Pốk',
                            value: '24256',
                        },
                        {
                            lable: 'Thị trấn Quảng Phú',
                            value: '24259',
                        },
                        {
                            lable: 'Xã Cuor Đăng',
                            value: '24301',
                        },
                        {
                            lable: 'Xã Cư Dliê M\'nông',
                            value: '24271',
                        },
                        {
                            lable: 'Xã Cư M\'gar',
                            value: '24289',
                        },
                        {
                            lable: 'Xã Cư Suê',
                            value: '24298',
                        },
                        {
                            lable: 'Xã Ea D\'Rơng',
                            value: '24292',
                        },
                        {
                            lable: 'Xã Ea H\'đinh',
                            value: '24274',
                        },
                        {
                            lable: 'Xã Ea KPam',
                            value: '24280',
                        },
                        {
                            lable: 'Xã Ea Kiết',
                            value: '24265',
                        },
                        {
                            lable: 'Xã Ea Kuêh',
                            value: '24264',
                        },
                        {
                            lable: 'Xã Ea M\'DRóh',
                            value: '24283',
                        },
                        {
                            lable: 'Xã Ea M\'nang',
                            value: '24295',
                        },
                        {
                            lable: 'Xã Ea Tar',
                            value: '24268',
                        },
                        {
                            lable: 'Xã Ea Tul',
                            value: '24277',
                        },
                        {
                            lable: 'Xã Quảng Hiệp',
                            value: '24286',
                        },
                        {
                            lable: 'Xã Quảng Tiến',
                            value: '24262',
                        },
                    ],
                },
                {
                    lable: 'Huyện Krông Búk',
                    value: '649',
                    wards: [
                        {
                            lable: 'Xã Chư KBô',
                            value: '24310',
                        },
                        {
                            lable: 'Xã Cư Né',
                            value: '24307',
                        },
                        {
                            lable: 'Xã Cư Pơng',
                            value: '24313',
                        },
                        {
                            lable: 'Xã Ea Ngai',
                            value: '24319',
                        },
                        {
                            lable: 'Xã Ea Sin',
                            value: '24314',
                        },
                        {
                            lable: 'Xã Pơng Drang',
                            value: '24316',
                        },
                        {
                            lable: 'Xã Tân Lập',
                            value: '24317',
                        },
                    ],
                },
                {
                    lable: 'Huyện Krông Năng',
                    value: '650',
                    wards: [
                        {
                            lable: 'Thị trấn Krông Năng',
                            value: '24343',
                        },
                        {
                            lable: 'Xã Cư Klông',
                            value: '24367',
                        },
                        {
                            lable: 'Xã Ea Dăh',
                            value: '24360',
                        },
                        {
                            lable: 'Xã Ea Hồ',
                            value: '24361',
                        },
                        {
                            lable: 'Xã Ea Puk',
                            value: '24359',
                        },
                        {
                            lable: 'Xã Ea Tam',
                            value: '24352',
                        },
                        {
                            lable: 'Xã Ea Tân',
                            value: '24370',
                        },
                        {
                            lable: 'Xã Ea Tóh',
                            value: '24349',
                        },
                        {
                            lable: 'Xã Phú Lộc',
                            value: '24355',
                        },
                        {
                            lable: 'Xã Phú Xuân',
                            value: '24364',
                        },
                        {
                            lable: 'Xã Tam Giang',
                            value: '24358',
                        },
                        {
                            lable: 'Xã ĐLiê Ya',
                            value: '24346',
                        },
                    ],
                },
                {
                    lable: 'Huyện Ea Kar',
                    value: '651',
                    wards: [
                        {
                            lable: 'Thị trấn Ea Kar',
                            value: '24373',
                        },
                        {
                            lable: 'Thị trấn Ea Knốp',
                            value: '24376',
                        },
                        {
                            lable: 'Xã Cư Bông',
                            value: '24406',
                        },
                        {
                            lable: 'Xã Cư ELang',
                            value: '24404',
                        },
                        {
                            lable: 'Xã Cư Huê',
                            value: '24385',
                        },
                        {
                            lable: 'Xã Cư Jang',
                            value: '24409',
                        },
                        {
                            lable: 'Xã Cư Ni',
                            value: '24397',
                        },
                        {
                            lable: 'Xã Cư Prông',
                            value: '24401',
                        },
                        {
                            lable: 'Xã Ea Kmút',
                            value: '24394',
                        },
                        {
                            lable: 'Xã Ea Păl',
                            value: '24400',
                        },
                        {
                            lable: 'Xã Ea Sar',
                            value: '24380',
                        },
                        {
                            lable: 'Xã Ea Sô',
                            value: '24379',
                        },
                        {
                            lable: 'Xã Ea Tih',
                            value: '24388',
                        },
                        {
                            lable: 'Xã Ea Ô',
                            value: '24403',
                        },
                        {
                            lable: 'Xã Ea Đar',
                            value: '24391',
                        },
                        {
                            lable: 'Xã Xuân Phú',
                            value: '24382',
                        },
                    ],
                },
                {
                    lable: 'Huyện M\'Đrắk',
                    value: '652',
                    wards: [
                        {
                            lable: 'Thị trấn M\'Đrắk',
                            value: '24412',
                        },
                        {
                            lable: 'Xã Cư K Róa',
                            value: '24439',
                        },
                        {
                            lable: 'Xã Cư M\'ta',
                            value: '24436',
                        },
                        {
                            lable: 'Xã Cư Prao',
                            value: '24415',
                        },
                        {
                            lable: 'Xã Cư San',
                            value: '24444',
                        },
                        {
                            lable: 'Xã Ea H\'MLay',
                            value: '24424',
                        },
                        {
                            lable: 'Xã Ea Lai',
                            value: '24421',
                        },
                        {
                            lable: 'Xã Ea M\' Doal',
                            value: '24430',
                        },
                        {
                            lable: 'Xã Ea Pil',
                            value: '24418',
                        },
                        {
                            lable: 'Xã Ea Riêng',
                            value: '24433',
                        },
                        {
                            lable: 'Xã Ea Trang',
                            value: '24445',
                        },
                        {
                            lable: 'Xã Krông Jing',
                            value: '24427',
                        },
                        {
                            lable: 'Xã Krông Á',
                            value: '24442',
                        },
                    ],
                },
                {
                    lable: 'Huyện Krông Bông',
                    value: '653',
                    wards: [
                        {
                            lable: 'Thị trấn Krông Kmar',
                            value: '24448',
                        },
                        {
                            lable: 'Xã Cư Drăm',
                            value: '24484',
                        },
                        {
                            lable: 'Xã Cư KTy',
                            value: '24454',
                        },
                        {
                            lable: 'Xã Cư Pui',
                            value: '24478',
                        },
                        {
                            lable: 'Xã Dang Kang',
                            value: '24451',
                        },
                        {
                            lable: 'Xã Ea Trul',
                            value: '24472',
                        },
                        {
                            lable: 'Xã Hòa Lễ',
                            value: '24466',
                        },
                        {
                            lable: 'Xã Hòa Phong',
                            value: '24463',
                        },
                        {
                            lable: 'Xã Hòa Sơn',
                            value: '24481',
                        },
                        {
                            lable: 'Xã Hòa Thành',
                            value: '24457',
                        },
                        {
                            lable: 'Xã Hòa Tân',
                            value: '24460',
                        },
                        {
                            lable: 'Xã Khuê Ngọc Điền',
                            value: '24475',
                        },
                        {
                            lable: 'Xã Yang Mao',
                            value: '24487',
                        },
                        {
                            lable: 'Xã Yang Reh',
                            value: '24469',
                        },
                    ],
                },
                {
                    lable: 'Huyện Krông Pắc',
                    value: '654',
                    wards: [
                        {
                            lable: 'Thị trấn Phước An',
                            value: '24490',
                        },
                        {
                            lable: 'Xã Ea Hiu',
                            value: '24520',
                        },
                        {
                            lable: 'Xã Ea KNuec',
                            value: '24505',
                        },
                        {
                            lable: 'Xã Ea Kly',
                            value: '24496',
                        },
                        {
                            lable: 'Xã Ea Kuăng',
                            value: '24514',
                        },
                        {
                            lable: 'Xã Ea Kênh',
                            value: '24499',
                        },
                        {
                            lable: 'Xã Ea Phê',
                            value: '24502',
                        },
                        {
                            lable: 'Xã Ea Uy',
                            value: '24532',
                        },
                        {
                            lable: 'Xã Ea Yiêng',
                            value: '24535',
                        },
                        {
                            lable: 'Xã Ea Yông',
                            value: '24508',
                        },
                        {
                            lable: 'Xã Hòa An',
                            value: '24511',
                        },
                        {
                            lable: 'Xã Hòa Tiến',
                            value: '24523',
                        },
                        {
                            lable: 'Xã Hòa Đông',
                            value: '24517',
                        },
                        {
                            lable: 'Xã KRông Búk',
                            value: '24493',
                        },
                        {
                            lable: 'Xã Tân Tiến',
                            value: '24526',
                        },
                        {
                            lable: 'Xã Vụ Bổn',
                            value: '24529',
                        },
                    ],
                },
                {
                    lable: 'Huyện Krông A Na',
                    value: '655',
                    wards: [
                        {
                            lable: 'Thị trấn Buôn Trấp',
                            value: '24538',
                        },
                        {
                            lable: 'Xã Bình Hòa',
                            value: '24574',
                        },
                        {
                            lable: 'Xã Băng A Drênh',
                            value: '24568',
                        },
                        {
                            lable: 'Xã Dray Sáp',
                            value: '24556',
                        },
                        {
                            lable: 'Xã Dur KMăl',
                            value: '24571',
                        },
                        {
                            lable: 'Xã Ea Bông',
                            value: '24565',
                        },
                        {
                            lable: 'Xã Ea Na',
                            value: '24559',
                        },
                        {
                            lable: 'Xã Quảng Điền',
                            value: '24577',
                        },
                    ],
                },
                {
                    lable: 'Huyện Lắk',
                    value: '656',
                    wards: [
                        {
                            lable: 'Thị trấn Liên Sơn',
                            value: '24580',
                        },
                        {
                            lable: 'Xã Buôn Triết',
                            value: '24592',
                        },
                        {
                            lable: 'Xã Buôn Tría',
                            value: '24595',
                        },
                        {
                            lable: 'Xã Bông Krang',
                            value: '24586',
                        },
                        {
                            lable: 'Xã Ea R\'Bin',
                            value: '24610',
                        },
                        {
                            lable: 'Xã Krông Nô',
                            value: '24604',
                        },
                        {
                            lable: 'Xã Nam Ka',
                            value: '24607',
                        },
                        {
                            lable: 'Xã Yang Tao',
                            value: '24583',
                        },
                        {
                            lable: 'Xã Đắk Liêng',
                            value: '24589',
                        },
                        {
                            lable: 'Xã Đắk Nuê',
                            value: '24601',
                        },
                        {
                            lable: 'Xã Đắk Phơi',
                            value: '24598',
                        },
                    ],
                },
                {
                    lable: 'Huyện Cư Kuin',
                    value: '657',
                    wards: [
                        {
                            lable: 'Xã Cư Ê Wi',
                            value: '24541',
                        },
                        {
                            lable: 'Xã Dray Bhăng',
                            value: '24561',
                        },
                        {
                            lable: 'Xã Ea BHốk',
                            value: '24550',
                        },
                        {
                            lable: 'Xã Ea Hu',
                            value: '24553',
                        },
                        {
                            lable: 'Xã Ea Ktur',
                            value: '24544',
                        },
                        {
                            lable: 'Xã Ea Ning',
                            value: '24540',
                        },
                        {
                            lable: 'Xã Ea Tiêu',
                            value: '24547',
                        },
                        {
                            lable: 'Xã Hòa Hiệp',
                            value: '24562',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Đắk Nông',
            value: '67',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Gia Nghĩa',
                    value: '660',
                    wards: [
                        {
                            lable: 'Phường Nghĩa Phú',
                            value: '24614',
                        },
                        {
                            lable: 'Phường Nghĩa Thành',
                            value: '24612',
                        },
                        {
                            lable: 'Phường Nghĩa Trung',
                            value: '24617',
                        },
                        {
                            lable: 'Phường Nghĩa Tân',
                            value: '24615',
                        },
                        {
                            lable: 'Phường Nghĩa Đức',
                            value: '24611',
                        },
                        {
                            lable: 'Phường Quảng Thành',
                            value: '24619',
                        },
                        {
                            lable: 'Xã Đăk R\'Moan',
                            value: '24618',
                        },
                        {
                            lable: 'Xã Đắk Nia',
                            value: '24628',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đăk Glong',
                    value: '661',
                    wards: [
                        {
                            lable: 'Xã Quảng Hoà',
                            value: '24620',
                        },
                        {
                            lable: 'Xã Quảng Khê',
                            value: '24631',
                        },
                        {
                            lable: 'Xã Quảng Sơn',
                            value: '24616',
                        },
                        {
                            lable: 'Xã Đắk Ha',
                            value: '24622',
                        },
                        {
                            lable: 'Xã Đắk Plao',
                            value: '24634',
                        },
                        {
                            lable: 'Xã Đắk R\'Măng',
                            value: '24625',
                        },
                        {
                            lable: 'Xã Đắk Som',
                            value: '24637',
                        },
                    ],
                },
                {
                    lable: 'Huyện Cư Jút',
                    value: '662',
                    wards: [
                        {
                            lable: 'Thị trấn Ea T\'Ling',
                            value: '24640',
                        },
                        {
                            lable: 'Xã Cư Knia',
                            value: '24658',
                        },
                        {
                            lable: 'Xã Ea Pô',
                            value: '24646',
                        },
                        {
                            lable: 'Xã Nam Dong',
                            value: '24649',
                        },
                        {
                            lable: 'Xã Trúc Sơn',
                            value: '24661',
                        },
                        {
                            lable: 'Xã Tâm Thắng',
                            value: '24655',
                        },
                        {
                            lable: 'Xã Đắk DRông',
                            value: '24652',
                        },
                        {
                            lable: 'Xã Đắk Wil',
                            value: '24643',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đắk Mil',
                    value: '663',
                    wards: [
                        {
                            lable: 'Thị trấn Đắk Mil',
                            value: '24664',
                        },
                        {
                            lable: 'Xã  Đắk Lao',
                            value: '24667',
                        },
                        {
                            lable: 'Xã Long Sơn',
                            value: '24678',
                        },
                        {
                            lable: 'Xã Thuận An',
                            value: '24682',
                        },
                        {
                            lable: 'Xã Đắk Gằn',
                            value: '24673',
                        },
                        {
                            lable: 'Xã Đắk N\'Drót',
                            value: '24677',
                        },
                        {
                            lable: 'Xã Đắk R\'La',
                            value: '24670',
                        },
                        {
                            lable: 'Xã Đắk Sắk',
                            value: '24679',
                        },
                        {
                            lable: 'Xã Đức Minh',
                            value: '24685',
                        },
                        {
                            lable: 'Xã Đức Mạnh',
                            value: '24676',
                        },
                    ],
                },
                {
                    lable: 'Huyện Krông Nô',
                    value: '664',
                    wards: [
                        {
                            lable: 'Thị trấn Đắk Mâm',
                            value: '24688',
                        },
                        {
                            lable: 'Xã Buôn Choah',
                            value: '24694',
                        },
                        {
                            lable: 'Xã Nam Xuân',
                            value: '24692',
                        },
                        {
                            lable: 'Xã Nam Đà',
                            value: '24697',
                        },
                        {
                            lable: 'Xã Nâm N\'Đir',
                            value: '24715',
                        },
                        {
                            lable: 'Xã Nâm Nung',
                            value: '24703',
                        },
                        {
                            lable: 'Xã Quảng Phú',
                            value: '24712',
                        },
                        {
                            lable: 'Xã Tân Thành',
                            value: '24699',
                        },
                        {
                            lable: 'Xã Đắk Drô',
                            value: '24700',
                        },
                        {
                            lable: 'Xã Đắk Nang',
                            value: '24709',
                        },
                        {
                            lable: 'Xã Đắk Sôr',
                            value: '24691',
                        },
                        {
                            lable: 'Xã Đức Xuyên',
                            value: '24706',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đắk Song',
                    value: '665',
                    wards: [
                        {
                            lable: 'Thị trấn Đức An',
                            value: '24717',
                        },
                        {
                            lable: 'Xã Nam Bình',
                            value: '24721',
                        },
                        {
                            lable: 'Xã Nâm N\'Jang',
                            value: '24728',
                        },
                        {
                            lable: 'Xã Thuận Hà',
                            value: '24722',
                        },
                        {
                            lable: 'Xã Thuận Hạnh',
                            value: '24724',
                        },
                        {
                            lable: 'Xã Trường Xuân',
                            value: '24730',
                        },
                        {
                            lable: 'Xã Đắk Hòa',
                            value: '24719',
                        },
                        {
                            lable: 'Xã Đắk Môl',
                            value: '24718',
                        },
                        {
                            lable: 'Xã Đắk N\'Dung',
                            value: '24727',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đắk R\'Lấp',
                    value: '666',
                    wards: [
                        {
                            lable: 'Thị trấn Kiến Đức',
                            value: '24733',
                        },
                        {
                            lable: 'Xã Hưng Bình',
                            value: '24761',
                        },
                        {
                            lable: 'Xã Kiến Thành',
                            value: '24754',
                        },
                        {
                            lable: 'Xã Nghĩa Thắng',
                            value: '24756',
                        },
                        {
                            lable: 'Xã Nhân Cơ',
                            value: '24751',
                        },
                        {
                            lable: 'Xã Nhân Đạo',
                            value: '24766',
                        },
                        {
                            lable: 'Xã Quảng Tín',
                            value: '24745',
                        },
                        {
                            lable: 'Xã Đạo Nghĩa',
                            value: '24757',
                        },
                        {
                            lable: 'Xã Đắk Ru',
                            value: '24763',
                        },
                        {
                            lable: 'Xã Đắk Sin',
                            value: '24760',
                        },
                        {
                            lable: 'Xã Đắk Wer',
                            value: '24750',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tuy Đức',
                    value: '667',
                    wards: [
                        {
                            lable: 'Xã Quảng Trực',
                            value: '24736',
                        },
                        {
                            lable: 'Xã Quảng Tâm',
                            value: '24740',
                        },
                        {
                            lable: 'Xã Quảng Tân',
                            value: '24748',
                        },
                        {
                            lable: 'Xã Đắk Búk So',
                            value: '24739',
                        },
                        {
                            lable: 'Xã Đắk Ngo',
                            value: '24746',
                        },
                        {
                            lable: 'Xã Đắk R\'Tíh',
                            value: '24742',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Điện Biên',
            value: '11',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Điện Biên Phủ',
                    value: '094',
                    wards: [
                        {
                            lable: 'Phường Him Lam',
                            value: '03127',
                        },
                        {
                            lable: 'Phường Mường Thanh',
                            value: '03136',
                        },
                        {
                            lable: 'Phường Nam Thanh',
                            value: '03139',
                        },
                        {
                            lable: 'Phường Noong Bua',
                            value: '03124',
                        },
                        {
                            lable: 'Phường Thanh Bình',
                            value: '03130',
                        },
                        {
                            lable: 'Phường Thanh Trường',
                            value: '03142',
                        },
                        {
                            lable: 'Phường Tân Thanh',
                            value: '03133',
                        },
                        {
                            lable: 'Xã Mường Phăng',
                            value: '03325',
                        },
                        {
                            lable: 'Xã Nà Nhạn',
                            value: '03317',
                        },
                        {
                            lable: 'Xã Nà Tấu',
                            value: '03316',
                        },
                        {
                            lable: 'Xã Pá Khoang',
                            value: '03326',
                        },
                        {
                            lable: 'Xã Thanh Minh',
                            value: '03145',
                        },
                    ],
                },
                {
                    lable: 'Thị Xã Mường Lay',
                    value: '095',
                    wards: [
                        {
                            lable: 'Phường Na Lay',
                            value: '03151',
                        },
                        {
                            lable: 'Phường Sông Đà',
                            value: '03148',
                        },
                        {
                            lable: 'Xã Lay Nưa',
                            value: '03184',
                        },
                    ],
                },
                {
                    lable: 'Huyện Mường Nhé',
                    value: '096',
                    wards: [
                        {
                            lable: 'Xã Chung Chải',
                            value: '03157',
                        },
                        {
                            lable: 'Xã Huổi Lếnh',
                            value: '03177',
                        },
                        {
                            lable: 'Xã Leng Su Sìn',
                            value: '03158',
                        },
                        {
                            lable: 'Xã Mường Nhé',
                            value: '03160',
                        },
                        {
                            lable: 'Xã Mường Toong',
                            value: '03163',
                        },
                        {
                            lable: 'Xã Nậm Kè',
                            value: '03162',
                        },
                        {
                            lable: 'Xã Nậm Vì',
                            value: '03161',
                        },
                        {
                            lable: 'Xã Pá Mỳ',
                            value: '03159',
                        },
                        {
                            lable: 'Xã Quảng Lâm',
                            value: '03164',
                        },
                        {
                            lable: 'Xã Sen Thượng',
                            value: '03155',
                        },
                        {
                            lable: 'Xã Sín Thầu',
                            value: '03154',
                        },
                    ],
                },
                {
                    lable: 'Huyện Mường Chà',
                    value: '097',
                    wards: [
                        {
                            lable: 'Thị Trấn Mường Chà',
                            value: '03172',
                        },
                        {
                            lable: 'Xã Huổi Lèng',
                            value: '03196',
                        },
                        {
                            lable: 'Xã Huổi Mí',
                            value: '03191',
                        },
                        {
                            lable: 'Xã Hừa Ngài',
                            value: '03190',
                        },
                        {
                            lable: 'Xã Ma Thì Hồ',
                            value: '03200',
                        },
                        {
                            lable: 'Xã Mường Mươn',
                            value: '03202',
                        },
                        {
                            lable: 'Xã Mường Tùng',
                            value: '03181',
                        },
                        {
                            lable: 'Xã Na Sang',
                            value: '03201',
                        },
                        {
                            lable: 'Xã Nậm Nèn',
                            value: '03194',
                        },
                        {
                            lable: 'Xã Pa Ham',
                            value: '03193',
                        },
                        {
                            lable: 'Xã Sa Lông',
                            value: '03197',
                        },
                        {
                            lable: 'Xã Xá Tổng',
                            value: '03178',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tủa Chùa',
                    value: '098',
                    wards: [
                        {
                            lable: 'Thị trấn Tủa Chùa',
                            value: '03217',
                        },
                        {
                            lable: 'Xã Huổi Só',
                            value: '03220',
                        },
                        {
                            lable: 'Xã Lao Xả Phình',
                            value: '03229',
                        },
                        {
                            lable: 'Xã Mường Báng',
                            value: '03250',
                        },
                        {
                            lable: 'Xã Mường Đun',
                            value: '03247',
                        },
                        {
                            lable: 'Xã Sáng Nhè',
                            value: '03244',
                        },
                        {
                            lable: 'Xã Sính Phình',
                            value: '03241',
                        },
                        {
                            lable: 'Xã Trung Thu',
                            value: '03238',
                        },
                        {
                            lable: 'Xã Tả Phìn',
                            value: '03232',
                        },
                        {
                            lable: 'Xã Tả Sìn Thàng',
                            value: '03226',
                        },
                        {
                            lable: 'Xã Tủa Thàng',
                            value: '03235',
                        },
                        {
                            lable: 'Xã Xín Chải',
                            value: '03223',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tuần Giáo',
                    value: '099',
                    wards: [
                        {
                            lable: 'Thị trấn Tuần Giáo',
                            value: '03253',
                        },
                        {
                            lable: 'Xã Chiềng Sinh',
                            value: '03298',
                        },
                        {
                            lable: 'Xã Chiềng Đông',
                            value: '03299',
                        },
                        {
                            lable: 'Xã Mùn Chung',
                            value: '03262',
                        },
                        {
                            lable: 'Xã Mường Khong',
                            value: '03284',
                        },
                        {
                            lable: 'Xã Mường Mùn',
                            value: '03268',
                        },
                        {
                            lable: 'Xã Mường Thín',
                            value: '03277',
                        },
                        {
                            lable: 'Xã Nà Sáy',
                            value: '03283',
                        },
                        {
                            lable: 'Xã Nà Tòng',
                            value: '03263',
                        },
                        {
                            lable: 'Xã Phình Sáng',
                            value: '03259',
                        },
                        {
                            lable: 'Xã Pú Nhung',
                            value: '03271',
                        },
                        {
                            lable: 'Xã Pú Xi',
                            value: '03269',
                        },
                        {
                            lable: 'Xã Quài Cang',
                            value: '03289',
                        },
                        {
                            lable: 'Xã Quài Nưa',
                            value: '03274',
                        },
                        {
                            lable: 'Xã Quài Tở',
                            value: '03295',
                        },
                        {
                            lable: 'Xã Rạng Đông',
                            value: '03260',
                        },
                        {
                            lable: 'Xã Ta Ma',
                            value: '03265',
                        },
                        {
                            lable: 'Xã Tênh Phông',
                            value: '03304',
                        },
                        {
                            lable: 'Xã Tỏa Tình',
                            value: '03280',
                        },
                    ],
                },
                {
                    lable: 'Huyện Điện Biên',
                    value: '100',
                    wards: [
                        {
                            lable: 'Xã Hua Thanh',
                            value: '03323',
                        },
                        {
                            lable: 'Xã Hẹ Muông',
                            value: '03359',
                        },
                        {
                            lable: 'Xã Mường Lói',
                            value: '03367',
                        },
                        {
                            lable: 'Xã Mường Nhà',
                            value: '03364',
                        },
                        {
                            lable: 'Xã Mường Pồn',
                            value: '03319',
                        },
                        {
                            lable: 'Xã Na Tông',
                            value: '03365',
                        },
                        {
                            lable: 'Xã Na Ư',
                            value: '03361',
                        },
                        {
                            lable: 'Xã Noong Luống',
                            value: '03349',
                        },
                        {
                            lable: 'Xã Noọng Hẹt',
                            value: '03352',
                        },
                        {
                            lable: 'Xã Núa Ngam',
                            value: '03358',
                        },
                        {
                            lable: 'Xã Pa Thơm',
                            value: '03340',
                        },
                        {
                            lable: 'Xã Phu Luông',
                            value: '03368',
                        },
                        {
                            lable: 'Xã Pom Lót',
                            value: '03356',
                        },
                        {
                            lable: 'Xã Sam Mứn',
                            value: '03355',
                        },
                        {
                            lable: 'Xã Thanh An',
                            value: '03343',
                        },
                        {
                            lable: 'Xã Thanh Chăn',
                            value: '03337',
                        },
                        {
                            lable: 'Xã Thanh Hưng',
                            value: '03331',
                        },
                        {
                            lable: 'Xã Thanh Luông',
                            value: '03328',
                        },
                        {
                            lable: 'Xã Thanh Nưa',
                            value: '03322',
                        },
                        {
                            lable: 'Xã Thanh Xương',
                            value: '03334',
                        },
                        {
                            lable: 'Xã Thanh Yên',
                            value: '03346',
                        },
                    ],
                },
                {
                    lable: 'Huyện Điện Biên Đông',
                    value: '101',
                    wards: [
                        {
                            lable: 'Thị trấn Điện Biên Đông',
                            value: '03203',
                        },
                        {
                            lable: 'Xã Chiềng Sơ',
                            value: '03211',
                        },
                        {
                            lable: 'Xã Háng Lìa',
                            value: '03385',
                        },
                        {
                            lable: 'Xã Keo Lôm',
                            value: '03376',
                        },
                        {
                            lable: 'Xã Luân Giới',
                            value: '03379',
                        },
                        {
                            lable: 'Xã Mường Luân',
                            value: '03214',
                        },
                        {
                            lable: 'Xã Na Son',
                            value: '03205',
                        },
                        {
                            lable: 'Xã Nong U',
                            value: '03371',
                        },
                        {
                            lable: 'Xã Phì Nhừ',
                            value: '03208',
                        },
                        {
                            lable: 'Xã Phình Giàng',
                            value: '03382',
                        },
                        {
                            lable: 'Xã Pú Hồng',
                            value: '03383',
                        },
                        {
                            lable: 'Xã Pú Nhi',
                            value: '03370',
                        },
                        {
                            lable: 'Xã Tìa Dình',
                            value: '03384',
                        },
                        {
                            lable: 'Xã Xa Dung',
                            value: '03373',
                        },
                    ],
                },
                {
                    lable: 'Huyện Mường Ảng',
                    value: '102',
                    wards: [
                        {
                            lable: 'Thị trấn Mường Ảng',
                            value: '03256',
                        },
                        {
                            lable: 'Xã Búng Lao',
                            value: '03301',
                        },
                        {
                            lable: 'Xã Mường Lạn',
                            value: '03313',
                        },
                        {
                            lable: 'Xã Mường Đăng',
                            value: '03286',
                        },
                        {
                            lable: 'Xã Ngối Cáy',
                            value: '03287',
                        },
                        {
                            lable: 'Xã Nặm Lịch',
                            value: '03312',
                        },
                        {
                            lable: 'Xã Xuân Lao',
                            value: '03302',
                        },
                        {
                            lable: 'Xã Ẳng Cang',
                            value: '03310',
                        },
                        {
                            lable: 'Xã Ẳng Nưa',
                            value: '03307',
                        },
                        {
                            lable: 'Xã Ẳng Tở',
                            value: '03292',
                        },
                    ],
                },
                {
                    lable: 'Huyện Nậm Pồ',
                    value: '103',
                    wards: [
                        {
                            lable: 'Xã Chà Cang',
                            value: '03166',
                        },
                        {
                            lable: 'Xã Chà Nưa',
                            value: '03187',
                        },
                        {
                            lable: 'Xã Chà Tở',
                            value: '03175',
                        },
                        {
                            lable: 'Xã Na Cô Sa',
                            value: '03167',
                        },
                        {
                            lable: 'Xã Nà Bủng',
                            value: '03170',
                        },
                        {
                            lable: 'Xã Nà Hỳ',
                            value: '03169',
                        },
                        {
                            lable: 'Xã Nà Khoa',
                            value: '03168',
                        },
                        {
                            lable: 'Xã Nậm Chua',
                            value: '03173',
                        },
                        {
                            lable: 'Xã Nậm Khăn',
                            value: '03174',
                        },
                        {
                            lable: 'Xã Nậm Nhừ',
                            value: '03171',
                        },
                        {
                            lable: 'Xã Nậm Tin',
                            value: '03156',
                        },
                        {
                            lable: 'Xã Pa Tần',
                            value: '03165',
                        },
                        {
                            lable: 'Xã Phìn Hồ',
                            value: '03198',
                        },
                        {
                            lable: 'Xã Si Pa Phìn',
                            value: '03199',
                        },
                        {
                            lable: 'Xã Vàng Đán',
                            value: '03176',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Đồng Nai',
            value: '75',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Biên Hòa',
                    value: '731',
                    wards: [
                        {
                            lable: 'Phường An Bình',
                            value: '26050',
                        },
                        {
                            lable: 'Phường An Hòa',
                            value: '26371',
                        },
                        {
                            lable: 'Phường Bình Đa',
                            value: '26047',
                        },
                        {
                            lable: 'Phường Bửu Hòa',
                            value: '26053',
                        },
                        {
                            lable: 'Phường Bửu Long',
                            value: '26011',
                        },
                        {
                            lable: 'Phường Hiệp Hòa',
                            value: '26065',
                        },
                        {
                            lable: 'Phường Hòa Bình',
                            value: '26038',
                        },
                        {
                            lable: 'Phường Hóa An',
                            value: '26068',
                        },
                        {
                            lable: 'Phường Hố Nai',
                            value: '26002',
                        },
                        {
                            lable: 'Phường Long Bình',
                            value: '26020',
                        },
                        {
                            lable: 'Phường Long Bình Tân',
                            value: '26056',
                        },
                        {
                            lable: 'Phường Phước Tân',
                            value: '26377',
                        },
                        {
                            lable: 'Phường Quang Vinh',
                            value: '26023',
                        },
                        {
                            lable: 'Phường Quyết Thắng',
                            value: '26041',
                        },
                        {
                            lable: 'Phường Tam Hiệp',
                            value: '26017',
                        },
                        {
                            lable: 'Phường Tam Hòa',
                            value: '26035',
                        },
                        {
                            lable: 'Phường Tam Phước',
                            value: '26374',
                        },
                        {
                            lable: 'Phường Thanh Bình',
                            value: '26044',
                        },
                        {
                            lable: 'Phường Thống Nhất',
                            value: '26029',
                        },
                        {
                            lable: 'Phường Trung Dũng',
                            value: '26032',
                        },
                        {
                            lable: 'Phường Trảng Dài',
                            value: '25993',
                        },
                        {
                            lable: 'Phường Tân Biên',
                            value: '25999',
                        },
                        {
                            lable: 'Phường Tân Hiệp',
                            value: '26008',
                        },
                        {
                            lable: 'Phường Tân Hòa',
                            value: '26005',
                        },
                        {
                            lable: 'Phường Tân Hạnh',
                            value: '26062',
                        },
                        {
                            lable: 'Phường Tân Mai',
                            value: '26026',
                        },
                        {
                            lable: 'Phường Tân Phong',
                            value: '25996',
                        },
                        {
                            lable: 'Phường Tân Tiến',
                            value: '26014',
                        },
                        {
                            lable: 'Phường Tân Vạn',
                            value: '26059',
                        },
                        {
                            lable: 'Xã Long Hưng',
                            value: '26380',
                        },
                    ],
                },
                {
                    lable: 'Thành phố Long Khánh',
                    value: '732',
                    wards: [
                        {
                            lable: 'Phường Bàu Sen',
                            value: '26104',
                        },
                        {
                            lable: 'Phường Bảo Vinh',
                            value: '26098',
                        },
                        {
                            lable: 'Phường Phú Bình',
                            value: '26086',
                        },
                        {
                            lable: 'Phường Suối Tre',
                            value: '26095',
                        },
                        {
                            lable: 'Phường Xuân An',
                            value: '26080',
                        },
                        {
                            lable: 'Phường Xuân Bình',
                            value: '26077',
                        },
                        {
                            lable: 'Phường Xuân Hoà',
                            value: '26083',
                        },
                        {
                            lable: 'Phường Xuân Lập',
                            value: '26101',
                        },
                        {
                            lable: 'Phường Xuân Thanh',
                            value: '26074',
                        },
                        {
                            lable: 'Phường Xuân Trung',
                            value: '26071',
                        },
                        {
                            lable: 'Phường Xuân Tân',
                            value: '26110',
                        },
                        {
                            lable: 'Xã Bàu Trâm',
                            value: '26107',
                        },
                        {
                            lable: 'Xã Bình Lộc',
                            value: '26089',
                        },
                        {
                            lable: 'Xã Bảo Quang',
                            value: '26092',
                        },
                        {
                            lable: 'Xã Hàng Gòn',
                            value: '26113',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tân Phú',
                    value: '734',
                    wards: [
                        {
                            lable: 'Thị trấn Tân Phú',
                            value: '26116',
                        },
                        {
                            lable: 'Xã Dak Lua',
                            value: '26119',
                        },
                        {
                            lable: 'Xã Nam Cát Tiên',
                            value: '26122',
                        },
                        {
                            lable: 'Xã Núi Tượng',
                            value: '26128',
                        },
                        {
                            lable: 'Xã Phú An',
                            value: '26125',
                        },
                        {
                            lable: 'Xã Phú Bình',
                            value: '26158',
                        },
                        {
                            lable: 'Xã Phú Lâm',
                            value: '26155',
                        },
                        {
                            lable: 'Xã Phú Lập',
                            value: '26134',
                        },
                        {
                            lable: 'Xã Phú Lộc',
                            value: '26152',
                        },
                        {
                            lable: 'Xã Phú Sơn',
                            value: '26137',
                        },
                        {
                            lable: 'Xã Phú Thanh',
                            value: '26161',
                        },
                        {
                            lable: 'Xã Phú Thịnh',
                            value: '26140',
                        },
                        {
                            lable: 'Xã Phú Trung',
                            value: '26146',
                        },
                        {
                            lable: 'Xã Phú Xuân',
                            value: '26149',
                        },
                        {
                            lable: 'Xã Phú Điền',
                            value: '26167',
                        },
                        {
                            lable: 'Xã Thanh Sơn',
                            value: '26143',
                        },
                        {
                            lable: 'Xã Trà Cổ',
                            value: '26164',
                        },
                        {
                            lable: 'Xã Tà Lài',
                            value: '26131',
                        },
                    ],
                },
                {
                    lable: 'Huyện Vĩnh Cửu',
                    value: '735',
                    wards: [
                        {
                            lable: 'Thị trấn Vĩnh An',
                            value: '26170',
                        },
                        {
                            lable: 'Xã Bình Hòa',
                            value: '26197',
                        },
                        {
                            lable: 'Xã Bình Lợi',
                            value: '26185',
                        },
                        {
                            lable: 'Xã Hiếu Liêm',
                            value: '26203',
                        },
                        {
                            lable: 'Xã Mã Đà',
                            value: '26200',
                        },
                        {
                            lable: 'Xã Phú Lý',
                            value: '26173',
                        },
                        {
                            lable: 'Xã Thiện Tân',
                            value: '26191',
                        },
                        {
                            lable: 'Xã Thạnh Phú',
                            value: '26188',
                        },
                        {
                            lable: 'Xã Trị An',
                            value: '26176',
                        },
                        {
                            lable: 'Xã Tân An',
                            value: '26179',
                        },
                        {
                            lable: 'Xã Tân Bình',
                            value: '26194',
                        },
                        {
                            lable: 'Xã Vĩnh Tân',
                            value: '26182',
                        },
                    ],
                },
                {
                    lable: 'Huyện Định Quán',
                    value: '736',
                    wards: [
                        {
                            lable: 'Thị trấn Định Quán',
                            value: '26206',
                        },
                        {
                            lable: 'Xã Gia Canh',
                            value: '26230',
                        },
                        {
                            lable: 'Xã La Ngà',
                            value: '26227',
                        },
                        {
                            lable: 'Xã Ngọc Định',
                            value: '26224',
                        },
                        {
                            lable: 'Xã Phú Cường',
                            value: '26236',
                        },
                        {
                            lable: 'Xã Phú Hòa',
                            value: '26221',
                        },
                        {
                            lable: 'Xã Phú Lợi',
                            value: '26218',
                        },
                        {
                            lable: 'Xã Phú Ngọc',
                            value: '26233',
                        },
                        {
                            lable: 'Xã Phú Tân',
                            value: '26212',
                        },
                        {
                            lable: 'Xã Phú Túc',
                            value: '26242',
                        },
                        {
                            lable: 'Xã Phú Vinh',
                            value: '26215',
                        },
                        {
                            lable: 'Xã Suối Nho',
                            value: '26245',
                        },
                        {
                            lable: 'Xã Thanh Sơn',
                            value: '26209',
                        },
                        {
                            lable: 'Xã Túc Trưng',
                            value: '26239',
                        },
                    ],
                },
                {
                    lable: 'Huyện Trảng Bom',
                    value: '737',
                    wards: [
                        {
                            lable: 'Thị trấn Trảng Bom',
                            value: '26248',
                        },
                        {
                            lable: 'Xã An Viễn',
                            value: '26296',
                        },
                        {
                            lable: 'Xã Bàu Hàm',
                            value: '26257',
                        },
                        {
                            lable: 'Xã Bình Minh',
                            value: '26278',
                        },
                        {
                            lable: 'Xã Bắc Sơn',
                            value: '26269',
                        },
                        {
                            lable: 'Xã Cây Gáo',
                            value: '26254',
                        },
                        {
                            lable: 'Xã Giang Điền',
                            value: '26293',
                        },
                        {
                            lable: 'Xã Hưng Thịnh',
                            value: '26287',
                        },
                        {
                            lable: 'Xã Hố Nai 3',
                            value: '26272',
                        },
                        {
                            lable: 'Xã Quảng Tiến',
                            value: '26290',
                        },
                        {
                            lable: 'Xã Sông Thao',
                            value: '26260',
                        },
                        {
                            lable: 'Xã Sông Trầu',
                            value: '26263',
                        },
                        {
                            lable: 'Xã Thanh Bình',
                            value: '26251',
                        },
                        {
                            lable: 'Xã Trung Hoà',
                            value: '26281',
                        },
                        {
                            lable: 'Xã Tây Hoà',
                            value: '26275',
                        },
                        {
                            lable: 'Xã Đông Hoà',
                            value: '26266',
                        },
                        {
                            lable: 'Xã Đồi 61',
                            value: '26284',
                        },
                    ],
                },
                {
                    lable: 'Huyện Thống Nhất',
                    value: '738',
                    wards: [
                        {
                            lable: 'Thị trấn Dầu Giây',
                            value: '26326',
                        },
                        {
                            lable: 'Xã Bàu Hàm 2',
                            value: '26314',
                        },
                        {
                            lable: 'Xã Gia Kiệm',
                            value: '26308',
                        },
                        {
                            lable: 'Xã Gia Tân 1',
                            value: '26299',
                        },
                        {
                            lable: 'Xã Gia Tân 2',
                            value: '26302',
                        },
                        {
                            lable: 'Xã Gia Tân 3',
                            value: '26305',
                        },
                        {
                            lable: 'Xã Hưng Lộc',
                            value: '26317',
                        },
                        {
                            lable: 'Xã Lộ 25',
                            value: '26320',
                        },
                        {
                            lable: 'Xã Quang Trung',
                            value: '26311',
                        },
                        {
                            lable: 'Xã Xuân Thiện',
                            value: '26323',
                        },
                    ],
                },
                {
                    lable: 'Huyện Cẩm Mỹ',
                    value: '739',
                    wards: [
                        {
                            lable: 'Thị trấn Long Giao',
                            value: '26341',
                        },
                        {
                            lable: 'Xã Bảo Bình',
                            value: '26350',
                        },
                        {
                            lable: 'Xã Lâm San',
                            value: '26365',
                        },
                        {
                            lable: 'Xã Nhân Nghĩa',
                            value: '26335',
                        },
                        {
                            lable: 'Xã Sông Nhạn',
                            value: '26329',
                        },
                        {
                            lable: 'Xã Sông Ray',
                            value: '26362',
                        },
                        {
                            lable: 'Xã Thừa Đức',
                            value: '26347',
                        },
                        {
                            lable: 'Xã Xuân Bảo',
                            value: '26353',
                        },
                        {
                            lable: 'Xã Xuân Mỹ',
                            value: '26344',
                        },
                        {
                            lable: 'Xã Xuân Quế',
                            value: '26332',
                        },
                        {
                            lable: 'Xã Xuân Tây',
                            value: '26356',
                        },
                        {
                            lable: 'Xã Xuân Đông',
                            value: '26359',
                        },
                        {
                            lable: 'Xã Xuân Đường',
                            value: '26338',
                        },
                    ],
                },
                {
                    lable: 'Huyện Long Thành',
                    value: '740',
                    wards: [
                        {
                            lable: 'Thị trấn Long Thành',
                            value: '26368',
                        },
                        {
                            lable: 'Xã An Phước',
                            value: '26383',
                        },
                        {
                            lable: 'Xã Bàu Cạn',
                            value: '26410',
                        },
                        {
                            lable: 'Xã Bình An',
                            value: '26386',
                        },
                        {
                            lable: 'Xã Bình Sơn',
                            value: '26395',
                        },
                        {
                            lable: 'Xã Cẩm Đường',
                            value: '26401',
                        },
                        {
                            lable: 'Xã Long An',
                            value: '26404',
                        },
                        {
                            lable: 'Xã Long Phước',
                            value: '26413',
                        },
                        {
                            lable: 'Xã Long Đức',
                            value: '26389',
                        },
                        {
                            lable: 'Xã Lộc An',
                            value: '26392',
                        },
                        {
                            lable: 'Xã Phước Bình',
                            value: '26416',
                        },
                        {
                            lable: 'Xã Phước Thái',
                            value: '26422',
                        },
                        {
                            lable: 'Xã Tam An',
                            value: '26398',
                        },
                        {
                            lable: 'Xã Tân Hiệp',
                            value: '26419',
                        },
                    ],
                },
                {
                    lable: 'Huyện Xuân Lộc',
                    value: '741',
                    wards: [
                        {
                            lable: 'Thị trấn Gia Ray',
                            value: '26425',
                        },
                        {
                            lable: 'Xã Bảo Hoà',
                            value: '26464',
                        },
                        {
                            lable: 'Xã Lang Minh',
                            value: '26467',
                        },
                        {
                            lable: 'Xã Suối Cao',
                            value: '26431',
                        },
                        {
                            lable: 'Xã Suối Cát',
                            value: '26452',
                        },
                        {
                            lable: 'Xã Xuân Bắc',
                            value: '26428',
                        },
                        {
                            lable: 'Xã Xuân Hiệp',
                            value: '26455',
                        },
                        {
                            lable: 'Xã Xuân Hòa',
                            value: '26443',
                        },
                        {
                            lable: 'Xã Xuân Hưng',
                            value: '26446',
                        },
                        {
                            lable: 'Xã Xuân Phú',
                            value: '26458',
                        },
                        {
                            lable: 'Xã Xuân Thành',
                            value: '26434',
                        },
                        {
                            lable: 'Xã Xuân Thọ',
                            value: '26437',
                        },
                        {
                            lable: 'Xã Xuân Trường',
                            value: '26440',
                        },
                        {
                            lable: 'Xã Xuân Tâm',
                            value: '26449',
                        },
                        {
                            lable: 'Xã Xuân Định',
                            value: '26461',
                        },
                    ],
                },
                {
                    lable: 'Huyện Nhơn Trạch',
                    value: '742',
                    wards: [
                        {
                            lable: 'Thị trấn Hiệp Phước',
                            value: '26479',
                        },
                        {
                            lable: 'Xã Long Thọ',
                            value: '26494',
                        },
                        {
                            lable: 'Xã Long Tân',
                            value: '26473',
                        },
                        {
                            lable: 'Xã Phú Hội',
                            value: '26485',
                        },
                        {
                            lable: 'Xã Phú Hữu',
                            value: '26482',
                        },
                        {
                            lable: 'Xã Phú Thạnh',
                            value: '26488',
                        },
                        {
                            lable: 'Xã Phú Đông',
                            value: '26491',
                        },
                        {
                            lable: 'Xã Phước An',
                            value: '26503',
                        },
                        {
                            lable: 'Xã Phước Khánh',
                            value: '26500',
                        },
                        {
                            lable: 'Xã Phước Thiền',
                            value: '26470',
                        },
                        {
                            lable: 'Xã Vĩnh Thanh',
                            value: '26497',
                        },
                        {
                            lable: 'Xã Đại Phước',
                            value: '26476',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Đồng Tháp',
            value: '87',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Cao Lãnh',
                    value: '866',
                    wards: [
                        {
                            lable: 'Phường 1',
                            value: '29866',
                        },
                        {
                            lable: 'Phường 11',
                            value: '29863',
                        },
                        {
                            lable: 'Phường 2',
                            value: '29869',
                        },
                        {
                            lable: 'Phường 3',
                            value: '29875',
                        },
                        {
                            lable: 'Phường 4',
                            value: '29872',
                        },
                        {
                            lable: 'Phường 6',
                            value: '29878',
                        },
                        {
                            lable: 'Phường Hoà Thuận',
                            value: '29892',
                        },
                        {
                            lable: 'Phường Mỹ Phú',
                            value: '29888',
                        },
                        {
                            lable: 'Xã Hòa An',
                            value: '29893',
                        },
                        {
                            lable: 'Xã Mỹ Ngãi',
                            value: '29881',
                        },
                        {
                            lable: 'Xã Mỹ Trà',
                            value: '29887',
                        },
                        {
                            lable: 'Xã Mỹ Tân',
                            value: '29884',
                        },
                        {
                            lable: 'Xã Tân Thuận Tây',
                            value: '29890',
                        },
                        {
                            lable: 'Xã Tân Thuận Đông',
                            value: '29896',
                        },
                        {
                            lable: 'Xã Tịnh Thới',
                            value: '29899',
                        },
                    ],
                },
                {
                    lable: 'Thành phố Sa Đéc',
                    value: '867',
                    wards: [
                        {
                            lable: 'Phường 1',
                            value: '29905',
                        },
                        {
                            lable: 'Phường 2',
                            value: '29911',
                        },
                        {
                            lable: 'Phường 3',
                            value: '29902',
                        },
                        {
                            lable: 'Phường 4',
                            value: '29908',
                        },
                        {
                            lable: 'Phường An Hoà',
                            value: '29919',
                        },
                        {
                            lable: 'Phường Tân Quy Đông',
                            value: '29917',
                        },
                        {
                            lable: 'Xã Tân Khánh Đông',
                            value: '29914',
                        },
                        {
                            lable: 'Xã Tân Phú Đông',
                            value: '29923',
                        },
                        {
                            lable: 'Xã Tân Quy Tây',
                            value: '29920',
                        },
                    ],
                },
                {
                    lable: 'Thành phố Hồng Ngự',
                    value: '868',
                    wards: [
                        {
                            lable: 'Phường An Bình A',
                            value: '29989',
                        },
                        {
                            lable: 'Phường An Bình B',
                            value: '29986',
                        },
                        {
                            lable: 'Phường An Lạc',
                            value: '29978',
                        },
                        {
                            lable: 'Phường An Lộc',
                            value: '29954',
                        },
                        {
                            lable: 'Phường An Thạnh',
                            value: '29955',
                        },
                        {
                            lable: 'Xã Bình Thạnh',
                            value: '29959',
                        },
                        {
                            lable: 'Xã Tân Hội',
                            value: '29965',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tân Hồng',
                    value: '869',
                    wards: [
                        {
                            lable: 'Thị trấn Sa Rài',
                            value: '29926',
                        },
                        {
                            lable: 'Xã An Phước',
                            value: '29950',
                        },
                        {
                            lable: 'Xã Bình Phú',
                            value: '29935',
                        },
                        {
                            lable: 'Xã Thông Bình',
                            value: '29932',
                        },
                        {
                            lable: 'Xã Tân Công Chí',
                            value: '29947',
                        },
                        {
                            lable: 'Xã Tân Hộ Cơ',
                            value: '29929',
                        },
                        {
                            lable: 'Xã Tân Phước',
                            value: '29944',
                        },
                        {
                            lable: 'Xã Tân Thành A',
                            value: '29938',
                        },
                        {
                            lable: 'Xã Tân Thành B',
                            value: '29941',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hồng Ngự',
                    value: '870',
                    wards: [
                        {
                            lable: 'Thị trấn Thường Thới Tiền',
                            value: '29971',
                        },
                        {
                            lable: 'Xã Long Khánh A',
                            value: '29980',
                        },
                        {
                            lable: 'Xã Long Khánh B',
                            value: '29983',
                        },
                        {
                            lable: 'Xã Long Thuận',
                            value: '29992',
                        },
                        {
                            lable: 'Xã Phú Thuận A',
                            value: '29998',
                        },
                        {
                            lable: 'Xã Phú Thuận B',
                            value: '29995',
                        },
                        {
                            lable: 'Xã Thường Lạc',
                            value: '29977',
                        },
                        {
                            lable: 'Xã Thường Phước 1',
                            value: '29956',
                        },
                        {
                            lable: 'Xã Thường Phước 2',
                            value: '29974',
                        },
                        {
                            lable: 'Xã Thường Thới Hậu A',
                            value: '29962',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tam Nông',
                    value: '871',
                    wards: [
                        {
                            lable: 'Thị trấn Tràm Chim',
                            value: '30001',
                        },
                        {
                            lable: 'Xã An Hòa',
                            value: '30019',
                        },
                        {
                            lable: 'Xã An Long',
                            value: '30022',
                        },
                        {
                            lable: 'Xã Hoà Bình',
                            value: '30004',
                        },
                        {
                            lable: 'Xã Phú Cường',
                            value: '30025',
                        },
                        {
                            lable: 'Xã Phú Hiệp',
                            value: '30010',
                        },
                        {
                            lable: 'Xã Phú Ninh',
                            value: '30028',
                        },
                        {
                            lable: 'Xã Phú Thành A',
                            value: '30034',
                        },
                        {
                            lable: 'Xã Phú Thành B',
                            value: '30016',
                        },
                        {
                            lable: 'Xã Phú Thọ',
                            value: '30031',
                        },
                        {
                            lable: 'Xã Phú Đức',
                            value: '30013',
                        },
                        {
                            lable: 'Xã Tân Công Sính',
                            value: '30007',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tháp Mười',
                    value: '872',
                    wards: [
                        {
                            lable: 'Thị trấn Mỹ An',
                            value: '30037',
                        },
                        {
                            lable: 'Xã Hưng Thạnh',
                            value: '30043',
                        },
                        {
                            lable: 'Xã Láng Biển',
                            value: '30070',
                        },
                        {
                            lable: 'Xã Mỹ An',
                            value: '30064',
                        },
                        {
                            lable: 'Xã Mỹ Hòa',
                            value: '30052',
                        },
                        {
                            lable: 'Xã Mỹ Quý',
                            value: '30055',
                        },
                        {
                            lable: 'Xã Mỹ Đông',
                            value: '30058',
                        },
                        {
                            lable: 'Xã Phú Điền',
                            value: '30067',
                        },
                        {
                            lable: 'Xã Thanh Mỹ',
                            value: '30073',
                        },
                        {
                            lable: 'Xã Thạnh Lợi',
                            value: '30040',
                        },
                        {
                            lable: 'Xã Trường Xuân',
                            value: '30046',
                        },
                        {
                            lable: 'Xã Tân Kiều',
                            value: '30049',
                        },
                        {
                            lable: 'Xã Đốc Binh Kiều',
                            value: '30061',
                        },
                    ],
                },
                {
                    lable: 'Huyện Cao Lãnh',
                    value: '873',
                    wards: [
                        {
                            lable: 'Thị trấn Mỹ Thọ',
                            value: '30076',
                        },
                        {
                            lable: 'Xã An Bình',
                            value: '30106',
                        },
                        {
                            lable: 'Xã Ba Sao',
                            value: '30085',
                        },
                        {
                            lable: 'Xã Bình Hàng Trung',
                            value: '30118',
                        },
                        {
                            lable: 'Xã Bình Hàng Tây',
                            value: '30124',
                        },
                        {
                            lable: 'Xã Bình Thạnh',
                            value: '30127',
                        },
                        {
                            lable: 'Xã Gáo Giồng',
                            value: '30079',
                        },
                        {
                            lable: 'Xã Mỹ Hiệp',
                            value: '30112',
                        },
                        {
                            lable: 'Xã Mỹ Hội',
                            value: '30109',
                        },
                        {
                            lable: 'Xã Mỹ Long',
                            value: '30115',
                        },
                        {
                            lable: 'Xã Mỹ Thọ',
                            value: '30100',
                        },
                        {
                            lable: 'Xã Mỹ Xương',
                            value: '30121',
                        },
                        {
                            lable: 'Xã Nhị Mỹ',
                            value: '30097',
                        },
                        {
                            lable: 'Xã Phong Mỹ',
                            value: '30088',
                        },
                        {
                            lable: 'Xã Phương Thịnh',
                            value: '30082',
                        },
                        {
                            lable: 'Xã Phương Trà',
                            value: '30094',
                        },
                        {
                            lable: 'Xã Tân Hội Trung',
                            value: '30103',
                        },
                        {
                            lable: 'Xã Tân Nghĩa',
                            value: '30091',
                        },
                    ],
                },
                {
                    lable: 'Huyện Thanh Bình',
                    value: '874',
                    wards: [
                        {
                            lable: 'Thị trấn Thanh Bình',
                            value: '30130',
                        },
                        {
                            lable: 'Xã An Phong',
                            value: '30139',
                        },
                        {
                            lable: 'Xã Bình Thành',
                            value: '30163',
                        },
                        {
                            lable: 'Xã Bình Tấn',
                            value: '30148',
                        },
                        {
                            lable: 'Xã Phú Lợi',
                            value: '30142',
                        },
                        {
                            lable: 'Xã Tân Bình',
                            value: '30154',
                        },
                        {
                            lable: 'Xã Tân Huề',
                            value: '30151',
                        },
                        {
                            lable: 'Xã Tân Hòa',
                            value: '30136',
                        },
                        {
                            lable: 'Xã Tân Long',
                            value: '30166',
                        },
                        {
                            lable: 'Xã Tân Mỹ',
                            value: '30145',
                        },
                        {
                            lable: 'Xã Tân Phú',
                            value: '30160',
                        },
                        {
                            lable: 'Xã Tân Quới',
                            value: '30133',
                        },
                        {
                            lable: 'Xã Tân Thạnh',
                            value: '30157',
                        },
                    ],
                },
                {
                    lable: 'Huyện Lấp Vò',
                    value: '875',
                    wards: [
                        {
                            lable: 'Thị trấn Lấp Vò',
                            value: '30169',
                        },
                        {
                            lable: 'Xã Bình Thành',
                            value: '30193',
                        },
                        {
                            lable: 'Xã Bình Thạnh Trung',
                            value: '30205',
                        },
                        {
                            lable: 'Xã Hội An Đông',
                            value: '30202',
                        },
                        {
                            lable: 'Xã Long Hưng A',
                            value: '30184',
                        },
                        {
                            lable: 'Xã Long Hưng B',
                            value: '30190',
                        },
                        {
                            lable: 'Xã Mỹ An Hưng A',
                            value: '30172',
                        },
                        {
                            lable: 'Xã Mỹ An Hưng B',
                            value: '30178',
                        },
                        {
                            lable: 'Xã Tân  Khánh Trung',
                            value: '30181',
                        },
                        {
                            lable: 'Xã Tân Mỹ',
                            value: '30175',
                        },
                        {
                            lable: 'Xã Vĩnh Thạnh',
                            value: '30187',
                        },
                        {
                            lable: 'Xã Định An',
                            value: '30196',
                        },
                        {
                            lable: 'Xã Định Yên',
                            value: '30199',
                        },
                    ],
                },
                {
                    lable: 'Huyện Lai Vung',
                    value: '876',
                    wards: [
                        {
                            lable: 'Thị trấn Lai Vung',
                            value: '30208',
                        },
                        {
                            lable: 'Xã Hòa Long',
                            value: '30223',
                        },
                        {
                            lable: 'Xã Hòa Thành',
                            value: '30214',
                        },
                        {
                            lable: 'Xã Long Hậu',
                            value: '30217',
                        },
                        {
                            lable: 'Xã Long Thắng',
                            value: '30229',
                        },
                        {
                            lable: 'Xã Phong Hòa',
                            value: '30241',
                        },
                        {
                            lable: 'Xã Tân Dương',
                            value: '30211',
                        },
                        {
                            lable: 'Xã Tân Hòa',
                            value: '30235',
                        },
                        {
                            lable: 'Xã Tân Phước',
                            value: '30220',
                        },
                        {
                            lable: 'Xã Tân Thành',
                            value: '30226',
                        },
                        {
                            lable: 'Xã Vĩnh Thới',
                            value: '30232',
                        },
                        {
                            lable: 'Xã Định Hòa',
                            value: '30238',
                        },
                    ],
                },
                {
                    lable: 'Huyện Châu Thành',
                    value: '877',
                    wards: [
                        {
                            lable: 'Thị trấn Cái Tàu Hạ',
                            value: '30244',
                        },
                        {
                            lable: 'Xã An Hiệp',
                            value: '30247',
                        },
                        {
                            lable: 'Xã An Khánh',
                            value: '30271',
                        },
                        {
                            lable: 'Xã An Nhơn',
                            value: '30250',
                        },
                        {
                            lable: 'Xã An Phú Thuận',
                            value: '30265',
                        },
                        {
                            lable: 'Xã Hòa Tân',
                            value: '30277',
                        },
                        {
                            lable: 'Xã Phú Hựu',
                            value: '30268',
                        },
                        {
                            lable: 'Xã Phú Long',
                            value: '30262',
                        },
                        {
                            lable: 'Xã Tân Bình',
                            value: '30256',
                        },
                        {
                            lable: 'Xã Tân Nhuận Đông',
                            value: '30253',
                        },
                        {
                            lable: 'Xã Tân Phú',
                            value: '30274',
                        },
                        {
                            lable: 'Xã Tân Phú Trung',
                            value: '30259',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Gia Lai',
            value: '64',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Pleiku',
                    value: '622',
                    wards: [
                        {
                            lable: 'Phường Chi Lăng',
                            value: '23586',
                        },
                        {
                            lable: 'Phường Diên Hồng',
                            value: '23560',
                        },
                        {
                            lable: 'Phường Hoa Lư',
                            value: '23572',
                        },
                        {
                            lable: 'Phường Hội Phú',
                            value: '23569',
                        },
                        {
                            lable: 'Phường Hội Thương',
                            value: '23566',
                        },
                        {
                            lable: 'Phường Ia Kring',
                            value: '23563',
                        },
                        {
                            lable: 'Phường Phù Đổng',
                            value: '23570',
                        },
                        {
                            lable: 'Phường Thắng Lợi',
                            value: '23582',
                        },
                        {
                            lable: 'Phường Thống Nhất',
                            value: '23578',
                        },
                        {
                            lable: 'Phường Trà Bá',
                            value: '23581',
                        },
                        {
                            lable: 'Phường Tây Sơn',
                            value: '23575',
                        },
                        {
                            lable: 'Phường Yên Thế',
                            value: '23584',
                        },
                        {
                            lable: 'Phường Yên Đỗ',
                            value: '23557',
                        },
                        {
                            lable: 'Phường Đống Đa',
                            value: '23579',
                        },
                        {
                            lable: 'Xã An Phú',
                            value: '23602',
                        },
                        {
                            lable: 'Xã Biển Hồ',
                            value: '23590',
                        },
                        {
                            lable: 'Xã Chư Á',
                            value: '23599',
                        },
                        {
                            lable: 'Xã Diên Phú',
                            value: '23605',
                        },
                        {
                            lable: 'Xã Gào',
                            value: '23611',
                        },
                        {
                            lable: 'Xã Ia Kênh',
                            value: '23608',
                        },
                        {
                            lable: 'Xã Trà Đa',
                            value: '23596',
                        },
                        {
                            lable: 'Xã Tân Sơn',
                            value: '23593',
                        },
                    ],
                },
                {
                    lable: 'Thị xã An Khê',
                    value: '623',
                    wards: [
                        {
                            lable: 'Phường An Bình',
                            value: '23614',
                        },
                        {
                            lable: 'Phường An Phú',
                            value: '23620',
                        },
                        {
                            lable: 'Phường An Phước',
                            value: '23630',
                        },
                        {
                            lable: 'Phường An Tân',
                            value: '23623',
                        },
                        {
                            lable: 'Phường Ngô Mây',
                            value: '23633',
                        },
                        {
                            lable: 'Phường Tây Sơn',
                            value: '23617',
                        },
                        {
                            lable: 'Xã Cửu An',
                            value: '23629',
                        },
                        {
                            lable: 'Xã Song An',
                            value: '23632',
                        },
                        {
                            lable: 'Xã Thành An',
                            value: '23635',
                        },
                        {
                            lable: 'Xã Tú An',
                            value: '23626',
                        },
                        {
                            lable: 'Xã Xuân An',
                            value: '23627',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Ayun Pa',
                    value: '624',
                    wards: [
                        {
                            lable: 'Phường Cheo Reo',
                            value: '24041',
                        },
                        {
                            lable: 'Phường Hòa Bình',
                            value: '24042',
                        },
                        {
                            lable: 'Phường Sông Bờ',
                            value: '24045',
                        },
                        {
                            lable: 'Phường Đoàn Kết',
                            value: '24044',
                        },
                        {
                            lable: 'Xã Chư Băh',
                            value: '24065',
                        },
                        {
                            lable: 'Xã Ia RBol',
                            value: '24064',
                        },
                        {
                            lable: 'Xã Ia RTô',
                            value: '24070',
                        },
                        {
                            lable: 'Xã Ia Sao',
                            value: '24073',
                        },
                    ],
                },
                {
                    lable: 'Huyện KBang',
                    value: '625',
                    wards: [
                        {
                            lable: 'Thị trấn KBang',
                            value: '23638',
                        },
                        {
                            lable: 'Xã KRong',
                            value: '23650',
                        },
                        {
                            lable: 'Xã Kon Pne',
                            value: '23641',
                        },
                        {
                            lable: 'Xã Kông Lơng Khơng',
                            value: '23668',
                        },
                        {
                            lable: 'Xã Kông Pla',
                            value: '23671',
                        },
                        {
                            lable: 'Xã Lơ Ku',
                            value: '23656',
                        },
                        {
                            lable: 'Xã Nghĩa An',
                            value: '23662',
                        },
                        {
                            lable: 'Xã Sơ Pai',
                            value: '23653',
                        },
                        {
                            lable: 'Xã Sơn Lang',
                            value: '23647',
                        },
                        {
                            lable: 'Xã Tơ Tung',
                            value: '23665',
                        },
                        {
                            lable: 'Xã Đak SMar',
                            value: '23660',
                        },
                        {
                            lable: 'Xã Đông',
                            value: '23659',
                        },
                        {
                            lable: 'Xã Đăk HLơ',
                            value: '23674',
                        },
                        {
                            lable: 'Xã Đăk Roong',
                            value: '23644',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đăk Đoa',
                    value: '626',
                    wards: [
                        {
                            lable: 'Thị trấn Đăk Đoa',
                            value: '23677',
                        },
                        {
                            lable: 'Xã A Dơk',
                            value: '23710',
                        },
                        {
                            lable: 'Xã Glar',
                            value: '23707',
                        },
                        {
                            lable: 'Xã H\' Neng',
                            value: '23701',
                        },
                        {
                            lable: 'Xã HNol',
                            value: '23714',
                        },
                        {
                            lable: 'Xã Hà Bầu',
                            value: '23692',
                        },
                        {
                            lable: 'Xã Hà Đông',
                            value: '23680',
                        },
                        {
                            lable: 'Xã Hải Yang',
                            value: '23686',
                        },
                        {
                            lable: 'Xã Ia Băng',
                            value: '23719',
                        },
                        {
                            lable: 'Xã Ia Pết',
                            value: '23716',
                        },
                        {
                            lable: 'Xã K\' Dang',
                            value: '23698',
                        },
                        {
                            lable: 'Xã Kon Gang',
                            value: '23689',
                        },
                        {
                            lable: 'Xã Nam Yang',
                            value: '23695',
                        },
                        {
                            lable: 'Xã Trang',
                            value: '23713',
                        },
                        {
                            lable: 'Xã Tân Bình',
                            value: '23704',
                        },
                        {
                            lable: 'Xã Đăk Krong',
                            value: '23684',
                        },
                        {
                            lable: 'Xã Đăk Sơmei',
                            value: '23683',
                        },
                    ],
                },
                {
                    lable: 'Huyện Chư Păh',
                    value: '627',
                    wards: [
                        {
                            lable: 'Thị trấn Ia Ly',
                            value: '23734',
                        },
                        {
                            lable: 'Thị trấn Phú Hòa',
                            value: '23722',
                        },
                        {
                            lable: 'Xã Chư Đăng Ya',
                            value: '23746',
                        },
                        {
                            lable: 'Xã Hà Tây',
                            value: '23725',
                        },
                        {
                            lable: 'Xã Hòa Phú',
                            value: '23743',
                        },
                        {
                            lable: 'Xã Ia Ka',
                            value: '23749',
                        },
                        {
                            lable: 'Xã Ia Khươl',
                            value: '23728',
                        },
                        {
                            lable: 'Xã Ia Kreng',
                            value: '23738',
                        },
                        {
                            lable: 'Xã Ia Mơ Nông',
                            value: '23737',
                        },
                        {
                            lable: 'Xã Ia Nhin',
                            value: '23752',
                        },
                        {
                            lable: 'Xã Ia Phí',
                            value: '23731',
                        },
                        {
                            lable: 'Xã Nghĩa Hòa',
                            value: '23755',
                        },
                        {
                            lable: 'Xã Nghĩa Hưng',
                            value: '23761',
                        },
                        {
                            lable: 'Xã Đăk Tơ Ver',
                            value: '23740',
                        },
                    ],
                },
                {
                    lable: 'Huyện Ia Grai',
                    value: '628',
                    wards: [
                        {
                            lable: 'Thị trấn Ia Kha',
                            value: '23764',
                        },
                        {
                            lable: 'Xã Ia Bă',
                            value: '23771',
                        },
                        {
                            lable: 'Xã Ia Chia',
                            value: '23788',
                        },
                        {
                            lable: 'Xã Ia Dêr',
                            value: '23785',
                        },
                        {
                            lable: 'Xã Ia Grăng',
                            value: '23778',
                        },
                        {
                            lable: 'Xã Ia Hrung',
                            value: '23770',
                        },
                        {
                            lable: 'Xã Ia KRai',
                            value: '23776',
                        },
                        {
                            lable: 'Xã Ia Khai',
                            value: '23773',
                        },
                        {
                            lable: 'Xã Ia O',
                            value: '23782',
                        },
                        {
                            lable: 'Xã Ia Pếch',
                            value: '23791',
                        },
                        {
                            lable: 'Xã Ia Sao',
                            value: '23767',
                        },
                        {
                            lable: 'Xã Ia Tô',
                            value: '23779',
                        },
                        {
                            lable: 'Xã Ia Yok',
                            value: '23768',
                        },
                    ],
                },
                {
                    lable: 'Huyện Mang Yang',
                    value: '629',
                    wards: [
                        {
                            lable: 'Thị trấn Kon Dơng',
                            value: '23794',
                        },
                        {
                            lable: 'Xã Ayun',
                            value: '23797',
                        },
                        {
                            lable: 'Xã Hra',
                            value: '23800',
                        },
                        {
                            lable: 'Xã Kon Chiêng',
                            value: '23818',
                        },
                        {
                            lable: 'Xã Kon Thụp',
                            value: '23812',
                        },
                        {
                            lable: 'Xã Lơ Pang',
                            value: '23809',
                        },
                        {
                            lable: 'Xã Đak Jơ Ta',
                            value: '23798',
                        },
                        {
                            lable: 'Xã Đak Ta Ley',
                            value: '23799',
                        },
                        {
                            lable: 'Xã Đê Ar',
                            value: '23815',
                        },
                        {
                            lable: 'Xã Đăk Djrăng',
                            value: '23806',
                        },
                        {
                            lable: 'Xã Đăk Trôi',
                            value: '23821',
                        },
                        {
                            lable: 'Xã Đăk Yă',
                            value: '23803',
                        },
                    ],
                },
                {
                    lable: 'Huyện Kông Chro',
                    value: '630',
                    wards: [
                        {
                            lable: 'Thị trấn Kông Chro',
                            value: '23824',
                        },
                        {
                            lable: 'Xã An Trung',
                            value: '23830',
                        },
                        {
                            lable: 'Xã Chơ Long',
                            value: '23851',
                        },
                        {
                            lable: 'Xã Chư Krêy',
                            value: '23827',
                        },
                        {
                            lable: 'Xã Kông Yang',
                            value: '23833',
                        },
                        {
                            lable: 'Xã SRó',
                            value: '23839',
                        },
                        {
                            lable: 'Xã Ya Ma',
                            value: '23848',
                        },
                        {
                            lable: 'Xã Yang Nam',
                            value: '23854',
                        },
                        {
                            lable: 'Xã Yang Trung',
                            value: '23845',
                        },
                        {
                            lable: 'Xã Đăk Pling',
                            value: '23843',
                        },
                        {
                            lable: 'Xã Đăk Pơ Pho',
                            value: '23846',
                        },
                        {
                            lable: 'Xã Đăk Song',
                            value: '23842',
                        },
                        {
                            lable: 'Xã Đăk Tơ Pang',
                            value: '23836',
                        },
                        {
                            lable: 'Xã Đắk Kơ Ning',
                            value: '23840',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đức Cơ',
                    value: '631',
                    wards: [
                        {
                            lable: 'Thị trấn Chư Ty',
                            value: '23857',
                        },
                        {
                            lable: 'Xã Ia Din',
                            value: '23866',
                        },
                        {
                            lable: 'Xã Ia Dom',
                            value: '23872',
                        },
                        {
                            lable: 'Xã Ia Dơk',
                            value: '23860',
                        },
                        {
                            lable: 'Xã Ia Kla',
                            value: '23869',
                        },
                        {
                            lable: 'Xã Ia Kriêng',
                            value: '23878',
                        },
                        {
                            lable: 'Xã Ia Krêl',
                            value: '23863',
                        },
                        {
                            lable: 'Xã Ia Lang',
                            value: '23875',
                        },
                        {
                            lable: 'Xã Ia Nan',
                            value: '23884',
                        },
                        {
                            lable: 'Xã Ia Pnôn',
                            value: '23881',
                        },
                    ],
                },
                {
                    lable: 'Huyện Chư Prông',
                    value: '632',
                    wards: [
                        {
                            lable: 'Thị trấn Chư Prông',
                            value: '23887',
                        },
                        {
                            lable: 'Xã Bàu Cạn',
                            value: '23899',
                        },
                        {
                            lable: 'Xã Bình Giáo',
                            value: '23890',
                        },
                        {
                            lable: 'Xã Ia Bang',
                            value: '23924',
                        },
                        {
                            lable: 'Xã Ia Boòng',
                            value: '23911',
                        },
                        {
                            lable: 'Xã Ia Băng',
                            value: '23905',
                        },
                        {
                            lable: 'Xã Ia Drăng',
                            value: '23893',
                        },
                        {
                            lable: 'Xã Ia Ga',
                            value: '23929',
                        },
                        {
                            lable: 'Xã Ia Kly',
                            value: '23888',
                        },
                        {
                            lable: 'Xã Ia Lâu',
                            value: '23932',
                        },
                        {
                            lable: 'Xã Ia Me',
                            value: '23920',
                        },
                        {
                            lable: 'Xã Ia Mơ',
                            value: '23938',
                        },
                        {
                            lable: 'Xã Ia O',
                            value: '23914',
                        },
                        {
                            lable: 'Xã Ia Phìn',
                            value: '23902',
                        },
                        {
                            lable: 'Xã Ia Pia',
                            value: '23926',
                        },
                        {
                            lable: 'Xã Ia Piơr',
                            value: '23935',
                        },
                        {
                            lable: 'Xã Ia Púch',
                            value: '23917',
                        },
                        {
                            lable: 'Xã Ia Tôr',
                            value: '23908',
                        },
                        {
                            lable: 'Xã Ia Vê',
                            value: '23923',
                        },
                        {
                            lable: 'Xã Thăng Hưng',
                            value: '23896',
                        },
                    ],
                },
                {
                    lable: 'Huyện Chư Sê',
                    value: '633',
                    wards: [
                        {
                            lable: 'Thị trấn Chư Sê',
                            value: '23941',
                        },
                        {
                            lable: 'Xã AL Bá',
                            value: '23953',
                        },
                        {
                            lable: 'Xã AYun',
                            value: '23956',
                        },
                        {
                            lable: 'Xã Bar Măih',
                            value: '23946',
                        },
                        {
                            lable: 'Xã Bờ Ngoong',
                            value: '23947',
                        },
                        {
                            lable: 'Xã Chư Pơng',
                            value: '23945',
                        },
                        {
                            lable: 'Xã Dun',
                            value: '23965',
                        },
                        {
                            lable: 'Xã H Bông',
                            value: '23968',
                        },
                        {
                            lable: 'Xã Ia Blang',
                            value: '23962',
                        },
                        {
                            lable: 'Xã Ia Glai',
                            value: '23950',
                        },
                        {
                            lable: 'Xã Ia HLốp',
                            value: '23959',
                        },
                        {
                            lable: 'Xã Ia Ko',
                            value: '23977',
                        },
                        {
                            lable: 'Xã Ia Pal',
                            value: '23966',
                        },
                        {
                            lable: 'Xã Ia Tiêm',
                            value: '23944',
                        },
                        {
                            lable: 'Xã Kông HTok',
                            value: '23954',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đăk Pơ',
                    value: '634',
                    wards: [
                        {
                            lable: 'Thị trấn Đak Pơ',
                            value: '23995',
                        },
                        {
                            lable: 'Xã An Thành',
                            value: '23992',
                        },
                        {
                            lable: 'Xã Cư An',
                            value: '24001',
                        },
                        {
                            lable: 'Xã Hà Tam',
                            value: '23989',
                        },
                        {
                            lable: 'Xã Phú An',
                            value: '24007',
                        },
                        {
                            lable: 'Xã Tân An',
                            value: '24004',
                        },
                        {
                            lable: 'Xã Ya Hội',
                            value: '24010',
                        },
                        {
                            lable: 'Xã Yang Bắc',
                            value: '23998',
                        },
                    ],
                },
                {
                    lable: 'Huyện Ia Pa',
                    value: '635',
                    wards: [
                        {
                            lable: 'Xã Chư Mố',
                            value: '24025',
                        },
                        {
                            lable: 'Xã Chư Răng',
                            value: '24016',
                        },
                        {
                            lable: 'Xã Ia Broăi',
                            value: '24034',
                        },
                        {
                            lable: 'Xã Ia KDăm',
                            value: '24019',
                        },
                        {
                            lable: 'Xã Ia Ma Rơn',
                            value: '24031',
                        },
                        {
                            lable: 'Xã Ia Trok',
                            value: '24037',
                        },
                        {
                            lable: 'Xã Ia Tul',
                            value: '24028',
                        },
                        {
                            lable: 'Xã Kim Tân',
                            value: '24022',
                        },
                        {
                            lable: 'Xã Pờ Tó',
                            value: '24013',
                        },
                    ],
                },
                {
                    lable: 'Huyện Krông Pa',
                    value: '637',
                    wards: [
                        {
                            lable: 'Thị trấn Phú Túc',
                            value: '24076',
                        },
                        {
                            lable: 'Xã Chư Drăng',
                            value: '24094',
                        },
                        {
                            lable: 'Xã Chư Gu',
                            value: '24085',
                        },
                        {
                            lable: 'Xã Chư Ngọc',
                            value: '24106',
                        },
                        {
                            lable: 'Xã Chư Rcăm',
                            value: '24112',
                        },
                        {
                            lable: 'Xã Ia HDreh',
                            value: '24100',
                        },
                        {
                            lable: 'Xã Ia Mláh',
                            value: '24091',
                        },
                        {
                            lable: 'Xã Ia RMok',
                            value: '24103',
                        },
                        {
                            lable: 'Xã Ia RSai',
                            value: '24079',
                        },
                        {
                            lable: 'Xã Ia RSươm',
                            value: '24082',
                        },
                        {
                            lable: 'Xã Krông Năng',
                            value: '24115',
                        },
                        {
                            lable: 'Xã Phú Cần',
                            value: '24097',
                        },
                        {
                            lable: 'Xã Uar',
                            value: '24109',
                        },
                        {
                            lable: 'Xã Đất Bằng',
                            value: '24088',
                        },
                    ],
                },
                {
                    lable: 'Huyện Phú Thiện',
                    value: '638',
                    wards: [
                        {
                            lable: 'Thị trấn Phú Thiện',
                            value: '24043',
                        },
                        {
                            lable: 'Xã Ayun Hạ',
                            value: '24048',
                        },
                        {
                            lable: 'Xã Chrôh Pơnan',
                            value: '24060',
                        },
                        {
                            lable: 'Xã Chư A Thai',
                            value: '24046',
                        },
                        {
                            lable: 'Xã Ia Ake',
                            value: '24049',
                        },
                        {
                            lable: 'Xã Ia Hiao',
                            value: '24061',
                        },
                        {
                            lable: 'Xã Ia Peng',
                            value: '24058',
                        },
                        {
                            lable: 'Xã Ia Piar',
                            value: '24055',
                        },
                        {
                            lable: 'Xã Ia Sol',
                            value: '24052',
                        },
                        {
                            lable: 'Xã Ia Yeng',
                            value: '24067',
                        },
                    ],
                },
                {
                    lable: 'Huyện Chư Pưh',
                    value: '639',
                    wards: [
                        {
                            lable: 'Thị trấn Nhơn Hoà',
                            value: '23942',
                        },
                        {
                            lable: 'Xã Chư Don',
                            value: '23980',
                        },
                        {
                            lable: 'Xã Ia BLứ',
                            value: '23987',
                        },
                        {
                            lable: 'Xã Ia Dreng',
                            value: '23974',
                        },
                        {
                            lable: 'Xã Ia Hla',
                            value: '23978',
                        },
                        {
                            lable: 'Xã Ia Hrú',
                            value: '23971',
                        },
                        {
                            lable: 'Xã Ia Le',
                            value: '23986',
                        },
                        {
                            lable: 'Xã Ia Phang',
                            value: '23983',
                        },
                        {
                            lable: 'Xã Ia Rong',
                            value: '23972',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Hà Giang',
            value: '02',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Hà Giang',
                    value: '024',
                    wards: [
                        {
                            lable: 'Phường Minh Khai',
                            value: '00697',
                        },
                        {
                            lable: 'Phường Nguyễn Trãi',
                            value: '00694',
                        },
                        {
                            lable: 'Phường Ngọc Hà',
                            value: '00692',
                        },
                        {
                            lable: 'Phường Quang Trung',
                            value: '00688',
                        },
                        {
                            lable: 'Phường Trần Phú',
                            value: '00691',
                        },
                        {
                            lable: 'Xã Ngọc Đường',
                            value: '00700',
                        },
                        {
                            lable: 'Xã Phương Thiện',
                            value: '00949',
                        },
                        {
                            lable: 'Xã Phương Độ',
                            value: '00946',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đồng Văn',
                    value: '026',
                    wards: [
                        {
                            lable: 'Thị trấn Phó Bảng',
                            value: '00712',
                        },
                        {
                            lable: 'Thị trấn Đồng Văn',
                            value: '00721',
                        },
                        {
                            lable: 'Xã Hố Quáng Phìn',
                            value: '00757',
                        },
                        {
                            lable: 'Xã Lũng Cú',
                            value: '00715',
                        },
                        {
                            lable: 'Xã Lũng Phìn',
                            value: '00763',
                        },
                        {
                            lable: 'Xã Lũng Thầu',
                            value: '00754',
                        },
                        {
                            lable: 'Xã Lũng Táo',
                            value: '00724',
                        },
                        {
                            lable: 'Xã Má Lé',
                            value: '00718',
                        },
                        {
                            lable: 'Xã Phố Cáo',
                            value: '00745',
                        },
                        {
                            lable: 'Xã Phố Là',
                            value: '00727',
                        },
                        {
                            lable: 'Xã Sính Lủng',
                            value: '00748',
                        },
                        {
                            lable: 'Xã Sảng Tủng',
                            value: '00751',
                        },
                        {
                            lable: 'Xã Sủng Là',
                            value: '00733',
                        },
                        {
                            lable: 'Xã Sủng Trái',
                            value: '00766',
                        },
                        {
                            lable: 'Xã Thài Phìn Tủng',
                            value: '00730',
                        },
                        {
                            lable: 'Xã Tả Lủng',
                            value: '00742',
                        },
                        {
                            lable: 'Xã Tả Phìn',
                            value: '00739',
                        },
                        {
                            lable: 'Xã Vần Chải',
                            value: '00760',
                        },
                        {
                            lable: 'Xã Xà Phìn',
                            value: '00736',
                        },
                    ],
                },
                {
                    lable: 'Huyện Mèo Vạc',
                    value: '027',
                    wards: [
                        {
                            lable: 'Thị trấn Mèo Vạc',
                            value: '00769',
                        },
                        {
                            lable: 'Xã Cán Chu Phìn',
                            value: '00799',
                        },
                        {
                            lable: 'Xã Giàng Chu Phìn',
                            value: '00784',
                        },
                        {
                            lable: 'Xã Khâu Vai',
                            value: '00814',
                        },
                        {
                            lable: 'Xã Lũng Chinh',
                            value: '00805',
                        },
                        {
                            lable: 'Xã Lũng Pù',
                            value: '00802',
                        },
                        {
                            lable: 'Xã Niêm Sơn',
                            value: '00817',
                        },
                        {
                            lable: 'Xã Niêm Tòng',
                            value: '00815',
                        },
                        {
                            lable: 'Xã Nậm Ban',
                            value: '00811',
                        },
                        {
                            lable: 'Xã Pả Vi',
                            value: '00781',
                        },
                        {
                            lable: 'Xã Pải Lủng',
                            value: '00775',
                        },
                        {
                            lable: 'Xã Sơn Vĩ',
                            value: '00793',
                        },
                        {
                            lable: 'Xã Sủng Máng',
                            value: '00790',
                        },
                        {
                            lable: 'Xã Sủng Trà',
                            value: '00787',
                        },
                        {
                            lable: 'Xã Thượng Phùng',
                            value: '00772',
                        },
                        {
                            lable: 'Xã Tát Ngà',
                            value: '00808',
                        },
                        {
                            lable: 'Xã Tả Lủng',
                            value: '00796',
                        },
                        {
                            lable: 'Xã Xín Cái',
                            value: '00778',
                        },
                    ],
                },
                {
                    lable: 'Huyện Yên Minh',
                    value: '028',
                    wards: [
                        {
                            lable: 'Thị trấn Yên Minh',
                            value: '00820',
                        },
                        {
                            lable: 'Xã Bạch Đích',
                            value: '00832',
                        },
                        {
                            lable: 'Xã Du Già',
                            value: '00871',
                        },
                        {
                            lable: 'Xã Du Tiến',
                            value: '00868',
                        },
                        {
                            lable: 'Xã Hữu Vinh',
                            value: '00841',
                        },
                        {
                            lable: 'Xã Lao Và Chải',
                            value: '00844',
                        },
                        {
                            lable: 'Xã Lũng Hồ',
                            value: '00865',
                        },
                        {
                            lable: 'Xã Mậu Duệ',
                            value: '00847',
                        },
                        {
                            lable: 'Xã Mậu Long',
                            value: '00853',
                        },
                        {
                            lable: 'Xã Na Khê',
                            value: '00835',
                        },
                        {
                            lable: 'Xã Ngam La',
                            value: '00856',
                        },
                        {
                            lable: 'Xã Ngọc Long',
                            value: '00859',
                        },
                        {
                            lable: 'Xã Phú Lũng',
                            value: '00826',
                        },
                        {
                            lable: 'Xã Sủng Thài',
                            value: '00838',
                        },
                        {
                            lable: 'Xã Sủng Tráng',
                            value: '00829',
                        },
                        {
                            lable: 'Xã Thắng Mố',
                            value: '00823',
                        },
                        {
                            lable: 'Xã Đông Minh',
                            value: '00850',
                        },
                        {
                            lable: 'Xã Đường Thượng',
                            value: '00862',
                        },
                    ],
                },
                {
                    lable: 'Huyện Quản Bạ',
                    value: '029',
                    wards: [
                        {
                            lable: 'Thị trấn Tam Sơn',
                            value: '00874',
                        },
                        {
                            lable: 'Xã Bát Đại Sơn',
                            value: '00877',
                        },
                        {
                            lable: 'Xã Cao Mã Pờ',
                            value: '00886',
                        },
                        {
                            lable: 'Xã Cán Tỷ',
                            value: '00883',
                        },
                        {
                            lable: 'Xã Lùng Tám',
                            value: '00901',
                        },
                        {
                            lable: 'Xã Nghĩa Thuận',
                            value: '00880',
                        },
                        {
                            lable: 'Xã Quyết Tiến',
                            value: '00904',
                        },
                        {
                            lable: 'Xã Quản Bạ',
                            value: '00898',
                        },
                        {
                            lable: 'Xã Thanh Vân',
                            value: '00889',
                        },
                        {
                            lable: 'Xã Thái An',
                            value: '00910',
                        },
                        {
                            lable: 'Xã Tùng Vài',
                            value: '00892',
                        },
                        {
                            lable: 'Xã Tả Ván',
                            value: '00907',
                        },
                        {
                            lable: 'Xã Đông Hà',
                            value: '00895',
                        },
                    ],
                },
                {
                    lable: 'Huyện Vị Xuyên',
                    value: '030',
                    wards: [
                        {
                            lable: 'Thị trấn Nông Trường Việt Lâm',
                            value: '00916',
                        },
                        {
                            lable: 'Thị trấn Vị Xuyên',
                            value: '00913',
                        },
                        {
                            lable: 'Xã Bạch Ngọc',
                            value: '00976',
                        },
                        {
                            lable: 'Xã Cao Bồ',
                            value: '00952',
                        },
                        {
                            lable: 'Xã Kim Linh',
                            value: '00709',
                        },
                        {
                            lable: 'Xã Kim Thạch',
                            value: '00703',
                        },
                        {
                            lable: 'Xã Lao Chải',
                            value: '00943',
                        },
                        {
                            lable: 'Xã Linh Hồ',
                            value: '00961',
                        },
                        {
                            lable: 'Xã Minh Tân',
                            value: '00919',
                        },
                        {
                            lable: 'Xã Ngọc Linh',
                            value: '00970',
                        },
                        {
                            lable: 'Xã Ngọc Minh',
                            value: '00973',
                        },
                        {
                            lable: 'Xã Phong Quang',
                            value: '00934',
                        },
                        {
                            lable: 'Xã Phú Linh',
                            value: '00706',
                        },
                        {
                            lable: 'Xã Phương Tiến',
                            value: '00940',
                        },
                        {
                            lable: 'Xã Quảng Ngần',
                            value: '00964',
                        },
                        {
                            lable: 'Xã Thanh Thủy',
                            value: '00928',
                        },
                        {
                            lable: 'Xã Thanh Đức',
                            value: '00931',
                        },
                        {
                            lable: 'Xã Thuận Hoà',
                            value: '00922',
                        },
                        {
                            lable: 'Xã Thượng Sơn',
                            value: '00958',
                        },
                        {
                            lable: 'Xã Trung Thành',
                            value: '00979',
                        },
                        {
                            lable: 'Xã Tùng Bá',
                            value: '00925',
                        },
                        {
                            lable: 'Xã Việt Lâm',
                            value: '00967',
                        },
                        {
                            lable: 'Xã Xín Chải',
                            value: '00937',
                        },
                        {
                            lable: 'Xã Đạo Đức',
                            value: '00955',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bắc Mê',
                    value: '031',
                    wards: [
                        {
                            lable: 'Thị trấn Yên Phú',
                            value: '00991',
                        },
                        {
                            lable: 'Xã Giáp Trung',
                            value: '00985',
                        },
                        {
                            lable: 'Xã Lạc Nông',
                            value: '01000',
                        },
                        {
                            lable: 'Xã Minh Ngọc',
                            value: '00994',
                        },
                        {
                            lable: 'Xã Minh Sơn',
                            value: '00982',
                        },
                        {
                            lable: 'Xã Phiêng Luông',
                            value: '01018',
                        },
                        {
                            lable: 'Xã Phú Nam',
                            value: '01003',
                        },
                        {
                            lable: 'Xã Thượng Tân',
                            value: '01009',
                        },
                        {
                            lable: 'Xã Yên Cường',
                            value: '01006',
                        },
                        {
                            lable: 'Xã Yên Phong',
                            value: '00997',
                        },
                        {
                            lable: 'Xã Yên Định',
                            value: '00988',
                        },
                        {
                            lable: 'Xã Đường Hồng',
                            value: '01015',
                        },
                        {
                            lable: 'Xã Đường Âm',
                            value: '01012',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hoàng Su Phì',
                    value: '032',
                    wards: [
                        {
                            lable: 'Thị trấn Vinh Quang',
                            value: '01021',
                        },
                        {
                            lable: 'Xã Bản Luốc',
                            value: '01063',
                        },
                        {
                            lable: 'Xã Bản Máy',
                            value: '01024',
                        },
                        {
                            lable: 'Xã Bản Nhùng',
                            value: '01069',
                        },
                        {
                            lable: 'Xã Bản Phùng',
                            value: '01036',
                        },
                        {
                            lable: 'Xã Chiến Phố',
                            value: '01042',
                        },
                        {
                            lable: 'Xã Hồ Thầu',
                            value: '01081',
                        },
                        {
                            lable: 'Xã Nam Sơn',
                            value: '01084',
                        },
                        {
                            lable: 'Xã Ngàm Đăng Vài',
                            value: '01066',
                        },
                        {
                            lable: 'Xã Nàng Đôn',
                            value: '01054',
                        },
                        {
                            lable: 'Xã Nậm Dịch',
                            value: '01075',
                        },
                        {
                            lable: 'Xã Nậm Khòa',
                            value: '01093',
                        },
                        {
                            lable: 'Xã Nậm Tỵ',
                            value: '01087',
                        },
                        {
                            lable: 'Xã Pố Lồ',
                            value: '01033',
                        },
                        {
                            lable: 'Xã Pờ Ly Ngài',
                            value: '01057',
                        },
                        {
                            lable: 'Xã Sán Xả Hồ',
                            value: '01060',
                        },
                        {
                            lable: 'Xã Thàng Tín',
                            value: '01027',
                        },
                        {
                            lable: 'Xã Thèn Chu Phìn',
                            value: '01030',
                        },
                        {
                            lable: 'Xã Thông Nguyên',
                            value: '01090',
                        },
                        {
                            lable: 'Xã Tân Tiến',
                            value: '01051',
                        },
                        {
                            lable: 'Xã Túng Sán',
                            value: '01039',
                        },
                        {
                            lable: 'Xã Tả Sử Choóng',
                            value: '01072',
                        },
                        {
                            lable: 'Xã Tụ Nhân',
                            value: '01048',
                        },
                        {
                            lable: 'Xã Đản Ván',
                            value: '01045',
                        },
                    ],
                },
                {
                    lable: 'Huyện Xín Mần',
                    value: '033',
                    wards: [
                        {
                            lable: 'Thị trấn Cốc Pài',
                            value: '01096',
                        },
                        {
                            lable: 'Xã Bản Díu',
                            value: '01102',
                        },
                        {
                            lable: 'Xã Bản Ngò',
                            value: '01135',
                        },
                        {
                            lable: 'Xã Chí Cà',
                            value: '01105',
                        },
                        {
                            lable: 'Xã Chế Là',
                            value: '01138',
                        },
                        {
                            lable: 'Xã Cốc Rế',
                            value: '01123',
                        },
                        {
                            lable: 'Xã Khuôn Lùng',
                            value: '01150',
                        },
                        {
                            lable: 'Xã Nà Chì',
                            value: '01147',
                        },
                        {
                            lable: 'Xã Nàn Ma',
                            value: '01129',
                        },
                        {
                            lable: 'Xã Nàn Xỉn',
                            value: '01099',
                        },
                        {
                            lable: 'Xã Nấm Dẩn',
                            value: '01141',
                        },
                        {
                            lable: 'Xã Pà Vầy Sủ',
                            value: '01120',
                        },
                        {
                            lable: 'Xã Quảng Nguyên',
                            value: '01144',
                        },
                        {
                            lable: 'Xã Thu Tà',
                            value: '01126',
                        },
                        {
                            lable: 'Xã Thèn Phàng',
                            value: '01114',
                        },
                        {
                            lable: 'Xã Trung Thịnh',
                            value: '01117',
                        },
                        {
                            lable: 'Xã Tả Nhìu',
                            value: '01132',
                        },
                        {
                            lable: 'Xã Xín Mần',
                            value: '01108',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bắc Quang',
                    value: '034',
                    wards: [
                        {
                            lable: 'Thị trấn Việt Quang',
                            value: '01153',
                        },
                        {
                            lable: 'Thị trấn Vĩnh Tuy',
                            value: '01156',
                        },
                        {
                            lable: 'Xã Bằng Hành',
                            value: '01186',
                        },
                        {
                            lable: 'Xã Hùng An',
                            value: '01201',
                        },
                        {
                            lable: 'Xã Hữu Sản',
                            value: '01177',
                        },
                        {
                            lable: 'Xã Kim Ngọc',
                            value: '01180',
                        },
                        {
                            lable: 'Xã Liên Hiệp',
                            value: '01192',
                        },
                        {
                            lable: 'Xã Quang Minh',
                            value: '01189',
                        },
                        {
                            lable: 'Xã Thượng Bình',
                            value: '01174',
                        },
                        {
                            lable: 'Xã Tiên Kiều',
                            value: '01207',
                        },
                        {
                            lable: 'Xã Tân Lập',
                            value: '01159',
                        },
                        {
                            lable: 'Xã Tân Quang',
                            value: '01171',
                        },
                        {
                            lable: 'Xã Tân Thành',
                            value: '01162',
                        },
                        {
                            lable: 'Xã Việt Hồng',
                            value: '01198',
                        },
                        {
                            lable: 'Xã Việt Vinh',
                            value: '01183',
                        },
                        {
                            lable: 'Xã Vô Điếm',
                            value: '01195',
                        },
                        {
                            lable: 'Xã Vĩnh Hảo',
                            value: '01210',
                        },
                        {
                            lable: 'Xã Vĩnh Phúc',
                            value: '01213',
                        },
                        {
                            lable: 'Xã Đông Thành',
                            value: '01219',
                        },
                        {
                            lable: 'Xã Đồng Tiến',
                            value: '01165',
                        },
                        {
                            lable: 'Xã Đồng Tâm',
                            value: '01168',
                        },
                        {
                            lable: 'Xã Đồng Yên',
                            value: '01216',
                        },
                        {
                            lable: 'Xã Đức Xuân',
                            value: '01204',
                        },
                    ],
                },
                {
                    lable: 'Huyện Quang Bình',
                    value: '035',
                    wards: [
                        {
                            lable: 'Thị trấn Yên Bình',
                            value: '01237',
                        },
                        {
                            lable: 'Xã Bản Rịa',
                            value: '01231',
                        },
                        {
                            lable: 'Xã Bằng Lang',
                            value: '01246',
                        },
                        {
                            lable: 'Xã Hương Sơn',
                            value: '01252',
                        },
                        {
                            lable: 'Xã Nà Khương',
                            value: '01258',
                        },
                        {
                            lable: 'Xã Tiên Nguyên',
                            value: '01225',
                        },
                        {
                            lable: 'Xã Tiên Yên',
                            value: '01261',
                        },
                        {
                            lable: 'Xã Tân Bắc',
                            value: '01243',
                        },
                        {
                            lable: 'Xã Tân Nam',
                            value: '01228',
                        },
                        {
                            lable: 'Xã Tân Trịnh',
                            value: '01240',
                        },
                        {
                            lable: 'Xã Vĩ Thượng',
                            value: '01264',
                        },
                        {
                            lable: 'Xã Xuân Giang',
                            value: '01255',
                        },
                        {
                            lable: 'Xã Xuân Minh',
                            value: '01222',
                        },
                        {
                            lable: 'Xã Yên Hà',
                            value: '01249',
                        },
                        {
                            lable: 'Xã Yên Thành',
                            value: '01234',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Hà Nam',
            value: '35',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Phủ Lý',
                    value: '347',
                    wards: [
                        {
                            lable: 'Phường Châu Sơn',
                            value: '13318',
                        },
                        {
                            lable: 'Phường Hai Bà Trưng',
                            value: '13297',
                        },
                        {
                            lable: 'Phường Lam Hạ',
                            value: '13303',
                        },
                        {
                            lable: 'Phường Liêm Chính',
                            value: '13309',
                        },
                        {
                            lable: 'Phường Lê Hồng Phong',
                            value: '13291',
                        },
                        {
                            lable: 'Phường Lương Khánh Thiện',
                            value: '13288',
                        },
                        {
                            lable: 'Phường Minh Khai',
                            value: '13294',
                        },
                        {
                            lable: 'Phường Quang Trung',
                            value: '13285',
                        },
                        {
                            lable: 'Phường Thanh Châu',
                            value: '13315',
                        },
                        {
                            lable: 'Phường Thanh Tuyền',
                            value: '13459',
                        },
                        {
                            lable: 'Phường Trần Hưng Đạo',
                            value: '13300',
                        },
                        {
                            lable: 'Xã Kim Bình',
                            value: '13426',
                        },
                        {
                            lable: 'Xã Liêm Chung',
                            value: '13312',
                        },
                        {
                            lable: 'Xã Liêm Tiết',
                            value: '13447',
                        },
                        {
                            lable: 'Xã Liêm Tuyền',
                            value: '13444',
                        },
                        {
                            lable: 'Xã Phù Vân',
                            value: '13306',
                        },
                        {
                            lable: 'Xã Tiên Hiệp',
                            value: '13372',
                        },
                        {
                            lable: 'Xã Tiên Hải',
                            value: '13381',
                        },
                        {
                            lable: 'Xã Tiên Tân',
                            value: '13366',
                        },
                        {
                            lable: 'Xã Trịnh Xá',
                            value: '13513',
                        },
                        {
                            lable: 'Xã Đinh Xá',
                            value: '13507',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Duy Tiên',
                    value: '349',
                    wards: [
                        {
                            lable: 'Phường Bạch Thượng',
                            value: '13333',
                        },
                        {
                            lable: 'Phường Châu Giang',
                            value: '13330',
                        },
                        {
                            lable: 'Phường Duy Hải',
                            value: '13342',
                        },
                        {
                            lable: 'Phường Duy Minh',
                            value: '13336',
                        },
                        {
                            lable: 'Phường Hoàng Đông',
                            value: '13357',
                        },
                        {
                            lable: 'Phường Hòa Mạc',
                            value: '13324',
                        },
                        {
                            lable: 'Phường Tiên Nội',
                            value: '13354',
                        },
                        {
                            lable: 'Phường Yên Bắc',
                            value: '13348',
                        },
                        {
                            lable: 'Phường Đồng Văn',
                            value: '13321',
                        },
                        {
                            lable: 'Xã Chuyên Ngoại',
                            value: '13345',
                        },
                        {
                            lable: 'Xã Mộc Bắc',
                            value: '13327',
                        },
                        {
                            lable: 'Xã Mộc Nam',
                            value: '13339',
                        },
                        {
                            lable: 'Xã Tiên Ngoại',
                            value: '13363',
                        },
                        {
                            lable: 'Xã Tiên Sơn',
                            value: '13369',
                        },
                        {
                            lable: 'Xã Trác Văn',
                            value: '13351',
                        },
                        {
                            lable: 'Xã Yên Nam',
                            value: '13360',
                        },
                    ],
                },
                {
                    lable: 'Huyện Kim Bảng',
                    value: '350',
                    wards: [
                        {
                            lable: 'Thị trấn Ba Sao',
                            value: '13429',
                        },
                        {
                            lable: 'Thị trấn Quế',
                            value: '13384',
                        },
                        {
                            lable: 'Xã Hoàng Tây',
                            value: '13408',
                        },
                        {
                            lable: 'Xã Khả Phong',
                            value: '13420',
                        },
                        {
                            lable: 'Xã Liên Sơn',
                            value: '13432',
                        },
                        {
                            lable: 'Xã Lê Hồ',
                            value: '13393',
                        },
                        {
                            lable: 'Xã Nguyễn Úy',
                            value: '13387',
                        },
                        {
                            lable: 'Xã Ngọc Sơn',
                            value: '13423',
                        },
                        {
                            lable: 'Xã Nhật Tân',
                            value: '13402',
                        },
                        {
                            lable: 'Xã Nhật Tựu',
                            value: '13399',
                        },
                        {
                            lable: 'Xã Thanh Sơn',
                            value: '13438',
                        },
                        {
                            lable: 'Xã Thi Sơn',
                            value: '13435',
                        },
                        {
                            lable: 'Xã Thụy Lôi',
                            value: '13414',
                        },
                        {
                            lable: 'Xã Tân Sơn',
                            value: '13411',
                        },
                        {
                            lable: 'Xã Tượng Lĩnh',
                            value: '13396',
                        },
                        {
                            lable: 'Xã Văn Xá',
                            value: '13417',
                        },
                        {
                            lable: 'Xã Đại Cương',
                            value: '13390',
                        },
                        {
                            lable: 'Xã Đồng Hóa',
                            value: '13405',
                        },
                    ],
                },
                {
                    lable: 'Huyện Thanh Liêm',
                    value: '351',
                    wards: [
                        {
                            lable: 'Thị trấn Kiện Khê',
                            value: '13441',
                        },
                        {
                            lable: 'Thị trấn Tân Thanh',
                            value: '13474',
                        },
                        {
                            lable: 'Xã Liêm Cần',
                            value: '13456',
                        },
                        {
                            lable: 'Xã Liêm Phong',
                            value: '13450',
                        },
                        {
                            lable: 'Xã Liêm Sơn',
                            value: '13483',
                        },
                        {
                            lable: 'Xã Liêm Thuận',
                            value: '13465',
                        },
                        {
                            lable: 'Xã Liêm Túc',
                            value: '13480',
                        },
                        {
                            lable: 'Xã Thanh Hà',
                            value: '13453',
                        },
                        {
                            lable: 'Xã Thanh Hương',
                            value: '13486',
                        },
                        {
                            lable: 'Xã Thanh Hải',
                            value: '13498',
                        },
                        {
                            lable: 'Xã Thanh Nghị',
                            value: '13489',
                        },
                        {
                            lable: 'Xã Thanh Nguyên',
                            value: '13495',
                        },
                        {
                            lable: 'Xã Thanh Phong',
                            value: '13471',
                        },
                        {
                            lable: 'Xã Thanh Thủy',
                            value: '13468',
                        },
                        {
                            lable: 'Xã Thanh Tâm',
                            value: '13492',
                        },
                        {
                            lable: 'Xã Thanh Tân',
                            value: '13477',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bình Lục',
                    value: '352',
                    wards: [
                        {
                            lable: 'Thị trấn Bình Mỹ',
                            value: '13501',
                        },
                        {
                            lable: 'Xã An Lão',
                            value: '13561',
                        },
                        {
                            lable: 'Xã An Ninh',
                            value: '13528',
                        },
                        {
                            lable: 'Xã An Nội',
                            value: '13540',
                        },
                        {
                            lable: 'Xã An Đổ',
                            value: '13552',
                        },
                        {
                            lable: 'Xã Bình Nghĩa',
                            value: '13504',
                        },
                        {
                            lable: 'Xã Bối Cầu',
                            value: '13534',
                        },
                        {
                            lable: 'Xã Bồ Đề',
                            value: '13531',
                        },
                        {
                            lable: 'Xã Hưng Công',
                            value: '13522',
                        },
                        {
                            lable: 'Xã La Sơn',
                            value: '13555',
                        },
                        {
                            lable: 'Xã Ngọc Lũ',
                            value: '13519',
                        },
                        {
                            lable: 'Xã Tiêu Động',
                            value: '13558',
                        },
                        {
                            lable: 'Xã Trung Lương',
                            value: '13546',
                        },
                        {
                            lable: 'Xã Tràng An',
                            value: '13510',
                        },
                        {
                            lable: 'Xã Vũ Bản',
                            value: '13543',
                        },
                        {
                            lable: 'Xã Đồn Xá',
                            value: '13525',
                        },
                        {
                            lable: 'Xã Đồng Du',
                            value: '13516',
                        },
                    ],
                },
                {
                    lable: 'Huyện Lý Nhân',
                    value: '353',
                    wards: [
                        {
                            lable: 'Thị trấn Vĩnh Trụ',
                            value: '13597',
                        },
                        {
                            lable: 'Xã Bắc Lý',
                            value: '13588',
                        },
                        {
                            lable: 'Xã Chân Lý',
                            value: '13576',
                        },
                        {
                            lable: 'Xã Chính Lý',
                            value: '13573',
                        },
                        {
                            lable: 'Xã Công Lý',
                            value: '13582',
                        },
                        {
                            lable: 'Xã Hòa Hậu',
                            value: '13630',
                        },
                        {
                            lable: 'Xã Hợp Lý',
                            value: '13567',
                        },
                        {
                            lable: 'Xã Nguyên Lý',
                            value: '13570',
                        },
                        {
                            lable: 'Xã Nhân Bình',
                            value: '13618',
                        },
                        {
                            lable: 'Xã Nhân Chính',
                            value: '13615',
                        },
                        {
                            lable: 'Xã Nhân Khang',
                            value: '13606',
                        },
                        {
                            lable: 'Xã Nhân Mỹ',
                            value: '13609',
                        },
                        {
                            lable: 'Xã Nhân Nghĩa',
                            value: '13612',
                        },
                        {
                            lable: 'Xã Nhân Thịnh',
                            value: '13600',
                        },
                        {
                            lable: 'Xã Phú Phúc',
                            value: '13621',
                        },
                        {
                            lable: 'Xã Tiến Thắng',
                            value: '13627',
                        },
                        {
                            lable: 'Xã Trần Hưng Đạo',
                            value: '13594',
                        },
                        {
                            lable: 'Xã Văn Lý',
                            value: '13585',
                        },
                        {
                            lable: 'Xã Xuân Khê',
                            value: '13624',
                        },
                        {
                            lable: 'Xã Đạo Lý',
                            value: '13579',
                        },
                        {
                            lable: 'Xã Đức Lý',
                            value: '13591',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Hà Tĩnh',
            value: '42',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Hà Tĩnh',
                    value: '436',
                    wards: [
                        {
                            lable: 'Phường Bắc Hà',
                            value: '18076',
                        },
                        {
                            lable: 'Phường Hà Huy Tập',
                            value: '18085',
                        },
                        {
                            lable: 'Phường Nam Hà',
                            value: '18073',
                        },
                        {
                            lable: 'Phường Nguyễn Du',
                            value: '18077',
                        },
                        {
                            lable: 'Phường Thạch Linh',
                            value: '18094',
                        },
                        {
                            lable: 'Phường Thạch Quý',
                            value: '18091',
                        },
                        {
                            lable: 'Phường Trần Phú',
                            value: '18070',
                        },
                        {
                            lable: 'Phường Tân Giang',
                            value: '18079',
                        },
                        {
                            lable: 'Phường Văn Yên',
                            value: '18097',
                        },
                        {
                            lable: 'Phường Đại Nài',
                            value: '18082',
                        },
                        {
                            lable: 'Xã Thạch Bình',
                            value: '18112',
                        },
                        {
                            lable: 'Xã Thạch Hưng',
                            value: '18109',
                        },
                        {
                            lable: 'Xã Thạch Hạ',
                            value: '18100',
                        },
                        {
                            lable: 'Xã Thạch Trung',
                            value: '18088',
                        },
                        {
                            lable: 'Xã Đồng Môn',
                            value: '18103',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Hồng Lĩnh',
                    value: '437',
                    wards: [
                        {
                            lable: 'Phường Bắc Hồng',
                            value: '18115',
                        },
                        {
                            lable: 'Phường Nam Hồng',
                            value: '18118',
                        },
                        {
                            lable: 'Phường Trung Lương',
                            value: '18121',
                        },
                        {
                            lable: 'Phường Đậu Liêu',
                            value: '18127',
                        },
                        {
                            lable: 'Phường Đức Thuận',
                            value: '18124',
                        },
                        {
                            lable: 'Xã Thuận Lộc',
                            value: '18130',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hương Sơn',
                    value: '439',
                    wards: [
                        {
                            lable: 'Thị trấn  Tây Sơn',
                            value: '18136',
                        },
                        {
                            lable: 'Thị trấn Phố Châu',
                            value: '18133',
                        },
                        {
                            lable: 'Xã An Hòa Thịnh',
                            value: '18163',
                        },
                        {
                            lable: 'Xã Kim Hoa',
                            value: '18211',
                        },
                        {
                            lable: 'Xã Quang Diệm',
                            value: '18184',
                        },
                        {
                            lable: 'Xã Sơn Bình',
                            value: '18193',
                        },
                        {
                            lable: 'Xã Sơn Bằng',
                            value: '18190',
                        },
                        {
                            lable: 'Xã Sơn Châu',
                            value: '18178',
                        },
                        {
                            lable: 'Xã Sơn Giang',
                            value: '18157',
                        },
                        {
                            lable: 'Xã Sơn Hàm',
                            value: '18214',
                        },
                        {
                            lable: 'Xã Sơn Hồng',
                            value: '18139',
                        },
                        {
                            lable: 'Xã Sơn Kim 1',
                            value: '18196',
                        },
                        {
                            lable: 'Xã Sơn Kim 2',
                            value: '18199',
                        },
                        {
                            lable: 'Xã Sơn Long',
                            value: '18205',
                        },
                        {
                            lable: 'Xã Sơn Lâm',
                            value: '18145',
                        },
                        {
                            lable: 'Xã Sơn Lĩnh',
                            value: '18160',
                        },
                        {
                            lable: 'Xã Sơn Lễ',
                            value: '18148',
                        },
                        {
                            lable: 'Xã Sơn Ninh',
                            value: '18175',
                        },
                        {
                            lable: 'Xã Sơn Phú',
                            value: '18217',
                        },
                        {
                            lable: 'Xã Sơn Tiến',
                            value: '18142',
                        },
                        {
                            lable: 'Xã Sơn Trung',
                            value: '18187',
                        },
                        {
                            lable: 'Xã Sơn Trà',
                            value: '18202',
                        },
                        {
                            lable: 'Xã Sơn Trường',
                            value: '18223',
                        },
                        {
                            lable: 'Xã Sơn Tây',
                            value: '18172',
                        },
                        {
                            lable: 'Xã Tân Mỹ Hà',
                            value: '18181',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đức Thọ',
                    value: '440',
                    wards: [
                        {
                            lable: 'Thị trấn Đức Thọ',
                            value: '18229',
                        },
                        {
                            lable: 'Xã An Dũng',
                            value: '18298',
                        },
                        {
                            lable: 'Xã Bùi La Nhân',
                            value: '18262',
                        },
                        {
                            lable: 'Xã Hòa Lạc',
                            value: '18280',
                        },
                        {
                            lable: 'Xã Liên Minh',
                            value: '18247',
                        },
                        {
                            lable: 'Xã Lâm Trung Thủy',
                            value: '18277',
                        },
                        {
                            lable: 'Xã Quang Vĩnh',
                            value: '18235',
                        },
                        {
                            lable: 'Xã Thanh Bình Thịnh',
                            value: '18274',
                        },
                        {
                            lable: 'Xã Trường Sơn',
                            value: '18244',
                        },
                        {
                            lable: 'Xã Tân Dân',
                            value: '18283',
                        },
                        {
                            lable: 'Xã Tân Hương',
                            value: '18310',
                        },
                        {
                            lable: 'Xã Tùng Châu',
                            value: '18241',
                        },
                        {
                            lable: 'Xã Tùng Ảnh',
                            value: '18259',
                        },
                        {
                            lable: 'Xã Yên Hồ',
                            value: '18253',
                        },
                        {
                            lable: 'Xã Đức Lạng',
                            value: '18307',
                        },
                        {
                            lable: 'Xã Đức Đồng',
                            value: '18304',
                        },
                    ],
                },
                {
                    lable: 'Huyện Vũ Quang',
                    value: '441',
                    wards: [
                        {
                            lable: 'Thị trấn Vũ Quang',
                            value: '18313',
                        },
                        {
                            lable: 'Xã Hương Minh',
                            value: '18340',
                        },
                        {
                            lable: 'Xã Quang Thọ',
                            value: '18343',
                        },
                        {
                            lable: 'Xã Thọ Điền',
                            value: '18325',
                        },
                        {
                            lable: 'Xã Ân Phú',
                            value: '18316',
                        },
                        {
                            lable: 'Xã Đức Bồng',
                            value: '18331',
                        },
                        {
                            lable: 'Xã Đức Giang',
                            value: '18319',
                        },
                        {
                            lable: 'Xã Đức Hương',
                            value: '18328',
                        },
                        {
                            lable: 'Xã Đức Liên',
                            value: '18334',
                        },
                        {
                            lable: 'Xã Đức Lĩnh',
                            value: '18322',
                        },
                    ],
                },
                {
                    lable: 'Huyện Nghi Xuân',
                    value: '442',
                    wards: [
                        {
                            lable: 'Thị trấn Tiên Điền',
                            value: '18373',
                        },
                        {
                            lable: 'Thị trấn Xuân An',
                            value: '18352',
                        },
                        {
                            lable: 'Xã Cương Gián',
                            value: '18403',
                        },
                        {
                            lable: 'Xã Cỗ Đạm',
                            value: '18391',
                        },
                        {
                            lable: 'Xã Xuân Giang',
                            value: '18370',
                        },
                        {
                            lable: 'Xã Xuân Hải',
                            value: '18367',
                        },
                        {
                            lable: 'Xã Xuân Hồng',
                            value: '18388',
                        },
                        {
                            lable: 'Xã Xuân Hội',
                            value: '18355',
                        },
                        {
                            lable: 'Xã Xuân Lam',
                            value: '18400',
                        },
                        {
                            lable: 'Xã Xuân Liên',
                            value: '18394',
                        },
                        {
                            lable: 'Xã Xuân Lĩnh',
                            value: '18397',
                        },
                        {
                            lable: 'Xã Xuân Mỹ',
                            value: '18379',
                        },
                        {
                            lable: 'Xã Xuân Phổ',
                            value: '18364',
                        },
                        {
                            lable: 'Xã Xuân Thành',
                            value: '18382',
                        },
                        {
                            lable: 'Xã Xuân Viên',
                            value: '18385',
                        },
                        {
                            lable: 'Xã Xuân Yên',
                            value: '18376',
                        },
                        {
                            lable: 'Xã Đan Trường',
                            value: '18358',
                        },
                    ],
                },
                {
                    lable: 'Huyện Can Lộc',
                    value: '443',
                    wards: [
                        {
                            lable: 'Thị trấn Nghèn',
                            value: '18406',
                        },
                        {
                            lable: 'Thị trấn Đồng Lộc',
                            value: '18484',
                        },
                        {
                            lable: 'Xã Gia Hanh',
                            value: '18463',
                        },
                        {
                            lable: 'Xã Khánh Vĩnh Yên',
                            value: '18466',
                        },
                        {
                            lable: 'Xã Kim Song Trường',
                            value: '18436',
                        },
                        {
                            lable: 'Xã Mỹ Lộc',
                            value: '18487',
                        },
                        {
                            lable: 'Xã Phú Lộc',
                            value: '18454',
                        },
                        {
                            lable: 'Xã Quang Lộc',
                            value: '18481',
                        },
                        {
                            lable: 'Xã Sơn Lộc',
                            value: '18490',
                        },
                        {
                            lable: 'Xã Thanh Lộc',
                            value: '18433',
                        },
                        {
                            lable: 'Xã Thiên Lộc',
                            value: '18415',
                        },
                        {
                            lable: 'Xã Thuần Thiện',
                            value: '18418',
                        },
                        {
                            lable: 'Xã Thường Nga',
                            value: '18439',
                        },
                        {
                            lable: 'Xã Thượng Lộc',
                            value: '18478',
                        },
                        {
                            lable: 'Xã Trung Lộc',
                            value: '18472',
                        },
                        {
                            lable: 'Xã Tùng Lộc',
                            value: '18445',
                        },
                        {
                            lable: 'Xã Vượng Lộc',
                            value: '18427',
                        },
                        {
                            lable: 'Xã Xuân Lộc',
                            value: '18475',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hương Khê',
                    value: '444',
                    wards: [
                        {
                            lable: 'Thị trấn Hương Khê',
                            value: '18496',
                        },
                        {
                            lable: 'Xã Gia Phố',
                            value: '18532',
                        },
                        {
                            lable: 'Xã Hà Linh',
                            value: '18502',
                        },
                        {
                            lable: 'Xã Hòa Hải',
                            value: '18508',
                        },
                        {
                            lable: 'Xã Hương Bình',
                            value: '18523',
                        },
                        {
                            lable: 'Xã Hương Giang',
                            value: '18517',
                        },
                        {
                            lable: 'Xã Hương Liên',
                            value: '18559',
                        },
                        {
                            lable: 'Xã Hương Long',
                            value: '18526',
                        },
                        {
                            lable: 'Xã Hương Lâm',
                            value: '18556',
                        },
                        {
                            lable: 'Xã Hương Thủy',
                            value: '18505',
                        },
                        {
                            lable: 'Xã Hương Trà',
                            value: '18550',
                        },
                        {
                            lable: 'Xã Hương Trạch',
                            value: '18553',
                        },
                        {
                            lable: 'Xã Hương Vĩnh',
                            value: '18541',
                        },
                        {
                            lable: 'Xã Hương Xuân',
                            value: '18544',
                        },
                        {
                            lable: 'Xã Hương Đô',
                            value: '18538',
                        },
                        {
                            lable: 'Xã Lộc Yên',
                            value: '18520',
                        },
                        {
                            lable: 'Xã Phú Gia',
                            value: '18529',
                        },
                        {
                            lable: 'Xã Phú Phong',
                            value: '18535',
                        },
                        {
                            lable: 'Xã Phúc Trạch',
                            value: '18547',
                        },
                        {
                            lable: 'Xã Phúc Đồng',
                            value: '18514',
                        },
                        {
                            lable: 'Xã Điền Mỹ',
                            value: '18499',
                        },
                    ],
                },
                {
                    lable: 'Huyện Thạch Hà',
                    value: '445',
                    wards: [
                        {
                            lable: 'Thị trấn Thạch Hà',
                            value: '18562',
                        },
                        {
                            lable: 'Xã Lưu Vĩnh Sơn',
                            value: '18634',
                        },
                        {
                            lable: 'Xã Nam Điền',
                            value: '18667',
                        },
                        {
                            lable: 'Xã Ngọc Sơn',
                            value: '18565',
                        },
                        {
                            lable: 'Xã Thạch Hải',
                            value: '18571',
                        },
                        {
                            lable: 'Xã Thạch Hội',
                            value: '18649',
                        },
                        {
                            lable: 'Xã Thạch Khê',
                            value: '18604',
                        },
                        {
                            lable: 'Xã Thạch Kênh',
                            value: '18586',
                        },
                        {
                            lable: 'Xã Thạch Liên',
                            value: '18592',
                        },
                        {
                            lable: 'Xã Thạch Long',
                            value: '18607',
                        },
                        {
                            lable: 'Xã Thạch Lạc',
                            value: '18622',
                        },
                        {
                            lable: 'Xã Thạch Ngọc',
                            value: '18625',
                        },
                        {
                            lable: 'Xã Thạch Sơn',
                            value: '18589',
                        },
                        {
                            lable: 'Xã Thạch Thắng',
                            value: '18637',
                        },
                        {
                            lable: 'Xã Thạch Trị',
                            value: '18619',
                        },
                        {
                            lable: 'Xã Thạch Văn',
                            value: '18631',
                        },
                        {
                            lable: 'Xã Thạch Xuân',
                            value: '18658',
                        },
                        {
                            lable: 'Xã Thạch Đài',
                            value: '18643',
                        },
                        {
                            lable: 'Xã Tân Lâm Hương',
                            value: '18652',
                        },
                        {
                            lable: 'Xã Tượng Sơn',
                            value: '18628',
                        },
                        {
                            lable: 'Xã Việt Tiến',
                            value: '18601',
                        },
                        {
                            lable: 'Xã Đỉnh Bàn',
                            value: '18595',
                        },
                    ],
                },
                {
                    lable: 'Huyện Cẩm Xuyên',
                    value: '446',
                    wards: [
                        {
                            lable: 'Thị trấn Cẩm Xuyên',
                            value: '18673',
                        },
                        {
                            lable: 'Thị trấn Thiên Cầm',
                            value: '18676',
                        },
                        {
                            lable: 'Xã Cẩm Bình',
                            value: '18685',
                        },
                        {
                            lable: 'Xã Cẩm Duệ',
                            value: '18715',
                        },
                        {
                            lable: 'Xã Cẩm Dương',
                            value: '18682',
                        },
                        {
                            lable: 'Xã Cẩm Hà',
                            value: '18727',
                        },
                        {
                            lable: 'Xã Cẩm Hưng',
                            value: '18733',
                        },
                        {
                            lable: 'Xã Cẩm Lĩnh',
                            value: '18721',
                        },
                        {
                            lable: 'Xã Cẩm Lạc',
                            value: '18748',
                        },
                        {
                            lable: 'Xã Cẩm Lộc',
                            value: '18730',
                        },
                        {
                            lable: 'Xã Cẩm Minh',
                            value: '18751',
                        },
                        {
                            lable: 'Xã Cẩm Mỹ',
                            value: '18739',
                        },
                        {
                            lable: 'Xã Cẩm Nhượng',
                            value: '18709',
                        },
                        {
                            lable: 'Xã Cẩm Quan',
                            value: '18724',
                        },
                        {
                            lable: 'Xã Cẩm Quang',
                            value: '18697',
                        },
                        {
                            lable: 'Xã Cẩm Sơn',
                            value: '18745',
                        },
                        {
                            lable: 'Xã Cẩm Thành',
                            value: '18694',
                        },
                        {
                            lable: 'Xã Cẩm Thạch',
                            value: '18706',
                        },
                        {
                            lable: 'Xã Cẩm Thịnh',
                            value: '18736',
                        },
                        {
                            lable: 'Xã Cẩm Trung',
                            value: '18742',
                        },
                        {
                            lable: 'Xã Cẩm Vĩnh',
                            value: '18691',
                        },
                        {
                            lable: 'Xã Nam Phúc Thăng',
                            value: '18712',
                        },
                        {
                            lable: 'Xã Yên Hòa',
                            value: '18679',
                        },
                    ],
                },
                {
                    lable: 'Huyện Kỳ Anh',
                    value: '447',
                    wards: [
                        {
                            lable: 'Xã Kỳ Bắc',
                            value: '18760',
                        },
                        {
                            lable: 'Xã Kỳ Châu',
                            value: '18811',
                        },
                        {
                            lable: 'Xã Kỳ Giang',
                            value: '18772',
                        },
                        {
                            lable: 'Xã Kỳ Hải',
                            value: '18802',
                        },
                        {
                            lable: 'Xã Kỳ Khang',
                            value: '18778',
                        },
                        {
                            lable: 'Xã Kỳ Lạc',
                            value: '18850',
                        },
                        {
                            lable: 'Xã Kỳ Phong',
                            value: '18766',
                        },
                        {
                            lable: 'Xã Kỳ Phú',
                            value: '18763',
                        },
                        {
                            lable: 'Xã Kỳ Sơn',
                            value: '18844',
                        },
                        {
                            lable: 'Xã Kỳ Thư',
                            value: '18805',
                        },
                        {
                            lable: 'Xã Kỳ Thượng',
                            value: '18799',
                        },
                        {
                            lable: 'Xã Kỳ Thọ',
                            value: '18790',
                        },
                        {
                            lable: 'Xã Kỳ Tiến',
                            value: '18769',
                        },
                        {
                            lable: 'Xã Kỳ Trung',
                            value: '18787',
                        },
                        {
                            lable: 'Xã Kỳ Tân',
                            value: '18814',
                        },
                        {
                            lable: 'Xã Kỳ Tây',
                            value: '18793',
                        },
                        {
                            lable: 'Xã Kỳ Văn',
                            value: '18784',
                        },
                        {
                            lable: 'Xã Kỳ Xuân',
                            value: '18757',
                        },
                        {
                            lable: 'Xã Kỳ Đồng',
                            value: '18775',
                        },
                        {
                            lable: 'Xã Lâm Hợp',
                            value: '18838',
                        },
                    ],
                },
                {
                    lable: 'Huyện Lộc Hà',
                    value: '448',
                    wards: [
                        {
                            lable: 'Thị trấn Lộc Hà',
                            value: '18568',
                        },
                        {
                            lable: 'Xã Bình An',
                            value: '18430',
                        },
                        {
                            lable: 'Xã Hồng Lộc',
                            value: '18412',
                        },
                        {
                            lable: 'Xã Hộ Độ',
                            value: '18598',
                        },
                        {
                            lable: 'Xã Mai Phụ',
                            value: '18670',
                        },
                        {
                            lable: 'Xã Phù Lưu',
                            value: '18493',
                        },
                        {
                            lable: 'Xã Thạch Châu',
                            value: '18583',
                        },
                        {
                            lable: 'Xã Thạch Kim',
                            value: '18580',
                        },
                        {
                            lable: 'Xã Thạch Mỹ',
                            value: '18577',
                        },
                        {
                            lable: 'Xã Thịnh Lộc',
                            value: '18421',
                        },
                        {
                            lable: 'Xã Tân Lộc',
                            value: '18409',
                        },
                        {
                            lable: 'Xã Ích Hậu',
                            value: '18457',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Kỳ Anh',
                    value: '449',
                    wards: [
                        {
                            lable: 'Phường Hưng Trí',
                            value: '18754',
                        },
                        {
                            lable: 'Phường Kỳ Liên',
                            value: '18841',
                        },
                        {
                            lable: 'Phường Kỳ Long',
                            value: '18835',
                        },
                        {
                            lable: 'Phường Kỳ Phương',
                            value: '18832',
                        },
                        {
                            lable: 'Phường Kỳ Thịnh',
                            value: '18823',
                        },
                        {
                            lable: 'Phường Kỳ Trinh',
                            value: '18820',
                        },
                        {
                            lable: 'Xã Kỳ Hoa',
                            value: '18829',
                        },
                        {
                            lable: 'Xã Kỳ Hà',
                            value: '18808',
                        },
                        {
                            lable: 'Xã Kỳ Lợi',
                            value: '18796',
                        },
                        {
                            lable: 'Xã Kỳ Nam',
                            value: '18847',
                        },
                        {
                            lable: 'Xã Kỳ Ninh',
                            value: '18781',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Hải Dương',
            value: '30',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Hải Dương',
                    value: '288',
                    wards: [
                        {
                            lable: 'Phường Bình Hàn',
                            value: '10510',
                        },
                        {
                            lable: 'Phường Cẩm Thượng',
                            value: '10507',
                        },
                        {
                            lable: 'Phường Hải Tân',
                            value: '10537',
                        },
                        {
                            lable: 'Phường Lê Thanh Nghị',
                            value: '10534',
                        },
                        {
                            lable: 'Phường Nam Đồng',
                            value: '10672',
                        },
                        {
                            lable: 'Phường Nguyễn Trãi',
                            value: '10519',
                        },
                        {
                            lable: 'Phường Ngọc Châu',
                            value: '10513',
                        },
                        {
                            lable: 'Phường Nhị Châu',
                            value: '10514',
                        },
                        {
                            lable: 'Phường Phạm Ngũ Lão',
                            value: '10522',
                        },
                        {
                            lable: 'Phường Quang Trung',
                            value: '10516',
                        },
                        {
                            lable: 'Phường Thanh Bình',
                            value: '10531',
                        },
                        {
                            lable: 'Phường Thạch Khôi',
                            value: '11002',
                        },
                        {
                            lable: 'Phường Trần Hưng Đạo',
                            value: '10525',
                        },
                        {
                            lable: 'Phường Trần Phú',
                            value: '10528',
                        },
                        {
                            lable: 'Phường Tân Bình',
                            value: '10532',
                        },
                        {
                            lable: 'Phường Tân Hưng',
                            value: '11011',
                        },
                        {
                            lable: 'Phường Tứ Minh',
                            value: '10540',
                        },
                        {
                            lable: 'Phường Việt Hoà',
                            value: '10543',
                        },
                        {
                            lable: 'Phường Ái Quốc',
                            value: '10660',
                        },
                        {
                            lable: 'Xã An Thượng',
                            value: '10663',
                        },
                        {
                            lable: 'Xã Gia Xuyên',
                            value: '11017',
                        },
                        {
                            lable: 'Xã Liên Hồng',
                            value: '11005',
                        },
                        {
                            lable: 'Xã Ngọc Sơn',
                            value: '11077',
                        },
                        {
                            lable: 'Xã Quyết Thắng',
                            value: '10822',
                        },
                        {
                            lable: 'Xã Tiền Tiến',
                            value: '10837',
                        },
                    ],
                },
                {
                    lable: 'Thành phố Chí Linh',
                    value: '290',
                    wards: [
                        {
                            lable: 'Phường An Lạc',
                            value: '10594',
                        },
                        {
                            lable: 'Phường Bến Tắm',
                            value: '10552',
                        },
                        {
                            lable: 'Phường Chí Minh',
                            value: '10582',
                        },
                        {
                            lable: 'Phường Cổ Thành',
                            value: '10576',
                        },
                        {
                            lable: 'Phường Cộng Hoà',
                            value: '10570',
                        },
                        {
                            lable: 'Phường Hoàng Tiến',
                            value: '10567',
                        },
                        {
                            lable: 'Phường Hoàng Tân',
                            value: '10573',
                        },
                        {
                            lable: 'Phường Phả Lại',
                            value: '10546',
                        },
                        {
                            lable: 'Phường Sao Đỏ',
                            value: '10549',
                        },
                        {
                            lable: 'Phường Thái Học',
                            value: '10588',
                        },
                        {
                            lable: 'Phường Tân Dân',
                            value: '10603',
                        },
                        {
                            lable: 'Phường Văn An',
                            value: '10579',
                        },
                        {
                            lable: 'Phường Văn Đức',
                            value: '10585',
                        },
                        {
                            lable: 'Phường Đồng Lạc',
                            value: '10600',
                        },
                        {
                            lable: 'Xã Bắc An',
                            value: '10558',
                        },
                        {
                            lable: 'Xã Hoàng Hoa Thám',
                            value: '10555',
                        },
                        {
                            lable: 'Xã Hưng Đạo',
                            value: '10561',
                        },
                        {
                            lable: 'Xã Lê Lợi',
                            value: '10564',
                        },
                        {
                            lable: 'Xã Nhân Huệ',
                            value: '10591',
                        },
                    ],
                },
                {
                    lable: 'Huyện Nam Sách',
                    value: '291',
                    wards: [
                        {
                            lable: 'Thị trấn Nam Sách',
                            value: '10606',
                        },
                        {
                            lable: 'Xã An Bình',
                            value: '10630',
                        },
                        {
                            lable: 'Xã An Lâm',
                            value: '10645',
                        },
                        {
                            lable: 'Xã An Sơn',
                            value: '10636',
                        },
                        {
                            lable: 'Xã Cộng Hòa',
                            value: '10639',
                        },
                        {
                            lable: 'Xã Hiệp Cát',
                            value: '10618',
                        },
                        {
                            lable: 'Xã Hồng Phong',
                            value: '10654',
                        },
                        {
                            lable: 'Xã Hợp Tiến',
                            value: '10615',
                        },
                        {
                            lable: 'Xã Minh Tân',
                            value: '10666',
                        },
                        {
                            lable: 'Xã Nam Chính',
                            value: '10627',
                        },
                        {
                            lable: 'Xã Nam Hưng',
                            value: '10609',
                        },
                        {
                            lable: 'Xã Nam Hồng',
                            value: '10651',
                        },
                        {
                            lable: 'Xã Nam Trung',
                            value: '10633',
                        },
                        {
                            lable: 'Xã Nam Tân',
                            value: '10612',
                        },
                        {
                            lable: 'Xã Phú Điền',
                            value: '10648',
                        },
                        {
                            lable: 'Xã Quốc Tuấn',
                            value: '10624',
                        },
                        {
                            lable: 'Xã Thanh Quang',
                            value: '10621',
                        },
                        {
                            lable: 'Xã Thái Tân',
                            value: '10642',
                        },
                        {
                            lable: 'Xã Đồng Lạc',
                            value: '10657',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Kinh Môn',
                    value: '292',
                    wards: [
                        {
                            lable: 'Phường An Lưu',
                            value: '10675',
                        },
                        {
                            lable: 'Phường An Phụ',
                            value: '10732',
                        },
                        {
                            lable: 'Phường An Sinh',
                            value: '10723',
                        },
                        {
                            lable: 'Phường Duy Tân',
                            value: '10696',
                        },
                        {
                            lable: 'Phường Hiến Thành',
                            value: '10744',
                        },
                        {
                            lable: 'Phường Hiệp An',
                            value: '10735',
                        },
                        {
                            lable: 'Phường Hiệp Sơn',
                            value: '10726',
                        },
                        {
                            lable: 'Phường Long Xuyên',
                            value: '10738',
                        },
                        {
                            lable: 'Phường Minh Tân',
                            value: '10702',
                        },
                        {
                            lable: 'Phường Phú Thứ',
                            value: '10714',
                        },
                        {
                            lable: 'Phường Phạm Thái',
                            value: '10693',
                        },
                        {
                            lable: 'Phường Thái Thịnh',
                            value: '10741',
                        },
                        {
                            lable: 'Phường Thất Hùng',
                            value: '10681',
                        },
                        {
                            lable: 'Phường Tân Dân',
                            value: '10699',
                        },
                        {
                            lable: 'Xã Bạch Đằng',
                            value: '10678',
                        },
                        {
                            lable: 'Xã Hiệp Hòa',
                            value: '10708',
                        },
                        {
                            lable: 'Xã Hoành Sơn',
                            value: '10687',
                        },
                        {
                            lable: 'Xã Lê Ninh',
                            value: '10684',
                        },
                        {
                            lable: 'Xã Lạc Long',
                            value: '10720',
                        },
                        {
                            lable: 'Xã Minh Hòa',
                            value: '10747',
                        },
                        {
                            lable: 'Xã Quang Thành',
                            value: '10705',
                        },
                        {
                            lable: 'Xã Thăng Long',
                            value: '10717',
                        },
                        {
                            lable: 'Xã Thượng Quận',
                            value: '10729',
                        },
                    ],
                },
                {
                    lable: 'Huyện Kim Thành',
                    value: '293',
                    wards: [
                        {
                            lable: 'Thị trấn Phú Thái',
                            value: '10750',
                        },
                        {
                            lable: 'Xã Bình Dân',
                            value: '10798',
                        },
                        {
                            lable: 'Xã Cổ Dũng',
                            value: '10762',
                        },
                        {
                            lable: 'Xã Cộng Hòa',
                            value: '10756',
                        },
                        {
                            lable: 'Xã Kim Anh',
                            value: '10780',
                        },
                        {
                            lable: 'Xã Kim Liên',
                            value: '10783',
                        },
                        {
                            lable: 'Xã Kim Tân',
                            value: '10786',
                        },
                        {
                            lable: 'Xã Kim Xuyên',
                            value: '10771',
                        },
                        {
                            lable: 'Xã Kim Đính',
                            value: '10792',
                        },
                        {
                            lable: 'Xã Lai Vu',
                            value: '10753',
                        },
                        {
                            lable: 'Xã Liên Hòa',
                            value: '10807',
                        },
                        {
                            lable: 'Xã Ngũ Phúc',
                            value: '10777',
                        },
                        {
                            lable: 'Xã Phúc Thành A',
                            value: '10774',
                        },
                        {
                            lable: 'Xã Tam Kỳ',
                            value: '10801',
                        },
                        {
                            lable: 'Xã Thượng Vũ',
                            value: '10759',
                        },
                        {
                            lable: 'Xã Tuấn Việt',
                            value: '10768',
                        },
                        {
                            lable: 'Xã Đại Đức',
                            value: '10810',
                        },
                        {
                            lable: 'Xã Đồng Cẩm',
                            value: '10804',
                        },
                    ],
                },
                {
                    lable: 'Huyện Thanh Hà',
                    value: '294',
                    wards: [
                        {
                            lable: 'Thị trấn Thanh Hà',
                            value: '10813',
                        },
                        {
                            lable: 'Xã An Phượng',
                            value: '10864',
                        },
                        {
                            lable: 'Xã Cẩm Chế',
                            value: '10828',
                        },
                        {
                            lable: 'Xã Hồng Lạc',
                            value: '10816',
                        },
                        {
                            lable: 'Xã Liên Mạc',
                            value: '10843',
                        },
                        {
                            lable: 'Xã Thanh An',
                            value: '10831',
                        },
                        {
                            lable: 'Xã Thanh Cường',
                            value: '10882',
                        },
                        {
                            lable: 'Xã Thanh Hải',
                            value: '10846',
                        },
                        {
                            lable: 'Xã Thanh Hồng',
                            value: '10879',
                        },
                        {
                            lable: 'Xã Thanh Khê',
                            value: '10849',
                        },
                        {
                            lable: 'Xã Thanh Lang',
                            value: '10834',
                        },
                        {
                            lable: 'Xã Thanh Quang',
                            value: '10876',
                        },
                        {
                            lable: 'Xã Thanh Sơn',
                            value: '10867',
                        },
                        {
                            lable: 'Xã Thanh Thủy',
                            value: '10861',
                        },
                        {
                            lable: 'Xã Thanh Xuân',
                            value: '10855',
                        },
                        {
                            lable: 'Xã Thanh Xá',
                            value: '10852',
                        },
                        {
                            lable: 'Xã Tân An',
                            value: '10840',
                        },
                        {
                            lable: 'Xã Tân Việt',
                            value: '10825',
                        },
                        {
                            lable: 'Xã Việt Hồng',
                            value: '10819',
                        },
                        {
                            lable: 'Xã Vĩnh Lập',
                            value: '10885',
                        },
                    ],
                },
                {
                    lable: 'Huyện Cẩm Giàng',
                    value: '295',
                    wards: [
                        {
                            lable: 'Thị trấn Cẩm Giang',
                            value: '10888',
                        },
                        {
                            lable: 'Thị trấn Lai Cách',
                            value: '10891',
                        },
                        {
                            lable: 'Xã Cao An',
                            value: '10927',
                        },
                        {
                            lable: 'Xã Cẩm Hoàng',
                            value: '10897',
                        },
                        {
                            lable: 'Xã Cẩm Hưng',
                            value: '10894',
                        },
                        {
                            lable: 'Xã Cẩm Phúc',
                            value: '10933',
                        },
                        {
                            lable: 'Xã Cẩm Văn',
                            value: '10900',
                        },
                        {
                            lable: 'Xã Cẩm Vũ',
                            value: '10909',
                        },
                        {
                            lable: 'Xã Cẩm Điền',
                            value: '10936',
                        },
                        {
                            lable: 'Xã Cẩm Đoài',
                            value: '10942',
                        },
                        {
                            lable: 'Xã Cẩm Đông',
                            value: '10939',
                        },
                        {
                            lable: 'Xã Lương Điền',
                            value: '10924',
                        },
                        {
                            lable: 'Xã Ngọc Liên',
                            value: '10903',
                        },
                        {
                            lable: 'Xã Thạch Lỗi',
                            value: '10906',
                        },
                        {
                            lable: 'Xã Tân Trường',
                            value: '10930',
                        },
                        {
                            lable: 'Xã Định Sơn',
                            value: '10918',
                        },
                        {
                            lable: 'Xã Đức Chính',
                            value: '10912',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bình Giang',
                    value: '296',
                    wards: [
                        {
                            lable: 'Thị trấn Kẻ Sặt',
                            value: '10945',
                        },
                        {
                            lable: 'Xã Bình Minh',
                            value: '10975',
                        },
                        {
                            lable: 'Xã Bình Xuyên',
                            value: '10996',
                        },
                        {
                            lable: 'Xã Cổ Bì',
                            value: '10984',
                        },
                        {
                            lable: 'Xã Hùng Thắng',
                            value: '10954',
                        },
                        {
                            lable: 'Xã Hồng Khê',
                            value: '10978',
                        },
                        {
                            lable: 'Xã Long Xuyên',
                            value: '10963',
                        },
                        {
                            lable: 'Xã Nhân Quyền',
                            value: '10987',
                        },
                        {
                            lable: 'Xã Thái Dương',
                            value: '10990',
                        },
                        {
                            lable: 'Xã Thái Hòa',
                            value: '10993',
                        },
                        {
                            lable: 'Xã Thái Học',
                            value: '10981',
                        },
                        {
                            lable: 'Xã Thúc Kháng',
                            value: '10969',
                        },
                        {
                            lable: 'Xã Tân Hồng',
                            value: '10972',
                        },
                        {
                            lable: 'Xã Tân Việt',
                            value: '10966',
                        },
                        {
                            lable: 'Xã Vĩnh Hưng',
                            value: '10951',
                        },
                        {
                            lable: 'Xã Vĩnh Hồng',
                            value: '10960',
                        },
                    ],
                },
                {
                    lable: 'Huyện Gia Lộc',
                    value: '297',
                    wards: [
                        {
                            lable: 'Thị trấn Gia Lộc',
                            value: '10999',
                        },
                        {
                            lable: 'Xã Gia Khánh',
                            value: '11035',
                        },
                        {
                            lable: 'Xã Gia Lương',
                            value: '11038',
                        },
                        {
                            lable: 'Xã Gia Tân',
                            value: '11029',
                        },
                        {
                            lable: 'Xã Hoàng Diệu',
                            value: '11047',
                        },
                        {
                            lable: 'Xã Hồng Hưng',
                            value: '11050',
                        },
                        {
                            lable: 'Xã Lê Lợi',
                            value: '11041',
                        },
                        {
                            lable: 'Xã Nhật Tân',
                            value: '11068',
                        },
                        {
                            lable: 'Xã Phạm Trấn',
                            value: '11053',
                        },
                        {
                            lable: 'Xã Quang Minh',
                            value: '11062',
                        },
                        {
                            lable: 'Xã Thống Kênh',
                            value: '11059',
                        },
                        {
                            lable: 'Xã Thống Nhất',
                            value: '11008',
                        },
                        {
                            lable: 'Xã Toàn Thắng',
                            value: '11044',
                        },
                        {
                            lable: 'Xã Tân Tiến',
                            value: '11032',
                        },
                        {
                            lable: 'Xã Yết Kiêu',
                            value: '11020',
                        },
                        {
                            lable: 'Xã Đoàn Thượng',
                            value: '11056',
                        },
                        {
                            lable: 'Xã Đồng Quang',
                            value: '11065',
                        },
                        {
                            lable: 'Xã Đức Xương',
                            value: '11071',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tứ Kỳ',
                    value: '298',
                    wards: [
                        {
                            lable: 'Thị trấn Tứ Kỳ',
                            value: '11074',
                        },
                        {
                            lable: 'Xã An Thanh',
                            value: '11125',
                        },
                        {
                            lable: 'Xã Bình Lăng',
                            value: '11092',
                        },
                        {
                            lable: 'Xã Chí Minh',
                            value: '11095',
                        },
                        {
                            lable: 'Xã Cộng Lạc',
                            value: '11140',
                        },
                        {
                            lable: 'Xã Dân Chủ',
                            value: '11110',
                        },
                        {
                            lable: 'Xã Hà Kỳ',
                            value: '11149',
                        },
                        {
                            lable: 'Xã Hà Thanh',
                            value: '11152',
                        },
                        {
                            lable: 'Xã Hưng Đạo',
                            value: '11086',
                        },
                        {
                            lable: 'Xã Minh Đức',
                            value: '11128',
                        },
                        {
                            lable: 'Xã Nguyên Giáp',
                            value: '11146',
                        },
                        {
                            lable: 'Xã Ngọc Kỳ',
                            value: '11089',
                        },
                        {
                            lable: 'Xã Phượng Kỳ',
                            value: '11137',
                        },
                        {
                            lable: 'Xã Quang Khải',
                            value: '11116',
                        },
                        {
                            lable: 'Xã Quang Phục',
                            value: '11101',
                        },
                        {
                            lable: 'Xã Quang Trung',
                            value: '11134',
                        },
                        {
                            lable: 'Xã Quảng Nghiệp',
                            value: '11122',
                        },
                        {
                            lable: 'Xã Tiên Động',
                            value: '11143',
                        },
                        {
                            lable: 'Xã Tái Sơn',
                            value: '11098',
                        },
                        {
                            lable: 'Xã Tân Kỳ',
                            value: '11113',
                        },
                        {
                            lable: 'Xã Văn Tố',
                            value: '11131',
                        },
                        {
                            lable: 'Xã Đại Hợp',
                            value: '11119',
                        },
                        {
                            lable: 'Xã Đại Sơn',
                            value: '11083',
                        },
                    ],
                },
                {
                    lable: 'Huyện Ninh Giang',
                    value: '299',
                    wards: [
                        {
                            lable: 'Thị trấn Ninh Giang',
                            value: '11155',
                        },
                        {
                            lable: 'Xã An Đức',
                            value: '11173',
                        },
                        {
                            lable: 'Xã Hiệp Lực',
                            value: '11227',
                        },
                        {
                            lable: 'Xã Hưng Long',
                            value: '11233',
                        },
                        {
                            lable: 'Xã Hồng Dụ',
                            value: '11215',
                        },
                        {
                            lable: 'Xã Hồng Phong',
                            value: '11224',
                        },
                        {
                            lable: 'Xã Hồng Phúc',
                            value: '11230',
                        },
                        {
                            lable: 'Xã Hồng Đức',
                            value: '11167',
                        },
                        {
                            lable: 'Xã Kiến Quốc',
                            value: '11209',
                        },
                        {
                            lable: 'Xã Nghĩa An',
                            value: '11164',
                        },
                        {
                            lable: 'Xã Ninh Hải',
                            value: '11200',
                        },
                        {
                            lable: 'Xã Tân Hương',
                            value: '11179',
                        },
                        {
                            lable: 'Xã Tân Phong',
                            value: '11197',
                        },
                        {
                            lable: 'Xã Tân Quang',
                            value: '11206',
                        },
                        {
                            lable: 'Xã Văn Hội',
                            value: '11218',
                        },
                        {
                            lable: 'Xã Vĩnh Hòa',
                            value: '11185',
                        },
                        {
                            lable: 'Xã Vạn Phúc',
                            value: '11176',
                        },
                        {
                            lable: 'Xã Đông Xuyên',
                            value: '11188',
                        },
                        {
                            lable: 'Xã Đồng Tâm',
                            value: '11203',
                        },
                        {
                            lable: 'Xã Ứng Hoè',
                            value: '11161',
                        },
                    ],
                },
                {
                    lable: 'Huyện Thanh Miện',
                    value: '300',
                    wards: [
                        {
                            lable: 'Thị trấn Thanh Miện',
                            value: '11239',
                        },
                        {
                            lable: 'Xã Cao Thắng',
                            value: '11278',
                        },
                        {
                            lable: 'Xã Chi Lăng Bắc',
                            value: '11281',
                        },
                        {
                            lable: 'Xã Chi Lăng Nam',
                            value: '11284',
                        },
                        {
                            lable: 'Xã Hồng Phong',
                            value: '11293',
                        },
                        {
                            lable: 'Xã Hồng Quang',
                            value: '11254',
                        },
                        {
                            lable: 'Xã Lam Sơn',
                            value: '11260',
                        },
                        {
                            lable: 'Xã Lê Hồng',
                            value: '11266',
                        },
                        {
                            lable: 'Xã Ngô Quyền',
                            value: '11248',
                        },
                        {
                            lable: 'Xã Ngũ Hùng',
                            value: '11275',
                        },
                        {
                            lable: 'Xã Phạm Kha',
                            value: '11245',
                        },
                        {
                            lable: 'Xã Thanh Giang',
                            value: '11287',
                        },
                        {
                            lable: 'Xã Thanh Tùng',
                            value: '11242',
                        },
                        {
                            lable: 'Xã Tân Trào',
                            value: '11257',
                        },
                        {
                            lable: 'Xã Tứ Cường',
                            value: '11269',
                        },
                        {
                            lable: 'Xã Đoàn Kết',
                            value: '11263',
                        },
                        {
                            lable: 'Xã Đoàn Tùng',
                            value: '11251',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Hậu Giang',
            value: '93',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Vị Thanh',
                    value: '930',
                    wards: [
                        {
                            lable: 'Phường I',
                            value: '31318',
                        },
                        {
                            lable: 'Phường III',
                            value: '31321',
                        },
                        {
                            lable: 'Phường IV',
                            value: '31324',
                        },
                        {
                            lable: 'Phường V',
                            value: '31327',
                        },
                        {
                            lable: 'Phường VII',
                            value: '31330',
                        },
                        {
                            lable: 'Xã Hoả Lựu',
                            value: '31336',
                        },
                        {
                            lable: 'Xã Hoả Tiến',
                            value: '31339',
                        },
                        {
                            lable: 'Xã Tân Tiến',
                            value: '31338',
                        },
                        {
                            lable: 'Xã Vị Tân',
                            value: '31333',
                        },
                    ],
                },
                {
                    lable: 'Thành phố Ngã Bảy',
                    value: '931',
                    wards: [
                        {
                            lable: 'Phường Hiệp Lợi',
                            value: '31344',
                        },
                        {
                            lable: 'Phường Hiệp Thành',
                            value: '31343',
                        },
                        {
                            lable: 'Phường Lái Hiếu',
                            value: '31341',
                        },
                        {
                            lable: 'Phường Ngã Bảy',
                            value: '31340',
                        },
                        {
                            lable: 'Xã Tân Thành',
                            value: '31414',
                        },
                        {
                            lable: 'Xã Đại Thành',
                            value: '31411',
                        },
                    ],
                },
                {
                    lable: 'Huyện Châu Thành',
                    value: '933',
                    wards: [
                        {
                            lable: 'Thị Trấn Ngã Sáu',
                            value: '31366',
                        },
                        {
                            lable: 'Thị trấn Mái Dầm',
                            value: '31381',
                        },
                        {
                            lable: 'Xã Phú Hữu',
                            value: '31378',
                        },
                        {
                            lable: 'Xã Phú Tân',
                            value: '31379',
                        },
                        {
                            lable: 'Xã Đông Phú',
                            value: '31375',
                        },
                        {
                            lable: 'Xã Đông Phước',
                            value: '31384',
                        },
                        {
                            lable: 'Xã Đông Phước A',
                            value: '31387',
                        },
                        {
                            lable: 'Xã Đông Thạnh',
                            value: '31369',
                        },
                    ],
                },
                {
                    lable: 'Huyện Châu Thành A',
                    value: '932',
                    wards: [
                        {
                            lable: 'Thị trấn Bảy Ngàn',
                            value: '31346',
                        },
                        {
                            lable: 'Thị trấn Cái Tắc',
                            value: '31362',
                        },
                        {
                            lable: 'Thị trấn Một Ngàn',
                            value: '31342',
                        },
                        {
                            lable: 'Thị trấn Rạch Gòi',
                            value: '31359',
                        },
                        {
                            lable: 'Xã Nhơn Nghĩa A',
                            value: '31357',
                        },
                        {
                            lable: 'Xã Thạnh Xuân',
                            value: '31360',
                        },
                        {
                            lable: 'Xã Trường Long A',
                            value: '31351',
                        },
                        {
                            lable: 'Xã Trường Long Tây',
                            value: '31348',
                        },
                        {
                            lable: 'Xã Tân Hoà',
                            value: '31345',
                        },
                        {
                            lable: 'Xã Tân Phú Thạnh',
                            value: '31363',
                        },
                    ],
                },
                {
                    lable: 'Huyện Phụng Hiệp',
                    value: '934',
                    wards: [
                        {
                            lable: 'Thị trấn Búng Tàu',
                            value: '31433',
                        },
                        {
                            lable: 'Thị trấn Cây Dương',
                            value: '31396',
                        },
                        {
                            lable: 'Thị trấn Kinh Cùng',
                            value: '31393',
                        },
                        {
                            lable: 'Xã Bình Thành',
                            value: '31402',
                        },
                        {
                            lable: 'Xã Hiệp Hưng',
                            value: '31429',
                        },
                        {
                            lable: 'Xã Hòa An',
                            value: '31423',
                        },
                        {
                            lable: 'Xã Hòa Mỹ',
                            value: '31420',
                        },
                        {
                            lable: 'Xã Long Thạnh',
                            value: '31408',
                        },
                        {
                            lable: 'Xã Phương Bình',
                            value: '31426',
                        },
                        {
                            lable: 'Xã Phương Phú',
                            value: '31435',
                        },
                        {
                            lable: 'Xã Phụng Hiệp',
                            value: '31417',
                        },
                        {
                            lable: 'Xã Thạnh Hòa',
                            value: '31405',
                        },
                        {
                            lable: 'Xã Tân Bình',
                            value: '31399',
                        },
                        {
                            lable: 'Xã Tân Long',
                            value: '31438',
                        },
                        {
                            lable: 'Xã Tân Phước Hưng',
                            value: '31432',
                        },
                    ],
                },
                {
                    lable: 'Huyện Vị Thuỷ',
                    value: '935',
                    wards: [
                        {
                            lable: 'Thị trấn Nàng Mau',
                            value: '31441',
                        },
                        {
                            lable: 'Xã Vĩnh Thuận Tây',
                            value: '31453',
                        },
                        {
                            lable: 'Xã Vĩnh Trung',
                            value: '31456',
                        },
                        {
                            lable: 'Xã Vĩnh Tường',
                            value: '31459',
                        },
                        {
                            lable: 'Xã Vị Bình',
                            value: '31468',
                        },
                        {
                            lable: 'Xã Vị Thanh',
                            value: '31465',
                        },
                        {
                            lable: 'Xã Vị Thuỷ',
                            value: '31447',
                        },
                        {
                            lable: 'Xã Vị Thắng',
                            value: '31450',
                        },
                        {
                            lable: 'Xã Vị Trung',
                            value: '31444',
                        },
                        {
                            lable: 'Xã Vị Đông',
                            value: '31462',
                        },
                    ],
                },
                {
                    lable: 'Huyện Long Mỹ',
                    value: '936',
                    wards: [
                        {
                            lable: 'Thị trấn Vĩnh Viễn',
                            value: '31489',
                        },
                        {
                            lable: 'Xã Lương Nghĩa',
                            value: '31493',
                        },
                        {
                            lable: 'Xã Lương Tâm',
                            value: '31492',
                        },
                        {
                            lable: 'Xã Thuận Hòa',
                            value: '31484',
                        },
                        {
                            lable: 'Xã Thuận Hưng',
                            value: '31483',
                        },
                        {
                            lable: 'Xã Vĩnh Thuận Đông',
                            value: '31486',
                        },
                        {
                            lable: 'Xã Vĩnh Viễn A',
                            value: '31490',
                        },
                        {
                            lable: 'Xã Xà Phiên',
                            value: '31495',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Long Mỹ',
                    value: '937',
                    wards: [
                        {
                            lable: 'Phường Bình Thạnh',
                            value: '31473',
                        },
                        {
                            lable: 'Phường Thuận An',
                            value: '31471',
                        },
                        {
                            lable: 'Phường Trà Lồng',
                            value: '31472',
                        },
                        {
                            lable: 'Phường Vĩnh Tường',
                            value: '31475',
                        },
                        {
                            lable: 'Xã Long Bình',
                            value: '31474',
                        },
                        {
                            lable: 'Xã Long Phú',
                            value: '31480',
                        },
                        {
                            lable: 'Xã Long Trị',
                            value: '31477',
                        },
                        {
                            lable: 'Xã Long Trị A',
                            value: '31478',
                        },
                        {
                            lable: 'Xã Tân Phú',
                            value: '31481',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Hoà Bình',
            value: '17',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Hòa Bình',
                    value: '148',
                    wards: [
                        {
                            lable: 'Phường Dân Chủ',
                            value: '04819',
                        },
                        {
                            lable: 'Phường Hữu Nghị',
                            value: '04798',
                        },
                        {
                            lable: 'Phường Kỳ Sơn',
                            value: '04894',
                        },
                        {
                            lable: 'Phường Phương Lâm',
                            value: '04807',
                        },
                        {
                            lable: 'Phường Quỳnh Lâm',
                            value: '04816',
                        },
                        {
                            lable: 'Phường Thái Bình',
                            value: '04789',
                        },
                        {
                            lable: 'Phường Thịnh Lang',
                            value: '04795',
                        },
                        {
                            lable: 'Phường Thống Nhất',
                            value: '04828',
                        },
                        {
                            lable: 'Phường Trung Minh',
                            value: '04918',
                        },
                        {
                            lable: 'Phường Tân Hòa',
                            value: '04792',
                        },
                        {
                            lable: 'Phường Tân Thịnh',
                            value: '04801',
                        },
                        {
                            lable: 'Phường Đồng Tiến',
                            value: '04804',
                        },
                        {
                            lable: 'Xã Hòa Bình',
                            value: '04825',
                        },
                        {
                            lable: 'Xã Hợp Thành',
                            value: '04903',
                        },
                        {
                            lable: 'Xã Mông Hóa',
                            value: '04912',
                        },
                        {
                            lable: 'Xã Quang Tiến',
                            value: '04906',
                        },
                        {
                            lable: 'Xã Thịnh Minh',
                            value: '04897',
                        },
                        {
                            lable: 'Xã Yên Mông',
                            value: '04813',
                        },
                        {
                            lable: 'Xã Độc Lập',
                            value: '04921',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đà Bắc',
                    value: '150',
                    wards: [
                        {
                            lable: 'Thị trấn Đà Bắc',
                            value: '04831',
                        },
                        {
                            lable: 'Xã Cao Sơn',
                            value: '04876',
                        },
                        {
                            lable: 'Xã Giáp Đắt',
                            value: '04840',
                        },
                        {
                            lable: 'Xã Hiền Lương',
                            value: '04885',
                        },
                        {
                            lable: 'Xã Mường Chiềng',
                            value: '04846',
                        },
                        {
                            lable: 'Xã Nánh Nghê',
                            value: '04834',
                        },
                        {
                            lable: 'Xã Tiền Phong',
                            value: '04888',
                        },
                        {
                            lable: 'Xã Toàn Sơn',
                            value: '04879',
                        },
                        {
                            lable: 'Xã Trung Thành',
                            value: '04870',
                        },
                        {
                            lable: 'Xã Tân Minh',
                            value: '04855',
                        },
                        {
                            lable: 'Xã Tân Pheo',
                            value: '04849',
                        },
                        {
                            lable: 'Xã Tú Lý',
                            value: '04867',
                        },
                        {
                            lable: 'Xã Vầy Nưa',
                            value: '04891',
                        },
                        {
                            lable: 'Xã Yên Hòa',
                            value: '04873',
                        },
                        {
                            lable: 'Xã Đoàn Kết',
                            value: '04858',
                        },
                        {
                            lable: 'Xã Đồng Chum',
                            value: '04852',
                        },
                        {
                            lable: 'Xã Đồng Ruộng',
                            value: '04861',
                        },
                    ],
                },
                {
                    lable: 'Huyện Lương Sơn',
                    value: '152',
                    wards: [
                        {
                            lable: 'Thị trấn Lương Sơn',
                            value: '04924',
                        },
                        {
                            lable: 'Xã Cao Dương',
                            value: '05008',
                        },
                        {
                            lable: 'Xã Cao Sơn',
                            value: '04957',
                        },
                        {
                            lable: 'Xã Cư Yên',
                            value: '04960',
                        },
                        {
                            lable: 'Xã Hòa Sơn',
                            value: '04945',
                        },
                        {
                            lable: 'Xã Liên Sơn',
                            value: '04969',
                        },
                        {
                            lable: 'Xã Lâm Sơn',
                            value: '04942',
                        },
                        {
                            lable: 'Xã Nhuận Trạch',
                            value: '04954',
                        },
                        {
                            lable: 'Xã Thanh Cao',
                            value: '05047',
                        },
                        {
                            lable: 'Xã Thanh Sơn',
                            value: '05041',
                        },
                        {
                            lable: 'Xã Tân Vinh',
                            value: '04951',
                        },
                    ],
                },
                {
                    lable: 'Huyện Kim Bôi',
                    value: '153',
                    wards: [
                        {
                            lable: 'Thị trấn Bo',
                            value: '04978',
                        },
                        {
                            lable: 'Xã Bình Sơn',
                            value: '04990',
                        },
                        {
                            lable: 'Xã Cuối Hạ',
                            value: '05077',
                        },
                        {
                            lable: 'Xã Hùng Sơn',
                            value: '04987',
                        },
                        {
                            lable: 'Xã Hợp Tiến',
                            value: '05038',
                        },
                        {
                            lable: 'Xã Kim Bôi',
                            value: '05065',
                        },
                        {
                            lable: 'Xã Kim Lập',
                            value: '05035',
                        },
                        {
                            lable: 'Xã Mi Hòa',
                            value: '05083',
                        },
                        {
                            lable: 'Xã Nam Thượng',
                            value: '05068',
                        },
                        {
                            lable: 'Xã Nuông Dăm',
                            value: '05086',
                        },
                        {
                            lable: 'Xã Sào Báy',
                            value: '05080',
                        },
                        {
                            lable: 'Xã Tú Sơn',
                            value: '04999',
                        },
                        {
                            lable: 'Xã Vĩnh Tiến',
                            value: '05005',
                        },
                        {
                            lable: 'Xã Vĩnh Đồng',
                            value: '05026',
                        },
                        {
                            lable: 'Xã Xuân Thủy',
                            value: '05017',
                        },
                        {
                            lable: 'Xã Đông Bắc',
                            value: '05014',
                        },
                        {
                            lable: 'Xã Đú Sáng',
                            value: '04984',
                        },
                    ],
                },
                {
                    lable: 'Huyện Cao Phong',
                    value: '154',
                    wards: [
                        {
                            lable: 'Thị trấn Cao Phong',
                            value: '05089',
                        },
                        {
                            lable: 'Xã Bình Thanh',
                            value: '05092',
                        },
                        {
                            lable: 'Xã Bắc Phong',
                            value: '05098',
                        },
                        {
                            lable: 'Xã Dũng Phong',
                            value: '05116',
                        },
                        {
                            lable: 'Xã Hợp Phong',
                            value: '05104',
                        },
                        {
                            lable: 'Xã Nam Phong',
                            value: '05119',
                        },
                        {
                            lable: 'Xã Thu Phong',
                            value: '05101',
                        },
                        {
                            lable: 'Xã Thung Nai',
                            value: '05095',
                        },
                        {
                            lable: 'Xã Thạch Yên',
                            value: '05125',
                        },
                        {
                            lable: 'Xã Tây Phong',
                            value: '05110',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tân Lạc',
                    value: '155',
                    wards: [
                        {
                            lable: 'Thị trấn Mãn Đức',
                            value: '05128',
                        },
                        {
                            lable: 'Xã Gia Mô',
                            value: '05197',
                        },
                        {
                            lable: 'Xã Lỗ Sơn',
                            value: '05191',
                        },
                        {
                            lable: 'Xã Mỹ Hòa',
                            value: '05143',
                        },
                        {
                            lable: 'Xã Ngọc Mỹ',
                            value: '05170',
                        },
                        {
                            lable: 'Xã Ngổ Luông',
                            value: '05194',
                        },
                        {
                            lable: 'Xã Nhân Mỹ',
                            value: '05182',
                        },
                        {
                            lable: 'Xã Phong Phú',
                            value: '05158',
                        },
                        {
                            lable: 'Xã Phú Cường',
                            value: '05140',
                        },
                        {
                            lable: 'Xã Phú Vinh',
                            value: '05137',
                        },
                        {
                            lable: 'Xã Quyết Chiến',
                            value: '05152',
                        },
                        {
                            lable: 'Xã Suối Hoa',
                            value: '05134',
                        },
                        {
                            lable: 'Xã Thanh Hối',
                            value: '05167',
                        },
                        {
                            lable: 'Xã Tử Nê',
                            value: '05164',
                        },
                        {
                            lable: 'Xã Vân Sơn',
                            value: '05176',
                        },
                        {
                            lable: 'Xã Đông Lai',
                            value: '05173',
                        },
                    ],
                },
                {
                    lable: 'Huyện Mai Châu',
                    value: '156',
                    wards: [
                        {
                            lable: 'Thị trấn Mai Châu',
                            value: '05200',
                        },
                        {
                            lable: 'Xã Bao La',
                            value: '05227',
                        },
                        {
                            lable: 'Xã Chiềng Châu',
                            value: '05248',
                        },
                        {
                            lable: 'Xã Cun Pheo',
                            value: '05224',
                        },
                        {
                            lable: 'Xã Hang Kia',
                            value: '05212',
                        },
                        {
                            lable: 'Xã Mai Hạ',
                            value: '05251',
                        },
                        {
                            lable: 'Xã Mai Hịch',
                            value: '05257',
                        },
                        {
                            lable: 'Xã Nà Phòn',
                            value: '05242',
                        },
                        {
                            lable: 'Xã Pà Cò',
                            value: '05209',
                        },
                        {
                            lable: 'Xã Săm Khóe',
                            value: '05245',
                        },
                        {
                            lable: 'Xã Sơn Thủy',
                            value: '05206',
                        },
                        {
                            lable: 'Xã Thành Sơn',
                            value: '05254',
                        },
                        {
                            lable: 'Xã Tân Thành',
                            value: '04882',
                        },
                        {
                            lable: 'Xã Tòng Đậu',
                            value: '05233',
                        },
                        {
                            lable: 'Xã Vạn Mai',
                            value: '05263',
                        },
                        {
                            lable: 'Xã Đồng Tân',
                            value: '05221',
                        },
                    ],
                },
                {
                    lable: 'Huyện Lạc Sơn',
                    value: '157',
                    wards: [
                        {
                            lable: 'Thị trấn Vụ Bản',
                            value: '05266',
                        },
                        {
                            lable: 'Xã Bình Hẻm',
                            value: '05308',
                        },
                        {
                            lable: 'Xã Chí Đạo',
                            value: '05323',
                        },
                        {
                            lable: 'Xã Hương Nhượng',
                            value: '05332',
                        },
                        {
                            lable: 'Xã Miền Đồi',
                            value: '05272',
                        },
                        {
                            lable: 'Xã Mỹ Thành',
                            value: '05275',
                        },
                        {
                            lable: 'Xã Ngọc Lâu',
                            value: '05350',
                        },
                        {
                            lable: 'Xã Ngọc Sơn',
                            value: '05329',
                        },
                        {
                            lable: 'Xã Nhân Nghĩa',
                            value: '05290',
                        },
                        {
                            lable: 'Xã Quyết Thắng',
                            value: '05299',
                        },
                        {
                            lable: 'Xã Quý Hòa',
                            value: '05269',
                        },
                        {
                            lable: 'Xã Thượng Cốc',
                            value: '05293',
                        },
                        {
                            lable: 'Xã Tuân Đạo',
                            value: '05278',
                        },
                        {
                            lable: 'Xã Tân Lập',
                            value: '05287',
                        },
                        {
                            lable: 'Xã Tân Mỹ',
                            value: '05344',
                        },
                        {
                            lable: 'Xã Tự Do',
                            value: '05338',
                        },
                        {
                            lable: 'Xã Văn Nghĩa',
                            value: '05281',
                        },
                        {
                            lable: 'Xã Văn Sơn',
                            value: '05284',
                        },
                        {
                            lable: 'Xã Vũ Bình',
                            value: '05335',
                        },
                        {
                            lable: 'Xã Xuất Hóa',
                            value: '05302',
                        },
                        {
                            lable: 'Xã Yên Nghiệp',
                            value: '05341',
                        },
                        {
                            lable: 'Xã Yên Phú',
                            value: '05305',
                        },
                        {
                            lable: 'Xã Ân Nghĩa',
                            value: '05347',
                        },
                        {
                            lable: 'Xã Định Cư',
                            value: '05320',
                        },
                    ],
                },
                {
                    lable: 'Huyện Yên Thủy',
                    value: '158',
                    wards: [
                        {
                            lable: 'Thị trấn Hàng Trạm',
                            value: '05353',
                        },
                        {
                            lable: 'Xã Bảo Hiệu',
                            value: '05365',
                        },
                        {
                            lable: 'Xã Hữu Lợi',
                            value: '05371',
                        },
                        {
                            lable: 'Xã Lạc Lương',
                            value: '05362',
                        },
                        {
                            lable: 'Xã Lạc Sỹ',
                            value: '05356',
                        },
                        {
                            lable: 'Xã Lạc Thịnh',
                            value: '05374',
                        },
                        {
                            lable: 'Xã Ngọc Lương',
                            value: '05389',
                        },
                        {
                            lable: 'Xã Phú Lai',
                            value: '05383',
                        },
                        {
                            lable: 'Xã Yên Trị',
                            value: '05386',
                        },
                        {
                            lable: 'Xã Đa Phúc',
                            value: '05368',
                        },
                        {
                            lable: 'Xã Đoàn Kết',
                            value: '05380',
                        },
                    ],
                },
                {
                    lable: 'Huyện Lạc Thủy',
                    value: '159',
                    wards: [
                        {
                            lable: 'Thị trấn Ba Hàng Đồi',
                            value: '04981',
                        },
                        {
                            lable: 'Thị trấn Chi Nê',
                            value: '05392',
                        },
                        {
                            lable: 'Xã An Bình',
                            value: '05428',
                        },
                        {
                            lable: 'Xã Hưng Thi',
                            value: '05404',
                        },
                        {
                            lable: 'Xã Khoan Dụ',
                            value: '05413',
                        },
                        {
                            lable: 'Xã Phú Nghĩa',
                            value: '05395',
                        },
                        {
                            lable: 'Xã Phú Thành',
                            value: '05398',
                        },
                        {
                            lable: 'Xã Thống Nhất',
                            value: '05425',
                        },
                        {
                            lable: 'Xã Yên Bồng',
                            value: '05422',
                        },
                        {
                            lable: 'Xã Đồng Tâm',
                            value: '05419',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Hưng Yên',
            value: '33',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Hưng Yên',
                    value: '323',
                    wards: [
                        {
                            lable: 'Phường An Tảo',
                            value: '11956',
                        },
                        {
                            lable: 'Phường Hiến Nam',
                            value: '11953',
                        },
                        {
                            lable: 'Phường Hồng Châu',
                            value: '11968',
                        },
                        {
                            lable: 'Phường Lam Sơn',
                            value: '11950',
                        },
                        {
                            lable: 'Phường Lê Lợi',
                            value: '11959',
                        },
                        {
                            lable: 'Phường Minh Khai',
                            value: '11962',
                        },
                        {
                            lable: 'Phường Quang Trung',
                            value: '11965',
                        },
                        {
                            lable: 'Xã Bảo Khê',
                            value: '11983',
                        },
                        {
                            lable: 'Xã Hoàng Hanh',
                            value: '12388',
                        },
                        {
                            lable: 'Xã Hùng Cường',
                            value: '12334',
                        },
                        {
                            lable: 'Xã Hồng Nam',
                            value: '11977',
                        },
                        {
                            lable: 'Xã Liên Phương',
                            value: '11974',
                        },
                        {
                            lable: 'Xã Phú Cường',
                            value: '12331',
                        },
                        {
                            lable: 'Xã Phương Chiểu',
                            value: '12382',
                        },
                        {
                            lable: 'Xã Quảng Châu',
                            value: '11980',
                        },
                        {
                            lable: 'Xã Trung Nghĩa',
                            value: '11971',
                        },
                        {
                            lable: 'Xã Tân Hưng',
                            value: '12385',
                        },
                    ],
                },
                {
                    lable: 'Huyện Văn Lâm',
                    value: '325',
                    wards: [
                        {
                            lable: 'Thị trấn Như Quỳnh',
                            value: '11986',
                        },
                        {
                            lable: 'Xã Chỉ Đạo',
                            value: '11992',
                        },
                        {
                            lable: 'Xã Lương Tài',
                            value: '12010',
                        },
                        {
                            lable: 'Xã Lạc Hồng',
                            value: '12016',
                        },
                        {
                            lable: 'Xã Lạc Đạo',
                            value: '11989',
                        },
                        {
                            lable: 'Xã Minh Hải',
                            value: '12007',
                        },
                        {
                            lable: 'Xã Trưng Trắc',
                            value: '12013',
                        },
                        {
                            lable: 'Xã Tân Quang',
                            value: '12001',
                        },
                        {
                            lable: 'Xã Việt Hưng',
                            value: '11998',
                        },
                        {
                            lable: 'Xã Đình Dù',
                            value: '12004',
                        },
                        {
                            lable: 'Xã Đại Đồng',
                            value: '11995',
                        },
                    ],
                },
                {
                    lable: 'Huyện Văn Giang',
                    value: '326',
                    wards: [
                        {
                            lable: 'Thị trấn Văn Giang',
                            value: '12019',
                        },
                        {
                            lable: 'Xã Cửu Cao',
                            value: '12025',
                        },
                        {
                            lable: 'Xã Liên Nghĩa',
                            value: '12040',
                        },
                        {
                            lable: 'Xã Long Hưng',
                            value: '12034',
                        },
                        {
                            lable: 'Xã Mễ Sở',
                            value: '12049',
                        },
                        {
                            lable: 'Xã Nghĩa Trụ',
                            value: '12031',
                        },
                        {
                            lable: 'Xã Phụng Công',
                            value: '12028',
                        },
                        {
                            lable: 'Xã Thắng Lợi',
                            value: '12046',
                        },
                        {
                            lable: 'Xã Tân Tiến',
                            value: '12043',
                        },
                        {
                            lable: 'Xã Vĩnh Khúc',
                            value: '12037',
                        },
                        {
                            lable: 'Xã Xuân Quan',
                            value: '12022',
                        },
                    ],
                },
                {
                    lable: 'Huyện Yên Mỹ',
                    value: '327',
                    wards: [
                        {
                            lable: 'Thị trấn Yên Mỹ',
                            value: '12052',
                        },
                        {
                            lable: 'Xã Giai Phạm',
                            value: '12055',
                        },
                        {
                            lable: 'Xã Hoàn Long',
                            value: '12070',
                        },
                        {
                            lable: 'Xã Liêu Xá',
                            value: '12067',
                        },
                        {
                            lable: 'Xã Lý Thường Kiệt',
                            value: '12097',
                        },
                        {
                            lable: 'Xã Minh Châu',
                            value: '12091',
                        },
                        {
                            lable: 'Xã Nghĩa Hiệp',
                            value: '12058',
                        },
                        {
                            lable: 'Xã Ngọc Long',
                            value: '12064',
                        },
                        {
                            lable: 'Xã Thanh Long',
                            value: '12076',
                        },
                        {
                            lable: 'Xã Trung Hòa',
                            value: '12085',
                        },
                        {
                            lable: 'Xã Trung Hưng',
                            value: '12094',
                        },
                        {
                            lable: 'Xã Tân Lập',
                            value: '12073',
                        },
                        {
                            lable: 'Xã Tân Việt',
                            value: '12100',
                        },
                        {
                            lable: 'Xã Việt Cường',
                            value: '12082',
                        },
                        {
                            lable: 'Xã Yên Hòa',
                            value: '12088',
                        },
                        {
                            lable: 'Xã Yên Phú',
                            value: '12079',
                        },
                        {
                            lable: 'Xã Đồng Than',
                            value: '12061',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Mỹ Hào',
                    value: '328',
                    wards: [
                        {
                            lable: 'Phường Bạch Sam',
                            value: '12124',
                        },
                        {
                            lable: 'Phường Bần Yên Nhân',
                            value: '12103',
                        },
                        {
                            lable: 'Phường Dị Sử',
                            value: '12121',
                        },
                        {
                            lable: 'Phường Minh Đức',
                            value: '12127',
                        },
                        {
                            lable: 'Phường Nhân Hòa',
                            value: '12118',
                        },
                        {
                            lable: 'Phường Phan Đình Phùng',
                            value: '12106',
                        },
                        {
                            lable: 'Phường Phùng Chí Kiên',
                            value: '12130',
                        },
                        {
                            lable: 'Xã Cẩm Xá',
                            value: '12109',
                        },
                        {
                            lable: 'Xã Dương Quang',
                            value: '12112',
                        },
                        {
                            lable: 'Xã Hòa Phong',
                            value: '12115',
                        },
                        {
                            lable: 'Xã Hưng Long',
                            value: '12139',
                        },
                        {
                            lable: 'Xã Ngọc Lâm',
                            value: '12136',
                        },
                        {
                            lable: 'Xã Xuân Dục',
                            value: '12133',
                        },
                    ],
                },
                {
                    lable: 'Huyện Ân Thi',
                    value: '329',
                    wards: [
                        {
                            lable: 'Thị trấn Ân Thi',
                            value: '12142',
                        },
                        {
                            lable: 'Xã Bãi Sậy',
                            value: '12151',
                        },
                        {
                            lable: 'Xã Bắc Sơn',
                            value: '12148',
                        },
                        {
                            lable: 'Xã Cẩm Ninh',
                            value: '12181',
                        },
                        {
                            lable: 'Xã Hoàng Hoa Thám',
                            value: '12169',
                        },
                        {
                            lable: 'Xã Hạ Lễ',
                            value: '12202',
                        },
                        {
                            lable: 'Xã Hồ Tùng Mậu',
                            value: '12190',
                        },
                        {
                            lable: 'Xã Hồng Quang',
                            value: '12199',
                        },
                        {
                            lable: 'Xã Hồng Vân',
                            value: '12196',
                        },
                        {
                            lable: 'Xã Nguyễn Trãi',
                            value: '12184',
                        },
                        {
                            lable: 'Xã Phù Ủng',
                            value: '12145',
                        },
                        {
                            lable: 'Xã Quang Vinh',
                            value: '12163',
                        },
                        {
                            lable: 'Xã Quảng Lãng',
                            value: '12172',
                        },
                        {
                            lable: 'Xã Tiền Phong',
                            value: '12193',
                        },
                        {
                            lable: 'Xã Tân Phúc',
                            value: '12157',
                        },
                        {
                            lable: 'Xã Vân Du',
                            value: '12160',
                        },
                        {
                            lable: 'Xã Văn Nhuệ',
                            value: '12175',
                        },
                        {
                            lable: 'Xã Xuân Trúc',
                            value: '12166',
                        },
                        {
                            lable: 'Xã Đa Lộc',
                            value: '12187',
                        },
                        {
                            lable: 'Xã Đào Dương',
                            value: '12154',
                        },
                        {
                            lable: 'Xã Đặng Lễ',
                            value: '12178',
                        },
                    ],
                },
                {
                    lable: 'Huyện Khoái Châu',
                    value: '330',
                    wards: [
                        {
                            lable: 'Thị trấn Khoái Châu',
                            value: '12205',
                        },
                        {
                            lable: 'Xã An Vĩ',
                            value: '12229',
                        },
                        {
                            lable: 'Xã Bình Kiều',
                            value: '12235',
                        },
                        {
                            lable: 'Xã Bình Minh',
                            value: '12211',
                        },
                        {
                            lable: 'Xã Chí Tân',
                            value: '12265',
                        },
                        {
                            lable: 'Xã Dân Tiến',
                            value: '12238',
                        },
                        {
                            lable: 'Xã Dạ Trạch',
                            value: '12214',
                        },
                        {
                            lable: 'Xã Hàm Tử',
                            value: '12217',
                        },
                        {
                            lable: 'Xã Hồng Tiến',
                            value: '12244',
                        },
                        {
                            lable: 'Xã Liên Khê',
                            value: '12250',
                        },
                        {
                            lable: 'Xã Nhuế Dương',
                            value: '12277',
                        },
                        {
                            lable: 'Xã Phùng Hưng',
                            value: '12253',
                        },
                        {
                            lable: 'Xã Thuần Hưng',
                            value: '12271',
                        },
                        {
                            lable: 'Xã Thành Công',
                            value: '12274',
                        },
                        {
                            lable: 'Xã Tân Châu',
                            value: '12247',
                        },
                        {
                            lable: 'Xã Tân Dân',
                            value: '12223',
                        },
                        {
                            lable: 'Xã Tứ Dân',
                            value: '12226',
                        },
                        {
                            lable: 'Xã Việt Hòa',
                            value: '12256',
                        },
                        {
                            lable: 'Xã Ông Đình',
                            value: '12220',
                        },
                        {
                            lable: 'Xã Đông Kết',
                            value: '12232',
                        },
                        {
                            lable: 'Xã Đông Ninh',
                            value: '12259',
                        },
                        {
                            lable: 'Xã Đông Tảo',
                            value: '12208',
                        },
                        {
                            lable: 'Xã Đại Hưng',
                            value: '12268',
                        },
                        {
                            lable: 'Xã Đại Tập',
                            value: '12262',
                        },
                        {
                            lable: 'Xã Đồng Tiến',
                            value: '12241',
                        },
                    ],
                },
                {
                    lable: 'Huyện Kim Động',
                    value: '331',
                    wards: [
                        {
                            lable: 'Thị trấn Lương Bằng',
                            value: '12280',
                        },
                        {
                            lable: 'Xã Chính Nghĩa',
                            value: '12304',
                        },
                        {
                            lable: 'Xã Hiệp Cường',
                            value: '12328',
                        },
                        {
                            lable: 'Xã Hùng An',
                            value: '12319',
                        },
                        {
                            lable: 'Xã Mai Động',
                            value: '12313',
                        },
                        {
                            lable: 'Xã Nghĩa Dân',
                            value: '12283',
                        },
                        {
                            lable: 'Xã Ngọc Thanh',
                            value: '12322',
                        },
                        {
                            lable: 'Xã Nhân La',
                            value: '12307',
                        },
                        {
                            lable: 'Xã Phú Thịnh',
                            value: '12310',
                        },
                        {
                            lable: 'Xã Phạm Ngũ Lão',
                            value: '12292',
                        },
                        {
                            lable: 'Xã Song Mai',
                            value: '12301',
                        },
                        {
                            lable: 'Xã Thọ Vinh',
                            value: '12295',
                        },
                        {
                            lable: 'Xã Toàn Thắng',
                            value: '12286',
                        },
                        {
                            lable: 'Xã Vĩnh Xá',
                            value: '12289',
                        },
                        {
                            lable: 'Xã Vũ Xá',
                            value: '12325',
                        },
                        {
                            lable: 'Xã Đồng Thanh',
                            value: '12298',
                        },
                        {
                            lable: 'Xã Đức Hợp',
                            value: '12316',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tiên Lữ',
                    value: '332',
                    wards: [
                        {
                            lable: 'Thị trấn Vương',
                            value: '12337',
                        },
                        {
                            lable: 'Xã An Viên',
                            value: '12355',
                        },
                        {
                            lable: 'Xã Cương Chính',
                            value: '12376',
                        },
                        {
                            lable: 'Xã Dị Chế',
                            value: '12349',
                        },
                        {
                            lable: 'Xã Hưng Đạo',
                            value: '12340',
                        },
                        {
                            lable: 'Xã Hải Triều',
                            value: '12364',
                        },
                        {
                            lable: 'Xã Lệ Xá',
                            value: '12352',
                        },
                        {
                            lable: 'Xã Minh Phượng',
                            value: '12379',
                        },
                        {
                            lable: 'Xã Ngô Quyền',
                            value: '12343',
                        },
                        {
                            lable: 'Xã Nhật Tân',
                            value: '12346',
                        },
                        {
                            lable: 'Xã Thiện Phiến',
                            value: '12370',
                        },
                        {
                            lable: 'Xã Thụy Lôi',
                            value: '12373',
                        },
                        {
                            lable: 'Xã Thủ Sỹ',
                            value: '12367',
                        },
                        {
                            lable: 'Xã Trung Dũng',
                            value: '12361',
                        },
                        {
                            lable: 'Xã Đức Thắng',
                            value: '12358',
                        },
                    ],
                },
                {
                    lable: 'Huyện Phù Cừ',
                    value: '333',
                    wards: [
                        {
                            lable: 'Thị trấn Trần Cao',
                            value: '12391',
                        },
                        {
                            lable: 'Xã Minh Hoàng',
                            value: '12403',
                        },
                        {
                            lable: 'Xã Minh Tiến',
                            value: '12424',
                        },
                        {
                            lable: 'Xã Minh Tân',
                            value: '12394',
                        },
                        {
                            lable: 'Xã Nguyên Hòa',
                            value: '12427',
                        },
                        {
                            lable: 'Xã Nhật Quang',
                            value: '12415',
                        },
                        {
                            lable: 'Xã Phan Sào Nam',
                            value: '12397',
                        },
                        {
                            lable: 'Xã Quang Hưng',
                            value: '12400',
                        },
                        {
                            lable: 'Xã Tam Đa',
                            value: '12421',
                        },
                        {
                            lable: 'Xã Tiền Tiến',
                            value: '12418',
                        },
                        {
                            lable: 'Xã Tống Phan',
                            value: '12409',
                        },
                        {
                            lable: 'Xã Tống Trân',
                            value: '12430',
                        },
                        {
                            lable: 'Xã Đoàn Đào',
                            value: '12406',
                        },
                        {
                            lable: 'Xã Đình Cao',
                            value: '12412',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Khánh Hòa',
            value: '56',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Nha Trang',
                    value: '568',
                    wards: [
                        {
                            lable: 'Phường Lộc Thọ',
                            value: '22363',
                        },
                        {
                            lable: 'Phường Ngọc Hiệp',
                            value: '22336',
                        },
                        {
                            lable: 'Phường Phương Sài',
                            value: '22351',
                        },
                        {
                            lable: 'Phường Phương Sơn',
                            value: '22354',
                        },
                        {
                            lable: 'Phường Phước Hòa',
                            value: '22372',
                        },
                        {
                            lable: 'Phường Phước Hải',
                            value: '22357',
                        },
                        {
                            lable: 'Phường Phước Long',
                            value: '22378',
                        },
                        {
                            lable: 'Phường Phước Tiến',
                            value: '22366',
                        },
                        {
                            lable: 'Phường Phước Tân',
                            value: '22360',
                        },
                        {
                            lable: 'Phường Tân Lập',
                            value: '22369',
                        },
                        {
                            lable: 'Phường Vĩnh Hòa',
                            value: '22327',
                        },
                        {
                            lable: 'Phường Vĩnh Hải',
                            value: '22330',
                        },
                        {
                            lable: 'Phường Vĩnh Nguyên',
                            value: '22375',
                        },
                        {
                            lable: 'Phường Vĩnh Phước',
                            value: '22333',
                        },
                        {
                            lable: 'Phường Vĩnh Thọ',
                            value: '22339',
                        },
                        {
                            lable: 'Phường Vĩnh Trường',
                            value: '22381',
                        },
                        {
                            lable: 'Phường Vạn Thạnh',
                            value: '22348',
                        },
                        {
                            lable: 'Phường Vạn Thắng',
                            value: '22345',
                        },
                        {
                            lable: 'Phường Xương Huân',
                            value: '22342',
                        },
                        {
                            lable: 'Xã Phước Đồng',
                            value: '22405',
                        },
                        {
                            lable: 'Xã Vĩnh Hiệp',
                            value: '22399',
                        },
                        {
                            lable: 'Xã Vĩnh Lương',
                            value: '22384',
                        },
                        {
                            lable: 'Xã Vĩnh Ngọc',
                            value: '22390',
                        },
                        {
                            lable: 'Xã Vĩnh Phương',
                            value: '22387',
                        },
                        {
                            lable: 'Xã Vĩnh Thái',
                            value: '22402',
                        },
                        {
                            lable: 'Xã Vĩnh Thạnh',
                            value: '22393',
                        },
                        {
                            lable: 'Xã Vĩnh Trung',
                            value: '22396',
                        },
                    ],
                },
                {
                    lable: 'Thành phố Cam Ranh',
                    value: '569',
                    wards: [
                        {
                            lable: 'Phường Ba Ngòi',
                            value: '22423',
                        },
                        {
                            lable: 'Phường Cam Linh',
                            value: '22432',
                        },
                        {
                            lable: 'Phường Cam Lộc',
                            value: '22417',
                        },
                        {
                            lable: 'Phường Cam Lợi',
                            value: '22429',
                        },
                        {
                            lable: 'Phường Cam Nghĩa',
                            value: '22408',
                        },
                        {
                            lable: 'Phường Cam Phú',
                            value: '22420',
                        },
                        {
                            lable: 'Phường Cam Phúc Bắc',
                            value: '22411',
                        },
                        {
                            lable: 'Phường Cam Phúc Nam',
                            value: '22414',
                        },
                        {
                            lable: 'Phường Cam Thuận',
                            value: '22426',
                        },
                        {
                            lable: 'Xã Cam Bình',
                            value: '22486',
                        },
                        {
                            lable: 'Xã Cam Lập',
                            value: '22483',
                        },
                        {
                            lable: 'Xã Cam Phước Đông',
                            value: '22474',
                        },
                        {
                            lable: 'Xã Cam Thành Nam',
                            value: '22468',
                        },
                        {
                            lable: 'Xã Cam Thịnh Tây',
                            value: '22477',
                        },
                        {
                            lable: 'Xã Cam Thịnh Đông',
                            value: '22480',
                        },
                    ],
                },
                {
                    lable: 'Huyện Cam Lâm',
                    value: '570',
                    wards: [
                        {
                            lable: 'Thị trấn Cam Đức',
                            value: '22453',
                        },
                        {
                            lable: 'Xã Cam An Bắc',
                            value: '22465',
                        },
                        {
                            lable: 'Xã Cam An Nam',
                            value: '22471',
                        },
                        {
                            lable: 'Xã Cam Hiệp Bắc',
                            value: '22450',
                        },
                        {
                            lable: 'Xã Cam Hiệp Nam',
                            value: '22456',
                        },
                        {
                            lable: 'Xã Cam Hòa',
                            value: '22438',
                        },
                        {
                            lable: 'Xã Cam Hải Tây',
                            value: '22444',
                        },
                        {
                            lable: 'Xã Cam Hải Đông',
                            value: '22441',
                        },
                        {
                            lable: 'Xã Cam Phước Tây',
                            value: '22459',
                        },
                        {
                            lable: 'Xã Cam Thành Bắc',
                            value: '22462',
                        },
                        {
                            lable: 'Xã Cam Tân',
                            value: '22435',
                        },
                        {
                            lable: 'Xã Suối Cát',
                            value: '22708',
                        },
                        {
                            lable: 'Xã Suối Tân',
                            value: '22711',
                        },
                        {
                            lable: 'Xã Sơn Tân',
                            value: '22447',
                        },
                    ],
                },
                {
                    lable: 'Huyện Vạn Ninh',
                    value: '571',
                    wards: [
                        {
                            lable: 'Thị trấn Vạn Giã',
                            value: '22489',
                        },
                        {
                            lable: 'Xã Vạn Bình',
                            value: '22501',
                        },
                        {
                            lable: 'Xã Vạn Hưng',
                            value: '22525',
                        },
                        {
                            lable: 'Xã Vạn Khánh',
                            value: '22507',
                        },
                        {
                            lable: 'Xã Vạn Long',
                            value: '22498',
                        },
                        {
                            lable: 'Xã Vạn Lương',
                            value: '22513',
                        },
                        {
                            lable: 'Xã Vạn Phú',
                            value: '22510',
                        },
                        {
                            lable: 'Xã Vạn Phước',
                            value: '22495',
                        },
                        {
                            lable: 'Xã Vạn Thạnh',
                            value: '22519',
                        },
                        {
                            lable: 'Xã Vạn Thắng',
                            value: '22516',
                        },
                        {
                            lable: 'Xã Vạn Thọ',
                            value: '22504',
                        },
                        {
                            lable: 'Xã Xuân Sơn',
                            value: '22522',
                        },
                        {
                            lable: 'Xã Đại Lãnh',
                            value: '22492',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Ninh Hòa',
                    value: '572',
                    wards: [
                        {
                            lable: 'Phường Ninh Diêm',
                            value: '22561',
                        },
                        {
                            lable: 'Phường Ninh Giang',
                            value: '22591',
                        },
                        {
                            lable: 'Phường Ninh Hiệp',
                            value: '22528',
                        },
                        {
                            lable: 'Phường Ninh Hà',
                            value: '22594',
                        },
                        {
                            lable: 'Phường Ninh Hải',
                            value: '22543',
                        },
                        {
                            lable: 'Phường Ninh Thủy',
                            value: '22567',
                        },
                        {
                            lable: 'Phường Ninh Đa',
                            value: '22570',
                        },
                        {
                            lable: 'Xã Ninh An',
                            value: '22540',
                        },
                        {
                            lable: 'Xã Ninh Bình',
                            value: '22576',
                        },
                        {
                            lable: 'Xã Ninh Hưng',
                            value: '22597',
                        },
                        {
                            lable: 'Xã Ninh Lộc',
                            value: '22600',
                        },
                        {
                            lable: 'Xã Ninh Phú',
                            value: '22582',
                        },
                        {
                            lable: 'Xã Ninh Phước',
                            value: '22579',
                        },
                        {
                            lable: 'Xã Ninh Phụng',
                            value: '22573',
                        },
                        {
                            lable: 'Xã Ninh Quang',
                            value: '22588',
                        },
                        {
                            lable: 'Xã Ninh Sim',
                            value: '22552',
                        },
                        {
                            lable: 'Xã Ninh Sơn',
                            value: '22531',
                        },
                        {
                            lable: 'Xã Ninh Thân',
                            value: '22558',
                        },
                        {
                            lable: 'Xã Ninh Thượng',
                            value: '22537',
                        },
                        {
                            lable: 'Xã Ninh Thọ',
                            value: '22546',
                        },
                        {
                            lable: 'Xã Ninh Trung',
                            value: '22549',
                        },
                        {
                            lable: 'Xã Ninh Tân',
                            value: '22585',
                        },
                        {
                            lable: 'Xã Ninh Tây',
                            value: '22534',
                        },
                        {
                            lable: 'Xã Ninh Vân',
                            value: '22606',
                        },
                        {
                            lable: 'Xã Ninh Xuân',
                            value: '22555',
                        },
                        {
                            lable: 'Xã Ninh Ích',
                            value: '22603',
                        },
                        {
                            lable: 'Xã Ninh Đông',
                            value: '22564',
                        },
                    ],
                },
                {
                    lable: 'Huyện Khánh Vĩnh',
                    value: '573',
                    wards: [
                        {
                            lable: 'Thị trấn Khánh Vĩnh',
                            value: '22609',
                        },
                        {
                            lable: 'Xã Cầu Bà',
                            value: '22636',
                        },
                        {
                            lable: 'Xã Giang Ly',
                            value: '22633',
                        },
                        {
                            lable: 'Xã Khánh Bình',
                            value: '22615',
                        },
                        {
                            lable: 'Xã Khánh Hiệp',
                            value: '22612',
                        },
                        {
                            lable: 'Xã Khánh Nam',
                            value: '22627',
                        },
                        {
                            lable: 'Xã Khánh Phú',
                            value: '22645',
                        },
                        {
                            lable: 'Xã Khánh Thành',
                            value: '22642',
                        },
                        {
                            lable: 'Xã Khánh Thượng',
                            value: '22624',
                        },
                        {
                            lable: 'Xã Khánh Trung',
                            value: '22618',
                        },
                        {
                            lable: 'Xã Khánh Đông',
                            value: '22621',
                        },
                        {
                            lable: 'Xã Liên Sang',
                            value: '22639',
                        },
                        {
                            lable: 'Xã Sông Cầu',
                            value: '22630',
                        },
                        {
                            lable: 'Xã Sơn Thái',
                            value: '22648',
                        },
                    ],
                },
                {
                    lable: 'Huyện Diên Khánh',
                    value: '574',
                    wards: [
                        {
                            lable: 'Thị trấn Diên Khánh',
                            value: '22651',
                        },
                        {
                            lable: 'Xã Bình Lộc',
                            value: '22696',
                        },
                        {
                            lable: 'Xã Diên An',
                            value: '22693',
                        },
                        {
                            lable: 'Xã Diên Hòa',
                            value: '22684',
                        },
                        {
                            lable: 'Xã Diên Lâm',
                            value: '22654',
                        },
                        {
                            lable: 'Xã Diên Lạc',
                            value: '22678',
                        },
                        {
                            lable: 'Xã Diên Phú',
                            value: '22669',
                        },
                        {
                            lable: 'Xã Diên Phước',
                            value: '22675',
                        },
                        {
                            lable: 'Xã Diên Sơn',
                            value: '22663',
                        },
                        {
                            lable: 'Xã Diên Thạnh',
                            value: '22687',
                        },
                        {
                            lable: 'Xã Diên Thọ',
                            value: '22672',
                        },
                        {
                            lable: 'Xã Diên Toàn',
                            value: '22690',
                        },
                        {
                            lable: 'Xã Diên Tân',
                            value: '22681',
                        },
                        {
                            lable: 'Xã Diên Xuân',
                            value: '22660',
                        },
                        {
                            lable: 'Xã Diên Điền',
                            value: '22657',
                        },
                        {
                            lable: 'Xã Diên Đồng',
                            value: '22666',
                        },
                        {
                            lable: 'Xã Suối Hiệp',
                            value: '22702',
                        },
                        {
                            lable: 'Xã Suối Tiên',
                            value: '22705',
                        },
                    ],
                },
                {
                    lable: 'Huyện Khánh Sơn',
                    value: '575',
                    wards: [
                        {
                            lable: 'Thị trấn Tô Hạp',
                            value: '22714',
                        },
                        {
                            lable: 'Xã Ba Cụm Bắc',
                            value: '22732',
                        },
                        {
                            lable: 'Xã Ba Cụm Nam',
                            value: '22735',
                        },
                        {
                            lable: 'Xã Sơn Bình',
                            value: '22726',
                        },
                        {
                            lable: 'Xã Sơn Hiệp',
                            value: '22723',
                        },
                        {
                            lable: 'Xã Sơn Lâm',
                            value: '22720',
                        },
                        {
                            lable: 'Xã Sơn Trung',
                            value: '22729',
                        },
                        {
                            lable: 'Xã Thành Sơn',
                            value: '22717',
                        },
                    ],
                },
                {
                    lable: 'Huyện Trường Sa',
                    value: '576',
                    wards: [
                        {
                            lable: 'Thị trấn Trường Sa',
                            value: '22736',
                        },
                        {
                            lable: 'Xã Sinh Tồn',
                            value: '22739',
                        },
                        {
                            lable: 'Xã Song Tử Tây',
                            value: '22737',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Kiên Giang',
            value: '91',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Rạch Giá',
                    value: '899',
                    wards: [
                        {
                            lable: 'Phường An Bình',
                            value: '30751',
                        },
                        {
                            lable: 'Phường An Hòa',
                            value: '30748',
                        },
                        {
                            lable: 'Phường Rạch Sỏi',
                            value: '30754',
                        },
                        {
                            lable: 'Phường Vĩnh Bảo',
                            value: '30742',
                        },
                        {
                            lable: 'Phường Vĩnh Hiệp',
                            value: '30739',
                        },
                        {
                            lable: 'Phường Vĩnh Lạc',
                            value: '30745',
                        },
                        {
                            lable: 'Phường Vĩnh Lợi',
                            value: '30757',
                        },
                        {
                            lable: 'Phường Vĩnh Quang',
                            value: '30736',
                        },
                        {
                            lable: 'Phường Vĩnh Thanh',
                            value: '30733',
                        },
                        {
                            lable: 'Phường Vĩnh Thanh Vân',
                            value: '30730',
                        },
                        {
                            lable: 'Phường Vĩnh Thông',
                            value: '30760',
                        },
                        {
                            lable: 'Xã Phi Thông',
                            value: '30763',
                        },
                    ],
                },
                {
                    lable: 'Thành phố Hà Tiên',
                    value: '900',
                    wards: [
                        {
                            lable: 'Phường Bình San',
                            value: '30772',
                        },
                        {
                            lable: 'Phường Mỹ Đức',
                            value: '30778',
                        },
                        {
                            lable: 'Phường Pháo Đài',
                            value: '30775',
                        },
                        {
                            lable: 'Phường Tô Châu',
                            value: '30766',
                        },
                        {
                            lable: 'Phường Đông Hồ',
                            value: '30769',
                        },
                        {
                            lable: 'Xã Thuận Yên',
                            value: '30784',
                        },
                        {
                            lable: 'Xã Tiên Hải',
                            value: '30781',
                        },
                    ],
                },
                {
                    lable: 'Huyện Kiên Lương',
                    value: '902',
                    wards: [
                        {
                            lable: 'Thị trấn Kiên Lương',
                            value: '30787',
                        },
                        {
                            lable: 'Xã Bình An',
                            value: '30808',
                        },
                        {
                            lable: 'Xã Bình Trị',
                            value: '30809',
                        },
                        {
                            lable: 'Xã Dương Hòa',
                            value: '30805',
                        },
                        {
                            lable: 'Xã Hòa Điền',
                            value: '30802',
                        },
                        {
                            lable: 'Xã Hòn Nghệ',
                            value: '30814',
                        },
                        {
                            lable: 'Xã Kiên Bình',
                            value: '30790',
                        },
                        {
                            lable: 'Xã Sơn Hải',
                            value: '30811',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hòn Đất',
                    value: '903',
                    wards: [
                        {
                            lable: 'Thị trấn Hòn Đất',
                            value: '30817',
                        },
                        {
                            lable: 'Thị trấn Sóc Sơn',
                            value: '30820',
                        },
                        {
                            lable: 'Xã Bình Giang',
                            value: '30826',
                        },
                        {
                            lable: 'Xã Bình Sơn',
                            value: '30823',
                        },
                        {
                            lable: 'Xã Lình Huỳnh',
                            value: '30840',
                        },
                        {
                            lable: 'Xã Mỹ Hiệp Sơn',
                            value: '30832',
                        },
                        {
                            lable: 'Xã Mỹ Lâm',
                            value: '30844',
                        },
                        {
                            lable: 'Xã Mỹ Phước',
                            value: '30847',
                        },
                        {
                            lable: 'Xã Mỹ Thuận',
                            value: '30838',
                        },
                        {
                            lable: 'Xã Mỹ Thái',
                            value: '30828',
                        },
                        {
                            lable: 'Xã Nam Thái Sơn',
                            value: '30829',
                        },
                        {
                            lable: 'Xã Sơn Bình',
                            value: '30836',
                        },
                        {
                            lable: 'Xã Sơn Kiên',
                            value: '30835',
                        },
                        {
                            lable: 'Xã Thổ Sơn',
                            value: '30841',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tân Hiệp',
                    value: '904',
                    wards: [
                        {
                            lable: 'Thị trấn Tân Hiệp',
                            value: '30850',
                        },
                        {
                            lable: 'Xã Thạnh Trị',
                            value: '30877',
                        },
                        {
                            lable: 'Xã Thạnh Đông',
                            value: '30865',
                        },
                        {
                            lable: 'Xã Thạnh Đông A',
                            value: '30874',
                        },
                        {
                            lable: 'Xã Thạnh Đông B',
                            value: '30862',
                        },
                        {
                            lable: 'Xã Tân An',
                            value: '30871',
                        },
                        {
                            lable: 'Xã Tân Hiệp A',
                            value: '30868',
                        },
                        {
                            lable: 'Xã Tân Hiệp B',
                            value: '30859',
                        },
                        {
                            lable: 'Xã Tân Hoà',
                            value: '30860',
                        },
                        {
                            lable: 'Xã Tân Hội',
                            value: '30853',
                        },
                        {
                            lable: 'Xã Tân Thành',
                            value: '30856',
                        },
                    ],
                },
                {
                    lable: 'Huyện Châu Thành',
                    value: '905',
                    wards: [
                        {
                            lable: 'Thị trấn Minh Lương',
                            value: '30880',
                        },
                        {
                            lable: 'Xã Bình An',
                            value: '30898',
                        },
                        {
                            lable: 'Xã Giục Tượng',
                            value: '30889',
                        },
                        {
                            lable: 'Xã Minh Hòa',
                            value: '30895',
                        },
                        {
                            lable: 'Xã Mong Thọ',
                            value: '30887',
                        },
                        {
                            lable: 'Xã Mong Thọ A',
                            value: '30883',
                        },
                        {
                            lable: 'Xã Mong Thọ B',
                            value: '30886',
                        },
                        {
                            lable: 'Xã Thạnh Lộc',
                            value: '30901',
                        },
                        {
                            lable: 'Xã Vĩnh Hoà Phú',
                            value: '30893',
                        },
                        {
                            lable: 'Xã Vĩnh Hòa Hiệp',
                            value: '30892',
                        },
                    ],
                },
                {
                    lable: 'Huyện Giồng Riềng',
                    value: '906',
                    wards: [
                        {
                            lable: 'Thị Trấn Giồng Riềng',
                            value: '30904',
                        },
                        {
                            lable: 'Xã  Hòa Thuận',
                            value: '30949',
                        },
                        {
                            lable: 'Xã Bàn Thạch',
                            value: '30919',
                        },
                        {
                            lable: 'Xã Bàn Tân Định',
                            value: '30922',
                        },
                        {
                            lable: 'Xã Hoà An',
                            value: '30940',
                        },
                        {
                            lable: 'Xã Hoà Lợi',
                            value: '30937',
                        },
                        {
                            lable: 'Xã Hòa Hưng',
                            value: '30934',
                        },
                        {
                            lable: 'Xã Long Thạnh',
                            value: '30943',
                        },
                        {
                            lable: 'Xã Ngọc Chúc',
                            value: '30928',
                        },
                        {
                            lable: 'Xã Ngọc Hoà',
                            value: '30950',
                        },
                        {
                            lable: 'Xã Ngọc Thuận',
                            value: '30931',
                        },
                        {
                            lable: 'Xã Ngọc Thành',
                            value: '30925',
                        },
                        {
                            lable: 'Xã Thạnh Bình',
                            value: '30917',
                        },
                        {
                            lable: 'Xã Thạnh Hòa',
                            value: '30916',
                        },
                        {
                            lable: 'Xã Thạnh Hưng',
                            value: '30907',
                        },
                        {
                            lable: 'Xã Thạnh Lộc',
                            value: '30913',
                        },
                        {
                            lable: 'Xã Thạnh Phước',
                            value: '30910',
                        },
                        {
                            lable: 'Xã Vĩnh Phú',
                            value: '30947',
                        },
                        {
                            lable: 'Xã Vĩnh Thạnh',
                            value: '30946',
                        },
                    ],
                },
                {
                    lable: 'Huyện Gò Quao',
                    value: '907',
                    wards: [
                        {
                            lable: 'Thị trấn Gò Quao',
                            value: '30952',
                        },
                        {
                            lable: 'Xã Thới Quản',
                            value: '30961',
                        },
                        {
                            lable: 'Xã Thủy Liễu',
                            value: '30967',
                        },
                        {
                            lable: 'Xã Vĩnh Hòa Hưng Bắc',
                            value: '30955',
                        },
                        {
                            lable: 'Xã Vĩnh Hòa Hưng Nam',
                            value: '30970',
                        },
                        {
                            lable: 'Xã Vĩnh Phước A',
                            value: '30973',
                        },
                        {
                            lable: 'Xã Vĩnh Phước B',
                            value: '30976',
                        },
                        {
                            lable: 'Xã Vĩnh Thắng',
                            value: '30982',
                        },
                        {
                            lable: 'Xã Vĩnh Tuy',
                            value: '30979',
                        },
                        {
                            lable: 'Xã Định An',
                            value: '30964',
                        },
                        {
                            lable: 'Xã Định Hòa',
                            value: '30958',
                        },
                    ],
                },
                {
                    lable: 'Huyện An Biên',
                    value: '908',
                    wards: [
                        {
                            lable: 'Thị trấn Thứ Ba',
                            value: '30985',
                        },
                        {
                            lable: 'Xã Hưng Yên',
                            value: '30997',
                        },
                        {
                            lable: 'Xã Nam Thái',
                            value: '31000',
                        },
                        {
                            lable: 'Xã Nam Thái A',
                            value: '31003',
                        },
                        {
                            lable: 'Xã Nam Yên',
                            value: '30994',
                        },
                        {
                            lable: 'Xã Tây Yên',
                            value: '30988',
                        },
                        {
                            lable: 'Xã Tây Yên A',
                            value: '30991',
                        },
                        {
                            lable: 'Xã Đông Thái',
                            value: '31006',
                        },
                        {
                            lable: 'Xã Đông Yên',
                            value: '31009',
                        },
                    ],
                },
                {
                    lable: 'Huyện An Minh',
                    value: '909',
                    wards: [
                        {
                            lable: 'Thị trấn Thứ Mười Một',
                            value: '31018',
                        },
                        {
                            lable: 'Xã Thuận Hoà',
                            value: '31021',
                        },
                        {
                            lable: 'Xã Tân Thạnh',
                            value: '31031',
                        },
                        {
                            lable: 'Xã Vân Khánh',
                            value: '31042',
                        },
                        {
                            lable: 'Xã Vân Khánh Tây',
                            value: '31048',
                        },
                        {
                            lable: 'Xã Vân Khánh Đông',
                            value: '31045',
                        },
                        {
                            lable: 'Xã Đông Hòa',
                            value: '31024',
                        },
                        {
                            lable: 'Xã Đông Hưng',
                            value: '31033',
                        },
                        {
                            lable: 'Xã Đông Hưng A',
                            value: '31036',
                        },
                        {
                            lable: 'Xã Đông Hưng B',
                            value: '31039',
                        },
                        {
                            lable: 'Xã Đông Thạnh',
                            value: '31030',
                        },
                    ],
                },
                {
                    lable: 'Huyện Vĩnh Thuận',
                    value: '910',
                    wards: [
                        {
                            lable: 'Thị trấn Vĩnh Thuận',
                            value: '31051',
                        },
                        {
                            lable: 'Xã Bình Minh',
                            value: '31064',
                        },
                        {
                            lable: 'Xã Phong Đông',
                            value: '31074',
                        },
                        {
                            lable: 'Xã Tân Thuận',
                            value: '31072',
                        },
                        {
                            lable: 'Xã Vĩnh Bình Bắc',
                            value: '31060',
                        },
                        {
                            lable: 'Xã Vĩnh Bình Nam',
                            value: '31063',
                        },
                        {
                            lable: 'Xã Vĩnh Phong',
                            value: '31075',
                        },
                        {
                            lable: 'Xã Vĩnh Thuận',
                            value: '31069',
                        },
                    ],
                },
                {
                    lable: 'Thành phố Phú Quốc',
                    value: '911',
                    wards: [
                        {
                            lable: 'Phường An Thới',
                            value: '31081',
                        },
                        {
                            lable: 'Phường Dương Đông',
                            value: '31078',
                        },
                        {
                            lable: 'Xã Bãi Thơm',
                            value: '31102',
                        },
                        {
                            lable: 'Xã Cửa Cạn',
                            value: '31084',
                        },
                        {
                            lable: 'Xã Cửa Dương',
                            value: '31090',
                        },
                        {
                            lable: 'Xã Dương Tơ',
                            value: '31096',
                        },
                        {
                            lable: 'Xã Gành Dầu',
                            value: '31087',
                        },
                        {
                            lable: 'Xã Hàm Ninh',
                            value: '31093',
                        },
                        {
                            lable: 'Xã Thổ Châu',
                            value: '31105',
                        },
                    ],
                },
                {
                    lable: 'Huyện Kiên Hải',
                    value: '912',
                    wards: [
                        {
                            lable: 'Xã An Sơn',
                            value: '31114',
                        },
                        {
                            lable: 'Xã Hòn Tre',
                            value: '31108',
                        },
                        {
                            lable: 'Xã Lại Sơn',
                            value: '31111',
                        },
                        {
                            lable: 'Xã Nam Du',
                            value: '31115',
                        },
                    ],
                },
                {
                    lable: 'Huyện U Minh Thượng',
                    value: '913',
                    wards: [
                        {
                            lable: 'Xã An Minh Bắc',
                            value: '31027',
                        },
                        {
                            lable: 'Xã Hoà Chánh',
                            value: '31057',
                        },
                        {
                            lable: 'Xã Minh Thuận',
                            value: '31066',
                        },
                        {
                            lable: 'Xã Thạnh Yên',
                            value: '31012',
                        },
                        {
                            lable: 'Xã Thạnh Yên A',
                            value: '31015',
                        },
                        {
                            lable: 'Xã Vĩnh Hòa',
                            value: '31054',
                        },
                    ],
                },
                {
                    lable: 'Huyện Giang Thành',
                    value: '914',
                    wards: [
                        {
                            lable: 'Xã Phú Lợi',
                            value: '30797',
                        },
                        {
                            lable: 'Xã Phú Mỹ',
                            value: '30799',
                        },
                        {
                            lable: 'Xã Tân Khánh Hòa',
                            value: '30796',
                        },
                        {
                            lable: 'Xã Vĩnh Phú',
                            value: '30791',
                        },
                        {
                            lable: 'Xã Vĩnh Điều',
                            value: '30793',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Kon Tum',
            value: '62',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Kon Tum',
                    value: '608',
                    wards: [
                        {
                            lable: 'Phường Duy Tân',
                            value: '23284',
                        },
                        {
                            lable: 'Phường Lê Lợi',
                            value: '23302',
                        },
                        {
                            lable: 'Phường Nguyễn Trãi',
                            value: '23305',
                        },
                        {
                            lable: 'Phường Ngô Mây',
                            value: '23296',
                        },
                        {
                            lable: 'Phường Quang Trung',
                            value: '23281',
                        },
                        {
                            lable: 'Phường Quyết Thắng',
                            value: '23287',
                        },
                        {
                            lable: 'Phường Thắng Lợi',
                            value: '23293',
                        },
                        {
                            lable: 'Phường Thống Nhất',
                            value: '23299',
                        },
                        {
                            lable: 'Phường Trường Chinh',
                            value: '23290',
                        },
                        {
                            lable: 'Phường Trần Hưng Đạo',
                            value: '23308',
                        },
                        {
                            lable: 'Xã Chư Hreng',
                            value: '23332',
                        },
                        {
                            lable: 'Xã Hòa Bình',
                            value: '23338',
                        },
                        {
                            lable: 'Xã Ia Chim',
                            value: '23326',
                        },
                        {
                            lable: 'Xã Kroong',
                            value: '23314',
                        },
                        {
                            lable: 'Xã Ngọk Bay',
                            value: '23317',
                        },
                        {
                            lable: 'Xã Vinh Quang',
                            value: '23320',
                        },
                        {
                            lable: 'Xã Đoàn Kết',
                            value: '23329',
                        },
                        {
                            lable: 'Xã Đăk Năng',
                            value: '23327',
                        },
                        {
                            lable: 'Xã Đắk Blà',
                            value: '23323',
                        },
                        {
                            lable: 'Xã Đắk Cấm',
                            value: '23311',
                        },
                        {
                            lable: 'Xã Đắk Rơ Wa',
                            value: '23335',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đắk Glei',
                    value: '610',
                    wards: [
                        {
                            lable: 'Thị trấn Đắk Glei',
                            value: '23341',
                        },
                        {
                            lable: 'Xã Mường Hoong',
                            value: '23362',
                        },
                        {
                            lable: 'Xã Ngọc Linh',
                            value: '23365',
                        },
                        {
                            lable: 'Xã Xốp',
                            value: '23359',
                        },
                        {
                            lable: 'Xã Đắk Blô',
                            value: '23344',
                        },
                        {
                            lable: 'Xã Đắk Choong',
                            value: '23356',
                        },
                        {
                            lable: 'Xã Đắk KRoong',
                            value: '23371',
                        },
                        {
                            lable: 'Xã Đắk Long',
                            value: '23368',
                        },
                        {
                            lable: 'Xã Đắk Man',
                            value: '23347',
                        },
                        {
                            lable: 'Xã Đắk Môn',
                            value: '23374',
                        },
                        {
                            lable: 'Xã Đắk Nhoong',
                            value: '23350',
                        },
                        {
                            lable: 'Xã Đắk Pék',
                            value: '23353',
                        },
                    ],
                },
                {
                    lable: 'Huyện Ngọc Hồi',
                    value: '611',
                    wards: [
                        {
                            lable: 'Thị trấn Plei Kần',
                            value: '23377',
                        },
                        {
                            lable: 'Xã Bờ Y',
                            value: '23395',
                        },
                        {
                            lable: 'Xã Sa Loong',
                            value: '23398',
                        },
                        {
                            lable: 'Xã Đắk Ang',
                            value: '23380',
                        },
                        {
                            lable: 'Xã Đắk Dục',
                            value: '23383',
                        },
                        {
                            lable: 'Xã Đắk Kan',
                            value: '23392',
                        },
                        {
                            lable: 'Xã Đắk Nông',
                            value: '23386',
                        },
                        {
                            lable: 'Xã Đắk Xú',
                            value: '23389',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đắk Tô',
                    value: '612',
                    wards: [
                        {
                            lable: 'Thị trấn Đắk Tô',
                            value: '23401',
                        },
                        {
                            lable: 'Xã Diên Bình',
                            value: '23440',
                        },
                        {
                            lable: 'Xã Kon Đào',
                            value: '23434',
                        },
                        {
                            lable: 'Xã Ngọk Tụ',
                            value: '23428',
                        },
                        {
                            lable: 'Xã Pô Kô',
                            value: '23443',
                        },
                        {
                            lable: 'Xã Tân Cảnh',
                            value: '23437',
                        },
                        {
                            lable: 'Xã Văn Lem',
                            value: '23431',
                        },
                        {
                            lable: 'Xã Đắk Rơ Nga',
                            value: '23427',
                        },
                        {
                            lable: 'Xã Đắk Trăm',
                            value: '23430',
                        },
                    ],
                },
                {
                    lable: 'Huyện Kon Plông',
                    value: '613',
                    wards: [
                        {
                            lable: 'Thị trấn Măng Đen',
                            value: '23473',
                        },
                        {
                            lable: 'Xã Hiếu',
                            value: '23476',
                        },
                        {
                            lable: 'Xã Măng Buk',
                            value: '23458',
                        },
                        {
                            lable: 'Xã Măng Cành',
                            value: '23470',
                        },
                        {
                            lable: 'Xã Ngok Tem',
                            value: '23464',
                        },
                        {
                            lable: 'Xã Pờ Ê',
                            value: '23467',
                        },
                        {
                            lable: 'Xã Đắk Nên',
                            value: '23452',
                        },
                        {
                            lable: 'Xã Đắk Ring',
                            value: '23455',
                        },
                        {
                            lable: 'Xã Đắk Tăng',
                            value: '23461',
                        },
                    ],
                },
                {
                    lable: 'Huyện Kon Rẫy',
                    value: '614',
                    wards: [
                        {
                            lable: 'Thị trấn Đắk Rve',
                            value: '23479',
                        },
                        {
                            lable: 'Xã Tân Lập',
                            value: '23497',
                        },
                        {
                            lable: 'Xã Đắk Kôi',
                            value: '23482',
                        },
                        {
                            lable: 'Xã Đắk Pne',
                            value: '23491',
                        },
                        {
                            lable: 'Xã Đắk Ruồng',
                            value: '23488',
                        },
                        {
                            lable: 'Xã Đắk Tơ Lung',
                            value: '23485',
                        },
                        {
                            lable: 'Xã Đắk Tờ Re',
                            value: '23494',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đắk Hà',
                    value: '615',
                    wards: [
                        {
                            lable: 'Thị trấn Đắk Hà',
                            value: '23500',
                        },
                        {
                            lable: 'Xã Hà Mòn',
                            value: '23521',
                        },
                        {
                            lable: 'Xã Ngok Réo',
                            value: '23518',
                        },
                        {
                            lable: 'Xã Ngok Wang',
                            value: '23515',
                        },
                        {
                            lable: 'Xã Đăk Long',
                            value: '23504',
                        },
                        {
                            lable: 'Xã Đăk Ngọk',
                            value: '23510',
                        },
                        {
                            lable: 'Xã Đắk HRing',
                            value: '23506',
                        },
                        {
                            lable: 'Xã Đắk La',
                            value: '23524',
                        },
                        {
                            lable: 'Xã Đắk Mar',
                            value: '23512',
                        },
                        {
                            lable: 'Xã Đắk PXi',
                            value: '23503',
                        },
                        {
                            lable: 'Xã Đắk Ui',
                            value: '23509',
                        },
                    ],
                },
                {
                    lable: 'Huyện Sa Thầy',
                    value: '616',
                    wards: [
                        {
                            lable: 'Thị trấn Sa Thầy',
                            value: '23527',
                        },
                        {
                            lable: 'Xã Hơ Moong',
                            value: '23534',
                        },
                        {
                            lable: 'Xã Mô Rai',
                            value: '23536',
                        },
                        {
                            lable: 'Xã Rơ Kơi',
                            value: '23530',
                        },
                        {
                            lable: 'Xã Sa Bình',
                            value: '23545',
                        },
                        {
                            lable: 'Xã Sa Nghĩa',
                            value: '23542',
                        },
                        {
                            lable: 'Xã Sa Nhơn',
                            value: '23533',
                        },
                        {
                            lable: 'Xã Sa Sơn',
                            value: '23539',
                        },
                        {
                            lable: 'Xã Ya Tăng',
                            value: '23551',
                        },
                        {
                            lable: 'Xã Ya Xiêr',
                            value: '23548',
                        },
                        {
                            lable: 'Xã Ya ly',
                            value: '23554',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tu Mơ Rông',
                    value: '617',
                    wards: [
                        {
                            lable: 'Xã Măng Ri',
                            value: '23410',
                        },
                        {
                            lable: 'Xã Ngọc Lây',
                            value: '23404',
                        },
                        {
                            lable: 'Xã Ngọc Yêu',
                            value: '23413',
                        },
                        {
                            lable: 'Xã Tu Mơ Rông',
                            value: '23422',
                        },
                        {
                            lable: 'Xã Tê Xăng',
                            value: '23446',
                        },
                        {
                            lable: 'Xã Văn Xuôi',
                            value: '23449',
                        },
                        {
                            lable: 'Xã Đắk Hà',
                            value: '23425',
                        },
                        {
                            lable: 'Xã Đắk Na',
                            value: '23407',
                        },
                        {
                            lable: 'Xã Đắk Rơ Ông',
                            value: '23417',
                        },
                        {
                            lable: 'Xã Đắk Sao',
                            value: '23416',
                        },
                        {
                            lable: 'Xã Đắk Tờ Kan',
                            value: '23419',
                        },
                    ],
                },
                {
                    lable: 'Huyện Ia H\' Drai',
                    value: '618',
                    wards: [
                        {
                            lable: 'Xã Ia Dom',
                            value: '23537',
                        },
                        {
                            lable: 'Xã Ia Tơi',
                            value: '23538',
                        },
                        {
                            lable: 'Xã Ia Đal',
                            value: '23535',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Lai Châu',
            value: '12',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Lai Châu',
                    value: '105',
                    wards: [
                        {
                            lable: 'Phường Quyết Thắng',
                            value: '03386',
                        },
                        {
                            lable: 'Phường Quyết Tiến',
                            value: '03388',
                        },
                        {
                            lable: 'Phường Tân Phong',
                            value: '03387',
                        },
                        {
                            lable: 'Phường Đoàn Kết',
                            value: '03389',
                        },
                        {
                            lable: 'Phường Đông Phong',
                            value: '03408',
                        },
                        {
                            lable: 'Xã San Thàng',
                            value: '03409',
                        },
                        {
                            lable: 'Xã Sùng Phài',
                            value: '03403',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tam Đường',
                    value: '106',
                    wards: [
                        {
                            lable: 'Thị trấn Tam Đường',
                            value: '03390',
                        },
                        {
                            lable: 'Xã Bình Lư',
                            value: '03412',
                        },
                        {
                            lable: 'Xã Bản Bo',
                            value: '03424',
                        },
                        {
                            lable: 'Xã Bản Giang',
                            value: '03418',
                        },
                        {
                            lable: 'Xã Bản Hon',
                            value: '03421',
                        },
                        {
                            lable: 'Xã Giang Ma',
                            value: '03405',
                        },
                        {
                            lable: 'Xã Hồ Thầu',
                            value: '03406',
                        },
                        {
                            lable: 'Xã Khun Há',
                            value: '03430',
                        },
                        {
                            lable: 'Xã Nà Tăm',
                            value: '03427',
                        },
                        {
                            lable: 'Xã Nùng Nàng',
                            value: '03415',
                        },
                        {
                            lable: 'Xã Sơn Bình',
                            value: '03413',
                        },
                        {
                            lable: 'Xã Thèn Sin',
                            value: '03394',
                        },
                        {
                            lable: 'Xã Tả Lèng',
                            value: '03400',
                        },
                    ],
                },
                {
                    lable: 'Huyện Mường Tè',
                    value: '107',
                    wards: [
                        {
                            lable: 'Thị trấn Mường Tè',
                            value: '03433',
                        },
                        {
                            lable: 'Xã Bum Nưa',
                            value: '03466',
                        },
                        {
                            lable: 'Xã Bum Tở',
                            value: '03454',
                        },
                        {
                            lable: 'Xã Ka Lăng',
                            value: '03439',
                        },
                        {
                            lable: 'Xã Kan Hồ',
                            value: '03469',
                        },
                        {
                            lable: 'Xã Mù Cả',
                            value: '03451',
                        },
                        {
                            lable: 'Xã Mường Tè',
                            value: '03445',
                        },
                        {
                            lable: 'Xã Nậm Khao',
                            value: '03457',
                        },
                        {
                            lable: 'Xã Pa Vệ Sử',
                            value: '03448',
                        },
                        {
                            lable: 'Xã Pa ủ',
                            value: '03442',
                        },
                        {
                            lable: 'Xã Thu Lũm',
                            value: '03436',
                        },
                        {
                            lable: 'Xã Tà Tổng',
                            value: '03463',
                        },
                        {
                            lable: 'Xã Tá Bạ',
                            value: '03440',
                        },
                        {
                            lable: 'Xã Vàng San',
                            value: '03467',
                        },
                    ],
                },
                {
                    lable: 'Huyện Sìn Hồ',
                    value: '108',
                    wards: [
                        {
                            lable: 'Thị trấn Sìn Hồ',
                            value: '03478',
                        },
                        {
                            lable: 'Xã Chăn Nưa',
                            value: '03487',
                        },
                        {
                            lable: 'Xã Căn Co',
                            value: '03538',
                        },
                        {
                            lable: 'Xã Hồng Thu',
                            value: '03499',
                        },
                        {
                            lable: 'Xã Làng Mô',
                            value: '03529',
                        },
                        {
                            lable: 'Xã Lùng Thàng',
                            value: '03509',
                        },
                        {
                            lable: 'Xã Ma Quai',
                            value: '03508',
                        },
                        {
                            lable: 'Xã Noong Hẻo',
                            value: '03532',
                        },
                        {
                            lable: 'Xã Nậm Cha',
                            value: '03526',
                        },
                        {
                            lable: 'Xã Nậm Cuổi',
                            value: '03544',
                        },
                        {
                            lable: 'Xã Nậm Hăn',
                            value: '03547',
                        },
                        {
                            lable: 'Xã Nậm Mạ',
                            value: '03535',
                        },
                        {
                            lable: 'Xã Nậm Tăm',
                            value: '03517',
                        },
                        {
                            lable: 'Xã Pa Khoá',
                            value: '03527',
                        },
                        {
                            lable: 'Xã Pa Tần',
                            value: '03493',
                        },
                        {
                            lable: 'Xã Phìn Hồ',
                            value: '03496',
                        },
                        {
                            lable: 'Xã Phăng Sô Lin',
                            value: '03505',
                        },
                        {
                            lable: 'Xã Pu Sam Cáp',
                            value: '03523',
                        },
                        {
                            lable: 'Xã Sà Dề Phìn',
                            value: '03514',
                        },
                        {
                            lable: 'Xã Tả Ngảo',
                            value: '03520',
                        },
                        {
                            lable: 'Xã Tả Phìn',
                            value: '03511',
                        },
                        {
                            lable: 'Xã Tủa Sín Chải',
                            value: '03541',
                        },
                    ],
                },
                {
                    lable: 'Huyện Phong Thổ',
                    value: '109',
                    wards: [
                        {
                            lable: 'Thị trấn Phong Thổ',
                            value: '03549',
                        },
                        {
                            lable: 'Xã Bản Lang',
                            value: '03577',
                        },
                        {
                            lable: 'Xã Dào San',
                            value: '03571',
                        },
                        {
                            lable: 'Xã Hoang Thèn',
                            value: '03580',
                        },
                        {
                            lable: 'Xã Huổi Luông',
                            value: '03490',
                        },
                        {
                            lable: 'Xã Khổng Lào',
                            value: '03583',
                        },
                        {
                            lable: 'Xã Lả Nhì Thàng',
                            value: '03391',
                        },
                        {
                            lable: 'Xã Ma Ly Pho',
                            value: '03574',
                        },
                        {
                            lable: 'Xã Mù Sang',
                            value: '03568',
                        },
                        {
                            lable: 'Xã Mường So',
                            value: '03589',
                        },
                        {
                            lable: 'Xã Mồ Sì San',
                            value: '03553',
                        },
                        {
                            lable: 'Xã Nậm Xe',
                            value: '03586',
                        },
                        {
                            lable: 'Xã Pa Vây Sử',
                            value: '03559',
                        },
                        {
                            lable: 'Xã Sin Suối Hồ',
                            value: '03592',
                        },
                        {
                            lable: 'Xã Sì Lở Lầu',
                            value: '03550',
                        },
                        {
                            lable: 'Xã Tông Qua Lìn',
                            value: '03565',
                        },
                        {
                            lable: 'Xã Vàng Ma Chải',
                            value: '03562',
                        },
                    ],
                },
                {
                    lable: 'Huyện Than Uyên',
                    value: '110',
                    wards: [
                        {
                            lable: 'Thị trấn Than Uyên',
                            value: '03595',
                        },
                        {
                            lable: 'Xã Hua Nà',
                            value: '03632',
                        },
                        {
                            lable: 'Xã Khoen On',
                            value: '03643',
                        },
                        {
                            lable: 'Xã Mường Cang',
                            value: '03631',
                        },
                        {
                            lable: 'Xã Mường Kim',
                            value: '03637',
                        },
                        {
                            lable: 'Xã Mường Mít',
                            value: '03625',
                        },
                        {
                            lable: 'Xã Mường Than',
                            value: '03619',
                        },
                        {
                            lable: 'Xã Pha Mu',
                            value: '03628',
                        },
                        {
                            lable: 'Xã Phúc Than',
                            value: '03618',
                        },
                        {
                            lable: 'Xã Tà Gia',
                            value: '03640',
                        },
                        {
                            lable: 'Xã Tà Hừa',
                            value: '03634',
                        },
                        {
                            lable: 'Xã Tà Mung',
                            value: '03638',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tân Uyên',
                    value: '111',
                    wards: [
                        {
                            lable: 'Thị trấn Tân Uyên',
                            value: '03598',
                        },
                        {
                            lable: 'Xã Hố Mít',
                            value: '03607',
                        },
                        {
                            lable: 'Xã Mường Khoa',
                            value: '03601',
                        },
                        {
                            lable: 'Xã Nậm Cần',
                            value: '03610',
                        },
                        {
                            lable: 'Xã Nậm Sỏ',
                            value: '03613',
                        },
                        {
                            lable: 'Xã Phúc Khoa',
                            value: '03602',
                        },
                        {
                            lable: 'Xã Pắc Ta',
                            value: '03616',
                        },
                        {
                            lable: 'Xã Thân Thuộc',
                            value: '03604',
                        },
                        {
                            lable: 'Xã Trung Đồng',
                            value: '03605',
                        },
                        {
                            lable: 'Xã Tà Mít',
                            value: '03622',
                        },
                    ],
                },
                {
                    lable: 'Huyện Nậm Nhùn',
                    value: '112',
                    wards: [
                        {
                            lable: 'Thị trấn Nậm Nhùn',
                            value: '03434',
                        },
                        {
                            lable: 'Xã Hua Bun',
                            value: '03460',
                        },
                        {
                            lable: 'Xã Lê Lợi',
                            value: '03481',
                        },
                        {
                            lable: 'Xã Mường Mô',
                            value: '03472',
                        },
                        {
                            lable: 'Xã Nậm Ban',
                            value: '03502',
                        },
                        {
                            lable: 'Xã Nậm Chà',
                            value: '03473',
                        },
                        {
                            lable: 'Xã Nậm Hàng',
                            value: '03475',
                        },
                        {
                            lable: 'Xã Nậm Manh',
                            value: '03474',
                        },
                        {
                            lable: 'Xã Nậm Pì',
                            value: '03488',
                        },
                        {
                            lable: 'Xã Pú Đao',
                            value: '03484',
                        },
                        {
                            lable: 'Xã Trung Chải',
                            value: '03503',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Lâm Đồng',
            value: '68',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Đà Lạt',
                    value: '672',
                    wards: [
                        {
                            lable: 'Phường 1',
                            value: '24784',
                        },
                        {
                            lable: 'Phường 10',
                            value: '24796',
                        },
                        {
                            lable: 'Phường 11',
                            value: '24799',
                        },
                        {
                            lable: 'Phường 12',
                            value: '24775',
                        },
                        {
                            lable: 'Phường 2',
                            value: '24781',
                        },
                        {
                            lable: 'Phường 3',
                            value: '24802',
                        },
                        {
                            lable: 'Phường 4',
                            value: '24793',
                        },
                        {
                            lable: 'Phường 5',
                            value: '24790',
                        },
                        {
                            lable: 'Phường 6',
                            value: '24787',
                        },
                        {
                            lable: 'Phường 7',
                            value: '24769',
                        },
                        {
                            lable: 'Phường 8',
                            value: '24772',
                        },
                        {
                            lable: 'Phường 9',
                            value: '24778',
                        },
                        {
                            lable: 'Xã Trạm Hành',
                            value: '24810',
                        },
                        {
                            lable: 'Xã Tà Nung',
                            value: '24808',
                        },
                        {
                            lable: 'Xã Xuân Thọ',
                            value: '24805',
                        },
                        {
                            lable: 'Xã Xuân Trường',
                            value: '24811',
                        },
                    ],
                },
                {
                    lable: 'Thành phố Bảo Lộc',
                    value: '673',
                    wards: [
                        {
                            lable: 'Phường 1',
                            value: '24823',
                        },
                        {
                            lable: 'Phường 2',
                            value: '24820',
                        },
                        {
                            lable: 'Phường B\'lao',
                            value: '24826',
                        },
                        {
                            lable: 'Phường Lộc Phát',
                            value: '24814',
                        },
                        {
                            lable: 'Phường Lộc Sơn',
                            value: '24829',
                        },
                        {
                            lable: 'Phường Lộc Tiến',
                            value: '24817',
                        },
                        {
                            lable: 'Xã Lộc Châu',
                            value: '24841',
                        },
                        {
                            lable: 'Xã Lộc Nga',
                            value: '24838',
                        },
                        {
                            lable: 'Xã Lộc Thanh',
                            value: '24835',
                        },
                        {
                            lable: 'Xã Đại Lào',
                            value: '24844',
                        },
                        {
                            lable: 'Xã Đạm Bri',
                            value: '24832',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đam Rông',
                    value: '674',
                    wards: [
                        {
                            lable: 'Xã Liêng Srônh',
                            value: '24874',
                        },
                        {
                            lable: 'Xã Phi Liêng',
                            value: '24886',
                        },
                        {
                            lable: 'Xã Rô Men',
                            value: '24877',
                        },
                        {
                            lable: 'Xã Đạ K\' Nàng',
                            value: '24889',
                        },
                        {
                            lable: 'Xã Đạ Long',
                            value: '24856',
                        },
                        {
                            lable: 'Xã Đạ M\' Rong',
                            value: '24859',
                        },
                        {
                            lable: 'Xã Đạ Rsal',
                            value: '24875',
                        },
                        {
                            lable: 'Xã Đạ Tông',
                            value: '24853',
                        },
                    ],
                },
                {
                    lable: 'Huyện Lạc Dương',
                    value: '675',
                    wards: [
                        {
                            lable: 'Thị trấn Lạc Dương',
                            value: '24846',
                        },
                        {
                            lable: 'Xã Lát',
                            value: '24862',
                        },
                        {
                            lable: 'Xã Đưng KNớ',
                            value: '24850',
                        },
                        {
                            lable: 'Xã Đạ Chais',
                            value: '24847',
                        },
                        {
                            lable: 'Xã Đạ Nhim',
                            value: '24848',
                        },
                        {
                            lable: 'Xã Đạ Sar',
                            value: '24865',
                        },
                    ],
                },
                {
                    lable: 'Huyện Lâm Hà',
                    value: '676',
                    wards: [
                        {
                            lable: 'Thị trấn Nam Ban',
                            value: '24868',
                        },
                        {
                            lable: 'Thị trấn Đinh Văn',
                            value: '24871',
                        },
                        {
                            lable: 'Xã Gia Lâm',
                            value: '24904',
                        },
                        {
                            lable: 'Xã Hoài Đức',
                            value: '24913',
                        },
                        {
                            lable: 'Xã Liên Hà',
                            value: '24919',
                        },
                        {
                            lable: 'Xã Mê Linh',
                            value: '24892',
                        },
                        {
                            lable: 'Xã Nam Hà',
                            value: '24925',
                        },
                        {
                            lable: 'Xã Phi Tô',
                            value: '24883',
                        },
                        {
                            lable: 'Xã Phú Sơn',
                            value: '24880',
                        },
                        {
                            lable: 'Xã Phúc Thọ',
                            value: '24898',
                        },
                        {
                            lable: 'Xã Tân Hà',
                            value: '24916',
                        },
                        {
                            lable: 'Xã Tân Thanh',
                            value: '24907',
                        },
                        {
                            lable: 'Xã Tân Văn',
                            value: '24910',
                        },
                        {
                            lable: 'Xã Đan Phượng',
                            value: '24922',
                        },
                        {
                            lable: 'Xã Đông Thanh',
                            value: '24901',
                        },
                        {
                            lable: 'Xã Đạ Đờn',
                            value: '24895',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đơn Dương',
                    value: '677',
                    wards: [
                        {
                            lable: 'Thị trấn D\'Ran',
                            value: '24928',
                        },
                        {
                            lable: 'Thị trấn Thạnh Mỹ',
                            value: '24931',
                        },
                        {
                            lable: 'Xã Ka Đô',
                            value: '24943',
                        },
                        {
                            lable: 'Xã Ka Đơn',
                            value: '24949',
                        },
                        {
                            lable: 'Xã Lạc Lâm',
                            value: '24940',
                        },
                        {
                            lable: 'Xã Lạc Xuân',
                            value: '24934',
                        },
                        {
                            lable: 'Xã Pró',
                            value: '24955',
                        },
                        {
                            lable: 'Xã Quảng Lập',
                            value: '24946',
                        },
                        {
                            lable: 'Xã Tu Tra',
                            value: '24952',
                        },
                        {
                            lable: 'Xã Đạ Ròn',
                            value: '24937',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đức Trọng',
                    value: '678',
                    wards: [
                        {
                            lable: 'Thị trấn Liên Nghĩa',
                            value: '24958',
                        },
                        {
                            lable: 'Xã Bình Thạnh',
                            value: '24970',
                        },
                        {
                            lable: 'Xã Hiệp An',
                            value: '24961',
                        },
                        {
                            lable: 'Xã Hiệp Thạnh',
                            value: '24967',
                        },
                        {
                            lable: 'Xã Liên Hiệp',
                            value: '24964',
                        },
                        {
                            lable: 'Xã N\'Thol Hạ',
                            value: '24973',
                        },
                        {
                            lable: 'Xã Ninh Gia',
                            value: '24985',
                        },
                        {
                            lable: 'Xã Ninh Loan',
                            value: '24997',
                        },
                        {
                            lable: 'Xã Phú Hội',
                            value: '24982',
                        },
                        {
                            lable: 'Xã Tà Hine',
                            value: '24991',
                        },
                        {
                            lable: 'Xã Tà Năng',
                            value: '24988',
                        },
                        {
                            lable: 'Xã Tân Hội',
                            value: '24976',
                        },
                        {
                            lable: 'Xã Tân Thành',
                            value: '24979',
                        },
                        {
                            lable: 'Xã Đa Quyn',
                            value: '24989',
                        },
                        {
                            lable: 'Xã Đà Loan',
                            value: '24994',
                        },
                    ],
                },
                {
                    lable: 'Huyện Di Linh',
                    value: '679',
                    wards: [
                        {
                            lable: 'Thị trấn Di Linh',
                            value: '25000',
                        },
                        {
                            lable: 'Xã Bảo Thuận',
                            value: '25033',
                        },
                        {
                            lable: 'Xã Gia Bắc',
                            value: '25051',
                        },
                        {
                            lable: 'Xã Gia Hiệp',
                            value: '25015',
                        },
                        {
                            lable: 'Xã Gung Ré',
                            value: '25030',
                        },
                        {
                            lable: 'Xã Hòa Bắc',
                            value: '25045',
                        },
                        {
                            lable: 'Xã Hòa Nam',
                            value: '25042',
                        },
                        {
                            lable: 'Xã Hòa Ninh',
                            value: '25036',
                        },
                        {
                            lable: 'Xã Hòa Trung',
                            value: '25039',
                        },
                        {
                            lable: 'Xã Liên Đầm',
                            value: '25027',
                        },
                        {
                            lable: 'Xã Sơn Điền',
                            value: '25048',
                        },
                        {
                            lable: 'Xã Tam Bố',
                            value: '25021',
                        },
                        {
                            lable: 'Xã Tân Châu',
                            value: '25009',
                        },
                        {
                            lable: 'Xã Tân Lâm',
                            value: '25007',
                        },
                        {
                            lable: 'Xã Tân Nghĩa',
                            value: '25012',
                        },
                        {
                            lable: 'Xã Tân Thượng',
                            value: '25006',
                        },
                        {
                            lable: 'Xã Đinh Lạc',
                            value: '25018',
                        },
                        {
                            lable: 'Xã Đinh Trang Hòa',
                            value: '25024',
                        },
                        {
                            lable: 'Xã Đinh Trang Thượng',
                            value: '25003',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bảo Lâm',
                    value: '680',
                    wards: [
                        {
                            lable: 'Thị trấn Lộc Thắng',
                            value: '25054',
                        },
                        {
                            lable: 'Xã B\' Lá',
                            value: '25069',
                        },
                        {
                            lable: 'Xã Lộc An',
                            value: '25084',
                        },
                        {
                            lable: 'Xã Lộc Bảo',
                            value: '25057',
                        },
                        {
                            lable: 'Xã Lộc Bắc',
                            value: '25066',
                        },
                        {
                            lable: 'Xã Lộc Lâm',
                            value: '25060',
                        },
                        {
                            lable: 'Xã Lộc Nam',
                            value: '25093',
                        },
                        {
                            lable: 'Xã Lộc Ngãi',
                            value: '25072',
                        },
                        {
                            lable: 'Xã Lộc Phú',
                            value: '25063',
                        },
                        {
                            lable: 'Xã Lộc Quảng',
                            value: '25075',
                        },
                        {
                            lable: 'Xã Lộc Thành',
                            value: '25090',
                        },
                        {
                            lable: 'Xã Lộc Tân',
                            value: '25078',
                        },
                        {
                            lable: 'Xã Lộc Đức',
                            value: '25081',
                        },
                        {
                            lable: 'Xã Tân Lạc',
                            value: '25087',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đạ Huoai',
                    value: '681',
                    wards: [
                        {
                            lable: 'Thị trấn Ma Đa Guôi',
                            value: '25099',
                        },
                        {
                            lable: 'Thị trấn Đạ M\'ri',
                            value: '25096',
                        },
                        {
                            lable: 'Xã Hà Lâm',
                            value: '25105',
                        },
                        {
                            lable: 'Xã Ma Đa Guôi',
                            value: '25117',
                        },
                        {
                            lable: 'Xã Phước Lộc',
                            value: '25123',
                        },
                        {
                            lable: 'Xã Đoàn Kết',
                            value: '25120',
                        },
                        {
                            lable: 'Xã Đạ Oai',
                            value: '25111',
                        },
                        {
                            lable: 'Xã Đạ Ploa',
                            value: '25114',
                        },
                        {
                            lable: 'Xã Đạ Tồn',
                            value: '25108',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đạ Tẻh',
                    value: '682',
                    wards: [
                        {
                            lable: 'Thị trấn Đạ Tẻh',
                            value: '25126',
                        },
                        {
                            lable: 'Xã An Nhơn',
                            value: '25129',
                        },
                        {
                            lable: 'Xã Mỹ Đức',
                            value: '25135',
                        },
                        {
                            lable: 'Xã Quảng Trị',
                            value: '25138',
                        },
                        {
                            lable: 'Xã Quốc Oai',
                            value: '25132',
                        },
                        {
                            lable: 'Xã Triệu Hải',
                            value: '25147',
                        },
                        {
                            lable: 'Xã Đạ Kho',
                            value: '25153',
                        },
                        {
                            lable: 'Xã Đạ Lây',
                            value: '25141',
                        },
                        {
                            lable: 'Xã Đạ Pal',
                            value: '25156',
                        },
                    ],
                },
                {
                    lable: 'Huyện Cát Tiên',
                    value: '683',
                    wards: [
                        {
                            lable: 'Thị trấn Cát Tiên',
                            value: '25159',
                        },
                        {
                            lable: 'Thị trấn Phước Cát ',
                            value: '25180',
                        },
                        {
                            lable: 'Xã Gia Viễn',
                            value: '25168',
                        },
                        {
                            lable: 'Xã Nam Ninh',
                            value: '25171',
                        },
                        {
                            lable: 'Xã Phước Cát 2',
                            value: '25165',
                        },
                        {
                            lable: 'Xã Quảng Ngãi',
                            value: '25189',
                        },
                        {
                            lable: 'Xã Tiên Hoàng',
                            value: '25162',
                        },
                        {
                            lable: 'Xã Đồng Nai Thượng',
                            value: '25192',
                        },
                        {
                            lable: 'Xã Đức Phổ',
                            value: '25183',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Lạng Sơn',
            value: '20',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Lạng Sơn',
                    value: '178',
                    wards: [
                        {
                            lable: 'Phường Chi Lăng',
                            value: '05983',
                        },
                        {
                            lable: 'Phường Hoàng Văn Thụ',
                            value: '05971',
                        },
                        {
                            lable: 'Phường Tam Thanh',
                            value: '05974',
                        },
                        {
                            lable: 'Phường Vĩnh Trại',
                            value: '05977',
                        },
                        {
                            lable: 'Phường Đông Kinh',
                            value: '05980',
                        },
                        {
                            lable: 'Xã Hoàng Đồng',
                            value: '05986',
                        },
                        {
                            lable: 'Xã Mai Pha',
                            value: '05992',
                        },
                        {
                            lable: 'Xã Quảng Lạc',
                            value: '05989',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tràng Định',
                    value: '180',
                    wards: [
                        {
                            lable: 'Thị trấn Thất Khê',
                            value: '05995',
                        },
                        {
                            lable: 'Xã Cao Minh',
                            value: '06010',
                        },
                        {
                            lable: 'Xã Chi Lăng',
                            value: '06034',
                        },
                        {
                            lable: 'Xã Chí Minh',
                            value: '06013',
                        },
                        {
                            lable: 'Xã Hùng Sơn',
                            value: '06055',
                        },
                        {
                            lable: 'Xã Hùng Việt',
                            value: '06061',
                        },
                        {
                            lable: 'Xã Kháng Chiến',
                            value: '06049',
                        },
                        {
                            lable: 'Xã Khánh Long',
                            value: '05998',
                        },
                        {
                            lable: 'Xã Kim Đồng',
                            value: '06031',
                        },
                        {
                            lable: 'Xã Quốc Khánh',
                            value: '06004',
                        },
                        {
                            lable: 'Xã Quốc Việt',
                            value: '06058',
                        },
                        {
                            lable: 'Xã Tri Phương',
                            value: '06016',
                        },
                        {
                            lable: 'Xã Trung Thành',
                            value: '06037',
                        },
                        {
                            lable: 'Xã Tân Minh',
                            value: '06028',
                        },
                        {
                            lable: 'Xã Tân Tiến',
                            value: '06019',
                        },
                        {
                            lable: 'Xã Tân Yên',
                            value: '06022',
                        },
                        {
                            lable: 'Xã Vĩnh Tiến',
                            value: '06007',
                        },
                        {
                            lable: 'Xã Đoàn Kết',
                            value: '06001',
                        },
                        {
                            lable: 'Xã Đào Viên',
                            value: '06043',
                        },
                        {
                            lable: 'Xã Đại Đồng',
                            value: '06040',
                        },
                        {
                            lable: 'Xã Đề Thám',
                            value: '06046',
                        },
                        {
                            lable: 'Xã Đội Cấn',
                            value: '06025',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bình Gia',
                    value: '181',
                    wards: [
                        {
                            lable: 'Thị trấn Bình Gia',
                            value: '06112',
                        },
                        {
                            lable: 'Xã Bình La',
                            value: '06118',
                        },
                        {
                            lable: 'Xã Hoa Thám',
                            value: '06073',
                        },
                        {
                            lable: 'Xã Hoàng Văn Thụ',
                            value: '06100',
                        },
                        {
                            lable: 'Xã Hòa Bình',
                            value: '06103',
                        },
                        {
                            lable: 'Xã Hưng Đạo',
                            value: '06067',
                        },
                        {
                            lable: 'Xã Hồng Phong',
                            value: '06079',
                        },
                        {
                            lable: 'Xã Hồng Thái',
                            value: '06115',
                        },
                        {
                            lable: 'Xã Minh Khai',
                            value: '06094',
                        },
                        {
                            lable: 'Xã Mông Ân',
                            value: '06106',
                        },
                        {
                            lable: 'Xã Quang Trung',
                            value: '06088',
                        },
                        {
                            lable: 'Xã Quý Hòa',
                            value: '06076',
                        },
                        {
                            lable: 'Xã Thiện Hòa',
                            value: '06085',
                        },
                        {
                            lable: 'Xã Thiện Long',
                            value: '06097',
                        },
                        {
                            lable: 'Xã Thiện Thuật',
                            value: '06091',
                        },
                        {
                            lable: 'Xã Tân Hòa',
                            value: '06109',
                        },
                        {
                            lable: 'Xã Tân Văn',
                            value: '06121',
                        },
                        {
                            lable: 'Xã Vĩnh Yên',
                            value: '06070',
                        },
                        {
                            lable: 'Xã Yên Lỗ',
                            value: '06082',
                        },
                    ],
                },
                {
                    lable: 'Huyện Văn Lãng',
                    value: '182',
                    wards: [
                        {
                            lable: 'Thị trấn Na Sầm',
                            value: '06124',
                        },
                        {
                            lable: 'Xã  Hoàng Văn Thụ',
                            value: '06178',
                        },
                        {
                            lable: 'Xã Bắc Hùng',
                            value: '06139',
                        },
                        {
                            lable: 'Xã Bắc La',
                            value: '06133',
                        },
                        {
                            lable: 'Xã Bắc Việt',
                            value: '06154',
                        },
                        {
                            lable: 'Xã Gia Miễn',
                            value: '06160',
                        },
                        {
                            lable: 'Xã Hoàng Việt',
                            value: '06157',
                        },
                        {
                            lable: 'Xã Hồng Thái',
                            value: '06175',
                        },
                        {
                            lable: 'Xã Hội Hoan',
                            value: '06151',
                        },
                        {
                            lable: 'Xã Nhạc Kỳ',
                            value: '06181',
                        },
                        {
                            lable: 'Xã Thanh Long',
                            value: '06148',
                        },
                        {
                            lable: 'Xã Thành Hòa',
                            value: '06163',
                        },
                        {
                            lable: 'Xã Thụy Hùng',
                            value: '06136',
                        },
                        {
                            lable: 'Xã Trùng Khánh',
                            value: '06127',
                        },
                        {
                            lable: 'Xã Tân Mỹ',
                            value: '06172',
                        },
                        {
                            lable: 'Xã Tân Thanh',
                            value: '06166',
                        },
                        {
                            lable: 'Xã Tân Tác',
                            value: '06142',
                        },
                    ],
                },
                {
                    lable: 'Huyện Cao Lộc',
                    value: '183',
                    wards: [
                        {
                            lable: 'Thị trấn Cao Lộc',
                            value: '06187',
                        },
                        {
                            lable: 'Thị trấn Đồng Đăng',
                            value: '06184',
                        },
                        {
                            lable: 'Xã Bình Trung',
                            value: '06217',
                        },
                        {
                            lable: 'Xã Bảo Lâm',
                            value: '06190',
                        },
                        {
                            lable: 'Xã Cao Lâu',
                            value: '06196',
                        },
                        {
                            lable: 'Xã Công Sơn',
                            value: '06232',
                        },
                        {
                            lable: 'Xã Gia Cát',
                            value: '06235',
                        },
                        {
                            lable: 'Xã Hòa Cư',
                            value: '06223',
                        },
                        {
                            lable: 'Xã Hải Yến',
                            value: '06220',
                        },
                        {
                            lable: 'Xã Hồng Phong',
                            value: '06205',
                        },
                        {
                            lable: 'Xã Hợp Thành',
                            value: '06226',
                        },
                        {
                            lable: 'Xã Lộc Yên',
                            value: '06211',
                        },
                        {
                            lable: 'Xã Mẫu Sơn',
                            value: '06238',
                        },
                        {
                            lable: 'Xã Phú Xá',
                            value: '06214',
                        },
                        {
                            lable: 'Xã Thanh Lòa',
                            value: '06193',
                        },
                        {
                            lable: 'Xã Thạch Đạn',
                            value: '06199',
                        },
                        {
                            lable: 'Xã Thụy Hùng',
                            value: '06208',
                        },
                        {
                            lable: 'Xã Tân Liên',
                            value: '06244',
                        },
                        {
                            lable: 'Xã Tân Thành',
                            value: '06250',
                        },
                        {
                            lable: 'Xã Xuân Long',
                            value: '06241',
                        },
                        {
                            lable: 'Xã Xuất Lễ',
                            value: '06202',
                        },
                        {
                            lable: 'Xã Yên Trạch',
                            value: '06247',
                        },
                    ],
                },
                {
                    lable: 'Huyện Văn Quan',
                    value: '184',
                    wards: [
                        {
                            lable: 'Thị trấn Văn Quan',
                            value: '06253',
                        },
                        {
                            lable: 'Xã An Sơn',
                            value: '06283',
                        },
                        {
                            lable: 'Xã Bình Phúc',
                            value: '06298',
                        },
                        {
                            lable: 'Xã Hòa Bình',
                            value: '06274',
                        },
                        {
                            lable: 'Xã Hữu Lễ',
                            value: '06322',
                        },
                        {
                            lable: 'Xã Khánh Khê',
                            value: '06286',
                        },
                        {
                            lable: 'Xã Liên Hội',
                            value: '06268',
                        },
                        {
                            lable: 'Xã Lương Năng',
                            value: '06292',
                        },
                        {
                            lable: 'Xã Tri Lễ',
                            value: '06313',
                        },
                        {
                            lable: 'Xã Tràng Các',
                            value: '06301',
                        },
                        {
                            lable: 'Xã Tràng Phái',
                            value: '06316',
                        },
                        {
                            lable: 'Xã Trấn Ninh',
                            value: '06256',
                        },
                        {
                            lable: 'Xã Tân Đoàn',
                            value: '06307',
                        },
                        {
                            lable: 'Xã Tú Xuyên',
                            value: '06277',
                        },
                        {
                            lable: 'Xã Yên Phúc',
                            value: '06319',
                        },
                        {
                            lable: 'Xã Điềm He',
                            value: '06280',
                        },
                        {
                            lable: 'Xã Đồng Giáp',
                            value: '06295',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bắc Sơn',
                    value: '185',
                    wards: [
                        {
                            lable: 'Thị trấn Bắc Sơn',
                            value: '06325',
                        },
                        {
                            lable: 'Xã Bắc Quỳnh',
                            value: '06343',
                        },
                        {
                            lable: 'Xã Chiêu Vũ',
                            value: '06358',
                        },
                        {
                            lable: 'Xã Chiến Thắng',
                            value: '06364',
                        },
                        {
                            lable: 'Xã Hưng Vũ',
                            value: '06349',
                        },
                        {
                            lable: 'Xã Long Đống',
                            value: '06328',
                        },
                        {
                            lable: 'Xã Nhất Hòa',
                            value: '06376',
                        },
                        {
                            lable: 'Xã Nhất Tiến',
                            value: '06382',
                        },
                        {
                            lable: 'Xã Trấn Yên',
                            value: '06370',
                        },
                        {
                            lable: 'Xã Tân Hương',
                            value: '06361',
                        },
                        {
                            lable: 'Xã Tân Lập',
                            value: '06352',
                        },
                        {
                            lable: 'Xã Tân Thành',
                            value: '06379',
                        },
                        {
                            lable: 'Xã Tân Tri',
                            value: '06340',
                        },
                        {
                            lable: 'Xã Vũ Lăng',
                            value: '06367',
                        },
                        {
                            lable: 'Xã Vũ Lễ',
                            value: '06373',
                        },
                        {
                            lable: 'Xã Vũ Sơn',
                            value: '06355',
                        },
                        {
                            lable: 'Xã Vạn Thủy',
                            value: '06331',
                        },
                        {
                            lable: 'Xã Đồng ý',
                            value: '06337',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hữu Lũng',
                    value: '186',
                    wards: [
                        {
                            lable: 'Thị trấn Hữu Lũng',
                            value: '06385',
                        },
                        {
                            lable: 'Xã Cai Kinh',
                            value: '06427',
                        },
                        {
                            lable: 'Xã Hòa Bình',
                            value: '06397',
                        },
                        {
                            lable: 'Xã Hòa Lạc',
                            value: '06430',
                        },
                        {
                            lable: 'Xã Hòa Sơn',
                            value: '06445',
                        },
                        {
                            lable: 'Xã Hòa Thắng',
                            value: '06460',
                        },
                        {
                            lable: 'Xã Hồ Sơn',
                            value: '06451',
                        },
                        {
                            lable: 'Xã Hữu Liên',
                            value: '06388',
                        },
                        {
                            lable: 'Xã Minh Hòa',
                            value: '06457',
                        },
                        {
                            lable: 'Xã Minh Sơn',
                            value: '06448',
                        },
                        {
                            lable: 'Xã Minh Tiến',
                            value: '06415',
                        },
                        {
                            lable: 'Xã Nhật Tiến',
                            value: '06418',
                        },
                        {
                            lable: 'Xã Quyết Thắng',
                            value: '06394',
                        },
                        {
                            lable: 'Xã Sơn Hà',
                            value: '06454',
                        },
                        {
                            lable: 'Xã Thanh Sơn',
                            value: '06421',
                        },
                        {
                            lable: 'Xã Thiện Tân',
                            value: '06406',
                        },
                        {
                            lable: 'Xã Tân Thành',
                            value: '06442',
                        },
                        {
                            lable: 'Xã Vân Nham',
                            value: '06433',
                        },
                        {
                            lable: 'Xã Yên Bình',
                            value: '06391',
                        },
                        {
                            lable: 'Xã Yên Sơn',
                            value: '06403',
                        },
                        {
                            lable: 'Xã Yên Thịnh',
                            value: '06400',
                        },
                        {
                            lable: 'Xã Yên Vượng',
                            value: '06412',
                        },
                        {
                            lable: 'Xã Đồng Tiến',
                            value: '06436',
                        },
                        {
                            lable: 'Xã Đồng Tân',
                            value: '06424',
                        },
                    ],
                },
                {
                    lable: 'Huyện Chi Lăng',
                    value: '187',
                    wards: [
                        {
                            lable: 'Thị trấn Chi Lăng',
                            value: '06466',
                        },
                        {
                            lable: 'Thị trấn Đồng Mỏ',
                            value: '06463',
                        },
                        {
                            lable: 'Xã Bắc Thủy',
                            value: '06478',
                        },
                        {
                            lable: 'Xã Bằng Hữu',
                            value: '06487',
                        },
                        {
                            lable: 'Xã Bằng Mạc',
                            value: '06493',
                        },
                        {
                            lable: 'Xã Chi Lăng',
                            value: '06523',
                        },
                        {
                            lable: 'Xã Chiến Thắng',
                            value: '06481',
                        },
                        {
                            lable: 'Xã Gia Lộc',
                            value: '06475',
                        },
                        {
                            lable: 'Xã Hòa Bình',
                            value: '06508',
                        },
                        {
                            lable: 'Xã Hữu Kiên',
                            value: '06514',
                        },
                        {
                            lable: 'Xã Liên Sơn',
                            value: '06502',
                        },
                        {
                            lable: 'Xã Lâm Sơn',
                            value: '06499',
                        },
                        {
                            lable: 'Xã Mai Sao',
                            value: '06484',
                        },
                        {
                            lable: 'Xã Nhân Lý',
                            value: '06496',
                        },
                        {
                            lable: 'Xã Quan Sơn',
                            value: '06517',
                        },
                        {
                            lable: 'Xã Thượng Cường',
                            value: '06490',
                        },
                        {
                            lable: 'Xã Vân An',
                            value: '06469',
                        },
                        {
                            lable: 'Xã Vân Thủy',
                            value: '06472',
                        },
                        {
                            lable: 'Xã Vạn Linh',
                            value: '06505',
                        },
                        {
                            lable: 'Xã Y Tịch',
                            value: '06520',
                        },
                    ],
                },
                {
                    lable: 'Huyện Lộc Bình',
                    value: '188',
                    wards: [
                        {
                            lable: 'Thị trấn Lộc Bình',
                            value: '06529',
                        },
                        {
                            lable: 'Thị trấn Na Dương',
                            value: '06526',
                        },
                        {
                            lable: 'Xã Hữu Khánh',
                            value: '06550',
                        },
                        {
                            lable: 'Xã Hữu Lân',
                            value: '06598',
                        },
                        {
                            lable: 'Xã Khuất Xá',
                            value: '06565',
                        },
                        {
                            lable: 'Xã Khánh Xuân',
                            value: '06544',
                        },
                        {
                            lable: 'Xã Lợi Bác',
                            value: '06601',
                        },
                        {
                            lable: 'Xã Minh Hiệp',
                            value: '06595',
                        },
                        {
                            lable: 'Xã Mẫu Sơn',
                            value: '06532',
                        },
                        {
                            lable: 'Xã Nam Quan',
                            value: '06604',
                        },
                        {
                            lable: 'Xã Sàn Viên',
                            value: '06589',
                        },
                        {
                            lable: 'Xã Tam Gia',
                            value: '06559',
                        },
                        {
                            lable: 'Xã Thống Nhất',
                            value: '06577',
                        },
                        {
                            lable: 'Xã Tú Mịch',
                            value: '06547',
                        },
                        {
                            lable: 'Xã Tú Đoạn',
                            value: '06562',
                        },
                        {
                            lable: 'Xã Tĩnh Bắc',
                            value: '06574',
                        },
                        {
                            lable: 'Xã Xuân Dương',
                            value: '06607',
                        },
                        {
                            lable: 'Xã Yên Khoái',
                            value: '06541',
                        },
                        {
                            lable: 'Xã Ái Quốc',
                            value: '06610',
                        },
                        {
                            lable: 'Xã Đông Quan',
                            value: '06592',
                        },
                        {
                            lable: 'Xã Đồng Bục',
                            value: '06553',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đình Lập',
                    value: '189',
                    wards: [
                        {
                            lable: 'Thị trấn NT Thái Bình',
                            value: '06616',
                        },
                        {
                            lable: 'Thị trấn Đình Lập',
                            value: '06613',
                        },
                        {
                            lable: 'Xã Bính Xá',
                            value: '06622',
                        },
                        {
                            lable: 'Xã Bắc Lãng',
                            value: '06646',
                        },
                        {
                            lable: 'Xã Bắc Xa',
                            value: '06619',
                        },
                        {
                            lable: 'Xã Châu Sơn',
                            value: '06637',
                        },
                        {
                            lable: 'Xã Cường Lợi',
                            value: '06634',
                        },
                        {
                            lable: 'Xã Kiên Mộc',
                            value: '06625',
                        },
                        {
                            lable: 'Xã Lâm Ca',
                            value: '06640',
                        },
                        {
                            lable: 'Xã Thái Bình',
                            value: '06631',
                        },
                        {
                            lable: 'Xã Đình Lập',
                            value: '06628',
                        },
                        {
                            lable: 'Xã Đồng Thắng',
                            value: '06643',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Lào Cai',
            value: '10',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Lào Cai',
                    value: '080',
                    wards: [
                        {
                            lable: 'Phường Bình Minh',
                            value: '02658',
                        },
                        {
                            lable: 'Phường Bắc Cường',
                            value: '02668',
                        },
                        {
                            lable: 'Phường Bắc Lệnh',
                            value: '02650',
                        },
                        {
                            lable: 'Phường Cốc Lếu',
                            value: '02644',
                        },
                        {
                            lable: 'Phường Duyên Hải',
                            value: '02635',
                        },
                        {
                            lable: 'Phường Kim Tân',
                            value: '02647',
                        },
                        {
                            lable: 'Phường Lào Cai',
                            value: '02641',
                        },
                        {
                            lable: 'Phường Nam Cường',
                            value: '02671',
                        },
                        {
                            lable: 'Phường Pom Hán',
                            value: '02653',
                        },
                        {
                            lable: 'Phường Xuân Tăng',
                            value: '02656',
                        },
                        {
                            lable: 'Xã Cam Đường',
                            value: '02674',
                        },
                        {
                            lable: 'Xã Cốc San',
                            value: '02746',
                        },
                        {
                            lable: 'Xã Hợp Thành',
                            value: '02680',
                        },
                        {
                            lable: 'Xã Thống Nhất',
                            value: '02659',
                        },
                        {
                            lable: 'Xã Tả Phời',
                            value: '02677',
                        },
                        {
                            lable: 'Xã Vạn Hoà',
                            value: '02665',
                        },
                        {
                            lable: 'Xã Đồng Tuyển',
                            value: '02662',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bát Xát',
                    value: '082',
                    wards: [
                        {
                            lable: 'Thị trấn Bát Xát',
                            value: '02683',
                        },
                        {
                            lable: 'Xã A Lù',
                            value: '02692',
                        },
                        {
                            lable: 'Xã A Mú Sung',
                            value: '02686',
                        },
                        {
                            lable: 'Xã Bản Qua',
                            value: '02716',
                        },
                        {
                            lable: 'Xã Bản Vược',
                            value: '02710',
                        },
                        {
                            lable: 'Xã Bản Xèo',
                            value: '02725',
                        },
                        {
                            lable: 'Xã Cốc Mỳ',
                            value: '02704',
                        },
                        {
                            lable: 'Xã Dền Sáng',
                            value: '02707',
                        },
                        {
                            lable: 'Xã Dền Thàng',
                            value: '02722',
                        },
                        {
                            lable: 'Xã Mường Hum',
                            value: '02728',
                        },
                        {
                            lable: 'Xã Mường Vi',
                            value: '02719',
                        },
                        {
                            lable: 'Xã Nậm Chạc',
                            value: '02689',
                        },
                        {
                            lable: 'Xã Nậm Pung',
                            value: '02740',
                        },
                        {
                            lable: 'Xã Pa Cheo',
                            value: '02737',
                        },
                        {
                            lable: 'Xã Phìn Ngan',
                            value: '02743',
                        },
                        {
                            lable: 'Xã Quang Kim',
                            value: '02734',
                        },
                        {
                            lable: 'Xã Sàng Ma Sáo',
                            value: '02713',
                        },
                        {
                            lable: 'Xã Trung Lèng Hồ',
                            value: '02731',
                        },
                        {
                            lable: 'Xã Trịnh Tường',
                            value: '02695',
                        },
                        {
                            lable: 'Xã Tòng Sành',
                            value: '02749',
                        },
                        {
                            lable: 'Xã Y Tý',
                            value: '02701',
                        },
                    ],
                },
                {
                    lable: 'Huyện Mường Khương',
                    value: '083',
                    wards: [
                        {
                            lable: 'Thị trấn Mường Khương',
                            value: '02761',
                        },
                        {
                            lable: 'Xã Bản Lầu',
                            value: '02788',
                        },
                        {
                            lable: 'Xã Bản Sen',
                            value: '02797',
                        },
                        {
                            lable: 'Xã Cao Sơn',
                            value: '02782',
                        },
                        {
                            lable: 'Xã Dìn Chin',
                            value: '02764',
                        },
                        {
                            lable: 'Xã La Pan Tẩn',
                            value: '02791',
                        },
                        {
                            lable: 'Xã Lùng Khấu Nhin',
                            value: '02776',
                        },
                        {
                            lable: 'Xã Lùng Vai',
                            value: '02785',
                        },
                        {
                            lable: 'Xã Nấm Lư',
                            value: '02773',
                        },
                        {
                            lable: 'Xã Nậm Chảy',
                            value: '02770',
                        },
                        {
                            lable: 'Xã Pha Long',
                            value: '02752',
                        },
                        {
                            lable: 'Xã Thanh Bình',
                            value: '02779',
                        },
                        {
                            lable: 'Xã Tung Chung Phố',
                            value: '02758',
                        },
                        {
                            lable: 'Xã Tả Gia Khâu',
                            value: '02767',
                        },
                        {
                            lable: 'Xã Tả Ngải Chồ',
                            value: '02755',
                        },
                        {
                            lable: 'Xã Tả Thàng',
                            value: '02794',
                        },
                    ],
                },
                {
                    lable: 'Huyện Si Ma Cai',
                    value: '084',
                    wards: [
                        {
                            lable: 'Thị trấn Si Ma Cai',
                            value: '02809',
                        },
                        {
                            lable: 'Xã Bản Mế',
                            value: '02806',
                        },
                        {
                            lable: 'Xã Cán Cấu',
                            value: '02821',
                        },
                        {
                            lable: 'Xã Lùng Thẩn',
                            value: '02818',
                        },
                        {
                            lable: 'Xã Nàn Sán',
                            value: '02800',
                        },
                        {
                            lable: 'Xã Nàn Xín',
                            value: '02836',
                        },
                        {
                            lable: 'Xã Quan Hồ Thẩn',
                            value: '02827',
                        },
                        {
                            lable: 'Xã Sán Chải',
                            value: '02812',
                        },
                        {
                            lable: 'Xã Sín Chéng',
                            value: '02824',
                        },
                        {
                            lable: 'Xã Thào Chư Phìn',
                            value: '02803',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bắc Hà',
                    value: '085',
                    wards: [
                        {
                            lable: 'Thị trấn Bắc Hà',
                            value: '02839',
                        },
                        {
                            lable: 'Xã Bản Cái',
                            value: '02899',
                        },
                        {
                            lable: 'Xã Bản Liền',
                            value: '02869',
                        },
                        {
                            lable: 'Xã Bản Phố',
                            value: '02866',
                        },
                        {
                            lable: 'Xã Bảo Nhai',
                            value: '02890',
                        },
                        {
                            lable: 'Xã Cốc Ly',
                            value: '02878',
                        },
                        {
                            lable: 'Xã Cốc Lầu',
                            value: '02896',
                        },
                        {
                            lable: 'Xã Hoàng Thu Phố',
                            value: '02863',
                        },
                        {
                            lable: 'Xã Lùng Cải',
                            value: '02842',
                        },
                        {
                            lable: 'Xã Lùng Phình',
                            value: '02848',
                        },
                        {
                            lable: 'Xã Na Hối',
                            value: '02875',
                        },
                        {
                            lable: 'Xã Nậm Khánh',
                            value: '02887',
                        },
                        {
                            lable: 'Xã Nậm Lúc',
                            value: '02893',
                        },
                        {
                            lable: 'Xã Nậm Mòn',
                            value: '02881',
                        },
                        {
                            lable: 'Xã Nậm Đét',
                            value: '02884',
                        },
                        {
                            lable: 'Xã Thải Giàng Phố',
                            value: '02857',
                        },
                        {
                            lable: 'Xã Tà Chải',
                            value: '02872',
                        },
                        {
                            lable: 'Xã Tả Củ Tỷ',
                            value: '02854',
                        },
                        {
                            lable: 'Xã Tả Van Chư',
                            value: '02851',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bảo Thắng',
                    value: '086',
                    wards: [
                        {
                            lable: 'Thị trấn N.T Phong Hải',
                            value: '02902',
                        },
                        {
                            lable: 'Thị trấn Phố Lu',
                            value: '02905',
                        },
                        {
                            lable: 'Thị trấn Tằng Loỏng',
                            value: '02908',
                        },
                        {
                            lable: 'Xã Bản Cầm',
                            value: '02914',
                        },
                        {
                            lable: 'Xã Bản Phiệt',
                            value: '02911',
                        },
                        {
                            lable: 'Xã Gia Phú',
                            value: '02923',
                        },
                        {
                            lable: 'Xã Phong Niên',
                            value: '02920',
                        },
                        {
                            lable: 'Xã Phú Nhuận',
                            value: '02944',
                        },
                        {
                            lable: 'Xã Sơn Hà',
                            value: '02938',
                        },
                        {
                            lable: 'Xã Sơn Hải',
                            value: '02929',
                        },
                        {
                            lable: 'Xã Thái Niên',
                            value: '02917',
                        },
                        {
                            lable: 'Xã Trì Quang',
                            value: '02935',
                        },
                        {
                            lable: 'Xã Xuân Giao',
                            value: '02932',
                        },
                        {
                            lable: 'Xã Xuân Quang',
                            value: '02926',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bảo Yên',
                    value: '087',
                    wards: [
                        {
                            lable: 'Thị trấn Phố Ràng',
                            value: '02947',
                        },
                        {
                            lable: 'Xã Bảo Hà',
                            value: '02989',
                        },
                        {
                            lable: 'Xã Cam Cọn',
                            value: '02974',
                        },
                        {
                            lable: 'Xã Kim Sơn',
                            value: '02971',
                        },
                        {
                            lable: 'Xã Lương Sơn',
                            value: '02992',
                        },
                        {
                            lable: 'Xã Minh Tân',
                            value: '02977',
                        },
                        {
                            lable: 'Xã Nghĩa Đô',
                            value: '02953',
                        },
                        {
                            lable: 'Xã Phúc Khánh',
                            value: '02998',
                        },
                        {
                            lable: 'Xã Thượng Hà',
                            value: '02968',
                        },
                        {
                            lable: 'Xã Tân Dương',
                            value: '02965',
                        },
                        {
                            lable: 'Xã Tân Tiến',
                            value: '02950',
                        },
                        {
                            lable: 'Xã Việt Tiến',
                            value: '02983',
                        },
                        {
                            lable: 'Xã Vĩnh Yên',
                            value: '02956',
                        },
                        {
                            lable: 'Xã Xuân Hoà',
                            value: '02962',
                        },
                        {
                            lable: 'Xã Xuân Thượng',
                            value: '02980',
                        },
                        {
                            lable: 'Xã Yên Sơn',
                            value: '02986',
                        },
                        {
                            lable: 'Xã Điện Quan',
                            value: '02959',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Sa Pa',
                    value: '088',
                    wards: [
                        {
                            lable: 'Phường Cầu Mây',
                            value: '03028',
                        },
                        {
                            lable: 'Phường Hàm Rồng',
                            value: '03016',
                        },
                        {
                            lable: 'Phường Phan Si Păng',
                            value: '03006',
                        },
                        {
                            lable: 'Phường Sa Pa',
                            value: '03001',
                        },
                        {
                            lable: 'Phường Sa Pả',
                            value: '03002',
                        },
                        {
                            lable: 'Phường Ô Quý Hồ',
                            value: '03003',
                        },
                        {
                            lable: 'Xã Bản Hồ',
                            value: '03046',
                        },
                        {
                            lable: 'Xã Hoàng Liên',
                            value: '03019',
                        },
                        {
                            lable: 'Xã Liên Minh',
                            value: '03052',
                        },
                        {
                            lable: 'Xã Mường Bo',
                            value: '03043',
                        },
                        {
                            lable: 'Xã Mường Hoa',
                            value: '03037',
                        },
                        {
                            lable: 'Xã Ngũ Chỉ Sơn',
                            value: '03004',
                        },
                        {
                            lable: 'Xã Thanh Bình',
                            value: '03022',
                        },
                        {
                            lable: 'Xã Trung Chải',
                            value: '03010',
                        },
                        {
                            lable: 'Xã Tả Phìn',
                            value: '03013',
                        },
                        {
                            lable: 'Xã Tả Van',
                            value: '03040',
                        },
                    ],
                },
                {
                    lable: 'Huyện Văn Bàn',
                    value: '089',
                    wards: [
                        {
                            lable: 'Thị trấn Khánh Yên',
                            value: '03055',
                        },
                        {
                            lable: 'Xã Chiềng Ken',
                            value: '03091',
                        },
                        {
                            lable: 'Xã Dương Quỳ',
                            value: '03106',
                        },
                        {
                            lable: 'Xã Dần Thàng',
                            value: '03088',
                        },
                        {
                            lable: 'Xã Hoà Mạc',
                            value: '03097',
                        },
                        {
                            lable: 'Xã Khánh Yên Hạ',
                            value: '03103',
                        },
                        {
                            lable: 'Xã Khánh Yên Thượng',
                            value: '03082',
                        },
                        {
                            lable: 'Xã Khánh Yên Trung',
                            value: '03100',
                        },
                        {
                            lable: 'Xã Liêm Phú',
                            value: '03118',
                        },
                        {
                            lable: 'Xã Làng Giàng',
                            value: '03094',
                        },
                        {
                            lable: 'Xã Minh Lương',
                            value: '03112',
                        },
                        {
                            lable: 'Xã Nậm Chầy',
                            value: '03076',
                        },
                        {
                            lable: 'Xã Nậm Mả',
                            value: '03067',
                        },
                        {
                            lable: 'Xã Nậm Rạng',
                            value: '03073',
                        },
                        {
                            lable: 'Xã Nậm Tha',
                            value: '03109',
                        },
                        {
                            lable: 'Xã Nậm Xây',
                            value: '03121',
                        },
                        {
                            lable: 'Xã Nậm Xé',
                            value: '03085',
                        },
                        {
                            lable: 'Xã Sơn Thuỷ',
                            value: '03064',
                        },
                        {
                            lable: 'Xã Thẩm Dương',
                            value: '03115',
                        },
                        {
                            lable: 'Xã Tân An',
                            value: '03079',
                        },
                        {
                            lable: 'Xã Tân Thượng',
                            value: '03070',
                        },
                        {
                            lable: 'Xã Võ Lao',
                            value: '03061',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Long An',
            value: '80',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Tân An',
                    value: '794',
                    wards: [
                        {
                            lable: 'Phường 1',
                            value: '27694',
                        },
                        {
                            lable: 'Phường 2',
                            value: '27688',
                        },
                        {
                            lable: 'Phường 3',
                            value: '27697',
                        },
                        {
                            lable: 'Phường 4',
                            value: '27691',
                        },
                        {
                            lable: 'Phường 5',
                            value: '27685',
                        },
                        {
                            lable: 'Phường 6',
                            value: '27700',
                        },
                        {
                            lable: 'Phường 7',
                            value: '27698',
                        },
                        {
                            lable: 'Phường Khánh Hậu',
                            value: '27715',
                        },
                        {
                            lable: 'Phường Tân Khánh',
                            value: '27692',
                        },
                        {
                            lable: 'Xã An Vĩnh Ngãi',
                            value: '27718',
                        },
                        {
                            lable: 'Xã Bình Tâm',
                            value: '27712',
                        },
                        {
                            lable: 'Xã Hướng Thọ Phú',
                            value: '27703',
                        },
                        {
                            lable: 'Xã Lợi Bình Nhơn',
                            value: '27709',
                        },
                        {
                            lable: 'Xã Nhơn Thạnh Trung',
                            value: '27706',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Kiến Tường',
                    value: '795',
                    wards: [
                        {
                            lable: 'Phường 1',
                            value: '27787',
                        },
                        {
                            lable: 'Phường 2',
                            value: '27788',
                        },
                        {
                            lable: 'Phường 3',
                            value: '27806',
                        },
                        {
                            lable: 'Xã Bình Hiệp',
                            value: '27793',
                        },
                        {
                            lable: 'Xã Bình Tân',
                            value: '27799',
                        },
                        {
                            lable: 'Xã Thạnh Hưng',
                            value: '27817',
                        },
                        {
                            lable: 'Xã Thạnh Trị',
                            value: '27790',
                        },
                        {
                            lable: 'Xã Tuyên Thạnh',
                            value: '27805',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tân Hưng',
                    value: '796',
                    wards: [
                        {
                            lable: 'Thị trấn Tân Hưng',
                            value: '27721',
                        },
                        {
                            lable: 'Xã Hưng Hà',
                            value: '27724',
                        },
                        {
                            lable: 'Xã Hưng Thạnh',
                            value: '27736',
                        },
                        {
                            lable: 'Xã Hưng Điền',
                            value: '27730',
                        },
                        {
                            lable: 'Xã Hưng Điền B',
                            value: '27727',
                        },
                        {
                            lable: 'Xã Thạnh Hưng',
                            value: '27733',
                        },
                        {
                            lable: 'Xã Vĩnh Bửu',
                            value: '27754',
                        },
                        {
                            lable: 'Xã Vĩnh Châu A',
                            value: '27751',
                        },
                        {
                            lable: 'Xã Vĩnh Châu B',
                            value: '27742',
                        },
                        {
                            lable: 'Xã Vĩnh Lợi',
                            value: '27745',
                        },
                        {
                            lable: 'Xã Vĩnh Thạnh',
                            value: '27739',
                        },
                        {
                            lable: 'Xã Vĩnh Đại',
                            value: '27748',
                        },
                    ],
                },
                {
                    lable: 'Huyện Vĩnh Hưng',
                    value: '797',
                    wards: [
                        {
                            lable: 'Thị trấn Vĩnh Hưng',
                            value: '27757',
                        },
                        {
                            lable: 'Xã Hưng Điền A',
                            value: '27760',
                        },
                        {
                            lable: 'Xã Khánh Hưng',
                            value: '27763',
                        },
                        {
                            lable: 'Xã Thái Bình Trung',
                            value: '27772',
                        },
                        {
                            lable: 'Xã Thái Trị',
                            value: '27766',
                        },
                        {
                            lable: 'Xã Tuyên Bình',
                            value: '27781',
                        },
                        {
                            lable: 'Xã Tuyên Bình Tây',
                            value: '27784',
                        },
                        {
                            lable: 'Xã Vĩnh Bình',
                            value: '27775',
                        },
                        {
                            lable: 'Xã Vĩnh Thuận',
                            value: '27778',
                        },
                        {
                            lable: 'Xã Vĩnh Trị',
                            value: '27769',
                        },
                    ],
                },
                {
                    lable: 'Huyện Mộc Hóa',
                    value: '798',
                    wards: [
                        {
                            lable: 'Thị trấn Bình Phong Thạnh',
                            value: '27814',
                        },
                        {
                            lable: 'Xã Bình Hòa Trung',
                            value: '27808',
                        },
                        {
                            lable: 'Xã Bình Hòa Tây',
                            value: '27796',
                        },
                        {
                            lable: 'Xã Bình Hòa Đông',
                            value: '27811',
                        },
                        {
                            lable: 'Xã Bình Thạnh',
                            value: '27802',
                        },
                        {
                            lable: 'Xã Tân Lập',
                            value: '27820',
                        },
                        {
                            lable: 'Xã Tân Thành',
                            value: '27823',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tân Thạnh',
                    value: '799',
                    wards: [
                        {
                            lable: 'Thị trấn Tân Thạnh',
                            value: '27826',
                        },
                        {
                            lable: 'Xã Bắc Hòa',
                            value: '27829',
                        },
                        {
                            lable: 'Xã Hậu Thạnh Tây',
                            value: '27832',
                        },
                        {
                            lable: 'Xã Hậu Thạnh Đông',
                            value: '27841',
                        },
                        {
                            lable: 'Xã Kiến Bình',
                            value: '27847',
                        },
                        {
                            lable: 'Xã Nhơn Hoà',
                            value: '27844',
                        },
                        {
                            lable: 'Xã Nhơn Hòa Lập',
                            value: '27835',
                        },
                        {
                            lable: 'Xã Nhơn Ninh',
                            value: '27859',
                        },
                        {
                            lable: 'Xã Tân Bình',
                            value: '27853',
                        },
                        {
                            lable: 'Xã Tân Hòa',
                            value: '27862',
                        },
                        {
                            lable: 'Xã Tân Lập',
                            value: '27838',
                        },
                        {
                            lable: 'Xã Tân Ninh',
                            value: '27856',
                        },
                        {
                            lable: 'Xã Tân Thành',
                            value: '27850',
                        },
                    ],
                },
                {
                    lable: 'Huyện Thạnh Hóa',
                    value: '800',
                    wards: [
                        {
                            lable: 'Thị trấn Thạnh Hóa',
                            value: '27865',
                        },
                        {
                            lable: 'Xã Thuận Bình',
                            value: '27871',
                        },
                        {
                            lable: 'Xã Thuận Nghĩa Hòa',
                            value: '27880',
                        },
                        {
                            lable: 'Xã Thạnh An',
                            value: '27895',
                        },
                        {
                            lable: 'Xã Thạnh Phú',
                            value: '27877',
                        },
                        {
                            lable: 'Xã Thạnh Phước',
                            value: '27874',
                        },
                        {
                            lable: 'Xã Thủy Tây',
                            value: '27886',
                        },
                        {
                            lable: 'Xã Thủy Đông',
                            value: '27883',
                        },
                        {
                            lable: 'Xã Tân Hiệp',
                            value: '27868',
                        },
                        {
                            lable: 'Xã Tân Tây',
                            value: '27889',
                        },
                        {
                            lable: 'Xã Tân Đông',
                            value: '27892',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đức Huệ',
                    value: '801',
                    wards: [
                        {
                            lable: 'Thị trấn Đông Thành',
                            value: '27898',
                        },
                        {
                            lable: 'Xã Bình Hòa Bắc',
                            value: '27919',
                        },
                        {
                            lable: 'Xã Bình Hòa Hưng',
                            value: '27922',
                        },
                        {
                            lable: 'Xã Bình Hòa Nam',
                            value: '27925',
                        },
                        {
                            lable: 'Xã Bình Thành',
                            value: '27916',
                        },
                        {
                            lable: 'Xã Mỹ Bình',
                            value: '27928',
                        },
                        {
                            lable: 'Xã Mỹ Quý Tây',
                            value: '27907',
                        },
                        {
                            lable: 'Xã Mỹ Quý Đông',
                            value: '27901',
                        },
                        {
                            lable: 'Xã Mỹ Thạnh Bắc',
                            value: '27904',
                        },
                        {
                            lable: 'Xã Mỹ Thạnh Tây',
                            value: '27910',
                        },
                        {
                            lable: 'Xã Mỹ Thạnh Đông',
                            value: '27913',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đức Hòa',
                    value: '802',
                    wards: [
                        {
                            lable: 'Thị trấn Hiệp Hòa',
                            value: '27934',
                        },
                        {
                            lable: 'Thị trấn Hậu Nghĩa',
                            value: '27931',
                        },
                        {
                            lable: 'Thị trấn Đức Hòa',
                            value: '27937',
                        },
                        {
                            lable: 'Xã An Ninh Tây',
                            value: '27946',
                        },
                        {
                            lable: 'Xã An Ninh Đông',
                            value: '27943',
                        },
                        {
                            lable: 'Xã Hiệp Hòa',
                            value: '27952',
                        },
                        {
                            lable: 'Xã Hòa Khánh Nam',
                            value: '27979',
                        },
                        {
                            lable: 'Xã Hòa Khánh Tây',
                            value: '27970',
                        },
                        {
                            lable: 'Xã Hòa Khánh Đông',
                            value: '27973',
                        },
                        {
                            lable: 'Xã Hựu Thạnh',
                            value: '27988',
                        },
                        {
                            lable: 'Xã Lộc Giang',
                            value: '27940',
                        },
                        {
                            lable: 'Xã Mỹ Hạnh Bắc',
                            value: '27964',
                        },
                        {
                            lable: 'Xã Mỹ Hạnh Nam',
                            value: '27976',
                        },
                        {
                            lable: 'Xã Tân Mỹ',
                            value: '27949',
                        },
                        {
                            lable: 'Xã Tân Phú',
                            value: '27961',
                        },
                        {
                            lable: 'Xã Đức Hòa Hạ',
                            value: '27985',
                        },
                        {
                            lable: 'Xã Đức Hòa Thượng',
                            value: '27967',
                        },
                        {
                            lable: 'Xã Đức Hòa Đông',
                            value: '27982',
                        },
                        {
                            lable: 'Xã Đức Lập Hạ',
                            value: '27958',
                        },
                        {
                            lable: 'Xã Đức Lập Thượng',
                            value: '27955',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bến Lức',
                    value: '803',
                    wards: [
                        {
                            lable: 'Thị trấn Bến Lức',
                            value: '27991',
                        },
                        {
                            lable: 'Xã An Thạnh',
                            value: '28012',
                        },
                        {
                            lable: 'Xã Bình Đức',
                            value: '28015',
                        },
                        {
                            lable: 'Xã Long Hiệp',
                            value: '28024',
                        },
                        {
                            lable: 'Xã Lương Bình',
                            value: '27997',
                        },
                        {
                            lable: 'Xã Lương Hòa',
                            value: '28003',
                        },
                        {
                            lable: 'Xã Mỹ Yên',
                            value: '28018',
                        },
                        {
                            lable: 'Xã Nhựt Chánh',
                            value: '28033',
                        },
                        {
                            lable: 'Xã Phước Lợi',
                            value: '28030',
                        },
                        {
                            lable: 'Xã Thanh Phú',
                            value: '28021',
                        },
                        {
                            lable: 'Xã Thạnh Hòa',
                            value: '28000',
                        },
                        {
                            lable: 'Xã Thạnh Lợi',
                            value: '27994',
                        },
                        {
                            lable: 'Xã Thạnh Đức',
                            value: '28027',
                        },
                        {
                            lable: 'Xã Tân Bửu',
                            value: '28009',
                        },
                        {
                            lable: 'Xã Tân Hòa',
                            value: '28006',
                        },
                    ],
                },
                {
                    lable: 'Huyện Thủ Thừa',
                    value: '804',
                    wards: [
                        {
                            lable: 'Thị trấn Thủ Thừa',
                            value: '28036',
                        },
                        {
                            lable: 'Xã Bình An',
                            value: '28054',
                        },
                        {
                            lable: 'Xã Bình Thạnh',
                            value: '28063',
                        },
                        {
                            lable: 'Xã Long Thuận',
                            value: '28045',
                        },
                        {
                            lable: 'Xã Long Thạnh',
                            value: '28039',
                        },
                        {
                            lable: 'Xã Mỹ An',
                            value: '28060',
                        },
                        {
                            lable: 'Xã Mỹ Lạc',
                            value: '28048',
                        },
                        {
                            lable: 'Xã Mỹ Phú',
                            value: '28066',
                        },
                        {
                            lable: 'Xã Mỹ Thạnh',
                            value: '28051',
                        },
                        {
                            lable: 'Xã Nhị Thành',
                            value: '28057',
                        },
                        {
                            lable: 'Xã Tân Long',
                            value: '28072',
                        },
                        {
                            lable: 'Xã Tân Thành',
                            value: '28042',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tân Trụ',
                    value: '805',
                    wards: [
                        {
                            lable: 'Thị trấn Tân Trụ',
                            value: '28075',
                        },
                        {
                            lable: 'Xã Bình Lãng',
                            value: '28096',
                        },
                        {
                            lable: 'Xã Bình Trinh Đông',
                            value: '28090',
                        },
                        {
                            lable: 'Xã Bình Tịnh',
                            value: '28099',
                        },
                        {
                            lable: 'Xã Lạc Tấn',
                            value: '28087',
                        },
                        {
                            lable: 'Xã Nhựt Ninh',
                            value: '28105',
                        },
                        {
                            lable: 'Xã Quê Mỹ Thạnh',
                            value: '28084',
                        },
                        {
                            lable: 'Xã Tân Bình',
                            value: '28078',
                        },
                        {
                            lable: 'Xã Tân Phước Tây',
                            value: '28093',
                        },
                        {
                            lable: 'Xã Đức Tân',
                            value: '28102',
                        },
                    ],
                },
                {
                    lable: 'Huyện Cần Đước',
                    value: '806',
                    wards: [
                        {
                            lable: 'Thị trấn Cần Đước',
                            value: '28108',
                        },
                        {
                            lable: 'Xã Long Cang',
                            value: '28126',
                        },
                        {
                            lable: 'Xã Long Hòa',
                            value: '28123',
                        },
                        {
                            lable: 'Xã Long Hựu Tây',
                            value: '28153',
                        },
                        {
                            lable: 'Xã Long Hựu Đông',
                            value: '28144',
                        },
                        {
                            lable: 'Xã Long Khê',
                            value: '28114',
                        },
                        {
                            lable: 'Xã Long Sơn',
                            value: '28129',
                        },
                        {
                            lable: 'Xã Long Trạch',
                            value: '28111',
                        },
                        {
                            lable: 'Xã Long Định',
                            value: '28117',
                        },
                        {
                            lable: 'Xã Mỹ Lệ',
                            value: '28135',
                        },
                        {
                            lable: 'Xã Phước Tuy',
                            value: '28141',
                        },
                        {
                            lable: 'Xã Phước Vân',
                            value: '28120',
                        },
                        {
                            lable: 'Xã Phước Đông',
                            value: '28150',
                        },
                        {
                            lable: 'Xã Tân Chánh',
                            value: '28156',
                        },
                        {
                            lable: 'Xã Tân Lân',
                            value: '28138',
                        },
                        {
                            lable: 'Xã Tân Trạch',
                            value: '28132',
                        },
                        {
                            lable: 'Xã Tân Ân',
                            value: '28147',
                        },
                    ],
                },
                {
                    lable: 'Huyện Cần Giuộc',
                    value: '807',
                    wards: [
                        {
                            lable: 'Thị trấn Cần Giuộc',
                            value: '28159',
                        },
                        {
                            lable: 'Xã Long An',
                            value: '28198',
                        },
                        {
                            lable: 'Xã Long Hậu',
                            value: '28168',
                        },
                        {
                            lable: 'Xã Long Phụng',
                            value: '28201',
                        },
                        {
                            lable: 'Xã Long Thượng',
                            value: '28165',
                        },
                        {
                            lable: 'Xã Mỹ Lộc',
                            value: '28177',
                        },
                        {
                            lable: 'Xã Phước Hậu',
                            value: '28174',
                        },
                        {
                            lable: 'Xã Phước Lâm',
                            value: '28183',
                        },
                        {
                            lable: 'Xã Phước Lý',
                            value: '28162',
                        },
                        {
                            lable: 'Xã Phước Lại',
                            value: '28180',
                        },
                        {
                            lable: 'Xã Phước Vĩnh Tây',
                            value: '28192',
                        },
                        {
                            lable: 'Xã Phước Vĩnh Đông',
                            value: '28195',
                        },
                        {
                            lable: 'Xã Thuận Thành',
                            value: '28189',
                        },
                        {
                            lable: 'Xã Tân Tập',
                            value: '28207',
                        },
                        {
                            lable: 'Xã Đông Thạnh',
                            value: '28204',
                        },
                    ],
                },
                {
                    lable: 'Huyện Châu Thành',
                    value: '808',
                    wards: [
                        {
                            lable: 'Thị trấn Tầm Vu',
                            value: '28210',
                        },
                        {
                            lable: 'Xã An Lục Long',
                            value: '28240',
                        },
                        {
                            lable: 'Xã Bình Quới',
                            value: '28213',
                        },
                        {
                            lable: 'Xã Dương Xuân Hội',
                            value: '28237',
                        },
                        {
                            lable: 'Xã Hiệp Thạnh',
                            value: '28228',
                        },
                        {
                            lable: 'Xã Hòa Phú',
                            value: '28216',
                        },
                        {
                            lable: 'Xã Long Trì',
                            value: '28243',
                        },
                        {
                            lable: 'Xã Phú Ngãi Trị',
                            value: '28219',
                        },
                        {
                            lable: 'Xã Phước Tân Hưng',
                            value: '28231',
                        },
                        {
                            lable: 'Xã Thanh Phú Long',
                            value: '28234',
                        },
                        {
                            lable: 'Xã Thanh Vĩnh Đông',
                            value: '28246',
                        },
                        {
                            lable: 'Xã Thuận Mỹ',
                            value: '28225',
                        },
                        {
                            lable: 'Xã Vĩnh Công',
                            value: '28222',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Nam Định',
            value: '36',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Nam Định',
                    value: '356',
                    wards: [
                        {
                            lable: 'Phường Bà Triệu',
                            value: '13654',
                        },
                        {
                            lable: 'Phường Cửa Bắc',
                            value: '13648',
                        },
                        {
                            lable: 'Phường Cửa Nam',
                            value: '13690',
                        },
                        {
                            lable: 'Phường Hạ Long',
                            value: '13633',
                        },
                        {
                            lable: 'Phường Lộc Hòa',
                            value: '13693',
                        },
                        {
                            lable: 'Phường Lộc Hạ',
                            value: '13684',
                        },
                        {
                            lable: 'Phường Lộc Vượng',
                            value: '13687',
                        },
                        {
                            lable: 'Phường Mỹ Xá',
                            value: '13699',
                        },
                        {
                            lable: 'Phường Nguyễn Du',
                            value: '13651',
                        },
                        {
                            lable: 'Phường Ngô Quyền',
                            value: '13663',
                        },
                        {
                            lable: 'Phường Năng Tĩnh',
                            value: '13672',
                        },
                        {
                            lable: 'Phường Phan Đình Phùng',
                            value: '13660',
                        },
                        {
                            lable: 'Phường Quang Trung',
                            value: '13645',
                        },
                        {
                            lable: 'Phường Thống Nhất',
                            value: '13681',
                        },
                        {
                            lable: 'Phường Trường Thi',
                            value: '13657',
                        },
                        {
                            lable: 'Phường Trần Hưng Đạo',
                            value: '13666',
                        },
                        {
                            lable: 'Phường Trần Quang Khải',
                            value: '13678',
                        },
                        {
                            lable: 'Phường Trần Tế Xương',
                            value: '13636',
                        },
                        {
                            lable: 'Phường Trần Đăng Ninh',
                            value: '13669',
                        },
                        {
                            lable: 'Phường Văn Miếu',
                            value: '13675',
                        },
                        {
                            lable: 'Phường Vị Hoàng',
                            value: '13639',
                        },
                        {
                            lable: 'Phường Vị Xuyên',
                            value: '13642',
                        },
                        {
                            lable: 'Xã Lộc An',
                            value: '13702',
                        },
                        {
                            lable: 'Xã Nam Phong',
                            value: '13696',
                        },
                        {
                            lable: 'Xã Nam Vân',
                            value: '13705',
                        },
                    ],
                },
                {
                    lable: 'Huyện Mỹ Lộc',
                    value: '358',
                    wards: [
                        {
                            lable: 'Thị trấn Mỹ Lộc',
                            value: '13708',
                        },
                        {
                            lable: 'Xã Mỹ Hà',
                            value: '13711',
                        },
                        {
                            lable: 'Xã Mỹ Hưng',
                            value: '13729',
                        },
                        {
                            lable: 'Xã Mỹ Phúc',
                            value: '13726',
                        },
                        {
                            lable: 'Xã Mỹ Thuận',
                            value: '13732',
                        },
                        {
                            lable: 'Xã Mỹ Thành',
                            value: '13738',
                        },
                        {
                            lable: 'Xã Mỹ Thắng',
                            value: '13717',
                        },
                        {
                            lable: 'Xã Mỹ Thịnh',
                            value: '13735',
                        },
                        {
                            lable: 'Xã Mỹ Tiến',
                            value: '13714',
                        },
                        {
                            lable: 'Xã Mỹ Trung',
                            value: '13720',
                        },
                        {
                            lable: 'Xã Mỹ Tân',
                            value: '13723',
                        },
                    ],
                },
                {
                    lable: 'Huyện Vụ Bản',
                    value: '359',
                    wards: [
                        {
                            lable: 'Thị trấn Gôi',
                            value: '13741',
                        },
                        {
                            lable: 'Xã Cộng Hòa',
                            value: '13762',
                        },
                        {
                            lable: 'Xã Hiển Khánh',
                            value: '13747',
                        },
                        {
                            lable: 'Xã Hợp Hưng',
                            value: '13753',
                        },
                        {
                            lable: 'Xã Kim Thái',
                            value: '13780',
                        },
                        {
                            lable: 'Xã Liên Bảo',
                            value: '13774',
                        },
                        {
                            lable: 'Xã Liên Minh',
                            value: '13783',
                        },
                        {
                            lable: 'Xã Minh Thuận',
                            value: '13744',
                        },
                        {
                            lable: 'Xã Minh Tân',
                            value: '13771',
                        },
                        {
                            lable: 'Xã Quang Trung',
                            value: '13768',
                        },
                        {
                            lable: 'Xã Tam Thanh',
                            value: '13789',
                        },
                        {
                            lable: 'Xã Thành Lợi',
                            value: '13777',
                        },
                        {
                            lable: 'Xã Trung Thành',
                            value: '13765',
                        },
                        {
                            lable: 'Xã Tân Khánh',
                            value: '13750',
                        },
                        {
                            lable: 'Xã Tân Thành',
                            value: '13759',
                        },
                        {
                            lable: 'Xã Vĩnh Hào',
                            value: '13792',
                        },
                        {
                            lable: 'Xã Đại An',
                            value: '13756',
                        },
                        {
                            lable: 'Xã Đại Thắng',
                            value: '13786',
                        },
                    ],
                },
                {
                    lable: 'Huyện Ý Yên',
                    value: '360',
                    wards: [
                        {
                            lable: 'Thị trấn Lâm',
                            value: '13795',
                        },
                        {
                            lable: 'Xã Yên Bình',
                            value: '13825',
                        },
                        {
                            lable: 'Xã Yên Bằng',
                            value: '13876',
                        },
                        {
                            lable: 'Xã Yên Chính',
                            value: '13822',
                        },
                        {
                            lable: 'Xã Yên Cường',
                            value: '13870',
                        },
                        {
                            lable: 'Xã Yên Dương',
                            value: '13834',
                        },
                        {
                            lable: 'Xã Yên Hưng',
                            value: '13840',
                        },
                        {
                            lable: 'Xã Yên Hồng',
                            value: '13855',
                        },
                        {
                            lable: 'Xã Yên Khang',
                            value: '13882',
                        },
                        {
                            lable: 'Xã Yên Khánh',
                            value: '13843',
                        },
                        {
                            lable: 'Xã Yên Lương',
                            value: '13852',
                        },
                        {
                            lable: 'Xã Yên Lộc',
                            value: '13873',
                        },
                        {
                            lable: 'Xã Yên Lợi',
                            value: '13807',
                        },
                        {
                            lable: 'Xã Yên Minh',
                            value: '13816',
                        },
                        {
                            lable: 'Xã Yên Mỹ',
                            value: '13831',
                        },
                        {
                            lable: 'Xã Yên Nghĩa',
                            value: '13813',
                        },
                        {
                            lable: 'Xã Yên Nhân',
                            value: '13885',
                        },
                        {
                            lable: 'Xã Yên Ninh',
                            value: '13849',
                        },
                        {
                            lable: 'Xã Yên Phong',
                            value: '13846',
                        },
                        {
                            lable: 'Xã Yên Phú',
                            value: '13828',
                        },
                        {
                            lable: 'Xã Yên Phúc',
                            value: '13867',
                        },
                        {
                            lable: 'Xã Yên Phương',
                            value: '13819',
                        },
                        {
                            lable: 'Xã Yên Quang',
                            value: '13858',
                        },
                        {
                            lable: 'Xã Yên Thành',
                            value: '13801',
                        },
                        {
                            lable: 'Xã Yên Thắng',
                            value: '13864',
                        },
                        {
                            lable: 'Xã Yên Thọ',
                            value: '13810',
                        },
                        {
                            lable: 'Xã Yên Tiến',
                            value: '13861',
                        },
                        {
                            lable: 'Xã Yên Trung',
                            value: '13798',
                        },
                        {
                            lable: 'Xã Yên Trị',
                            value: '13888',
                        },
                        {
                            lable: 'Xã Yên Tân',
                            value: '13804',
                        },
                        {
                            lable: 'Xã Yên Đồng',
                            value: '13879',
                        },
                    ],
                },
                {
                    lable: 'Huyện Nghĩa Hưng',
                    value: '361',
                    wards: [
                        {
                            lable: 'Thị trấn Liễu Đề',
                            value: '13891',
                        },
                        {
                            lable: 'Thị trấn Quỹ Nhất',
                            value: '13936',
                        },
                        {
                            lable: 'Thị trấn Rạng Đông',
                            value: '13894',
                        },
                        {
                            lable: 'Xã Hoàng Nam',
                            value: '13909',
                        },
                        {
                            lable: 'Xã Nam Điền',
                            value: '13963',
                        },
                        {
                            lable: 'Xã Nghĩa Bình',
                            value: '13933',
                        },
                        {
                            lable: 'Xã Nghĩa Châu',
                            value: '13912',
                        },
                        {
                            lable: 'Xã Nghĩa Hùng',
                            value: '13942',
                        },
                        {
                            lable: 'Xã Nghĩa Hải',
                            value: '13957',
                        },
                        {
                            lable: 'Xã Nghĩa Hồng',
                            value: '13924',
                        },
                        {
                            lable: 'Xã Nghĩa Lâm',
                            value: '13945',
                        },
                        {
                            lable: 'Xã Nghĩa Lạc',
                            value: '13921',
                        },
                        {
                            lable: 'Xã Nghĩa Lợi',
                            value: '13954',
                        },
                        {
                            lable: 'Xã Nghĩa Minh',
                            value: '13903',
                        },
                        {
                            lable: 'Xã Nghĩa Phong',
                            value: '13927',
                        },
                        {
                            lable: 'Xã Nghĩa Phú',
                            value: '13930',
                        },
                        {
                            lable: 'Xã Nghĩa Sơn',
                            value: '13918',
                        },
                        {
                            lable: 'Xã Nghĩa Thành',
                            value: '13948',
                        },
                        {
                            lable: 'Xã Nghĩa Thái',
                            value: '13906',
                        },
                        {
                            lable: 'Xã Nghĩa Thịnh',
                            value: '13900',
                        },
                        {
                            lable: 'Xã Nghĩa Trung',
                            value: '13915',
                        },
                        {
                            lable: 'Xã Nghĩa Tân',
                            value: '13939',
                        },
                        {
                            lable: 'Xã Nghĩa Đồng',
                            value: '13897',
                        },
                        {
                            lable: 'Xã Phúc Thắng',
                            value: '13951',
                        },
                    ],
                },
                {
                    lable: 'Huyện Nam Trực',
                    value: '362',
                    wards: [
                        {
                            lable: 'Thị trấn Nam Giang',
                            value: '13966',
                        },
                        {
                            lable: 'Xã Bình Minh',
                            value: '14011',
                        },
                        {
                            lable: 'Xã Hồng Quang',
                            value: '13984',
                        },
                        {
                            lable: 'Xã Nam Cường',
                            value: '13990',
                        },
                        {
                            lable: 'Xã Nam Dương',
                            value: '14002',
                        },
                        {
                            lable: 'Xã Nam Hoa',
                            value: '13999',
                        },
                        {
                            lable: 'Xã Nam Hùng',
                            value: '13996',
                        },
                        {
                            lable: 'Xã Nam Hải',
                            value: '14020',
                        },
                        {
                            lable: 'Xã Nam Hồng',
                            value: '13993',
                        },
                        {
                            lable: 'Xã Nam Lợi',
                            value: '14008',
                        },
                        {
                            lable: 'Xã Nam Mỹ',
                            value: '13969',
                        },
                        {
                            lable: 'Xã Nam Thanh',
                            value: '14005',
                        },
                        {
                            lable: 'Xã Nam Thái',
                            value: '14023',
                        },
                        {
                            lable: 'Xã Nam Thắng',
                            value: '13978',
                        },
                        {
                            lable: 'Xã Nam Tiến',
                            value: '14017',
                        },
                        {
                            lable: 'Xã Nam Toàn',
                            value: '13981',
                        },
                        {
                            lable: 'Xã Nghĩa An',
                            value: '13975',
                        },
                        {
                            lable: 'Xã Tân Thịnh',
                            value: '13987',
                        },
                        {
                            lable: 'Xã Điền Xá',
                            value: '13972',
                        },
                        {
                            lable: 'Xã Đồng Sơn',
                            value: '14014',
                        },
                    ],
                },
                {
                    lable: 'Huyện Trực Ninh',
                    value: '363',
                    wards: [
                        {
                            lable: 'Thị trấn Cát Thành',
                            value: '14056',
                        },
                        {
                            lable: 'Thị trấn Cổ Lễ',
                            value: '14026',
                        },
                        {
                            lable: 'Thị trấn Ninh Cường',
                            value: '14077',
                        },
                        {
                            lable: 'Xã Liêm Hải',
                            value: '14038',
                        },
                        {
                            lable: 'Xã Phương Định',
                            value: '14029',
                        },
                        {
                            lable: 'Xã Trung Đông',
                            value: '14035',
                        },
                        {
                            lable: 'Xã Trực Chính',
                            value: '14032',
                        },
                        {
                            lable: 'Xã Trực Cường',
                            value: '14074',
                        },
                        {
                            lable: 'Xã Trực Hùng',
                            value: '14083',
                        },
                        {
                            lable: 'Xã Trực Hưng',
                            value: '14050',
                        },
                        {
                            lable: 'Xã Trực Khang',
                            value: '14062',
                        },
                        {
                            lable: 'Xã Trực Mỹ',
                            value: '14068',
                        },
                        {
                            lable: 'Xã Trực Nội',
                            value: '14053',
                        },
                        {
                            lable: 'Xã Trực Thanh',
                            value: '14059',
                        },
                        {
                            lable: 'Xã Trực Thuận',
                            value: '14065',
                        },
                        {
                            lable: 'Xã Trực Thái',
                            value: '14080',
                        },
                        {
                            lable: 'Xã Trực Thắng',
                            value: '14086',
                        },
                        {
                            lable: 'Xã Trực Tuấn',
                            value: '14041',
                        },
                        {
                            lable: 'Xã Trực Đại',
                            value: '14071',
                        },
                        {
                            lable: 'Xã Trực Đạo',
                            value: '14047',
                        },
                        {
                            lable: 'Xã Việt Hùng',
                            value: '14044',
                        },
                    ],
                },
                {
                    lable: 'Huyện Xuân Trường',
                    value: '364',
                    wards: [
                        {
                            lable: 'Thị trấn Xuân Trường',
                            value: '14089',
                        },
                        {
                            lable: 'Xã Thọ Nghiệp',
                            value: '14125',
                        },
                        {
                            lable: 'Xã Xuân Bắc',
                            value: '14119',
                        },
                        {
                            lable: 'Xã Xuân Châu',
                            value: '14092',
                        },
                        {
                            lable: 'Xã Xuân Hòa',
                            value: '14146',
                        },
                        {
                            lable: 'Xã Xuân Hồng',
                            value: '14095',
                        },
                        {
                            lable: 'Xã Xuân Kiên',
                            value: '14137',
                        },
                        {
                            lable: 'Xã Xuân Ngọc',
                            value: '14116',
                        },
                        {
                            lable: 'Xã Xuân Ninh',
                            value: '14143',
                        },
                        {
                            lable: 'Xã Xuân Phong',
                            value: '14104',
                        },
                        {
                            lable: 'Xã Xuân Phú',
                            value: '14128',
                        },
                        {
                            lable: 'Xã Xuân Phương',
                            value: '14122',
                        },
                        {
                            lable: 'Xã Xuân Thành',
                            value: '14098',
                        },
                        {
                            lable: 'Xã Xuân Thượng',
                            value: '14101',
                        },
                        {
                            lable: 'Xã Xuân Thủy',
                            value: '14113',
                        },
                        {
                            lable: 'Xã Xuân Tiến',
                            value: '14140',
                        },
                        {
                            lable: 'Xã Xuân Trung',
                            value: '14131',
                        },
                        {
                            lable: 'Xã Xuân Tân',
                            value: '14110',
                        },
                        {
                            lable: 'Xã Xuân Vinh',
                            value: '14134',
                        },
                        {
                            lable: 'Xã Xuân Đài',
                            value: '14107',
                        },
                    ],
                },
                {
                    lable: 'Huyện Giao Thủy',
                    value: '365',
                    wards: [
                        {
                            lable: 'Thị trấn Ngô Đồng',
                            value: '14149',
                        },
                        {
                            lable: 'Thị trấn Quất Lâm',
                            value: '14152',
                        },
                        {
                            lable: 'Xã Bình Hòa',
                            value: '14170',
                        },
                        {
                            lable: 'Xã Bạch Long',
                            value: '14206',
                        },
                        {
                            lable: 'Xã Giao An',
                            value: '14182',
                        },
                        {
                            lable: 'Xã Giao Châu',
                            value: '14188',
                        },
                        {
                            lable: 'Xã Giao Hà',
                            value: '14176',
                        },
                        {
                            lable: 'Xã Giao Hương',
                            value: '14155',
                        },
                        {
                            lable: 'Xã Giao Hải',
                            value: '14203',
                        },
                        {
                            lable: 'Xã Giao Long',
                            value: '14209',
                        },
                        {
                            lable: 'Xã Giao Lạc',
                            value: '14185',
                        },
                        {
                            lable: 'Xã Giao Nhân',
                            value: '14179',
                        },
                        {
                            lable: 'Xã Giao Phong',
                            value: '14212',
                        },
                        {
                            lable: 'Xã Giao Thanh',
                            value: '14164',
                        },
                        {
                            lable: 'Xã Giao Thiện',
                            value: '14161',
                        },
                        {
                            lable: 'Xã Giao Thịnh',
                            value: '14200',
                        },
                        {
                            lable: 'Xã Giao Tiến',
                            value: '14173',
                        },
                        {
                            lable: 'Xã Giao Tân',
                            value: '14191',
                        },
                        {
                            lable: 'Xã Giao Xuân',
                            value: '14197',
                        },
                        {
                            lable: 'Xã Giao Yến',
                            value: '14194',
                        },
                        {
                            lable: 'Xã Hoành Sơn',
                            value: '14167',
                        },
                        {
                            lable: 'Xã Hồng Thuận',
                            value: '14158',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hải Hậu',
                    value: '366',
                    wards: [
                        {
                            lable: 'Thị trấn Cồn',
                            value: '14218',
                        },
                        {
                            lable: 'Thị trấn Thịnh Long',
                            value: '14221',
                        },
                        {
                            lable: 'Thị trấn Yên Định',
                            value: '14215',
                        },
                        {
                            lable: 'Xã Hải An',
                            value: '14284',
                        },
                        {
                            lable: 'Xã Hải Anh',
                            value: '14236',
                        },
                        {
                            lable: 'Xã Hải Bắc',
                            value: '14242',
                        },
                        {
                            lable: 'Xã Hải Châu',
                            value: '14311',
                        },
                        {
                            lable: 'Xã Hải Chính',
                            value: '14305',
                        },
                        {
                            lable: 'Xã Hải Cường',
                            value: '14299',
                        },
                        {
                            lable: 'Xã Hải Giang',
                            value: '14296',
                        },
                        {
                            lable: 'Xã Hải Hà',
                            value: '14251',
                        },
                        {
                            lable: 'Xã Hải Hòa',
                            value: '14317',
                        },
                        {
                            lable: 'Xã Hải Hưng',
                            value: '14239',
                        },
                        {
                            lable: 'Xã Hải Long',
                            value: '14254',
                        },
                        {
                            lable: 'Xã Hải Lý',
                            value: '14290',
                        },
                        {
                            lable: 'Xã Hải Lộc',
                            value: '14263',
                        },
                        {
                            lable: 'Xã Hải Minh',
                            value: '14233',
                        },
                        {
                            lable: 'Xã Hải Nam',
                            value: '14224',
                        },
                        {
                            lable: 'Xã Hải Ninh',
                            value: '14302',
                        },
                        {
                            lable: 'Xã Hải Phong',
                            value: '14281',
                        },
                        {
                            lable: 'Xã Hải Phú',
                            value: '14293',
                        },
                        {
                            lable: 'Xã Hải Phúc',
                            value: '14245',
                        },
                        {
                            lable: 'Xã Hải Phương',
                            value: '14257',
                        },
                        {
                            lable: 'Xã Hải Quang',
                            value: '14266',
                        },
                        {
                            lable: 'Xã Hải Sơn',
                            value: '14272',
                        },
                        {
                            lable: 'Xã Hải Thanh',
                            value: '14248',
                        },
                        {
                            lable: 'Xã Hải Triều',
                            value: '14314',
                        },
                        {
                            lable: 'Xã Hải Trung',
                            value: '14227',
                        },
                        {
                            lable: 'Xã Hải Tân',
                            value: '14275',
                        },
                        {
                            lable: 'Xã Hải Tây',
                            value: '14287',
                        },
                        {
                            lable: 'Xã Hải Vân',
                            value: '14230',
                        },
                        {
                            lable: 'Xã Hải Xuân',
                            value: '14308',
                        },
                        {
                            lable: 'Xã Hải Đông',
                            value: '14269',
                        },
                        {
                            lable: 'Xã Hải Đường',
                            value: '14260',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Nghệ An',
            value: '40',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Vinh',
                    value: '412',
                    wards: [
                        {
                            lable: 'Phường Bến Thủy',
                            value: '16693',
                        },
                        {
                            lable: 'Phường Cửa Nam',
                            value: '16678',
                        },
                        {
                            lable: 'Phường Hà Huy Tập',
                            value: '16666',
                        },
                        {
                            lable: 'Phường Hưng Bình',
                            value: '16672',
                        },
                        {
                            lable: 'Phường Hưng Dũng',
                            value: '16675',
                        },
                        {
                            lable: 'Phường Hưng Phúc',
                            value: '16673',
                        },
                        {
                            lable: 'Phường Hồng Sơn',
                            value: '16696',
                        },
                        {
                            lable: 'Phường Lê Lợi',
                            value: '16669',
                        },
                        {
                            lable: 'Phường Lê Mao',
                            value: '16687',
                        },
                        {
                            lable: 'Phường Quang Trung',
                            value: '16681',
                        },
                        {
                            lable: 'Phường Quán Bàu',
                            value: '16670',
                        },
                        {
                            lable: 'Phường Trung Đô',
                            value: '16699',
                        },
                        {
                            lable: 'Phường Trường Thi',
                            value: '16690',
                        },
                        {
                            lable: 'Phường Vinh Tân',
                            value: '16714',
                        },
                        {
                            lable: 'Phường Đông Vĩnh',
                            value: '16663',
                        },
                        {
                            lable: 'Phường Đội Cung',
                            value: '16684',
                        },
                        {
                            lable: 'Xã Hưng Chính',
                            value: '18013',
                        },
                        {
                            lable: 'Xã Hưng Hòa',
                            value: '16711',
                        },
                        {
                            lable: 'Xã Hưng Lộc',
                            value: '16708',
                        },
                        {
                            lable: 'Xã Hưng Đông',
                            value: '16705',
                        },
                        {
                            lable: 'Xã Nghi Kim',
                            value: '17920',
                        },
                        {
                            lable: 'Xã Nghi Liên',
                            value: '17908',
                        },
                        {
                            lable: 'Xã Nghi Phú',
                            value: '16702',
                        },
                        {
                            lable: 'Xã Nghi Ân',
                            value: '17914',
                        },
                        {
                            lable: 'Xã Nghi Đức',
                            value: '17923',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Cửa Lò',
                    value: '413',
                    wards: [
                        {
                            lable: 'Phường Nghi Hòa',
                            value: '16726',
                        },
                        {
                            lable: 'Phường Nghi Hương',
                            value: '16732',
                        },
                        {
                            lable: 'Phường Nghi Hải',
                            value: '16729',
                        },
                        {
                            lable: 'Phường Nghi Thu',
                            value: '16735',
                        },
                        {
                            lable: 'Phường Nghi Thuỷ',
                            value: '16717',
                        },
                        {
                            lable: 'Phường Nghi Tân',
                            value: '16720',
                        },
                        {
                            lable: 'Phường Thu Thuỷ',
                            value: '16723',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Thái Hoà',
                    value: '414',
                    wards: [
                        {
                            lable: 'Phường Hoà Hiếu',
                            value: '16939',
                        },
                        {
                            lable: 'Phường Long Sơn',
                            value: '17003',
                        },
                        {
                            lable: 'Phường Quang Phong',
                            value: '16993',
                        },
                        {
                            lable: 'Phường Quang Tiến',
                            value: '16994',
                        },
                        {
                            lable: 'Xã Nghĩa Mỹ',
                            value: '17008',
                        },
                        {
                            lable: 'Xã Nghĩa Thuận',
                            value: '17014',
                        },
                        {
                            lable: 'Xã Nghĩa Tiến',
                            value: '17005',
                        },
                        {
                            lable: 'Xã Tây Hiếu',
                            value: '17011',
                        },
                        {
                            lable: 'Xã Đông Hiếu',
                            value: '17017',
                        },
                    ],
                },
                {
                    lable: 'Huyện Quế Phong',
                    value: '415',
                    wards: [
                        {
                            lable: 'Thị trấn Kim Sơn',
                            value: '16738',
                        },
                        {
                            lable: 'Xã Châu Kim',
                            value: '16759',
                        },
                        {
                            lable: 'Xã Châu Thôn',
                            value: '16765',
                        },
                        {
                            lable: 'Xã Căm Muộn',
                            value: '16774',
                        },
                        {
                            lable: 'Xã Hạnh Dịch',
                            value: '16747',
                        },
                        {
                            lable: 'Xã Mường Nọc',
                            value: '16763',
                        },
                        {
                            lable: 'Xã Nậm Giải',
                            value: '16753',
                        },
                        {
                            lable: 'Xã Nậm Nhoóng',
                            value: '16768',
                        },
                        {
                            lable: 'Xã Quang Phong',
                            value: '16771',
                        },
                        {
                            lable: 'Xã Thông Thụ',
                            value: '16741',
                        },
                        {
                            lable: 'Xã Tiền Phong',
                            value: '16750',
                        },
                        {
                            lable: 'Xã Tri Lễ',
                            value: '16756',
                        },
                        {
                            lable: 'Xã Đồng Văn',
                            value: '16744',
                        },
                    ],
                },
                {
                    lable: 'Huyện Quỳ Châu',
                    value: '416',
                    wards: [
                        {
                            lable: 'Thị trấn Tân Lạc',
                            value: '16777',
                        },
                        {
                            lable: 'Xã Châu Bình',
                            value: '16804',
                        },
                        {
                            lable: 'Xã Châu Bính',
                            value: '16780',
                        },
                        {
                            lable: 'Xã Châu Hoàn',
                            value: '16807',
                        },
                        {
                            lable: 'Xã Châu Hạnh',
                            value: '16795',
                        },
                        {
                            lable: 'Xã Châu Hội',
                            value: '16786',
                        },
                        {
                            lable: 'Xã Châu Nga',
                            value: '16789',
                        },
                        {
                            lable: 'Xã Châu Phong',
                            value: '16801',
                        },
                        {
                            lable: 'Xã Châu Thuận',
                            value: '16783',
                        },
                        {
                            lable: 'Xã Châu Thắng',
                            value: '16798',
                        },
                        {
                            lable: 'Xã Châu Tiến',
                            value: '16792',
                        },
                        {
                            lable: 'Xã Diên Lãm',
                            value: '16810',
                        },
                    ],
                },
                {
                    lable: 'Huyện Kỳ Sơn',
                    value: '417',
                    wards: [
                        {
                            lable: 'Thị trấn Mường Xén',
                            value: '16813',
                        },
                        {
                            lable: 'Xã Bảo Nam',
                            value: '16840',
                        },
                        {
                            lable: 'Xã Bảo Thắng',
                            value: '16846',
                        },
                        {
                            lable: 'Xã Bắc Lý',
                            value: '16819',
                        },
                        {
                            lable: 'Xã Chiêu Lưu',
                            value: '16855',
                        },
                        {
                            lable: 'Xã Huồi Tụ',
                            value: '16828',
                        },
                        {
                            lable: 'Xã Hữu Kiệm',
                            value: '16861',
                        },
                        {
                            lable: 'Xã Hữu Lập',
                            value: '16849',
                        },
                        {
                            lable: 'Xã Keng Đu',
                            value: '16822',
                        },
                        {
                            lable: 'Xã Mường Lống',
                            value: '16831',
                        },
                        {
                            lable: 'Xã Mường Típ',
                            value: '16858',
                        },
                        {
                            lable: 'Xã Mường Ải',
                            value: '16867',
                        },
                        {
                            lable: 'Xã Mỹ Lý',
                            value: '16816',
                        },
                        {
                            lable: 'Xã Na Loi',
                            value: '16834',
                        },
                        {
                            lable: 'Xã Na Ngoi',
                            value: '16870',
                        },
                        {
                            lable: 'Xã Nậm Càn',
                            value: '16873',
                        },
                        {
                            lable: 'Xã Nậm Cắn',
                            value: '16837',
                        },
                        {
                            lable: 'Xã Phà Đánh',
                            value: '16843',
                        },
                        {
                            lable: 'Xã Tà Cạ',
                            value: '16852',
                        },
                        {
                            lable: 'Xã Tây Sơn',
                            value: '16864',
                        },
                        {
                            lable: 'Xã Đoọc Mạy',
                            value: '16825',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tương Dương',
                    value: '418',
                    wards: [
                        {
                            lable: 'Thị trấn Thạch Giám',
                            value: '16876',
                        },
                        {
                            lable: 'Xã Hữu Khuông',
                            value: '16885',
                        },
                        {
                            lable: 'Xã Lưu Kiền',
                            value: '16915',
                        },
                        {
                            lable: 'Xã Lưỡng Minh',
                            value: '16906',
                        },
                        {
                            lable: 'Xã Mai Sơn',
                            value: '16879',
                        },
                        {
                            lable: 'Xã Nga My',
                            value: '16903',
                        },
                        {
                            lable: 'Xã Nhôn Mai',
                            value: '16882',
                        },
                        {
                            lable: 'Xã Tam Hợp',
                            value: '16936',
                        },
                        {
                            lable: 'Xã Tam Quang',
                            value: '16933',
                        },
                        {
                            lable: 'Xã Tam Thái',
                            value: '16924',
                        },
                        {
                            lable: 'Xã Tam Đình',
                            value: '16927',
                        },
                        {
                            lable: 'Xã Xiêng My',
                            value: '16904',
                        },
                        {
                            lable: 'Xã Xá Lượng',
                            value: '16921',
                        },
                        {
                            lable: 'Xã Yên Hòa',
                            value: '16909',
                        },
                        {
                            lable: 'Xã Yên Na',
                            value: '16912',
                        },
                        {
                            lable: 'Xã Yên Thắng',
                            value: '16930',
                        },
                        {
                            lable: 'Xã Yên Tĩnh',
                            value: '16900',
                        },
                    ],
                },
                {
                    lable: 'Huyện Nghĩa Đàn',
                    value: '419',
                    wards: [
                        {
                            lable: 'Thị trấn Nghĩa Đàn',
                            value: '16941',
                        },
                        {
                            lable: 'Xã Nghĩa An',
                            value: '17023',
                        },
                        {
                            lable: 'Xã Nghĩa Bình',
                            value: '16960',
                        },
                        {
                            lable: 'Xã Nghĩa Hiếu',
                            value: '16996',
                        },
                        {
                            lable: 'Xã Nghĩa Hưng',
                            value: '16972',
                        },
                        {
                            lable: 'Xã Nghĩa Hồng',
                            value: '16975',
                        },
                        {
                            lable: 'Xã Nghĩa Hội',
                            value: '16984',
                        },
                        {
                            lable: 'Xã Nghĩa Khánh',
                            value: '17032',
                        },
                        {
                            lable: 'Xã Nghĩa Long',
                            value: '17026',
                        },
                        {
                            lable: 'Xã Nghĩa Lâm',
                            value: '16951',
                        },
                        {
                            lable: 'Xã Nghĩa Lạc',
                            value: '16948',
                        },
                        {
                            lable: 'Xã Nghĩa Lộc',
                            value: '17029',
                        },
                        {
                            lable: 'Xã Nghĩa Lợi',
                            value: '16957',
                        },
                        {
                            lable: 'Xã Nghĩa Mai',
                            value: '16942',
                        },
                        {
                            lable: 'Xã Nghĩa Minh',
                            value: '16966',
                        },
                        {
                            lable: 'Xã Nghĩa Phú',
                            value: '16969',
                        },
                        {
                            lable: 'Xã Nghĩa Sơn',
                            value: '16954',
                        },
                        {
                            lable: 'Xã Nghĩa Thành',
                            value: '16987',
                        },
                        {
                            lable: 'Xã Nghĩa Thịnh',
                            value: '16978',
                        },
                        {
                            lable: 'Xã Nghĩa Thọ',
                            value: '16963',
                        },
                        {
                            lable: 'Xã Nghĩa Trung',
                            value: '16981',
                        },
                        {
                            lable: 'Xã Nghĩa Yên',
                            value: '16945',
                        },
                        {
                            lable: 'Xã Nghĩa Đức',
                            value: '17020',
                        },
                    ],
                },
                {
                    lable: 'Huyện Quỳ Hợp',
                    value: '420',
                    wards: [
                        {
                            lable: 'Thị trấn Quỳ Hợp',
                            value: '17035',
                        },
                        {
                            lable: 'Xã Bắc Sơn',
                            value: '17095',
                        },
                        {
                            lable: 'Xã Châu Cường',
                            value: '17062',
                        },
                        {
                            lable: 'Xã Châu Hồng',
                            value: '17044',
                        },
                        {
                            lable: 'Xã Châu Lý',
                            value: '17089',
                        },
                        {
                            lable: 'Xã Châu Lộc',
                            value: '17056',
                        },
                        {
                            lable: 'Xã Châu Quang',
                            value: '17065',
                        },
                        {
                            lable: 'Xã Châu Thành',
                            value: '17050',
                        },
                        {
                            lable: 'Xã Châu Thái',
                            value: '17077',
                        },
                        {
                            lable: 'Xã Châu Tiến',
                            value: '17041',
                        },
                        {
                            lable: 'Xã Châu Đình',
                            value: '17080',
                        },
                        {
                            lable: 'Xã Hạ Sơn',
                            value: '17092',
                        },
                        {
                            lable: 'Xã Liên Hợp',
                            value: '17053',
                        },
                        {
                            lable: 'Xã Minh Hợp',
                            value: '17071',
                        },
                        {
                            lable: 'Xã Nam Sơn',
                            value: '17086',
                        },
                        {
                            lable: 'Xã Nghĩa Xuân',
                            value: '17074',
                        },
                        {
                            lable: 'Xã Tam Hợp',
                            value: '17059',
                        },
                        {
                            lable: 'Xã Thọ Hợp',
                            value: '17068',
                        },
                        {
                            lable: 'Xã Văn Lợi',
                            value: '17083',
                        },
                        {
                            lable: 'Xã Yên Hợp',
                            value: '17038',
                        },
                        {
                            lable: 'Xã Đồng Hợp',
                            value: '17047',
                        },
                    ],
                },
                {
                    lable: 'Huyện Quỳnh Lưu',
                    value: '421',
                    wards: [
                        {
                            lable: 'Thị trấn Cầu Giát',
                            value: '17098',
                        },
                        {
                            lable: 'Xã An Hòa',
                            value: '17206',
                        },
                        {
                            lable: 'Xã Ngọc Sơn',
                            value: '17146',
                        },
                        {
                            lable: 'Xã Quỳnh Bá',
                            value: '17185',
                        },
                        {
                            lable: 'Xã Quỳnh Bảng',
                            value: '17158',
                        },
                        {
                            lable: 'Xã Quỳnh Châu',
                            value: '17122',
                        },
                        {
                            lable: 'Xã Quỳnh Diễn',
                            value: '17191',
                        },
                        {
                            lable: 'Xã Quỳnh Giang',
                            value: '17197',
                        },
                        {
                            lable: 'Xã Quỳnh Hoa',
                            value: '17152',
                        },
                        {
                            lable: 'Xã Quỳnh Hưng',
                            value: '17194',
                        },
                        {
                            lable: 'Xã Quỳnh Hậu',
                            value: '17167',
                        },
                        {
                            lable: 'Xã Quỳnh Hồng',
                            value: '17179',
                        },
                        {
                            lable: 'Xã Quỳnh Long',
                            value: '17221',
                        },
                        {
                            lable: 'Xã Quỳnh Lâm',
                            value: '17170',
                        },
                        {
                            lable: 'Xã Quỳnh Lương',
                            value: '17176',
                        },
                        {
                            lable: 'Xã Quỳnh Minh',
                            value: '17188',
                        },
                        {
                            lable: 'Xã Quỳnh Mỹ',
                            value: '17161',
                        },
                        {
                            lable: 'Xã Quỳnh Nghĩa',
                            value: '17203',
                        },
                        {
                            lable: 'Xã Quỳnh Ngọc',
                            value: '17200',
                        },
                        {
                            lable: 'Xã Quỳnh Tam',
                            value: '17149',
                        },
                        {
                            lable: 'Xã Quỳnh Thanh',
                            value: '17164',
                        },
                        {
                            lable: 'Xã Quỳnh Thuận',
                            value: '17218',
                        },
                        {
                            lable: 'Xã Quỳnh Thạch',
                            value: '17155',
                        },
                        {
                            lable: 'Xã Quỳnh Thắng',
                            value: '17101',
                        },
                        {
                            lable: 'Xã Quỳnh Thọ',
                            value: '17215',
                        },
                        {
                            lable: 'Xã Quỳnh Tân',
                            value: '17119',
                        },
                        {
                            lable: 'Xã Quỳnh Văn',
                            value: '17143',
                        },
                        {
                            lable: 'Xã Quỳnh Yên',
                            value: '17182',
                        },
                        {
                            lable: 'Xã Quỳnh Đôi',
                            value: '17173',
                        },
                        {
                            lable: 'Xã Sơn Hải',
                            value: '17212',
                        },
                        {
                            lable: 'Xã Tiến Thủy',
                            value: '17209',
                        },
                        {
                            lable: 'Xã Tân Sơn',
                            value: '17140',
                        },
                        {
                            lable: 'Xã Tân Thắng',
                            value: '17224',
                        },
                    ],
                },
                {
                    lable: 'Huyện Con Cuông',
                    value: '422',
                    wards: [
                        {
                            lable: 'Thị trấn Con Cuông',
                            value: '17227',
                        },
                        {
                            lable: 'Xã Bình Chuẩn',
                            value: '17230',
                        },
                        {
                            lable: 'Xã Bồng Khê',
                            value: '17254',
                        },
                        {
                            lable: 'Xã Cam Lâm',
                            value: '17236',
                        },
                        {
                            lable: 'Xã Chi Khê',
                            value: '17251',
                        },
                        {
                            lable: 'Xã Châu Khê',
                            value: '17248',
                        },
                        {
                            lable: 'Xã Lạng Khê',
                            value: '17233',
                        },
                        {
                            lable: 'Xã Lục Dạ',
                            value: '17260',
                        },
                        {
                            lable: 'Xã Môn Sơn',
                            value: '17263',
                        },
                        {
                            lable: 'Xã Mậu Đức',
                            value: '17245',
                        },
                        {
                            lable: 'Xã Thạch Ngàn',
                            value: '17239',
                        },
                        {
                            lable: 'Xã Yên Khê',
                            value: '17257',
                        },
                        {
                            lable: 'Xã Đôn Phục',
                            value: '17242',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tân Kỳ',
                    value: '423',
                    wards: [
                        {
                            lable: 'Thị trấn Tân Kỳ',
                            value: '17266',
                        },
                        {
                            lable: 'Xã Giai Xuân',
                            value: '17278',
                        },
                        {
                            lable: 'Xã Hương Sơn',
                            value: '17317',
                        },
                        {
                            lable: 'Xã Kỳ Sơn',
                            value: '17314',
                        },
                        {
                            lable: 'Xã Kỳ Tân',
                            value: '17320',
                        },
                        {
                            lable: 'Xã Nghĩa Bình',
                            value: '17281',
                        },
                        {
                            lable: 'Xã Nghĩa Dũng',
                            value: '17308',
                        },
                        {
                            lable: 'Xã Nghĩa Hoàn',
                            value: '17296',
                        },
                        {
                            lable: 'Xã Nghĩa Hành',
                            value: '17326',
                        },
                        {
                            lable: 'Xã Nghĩa Hợp',
                            value: '17293',
                        },
                        {
                            lable: 'Xã Nghĩa Phúc',
                            value: '17299',
                        },
                        {
                            lable: 'Xã Nghĩa Thái',
                            value: '17290',
                        },
                        {
                            lable: 'Xã Nghĩa Đồng',
                            value: '17284',
                        },
                        {
                            lable: 'Xã Phú Sơn',
                            value: '17323',
                        },
                        {
                            lable: 'Xã Tiên Kỳ',
                            value: '17302',
                        },
                        {
                            lable: 'Xã Tân An',
                            value: '17305',
                        },
                        {
                            lable: 'Xã Tân Hương',
                            value: '17325',
                        },
                        {
                            lable: 'Xã Tân Hợp',
                            value: '17269',
                        },
                        {
                            lable: 'Xã Tân Long',
                            value: '17311',
                        },
                        {
                            lable: 'Xã Tân Phú',
                            value: '17272',
                        },
                        {
                            lable: 'Xã Tân Xuân',
                            value: '17275',
                        },
                        {
                            lable: 'Xã Đồng Văn',
                            value: '17287',
                        },
                    ],
                },
                {
                    lable: 'Huyện Anh Sơn',
                    value: '424',
                    wards: [
                        {
                            lable: 'Thị trấn Anh Sơn',
                            value: '17329',
                        },
                        {
                            lable: 'Xã Bình Sơn',
                            value: '17338',
                        },
                        {
                            lable: 'Xã Cao Sơn',
                            value: '17386',
                        },
                        {
                            lable: 'Xã Cẩm Sơn',
                            value: '17350',
                        },
                        {
                            lable: 'Xã Hoa Sơn',
                            value: '17357',
                        },
                        {
                            lable: 'Xã Hùng Sơn',
                            value: '17347',
                        },
                        {
                            lable: 'Xã Hội Sơn',
                            value: '17368',
                        },
                        {
                            lable: 'Xã Khai Sơn',
                            value: '17380',
                        },
                        {
                            lable: 'Xã Long Sơn',
                            value: '17377',
                        },
                        {
                            lable: 'Xã Lĩnh Sơn',
                            value: '17383',
                        },
                        {
                            lable: 'Xã Lạng Sơn',
                            value: '17365',
                        },
                        {
                            lable: 'Xã Phúc Sơn',
                            value: '17374',
                        },
                        {
                            lable: 'Xã Tam Sơn',
                            value: '17341',
                        },
                        {
                            lable: 'Xã Thành Sơn',
                            value: '17335',
                        },
                        {
                            lable: 'Xã Thạch Sơn',
                            value: '17371',
                        },
                        {
                            lable: 'Xã Thọ Sơn',
                            value: '17332',
                        },
                        {
                            lable: 'Xã Tào Sơn',
                            value: '17359',
                        },
                        {
                            lable: 'Xã Tường Sơn',
                            value: '17356',
                        },
                        {
                            lable: 'Xã Vĩnh Sơn',
                            value: '17362',
                        },
                        {
                            lable: 'Xã Đỉnh Sơn',
                            value: '17344',
                        },
                        {
                            lable: 'Xã Đức Sơn',
                            value: '17353',
                        },
                    ],
                },
                {
                    lable: 'Huyện Diễn Châu',
                    value: '425',
                    wards: [
                        {
                            lable: 'Thị trấn Diễn Châu',
                            value: '17389',
                        },
                        {
                            lable: 'Xã Diễn An',
                            value: '17500',
                        },
                        {
                            lable: 'Xã Diễn Bích',
                            value: '17446',
                        },
                        {
                            lable: 'Xã Diễn Cát',
                            value: '17476',
                        },
                        {
                            lable: 'Xã Diễn Hoa',
                            value: '17461',
                        },
                        {
                            lable: 'Xã Diễn Hoàng',
                            value: '17404',
                        },
                        {
                            lable: 'Xã Diễn Hùng',
                            value: '17407',
                        },
                        {
                            lable: 'Xã Diễn Hạnh',
                            value: '17449',
                        },
                        {
                            lable: 'Xã Diễn Hải',
                            value: '17419',
                        },
                        {
                            lable: 'Xã Diễn Hồng',
                            value: '17413',
                        },
                        {
                            lable: 'Xã Diễn Kim',
                            value: '17431',
                        },
                        {
                            lable: 'Xã Diễn Kỷ',
                            value: '17434',
                        },
                        {
                            lable: 'Xã Diễn Liên',
                            value: '17425',
                        },
                        {
                            lable: 'Xã Diễn Lâm',
                            value: '17392',
                        },
                        {
                            lable: 'Xã Diễn Lộc',
                            value: '17494',
                        },
                        {
                            lable: 'Xã Diễn Lợi',
                            value: '17491',
                        },
                        {
                            lable: 'Xã Diễn Mỹ',
                            value: '17410',
                        },
                        {
                            lable: 'Xã Diễn Nguyên',
                            value: '17458',
                        },
                        {
                            lable: 'Xã Diễn Ngọc',
                            value: '17452',
                        },
                        {
                            lable: 'Xã Diễn Phong',
                            value: '17416',
                        },
                        {
                            lable: 'Xã Diễn Phú',
                            value: '17503',
                        },
                        {
                            lable: 'Xã Diễn Phúc',
                            value: '17467',
                        },
                        {
                            lable: 'Xã Diễn Quảng',
                            value: '17455',
                        },
                        {
                            lable: 'Xã Diễn Thành',
                            value: '17464',
                        },
                        {
                            lable: 'Xã Diễn Thái',
                            value: '17440',
                        },
                        {
                            lable: 'Xã Diễn Tháp',
                            value: '17422',
                        },
                        {
                            lable: 'Xã Diễn Thịnh',
                            value: '17479',
                        },
                        {
                            lable: 'Xã Diễn Thọ',
                            value: '17488',
                        },
                        {
                            lable: 'Xã Diễn Trung',
                            value: '17497',
                        },
                        {
                            lable: 'Xã Diễn Trường',
                            value: '17398',
                        },
                        {
                            lable: 'Xã Diễn Tân',
                            value: '17482',
                        },
                        {
                            lable: 'Xã Diễn Vạn',
                            value: '17428',
                        },
                        {
                            lable: 'Xã Diễn Xuân',
                            value: '17437',
                        },
                        {
                            lable: 'Xã Diễn Yên',
                            value: '17401',
                        },
                        {
                            lable: 'Xã Diễn Đoài',
                            value: '17395',
                        },
                        {
                            lable: 'Xã Diễn Đồng',
                            value: '17443',
                        },
                        {
                            lable: 'Xã Minh Châu',
                            value: '17485',
                        },
                    ],
                },
                {
                    lable: 'Huyện Yên Thành',
                    value: '426',
                    wards: [
                        {
                            lable: 'Thị trấn Yên Thành',
                            value: '17506',
                        },
                        {
                            lable: 'Xã Bảo Thành',
                            value: '17605',
                        },
                        {
                            lable: 'Xã Bắc Thành',
                            value: '17569',
                        },
                        {
                            lable: 'Xã Công Thành',
                            value: '17611',
                        },
                        {
                            lable: 'Xã Hoa Thành',
                            value: '17551',
                        },
                        {
                            lable: 'Xã Hùng Thành',
                            value: '17525',
                        },
                        {
                            lable: 'Xã Hậu Thành',
                            value: '17524',
                        },
                        {
                            lable: 'Xã Hồng Thành',
                            value: '17542',
                        },
                        {
                            lable: 'Xã Hợp Thành',
                            value: '17563',
                        },
                        {
                            lable: 'Xã Khánh Thành',
                            value: '17593',
                        },
                        {
                            lable: 'Xã Kim Thành',
                            value: '17521',
                        },
                        {
                            lable: 'Xã Liên Thành',
                            value: '17602',
                        },
                        {
                            lable: 'Xã Long Thành',
                            value: '17578',
                        },
                        {
                            lable: 'Xã Lý Thành',
                            value: '17590',
                        },
                        {
                            lable: 'Xã Lăng Thành',
                            value: '17512',
                        },
                        {
                            lable: 'Xã Minh Thành',
                            value: '17581',
                        },
                        {
                            lable: 'Xã Mã Thành',
                            value: '17509',
                        },
                        {
                            lable: 'Xã Mỹ Thành',
                            value: '17608',
                        },
                        {
                            lable: 'Xã Nam Thành',
                            value: '17584',
                        },
                        {
                            lable: 'Xã Nhân Thành',
                            value: '17572',
                        },
                        {
                            lable: 'Xã Phú Thành',
                            value: '17548',
                        },
                        {
                            lable: 'Xã Phúc Thành',
                            value: '17539',
                        },
                        {
                            lable: 'Xã Quang Thành',
                            value: '17533',
                        },
                        {
                            lable: 'Xã Sơn Thành',
                            value: '17614',
                        },
                        {
                            lable: 'Xã Thịnh Thành',
                            value: '17560',
                        },
                        {
                            lable: 'Xã Thọ Thành',
                            value: '17530',
                        },
                        {
                            lable: 'Xã Tiến Thành',
                            value: '17510',
                        },
                        {
                            lable: 'Xã Trung Thành',
                            value: '17575',
                        },
                        {
                            lable: 'Xã Tân Thành',
                            value: '17515',
                        },
                        {
                            lable: 'Xã Tây Thành',
                            value: '17536',
                        },
                        {
                            lable: 'Xã Tăng Thành',
                            value: '17554',
                        },
                        {
                            lable: 'Xã Viên Thành',
                            value: '17596',
                        },
                        {
                            lable: 'Xã Văn Thành',
                            value: '17557',
                        },
                        {
                            lable: 'Xã Vĩnh Thành',
                            value: '17587',
                        },
                        {
                            lable: 'Xã Xuân Thành',
                            value: '17566',
                        },
                        {
                            lable: 'Xã Đô Thành',
                            value: '17527',
                        },
                        {
                            lable: 'Xã Đại Thành',
                            value: '17599',
                        },
                        {
                            lable: 'Xã Đồng Thành',
                            value: '17545',
                        },
                        {
                            lable: 'Xã Đức Thành',
                            value: '17518',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đô Lương',
                    value: '427',
                    wards: [
                        {
                            lable: 'Thị trấn Đô Lương',
                            value: '17617',
                        },
                        {
                            lable: 'Xã Bài Sơn',
                            value: '17632',
                        },
                        {
                            lable: 'Xã Bắc Sơn',
                            value: '17638',
                        },
                        {
                            lable: 'Xã Bồi Sơn',
                            value: '17626',
                        },
                        {
                            lable: 'Xã Giang Sơn Tây',
                            value: '17620',
                        },
                        {
                            lable: 'Xã Giang Sơn Đông',
                            value: '17619',
                        },
                        {
                            lable: 'Xã Hiến Sơn',
                            value: '17701',
                        },
                        {
                            lable: 'Xã Hòa Sơn',
                            value: '17647',
                        },
                        {
                            lable: 'Xã Hồng Sơn',
                            value: '17629',
                        },
                        {
                            lable: 'Xã Lam Sơn',
                            value: '17623',
                        },
                        {
                            lable: 'Xã Lưu Sơn',
                            value: '17659',
                        },
                        {
                            lable: 'Xã Lạc Sơn',
                            value: '17671',
                        },
                        {
                            lable: 'Xã Minh Sơn',
                            value: '17692',
                        },
                        {
                            lable: 'Xã Mỹ Sơn',
                            value: '17704',
                        },
                        {
                            lable: 'Xã Nam Sơn',
                            value: '17656',
                        },
                        {
                            lable: 'Xã Ngọc Sơn',
                            value: '17635',
                        },
                        {
                            lable: 'Xã Nhân Sơn',
                            value: '17698',
                        },
                        {
                            lable: 'Xã Quang Sơn',
                            value: '17680',
                        },
                        {
                            lable: 'Xã Thuận Sơn',
                            value: '17695',
                        },
                        {
                            lable: 'Xã Thái Sơn',
                            value: '17677',
                        },
                        {
                            lable: 'Xã Thượng Sơn',
                            value: '17644',
                        },
                        {
                            lable: 'Xã Thịnh Sơn',
                            value: '17683',
                        },
                        {
                            lable: 'Xã Trung Sơn',
                            value: '17686',
                        },
                        {
                            lable: 'Xã Tràng Sơn',
                            value: '17641',
                        },
                        {
                            lable: 'Xã Trù Sơn',
                            value: '17707',
                        },
                        {
                            lable: 'Xã Tân Sơn',
                            value: '17674',
                        },
                        {
                            lable: 'Xã Văn Sơn',
                            value: '17665',
                        },
                        {
                            lable: 'Xã Xuân Sơn',
                            value: '17689',
                        },
                        {
                            lable: 'Xã Yên Sơn',
                            value: '17662',
                        },
                        {
                            lable: 'Xã Đà Sơn',
                            value: '17668',
                        },
                        {
                            lable: 'Xã Đông Sơn',
                            value: '17653',
                        },
                        {
                            lable: 'Xã Đại Sơn',
                            value: '17710',
                        },
                        {
                            lable: 'Xã Đặng Sơn',
                            value: '17650',
                        },
                    ],
                },
                {
                    lable: 'Huyện Thanh Chương',
                    value: '428',
                    wards: [
                        {
                            lable: 'Thị trấn Thanh Chương',
                            value: '17713',
                        },
                        {
                            lable: 'Xã Cát Văn',
                            value: '17716',
                        },
                        {
                            lable: 'Xã Hạnh Lâm',
                            value: '17722',
                        },
                        {
                            lable: 'Xã Ngọc Lâm',
                            value: '17759',
                        },
                        {
                            lable: 'Xã Ngọc Sơn',
                            value: '17767',
                        },
                        {
                            lable: 'Xã Phong Thịnh',
                            value: '17728',
                        },
                        {
                            lable: 'Xã Thanh An',
                            value: '17773',
                        },
                        {
                            lable: 'Xã Thanh Chi',
                            value: '17776',
                        },
                        {
                            lable: 'Xã Thanh Dương',
                            value: '17782',
                        },
                        {
                            lable: 'Xã Thanh Giang',
                            value: '17809',
                        },
                        {
                            lable: 'Xã Thanh Hà',
                            value: '17806',
                        },
                        {
                            lable: 'Xã Thanh Hòa',
                            value: '17725',
                        },
                        {
                            lable: 'Xã Thanh Hương',
                            value: '17758',
                        },
                        {
                            lable: 'Xã Thanh Khai',
                            value: '17800',
                        },
                        {
                            lable: 'Xã Thanh Khê',
                            value: '17788',
                        },
                        {
                            lable: 'Xã Thanh Liên',
                            value: '17743',
                        },
                        {
                            lable: 'Xã Thanh Long',
                            value: '17794',
                        },
                        {
                            lable: 'Xã Thanh Lâm',
                            value: '17815',
                        },
                        {
                            lable: 'Xã Thanh Lĩnh',
                            value: '17761',
                        },
                        {
                            lable: 'Xã Thanh Lương',
                            value: '17785',
                        },
                        {
                            lable: 'Xã Thanh Mai',
                            value: '17818',
                        },
                        {
                            lable: 'Xã Thanh Mỹ',
                            value: '17734',
                        },
                        {
                            lable: 'Xã Thanh Ngọc',
                            value: '17755',
                        },
                        {
                            lable: 'Xã Thanh Nho',
                            value: '17719',
                        },
                        {
                            lable: 'Xã Thanh Phong',
                            value: '17731',
                        },
                        {
                            lable: 'Xã Thanh Sơn',
                            value: '17723',
                        },
                        {
                            lable: 'Xã Thanh Thịnh',
                            value: '17770',
                        },
                        {
                            lable: 'Xã Thanh Thủy',
                            value: '17797',
                        },
                        {
                            lable: 'Xã Thanh Tiên',
                            value: '17737',
                        },
                        {
                            lable: 'Xã Thanh Tùng',
                            value: '17812',
                        },
                        {
                            lable: 'Xã Thanh Xuân',
                            value: '17821',
                        },
                        {
                            lable: 'Xã Thanh Yên',
                            value: '17803',
                        },
                        {
                            lable: 'Xã Thanh Đồng',
                            value: '17752',
                        },
                        {
                            lable: 'Xã Thanh Đức',
                            value: '17824',
                        },
                        {
                            lable: 'Xã Võ Liệt',
                            value: '17791',
                        },
                        {
                            lable: 'Xã Xuân Tường',
                            value: '17779',
                        },
                        {
                            lable: 'Xã Đại Đồng',
                            value: '17749',
                        },
                        {
                            lable: 'Xã Đồng Văn',
                            value: '17764',
                        },
                    ],
                },
                {
                    lable: 'Huyện Nghi Lộc',
                    value: '429',
                    wards: [
                        {
                            lable: 'Thị trấn Quán Hành',
                            value: '17827',
                        },
                        {
                            lable: 'Xã Khánh Hợp',
                            value: '17878',
                        },
                        {
                            lable: 'Xã Nghi Công Bắc',
                            value: '17884',
                        },
                        {
                            lable: 'Xã Nghi Công Nam',
                            value: '17887',
                        },
                        {
                            lable: 'Xã Nghi Diên',
                            value: '17899',
                        },
                        {
                            lable: 'Xã Nghi Hoa',
                            value: '17875',
                        },
                        {
                            lable: 'Xã Nghi Hưng',
                            value: '17839',
                        },
                        {
                            lable: 'Xã Nghi Kiều',
                            value: '17854',
                        },
                        {
                            lable: 'Xã Nghi Long',
                            value: '17866',
                        },
                        {
                            lable: 'Xã Nghi Lâm',
                            value: '17848',
                        },
                        {
                            lable: 'Xã Nghi Mỹ',
                            value: '17857',
                        },
                        {
                            lable: 'Xã Nghi Phong',
                            value: '17902',
                        },
                        {
                            lable: 'Xã Nghi Phương',
                            value: '17860',
                        },
                        {
                            lable: 'Xã Nghi Quang',
                            value: '17851',
                        },
                        {
                            lable: 'Xã Nghi Thiết',
                            value: '17845',
                        },
                        {
                            lable: 'Xã Nghi Thuận',
                            value: '17863',
                        },
                        {
                            lable: 'Xã Nghi Thái',
                            value: '17926',
                        },
                        {
                            lable: 'Xã Nghi Thạch',
                            value: '17890',
                        },
                        {
                            lable: 'Xã Nghi Thịnh',
                            value: '17881',
                        },
                        {
                            lable: 'Xã Nghi Tiến',
                            value: '17836',
                        },
                        {
                            lable: 'Xã Nghi Trung',
                            value: '17893',
                        },
                        {
                            lable: 'Xã Nghi Trường',
                            value: '17896',
                        },
                        {
                            lable: 'Xã Nghi Văn',
                            value: '17830',
                        },
                        {
                            lable: 'Xã Nghi Vạn',
                            value: '17911',
                        },
                        {
                            lable: 'Xã Nghi Xuân',
                            value: '17905',
                        },
                        {
                            lable: 'Xã Nghi Xá',
                            value: '17869',
                        },
                        {
                            lable: 'Xã Nghi Yên',
                            value: '17833',
                        },
                        {
                            lable: 'Xã Nghi Đồng',
                            value: '17842',
                        },
                        {
                            lable: 'Xã Phúc Thọ',
                            value: '17917',
                        },
                    ],
                },
                {
                    lable: 'Huyện Nam Đàn',
                    value: '430',
                    wards: [
                        {
                            lable: 'Thị trấn Nam Đàn',
                            value: '17950',
                        },
                        {
                            lable: 'Xã Hùng Tiến',
                            value: '17962',
                        },
                        {
                            lable: 'Xã Hồng Long',
                            value: '17977',
                        },
                        {
                            lable: 'Xã Khánh Sơn',
                            value: '17986',
                        },
                        {
                            lable: 'Xã Kim Liên',
                            value: '17971',
                        },
                        {
                            lable: 'Xã Nam Anh',
                            value: '17941',
                        },
                        {
                            lable: 'Xã Nam Cát',
                            value: '17983',
                        },
                        {
                            lable: 'Xã Nam Giang',
                            value: '17956',
                        },
                        {
                            lable: 'Xã Nam Hưng',
                            value: '17932',
                        },
                        {
                            lable: 'Xã Nam Kim',
                            value: '17998',
                        },
                        {
                            lable: 'Xã Nam Lĩnh',
                            value: '17953',
                        },
                        {
                            lable: 'Xã Nam Nghĩa',
                            value: '17935',
                        },
                        {
                            lable: 'Xã Nam Thanh',
                            value: '17938',
                        },
                        {
                            lable: 'Xã Nam Thái',
                            value: '17947',
                        },
                        {
                            lable: 'Xã Nam Xuân',
                            value: '17944',
                        },
                        {
                            lable: 'Xã Thượng Tân Lộc',
                            value: '17968',
                        },
                        {
                            lable: 'Xã Trung Phúc Cường',
                            value: '17989',
                        },
                        {
                            lable: 'Xã Xuân Hòa',
                            value: '17959',
                        },
                        {
                            lable: 'Xã Xuân Lâm',
                            value: '17980',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hưng Nguyên',
                    value: '431',
                    wards: [
                        {
                            lable: 'Thị trấn Hưng Nguyên',
                            value: '18001',
                        },
                        {
                            lable: 'Xã Châu Nhân',
                            value: '18052',
                        },
                        {
                            lable: 'Xã Hưng Lĩnh',
                            value: '18025',
                        },
                        {
                            lable: 'Xã Hưng Lợi',
                            value: '18034',
                        },
                        {
                            lable: 'Xã Hưng Mỹ',
                            value: '18019',
                        },
                        {
                            lable: 'Xã Hưng Nghĩa',
                            value: '18037',
                        },
                        {
                            lable: 'Xã Hưng Phúc',
                            value: '18040',
                        },
                        {
                            lable: 'Xã Hưng Thành',
                            value: '18064',
                        },
                        {
                            lable: 'Xã Hưng Thông',
                            value: '18028',
                        },
                        {
                            lable: 'Xã Hưng Thịnh',
                            value: '18022',
                        },
                        {
                            lable: 'Xã Hưng Trung',
                            value: '18004',
                        },
                        {
                            lable: 'Xã Hưng Tân',
                            value: '18031',
                        },
                        {
                            lable: 'Xã Hưng Tây',
                            value: '18010',
                        },
                        {
                            lable: 'Xã Hưng Yên',
                            value: '18007',
                        },
                        {
                            lable: 'Xã Hưng Yên Bắc',
                            value: '18008',
                        },
                        {
                            lable: 'Xã Hưng Đạo',
                            value: '18016',
                        },
                        {
                            lable: 'Xã Long Xá',
                            value: '18043',
                        },
                        {
                            lable: 'Xã Xuân Lam',
                            value: '18055',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Hoàng Mai',
                    value: '432',
                    wards: [
                        {
                            lable: 'Phường Mai Hùng',
                            value: '17125',
                        },
                        {
                            lable: 'Phường Quỳnh Dị',
                            value: '17128',
                        },
                        {
                            lable: 'Phường Quỳnh Phương',
                            value: '17134',
                        },
                        {
                            lable: 'Phường Quỳnh Thiện',
                            value: '17110',
                        },
                        {
                            lable: 'Phường Quỳnh Xuân',
                            value: '17131',
                        },
                        {
                            lable: 'Xã Quỳnh Liên',
                            value: '17137',
                        },
                        {
                            lable: 'Xã Quỳnh Lập',
                            value: '17113',
                        },
                        {
                            lable: 'Xã Quỳnh Lộc',
                            value: '17107',
                        },
                        {
                            lable: 'Xã Quỳnh Trang',
                            value: '17116',
                        },
                        {
                            lable: 'Xã Quỳnh Vinh',
                            value: '17104',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Ninh Bình',
            value: '37',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Ninh Bình',
                    value: '369',
                    wards: [
                        {
                            lable: 'Phường Bích Đào',
                            value: '14332',
                        },
                        {
                            lable: 'Phường Nam Bình',
                            value: '14338',
                        },
                        {
                            lable: 'Phường Nam Thành',
                            value: '14341',
                        },
                        {
                            lable: 'Phường Ninh Khánh',
                            value: '14344',
                        },
                        {
                            lable: 'Phường Ninh Phong',
                            value: '14359',
                        },
                        {
                            lable: 'Phường Ninh Sơn',
                            value: '14356',
                        },
                        {
                            lable: 'Phường Phúc Thành',
                            value: '14335',
                        },
                        {
                            lable: 'Phường Thanh Bình',
                            value: '14326',
                        },
                        {
                            lable: 'Phường Tân Thành',
                            value: '14323',
                        },
                        {
                            lable: 'Phường Vân Giang',
                            value: '14329',
                        },
                        {
                            lable: 'Phường Đông Thành',
                            value: '14320',
                        },
                        {
                            lable: 'Xã Ninh Nhất',
                            value: '14347',
                        },
                        {
                            lable: 'Xã Ninh Phúc',
                            value: '14353',
                        },
                        {
                            lable: 'Xã Ninh Tiến',
                            value: '14350',
                        },
                    ],
                },
                {
                    lable: 'Thành phố Tam Điệp',
                    value: '370',
                    wards: [
                        {
                            lable: 'Phường Bắc Sơn',
                            value: '14362',
                        },
                        {
                            lable: 'Phường Nam Sơn',
                            value: '14368',
                        },
                        {
                            lable: 'Phường Trung Sơn',
                            value: '14365',
                        },
                        {
                            lable: 'Phường Tân Bình',
                            value: '14375',
                        },
                        {
                            lable: 'Phường Tây Sơn',
                            value: '14369',
                        },
                        {
                            lable: 'Phường Yên Bình',
                            value: '14374',
                        },
                        {
                            lable: 'Xã Quang Sơn',
                            value: '14377',
                        },
                        {
                            lable: 'Xã Yên Sơn',
                            value: '14371',
                        },
                        {
                            lable: 'Xã Đông Sơn',
                            value: '14380',
                        },
                    ],
                },
                {
                    lable: 'Huyện Nho Quan',
                    value: '372',
                    wards: [
                        {
                            lable: 'Thị trấn Nho Quan',
                            value: '14383',
                        },
                        {
                            lable: 'Xã Cúc Phương',
                            value: '14404',
                        },
                        {
                            lable: 'Xã Gia Lâm',
                            value: '14389',
                        },
                        {
                            lable: 'Xã Gia Sơn',
                            value: '14392',
                        },
                        {
                            lable: 'Xã Gia Thủy',
                            value: '14398',
                        },
                        {
                            lable: 'Xã Gia Tường',
                            value: '14401',
                        },
                        {
                            lable: 'Xã Kỳ Phú',
                            value: '14449',
                        },
                        {
                            lable: 'Xã Lạc Vân',
                            value: '14413',
                        },
                        {
                            lable: 'Xã Lạng Phong',
                            value: '14422',
                        },
                        {
                            lable: 'Xã Phú Long',
                            value: '14458',
                        },
                        {
                            lable: 'Xã Phú Lộc',
                            value: '14446',
                        },
                        {
                            lable: 'Xã Phú Sơn',
                            value: '14407',
                        },
                        {
                            lable: 'Xã Quảng Lạc',
                            value: '14461',
                        },
                        {
                            lable: 'Xã Quỳnh Lưu',
                            value: '14452',
                        },
                        {
                            lable: 'Xã Sơn Hà',
                            value: '14455',
                        },
                        {
                            lable: 'Xã Sơn Lai',
                            value: '14437',
                        },
                        {
                            lable: 'Xã Sơn Thành',
                            value: '14440',
                        },
                        {
                            lable: 'Xã Thanh Lạc',
                            value: '14434',
                        },
                        {
                            lable: 'Xã Thượng Hòa',
                            value: '14425',
                        },
                        {
                            lable: 'Xã Thạch Bình',
                            value: '14395',
                        },
                        {
                            lable: 'Xã Văn Phong',
                            value: '14428',
                        },
                        {
                            lable: 'Xã Văn Phú',
                            value: '14443',
                        },
                        {
                            lable: 'Xã Văn Phương',
                            value: '14431',
                        },
                        {
                            lable: 'Xã Xích Thổ',
                            value: '14386',
                        },
                        {
                            lable: 'Xã Yên Quang',
                            value: '14419',
                        },
                        {
                            lable: 'Xã Đồng Phong',
                            value: '14416',
                        },
                        {
                            lable: 'Xã Đức Long',
                            value: '14410',
                        },
                    ],
                },
                {
                    lable: 'Huyện Gia Viễn',
                    value: '373',
                    wards: [
                        {
                            lable: 'Thị trấn Me',
                            value: '14464',
                        },
                        {
                            lable: 'Xã Gia Hòa',
                            value: '14467',
                        },
                        {
                            lable: 'Xã Gia Hưng',
                            value: '14470',
                        },
                        {
                            lable: 'Xã Gia Lạc',
                            value: '14515',
                        },
                        {
                            lable: 'Xã Gia Lập',
                            value: '14488',
                        },
                        {
                            lable: 'Xã Gia Minh',
                            value: '14512',
                        },
                        {
                            lable: 'Xã Gia Phong',
                            value: '14524',
                        },
                        {
                            lable: 'Xã Gia Phú',
                            value: '14482',
                        },
                        {
                            lable: 'Xã Gia Phương',
                            value: '14500',
                        },
                        {
                            lable: 'Xã Gia Sinh',
                            value: '14521',
                        },
                        {
                            lable: 'Xã Gia Thanh',
                            value: '14476',
                        },
                        {
                            lable: 'Xã Gia Thắng',
                            value: '14506',
                        },
                        {
                            lable: 'Xã Gia Thịnh',
                            value: '14497',
                        },
                        {
                            lable: 'Xã Gia Tiến',
                            value: '14518',
                        },
                        {
                            lable: 'Xã Gia Trung',
                            value: '14509',
                        },
                        {
                            lable: 'Xã Gia Trấn',
                            value: '14494',
                        },
                        {
                            lable: 'Xã Gia Tân',
                            value: '14503',
                        },
                        {
                            lable: 'Xã Gia Vân',
                            value: '14479',
                        },
                        {
                            lable: 'Xã Gia Vượng',
                            value: '14491',
                        },
                        {
                            lable: 'Xã Gia Xuân',
                            value: '14485',
                        },
                        {
                            lable: 'Xã Liên Sơn',
                            value: '14473',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hoa Lư',
                    value: '374',
                    wards: [
                        {
                            lable: 'Thị trấn Thiên Tôn',
                            value: '14527',
                        },
                        {
                            lable: 'Xã Ninh An',
                            value: '14557',
                        },
                        {
                            lable: 'Xã Ninh Giang',
                            value: '14530',
                        },
                        {
                            lable: 'Xã Ninh Hòa',
                            value: '14542',
                        },
                        {
                            lable: 'Xã Ninh Hải',
                            value: '14548',
                        },
                        {
                            lable: 'Xã Ninh Khang',
                            value: '14536',
                        },
                        {
                            lable: 'Xã Ninh Mỹ',
                            value: '14539',
                        },
                        {
                            lable: 'Xã Ninh Thắng',
                            value: '14551',
                        },
                        {
                            lable: 'Xã Ninh Vân',
                            value: '14554',
                        },
                        {
                            lable: 'Xã Ninh Xuân',
                            value: '14545',
                        },
                        {
                            lable: 'Xã Trường Yên',
                            value: '14533',
                        },
                    ],
                },
                {
                    lable: 'Huyện Yên Khánh',
                    value: '375',
                    wards: [
                        {
                            lable: 'Thị trấn Yên Ninh',
                            value: '14560',
                        },
                        {
                            lable: 'Xã Khánh An',
                            value: '14575',
                        },
                        {
                            lable: 'Xã Khánh Công',
                            value: '14602',
                        },
                        {
                            lable: 'Xã Khánh Cư',
                            value: '14581',
                        },
                        {
                            lable: 'Xã Khánh Cường',
                            value: '14578',
                        },
                        {
                            lable: 'Xã Khánh Hòa',
                            value: '14569',
                        },
                        {
                            lable: 'Xã Khánh Hải',
                            value: '14587',
                        },
                        {
                            lable: 'Xã Khánh Hồng',
                            value: '14617',
                        },
                        {
                            lable: 'Xã Khánh Hội',
                            value: '14599',
                        },
                        {
                            lable: 'Xã Khánh Lợi',
                            value: '14572',
                        },
                        {
                            lable: 'Xã Khánh Mậu',
                            value: '14593',
                        },
                        {
                            lable: 'Xã Khánh Nhạc',
                            value: '14611',
                        },
                        {
                            lable: 'Xã Khánh Phú',
                            value: '14566',
                        },
                        {
                            lable: 'Xã Khánh Thiện',
                            value: '14584',
                        },
                        {
                            lable: 'Xã Khánh Thành',
                            value: '14608',
                        },
                        {
                            lable: 'Xã Khánh Thủy',
                            value: '14614',
                        },
                        {
                            lable: 'Xã Khánh Tiên',
                            value: '14563',
                        },
                        {
                            lable: 'Xã Khánh Trung',
                            value: '14590',
                        },
                        {
                            lable: 'Xã Khánh Vân',
                            value: '14596',
                        },
                    ],
                },
                {
                    lable: 'Huyện Kim Sơn',
                    value: '376',
                    wards: [
                        {
                            lable: 'Thị trấn Bình Minh',
                            value: '14623',
                        },
                        {
                            lable: 'Thị trấn Phát Diệm',
                            value: '14620',
                        },
                        {
                            lable: 'Xã Chất Bình',
                            value: '14653',
                        },
                        {
                            lable: 'Xã Cồn Thoi',
                            value: '14689',
                        },
                        {
                            lable: 'Xã Hùng Tiến',
                            value: '14641',
                        },
                        {
                            lable: 'Xã Hồi Ninh',
                            value: '14629',
                        },
                        {
                            lable: 'Xã Kim Chính',
                            value: '14659',
                        },
                        {
                            lable: 'Xã Kim Hải',
                            value: '14692',
                        },
                        {
                            lable: 'Xã Kim Mỹ',
                            value: '14686',
                        },
                        {
                            lable: 'Xã Kim Trung',
                            value: '14695',
                        },
                        {
                            lable: 'Xã Kim Tân',
                            value: '14683',
                        },
                        {
                            lable: 'Xã Kim Đông',
                            value: '14698',
                        },
                        {
                            lable: 'Xã Kim Định',
                            value: '14635',
                        },
                        {
                            lable: 'Xã Lai Thành',
                            value: '14674',
                        },
                        {
                            lable: 'Xã Lưu Phương',
                            value: '14665',
                        },
                        {
                            lable: 'Xã Như Hòa',
                            value: '14650',
                        },
                        {
                            lable: 'Xã Quang Thiện',
                            value: '14647',
                        },
                        {
                            lable: 'Xã Thượng Kiệm',
                            value: '14662',
                        },
                        {
                            lable: 'Xã Tân Thành',
                            value: '14668',
                        },
                        {
                            lable: 'Xã Văn Hải',
                            value: '14680',
                        },
                        {
                            lable: 'Xã Xuân Chính',
                            value: '14632',
                        },
                        {
                            lable: 'Xã Yên Lộc',
                            value: '14671',
                        },
                        {
                            lable: 'Xã Ân Hòa',
                            value: '14638',
                        },
                        {
                            lable: 'Xã Định Hóa',
                            value: '14677',
                        },
                        {
                            lable: 'Xã Đồng Hướng',
                            value: '14656',
                        },
                    ],
                },
                {
                    lable: 'Huyện Yên Mô',
                    value: '377',
                    wards: [
                        {
                            lable: 'Thị trấn Yên Thịnh',
                            value: '14701',
                        },
                        {
                            lable: 'Xã Khánh Dương',
                            value: '14707',
                        },
                        {
                            lable: 'Xã Khánh Thượng',
                            value: '14704',
                        },
                        {
                            lable: 'Xã Khánh Thịnh',
                            value: '14713',
                        },
                        {
                            lable: 'Xã Mai Sơn',
                            value: '14710',
                        },
                        {
                            lable: 'Xã Yên Hòa',
                            value: '14722',
                        },
                        {
                            lable: 'Xã Yên Hưng',
                            value: '14731',
                        },
                        {
                            lable: 'Xã Yên Lâm',
                            value: '14752',
                        },
                        {
                            lable: 'Xã Yên Mạc',
                            value: '14743',
                        },
                        {
                            lable: 'Xã Yên Mỹ',
                            value: '14740',
                        },
                        {
                            lable: 'Xã Yên Nhân',
                            value: '14737',
                        },
                        {
                            lable: 'Xã Yên Phong',
                            value: '14719',
                        },
                        {
                            lable: 'Xã Yên Thành',
                            value: '14734',
                        },
                        {
                            lable: 'Xã Yên Thái',
                            value: '14749',
                        },
                        {
                            lable: 'Xã Yên Thắng',
                            value: '14725',
                        },
                        {
                            lable: 'Xã Yên Từ',
                            value: '14728',
                        },
                        {
                            lable: 'Xã Yên Đồng',
                            value: '14746',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Ninh Thuận',
            value: '58',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Phan Rang-Tháp Chàm',
                    value: '582',
                    wards: [
                        {
                            lable: 'Phường Bảo An',
                            value: '22744',
                        },
                        {
                            lable: 'Phường Kinh Dinh',
                            value: '22759',
                        },
                        {
                            lable: 'Phường Mỹ Bình',
                            value: '22779',
                        },
                        {
                            lable: 'Phường Mỹ Hương',
                            value: '22753',
                        },
                        {
                            lable: 'Phường Mỹ Hải',
                            value: '22780',
                        },
                        {
                            lable: 'Phường Mỹ Đông',
                            value: '22771',
                        },
                        {
                            lable: 'Phường Phước Mỹ',
                            value: '22741',
                        },
                        {
                            lable: 'Phường Phủ Hà',
                            value: '22747',
                        },
                        {
                            lable: 'Phường Thanh Sơn',
                            value: '22750',
                        },
                        {
                            lable: 'Phường Tấn Tài',
                            value: '22756',
                        },
                        {
                            lable: 'Phường Văn Hải',
                            value: '22777',
                        },
                        {
                            lable: 'Phường Đài Sơn',
                            value: '22765',
                        },
                        {
                            lable: 'Phường Đô Vinh',
                            value: '22738',
                        },
                        {
                            lable: 'Phường Đông Hải',
                            value: '22768',
                        },
                        {
                            lable: 'Phường Đạo Long',
                            value: '22762',
                        },
                        {
                            lable: 'Xã Thành Hải',
                            value: '22774',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bác Ái',
                    value: '584',
                    wards: [
                        {
                            lable: 'Xã Phước Bình',
                            value: '22783',
                        },
                        {
                            lable: 'Xã Phước Chính',
                            value: '22804',
                        },
                        {
                            lable: 'Xã Phước Hòa',
                            value: '22786',
                        },
                        {
                            lable: 'Xã Phước Thành',
                            value: '22798',
                        },
                        {
                            lable: 'Xã Phước Thắng',
                            value: '22795',
                        },
                        {
                            lable: 'Xã Phước Tiến',
                            value: '22792',
                        },
                        {
                            lable: 'Xã Phước Trung',
                            value: '22807',
                        },
                        {
                            lable: 'Xã Phước Tân',
                            value: '22789',
                        },
                        {
                            lable: 'Xã Phước Đại',
                            value: '22801',
                        },
                    ],
                },
                {
                    lable: 'Huyện Ninh Sơn',
                    value: '585',
                    wards: [
                        {
                            lable: 'Thị trấn Tân Sơn',
                            value: '22810',
                        },
                        {
                            lable: 'Xã Hòa Sơn',
                            value: '22825',
                        },
                        {
                            lable: 'Xã Lâm Sơn',
                            value: '22813',
                        },
                        {
                            lable: 'Xã Lương Sơn',
                            value: '22816',
                        },
                        {
                            lable: 'Xã Ma Nới',
                            value: '22828',
                        },
                        {
                            lable: 'Xã Mỹ Sơn',
                            value: '22822',
                        },
                        {
                            lable: 'Xã Nhơn Sơn',
                            value: '22831',
                        },
                        {
                            lable: 'Xã Quảng Sơn',
                            value: '22819',
                        },
                    ],
                },
                {
                    lable: 'Huyện Ninh Hải',
                    value: '586',
                    wards: [
                        {
                            lable: 'Thị trấn Khánh Hải',
                            value: '22834',
                        },
                        {
                            lable: 'Xã Hộ Hải',
                            value: '22861',
                        },
                        {
                            lable: 'Xã Nhơn Hải',
                            value: '22867',
                        },
                        {
                            lable: 'Xã Phương Hải',
                            value: '22852',
                        },
                        {
                            lable: 'Xã Thanh Hải',
                            value: '22868',
                        },
                        {
                            lable: 'Xã Tri Hải',
                            value: '22864',
                        },
                        {
                            lable: 'Xã Tân Hải',
                            value: '22855',
                        },
                        {
                            lable: 'Xã Vĩnh Hải',
                            value: '22846',
                        },
                        {
                            lable: 'Xã Xuân Hải',
                            value: '22858',
                        },
                    ],
                },
                {
                    lable: 'Huyện Ninh Phước',
                    value: '587',
                    wards: [
                        {
                            lable: 'Thị trấn Phước Dân',
                            value: '22870',
                        },
                        {
                            lable: 'Xã An Hải',
                            value: '22888',
                        },
                        {
                            lable: 'Xã Phước Hải',
                            value: '22894',
                        },
                        {
                            lable: 'Xã Phước Hậu',
                            value: '22879',
                        },
                        {
                            lable: 'Xã Phước Hữu',
                            value: '22891',
                        },
                        {
                            lable: 'Xã Phước Sơn',
                            value: '22873',
                        },
                        {
                            lable: 'Xã Phước Thuận',
                            value: '22882',
                        },
                        {
                            lable: 'Xã Phước Thái',
                            value: '22876',
                        },
                        {
                            lable: 'Xã Phước Vinh',
                            value: '22912',
                        },
                    ],
                },
                {
                    lable: 'Huyện Thuận Bắc',
                    value: '588',
                    wards: [
                        {
                            lable: 'Xã Bắc Phong',
                            value: '22856',
                        },
                        {
                            lable: 'Xã Bắc Sơn',
                            value: '22853',
                        },
                        {
                            lable: 'Xã Công Hải',
                            value: '22840',
                        },
                        {
                            lable: 'Xã Lợi Hải',
                            value: '22849',
                        },
                        {
                            lable: 'Xã Phước Chiến',
                            value: '22837',
                        },
                        {
                            lable: 'Xã Phước Kháng',
                            value: '22843',
                        },
                    ],
                },
                {
                    lable: 'Huyện Thuận Nam',
                    value: '589',
                    wards: [
                        {
                            lable: 'Xã Cà Ná',
                            value: '22910',
                        },
                        {
                            lable: 'Xã Nhị Hà',
                            value: '22900',
                        },
                        {
                            lable: 'Xã Phước Dinh',
                            value: '22903',
                        },
                        {
                            lable: 'Xã Phước Diêm',
                            value: '22909',
                        },
                        {
                            lable: 'Xã Phước Hà',
                            value: '22885',
                        },
                        {
                            lable: 'Xã Phước Minh',
                            value: '22906',
                        },
                        {
                            lable: 'Xã Phước Nam',
                            value: '22897',
                        },
                        {
                            lable: 'Xã Phước Ninh',
                            value: '22898',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Phú Thọ',
            value: '25',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Việt Trì',
                    value: '227',
                    wards: [
                        {
                            lable: 'Phường Bạch Hạc',
                            value: '07912',
                        },
                        {
                            lable: 'Phường Bến Gót',
                            value: '07915',
                        },
                        {
                            lable: 'Phường Dữu Lâu',
                            value: '07888',
                        },
                        {
                            lable: 'Phường Gia Cẩm',
                            value: '07900',
                        },
                        {
                            lable: 'Phường Minh Nông',
                            value: '07933',
                        },
                        {
                            lable: 'Phường Minh Phương',
                            value: '07927',
                        },
                        {
                            lable: 'Phường Nông Trang',
                            value: '07894',
                        },
                        {
                            lable: 'Phường Thanh Miếu',
                            value: '07909',
                        },
                        {
                            lable: 'Phường Thọ Sơn',
                            value: '07906',
                        },
                        {
                            lable: 'Phường Tiên Cát',
                            value: '07903',
                        },
                        {
                            lable: 'Phường Tân Dân',
                            value: '07897',
                        },
                        {
                            lable: 'Phường Vân Cơ',
                            value: '07891',
                        },
                        {
                            lable: 'Phường Vân Phú',
                            value: '07918',
                        },
                        {
                            lable: 'Xã Chu Hóa',
                            value: '08506',
                        },
                        {
                            lable: 'Xã Hy Cương',
                            value: '08503',
                        },
                        {
                            lable: 'Xã Hùng Lô',
                            value: '08287',
                        },
                        {
                            lable: 'Xã Kim Đức',
                            value: '08281',
                        },
                        {
                            lable: 'Xã Phượng Lâu',
                            value: '07921',
                        },
                        {
                            lable: 'Xã Sông Lô',
                            value: '07936',
                        },
                        {
                            lable: 'Xã Thanh Đình',
                            value: '08515',
                        },
                        {
                            lable: 'Xã Thụy Vân',
                            value: '07924',
                        },
                        {
                            lable: 'Xã Trưng Vương',
                            value: '07930',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Phú Thọ',
                    value: '228',
                    wards: [
                        {
                            lable: 'Phường Hùng Vương',
                            value: '07942',
                        },
                        {
                            lable: 'Phường Phong Châu',
                            value: '07945',
                        },
                        {
                            lable: 'Phường Thanh Vinh',
                            value: '07966',
                        },
                        {
                            lable: 'Phường Âu Cơ',
                            value: '07948',
                        },
                        {
                            lable: 'Xã Hà Lộc',
                            value: '07951',
                        },
                        {
                            lable: 'Xã Hà Thạch',
                            value: '07963',
                        },
                        {
                            lable: 'Xã Phú Hộ',
                            value: '07954',
                        },
                        {
                            lable: 'Xã Thanh Minh',
                            value: '07960',
                        },
                        {
                            lable: 'Xã Văn Lung',
                            value: '07957',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đoan Hùng',
                    value: '230',
                    wards: [
                        {
                            lable: 'Thị trấn Đoan Hùng',
                            value: '07969',
                        },
                        {
                            lable: 'Xã Bằng Doãn',
                            value: '07996',
                        },
                        {
                            lable: 'Xã Bằng Luân',
                            value: '07981',
                        },
                        {
                            lable: 'Xã Ca Đình',
                            value: '08050',
                        },
                        {
                            lable: 'Xã Chân Mộng',
                            value: '08047',
                        },
                        {
                            lable: 'Xã Chí Đám',
                            value: '07999',
                        },
                        {
                            lable: 'Xã Hùng Long',
                            value: '08029',
                        },
                        {
                            lable: 'Xã Hùng Xuyên',
                            value: '07975',
                        },
                        {
                            lable: 'Xã Hợp Nhất',
                            value: '08014',
                        },
                        {
                            lable: 'Xã Minh Lương',
                            value: '07993',
                        },
                        {
                            lable: 'Xã Minh Phú',
                            value: '08044',
                        },
                        {
                            lable: 'Xã Minh Tiến',
                            value: '08041',
                        },
                        {
                            lable: 'Xã Ngọc Quan',
                            value: '08008',
                        },
                        {
                            lable: 'Xã Phú Lâm',
                            value: '07987',
                        },
                        {
                            lable: 'Xã Phúc Lai',
                            value: '08005',
                        },
                        {
                            lable: 'Xã Sóc Đăng',
                            value: '08017',
                        },
                        {
                            lable: 'Xã Tiêu Sơn',
                            value: '08038',
                        },
                        {
                            lable: 'Xã Tây Cốc',
                            value: '08023',
                        },
                        {
                            lable: 'Xã Vân Du',
                            value: '07984',
                        },
                        {
                            lable: 'Xã Vân Đồn',
                            value: '08035',
                        },
                        {
                            lable: 'Xã Vụ Quang',
                            value: '08032',
                        },
                        {
                            lable: 'Xã Yên Kiện',
                            value: '08026',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hạ Hoà',
                    value: '231',
                    wards: [
                        {
                            lable: 'Thị trấn Hạ Hoà',
                            value: '08053',
                        },
                        {
                            lable: 'Xã Bằng Giã',
                            value: '08125',
                        },
                        {
                            lable: 'Xã Gia Điền',
                            value: '08092',
                        },
                        {
                            lable: 'Xã Hiền Lương',
                            value: '08080',
                        },
                        {
                            lable: 'Xã Hà Lương',
                            value: '08065',
                        },
                        {
                            lable: 'Xã Hương Xạ',
                            value: '08104',
                        },
                        {
                            lable: 'Xã Lang Sơn',
                            value: '08122',
                        },
                        {
                            lable: 'Xã Minh Côi',
                            value: '08140',
                        },
                        {
                            lable: 'Xã Minh Hạc',
                            value: '08119',
                        },
                        {
                            lable: 'Xã Phương Viên',
                            value: '08089',
                        },
                        {
                            lable: 'Xã Tứ Hiệp',
                            value: '08071',
                        },
                        {
                            lable: 'Xã Vô Tranh',
                            value: '08131',
                        },
                        {
                            lable: 'Xã Văn Lang',
                            value: '08134',
                        },
                        {
                            lable: 'Xã Vĩnh Chân',
                            value: '08143',
                        },
                        {
                            lable: 'Xã Xuân Áng',
                            value: '08110',
                        },
                        {
                            lable: 'Xã Yên Kỳ',
                            value: '08113',
                        },
                        {
                            lable: 'Xã Yên Luật',
                            value: '08128',
                        },
                        {
                            lable: 'Xã Đan Thượng',
                            value: '08062',
                        },
                        {
                            lable: 'Xã Đại Phạm',
                            value: '08056',
                        },
                        {
                            lable: 'Xã Ấm Hạ',
                            value: '08095',
                        },
                    ],
                },
                {
                    lable: 'Huyện Thanh Ba',
                    value: '232',
                    wards: [
                        {
                            lable: 'Thị trấn Thanh Ba',
                            value: '08152',
                        },
                        {
                            lable: 'Xã Chí Tiên',
                            value: '08206',
                        },
                        {
                            lable: 'Xã Hanh Cù',
                            value: '08164',
                        },
                        {
                            lable: 'Xã Hoàng Cương',
                            value: '08203',
                        },
                        {
                            lable: 'Xã Khải Xuân',
                            value: '08197',
                        },
                        {
                            lable: 'Xã Lương Lỗ',
                            value: '08227',
                        },
                        {
                            lable: 'Xã Mạn Lạn',
                            value: '08200',
                        },
                        {
                            lable: 'Xã Ninh Dân',
                            value: '08179',
                        },
                        {
                            lable: 'Xã Quảng Yên',
                            value: '08173',
                        },
                        {
                            lable: 'Xã Sơn Cương',
                            value: '08215',
                        },
                        {
                            lable: 'Xã Thanh Hà',
                            value: '08218',
                        },
                        {
                            lable: 'Xã Vân Lĩnh',
                            value: '08156',
                        },
                        {
                            lable: 'Xã Võ Lao',
                            value: '08194',
                        },
                        {
                            lable: 'Xã Đông Lĩnh',
                            value: '08158',
                        },
                        {
                            lable: 'Xã Đông Thành',
                            value: '08209',
                        },
                        {
                            lable: 'Xã Đại An',
                            value: '08161',
                        },
                        {
                            lable: 'Xã Đồng Xuân',
                            value: '08170',
                        },
                        {
                            lable: 'Xã Đỗ Sơn',
                            value: '08221',
                        },
                        {
                            lable: 'Xã Đỗ Xuyên',
                            value: '08224',
                        },
                    ],
                },
                {
                    lable: 'Huyện Phù Ninh',
                    value: '233',
                    wards: [
                        {
                            lable: 'Thị trấn Phong Châu',
                            value: '08230',
                        },
                        {
                            lable: 'Xã An Đạo',
                            value: '08272',
                        },
                        {
                            lable: 'Xã Bình Phú',
                            value: '08275',
                        },
                        {
                            lable: 'Xã Bảo Thanh',
                            value: '08254',
                        },
                        {
                            lable: 'Xã Gia Thanh',
                            value: '08260',
                        },
                        {
                            lable: 'Xã Hạ Giáp',
                            value: '08251',
                        },
                        {
                            lable: 'Xã Liên Hoa',
                            value: '08236',
                        },
                        {
                            lable: 'Xã Lệ Mỹ',
                            value: '08234',
                        },
                        {
                            lable: 'Xã Phù Ninh',
                            value: '08278',
                        },
                        {
                            lable: 'Xã Phú Lộc',
                            value: '08257',
                        },
                        {
                            lable: 'Xã Phú Mỹ',
                            value: '08233',
                        },
                        {
                            lable: 'Xã Phú Nham',
                            value: '08266',
                        },
                        {
                            lable: 'Xã Tiên Du',
                            value: '08263',
                        },
                        {
                            lable: 'Xã Tiên Phú',
                            value: '08248',
                        },
                        {
                            lable: 'Xã Trung Giáp',
                            value: '08245',
                        },
                        {
                            lable: 'Xã Trạm Thản',
                            value: '08239',
                        },
                        {
                            lable: 'Xã Trị Quận',
                            value: '08242',
                        },
                    ],
                },
                {
                    lable: 'Huyện Yên Lập',
                    value: '234',
                    wards: [
                        {
                            lable: 'Thị trấn Yên Lập',
                            value: '08290',
                        },
                        {
                            lable: 'Xã Hưng Long',
                            value: '08314',
                        },
                        {
                            lable: 'Xã Lương Sơn',
                            value: '08299',
                        },
                        {
                            lable: 'Xã Minh Hòa',
                            value: '08332',
                        },
                        {
                            lable: 'Xã Mỹ Lung',
                            value: '08293',
                        },
                        {
                            lable: 'Xã Mỹ Lương',
                            value: '08296',
                        },
                        {
                            lable: 'Xã Nga Hoàng',
                            value: '08317',
                        },
                        {
                            lable: 'Xã Ngọc Lập',
                            value: '08335',
                        },
                        {
                            lable: 'Xã Ngọc Đồng',
                            value: '08338',
                        },
                        {
                            lable: 'Xã Phúc Khánh',
                            value: '08329',
                        },
                        {
                            lable: 'Xã Thượng Long',
                            value: '08323',
                        },
                        {
                            lable: 'Xã Trung Sơn',
                            value: '08311',
                        },
                        {
                            lable: 'Xã Xuân An',
                            value: '08302',
                        },
                        {
                            lable: 'Xã Xuân Thủy',
                            value: '08308',
                        },
                        {
                            lable: 'Xã Xuân Viên',
                            value: '08305',
                        },
                        {
                            lable: 'Xã Đồng Lạc',
                            value: '08320',
                        },
                        {
                            lable: 'Xã Đồng Thịnh',
                            value: '08326',
                        },
                    ],
                },
                {
                    lable: 'Huyện Cẩm Khê',
                    value: '235',
                    wards: [
                        {
                            lable: 'Thị trấn Cẩm Khê',
                            value: '08341',
                        },
                        {
                            lable: 'Xã Chương Xá',
                            value: '08413',
                        },
                        {
                            lable: 'Xã Cấp Dẫn',
                            value: '08383',
                        },
                        {
                            lable: 'Xã Hùng Việt',
                            value: '08416',
                        },
                        {
                            lable: 'Xã Hương Lung',
                            value: '08401',
                        },
                        {
                            lable: 'Xã Minh Tân',
                            value: '08353',
                        },
                        {
                            lable: 'Xã Ngô Xá',
                            value: '08350',
                        },
                        {
                            lable: 'Xã Phú Khê',
                            value: '08392',
                        },
                        {
                            lable: 'Xã Phú Lạc',
                            value: '08407',
                        },
                        {
                            lable: 'Xã Phượng Vĩ',
                            value: '08356',
                        },
                        {
                            lable: 'Xã Sơn Tình',
                            value: '08395',
                        },
                        {
                            lable: 'Xã Tam Sơn',
                            value: '08377',
                        },
                        {
                            lable: 'Xã Thụy Liễu',
                            value: '08362',
                        },
                        {
                            lable: 'Xã Tiên Lương',
                            value: '08344',
                        },
                        {
                            lable: 'Xã Tuy Lộc',
                            value: '08347',
                        },
                        {
                            lable: 'Xã Tùng Khê',
                            value: '08374',
                        },
                        {
                            lable: 'Xã Tạ Xá',
                            value: '08404',
                        },
                        {
                            lable: 'Xã Văn Bán',
                            value: '08380',
                        },
                        {
                            lable: 'Xã Văn Khúc',
                            value: '08419',
                        },
                        {
                            lable: 'Xã Xương Thịnh',
                            value: '08389',
                        },
                        {
                            lable: 'Xã Yên Dưỡng',
                            value: '08422',
                        },
                        {
                            lable: 'Xã Yên Tập',
                            value: '08398',
                        },
                        {
                            lable: 'Xã Điêu Lương',
                            value: '08428',
                        },
                        {
                            lable: 'Xã Đồng Lương',
                            value: '08431',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tam Nông',
                    value: '236',
                    wards: [
                        {
                            lable: 'Thị trấn Hưng Hoá',
                            value: '08434',
                        },
                        {
                            lable: 'Xã Bắc Sơn',
                            value: '08443',
                        },
                        {
                            lable: 'Xã Dân Quyền',
                            value: '08491',
                        },
                        {
                            lable: 'Xã Dị Nậu',
                            value: '08482',
                        },
                        {
                            lable: 'Xã Hiền Quan',
                            value: '08440',
                        },
                        {
                            lable: 'Xã Hương Nộn',
                            value: '08473',
                        },
                        {
                            lable: 'Xã Lam Sơn',
                            value: '08461',
                        },
                        {
                            lable: 'Xã Quang Húc',
                            value: '08470',
                        },
                        {
                            lable: 'Xã Thanh Uyên',
                            value: '08446',
                        },
                        {
                            lable: 'Xã Thọ Văn',
                            value: '08479',
                        },
                        {
                            lable: 'Xã Tề Lễ',
                            value: '08476',
                        },
                        {
                            lable: 'Xã Vạn Xuân',
                            value: '08467',
                        },
                    ],
                },
                {
                    lable: 'Huyện Lâm Thao',
                    value: '237',
                    wards: [
                        {
                            lable: 'Thị trấn Hùng Sơn',
                            value: '08498',
                        },
                        {
                            lable: 'Thị trấn Lâm Thao',
                            value: '08494',
                        },
                        {
                            lable: 'Xã Bản Nguyên',
                            value: '08539',
                        },
                        {
                            lable: 'Xã Cao Xá',
                            value: '08527',
                        },
                        {
                            lable: 'Xã Phùng Nguyên',
                            value: '08521',
                        },
                        {
                            lable: 'Xã Sơn Vi',
                            value: '08518',
                        },
                        {
                            lable: 'Xã Thạch Sơn',
                            value: '08512',
                        },
                        {
                            lable: 'Xã Tiên Kiên',
                            value: '08497',
                        },
                        {
                            lable: 'Xã Tứ Xã',
                            value: '08536',
                        },
                        {
                            lable: 'Xã Vĩnh Lại',
                            value: '08533',
                        },
                        {
                            lable: 'Xã Xuân Huy',
                            value: '08509',
                        },
                        {
                            lable: 'Xã Xuân Lũng',
                            value: '08500',
                        },
                    ],
                },
                {
                    lable: 'Huyện Thanh Sơn',
                    value: '238',
                    wards: [
                        {
                            lable: 'Thị trấn Thanh Sơn',
                            value: '08542',
                        },
                        {
                            lable: 'Xã Cự Thắng',
                            value: '08602',
                        },
                        {
                            lable: 'Xã Cự Đồng',
                            value: '08614',
                        },
                        {
                            lable: 'Xã Giáp Lai',
                            value: '08575',
                        },
                        {
                            lable: 'Xã Hương Cần',
                            value: '08632',
                        },
                        {
                            lable: 'Xã Khả Cửu',
                            value: '08635',
                        },
                        {
                            lable: 'Xã Lương Nha',
                            value: '08653',
                        },
                        {
                            lable: 'Xã Sơn Hùng',
                            value: '08563',
                        },
                        {
                            lable: 'Xã Thượng Cửu',
                            value: '08650',
                        },
                        {
                            lable: 'Xã Thạch Khoán',
                            value: '08587',
                        },
                        {
                            lable: 'Xã Thắng Sơn',
                            value: '08623',
                        },
                        {
                            lable: 'Xã Thục Luyện',
                            value: '08581',
                        },
                        {
                            lable: 'Xã Tinh Nhuệ',
                            value: '08659',
                        },
                        {
                            lable: 'Xã Tân Lập',
                            value: '08641',
                        },
                        {
                            lable: 'Xã Tân Minh',
                            value: '08629',
                        },
                        {
                            lable: 'Xã Tất Thắng',
                            value: '08605',
                        },
                        {
                            lable: 'Xã Võ Miếu',
                            value: '08584',
                        },
                        {
                            lable: 'Xã Văn Miếu',
                            value: '08611',
                        },
                        {
                            lable: 'Xã Yên Lãng',
                            value: '08644',
                        },
                        {
                            lable: 'Xã Yên Lương',
                            value: '08647',
                        },
                        {
                            lable: 'Xã Yên Sơn',
                            value: '08656',
                        },
                        {
                            lable: 'Xã Đông Cửu',
                            value: '08638',
                        },
                        {
                            lable: 'Xã Địch Quả',
                            value: '08572',
                        },
                    ],
                },
                {
                    lable: 'Huyện Thanh Thuỷ',
                    value: '239',
                    wards: [
                        {
                            lable: 'Thị trấn Thanh Thủy',
                            value: '08674',
                        },
                        {
                            lable: 'Xã Bảo Yên',
                            value: '08680',
                        },
                        {
                            lable: 'Xã Hoàng Xá',
                            value: '08689',
                        },
                        {
                            lable: 'Xã Sơn Thủy',
                            value: '08677',
                        },
                        {
                            lable: 'Xã Thạch Đồng',
                            value: '08665',
                        },
                        {
                            lable: 'Xã Tu Vũ',
                            value: '08701',
                        },
                        {
                            lable: 'Xã Tân Phương',
                            value: '08671',
                        },
                        {
                            lable: 'Xã Xuân Lộc',
                            value: '08668',
                        },
                        {
                            lable: 'Xã Đoan Hạ',
                            value: '08683',
                        },
                        {
                            lable: 'Xã Đào Xá',
                            value: '08662',
                        },
                        {
                            lable: 'Xã Đồng Trung',
                            value: '08686',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tân Sơn',
                    value: '240',
                    wards: [
                        {
                            lable: 'Xã Kim Thượng',
                            value: '08617',
                        },
                        {
                            lable: 'Xã Kiệt Sơn',
                            value: '08554',
                        },
                        {
                            lable: 'Xã Lai Đồng',
                            value: '08560',
                        },
                        {
                            lable: 'Xã Long Cốc',
                            value: '08608',
                        },
                        {
                            lable: 'Xã Minh Đài',
                            value: '08593',
                        },
                        {
                            lable: 'Xã Mỹ Thuận',
                            value: '08569',
                        },
                        {
                            lable: 'Xã Tam Thanh',
                            value: '08620',
                        },
                        {
                            lable: 'Xã Thu Cúc',
                            value: '08545',
                        },
                        {
                            lable: 'Xã Thu Ngạc',
                            value: '08551',
                        },
                        {
                            lable: 'Xã Thạch Kiệt',
                            value: '08548',
                        },
                        {
                            lable: 'Xã Tân Phú',
                            value: '08566',
                        },
                        {
                            lable: 'Xã Tân Sơn',
                            value: '08578',
                        },
                        {
                            lable: 'Xã Vinh Tiền',
                            value: '08626',
                        },
                        {
                            lable: 'Xã Văn Luông',
                            value: '08596',
                        },
                        {
                            lable: 'Xã Xuân Sơn',
                            value: '08599',
                        },
                        {
                            lable: 'Xã Xuân Đài',
                            value: '08590',
                        },
                        {
                            lable: 'Xã Đồng Sơn',
                            value: '08557',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Phú Yên',
            value: '54',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Tuy Hoà',
                    value: '555',
                    wards: [
                        {
                            lable: 'Phường 1',
                            value: '22015',
                        },
                        {
                            lable: 'Phường 2',
                            value: '22021',
                        },
                        {
                            lable: 'Phường 3',
                            value: '22027',
                        },
                        {
                            lable: 'Phường 4',
                            value: '22030',
                        },
                        {
                            lable: 'Phường 5',
                            value: '22033',
                        },
                        {
                            lable: 'Phường 6',
                            value: '22039',
                        },
                        {
                            lable: 'Phường 7',
                            value: '22036',
                        },
                        {
                            lable: 'Phường 8',
                            value: '22018',
                        },
                        {
                            lable: 'Phường 9',
                            value: '22024',
                        },
                        {
                            lable: 'Phường Phú Lâm',
                            value: '22240',
                        },
                        {
                            lable: 'Phường Phú Thạnh',
                            value: '22040',
                        },
                        {
                            lable: 'Phường Phú Đông',
                            value: '22041',
                        },
                        {
                            lable: 'Xã An Phú',
                            value: '22162',
                        },
                        {
                            lable: 'Xã Bình Kiến',
                            value: '22045',
                        },
                        {
                            lable: 'Xã Bình Ngọc',
                            value: '22048',
                        },
                        {
                            lable: 'Xã Hòa Kiến',
                            value: '22042',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Sông Cầu',
                    value: '557',
                    wards: [
                        {
                            lable: 'Phường Xuân Phú',
                            value: '22051',
                        },
                        {
                            lable: 'Phường Xuân Thành',
                            value: '22053',
                        },
                        {
                            lable: 'Phường Xuân Yên',
                            value: '22073',
                        },
                        {
                            lable: 'Phường Xuân Đài',
                            value: '22076',
                        },
                        {
                            lable: 'Xã Xuân Bình',
                            value: '22060',
                        },
                        {
                            lable: 'Xã Xuân Cảnh',
                            value: '22066',
                        },
                        {
                            lable: 'Xã Xuân Hải',
                            value: '22054',
                        },
                        {
                            lable: 'Xã Xuân Lâm',
                            value: '22052',
                        },
                        {
                            lable: 'Xã Xuân Lộc',
                            value: '22057',
                        },
                        {
                            lable: 'Xã Xuân Phương',
                            value: '22072',
                        },
                        {
                            lable: 'Xã Xuân Thịnh',
                            value: '22069',
                        },
                        {
                            lable: 'Xã Xuân Thọ 1',
                            value: '22075',
                        },
                        {
                            lable: 'Xã Xuân Thọ 2',
                            value: '22078',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đồng Xuân',
                    value: '558',
                    wards: [
                        {
                            lable: 'Thị trấn La Hai',
                            value: '22081',
                        },
                        {
                            lable: 'Xã Phú Mỡ',
                            value: '22087',
                        },
                        {
                            lable: 'Xã Xuân Long',
                            value: '22093',
                        },
                        {
                            lable: 'Xã Xuân Lãnh',
                            value: '22090',
                        },
                        {
                            lable: 'Xã Xuân Phước',
                            value: '22111',
                        },
                        {
                            lable: 'Xã Xuân Quang 1',
                            value: '22096',
                        },
                        {
                            lable: 'Xã Xuân Quang 2',
                            value: '22102',
                        },
                        {
                            lable: 'Xã Xuân Quang 3',
                            value: '22108',
                        },
                        {
                            lable: 'Xã Xuân Sơn Bắc',
                            value: '22099',
                        },
                        {
                            lable: 'Xã Xuân Sơn Nam',
                            value: '22105',
                        },
                        {
                            lable: 'Xã Đa Lộc',
                            value: '22084',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tuy An',
                    value: '559',
                    wards: [
                        {
                            lable: 'Thị trấn Chí Thạnh',
                            value: '22114',
                        },
                        {
                            lable: 'Xã An Chấn',
                            value: '22156',
                        },
                        {
                            lable: 'Xã An Cư',
                            value: '22138',
                        },
                        {
                            lable: 'Xã An Dân',
                            value: '22117',
                        },
                        {
                            lable: 'Xã An Hiệp',
                            value: '22150',
                        },
                        {
                            lable: 'Xã An Hòa Hải',
                            value: '22147',
                        },
                        {
                            lable: 'Xã An Lĩnh',
                            value: '22144',
                        },
                        {
                            lable: 'Xã An Mỹ',
                            value: '22153',
                        },
                        {
                            lable: 'Xã An Nghiệp',
                            value: '22132',
                        },
                        {
                            lable: 'Xã An Ninh Tây',
                            value: '22120',
                        },
                        {
                            lable: 'Xã An Ninh Đông',
                            value: '22123',
                        },
                        {
                            lable: 'Xã An Thạch',
                            value: '22126',
                        },
                        {
                            lable: 'Xã An Thọ',
                            value: '22159',
                        },
                        {
                            lable: 'Xã An Xuân',
                            value: '22141',
                        },
                        {
                            lable: 'Xã An Định',
                            value: '22129',
                        },
                    ],
                },
                {
                    lable: 'Huyện Sơn Hòa',
                    value: '560',
                    wards: [
                        {
                            lable: 'Thị trấn Củng Sơn',
                            value: '22165',
                        },
                        {
                            lable: 'Xã Cà Lúi',
                            value: '22180',
                        },
                        {
                            lable: 'Xã Eachà Rang',
                            value: '22192',
                        },
                        {
                            lable: 'Xã Krông Pa',
                            value: '22195',
                        },
                        {
                            lable: 'Xã Phước Tân',
                            value: '22168',
                        },
                        {
                            lable: 'Xã Suối Bạc',
                            value: '22198',
                        },
                        {
                            lable: 'Xã Suối Trai',
                            value: '22204',
                        },
                        {
                            lable: 'Xã Sơn Hà',
                            value: '22201',
                        },
                        {
                            lable: 'Xã Sơn Hội',
                            value: '22171',
                        },
                        {
                            lable: 'Xã Sơn Long',
                            value: '22177',
                        },
                        {
                            lable: 'Xã Sơn Nguyên',
                            value: '22189',
                        },
                        {
                            lable: 'Xã Sơn Phước',
                            value: '22183',
                        },
                        {
                            lable: 'Xã Sơn Xuân',
                            value: '22186',
                        },
                        {
                            lable: 'Xã Sơn Định',
                            value: '22174',
                        },
                    ],
                },
                {
                    lable: 'Huyện Sông Hinh',
                    value: '561',
                    wards: [
                        {
                            lable: 'Thị trấn Hai Riêng',
                            value: '22207',
                        },
                        {
                            lable: 'Xã Ea Bá',
                            value: '22216',
                        },
                        {
                            lable: 'Xã Ea Lâm',
                            value: '22210',
                        },
                        {
                            lable: 'Xã EaBar',
                            value: '22225',
                        },
                        {
                            lable: 'Xã EaBia',
                            value: '22228',
                        },
                        {
                            lable: 'Xã EaTrol',
                            value: '22231',
                        },
                        {
                            lable: 'Xã Ealy',
                            value: '22237',
                        },
                        {
                            lable: 'Xã Sông Hinh',
                            value: '22234',
                        },
                        {
                            lable: 'Xã Sơn Giang',
                            value: '22219',
                        },
                        {
                            lable: 'Xã Đức Bình Tây',
                            value: '22213',
                        },
                        {
                            lable: 'Xã Đức Bình Đông',
                            value: '22222',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tây Hoà',
                    value: '562',
                    wards: [
                        {
                            lable: 'Thị trấn Phú Thứ',
                            value: '22255',
                        },
                        {
                            lable: 'Xã Hòa Bình 1',
                            value: '22252',
                        },
                        {
                            lable: 'Xã Hòa Mỹ Tây',
                            value: '22288',
                        },
                        {
                            lable: 'Xã Hòa Mỹ Đông',
                            value: '22285',
                        },
                        {
                            lable: 'Xã Hòa Phong',
                            value: '22264',
                        },
                        {
                            lable: 'Xã Hòa Phú',
                            value: '22270',
                        },
                        {
                            lable: 'Xã Hòa Thịnh',
                            value: '22294',
                        },
                        {
                            lable: 'Xã Hòa Tân Tây',
                            value: '22273',
                        },
                        {
                            lable: 'Xã Hòa Đồng',
                            value: '22276',
                        },
                        {
                            lable: 'Xã Sơn Thành Tây',
                            value: '22249',
                        },
                        {
                            lable: 'Xã Sơn Thành Đông',
                            value: '22250',
                        },
                    ],
                },
                {
                    lable: 'Huyện Phú Hoà',
                    value: '563',
                    wards: [
                        {
                            lable: 'Thị Trấn Phú Hoà',
                            value: '22319',
                        },
                        {
                            lable: 'Xã Hòa An',
                            value: '22315',
                        },
                        {
                            lable: 'Xã Hòa Hội',
                            value: '22309',
                        },
                        {
                            lable: 'Xã Hòa Quang Bắc',
                            value: '22303',
                        },
                        {
                            lable: 'Xã Hòa Quang Nam',
                            value: '22306',
                        },
                        {
                            lable: 'Xã Hòa Thắng',
                            value: '22324',
                        },
                        {
                            lable: 'Xã Hòa Trị',
                            value: '22312',
                        },
                        {
                            lable: 'Xã Hòa Định Tây',
                            value: '22321',
                        },
                        {
                            lable: 'Xã Hòa Định Đông',
                            value: '22318',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Đông Hòa',
                    value: '564',
                    wards: [
                        {
                            lable: 'Phường Hoà Hiệp Trung',
                            value: '22261',
                        },
                        {
                            lable: 'Phường Hoà Vinh',
                            value: '22258',
                        },
                        {
                            lable: 'Phường Hòa Hiệp Bắc',
                            value: '22246',
                        },
                        {
                            lable: 'Phường Hòa Hiệp Nam',
                            value: '22282',
                        },
                        {
                            lable: 'Phường Hòa Xuân Tây',
                            value: '22279',
                        },
                        {
                            lable: 'Xã Hòa Thành',
                            value: '22243',
                        },
                        {
                            lable: 'Xã Hòa Tâm',
                            value: '22297',
                        },
                        {
                            lable: 'Xã Hòa Tân Đông',
                            value: '22267',
                        },
                        {
                            lable: 'Xã Hòa Xuân Nam',
                            value: '22300',
                        },
                        {
                            lable: 'Xã Hòa Xuân Đông',
                            value: '22291',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Quảng Bình',
            value: '44',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành Phố Đồng Hới',
                    value: '450',
                    wards: [
                        {
                            lable: 'Phường Bắc Lý',
                            value: '18859',
                        },
                        {
                            lable: 'Phường Bắc Nghĩa',
                            value: '18877',
                        },
                        {
                            lable: 'Phường Hải Thành',
                            value: '18853',
                        },
                        {
                            lable: 'Phường Nam Lý',
                            value: '18865',
                        },
                        {
                            lable: 'Phường Phú Hải',
                            value: '18874',
                        },
                        {
                            lable: 'Phường Đồng Hải',
                            value: '18868',
                        },
                        {
                            lable: 'Phường Đồng Phú',
                            value: '18856',
                        },
                        {
                            lable: 'Phường Đồng Sơn',
                            value: '18871',
                        },
                        {
                            lable: 'Phường Đức Ninh Đông',
                            value: '18880',
                        },
                        {
                            lable: 'Xã Bảo Ninh',
                            value: '18889',
                        },
                        {
                            lable: 'Xã Lộc Ninh',
                            value: '18886',
                        },
                        {
                            lable: 'Xã Nghĩa Ninh',
                            value: '18892',
                        },
                        {
                            lable: 'Xã Quang Phú',
                            value: '18883',
                        },
                        {
                            lable: 'Xã Thuận Đức',
                            value: '18895',
                        },
                        {
                            lable: 'Xã Đức Ninh',
                            value: '18898',
                        },
                    ],
                },
                {
                    lable: 'Huyện Minh Hóa',
                    value: '452',
                    wards: [
                        {
                            lable: 'Thị trấn Quy Đạt',
                            value: '18901',
                        },
                        {
                            lable: 'Xã Dân Hóa',
                            value: '18904',
                        },
                        {
                            lable: 'Xã Hóa Hợp',
                            value: '18922',
                        },
                        {
                            lable: 'Xã Hóa Phúc',
                            value: '18910',
                        },
                        {
                            lable: 'Xã Hóa Sơn',
                            value: '18937',
                        },
                        {
                            lable: 'Xã Hóa Thanh',
                            value: '18916',
                        },
                        {
                            lable: 'Xã Hóa Tiến',
                            value: '18919',
                        },
                        {
                            lable: 'Xã Hồng Hóa',
                            value: '18913',
                        },
                        {
                            lable: 'Xã Minh Hóa',
                            value: '18931',
                        },
                        {
                            lable: 'Xã Thượng Hóa',
                            value: '18946',
                        },
                        {
                            lable: 'Xã Trung Hóa',
                            value: '18943',
                        },
                        {
                            lable: 'Xã Trọng Hóa',
                            value: '18907',
                        },
                        {
                            lable: 'Xã Tân Hóa',
                            value: '18934',
                        },
                        {
                            lable: 'Xã Xuân Hóa',
                            value: '18925',
                        },
                        {
                            lable: 'Xã Yên Hóa',
                            value: '18928',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tuyên Hóa',
                    value: '453',
                    wards: [
                        {
                            lable: 'Thị trấn Đồng Lê',
                            value: '18949',
                        },
                        {
                            lable: 'Xã Cao Quảng',
                            value: '19003',
                        },
                        {
                            lable: 'Xã Châu Hóa',
                            value: '19000',
                        },
                        {
                            lable: 'Xã Hương Hóa',
                            value: '18952',
                        },
                        {
                            lable: 'Xã Kim Hóa',
                            value: '18955',
                        },
                        {
                            lable: 'Xã Lâm Hóa',
                            value: '18967',
                        },
                        {
                            lable: 'Xã Lê Hóa',
                            value: '18970',
                        },
                        {
                            lable: 'Xã Mai Hóa',
                            value: '18994',
                        },
                        {
                            lable: 'Xã Ngư Hóa',
                            value: '18979',
                        },
                        {
                            lable: 'Xã Phong Hóa',
                            value: '18991',
                        },
                        {
                            lable: 'Xã Sơn Hóa',
                            value: '18973',
                        },
                        {
                            lable: 'Xã Thanh Hóa',
                            value: '18958',
                        },
                        {
                            lable: 'Xã Thanh Thạch',
                            value: '18961',
                        },
                        {
                            lable: 'Xã Thuận Hóa',
                            value: '18964',
                        },
                        {
                            lable: 'Xã Thạch Hóa',
                            value: '18985',
                        },
                        {
                            lable: 'Xã Tiến Hóa',
                            value: '18997',
                        },
                        {
                            lable: 'Xã Văn Hóa',
                            value: '19006',
                        },
                        {
                            lable: 'Xã Đồng Hóa',
                            value: '18976',
                        },
                        {
                            lable: 'Xã Đức Hóa',
                            value: '18988',
                        },
                    ],
                },
                {
                    lable: 'Huyện Quảng Trạch',
                    value: '454',
                    wards: [
                        {
                            lable: 'Xã Cảnh Dương',
                            value: '19036',
                        },
                        {
                            lable: 'Xã Cảnh Hóa',
                            value: '19048',
                        },
                        {
                            lable: 'Xã Liên Trường',
                            value: '19051',
                        },
                        {
                            lable: 'Xã Phù Hóa',
                            value: '19063',
                        },
                        {
                            lable: 'Xã Quảng Châu',
                            value: '19024',
                        },
                        {
                            lable: 'Xã Quảng Hưng',
                            value: '19042',
                        },
                        {
                            lable: 'Xã Quảng Hợp',
                            value: '19012',
                        },
                        {
                            lable: 'Xã Quảng Kim',
                            value: '19015',
                        },
                        {
                            lable: 'Xã Quảng Lưu',
                            value: '19030',
                        },
                        {
                            lable: 'Xã Quảng Phú',
                            value: '19021',
                        },
                        {
                            lable: 'Xã Quảng Phương',
                            value: '19057',
                        },
                        {
                            lable: 'Xã Quảng Thanh',
                            value: '19072',
                        },
                        {
                            lable: 'Xã Quảng Thạch',
                            value: '19027',
                        },
                        {
                            lable: 'Xã Quảng Tiến',
                            value: '19039',
                        },
                        {
                            lable: 'Xã Quảng Tùng',
                            value: '19033',
                        },
                        {
                            lable: 'Xã Quảng Xuân',
                            value: '19045',
                        },
                        {
                            lable: 'Xã Quảng Đông',
                            value: '19018',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bố Trạch',
                    value: '455',
                    wards: [
                        {
                            lable: 'Thị trấn Hoàn Lão',
                            value: '19111',
                        },
                        {
                            lable: 'Thị trấn NT Việt Trung',
                            value: '19114',
                        },
                        {
                            lable: 'Thị trấn Phong Nha',
                            value: '19165',
                        },
                        {
                            lable: 'Xã Bắc Trạch',
                            value: '19126',
                        },
                        {
                            lable: 'Xã Cự Nẫm',
                            value: '19141',
                        },
                        {
                            lable: 'Xã Hòa Trạch',
                            value: '19183',
                        },
                        {
                            lable: 'Xã Hưng Trạch',
                            value: '19156',
                        },
                        {
                            lable: 'Xã Hạ Trạch',
                            value: '19123',
                        },
                        {
                            lable: 'Xã Hải Phú',
                            value: '19144',
                        },
                        {
                            lable: 'Xã Liên Trạch',
                            value: '19135',
                        },
                        {
                            lable: 'Xã Lâm Trạch',
                            value: '19129',
                        },
                        {
                            lable: 'Xã Lý Trạch',
                            value: '19198',
                        },
                        {
                            lable: 'Xã Mỹ Trạch',
                            value: '19120',
                        },
                        {
                            lable: 'Xã Nam Trạch',
                            value: '19195',
                        },
                        {
                            lable: 'Xã Nhân Trạch',
                            value: '19189',
                        },
                        {
                            lable: 'Xã Phú Định',
                            value: '19174',
                        },
                        {
                            lable: 'Xã Phúc Trạch',
                            value: '19138',
                        },
                        {
                            lable: 'Xã Sơn Lộc',
                            value: '19150',
                        },
                        {
                            lable: 'Xã Thanh Trạch',
                            value: '19132',
                        },
                        {
                            lable: 'Xã Thượng Trạch',
                            value: '19147',
                        },
                        {
                            lable: 'Xã Trung Trạch',
                            value: '19177',
                        },
                        {
                            lable: 'Xã Tân Trạch',
                            value: '19192',
                        },
                        {
                            lable: 'Xã Tây Trạch',
                            value: '19180',
                        },
                        {
                            lable: 'Xã Vạn Trạch',
                            value: '19168',
                        },
                        {
                            lable: 'Xã Xuân Trạch',
                            value: '19117',
                        },
                        {
                            lable: 'Xã Đại Trạch',
                            value: '19186',
                        },
                        {
                            lable: 'Xã Đồng Trạch',
                            value: '19159',
                        },
                        {
                            lable: 'Xã Đức Trạch',
                            value: '19162',
                        },
                    ],
                },
                {
                    lable: 'Huyện Quảng Ninh',
                    value: '456',
                    wards: [
                        {
                            lable: 'Thị trấn Quán Hàu',
                            value: '19201',
                        },
                        {
                            lable: 'Xã An Ninh',
                            value: '19240',
                        },
                        {
                            lable: 'Xã Duy Ninh',
                            value: '19222',
                        },
                        {
                            lable: 'Xã Gia Ninh',
                            value: '19225',
                        },
                        {
                            lable: 'Xã Hiền Ninh',
                            value: '19231',
                        },
                        {
                            lable: 'Xã Hàm Ninh',
                            value: '19219',
                        },
                        {
                            lable: 'Xã Hải Ninh',
                            value: '19216',
                        },
                        {
                            lable: 'Xã Lương Ninh',
                            value: '19207',
                        },
                        {
                            lable: 'Xã Trường Sơn',
                            value: '19204',
                        },
                        {
                            lable: 'Xã Trường Xuân',
                            value: '19228',
                        },
                        {
                            lable: 'Xã Tân Ninh',
                            value: '19234',
                        },
                        {
                            lable: 'Xã Võ Ninh',
                            value: '19213',
                        },
                        {
                            lable: 'Xã Vĩnh Ninh',
                            value: '19210',
                        },
                        {
                            lable: 'Xã Vạn Ninh',
                            value: '19243',
                        },
                        {
                            lable: 'Xã Xuân Ninh',
                            value: '19237',
                        },
                    ],
                },
                {
                    lable: 'Huyện Lệ Thủy',
                    value: '457',
                    wards: [
                        {
                            lable: 'Thị trấn Kiến Giang',
                            value: '19249',
                        },
                        {
                            lable: 'Thị trấn NT Lệ Ninh',
                            value: '19246',
                        },
                        {
                            lable: 'Xã An Thủy',
                            value: '19264',
                        },
                        {
                            lable: 'Xã Cam Thủy',
                            value: '19270',
                        },
                        {
                            lable: 'Xã Dương Thủy',
                            value: '19291',
                        },
                        {
                            lable: 'Xã Hoa Thủy',
                            value: '19258',
                        },
                        {
                            lable: 'Xã Hưng Thủy',
                            value: '19288',
                        },
                        {
                            lable: 'Xã Hồng Thủy',
                            value: '19252',
                        },
                        {
                            lable: 'Xã Kim Thủy',
                            value: '19318',
                        },
                        {
                            lable: 'Xã Liên Thủy',
                            value: '19285',
                        },
                        {
                            lable: 'Xã Lâm Thủy',
                            value: '19327',
                        },
                        {
                            lable: 'Xã Lộc Thủy',
                            value: '19279',
                        },
                        {
                            lable: 'Xã Mai Thủy',
                            value: '19309',
                        },
                        {
                            lable: 'Xã Mỹ Thủy',
                            value: '19303',
                        },
                        {
                            lable: 'Xã Ngân Thủy',
                            value: '19273',
                        },
                        {
                            lable: 'Xã Ngư Thủy ',
                            value: '19306',
                        },
                        {
                            lable: 'Xã Ngư Thủy Bắc',
                            value: '19255',
                        },
                        {
                            lable: 'Xã Phong Thủy',
                            value: '19267',
                        },
                        {
                            lable: 'Xã Phú Thủy',
                            value: '19297',
                        },
                        {
                            lable: 'Xã Sen Thủy',
                            value: '19312',
                        },
                        {
                            lable: 'Xã Sơn Thủy',
                            value: '19276',
                        },
                        {
                            lable: 'Xã Thanh Thủy',
                            value: '19261',
                        },
                        {
                            lable: 'Xã Thái Thủy',
                            value: '19315',
                        },
                        {
                            lable: 'Xã Trường Thủy',
                            value: '19321',
                        },
                        {
                            lable: 'Xã Tân Thủy',
                            value: '19294',
                        },
                        {
                            lable: 'Xã Xuân Thủy',
                            value: '19300',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Ba Đồn',
                    value: '458',
                    wards: [
                        {
                            lable: 'Phường Ba Đồn',
                            value: '19009',
                        },
                        {
                            lable: 'Phường Quảng Long',
                            value: '19060',
                        },
                        {
                            lable: 'Phường Quảng Phong',
                            value: '19078',
                        },
                        {
                            lable: 'Phường Quảng Phúc',
                            value: '19102',
                        },
                        {
                            lable: 'Phường Quảng Thuận',
                            value: '19081',
                        },
                        {
                            lable: 'Phường Quảng Thọ',
                            value: '19066',
                        },
                        {
                            lable: 'Xã Quảng Hòa',
                            value: '19105',
                        },
                        {
                            lable: 'Xã Quảng Hải',
                            value: '19087',
                        },
                        {
                            lable: 'Xã Quảng Lộc',
                            value: '19093',
                        },
                        {
                            lable: 'Xã Quảng Minh',
                            value: '19108',
                        },
                        {
                            lable: 'Xã Quảng Sơn',
                            value: '19090',
                        },
                        {
                            lable: 'Xã Quảng Thủy',
                            value: '19096',
                        },
                        {
                            lable: 'Xã Quảng Tiên',
                            value: '19069',
                        },
                        {
                            lable: 'Xã Quảng Trung',
                            value: '19075',
                        },
                        {
                            lable: 'Xã Quảng Tân',
                            value: '19084',
                        },
                        {
                            lable: 'Xã Quảng Văn',
                            value: '19099',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Quảng Nam',
            value: '49',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Tam Kỳ',
                    value: '502',
                    wards: [
                        {
                            lable: 'Phường An Mỹ',
                            value: '20341',
                        },
                        {
                            lable: 'Phường An Phú',
                            value: '20356',
                        },
                        {
                            lable: 'Phường An Sơn',
                            value: '20350',
                        },
                        {
                            lable: 'Phường An Xuân',
                            value: '20347',
                        },
                        {
                            lable: 'Phường Hoà Thuận',
                            value: '20375',
                        },
                        {
                            lable: 'Phường Hòa Hương',
                            value: '20344',
                        },
                        {
                            lable: 'Phường Phước Hòa',
                            value: '20338',
                        },
                        {
                            lable: 'Phường Trường Xuân',
                            value: '20353',
                        },
                        {
                            lable: 'Phường Tân Thạnh',
                            value: '20335',
                        },
                        {
                            lable: 'Xã Tam Ngọc',
                            value: '20389',
                        },
                        {
                            lable: 'Xã Tam Phú',
                            value: '20371',
                        },
                        {
                            lable: 'Xã Tam Thanh',
                            value: '20359',
                        },
                        {
                            lable: 'Xã Tam Thăng',
                            value: '20362',
                        },
                    ],
                },
                {
                    lable: 'Thành phố Hội An',
                    value: '503',
                    wards: [
                        {
                            lable: 'Phường Cẩm An',
                            value: '20419',
                        },
                        {
                            lable: 'Phường Cẩm Châu',
                            value: '20413',
                        },
                        {
                            lable: 'Phường Cẩm Nam',
                            value: '20428',
                        },
                        {
                            lable: 'Phường Cẩm Phô',
                            value: '20404',
                        },
                        {
                            lable: 'Phường Cửa Đại',
                            value: '20416',
                        },
                        {
                            lable: 'Phường Minh An',
                            value: '20398',
                        },
                        {
                            lable: 'Phường Sơn Phong',
                            value: '20410',
                        },
                        {
                            lable: 'Phường Thanh Hà',
                            value: '20407',
                        },
                        {
                            lable: 'Phường Tân An',
                            value: '20401',
                        },
                        {
                            lable: 'Xã Cẩm Hà',
                            value: '20422',
                        },
                        {
                            lable: 'Xã Cẩm Kim',
                            value: '20425',
                        },
                        {
                            lable: 'Xã Cẩm Thanh',
                            value: '20431',
                        },
                        {
                            lable: 'Xã Tân Hiệp',
                            value: '20434',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tây Giang',
                    value: '504',
                    wards: [
                        {
                            lable: 'Xã A Nông',
                            value: '20452',
                        },
                        {
                            lable: 'Xã A Tiêng',
                            value: '20455',
                        },
                        {
                            lable: 'Xã A Vương',
                            value: '20461',
                        },
                        {
                            lable: 'Xã A Xan',
                            value: '20443',
                        },
                        {
                            lable: 'Xã Bha Lê',
                            value: '20458',
                        },
                        {
                            lable: 'Xã Ch\'ơm',
                            value: '20437',
                        },
                        {
                            lable: 'Xã Dang',
                            value: '20464',
                        },
                        {
                            lable: 'Xã Ga Ri',
                            value: '20440',
                        },
                        {
                            lable: 'Xã Lăng',
                            value: '20449',
                        },
                        {
                            lable: 'Xã Tr\'Hy',
                            value: '20446',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đông Giang',
                    value: '505',
                    wards: [
                        {
                            lable: 'Thị trấn P Rao',
                            value: '20467',
                        },
                        {
                            lable: 'Xã  Tư',
                            value: '20482',
                        },
                        {
                            lable: 'Xã A Rooi',
                            value: '20488',
                        },
                        {
                            lable: 'Xã A Ting',
                            value: '20479',
                        },
                        {
                            lable: 'Xã Ba',
                            value: '20485',
                        },
                        {
                            lable: 'Xã Jơ Ngây',
                            value: '20476',
                        },
                        {
                            lable: 'Xã Ka Dăng',
                            value: '20497',
                        },
                        {
                            lable: 'Xã Mà Cooi',
                            value: '20494',
                        },
                        {
                            lable: 'Xã Sông Kôn',
                            value: '20473',
                        },
                        {
                            lable: 'Xã Tà Lu',
                            value: '20470',
                        },
                        {
                            lable: 'Xã Za Hung',
                            value: '20491',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đại Lộc',
                    value: '506',
                    wards: [
                        {
                            lable: 'Thị Trấn Ái Nghĩa',
                            value: '20500',
                        },
                        {
                            lable: 'Xã Đại An',
                            value: '20547',
                        },
                        {
                            lable: 'Xã Đại Chánh',
                            value: '20530',
                        },
                        {
                            lable: 'Xã Đại Cường',
                            value: '20545',
                        },
                        {
                            lable: 'Xã Đại Hiệp',
                            value: '20524',
                        },
                        {
                            lable: 'Xã Đại Hòa',
                            value: '20548',
                        },
                        {
                            lable: 'Xã Đại Hưng',
                            value: '20509',
                        },
                        {
                            lable: 'Xã Đại Hồng',
                            value: '20512',
                        },
                        {
                            lable: 'Xã Đại Lãnh',
                            value: '20506',
                        },
                        {
                            lable: 'Xã Đại Minh',
                            value: '20539',
                        },
                        {
                            lable: 'Xã Đại Nghĩa',
                            value: '20521',
                        },
                        {
                            lable: 'Xã Đại Phong',
                            value: '20536',
                        },
                        {
                            lable: 'Xã Đại Quang',
                            value: '20518',
                        },
                        {
                            lable: 'Xã Đại Sơn',
                            value: '20503',
                        },
                        {
                            lable: 'Xã Đại Thạnh',
                            value: '20527',
                        },
                        {
                            lable: 'Xã Đại Thắng',
                            value: '20542',
                        },
                        {
                            lable: 'Xã Đại Tân',
                            value: '20533',
                        },
                        {
                            lable: 'Xã Đại Đồng',
                            value: '20515',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Điện Bàn',
                    value: '507',
                    wards: [
                        {
                            lable: 'Phường Vĩnh Điện',
                            value: '20551',
                        },
                        {
                            lable: 'Phường Điện An',
                            value: '20575',
                        },
                        {
                            lable: 'Phường Điện Dương',
                            value: '20581',
                        },
                        {
                            lable: 'Phường Điện Nam Bắc',
                            value: '20578',
                        },
                        {
                            lable: 'Phường Điện Nam Trung',
                            value: '20579',
                        },
                        {
                            lable: 'Phường Điện Nam Đông',
                            value: '20580',
                        },
                        {
                            lable: 'Phường Điện Ngọc',
                            value: '20563',
                        },
                        {
                            lable: 'Xã Điện Hòa',
                            value: '20557',
                        },
                        {
                            lable: 'Xã Điện Hồng',
                            value: '20566',
                        },
                        {
                            lable: 'Xã Điện Minh',
                            value: '20593',
                        },
                        {
                            lable: 'Xã Điện Phong',
                            value: '20590',
                        },
                        {
                            lable: 'Xã Điện Phương',
                            value: '20596',
                        },
                        {
                            lable: 'Xã Điện Phước',
                            value: '20572',
                        },
                        {
                            lable: 'Xã Điện Quang',
                            value: '20584',
                        },
                        {
                            lable: 'Xã Điện Thắng Bắc',
                            value: '20560',
                        },
                        {
                            lable: 'Xã Điện Thắng Nam',
                            value: '20562',
                        },
                        {
                            lable: 'Xã Điện Thắng Trung',
                            value: '20561',
                        },
                        {
                            lable: 'Xã Điện Thọ',
                            value: '20569',
                        },
                        {
                            lable: 'Xã Điện Tiến',
                            value: '20554',
                        },
                        {
                            lable: 'Xã Điện Trung',
                            value: '20587',
                        },
                    ],
                },
                {
                    lable: 'Huyện Duy Xuyên',
                    value: '508',
                    wards: [
                        {
                            lable: 'Thị trấn Nam Phước',
                            value: '20599',
                        },
                        {
                            lable: 'Xã Duy Châu',
                            value: '20614',
                        },
                        {
                            lable: 'Xã Duy Hòa',
                            value: '20611',
                        },
                        {
                            lable: 'Xã Duy Hải',
                            value: '20638',
                        },
                        {
                            lable: 'Xã Duy Nghĩa',
                            value: '20635',
                        },
                        {
                            lable: 'Xã Duy Phú',
                            value: '20605',
                        },
                        {
                            lable: 'Xã Duy Phước',
                            value: '20626',
                        },
                        {
                            lable: 'Xã Duy Sơn',
                            value: '20620',
                        },
                        {
                            lable: 'Xã Duy Thu',
                            value: '20602',
                        },
                        {
                            lable: 'Xã Duy Thành',
                            value: '20629',
                        },
                        {
                            lable: 'Xã Duy Trinh',
                            value: '20617',
                        },
                        {
                            lable: 'Xã Duy Trung',
                            value: '20623',
                        },
                        {
                            lable: 'Xã Duy Tân',
                            value: '20608',
                        },
                        {
                            lable: 'Xã Duy Vinh',
                            value: '20632',
                        },
                    ],
                },
                {
                    lable: 'Huyện Quế Sơn',
                    value: '509',
                    wards: [
                        {
                            lable: 'Thị trấn Hương An',
                            value: '20651',
                        },
                        {
                            lable: 'Thị trấn Đông Phú',
                            value: '20641',
                        },
                        {
                            lable: 'Xã Quế An',
                            value: '20686',
                        },
                        {
                            lable: 'Xã Quế Châu',
                            value: '20680',
                        },
                        {
                            lable: 'Xã Quế Hiệp',
                            value: '20659',
                        },
                        {
                            lable: 'Xã Quế Long',
                            value: '20677',
                        },
                        {
                            lable: 'Xã Quế Minh',
                            value: '20689',
                        },
                        {
                            lable: 'Xã Quế Mỹ',
                            value: '20665',
                        },
                        {
                            lable: 'Xã Quế Phong',
                            value: '20683',
                        },
                        {
                            lable: 'Xã Quế Phú',
                            value: '20650',
                        },
                        {
                            lable: 'Xã Quế Thuận',
                            value: '20662',
                        },
                        {
                            lable: 'Xã Quế Xuân 1',
                            value: '20644',
                        },
                        {
                            lable: 'Xã Quế Xuân 2',
                            value: '20647',
                        },
                    ],
                },
                {
                    lable: 'Huyện Nam Giang',
                    value: '510',
                    wards: [
                        {
                            lable: 'Thị trấn Thạnh Mỹ',
                            value: '20695',
                        },
                        {
                            lable: 'Xã Chà Vàl',
                            value: '20707',
                        },
                        {
                            lable: 'Xã Chơ Chun',
                            value: '20699',
                        },
                        {
                            lable: 'Xã Cà Dy',
                            value: '20713',
                        },
                        {
                            lable: 'Xã La Dêê',
                            value: '20704',
                        },
                        {
                            lable: 'Xã Laêê',
                            value: '20698',
                        },
                        {
                            lable: 'Xã Tà Bhinh',
                            value: '20710',
                        },
                        {
                            lable: 'Xã Tà Pơơ',
                            value: '20702',
                        },
                        {
                            lable: 'Xã Zuôich',
                            value: '20701',
                        },
                        {
                            lable: 'Xã Đắc Pre',
                            value: '20716',
                        },
                        {
                            lable: 'Xã Đắc Pring',
                            value: '20719',
                        },
                        {
                            lable: 'Xã Đắc Tôi',
                            value: '20705',
                        },
                    ],
                },
                {
                    lable: 'Huyện Phước Sơn',
                    value: '511',
                    wards: [
                        {
                            lable: 'Thị trấn Khâm Đức',
                            value: '20722',
                        },
                        {
                            lable: 'Xã Phước Chánh',
                            value: '20740',
                        },
                        {
                            lable: 'Xã Phước Công',
                            value: '20743',
                        },
                        {
                            lable: 'Xã Phước Hiệp',
                            value: '20728',
                        },
                        {
                            lable: 'Xã Phước Hoà',
                            value: '20729',
                        },
                        {
                            lable: 'Xã Phước Kim',
                            value: '20746',
                        },
                        {
                            lable: 'Xã Phước Lộc',
                            value: '20749',
                        },
                        {
                            lable: 'Xã Phước Mỹ',
                            value: '20737',
                        },
                        {
                            lable: 'Xã Phước Năng',
                            value: '20734',
                        },
                        {
                            lable: 'Xã Phước Thành',
                            value: '20752',
                        },
                        {
                            lable: 'Xã Phước Xuân',
                            value: '20725',
                        },
                        {
                            lable: 'Xã Phước Đức',
                            value: '20731',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hiệp Đức',
                    value: '512',
                    wards: [
                        {
                            lable: 'Thị trấn Tân Bình',
                            value: '20779',
                        },
                        {
                            lable: 'Xã Bình Lâm',
                            value: '20767',
                        },
                        {
                            lable: 'Xã Bình Sơn',
                            value: '20788',
                        },
                        {
                            lable: 'Xã Hiệp Hòa',
                            value: '20758',
                        },
                        {
                            lable: 'Xã Hiệp Thuận',
                            value: '20761',
                        },
                        {
                            lable: 'Xã Phước Gia',
                            value: '20776',
                        },
                        {
                            lable: 'Xã Phước Trà',
                            value: '20773',
                        },
                        {
                            lable: 'Xã Quế Lưu',
                            value: '20782',
                        },
                        {
                            lable: 'Xã Quế Thọ',
                            value: '20764',
                        },
                        {
                            lable: 'Xã Sông Trà',
                            value: '20770',
                        },
                        {
                            lable: 'Xã Thăng Phước',
                            value: '20785',
                        },
                    ],
                },
                {
                    lable: 'Huyện Thăng Bình',
                    value: '513',
                    wards: [
                        {
                            lable: 'Thị trấn Hà Lam',
                            value: '20791',
                        },
                        {
                            lable: 'Xã Bình An',
                            value: '20845',
                        },
                        {
                            lable: 'Xã Bình Chánh',
                            value: '20830',
                        },
                        {
                            lable: 'Xã Bình Dương',
                            value: '20794',
                        },
                        {
                            lable: 'Xã Bình Giang',
                            value: '20797',
                        },
                        {
                            lable: 'Xã Bình Hải',
                            value: '20839',
                        },
                        {
                            lable: 'Xã Bình Lãnh',
                            value: '20815',
                        },
                        {
                            lable: 'Xã Bình Minh',
                            value: '20812',
                        },
                        {
                            lable: 'Xã Bình Nam',
                            value: '20851',
                        },
                        {
                            lable: 'Xã Bình Nguyên',
                            value: '20800',
                        },
                        {
                            lable: 'Xã Bình Phú',
                            value: '20827',
                        },
                        {
                            lable: 'Xã Bình Phục',
                            value: '20803',
                        },
                        {
                            lable: 'Xã Bình Quý',
                            value: '20824',
                        },
                        {
                            lable: 'Xã Bình Quế',
                            value: '20842',
                        },
                        {
                            lable: 'Xã Bình Sa',
                            value: '20836',
                        },
                        {
                            lable: 'Xã Bình Triều',
                            value: '20806',
                        },
                        {
                            lable: 'Xã Bình Trung',
                            value: '20848',
                        },
                        {
                            lable: 'Xã Bình Trị',
                            value: '20818',
                        },
                        {
                            lable: 'Xã Bình Tú',
                            value: '20833',
                        },
                        {
                            lable: 'Xã Bình Đào',
                            value: '20809',
                        },
                        {
                            lable: 'Xã Bình Định Bắc',
                            value: '20821',
                        },
                        {
                            lable: 'Xã Bình Định Nam',
                            value: '20822',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tiên Phước',
                    value: '514',
                    wards: [
                        {
                            lable: 'Thị trấn Tiên Kỳ',
                            value: '20854',
                        },
                        {
                            lable: 'Xã Tiên An',
                            value: '20890',
                        },
                        {
                            lable: 'Xã Tiên Châu',
                            value: '20866',
                        },
                        {
                            lable: 'Xã Tiên Cảnh',
                            value: '20878',
                        },
                        {
                            lable: 'Xã Tiên Cẩm',
                            value: '20863',
                        },
                        {
                            lable: 'Xã Tiên Hiệp',
                            value: '20875',
                        },
                        {
                            lable: 'Xã Tiên Hà',
                            value: '20860',
                        },
                        {
                            lable: 'Xã Tiên Lãnh',
                            value: '20869',
                        },
                        {
                            lable: 'Xã Tiên Lập',
                            value: '20896',
                        },
                        {
                            lable: 'Xã Tiên Lộc',
                            value: '20893',
                        },
                        {
                            lable: 'Xã Tiên Mỹ',
                            value: '20881',
                        },
                        {
                            lable: 'Xã Tiên Ngọc',
                            value: '20872',
                        },
                        {
                            lable: 'Xã Tiên Phong',
                            value: '20884',
                        },
                        {
                            lable: 'Xã Tiên Sơn',
                            value: '20857',
                        },
                        {
                            lable: 'Xã Tiên Thọ',
                            value: '20887',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bắc Trà My',
                    value: '515',
                    wards: [
                        {
                            lable: 'Thị trấn Trà My',
                            value: '20899',
                        },
                        {
                            lable: 'Xã Trà Bui',
                            value: '20917',
                        },
                        {
                            lable: 'Xã Trà Dương',
                            value: '20911',
                        },
                        {
                            lable: 'Xã Trà Giang',
                            value: '20914',
                        },
                        {
                            lable: 'Xã Trà Giác',
                            value: '20926',
                        },
                        {
                            lable: 'Xã Trà Giáp',
                            value: '20929',
                        },
                        {
                            lable: 'Xã Trà Ka',
                            value: '20932',
                        },
                        {
                            lable: 'Xã Trà Kót',
                            value: '20902',
                        },
                        {
                            lable: 'Xã Trà Nú',
                            value: '20905',
                        },
                        {
                            lable: 'Xã Trà Sơn',
                            value: '20900',
                        },
                        {
                            lable: 'Xã Trà Tân',
                            value: '20923',
                        },
                        {
                            lable: 'Xã Trà Đông',
                            value: '20908',
                        },
                        {
                            lable: 'Xã Trà Đốc',
                            value: '20920',
                        },
                    ],
                },
                {
                    lable: 'Huyện Nam Trà My',
                    value: '516',
                    wards: [
                        {
                            lable: 'Xã Trà Cang',
                            value: '20947',
                        },
                        {
                            lable: 'Xã Trà Don',
                            value: '20956',
                        },
                        {
                            lable: 'Xã Trà Dơn',
                            value: '20938',
                        },
                        {
                            lable: 'Xã Trà Leng',
                            value: '20935',
                        },
                        {
                            lable: 'Xã Trà Linh',
                            value: '20950',
                        },
                        {
                            lable: 'Xã Trà Mai',
                            value: '20944',
                        },
                        {
                            lable: 'Xã Trà Nam',
                            value: '20953',
                        },
                        {
                            lable: 'Xã Trà Tập',
                            value: '20941',
                        },
                        {
                            lable: 'Xã Trà Vinh',
                            value: '20962',
                        },
                        {
                            lable: 'Xã Trà Vân',
                            value: '20959',
                        },
                    ],
                },
                {
                    lable: 'Huyện Núi Thành',
                    value: '517',
                    wards: [
                        {
                            lable: 'Thị trấn Núi Thành',
                            value: '20965',
                        },
                        {
                            lable: 'Xã Tam Anh Bắc',
                            value: '20983',
                        },
                        {
                            lable: 'Xã Tam Anh Nam',
                            value: '20984',
                        },
                        {
                            lable: 'Xã Tam Giang',
                            value: '20995',
                        },
                        {
                            lable: 'Xã Tam Hiệp',
                            value: '20989',
                        },
                        {
                            lable: 'Xã Tam Hòa',
                            value: '20986',
                        },
                        {
                            lable: 'Xã Tam Hải',
                            value: '20992',
                        },
                        {
                            lable: 'Xã Tam Mỹ Tây',
                            value: '21004',
                        },
                        {
                            lable: 'Xã Tam Mỹ Đông',
                            value: '21005',
                        },
                        {
                            lable: 'Xã Tam Nghĩa',
                            value: '21001',
                        },
                        {
                            lable: 'Xã Tam Quang',
                            value: '20998',
                        },
                        {
                            lable: 'Xã Tam Sơn',
                            value: '20977',
                        },
                        {
                            lable: 'Xã Tam Thạnh',
                            value: '20980',
                        },
                        {
                            lable: 'Xã Tam Tiến',
                            value: '20974',
                        },
                        {
                            lable: 'Xã Tam Trà',
                            value: '21007',
                        },
                        {
                            lable: 'Xã Tam Xuân I',
                            value: '20968',
                        },
                        {
                            lable: 'Xã Tam Xuân II',
                            value: '20971',
                        },
                    ],
                },
                {
                    lable: 'Huyện Phú Ninh',
                    value: '518',
                    wards: [
                        {
                            lable: 'Thị trấn Phú Thịnh',
                            value: '20364',
                        },
                        {
                            lable: 'Xã Tam An',
                            value: '20368',
                        },
                        {
                            lable: 'Xã Tam Dân',
                            value: '20392',
                        },
                        {
                            lable: 'Xã Tam Lãnh',
                            value: '20395',
                        },
                        {
                            lable: 'Xã Tam Lộc',
                            value: '20377',
                        },
                        {
                            lable: 'Xã Tam Phước',
                            value: '20380',
                        },
                        {
                            lable: 'Xã Tam Thành',
                            value: '20365',
                        },
                        {
                            lable: 'Xã Tam Thái',
                            value: '20386',
                        },
                        {
                            lable: 'Xã Tam Vinh',
                            value: '20383',
                        },
                        {
                            lable: 'Xã Tam Đàn',
                            value: '20374',
                        },
                        {
                            lable: 'Xã Tam Đại',
                            value: '20387',
                        },
                    ],
                },
                {
                    lable: 'Huyện Nông Sơn',
                    value: '519',
                    wards: [
                        {
                            lable: 'Xã Ninh Phước',
                            value: '20668',
                        },
                        {
                            lable: 'Xã Phước Ninh',
                            value: '20669',
                        },
                        {
                            lable: 'Xã Quế Lâm',
                            value: '20692',
                        },
                        {
                            lable: 'Xã Quế Lộc',
                            value: '20671',
                        },
                        {
                            lable: 'Xã Quế Trung',
                            value: '20656',
                        },
                        {
                            lable: 'Xã Sơn Viên',
                            value: '20672',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Quảng Ngãi',
            value: '51',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Quảng Ngãi',
                    value: '522',
                    wards: [
                        {
                            lable: 'Phường Chánh Lộ',
                            value: '21031',
                        },
                        {
                            lable: 'Phường Lê Hồng Phong',
                            value: '21010',
                        },
                        {
                            lable: 'Phường Nghĩa Chánh',
                            value: '21019',
                        },
                        {
                            lable: 'Phường Nghĩa Lộ',
                            value: '21028',
                        },
                        {
                            lable: 'Phường Nguyễn Nghiêm',
                            value: '21025',
                        },
                        {
                            lable: 'Phường Quảng Phú',
                            value: '21016',
                        },
                        {
                            lable: 'Phường Trương Quang Trọng',
                            value: '21172',
                        },
                        {
                            lable: 'Phường Trần Hưng Đạo',
                            value: '21022',
                        },
                        {
                            lable: 'Phường Trần Phú',
                            value: '21013',
                        },
                        {
                            lable: 'Xã Nghĩa An',
                            value: '21262',
                        },
                        {
                            lable: 'Xã Nghĩa Dõng',
                            value: '21037',
                        },
                        {
                            lable: 'Xã Nghĩa Dũng',
                            value: '21034',
                        },
                        {
                            lable: 'Xã Nghĩa Hà',
                            value: '21256',
                        },
                        {
                            lable: 'Xã Nghĩa Phú',
                            value: '21253',
                        },
                        {
                            lable: 'Xã Tịnh An',
                            value: '21232',
                        },
                        {
                            lable: 'Xã Tịnh Châu',
                            value: '21208',
                        },
                        {
                            lable: 'Xã Tịnh Hòa',
                            value: '21187',
                        },
                        {
                            lable: 'Xã Tịnh Khê',
                            value: '21211',
                        },
                        {
                            lable: 'Xã Tịnh Kỳ',
                            value: '21190',
                        },
                        {
                            lable: 'Xã Tịnh Long',
                            value: '21214',
                        },
                        {
                            lable: 'Xã Tịnh Thiện',
                            value: '21199',
                        },
                        {
                            lable: 'Xã Tịnh Ấn Tây',
                            value: '21223',
                        },
                        {
                            lable: 'Xã Tịnh Ấn Đông',
                            value: '21202',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bình Sơn',
                    value: '524',
                    wards: [
                        {
                            lable: 'Thị Trấn Châu Ổ',
                            value: '21040',
                        },
                        {
                            lable: 'Xã Bình An',
                            value: '21064',
                        },
                        {
                            lable: 'Xã Bình Chánh',
                            value: '21052',
                        },
                        {
                            lable: 'Xã Bình Châu',
                            value: '21112',
                        },
                        {
                            lable: 'Xã Bình Chương',
                            value: '21100',
                        },
                        {
                            lable: 'Xã Bình Dương',
                            value: '21070',
                        },
                        {
                            lable: 'Xã Bình Hiệp',
                            value: '21103',
                        },
                        {
                            lable: 'Xã Bình Hòa',
                            value: '21079',
                        },
                        {
                            lable: 'Xã Bình Hải',
                            value: '21067',
                        },
                        {
                            lable: 'Xã Bình Khương',
                            value: '21058',
                        },
                        {
                            lable: 'Xã Bình Long',
                            value: '21088',
                        },
                        {
                            lable: 'Xã Bình Minh',
                            value: '21085',
                        },
                        {
                            lable: 'Xã Bình Mỹ',
                            value: '21106',
                        },
                        {
                            lable: 'Xã Bình Nguyên',
                            value: '21055',
                        },
                        {
                            lable: 'Xã Bình Phước',
                            value: '21073',
                        },
                        {
                            lable: 'Xã Bình Thanh ',
                            value: '21091',
                        },
                        {
                            lable: 'Xã Bình Thuận',
                            value: '21043',
                        },
                        {
                            lable: 'Xã Bình Thạnh',
                            value: '21046',
                        },
                        {
                            lable: 'Xã Bình Trung',
                            value: '21082',
                        },
                        {
                            lable: 'Xã Bình Trị',
                            value: '21061',
                        },
                        {
                            lable: 'Xã Bình Tân Phú',
                            value: '21109',
                        },
                        {
                            lable: 'Xã Bình Đông',
                            value: '21049',
                        },
                    ],
                },
                {
                    lable: 'Huyện Trà Bồng',
                    value: '525',
                    wards: [
                        {
                            lable: 'Thị trấn Trà Xuân',
                            value: '21115',
                        },
                        {
                            lable: 'Xã Hương Trà',
                            value: '21157',
                        },
                        {
                            lable: 'Xã Sơn Trà',
                            value: '21148',
                        },
                        {
                            lable: 'Xã Trà Bình',
                            value: '21127',
                        },
                        {
                            lable: 'Xã Trà Bùi',
                            value: '21142',
                        },
                        {
                            lable: 'Xã Trà Giang',
                            value: '21118',
                        },
                        {
                            lable: 'Xã Trà Hiệp',
                            value: '21124',
                        },
                        {
                            lable: 'Xã Trà Lâm',
                            value: '21133',
                        },
                        {
                            lable: 'Xã Trà Phong',
                            value: '21154',
                        },
                        {
                            lable: 'Xã Trà Phú',
                            value: '21130',
                        },
                        {
                            lable: 'Xã Trà Sơn',
                            value: '21139',
                        },
                        {
                            lable: 'Xã Trà Thanh',
                            value: '21145',
                        },
                        {
                            lable: 'Xã Trà Thủy',
                            value: '21121',
                        },
                        {
                            lable: 'Xã Trà Tân',
                            value: '21136',
                        },
                        {
                            lable: 'Xã Trà Tây',
                            value: '21166',
                        },
                        {
                            lable: 'Xã Trà Xinh',
                            value: '21163',
                        },
                    ],
                },
                {
                    lable: 'Huyện Sơn Tịnh',
                    value: '527',
                    wards: [
                        {
                            lable: 'Xã Tịnh Bình',
                            value: '21193',
                        },
                        {
                            lable: 'Xã Tịnh Bắc',
                            value: '21205',
                        },
                        {
                            lable: 'Xã Tịnh Giang',
                            value: '21226',
                        },
                        {
                            lable: 'Xã Tịnh Hiệp',
                            value: '21184',
                        },
                        {
                            lable: 'Xã Tịnh Hà',
                            value: '21220',
                        },
                        {
                            lable: 'Xã Tịnh Minh',
                            value: '21229',
                        },
                        {
                            lable: 'Xã Tịnh Phong',
                            value: '21181',
                        },
                        {
                            lable: 'Xã Tịnh Sơn',
                            value: '21217',
                        },
                        {
                            lable: 'Xã Tịnh Thọ',
                            value: '21175',
                        },
                        {
                            lable: 'Xã Tịnh Trà',
                            value: '21178',
                        },
                        {
                            lable: 'Xã Tịnh Đông',
                            value: '21196',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tư Nghĩa',
                    value: '528',
                    wards: [
                        {
                            lable: 'Thị trấn La Hà',
                            value: '21235',
                        },
                        {
                            lable: 'Thị trấn Sông Vệ',
                            value: '21238',
                        },
                        {
                            lable: 'Xã Nghĩa Hiệp',
                            value: '21280',
                        },
                        {
                            lable: 'Xã Nghĩa Hòa',
                            value: '21268',
                        },
                        {
                            lable: 'Xã Nghĩa Kỳ',
                            value: '21250',
                        },
                        {
                            lable: 'Xã Nghĩa Lâm',
                            value: '21241',
                        },
                        {
                            lable: 'Xã Nghĩa Mỹ',
                            value: '21286',
                        },
                        {
                            lable: 'Xã Nghĩa Phương',
                            value: '21283',
                        },
                        {
                            lable: 'Xã Nghĩa Sơn',
                            value: '21259',
                        },
                        {
                            lable: 'Xã Nghĩa Thuận',
                            value: '21247',
                        },
                        {
                            lable: 'Xã Nghĩa Thương',
                            value: '21274',
                        },
                        {
                            lable: 'Xã Nghĩa Thắng',
                            value: '21244',
                        },
                        {
                            lable: 'Xã Nghĩa Trung',
                            value: '21277',
                        },
                        {
                            lable: 'Xã Nghĩa Điền',
                            value: '21271',
                        },
                    ],
                },
                {
                    lable: 'Huyện Sơn Hà',
                    value: '529',
                    wards: [
                        {
                            lable: 'Thị trấn Di Lăng',
                            value: '21289',
                        },
                        {
                            lable: 'Xã Sơn Ba',
                            value: '21328',
                        },
                        {
                            lable: 'Xã Sơn Bao',
                            value: '21301',
                        },
                        {
                            lable: 'Xã Sơn Cao',
                            value: '21316',
                        },
                        {
                            lable: 'Xã Sơn Giang',
                            value: '21307',
                        },
                        {
                            lable: 'Xã Sơn Hạ',
                            value: '21292',
                        },
                        {
                            lable: 'Xã Sơn Hải',
                            value: '21319',
                        },
                        {
                            lable: 'Xã Sơn Kỳ',
                            value: '21325',
                        },
                        {
                            lable: 'Xã Sơn Linh',
                            value: '21304',
                        },
                        {
                            lable: 'Xã Sơn Nham',
                            value: '21298',
                        },
                        {
                            lable: 'Xã Sơn Thành',
                            value: '21295',
                        },
                        {
                            lable: 'Xã Sơn Thượng',
                            value: '21313',
                        },
                        {
                            lable: 'Xã Sơn Thủy',
                            value: '21322',
                        },
                        {
                            lable: 'Xã Sơn Trung',
                            value: '21310',
                        },
                    ],
                },
                {
                    lable: 'Huyện Sơn Tây',
                    value: '530',
                    wards: [
                        {
                            lable: 'Xã Sơn Bua',
                            value: '21331',
                        },
                        {
                            lable: 'Xã Sơn Dung',
                            value: '21340',
                        },
                        {
                            lable: 'Xã Sơn Liên',
                            value: '21335',
                        },
                        {
                            lable: 'Xã Sơn Long',
                            value: '21341',
                        },
                        {
                            lable: 'Xã Sơn Lập',
                            value: '21346',
                        },
                        {
                            lable: 'Xã Sơn Màu',
                            value: '21338',
                        },
                        {
                            lable: 'Xã Sơn Mùa',
                            value: '21334',
                        },
                        {
                            lable: 'Xã Sơn Tinh',
                            value: '21343',
                        },
                        {
                            lable: 'Xã Sơn Tân',
                            value: '21337',
                        },
                    ],
                },
                {
                    lable: 'Huyện Minh Long',
                    value: '531',
                    wards: [
                        {
                            lable: 'Xã Long Hiệp',
                            value: '21361',
                        },
                        {
                            lable: 'Xã Long Mai',
                            value: '21352',
                        },
                        {
                            lable: 'Xã Long Môn',
                            value: '21358',
                        },
                        {
                            lable: 'Xã Long Sơn',
                            value: '21349',
                        },
                        {
                            lable: 'Xã Thanh An',
                            value: '21355',
                        },
                    ],
                },
                {
                    lable: 'Huyện Nghĩa Hành',
                    value: '532',
                    wards: [
                        {
                            lable: 'Thị trấn Chợ Chùa',
                            value: '21364',
                        },
                        {
                            lable: 'Xã Hành Dũng',
                            value: '21370',
                        },
                        {
                            lable: 'Xã Hành Minh',
                            value: '21382',
                        },
                        {
                            lable: 'Xã Hành Nhân',
                            value: '21376',
                        },
                        {
                            lable: 'Xã Hành Phước',
                            value: '21385',
                        },
                        {
                            lable: 'Xã Hành Thiện',
                            value: '21388',
                        },
                        {
                            lable: 'Xã Hành Thuận',
                            value: '21367',
                        },
                        {
                            lable: 'Xã Hành Thịnh',
                            value: '21391',
                        },
                        {
                            lable: 'Xã Hành Trung',
                            value: '21373',
                        },
                        {
                            lable: 'Xã Hành Tín  Đông',
                            value: '21397',
                        },
                        {
                            lable: 'Xã Hành Tín Tây',
                            value: '21394',
                        },
                        {
                            lable: 'Xã Hành Đức',
                            value: '21379',
                        },
                    ],
                },
                {
                    lable: 'Huyện Mộ Đức',
                    value: '533',
                    wards: [
                        {
                            lable: 'Thị trấn Mộ Đức',
                            value: '21400',
                        },
                        {
                            lable: 'Xã Đức Chánh',
                            value: '21412',
                        },
                        {
                            lable: 'Xã Đức Hiệp',
                            value: '21415',
                        },
                        {
                            lable: 'Xã Đức Hòa',
                            value: '21424',
                        },
                        {
                            lable: 'Xã Đức Lân',
                            value: '21436',
                        },
                        {
                            lable: 'Xã Đức Lợi',
                            value: '21403',
                        },
                        {
                            lable: 'Xã Đức Minh',
                            value: '21418',
                        },
                        {
                            lable: 'Xã Đức Nhuận',
                            value: '21409',
                        },
                        {
                            lable: 'Xã Đức Phong',
                            value: '21433',
                        },
                        {
                            lable: 'Xã Đức Phú',
                            value: '21430',
                        },
                        {
                            lable: 'Xã Đức Thạnh',
                            value: '21421',
                        },
                        {
                            lable: 'Xã Đức Thắng',
                            value: '21406',
                        },
                        {
                            lable: 'Xã Đức Tân',
                            value: '21427',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Đức Phổ',
                    value: '534',
                    wards: [
                        {
                            lable: 'Phường Nguyễn Nghiêm',
                            value: '21439',
                        },
                        {
                            lable: 'Phường Phổ Hòa',
                            value: '21469',
                        },
                        {
                            lable: 'Phường Phổ Minh',
                            value: '21463',
                        },
                        {
                            lable: 'Phường Phổ Ninh',
                            value: '21460',
                        },
                        {
                            lable: 'Phường Phổ Quang',
                            value: '21454',
                        },
                        {
                            lable: 'Phường Phổ Thạnh',
                            value: '21478',
                        },
                        {
                            lable: 'Phường Phổ Vinh',
                            value: '21466',
                        },
                        {
                            lable: 'Phường Phổ Văn',
                            value: '21451',
                        },
                        {
                            lable: 'Xã Phổ An',
                            value: '21442',
                        },
                        {
                            lable: 'Xã Phổ Châu',
                            value: '21481',
                        },
                        {
                            lable: 'Xã Phổ Cường',
                            value: '21472',
                        },
                        {
                            lable: 'Xã Phổ Khánh',
                            value: '21475',
                        },
                        {
                            lable: 'Xã Phổ Nhơn',
                            value: '21457',
                        },
                        {
                            lable: 'Xã Phổ Phong',
                            value: '21445',
                        },
                        {
                            lable: 'Xã Phổ Thuận',
                            value: '21448',
                        },
                    ],
                },
                {
                    lable: 'Huyện Ba Tơ',
                    value: '535',
                    wards: [
                        {
                            lable: 'Thị trấn Ba Tơ',
                            value: '21484',
                        },
                        {
                            lable: 'Xã Ba Bích',
                            value: '21526',
                        },
                        {
                            lable: 'Xã Ba Cung',
                            value: '21511',
                        },
                        {
                            lable: 'Xã Ba Dinh',
                            value: '21499',
                        },
                        {
                            lable: 'Xã Ba Giang',
                            value: '21500',
                        },
                        {
                            lable: 'Xã Ba Khâm',
                            value: '21508',
                        },
                        {
                            lable: 'Xã Ba Liên',
                            value: '21502',
                        },
                        {
                            lable: 'Xã Ba Lế',
                            value: '21532',
                        },
                        {
                            lable: 'Xã Ba Nam',
                            value: '21535',
                        },
                        {
                            lable: 'Xã Ba Ngạc',
                            value: '21505',
                        },
                        {
                            lable: 'Xã Ba Thành',
                            value: '21493',
                        },
                        {
                            lable: 'Xã Ba Tiêu',
                            value: '21517',
                        },
                        {
                            lable: 'Xã Ba Trang',
                            value: '21520',
                        },
                        {
                            lable: 'Xã Ba Tô',
                            value: '21523',
                        },
                        {
                            lable: 'Xã Ba Vinh',
                            value: '21490',
                        },
                        {
                            lable: 'Xã Ba Vì',
                            value: '21529',
                        },
                        {
                            lable: 'Xã Ba Xa',
                            value: '21538',
                        },
                        {
                            lable: 'Xã Ba Điền',
                            value: '21487',
                        },
                        {
                            lable: 'Xã Ba Động',
                            value: '21496',
                        },
                    ],
                },
                {
                    lable: 'Huyện Lý Sơn',
                    value: '536',
                    wards: [],
                },
            ],
        },
        {
            lable: 'Tỉnh Quảng Ninh',
            value: '22',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Hạ Long',
                    value: '193',
                    wards: [
                        {
                            lable: 'Phường Bãi Cháy',
                            value: '06673',
                        },
                        {
                            lable: 'Phường Bạch Đằng',
                            value: '06694',
                        },
                        {
                            lable: 'Phường Cao Thắng',
                            value: '06676',
                        },
                        {
                            lable: 'Phường Cao Xanh',
                            value: '06658',
                        },
                        {
                            lable: 'Phường Giếng Đáy',
                            value: '06661',
                        },
                        {
                            lable: 'Phường Hoành Bồ',
                            value: '07030',
                        },
                        {
                            lable: 'Phường Hà Khánh',
                            value: '06649',
                        },
                        {
                            lable: 'Phường Hà Khẩu',
                            value: '06655',
                        },
                        {
                            lable: 'Phường Hà Lầm',
                            value: '06670',
                        },
                        {
                            lable: 'Phường Hà Phong',
                            value: '06652',
                        },
                        {
                            lable: 'Phường Hà Trung',
                            value: '06667',
                        },
                        {
                            lable: 'Phường Hà Tu',
                            value: '06664',
                        },
                        {
                            lable: 'Phường Hùng Thắng',
                            value: '06679',
                        },
                        {
                            lable: 'Phường Hồng Gai',
                            value: '06691',
                        },
                        {
                            lable: 'Phường Hồng Hà',
                            value: '06697',
                        },
                        {
                            lable: 'Phường Hồng Hải',
                            value: '06688',
                        },
                        {
                            lable: 'Phường Trần Hưng Đạo',
                            value: '06685',
                        },
                        {
                            lable: 'Phường Tuần Châu',
                            value: '06700',
                        },
                        {
                            lable: 'Phường Việt Hưng',
                            value: '06703',
                        },
                        {
                            lable: 'Phường Yết Kiêu',
                            value: '06682',
                        },
                        {
                            lable: 'Phường Đại Yên',
                            value: '06706',
                        },
                        {
                            lable: 'Xã Bằng Cả',
                            value: '07057',
                        },
                        {
                            lable: 'Xã Dân Chủ',
                            value: '07051',
                        },
                        {
                            lable: 'Xã Hòa Bình',
                            value: '07045',
                        },
                        {
                            lable: 'Xã Kỳ Thượng',
                            value: '07033',
                        },
                        {
                            lable: 'Xã Lê Lợi',
                            value: '07066',
                        },
                        {
                            lable: 'Xã Quảng La',
                            value: '07054',
                        },
                        {
                            lable: 'Xã Sơn Dương',
                            value: '07063',
                        },
                        {
                            lable: 'Xã Thống Nhất',
                            value: '07060',
                        },
                        {
                            lable: 'Xã Tân Dân',
                            value: '07039',
                        },
                        {
                            lable: 'Xã Vũ Oai',
                            value: '07048',
                        },
                        {
                            lable: 'Xã Đồng Lâm',
                            value: '07042',
                        },
                        {
                            lable: 'Xã Đồng Sơn',
                            value: '07036',
                        },
                    ],
                },
                {
                    lable: 'Thành phố Móng Cái',
                    value: '194',
                    wards: [
                        {
                            lable: 'Phường Bình Ngọc',
                            value: '06751',
                        },
                        {
                            lable: 'Phường Hoà Lạc',
                            value: '06718',
                        },
                        {
                            lable: 'Phường Hải Hoà',
                            value: '06742',
                        },
                        {
                            lable: 'Phường Hải Yên',
                            value: '06736',
                        },
                        {
                            lable: 'Phường Ka Long',
                            value: '06709',
                        },
                        {
                            lable: 'Phường Ninh Dương',
                            value: '06715',
                        },
                        {
                            lable: 'Phường Trà Cổ',
                            value: '06721',
                        },
                        {
                            lable: 'Phường Trần Phú',
                            value: '06712',
                        },
                        {
                            lable: 'Xã Bắc Sơn',
                            value: '06727',
                        },
                        {
                            lable: 'Xã Hải Sơn',
                            value: '06724',
                        },
                        {
                            lable: 'Xã Hải Tiến',
                            value: '06733',
                        },
                        {
                            lable: 'Xã Hải Xuân',
                            value: '06745',
                        },
                        {
                            lable: 'Xã Hải Đông',
                            value: '06730',
                        },
                        {
                            lable: 'Xã Quảng Nghĩa',
                            value: '06739',
                        },
                        {
                            lable: 'Xã Vĩnh Thực',
                            value: '06757',
                        },
                        {
                            lable: 'Xã Vĩnh Trung',
                            value: '06754',
                        },
                        {
                            lable: 'Xã Vạn Ninh',
                            value: '06748',
                        },
                    ],
                },
                {
                    lable: 'Thành phố Cẩm Phả',
                    value: '195',
                    wards: [
                        {
                            lable: 'Phường Cẩm Bình',
                            value: '06796',
                        },
                        {
                            lable: 'Phường Cẩm Phú',
                            value: '06772',
                        },
                        {
                            lable: 'Phường Cẩm Sơn',
                            value: '06766',
                        },
                        {
                            lable: 'Phường Cẩm Thành',
                            value: '06790',
                        },
                        {
                            lable: 'Phường Cẩm Thạch',
                            value: '06787',
                        },
                        {
                            lable: 'Phường Cẩm Thịnh',
                            value: '06781',
                        },
                        {
                            lable: 'Phường Cẩm Thủy',
                            value: '06784',
                        },
                        {
                            lable: 'Phường Cẩm Trung',
                            value: '06793',
                        },
                        {
                            lable: 'Phường Cẩm Tây',
                            value: '06775',
                        },
                        {
                            lable: 'Phường Cẩm Đông',
                            value: '06769',
                        },
                        {
                            lable: 'Phường Cửa Ông',
                            value: '06763',
                        },
                        {
                            lable: 'Phường Mông Dương',
                            value: '06760',
                        },
                        {
                            lable: 'Phường Quang Hanh',
                            value: '06778',
                        },
                        {
                            lable: 'Xã Cẩm Hải',
                            value: '06802',
                        },
                        {
                            lable: 'Xã Cộng Hòa',
                            value: '06799',
                        },
                        {
                            lable: 'Xã Dương Huy',
                            value: '06805',
                        },
                    ],
                },
                {
                    lable: 'Thành phố Uông Bí',
                    value: '196',
                    wards: [
                        {
                            lable: 'Phường Bắc Sơn',
                            value: '06814',
                        },
                        {
                            lable: 'Phường Nam Khê',
                            value: '06823',
                        },
                        {
                            lable: 'Phường Phương Nam',
                            value: '06835',
                        },
                        {
                            lable: 'Phường Phương Đông',
                            value: '06832',
                        },
                        {
                            lable: 'Phường Quang Trung',
                            value: '06817',
                        },
                        {
                            lable: 'Phường Thanh Sơn',
                            value: '06811',
                        },
                        {
                            lable: 'Phường Trưng Vương',
                            value: '06820',
                        },
                        {
                            lable: 'Phường Vàng Danh',
                            value: '06808',
                        },
                        {
                            lable: 'Phường Yên Thanh',
                            value: '06826',
                        },
                        {
                            lable: 'Xã Thượng Yên Công',
                            value: '06829',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bình Liêu',
                    value: '198',
                    wards: [
                        {
                            lable: 'Thị trấn Bình Liêu',
                            value: '06838',
                        },
                        {
                            lable: 'Xã Hoành Mô',
                            value: '06841',
                        },
                        {
                            lable: 'Xã Húc Động',
                            value: '06859',
                        },
                        {
                            lable: 'Xã Lục Hồn',
                            value: '06856',
                        },
                        {
                            lable: 'Xã Vô Ngại',
                            value: '06853',
                        },
                        {
                            lable: 'Xã Đồng Tâm',
                            value: '06844',
                        },
                        {
                            lable: 'Xã Đồng Văn',
                            value: '06847',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tiên Yên',
                    value: '199',
                    wards: [
                        {
                            lable: 'Thị trấn Tiên Yên',
                            value: '06862',
                        },
                        {
                            lable: 'Xã Hà Lâu',
                            value: '06865',
                        },
                        {
                            lable: 'Xã Hải Lạng',
                            value: '06886',
                        },
                        {
                            lable: 'Xã Phong Dụ',
                            value: '06871',
                        },
                        {
                            lable: 'Xã Tiên Lãng',
                            value: '06889',
                        },
                        {
                            lable: 'Xã Yên Than',
                            value: '06880',
                        },
                        {
                            lable: 'Xã Điền Xá',
                            value: '06874',
                        },
                        {
                            lable: 'Xã Đông Hải',
                            value: '06883',
                        },
                        {
                            lable: 'Xã Đông Ngũ',
                            value: '06877',
                        },
                        {
                            lable: 'Xã Đại Dực',
                            value: '06868',
                        },
                        {
                            lable: 'Xã Đồng Rui',
                            value: '06892',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đầm Hà',
                    value: '200',
                    wards: [
                        {
                            lable: 'Thị trấn Đầm Hà',
                            value: '06895',
                        },
                        {
                            lable: 'Xã Dực Yên',
                            value: '06910',
                        },
                        {
                            lable: 'Xã Quảng An',
                            value: '06901',
                        },
                        {
                            lable: 'Xã Quảng Lâm',
                            value: '06898',
                        },
                        {
                            lable: 'Xã Quảng Tân',
                            value: '06913',
                        },
                        {
                            lable: 'Xã Tân Bình',
                            value: '06904',
                        },
                        {
                            lable: 'Xã Tân Lập',
                            value: '06917',
                        },
                        {
                            lable: 'Xã Đại Bình',
                            value: '06919',
                        },
                        {
                            lable: 'Xã Đầm Hà',
                            value: '06916',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hải Hà',
                    value: '201',
                    wards: [
                        {
                            lable: 'Thị trấn Quảng Hà',
                            value: '06922',
                        },
                        {
                            lable: 'Xã Cái Chiên',
                            value: '06967',
                        },
                        {
                            lable: 'Xã Quảng Chính',
                            value: '06943',
                        },
                        {
                            lable: 'Xã Quảng Long',
                            value: '06946',
                        },
                        {
                            lable: 'Xã Quảng Minh',
                            value: '06940',
                        },
                        {
                            lable: 'Xã Quảng Phong',
                            value: '06952',
                        },
                        {
                            lable: 'Xã Quảng Sơn',
                            value: '06928',
                        },
                        {
                            lable: 'Xã Quảng Thành',
                            value: '06931',
                        },
                        {
                            lable: 'Xã Quảng Thịnh',
                            value: '06937',
                        },
                        {
                            lable: 'Xã Quảng Đức',
                            value: '06925',
                        },
                        {
                            lable: 'Xã Đường Hoa',
                            value: '06949',
                        },
                    ],
                },
                {
                    lable: 'Huyện Ba Chẽ',
                    value: '202',
                    wards: [
                        {
                            lable: 'Thị trấn Ba Chẽ',
                            value: '06970',
                        },
                        {
                            lable: 'Xã Lương Mông',
                            value: '06985',
                        },
                        {
                            lable: 'Xã Minh Cầm',
                            value: '06991',
                        },
                        {
                            lable: 'Xã Nam Sơn',
                            value: '06982',
                        },
                        {
                            lable: 'Xã Thanh Lâm',
                            value: '06976',
                        },
                        {
                            lable: 'Xã Thanh Sơn',
                            value: '06973',
                        },
                        {
                            lable: 'Xã Đạp Thanh',
                            value: '06979',
                        },
                        {
                            lable: 'Xã Đồn Đạc',
                            value: '06988',
                        },
                    ],
                },
                {
                    lable: 'Huyện Vân Đồn',
                    value: '203',
                    wards: [
                        {
                            lable: 'Thị trấn Cái Rồng',
                            value: '06994',
                        },
                        {
                            lable: 'Xã Bình Dân',
                            value: '07000',
                        },
                        {
                            lable: 'Xã Bản Sen',
                            value: '07018',
                        },
                        {
                            lable: 'Xã Hạ Long',
                            value: '07012',
                        },
                        {
                            lable: 'Xã Minh Châu',
                            value: '07006',
                        },
                        {
                            lable: 'Xã Ngọc Vừng',
                            value: '07027',
                        },
                        {
                            lable: 'Xã Quan Lạn',
                            value: '07024',
                        },
                        {
                            lable: 'Xã Thắng Lợi',
                            value: '07021',
                        },
                        {
                            lable: 'Xã Vạn Yên',
                            value: '07003',
                        },
                        {
                            lable: 'Xã Đoàn Kết',
                            value: '07009',
                        },
                        {
                            lable: 'Xã Đài Xuyên',
                            value: '06997',
                        },
                        {
                            lable: 'Xã Đông Xá',
                            value: '07015',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Đông Triều',
                    value: '205',
                    wards: [
                        {
                            lable: 'Phường Hoàng Quế',
                            value: '07114',
                        },
                        {
                            lable: 'Phường Hưng Đạo',
                            value: '07126',
                        },
                        {
                            lable: 'Phường Hồng Phong',
                            value: '07120',
                        },
                        {
                            lable: 'Phường Kim Sơn',
                            value: '07123',
                        },
                        {
                            lable: 'Phường Mạo Khê',
                            value: '07069',
                        },
                        {
                            lable: 'Phường Tràng An',
                            value: '07096',
                        },
                        {
                            lable: 'Phường Xuân Sơn',
                            value: '07105',
                        },
                        {
                            lable: 'Phường Yên Thọ',
                            value: '07117',
                        },
                        {
                            lable: 'Phường Đông Triều',
                            value: '07072',
                        },
                        {
                            lable: 'Phường Đức Chính',
                            value: '07093',
                        },
                        {
                            lable: 'Xã An Sinh',
                            value: '07075',
                        },
                        {
                            lable: 'Xã Bình Dương',
                            value: '07090',
                        },
                        {
                            lable: 'Xã Bình Khê',
                            value: '07081',
                        },
                        {
                            lable: 'Xã Hồng Thái Tây',
                            value: '07108',
                        },
                        {
                            lable: 'Xã Hồng Thái Đông',
                            value: '07111',
                        },
                        {
                            lable: 'Xã Nguyễn Huệ',
                            value: '07099',
                        },
                        {
                            lable: 'Xã Thủy An',
                            value: '07102',
                        },
                        {
                            lable: 'Xã Tràng Lương',
                            value: '07078',
                        },
                        {
                            lable: 'Xã Tân Việt',
                            value: '07087',
                        },
                        {
                            lable: 'Xã Việt Dân',
                            value: '07084',
                        },
                        {
                            lable: 'Xã Yên Đức',
                            value: '07129',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Quảng Yên',
                    value: '206',
                    wards: [
                        {
                            lable: 'Phường Cộng Hòa',
                            value: '07150',
                        },
                        {
                            lable: 'Phường Hà An',
                            value: '07168',
                        },
                        {
                            lable: 'Phường Minh Thành',
                            value: '07138',
                        },
                        {
                            lable: 'Phường Nam Hoà',
                            value: '07165',
                        },
                        {
                            lable: 'Phường Phong Cốc',
                            value: '07183',
                        },
                        {
                            lable: 'Phường Phong Hải',
                            value: '07174',
                        },
                        {
                            lable: 'Phường Quảng Yên',
                            value: '07132',
                        },
                        {
                            lable: 'Phường Tân An',
                            value: '07159',
                        },
                        {
                            lable: 'Phường Yên Giang',
                            value: '07162',
                        },
                        {
                            lable: 'Phường Yên Hải',
                            value: '07177',
                        },
                        {
                            lable: 'Phường Đông Mai',
                            value: '07135',
                        },
                        {
                            lable: 'Xã Cẩm La',
                            value: '07171',
                        },
                        {
                            lable: 'Xã Hiệp Hòa',
                            value: '07147',
                        },
                        {
                            lable: 'Xã Hoàng Tân',
                            value: '07156',
                        },
                        {
                            lable: 'Xã Liên Hòa',
                            value: '07180',
                        },
                        {
                            lable: 'Xã Liên Vị',
                            value: '07186',
                        },
                        {
                            lable: 'Xã Sông Khoai',
                            value: '07144',
                        },
                        {
                            lable: 'Xã Tiền An',
                            value: '07153',
                        },
                        {
                            lable: 'Xã Tiền Phong',
                            value: '07189',
                        },
                    ],
                },
                {
                    lable: 'Huyện Cô Tô',
                    value: '207',
                    wards: [
                        {
                            lable: 'Thị trấn Cô Tô',
                            value: '07192',
                        },
                        {
                            lable: 'Xã Thanh Lân',
                            value: '07198',
                        },
                        {
                            lable: 'Xã Đồng Tiến',
                            value: '07195',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Quảng Trị',
            value: '45',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Đông Hà',
                    value: '461',
                    wards: [
                        {
                            lable: 'Phường 1',
                            value: '19333',
                        },
                        {
                            lable: 'Phường 2',
                            value: '19342',
                        },
                        {
                            lable: 'Phường 3',
                            value: '19354',
                        },
                        {
                            lable: 'Phường 4',
                            value: '19345',
                        },
                        {
                            lable: 'Phường 5',
                            value: '19348',
                        },
                        {
                            lable: 'Phường Đông Giang',
                            value: '19330',
                        },
                        {
                            lable: 'Phường Đông Lương',
                            value: '19351',
                        },
                        {
                            lable: 'Phường Đông Lễ',
                            value: '19336',
                        },
                        {
                            lable: 'Phường Đông Thanh',
                            value: '19339',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Quảng Trị',
                    value: '462',
                    wards: [
                        {
                            lable: 'Phường 1',
                            value: '19357',
                        },
                        {
                            lable: 'Phường 2',
                            value: '19360',
                        },
                        {
                            lable: 'Phường 3',
                            value: '19361',
                        },
                        {
                            lable: 'Phường An Đôn',
                            value: '19358',
                        },
                        {
                            lable: 'Xã Hải Lệ',
                            value: '19705',
                        },
                    ],
                },
                {
                    lable: 'Huyện Vĩnh Linh',
                    value: '464',
                    wards: [
                        {
                            lable: 'Thị trấn Bến Quan',
                            value: '19366',
                        },
                        {
                            lable: 'Thị trấn Cửa Tùng',
                            value: '19414',
                        },
                        {
                            lable: 'Thị trấn Hồ Xá',
                            value: '19363',
                        },
                        {
                            lable: 'Xã Hiền Thành',
                            value: '19408',
                        },
                        {
                            lable: 'Xã Kim Thạch',
                            value: '19384',
                        },
                        {
                            lable: 'Xã Trung Nam',
                            value: '19378',
                        },
                        {
                            lable: 'Xã Vĩnh Chấp',
                            value: '19375',
                        },
                        {
                            lable: 'Xã Vĩnh Giang',
                            value: '19423',
                        },
                        {
                            lable: 'Xã Vĩnh Hà',
                            value: '19417',
                        },
                        {
                            lable: 'Xã Vĩnh Hòa',
                            value: '19396',
                        },
                        {
                            lable: 'Xã Vĩnh Khê',
                            value: '19393',
                        },
                        {
                            lable: 'Xã Vĩnh Long',
                            value: '19387',
                        },
                        {
                            lable: 'Xã Vĩnh Lâm',
                            value: '19405',
                        },
                        {
                            lable: 'Xã Vĩnh Sơn',
                            value: '19420',
                        },
                        {
                            lable: 'Xã Vĩnh Thái',
                            value: '19369',
                        },
                        {
                            lable: 'Xã Vĩnh Thủy',
                            value: '19402',
                        },
                        {
                            lable: 'Xã Vĩnh Tú',
                            value: '19372',
                        },
                        {
                            lable: 'Xã Vĩnh Ô',
                            value: '19426',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hướng Hóa',
                    value: '465',
                    wards: [
                        {
                            lable: 'Thị trấn Khe Sanh',
                            value: '19429',
                        },
                        {
                            lable: 'Thị trấn Lao Bảo',
                            value: '19432',
                        },
                        {
                            lable: 'Xã  A Dơi',
                            value: '19483',
                        },
                        {
                            lable: 'Xã Ba Tầng',
                            value: '19477',
                        },
                        {
                            lable: 'Xã Húc',
                            value: '19468',
                        },
                        {
                            lable: 'Xã Hướng Linh',
                            value: '19447',
                        },
                        {
                            lable: 'Xã Hướng Lập',
                            value: '19435',
                        },
                        {
                            lable: 'Xã Hướng Lộc',
                            value: '19474',
                        },
                        {
                            lable: 'Xã Hướng Phùng',
                            value: '19441',
                        },
                        {
                            lable: 'Xã Hướng Sơn',
                            value: '19444',
                        },
                        {
                            lable: 'Xã Hướng Tân',
                            value: '19453',
                        },
                        {
                            lable: 'Xã Hướng Việt',
                            value: '19438',
                        },
                        {
                            lable: 'Xã Lìa',
                            value: '19489',
                        },
                        {
                            lable: 'Xã Thanh',
                            value: '19480',
                        },
                        {
                            lable: 'Xã Thuận',
                            value: '19471',
                        },
                        {
                            lable: 'Xã Tân Hợp',
                            value: '19450',
                        },
                        {
                            lable: 'Xã Tân Liên',
                            value: '19465',
                        },
                        {
                            lable: 'Xã Tân Long',
                            value: '19459',
                        },
                        {
                            lable: 'Xã Tân Lập',
                            value: '19462',
                        },
                        {
                            lable: 'Xã Tân Thành',
                            value: '19456',
                        },
                        {
                            lable: 'Xã Xy',
                            value: '19492',
                        },
                    ],
                },
                {
                    lable: 'Huyện Gio Linh',
                    value: '466',
                    wards: [
                        {
                            lable: 'Thị trấn Cửa Việt',
                            value: '19496',
                        },
                        {
                            lable: 'Thị trấn Gio Linh',
                            value: '19495',
                        },
                        {
                            lable: 'Xã Gio An',
                            value: '19522',
                        },
                        {
                            lable: 'Xã Gio Châu',
                            value: '19525',
                        },
                        {
                            lable: 'Xã Gio Hải',
                            value: '19519',
                        },
                        {
                            lable: 'Xã Gio Mai',
                            value: '19543',
                        },
                        {
                            lable: 'Xã Gio Mỹ',
                            value: '19510',
                        },
                        {
                            lable: 'Xã Gio Quang',
                            value: '19552',
                        },
                        {
                            lable: 'Xã Gio Sơn',
                            value: '19537',
                        },
                        {
                            lable: 'Xã Gio Việt',
                            value: '19531',
                        },
                        {
                            lable: 'Xã Hải Thái',
                            value: '19546',
                        },
                        {
                            lable: 'Xã Linh Hải',
                            value: '19549',
                        },
                        {
                            lable: 'Xã Linh Trường',
                            value: '19534',
                        },
                        {
                            lable: 'Xã Phong Bình',
                            value: '19507',
                        },
                        {
                            lable: 'Xã Trung Giang',
                            value: '19498',
                        },
                        {
                            lable: 'Xã Trung Hải',
                            value: '19501',
                        },
                        {
                            lable: 'Xã Trung Sơn',
                            value: '19504',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đa Krông',
                    value: '467',
                    wards: [
                        {
                            lable: 'Thị trấn Krông Klang',
                            value: '19555',
                        },
                        {
                            lable: 'Xã A Bung',
                            value: '19591',
                        },
                        {
                            lable: 'Xã A Ngo',
                            value: '19594',
                        },
                        {
                            lable: 'Xã A Vao',
                            value: '19585',
                        },
                        {
                            lable: 'Xã Ba Lòng',
                            value: '19570',
                        },
                        {
                            lable: 'Xã Ba Nang',
                            value: '19576',
                        },
                        {
                            lable: 'Xã Húc Nghì',
                            value: '19582',
                        },
                        {
                            lable: 'Xã Hướng Hiệp',
                            value: '19561',
                        },
                        {
                            lable: 'Xã Mò Ó',
                            value: '19558',
                        },
                        {
                            lable: 'Xã Triệu Nguyên',
                            value: '19567',
                        },
                        {
                            lable: 'Xã Tà Long',
                            value: '19579',
                        },
                        {
                            lable: 'Xã Tà Rụt',
                            value: '19588',
                        },
                        {
                            lable: 'Xã Đa Krông',
                            value: '19564',
                        },
                    ],
                },
                {
                    lable: 'Huyện Cam Lộ',
                    value: '468',
                    wards: [
                        {
                            lable: 'Thị trấn Cam Lộ',
                            value: '19597',
                        },
                        {
                            lable: 'Xã Cam Chính',
                            value: '19618',
                        },
                        {
                            lable: 'Xã Cam Hiếu',
                            value: '19615',
                        },
                        {
                            lable: 'Xã Cam Nghĩa',
                            value: '19621',
                        },
                        {
                            lable: 'Xã Cam Thành',
                            value: '19612',
                        },
                        {
                            lable: 'Xã Cam Thủy',
                            value: '19606',
                        },
                        {
                            lable: 'Xã Cam Tuyền',
                            value: '19600',
                        },
                        {
                            lable: 'Xã Thanh An',
                            value: '19603',
                        },
                    ],
                },
                {
                    lable: 'Huyện Triệu Phong',
                    value: '469',
                    wards: [
                        {
                            lable: 'Thị Trấn Ái Tử',
                            value: '19624',
                        },
                        {
                            lable: 'Xã Triệu An',
                            value: '19627',
                        },
                        {
                            lable: 'Xã Triệu Giang',
                            value: '19675',
                        },
                        {
                            lable: 'Xã Triệu Hòa',
                            value: '19648',
                        },
                        {
                            lable: 'Xã Triệu Long',
                            value: '19657',
                        },
                        {
                            lable: 'Xã Triệu Lăng',
                            value: '19651',
                        },
                        {
                            lable: 'Xã Triệu Phước',
                            value: '19633',
                        },
                        {
                            lable: 'Xã Triệu Sơn',
                            value: '19654',
                        },
                        {
                            lable: 'Xã Triệu Thuận',
                            value: '19642',
                        },
                        {
                            lable: 'Xã Triệu Thành',
                            value: '19678',
                        },
                        {
                            lable: 'Xã Triệu Thượng',
                            value: '19672',
                        },
                        {
                            lable: 'Xã Triệu Trung',
                            value: '19666',
                        },
                        {
                            lable: 'Xã Triệu Trạch',
                            value: '19639',
                        },
                        {
                            lable: 'Xã Triệu Tài',
                            value: '19660',
                        },
                        {
                            lable: 'Xã Triệu Vân',
                            value: '19630',
                        },
                        {
                            lable: 'Xã Triệu Ái',
                            value: '19669',
                        },
                        {
                            lable: 'Xã Triệu Đại',
                            value: '19645',
                        },
                        {
                            lable: 'Xã Triệu Độ',
                            value: '19636',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hải Lăng',
                    value: '470',
                    wards: [
                        {
                            lable: 'Thị trấn Diên Sanh',
                            value: '19681',
                        },
                        {
                            lable: 'Xã Hải An',
                            value: '19684',
                        },
                        {
                            lable: 'Xã Hải Ba',
                            value: '19687',
                        },
                        {
                            lable: 'Xã Hải Chánh',
                            value: '19738',
                        },
                        {
                            lable: 'Xã Hải Dương',
                            value: '19711',
                        },
                        {
                            lable: 'Xã Hải Hưng',
                            value: '19699',
                        },
                        {
                            lable: 'Xã Hải Khê',
                            value: '19741',
                        },
                        {
                            lable: 'Xã Hải Lâm',
                            value: '19717',
                        },
                        {
                            lable: 'Xã Hải Phong',
                            value: '19726',
                        },
                        {
                            lable: 'Xã Hải Phú',
                            value: '19702',
                        },
                        {
                            lable: 'Xã Hải Quy',
                            value: '19693',
                        },
                        {
                            lable: 'Xã Hải Quế',
                            value: '19696',
                        },
                        {
                            lable: 'Xã Hải Sơn',
                            value: '19735',
                        },
                        {
                            lable: 'Xã Hải Thượng',
                            value: '19708',
                        },
                        {
                            lable: 'Xã Hải Trường',
                            value: '19729',
                        },
                        {
                            lable: 'Xã Hải Định',
                            value: '19714',
                        },
                    ],
                },
                {
                    lable: 'Huyện Cồn Cỏ',
                    value: '471',
                    wards: [],
                },
            ],
        },
        {
            lable: 'Tỉnh Sóc Trăng',
            value: '94',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Sóc Trăng',
                    value: '941',
                    wards: [
                        {
                            lable: 'Phường 1',
                            value: '31513',
                        },
                        {
                            lable: 'Phường 10',
                            value: '31525',
                        },
                        {
                            lable: 'Phường 2',
                            value: '31510',
                        },
                        {
                            lable: 'Phường 3',
                            value: '31519',
                        },
                        {
                            lable: 'Phường 4',
                            value: '31516',
                        },
                        {
                            lable: 'Phường 5',
                            value: '31498',
                        },
                        {
                            lable: 'Phường 6',
                            value: '31507',
                        },
                        {
                            lable: 'Phường 7',
                            value: '31501',
                        },
                        {
                            lable: 'Phường 8',
                            value: '31504',
                        },
                        {
                            lable: 'Phường 9',
                            value: '31522',
                        },
                    ],
                },
                {
                    lable: 'Huyện Châu Thành',
                    value: '942',
                    wards: [
                        {
                            lable: 'Thị trấn Châu Thành',
                            value: '31569',
                        },
                        {
                            lable: 'Xã An Hiệp',
                            value: '31594',
                        },
                        {
                            lable: 'Xã An Ninh',
                            value: '31600',
                        },
                        {
                            lable: 'Xã Hồ Đắc Kiện',
                            value: '31570',
                        },
                        {
                            lable: 'Xã Phú Tâm',
                            value: '31573',
                        },
                        {
                            lable: 'Xã Phú Tân',
                            value: '31582',
                        },
                        {
                            lable: 'Xã Thiện Mỹ',
                            value: '31585',
                        },
                        {
                            lable: 'Xã Thuận Hòa',
                            value: '31576',
                        },
                    ],
                },
                {
                    lable: 'Huyện Kế Sách',
                    value: '943',
                    wards: [
                        {
                            lable: 'Thị trấn An Lạc Thôn',
                            value: '31531',
                        },
                        {
                            lable: 'Thị trấn Kế Sách',
                            value: '31528',
                        },
                        {
                            lable: 'Xã An Lạc Tây',
                            value: '31540',
                        },
                        {
                            lable: 'Xã An Mỹ',
                            value: '31564',
                        },
                        {
                            lable: 'Xã Ba Trinh',
                            value: '31546',
                        },
                        {
                            lable: 'Xã Kế An',
                            value: '31558',
                        },
                        {
                            lable: 'Xã Kế Thành',
                            value: '31555',
                        },
                        {
                            lable: 'Xã Nhơn Mỹ',
                            value: '31552',
                        },
                        {
                            lable: 'Xã Phong Nẫm',
                            value: '31537',
                        },
                        {
                            lable: 'Xã Thới An Hội',
                            value: '31549',
                        },
                        {
                            lable: 'Xã Trinh Phú',
                            value: '31543',
                        },
                        {
                            lable: 'Xã Xuân Hòa',
                            value: '31534',
                        },
                        {
                            lable: 'Xã Đại Hải',
                            value: '31561',
                        },
                    ],
                },
                {
                    lable: 'Huyện Mỹ Tú',
                    value: '944',
                    wards: [
                        {
                            lable: 'Thị trấn Huỳnh Hữu Nghĩa',
                            value: '31567',
                        },
                        {
                            lable: 'Xã Hưng Phú',
                            value: '31588',
                        },
                        {
                            lable: 'Xã Long Hưng',
                            value: '31579',
                        },
                        {
                            lable: 'Xã Mỹ Hương',
                            value: '31591',
                        },
                        {
                            lable: 'Xã Mỹ Phước',
                            value: '31603',
                        },
                        {
                            lable: 'Xã Mỹ Thuận',
                            value: '31609',
                        },
                        {
                            lable: 'Xã Mỹ Tú',
                            value: '31597',
                        },
                        {
                            lable: 'Xã Phú Mỹ',
                            value: '31612',
                        },
                        {
                            lable: 'Xã Thuận Hưng',
                            value: '31606',
                        },
                    ],
                },
                {
                    lable: 'Huyện Cù Lao Dung',
                    value: '945',
                    wards: [
                        {
                            lable: 'Thị trấn Cù Lao Dung',
                            value: '31615',
                        },
                        {
                            lable: 'Xã An Thạnh 1',
                            value: '31618',
                        },
                        {
                            lable: 'Xã An Thạnh 2',
                            value: '31630',
                        },
                        {
                            lable: 'Xã An Thạnh 3',
                            value: '31633',
                        },
                        {
                            lable: 'Xã An Thạnh Nam',
                            value: '31636',
                        },
                        {
                            lable: 'Xã An Thạnh Tây',
                            value: '31621',
                        },
                        {
                            lable: 'Xã An Thạnh Đông',
                            value: '31624',
                        },
                        {
                            lable: 'Xã Đại Ân 1',
                            value: '31627',
                        },
                    ],
                },
                {
                    lable: 'Huyện Long Phú',
                    value: '946',
                    wards: [
                        {
                            lable: 'Thị trấn Long Phú',
                            value: '31639',
                        },
                        {
                            lable: 'Thị trấn Đại Ngãi',
                            value: '31645',
                        },
                        {
                            lable: 'Xã Châu Khánh',
                            value: '31663',
                        },
                        {
                            lable: 'Xã Hậu Thạnh',
                            value: '31648',
                        },
                        {
                            lable: 'Xã Long Phú',
                            value: '31669',
                        },
                        {
                            lable: 'Xã Long Đức',
                            value: '31651',
                        },
                        {
                            lable: 'Xã Phú Hữu',
                            value: '31657',
                        },
                        {
                            lable: 'Xã Song Phụng',
                            value: '31642',
                        },
                        {
                            lable: 'Xã Trường Khánh',
                            value: '31654',
                        },
                        {
                            lable: 'Xã Tân Hưng',
                            value: '31660',
                        },
                        {
                            lable: 'Xã Tân Thạnh',
                            value: '31666',
                        },
                    ],
                },
                {
                    lable: 'Huyện Mỹ Xuyên',
                    value: '947',
                    wards: [
                        {
                            lable: 'Thị trấn Mỹ Xuyên',
                            value: '31684',
                        },
                        {
                            lable: 'Xã Gia Hòa 1',
                            value: '31720',
                        },
                        {
                            lable: 'Xã Gia Hòa 2',
                            value: '31726',
                        },
                        {
                            lable: 'Xã Hòa Tú 1',
                            value: '31717',
                        },
                        {
                            lable: 'Xã Hòa Tú II',
                            value: '31729',
                        },
                        {
                            lable: 'Xã Ngọc Tố',
                            value: '31723',
                        },
                        {
                            lable: 'Xã Ngọc Đông',
                            value: '31711',
                        },
                        {
                            lable: 'Xã Tham Đôn',
                            value: '31693',
                        },
                        {
                            lable: 'Xã Thạnh Phú',
                            value: '31708',
                        },
                        {
                            lable: 'Xã Thạnh Quới',
                            value: '31714',
                        },
                        {
                            lable: 'Xã Đại Tâm',
                            value: '31690',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Ngã Năm',
                    value: '948',
                    wards: [
                        {
                            lable: 'Phường 1',
                            value: '31732',
                        },
                        {
                            lable: 'Phường 2',
                            value: '31735',
                        },
                        {
                            lable: 'Phường 3',
                            value: '31747',
                        },
                        {
                            lable: 'Xã Long Bình',
                            value: '31744',
                        },
                        {
                            lable: 'Xã Mỹ Bình',
                            value: '31750',
                        },
                        {
                            lable: 'Xã Mỹ Quới',
                            value: '31753',
                        },
                        {
                            lable: 'Xã Tân Long',
                            value: '31741',
                        },
                        {
                            lable: 'Xã Vĩnh Quới',
                            value: '31738',
                        },
                    ],
                },
                {
                    lable: 'Huyện Thạnh Trị',
                    value: '949',
                    wards: [
                        {
                            lable: 'Thị trấn Hưng Lợi',
                            value: '31757',
                        },
                        {
                            lable: 'Thị trấn Phú Lộc',
                            value: '31756',
                        },
                        {
                            lable: 'Xã Châu Hưng',
                            value: '31780',
                        },
                        {
                            lable: 'Xã Lâm Kiết',
                            value: '31765',
                        },
                        {
                            lable: 'Xã Lâm Tân',
                            value: '31759',
                        },
                        {
                            lable: 'Xã Thạnh Trị',
                            value: '31774',
                        },
                        {
                            lable: 'Xã Thạnh Tân',
                            value: '31762',
                        },
                        {
                            lable: 'Xã Tuân Tức',
                            value: '31768',
                        },
                        {
                            lable: 'Xã Vĩnh Lợi',
                            value: '31777',
                        },
                        {
                            lable: 'Xã Vĩnh Thành',
                            value: '31771',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Vĩnh Châu',
                    value: '950',
                    wards: [
                        {
                            lable: 'Phường 1',
                            value: '31783',
                        },
                        {
                            lable: 'Phường 2',
                            value: '31801',
                        },
                        {
                            lable: 'Phường Khánh Hòa',
                            value: '31789',
                        },
                        {
                            lable: 'Phường Vĩnh Phước',
                            value: '31804',
                        },
                        {
                            lable: 'Xã Hòa Đông',
                            value: '31786',
                        },
                        {
                            lable: 'Xã Lai Hòa',
                            value: '31810',
                        },
                        {
                            lable: 'Xã Lạc Hòa',
                            value: '31798',
                        },
                        {
                            lable: 'Xã Vĩnh Hiệp',
                            value: '31792',
                        },
                        {
                            lable: 'Xã Vĩnh Hải',
                            value: '31795',
                        },
                        {
                            lable: 'Xã Vĩnh Tân',
                            value: '31807',
                        },
                    ],
                },
                {
                    lable: 'Huyện Trần Đề',
                    value: '951',
                    wards: [
                        {
                            lable: 'Thị trấn Lịch Hội Thượng',
                            value: '31679',
                        },
                        {
                            lable: 'Thị trấn Trần Đề',
                            value: '31673',
                        },
                        {
                            lable: 'Xã Liêu Tú',
                            value: '31675',
                        },
                        {
                            lable: 'Xã Lịch Hội Thượng',
                            value: '31678',
                        },
                        {
                            lable: 'Xã Thạnh Thới An',
                            value: '31699',
                        },
                        {
                            lable: 'Xã Thạnh Thới Thuận',
                            value: '31702',
                        },
                        {
                            lable: 'Xã Trung Bình',
                            value: '31681',
                        },
                        {
                            lable: 'Xã Tài Văn',
                            value: '31687',
                        },
                        {
                            lable: 'Xã Viên An',
                            value: '31696',
                        },
                        {
                            lable: 'Xã Viên Bình',
                            value: '31705',
                        },
                        {
                            lable: 'Xã Đại Ân  2',
                            value: '31672',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Sơn La',
            value: '14',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Sơn La',
                    value: '116',
                    wards: [
                        {
                            lable: 'Phường Chiềng An',
                            value: '03667',
                        },
                        {
                            lable: 'Phường Chiềng Cơi',
                            value: '03670',
                        },
                        {
                            lable: 'Phường Chiềng Lề',
                            value: '03646',
                        },
                        {
                            lable: 'Phường Chiềng Sinh',
                            value: '03679',
                        },
                        {
                            lable: 'Phường Quyết Thắng',
                            value: '03652',
                        },
                        {
                            lable: 'Phường Quyết Tâm',
                            value: '03655',
                        },
                        {
                            lable: 'Phường Tô Hiệu',
                            value: '03649',
                        },
                        {
                            lable: 'Xã Chiềng Cọ',
                            value: '03658',
                        },
                        {
                            lable: 'Xã Chiềng Ngần',
                            value: '03673',
                        },
                        {
                            lable: 'Xã Chiềng Xôm',
                            value: '03664',
                        },
                        {
                            lable: 'Xã Chiềng Đen',
                            value: '03661',
                        },
                        {
                            lable: 'Xã Hua La',
                            value: '03676',
                        },
                    ],
                },
                {
                    lable: 'Huyện Quỳnh Nhai',
                    value: '118',
                    wards: [
                        {
                            lable: 'Xã Chiềng Bằng',
                            value: '03706',
                        },
                        {
                            lable: 'Xã Chiềng Khay',
                            value: '03688',
                        },
                        {
                            lable: 'Xã Chiềng Khoang',
                            value: '03718',
                        },
                        {
                            lable: 'Xã Chiềng Ơn',
                            value: '03700',
                        },
                        {
                            lable: 'Xã Cà Nàng',
                            value: '03685',
                        },
                        {
                            lable: 'Xã Mường Chiên',
                            value: '03682',
                        },
                        {
                            lable: 'Xã Mường Giàng',
                            value: '03703',
                        },
                        {
                            lable: 'Xã Mường Giôn',
                            value: '03694',
                        },
                        {
                            lable: 'Xã Mường Sại',
                            value: '03709',
                        },
                        {
                            lable: 'Xã Nậm ét',
                            value: '03712',
                        },
                        {
                            lable: 'Xã Pá Ma Pha Khinh',
                            value: '03697',
                        },
                    ],
                },
                {
                    lable: 'Huyện Thuận Châu',
                    value: '119',
                    wards: [
                        {
                            lable: 'Thị trấn Thuận Châu',
                            value: '03721',
                        },
                        {
                            lable: 'Xã Bon Phặng',
                            value: '03793',
                        },
                        {
                            lable: 'Xã Bó Mười',
                            value: '03778',
                        },
                        {
                            lable: 'Xã Bản Lầm',
                            value: '03805',
                        },
                        {
                            lable: 'Xã Chiềng Bôm',
                            value: '03766',
                        },
                        {
                            lable: 'Xã Chiềng La',
                            value: '03733',
                        },
                        {
                            lable: 'Xã Chiềng Ly',
                            value: '03751',
                        },
                        {
                            lable: 'Xã Chiềng Ngàm',
                            value: '03736',
                        },
                        {
                            lable: 'Xã Chiềng Pha',
                            value: '03730',
                        },
                        {
                            lable: 'Xã Chiềng Pấc',
                            value: '03787',
                        },
                        {
                            lable: 'Xã Co Mạ',
                            value: '03781',
                        },
                        {
                            lable: 'Xã Co Tòng',
                            value: '03796',
                        },
                        {
                            lable: 'Xã Liệp Tè',
                            value: '03739',
                        },
                        {
                            lable: 'Xã Long Hẹ',
                            value: '03763',
                        },
                        {
                            lable: 'Xã Muổi Nọi',
                            value: '03799',
                        },
                        {
                            lable: 'Xã Mường Bám',
                            value: '03760',
                        },
                        {
                            lable: 'Xã Mường Khiêng',
                            value: '03757',
                        },
                        {
                            lable: 'Xã Mường é',
                            value: '03727',
                        },
                        {
                            lable: 'Xã Noong Lay',
                            value: '03754',
                        },
                        {
                            lable: 'Xã Nậm Lầu',
                            value: '03790',
                        },
                        {
                            lable: 'Xã Phổng Lái',
                            value: '03724',
                        },
                        {
                            lable: 'Xã Phổng Lăng',
                            value: '03748',
                        },
                        {
                            lable: 'Xã Phổng Lập',
                            value: '03745',
                        },
                        {
                            lable: 'Xã Pá Lông',
                            value: '03802',
                        },
                        {
                            lable: 'Xã Púng Tra',
                            value: '03784',
                        },
                        {
                            lable: 'Xã Thôm Mòn',
                            value: '03769',
                        },
                        {
                            lable: 'Xã Tông Cọ',
                            value: '03775',
                        },
                        {
                            lable: 'Xã Tông Lạnh',
                            value: '03772',
                        },
                        {
                            lable: 'Xã é Tòng',
                            value: '03742',
                        },
                    ],
                },
                {
                    lable: 'Huyện Mường La',
                    value: '120',
                    wards: [
                        {
                            lable: 'Thị trấn Ít Ong',
                            value: '03808',
                        },
                        {
                            lable: 'Xã Chiềng Công',
                            value: '03838',
                        },
                        {
                            lable: 'Xã Chiềng Hoa',
                            value: '03850',
                        },
                        {
                            lable: 'Xã Chiềng Lao',
                            value: '03814',
                        },
                        {
                            lable: 'Xã Chiềng Muôn',
                            value: '03829',
                        },
                        {
                            lable: 'Xã Chiềng San',
                            value: '03844',
                        },
                        {
                            lable: 'Xã Chiềng Ân',
                            value: '03832',
                        },
                        {
                            lable: 'Xã Hua Trai',
                            value: '03817',
                        },
                        {
                            lable: 'Xã Mường Bú',
                            value: '03847',
                        },
                        {
                            lable: 'Xã Mường Chùm',
                            value: '03853',
                        },
                        {
                            lable: 'Xã Mường Trai',
                            value: '03823',
                        },
                        {
                            lable: 'Xã Ngọc Chiến',
                            value: '03820',
                        },
                        {
                            lable: 'Xã Nậm Giôn',
                            value: '03811',
                        },
                        {
                            lable: 'Xã Nậm Păm',
                            value: '03826',
                        },
                        {
                            lable: 'Xã Pi Toong',
                            value: '03835',
                        },
                        {
                            lable: 'Xã Tạ Bú',
                            value: '03841',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bắc Yên',
                    value: '121',
                    wards: [
                        {
                            lable: 'Thị trấn Bắc Yên',
                            value: '03856',
                        },
                        {
                            lable: 'Xã Chim Vàn',
                            value: '03877',
                        },
                        {
                            lable: 'Xã Chiềng Sại',
                            value: '03895',
                        },
                        {
                            lable: 'Xã Hang Chú',
                            value: '03862',
                        },
                        {
                            lable: 'Xã Hua Nhàn',
                            value: '03890',
                        },
                        {
                            lable: 'Xã Háng Đồng',
                            value: '03869',
                        },
                        {
                            lable: 'Xã Hồng Ngài',
                            value: '03886',
                        },
                        {
                            lable: 'Xã Làng Chếu',
                            value: '03874',
                        },
                        {
                            lable: 'Xã Mường Khoa',
                            value: '03880',
                        },
                        {
                            lable: 'Xã Phiêng Ban',
                            value: '03859',
                        },
                        {
                            lable: 'Xã Phiêng Côn',
                            value: '03892',
                        },
                        {
                            lable: 'Xã Pắc Ngà',
                            value: '03871',
                        },
                        {
                            lable: 'Xã Song Pe',
                            value: '03883',
                        },
                        {
                            lable: 'Xã Tà Xùa',
                            value: '03868',
                        },
                        {
                            lable: 'Xã Tạ Khoa',
                            value: '03889',
                        },
                        {
                            lable: 'Xã Xím Vàng',
                            value: '03865',
                        },
                    ],
                },
                {
                    lable: 'Huyện Phù Yên',
                    value: '122',
                    wards: [
                        {
                            lable: 'Thị trấn Phù Yên',
                            value: '03898',
                        },
                        {
                            lable: 'Xã Bắc Phong',
                            value: '03976',
                        },
                        {
                            lable: 'Xã Gia Phù',
                            value: '03922',
                        },
                        {
                            lable: 'Xã Huy Bắc',
                            value: '03913',
                        },
                        {
                            lable: 'Xã Huy Hạ',
                            value: '03928',
                        },
                        {
                            lable: 'Xã Huy Thượng',
                            value: '03916',
                        },
                        {
                            lable: 'Xã Huy Tân',
                            value: '03931',
                        },
                        {
                            lable: 'Xã Huy Tường',
                            value: '03940',
                        },
                        {
                            lable: 'Xã Kim Bon',
                            value: '03961',
                        },
                        {
                            lable: 'Xã Mường Bang',
                            value: '03964',
                        },
                        {
                            lable: 'Xã Mường Cơi',
                            value: '03907',
                        },
                        {
                            lable: 'Xã Mường Do',
                            value: '03943',
                        },
                        {
                            lable: 'Xã Mường Lang',
                            value: '03934',
                        },
                        {
                            lable: 'Xã Mường Thải',
                            value: '03904',
                        },
                        {
                            lable: 'Xã Nam Phong',
                            value: '03973',
                        },
                        {
                            lable: 'Xã Quang Huy',
                            value: '03910',
                        },
                        {
                            lable: 'Xã Suối Bau',
                            value: '03937',
                        },
                        {
                            lable: 'Xã Suối Tọ',
                            value: '03901',
                        },
                        {
                            lable: 'Xã Sập Xa',
                            value: '03946',
                        },
                        {
                            lable: 'Xã Tân Lang',
                            value: '03919',
                        },
                        {
                            lable: 'Xã Tân Phong',
                            value: '03970',
                        },
                        {
                            lable: 'Xã Tường Hạ',
                            value: '03958',
                        },
                        {
                            lable: 'Xã Tường Phong',
                            value: '03955',
                        },
                        {
                            lable: 'Xã Tường Phù',
                            value: '03925',
                        },
                        {
                            lable: 'Xã Tường Thượng',
                            value: '03949',
                        },
                        {
                            lable: 'Xã Tường Tiến',
                            value: '03952',
                        },
                        {
                            lable: 'Xã Đá Đỏ',
                            value: '03967',
                        },
                    ],
                },
                {
                    lable: 'Huyện Mộc Châu',
                    value: '123',
                    wards: [
                        {
                            lable: 'Thị trấn Mộc Châu',
                            value: '03979',
                        },
                        {
                            lable: 'Thị trấn NT Mộc Châu',
                            value: '03982',
                        },
                        {
                            lable: 'Xã Chiềng Hắc',
                            value: '04012',
                        },
                        {
                            lable: 'Xã Chiềng Khừa',
                            value: '04024',
                        },
                        {
                            lable: 'Xã Chiềng Sơn',
                            value: '03985',
                        },
                        {
                            lable: 'Xã Hua Păng',
                            value: '04015',
                        },
                        {
                            lable: 'Xã Lóng Sập',
                            value: '04045',
                        },
                        {
                            lable: 'Xã Mường Sang',
                            value: '04027',
                        },
                        {
                            lable: 'Xã Nà Mường',
                            value: '04000',
                        },
                        {
                            lable: 'Xã Phiêng Luông',
                            value: '04033',
                        },
                        {
                            lable: 'Xã Qui Hướng',
                            value: '03991',
                        },
                        {
                            lable: 'Xã Tà Lai',
                            value: '04003',
                        },
                        {
                            lable: 'Xã Tân Hợp',
                            value: '03988',
                        },
                        {
                            lable: 'Xã Tân Lập',
                            value: '03997',
                        },
                        {
                            lable: 'Xã Đông Sang',
                            value: '04030',
                        },
                    ],
                },
                {
                    lable: 'Huyện Yên Châu',
                    value: '124',
                    wards: [
                        {
                            lable: 'Thị trấn Yên Châu',
                            value: '04060',
                        },
                        {
                            lable: 'Xã Chiềng Hặc',
                            value: '04078',
                        },
                        {
                            lable: 'Xã Chiềng Khoi',
                            value: '04090',
                        },
                        {
                            lable: 'Xã Chiềng On',
                            value: '04084',
                        },
                        {
                            lable: 'Xã Chiềng Pằn',
                            value: '04072',
                        },
                        {
                            lable: 'Xã Chiềng Sàng',
                            value: '04069',
                        },
                        {
                            lable: 'Xã Chiềng Tương',
                            value: '04102',
                        },
                        {
                            lable: 'Xã Chiềng Đông',
                            value: '04063',
                        },
                        {
                            lable: 'Xã Lóng Phiêng',
                            value: '04096',
                        },
                        {
                            lable: 'Xã Mường Lựm',
                            value: '04081',
                        },
                        {
                            lable: 'Xã Phiêng Khoài',
                            value: '04099',
                        },
                        {
                            lable: 'Xã Sập Vạt',
                            value: '04066',
                        },
                        {
                            lable: 'Xã Tú Nang',
                            value: '04093',
                        },
                        {
                            lable: 'Xã Viêng Lán',
                            value: '04075',
                        },
                        {
                            lable: 'Xã Yên Sơn',
                            value: '04087',
                        },
                    ],
                },
                {
                    lable: 'Huyện Mai Sơn',
                    value: '125',
                    wards: [
                        {
                            lable: 'Thị trấn Hát Lót',
                            value: '04105',
                        },
                        {
                            lable: 'Xã Chiềng Ban',
                            value: '04120',
                        },
                        {
                            lable: 'Xã Chiềng Chung',
                            value: '04129',
                        },
                        {
                            lable: 'Xã Chiềng Chăn',
                            value: '04114',
                        },
                        {
                            lable: 'Xã Chiềng Dong',
                            value: '04147',
                        },
                        {
                            lable: 'Xã Chiềng Kheo',
                            value: '04150',
                        },
                        {
                            lable: 'Xã Chiềng Lương',
                            value: '04156',
                        },
                        {
                            lable: 'Xã Chiềng Mai',
                            value: '04132',
                        },
                        {
                            lable: 'Xã Chiềng Mung',
                            value: '04123',
                        },
                        {
                            lable: 'Xã Chiềng Nơi',
                            value: '04141',
                        },
                        {
                            lable: 'Xã Chiềng Sung',
                            value: '04108',
                        },
                        {
                            lable: 'Xã Chiềng Ve',
                            value: '04153',
                        },
                        {
                            lable: 'Xã Cò  Nòi',
                            value: '04138',
                        },
                        {
                            lable: 'Xã Hát Lót',
                            value: '04135',
                        },
                        {
                            lable: 'Xã Mương Chanh',
                            value: '04117',
                        },
                        {
                            lable: 'Xã Mường Bon',
                            value: '04126',
                        },
                        {
                            lable: 'Xã Mường Bằng',
                            value: '04111',
                        },
                        {
                            lable: 'Xã Nà Pó',
                            value: '04136',
                        },
                        {
                            lable: 'Xã Nà Ơt',
                            value: '04162',
                        },
                        {
                            lable: 'Xã Phiêng Cằm',
                            value: '04144',
                        },
                        {
                            lable: 'Xã Phiêng Pằn',
                            value: '04159',
                        },
                        {
                            lable: 'Xã Tà Hộc',
                            value: '04165',
                        },
                    ],
                },
                {
                    lable: 'Huyện Sông Mã',
                    value: '126',
                    wards: [
                        {
                            lable: 'Thị trấn Sông Mã',
                            value: '04168',
                        },
                        {
                            lable: 'Xã Bó Sinh',
                            value: '04171',
                        },
                        {
                            lable: 'Xã Chiềng Cang',
                            value: '04207',
                        },
                        {
                            lable: 'Xã Chiềng En',
                            value: '04180',
                        },
                        {
                            lable: 'Xã Chiềng Khoong',
                            value: '04204',
                        },
                        {
                            lable: 'Xã Chiềng Khương',
                            value: '04222',
                        },
                        {
                            lable: 'Xã Chiềng Phung',
                            value: '04177',
                        },
                        {
                            lable: 'Xã Chiềng Sơ',
                            value: '04195',
                        },
                        {
                            lable: 'Xã Huổi Một',
                            value: '04210',
                        },
                        {
                            lable: 'Xã Mường Cai',
                            value: '04216',
                        },
                        {
                            lable: 'Xã Mường Hung',
                            value: '04219',
                        },
                        {
                            lable: 'Xã Mường Lầm',
                            value: '04183',
                        },
                        {
                            lable: 'Xã Mường Sai',
                            value: '04213',
                        },
                        {
                            lable: 'Xã Nà Nghịu',
                            value: '04198',
                        },
                        {
                            lable: 'Xã Nậm Mằn',
                            value: '04201',
                        },
                        {
                            lable: 'Xã Nậm Ty',
                            value: '04186',
                        },
                        {
                            lable: 'Xã Pú Pẩu',
                            value: '04174',
                        },
                        {
                            lable: 'Xã Yên Hưng',
                            value: '04192',
                        },
                        {
                            lable: 'Xã Đứa Mòn',
                            value: '04189',
                        },
                    ],
                },
                {
                    lable: 'Huyện Sốp Cộp',
                    value: '127',
                    wards: [
                        {
                            lable: 'Xã Dồm Cang',
                            value: '04234',
                        },
                        {
                            lable: 'Xã Mường Lèo',
                            value: '04240',
                        },
                        {
                            lable: 'Xã Mường Lạn',
                            value: '04246',
                        },
                        {
                            lable: 'Xã Mường Và',
                            value: '04243',
                        },
                        {
                            lable: 'Xã Nậm Lạnh',
                            value: '04237',
                        },
                        {
                            lable: 'Xã Púng Bánh',
                            value: '04228',
                        },
                        {
                            lable: 'Xã Sam Kha',
                            value: '04225',
                        },
                        {
                            lable: 'Xã Sốp Cộp',
                            value: '04231',
                        },
                    ],
                },
                {
                    lable: 'Huyện Vân Hồ',
                    value: '128',
                    wards: [
                        {
                            lable: 'Xã Chiềng Khoa',
                            value: '04036',
                        },
                        {
                            lable: 'Xã Chiềng Xuân',
                            value: '04056',
                        },
                        {
                            lable: 'Xã Chiềng Yên',
                            value: '04054',
                        },
                        {
                            lable: 'Xã Liên Hoà',
                            value: '04009',
                        },
                        {
                            lable: 'Xã Lóng Luông',
                            value: '04051',
                        },
                        {
                            lable: 'Xã Mường Men',
                            value: '04039',
                        },
                        {
                            lable: 'Xã Mường Tè',
                            value: '04021',
                        },
                        {
                            lable: 'Xã Quang Minh',
                            value: '04042',
                        },
                        {
                            lable: 'Xã Song Khủa',
                            value: '04006',
                        },
                        {
                            lable: 'Xã Suối Bàng',
                            value: '03994',
                        },
                        {
                            lable: 'Xã Tân Xuân',
                            value: '04058',
                        },
                        {
                            lable: 'Xã Tô Múa',
                            value: '04018',
                        },
                        {
                            lable: 'Xã Vân Hồ',
                            value: '04048',
                        },
                        {
                            lable: 'Xã Xuân Nha',
                            value: '04057',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Tây Ninh',
            value: '72',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Tây Ninh',
                    value: '703',
                    wards: [
                        {
                            lable: 'Phường 1',
                            value: '25456',
                        },
                        {
                            lable: 'Phường 2',
                            value: '25468',
                        },
                        {
                            lable: 'Phường 3',
                            value: '25459',
                        },
                        {
                            lable: 'Phường 4',
                            value: '25462',
                        },
                        {
                            lable: 'Phường Hiệp Ninh',
                            value: '25465',
                        },
                        {
                            lable: 'Phường Ninh Sơn',
                            value: '25480',
                        },
                        {
                            lable: 'Phường Ninh Thạnh',
                            value: '25483',
                        },
                        {
                            lable: 'Xã Bình Minh',
                            value: '25477',
                        },
                        {
                            lable: 'Xã Thạnh Tân',
                            value: '25471',
                        },
                        {
                            lable: 'Xã Tân Bình',
                            value: '25474',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tân Biên',
                    value: '705',
                    wards: [
                        {
                            lable: 'Thị trấn Tân Biên',
                            value: '25486',
                        },
                        {
                            lable: 'Xã Hòa Hiệp',
                            value: '25504',
                        },
                        {
                            lable: 'Xã Mỏ Công',
                            value: '25510',
                        },
                        {
                            lable: 'Xã Thạnh Bình',
                            value: '25498',
                        },
                        {
                            lable: 'Xã Thạnh Bắc',
                            value: '25492',
                        },
                        {
                            lable: 'Xã Thạnh Tây',
                            value: '25501',
                        },
                        {
                            lable: 'Xã Trà Vong',
                            value: '25513',
                        },
                        {
                            lable: 'Xã Tân Bình',
                            value: '25495',
                        },
                        {
                            lable: 'Xã Tân Lập',
                            value: '25489',
                        },
                        {
                            lable: 'Xã Tân Phong',
                            value: '25507',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tân Châu',
                    value: '706',
                    wards: [
                        {
                            lable: 'Thị trấn Tân Châu',
                            value: '25516',
                        },
                        {
                            lable: 'Xã Suối Dây',
                            value: '25534',
                        },
                        {
                            lable: 'Xã Suối Ngô',
                            value: '25531',
                        },
                        {
                            lable: 'Xã Thạnh Đông',
                            value: '25540',
                        },
                        {
                            lable: 'Xã Tân Hiệp',
                            value: '25537',
                        },
                        {
                            lable: 'Xã Tân Hà',
                            value: '25519',
                        },
                        {
                            lable: 'Xã Tân Hòa',
                            value: '25528',
                        },
                        {
                            lable: 'Xã Tân Hưng',
                            value: '25549',
                        },
                        {
                            lable: 'Xã Tân Hội',
                            value: '25525',
                        },
                        {
                            lable: 'Xã Tân Phú',
                            value: '25546',
                        },
                        {
                            lable: 'Xã Tân Thành',
                            value: '25543',
                        },
                        {
                            lable: 'Xã Tân Đông',
                            value: '25522',
                        },
                    ],
                },
                {
                    lable: 'Huyện Dương Minh Châu',
                    value: '707',
                    wards: [
                        {
                            lable: 'Thị trấn Dương Minh Châu',
                            value: '25552',
                        },
                        {
                            lable: 'Xã Bàu Năng',
                            value: '25567',
                        },
                        {
                            lable: 'Xã Bến Củi',
                            value: '25576',
                        },
                        {
                            lable: 'Xã Chà Là',
                            value: '25570',
                        },
                        {
                            lable: 'Xã Cầu Khởi',
                            value: '25573',
                        },
                        {
                            lable: 'Xã Lộc Ninh',
                            value: '25579',
                        },
                        {
                            lable: 'Xã Phan',
                            value: '25558',
                        },
                        {
                            lable: 'Xã Phước Minh',
                            value: '25564',
                        },
                        {
                            lable: 'Xã Phước Ninh',
                            value: '25561',
                        },
                        {
                            lable: 'Xã Suối Đá',
                            value: '25555',
                        },
                        {
                            lable: 'Xã Truông Mít',
                            value: '25582',
                        },
                    ],
                },
                {
                    lable: 'Huyện Châu Thành',
                    value: '708',
                    wards: [
                        {
                            lable: 'Thị trấn Châu Thành',
                            value: '25585',
                        },
                        {
                            lable: 'Xã An Bình',
                            value: '25615',
                        },
                        {
                            lable: 'Xã An Cơ',
                            value: '25600',
                        },
                        {
                            lable: 'Xã Biên Giới',
                            value: '25603',
                        },
                        {
                            lable: 'Xã Hòa Hội',
                            value: '25612',
                        },
                        {
                            lable: 'Xã Hòa Thạnh',
                            value: '25606',
                        },
                        {
                            lable: 'Xã Hảo Đước',
                            value: '25588',
                        },
                        {
                            lable: 'Xã Long Vĩnh',
                            value: '25627',
                        },
                        {
                            lable: 'Xã Ninh Điền',
                            value: '25624',
                        },
                        {
                            lable: 'Xã Phước Vinh',
                            value: '25591',
                        },
                        {
                            lable: 'Xã Thanh Điền',
                            value: '25618',
                        },
                        {
                            lable: 'Xã Thành Long',
                            value: '25621',
                        },
                        {
                            lable: 'Xã Thái Bình',
                            value: '25597',
                        },
                        {
                            lable: 'Xã Trí Bình',
                            value: '25609',
                        },
                        {
                            lable: 'Xã Đồng Khởi',
                            value: '25594',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Hòa Thành',
                    value: '709',
                    wards: [
                        {
                            lable: 'Phường Hiệp Tân',
                            value: '25633',
                        },
                        {
                            lable: 'Phường Long Hoa',
                            value: '25630',
                        },
                        {
                            lable: 'Phường Long Thành Bắc',
                            value: '25636',
                        },
                        {
                            lable: 'Phường Long Thành Trung',
                            value: '25645',
                        },
                        {
                            lable: 'Xã Long Thành Nam',
                            value: '25651',
                        },
                        {
                            lable: 'Xã Trường Hòa',
                            value: '25639',
                        },
                        {
                            lable: 'Xã Trường Tây',
                            value: '25648',
                        },
                        {
                            lable: 'Xã Trường Đông',
                            value: '25642',
                        },
                    ],
                },
                {
                    lable: 'Huyện Gò Dầu',
                    value: '710',
                    wards: [
                        {
                            lable: 'Thị trấn Gò Dầu',
                            value: '25654',
                        },
                        {
                            lable: 'Xã Bàu Đồn',
                            value: '25666',
                        },
                        {
                            lable: 'Xã Cẩm Giang',
                            value: '25660',
                        },
                        {
                            lable: 'Xã Hiệp Thạnh',
                            value: '25663',
                        },
                        {
                            lable: 'Xã Phước Thạnh',
                            value: '25669',
                        },
                        {
                            lable: 'Xã Phước Trạch',
                            value: '25675',
                        },
                        {
                            lable: 'Xã Phước Đông',
                            value: '25672',
                        },
                        {
                            lable: 'Xã Thanh Phước',
                            value: '25678',
                        },
                        {
                            lable: 'Xã Thạnh Đức',
                            value: '25657',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bến Cầu',
                    value: '711',
                    wards: [
                        {
                            lable: 'Thị trấn Bến Cầu',
                            value: '25681',
                        },
                        {
                            lable: 'Xã An Thạnh',
                            value: '25705',
                        },
                        {
                            lable: 'Xã Long Chữ',
                            value: '25684',
                        },
                        {
                            lable: 'Xã Long Giang',
                            value: '25690',
                        },
                        {
                            lable: 'Xã Long Khánh',
                            value: '25696',
                        },
                        {
                            lable: 'Xã Long Phước',
                            value: '25687',
                        },
                        {
                            lable: 'Xã Long Thuận',
                            value: '25702',
                        },
                        {
                            lable: 'Xã Lợi Thuận',
                            value: '25699',
                        },
                        {
                            lable: 'Xã Tiên Thuận',
                            value: '25693',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Trảng Bàng',
                    value: '712',
                    wards: [
                        {
                            lable: 'Phường An Hòa',
                            value: '25735',
                        },
                        {
                            lable: 'Phường An Tịnh',
                            value: '25732',
                        },
                        {
                            lable: 'Phường Gia Bình',
                            value: '25723',
                        },
                        {
                            lable: 'Phường Gia Lộc',
                            value: '25720',
                        },
                        {
                            lable: 'Phường Lộc Hưng',
                            value: '25717',
                        },
                        {
                            lable: 'Phường Trảng Bàng',
                            value: '25708',
                        },
                        {
                            lable: 'Xã Hưng Thuận',
                            value: '25714',
                        },
                        {
                            lable: 'Xã Phước Bình',
                            value: '25729',
                        },
                        {
                            lable: 'Xã Phước Chỉ',
                            value: '25738',
                        },
                        {
                            lable: 'Xã Đôn Thuận',
                            value: '25711',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Thái Bình',
            value: '34',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Thái Bình',
                    value: '336',
                    wards: [
                        {
                            lable: 'Phường Bồ Xuyên',
                            value: '12436',
                        },
                        {
                            lable: 'Phường Hoàng Diệu',
                            value: '12460',
                        },
                        {
                            lable: 'Phường Kỳ Bá',
                            value: '12442',
                        },
                        {
                            lable: 'Phường Lê Hồng Phong',
                            value: '12433',
                        },
                        {
                            lable: 'Phường Phú Khánh',
                            value: '12448',
                        },
                        {
                            lable: 'Phường Quang Trung',
                            value: '12445',
                        },
                        {
                            lable: 'Phường Tiền Phong',
                            value: '12451',
                        },
                        {
                            lable: 'Phường Trần Hưng Đạo',
                            value: '12452',
                        },
                        {
                            lable: 'Phường Trần Lãm',
                            value: '12454',
                        },
                        {
                            lable: 'Phường Đề Thám',
                            value: '12439',
                        },
                        {
                            lable: 'Xã Phú Xuân',
                            value: '12463',
                        },
                        {
                            lable: 'Xã Tân Bình',
                            value: '13225',
                        },
                        {
                            lable: 'Xã Vũ Chính',
                            value: '12469',
                        },
                        {
                            lable: 'Xã Vũ Lạc',
                            value: '13108',
                        },
                        {
                            lable: 'Xã Vũ Phúc',
                            value: '12466',
                        },
                        {
                            lable: 'Xã Vũ Đông',
                            value: '13084',
                        },
                        {
                            lable: 'Xã Đông Hòa',
                            value: '12457',
                        },
                        {
                            lable: 'Xã Đông Mỹ',
                            value: '12817',
                        },
                        {
                            lable: 'Xã Đông Thọ',
                            value: '12820',
                        },
                    ],
                },
                {
                    lable: 'Huyện Quỳnh Phụ',
                    value: '338',
                    wards: [
                        {
                            lable: 'Thị trấn An Bài',
                            value: '12523',
                        },
                        {
                            lable: 'Thị trấn Quỳnh Côi',
                            value: '12472',
                        },
                        {
                            lable: 'Xã An Cầu',
                            value: '12502',
                        },
                        {
                            lable: 'Xã An Dục',
                            value: '12571',
                        },
                        {
                            lable: 'Xã An Hiệp',
                            value: '12490',
                        },
                        {
                            lable: 'Xã An Khê',
                            value: '12475',
                        },
                        {
                            lable: 'Xã An Lễ',
                            value: '12550',
                        },
                        {
                            lable: 'Xã An Mỹ',
                            value: '12559',
                        },
                        {
                            lable: 'Xã An Ninh',
                            value: '12514',
                        },
                        {
                            lable: 'Xã An Quí',
                            value: '12538',
                        },
                        {
                            lable: 'Xã An Thanh',
                            value: '12541',
                        },
                        {
                            lable: 'Xã An Thái',
                            value: '12499',
                        },
                        {
                            lable: 'Xã An Tràng',
                            value: '12580',
                        },
                        {
                            lable: 'Xã An Vinh',
                            value: '12565',
                        },
                        {
                            lable: 'Xã An Vũ',
                            value: '12547',
                        },
                        {
                            lable: 'Xã An Đồng',
                            value: '12478',
                        },
                        {
                            lable: 'Xã An Ấp',
                            value: '12526',
                        },
                        {
                            lable: 'Xã Châu Sơn',
                            value: '12532',
                        },
                        {
                            lable: 'Xã Quỳnh Bảo',
                            value: '12556',
                        },
                        {
                            lable: 'Xã Quỳnh Giao',
                            value: '12496',
                        },
                        {
                            lable: 'Xã Quỳnh Hoa',
                            value: '12481',
                        },
                        {
                            lable: 'Xã Quỳnh Hoàng',
                            value: '12493',
                        },
                        {
                            lable: 'Xã Quỳnh Hưng',
                            value: '12553',
                        },
                        {
                            lable: 'Xã Quỳnh Hải',
                            value: '12520',
                        },
                        {
                            lable: 'Xã Quỳnh Hồng',
                            value: '12505',
                        },
                        {
                            lable: 'Xã Quỳnh Hội',
                            value: '12529',
                        },
                        {
                            lable: 'Xã Quỳnh Khê',
                            value: '12508',
                        },
                        {
                            lable: 'Xã Quỳnh Lâm',
                            value: '12484',
                        },
                        {
                            lable: 'Xã Quỳnh Minh',
                            value: '12511',
                        },
                        {
                            lable: 'Xã Quỳnh Mỹ',
                            value: '12535',
                        },
                        {
                            lable: 'Xã Quỳnh Nguyên',
                            value: '12562',
                        },
                        {
                            lable: 'Xã Quỳnh Ngọc',
                            value: '12517',
                        },
                        {
                            lable: 'Xã Quỳnh Thọ',
                            value: '12487',
                        },
                        {
                            lable: 'Xã Quỳnh Trang',
                            value: '12577',
                        },
                        {
                            lable: 'Xã Quỳnh Xá',
                            value: '12568',
                        },
                        {
                            lable: 'Xã Đông Hải',
                            value: '12574',
                        },
                        {
                            lable: 'Xã Đồng Tiến',
                            value: '12583',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hưng Hà',
                    value: '339',
                    wards: [
                        {
                            lable: 'Thị trấn Hưng Hà',
                            value: '12586',
                        },
                        {
                            lable: 'Thị trấn Hưng Nhân',
                            value: '12613',
                        },
                        {
                            lable: 'Xã Bắc Sơn',
                            value: '12628',
                        },
                        {
                            lable: 'Xã Canh Tân',
                            value: '12601',
                        },
                        {
                            lable: 'Xã Chi Lăng',
                            value: '12656',
                        },
                        {
                            lable: 'Xã Chí Hòa',
                            value: '12679',
                        },
                        {
                            lable: 'Xã Cộng Hòa',
                            value: '12595',
                        },
                        {
                            lable: 'Xã Duyên Hải',
                            value: '12619',
                        },
                        {
                            lable: 'Xã Dân Chủ',
                            value: '12598',
                        },
                        {
                            lable: 'Xã Hòa Bình',
                            value: '12655',
                        },
                        {
                            lable: 'Xã Hòa Tiến',
                            value: '12604',
                        },
                        {
                            lable: 'Xã Hùng Dũng',
                            value: '12607',
                        },
                        {
                            lable: 'Xã Hồng An',
                            value: '12661',
                        },
                        {
                            lable: 'Xã Hồng Lĩnh',
                            value: '12667',
                        },
                        {
                            lable: 'Xã Hồng Minh',
                            value: '12685',
                        },
                        {
                            lable: 'Xã Kim Chung',
                            value: '12664',
                        },
                        {
                            lable: 'Xã Liên Hiệp',
                            value: '12637',
                        },
                        {
                            lable: 'Xã Minh Hòa',
                            value: '12682',
                        },
                        {
                            lable: 'Xã Minh Khai',
                            value: '12658',
                        },
                        {
                            lable: 'Xã Minh Tân',
                            value: '12670',
                        },
                        {
                            lable: 'Xã Phúc Khánh',
                            value: '12634',
                        },
                        {
                            lable: 'Xã Thái Hưng',
                            value: '12649',
                        },
                        {
                            lable: 'Xã Thái Phương',
                            value: '12652',
                        },
                        {
                            lable: 'Xã Thống Nhất',
                            value: '12643',
                        },
                        {
                            lable: 'Xã Tiến Đức',
                            value: '12646',
                        },
                        {
                            lable: 'Xã Tân Hòa',
                            value: '12622',
                        },
                        {
                            lable: 'Xã Tân Lễ',
                            value: '12592',
                        },
                        {
                            lable: 'Xã Tân Tiến',
                            value: '12610',
                        },
                        {
                            lable: 'Xã Tây Đô',
                            value: '12640',
                        },
                        {
                            lable: 'Xã Văn Cẩm',
                            value: '12625',
                        },
                        {
                            lable: 'Xã Văn Lang',
                            value: '12673',
                        },
                        {
                            lable: 'Xã Điệp Nông',
                            value: '12589',
                        },
                        {
                            lable: 'Xã Đoan Hùng',
                            value: '12616',
                        },
                        {
                            lable: 'Xã Đông Đô',
                            value: '12631',
                        },
                        {
                            lable: 'Xã Độc Lập',
                            value: '12676',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đông Hưng',
                    value: '340',
                    wards: [
                        {
                            lable: 'Thị trấn Đông Hưng',
                            value: '12688',
                        },
                        {
                            lable: 'Xã An Châu',
                            value: '12700',
                        },
                        {
                            lable: 'Xã Chương Dương',
                            value: '12727',
                        },
                        {
                            lable: 'Xã Hà Giang',
                            value: '12745',
                        },
                        {
                            lable: 'Xã Hồng Bạch',
                            value: '12778',
                        },
                        {
                            lable: 'Xã Hồng Giang',
                            value: '12790',
                        },
                        {
                            lable: 'Xã Hồng Việt',
                            value: '12739',
                        },
                        {
                            lable: 'Xã Hợp Tiến',
                            value: '12736',
                        },
                        {
                            lable: 'Xã Liên Giang',
                            value: '12697',
                        },
                        {
                            lable: 'Xã Liên Hoa',
                            value: '12763',
                        },
                        {
                            lable: 'Xã Lô Giang',
                            value: '12715',
                        },
                        {
                            lable: 'Xã Minh Phú',
                            value: '12823',
                        },
                        {
                            lable: 'Xã Minh Tân',
                            value: '12721',
                        },
                        {
                            lable: 'Xã Mê Linh',
                            value: '12712',
                        },
                        {
                            lable: 'Xã Nguyên Xá',
                            value: '12730',
                        },
                        {
                            lable: 'Xã Phong Châu',
                            value: '12733',
                        },
                        {
                            lable: 'Xã Phú Châu',
                            value: '12760',
                        },
                        {
                            lable: 'Xã Phú Lương',
                            value: '12709',
                        },
                        {
                            lable: 'Xã Thăng Long',
                            value: '12754',
                        },
                        {
                            lable: 'Xã Trọng Quan',
                            value: '12784',
                        },
                        {
                            lable: 'Xã Đô Lương',
                            value: '12691',
                        },
                        {
                            lable: 'Xã Đông Các',
                            value: '12757',
                        },
                        {
                            lable: 'Xã Đông Cường',
                            value: '12706',
                        },
                        {
                            lable: 'Xã Đông Dương',
                            value: '12811',
                        },
                        {
                            lable: 'Xã Đông Hoàng',
                            value: '12808',
                        },
                        {
                            lable: 'Xã Đông Hợp',
                            value: '12751',
                        },
                        {
                            lable: 'Xã Đông Kinh',
                            value: '12748',
                        },
                        {
                            lable: 'Xã Đông La',
                            value: '12718',
                        },
                        {
                            lable: 'Xã Đông Phương',
                            value: '12694',
                        },
                        {
                            lable: 'Xã Đông Quan',
                            value: '12793',
                        },
                        {
                            lable: 'Xã Đông Quang',
                            value: '12796',
                        },
                        {
                            lable: 'Xã Đông Sơn',
                            value: '12703',
                        },
                        {
                            lable: 'Xã Đông Tân',
                            value: '12769',
                        },
                        {
                            lable: 'Xã Đông Vinh',
                            value: '12772',
                        },
                        {
                            lable: 'Xã Đông Xuân',
                            value: '12799',
                        },
                        {
                            lable: 'Xã Đông Xá',
                            value: '12724',
                        },
                        {
                            lable: 'Xã Đông Á',
                            value: '12802',
                        },
                        {
                            lable: 'Xã Đông Động',
                            value: '12775',
                        },
                    ],
                },
                {
                    lable: 'Huyện Thái Thụy',
                    value: '341',
                    wards: [
                        {
                            lable: 'Thị trấn Diêm Điền',
                            value: '12826',
                        },
                        {
                            lable: 'Xã  Mỹ Lộc',
                            value: '12949',
                        },
                        {
                            lable: 'Xã An Tân',
                            value: '12847',
                        },
                        {
                            lable: 'Xã Dương Hồng  Thủy',
                            value: '12916',
                        },
                        {
                            lable: 'Xã Dương Phúc',
                            value: '12865',
                        },
                        {
                            lable: 'Xã Hòa An',
                            value: '12922',
                        },
                        {
                            lable: 'Xã Hồng Dũng',
                            value: '12841',
                        },
                        {
                            lable: 'Xã Sơn Hà',
                            value: '12925',
                        },
                        {
                            lable: 'Xã Thuần Thành',
                            value: '12964',
                        },
                        {
                            lable: 'Xã Thái Giang',
                            value: '12919',
                        },
                        {
                            lable: 'Xã Thái Hưng',
                            value: '12937',
                        },
                        {
                            lable: 'Xã Thái Nguyên',
                            value: '12910',
                        },
                        {
                            lable: 'Xã Thái Phúc',
                            value: '12934',
                        },
                        {
                            lable: 'Xã Thái Thượng',
                            value: '12907',
                        },
                        {
                            lable: 'Xã Thái Thịnh',
                            value: '12961',
                        },
                        {
                            lable: 'Xã Thái Thọ',
                            value: '12967',
                        },
                        {
                            lable: 'Xã Thái Xuyên',
                            value: '12943',
                        },
                        {
                            lable: 'Xã Thái Đô',
                            value: '12940',
                        },
                        {
                            lable: 'Xã Thụy Bình',
                            value: '12871',
                        },
                        {
                            lable: 'Xã Thụy Chính',
                            value: '12874',
                        },
                        {
                            lable: 'Xã Thụy Duyên',
                            value: '12892',
                        },
                        {
                            lable: 'Xã Thụy Dân',
                            value: '12877',
                        },
                        {
                            lable: 'Xã Thụy Hưng',
                            value: '12853',
                        },
                        {
                            lable: 'Xã Thụy Hải',
                            value: '12880',
                        },
                        {
                            lable: 'Xã Thụy Liên',
                            value: '12889',
                        },
                        {
                            lable: 'Xã Thụy Ninh',
                            value: '12850',
                        },
                        {
                            lable: 'Xã Thụy Phong',
                            value: '12904',
                        },
                        {
                            lable: 'Xã Thụy Quỳnh',
                            value: '12844',
                        },
                        {
                            lable: 'Xã Thụy Sơn',
                            value: '12901',
                        },
                        {
                            lable: 'Xã Thụy Thanh',
                            value: '12898',
                        },
                        {
                            lable: 'Xã Thụy Trình',
                            value: '12868',
                        },
                        {
                            lable: 'Xã Thụy Trường',
                            value: '12832',
                        },
                        {
                            lable: 'Xã Thụy Việt',
                            value: '12856',
                        },
                        {
                            lable: 'Xã Thụy Văn',
                            value: '12859',
                        },
                        {
                            lable: 'Xã Thụy Xuân',
                            value: '12862',
                        },
                        {
                            lable: 'Xã Tân Học',
                            value: '12958',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tiền Hải',
                    value: '342',
                    wards: [
                        {
                            lable: 'Thị trấn Tiền Hải',
                            value: '12970',
                        },
                        {
                            lable: 'Xã An Ninh',
                            value: '13012',
                        },
                        {
                            lable: 'Xã Bắc Hải',
                            value: '13048',
                        },
                        {
                            lable: 'Xã Nam Chính',
                            value: '13045',
                        },
                        {
                            lable: 'Xã Nam Cường',
                            value: '13036',
                        },
                        {
                            lable: 'Xã Nam Hà',
                            value: '13054',
                        },
                        {
                            lable: 'Xã Nam Hưng',
                            value: '13066',
                        },
                        {
                            lable: 'Xã Nam Hải',
                            value: '13069',
                        },
                        {
                            lable: 'Xã Nam Hồng',
                            value: '13063',
                        },
                        {
                            lable: 'Xã Nam Phú',
                            value: '13072',
                        },
                        {
                            lable: 'Xã Nam Thanh',
                            value: '13057',
                        },
                        {
                            lable: 'Xã Nam Thắng',
                            value: '13042',
                        },
                        {
                            lable: 'Xã Nam Thịnh',
                            value: '13051',
                        },
                        {
                            lable: 'Xã Nam Trung',
                            value: '13060',
                        },
                        {
                            lable: 'Xã Phương Công',
                            value: '13027',
                        },
                        {
                            lable: 'Xã Tây Giang',
                            value: '13021',
                        },
                        {
                            lable: 'Xã Tây Lương',
                            value: '12991',
                        },
                        {
                            lable: 'Xã Tây Ninh',
                            value: '12994',
                        },
                        {
                            lable: 'Xã Tây Phong',
                            value: '13030',
                        },
                        {
                            lable: 'Xã Tây Tiến',
                            value: '13033',
                        },
                        {
                            lable: 'Xã Vân Trường',
                            value: '13039',
                        },
                        {
                            lable: 'Xã Vũ Lăng',
                            value: '12985',
                        },
                        {
                            lable: 'Xã Đông Cơ',
                            value: '13018',
                        },
                        {
                            lable: 'Xã Đông Hoàng',
                            value: '13000',
                        },
                        {
                            lable: 'Xã Đông Long',
                            value: '12979',
                        },
                        {
                            lable: 'Xã Đông Lâm',
                            value: '13024',
                        },
                        {
                            lable: 'Xã Đông Minh',
                            value: '13003',
                        },
                        {
                            lable: 'Xã Đông Phong',
                            value: '13009',
                        },
                        {
                            lable: 'Xã Đông Quí',
                            value: '12982',
                        },
                        {
                            lable: 'Xã Đông Trung',
                            value: '12997',
                        },
                        {
                            lable: 'Xã Đông Trà',
                            value: '12976',
                        },
                        {
                            lable: 'Xã Đông Xuyên',
                            value: '12988',
                        },
                    ],
                },
                {
                    lable: 'Huyện Kiến Xương',
                    value: '343',
                    wards: [
                        {
                            lable: 'Thị trấn Kiến Xương',
                            value: '13075',
                        },
                        {
                            lable: 'Xã An Bình',
                            value: '13087',
                        },
                        {
                            lable: 'Xã Bình Minh',
                            value: '13138',
                        },
                        {
                            lable: 'Xã Bình Nguyên',
                            value: '13096',
                        },
                        {
                            lable: 'Xã Bình Thanh',
                            value: '13183',
                        },
                        {
                            lable: 'Xã Bình Định',
                            value: '13186',
                        },
                        {
                            lable: 'Xã Hòa Bình',
                            value: '13135',
                        },
                        {
                            lable: 'Xã Hồng Thái',
                            value: '13093',
                        },
                        {
                            lable: 'Xã Hồng Tiến',
                            value: '13189',
                        },
                        {
                            lable: 'Xã Lê Lợi',
                            value: '13102',
                        },
                        {
                            lable: 'Xã Minh Quang',
                            value: '13171',
                        },
                        {
                            lable: 'Xã Minh Tân',
                            value: '13177',
                        },
                        {
                            lable: 'Xã Nam Bình',
                            value: '13180',
                        },
                        {
                            lable: 'Xã Nam Cao',
                            value: '13120',
                        },
                        {
                            lable: 'Xã Quang Bình',
                            value: '13144',
                        },
                        {
                            lable: 'Xã Quang Lịch',
                            value: '13132',
                        },
                        {
                            lable: 'Xã Quang Minh',
                            value: '13162',
                        },
                        {
                            lable: 'Xã Quang Trung',
                            value: '13165',
                        },
                        {
                            lable: 'Xã Quốc Tuấn',
                            value: '13081',
                        },
                        {
                            lable: 'Xã Thanh Tân',
                            value: '13114',
                        },
                        {
                            lable: 'Xã Thượng Hiền',
                            value: '13117',
                        },
                        {
                            lable: 'Xã Trà Giang',
                            value: '13078',
                        },
                        {
                            lable: 'Xã Tây Sơn',
                            value: '13090',
                        },
                        {
                            lable: 'Xã Vũ An',
                            value: '13129',
                        },
                        {
                            lable: 'Xã Vũ Bình',
                            value: '13174',
                        },
                        {
                            lable: 'Xã Vũ Công',
                            value: '13156',
                        },
                        {
                            lable: 'Xã Vũ Hòa',
                            value: '13159',
                        },
                        {
                            lable: 'Xã Vũ Lễ',
                            value: '13111',
                        },
                        {
                            lable: 'Xã Vũ Ninh',
                            value: '13126',
                        },
                        {
                            lable: 'Xã Vũ Quí',
                            value: '13141',
                        },
                        {
                            lable: 'Xã Vũ Thắng',
                            value: '13153',
                        },
                        {
                            lable: 'Xã Vũ Trung',
                            value: '13150',
                        },
                        {
                            lable: 'Xã Đình Phùng',
                            value: '13123',
                        },
                    ],
                },
                {
                    lable: 'Huyện Vũ Thư',
                    value: '344',
                    wards: [
                        {
                            lable: 'Thị trấn Vũ Thư',
                            value: '13192',
                        },
                        {
                            lable: 'Xã Bách Thuận',
                            value: '13243',
                        },
                        {
                            lable: 'Xã Duy Nhất',
                            value: '13279',
                        },
                        {
                            lable: 'Xã Dũng Nghĩa',
                            value: '13231',
                        },
                        {
                            lable: 'Xã Hiệp Hòa',
                            value: '13204',
                        },
                        {
                            lable: 'Xã Hòa Bình',
                            value: '13258',
                        },
                        {
                            lable: 'Xã Hồng Lý',
                            value: '13195',
                        },
                        {
                            lable: 'Xã Hồng Phong',
                            value: '13282',
                        },
                        {
                            lable: 'Xã Minh Khai',
                            value: '13228',
                        },
                        {
                            lable: 'Xã Minh Lãng',
                            value: '13222',
                        },
                        {
                            lable: 'Xã Minh Quang',
                            value: '13234',
                        },
                        {
                            lable: 'Xã Nguyên Xá',
                            value: '13261',
                        },
                        {
                            lable: 'Xã Phúc Thành',
                            value: '13207',
                        },
                        {
                            lable: 'Xã Song An',
                            value: '13249',
                        },
                        {
                            lable: 'Xã Song Lãng',
                            value: '13213',
                        },
                        {
                            lable: 'Xã Tam Quang',
                            value: '13237',
                        },
                        {
                            lable: 'Xã Trung An',
                            value: '13252',
                        },
                        {
                            lable: 'Xã Tân Hòa',
                            value: '13216',
                        },
                        {
                            lable: 'Xã Tân Lập',
                            value: '13240',
                        },
                        {
                            lable: 'Xã Tân Phong',
                            value: '13210',
                        },
                        {
                            lable: 'Xã Tự Tân',
                            value: '13246',
                        },
                        {
                            lable: 'Xã Việt Hùng',
                            value: '13219',
                        },
                        {
                            lable: 'Xã Việt Thuận',
                            value: '13264',
                        },
                        {
                            lable: 'Xã Vũ Hội',
                            value: '13255',
                        },
                        {
                            lable: 'Xã Vũ Tiến',
                            value: '13273',
                        },
                        {
                            lable: 'Xã Vũ Vinh',
                            value: '13267',
                        },
                        {
                            lable: 'Xã Vũ Vân',
                            value: '13276',
                        },
                        {
                            lable: 'Xã Vũ Đoài',
                            value: '13270',
                        },
                        {
                            lable: 'Xã Xuân Hòa',
                            value: '13201',
                        },
                        {
                            lable: 'Xã Đồng Thanh',
                            value: '13198',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Thái Nguyên',
            value: '19',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Thái Nguyên',
                    value: '164',
                    wards: [
                        {
                            lable: 'Phường Cam Giá',
                            value: '05467',
                        },
                        {
                            lable: 'Phường Chùa Hang',
                            value: '05659',
                        },
                        {
                            lable: 'Phường Gia Sàng',
                            value: '05461',
                        },
                        {
                            lable: 'Phường Hoàng Văn Thụ',
                            value: '05440',
                        },
                        {
                            lable: 'Phường Hương Sơn',
                            value: '05473',
                        },
                        {
                            lable: 'Phường Phan Đình Phùng',
                            value: '05449',
                        },
                        {
                            lable: 'Phường Phú Xá',
                            value: '05470',
                        },
                        {
                            lable: 'Phường Quang Trung',
                            value: '05446',
                        },
                        {
                            lable: 'Phường Quang Vinh',
                            value: '05434',
                        },
                        {
                            lable: 'Phường Quán Triều',
                            value: '05431',
                        },
                        {
                            lable: 'Phường Thịnh Đán',
                            value: '05455',
                        },
                        {
                            lable: 'Phường Trung Thành',
                            value: '05476',
                        },
                        {
                            lable: 'Phường Trưng Vương',
                            value: '05443',
                        },
                        {
                            lable: 'Phường Tân Long',
                            value: '05482',
                        },
                        {
                            lable: 'Phường Tân Lập',
                            value: '05464',
                        },
                        {
                            lable: 'Phường Tân Thành',
                            value: '05479',
                        },
                        {
                            lable: 'Phường Tân Thịnh',
                            value: '05452',
                        },
                        {
                            lable: 'Phường Tích Lương',
                            value: '05500',
                        },
                        {
                            lable: 'Phường Túc Duyên',
                            value: '05437',
                        },
                        {
                            lable: 'Phường Đồng Bẩm',
                            value: '05710',
                        },
                        {
                            lable: 'Phường Đồng Quang',
                            value: '05458',
                        },
                        {
                            lable: 'Xã Cao Ngạn',
                            value: '05695',
                        },
                        {
                            lable: 'Xã Huống Thượng',
                            value: '05713',
                        },
                        {
                            lable: 'Xã Linh Sơn',
                            value: '05701',
                        },
                        {
                            lable: 'Xã Phúc Hà',
                            value: '05485',
                        },
                        {
                            lable: 'Xã Phúc Trìu',
                            value: '05494',
                        },
                        {
                            lable: 'Xã Phúc Xuân',
                            value: '05488',
                        },
                        {
                            lable: 'Xã Quyết Thắng',
                            value: '05491',
                        },
                        {
                            lable: 'Xã Sơn Cẩm',
                            value: '05653',
                        },
                        {
                            lable: 'Xã Thịnh Đức',
                            value: '05497',
                        },
                        {
                            lable: 'Xã Tân Cương',
                            value: '05503',
                        },
                        {
                            lable: 'Xã Đồng Liên',
                            value: '05914',
                        },
                    ],
                },
                {
                    lable: 'Thành phố Sông Công',
                    value: '165',
                    wards: [
                        {
                            lable: 'Phường Bách Quang',
                            value: '05528',
                        },
                        {
                            lable: 'Phường Châu Sơn',
                            value: '05509',
                        },
                        {
                            lable: 'Phường Cải Đan',
                            value: '05515',
                        },
                        {
                            lable: 'Phường Lương Sơn',
                            value: '05506',
                        },
                        {
                            lable: 'Phường Mỏ Chè',
                            value: '05512',
                        },
                        {
                            lable: 'Phường Phố Cò',
                            value: '05521',
                        },
                        {
                            lable: 'Phường Thắng Lợi',
                            value: '05518',
                        },
                        {
                            lable: 'Xã Bá Xuyên',
                            value: '05533',
                        },
                        {
                            lable: 'Xã Bình Sơn',
                            value: '05530',
                        },
                        {
                            lable: 'Xã Tân Quang',
                            value: '05527',
                        },
                    ],
                },
                {
                    lable: 'Huyện Định Hóa',
                    value: '167',
                    wards: [
                        {
                            lable: 'Thị trấn Chợ Chu',
                            value: '05536',
                        },
                        {
                            lable: 'Xã Bình Thành',
                            value: '05605',
                        },
                        {
                            lable: 'Xã Bình Yên',
                            value: '05587',
                        },
                        {
                            lable: 'Xã Bảo Cường',
                            value: '05569',
                        },
                        {
                            lable: 'Xã Bảo Linh',
                            value: '05554',
                        },
                        {
                            lable: 'Xã Bộc Nhiêu',
                            value: '05596',
                        },
                        {
                            lable: 'Xã Kim Phượng',
                            value: '05551',
                        },
                        {
                            lable: 'Xã Lam Vỹ',
                            value: '05542',
                        },
                        {
                            lable: 'Xã Linh Thông',
                            value: '05539',
                        },
                        {
                            lable: 'Xã Phú Tiến',
                            value: '05593',
                        },
                        {
                            lable: 'Xã Phú Đình',
                            value: '05602',
                        },
                        {
                            lable: 'Xã Phúc Chu',
                            value: '05560',
                        },
                        {
                            lable: 'Xã Phượng Tiến',
                            value: '05566',
                        },
                        {
                            lable: 'Xã Quy Kỳ',
                            value: '05545',
                        },
                        {
                            lable: 'Xã Sơn Phú',
                            value: '05599',
                        },
                        {
                            lable: 'Xã Thanh Định',
                            value: '05578',
                        },
                        {
                            lable: 'Xã Trung Hội',
                            value: '05581',
                        },
                        {
                            lable: 'Xã Trung Lương',
                            value: '05584',
                        },
                        {
                            lable: 'Xã Tân Dương',
                            value: '05563',
                        },
                        {
                            lable: 'Xã Tân Thịnh',
                            value: '05548',
                        },
                        {
                            lable: 'Xã Điềm Mặc',
                            value: '05590',
                        },
                        {
                            lable: 'Xã Định Biên',
                            value: '05575',
                        },
                        {
                            lable: 'Xã Đồng Thịnh',
                            value: '05572',
                        },
                    ],
                },
                {
                    lable: 'Huyện Phú Lương',
                    value: '168',
                    wards: [
                        {
                            lable: 'Thị trấn Giang Tiên',
                            value: '05608',
                        },
                        {
                            lable: 'Thị trấn Đu',
                            value: '05611',
                        },
                        {
                            lable: 'Xã Cổ Lũng',
                            value: '05650',
                        },
                        {
                            lable: 'Xã Hợp Thành',
                            value: '05638',
                        },
                        {
                            lable: 'Xã Phú Đô',
                            value: '05635',
                        },
                        {
                            lable: 'Xã Phấn Mễ',
                            value: '05644',
                        },
                        {
                            lable: 'Xã Phủ Lý',
                            value: '05632',
                        },
                        {
                            lable: 'Xã Tức Tranh',
                            value: '05641',
                        },
                        {
                            lable: 'Xã Vô Tranh',
                            value: '05647',
                        },
                        {
                            lable: 'Xã Yên Lạc',
                            value: '05623',
                        },
                        {
                            lable: 'Xã Yên Ninh',
                            value: '05614',
                        },
                        {
                            lable: 'Xã Yên Trạch',
                            value: '05617',
                        },
                        {
                            lable: 'Xã Yên Đổ',
                            value: '05620',
                        },
                        {
                            lable: 'Xã Ôn Lương',
                            value: '05626',
                        },
                        {
                            lable: 'Xã Động Đạt',
                            value: '05629',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đồng Hỷ',
                    value: '169',
                    wards: [
                        {
                            lable: 'Thị trấn Sông Cầu',
                            value: '05656',
                        },
                        {
                            lable: 'Thị trấn Trại Cau',
                            value: '05662',
                        },
                        {
                            lable: 'Xã Cây Thị',
                            value: '05689',
                        },
                        {
                            lable: 'Xã Hòa Bình',
                            value: '05671',
                        },
                        {
                            lable: 'Xã Hóa Thượng',
                            value: '05692',
                        },
                        {
                            lable: 'Xã Hóa Trung',
                            value: '05683',
                        },
                        {
                            lable: 'Xã Hợp Tiến',
                            value: '05698',
                        },
                        {
                            lable: 'Xã Khe Mo',
                            value: '05686',
                        },
                        {
                            lable: 'Xã Minh Lập',
                            value: '05677',
                        },
                        {
                            lable: 'Xã Nam Hòa',
                            value: '05707',
                        },
                        {
                            lable: 'Xã Quang Sơn',
                            value: '05674',
                        },
                        {
                            lable: 'Xã Tân Long',
                            value: '05668',
                        },
                        {
                            lable: 'Xã Tân Lợi',
                            value: '05704',
                        },
                        {
                            lable: 'Xã Văn Hán',
                            value: '05680',
                        },
                        {
                            lable: 'Xã Văn Lăng',
                            value: '05665',
                        },
                    ],
                },
                {
                    lable: 'Huyện Võ Nhai',
                    value: '170',
                    wards: [
                        {
                            lable: 'Thị trấn Đình Cả',
                            value: '05716',
                        },
                        {
                            lable: 'Xã Bình Long',
                            value: '05758',
                        },
                        {
                            lable: 'Xã Cúc Đường',
                            value: '05737',
                        },
                        {
                            lable: 'Xã Dân Tiến',
                            value: '05755',
                        },
                        {
                            lable: 'Xã La Hiên',
                            value: '05740',
                        },
                        {
                            lable: 'Xã Liên Minh',
                            value: '05752',
                        },
                        {
                            lable: 'Xã Lâu Thượng',
                            value: '05743',
                        },
                        {
                            lable: 'Xã Nghinh Tường',
                            value: '05722',
                        },
                        {
                            lable: 'Xã Phú Thượng',
                            value: '05734',
                        },
                        {
                            lable: 'Xã Phương Giao',
                            value: '05749',
                        },
                        {
                            lable: 'Xã Sảng Mộc',
                            value: '05719',
                        },
                        {
                            lable: 'Xã Thượng Nung',
                            value: '05731',
                        },
                        {
                            lable: 'Xã Thần Xa',
                            value: '05725',
                        },
                        {
                            lable: 'Xã Tràng Xá',
                            value: '05746',
                        },
                        {
                            lable: 'Xã Vũ Chấn',
                            value: '05728',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đại Từ',
                    value: '171',
                    wards: [
                        {
                            lable: 'Thị trấn Hùng Sơn',
                            value: '05761',
                        },
                        {
                            lable: 'Thị trấn Quân Chu',
                            value: '05764',
                        },
                        {
                            lable: 'Xã An Khánh',
                            value: '05824',
                        },
                        {
                            lable: 'Xã Bình Thuận',
                            value: '05830',
                        },
                        {
                            lable: 'Xã Bản Ngoại',
                            value: '05800',
                        },
                        {
                            lable: 'Xã Cát Nê',
                            value: '05848',
                        },
                        {
                            lable: 'Xã Cù Vân',
                            value: '05809',
                        },
                        {
                            lable: 'Xã Hoàng Nông',
                            value: '05818',
                        },
                        {
                            lable: 'Xã Hà Thượng',
                            value: '05812',
                        },
                        {
                            lable: 'Xã Khôi Kỳ',
                            value: '05821',
                        },
                        {
                            lable: 'Xã Ký Phú',
                            value: '05845',
                        },
                        {
                            lable: 'Xã La Bằng',
                            value: '05815',
                        },
                        {
                            lable: 'Xã Lục Ba',
                            value: '05833',
                        },
                        {
                            lable: 'Xã Minh Tiến',
                            value: '05770',
                        },
                        {
                            lable: 'Xã Mỹ Yên',
                            value: '05836',
                        },
                        {
                            lable: 'Xã Na Mao',
                            value: '05782',
                        },
                        {
                            lable: 'Xã Phú Cường',
                            value: '05779',
                        },
                        {
                            lable: 'Xã Phú Lạc',
                            value: '05785',
                        },
                        {
                            lable: 'Xã Phú Thịnh',
                            value: '05791',
                        },
                        {
                            lable: 'Xã Phú Xuyên',
                            value: '05797',
                        },
                        {
                            lable: 'Xã Phúc Lương',
                            value: '05767',
                        },
                        {
                            lable: 'Xã Phục Linh',
                            value: '05794',
                        },
                        {
                            lable: 'Xã Quân Chu',
                            value: '05851',
                        },
                        {
                            lable: 'Xã Tiên Hội',
                            value: '05803',
                        },
                        {
                            lable: 'Xã Tân Linh',
                            value: '05788',
                        },
                        {
                            lable: 'Xã Tân Thái',
                            value: '05827',
                        },
                        {
                            lable: 'Xã Văn Yên',
                            value: '05842',
                        },
                        {
                            lable: 'Xã Vạn Thọ',
                            value: '05839',
                        },
                        {
                            lable: 'Xã Yên Lãng',
                            value: '05773',
                        },
                        {
                            lable: 'Xã Đức Lương',
                            value: '05776',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Phổ Yên',
                    value: '172',
                    wards: [
                        {
                            lable: 'Phường Ba Hàng',
                            value: '05860',
                        },
                        {
                            lable: 'Phường Bãi Bông',
                            value: '05854',
                        },
                        {
                            lable: 'Phường Bắc Sơn',
                            value: '05857',
                        },
                        {
                            lable: 'Phường Đồng Tiến',
                            value: '05878',
                        },
                        {
                            lable: 'Xã Hồng Tiến',
                            value: '05869',
                        },
                        {
                            lable: 'Xã Minh Đức',
                            value: '05872',
                        },
                        {
                            lable: 'Xã Nam Tiến',
                            value: '05890',
                        },
                        {
                            lable: 'Xã Phúc Thuận',
                            value: '05866',
                        },
                        {
                            lable: 'Xã Phúc Tân',
                            value: '05863',
                        },
                        {
                            lable: 'Xã Thuận Thành',
                            value: '05905',
                        },
                        {
                            lable: 'Xã Thành Công',
                            value: '05881',
                        },
                        {
                            lable: 'Xã Tiên Phong',
                            value: '05884',
                        },
                        {
                            lable: 'Xã Trung Thành',
                            value: '05899',
                        },
                        {
                            lable: 'Xã Tân Hương',
                            value: '05893',
                        },
                        {
                            lable: 'Xã Tân Phú',
                            value: '05902',
                        },
                        {
                            lable: 'Xã Vạn Phái',
                            value: '05887',
                        },
                        {
                            lable: 'Xã Đông Cao',
                            value: '05896',
                        },
                        {
                            lable: 'Xã Đắc Sơn',
                            value: '05875',
                        },
                    ],
                },
                {
                    lable: 'Huyện Phú Bình',
                    value: '173',
                    wards: [
                        {
                            lable: 'Thị trấn Hương Sơn',
                            value: '05908',
                        },
                        {
                            lable: 'Xã Bàn Đạt',
                            value: '05911',
                        },
                        {
                            lable: 'Xã Bảo Lý',
                            value: '05929',
                        },
                        {
                            lable: 'Xã Dương Thành',
                            value: '05965',
                        },
                        {
                            lable: 'Xã Hà Châu',
                            value: '05968',
                        },
                        {
                            lable: 'Xã Kha Sơn',
                            value: '05959',
                        },
                        {
                            lable: 'Xã Lương Phú',
                            value: '05953',
                        },
                        {
                            lable: 'Xã Nga My',
                            value: '05956',
                        },
                        {
                            lable: 'Xã Nhã Lộng',
                            value: '05938',
                        },
                        {
                            lable: 'Xã Thanh Ninh',
                            value: '05962',
                        },
                        {
                            lable: 'Xã Thượng Đình',
                            value: '05932',
                        },
                        {
                            lable: 'Xã Tân Hòa',
                            value: '05935',
                        },
                        {
                            lable: 'Xã Tân Khánh',
                            value: '05917',
                        },
                        {
                            lable: 'Xã Tân Kim',
                            value: '05920',
                        },
                        {
                            lable: 'Xã Tân Thành',
                            value: '05923',
                        },
                        {
                            lable: 'Xã Tân Đức',
                            value: '05947',
                        },
                        {
                            lable: 'Xã Xuân Phương',
                            value: '05944',
                        },
                        {
                            lable: 'Xã Úc Kỳ',
                            value: '05950',
                        },
                        {
                            lable: 'Xã Điềm Thụy',
                            value: '05941',
                        },
                        {
                            lable: 'Xã Đào Xá',
                            value: '05926',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Thanh Hóa',
            value: '38',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Thanh Hóa',
                    value: '380',
                    wards: [
                        {
                            lable: 'Phường An Hưng',
                            value: '16435',
                        },
                        {
                            lable: 'Phường Ba Đình',
                            value: '14776',
                        },
                        {
                            lable: 'Phường Hàm Rồng',
                            value: '14755',
                        },
                        {
                            lable: 'Phường Lam Sơn',
                            value: '14773',
                        },
                        {
                            lable: 'Phường Long Anh',
                            value: '15922',
                        },
                        {
                            lable: 'Phường Nam Ngạn',
                            value: '14761',
                        },
                        {
                            lable: 'Phường Ngọc Trạo',
                            value: '14779',
                        },
                        {
                            lable: 'Phường Phú Sơn',
                            value: '14770',
                        },
                        {
                            lable: 'Phường Quảng Cát',
                            value: '16507',
                        },
                        {
                            lable: 'Phường Quảng Hưng',
                            value: '14800',
                        },
                        {
                            lable: 'Phường Quảng Phú',
                            value: '16522',
                        },
                        {
                            lable: 'Phường Quảng Thành',
                            value: '14806',
                        },
                        {
                            lable: 'Phường Quảng Thắng',
                            value: '14803',
                        },
                        {
                            lable: 'Phường Quảng Thịnh',
                            value: '16441',
                        },
                        {
                            lable: 'Phường Quảng Tâm',
                            value: '16525',
                        },
                        {
                            lable: 'Phường Quảng Đông',
                            value: '16459',
                        },
                        {
                            lable: 'Phường Thiệu Dương',
                            value: '15859',
                        },
                        {
                            lable: 'Phường Thiệu Khánh',
                            value: '15856',
                        },
                        {
                            lable: 'Phường Trường Thi',
                            value: '14764',
                        },
                        {
                            lable: 'Phường Tào Xuyên',
                            value: '15913',
                        },
                        {
                            lable: 'Phường Tân Sơn',
                            value: '14788',
                        },
                        {
                            lable: 'Phường Điện Biên',
                            value: '14767',
                        },
                        {
                            lable: 'Phường Đông Cương',
                            value: '14791',
                        },
                        {
                            lable: 'Phường Đông Hương',
                            value: '14794',
                        },
                        {
                            lable: 'Phường Đông Hải',
                            value: '14797',
                        },
                        {
                            lable: 'Phường Đông Lĩnh',
                            value: '16396',
                        },
                        {
                            lable: 'Phường Đông Sơn',
                            value: '14785',
                        },
                        {
                            lable: 'Phường Đông Thọ',
                            value: '14758',
                        },
                        {
                            lable: 'Phường Đông Tân',
                            value: '16432',
                        },
                        {
                            lable: 'Phường Đông Vệ',
                            value: '14782',
                        },
                        {
                            lable: 'Xã Hoằng Quang',
                            value: '15925',
                        },
                        {
                            lable: 'Xã Hoằng Đại',
                            value: '15970',
                        },
                        {
                            lable: 'Xã Thiệu Vân',
                            value: '15850',
                        },
                        {
                            lable: 'Xã Đông Vinh',
                            value: '16429',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Bỉm Sơn',
                    value: '381',
                    wards: [
                        {
                            lable: 'Phường Ba Đình',
                            value: '14812',
                        },
                        {
                            lable: 'Phường Bắc Sơn',
                            value: '14809',
                        },
                        {
                            lable: 'Phường Lam Sơn',
                            value: '14815',
                        },
                        {
                            lable: 'Phường Ngọc Trạo',
                            value: '14818',
                        },
                        {
                            lable: 'Phường Phú Sơn',
                            value: '14823',
                        },
                        {
                            lable: 'Phường Đông Sơn',
                            value: '14821',
                        },
                        {
                            lable: 'Xã Quang Trung',
                            value: '14824',
                        },
                    ],
                },
                {
                    lable: 'Thành phố Sầm Sơn',
                    value: '382',
                    wards: [
                        {
                            lable: 'Phường Bắc Sơn',
                            value: '14833',
                        },
                        {
                            lable: 'Phường Quảng Châu',
                            value: '16531',
                        },
                        {
                            lable: 'Phường Quảng Cư',
                            value: '14839',
                        },
                        {
                            lable: 'Phường Quảng Thọ',
                            value: '16528',
                        },
                        {
                            lable: 'Phường Quảng Tiến',
                            value: '14842',
                        },
                        {
                            lable: 'Phường Quảng Vinh',
                            value: '16534',
                        },
                        {
                            lable: 'Phường Trung Sơn',
                            value: '14830',
                        },
                        {
                            lable: 'Phường Trường Sơn',
                            value: '14836',
                        },
                        {
                            lable: 'Xã Quảng Hùng',
                            value: '16516',
                        },
                        {
                            lable: 'Xã Quảng Minh',
                            value: '16513',
                        },
                        {
                            lable: 'Xã Quảng Đại',
                            value: '16537',
                        },
                    ],
                },
                {
                    lable: 'Huyện Mường Lát',
                    value: '384',
                    wards: [
                        {
                            lable: 'Thị trấn Mường Lát',
                            value: '14845',
                        },
                        {
                            lable: 'Xã Mường Chanh',
                            value: '14866',
                        },
                        {
                            lable: 'Xã Mường Lý',
                            value: '14854',
                        },
                        {
                            lable: 'Xã Nhi Sơn',
                            value: '14864',
                        },
                        {
                            lable: 'Xã Pù Nhi',
                            value: '14863',
                        },
                        {
                            lable: 'Xã Quang Chiểu',
                            value: '14860',
                        },
                        {
                            lable: 'Xã Tam Chung',
                            value: '14848',
                        },
                        {
                            lable: 'Xã Trung Lý',
                            value: '14857',
                        },
                    ],
                },
                {
                    lable: 'Huyện Quan Hóa',
                    value: '385',
                    wards: [
                        {
                            lable: 'Thị trấn Hồi Xuân',
                            value: '14869',
                        },
                        {
                            lable: 'Xã Hiền Chung',
                            value: '14896',
                        },
                        {
                            lable: 'Xã Hiền Kiệt',
                            value: '14899',
                        },
                        {
                            lable: 'Xã Nam Tiến',
                            value: '14902',
                        },
                        {
                            lable: 'Xã Nam Xuân',
                            value: '14914',
                        },
                        {
                            lable: 'Xã Nam Động',
                            value: '14917',
                        },
                        {
                            lable: 'Xã Phú Lệ',
                            value: '14884',
                        },
                        {
                            lable: 'Xã Phú Nghiêm',
                            value: '14911',
                        },
                        {
                            lable: 'Xã Phú Sơn',
                            value: '14887',
                        },
                        {
                            lable: 'Xã Phú Thanh',
                            value: '14878',
                        },
                        {
                            lable: 'Xã Phú Xuân',
                            value: '14890',
                        },
                        {
                            lable: 'Xã Thiên Phủ',
                            value: '14908',
                        },
                        {
                            lable: 'Xã Thành Sơn',
                            value: '14872',
                        },
                        {
                            lable: 'Xã Trung Sơn',
                            value: '14875',
                        },
                        {
                            lable: 'Xã Trung Thành',
                            value: '14881',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bá Thước',
                    value: '386',
                    wards: [
                        {
                            lable: 'Thị trấn Cành Nàng',
                            value: '14923',
                        },
                        {
                            lable: 'Xã Ban Công',
                            value: '14971',
                        },
                        {
                            lable: 'Xã Cổ Lũng',
                            value: '14965',
                        },
                        {
                            lable: 'Xã Hạ Trung',
                            value: '14962',
                        },
                        {
                            lable: 'Xã Kỳ Tân',
                            value: '14974',
                        },
                        {
                            lable: 'Xã Lũng Cao',
                            value: '14959',
                        },
                        {
                            lable: 'Xã Lũng Niêm',
                            value: '14956',
                        },
                        {
                            lable: 'Xã Lương Ngoại',
                            value: '14941',
                        },
                        {
                            lable: 'Xã Lương Nội',
                            value: '14947',
                        },
                        {
                            lable: 'Xã Lương Trung',
                            value: '14953',
                        },
                        {
                            lable: 'Xã Thiết Kế',
                            value: '14986',
                        },
                        {
                            lable: 'Xã Thiết Ống',
                            value: '14980',
                        },
                        {
                            lable: 'Xã Thành Lâm',
                            value: '14968',
                        },
                        {
                            lable: 'Xã Thành Sơn',
                            value: '14938',
                        },
                        {
                            lable: 'Xã Văn Nho',
                            value: '14977',
                        },
                        {
                            lable: 'Xã Ái Thượng',
                            value: '14944',
                        },
                        {
                            lable: 'Xã Điền Hạ',
                            value: '14929',
                        },
                        {
                            lable: 'Xã Điền Lư',
                            value: '14950',
                        },
                        {
                            lable: 'Xã Điền Quang',
                            value: '14932',
                        },
                        {
                            lable: 'Xã Điền Thượng',
                            value: '14926',
                        },
                        {
                            lable: 'Xã Điền Trung',
                            value: '14935',
                        },
                    ],
                },
                {
                    lable: 'Huyện Quan Sơn',
                    value: '387',
                    wards: [
                        {
                            lable: 'Thị trấn Sơn Lư',
                            value: '15016',
                        },
                        {
                            lable: 'Xã Mường Mìn',
                            value: '15025',
                        },
                        {
                            lable: 'Xã Na Mèo',
                            value: '15013',
                        },
                        {
                            lable: 'Xã Sơn Hà',
                            value: '15004',
                        },
                        {
                            lable: 'Xã Sơn Thủy',
                            value: '15010',
                        },
                        {
                            lable: 'Xã Sơn Điện',
                            value: '15022',
                        },
                        {
                            lable: 'Xã Tam Lư',
                            value: '15019',
                        },
                        {
                            lable: 'Xã Tam Thanh',
                            value: '15007',
                        },
                        {
                            lable: 'Xã Trung Hạ',
                            value: '15001',
                        },
                        {
                            lable: 'Xã Trung Thượng',
                            value: '14998',
                        },
                        {
                            lable: 'Xã Trung Tiến',
                            value: '14999',
                        },
                        {
                            lable: 'Xã Trung Xuân',
                            value: '14995',
                        },
                    ],
                },
                {
                    lable: 'Huyện Lang Chánh',
                    value: '388',
                    wards: [
                        {
                            lable: 'Thị trấn Lang Chánh',
                            value: '15055',
                        },
                        {
                            lable: 'Xã Giao An',
                            value: '15040',
                        },
                        {
                            lable: 'Xã Giao Thiện',
                            value: '15043',
                        },
                        {
                            lable: 'Xã Lâm Phú',
                            value: '15052',
                        },
                        {
                            lable: 'Xã Tam Văn',
                            value: '15049',
                        },
                        {
                            lable: 'Xã Trí Nang',
                            value: '15037',
                        },
                        {
                            lable: 'Xã Tân Phúc',
                            value: '15046',
                        },
                        {
                            lable: 'Xã Yên Khương',
                            value: '15031',
                        },
                        {
                            lable: 'Xã Yên Thắng',
                            value: '15034',
                        },
                        {
                            lable: 'Xã Đồng Lương',
                            value: '15058',
                        },
                    ],
                },
                {
                    lable: 'Huyện Ngọc Lặc',
                    value: '389',
                    wards: [
                        {
                            lable: 'Thị Trấn Ngọc Lặc',
                            value: '15061',
                        },
                        {
                            lable: 'Xã Cao Ngọc',
                            value: '15079',
                        },
                        {
                            lable: 'Xã Cao Thịnh',
                            value: '15100',
                        },
                        {
                            lable: 'Xã Kiên Thọ',
                            value: '15118',
                        },
                        {
                            lable: 'Xã Lam Sơn',
                            value: '15064',
                        },
                        {
                            lable: 'Xã Lộc Thịnh',
                            value: '15097',
                        },
                        {
                            lable: 'Xã Minh Sơn',
                            value: '15124',
                        },
                        {
                            lable: 'Xã Minh Tiến',
                            value: '15121',
                        },
                        {
                            lable: 'Xã Mỹ Tân',
                            value: '15067',
                        },
                        {
                            lable: 'Xã Nguyệt Ấn',
                            value: '15115',
                        },
                        {
                            lable: 'Xã Ngọc Liên',
                            value: '15091',
                        },
                        {
                            lable: 'Xã Ngọc Sơn',
                            value: '15094',
                        },
                        {
                            lable: 'Xã Ngọc Trung',
                            value: '15103',
                        },
                        {
                            lable: 'Xã Phùng Giáo',
                            value: '15106',
                        },
                        {
                            lable: 'Xã Phùng Minh',
                            value: '15109',
                        },
                        {
                            lable: 'Xã Phúc Thịnh',
                            value: '15112',
                        },
                        {
                            lable: 'Xã Quang Trung',
                            value: '15085',
                        },
                        {
                            lable: 'Xã Thúy Sơn',
                            value: '15070',
                        },
                        {
                            lable: 'Xã Thạch Lập',
                            value: '15073',
                        },
                        {
                            lable: 'Xã Vân Âm',
                            value: '15076',
                        },
                        {
                            lable: 'Xã Đồng Thịnh',
                            value: '15088',
                        },
                    ],
                },
                {
                    lable: 'Huyện Cẩm Thủy',
                    value: '390',
                    wards: [
                        {
                            lable: 'Thị trấn Phong Sơn',
                            value: '15127',
                        },
                        {
                            lable: 'Xã Cẩm Bình',
                            value: '15151',
                        },
                        {
                            lable: 'Xã Cẩm Châu',
                            value: '15160',
                        },
                        {
                            lable: 'Xã Cẩm Giang',
                            value: '15148',
                        },
                        {
                            lable: 'Xã Cẩm Liên',
                            value: '15145',
                        },
                        {
                            lable: 'Xã Cẩm Long',
                            value: '15172',
                        },
                        {
                            lable: 'Xã Cẩm Lương',
                            value: '15139',
                        },
                        {
                            lable: 'Xã Cẩm Ngọc',
                            value: '15169',
                        },
                        {
                            lable: 'Xã Cẩm Phú',
                            value: '15181',
                        },
                        {
                            lable: 'Xã Cẩm Quý',
                            value: '15136',
                        },
                        {
                            lable: 'Xã Cẩm Thành',
                            value: '15133',
                        },
                        {
                            lable: 'Xã Cẩm Thạch',
                            value: '15142',
                        },
                        {
                            lable: 'Xã Cẩm Tâm',
                            value: '15163',
                        },
                        {
                            lable: 'Xã Cẩm Tân',
                            value: '15178',
                        },
                        {
                            lable: 'Xã Cẩm Tú',
                            value: '15154',
                        },
                        {
                            lable: 'Xã Cẩm Vân',
                            value: '15184',
                        },
                        {
                            lable: 'Xã Cẩm Yên',
                            value: '15175',
                        },
                    ],
                },
                {
                    lable: 'Huyện Thạch Thành',
                    value: '391',
                    wards: [
                        {
                            lable: 'Thị trấn Kim Tân',
                            value: '15187',
                        },
                        {
                            lable: 'Thị trấn Vân Du',
                            value: '15190',
                        },
                        {
                            lable: 'Xã Ngọc Trạo',
                            value: '15268',
                        },
                        {
                            lable: 'Xã Thành An',
                            value: '15250',
                        },
                        {
                            lable: 'Xã Thành Công',
                            value: '15235',
                        },
                        {
                            lable: 'Xã Thành Hưng',
                            value: '15265',
                        },
                        {
                            lable: 'Xã Thành Long',
                            value: '15259',
                        },
                        {
                            lable: 'Xã Thành Minh',
                            value: '15232',
                        },
                        {
                            lable: 'Xã Thành Mỹ',
                            value: '15223',
                        },
                        {
                            lable: 'Xã Thành Thọ',
                            value: '15253',
                        },
                        {
                            lable: 'Xã Thành Tiến',
                            value: '15256',
                        },
                        {
                            lable: 'Xã Thành Trực',
                            value: '15241',
                        },
                        {
                            lable: 'Xã Thành Tâm',
                            value: '15247',
                        },
                        {
                            lable: 'Xã Thành Tân',
                            value: '15238',
                        },
                        {
                            lable: 'Xã Thành Vinh',
                            value: '15229',
                        },
                        {
                            lable: 'Xã Thành Yên',
                            value: '15226',
                        },
                        {
                            lable: 'Xã Thạch Bình',
                            value: '15211',
                        },
                        {
                            lable: 'Xã Thạch Cẩm',
                            value: '15205',
                        },
                        {
                            lable: 'Xã Thạch Long',
                            value: '15220',
                        },
                        {
                            lable: 'Xã Thạch Lâm',
                            value: '15196',
                        },
                        {
                            lable: 'Xã Thạch Quảng',
                            value: '15199',
                        },
                        {
                            lable: 'Xã Thạch Sơn',
                            value: '15208',
                        },
                        {
                            lable: 'Xã Thạch Tượng',
                            value: '15202',
                        },
                        {
                            lable: 'Xã Thạch Định',
                            value: '15214',
                        },
                        {
                            lable: 'Xã Thạch Đồng',
                            value: '15217',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hà Trung',
                    value: '392',
                    wards: [
                        {
                            lable: 'Thị trấn Hà Trung',
                            value: '15271',
                        },
                        {
                            lable: 'Xã Hoạt Giang',
                            value: '15283',
                        },
                        {
                            lable: 'Xã Hà Bình',
                            value: '15328',
                        },
                        {
                            lable: 'Xã Hà Bắc',
                            value: '15280',
                        },
                        {
                            lable: 'Xã Hà Châu',
                            value: '15334',
                        },
                        {
                            lable: 'Xã Hà Giang',
                            value: '15292',
                        },
                        {
                            lable: 'Xã Hà Hải',
                            value: '15343',
                        },
                        {
                            lable: 'Xã Hà Lai',
                            value: '15331',
                        },
                        {
                            lable: 'Xã Hà Long',
                            value: '15274',
                        },
                        {
                            lable: 'Xã Hà Lĩnh',
                            value: '15316',
                        },
                        {
                            lable: 'Xã Hà Ngọc',
                            value: '15304',
                        },
                        {
                            lable: 'Xã Hà Sơn',
                            value: '15313',
                        },
                        {
                            lable: 'Xã Hà Thái',
                            value: '15340',
                        },
                        {
                            lable: 'Xã Hà Tiến',
                            value: '15325',
                        },
                        {
                            lable: 'Xã Hà Tân',
                            value: '15322',
                        },
                        {
                            lable: 'Xã Hà Vinh',
                            value: '15277',
                        },
                        {
                            lable: 'Xã Hà Đông',
                            value: '15319',
                        },
                        {
                            lable: 'Xã Lĩnh Toại',
                            value: '15298',
                        },
                        {
                            lable: 'Xã Yên Dương',
                            value: '15286',
                        },
                        {
                            lable: 'Xã Yến Sơn',
                            value: '15307',
                        },
                    ],
                },
                {
                    lable: 'Huyện Vĩnh Lộc',
                    value: '393',
                    wards: [
                        {
                            lable: 'Thị trấn Vĩnh Lộc',
                            value: '15349',
                        },
                        {
                            lable: 'Xã Minh Tân',
                            value: '15382',
                        },
                        {
                            lable: 'Xã Ninh Khang',
                            value: '15385',
                        },
                        {
                            lable: 'Xã Vĩnh An',
                            value: '15391',
                        },
                        {
                            lable: 'Xã Vĩnh Hòa',
                            value: '15376',
                        },
                        {
                            lable: 'Xã Vĩnh Hùng',
                            value: '15379',
                        },
                        {
                            lable: 'Xã Vĩnh Hưng',
                            value: '15367',
                        },
                        {
                            lable: 'Xã Vĩnh Long',
                            value: '15361',
                        },
                        {
                            lable: 'Xã Vĩnh Phúc',
                            value: '15364',
                        },
                        {
                            lable: 'Xã Vĩnh Quang',
                            value: '15352',
                        },
                        {
                            lable: 'Xã Vĩnh Thịnh',
                            value: '15388',
                        },
                        {
                            lable: 'Xã Vĩnh Tiến',
                            value: '15358',
                        },
                        {
                            lable: 'Xã Vĩnh Yên',
                            value: '15355',
                        },
                    ],
                },
                {
                    lable: 'Huyện Yên Định',
                    value: '394',
                    wards: [
                        {
                            lable: 'Thị trấn Quán Lào',
                            value: '15469',
                        },
                        {
                            lable: 'Thị trấn Quý Lộc',
                            value: '15412',
                        },
                        {
                            lable: 'Thị trấn Thống Nhất',
                            value: '15397',
                        },
                        {
                            lable: 'Thị trấn Yên Lâm',
                            value: '15403',
                        },
                        {
                            lable: 'Xã Yên Hùng',
                            value: '15433',
                        },
                        {
                            lable: 'Xã Yên Lạc',
                            value: '15442',
                        },
                        {
                            lable: 'Xã Yên Ninh',
                            value: '15439',
                        },
                        {
                            lable: 'Xã Yên Phong',
                            value: '15427',
                        },
                        {
                            lable: 'Xã Yên Phú',
                            value: '15409',
                        },
                        {
                            lable: 'Xã Yên Thái',
                            value: '15430',
                        },
                        {
                            lable: 'Xã Yên Thịnh',
                            value: '15436',
                        },
                        {
                            lable: 'Xã Yên Thọ',
                            value: '15415',
                        },
                        {
                            lable: 'Xã Yên Trung',
                            value: '15418',
                        },
                        {
                            lable: 'Xã Yên Trường',
                            value: '15421',
                        },
                        {
                            lable: 'Xã Yên Tâm',
                            value: '15406',
                        },
                        {
                            lable: 'Xã Định Bình',
                            value: '15478',
                        },
                        {
                            lable: 'Xã Định Công',
                            value: '15454',
                        },
                        {
                            lable: 'Xã Định Hòa',
                            value: '15448',
                        },
                        {
                            lable: 'Xã Định Hưng',
                            value: '15472',
                        },
                        {
                            lable: 'Xã Định Hải',
                            value: '15475',
                        },
                        {
                            lable: 'Xã Định Liên',
                            value: '15466',
                        },
                        {
                            lable: 'Xã Định Long',
                            value: '15463',
                        },
                        {
                            lable: 'Xã Định Thành',
                            value: '15451',
                        },
                        {
                            lable: 'Xã Định Tiến',
                            value: '15460',
                        },
                        {
                            lable: 'Xã Định Tân',
                            value: '15457',
                        },
                        {
                            lable: 'Xã Định Tăng',
                            value: '15445',
                        },
                    ],
                },
                {
                    lable: 'Huyện Thọ Xuân',
                    value: '395',
                    wards: [
                        {
                            lable: 'Thị trấn Lam Sơn',
                            value: '15556',
                        },
                        {
                            lable: 'Thị trấn Sao Vàng',
                            value: '15553',
                        },
                        {
                            lable: 'Thị trấn Thọ Xuân',
                            value: '15499',
                        },
                        {
                            lable: 'Xã Bắc Lương',
                            value: '15502',
                        },
                        {
                            lable: 'Xã Nam Giang',
                            value: '15505',
                        },
                        {
                            lable: 'Xã Phú Xuân',
                            value: '15577',
                        },
                        {
                            lable: 'Xã Quảng Phú',
                            value: '15571',
                        },
                        {
                            lable: 'Xã Thuận Minh',
                            value: '15565',
                        },
                        {
                            lable: 'Xã Thọ Diên',
                            value: '15538',
                        },
                        {
                            lable: 'Xã Thọ Hải',
                            value: '15520',
                        },
                        {
                            lable: 'Xã Thọ Lâm',
                            value: '15541',
                        },
                        {
                            lable: 'Xã Thọ Lập',
                            value: '15568',
                        },
                        {
                            lable: 'Xã Thọ Lộc',
                            value: '15511',
                        },
                        {
                            lable: 'Xã Thọ Xương',
                            value: '15544',
                        },
                        {
                            lable: 'Xã Trường Xuân',
                            value: '15598',
                        },
                        {
                            lable: 'Xã Tây Hồ',
                            value: '15523',
                        },
                        {
                            lable: 'Xã Xuân Bái',
                            value: '15547',
                        },
                        {
                            lable: 'Xã Xuân Giang',
                            value: '15526',
                        },
                        {
                            lable: 'Xã Xuân Hòa',
                            value: '15517',
                        },
                        {
                            lable: 'Xã Xuân Hưng',
                            value: '15535',
                        },
                        {
                            lable: 'Xã Xuân Hồng',
                            value: '15493',
                        },
                        {
                            lable: 'Xã Xuân Lai',
                            value: '15583',
                        },
                        {
                            lable: 'Xã Xuân Lập',
                            value: '15586',
                        },
                        {
                            lable: 'Xã Xuân Minh',
                            value: '15592',
                        },
                        {
                            lable: 'Xã Xuân Phong',
                            value: '15508',
                        },
                        {
                            lable: 'Xã Xuân Phú',
                            value: '15550',
                        },
                        {
                            lable: 'Xã Xuân Sinh',
                            value: '15532',
                        },
                        {
                            lable: 'Xã Xuân Thiên',
                            value: '15559',
                        },
                        {
                            lable: 'Xã Xuân Trường',
                            value: '15514',
                        },
                        {
                            lable: 'Xã Xuân Tín',
                            value: '15574',
                        },
                    ],
                },
                {
                    lable: 'Huyện Thường Xuân',
                    value: '396',
                    wards: [
                        {
                            lable: 'Thị trấn Thường Xuân',
                            value: '15646',
                        },
                        {
                            lable: 'Xã Bát Mọt',
                            value: '15607',
                        },
                        {
                            lable: 'Xã Luận Khê',
                            value: '15637',
                        },
                        {
                            lable: 'Xã Luận Thành',
                            value: '15634',
                        },
                        {
                            lable: 'Xã Lương Sơn',
                            value: '15628',
                        },
                        {
                            lable: 'Xã Ngọc Phụng',
                            value: '15655',
                        },
                        {
                            lable: 'Xã Thọ Thanh',
                            value: '15652',
                        },
                        {
                            lable: 'Xã Tân Thành',
                            value: '15661',
                        },
                        {
                            lable: 'Xã Vạn Xuân',
                            value: '15622',
                        },
                        {
                            lable: 'Xã Xuân Cao',
                            value: '15631',
                        },
                        {
                            lable: 'Xã Xuân Chinh',
                            value: '15658',
                        },
                        {
                            lable: 'Xã Xuân Dương',
                            value: '15649',
                        },
                        {
                            lable: 'Xã Xuân Lẹ',
                            value: '15619',
                        },
                        {
                            lable: 'Xã Xuân Lộc',
                            value: '15643',
                        },
                        {
                            lable: 'Xã Xuân Thắng',
                            value: '15640',
                        },
                        {
                            lable: 'Xã Yên Nhân',
                            value: '15610',
                        },
                    ],
                },
                {
                    lable: 'Huyện Triệu Sơn',
                    value: '397',
                    wards: [
                        {
                            lable: 'Thị trấn Nưa',
                            value: '15718',
                        },
                        {
                            lable: 'Thị trấn Triệu Sơn',
                            value: '15664',
                        },
                        {
                            lable: 'Xã An Nông',
                            value: '15709',
                        },
                        {
                            lable: 'Xã Bình Sơn',
                            value: '15769',
                        },
                        {
                            lable: 'Xã Dân Lý',
                            value: '15703',
                        },
                        {
                            lable: 'Xã Dân Lực',
                            value: '15700',
                        },
                        {
                            lable: 'Xã Dân Quyền',
                            value: '15706',
                        },
                        {
                            lable: 'Xã Hợp Lý',
                            value: '15676',
                        },
                        {
                            lable: 'Xã Hợp Thành',
                            value: '15682',
                        },
                        {
                            lable: 'Xã Hợp Thắng',
                            value: '15688',
                        },
                        {
                            lable: 'Xã Hợp Tiến',
                            value: '15679',
                        },
                        {
                            lable: 'Xã Khuyến Nông',
                            value: '15733',
                        },
                        {
                            lable: 'Xã Minh Sơn',
                            value: '15691',
                        },
                        {
                            lable: 'Xã Nông Trường',
                            value: '15766',
                        },
                        {
                            lable: 'Xã Thái Hòa',
                            value: '15715',
                        },
                        {
                            lable: 'Xã Thọ Bình',
                            value: '15670',
                        },
                        {
                            lable: 'Xã Thọ Cường',
                            value: '15754',
                        },
                        {
                            lable: 'Xã Thọ Dân',
                            value: '15742',
                        },
                        {
                            lable: 'Xã Thọ Ngọc',
                            value: '15751',
                        },
                        {
                            lable: 'Xã Thọ Phú',
                            value: '15757',
                        },
                        {
                            lable: 'Xã Thọ Sơn',
                            value: '15667',
                        },
                        {
                            lable: 'Xã Thọ Thế',
                            value: '15763',
                        },
                        {
                            lable: 'Xã Thọ Tiến',
                            value: '15673',
                        },
                        {
                            lable: 'Xã Thọ Tân',
                            value: '15748',
                        },
                        {
                            lable: 'Xã Thọ Vực',
                            value: '15760',
                        },
                        {
                            lable: 'Xã Tiến Nông',
                            value: '15730',
                        },
                        {
                            lable: 'Xã Triệu Thành',
                            value: '15685',
                        },
                        {
                            lable: 'Xã Văn Sơn',
                            value: '15712',
                        },
                        {
                            lable: 'Xã Xuân Lộc',
                            value: '15739',
                        },
                        {
                            lable: 'Xã Xuân Thịnh',
                            value: '15736',
                        },
                        {
                            lable: 'Xã Xuân Thọ',
                            value: '15745',
                        },
                        {
                            lable: 'Xã Đồng Lợi',
                            value: '15721',
                        },
                        {
                            lable: 'Xã Đồng Thắng',
                            value: '15727',
                        },
                        {
                            lable: 'Xã Đồng Tiến',
                            value: '15724',
                        },
                    ],
                },
                {
                    lable: 'Huyện Thiệu Hóa',
                    value: '398',
                    wards: [
                        {
                            lable: 'Thị trấn Thiệu Hóa',
                            value: '15772',
                        },
                        {
                            lable: 'Xã Minh Tâm',
                            value: '15829',
                        },
                        {
                            lable: 'Xã Thiệu Chính',
                            value: '15820',
                        },
                        {
                            lable: 'Xã Thiệu Công',
                            value: '15787',
                        },
                        {
                            lable: 'Xã Thiệu Duy',
                            value: '15799',
                        },
                        {
                            lable: 'Xã Thiệu Giang',
                            value: '15796',
                        },
                        {
                            lable: 'Xã Thiệu Giao',
                            value: '15853',
                        },
                        {
                            lable: 'Xã Thiệu Hòa',
                            value: '15823',
                        },
                        {
                            lable: 'Xã Thiệu Hợp',
                            value: '15805',
                        },
                        {
                            lable: 'Xã Thiệu Long',
                            value: '15793',
                        },
                        {
                            lable: 'Xã Thiệu Lý',
                            value: '15835',
                        },
                        {
                            lable: 'Xã Thiệu Nguyên',
                            value: '15802',
                        },
                        {
                            lable: 'Xã Thiệu Ngọc',
                            value: '15775',
                        },
                        {
                            lable: 'Xã Thiệu Phú',
                            value: '15790',
                        },
                        {
                            lable: 'Xã Thiệu Phúc',
                            value: '15781',
                        },
                        {
                            lable: 'Xã Thiệu Quang',
                            value: '15811',
                        },
                        {
                            lable: 'Xã Thiệu Thành',
                            value: '15814',
                        },
                        {
                            lable: 'Xã Thiệu Thịnh',
                            value: '15808',
                        },
                        {
                            lable: 'Xã Thiệu Tiến',
                            value: '15784',
                        },
                        {
                            lable: 'Xã Thiệu Toán',
                            value: '15817',
                        },
                        {
                            lable: 'Xã Thiệu Trung',
                            value: '15841',
                        },
                        {
                            lable: 'Xã Thiệu Viên',
                            value: '15832',
                        },
                        {
                            lable: 'Xã Thiệu Vũ',
                            value: '15778',
                        },
                        {
                            lable: 'Xã Thiệu Vận',
                            value: '15838',
                        },
                        {
                            lable: 'Xã Tân Châu',
                            value: '15847',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hoằng Hóa',
                    value: '399',
                    wards: [
                        {
                            lable: 'Thị trấn Bút Sơn',
                            value: '15865',
                        },
                        {
                            lable: 'Xã Hoằng Châu',
                            value: '15979',
                        },
                        {
                            lable: 'Xã Hoằng Cát',
                            value: '15907',
                        },
                        {
                            lable: 'Xã Hoằng Giang',
                            value: '15871',
                        },
                        {
                            lable: 'Xã Hoằng Hà',
                            value: '15937',
                        },
                        {
                            lable: 'Xã Hoằng Hải',
                            value: '15991',
                        },
                        {
                            lable: 'Xã Hoằng Hợp',
                            value: '15919',
                        },
                        {
                            lable: 'Xã Hoằng Kim',
                            value: '15889',
                        },
                        {
                            lable: 'Xã Hoằng Lưu',
                            value: '15976',
                        },
                        {
                            lable: 'Xã Hoằng Lộc',
                            value: '15964',
                        },
                        {
                            lable: 'Xã Hoằng Ngọc',
                            value: '15994',
                        },
                        {
                            lable: 'Xã Hoằng Phong',
                            value: '15973',
                        },
                        {
                            lable: 'Xã Hoằng Phú',
                            value: '15883',
                        },
                        {
                            lable: 'Xã Hoằng Phượng',
                            value: '15880',
                        },
                        {
                            lable: 'Xã Hoằng Phụ',
                            value: '16003',
                        },
                        {
                            lable: 'Xã Hoằng Quý',
                            value: '15916',
                        },
                        {
                            lable: 'Xã Hoằng Quỳ',
                            value: '15886',
                        },
                        {
                            lable: 'Xã Hoằng Sơn',
                            value: '15901',
                        },
                        {
                            lable: 'Xã Hoằng Thanh',
                            value: '16000',
                        },
                        {
                            lable: 'Xã Hoằng Thành',
                            value: '15961',
                        },
                        {
                            lable: 'Xã Hoằng Thái',
                            value: '15955',
                        },
                        {
                            lable: 'Xã Hoằng Thắng',
                            value: '15949',
                        },
                        {
                            lable: 'Xã Hoằng Thịnh',
                            value: '15958',
                        },
                        {
                            lable: 'Xã Hoằng Tiến',
                            value: '15988',
                        },
                        {
                            lable: 'Xã Hoằng Trinh',
                            value: '15895',
                        },
                        {
                            lable: 'Xã Hoằng Trung',
                            value: '15892',
                        },
                        {
                            lable: 'Xã Hoằng Trường',
                            value: '16006',
                        },
                        {
                            lable: 'Xã Hoằng Trạch',
                            value: '15967',
                        },
                        {
                            lable: 'Xã Hoằng Tân',
                            value: '15982',
                        },
                        {
                            lable: 'Xã Hoằng Xuyên',
                            value: '15910',
                        },
                        {
                            lable: 'Xã Hoằng Xuân',
                            value: '15877',
                        },
                        {
                            lable: 'Xã Hoằng Yến',
                            value: '15985',
                        },
                        {
                            lable: 'Xã Hoằng Đông',
                            value: '15997',
                        },
                        {
                            lable: 'Xã Hoằng Đạo',
                            value: '15946',
                        },
                        {
                            lable: 'Xã Hoằng Đạt',
                            value: '15940',
                        },
                        {
                            lable: 'Xã Hoằng Đồng',
                            value: '15952',
                        },
                        {
                            lable: 'Xã Hoằng Đức',
                            value: '15928',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hậu Lộc',
                    value: '400',
                    wards: [
                        {
                            lable: 'Thị trấn Hậu Lộc',
                            value: '16012',
                        },
                        {
                            lable: 'Xã Cầu Lộc',
                            value: '16033',
                        },
                        {
                            lable: 'Xã Hoa Lộc',
                            value: '16063',
                        },
                        {
                            lable: 'Xã Hòa Lộc',
                            value: '16075',
                        },
                        {
                            lable: 'Xã Hưng Lộc',
                            value: '16081',
                        },
                        {
                            lable: 'Xã Hải Lộc',
                            value: '16084',
                        },
                        {
                            lable: 'Xã Liên Lộc',
                            value: '16066',
                        },
                        {
                            lable: 'Xã Lộc Sơn',
                            value: '16030',
                        },
                        {
                            lable: 'Xã Minh Lộc',
                            value: '16078',
                        },
                        {
                            lable: 'Xã Mỹ Lộc',
                            value: '16045',
                        },
                        {
                            lable: 'Xã Ngư Lộc',
                            value: '16090',
                        },
                        {
                            lable: 'Xã Phong Lộc',
                            value: '16042',
                        },
                        {
                            lable: 'Xã Phú Lộc',
                            value: '16072',
                        },
                        {
                            lable: 'Xã Quang Lộc',
                            value: '16069',
                        },
                        {
                            lable: 'Xã Thuần Lộc',
                            value: '16048',
                        },
                        {
                            lable: 'Xã Thành Lộc',
                            value: '16036',
                        },
                        {
                            lable: 'Xã Tiến Lộc',
                            value: '16027',
                        },
                        {
                            lable: 'Xã Triệu Lộc',
                            value: '16021',
                        },
                        {
                            lable: 'Xã Tuy Lộc',
                            value: '16039',
                        },
                        {
                            lable: 'Xã Xuân Lộc',
                            value: '16057',
                        },
                        {
                            lable: 'Xã Đa Lộc',
                            value: '16087',
                        },
                        {
                            lable: 'Xã Đại Lộc',
                            value: '16018',
                        },
                        {
                            lable: 'Xã Đồng Lộc',
                            value: '16015',
                        },
                    ],
                },
                {
                    lable: 'Huyện Nga Sơn',
                    value: '401',
                    wards: [
                        {
                            lable: 'Thị trấn Nga Sơn',
                            value: '16093',
                        },
                        {
                            lable: 'Xã Ba Đình',
                            value: '16096',
                        },
                        {
                            lable: 'Xã Nga An',
                            value: '16144',
                        },
                        {
                            lable: 'Xã Nga Bạch',
                            value: '16120',
                        },
                        {
                            lable: 'Xã Nga Giáp',
                            value: '16135',
                        },
                        {
                            lable: 'Xã Nga Hải',
                            value: '16138',
                        },
                        {
                            lable: 'Xã Nga Liên',
                            value: '16159',
                        },
                        {
                            lable: 'Xã Nga Phú',
                            value: '16147',
                        },
                        {
                            lable: 'Xã Nga Phượng',
                            value: '16114',
                        },
                        {
                            lable: 'Xã Nga Thanh',
                            value: '16123',
                        },
                        {
                            lable: 'Xã Nga Thiện',
                            value: '16105',
                        },
                        {
                            lable: 'Xã Nga Thành',
                            value: '16141',
                        },
                        {
                            lable: 'Xã Nga Thái',
                            value: '16162',
                        },
                        {
                            lable: 'Xã Nga Thạch',
                            value: '16165',
                        },
                        {
                            lable: 'Xã Nga Thắng',
                            value: '16168',
                        },
                        {
                            lable: 'Xã Nga Thủy',
                            value: '16156',
                        },
                        {
                            lable: 'Xã Nga Tiến',
                            value: '16108',
                        },
                        {
                            lable: 'Xã Nga Trung',
                            value: '16117',
                        },
                        {
                            lable: 'Xã Nga Trường',
                            value: '16171',
                        },
                        {
                            lable: 'Xã Nga Tân',
                            value: '16153',
                        },
                        {
                            lable: 'Xã Nga Văn',
                            value: '16102',
                        },
                        {
                            lable: 'Xã Nga Vịnh',
                            value: '16099',
                        },
                        {
                            lable: 'Xã Nga Yên',
                            value: '16132',
                        },
                        {
                            lable: 'Xã Nga Điền',
                            value: '16150',
                        },
                    ],
                },
                {
                    lable: 'Huyện Như Xuân',
                    value: '402',
                    wards: [
                        {
                            lable: 'Thị trấn Yên Cát',
                            value: '16174',
                        },
                        {
                            lable: 'Xã Bãi Trành',
                            value: '16177',
                        },
                        {
                            lable: 'Xã Bình Lương',
                            value: '16204',
                        },
                        {
                            lable: 'Xã Cát Tân',
                            value: '16198',
                        },
                        {
                            lable: 'Xã Cát Vân',
                            value: '16195',
                        },
                        {
                            lable: 'Xã Hóa Quỳ',
                            value: '16186',
                        },
                        {
                            lable: 'Xã Thanh Hòa',
                            value: '16213',
                        },
                        {
                            lable: 'Xã Thanh Lâm',
                            value: '16219',
                        },
                        {
                            lable: 'Xã Thanh Phong',
                            value: '16216',
                        },
                        {
                            lable: 'Xã Thanh Quân',
                            value: '16207',
                        },
                        {
                            lable: 'Xã Thanh Sơn',
                            value: '16222',
                        },
                        {
                            lable: 'Xã Thanh Xuân',
                            value: '16210',
                        },
                        {
                            lable: 'Xã Thượng Ninh',
                            value: '16225',
                        },
                        {
                            lable: 'Xã Tân Bình',
                            value: '16201',
                        },
                        {
                            lable: 'Xã Xuân Bình',
                            value: '16183',
                        },
                        {
                            lable: 'Xã Xuân Hòa',
                            value: '16180',
                        },
                    ],
                },
                {
                    lable: 'Huyện Như Thanh',
                    value: '403',
                    wards: [
                        {
                            lable: 'Thị trấn Bến Sung',
                            value: '16228',
                        },
                        {
                            lable: 'Xã Cán Khê',
                            value: '16231',
                        },
                        {
                            lable: 'Xã Hải Long',
                            value: '16252',
                        },
                        {
                            lable: 'Xã Mậu Lâm',
                            value: '16243',
                        },
                        {
                            lable: 'Xã Phú Nhuận',
                            value: '16249',
                        },
                        {
                            lable: 'Xã Phượng Nghi',
                            value: '16240',
                        },
                        {
                            lable: 'Xã Thanh Kỳ',
                            value: '16276',
                        },
                        {
                            lable: 'Xã Thanh Tân',
                            value: '16273',
                        },
                        {
                            lable: 'Xã Xuân Du',
                            value: '16234',
                        },
                        {
                            lable: 'Xã Xuân Khang',
                            value: '16246',
                        },
                        {
                            lable: 'Xã Xuân Phúc',
                            value: '16261',
                        },
                        {
                            lable: 'Xã Xuân Thái',
                            value: '16258',
                        },
                        {
                            lable: 'Xã Yên Lạc',
                            value: '16267',
                        },
                        {
                            lable: 'Xã Yên Thọ',
                            value: '16264',
                        },
                    ],
                },
                {
                    lable: 'Huyện Nông Cống',
                    value: '404',
                    wards: [
                        {
                            lable: 'Thị trấn Nông Cống',
                            value: '16279',
                        },
                        {
                            lable: 'Xã Công Chính',
                            value: '16369',
                        },
                        {
                            lable: 'Xã Công Liêm',
                            value: '16354',
                        },
                        {
                            lable: 'Xã Hoàng Giang',
                            value: '16294',
                        },
                        {
                            lable: 'Xã Hoàng Sơn',
                            value: '16288',
                        },
                        {
                            lable: 'Xã Minh Khôi',
                            value: '16324',
                        },
                        {
                            lable: 'Xã Minh Nghĩa',
                            value: '16321',
                        },
                        {
                            lable: 'Xã Thăng Bình',
                            value: '16351',
                        },
                        {
                            lable: 'Xã Thăng Long',
                            value: '16342',
                        },
                        {
                            lable: 'Xã Thăng Thọ',
                            value: '16360',
                        },
                        {
                            lable: 'Xã Trung Chính',
                            value: '16297',
                        },
                        {
                            lable: 'Xã Trung Thành',
                            value: '16303',
                        },
                        {
                            lable: 'Xã Trường Giang',
                            value: '16336',
                        },
                        {
                            lable: 'Xã Trường Minh',
                            value: '16345',
                        },
                        {
                            lable: 'Xã Trường Sơn',
                            value: '16348',
                        },
                        {
                            lable: 'Xã Trường Trung',
                            value: '16330',
                        },
                        {
                            lable: 'Xã Tân Khang',
                            value: '16291',
                        },
                        {
                            lable: 'Xã Tân Phúc',
                            value: '16282',
                        },
                        {
                            lable: 'Xã Tân Thọ',
                            value: '16285',
                        },
                        {
                            lable: 'Xã Tượng Lĩnh',
                            value: '16363',
                        },
                        {
                            lable: 'Xã Tượng Sơn',
                            value: '16366',
                        },
                        {
                            lable: 'Xã Tượng Văn',
                            value: '16357',
                        },
                        {
                            lable: 'Xã Tế Lợi',
                            value: '16315',
                        },
                        {
                            lable: 'Xã Tế Nông',
                            value: '16318',
                        },
                        {
                            lable: 'Xã Tế Thắng',
                            value: '16309',
                        },
                        {
                            lable: 'Xã Vạn Hòa',
                            value: '16327',
                        },
                        {
                            lable: 'Xã Vạn Thiện',
                            value: '16339',
                        },
                        {
                            lable: 'Xã Vạn Thắng',
                            value: '16333',
                        },
                        {
                            lable: 'Xã Yên Mỹ',
                            value: '16375',
                        },
                    ],
                },
                {
                    lable: 'Huyện Đông Sơn',
                    value: '405',
                    wards: [
                        {
                            lable: 'Thị trấn Rừng Thông',
                            value: '16378',
                        },
                        {
                            lable: 'Xã Đông Hoàng',
                            value: '16381',
                        },
                        {
                            lable: 'Xã Đông Hòa',
                            value: '16390',
                        },
                        {
                            lable: 'Xã Đông Khê',
                            value: '16408',
                        },
                        {
                            lable: 'Xã Đông Minh',
                            value: '16399',
                        },
                        {
                            lable: 'Xã Đông Nam',
                            value: '16423',
                        },
                        {
                            lable: 'Xã Đông Ninh',
                            value: '16384',
                        },
                        {
                            lable: 'Xã Đông Phú',
                            value: '16420',
                        },
                        {
                            lable: 'Xã Đông Quang',
                            value: '16426',
                        },
                        {
                            lable: 'Xã Đông Thanh',
                            value: '16402',
                        },
                        {
                            lable: 'Xã Đông Thịnh',
                            value: '16414',
                        },
                        {
                            lable: 'Xã Đông Tiến',
                            value: '16405',
                        },
                        {
                            lable: 'Xã Đông Văn',
                            value: '16417',
                        },
                        {
                            lable: 'Xã Đông Yên',
                            value: '16393',
                        },
                    ],
                },
                {
                    lable: 'Huyện Quảng Xương',
                    value: '406',
                    wards: [
                        {
                            lable: 'Thị trấn Tân Phong',
                            value: '16438',
                        },
                        {
                            lable: 'Xã Quảng Bình',
                            value: '16468',
                        },
                        {
                            lable: 'Xã Quảng Chính',
                            value: '16495',
                        },
                        {
                            lable: 'Xã Quảng Giao',
                            value: '16519',
                        },
                        {
                            lable: 'Xã Quảng Hòa',
                            value: '16483',
                        },
                        {
                            lable: 'Xã Quảng Hải',
                            value: '16540',
                        },
                        {
                            lable: 'Xã Quảng Hợp',
                            value: '16471',
                        },
                        {
                            lable: 'Xã Quảng Khê',
                            value: '16489',
                        },
                        {
                            lable: 'Xã Quảng Long',
                            value: '16477',
                        },
                        {
                            lable: 'Xã Quảng Lưu',
                            value: '16543',
                        },
                        {
                            lable: 'Xã Quảng Lộc',
                            value: '16546',
                        },
                        {
                            lable: 'Xã Quảng Ngọc',
                            value: '16498',
                        },
                        {
                            lable: 'Xã Quảng Nham',
                            value: '16552',
                        },
                        {
                            lable: 'Xã Quảng Nhân',
                            value: '16462',
                        },
                        {
                            lable: 'Xã Quảng Ninh',
                            value: '16465',
                        },
                        {
                            lable: 'Xã Quảng Phúc',
                            value: '16510',
                        },
                        {
                            lable: 'Xã Quảng Thái',
                            value: '16558',
                        },
                        {
                            lable: 'Xã Quảng Thạch',
                            value: '16555',
                        },
                        {
                            lable: 'Xã Quảng Trung',
                            value: '16492',
                        },
                        {
                            lable: 'Xã Quảng Trường',
                            value: '16501',
                        },
                        {
                            lable: 'Xã Quảng Trạch',
                            value: '16447',
                        },
                        {
                            lable: 'Xã Quảng Văn',
                            value: '16474',
                        },
                        {
                            lable: 'Xã Quảng Yên',
                            value: '16480',
                        },
                        {
                            lable: 'Xã Quảng Định',
                            value: '16456',
                        },
                        {
                            lable: 'Xã Quảng Đức',
                            value: '16453',
                        },
                        {
                            lable: 'Xã Tiên Trang',
                            value: '16549',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Nghi Sơn',
                    value: '407',
                    wards: [
                        {
                            lable: 'Phường Bình Minh',
                            value: '16618',
                        },
                        {
                            lable: 'Phường Hải An',
                            value: '16585',
                        },
                        {
                            lable: 'Phường Hải Bình',
                            value: '16633',
                        },
                        {
                            lable: 'Phường Hải Châu',
                            value: '16564',
                        },
                        {
                            lable: 'Phường Hải Hòa',
                            value: '16561',
                        },
                        {
                            lable: 'Phường Hải Lĩnh',
                            value: '16597',
                        },
                        {
                            lable: 'Phường Hải Ninh',
                            value: '16576',
                        },
                        {
                            lable: 'Phường Hải Thanh',
                            value: '16621',
                        },
                        {
                            lable: 'Phường Hải Thượng',
                            value: '16654',
                        },
                        {
                            lable: 'Phường Mai Lâm',
                            value: '16645',
                        },
                        {
                            lable: 'Phường Nguyên Bình',
                            value: '16609',
                        },
                        {
                            lable: 'Phường Ninh Hải',
                            value: '16606',
                        },
                        {
                            lable: 'Phường Trúc Lâm',
                            value: '16630',
                        },
                        {
                            lable: 'Phường Tân Dân',
                            value: '16594',
                        },
                        {
                            lable: 'Phường Tĩnh Hải',
                            value: '16642',
                        },
                        {
                            lable: 'Phường Xuân Lâm',
                            value: '16627',
                        },
                        {
                            lable: 'Xã Anh Sơn',
                            value: '16579',
                        },
                        {
                            lable: 'Xã Các Sơn',
                            value: '16591',
                        },
                        {
                            lable: 'Xã Hải Hà',
                            value: '16660',
                        },
                        {
                            lable: 'Xã Hải Nhân',
                            value: '16612',
                        },
                        {
                            lable: 'Xã Hải Yến',
                            value: '16651',
                        },
                        {
                            lable: 'Xã Nghi Sơn',
                            value: '16657',
                        },
                        {
                            lable: 'Xã Ngọc Lĩnh',
                            value: '16582',
                        },
                        {
                            lable: 'Xã Phú Lâm',
                            value: '16624',
                        },
                        {
                            lable: 'Xã Phú Sơn',
                            value: '16603',
                        },
                        {
                            lable: 'Xã Thanh Sơn',
                            value: '16570',
                        },
                        {
                            lable: 'Xã Thanh Thủy',
                            value: '16567',
                        },
                        {
                            lable: 'Xã Trường Lâm',
                            value: '16648',
                        },
                        {
                            lable: 'Xã Tân Trường',
                            value: '16636',
                        },
                        {
                            lable: 'Xã Tùng Lâm',
                            value: '16639',
                        },
                        {
                            lable: 'Xã Định Hải',
                            value: '16600',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Thừa Thiên Huế',
            value: '46',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Huế',
                    value: '474',
                    wards: [
                        {
                            lable: 'Phường An Cựu',
                            value: '19801',
                        },
                        {
                            lable: 'Phường An Hòa',
                            value: '19803',
                        },
                        {
                            lable: 'Phường An Tây',
                            value: '19816',
                        },
                        {
                            lable: 'Phường An Đông',
                            value: '19815',
                        },
                        {
                            lable: 'Phường Gia Hội',
                            value: '19756',
                        },
                        {
                            lable: 'Phường Hương An',
                            value: '20023',
                        },
                        {
                            lable: 'Phường Hương Hồ',
                            value: '20029',
                        },
                        {
                            lable: 'Phường Hương Long',
                            value: '19810',
                        },
                        {
                            lable: 'Phường Hương Sơ',
                            value: '19804',
                        },
                        {
                            lable: 'Phường Hương Vinh',
                            value: '20014',
                        },
                        {
                            lable: 'Phường Kim Long',
                            value: '19774',
                        },
                        {
                            lable: 'Phường Phú Hậu',
                            value: '19759',
                        },
                        {
                            lable: 'Phường Phú Hội',
                            value: '19786',
                        },
                        {
                            lable: 'Phường Phú Nhuận',
                            value: '19789',
                        },
                        {
                            lable: 'Phường Phú Thượng',
                            value: '19930',
                        },
                        {
                            lable: 'Phường Phước Vĩnh',
                            value: '19798',
                        },
                        {
                            lable: 'Phường Phường Đúc',
                            value: '19780',
                        },
                        {
                            lable: 'Phường Thuận An',
                            value: '19900',
                        },
                        {
                            lable: 'Phường Thuận Hòa',
                            value: '19762',
                        },
                        {
                            lable: 'Phường Thuận Lộc',
                            value: '19753',
                        },
                        {
                            lable: 'Phường Thuỷ Biều',
                            value: '19807',
                        },
                        {
                            lable: 'Phường Thuỷ Xuân',
                            value: '19813',
                        },
                        {
                            lable: 'Phường Thủy Vân',
                            value: '19963',
                        },
                        {
                            lable: 'Phường Trường An',
                            value: '19795',
                        },
                        {
                            lable: 'Phường Tây Lộc',
                            value: '19750',
                        },
                        {
                            lable: 'Phường Vĩnh Ninh',
                            value: '19783',
                        },
                        {
                            lable: 'Phường Vỹ Dạ',
                            value: '19777',
                        },
                        {
                            lable: 'Phường Xuân Phú',
                            value: '19792',
                        },
                        {
                            lable: 'Phường Đông Ba',
                            value: '19768',
                        },
                        {
                            lable: 'Xã Hương Phong',
                            value: '20002',
                        },
                        {
                            lable: 'Xã Hương Thọ',
                            value: '20032',
                        },
                        {
                            lable: 'Xã Hải Dương',
                            value: '19999',
                        },
                        {
                            lable: 'Xã Phú Dương',
                            value: '19906',
                        },
                        {
                            lable: 'Xã Phú Mậu',
                            value: '19909',
                        },
                        {
                            lable: 'Xã Phú Thanh',
                            value: '19924',
                        },
                        {
                            lable: 'Xã Thủy Bằng',
                            value: '19981',
                        },
                    ],
                },
                {
                    lable: 'Huyện Phong Điền',
                    value: '476',
                    wards: [
                        {
                            lable: 'Thị trấn Phong Điền',
                            value: '19819',
                        },
                        {
                            lable: 'Xã Phong An',
                            value: '19858',
                        },
                        {
                            lable: 'Xã Phong Bình',
                            value: '19831',
                        },
                        {
                            lable: 'Xã Phong Chương',
                            value: '19837',
                        },
                        {
                            lable: 'Xã Phong Hiền',
                            value: '19852',
                        },
                        {
                            lable: 'Xã Phong Hòa',
                            value: '19846',
                        },
                        {
                            lable: 'Xã Phong Hải',
                            value: '19840',
                        },
                        {
                            lable: 'Xã Phong Mỹ',
                            value: '19855',
                        },
                        {
                            lable: 'Xã Phong Sơn',
                            value: '19864',
                        },
                        {
                            lable: 'Xã Phong Thu',
                            value: '19849',
                        },
                        {
                            lable: 'Xã Phong Xuân',
                            value: '19861',
                        },
                        {
                            lable: 'Xã Điền Hòa',
                            value: '19834',
                        },
                        {
                            lable: 'Xã Điền Hương',
                            value: '19822',
                        },
                        {
                            lable: 'Xã Điền Hải',
                            value: '19843',
                        },
                        {
                            lable: 'Xã Điền Lộc',
                            value: '19828',
                        },
                        {
                            lable: 'Xã Điền Môn',
                            value: '19825',
                        },
                    ],
                },
                {
                    lable: 'Huyện Quảng Điền',
                    value: '477',
                    wards: [
                        {
                            lable: 'Thị trấn Sịa',
                            value: '19867',
                        },
                        {
                            lable: 'Xã Quảng An',
                            value: '19888',
                        },
                        {
                            lable: 'Xã Quảng Công',
                            value: '19879',
                        },
                        {
                            lable: 'Xã Quảng Lợi',
                            value: '19876',
                        },
                        {
                            lable: 'Xã Quảng Ngạn',
                            value: '19873',
                        },
                        {
                            lable: 'Xã Quảng Phú',
                            value: '19897',
                        },
                        {
                            lable: 'Xã Quảng Phước',
                            value: '19882',
                        },
                        {
                            lable: 'Xã Quảng Thành',
                            value: '19891',
                        },
                        {
                            lable: 'Xã Quảng Thái',
                            value: '19870',
                        },
                        {
                            lable: 'Xã Quảng Thọ',
                            value: '19894',
                        },
                        {
                            lable: 'Xã Quảng Vinh',
                            value: '19885',
                        },
                    ],
                },
                {
                    lable: 'Huyện Phú Vang',
                    value: '478',
                    wards: [
                        {
                            lable: 'Thị trấn Phú Đa',
                            value: '19942',
                        },
                        {
                            lable: 'Xã Phú An',
                            value: '19912',
                        },
                        {
                            lable: 'Xã Phú Diên',
                            value: '19921',
                        },
                        {
                            lable: 'Xã Phú Gia',
                            value: '19954',
                        },
                        {
                            lable: 'Xã Phú Hải',
                            value: '19915',
                        },
                        {
                            lable: 'Xã Phú Hồ',
                            value: '19933',
                        },
                        {
                            lable: 'Xã Phú Lương',
                            value: '19939',
                        },
                        {
                            lable: 'Xã Phú Mỹ',
                            value: '19927',
                        },
                        {
                            lable: 'Xã Phú Thuận',
                            value: '19903',
                        },
                        {
                            lable: 'Xã Phú Xuân',
                            value: '19918',
                        },
                        {
                            lable: 'Xã Vinh An',
                            value: '19948',
                        },
                        {
                            lable: 'Xã Vinh Hà',
                            value: '19957',
                        },
                        {
                            lable: 'Xã Vinh Thanh',
                            value: '19945',
                        },
                        {
                            lable: 'Xã Vinh Xuân',
                            value: '19936',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Hương Thủy',
                    value: '479',
                    wards: [
                        {
                            lable: 'Phường Phú Bài',
                            value: '19960',
                        },
                        {
                            lable: 'Phường Thủy Châu',
                            value: '19975',
                        },
                        {
                            lable: 'Phường Thủy Dương',
                            value: '19969',
                        },
                        {
                            lable: 'Phường Thủy Lương',
                            value: '19978',
                        },
                        {
                            lable: 'Phường Thủy Phương',
                            value: '19972',
                        },
                        {
                            lable: 'Xã Dương Hòa',
                            value: '19993',
                        },
                        {
                            lable: 'Xã Phú Sơn',
                            value: '19990',
                        },
                        {
                            lable: 'Xã Thủy Phù',
                            value: '19987',
                        },
                        {
                            lable: 'Xã Thủy Thanh',
                            value: '19966',
                        },
                        {
                            lable: 'Xã Thủy Tân',
                            value: '19984',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Hương Trà',
                    value: '480',
                    wards: [
                        {
                            lable: 'Phường Hương Chữ',
                            value: '20020',
                        },
                        {
                            lable: 'Phường Hương Vân',
                            value: '20008',
                        },
                        {
                            lable: 'Phường Hương Văn',
                            value: '20011',
                        },
                        {
                            lable: 'Phường Hương Xuân',
                            value: '20017',
                        },
                        {
                            lable: 'Phường Tứ Hạ',
                            value: '19996',
                        },
                        {
                            lable: 'Xã Bình Thành',
                            value: '20041',
                        },
                        {
                            lable: 'Xã Bình Tiến',
                            value: '20035',
                        },
                        {
                            lable: 'Xã Hương Bình',
                            value: '20026',
                        },
                        {
                            lable: 'Xã Hương Toàn',
                            value: '20005',
                        },
                    ],
                },
                {
                    lable: 'Huyện A Lưới',
                    value: '481',
                    wards: [
                        {
                            lable: 'Thị trấn A Lưới',
                            value: '20044',
                        },
                        {
                            lable: 'Xã A Ngo',
                            value: '20068',
                        },
                        {
                            lable: 'Xã A Roàng',
                            value: '20095',
                        },
                        {
                            lable: 'Xã Hương Nguyên',
                            value: '20059',
                        },
                        {
                            lable: 'Xã Hương Phong',
                            value: '20080',
                        },
                        {
                            lable: 'Xã Hồng Bắc',
                            value: '20065',
                        },
                        {
                            lable: 'Xã Hồng Hạ',
                            value: '20050',
                        },
                        {
                            lable: 'Xã Hồng Kim',
                            value: '20053',
                        },
                        {
                            lable: 'Xã Hồng Thái',
                            value: '20089',
                        },
                        {
                            lable: 'Xã Hồng Thượng',
                            value: '20086',
                        },
                        {
                            lable: 'Xã Hồng Thủy',
                            value: '20104',
                        },
                        {
                            lable: 'Xã Hồng Vân',
                            value: '20047',
                        },
                        {
                            lable: 'Xã Lâm Đớt',
                            value: '20101',
                        },
                        {
                            lable: 'Xã Phú Vinh',
                            value: '20074',
                        },
                        {
                            lable: 'Xã Quảng Nhâm',
                            value: '20083',
                        },
                        {
                            lable: 'Xã Sơn Thủy',
                            value: '20071',
                        },
                        {
                            lable: 'Xã Trung Sơn',
                            value: '20056',
                        },
                        {
                            lable: 'Xã Đông Sơn',
                            value: '20098',
                        },
                    ],
                },
                {
                    lable: 'Huyện Phú Lộc',
                    value: '482',
                    wards: [
                        {
                            lable: 'Thị trấn Lăng Cô',
                            value: '20110',
                        },
                        {
                            lable: 'Thị trấn Phú Lộc',
                            value: '20107',
                        },
                        {
                            lable: 'Xã Giang Hải',
                            value: '20122',
                        },
                        {
                            lable: 'Xã Lộc An',
                            value: '20140',
                        },
                        {
                            lable: 'Xã Lộc Bình',
                            value: '20134',
                        },
                        {
                            lable: 'Xã Lộc Bổn',
                            value: '20128',
                        },
                        {
                            lable: 'Xã Lộc Hòa',
                            value: '20155',
                        },
                        {
                            lable: 'Xã Lộc Sơn',
                            value: '20131',
                        },
                        {
                            lable: 'Xã Lộc Thủy',
                            value: '20146',
                        },
                        {
                            lable: 'Xã Lộc Tiến',
                            value: '20152',
                        },
                        {
                            lable: 'Xã Lộc Trì',
                            value: '20149',
                        },
                        {
                            lable: 'Xã Lộc Vĩnh',
                            value: '20137',
                        },
                        {
                            lable: 'Xã Lộc Điền',
                            value: '20143',
                        },
                        {
                            lable: 'Xã Vinh Hiền',
                            value: '20125',
                        },
                        {
                            lable: 'Xã Vinh Hưng',
                            value: '20116',
                        },
                        {
                            lable: 'Xã Vinh Mỹ',
                            value: '20113',
                        },
                        {
                            lable: 'Xã Xuân Lộc',
                            value: '20158',
                        },
                    ],
                },
                {
                    lable: 'Huyện Nam Đông',
                    value: '483',
                    wards: [
                        {
                            lable: 'Thị trấn Khe Tre',
                            value: '20161',
                        },
                        {
                            lable: 'Xã Hương Hữu',
                            value: '20182',
                        },
                        {
                            lable: 'Xã Hương Lộc',
                            value: '20170',
                        },
                        {
                            lable: 'Xã Hương Phú',
                            value: '20164',
                        },
                        {
                            lable: 'Xã Hương Sơn',
                            value: '20167',
                        },
                        {
                            lable: 'Xã Hương Xuân',
                            value: '20179',
                        },
                        {
                            lable: 'Xã Thượng Long',
                            value: '20188',
                        },
                        {
                            lable: 'Xã Thượng Lộ',
                            value: '20185',
                        },
                        {
                            lable: 'Xã Thượng Nhật',
                            value: '20191',
                        },
                        {
                            lable: 'Xã Thượng Quảng',
                            value: '20173',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Tiền Giang',
            value: '82',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Mỹ Tho',
                    value: '815',
                    wards: [
                        {
                            lable: 'Phường 1',
                            value: '28261',
                        },
                        {
                            lable: 'Phường 10',
                            value: '28276',
                        },
                        {
                            lable: 'Phường 2',
                            value: '28264',
                        },
                        {
                            lable: 'Phường 3',
                            value: '28258',
                        },
                        {
                            lable: 'Phường 4',
                            value: '28252',
                        },
                        {
                            lable: 'Phường 5',
                            value: '28249',
                        },
                        {
                            lable: 'Phường 6',
                            value: '28270',
                        },
                        {
                            lable: 'Phường 7',
                            value: '28255',
                        },
                        {
                            lable: 'Phường 8',
                            value: '28267',
                        },
                        {
                            lable: 'Phường 9',
                            value: '28273',
                        },
                        {
                            lable: 'Phường Tân Long',
                            value: '28279',
                        },
                        {
                            lable: 'Xã Mỹ Phong',
                            value: '28288',
                        },
                        {
                            lable: 'Xã Phước Thạnh',
                            value: '28567',
                        },
                        {
                            lable: 'Xã Thới Sơn',
                            value: '28591',
                        },
                        {
                            lable: 'Xã Trung An',
                            value: '28285',
                        },
                        {
                            lable: 'Xã Tân Mỹ Chánh',
                            value: '28291',
                        },
                        {
                            lable: 'Xã Đạo Thạnh',
                            value: '28282',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Gò Công',
                    value: '816',
                    wards: [
                        {
                            lable: 'Phường 1',
                            value: '28303',
                        },
                        {
                            lable: 'Phường 2',
                            value: '28297',
                        },
                        {
                            lable: 'Phường 3',
                            value: '28294',
                        },
                        {
                            lable: 'Phường 4',
                            value: '28300',
                        },
                        {
                            lable: 'Phường 5',
                            value: '28306',
                        },
                        {
                            lable: 'Xã Bình Xuân',
                            value: '28717',
                        },
                        {
                            lable: 'Xã Bình Đông',
                            value: '28708',
                        },
                        {
                            lable: 'Xã Long Chánh',
                            value: '28315',
                        },
                        {
                            lable: 'Xã Long Hòa',
                            value: '28318',
                        },
                        {
                            lable: 'Xã Long Hưng',
                            value: '28309',
                        },
                        {
                            lable: 'Xã Long Thuận',
                            value: '28312',
                        },
                        {
                            lable: 'Xã Tân Trung',
                            value: '28729',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Cai Lậy',
                    value: '817',
                    wards: [
                        {
                            lable: 'Phường 1',
                            value: '28435',
                        },
                        {
                            lable: 'Phường 2',
                            value: '28436',
                        },
                        {
                            lable: 'Phường 3',
                            value: '28437',
                        },
                        {
                            lable: 'Phường 4',
                            value: '28439',
                        },
                        {
                            lable: 'Phường 5',
                            value: '28440',
                        },
                        {
                            lable: 'Phường Nhị Mỹ',
                            value: '28474',
                        },
                        {
                            lable: 'Xã Long Khánh',
                            value: '28486',
                        },
                        {
                            lable: 'Xã Mỹ Hạnh Trung',
                            value: '28453',
                        },
                        {
                            lable: 'Xã Mỹ Hạnh Đông',
                            value: '28450',
                        },
                        {
                            lable: 'Xã Mỹ Phước Tây',
                            value: '28447',
                        },
                        {
                            lable: 'Xã Nhị Quý',
                            value: '28477',
                        },
                        {
                            lable: 'Xã Phú Quý',
                            value: '28483',
                        },
                        {
                            lable: 'Xã Thanh Hòa',
                            value: '28480',
                        },
                        {
                            lable: 'Xã Tân Bình',
                            value: '28462',
                        },
                        {
                            lable: 'Xã Tân Hội',
                            value: '28468',
                        },
                        {
                            lable: 'Xã Tân Phú',
                            value: '28459',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tân Phước',
                    value: '818',
                    wards: [
                        {
                            lable: 'Thị trấn Mỹ Phước',
                            value: '28321',
                        },
                        {
                            lable: 'Xã Hưng Thạnh',
                            value: '28342',
                        },
                        {
                            lable: 'Xã Phú Mỹ',
                            value: '28336',
                        },
                        {
                            lable: 'Xã Phước Lập',
                            value: '28357',
                        },
                        {
                            lable: 'Xã Thạnh Hoà',
                            value: '28333',
                        },
                        {
                            lable: 'Xã Thạnh Mỹ',
                            value: '28330',
                        },
                        {
                            lable: 'Xã Thạnh Tân',
                            value: '28327',
                        },
                        {
                            lable: 'Xã Tân Hòa Thành',
                            value: '28339',
                        },
                        {
                            lable: 'Xã Tân Hòa Tây',
                            value: '28348',
                        },
                        {
                            lable: 'Xã Tân Hòa Đông',
                            value: '28324',
                        },
                        {
                            lable: 'Xã Tân Lập 1',
                            value: '28345',
                        },
                        {
                            lable: 'Xã Tân Lập 2',
                            value: '28354',
                        },
                    ],
                },
                {
                    lable: 'Huyện Cái Bè',
                    value: '819',
                    wards: [
                        {
                            lable: 'Thị trấn Cái Bè',
                            value: '28360',
                        },
                        {
                            lable: 'Xã An Cư',
                            value: '28390',
                        },
                        {
                            lable: 'Xã An Hữu',
                            value: '28429',
                        },
                        {
                            lable: 'Xã An Thái Trung',
                            value: '28426',
                        },
                        {
                            lable: 'Xã An Thái Đông',
                            value: '28414',
                        },
                        {
                            lable: 'Xã Hòa Hưng',
                            value: '28432',
                        },
                        {
                            lable: 'Xã Hòa Khánh',
                            value: '28399',
                        },
                        {
                            lable: 'Xã Hậu Mỹ Bắc A',
                            value: '28366',
                        },
                        {
                            lable: 'Xã Hậu Mỹ Bắc B',
                            value: '28363',
                        },
                        {
                            lable: 'Xã Hậu Mỹ Phú',
                            value: '28375',
                        },
                        {
                            lable: 'Xã Hậu Mỹ Trinh',
                            value: '28372',
                        },
                        {
                            lable: 'Xã Hậu Thành',
                            value: '28393',
                        },
                        {
                            lable: 'Xã Mỹ Hội',
                            value: '28387',
                        },
                        {
                            lable: 'Xã Mỹ Lương',
                            value: '28420',
                        },
                        {
                            lable: 'Xã Mỹ Lợi A',
                            value: '28396',
                        },
                        {
                            lable: 'Xã Mỹ Lợi B',
                            value: '28381',
                        },
                        {
                            lable: 'Xã Mỹ Trung',
                            value: '28369',
                        },
                        {
                            lable: 'Xã Mỹ Tân',
                            value: '28378',
                        },
                        {
                            lable: 'Xã Mỹ Đức Tây',
                            value: '28408',
                        },
                        {
                            lable: 'Xã Mỹ Đức Đông',
                            value: '28405',
                        },
                        {
                            lable: 'Xã Thiện Trung',
                            value: '28384',
                        },
                        {
                            lable: 'Xã Thiện Trí',
                            value: '28402',
                        },
                        {
                            lable: 'Xã Tân Hưng',
                            value: '28417',
                        },
                        {
                            lable: 'Xã Tân Thanh',
                            value: '28423',
                        },
                        {
                            lable: 'Xã Đông Hòa Hiệp',
                            value: '28411',
                        },
                    ],
                },
                {
                    lable: 'Huyện Cai Lậy',
                    value: '820',
                    wards: [
                        {
                            lable: 'Xã Bình Phú',
                            value: '28471',
                        },
                        {
                            lable: 'Xã Cẩm Sơn',
                            value: '28489',
                        },
                        {
                            lable: 'Xã Hiệp Đức',
                            value: '28501',
                        },
                        {
                            lable: 'Xã Hội Xuân',
                            value: '28507',
                        },
                        {
                            lable: 'Xã Long Tiên',
                            value: '28498',
                        },
                        {
                            lable: 'Xã Long Trung',
                            value: '28504',
                        },
                        {
                            lable: 'Xã Mỹ Long',
                            value: '28495',
                        },
                        {
                            lable: 'Xã Mỹ Thành Bắc',
                            value: '28441',
                        },
                        {
                            lable: 'Xã Mỹ Thành Nam',
                            value: '28456',
                        },
                        {
                            lable: 'Xã Ngũ Hiệp',
                            value: '28516',
                        },
                        {
                            lable: 'Xã Phú An',
                            value: '28492',
                        },
                        {
                            lable: 'Xã Phú Cường',
                            value: '28444',
                        },
                        {
                            lable: 'Xã Phú Nhuận',
                            value: '28465',
                        },
                        {
                            lable: 'Xã Tam Bình',
                            value: '28513',
                        },
                        {
                            lable: 'Xã Thạnh Lộc',
                            value: '28438',
                        },
                        {
                            lable: 'Xã Tân Phong',
                            value: '28510',
                        },
                    ],
                },
                {
                    lable: 'Huyện Châu Thành',
                    value: '821',
                    wards: [
                        {
                            lable: 'Thị trấn Tân Hiệp',
                            value: '28519',
                        },
                        {
                            lable: 'Xã Bàn Long',
                            value: '28573',
                        },
                        {
                            lable: 'Xã Bình Trưng',
                            value: '28564',
                        },
                        {
                            lable: 'Xã Bình Đức',
                            value: '28579',
                        },
                        {
                            lable: 'Xã Dưỡng Điềm',
                            value: '28546',
                        },
                        {
                            lable: 'Xã Hữu Đạo',
                            value: '28555',
                        },
                        {
                            lable: 'Xã Kim Sơn',
                            value: '28585',
                        },
                        {
                            lable: 'Xã Long An',
                            value: '28558',
                        },
                        {
                            lable: 'Xã Long Hưng',
                            value: '28561',
                        },
                        {
                            lable: 'Xã Long Định',
                            value: '28552',
                        },
                        {
                            lable: 'Xã Nhị Bình',
                            value: '28543',
                        },
                        {
                            lable: 'Xã Phú Phong',
                            value: '28588',
                        },
                        {
                            lable: 'Xã Song Thuận',
                            value: '28582',
                        },
                        {
                            lable: 'Xã Tam Hiệp',
                            value: '28537',
                        },
                        {
                            lable: 'Xã Thân Cửu Nghĩa',
                            value: '28534',
                        },
                        {
                            lable: 'Xã Thạnh Phú',
                            value: '28570',
                        },
                        {
                            lable: 'Xã Tân Hương',
                            value: '28525',
                        },
                        {
                            lable: 'Xã Tân Hội Đông',
                            value: '28522',
                        },
                        {
                            lable: 'Xã Tân Lý Tây',
                            value: '28531',
                        },
                        {
                            lable: 'Xã Tân Lý Đông',
                            value: '28528',
                        },
                        {
                            lable: 'Xã Vĩnh Kim',
                            value: '28576',
                        },
                        {
                            lable: 'Xã Điềm Hy',
                            value: '28540',
                        },
                        {
                            lable: 'Xã Đông Hòa',
                            value: '28549',
                        },
                    ],
                },
                {
                    lable: 'Huyện Chợ Gạo',
                    value: '822',
                    wards: [
                        {
                            lable: 'Thị trấn Chợ Gạo',
                            value: '28594',
                        },
                        {
                            lable: 'Xã An Thạnh Thủy',
                            value: '28639',
                        },
                        {
                            lable: 'Xã Bình Ninh',
                            value: '28648',
                        },
                        {
                            lable: 'Xã Bình Phan',
                            value: '28633',
                        },
                        {
                            lable: 'Xã Bình Phục Nhứt',
                            value: '28621',
                        },
                        {
                            lable: 'Xã Hòa Tịnh',
                            value: '28600',
                        },
                        {
                            lable: 'Xã Hòa Định',
                            value: '28645',
                        },
                        {
                            lable: 'Xã Long Bình Điền',
                            value: '28636',
                        },
                        {
                            lable: 'Xã Lương Hòa Lạc',
                            value: '28612',
                        },
                        {
                            lable: 'Xã Mỹ Tịnh An',
                            value: '28603',
                        },
                        {
                            lable: 'Xã Phú Kiết',
                            value: '28609',
                        },
                        {
                            lable: 'Xã Quơn Long',
                            value: '28618',
                        },
                        {
                            lable: 'Xã Song Bình',
                            value: '28630',
                        },
                        {
                            lable: 'Xã Thanh Bình',
                            value: '28615',
                        },
                        {
                            lable: 'Xã Trung Hòa',
                            value: '28597',
                        },
                        {
                            lable: 'Xã Tân Bình Thạnh',
                            value: '28606',
                        },
                        {
                            lable: 'Xã Tân Thuận Bình',
                            value: '28627',
                        },
                        {
                            lable: 'Xã Xuân Đông',
                            value: '28642',
                        },
                        {
                            lable: 'Xã Đăng Hưng Phước',
                            value: '28624',
                        },
                    ],
                },
                {
                    lable: 'Huyện Gò Công Tây',
                    value: '823',
                    wards: [
                        {
                            lable: 'Thị trấn Vĩnh Bình',
                            value: '28651',
                        },
                        {
                            lable: 'Xã Bình Nhì',
                            value: '28666',
                        },
                        {
                            lable: 'Xã Bình Phú',
                            value: '28657',
                        },
                        {
                            lable: 'Xã Bình Tân',
                            value: '28681',
                        },
                        {
                            lable: 'Xã Long Bình',
                            value: '28687',
                        },
                        {
                            lable: 'Xã Long Vĩnh',
                            value: '28678',
                        },
                        {
                            lable: 'Xã Thành Công',
                            value: '28663',
                        },
                        {
                            lable: 'Xã Thạnh Nhựt',
                            value: '28675',
                        },
                        {
                            lable: 'Xã Thạnh Trị',
                            value: '28672',
                        },
                        {
                            lable: 'Xã Vĩnh Hựu',
                            value: '28684',
                        },
                        {
                            lable: 'Xã Yên Luông',
                            value: '28669',
                        },
                        {
                            lable: 'Xã Đồng Sơn',
                            value: '28654',
                        },
                        {
                            lable: 'Xã Đồng Thạnh',
                            value: '28660',
                        },
                    ],
                },
                {
                    lable: 'Huyện Gò Công Đông',
                    value: '824',
                    wards: [
                        {
                            lable: 'Thị trấn Tân Hòa',
                            value: '28702',
                        },
                        {
                            lable: 'Thị trấn Vàm Láng',
                            value: '28720',
                        },
                        {
                            lable: 'Xã Bình Nghị',
                            value: '28741',
                        },
                        {
                            lable: 'Xã Bình Ân',
                            value: '28735',
                        },
                        {
                            lable: 'Xã Gia Thuận',
                            value: '28714',
                        },
                        {
                            lable: 'Xã Kiểng Phước',
                            value: '28726',
                        },
                        {
                            lable: 'Xã Phước Trung',
                            value: '28744',
                        },
                        {
                            lable: 'Xã Tân Phước',
                            value: '28711',
                        },
                        {
                            lable: 'Xã Tân Thành',
                            value: '28747',
                        },
                        {
                            lable: 'Xã Tân Tây',
                            value: '28723',
                        },
                        {
                            lable: 'Xã Tân Điền',
                            value: '28738',
                        },
                        {
                            lable: 'Xã Tân Đông',
                            value: '28732',
                        },
                        {
                            lable: 'Xã Tăng Hoà',
                            value: '28705',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tân Phú Đông',
                    value: '825',
                    wards: [
                        {
                            lable: 'Xã Phú Thạnh',
                            value: '28696',
                        },
                        {
                            lable: 'Xã Phú Tân',
                            value: '28753',
                        },
                        {
                            lable: 'Xã Phú Đông',
                            value: '28750',
                        },
                        {
                            lable: 'Xã Tân Phú',
                            value: '28693',
                        },
                        {
                            lable: 'Xã Tân Thạnh',
                            value: '28699',
                        },
                        {
                            lable: 'Xã Tân Thới',
                            value: '28690',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Trà Vinh',
            value: '84',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Trà Vinh',
                    value: '842',
                    wards: [
                        {
                            lable: 'Phường 1',
                            value: '29239',
                        },
                        {
                            lable: 'Phường 2',
                            value: '29245',
                        },
                        {
                            lable: 'Phường 3',
                            value: '29242',
                        },
                        {
                            lable: 'Phường 4',
                            value: '29236',
                        },
                        {
                            lable: 'Phường 5',
                            value: '29248',
                        },
                        {
                            lable: 'Phường 6',
                            value: '29251',
                        },
                        {
                            lable: 'Phường 7',
                            value: '29254',
                        },
                        {
                            lable: 'Phường 8',
                            value: '29257',
                        },
                        {
                            lable: 'Phường 9',
                            value: '29260',
                        },
                        {
                            lable: 'Xã Long Đức',
                            value: '29263',
                        },
                    ],
                },
                {
                    lable: 'Huyện Càng Long',
                    value: '844',
                    wards: [
                        {
                            lable: 'Thị trấn Càng Long',
                            value: '29266',
                        },
                        {
                            lable: 'Xã An Trường',
                            value: '29275',
                        },
                        {
                            lable: 'Xã An Trường A',
                            value: '29272',
                        },
                        {
                            lable: 'Xã Bình Phú',
                            value: '29287',
                        },
                        {
                            lable: 'Xã Huyền Hội',
                            value: '29278',
                        },
                        {
                            lable: 'Xã Mỹ Cẩm',
                            value: '29269',
                        },
                        {
                            lable: 'Xã Nhị Long',
                            value: '29302',
                        },
                        {
                            lable: 'Xã Nhị Long Phú',
                            value: '29299',
                        },
                        {
                            lable: 'Xã Phương Thạnh',
                            value: '29290',
                        },
                        {
                            lable: 'Xã Tân An',
                            value: '29281',
                        },
                        {
                            lable: 'Xã Tân Bình',
                            value: '29284',
                        },
                        {
                            lable: 'Xã Đại Phúc',
                            value: '29293',
                        },
                        {
                            lable: 'Xã Đại Phước',
                            value: '29296',
                        },
                        {
                            lable: 'Xã Đức Mỹ',
                            value: '29305',
                        },
                    ],
                },
                {
                    lable: 'Huyện Cầu Kè',
                    value: '845',
                    wards: [
                        {
                            lable: 'Thị trấn Cầu Kè',
                            value: '29308',
                        },
                        {
                            lable: 'Xã An Phú Tân',
                            value: '29317',
                        },
                        {
                            lable: 'Xã Châu Điền',
                            value: '29314',
                        },
                        {
                            lable: 'Xã Hoà Tân',
                            value: '29320',
                        },
                        {
                            lable: 'Xã Hòa Ân',
                            value: '29311',
                        },
                        {
                            lable: 'Xã Ninh Thới',
                            value: '29323',
                        },
                        {
                            lable: 'Xã Phong Phú',
                            value: '29326',
                        },
                        {
                            lable: 'Xã Phong Thạnh',
                            value: '29329',
                        },
                        {
                            lable: 'Xã Tam Ngãi',
                            value: '29332',
                        },
                        {
                            lable: 'Xã Thông Hòa',
                            value: '29335',
                        },
                        {
                            lable: 'Xã Thạnh Phú',
                            value: '29338',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tiểu Cần',
                    value: '846',
                    wards: [
                        {
                            lable: 'Thị trấn Cầu Quan',
                            value: '29344',
                        },
                        {
                            lable: 'Thị trấn Tiểu Cần',
                            value: '29341',
                        },
                        {
                            lable: 'Xã Hiếu Trung',
                            value: '29353',
                        },
                        {
                            lable: 'Xã Hiếu Tử',
                            value: '29350',
                        },
                        {
                            lable: 'Xã Hùng Hòa',
                            value: '29359',
                        },
                        {
                            lable: 'Xã Long Thới',
                            value: '29356',
                        },
                        {
                            lable: 'Xã Ngãi Hùng',
                            value: '29368',
                        },
                        {
                            lable: 'Xã Phú Cần',
                            value: '29347',
                        },
                        {
                            lable: 'Xã Tân Hòa',
                            value: '29371',
                        },
                        {
                            lable: 'Xã Tân Hùng',
                            value: '29362',
                        },
                        {
                            lable: 'Xã Tập Ngãi',
                            value: '29365',
                        },
                    ],
                },
                {
                    lable: 'Huyện Châu Thành',
                    value: '847',
                    wards: [
                        {
                            lable: 'Thị trấn Châu Thành',
                            value: '29374',
                        },
                        {
                            lable: 'Xã Hòa Lợi',
                            value: '29401',
                        },
                        {
                            lable: 'Xã Hòa Minh',
                            value: '29410',
                        },
                        {
                            lable: 'Xã Hòa Thuận',
                            value: '29398',
                        },
                        {
                            lable: 'Xã Hưng Mỹ',
                            value: '29407',
                        },
                        {
                            lable: 'Xã Long Hòa',
                            value: '29413',
                        },
                        {
                            lable: 'Xã Lương Hoà A',
                            value: '29386',
                        },
                        {
                            lable: 'Xã Lương Hòa',
                            value: '29389',
                        },
                        {
                            lable: 'Xã Mỹ Chánh',
                            value: '29380',
                        },
                        {
                            lable: 'Xã Nguyệt Hóa',
                            value: '29395',
                        },
                        {
                            lable: 'Xã Phước Hảo',
                            value: '29404',
                        },
                        {
                            lable: 'Xã Song Lộc',
                            value: '29392',
                        },
                        {
                            lable: 'Xã Thanh Mỹ',
                            value: '29383',
                        },
                        {
                            lable: 'Xã Đa Lộc',
                            value: '29377',
                        },
                    ],
                },
                {
                    lable: 'Huyện Cầu Ngang',
                    value: '848',
                    wards: [
                        {
                            lable: 'Thị trấn Cầu Ngang',
                            value: '29416',
                        },
                        {
                            lable: 'Thị trấn Mỹ Long',
                            value: '29419',
                        },
                        {
                            lable: 'Xã Hiệp Hòa',
                            value: '29437',
                        },
                        {
                            lable: 'Xã Hiệp Mỹ Tây',
                            value: '29455',
                        },
                        {
                            lable: 'Xã Hiệp Mỹ Đông',
                            value: '29452',
                        },
                        {
                            lable: 'Xã Kim Hòa',
                            value: '29434',
                        },
                        {
                            lable: 'Xã Long Sơn',
                            value: '29443',
                        },
                        {
                            lable: 'Xã Mỹ Hòa',
                            value: '29428',
                        },
                        {
                            lable: 'Xã Mỹ Long Bắc',
                            value: '29422',
                        },
                        {
                            lable: 'Xã Mỹ Long Nam',
                            value: '29425',
                        },
                        {
                            lable: 'Xã Nhị Trường',
                            value: '29446',
                        },
                        {
                            lable: 'Xã Thuận Hòa',
                            value: '29440',
                        },
                        {
                            lable: 'Xã Thạnh Hòa Sơn',
                            value: '29458',
                        },
                        {
                            lable: 'Xã Trường Thọ',
                            value: '29449',
                        },
                        {
                            lable: 'Xã Vĩnh Kim',
                            value: '29431',
                        },
                    ],
                },
                {
                    lable: 'Huyện Trà Cú',
                    value: '849',
                    wards: [
                        {
                            lable: 'Thị trấn Trà Cú',
                            value: '29461',
                        },
                        {
                            lable: 'Thị trấn Định An',
                            value: '29462',
                        },
                        {
                            lable: 'Xã An Quảng Hữu',
                            value: '29473',
                        },
                        {
                            lable: 'Xã Hàm Giang',
                            value: '29488',
                        },
                        {
                            lable: 'Xã Hàm Tân',
                            value: '29489',
                        },
                        {
                            lable: 'Xã Kim Sơn',
                            value: '29482',
                        },
                        {
                            lable: 'Xã Long Hiệp',
                            value: '29506',
                        },
                        {
                            lable: 'Xã Lưu Nghiệp Anh',
                            value: '29476',
                        },
                        {
                            lable: 'Xã Ngãi Xuyên',
                            value: '29479',
                        },
                        {
                            lable: 'Xã Ngọc Biên',
                            value: '29503',
                        },
                        {
                            lable: 'Xã Phước Hưng',
                            value: '29464',
                        },
                        {
                            lable: 'Xã Thanh Sơn',
                            value: '29485',
                        },
                        {
                            lable: 'Xã Tân Hiệp',
                            value: '29509',
                        },
                        {
                            lable: 'Xã Tân Sơn',
                            value: '29470',
                        },
                        {
                            lable: 'Xã Tập Sơn',
                            value: '29467',
                        },
                        {
                            lable: 'Xã Đại An',
                            value: '29491',
                        },
                        {
                            lable: 'Xã Định An',
                            value: '29494',
                        },
                    ],
                },
                {
                    lable: 'Huyện Duyên Hải',
                    value: '850',
                    wards: [
                        {
                            lable: 'Thị trấn Long Thành',
                            value: '29513',
                        },
                        {
                            lable: 'Xã Long Khánh',
                            value: '29521',
                        },
                        {
                            lable: 'Xã Long Vĩnh',
                            value: '29533',
                        },
                        {
                            lable: 'Xã Ngũ Lạc',
                            value: '29530',
                        },
                        {
                            lable: 'Xã Đôn Châu',
                            value: '29500',
                        },
                        {
                            lable: 'Xã Đôn Xuân',
                            value: '29497',
                        },
                        {
                            lable: 'Xã Đông Hải',
                            value: '29536',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Duyên Hải',
                    value: '851',
                    wards: [
                        {
                            lable: 'Phường 1',
                            value: '29512',
                        },
                        {
                            lable: 'Phường 2',
                            value: '29516',
                        },
                        {
                            lable: 'Xã Dân Thành',
                            value: '29524',
                        },
                        {
                            lable: 'Xã Hiệp Thạnh',
                            value: '29539',
                        },
                        {
                            lable: 'Xã Long Hữu',
                            value: '29518',
                        },
                        {
                            lable: 'Xã Long Toàn',
                            value: '29515',
                        },
                        {
                            lable: 'Xã Trường Long Hòa',
                            value: '29527',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Tuyên Quang',
            value: '08',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Tuyên Quang',
                    value: '070',
                    wards: [
                        {
                            lable: 'Phường An Tường',
                            value: '02512',
                        },
                        {
                            lable: 'Phường Hưng Thành',
                            value: '02218',
                        },
                        {
                            lable: 'Phường Minh Xuân',
                            value: '02203',
                        },
                        {
                            lable: 'Phường Mỹ Lâm',
                            value: '02509',
                        },
                        {
                            lable: 'Phường Nông Tiến',
                            value: '02212',
                        },
                        {
                            lable: 'Phường Phan Thiết',
                            value: '02200',
                        },
                        {
                            lable: 'Phường Tân Hà',
                            value: '02216',
                        },
                        {
                            lable: 'Phường Tân Quang',
                            value: '02206',
                        },
                        {
                            lable: 'Phường Đội Cấn',
                            value: '02524',
                        },
                        {
                            lable: 'Phường Ỷ La',
                            value: '02215',
                        },
                        {
                            lable: 'Xã An Khang',
                            value: '02503',
                        },
                        {
                            lable: 'Xã Kim Phú',
                            value: '02497',
                        },
                        {
                            lable: 'Xã Lưỡng Vượng',
                            value: '02515',
                        },
                        {
                            lable: 'Xã Thái Long',
                            value: '02521',
                        },
                        {
                            lable: 'Xã Tràng Đà',
                            value: '02209',
                        },
                    ],
                },
                {
                    lable: 'Huyện Lâm Bình',
                    value: '071',
                    wards: [
                        {
                            lable: 'Thị trấn Lăng Can',
                            value: '02266',
                        },
                        {
                            lable: 'Xã Bình An',
                            value: '02290',
                        },
                        {
                            lable: 'Xã Hồng Quang',
                            value: '02293',
                        },
                        {
                            lable: 'Xã Khuôn Hà',
                            value: '02251',
                        },
                        {
                            lable: 'Xã Minh Quang',
                            value: '02302',
                        },
                        {
                            lable: 'Xã Phúc Sơn',
                            value: '02299',
                        },
                        {
                            lable: 'Xã Phúc Yên',
                            value: '02233',
                        },
                        {
                            lable: 'Xã Thượng Lâm',
                            value: '02269',
                        },
                        {
                            lable: 'Xã Thổ Bình',
                            value: '02296',
                        },
                        {
                            lable: 'Xã Xuân Lập',
                            value: '02242',
                        },
                    ],
                },
                {
                    lable: 'Huyện Na Hang',
                    value: '072',
                    wards: [
                        {
                            lable: 'Thị trấn Na Hang',
                            value: '02221',
                        },
                        {
                            lable: 'Xã Côn Lôn',
                            value: '02245',
                        },
                        {
                            lable: 'Xã Hồng Thái',
                            value: '02254',
                        },
                        {
                            lable: 'Xã Khau Tinh',
                            value: '02263',
                        },
                        {
                            lable: 'Xã Năng Khả',
                            value: '02281',
                        },
                        {
                            lable: 'Xã Sinh Long',
                            value: '02227',
                        },
                        {
                            lable: 'Xã Sơn Phú',
                            value: '02275',
                        },
                        {
                            lable: 'Xã Thanh Tương',
                            value: '02284',
                        },
                        {
                            lable: 'Xã Thượng Giáp',
                            value: '02230',
                        },
                        {
                            lable: 'Xã Thượng Nông',
                            value: '02239',
                        },
                        {
                            lable: 'Xã Yên Hoa',
                            value: '02248',
                        },
                        {
                            lable: 'Xã Đà Vị',
                            value: '02260',
                        },
                    ],
                },
                {
                    lable: 'Huyện Chiêm Hóa',
                    value: '073',
                    wards: [
                        {
                            lable: 'Thị trấn Vĩnh Lộc',
                            value: '02287',
                        },
                        {
                            lable: 'Xã Bình Nhân',
                            value: '02371',
                        },
                        {
                            lable: 'Xã Bình Phú',
                            value: '02323',
                        },
                        {
                            lable: 'Xã Hà Lang',
                            value: '02311',
                        },
                        {
                            lable: 'Xã Hòa An',
                            value: '02353',
                        },
                        {
                            lable: 'Xã Hòa Phú',
                            value: '02335',
                        },
                        {
                            lable: 'Xã Hùng Mỹ',
                            value: '02314',
                        },
                        {
                            lable: 'Xã Kim Bình',
                            value: '02350',
                        },
                        {
                            lable: 'Xã Kiên Đài',
                            value: '02341',
                        },
                        {
                            lable: 'Xã Linh Phú',
                            value: '02368',
                        },
                        {
                            lable: 'Xã Ngọc Hội',
                            value: '02329',
                        },
                        {
                            lable: 'Xã Nhân Lý',
                            value: '02362',
                        },
                        {
                            lable: 'Xã Phú Bình',
                            value: '02332',
                        },
                        {
                            lable: 'Xã Phúc Thịnh',
                            value: '02338',
                        },
                        {
                            lable: 'Xã Tri Phú',
                            value: '02359',
                        },
                        {
                            lable: 'Xã Trung Hà',
                            value: '02305',
                        },
                        {
                            lable: 'Xã Trung Hòa',
                            value: '02347',
                        },
                        {
                            lable: 'Xã Tân An',
                            value: '02320',
                        },
                        {
                            lable: 'Xã Tân Mỹ',
                            value: '02308',
                        },
                        {
                            lable: 'Xã Tân Thịnh',
                            value: '02344',
                        },
                        {
                            lable: 'Xã Vinh Quang',
                            value: '02356',
                        },
                        {
                            lable: 'Xã Xuân Quang',
                            value: '02326',
                        },
                        {
                            lable: 'Xã Yên Lập',
                            value: '02317',
                        },
                        {
                            lable: 'Xã Yên Nguyên',
                            value: '02365',
                        },
                    ],
                },
                {
                    lable: 'Huyện Hàm Yên',
                    value: '074',
                    wards: [
                        {
                            lable: 'Thị trấn Tân Yên',
                            value: '02374',
                        },
                        {
                            lable: 'Xã Bình Xa',
                            value: '02404',
                        },
                        {
                            lable: 'Xã Bạch Xa',
                            value: '02380',
                        },
                        {
                            lable: 'Xã Bằng Cốc',
                            value: '02416',
                        },
                        {
                            lable: 'Xã Hùng Đức',
                            value: '02425',
                        },
                        {
                            lable: 'Xã Minh Dân',
                            value: '02389',
                        },
                        {
                            lable: 'Xã Minh Hương',
                            value: '02395',
                        },
                        {
                            lable: 'Xã Minh Khương',
                            value: '02383',
                        },
                        {
                            lable: 'Xã Nhân Mục',
                            value: '02410',
                        },
                        {
                            lable: 'Xã Phù Lưu',
                            value: '02392',
                        },
                        {
                            lable: 'Xã Thành Long',
                            value: '02413',
                        },
                        {
                            lable: 'Xã Thái Hòa',
                            value: '02419',
                        },
                        {
                            lable: 'Xã Thái Sơn',
                            value: '02407',
                        },
                        {
                            lable: 'Xã Tân Thành',
                            value: '02401',
                        },
                        {
                            lable: 'Xã Yên Lâm',
                            value: '02386',
                        },
                        {
                            lable: 'Xã Yên Phú',
                            value: '02398',
                        },
                        {
                            lable: 'Xã Yên Thuận',
                            value: '02377',
                        },
                        {
                            lable: 'Xã Đức Ninh',
                            value: '02422',
                        },
                    ],
                },
                {
                    lable: 'Huyện Yên Sơn',
                    value: '075',
                    wards: [
                        {
                            lable: 'Thị trấn Yên Sơn',
                            value: '02473',
                        },
                        {
                            lable: 'Xã Chiêu Yên',
                            value: '02443',
                        },
                        {
                            lable: 'Xã Chân Sơn',
                            value: '02491',
                        },
                        {
                            lable: 'Xã Công Đa',
                            value: '02485',
                        },
                        {
                            lable: 'Xã Hoàng Khai',
                            value: '02518',
                        },
                        {
                            lable: 'Xã Hùng Lợi',
                            value: '02455',
                        },
                        {
                            lable: 'Xã Kim Quan',
                            value: '02476',
                        },
                        {
                            lable: 'Xã Kiến Thiết',
                            value: '02437',
                        },
                        {
                            lable: 'Xã Lang Quán',
                            value: '02479',
                        },
                        {
                            lable: 'Xã Lực Hành',
                            value: '02434',
                        },
                        {
                            lable: 'Xã Mỹ Bằng',
                            value: '02506',
                        },
                        {
                            lable: 'Xã Nhữ Hán',
                            value: '02527',
                        },
                        {
                            lable: 'Xã Nhữ Khê',
                            value: '02530',
                        },
                        {
                            lable: 'Xã Phú Thịnh',
                            value: '02482',
                        },
                        {
                            lable: 'Xã Phúc Ninh',
                            value: '02452',
                        },
                        {
                            lable: 'Xã Quí Quân',
                            value: '02431',
                        },
                        {
                            lable: 'Xã Thái Bình',
                            value: '02494',
                        },
                        {
                            lable: 'Xã Tiến Bộ',
                            value: '02500',
                        },
                        {
                            lable: 'Xã Trung Minh',
                            value: '02440',
                        },
                        {
                            lable: 'Xã Trung Môn',
                            value: '02488',
                        },
                        {
                            lable: 'Xã Trung Sơn',
                            value: '02458',
                        },
                        {
                            lable: 'Xã Trung Trực',
                            value: '02446',
                        },
                        {
                            lable: 'Xã Tân Long',
                            value: '02470',
                        },
                        {
                            lable: 'Xã Tân Tiến',
                            value: '02461',
                        },
                        {
                            lable: 'Xã Tứ Quận',
                            value: '02464',
                        },
                        {
                            lable: 'Xã Xuân Vân',
                            value: '02449',
                        },
                        {
                            lable: 'Xã Đạo Viện',
                            value: '02467',
                        },
                        {
                            lable: 'Xã Đội Bình',
                            value: '02533',
                        },
                    ],
                },
                {
                    lable: 'Huyện Sơn Dương',
                    value: '076',
                    wards: [
                        {
                            lable: 'Thị trấn Sơn Dương',
                            value: '02536',
                        },
                        {
                            lable: 'Xã Bình Yên',
                            value: '02554',
                        },
                        {
                            lable: 'Xã Chi Thiết',
                            value: '02599',
                        },
                        {
                            lable: 'Xã Cấp Tiến',
                            value: '02563',
                        },
                        {
                            lable: 'Xã Hào Phú',
                            value: '02623',
                        },
                        {
                            lable: 'Xã Hồng Lạc',
                            value: '02608',
                        },
                        {
                            lable: 'Xã Hợp Hòa',
                            value: '02578',
                        },
                        {
                            lable: 'Xã Hợp Thành',
                            value: '02566',
                        },
                        {
                            lable: 'Xã Kháng Nhật',
                            value: '02575',
                        },
                        {
                            lable: 'Xã Lương Thiện',
                            value: '02557',
                        },
                        {
                            lable: 'Xã Minh Thanh',
                            value: '02542',
                        },
                        {
                            lable: 'Xã Ninh Lai',
                            value: '02614',
                        },
                        {
                            lable: 'Xã Phú Lương',
                            value: '02611',
                        },
                        {
                            lable: 'Xã Phúc Ứng',
                            value: '02569',
                        },
                        {
                            lable: 'Xã Quyết Thắng',
                            value: '02584',
                        },
                        {
                            lable: 'Xã Sơn Nam',
                            value: '02620',
                        },
                        {
                            lable: 'Xã Tam Đa',
                            value: '02626',
                        },
                        {
                            lable: 'Xã Thiện Kế',
                            value: '02605',
                        },
                        {
                            lable: 'Xã Thượng Ấm',
                            value: '02551',
                        },
                        {
                            lable: 'Xã Trung Yên',
                            value: '02539',
                        },
                        {
                            lable: 'Xã Trường Sinh',
                            value: '02632',
                        },
                        {
                            lable: 'Xã Tân Thanh',
                            value: '02590',
                        },
                        {
                            lable: 'Xã Tân Trào',
                            value: '02545',
                        },
                        {
                            lable: 'Xã Tú Thịnh',
                            value: '02560',
                        },
                        {
                            lable: 'Xã Vân Sơn',
                            value: '02593',
                        },
                        {
                            lable: 'Xã Văn Phú',
                            value: '02596',
                        },
                        {
                            lable: 'Xã Vĩnh Lợi',
                            value: '02548',
                        },
                        {
                            lable: 'Xã Đông Lợi',
                            value: '02602',
                        },
                        {
                            lable: 'Xã Đông Thọ',
                            value: '02572',
                        },
                        {
                            lable: 'Xã Đại Phú',
                            value: '02617',
                        },
                        {
                            lable: 'Xã Đồng Quý',
                            value: '02587',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Vĩnh Long',
            value: '86',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Vĩnh Long',
                    value: '855',
                    wards: [
                        {
                            lable: 'Phường 1',
                            value: '29548',
                        },
                        {
                            lable: 'Phường 2',
                            value: '29551',
                        },
                        {
                            lable: 'Phường 3',
                            value: '29557',
                        },
                        {
                            lable: 'Phường 4',
                            value: '29554',
                        },
                        {
                            lable: 'Phường 5',
                            value: '29545',
                        },
                        {
                            lable: 'Phường 8',
                            value: '29560',
                        },
                        {
                            lable: 'Phường 9',
                            value: '29542',
                        },
                        {
                            lable: 'Phường Trường An',
                            value: '29572',
                        },
                        {
                            lable: 'Phường Tân Hòa',
                            value: '29566',
                        },
                        {
                            lable: 'Phường Tân Hội',
                            value: '29569',
                        },
                        {
                            lable: 'Phường Tân Ngãi',
                            value: '29563',
                        },
                    ],
                },
                {
                    lable: 'Huyện Long Hồ',
                    value: '857',
                    wards: [
                        {
                            lable: 'Thị trấn Long Hồ',
                            value: '29575',
                        },
                        {
                            lable: 'Xã An Bình',
                            value: '29587',
                        },
                        {
                            lable: 'Xã Bình Hòa Phước',
                            value: '29581',
                        },
                        {
                            lable: 'Xã Hòa Ninh',
                            value: '29584',
                        },
                        {
                            lable: 'Xã Hòa Phú',
                            value: '29617',
                        },
                        {
                            lable: 'Xã Long An',
                            value: '29608',
                        },
                        {
                            lable: 'Xã Long Phước',
                            value: '29599',
                        },
                        {
                            lable: 'Xã Lộc Hòa',
                            value: '29605',
                        },
                        {
                            lable: 'Xã Phú Quới',
                            value: '29611',
                        },
                        {
                            lable: 'Xã Phú Đức',
                            value: '29602',
                        },
                        {
                            lable: 'Xã Phước Hậu',
                            value: '29596',
                        },
                        {
                            lable: 'Xã Thanh Đức',
                            value: '29590',
                        },
                        {
                            lable: 'Xã Thạnh Quới',
                            value: '29614',
                        },
                        {
                            lable: 'Xã Tân Hạnh',
                            value: '29593',
                        },
                        {
                            lable: 'Xã Đồng Phú',
                            value: '29578',
                        },
                    ],
                },
                {
                    lable: 'Huyện Mang Thít',
                    value: '858',
                    wards: [
                        {
                            lable: 'Thị trấn Cái Nhum',
                            value: '29641',
                        },
                        {
                            lable: 'Xã An Phước',
                            value: '29629',
                        },
                        {
                            lable: 'Xã Bình Phước',
                            value: '29644',
                        },
                        {
                            lable: 'Xã Chánh An',
                            value: '29647',
                        },
                        {
                            lable: 'Xã Hòa Tịnh',
                            value: '29638',
                        },
                        {
                            lable: 'Xã Long Mỹ',
                            value: '29635',
                        },
                        {
                            lable: 'Xã Mỹ An',
                            value: '29623',
                        },
                        {
                            lable: 'Xã Mỹ Phước',
                            value: '29626',
                        },
                        {
                            lable: 'Xã Nhơn Phú',
                            value: '29632',
                        },
                        {
                            lable: 'Xã Tân An Hội',
                            value: '29650',
                        },
                        {
                            lable: 'Xã Tân Long',
                            value: '29653',
                        },
                        {
                            lable: 'Xã Tân Long Hội',
                            value: '29656',
                        },
                    ],
                },
                {
                    lable: 'Huyện  Vũng Liêm',
                    value: '859',
                    wards: [
                        {
                            lable: 'Thị trấn Vũng Liêm',
                            value: '29659',
                        },
                        {
                            lable: 'Xã Hiếu Nghĩa',
                            value: '29716',
                        },
                        {
                            lable: 'Xã Hiếu Nhơn',
                            value: '29710',
                        },
                        {
                            lable: 'Xã Hiếu Phụng',
                            value: '29686',
                        },
                        {
                            lable: 'Xã Hiếu Thuận',
                            value: '29701',
                        },
                        {
                            lable: 'Xã Hiếu Thành',
                            value: '29713',
                        },
                        {
                            lable: 'Xã Quới An',
                            value: '29668',
                        },
                        {
                            lable: 'Xã Quới Thiện',
                            value: '29665',
                        },
                        {
                            lable: 'Xã Thanh Bình',
                            value: '29677',
                        },
                        {
                            lable: 'Xã Trung An',
                            value: '29707',
                        },
                        {
                            lable: 'Xã Trung Chánh',
                            value: '29671',
                        },
                        {
                            lable: 'Xã Trung Hiếu',
                            value: '29695',
                        },
                        {
                            lable: 'Xã Trung Hiệp',
                            value: '29683',
                        },
                        {
                            lable: 'Xã Trung Nghĩa',
                            value: '29704',
                        },
                        {
                            lable: 'Xã Trung Ngãi',
                            value: '29698',
                        },
                        {
                            lable: 'Xã Trung Thành',
                            value: '29692',
                        },
                        {
                            lable: 'Xã Trung Thành Tây',
                            value: '29680',
                        },
                        {
                            lable: 'Xã Trung Thành Đông',
                            value: '29689',
                        },
                        {
                            lable: 'Xã Tân An Luông',
                            value: '29674',
                        },
                        {
                            lable: 'Xã Tân Quới Trung',
                            value: '29662',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tam Bình',
                    value: '860',
                    wards: [
                        {
                            lable: 'Thị trấn Tam Bình',
                            value: '29719',
                        },
                        {
                            lable: 'Xã Bình Ninh',
                            value: '29767',
                        },
                        {
                            lable: 'Xã Hoà Lộc',
                            value: '29734',
                        },
                        {
                            lable: 'Xã Hòa Hiệp',
                            value: '29743',
                        },
                        {
                            lable: 'Xã Hòa Thạnh',
                            value: '29731',
                        },
                        {
                            lable: 'Xã Hậu Lộc',
                            value: '29728',
                        },
                        {
                            lable: 'Xã Loan Mỹ',
                            value: '29761',
                        },
                        {
                            lable: 'Xã Long Phú',
                            value: '29752',
                        },
                        {
                            lable: 'Xã Mỹ Lộc',
                            value: '29746',
                        },
                        {
                            lable: 'Xã Mỹ Thạnh Trung',
                            value: '29755',
                        },
                        {
                            lable: 'Xã Ngãi Tứ',
                            value: '29764',
                        },
                        {
                            lable: 'Xã Phú Lộc',
                            value: '29737',
                        },
                        {
                            lable: 'Xã Phú Thịnh',
                            value: '29725',
                        },
                        {
                            lable: 'Xã Song Phú',
                            value: '29740',
                        },
                        {
                            lable: 'Xã Tân Lộc',
                            value: '29722',
                        },
                        {
                            lable: 'Xã Tân Phú',
                            value: '29749',
                        },
                        {
                            lable: 'Xã Tường Lộc',
                            value: '29758',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Bình Minh',
                    value: '861',
                    wards: [
                        {
                            lable: 'Phường Cái Vồn',
                            value: '29770',
                        },
                        {
                            lable: 'Phường Thành Phước',
                            value: '29771',
                        },
                        {
                            lable: 'Phường Đông Thuận',
                            value: '29813',
                        },
                        {
                            lable: 'Xã Mỹ Hòa',
                            value: '29815',
                        },
                        {
                            lable: 'Xã Thuận An',
                            value: '29806',
                        },
                        {
                            lable: 'Xã Đông Bình',
                            value: '29812',
                        },
                        {
                            lable: 'Xã Đông Thành',
                            value: '29818',
                        },
                        {
                            lable: 'Xã Đông Thạnh',
                            value: '29809',
                        },
                    ],
                },
                {
                    lable: 'Huyện Trà Ôn',
                    value: '862',
                    wards: [
                        {
                            lable: 'Thị trấn Trà Ôn',
                            value: '29821',
                        },
                        {
                            lable: 'Xã Hòa Bình',
                            value: '29830',
                        },
                        {
                            lable: 'Xã Hựu Thành',
                            value: '29842',
                        },
                        {
                            lable: 'Xã Lục Sỹ Thành',
                            value: '29857',
                        },
                        {
                            lable: 'Xã Nhơn Bình',
                            value: '29827',
                        },
                        {
                            lable: 'Xã Phú Thành',
                            value: '29851',
                        },
                        {
                            lable: 'Xã Thiện Mỹ',
                            value: '29854',
                        },
                        {
                            lable: 'Xã Thuận Thới',
                            value: '29848',
                        },
                        {
                            lable: 'Xã Thới Hòa',
                            value: '29833',
                        },
                        {
                            lable: 'Xã Trà Côn',
                            value: '29836',
                        },
                        {
                            lable: 'Xã Tân Mỹ',
                            value: '29839',
                        },
                        {
                            lable: 'Xã Tích Thiện',
                            value: '29860',
                        },
                        {
                            lable: 'Xã Vĩnh Xuân',
                            value: '29845',
                        },
                        {
                            lable: 'Xã Xuân Hiệp',
                            value: '29824',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bình Tân',
                    value: '863',
                    wards: [
                        {
                            lable: 'Thị trấn Tân Quới',
                            value: '29800',
                        },
                        {
                            lable: 'Xã Mỹ Thuận',
                            value: '29794',
                        },
                        {
                            lable: 'Xã Nguyễn Văn Thảnh',
                            value: '29788',
                        },
                        {
                            lable: 'Xã Thành Lợi',
                            value: '29791',
                        },
                        {
                            lable: 'Xã Thành Trung',
                            value: '29779',
                        },
                        {
                            lable: 'Xã Tân An Thạnh',
                            value: '29782',
                        },
                        {
                            lable: 'Xã Tân Bình',
                            value: '29797',
                        },
                        {
                            lable: 'Xã Tân Hưng',
                            value: '29773',
                        },
                        {
                            lable: 'Xã Tân Lược',
                            value: '29785',
                        },
                        {
                            lable: 'Xã Tân Thành',
                            value: '29776',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Vĩnh Phúc',
            value: '26',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Vĩnh Yên',
                    value: '243',
                    wards: [
                        {
                            lable: 'Phường Hội Hợp',
                            value: '08713',
                        },
                        {
                            lable: 'Phường Khai Quang',
                            value: '08728',
                        },
                        {
                            lable: 'Phường Liên Bảo',
                            value: '08710',
                        },
                        {
                            lable: 'Phường Ngô Quyền',
                            value: '08719',
                        },
                        {
                            lable: 'Phường Tích Sơn',
                            value: '08707',
                        },
                        {
                            lable: 'Phường Đống Đa',
                            value: '08716',
                        },
                        {
                            lable: 'Phường Đồng Tâm',
                            value: '08722',
                        },
                        {
                            lable: 'Xã Thanh Trù',
                            value: '08731',
                        },
                        {
                            lable: 'Xã Định Trung',
                            value: '08725',
                        },
                    ],
                },
                {
                    lable: 'Thành phố Phúc Yên',
                    value: '244',
                    wards: [
                        {
                            lable: 'Phường Hùng Vương',
                            value: '08737',
                        },
                        {
                            lable: 'Phường Nam Viêm',
                            value: '08755',
                        },
                        {
                            lable: 'Phường Phúc Thắng',
                            value: '08743',
                        },
                        {
                            lable: 'Phường Tiền Châu',
                            value: '08758',
                        },
                        {
                            lable: 'Phường Trưng Nhị',
                            value: '08740',
                        },
                        {
                            lable: 'Phường Trưng Trắc',
                            value: '08734',
                        },
                        {
                            lable: 'Phường Xuân Hoà',
                            value: '08746',
                        },
                        {
                            lable: 'Phường Đồng Xuân',
                            value: '08747',
                        },
                        {
                            lable: 'Xã Cao Minh',
                            value: '08752',
                        },
                        {
                            lable: 'Xã Ngọc Thanh',
                            value: '08749',
                        },
                    ],
                },
                {
                    lable: 'Huyện Lập Thạch',
                    value: '246',
                    wards: [
                        {
                            lable: 'Thị trấn Hoa Sơn',
                            value: '08789',
                        },
                        {
                            lable: 'Thị trấn Lập Thạch',
                            value: '08761',
                        },
                        {
                            lable: 'Xã Bàn Giản',
                            value: '08833',
                        },
                        {
                            lable: 'Xã Bắc Bình',
                            value: '08785',
                        },
                        {
                            lable: 'Xã Hợp Lý',
                            value: '08770',
                        },
                        {
                            lable: 'Xã Liên Hòa',
                            value: '08812',
                        },
                        {
                            lable: 'Xã Liễn Sơn',
                            value: '08791',
                        },
                        {
                            lable: 'Xã Ngọc Mỹ',
                            value: '08767',
                        },
                        {
                            lable: 'Xã Quang Sơn',
                            value: '08764',
                        },
                        {
                            lable: 'Xã Sơn Đông',
                            value: '08866',
                        },
                        {
                            lable: 'Xã Thái Hòa',
                            value: '08788',
                        },
                        {
                            lable: 'Xã Tiên Lữ',
                            value: '08842',
                        },
                        {
                            lable: 'Xã Triệu Đề',
                            value: '08863',
                        },
                        {
                            lable: 'Xã Tử Du',
                            value: '08815',
                        },
                        {
                            lable: 'Xã Vân Trục',
                            value: '08797',
                        },
                        {
                            lable: 'Xã Văn Quán',
                            value: '08845',
                        },
                        {
                            lable: 'Xã Xuân Hòa',
                            value: '08794',
                        },
                        {
                            lable: 'Xã Xuân Lôi',
                            value: '08836',
                        },
                        {
                            lable: 'Xã Đình Chu',
                            value: '08857',
                        },
                        {
                            lable: 'Xã Đồng Ích',
                            value: '08839',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tam Dương',
                    value: '247',
                    wards: [
                        {
                            lable: 'Thị trấn Hợp Hòa',
                            value: '08869',
                        },
                        {
                            lable: 'Xã An Hòa',
                            value: '08887',
                        },
                        {
                            lable: 'Xã Duy Phiên',
                            value: '08893',
                        },
                        {
                            lable: 'Xã Hoàng Hoa',
                            value: '08872',
                        },
                        {
                            lable: 'Xã Hoàng Lâu',
                            value: '08899',
                        },
                        {
                            lable: 'Xã Hoàng Đan',
                            value: '08896',
                        },
                        {
                            lable: 'Xã Hướng Đạo',
                            value: '08881',
                        },
                        {
                            lable: 'Xã Hợp Thịnh',
                            value: '08905',
                        },
                        {
                            lable: 'Xã Kim Long',
                            value: '08878',
                        },
                        {
                            lable: 'Xã Thanh Vân',
                            value: '08890',
                        },
                        {
                            lable: 'Xã Vân Hội',
                            value: '08902',
                        },
                        {
                            lable: 'Xã Đạo Tú',
                            value: '08884',
                        },
                        {
                            lable: 'Xã Đồng Tĩnh',
                            value: '08875',
                        },
                    ],
                },
                {
                    lable: 'Huyện Tam Đảo',
                    value: '248',
                    wards: [
                        {
                            lable: 'Thị trấn Hợp Châu',
                            value: '08911',
                        },
                        {
                            lable: 'Thị trấn Tam Đảo',
                            value: '08908',
                        },
                        {
                            lable: 'Thị trấn Đại Đình',
                            value: '08923',
                        },
                        {
                            lable: 'Xã Bồ Lý',
                            value: '08920',
                        },
                        {
                            lable: 'Xã Hồ Sơn',
                            value: '08929',
                        },
                        {
                            lable: 'Xã Minh Quang',
                            value: '08932',
                        },
                        {
                            lable: 'Xã Tam Quan',
                            value: '08926',
                        },
                        {
                            lable: 'Xã Yên Dương',
                            value: '08917',
                        },
                        {
                            lable: 'Xã Đạo Trù',
                            value: '08914',
                        },
                    ],
                },
                {
                    lable: 'Huyện Bình Xuyên',
                    value: '249',
                    wards: [
                        {
                            lable: 'Thị trấn Bá Hiến',
                            value: '08944',
                        },
                        {
                            lable: 'Thị trấn Gia Khánh',
                            value: '08936',
                        },
                        {
                            lable: 'Thị trấn Hương Canh',
                            value: '08935',
                        },
                        {
                            lable: 'Thị trấn Thanh Lãng',
                            value: '08968',
                        },
                        {
                            lable: 'Thị trấn Đạo Đức',
                            value: '08962',
                        },
                        {
                            lable: 'Xã Hương Sơn',
                            value: '08950',
                        },
                        {
                            lable: 'Xã Phú Xuân',
                            value: '08971',
                        },
                        {
                            lable: 'Xã Quất Lưu',
                            value: '08956',
                        },
                        {
                            lable: 'Xã Sơn Lôi',
                            value: '08959',
                        },
                        {
                            lable: 'Xã Tam Hợp',
                            value: '08953',
                        },
                        {
                            lable: 'Xã Thiện Kế',
                            value: '08947',
                        },
                        {
                            lable: 'Xã Trung Mỹ',
                            value: '08938',
                        },
                        {
                            lable: 'Xã Tân Phong',
                            value: '08965',
                        },
                    ],
                },
                {
                    lable: 'Huyện Yên Lạc',
                    value: '251',
                    wards: [
                        {
                            lable: 'Thị trấn Yên Lạc',
                            value: '09025',
                        },
                        {
                            lable: 'Xã Bình Định',
                            value: '09034',
                        },
                        {
                            lable: 'Xã Hồng Châu',
                            value: '09070',
                        },
                        {
                            lable: 'Xã Hồng Phương',
                            value: '09058',
                        },
                        {
                            lable: 'Xã Liên Châu',
                            value: '09064',
                        },
                        {
                            lable: 'Xã Nguyệt Đức',
                            value: '09052',
                        },
                        {
                            lable: 'Xã Tam Hồng',
                            value: '09043',
                        },
                        {
                            lable: 'Xã Trung Hà',
                            value: '09073',
                        },
                        {
                            lable: 'Xã Trung Kiên',
                            value: '09061',
                        },
                        {
                            lable: 'Xã Trung Nguyên',
                            value: '09037',
                        },
                        {
                            lable: 'Xã Tề Lỗ',
                            value: '09040',
                        },
                        {
                            lable: 'Xã Văn Tiến',
                            value: '09049',
                        },
                        {
                            lable: 'Xã Yên Phương',
                            value: '09055',
                        },
                        {
                            lable: 'Xã Yên Đồng',
                            value: '09046',
                        },
                        {
                            lable: 'Xã Đại Tự',
                            value: '09067',
                        },
                        {
                            lable: 'Xã Đồng Cương',
                            value: '09028',
                        },
                        {
                            lable: 'Xã Đồng Văn',
                            value: '09031',
                        },
                    ],
                },
                {
                    lable: 'Huyện Vĩnh Tường',
                    value: '252',
                    wards: [
                        {
                            lable: 'Thị Trấn Thổ Tang',
                            value: '09112',
                        },
                        {
                            lable: 'Thị trấn Tứ Trưng',
                            value: '09145',
                        },
                        {
                            lable: 'Thị trấn Vĩnh Tường',
                            value: '09076',
                        },
                        {
                            lable: 'Xã An Tường',
                            value: '09151',
                        },
                        {
                            lable: 'Xã Bình Dương',
                            value: '09118',
                        },
                        {
                            lable: 'Xã Bồ Sao',
                            value: '09097',
                        },
                        {
                            lable: 'Xã Cao Đại',
                            value: '09109',
                        },
                        {
                            lable: 'Xã Chấn Hưng',
                            value: '09085',
                        },
                        {
                            lable: 'Xã Kim Xá',
                            value: '09079',
                        },
                        {
                            lable: 'Xã Lý Nhân',
                            value: '09133',
                        },
                        {
                            lable: 'Xã Lũng Hoà',
                            value: '09106',
                        },
                        {
                            lable: 'Xã Nghĩa Hưng',
                            value: '09088',
                        },
                        {
                            lable: 'Xã Ngũ Kiên',
                            value: '09148',
                        },
                        {
                            lable: 'Xã Phú Đa',
                            value: '09157',
                        },
                        {
                            lable: 'Xã Tam Phúc',
                            value: '09142',
                        },
                        {
                            lable: 'Xã Thượng Trưng',
                            value: '09127',
                        },
                        {
                            lable: 'Xã Tuân Chính',
                            value: '09136',
                        },
                        {
                            lable: 'Xã Tân Phú',
                            value: '09124',
                        },
                        {
                            lable: 'Xã Tân Tiến',
                            value: '09103',
                        },
                        {
                            lable: 'Xã Việt Xuân',
                            value: '09094',
                        },
                        {
                            lable: 'Xã Vân Xuân',
                            value: '09139',
                        },
                        {
                            lable: 'Xã Vĩnh Ninh',
                            value: '09160',
                        },
                        {
                            lable: 'Xã Vĩnh Sơn',
                            value: '09115',
                        },
                        {
                            lable: 'Xã Vĩnh Thịnh',
                            value: '09154',
                        },
                        {
                            lable: 'Xã Vũ Di',
                            value: '09130',
                        },
                        {
                            lable: 'Xã Yên Bình',
                            value: '09082',
                        },
                        {
                            lable: 'Xã Yên Lập',
                            value: '09091',
                        },
                        {
                            lable: 'Xã Đại Đồng',
                            value: '09100',
                        },
                    ],
                },
                {
                    lable: 'Huyện Sông Lô',
                    value: '253',
                    wards: [
                        {
                            lable: 'Thị trấn Tam Sơn',
                            value: '08824',
                        },
                        {
                            lable: 'Xã Bạch Lưu',
                            value: '08779',
                        },
                        {
                            lable: 'Xã Cao Phong',
                            value: '08860',
                        },
                        {
                            lable: 'Xã Hải Lựu',
                            value: '08782',
                        },
                        {
                            lable: 'Xã Lãng Công',
                            value: '08773',
                        },
                        {
                            lable: 'Xã Nhân Đạo',
                            value: '08803',
                        },
                        {
                            lable: 'Xã Như Thụy',
                            value: '08827',
                        },
                        {
                            lable: 'Xã Nhạo Sơn',
                            value: '08821',
                        },
                        {
                            lable: 'Xã Phương Khoan',
                            value: '08809',
                        },
                        {
                            lable: 'Xã Quang Yên',
                            value: '08776',
                        },
                        {
                            lable: 'Xã Tân Lập',
                            value: '08818',
                        },
                        {
                            lable: 'Xã Tứ Yên',
                            value: '08851',
                        },
                        {
                            lable: 'Xã Yên Thạch',
                            value: '08830',
                        },
                        {
                            lable: 'Xã Đôn Nhân',
                            value: '08806',
                        },
                        {
                            lable: 'Xã Đồng Quế',
                            value: '08800',
                        },
                        {
                            lable: 'Xã Đồng Thịnh',
                            value: '08848',
                        },
                        {
                            lable: 'Xã Đức Bác',
                            value: '08854',
                        },
                    ],
                },
            ],
        },
        {
            lable: 'Tỉnh Yên Bái',
            value: '15',
            type: 'Tỉnh',
            districts: [
                {
                    lable: 'Thành phố Yên Bái',
                    value: '132',
                    wards: [
                        {
                            lable: 'Phường Hồng Hà',
                            value: '04267',
                        },
                        {
                            lable: 'Phường Hợp Minh',
                            value: '04546',
                        },
                        {
                            lable: 'Phường Minh Tân',
                            value: '04255',
                        },
                        {
                            lable: 'Phường Nam Cường',
                            value: '04273',
                        },
                        {
                            lable: 'Phường Nguyễn Phúc',
                            value: '04264',
                        },
                        {
                            lable: 'Phường Nguyễn Thái Học',
                            value: '04258',
                        },
                        {
                            lable: 'Phường Yên Ninh',
                            value: '04252',
                        },
                        {
                            lable: 'Phường Yên Thịnh',
                            value: '04249',
                        },
                        {
                            lable: 'Phường Đồng Tâm',
                            value: '04261',
                        },
                        {
                            lable: 'Xã Giới Phiên',
                            value: '04543',
                        },
                        {
                            lable: 'Xã Minh Bảo',
                            value: '04270',
                        },
                        {
                            lable: 'Xã Tuy Lộc',
                            value: '04276',
                        },
                        {
                            lable: 'Xã Tân Thịnh',
                            value: '04279',
                        },
                        {
                            lable: 'Xã Văn Phú',
                            value: '04558',
                        },
                        {
                            lable: 'Xã Âu Lâu',
                            value: '04540',
                        },
                    ],
                },
                {
                    lable: 'Thị xã Nghĩa Lộ',
                    value: '133',
                    wards: [
                        {
                            lable: 'Phường Cầu Thia',
                            value: '04291',
                        },
                        {
                            lable: 'Phường Pú Trạng',
                            value: '04282',
                        },
                        {
                            lable: 'Phường Trung Tâm',
                            value: '04285',
                        },
                        {
                            lable: 'Phường Tân An',
                            value: '04288',
                        },
                        {
                            lable: 'Xã Hạnh Sơn',
                            value: '04678',
                        },
                        {
                            lable: 'Xã Nghĩa An',
                            value: '04300',
                        },
                        {
                            lable: 'Xã Nghĩa Lộ',
                            value: '04624',
                        },
                        {
                            lable: 'Xã Nghĩa Lợi',
                            value: '04294',
                        },
                        {
                            lable: 'Xã Nghĩa Phúc',
                            value: '04297',
                        },
                        {
                            lable: 'Xã Phù Nham',
                            value: '04663',
                        },
                        {
                            lable: 'Xã Phúc Sơn',
                            value: '04681',
                        },
                        {
                            lable: 'Xã Sơn A',
                            value: '04660',
                        },
                        {
                            lable: 'Xã Thanh Lương',
                            value: '04675',
                        },
                        {
                            lable: 'Xã Thạch Lương',
                            value: '04684',
                        },
                    ],
                },
                {
                    lable: 'Huyện Lục Yên',
                    value: '135',
                    wards: [
                        {
                            lable: 'Thị trấn Yên Thế',
                            value: '04303',
                        },
                        {
                            lable: 'Xã An Lạc',
                            value: '04327',
                        },
                        {
                            lable: 'Xã An Phú',
                            value: '04369',
                        },
                        {
                            lable: 'Xã Khai Trung',
                            value: '04321',
                        },
                        {
                            lable: 'Xã Khánh Hoà',
                            value: '04342',
                        },
                        {
                            lable: 'Xã Khánh Thiện',
                            value: '04312',
                        },
                        {
                            lable: 'Xã Liễu Đô',
                            value: '04348',
                        },
                        {
                            lable: 'Xã Lâm Thượng',
                            value: '04309',
                        },
                        {
                            lable: 'Xã Mai Sơn',
                            value: '04318',
                        },
                        {
                            lable: 'Xã Minh Chuẩn',
                            value: '04315',
                        },
                        {
                            lable: 'Xã Minh Tiến',
                            value: '04357',
                        },
                        {
                            lable: 'Xã Minh Xuân',
                            value: '04330',
                        },
                        {
                            lable: 'Xã Mường Lai',
                            value: '04324',
                        },
                        {
                            lable: 'Xã Phan Thanh',
                            value: '04366',
                        },
                        {
                            lable: 'Xã Phúc Lợi',
                            value: '04363',
                        },
                        {
                            lable: 'Xã Trung Tâm',
                            value: '04372',
                        },
                        {
                            lable: 'Xã Trúc Lâu',
                            value: '04360',
                        },
                        {
                            lable: 'Xã Tân Lĩnh',
                            value: '04336',
                        },
                        {
                            lable: 'Xã Tân Lập',
                            value: '04354',
                        },
                        {
                            lable: 'Xã Tân Phượng',
                            value: '04306',
                        },
                        {
                            lable: 'Xã Tô Mậu',
                            value: '04333',
                        },
                        {
                            lable: 'Xã Vĩnh Lạc',
                            value: '04345',
                        },
                        {
                            lable: 'Xã Yên Thắng',
                            value: '04339',
                        },
                        {
                            lable: 'Xã Động Quan',
                            value: '04351',
                        },
                    ],
                },
                {
                    lable: 'Huyện Văn Yên',
                    value: '136',
                    wards: [
                        {
                            lable: 'Thị trấn Mậu A',
                            value: '04375',
                        },
                        {
                            lable: 'Xã An Bình',
                            value: '04390',
                        },
                        {
                            lable: 'Xã An Thịnh',
                            value: '04417',
                        },
                        {
                            lable: 'Xã Châu Quế Hạ',
                            value: '04387',
                        },
                        {
                            lable: 'Xã Châu Quế Thượng',
                            value: '04384',
                        },
                        {
                            lable: 'Xã Lang Thíp',
                            value: '04378',
                        },
                        {
                            lable: 'Xã Lâm Giang',
                            value: '04381',
                        },
                        {
                            lable: 'Xã Mậu Đông',
                            value: '04405',
                        },
                        {
                            lable: 'Xã Mỏ Vàng',
                            value: '04450',
                        },
                        {
                            lable: 'Xã Ngòi A',
                            value: '04408',
                        },
                        {
                            lable: 'Xã Nà Hẩu',
                            value: '04453',
                        },
                        {
                            lable: 'Xã Phong Dụ Hạ',
                            value: '04402',
                        },
                        {
                            lable: 'Xã Phong Dụ Thượng',
                            value: '04423',
                        },
                        {
                            lable: 'Xã Quang Minh',
                            value: '04393',
                        },
                        {
                            lable: 'Xã Tân Hợp',
                            value: '04414',
                        },
                        {
                            lable: 'Xã Viễn Sơn',
                            value: '04447',
                        },
                        {
                            lable: 'Xã Xuân Tầm',
                            value: '04411',
                        },
                        {
                            lable: 'Xã Xuân Ái',
                            value: '04441',
                        },
                        {
                            lable: 'Xã Yên Hợp',
                            value: '04426',
                        },
                        {
                            lable: 'Xã Yên Phú',
                            value: '04438',
                        },
                        {
                            lable: 'Xã Yên Thái',
                            value: '04420',
                        },
                        {
                            lable: 'Xã Đông An',
                            value: '04396',
                        },
                        {
                            lable: 'Xã Đông Cuông',
                            value: '04399',
                        },
                        {
                            lable: 'Xã Đại Phác',
                            value: '04435',
                        },
                        {
                            lable: 'Xã Đại Sơn',
                            value: '04429',
                        },
                    ],
                },
                {
                    lable: 'Huyện Mù Căng Chải',
                    value: '137',
                    wards: [
                        {
                            lable: 'Thị trấn Mù Căng Chải',
                            value: '04456',
                        },
                        {
                            lable: 'Xã Cao Phạ',
                            value: '04480',
                        },
                        {
                            lable: 'Xã Chế Cu Nha',
                            value: '04471',
                        },
                        {
                            lable: 'Xã Chế Tạo',
                            value: '04489',
                        },
                        {
                            lable: 'Xã Dế Su Phình',
                            value: '04486',
                        },
                        {
                            lable: 'Xã Hồ Bốn',
                            value: '04459',
                        },
                        {
                            lable: 'Xã Khao Mang',
                            value: '04465',
                        },
                        {
                            lable: 'Xã Kim Nọi',
                            value: '04477',
                        },
                        {
                            lable: 'Xã La Pán Tẩn',
                            value: '04483',
                        },
                        {
                            lable: 'Xã Lao Chải',
                            value: '04474',
                        },
                        {
                            lable: 'Xã Mồ Dề',
                            value: '04468',
                        },
                        {
                            lable: 'Xã Nậm Có',
                            value: '04462',
                        },
                        {
                            lable: 'Xã Nậm Khắt',
                            value: '04495',
                        },
                        {
                            lable: 'Xã Púng Luông',
                            value: '04492',
                        },
                    ],
                },
                {
                    lable: 'Huyện Trấn Yên',
                    value: '138',
                    wards: [
                        {
                            lable: 'Thị trấn Cổ Phúc',
                            value: '04498',
                        },
                        {
                            lable: 'Xã Báo Đáp',
                            value: '04504',
                        },
                        {
                            lable: 'Xã Bảo Hưng',
                            value: '04561',
                        },
                        {
                            lable: 'Xã Cường Thịnh',
                            value: '04522',
                        },
                        {
                            lable: 'Xã Hòa Cuông',
                            value: '04513',
                        },
                        {
                            lable: 'Xã Hưng Khánh',
                            value: '04576',
                        },
                        {
                            lable: 'Xã Hưng Thịnh',
                            value: '04573',
                        },
                        {
                            lable: 'Xã Hồng Ca',
                            value: '04570',
                        },
                        {
                            lable: 'Xã Kiên Thành',
                            value: '04525',
                        },
                        {
                            lable: 'Xã Lương Thịnh',
                            value: '04537',
                        },
                        {
                            lable: 'Xã Minh Quán',
                            value: '04516',
                        },
                        {
                            lable: 'Xã Minh Quân',
                            value: '04567',
                        },
                        {
                            lable: 'Xã Nga Quán',
                            value: '04528',
                        },
                        {
                            lable: 'Xã Quy Mông',
                            value: '04519',
                        },
                        {
                            lable: 'Xã Tân Đồng',
                            value: '04501',
                        },
                        {
                            lable: 'Xã Việt Cường',
                            value: '04564',
                        },
                        {
                            lable: 'Xã Việt Hồng',
                            value: '04579',
                        },
                        {
                            lable: 'Xã Việt Thành',
                            value: '04510',
                        },
                        {
                            lable: 'Xã Vân Hội',
                            value: '04582',
                        },
                        {
                            lable: 'Xã Y Can',
                            value: '04531',
                        },
                        {
                            lable: 'Xã Đào Thịnh',
                            value: '04507',
                        },
                    ],
                },
                {
                    lable: 'Huyện Trạm Tấu',
                    value: '139',
                    wards: [
                        {
                            lable: 'Thị trấn Trạm Tấu',
                            value: '04585',
                        },
                        {
                            lable: 'Xã Bản Công',
                            value: '04612',
                        },
                        {
                            lable: 'Xã Bản Mù',
                            value: '04615',
                        },
                        {
                            lable: 'Xã Hát Lìu',
                            value: '04618',
                        },
                        {
                            lable: 'Xã Làng Nhì',
                            value: '04609',
                        },
                        {
                            lable: 'Xã Phình Hồ',
                            value: '04597',
                        },
                        {
                            lable: 'Xã Pá Hu',
                            value: '04606',
                        },
                        {
                            lable: 'Xã Pá Lau',
                            value: '04591',
                        },
                        {
                            lable: 'Xã Trạm Tấu',
                            value: '04600',
                        },
                        {
                            lable: 'Xã Tà Si Láng',
                            value: '04603',
                        },
                        {
                            lable: 'Xã Túc Đán',
                            value: '04588',
                        },
                        {
                            lable: 'Xã Xà Hồ',
                            value: '04594',
                        },
                    ],
                },
                {
                    lable: 'Huyện Văn Chấn',
                    value: '140',
                    wards: [
                        {
                            lable: 'Thị trấn NT Liên Sơn',
                            value: '04621',
                        },
                        {
                            lable: 'Thị trấn NT Trần Phú',
                            value: '04627',
                        },
                        {
                            lable: 'Thị trấn Sơn Thịnh',
                            value: '04672',
                        },
                        {
                            lable: 'Xã An Lương',
                            value: '04645',
                        },
                        {
                            lable: 'Xã Bình Thuận',
                            value: '04702',
                        },
                        {
                            lable: 'Xã Chấn Thịnh',
                            value: '04699',
                        },
                        {
                            lable: 'Xã Cát Thịnh',
                            value: '04693',
                        },
                        {
                            lable: 'Xã Gia Hội',
                            value: '04636',
                        },
                        {
                            lable: 'Xã Minh An',
                            value: '04708',
                        },
                        {
                            lable: 'Xã Nghĩa Sơn',
                            value: '04666',
                        },
                        {
                            lable: 'Xã Nghĩa Tâm',
                            value: '04711',
                        },
                        {
                            lable: 'Xã Nậm Búng',
                            value: '04633',
                        },
                        {
                            lable: 'Xã Nậm Lành',
                            value: '04648',
                        },
                        {
                            lable: 'Xã Nậm Mười',
                            value: '04642',
                        },
                        {
                            lable: 'Xã Suối Bu',
                            value: '04669',
                        },
                        {
                            lable: 'Xã Suối Giàng',
                            value: '04657',
                        },
                        {
                            lable: 'Xã Suối Quyền',
                            value: '04654',
                        },
                        {
                            lable: 'Xã Sùng Đô',
                            value: '04639',
                        },
                        {
                            lable: 'Xã Sơn Lương',
                            value: '04651',
                        },
                        {
                            lable: 'Xã Thượng Bằng La',
                            value: '04705',
                        },
                        {
                            lable: 'Xã Tân Thịnh',
                            value: '04696',
                        },
                        {
                            lable: 'Xã Tú Lệ',
                            value: '04630',
                        },
                        {
                            lable: 'Xã Đại Lịch',
                            value: '04687',
                        },
                        {
                            lable: 'Xã Đồng Khê',
                            value: '04690',
                        },
                    ],
                },
                {
                    lable: 'Huyện Yên Bình',
                    value: '141',
                    wards: [
                        {
                            lable: 'Thị trấn Thác Bà',
                            value: '04717',
                        },
                        {
                            lable: 'Thị trấn Yên Bình',
                            value: '04714',
                        },
                        {
                            lable: 'Xã Bạch Hà',
                            value: '04762',
                        },
                        {
                            lable: 'Xã Bảo Ái',
                            value: '04738',
                        },
                        {
                            lable: 'Xã Cảm Nhân',
                            value: '04726',
                        },
                        {
                            lable: 'Xã Cảm Ân',
                            value: '04750',
                        },
                        {
                            lable: 'Xã Hán Đà',
                            value: '04780',
                        },
                        {
                            lable: 'Xã Mông Sơn',
                            value: '04747',
                        },
                        {
                            lable: 'Xã Mỹ Gia',
                            value: '04741',
                        },
                        {
                            lable: 'Xã Ngọc Chấn',
                            value: '04729',
                        },
                        {
                            lable: 'Xã Phú Thịnh',
                            value: '04783',
                        },
                        {
                            lable: 'Xã Phúc An',
                            value: '04759',
                        },
                        {
                            lable: 'Xã Phúc Ninh',
                            value: '04735',
                        },
                        {
                            lable: 'Xã Thịnh Hưng',
                            value: '04777',
                        },
                        {
                            lable: 'Xã Tân Hương',
                            value: '04756',
                        },
                        {
                            lable: 'Xã Tân Nguyên',
                            value: '04732',
                        },
                        {
                            lable: 'Xã Vĩnh Kiên',
                            value: '04771',
                        },
                        {
                            lable: 'Xã Vũ Linh',
                            value: '04765',
                        },
                        {
                            lable: 'Xã Xuân Lai',
                            value: '04744',
                        },
                        {
                            lable: 'Xã Xuân Long',
                            value: '04720',
                        },
                        {
                            lable: 'Xã Yên Bình',
                            value: '04774',
                        },
                        {
                            lable: 'Xã Yên Thành',
                            value: '04753',
                        },
                        {
                            lable: 'Xã Đại Minh',
                            value: '04786',
                        },
                        {
                            lable: 'Xã Đại Đồng',
                            value: '04768',
                        },
                    ],
                },
            ],
        },
    ];


    today = moment(new Date()).format('YYYY-MM-DD');
    loading = false;

    cgXetNghiemKhangDinh = '';
    ketQuaXNSangLoc = '';
    cgPrep = '';
    isEmpty = false;
    countRiskFactor = 0;
    countARV = 0;
    countPrep = 0;
    countBTBC = 0;
     countFavoriteService = 0;
     countTestHiv = 0;
    validateTestHiv = false;
    validateTreatmentArv = false;
    validatePrep = false;
    isCreateNewRiskFactor = false;
    isCreateNewBTBC = false;
    isCreateNewFavoriteService = false;
    isCreateNewHivTest = false;
    isCreateNewTreatmentArv = false;
    isCreateNewTreatmentPrep = false;
    updateRelateHistoryRiskFactor = false;
    updateRelateHistoryBTBC = false;
    updateRelateHistoryFavoriteService = false;
    updateRelateHistoryHIVTest = false;
    updateRelateHistoryTreatmentArv = false;
    updateRelateHistoryTreatmentPrep = false;
    isLoadingRiskFactor = false;
    isLoadingBTBC = false;
    isLoadingFavoriteService = false;
    isLoadingTestHiv = false;
    isLoadingPrep = false;
    isLoadingArv = false;
    isLoadingSubmit = false;
    tinhDuAn = '';
    ngayBDTiepCan = null;


    constructor(
        private global: GlobalFunc,
        public atrCtrl: AlertController,
        private route: ActivatedRoute,
        private customerService: CustomerService,
        private store: Store<any>,
        private modalCtrl: ModalController
    ) {
    }

    ionViewWillEnter() {
        this.getDetailSupport();
        this.fakeUser = this.fakeUser1;
        if (this.gender === 'Nam'){
            this.fakeUser = this.fakeUser1;
        }
        else{
            this.fakeUser = this.fakeUser2;
        }
        this.getAdress();
    }

    ngOnInit() {
        this.deliveryFacility = [
            {
                lable: "Bệnh viện 30-4",
                value: "79011"
            },
        ]
        this.fakeUser = this.fakeUser1;
        if (this.customerId) {
            this.getDetailSupport();
        }

    }

    getDetailSupport() {
        this.loading = true;
        this.customerService.getDetailUsersSendKPElog(this.customerId).then((res:any) => {
            this.loading = false;
            this.countRiskFactor = res.ttNangCao.yeuToNguyCo.length;
            this.countBTBC = res.ttNangCao.soBanTinhBanChich.length;
            this.countFavoriteService = res.ttNangCao.dichVuYeuThich.length;
            this.countARV = res.ttNangCao.tinhTrangDieuTriARV.length;
            this.countPrep = res.ttNangCao.tinhTrangPrep.length;
            this.countTestHiv = res.ttNangCao.tienSuXetNghiemHIV.length;
            this.tinhTPNoiSong = res.ttNangCao?.noiThuongTru?.tinhTP.lable?
                res.ttNangCao?.noiThuongTru?.tinhTP
                : '';
            this.quanHuyenNoiSong = res.ttNangCao?.noiThuongTru?.quanHuyen?.lable
                ? res.ttNangCao?.noiThuongTru?.quanHuyen
                : '';
            this.phuongXaNoiSong = res.ttNangCao?.noiThuongTru?.phuongXa?.lable
                ? res.ttNangCao?.noiThuongTru?.phuongXa
                : '';
            this.soNhaDuong = res.ttNangCao?.noiLamViecHienTai?.soNhaDuong
                ? res.ttNangCao?.noiLamViecHienTai?.soNhaDuong
                : '';
            this.thoiDiemBatDauSongTaiNoiOHienTai = res.ttNangCao?.thoiDiemBatDauSongTaiNoiOHienTai
                ? moment(res.ttNangCao?.thoiDiemBatDauSongTaiNoiOHienTai).format(
                    'YYYY-MM-DD'
                )
                : '';
            this.duKienOChoDen = res.ttNangCao?.duKienOChoDen
                ? moment(res.ttNangCao?.duKienOChoDen).format(
                    'YYYY-MM-DD'
                )
                : '';
            this.thuNhap = res.ttNangCao?.thuNhap;
            this.tinhTP =  res.ttNangCao?.noiLamViecHienTai?.tinhTP?.lable
                ?  res.ttNangCao?.noiLamViecHienTai?.tinhTP
                : '';
            this.quanHuyen =  res.ttNangCao?.noiLamViecHienTai?.quanHuyen?.lable
                ?  res.ttNangCao?.noiLamViecHienTai?.quanHuyen
                : '';
            this.phuongXa =  res.ttNangCao?.noiLamViecHienTai?.phuongXa?.lable
                ?  res.ttNangCao?.noiLamViecHienTai?.phuongXa
                : '';
            this.soNhaDuongNoiSong = res.ttNangCao?.noiThuongTru?.soNhaDuong
                ? res.ttNangCao?.noiThuongTru?.soNhaDuong
                : '';
            this._idRiskFactor =res.ttNangCao?.yeuToNguyCo[res.ttNangCao?.yeuToNguyCo.length-1]?._id;
            this._idBTBC = res.ttNangCao?.soBanTinhBanChich[res.ttNangCao?.soBanTinhBanChich.length-1]?._id;
            this._idFavoriteService = res.ttNangCao?.dichVuYeuThich[res.ttNangCao?.dichVuYeuThich.length-1]?._id;
            this._idTestHiv = res.ttNangCao?.tienSuXetNghiemHIV[res.ttNangCao?.tienSuXetNghiemHIV.length-1]?._id;
            this._idTreatmentArv = res.ttNangCao?.tinhTrangDieuTriARV[res.ttNangCao?.tinhTrangDieuTriARV.length-1]?._id;
            this._idTreatPrep = res.ttNangCao?.tinhTrangPrep[res.ttNangCao?.tinhTrangPrep.length-1]?._id;
            this.nguyCoNhiemHIV =  res?.ttNangCao?.yeuToNguyCo[res?.ttNangCao?.yeuToNguyCo.length-1]?.nguyCoNhiemHIV?.value?
            res?.ttNangCao?.yeuToNguyCo[res?.ttNangCao?.yeuToNguyCo.length-1]?.nguyCoNhiemHIV?.value: '';
            this.nguyCoNhiemHIVKhac = res?.ttNangCao?.yeuToNguyCo[res?.ttNangCao?.yeuToNguyCo.length-1]?.nguyCoNhiemHIV?.khac?
                res?.ttNangCao?.yeuToNguyCo[res?.ttNangCao?.yeuToNguyCo.length-1]?.nguyCoNhiemHIV?.khac: '';
            this.ketQuanTimHieuNguyCo = res?.ttNangCao?.yeuToNguyCo[res?.ttNangCao?.yeuToNguyCo.length-1]?.ketQuanTimHieuNguyCo;
            this.soBanTinhBanChich =res?.ttNangCao?.soBanTinhBanChich[res?.ttNangCao?.soBanTinhBanChich.length-1]?.soBanTinhBanChich;
            this.soBanTinhBanChichThuongXuyen =res?.ttNangCao?.soBanTinhBanChich[res?.ttNangCao?.soBanTinhBanChich.length-1]?.soBanTinhBanChichThuongXuyen;
            this.soBanTinhBanChichBatChot =res?.ttNangCao?.soBanTinhBanChich[res?.ttNangCao?.soBanTinhBanChich.length-1]?.soBanTinhBanChichBatChot;
            this.hinhThucXNHIVYeuThich =res?.ttNangCao?.dichVuYeuThich[res?.ttNangCao?.dichVuYeuThich.length-1]?.hinhThucXNHIVYeuThich;
            this.lyDoYeuThichXNHIV =res?.ttNangCao?.dichVuYeuThich[res?.ttNangCao?.dichVuYeuThich.length-1]?.lyDoYeuThichXNHIV;
            this.hinhThucDieuTriHIVYeuThich =res?.ttNangCao?.dichVuYeuThich[res?.ttNangCao?.dichVuYeuThich.length-1]?.hinhThucDieuTriHIVYeuThich;
            this.lyDoYeuThichDieuTriHIV =res?.ttNangCao?.dichVuYeuThich[res?.ttNangCao?.dichVuYeuThich.length-1]?.lyDoYeuThichDieuTriHIV;
            this.hinhThucPrepYeuThich =res?.ttNangCao?.dichVuYeuThich[res?.ttNangCao?.dichVuYeuThich.length-1]?.hinhThucPrepYeuThich;
            this.dieuQuanTrongTiepCanDichVuDuPhongVaDieuTri =res?.ttNangCao?.dichVuYeuThich[res?.ttNangCao?.dichVuYeuThich.length-1]?.dieuQuanTrongTiepCanDichVuDuPhongVaDieuTri;
            this.tienSuXetNghiemHIV =res?.ttNangCao?.tienSuXetNghiemHIV[res?.ttNangCao?.tienSuXetNghiemHIV.length-1]?.tienSuXetNghiemHIV;
            this.tinhTrangNhiemHIV =res?.ttNangCao?.tienSuXetNghiemHIV[res?.ttNangCao?.tienSuXetNghiemHIV.length-1]?.tinhTrangNhiemHIV;
            this.ngayXNHIV =this.formatGetDate(res?.ttNangCao?.tienSuXetNghiemHIV[res?.ttNangCao?.tienSuXetNghiemHIV.length-1]?.ngayXNHIV)
            this.tinhTrangDieuTriARV =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.tinhTrangDieuTriARV;
            this.dieuTriARVQuaBHYT =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.dieuTriARVQuaBHYT;
            this.taiLuongVirus =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.taiLuongVirus;

            this.tuanThuARV2 =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.tuanThuARV2?.value;
            this.soLieuDaQuenARV2 =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.tuanThuARV2?.soLieuDaQuen;
            this.soNgayNgungARV2 =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.tuanThuARV2?.soNgayNgung;
            this.lyDoQuenARV2 =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.tuanThuARV2?.lyDoQuen;
            this.khacARV2 =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.tuanThuARV2?.khac;

            this.tuanThuARV3 =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.tuanThuARV3?.value;
            this.soLieuDaQuenARV3 =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.tuanThuARV3?.soLieuDaQuen;
            this.soNgayNgungARV3 =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.tuanThuARV3?.soNgayNgung;
            this.lyDoQuenARV3 =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.tuanThuARV3?.lyDoQuen;
            this.khacARV3 =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.tuanThuARV3?.khac;

            this.tuanThuARV1 =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.tuanThuARV1?.value;
            this.soLieuDaQuenARV1 =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.tuanThuARV1?.soLieuDaQuen;
            this.soNgayNgungARV1 =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.tuanThuARV1?.soNgayNgung;
            this.lyDoQuenARV1 =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.tuanThuARV1?.lyDoQuen;
            this.khacARV1 =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.tuanThuARV1?.khac;

            this.tinhTrangPrep =res?.ttNangCao?.tinhTrangPrep[res?.ttNangCao?.tinhTrangPrep.length-1]?.tinhTrangPrep;
            this.tuanThuPrep1 =res?.ttNangCao?.tinhTrangPrep[res?.ttNangCao?.tinhTrangPrep.length-1]?.tuanThuPrep1?.value;
            this.soLieuDaQuenPrep1 =res?.ttNangCao?.tinhTrangPrep[res?.ttNangCao?.tinhTrangPrep.length-1]?.tuanThuPrep1?.soLieuDaQuen;
            this.soNgayNgungPrep1 =res?.ttNangCao?.tinhTrangPrep[res?.ttNangCao?.tinhTrangPrep.length-1]?.tuanThuPrep1?.soNgayNgung;
            this.lyDoQuenPrep1 =res?.ttNangCao?.tinhTrangPrep[res?.ttNangCao?.tinhTrangPrep.length-1]?.tuanThuPrep1?.lyDoQuen;
            this.khacPrep1 =res?.ttNangCao?.tinhTrangPrep[res?.ttNangCao?.tinhTrangPrep.length-1]?.tuanThuPrep1?.khac;


            this.tuanThuPrep2 =res?.ttNangCao?.tinhTrangPrep[res?.ttNangCao?.tinhTrangPrep.length-1]?.tuanThuPrep2?.value;
            this.soLieuDaQuenPrep2 =res?.ttNangCao?.tinhTrangPrep[res?.ttNangCao?.tinhTrangPrep.length-1]?.tuanThuPrep2?.soLieuDaQuen;
            this.soNgayNgungPrep2 =res?.ttNangCao?.tinhTrangPrep[res?.ttNangCao?.tinhTrangPrep.length-1]?.tuanThuPrep2?.soNgayNgung;
            this.lyDoQuenPrep2 =res?.ttNangCao?.tinhTrangPrep[res?.ttNangCao?.tinhTrangPrep.length-1]?.tuanThuPrep2?.lyDoQuen;
            this.khacPrep2 =res?.ttNangCao?.tinhTrangPrep[res?.ttNangCao?.tinhTrangPrep.length-1]?.tuanThuPrep2?.khac;


            this.tuanThuPrep3 =res?.ttNangCao?.tinhTrangPrep[res?.ttNangCao?.tinhTrangPrep.length-1]?.tuanThuPrep3?.value;
            this.soLieuDaQuenPrep3 =res?.ttNangCao?.tinhTrangPrep[res?.ttNangCao?.tinhTrangPrep.length-1]?.tuanThuPrep3?.soLieuDaQuen;
            this.soNgayNgungPrep3 =res?.ttNangCao?.tinhTrangPrep[res?.ttNangCao?.tinhTrangPrep.length-1]?.tuanThuPrep3?.soNgayNgung;
            this.lyDoQuenPrep3 =res?.ttNangCao?.tinhTrangPrep[res?.ttNangCao?.tinhTrangPrep.length-1]?.tuanThuPrep3?.lyDoQuen;
            this.khacPrep3 =res?.ttNangCao?.tinhTrangPrep[res?.ttNangCao?.tinhTrangPrep.length-1]?.tuanThuPrep3?.khac;
            this.loaiHinhCongViec =res?.ttNangCao?.loaiHinhCongViec;
            this.songTrongNhaCuaAi =res?.ttNangCao?.songTrongNhaCuaAi;
            this.songVoiAi =res?.ttNangCao?.songVoiAi;
            this.lyDoYeuThichPrep = res?.ttNangCao?.dichVuYeuThich[res?.ttNangCao?.dichVuYeuThich.length-1]?.lyDoYeuThichPrep;
            this.tinhDuAn = res?.ttChung?.tinhDuAn ? res?.ttChung?.tinhDuAn : '';
            this.ngayBDTiepCan = res?.ttCoBan?.ngayBDTiepCan
                ? moment(res?.ttCoBan?.ngayBDTiepCan).format(
                    'YYYY-MM-DD'
                )
                : moment(this.today).format(
                    'YYYY-MM-DD'
                );
            this.getAdress();
            this.store.select('customer').subscribe((s) => {
                this.loading = false;
                this.cgDieuTriARV = s?.detailUserKPELog?.ttNangCao?.tinhTrangDieuTriARV[s?.detailUserKPELog?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.cgDieuTriARV;
                this.cgXetNghiemKhangDinh = s?.detailUserKPELog?.ttDichVu?.xetNghiemSangLocHIV[s?.detailUserKPELog?.ttDichVu?.xetNghiemSangLocHIV.length-1]?.cgXetNghiemKhangDinh;
                this.ketQuaXNSangLoc = s?.detailUserKPELog?.ttDichVu?.xetNghiemSangLocHIV[s?.detailUserKPELog?.ttDichVu?.xetNghiemSangLocHIV.length-1]?.ketQuaXNSangLoc;
                this.cgPrep = s?.detailUserKPELog?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep[s?.detailUserKPELog?.ttDichVu?.tuVanVaHoTroDuPhongHIVTruocPhoiNhiemPrep.length-1]?.cgPrep;
            });

        });

    }
    getAdress(){
        this.cities = this.location.map((item) => {
            return item;
        });
        this.districts = this.location
            .filter((item) => item.lable === this.tinhTP?.lable)
            .map((value) => {
                return value.districts.map((item1) => item1);
            });

        const filterDistrict= this.location.filter((item) => item.lable === this.tinhTP?.lable);
        this.wards = filterDistrict[0]?.districts.filter((item2) =>  item2.lable === this.quanHuyen?.lable)
            .map((value) => {
                return value.wards.map((item1) => item1);
            });
        //Living
        this.citiesLiving = this.location.map((item) => {
            return item;
        });
        this.districtsLiving = this.location
            .filter((item) => item.lable === this.tinhTPNoiSong?.lable)
            .map((value) => {
                return  value.districts.map((item1) => item1);
            });

        const filterDistrictLiving= this.location.filter((item) => item.lable === this.tinhTPNoiSong?.lable);
        this.wardsLiving = filterDistrictLiving[0]?.districts.filter((item2) =>  item2.lable === this.quanHuyenNoiSong?.lable)
            .map((value) => {
                return value.wards.map((item1) => item1);
            });
        this.location
            .filter((item) => item.lable === 'Thành phố Hồ Chí Minh')
            .map((value) => {
                return (this.valueTinhTP = value.value);
            });
    }
    goToPage(str) {
        this.global.goToPage(str);
    }

    handleDropdown() {
        this.isShow = !this.isShow;
    }

    handleDropdownAlive() {
        this.isShowAlive = !this.isShowAlive;
    }

    handleDropdownRisk() {
        this.isShowRisk = !this.isShowRisk;
    }

    handleDropdownActivate() {
        this.isShowActivate = !this.isShowActivate;
    }

    handleDropdownHarmful() {
        this.isShowHarmful = !this.isShowHarmful;
    }

    handleDropdownHIVStatus() {
        this.isShowHIV = !this.isShowHIV;
    }

    handleDropdownRiskViolence() {
        this.isShowViolence = !this.isShowViolence;
    }
    handleDropdownNumberOfPartner(){
        this.isShowNumberOfPartner = !this.isShowNumberOfPartner;
    }
    handleDropdownTestHistory(){
        this.isShowTestHistory = ! this.isShowTestHistory;
    }
    handleDropdownRiskHIV() {
        this.isShowRiskHIV = !this.isShowRiskHIV;
    }
    handleDropdownRiskHIVNeedPrep() {
        this.isShowRiskHIVNeedPrep = !this.isShowRiskHIVNeedPrep;
    }
    handleDropdownRiskHIVNeedPep() {
        this.isShowRiskHIVNeedPep = !this.isShowRiskHIVNeedPep;
    }


    handleDropdownDifficultHIV() {
        this.isShowDifficultHIV = !this.isShowDifficultHIV;
    }

    handleDropdownComplianceARV() {
        this.isShowComplianceARV = !this.isShowComplianceARV;
    }

    handleDropdownReproduction() {
        this.isShowReproduction = !this.isShowReproduction;
    }

    handleDropdownLike() {
        this.isShowLike = !this.isShowLike;
    }

    handleDropdownHeathStatus() {
        this.isShowHealthStatus = !this.isShowHealthStatus;
    }

    handleDropdownTreatmentARV() {
        this.isShowTreatmentARV = !this.isShowTreatmentARV;
    }

    handleDropdownTreatmentPrep() {
        this.isShowTreatmentPrep = !this.isShowTreatmentPrep;
    }
    handleDropdownStatusTreatmentPrep(){
        this.isShowStatusPrep = !this.isShowStatusPrep;
    }
    handleChangeTTArv1(e){
        this.tuanThuARV1 = e.target.value;
        if(this.tuanThuARV1==='Không quên liều'){
            this.soLieuDaQuenARV1 = '';
            this.soNgayNgungARV1 = '';
            this.lyDoQuenARV1 = '';
            this.khacARV1 = '';
        }
        if(this.tuanThuARV1!=='Ngưng uống thuốc'){
            this.soNgayNgungARV1 = '';
        }
        if(this.tuanThuARV1!=='Có quên liều'){
            this.soLieuDaQuenARV1 = '';
        }

    }
    handleChangeLyDoQuenARV1(e){
        this.lyDoQuenARV1 = e.target.value
        if(this.lyDoQuenARV1 !== 'Khác'){
            this.khacARV1 = '';
        }
    }
    handleChangeTTArv2(e){
        this.tuanThuARV2 = e.target.value;
        if(this.tuanThuARV2==='Không quên liều'){
            this.soLieuDaQuenARV2 = '';
            this.soNgayNgungARV2 = '';
            this.lyDoQuenARV2 = '';
            this.khacARV2 = '';
        }
        if(this.tuanThuARV2!=='Ngưng uống thuốc'){
            this.soNgayNgungARV2 = '';
        }
        if(this.tuanThuARV2!=='Có quên liều'){
            this.soLieuDaQuenARV2 = '';
        }

    }
    handleChangeLyDoQuenARV2(e){
        this.lyDoQuenARV2 = e.target.value
        if(this.lyDoQuenARV2 !== 'Khác'){
            this.khacARV2 = '';
        }
    }
    handleChangeTTArv3(e){
        this.tuanThuARV3 = e.target.value;
        if(this.tuanThuARV3==='Không quên liều'){
            this.soLieuDaQuenARV3 = '';
            this.soNgayNgungARV3 = '';
            this.lyDoQuenARV3 = '';
            this.khacARV3 = '';
        }
        if(this.tuanThuARV3!=='Ngưng uống thuốc'){
            this.soNgayNgungARV3 = '';
        }
        if(this.tuanThuARV3!=='Có quên liều'){
            this.soLieuDaQuenARV3 = '';
        }

    }
    handleChangeLyDoQuenARV3(e){
        this.lyDoQuenARV3 = e.target.value
        if(this.lyDoQuenARV3 !== 'Khác'){
            this.khacARV3 = '';
        }
    }
    handleChangeTTPrep1(e){
        this.tuanThuPrep1 = e.target.value;
        if(this.tuanThuPrep1==='Không quên liều'){
            this.soLieuDaQuenPrep1 = '';
            this.soNgayNgungPrep1 = '';
            this.lyDoQuenPrep1 = '';
            this.khacPrep1 = '';
        }
        if(this.tuanThuPrep1!=='Ngưng uống thuốc'){
            this.soNgayNgungPrep1 = '';
        }
        if(this.tuanThuPrep1!=='Có quên liều'){
            this.soLieuDaQuenPrep1 = '';
        }

    }
    handleChangeLyDoQuenPrep1(e){
        this.lyDoQuenPrep1 = e.target.value
        if(this.lyDoQuenPrep1 !== 'Khác'){
            this.khacPrep1 = '';
        }
    }
    handleChangeTTPrep2(e){
        this.tuanThuPrep2 = e.target.value;
        if(this.tuanThuPrep2==='Không quên liều'){
            this.soLieuDaQuenPrep2 = '';
            this.soNgayNgungPrep2 = '';
            this.lyDoQuenPrep2 = '';
            this.khacPrep2 = '';
        }
        if(this.tuanThuPrep2!=='Ngưng uống thuốc'){
            this.soNgayNgungPrep2 = '';
        }
        if(this.tuanThuPrep2!=='Có quên liều'){
            this.soLieuDaQuenPrep2 = '';
        }

    }
    handleChangeLyDoQuenPrep2(e){
        this.lyDoQuenPrep2 = e.target.value
        if(this.lyDoQuenPrep2 !== 'Khác'){
            this.khacPrep2 = '';
        }
    }
    handleChangeTTPrep3(e){
        this.tuanThuPrep3 = e.target.value;
        if(this.tuanThuPrep3==='Không quên liều'){
            this.soLieuDaQuenPrep3 = '';
            this.soNgayNgungPrep3 = '';
            this.lyDoQuenPrep3 = '';
            this.khacPrep3 = '';
        }
        if(this.tuanThuPrep3!=='Ngưng uống thuốc'){
            this.soNgayNgungPrep2 = '';
        }
        if(this.tuanThuPrep3!=='Có quên liều'){
            this.soLieuDaQuenPrep3 = '';
        }

    }
    handleChangeLyDoQuenPrep3(e){
        this.lyDoQuenPrep3 = e.target.value
        if(this.lyDoQuenPrep3 !== 'Khác'){
            this.khacPrep3 = '';
        }
    }

    async handleSaveAll() {
        const alertConfirm = this.atrCtrl.create({
            message:
                'Thông tin vừa được lưu vào bộ nhớ tạm thời, bạn có thể tiếp tục cập nhật hoặc quay lại sau!',
        });
        (await alertConfirm).present();
    }

    async onComplete() {
        this.isLoadingSubmit = true;
        const payload = {
            userId: this.customerId,
            thoiDiemBatDauSongTaiNoiOHienTai: new Date(this.thoiDiemBatDauSongTaiNoiOHienTai).getTime(),
            duKienOChoDen: new Date(this.duKienOChoDen).getTime(),
            loaiHinhCongViec: this.loaiHinhCongViec,
            thuNhap: this.thuNhap,
            songTrongNhaCuaAi: this.songTrongNhaCuaAi,
            songVoiAi: this.songVoiAi,
            coKhoKhanVeThayDoiHanhViCanHoTro: this.coKhoKhanVeThayDoiHanhViCanHoTro,
            khoKhanCuThe: this.khoKhanCuThe,
            coHanhViCoHaiChoSucKhoeCanHoTro: this.coHanhViCoHaiChoSucKhoeCanHoTro,
            hanhViCoHaiCuThe: this.hanhViCoHaiCuThe,
            coNguyCoBaoHanh: this.coNguyCoBaoHanh,
            nguyCoBaoHanhCuThe: this.nguyCoBaoHanhCuThe,
            coNguyCoLayNhiemHIV: this.coNguyCoLayNhiemHIV,
            nguyCoLayNhiemHIVCuThe: this.nguyCoLayNhiemHIVCuThe,
            coKhoKhanDieuTriARV: this.coKhoKhanDieuTriARV,
            khoKhanDieuTriARVCuThe: this.khoKhanDieuTriARVCuThe,
            coKhoKhanTuanThuDieuTri: this.coKhoKhanTuanThuDieuTri,
            khoKhanTuanThuDieuTri: this.khoKhanTuanThuDieuTri,
            coKhoKhanPrep: this.coKhoKhanPrep,
            khoKhanPrep: this.khoKhanPrep,
            coKhoKhanSKTD: this.coKhoKhanSKTD,
            khoKhanSKTD: this.khoKhanSKTD,
            noiThuongTru: {
                soNhaDuong: this.soNhaDuongNoiSong,
                phuongXa: {
                    lable: this.phuongXaNoiSong.lable,
                    value:  this.phuongXaNoiSong.value,
                },
                quanHuyen: {
                    lable: this.quanHuyenNoiSong.lable,
                    value: this.quanHuyenNoiSong.value,
                },
                tinhTP: {
                    lable: this.tinhTPNoiSong.lable,
                    value: this.tinhTPNoiSong.value,
                },
            },
            noiLamViecHienTai: {
                soNhaDuong: this.soNhaDuong,
                phuongXa: {
                    lable: this.phuongXa.lable,
                    value: this.phuongXa.value,
                },
                quanHuyen: {
                    lable: this.quanHuyen.lable,
                    value:  this.quanHuyen.value,
                },
                tinhTP: {
                    lable: this.tinhTP.lable,
                    value: this.tinhTP.value,
                },
            }
        };
        this.isEmpty = true;
        this.openTabHasError();

        if (!this.tinhTPNoiSong || (this.tienSuXetNghiemHIV=== 'Có xét nghiệm'&& this.tinhTrangNhiemHIV==='Âm tính' && !this.tinhTrangPrep)
            ||(this.tienSuXetNghiemHIV === 'Có xét nghiệm' && this.tinhTrangNhiemHIV === 'Dương tính' &&  !this.tinhTrangDieuTriARV)) {
            this.validateTreatmentArv = true;
            this.validatePrep = true;
            this.isLoadingSubmit = false;
            let alertConfirm = this.atrCtrl.create({
                cssClass: 'my-custom-class',
                message: 'Vui lòng nhập đầy đủ thông tin hợp lệ!',
            });
            (await alertConfirm).dismiss();

            (await alertConfirm).present();
        } else {
            if (localStorage.getItem('isNew') === '1') {
                this.customerService
                    .updateInformationAdvanceKpelog(payload)
                    .then(async (res: any) => {
                        this.isLoadingSubmit = false;
                        if (res) {
                            const alertConfirm = this.atrCtrl.create({
                                message: 'Thông tin vừa được lưu vào hệ thống!',
                                cssClass: 'my-custom-class-success',
                            });
                            setTimeout(async () => {
                                (await alertConfirm).dismiss();
                                if (this.customerId) {
                                    this.goToPage('tabs/list-survey/service-information/' + this.customerId);
                                } else {
                                    this.goToPage('tabs/list-survey/service-information/' + res.global_id);
                                }
                            }, 1000);
                            (await alertConfirm).present();
                        }
                    })
                    .catch();
            } else if (localStorage.getItem('isNew') === '0') {

                if(moment(this.ngayBDTiepCan).isAfter(this.duKienOChoDen)){
                    let alertConfirm = this.atrCtrl.create({
                        cssClass: 'my-custom-class',
                        message: 'Ngày dự kiến tiếp tục sống tại nơi ở hiện tại đang trước ngày bắt đầu tiếp cận!',
                        buttons: [
                            { text: "",
                                role: "cancel",
                                handler: () => {
                                    this.payloadForUpdate = payload;
                                    this.customerService
                                        .updateInformationAdvanceKpelog(this.payloadForUpdate)
                                        .then(async (res: any) => {
                                            this.isLoadingSubmit = false;
                                            setTimeout(async () => {
                                                this.customerService
                                                    .checkMissingData(this.customerId)
                                                    .then(async (res: any) => {
                                                        if (res) {
                                                            res.map(value => {
                                                                    if (value.reason) {
                                                                        this.missingFieldArr = value.reason;
                                                                    } else {
                                                                        this.missingFieldArr = [];
                                                                    }
                                                                }
                                                            );
                                                        }
                                                        if (((this.cgXetNghiemKhangDinh === 'Có' && this.ketQuaXNSangLoc === 'Có phản ứng') || this.cgPrep === 'Có') && this.tinhDuAn ==='Thành phố Hồ Chí Minh' ) {
                                                            this.isOpenModalMenu = !this.isOpenModalMenu;
                                                        } else {
                                                            const alertConfirm = this.atrCtrl.create({
                                                                message: 'Thông tin vừa được lưu vào hệ thống!',
                                                                cssClass: 'my-custom-class-success',
                                                            });
                                                            setTimeout(async () => {
                                                                (await alertConfirm).dismiss();
                                                                if (this.customerId) {
                                                                    this.goToPage('tabs/list-survey/service-information/' + this.customerId);
                                                                }
                                                            }, 1000);
                                                            (await alertConfirm).present();
                                                        }
                                                    })
                                                    .catch();

                                            }, 1000);

                                        })
                                        .catch();
                                },
                            },]
                    });
                    (await alertConfirm).dismiss();
                    (await alertConfirm).present();
                }
                else{
                    this.payloadForUpdate = payload;
                    this.customerService
                        .updateInformationAdvanceKpelog(this.payloadForUpdate)
                        .then(async (res: any) => {
                            this.isLoadingSubmit = false;
                            setTimeout(async () => {
                                this.customerService
                                    .checkMissingData(this.customerId)
                                    .then(async (res: any) => {
                                        if (res) {
                                            res.map(value => {
                                                    if (value.reason) {
                                                        this.missingFieldArr = value.reason;
                                                    } else {
                                                        this.missingFieldArr = [];
                                                    }
                                                }
                                            );
                                        }
                                        if (((this.cgXetNghiemKhangDinh === 'Có' && this.ketQuaXNSangLoc === 'Có phản ứng') || this.cgPrep === 'Có') && this.tinhDuAn ==='Thành phố Hồ Chí Minh' ) {
                                            this.isOpenModalMenu = !this.isOpenModalMenu;
                                        } else {
                                            const alertConfirm = this.atrCtrl.create({
                                                message: 'Thông tin vừa được lưu vào hệ thống!',
                                                cssClass: 'my-custom-class-success',
                                            });
                                            setTimeout(async () => {
                                                (await alertConfirm).dismiss();
                                                if (this.customerId) {
                                                    this.goToPage('tabs/list-survey/service-information/' + this.customerId);
                                                }
                                            }, 1000);
                                            (await alertConfirm).present();
                                        }
                                    })
                                    .catch();

                            }, 1000);

                        })
                        .catch();
                }



            }
        }


    }
    async onCompleteRiskFactor() {
        this.isLoadingRiskFactor = true;
        this.isCreateNewRiskFactor = true;
        const payload = {
            userId: this.customerId,
            nguyCoNhiemHIV: {
                value: this.nguyCoNhiemHIV,
                khac: this.nguyCoNhiemHIVKhac,
            },
            ketQuanTimHieuNguyCo: this.ketQuanTimHieuNguyCo,
        }
        let clonePayload = JSON.parse(JSON.stringify(payload));
        delete clonePayload.userId;
        const isEmpty = (element, index, array) => {
            return element === '' || (element.value?.length === 0 && element?.khac === '')
        };
        if (Object.values(clonePayload).every(isEmpty)) {
            this.isLoadingRiskFactor = false;

        } else {

                this.customerService.saveHistoryRiskFactor(payload).then(async res => {
                    this.isLoadingRiskFactor = false;
                    const alertConfirm = this.atrCtrl.create({
                        message: 'Thông tin vừa được lưu vào hệ thống!',
                        cssClass: 'my-custom-class-success',
                    });
                    (await alertConfirm).dismiss();
                    (await alertConfirm).present();
                    this.isCreateNewRiskFactor = false;
                    this.customerService.getDetailUsersSendKPElog(this.customerId).then((res: any) => {
                        this.countRiskFactor = res.ttNangCao.yeuToNguyCo.length;
                        this._idRiskFactor = res.ttNangCao?.yeuToNguyCo[res.ttNangCao?.yeuToNguyCo.length - 1]?._id;
                        this.updateRelateHistoryRiskFactor = false;
                    })
                }).catch(() => this.isLoadingRiskFactor = false)


        }
    }
    updateHistoryRiskFactor(){
        this.isLoadingRiskFactor = true;
        const payload = {
            userId: this.customerId,
            nguyCoNhiemHIV: {
                value: this.nguyCoNhiemHIV,
                khac : this.nguyCoNhiemHIVKhac,
            },
            ketQuanTimHieuNguyCo: this.ketQuanTimHieuNguyCo,
        }
        this.customerService.updateHistoryRiskFactor(this._idRiskFactor,payload).then(async res => {
            this.isLoadingRiskFactor = false;
            const alertConfirm = this.atrCtrl.create({
                message: 'Thông tin vừa được lưu vào hệ thống!',
                cssClass: 'my-custom-class-success',
            });
            (await alertConfirm).dismiss();
            (await alertConfirm).present();
        }).catch()
    }
    async onCompleteBTBC() {
        this.isLoadingBTBC = true;
        const payload = {
            userId: this.customerId,
            soBanTinhBanChich: this.soBanTinhBanChich,
            soBanTinhBanChichThuongXuyen: this.soBanTinhBanChichThuongXuyen,
            soBanTinhBanChichBatChot: this.soBanTinhBanChichBatChot,
        }
        let clonePayload = JSON.parse(JSON.stringify(payload));
        delete clonePayload.userId
        const isEmpty = (element, index, array) => {
            return element === null
        };
        if (Object.values(clonePayload).every(isEmpty)) {
            this.isLoadingBTBC = false;

        } else {
            if (this.soBanTinhBanChichBatChot > (this.soBanTinhBanChich - this.soBanTinhBanChichThuongXuyen) ) {
                // this.validateXNSL = true;
                this.isLoadingBTBC = false;
                const alertConfirm = this.atrCtrl.create({
                    cssClass: 'my-custom-class',
                    message: 'Vui lòng nhập đầy đủ thông tin hợp lệ!',
                });
                (await alertConfirm).dismiss();

                (await alertConfirm).present();
            } else {
                this.customerService.saveHistoryBTBC(payload).then(async res => {
                    this.isLoadingBTBC = false;
                    const alertConfirm = this.atrCtrl.create({
                        message: 'Thông tin vừa được lưu vào hệ thống!',
                        cssClass: 'my-custom-class-success',
                    });
                    (await alertConfirm).dismiss();
                    (await alertConfirm).present();
                    this.isCreateNewBTBC = false;
                    this.customerService.getDetailUsersSendKPElog(this.customerId).then((res: any) => {
                        this.countBTBC = res.ttNangCao.soBanTinhBanChich.length;
                        this._idBTBC = res.ttNangCao?.soBanTinhBanChich[res.ttNangCao?.soBanTinhBanChich.length-1]?._id;
                        this.updateRelateHistoryBTBC = false;

                    })
                }).catch(() =>this.isLoadingBTBC = false)
            }

        }
    }
    async updateHistoryBTBC() {
        this.isLoadingBTBC = true;
        const payload = {
            userId: this.customerId,
            soBanTinhBanChich: this.soBanTinhBanChich,
            soBanTinhBanChichThuongXuyen: this.soBanTinhBanChichThuongXuyen,
            soBanTinhBanChichBatChot: this.soBanTinhBanChichBatChot,
        }
        if (this.soBanTinhBanChichBatChot > (this.soBanTinhBanChich - this.soBanTinhBanChichThuongXuyen) ) {
            // this.validateXNSL = true;
            this.isLoadingBTBC = false;
            const alertConfirm = this.atrCtrl.create({
                cssClass: 'my-custom-class',
                message: 'Vui lòng nhập đầy đủ thông tin hợp lệ!',
            });
            (await alertConfirm).dismiss();

            (await alertConfirm).present();
        } else {
            this.customerService.updateHistoryBTBC(this._idBTBC, payload).then(async res => {
                this.isLoadingBTBC = false;
                const alertConfirm = this.atrCtrl.create({
                    message: 'Thông tin vừa được lưu vào hệ thống!',
                    cssClass: 'my-custom-class-success',
                });
                (await alertConfirm).dismiss();
                (await alertConfirm).present();
            }).catch()
        }


    }
    async onCompleteFavoriteService() {
        this.isLoadingFavoriteService = true;
        const payload = {
            userId: this.customerId,
            hinhThucXNHIVYeuThich: this.hinhThucXNHIVYeuThich,
            lyDoYeuThichXNHIV: this.lyDoYeuThichXNHIV,
            hinhThucDieuTriHIVYeuThich: this.hinhThucDieuTriHIVYeuThich,
            lyDoYeuThichDieuTriHIV: this.lyDoYeuThichDieuTriHIV,
            hinhThucPrepYeuThich: this.hinhThucPrepYeuThich,
            lyDoYeuThichPrep: this.lyDoYeuThichPrep,
            dieuQuanTrongTiepCanDichVuDuPhongVaDieuTri: this.dieuQuanTrongTiepCanDichVuDuPhongVaDieuTri,
        }
        let clonePayload = JSON.parse(JSON.stringify(payload));
        delete clonePayload.userId;
        const isEmpty = (element, index, array) => {
            return element === ''
        };
        if (Object.values(clonePayload).every(isEmpty)) {
            this.isLoadingFavoriteService = false;
        } else {
            if ((this.tienSuXetNghiemHIV === 'Có xét nghiệm' && !this.tinhTrangNhiemHIV)
                || (this.tienSuXetNghiemHIV === 'Có xét nghiệm' && !this.ngayXNHIV)) {
                // this.validateXNSL = true;
                this.isLoadingFavoriteService = false
                const alertConfirm = this.atrCtrl.create({
                    cssClass: 'my-custom-class',
                    message: 'Vui lòng nhập đầy đủ thông tin hợp lệ!',
                });
                (await alertConfirm).dismiss();

                (await alertConfirm).present();
            } else {
                this.customerService.saveHistoryFavoriteService(payload).then(async res => {
                    this.isLoadingFavoriteService = false
                    const alertConfirm = this.atrCtrl.create({
                        message: 'Thông tin vừa được lưu vào hệ thống!',
                        cssClass: 'my-custom-class-success',
                    });
                    (await alertConfirm).dismiss();
                    (await alertConfirm).present();
                    this.isCreateNewFavoriteService = false;
                    this.customerService.getDetailUsersSendKPElog(this.customerId).then((res: any) => {
                        this.countFavoriteService = res.ttNangCao.dichVuYeuThich.length;
                        this._idFavoriteService = res.ttNangCao?.dichVuYeuThich[res.ttNangCao?.dichVuYeuThich.length-1]?._id;
                        this.updateRelateHistoryFavoriteService = false;
                    })
                }).catch(() => this.isLoadingFavoriteService = false)
            }

        }
    }
    updateHistoryFavoriteService(){
        this.isLoadingFavoriteService = true;
        const payload = {
            userId: this.customerId,
            hinhThucXNHIVYeuThich: this.hinhThucXNHIVYeuThich,
            lyDoYeuThichXNHIV: this.lyDoYeuThichXNHIV,
            hinhThucDieuTriHIVYeuThich: this.hinhThucDieuTriHIVYeuThich,
            lyDoYeuThichDieuTriHIV: this.lyDoYeuThichDieuTriHIV,
            hinhThucPrepYeuThich: this.hinhThucPrepYeuThich,
            lyDoYeuThichPrep: this.lyDoYeuThichPrep,
            dieuQuanTrongTiepCanDichVuDuPhongVaDieuTri: this.dieuQuanTrongTiepCanDichVuDuPhongVaDieuTri,
        }

            this.customerService.updateHistoryFavoriteService(this._idFavoriteService,payload).then(async res => {
                this.isLoadingFavoriteService = false;
                const alertConfirm = this.atrCtrl.create({
                    message: 'Thông tin vừa được lưu vào hệ thống!',
                    cssClass: 'my-custom-class-success',
                });
                (await alertConfirm).dismiss();
                (await alertConfirm).present();
            }).catch()



    }
    async onCompleteHistoryTest() {
        this.isLoadingTestHiv = true;
        const payload = {
            userId: this.customerId,
            tienSuXetNghiemHIV: this.tienSuXetNghiemHIV,
            tinhTrangNhiemHIV: this.tinhTrangNhiemHIV,
            ngayXNHIV: new Date(this.ngayXNHIV).getTime(),
        }
        if (!this.ngayXNHIV) {
            delete payload.ngayXNHIV;
        }
        let clonePayload = JSON.parse(JSON.stringify(payload));
        delete clonePayload.userId;
        const isEmpty = (element, index, array) => {
            return element === '';
        };
        if (Object.values(clonePayload).every(isEmpty)) {
            this.isLoadingTestHiv = false;
        } else {
            if ((this.tienSuXetNghiemHIV === 'Có xét nghiệm' && !this.tinhTrangNhiemHIV) || (this.tienSuXetNghiemHIV === 'Có xét nghiệm' && !this.ngayXNHIV)) {
                this.validateTestHiv = true;
                this.isLoadingTestHiv = false
                const alertConfirm = this.atrCtrl.create({
                    cssClass: 'my-custom-class',
                    message: 'Vui lòng nhập đầy đủ thông tin hợp lệ!',
                });
                (await alertConfirm).dismiss();

                (await alertConfirm).present();
            } else {
                this.customerService.saveHistoryTest(payload).then(async res => {
                    this.isLoadingTestHiv = false
                    const alertConfirm = this.atrCtrl.create({
                        message: 'Thông tin vừa được lưu vào hệ thống!',
                        cssClass: 'my-custom-class-success',
                    });
                    (await alertConfirm).dismiss();
                    (await alertConfirm).present();
                    this.isCreateNewHivTest = false;
                    this.customerService.getDetailUsersSendKPElog(this.customerId).then((res:any) => {
                        this.countTestHiv = res.ttNangCao.tienSuXetNghiemHIV.length;
                        this._idTestHiv = res.ttNangCao?.tienSuXetNghiemHIV[res.ttNangCao?.tienSuXetNghiemHIV.length-1]?._id;
                        this.updateRelateHistoryHIVTest = false;
                    })
                }).catch(() => this.isLoadingTestHiv = false)
            }

        }
    }
    async updateHistoryTestHiv() {
        this.isLoadingTestHiv = true;
        const payload = {
            userId: this.customerId,
            tienSuXetNghiemHIV: this.tienSuXetNghiemHIV,
            tinhTrangNhiemHIV: this.tinhTrangNhiemHIV,
            ngayXNHIV: new Date(this.ngayXNHIV).getTime(),
        }
        if (payload.tienSuXetNghiemHIV === 'Chưa từng xét nghiệm') {
            payload.tinhTrangNhiemHIV = '';
            payload.ngayXNHIV = null;
        }
        if ((this.tienSuXetNghiemHIV === 'Có xét nghiệm' &&!this.tinhTrangNhiemHIV) || (this.tienSuXetNghiemHIV === 'Có xét nghiệm' && !this.ngayXNHIV)) {
            this.validateTestHiv = true;
            this.isLoadingTestHiv = false;
            const alertConfirm = this.atrCtrl.create({
                cssClass: 'my-custom-class',
                message: 'Vui lòng nhập đầy đủ thông tin hợp lệ!',
            });
            (await alertConfirm).dismiss();

            (await alertConfirm).present();
        } else {
            this.customerService.updateHistoryTestHiv(this._idTestHiv, payload).then(async res => {
                this.isLoadingTestHiv = false;
                const alertConfirm = this.atrCtrl.create({
                    message: 'Thông tin vừa được lưu vào hệ thống!',
                    cssClass: 'my-custom-class-success',
                });
                (await alertConfirm).dismiss();
                (await alertConfirm).present();
            }).catch()
        }

    }
    async onCompleteTreatmentARV() {
        this.isLoadingArv = true;
        const payload = {
            userId: this.customerId,
            tinhTrangDieuTriARV: this.tinhTrangDieuTriARV,
            dieuTriARVQuaBHYT: this.dieuTriARVQuaBHYT,
            taiLuongVirus: this.taiLuongVirus,
            tuanThuARV1: {
                value: this.tuanThuARV1,
                soLieuDaQuen: this.soLieuDaQuenARV1,
                soNgayNgung: this.soNgayNgungARV1,
                lyDoQuen: this.lyDoQuenARV1,
                khac: this.khacARV1
            },
            tuanThuARV2: {
                value: this.tuanThuARV2,
                soLieuDaQuen: this.soLieuDaQuenARV2,
                soNgayNgung: this.soNgayNgungARV2,
                lyDoQuen: this.lyDoQuenARV1,
                khac: this.khacARV2
            },
            tuanThuARV3: {
                value: this.tuanThuARV3,
                soLieuDaQuen: this.soLieuDaQuenARV3,
                soNgayNgung: this.soNgayNgungARV3,
                lyDoQuen: this.lyDoQuenARV3,
                khac: this.khacARV3
            },
            cgDieuTriARV: this.cgDieuTriARV
        }
        let clonePayload = JSON.parse(JSON.stringify(payload));

        delete clonePayload.userId;
        const objectEmpty = (element) => {
            return element === ''
        };
        const isEmpty = (element: any) => {
            return element === '' ||
                (element.value === '' && element.soLieuDaQuen === '' && element.soNgayNgung === '' && element.lyDoQuen === '' && element.khac === '')
        };

        if (Object.values(clonePayload).every(isEmpty)) {
            this.isLoadingArv = false;
        } else {
            if ((this.tienSuXetNghiemHIV === 'Có xét nghiệm' && this.tinhTrangNhiemHIV === 'Dương tính' && !this.tinhTrangDieuTriARV) || (this.tinhTrangDieuTriARV !== 'Đang điều trị' && !this.cgDieuTriARV)) {
                this.validateTreatmentArv = true;
                this.isLoadingArv = false;
                const alertConfirm = this.atrCtrl.create({
                    cssClass: 'my-custom-class',
                    message: 'Vui lòng nhập đầy đủ thông tin hợp lệ!',
                });
                (await alertConfirm).dismiss();

                (await alertConfirm).present();
            } else {
                this.customerService.saveHistoryTreatmentArv(payload).then(async res => {
                    this.isLoadingArv = false;
                    const alertConfirm = this.atrCtrl.create({
                        message: 'Thông tin vừa được lưu vào hệ thống!',
                        cssClass: 'my-custom-class-success',
                    });
                    (await alertConfirm).dismiss();
                    (await alertConfirm).present();
                    this.isCreateNewTreatmentArv = false;
                    this.customerService.getDetailUsersSendKPElog(this.customerId).then((res:any) => {
                        this.countARV = res.ttNangCao.tinhTrangDieuTriARV.length;
                        this._idTreatmentArv = res.ttNangCao?.tinhTrangDieuTriARV[res.ttNangCao?.tinhTrangDieuTriARV.length-1]?._id;
                        this.updateRelateHistoryTreatmentArv = false;
                    })
                }).catch(() => this.isLoadingArv = false);
            }

        }
    }
    async updateHistoryTreatmentArv() {
        this.isLoadingArv = true;
        const payload = {
            userId: this.customerId,
            tinhTrangDieuTriARV: this.tinhTrangDieuTriARV,
            dieuTriARVQuaBHYT: this.dieuTriARVQuaBHYT,
            taiLuongVirus: this.taiLuongVirus,
            tuanThuARV1: {
                value: this.tuanThuARV1,
                soLieuDaQuen: this.soLieuDaQuenARV1,
                soNgayNgung: this.soNgayNgungARV1,
                lyDoQuen: this.lyDoQuenARV1,
                khac: this.khacARV1
            },
            tuanThuARV2: {
                value: this.tuanThuARV2,
                soLieuDaQuen: this.soLieuDaQuenARV2,
                soNgayNgung: this.soNgayNgungARV2,
                lyDoQuen: this.lyDoQuenARV1,
                khac: this.khacARV2
            },
            tuanThuARV3: {
                value: this.tuanThuARV3,
                soLieuDaQuen: this.soLieuDaQuenARV3,
                soNgayNgung: this.soNgayNgungARV3,
                lyDoQuen: this.lyDoQuenARV3,
                khac: this.khacARV3
            },
            cgDieuTriARV: this.cgDieuTriARV
        }
        if (payload.tinhTrangDieuTriARV !== 'Đang điều trị') {
            payload.dieuTriARVQuaBHYT = '';
            payload.taiLuongVirus = '';
            payload.tuanThuARV1.value = '';
            payload.tuanThuARV1.soLieuDaQuen = '';
            payload.tuanThuARV1.lyDoQuen = '';
            payload.tuanThuARV1.soNgayNgung = '';
            payload.tuanThuARV1.khac = '';
            payload.tuanThuARV2.value = '';
            payload.tuanThuARV2.soLieuDaQuen = '';
            payload.tuanThuARV2.lyDoQuen = '';
            payload.tuanThuARV2.soNgayNgung = '';
            payload.tuanThuARV2.khac = '';
            payload.tuanThuARV3.value = '';
            payload.tuanThuARV3.soLieuDaQuen = '';
            payload.tuanThuARV3.lyDoQuen = '';
            payload.tuanThuARV3.soNgayNgung = '';
            payload.tuanThuARV3.khac = ''
        }
        if ((this.tienSuXetNghiemHIV === 'Có xét nghiệm' && this.tinhTrangNhiemHIV === 'Dương tính' &&  !this.tinhTrangDieuTriARV) ||(this.tinhTrangDieuTriARV!=='Đang điều trị'&&!this.cgDieuTriARV)) {
            this.validateTreatmentArv = true;
            this.isLoadingArv = false;
            const alertConfirm = this.atrCtrl.create({
                cssClass: 'my-custom-class',
                message: 'Vui lòng nhập đầy đủ thông tin hợp lệ!',
            });
            (await alertConfirm).dismiss();

            (await alertConfirm).present();
        } else {
            this.customerService.editHistoryTreatmentArv(this._idTreatmentArv, payload).then(async res => {
                this.isLoadingArv = false;
                const alertConfirm = this.atrCtrl.create({
                    message: 'Thông tin vừa được lưu vào hệ thống!',
                    cssClass: 'my-custom-class-success',
                });
                (await alertConfirm).dismiss();
                (await alertConfirm).present();
            }).catch()
        }


    }

    async onCompletePrep() {
        this.isLoadingPrep = true;
        const payload = {
            userId: this.customerId,
            tinhTrangPrep: this.tinhTrangPrep,
            tuanThuPrep1: {
                value: this.tuanThuPrep1,
                soLieuDaQuen: this.soLieuDaQuenPrep1,
                soNgayNgung: this.soNgayNgungPrep1,
                lyDoQuen: this.lyDoQuenPrep1,
                khac: this.khacPrep1
            },
            tuanThuPrep2: {
                value: this.tuanThuPrep2,
                soLieuDaQuen: this.soLieuDaQuenPrep2,
                soNgayNgung: this.soNgayNgungPrep2,
                lyDoQuen: this.lyDoQuenPrep2,
                khac: this.khacPrep2
            },
            tuanThuPrep3: {
                value: this.tuanThuPrep3,
                soLieuDaQuen: this.soLieuDaQuenPrep3,
                soNgayNgung: this.soNgayNgungPrep3,
                lyDoQuen: this.lyDoQuenPrep3,
                khac: this.khacPrep3
            },
        }
        if (payload.tinhTrangPrep !== 'Đang điều trị') {
            payload.tuanThuPrep1.value = '';
            payload.tuanThuPrep1.soLieuDaQuen = '';
            payload.tuanThuPrep1.lyDoQuen = '';
            payload.tuanThuPrep1.soNgayNgung = '';
            payload.tuanThuPrep1.khac = '';
            payload.tuanThuPrep2.value = '';
            payload.tuanThuPrep2.soLieuDaQuen = '';
            payload.tuanThuPrep2.lyDoQuen = '';
            payload.tuanThuPrep2.soNgayNgung = '';
            payload.tuanThuPrep2.khac = '';
            payload.tuanThuPrep3.value = '';
            payload.tuanThuPrep3.soLieuDaQuen = '';
            payload.tuanThuPrep3.lyDoQuen = '';
            payload.tuanThuPrep3.soNgayNgung = '';
            payload.tuanThuPrep3.khac = ''
        }
        let clonePayload = JSON.parse(JSON.stringify(payload));

        delete clonePayload.userId;
        const objectEmpty = (element) => {
            return element === ''
        };
        const isEmpty = (element: any) => {
            return element === '' ||
                (element.value === '' && element.soLieuDaQuen === '' && element.soNgayNgung === '' && element.lyDoQuen === '' && element.khac === '')
        };

        if (Object.values(clonePayload).every(isEmpty)) {
            this.isLoadingPrep = false;
        } else {
            if (this.tienSuXetNghiemHIV=== 'Có xét nghiệm'&& this.tinhTrangNhiemHIV==='Âm tính' && !this.tinhTrangPrep) {
                this.validatePrep = true;
                this.isLoadingPrep = false;
                const alertConfirm = this.atrCtrl.create({
                    cssClass: 'my-custom-class',
                    message: 'Vui lòng nhập đầy đủ thông tin hợp lệ!',
                });
                (await alertConfirm).dismiss();

                (await alertConfirm).present();
            } else {
                this.customerService.saveHistoryPrepAdvanced(payload).then(async res => {
                    this.isLoadingPrep = false;
                    const alertConfirm = this.atrCtrl.create({
                        message: 'Thông tin vừa được lưu vào hệ thống!',
                        cssClass: 'my-custom-class-success',
                    });
                    (await alertConfirm).dismiss();
                    (await alertConfirm).present();
                    this.isCreateNewTreatmentPrep = false;
                    this.customerService.getDetailUsersSendKPElog(this.customerId).then((res:any) => {
                        this.countPrep = res.ttNangCao.tinhTrangPrep.length;
                        this._idTreatPrep = res.ttNangCao?.tinhTrangPrep[res.ttNangCao?.tinhTrangPrep.length-1]?._id;
                        this.updateRelateHistoryTreatmentPrep = false;
                    })
                }).catch(() =>this.isLoadingPrep = false);
            }

        }
    }
    async updateHistoryPrep() {
        this.isLoadingPrep = true;
        const payload = {
            userId: this.customerId,
            tinhTrangPrep: this.tinhTrangPrep,
            tuanThuPrep1: {
                value: this.tuanThuPrep1,
                soLieuDaQuen: this.soLieuDaQuenPrep1,
                soNgayNgung: this.soNgayNgungPrep1,
                lyDoQuen: this.lyDoQuenPrep1,
                khac: this.khacPrep1
            },
            tuanThuPrep2: {
                value: this.tuanThuPrep2,
                soLieuDaQuen: this.soLieuDaQuenPrep2,
                soNgayNgung: this.soNgayNgungPrep2,
                lyDoQuen: this.lyDoQuenPrep2,
                khac: this.khacPrep2
            },
            tuanThuPrep3: {
                value: this.tuanThuPrep3,
                soLieuDaQuen: this.soLieuDaQuenPrep3,
                soNgayNgung: this.soNgayNgungPrep3,
                lyDoQuen: this.lyDoQuenPrep3,
                khac: this.khacPrep3
            },

        }
        if (payload.tinhTrangPrep !== 'Đang điều trị') {
            payload.tuanThuPrep1.value = '';
            payload.tuanThuPrep1.soLieuDaQuen = '';
            payload.tuanThuPrep1.lyDoQuen = '';
            payload.tuanThuPrep1.soNgayNgung = '';
            payload.tuanThuPrep1.khac = '';
            payload.tuanThuPrep2.value = '';
            payload.tuanThuPrep2.soLieuDaQuen = '';
            payload.tuanThuPrep2.lyDoQuen = '';
            payload.tuanThuPrep2.soNgayNgung = '';
            payload.tuanThuPrep2.khac = '';
            payload.tuanThuPrep3.value = '';
            payload.tuanThuPrep3.soLieuDaQuen = '';
            payload.tuanThuPrep3.lyDoQuen = '';
            payload.tuanThuPrep3.soNgayNgung = '';
            payload.tuanThuPrep3.khac = ''
        }
        let clonePayload = JSON.parse(JSON.stringify(payload));

        delete clonePayload.userId;
        const objectEmpty = (element) => {
            return element === ''
        };
        const isEmpty = (element: any) => {
            return element === '' ||
                (element.value === '' && element.soLieuDaQuen === '' && element.soNgayNgung === '' && element.lyDoQuen === '' && element.khac === '')
        };

        if (Object.values(clonePayload).every(isEmpty)) {
        } else {
            if (this.tienSuXetNghiemHIV=== 'Có xét nghiệm'&& this.tinhTrangNhiemHIV==='Âm tính' && !this.tinhTrangPrep) {
                this.validatePrep = true;
                this.isLoadingPrep = false;
                const alertConfirm = this.atrCtrl.create({
                    cssClass: 'my-custom-class',
                    message: 'Vui lòng nhập đầy đủ thông tin hợp lệ!',
                });
                (await alertConfirm).dismiss();

                (await alertConfirm).present();
            } else {
                this.customerService.editHistoryPrepAdvanced(this._idTreatPrep,payload).then(async res => {
                    this.isLoadingPrep = false;
                    const alertConfirm = this.atrCtrl.create({
                        message: 'Thông tin vừa được lưu vào hệ thống!',
                        cssClass: 'my-custom-class-success',
                    });
                    (await alertConfirm).dismiss();
                    (await alertConfirm).present();
                    this.isCreateNewTreatmentPrep = false;
                    this.customerService.getDetailUsersSendKPElog(this.customerId).then((res:any) => {
                        this.countPrep = res.ttNangCao.tinhTrangPrep.length;
                    })
                }).catch();
            }

        }
    }
    goBack() {
        if (localStorage.getItem('isNew') === '1') {
            this.goToPage('customer');

        } else if (localStorage.getItem('isNew') === '0') {
            this.goToPage('tabs/customer-detail-kpelog/' + this.customerId);
        }
        localStorage.setItem('isNew', '0');
        localStorage.setItem('isNew', '0');
    }

    handleChangenguyCoNhiemHIV(e) {
        this.nguyCoNhiemHIV = e.target.value;
    }

    handleChangetienSuXetNghiemHIV(e) {
        this.tienSuXetNghiemHIV = e.target.value;
    }

    handleChangetinhTrangNhiemHIV(e) {
        this.tinhTrangNhiemHIV = e.target.value;
    }

    handleChangengayXNHIV(e) {
        this.ngayXNHIV = e.target.value;
    }

    handleChangecgDieuTriARV(e) {
        this.cgDieuTriARV = e.target.value;
    }

    clearDatetime(value) {
        if (value === 'ngayXNHIV') {
            this.ngayXNHIV = null;
        }
    }

    handleChangephuongXa(e) {
        let listNew = null;
        listNew = this.location
            .filter((item) => item.lable === this.tinhTP?.lable)
            .map((value) => {
                return value.districts;
            });
        let listFormat = null;
        listFormat = listNew[0]
            .filter((item) => item.lable === this.quanHuyen?.lable)
            .map((item2) => {
                return item2.wards;
            });
        listFormat[0]
            .filter((item) => item.lable === e.lable)
            .map((item2) => {
                return (this.valuePhuongXa = item2.value);
            });
        this.phuongXa = e;
    }

    handleChangequanHuyen(e) {
        this.phuongXa = '';
        let listNew = null;
        listNew = this.location
            .filter((item) => item.lable === this.tinhTP?.lable)
            .map((value) => {
                return value.districts;
            });
        this.wards = listNew[0]
            .filter((item) => item.lable === e.lable)
            .map((item2) => {
                return item2.wards.map((item1) => item1);
            });
        listNew[0]
            .filter((item) => item.lable === e.lable)
            .map((value) => {
                return (this.valueQuanHuyen = value.value);
            });
        this.quanHuyen = e;
    }

    handleChangetinhTP(e) {
        this.quanHuyen = '';
        this.phuongXa = '';
        this.districts = this.location
            .filter((item) => item.lable === e.lable)
            .map((value) => {
                return value.districts.map((item1) => item1);
            });
        this.location
            .filter((item) => item.lable ===e.lable)
            .map((value) => {
                return (this.valueTinhTP = value.value);
            });
        this.tinhTP = e;
    }

    handleChangesoNhaDuong(e) {
        this.soNhaDuong = e.target.value;
    }
    handleChangephuongXaNoiSong(e) {
        let listNew = null;
        listNew = this.location
            .filter((item) => item.lable === this.tinhTPNoiSong?.lable)
            .map((value) => {
                return value.districts;
            });
        let listFormat = null;
        listFormat = listNew[0]
            .filter((item) => item.lable === this.quanHuyenNoiSong?.lable)
            .map((item2) => {
                return item2.wards;
            });
        listFormat[0]
            .filter((item) => item.lable === e.lable)
            .map((item2) => {
                return (this.valuePhuongXaNoiSong = item2.value);
            });
        this.phuongXaNoiSong = e;
    }

    handleChangequanHuyenNoiSong(e) {
        this.phuongXaNoiSong = '';
        let listNew = null;
        listNew = this.location
            .filter((item) => item.lable === this.tinhTPNoiSong?.lable)
            .map((value) => {
                return value.districts;
            });
        this.wardsLiving = listNew[0]
            .filter((item) => item.lable === e.lable)
            .map((item2) => {
                return item2.wards.map((item1) => item1);
            });
        listNew[0]
            .filter((item) => item.lable === e.lable)
            .map((value) => {
                return (this.valueQuanHuyenNoiSong = value.value);
            });
        this.quanHuyenNoiSong = e;
    }

    handleChangetinhTPNoiSong(e) {
        this.quanHuyenNoiSong = '';
        this.phuongXaNoiSong = '';
        this.districtsLiving = this.location
            .filter((item) => item.lable === e.lable)
            .map((value) => {
                return value.districts.map((item1) => item1);
            });
        this.location
            .filter((item) => item.lable === e.lable)
            .map((value) => {
                return (this.valueTinhTPNoiSong = value.value);
            });
        this.tinhTPNoiSong = e;
    }

    async closeModalMenu() {

        this.isOpenModalMenu = false;
        const alertConfirm = this.atrCtrl.create({
                message: 'Thông tin vừa được lưu vào hệ thống!',
                cssClass: 'my-custom-class-success',
            });
        setTimeout(async () => {
                (await alertConfirm).dismiss();
                if (this.customerId){
                    this.goToPage('tabs/list-survey/service-information/' + this.customerId);
                }
            }, 1000);
        (await alertConfirm).present();

    }
    openTabHasError (){
     if((this.tienSuXetNghiemHIV === 'Có xét nghiệm' &&!this.tinhTrangNhiemHIV) || (this.tienSuXetNghiemHIV === 'Có xét nghiệm' && !this.ngayXNHIV)){
         this.isShowHealthStatus = true;
         this.isShowTestHistory = true;
     }
     if(this.tienSuXetNghiemHIV === 'Có xét nghiệm' && this.tinhTrangNhiemHIV === 'Dương tính' && (!this.cgDieuTriARV ||!this.tinhTrangDieuTriARV)){
         this.isShowTreatmentARV = true;
     }
     if(this.tienSuXetNghiemHIV === 'Có xét nghiệm' && this.tinhTrangNhiemHIV === 'Âm tính' && !this.tinhTrangPrep){
            this.isShowStatusPrep = true;
        }
     if(!this.tinhTPNoiSong){
         this.isShowAlive = true;
     }
    }
    async openModalRiskFactor() {
        const modal = await this.modalCtrl.create({
            component: HistorryRiskFactorComponent,
            cssClass: "history-test",
            componentProps:{
                customerId: this.customerId
            }
        });
        await modal.present();

        const {data, role} = await modal.onWillDismiss();

        if (role === 'confirm') {
            this.message = `Hello, ${data}!`;
        }

    }
    async openModalBTBC() {
        const modal = await this.modalCtrl.create({
            component: HistoryBtbcComponent,
            cssClass: "history-test",
            componentProps:{
                customerId: this.customerId
            }
        });
        await modal.present();

        const {data, role} = await modal.onWillDismiss();

        if (role === 'confirm') {
            this.message = `Hello, ${data}!`;
        }

    }
    async openModalFavoriteService() {
        const modal = await this.modalCtrl.create({
            component: HistoryServiceFavoriteComponent,
            cssClass: "history-test",
            componentProps:{
                customerId: this.customerId
            }
        });
        await modal.present();

        const {data, role} = await modal.onWillDismiss();

        if (role === 'confirm') {
            this.message = `Hello, ${data}!`;
        }

    }
    async openModalTestHiv() {
        const modal = await this.modalCtrl.create({
            component: HistoryTestHivComponent,
            cssClass: "history-test",
            componentProps:{
                customerId: this.customerId
            }
        });
        await modal.present();

        const {data, role} = await modal.onWillDismiss();

        if (role === 'confirm') {
            this.message = `Hello, ${data}!`;
        }

    }
    async openModalTreatmentArv() {
        const modal = await this.modalCtrl.create({
            component: HistoryArvNangcaoComponent,
            cssClass: "history-test",
            componentProps:{
                customerId: this.customerId
            }
        });
        await modal.present();

        const {data, role} = await modal.onWillDismiss();

        if (role === 'confirm') {
            this.message = `Hello, ${data}!`;
        }

    }
    async openModalPrep() {
        const modal = await this.modalCtrl.create({
            component: HistoryPrepNangcaoComponent,
            cssClass: "history-test",
            componentProps:{
                customerId: this.customerId
            }
        });
        await modal.present();

        const {data, role} = await modal.onWillDismiss();

        if (role === 'confirm') {
            this.message = `Hello, ${data}!`;
        }

    }

    formatGetDate(date:string){
        if(date){
            return moment(date,'DD-MM-YYYY').format('YYYY-MM-DD');
        }
        else {
            return null;
        }

    }
    async openModalResetRiskFactor() {
        const modal = await this.modalCtrl.create({
            component: ModalResetHistoryComponent,
            cssClass: 'reset-history',

        });
        await modal.present();

        const {data, role} = await modal.onWillDismiss();

        if (role === 'confirm') {
            this.isCreateNewRiskFactor = true;
            this.nguyCoNhiemHIV = [];
            this.nguyCoNhiemHIVKhac = '';
            this.ketQuanTimHieuNguyCo = '';
            this.updateRelateHistoryRiskFactor = true;
        }
        else if(role === 'cancel'){
        }
    }
    async openModalResetBTBC() {
        const modal = await this.modalCtrl.create({
            component: ModalResetHistoryComponent,
            cssClass: 'reset-history',

        });
        await modal.present();

        const {data, role} = await modal.onWillDismiss();

        if (role === 'confirm') {
            this.isCreateNewBTBC = true;
            this.soBanTinhBanChich=  null;
            this.soBanTinhBanChichThuongXuyen=  null;
            this.soBanTinhBanChichBatChot=  null;
            this.updateRelateHistoryBTBC = true;
        }
        else if(role === 'cancel'){
        }
    }
    async openModalResetFavoriteService() {
        const modal = await this.modalCtrl.create({
            component: ModalResetHistoryComponent,
            cssClass: 'reset-history',

        });
        await modal.present();

        const {data, role} = await modal.onWillDismiss();

        if (role === 'confirm') {
            this.isCreateNewFavoriteService = true;
            this.hinhThucXNHIVYeuThich= '';
            this.lyDoYeuThichXNHIV= '';
            this.hinhThucDieuTriHIVYeuThich= '';
            this.lyDoYeuThichDieuTriHIV= '';
            this.hinhThucPrepYeuThich= '';
            this.lyDoYeuThichPrep= '';
            this.dieuQuanTrongTiepCanDichVuDuPhongVaDieuTri= '';
            this.updateRelateHistoryFavoriteService = true;
        }
        else if(role === 'cancel'){
        }
    }
    async openModalResetHIVTest() {
        const modal = await this.modalCtrl.create({
            component: ModalResetHistoryComponent,
            cssClass: 'reset-history',

        });
        await modal.present();

        const {data, role} = await modal.onWillDismiss();

        if (role === 'confirm') {
            this.isCreateNewHivTest = true;
            this.tienSuXetNghiemHIV = '';
            this.tinhTrangNhiemHIV = '';
            this.ngayXNHIV = null;
            this.updateRelateHistoryHIVTest = true;
        }
        else if(role === 'cancel'){
        }
    }
    async openModalResetTreatmentArv() {
        const modal = await this.modalCtrl.create({
            component: ModalResetHistoryComponent,
            cssClass: 'reset-history',

        });
        await modal.present();

        const {data, role} = await modal.onWillDismiss();

        if (role === 'confirm') {
            this.isCreateNewTreatmentArv = true;
            this.tinhTrangDieuTriARV = '';
            this.dieuTriARVQuaBHYT = '';
            this.taiLuongVirus = '';
            this.tuanThuARV1 = '';
            this.tuanThuARV2 = '';
            this.tuanThuARV3 = '';
            this.soLieuDaQuenARV1= '';
            this.soNgayNgungARV1= '';
            this.lyDoQuenARV1= '';
            this.khacARV1= '';
            this.soLieuDaQuenARV2= '';
            this.soNgayNgungARV2= '';
            this.lyDoQuenARV2= '';
            this.khacARV2= '';
            this.soLieuDaQuenARV3= '';
            this.soNgayNgungARV3= '';
            this.lyDoQuenARV3= '';
            this.khacARV3= '';
            this.updateRelateHistoryTreatmentArv = true;
        }
        else if(role === 'cancel'){
        }
    }
    async openModalResetTreatmentPrep() {
        const modal = await this.modalCtrl.create({
            component: ModalResetHistoryComponent,
            cssClass: 'reset-history',

        });
        await modal.present();

        const {data, role} = await modal.onWillDismiss();

        if (role === 'confirm') {
            this.isCreateNewTreatmentPrep= true;
            this.tinhTrangPrep = '';
            this.tuanThuPrep1 = '';
            this.tuanThuPrep2 = '';
            this.tuanThuPrep3 = '';
            this.soLieuDaQuenPrep1= '';
            this.soNgayNgungPrep1= '';
            this.lyDoQuenPrep1= '';
            this.khacPrep1= '';
            this.soLieuDaQuenPrep2= '';
            this.soNgayNgungPrep2= '';
            this.lyDoQuenPrep2= '';
            this.khacPrep2= '';
            this.soLieuDaQuenPrep3= '';
            this.soNgayNgungPrep3= '';
            this.lyDoQuenPrep3= '';
            this.khacPrep3= '';
            this.updateRelateHistoryTreatmentPrep = true;
        }
        else if(role === 'cancel'){
        }
    }
    updateRelatedHistoryRiskFactor(){
        this.updateRelateHistoryRiskFactor = false;
        this.isCreateNewRiskFactor = false;
        this.customerService.getDetailUsersSendKPElog(this.customerId)
            .then((res:any) =>
            {
                this.nguyCoNhiemHIV =  res?.ttNangCao?.yeuToNguyCo[res?.ttNangCao?.yeuToNguyCo.length-1]?.nguyCoNhiemHIV?.value;
                this.nguyCoNhiemHIVKhac = res?.ttNangCao?.yeuToNguyCo[res?.ttNangCao?.yeuToNguyCo.length-1]?.nguyCoNhiemHIV?.khac;
                this.ketQuanTimHieuNguyCo = res?.ttNangCao?.yeuToNguyCo[res?.ttNangCao?.yeuToNguyCo.length-1]?.ketQuanTimHieuNguyCo;
            })
            .catch()
    }
    updateRelatedHistoryBTBC() {
        this.updateRelateHistoryBTBC = false;
        this.isCreateNewBTBC = false;
        this.customerService.getDetailUsersSendKPElog(this.customerId)
            .then((res:any) =>
            {
                this.soBanTinhBanChich =res?.ttNangCao?.soBanTinhBanChich[res?.ttNangCao?.soBanTinhBanChich.length-1]?.soBanTinhBanChich;
                this.soBanTinhBanChichThuongXuyen =res?.ttNangCao?.soBanTinhBanChich[res?.ttNangCao?.soBanTinhBanChich.length-1]?.soBanTinhBanChichThuongXuyen;
                this.soBanTinhBanChichBatChot =res?.ttNangCao?.soBanTinhBanChich[res?.ttNangCao?.soBanTinhBanChich.length-1]?.soBanTinhBanChichBatChot;
            })
            .catch();
    }
    updateRelatedHistoryFavoriteService(){
        this.updateRelateHistoryFavoriteService = false;
        this.isCreateNewFavoriteService = false;
        this.customerService.getDetailUsersSendKPElog(this.customerId)
            .then((res:any) =>
            {
                this.hinhThucXNHIVYeuThich =res?.ttNangCao?.dichVuYeuThich[res?.ttNangCao?.dichVuYeuThich.length-1]?.hinhThucXNHIVYeuThich;
                this.lyDoYeuThichXNHIV =res?.ttNangCao?.dichVuYeuThich[res?.ttNangCao?.dichVuYeuThich.length-1]?.lyDoYeuThichXNHIV;
                this.hinhThucDieuTriHIVYeuThich =res?.ttNangCao?.dichVuYeuThich[res?.ttNangCao?.dichVuYeuThich.length-1]?.hinhThucDieuTriHIVYeuThich;
                this.lyDoYeuThichDieuTriHIV =res?.ttNangCao?.dichVuYeuThich[res?.ttNangCao?.dichVuYeuThich.length-1]?.lyDoYeuThichDieuTriHIV;
                this.hinhThucPrepYeuThich =res?.ttNangCao?.dichVuYeuThich[res?.ttNangCao?.dichVuYeuThich.length-1]?.hinhThucPrepYeuThich;
                this.dieuQuanTrongTiepCanDichVuDuPhongVaDieuTri =res?.ttNangCao?.dichVuYeuThich[res?.ttNangCao?.dichVuYeuThich.length-1]?.dieuQuanTrongTiepCanDichVuDuPhongVaDieuTri;
            })
            .catch()
    }
    updateRelatedHistoryHIVTest(){
        this.updateRelateHistoryHIVTest = false;
        this.isCreateNewHivTest = false;
        this.customerService.getDetailUsersSendKPElog(this.customerId)
            .then((res:any) =>
            {
                this.tienSuXetNghiemHIV =res?.ttNangCao?.tienSuXetNghiemHIV[res?.ttNangCao?.tienSuXetNghiemHIV.length-1]?.tienSuXetNghiemHIV;
                this.tinhTrangNhiemHIV =res?.ttNangCao?.tienSuXetNghiemHIV[res?.ttNangCao?.tienSuXetNghiemHIV.length-1]?.tinhTrangNhiemHIV;
                this.ngayXNHIV =this.formatGetDate(res?.ttNangCao?.tienSuXetNghiemHIV[res?.ttNangCao?.tienSuXetNghiemHIV.length-1]?.ngayXNHIV)
            })
            .catch();
    }
    updateRelatedHistoryTreatmentArv(){
        this.updateRelateHistoryTreatmentArv = false;
        this.isCreateNewTreatmentArv = false;
        this.customerService.getDetailUsersSendKPElog(this.customerId)
            .then((res:any) =>
            {
                this.tinhTrangDieuTriARV =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.tinhTrangDieuTriARV;
                this.dieuTriARVQuaBHYT =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.dieuTriARVQuaBHYT;
                this.taiLuongVirus =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.taiLuongVirus;

                this.tuanThuARV2 =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.tuanThuARV2?.value;
                this.soLieuDaQuenARV2 =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.tuanThuARV2?.soLieuDaQuen;
                this.soNgayNgungARV2 =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.tuanThuARV2?.soNgayNgung;
                this.lyDoQuenARV2 =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.tuanThuARV2?.lyDoQuen;
                this.khacARV2 =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.tuanThuARV2?.khac;

                this.tuanThuARV3 =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.tuanThuARV3?.value;
                this.soLieuDaQuenARV3 =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.tuanThuARV3?.soLieuDaQuen;
                this.soNgayNgungARV3 =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.tuanThuARV3?.soNgayNgung;
                this.lyDoQuenARV3 =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.tuanThuARV3?.lyDoQuen;
                this.khacARV3 =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.tuanThuARV3?.khac;

                this.tuanThuARV1 =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.tuanThuARV1?.value;
                this.soLieuDaQuenARV1 =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.tuanThuARV1?.soLieuDaQuen;
                this.soNgayNgungARV1 =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.tuanThuARV1?.soNgayNgung;
                this.lyDoQuenARV1 =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.tuanThuARV1?.lyDoQuen;
                this.khacARV1 =res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.tuanThuARV1?.khac;
                this.cgDieuTriARV = res?.ttNangCao?.tinhTrangDieuTriARV[res?.ttNangCao?.tinhTrangDieuTriARV.length-1]?.cgDieuTriARV;

            })
            .catch();
    }
    updateRelatedHistoryTreatmentPrep(){
        this.updateRelateHistoryTreatmentPrep = false;
        this.isCreateNewTreatmentPrep = false;
        this.customerService.getDetailUsersSendKPElog(this.customerId)
            .then((res:any) =>
            {
                this.tinhTrangPrep =res?.ttNangCao?.tinhTrangPrep[res?.ttNangCao?.tinhTrangPrep.length-1]?.tinhTrangPrep;
                this.tuanThuPrep1 =res?.ttNangCao?.tinhTrangPrep[res?.ttNangCao?.tinhTrangPrep.length-1]?.tuanThuPrep1?.value;
                this.soLieuDaQuenPrep1 =res?.ttNangCao?.tinhTrangPrep[res?.ttNangCao?.tinhTrangPrep.length-1]?.tuanThuPrep1?.soLieuDaQuen;
                this.soNgayNgungPrep1 =res?.ttNangCao?.tinhTrangPrep[res?.ttNangCao?.tinhTrangPrep.length-1]?.tuanThuPrep1?.soNgayNgung;
                this.lyDoQuenPrep1 =res?.ttNangCao?.tinhTrangPrep[res?.ttNangCao?.tinhTrangPrep.length-1]?.tuanThuPrep1?.lyDoQuen;
                this.khacPrep1 =res?.ttNangCao?.tinhTrangPrep[res?.ttNangCao?.tinhTrangPrep.length-1]?.tuanThuPrep1?.khac;
                this.tuanThuPrep2 =res?.ttNangCao?.tinhTrangPrep[res?.ttNangCao?.tinhTrangPrep.length-1]?.tuanThuPrep2?.value;
                this.soLieuDaQuenPrep2 =res?.ttNangCao?.tinhTrangPrep[res?.ttNangCao?.tinhTrangPrep.length-1]?.tuanThuPrep2?.soLieuDaQuen;
                this.soNgayNgungPrep2 =res?.ttNangCao?.tinhTrangPrep[res?.ttNangCao?.tinhTrangPrep.length-1]?.tuanThuPrep2?.soNgayNgung;
                this.lyDoQuenPrep2 =res?.ttNangCao?.tinhTrangPrep[res?.ttNangCao?.tinhTrangPrep.length-1]?.tuanThuPrep2?.lyDoQuen;
                this.khacPrep2 =res?.ttNangCao?.tinhTrangPrep[res?.ttNangCao?.tinhTrangPrep.length-1]?.tuanThuPrep2?.khac;
                this.tuanThuPrep3 =res?.ttNangCao?.tinhTrangPrep[res?.ttNangCao?.tinhTrangPrep.length-1]?.tuanThuPrep3?.value;
                this.soLieuDaQuenPrep3 =res?.ttNangCao?.tinhTrangPrep[res?.ttNangCao?.tinhTrangPrep.length-1]?.tuanThuPrep3?.soLieuDaQuen;
                this.soNgayNgungPrep3 =res?.ttNangCao?.tinhTrangPrep[res?.ttNangCao?.tinhTrangPrep.length-1]?.tuanThuPrep3?.soNgayNgung;
                this.lyDoQuenPrep3 =res?.ttNangCao?.tinhTrangPrep[res?.ttNangCao?.tinhTrangPrep.length-1]?.tuanThuPrep3?.lyDoQuen;
                this.khacPrep3 =res?.ttNangCao?.tinhTrangPrep[res?.ttNangCao?.tinhTrangPrep.length-1]?.tuanThuPrep3?.khac;
            })
            .catch()
    }
    async onPasteThoiDiemSong() {
        const datePaste = await navigator.clipboard.readText();
        const datePasteFormated = moment(datePaste,'DD-MM-YYYY').format('YYYY-MM-DD');
        let checkDate = moment(datePaste, 'DD-MM-YYYY').isValid() || moment(datePaste, 'DD/MM/YYYY').isValid()
            ||moment(datePaste, 'D-M-YYYY').isValid() || moment(datePaste, 'D/M/YYYY').isValid()
            ||moment(datePaste, 'D-M-YY').isValid() || moment(datePaste, 'D/M/YY').isValid();
        if(checkDate){
            this.thoiDiemBatDauSongTaiNoiOHienTai = datePasteFormated
        }
    }
    async onPasteSongToiThoiDiem() {
        const datePaste = await navigator.clipboard.readText();
        const datePasteFormated = moment(datePaste,'DD-MM-YYYY').format('YYYY-MM-DD');
        let checkDate = moment(datePaste, 'DD-MM-YYYY').isValid() || moment(datePaste, 'DD/MM/YYYY').isValid()
            ||moment(datePaste, 'D-M-YYYY').isValid() || moment(datePaste, 'D/M/YYYY').isValid()
            ||moment(datePaste, 'D-M-YY').isValid() || moment(datePaste, 'D/M/YY').isValid();
        if(checkDate){
            this.duKienOChoDen = datePasteFormated
        }
    }
    async onPasteNgayXN() {
        const datePaste = await navigator.clipboard.readText();
        const datePasteFormated = moment(datePaste,'DD-MM-YYYY').format('YYYY-MM-DD');
        let checkDate = moment(datePaste, 'DD-MM-YYYY').isValid() || moment(datePaste, 'DD/MM/YYYY').isValid()
            ||moment(datePaste, 'D-M-YYYY').isValid() || moment(datePaste, 'D/M/YYYY').isValid()
            ||moment(datePaste, 'D-M-YY').isValid() || moment(datePaste, 'D/M/YY').isValid();
        if(checkDate){
            this.ngayXNHIV = datePasteFormated
        }
    }
}

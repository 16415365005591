import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  PreloadAllModules,
  RouterModule,
  RouteReuseStrategy,
} from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';



import { AppRoutingModule } from './app-routing.module';
import reducerContainer from './reducers';

// Services
import { Request } from './services/request.service';
import { AuthApiService } from './services/auth.service';
import { CustomerService } from './services/customer.service';
import { ServicesService } from './services/services.service';

// Components
import { SharedModule } from './components/shared.module';

import { ScreeningComponent } from './components/screening/screening.component';
import { AppComponent } from './app.component';
import { ChangesHistoryService } from './services/changes-history.service';
import { NotificationService } from './services/notification.service';
import {SelectComponent} from './components/select/select.component';
import {GeneralInformationComponent} from './page/list-survey/general-information/general-information.component';
import {BasicInformationComponent} from './page/list-survey/basic-information/basic-information.component';
import {AdvancedInformationComponent} from './page/list-survey/advanced-information/advanced-information.component';
import {ServiceInformationComponent} from './page/list-survey/service-information/service-information.component';
import {SelectMultipleComponent} from './components/select-multiple/select-multiple.component';
import {IConfig, NgxMaskModule} from "ngx-mask";
import {SurveyHistoryComponent} from "./components/survey-history/survey-history/survey-history.component";
import {HistoryPrepComponent} from "./components/survey-history/history-prep/history-prep.component";
import {HistoryPepComponent} from "./components/survey-history/history-pep/history-pep.component";
import {
    HistoryTreatmentArvComponent
} from "./components/survey-history/history-treatment-arv/history-treatment-arv.component";
import {
    HistoryComplianceArvComponent
} from "./components/survey-history/history-compliance-arv/history-compliance-arv.component";
import {HistoryViolenceComponent} from "./components/survey-history/history-violence/history-violence.component";
import {
    HistoryChangeHabbitComponent
} from "./components/survey-history/history-change-habbit/history-change-habbit.component";
import {
    HistoryMethodReduceComponent
} from "./components/survey-history/history-method-reduce/history-method-reduce.component";
import {HistoryShareHivComponent} from "./components/survey-history/history-share-hiv/history-share-hiv.component";
import {SexualIllnessComponent} from "./components/survey-history/sexual-illness/sexual-illness.component";
import {HistoryOtherSendComponent} from "./components/survey-history/history-other-send/history-other-send.component";
import {HistorySexHealthComponent} from "./components/survey-history/history-sex-health/history-sex-health.component";
import {
    HistorryRiskFactorComponent
} from "./components/survey-history/historry-risk-factor/historry-risk-factor.component";
import {
    HistoryArvNangcaoComponent
} from "./components/survey-history/history-arv-nangcao/history-arv-nangcao.component";
import {HistoryBtbcComponent} from "./components/survey-history/history-btbc/history-btbc.component";
import {
    HistoryServiceFavoriteComponent
} from "./components/survey-history/history-service-favorite/history-service-favorite.component";
import {HistoryTestHivComponent} from "./components/survey-history/history-test-hiv/history-test-hiv.component";
import {
    ModalResetHistoryComponent
} from "./components/survey-history/modal-reset-history/modal-reset-history.component";
import {
    HistoryPrepNangcaoComponent
} from "./components/survey-history/history-prep-nangcao/history-prep-nangcao.component";
import {IonicSelectableModule} from "ionic-selectable";
import {UploadImageComponent} from "./components/upload-image/upload-image.component";

import {IonicSelectableComponent} from "./components/ionic-selectable/ionic-selectable.component";
import {IonSelectableAddressComponent} from "./components/ion-selectable-address/ion-selectable-address.component";
const maskConfig: Partial<IConfig> = {
    validation: false,
};
@NgModule({
  declarations: [AppComponent, SelectMultipleComponent, SelectComponent, GeneralInformationComponent, BasicInformationComponent,
      AdvancedInformationComponent, ServiceInformationComponent, SurveyHistoryComponent,HistoryPrepComponent, HistoryPepComponent,
      HistoryTreatmentArvComponent, HistoryComplianceArvComponent, HistoryViolenceComponent, HistoryChangeHabbitComponent,
      HistoryMethodReduceComponent, HistoryShareHivComponent, SexualIllnessComponent, HistoryOtherSendComponent, HistorySexHealthComponent,
      HistorryRiskFactorComponent, HistoryArvNangcaoComponent, HistoryBtbcComponent, HistoryServiceFavoriteComponent, HistoryTestHivComponent,
      ModalResetHistoryComponent,HistoryPrepNangcaoComponent,IonicSelectableComponent, UploadImageComponent, IonSelectableAddressComponent],
  entryComponents: [],
    imports: [
        SharedModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        StoreModule.forRoot(reducerContainer),
        NgxMaskModule.forRoot(maskConfig),
        IonicSelectableModule,
    ],
  providers: [
    StatusBar,
    SplashScreen,
    Request,
    AuthApiService,
    CustomerService,
    ServicesService,
    ChangesHistoryService,
    NotificationService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

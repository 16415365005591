import * as types from '../types';

const initialState = {
    listService: null,
    listHistory: null,
    arrQuestService: []
};

export default function servicesReducer(state = initialState, action) {
    switch (action.type) {
        case types.LIST_SERVICE:
            return {
                ...state,
                listService: action.payload
            };
        case types.LIST_HISTORY:
            return {
                ...state,
                listHistory: action.payload
            };
        case types.LIST_QUEST_SERVICE:
            return {
                ...state,
                arrQuestService: action.payload
            };
        default:
            return state;
    }
}

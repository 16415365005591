import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-select-multiple',
  templateUrl: './select-multiple.component.html',
  styleUrls: ['./select-multiple.component.scss'],
})
export class SelectMultipleComponent implements OnInit {
  @Input() selectItem = [];
  @Input() placeholder = '';
  @Input() style = '';
  @Input() margin = '';
  @Input() selected = [];
  @Input() multiple = false;
  constructor() { }
  ngOnInit() {
  }

}

import * as dayjs from 'dayjs';
import STATIC_ADDRESS from './address.js'

export const ARV = 'ARV'
export const PREP = 'Prep'

const getCauHoiObj = (listCauHoi, index) => listCauHoi.find(i => i.index === index)
const getTotalQuest = (batches) => {
    return batches.map(i => i.soCauHoi).reduce((pre, curr) => pre + curr, 0)
}
const getTotalAnswer = (batches) => {
    const copyBatches = batches.map(i => {
        const length = i.lichSuTraLoi?.daTraLoi
        return length ? length : 0
    }).reduce((pre, curr) => pre + curr, 0)
    return copyBatches
}
const filterQuest = (array, ids, path) => {
    return array.filter(i => !ids.includes(i[path]))
}

const filterQuestByKeep = (array, codes, path = 'stt') => {
    console.log("filterQuestByKeep", array, codes);

    return array.filter(i => codes.includes(i[path]))
}

export const handleFindUnsaved = (array) => {
    let isHaveChanged = false

    array.forEach((item) => {
        if (item.lichSuTraLoi) {
            const {stt, _id} = item.lichSuTraLoi
            stt.forEach(j => {
                if (j.isTempSave) isHaveChanged = true
            })
        }
    })

    return isHaveChanged
}

export const findBatch = (array, id, path) => {
    return array.find(i => i[path] === id)
}

export const removeDuplicate = (array) => {
    return array.filter((item, i) => array.indexOf(item) === i)
}

export const updateFilterCodes = (array, answerObj) => {
    const filterId = useQuestionFlow(answerObj)

    if (filterId === 'rmBo1Logic') return array.filter(i => i !== 'bo1cau8chuyencau9')
    if (filterId === 'rmBo4cau3chuyencau8') return array.filter(i => i !== 'bo4cau3chuyencau8')
    if (filterId === 'rmBo6cau1chuyencau20B') return array.filter(i => i !== 'bo6cau1chuyencau20B')
    if (filterId === 'rmBo7cau1chuyencau20A') return array.filter(i => i !== 'bo7cau1chuyencau20A')
    if (filterId === 'clearbo8logic') return array.filter(i => i === 'clearbo8logic')
    if (filterId === 'rmBo8cau4chuyencau21') return array.filter(i => i !== 'bo8cau4chuyencau21')
    else {
        const afterRemove = removeDuplicate([...array, filterId])
        if (filterId === 'bo6cau2chuyen18.1.2' && afterRemove.find(i => i === 'bo6cau2chuyen18.1.2')) return afterRemove.filter(i => i === 'bo6cau2chuyen18.1.2')
        if (filterId === 'bo6cau2chuyen18.1.1' && afterRemove.find(i => i === 'bo6cau2chuyen18.1.1')) return afterRemove.filter(i => i === 'bo6cau2chuyen18.1.1')
        if (filterId === 'bo7cau2chuyen19.1.2' && afterRemove.find(i => i === 'bo7cau2chuyen19.1.2')) return afterRemove.filter(i => i === 'bo7cau2chuyen19.1.2')
        if (filterId === 'bo7cau2chuyen19.2' && afterRemove.find(i => i === 'bo7cau2chuyen19.2')) return afterRemove.filter(i => i === 'bo7cau2chuyen19.2')
        if (filterId === 'bo7cau2chuyen19.1.1' && afterRemove.find(i => i === 'bo7cau2chuyen19.1.1')) return afterRemove.filter(i => i === 'bo7cau2chuyen19.1.1')
        if (filterId === 'bo6cau2chuyen18.2' && afterRemove.find(i => i === 'bo6cau2chuyen18.2')) return afterRemove.filter(i => i === 'bo6cau2chuyen18.2')
        if (filterId === 'bo8cau20Achuyencau20.2' && afterRemove.find(i => i === 'bo8cau20Achuyencau20.2')) return afterRemove.filter(i => i !== 'bo8cau20Achuyencau20.1')
        if (filterId === 'bo8cau20Achuyencau20.1' && afterRemove.find(i => i === 'bo8cau20Achuyencau20.1')) return afterRemove.filter(i => i !== 'bo8cau20Achuyencau20.2')
        if (filterId === 'bo8cau20Bchuyencau20.2' && afterRemove.find(i => i === 'bo8cau20Bchuyencau20.2')) return afterRemove.filter(i => i !== 'bo8cau20Bchuyencau20.1')
        if (filterId === 'bo8cau20Bchuyencau20.1' && afterRemove.find(i => i === 'bo8cau20Bchuyencau20.1')) return afterRemove.filter(i => i !== 'bo8cau20Bchuyencau20.2')
        if (filterId === 'bo4cau8chuyencau20A') return afterRemove.filter(i => ['bo4cau8chuyencau20A', 'bo4cau3chuyencau8'].includes(i))
        if (filterId === 'bo4cau1chuyencau17') return afterRemove.filter(i => ['bo4cau1chuyencau17', 'bo4cau3chuyencau8'].includes(i))
        if (filterId === 'bo4cau8chuyencau19') return afterRemove.filter(i => ['bo4cau8chuyencau19', 'bo4cau3chuyencau8'].includes(i))
        if (filterId === 'bo8cau4chuyencau21') return afterRemove.filter(i => ['bo8cau20Bchuyencau20.2', 'bo8cau20Achuyencau20.2', 'bo8cau4chuyencau21', 'bo8cau20Bchuyencau20.1'].includes(i))

        return afterRemove
    }
}

export const useFilterQuestion = (questions, filterCodes) => {
    let updatedQuestions = questions
    filterCodes = filterCodes.filter(i => i)

    filterCodes.forEach(item => {
        if (item === 'bo1cau8chuyencau9') updatedQuestions = filterQuest(updatedQuestions, [9], 'stt')
        else if (item === 'bo4cau3chuyencau8') updatedQuestions = filterQuest(updatedQuestions, [4, 5, 6, 7], 'stt')
        else if (['bo6cau1chuyencau20B', 'bo7cau1chuyencau20A'].includes(item)) updatedQuestions = filterQuestByKeep(updatedQuestions, [1])
        else if (['bo6cau2chuyen18.1.2', 'bo7cau2chuyen19.1.2'].includes(item)) updatedQuestions = filterQuest(updatedQuestions, [3], 'stt')
        else if (['bo7cau2chuyen19.2', 'bo6cau2chuyen18.2'].includes(item)) updatedQuestions = filterQuest(updatedQuestions, [3, 4], 'stt')
        else if (['bo8cau20Achuyencau20.2'].includes(item)) updatedQuestions = filterQuest(updatedQuestions, [3], 'stt')
        else if (['bo8cau20Bchuyencau20.2'].includes(item)) updatedQuestions = filterQuest(updatedQuestions, [3], 'stt')
        else if (['bo8cau4chuyencau21'].includes(item)) updatedQuestions = filterQuest(updatedQuestions, [5, 6], 'stt')
        else if (['bo4cau8chuyencau20A', 'bo4cau8chuyencau19'].includes(item)) updatedQuestions = filterQuest(updatedQuestions, [9], 'stt')
        else if ((filterCodes.includes('bo4cau9chuyencau20A') && filterCodes.includes('bo4cau8chuyencau19')) || (filterCodes.includes('bo4cau8chuyencau20A') && filterCodes.includes('bo4cau9chuyencau20A'))) updatedQuestions = filterQuest(updatedQuestions, [9], 'stt')
        else if (['bo4cau1chuyencau17'].includes(item)) return updatedQuestions
        else if (item === 'rmBo4cau9chuyencau20A' && filterCodes.includes('bo4cau3chuyencau8')) return updatedQuestions
        else if (item === 'bo4cau9chuyencau20A' && filterCodes.includes('bo4cau3chuyencau8')) return updatedQuestions
        else if (item === 'rmBo8cau4chuyencau21' && (filterCodes.includes('bo8cau20Achuyencau20.2') || filterCodes.includes('bo8cau20Bchuyencau20.2'))) updatedQuestions = filterQuest(questions, [3], 'stt')
        else if (filterCodes.includes('bo8cau4chuyencau21') && (filterCodes.includes('bo8cau20Bchuyencau20.1') || filterCodes.includes('bo8cau20Achuyencau20.1'))) updatedQuestions = filterQuest(updatedQuestions, [5, 6], 'stt')
        else if (item === 'bo6cau9chuyencau20B')return updatedQuestions
        else updatedQuestions = questions
    });
    return updatedQuestions
}


export const filterQuestionBatchs = (array, stt = []) => {
    const boCauHoi = [...array]
    let needRemove = []

    const updated = boCauHoi.filter((item, i) => {
        const {tenBoCauHoi, lichSuTraLoi} = item
        if (!['4', '5', '6', '7'].includes(tenBoCauHoi)) return true

        // bo 4 cau 8 -> 3 4, 9 -> false
        if (tenBoCauHoi === '4' && lichSuTraLoi) {
            const cau8 = getCauHoiObj(stt, 8) ?? getCauHoiObj(lichSuTraLoi.stt, 8)
            const cau8current = getCauHoiObj(stt, 8)
            const cau9 = getCauHoiObj(stt, 9) ?? getCauHoiObj(lichSuTraLoi.stt, 9)

            if ([3, 4].includes(cau8?.cauTraLoi) || (!cau9?.cauTraLoi && typeof cau9?.cauTraLoi === 'boolean')) {
                needRemove = ['5', '6', '7']
            }
            if (cau8?.cauTraLoi === 2) {
                needRemove = ['5', '6']
                if (cau8current?.cauTraLoi === 1) needRemove = []
            }
        }

        // bo 5 cau 5 -> 3;
        if (tenBoCauHoi === '5' && lichSuTraLoi && !needRemove.length) {
            const cau5 = getCauHoiObj(lichSuTraLoi.stt, 5)

            if (cau5?.cauTraLoi === 3) needRemove = ['6', '7']
            if (cau5?.cauTraLoi === 1) needRemove = ['7']
            if (cau5?.cauTraLoi === 2) needRemove = ['6']
        }

        if (isExistBatchType(boCauHoi, '6') && !needRemove.length) needRemove = ['7']
        if (isExistBatchType(boCauHoi, '7') && !needRemove.length) needRemove = ['6']

        return !needRemove.includes(tenBoCauHoi)
    })

    console.log("filterQuestionBatchs", needRemove, updated);
    return updated.sort((a, b) => Number(a.tenBoCauHoi) - Number(b.tenBoCauHoi))
}

export const handleFindInactiveBatches = (array, stt = []) => {
    const boCauHoi = [...array]
    let needRemove = []

    const updated2 = boCauHoi.map(i => needRemove.includes(i.tenBoCauHoi) ? {...i, isActive: false} : i)


    return {array: updated2.sort((a, b) => Number(a.tenBoCauHoi) - Number(b.tenBoCauHoi)), needRemove}
}

export const calculateProcess = (batches) => {
    const totalQuest = getTotalQuest(batches.filter(i => {
        if (typeof i?.isActive === 'boolean' && !i?.isActive) return false
        return true
    }))
    const totalAnswers = getTotalAnswer(batches)

    console.log("totalQuest", totalQuest);
    console.log("totalAnswers", totalAnswers);
    console.log("Number((totalAnswers * 100 / totalQuest).toFixed(2)) = ", Number((totalAnswers * 100 / totalQuest).toFixed(2)));

    return Number((totalAnswers * 100 / totalQuest).toFixed(2))
}

export const useQuestionFlow = (obj) => {
    const {tenBoCauHoi, cauTraLoi, index, userType} = obj

    if (tenBoCauHoi === '1') {
        if (index === 8) return cauTraLoi === 6 ? 'bo1cau8chuyencau9' : 'rmBo1Logic'
    }
    if (tenBoCauHoi === '4') {
        if (index === 3) {
            return [2, 3].includes(cauTraLoi) ? 'bo4cau3chuyencau8' : 'rmBo4cau3chuyencau8'
        }
        if (index === 8) {
            if (cauTraLoi === 2) return 'bo4cau8chuyencau19'
            return cauTraLoi === 1 ? 'bo4cau1chuyencau17' : 'bo4cau8chuyencau20A'
        }
        if (index === 9) {
            return typeof cauTraLoi === 'boolean' && !cauTraLoi ? 'bo4cau9chuyencau20A' : 'rmBo4cau9chuyencau20A'
        }
    }
    if (tenBoCauHoi === '5') {
        if (index === 5) {
            if (cauTraLoi === 3) return 'bo5cau5chuyen20A'
            if (cauTraLoi === 1) return 'bo5cau5chuyen18'
            if (cauTraLoi === 2) return 'bo5cau5chuyen19'
            return 'rm'
        }
    }
    if (tenBoCauHoi === '6') {
        if (index === 1) return typeof cauTraLoi === 'boolean' && !cauTraLoi ? 'bo6cau1chuyencau20B' : 'rmBo6cau1chuyencau20B'
        if (index === 2) {
            const {selected, content} = cauTraLoi

            if (selected === 1) {
                if (content > 0) return 'bo6cau2chuyen18.1.2'
                if (content === 0 || content === '0') return 'bo6cau2chuyen18.2'
            }
            if ([2, 3].includes(selected)) return 'bo6cau2chuyen18.1.1'
        }
        if (index === 9) return cauTraLoi ? 'bo6cau9chuyencau20B' : 'rm'
    }
    if (tenBoCauHoi === '7') {
        if (index === 1) return typeof cauTraLoi === 'boolean' && !cauTraLoi ? 'bo7cau1chuyencau20A' : 'rmBo7cau1chuyencau20A'
        if (index === 2) {
            const {selected, content} = cauTraLoi

            if (selected === 1) {
                if (content > 0) return 'bo7cau2chuyen19.1.2'
                if (content === 0 || content === '0') return 'bo7cau2chuyen19.2'
            }
            if ([2, 3].includes(selected)) return 'bo7cau2chuyen19.1.1'
        }
    }

    if (tenBoCauHoi === '8') {
        if (index === 1 || index === 2) {
            if (cauTraLoi === 'tuChoi') return 'clearbo8logic'
            if (cauTraLoi) {
                return userType === PREP ? 'bo8cau20Achuyencau20.2' : 'bo8cau20Bchuyencau20.2'
            }
            if (typeof cauTraLoi === 'boolean' && !cauTraLoi) return userType === PREP ? 'bo8cau20Achuyencau20.1' : 'bo8cau20Bchuyencau20.1'
        }

        if (index === 4) return cauTraLoi?.selected === 3 ? 'bo8cau4chuyencau21' : 'rmBo8cau4chuyencau21'
    }
}

export const useQuestionFlowWithNoEffect = (obj) => {
    const {tenBoCauHoi, cauTraLoi, index, userType} = obj

    if (tenBoCauHoi === '3') {
        if (index === 1) {
            const cau9 = cauTraLoi.find(({selected}) => selected === 9)
            return cau9 ? 'bo3cau1choncau9' : 'rmBo3cau1choncau9'
        }
    }

    if (tenBoCauHoi === '8') {
        if (index === 5) {
            const cau5 = cauTraLoi.find(({selected}) => selected === 5)
            return cau5 ? 'bo8cau5choncau5' : 'rmBo8cau5choncau5'
        }
    }
}

export const updateAnswerByFilteringCode = (stt, filterCodes) => {

    return stt
}

export const updateSttByFilterCode = (questions, updatedFilterCodes, stt) => {
    return questions.map((item) => {
        const found = stt.find(j => j.questId === item._id)
        if (found) return found

        return {
            cauTraLoi: null,
            index: item.stt,
            indexLevel: item.indexLevel,
            questId: item._id
        }
    })
}

const isExistBatchType = (batches, batchName) => {
    const found = findBatch(batches, batchName, 'tenBoCauHoi')

    if (!found?.lichSuTraLoi) return false

    const {lichSuTraLoi} = found
    return lichSuTraLoi.stt.length
}

export const useInitBatch = (obj, batches) => {
    const {listCauHoi, tenBoCauHoi} = obj
    let list = [...listCauHoi]

    const foundBatch5 = findBatch(batches, '5', 'tenBoCauHoi')
    const cau5 = getCauHoiObj(foundBatch5?.lichSuTraLoi?.stt ?? [], 5) ?? {}

    const isARV = isExistBatchType(batches, '6') || cau5?.cauTraLoi === 1
    const isPrep = isExistBatchType(batches, '7') || cau5?.cauTraLoi === 2

    if (tenBoCauHoi === '8') {
        if (isARV) list = filterQuest(list, [1], 'stt')
        if (isPrep) list = filterQuest(list, [2], 'stt').map(i => i.stt === 3 ? {
            ...i,
            cauTraLoi: i.cauTraLoi.filter((_, idx) => idx !== 5)
        } : i)
        if (!isARV && !isPrep) list = filterQuest(list, [2], 'stt')
    }

    if (tenBoCauHoi === '2') {
        const found = findBatch(batches, '1', 'tenBoCauHoi')
        if (found.lichSuTraLoi) {
            const {stt} = found.lichSuTraLoi
            const foundAnswer = stt.find(i => i.index === 6 && [5, 6].includes(i.cauTraLoi))

            if (foundAnswer) list = filterQuest(list, [3], 'stt')
        }
    }


    return {
        list: list.sort((a, b) => a.stt - b.stt).map(i => ({...i, isAvailable: true})),
        userType: isARV ? ARV : isPrep ? PREP : 'normal'
    }
}

export const handleDisableAnswers = (obj) => {
    const {currentQuestion, stt, maLocNoEffect} = obj
    let updatedIsOpen = []
    let updatedStt = []

    if (maLocNoEffect === 'bo3cau1choncau9') {
        updatedIsOpen = currentQuestion.cauTraLoi.map((_, index) => [0, 1, 2, 3, 4, 5, 6, 7, 9, 10].includes(index) ? {
            ..._,
            isDisabled: true
        } : _)

        updatedStt = stt.map(i => {
            let {cauTraLoi} = i
            cauTraLoi = cauTraLoi?.filter(i => i.selected === 9)
            return i.index === currentQuestion.stt ? {...i, cauTraLoi} : i
        })
    }
    if (maLocNoEffect === 'bo8cau5choncau5') {
        updatedIsOpen = currentQuestion.cauTraLoi.map((_, index) => [0, 1, 2, 3, 4].includes(index) ? {
            ..._,
            isDisabled: true
        } : _)

        updatedStt = stt.map(i => {
            let {cauTraLoi} = i
            cauTraLoi = cauTraLoi?.filter(i => i.selected === 5)
            return i.index === currentQuestion.stt ? {...i, cauTraLoi} : i
        })
    }

    if (maLocNoEffect === 'rmBo3cau1choncau9') {
        updatedIsOpen = currentQuestion.cauTraLoi.map((_, index) => [0, 1, 2, 3, 4, 5, 6, 7, 9, 10].includes(index) ? {
            ..._,
            isDisabled: false
        } : _)
    }

    if (maLocNoEffect === 'rmBo8cau5choncau5') {
        updatedIsOpen = currentQuestion.cauTraLoi.map((_, index) => [0, 1, 2, 3, 4].includes(index) ? {
            ..._,
            isDisabled: false
        } : _)
    }

    return {
        updatedIsOpen,
        stt: updatedStt
    }
}

export const mapChoiceStar3Answer = (array) => {
    return array.map(i => {
        return {
            content: i,
            rank: [{
                value: 1,
                isDisable: false
            }, {
                value: 2,
                isDisable: false
            }, {
                value: 3,
                isDisable: false
            },]
        }
    })
}

export const handleValidateChoiceStar3 = (answers, value) => {
    return answers.map((item, i) => {
        const {rank} = item
        const updateRank = rank.map(j => {
            return value.includes(j.value) ? {...j, isDisable: true} : {...j, isDisable: false}
        })

        return {...item, rank: updateRank}
    })
}

export const handleStar3Style = (rank) => {
    return rank ? `checkmark checkmark-center-position bg-gray no-drop` : 'checkmark checkmark-center-position'
}


export const calUnAnswers2 = (value, inputTypes, loaiCauTraLoi, displayAnswer) => {
    const {
        multipleSelectTypes,
        numberInputTypes,
        MULTICHOICE_INPUT,
        SINGLECHOICE_INPUT,
        SINGLECHOICE_BOOL,
        INPUT_ADDRESS
    } = inputTypes;
    const [a, INPUT_NUMBER, SINGLECHOICE_CONTENT] = numberInputTypes
    const [MULTI_CHOICE] = multipleSelectTypes
    let count = 0
    const script = "Khách hàng từ chối trả lời"


    if (loaiCauTraLoi === SINGLECHOICE_BOOL) {
    } else if (loaiCauTraLoi === SINGLECHOICE_INPUT) {
        if (displayAnswer[value.selected - 1].cauTraLoi === script) {
            return count = 1
        } else return count = 0
    } else if (loaiCauTraLoi === SINGLECHOICE_CONTENT) {
        if (displayAnswer[value - 1] === script) {
            return count = 1
        } else return count = 0
    } else if (loaiCauTraLoi === MULTI_CHOICE) {
        const refuseIndex = displayAnswer.findIndex(i => i === script)

        if (refuseIndex > -1) {
            if (value.includes(refuseIndex + 1)) return count = 1
            else return count = 0
        }
    } else if (loaiCauTraLoi === MULTICHOICE_INPUT) {
        const refuseIndex = displayAnswer.findIndex(i => i.cauTraLoi === script)
        if (refuseIndex > -1) {
            if (value.find(i => i.selected === refuseIndex + 1)) return count = 1
            else return count = 0
        }
    } else if (loaiCauTraLoi === INPUT_NUMBER) {
        if (value && typeof value === 'boolean') return count = 1
        else return count = 0
    } else if (loaiCauTraLoi === INPUT_ADDRESS) {
        if (value?.tuChoi) return count = 1
        else return count = 0
    }

    return count
}

export const calAllRefuseAnswer = (batches, stt, currentBatch) => {
    const allBatches = batches.filter(i => i.tenBoCauHoi !== currentBatch.tenBoCauHoi)
    const allSTT = allBatches.map(i => {
        if (i?.lichSuTraLoi?.stt?.length) return i.lichSuTraLoi.stt.map(j => j?.refuse).filter(i => i).reduce((a, b) => a + b, 0)
        return 0
    }).reduce((a, b) => a + b, 0)
    const currentRefuse = stt.map(i => i.refuse).filter(i => i).reduce((a, b) => a + b, 0)
    if (currentRefuse || allSTT) {
        const countQuestions = batches.map(i => i.listCauHoi.length).reduce((a, b) => a + b, 0)

        return ((currentRefuse + allSTT) * 100 / countQuestions)
    }
    return 0
}



export const getCities = () => {
    const changePosition = ['thanh_pho_ho_chi_minh', 'thanh_pho_ha_noi', 'tinh_dong_nai']
    const [haNoi, dongNai, hcm] = STATIC_ADDRESS.filter(i => changePosition.includes(i.codename))

    return [hcm, haNoi, dongNai].concat(STATIC_ADDRESS.filter(i => !changePosition.includes(i.codename)))
}
export const getDistricts = (city) => STATIC_ADDRESS.find(i => i.codename === city)?.districts
export const getWards = (district, city) => {
    return getDistricts(city).find(i => i.codename === district).wards
}
export const handleSelectedAddress = (array = [], value) => {
    return array.map(i => i.codename === value ? {...i, selected: true} : {...i, selected: false})
}

export const handleClearOtherAddress = (fieldNames, cauTraLoi, answerIndex, value) => {
    const deleteFields = fieldNames.slice(answerIndex, fieldNames.length)
    deleteFields.forEach(element => {
        cauTraLoi[element] = ''
    });

    return {...cauTraLoi, [fieldNames[answerIndex]]: value}
}

export const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export const validateYearInput = (date) => {
    const inputYear = dayjs(date).year()
    const currentYear = dayjs().year()
    if (inputYear > currentYear + 1) return dayjs(dayjs(date).set('year', currentYear + 1)).format('YYYY-MM-DD')
    return date
}

export const handleMapSumQuestion = (batches) => {
    const returnArray = batches.map((item, i) => {
        const filterCodes = []
        const initBatchObj = {listCauHoi: item.listCauHoi, tenBoCauHoi: item.tenBoCauHoi}

        if (item.lichSuTraLoi) {
            item.lichSuTraLoi.stt.forEach(j => {
                const answerObj = {
                    cauTraLoi: j.cauTraLoi,
                    index: j.index,
                    tenBoCauHoi: item.tenBoCauHoi,
                    userType: useInitBatch(initBatchObj, batches)?.userType
                }
                const maLoc = useQuestionFlow(answerObj)

                if (maLoc) filterCodes.push(maLoc)
            })
        }

        if (item.tenBoCauHoi === '8') {
            const {list} = useInitBatch(initBatchObj, batches)
            return {batchId: item._id, totalQuestions: useFilterQuestion(list, filterCodes).length}
        }
        if (filterCodes.length) return {
            batchId: item._id,
            totalQuestions: useFilterQuestion(item.listCauHoi, filterCodes).length
        }
    })

    return returnArray.filter(i => i)
}

export const findUpdatingBatch = (answers, {tenBoCauHoi}) => {
    if (tenBoCauHoi === '1') {
        const [quest6] = filterQuestByKeep(answers, [6], 'index')

        if (quest6) return [5, 6].includes(quest6?.cauTraLoi) ? 'filterBatch2ByBatch1' : 'backtoOriginBatch2'
    }

    return ''
}

const handleUpdateOtherBatchValue = (batches, batchName, code) => {
    const {lichSuTraLoi, _id} = findBatch(batches, batchName, 'tenBoCauHoi')
    let answers = lichSuTraLoi ?? {
        cauTraLoi: [],
        indexLevels: [],
        stt: [],
        boCauHoiId: _id,
    }

    if (lichSuTraLoi && code === 'filterBatch2ByBatch1') {
        answers = {...answers, stt: answers.stt.filter(({index}) => index !== 3)}
    }

    return {answers, _id}
}

export const useFilterQuestionOtherBatch = (code, batches) => {
    if (['filterBatch2ByBatch1', 'backtoOriginBatch2'].includes(code)) {
        const {answers, _id} = handleUpdateOtherBatchValue(batches, '2', code)

        return {
            answers,
            stt: answers.stt,
            length: code === 'filterBatch2ByBatch1' ? 4 : 5,
            batchId: _id,
        }
    }

    return
}
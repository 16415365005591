import * as types from '../types';

const initialState = {
  listChanges: null,
  listChangesDetail: null
};

export default function changesHistoryReducer(state = initialState, action) {
  switch (action.type) {
    case types.LIST_CHANGES_HISTORY:
      return {
        ...state,
        listChanges: action.payload
      };
    case types. LIST_CHANGES_HISTORY_DETAIL:
      return {
        ...state,
        listChangesDetail: action.payload
      };
    default:
      return state;
  }

}

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { readCookie } from '../helpers/cookie.helper';
@Injectable({
    providedIn: 'root'
})

export class MustBeGuestGuard implements CanActivate {
    constructor(private router: Router) { }
    canActivate(): boolean {
        const token = readCookie('token');
        if (token) {
            this.router.navigateByUrl('home');
        }
        return !token;
    }
}

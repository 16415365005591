export const SET_AUTH = "SET_AUTH";
export const REGISTER_AUTH = "REGISTER_AUTH";
export const USER_INFO = "USER_INFO";
export const USER_ID = "USER_ID";
export const VALID_OTP = "VALID_OTP";
export const VALID_PASS = "VALID_PASS";
export const VALID_USER = "VALID_USER";
export const OTP_CODE = "OTP_CODE";
export const TCV_INFO = "TCV_INFO";
export const COUNT_CUSTOMER = "COUNT_CUSTOMER";

//customer
export const LIST_USERS = "LIST_USERS";
export const DETAIL_USERS = "DETAIL_USERS";
export const DETAIL_USERS_KPELOG = "DETAIL_USERS_KPELOG";
export const VALID_LK_USERS = "VALID_LK_USERS";
export const CUSTOMER_INFO = "CUSTOMER_INFO";
export const CUSTOMER_FILTER = "CUSTOMER_FILTER";
export const SEND_LINK_PHONE = "SEND_LINK_PHONE";

//service
export const LIST_SERVICE = "LIST_SERVICE";
export const LIST_HISTORY = "LIST_HISTORY";
export const LIST_QUEST_SERVICE = "LIST_QUEST_SERVICE";

//changes-history
export const LIST_CHANGES_HISTORY = "LIST_CHANGES_HISTORY";
export const LIST_CHANGES_HISTORY_DETAIL = "LIST_CHANGES_HISTORY_DETAIL";
//notifications
export const LIST_NOTIFICATIONS = "LIST_NOTIFICATIONS";
export const LIST_NOTIFICATIONS_CBO = "LIST_NOTIFICATIONS_CBO";
export const NEW_BOOKINGS = 'NEW_BOOKINGS'

// survey
export const SURVEY_GET_LIST = "SURVEY_GET_LIST";
export const SURVEY_SET_BATCH = "SURVEY_SET_BATCH";
export const SURVEY_SAVE_DATA = "SURVEY_SAVE_DATA";
export const SURVEY_GET_USER_PROCESS = "SURVEY_GET_USER_PROCESS";
export const SURVEY_UPDATE_STT = "SURVEY_UPDATE_STT";
export const SURVEY_UPDATE_HAVE_CHANGE = "SURVEY_UPDATE_HAVE_CHANGE";
